import { WORDS } from '../constants/wordlist'
import { VALID_GUESSES } from '../constants/validGuesses'
import { WRONG_SPOT_MESSAGE, NOT_CONTAINED_MESSAGE } from '../constants/strings'
import { getGuessStatuses } from './statuses'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { ACENTOS } from '../constants/acentos'

// 1 January 2022 Game Epoch
export const firstGameDate = new Date(2022, 0)
export const periodInDays = 1

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(localeAwareLowerCase(word)) ||
    VALID_GUESSES.includes(localeAwareLowerCase(word)) ||
    localeAwareLowerCase(word) in ACENTOS
  )
}

export const isWinningWord = (word: string) => {
  if (localeAwareLowerCase(word) in ACENTOS) {
    word = localeCorrectWord(word).toUpperCase()
  }
  return solution === word
}

export const jAcentos = JSON.parse(JSON.stringify(ACENTOS))

export const localeCorrectWord = (word: string) => {
  let correctWordWithAcentos = jAcentos[word.toLowerCase()]
  return correctWordWithAcentos
}

// Criar uma funcao que recebe uma palavra com acentos e retorna a mesma sem acentos

export const solutionNoAccents = (word: string) => {
  // receive a word with accents and return the same word without accents
  return word.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

// provide the word guess with accents
export const accentedGuess = (word: string) => {
  if (localeAwareLowerCase(word) in ACENTOS) {
    word = localeCorrectWord(word).toUpperCase()
  }
  return word
}

// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
// also check if all revealed instances of a letter are used (i.e. two C's)
export const findFirstUnusedReveal = (word: string, guesses: string[]) => {
  if (guesses.length === 0) {
    return false
  }

  const lettersLeftArray = new Array<string>()
  const guess = guesses[guesses.length - 1]
  const statuses = getGuessStatuses(solution, guess)
  const splitWord = unicodeSplit(word)
  const splitGuess = unicodeSplit(guess)

  for (let i = 0; i < splitGuess.length; i++) {
    if (statuses[i] === 'correct' || statuses[i] === 'present') {
      lettersLeftArray.push(splitGuess[i])
    }
    if (statuses[i] === 'correct' && splitWord[i] !== splitGuess[i]) {
      return WRONG_SPOT_MESSAGE(splitGuess[i], i + 1)
    }
  }

  // check for the first unused letter, taking duplicate letters
  // into account - see issue #198

  // Some conflit with portuguese accents - back here if someone complains on hard mode behavior

  let n
  for (const letter of splitWord) {
    n = lettersLeftArray.indexOf(letter)
    if (n !== -1) {
      lettersLeftArray.splice(n, 1)
    }
  }
  console.log(`lettersLeftArray ${lettersLeftArray}`)
  if (lettersLeftArray.length > 0) {
    return NOT_CONTAINED_MESSAGE(lettersLeftArray[0])
  }

  return false
}

export const unicodeSplit = (word: string) => {
  // console.log(`word is ${word}`)
  // if (localeAwareLowerCase(word) in ACENTOS) {
  //   word = localeCorrectWord(word).toUpperCase()
  //   console.log(`word now is ${word}`)
  // } else {
  //   return new GraphemeSplitter().splitGraphemes(word)
  // }
  return new GraphemeSplitter().splitGraphemes(word)
}

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length
}

export const unicodeNormalize = (word: string) => {
  console.log('Normal ', word)
  return word
}

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase()
}

export const getToday = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today
}

export const getLastGameDate = (today: Date) => {
  const t = new Date(today)
  t.setHours(0, 0, 0)
  let daysSinceLastGame =
    (t.getDay() - firstGameDate.getDay() + 7) % periodInDays
  const lastDate = new Date(t)
  lastDate.setDate(t.getDate() - daysSinceLastGame)
  return lastDate
}

export const getNextGameDate = (today: Date) => {
  const t = new Date(today)
  t.setHours(0, 0, 0)
  t.setDate(getLastGameDate(today).getDate() + periodInDays)
  return t
}

export const getIndex = (today: Date) => {
  const start = new Date(firstGameDate)
  let index = -1
  do {
    index++
    start.setDate(start.getDate() + periodInDays)
  } while (start <= today)

  return index
}

export const getWordOfDay = (index: number) => {
  if (index < 0) {
    throw new Error('Invalid index')
  }

  return localeAwareUpperCase(WORDS[index % WORDS.length])
}

export const getSolution = (today: Date) => {
  const nextGameDate = getNextGameDate(today)
  const index = getIndex(today)
  const wordOfTheDay = getWordOfDay(index)
  // const wordOfTheDay = 'DIVERGE'

  return {
    solution: wordOfTheDay,
    solutionIndex: index,
    tomorrow: nextGameDate.valueOf(),
  }
}

export const { solution, solutionIndex, tomorrow } = getSolution(getToday())
