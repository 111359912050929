export const VALID_GUESSES = [
"pessoas",
"produto",
"janeiro",
"durante",
"empresa",
"sistema",
"algumas",
"através",
"direito",
"projeto",
"momento",
"público",
"governo",
"família",
"verdade",
"segundo",
"exemplo",
"federal",
"relação",
"segunda",
"outubro",
"precisa",
"grandes",
"mercado",
"somente",
"serviço",
"fazendo",
"simples",
"preciso",
"estamos",
"milhões",
"período",
"próximo",
"coração",
"próprio",
"pessoal",
"passado",
"estados",
"justiça",
"sucesso",
"comprar",
"sociais",
"minutos",
"palavra",
"pública",
"maneira",
"sentido",
"própria",
"atenção",
"ninguém",
"maioria",
"futebol",
"poderia",
"questão",
"vontade",
"caminho",
"podemos",
"energia",
"certeza",
"polícia",
"contato",
"prática",
"assunto",
"imagens",
"análise",
"entrega",
"receber",
"tamanho",
"existem",
"difícil",
"mundial",
"aqueles",
"animais",
"quartos",
"artigos",
"entanto",
"colocar",
"oficial",
"eventos",
"criança",
"valores",
"escolha",
"pequeno",
"maiores",
"técnico",
"entrada",
"cultura",
"decisão",
"vitória",
"estudos",
"próxima",
"opinião",
"técnica",
"nenhuma",
"começou",
"emprego",
"criação",
"postado",
"permite",
"oferece",
"leitura",
"unidade",
"estavam",
"últimos",
"membros",
"pequena",
"usuário",
"aumento",
"arquivo",
"américa",
"domingo",
"posição",
"solução",
"começar",
"natural",
"cidades",
"matéria",
"cozinha",
"capital",
"suporte",
"musicas",
"mostrar",
"escolas",
"chamado",
"máquina",
"procura",
"cliente",
"deveria",
"direção",
"efeitos",
"alegria",
"escrito",
"falando",
"ofertas",
"agência",
"modelos",
"humanos",
"vendido",
"mudança",
"revista",
"consigo",
"partido",
"lugares",
"páginas",
"seguida",
"estiver",
"memória",
"dúvidas",
"celular",
"crédito",
"negócio",
"avenida",
"receita",
"popular",
"turismo",
"espécie",
"consumo",
"piscina",
"jogador",
"diretor",
"geração",
"partida",
"semanas",
"chamada",
"pressão",
"estação",
"esporte",
"afirmou",
"cuidado",
"recebeu",
"medidas",
"fizeram",
"dizendo",
"lembrar",
"funções",
"conhece",
"noticia",
"formato",
"esperar",
"planeta",
"recurso",
"aparece",
"viagens",
"ricardo",
"estrada",
"reunião",
"escrita",
"inicial",
"vestido",
"madeira",
"central",
"destino",
"tivesse",
"pereira",
"seleção",
"escolar",
"virtual",
"editora",
"poderão",
"veículo",
"digital",
"anuncie",
"redução",
"fazenda",
"carinho",
"horário",
"limpeza",
"meninas",
"direita",
"possuem",
"gostosa",
"tópicos",
"adultos",
"médicos",
"artista",
"crítica",
"atuação",
"autores",
"doenças",
"objetos",
"inteiro",
"estrela",
"fatores",
"tiveram",
"reserva",
"explica",
"ciência",
"lançado",
"supremo",
"visitas",
"anúncio",
"importa",
"bateria",
"comando",
"números",
"cenário",
"compras",
"atender",
"estreia",
"bilhões",
"cabelos",
"caráter",
"devemos",
"ficaram",
"antigos",
"amizade",
"salário",
"aluguel",
"motivos",
"regiões",
"enviado",
"visitar",
"esforço",
"terreno",
"desafio",
"parecer",
"sorriso",
"atitude",
"militar",
"recente",
"aquelas",
"estudar",
"coleção",
"francês",
"limites",
"fábrica",
"retorno",
"desenho",
"daquele",
"querida",
"galeria",
"ligação",
"chegada",
"espaços",
"ribeiro",
"estádio",
"gostoso",
"aspecto",
"levando",
"reforma",
"naquele",
"ocorreu",
"plantas",
"ficando",
"agentes",
"martins",
"podendo",
"passada",
"sabemos",
"musical",
"títulos",
"versões",
"domínio",
"conosco",
"combate",
"pintura",
"menores",
"últimas",
"imóveis",
"sujeito",
"passeio",
"mistura",
"orgulho",
"coragem",
"aluguer",
"depende",
"guardar",
"métodos",
"jornada",
"batalha",
"realiza",
"acórdão",
"sozinho",
"almeida",
"abraços",
"avaliar",
"parecia",
"corrida",
"duração",
"estágio",
"mostrou",
"tivemos",
"garante",
"reduzir",
"células",
"colegas",
"crescer",
"vítimas",
"utiliza",
"baseado",
"deverão",
"padrões",
"aceitar",
"jornais",
"ocasião",
"acessar",
"privada",
"pedidos",
"alterar",
"perante",
"inverno",
"química",
"amarelo",
"atletas",
"atingir",
"sorteio",
"equipes",
"demanda",
"líderes",
"campeão",
"eleição",
"perdido",
"licença",
"saudade",
"fórmula",
"incluem",
"aplicar",
"aumenta",
"olhando",
"cumprir",
"definir",
"garagem",
"felizes",
"tarefas",
"interna",
"convite",
"existir",
"entende",
"espelho",
"gráfico",
"daquela",
"naquela",
"comecei",
"europeu",
"coloque",
"capazes",
"estadia",
"líquido",
"enviada",
"interno",
"poderes",
"cérebro",
"sabendo",
"paraíba",
"estaria",
"abertas",
"moderno",
"desejar",
"decidiu",
"dólares",
"pudesse",
"ocorrer",
"termina",
"teclado",
"correta",
"augusto",
"excesso",
"inteira",
"escreve",
"iniciar",
"farinha",
"parecem",
"conheço",
"comprei",
"teremos",
"lateral",
"laranja",
"fechado",
"ficamos",
"divisão",
"décadas",
"procure",
"cidadão",
"disputa",
"desejos",
"gerente",
"árvores",
"benfica",
"privado",
"diálogo",
"estúdio",
"mostram",
"fazemos",
"externa",
"torcida",
"romance",
"formado",
"conexão",
"paredes",
"cantora",
"batista",
"ensinar",
"moderna",
"térmica",
"cálculo",
"auxílio",
"famosos",
"envolve",
"assumir",
"senhora",
"segredo",
"setores",
"visando",
"cristão",
"gordura",
"colocou",
"oficina",
"estarão",
"revisão",
"estejam",
"resolvi",
"andrade",
"antigas",
"bebidas",
"alcance",
"começam",
"marcado",
"cadeira",
"avaliou",
"cerveja",
"brincar",
"palácio",
"sozinha",
"detalhe",
"integra",
"ataques",
"repente",
"talento",
"destaca",
"correto",
"tomando",
"externo",
"perfume",
"símbolo",
"baseada",
"general",
"aliança",
"decidir",
"emoções",
"esquema",
"postura",
"fizemos",
"centros",
"delicia",
"afirmar",
"prestar",
"roteiro",
"votação",
"tratado",
"meninos",
"largura",
"balanço",
"autoria",
"mandato",
"paraíso",
"imposto",
"criando",
"classes",
"algodão",
"entendo",
"básicos",
"gostava",
"séculos",
"formada",
"tecidos",
"percebe",
"torneio",
"pimenta",
"pedindo",
"vivendo",
"vivemos",
"aprendi",
"prático",
"estoque",
"edições",
"canções",
"acessos",
"riqueza",
"físicas",
"elevado",
"promove",
"titular",
"chances",
"retirar",
"igrejas",
"jogando",
"socorro",
"figuras",
"filtrar",
"promete",
"clínica",
"bairros",
"cheguei",
"defende",
"emissão",
"barriga",
"vicente",
"querido",
"prefiro",
"aprenda",
"cartões",
"ampliar",
"básicas",
"inimigo",
"gigante",
"percebi",
"estando",
"contudo",
"derrota",
"esqueça",
"conduta",
"quantos",
"máscara",
"mentira",
"pobreza",
"fashion",
"virtude",
"baratos",
"relógio",
"esqueci",
"fornece",
"iniciou",
"encanto",
"melhora",
"trailer",
"línguas",
"marcada",
"pacotes",
"loucura",
"assista",
"confira",
"fiscais",
"pousada",
"regular",
"ligados",
"adianta",
"recebem",
"redação",
"litoral",
"terapia",
"dezenas",
"tarifas",
"incluir",
"impedir",
"oriente",
"atração",
"diárias",
"resenha",
"atacado",
"varanda",
"hábitos",
"tráfico",
"solidão",
"indicar",
"esmalte",
"ouvindo",
"possuir",
"debaixo",
"senador",
"nascido",
"japonês",
"utilize",
"exceção",
"pescoço",
"barulho",
"acusado",
"revelou",
"prêmios",
"implica",
"estilos",
"juliana",
"costuma",
"janelas",
"brancos",
"feriado",
"inferno",
"quantas",
"anônimo",
"físicos",
"amostra",
"abertos",
"quadros",
"sapatos",
"ouvidos",
"superar",
"novinha",
"crítico",
"pecados",
"tirando",
"humanas",
"intuito",
"garotas",
"marinho",
"extrema",
"recorde",
"todavia",
"dirigir",
"correio",
"lavagem",
"grávida",
"informa",
"tráfego",
"parcial",
"garrafa",
"criados",
"anuncia",
"acordar",
"marinha",
"círculo",
"intensa",
"ficaria",
"goleiro",
"preparo",
"estarei",
"conheci",
"gráfica",
"carioca",
"elevada",
"passava",
"tabelas",
"sudeste",
"intenso",
"pedaços",
"locação",
"repetir",
"cresceu",
"adiante",
"câmeras",
"absurdo",
"parcela",
"colégio",
"contigo",
"entendi",
"prepara",
"seguido",
"bárbara",
"resolve",
"término",
"teorias",
"exercer",
"excluir",
"ensaios",
"remédio",
"dividir",
"assinar",
"comendo",
"armário",
"alagoas",
"sessões",
"comprou",
"lançada",
"normais",
"vanessa",
"boletim",
"daquilo",
"fundada",
"escrevi",
"levaram",
"sombras",
"registo",
"camadas",
"futuros",
"jardins",
"efetuar",
"observa",
"adesivo",
"amarela",
"costume",
"ocorrem",
"tesouro",
"quebrar",
"legenda",
"tomadas",
"higiene",
"situado",
"deixado",
"visível",
"efetivo",
"andando",
"voltado",
"extremo",
"holanda",
"devendo",
"tocando",
"consiga",
"índices",
"arrumar",
"criador",
"obtidos",
"músicos",
"escreva",
"ranking",
"achando",
"sexuais",
"terraço",
"pensava",
"similar",
"procuro",
"perdida",
"dívidas",
"conheça",
"deserto",
"aprende",
"síntese",
"aguardo",
"urgente",
"códigos",
"embaixo",
"debates",
"entenda",
"bonitas",
"comenta",
"curioso",
"galinha",
"falamos",
"fechada",
"motores",
"gêneros",
"assumiu",
"existia",
"carbono",
"orlando",
"estarem",
"revelar",
"lembrou",
"crônica",
"seguros",
"julgado",
"vizinho",
"atingiu",
"resulta",
"nuclear",
"bancada",
"manteve",
"provoca",
"cabeças",
"divulga",
"quentes",
"abençoe",
"parques",
"assisti",
"prestes",
"milagre",
"abrindo",
"profeta",
"famosas",
"fixação",
"luciana",
"esquina",
"escrevo",
"relatos",
"efetiva",
"tavares",
"comédia",
"filtros",
"defeito",
"havendo",
"visuais",
"imagina",
"protege",
"desfile",
"cotação",
"estável",
"escolhe",
"inglesa",
"guerras",
"treinar",
"brancas",
"ligadas",
"pilotos",
"volante",
"prontos",
"balança",
"lâmpada",
"decreto",
"doentes",
"criadas",
"avançar",
"trechos",
"aliados",
"umidade",
"idiomas",
"cintura",
"batendo",
"resgate",
"afastar",
"escapar",
"separar",
"levanta",
"citados",
"estampa",
"gerando",
"gravado",
"cinemas",
"remover",
"herança",
"prédios",
"castelo",
"escutar",
"avanços",
"agradar",
"soldado",
"reações",
"vínculo",
"exibido",
"camargo",
"merecem",
"capitão",
"lembrei",
"ginásio",
"assalto",
"futuras",
"ameaças",
"orações",
"margens",
"juvenil",
"crenças",
"maconha",
"textura",
"legumes",
"prefere",
"estatal",
"suporta",
"enormes",
"confiar",
"elogios",
"amantes",
"compõem",
"convida",
"esquece",
"reforço",
"voltada",
"lacrado",
"origens",
"sobrado",
"combina",
"chegado",
"quintal",
"queriam",
"invasão",
"nervoso",
"confere",
"gramado",
"exposto",
"relator",
"amadora",
"admitir",
"humilde",
"encarar",
"vegetal",
"adaptar",
"indicam",
"atuando",
"reflete",
"haveria",
"mudando",
"olhares",
"dedicar",
"desejam",
"docente",
"urbanos",
"sorvete",
"plateia",
"curiosa",
"retrato",
"frontal",
"diploma",
"caseiro",
"radical",
"sentado",
"revolta",
"acordos",
"observe",
"miséria",
"pagando",
"faleceu",
"relaxar",
"cigarro",
"treinos",
"cavalos",
"ocupado",
"permita",
"passear",
"diminui",
"pegando",
"animado",
"afirmam",
"gostado",
"website",
"passará",
"cansado",
"reflexo",
"piedade",
"semente",
"colunas",
"sumário",
"ensinou",
"acabado",
"trilhas",
"eleitos",
"esconde",
"equipas",
"manchas",
"remoção",
"bagagem",
"letícia",
"aquário",
"dourado",
"editado",
"segurar",
"acertar",
"adeptos",
"inserir",
"situada",
"barreto",
"lareira",
"servido",
"clínico",
"mantido",
"mandado",
"fodendo",
"bilhete",
"fazerem",
"tornado",
"lembram",
"sagrado",
"armadas",
"clareza",
"alemães",
"volumes",
"bonitos",
"plantão",
"caderno",
"repouso",
"tortura",
"decorar",
"pareceu",
"gerador",
"fortuna",
"vizinha",
"missões",
"rapidez",
"empenho",
"feridas",
"teórico",
"bondade",
"aumente",
"apontam",
"conclui",
"escolhi",
"vintage",
"moradia",
"alfredo",
"escadas",
"escravo",
"esperam",
"batatas",
"fizesse",
"animada",
"analisa",
"causado",
"mínimos",
"punição",
"percebo",
"pássaro",
"satanás",
"mestres",
"referir",
"carrega",
"mensais",
"prepare",
"devagar",
"painéis",
"cansaço",
"mentais",
"batismo",
"novelas",
"apontar",
"encerra",
"brindes",
"monstro",
"andares",
"ficarem",
"aceitou",
"pensado",
"liberal",
"aprovou",
"nomeado",
"válvula",
"rosário",
"dominar",
"garotos",
"veremos",
"império",
"precoce",
"algarve",
"colocam",
"isolado",
"ajustes",
"crentes",
"suposto",
"vigente",
"bezerra",
"liminar",
"vocação",
"deveres",
"costura",
"passivo",
"impulso",
"feridos",
"tiverem",
"coberta",
"adotado",
"discute",
"bíblica",
"filhote",
"navegar",
"isenção",
"estante",
"sábados",
"pesados",
"revelam",
"aplique",
"horária",
"acharam",
"sabores",
"estreou",
"gênesis",
"chácara",
"rápidas",
"bíblico",
"medalha",
"retorna",
"prender",
"boquete",
"quantia",
"ajudado",
"rodovia",
"levados",
"toalhas",
"apostar",
"informe",
"doações",
"estende",
"censura",
"putaria",
"enganar",
"petista",
"petição",
"médicas",
"monitor",
"declara",
"demorar",
"tamanha",
"causada",
"vejamos",
"acordei",
"caralho",
"truques",
"árbitro",
"obtenha",
"rapazes",
"extrato",
"baratas",
"ausente",
"pedimos",
"infeliz",
"ficarão",
"câmaras",
"racismo",
"estadão",
"contará",
"poética",
"aviação",
"tratava",
"semanal",
"notável",
"módulos",
"medição",
"esboços",
"abordar",
"tratada",
"bênçãos",
"obtidas",
"fachada",
"sincero",
"puderam",
"rápidos",
"cimento",
"cultivo",
"diários",
"bonecos",
"urbanas",
"achamos",
"sofrido",
"joelhos",
"bonecas",
"manuais",
"coberto",
"sugerir",
"trajeto",
"buracos",
"abraçar",
"secreto",
"gourmet",
"alcança",
"atentos",
"fundado",
"estátua",
"repleto",
"fabiano",
"estudou",
"apelido",
"criaram",
"citação",
"armando",
"queimar",
"cansada",
"mineral",
"cometer",
"demorou",
"liberar",
"compara",
"misture",
"possuía",
"playboy",
"falaram",
"líquida",
"assento",
"perícia",
"globais",
"tristes",
"faltava",
"traição",
"asfalto",
"deixava",
"falante",
"chinesa",
"poesias",
"triunfo",
"ansiosa",
"telhado",
"lutando",
"mantida",
"bordado",
"perigos",
"cristal",
"teórica",
"apostas",
"déficit",
"suposta",
"aceitam",
"jundiaí",
"peixoto",
"valente",
"mudaram",
"barroso",
"bolinha",
"paradas",
"aguarde",
"montado",
"abrange",
"prótese",
"insetos",
"calçada",
"chamava",
"suprema",
"valéria",
"adotada",
"captura",
"digamos",
"péssimo",
"castigo",
"pérolas",
"esferas",
"incapaz",
"prometo",
"padaria",
"tributo",
"beatles",
"tenente",
"véspera",
"apetite",
"biquíni",
"vencido",
"mineiro",
"guarani",
"sólidos",
"imagino",
"perdoar",
"aprovar",
"deixará",
"ignorar",
"omissão",
"armação",
"evoluir",
"plantio",
"chegará",
"gatinha",
"caminha",
"eleitor",
"paixões",
"sensual",
"difusão",
"demônio",
"diretos",
"despesa",
"palhaço",
"colombo",
"console",
"explore",
"recheio",
"objecto",
"outrora",
"reforça",
"messias",
"tapetes",
"patamar",
"assiste",
"exibida",
"renovar",
"matando",
"apontou",
"patente",
"digitar",
"inédito",
"secador",
"debater",
"recados",
"camisas",
"assinou",
"jaqueta",
"chegava",
"reserve",
"rotação",
"entorno",
"mexendo",
"químico",
"destina",
"cândido",
"jogadas",
"agendar",
"estupro",
"confuso",
"tribuna",
"equação",
"bandido",
"auxilia",
"arranjo",
"injeção",
"queiram",
"baixada",
"seremos",
"aguarda",
"ajustar",
"mochila",
"secreta",
"ovelhas",
"aliviar",
"referem",
"bandeja",
"armazém",
"culpado",
"ladrões",
"reclama",
"piratas",
"brincos",
"contava",
"colônia",
"indicou",
"equador",
"tentava",
"maldade",
"gozando",
"agrária",
"naquilo",
"prontas",
"limitar",
"selinho",
"subindo",
"honesto",
"pilares",
"brandão",
"coronel",
"directo",
"rituais",
"insiste",
"lamento",
"turista",
"madonna",
"mortais",
"dublado",
"gerados",
"ocultas",
"acertou",
"teatral",
"plantar",
"ternura",
"virando",
"existiu",
"quartas",
"forçado",
"faremos",
"escuros",
"solares",
"potente",
"vampiro",
"esteira",
"ademais",
"orienta",
"viatura",
"brilhar",
"lençóis",
"advento",
"vaidade",
"exagero",
"exigido",
"peituda",
"furacão",
"voltará",
"refúgio",
"tubarão",
"roubado",
"sentada",
"visitou",
"morador",
"materno",
"parente",
"retomar",
"amoroso",
"reparar",
"cobrado",
"ruptura",
"celebra",
"apanhar",
"guardas",
"romanos",
"porções",
"sujeita",
"poderem",
"imagine",
"costumo",
"namorar",
"noturno",
"tentado",
"editais",
"vinagre",
"cenoura",
"colorir",
"decorre",
"bagunça",
"enfoque",
"audição",
"ouvimos",
"apertar",
"sincera",
"povoado",
"metendo",
"fabiana",
"contida",
"melodia",
"extensa",
"cocaína",
"crescem",
"alturas",
"elimina",
"noturna",
"executa",
"valendo",
"entrará",
"quebrou",
"seriado",
"típicas",
"comidas",
"isolada",
"acúmulo",
"pediram",
"rolando",
"saberes",
"galáxia",
"quesito",
"armados",
"mármore",
"trilhos",
"emendas",
"músculo",
"almoçar",
"dirigiu",
"xícaras",
"jogaram",
"arsenal",
"postada",
"cortado",
"ocupada",
"natação",
"térmico",
"sujeira",
"aldeias",
"vitrine",
"vendida",
"planeja",
"ficasse",
"orelhas",
"alertas",
"inédita",
"tomaram",
"templos",
"consome",
"ventura",
"iogurte",
"devedor",
"apoiado",
"atendeu",
"formais",
"granito",
"repleta",
"ordenar",
"mineira",
"emitido",
"lanches",
"constar",
"pênalti",
"ousadia",
"casados",
"aquecer",
"browser",
"escuras",
"ilegais",
"investe",
"enquete",
"ambição",
"acomoda",
"estória",
"péssima",
"dourada",
"fraldas",
"proteja",
"egoísmo",
"ansioso",
"manobra",
"tensões",
"retrata",
"extrair",
"definiu",
"papelão",
"ficarei",
"sejamos",
"oitenta",
"pintado",
"devidos",
"espanto",
"desviar",
"núcleos",
"passiva",
"sanções",
"calçado",
"nublado",
"válidos",
"camarão",
"devoção",
"superou",
"pesadas",
"atelier",
"garanto",
"sugerem",
"redonda",
"gravata",
"alergia",
"gatinho",
"idiotas",
"escócia",
"sublime",
"glamour",
"lágrima",
"prisões",
"emissor",
"pincéis",
"minoria",
"eclipse",
"amorosa",
"cumpriu",
"imposta",
"exposta",
"leitora",
"gavetas",
"caseira",
"nervosa",
"desvios",
"erradas",
"ligando",
"gravada",
"citadas",
"quadras",
"sugeriu",
"ouviram",
"abacaxi",
"bizarro",
"crucial",
"tornará",
"rotinas",
"aguenta",
"encanta",
"morando",
"pistola",
"pelúcia",
"nominal",
"panelas",
"assédio",
"descida",
"achados",
"levante",
"abração",
"cometeu",
"esponja",
"acordou",
"esperto",
"vacinas",
"brigada",
"liberta",
"devidas",
"adjunto",
"glicose",
"explora",
"furtado",
"ficavam",
"favelas",
"montada",
"umbanda",
"relatou",
"absurda",
"aceitos",
"firmeza",
"pensões",
"abóbora",
"traçado",
"contido",
"precise",
"encaixa",
"caramba",
"portais",
"atrasos",
"inspira",
"service",
"cortina",
"expulso",
"tanques",
"esfriar",
"invadir",
"tomates",
"típicos",
"instala",
"criamos",
"caverna",
"cereais",
"adquire",
"ouvidas",
"compete",
"tocante",
"juliano",
"admitiu",
"confusa",
"educado",
"atribui",
"reinado",
"vestida",
"prancha",
"contada",
"barraca",
"atalhos",
"setenta",
"fomento",
"adorava",
"rebelde",
"engenho",
"recusou",
"poderei",
"alertar",
"colapso",
"rumores",
"tiradas",
"célebre",
"casinha",
"egoísta",
"violeta",
"bobagem",
"arraial",
"cortada",
"rebanho",
"atendem",
"portões",
"firmado",
"concede",
"decidem",
"decente",
"visitam",
"judaica",
"garanta",
"nascida",
"marcela",
"negação",
"carente",
"carinha",
"regimes",
"comboio",
"tesoura",
"vivenda",
"alegres",
"mínimas",
"nativos",
"encaixe",
"levaria",
"morango",
"assusta",
"escalas",
"risadas",
"réplica",
"pulmões",
"contado",
"blogues",
"acolher",
"merecia",
"tempero",
"recusar",
"expedia",
"ordenou",
"shampoo",
"abelhas",
"funchal",
"existam",
"cautela",
"rodando",
"admirar",
"molhado",
"cadeias",
"grafite",
"cercado",
"cônjuge",
"morrido",
"atrizes",
"provado",
"orgasmo",
"agulhas",
"enfeite",
"aplicam",
"fraudes",
"directa",
"portela",
"fugindo",
"opostos",
"colchão",
"cliques",
"trazido",
"sagrada",
"largada",
"dispõem",
"destrói",
"injusto",
"pecador",
"offline",
"apareça",
"queixas",
"coletar",
"bermuda",
"velhice",
"condena",
"cristãs",
"engolir",
"levadas",
"aptidão",
"acabara",
"ajudará",
"trágico",
"ensinam",
"desktop",
"ilusões",
"ganhará",
"genebra",
"adequar",
"exigida",
"hóspede",
"nicolau",
"incluso",
"defesas",
"julgada",
"noventa",
"sintoma",
"emprega",
"anseios",
"entrado",
"acumula",
"dedicou",
"premier",
"viveram",
"perceba",
"definem",
"belezas",
"consolo",
"repasse",
"rótulos",
"antenas",
"testado",
"prezado",
"punheta",
"ângulos",
"moldura",
"biscuit",
"formosa",
"colisão",
"descaso",
"comente",
"leilões",
"carisma",
"lutador",
"liderar",
"retirou",
"viciado",
"mágicos",
"ativado",
"relatar",
"sinopse",
"débitos",
"acalmar",
"obtendo",
"calibre",
"atingem",
"prefira",
"insumos",
"unânime",
"jacuzzi",
"fluidos",
"seguras",
"anistia",
"tumores",
"sossego",
"materna",
"abriram",
"augusta",
"batidas",
"deitado",
"agregar",
"tomamos",
"laboral",
"certame",
"espiral",
"taubaté",
"poético",
"tijolos",
"dizemos",
"incomum",
"júpiter",
"baladas",
"queijos",
"extenso",
"sombrio",
"formiga",
"bebendo",
"comanda",
"autismo",
"rodadas",
"servirá",
"acertos",
"limeira",
"postais",
"ilícito",
"referiu",
"viciada",
"molhada",
"lâminas",
"lixeira",
"prestam",
"proibir",
"diretas",
"bacanas",
"vetores",
"forçada",
"ocultar",
"enxerga",
"recebia",
"granada",
"pintada",
"inúteis",
"abrigar",
"oceanos",
"paragem",
"citando",
"afastou",
"paulino",
"bolinho",
"acordes",
"florais",
"emitida",
"impedem",
"estrear",
"fósforo",
"ilustra",
"vírgula",
"recolha",
"incisos",
"ilustre",
"aleluia",
"levamos",
"cunhado",
"nobreza",
"alecrim",
"fósseis",
"recorda",
"ouvinte",
"inverso",
"paladar",
"constam",
"marchas",
"cheques",
"resiste",
"extinta",
"plantel",
"sedução",
"alameda",
"deparei",
"cachaça",
"apetece",
"valioso",
"gradual",
"redondo",
"detidos",
"mágicas",
"convoca",
"dezoito",
"impediu",
"secagem",
"merecer",
"incluiu",
"suspiro",
"entrava",
"jaraguá",
"votaram",
"noutros",
"esqueço",
"extinto",
"upgrade",
"somando",
"espalhe",
"perderá",
"arcanjo",
"falavam",
"afetado",
"juvenis",
"variado",
"capilar",
"violino",
"rejeita",
"karaokê",
"maestro",
"quadril",
"lamenta",
"deixada",
"avançou",
"saberia",
"troquei",
"janaína",
"peritos",
"assumem",
"paredão",
"mística",
"virgens",
"sotaque",
"agradou",
"celeste",
"inmetro",
"reparos",
"frescos",
"trocado",
"esposas",
"errados",
"afronta",
"lembrem",
"frentes",
"exercem",
"almoços",
"empatia",
"adquira",
"realize",
"variada",
"dividem",
"escalão",
"aceitas",
"revenda",
"puxando",
"voltava",
"maldito",
"funeral",
"ditador",
"vedação",
"literal",
"insônia",
"explico",
"botelho",
"apurado",
"procede",
"default",
"recordo",
"freezer",
"desliga",
"acender",
"emblema",
"display",
"bananas",
"obrigar",
"iniciam",
"propina",
"degraus",
"invadiu",
"caçador",
"ardente",
"cabendo",
"aguento",
"cunhada",
"compram",
"espalha",
"válidas",
"provido",
"auditor",
"estudam",
"afetada",
"coitado",
"porrada",
"engloba",
"reduziu",
"prestou",
"sacolas",
"extreme",
"aninhos",
"esperei",
"transar",
"filmado",
"ilumina",
"erótico",
"loteria",
"lésbica",
"delírio",
"anormal",
"escrava",
"euforia",
"orvalho",
"macacos",
"indiano",
"feitiço",
"potável",
"anônima",
"recinto",
"tucanos",
"pétalas",
"apoiada",
"méritos",
"habitat",
"cadáver",
"cápsula",
"evoluiu",
"envolva",
"imortal",
"honesta",
"pulando",
"heroína",
"imensas",
"acelera",
"folheto",
"cremoso",
"recorte",
"dotados",
"jurados",
"assisto",
"estêvão",
"pudemos",
"pegamos",
"sentirá",
"buscapé",
"agarrar",
"saladas",
"textual",
"frágeis",
"teatros",
"adquiri",
"escalar",
"atlanta",
"educada",
"abdômen",
"outdoor",
"enzimas",
"goleada",
"desiste",
"elogiar",
"moleque",
"forense",
"profile",
"listras",
"milênio",
"mísseis",
"ofensas",
"apagado",
"habitam",
"arranca",
"baleado",
"covarde",
"prendeu",
"ninfeta",
"fixados",
"tempere",
"geradas",
"alterou",
"façamos",
"íntimas",
"quintas",
"espinha",
"baixado",
"rodelas",
"maldita",
"traumas",
"vaginal",
"frescas",
"maridos",
"tomados",
"excepto",
"puderem",
"tiraram",
"execute",
"alarmes",
"espinho",
"saturno",
"suicida",
"avisado",
"triagem",
"nazista",
"autoral",
"cantada",
"bendito",
"luxuoso",
"visitem",
"alegrar",
"ladeira",
"importo",
"editada",
"quartel",
"caracas",
"aflição",
"liberou",
"diversa",
"lavoura",
"córrego",
"obrigou",
"troféus",
"eternos",
"sofrida",
"valença",
"acusada",
"circula",
"enxoval",
"obedece",
"sonoras",
"pagamos",
"espadas",
"nativas",
"safadas",
"viraram",
"sintaxe",
"projeta",
"encosto",
"esteves",
"orbital",
"corrido",
"parcele",
"ingerir",
"barroco",
"enterro",
"devolve",
"bovinos",
"ofereça",
"pontual",
"julieta",
"arraste",
"cercada",
"incerto",
"conecta",
"salgado",
"esgotos",
"tutelar",
"desista",
"exerceu",
"subiram",
"saudita",
"motivar",
"duvidar",
"martelo",
"impasse",
"desafia",
"pararam",
"repetiu",
"cantava",
"inchaço",
"cobrada",
"mosaico",
"alertou",
"racista",
"visitei",
"choques",
"country",
"arrasou",
"colares",
"comarca",
"vigília",
"ofender",
"novinho",
"noutras",
"lotação",
"arrasta",
"bastava",
"fratura",
"condado",
"vencida",
"andamos",
"mocinha",
"íntimos",
"paramos",
"mutação",
"deitada",
"parados",
"acabava",
"telinha",
"dosagem",
"consola",
"inércia",
"ressaca",
"associa",
"peneira",
"resumir",
"diverte",
"aprecia",
"aquando",
"remessa",
"zodíaco",
"cumprem",
"encosta",
"tornava",
"oitavas",
"indiana",
"desisti",
"fizeste",
"budismo",
"valiosa",
"gaúchos",
"dígitos",
"estudei",
"indução",
"alheios",
"secular",
"gemendo",
"empurra",
"amarrar",
"ursinho",
"disparo",
"fugiram",
"refazer",
"verbais",
"respira",
"velório",
"medindo",
"egípcio",
"rústico",
"surgido",
"irônico",
"místico",
"ofícios",
"agitado",
"injusta",
"jogados",
"vigorar",
"imbecil",
"ligeiro",
"baralho",
"buscava",
"vividas",
"derruba",
"abraçou",
"coreano",
"tóxicos",
"utilizo",
"roubada",
"híbrido",
"carimbo",
"delitos",
"estrias",
"arrasar",
"fizerem",
"expondo",
"viaduto",
"predial",
"rigidez",
"ganhava",
"girando",
"ligeira",
"creches",
"mataram",
"activos",
"exceder",
"empório",
"comemos",
"penhora",
"tsunami",
"notório",
"resisti",
"agendas",
"cruzada",
"dedicam",
"foguete",
"operado",
"bengala",
"lacunas",
"olhamos",
"cósmica",
"grossas",
"rodeado",
"afetiva",
"pompeia",
"platina",
"eduarda",
"damasco",
"empatou",
"seguirá",
"indique",
"noivado",
"delação",
"chorava",
"agitada",
"enxofre",
"embrião",
"caipira",
"sonhava",
"carecem",
"santana",
"genuína",
"fetiche",
"diverso",
"timidez",
"patrões",
"gentios",
"taxista",
"parando",
"esperou",
"minério",
"custeio",
"modesta",
"resolva",
"dragões",
"imersão",
"lojinha",
"termine",
"jacareí",
"salgada",
"acionar",
"infarto",
"retalho",
"obscuro",
"deveras",
"atrasar",
"atuante",
"elogiou",
"mostrei",
"motivou",
"ilumine",
"governa",
"cerrado",
"munição",
"reviver",
"raciais",
"modesto",
"estelar",
"rígidos",
"lectivo",
"lactose",
"afetivo",
"picadas",
"resgata",
"enrolar",
"estande",
"galileu",
"acordam",
"aplicou",
"pedágio",
"saberem",
"vividos",
"sentida",
"mudamos",
"punhado",
"táticas",
"agredir",
"resumos",
"reduzem",
"clichês",
"soberba",
"meditar",
"recarga",
"trágica",
"derrame",
"aceitei",
"dióxido",
"flechas",
"servida",
"sucedeu",
"linhaça",
"achavam",
"fofinho",
"judaico",
"designa",
"saudoso",
"pandora",
"toninho",
"correia",
"faustão",
"escapou",
"outorga",
"hackers",
"recesso",
"locutor",
"aparato",
"guardam",
"evitado",
"pedalar",
"coelhos",
"fundido",
"comento",
"falarem",
"estéreo",
"mostras",
"hortelã",
"induzir",
"trazida",
"criarem",
"lucidez",
"atacada",
"faciais",
"paródia",
"validar",
"gestora",
"polonês",
"rodízio",
"permuta",
"erguida",
"dispara",
"dominam",
"agreste",
"alicate",
"persona",
"têxteis",
"convido",
"seduzir",
"maligno",
"deparar",
"subsolo",
"resíduo",
"origami",
"machuca",
"morenas",
"machado",
"velozes",
"estrago",
"guincho",
"cardeal",
"segurou",
"bolacha",
"ampliou",
"cafeína",
"dividiu",
"jacinto",
"reunido",
"escutei",
"cruzado",
"artrite",
"frascos",
"possuam",
"baleias",
"canetas",
"germano",
"ateísmo",
"previne",
"genuíno",
"centavo",
"alheias",
"ocultos",
"cheguem",
"tocadas",
"singles",
"reparei",
"abordou",
"sílabas",
"guaraná",
"eternas",
"valadão",
"dirigia",
"favores",
"ensinos",
"calcula",
"absorve",
"remonta",
"maduras",
"mereceu",
"palpite",
"repúdio",
"ganhado",
"explode",
"atraído",
"atômica",
"recorre",
"esperta",
"brincou",
"divirta",
"assenta",
"adopção",
"cândida",
"adverte",
"imensos",
"carcaça",
"carreta",
"pelotão",
"tumulto",
"surreal",
"doutora",
"sonoros",
"pêssego",
"lasanha",
"gravura",
"cheiros",
"atentar",
"cubanos",
"pinguim",
"fabrico",
"divinas",
"nápoles",
"toxinas",
"neblina",
"piranha",
"cascata",
"acumule",
"referia",
"separam",
"alinhar",
"subitem",
"filiais",
"vigário",
"gateway",
"nuances",
"cozidos",
"entrego",
"pegaram",
"peladas",
"tirinha",
"robusto",
"ofereço",
"atalaia",
"impondo",
"release",
"alcione",
"separou",
"infanto",
"chapéus",
"expulsa",
"falasse",
"suponho",
"invadem",
"assinam",
"adesiva",
"bipolar",
"ameaçar",
"baianos",
"empates",
"burrice",
"camping",
"nomeada",
"propõem",
"garçons",
"revisto",
"escovas",
"glórias",
"toscana",
"dizerem",
"pensada",
"realçar",
"colonos",
"engorda",
"centena",
"pastéis",
"angular",
"sediada",
"chinelo",
"dizeres",
"regente",
"gritava",
"répteis",
"lógicas",
"máximas",
"reexame",
"turbina",
"culpada",
"dominou",
"prometi",
"sólidas",
"médiuns",
"recreio",
"elabora",
"estimar",
"revisar",
"compare",
"prendas",
"pestana",
"relatam",
"indício",
"arrisca",
"abacate",
"patrono",
"iríamos",
"cômodos",
"latente",
"remotos",
"penetra",
"esperem",
"casacos",
"acolheu",
"bombons",
"desenha",
"inventa",
"sonhado",
"eugênia",
"bússola",
"chuvoso",
"movendo",
"cartela",
"arantes",
"sortudo",
"juizado",
"pancada",
"teólogo",
"submete",
"lagarto",
"residem",
"maduros",
"liderou",
"mediana",
"cambial",
"isentos",
"previas",
"avental",
"palermo",
"simular",
"pegadas",
"chileno",
"aceites",
"frações",
"latinha",
"cebolas",
"erupção",
"apólice",
"cuidada",
"artesão",
"mascote",
"agravar",
"egípcia",
"divinos",
"detesto",
"jurista",
"nocivos",
"pedroso",
"modelar",
"escolho",
"prefixo",
"vampire",
"singela",
"curador",
"tiramos",
"ingênuo",
"colagem",
"embargo",
"furioso",
"bendita",
"incluía",
"biólogo",
"tocaram",
"picante",
"reboque",
"polegar",
"testada",
"budista",
"demorei",
"honrado",
"lançará",
"aditivo",
"melhore",
"malária",
"mandava",
"repetem",
"rodrigo",
"oriundo",
"algures",
"gatilho",
"picados",
"frescor",
"aranhas",
"orégano",
"aprendo",
"estéril",
"tiragem",
"diocese",
"coletor",
"espanta",
"abrigos",
"opostas",
"habitar",
"instale",
"abordam",
"aviador",
"mobília",
"esperma",
"engomar",
"utentes",
"movidos",
"cólicas",
"limitam",
"pantera",
"focando",
"craques",
"mendigo",
"minutes",
"tranças",
"cozinhe",
"falecer",
"lindona",
"omelete",
"lampião",
"apostou",
"ameaçam",
"métrica",
"ignição",
"admitem",
"remetem",
"mordida",
"residir",
"comecem",
"negando",
"origina",
"promova",
"ocorria",
"galpões",
"infusão",
"aversão",
"fartura",
"vasilha",
"fluvial",
"deleite",
"viverem",
"ocupava",
"recolhe",
"enganou",
"pedrosa",
"guardei",
"mazelas",
"cântico",
"máximos",
"estraga",
"foliões",
"milenar",
"facetas",
"punidos",
"agrário",
"dirigem",
"merenda",
"defendo",
"pesando",
"amêndoa",
"autoras",
"contras",
"atendam",
"babando",
"tomarem",
"reunida",
"exótico",
"recruta",
"escudos",
"puseram",
"tentara",
"lotados",
"incenso",
"chupeta",
"heresia",
"focados",
"acharem",
"perdoem",
"anabela",
"abrimos",
"quebram",
"facções",
"preferi",
"muralha",
"nazismo",
"deserta",
"pressas",
"fofocas",
"vômitos",
"rodeiam",
"cientes",
"pronome",
"troncos",
"erótica",
"proibiu",
"videira",
"azulejo",
"baseiam",
"casadas",
"diferem",
"colinas",
"rodeada",
"medidor",
"demitir",
"somados",
"invocar",
"expande",
"ontário",
"pontapé",
"esforça",
"banhado",
"inversa",
"cantado",
"cometem",
"genital",
"orgulha",
"coceira",
"fluente",
"mamando",
"náuseas",
"ingênua",
"pílulas",
"decreta",
"adorado",
"ajudava",
"ciganos",
"abaixar",
"varinha",
"levavam",
"mudaria",
"reparou",
"fixadas",
"binário",
"optaram",
"bourbon",
"alegada",
"cênicas",
"escocês",
"abatido",
"severas",
"ameaçou",
"avaliam",
"aprecio",
"prendem",
"penalty",
"deduzir",
"barbear",
"desloca",
"decurso",
"observo",
"lúcifer",
"votados",
"encargo",
"cordial",
"detecta",
"cárcere",
"terrena",
"gringos",
"demoram",
"afundar",
"clarear",
"cabível",
"esticar",
"gaiolas",
"igualar",
"buscado",
"filiado",
"jogamos",
"quixote",
"latinos",
"sofrera",
"convive",
"unissex",
"dedução",
"brincam",
"reunira",
"lidando",
"adultas",
"canário",
"intriga",
"gemidos",
"corrige",
"limitou",
"morrera",
"acessem",
"queixar",
"teorema",
"enseada",
"maridão",
"incesto",
"sedutor",
"cósmico",
"centers",
"recriar",
"alcides",
"pitanga",
"antemão",
"artéria",
"radares",
"exprime",
"franjas",
"palitos",
"nojento",
"hídrica",
"escorra",
"arranha",
"beltrão",
"titânio",
"empatar",
"cédulas",
"alegado",
"inimiga",
"alteram",
"placebo",
"corumbá",
"malucos",
"brilhos",
"costela",
"bosques",
"despejo",
"bizarra",
"burguês",
"crônico",
"fábulas",
"irritar",
"louvado",
"abusivo",
"lutaram",
"falarei",
"embalar",
"copiado",
"figurar",
"dilúvio",
"coreana",
"lavanda",
"monarca",
"lajeado",
"desejou",
"subidas",
"tintura",
"matinal",
"andavam",
"apurada",
"brilham",
"trilhar",
"rígidas",
"layouts",
"sombria",
"caótico",
"enrique",
"pregado",
"mutirão",
"dilemas",
"apostam",
"gincana",
"alargar",
"oncinha",
"façanha",
"balance",
"arrepio",
"examina",
"alcorão",
"pesagem",
"córdoba",
"assente",
"maneiro",
"devotos",
"estrito",
"adentro",
"vinhedo",
"escasso",
"rubrica",
"levanto",
"alugado",
"bárbaro",
"palheta",
"chifres",
"obrigam",
"fumando",
"seguiam",
"aquiles",
"destruí",
"palmito",
"negocia",
"gálatas",
"moravam",
"flagras",
"traidor",
"escutas",
"pântano",
"manterá",
"negrito",
"robusta",
"rústica",
"reversa",
"afeição",
"rezando",
"boletos",
"fumante",
"socando",
"tudinho",
"arejado",
"tapajós",
"iniciei",
"tapioca",
"fluidez",
"dobrado",
"poetisa",
"aliadas",
"picanha",
"serviam",
"canindé",
"oriunda",
"cremosa",
"operada",
"pescado",
"cortejo",
"comoção",
"modular",
"traindo",
"grossos",
"vomitar",
"fontana",
"temores",
"redator",
"desviou",
"guardou",
"tirania",
"oráculo",
"paterna",
"elimine",
"confiam",
"torpedo",
"pagaram",
"afastam",
"alongar",
"apagada",
"planeje",
"divisor",
"entulho",
"ocorram",
"estigma",
"recusam",
"ervilha",
"bristol",
"divisas",
"etárias",
"púrpura",
"premiar",
"celeiro",
"restava",
"frangos",
"anjinho",
"enganam",
"optamos",
"coitada",
"agradam",
"pólvora",
"formoso",
"aegypti",
"bálsamo",
"erudita",
"levasse",
"grampos",
"represa",
"lógicos",
"pastora",
"registe",
"barraco",
"anciãos",
"solidez",
"fofinha",
"remotas",
"ganchos",
"novatos",
"corante",
"escovar",
"crescia",
"amarras",
"falácia",
"marques",
"encarte",
"acepção",
"esgotar",
"scanner",
"temendo",
"sicília",
"parking",
"casarão",
"malícia",
"preside",
"gelados",
"castela",
"quadris",
"fileira",
"traziam",
"cheirar",
"optando",
"pilotar",
"pentear",
"recebam",
"lavando",
"estacas",
"apanhou",
"queimou",
"sarampo",
"notória",
"aflitos",
"supondo",
"cluster",
"biópsia",
"florido",
"ignoram",
"tiveres",
"tolerar",
"firmino",
"bordada",
"travada",
"deletar",
"acabada",
"reflita",
"chicote",
"viveiro",
"alcunha",
"forrada",
"treinou",
"bêbados",
"tenista",
"produza",
"cubatão",
"envolto",
"decerto",
"ilícita",
"sentiam",
"banidos",
"remorso",
"síndico",
"canecas",
"quatros",
"locução",
"retorne",
"obscura",
"caveira",
"vicioso",
"juvenal",
"juninas",
"conchas",
"travado",
"viáveis",
"despede",
"aprovam",
"realeza",
"superam",
"carpete",
"abalada",
"recibos",
"furiosa",
"arrumei",
"refogue",
"avançam",
"tramita",
"babados",
"refluxo",
"casaram",
"confiou",
"bobeira",
"brilhou",
"feeling",
"itajuba",
"veredas",
"filhota",
"driblar",
"chocada",
"cancela",
"francos",
"tóxicas",
"espólio",
"macacão",
"geraram",
"suceder",
"tutores",
"durável",
"vampira",
"arrumou",
"sonhada",
"boicote",
"gasóleo",
"geniais",
"agarrou",
"festivo",
"autista",
"abusiva",
"festeja",
"importe",
"chocado",
"puxador",
"excerto",
"gozadas",
"acertei",
"escorre",
"étnicos",
"abençoa",
"saberão",
"escolta",
"hebreus",
"efetuou",
"edredom",
"festiva",
"canalha",
"soquete",
"faturar",
"sulfato",
"palhoça",
"suporto",
"destaco",
"firmada",
"repolho",
"intacta",
"fininho",
"arrisco",
"mariana",
"busquei",
"sazonal",
"gaivota",
"chávena",
"levarem",
"neutros",
"peruano",
"electro",
"ofertar",
"singelo",
"fervura",
"faturas",
"narrado",
"custava",
"escobar",
"julgava",
"carroça",
"pelagem",
"imprime",
"vértice",
"barroca",
"bateram",
"comitês",
"paulina",
"levarão",
"bunduda",
"atômico",
"liberte",
"pilatos",
"caberia",
"cassete",
"fixando",
"soprano",
"acervos",
"docinho",
"cloreto",
"aterros",
"mediano",
"deparou",
"contate",
"exótica",
"andaram",
"altares",
"demasia",
"barrado",
"comício",
"freiras",
"púlpito",
"embutir",
"varizes",
"batidos",
"invista",
"apertou",
"passeia",
"sortear",
"alíneas",
"canhões",
"erguido",
"armador",
"poderás",
"custara",
"abortos",
"prólogo",
"sensato",
"agravos",
"reveste",
"ousadas",
"cassino",
"mutante",
"felinos",
"todinha",
"neutras",
"argolas",
"focinho",
"anuário",
"lagosta",
"motoras",
"azeredo",
"listado",
"boliche",
"secções",
"permeia",
"intento",
"tubular",
"acendeu",
"bondoso",
"bravura",
"escutou",
"separei",
"calúnia",
"enxague",
"guiados",
"espessa",
"puzzles",
"taquara",
"étnicas",
"matança",
"cassado",
"deslize",
"encarou",
"tulipas",
"atenuar",
"casuais",
"enigmas",
"enviara",
"trocada",
"ativada",
"cinismo",
"terrier",
"maquiar",
"revelia",
"impedia",
"anexado",
"prainha",
"teimoso",
"pascoal",
"forneça",
"cozidas",
"anulado",
"morcego",
"chassis",
"baleiro",
"gangues",
"solúvel",
"morgado",
"fascina",
"luxúria",
"acetato",
"desisto",
"cultiva",
"feições",
"vidente",
"balcões",
"trivial",
"pagaria",
"gilbert",
"faturou",
"pautada",
"peeling",
"receios",
"factura",
"hipnose",
"precede",
"errando",
"nitidez",
"carrego",
"túmulos",
"bobinas",
"braille",
"vidinha",
"sortuda",
"arranja",
"libanês",
"mesinha",
"audácia",
"emergir",
"viajava",
"escassa",
"carlota",
"maligna",
"lúdicas",
"emérito",
"liberto",
"reflexa",
"sumiram",
"padeiro",
"inspire",
"incerta",
"daremos",
"zombies",
"verbete",
"sífilis",
"devorar",
"coleira",
"insulto",
"resumiu",
"erudito",
"marcial",
"confins",
"silício",
"rastros",
"salutar",
"manager",
"rítmica",
"escamas",
"honrosa",
"exaltar",
"estrita",
"diminua",
"facadas",
"cabines",
"ouvirem",
"inchada",
"esôfago",
"litígio",
"iemanjá",
"enganos",
"imbecis",
"sermões",
"veleiro",
"bíblias",
"embarca",
"defenda",
"catorze",
"náutico",
"obreiro",
"repousa",
"tomador",
"hilário",
"diverti",
"brechas",
"ousados",
"pautado",
"alertam",
"incolor",
"prévios",
"detalha",
"batente",
"retirei",
"quartzo",
"esmagar",
"estavas",
"botando",
"potinho",
"quebras",
"moramos",
"acessam",
"busquem",
"cúbicos",
"gestões",
"caçamba",
"vinheta",
"atendia",
"preveem",
"pareçam",
"camelos",
"ostenta",
"equipar",
"retomou",
"plantou",
"tequila",
"moinhos",
"tontura",
"chuvosa",
"ovários",
"enfermo",
"jubileu",
"recanto",
"hidrata",
"aprecie",
"híbrida",
"assíduo",
"elétron",
"prestem",
"poderio",
"abalado",
"planear",
"repulsa",
"assados",
"sangrar",
"flutuar",
"chilena",
"caótica",
"algemas",
"respiro",
"olharam",
"somaram",
"ensaiar",
"rodeios",
"súplica",
"moscada",
"estrofe",
"esquilo",
"inferir",
"hectare",
"cortiça",
"violões",
"despeje",
"olhavam",
"multado",
"combine",
"espiões",
"acentos",
"notação",
"mostrem",
"arestas",
"remeter",
"cuidava",
"fidalgo",
"lojista",
"arguido",
"forrado",
"vaidosa",
"custear",
"gengiva",
"corriam",
"polares",
"matador",
"safados",
"cedendo",
"úlceras",
"trancar",
"comeram",
"lavável",
"cratera",
"zelador",
"dobrada",
"figuram",
"mesmice",
"tomilho",
"coentro",
"acentua",
"filmada",
"notamos",
"cativar",
"perdoou",
"regista",
"mocinho",
"amianto",
"resulte",
"romanas",
"paquera",
"catalão",
"atrasou",
"virilha",
"invasor",
"dízimos",
"enganei",
"vulcões",
"avistar",
"acampar",
"escutam",
"férteis",
"obtiver",
"faminto",
"trâmite",
"suponha",
"atuamos",
"adjunta",
"talhada",
"votando",
"apanhei",
"atuarem",
"malvado",
"envolta",
"cutânea",
"abdicar",
"espesso",
"exercia",
"psicose",
"patinho",
"derrete",
"rolagem",
"pondera",
"devesse",
"açougue",
"camarim",
"alfenas",
"céticos",
"redigir",
"cordões",
"menções",
"andebol",
"legível",
"estouro",
"tocados",
"regulam",
"cerejas",
"incidir",
"backups",
"olímpio",
"adaptam",
"injúria",
"sediado",
"ribeira",
"barnabé",
"juntada",
"medidos",
"retiram",
"ligaram",
"cadeado",
"húngaro",
"desejei",
"exilado",
"cartola",
"coletes",
"conceda",
"jumento",
"redutor",
"cantina",
"teimosa",
"stricto",
"exporta",
"embates",
"suscita",
"petisco",
"gauchão",
"pregava",
"justice",
"heróico",
"nadador",
"marcava",
"escroto",
"rabinho",
"inchado",
"palmela",
"ouvidor",
"flashes",
"tocamos",
"rasgado",
"lutamos",
"forjado",
"presume",
"defunto",
"debatem",
"falhado",
"geladas",
"cambada",
"mamilos",
"abaixou",
"granado",
"brinque",
"aceitem",
"bolívar",
"âmbitos",
"anotado",
"abortar",
"atuaram",
"fusível",
"sensata",
"nadando",
"digerir",
"plácido",
"seringa",
"nádegas",
"residia",
"bouquet",
"venture",
"leixões",
"camurça",
"provada",
"fosfato",
"detesta",
"reparem",
"pisando",
"peruana",
"tropeço",
"chatice",
"adaptou",
"leasing",
"fármaco",
"profano",
"stevens",
"íntegro",
"fruição",
"maquete",
"efêmero",
"amnésia",
"jesuíta",
"calados",
"luxuosa",
"cumpram",
"lideram",
"invento",
"pêndulo",
"escapam",
"chaminé",
"subimos",
"renovou",
"moldado",
"narinas",
"lacinho",
"avulsos",
"torrado",
"dotadas",
"surgira",
"acresce",
"decimal",
"retidão",
"mixagem",
"súditos",
"honrada",
"guiando",
"aplauso",
"trilhão",
"beliche",
"tenório",
"cromado",
"colados",
"oclusão",
"tomasse",
"astúcia",
"alferes",
"ignorou",
"listada",
"afectar",
"insisto",
"relvado",
"açafrão",
"causava",
"soberbo",
"focadas",
"abrirem",
"brindar",
"invicto",
"guarita",
"repassa",
"malucas",
"besouro",
"afogado",
"pontuar",
"venenos",
"alabama",
"moradas",
"vincula",
"guiadas",
"levitas",
"lanchas",
"rodagem",
"análogo",
"comprem",
"arautos",
"rodados",
"pijamas",
"omissos",
"maiorca",
"viscose",
"autuado",
"encaram",
"reverso",
"pródigo",
"violado",
"cativos",
"derrama",
"falaria",
"comedor",
"chamara",
"resista",
"guardiã",
"faltara",
"massiva",
"errante",
"evitada",
"zumbido",
"banhada",
"paletes",
"decolar",
"heroica",
"edícula",
"pedirem",
"dissera",
"desejem",
"notaram",
"detonar",
"freguês",
"galdino",
"desleal",
"rainhas",
"ligamos",
"autarca",
"redimir",
"liberam",
"parasse",
"bezerro",
"agrupar",
"tatuada",
"asneira",
"perdura",
"sairmos",
"dialeto",
"atentas",
"activar",
"engajar",
"proceda",
"engoliu",
"estanho",
"todinho",
"lagarta",
"raquete",
"propaga",
"folhado",
"poupado",
"netbook",
"pipocas",
"panteão",
"mudarem",
"fixador",
"gaúchas",
"metades",
"repetia",
"concebe",
"dedinho",
"destrua",
"cabedal",
"galícia",
"ópticas",
"camacho",
"melissa",
"campina",
"ferindo",
"sonetos",
"viajado",
"abafado",
"narrada",
"vagando",
"voucher",
"vapores",
"sucedem",
"triplex",
"lataria",
"chacina",
"bicolor",
"lirismo",
"permito",
"adornos",
"larguei",
"magoado",
"resinas",
"cedinho",
"aliando",
"atestar",
"tangará",
"pontuou",
"reitera",
"serrana",
"curtido",
"legados",
"sanfona",
"bonitão",
"aspetos",
"arbusto",
"lavados",
"nascera",
"nódulos",
"milícia",
"epopéia",
"resumem",
"erramos",
"pezinho",
"sacudir",
"marcara",
"paterno",
"vivente",
"viseira",
"enxerto",
"anulada",
"fazeres",
"cartoon",
"brigado",
"humilha",
"acomete",
"levarei",
"fricção",
"exigira",
"modinha",
"dormido",
"dejetos",
"finados",
"tecendo",
"sacadas",
"espanca",
"irônica",
"objeção",
"viragem",
"irritou",
"prantos",
"coxinha",
"somadas",
"exausto",
"latinas",
"diluído",
"cesárea",
"ópticos",
"brutais",
"aracati",
"hídrico",
"viradas",
"corrigi",
"designs",
"resisto",
"lacrada",
"toalete",
"esgotou",
"deparam",
"taquari",
"samurai",
"colhido",
"tabuada",
"asfixia",
"emergem",
"papinha",
"estenda",
"calções",
"suborno",
"cítrico",
"afixado",
"outeiro",
"mordomo",
"afundou",
"tocavam",
"marquei",
"eletivo",
"manchar",
"lisinha",
"rosinha",
"falange",
"network",
"minerva",
"fragoso",
"taninos",
"evoluem",
"garimpo",
"enredos",
"amassar",
"degradê",
"soltura",
"sufocar",
"nortear",
"vacinar",
"ditames",
"tímidos",
"avulsas",
"herdado",
"batizar",
"pórtico",
"demitiu",
"revogar",
"mantive",
"ladinho",
"moletom",
"macumba",
"derivam",
"tadinho",
"atraída",
"esmolas",
"reprise",
"baianas",
"porquês",
"casando",
"revisor",
"delegar",
"infiéis",
"caracol",
"agregam",
"cedidos",
"polenta",
"viessem",
"órbitas",
"sequela",
"utópico",
"rebotes",
"amuleto",
"cátedra",
"tendões",
"saveiro",
"sumária",
"minhoca",
"enxugar",
"itálico",
"mascavo",
"batedor",
"declaro",
"quebrei",
"pagarem",
"queixam",
"reuniam",
"ferozes",
"causara",
"refogar",
"nocivas",
"nítidas",
"mirante",
"inativo",
"tirados",
"esfrega",
"efêmera",
"birigui",
"campeãs",
"lavadas",
"gaviões",
"chupada",
"vikings",
"escriba",
"povinho",
"granizo",
"tempera",
"dotação",
"zangado",
"faladas",
"enteada",
"celsius",
"torrada",
"donzela",
"colméia",
"trepada",
"coroado",
"abraçam",
"uterino",
"receção",
"fechava",
"abrigam",
"utopias",
"análoga",
"indagar",
"desliza",
"severos",
"esbarra",
"amargas",
"homilia",
"pelados",
"avarias",
"revendo",
"mucosas",
"núpcias",
"tentada",
"buritis",
"folhada",
"ampliam",
"consuma",
"volátil",
"tribais",
"ratinho",
"sapiens",
"entrave",
"atirado",
"usarmos",
"jogavam",
"timbres",
"deposto",
"cateter",
"holding",
"matizes",
"sentava",
"trovões",
"pregões",
"pescada",
"dividia",
"examine",
"negaram",
"pedidas",
"espácio",
"caboclo",
"taberna",
"tablete",
"exegese",
"movidas",
"benigno",
"conecte",
"delgado",
"legiões",
"desfila",
"capelas",
"tombado",
"fúnebre",
"caiaque",
"navalha",
"detidas",
"armênio",
"tomavam",
"aspirar",
"assinei",
"coletas",
"divirto",
"abracei",
"isentas",
"bocados",
"laringe",
"uterina",
"lésbico",
"regatas",
"escória",
"colhida",
"tirarem",
"atestam",
"exausta",
"gargalo",
"fissura",
"queimam",
"jogarem",
"abismos",
"cogitar",
"esterco",
"curados",
"lombada",
"perucas",
"sibéria",
"marrons",
"vencera",
"saberei",
"assadas",
"repudia",
"detinha",
"nadinha",
"fluindo",
"bebemos",
"jangada",
"encarna",
"fritura",
"rasgada",
"injetar",
"flagelo",
"cáritas",
"inserem",
"grandão",
"deboche",
"errônea",
"arcaico",
"chiques",
"caçando",
"amáveis",
"apaguei",
"reclame",
"abridor",
"charuto",
"pistões",
"ameixas",
"charges",
"hospeda",
"niqueis",
"indagou",
"usuária",
"mansões",
"minibar",
"iceberg",
"prezada",
"ajeitar",
"fibrose",
"pegador",
"retidos",
"distrai",
"segurei",
"brownie",
"secando",
"diácono",
"devolva",
"inícios",
"acharia",
"mediada",
"ímpares",
"pointer",
"côncavo",
"escutem",
"cimeira",
"viriato",
"fininha",
"inclusa",
"cometas",
"moreira",
"cacique",
"iguaria",
"estejas",
"corrija",
"desabou",
"retardo",
"produzi",
"nojenta",
"feijões",
"doentio",
"matagal",
"peitões",
"leciona",
"technos",
"copinho",
"coringa",
"incidem",
"casório",
"vendiam",
"obterem",
"beijado",
"ideário",
"saudosa",
"esconda",
"atrofia",
"hesitou",
"abusada",
"carnais",
"eclosão",
"colando",
"locador",
"tomaria",
"sereias",
"moldada",
"pararem",
"arrasto",
"falados",
"taverna",
"castiga",
"miragem",
"tristão",
"marisco",
"coerção",
"flagrou",
"avistou",
"assídua",
"apoiava",
"reativa",
"esperas",
"canhoto",
"rolante",
"garrido",
"estirpe",
"admiram",
"nalguns",
"regidos",
"avareza",
"excluem",
"mesclar",
"influir",
"adverso",
"prótons",
"fortune",
"comerem",
"reporta",
"sucinta",
"convide",
"atípico",
"nanquim",
"cliquem",
"telonas",
"ardendo",
"western",
"exigiam",
"piolhos",
"campelo",
"batuque",
"caretas",
"coladas",
"álgebra",
"dançava",
"irradia",
"starter",
"hereges",
"batizou",
"baleada",
"arrocha",
"bióloga",
"acionou",
"viveria",
"colírio",
"cócegas",
"perdoes",
"sofriam",
"táctica",
"peludos",
"rendido",
"fanzine",
"conduzi",
"tirasse",
"pélvica",
"andarem",
"enterra",
"peninha",
"abusado",
"cresçam",
"adições",
"tomemos",
"nocaute",
"resolvo",
"flanela",
"temível",
"babacas",
"maníaco",
"ventosa",
"olharem",
"devassa",
"rebater",
"feitura",
"derreta",
"maxilar",
"agravou",
"caminhe",
"atritos",
"obtemos",
"amparar",
"presumo",
"motoboy",
"dádivas",
"corujas",
"beijoca",
"cumpria",
"bonança",
"tocador",
"centrar",
"largado",
"escores",
"dirimir",
"informo",
"nupcial",
"leonino",
"anedota",
"lástima",
"treinam",
"desabar",
"filetes",
"expirar",
"trouxas",
"refutar",
"formava",
"caixote",
"organza",
"estojos",
"espelha",
"lotadas",
"estimam",
"imprima",
"navegue",
"soutien",
"íngreme",
"doentia",
"cativou",
"jazidas",
"sangria",
"austral",
"espumas",
"estevam",
"rompido",
"eulália",
"anilhas",
"atuador",
"afetara",
"posando",
"tadinha",
"guinada",
"tatuado",
"haverão",
"otomano",
"chatear",
"anotada",
"cabides",
"rajadas",
"pleitos",
"mudasse",
"enxergo",
"paletas",
"cirrose",
"adianto",
"decotes",
"captada",
"pediria",
"adversa",
"neurose",
"returno",
"salvara",
"surgiam",
"adiando",
"cantiga",
"empenha",
"madruga",
"solista",
"quantum",
"termais",
"lindeza",
"buscara",
"casinos",
"ciclone",
"impunha",
"notando",
"lavrado",
"caxambu",
"agrediu",
"bacelar",
"conterá",
"manejar",
"adoecer",
"ensinei",
"glúteos",
"mitigar",
"bexigas",
"esquiva",
"hesitar",
"falemos",
"herdada",
"captado",
"socorre",
"adorada",
"estalar",
"rebateu",
"empurre",
"faltado",
"assuste",
"dormiam",
"palomar",
"botinha",
"vaidoso",
"olivais",
"afastei",
"emergiu",
"conduza",
"riachos",
"flexões",
"soluços",
"caetano",
"julguem",
"adotivo",
"esboçar",
"esgrima",
"abrisse",
"sumindo",
"pedisse",
"fragata",
"ingrata",
"drogado",
"livreto",
"suplico",
"strings",
"pitadas",
"beirute",
"ciranda",
"esfinge",
"respire",
"bandida",
"empolga",
"lácteos",
"tilápia",
"afigura",
"náutica",
"pagador",
"barbudo",
"vacaria",
"pregada",
"espetos",
"pitacos",
"expedir",
"rechear",
"valeram",
"vivesse",
"ofereci",
"acetona",
"ninhada",
"abriria",
"renasce",
"brumado",
"renovam",
"mórbida",
"adesões",
"avessas",
"camiões",
"décimos",
"mataria",
"munidos",
"necrose",
"arquiva",
"congela",
"tweeter",
"traçada",
"emotiva",
"pensara",
"paqueta",
"exerçam",
"binária",
"azulado",
"quietos",
"lembras",
"inclina",
"maisena",
"implora",
"abrupta",
"duendes",
"puxados",
"escondo",
"apareço",
"coleiro",
"lutavam",
"colação",
"reposta",
"aguente",
"afirmei",
"benigna",
"meteoro",
"restara",
"tratara",
"trocava",
"declive",
"correra",
"bruscas",
"emulsão",
"filiada",
"primado",
"puderes",
"chapada",
"pintora",
"tocarem",
"delator",
"estufas",
"estores",
"prestei",
"discuta",
"amargor",
"trípoli",
"voadora",
"ajuizou",
"piccolo",
"malvada",
"bagulho",
"exibira",
"eleitas",
"surgida",
"etílico",
"macabro",
"poliana",
"alverca",
"carlito",
"linfoma",
"rotunda",
"reabrir",
"modismo",
"votarem",
"tiraria",
"erraram",
"enumera",
"alterna",
"smoking",
"equinos",
"recaída",
"otários",
"sucatas",
"irritam",
"botijão",
"recifes",
"ambígua",
"concisa",
"gadelha",
"permiti",
"talismã",
"bigodes",
"slogans",
"assumam",
"inverte",
"viramos",
"alugada",
"crioulo",
"prurido",
"povoada",
"vitrais",
"imorais",
"caninos",
"espigão",
"estimou",
"centeio",
"dedique",
"alegram",
"colidir",
"geólogo",
"biguaçu",
"pegação",
"plotter",
"culmina",
"inertes",
"artrose",
"lançava",
"vivamos",
"beijava",
"tubinho",
"paravam",
"reverte",
"binômio",
"erratas",
"soleira",
"fomenta",
"poluído",
"guajará",
"toscano",
"agridem",
"arteira",
"magnata",
"marchar",
"amadeus",
"anexada",
"datados",
"mandara",
"aflorar",
"nudismo",
"gestual",
"peludas",
"scooter",
"raspada",
"folhear",
"peçamos",
"ocupara",
"grudado",
"epílogo",
"tremula",
"copiada",
"edifica",
"boiando",
"catarse",
"angelim",
"algozes",
"pincele",
"pegaria",
"piedoso",
"topázio",
"frenesi",
"exponha",
"incisão",
"rochosa",
"desamor",
"vitrola",
"agruras",
"bisnaga",
"ditados",
"citamos",
"insista",
"provida",
"figures",
"indolor",
"chacota",
"tiveste",
"endosso",
"adiposo",
"insinua",
"acelere",
"emotivo",
"sedento",
"otimiza",
"broches",
"glacial",
"achasse",
"difusor",
"fonemas",
"incitar",
"estarás",
"rebolar",
"speaker",
"estoura",
"desmame",
"espigas",
"bacabal",
"culposo",
"dependa",
"bulhões",
"incumbe",
"biliões",
"lesados",
"magoada",
"violada",
"cânones",
"instrui",
"roubava",
"atingia",
"muletas",
"cagando",
"bulimia",
"óscares",
"diluída",
"ensejar",
"aportes",
"aroeira",
"mandada",
"julgara",
"abutres",
"catraca",
"acordem",
"juntado",
"escrota",
"indigno",
"colidiu",
"granola",
"losango",
"ingrato",
"beijada",
"ultraje",
"ouvisse",
"dentada",
"ampolas",
"afligem",
"atinjam",
"almejam",
"roupões",
"capinha",
"enfiado",
"vitório",
"crespos",
"ginasta",
"pupilas",
"recitar",
"sediara",
"diabete",
"cambuci",
"afinado",
"tarados",
"gláucia",
"frestas",
"imploro",
"recital",
"tornada",
"votadas",
"seguram",
"reclamo",
"míseros",
"tutoria",
"arcaica",
"acarajé",
"escalou",
"morfina",
"atuavam",
"verdura",
"fraudar",
"triênio",
"neurais",
"miminho",
"chupava",
"invicta",
"emendar",
"onerosa",
"estopim",
"abrigou",
"contrai",
"havemos",
"escondi",
"leonina",
"cotados",
"padecer",
"fecunda",
"arejada",
"alojado",
"mantras",
"atípica",
"calcule",
"insight",
"pimento",
"infante",
"evitara",
"julguei",
"ancoras",
"pomares",
"acerola",
"garotão",
"traduzi",
"alusiva",
"roberta",
"cabanas",
"maresia",
"folgado",
"fotinho",
"peralta",
"esquiar",
"ambíguo",
"maizena",
"fuçando",
"gigabit",
"plantei",
"graduou",
"arrepia",
"impeçam",
"cíclico",
"desenhe",
"pulinho",
"nitrato",
"optarem",
"tijucas",
"frutose",
"apertei",
"botecos",
"refinar",
"sedação",
"bichano",
"motivam",
"difusos",
"amargos",
"morriam",
"estrado",
"relevos",
"cobaias",
"abóbada",
"mundana",
"poeiras",
"marasmo",
"licores",
"concluo",
"eternal",
"prensas",
"ficções",
"queiras",
"nômades",
"solário",
"gôndola",
"impacta",
"inseriu",
"esvazia",
"enjoada",
"ofendeu",
"riscado",
"afiadas",
"trincas",
"leandra",
"remixes",
"factual",
"maringá",
"exagera",
"imersos",
"forjada",
"tralhas",
"assíria",
"retoque",
"tiranos",
"proibia",
"declare",
"cíclica",
"estrato",
"massivo",
"insanas",
"ementas",
"pagavam",
"triatlo",
"detento",
"coroada",
"lousada",
"biótipo",
"induzem",
"emigrar",
"batemos",
"hippies",
"cabrito",
"esporos",
"abatida",
"enfiada",
"forward",
"bananal",
"antever",
"feudais",
"estribo",
"conluio",
"dialoga",
"ameniza",
"frutado",
"netinho",
"cutâneo",
"cedidas",
"suspira",
"duraram",
"instiga",
"romaria",
"olhasse",
"búlgaro",
"mafioso",
"selagem",
"adutora",
"hilária",
"ketchup",
"tocasse",
"obrigue",
"defendi",
"lidamos",
"pasquim",
"machine",
"flautas",
"cuidara",
"desonra",
"coçando",
"rotular",
"sertões",
"varetas",
"mordido",
"profana",
"diálise",
"tamanco",
"viscosa",
"azulada",
"imprimi",
"topless",
"medroso",
"carnuda",
"alienar",
"bastara",
"abrasão",
"desmaio",
"mossoró",
"expirou",
"admitia",
"viverão",
"formara",
"oneroso",
"tubetes",
"vazados",
"macetes",
"ourique",
"selênio",
"imaturo",
"cômodas",
"virados",
"cartago",
"relance",
"perecer",
"obsceno",
"icônico",
"cadarço",
"nítrico",
"esforce",
"gestalt",
"sorriem",
"comunas",
"afinada",
"cliquei",
"demolir",
"jaguaré",
"vacilou",
"compota",
"gorjeta",
"cretino",
"amperes",
"snooker",
"trópico",
"falésia",
"consumi",
"ajuizar",
"começos",
"acalmou",
"excluiu",
"curaçao",
"nefasta",
"ironias",
"pacotão",
"lustres",
"fadinha",
"extraia",
"triplos",
"utópica",
"oitavos",
"curando",
"pecaram",
"hamster",
"operava",
"indigna",
"acendem",
"cochilo",
"andaime",
"trompas",
"realizo",
"elogiam",
"oblíqua",
"duraria",
"conjuga",
"genitor",
"bastões",
"carecas",
"tropeça",
"marmita",
"divinal",
"proíbem",
"límpida",
"dribles",
"egresso",
"cortava",
"esbanja",
"perenes",
"namoros",
"contran",
"impunes",
"assalta",
"mórbido",
"pagarão",
"trapaça",
"criaria",
"ecstasy",
"sugando",
"finitos",
"queixou",
"doppler",
"danosos",
"buscada",
"intruso",
"raposas",
"penúria",
"estofos",
"fariseu",
"pendura",
"taxação",
"fivelas",
"mestria",
"límpido",
"cobrava",
"alcácer",
"geridos",
"fadista",
"solenes",
"refeito",
"anfíbio",
"quitado",
"antares",
"snorkel",
"blister",
"noitada",
"oleosos",
"despido",
"piedosa",
"netinha",
"fiambre",
"mundano",
"tomarão",
"xoxotas",
"acertam",
"oleosas",
"astecas",
"andador",
"pesares",
"cottage",
"enfeita",
"vitinho",
"vacilar",
"expanda",
"jusante",
"vazando",
"pomadas",
"descido",
"baseava",
"guaxupé",
"abarcar",
"abundam",
"sugeria",
"associe",
"ordenha",
"molares",
"rochedo",
"galante",
"exaltou",
"libéria",
"habitou",
"maciços",
"adotiva",
"discuti",
"felícia",
"bicudos",
"eremita",
"impuros",
"sideral",
"bondosa",
"farpado",
"pluvial",
"proezas",
"abolida",
"câmbios",
"jogaria",
"carícia",
"espiada",
"reinava",
"partira",
"insanos",
"guardem",
"pegasse",
"chefias",
"grelhas",
"magreza",
"mandala",
"sobrava",
"pagasse",
"comoveu",
"mirando",
"tímidas",
"delirar",
"belinha",
"beldade",
"doleiro",
"egrégio",
"faíscas",
"inativa",
"assumia",
"licitas",
"votaria",
"enteado",
"avistei",
"durinho",
"cegonha",
"admirei",
"fechara",
"hélices",
"almejar",
"adaptei",
"trancou",
"tombada",
"buzinas",
"apronta",
"oleiros",
"guarida",
"comeria",
"alberga",
"weekend",
"estudem",
"profere",
"generis",
"furtada",
"retocar",
"embasar",
"beirada",
"afastem",
"incluam",
"lazeres",
"capotou",
"retarda",
"avisada",
"nórdico",
"metidos",
"bandana",
"canguru",
"encarei",
"cidadãs",
"desviam",
"nefasto",
"dormida",
"arromba",
"ferrara",
"talasso",
"arábica",
"aquecem",
"charles",
"azeméis",
"ourives",
"desejas",
"bocaina",
"imundos",
"indutor",
"baderna",
"recuado",
"pegarem",
"supunha",
"virarem",
"bueiros",
"conteve",
"póstuma",
"sulfite",
"decidam",
"vitimou",
"pedrada",
"subirem",
"medonho",
"poluída",
"evacuar",
"repelir",
"pintava",
"deságua",
"volúpia",
"rolaram",
"macabra",
"chateia",
"elabore",
"encalço",
"desmaia",
"alvarás",
"artesãs",
"matarem",
"lavrada",
"sorteia",
"ibérica",
"acolhem",
"euzinha",
"exclama",
"audível",
"fofuras",
"afiados",
"discada",
"advinda",
"assírio",
"mestiço",
"vidraça",
"pardais",
"irreais",
"definia",
"duquesa",
"querias",
"quereis",
"clicado",
"flancos",
"cockpit",
"envolvi",
"jukebox",
"atentem",
"postula",
"báltico",
"reitora",
"focagem",
"agiliza",
"sacudiu",
"possuiu",
"varejão",
"enjoado",
"moderar",
"mutável",
"corneta",
"esgotam",
"rodinha",
"jacarés",
"mamaria",
"matilha",
"aplaude",
"eólicas",
"apressa",
"lotaram",
"perdoai",
"numeral",
"demente",
"gláucio",
"clérigo",
"aderido",
"removeu",
"geraria",
"negados",
"civismo",
"faminta",
"magrela",
"prendia",
"ascende",
"varíola",
"adotara",
"matasse",
"retiros",
"obedeça",
"ínterim",
"borrada",
"pamonha",
"agarrei",
"fatiado",
"curadas",
"tamoios",
"tártaro",
"apoiara",
"trancos",
"apertos",
"narizes",
"tesudas",
"durarem",
"séptico",
"namorou",
"bisturi",
"vigiada",
"omitido",
"mediado",
"colchas",
"sedenta",
"azulões",
"adentra",
"alegrou",
"chorado",
"entretê",
"almocei",
"estares",
"gametas",
"include",
"andante",
"diremos",
"incutir",
"maninha",
"sopinha",
"norteia",
"deferiu",
"arcanos",
"cômputo",
"derrube",
"conduto",
"escusos",
"rafting",
"tapinha",
"gastava",
"aurélia",
"barrada",
"premiou",
"ironiza",
"perdiam",
"altivez",
"pêsames",
"emendou",
"gotinha",
"letivos",
"pantone",
"culatra",
"aluguei",
"esposos",
"traduza",
"trouxer",
"caloria",
"revogou",
"apegado",
"rolinho",
"assolam",
"embuste",
"travões",
"espirro",
"alusões",
"ovinhos",
"despeço",
"aplacar",
"humores",
"póstumo",
"abrirão",
"ungidos",
"aludido",
"encolhe",
"navegam",
"atuarão",
"derivar",
"salinha",
"desatar",
"biscate",
"alusivo",
"palmada",
"aimorés",
"divulgo",
"surdina",
"tenhais",
"enfarte",
"aprovei",
"lúdicos",
"meteram",
"fretado",
"exagere",
"fronhas",
"debateu",
"bailado",
"petismo",
"vasinho",
"ardósia",
"lobista",
"astrais",
"frustra",
"torcido",
"sudário",
"arsênio",
"festejo",
"rebento",
"rosália",
"pirates",
"casamos",
"alocado",
"angelus",
"palmira",
"recluso",
"apelado",
"lutarem",
"nórdica",
"cromada",
"anseiam",
"prometa",
"simplex",
"decolou",
"infames",
"fecundo",
"nenhuns",
"cobriam",
"acessou",
"goianos",
"risonho",
"royalty",
"montana",
"haverem",
"reprova",
"desligo",
"absinto",
"velhote",
"criasse",
"cigarra",
"palabra",
"advindo",
"datação",
"crispim",
"suaviza",
"pujança",
"suicide",
"caçadas",
"cobalto",
"encerro",
"despedi",
"paninho",
"constou",
"leproso",
"echarpe",
"ouviste",
"temente",
"lapidar",
"atônito",
"bordéus",
"citaram",
"girafas",
"apático",
"tribute",
"odiando",
"abdução",
"inundar",
"sabotar",
"abolido",
"cursado",
"baguete",
"bisneto",
"excedeu",
"lifting",
"lambari",
"sóbrios",
"caroços",
"despida",
"honroso",
"edredon",
"encobre",
"cúpulas",
"vergara",
"nivelar",
"bauhaus",
"furador",
"sapatas",
"lixando",
"vestiam",
"fixaram",
"dependo",
"assopra",
"sarados",
"sumulas",
"relento",
"pepinos",
"rochoso",
"empadão",
"evitava",
"convivo",
"bangalô",
"encante",
"jacuipê",
"dossier",
"lunares",
"soltava",
"cominho",
"ariadne",
"trunfos",
"lúcidos",
"fizeres",
"conferi",
"simulam",
"jingles",
"abrupto",
"tríceps",
"visados",
"abaixei",
"macular",
"atacava",
"ciladas",
"usufrui",
"séricos",
"vorazes",
"comadre",
"tigelas",
"tréguas",
"rubéola",
"húngara",
"medular",
"situava",
"altinho",
"balizas",
"ibérico",
"amarrou",
"legista",
"dublada",
"esticou",
"partiam",
"enlaces",
"coaxial",
"invente",
"cadelas",
"malhado",
"quinhão",
"emitira",
"ajustam",
"ateísta",
"galegos",
"vigiado",
"dissipa",
"conocer",
"oblíquo",
"charter",
"depilar",
"punível",
"balnear",
"houvera",
"mulatas",
"quintos",
"tíquete",
"conciso",
"eólicos",
"mudadas",
"beberam",
"calotas",
"webmail",
"peneire",
"epíteto",
"medrosa",
"avaliem",
"aportar",
"enviava",
"alcaide",
"allegro",
"soriano",
"cacilda",
"assobio",
"ofuscar",
"azeites",
"seletor",
"arianos",
"zangada",
"ajuntar",
"regidas",
"arranje",
"adoeceu",
"lactato",
"araruna",
"aldeota",
"estorno",
"falarão",
"auferir",
"jatinho",
"cesaria",
"folgada",
"conexos",
"mereces",
"iludido",
"faringe",
"saíssem",
"duvidam",
"piquete",
"aterrar",
"excitar",
"gozação",
"esfumar",
"oscilar",
"reporte",
"tolices",
"revirar",
"aguçada",
"criavam",
"pecando",
"relendo",
"tardias",
"benzeno",
"invocam",
"tampões",
"ateliês",
"galocha",
"cascais",
"páprica",
"delfino",
"táticos",
"ferrado",
"papinho",
"namorei",
"remeteu",
"retomam",
"postava",
"fitando",
"subárea",
"disporá",
"linense",
"borrego",
"bastião",
"casaria",
"jordana",
"barbada",
"lavador",
"repitam",
"tiravam",
"discuto",
"glaciar",
"cancele",
"comover",
"frisson",
"matamos",
"temidos",
"pairava",
"angicos",
"detenha",
"licitar",
"olvidar",
"molusco",
"cívicos",
"jogasse",
"churros",
"quimera",
"clareia",
"ecoando",
"denotam",
"salivar",
"visavam",
"enferma",
"maldoso",
"drinque",
"quisera",
"cartéis",
"resumia",
"apanham",
"fuligem",
"ditadas",
"influxo",
"viverei",
"alistar",
"brindou",
"desciam",
"vazadas",
"empatam",
"pedonal",
"inépcia",
"aviaria",
"gorilas",
"padecem",
"soninho",
"alegava",
"ajeitou",
"esporão",
"integre",
"magenta",
"desfaça",
"portava",
"causais",
"austero",
"azeitão",
"albergo",
"arcadas",
"leviano",
"canjica",
"cangaço",
"datadas",
"conclua",
"tumoral",
"cultuar",
"puteiro",
"entupir",
"rebelar",
"relevar",
"recusei",
"ajudada",
"cederam",
"juntava",
"cômicas",
"vazaram",
"lisinho",
"mudarão",
"condeno",
"submeta",
"apontei",
"feriram",
"lanchar",
"raivoso",
"palidez",
"multada",
"peniana",
"ofendem",
"atirada",
"picapes",
"faculta",
"senzala",
"apavora",
"vacinal",
"grudada",
"jumping",
"seminal",
"silagem",
"brechós",
"brasões",
"leviana",
"incorre",
"insisti",
"receoso",
"atribua",
"sunitas",
"gringas",
"deforma",
"durinha",
"samples",
"janaúba",
"custado",
"azimute",
"comenda",
"espetar",
"prelado",
"fumador",
"virasse",
"mombaça",
"ancorar",
"tiroide",
"vistoso",
"espasmo",
"primata",
"superei",
"medonha",
"casarem",
"fiáveis",
"formica",
"difamar",
"estocar",
"caixões",
"salvado",
"praiano",
"odiados",
"zapping",
"coturno",
"infâmia",
"métrico",
"flutuam",
"baterem",
"regadas",
"acácias",
"imponha",
"enólogo",
"moqueca",
"alterei",
"fulcral",
"mijando",
"chefiar",
"zelando",
"pomposo",
"esfriou",
"gasosas",
"tememos",
"isentar",
"agarram",
"planeia",
"juninos",
"alsácia",
"apostei",
"curvado",
"sulista",
"unifica",
"agravam",
"mudados",
"cáucaso",
"abomina",
"mestiça",
"saraiva",
"celíaca",
"alastra",
"apareci",
"briguei",
"portuga",
"crachás",
"doadora",
"despeja",
"acético",
"detonou",
"garanti",
"paulada",
"móbiles",
"vedadas",
"divagar",
"punidas",
"chacras",
"tunados",
"optante",
"acomode",
"debruça",
"previna",
"baldios",
"evapora",
"realçou",
"alinham",
"currais",
"grelhar",
"reduzam",
"descera",
"charada",
"viscoso",
"durando",
"ociosos",
"catarro",
"taurina",
"embolia",
"rosados",
"oscilam",
"imputar",
"mentido",
"chapado",
"treinei",
"acusava",
"elencar",
"baraúna",
"jandaia",
"heraldo",
"cerrada",
"enrolou",
"vórtice",
"sapinho",
"alarido",
"búfalos",
"toleram",
"pudores",
"maciças",
"locanda",
"fugirem",
"rabinos",
"alcaçuz",
"murchar",
"semeado",
"codorna",
"palhaça",
"pélvico",
"exploda",
"remendo",
"babador",
"escavar",
"cômicos",
"salinas",
"cãibras",
"rejeito",
"laxante",
"receosa",
"elencos",
"ansiava",
"pebolim",
"antique",
"resseca",
"clicada",
"reenvio",
"escapes",
"previam",
"cânhamo",
"gideões",
"parecis",
"vendera",
"tendera",
"provera",
"orelhão",
"engates",
"estupra",
"telefax",
"mexidos",
"sucinto",
"rachado",
"sedosos",
"crânios",
"gasosos",
"mestras",
"touriga",
"chiffon",
"atolado",
"novelos",
"lambada",
"ventral",
"cassada",
"daninha",
"adéquam",
"arenoso",
"fitinha",
"selados",
"difunde",
"perdoam",
"prevejo",
"ouvirão",
"capelão",
"basilar",
"sadismo",
"rabudas",
"paquete",
"minorar",
"encenar",
"pochete",
"pactual",
"tourada",
"pujante",
"patinha",
"casario",
"ásperas",
"agregou",
"usufrua",
"poleiro",
"relatei",
"mancada",
"etérico",
"parador",
"ditando",
"caparão",
"pantufa",
"vazante",
"pérsico",
"bispado",
"mesclam",
"roseira",
"residiu",
"acreano",
"revolto",
"seduziu",
"rematar",
"enganem",
"miríade",
"amansar",
"desnuda",
"barigui",
"sarjeta",
"dumping",
"mórmons",
"advogar",
"catando",
"roçando",
"formata",
"transou",
"geleira",
"andasse",
"canelas",
"viajara",
"cheiram",
"raptada",
"triture",
"ursinha",
"notados",
"costure",
"ajoelha",
"dossiês",
"afogada",
"engatar",
"glucose",
"trucado",
"lavínia",
"nirvana",
"alcatra",
"transei",
"sairiam",
"colosso",
"diadema",
"pelouro",
"abafada",
"recicla",
"sirenes",
"escrivã",
"estique",
"sorriam",
"graúdos",
"velejar",
"estrume",
"devamos",
"junções",
"instalo",
"lampejo",
"imatura",
"axiomas",
"cravado",
"amazona",
"faxinal",
"plurais",
"pupilos",
"apertam",
"revidar",
"alhures",
"forçoso",
"sândalo",
"mariano",
"tornamo",
"excelso",
"mulatos",
"picolés",
"cadente",
"arrocho",
"bestial",
"aeróbio",
"inspiro",
"redutos",
"esboçou",
"onírico",
"bigorna",
"lexical",
"falcões",
"austera",
"recorro",
"góticos",
"vicinal",
"comidos",
"rebaixa",
"revezam",
"apresse",
"surubim",
"contive",
"sérvios",
"atrasam",
"diverge",
"pisadas",
"cívicas",
"patetas",
"abdicou",
"arganil",
"usariam",
"analiso",
"vindima",
"investi",
"lounges",
"recheie",
"andaluz",
"indulto",
"constem",
"chorona",
"gostara",
"pulsões",
"deitava",
"ciganas",
"mordaça",
"redigiu",
"sexismo",
"perdoei",
"deferir",
"azarado",
"mistral",
"leiaute",
"saiamos",
"gulosos",
"falidos",
"apontem",
"séquito",
"onlines",
"rendida",
"otimize",
"auréola",
"planejo",
"enchiam",
"nítidos",
"confiei",
"auxilie",
"curinga",
"tingido",
"igualou",
"sóbrias",
"icônica",
"leviatã",
"indicia",
"fractal",
"cogitou",
"atolada",
"igarapé",
"ligaria",
"olhemos",
"ensinem",
"bombear",
"morasse",
"caladas",
"broncas",
"agrupam",
"anátema",
"variava",
"adiadas",
"valesse",
"barrica",
"paisana",
"lembrai",
"acessei",
"túnicas",
"cavando",
"decoram",
"estorvo",
"arrumam",
"cadinho",
"veicula",
"golpear",
"cutucar",
"culposa",
"recorra",
"namoram",
"azaleia",
"pólipos",
"demoras",
"vassalo",
"entalhe",
"puxadas",
"grupais",
"tostado",
"botaram",
"candura",
"maldosa",
"suprime",
"casebre",
"remates",
"brigava",
"pastore",
"headset",
"cavalga",
"rítmico",
"amaciar",
"revival",
"refeita",
"etários",
"boleros",
"ligasse",
"polacos",
"arraias",
"cotadas",
"pastejo",
"papoila",
"convivi",
"degrada",
"tedioso",
"aeração",
"assolou",
"héteros",
"custoso",
"mastros",
"haveres",
"extraiu",
"ligavam",
"latidos",
"canibal",
"pendure",
"couvert",
"trêmulo",
"taurino",
"taradas",
"advinha",
"amarmos",
"maninho",
"míticos",
"órteses",
"tomarei",
"hackear",
"alterne",
"quilate",
"tablado",
"ligarem",
"bordalo",
"frância",
"traídos",
"sigilos",
"calouro",
"rosadas",
"pirraça",
"aguçado",
"capataz",
"exponho",
"insular",
"varrido",
"letrado",
"aramado",
"coaduna",
"driblou",
"cortiço",
"aludida",
"plantam",
"amargar",
"agirmos",
"geleias",
"pleural",
"troquem",
"gritado",
"várzeas",
"geridas",
"ofensor",
"influem",
"maçante",
"emigrou",
"patinar",
"transam",
"apegada",
"femoral",
"diurnos",
"colinho",
"acurada",
"risível",
"cheddar",
"prismas",
"arruela",
"pusesse",
"saudado",
"retirem",
"revelem",
"dentais",
"modules",
"verruga",
"esprema",
"exaltam",
"pupunha",
"coagulo",
"ousaram",
"sentimo",
"faceira",
"pilotis",
"pituaçu",
"míticas",
"eritema",
"coveiro",
"furadas",
"trufado",
"pagante",
"surrado",
"decifra",
"diferir",
"etileno",
"comesse",
"alegrai",
"vieiras",
"adeptas",
"firulas",
"figurou",
"livrara",
"eletiva",
"ousaria",
"blindar",
"montras",
"punindo",
"viraria",
"arroios",
"treliça",
"têmpora",
"hipismo",
"recobre",
"reativo",
"enclave",
"chororó",
"absolve",
"banidas",
"concedo",
"ribalta",
"suprida",
"cadetes",
"odiamos",
"cozinho",
"enuncia",
"aspiram",
"rachada",
"inflama",
"expelir",
"vinhais",
"duplica",
"sossega",
"réquiem",
"usassem",
"aliviam",
"pinhões",
"decorei",
"excedem",
"injetor",
"retidas",
"vozinha",
"possais",
"medinho",
"fatiada",
"adereço",
"croquis",
"tardios",
"compila",
"tostões",
"ginseng",
"caçados",
"catalãs",
"tigresa",
"couraça",
"cupuaçu",
"oprimir",
"rodapés",
"rematou",
"ficante",
"rejunte",
"acidose",
"matavam",
"azáfama",
"amornar",
"limpava",
"sumatra",
"batidão",
"claques",
"encoste",
"faraday",
"celeuma",
"alagada",
"fazedor",
"abissal",
"manhosa",
"charque",
"basalto",
"chavões",
"talibãs",
"desfiar",
"mangues",
"curvada",
"canudos",
"emanada",
"coopera",
"aferida",
"ladeado",
"cística",
"lambida",
"metanol",
"finança",
"argilas",
"adrenal",
"gravava",
"ilações",
"afixada",
"enrolei",
"fenícia",
"ilibada",
"bazares",
"reparam",
"endossa",
"galinho",
"ficaste",
"penetre",
"jugular",
"infecto",
"papoula",
"apagara",
"danosas",
"agregue",
"plebeus",
"bolsões",
"digitei",
"tedesco",
"poiares",
"arenito",
"cabeção",
"trastes",
"sexista",
"seriais",
"serenos",
"retendo",
"cativas",
"contata",
"fluidas",
"alourar",
"boêmios",
"táctico",
"assando",
"pedante",
"rigores",
"fagulha",
"sísmica",
"afegãos",
"colorau",
"detendo",
"escambo",
"atraiam",
"triunfa",
"calisto",
"pagarei",
"arrasam",
"peniano",
"enchido",
"eslavos",
"errôneo",
"madeiro",
"leitoso",
"hinário",
"estendo",
"obscena",
"minando",
"bombado",
"infecta",
"alpiste",
"reabriu",
"ranchos",
"convier",
"assinem",
"cobrara",
"apodera",
"alinhou",
"víveres",
"gameiro",
"exortou",
"estalos",
"invocou",
"amassos",
"anjinha",
"sórdido",
"apregoa",
"pareces",
"sinuoso",
"lavabos",
"soturno",
"pousado",
"cínicos",
"bisavós",
"desarma",
"teimava",
"enfiava",
"absorva",
"repasso",
"dizimar",
"vulgata",
"zilhões",
"saquear",
"levinho",
"afecção",
"pulsada",
"vomitou",
"cártamo",
"reinara",
"adiados",
"rincões",
"carniça",
"nevasca",
"taradão",
"talhado",
"coatora",
"cravada",
"leitões",
"furados",
"baloiço",
"decidia",
"realçam",
"fugimos",
"granjas",
"manhoso",
"deidade",
"expediu",
"cartada",
"fístula",
"sigamos",
"vidrado",
"rompida",
"rendera",
"inundou",
"cobarde",
"sanadas",
"bimotor",
"sinuosa",
"pixoxós",
"flagrar",
"refugia",
"bobinho",
"bruscos",
"sashimi",
"novilho",
"reitero",
"ensaiou",
"atuaria",
"cremado",
"bordões",
"franças",
"aruanda",
"crioula",
"flertar",
"irrompe",
"mecenas",
"cessado",
"anciões",
"défices",
"clonado",
"fedendo",
"marinar",
"exortar",
"capenga",
"drogada",
"achismo",
"efetuei",
"sharing",
"micoses",
"mamário",
"boazuda",
"jurando",
"savanas",
"trienal",
"perequê",
"sobrara",
"tâmaras",
"semitas",
"arresto",
"capotar",
"corpete",
"bauxita",
"arranco",
"auroras",
"apagões",
"nutrido",
"mamadas",
"marreta",
"gastara",
"ressoar",
"credita",
"goleado",
"insulta",
"portada",
"falares",
"latindo",
"idílico",
"tapando",
"ouvirei",
"distros",
"venerar",
"trivela",
"aviário",
"verbena",
"fundida",
"apenado",
"périplo",
"alterem",
"enfocar",
"brandas",
"impacte",
"acharão",
"erigido",
"cursava",
"vigorou",
"cobrira",
"apática",
"açucena",
"aqueceu",
"feitios",
"aportou",
"aldeões",
"meeting",
"digitou",
"raspado",
"dilatar",
"renegar",
"luxação",
"invadia",
"furando",
"pularam",
"séptica",
"fervida",
"góticas",
"ouviria",
"induziu",
"sujando",
"neptuno",
"tramado",
"coautor",
"regando",
"coletou",
"praceta",
"encerre",
"soprava",
"regrada",
"confete",
"efetuam",
"fugazes",
"pálidas",
"empadas",
"hibisco",
"colidem",
"apartar",
"brandos",
"sórdida",
"ilógico",
"cantara",
"embalou",
"hormona",
"duodeno",
"infeção",
"reparte",
"rasuras",
"duvidou",
"encaixo",
"capacho",
"bulário",
"unirmos",
"estator",
"líricos",
"cansava",
"cercava",
"fadados",
"acoplar",
"síncope",
"nasciam",
"falaste",
"refleti",
"risonha",
"lamaçal",
"quererá",
"passeie",
"amassou",
"jargões",
"vivendi",
"catador",
"faturam",
"ossadas",
"alçapão",
"aeróbia",
"jogarão",
"enteral",
"pepitas",
"passeou",
"reprime",
"dolosos",
"matilda",
"centrou",
"colhera",
"admirou",
"flácida",
"palpita",
"bélicos",
"versado",
"arcádia",
"refrões",
"zelotes",
"taxados",
"rabisco",
"silvana",
"suméria",
"exibiam",
"vesúvio",
"atrever",
"calorão",
"karting",
"tímpano",
"justeza",
"lotaria",
"leninha",
"morenos",
"falhada",
"ocultam",
"rejeite",
"coroatá",
"prenome",
"herdade",
"votamos",
"queimei",
"mexeram",
"ruidoso",
"bobinha",
"cifrada",
"refiram",
"atrairá",
"açoites",
"hispano",
"atentam",
"grafado",
"candeia",
"devoram",
"chefões",
"levedar",
"exonera",
"amarrei",
"soldada",
"subtrai",
"evocado",
"subtipo",
"fasquia",
"absorto",
"rezamos",
"saciado",
"lapinha",
"sodomia",
"colores",
"peluche",
"roxinho",
"sucumbe",
"ruidosa",
"vanádio",
"cairmos",
"espante",
"regados",
"abordei",
"períneo",
"barrete",
"pectina",
"deverei",
"papilas",
"atirava",
"luzinha",
"temidas",
"radiais",
"romenos",
"montava",
"looping",
"enésima",
"pedinte",
"eclodiu",
"rebites",
"hérnias",
"idílica",
"imitado",
"viroses",
"butique",
"deixeis",
"taoísmo",
"confiem",
"anilina",
"farense",
"irrigar",
"filmaço",
"opunham",
"guardai",
"expurgo",
"abomino",
"duvidei",
"mudavam",
"tampada",
"animava",
"javalis",
"jogável",
"invejas",
"cêntimo",
"curtida",
"macramé",
"feltros",
"predito",
"combino",
"raptado",
"obreira",
"arcando",
"difusas",
"boipeba",
"vaguear",
"imbuído",
"pedirei",
"derivou",
"entorse",
"diurnas",
"castrar",
"mediram",
"vistosa",
"alcanço",
"quebrem",
"esteres",
"elucida",
"risotto",
"fajardo",
"alargou",
"notinha",
"horinha",
"soluçar",
"fingido",
"bodegas",
"subirão",
"bienais",
"ingerem",
"lacaios",
"trafega",
"esbelto",
"biscaia",
"calaram",
"denotar",
"sísmico",
"caiçara",
"malhada",
"poupada",
"focamos",
"viviana",
"esterno",
"afloram",
"atóxico",
"avencas",
"notadas",
"notário",
"lúgubre",
"moicano",
"pessach",
"supimpa",
"asinhas",
"invejar",
"prendam",
"isabela",
"inflado",
"malinha",
"camufla",
"cubanas",
"sortido",
"mereçam",
"sacanas",
"aliviou",
"pegavam",
"onírica",
"pararia",
"gueiros",
"defecar",
"misturo",
"padeceu",
"cedente",
"pastosa",
"fornada",
"enxugue",
"postiça",
"jalapão",
"mongóis",
"anemias",
"monstra",
"galados",
"removem",
"arguida",
"basebol",
"apossar",
"hesitei",
"rodeava",
"aerosol",
"frisado",
"papiros",
"burrito",
"mancais",
"deteção",
"livrado",
"assusto",
"iniciem",
"interés",
"oscilou",
"saciada",
"insigne",
"mataras",
"raivosa",
"baixava",
"tragada",
"varrida",
"déspota",
"alistou",
"cracker",
"estendi",
"quitada",
"odorico",
"jogging",
"travava",
"fardado",
"puxaram",
"autuada",
"ligúria",
"geramos",
"flanges",
"vaginas",
"borrado",
"oficias",
"missiva",
"pecúlio",
"permear",
"burrada",
"ficares",
"derrote",
"mimados",
"tremiam",
"atreveu",
"apalpar",
"pirando",
"inicias",
"capture",
"migalha",
"alheira",
"reagido",
"druidas",
"lingual",
"sufixos",
"grávido",
"hesitam",
"fibroso",
"almaraz",
"comparo",
"transas",
"magrelo",
"ajustou",
"duravam",
"cancros",
"alumina",
"excelsa",
"arbóreo",
"beberem",
"saberás",
"legisla",
"lagares",
"temerei",
"usurpar",
"ourense",
"cambiar",
"graduar",
"superem",
"remando",
"guisado",
"shiatsu",
"reservo",
"lontras",
"guariba",
"erigida",
"embalam",
"devorou",
"cruzava",
"agoniza",
"empinar",
"auditar",
"grafias",
"felpudo",
"piorado",
"acatada",
"moveram",
"caldeus",
"dançado",
"ciático",
"insurge",
"adaptem",
"revirou",
"ofertou",
"borrões",
"cúrcuma",
"enterre",
"negamos",
"súbitas",
"achadas",
"iludida",
"andaria",
"ociosas",
"tecidas",
"circule",
"alojada",
"supomos",
"definam",
"areeiro",
"atlante",
"luthier",
"agendou",
"côvados",
"taludes",
"entrudo",
"dilação",
"revende",
"vesical",
"barbuda",
"canhota",
"ignorei",
"licínio",
"murmura",
"zabumba",
"delicie",
"ascenso",
"sabedor",
"recorri",
"esporro",
"sininho",
"croatas",
"ênfases",
"esmirna",
"reveses",
"gripado",
"armaram",
"rosácea",
"batesse",
"jumenta",
"somavam",
"esmague",
"serenas",
"acendia",
"furtiva",
"gastado",
"aórtica",
"indexar",
"referes",
"plugues",
"pompons",
"lulismo",
"ouriços",
"carrões",
"dirijam",
"clamava",
"excluso",
"subisse",
"amarram",
"pontada",
"discado",
"cartuns",
"revisou",
"moranga",
"pisaram",
"sátiras",
"afamado",
"proveta",
"manchou",
"pipocar",
"vagueia",
"xingado",
"cardume",
"remexer",
"germana",
"devolvo",
"debitar",
"barista",
"penosas",
"trariam",
"atentou",
"manopla",
"preveja",
"ajudamo",
"encurta",
"ladeada",
"ladário",
"escarro",
"convexo",
"chutado",
"navegou",
"estepes",
"apertem",
"dentina",
"queques",
"cátions",
"reforce",
"extraem",
"polidas",
"rebelou",
"excedam",
"alcinha",
"refrear",
"angical",
"loiraça",
"cometam",
"lobinho",
"baqueta",
"volúvel",
"ignorem",
"perfuma",
"largava",
"sofisma",
"acedido",
"coletam",
"ocarina",
"oxidado",
"bancado",
"tapumes",
"arrotar",
"minivan",
"remidos",
"produzo",
"secaram",
"chorosa",
"escutai",
"vedados",
"leiloar",
"sexuada",
"soslaio",
"cagadas",
"cistite",
"bundona",
"lápides",
"nublada",
"dorsais",
"sexagem",
"caseína",
"cessara",
"secante",
"emanado",
"delgada",
"imperam",
"facundo",
"movemos",
"mastiga",
"bordeis",
"levitar",
"afundam",
"decorra",
"lotando",
"atrasei",
"bronzes",
"repasto",
"portado",
"chorume",
"engolem",
"ignóbil",
"letrada",
"herdara",
"bainhas",
"praiana",
"cafetão",
"ilhotas",
"negadas",
"durasse",
"casasse",
"recusas",
"selante",
"acalmam",
"canabis",
"jateado",
"mirtilo",
"aferido",
"atrevem",
"baranga",
"atracar",
"férreas",
"aríston",
"judocas",
"anthrax",
"botinas",
"deletei",
"obtinha",
"atestou",
"reduzia",
"drenado",
"fingida",
"pululam",
"semeada",
"devorei",
"loirona",
"bistrot",
"olhinho",
"albanês",
"flocado",
"expunha",
"cócoras",
"lituano",
"quentão",
"residam",
"atribuo",
"idôneos",
"belzebu",
"guildas",
"subiria",
"bêbadas",
"franziu",
"amargou",
"alfaces",
"alocada",
"laurita",
"turbine",
"garrote",
"atópica",
"gestapo",
"apelada",
"juntara",
"avícola",
"cordéis",
"maricas",
"gradiva",
"imperar",
"cretina",
"velinha",
"almoçou",
"porosos",
"vomitei",
"tapetão",
"ordenei",
"declina",
"raminho",
"preveni",
"eunucos",
"decorou",
"minutas",
"umedeça",
"enxugou",
"seladas",
"fervido",
"patacas",
"germina",
"viárias",
"zelosos",
"lícitos",
"veladas",
"octávia",
"rendado",
"resumão",
"fibrosa",
"datando",
"conexas",
"empossa",
"arenosa",
"selando",
"búlgara",
"semeiam",
"cercear",
"albinos",
"carecia",
"alagado",
"reviveu",
"serrano",
"exarado",
"faiança",
"capanga",
"edredão",
"walkman",
"biotina",
"elegera",
"argento",
"rogando",
"incauto",
"eclodir",
"delfina",
"corroer",
"acatado",
"propano",
"esmagou",
"lascada",
"falhara",
"ensejou",
"céptico",
"goleira",
"adornar",
"defense",
"dermato",
"emanava",
"bursite",
"torture",
"posaram",
"cultive",
"líricas",
"religar",
"tournée",
"depurar",
"esquete",
"saltava",
"demarca",
"maletas",
"sufocam",
"gozarem",
"crostas",
"lameira",
"hipóxia",
"doçaria",
"polaina",
"cênicos",
"sonegar",
"gingado",
"tainara",
"marcham",
"militam",
"dotando",
"envolvo",
"idôneas",
"espirra",
"ásperos",
"esbulho",
"começas",
"tirarei",
"valerão",
"adoecem",
"elevara",
"adoções",
"alcoóis",
"cânfora",
"tocaria",
"apaguem",
"exitosa",
"cítrica",
"caronas",
"ditongo",
"sacando",
"ranhura",
"quietas",
"seduzem",
"almagro",
"aulinha",
"gerarem",
"chovido",
"apossou",
"projete",
"exalava",
"deprime",
"avessos",
"criarão",
"tampado",
"mentora",
"siemens",
"comunal",
"engolia",
"forçava",
"fominha",
"insecto",
"nutrida",
"vibrava",
"falador",
"espiado",
"laminar",
"lascado",
"orbitam",
"polidor",
"votarão",
"regendo",
"pródiga",
"molinho",
"xaropes",
"plagiar",
"reposto",
"tediosa",
"enxames",
"sondado",
"espinal",
"ordenam",
"picador",
"redimiu",
"marquem",
"caíssem",
"toranja",
"debelar",
"jalecos",
"revoada",
"pastoso",
"balinha",
"cabocla",
"aprisco",
"fadigas",
"frouxos",
"ordeira",
"havidos",
"másculo",
"conecto",
"pescara",
"avisava",
"vertido",
"gripada",
"benesse",
"gerânio",
"fugisse",
"ampliem",
"ojeriza",
"cheirou",
"votante",
"jubarte",
"pérgola",
"caxumba",
"pactuar",
"avancem",
"maníaca",
"furtivo",
"moraram",
"insosso",
"risotos",
"inundam",
"olheiro",
"surrada",
"bambolê",
"arruiná",
"ventana",
"nalguma",
"ímpetos",
"plugado",
"jubilar",
"mosaica",
"genomas",
"centram",
"pálidos",
"desatou",
"arreios",
"marchou",
"soprado",
"ousamos",
"tolueno",
"arrival",
"ubiratã",
"gandaia",
"pusemos",
"ínfimos",
"arrobas",
"polidez",
"párocos",
"negavam",
"morarem",
"debatia",
"broncos",
"farrapo",
"tarjeta",
"limitei",
"aditiva",
"mapeado",
"degredo",
"brioche",
"menisco",
"murchas",
"ensaiam",
"mastite",
"logrado",
"rechaça",
"condene",
"escapei",
"piedras",
"jujubas",
"estuque",
"retruca",
"meterem",
"fisgada",
"enchera",
"canapés",
"reflito",
"mancebo",
"escusas",
"caserna",
"apagava",
"recriam",
"juraram",
"arrumem",
"frontão",
"biombos",
"agiotas",
"penedos",
"deleita",
"aliciar",
"setinha",
"puseste",
"comovem",
"fórceps",
"sonhara",
"retinas",
"gerindo",
"negarem",
"alvitre",
"farelos",
"perdure",
"aplaudo",
"medimos",
"andinos",
"sangram",
"filmava",
"paddock",
"omitida",
"plateau",
"lidarem",
"varonil",
"chupado",
"imundas",
"rondava",
"desarme",
"polidos",
"tapados",
"fofocar",
"tingida",
"palerma",
"guichês",
"oprimem",
"cálices",
"vetados",
"lixeiro",
"atrozes",
"fugidos",
"bacilos",
"guaiuba",
"cotista",
"citrato",
"esbelta",
"provara",
"esvazie",
"denegou",
"ortigão",
"perecem",
"cativam",
"ianques",
"vacilão",
"arrenda",
"valorar",
"estufar",
"comeras",
"esmeril",
"chorões",
"mapeada",
"marinas",
"untadas",
"ferrada",
"suingue",
"calores",
"libreto",
"outonal",
"barcelo",
"finesse",
"pediste",
"arteiro",
"limitem",
"tardara",
"biônico",
"chefiou",
"faltoso",
"posadas",
"ataquem",
"churras",
"preguei",
"incitam",
"nitroso",
"afoitos",
"porrete",
"adoçado",
"ramalho",
"biquine",
"ajuntou",
"secarem",
"azedume",
"taoista",
"neófito",
"jeitoso",
"assador",
"velhota",
"cubismo",
"odiavam",
"afiação",
"parelha",
"pagodes",
"pontuam",
"sangrou",
"novenas",
"devolvi",
"latrina",
"gozavam",
"segurem",
"minimal",
"porreta",
"alunado",
"ficaras",
"querela",
"módicos",
"bebedor",
"rumando",
"migrado",
"afiador",
"assenti",
"mutilar",
"oblação",
"esporas",
"grêmios",
"deduziu",
"rasante",
"punhais",
"leitosa",
"rebenta",
"erosões",
"clareou",
"ringues",
"atearam",
"sitiada",
"leucina",
"umbrais",
"pátrias",
"amputar",
"bibiana",
"espúria",
"credora",
"dancing",
"libânio",
"simulou",
"calando",
"escalda",
"descola",
"copeiro",
"cometia",
"abordem",
"bandage",
"torções",
"imersas",
"tabasco",
"afresco",
"trombas",
"expulse",
"relaxam",
"imigrar",
"enrolam",
"guiador",
"bélicas",
"pistons",
"acurado",
"recorta",
"falação",
"perfura",
"arapuca",
"copeira",
"bolotas",
"florada",
"repelem",
"dávamos",
"quimono",
"reanima",
"reclusa",
"custosa",
"submeto",
"picanço",
"mendiga",
"tifoide",
"gólgota",
"citarei",
"colante",
"tachado",
"gutural",
"proteus",
"tiamina",
"safadão",
"serrote",
"braxton",
"patelar",
"afetava",
"ingeriu",
"ralador",
"existes",
"grafeno",
"obtenho",
"estival",
"pulgões",
"separem",
"balizar",
"síndica",
"repondo",
"bacanal",
"itálica",
"moverem",
"tarouca",
"coradas",
"graveto",
"amigona",
"braçada",
"espécia",
"olharia",
"cursiva",
"vigoram",
"soletre",
"ereções",
"magneto",
"sexteto",
"argônio",
"retomei",
"cândace",
"andarão",
"ablação",
"lignina",
"giravam",
"beirais",
"tabanca",
"executo",
"maminha",
"tacadas",
"acharei",
"pesaram",
"silente",
"recebes",
"versada",
"garfada",
"deveram",
"alegrem",
"vexames",
"oxumarê",
"recolhi",
"metidas",
"lúcidas",
"defensa",
"colocas",
"tutelas",
"tribuno",
"alforge",
"cairiam",
"submenu",
"emplaca",
"atônita",
"piçarra",
"martíni",
"coclear",
"ralando",
"abusava",
"jaguara",
"ocultou",
"pereiro",
"amolece",
"tabapuã",
"arbórea",
"falidas",
"córneas",
"etiopês",
"láctico",
"pirambu",
"límbico",
"cânions",
"elegido",
"pinhole",
"batizei",
"exímios",
"cerrito",
"rolavam",
"víboras",
"estrupo",
"desfaço",
"merreca",
"xxxviii",
"subsede",
"mexidas",
"aclarar",
"troiano",
"ofertam",
"desafie",
"arábias",
"ouvires",
"flácido",
"decênio",
"fenaban",
"nuanças",
"governe",
"sulfeto",
"borrife",
"aspásia",
"cântaro",
"trincar",
"calagem",
"metemos",
"advogam",
"geravam",
"congele",
"leciono",
"galegas",
"melasma",
"rotores",
"funesto",
"cônegos",
"lesivas",
"dormira",
"cardigã",
"estupor",
"côncava",
"dominem",
"marujos",
"penugem",
"aliaram",
"degusta",
"evasiva",
"lóbulos",
"aquecia",
"cobiçar",
"sacaram",
"aprovem",
"atendei",
"gloriar",
"fogosas",
"caxangá",
"casulos",
"colíder",
"velando",
"desnudo",
"anhumas",
"inculto",
"emirado",
"campana",
"designe",
"nobreak",
"aventar",
"desviei",
"criaste",
"zíperes",
"deságio",
"norteou",
"protejo",
"gralhas",
"desvelo",
"atrelar",
"malotes",
"dizimou",
"centrão",
"recolho",
"isótopo",
"exporem",
"pseudos",
"garoupa",
"texture",
"cultuam",
"rebocar",
"goteira",
"repense",
"triplas",
"esporra",
"obstrui",
"coagido",
"lulista",
"viravam",
"refutou",
"espreme",
"relança",
"veneram",
"prímula",
"sapatão",
"dosador",
"doutros",
"imitava",
"molinha",
"kwanzas",
"alfaias",
"engasga",
"perneta",
"comerão",
"intimar",
"agradem",
"alvejar",
"peladão",
"minúcia",
"ferroso",
"ressoam",
"detetar",
"ligante",
"desbota",
"orarmos",
"opinado",
"intenta",
"decaído",
"varjota",
"bisneta",
"inibido",
"molotov",
"fumeiro",
"audazes",
"dadores",
"soltado",
"admitam",
"vargens",
"velados",
"somamos",
"esquimó",
"janduís",
"tendiam",
"arribas",
"pausada",
"agrados",
"piranga",
"baixeza",
"paremos",
"cognome",
"gabando",
"inodoro",
"exilada",
"peçonha",
"falível",
"louvada",
"niacina",
"opereta",
"relaxou",
"tangram",
"gozamos",
"lavamos",
"formule",
"ensinai",
"pecúnia",
"desvela",
"agonias",
"alongam",
"cortesã",
"instado",
"tirante",
"domador",
"aquieta",
"vitrina",
"locados",
"acalmem",
"subjuga",
"remetia",
"traíram",
"colecta",
"taciana",
"trígono",
"incurso",
"clipart",
"sorteie",
"delegou",
"suturas",
"agachar",
"incidiu",
"prolixo",
"biberão",
"injetou",
"agitava",
"selador",
"habitus",
"sugiram",
"furriel",
"faceiro",
"pocilga",
"rebordo",
"afligir",
"vidrada",
"sonatas",
"enrosca",
"abranda",
"pipocam",
"dioniso",
"lamente",
"brometo",
"cascudo",
"plátano",
"embarco",
"péptica",
"anímica",
"despojo",
"patadas",
"absteve",
"ouçamos",
"ríspido",
"sádicos",
"erectus",
"aquosas",
"associo",
"boleiro",
"imergir",
"regador",
"compraz",
"vestira",
"cianeto",
"jazigos",
"chamego",
"afirmem",
"pétreas",
"combata",
"andemos",
"elevava",
"tipagem",
"lectiva",
"tecelão",
"gramada",
"labiais",
"pesavam",
"rendada",
"encetar",
"antanho",
"fáticos",
"mancham",
"galhofa",
"solânea",
"flipper",
"boticas",
"manjado",
"persiga",
"checada",
"torrões",
"fidalga",
"sucedia",
"pecinha",
"cachola",
"traduzo",
"suprido",
"detonam",
"bisteca",
"fumaças",
"numerar",
"seresta",
"ritmada",
"carreto",
"trocara",
"furinho",
"usáveis",
"nitrito",
"excisão",
"brucutu",
"finitas",
"optaria",
"polônio",
"tritura",
"penosos",
"andarei",
"cônicas",
"lacraia",
"obedeci",
"saltado",
"despois",
"frangas",
"vilania",
"aterrou",
"inócuas",
"roletes",
"relaxei",
"jamelão",
"medicar",
"assobia",
"lesivos",
"apeguei",
"empezar",
"tônicos",
"frajola",
"oradora",
"pereceu",
"havidas",
"pelando",
"riscada",
"câimbra",
"furgões",
"fáticas",
"pedirão",
"golaços",
"cedilha",
"ousando",
"tempura",
"abrirei",
"bitolas",
"nomeiam",
"sacados",
"liberem",
"serrada",
"torreão",
"achaste",
"pomposa",
"dispare",
"rumamos",
"pesadão",
"toparia",
"bocejar",
"vedanta",
"fisgado",
"incline",
"levinha",
"alvinho",
"doceria",
"adotava",
"gatunos",
"ingesta",
"vetiver",
"destila",
"incitou",
"lixívia",
"saudada",
"tiraste",
"empanar",
"seriada",
"equipam",
"espiras",
"túbulos",
"eletros",
"pegarei",
"arredio",
"recriou",
"fincada",
"requere",
"comunga",
"braçais",
"evocada",
"deflete",
"sinusal",
"molenga",
"anotava",
"plasmas",
"aflitas",
"insones",
"vincada",
"encapar",
"tubagem",
"zerados",
"acionam",
"golinha",
"tatames",
"sugador",
"infanta",
"nomeara",
"labores",
"seminua",
"gravame",
"enojado",
"espalho",
"dístico",
"ríspida",
"fúngica",
"franzir",
"usinado",
"pontudo",
"tableau",
"visamos",
"cantata",
"talmude",
"sorteou",
"desanda",
"excitam",
"descabe",
"coliseu",
"repique",
"enguias",
"goiabas",
"expomos",
"gravara",
"penteie",
"teístas",
"reconta",
"revezar",
"tempore",
"torpeza",
"avistam",
"casavam",
"bedelho",
"anchova",
"flagram",
"pulamos",
"séricas",
"jugaras",
"duvidem",
"flâmula",
"sujinho",
"pisamos",
"friagem",
"ermitão",
"fondant",
"impugna",
"incúria",
"cortara",
"notarem",
"lezíria",
"boladão",
"urtigas",
"devasso",
"visadas",
"celebro",
"saleiro",
"tórrido",
"plágios",
"obedeço",
"funesta",
"laginha",
"negocie",
"desídia",
"estalou",
"acedida",
"doçuras",
"berrava",
"tchecos",
"gravoso",
"toparam",
"bifanas",
"impuras",
"mimadas",
"acuados",
"destros",
"apartai",
"velhaco",
"marmelo",
"sumirem",
"rasguei",
"damiana",
"fugiria",
"erecção",
"guianas",
"olhados",
"destine",
"focaram",
"regalia",
"bolando",
"barbado",
"imolado",
"aiatolá",
"devasta",
"mielina",
"olhadas",
"tranque",
"granéis",
"jeitosa",
"costado",
"ralação",
"ritmado",
"calotes",
"mandona",
"ravióli",
"sangues",
"anímico",
"possuis",
"monções",
"cacondê",
"trilhou",
"uretral",
"canaria",
"ornatos",
"oclusal",
"abranja",
"escarpa",
"rodaram",
"caruaru",
"tinindo",
"lajotas",
"revelei",
"arenque",
"raladas",
"lípidos",
"bambino",
"vingado",
"vibrato",
"blusões",
"ceviche",
"elencou",
"talisca",
"plêiade",
"conviva",
"lesadas",
"bombeia",
"olheira",
"ossinho",
"modelam",
"gulosas",
"inalado",
"obteria",
"trancam",
"embasam",
"estoure",
"abaporu",
"caninas",
"petardo",
"acolhia",
"enxadas",
"poupara",
"berbere",
"ínfimas",
"minhota",
"egrégia",
"exógena",
"invejam",
"enredar",
"empáfia",
"laçadas",
"patrona",
"postiço",
"rogação",
"validou",
"euterpe",
"bufando",
"revirei",
"acirrar",
"deletou",
"cuspido",
"encenou",
"recuada",
"brasuca",
"tocarão",
"parejas",
"tarrafa",
"acéfalo",
"abismal",
"fílmica",
"inibida",
"menarca",
"astutos",
"estadas",
"pasmado",
"pernada",
"escaras",
"doutras",
"narciso",
"notasse",
"fumavam",
"ausenta",
"vedando",
"rogamos",
"hidrate",
"viração",
"golpeia",
"mudarei",
"achegar",
"falhava",
"votarei",
"felpuda",
"asbesto",
"jagunço",
"dengosa",
"chequei",
"trololó",
"ventoso",
"ditaram",
"proferi",
"livrete",
"lutaria",
"viários",
"doceira",
"empunha",
"trabuco",
"pulsava",
"piscava",
"achares",
"badalar",
"esparsa",
"lerdeza",
"escrete",
"placard",
"farejar",
"queimem",
"pesquei",
"penteia",
"sépalas",
"recuava",
"térreas",
"basicão",
"igualam",
"levemos",
"entoado",
"sofista",
"pausado",
"buzinar",
"tônicas",
"explodi",
"gueixas",
"moraria",
"viações",
"mesário",
"novação",
"cooptar",
"cabazes",
"levaste",
"pelegos",
"narrava",
"azarias",
"somalis",
"safiras",
"corroem",
"visores",
"etéreos",
"pingado",
"estames",
"xinguei",
"demorem",
"piorava",
"insossa",
"sponsor",
"hidrato",
"pedires",
"brindam",
"quedado",
"falsete",
"manadas",
"fígados",
"rezemos",
"ensejam",
"avancei",
"sacador",
"envidar",
"chibata",
"revejam",
"bérgamo",
"dispões",
"viveras",
"espúrio",
"tomaras",
"iônicos",
"hóstias",
"bajular",
"paradão",
"sedeada",
"filtram",
"abarcam",
"escoras",
"elísios",
"coisita",
"céleres",
"formate",
"lavaram",
"clarões",
"adornam",
"ordinal",
"repugna",
"brotava",
"apensos",
"larguem",
"apiacás",
"entopem",
"zincado",
"lascivo",
"tramóia",
"ciática",
"roldana",
"honrara",
"pelágio",
"sanados",
"cacetão",
"catumbi",
"râguebi",
"biônica",
"gaxetas",
"amilase",
"engajou",
"exaurir",
"paletós",
"dobrara",
"picando",
"habitua",
"sufocou",
"enoteca",
"recitou",
"consume",
"obterão",
"traíras",
"salvava",
"nevando",
"iníquos",
"leiamos",
"mexerem",
"carecer",
"colibri",
"gândara",
"sinodal",
"terroso",
"melhoro",
"cutucou",
"crermos",
"mãezona",
"negrume",
"funding",
"emperra",
"orgulhe",
"oriento",
"supetão",
"trajava",
"matarão",
"tabular",
"manejos",
"citrino",
"figurão",
"caudais",
"risinho",
"experto",
"radioso",
"inexata",
"enforca",
"fiordes",
"rodamos",
"marital",
"ceifara",
"cátaros",
"crápula",
"lutarei",
"tragado",
"súbitos",
"bigamia",
"elipses",
"fortran",
"trajado",
"reagira",
"atacara",
"confina",
"renhida",
"esquife",
"alucina",
"gravosa",
"corrijo",
"lilases",
"remição",
"matraca",
"retinol",
"moçoila",
"mafiosa",
"anverso",
"atadura",
"tacanha",
"zoonose",
"fincado",
"saltear",
"piteira",
"poodles",
"tinhoso",
"cangote",
"boitatá",
"revezes",
"aspargo",
"intendi",
"revidou",
"gozaram",
"relaxem",
"aletria",
"rezaram",
"apícola",
"olarias",
"esfolar",
"pátrios",
"briguem",
"tacinha",
"rasteja",
"prenhez",
"lactase",
"lagunas",
"aflorou",
"neutral",
"ousasse",
"petúnia",
"ejacula",
"afeitos",
"denegar",
"invadam",
"novatas",
"lamelas",
"valetas",
"marajás",
"acamado",
"imbuída",
"fixarem",
"oxalato",
"pondere",
"provava",
"tremida",
"ordeiro",
"plácida",
"morávia",
"cantões",
"debocha",
"emergia",
"rezavam",
"cabarés",
"resfria",
"engasgo",
"flocada",
"soubera",
"surubas",
"miçanga",
"umbigos",
"esquivo",
"piração",
"acenava",
"galeras",
"comilão",
"golpeou",
"maculas",
"latejar",
"pirocas",
"pozinho",
"enxutos",
"somenos",
"gironda",
"bichona",
"reporto",
"anômalo",
"caseado",
"nêutron",
"matinês",
"dividam",
"salobra",
"voragem",
"drenada",
"uiraúna",
"abençoo",
"excluía",
"carimba",
"diurese",
"negaria",
"convexa",
"acólito",
"moringa",
"niveles",
"pirueta",
"erguiam",
"bravata",
"enurese",
"mixaria",
"alongue",
"fuchsia",
"charcos",
"rebelam",
"rizomas",
"giardia",
"amanhas",
"aprouve",
"acordas",
"desaire",
"liberei",
"nodular",
"dilatam",
"maioral",
"remetam",
"atrevia",
"delatar",
"tramada",
"frouxas",
"prenhes",
"fumados",
"receiam",
"quindim",
"intendo",
"saturar",
"ilógica",
"logados",
"arábico",
"abracem",
"postigo",
"suvenir",
"exporte",
"manjada",
"dopagem",
"recairá",
"lapônia",
"gracejo",
"madames",
"bovinas",
"coronha",
"machões",
"caberão",
"surtido",
"décimas",
"padecia",
"altivos",
"rumaram",
"assunta",
"sabidos",
"lavação",
"acaiacá",
"torciam",
"valerem",
"almoçam",
"amparam",
"sagital",
"optasse",
"pensais",
"cumbuca",
"esteios",
"tabocas",
"definha",
"astenia",
"crédulo",
"nidação",
"avisara",
"pulavam",
"gelando",
"baixara",
"ruminal",
"otomana",
"guiaram",
"eclusas",
"copaíba",
"anômala",
"ecoavam",
"invisto",
"origine",
"temerem",
"abaixam",
"efetive",
"fritada",
"destoar",
"habitué",
"forneco",
"bendiga",
"sarrafo",
"noviços",
"solados",
"tenores",
"porosas",
"imissão",
"medical",
"pelejar",
"rezarem",
"seletos",
"saxônia",
"joanete",
"inculta",
"redunda",
"beleléu",
"remanso",
"sinapse",
"apostem",
"retrair",
"frívola",
"chumbar",
"maoista",
"lodeiro",
"expandi",
"gaélico",
"saradas",
"meninão",
"paládio",
"adresse",
"reverse",
"alertei",
"sumidos",
"falindo",
"revivem",
"demover",
"poupava",
"patifes",
"nanicos",
"votasse",
"orvieto",
"sandice",
"aparada",
"temeram",
"subirei",
"azenhas",
"encalhe",
"lameiro",
"valônia",
"cogitei",
"piscada",
"imperou",
"ajeitei",
"lagamar",
"cogitam",
"absorta",
"axônios",
"andança",
"aninhar",
"deleção",
"inserto",
"reverta",
"limpado",
"mensura",
"letivas",
"detecte",
"ferrite",
"legaram",
"errinho",
"admirem",
"quáquer",
"xingava",
"bocejos",
"evasivo",
"inócuos",
"acorrem",
"espumar",
"exarada",
"grafada",
"dálmata",
"diferiu",
"cafonas",
"tomense",
"marines",
"fenício",
"saboreá",
"danação",
"laborar",
"roubara",
"vigiava",
"mamonas",
"manuseá",
"alforje",
"bacurau",
"pulôver",
"fugidia",
"esticam",
"lutarão",
"encerar",
"engordo",
"desmama",
"engajam",
"hesitem",
"líquens",
"ratinha",
"menagem",
"empurro",
"atafona",
"milicos",
"hostess",
"bistrôs",
"ligares",
"debutou",
"desfere",
"ideação",
"begônia",
"mousses",
"acarape",
"crochês",
"molhava",
"cubista",
"futures",
"girinos",
"viremos",
"vacilei",
"agudeza",
"gandula",
"faliram",
"trotski",
"valemos",
"recurvo",
"césares",
"aportam",
"abcesso",
"olharei",
"ataquei",
"inoculo",
"rosbife",
"deturpa",
"argúcia",
"abstrai",
"alçadas",
"arrotos",
"pilotou",
"caprino",
"laçados",
"desdita",
"infinda",
"empenhe",
"mimosas",
"uivando",
"alisado",
"tumular",
"chingar",
"arrendo",
"engatou",
"enfocam",
"minhoto",
"adverti",
"dispute",
"elogiei",
"lasciva",
"parrudo",
"operara",
"militou",
"épsilon",
"banhava",
"tarraxa",
"arataca",
"anciães",
"validez",
"afligia",
"eréctil",
"grifado",
"persigo",
"calarem",
"vândalo",
"relapso",
"tucanas",
"entente",
"decaída",
"ventres",
"puxavam",
"cristos",
"relutam",
"resvala",
"lusíada",
"arbitra",
"soturna",
"tostada",
"danados",
"sugados",
"nudista",
"bebesse",
"computa",
"oftalmo",
"lordose",
"poetiza",
"saibais",
"alugava",
"apartes",
"bendigo",
"defunta",
"pelejas",
"alanina",
"zoólogo",
"matarei",
"encenam",
"sicrano",
"murcham",
"juanita",
"vacante",
"mieloma",
"ecoaram",
"ufólogo",
"escorar",
"influiu",
"adquiro",
"purista",
"sudanês",
"néscios",
"escopos",
"estóico",
"esmiúça",
"disseca",
"vinhaça",
"submeti",
"equipou",
"frisbee",
"beirava",
"nevados",
"colorem",
"corados",
"falsear",
"retrate",
"araújos",
"eclodem",
"corveta",
"prensar",
"pigmeus",
"legação",
"rocinha",
"enfarto",
"frívolo",
"mamutes",
"zerinho",
"checado",
"declama",
"competi",
"repouse",
"dobrava",
"inaptos",
"ofuscou",
"beberei",
"penadas",
"brilhem",
"inverta",
"frescal",
"trincos",
"joguete",
"divisar",
"abortou",
"ondular",
"debalde",
"ralados",
"jorrava",
"adiaram",
"ciclope",
"nanismo",
"torquês",
"camélia",
"cavacos",
"lajinha",
"pangaré",
"coronal",
"odiaram",
"chateou",
"atracou",
"lituana",
"andaste",
"cabaças",
"novilha",
"ralinho",
"eczemas",
"pocinho",
"desabam",
"barbato",
"fluírem",
"parnaso",
"exitoso",
"rolarem",
"afamada",
"anêmico",
"viestes",
"imitada",
"arroubo",
"gélidas",
"bengali",
"ípsilon",
"capella",
"largara",
"belisca",
"colaram",
"avariou",
"goianas",
"marmota",
"trampos",
"balelas",
"endemia",
"glicina",
"punções",
"jasmins",
"botamos",
"mandril",
"moleiro",
"taxadas",
"expiram",
"distais",
"relegar",
"intende",
"lamúria",
"ómnibus",
"oxidase",
"acharas",
"fodidos",
"derreto",
"espraia",
"etéreas",
"sentara",
"estatui",
"odiosos",
"ancinho",
"ajustem",
"desovar",
"solutos",
"oficiar",
"retenha",
"queimas",
"relutou",
"reedita",
"ponteio",
"verdugo",
"alargam",
"códices",
"repensa",
"aplaudi",
"oitivas",
"fibrina",
"parcelo",
"tríades",
"assovio",
"céltica",
"rasgava",
"esperes",
"guelras",
"regride",
"choroso",
"bonsais",
"galaico",
"ofendam",
"apelida",
"chutada",
"hastear",
"concebo",
"copiava",
"restado",
"marreco",
"auferem",
"gônadas",
"parasol",
"gerasse",
"orbitar",
"culpava",
"pilosos",
"passeei",
"vicunha",
"cianose",
"fuzilar",
"nêmesis",
"remador",
"singrar",
"sonecas",
"convoco",
"granite",
"ecovias",
"felinas",
"exalado",
"proporá",
"tabelou",
"bombada",
"reverem",
"oxidada",
"viajada",
"lambuja",
"urupema",
"mofando",
"alcalde",
"sacudia",
"amintas",
"tiatira",
"gavetão",
"forçara",
"conduzo",
"enrosco",
"nevadas",
"demande",
"jerimum",
"intimou",
"bípedes",
"empolgo",
"etílica",
"segrega",
"berlina",
"vertida",
"dotadão",
"entorta",
"festina",
"invoque",
"jogares",
"objetar",
"mutismo",
"bávaros",
"aldeído",
"dançada",
"gamarra",
"vedetas",
"cabaret",
"mormaço",
"embrace",
"encolhi",
"lxxviii",
"amassem",
"frêmito",
"margeia",
"infunde",
"venosas",
"paidéia",
"cabrita",
"porcina",
"xavasca",
"alçados",
"odiaria",
"penando",
"devotas",
"costões",
"agissem",
"ansiado",
"rebaixo",
"boudoir",
"higidez",
"rodante",
"incidam",
"cornija",
"montara",
"agarrem",
"lemúria",
"vilares",
"cavalar",
"fugidio",
"alvéolo",
"unissem",
"litíase",
"barrera",
"catetos",
"trilham",
"brotara",
"bebezão",
"boleira",
"inserta",
"estanca",
"lamarão",
"lúpulos",
"joanina",
"gânglio",
"humilhe",
"sortida",
"torando",
"delicio",
"outonos",
"afastai",
"limpara",
"criveis",
"estreei",
"permeou",
"debemos",
"míseras",
"viciosa",
"esmagam",
"jantava",
"selaram",
"carvões",
"parelho",
"bisonho",
"gerarão",
"revesti",
"assolar",
"afáveis",
"strudel",
"orçados",
"azarada",
"cosseno",
"robalos",
"êxtases",
"solfejo",
"escapem",
"esbarro",
"decisor",
"ensinas",
"bianual",
"solinho",
"pescava",
"juramos",
"gazelas",
"manilha",
"salitre",
"afobado",
"baitola",
"apaches",
"ultimar",
"apelava",
"cavalas",
"arpejos",
"erguera",
"murchou",
"pascais",
"xenônio",
"alçando",
"trincha",
"báscula",
"adicção",
"gustado",
"barcaça",
"doidona",
"planura",
"matinho",
"sineira",
"cármico",
"aluvião",
"evicção",
"trincou",
"bolores",
"evoluam",
"vimeiro",
"tigrado",
"zerando",
"púbicos",
"radiosa",
"acessão",
"mediata",
"queirós",
"cremesp",
"atóxica",
"adendos",
"ofuscam",
"atentei",
"políbio",
"babetes",
"cônscio",
"sumério",
"afiliar",
"agilize",
"caloiro",
"ligório",
"ecovila",
"rugindo",
"cifrado",
"arariba",
"negasse",
"rosetas",
"colhões",
"intrusa",
"pisquei",
"sorrido",
"avaliei",
"alarmar",
"bugalho",
"chutney",
"ensaiei",
"assedia",
"ararica",
"galopar",
"nojeira",
"milonga",
"calhaus",
"joviais",
"chamais",
"penacho",
"féretro",
"amparou",
"revolve",
"terrina",
"glosses",
"acampam",
"chiados",
"cetonas",
"coloriu",
"comeres",
"vintena",
"drágeas",
"tubária",
"tapuias",
"aparado",
"desmate",
"acertem",
"gracita",
"enxutas",
"matutar",
"reluzir",
"passive",
"imporem",
"acelero",
"cochila",
"rodopio",
"acalmei",
"tenazes",
"pagável",
"curável",
"sabidas",
"tapadas",
"irritei",
"cavadas",
"arzinho",
"cármica",
"dulcina",
"arruaça",
"babuíno",
"coortes",
"pousava",
"molesta",
"afrouxa",
"trairão",
"arrolar",
"achatar",
"tínheis",
"conjura",
"devotar",
"milheto",
"derramo",
"sufoque",
"estroma",
"pesinho",
"modelou",
"parenta",
"gruyère",
"durarão",
"escorou",
"grafita",
"unívoca",
"queixos",
"reprisa",
"buracão",
"ricaços",
"epítome",
"explana",
"adéquem",
"venciam",
"insider",
"lunetas",
"pérfida",
"flertes",
"rodavam",
"beduíno",
"etrusca",
"mudemos",
"lavarem",
"fixamos",
"zambujo",
"lambido",
"peixada",
"jabutis",
"lutemos",
"chumaço",
"relutar",
"softbol",
"visaram",
"palhada",
"tomista",
"fumaram",
"gravita",
"paquito",
"fadadas",
"recusem",
"manetes",
"nefrite",
"tosquia",
"retinha",
"entoada",
"pegarão",
"chambre",
"lixinho",
"doninha",
"freadas",
"sumular",
"vacilos",
"efusiva",
"recicle",
"cacetes",
"permeio",
"caterva",
"fingiam",
"afonsos",
"diorama",
"adubada",
"montijo",
"comerei",
"soveral",
"buchada",
"carreia",
"lavavam",
"parindo",
"piraquê",
"tântalo",
"camafeu",
"ilibado",
"aguinha",
"gestado",
"regrado",
"lagunar",
"denigre",
"evapore",
"deporte",
"atenuam",
"medusas",
"lácteas",
"copista",
"adequou",
"vicário",
"lepanto",
"bambina",
"lambuza",
"pépsico",
"índigos",
"malogro",
"radicou",
"ilhados",
"agendei",
"apiário",
"razones",
"eslavas",
"agachou",
"pangeia",
"aninhas",
"premido",
"chacais",
"gozarão",
"caçavam",
"vedante",
"sacolão",
"carrada",
"abaixem",
"aderida",
"cilícia",
"tipinho",
"metesse",
"prédica",
"cabinas",
"pirados",
"toninha",
"arquive",
"banhara",
"receava",
"pilhado",
"togados",
"encarem",
"caloura",
"bitucas",
"pisados",
"banindo",
"sacodem",
"curtiam",
"mímicas",
"rechaço",
"soviete",
"martela",
"reputar",
"ajoelhe",
"curasse",
"pinagem",
"impelem",
"hititas",
"araruta",
"trombos",
"previra",
"escalam",
"tesudos",
"bretões",
"venosos",
"galitos",
"rotário",
"cabinho",
"culotes",
"ducados",
"palanca",
"tranche",
"plasmar",
"cederem",
"intense",
"procedo",
"suprirá",
"balaios",
"cavaram",
"gaulesa",
"bichará",
"bardana",
"tributa",
"acamada",
"enganas",
"petizes",
"apronto",
"sepulta",
"fuzilou",
"brâmane",
"quistos",
"odiarem",
"piaçava",
"jogarei",
"serrado",
"injetam",
"cavados",
"calamos",
"sagazes",
"genovês",
"editava",
"tremido",
"penseis",
"fitilho",
"aztecas",
"rabeira",
"fuleiro",
"dondoca",
"berílio",
"apeteça",
"garboso",
"asseado",
"bucelas",
"caapora",
"gozador",
"abramos",
"brocado",
"javanês",
"ruminar",
"rompera",
"pivetes",
"renegou",
"sublima",
"canecos",
"crassos",
"chutava",
"isentou",
"tirarão",
"pérfido",
"traídas",
"aramina",
"relutei",
"rodarem",
"jugador",
"pontões",
"regulou",
"agarras",
"vincado",
"vedetes",
"marista",
"esgotem",
"emitiam",
"advogou",
"descrer",
"abrigue",
"cedemos",
"clonada",
"copular",
"cabeada",
"rotador",
"renasça",
"bombona",
"inclino",
"eclésia",
"boleias",
"temário",
"magrebe",
"biótico",
"caibros",
"machuco",
"vomitam",
"copiosa",
"rotulam",
"engôdos",
"sístole",
"plebeia",
"firmara",
"efetuem",
"enraizá",
"vicaria",
"grenada",
"chumbos",
"meteção",
"serafim",
"embolar",
"humoral",
"subtema",
"desviem",
"flagrei",
"derrapa",
"vermeil",
"augúrio",
"impomos",
"tainhas",
"capitel",
"mascate",
"sedição",
"renovei",
"tópicas",
"hospedo",
"aramada",
"rotundo",
"porcada",
"cônicos",
"têmporo",
"jotinha",
"enverga",
"codeína",
"detenho",
"curtume",
"embeber",
"platino",
"imberbe",
"protões",
"fulanos",
"pedalei",
"require",
"adenina",
"testara",
"pichado",
"enojada",
"memento",
"talhões",
"bloqueá",
"corpora",
"reaviva",
"quitute",
"arredar",
"demitem",
"dígrafo",
"gárgula",
"maracas",
"tolhido",
"abadias",
"catinga",
"espetou",
"socorra",
"iguanas",
"lesiona",
"rissóis",
"esculpe",
"odiosas",
"apresso",
"bulício",
"cágados",
"gagueja",
"mulambo",
"aórtico",
"alisava",
"pipetas",
"procedi",
"teoriza",
"lesando",
"surtira",
"usurpou",
"andinas",
"toureio",
"melosas",
"versara",
"rendiam",
"enxaguá",
"flutuou",
"cegando",
"aquosos",
"trôpego",
"prolixa",
"destoam",
"cálidas",
"carrara",
"recaiam",
"extraio",
"arredia",
"fonação",
"amieira",
"regatos",
"abdicam",
"jacundá",
"escovei",
"cuspida",
"acirrou",
"minguar",
"relapsa",
"notaria",
"viçosas",
"tibieza",
"caiados",
"longeva",
"violava",
"fracote",
"larápio",
"refugos",
"murchos",
"renovem",
"xeretar",
"surtada",
"polacas",
"calasse",
"regalos",
"coligar",
"reabrem",
"bibelôs",
"danadas",
"olharão",
"proveio",
"cachepô",
"thinner",
"exprima",
"freando",
"mesclou",
"movente",
"chinela",
"boladas",
"acorrer",
"serenar",
"pendiam",
"monismo",
"carnudo",
"fondues",
"cafezal",
"englobe",
"laçador",
"miasmas",
"ousavam",
"crooner",
"pitombo",
"mialgia",
"caducas",
"afilado",
"congelo",
"birosca",
"patroas",
"estelas",
"inseria",
"gérbera",
"mediato",
"repeito",
"jamanta",
"açoitar",
"sacaria",
"cérbero",
"neonato",
"alegrei",
"exógeno",
"ilhoses",
"bismuto",
"pecíolo",
"pulsado",
"ejetado",
"samsara",
"gozados",
"bósnios",
"imigrou",
"grotões",
"palinha",
"lêmures",
"dedadas",
"munidas",
"votavam",
"trairia",
"eivados",
"pedalou",
"vingada",
"abóboda",
"ouviras",
"tablier",
"calcava",
"lidador",
"sarcoma",
"degolar",
"cacimba",
"lisonja",
"barrosa",
"empinou",
"morcela",
"pedalam",
"longevo",
"acorreu",
"aditado",
"inebria",
"batizam",
"baracho",
"iônicas",
"creiais",
"titânia",
"etérica",
"campeia",
"mapinha",
"cintila",
"abortam",
"calibra",
"calango",
"culhões",
"bradava",
"fortões",
"florete",
"refutam",
"indagam",
"cedesse",
"modista",
"nanicas",
"pigarro",
"libação",
"inovado",
"julgais",
"escunas",
"agredia",
"bonheur",
"tropece",
"lêndeas",
"coiotes",
"levares",
"copinha",
"embater",
"lixados",
"cotovia",
"glóbulo",
"regadio",
"traçava",
"acalmia",
"ventava",
"vetusta",
"padeira",
"sumisse",
"variara",
"buscais",
"motivem",
"torneis",
"batalhe",
"musicar",
"rimando",
"suprimi",
"topando",
"pontuda",
"aquivos",
"mexemos",
"provoco",
"lembres",
"esnobes",
"renasci",
"jantado",
"soltara",
"corteja",
"pápulas",
"dominós",
"tabacos",
"ungidas",
"grânulo",
"lenhosa",
"lutasse",
"gélidos",
"achemos",
"tritões",
"andavas",
"esnobar",
"poranga",
"taxando",
"corréus",
"alongou",
"revolte",
"boicota",
"omissas",
"transes",
"tomaste",
"pingava",
"versava",
"lorotas",
"machina",
"adenoma",
"tacanho",
"azulino",
"cínicas",
"movesse",
"estrema",
"averbar",
"logical",
"esfrego",
"amargam",
"bichado",
"amídala",
"erosiva",
"ansiada",
"cruzeta",
"trombar",
"induzam",
"fogaréu",
"ovócito",
"validam",
"benzina",
"matizar",
"pendant",
"trisavó",
"fodidas",
"laicato",
"vetadas",
"comeste",
"femural",
"tremoço",
"vinténs",
"siamesa",
"trófico",
"abobado",
"aclamar",
"arquear",
"batucar",
"exitada",
"limpada",
"focarem",
"rafeiro",
"vincule",
"bebiano",
"glicada",
"arabutã",
"passaré",
"elegias",
"soprada",
"maqueta",
"beberia",
"nervura",
"batutas",
"reprimi",
"excitou",
"arrebol",
"excedia",
"fiascos",
"pularem",
"rolinha",
"kazinho",
"zangões",
"implore",
"falaras",
"embaúba",
"quiabos",
"diferia",
"realejo",
"jograis",
"lidaram",
"frígida",
"sonante",
"galeões",
"fundava",
"recebei",
"apartou",
"vermute",
"jugando",
"picotar",
"majorar",
"acampou",
"bibiano",
"emudece",
"inflada",
"minerar",
"cercano",
"decenal",
"imponho",
"ameacem",
"melosos",
"obtusos",
"pegunta",
"anémona",
"julgues",
"carapau",
"boreais",
"apostos",
"arqueou",
"fodinha",
"teceram",
"ajoelho",
"atordoa",
"entupiu",
"infindo",
"cessada",
"ungueal",
"decídua",
"luteína",
"acciona",
"anfíbia",
"teríeis",
"etrusco",
"acéfala",
"retomas",
"fráguas",
"podadas",
"meditam",
"infesta",
"esganar",
"ventila",
"somarem",
"xingada",
"detetor",
"diáfano",
"tubaína",
"plagiou",
"resigna",
"atávica",
"vivinho",
"reprima",
"chumbou",
"chorara",
"salteie",
"suscite",
"acolham",
"sebácea",
"clarins",
"uísques",
"floreal",
"seduzia",
"rodopia",
"hádrons",
"linchar",
"imprimo",
"queixem",
"refrega",
"topamos",
"derreti",
"dolores",
"abrires",
"lobitos",
"charrua",
"miosina",
"tchecas",
"careira",
"efusivo",
"sopeira",
"entoava",
"cressem",
"afoguei",
"cupidez",
"xavante",
"gozasse",
"ajuntam",
"emigram",
"mangaba",
"ramadão",
"dérmica",
"somarão",
"abrevia",
"póstero",
"adiamos",
"alardes",
"saradão",
"quéchua",
"frisada",
"pelinho",
"engorde",
"testava",
"cifrões",
"mexesse",
"púberes",
"alminha",
"exultai",
"folheei",
"redinha",
"gotejar",
"criemos",
"vetusto",
"bólidos",
"gabardo",
"tacando",
"desfaze",
"alumiar",
"afundei",
"gêneses",
"patrola",
"petisca",
"saltara",
"cruzara",
"lustrar",
"cessões",
"tiremos",
"cessava",
"viveste",
"fugirão",
"aclives",
"chiando",
"impelir",
"impende",
"reelege",
"colenda",
"corvina",
"sitiado",
"cúbicas",
"ressoou",
"tramway",
"ceifada",
"magusto",
"jataúba",
"soletra",
"panados",
"itapema",
"joanino",
"piropos",
"fílmico",
"fianças",
"sonidos",
"cujubim",
"onívoro",
"salames",
"despoja",
"gestada",
"planeio",
"predita",
"casacão",
"tornear",
"confiro",
"corpaço",
"puxamos",
"lotavam",
"esboçam",
"plafond",
"pacatos",
"tétrico",
"micélio",
"sufismo",
"dúplice",
"macerar",
"gritara",
"citaria",
"dengoso",
"betting",
"galeses",
"ciclano",
"álcalis",
"caiamos",
"beijaço",
"romeiro",
"apensar",
"catuaba",
"aguadas",
"enlaçar",
"timbira",
"pareado",
"prezava",
"reagiam",
"cogente",
"azougue",
"examino",
"lenhoso",
"furaram",
"ameacei",
"entreis",
"minados",
"vagavam",
"pesetas",
"títeres",
"vendado",
"feteira",
"iídiche",
"tijolão",
"baralha",
"rebatem",
"mundias",
"escusou",
"entorna",
"espiava",
"tachada",
"colamos",
"quartil",
"lidavam",
"comande",
"frutada",
"mastruz",
"nursery",
"solapar",
"cederia",
"inumano",
"notarão",
"bicadas",
"itatiba",
"brigões",
"repeliu",
"cocadas",
"detemos",
"janelão",
"alísios",
"recitam",
"quilhas",
"burrico",
"chanfro",
"ladeiam",
"bouches",
"antevia",
"galheta",
"lagoona",
"domados",
"ponchos",
"fomente",
"ajustei",
"céptica",
"acercar",
"atuário",
"caiadas",
"esguias",
"esquiam",
"armeiro",
"ligarei",
"obaluaê",
"embasou",
"antíqua",
"reparti",
"reteste",
"agridam",
"reviram",
"comungo",
"exílios",
"cindida",
"céticas",
"encarta",
"oócitos",
"cheirei",
"satisfa",
"ramagem",
"idiotia",
"preguem",
"orçadas",
"rangido",
"matrona",
"giestas",
"tremolo",
"faxinas",
"sultões",
"desabei",
"excluam",
"corando",
"cabrões",
"fundara",
"templar",
"evadido",
"fedelho",
"perfila",
"inalada",
"folheia",
"abotoar",
"astutas",
"deixemo",
"tosador",
"argenta",
"acolhei",
"absorvi",
"enumere",
"geniosa",
"rentrée",
"evocava",
"planeou",
"abriste",
"atuasse",
"arderam",
"treinem",
"merluza",
"tolhida",
"dolente",
"andores",
"ovalada",
"veludos",
"umedece",
"ancorou",
"varando",
"chevron",
"bifinho",
"lixadas",
"proíbam",
"emendei",
"difundi",
"cubinho",
"imuniza",
"coagida",
"entedia",
"perigeu",
"escalei",
"reconto",
"monturo",
"clorada",
"arvorar",
"pequisa",
"lonjura",
"demoliu",
"iorubas",
"bendize",
"tácteis",
"lodaçal",
"absorvo",
"facinha",
"arvoram",
"atresia",
"tardava",
"olíbano",
"retomem",
"erosivo",
"ajuntai",
"chuvada",
"tarugos",
"piorada",
"jardine",
"pisarem",
"guanina",
"rebolou",
"recitas",
"curaram",
"fezinha",
"tabelar",
"pataias",
"terenos",
"amenize",
"rolasse",
"triunfe",
"ginetes",
"mimosos",
"advirto",
"porcino",
"maturar",
"exegeta",
"vegetar",
"purismo",
"deixamo",
"maleita",
"maromba",
"chocava",
"oblongo",
"atávico",
"bonobos",
"tórrida",
"rolaria",
"busílis",
"papilar",
"chicana",
"facista",
"sôfrego",
"fascite",
"cúspide",
"errarem",
"insânia",
"tomares",
"anêmica",
"incitem",
"tirares",
"virarão",
"amardes",
"trafica",
"pacense",
"revemos",
"sintase",
"adensar",
"apalpou",
"bufunfa",
"empilha",
"viradão",
"panelão",
"brotado",
"urrando",
"saqueou",
"tirreno",
"acendam",
"manejam",
"concedi",
"blitzes",
"licorne",
"vultosa",
"dracmas",
"surtado",
"bravios",
"mudinha",
"negarei",
"costuro",
"emérita",
"amorais",
"lampara",
"bonomia",
"fraudou",
"odiadas",
"sorrira",
"baniram",
"golinho",
"alcanos",
"moleira",
"puxasse",
"cobiças",
"plugada",
"premiam",
"cacoete",
"êmbolos",
"fétidos",
"fedidos",
"ovalado",
"faseada",
"suedine",
"lageano",
"solando",
"zarolho",
"alívios",
"malaios",
"acionei",
"agrupou",
"prostra",
"volição",
"arfando",
"roliças",
"adictos",
"fritado",
"marsala",
"ubarana",
"arneiro",
"chicane",
"girador",
"encetou",
"nhoques",
"encolha",
"escusar",
"sopapos",
"reenvia",
"impeliu",
"fiações",
"planeie",
"cachear",
"gemelar",
"engrena",
"safáris",
"étamine",
"petecas",
"amassam",
"blogado",
"inflige",
"pífanos",
"vacilam",
"adentre",
"enamora",
"evelina",
"amieiro",
"capinar",
"caranga",
"escalde",
"nutriam",
"olhaste",
"salmões",
"houston",
"instada",
"arriada",
"milanês",
"flyback",
"linóleo",
"nomeava",
"emulado",
"florins",
"refazem",
"arrudas",
"caberem",
"pularia",
"zepelim",
"criarei",
"choquei",
"abanava",
"habitas",
"debutar",
"legadas",
"esfriam",
"babarem",
"comutar",
"ombrear",
"podando",
"ermidas",
"mourões",
"meteres",
"giraram",
"retenho",
"aciaria",
"estudas",
"criares",
"esbater",
"cinemão",
"puxarem",
"rabisca",
"vingara",
"laranjo",
"rateado",
"cilício",
"nadavam",
"amorfos",
"durezas",
"perebas",
"medulas",
"festeje",
"afogava",
"tartufo",
"algemar",
"pondero",
"trezena",
"epífita",
"deitara",
"escâner",
"valorem",
"guardes",
"trolhas",
"meditou",
"repicar",
"zeradas",
"amarrem",
"avocado",
"apessoa",
"covinha",
"fajutas",
"ingente",
"armilar",
"aceitai",
"lapelas",
"quitina",
"vaiados",
"abrasar",
"cranial",
"cárneos",
"decides",
"marotos",
"umbaúba",
"rugidos",
"azurara",
"pazinha",
"gipsita",
"gênicas",
"curgete",
"regrava",
"vendada",
"monista",
"recheia",
"aramida",
"figurem",
"afiliou",
"telhada",
"criptas",
"capuzes",
"doridos",
"fixidez",
"ungindo",
"iatismo",
"mirrado",
"abalara",
"tânatos",
"crivado",
"locável",
"charmes",
"difunda",
"volutas",
"zunindo",
"musique",
"primeva",
"bisonha",
"exsurge",
"diminuo",
"unívoco",
"afirmas",
"amplexo",
"enófilo",
"firmava",
"opalina",
"enervar",
"destruo",
"ruinoso",
"sanando",
"votaste",
"encalha",
"prosear",
"apanhem",
"vacatio",
"deteste",
"engessa",
"ruinosa",
"atestem",
"avariar",
"citavam",
"ofendia",
"ativara",
"incidia",
"embaçar",
"víscera",
"ameijoa",
"grelina",
"quélida",
"vestais",
"agriões",
"permuto",
"tracoma",
"derrubo",
"exprimo",
"mudaste",
"acessas",
"parusia",
"intuído",
"desatam",
"plancha",
"avances",
"anulara",
"ânforas",
"affaire",
"hominis",
"logando",
"sanitas",
"despeça",
"pletora",
"beatnik",
"profusa",
"relevam",
"sapecas",
"valadas",
"vaiaram",
"fixavam",
"gordona",
"oprimia",
"aduanas",
"frieira",
"rosnado",
"bumbuns",
"celíaco",
"firmina",
"ravinas",
"pertine",
"exploro",
"instrua",
"zimbros",
"esmerou",
"oponham",
"arminho",
"nominar",
"esguios",
"imputou",
"podamos",
"pequeis",
"ascetas",
"aceptar",
"guapira",
"boiadas",
"revisei",
"fichado",
"debulha",
"casarei",
"melando",
"bulevar",
"léxicos",
"serôdia",
"laxismo",
"collant",
"amanita",
"carango",
"vanerão",
"sumidas",
"trineto",
"digerem",
"batuíra",
"agiriam",
"desejes",
"furtava",
"pinguço",
"texanos",
"socador",
"rabicho",
"arcádio",
"cânulas",
"embateu",
"rabecão",
"teliana",
"ossanha",
"arruada",
"andaras",
"minimum",
"goiabal",
"tecemos",
"pubiana",
"céltico",
"decepar",
"trófica",
"almejou",
"suborna",
"enlaçam",
"romagem",
"atroari",
"dentuça",
"fitavam",
"notavam",
"maltado",
"ariscos",
"renegam",
"joanita",
"beijões",
"regímen",
"relatem",
"amontoa",
"roncava",
"vigotas",
"medievo",
"bebidos",
"castiço",
"diletos",
"retrete",
"exprimi",
"labutam",
"palhais",
"sararei",
"tigrada",
"vaqueta",
"foderam",
"pútrido",
"soalhos",
"tolinho",
"côndilo",
"sarilho",
"maltino",
"jurunas",
"diáfana",
"joviano",
"refilar",
"grafema",
"escovou",
"escusam",
"máscula",
"calcita",
"propões",
"flertou",
"formulo",
"juravam",
"estalam",
"valetes",
"amoldar",
"detonei",
"lidinha",
"teóloga",
"estufou",
"foderem",
"legando",
"alijado",
"espelhe",
"deverem",
"lavores",
"polindo",
"exumado",
"colhiam",
"lipídio",
"aturado",
"órganon",
"bramido",
"perfure",
"animara",
"decotão",
"swahili",
"edifico",
"cingido",
"amassei",
"arrelia",
"pindoba",
"exitado",
"forfait",
"ondinas",
"oximoro",
"provejo",
"siríaco",
"advenha",
"amoleça",
"forania",
"vernier",
"roteado",
"empacou",
"esbocei",
"mostrai",
"compele",
"veniais",
"boazona",
"esfirra",
"mataste",
"mimando",
"retraiu",
"steward",
"benjoim",
"espetro",
"libério",
"afrique",
"caduceu",
"medirão",
"emersão",
"liteira",
"oficiou",
"trustes",
"mofados",
"sisudos",
"meditem",
"anáfora",
"isômero",
"sôfrega",
"judiado",
"férrico",
"apanhas",
"deduzem",
"adorara",
"compres",
"ditavam",
"alastre",
"ardidas",
"exultou",
"isentam",
"baratão",
"cucuias",
"mocismo",
"aráveis",
"rotulas",
"epífise",
"flertam",
"abatera",
"casacas",
"achaque",
"louvava",
"frufrus",
"trampar",
"reponha",
"apicais",
"balizam",
"duvides",
"fugirei",
"aspirou",
"eduquem",
"prenhas",
"toqueis",
"vagueio",
"fuzarca",
"afiando",
"delegam",
"ousarem",
"manitol",
"deleito",
"botarem",
"cabrais",
"rançoso",
"pichada",
"ênclise",
"toxidez",
"paroles",
"biplano",
"ondinha",
"girante",
"presidi",
"roletas",
"fustiga",
"brandes",
"fresnel",
"temesse",
"fajutos",
"subrede",
"estirão",
"catação",
"depondo",
"tolinha",
"scholar",
"ferimos",
"liquide",
"pitomba",
"navarro",
"bojador",
"catimba",
"chistes",
"cancelo",
"citarem",
"fulmina",
"afiança",
"livrava",
"captava",
"exíguos",
"alpinas",
"delatou",
"bocarra",
"scanear",
"inodora",
"arestos",
"maleato",
"escoado",
"viesses",
"azagaia",
"brandir",
"estônio",
"recobra",
"antúrio",
"manacás",
"exangue",
"octetos",
"cazaque",
"reponde",
"interne",
"trucada",
"dioxina",
"escavam",
"azienda",
"limiano",
"presumi",
"civitas",
"vaiando",
"antigão",
"maxwell",
"findado",
"holismo",
"arredor",
"bombava",
"capotas",
"iníquas",
"sulinos",
"freires",
"socados",
"ineptos",
"regulem",
"irrigam",
"estofar",
"musicou",
"dopados",
"extinga",
"insiram",
"silvano",
"bazucas",
"caprina",
"oxálico",
"denário",
"foleiro",
"imporia",
"incutiu",
"evoluía",
"esperai",
"cerceia",
"locamos",
"protegi",
"tricote",
"sangrei",
"temeria",
"grifada",
"odiasse",
"meteria",
"girasol",
"puberal",
"designo",
"venérea",
"ancoram",
"esgotei",
"fumamos",
"nozinho",
"amurada",
"gineceu",
"símiles",
"pipocou",
"cacifos",
"aterram",
"abalone",
"cálidos",
"evasões",
"arredou",
"sátiros",
"rinites",
"ardores",
"xinguem",
"alterno",
"canzana",
"sedeado",
"marvada",
"maoismo",
"pataxós",
"mecônio",
"tricota",
"habitem",
"pevides",
"raiando",
"impuser",
"mutreta",
"risquei",
"adâmica",
"anáguas",
"falavas",
"frontes",
"alargue",
"atalhou",
"acercou",
"curarem",
"inhames",
"mixando",
"alienou",
"estacou",
"alcaria",
"acusara",
"bundões",
"morfema",
"reluzia",
"inexato",
"montões",
"taciano",
"deposta",
"avoluma",
"cursada",
"elaboro",
"pudeste",
"enrabar",
"ficavas",
"cordato",
"relevou",
"folares",
"pacatas",
"asilado",
"magotes",
"saudava",
"sovando",
"servias",
"flebite",
"modorra",
"pasmada",
"pênfigo",
"espalda",
"ajeitam",
"lunação",
"alpinos",
"lavasse",
"purinas",
"milorde",
"nominho",
"folheie",
"sonegam",
"bragado",
"oxigena",
"oxítona",
"pulasse",
"fluirão",
"detiver",
"biótica",
"isolava",
"invocai",
"perdemo",
"acneica",
"erravam",
"fraudas",
"aladino",
"matutos",
"lavrara",
"safanão",
"áscaris",
"esparso",
"maleiro",
"pompoar",
"escoada",
"guiarão",
"aventou",
"estolas",
"cistina",
"roxinha",
"deterem",
"acentue",
"debacle",
"juradas",
"girasse",
"léxicon",
"panicum",
"decrete",
"jordano",
"livônia",
"zombava",
"afligiu",
"relegou",
"catiguá",
"tolerou",
"juranda",
"pérgula",
"editara",
"expelem",
"adiposa",
"jocosos",
"olmecas",
"vacinou",
"obrigas",
"mesclas",
"vosmecê",
"pesaria",
"puserem",
"romenas",
"adubado",
"gerirem",
"apronte",
"colarem",
"mícrons",
"angaria",
"patacho",
"espiona",
"uzbeque",
"apitado",
"archote",
"alquila",
"babalaô",
"sorraia",
"forneci",
"babuche",
"sugadas",
"sisudez",
"assopro",
"láurico",
"aluvial",
"petites",
"frutais",
"preamar",
"findara",
"ferrões",
"labrego",
"soufflé",
"dosados",
"ferisse",
"nazireu",
"vultoso",
"felação",
"infarte",
"grunhiu",
"amapola",
"relevem",
"caninha",
"fórmico",
"cemento",
"carolas",
"arrozal",
"comporá",
"desvãos",
"railway",
"cotando",
"noviças",
"brigara",
"ascenda",
"desloco",
"parabém",
"gloriam",
"madeixa",
"maxilas",
"falecia",
"solanas",
"tremens",
"astarte",
"promovo",
"passais",
"delirou",
"tabefes",
"faixada",
"moldava",
"sínodos",
"filtrou",
"cumular",
"googlar",
"removam",
"selaria",
"zombado",
"parolos",
"alinhem",
"xilitol",
"crédula",
"rebarba",
"guiasse",
"marolas",
"bateras",
"chateie",
"farofas",
"brunete",
"compile",
"sésseis",
"mudares",
"chegues",
"caducar",
"vagarem",
"topetes",
"adestra",
"ulmeiro",
"laboram",
"palmier",
"bagaços",
"custeia",
"vivinha",
"errasse",
"tratore",
"anteras",
"botijas",
"enfocou",
"sabotam",
"demores",
"arrasem",
"amabile",
"múrmuro",
"burlado",
"faisões",
"flutter",
"coopere",
"devotou",
"olvides",
"califas",
"carcará",
"lasquei",
"clicava",
"amolada",
"calvice",
"regalar",
"tecelãs",
"enrugar",
"mapeiam",
"pitinga",
"pilinha",
"esfolia",
"radiola",
"detrito",
"atalhar",
"germens",
"hialina",
"ateando",
"palavas",
"tocarei",
"egressa",
"palhana",
"bolhosa",
"compões",
"empenas",
"abraças",
"somaria",
"cúmulos",
"fétidas",
"rebocos",
"caducou",
"primevo",
"esmerar",
"sádicas",
"brincas",
"mônadas",
"decolam",
"repisar",
"cauções",
"guaimbé",
"tiração",
"caubóis",
"cruenta",
"atenuou",
"curaria",
"semitom",
"fumarem",
"garajau",
"achegas",
"implode",
"papaína",
"grilhão",
"colinha",
"zoações",
"abonada",
"guizado",
"mapuche",
"pepsina",
"dissipe",
"nefando",
"lambris",
"neurite",
"tácitos",
"flautim",
"incorra",
"sumiços",
"glutões",
"rameira",
"saburra",
"exérese",
"inulina",
"abalava",
"chateio",
"patudos",
"bobeada",
"champôs",
"guiavam",
"induzia",
"pinador",
"revogue",
"hesites",
"amélias",
"frustar",
"braúnas",
"tobogãs",
"renhido",
"humaitá",
"irradie",
"rolados",
"inusual",
"marimba",
"beberão",
"mesadas",
"perfuro",
"nadaram",
"fuleira",
"primava",
"reparas",
"assanha",
"fenecer",
"clamado",
"altista",
"dentuço",
"anteviu",
"baixios",
"aileron",
"arianas",
"bebamos",
"pincela",
"praliné",
"abonado",
"cajuína",
"embarga",
"christi",
"ampliei",
"pombais",
"ditosos",
"alheado",
"deguste",
"ornados",
"atracam",
"agarose",
"desposa",
"arranho",
"leremos",
"seminus",
"baixela",
"remixar",
"afobada",
"tarimba",
"fendido",
"luminar",
"exultar",
"lastros",
"rogaram",
"farrear",
"quíchua",
"dosando",
"alarmou",
"agradei",
"recetor",
"folheio",
"cultual",
"braveza",
"penetro",
"cizânia",
"curavam",
"digitam",
"trajada",
"redário",
"cuidais",
"calazar",
"ferirem",
"pirabas",
"esmeram",
"beloura",
"ferrita",
"rugosas",
"vacúolo",
"amainar",
"grafiti",
"abundou",
"curumim",
"tanhaçu",
"arrogar",
"terceto",
"assopre",
"tralalá",
"machete",
"pilhada",
"podeira",
"xantana",
"remadas",
"judicia",
"costais",
"embolou",
"familie",
"extirpa",
"barbela",
"vetando",
"leseira",
"naninha",
"muretas",
"desmata",
"sibilos",
"sondada",
"travara",
"díptico",
"momesca",
"alcenos",
"fardada",
"chuchus",
"melados",
"dereito",
"fumegar",
"ordália",
"esnobou",
"franjão",
"ínclito",
"obteres",
"plantes",
"rebolam",
"paisano",
"rateios",
"salinos",
"bateira",
"sucumbi",
"sulfito",
"recrear",
"agracia",
"ligarão",
"espelta",
"prolina",
"flexora",
"tesauro",
"rimados",
"alienam",
"breslau",
"cobiçam",
"bazófia",
"petrino",
"resgato",
"canchas",
"conetor",
"apurava",
"notemos",
"estrupa",
"facismo",
"bicando",
"inumana",
"labutar",
"zenital",
"fricote",
"gritada",
"lastimo",
"censuro",
"quartis",
"defumar",
"poveira",
"sujeite",
"promovi",
"mofadas",
"calipso",
"deixais",
"falecem",
"erógena",
"enxotar",
"estagia",
"delonga",
"rotulou",
"deístas",
"encolho",
"visites",
"presuma",
"jauense",
"macheza",
"carajás",
"bolados",
"quirera",
"derivas",
"abundar",
"ximbica",
"adelita",
"galopim",
"tirador",
"esgares",
"corroeu",
"mixadas",
"atrevam",
"tânagra",
"carocha",
"alperce",
"nadamos",
"reforme",
"roceiro",
"votivas",
"ensaboa",
"fonador",
"quinino",
"cachaço",
"limbada",
"priscas",
"carbura",
"embolsa",
"atrasem",
"sumaúma",
"baseara",
"avivada",
"dormita",
"esteiro",
"exortam",
"yuppies",
"grassam",
"plafons",
"védicos",
"cimeiro",
"plainas",
"varejos",
"bachata",
"quelato",
"retraem",
"venéreo",
"guiarem",
"reparto",
"arfante",
"lignano",
"devires",
"fujamos",
"lípides",
"perobas",
"pixarro",
"porfias",
"trintão",
"corujão",
"cercana",
"ecônomo",
"aplauda",
"punjabi",
"radicar",
"apavore",
"pisavam",
"tocaste",
"cesáreo",
"bêbedos",
"deliram",
"reisado",
"levaras",
"moedura",
"cavilha",
"vergado",
"nepalês",
"espaçar",
"minuano",
"encrava",
"texugos",
"festins",
"impelia",
"cansara",
"exauriu",
"expeliu",
"azulina",
"pararão",
"escalpo",
"lantana",
"muriqui",
"esclera",
"kerning",
"abjetos",
"láctica",
"roçados",
"premida",
"extasia",
"vilinha",
"ladinos",
"conjuro",
"sonares",
"desatei",
"pileque",
"cotejar",
"deplora",
"jacente",
"rogarei",
"anhanga",
"anulava",
"curvava",
"ilhadas",
"avaliza",
"optavam",
"rabiola",
"luvinha",
"estagna",
"remexia",
"apitava",
"fidúcia",
"vibrado",
"postara",
"pesasse",
"minaram",
"vedador",
"feérico",
"aleijar",
"ceifado",
"pousara",
"maltose",
"barreta",
"advirão",
"arresta",
"medirem",
"palomas",
"confias",
"feirões",
"orassem",
"bifurca",
"ordenes",
"mateiro",
"lingote",
"meditei",
"catatau",
"rezasse",
"surfada",
"esculta",
"malocas",
"modulam",
"rodasse",
"alvores",
"caudado",
"aduelas",
"mastigo",
"remonte",
"acriano",
"pistilo",
"jônicas",
"podengo",
"subamos",
"incubar",
"trações",
"improve",
"pregara",
"soçaite",
"meteste",
"botavam",
"manolos",
"rebrota",
"brejões",
"magoava",
"atirara",
"escavou",
"melação",
"empilhe",
"tutelam",
"trigais",
"vivalma",
"palatal",
"panicos",
"ósculos",
"amplias",
"apatita",
"comovia",
"baterão",
"fafense",
"chaveta",
"comamos",
"sabotou",
"remexeu",
"avultam",
"esquive",
"negaste",
"térreos",
"podados",
"procria",
"barrela",
"bobocas",
"bocetas",
"macaúba",
"asseada",
"fatiota",
"combato",
"catitas",
"sequoia",
"dilatou",
"palhota",
"temples",
"isótipo",
"debugar",
"afastes",
"gazebos",
"perigar",
"benzida",
"cupidos",
"fluíram",
"papista",
"revisam",
"crescei",
"ingeria",
"jóqueis",
"abarcou",
"cismado",
"decidas",
"pelanca",
"rogados",
"armarem",
"chagada",
"clubber",
"chupões",
"rastreá",
"acordai",
"eugenol",
"acidade",
"tomismo",
"atreves",
"arrogam",
"cabanos",
"elimino",
"ostente",
"litigar",
"toríbio",
"engulam",
"gerimos",
"revogam",
"maestra",
"aninhou",
"digitem",
"esticão",
"octanas",
"sopesar",
"roturas",
"roladas",
"agouros",
"pândega",
"saltada",
"capotei",
"acreção",
"adoçada",
"bocejou",
"copioso",
"fartava",
"gastura",
"tacuara",
"teníase",
"poemeto",
"afeitas",
"choupos",
"encapei",
"carrear",
"páscoas",
"aférese",
"debruço",
"sultana",
"entalar",
"genioso",
"bisonte",
"caniche",
"fundura",
"prófase",
"gaiatos",
"expurga",
"reciclo",
"trepado",
"dedurar",
"gonadal",
"fiqueis",
"ostomia",
"afrouxe",
"detenta",
"laborou",
"biênios",
"borderô",
"pupilar",
"coagula",
"coçares",
"pagares",
"boeings",
"vazador",
"pedação",
"mexeria",
"nabiças",
"pegados",
"ficados",
"devotam",
"ranhoso",
"untuoso",
"colendo",
"alcovas",
"armamos",
"matinha",
"quedara",
"vitimar",
"alçaram",
"tétrica",
"rodaria",
"remedia",
"alisada",
"podador",
"rebaixe",
"gatonas",
"mixados",
"senoide",
"botasse",
"colapsa",
"cueiros",
"emersas",
"grunhir",
"obstado",
"driblam",
"estremo",
"fiadora",
"penteio",
"enteiro",
"coretos",
"aerador",
"afeiçoa",
"armaria",
"moveria",
"entrevê",
"mássica",
"albinas",
"honrava",
"bolinar",
"cambará",
"eivadas",
"coligir",
"perdoas",
"vivazes",
"encobri",
"borrifa",
"fímbria",
"onerado",
"alodial",
"espalma",
"menires",
"calunga",
"troveja",
"apólogo",
"lograra",
"propomo",
"zipados",
"suicido",
"aluguem",
"gememos",
"arderem",
"camisão",
"estalei",
"eufemia",
"exaltem",
"secavam",
"enfeito",
"mutilam",
"mangote",
"subsume",
"caxingó",
"estivem",
"esvoaça",
"bordava",
"apartam",
"delegue",
"pararei",
"sapucaí",
"lucrado",
"socadas",
"difamam",
"cesário",
"nefitas",
"saguões",
"litania",
"esfumei",
"reparta",
"limalha",
"rasgões",
"caniços",
"coroide",
"címbalo",
"potassa",
"cananeu",
"majores",
"anotara",
"oxaguiã",
"boiolas",
"plantem",
"sanhaço",
"decepou",
"debatam",
"ardidos",
"astério",
"semeava",
"soluçou",
"anelado",
"aspiral",
"aviltar",
"bitoque",
"sulanca",
"dealbar",
"garrida",
"malhava",
"roçagem",
"aliamos",
"excetua",
"retires",
"vetaram",
"ecólogo",
"cadenas",
"perlita",
"transpô",
"grilado",
"caçulas",
"britada",
"grudava",
"penduro",
"doidice",
"licínia",
"custeou",
"queixei",
"enchida",
"rasteje",
"papeira",
"pautava",
"pavores",
"fartote",
"trinado",
"fogosos",
"calhava",
"congado",
"trilhei",
"cartaxo",
"grumete",
"inábeis",
"grisões",
"invejei",
"feérica",
"forçosa",
"recheei",
"traçara",
"zíngaro",
"adágios",
"cáscara",
"corista",
"esbalda",
"aquiete",
"folheou",
"agendam",
"doidões",
"deslizo",
"esvazio",
"patusco",
"balinês",
"polpuda",
"maradas",
"apertão",
"fibular",
"amazias",
"repenso",
"altivas",
"maçador",
"sofreis",
"fuxicar",
"lindões",
"truísmo",
"abjecto",
"coletei",
"gamelas",
"aqueçam",
"sínfise",
"baronia",
"cambota",
"sentias",
"lockout",
"penteou",
"ornadas",
"penguim",
"bufante",
"amoreco",
"cunhais",
"guaibim",
"inverti",
"infirma",
"loteada",
"citosol",
"puxação",
"faxinar",
"avelina",
"sabujos",
"calavam",
"beijara",
"crespas",
"bodoque",
"excreta",
"pesamos",
"votinho",
"adocica",
"discais",
"incauta",
"czarina",
"cordata",
"credite",
"traímos",
"coloide",
"trombou",
"óctuplo",
"afronte",
"xerifes",
"prostro",
"fermata",
"mirrada",
"inovada",
"alertem",
"fumadas",
"panacas",
"roadies",
"dançará",
"girarem",
"escorri",
"checkup",
"enguiço",
"timburi",
"poveiro",
"salpica",
"desossa",
"estirar",
"kingpin",
"faltosa",
"talante",
"eubiose",
"aldrava",
"bivalve",
"esculto",
"caleche",
"azinhal",
"tugúrio",
"galhada",
"harpias",
"pisasse",
"caducos",
"mutilou",
"parruda",
"apodere",
"maculam",
"afrente",
"pronada",
"suprima",
"cajados",
"oblonga",
"irritem",
"salubre",
"combati",
"gripais",
"trepava",
"encarne",
"graduei",
"avulsão",
"trôpega",
"feriras",
"papeles",
"cariado",
"gozemos",
"descole",
"auxilar",
"poentes",
"eflúvio",
"itararé",
"rondara",
"anáfase",
"obstrua",
"baseiem",
"morrida",
"ignoras",
"pilotam",
"agrafos",
"miradas",
"burlões",
"barrote",
"gozaria",
"ossário",
"cegueta",
"mingaus",
"iguales",
"feriria",
"caipora",
"calzone",
"mesuras",
"sarandi",
"aclamou",
"prelada",
"morosos",
"creador",
"campais",
"enforma",
"bacante",
"dracena",
"reergue",
"abdique",
"locadas",
"indócil",
"taparam",
"guaiaca",
"focavam",
"impinge",
"disúria",
"brochar",
"cístico",
"auxinas",
"cachopa",
"guajeru",
"guajiru",
"bolicho",
"novelão",
"imputam",
"arranhe",
"acrômio",
"golpada",
"abjeção",
"armavam",
"chorada",
"redobra",
"cagaram",
"oblatas",
"caricas",
"floreio",
"esturro",
"hígidos",
"loteado",
"pateira",
"pecarem",
"meladas",
"negarão",
"lobinha",
"pútrida",
"marreca",
"moabita",
"viçosos",
"aldraba",
"arcaria",
"descama",
"fodedor",
"redomas",
"renasço",
"embalei",
"obtusas",
"borrifo",
"germine",
"dedicas",
"fatinho",
"aderiam",
"aprumar",
"abarque",
"horista",
"piazada",
"temerão",
"seletas",
"parição",
"efusões",
"destoou",
"curamos",
"ferrete",
"dentado",
"garimpa",
"segreda",
"chiança",
"congada",
"cognato",
"obstada",
"rompiam",
"macaíba",
"engando",
"paresia",
"boreste",
"carraça",
"enlaçou",
"adeuses",
"gateira",
"grilada",
"afagava",
"chamamé",
"maquiou",
"corisco",
"ensejos",
"insulte",
"helenos",
"descair",
"estévia",
"sedosas",
"explodo",
"sujaram",
"jogaste",
"juqueri",
"botaria",
"cabanha",
"leiloou",
"opalino",
"pagaste",
"antepor",
"proveja",
"recaído",
"ateroma",
"dizimas",
"acirram",
"gambito",
"regedor",
"choldra",
"depenar",
"hissopo",
"canitar",
"pintara",
"realces",
"magicar",
"moendas",
"mirador",
"grabado",
"forreta",
"gandola",
"luzeiro",
"pelinha",
"senário",
"cebolão",
"exultam",
"esfumou",
"louvara",
"aninham",
"advirta",
"amorfas",
"graúdas",
"calidez",
"beiçola",
"espicha",
"findava",
"tabocal",
"burilar",
"refreia",
"aliarem",
"argente",
"belinho",
"alberca",
"coutada",
"arconte",
"espetam",
"minadas",
"tachões",
"usinada",
"domando",
"cremada",
"velcros",
"bodinho",
"mitoses",
"corcova",
"saneado",
"enésimo",
"vegetam",
"conceba",
"censure",
"lamelar",
"odiarão",
"execrar",
"ensacar",
"porneia",
"vidrios",
"carijos",
"veicule",
"engatei",
"logista",
"acômodo",
"daninho",
"dipolos",
"amarais",
"cordura",
"vitinha",
"espeque",
"lázaros",
"nubente",
"perônio",
"sudação",
"assovia",
"dosadas",
"velador",
"prestas",
"semiose",
"gomagem",
"cábulas",
"equidna",
"espetei",
"libânia",
"cíngulo",
"oprimiu",
"citasse",
"freiria",
"pimpões",
"rotaria",
"afilada",
"bicudas",
"atentai",
"bananão",
"defecam",
"galopou",
"zincada",
"tensora",
"agravem",
"ablução",
"rezadas",
"vagueza",
"viermos",
"néscias",
"concebi",
"leprosa",
"simonia",
"decibel",
"catraia",
"ikebana",
"cornudo",
"tunadas",
"górgona",
"redobre",
"insufla",
"ficadas",
"reguila",
"bloquei",
"guindar",
"laertes",
"cetáceo",
"birutas",
"canídeo",
"alpacas",
"editore",
"prestai",
"caraguá",
"pústula",
"súbdito",
"judiada",
"engulir",
"giramos",
"endívia",
"embraço",
"sumiria",
"flambar",
"assomou",
"bobines",
"porongo",
"guarino",
"chispas",
"espanar",
"mentiam",
"frustre",
"gozosos",
"toxemia",
"guiaria",
"batalho",
"dinares",
"secadas",
"alentar",
"engajem",
"inúmero",
"exporia",
"fitaram",
"hemácia",
"revelas",
"rançosa",
"afunila",
"diáfise",
"hiberna",
"pesarem",
"choquem",
"sainhas",
"emendam",
"esbanje",
"fundiam",
"haletos",
"sacoles",
"bastado",
"presida",
"sustado",
"arqueia",
"cegaram",
"pendões",
"indicas",
"mariola",
"agarrão",
"ferinas",
"baleira",
"reputam",
"sibilar",
"croquet",
"abraxas",
"bitaite",
"receito",
"topasse",
"restelo",
"perobal",
"engaste",
"repuxar",
"sinteco",
"endeusa",
"estrala",
"abortei",
"aterrei",
"uretano",
"alhadas",
"brenhas",
"doridas",
"fumasse",
"roliços",
"sumirão",
"cubagem",
"usurpam",
"bobsled",
"gorjeio",
"reluzem",
"aplasia",
"crestes",
"esbarre",
"remenda",
"sargaço",
"derivem",
"enxáguo",
"cismada",
"sanável",
"sônicas",
"aprumos",
"calhado",
"falseia",
"yeshiva",
"clinton",
"delirei",
"tiazona",
"apeguem",
"carnosa",
"auferiu",
"estuche",
"biochip",
"sadinos",
"espargo",
"abrande",
"euzinho",
"nenúfar",
"dirimiu",
"patense",
"sebenta",
"sovacos",
"torreta",
"aparcar",
"exaltai",
"fálicos",
"arrasei",
"esmolar",
"selecto",
"salivas",
"brissos",
"pisante",
"boêmias",
"enumero",
"trombei",
"acercam",
"reunimo",
"biótopo",
"caturra",
"safando",
"peônias",
"tropica",
"galopes",
"pinotes",
"indiada",
"raiadas",
"ligadão",
"cacatua",
"laçando",
"almejei",
"secasse",
"cártula",
"redigia",
"mostres",
"pinhata",
"calarei",
"púnicas",
"referee",
"goulash",
"nadarem",
"previno",
"listava",
"fugiste",
"leucipo",
"bateias",
"golpeie",
"bivaque",
"arrifes",
"marconi",
"somador",
"belisco",
"casinho",
"piaçaba",
"rubicão",
"anuênio",
"batatal",
"estreio",
"ilíacas",
"filipes",
"clorose",
"ascendi",
"lipases",
"patilha",
"suavize",
"volvido",
"espirre",
"áulicos",
"doirado",
"biritas",
"mudéjar",
"pelejou",
"afundem",
"juntamo",
"vicejam",
"agachei",
"zimbres",
"oxalufã",
"atiçado",
"descura",
"acanhem",
"clipper",
"camburi",
"hidreto",
"buzinam",
"inúmera",
"proveem",
"ímprobo",
"essênio",
"bravias",
"empacar",
"bancava",
"quinina",
"bororos",
"polpudo",
"altinha",
"colunar",
"deporta",
"embutem",
"refinou",
"nêspera",
"medeiam",
"lemense",
"comeces",
"saqueia",
"cutucão",
"dedilha",
"erraria",
"vergada",
"jocosas",
"radônio",
"eriçado",
"guidões",
"oersted",
"pacatez",
"posarem",
"gnaisse",
"recebas",
"agudiza",
"trancão",
"parécio",
"coloria",
"lanceta",
"sarando",
"epônimo",
"espadim",
"lidaria",
"tolteca",
"perches",
"reprovo",
"apitara",
"elogiem",
"luzente",
"líticas",
"sucumba",
"sugeres",
"tortosa",
"debitam",
"gessada",
"arcados",
"arrolou",
"estetas",
"arrotam",
"desamar",
"enjoava",
"cabeços",
"sábanas",
"puseres",
"muradas",
"alvejou",
"entrais",
"fóbicos",
"geninha",
"minibus",
"ranhosa",
"sonegou",
"ponente",
"varzedo",
"fendida",
"privava",
"abduzir",
"afastas",
"enóloga",
"lidiana",
"presido",
"encorpa",
"rosnava",
"geóloga",
"fontela",
"enredam",
"sapecou",
"apraxia",
"azevias",
"bimodal",
"elegiam",
"saldado",
"alumino",
"caladão",
"dominas",
"batelão",
"riscava",
"sorrias",
"alijada",
"lacunar",
"padreco",
"butanol",
"baganha",
"rateada",
"rapagão",
"felizão",
"mediais",
"mísulas",
"vítreos",
"mazinha",
"ofusque",
"tarouco",
"footing",
"adviria",
"removia",
"uniriam",
"abjurar",
"ovações",
"reprove",
"temeras",
"obrador",
"ragtime",
"reputou",
"supereu",
"columna",
"feiosos",
"abafava",
"secados",
"juraria",
"untados",
"bolaram",
"rasguem",
"roscada",
"batoque",
"esvaído",
"vãmente",
"rapadas",
"carbeto",
"aliance",
"arminha",
"pariram",
"relidos",
"inaptas",
"obterei",
"ronrons",
"timolol",
"depilam",
"xavecar",
"afoitas",
"intente",
"pacoval",
"árcades",
"efígies",
"assomar",
"pançudo",
"complôs",
"paridos",
"aleguem",
"inculca",
"guaxuma",
"aporias",
"amastes",
"empenar",
"sônicos",
"bólides",
"apossam",
"líticos",
"afídeos",
"jejuava",
"campões",
"maltina",
"atrator",
"futrica",
"ilharga",
"visagem",
"esmurra",
"janeira",
"arameus",
"paneira",
"careçam",
"metedor",
"dimetil",
"detetou",
"minueto",
"ribamar",
"lousado",
"esprito",
"posaria",
"buzinou",
"cafundó",
"citrina",
"marzipã",
"capinas",
"guaches",
"apontas",
"estopas",
"téssera",
"envasar",
"faseado",
"friável",
"mavioso",
"convirá",
"omíadas",
"magista",
"ferirei",
"subiste",
"aturada",
"júbilos",
"bugrino",
"trepida",
"coibido",
"safaram",
"clareie",
"discman",
"paulito",
"corcéis",
"mascado",
"meneses",
"galetos",
"retemos",
"tamariz",
"adorais",
"olivina",
"desunir",
"malgaxe",
"pétreos",
"batávia",
"cálcica",
"traísse",
"polução",
"quengas",
"goitacá",
"recuara",
"melenas",
"bétulas",
"trufada",
"anódino",
"buzanfã",
"fulgura",
"merdoso",
"operosa",
"viremia",
"ocultei",
"profira",
"apartem",
"goradas",
"guiense",
"marchem",
"tocares",
"demoveu",
"sulcado",
"agendem",
"muquifo",
"regerão",
"fundeio",
"aguados",
"raspava",
"espremi",
"mordiam",
"objetou",
"testigo",
"saloios",
"acertas",
"capista",
"experta",
"nortada",
"arcanas",
"laquear",
"fartara",
"sentamo",
"cindido",
"finório",
"amentos",
"compita",
"garance",
"fodemos",
"aréolas",
"detecto",
"fixasse",
"encanar",
"pereçam",
"calombo",
"cordada",
"esquizo",
"degolou",
"lulinha",
"manjuba",
"valhala",
"festões",
"betaína",
"chagado",
"frugais",
"sacarem",
"sufixal",
"digeriu",
"batinga",
"desbote",
"tourear",
"agrande",
"burlada",
"focasse",
"escovam",
"coações",
"boninal",
"doarmos",
"susterá",
"caçarem",
"esfriem",
"farolim",
"românia",
"busques",
"exórdio",
"surfava",
"objetal",
"dizimam",
"micelas",
"arriado",
"atópico",
"comprai",
"elegida",
"paçocas",
"temamos",
"batinas",
"lufadas",
"zarolha",
"ensopar",
"onívora",
"atermos",
"itapeva",
"cegados",
"clamara",
"bateção",
"cuspiam",
"tácitas",
"farejam",
"indague",
"rimadas",
"aquenta",
"besunte",
"quixaba",
"dérmico",
"reclina",
"vôngole",
"cidinha",
"mentres",
"apavoro",
"rebolos",
"empíreo",
"narigão",
"tonante",
"sommier",
"zelosas",
"árticas",
"encruza",
"propeno",
"operoso",
"secarão",
"alaúdes",
"chícara",
"frondes",
"pinçado",
"puladas",
"santoro",
"amariam",
"chovera",
"uigures",
"blendas",
"reatado",
"avivado",
"rendoso",
"padinha",
"vozerio",
"carmelo",
"chemise",
"tubulão",
"fúngico",
"alugara",
"estampo",
"arrufos",
"vingava",
"pândego",
"ramboia",
"cafunés",
"alinhei",
"cavamos",
"abjetas",
"alumbra",
"carpado",
"bimetal",
"lactuca",
"estampe",
"humilho",
"pacóvio",
"batarda",
"inflame",
"patinam",
"vezeiro",
"cadivéu",
"expires",
"caruana",
"réstias",
"texanas",
"recobro",
"sujarem",
"munheca",
"suporia",
"apuriná",
"confrei",
"retorce",
"selecta",
"decatlo",
"foleira",
"ômicron",
"artêmia",
"coibida",
"assomam",
"capetas",
"jeremie",
"charros",
"propala",
"trazias",
"agitara",
"telepar",
"estrelo",
"castrou",
"torrone",
"açodada",
"estimas",
"fluísse",
"remarca",
"toilete",
"macacas",
"benzido",
"faculte",
"releiam",
"visiona",
"chateei",
"brancão",
"cimenta",
"decifro",
"golfada",
"coelhas",
"garbosa",
"majorou",
"povoava",
"visasse",
"comprir",
"dadinho",
"urtigão",
"manejou",
"tabuado",
"moderou",
"saltita",
"fugidas",
"acampei",
"plotado",
"lavaria",
"zebrada",
"saturou",
"enturma",
"acoplam",
"guajira",
"maisque",
"brandia",
"cabinda",
"refulge",
"inferem",
"escoava",
"arrojar",
"decesso",
"abdutor",
"rodarão",
"corroía",
"escovão",
"colavam",
"córdula",
"murinho",
"valados",
"elencam",
"jesuína",
"mulungu",
"socorri",
"aduzido",
"implico",
"chorudo",
"pelagra",
"púbicas",
"solimão",
"decline",
"empiema",
"acasala",
"negrura",
"murados",
"desovas",
"aleijão",
"distava",
"gigolôs",
"guiarei",
"leonesa",
"pilotei",
"fonadas",
"guiamos",
"zoófila",
"copiara",
"florões",
"deteria",
"vazarem",
"gadanha",
"haverei",
"trintas",
"abandou",
"bijagós",
"paraste",
"visaria",
"noética",
"findada",
"zelamos",
"trucida",
"caloira",
"priscos",
"exíguas",
"vacinem",
"índoles",
"báltica",
"deforme",
"escorço",
"haltere",
"penicos",
"desfará",
"patacão",
"patusca",
"pórfiro",
"potiche",
"tutoras",
"capitis",
"pleroma",
"incutem",
"nivelam",
"subzero",
"tramela",
"tômbola",
"voassem",
"achoque",
"albores",
"decifre",
"dominei",
"medisse",
"sedados",
"cruento",
"capsule",
"moderne",
"palombo",
"capotes",
"ariscar",
"puxaria",
"ornando",
"adornou",
"aeróbus",
"pinçada",
"réprobo",
"archaea",
"chequem",
"meeiros",
"babésia",
"baitaca",
"tenções",
"amanhar",
"saneada",
"argutos",
"laborem",
"orantes",
"estreme",
"nefanda",
"sucuris",
"bairrão",
"cerúleo",
"urinóis",
"maltada",
"maltesa",
"vezinha",
"peinado",
"enxugam",
"elidida",
"famelga",
"jequeri",
"resfrie",
"varadas",
"tasquei",
"fedidas",
"sufocos",
"ajuntem",
"logares",
"algares",
"guabiju",
"cingida",
"revisem",
"solidéu",
"avatara",
"guipure",
"volvera",
"brócoli",
"marotas",
"pachola",
"apegava",
"pudicas",
"estulto",
"ronrona",
"sobrasa",
"bureaus",
"eduquei",
"cascuda",
"vinguei",
"frígido",
"opinava",
"florear",
"bombeio",
"colisor",
"espádua",
"furreca",
"amputou",
"cloacal",
"castiça",
"rezarei",
"alistei",
"monjolo",
"paellas",
"alteres",
"gageiro",
"cocotas",
"apensas",
"azarões",
"galgado",
"coxilha",
"plasmou",
"esclusa",
"empiria",
"traírem",
"alheada",
"empalar",
"xantina",
"sineiro",
"rebatia",
"baciada",
"fixemos",
"aurores",
"cozendo",
"massapé",
"redores",
"arrudão",
"penteei",
"mistela",
"hípicas",
"carnoso",
"partita",
"sexuado",
"sorvido",
"chuchar",
"velhaca",
"carpina",
"globina",
"patarra",
"escólio",
"marulho",
"aparace",
"defines",
"jarrete",
"sansões",
"secunda",
"captara",
"rebente",
"tunante",
"cuckold",
"roscado",
"acenado",
"rolamos",
"admiras",
"avanças",
"ferrosa",
"insinue",
"pudicos",
"bicamas",
"trazeis",
"terenas",
"vitimam",
"adensam",
"alcmena",
"untando",
"arruíne",
"pentose",
"eluição",
"fatorar",
"aduzida",
"dedeira",
"afasias",
"profane",
"morenão",
"povaréu",
"aspirei",
"ritinha",
"bombaça",
"teurgia",
"abstive",
"brilhas",
"matemos",
"panteon",
"devíeis",
"erodido",
"inciais",
"mamatas",
"ressona",
"aliviem",
"folatos",
"ganeses",
"lustros",
"piapara",
"maxixes",
"estreie",
"atilhos",
"coquete",
"tetudas",
"seleiro",
"serenou",
"matares",
"bailava",
"mímicos",
"maquiam",
"assucar",
"chalaça",
"náuatle",
"exaltei",
"tonsura",
"miravam",
"poeirão",
"leucena",
"aoristo",
"laurino",
"farejou",
"maquiei",
"omeleta",
"vitelos",
"cevados",
"receite",
"aguapés",
"simulei",
"giraria",
"luzindo",
"acoplou",
"bacanos",
"fundear",
"aferrar",
"rapinas",
"clonais",
"caçaram",
"catodos",
"basenji",
"bolamos",
"cegante",
"olhavas",
"evolver",
"racemos",
"glabras",
"locaute",
"refluir",
"escorro",
"irmanam",
"lobular",
"alegara",
"ganhada",
"jaraqui",
"telúrio",
"balzaca",
"benissa",
"demitam",
"agônico",
"cuideis",
"demitia",
"rumavam",
"acriana",
"gênicos",
"sugaram",
"apalpei",
"aviadas",
"guantes",
"amainou",
"gliomas",
"mancava",
"cabemos",
"conflui",
"fumaria",
"truncar",
"vulnera",
"alentos",
"altezas",
"impetra",
"pactuam",
"sucedam",
"broquel",
"pelugem",
"citaras",
"regulei",
"opiáceo",
"patinou",
"mandões",
"gaudino",
"rapados",
"recrute",
"marosca",
"prendes",
"purinha",
"moverão",
"recendo",
"chalana",
"algemou",
"relegam",
"serifas",
"orardes",
"estevas",
"desfiou",
"gongora",
"saciava",
"desvies",
"escarra",
"debruce",
"pequito",
"conúbio",
"medicou",
"conotar",
"maciota",
"vênulas",
"esfolie",
"lúbrico",
"védicas",
"maculou",
"hominal",
"procela",
"apurara",
"azurita",
"norinha",
"doirada",
"rábulas",
"terçado",
"gabiões",
"escaler",
"achegou",
"proêmio",
"renegue",
"élitros",
"tiraras",
"torcera",
"aguando",
"elágico",
"meneios",
"pulador",
"acatara",
"aspiras",
"cutuque",
"lambril",
"piradas",
"averbou",
"bonecão",
"começão",
"meleças",
"redunde",
"absolvo",
"apresar",
"balanta",
"soterra",
"freixos",
"negrada",
"brincão",
"gaiense",
"dínamos",
"roçadas",
"estoiro",
"exações",
"modelei",
"munindo",
"celepar",
"itupeva",
"piorara",
"tabeliã",
"protela",
"crimpar",
"urinado",
"coativa",
"educava",
"pactuou",
"vergões",
"decanos",
"caixola",
"violara",
"roubais",
"encampa",
"edênico",
"eversão",
"maralha",
"urinava",
"cosidos",
"carlina",
"foreiro",
"mamaram",
"mortiça",
"denotou",
"estélio",
"piraram",
"barbate",
"monacal",
"calarão",
"anódica",
"ostende",
"cacimbo",
"suspire",
"fatigar",
"repuxos",
"solerte",
"arribar",
"acendei",
"corolas",
"bichada",
"pisaria",
"amigões",
"diegese",
"datavam",
"futucar",
"volemia",
"agulhão",
"polióis",
"graçola",
"petrina",
"aguante",
"eximias",
"esculpi",
"langues",
"arachis",
"tuitada",
"chuchas",
"adoecia",
"suturar",
"alhinho",
"fêmures",
"retende",
"anelada",
"namorem",
"párrafo",
"peritas",
"quirais",
"retarde",
"simulem",
"mirones",
"coalhar",
"depreda",
"bebeste",
"giganta",
"luzidio",
"verruma",
"artêmio",
"caiapós",
"mudinho",
"chinoca",
"mironga",
"colidam",
"fascino",
"bonsuça",
"maléolo",
"renomeá",
"aprecem",
"fruidor",
"megabit",
"murmuré",
"nitreto",
"repelia",
"aradora",
"ervedal",
"exclamo",
"puniram",
"massada",
"achavas",
"triados",
"cônscia",
"flameja",
"escudar",
"lajedos",
"recheou",
"aclamam",
"jacaúna",
"ripados",
"alfalfa",
"guiagem",
"paraver",
"tisanas",
"tirolês",
"aguilas",
"luminal",
"semelha",
"zenóbia",
"desfoca",
"anódina",
"silepse",
"acelgas",
"aditada",
"sulfate",
"acional",
"broinha",
"dominai",
"citemos",
"encurte",
"latisse",
"tubeira",
"fritava",
"ofegava",
"ripária",
"sudetos",
"odiável",
"roçavam",
"frisava",
"aceiros",
"anseias",
"cismava",
"estriol",
"irrisão",
"polegas",
"adiarem",
"citaste",
"aplacou",
"decorem",
"divirjo",
"hispana",
"sétimos",
"pracaxi",
"susteve",
"renovos",
"afluíam",
"clister",
"retalha",
"zingara",
"odorata",
"rastejo",
"alastor",
"barbacã",
"sorbato",
"globosa",
"permeie",
"ditarem",
"fáscias",
"mordera",
"poremos",
"vagaram",
"caçamos",
"hachura",
"estomas",
"penasco",
"eclipsa",
"hípicos",
"jatobás",
"gongada",
"arcarem",
"cosidas",
"curarão",
"lucrava",
"miramos",
"estocam",
"inquiri",
"tijucal",
"miudeza",
"varriam",
"zeraram",
"devorem",
"inermes",
"tramava",
"trítono",
"cópulas",
"catimbó",
"ligador",
"subidos",
"fiorina",
"grasnar",
"laicado",
"acuadas",
"auferia",
"queixes",
"desmaie",
"tirinho",
"defecou",
"nivelou",
"tertius",
"assitia",
"fabular",
"fanzoca",
"infenso",
"locando",
"profuso",
"alinhas",
"jejuado",
"girados",
"juntura",
"baalins",
"puniria",
"radicam",
"lobeira",
"ludismo",
"vendeta",
"amoldam",
"coesivo",
"luzidia",
"ousarei",
"sucuriú",
"expecto",
"lograda",
"reenvie",
"siríaca",
"empinei",
"mobilar",
"pecasse",
"resigno",
"tetravó",
"moçoilo",
"rondado",
"disquei",
"amigues",
"virgina",
"obtende",
"momesco",
"retiras",
"assumes",
"derroga",
"bundudo",
"pretano",
"valinho",
"achegam",
"chameis",
"acendes",
"adiasse",
"embalse",
"acresça",
"afigure",
"aquento",
"cravava",
"telados",
"cofator",
"exaurem",
"liceais",
"cavacas",
"emulada",
"lixador",
"quintão",
"bataclã",
"colomba",
"atiçada",
"apertas",
"catanas",
"centres",
"urdidas",
"tracajá",
"alistam",
"bloques",
"confies",
"consego",
"remisso",
"cadista",
"fixarão",
"lichias",
"mijadas",
"cumeada",
"gaditas",
"manténs",
"cobogós",
"evacuou",
"privara",
"aflijas",
"cheiras",
"incensa",
"lucrara",
"dedados",
"minguou",
"vaivéns",
"bajulam",
"furtara",
"votemos",
"autovia",
"decanta",
"tétrade",
"toparem",
"padiola",
"chocara",
"gorilão",
"bendegó",
"cóleras",
"ralenti",
"arreglo",
"baptiza",
"sibilou",
"cederão",
"choviam",
"ilustro",
"sumimos",
"retraia",
"insídia",
"virarei",
"capitar",
"escadão",
"micções",
"titulou",
"dalguns",
"húmicas",
"mortiço",
"terreal",
"pandeló",
"tampava",
"afônico",
"briosos",
"forjava",
"rasgara",
"refazia",
"morosas",
"paxiúba",
"xavecos",
"caiação",
"miosite",
"acabais",
"bichana",
"rizadas",
"tafarel",
"capucho",
"devenir",
"dizível",
"prumada",
"somasse",
"uivante",
"liderei",
"trevoso",
"palanga",
"solaria",
"amilose",
"ledores",
"biocida",
"goleiam",
"livrada",
"piscara",
"rufiões",
"rasurar",
"ousemos",
"tocável",
"trousse",
"sacamos",
"pragana",
"sátrapa",
"aleguei",
"rogéria",
"zanguei",
"alquilo",
"mezinha",
"moveres",
"aliciam",
"javista",
"oviduto",
"algéria",
"hominho",
"olharas",
"migrara",
"giradas",
"saldada",
"ativava",
"fixaria",
"migrada",
"tépidas",
"caducam",
"espremo",
"jorrado",
"alojava",
"jazendo",
"abcissa",
"dedurou",
"xifoide",
"fanhoso",
"relojes",
"delusão",
"ilumino",
"xerocar",
"labutas",
"cloasma",
"fascine",
"requeri",
"erínias",
"reitere",
"murchem",
"remidor",
"achegue",
"embaçam",
"incomun",
"pautara",
"perorar",
"warrant",
"desfiam",
"lanchei",
"pageant",
"terrear",
"tocatas",
"policio",
"almóada",
"posamos",
"resulto",
"zinabre",
"artério",
"tebanos",
"maximal",
"piastra",
"serôdio",
"vieiros",
"arrozes",
"voarmos",
"culmine",
"cavador",
"expuser",
"limiana",
"muqueca",
"paginar",
"arcarão",
"atrelam",
"coabita",
"curarei",
"escurão",
"alcinos",
"atriais",
"embotar",
"escalpe",
"recorto",
"vistano",
"cossaco",
"rendosa",
"apeados",
"prexeca",
"crivada",
"decapar",
"rococós",
"maviosa",
"tacaram",
"desfilo",
"furoato",
"meandro",
"preceda",
"crereis",
"cabalas",
"lajeosa",
"planeei",
"cobiçou",
"repudie",
"rangiam",
"estralo",
"palomba",
"reergui",
"taxinha",
"zeólita",
"bruxedo",
"ejecção",
"mocambo",
"ausento",
"fitatos",
"ogivais",
"brochas",
"irmanar",
"optarão",
"arrombo",
"frasais",
"picotes",
"apalpam",
"frechas",
"mamocas",
"numídia",
"parares",
"trácios",
"levavas",
"bejinho",
"oosfera",
"pastava",
"pilosas",
"atipias",
"listara",
"leitoas",
"reeduca",
"resalva",
"difiram",
"frincha",
"linques",
"arrotou",
"dragado",
"guisada",
"marados",
"ofídios",
"rastelo",
"semeias",
"sacrais",
"saturam",
"terrosa",
"aplanar",
"atavios",
"estorva",
"apelara",
"grafito",
"pegaste",
"replico",
"vicejar",
"ditarão",
"borduna",
"fenando",
"neonazi",
"patinas",
"reopção",
"trípode",
"enervam",
"panônia",
"revisse",
"áfricas",
"atrelou",
"fagotes",
"juncais",
"redigem",
"coninha",
"pinadas",
"sebáceo",
"soprara",
"alcofas",
"fatigue",
"engatam",
"gaturro",
"afinava",
"léxicas",
"gazetas",
"ejeções",
"trempes",
"expelia",
"plaines",
"acionem",
"cabular",
"furries",
"opoente",
"desovam",
"beselga",
"ejetada",
"gradeço",
"remista",
"declino",
"franzia",
"vinguem",
"blastos",
"fletido",
"israeli",
"sediava",
"descaiu",
"madurez",
"escamar",
"melecar",
"curimba",
"eufonia",
"festona",
"cerquem",
"frênico",
"tantras",
"boieiro",
"usufruo",
"destras",
"apresta",
"formões",
"fadário",
"forames",
"motocas",
"licitou",
"alburno",
"exalada",
"moverei",
"gataria",
"tishrei",
"lambiam",
"lambuze",
"palitar",
"lageana",
"sidecar",
"abadejo",
"enredou",
"mazurca",
"láureas",
"agnosia",
"choreis",
"riquixá",
"ruandês",
"ftalato",
"gaguejo",
"vinhoto",
"rabetas",
"petinha",
"acepipe",
"blindou",
"matulão",
"refrata",
"beberas",
"migrans",
"rodilha",
"zoeiras",
"jateada",
"dolmens",
"emassar",
"marejar",
"abanico",
"ondulam",
"pressor",
"erodida",
"frangão",
"baterei",
"figadal",
"refinam",
"antropo",
"bolanha",
"beberes",
"morrais",
"retinto",
"ligaste",
"tamales",
"manchei",
"rebolei",
"régulos",
"barrava",
"elvense",
"féculas",
"pitando",
"balaços",
"cozerem",
"amonita",
"denegri",
"volteio",
"pêndula",
"bocejam",
"dóricas",
"priores",
"inferiu",
"reinais",
"tapavam",
"admires",
"sulcada",
"nababos",
"calaria",
"entalou",
"desanca",
"pudendo",
"dativos",
"fadinho",
"caixeta",
"azurras",
"estivas",
"subteto",
"tremura",
"erectos",
"expiado",
"flagela",
"gitanos",
"tabique",
"tombava",
"acádios",
"apender",
"fiteiro",
"depilou",
"estimem",
"lutaste",
"minguem",
"corrico",
"apófise",
"exultem",
"ariscas",
"laurear",
"maliano",
"offside",
"tecerem",
"enganes",
"selamos",
"decairá",
"escapas",
"pulposo",
"angolas",
"azeitar",
"muambas",
"reterem",
"cutista",
"promana",
"tornais",
"cremers",
"opioide",
"aniagem",
"baixote",
"fibroma",
"orfismo",
"chapeis",
"omitira",
"plastia",
"deparem",
"copulam",
"recriei",
"aguçava",
"cimério",
"lacônia",
"avoante",
"néfrons",
"safenas",
"doassem",
"imiscui",
"entubar",
"ralhava",
"sujamos",
"amparem",
"pinchas",
"aliviei",
"assalte",
"alambre",
"amaciam",
"capicua",
"abaxial",
"checava",
"crepita",
"estanco",
"zebrado",
"jurasse",
"aderira",
"teatrão",
"tetania",
"almeira",
"atenham",
"atenuem",
"galeira",
"renques",
"aldeãos",
"optares",
"orareis",
"vagante",
"ametade",
"açoitam",
"lotamos",
"lipídeo",
"pontuei",
"procera",
"crerdes",
"pautais",
"refaçam",
"léptons",
"teladas",
"cominar",
"disputo",
"leônico",
"mexedor",
"beirões",
"frescão",
"papangu",
"aldeola",
"lacrima",
"zigotos",
"baguaçu",
"pluguei",
"portara",
"diriges",
"aerogel",
"afagado",
"religue",
"assinto",
"eriçada",
"furanos",
"enxadão",
"aplicas",
"encurva",
"riponga",
"mediria",
"planete",
"embalde",
"ferviam",
"húmicos",
"imporão",
"negaras",
"saciara",
"correte",
"filmete",
"aportei",
"molesto",
"canecão",
"encores",
"invejou",
"aquirir",
"caraíba",
"verrine",
"boleado",
"aloprar",
"láudano",
"viático",
"araçari",
"salpico",
"pejadas",
"sabença",
"dolosas",
"validem",
"zoneada",
"achatam",
"arrojou",
"massudo",
"mucamas",
"obterás",
"regamos",
"almocem",
"debitou",
"admites",
"estalão",
"apiedou",
"baticum",
"vazasse",
"cascada",
"estriba",
"sabidão",
"remelas",
"inchava",
"macieza",
"mamilar",
"relidas",
"brigona",
"estoica",
"revivia",
"antepõe",
"detalho",
"prensou",
"furamos",
"narcose",
"pulular",
"relatas",
"trampas",
"letargo",
"perfumo",
"vivante",
"standes",
"clorato",
"entupia",
"safarem",
"achacar",
"pinares",
"troncal",
"denotem",
"agônica",
"pareada",
"pinchos",
"enamoro",
"fiorita",
"roquete",
"tontice",
"cutucam",
"moldara",
"relevei",
"uveítes",
"aflorem",
"fraudam",
"tatuzão",
"esteros",
"feijoca",
"feminil",
"obstina",
"sabinos",
"urêmica",
"vagueou",
"setando",
"apinajé",
"comível",
"estrona",
"puchero",
"sanzala",
"esfolam",
"esparza",
"federar",
"bolhoso",
"carélia",
"tarecos",
"lupanar",
"anginas",
"atuados",
"puntual",
"aclamai",
"alpínia",
"módicas",
"louraça",
"varrera",
"juraste",
"rachava",
"aleijou",
"dormias",
"ervinha",
"retraço",
"corneto",
"giletes",
"levezas",
"vagamos",
"arrepie",
"fálicas",
"espocar",
"afoguem",
"orquite",
"motinho",
"plúmbeo",
"hasteou",
"mateira",
"velejou",
"castram",
"delfins",
"agrupem",
"anafado",
"complot",
"vínicos",
"deponha",
"proviam",
"túrbido",
"alegras",
"exemple",
"difamou",
"lanchão",
"ajeitem",
"coldres",
"destapa",
"duronas",
"remixou",
"chifrar",
"tolerem",
"marfins",
"lavrava",
"ponhais",
"garfado",
"goladas",
"colites",
"fôlegos",
"linalol",
"reineta",
"banquei",
"tinhosa",
"alarves",
"carmona",
"degusto",
"amparai",
"ensaboe",
"piraíba",
"recosta",
"rogavam",
"fatigam",
"inibira",
"petinga",
"réchaud",
"otárias",
"boutade",
"inciado",
"negador",
"arengas",
"charlas",
"chorais",
"colocai",
"galador",
"skating",
"mugindo",
"mascava",
"saireis",
"abrótea",
"deletam",
"sisudas",
"ostento",
"alevino",
"ubíquos",
"lectina",
"acerbas",
"pecavam",
"resenho",
"tricoto",
"garriga",
"ignaros",
"traques",
"pointes",
"cursivo",
"atiçava",
"abomaso",
"oboísta",
"pairado",
"inquire",
"organdi",
"gabavam",
"cheirem",
"larvais",
"orçando",
"dervixe",
"ionizar",
"subfilo",
"broxada",
"cromato",
"fanhosa",
"jurarem",
"arcabuz",
"picaram",
"sobejar",
"vacinam",
"odiaras",
"semeara",
"punisse",
"situara",
"cairdes",
"saqueio",
"paduano",
"albarda",
"alfange",
"bataria",
"inocula",
"canoras",
"cosendo",
"retraso",
"cresces",
"cumulou",
"ictiose",
"libelos",
"pilchas",
"estimei",
"dedando",
"ralaram",
"frâncio",
"portaló",
"acostar",
"beduína",
"curvara",
"depilei",
"prolema",
"enrabou",
"erraste",
"frustro",
"abbadia",
"abriras",
"cursara",
"faiscar",
"obrando",
"trevosa",
"liderem",
"pecaria",
"retorta",
"fofocam",
"tendido",
"aprumou",
"mensual",
"educara",
"galeota",
"hotline",
"verazes",
"virador",
"ataxias",
"penates",
"saiotes",
"dislate",
"fenação",
"viraste",
"arqueei",
"rolarão",
"imolada",
"recitei",
"ungiras",
"enamore",
"custura",
"kosovar",
"lixaram",
"acúleos",
"sugavam",
"esbatem",
"erviços",
"lhaneza",
"biotita",
"beirado",
"sobejos",
"envergo",
"ferreto",
"bateres",
"calçola",
"edípica",
"esfumam",
"forcing",
"narrara",
"pagaras",
"dalguma",
"faturei",
"norteie",
"gessado",
"perecia",
"bocejei",
"falirem",
"revimos",
"missais",
"arvense",
"confiai",
"guanche",
"ladrona",
"anilado",
"estirou",
"picagem",
"anuindo",
"ranheta",
"tonando",
"troncho",
"frásica",
"comisso",
"feneceu",
"rabecas",
"coulomb",
"pirilau",
"cintado",
"exporto",
"ganense",
"rapaces",
"toucado",
"vibrara",
"cavavam",
"décuplo",
"mintais",
"onerada",
"candela",
"dilecto",
"enfadar",
"jamaris",
"moreias",
"finquei",
"retruco",
"asfalta",
"grunges",
"basados",
"bugrina",
"sobejam",
"borrava",
"chuleta",
"embirro",
"fungada",
"galhano",
"latejam",
"taxaram",
"lastres",
"bancara",
"esconso",
"tateiam",
"curtira",
"sígnica",
"regeram",
"timbrar",
"almenos",
"rutênio",
"soariam",
"lapidou",
"rugosos",
"feiosas",
"fruindo",
"bibelot",
"cenotes",
"ditaria",
"saísses",
"almoedo",
"assecla",
"enxerta",
"esgarça",
"resumam",
"retocou",
"tênebra",
"botinho",
"legisle",
"assamos",
"chumbam",
"soassem",
"curtose",
"mascota",
"venhais",
"arvorou",
"bacocos",
"careceu",
"alpista",
"fraldão",
"roçaram",
"abortem",
"cerceio",
"corneal",
"fraques",
"levitam",
"piranas",
"ruminam",
"toldado",
"cagueta",
"chefiam",
"auguram",
"scripta",
"feriste",
"pendido",
"sensora",
"tonsila",
"arribou",
"cerrava",
"coxinho",
"peitudo",
"dipolar",
"fingira",
"grassou",
"puranas",
"estudai",
"assavam",
"bráulia",
"desague",
"peugada",
"anestro",
"empolar",
"bandeou",
"galizia",
"maneria",
"rebocou",
"adiaria",
"talares",
"deduzia",
"gaélica",
"globoso",
"chalota",
"garupas",
"posavam",
"saístes",
"choruda",
"abatiam",
"desonre",
"engraça",
"varapau",
"pinguei",
"alfobre",
"asinino",
"manchem",
"dopadas",
"soleado",
"vezeira",
"elucide",
"pisarão",
"ninando",
"agostos",
"estesia",
"secamos",
"tetinas",
"toletes",
"adâmico",
"aspirem",
"invejem",
"irisado",
"dentões",
"doceiro",
"exergia",
"sétimas",
"decíduo",
"tutinha",
"aliciou",
"andados",
"enfarta",
"gaúches",
"romeira",
"artelho",
"compute",
"mundiça",
"tangido",
"divagam",
"fosfora",
"orgônio",
"coirmãs",
"filmeco",
"sugarem",
"pronoto",
"rezaria",
"sondava",
"tremera",
"untuosa",
"folhosa",
"miraram",
"abeirar",
"motivei",
"rubídio",
"bambara",
"beiçudo",
"enraíze",
"baptizo",
"osteíte",
"roedora",
"batavos",
"cometar",
"intuída",
"píncaro",
"chincha",
"quintil",
"brotada",
"lobélia",
"verdete",
"fogacho",
"megeras",
"fieiras",
"vimanas",
"deceção",
"desvire",
"sobejas",
"atoarda",
"brabeza",
"báctris",
"casitas",
"colódio",
"datador",
"esposar",
"pariato",
"amparos",
"haverás",
"sentais",
"arméria",
"cobicei",
"entrevi",
"pífaros",
"enxotou",
"salivei",
"anciano",
"eximira",
"reinóis",
"khoisan",
"bêbedas",
"enrugou",
"quereno",
"enlevar",
"fartado",
"frustou",
"lúbrica",
"sorteei",
"varinas",
"limando",
"latadas",
"mugidos",
"capitãs",
"elusivo",
"lesaram",
"colasse",
"colusão",
"roçarem",
"togolês",
"dublava",
"primais",
"absolva",
"desfiei",
"morarão",
"bunraku",
"sitados",
"neuroma",
"limitas",
"cálcico",
"poraquê",
"concita",
"diabolô",
"brandiu",
"caçoada",
"gentias",
"pintosa",
"adulado",
"alidade",
"tactear",
"amarena",
"bimbada",
"carimbe",
"divisou",
"dúcteis",
"alevins",
"drogava",
"esparge",
"taparem",
"manivas",
"cordame",
"ordenem",
"umbundo",
"uracila",
"alfenim",
"apiedar",
"debicar",
"ectasia",
"vibrião",
"adverse",
"fodesse",
"lesante",
"balista",
"carriço",
"embolam",
"lacagem",
"proeiro",
"carpeta",
"deletem",
"grassar",
"extingo",
"coesiva",
"enrendo",
"excusas",
"bolitas",
"corifeu",
"oblique",
"embaçou",
"inverto",
"gostais",
"infarta",
"percais",
"aruaque",
"ramblas",
"batatão",
"carilho",
"forâmen",
"rapapés",
"dotaram",
"moderam",
"opaline",
"sagrava",
"escorva",
"gavinha",
"radiada",
"reative",
"bacilar",
"flutuei",
"grainha",
"reeleja",
"tibiais",
"catraio",
"enervou",
"bebível",
"impulse",
"panjabi",
"paulita",
"cometes",
"langres",
"lipomas",
"sustada",
"deprima",
"reponho",
"acudiam",
"filmara",
"igualem",
"atilado",
"deprimi",
"amárico",
"clorado",
"demônia",
"enricar",
"grazina",
"cacifar",
"almejas",
"boninas",
"oblívio",
"pecadão",
"fenecem",
"tagetes",
"brochei",
"lapadas",
"bateste",
"cimalha",
"porfiar",
"ramadas",
"recalca",
"sargeta",
"ultraja",
"machada",
"pianola",
"retumba",
"sirvais",
"boiavam",
"coivara",
"guimbas",
"pelejam",
"policie",
"torrico",
"mandris",
"verdejo",
"decocto",
"tiritar",
"vetaria",
"aufiram",
"espirar",
"estacar",
"furores",
"aríetes",
"ditasse",
"brusone",
"ectopia",
"furavam",
"envases",
"abstido",
"ascendo",
"aturava",
"realcem",
"antídio",
"enfeiam",
"esclavo",
"mínguam",
"corveia",
"esquila",
"picaria",
"scraper",
"esmoler",
"flutuem",
"empinam",
"herdava",
"refente",
"bofetão",
"presado",
"aceptor",
"asperge",
"assacar",
"assaram",
"enfinca",
"ganindo",
"guelfos",
"molambo",
"questor",
"teósofo",
"surfado",
"militei",
"ripagem",
"supriam",
"ametais",
"ceroula",
"enduros",
"esfolou",
"larocas",
"calázio",
"teresas",
"ganidos",
"órficos",
"cervice",
"voleios",
"dopante",
"valises",
"blindam",
"sapecar",
"caldeia",
"grunhia",
"motetos",
"raiados",
"cagança",
"fiquemo",
"pejados",
"seduzam",
"sorvida",
"bagunce",
"cooptou",
"quizila",
"corinto",
"esparto",
"galerão",
"entrenó",
"íncubos",
"aliavam",
"esfagno",
"lumbago",
"afaguei",
"enguiça",
"jônicos",
"velavam",
"crômico",
"gamanço",
"gemesse",
"silvado",
"tipuana",
"inciada",
"cinzéis",
"pirisca",
"acessoa",
"mamasse",
"vênetos",
"abordas",
"pesarão",
"capturo",
"canoros",
"matinas",
"enleado",
"gazules",
"abeirou",
"luminol",
"pimpona",
"bandear",
"excuses",
"bifocal",
"boletas",
"cachara",
"ergamos",
"fisguei",
"medique",
"acorria",
"atentes",
"sujavam",
"empunhe",
"fixismo",
"atendes",
"árctico",
"peonada",
"tabaréu",
"purgado",
"torturo",
"neutrão",
"aflijam",
"caretos",
"lucinha",
"ísquios",
"empasse",
"gotejam",
"piscoso",
"desoras",
"quedava",
"tijelas",
"voltamo",
"arquios",
"cúbitos",
"filhona",
"mateada",
"plasmam",
"sununga",
"morados",
"rezador",
"acética",
"clangor",
"dapsona",
"instava",
"tapamos",
"taranta",
"chalupa",
"pedrado",
"retinal",
"canopla",
"enrolem",
"indigne",
"tamaris",
"venerou",
"deviria",
"digneis",
"subjugo",
"baguari",
"regaram",
"roceira",
"divagou",
"gorjear",
"tragava",
"atinado",
"mustafá",
"tabacum",
"asmodeu",
"galanga",
"ingrime",
"candies",
"tiranas",
"hosanas",
"farrapa",
"paroara",
"rebates",
"adaxial",
"oxiúros",
"quiasma",
"réditos",
"aliasse",
"emigrem",
"evoquei",
"fuminho",
"biltres",
"encubra",
"jericos",
"lacerta",
"pirosas",
"tuiuiús",
"bolanos",
"punimos",
"traveis",
"pentano",
"visarem",
"adendas",
"inumado",
"berrado",
"cachupa",
"anuente",
"calcite",
"domadas",
"estupre",
"formolo",
"navajos",
"clarone",
"batotas",
"bambeia",
"cloacas",
"flandes",
"guincha",
"mauzões",
"peleias",
"relemos",
"azáleas",
"possuas",
"renovas",
"atinava",
"dependi",
"insulin",
"assomos",
"chavalo",
"babavam",
"campino",
"virolas",
"cariris",
"lívidos",
"remexem",
"cardada",
"aislado",
"traíres",
"glosado",
"ajuntei",
"apenada",
"praxado",
"solinha",
"babadas",
"badalos",
"adoeçam",
"favelão",
"imergiu",
"aragens",
"javardo",
"largues",
"serosas",
"falazes",
"manduça",
"instila",
"avieira",
"constas",
"limados",
"dunares",
"acônito",
"bulbosa",
"nevasse",
"postres",
"pratada",
"finezas",
"jantara",
"lambrim",
"ofertei",
"lídimos",
"nomeiem",
"ardesse",
"brindei",
"coadune",
"embutiu",
"incumba",
"ruturas",
"solucei",
"chicano",
"infovia",
"unhadas",
"focaria",
"madurar",
"pendida",
"pierrôs",
"redimem",
"tecerei",
"lidados",
"gandavo",
"olvidou",
"apagues",
"escoram",
"moleste",
"planado",
"capotão",
"mórfica",
"pretana",
"deterei",
"fulanas",
"sufista",
"dímeros",
"empenos",
"enjeita",
"zoófilo",
"cargado",
"cubital",
"afronto",
"amenina",
"empuxos",
"punirem",
"reformo",
"abrevie",
"durador",
"neófita",
"oiteiro",
"sofismo",
"tachira",
"acudido",
"hilotas",
"ineptas",
"tribufu",
"didatas",
"engodar",
"pizaria",
"vulcana",
"esmaece",
"galopam",
"irrites",
"palhava",
"perjuro",
"pubiano",
"recópia",
"secesso",
"limadas",
"queremo",
"agradas",
"aretino",
"entrosa",
"lasquem",
"provier",
"ternate",
"esmalta",
"leitada",
"traguei",
"festuca",
"gadanho",
"inervam",
"sabíeis",
"indexam",
"manjava",
"zangava",
"bejense",
"ciliado",
"ganhões",
"carinii",
"cerceta",
"enfeiar",
"jebuseu",
"cavadão",
"deferia",
"mamarem",
"mastins",
"previso",
"rearmar",
"malaias",
"repetes",
"rostros",
"amuados",
"casares",
"panasca",
"bergère",
"lograva",
"mangais",
"ridente",
"campear",
"chenile",
"molengo",
"notaste",
"ofertem",
"ejectar",
"revolvi",
"cadeião",
"flectir",
"triadas",
"aclarou",
"erétria",
"glories",
"pilrito",
"rumarem",
"angioma",
"atritar",
"calvino",
"sotaina",
"evoquem",
"plastos",
"babamos",
"buzinão",
"númidas",
"pegares",
"revidam",
"dobrões",
"erronia",
"lodosas",
"cercara",
"parrado",
"lorgues",
"tubista",
"optemos",
"figural",
"laicais",
"metical",
"chavear",
"novista",
"areolar",
"babeiro",
"lauréis",
"rajados",
"sanação",
"monômio",
"somação",
"atrevas",
"coletem",
"emalhar",
"fuçador",
"abolira",
"alagava",
"geriram",
"holista",
"notares",
"pernoca",
"remindo",
"desvira",
"embolso",
"engenha",
"tocaias",
"troiana",
"ataúdes",
"bagunço",
"enxovia",
"focarei",
"funérea",
"albúmen",
"famaliá",
"febeapá",
"soçobra",
"gouveio",
"mareado",
"torrava",
"tortora",
"moirões",
"voariam",
"esquipe",
"tojeira",
"animoso",
"charola",
"roteada",
"flandre",
"tidinha",
"açoitou",
"gemelos",
"intimem",
"súcubos",
"tateava",
"descolo",
"florina",
"picarem",
"pícaros",
"regiana",
"silvino",
"valeira",
"cabaços",
"chipado",
"orixalá",
"rogadas",
"estivar",
"ferinos",
"ginguba",
"infrene",
"municia",
"trabulo",
"fosseta",
"apoucar",
"badanas",
"graceja",
"inhambu",
"mondego",
"pernões",
"vigendo",
"zelarem",
"endoida",
"guardão",
"mediara",
"vassala",
"aterrem",
"papando",
"sovinas",
"carcela",
"enfeixa",
"hialino",
"lidarão",
"trépano",
"ungiste",
"retinir",
"amasias",
"troques",
"incutia",
"labruge",
"garrado",
"ensaque",
"pulação",
"enlevos",
"entonce",
"citador",
"tuatara",
"beócios",
"profiro",
"bulindo",
"uivador",
"diverta",
"fluiria",
"hebreia",
"vinhosa",
"barraza",
"aviltam",
"samovar",
"estaque",
"estocou",
"furarem",
"procrie",
"esboroa",
"gotosas",
"regaste",
"clicará",
"topspin",
"tercena",
"ejaculo",
"frênulo",
"didacta",
"radiano",
"borrega",
"calheta",
"bacalar",
"encômio",
"acartar",
"jiboias",
"surtiam",
"emético",
"histona",
"jugados",
"plagiei",
"amasses",
"assobie",
"punidor",
"cresças",
"imigram",
"poldros",
"visitai",
"hálitos",
"refugar",
"sabugos",
"sotália",
"endosse",
"jungido",
"purezas",
"renovai",
"ferrava",
"retiver",
"barbeia",
"digitas",
"gorgeta",
"pulhice",
"rapinho",
"advogue",
"azaruja",
"rareiam",
"caititu",
"diestro",
"navarra",
"arrombe",
"côngrua",
"glótica",
"jorrada",
"legende",
"magoara",
"metecos",
"picavam",
"tílburi",
"abomine",
"augurar",
"fôramos",
"ripador",
"subzona",
"caseosa",
"coroava",
"esterei",
"exílico",
"fundira",
"ocultem",
"inovava",
"bárions",
"compone",
"miramar",
"pinicar",
"alembra",
"lépidos",
"vindita",
"amolado",
"expirem",
"fichada",
"soneira",
"conjugo",
"aforada",
"baseamo",
"cagaita",
"frésias",
"treloso",
"detença",
"sedendo",
"infunda",
"omáguas",
"afogara",
"nebular",
"sirenas",
"vacilem",
"árticos",
"datável",
"tarelho",
"turbado",
"afianço",
"lotarem",
"passeis",
"virtues",
"corarem",
"gálbano",
"subires",
"coubera",
"esmegma",
"infecte",
"abacial",
"defesos",
"ungiram",
"amoldem",
"cerceou",
"equídeo",
"condoer",
"janotas",
"acolhes",
"chulice",
"mascaro",
"taperas",
"impulsa",
"mãozada",
"ralamos",
"ardinas",
"bogalhó",
"seriema",
"tombara",
"enforme",
"lutares",
"nutrira",
"sapador",
"sulinas",
"teutões",
"toxoide",
"zapeada",
"fundões",
"mulinha",
"vogando",
"aferram",
"cacunda",
"solapam",
"falseta",
"abelhão",
"campião",
"massala",
"agilita",
"careiro",
"fucsina",
"idílios",
"longura",
"surucuá",
"afixara",
"padeçam",
"realoca",
"fibrado",
"pisaras",
"superfã",
"tourais",
"acuando",
"anosmia",
"apitada",
"atraque",
"funéreo",
"recosto",
"taipais",
"baguera",
"achatou",
"algália",
"batizai",
"cardite",
"enfesto",
"estulta",
"odonata",
"pulemos",
"sílfide",
"esteato",
"rebelei",
"anilhar",
"garimpe",
"deterão",
"esgrime",
"larache",
"espiche",
"ovíparo",
"negrões",
"primate",
"toledos",
"muscles",
"adoreia",
"deprimo",
"emboaba",
"fusiona",
"oleadas",
"adensou",
"pirosos",
"quantis",
"trigona",
"expluda",
"galaica",
"cafuzos",
"capados",
"miíases",
"refugou",
"troncha",
"abismar",
"palaces",
"platine",
"descure",
"equipei",
"ignotos",
"bolinas",
"inundem",
"recende",
"sambava",
"ubíquas",
"belugas",
"indexes",
"pingada",
"betumes",
"contuso",
"aerados",
"lustral",
"minoica",
"refutei",
"subiras",
"tacapes",
"merlões",
"basadas",
"buruquê",
"faveira",
"sebosos",
"toldada",
"cinzela",
"pendera",
"traíste",
"adenite",
"esnobam",
"ladrava",
"trilião",
"vagares",
"mitigou",
"opresso",
"tebaida",
"verboso",
"amansou",
"bivolts",
"brecada",
"britado",
"lindote",
"tepidez",
"deserda",
"galista",
"gemeram",
"apoiamo",
"arrumas",
"cintada",
"fogaças",
"calosas",
"cavalgo",
"exortai",
"voltear",
"invades",
"revesar",
"vareiro",
"amareis",
"odiento",
"sinetes",
"frearam",
"piscado",
"estufam",
"faustos",
"fuinhas",
"juritis",
"alarmas",
"compeli",
"colaria",
"ecoarem",
"glabela",
"moldavo",
"amorreu",
"poltrão",
"rezados",
"sucumbo",
"cavalão",
"juremal",
"fresado",
"graxaim",
"afadiga",
"hérulos",
"sebosas",
"ditamos",
"olfação",
"coirmão",
"frísios",
"grelhei",
"guanaco",
"falcato",
"furasse",
"tietado",
"acanhar",
"tapirus",
"badejos",
"beijamo",
"votares",
"enrugam",
"navegas",
"antinha",
"coligou",
"coseram",
"guacuri",
"habituo",
"lexemas",
"singram",
"estoira",
"grupado",
"arapaçu",
"rematam",
"acatava",
"lacraus",
"lanugem",
"docagem",
"regrida",
"lenções",
"antenar",
"vareira",
"almejem",
"pateiro",
"loucona",
"uivavam",
"urdidos",
"rabelos",
"envidou",
"fissões",
"fratria",
"refugie",
"truchas",
"ensejem",
"aquieto",
"brunela",
"tenesmo",
"arredam",
"ordálio",
"evacuem",
"exporei",
"feltrar",
"arrimos",
"excurso",
"infamar",
"larilas",
"orenoco",
"palesta",
"papaias",
"tripula",
"avariam",
"détente",
"lambdas",
"miração",
"graduam",
"raianas",
"rúnicas",
"gravito",
"imitara",
"inopino",
"mórfico",
"enfiara",
"abanado",
"aviados",
"matação",
"umbelas",
"bafejar",
"decompô",
"falenas",
"fijiano",
"hirsuto",
"zanguem",
"arrimar",
"atrição",
"coçavam",
"divises",
"fascial",
"paranga",
"assarem",
"havíeis",
"samburá",
"eritreu",
"topadas",
"luteria",
"espumam",
"massaja",
"acometa",
"búfalas",
"sizígia",
"vazavam",
"creriam",
"demonho",
"gratina",
"oxicoco",
"tendais",
"virtuas",
"alvinha",
"cotocos",
"engajei",
"penedia",
"camones",
"elusiva",
"septeto",
"angélia",
"cumpres",
"epistar",
"lóculos",
"conatus",
"regatão",
"ximango",
"empapar",
"encodar",
"ortigas",
"passoca",
"zipadas",
"cibório",
"cubatas",
"fateixa",
"rosáceo",
"amanham",
"migrava",
"pressos",
"ticunas",
"irrigou",
"gusanos",
"benzila",
"caçapas",
"lambuzo",
"liófilo",
"posture",
"apótema",
"cartuxo",
"diátese",
"enrolão",
"enuncie",
"ofrenda",
"tardado",
"alentou",
"mantemo",
"apetito",
"bauxite",
"grandeá",
"mássico",
"mênades",
"baladão",
"cutelos",
"pagados",
"agonizo",
"bandada",
"gustava",
"nadaria",
"sotalol",
"aparava",
"cubetas",
"ofídico",
"ralhete",
"urdindo",
"blocado",
"capotam",
"impalas",
"mascada",
"arreado",
"escutes",
"franque",
"genovez",
"pitosga",
"quererê",
"balázio",
"terrado",
"glabros",
"remamos",
"culpara",
"entorte",
"expeçam",
"mascare",
"catados",
"catexia",
"chiaram",
"ferrato",
"habitei",
"mitigam",
"ornavam",
"canasta",
"esgalha",
"wysiwyg",
"baldado",
"zangues",
"alopata",
"penhoar",
"psiques",
"paneiro",
"remoque",
"subasta",
"tártara",
"laracha",
"despiam",
"impudor",
"itororó",
"sujasse",
"balidos",
"eximido",
"meninge",
"olvidam",
"papáver",
"atanado",
"cenóbio",
"ignores",
"objetam",
"perdita",
"moagens",
"retirai",
"secaria",
"zeramos",
"amitraz",
"copitos",
"revolva",
"ultimam",
"doleira",
"rostral",
"entalha",
"carpelo",
"refreou",
"relando",
"sicário",
"topinho",
"cingira",
"coruche",
"edênica",
"espalme",
"saludar",
"derriba",
"encaras",
"estives",
"fanconi",
"optarei",
"reggaes",
"saloias",
"brindem",
"frígios",
"bizzaro",
"arcaram",
"bicuíba",
"opinara",
"receies",
"arqueja",
"evacuam",
"mielite",
"brochou",
"poldras",
"salobro",
"xerecas",
"machona",
"pregues",
"vítreas",
"empenou",
"tontear",
"gavagem",
"timento",
"alembro",
"doessem",
"penaram",
"racinha",
"rifando",
"apartei",
"divirja",
"fofices",
"gabonês",
"obstara",
"serieis",
"premeio",
"sustido",
"átropos",
"balizou",
"revidei",
"etmoide",
"fitares",
"incaica",
"jarobas",
"mocreia",
"provete",
"pobreta",
"pecaste",
"abanões",
"esterna",
"estrepe",
"gravure",
"quetzal",
"soletro",
"canseis",
"emudeci",
"iatista",
"malhete",
"tabelão",
"afásico",
"ebonite",
"rebelem",
"santola",
"manchus",
"muxoxos",
"postule",
"baquete",
"cumpras",
"ombreia",
"chaveco",
"exempla",
"varonia",
"tundras",
"zunidos",
"pernuda",
"amimais",
"ferente",
"reluziu",
"tudinha",
"atendas",
"emigrei",
"agacham",
"bromato",
"quelhas",
"pocinha",
"sórbico",
"absides",
"falcata",
"mesária",
"nitrilo",
"patolas",
"plintos",
"vaiavam",
"amaciou",
"chagava",
"exonere",
"medirei",
"plotada",
"tiquira",
"votivos",
"astreia",
"carmina",
"fugires",
"oleados",
"ossudos",
"pitante",
"trigoso",
"gorjeia",
"bichice",
"ditosas",
"julgamo",
"ovárica",
"visione",
"chavala",
"difenil",
"edípico",
"nominou",
"deporem",
"postila",
"cerúmen",
"jurarás",
"injetem",
"lavante",
"revezou",
"súplice",
"tratora",
"tóricas",
"varvito",
"garrano",
"setênio",
"denigra",
"renomes",
"férreos",
"xistosa",
"juncado",
"ordenas",
"pipetar",
"tablada",
"aquênio",
"maldigo",
"chefete",
"cromita",
"influam",
"lanceis",
"sevícia",
"intenda",
"quóruns",
"tapagem",
"ventile",
"aprazia",
"coativo",
"dimanam",
"panatis",
"perdias",
"auroral",
"debanda",
"declamo",
"loureço",
"repitas",
"brumoso",
"enchova",
"zurrapa",
"chopada",
"desande",
"diedros",
"trineta",
"calejar",
"espinas",
"garrada",
"petréis",
"forâneo",
"inglese",
"marejam",
"otalgia",
"tangida",
"censora",
"peletes",
"pulados",
"aimarás",
"lavarei",
"terrara",
"amigado",
"arrière",
"conduze",
"coroara",
"inibina",
"acapela",
"ignotas",
"aprazer",
"beatice",
"mensure",
"munguzá",
"urgindo",
"bacelos",
"deslise",
"lívidas",
"useiros",
"vacinei",
"empatas",
"ensines",
"erógeno",
"gelosia",
"heptano",
"pinados",
"reprimo",
"trilhem",
"indicie",
"dirígio",
"invites",
"sicrana",
"apupado",
"dolinas",
"tareias",
"velames",
"encarno",
"livreco",
"láticas",
"trareis",
"tricana",
"andadas",
"enleios",
"rebitar",
"rotulem",
"acantos",
"deploro",
"dignado",
"marrano",
"mazembe",
"andeiro",
"cacheia",
"engolfa",
"eréteis",
"espanco",
"pontear",
"reentra",
"avencal",
"imergem",
"merdosa",
"ouropel",
"vereado",
"avalias",
"boicoto",
"guinéus",
"locusta",
"molhara",
"panoias",
"pôntico",
"acensão",
"apodado",
"cagarem",
"canitos",
"conquis",
"jujitsu",
"lobadas",
"demando",
"estiola",
"lincado",
"amarado",
"azuleno",
"empacam",
"matados",
"tentais",
"vestimo",
"alvejam",
"bastida",
"cumbuco",
"fincava",
"palhiço",
"patines",
"acossar",
"anômico",
"conotam",
"munício",
"recitem",
"clériga",
"fáctico",
"ilíacos",
"invitar",
"socalco",
"umbrela",
"asinina",
"baratim",
"faúlhas",
"tingiam",
"ínsitos",
"chupins",
"excitei",
"insonso",
"límbica",
"recurva",
"gravato",
"minavam",
"apneias",
"derrape",
"fedelha",
"fônicos",
"grassas",
"lingala",
"viagras",
"pulpite",
"sambada",
"soluçam",
"acedera",
"artolas",
"açodado",
"chumbei",
"papismo",
"sairdes",
"alfanje",
"banirem",
"chasque",
"sacavam",
"embebeu",
"manches",
"mangalô",
"meganha",
"alcofra",
"alvéola",
"noético",
"rebusca",
"reviste",
"vertiam",
"xeretas",
"ilidida",
"líberos",
"saldões",
"tintins",
"aloirar",
"panadas",
"jejunal",
"papadas",
"isolara",
"caleira",
"ugandês",
"boleada",
"ominoso",
"recuses",
"sacudam",
"esparro",
"infensa",
"sibilas",
"forrava",
"roséola",
"fômites",
"pazadas",
"aplicai",
"leprose",
"mucoide",
"edomita",
"remaram",
"sebento",
"tabulas",
"tuitado",
"pelegas",
"bilioso",
"cambucá",
"lodosos",
"rubores",
"sangrem",
"umerais",
"aforado",
"afônica",
"picadão",
"sumagre",
"babugem",
"nisseis",
"pleuras",
"sucções",
"membras",
"degolam",
"descaro",
"desviai",
"esvaiam",
"pálamos",
"volteia",
"gostada",
"arcamos",
"engraxa",
"fosfina",
"galesas",
"gorazes",
"picudos",
"agoniar",
"alçarem",
"beguine",
"chifrou",
"incréus",
"minorca",
"moviola",
"pistori",
"azedado",
"aristas",
"atrofio",
"cardado",
"farinho",
"jorrara",
"tincoãs",
"epítopo",
"esmurro",
"fechais",
"fuzilam",
"negrita",
"orlinha",
"soirées",
"timbale",
"aráceas",
"piramos",
"apócope",
"singrou",
"ranhoca",
"galalau",
"iodados",
"mamavam",
"quandro",
"venusta",
"viandas",
"molecão",
"resvale",
"aeradas",
"folgava",
"hidrose",
"plainar",
"expedem",
"incursa",
"indouto",
"amealha",
"arderão",
"crustal",
"lucerna",
"odienta",
"relocar",
"sararam",
"dosséis",
"folíolo",
"forjara",
"relvada",
"somemos",
"tourigo",
"amiense",
"gilista",
"vitelas",
"embasem",
"tarraço",
"afanado",
"clubeco",
"objetor",
"paceiro",
"barreno",
"encalce",
"tornemo",
"turunas",
"ugolino",
"acostou",
"babaram",
"bambusa",
"aerovia",
"atonais",
"irrompa",
"sanefas",
"grimpas",
"laricas",
"sapólio",
"atuemos",
"bombeie",
"expiada",
"ladeava",
"remitir",
"cavarem",
"faíscam",
"hasteia",
"ipuense",
"quibebe",
"refains",
"unitiva",
"remígio",
"cornada",
"eliciar",
"embalem",
"emburra",
"frechou",
"trilema",
"bocadas",
"delatam",
"geofone",
"sinucas",
"vilezas",
"caranha",
"galgava",
"opusera",
"unitivo",
"arcasse",
"vagidos",
"copadas",
"etólogo",
"jugadas",
"negaças",
"sistina",
"unciais",
"acentuo",
"avoenga",
"bicheza",
"contina",
"emudeço",
"imitido",
"orbitou",
"perlite",
"riachão",
"samarra",
"buzinei",
"empatei",
"encimar",
"volvida",
"peneiro",
"trasgos",
"baiacus",
"danando",
"digesta",
"cireneu",
"sarrada",
"aeterno",
"solvida",
"compilo",
"regerem",
"rimador",
"treinas",
"arquejo",
"vacunas",
"aplaque",
"lizados",
"chatura",
"atlanto",
"bobices",
"engrene",
"lapidam",
"preverá",
"vexação",
"bátegas",
"cabeado",
"detetam",
"lempira",
"ovulado",
"paquero",
"areinho",
"buchuda",
"catadas",
"cozeram",
"mahatma",
"marchei",
"pontara",
"granita",
"sinetas",
"alverga",
"aplaina",
"iodetos",
"lóticos",
"acresço",
"rosélia",
"escopro",
"escudam",
"vetasse",
"bandola",
"lionesa",
"pânfilo",
"inverna",
"malinês",
"trombam",
"armasse",
"jimbras",
"reporem",
"fúrcula",
"óctupla",
"anelava",
"apicuns",
"camaquã",
"chicles",
"guiaste",
"evocara",
"lividez",
"estilho",
"podíeis",
"abraçai",
"alteras",
"bebiana",
"cardial",
"moletão",
"amansam",
"argutas",
"fletida",
"tisnada",
"cativem",
"ressoem",
"servimo",
"pisarei",
"tunagem",
"avançai",
"filtrem",
"influía",
"pitecos",
"planava",
"pégasos",
"quixadá",
"reumato",
"scherzo",
"tucunas",
"retesar",
"subtons",
"calemos",
"corteza",
"erradia",
"maçapão",
"abancar",
"cerquei",
"friezas",
"impante",
"quelado",
"alferce",
"arrende",
"depunha",
"entorne",
"expando",
"moletas",
"penante",
"rebuços",
"deporto",
"mutucas",
"púrpuro",
"refreie",
"cruzamo",
"neógama",
"vigiara",
"namoras",
"surtava",
"tetando",
"varável",
"frentea",
"juerana",
"morarei",
"argânia",
"encarga",
"epóxido",
"frechal",
"gelaram",
"abotoou",
"anfioxo",
"externe",
"lavaras",
"varonis",
"amarias",
"bundada",
"velaram",
"arreada",
"rizóbio",
"rãzinha",
"carcaju",
"corixos",
"foderia",
"gongado",
"raçudos",
"secundo",
"pereças",
"repetis",
"samoano",
"casaste",
"chamamo",
"gabarem",
"gratine",
"currada",
"fecheis",
"gasista",
"pandoro",
"turrona",
"agrafar",
"amigada",
"edáfica",
"quitara",
"inteire",
"rapsodo",
"ajuízam",
"beiçuda",
"cabruca",
"cativei",
"retalia",
"afebril",
"alojara",
"anexara",
"lajense",
"matinée",
"rúnicos",
"centrem",
"engulho",
"lactona",
"sepulte",
"uncária",
"bradado",
"cederei",
"zumbiam",
"cafuçus",
"histero",
"ionizam",
"piracuí",
"péptido",
"rasinho",
"logique",
"verbera",
"capitam",
"casotas",
"selarem",
"zelavam",
"ciclana",
"coisado",
"fatuais",
"tânicos",
"brumosa",
"eletrão",
"embotam",
"extirpe",
"golungo",
"granate",
"guarapa",
"lxxxiii",
"mexidão",
"trituro",
"aqueces",
"botelha",
"cíclame",
"estripe",
"linária",
"lisador",
"borboto",
"embirra",
"gospeis",
"trastos",
"usbeque",
"coisada",
"enluvar",
"repicou",
"brigues",
"mastaba",
"receias",
"remavam",
"teclava",
"gamação",
"toleras",
"volscos",
"buchudo",
"farpela",
"lavarão",
"adípico",
"bermudo",
"ovoides",
"pularei",
"entoara",
"salaria",
"lixamos",
"mudaras",
"papalvo",
"cachopo",
"coronas",
"fosfito",
"fregona",
"lidasse",
"aninhei",
"esfolei",
"farpada",
"mirados",
"betesga",
"raqueta",
"apegara",
"aventam",
"nynorsk",
"sintais",
"luzerna",
"perinho",
"termite",
"ecótono",
"fanicos",
"ovípara",
"setados",
"cadaval",
"turvada",
"garages",
"plúrima",
"pedéria",
"samaúma",
"viviano",
"cariosa",
"especto",
"anexins",
"antrais",
"ecoarão",
"gládios",
"volumão",
"morcega",
"previsa",
"recline",
"varvara",
"vicejou",
"ajudeis",
"arengar",
"avexado",
"brindas",
"desferi",
"ratones",
"risquem",
"anovela",
"diorito",
"arruado",
"embatem",
"feminis",
"lectora",
"refutem",
"zebuína",
"empunho",
"reveles",
"separai",
"tarando",
"xucurus",
"divisam",
"infinit",
"pirogas",
"estanha",
"flechar",
"verismo",
"fortins",
"pifando",
"raciona",
"ripadas",
"sacasse",
"sorites",
"túberas",
"aprovas",
"bambear",
"hífenes",
"sinóvia",
"flertei",
"guarabu",
"planche",
"caribes",
"deturpe",
"dourava",
"obturar",
"vergéis",
"ímproba",
"adoreis",
"arrátel",
"fornida",
"tragara",
"eleatas",
"embosca",
"etarras",
"incarna",
"teceste",
"éclogas",
"bávaras",
"egestas",
"clorela",
"empesas",
"trincam",
"bufetes",
"desempe",
"ingiram",
"anegada",
"canadas",
"prioste",
"aislada",
"alarmam",
"bicorno",
"cagarro",
"calares",
"ciliada",
"erectas",
"massaje",
"assinas",
"errarão",
"mozinho",
"plectro",
"solapou",
"penamos",
"revesam",
"amorita",
"desnude",
"digeria",
"európio",
"fecunde",
"molecas",
"reverti",
"madonas",
"mimaram",
"supinos",
"zoneado",
"arrulho",
"condigo",
"manetas",
"radicas",
"retraça",
"transem",
"castrei",
"curaste",
"esvaída",
"gambeta",
"lesione",
"retesou",
"tokamak",
"alexina",
"balofos",
"morgada",
"secação",
"tunando",
"encerou",
"juizada",
"naturas",
"alumiou",
"chipada",
"conetar",
"entupam",
"estilha",
"pugnado",
"casulas",
"escanda",
"medicam",
"clítico",
"coligiu",
"guindou",
"laxista",
"lecione",
"prazerá",
"represe",
"vínicas",
"aristos",
"cocriar",
"lonômia",
"nereida",
"obcecar",
"pegunto",
"peitada",
"tonteia",
"videiro",
"balofas",
"emboque",
"gateado",
"dilatem",
"destile",
"gaiatas",
"maciais",
"narcisa",
"pardela",
"enlatar",
"ibirubá",
"mamador",
"favinha",
"carnava",
"ossudas",
"salivam",
"acetado",
"sílicas",
"turbada",
"bráctea",
"clamada",
"detonem",
"durismo",
"taxarem",
"tecedor",
"zaranza",
"sacação",
"tapasse",
"rapinar",
"surungo",
"tépidos",
"abjurou",
"doseado",
"pranche",
"talocha",
"tapuios",
"astracã",
"atearem",
"bacocas",
"cegavam",
"dicções",
"ignitor",
"inuítes",
"jungida",
"ovárico",
"litigam",
"mesclem",
"abusara",
"cambono",
"guaianá",
"belenos",
"logadas",
"morcões",
"patacos",
"sparing",
"trinque",
"coraram",
"eclodia",
"núncios",
"plicoma",
"sujaria",
"ariribá",
"azotada",
"caneiro",
"coçarem",
"grudara",
"mitenes",
"picamos",
"vestias",
"ínsitas",
"cagamos",
"incerte",
"ossetas",
"tuições",
"cabista",
"comados",
"estrepa",
"cassava",
"comoros",
"desrama",
"faríeis",
"isidoro",
"lanchou",
"palamas",
"piornos",
"regavam",
"rosiana",
"numerou",
"paquere",
"resigne",
"sucrose",
"tratamo",
"varados",
"donzelo",
"espanou",
"galgada",
"barbeta",
"brogues",
"custeie",
"matadas",
"prostre",
"varavam",
"cravina",
"eirense",
"empatem",
"glicóis",
"bisagra",
"caixona",
"courato",
"impeças",
"pienses",
"retraio",
"correis",
"osteoma",
"segóvia",
"bruteza",
"estrega",
"elitize",
"clivado",
"inflava",
"oporiam",
"dóricos",
"fretada",
"clivada",
"palpado",
"quantil",
"reparai",
"ricerca",
"turvado",
"validei",
"cabeçal",
"pinchar",
"reanime",
"chantre",
"cotinha",
"estrava",
"omitiam",
"sedante",
"canaval",
"astéria",
"baseias",
"exílica",
"queimor",
"arapari",
"búdicas",
"cúprico",
"gerúsia",
"novense",
"peidava",
"saudeis",
"abraces",
"baloiça",
"amandar",
"aturdir",
"donaire",
"fornica",
"glosada",
"inquina",
"parolas",
"tajique",
"fundeou",
"papular",
"retinta",
"sabroso",
"samário",
"sincelo",
"toutiço",
"balanga",
"parcéis",
"verseja",
"arrogou",
"fornido",
"gemerem",
"gretada",
"soergue",
"tranvia",
"afagada",
"fungagá",
"hissope",
"erremos",
"mangual",
"rúculas",
"narceja",
"tisnado",
"curaçau",
"elogias",
"sumidão",
"mesetas",
"pediras",
"zebuíno",
"ataviem",
"bruacas",
"goiamum",
"guampas",
"jacamim",
"laborda",
"actínio",
"arquite",
"boanova",
"virares",
"vosmicê",
"agresta",
"encetei",
"esbirro",
"garnisé",
"lajeada",
"filando",
"franzem",
"ofendas",
"safamos",
"tapeado",
"gastada",
"margear",
"boeiros",
"comovam",
"fermoso",
"expirei",
"macário",
"vândala",
"bufaria",
"remoçar",
"encetam",
"fujindo",
"saletas",
"sustida",
"aleijam",
"lixinha",
"papeiro",
"afídios",
"cifrava",
"glaucos",
"máficos",
"noticie",
"purgada",
"recompô",
"salvada",
"sufraga",
"sígnico",
"vagueie",
"cedular",
"judiava",
"maçadas",
"opormos",
"remiste",
"pirarem",
"bailões",
"esmeris",
"refilão",
"vigorem",
"aclaram",
"alumiam",
"indexou",
"pocando",
"scabiei",
"aranhão",
"empreso",
"filiara",
"infetar",
"tarifar",
"tolerei",
"esvaece",
"ferirão",
"colidia",
"cubelos",
"hexoses",
"levitou",
"resvalo",
"revezem",
"charlar",
"fazível",
"motives",
"rombuda",
"afeções",
"chamemo",
"portelo",
"suiriri",
"teclada",
"haurido",
"icoense",
"porunga",
"pretume",
"tépalas",
"atinges",
"chochos",
"crismar",
"errarei",
"fulcros",
"montesa",
"augures",
"caseoso",
"encapou",
"medrado",
"nodosos",
"pitocos",
"vigária",
"antíope",
"açoteia",
"desabem",
"drenava",
"nodosas",
"palatos",
"riremos",
"inflija",
"quintis",
"cãozito",
"gorados",
"podaram",
"veranil",
"pírrica",
"remidas",
"acampem",
"agrupei",
"fâmulos",
"retreta",
"olubajé",
"jundiás",
"marrada",
"núcleon",
"rapando",
"recriem",
"amofina",
"cavucar",
"deligar",
"irradio",
"picardo",
"chapins",
"numerei",
"enlacei",
"escaldo",
"reavive",
"deponho",
"enlutou",
"intervi",
"melisma",
"baldear",
"opróbio",
"upsilon",
"vagueei",
"arreata",
"assante",
"ecoados",
"enleada",
"paumari",
"socaram",
"maranha",
"pedalas",
"vilosas",
"caulino",
"flechou",
"fungava",
"fêveras",
"lugarem",
"imantar",
"amelina",
"ensaiem",
"franciú",
"amarica",
"cólofon",
"revivam",
"sanarem",
"bajando",
"hioideo",
"triedro",
"triscar",
"aportem",
"choupal",
"corujar",
"ilírios",
"inhaúma",
"topical",
"cataram",
"escólex",
"prendei",
"parimos",
"avultar",
"elamita",
"frustam",
"maturam",
"rafeira",
"unheiro",
"anergia",
"bulufas",
"filtrei",
"derroto",
"espumou",
"esterca",
"galando",
"mediava",
"suporem",
"amanhem",
"apontes",
"baldias",
"condoeu",
"descont",
"folhoso",
"injetei",
"palpito",
"vivário",
"aguerra",
"coloram",
"dríades",
"gateiro",
"agrafas",
"cognata",
"erídano",
"haurida",
"nubiana",
"papados",
"accione",
"arneses",
"saudara",
"sumirei",
"aludiam",
"dadeira",
"lãzinha",
"regaços",
"abrasou",
"balouço",
"cegadas",
"sanaram",
"sepulto",
"posasse",
"vizires",
"mexeres",
"olfatos",
"receado",
"recifal",
"sobejou",
"térmita",
"velejam",
"acalmas",
"cagação",
"regatar",
"abúlico",
"acetila",
"bossais",
"escalem",
"ápeiron",
"focarão",
"regalou",
"sobrepô",
"receiem",
"timbila",
"vibrada",
"bordeja",
"riquexó",
"tatuíra",
"catiões",
"emudeça",
"enlevam",
"rematei",
"túrgido",
"alentam",
"ancorem",
"atuadas",
"chinfra",
"ejacule",
"esteval",
"felpado",
"tesoiro",
"biaxial",
"capucha",
"ferroar",
"floreta",
"pedales",
"badalou",
"repunha",
"lancham",
"leonado",
"bravado",
"airosas",
"encrave",
"engraxe",
"esterol",
"férrica",
"maquiné",
"víricas",
"auroque",
"brandis",
"cuboide",
"martele",
"modulou",
"teremim",
"hinódia",
"maninas",
"oitante",
"cegamos",
"decolei",
"degelar",
"háptico",
"sacadão",
"albinia",
"esbaldo",
"estudes",
"ofendes",
"rombudo",
"alindar",
"omolocô",
"puderia",
"agarres",
"imolara",
"sugasse",
"tafetás",
"abinhas",
"aprazem",
"avenses",
"exempli",
"gemente",
"ebúrnea",
"pacóvia",
"rogasse",
"trifena",
"voivoda",
"duranta",
"pularão",
"talinho",
"viverra",
"cacaria",
"fúlgido",
"lajares",
"anseiem",
"badalam",
"cafeico",
"ecoasse",
"imiteis",
"lindano",
"maquias",
"nutação",
"joeirar",
"judicio",
"poisada",
"sanctus",
"tiborna",
"apanhes",
"púcaros",
"despega",
"esbanjo",
"esporre",
"fartamo",
"fóbicas",
"tossida",
"viminas",
"asserio",
"desejai",
"friense",
"itérbio",
"depuram",
"enuncio",
"excitem",
"ousarão",
"visarão",
"carriça",
"guachos",
"vindicá",
"vivível",
"adernar",
"apearam",
"capitol",
"diverto",
"geraste",
"lacerar",
"peitado",
"baqueou",
"barjona",
"condita",
"imputes",
"tosados",
"viciava",
"anódico",
"cevando",
"cornuda",
"exortes",
"rezarão",
"vertera",
"atemoia",
"cabidos",
"conveio",
"fitados",
"híspida",
"olhalvo",
"patotas",
"suábios",
"agulhar",
"colmata",
"fitamos",
"pelicas",
"tableta",
"espirou",
"faveiro",
"reouvir",
"tricone",
"colofão",
"duramos",
"minutar",
"peixões",
"perinha",
"ramaria",
"reveria",
"broxado",
"clônica",
"espolia",
"folgara",
"indenes",
"redenho",
"condiga",
"homines",
"precito",
"rodeiro",
"titulam",
"deglute",
"deitamo",
"neopagã",
"hígidas",
"juntemo",
"nadasse",
"brousse",
"entulha",
"tariana",
"butanês",
"fortona",
"himeneu",
"olamina",
"usastes",
"venerem",
"alimpai",
"cômpito",
"feriais",
"matutei",
"meterei",
"miopias",
"refusão",
"cremepe",
"ftálico",
"fungado",
"passamo",
"bafejou",
"botecar",
"crismas",
"entocar",
"háptica",
"insurgi",
"parável",
"salteei",
"alauita",
"elações",
"esvaíra",
"falimos",
"involui",
"regemos",
"amentar",
"induzas",
"todolos",
"tourega",
"escorei",
"imbrica",
"insurja",
"alhetas",
"babaria",
"buretas",
"germino",
"irisada",
"vergava",
"brancal",
"lavares",
"lígures",
"odiador",
"panachê",
"priveis",
"refluiu",
"escaneá",
"suratas",
"topavam",
"acerque",
"ancorei",
"clastos",
"gaivina",
"minetes",
"sugamos",
"albiano",
"bicavam",
"desgelo",
"divides",
"envidas",
"epígono",
"guingão",
"irritas",
"laringo",
"zombara",
"divisei",
"emerjam",
"girarão",
"jazente",
"milhete",
"rutenos",
"arribes",
"colador",
"lançamo",
"mesclei",
"plúmbea",
"sanhaçu",
"atritam",
"babosas",
"bebinha",
"catavam",
"incaico",
"intimei",
"pericos",
"regredi",
"repares",
"setadas",
"sugesta",
"abanada",
"divague",
"doseada",
"janitor",
"poaense",
"atolava",
"chernes",
"emborca",
"peruada",
"chiripá",
"incumbi",
"notaras",
"sabugal",
"acudira",
"definho",
"lisboas",
"livremo",
"pisquem",
"punirei",
"ressuma",
"televes",
"córneos",
"destilo",
"destoem",
"implume",
"mandais",
"massuda",
"quiçava",
"sadista",
"camufle",
"cerzido",
"eufônio",
"acanhou",
"agourar",
"cerosas",
"parirem",
"patioba",
"velarei",
"bósnias",
"enchemo",
"filária",
"jarreta",
"quirela",
"sienito",
"tisbita",
"atulhar",
"ailanto",
"arrugas",
"giração",
"revides",
"gafaria",
"guaiaco",
"recanta",
"semeiem",
"catrina",
"quindão",
"gorando",
"perfaça",
"abrasem",
"guipura",
"lampeja",
"pedrais",
"puxarão",
"teatino",
"testudo",
"bifaces",
"emersos",
"meditai",
"tiravas",
"admitas",
"aplacam",
"diluirá",
"farnéis",
"lascava",
"aversos",
"pedalem",
"galucho",
"numeram",
"pretere",
"caçoado",
"diríeis",
"lobismo",
"álgicos",
"acanham",
"cerrara",
"esfriei",
"perilla",
"relates",
"tapeada",
"brunido",
"jarimba",
"oocisto",
"separas",
"víspora",
"acrania",
"asfixie",
"rataria",
"rederam",
"trolada",
"ubaense",
"banquem",
"fleches",
"igualha",
"oprimam",
"paturis",
"pugnava",
"reifica",
"apalpai",
"arrojam",
"falseou",
"gamelão",
"luditas",
"moderas",
"siringe",
"naceles",
"pedição",
"prócion",
"vivório",
"brócolo",
"catecol",
"cordado",
"fuçadas",
"listelo",
"tralhão",
"tripeça",
"anafada",
"mutamba",
"oficiem",
"agraves",
"escoara",
"felídeo",
"hiberno",
"ladrado",
"linhito",
"pronaos",
"adestre",
"exclame",
"melgaço",
"mínguas",
"pretona",
"tentame",
"velamos",
"carreou",
"discuso",
"incubam",
"malares",
"securas",
"trateis",
"espeito",
"garança",
"lávicas",
"mustela",
"perenal",
"surrava",
"cheirão",
"embolei",
"floriam",
"magnate",
"meterão",
"vagasse",
"dorados",
"felonia",
"papudos",
"córnico",
"ignaras",
"lagoaça",
"pinando",
"camorra",
"despira",
"ilangue",
"cornear",
"sacaste",
"bibocas",
"diádica",
"encucar",
"festonê",
"incivil",
"demarco",
"lupinos",
"oxigeno",
"trimarã",
"amargue",
"descasa",
"foliona",
"galdina",
"instruo",
"oscilem",
"regalam",
"tricolo",
"hobista",
"toalhão",
"alçamos",
"botarei",
"genista",
"mamados",
"memorar",
"murinos",
"reganho",
"abalroa",
"colapse",
"desfira",
"inibiam",
"jarrões",
"vararam",
"apossei",
"esgoela",
"malabar",
"menacme",
"palhete",
"saquito",
"tectona",
"umutina",
"apreços",
"bustiês",
"cargada",
"cruezas",
"jubilou",
"apedido",
"dataram",
"grelham",
"mapeara",
"pratear",
"túrcica",
"aviamos",
"cotiano",
"mirarem",
"munhões",
"palavro",
"exabyte",
"execram",
"goleava",
"horando",
"lindura",
"mantena",
"olimpos",
"religou",
"taleigo",
"tijuaçu",
"valorou",
"acenada",
"insonsa",
"leucose",
"tutorar",
"zéfiros",
"ôhmicos",
"barcino",
"sitadas",
"surtida",
"velejei",
"xambioá",
"aliária",
"cultuou",
"encilha",
"limpais",
"percute",
"açafroa",
"enojava",
"lxxxvii",
"emboava",
"esculpa",
"malungo",
"puntura",
"reaches",
"tutelou",
"aquileu",
"cevadas",
"cármine",
"matutas",
"confine",
"entalho",
"gomadas",
"prótalo",
"regeria",
"sexênio",
"admirai",
"cremesc",
"desdito",
"refoque",
"sidônio",
"triples",
"tróclea",
"almudes",
"desatem",
"espaces",
"folguei",
"gamados",
"alucino",
"barbeou",
"calamar",
"decupar",
"descaia",
"esbateu",
"panasco",
"umbrosa",
"abnegar",
"degolas",
"ecoante",
"pirites",
"adoçava",
"cúltica",
"filetar",
"glutona",
"pirosca",
"reduzes",
"alapada",
"brilhei",
"citânia",
"deferem",
"febrões",
"haustos",
"pasmosa",
"pesador",
"pilotas",
"venação",
"vendias",
"zombada",
"bedelia",
"cotizar",
"cururus",
"fixista",
"olhador",
"amontoe",
"atestei",
"estufei",
"jugamos",
"voltemo",
"dirijas",
"fajarda",
"galeada",
"oloroso",
"túrgida",
"conteis",
"eloísta",
"espocam",
"esposou",
"formigo",
"meneiam",
"relerem",
"urravam",
"arredei",
"deduzam",
"langará",
"lêvedos",
"taquera",
"concani",
"fechamo",
"harmina",
"muzenza",
"parisse",
"plagiam",
"senados",
"subidão",
"vinilos",
"zelaram",
"alvadia",
"dublara",
"fulmine",
"obrinha",
"rosaria",
"embacia",
"empaque",
"eutimia",
"iludiam",
"naturam",
"putâmen",
"agoirar",
"alíseos",
"escovém",
"esmalto",
"maliana",
"zimbrão",
"apinham",
"currado",
"fadigar",
"grogotó",
"mareada",
"quência",
"somitos",
"comutam",
"ecoadas",
"avoados",
"enfaixa",
"esculte",
"minaria",
"motivas",
"pinimba",
"suscito",
"abundem",
"diérese",
"guimara",
"gímnico",
"pitinha",
"acicate",
"cataria",
"maganos",
"queimão",
"rachões",
"auditou",
"bilogia",
"bocudos",
"boticão",
"cascões",
"agíamos",
"arcella",
"grumoso",
"infúndi",
"lutadas",
"mijinha",
"duelara",
"estáter",
"flirtar",
"populam",
"repugne",
"rufando",
"sabinas",
"anuíram",
"demolha",
"exporão",
"labutou",
"poisado",
"sambado",
"soidade",
"trifeta",
"vistado",
"alerces",
"assassi",
"caçoava",
"futrico",
"inhumas",
"moderem",
"albanos",
"calista",
"destoca",
"latejou",
"moremos",
"soltada",
"aloprou",
"amanajé",
"ariados",
"estipes",
"gemidas",
"glótico",
"lúmenes",
"moderei",
"boieira",
"rapelar",
"recreie",
"aspirai",
"cavocar",
"mixador",
"pinguça",
"septais",
"sáurios",
"álgicas",
"bolinou",
"colimar",
"estígio",
"joviana",
"tuxauas",
"ameaços",
"grasnam",
"moitões",
"pilones",
"refunda",
"tigrina",
"encanei",
"gradear",
"incipit",
"galrito",
"maturou",
"achador",
"aleitar",
"bichões",
"cuvetes",
"elzevir",
"esbalde",
"lavanca",
"medinas",
"patinei",
"trolado",
"amerino",
"botados",
"coxeava",
"elidido",
"equinas",
"galguei",
"garoava",
"ocluída",
"tojeiro",
"vedavam",
"atônica",
"cassara",
"multara",
"verdeja",
"aquilos",
"barolos",
"decores",
"embicar",
"gímnica",
"apreçar",
"calibro",
"dopando",
"hererós",
"ladeiro",
"ticumbi",
"vigilar",
"boiarem",
"desdéns",
"encenei",
"hadoque",
"lucíola",
"mentida",
"rebrote",
"aminado",
"assuada",
"basante",
"canguçu",
"espojar",
"atrases",
"entalei",
"eunômia",
"anequim",
"exaltas",
"motense",
"quadrão",
"incesta",
"jucundo",
"testuda",
"chorrar",
"ordenai",
"parches",
"piletas",
"tiritas",
"arrojos",
"ominosa",
"pintoso",
"pirexia",
"repicam",
"toronja",
"velares",
"chungas",
"lastime",
"retocam",
"risotas",
"buracas",
"embicou",
"ilhotes",
"pincelo",
"asserto",
"cárpico",
"rolador",
"abrando",
"danitas",
"grabada",
"babonas",
"carbone",
"freagem",
"pontina",
"realcei",
"reguada",
"abduziu",
"alógeno",
"freados",
"panilas",
"peptona",
"bobinar",
"bragada",
"concave",
"dioxide",
"largata",
"tobiano",
"apresto",
"fônicas",
"térebra",
"urinada",
"angorás",
"bandara",
"bitonal",
"dedilho",
"esconsa",
"faturem",
"glicine",
"legendo",
"ardemos",
"córtice",
"embutia",
"zircões",
"arbitre",
"ataleia",
"mudavas",
"odiaste",
"piradão",
"sapeada",
"taurito",
"toantes",
"debulho",
"definhe",
"sobrada",
"zarpado",
"apregoe",
"cáprico",
"gastais",
"mariela",
"rumaria",
"sebinho",
"violine",
"arrolam",
"enforco",
"posados",
"superas",
"adernou",
"emanara",
"ensopou",
"sachola",
"vividez",
"filitos",
"flexura",
"grasnou",
"optaste",
"atreito",
"diachos",
"estriga",
"gritona",
"queneus",
"tendida",
"varação",
"uvinhas",
"cerdoso",
"perigam",
"pleiteá",
"soverte",
"cáspite",
"dejecto",
"dotarem",
"enrugue",
"garinas",
"matizam",
"megaton",
"candiru",
"celesta",
"japonas",
"tercina",
"camorim",
"citarão",
"maiatos",
"ourinho",
"pisoteá",
"resides",
"amorins",
"cacetas",
"crismal",
"impugne",
"trairei",
"tríbulo",
"bijeção",
"catamos",
"frescar",
"linhada",
"pernudo",
"pocaria",
"zunzuns",
"bundaça",
"enevoar",
"faculto",
"mucosos",
"sétuplo",
"teorize",
"timaços",
"abrigas",
"alvitra",
"odiemos",
"pagelas",
"remoído",
"verdeal",
"áuricos",
"alocara",
"aridade",
"crismou",
"estiram",
"gradara",
"insinuo",
"minagem",
"monição",
"arganaz",
"besunta",
"náiades",
"ourelas",
"piladas",
"primada",
"torneia",
"turquês",
"imperem",
"lidarei",
"teceria",
"agonize",
"atazana",
"mobilha",
"protrai",
"analema",
"attaché",
"caolhos",
"catucar",
"erúcico",
"gustará",
"lizadas",
"meninho",
"recreia",
"rosalvo",
"segrego",
"sipaios",
"xistoso",
"bajulou",
"opíparo",
"plantai",
"betaras",
"começai",
"puxares",
"brisado",
"foliada",
"latiram",
"oscular",
"rogaria",
"ascarel",
"cambito",
"crucias",
"nitrila",
"pidesco",
"presepe",
"septado",
"travesa",
"cabense",
"olefina",
"olvidos",
"solides",
"sugaria",
"verdial",
"courela",
"ejetora",
"sombrei",
"aliviai",
"amarada",
"fixante",
"pedraça",
"caribus",
"encarde",
"exceler",
"mitrado",
"orvalha",
"bolsada",
"brocada",
"centrei",
"cingiam",
"corrias",
"demolhe",
"entrono",
"manteca",
"mujique",
"prateia",
"regesse",
"tubário",
"bebinho",
"corunha",
"lixação",
"matungo",
"ponches",
"repelão",
"sacares",
"velarão",
"dataria",
"desvias",
"diluíam",
"droguei",
"erígena",
"gabaram",
"lenhina",
"reabram",
"redizer",
"gemadas",
"latirem",
"anrique",
"ausônio",
"decolem",
"gabirus",
"catrefa",
"ceifava",
"ctônico",
"gabarra",
"gremial",
"mentado",
"movamos",
"poluíam",
"apogeus",
"dêitico",
"faiando",
"infusas",
"maculem",
"racimos",
"saltões",
"engolfo",
"regaliz",
"fedores",
"súditas",
"velarem",
"aivados",
"amarrai",
"bióxido",
"brocoió",
"desando",
"fuzilei",
"olmeiro",
"tocavas",
"vexados",
"catadão",
"malvino",
"mofetil",
"mugunzá",
"opressa",
"talassa",
"aracnos",
"montano",
"muretes",
"sacaneá",
"tilinta",
"algemam",
"alqueno",
"brandem",
"cagavam",
"desvele",
"manobre",
"paração",
"sentina",
"boconas",
"enfriar",
"lizando",
"mulamba",
"reponta",
"anélito",
"arrifem",
"finuras",
"gosteis",
"ocupamo",
"acediam",
"boratos",
"cérceas",
"frasear",
"pestano",
"refilam",
"actante",
"briseis",
"galvano",
"jogaras",
"siálico",
"sorábio",
"chochas",
"digesto",
"inseres",
"ninhais",
"ousaste",
"saltito",
"atochar",
"danasse",
"embocar",
"maneire",
"mentruz",
"penelas",
"sarjado",
"lixarem",
"pesarei",
"sexados",
"sinopla",
"abotoam",
"arrogue",
"bornais",
"debelou",
"estripa",
"intimam",
"pisaste",
"propago",
"rezinha",
"batamos",
"embebia",
"emendem",
"esfalfa",
"gamense",
"labrega",
"pairara",
"paranho",
"ponteia",
"ulmária",
"zoóloga",
"floreia",
"radiado",
"temblar",
"acossam",
"aftosas",
"alegrão",
"bocadão",
"defessa",
"escavei",
"gurupés",
"jejuais",
"modelem",
"monodia",
"ondeado",
"orgíaco",
"petinho",
"aquecei",
"bagaças",
"barbata",
"bitolar",
"caranda",
"ebúrneo",
"exultei",
"falbalá",
"fanecas",
"gêmulas",
"jacobeu",
"sembrar",
"solapas",
"turrões",
"alvanir",
"dignava",
"espigos",
"héspero",
"sutache",
"vedaram",
"emerges",
"aguerre",
"capelos",
"cavalho",
"colgado",
"esposam",
"linteis",
"morfeia",
"chutara",
"folhiço",
"maceira",
"sinople",
"sódicos",
"cegasse",
"palapas",
"soleada",
"atônico",
"chegais",
"contruí",
"engoles",
"juremas",
"meleira",
"repinta",
"tuviras",
"ultimou",
"baradel",
"metrite",
"modelas",
"píretro",
"brasido",
"defluxo",
"efetora",
"evolvem",
"folhedo",
"repugno",
"acalmai",
"descora",
"remente",
"tecesse",
"beemote",
"breques",
"charcas",
"envidem",
"incense",
"lentino",
"tessele",
"vizeira",
"algeroz",
"fleugma",
"insurjo",
"postulo",
"sunismo",
"banimos",
"gozarei",
"pacovas",
"batizem",
"cipaios",
"cornuta",
"cravara",
"embalas",
"engrama",
"arcanja",
"emigras",
"hélicon",
"noitibó",
"pichava",
"puxaste",
"atarefa",
"clorito",
"dadivar",
"fúlgida",
"pidesca",
"protele",
"provirá",
"vascões",
"arcturo",
"biureto",
"invídia",
"marabus",
"pinicão",
"praxias",
"virotão",
"amarele",
"cagador",
"deslisa",
"magique",
"juçaral",
"pícrico",
"uivaram",
"altaica",
"aéticas",
"berilos",
"comutou",
"conjure",
"decotar",
"destape",
"lambões",
"lestada",
"lidemos",
"migueis",
"pamoato",
"tablita",
"vargedo",
"clonava",
"cumpris",
"háplico",
"judaria",
"livramo",
"minoico",
"padeces",
"pelitos",
"areação",
"engolis",
"socavão",
"costear",
"discava",
"empedra",
"invetar",
"seguias",
"tiveras",
"tonquim",
"baquear",
"indicão",
"molosso",
"nevrose",
"ondulou",
"balango",
"fitarem",
"iludira",
"pisseta",
"primara",
"unários",
"agrafes",
"guasque",
"matutou",
"pagadas",
"derruir",
"pulante",
"ajudemo",
"caddies",
"ciclina",
"estacam",
"gaibéus",
"glandes",
"padmini",
"pateada",
"valoram",
"agregas",
"bridões",
"copiões",
"emboras",
"malando",
"piquira",
"amoleci",
"inovara",
"punhete",
"simplão",
"toldava",
"caftans",
"momices",
"trochas",
"alforja",
"existas",
"inundei",
"maturas",
"morisco",
"toleima",
"valeiro",
"bubinga",
"calcara",
"exerces",
"gatices",
"pesquem",
"quadrar",
"sandará",
"encimam",
"prolata",
"fedemos",
"lastras",
"negares",
"pifaram",
"bojudas",
"esquiou",
"recueis",
"repeita",
"rostelo",
"resilir",
"danazol",
"eixinho",
"fatelas",
"laurina",
"recobri",
"repomos",
"reteria",
"rodeiem",
"velosos",
"bodegão",
"ciscava",
"concito",
"deflora",
"lenhada",
"lotadão",
"malvina",
"minimax",
"tissues",
"aventei",
"bandeia",
"chulipa",
"deprava",
"gastões",
"tamanca",
"taxavam",
"alfâmar",
"amissão",
"benziam",
"driblei",
"encruzo",
"fissure",
"grimpar",
"ituanos",
"nanando",
"picasse",
"âmbares",
"agracie",
"feirado",
"librado",
"mentias",
"paratis",
"talinha",
"tísicos",
"andrios",
"balindo",
"barrias",
"cambona",
"cobices",
"desarmo",
"erotiza",
"limagem",
"silvina",
"zelante",
"ateneus",
"cobrido",
"expecta",
"forfeit",
"gaitada",
"pangaio",
"puxarei",
"quinona",
"anuviar",
"clareei",
"imolava",
"lépidas",
"mexerão",
"áspides",
"acanhes",
"checara",
"eximida",
"lazeira",
"máficas",
"usanças",
"ístmica",
"agrifam",
"perlado",
"ajudais",
"arfagem",
"aspersa",
"cuspira",
"deifica",
"engobes",
"pategos",
"prélios",
"refilou",
"reputei",
"revolvo",
"segarão",
"ticando",
"broxará",
"faecium",
"trazemo",
"agnatos",
"apatite",
"ecoaria",
"fragada",
"gamboas",
"perfile",
"pimples",
"postear",
"roncado",
"sururus",
"volitar",
"arraiga",
"brecado",
"burlava",
"manente",
"toureia",
"aguçará",
"altense",
"apaiari",
"aziagos",
"comedão",
"diletas",
"envidam",
"emílias",
"escolte",
"estriar",
"milites",
"pisador",
"poetizo",
"rosilho",
"sabotei",
"siringa",
"suleste",
"tietada",
"vomitem",
"axonais",
"epífora",
"amandas",
"vierdes",
"atrapar",
"despoje",
"frâncoa",
"gradina",
"laçaram",
"odiarei",
"salivou",
"adriças",
"aljavas",
"barrida",
"berrada",
"povoara",
"bargado",
"bohêmia",
"chupara",
"marçano",
"mitigue",
"paranás",
"pierdes",
"siriris",
"suídeos",
"aminais",
"amputam",
"ducreyi",
"hartree",
"láparos",
"maltase",
"pontuem",
"armarão",
"emulava",
"enceram",
"enricou",
"guaiapó",
"indemne",
"joanico",
"liríope",
"nelores",
"ploidia",
"rinques",
"tomavas",
"arraigo",
"calaste",
"hilares",
"olharmo",
"paravas",
"planeza",
"redobro",
"atilada",
"religam",
"susques",
"larício",
"parango",
"pulsara",
"açuense",
"margina",
"natinha",
"ostíolo",
"vinhaço",
"adoçará",
"euglena",
"gajeiro",
"ornaram",
"ramento",
"ranário",
"bainita",
"balonas",
"bangués",
"crispou",
"endorse",
"filtres",
"tolhiam",
"trotava",
"datarem",
"fumegam",
"ratitas",
"síncopa",
"tungada",
"banzado",
"bilocas",
"deságuo",
"empeços",
"galismo",
"caguete",
"casemos",
"fugiras",
"mesurar",
"praxada",
"encesto",
"pinches",
"abduzem",
"agastar",
"caireis",
"funfava",
"raptava",
"tratais",
"aljôfar",
"butiral",
"cardaço",
"estrade",
"falisse",
"inalava",
"lindaço",
"recadão",
"rendemo",
"repisou",
"soantes",
"varejar",
"dirigis",
"domável",
"enconde",
"lincham",
"riolito",
"salemas",
"salteia",
"tamburi",
"zelarão",
"frieiro",
"intrões",
"pitinho",
"podrida",
"rajando",
"tacteia",
"bombeou",
"dezinho",
"impigem",
"leiloam",
"rebusco",
"aéticos",
"boiardo",
"cainita",
"coisica",
"equipem",
"exhibir",
"honores",
"incisas",
"ocelote",
"subleva",
"tabeira",
"vaiadas",
"condral",
"desuniu",
"doblete",
"gotosos",
"iâmbico",
"produze",
"recibes",
"tudense",
"volteis",
"ductais",
"furunfa",
"noticio",
"rabadas",
"amparei",
"bojudos",
"cozedor",
"esquiço",
"furaria",
"ombrada",
"provões",
"puderão",
"rondada",
"abécula",
"giestal",
"guriatã",
"olarila",
"perdeis",
"zelarei",
"baganas",
"lavaste",
"placide",
"urrados",
"adequei",
"caçasse",
"enfados",
"marrana",
"tatuava",
"auditam",
"coçaram",
"demolho",
"púnicos",
"troquel",
"araches",
"bacuris",
"escales",
"espetão",
"paralta",
"selavam",
"temeres",
"açafate",
"açucara",
"caieira",
"cuencas",
"cumbias",
"devoras",
"moloque",
"andongo",
"enxurro",
"predado",
"vidrões",
"adsorvê",
"cassata",
"catarem",
"enrolas",
"onerava",
"pisares",
"pérmico",
"vistaço",
"ateados",
"cabaçal",
"contino",
"doctora",
"geronto",
"refluem",
"relegue",
"tonaram",
"tômbolo",
"acabace",
"caldoso",
"clitelo",
"espadão",
"hajamos",
"hoplita",
"matroca",
"sibling",
"vespões",
"acercas",
"coveira",
"flutuas",
"profilo",
"riposta",
"silanos",
"braçado",
"magarça",
"relutem",
"abonará",
"feiuras",
"golpeei",
"touques",
"depurou",
"dragada",
"dragona",
"eclisse",
"habitai",
"regasse",
"superno",
"aporema",
"bautizo",
"declame",
"emirato",
"invocas",
"irrigue",
"maubere",
"profaça",
"agueiro",
"alqueve",
"funguei",
"gambina",
"honrais",
"nitinol",
"refação",
"clarete",
"escapos",
"exauria",
"gabamos",
"mamente",
"manieta",
"meditas",
"pipeiro",
"triodos",
"cossaca",
"escotos",
"imunize",
"mártire",
"ramelas",
"sodicas",
"tungado",
"ucharia",
"acorram",
"anhambi",
"bangala",
"defeque",
"depenou",
"ecóloga",
"granula",
"palpada",
"sacular",
"sanador",
"tererês",
"turicas",
"demovem",
"doariam",
"execrou",
"galhudo",
"gofrado",
"logarem",
"plumosa",
"punhada",
"enxotam",
"esticas",
"infligi",
"sextina",
"alvuras",
"asnices",
"baldada",
"cornaca",
"desfiem",
"enfadam",
"habites",
"revenir",
"albarrã",
"anexava",
"ejector",
"enfades",
"erigiam",
"estupas",
"mimarem",
"anarcas",
"ansiara",
"fervera",
"gourdes",
"pamaris",
"pingola",
"redijam",
"tocaras",
"umedeci",
"aferrou",
"agoiros",
"casador",
"chiavam",
"decepam",
"desovou",
"escócio",
"lêvedas",
"pelejei",
"sumiste",
"emética",
"exumada",
"maconde",
"povoléu",
"rateira",
"sinalar",
"sinimbu",
"coagule",
"decante",
"detemir",
"endecha",
"macinho",
"pinicam",
"párroco",
"triscou",
"társica",
"amarres",
"atetose",
"açaizal",
"empalha",
"mutuado",
"parísio",
"referis",
"síliqua",
"zúquete",
"amigaço",
"atuarei",
"baiense",
"dadoras",
"deparas",
"expludo",
"fresada",
"gretado",
"vaiarem",
"virotes",
"cultuem",
"estatuo",
"farsola",
"guaxima",
"inchara",
"jogavas",
"resinar",
"segures",
"agarota",
"arvorem",
"clipado",
"estafar",
"fisális",
"revirem",
"terpeno",
"velário",
"vetarem",
"agotado",
"alugues",
"cedamos",
"checape",
"espinel",
"lanhado",
"macegas",
"merdice",
"mijarem",
"obstava",
"raposão",
"reavido",
"tatinho",
"biotite",
"carcome",
"edetato",
"fluímos",
"ocluído",
"turvava",
"altivar",
"apeando",
"costeia",
"espigar",
"laminou",
"sudetas",
"venetas",
"voltais",
"abúlica",
"ancilar",
"helíaco",
"lipemia",
"opinada",
"picotou",
"rebocam",
"someter",
"extenua",
"frísias",
"gazânia",
"inflamo",
"janista",
"jucunda",
"mineras",
"abrasam",
"erradio",
"errares",
"manduri",
"sonegue",
"ungires",
"afeitar",
"briareu",
"caneira",
"chagara",
"doradas",
"lavemos",
"vedores",
"acenara",
"botarão",
"camilha",
"emborra",
"faroles",
"gestava",
"infinto",
"reusado",
"sacodes",
"ateavam",
"burrica",
"cariada",
"kunzita",
"podenga",
"raçador",
"ribomba",
"báratro",
"cabidão",
"cartuxa",
"cooptam",
"explota",
"grafava",
"sarasse",
"empesta",
"existis",
"fueiros",
"incorro",
"jadeíte",
"maleico",
"safaste",
"sainete",
"sedadas",
"trifosa",
"turnera",
"ulcerar",
"chaveia",
"ermitãs",
"hurrita",
"velaria",
"batique",
"cavasse",
"gonçala",
"maganão",
"prurigo",
"páteras",
"rechace",
"basista",
"blagues",
"difamem",
"feitora",
"filiava",
"instigo",
"amoldou",
"golpeio",
"grabato",
"inferia",
"aflores",
"anóxica",
"baselga",
"cadaste",
"cerúlea",
"corvino",
"mentual",
"motetes",
"opanijé",
"palavão",
"peitica",
"tucanes",
"adrasto",
"atáxica",
"desejum",
"esnobei",
"focarmo",
"linhões",
"oficiam",
"oscilei",
"trêfego",
"absolvi",
"enebria",
"fusores",
"germane",
"minador",
"percola",
"rolimãs",
"umectar",
"adoramo",
"alapado",
"suponde",
"vessada",
"calcina",
"defluem",
"manitas",
"poliano",
"propele",
"ruderal",
"urêmico",
"adornei",
"alógica",
"clavado",
"moinhas",
"panduro",
"pontino",
"quincas",
"raianos",
"árctica",
"bovídeo",
"cerceie",
"corindo",
"expores",
"quacres",
"rapinha",
"talhava",
"túmidos",
"acasale",
"aquária",
"crispar",
"dáfnias",
"exânime",
"lajeiro",
"peitaca",
"recubra",
"refugiu",
"cesuras",
"cársica",
"debruar",
"ponhamo",
"reatada",
"ressono",
"sedinha",
"cantais",
"cebuano",
"espetem",
"ferrito",
"honorem",
"lidinho",
"magalas",
"murchei",
"rasando",
"terabit",
"toureou",
"versejo",
"cintile",
"meneava",
"rugiram",
"suriano",
"triazol",
"acusais",
"calundu",
"cambaio",
"cepilho",
"clorita",
"dejejum",
"enxeram",
"pelames",
"plainos",
"pseudas",
"superna",
"abertão",
"alguidá",
"alteiam",
"ecúmeno",
"peanhas",
"remarem",
"socaria",
"tangelo",
"apossem",
"empecer",
"etólios",
"isentem",
"lágimas",
"macicez",
"matures",
"naturai",
"obraram",
"pagavas",
"paranos",
"poviléu",
"aceptas",
"alazões",
"elenque",
"físseis",
"goiases",
"mijaram",
"natives",
"negueis",
"piraria",
"sagrara",
"cartune",
"comenos",
"degluti",
"laceram",
"refreio",
"sonsice",
"cochile",
"colniza",
"englobo",
"enleiam",
"acerbos",
"barreia",
"chucros",
"empoado",
"feiinho",
"jactava",
"morares",
"nucelar",
"repuxam",
"tossido",
"afivela",
"florira",
"galeote",
"lamecha",
"livreis",
"pivotar",
"tesbita",
"xátrias",
"banisse",
"coirato",
"devotei",
"entorto",
"iscadas",
"rômbico",
"solvido",
"sumidor",
"bailada",
"curiais",
"encanou",
"enjaula",
"erigira",
"fumigar",
"ganamos",
"gemaria",
"guaiamu",
"painhos",
"segador",
"cianato",
"gravide",
"resumes",
"rosetar",
"adaucto",
"cumulam",
"exortei",
"fatigas",
"grelado",
"mabecos",
"marlins",
"revelim",
"sopremo",
"tougues",
"díptero",
"empenam",
"precavi",
"rapanui",
"ripando",
"xilenos",
"atinada",
"biforme",
"entrona",
"esmerei",
"morrote",
"parolim",
"quirida",
"teimado",
"bêntica",
"escotas",
"lentigo",
"militem",
"oréstia",
"pactado",
"reedite",
"stereos",
"abelino",
"andaina",
"avenina",
"amaçado",
"envieis",
"fíbulas",
"glúteas",
"imbaúba",
"morrias",
"orladas",
"paleiro",
"aningas",
"azotado",
"burrego",
"cuminho",
"maltosa",
"pasmoso",
"recolar",
"rotulei",
"secarei",
"aquesto",
"areadas",
"biciclo",
"epiceno",
"linquei",
"proseia",
"seteira",
"amansai",
"mudável",
"opossum",
"redimia",
"alistem",
"cirando",
"debuxos",
"mantado",
"pudenda",
"ribombo",
"zarelho",
"abrolho",
"ajustas",
"anhinga",
"argivos",
"doutrem",
"enéadas",
"grunhem",
"ironizo",
"maguari",
"torinho",
"tricama",
"corruto",
"idumeus",
"patrial",
"receeis",
"carocho",
"clarita",
"cocanha",
"farinas",
"lambera",
"lóquios",
"pecaras",
"pigmeia",
"prendar",
"anilada",
"assedie",
"madorna",
"parceia",
"propila",
"retuíte",
"afanosa",
"arqueio",
"balouça",
"clavina",
"coçador",
"embebem",
"eulógia",
"ganhamo",
"imporei",
"paiacus",
"usarias",
"alinear",
"ecótipo",
"estiada",
"explane",
"freches",
"marisma",
"matizou",
"morgues",
"quebres",
"rodeara",
"seguimo",
"usasses",
"vidados",
"cebiche",
"empalme",
"guarido",
"madraço",
"soçobro",
"tradado",
"abrirmo",
"aclasta",
"cometei",
"corneou",
"huertas",
"négligé",
"omental",
"plêione",
"refizer",
"abrunho",
"barbeie",
"cuproso",
"finadas",
"guaruba",
"juncada",
"luxosos",
"penavam",
"reterão",
"abricós",
"arrotei",
"berruga",
"curetas",
"decampo",
"estrese",
"falinha",
"mirasse",
"saduceu",
"alheiro",
"aluados",
"cominou",
"enlutar",
"feácios",
"ipomeia",
"olvidas",
"panamás",
"revelão",
"abeiram",
"bojarda",
"prensei",
"tabardo",
"usardes",
"anistas",
"cegarem",
"coligam",
"impingi",
"leãozão",
"mesiais",
"pipilar",
"senense",
"sibilam",
"tinidos",
"urdiram",
"capuava",
"carbona",
"embotou",
"gordões",
"maldiga",
"rosiano",
"valsava",
"adeante",
"cumanos",
"flanava",
"geladão",
"marroio",
"selasse",
"taparei",
"airosos",
"ajuizei",
"avultou",
"copilar",
"diádico",
"laureou",
"litinha",
"mimamos",
"muniram",
"noruego",
"picacho",
"vagaria",
"xaxados",
"batucam",
"bipando",
"bugrada",
"chamuça",
"digitai",
"grogues",
"novitas",
"ribaldo",
"usareis",
"vidamas",
"volteei",
"capetão",
"caponga",
"guerche",
"madraça",
"nefrose",
"perigas",
"pesebre",
"acólita",
"esfolio",
"gingada",
"musgosa",
"paizões",
"respiga",
"samnita",
"acendas",
"apeadas",
"armares",
"arrosta",
"brabosa",
"cavaste",
"cavação",
"cozeras",
"enovela",
"meminas",
"orçamos",
"rabicós",
"rabudos",
"índicos",
"afluxos",
"estirei",
"fragosa",
"fruídos",
"fusando",
"levadia",
"ranilha",
"sanseis",
"aturdem",
"cabação",
"luxinho",
"oxigene",
"recepto",
"reconte",
"trotada",
"unhacas",
"adulada",
"cogitem",
"lixaste",
"motejos",
"obligar",
"padreca",
"sumulou",
"buganda",
"chanças",
"coalhou",
"gafeira",
"lutécio",
"minarem",
"pantana",
"pepecas",
"solidar",
"afiliei",
"alandra",
"derriça",
"erguemo",
"foleado",
"geronte",
"mimalho",
"penados",
"pousios",
"retores",
"stretto",
"tigrões",
"advirem",
"chegamo",
"enlacem",
"entonar",
"estorna",
"mirceno",
"sintamo",
"abusões",
"alteada",
"altismo",
"apertes",
"apodero",
"baldões",
"batucou",
"bitates",
"cafofos",
"coluria",
"freasse",
"melícia",
"sorviam",
"vazaria",
"aranzel",
"barcena",
"chambão",
"convola",
"garrota",
"gloriem",
"pranteá",
"tapense",
"varição",
"vísseis",
"adiavam",
"alçavam",
"anchura",
"caxixis",
"escodem",
"evaporo",
"lesarem",
"maçaria",
"rejunta",
"topiara",
"ápteros",
"felupes",
"functor",
"látegos",
"pilhava",
"alvação",
"amontar",
"apanhai",
"bácoros",
"crátons",
"garajão",
"imantam",
"canatos",
"coterie",
"lapidem",
"ligaras",
"motumbá",
"rêmiges",
"trépido",
"carroço",
"caístes",
"coreias",
"estrele",
"parecei",
"veneras",
"armelim",
"biritar",
"cacifes",
"cagaria",
"criaras",
"decalca",
"molezas",
"pérvias",
"roleira",
"sovados",
"aprumam",
"brilhai",
"cabulou",
"cinérea",
"endente",
"iludais",
"ondeada",
"quietar",
"sorongo",
"suprimo",
"aldeano",
"arraiar",
"avoando",
"demolem",
"deserts",
"fórcipe",
"movedor",
"papável",
"tenteis",
"babosos",
"botaréu",
"duplico",
"mixteca",
"mulemba",
"partias",
"recardo",
"animosa",
"arcavam",
"bailéus",
"diamina",
"mucambo",
"serrava",
"socavam",
"anóxico",
"demolia",
"entreti",
"escudou",
"leveler",
"marumbi",
"mordeis",
"ocupais",
"puxirum",
"ramalde",
"sitiava",
"abocado",
"alarmem",
"piritas",
"rútilos",
"vaporar",
"verdoso",
"briques",
"cotexto",
"fagaras",
"gagueje",
"inervar",
"metanal",
"plosiva",
"similia",
"zamorim",
"aclamem",
"amandes",
"anômica",
"assumas",
"carenas",
"carioso",
"dugongo",
"lutuoso",
"quiasmo",
"untavam",
"anábase",
"cambone",
"decaiam",
"iterado",
"puderas",
"rutinas",
"sanaria",
"caniçal",
"feriada",
"fibrada",
"obnóxio",
"resenhe",
"salguei",
"verbosa",
"aforrar",
"apatias",
"birrada",
"brações",
"báculos",
"caiovás",
"carolos",
"crimina",
"forteza",
"gamadão",
"moirama",
"octante",
"píticos",
"removas",
"tacteio",
"tensiva",
"tetudos",
"afundas",
"baniria",
"bolarem",
"cagasse",
"cianita",
"degrado",
"lúpicos",
"mimeses",
"oficiai",
"piqueta",
"breteau",
"capação",
"ironize",
"meteras",
"samoana",
"verilha",
"exitava",
"rimavam",
"sitando",
"arqueol",
"bilinga",
"chorros",
"eclipso",
"encampe",
"farejei",
"gozares",
"joqueta",
"osmolar",
"palavre",
"cingias",
"coalhos",
"cornice",
"demovia",
"esquiei",
"jaçanãs",
"murales",
"pulinha",
"rêmoras",
"arruído",
"cepacia",
"incluis",
"insecta",
"muvucas",
"patelas",
"recatos",
"sonhais",
"coqueta",
"croques",
"facécia",
"filante",
"moloide",
"pindela",
"zelasse",
"faiscou",
"fitasse",
"fosfeto",
"guiares",
"jumelos",
"reverto",
"rissole",
"setamos",
"batanga",
"cársico",
"foquemo",
"homagem",
"olvidei",
"saciais",
"zapeava",
"alfonsi",
"belarte",
"degolei",
"empoada",
"fuxique",
"mexeste",
"peidado",
"acoimar",
"aracuri",
"bravais",
"flexiva",
"guapeca",
"incitei",
"inflete",
"lustrou",
"rosando",
"sêmolas",
"vistamo",
"assesta",
"crassas",
"guarras",
"lavínio",
"medeira",
"mutuais",
"trombil",
"velasse",
"anteder",
"caiense",
"cloacae",
"frustei",
"gadinho",
"garrana",
"intatos",
"maranho",
"marrões",
"retante",
"vaiamos",
"alimpar",
"angolar",
"arqueie",
"cabalar",
"deligue",
"esculca",
"lacetes",
"rifados",
"virales",
"vivíeis",
"afiliam",
"bourrée",
"carrete",
"cerzida",
"exempro",
"maiense",
"mântica",
"orchata",
"peteado",
"politia",
"vigores",
"clipada",
"eirados",
"eirinha",
"empilho",
"gelavam",
"lagoano",
"lobares",
"petrosa",
"remende",
"abancou",
"bodetes",
"laniado",
"paquife",
"perjura",
"délfico",
"espione",
"gromada",
"quicada",
"saintes",
"tralhar",
"acasuso",
"calinho",
"enervei",
"estropo",
"laureta",
"mijados",
"pudente",
"quilmes",
"remonto",
"brocham",
"fachado",
"farófia",
"folguem",
"guieiro",
"gutação",
"ordenhe",
"recoste",
"saturei",
"sulfona",
"admitis",
"dicação",
"lacrime",
"rabanos",
"rimasse",
"apegamo",
"atassem",
"azulona",
"deiteis",
"fornear",
"rogante",
"tinamus",
"enerves",
"mochica",
"varinos",
"denegam",
"medires",
"púricas",
"ufanava",
"aviltou",
"badoque",
"bambelô",
"mereças",
"michela",
"mucinas",
"trintei",
"cremona",
"elitiza",
"gnômico",
"lancemo",
"roxidão",
"tingira",
"zelados",
"almadia",
"exercei",
"falseio",
"lidadas",
"manecas",
"popança",
"porfiam",
"sanasse",
"trancei",
"aguilha",
"aquilão",
"bexigão",
"debutam",
"fofoque",
"macucos",
"aivecas",
"anciana",
"erector",
"gamaram",
"lapacho",
"pasmava",
"práxica",
"acervas",
"assunte",
"balizem",
"decoras",
"delineá",
"descria",
"dignara",
"dileção",
"duelava",
"gálicas",
"hesitas",
"porfiai",
"repisam",
"riscara",
"sapados",
"arnelas",
"balúchi",
"brunida",
"cagaçal",
"detrair",
"peitava",
"poceiro",
"resalto",
"seninha",
"socadão",
"umedeço",
"bonzões",
"erários",
"federai",
"fâneros",
"galgara",
"mordoma",
"podrido",
"quincho",
"quinzes",
"safaria",
"safavam",
"descaem",
"inações",
"machico",
"ofeguei",
"roçagar",
"sirvamo",
"telarca",
"ápteras",
"balalão",
"empanca",
"emprese",
"enfezou",
"pisanos",
"reentre",
"refogem",
"adaptas",
"afliges",
"alteado",
"chiclãs",
"copulou",
"entedio",
"faceado",
"preteri",
"tardeis",
"ascites",
"ataviar",
"azedava",
"catatua",
"epocais",
"fixarei",
"hirundo",
"irmanou",
"minhoco",
"natures",
"rudezas",
"taletos",
"tresler",
"vaciles",
"adiável",
"aquilia",
"bifilar",
"butacas",
"elisivo",
"espetas",
"lindaça",
"agrafia",
"angarie",
"belones",
"domaram",
"feridão",
"fumarei",
"invejes",
"lincada",
"predigo",
"saravam",
"sismada",
"absorbe",
"almejos",
"argiope",
"caviúna",
"contano",
"esmerem",
"feiinha",
"gateada",
"métopas",
"ninados",
"pausava",
"pertual",
"regarem",
"secrete",
"seromas",
"superes",
"amostro",
"bronhas",
"cozemos",
"esclero",
"preitos",
"saaraui",
"trocate",
"viciara",
"virente",
"alheiam",
"dorense",
"feijoal",
"jurarei",
"espraio",
"fincara",
"guaraju",
"inosina",
"pínulas",
"suburra",
"doparam",
"esganou",
"fintado",
"mogango",
"puderei",
"sugação",
"sétupla",
"ufóloga",
"varamos",
"acádico",
"alvites",
"avoengo",
"gozaras",
"mercada",
"poetize",
"samorim",
"tramaga",
"uridina",
"acídulo",
"aragana",
"assamês",
"bezinho",
"cacheta",
"colegar",
"plicada",
"pubarca",
"venerei",
"zaralho",
"almança",
"bambual",
"camitas",
"cretone",
"fijiana",
"florado",
"objecta",
"ocupemo",
"pegadão",
"trefila",
"capadas",
"explano",
"fisgava",
"férmion",
"imbuias",
"marejou",
"ondadas",
"pelágia",
"placado",
"preclui",
"teorizo",
"venados",
"agrovia",
"cabidas",
"curiúva",
"desatas",
"esbocem",
"esculpo",
"exsudar",
"favarão",
"fundimo",
"mnêmico",
"permute",
"predada",
"prenota",
"sequins",
"supurar",
"cagarra",
"caçarão",
"dermite",
"friúmes",
"afirmes",
"atalhei",
"avivava",
"carnear",
"flotado",
"mijador",
"moravas",
"morrião",
"tarasca",
"tarjado",
"afrouxo",
"atrofie",
"biliosa",
"catiras",
"descuro",
"engodam",
"gamadas",
"mitrais",
"reabria",
"reedito",
"remolho",
"galaria",
"gelarem",
"glocais",
"liseirã",
"macuxis",
"pirizal",
"refogou",
"sultani",
"aleijei",
"avoadas",
"barregã",
"cotamos",
"encurto",
"exortem",
"rumasse",
"aferira",
"ajuntas",
"enviesa",
"exilara",
"gépidas",
"luchado",
"papouço",
"petroso",
"radiava",
"taxamos",
"telária",
"ungisse",
"zerarem",
"bambeou",
"bolhudo",
"cotizam",
"escatol",
"estarda",
"estrais",
"hapteno",
"lotarão",
"nadadas",
"obsesso",
"tiritam",
"digrama",
"estolão",
"fregata",
"zanzava",
"arderia",
"arrouba",
"atecnia",
"biblios",
"caparro",
"chorrei",
"lanosos",
"perchas",
"aspergi",
"cegonho",
"desdigo",
"edáfico",
"gerisse",
"guascas",
"górgone",
"incubou",
"lambona",
"salobre",
"vazamos",
"véspero",
"cogitas",
"coopero",
"cunhava",
"extrais",
"fumaçar",
"leninho",
"lobriga",
"lápitas",
"malogra",
"reagida",
"removes",
"sedente",
"trífida",
"veríeis",
"vianesa",
"adelfas",
"aninais",
"augurou",
"calceta",
"marrado",
"reganha",
"rezares",
"tamuatá",
"tufados",
"áuricas",
"arrazoa",
"cúltico",
"engraço",
"fixaste",
"higroma",
"infusos",
"lagoana",
"amoleço",
"demudar",
"enfezar",
"juremos",
"louraço",
"lídimas",
"retador",
"tacarem",
"tersina",
"tresses",
"tricoma",
"violato",
"vistada",
"bajudas",
"baloche",
"crípton",
"dardeja",
"definas",
"imputem",
"intuíam",
"limaram",
"movível",
"piscosa",
"pródico",
"rachara",
"agênere",
"cuecões",
"estolar",
"houvido",
"repesca",
"truncou",
"burilou",
"carteia",
"coaduno",
"gozaste",
"indexem",
"inferna",
"potocas",
"trocais",
"barrido",
"clavada",
"expende",
"gramava",
"inatual",
"mitemas",
"moneras",
"rogarem",
"roçasse",
"suborne",
"unárias",
"versais",
"bolaria",
"boleima",
"cambias",
"fintava",
"orbitem",
"capisco",
"desolar",
"dioneia",
"espicho",
"lutaras",
"puraquê",
"sofrear",
"verrina",
"bisando",
"blocada",
"manchão",
"pedente",
"ralavam",
"reveres",
"bolinam",
"egungum",
"entalam",
"estrara",
"imploda",
"laneiro",
"larapia",
"maaiana",
"mimalha",
"planice",
"rabiosa",
"recepta",
"rifadas",
"sismado",
"socavar",
"zagaias",
"cagaços",
"carteio",
"clareio",
"coartar",
"coçados",
"geminar",
"guapeva",
"mocotós",
"ocultes",
"relesse",
"camuflo",
"casquei",
"empoçar",
"entroca",
"gastros",
"mofaram",
"pecarão",
"revendi",
"sofrias",
"tosando",
"zebrina",
"caticós",
"guilhas",
"miliano",
"propiás",
"reusada",
"rimarem",
"separes",
"ameadas",
"coibira",
"colarei",
"colegue",
"contusa",
"eriçava",
"esmurre",
"ladinas",
"marujas",
"míscaro",
"paremia",
"socarem",
"almagre",
"apelide",
"imentos",
"imergia",
"jatrofa",
"mapeava",
"sonando",
"tinguis",
"toroide",
"aiquidô",
"botoque",
"brisada",
"calunie",
"drapeja",
"eduques",
"estilar",
"fofocou",
"jequice",
"malfada",
"pastura",
"zanzara",
"bugiada",
"dengues",
"extasio",
"perfuso",
"rosales",
"terrore",
"untamos",
"agrimar",
"ardísia",
"axolote",
"britano",
"cegaria",
"dinasta",
"espotes",
"evacuei",
"exalara",
"fujonas",
"manando",
"morocha",
"respigo",
"rosense",
"trativo",
"vibrais",
"amofine",
"aproado",
"aragano",
"arrulha",
"carmins",
"chamote",
"deporia",
"hiroito",
"pedinho",
"raparam",
"recasar",
"abafara",
"abieiro",
"alunato",
"animato",
"anuviou",
"apalpão",
"artilha",
"aurorar",
"biricar",
"desosse",
"devaste",
"enfunar",
"engasta",
"expiara",
"guajiro",
"jaburus",
"lineana",
"loteiam",
"mixamos",
"ruinhas",
"virions",
"acudida",
"amarina",
"carpido",
"dédalos",
"espacia",
"maquila",
"multava",
"orexina",
"pechada",
"pegunte",
"vedaria",
"vedoria",
"adiemos",
"antolha",
"barrena",
"bulboso",
"helenas",
"melaços",
"pervade",
"picrato",
"plúrimo",
"polders",
"riluzol",
"rodeira",
"vedismo",
"ataviou",
"barrara",
"brejoso",
"emposse",
"erastes",
"implodi",
"língula",
"marrafa",
"potério",
"vezinho",
"afiaram",
"alcaçus",
"baguala",
"catopês",
"cipoais",
"cuchita",
"curicas",
"esfolem",
"estafas",
"fundeia",
"ipueira",
"vilória",
"amental",
"besugos",
"dominga",
"guiaras",
"opilião",
"quedada",
"raspara",
"saparia",
"siltosa",
"tintado",
"apernas",
"arrasas",
"boiaram",
"capitou",
"daneses",
"divíduo",
"encubro",
"enjeito",
"fumaste",
"ligavas",
"malfere",
"piramba",
"placita",
"plicado",
"versura",
"decanto",
"enviais",
"espumem",
"lonados",
"mijonas",
"rabiças",
"segando",
"tarsais",
"vilelas",
"acabeis",
"arcazes",
"buxinha",
"cabulei",
"encesta",
"fodeste",
"pançuda",
"soladas",
"tintada",
"bacongo",
"crupiês",
"espraie",
"garraio",
"luzirão",
"metermo",
"taparia",
"tapices",
"urucuri",
"absterá",
"babante",
"chapeou",
"cresses",
"ecdises",
"mijamos",
"oxítono",
"piçarro",
"pujadas",
"sastras",
"soalhas",
"tirrena",
"alombar",
"ateadas",
"avoador",
"bobonas",
"caduque",
"fuçamos",
"ilarias",
"jardina",
"laceado",
"nicenos",
"pachuco",
"poisava",
"saudais",
"autuara",
"cóptico",
"guaruça",
"lutuosa",
"magnete",
"mechamo",
"nefário",
"ouriçar",
"plissar",
"premies",
"repuxou",
"sazonar",
"travoso",
"acéquia",
"bohêmio",
"cifoses",
"cocotes",
"coçamos",
"fendera",
"filhada",
"galarim",
"garções",
"gloriai",
"infinta",
"leonita",
"revocar",
"ricaças",
"targuns",
"tarolas",
"bigorne",
"encerei",
"indagas",
"numular",
"regalas",
"amorena",
"barateá",
"espruce",
"locaram",
"majorem",
"nisense",
"ondeiam",
"tainada",
"tércios",
"verista",
"aldeães",
"arjonas",
"belbute",
"báquica",
"chiitas",
"esmolam",
"maitaca",
"minuete",
"quiosco",
"redomão",
"releram",
"reputem",
"titicas",
"tosquie",
"aludira",
"bígamos",
"chiador",
"espesse",
"fitemos",
"linchou",
"prensam",
"purunga",
"pélagos",
"retalhe",
"adamado",
"burriés",
"grasses",
"lambros",
"ripanço",
"aiúbida",
"babaçus",
"baforar",
"divergi",
"ecocasa",
"engesse",
"gitanas",
"grampeá",
"orlados",
"pisavas",
"asseios",
"cambiou",
"cineral",
"dioxano",
"dotamos",
"epídoto",
"expanso",
"gofrada",
"itaúnas",
"uberada",
"vesânia",
"epônima",
"gomeira",
"macetas",
"narvais",
"regarei",
"tataíra",
"tróleis",
"aldoses",
"bronzeá",
"chancho",
"defasar",
"grumosa",
"maçudos",
"poluirá",
"pêntodo",
"semilha",
"sopesou",
"vendola",
"ílhavos",
"acuaram",
"aguador",
"alçares",
"argolão",
"atiramo",
"biribas",
"chavoso",
"cloquei",
"encorpe",
"enodoar",
"esmeros",
"hipogeu",
"intatas",
"selarão",
"alourei",
"apalpem",
"babucha",
"badalas",
"caracal",
"cuitado",
"cultora",
"empanou",
"insulso",
"mixinas",
"nubiano",
"parança",
"raiação",
"refiras",
"soengas",
"toneira",
"tritons",
"almoças",
"emposto",
"parvenu",
"puxirão",
"recruto",
"sararem",
"tintino",
"álacres",
"adipose",
"amotina",
"bobando",
"curares",
"inquice",
"lustram",
"micuins",
"pompeio",
"caleiro",
"desunem",
"enturme",
"gingava",
"girofle",
"levitei",
"mancado",
"matarmo",
"parante",
"sifonar",
"tartana",
"trabuca",
"abrevio",
"descame",
"estoire",
"foguear",
"lignite",
"mangona",
"marchai",
"oclusor",
"pecarei",
"rebeles",
"tabarro",
"tascava",
"trubufu",
"aclasse",
"fadigam",
"fumagem",
"harmala",
"mentada",
"quiroga",
"abstemo",
"capeado",
"descreu",
"estrabo",
"fibrila",
"lajetas",
"matizei",
"siderar",
"solinas",
"alógena",
"apiedai",
"apresou",
"brunhes",
"crisina",
"deliria",
"desunha",
"exerção",
"fazíeis",
"fovismo",
"sambuca",
"soprosa",
"veronal",
"alofone",
"alporão",
"angosto",
"baguais",
"calhara",
"castrem",
"desaine",
"harpejo",
"lucense",
"morávio",
"ouvisto",
"piadoso",
"reclino",
"ruinzão",
"sanguis",
"batizas",
"bedengó",
"bonecar",
"báquico",
"chispar",
"enfeiou",
"inundas",
"nutrice",
"trotear",
"veranis",
"vidraço",
"abismou",
"acerada",
"aliarão",
"dialelo",
"dêmodex",
"enlatam",
"fáculas",
"lucides",
"ludista",
"maruins",
"mumunha",
"parolar",
"pirante",
"resteva",
"tambura",
"teucros",
"afeiçoe",
"atreita",
"cassita",
"coravam",
"hadrões",
"jazerão",
"luliana",
"pineais",
"tiguera",
"assanho",
"cevador",
"fatiava",
"geremos",
"leucoma",
"lixasse",
"lutados",
"melania",
"mimavam",
"predela",
"sapezal",
"sufocas",
"tuscana",
"volumem",
"alcouce",
"amaduro",
"cozerão",
"entesar",
"lenhado",
"ponchas",
"tradada",
"afaguem",
"arriava",
"carvona",
"clânica",
"erveira",
"espatas",
"incubem",
"latagão",
"pontais",
"rumarão",
"solfeja",
"trances",
"adentar",
"bicorne",
"capando",
"capiaus",
"cassaco",
"figurei",
"loendro",
"malezas",
"maçaras",
"pandava",
"roçamos",
"serosos",
"sármata",
"xocante",
"acribia",
"algidez",
"alçasse",
"aplaine",
"aviando",
"azêmola",
"calejou",
"capeias",
"clarito",
"crimino",
"egoicos",
"gaviais",
"invetou",
"raiasse",
"sedando",
"ventado",
"zanquim",
"afofada",
"agitato",
"amenizo",
"blindes",
"janismo",
"monoide",
"ruminou",
"temeste",
"tsongas",
"coraria",
"cunhatã",
"desfoco",
"dindins",
"fuzilem",
"hérmias",
"lapidei",
"pareças",
"punirão",
"quadram",
"ralhado",
"raptara",
"acoluna",
"caçoila",
"deletes",
"edemias",
"emperre",
"estagne",
"exubera",
"genebro",
"intuirá",
"manceba",
"maximar",
"orelana",
"outorgo",
"refazes",
"amorado",
"aricuri",
"arqueus",
"cabelas",
"canopos",
"caraíta",
"chulear",
"desusos",
"drósera",
"evadida",
"mariada",
"prantar",
"taxiava",
"aguagem",
"atulham",
"colarão",
"dizente",
"frontei",
"minoana",
"murraça",
"opiácea",
"selares",
"aprazar",
"chateza",
"devesas",
"entedie",
"excedas",
"intubar",
"jinguba",
"loiraço",
"puntada",
"releria",
"retinem",
"valedor",
"albumes",
"amparas",
"asseita",
"bifeira",
"centras",
"curteza",
"escopas",
"incorri",
"lápidas",
"macieis",
"mazombo",
"queijas",
"sorvera",
"atinham",
"avogado",
"colmado",
"crestar",
"denegue",
"finando",
"imigrei",
"includo",
"lêntico",
"nadarão",
"rarefaz",
"sinuelo",
"sublimo",
"afanada",
"andrajo",
"avinhar",
"bajadas",
"bífidos",
"enfadou",
"fruídas",
"honeste",
"iodação",
"remissa",
"tacaria",
"tarasco",
"terremo",
"velense",
"zumbaia",
"adesmia",
"adulava",
"atinjas",
"chieira",
"cortume",
"enferme",
"enolato",
"guianês",
"inquira",
"javismo",
"rogueis",
"tabulou",
"tribofe",
"volviam",
"vozeria",
"aplanai",
"armenta",
"atomiza",
"bandado",
"banques",
"bradara",
"chispes",
"deicida",
"encheis",
"manarão",
"mourama",
"quinado",
"tutanos",
"afonsim",
"alheava",
"chefiei",
"cutiano",
"danaram",
"gotejou",
"pelejai",
"quássia",
"recozer",
"vinhote",
"vólvulo",
"basismo",
"boldina",
"clórico",
"escupir",
"fraseio",
"galapos",
"lignito",
"pingues",
"siltito",
"umbroso",
"xonados",
"abelhar",
"baixões",
"cóptica",
"edomeus",
"enfaixe",
"morreis",
"preação",
"regaçar",
"sangras",
"subloca",
"verstas",
"vinhado",
"ascídia",
"avalize",
"carumbé",
"ensebar",
"filoide",
"lanudos",
"marches",
"marocas",
"patinga",
"possues",
"quartão",
"achista",
"apeteço",
"assovie",
"assumis",
"coibiam",
"despego",
"entreva",
"falerno",
"logaram",
"pelotes",
"safasse",
"tentear",
"turfoso",
"violete",
"afinara",
"botemos",
"chouras",
"citável",
"enviamo",
"foreira",
"gravite",
"hussita",
"jejuara",
"maldize",
"medrava",
"nublava",
"objetei",
"serenam",
"tuitava",
"turbeis",
"ônticas",
"abrogar",
"acintes",
"aturdia",
"escrivo",
"iroquês",
"macaréu",
"rebatam",
"reocupa",
"tarumás",
"tateado",
"xavecou",
"abicada",
"afanoso",
"aspeito",
"cônegas",
"galanto",
"lidares",
"nítrica",
"pérsica",
"rateiam",
"salgava",
"sararão",
"tríduos",
"tsarina",
"zeólito",
"apinhar",
"aptiano",
"chusmas",
"contais",
"encenem",
"exsudam",
"pistolé",
"pomelos",
"puxemos",
"taieira",
"uretero",
"alabado",
"aselhas",
"aveluda",
"crótons",
"dorzita",
"embroma",
"enrabei",
"enrijar",
"inumada",
"linhita",
"micagem",
"oleiras",
"papitas",
"pisemos",
"polisse",
"talibés",
"tropear",
"veronês",
"apameia",
"avocada",
"balando",
"bambuco",
"brontes",
"canfeno",
"cullens",
"decidis",
"homizio",
"ousares",
"presada",
"raptora",
"berrões",
"crispam",
"engreno",
"grelhou",
"impedes",
"lactado",
"legamos",
"mangala",
"quintar",
"terriço",
"tribuir",
"ambulas",
"anidros",
"atendar",
"capulho",
"caulins",
"dedicai",
"gelidez",
"gemedor",
"guinado",
"inebrie",
"ootecas",
"retorça",
"xerente",
"canjerê",
"descaço",
"egestão",
"molejos",
"normado",
"pagaias",
"replete",
"sinalou",
"sprinte",
"tonteou",
"agranda",
"agremia",
"amuadas",
"arfavam",
"barrico",
"clâmide",
"enguliu",
"gramosa",
"mutilem",
"orógeno",
"tomento",
"tucujus",
"vivaços",
"acicata",
"aprumei",
"carurus",
"debocho",
"dejeção",
"escurra",
"gupiara",
"górdios",
"lisados",
"reexibe",
"refaria",
"uracilo",
"bogarim",
"cântigo",
"donadio",
"egoicas",
"esmolol",
"gonzada",
"minense",
"mátrias",
"prevale",
"relados",
"rútulos",
"taiobas",
"vendemo",
"úgricas",
"abrasai",
"amolgar",
"biparte",
"cairota",
"foragiu",
"frearem",
"gradeza",
"metidão",
"palista",
"pilotes",
"ráfides",
"texiano",
"acratas",
"açaflor",
"borneol",
"bussolo",
"caselas",
"devasto",
"duplado",
"exilava",
"goiense",
"procolo",
"tacamos",
"tardada",
"timboré",
"toretes",
"tudesco",
"clienta",
"fanadas",
"labioso",
"macabeu",
"paduana",
"paiaguá",
"regrave",
"retunda",
"situamo",
"amniota",
"ampélio",
"corroam",
"empluma",
"encucou",
"impetre",
"tajurás",
"tidinho",
"vasquim",
"aflorei",
"afoitar",
"rasteio",
"sustive",
"telares",
"temefós",
"túbaros",
"adornes",
"baraços",
"calquei",
"colúvio",
"homessa",
"hórrido",
"jeremia",
"libambo",
"ligases",
"polimos",
"rasurou",
"roselas",
"teatina",
"tirense",
"zarpara",
"adonais",
"afegani",
"bradada",
"ciclose",
"coaltar",
"cozidão",
"especta",
"forânea",
"graúnas",
"jerivás",
"lacrava",
"medeiro",
"paparam",
"pastado",
"ucraíno",
"ventilo",
"amílico",
"estrepo",
"goraram",
"negrite",
"nimbado",
"protelo",
"remeiro",
"salsada",
"trácias",
"usurpem",
"zonaras",
"amaciei",
"bicaram",
"cajaíba",
"cambaus",
"cortijo",
"desmina",
"dissipo",
"jamunda",
"madurão",
"márcias",
"revesse",
"romanço",
"ambages",
"calmoso",
"calosos",
"dirimem",
"esporta",
"excitas",
"howlita",
"indulta",
"infetou",
"madamas",
"mandeis",
"motejar",
"petreca",
"retiniu",
"torênia",
"vedasse",
"alambor",
"bigotes",
"depenam",
"erratum",
"estrila",
"flavona",
"freamos",
"fugaces",
"igualei",
"mamaste",
"passemo",
"pelouse",
"aboleta",
"anúbias",
"areados",
"calabar",
"comoves",
"esmagas",
"gizadas",
"gizados",
"insulas",
"malásio",
"raposos",
"receara",
"sulcava",
"teimara",
"veniaga",
"acarear",
"agerato",
"amanada",
"derriço",
"emblica",
"esperte",
"fruitos",
"metamos",
"pancado",
"raspões",
"subguia",
"valerei",
"varelas",
"acresci",
"comorar",
"cresóis",
"erveiro",
"estolho",
"ferrule",
"lotasse",
"nuidade",
"pavônia",
"quebrai",
"titônio",
"vinagra",
"xeretei",
"zeolite",
"adjutor",
"banição",
"cabrona",
"cessais",
"focares",
"folgazã",
"gadelho",
"hostais",
"irisina",
"papança",
"pepinão",
"premiem",
"torneou",
"triques",
"aboliam",
"alético",
"arestal",
"broeiro",
"bífidas",
"caiçuma",
"disfere",
"flâmine",
"guazuma",
"infirme",
"japiaçu",
"masquei",
"onerara",
"purungo",
"refinei",
"adictas",
"afegane",
"afofado",
"afrenta",
"cordite",
"crianço",
"crivosa",
"fruímos",
"hetaira",
"liberas",
"marnoto",
"meioses",
"omentos",
"orgíaca",
"pilando",
"quinada",
"reacesa",
"seduzes",
"tititis",
"agistes",
"atascar",
"baixeis",
"desavim",
"doidura",
"furaste",
"himenal",
"lugaras",
"mijaria",
"patesca",
"revessa",
"órficas",
"assaria",
"bicanca",
"cansamo",
"carpais",
"debutei",
"esbatia",
"fuçaram",
"minoram",
"sibilei",
"torinha",
"alivias",
"baralhe",
"carepas",
"detarde",
"didelfo",
"hipanto",
"lanhada",
"nabateu",
"nicromo",
"pêssega",
"queimes",
"quérria",
"risques",
"sexadas",
"tangiam",
"zunidor",
"atridas",
"búdicos",
"campeou",
"dispomo",
"empedre",
"hiberne",
"lançais",
"mediste",
"mestral",
"moureja",
"repulso",
"sínicos",
"talhara",
"anarcos",
"ateneia",
"estador",
"finória",
"lestras",
"liturgo",
"mazorca",
"mijavam",
"olorosa",
"rareava",
"turbino",
"valares",
"ínferos",
"acerado",
"agoniam",
"arregla",
"asexual",
"assapar",
"cotaram",
"ganzado",
"guabiru",
"rapagem",
"remisse",
"reverei",
"solemos",
"tubiana",
"baqueia",
"brônzeo",
"civetas",
"debicam",
"descose",
"disques",
"gralhar",
"iterada",
"leriado",
"magriço",
"mangará",
"optados",
"recurar",
"terrais",
"zambeta",
"zeladas",
"anichar",
"contros",
"glaucas",
"levedou",
"penhore",
"podrões",
"titilar",
"acaiaba",
"agógica",
"enjoara",
"evolveu",
"extasie",
"filados",
"gerares",
"massame",
"miócito",
"precavê",
"ricotas",
"sariana",
"sofrais",
"acabano",
"arrojei",
"avistem",
"burrona",
"colpite",
"escaria",
"mnêmica",
"prócris",
"zelaria",
"abaluaê",
"ageusia",
"artinha",
"arábios",
"babiana",
"catotas",
"dadinha",
"excusar",
"fixável",
"mandamo",
"manejem",
"tribula",
"vedemos",
"acocora",
"anexite",
"açulado",
"besunto",
"cássias",
"factore",
"foliosa",
"lingues",
"miremos",
"siriano",
"afótica",
"agilizo",
"amorada",
"asseito",
"bodiões",
"centrai",
"corenta",
"driblem",
"epóxica",
"lebréis",
"madrugo",
"massais",
"morança",
"plânula",
"prófugo",
"abeirei",
"alcobas",
"enteais",
"gemerão",
"mixaram",
"monília",
"pigídio",
"redimam",
"refugir",
"refunde",
"zerasse",
"absente",
"alambra",
"bonense",
"bujarda",
"cobrias",
"forceja",
"morfose",
"occisão",
"oréades",
"piravam",
"protusa",
"rodeias",
"tabelam",
"triando",
"zarpava",
"afiavam",
"airense",
"arações",
"calavas",
"enfarda",
"fúcsias",
"nominam",
"pensamo",
"rugidor",
"tumbado",
"vilosos",
"zanatas",
"zangara",
"assoava",
"caranho",
"cataras",
"lenição",
"moentes",
"pactuem",
"rinchar",
"sentemo",
"tucanou",
"azótico",
"badogue",
"borrara",
"ecotaxa",
"esgaçar",
"fanares",
"fruível",
"jazemos",
"liações",
"mechado",
"pararas",
"poianas",
"roupeta",
"adiarão",
"cominam",
"condoem",
"encarre",
"flector",
"iniciai",
"jamegão",
"octanos",
"sumamos",
"alltype",
"arruíno",
"avistas",
"includa",
"láticos",
"mapeiem",
"mentiro",
"pespega",
"pontano",
"urdidor",
"vacínia",
"abotoei",
"acracia",
"adhesão",
"aprecei",
"cissura",
"côngruo",
"esparze",
"expiras",
"mamamos",
"melaram",
"mofarem",
"presava",
"rabugem",
"revives",
"sardões",
"semeais",
"siltoso",
"trovada",
"adeiras",
"aimbiré",
"empalou",
"ganhais",
"lexívia",
"pepinal",
"refogam",
"signado",
"aforros",
"ajuízem",
"bilimbi",
"bolbosa",
"burilam",
"cambucu",
"cinesia",
"cotejou",
"desamei",
"edílico",
"fedayin",
"federem",
"fumaces",
"intrigo",
"livores",
"rasadas",
"ratadas",
"socolor",
"vozeiro",
"xibungo",
"achacam",
"alberge",
"alocava",
"amandou",
"anojado",
"cachões",
"caldear",
"dineína",
"gurufim",
"inconel",
"lingada",
"náutilo",
"pecaris",
"quicado",
"sedaram",
"tabaroa",
"tapires",
"telexes",
"tiquara",
"trocano",
"bisaram",
"bufadas",
"carapiá",
"chiarem",
"derroca",
"embacem",
"ensilar",
"espinos",
"exaltes",
"ficarmo",
"gigogas",
"gneisse",
"latismo",
"loteira",
"pilotem",
"prócere",
"savelha",
"uniatas",
"abonava",
"amouras",
"atireis",
"bigatos",
"fraudem",
"fumaras",
"moitará",
"porfiou",
"vadiava",
"colorar",
"elevais",
"fetidez",
"fixares",
"foliado",
"frearia",
"futurar",
"gavelas",
"lisadas",
"pandano",
"aduncas",
"atrasas",
"açucare",
"bellita",
"dignais",
"encodes",
"fatigou",
"peleiro",
"radique",
"sanharó",
"sararas",
"sudável",
"tecerão",
"tibungo",
"amaçada",
"aquente",
"aristão",
"camelas",
"fresque",
"jiquiri",
"meleiro",
"remoída",
"sobrecu",
"vagarão",
"vodúnsi",
"achiote",
"aprista",
"deserte",
"focales",
"ilhando",
"laurana",
"legorne",
"litotes",
"maridas",
"mexerei",
"nadolol",
"norteio",
"ovulava",
"proleta",
"retesam",
"socasse",
"adornem",
"alongas",
"açoitem",
"bofetes",
"brasino",
"briosas",
"burleta",
"cárdica",
"descias",
"flectiu",
"ladrido",
"leituga",
"patinem",
"pinelas",
"roxeado",
"sisteme",
"soluças",
"sucuuba",
"adarves",
"agarrai",
"agustia",
"alastro",
"assenso",
"atachar",
"bebinca",
"burlona",
"conténs",
"debelam",
"detrata",
"enflora",
"listões",
"marinei",
"ofídica",
"pagueis",
"piadade",
"quitava",
"uranila",
"vidadas",
"arroxar",
"avolume",
"condons",
"engaram",
"fogones",
"jiboiar",
"labiosa",
"sondara",
"telhões",
"uniduto",
"aldeada",
"aliante",
"azurite",
"derrapo",
"endoído",
"lobrega",
"mulungo",
"murundu",
"máticas",
"pilados",
"prefixa",
"sulense",
"titulem",
"tunadão",
"vombate",
"vomitas",
"astista",
"bancões",
"dotaria",
"etnarca",
"infando",
"jaciaba",
"mofavam",
"ombreou",
"orçaram",
"acertes",
"amornem",
"azuleja",
"bicados",
"coabite",
"despeda",
"duplada",
"gondolo",
"lixavam",
"pichaim",
"possamo",
"pulares",
"pírulas",
"rastear",
"velhona",
"academo",
"aquilas",
"berrara",
"cablada",
"curacas",
"decerta",
"frásico",
"galeiro",
"gravana",
"lapense",
"limenha",
"popinha",
"tapurus",
"adenose",
"alagara",
"antepôs",
"aruandê",
"assolem",
"bafafás",
"carrijó",
"copázio",
"ecocida",
"enervem",
"gestose",
"infesto",
"lobatos",
"maratas",
"olentes",
"apregoo",
"asilada",
"brasado",
"cárdeno",
"docetas",
"encubar",
"enviuvá",
"habillé",
"limosos",
"musgoso",
"ponteou",
"rapazas",
"salvais",
"soedade",
"troando",
"zuretas",
"apistos",
"belinas",
"comedir",
"fugueta",
"girimum",
"mourato",
"moçadas",
"palmado",
"petitas",
"roxeada",
"vatapás",
"adastra",
"adastro",
"apuanos",
"arpoado",
"estalem",
"estivam",
"fossado",
"gadaria",
"legaria",
"lidimes",
"lôbrego",
"mesures",
"pedidor",
"penares",
"prioris",
"sopitar",
"tacrina",
"afásica",
"asparte",
"balaram",
"bocetos",
"bolação",
"igualde",
"ismênia",
"mobelha",
"pantufo",
"paraguá",
"pegavas",
"pontaço",
"pôntica",
"regalei",
"reposte",
"teóforo",
"trímero",
"tubéria",
"virtuem",
"voantes",
"yorkino",
"íncolas",
"alienem",
"alçarão",
"aurindo",
"banaras",
"buraque",
"desdiga",
"gorjões",
"manjaco",
"posudos",
"pregais",
"sonhoso",
"tenteei",
"tossiam",
"aloxana",
"bareges",
"dativas",
"lenhite",
"maginar",
"munirem",
"nanofio",
"noutrem",
"penação",
"remoçou",
"velhada",
"volibol",
"alouram",
"aziagas",
"chapear",
"coçasse",
"envazar",
"laudino",
"limense",
"pincenê",
"queimai",
"quiumba",
"sararia",
"saturem",
"saxônio",
"suarmos",
"tenhamo",
"vedarem",
"apegues",
"estorve",
"lanados",
"limenho",
"maboque",
"poupeis",
"pretura",
"reimoso",
"sabotem",
"unirdes",
"achatei",
"afiamos",
"biancas",
"birivas",
"cabomba",
"calquem",
"colonas",
"departa",
"drenara",
"embolse",
"frenado",
"garlopa",
"ouvível",
"padoque",
"quinqué",
"repinte",
"ucronia",
"agostar",
"armemos",
"barbeio",
"caparam",
"casetas",
"colmeal",
"gaseado",
"gorgeio",
"gravano",
"laborai",
"leceiro",
"livrais",
"minores",
"nefrita",
"parísis",
"perfilo",
"plaquês",
"precata",
"preteja",
"procuto",
"rosilha",
"tapinas",
"amoures",
"diácido",
"durmais",
"fardões",
"finaram",
"folheta",
"granhar",
"insulsa",
"agostem",
"cesseis",
"ctônica",
"detetei",
"eluídos",
"embatia",
"engerir",
"escamam",
"escumas",
"gambela",
"gerante",
"notarei",
"rapazio",
"ritense",
"sucanga",
"tiofeno",
"afligis",
"amostre",
"baptize",
"berbera",
"chismes",
"cobaios",
"dactilo",
"danadão",
"debulhe",
"estagie",
"estrilo",
"fordeco",
"frender",
"palitam",
"poliram",
"rareado",
"revisas",
"sedenho",
"valador",
"vaqueja",
"víndice",
"chuncho",
"cuieira",
"dêitica",
"granule",
"hinista",
"infetam",
"kilobit",
"morinda",
"mouches",
"opsinas",
"parreco",
"supuser",
"trarias",
"ungimos",
"venturo",
"afixava",
"amiúdes",
"arvorai",
"derivei",
"encadeá",
"enxarca",
"fiarmos",
"iscados",
"juncosa",
"mijasse",
"nunciar",
"pareiam",
"reergam",
"rorqual",
"rutilas",
"aguinis",
"alveite",
"archete",
"difamei",
"enxerem",
"espique",
"lactosa",
"nivelem",
"samouco",
"socamos",
"vagarei",
"acidado",
"amonite",
"citante",
"crúzios",
"duzidos",
"erudite",
"gasteis",
"himênio",
"isolina",
"poetava",
"rácicas",
"sáficos",
"troqueu",
"vovozão",
"caetana",
"despose",
"escauro",
"faxinei",
"loucões",
"lusista",
"mocetão",
"musicai",
"olhetes",
"ramnose",
"refilma",
"reinada",
"sebista",
"talamos",
"trêfega",
"xizinho",
"aduncos",
"aldeado",
"alpines",
"benzoil",
"caimito",
"cambiam",
"deliras",
"escande",
"fluides",
"gascões",
"imiscua",
"mainato",
"nêurada",
"tacavam",
"tacteou",
"viburno",
"alteava",
"conoide",
"curemos",
"legacia",
"obsidia",
"picotei",
"raconte",
"repuser",
"sorores",
"amochar",
"arapoca",
"arquita",
"bailios",
"baronas",
"boxista",
"candeal",
"copados",
"eclipta",
"estapeá",
"exornam",
"foleada",
"gôndula",
"peitaço",
"peltata",
"quotado",
"saldava",
"uivarem",
"agrades",
"bisalho",
"cisalha",
"elemina",
"jérseis",
"lanadas",
"lanzudo",
"lobeiro",
"mangava",
"marinum",
"necrosa",
"pautamo",
"perorou",
"ralarem",
"tipoias",
"acetosa",
"afivele",
"ambundo",
"açoutes",
"bricola",
"calejam",
"convéns",
"dilatei",
"elevamo",
"embalão",
"ensacou",
"gatenho",
"irmanem",
"lavacro",
"musicam",
"pirazol",
"pupação",
"repedir",
"teteira",
"amputei",
"ateasse",
"aulista",
"brandar",
"cerejal",
"concriz",
"custode",
"lagasca",
"lamurio",
"mojadas",
"olivari",
"palmeta",
"plúmula",
"prendou",
"repovoa",
"abaliza",
"agárico",
"duraque",
"inciará",
"nauseia",
"papeava",
"pitança",
"prezara",
"safismo",
"sustara",
"absidal",
"apeamos",
"arênico",
"brechar",
"bufaram",
"corimbo",
"encalho",
"esmiúce",
"gaianos",
"geonoma",
"limosas",
"litemia",
"marilha",
"minoano",
"polibãs",
"retorço",
"ribanas",
"rosnada",
"tonteio",
"adesina",
"barabas",
"cobalte",
"curagem",
"fedoras",
"giolhos",
"hossana",
"lucanos",
"obverso",
"salvage",
"talaria",
"toirada",
"acimado",
"arreiam",
"balatas",
"coarcta",
"coerana",
"constei",
"cremero",
"debuxar",
"desseca",
"farines",
"feroces",
"jurarão",
"pangola",
"santomé",
"sativas",
"urgimos",
"vigoras",
"viguesa",
"artices",
"casaras",
"entença",
"increpa",
"irapuru",
"jazidos",
"lúpicas",
"olência",
"paveses",
"saguaro",
"sugesto",
"acimada",
"agendes",
"antifaz",
"aquetar",
"asimina",
"azinhas",
"cavaria",
"desboca",
"efrateu",
"enrabam",
"esmagai",
"labutei",
"manquem",
"otimizo",
"papamos",
"pramana",
"rimento",
"sáculos",
"taxaria",
"acromia",
"almagra",
"apodada",
"betumar",
"caseara",
"comissa",
"desviro",
"droguem",
"díscolo",
"elisões",
"enraiar",
"escrive",
"ganchas",
"manolas",
"nitrado",
"refirma",
"surrões",
"vacadas",
"aloquem",
"aturdiu",
"falacem",
"feroesa",
"fiteira",
"jadeíta",
"malacia",
"manquei",
"rapavam",
"repagar",
"tapiris",
"ulceram",
"undação",
"achegai",
"amadura",
"anisete",
"capinam",
"cosemos",
"flegmão",
"hórrida",
"interme",
"juditia",
"mameira",
"mimésis",
"monguba",
"navetas",
"pariste",
"pesemos",
"práxico",
"receada",
"zagalos",
"abrasas",
"acebola",
"entubou",
"focaste",
"lindane",
"minasse",
"minorem",
"moedora",
"ninfais",
"piraúna",
"proeira",
"rubacão",
"soriana",
"tatetos",
"vegetou",
"óstraco",
"amilina",
"aradura",
"baldeio",
"calaras",
"camurim",
"captora",
"cartear",
"ciruela",
"cobicem",
"defluir",
"escumar",
"espaçam",
"exúvias",
"federam",
"felosas",
"gladiar",
"lutinas",
"potagem",
"tocaiar",
"trovoar",
"amasiar",
"apadana",
"apuleia",
"didímio",
"dioptro",
"elucido",
"escanei",
"ferires",
"fulgure",
"geraras",
"intruja",
"laceada",
"memores",
"náuplio",
"obcecou",
"pitorra",
"ventara",
"zumbira",
"afluído",
"assares",
"açulada",
"bardino",
"bonacho",
"cinéreo",
"corteje",
"engonço",
"greides",
"malhões",
"massajo",
"minhões",
"munguba",
"numenal",
"nutrias",
"pártico",
"quemose",
"recreou",
"apegais",
"axonema",
"boletes",
"bromado",
"chuleio",
"cremego",
"domamos",
"incutam",
"legente",
"mouchão",
"quicuio",
"sievert",
"valando",
"zambelê",
"almenar",
"amolava",
"arrobar",
"cedeste",
"doeriam",
"entrose",
"expiava",
"faxadas",
"fingias",
"flabelo",
"gablete",
"gritões",
"nitrate",
"ormósia",
"poterna",
"quentar",
"regaras",
"sariguê",
"soldava",
"taracuá",
"cofiava",
"cárdias",
"delongo",
"faltada",
"lidação",
"mudarmo",
"parrano",
"peixeis",
"renegas",
"abranjo",
"abronzo",
"albanel",
"amanara",
"cacifou",
"dichote",
"difundo",
"enfadas",
"fidagal",
"incitas",
"maludos",
"nadados",
"parélio",
"pastara",
"prouver",
"renanos",
"retuíta",
"rotante",
"segadas",
"arrosto",
"barulha",
"bufavam",
"cedeira",
"eburões",
"fluxões",
"freavam",
"fumares",
"lateiro",
"latines",
"lígulas",
"movermo",
"pegaras",
"pifados",
"pleitar",
"referve",
"saiinha",
"sestear",
"sidéreo",
"trelado",
"vulvite",
"afiarem",
"aleútes",
"armento",
"borrosa",
"canelão",
"caporal",
"decoada",
"dimanar",
"erreira",
"eumolpo",
"licitam",
"malitas",
"manquês",
"marulha",
"mórulas",
"pedados",
"proares",
"tachava",
"tampona",
"vectora",
"adiarei",
"afiance",
"apostes",
"aramaçã",
"basídio",
"bodelha",
"cubense",
"denotei",
"departe",
"emboços",
"empanam",
"esmifra",
"heteras",
"inhapim",
"refilei",
"sugarão",
"sumanta",
"tranchã",
"úmbrios",
"apiales",
"beronha",
"besnico",
"bipolos",
"biselar",
"boiamos",
"caçarei",
"currelo",
"cécrops",
"empacho",
"empolou",
"forints",
"fruírem",
"ingeres",
"liurais",
"medível",
"obragem",
"ornarem",
"pituras",
"raparem",
"refrito",
"regerar",
"rezinga",
"tosadas",
"brilhes",
"calmosa",
"cimente",
"cloruro",
"coramos",
"drageia",
"enxotei",
"faliria",
"igaraçu",
"milhado",
"natante",
"olvidem",
"perduro",
"predize",
"querena",
"redisse",
"refarei",
"simiano",
"tenrura",
"tragais",
"táquion",
"vegetas",
"aranhar",
"calemas",
"cambado",
"chacona",
"chiante",
"córnica",
"duelado",
"eliciam",
"escurar",
"manejas",
"prateou",
"salense",
"sanhudo",
"sombrar",
"tapeiam",
"tergito",
"truncam",
"zelemos",
"acoplei",
"bocardo",
"celista",
"corneia",
"curatos",
"delirem",
"dotasse",
"encilho",
"estucha",
"guinche",
"humilda",
"locular",
"mutilei",
"paladim",
"rebojos",
"repeite",
"rijinho",
"rossios",
"tarrafo",
"veleira",
"acalora",
"bisotes",
"chispam",
"empapou",
"esbulha",
"ligarmo",
"lubango",
"morguei",
"refrige",
"secaste",
"vegetei",
"assanhe",
"bascule",
"coesões",
"empaste",
"imagear",
"janeiru",
"madurou",
"muganga",
"nausear",
"númenos",
"posarão",
"ulnares",
"vedamos",
"aproves",
"assises",
"balboas",
"bivacar",
"canídea",
"encapam",
"filagem",
"javarda",
"judicar",
"lactama",
"lesavam",
"margoso",
"quichés",
"renites",
"társios",
"verdões",
"caruara",
"dioicas",
"empolam",
"escalfe",
"fuçarem",
"juquiri",
"librada",
"maranta",
"peroram",
"risório",
"sofreno",
"subsuma",
"violina",
"alienei",
"arinque",
"bipedal",
"cruzeis",
"crático",
"cólicos",
"elisiva",
"eruexim",
"esgotas",
"esmiúço",
"evolava",
"fazeção",
"magismo",
"perlada",
"ruivona",
"suturou",
"tabuões",
"teônimo",
"viçando",
"afincar",
"alancar",
"aluadas",
"amentes",
"assines",
"barrear",
"buscamo",
"embrear",
"fórnice",
"matumbo",
"municie",
"métopes",
"pacuera",
"sovelas",
"tapadão",
"alcemos",
"antrona",
"crática",
"iodadas",
"mineram",
"moscado",
"pecitas",
"ressume",
"siriúba",
"tufadas",
"degaste",
"depilem",
"excutir",
"fofando",
"gaiutas",
"gozante",
"junteis",
"marinou",
"picotas",
"pomboca",
"quediva",
"refinem",
"abichar",
"acetais",
"ancôneo",
"babadão",
"caligem",
"capador",
"cetoses",
"focinha",
"marisca",
"parreca",
"rodaque",
"sutural",
"tauismo",
"urraram",
"virtuai",
"abdomes",
"adjecto",
"afogues",
"altaico",
"antenal",
"atrelem",
"boipeva",
"colaste",
"cornejo",
"deitemo",
"disseco",
"elítica",
"evoluis",
"faleçam",
"frisões",
"fúndica",
"gangana",
"latidor",
"luziram",
"mudador",
"oracula",
"pelasgo",
"podaras",
"sistros",
"traceja",
"urutaus",
"verdosa",
"zurrava",
"adestro",
"agoniou",
"assacam",
"bombamo",
"bordejo",
"careces",
"cefeida",
"chinita",
"coremas",
"diritos",
"efector",
"labrais",
"reboava",
"rodopie",
"saldara",
"sanamos",
"tramara",
"zurzido",
"abanara",
"amiudar",
"boinhas",
"cárdico",
"dimídio",
"encares",
"esfiado",
"ferroam",
"finagem",
"frênica",
"gelamos",
"linhais",
"mantela",
"marisas",
"mediate",
"olandim",
"reatara",
"regulas",
"amaciem",
"chichis",
"desavir",
"fadando",
"florena",
"fãzinha",
"gostamo",
"gráceis",
"laverca",
"lesaria",
"mamudas",
"mervais",
"mufumbo",
"ocupeis",
"pelador",
"rebolem",
"recolta",
"refreei",
"vetinho",
"xingara",
"acufeno",
"alvanel",
"amimado",
"armaste",
"caiando",
"colgada",
"desnate",
"escaços",
"maestre",
"moraras",
"místrio",
"remirar",
"rugirão",
"tessela",
"uranilo",
"acrasia",
"adejava",
"aortite",
"arapium",
"candado",
"capitoa",
"cinabre",
"emetina",
"engatem",
"escavem",
"eviteis",
"garrava",
"negavas",
"noteiro",
"ondimba",
"prancho",
"removei",
"tratemo",
"troneio",
"vetavam",
"acídico",
"alidada",
"aljofre",
"alumbre",
"amentou",
"amirina",
"anisado",
"contamo",
"couvade",
"curimbó",
"debique",
"desolou",
"ensacam",
"espanam",
"feitais",
"mendoca",
"papudas",
"pradoso",
"rasgues",
"taremos",
"umbrias",
"venidas",
"absolto",
"cabiúna",
"calmado",
"calçudo",
"conatos",
"esprita",
"festado",
"gaivoto",
"inticar",
"isóbata",
"milibar",
"minante",
"pactolo",
"pesante",
"porzana",
"rejunto",
"sumacas",
"tensivo",
"alvadio",
"aperana",
"apiedam",
"arroche",
"assasse",
"bezanas",
"buzinem",
"canarês",
"casonas",
"delatem",
"exônimo",
"majoram",
"nômicas",
"piolhas",
"planger",
"polpado",
"porinas",
"postamo",
"ralasse",
"rateiro",
"serissa",
"vidores",
"ôhmicas",
"ajoujar",
"analina",
"ansônia",
"argirol",
"auxilou",
"canhona",
"chiches",
"cxxviii",
"daríeis",
"decepei",
"dioicos",
"enxugai",
"espirei",
"masquem",
"nimbada",
"parodie",
"pintéus",
"polidão",
"remirei",
"segunde",
"sintema",
"sobados",
"tenteio",
"usurpei",
"aningal",
"budiões",
"cajitas",
"drunfos",
"estruge",
"formamo",
"jugueis",
"latânia",
"palonço",
"pinguem",
"reditas",
"refugam",
"rumarei",
"rutilar",
"tanásia",
"toradas",
"acalcar",
"algemei",
"argiria",
"caíques",
"desfiro",
"doutore",
"egótico",
"espiram",
"evadira",
"fenacho",
"fezadas",
"fosfeno",
"latidas",
"mariado",
"negrais",
"panocha",
"perceve",
"pobrete",
"pontado",
"ranques",
"restilo",
"sovador",
"tutelem",
"zelinho",
"ampares",
"chulada",
"cxlviii",
"gingras",
"monovia",
"nêurula",
"panaria",
"perélio",
"pugnará",
"rabujar",
"razonar",
"sarnoso",
"setária",
"abnóxio",
"amarios",
"columbo",
"désseis",
"enlutam",
"estagno",
"galopei",
"gandulo",
"gongolo",
"hístico",
"mangões",
"pagaiar",
"peroral",
"premera",
"rubisco",
"veloces",
"vulpino",
"abastar",
"afagara",
"almoeda",
"bolinei",
"citares",
"condoía",
"desnata",
"eixando",
"enfurna",
"esboças",
"escarva",
"extante",
"fartais",
"noveles",
"odiares",
"pilular",
"rulotes",
"zeraria",
"acamisa",
"albiana",
"alucine",
"amostas",
"arfadas",
"bajulem",
"bitelos",
"chipala",
"compeça",
"criavas",
"cutilar",
"enquisa",
"estanhe",
"farolas",
"juremar",
"mnésico",
"morboso",
"represo",
"terafim",
"balados",
"boldrié",
"canteis",
"chineis",
"emodina",
"finques",
"glotais",
"levador",
"lideras",
"lixaria",
"micados",
"minorou",
"morélia",
"muraria",
"penarem",
"provias",
"soltais",
"tremeis",
"vivedor",
"vogavam",
"amastia",
"amorfia",
"ampério",
"ariscam",
"covanca",
"desuses",
"encavar",
"igarité",
"maceram",
"naguais",
"ofélias",
"ondeava",
"privamo",
"pungido",
"rapasse",
"razoado",
"reaverá",
"sodados",
"tejanos",
"tezinho",
"órquide",
"animeis",
"antraco",
"arribam",
"ficoide",
"filhado",
"gâmbias",
"listrar",
"massará",
"muçambé",
"ocorrei",
"oestino",
"prediga",
"verguem",
"alaguem",
"arquete",
"atopias",
"bolador",
"colmada",
"crismei",
"cxxxvii",
"enfunam",
"fixarmo",
"jantada",
"maronês",
"numeras",
"reporia",
"ronrono",
"tadorna",
"velhões",
"zoisite",
"chicosa",
"dazibao",
"ergueis",
"malinar",
"mutuada",
"nosódio",
"podagra",
"porrais",
"remixam",
"saqueie",
"terrada",
"timbram",
"adicado",
"andejar",
"avigora",
"calinas",
"catechu",
"deprezo",
"enciuma",
"enluvei",
"esteras",
"estimes",
"fenecia",
"isóbaro",
"matavas",
"mesnada",
"morimos",
"murinas",
"nasales",
"necator",
"ululava",
"varismo",
"arrasai",
"brasada",
"citanto",
"enzinho",
"esfeito",
"itupava",
"meçamos",
"mufunfa",
"médanos",
"netúnio",
"nomeias",
"prolato",
"rimaria",
"tímicas",
"vigerão",
"brequei",
"cabilas",
"capitai",
"cárpica",
"detende",
"fraldes",
"guiauto",
"mamites",
"memorie",
"oclusas",
"opípara",
"plugava",
"raparão",
"reninha",
"sonhosa",
"sálvias",
"tarjada",
"trancho",
"tétanos",
"untaram",
"agrando",
"alopram",
"arrazoo",
"carnada",
"colidas",
"corizas",
"deputar",
"desamam",
"devindo",
"encacha",
"ericina",
"espocou",
"fanados",
"finquem",
"fivelão",
"ilusivo",
"incluas",
"jubilam",
"lombras",
"manobro",
"musette",
"oficiei",
"peleira",
"quietou",
"recrias",
"rulando",
"silabar",
"vasinha",
"velação",
"alvorar",
"ameados",
"bicarem",
"canetar",
"esfolha",
"feniano",
"fizeras",
"heureca",
"hornera",
"inebrio",
"matério",
"perigou",
"pisauro",
"polposo",
"premiei",
"protuso",
"rapazão",
"tinente",
"tolidos",
"volteou",
"agnação",
"amosita",
"caritel",
"chanana",
"colimam",
"ditemos",
"enlaças",
"estugou",
"evictor",
"lunário",
"orceína",
"palaios",
"perídio",
"podrona",
"polaino",
"profeto",
"recatar",
"robores",
"ticados",
"apupada",
"babismo",
"badagas",
"celagem",
"clarina",
"confino",
"cricris",
"curvamo",
"encruar",
"farisco",
"flipado",
"fujiram",
"fusione",
"humeral",
"poleame",
"refendo",
"rimaram",
"roçador",
"sandres",
"titulei",
"tomatal",
"ulexita",
"vendava",
"abêbera",
"agachem",
"cachiço",
"chicoso",
"decalco",
"estufem",
"furcada",
"jacuaçu",
"moqueta",
"márcios",
"negundo",
"paceira",
"peleado",
"portamo",
"propino",
"proseio",
"reduzas",
"ronquei",
"viragos",
"acorrei",
"baceiro",
"consoar",
"cotejam",
"desfeia",
"ensalmo",
"gamante",
"juventa",
"nevense",
"quiques",
"rotando",
"taniças",
"uivarão",
"xonadão",
"amornou",
"anistie",
"bananos",
"calemba",
"cederes",
"clônico",
"deduram",
"empanei",
"enlevou",
"lateria",
"linguem",
"listese",
"lupinas",
"mondado",
"obradas",
"teriaga",
"trisara",
"angusto",
"béquica",
"cabente",
"decoção",
"estugar",
"mojados",
"suáveis",
"terrões",
"vitelão",
"agalega",
"albedos",
"analita",
"aturais",
"azulava",
"birmane",
"brejais",
"cemente",
"conídio",
"corimás",
"cotiara",
"crestas",
"cupópia",
"enzinas",
"estases",
"estriam",
"farruco",
"festino",
"flagele",
"fruíram",
"gamando",
"glaçado",
"igualda",
"jubilai",
"nadarei",
"navajas",
"neurona",
"poáceas",
"reponte",
"tembetá",
"tripulo",
"acoados",
"alargas",
"alarife",
"albardo",
"arapapá",
"bordara",
"cabrada",
"camelão",
"elandes",
"liforme",
"mobílie",
"peligna",
"podarem",
"pululem",
"recapar",
"rojando",
"tétrada",
"vinhada",
"alquilé",
"bacarim",
"carcoma",
"coirões",
"comodar",
"detetem",
"dotavam",
"duzindo",
"embarra",
"galanes",
"leucita",
"mirasol",
"pererés",
"picotam",
"pleuris",
"remixem",
"resídio",
"romanis",
"ruiriam",
"sondais",
"adoremo",
"avençar",
"correge",
"cárneas",
"eslaide",
"fartéis",
"goelada",
"grupeto",
"igaçaba",
"luxando",
"moscoso",
"refocar",
"súbdita",
"timbrou",
"arretou",
"basquir",
"cetenas",
"chovida",
"cipoada",
"ecoamos",
"engulia",
"epífito",
"infamem",
"insufle",
"melodie",
"militas",
"polinia",
"pretora",
"resedás",
"revelai",
"rugirem",
"safares",
"silanol",
"tranita",
"varjões",
"varrões",
"vetamos",
"altores",
"asserir",
"bedames",
"bicones",
"cantamo",
"cumbaru",
"filiamo",
"futucou",
"galenas",
"insomne",
"lambais",
"laureno",
"macaque",
"ouriçou",
"palmeto",
"pariras",
"piantes",
"pigando",
"provemo",
"pâmpano",
"rabalha",
"reverão",
"roseado",
"rostrum",
"rácicos",
"serpeia",
"tiazida",
"trívias",
"agueira",
"alisara",
"animemo",
"crepite",
"dormito",
"federão",
"fraguei",
"juçaras",
"lamosos",
"landins",
"libombo",
"mioceno",
"máticos",
"pipoque",
"rasoura",
"salteio",
"achável",
"amansei",
"arisque",
"bixeira",
"boqueio",
"cambeta",
"chibcha",
"conação",
"dosaram",
"fogares",
"gasalho",
"havanês",
"jasione",
"nereide",
"numisma",
"oitavar",
"palpava",
"rômbica",
"semeeis",
"toparei",
"tumbira",
"vaiaria",
"vigiles",
"abaular",
"abrangi",
"alquino",
"bearnês",
"cagonas",
"cochino",
"corregi",
"cxxxiii",
"dalguém",
"desdize",
"eivando",
"encache",
"ferroou",
"grupada",
"hexanol",
"longada",
"mescado",
"monemas",
"nitente",
"ordenho",
"poetaço",
"russana",
"tornesa",
"vincava",
"acabamo",
"bidente",
"blindei",
"catasse",
"deslaça",
"digiram",
"elançou",
"ericino",
"gerbilo",
"landaus",
"lastrar",
"numênio",
"picaste",
"prótase",
"purpure",
"repilam",
"sapotis",
"silogeu",
"sugarei",
"acesses",
"acochar",
"ajeitas",
"atordoe",
"açaimes",
"baranha",
"bersama",
"blefava",
"chantar",
"destapo",
"doestes",
"ectomia",
"emposta",
"escanho",
"foiçada",
"invetam",
"jazeria",
"laurato",
"levites",
"lipoide",
"mojando",
"partimo",
"riojana",
"vítores",
"arregos",
"arretar",
"aureole",
"biancos",
"cultuas",
"demissa",
"discara",
"embruxa",
"entesou",
"ezraíta",
"gabasse",
"geloide",
"gozadão",
"ingredi",
"melarem",
"mofinos",
"paguros",
"papasse",
"ufarsim",
"écfrase",
"adornas",
"bacaraí",
"brandam",
"caapeba",
"calador",
"desavio",
"festara",
"fiolhal",
"gáspeas",
"humiana",
"legaste",
"lusismo",
"maduram",
"moicana",
"posemos",
"rabioso",
"ritmava",
"sairias",
"suavizo",
"tapemos",
"tororós",
"abadona",
"adenize",
"agencio",
"alertai",
"amavios",
"atapeta",
"atearia",
"baterna",
"bingada",
"chameja",
"chanato",
"chumbas",
"clânico",
"ensopam",
"frustas",
"garapas",
"legante",
"leirias",
"lourais",
"minamos",
"murando",
"mândria",
"neorama",
"nubloso",
"pirunga",
"reaceso",
"reemite",
"segrede",
"soleiro",
"alparca",
"amestra",
"caseada",
"choupas",
"corpuda",
"cuscuta",
"derreou",
"domines",
"esteado",
"filmões",
"fritara",
"frustem",
"joeiras",
"madurês",
"manatim",
"manatis",
"numária",
"panurgo",
"reanimo",
"refuses",
"regates",
"ressumo",
"rodares",
"salveis",
"aloprei",
"asfixio",
"aspires",
"ateamos",
"bafejos",
"bailara",
"balhana",
"chairas",
"crestou",
"crudeza",
"eructar",
"falcaça",
"fusário",
"maiatas",
"miscela",
"negável",
"novados",
"paparis",
"pecares",
"plumada",
"pulicar",
"rimamos",
"roscofe",
"semento",
"tendado",
"alcáçar",
"alforra",
"avícula",
"corasse",
"defenso",
"estriou",
"factivo",
"favônio",
"gelaria",
"inundai",
"locarem",
"macacoa",
"marcais",
"masques",
"mucicas",
"oprimis",
"picnose",
"radicai",
"sifonal",
"abrição",
"amínico",
"aqueste",
"arrimam",
"bocudas",
"cantate",
"cebolos",
"chumbem",
"curetes",
"debitem",
"encarra",
"ensopei",
"espanei",
"fortuno",
"forésia",
"lorenas",
"recargo",
"romanza",
"sardana",
"somares",
"sístena",
"taguara",
"acabana",
"cafreal",
"embolem",
"encruou",
"guancho",
"indispô",
"livônio",
"mundane",
"oroense",
"pingara",
"pirrita",
"plumoso",
"referta",
"relerei",
"segurai",
"sonados",
"terrora",
"terçóis",
"tórtula",
"vinhoso",
"zonagem",
"avivara",
"batocas",
"caderna",
"dardara",
"devotem",
"escolma",
"escruta",
"esganei",
"eslinga",
"estarmo",
"fumável",
"gradece",
"lazarou",
"lisuras",
"miótomo",
"normada",
"obrarem",
"oxidava",
"pregoar",
"rapinam",
"retrace",
"ricínio",
"soteiro",
"tijolar",
"adamita",
"alarmei",
"alvejei",
"cabouco",
"caraíva",
"cavaras",
"cupiúba",
"elviras",
"floreou",
"ilhares",
"indinho",
"instile",
"lanista",
"laçavam",
"lombuda",
"modicar",
"mofinas",
"noninha",
"parqueá",
"pirinha",
"planada",
"relvosa",
"rueiros",
"tracejo",
"aborção",
"agastou",
"amasiou",
"anomias",
"antelas",
"azeitou",
"barbona",
"braceja",
"cacheou",
"caimões",
"caveiro",
"cimbres",
"colugos",
"equante",
"escorem",
"estolou",
"galibis",
"ginkgos",
"inciava",
"nuançar",
"onagros",
"perrice",
"querato",
"recursa",
"rodeais",
"sarreta",
"tourado",
"vaganti",
"verguei",
"vexante",
"apintar",
"arrimou",
"brasear",
"buliram",
"canhada",
"darsana",
"esponda",
"eximiam",
"filhões",
"firmamo",
"lepisma",
"modeles",
"muscosa",
"nigrita",
"nonatos",
"pinchão",
"quenita",
"ruminei",
"tebanas",
"troante",
"tubiano",
"apensem",
"chifrão",
"cloroso",
"durarei",
"entreta",
"foragir",
"imbondo",
"lamegão",
"lorchas",
"maricão",
"muezins",
"palêmon",
"picaras",
"pigarço",
"quincha",
"quitoco",
"refluía",
"taronja",
"véliger",
"afinfar",
"agâmica",
"anestia",
"arengou",
"arinhos",
"baicuru",
"bragana",
"carocas",
"emendai",
"engomei",
"falisco",
"galadas",
"inserte",
"matamba",
"quesada",
"reborda",
"relaxes",
"romanda",
"trioses",
"tísicas",
"uricuri",
"zangamo",
"zimbral",
"zizânia",
"amanhos",
"botadas",
"capinou",
"cardios",
"clareai",
"cocções",
"datasse",
"dizimem",
"enredei",
"flúvico",
"gemeria",
"inflara",
"litigou",
"maneios",
"orariam",
"pogrons",
"porrado",
"quiçaça",
"rarezas",
"sujares",
"sâmaras",
"trepara",
"ulmeira",
"varunca",
"acalmes",
"alcunho",
"aloquei",
"apinhou",
"arredas",
"borroso",
"cabeçar",
"erraras",
"esteada",
"estiara",
"fumavas",
"geniana",
"gizando",
"logamos",
"maneias",
"nômicos",
"obcecam",
"pacamão",
"panarás",
"porejar",
"recurve",
"redador",
"susténs",
"taparão",
"uníamos",
"abrolha",
"boletei",
"dizedor",
"enfezei",
"espelir",
"estirem",
"estorce",
"excreto",
"favolas",
"florias",
"gárrulo",
"ideados",
"luzense",
"palotes",
"piornal",
"pixotes",
"retorci",
"somarei",
"tapaste",
"verdume",
"vicejem",
"volitam",
"zaburro",
"avarcas",
"biculas",
"brocotó",
"cedovém",
"empasta",
"escumam",
"esquipa",
"festada",
"odiavas",
"persina",
"potoque",
"revidem",
"riojano",
"subcapa",
"zamboto",
"aninhem",
"arrupia",
"aterras",
"bufador",
"cabueta",
"colmate",
"desolam",
"encanam",
"feminal",
"gatunas",
"nótulas",
"oveiros",
"palrava",
"perizeu",
"tobiana",
"ungirem",
"urânios",
"utensil",
"areeira",
"arreste",
"binação",
"biquara",
"cianuro",
"dotarão",
"esganam",
"evadiam",
"forçudo",
"fruirão",
"ginetas",
"laminam",
"legatos",
"lineais",
"oprimas",
"picumãs",
"páticas",
"quítons",
"repriso",
"romando",
"supuram",
"talones",
"travosa",
"tropéis",
"alógico",
"azedada",
"azucrim",
"beiçada",
"deporão",
"desamou",
"enxebre",
"escario",
"exalçar",
"excites",
"falteis",
"flexivo",
"frandes",
"frenada",
"gálicos",
"hipógea",
"jocundo",
"mussoco",
"planiço",
"retinia",
"sujarão",
"tolhera",
"tuliana",
"turiões",
"venusto",
"vivento",
"abdicas",
"adherir",
"alicias",
"amimada",
"aricobé",
"cabanga",
"cangapé",
"cordoma",
"céspede",
"debitei",
"fardeta",
"jugarem",
"lustrei",
"optadas",
"puelche",
"quilosa",
"regavas",
"rezaste",
"sessado",
"tentamo",
"tesoira",
"ínfulas",
"bagdali",
"beafada",
"braquio",
"calumbi",
"camboas",
"carbeno",
"catança",
"depomos",
"entocam",
"esfiapa",
"espaçou",
"jaquete",
"jogueis",
"licitem",
"mexamos",
"mordias",
"osculou",
"palheto",
"rifaram",
"rufaram",
"réproba",
"urônico",
"xeleléu",
"zigomas",
"acácios",
"adaptes",
"armadão",
"babaçuê",
"bailias",
"bolasse",
"braveja",
"campado",
"dicando",
"domarem",
"lariços",
"milhano",
"moraste",
"recando",
"silvava",
"solarem",
"trovejo",
"vigando",
"vitando",
"zíncica",
"adelfos",
"asceses",
"atorado",
"baleote",
"duzidas",
"exuense",
"flagrem",
"fleimão",
"garrões",
"juravas",
"lávicos",
"merinos",
"mestrão",
"rezaras",
"roseada",
"safavas",
"taleiga",
"toparão",
"voceros",
"agirdes",
"albarca",
"alienas",
"assacou",
"averbam",
"bafagem",
"bárdica",
"cediços",
"enfadei",
"estalas",
"esvoaço",
"expensa",
"ideadas",
"litinho",
"oogamia",
"pontuas",
"pretear",
"relaxas",
"secares",
"unhando",
"zeradão",
"álgidos",
"adustos",
"bichoso",
"bramava",
"brecava",
"cavarei",
"chatões",
"delével",
"endeuso",
"ensaias",
"fuçados",
"ganapos",
"morbosa",
"paladas",
"relocou",
"suingar",
"tarioba",
"torante",
"ventena",
"abismam",
"acirrem",
"caliças",
"camboim",
"cartona",
"domasse",
"drávida",
"gabadas",
"inflijo",
"kirundi",
"laçamos",
"macedos",
"machacá",
"magriça",
"meritis",
"prócida",
"retrasa",
"tomadia",
"useiras",
"valsado",
"xácaras",
"acalifa",
"agencie",
"algemem",
"amência",
"aparata",
"bornita",
"dilatas",
"enervas",
"ervaçal",
"gnômica",
"hioideu",
"iludimo",
"jejunos",
"lurando",
"lóticas",
"munimos",
"ocorres",
"pactada",
"painços",
"rabiote",
"rebeber",
"tremias",
"alçarás",
"arvorei",
"arábigo",
"bácoras",
"caeiras",
"clarino",
"costeou",
"deitais",
"discite",
"empezou",
"enleias",
"joãozão",
"mongoió",
"morugem",
"olhapim",
"ouriçam",
"parodio",
"pirasse",
"poemaço",
"saraste",
"segarás",
"turbara",
"vozeada",
"zostera",
"acuação",
"afeiçoo",
"alceado",
"atochas",
"badiana",
"baganda",
"bramara",
"burelas",
"cambeba",
"captais",
"cerosos",
"cremern",
"debitas",
"descome",
"dilatai",
"diserto",
"esporar",
"estalis",
"estucar",
"gozosas",
"licitei",
"muricis",
"novacap",
"obsedar",
"ofiúros",
"pirateá",
"provede",
"relaram",
"tampara",
"tégulas",
"atritou",
"babasse",
"besnica",
"cachené",
"cubando",
"deplore",
"engenhe",
"espiara",
"espumei",
"fedesse",
"gerarei",
"idearia",
"implexo",
"lapeira",
"melitos",
"mensuro",
"morcona",
"nêmeses",
"patível",
"pilecas",
"pimbada",
"sublote",
"tararam",
"trefina",
"tremule",
"abuseis",
"acocado",
"anartro",
"angulas",
"avexada",
"birmano",
"broxava",
"cambies",
"escargo",
"excedes",
"exonero",
"fuçavam",
"galones",
"glioxal",
"guindas",
"instara",
"jinsona",
"luxosas",
"nômadas",
"palmeia",
"purgava",
"rodador",
"rolhada",
"sicônio",
"sprinto",
"trílogo",
"verdito",
"vermina",
"vozeado",
"xarroco",
"agareno",
"aleitam",
"arganel",
"arrumes",
"bestice",
"chalado",
"cordais",
"cremese",
"dedilhe",
"derisão",
"doparem",
"embiras",
"excluis",
"guaduas",
"lascari",
"lixosos",
"maquino",
"marcita",
"mimares",
"paiaiás",
"propilo",
"provais",
"recadar",
"regrido",
"sitiara",
"soçobre",
"abanque",
"alumine",
"arfares",
"arômata",
"assoado",
"atiçará",
"bagadas",
"boquear",
"canarim",
"cardina",
"damismo",
"degluta",
"devores",
"dosamos",
"démodés",
"enchias",
"estarna",
"glosava",
"griséus",
"isospin",
"laçarem",
"novante",
"rebotar",
"relanço",
"remarco",
"repuxão",
"sexuale",
"éxciton",
"apontai",
"aqueças",
"aurigas",
"baliado",
"barbino",
"bolavam",
"camauro",
"cargará",
"engelho",
"enguice",
"entramo",
"excetuo",
"jardino",
"labelos",
"lisinas",
"luzidas",
"objeito",
"panejar",
"rabanho",
"regarão",
"retoria",
"robledo",
"sínicas",
"taxasse",
"unhinha",
"visemos",
"visiono",
"aduções",
"aformar",
"agnados",
"alentem",
"arengam",
"avalies",
"banzada",
"borados",
"cabecel",
"canchim",
"canície",
"chapeia",
"descase",
"edenize",
"escruto",
"estonar",
"estufim",
"fabales",
"invadas",
"manteia",
"nominei",
"nublosa",
"popismo",
"reavivo",
"receais",
"robalão",
"sanchas",
"sapeira",
"simulta",
"sprinta",
"amautas",
"apiedei",
"azedara",
"dedurei",
"despimo",
"dímetro",
"flaches",
"forraje",
"garçone",
"gelasse",
"glioses",
"guiemos",
"gulares",
"inguche",
"inundes",
"marambá",
"platais",
"porânea",
"silenos",
"talísia",
"toitiço",
"tronado",
"viduais",
"vinquei",
"acostam",
"acunhar",
"agraços",
"amainam",
"armilas",
"asarias",
"assinta",
"camanho",
"cecílio",
"chanfra",
"coloris",
"corocas",
"domação",
"engraxo",
"espaças",
"freixal",
"gomosas",
"mareira",
"mosques",
"obrados",
"olhudos",
"onímoda",
"orfeões",
"pajonal",
"postite",
"pátenas",
"reveler",
"tamarês",
"teurgos",
"tionila",
"togadas",
"trocamo",
"zerador",
"alógama",
"amorica",
"antolho",
"arreava",
"avezado",
"bariões",
"erinose",
"exitará",
"factiva",
"fagamos",
"ignávia",
"judites",
"lalação",
"lésbias",
"malanga",
"mixteco",
"opósito",
"plasmei",
"prócero",
"surcado",
"abaixai",
"abitado",
"aclimar",
"caldara",
"deserdo",
"délfica",
"eluídas",
"enleava",
"gerires",
"infamam",
"jequiri",
"laçasse",
"luxento",
"micáceo",
"obravam",
"palaque",
"partais",
"peixota",
"referto",
"rinchas",
"saguins",
"tarolos",
"trolaró",
"urracas",
"vitiano",
"adubava",
"andável",
"avalizo",
"berzebu",
"chapava",
"chaputa",
"cuxitas",
"falcada",
"filtras",
"luzirem",
"pejorar",
"perreio",
"quiocos",
"ranchão",
"reaviso",
"rodetes",
"tabacão",
"tamises",
"túmidas",
"vegetem",
"aquiléa",
"axônica",
"babatas",
"beguina",
"blefado",
"boranos",
"butanal",
"carraço",
"cuidoso",
"emplaco",
"esgalho",
"espalmo",
"esteiam",
"foderes",
"lerdice",
"moguino",
"odontos",
"rifamos",
"subloco",
"tourões",
"tucanar",
"tágides",
"zacinto",
"acabaça",
"adipato",
"agravei",
"alourou",
"amadure",
"bafejam",
"cameral",
"cangulo",
"cicleta",
"domaria",
"enciste",
"eventro",
"frondas",
"funfado",
"gabados",
"insonte",
"janelar",
"ligável",
"mamanas",
"moveras",
"obraria",
"oclusos",
"purágua",
"refiles",
"texiana",
"traguem",
"acolhas",
"afogamo",
"agravio",
"anharas",
"arejava",
"cuitelo",
"enxerte",
"epirota",
"faceada",
"galopas",
"mazanza",
"maçudas",
"pelicão",
"pigarra",
"rasurei",
"roncada",
"ronquém",
"rosqueá",
"anuviam",
"asmoneu",
"asserta",
"bastido",
"bobeado",
"coligem",
"cócleas",
"ejetava",
"estevem",
"evolves",
"galeado",
"guarumá",
"maianga",
"malinas",
"mozinha",
"mugirem",
"pausara",
"remetes",
"revejas",
"ruíssem",
"salésio",
"tacuari",
"abordos",
"alevita",
"aviltem",
"barbelo",
"braguês",
"centela",
"czardas",
"derribo",
"endures",
"esturra",
"golfava",
"impreca",
"inhibir",
"laureia",
"macacar",
"milimol",
"nativus",
"nobélio",
"nomarca",
"obviado",
"pairada",
"piriris",
"porcona",
"prepona",
"regaria",
"relojão",
"riparam",
"sandeus",
"signais",
"tenteou",
"toninas",
"valante",
"valeres",
"afilias",
"arcarei",
"arrolei",
"avalado",
"capilés",
"dopavam",
"entocou",
"esfumem",
"gabrito",
"galvana",
"gemação",
"juntais",
"mamunha",
"nevrite",
"pastada",
"ponhado",
"procotó",
"salvamo",
"vigiais",
"afretar",
"amandei",
"aracuãs",
"avalada",
"bacabas",
"badajos",
"coleita",
"coligue",
"copeque",
"cotarem",
"denotas",
"destece",
"enfiamo",
"escusei",
"estiado",
"marrona",
"pardoca",
"pedrões",
"rizados",
"solovox",
"sênones",
"treines",
"turante",
"télamon",
"abancam",
"acídica",
"airglow",
"apartas",
"bislamá",
"cagante",
"caudada",
"chingos",
"coalham",
"divagas",
"enevoam",
"espargi",
"esterem",
"lebrado",
"malembe",
"maquiem",
"operais",
"patifão",
"sonista",
"turvara",
"alijava",
"ambroso",
"auditem",
"açafata",
"bogaris",
"bombita",
"chilrar",
"coaxava",
"colheis",
"condais",
"córsico",
"defiram",
"elétrio",
"estofes",
"garfias",
"jagodes",
"modulem",
"nunciou",
"nébulas",
"rabeada",
"resseco",
"soquear",
"sáficas",
"trunfas",
"aliates",
"aneiros",
"aplasta",
"aquista",
"aranhol",
"ariscou",
"aromais",
"arredem",
"aspeado",
"assomem",
"bandora",
"bangola",
"birotes",
"cajuada",
"cervina",
"cisamos",
"ciânico",
"deformo",
"escanar",
"eslabão",
"exigias",
"falseie",
"fumegou",
"garruço",
"gloriou",
"imageio",
"inquiro",
"micarta",
"nadicas",
"nalguém",
"negreja",
"onceiro",
"porelas",
"realoja",
"rinchos",
"ruvinha",
"sulfuro",
"susanas",
"teçamos",
"vazarão",
"agrides",
"arquico",
"atalham",
"avelana",
"bandari",
"bissaco",
"bolsado",
"congros",
"cálcios",
"debelem",
"dizíeis",
"dobreis",
"encravo",
"espojou",
"etanóis",
"ficador",
"inchume",
"lambaio",
"litigue",
"papeiam",
"papilho",
"patoral",
"pirolas",
"pituíta",
"rasoira",
"teceres",
"unifico",
"acoleta",
"agotada",
"alardeá",
"alvitro",
"alílico",
"atroces",
"bajaras",
"canense",
"coagiam",
"costada",
"dúlcida",
"esqualo",
"estacal",
"friinha",
"fínicos",
"incisar",
"lobados",
"marchal",
"náilons",
"oricuri",
"quiriri",
"salafra",
"sangres",
"talando",
"tolares",
"trinava",
"voivode",
"volcado",
"xantoma",
"zipando",
"agnatas",
"agreira",
"andirás",
"antíquo",
"apícula",
"atadora",
"azeitam",
"bajamos",
"barrões",
"caleças",
"chagoma",
"chupito",
"chômage",
"eludido",
"espacie",
"espiono",
"excisar",
"excluas",
"holando",
"lapiana",
"moquear",
"redente",
"semeies",
"talados",
"telhais",
"teneném",
"trassem",
"agripas",
"altania",
"amanhou",
"amarilo",
"anteiro",
"barinel",
"broeira",
"cabulam",
"caolhas",
"crueira",
"dintéis",
"ensosso",
"esmaeça",
"ferrais",
"fineses",
"fisgara",
"linchem",
"látices",
"mocados",
"plancto",
"podasse",
"proface",
"reatava",
"rumemos",
"tambori",
"téspias",
"túrbida",
"vareios",
"votador",
"églogas",
"areento",
"avernal",
"boiasse",
"candeio",
"corrais",
"covites",
"desfase",
"diedral",
"dística",
"enturmo",
"flogose",
"idiotez",
"incivis",
"malambo",
"pocamos",
"polieno",
"pratões",
"regolfo",
"revesso",
"sovadas",
"traduze",
"urrador",
"vigeram",
"arribei",
"baquara",
"barreio",
"benzilo",
"cianido",
"crurais",
"danarem",
"embanda",
"gametos",
"ingride",
"jazesse",
"marante",
"modulor",
"osmanli",
"pantoea",
"pediosa",
"remasse",
"sistemo",
"virutas",
"brucita",
"casável",
"ciantes",
"cozesse",
"curiada",
"definis",
"derrear",
"gaivões",
"mnésica",
"podarei",
"polista",
"párvulo",
"raleada",
"rebolas",
"requira",
"ripário",
"sacolar",
"sapudas",
"sitaram",
"trovado",
"tulipão",
"vacilas",
"variais",
"abaixas",
"acamara",
"afadigo",
"alumias",
"amistar",
"arpoada",
"cavemos",
"comedie",
"courama",
"damista",
"desfaio",
"emperro",
"frautas",
"gabaria",
"gastamo",
"humanal",
"ilhense",
"lacaias",
"lamirés",
"latomia",
"lojecas",
"margeou",
"maurino",
"micaias",
"monsior",
"noúmeno",
"ogdóade",
"papocar",
"regiano",
"russano",
"rúpteis",
"trapada",
"virarmo",
"acinipo",
"atrelei",
"bambine",
"betunes",
"bovídea",
"canelar",
"caísses",
"derogar",
"desposo",
"engelha",
"engrado",
"folhuda",
"fruirás",
"glossas",
"jurares",
"landino",
"litores",
"longina",
"maretas",
"metilas",
"minerai",
"pipetou",
"propale",
"sadinas",
"telônio",
"turmada",
"távolas",
"tímicos",
"abajeru",
"abrigai",
"anidras",
"bisarma",
"burrões",
"canaris",
"capitei",
"cautchu",
"cendres",
"combros",
"criptos",
"derives",
"escodro",
"gelador",
"gramata",
"idiotes",
"ingênio",
"iologia",
"mimicar",
"oleação",
"pezudos",
"pitados",
"proseou",
"puxadão",
"quartal",
"racheei",
"rasquei",
"releres",
"ucraína",
"vivaram",
"acneico",
"aldrina",
"algodoo",
"apendeu",
"arrente",
"asfalte",
"brosses",
"caiaram",
"caudato",
"cavanha",
"chavosa",
"chichas",
"decorro",
"espojam",
"excrete",
"fruitas",
"fustigo",
"gatosas",
"interpô",
"lacerou",
"luxenta",
"mucunzá",
"nadares",
"niobato",
"ocelado",
"osciles",
"panázio",
"peidões",
"prístis",
"pássalo",
"rodarei",
"rufavam",
"tonadas",
"toucada",
"unistes",
"urgidos",
"zenetas",
"afirmai",
"apeavam",
"baixura",
"bendirá",
"cambões",
"cotador",
"crocita",
"ementes",
"escamou",
"escusem",
"fretava",
"jampear",
"leirião",
"manaram",
"mandola",
"mixomas",
"moganga",
"ocitano",
"pesadez",
"pifarem",
"rebolão",
"requero",
"resfrio",
"rizoide",
"salguem",
"tonarem",
"troveje",
"venerai",
"ônticos",
"abarcas",
"afoitou",
"aneiras",
"aviaram",
"babujar",
"béquico",
"cestões",
"copando",
"coxeira",
"datamos",
"datismo",
"deligou",
"descara",
"domavam",
"flameje",
"inicies",
"isídios",
"jejueis",
"malpara",
"metrado",
"obramos",
"pechado",
"prantei",
"quelhos",
"rabaçal",
"renoves",
"soteira",
"tapador",
"tontona",
"trainar",
"turacos",
"vetúrio",
"vimemos",
"abcázia",
"achatem",
"alcatre",
"apoiemo",
"apostia",
"açaimar",
"bassari",
"beijemo",
"botador",
"carburo",
"carpada",
"cornete",
"ecoloca",
"efundir",
"fixaras",
"fraudei",
"gibsita",
"janados",
"lardear",
"legarem",
"librina",
"lidaste",
"naifada",
"naninho",
"nivelei",
"petauro",
"plumado",
"primore",
"urupuca",
"xerocou",
"zeranol",
"amasios",
"ansiais",
"avieiro",
"barrito",
"brandou",
"calhada",
"calipto",
"censual",
"decoros",
"desates",
"entibia",
"estarde",
"fungosa",
"gabolas",
"gaseiro",
"indúsio",
"leirões",
"marmara",
"martita",
"migados",
"mossada",
"paelhas",
"pariria",
"retalie",
"ripinha",
"somonte",
"tamquam",
"tortuna",
"varadão",
"varasse",
"assomei",
"biatatá",
"bordana",
"cabeira",
"calarmo",
"caseria",
"causeis",
"cavouca",
"clorido",
"ejectou",
"enxugas",
"esbicas",
"esponta",
"fínicas",
"infeste",
"latejos",
"lavoira",
"lostras",
"mecinho",
"menfita",
"pandaro",
"podavam",
"raconto",
"servita",
"sonador",
"suaílis",
"suários",
"tairona",
"tenalha",
"tomação",
"vaiasse",
"verdino",
"anchara",
"auguras",
"baldosa",
"beluchi",
"blindem",
"cagaste",
"cansões",
"corruta",
"galerno",
"goivado",
"granças",
"isópode",
"jactais",
"joanica",
"lesamos",
"mombuca",
"pendule",
"pielite",
"pômulos",
"regalem",
"retados",
"selemos",
"serenei",
"sofreia",
"viroide",
"acordoa",
"amanado",
"casadão",
"chifram",
"chochar",
"costeio",
"culpeis",
"deturpo",
"edílica",
"elevemo",
"esbelte",
"firamos",
"fodidão",
"funfuns",
"hugoana",
"lineano",
"medicai",
"moldear",
"pelides",
"reterei",
"reunias",
"saudamo",
"sustava",
"triador",
"vulnere",
"afunile",
"alfares",
"almegue",
"alumiem",
"bastimo",
"beguino",
"cabeçou",
"caiador",
"carrilo",
"chaguei",
"degrana",
"delatei",
"devasse",
"empolas",
"entulhe",
"fremiam",
"félsica",
"gadeira",
"isódomo",
"luliano",
"mimasse",
"nostras",
"pedioso",
"quedamo",
"reagias",
"rociado",
"tabanga",
"tacasse",
"urticas",
"amuando",
"anuímos",
"badejas",
"betando",
"bostado",
"causeur",
"cerrote",
"corveja",
"cotiana",
"desatai",
"engulas",
"entelar",
"erguias",
"fumemos",
"lúnulas",
"mananas",
"metanos",
"mornado",
"morrões",
"mureína",
"nepotes",
"nácares",
"orotato",
"pepluns",
"pondoro",
"saqueei",
"tapares",
"taquear",
"uivados",
"afusões",
"aguaram",
"arubano",
"augural",
"avaliai",
"bolhões",
"bulcões",
"cricaré",
"cíprios",
"decanas",
"desboto",
"ditinho",
"fagales",
"fautora",
"hiantes",
"imputei",
"leceira",
"letrais",
"limento",
"louvemo",
"manibus",
"massado",
"maçados",
"muslins",
"obovado",
"redição",
"sefardi",
"xadreza",
"abrâmis",
"acharmo",
"acoplem",
"acossou",
"aduares",
"alector",
"aleijas",
"aliemos",
"andejos",
"aturara",
"balesta",
"bastita",
"bongada",
"bugalha",
"butenos",
"crocuta",
"dourina",
"elódeas",
"garrudo",
"geófita",
"gladiam",
"inditas",
"isótono",
"lampaça",
"laneira",
"lutavas",
"lúmpens",
"migalho",
"minarão",
"núncias",
"orçavam",
"revinda",
"roedura",
"salteou",
"terreio",
"vulpina",
"agnição",
"bambeza",
"barbeei",
"bardoto",
"batoréu",
"cabanal",
"chascos",
"crimine",
"cunetes",
"desalma",
"desunia",
"envasam",
"gomosos",
"grifons",
"litares",
"pactuei",
"pafioso",
"pelejem",
"pinasco",
"quínico",
"refulja",
"sucurus",
"sugaste",
"tribala",
"xeroque",
"acavalo",
"adarrum",
"alçaste",
"aponham",
"bagueta",
"calanga",
"caliche",
"cearmos",
"cromias",
"decúria",
"dubleto",
"engrade",
"filipas",
"fornico",
"gabação",
"macacal",
"macerou",
"maganas",
"mampara",
"manocas",
"marcamo",
"mofento",
"provéns",
"quimbos",
"racione",
"randevu",
"repelis",
"rimosas",
"rurbano",
"siluros",
"urzeira",
"armaras",
"arrotem",
"bramira",
"caracul",
"cavaloe",
"crenada",
"cárcova",
"dedinha",
"diurnal",
"embasei",
"entesta",
"eretiva",
"escólia",
"majoras",
"optação",
"pateado",
"pequemo",
"postema",
"recibas",
"residis",
"tóricos",
"urólogo",
"valasse",
"voejado",
"alfaraz",
"apoucam",
"armarei",
"aspília",
"azuline",
"batermo",
"descaça",
"dummont",
"durares",
"ecúmena",
"escacha",
"eteriza",
"evoluas",
"garroto",
"gomados",
"gonfose",
"gratino",
"ilírica",
"imantou",
"imitida",
"inculpa",
"jazeram",
"lábaros",
"miraria",
"mondada",
"montear",
"muleira",
"ofertai",
"palerme",
"passala",
"peguemo",
"pinicou",
"pugnada",
"raizame",
"regravo",
"senteis",
"septada",
"uretana",
"varador",
"zuniram",
"abarata",
"abitada",
"alistas",
"andinha",
"arramar",
"arrotas",
"assarão",
"atarmos",
"auletes",
"bolacho",
"butanos",
"chacuru",
"conflua",
"cíticas",
"elatine",
"emburro",
"empalam",
"fabulam",
"falucho",
"gabares",
"justado",
"laxidão",
"liberai",
"malunga",
"matense",
"pintalo",
"polposa",
"precedo",
"prurito",
"ratando",
"rogarão",
"rolhado",
"secutor",
"soterre",
"sápidas",
"tomante",
"urgiram",
"agradão",
"aleijem",
"açulava",
"brandal",
"brácaro",
"calmuco",
"cheires",
"cultuei",
"destete",
"efluído",
"empalma",
"estruir",
"fadaram",
"fastado",
"golpeai",
"incumbo",
"ituanas",
"jivaros",
"mucuras",
"notavas",
"passeai",
"pulcros",
"razoada",
"tucuxis",
"valeras",
"vínheis",
"abjugar",
"abênção",
"adoenta",
"agarimo",
"ameigar",
"antíopa",
"apreste",
"aquilar",
"bandeei",
"bracejo",
"catiana",
"dorisco",
"elícito",
"flóreas",
"guaratã",
"hárpago",
"impeles",
"injurie",
"laminei",
"lanosas",
"levação",
"logismo",
"meninez",
"nademos",
"osmoses",
"paangas",
"penique",
"podária",
"poleias",
"prantas",
"preador",
"proveis",
"queismo",
"ralhada",
"roçaria",
"subtela",
"supinar",
"tostava",
"tremais",
"vogamos",
"vástago",
"alemoas",
"amébica",
"banirão",
"caarina",
"cafunga",
"caiapiá",
"carates",
"chifrei",
"cumiada",
"delegas",
"dilênia",
"drapear",
"embocou",
"esbatam",
"escarre",
"esgarce",
"estígia",
"exijais",
"facunda",
"farroba",
"luddita",
"massorá",
"mateias",
"mugiram",
"mércios",
"orótico",
"pelaria",
"puceira",
"raffles",
"reginas",
"retrais",
"sambões",
"sanhuda",
"sidérea",
"silícea",
"soarmos",
"surriba",
"tanatãs",
"uveiras",
"violais",
"zombeis",
"abichou",
"alegros",
"aminada",
"antalas",
"apertai",
"arqueta",
"arretei",
"batavas",
"chantas",
"cruciar",
"descoco",
"embucha",
"enevoou",
"escampe",
"evecção",
"focemos",
"goleara",
"impinja",
"irarmos",
"jazerem",
"lopesco",
"mutondo",
"odiarmo",
"olearia",
"paixona",
"patorra",
"paulona",
"pintões",
"plosivo",
"roletar",
"sariema",
"serreta",
"sorvado",
"subsumi",
"suriana",
"vienesa",
"vélites",
"acômica",
"aleitou",
"bitonga",
"branqui",
"butando",
"bêntico",
"combogó",
"degelam",
"deletas",
"ejetivo",
"enjugar",
"enólico",
"excídio",
"falirão",
"fardava",
"gnosias",
"irrogar",
"jatadas",
"loreais",
"nevoado",
"obreias",
"pacaças",
"panaque",
"pedinha",
"piazote",
"pidonas",
"pimbado",
"ponhava",
"prótico",
"realças",
"remedar",
"timinas",
"torvado",
"aceptes",
"acineto",
"advocar",
"agireis",
"amputem",
"apêndix",
"areando",
"argiano",
"atroada",
"chuchou",
"cianina",
"cócaras",
"dicados",
"embuaçu",
"encocha",
"engodei",
"engrola",
"enlevas",
"escorça",
"esterce",
"forçamo",
"gamenho",
"itaciba",
"jugaram",
"ligames",
"luzidos",
"malucar",
"manavam",
"negrete",
"odorosa",
"recarda",
"reencha",
"rematas",
"remediu",
"secures",
"sofreou",
"telesto",
"trinche",
"tucumãs",
"vidicon",
"zoófito",
"álgidas",
"adornai",
"afluirá",
"anidade",
"apneico",
"caçoula",
"cobreis",
"embirre",
"empeçam",
"entadas",
"fibrino",
"flectem",
"gelinas",
"impando",
"pitavam",
"poliose",
"qualira",
"redanho",
"refilem",
"retumbe",
"setilha",
"sudoral",
"sujarei",
"sãmente",
"tateada",
"traspor",
"vênedos",
"adelito",
"amurado",
"apreçam",
"baforou",
"cachada",
"coserem",
"cupular",
"deamons",
"denigri",
"despeso",
"enraízo",
"esterre",
"flertem",
"jururus",
"jâmbico",
"lindado",
"livreta",
"nonadas",
"obsedia",
"olivedo",
"orelhar",
"oxidara",
"paludes",
"peidada",
"pertusa",
"pervaga",
"polutos",
"rascada",
"rateava",
"rechego",
"reponto",
"soferim",
"velemos",
"victron",
"abuscar",
"achates",
"acuseis",
"adormir",
"afoitam",
"ampular",
"apearem",
"avariem",
"baleiam",
"cafifas",
"ciciava",
"cotavam",
"doçarmo",
"embófia",
"escacar",
"esgarço",
"faustas",
"fraldar",
"laçaria",
"legares",
"litando",
"lucília",
"maceiro",
"mangada",
"ofuscas",
"ourados",
"pecante",
"reouver",
"soronda",
"xiranha",
"abismei",
"advogas",
"afundir",
"anelões",
"apiedem",
"aspeada",
"boscosa",
"cachado",
"casavas",
"corteis",
"eforato",
"grilhos",
"guitana",
"imundar",
"ligúrio",
"lorenos",
"malocar",
"marasca",
"melaria",
"milenas",
"monoico",
"mântico",
"notitas",
"onímodo",
"recapes",
"revezei",
"ripieno",
"rodilho",
"ronhosa",
"rotinho",
"samango",
"saranha",
"sarissa",
"sículos",
"tabicas",
"tegular",
"tiribas",
"toritos",
"abeiras",
"azedias",
"binodal",
"cacaual",
"cagalho",
"candial",
"coremos",
"cuprita",
"debutes",
"delidas",
"desfias",
"difamas",
"egótica",
"evenceu",
"expunge",
"eólitos",
"ilusiva",
"malacas",
"moqueio",
"oitavão",
"panarra",
"patatás",
"pavanas",
"pontoem",
"racines",
"roteiam",
"sangoma",
"seivoso",
"tangues",
"tilitos",
"tipinha",
"titulas",
"xeretou",
"zumbeis",
"ásteres",
"aberema",
"amerina",
"antando",
"aportas",
"atroava",
"azulego",
"baribas",
"boiante",
"buereré",
"cornuto",
"cotizou",
"defasou",
"desanco",
"enfrear",
"escreto",
"federas",
"gongues",
"jerarca",
"lombelo",
"magicou",
"meações",
"morocho",
"mundiai",
"naipado",
"olivara",
"pulcras",
"quentas",
"relampo",
"remitiu",
"repeles",
"ristina",
"sarador",
"taperão",
"taxitas",
"absolta",
"acalque",
"achacou",
"aleives",
"amaroso",
"aqueias",
"baiardo",
"barcane",
"bocetes",
"boídeos",
"cairias",
"capuano",
"caçanje",
"ciciada",
"efração",
"ejetiva",
"escavas",
"evoluta",
"facetar",
"hasteei",
"ilimite",
"irrompi",
"itaimbé",
"nadante",
"nocente",
"parteno",
"pelaram",
"poetado",
"raiavam",
"retenir",
"tefilim",
"tocanti",
"uivemos",
"velacho",
"aguarás",
"ancilas",
"apetado",
"arguira",
"atáxico",
"avosado",
"braquia",
"briônia",
"calmada",
"carpida",
"curiatã",
"cáfilas",
"delivro",
"depurem",
"erradão",
"escoima",
"esparra",
"estribe",
"exalçou",
"gueiras",
"haurira",
"homizia",
"humitas",
"iscando",
"lorgnon",
"mealhas",
"moraina",
"nevosos",
"novicio",
"origino",
"osmunda",
"penaria",
"poetada",
"preteou",
"raffiné",
"remitem",
"rimário",
"sapidez",
"tebaína",
"xadores",
"abatina",
"adimpli",
"adomado",
"aferiam",
"amarice",
"aranhou",
"bamboré",
"banirei",
"cachete",
"camucim",
"capsulo",
"chispou",
"cipista",
"cogidas",
"curiola",
"descore",
"eleveis",
"ementar",
"encacho",
"esbouço",
"eupneia",
"fatível",
"flébeis",
"fraxino",
"humiano",
"iluvial",
"infiram",
"inocule",
"landais",
"lavoras",
"lismara",
"mediras",
"moveste",
"oleicos",
"pulicou",
"queixal",
"questas",
"ranqueá",
"rasuram",
"regelar",
"remexam",
"remorde",
"rutenas",
"signada",
"televia",
"tenares",
"ternada",
"tremelo",
"xonadas",
"absorba",
"apoitar",
"ararita",
"atesado",
"atingis",
"avocará",
"bençoar",
"boletar",
"chucham",
"conduru",
"cumulem",
"desfear",
"desvive",
"distila",
"endeuse",
"enganai",
"ensaboo",
"frustes",
"gomitas",
"imigrem",
"inculpe",
"lenisse",
"luveiro",
"malsina",
"pifadas",
"porrões",
"prioral",
"proíbes",
"puxavas",
"párpado",
"quedona",
"ratados",
"sacarão",
"telando",
"abjuram",
"alindam",
"amicais",
"chapões",
"coisais",
"condido",
"cordina",
"curaras",
"getulos",
"ilírias",
"lanudas",
"luganda",
"macetar",
"manitos",
"miunças",
"mucubal",
"ostiais",
"paginou",
"paulama",
"perfaço",
"pifasse",
"puçanga",
"quaruba",
"rebelas",
"regagem",
"remoíam",
"remível",
"resonar",
"sarpado",
"sopeiro",
"sufusão",
"taipoca",
"ventada",
"acetina",
"afzélia",
"aliciem",
"amontou",
"aparate",
"astilbe",
"averbei",
"berlota",
"bolboso",
"cacular",
"cacusso",
"cazumba",
"centeia",
"certado",
"codilho",
"combuca",
"comorei",
"crasear",
"dizimei",
"efetuas",
"entalão",
"envolam",
"finismo",
"garçote",
"guizada",
"ilativo",
"indinha",
"jolando",
"lameado",
"magiado",
"meminos",
"mentire",
"numéria",
"plasmem",
"raizada",
"refutas",
"tacteei",
"tonasse",
"adicais",
"agastam",
"agostei",
"agrumes",
"alamano",
"amigaça",
"aramita",
"arverno",
"bitolam",
"bramiam",
"cantemo",
"cintilo",
"depenei",
"despela",
"estorço",
"estérea",
"expormo",
"floreie",
"frisara",
"futicar",
"galguem",
"irizado",
"labiada",
"lesiono",
"lágrias",
"mobilou",
"nascias",
"ovambos",
"pebrina",
"pirento",
"plagiem",
"recalco",
"refilas",
"revises",
"rurbana",
"sememas",
"trítico",
"tumbada",
"varejou",
"axônico",
"baraças",
"cartone",
"caçoilo",
"clavija",
"debrear",
"engonha",
"equedes",
"esmolou",
"fastios",
"fixagem",
"flamear",
"gripava",
"impores",
"itemize",
"locotes",
"mamares",
"mumbuca",
"nérvios",
"picarda",
"plutões",
"reimpor",
"rimance",
"romança",
"sucateá",
"síntipo",
"tomatas",
"troteia",
"viraras",
"abrasei",
"agulham",
"almugue",
"amaltas",
"astasia",
"bambula",
"banhamo",
"barbana",
"betonar",
"birreme",
"brajola",
"cadilho",
"carumas",
"caturro",
"clausal",
"cocaria",
"deserde",
"doestas",
"ejectam",
"emponto",
"encurve",
"expendi",
"facetes",
"forrara",
"galhofo",
"gavando",
"gerzeli",
"hastado",
"heredar",
"intimes",
"jogadão",
"lucerne",
"manguei",
"palmear",
"paredro",
"pintala",
"rodriga",
"ruivaco",
"tetamos",
"tipitis",
"traines",
"vasteza",
"zineira",
"afusado",
"anseies",
"aviavam",
"cansais",
"cutinho",
"cáudice",
"decimes",
"deprede",
"ditante",
"doastes",
"encardo",
"escames",
"espital",
"gaiares",
"iunense",
"melança",
"ninfeia",
"obrasse",
"ouvirmo",
"posteei",
"própole",
"pértiga",
"raparei",
"sapecam",
"saramos",
"serpear",
"someiro",
"sujaste",
"tuderte",
"tzarina",
"varanos",
"vestino",
"chanceá",
"charlei",
"comovas",
"deteres",
"develar",
"enodoam",
"flórula",
"fólicos",
"gamarem",
"geniano",
"glossar",
"goníaco",
"híspido",
"ióguica",
"licânia",
"macheia",
"malange",
"maninos",
"margaça",
"muceque",
"namores",
"nervino",
"patiras",
"pinante",
"ramosos",
"receder",
"ruminas",
"santões",
"topaste",
"trassar",
"vegetai",
"vendamo",
"viciamo",
"xalavar",
"zombais",
"aboneca",
"anestis",
"anoitei",
"aviarem",
"borrata",
"cambaia",
"cavilar",
"cegaste",
"chaveou",
"danamos",
"dulçora",
"embiara",
"esfigmo",
"estéria",
"filodes",
"fuxicou",
"havanas",
"joelhar",
"lancina",
"liseuse",
"majoria",
"maldivo",
"margosa",
"merendo",
"molejas",
"nasalar",
"pauzito",
"pipiras",
"premura",
"rabejar",
"remirou",
"revoava",
"sedeiro",
"tabulam",
"tatuara",
"trombão",
"verdemã",
"úmbrico",
"alaguei",
"alvazil",
"andoria",
"araruva",
"ariando",
"aurínia",
"cascate",
"cavarão",
"cicutas",
"condoes",
"copilou",
"cunhara",
"discões",
"déramos",
"elixido",
"enoques",
"ensaião",
"equídea",
"eunótia",
"exauram",
"famanaz",
"farreou",
"isentei",
"isolato",
"javaris",
"lacrara",
"larpara",
"letztes",
"marruás",
"pimpada",
"pomonga",
"pávidos",
"rangera",
"roselha",
"rufadas",
"tandens",
"tecoíta",
"tresleu",
"trezeno",
"urodela",
"usucapi",
"abatata",
"acordoe",
"ademane",
"bentana",
"bombara",
"cablado",
"chiadas",
"chuleia",
"clorite",
"coitais",
"corarão",
"corroas",
"cuscada",
"diplome",
"efetuai",
"folhudo",
"forídeo",
"friento",
"futuram",
"garvaia",
"gramoso",
"grilava",
"héctica",
"incendi",
"inânime",
"isoieta",
"japuíra",
"magoamo",
"pogônio",
"raminha",
"rearmou",
"redadas",
"restada",
"santigo",
"selarei",
"ticadas",
"tuitivo",
"valoras",
"viravas",
"virgule",
"acetilo",
"aguamos",
"alativo",
"alótipo",
"amuaram",
"anamita",
"arreias",
"ateimar",
"atoalha",
"atolara",
"besante",
"capinei",
"chuinga",
"educamo",
"encetem",
"encorna",
"engrace",
"errador",
"espoado",
"formila",
"frouxel",
"gritais",
"grivado",
"intermo",
"inçados",
"lesasse",
"luética",
"marouço",
"mujimbo",
"obstine",
"parajás",
"peróneo",
"populou",
"recetar",
"ressair",
"seletes",
"sorrida",
"tateias",
"tauista",
"tendeis",
"testear",
"topares",
"turinês",
"vetarão",
"afundes",
"alígero",
"bougies",
"béticos",
"delidos",
"dentudo",
"departo",
"estruma",
"falacio",
"funchos",
"goletas",
"injecto",
"jubilei",
"laborão",
"laudina",
"lepidez",
"margine",
"milando",
"neótipo",
"odoroso",
"pariana",
"pernear",
"posarei",
"preteje",
"pupário",
"reobter",
"revelho",
"reviria",
"surrara",
"tarrafe",
"tonteei",
"troncas",
"valeste",
"aclarem",
"ardeste",
"assomes",
"bagadão",
"barcana",
"barrais",
"capurro",
"carioba",
"conotou",
"corrica",
"cuidosa",
"dartros",
"engomou",
"esquino",
"exarcas",
"frature",
"fumarão",
"futuque",
"isatina",
"isolamo",
"jurumim",
"límulos",
"meiinho",
"morraça",
"muxibas",
"napista",
"parrada",
"parrana",
"pejavam",
"periche",
"pérvios",
"salival",
"sienesa",
"sudando",
"teísmos",
"trelosa",
"agastes",
"alforva",
"atochou",
"berebas",
"bromuro",
"broxura",
"bueiras",
"calabre",
"cavaloa",
"ceviana",
"chalada",
"chapina",
"cobrões",
"delivre",
"demudou",
"empeçar",
"faixeta",
"galenos",
"hásteas",
"incisai",
"lambias",
"leirado",
"louçana",
"mentona",
"moletes",
"motobói",
"ornaria",
"osculam",
"paganel",
"pascigo",
"pilarte",
"previda",
"rapante",
"rebemos",
"remoçam",
"samatra",
"topados",
"torçais",
"trainel",
"tuitiva",
"achoado",
"acometi",
"ailuros",
"alélico",
"anuírem",
"asneado",
"assuado",
"capeada",
"cequins",
"cervelo",
"ciciado",
"ciscada",
"corcovo",
"criante",
"criseno",
"crisóis",
"decanal",
"espigam",
"ficinas",
"folhava",
"gizaram",
"grumatá",
"guabira",
"lavorar",
"merinas",
"miópico",
"muraram",
"nandina",
"nômenos",
"opiatos",
"pitango",
"servais",
"sesmado",
"tarbush",
"trengos",
"ungidão",
"urrarem",
"acaules",
"achadão",
"acidula",
"acronia",
"aguazil",
"alourem",
"ambreta",
"anhanha",
"arcanes",
"basofia",
"bassula",
"besoura",
"caberei",
"cambapé",
"campeio",
"capatas",
"carnita",
"chopins",
"cubadas",
"diferes",
"figares",
"frouxar",
"fulgiam",
"gasnete",
"lancear",
"lidimar",
"liriais",
"maleína",
"maçunim",
"meiotas",
"paparem",
"petecar",
"rafiado",
"rebando",
"reenche",
"reimosa",
"rimarei",
"rixosos",
"sobrais",
"sublide",
"torrara",
"torônio",
"vajamos",
"vexilos",
"ástures",
"amiúdam",
"baldava",
"bongará",
"caftina",
"calemia",
"catucou",
"caçares",
"comicha",
"cumulei",
"depreca",
"embuias",
"empeste",
"enredem",
"escotes",
"estriça",
"federia",
"fulniôs",
"férulas",
"grelada",
"hepteto",
"iligado",
"inédias",
"lalaria",
"libando",
"logasse",
"mimento",
"musitas",
"mutambo",
"paginam",
"pilhara",
"pipiles",
"pirênio",
"prenses",
"proteia",
"rapinou",
"reluzam",
"suassem",
"sujemos",
"tabulei",
"topadão",
"tétrodo",
"acessoe",
"acinosa",
"adverto",
"afebris",
"amarosa",
"apensou",
"aplanou",
"avessar",
"bagasse",
"bicotas",
"botaste",
"básculo",
"cagarão",
"calques",
"chucras",
"cupidas",
"degluto",
"dimorfo",
"ermando",
"escuses",
"faixado",
"fenecos",
"glicano",
"leitado",
"lucarna",
"maurina",
"menorás",
"mindins",
"mofumbo",
"monoica",
"mosquei",
"nardino",
"oponhas",
"picueta",
"pinchou",
"pululou",
"purgara",
"quadres",
"roleiro",
"sórbios",
"taxarão",
"trelada",
"trinfou",
"veletas",
"xerogel",
"adenias",
"adernos",
"alheiem",
"amodelo",
"antécio",
"avalade",
"canetou",
"carpins",
"chamiço",
"coatado",
"célicas",
"cógnito",
"diéster",
"emulara",
"entorpe",
"genéria",
"límnica",
"maldada",
"manejei",
"minerou",
"naturar",
"novelar",
"ondeles",
"pacajás",
"picares",
"piraras",
"porrudo",
"recamar",
"reocupe",
"repesco",
"rinchão",
"roxinol",
"sorride",
"suturam",
"tolerai",
"torador",
"tuscano",
"valides",
"vaquice",
"xitique",
"acordoo",
"adiafas",
"algoses",
"apejado",
"apelais",
"armarmo",
"asserte",
"bonetes",
"brechão",
"cabanes",
"colotes",
"correão",
"defumou",
"desobra",
"difrata",
"ditarei",
"empapam",
"engamos",
"ervilho",
"escopia",
"estrige",
"fanfara",
"filosia",
"flocoso",
"forceis",
"frenais",
"gaviona",
"glícido",
"havanos",
"ideando",
"iáziges",
"latejem",
"librará",
"listeis",
"muflões",
"mussala",
"numerem",
"ornasse",
"passaje",
"placada",
"saveira",
"sobejem",
"supinas",
"unítono",
"vingues",
"aboceta",
"afancar",
"ajuntes",
"aliciei",
"alícota",
"aplacas",
"araçaji",
"aromada",
"bodeado",
"cangado",
"cheches",
"curtias",
"demovam",
"epímero",
"exiliar",
"foçador",
"gestuno",
"golfões",
"hipógeo",
"luteais",
"merceia",
"miraste",
"notador",
"ovinhas",
"paveias",
"pelavam",
"pespego",
"povinha",
"rajadão",
"referre",
"reunamo",
"safemos",
"tacante",
"tanocas",
"tenazas",
"ungiria",
"verdeou",
"adicada",
"afonias",
"antomio",
"aparara",
"aristol",
"asseriu",
"bárbula",
"cabozes",
"cavucou",
"cercais",
"conquém",
"coriais",
"coutado",
"durados",
"empupar",
"enigmes",
"enramar",
"evolvam",
"firmano",
"gavotas",
"gemerei",
"gradado",
"grifava",
"guanaré",
"ilidido",
"jipioca",
"lotinho",
"lábdano",
"líquene",
"micadas",
"mucamba",
"noturas",
"ovídeos",
"pantins",
"piadita",
"pinocar",
"pinçava",
"pânicas",
"recitai",
"restito",
"rilhava",
"roussos",
"saturas",
"solavam",
"sujadas",
"urálico",
"adelfia",
"adjurar",
"afânise",
"alvazis",
"amoitar",
"careado",
"colecte",
"célibes",
"deflaga",
"embaúva",
"envidei",
"ervadas",
"esvoace",
"farpões",
"fuxicam",
"gaseada",
"golilha",
"longado",
"nomisma",
"pilitas",
"pitocas",
"políade",
"pértica",
"píxides",
"realgar",
"reduzis",
"rezavas",
"saciemo",
"sarrava",
"sinalam",
"sloânea",
"tatalar",
"velavas",
"vintões",
"abjurei",
"alegais",
"alvanéu",
"amolgou",
"arpejar",
"atacoes",
"ateleia",
"bajulei",
"bangula",
"bolçado",
"borraça",
"cascado",
"cimosas",
"copulem",
"deperda",
"dicaram",
"ervagem",
"espoque",
"estuoso",
"furteis",
"gandrês",
"garudas",
"goetita",
"liburna",
"lucidar",
"modinho",
"objetem",
"paludos",
"parlava",
"parálio",
"poeriam",
"polpada",
"posteis",
"recluta",
"taburno",
"tantito",
"uretras",
"abortas",
"abrigoe",
"albanas",
"amansem",
"aromado",
"arraras",
"becapes",
"casteio",
"depuser",
"devação",
"diciclo",
"dirimia",
"décupla",
"filadas",
"fitaria",
"guiavas",
"líparis",
"maginem",
"maldiva",
"maração",
"marroco",
"migadas",
"milésio",
"miríada",
"netense",
"nojices",
"obovada",
"pasciam",
"pixídio",
"posante",
"potrada",
"remados",
"remiram",
"retesei",
"sororal",
"timburé",
"trepide",
"tronará",
"uivamos",
"acapara",
"adensem",
"afanava",
"agridas",
"alviões",
"amontam",
"atascou",
"axênico",
"azoteto",
"barline",
"beijoim",
"belense",
"bilunga",
"bígamas",
"caniças",
"caragos",
"clamais",
"conetam",
"cotinga",
"coxeiam",
"custada",
"disquem",
"diteiro",
"doirava",
"eonismo",
"equitas",
"eretora",
"etagere",
"fumaçou",
"galetas",
"graniza",
"incasto",
"indagai",
"isóbara",
"legasse",
"lobrigo",
"lonitas",
"maculei",
"mancara",
"mezinho",
"mássula",
"penedas",
"pretões",
"quelada",
"subjaze",
"surdiam",
"taninas",
"trifana",
"tropias",
"vexadas",
"vogados",
"adviera",
"africam",
"anambés",
"araques",
"aristus",
"açórica",
"bambala",
"bodeada",
"bufarem",
"cadinha",
"cangira",
"contemo",
"dourara",
"empenei",
"encomia",
"enfesta",
"estalai",
"flóculo",
"gomense",
"gordaça",
"grolado",
"mangado",
"membeca",
"oaristo",
"palhões",
"princês",
"prologa",
"puinave",
"reavida",
"regenta",
"repulse",
"revigor",
"titista",
"trenado",
"urramos",
"vernais",
"úgricos",
"acuavam",
"amealhe",
"amiguei",
"antevir",
"arrojes",
"bolsava",
"branhas",
"brucela",
"cataguá",
"colhudo",
"crivava",
"degases",
"depares",
"desteto",
"deífico",
"djumbai",
"egitana",
"eligido",
"embuçar",
"enólica",
"gróvios",
"imporás",
"jagudis",
"jugosas",
"levedam",
"locaria",
"louvais",
"lócrios",
"manante",
"mandora",
"palrear",
"parolis",
"peixote",
"peteiro",
"predico",
"quitaba",
"raçudas",
"relexos",
"renatos",
"safarão",
"tacados",
"trovava",
"trupial",
"turmado",
"votaras",
"abcisão",
"abelhal",
"abertal",
"aboiado",
"adjetos",
"adortei",
"aformou",
"agachas",
"alabaça",
"aluando",
"amerita",
"amostar",
"atonias",
"bispote",
"bixeiro",
"boradas",
"brancor",
"cagarei",
"carpiam",
"cavitar",
"dêutero",
"farreia",
"farroma",
"fendiam",
"ficelle",
"flotada",
"geleiro",
"hasteie",
"ilimita",
"lenindo",
"linhite",
"luxados",
"melasmo",
"nevosas",
"pireneu",
"polábio",
"pártica",
"redendê",
"remaria",
"repores",
"reviras",
"sulfure",
"surfará",
"taponas",
"trauteá",
"trucide",
"tungues",
"virtuar",
"afélios",
"alunada",
"amarido",
"amatara",
"binitas",
"britana",
"canalar",
"chantel",
"cobrimo",
"cogidos",
"deramar",
"diabada",
"drogues",
"emulsor",
"enricam",
"esgrimi",
"gradaús",
"grupara",
"jungues",
"labaçal",
"lampiro",
"litação",
"matante",
"minimar",
"miótico",
"novídeo",
"obregão",
"olbiano",
"pongues",
"porteio",
"precedi",
"pulvino",
"puxante",
"pântala",
"pêndola",
"rebecas",
"ressaco",
"rositas",
"rufares",
"saneiam",
"sobrade",
"sombram",
"varanga",
"volares",
"abocada",
"aceirar",
"acícula",
"aguerri",
"alfarge",
"alvense",
"amuamos",
"avocava",
"azarava",
"batição",
"blusona",
"cambras",
"campara",
"cegarão",
"centola",
"cortemo",
"dirimam",
"escando",
"estapor",
"exerças",
"extrata",
"fruísse",
"gozavas",
"grupões",
"latenho",
"mucumbu",
"nepente",
"niquito",
"opondes",
"picheis",
"pitacas",
"plácito",
"primina",
"rarefez",
"recolou",
"releves",
"repegar",
"retomes",
"rosalva",
"sitamos",
"snifado",
"sonetar",
"sopresa",
"troncão",
"turnedô",
"uivasse",
"ursinas",
"actinas",
"angolês",
"autuais",
"avenham",
"bordear",
"butucas",
"capacha",
"coicear",
"couplet",
"cábreas",
"dotador",
"encarai",
"esbarar",
"espleno",
"esponjo",
"filenos",
"gingões",
"invetei",
"jacamar",
"larvada",
"limarem",
"manuéis",
"mareiro",
"matolão",
"medalhe",
"michamo",
"nacarar",
"orciano",
"ovelhum",
"pernona",
"polição",
"polêmia",
"poupuda",
"recarei",
"rederem",
"regares",
"repanda",
"retadas",
"sebasto",
"sesamol",
"silvita",
"solapem",
"varejas",
"venceis",
"vetação",
"vexando",
"voyeuse",
"abegões",
"afinfou",
"arderei",
"atraias",
"atulhou",
"baitatá",
"caculos",
"caiotas",
"catalpa",
"cederas",
"chameio",
"coroais",
"crepons",
"debande",
"demisso",
"detonas",
"dolinha",
"eluidor",
"empasto",
"ensossa",
"gatismo",
"heroide",
"imanava",
"interma",
"isidora",
"lipotes",
"oliveta",
"ostinho",
"palitou",
"quotada",
"refazei",
"regeras",
"relenta",
"socação",
"solaram",
"susiana",
"tabelie",
"taladas",
"tararão",
"tenteia",
"tetaram",
"troavam",
"trípolo",
"urólito",
"valésia",
"vidonho",
"aldeana",
"alexias",
"alhanar",
"apósito",
"armante",
"armeira",
"açoutar",
"beetria",
"bengalé",
"bichosa",
"carlino",
"casetão",
"colhada",
"cubicar",
"desceis",
"diorita",
"ementou",
"empenem",
"empinem",
"enfunou",
"enregar",
"enxerir",
"fanaram",
"folheca",
"furicos",
"gamarão",
"ganhemo",
"ganizar",
"guidara",
"isóloga",
"locavam",
"lucilar",
"marador",
"marrara",
"miótica",
"morfado",
"nepenta",
"pendias",
"pezadas",
"poupemo",
"puteira",
"regatou",
"regaçou",
"regrais",
"retando",
"singrei",
"sombres",
"talcose",
"tótemes",
"vingamo",
"acossai",
"afiliem",
"agenora",
"agudize",
"alentei",
"amídica",
"bavetes",
"birmana",
"bogalha",
"cazumbi",
"colobos",
"cunhete",
"eireira",
"estocas",
"esvaeça",
"evitais",
"frescou",
"graduem",
"hipotes",
"héctico",
"lordeza",
"mandeus",
"maricos",
"matulas",
"metrias",
"mulando",
"paulado",
"pialado",
"plainam",
"plisses",
"sedemos",
"semelhe",
"siderou",
"surgias",
"taditas",
"tinirão",
"torneja",
"téspios",
"voejava",
"xeroxes",
"acanhei",
"aconche",
"alargai",
"alcoice",
"alimado",
"caritos",
"ceroide",
"citomas",
"cocriou",
"curavas",
"desaste",
"descano",
"enceste",
"fadigue",
"geófito",
"hamitas",
"hiatais",
"justapô",
"laborei",
"legavam",
"lonadas",
"mabaças",
"medites",
"murismo",
"muzenga",
"negarmo",
"patágio",
"peliose",
"pondras",
"púbrico",
"regouga",
"repediu",
"rifenho",
"roupado",
"segarem",
"simulas",
"sombreá",
"tangera",
"taxável",
"tiriças",
"turados",
"turbava",
"xantato",
"zonária",
"abaruna",
"aboiava",
"abrojos",
"acasalo",
"acoelho",
"aljamia",
"anuísse",
"apenhas",
"bacáceo",
"bodeiro",
"calunio",
"catódio",
"chamega",
"champas",
"clocado",
"condida",
"craveja",
"crótalo",
"cucumbi",
"cípsela",
"detrais",
"dioicia",
"estafou",
"excusou",
"farejem",
"frontar",
"jurutau",
"limador",
"macotas",
"maleada",
"mamaras",
"ogâmico",
"percado",
"perinde",
"pichara",
"pompear",
"raiaram",
"redimes",
"rematem",
"remixei",
"saralho",
"supurou",
"treitas",
"trismos",
"volvemo",
"alílica",
"amontoo",
"aperrei",
"arminas",
"arrinca",
"assoada",
"bambeio",
"bisados",
"bombeei",
"bufamos",
"cagares",
"caiabis",
"cambiei",
"carilha",
"coladão",
"coseres",
"defumam",
"dicador",
"enxotem",
"eventre",
"feneçam",
"fontano",
"fungais",
"impluda",
"iquitos",
"jirimum",
"lanceia",
"lepiota",
"maceiós",
"malanje",
"mandeia",
"neveiro",
"nucleão",
"obturou",
"osseína",
"patolar",
"peléxia",
"perolar",
"polirem",
"premira",
"rapioca",
"rocalha",
"sanarei",
"teteias",
"torremo",
"volcada",
"afarias",
"agoniei",
"amealho",
"aperado",
"asepsia",
"asseite",
"caiçaca",
"canjira",
"clxxxii",
"emborco",
"encovar",
"enredas",
"estriei",
"estrumo",
"fujimos",
"ganesas",
"gordote",
"graveza",
"hauriam",
"incisor",
"inferis",
"jobaria",
"labaças",
"laboras",
"lindada",
"lotemos",
"mainéis",
"malicio",
"mareiam",
"miópica",
"mucunas",
"nalgada",
"nevaria",
"nichado",
"palanco",
"palhoso",
"penchas",
"penicar",
"plagais",
"podange",
"recluse",
"refolho",
"ressone",
"revoque",
"rizando",
"ruivaca",
"sismava",
"stacato",
"sugeris",
"tarafes",
"teclear",
"tentena",
"tigelão",
"toraram",
"trigada",
"tubaras",
"ucaiali",
"unhonas",
"uralita",
"urtigal",
"vajroli",
"afincos",
"agrante",
"andeira",
"aprumem",
"baracha",
"bolante",
"cianite",
"cilista",
"colemos",
"concite",
"condito",
"cuprina",
"decepai",
"dieteta",
"duatlos",
"encampo",
"enrasca",
"espigou",
"estafes",
"exponde",
"gabiano",
"gentuça",
"géstica",
"ignavos",
"inquere",
"isotron",
"joseíta",
"landres",
"ligueis",
"longana",
"lusitos",
"manjaca",
"meeiras",
"memorio",
"mijaste",
"mirolho",
"palmais",
"pardala",
"pelamos",
"penível",
"priorar",
"repesar",
"sigilar",
"sovamos",
"surrapa",
"tambuca",
"vendeis",
"verrumo",
"ístmico",
"abarbar",
"abelina",
"abnegou",
"achanar",
"afobava",
"apetres",
"apódose",
"bichoca",
"bordeia",
"bélgico",
"caborés",
"cabrado",
"cainhos",
"camoesa",
"carenar",
"catembe",
"ciscado",
"coreira",
"delindo",
"embrago",
"esteias",
"execrem",
"felando",
"fraseou",
"fúlgora",
"gamamos",
"gatorro",
"glissar",
"idearam",
"ilocano",
"imponto",
"indexei",
"lucente",
"mambira",
"manguzá",
"matapis",
"mirreis",
"ondulas",
"paranaí",
"presigo",
"pórfido",
"quilhar",
"ralaria",
"recalar",
"redibir",
"rodemos",
"roubamo",
"rubrico",
"rutilam",
"saudemo",
"sevicio",
"somante",
"tabules",
"tapeava",
"teolina",
"tigrino",
"torceis",
"virtais",
"víricos",
"abitava",
"abáster",
"actínia",
"adunada",
"antense",
"aramaré",
"carcino",
"cefalia",
"deduzes",
"esfalfe",
"esquiça",
"exortas",
"guajuru",
"insulam",
"laceiam",
"laiciza",
"ligário",
"loquete",
"magiada",
"merdona",
"mucanda",
"muculos",
"nevante",
"opinoso",
"pigarça",
"polutas",
"polínea",
"quilaia",
"reatora",
"recurse",
"refundi",
"sabujar",
"saidões",
"sariano",
"solador",
"tibares",
"titanos",
"tárraga",
"vistara",
"zaidita",
"ádvenas",
"abeirem",
"alumiai",
"arrelio",
"avariei",
"baleais",
"boscoso",
"calejei",
"devimos",
"duvidai",
"elítico",
"engripa",
"ervanço",
"faltais",
"figurai",
"flectia",
"gingiva",
"guaribu",
"guiarmo",
"horados",
"itapeba",
"lambaru",
"luramos",
"netsuke",
"noivava",
"nudezes",
"obovais",
"obsedam",
"ocráceo",
"pecável",
"piloros",
"pinenos",
"porteis",
"prantou",
"prevido",
"puranga",
"pícnico",
"revóvel",
"roborar",
"rociada",
"sornice",
"surtará",
"tesinho",
"tigreão",
"trianta",
"urradas",
"varemos",
"vidanas",
"áulicas",
"alfurja",
"anichou",
"apagais",
"atenaza",
"aterias",
"autuava",
"averano",
"caidona",
"chitada",
"cincada",
"clxxvii",
"culmino",
"defluiu",
"deporei",
"distara",
"duremos",
"espojas",
"esponje",
"estanga",
"fastada",
"fermião",
"festava",
"foderei",
"fonados",
"frigias",
"fungoso",
"galício",
"grossor",
"gálagos",
"implodo",
"lavrita",
"lirista",
"macamba",
"marimbo",
"muzungo",
"obstrue",
"orários",
"paninos",
"pirajás",
"pungida",
"quarado",
"recatem",
"rixento",
"stigmas",
"tonaria",
"tugiram",
"ungirão",
"urânico",
"veneral",
"acateis",
"alacrau",
"alforfa",
"alonsos",
"ambrona",
"aroeiro",
"búnquer",
"cedível",
"cimados",
"decotas",
"dentola",
"ejetara",
"encavou",
"enfeado",
"epíploo",
"esporem",
"estafam",
"estardo",
"fedains",
"fitaste",
"fugante",
"furante",
"ganheis",
"incites",
"inflexa",
"irrompo",
"jeitado",
"lixosas",
"madalas",
"meieiro",
"oraliza",
"papalva",
"partina",
"pencudo",
"pestoso",
"pixains",
"pomonas",
"secunde",
"sorando",
"trotado",
"untarem",
"urdimos",
"valermo",
"valvado",
"vilagem",
"vomites",
"voraces",
"vulgado",
"achante",
"amiúdas",
"ampliai",
"apsarás",
"atingas",
"atopado",
"baldeou",
"bongado",
"brucina",
"bulisse",
"cainana",
"cananga",
"chincho",
"cismara",
"criável",
"cádmios",
"debatas",
"enjeite",
"entalem",
"epífano",
"estorne",
"fiacres",
"fodamos",
"gascona",
"gemelgo",
"glutine",
"gonagra",
"ilírico",
"imanado",
"lambeta",
"lassear",
"luchará",
"marmoto",
"marrava",
"mundele",
"nhaneca",
"oscense",
"palizas",
"pisaura",
"poitada",
"poliria",
"puteada",
"rechupe",
"reforja",
"remedir",
"revindo",
"simbola",
"tapitis",
"totoras",
"urdumes",
"urinara",
"vareiam",
"volatas",
"acadado",
"aciones",
"agitamo",
"airados",
"algazel",
"alódios",
"ambular",
"amomais",
"anúduva",
"apicado",
"arrupio",
"ataviam",
"atirais",
"baglama",
"cadelos",
"catares",
"cavocou",
"caxinde",
"coligia",
"copitas",
"especar",
"estofou",
"exempto",
"flechal",
"grelhem",
"guilhos",
"hetares",
"irênico",
"jocunda",
"landras",
"maracha",
"mimaria",
"mofador",
"motoros",
"neonata",
"novando",
"orlavam",
"podécio",
"porpita",
"releias",
"segaram",
"serpeja",
"sesotho",
"sulcara",
"talauma",
"temisto",
"tiotepa",
"turando",
"vestigo",
"vestina",
"afincou",
"agílimo",
"alegues",
"algente",
"apremai",
"assolho",
"bilosca",
"carreio",
"cataris",
"clássia",
"elogies",
"engados",
"escudem",
"favismo",
"faxinou",
"federou",
"formose",
"fretara",
"gigaton",
"gloriei",
"griteis",
"libatas",
"loquela",
"léleges",
"macelas",
"obstino",
"opilado",
"oscilas",
"parento",
"perados",
"picarão",
"piratar",
"rabalva",
"rodaste",
"roubeis",
"sideram",
"sopesam",
"tebenas",
"tofácea",
"torrija",
"treição",
"uranita",
"vedália",
"velaste",
"zincato",
"agrafei",
"agrupas",
"aguaçal",
"almazém",
"amoedar",
"apicada",
"apoiais",
"aponcho",
"babemos",
"cambuta",
"cancera",
"catetes",
"conguês",
"crespão",
"efabula",
"elativo",
"embuçam",
"engomam",
"especas",
"estresa",
"eternar",
"falenos",
"floxina",
"galispo",
"genária",
"humanar",
"medeiem",
"mijação",
"moledos",
"natário",
"nudezas",
"omanita",
"patanes",
"pineira",
"pisguei",
"rosista",
"rufarem",
"saltais",
"seciona",
"simento",
"sitioca",
"situemo",
"tribase",
"zafiras",
"írritos",
"achicar",
"adamane",
"alfaqui",
"anjolas",
"apricar",
"arregle",
"autopse",
"bararuá",
"beijoco",
"caládio",
"cobrida",
"coirama",
"coropós",
"cristel",
"desmede",
"desosso",
"dupleto",
"encheio",
"encimou",
"enlatou",
"estiolo",
"gaviola",
"geminou",
"gomeiro",
"gorjeou",
"grigris",
"induzes",
"lesadão",
"límicos",
"mandaré",
"maçarão",
"molismo",
"norteei",
"orangos",
"otólito",
"pigasse",
"pingões",
"pipadas",
"pipilam",
"prolate",
"propeli",
"rabicha",
"rapinei",
"refilia",
"relíqua",
"repinto",
"revirão",
"sarrado",
"serioso",
"sinúsia",
"sulanos",
"torneie",
"triarca",
"triares",
"tropano",
"tuparis",
"verbero",
"zoisita",
"alçarei",
"angario",
"apodava",
"bigudis",
"cacetar",
"caraúna",
"cervino",
"coaxado",
"corisca",
"curetar",
"dessora",
"encamar",
"escorão",
"esfrias",
"facione",
"ferezeu",
"flipada",
"fogagem",
"fortace",
"gavetos",
"globias",
"imperas",
"isauros",
"jamaxim",
"judicie",
"liberes",
"lizador",
"micenas",
"mônicas",
"nônuplo",
"oogônio",
"quadrou",
"raparia",
"recavar",
"retesas",
"roessem",
"rogador",
"rosnara",
"safaras",
"sobraça",
"subsome",
"talosas",
"tamburu",
"taminas",
"tascado",
"temposa",
"abexins",
"acanela",
"aguarem",
"ameixal",
"anodiza",
"arrotes",
"astrevo",
"baleato",
"balotes",
"casalar",
"cascava",
"cimadas",
"corniso",
"cuneada",
"cuzadas",
"debruam",
"divisem",
"diábase",
"edutora",
"encinta",
"endento",
"enfolar",
"esquias",
"flecham",
"formeis",
"galasse",
"gazeava",
"glaçada",
"inative",
"indicai",
"isabeis",
"latimos",
"mandubé",
"megaohm",
"negalho",
"ninasse",
"ovulada",
"panteia",
"pinotar",
"pregoam",
"proibis",
"relicta",
"retrava",
"revelha",
"rolotês",
"sanarão",
"soqueia",
"tamoatá",
"topazes",
"umbrina",
"veeiros",
"viajais",
"zuninga",
"acciono",
"acetoso",
"adanais",
"agostas",
"aprazou",
"arrabar",
"azulita",
"bacacas",
"batimos",
"beiroas",
"coreico",
"cortamo",
"crestam",
"decania",
"degrane",
"educais",
"empuxão",
"eternam",
"evocais",
"guinhol",
"hílicos",
"jalofos",
"larvado",
"lebréus",
"mandubi",
"mangano",
"mentila",
"mungido",
"míldios",
"paulzão",
"pavidez",
"ponhema",
"ponteei",
"premias",
"procrio",
"ralenta",
"ramisco",
"simplez",
"sorares",
"tejuaçu",
"teáceas",
"valsada",
"vexavam",
"aberrar",
"acerina",
"agouram",
"amandam",
"amarava",
"amídico",
"anísico",
"apupava",
"aquilho",
"arrabio",
"biófilo",
"cagaréu",
"debicou",
"degelou",
"desunho",
"emassei",
"embicam",
"empoçam",
"empoçou",
"emílios",
"encrise",
"epulões",
"erosada",
"estatue",
"filídio",
"forjais",
"gemando",
"ibadita",
"maneado",
"mariqué",
"mixoide",
"mornura",
"mortaço",
"muscais",
"niquice",
"novicia",
"nuinhas",
"padrear",
"pedâneo",
"peleiam",
"pequira",
"refines",
"relampa",
"repleno",
"sanchos",
"surrate",
"tabaque",
"tilinte",
"tégmina",
"usurpas",
"verseje",
"abatoca",
"aferroa",
"alfafas",
"axântis",
"azurrar",
"blasona",
"bocagem",
"cabilda",
"chuleco",
"corremo",
"corteio",
"curvemo",
"debamos",
"enconar",
"escreta",
"espeita",
"facomas",
"gemeste",
"guerreá",
"hipópio",
"inhanha",
"lecitol",
"lobense",
"mangole",
"mexeção",
"mirense",
"ofertes",
"padeças",
"papador",
"pralina",
"premuda",
"renatas",
"sanjaco",
"sompara",
"taludas",
"toleres",
"tubinha",
"acabelo",
"acamava",
"alterca",
"amebita",
"anelais",
"atearei",
"babares",
"barcada",
"camilas",
"cervela",
"chalrar",
"cingimo",
"combale",
"coresma",
"coroços",
"digonal",
"encioso",
"encucam",
"enfreia",
"esboroe",
"fisália",
"ganhoso",
"guaiaba",
"imácula",
"inalara",
"insolar",
"intexto",
"largamo",
"legarei",
"lixento",
"lumines",
"lutécia",
"machear",
"marando",
"matacos",
"nervada",
"nevoada",
"padrada",
"paparia",
"pardaus",
"piauçus",
"prólico",
"quiquei",
"rotalas",
"saltite",
"saremos",
"tatuaçu",
"vagaras",
"valacos",
"vararem",
"xipaias",
"adiares",
"afofava",
"aliáceo",
"anonime",
"aspados",
"brêtema",
"bárdico",
"bísaros",
"cafuzas",
"caldosa",
"cerdosa",
"clxviii",
"clxxiii",
"coeduca",
"crômica",
"cutilas",
"cárcava",
"eluvião",
"endorsa",
"esmolem",
"etóxido",
"everter",
"feridor",
"finesas",
"flocosa",
"gestara",
"impedis",
"invitas",
"jazeras",
"lurados",
"maramos",
"merquei",
"moscões",
"navalho",
"ninavam",
"novador",
"oitubro",
"pactuai",
"papisas",
"penamar",
"poilões",
"quarava",
"relente",
"resalte",
"retomai",
"retorto",
"salvina",
"santons",
"soltamo",
"sutures",
"troaram",
"uparmos",
"velidas",
"vivaces",
"aguazis",
"apremio",
"ariadas",
"atoledo",
"bacadas",
"bardais",
"bisnaus",
"botanas",
"camapus",
"carneou",
"derribe",
"dúlcido",
"eduzido",
"enfardo",
"epitoma",
"esporou",
"exultes",
"fademos",
"feijoas",
"filaram",
"finasse",
"folidol",
"freimas",
"garreia",
"guarema",
"hídrias",
"inúbias",
"mariais",
"mariama",
"melasse",
"merleta",
"mirrava",
"mundéus",
"opacado",
"parceio",
"pegueis",
"perilha",
"piscais",
"pitaria",
"recaudo",
"refluam",
"rememos",
"rentado",
"salgara",
"sernada",
"serpete",
"seveira",
"sitário",
"solanos",
"sopitas",
"tangana",
"tardego",
"trombem",
"trípole",
"vacinai",
"achânti",
"adustas",
"alabare",
"alamiré",
"alindou",
"alveiro",
"anilhei",
"apreçou",
"arenado",
"aretina",
"bicaria",
"bufento",
"caburés",
"caiaras",
"caiavam",
"cardosa",
"carnado",
"clúsias",
"degolai",
"ebrioso",
"enamina",
"epiteca",
"espolie",
"etóloga",
"excusam",
"fanando",
"filamos",
"forrico",
"forçuda",
"gandaio",
"giremos",
"guindam",
"gângula",
"imanada",
"larário",
"latonas",
"lucrada",
"luvaria",
"luvitas",
"maginei",
"matutam",
"mixarem",
"novices",
"ousaras",
"patamos",
"patifas",
"preacas",
"pschent",
"pulaste",
"pórtica",
"reborde",
"rolança",
"rucumbo",
"serenem",
"tarrada",
"tidores",
"transia",
"trinfar",
"tíficas",
"viandes",
"xantona",
"ababaia",
"acestes",
"aguasse",
"aliaste",
"apiabas",
"apídeos",
"arenada",
"balismo",
"baniste",
"baseies",
"bautiza",
"brejosa",
"caberes",
"caixela",
"chicuta",
"desemos",
"despena",
"desteme",
"diorite",
"espadal",
"excelia",
"excetue",
"familio",
"felpada",
"gralhão",
"impetro",
"incende",
"ióguico",
"jugaste",
"leneias",
"levarmo",
"maçando",
"micácea",
"mimarmo",
"murzelo",
"nhambus",
"percale",
"prenhar",
"píticas",
"ramalha",
"rapamos",
"rareada",
"reaveis",
"rebatar",
"recrave",
"relvoso",
"retento",
"ribetes",
"sacarei",
"sacudas",
"saídica",
"sidinho",
"simonte",
"subidor",
"tachara",
"tarraxo",
"tronche",
"unheira",
"usadona",
"vexaram",
"zeravam",
"adargas",
"apensão",
"arpista",
"avacado",
"biselho",
"borbota",
"campano",
"cubação",
"curules",
"desovei",
"envites",
"enviúve",
"esquito",
"fogueia",
"fuselos",
"félsico",
"geriria",
"humildo",
"intrude",
"lacínio",
"linagem",
"locares",
"marraio",
"melanos",
"melavam",
"nebrina",
"ninaram",
"oçânhim",
"panchão",
"pejando",
"peradas",
"piorias",
"pisoado",
"piélica",
"propine",
"protone",
"recusai",
"riposte",
"rolarei",
"selaste",
"senécio",
"tasiana",
"tosaram",
"tunense",
"zarpada",
"ártabro",
"abjurem",
"acádias",
"adengue",
"aflecha",
"alantol",
"aletrar",
"alófono",
"amassas",
"amigara",
"amigote",
"amocham",
"apalpas",
"aquisto",
"arvoras",
"baiucas",
"banacas",
"bebaços",
"béticas",
"comitre",
"demitas",
"dosarem",
"embales",
"engulis",
"ervados",
"escupiu",
"esmeras",
"espores",
"ferazes",
"gazeado",
"gurutil",
"imitemo",
"isagoge",
"latiria",
"lavavas",
"limatão",
"listrou",
"malfado",
"mechada",
"minimes",
"minutou",
"namíbio",
"nilotas",
"nivelas",
"novadas",
"oviário",
"palente",
"papiris",
"pascoes",
"petiços",
"praiada",
"púcaras",
"quiloso",
"rambutã",
"rastras",
"redemos",
"repudir",
"retumbo",
"rolemos",
"sidônia",
"silvedo",
"sofremo",
"sumarie",
"supores",
"suporão",
"tibéria",
"veieira",
"zonados",
"aboinha",
"abranco",
"absténs",
"achegos",
"amoitou",
"anagami",
"aninado",
"apsides",
"arrabil",
"atrolho",
"balacas",
"banibas",
"batonga",
"beritas",
"burreco",
"casaréu",
"caçaste",
"chincoã",
"chonada",
"colegam",
"cratões",
"culpamo",
"daturas",
"debotar",
"decapei",
"denotes",
"depurei",
"derreia",
"dinitro",
"encuque",
"esfalfo",
"faiamos",
"farsudo",
"flóreos",
"gradada",
"grenhas",
"ibérios",
"ilibava",
"manette",
"mimadão",
"montera",
"motares",
"nacelas",
"passaja",
"peninas",
"plangem",
"pularas",
"quitais",
"recador",
"refaças",
"resgada",
"rolhões",
"sarapós",
"sépalos",
"tríbade",
"tórculo",
"ursinos",
"vadeava",
"varrias",
"vetarei",
"abcázio",
"afiável",
"alandro",
"alesado",
"angiite",
"arrojas",
"atritem",
"avindos",
"cabaias",
"capeira",
"casucha",
"cavaloo",
"cavouco",
"cirroso",
"criadão",
"cubrais",
"defasam",
"despias",
"ditamno",
"enfeada",
"faceiam",
"fleumas",
"gingona",
"grulhar",
"guarapu",
"hamadas",
"humosos",
"igualas",
"inçadas",
"iodatos",
"lambeio",
"lamosas",
"lugarão",
"manzuás",
"marejei",
"ossaria",
"palhado",
"parteja",
"pilocas",
"pilules",
"pintamo",
"poisara",
"posdata",
"poupais",
"quartes",
"rataplã",
"recacho",
"rediges",
"salivem",
"segréis",
"tinamos",
"tiniram",
"tinitas",
"tornate",
"vidrais",
"volumar",
"agrafam",
"alfeire",
"amensal",
"araueté",
"arganéu",
"baciado",
"bambeei",
"bilheta",
"boleava",
"cacemos",
"cachoei",
"campeie",
"chonado",
"cíbalos",
"demento",
"denigro",
"descaio",
"dividis",
"elidirá",
"enforna",
"enrabem",
"enteses",
"epóxico",
"ervosas",
"espouca",
"esterar",
"furarão",
"joseíto",
"jugosos",
"junquei",
"malsins",
"meneado",
"mentava",
"mermado",
"moncada",
"padejar",
"paletar",
"parganá",
"pimpado",
"pisoada",
"poentos",
"pomério",
"precate",
"regiram",
"ressada",
"sapeque",
"slalons",
"suécias",
"síndeto",
"talador",
"tenória",
"tróspio",
"társico",
"vigairo",
"visivos",
"ápterix",
"aclarei",
"amalhar",
"amorico",
"antevês",
"arrames",
"atestas",
"bardado",
"bassopa",
"cavalim",
"coigual",
"comidão",
"cuchila",
"curiara",
"cárcola",
"derruiu",
"diamida",
"difenol",
"driblas",
"ecodidé",
"elegemo",
"engolfe",
"entubei",
"enuviar",
"esgrimo",
"ferônia",
"fiassem",
"finavam",
"fundana",
"gatunar",
"gonguei",
"gráfios",
"gueledé",
"infundo",
"insiras",
"iriante",
"isozima",
"janúsia",
"lameada",
"lastrou",
"lumiosa",
"luzimos",
"maltoso",
"matinar",
"miolada",
"molucas",
"mugango",
"muinhas",
"muladis",
"obumbra",
"oprimes",
"orabutã",
"outaras",
"pabular",
"parlemo",
"páticos",
"péctico",
"recasou",
"sastres",
"sobresi",
"testeis",
"vidando",
"xiriana",
"zanolho",
"zunirem",
"abnegam",
"abrutes",
"aditava",
"aimirim",
"aluindo",
"aluíram",
"amidina",
"apiedas",
"arapuru",
"arengue",
"arrazoe",
"atestes",
"açorado",
"balhava",
"biozona",
"bobadas",
"bostada",
"carbino",
"cegarei",
"cevaram",
"chazada",
"chiasse",
"crispei",
"degolem",
"donatos",
"enciosa",
"escoira",
"estarim",
"expeles",
"explote",
"fitaras",
"fulmino",
"herboso",
"hístrix",
"imponde",
"lacrais",
"lavajem",
"lizaram",
"lumaréu",
"maridar",
"motines",
"munirmo",
"muquiço",
"nagalho",
"plúteos",
"pospõem",
"possado",
"putadas",
"quarada",
"rampado",
"recadas",
"reelegi",
"refrita",
"revoado",
"rosnido",
"rubente",
"rugente",
"simiana",
"tecarmo",
"tercete",
"tragino",
"tupaias",
"téssalo",
"ulcerou",
"urrante",
"abacado",
"acometo",
"acuraua",
"adoeces",
"adonara",
"agátias",
"albafar",
"anófele",
"aplacai",
"ardieus",
"atiremo",
"birolho",
"bovista",
"calimba",
"casarmo",
"chanchã",
"corrião",
"curutié",
"dobando",
"enrijam",
"enublar",
"eofilos",
"escudei",
"esparja",
"estiava",
"evônimo",
"farpear",
"farripa",
"fateira",
"firmemo",
"fragões",
"gotejem",
"guinava",
"hidroma",
"imposte",
"impugno",
"jardeis",
"levitem",
"lidavas",
"logavam",
"medrara",
"mésicos",
"nasceis",
"nastros",
"outonar",
"panques",
"pericas",
"perigue",
"pesavas",
"planemo",
"posudas",
"purguem",
"péptico",
"raseiro",
"repecho",
"sitária",
"sucrase",
"tardais",
"tejupar",
"tintina",
"ultimem",
"vivando",
"abelita",
"acistia",
"agirias",
"alardar",
"alistão",
"amandem",
"anadara",
"angolão",
"antário",
"apeasse",
"armanço",
"artilhe",
"asneiro",
"atendou",
"aureolo",
"boiarão",
"braçuda",
"brochem",
"buenaço",
"caliços",
"cancaro",
"carinos",
"chalera",
"clxxxiv",
"colhias",
"coquito",
"coreado",
"descosi",
"desmuda",
"dicadas",
"dotaste",
"eludida",
"embromo",
"enfitar",
"esmante",
"fabelas",
"galheto",
"indaiás",
"infanda",
"injúrio",
"irmanas",
"laminal",
"legislo",
"legismo",
"lesador",
"limaria",
"manolho",
"marinam",
"mcmxcix",
"milicas",
"milongo",
"molície",
"nominas",
"néticos",
"papocou",
"papílio",
"patagão",
"peeiras",
"pegante",
"piadela",
"pirrola",
"pontosa",
"putirum",
"ratites",
"regougo",
"retouça",
"rotados",
"saravas",
"sitarei",
"sumição",
"taquiri",
"tecados",
"urgisse",
"virelai",
"visante",
"visarga",
"zurzida",
"afloras",
"afácico",
"alcoíla",
"alunita",
"antrite",
"aproava",
"araruba",
"argália",
"arrolhe",
"atacais",
"atorzão",
"açoitas",
"boleiam",
"cacheie",
"cachina",
"cautelo",
"chantes",
"contias",
"corotes",
"custeei",
"difalia",
"enformo",
"engatas",
"esbarei",
"escarça",
"escarço",
"esquita",
"estofei",
"exsurja",
"extubar",
"fabulou",
"fingimo",
"ganacha",
"ganhame",
"graveis",
"honramo",
"iacutos",
"jalecas",
"joeirei",
"lustrem",
"manhuço",
"mudação",
"muramos",
"nascemo",
"neolite",
"ninadas",
"nitrido",
"papavam",
"penhoro",
"pisônia",
"poríeis",
"prantes",
"ressaem",
"saibamo",
"segeiro",
"sermone",
"sístase",
"tanchão",
"transis",
"tupinás",
"unípede",
"upassem",
"verseto",
"vexamos",
"vigesse",
"zelação",
"aduzira",
"alembre",
"alertes",
"amornam",
"arbácia",
"assaque",
"assoles",
"cardeis",
"carneio",
"carélio",
"chapuza",
"cocoide",
"comadas",
"cosesse",
"córsica",
"decupou",
"deparai",
"desovem",
"eguaras",
"encodei",
"engesso",
"escarpe",
"esfolas",
"esfosso",
"esgotes",
"extirpo",
"fintada",
"fitadas",
"imitais",
"inceste",
"lambare",
"ligâmen",
"limpamo",
"listram",
"madinho",
"mexermo",
"morante",
"muscari",
"níspero",
"obviada",
"oleando",
"pangala",
"poupudo",
"prevede",
"queires",
"raiarem",
"reflora",
"relicto",
"remiras",
"renanas",
"seduzas",
"singrem",
"soalhar",
"sortira",
"sudaria",
"tabacal",
"tamarim",
"taxador",
"tinadas",
"trelade",
"trolhos",
"trucido",
"tururis",
"ultimes",
"violeis",
"voastes",
"votável",
"xaradas",
"zoneiro",
"abandar",
"acilada",
"agoiram",
"airelas",
"alígera",
"amochou",
"arapuas",
"astélia",
"bisarem",
"bobeiam",
"brejada",
"buquete",
"calumba",
"calíope",
"camaïeu",
"chicota",
"chimpar",
"cromito",
"decepem",
"desrame",
"dicásio",
"dualiza",
"eladora",
"escamba",
"escampo",
"estopar",
"franzes",
"fucales",
"gabarro",
"gatense",
"grunhas",
"gáudios",
"hipista",
"ingeris",
"inquine",
"laburno",
"lareiro",
"lucanas",
"mijares",
"miseres",
"muladas",
"otomaco",
"pararge",
"piperis",
"portear",
"repando",
"repeses",
"repisas",
"rompais",
"röntgen",
"sarango",
"sedarem",
"tabores",
"trilado",
"uivaria",
"vadiado",
"acampai",
"acomuna",
"aldeais",
"ameasse",
"apresem",
"atinara",
"açoitei",
"baldeia",
"brasume",
"burreza",
"cauchos",
"diaulos",
"dissona",
"empecem",
"empupam",
"encabar",
"enruste",
"esposem",
"fitavas",
"flanado",
"furaras",
"gentama",
"gurundi",
"imersor",
"invitou",
"lacínia",
"marroaz",
"maturem",
"migando",
"mulitas",
"nadegal",
"opordes",
"oxálica",
"oólitos",
"palmina",
"papeado",
"penudos",
"pianino",
"pistolo",
"populei",
"ratânia",
"redizem",
"revivas",
"sacarmo",
"secável",
"soprete",
"tarefes",
"tiolato",
"tiorbas",
"toirões",
"torçada",
"turinas",
"vairões",
"vigotes",
"vitimem",
"vivarem",
"vivaças",
"xamanes",
"zeugmas",
"acotado",
"afizera",
"altives",
"analgia",
"apensei",
"apículo",
"atocham",
"barbono",
"bigodis",
"bitante",
"boêmica",
"cartado",
"cascóis",
"cibinho",
"clxxxvi",
"coabito",
"cuicuro",
"debordo",
"debuxou",
"deputas",
"desvair",
"empobre",
"encenas",
"enroles",
"estaiar",
"furente",
"gabelas",
"ganzada",
"gatando",
"granido",
"imbeles",
"maciano",
"macrojê",
"madorra",
"maquela",
"medrada",
"meneara",
"naperão",
"obsessa",
"obstruo",
"ocimeno",
"ornemos",
"papuano",
"pasmara",
"penisco",
"piorras",
"planara",
"plangor",
"promane",
"pródito",
"ramosas",
"ratices",
"regeira",
"repisei",
"solfeje",
"tirésia",
"trinada",
"tíficos",
"umbamba",
"xilenol",
"ássanas",
"abranca",
"adnatas",
"aferrem",
"alivies",
"anciles",
"angando",
"aparteá",
"aproche",
"arrobam",
"açórico",
"basando",
"bastias",
"berrida",
"bocando",
"bombiar",
"braquês",
"catrino",
"catuque",
"chanfre",
"cumarim",
"deszelo",
"encendo",
"enchamo",
"escatel",
"espetes",
"estuado",
"exalais",
"fanegas",
"fedidão",
"foleava",
"fraguar",
"fraseia",
"fugados",
"grafara",
"hasteio",
"ilutada",
"imbaíba",
"imisção",
"intanha",
"judiais",
"lenteja",
"lustras",
"merlins",
"mitrada",
"munisse",
"oitavem",
"pasmemo",
"pincham",
"pingona",
"pinique",
"pixurum",
"péctica",
"quermes",
"quiebro",
"rebebeu",
"recluir",
"redeiro",
"regelou",
"remocei",
"resolto",
"rogaste",
"ruminem",
"salarie",
"serrota",
"somatos",
"sosiano",
"taraíra",
"titules",
"uivarei",
"urdirem",
"velâmen",
"abordes",
"adoteis",
"alfeiro",
"algesia",
"amitose",
"amofino",
"apresei",
"areenta",
"arfaram",
"arrufar",
"asquino",
"babeira",
"bagalho",
"boqueia",
"borrice",
"broquei",
"bulares",
"buzines",
"cabeçam",
"castras",
"catenas",
"chiamos",
"coticas",
"courear",
"despele",
"empanho",
"empuxar",
"enlodar",
"espeite",
"espório",
"esteram",
"fitônia",
"fluores",
"funanás",
"gacheta",
"gadeiro",
"garoado",
"gorduro",
"gulache",
"huminas",
"incoeso",
"jugaria",
"lagrime",
"lapeiro",
"laurite",
"limamos",
"machila",
"manduco",
"manonas",
"micrura",
"mirares",
"mueslis",
"mércias",
"noivara",
"ondulei",
"pestosa",
"pluguem",
"precita",
"pudlado",
"quedais",
"rebatou",
"recauda",
"recodes",
"reporão",
"roçares",
"rueiras",
"saleira",
"saqueai",
"sugueis",
"tolinas",
"tramoço",
"tronava",
"truquei",
"valiada",
"vencias",
"xeretam",
"adubara",
"agiotar",
"agênese",
"aldrabo",
"alitera",
"amanhei",
"ambrões",
"aparaju",
"arancuã",
"arderas",
"arreiem",
"baitolo",
"cabúqui",
"canevás",
"capuche",
"caratas",
"convole",
"curante",
"enconda",
"engrada",
"engrole",
"entremo",
"enxeria",
"espasma",
"estasia",
"exhalar",
"exubero",
"falacha",
"falseei",
"faxinam",
"garanho",
"godilho",
"herniar",
"iconiza",
"inconha",
"ledoras",
"losinha",
"muchada",
"nadaste",
"oograma",
"pectato",
"pirocar",
"píscola",
"raineta",
"rapazia",
"raseira",
"recamos",
"rechega",
"recites",
"reentro",
"revidas",
"rizinas",
"rolares",
"ronhoso",
"saneara",
"solação",
"suponto",
"tarifam",
"tosamos",
"téticos",
"undaria",
"vararia",
"veratro",
"achique",
"acoadas",
"acridez",
"aduziam",
"alazães",
"anastra",
"animamo",
"aplanem",
"apostai",
"aringas",
"aunando",
"aurelas",
"aviltes",
"blêizer",
"bocejem",
"bulhaco",
"chefãos",
"clxxxix",
"cocriam",
"coirana",
"comante",
"conchos",
"conteur",
"dunitos",
"eluvial",
"enregue",
"entocas",
"enxofra",
"falazar",
"fimoses",
"firmais",
"forçona",
"guapeza",
"impinjo",
"inhalar",
"jugavam",
"labutem",
"labutes",
"lignita",
"manuche",
"manzape",
"moravos",
"nateiro",
"nubrina",
"ocultai",
"ondados",
"ovarite",
"pepulim",
"pilação",
"plissou",
"pronuba",
"pungiam",
"rameiro",
"rascado",
"rechiei",
"rendamo",
"salvemo",
"saranda",
"saídico",
"snifava",
"sumires",
"tapizar",
"timbais",
"tossira",
"vazemos",
"viminol",
"vincara",
"ânglico",
"abondar",
"adereça",
"agracio",
"albarde",
"amações",
"ameaces",
"angonal",
"anuária",
"aproada",
"areador",
"ariosos",
"badalei",
"bocinha",
"boiados",
"bíforas",
"carrela",
"cartais",
"certada",
"changai",
"cibário",
"comutem",
"cotaria",
"descemo",
"descoro",
"ebriosa",
"empesar",
"entubam",
"espacei",
"esposei",
"fabácea",
"frechar",
"frestão",
"incarne",
"janelos",
"lindava",
"macutas",
"maleica",
"morense",
"nelumbo",
"obrigai",
"olhante",
"painita",
"pajeado",
"patalas",
"paçocão",
"penasse",
"pesável",
"rabiada",
"raivado",
"raçados",
"redundo",
"relutas",
"rixosas",
"roseiro",
"sacolei",
"tapiaçu",
"teimais",
"tubibas",
"urgidas",
"votavas",
"zapeiam",
"adnatos",
"adoaria",
"adoeças",
"ancores",
"anuídas",
"apeteci",
"arrumai",
"boiaria",
"botirão",
"bulidor",
"cairara",
"cambais",
"captana",
"cavoque",
"celadas",
"ceremos",
"chitões",
"chumbim",
"cochina",
"colimem",
"coraste",
"córmica",
"defumei",
"dobrais",
"encarda",
"encista",
"envaido",
"estemas",
"estuava",
"eudósia",
"federei",
"futucam",
"gotinho",
"gualdir",
"hemodia",
"heteias",
"horaram",
"imbicar",
"içarmos",
"jaibara",
"jeitada",
"jeitões",
"legarão",
"limares",
"louvamo",
"luético",
"manasse",
"mantada",
"orreiro",
"ovantes",
"palonça",
"papaste",
"patiguá",
"pesonas",
"pirágua",
"pulseia",
"recavém",
"roermos",
"sanavam",
"sercial",
"soldara",
"sonaria",
"sulipas",
"valeris",
"varzino",
"agastei",
"antinós",
"aquavia",
"baionês",
"bivocal",
"boteiro",
"cambeia",
"carinas",
"catarei",
"chimpas",
"chincar",
"cidadoa",
"colaços",
"coleava",
"cominem",
"condoma",
"corador",
"cubados",
"curumba",
"depenas",
"desonro",
"desusar",
"divista",
"doudice",
"dáblius",
"díptica",
"emburre",
"entoque",
"fasquio",
"filarco",
"flambei",
"fontais",
"franjar",
"guacari",
"gudinha",
"hernial",
"imitamo",
"laurona",
"loteava",
"maratro",
"meniana",
"merecei",
"muônico",
"obrarão",
"pigargo",
"pinocos",
"pivotes",
"raladão",
"ramonas",
"reladas",
"remarei",
"remiria",
"repoiso",
"reterás",
"sachada",
"sambara",
"sementa",
"socavas",
"transir",
"trialto",
"tórpida",
"velória",
"vigerem",
"vináceo",
"zoarmos",
"aboleto",
"aceptou",
"acunhou",
"afinais",
"agnésia",
"alécito",
"ambires",
"amornas",
"antedeu",
"apódema",
"arrojem",
"arrolem",
"atorais",
"averbem",
"boiador",
"bravada",
"brindão",
"buleiro",
"cabeceá",
"cainhar",
"cajuais",
"cicadas",
"cochada",
"coitelo",
"conotem",
"corchos",
"difluir",
"disafia",
"donosas",
"egletes",
"embacei",
"ensebou",
"erotize",
"estuada",
"finarem",
"fofonas",
"galeaça",
"gatosos",
"grampei",
"hamateu",
"harpeja",
"inseris",
"luariza",
"mazomba",
"medeias",
"minerei",
"nelense",
"osíride",
"ousavas",
"prezais",
"quiçaba",
"ranídeo",
"rebuçar",
"reexibi",
"relhada",
"remexes",
"ressoas",
"riposto",
"ronchas",
"roupada",
"santeno",
"sexamos",
"sousões",
"suadela",
"tenteie",
"tinório",
"trassou",
"vindimo",
"volteie",
"xexeiro",
"afoites",
"aganipe",
"aglaope",
"alojamo",
"amojada",
"arrolha",
"atremar",
"baiando",
"balugas",
"binagem",
"bornite",
"butelos",
"cangati",
"carmino",
"chorrou",
"cisuras",
"codorno",
"cogemos",
"confita",
"crismam",
"curarmo",
"deterás",
"deveste",
"donzéis",
"eigreja",
"embaído",
"embutam",
"encapas",
"entoure",
"excelem",
"foragem",
"furrica",
"galaram",
"georama",
"impenda",
"imémore",
"inferes",
"lanando",
"maiteno",
"marmela",
"marreto",
"massita",
"mataréu",
"milésia",
"mixavam",
"monarda",
"ormasde",
"paridor",
"pastios",
"podreza",
"prazido",
"pélopes",
"regasta",
"replena",
"robínia",
"romante",
"soergui",
"terreis",
"tirivas",
"trisque",
"tumbaga",
"tungros",
"vadeado",
"vantajo",
"viajões",
"voasses",
"volanta",
"zemareu",
"ablando",
"afeites",
"ajudeia",
"aliares",
"alijara",
"apresam",
"araguaí",
"araticu",
"arenato",
"arnicas",
"arredes",
"arvadeu",
"assarei",
"avencão",
"avileis",
"bagando",
"beijais",
"bodando",
"brolhas",
"buidora",
"bárbito",
"calaças",
"camposa",
"carstes",
"chibato",
"chuches",
"colitas",
"conluia",
"cordóis",
"cunetas",
"danavam",
"datário",
"domaste",
"duziram",
"embatam",
"embebam",
"encolar",
"estilei",
"estrale",
"feciais",
"forçais",
"fubento",
"gaguice",
"indouta",
"joguemo",
"landrus",
"lateais",
"lebrada",
"logouts",
"loureci",
"lucubra",
"matismo",
"mobílio",
"moldamo",
"palites",
"paraquê",
"pareava",
"patudas",
"pinaram",
"plastas",
"preteia",
"proseei",
"ranhado",
"reigota",
"remarão",
"remelar",
"rifenha",
"saguate",
"salsões",
"sampana",
"seteiro",
"soterro",
"taguari",
"tamatiá",
"tapação",
"tesoure",
"turfosa",
"unescos",
"xavecam",
"acarajá",
"agourou",
"airando",
"amoques",
"anopsia",
"ariques",
"atrombe",
"atucana",
"baceira",
"bafando",
"bandeio",
"benzera",
"bodados",
"cabecei",
"capengo",
"capilha",
"cariacu",
"carpira",
"cliname",
"criceto",
"cuidemo",
"cássida",
"debaste",
"defetos",
"desfala",
"diânion",
"elóquio",
"emaciar",
"embotem",
"enfaixo",
"entonou",
"entucha",
"ervário",
"esbofar",
"escolto",
"estiras",
"fadigou",
"fovista",
"genetas",
"golfado",
"gongava",
"gorarem",
"gruindo",
"hiparco",
"honreis",
"ideador",
"imigras",
"infamou",
"inhacas",
"joelhou",
"lactina",
"lixares",
"lunduns",
"maldado",
"manaria",
"maraguá",
"matrize",
"merende",
"mestreá",
"mochilo",
"nástica",
"ofiússa",
"palioto",
"peleada",
"peptina",
"prosada",
"querite",
"quirche",
"rasgamo",
"roteira",
"rugisse",
"tauxiar",
"tionina",
"truísta",
"téticas",
"validão",
"vortais",
"xerocam",
"ênteron",
"abalroe",
"abantos",
"acampas",
"acrecer",
"afivelo",
"alamina",
"aleados",
"aplanam",
"arrojai",
"arroxou",
"atearão",
"atochei",
"badalem",
"boencas",
"briscas",
"caixota",
"caparem",
"catleia",
"cenoira",
"chiaria",
"chilete",
"clítica",
"coiraça",
"copilei",
"cumbaca",
"deponde",
"edículo",
"elbaíta",
"empulha",
"encorpo",
"endrina",
"eserina",
"exculpa",
"finação",
"goteado",
"graceje",
"graveta",
"guiante",
"imáculo",
"limavam",
"lunária",
"mamicas",
"marfado",
"modíolo",
"moldais",
"objecte",
"onfália",
"ortoses",
"pafiosa",
"palecos",
"pambala",
"pardeus",
"peneruê",
"perineu",
"potreco",
"quiçamã",
"recubro",
"retaria",
"rifarem",
"sabujas",
"serícea",
"sinápis",
"soqueou",
"surubis",
"ticonha",
"tomarmo",
"ufanado",
"unisses",
"vendais",
"viríeis",
"zizanie",
"afincam",
"aglaias",
"algarão",
"amornei",
"anulais",
"anuídos",
"apodemo",
"arrelie",
"ateiras",
"atrolha",
"avisamo",
"azonais",
"cagueto",
"camacãs",
"cariota",
"charrue",
"chauvin",
"chocamo",
"cisando",
"colatas",
"costina",
"cromite",
"crustas",
"dançais",
"decimar",
"desdeem",
"desliso",
"desmamo",
"empraza",
"entimos",
"escabro",
"esquies",
"evoluto",
"formola",
"fundais",
"furtais",
"fórmios",
"imundou",
"inculco",
"lampana",
"lentura",
"listrão",
"luradas",
"mancões",
"marnéis",
"mundice",
"niobita",
"nutante",
"pelônia",
"pesaste",
"plantal",
"pontoso",
"prazado",
"protura",
"puseras",
"pávidas",
"quadrem",
"raiaria",
"reteres",
"retites",
"ronrone",
"rádulas",
"saparei",
"submata",
"sujavas",
"tarefar",
"tesando",
"titismo",
"trintar",
"vagação",
"valaria",
"valiado",
"vimeira",
"violles",
"zurrado",
"adiaste",
"afemina",
"agonies",
"albacar",
"almário",
"alvarós",
"atrites",
"bracear",
"cassais",
"celosas",
"cercamo",
"churrio",
"cordeio",
"cozeres",
"danante",
"datarão",
"deporás",
"egretas",
"entesam",
"enviemo",
"erítrea",
"estofam",
"exarara",
"exibias",
"gadídeo",
"gazitas",
"goivada",
"gorasse",
"grapirá",
"grassem",
"gurguri",
"haliote",
"hassani",
"heregia",
"intonso",
"jactado",
"leiloei",
"licenas",
"luzisse",
"macules",
"malaico",
"maluque",
"manecos",
"medersa",
"mortona",
"obrares",
"perfies",
"pericom",
"porende",
"porfiem",
"ralhara",
"recasam",
"refarão",
"refusar",
"resgado",
"rixenta",
"saneava",
"silabou",
"solaras",
"solasse",
"sotreta",
"sálicos",
"tarinha",
"tiflite",
"topemos",
"tudesca",
"vagindo",
"velinos",
"vigeria",
"viuvada",
"xaropar",
"abobara",
"adegado",
"afaquia",
"afrânia",
"alinhes",
"aluídos",
"amônias",
"aquetou",
"assinai",
"avangar",
"balhado",
"canoura",
"catetão",
"cavacar",
"cetanos",
"cobrelo",
"comires",
"conezia",
"corrina",
"danisco",
"desface",
"desfaza",
"devotes",
"elicita",
"embondo",
"endorso",
"endurar",
"ensarte",
"esborra",
"esponte",
"eversor",
"extenue",
"fitarei",
"flanada",
"fotínia",
"fuçasse",
"helênio",
"incoesa",
"larunda",
"leitras",
"liberne",
"lobunos",
"minguei",
"modemas",
"mônades",
"nublina",
"odiante",
"oxantel",
"palores",
"pandeia",
"papagos",
"pejaram",
"perdiga",
"pidonha",
"poentas",
"porfiei",
"proíbas",
"próteas",
"ravessa",
"reginam",
"ripamos",
"sambudo",
"sapajus",
"sarjada",
"sifônia",
"sinando",
"sovaram",
"subasto",
"taganas",
"tralham",
"vaicias",
"vidrava",
"vilicar",
"visória",
"zapeado",
"abâmita",
"acagaça",
"aguavam",
"alemoes",
"alienia",
"apiácea",
"arenata",
"asseiam",
"atenuei",
"bageiro",
"balsero",
"bilares",
"bundear",
"caveada",
"cenitas",
"cerense",
"chandes",
"culinas",
"degreda",
"deleixo",
"desabas",
"doáveis",
"díptera",
"empalho",
"encisto",
"endoide",
"enriste",
"escalfa",
"escuram",
"estilou",
"estimai",
"faquear",
"fujirem",
"granfas",
"ilíadas",
"imputas",
"irrupta",
"islenha",
"jumbeba",
"leptura",
"letrina",
"locasse",
"menente",
"metreta",
"missivo",
"mobilas",
"modulei",
"molango",
"muleiro",
"mumbaca",
"murídeo",
"obrages",
"ovígera",
"paliado",
"patalou",
"pedanas",
"pilador",
"poqueca",
"possada",
"purguei",
"repolga",
"retamas",
"roupara",
"seríceo",
"sufetas",
"sugares",
"sálmica",
"sóspita",
"tinação",
"turmena",
"valseia",
"vendara",
"vitálio",
"acoples",
"acoroás",
"adortar",
"agredem",
"anisada",
"antélio",
"apoucou",
"arrobou",
"arvoado",
"atranco",
"belidas",
"betouro",
"bostela",
"braçudo",
"cangada",
"carneia",
"catitus",
"cindiam",
"codejam",
"demudas",
"dentear",
"depenem",
"derroco",
"desfasa",
"diapiro",
"dieseis",
"drapeje",
"embraça",
"enfadem",
"enficar",
"enjaule",
"ensejei",
"esporam",
"evolvia",
"execrei",
"fataças",
"frontas",
"galeros",
"hugoano",
"indulte",
"ingrede",
"luxaria",
"líbidos",
"machiar",
"mimenta",
"miraras",
"mirável",
"molagem",
"molinas",
"mururés",
"numerai",
"obturei",
"pegacha",
"pilonos",
"porites",
"posteio",
"raleira",
"reagina",
"redibis",
"relatai",
"reluzes",
"remeças",
"remorco",
"ressoei",
"reunais",
"rodável",
"safarei",
"snifada",
"solagem",
"sujados",
"sésamos",
"tacacas",
"tagaris",
"telinga",
"ternado",
"toneiro",
"tredice",
"trivoli",
"tubaram",
"tânjara",
"varejam",
"verânia",
"zurziam",
"acardia",
"adverta",
"adéquas",
"anicham",
"apesare",
"apunham",
"arfarem",
"assídio",
"atardar",
"avargas",
"avejões",
"avençou",
"axoguns",
"baixate",
"baliana",
"biafada",
"boatado",
"bosteja",
"chatada",
"clarias",
"coimado",
"conotei",
"crético",
"dacitos",
"dejetar",
"deviera",
"drogara",
"dáimios",
"edíveis",
"eligira",
"enfrena",
"entones",
"envasou",
"especes",
"expolia",
"falecei",
"falácea",
"fluidal",
"formilo",
"imamado",
"imérito",
"lapônio",
"lixenta",
"lomento",
"macanha",
"marruco",
"mofenta",
"noivada",
"orastes",
"peltada",
"pesques",
"pilavam",
"pindari",
"púnheis",
"rabeava",
"rasbora",
"refrate",
"sabível",
"sachado",
"subcume",
"sufrago",
"suinara",
"sulimão",
"suporei",
"sérbios",
"tacinga",
"tijucos",
"toureei",
"tupiana",
"urdisse",
"vigilam",
"vivaria",
"xingues",
"abanana",
"aclaras",
"agisses",
"agoires",
"alardam",
"alentas",
"amancha",
"arenamo",
"argolar",
"aruenda",
"baguaça",
"barbena",
"batcará",
"bisaras",
"botadão",
"bulirem",
"carreço",
"caxetas",
"chinado",
"churria",
"corusca",
"cístron",
"destoei",
"duetada",
"dêmicos",
"entejam",
"entonam",
"escandi",
"galarem",
"gemeres",
"gondole",
"guinhas",
"idiotar",
"justiço",
"lagetas",
"lamação",
"lingado",
"lizamos",
"lorpice",
"malopes",
"manaias",
"minares",
"minduri",
"odrísio",
"orígano",
"pasceis",
"patecas",
"pedraço",
"pindoca",
"pivotam",
"pleitou",
"quendas",
"rapador",
"redento",
"senisga",
"serando",
"tambero",
"tesuras",
"toleira",
"torados",
"trireme",
"triário",
"trunfar",
"tumidez",
"varlete",
"vencemo",
"verdear",
"vinosos",
"vitular",
"zoassem",
"ablegar",
"alefriz",
"alentes",
"algibes",
"amadona",
"aniline",
"apeiros",
"aprismo",
"arfamos",
"aspadas",
"atriste",
"auditei",
"aventem",
"bufados",
"cadinhe",
"camanga",
"canevão",
"castear",
"condirá",
"coçarão",
"crenado",
"cuneado",
"curitas",
"debando",
"defases",
"demasio",
"desútil",
"dormite",
"ergulho",
"estetos",
"estiole",
"fefetes",
"feltrei",
"femeaço",
"frevado",
"ganhuça",
"garotes",
"goenses",
"gordaço",
"hortejo",
"ilícios",
"imundam",
"isvitas",
"jubilem",
"lanhava",
"lúridas",
"mandari",
"marzapo",
"medalho",
"mercava",
"núculas",
"ortigue",
"ourinas",
"pagalas",
"parótia",
"pedinto",
"pitasse",
"preando",
"privais",
"queijar",
"quiçais",
"rapelou",
"relavam",
"remirem",
"retroar",
"risória",
"rolaste",
"rágades",
"saárico",
"segados",
"seixoso",
"sifones",
"sitônia",
"subdial",
"tildado",
"titônia",
"tolidas",
"vaziado",
"vistava",
"xepeiro",
"ínferas",
"acercai",
"aclamas",
"adequai",
"afabula",
"afilhas",
"alheais",
"amanaci",
"amentei",
"amádiga",
"angiras",
"apoieis",
"aterrai",
"atático",
"baciano",
"bambuto",
"bateada",
"bicamos",
"brulote",
"caleado",
"canados",
"capites",
"carpino",
"carroce",
"catupés",
"coaptar",
"cortais",
"depilas",
"enredes",
"equóreo",
"esmerai",
"euscaro",
"exarava",
"fuscões",
"galatos",
"ganiram",
"glicana",
"gradava",
"indunas",
"inesita",
"irênica",
"janduim",
"jataíba",
"licuris",
"magaíça",
"mamavas",
"maneiam",
"maroiço",
"minucio",
"mofamos",
"orônimo",
"pateres",
"penhado",
"pipense",
"plegias",
"podarão",
"pojeira",
"popeiro",
"preados",
"rangida",
"recuram",
"roíamos",
"sacacas",
"salamim",
"serinas",
"seteais",
"sexador",
"tapetar",
"tarpões",
"terrois",
"toragem",
"turadas",
"tururim",
"ugriana",
"unanima",
"vogaram",
"abandoa",
"acartam",
"acartes",
"adietar",
"afiasse",
"alabada",
"aldrabe",
"aleijes",
"amadina",
"amativa",
"amontem",
"amébico",
"avalara",
"avoquem",
"bazófio",
"bengola",
"betaria",
"betones",
"biscato",
"bobeiem",
"boragem",
"burlara",
"caricio",
"carveja",
"cascona",
"chinada",
"codinha",
"coseria",
"couvada",
"demites",
"deslava",
"dormião",
"emalado",
"embacie",
"empanem",
"encanem",
"encurvo",
"enfarar",
"enfeava",
"enfeixe",
"engodou",
"enxudro",
"espenda",
"estripo",
"excursa",
"exumava",
"feroico",
"gralham",
"gredoso",
"haríolo",
"hicória",
"héspera",
"impormo",
"incoado",
"infólio",
"jogante",
"lablabe",
"laxação",
"mambuca",
"mareava",
"margues",
"maçarem",
"mimaste",
"mistões",
"nêurica",
"obdução",
"octopos",
"ogueira",
"patesco",
"predata",
"prezeis",
"prosado",
"quentem",
"quimbas",
"rebotou",
"repilas",
"râmulos",
"simules",
"solipso",
"somaste",
"sóliton",
"tabuais",
"tapeiem",
"taregas",
"tocaiam",
"triscam",
"ucasses",
"vinhete",
"zooides",
"zuartes",
"adernam",
"adjetas",
"advogai",
"afumado",
"agoirou",
"airadas",
"altinos",
"apesara",
"aramara",
"araroba",
"astroso",
"badante",
"bicasse",
"bisaria",
"bufasse",
"bulunga",
"cacifro",
"cagufas",
"carapim",
"cardano",
"caroatá",
"ccxxxix",
"cerques",
"charais",
"cintrão",
"colecto",
"conopeu",
"coçarei",
"dáblios",
"efluxos",
"empalem",
"enjerir",
"epígino",
"equórea",
"estrovo",
"faritas",
"fugadór",
"garalho",
"garfiar",
"gueguês",
"honorar",
"imbicou",
"jingola",
"labiado",
"lacônio",
"lambana",
"levedem",
"lucroso",
"maldava",
"mascoto",
"memorou",
"mourejo",
"munduri",
"neófilo",
"orelina",
"palabre",
"parirão",
"peleava",
"pentila",
"pereces",
"pidonho",
"pipando",
"porejam",
"prismar",
"quinoas",
"rainhos",
"ramelar",
"refrete",
"rosquei",
"sanhoso",
"sarneis",
"serrões",
"sestrem",
"situais",
"subarte",
"subliga",
"talavez",
"tampone",
"torreia",
"tramito",
"tríncia",
"untanha",
"ursídeo",
"urutago",
"varzina",
"vedanga",
"vergues",
"vidamia",
"vidrosa",
"vomitai",
"abadala",
"acusamo",
"agaiola",
"agiotai",
"aninada",
"aprazam",
"bagação",
"baniano",
"bastais",
"bessera",
"bobeava",
"bridado",
"cabrice",
"calento",
"cedrela",
"chibado",
"chisque",
"comboia",
"curtimo",
"cururuá",
"célicos",
"danesas",
"desavém",
"desrumo",
"duraste",
"encovam",
"engarra",
"enrijou",
"espedir",
"estopes",
"euriale",
"feirada",
"filidor",
"flamado",
"folhame",
"fulgira",
"furarei",
"gaiatri",
"gaipava",
"geolhos",
"harmatã",
"hircino",
"humanam",
"ignizar",
"impreco",
"ionizou",
"lavrais",
"leigado",
"litados",
"litaria",
"lumache",
"lêntica",
"mamelão",
"marafos",
"maturei",
"mimansa",
"montamo",
"nefária",
"ninhego",
"nitrada",
"occiput",
"olhudas",
"pinaças",
"priapos",
"psórico",
"rabitos",
"radiara",
"remorar",
"rogares",
"roqueta",
"sangada",
"santita",
"satinho",
"sobetas",
"subjaza",
"tetarei",
"tremado",
"trincho",
"vedável",
"vigaram",
"vinácea",
"zorongo",
"zurbada",
"abnegue",
"absenta",
"acartou",
"acincho",
"acostei",
"acutila",
"afagues",
"alamana",
"alcista",
"alombei",
"amiudou",
"anatina",
"andação",
"anojada",
"apetada",
"aplumar",
"atitulo",
"bajados",
"baqueei",
"baremas",
"barrufo",
"barulhe",
"bemoles",
"bifurco",
"binágio",
"bisasse",
"burmesa",
"cacumbi",
"caimbés",
"catenga",
"cogulas",
"comedes",
"cônsono",
"decorri",
"derraba",
"desbara",
"desbrio",
"desfies",
"deturba",
"diarcas",
"dicaria",
"difames",
"divisos",
"ecludam",
"efetues",
"embocam",
"enturva",
"esporei",
"evencer",
"filetas",
"fraturo",
"fumeira",
"galhuda",
"garinei",
"gavador",
"inambus",
"incesso",
"irânico",
"justada",
"lamurie",
"lapiano",
"lenires",
"letrias",
"loengos",
"maconhe",
"madural",
"mermada",
"ninhado",
"nomeies",
"onfalos",
"osteons",
"ovarina",
"pandura",
"pinocas",
"pitaram",
"postejo",
"primora",
"quartar",
"quibano",
"quicuia",
"reboado",
"regadia",
"regatei",
"rojoada",
"sotopor",
"stomias",
"suariam",
"sárcina",
"sânquia",
"tapetas",
"tateara",
"teredos",
"torneei",
"tuliano",
"urânica",
"vagagem",
"valsear",
"vogasse",
"zoariam",
"advocam",
"alanita",
"anilhos",
"aplitos",
"atufado",
"açaimos",
"badanal",
"besoiro",
"bifólio",
"bímanos",
"cafetãs",
"cainito",
"cajuaçu",
"calanca",
"capeiro",
"caurina",
"ceifais",
"corável",
"crinudo",
"crostal",
"córculo",
"depreco",
"desnexo",
"dopamos",
"encarto",
"enfurno",
"ensebam",
"entrevo",
"equesos",
"escarna",
"esferal",
"flambou",
"guingãs",
"ideamos",
"ignorai",
"insuflo",
"judenga",
"jurueba",
"lizante",
"lúgrebe",
"mantéus",
"marinhe",
"matique",
"mesonas",
"mexível",
"moderes",
"mofaria",
"murches",
"natális",
"oirates",
"ombreie",
"omissor",
"oósporo",
"palreio",
"patoilo",
"pilumno",
"pilungo",
"plainou",
"pleitei",
"ponteie",
"protona",
"quérube",
"raizama",
"ramelou",
"requins",
"rinário",
"roteara",
"rumaste",
"sacavas",
"samicas",
"sedamos",
"siricas",
"sorrimo",
"súcubas",
"tamisar",
"tricoco",
"triface",
"trâmuei",
"ululado",
"urgiria",
"vacínio",
"varegos",
"visório",
"zambuco",
"abrulho",
"acocada",
"aditora",
"agravas",
"amélica",
"anuição",
"apossas",
"aquesse",
"asadora",
"assedar",
"asseste",
"ativeis",
"baciais",
"barosma",
"basamos",
"biocopo",
"bombico",
"brevete",
"cabonde",
"cagadão",
"caldudo",
"calmava",
"candada",
"castões",
"cercefi",
"cochado",
"cogulho",
"culinos",
"cumaris",
"dementa",
"desçais",
"devenha",
"doardes",
"donação",
"entalis",
"entravo",
"epistem",
"escarvo",
"femíneo",
"fluirei",
"fornaça",
"gatázio",
"geônimo",
"hexalfa",
"iantras",
"imanato",
"imídico",
"infuses",
"inhenho",
"japiins",
"juntivo",
"lanzuda",
"lastram",
"lenteza",
"lepista",
"lhanura",
"liceano",
"limitai",
"loiolas",
"lutante",
"malanda",
"mamarão",
"manguem",
"matriza",
"meieira",
"mizando",
"nimbava",
"ominais",
"pegadio",
"petarda",
"piabuçu",
"procuta",
"prosemo",
"pulunga",
"quartei",
"queixai",
"quimase",
"rebroto",
"redopio",
"reixado",
"restais",
"revocou",
"rímicos",
"selinos",
"semelho",
"seteado",
"sufetes",
"sulismo",
"sálicas",
"teixugo",
"tioéter",
"tonamos",
"tordião",
"trenada",
"vagavas",
"xarifes",
"ásticos",
"abrumar",
"acessoo",
"achaguá",
"adernal",
"aldinas",
"amendoí",
"arapaço",
"atalhas",
"atascam",
"atenhas",
"atuares",
"balubas",
"batávio",
"caldeou",
"casales",
"codesso",
"copulei",
"cotasse",
"cuanzas",
"delires",
"descosa",
"diserta",
"eglefim",
"enduape",
"epúlide",
"ervatão",
"espanas",
"estilas",
"foderão",
"fóticos",
"gereira",
"infrato",
"jaguané",
"lancete",
"lanchem",
"lentejo",
"maginas",
"magrões",
"malocou",
"menecma",
"mordica",
"navegai",
"orçaria",
"pandeco",
"pelvina",
"pirróis",
"poitara",
"popocas",
"pororom",
"portora",
"quengos",
"quinica",
"recolte",
"remansa",
"repolha",
"retesta",
"rezinas",
"rumbear",
"safarmo",
"segares",
"sápidos",
"teorbas",
"tripule",
"trêmito",
"urrasse",
"árdegos",
"abantes",
"abiegno",
"acarino",
"adejado",
"aferres",
"alendar",
"alesada",
"alfadex",
"alfeira",
"anegado",
"anteato",
"anteira",
"apalpes",
"ardamos",
"assubir",
"atermar",
"atronar",
"bestado",
"bumélia",
"caraxué",
"cetíneo",
"chaveie",
"chinche",
"citavas",
"coaptam",
"cogites",
"coregos",
"corroei",
"crebros",
"creosol",
"cubeiro",
"culhuda",
"desmane",
"deszipa",
"egoisme",
"enticar",
"estenia",
"estinha",
"estrece",
"fajutar",
"ferível",
"fumegue",
"gangosa",
"garitos",
"gonilha",
"gozável",
"gradeça",
"guichos",
"horamos",
"infinca",
"lobolar",
"lodeira",
"lástrea",
"macaúva",
"maneava",
"marguem",
"maticar",
"maçaram",
"memoram",
"mingais",
"molinar",
"nauseou",
"nervais",
"olvidai",
"orégões",
"pajeada",
"panetas",
"pinchei",
"provamo",
"punamos",
"póstera",
"quadrum",
"quinaus",
"quindas",
"rabonas",
"rebitas",
"recaias",
"recibem",
"repropô",
"repuxei",
"rorejar",
"sangado",
"sopúbia",
"soronga",
"sucaria",
"taleiro",
"tomadão",
"tonados",
"traçais",
"traçana",
"trífido",
"tórpido",
"ultrama",
"vinquem",
"xaveque",
"ximburé",
"abafeis",
"aconita",
"afretou",
"alvoram",
"amansas",
"ananita",
"apenais",
"aplanas",
"aptador",
"ardumes",
"arguiam",
"ariquém",
"arrimes",
"arrufam",
"baldoso",
"baremos",
"bastece",
"batiais",
"beirara",
"betazol",
"bocapiú",
"bostejo",
"boêmico",
"brisava",
"caquile",
"cassina",
"cedinha",
"chamota",
"changas",
"chaveio",
"chavina",
"cotizem",
"cxcviii",
"devisas",
"dicamos",
"dignada",
"dimanou",
"dinamia",
"djelaba",
"ebulido",
"eixamos",
"falaces",
"farjuto",
"garrula",
"gorilha",
"gozeira",
"intátil",
"juglona",
"laciada",
"lambuça",
"lançaço",
"lêramos",
"macaíra",
"macotes",
"maiombe",
"meninis",
"mijarei",
"mitigas",
"muladar",
"mussaca",
"norreno",
"oréadas",
"pecadas",
"piastas",
"pilchar",
"poitado",
"popeira",
"putanga",
"rançado",
"relassa",
"remesso",
"reóbase",
"satania",
"saturna",
"siglema",
"sirênio",
"sombral",
"soproso",
"suasana",
"surgião",
"tegafur",
"tivermo",
"torquez",
"trapear",
"vulgada",
"abaixes",
"abicado",
"abissai",
"agrafou",
"ambaíba",
"apenhei",
"apívoro",
"aquetei",
"arrogas",
"atrepar",
"berrona",
"boemita",
"bostelo",
"bregada",
"burrado",
"bômbice",
"cachoar",
"cacumbu",
"cacália",
"campane",
"chachas",
"charões",
"cocôzão",
"copelas",
"cotonou",
"covelas",
"decliva",
"defloro",
"dátilos",
"embutes",
"emparar",
"encardi",
"enfezam",
"espinor",
"farejas",
"fichava",
"filavam",
"floreei",
"flâmeas",
"frolido",
"gabanço",
"intrudo",
"judicou",
"jugulam",
"larveja",
"louveis",
"manojos",
"melamos",
"morácea",
"murário",
"mélicos",
"oculina",
"ondulem",
"paniego",
"pascoam",
"pelejes",
"perfará",
"poiando",
"polques",
"populas",
"prefixe",
"preliba",
"presura",
"prógono",
"pérsias",
"querele",
"querelo",
"recante",
"recreei",
"regales",
"rocegar",
"roncara",
"sabugar",
"sacaras",
"sarnosa",
"sarobas",
"sebesta",
"serrara",
"sigilou",
"sistino",
"soluces",
"sonadas",
"suadido",
"sugiras",
"tagarra",
"taralho",
"trilhes",
"tronada",
"valvata",
"verruca",
"vinária",
"vitária",
"zimbrar",
"acineta",
"acuamos",
"adotais",
"adustão",
"adônica",
"aferras",
"ajetivo",
"alicece",
"ameiado",
"amentam",
"anismos",
"antevem",
"arditas",
"astrosa",
"avoadão",
"bacares",
"badalão",
"bailhos",
"baixais",
"baleies",
"balilas",
"barrufa",
"braçola",
"brocava",
"cabales",
"cadorna",
"cagunfa",
"camalho",
"caraipé",
"catreva",
"celamim",
"certela",
"cinzado",
"citadão",
"colorei",
"coregia",
"cotiado",
"crismes",
"cusitas",
"deslaço",
"desunhe",
"devinha",
"doutoro",
"eduzida",
"egretes",
"embreia",
"emprimo",
"encanoa",
"envasei",
"eparcas",
"escampa",
"estrego",
"filador",
"foscado",
"fuãzona",
"gametal",
"gatinhe",
"gozonas",
"horadas",
"imamato",
"invetem",
"jecoral",
"jotinho",
"juarana",
"lacassá",
"lampias",
"laqueou",
"macerei",
"madurai",
"malogre",
"mateava",
"mauzona",
"minutor",
"miriana",
"mucuins",
"nuvioso",
"obturam",
"oculado",
"partino",
"patenta",
"perrada",
"poeteis",
"pudícia",
"pulavas",
"pulidor",
"rapares",
"recatam",
"recatas",
"reemiti",
"refutes",
"returna",
"salitro",
"sancada",
"secione",
"seivosa",
"sotando",
"sovação",
"tergais",
"topiada",
"unúmbio",
"uvulite",
"vagadas",
"variosa",
"vigador",
"vomição",
"víbrios",
"vóltios",
"ximbras",
"zadruga",
"abalize",
"aclamei",
"aditara",
"adunado",
"agaloar",
"ajaezar",
"alanhar",
"aliarmo",
"alônimo",
"amolgam",
"anidado",
"arfados",
"assadão",
"bacairi",
"badalai",
"bamboar",
"burreca",
"butucar",
"caranaí",
"cardena",
"cavocam",
"chatina",
"cheleme",
"cremava",
"criarmo",
"cubinha",
"decotou",
"deputam",
"desídio",
"dialisa",
"dioctil",
"durâmen",
"emendes",
"empeças",
"empinas",
"enovada",
"envolem",
"ermitoa",
"escalho",
"essenos",
"esópica",
"exalçam",
"febroso",
"franceá",
"franzis",
"gabador",
"gabança",
"gandras",
"gonguem",
"grampas",
"gravamo",
"guinara",
"gurunga",
"horasse",
"inimiza",
"inquita",
"invitam",
"jambete",
"lanaram",
"loirões",
"longuim",
"léptico",
"líbicas",
"macunde",
"malaica",
"mamilas",
"marasma",
"margeie",
"mendubi",
"navarco",
"outonam",
"ovulará",
"panício",
"pechoso",
"picanas",
"pinhoca",
"querima",
"rapelei",
"recoser",
"riçados",
"rogaras",
"rugimos",
"saludes",
"satoris",
"serenes",
"sobestá",
"suturei",
"teófobo",
"timnita",
"tipismo",
"tobogão",
"trilhai",
"tráquea",
"tufando",
"vacação",
"vicemos",
"virante",
"adastre",
"agudado",
"alcorce",
"amossar",
"amuarem",
"anzinho",
"apicoar",
"armorio",
"arrinho",
"assubia",
"bacuara",
"badalhó",
"bandejo",
"bardara",
"binados",
"boeiras",
"bondado",
"calmara",
"camaria",
"carreie",
"caruatá",
"cegador",
"celomas",
"centese",
"chavona",
"chiismo",
"cifrara",
"cloroma",
"comedio",
"comende",
"copagem",
"corucão",
"cotejem",
"densado",
"dopasse",
"encafua",
"encerem",
"esborro",
"esgaçou",
"expungi",
"flocula",
"florona",
"frouxou",
"fundeei",
"furares",
"físcias",
"galeara",
"garceta",
"guarupu",
"hepteno",
"hâmulos",
"incides",
"infetas",
"iperita",
"locarei",
"límnico",
"marujar",
"munduru",
"orcinol",
"oriquis",
"orçarem",
"pagodeá",
"pepônio",
"pifavam",
"pixurim",
"polaron",
"profete",
"pterina",
"rambles",
"rasavam",
"romamos",
"rulavam",
"soletas",
"solevar",
"talimas",
"tarraca",
"teceras",
"temíeis",
"tetense",
"tibraca",
"tilhada",
"toldara",
"trancai",
"trianos",
"umãzona",
"vaixias",
"acoando",
"alheara",
"alinhos",
"alissos",
"alteara",
"alveira",
"aquiqui",
"arcante",
"arfasse",
"arratar",
"arvéola",
"atelana",
"atopara",
"baforei",
"bajulas",
"banires",
"baratar",
"batelos",
"bitolou",
"bodosas",
"bradais",
"bramoso",
"brequem",
"budleia",
"cabadas",
"cairuçu",
"caletas",
"camotim",
"campada",
"cangula",
"cervato",
"ciumada",
"coactos",
"comanos",
"confuta",
"coreiro",
"corujei",
"culumim",
"cumulai",
"debagar",
"defesso",
"devisar",
"diginos",
"embuçou",
"emproar",
"entroso",
"erreiro",
"escarno",
"escorna",
"facioso",
"falhais",
"franzam",
"galéola",
"girarei",
"guaiava",
"haloide",
"imprevi",
"invaria",
"japuaçu",
"juncoso",
"jurutaí",
"logicar",
"lumeque",
"mafurra",
"maginou",
"maladia",
"mandemo",
"maniada",
"mantéis",
"mariaté",
"mesamãs",
"mobilei",
"moermos",
"nadavas",
"neveira",
"ortézia",
"palaiês",
"palmeou",
"panhota",
"paparaz",
"pelares",
"permuda",
"pilhais",
"pisgado",
"pitador",
"poeirar",
"posares",
"poupamo",
"raciono",
"reelejo",
"reineis",
"revesei",
"rinária",
"rojavam",
"rolavas",
"rosairo",
"rotadas",
"saimiri",
"sanante",
"selário",
"soldura",
"solipas",
"taleira",
"telhudo",
"tiufado",
"tralhou",
"tricoca",
"tálicas",
"urálica",
"varista",
"vistais",
"advenho",
"aflando",
"agostou",
"aleixar",
"arrecua",
"ateaste",
"atrista",
"azoinar",
"babarão",
"balsedo",
"beirame",
"berrata",
"bobinam",
"buriqui",
"buxales",
"capotem",
"cardoça",
"carunho",
"caínico",
"cebruno",
"clinudo",
"debruei",
"deflore",
"distena",
"doparia",
"egirina",
"encorar",
"enfatua",
"entruda",
"enxovas",
"enéades",
"eoliano",
"estafei",
"exultas",
"falario",
"falucas",
"fitarão",
"fluagem",
"foderas",
"forcejo",
"furadão",
"irapuás",
"iâmbica",
"jamaias",
"juliões",
"ladrara",
"lajeira",
"lascara",
"laterão",
"manismo",
"matumba",
"meiágua",
"mercara",
"molassa",
"mucunha",
"ninamos",
"nublara",
"ordinha",
"panoura",
"poieses",
"prateie",
"precava",
"premuni",
"prioras",
"quietei",
"quintes",
"raiares",
"rasados",
"reberta",
"recedes",
"reeduco",
"refundo",
"releixo",
"repasta",
"revesou",
"rosetão",
"rábidos",
"setarem",
"sextava",
"tagalos",
"talaram",
"tebaico",
"trígamo",
"uaimiri",
"ufanias",
"untasse",
"valinas",
"valvada",
"verteis",
"vigiemo",
"vivença",
"vozeira",
"zincita",
"zodíaca",
"abduzam",
"aberram",
"acartei",
"adiador",
"aladina",
"alimpei",
"amauaca",
"andagem",
"anionte",
"assario",
"baileco",
"bispada",
"bocasse",
"bocejas",
"bodejar",
"brinhol",
"bufeira",
"buratos",
"burgada",
"cabungo",
"cacifam",
"caitité",
"caninga",
"casposo",
"cerbera",
"cerveje",
"cinzelo",
"cooptas",
"cooptem",
"coraíta",
"depurai",
"deslace",
"despraz",
"dianhos",
"dobagem",
"désmato",
"emassou",
"embases",
"empalei",
"empírea",
"encopar",
"ensopem",
"entreto",
"enxurra",
"esfumas",
"esvaias",
"etereal",
"etiópio",
"excitai",
"fagulho",
"falarmo",
"fenices",
"ficário",
"flentes",
"galhana",
"gemário",
"gobelim",
"godétia",
"gosmoso",
"gunitas",
"iabassê",
"ibgeana",
"imbuíam",
"inquisa",
"iodismo",
"isentes",
"legrado",
"lembara",
"leérsia",
"limasse",
"lorotar",
"maipuré",
"matosas",
"menaces",
"mingote",
"molidos",
"moliços",
"morremo",
"mutombo",
"natomia",
"ninfete",
"néfrica",
"oporias",
"pajuari",
"perques",
"poalhas",
"prumado",
"ramados",
"rombado",
"rompeis",
"rondeia",
"roçaste",
"rugiria",
"rularam",
"salgues",
"saraquá",
"sofisme",
"subleve",
"suábias",
"tabulus",
"tambula",
"termose",
"tietava",
"trigado",
"trunque",
"tunguei",
"undador",
"volvais",
"zumbida",
"abluído",
"acavala",
"acentro",
"adipsia",
"aferrei",
"algácea",
"alimais",
"ancorai",
"apendar",
"apendas",
"arazoia",
"ateizar",
"auridos",
"balizes",
"batecum",
"bocejes",
"bordais",
"bourais",
"brolhos",
"butinha",
"caldeta",
"canhono",
"carduça",
"carimãs",
"ccxxiii",
"cocuana",
"colinos",
"conviam",
"coreada",
"cossumo",
"cotejei",
"crocina",
"céfalos",
"dançamo",
"delatas",
"denodos",
"digesti",
"dotemos",
"elançar",
"elefoas",
"embrome",
"entalas",
"esgoele",
"esteava",
"eurecas",
"falcado",
"feerias",
"felugem",
"ferdina",
"figadas",
"filosos",
"findeis",
"flirtam",
"gabarei",
"gabarão",
"galeoto",
"garotar",
"gluglus",
"granosa",
"grencho",
"guandus",
"ingesto",
"intonsa",
"jambosa",
"laurano",
"lorigão",
"megalia",
"menteno",
"minuana",
"mirolha",
"modisto",
"moessem",
"mástica",
"namarra",
"ninfeus",
"oznitas",
"pacovão",
"palrado",
"pilante",
"popista",
"prurida",
"quilose",
"rappeis",
"rateiem",
"residas",
"ressaiu",
"retoiça",
"rodeamo",
"râncido",
"sabrada",
"salines",
"tacanas",
"telamão",
"terpina",
"tisnava",
"topiado",
"torroso",
"travais",
"trepemo",
"zonando",
"abássia",
"acoimam",
"acuarem",
"adjunte",
"agrimas",
"alféloa",
"alvorou",
"amassai",
"anafaia",
"apendem",
"apiloar",
"aquelem",
"arestim",
"augurei",
"azeitei",
"bandeie",
"barbaço",
"bastada",
"bironha",
"botares",
"broegas",
"bulando",
"buloses",
"caberás",
"cacicar",
"carunha",
"cefálio",
"chifons",
"chochim",
"colimou",
"condoam",
"corange",
"coroneu",
"curuatá",
"cógnita",
"dobramo",
"domarei",
"durázio",
"eliciou",
"embreou",
"esganem",
"extrate",
"faloque",
"famulas",
"forgões",
"fracola",
"fubecas",
"galaroz",
"imovias",
"japurás",
"jugares",
"lubinas",
"lucrosa",
"maiando",
"maniras",
"marejem",
"mingues",
"moldure",
"molecar",
"muchaco",
"mudubim",
"mufanas",
"mundiar",
"mésicas",
"nicenas",
"néticas",
"olhável",
"oxalose",
"pasteja",
"pertuso",
"pinasse",
"pinguel",
"pinicas",
"pinitol",
"pitaras",
"rabujou",
"rapsoda",
"rebitou",
"rechina",
"redamar",
"redopia",
"refugue",
"regante",
"regatam",
"relacre",
"remanda",
"retaram",
"retelho",
"rosadão",
"rutácea",
"saburro",
"salação",
"sesudos",
"setaram",
"soutava",
"subanel",
"sucatar",
"sufixar",
"sujonas",
"sáfaros",
"tapeteá",
"tralhei",
"treinai",
"velante",
"velinas",
"viajemo",
"vigieis",
"vispera",
"vistora",
"vitácea",
"zambana",
"abrutas",
"acilado",
"adonado",
"agatino",
"agitais",
"agnelos",
"amantas",
"amebose",
"amideto",
"apólise",
"arameia",
"argilos",
"arubana",
"auxilam",
"avicida",
"azuleia",
"bambado",
"barzabu",
"beócias",
"bimbado",
"bipados",
"boquejo",
"botação",
"brabara",
"brômico",
"caborje",
"capemba",
"caponas",
"cevamos",
"chapeie",
"cheminé",
"chitons",
"chuabos",
"cobrais",
"costelo",
"devulga",
"doçante",
"ebânico",
"empacha",
"escalte",
"escavão",
"evolado",
"farsada",
"fatures",
"felídea",
"fisguem",
"forceje",
"gatunha",
"glútens",
"gomitar",
"gorjala",
"grossar",
"gueixos",
"incendo",
"jurador",
"lateira",
"lezirão",
"madeire",
"magumba",
"malista",
"mangles",
"mantear",
"maturis",
"mijarão",
"misraim",
"mofetas",
"mônicos",
"pachaco",
"paginei",
"partejo",
"pegacho",
"pensemo",
"peralto",
"pevinha",
"pirraço",
"plócama",
"pésures",
"ralídeo",
"rebanha",
"releste",
"remanse",
"reptado",
"ritmito",
"ruassem",
"sambuco",
"sediela",
"serenai",
"soslaia",
"testico",
"tocarmo",
"toreuta",
"trompar",
"unímano",
"urarina",
"urodelo",
"ustório",
"vadeiam",
"vitável",
"volitou",
"abalona",
"abácida",
"acaecer",
"adonava",
"afeteis",
"afoitei",
"ajoujos",
"alarida",
"alvejem",
"alvidas",
"ambolós",
"arabaca",
"aranham",
"arbelos",
"arrinia",
"assemos",
"atrapem",
"bajoulo",
"balasto",
"bastiam",
"bissete",
"blablás",
"bramida",
"bregado",
"bétilos",
"cairela",
"calcine",
"camaral",
"camarço",
"canelha",
"caraços",
"cendais",
"chabelo",
"coaliza",
"croinha",
"curupaí",
"dealbou",
"detraem",
"doseiam",
"elegeis",
"encimas",
"encodam",
"enteque",
"esgoelo",
"esnobem",
"estesio",
"evitamo",
"feitada",
"feniana",
"flainar",
"folioso",
"frearão",
"fundamo",
"gabacho",
"gamaste",
"ganaras",
"gentaça",
"guaraçu",
"gurgite",
"injesta",
"irrigas",
"isônomo",
"languir",
"latirão",
"lumioso",
"maciana",
"maconho",
"maxixar",
"meladão",
"membira",
"merídio",
"mingado",
"mobilam",
"molhamo",
"morfada",
"mugimos",
"mumbebo",
"nicando",
"nogados",
"oleador",
"ornamos",
"ozenosa",
"palombe",
"papalão",
"parinho",
"parvice",
"pelanco",
"pertita",
"picumno",
"pinceta",
"polires",
"pontoar",
"pontoou",
"porisma",
"poteris",
"povoais",
"putrião",
"pírrico",
"ragadas",
"rampada",
"rapeira",
"raporte",
"rasteia",
"regirem",
"relavar",
"rendeis",
"repaire",
"requife",
"saisina",
"sapotas",
"saquete",
"saranga",
"semioca",
"siriria",
"sísifos",
"sólitos",
"telever",
"tilhado",
"tonarão",
"tramelo",
"trassas",
"traveso",
"trelido",
"trissar",
"trutina",
"tubante",
"táurica",
"udmurte",
"vedarão",
"vogante",
"abideno",
"acciano",
"acolada",
"adamada",
"afoutar",
"aljubes",
"aluares",
"amarila",
"amplies",
"aperrou",
"arnados",
"arredai",
"arroste",
"atorada",
"avençam",
"bastose",
"baxados",
"boresca",
"bramado",
"bravito",
"breagem",
"briales",
"báctrio",
"camoeca",
"camunos",
"caurins",
"ccxliii",
"cevavam",
"chefiem",
"chitado",
"choutar",
"cinjamo",
"ciumado",
"cocegue",
"coedita",
"cognado",
"conetas",
"denudar",
"desteço",
"ecardes",
"eclábio",
"efélide",
"embaías",
"emponta",
"encapem",
"encarro",
"encetas",
"enovelo",
"enquiro",
"enálage",
"esflora",
"estivei",
"farjuta",
"fatoras",
"fugando",
"fumadão",
"furungo",
"galense",
"gamenha",
"gradeou",
"gruídos",
"helíaca",
"humanes",
"iriadas",
"jagadas",
"laceira",
"lianças",
"lituras",
"límpias",
"magicam",
"maleado",
"matateu",
"mazelar",
"maçavam",
"meriana",
"milhada",
"miliana",
"muxinga",
"nebline",
"nervado",
"nojosos",
"oncídio",
"ornaste",
"ornejar",
"ovoidal",
"pendoar",
"pironas",
"pixinas",
"plagias",
"quiquem",
"ramador",
"rebecão",
"relasse",
"renteia",
"renuído",
"reporei",
"revires",
"safista",
"sasseia",
"seletar",
"sinados",
"sorábia",
"surdida",
"sêmicos",
"tapiíra",
"taquaré",
"titaras",
"titicar",
"torcias",
"tripada",
"tripais",
"vacunal",
"veiamos",
"vernons",
"zambras",
"abarcai",
"abotoem",
"adjudar",
"aeciana",
"arreiga",
"asinais",
"asseava",
"assesto",
"ataxito",
"auridas",
"autumno",
"avindas",
"avultem",
"babaojé",
"barbete",
"barlete",
"bitínio",
"brincai",
"bílicas",
"calambá",
"caquera",
"catorra",
"catucai",
"caxinxe",
"coartam",
"colhado",
"corpudo",
"crepito",
"desolha",
"devises",
"dosaria",
"efemina",
"emanais",
"empando",
"emparam",
"enlevem",
"enxovai",
"esbaram",
"esfaima",
"esfazer",
"esfuzia",
"estreai",
"fenagem",
"fremido",
"fóssula",
"genesim",
"geresão",
"grassei",
"harpear",
"irânios",
"jaspeie",
"joelhei",
"jussivo",
"lanugos",
"leucona",
"lindera",
"linquem",
"lornhão",
"lucaios",
"macanjo",
"mamagem",
"mandiba",
"mangofa",
"maçagem",
"maçarei",
"meloais",
"mitismo",
"mourais",
"narreis",
"nazareu",
"oasiano",
"obraste",
"opinais",
"ovistas",
"palmara",
"paquara",
"pelosos",
"perquem",
"pichico",
"plainei",
"polinar",
"pontava",
"précios",
"quedano",
"rabdoma",
"raivava",
"raizado",
"rareiem",
"reflore",
"refojos",
"refrato",
"regrara",
"rexista",
"rigente",
"rosismo",
"rudinho",
"rufados",
"rímicas",
"sabadim",
"sambica",
"signino",
"solteis",
"sonegas",
"subinte",
"sífiles",
"tamiana",
"tinguem",
"traceje",
"traslar",
"troneto",
"troteou",
"tênseis",
"uberava",
"ultimei",
"unípara",
"vadiice",
"verense",
"vernada",
"volteja",
"xeretem",
"zanzado",
"abolete",
"acachar",
"acertai",
"adjuvar",
"afoitem",
"agulhou",
"alimpam",
"amacias",
"amareei",
"apisoar",
"aquetas",
"aragoês",
"arietas",
"arrenta",
"arriara",
"atrapou",
"balhaus",
"baqueie",
"belotas",
"bichoco",
"bideira",
"bolicas",
"cabalou",
"capisca",
"cariano",
"caverno",
"cavucam",
"caçarás",
"chanela",
"chitina",
"coparam",
"crinone",
"cíticos",
"damaria",
"debotou",
"deputou",
"domário",
"duradas",
"embarba",
"empeceu",
"empolei",
"enganha",
"envasem",
"envinha",
"epírico",
"especio",
"evoques",
"exornar",
"exumara",
"fitilha",
"forques",
"funeste",
"ganhoto",
"garapão",
"gradeia",
"imunizo",
"ingenue",
"iscamos",
"jambire",
"jarinas",
"jazidão",
"laqueei",
"lavouro",
"levável",
"librais",
"lisboês",
"malhara",
"mamanga",
"manerio",
"mauzote",
"meruges",
"mornais",
"ojerizo",
"oleácea",
"oologia",
"ovótide",
"oxamato",
"palpais",
"pandara",
"penense",
"pensoso",
"perfaze",
"pindova",
"piteiro",
"pomária",
"portita",
"pragado",
"predirá",
"prátias",
"prótido",
"puteado",
"puvitas",
"rameado",
"rasarem",
"reaviar",
"rebolco",
"rebolir",
"redubla",
"retenes",
"rosaram",
"rumação",
"rutilou",
"sitônio",
"soltemo",
"subsola",
"suritas",
"súperos",
"tapeçar",
"tiazina",
"trapite",
"tuidara",
"vadiada",
"varilha",
"velenho",
"versiva",
"vinário",
"zanzada",
"ármicos",
"abateis",
"abjures",
"ablanda",
"acerbar",
"adjeção",
"afiguro",
"agaveta",
"aguanos",
"aguaria",
"aguilhó",
"almense",
"alugamo",
"alveado",
"amasiam",
"apalpos",
"apiedes",
"arcadão",
"arrases",
"arupema",
"aviares",
"avérneo",
"babujou",
"bebaças",
"betumem",
"bocaxim",
"bocoiós",
"brindai",
"butarga",
"canaimé",
"capeiam",
"catives",
"ccxxvii",
"cesurar",
"chinele",
"chócues",
"clarine",
"congote",
"conurba",
"copilas",
"corróis",
"crispes",
"cutucas",
"dealvar",
"deslado",
"disteis",
"ditares",
"ecroneu",
"empalhe",
"encasar",
"endenta",
"enética",
"espadar",
"esparjo",
"esquice",
"estreça",
"eusomos",
"exigimo",
"faceias",
"fanfula",
"fazente",
"fialhos",
"flamejo",
"fovéola",
"franges",
"frangir",
"fumista",
"gabaste",
"gamaria",
"gameira",
"garanço",
"glomera",
"glúcido",
"gradões",
"gramema",
"incutes",
"injetas",
"intrujo",
"leiloem",
"letônio",
"libongo",
"lorigas",
"luitado",
"mainate",
"manitus",
"mararam",
"matupás",
"melatos",
"minutam",
"minutei",
"miranha",
"mitreus",
"muchado",
"nigrans",
"nominem",
"olivada",
"opúncia",
"pancaia",
"partija",
"pencada",
"perimir",
"plotava",
"plumões",
"pontoas",
"porruda",
"poucona",
"ratonas",
"rebrama",
"repairo",
"retalio",
"retanto",
"rimares",
"roboram",
"réticos",
"sachava",
"sagácia",
"saldeis",
"selênia",
"solucem",
"sonhamo",
"taxarei",
"tropeia",
"tábidos",
"vacinha",
"veneres",
"xiistas",
"xântico",
"zipador",
"zoritas",
"abnuído",
"aceiram",
"acerosa",
"afiemos",
"afingir",
"albornó",
"alteias",
"amantem",
"antojar",
"apestar",
"aquelar",
"ardenês",
"armânia",
"assomas",
"atabuas",
"atrelas",
"aviasse",
"azarola",
"azinhos",
"bailhar",
"baleara",
"beninês",
"berzabu",
"bibicos",
"bolônia",
"burgaus",
"burjaca",
"cabreia",
"caiarem",
"caieiro",
"cambrai",
"canutos",
"caparia",
"cassamo",
"cavares",
"chacine",
"chuleie",
"cindira",
"codagem",
"coniose",
"corinas",
"cortino",
"danaide",
"dcclxxv",
"deontos",
"descoso",
"desvejo",
"diruído",
"dotarmo",
"drainei",
"dulçura",
"edentes",
"emaçado",
"embesta",
"embolha",
"enfolha",
"ensalma",
"erodiam",
"estorça",
"faiarei",
"feionas",
"finaste",
"flabela",
"florava",
"funante",
"fundano",
"galopem",
"ganirem",
"guidado",
"hebetar",
"hematos",
"hiemais",
"ilhamos",
"inhalas",
"intrica",
"labarda",
"ladroar",
"logreis",
"lucilei",
"maquins",
"marelas",
"matusca",
"meandra",
"minazes",
"miritis",
"motamos",
"moçonas",
"mumbava",
"neurosa",
"nevaram",
"obsedou",
"oiremos",
"omitimo",
"optavas",
"orilhas",
"orôncio",
"ossetos",
"outavas",
"ouvidão",
"ouvíeis",
"palária",
"paragão",
"patruça",
"peneano",
"pernaça",
"perruca",
"pifaria",
"pinitas",
"pólices",
"rambotã",
"rebimba",
"relevas",
"remanga",
"remares",
"remesse",
"resumas",
"riceira",
"rimante",
"rinchou",
"rizotos",
"safatas",
"sambaré",
"saraíba",
"sedavam",
"sereres",
"sidinha",
"sodador",
"talipes",
"tarifou",
"tebaica",
"tetrose",
"togares",
"toquedá",
"tosquio",
"trieras",
"trigode",
"triplax",
"trágulo",
"uivadas",
"unenses",
"unireis",
"vinhame",
"vivermo",
"volatim",
"zimeose",
"abojado",
"amareli",
"anelete",
"aromava",
"artemão",
"asarina",
"assisem",
"astilho",
"bajemos",
"balizei",
"baloice",
"besigue",
"binando",
"bolônio",
"bubulco",
"burilei",
"caibral",
"cambolo",
"ccxxxii",
"ccxxxiv",
"cicioso",
"cimélio",
"coelhal",
"coligas",
"copiais",
"cornado",
"couchés",
"cultuai",
"cumulas",
"cáligas",
"desafaz",
"devorai",
"domemos",
"dosasse",
"dragava",
"embeiça",
"emigres",
"empecei",
"emprazo",
"enchais",
"enfrute",
"enrista",
"ensonou",
"entufar",
"enócoas",
"eonista",
"escudas",
"esfiada",
"expugna",
"faldras",
"fatoram",
"flajolé",
"franzas",
"futurou",
"ganitas",
"garotou",
"gaudies",
"geminas",
"gerirão",
"geriste",
"grandor",
"guaretã",
"inerido",
"inflexo",
"involua",
"jacapau",
"janecas",
"kabuqui",
"lacerna",
"lasseia",
"lembado",
"liceana",
"lixemos",
"logaria",
"loteiro",
"lunanco",
"magoeis",
"malpica",
"meninil",
"minhana",
"minotos",
"mochada",
"mofasse",
"molungo",
"mondava",
"monopes",
"mulolas",
"muxoxar",
"negocos",
"nunciam",
"obtusão",
"permeei",
"pingato",
"poerias",
"prepusa",
"puniste",
"puxaras",
"pândita",
"quizaca",
"quérula",
"rabaças",
"raceado",
"raiante",
"recenda",
"reforje",
"regacei",
"reigada",
"relador",
"retuíto",
"rimaste",
"sapudos",
"secaras",
"setiana",
"superai",
"tacador",
"tanatau",
"tanaíta",
"tarimas",
"tataúba",
"tocanos",
"tolhiça",
"torrica",
"triamos",
"tunador",
"turrado",
"ubacaia",
"ubaraba",
"umbonal",
"unturas",
"ureicas",
"utensis",
"vaiemos",
"verdeje",
"vidroso",
"víscido",
"absinte",
"acervar",
"acoimem",
"acolado",
"acurana",
"adelite",
"adregue",
"afundiu",
"alceada",
"almarge",
"alteiem",
"alvinos",
"amarume",
"amateus",
"amorios",
"andejas",
"antiste",
"anzicos",
"arderes",
"armista",
"ascomas",
"atascal",
"atazane",
"atempar",
"atenças",
"avernos",
"banhuns",
"bigrama",
"boqueja",
"botoado",
"brantas",
"bródios",
"buriata",
"cabíeis",
"cadiuéu",
"cafeíne",
"calonas",
"cambava",
"canhola",
"canilha",
"caxixes",
"ccclxxv",
"cloquem",
"coiceia",
"coisava",
"colaças",
"crossas",
"cuprosa",
"deflexo",
"dejetam",
"desteta",
"deszipe",
"devirem",
"digites",
"dimanem",
"dormimo",
"dresina",
"duidade",
"díssono",
"elativa",
"eleanos",
"emitias",
"empegar",
"encalar",
"enroque",
"entojar",
"eolismo",
"escarne",
"exalcei",
"faixais",
"faleces",
"fruiria",
"ganisse",
"ginguei",
"iapoque",
"ilhamas",
"impunis",
"inerais",
"insoles",
"jurante",
"lanares",
"laticar",
"libente",
"lápsana",
"malatos",
"mamante",
"meminho",
"merejar",
"minguta",
"modique",
"motejam",
"mourada",
"muitias",
"nedinho",
"osculei",
"palitem",
"panhões",
"pecavas",
"pocados",
"portemo",
"posador",
"pragada",
"puxanço",
"pópulos",
"quintam",
"quirica",
"quirina",
"quitata",
"rebolca",
"remaste",
"repedia",
"restivo",
"shuntar",
"silenes",
"socairo",
"solveis",
"sonaste",
"sorvias",
"surrais",
"sutando",
"tabaibo",
"tartada",
"tascada",
"tcheque",
"toeiras",
"trevina",
"trietil",
"trigues",
"tristas",
"trécula",
"turbais",
"ungirei",
"velaras",
"velauno",
"velejem",
"vintona",
"vozeiam",
"vágados",
"xadreze",
"zoadora",
"zumzuns",
"áglifas",
"abaetes",
"abduzia",
"aclames",
"agapeto",
"alações",
"aliarei",
"alienes",
"almejes",
"almoces",
"anúrico",
"apenhar",
"arrozão",
"arrufou",
"arílico",
"avirama",
"axênica",
"baleava",
"balurdo",
"betônia",
"bodeira",
"calaico",
"carrola",
"cateado",
"catuíba",
"cocamas",
"cocegar",
"cocemos",
"cofiado",
"corares",
"danemos",
"dcclxii",
"delibar",
"demorai",
"desguia",
"desliar",
"ecasses",
"efundiu",
"eixaram",
"embarro",
"enações",
"encodou",
"enjooso",
"enraiva",
"esguiar",
"esvelto",
"exhumar",
"faulhar",
"ferócia",
"filipem",
"forcemo",
"frisura",
"furagem",
"fúndico",
"gambiar",
"iludias",
"imutado",
"imuteis",
"iratauá",
"jacular",
"justita",
"labuzar",
"lerdaço",
"lisação",
"lobotes",
"lugente",
"magoais",
"manaíba",
"mangaço",
"mareais",
"marimbe",
"medelim",
"minoras",
"molhais",
"montina",
"montosa",
"mugisse",
"musácea",
"mutilas",
"málicos",
"objetas",
"odoriza",
"ombruda",
"onglete",
"otócion",
"ourinol",
"pelarem",
"pendoou",
"perlito",
"piadona",
"pipinar",
"pretira",
"pútegas",
"quinoto",
"rafadas",
"raleado",
"recenes",
"relarem",
"relegas",
"relojos",
"rentear",
"repisem",
"requiro",
"riparem",
"rosarem",
"roçarão",
"sabente",
"sameira",
"savalus",
"sicanos",
"suspeso",
"tagalho",
"terroro",
"troicas",
"ufanoso",
"unanimo",
"vazaste",
"vertias",
"voáveis",
"zambuja",
"zurrada",
"abexiga",
"acedias",
"acuaria",
"adaptai",
"adimple",
"aforava",
"alcorça",
"alárabe",
"amônios",
"anilhou",
"anáfega",
"apetita",
"apitais",
"apouque",
"arrulhe",
"atelano",
"atoando",
"atuaste",
"atufava",
"atuável",
"augurem",
"austros",
"azoicos",
"babarei",
"baronal",
"bauxito",
"bigoudi",
"bingado",
"biontes",
"bispais",
"bílicos",
"capteis",
"cardais",
"ccxxxvi",
"cernido",
"cevaste",
"chilram",
"cigalho",
"cinzele",
"clonará",
"coarcte",
"colomim",
"coniina",
"convale",
"crerias",
"cuveiro",
"dedaram",
"deduzis",
"denguim",
"destoas",
"dosavam",
"eletriz",
"embique",
"eneides",
"enfeiem",
"enfocas",
"enojara",
"envaida",
"especou",
"espediu",
"esquine",
"estumar",
"exúbere",
"farinar",
"fedente",
"floemas",
"forneio",
"fradeco",
"fuçaria",
"fóticas",
"gabemos",
"gangela",
"gansada",
"geminam",
"gericas",
"grasnei",
"hidruro",
"impones",
"imutada",
"insulou",
"jampeie",
"jogatar",
"juncava",
"laçarei",
"leucite",
"linguei",
"lourear",
"lubambo",
"malinou",
"mamadão",
"margeio",
"mendobi",
"metilar",
"molongó",
"muchope",
"mugidor",
"muradal",
"naftóis",
"nulária",
"orvalhe",
"osmanto",
"panguei",
"parinas",
"pedíeis",
"percuti",
"pezunho",
"picarei",
"pironga",
"pisanas",
"porrina",
"prantam",
"primoro",
"prófuga",
"punires",
"quicará",
"rabeado",
"rechino",
"refalar",
"refreai",
"remitia",
"rendará",
"ressoca",
"rigados",
"ritmará",
"rosamos",
"roteava",
"ruptora",
"sagramo",
"sarambé",
"sarneto",
"sarçais",
"sebruno",
"segamos",
"sestras",
"siglada",
"soalhal",
"sopesei",
"suadona",
"sucosos",
"sufitas",
"tafulho",
"tapichi",
"taxiado",
"terçava",
"testamo",
"tinirem",
"tranemo",
"tuitará",
"vergais",
"vigadas",
"vitrosa",
"volumas",
"volveis",
"zagaiar",
"zavando",
"abestos",
"acetose",
"adentou",
"afanara",
"afunilo",
"agrador",
"alvenel",
"alvides",
"alvinas",
"amirado",
"andalas",
"aninhes",
"apagamo",
"aplumou",
"arrular",
"assazes",
"assunes",
"ateriam",
"avinham",
"avistes",
"baldeei",
"banidor",
"barbeis",
"barbiel",
"barrira",
"bicames",
"bifaria",
"bojando",
"bonitez",
"boxeado",
"breados",
"bubidas",
"caiemos",
"caixana",
"calcamo",
"caçavas",
"cerziam",
"cetanas",
"chapara",
"coadora",
"coalhem",
"cofinho",
"colides",
"condize",
"consona",
"contana",
"cozeste",
"cromoso",
"cubamos",
"cutinha",
"desabri",
"desamos",
"desasou",
"desgabo",
"desmite",
"duraras",
"encalca",
"encubam",
"engasto",
"enrugas",
"erativa",
"esbarou",
"esfênio",
"esmoleu",
"exempta",
"flirtou",
"fubicas",
"fulares",
"fusiono",
"gataram",
"honremo",
"houvida",
"inanido",
"incoada",
"instilo",
"inveraz",
"irardes",
"isoleis",
"justura",
"leniria",
"lindice",
"lobazes",
"mandras",
"maparás",
"mareias",
"marrete",
"matical",
"meiotes",
"meliana",
"memorem",
"merquem",
"mijante",
"miravas",
"monidos",
"morgava",
"nenecas",
"nhanhás",
"niquele",
"nitrona",
"ofegado",
"omitais",
"opugnar",
"pachtos",
"peitara",
"penarão",
"peralte",
"percuta",
"pinhola",
"pirador",
"pitamos",
"plerema",
"porejou",
"prozada",
"pulique",
"querona",
"rabusco",
"rateara",
"reemita",
"regueis",
"relhaço",
"remetas",
"resinga",
"rexismo",
"riboses",
"rincham",
"ringido",
"rodaras",
"rótolos",
"sanfeno",
"sarrafa",
"sobeira",
"sogatas",
"someira",
"síntono",
"taititu",
"taratas",
"taxemos",
"temeros",
"tibemos",
"timbrei",
"tintila",
"todároa",
"tonsure",
"torenas",
"trajara",
"tramais",
"trasvia",
"tricola",
"urgebão",
"vençais",
"viriais",
"vodunce",
"vogarem",
"zingado",
"acinoso",
"acionas",
"adônico",
"aluanda",
"ameraba",
"amimava",
"amotine",
"anistio",
"ararmos",
"atacamo",
"aticuns",
"atoagem",
"azeitem",
"babatar",
"babujam",
"bacento",
"bagaria",
"balidas",
"barrelo",
"bicanço",
"bilenar",
"bingara",
"calante",
"calinos",
"camotes",
"canopis",
"capamos",
"caquino",
"caragas",
"cargoso",
"causamo",
"chameia",
"chaneza",
"charolo",
"chimano",
"cinzais",
"cipoaba",
"cobrear",
"codaque",
"coluros",
"comíeis",
"corteei",
"cotidal",
"crocito",
"cuecona",
"dardejo",
"debites",
"debotam",
"desmudo",
"dilogia",
"diáfora",
"doantes",
"embebes",
"empanco",
"enroupa",
"enseios",
"ensejas",
"entales",
"enxamel",
"escarar",
"esmaeço",
"estafim",
"evolada",
"feitado",
"femínea",
"fulecar",
"galilés",
"galrear",
"ganeiro",
"geofono",
"gizarem",
"gizavam",
"gorgota",
"gulosei",
"houviam",
"ibibios",
"imiscuo",
"inconho",
"inqueri",
"ionista",
"jaldete",
"jeguedê",
"lagalhé",
"lagrimo",
"lidaras",
"lincava",
"lutitos",
"macelão",
"malaxar",
"malevas",
"matolas",
"merouço",
"metália",
"miólise",
"moengas",
"mornaça",
"muxinha",
"nasçais",
"nichada",
"ninarem",
"ossanas",
"pareias",
"peltado",
"pequice",
"perneia",
"petreus",
"piloura",
"pinarem",
"pingaço",
"pirismo",
"pneumon",
"pocilgo",
"pontigo",
"predava",
"prestal",
"profaço",
"protula",
"puníceo",
"quartau",
"quietam",
"quícios",
"ramando",
"ranguei",
"ratafia",
"ravasco",
"redeira",
"refusou",
"regalão",
"reganhe",
"regerei",
"repeteá",
"retaque",
"reviera",
"ridicar",
"ridicas",
"rizamos",
"roldões",
"salinar",
"sanemos",
"secanos",
"sessará",
"sexando",
"solamos",
"somaras",
"sopreza",
"sovinha",
"supurei",
"tarecas",
"tinalha",
"tlintar",
"toxique",
"tubares",
"tupidas",
"untaria",
"uricana",
"vaiarei",
"vaporei",
"verendo",
"vidasse",
"voadura",
"xávegas",
"zooteca",
"abadões",
"abexedo",
"abrocha",
"abádida",
"achanta",
"acharar",
"acordal",
"acotada",
"acurvar",
"adinhos",
"adueiro",
"alcídea",
"amentas",
"andarmo",
"anguina",
"anoxias",
"apaises",
"apendam",
"apiária",
"apoleia",
"aptando",
"apétala",
"apófase",
"ararama",
"arcelas",
"arcoses",
"areosas",
"atambor",
"ateador",
"baixamo",
"basutos",
"betumou",
"blefada",
"bochaca",
"bolhuda",
"botecas",
"bouçado",
"breches",
"cacholo",
"cambres",
"cancere",
"caneado",
"caroque",
"cassico",
"catombo",
"catrame",
"cccxxix",
"cerácea",
"cestada",
"chousos",
"chupita",
"cidrada",
"ciprino",
"cleares",
"codinho",
"coltada",
"coltado",
"condeia",
"coprimo",
"covetas",
"culpais",
"dedigna",
"defunte",
"delates",
"doestar",
"emagrei",
"embuche",
"emmeios",
"encondo",
"engulha",
"escogio",
"esfrola",
"eslavão",
"estirol",
"estreis",
"famense",
"fangana",
"fatiara",
"finaria",
"fincões",
"flambam",
"forinte",
"formina",
"fornias",
"frontam",
"fumigam",
"furemos",
"furlana",
"garriça",
"gemeras",
"glícera",
"glífico",
"grazino",
"gurupiá",
"infixas",
"ionismo",
"irianas",
"itureus",
"lactoso",
"lacunes",
"ladária",
"lampuga",
"landesa",
"lapamos",
"lapuzes",
"laredos",
"larense",
"leixava",
"línicas",
"macegal",
"magruço",
"malismo",
"mamilão",
"marlota",
"mcmlxix",
"mcmlxxv",
"mentano",
"meretiz",
"miantes",
"micondó",
"misuras",
"motejou",
"mudamas",
"nanomol",
"nastias",
"nhurros",
"ninaria",
"noritos",
"olongos",
"orneias",
"oveiras",
"parires",
"percole",
"pinaria",
"piraste",
"pisguem",
"precavo",
"previve",
"prítica",
"putedos",
"reboara",
"refogas",
"rendais",
"repoisa",
"reverás",
"ribitol",
"rocemos",
"rojaram",
"rufador",
"rumares",
"rutílio",
"sessava",
"sinucar",
"sojugar",
"sotanas",
"sotíaco",
"sovavam",
"sulfido",
"tareira",
"tatuetê",
"teratos",
"tersura",
"tetadas",
"tiloses",
"tiritou",
"topador",
"tucanei",
"uacimas",
"urgirem",
"vadiara",
"vandara",
"vaziada",
"violita",
"visarei",
"visivas",
"viçavam",
"zagonal",
"abulias",
"acapori",
"adafina",
"aduchar",
"aeragem",
"afilava",
"aiereba",
"alboreá",
"alfaque",
"aloiram",
"amainem",
"andejam",
"aricará",
"arigofe",
"arrolho",
"atantes",
"atibais",
"atinhas",
"atocaia",
"auroram",
"bailhão",
"balogum",
"bangolo",
"bangulê",
"bargada",
"barrano",
"bateado",
"betados",
"bigotas",
"biorana",
"biplana",
"bitolem",
"bobeiar",
"boiçado",
"bolarei",
"borlado",
"botante",
"bozenga",
"brigamo",
"bromina",
"brídege",
"bufidos",
"burraca",
"cacheio",
"cagavas",
"cambala",
"capitiú",
"cararás",
"carmesi",
"carumba",
"casques",
"caverne",
"cavirão",
"caxotão",
"cevarem",
"cinchar",
"cloques",
"cruzais",
"cumanas",
"decupei",
"degrede",
"denguem",
"desabre",
"descrês",
"desguie",
"desunam",
"dezenos",
"dezinco",
"dimitis",
"dioxana",
"dosável",
"duchada",
"díceros",
"edótica",
"elongar",
"emitimo",
"enjaulo",
"escaque",
"esparzi",
"esponto",
"esteará",
"faiaram",
"falcoas",
"falécio",
"finense",
"folotes",
"furunfo",
"fístico",
"gavadas",
"granida",
"guapeba",
"heptose",
"hexanos",
"homídio",
"hosteia",
"inoveis",
"iriados",
"jinjilo",
"jogarmo",
"lacação",
"laganha",
"largais",
"leabeus",
"ligória",
"lombudo",
"lotecas",
"lusória",
"luzires",
"malicie",
"marajós",
"marmada",
"matanga",
"mendico",
"morotós",
"murador",
"musgado",
"muxaxos",
"nembrar",
"nomeamo",
"nutrimo",
"olhadão",
"oroanal",
"ossonhe",
"pacalho",
"palhuço",
"paliada",
"panacum",
"panemas",
"pangará",
"panhame",
"pastoro",
"pateava",
"pestilo",
"pirenta",
"pojante",
"porreto",
"prainos",
"psórica",
"puceiro",
"quilhou",
"ratense",
"ratuíno",
"redoura",
"refenda",
"regaçam",
"reizete",
"remordo",
"remudar",
"reputas",
"resaque",
"restita",
"retocas",
"ritismo",
"rotules",
"salagre",
"sessoto",
"sesteia",
"sinales",
"sionita",
"solavas",
"soremas",
"subedar",
"taipada",
"taqueia",
"tugindo",
"tunídeo",
"táurico",
"usaremo",
"vimaria",
"vitimes",
"vólvoce",
"xairéis",
"zumbado",
"abastam",
"abismem",
"aburres",
"abusais",
"acanhas",
"achatas",
"acionai",
"acorres",
"adoando",
"afinque",
"aflante",
"aganara",
"aguerro",
"alatina",
"albugem",
"alifano",
"aluviar",
"amasiei",
"ambulam",
"anelara",
"anodize",
"apalear",
"apoucas",
"arapucu",
"arretes",
"ascumas",
"asselam",
"astilha",
"avencei",
"avencem",
"axinita",
"azerado",
"açudada",
"babaréu",
"baianim",
"bajoujo",
"barbote",
"birutes",
"bisotar",
"bloquem",
"bomárea",
"borcado",
"burgado",
"butaria",
"cabanão",
"cacetou",
"calcaré",
"carrião",
"ceratos",
"ceviano",
"chameli",
"chantou",
"chochou",
"clocada",
"coarmos",
"coirela",
"comermo",
"conchar",
"cotriba",
"covagem",
"cravatá",
"creions",
"cricrió",
"croácio",
"danaria",
"deligam",
"desecar",
"desnato",
"disside",
"dissidi",
"ditaste",
"eguando",
"embaíba",
"embolas",
"empiora",
"enfeuda",
"enfusas",
"envesga",
"escapai",
"estremi",
"exploto",
"fenadas",
"fervoro",
"filares",
"fontino",
"franger",
"gabiais",
"galalão",
"ganizam",
"ganzepe",
"garitas",
"gatamos",
"glífica",
"gonzado",
"graçona",
"hamaide",
"hermela",
"hexatlo",
"hioidea",
"hirteza",
"imolais",
"incasta",
"inquino",
"iodinas",
"lanilha",
"ligular",
"lineado",
"logicam",
"luandos",
"ludares",
"luinhas",
"maclura",
"mamucha",
"manados",
"manicas",
"manjuva",
"maquera",
"maridou",
"megalos",
"mimeira",
"molamba",
"moquéns",
"morfino",
"morruda",
"napeiro",
"nesitas",
"obrante",
"ocaruçu",
"opacada",
"oxálida",
"paltita",
"pastais",
"patolou",
"pausais",
"penaste",
"piassem",
"pintais",
"piratam",
"pisadão",
"premune",
"proedro",
"prótilo",
"pulposa",
"punçado",
"quedana",
"rabeiam",
"rasaram",
"recamam",
"remorto",
"repolir",
"rimação",
"rodadão",
"rogável",
"rondeis",
"salveta",
"sapeava",
"sarcode",
"secadal",
"seurbos",
"sexenal",
"solante",
"sonheis",
"sovasse",
"subcopa",
"tardona",
"teatine",
"torvava",
"tremada",
"trinida",
"tuberal",
"umotina",
"uremias",
"uxipuçu",
"vanecia",
"vatinga",
"vesanos",
"ástrica",
"êxedras",
"abestas",
"abrades",
"achinês",
"acinzar",
"afinfei",
"agógico",
"ajudeio",
"albacor",
"alcades",
"aletras",
"algozar",
"amestre",
"amoitam",
"angulou",
"anojoso",
"apearia",
"aproeja",
"arassem",
"ascídio",
"assolas",
"aterres",
"ativera",
"atracas",
"avexava",
"avigore",
"bambuda",
"batilha",
"biaribi",
"biparti",
"biscaio",
"biseles",
"blindas",
"bodelho",
"bolçava",
"bonicos",
"bostais",
"brocais",
"cabeiro",
"cafetal",
"caimita",
"cainham",
"calazas",
"cambica",
"candeus",
"capanda",
"capatão",
"caraúba",
"cardamo",
"cardava",
"carizes",
"carnaça",
"carpela",
"caxiris",
"caçapos",
"cerrais",
"champis",
"coiceou",
"concliz",
"copador",
"copiúba",
"crivoso",
"cuerudo",
"curvais",
"defeção",
"digeres",
"dioptra",
"ditotes",
"embalai",
"emolhar",
"encende",
"enlameá",
"entupas",
"equipai",
"escusai",
"esmifre",
"esmonde",
"espires",
"etilize",
"fanarem",
"farreio",
"ferramo",
"fisales",
"furável",
"garapés",
"garaúna",
"garfava",
"garmita",
"gauchar",
"gaudere",
"gemagem",
"gemamos",
"gerecia",
"glasura",
"goravam",
"grafais",
"grasnem",
"guaxica",
"ibérias",
"imponte",
"infirmo",
"içassem",
"lalando",
"lamegos",
"lapídeo",
"lazúlis",
"lemiste",
"limavas",
"litondo",
"locaias",
"logarei",
"lotarei",
"luzeira",
"lãzudos",
"líbicos",
"maligne",
"mangola",
"manguço",
"manicue",
"marioca",
"mcmxciv",
"mirarei",
"miserar",
"molejar",
"moquiço",
"muriato",
"muxoxou",
"obração",
"octanal",
"opífice",
"paninha",
"penteai",
"perfixo",
"peruado",
"petando",
"pliquei",
"pomáceo",
"príamos",
"quartam",
"rebatei",
"recapou",
"recomer",
"recopie",
"recunho",
"recurou",
"redando",
"reisada",
"requerá",
"rifaria",
"rodista",
"rumados",
"runjebe",
"salmear",
"salácia",
"saneiem",
"sonoite",
"surcada",
"sáxones",
"tataria",
"taxadão",
"tempões",
"timonar",
"trasluz",
"tremele",
"tripado",
"uivares",
"vazimba",
"viborão",
"xantino",
"ximbico",
"abastem",
"abridos",
"abundas",
"acabias",
"acostem",
"adamite",
"adobado",
"agourai",
"ajurana",
"ajustai",
"albente",
"alimpou",
"almaços",
"alumbro",
"amenaça",
"amossam",
"amuares",
"apenava",
"aperema",
"apíneos",
"aquetai",
"aquátil",
"arcense",
"ariante",
"armengo",
"arrance",
"arrates",
"asdodeu",
"assaras",
"assolda",
"astucio",
"atafais",
"atariam",
"aterina",
"avelada",
"avelhas",
"avoquei",
"azotato",
"baforam",
"baliste",
"baradas",
"baração",
"baritas",
"barreis",
"bendega",
"bilolas",
"bipador",
"bisalta",
"bocanha",
"bolhado",
"bondara",
"boquila",
"botocar",
"boxador",
"brabura",
"bulbite",
"bundais",
"buxeiro",
"cacifre",
"cagarim",
"canacas",
"canolas",
"cantari",
"carreno",
"caçadão",
"cerados",
"charapa",
"clameis",
"cobiçai",
"colarmo",
"colavas",
"coludir",
"combure",
"cooptei",
"corneio",
"cotrofe",
"cousado",
"craniar",
"damaras",
"desolhe",
"deípara",
"dialeta",
"dicanza",
"ditério",
"dríadas",
"duetado",
"dáctila",
"elipsar",
"empanas",
"empória",
"enoites",
"ensaies",
"entoais",
"entojam",
"entraja",
"enublou",
"erravas",
"escalpa",
"escarga",
"esgalga",
"estaliu",
"evagina",
"evencia",
"evenque",
"facejar",
"fadamos",
"fadasse",
"femeado",
"filarem",
"fobista",
"foliava",
"fregado",
"furoado",
"fuseiro",
"gadames",
"gamavam",
"garrear",
"glossai",
"gostemo",
"greguês",
"gálbula",
"heptana",
"herniam",
"ilharam",
"ilimito",
"imerges",
"imódico",
"invases",
"invejai",
"inverne",
"inânias",
"iononas",
"jairita",
"latejas",
"lazarar",
"leigais",
"machote",
"macumbé",
"malados",
"malhais",
"malheta",
"mamorim",
"mcmlxii",
"mimador",
"montais",
"morfava",
"mulundu",
"nuviosa",
"odínica",
"onagras",
"ondagem",
"orbitei",
"palidal",
"palurdo",
"panejou",
"pangaia",
"paracaí",
"parlais",
"patento",
"pelosas",
"perdigo",
"peritar",
"pestear",
"pinázio",
"pujando",
"quezila",
"rachear",
"rapaste",
"rebelão",
"regelam",
"regeres",
"relaxai",
"retouço",
"rânulas",
"saludam",
"sandias",
"sinhara",
"sovarem",
"subalar",
"sufocai",
"suiones",
"sulquei",
"suputar",
"tabelei",
"tambica",
"taração",
"telizes",
"ticaram",
"tilinto",
"timbuva",
"tiraços",
"toluico",
"toraria",
"toronar",
"torpeço",
"traspés",
"triagas",
"tungara",
"ulcerai",
"uranina",
"urinais",
"vantaje",
"vearias",
"voseado",
"xerocas",
"abitara",
"abolina",
"abortes",
"acredor",
"adufado",
"aforram",
"ajeites",
"alestes",
"aloirou",
"alvales",
"ameiada",
"amilhar",
"amontei",
"anexais",
"angrito",
"anêmola",
"apremia",
"apônico",
"ardidez",
"arenais",
"arregoa",
"artilho",
"assolto",
"atalhem",
"atordoo",
"atulhem",
"atupido",
"augasse",
"auraria",
"aveludo",
"balasco",
"balenga",
"bendala",
"biscais",
"biscata",
"biácido",
"cacheei",
"cacongo",
"canteia",
"canúris",
"capuaba",
"careais",
"cebuana",
"cerceal",
"cervejo",
"chaguem",
"chambas",
"chimpou",
"choramo",
"chufado",
"cimbrar",
"cinchas",
"cinturo",
"cissões",
"corujou",
"cotruco",
"crastas",
"curimãs",
"debuxei",
"dedavam",
"depelar",
"desamão",
"desmano",
"despõem",
"desquer",
"devente",
"distado",
"divismo",
"ecianos",
"eluindo",
"encalma",
"encoura",
"enovado",
"entupem",
"espaldo",
"fadares",
"fatorou",
"faísque",
"feirais",
"fervore",
"fluidor",
"focense",
"fugidor",
"furtamo",
"féeries",
"gajajas",
"galbada",
"ganhoas",
"gelante",
"gotadas",
"guaiara",
"guidada",
"heliose",
"imitiam",
"infidas",
"inspeto",
"irmanei",
"jaculou",
"jugarão",
"jugasse",
"lacrimo",
"lajeana",
"lampeje",
"legames",
"lerdeia",
"lizarem",
"léquios",
"macetou",
"mangana",
"matizem",
"mcmxcii",
"memorai",
"meneada",
"monetas",
"monteis",
"morsego",
"mungida",
"nanados",
"nolição",
"nonanal",
"numbela",
"oogonia",
"ornarão",
"palitas",
"parsano",
"paúlico",
"peleana",
"peliche",
"penedio",
"pinchai",
"pipotes",
"polábia",
"prangos",
"predate",
"prensem",
"prepoém",
"presago",
"profila",
"puparem",
"pálicos",
"párvula",
"quentou",
"quirana",
"rabujas",
"rebeque",
"recasei",
"releixe",
"relevai",
"rimarão",
"roeriam",
"rojados",
"romaico",
"rulante",
"saparam",
"sardoso",
"sazonou",
"saárica",
"setasse",
"sevando",
"sifmita",
"sirocos",
"situeis",
"solidou",
"sopitou",
"sulfata",
"taporos",
"tasquem",
"tateais",
"tatuaba",
"tiófilo",
"tontina",
"tostara",
"tumbice",
"ufanosa",
"unígene",
"uolofes",
"vagaste",
"vaiador",
"varremo",
"vasosos",
"velejas",
"vexarem",
"zorates",
"áuspice",
"abatelo",
"abesana",
"adoidar",
"afuroar",
"agregai",
"agúdias",
"alentai",
"aligado",
"alistai",
"almiara",
"almoçai",
"alombou",
"alterco",
"alvéloa",
"amestro",
"apoteca",
"aprônia",
"araicás",
"arelhas",
"arramou",
"asianas",
"astelas",
"astiano",
"atopada",
"atrapam",
"atroado",
"auriluz",
"avergar",
"avionar",
"avisais",
"azedura",
"baalita",
"balavam",
"baobabe",
"baquité",
"bastamo",
"beicado",
"bicorar",
"blasone",
"borriço",
"bracera",
"brotais",
"bulalas",
"cacicas",
"cafungo",
"calejes",
"capindó",
"cardeai",
"carmosa",
"cartara",
"carucho",
"casante",
"castrão",
"caônios",
"charpas",
"coleado",
"consoam",
"corneei",
"covatos",
"cranque",
"crotões",
"cucular",
"cuidamo",
"curveta",
"cutubas",
"derogue",
"deterge",
"devidor",
"domoico",
"doseava",
"ecoloco",
"empante",
"empares",
"enfeira",
"engadas",
"entopes",
"enviese",
"ervosos",
"escurei",
"espirem",
"eutério",
"exófago",
"figando",
"flimada",
"flutues",
"fubuias",
"fundeie",
"gabione",
"garroas",
"gemidão",
"gericos",
"gizamos",
"glosara",
"grinfar",
"guaripu",
"hérpete",
"imódica",
"infetem",
"ingiras",
"isandro",
"isentai",
"itaense",
"landina",
"lantano",
"laureie",
"lebrões",
"lenhava",
"lesmice",
"libínia",
"litchis",
"locário",
"lêsseis",
"malevos",
"malucou",
"mandchu",
"maniota",
"maometa",
"marroca",
"medense",
"mensana",
"miadela",
"mimavas",
"mimices",
"mixasse",
"mondino",
"monetes",
"nefrina",
"notista",
"olfatar",
"opiados",
"pacenho",
"panariz",
"papares",
"pateiam",
"patroou",
"patural",
"pechara",
"pelicos",
"peloria",
"penarei",
"perjure",
"perseas",
"poliste",
"pracata",
"quintai",
"rabeiro",
"rearmam",
"recozeu",
"redrado",
"reexpor",
"remimos",
"remorre",
"ressuda",
"rifavam",
"rizador",
"rocazes",
"rosólio",
"rugadas",
"sabires",
"sanfone",
"santali",
"sedaria",
"seresma",
"sevador",
"sobrase",
"solverá",
"talavam",
"tancada",
"tateiem",
"telinta",
"torçado",
"trabela",
"tranado",
"trassei",
"tritemo",
"vacando",
"vigarem",
"visteis",
"viática",
"xibimba",
"zerarei",
"aconcha",
"adibuda",
"adoente",
"adregou",
"afrasia",
"aguieta",
"aicanãs",
"aloprem",
"amareia",
"amigava",
"amoramo",
"amáraco",
"anuiria",
"aparrar",
"ariense",
"arrugar",
"arumará",
"asperja",
"ateimou",
"atopava",
"atorava",
"aulidos",
"aventas",
"aviseis",
"azogado",
"bambaré",
"baretas",
"batotar",
"benzemo",
"bermano",
"biliros",
"bodecos",
"canchos",
"canicho",
"caraxas",
"ccxciii",
"ccxviii",
"cerceei",
"chardós",
"chavado",
"cheiene",
"cismeis",
"cisquei",
"cocones",
"colmato",
"comiche",
"concela",
"corsita",
"corujam",
"curriça",
"címbrio",
"dalasis",
"debotas",
"desruga",
"detrato",
"dilates",
"domácia",
"enxotas",
"epitomo",
"espiais",
"espocas",
"estofas",
"etólico",
"eventra",
"exprobo",
"fadaria",
"faraute",
"favecas",
"fenicol",
"fincudo",
"flertas",
"fraitas",
"fremira",
"frescam",
"fumosas",
"fumosos",
"fuçarei",
"gadunha",
"gaiolos",
"galbado",
"gazeada",
"geravas",
"gripose",
"gulungo",
"gunguna",
"ictíica",
"incarno",
"isantos",
"joelhas",
"juparás",
"ladeias",
"limação",
"luxadas",
"lúridos",
"mabuias",
"malemba",
"mateína",
"matosos",
"matraco",
"meliano",
"melódio",
"memoras",
"mentará",
"mimarão",
"minável",
"mirarão",
"molícia",
"mupunga",
"mégaros",
"nanamos",
"navelas",
"negaçar",
"nuquini",
"néfrico",
"obducto",
"onzenas",
"pacobas",
"papeada",
"pararmo",
"pareiem",
"pelotar",
"penemos",
"perruna",
"pezenho",
"piaçoca",
"pirenos",
"pisques",
"poetais",
"poisias",
"próxeno",
"pânfila",
"pérsios",
"quilhem",
"rafados",
"ravinha",
"recadou",
"recolei",
"redimas",
"regulai",
"retinam",
"rumadas",
"sabujam",
"salitas",
"salmeia",
"savacus",
"semiluz",
"siglado",
"soladão",
"sonaras",
"soutado",
"sovinar",
"sulfura",
"sêmicas",
"tamárix",
"tirinas",
"titubei",
"topetar",
"tossica",
"treitar",
"trincal",
"trintou",
"troadas",
"troteio",
"tubinar",
"uranite",
"urraria",
"ustéria",
"vageiam",
"valhamo",
"vegadas",
"vergame",
"vidiana",
"visgado",
"xerafim",
"abainha",
"abassis",
"abicham",
"abóbeda",
"acossas",
"actites",
"acubina",
"adamara",
"adeusar",
"agitata",
"agravia",
"agudece",
"alantes",
"alcaboz",
"aldamas",
"alofana",
"amareie",
"amoreno",
"andaços",
"apascoa",
"apreces",
"arajuba",
"arcósio",
"arintos",
"asserem",
"atranca",
"avernas",
"aviaras",
"bajaste",
"bajules",
"balante",
"bambeie",
"banhemo",
"baranho",
"belouro",
"bitolei",
"bochada",
"boicorá",
"cambeva",
"canhado",
"castres",
"catanar",
"caxibos",
"caçurro",
"chapoda",
"chinque",
"chotado",
"chuçada",
"cimbras",
"cisquem",
"clampes",
"coactas",
"coaxais",
"corteia",
"corálio",
"cotável",
"covacho",
"coxotes",
"crinuda",
"cromilo",
"cupiúva",
"debelas",
"desasir",
"desmedi",
"desvivo",
"domarão",
"efesino",
"emboici",
"empeçou",
"eolista",
"eriteia",
"erosado",
"escorce",
"escures",
"estilam",
"estugam",
"etopeia",
"etésios",
"eucanto",
"falades",
"falênia",
"festosa",
"filaxia",
"fiolhos",
"flartar",
"floreja",
"forcões",
"fossemo",
"fugaram",
"gabarmo",
"gahnita",
"golados",
"gramais",
"guaiama",
"guaraji",
"ilidirá",
"ilocana",
"incinde",
"inculpo",
"irizada",
"itacuru",
"iântino",
"jalense",
"lacante",
"laivado",
"lealdar",
"liguemo",
"linácea",
"loteias",
"lucilam",
"láureos",
"maavita",
"machins",
"mafulos",
"mangagá",
"maranhe",
"mcmxlii",
"micotas",
"modulas",
"molitas",
"mufinos",
"mácrons",
"nacarou",
"ngangas",
"nozeira",
"obversa",
"ocreata",
"omalgia",
"ondeiro",
"origone",
"ortégia",
"ossosos",
"palmica",
"pantear",
"pedetes",
"perdudo",
"pericie",
"pigados",
"pinçais",
"pirense",
"plissas",
"plugara",
"polacra",
"potiúna",
"prazada",
"préfica",
"quentei",
"rancora",
"rapiada",
"rearmes",
"redizia",
"redouça",
"refugas",
"refusam",
"relamos",
"relinga",
"remante",
"repimpa",
"returco",
"revivei",
"reóforo",
"rilhado",
"ripeira",
"rodeeis",
"rodeies",
"romadas",
"rombada",
"rosmano",
"rúbidas",
"saborás",
"sacalão",
"saciamo",
"sacudem",
"saidela",
"saltica",
"santona",
"segavam",
"selvoso",
"sometas",
"sortais",
"sugante",
"sujador",
"sujarmo",
"susteis",
"sálmico",
"síndone",
"tabelem",
"tacelos",
"talosos",
"teflons",
"telegas",
"titinga",
"treslia",
"triarco",
"troarão",
"tupinaê",
"upeneus",
"usinara",
"usinava",
"vacaraí",
"vaiarão",
"varejei",
"veirado",
"venadas",
"voarias",
"vêndico",
"xenismo",
"zonadas",
"ânticos",
"abaiana",
"abissos",
"abnorme",
"abrolhe",
"abundei",
"acharca",
"acoimou",
"adotamo",
"adumbra",
"afeando",
"agelaia",
"alarmos",
"albides",
"alheamo",
"amantar",
"amodais",
"amolgue",
"anamesa",
"anamiri",
"anchado",
"anilham",
"anuviem",
"aricana",
"arinhas",
"atomize",
"babavas",
"bacorim",
"bafejem",
"bajunça",
"baneane",
"baranto",
"baritos",
"bisques",
"bisseto",
"bodelgo",
"brolhar",
"bárcida",
"cabalam",
"cabotar",
"calomel",
"camonha",
"campias",
"candeás",
"caotiza",
"capuana",
"caravas",
"carteou",
"casitéu",
"catemos",
"cdlxxiv",
"cegaras",
"charabã",
"chatins",
"chichia",
"chimbas",
"chumeco",
"coleiam",
"courada",
"coxeado",
"craseia",
"cruciam",
"crucies",
"cutruco",
"cuvetas",
"decotam",
"dedarem",
"dedurem",
"defecas",
"deferes",
"descala",
"desnuar",
"destare",
"docense",
"ecoemos",
"engalha",
"ensoado",
"envesso",
"epactas",
"esfriai",
"espacem",
"espilro",
"espinol",
"estinga",
"estopou",
"evolara",
"exproba",
"extenuo",
"fachola",
"falança",
"fanaria",
"fatacaz",
"fechemo",
"fieitos",
"filasse",
"fixavas",
"fizermo",
"forfete",
"freador",
"froixos",
"fuinhos",
"galanas",
"garruda",
"gavione",
"gopiara",
"goraria",
"grífico",
"gulosar",
"guteira",
"gêzinho",
"iguanos",
"iolitas",
"isíacos",
"jungiam",
"juruaçu",
"juruvas",
"juvenca",
"laqueia",
"lembava",
"lolardo",
"ludinha",
"lufares",
"lunados",
"manegar",
"manoque",
"mantais",
"mcmlxiv",
"meminha",
"mendosa",
"mentina",
"miliare",
"mimetes",
"moinice",
"mortada",
"mudulos",
"mumetal",
"munidor",
"muravam",
"muxaxas",
"nabiçal",
"nanodes",
"nemória",
"nervudo",
"neólogo",
"obdurar",
"papocas",
"patamal",
"pautear",
"pedinos",
"peituga",
"perrear",
"piabada",
"picenas",
"pisgava",
"plangia",
"poeiram",
"propalo",
"psichés",
"pubente",
"quilite",
"quinara",
"rapadão",
"rearmem",
"rebojar",
"refinas",
"remitam",
"rentais",
"repelar",
"requeté",
"retenta",
"ripeiro",
"ropálio",
"rosetes",
"roxense",
"ruflado",
"secavas",
"selaras",
"sequilo",
"sinaíta",
"sinceno",
"someteu",
"sulenta",
"surreis",
"surumbi",
"talefes",
"talinga",
"tanazes",
"tarante",
"tentana",
"titilam",
"traites",
"tristor",
"troares",
"troncar",
"tucupis",
"turinga",
"ubijara",
"ufanada",
"ugriano",
"valisas",
"vilanaz",
"zoólito",
"ásticas",
"átmicos",
"abadado",
"abanica",
"abecado",
"ableias",
"abrigoa",
"acarado",
"adulões",
"adverbe",
"afilhar",
"aforrou",
"alestar",
"alimada",
"almeiro",
"alumens",
"amusias",
"anameus",
"andaimo",
"antolhe",
"aparaça",
"aplaste",
"arcador",
"arcuado",
"arqueje",
"asperso",
"assocam",
"atochem",
"balafom",
"baliram",
"banidão",
"baniuas",
"barbica",
"barigas",
"bernesa",
"bicarão",
"binadas",
"bordeje",
"botaina",
"boubela",
"calcais",
"calçuda",
"canelho",
"capeava",
"casquem",
"cegueis",
"cezinho",
"changui",
"chiasco",
"comovei",
"conviem",
"cosipar",
"currava",
"cuspias",
"desdirá",
"desgela",
"dessome",
"dianita",
"efeitua",
"eleante",
"embucho",
"emigrai",
"empanda",
"emproam",
"encendi",
"enfarde",
"enfeixo",
"enodoou",
"enrabas",
"enseado",
"escadós",
"escamei",
"escinde",
"exógamo",
"falsura",
"fartada",
"floquei",
"fluíres",
"folgais",
"gaifana",
"galopão",
"gamasse",
"garotam",
"gelarão",
"gelação",
"granara",
"gretava",
"guaieno",
"guapear",
"guarabá",
"guarayo",
"guineia",
"impludo",
"jacubas",
"jererés",
"jurismo",
"lepchas",
"libados",
"lideira",
"lindará",
"luandas",
"madurem",
"manarem",
"mangrar",
"margara",
"marmais",
"matunga",
"matutem",
"mentigo",
"meruins",
"messada",
"metilol",
"micante",
"mineres",
"monteia",
"muquixe",
"musetas",
"muxitos",
"naftila",
"nateira",
"nevisco",
"nhungue",
"odorize",
"ontária",
"orgiais",
"ostrana",
"ouradas",
"padreia",
"papolas",
"pardale",
"pelomes",
"pesgado",
"pilídio",
"piremos",
"pirulos",
"popocar",
"pousais",
"prunela",
"púmices",
"quietem",
"quérulo",
"ralares",
"refirmo",
"regermo",
"resmoer",
"retente",
"roditas",
"rogavas",
"ronhice",
"rorando",
"sagitar",
"salitra",
"saurins",
"senguem",
"sesteou",
"setiano",
"sinuado",
"soabrir",
"socarei",
"soergam",
"solidam",
"soluçai",
"tanguis",
"tarrote",
"tejanas",
"tendola",
"testeei",
"tetarem",
"tionilo",
"tocário",
"tragana",
"tralado",
"trevais",
"troilos",
"trígeas",
"untador",
"veiados",
"ventora",
"vetares",
"viajeis",
"xilemas",
"zoneiam",
"ósmicas",
"abatuma",
"abrases",
"acendeá",
"achouta",
"aciclia",
"acidule",
"acostas",
"adjurou",
"adobale",
"afiarei",
"afixais",
"agarena",
"agaroto",
"agnomes",
"aguines",
"ajudeou",
"aletris",
"alfafal",
"alvaraz",
"amélios",
"anemiza",
"anumero",
"anético",
"arboral",
"arcóseo",
"assubiu",
"atenuas",
"atímico",
"balaias",
"baliada",
"barceus",
"batizes",
"belgata",
"berosos",
"bisadas",
"bixbite",
"boatada",
"boteira",
"braquis",
"brácons",
"canapus",
"canarás",
"canjala",
"carabas",
"carpear",
"caxirim",
"champou",
"chorras",
"ciarias",
"ciriais",
"cisgola",
"clorino",
"conário",
"copieis",
"coreuta",
"coseste",
"cotreia",
"coxetes",
"crocotó",
"cúficas",
"dejejua",
"desusam",
"desçamo",
"develas",
"devenho",
"donairo",
"dopadão",
"elongas",
"embalsa",
"encinho",
"encubem",
"enlatas",
"enluara",
"enojoso",
"enotera",
"enovele",
"entrama",
"enxotes",
"eoliana",
"ervando",
"esbates",
"escacho",
"esporeá",
"esprite",
"evarias",
"exordia",
"expendo",
"fedúcia",
"folheai",
"franjam",
"fugente",
"gadunho",
"gatanho",
"gemados",
"gorinos",
"grisado",
"guengas",
"hemíono",
"hereias",
"ilhavam",
"indazol",
"inhaíba",
"insidio",
"iresine",
"iriemos",
"iscador",
"isofana",
"judaíza",
"juvenco",
"latento",
"liadora",
"librino",
"loricos",
"lucubro",
"léquias",
"machino",
"mansado",
"maturus",
"meantes",
"mendica",
"merujar",
"mimarei",
"mofarão",
"molição",
"monchão",
"murungu",
"nevavam",
"nhandus",
"nianjas",
"ninfose",
"ninocha",
"nóricos",
"obremos",
"ogâmica",
"ombrudo",
"otofone",
"oxímero",
"palhiça",
"peagens",
"percamo",
"picudas",
"pinhota",
"pinteis",
"pletros",
"pneumas",
"porfina",
"pozeira",
"prussos",
"prândio",
"quanzas",
"quedeis",
"quibala",
"reabita",
"rebrado",
"recatai",
"recedeu",
"relarga",
"renetes",
"repuxes",
"ressumi",
"revesem",
"ripavam",
"ronques",
"ruireis",
"sabotai",
"sabotes",
"seriosa",
"sibites",
"socotri",
"solarão",
"solutol",
"talonar",
"tanagem",
"tantear",
"titilou",
"tocagem",
"torarem",
"tordeia",
"torgais",
"torrear",
"trampão",
"troarem",
"ubuesco",
"ungongo",
"valsara",
"ventais",
"viandar",
"vidiano",
"vingais",
"virgulo",
"visíbil",
"vitanda",
"vozeava",
"vuarame",
"vílulas",
"xivaíta",
"zabelês",
"abariga",
"ablator",
"ablânia",
"abobras",
"aborrir",
"abrotar",
"aciosas",
"acochou",
"acossem",
"acravar",
"adergar",
"afritos",
"afácica",
"alardem",
"alcaica",
"alcunhe",
"aleadas",
"alfamas",
"almiços",
"alustro",
"ambívio",
"amiguem",
"amorete",
"andarim",
"ansieis",
"apanhos",
"apólida",
"arâmico",
"asilara",
"assabão",
"ativais",
"atraímo",
"avacuar",
"avaluar",
"aviltas",
"avoaçar",
"açorita",
"babanca",
"babaste",
"babuzar",
"badanai",
"balsões",
"barcego",
"barreou",
"bitecas",
"boatava",
"bostear",
"bragais",
"bufonas",
"butaram",
"buçalem",
"caianas",
"calaica",
"calejem",
"calungo",
"campeei",
"candora",
"canetei",
"cardino",
"catarão",
"cavitam",
"ceceoso",
"chiarão",
"clausos",
"clivava",
"coccige",
"codeara",
"compeço",
"condoas",
"covardo",
"crética",
"debuxam",
"degasta",
"delubro",
"destona",
"dínodos",
"emanemo",
"emenina",
"enargia",
"endivas",
"enlatem",
"enlevei",
"enrizar",
"entrone",
"envazam",
"epífase",
"escrino",
"esfeita",
"espanem",
"espurco",
"estinho",
"estuais",
"eterais",
"etilena",
"evictos",
"exsudem",
"fadismo",
"fiariam",
"fujisse",
"fumigas",
"funária",
"gafento",
"ganeira",
"garapau",
"garoada",
"gavados",
"glúcide",
"grampar",
"gueréns",
"géticas",
"helveto",
"horarem",
"humanou",
"insidie",
"isquial",
"jabirus",
"jaspear",
"jibarra",
"ladripo",
"lanaria",
"landado",
"lapisão",
"lipoato",
"lograis",
"luciole",
"ludiana",
"lugando",
"luziria",
"madurei",
"magrote",
"manchua",
"manidos",
"manusca",
"maquile",
"marafar",
"marmosa",
"masseve",
"matagem",
"miliohm",
"miudear",
"moldeis",
"moncoso",
"morouço",
"moscava",
"murugem",
"móvitos",
"nuancem",
"nínfica",
"objetai",
"oceâneo",
"olivita",
"olorado",
"ondatra",
"orbites",
"oxalila",
"palacha",
"parapas",
"patetar",
"pelasse",
"pelecas",
"pelioma",
"pepolim",
"petizas",
"piascas",
"pingaça",
"pliques",
"pocense",
"podares",
"polilha",
"preemos",
"pregoou",
"pósiton",
"quentam",
"quotiza",
"ramista",
"rangado",
"raquéis",
"realete",
"recamou",
"recinte",
"recoito",
"recunha",
"remetei",
"reouvia",
"reputes",
"repuxem",
"resseso",
"reçumar",
"ribalda",
"rodavas",
"rufinas",
"sabongo",
"sacolou",
"saleros",
"seruaia",
"sevamos",
"silvara",
"sinalei",
"sucador",
"sumiras",
"tabeles",
"tapeias",
"tatamba",
"tendepá",
"termias",
"tolejar",
"tonteie",
"triacas",
"trilava",
"tubunas",
"tupiano",
"tátaros",
"uaianas",
"untaste",
"urucaca",
"uspense",
"valarem",
"veações",
"verbere",
"vexilar",
"vicejas",
"visgoso",
"vozaria",
"xaqueto",
"êugrafo",
"aalênio",
"abairro",
"abasgos",
"abatemo",
"abismas",
"abolhar",
"acontia",
"adirais",
"aditeis",
"adonina",
"adurava",
"aerizar",
"afeitam",
"alciões",
"aloides",
"alombam",
"alporca",
"amanaié",
"amardos",
"amádigo",
"anodine",
"antigel",
"apipará",
"aporfio",
"arabeta",
"arapati",
"ariamos",
"artimão",
"asaprol",
"asneias",
"atiuaçu",
"aunados",
"babunha",
"bagoada",
"basaram",
"batelas",
"batuera",
"baubaus",
"beberar",
"biparam",
"bitando",
"bobeche",
"boiarei",
"bolarão",
"bornear",
"bucinho",
"bulidos",
"busardo",
"cajaléu",
"cambita",
"capavam",
"carataí",
"cautele",
"ccclxxx",
"chameou",
"chibava",
"chirila",
"chovias",
"cianito",
"codório",
"colorou",
"colunal",
"comodam",
"condros",
"cotroso",
"cucumbe",
"curista",
"defasem",
"depredo",
"ducções",
"ejectes",
"elances",
"elatina",
"eliense",
"encruam",
"engador",
"entrame",
"erátema",
"escalai",
"esgaçam",
"estaias",
"esturre",
"eustelo",
"exalcem",
"falaraz",
"ferraça",
"fileiro",
"filódio",
"frenamo",
"funfada",
"furunga",
"galadão",
"galhoto",
"galurês",
"gambelo",
"garimba",
"geófago",
"girares",
"glumela",
"guarros",
"hereira",
"hoqueto",
"hormino",
"imotais",
"imotiva",
"incredo",
"indúsia",
"inosita",
"intigas",
"islenho",
"jibungo",
"jimbelê",
"joeiram",
"lavadão",
"lecânio",
"limbosa",
"linales",
"lobosos",
"maldirá",
"maquilo",
"marocha",
"marugem",
"matutes",
"mavumbo",
"meneies",
"meradas",
"meroíta",
"motivai",
"nazoreu",
"nebúlio",
"nerônio",
"nimboso",
"ninares",
"nióbico",
"nuveado",
"náticas",
"odonato",
"oleínas",
"osculem",
"ozônios",
"pacapio",
"pacarim",
"palados",
"papocam",
"pariris",
"paçaguá",
"pelante",
"petante",
"pieiras",
"pirista",
"polpará",
"prosava",
"páspalo",
"raiarão",
"rametas",
"rebulir",
"refarto",
"relasso",
"remedou",
"remento",
"remudei",
"repegou",
"repovoe",
"resumis",
"retrará",
"ripídio",
"roletou",
"roçante",
"ruflava",
"ruinado",
"rúbidos",
"sabraço",
"sagueza",
"salamba",
"saludas",
"seduzis",
"soberta",
"socapas",
"solense",
"sopeado",
"sucosas",
"surdido",
"surdino",
"tacarei",
"tagante",
"tancama",
"tantita",
"tectito",
"teucras",
"tijolou",
"tirites",
"toparca",
"torreis",
"tosavam",
"tragues",
"trapace",
"trapaço",
"tremate",
"truitas",
"ultrajo",
"unimama",
"uvarana",
"valhais",
"vatinho",
"viseano",
"xinxins",
"zarelha",
"zelaras",
"zerarão",
"zumbias",
"zângano",
"abandei",
"abanhar",
"abarcia",
"abastou",
"abraemo",
"acarapi",
"adelgar",
"aduanei",
"agrelas",
"aleando",
"aleitei",
"alicuri",
"alimpem",
"alistes",
"alojais",
"alândia",
"amaluca",
"amochem",
"anatara",
"ancadas",
"angulam",
"antepus",
"apandar",
"apensam",
"apepsia",
"aplaino",
"apossui",
"apoupar",
"aproses",
"aromoso",
"asnaria",
"asparas",
"aspirja",
"assisar",
"atromba",
"atática",
"avelara",
"bacondê",
"balhada",
"balhata",
"barilha",
"benzona",
"biguado",
"bixbita",
"cabamba",
"cabanis",
"cabrear",
"cachuba",
"caieque",
"cambuba",
"cartono",
"catiano",
"cdxlvii",
"cerilha",
"cestina",
"cestode",
"chacoal",
"chagrém",
"chalais",
"chefado",
"chibaba",
"chicomo",
"cibalho",
"cirande",
"clivoso",
"codaste",
"coloras",
"comodes",
"comutei",
"conetou",
"copamos",
"coparia",
"copaúba",
"coroemo",
"crauatá",
"decepes",
"desasar",
"desazos",
"dialilo",
"dipluro",
"divícia",
"dobadas",
"duzirem",
"délicas",
"díctica",
"eleados",
"emacias",
"embudes",
"empolem",
"enfreno",
"enfurne",
"engonho",
"enodado",
"ensacas",
"enxadar",
"esganes",
"estíbio",
"faiaria",
"faisoas",
"fastosa",
"felibre",
"ferreta",
"flambas",
"flustra",
"forreca",
"frenite",
"friamas",
"fronças",
"fumigou",
"fustões",
"galeato",
"gatinas",
"geleada",
"geloses",
"gengele",
"geninas",
"grajeia",
"guelros",
"guiruçu",
"huambos",
"híperon",
"ibicara",
"ilibará",
"iligais",
"inhábil",
"itaúbas",
"jazerei",
"laicize",
"laradas",
"lateado",
"latâneo",
"lavanco",
"lavarmo",
"lenidas",
"lentina",
"lerdear",
"lineada",
"luraram",
"lágomis",
"límpios",
"mandins",
"mandovi",
"manejes",
"mastura",
"meandre",
"meitega",
"mentimo",
"mergado",
"microhm",
"mobilhe",
"morunos",
"moscata",
"muxicas",
"móricos",
"obteste",
"oeirana",
"ombreio",
"pacalas",
"palicur",
"pandado",
"paredal",
"parália",
"pequapá",
"pequiás",
"picenos",
"piorzão",
"plasias",
"políada",
"poracés",
"porteta",
"pragata",
"promano",
"pólicos",
"quingue",
"rabejou",
"rabelho",
"rabicão",
"radíolo",
"ralasso",
"reachar",
"recramo",
"refecer",
"regirou",
"regoliz",
"remadão",
"remelam",
"remores",
"retacos",
"ripiado",
"sabelos",
"saburás",
"sabélio",
"sarares",
"sargaça",
"sinarca",
"sintrãs",
"siriúva",
"submesa",
"sócheio",
"taenias",
"tasques",
"tavenas",
"telhara",
"templou",
"thulita",
"timbeba",
"tolaria",
"toleiro",
"trapiás",
"tripses",
"tropina",
"trílito",
"tuchado",
"turquim",
"tutorou",
"têmenos",
"untaras",
"vacines",
"venablo",
"xilênio",
"zaralha",
"abacela",
"abacena",
"abolado",
"abondei",
"abrigoo",
"acanuto",
"acarrar",
"acendão",
"afalado",
"agerado",
"alfozes",
"alquifa",
"amareio",
"amblema",
"amenoso",
"amontes",
"ancudas",
"anúrica",
"aprosar",
"argonar",
"armoria",
"arramos",
"arçanha",
"asperjo",
"assoreá",
"atanaza",
"atarefe",
"atazano",
"ateimei",
"atontar",
"aurante",
"babista",
"badania",
"baianal",
"bandais",
"bantabá",
"barelas",
"biritai",
"bofélia",
"bosmina",
"bubuiar",
"bundana",
"caeiros",
"cagaras",
"careada",
"cartema",
"cervais",
"chabaçá",
"chaveei",
"chispem",
"ciátios",
"cogerir",
"copiúva",
"coranos",
"costeie",
"courana",
"crauaçu",
"crisopa",
"cuprato",
"célcios",
"daiaque",
"debreou",
"desfazo",
"desfita",
"diandro",
"dietilo",
"dólopes",
"engante",
"entinta",
"enzinha",
"esfiape",
"esguiez",
"esguião",
"esmirra",
"eutaxia",
"evantes",
"eximimo",
"expilar",
"faceará",
"fastoso",
"frascas",
"frigiam",
"fóricos",
"galivar",
"gatasse",
"gelosas",
"gemines",
"germelo",
"gibarra",
"gobiana",
"graeiro",
"gualdas",
"helxina",
"hifemas",
"hórtico",
"ibgeano",
"ibiboca",
"imitirá",
"incidas",
"includi",
"indexas",
"inultos",
"ipsilão",
"iriando",
"iátrica",
"judicam",
"lateres",
"latirei",
"letreio",
"levadão",
"libadas",
"liradas",
"mabanga",
"macerem",
"malacão",
"malamba",
"manjara",
"mariato",
"mavorte",
"mcmxliv",
"melador",
"merugem",
"mescles",
"mijemos",
"milhais",
"minduba",
"mirrara",
"moleija",
"mongolo",
"mormoso",
"mortens",
"mugiria",
"muscona",
"muzamba",
"neógeno",
"normana",
"nurague",
"nécaras",
"oberado",
"obsecro",
"odorava",
"odorina",
"ortélia",
"pairari",
"palolos",
"pancaru",
"parávoa",
"passete",
"pelicho",
"perluxo",
"perreia",
"petecou",
"pilouro",
"pineles",
"piparia",
"pipetes",
"plicava",
"pocante",
"poritos",
"pretiro",
"píssico",
"quérion",
"ralarão",
"ranhada",
"raziada",
"repelam",
"retundo",
"ribaçãs",
"rochete",
"rociais",
"rolista",
"rousses",
"saboias",
"salsifi",
"sancado",
"sandala",
"sergeta",
"serrais",
"sibilem",
"sinaria",
"sitante",
"sitarem",
"sonajas",
"sorbose",
"subsole",
"síngulo",
"tabalas",
"tangado",
"tocamas",
"tuteava",
"tôneres",
"tútulos",
"ubíquam",
"uitotos",
"urentes",
"valejos",
"valerol",
"versuta",
"vexador",
"viajamo",
"vitiana",
"volvias",
"volúbil",
"xerezes",
"xergões",
"ártabra",
"ésculos",
"ênuplas",
"ababosa",
"abaloou",
"abeires",
"abrumam",
"abrutar",
"acaindo",
"adjunge",
"adonada",
"afagoso",
"agoirem",
"alectra",
"almeias",
"aloucar",
"alterai",
"ambiado",
"anampse",
"anguias",
"animiza",
"armelas",
"arpejou",
"arrabis",
"ascomar",
"atasque",
"axandra",
"azimbre",
"babarmo",
"balcedo",
"banível",
"baquice",
"barabus",
"batorés",
"bazanas",
"bedélio",
"betadas",
"boleíta",
"boteque",
"briches",
"brigais",
"buchado",
"bufares",
"bugiado",
"béstias",
"cacherá",
"cainguá",
"calação",
"cangatá",
"capicha",
"cargosa",
"cartapé",
"caseque",
"centões",
"chainça",
"cicleno",
"ciganar",
"cipoado",
"cirrosa",
"citalos",
"ciumava",
"coerdar",
"combóis",
"comicho",
"consone",
"convies",
"corondó",
"cozeria",
"crocota",
"cumbari",
"dalinas",
"decupam",
"deganho",
"degotes",
"delogum",
"deprave",
"descale",
"diazoto",
"diteira",
"diáboas",
"eforado",
"emparia",
"empelos",
"empoava",
"enfiemo",
"engrupo",
"ensopas",
"entibie",
"entubem",
"envaide",
"esburgo",
"esganas",
"espúmeo",
"estorga",
"estucou",
"estuosa",
"expilam",
"fachuco",
"faiasse",
"faiavam",
"farfana",
"farolar",
"farteza",
"fastava",
"fatorem",
"fatício",
"fautriz",
"firmeis",
"foqueis",
"fronema",
"furiano",
"galrado",
"gamares",
"ganzola",
"gasnate",
"gazeiam",
"gibatão",
"graiado",
"griseta",
"guramis",
"géticos",
"hagrita",
"helvina",
"hetaria",
"hullita",
"improva",
"inflora",
"inviril",
"iriribá",
"irrogou",
"irânica",
"isômera",
"isônoma",
"itambés",
"jerutis",
"laceava",
"lajeano",
"lamecho",
"lapises",
"lesarão",
"lirados",
"listera",
"locarão",
"loteiem",
"lêmicas",
"macaões",
"macúndi",
"marinai",
"mariode",
"matariz",
"mcmlxxx",
"mcmxlvi",
"mendace",
"miniana",
"miocena",
"mistida",
"mochena",
"mococos",
"moçando",
"mulambe",
"mórinos",
"negante",
"neguemo",
"nepalis",
"nigrina",
"noergia",
"nucular",
"nudação",
"numeres",
"néctons",
"obceque",
"odorado",
"ofegoso",
"ogivada",
"olearam",
"oreinos",
"oxatres",
"pacação",
"pacobal",
"padreou",
"pajeava",
"panônio",
"peiotes",
"pelidna",
"piroaba",
"pivotou",
"pomácea",
"preavam",
"pregamo",
"prásino",
"pugnamo",
"quodore",
"rampona",
"rangues",
"rastele",
"razoais",
"raçadas",
"realoco",
"rebalde",
"rebouça",
"recluiu",
"reflado",
"refrite",
"regules",
"resaibo",
"rifasse",
"saborra",
"saquele",
"sensivo",
"seriara",
"serreal",
"simotes",
"sinaram",
"sênecas",
"sínfito",
"síntomo",
"tabanos",
"tagatés",
"tangala",
"tarbela",
"tasselo",
"tesante",
"tiglato",
"toparas",
"tradear",
"tucurão",
"turboso",
"turânio",
"usadora",
"vaconas",
"valonas",
"vaquear",
"verdeia",
"xardoso",
"xurdida",
"ástrico",
"aariana",
"abetais",
"abismai",
"abulado",
"acizelo",
"acuasse",
"adenizo",
"adentes",
"aeciano",
"afiares",
"afiaria",
"afolhar",
"afracar",
"agalope",
"agatina",
"aglosso",
"alabuga",
"amidado",
"amovera",
"amálago",
"amículo",
"anfídio",
"angaíba",
"antaras",
"aplanes",
"apneica",
"apoitam",
"apuanas",
"aquedar",
"areiras",
"arpoava",
"atabefe",
"ateimam",
"aturamo",
"aturdes",
"azougar",
"babelas",
"bacuéns",
"baianca",
"baseais",
"bidunga",
"boiadão",
"borques",
"bungado",
"buranda",
"bíparas",
"caguama",
"calhota",
"califom",
"canavez",
"canteei",
"caroara",
"carrelo",
"carugem",
"cepinho",
"chamiça",
"chefada",
"chiares",
"chispei",
"chocoso",
"cibicos",
"citódio",
"conaças",
"condóis",
"cordear",
"corneis",
"coronho",
"crapuça",
"crimpei",
"cuincar",
"cumacaá",
"curveis",
"cômedos",
"decruar",
"dessabe",
"dicasse",
"ditavas",
"ditrema",
"dunduns",
"duzimos",
"edético",
"eixadas",
"embreta",
"empanha",
"empoças",
"engobos",
"enlapar",
"entelei",
"enuviou",
"envieso",
"escinco",
"escurem",
"espapar",
"estorio",
"estroço",
"esvaeço",
"fabulei",
"faiença",
"farador",
"farreei",
"felsito",
"feltras",
"festine",
"filites",
"foiteza",
"fruirei",
"fáscios",
"galiosa",
"gamonte",
"gereiro",
"goleiem",
"golfeja",
"goteiro",
"gradivo",
"gravela",
"hipoide",
"hínicos",
"ilhante",
"ilhetas",
"imbuíra",
"inexige",
"isófona",
"jacobos",
"jeribás",
"jimbolo",
"jupuuba",
"lambeis",
"lantins",
"lavacra",
"lavajão",
"lavitas",
"lentado",
"licadas",
"listres",
"lourada",
"lousões",
"luancos",
"lécitos",
"lésbios",
"magrama",
"mananga",
"maremas",
"mdlxiii",
"meaçaba",
"michole",
"milocas",
"mingola",
"mitizar",
"modelai",
"modilho",
"moitedo",
"momeado",
"mucicão",
"mutiáti",
"nadiral",
"natineu",
"negroas",
"nhonhas",
"nodados",
"noitece",
"nonanol",
"nêuston",
"obvieis",
"ogivado",
"onicose",
"pactues",
"palpará",
"patridã",
"peneana",
"perlava",
"perrico",
"pilaram",
"piritos",
"piáculo",
"polirei",
"pongais",
"ponteco",
"pontuai",
"praxeis",
"psiádia",
"psorosa",
"pulicas",
"pírtigo",
"quibuca",
"racheta",
"rancura",
"rastela",
"ravasca",
"rebolai",
"rechiar",
"recuais",
"redares",
"regadão",
"remaras",
"remordi",
"retanha",
"revogas",
"rijezas",
"rosavam",
"rulador",
"sabicus",
"sabácia",
"salamar",
"sapeiro",
"sapicuá",
"saramba",
"sebeiro",
"seirões",
"sembras",
"serisse",
"shigela",
"sifonei",
"sipaúba",
"sitiano",
"siálica",
"sonaves",
"suasivo",
"subsino",
"sumbaré",
"sumbrar",
"sumpção",
"taleres",
"tanchar",
"tectriz",
"timbatu",
"tininte",
"tirarmo",
"tiritei",
"toritas",
"torvada",
"toscado",
"toureie",
"trompim",
"turbite",
"uaabita",
"urdição",
"vadeoso",
"vagonas",
"veiadas",
"vessado",
"voseeis",
"xeroxar",
"zornado",
"abogões",
"abraões",
"abrevar",
"absciso",
"abusque",
"abuzina",
"acalado",
"acalore",
"acedeis",
"acurvou",
"adenosa",
"adeusou",
"adobada",
"adocico",
"afeitou",
"afrecho",
"aglossa",
"agradai",
"aguisar",
"ajuízes",
"alapava",
"alfarje",
"algenis",
"almalho",
"alundos",
"alvarrã",
"alçagem",
"alífero",
"alúvios",
"amatala",
"amaçava",
"amelida",
"ametria",
"amigais",
"amilham",
"amuasse",
"aperola",
"aprumes",
"arabana",
"aramatá",
"araracu",
"argaços",
"arratei",
"arrimei",
"arroles",
"atalhes",
"atingaú",
"azumbre",
"azurram",
"babália",
"bafordo",
"balegão",
"banares",
"bandala",
"baqueio",
"batardo",
"beselho",
"bicador",
"bomassa",
"bonisco",
"borleta",
"botavas",
"buriato",
"burlete",
"cafrice",
"caienas",
"calçote",
"cangava",
"canteio",
"canácea",
"caprato",
"caquear",
"casposa",
"centeal",
"cerejos",
"cevagem",
"chifuta",
"cinerar",
"clórica",
"cofiada",
"coleada",
"colétia",
"comarco",
"comarim",
"combali",
"comediu",
"comesto",
"convolo",
"coravas",
"coudéis",
"creível",
"cruciai",
"cuprite",
"defleti",
"demoves",
"desfalo",
"didisco",
"dimidie",
"doidejo",
"drapejo",
"dureira",
"dêutzia",
"ecúleos",
"eguadas",
"emergis",
"empados",
"emplume",
"engarem",
"episema",
"epídema",
"escamem",
"escança",
"escaves",
"estales",
"estivai",
"estravo",
"evocamo",
"exorava",
"expecte",
"exporás",
"filônio",
"formemo",
"fosfate",
"fouçada",
"freadão",
"fujiria",
"galazia",
"galhato",
"ganchei",
"garcina",
"gentita",
"golunda",
"gomaras",
"grimpei",
"grífica",
"guapice",
"guarapé",
"halímio",
"hamular",
"helieia",
"hifemia",
"hioides",
"igualdo",
"imageou",
"irisava",
"irrogue",
"isólogo",
"jeremaí",
"joiados",
"ladezas",
"latinar",
"lenhita",
"levigar",
"lheísmo",
"lotador",
"luveira",
"lyonita",
"macruro",
"malecha",
"manates",
"mandiva",
"manival",
"maranas",
"marasme",
"maromas",
"marzoco",
"matames",
"medrica",
"mezuzás",
"mimanço",
"miômero",
"moirais",
"monaria",
"monoxós",
"morlaco",
"mullita",
"mundões",
"mupanda",
"muquero",
"múcicos",
"namazes",
"nauseio",
"nedungo",
"nolente",
"nominai",
"nulário",
"obradou",
"obélion",
"ofegada",
"olivado",
"opacite",
"ouriças",
"ousança",
"panásio",
"papavas",
"papilos",
"patetes",
"paúlica",
"pedadas",
"penariz",
"pencuda",
"peticar",
"pichoso",
"pinamos",
"pipipãs",
"pitácio",
"plangei",
"podrees",
"pomécia",
"pouseis",
"pragues",
"prepupa",
"prezamo",
"protomo",
"provona",
"písceas",
"rabiado",
"ramudos",
"rapelho",
"rapsode",
"refolgo",
"regomes",
"remoera",
"resenas",
"rizóbia",
"rodágio",
"rostais",
"ruandas",
"ruianos",
"rídicos",
"sacudes",
"saiotas",
"sapiquá",
"saquela",
"seivada",
"sensila",
"sezonal",
"sibongo",
"sicídio",
"silures",
"simarra",
"sinemas",
"sinhama",
"soabres",
"somasco",
"sopesem",
"sorfete",
"soíamos",
"subiote",
"sulquem",
"surdira",
"sílicos",
"tabuque",
"tagmema",
"tamatra",
"tapuoca",
"tarugas",
"temudos",
"tilaria",
"tinções",
"toledor",
"tosarem",
"tragamo",
"trepana",
"trácica",
"tuipara",
"tumbais",
"tupidos",
"urzelas",
"valseio",
"vanecer",
"vastada",
"vedarei",
"venoste",
"vereada",
"versona",
"vivadas",
"víbices",
"xaboque",
"xererém",
"águamãe",
"abessim",
"abilhar",
"acaídas",
"adoquim",
"aformam",
"afrodes",
"agalhas",
"alimpas",
"almeice",
"alongai",
"alucita",
"aluídas",
"alvejas",
"alvesia",
"amacaca",
"amarear",
"amoreia",
"amoreis",
"amorudo",
"amuavam",
"ancólia",
"antusas",
"aposses",
"apúlios",
"aramuru",
"ardiana",
"argiana",
"arnesar",
"arpejam",
"arreita",
"arrolas",
"asianos",
"atouaou",
"atulhas",
"auraúna",
"axinite",
"bafugem",
"bajador",
"bandiva",
"bardada",
"biguane",
"bisacos",
"biscado",
"blefara",
"boqueie",
"brochão",
"bromita",
"buladas",
"burlina",
"bífores",
"cachias",
"cairiri",
"calazal",
"caliana",
"canoira",
"cantear",
"capasse",
"capitia",
"carlins",
"caronha",
"catanda",
"catucam",
"ccxlvii",
"chilrem",
"chofres",
"chusmar",
"cinchão",
"cincões",
"cinista",
"cleruco",
"clávula",
"coccina",
"codeúdo",
"coplana",
"corruga",
"coteiro",
"cronema",
"críseas",
"curtins",
"dadivam",
"danarão",
"datista",
"debatei",
"debrasa",
"debutem",
"decorai",
"dentava",
"descace",
"descape",
"detones",
"diádoco",
"dormece",
"dornada",
"dórcada",
"dômicas",
"ecoador",
"emassas",
"embraia",
"empusas",
"encilhe",
"entaipa",
"epaxial",
"erandes",
"esbulhe",
"escanço",
"escovai",
"escurou",
"euderma",
"eurásio",
"exalamo",
"favária",
"felemos",
"felista",
"fervora",
"ferália",
"fichara",
"filanto",
"fletora",
"franjou",
"fraseie",
"fubecar",
"gaguear",
"garfeou",
"ginetos",
"gomenol",
"graduas",
"guardim",
"guicoxo",
"gênitos",
"hepatos",
"humíria",
"hárpalo",
"icnitos",
"ideavam",
"ilativa",
"intervê",
"isópaca",
"jabaana",
"joeires",
"jupatis",
"lactume",
"ladrais",
"lambado",
"lapardo",
"lapisar",
"lestado",
"lestias",
"litoide",
"macomba",
"malalis",
"manatas",
"mansada",
"maranga",
"margino",
"massuco",
"mcmliii",
"mesuram",
"modorna",
"mojicas",
"mornaço",
"mutança",
"nabinha",
"nefopam",
"negreje",
"nicadas",
"nitrosa",
"novadia",
"obarana",
"obsedes",
"ocelada",
"onicoma",
"opelifá",
"orçasse",
"paganal",
"pegulho",
"penudas",
"perauta",
"perlara",
"pesteie",
"peteira",
"picanca",
"pistada",
"plaudir",
"polense",
"pragais",
"prearam",
"protima",
"prítane",
"psilito",
"pugnace",
"puntais",
"párseos",
"pésicos",
"rabunar",
"raiolas",
"raleiam",
"rebanhe",
"rebotei",
"rebraço",
"recomei",
"refitam",
"regalai",
"reiunas",
"rejetos",
"remelão",
"remolar",
"retombo",
"ribança",
"sabucar",
"sadense",
"sagitas",
"saninho",
"sararau",
"seriama",
"sertela",
"sessamo",
"sigilas",
"siguais",
"sinalem",
"siripuã",
"sirruns",
"sofreei",
"sondeis",
"soranos",
"sotéria",
"stiques",
"sugavas",
"sédulas",
"sílvico",
"tabacas",
"tamarga",
"tatismo",
"tatuais",
"tibiros",
"tijupar",
"timbrem",
"tisnara",
"toirear",
"toireio",
"tonilho",
"toramos",
"torones",
"triaram",
"tropeou",
"trovoso",
"trímera",
"tumente",
"ucrânio",
"umbrino",
"umbuzal",
"uranato",
"utuaúba",
"vanádis",
"vastado",
"vedaste",
"vilório",
"ximbute",
"zimbras",
"ânglica",
"abdesto",
"abjunto",
"abordoa",
"acarmos",
"acessai",
"aciumes",
"aclusão",
"adanada",
"adligar",
"adulara",
"afetamo",
"aforara",
"aformal",
"aguemos",
"aisuari",
"alcanol",
"aljaraz",
"alvanil",
"alçador",
"alípede",
"amadino",
"amainas",
"ambrozô",
"amedina",
"ameijas",
"amistas",
"amouxar",
"ampolha",
"anipnia",
"anoteis",
"apancar",
"apavona",
"aperrar",
"aplasto",
"apojado",
"ardilez",
"armoles",
"arrimas",
"asarona",
"assuava",
"aurunco",
"avestas",
"azulece",
"azurado",
"bailhes",
"barrene",
"bascama",
"benzola",
"bestada",
"bilocal",
"bisnago",
"bitocar",
"bitruca",
"bobinou",
"boiares",
"bolhada",
"bungula",
"cachiça",
"camposo",
"capinal",
"carques",
"caveado",
"caviana",
"cccxxxi",
"cdlxvii",
"cdxcvii",
"cerolha",
"cerâmia",
"chabuco",
"charana",
"chiscar",
"ciasses",
"coburgo",
"codizar",
"coesita",
"comorou",
"congeia",
"conídia",
"cotarão",
"crisais",
"cumandá",
"curuera",
"dcclxiv",
"debruou",
"dedigno",
"desares",
"desdemo",
"desmune",
"desolei",
"dessada",
"diteque",
"ditisco",
"doesses",
"domares",
"dulcita",
"ecantos",
"ecpiema",
"ectenia",
"elasmas",
"encanas",
"encenso",
"engrolo",
"ensonar",
"enxaréu",
"enzonas",
"epítese",
"eructou",
"erísimo",
"esgaste",
"estruja",
"etilato",
"eversos",
"exculpe",
"exostra",
"falismo",
"faqueou",
"fatismo",
"felaína",
"feltrou",
"femeias",
"findamo",
"fonfons",
"frontai",
"fruíres",
"frútice",
"gaiacol",
"galimar",
"galrões",
"galécio",
"gamaras",
"garroba",
"gosmosa",
"grasnir",
"grunhes",
"guanacá",
"guandos",
"guieira",
"haliplo",
"harudes",
"herdamo",
"ibatimô",
"idiotai",
"inhanga",
"jacúzis",
"jagubes",
"janotar",
"jaranas",
"jebimba",
"jurutis",
"karatês",
"lagoões",
"latende",
"latidão",
"laureio",
"lidante",
"lâmpado",
"mafiado",
"malácio",
"mamosas",
"mantana",
"marouva",
"mateiam",
"melante",
"meletes",
"mestoma",
"miadura",
"micares",
"mimemos",
"molduro",
"molídia",
"monésia",
"mudezas",
"munheco",
"munjovo",
"muçunga",
"narbona",
"navarca",
"nesgada",
"nicados",
"niquins",
"náuplia",
"néstica",
"obviava",
"ourebia",
"ourinar",
"ovaliza",
"pacaria",
"paguemo",
"paiauru",
"paletou",
"pandana",
"pangene",
"pedatos",
"pedálio",
"penhada",
"pertite",
"petícia",
"pexotes",
"piadora",
"picuíra",
"pintane",
"plêiada",
"podrice",
"porfies",
"praxava",
"preitas",
"prepões",
"pulsear",
"quijuba",
"rabalvo",
"rabanar",
"ralemos",
"reanexá",
"repesem",
"ruditos",
"râmolas",
"sacomão",
"saguini",
"sambras",
"saragui",
"satense",
"saturai",
"sedalha",
"sedelas",
"selenes",
"selvosa",
"senária",
"setavam",
"sicílio",
"sinizar",
"sofitos",
"sopista",
"sufeito",
"suruquá",
"sântone",
"sênicas",
"tanante",
"tapulho",
"taqueio",
"telemos",
"tenaria",
"tolendo",
"toucava",
"treliço",
"tressua",
"trilite",
"trípodo",
"tuitais",
"uatútsi",
"unhados",
"urarema",
"vaganau",
"valorei",
"vaseiro",
"versivo",
"vildade",
"vivados",
"vivasse",
"vápidas",
"xinapre",
"xocados",
"xonando",
"zabucaí",
"zinidor",
"zoécias",
"áclides",
"abatini",
"abatirá",
"aboscar",
"abáculo",
"acevado",
"acroama",
"adeiros",
"adelosa",
"aformas",
"afrigem",
"agoures",
"agraram",
"aladora",
"alaguna",
"alamoas",
"alardou",
"alboque",
"aldinos",
"alomear",
"amborés",
"ameigam",
"ameigou",
"anainho",
"anartes",
"anaxial",
"antamos",
"anuirão",
"apeleis",
"apendei",
"apilhar",
"apilhas",
"apincha",
"aporosa",
"aquimia",
"arauari",
"argones",
"asiarca",
"asilava",
"asnilho",
"aulodia",
"avultas",
"azevéns",
"baaísmo",
"bacidez",
"badalim",
"badolas",
"balundo",
"bantins",
"baonesa",
"basaria",
"bestara",
"betadex",
"bexigal",
"boboque",
"bocalvo",
"bodadas",
"bolecos",
"bongava",
"bordaça",
"botoada",
"bróteas",
"budinos",
"cadeixo",
"cafecos",
"cagotes",
"cajabis",
"calhões",
"carafuz",
"carampã",
"casalem",
"cavanis",
"cerasta",
"chacino",
"cheirai",
"chengue",
"chumear",
"chumpim",
"ciarmos",
"colgará",
"colunam",
"cominas",
"coparem",
"copuaçu",
"correal",
"cribelo",
"crivara",
"croeira",
"crubixá",
"cuipuna",
"culimou",
"córcoro",
"córmico",
"dançata",
"debares",
"decapou",
"delíque",
"desunis",
"drapeia",
"duritos",
"eduções",
"eixemos",
"elimeus",
"emalhes",
"encoche",
"enebrie",
"enfunem",
"ervaria",
"escatos",
"esmeres",
"estaces",
"esvarar",
"eutocia",
"feijoco",
"felônio",
"filemos",
"flautar",
"folegar",
"folhaco",
"forages",
"fotônio",
"fugares",
"furcado",
"gafenho",
"gaifona",
"galreia",
"ganiçar",
"gaseiam",
"gibosos",
"giraste",
"glicado",
"goulões",
"gárgaro",
"halênia",
"heptila",
"herdeis",
"homizie",
"infiras",
"israíta",
"jasmona",
"jugário",
"juizeis",
"jujútsu",
"junques",
"justais",
"labrete",
"laianas",
"lambica",
"latejei",
"limugem",
"loangas",
"maciara",
"maloque",
"manalvo",
"mantava",
"marabás",
"maraves",
"marrela",
"mascato",
"masúrio",
"maçarmo",
"maçasse",
"menacma",
"mentilo",
"mesites",
"minuída",
"moledro",
"monécio",
"motucas",
"moíamos",
"mucanos",
"mulonga",
"murtais",
"neófobo",
"nódicos",
"oanandi",
"occíduo",
"ogânica",
"ongulos",
"opálias",
"ordinho",
"ortósia",
"ossosas",
"oxácido",
"padrice",
"paiorra",
"palreia",
"panelos",
"patanha",
"pecília",
"percuto",
"peritem",
"pilhote",
"planteá",
"pojixás",
"posteia",
"pragava",
"prenham",
"quedemo",
"quilino",
"rabiava",
"rapação",
"rauitas",
"reautue",
"recapta",
"reinaço",
"rendias",
"rieiras",
"roçavas",
"ruinada",
"runimol",
"râncias",
"sabaque",
"sabaíta",
"sabeano",
"sabável",
"saguiru",
"salamas",
"sedeúdo",
"senecto",
"sequele",
"soboadã",
"soidões",
"soprais",
"sorimãs",
"sucedes",
"sujável",
"tafonas",
"talucas",
"tapavas",
"tecando",
"terneza",
"testeou",
"timenés",
"tindola",
"tonista",
"tonizar",
"tortela",
"trancem",
"tremela",
"trisais",
"trovoou",
"tuchada",
"tucuras",
"tupiguá",
"ubiquar",
"ulmáceo",
"umectei",
"uniteco",
"unímodo",
"urânias",
"vanésia",
"vasosas",
"vazarei",
"versuto",
"viagear",
"viajata",
"vibreis",
"virosos",
"visgada",
"volpino",
"xarapim",
"xerasia",
"zanagas",
"ababone",
"abondam",
"abridas",
"abroito",
"abrásax",
"absunto",
"acesona",
"acorina",
"acumear",
"acursar",
"adarmes",
"adregam",
"aduagem",
"adufada",
"adutivo",
"adútero",
"afeados",
"afolado",
"aguioto",
"aikidos",
"ajoviar",
"aleitem",
"amainho",
"amateia",
"amelado",
"antedia",
"anzolar",
"anátase",
"apiadar",
"apinhem",
"aporreá",
"aquelam",
"arnilha",
"arramei",
"arratou",
"assocar",
"atecado",
"atibaçu",
"auridor",
"avexame",
"bacuras",
"baderne",
"badorei",
"bageado",
"baianço",
"bajante",
"balidão",
"barbais",
"baseemo",
"basofos",
"beguaba",
"besonha",
"bestais",
"bifloro",
"biguano",
"bijucas",
"bilando",
"bissapa",
"biócito",
"bochado",
"bocório",
"bodemos",
"bojeços",
"bolenga",
"boletou",
"borlada",
"botrião",
"braseia",
"buinhos",
"bulimos",
"bultrim",
"bunodes",
"buriles",
"buçalar",
"bóricos",
"caboatã",
"caborge",
"cabrião",
"cachoça",
"canoões",
"capassa",
"caruiri",
"casanas",
"caseava",
"casália",
"cccxlvi",
"cecídio",
"changue",
"choucha",
"chumela",
"coartem",
"coartou",
"cobreia",
"cobreio",
"cocante",
"coeruna",
"cogeram",
"coições",
"conanas",
"consoes",
"copiamo",
"cortine",
"coserei",
"crespes",
"curiava",
"cíclade",
"decotem",
"degotas",
"deligas",
"delisse",
"desavia",
"dessumo",
"dizanga",
"dobados",
"ejectei",
"elencas",
"embrica",
"empesto",
"empigem",
"encavam",
"encenda",
"encerto",
"enrolai",
"ensarta",
"ensilva",
"enticas",
"epíbole",
"escorno",
"escotro",
"esfoles",
"esgache",
"esmarcá",
"estesos",
"estreço",
"esvoeja",
"exibimo",
"exsudou",
"falhoso",
"fanates",
"farelão",
"farinhe",
"fatagem",
"filetei",
"fioteza",
"frenará",
"galhoso",
"garreio",
"garreão",
"garzeta",
"gipsite",
"glossem",
"grupava",
"guacuru",
"guanita",
"gálbulo",
"hematia",
"hulsita",
"humbral",
"hébrida",
"hímeras",
"ibiense",
"idiotou",
"incutas",
"inçando",
"inólito",
"isamila",
"içabitu",
"janadão",
"jilozal",
"jobando",
"kernita",
"lagonas",
"lamaico",
"laçarão",
"lenhito",
"lentada",
"lesagem",
"liderai",
"locaste",
"lojicas",
"lucrais",
"lufando",
"luitava",
"macanas",
"maciada",
"madidez",
"maionga",
"malanca",
"maravam",
"mateado",
"mateses",
"matizas",
"meixões",
"melezes",
"mengado",
"mexeras",
"michada",
"minemos",
"mixável",
"moedela",
"mofarei",
"mojiana",
"monanto",
"monares",
"moneres",
"monogás",
"mordela",
"mornada",
"morênia",
"muzinga",
"mílhara",
"nanarem",
"nequice",
"nomeeis",
"octanes",
"odínico",
"ofítica",
"oleatos",
"onagata",
"oplário",
"opomiza",
"opífero",
"orbitai",
"oxâmico",
"pabulas",
"pacamãs",
"pactear",
"padrado",
"pajauri",
"panaçar",
"pancaio",
"panetes",
"parlamo",
"patriza",
"paviola",
"pedário",
"pemanos",
"penadão",
"penicas",
"pereion",
"pesaras",
"petitou",
"picarço",
"picomol",
"picosos",
"pimplas",
"pitosco",
"piérias",
"plúmeos",
"populem",
"poquemo",
"porteia",
"posturo",
"preante",
"prenato",
"ptérion",
"puniras",
"pupilam",
"pálavis",
"quassar",
"rabados",
"ralhona",
"rapilho",
"readina",
"reatino",
"refeces",
"regrede",
"relaves",
"remapeá",
"reoxida",
"retrase",
"rimemos",
"rizaram",
"rugirei",
"réstiga",
"sabinol",
"sabotas",
"salasso",
"sangrai",
"sarambu",
"sarcito",
"soretes",
"sulamba",
"susinos",
"símbase",
"tabelio",
"tacheia",
"taimado",
"tamugão",
"tanadar",
"tarares",
"telinas",
"tersóis",
"tingará",
"tintilo",
"tirefão",
"traiais",
"tremoça",
"tricose",
"trocana",
"trupada",
"tálicos",
"uirinas",
"urômero",
"vangana",
"varedos",
"varioso",
"vexasse",
"vezados",
"viridul",
"visonha",
"volscas",
"ziparam",
"zunisse",
"úngulas",
"ababada",
"abarato",
"abeirão",
"abissar",
"ablabes",
"ablecto",
"acabaço",
"acrisia",
"acínace",
"aderado",
"afeadas",
"aferroe",
"afiante",
"afitado",
"agilite",
"aguares",
"aguaste",
"alabara",
"alagues",
"alfaria",
"algenil",
"alindei",
"altanei",
"amatava",
"amazelo",
"amúsico",
"anacaro",
"ananica",
"ananico",
"andanas",
"antadas",
"antonas",
"aprumas",
"aquador",
"arabita",
"arabote",
"araraus",
"arcares",
"arnélia",
"atapete",
"atriano",
"augados",
"avezada",
"aviemos",
"avolumo",
"azoando",
"açapuva",
"açorite",
"bachica",
"badores",
"baileis",
"baixias",
"balhara",
"balsada",
"baracuí",
"barroou",
"beguiri",
"betecas",
"bifadas",
"bigâmea",
"biliado",
"binomes",
"birrado",
"bocanho",
"boletem",
"borrato",
"botarra",
"botilho",
"braboso",
"brasida",
"cacatas",
"cadurco",
"caiasse",
"cambrou",
"carapas",
"cardife",
"careiam",
"carmoso",
"carrila",
"carusma",
"cccxiii",
"cegagem",
"cevalho",
"chaapta",
"chainha",
"chofrou",
"chumiço",
"ciadora",
"cibaria",
"cogelar",
"copejar",
"cortins",
"corumim",
"corundo",
"cosicas",
"couvões",
"crisada",
"cupulim",
"cúficos",
"dardado",
"dccxliv",
"dccxlix",
"dccxxxi",
"dclxxii",
"denodar",
"devidão",
"devotai",
"diazina",
"dimidia",
"diênico",
"donatas",
"durança",
"duravas",
"elejais",
"embarda",
"embelga",
"emoldar",
"emídias",
"encames",
"encofra",
"enforne",
"enfunas",
"enregou",
"enrocar",
"epígeno",
"escamão",
"escorja",
"esquite",
"estones",
"estreli",
"fachear",
"faiares",
"favadas",
"façalvo",
"ferroba",
"ferrobo",
"fidente",
"filmamo",
"fobados",
"forremo",
"fruamos",
"fusoide",
"fuzilas",
"galezia",
"gargado",
"gaseira",
"gimnema",
"gomesas",
"gralhou",
"greiros",
"gresosa",
"guaburu",
"guacumã",
"guapari",
"guindes",
"hameque",
"hilídeo",
"humismo",
"hárpaga",
"hérnico",
"imantem",
"impador",
"imérita",
"inanima",
"invadis",
"isatato",
"isáxone",
"jagonça",
"jifingo",
"jiguefo",
"joiadas",
"juntiva",
"lajista",
"lanceou",
"lanetas",
"larinas",
"laureei",
"lechias",
"leptoma",
"ligódio",
"litório",
"liásico",
"loantes",
"lucenas",
"macungo",
"madride",
"madrijo",
"magulha",
"maladas",
"manesas",
"manoses",
"mascabe",
"mesídio",
"monteio",
"moureje",
"muacara",
"mércola",
"naiques",
"ninarei",
"nisáeto",
"nozadas",
"oariana",
"ocáveis",
"omosita",
"onesina",
"opiando",
"opitimo",
"opsília",
"opólico",
"orçarão",
"otítico",
"ovisaco",
"oxamido",
"pacejar",
"pagadão",
"paivoto",
"paliava",
"pamuari",
"pardosa",
"pecuapá",
"pedaria",
"pedicel",
"peiudas",
"pelavas",
"peleles",
"pelucho",
"pesteou",
"petreia",
"pifador",
"pilhona",
"pilotai",
"pinalho",
"pinoteá",
"polenda",
"premada",
"primeis",
"propana",
"pérmica",
"quatrim",
"quedaço",
"quibuco",
"rabelas",
"raigrás",
"raivada",
"raleiro",
"ranchas",
"rebitei",
"redante",
"remedei",
"rimavas",
"rogeira",
"rojante",
"rosente",
"rotares",
"ruminai",
"sacarol",
"samarro",
"saranco",
"sarrico",
"sectora",
"sepegar",
"setemos",
"setenas",
"silvada",
"sincela",
"sipário",
"sitarão",
"sobreis",
"sorocas",
"sorosas",
"sorônia",
"sovaria",
"sólimos",
"tabizar",
"tacarão",
"talinas",
"taloide",
"taosita",
"telista",
"tibetes",
"ticares",
"tláspio",
"tongano",
"torrejo",
"tresvia",
"turador",
"uacanga",
"uburana",
"untarão",
"unígena",
"usambau",
"vejóvis",
"vicejos",
"voardes",
"zaberma",
"zagatai",
"zamucos",
"zoopsia",
"zurrara",
"éssedas",
"abacote",
"abatias",
"aberemo",
"aboador",
"abroque",
"acagina",
"acampes",
"acatamo",
"agomais",
"agourem",
"agraulo",
"ajicubo",
"albures",
"alforba",
"alfunda",
"alhorca",
"almiqui",
"aluição",
"alônsoa",
"amagues",
"ambrava",
"amoveis",
"amputas",
"anilino",
"anilite",
"aniquim",
"anisina",
"aparais",
"aporfia",
"arabata",
"arastes",
"araçoia",
"arecuno",
"arejara",
"arnento",
"arreica",
"arrobei",
"aspermo",
"assobem",
"atopamo",
"atrasai",
"atrecer",
"atélogo",
"aurorai",
"aviltei",
"axabeba",
"azevrar",
"açalpão",
"baaísta",
"babaniá",
"babassa",
"babaúca",
"bagudas",
"bajanco",
"balatro",
"balária",
"barrana",
"batagem",
"batilhe",
"beldará",
"betamos",
"bigúmea",
"bilinas",
"bocassi",
"bolemos",
"bombica",
"bonance",
"boqueou",
"buchete",
"buchita",
"bucária",
"burnido",
"burrega",
"cacaréu",
"cafeato",
"caiorda",
"calabas",
"calceto",
"caldada",
"calejas",
"cambeja",
"capetes",
"carazas",
"carenes",
"carácio",
"catanos",
"celopes",
"cenagal",
"cepilha",
"chapriz",
"chidova",
"chincou",
"chuinho",
"clocava",
"conetei",
"conotas",
"cosível",
"cotutor",
"coçavas",
"cravais",
"crimeza",
"crimose",
"cudurru",
"dacotas",
"debasse",
"deborda",
"debreia",
"debrida",
"delibes",
"demasie",
"desaria",
"destemi",
"desvivi",
"dicromo",
"difiras",
"dimesna",
"edictal",
"eláfrio",
"emalham",
"embirar",
"empatai",
"encélia",
"enresta",
"entesoa",
"envolar",
"enxaugo",
"erriçar",
"esampar",
"esboces",
"escabel",
"esmifro",
"esmoque",
"estenos",
"esterai",
"estevar",
"estrosa",
"faceies",
"faconas",
"faiemos",
"feriara",
"festoar",
"ficínia",
"fogalha",
"freguei",
"frulhos",
"fuscina",
"gaimões",
"galhuça",
"ganoína",
"gatante",
"gavilha",
"gradeai",
"graduai",
"grimpou",
"grinfas",
"guatapi",
"guicuru",
"guritas",
"guzerás",
"halação",
"halizão",
"halécio",
"halíida",
"hidrote",
"humetar",
"igapará",
"ilídimo",
"inhamuí",
"isático",
"jampeei",
"jeguiri",
"jiçonge",
"labeata",
"labelar",
"latenha",
"lazares",
"lembefe",
"lereias",
"lerista",
"levedes",
"lhandra",
"ligídeo",
"ligíida",
"lingões",
"lofíida",
"loireci",
"lopense",
"lugamos",
"lêucico",
"madente",
"mafomas",
"maiaria",
"mareato",
"mazorro",
"medista",
"metator",
"mifongo",
"mingava",
"moesina",
"molonas",
"mondual",
"monduaí",
"mosleme",
"movíeis",
"mulende",
"muxirão",
"muçumba",
"nerinda",
"nhurrio",
"nigrite",
"niveína",
"noselha",
"nuevita",
"oariúla",
"obanijé",
"oficies",
"omitias",
"ondasse",
"opadaço",
"opalgia",
"opógona",
"opórica",
"outaria",
"outeira",
"outonou",
"oxicano",
"palecas",
"pantaça",
"panário",
"paracuã",
"pardaça",
"paricás",
"pealado",
"pelasga",
"peleará",
"percada",
"pictavo",
"pinotou",
"pirrura",
"pistado",
"piófila",
"pongada",
"prásias",
"pubeiro",
"quercos",
"quogelo",
"rabanas",
"rapança",
"ratavam",
"realito",
"rebanar",
"refinai",
"rezulho",
"ribaras",
"rifaste",
"rijeira",
"rimanço",
"rodício",
"rogiera",
"rotavam",
"sabanga",
"sabismo",
"sabázia",
"sabínea",
"sacário",
"saiinho",
"salenda",
"salsola",
"sansama",
"sapatar",
"sapense",
"saracuá",
"sarcose",
"sarense",
"senembi",
"sisando",
"solhado",
"solutas",
"sopeses",
"subente",
"sugaras",
"sutrina",
"tangale",
"ticórea",
"tinebra",
"tingues",
"tipicam",
"tiradão",
"tireias",
"tonites",
"triante",
"trilisa",
"trional",
"triteta",
"troixas",
"trotona",
"trápola",
"turumãs",
"túrcico",
"uncínia",
"unjamos",
"uragoga",
"usadiço",
"usurpes",
"vasário",
"vaziúdo",
"vinheca",
"visaras",
"várgeas",
"xenagia",
"xeroses",
"xopotós",
"zabiano",
"zavares",
"zoófago",
"abianos",
"abitola",
"abocava",
"abombar",
"aboruma",
"acaberá",
"aclarai",
"aderias",
"adeusai",
"adregar",
"aforças",
"agaches",
"agerada",
"aglaíto",
"agomado",
"aguaçar",
"alamede",
"aleitas",
"aletrou",
"algalie",
"algemes",
"aligues",
"alorita",
"alvilho",
"amacaxi",
"amagrar",
"amaramo",
"amarize",
"amarona",
"ambroma",
"ambágio",
"anonada",
"anubrar",
"apalada",
"apedras",
"arfador",
"ariacós",
"assiram",
"ateares",
"aviveis",
"azurina",
"baamito",
"babanlá",
"bacubas",
"bageira",
"bailais",
"bailhas",
"balatal",
"baleste",
"baloiso",
"balordo",
"bancais",
"bantina",
"barambo",
"batanca",
"berrega",
"betasse",
"bismais",
"bolaços",
"brabejo",
"brabila",
"brelhos",
"breveza",
"brotera",
"bulbina",
"cafanga",
"cafurna",
"cambula",
"camouco",
"capoque",
"carname",
"carneus",
"carnuça",
"ceveira",
"chabeta",
"chachal",
"cifadas",
"cizirão",
"cociclo",
"colaras",
"colique",
"corcada",
"corongo",
"cravino",
"creiamo",
"cuiadas",
"cóscoro",
"debelei",
"decanol",
"dicasso",
"difamai",
"editoro",
"elidiam",
"embreei",
"encoito",
"enliçar",
"enteste",
"entouro",
"enxidro",
"enótrio",
"epítema",
"errátil",
"etálica",
"euboico",
"eugleno",
"expenda",
"expirai",
"faiarem",
"fendias",
"fenelho",
"fetusca",
"fitança",
"fluamos",
"fluorol",
"fogueie",
"funelho",
"gavaria",
"gigurro",
"glecoma",
"gripina",
"guamesa",
"guedice",
"guineis",
"helênia",
"herbosa",
"humosas",
"hyblita",
"incenda",
"ingleso",
"iodônio",
"ionizei",
"iridite",
"isanúzi",
"isoetal",
"itacava",
"ixâmata",
"jarreto",
"jegrama",
"jibelim",
"jibongo",
"junguer",
"jurguem",
"jutaúba",
"larecer",
"latinam",
"lealeza",
"lentara",
"lharufo",
"lhiçada",
"lidente",
"limpeis",
"linneia",
"lorário",
"luitará",
"luxança",
"macates",
"machial",
"macutos",
"magouas",
"marinem",
"medismo",
"megário",
"mengada",
"metânio",
"micenos",
"midique",
"miguins",
"mirmece",
"modorro",
"moelele",
"moliana",
"moncone",
"mosquia",
"motetar",
"mourete",
"mumizar",
"mundari",
"muruqui",
"muvomba",
"narisco",
"nasalam",
"nauseie",
"nazáreo",
"noógeno",
"nupciai",
"omócera",
"onzenar",
"opódimo",
"opófilo",
"opólito",
"opórino",
"orneiam",
"oxamida",
"oxindol",
"ozagrel",
"pacmina",
"pacnoda",
"paitina",
"panacém",
"panageu",
"pangeus",
"passola",
"pescota",
"pindoco",
"pingole",
"pipilou",
"pirotas",
"planais",
"pleitas",
"polmear",
"prismes",
"prótide",
"puitado",
"pulgosa",
"quelina",
"quilhes",
"reperes",
"ressaia",
"resílio",
"retasse",
"rigamos",
"rigveda",
"rotoína",
"roubemo",
"réstios",
"sabiaci",
"sabiúna",
"safador",
"sandins",
"saparás",
"sazonai",
"slavina",
"socavou",
"solevam",
"sucando",
"sujigar",
"sulcais",
"tapiaca",
"tarreta",
"tecadas",
"telésia",
"tetilha",
"tineira",
"tlanoma",
"tlipsia",
"torrais",
"trautar",
"trisses",
"troicos",
"trícios",
"tuiucas",
"tunaram",
"tunesco",
"turpesa",
"tutoram",
"uacarau",
"uananas",
"ubíques",
"unipene",
"urubuza",
"urucari",
"urópode",
"vaquito",
"vibília",
"vomente",
"zupando",
"ísquion",
"abanete",
"abaremo",
"acatita",
"acimara",
"agnatia",
"alumais",
"alçação",
"analose",
"anonzus",
"aplísia",
"araxixá",
"aribalo",
"arrigas",
"asselar",
"aventai",
"açodava",
"bagauda",
"bivacou",
"borneio",
"boucelo",
"bracaiá",
"brigoso",
"brâmine",
"cafifar",
"caguira",
"cairina",
"cametaú",
"cangauá",
"cararaô",
"catasta",
"chantão",
"cicliza",
"cnêmide",
"cocarda",
"decupem",
"enfreou",
"enxalmo",
"enzonar",
"epítase",
"esnogas",
"espinça",
"estírio",
"etamina",
"expeças",
"falcoar",
"freirei",
"fígaros",
"fúndulo",
"garotal",
"gitânia",
"glutina",
"gorjeie",
"guarnir",
"ingomba",
"iperite",
"iscuria",
"jalisos",
"labrear",
"lesques",
"limenos",
"lâmpedo",
"lúceres",
"malagma",
"marizal",
"minanas",
"miombos",
"mogores",
"mutungo",
"mázaces",
"oofagia",
"orcinas",
"pardalo",
"patolai",
"percode",
"piolhar",
"pirares",
"pitaica",
"portaje",
"pospelo",
"potreia",
"quijila",
"rebombo",
"recovar",
"recruza",
"refriso",
"reviçar",
"rigante",
"riscoso",
"rucungo",
"serapeu",
"sovelão",
"surques",
"tablino",
"tafular",
"teceloa",
"teutona",
"timiama",
"toranas",
"trígino",
"turânia",
"tálitro",
"têucrio",
"vindiço",
"viseana",
"vitroso",
"volcana",
"abibura",
"abiegna",
"absento",
"acuador",
"aflauta",
"africar",
"agalgar",
"agapeta",
"alealdo",
"alifafe",
"alquicé",
"ananins",
"anquilo",
"apaular",
"arapura",
"aroaqui",
"arrarai",
"arraçar",
"assação",
"atabale",
"avoceta",
"axípeto",
"bairari",
"balador",
"banatos",
"batocar",
"beniquê",
"biságio",
"bleforé",
"bracajá",
"bralhar",
"brejado",
"burabas",
"cabramo",
"cadmeus",
"campeva",
"caraípa",
"cascoso",
"caçoara",
"chantra",
"chechas",
"cheguel",
"chochem",
"chousas",
"coarcto",
"coaxada",
"confuto",
"crocoió",
"crumatá",
"cupânia",
"deludir",
"desapor",
"dessujo",
"dessumi",
"dictina",
"disopia",
"eivaram",
"enleara",
"equável",
"escardo",
"esgalgo",
"esparre",
"esporim",
"etático",
"fagaria",
"fateiro",
"federes",
"filosas",
"flamara",
"fluorar",
"fonfona",
"fragura",
"freiral",
"frótola",
"fumarar",
"fúlvido",
"gatunho",
"genomos",
"golelha",
"gonídio",
"gradino",
"gramina",
"grateia",
"guaraio",
"hiparca",
"híraces",
"ibijara",
"iecuana",
"igaraté",
"ileoses",
"indutar",
"inercio",
"ionizem",
"ipequis",
"janelam",
"jantina",
"jâmbica",
"lacteis",
"latilos",
"logânia",
"maconis",
"mairata",
"margeei",
"matipus",
"melanto",
"melúria",
"mesápio",
"moreões",
"nagadas",
"nagaram",
"nóctuas",
"olmedal",
"ondeira",
"osteose",
"patucas",
"pelevis",
"perecei",
"perieco",
"pinçará",
"piongos",
"psilose",
"puxavão",
"pérulas",
"quinhoa",
"recrava",
"regirar",
"rubidez",
"rumorar",
"saibrar",
"semisse",
"silvite",
"soabriu",
"sojigar",
"sometes",
"séptuor",
"tartago",
"terçada",
"tocaiou",
"tocoiós",
"trinara",
"trítias",
"tucumãí",
"uraneis",
"vaiante",
"vatéria",
"velosas",
"volucre",
"xilópia",
"xurdido",
"zingrar",
"abcásio",
"abodega",
"aboiada",
"acatina",
"aceroso",
"achachi",
"aclimou",
"acrídio",
"actébia",
"afaimar",
"aforese",
"aguarei",
"agâmico",
"ajibonã",
"albogue",
"alepino",
"algemia",
"algirão",
"aliácea",
"altênia",
"alvazir",
"alvenas",
"amblose",
"amocada",
"amovida",
"amplexa",
"anapuru",
"anconeu",
"anediar",
"anurese",
"apeirar",
"aprazes",
"apétalo",
"arapuam",
"arauaná",
"arauiri",
"arrebém",
"arruava",
"arsinas",
"axífugo",
"açafrol",
"bacabaí",
"baixemo",
"bandeis",
"bestava",
"bissexo",
"biônimo",
"bogante",
"boranas",
"borismo",
"borjaca",
"bouchar",
"brucite",
"brádipo",
"bulbulo",
"bululus",
"burrama",
"cabucho",
"caibrar",
"camueca",
"caracus",
"carnijó",
"catuqui",
"caxinga",
"chircas",
"ciclite",
"cilhões",
"cimácio",
"coagira",
"colpado",
"contego",
"costele",
"crênula",
"cuinhar",
"cutiara",
"céfalas",
"desmana",
"despear",
"dialila",
"dibases",
"dijinas",
"dilogum",
"durázia",
"ecoarei",
"eleúria",
"empalai",
"empomba",
"enfeiei",
"enfumar",
"engonia",
"enigmar",
"enrança",
"entiais",
"enxerca",
"enxerco",
"escodar",
"esputar",
"estibal",
"estírax",
"eudista",
"evitemo",
"exsicar",
"falheis",
"falsado",
"fantons",
"fissuro",
"flechei",
"frocado",
"fuzilai",
"fóricas",
"garrama",
"geoides",
"granete",
"grunado",
"grássia",
"guaripé",
"guriris",
"gáticos",
"gâmicos",
"hadroma",
"heteria",
"hialita",
"imerece",
"infusar",
"inumara",
"isâmico",
"isófono",
"janúbia",
"lambear",
"larétia",
"laçares",
"ledices",
"linhoso",
"lipuria",
"liófobo",
"lolismo",
"láctide",
"macúria",
"madista",
"maiambu",
"manicus",
"marimos",
"marruaz",
"massilo",
"massona",
"meenses",
"mezenas",
"minerem",
"mirabes",
"miserai",
"molungu",
"moralão",
"morzelo",
"mozetas",
"nevoaça",
"ningres",
"ocitana",
"ocrácea",
"octódio",
"ofítico",
"ongombe",
"oreoica",
"orfanar",
"ortósio",
"ostrina",
"ovençal",
"paliais",
"palrice",
"papilha",
"pipitar",
"pisídio",
"pochade",
"prótiro",
"quartou",
"queijei",
"queijou",
"quiista",
"rabular",
"raigota",
"rajante",
"ranceio",
"ravines",
"rebusno",
"refalei",
"regraxo",
"retroou",
"rodense",
"rorejam",
"rubreno",
"réticas",
"saxorne",
"seclusa",
"septena",
"seriamo",
"setácea",
"siderai",
"sinetar",
"slôgane",
"sorrate",
"sorédio",
"suleiro",
"suranas",
"séquano",
"sílices",
"tabicar",
"tambaca",
"templas",
"tentemo",
"tipicar",
"titubar",
"tiólica",
"togaras",
"tonavam",
"toraces",
"touruno",
"truanaz",
"trífano",
"trívios",
"umbunda",
"urticar",
"uvítico",
"valense",
"varudos",
"veletos",
"velicar",
"vermine",
"volitei",
"xiloide",
"zumbrir",
"zíncico",
"abaetar",
"abalsar",
"aborcar",
"abrumou",
"abstida",
"acanati",
"acaraçu",
"acheira",
"acnodal",
"acridão",
"acrinia",
"aderece",
"afeleia",
"afírica",
"airavam",
"alacras",
"albiões",
"alfarva",
"almatrá",
"altrose",
"alunite",
"alvener",
"alógamo",
"amativo",
"ambaúba",
"amonada",
"amêixoa",
"anegrar",
"anoites",
"antódio",
"apócino",
"apózema",
"araboia",
"aranhei",
"ardilão",
"ardosos",
"aricapu",
"arilado",
"arriçar",
"arvéloa",
"asteque",
"atrapes",
"atriana",
"atélico",
"bacoral",
"bafassá",
"bainhar",
"balbuto",
"baringa",
"bexigar",
"bigêmeo",
"bilbode",
"biplume",
"bispava",
"bolçada",
"bouchea",
"bouchei",
"bravejo",
"bromada",
"bruxear",
"burbons",
"bálanos",
"caapiás",
"cacuana",
"cacundo",
"cafinfa",
"calíbio",
"caçoulo",
"cebolal",
"cempsos",
"cenhoso",
"cenista",
"cetrino",
"ceveiro",
"cevenol",
"chatino",
"chelins",
"chineio",
"chofrar",
"cimosos",
"clivosa",
"coactar",
"cogular",
"coroque",
"corrixo",
"corônis",
"crespar",
"crinito",
"ctônios",
"cuincha",
"cuincho",
"cuminal",
"cunambi",
"curense",
"curismo",
"cúlaque",
"dedaria",
"desanos",
"detenço",
"diênica",
"dobrete",
"dogcart",
"doideja",
"donjuán",
"drongos",
"duelada",
"eivaria",
"elmínia",
"emagrar",
"encalir",
"enequim",
"enloque",
"enquice",
"entecer",
"entivar",
"entolas",
"enófobo",
"enóforo",
"epoptas",
"epípode",
"esboroo",
"esmagão",
"esmaiar",
"estivou",
"estolei",
"estreto",
"eternem",
"etésias",
"etólias",
"eupatia",
"excelir",
"extorso",
"exópode",
"fachudo",
"fagácea",
"faiante",
"faramos",
"fararia",
"fasiana",
"ferreis",
"fetação",
"fiadura",
"floramo",
"fortaço",
"fritamo",
"frondar",
"fumegas",
"fustete",
"gaiante",
"gambele",
"goderar",
"gratear",
"guavina",
"guriaçu",
"ideaste",
"ilusora",
"imbamba",
"incubei",
"indúvia",
"inzonar",
"iranxes",
"isócora",
"jugadar",
"lameche",
"lampona",
"lanteia",
"laparão",
"libavam",
"licanço",
"lilácea",
"lomátia",
"luvales",
"láurica",
"mabaços",
"macaguá",
"manchil",
"manicos",
"manocar",
"merisma",
"mialhar",
"milicie",
"mondina",
"motório",
"muniche",
"mussumé",
"muxicão",
"mádidas",
"mágnons",
"nacrita",
"nauquás",
"nigelar",
"nomante",
"nucleal",
"nudária",
"objurga",
"obsecra",
"ondejar",
"opalita",
"orfeico",
"oscitar",
"ostiaco",
"ovívoro",
"palejar",
"parirei",
"paruara",
"pascoei",
"pavesar",
"pedique",
"pelanga",
"peligno",
"picaças",
"pinácea",
"pirílio",
"possava",
"potocar",
"propóxi",
"próstio",
"pugilar",
"quelelê",
"quilohm",
"quiriru",
"quínase",
"raposia",
"rebique",
"recibar",
"recouto",
"redoiça",
"refilho",
"refrise",
"reprego",
"restira",
"retovar",
"rifador",
"ritmiza",
"rolaras",
"rotácea",
"rudáceo",
"rufiona",
"rugidas",
"sacanga",
"sarrido",
"saxátil",
"seranda",
"sereíba",
"songues",
"sovelar",
"subpolo",
"suciata",
"superpô",
"tacurus",
"tafulão",
"taludar",
"tambicu",
"tapiara",
"tataíba",
"tecidão",
"tefrito",
"terriça",
"tifosos",
"tinidas",
"tomelos",
"tomitas",
"torreja",
"trainou",
"transiu",
"trateio",
"tremoia",
"trifila",
"trigosa",
"trípilo",
"tutucar",
"táraque",
"téssala",
"tíglico",
"ubacaba",
"uleiota",
"ultrice",
"undante",
"untores",
"vadosas",
"vicênio",
"vidante",
"vinhemo",
"vinosas",
"vinteno",
"vitorie",
"vogador",
"víspere",
"ximbira",
"zambaio",
"zebrino",
"zonzear",
"úsnicos",
"abaloar",
"abalçar",
"abaúlam",
"abodego",
"aborais",
"abradar",
"abrejar",
"acapnia",
"aceimar",
"acentor",
"acinese",
"acunham",
"acunhei",
"acídias",
"acôncio",
"adagial",
"adargar",
"adiques",
"adlúmia",
"adofiró",
"adutiva",
"adátilo",
"afainar",
"aiapana",
"aiaraçu",
"alaudas",
"alepina",
"algáceo",
"aliagem",
"aljazar",
"almaala",
"almadra",
"almôade",
"amaribá",
"amberol",
"amidino",
"amoldei",
"amoucar",
"anagoge",
"ananaís",
"anauerá",
"anemiar",
"anielar",
"anilida",
"aniseta",
"anovelo",
"antecor",
"apajear",
"apalmar",
"aperuar",
"apiades",
"aptaria",
"aquetos",
"aquiria",
"aracuão",
"araxixu",
"ardidão",
"arecuna",
"argivas",
"arrieis",
"arrijar",
"arritmo",
"arrózia",
"artenas",
"arumaçá",
"assemia",
"atabafa",
"atapeto",
"atelhar",
"atolamo",
"atrigar",
"atropar",
"auferes",
"auxesia",
"axífero",
"azebrar",
"azerola",
"açudeco",
"açulará",
"bacarás",
"bacolas",
"bafejei",
"baicara",
"baixete",
"bajaria",
"balroas",
"balsame",
"bandeta",
"baniana",
"barachu",
"bararau",
"bastura",
"bejaqui",
"benjuim",
"berrego",
"betemos",
"biguana",
"bigúmeo",
"bijetor",
"biocoro",
"bitacas",
"boatice",
"bocelão",
"bocônia",
"borbote",
"bordeou",
"borusso",
"botalós",
"boxeava",
"brecara",
"buteiro",
"buçarda",
"cabruns",
"cacuris",
"cadmiar",
"cambiuá",
"canguás",
"canzoal",
"cariama",
"casease",
"castina",
"ceráceo",
"chamejo",
"chandel",
"charodó",
"choutão",
"chátria",
"cinture",
"cnêmida",
"coadura",
"coaxara",
"cofinal",
"cogoilo",
"combona",
"conceis",
"congoxa",
"copelar",
"coriavo",
"cornizo",
"corroio",
"cotarei",
"cracaxá",
"crajuru",
"craseio",
"crépido",
"cuandus",
"curuaia",
"curuiri",
"cílices",
"córions",
"decoara",
"dedáleo",
"dentelo",
"deperde",
"desarar",
"desguio",
"despica",
"detraio",
"digredi",
"diguice",
"dilarga",
"ditecas",
"diácope",
"dosemos",
"dânacas",
"díctico",
"ecianas",
"ecoísmo",
"efigiar",
"eleagno",
"elátero",
"emantar",
"emboléu",
"embraba",
"emporca",
"enantes",
"encubou",
"enforro",
"enfusta",
"enfuste",
"enramam",
"enramou",
"ensecar",
"entejar",
"enxalço",
"enxovar",
"eocenes",
"esbouça",
"escarpo",
"esfuzio",
"esguiam",
"esguiou",
"esmaeci",
"esmocar",
"espipar",
"esqueno",
"estazar",
"esterou",
"estroso",
"etérato",
"euscara",
"evulsão",
"excises",
"expetar",
"expluir",
"exstado",
"exstara",
"faceais",
"faceava",
"facoide",
"faláris",
"faquino",
"farisca",
"fartemo",
"felador",
"femeais",
"ferroem",
"figália",
"fiscela",
"forlana",
"formeno",
"fregava",
"frôndeo",
"fucsita",
"fulinha",
"fungina",
"fustina",
"fêmicos",
"fênigue",
"fúlguro",
"gapuiar",
"garotio",
"gazetal",
"gemiais",
"gipaeto",
"gobiões",
"golpeão",
"gosmado",
"gradaós",
"gradeie",
"grafila",
"gralhem",
"granais",
"granear",
"grogojó",
"grueiro",
"guaiule",
"guebros",
"guenzas",
"gurandi",
"guriabá",
"hialite",
"hircina",
"hérnica",
"hínicas",
"iatagãs",
"ictíaco",
"idumeia",
"idálico",
"impaste",
"inervou",
"inflita",
"inopina",
"insétil",
"inçavam",
"iraúnas",
"isoípsa",
"isógono",
"jabutim",
"jactura",
"jalapas",
"jaronda",
"judengo",
"labaçol",
"lacerei",
"ladairo",
"lajiano",
"lamista",
"lanceie",
"lapidai",
"lazaria",
"levadio",
"levidão",
"lisonje",
"liófila",
"liófoba",
"lobacho",
"loteara",
"lucivéu",
"luisita",
"lumiado",
"luxavam",
"lázaras",
"lúdicro",
"macabas",
"machias",
"maclada",
"maganza",
"majorei",
"malfira",
"malquis",
"mandobi",
"mangoça",
"marmaço",
"marnota",
"maronas",
"matruco",
"mdcccxx",
"megabar",
"megálio",
"menápio",
"metense",
"meáveis",
"mimbura",
"mindubi",
"moderai",
"moinhar",
"mojiano",
"molugem",
"monecia",
"mordome",
"motinar",
"motória",
"mucungo",
"mucutas",
"mugambo",
"muritis",
"napeias",
"nefroma",
"negaceá",
"niquela",
"nomeais",
"noseana",
"noógena",
"oiriçar",
"onóclea",
"orfanou",
"orfista",
"osteína",
"oxentes",
"oxifilo",
"ozônido",
"paleira",
"palmava",
"palília",
"pardaço",
"paredra",
"partamo",
"pavanos",
"peitama",
"pelvino",
"penujem",
"pepinar",
"peroras",
"perídea",
"pinacol",
"pinimas",
"piraaca",
"plotara",
"plumaço",
"podargo",
"podoces",
"polpava",
"postega",
"potroso",
"precato",
"preliar",
"prendai",
"priorem",
"priscar",
"prônubo",
"puteais",
"pértigo",
"pícnica",
"pírtiga",
"pórcias",
"rabadão",
"rasurem",
"ratuína",
"reboada",
"recatou",
"rechano",
"reenchi",
"regreta",
"relhota",
"relocam",
"renitir",
"ressaio",
"revedor",
"revenis",
"ricungo",
"rodouça",
"roliçar",
"roseara",
"rotáceo",
"roupala",
"rudácea",
"sabelas",
"salmita",
"salvete",
"saprema",
"senembu",
"sequeis",
"serzeta",
"sessada",
"sevemos",
"sobraji",
"sofrena",
"sublevo",
"subraji",
"sungado",
"tabuame",
"taipuru",
"talcoso",
"tamatás",
"tamônea",
"tanalas",
"tariota",
"tarruca",
"taçuíra",
"tetavam",
"tilomas",
"tipisca",
"tirázio",
"trilice",
"tritada",
"trochar",
"trígone",
"tuquira",
"tármica",
"túberos",
"uracaçu",
"urcéolo",
"urnário",
"urtigar",
"uterite",
"uxirana",
"uzífuro",
"vesicar",
"vigilas",
"vimíneo",
"vinhago",
"volutos",
"vovente",
"vriésia",
"zeremos",
"zincite",
"zonzeia",
"ínsubre",
"abadiar",
"abangue",
"abaúnas",
"abdalas",
"abolais",
"abolçar",
"abridão",
"absonar",
"abutuas",
"abuçará",
"acalcam",
"acalefo",
"acatemo",
"acaçapa",
"aceifar",
"acensos",
"acerava",
"acesume",
"acilara",
"aclídio",
"acofiar",
"acoreia",
"acornar",
"acroíta",
"acuição",
"adernei",
"adileis",
"advocou",
"afalava",
"afemear",
"afiarão",
"aficado",
"aflavam",
"afresca",
"agalhar",
"agelena",
"agoniem",
"agoural",
"agáloco",
"agático",
"alacrão",
"albafor",
"alerião",
"alfarda",
"alferça",
"algoide",
"alhares",
"aliamba",
"alizari",
"aliável",
"almôfar",
"aloques",
"alousar",
"alpense",
"alpidem",
"alquifu",
"aluísse",
"alvaner",
"amarará",
"amardas",
"amarujo",
"ambrear",
"amouque",
"amumiar",
"anadema",
"anaiuri",
"anelosa",
"anoitar",
"anoitou",
"anoplos",
"anoutou",
"anteses",
"antífen",
"anábata",
"anísica",
"aperreá",
"apocopa",
"apunhar",
"arataiá",
"araxana",
"ardosas",
"arduína",
"areense",
"arensar",
"arramas",
"arriais",
"arridas",
"arruçar",
"asmento",
"assetar",
"assonia",
"astense",
"astreve",
"ataviei",
"atenrar",
"ateremo",
"ativemo",
"atiçais",
"atrégua",
"aucúpio",
"augitas",
"auratos",
"avadana",
"avelais",
"avessia",
"aviarão",
"avieram",
"azangar",
"azarcão",
"azedete",
"aziumar",
"babujem",
"bajesto",
"balroar",
"bambada",
"banhais",
"baratei",
"barbuta",
"baremês",
"barrego",
"barroar",
"barroto",
"bastear",
"baxorum",
"beiçoca",
"beldros",
"belecar",
"beluíno",
"betilho",
"biatora",
"bibicas",
"biloura",
"biparia",
"birutou",
"bismita",
"biátlon",
"biênico",
"blatera",
"bobeias",
"bocelar",
"bocelim",
"bofetas",
"bogango",
"boicipó",
"bojante",
"brandei",
"braveje",
"brechou",
"broques",
"broslar",
"brossar",
"bubônia",
"buquine",
"buxeira",
"bíbalos",
"cachudo",
"cajubim",
"calorim",
"calvado",
"camenas",
"cariate",
"carmear",
"cassoco",
"catanga",
"cauchal",
"caudino",
"cauintã",
"cavuque",
"caçouro",
"cccxxii",
"cclxiii",
"cclxxii",
"cclxxiv",
"cclxxxi",
"ccxcvii",
"ceivado",
"cetenos",
"chanuca",
"chapota",
"charlou",
"charole",
"chazais",
"chiaras",
"chilras",
"chircal",
"chorram",
"chuetas",
"cicerar",
"cieiros",
"cingula",
"circeia",
"ciumosa",
"civista",
"cladode",
"coaluno",
"colóbio",
"concuta",
"corruda",
"corusco",
"cosedor",
"cotanês",
"couruda",
"covoada",
"cratego",
"cravete",
"cuneano",
"curemas",
"cutisar",
"cutiúba",
"cutruca",
"cíprico",
"cítolas",
"dadivou",
"deborde",
"deciare",
"decupas",
"decurio",
"deduzas",
"degotar",
"desabro",
"desadas",
"desasna",
"descana",
"desfale",
"desfaso",
"despene",
"despese",
"despoli",
"destala",
"destelo",
"desumir",
"detraiu",
"detrate",
"deveniu",
"deísmos",
"digamas",
"doermos",
"doidelo",
"dolomia",
"ducatão",
"dxxxvii",
"díbraco",
"dícroto",
"dóxicos",
"ecoides",
"eleando",
"elinvar",
"embalce",
"embraga",
"embreie",
"embruma",
"embucei",
"empezei",
"emposso",
"empuxou",
"empáfio",
"encases",
"encelar",
"encepar",
"eneense",
"enfogar",
"enfusca",
"engaiar",
"engaçar",
"enjunta",
"enlocar",
"enlodou",
"enlojar",
"enoitar",
"enoplos",
"enrijei",
"ensebei",
"entojou",
"entolai",
"enzampa",
"epilado",
"eritrol",
"erotizo",
"eructam",
"eríngio",
"esbofam",
"escodas",
"escorve",
"escádea",
"esgalhe",
"esnobas",
"espiamo",
"espolim",
"estalia",
"estouva",
"esvazou",
"esvento",
"eustilo",
"evertém",
"excetor",
"exerdar",
"exógino",
"facheou",
"fajutão",
"faséolo",
"febrina",
"feitiar",
"fenetol",
"ficelas",
"fictora",
"fiorite",
"flegmas",
"focante",
"forçura",
"fossete",
"freante",
"freirar",
"frâmeas",
"fulecas",
"fulveno",
"fundego",
"funfará",
"fungara",
"fálcula",
"fáretra",
"fíticos",
"gaitear",
"galanar",
"galucha",
"gangoso",
"gaponga",
"garulas",
"gegânia",
"gelaste",
"gipsito",
"giroide",
"gobídeo",
"goiamus",
"goipeba",
"gongoro",
"gonzemo",
"goreiro",
"grageia",
"grúmulo",
"guacina",
"gâmicas",
"gôngilo",
"hermeta",
"hiclato",
"hipuria",
"hircoso",
"ianomam",
"ibixuna",
"illitas",
"imbaúva",
"imergis",
"imparam",
"impelis",
"incindo",
"incises",
"incisou",
"indanos",
"influis",
"ingomas",
"insetas",
"insuave",
"intrico",
"iodetei",
"iricuri",
"irrisor",
"irrogam",
"irrorar",
"irídeas",
"isóbare",
"ituituí",
"jacuaru",
"jaguapé",
"jangara",
"jatamos",
"joeirou",
"judeias",
"juncara",
"jângala",
"larídeo",
"laxaria",
"leelite",
"lesemos",
"leônica",
"libamos",
"libemos",
"libolos",
"limaces",
"limpemo",
"linites",
"liricar",
"litargo",
"litique",
"litóreo",
"lobélio",
"lolarda",
"looques",
"lorenês",
"loretas",
"lucúleo",
"lugados",
"lurador",
"luteoma",
"luzincu",
"lâmpeda",
"macacus",
"maciosa",
"macucus",
"macéria",
"maguala",
"mainata",
"malotão",
"manchai",
"maneses",
"mangone",
"marajoa",
"mardana",
"maremos",
"marraxo",
"marsopa",
"marungo",
"marutas",
"masmuda",
"mataime",
"matidez",
"mauaris",
"mauense",
"mdclxvi",
"mealhar",
"medúseo",
"meinaco",
"melaína",
"mequéns",
"merarca",
"merital",
"metilam",
"metópio",
"mezeréu",
"miceneu",
"micênio",
"mijavas",
"miligrã",
"mineana",
"mineano",
"minudar",
"mitical",
"moceiro",
"mogorim",
"moireja",
"molímen",
"molúria",
"moponga",
"morrudo",
"muniria",
"muritim",
"murrões",
"murucis",
"muçulmi",
"métulas",
"mêndola",
"mútulos",
"nadível",
"naipada",
"natento",
"nemoral",
"neuraxe",
"nhaçanã",
"noturos",
"novenal",
"novênio",
"numário",
"nástico",
"oblates",
"obrarei",
"obsidio",
"obstipa",
"ocisiva",
"ocisivo",
"odreiro",
"ofegará",
"ofensão",
"oiteira",
"oleales",
"oleense",
"oleento",
"olmeira",
"onustos",
"orsínia",
"osmandi",
"osmanil",
"ovarino",
"ovígero",
"oxiopia",
"ozênico",
"pajeiam",
"palasse",
"palaíta",
"pampilo",
"panabas",
"panamos",
"pandale",
"panejei",
"parafia",
"pargata",
"parsana",
"parturi",
"pateará",
"patroar",
"peguaba",
"peguira",
"pejaste",
"pelecho",
"pencenê",
"pencinê",
"pendeis",
"perinas",
"perorei",
"perruma",
"pertina",
"petigma",
"picotem",
"pilatas",
"pilcham",
"pirrace",
"pitumba",
"plátipo",
"pongado",
"potrear",
"preleva",
"pristes",
"pronaus",
"pulgoso",
"putirão",
"puxeira",
"puxetas",
"pédions",
"póplite",
"quapoia",
"queleto",
"quibaca",
"rabação",
"rabujem",
"rajaram",
"ramasse",
"raposar",
"rapteis",
"realzão",
"rebebes",
"rebusna",
"rebuçou",
"recapam",
"recibou",
"recinta",
"recovas",
"refegar",
"refilme",
"refolga",
"reinita",
"reiunos",
"relaria",
"relhote",
"renegai",
"reouviu",
"resinei",
"ressabe",
"ressuar",
"reídeos",
"ribação",
"riçadas",
"rodomel",
"romeíta",
"rufante",
"rutáceo",
"sabeíta",
"sacelos",
"samblar",
"samonga",
"sapejar",
"satírio",
"saítico",
"seláceo",
"senecta",
"sermoas",
"serziam",
"silabei",
"sinumbu",
"sipilho",
"soprões",
"subcoro",
"sudista",
"sutinga",
"suturem",
"sétulas",
"sílabos",
"tabulem",
"taceira",
"taiuiás",
"talasse",
"talique",
"tapetam",
"tapiçuá",
"tardião",
"taroque",
"tebeles",
"telugos",
"tentene",
"teocáli",
"teonina",
"teopsia",
"terciam",
"terçará",
"tiazois",
"ticarão",
"tirícia",
"tiônico",
"toronou",
"tortual",
"tovacas",
"tradeis",
"tratriz",
"travela",
"tresavô",
"trevada",
"triódio",
"truacas",
"trácico",
"trífora",
"tróquio",
"tugimos",
"tungava",
"turbito",
"turinos",
"turundu",
"tutelai",
"tângere",
"típulas",
"túrdulo",
"túrnepo",
"uanambé",
"ucuubas",
"uníjugo",
"uranoso",
"urapuru",
"urdiras",
"urinoso",
"urucatu",
"urutauí",
"urético",
"ustular",
"vaabita",
"vacaris",
"vaimiri",
"valedio",
"vaporam",
"vaquejo",
"varante",
"velhoto",
"venador",
"vinagro",
"viscina",
"viuvara",
"viuvice",
"váscone",
"wattada",
"xurdira",
"zairota",
"zambeto",
"zanoios",
"zebruno",
"zoonito",
"zoécios",
"zoóforo",
"zoônimo",
"zunando",
"abarbou",
"abasmar",
"abassem",
"abatela",
"abaulou",
"abebero",
"abeceda",
"abelhoa",
"abicora",
"abitana",
"abláqua",
"abnodar",
"abolsar",
"abrotal",
"absogro",
"acaiano",
"acanoar",
"acarais",
"acarnas",
"acaroar",
"acausto",
"acentra",
"acharai",
"achinar",
"acianos",
"acitara",
"acoaram",
"acoutou",
"acrânio",
"acrídeo",
"acunati",
"acurtar",
"acálice",
"adalide",
"adegada",
"ademães",
"adenção",
"adiciça",
"adjazer",
"adnexas",
"adomava",
"adopera",
"adricem",
"adriçar",
"adunava",
"advimos",
"aeroide",
"aetites",
"afamara",
"afoquei",
"afundai",
"afínico",
"agafita",
"agafite",
"agresto",
"agroste",
"aguanas",
"agulhei",
"aguçoso",
"ailerão",
"alambel",
"alardei",
"alarvia",
"alcacel",
"alcaico",
"alcofar",
"aleirar",
"aleiras",
"alfaiar",
"alfenar",
"alfenis",
"alfofre",
"alforbe",
"alfábar",
"algemai",
"aliazar",
"almofia",
"almofre",
"alnicos",
"alofano",
"altaíta",
"altivam",
"alvalio",
"alvenéu",
"aléxico",
"amangue",
"amansia",
"amarroo",
"amatula",
"ambiano",
"ambrado",
"ambunda",
"ameaçai",
"amidada",
"amielia",
"amoites",
"amojado",
"amourar",
"amíngue",
"anantas",
"ananzar",
"anatamo",
"anaudia",
"andacas",
"andejou",
"aneixas",
"anomuro",
"antojou",
"antóbio",
"anuviei",
"apedrar",
"apedrei",
"aperada",
"apestas",
"apodrir",
"apojava",
"aporiam",
"apoutar",
"apremer",
"apresas",
"apusera",
"apófige",
"aquense",
"ararigã",
"arauató",
"arileno",
"armavas",
"armolão",
"arpente",
"arraiou",
"arraião",
"arrares",
"arroiar",
"arrojão",
"arrolos",
"arroube",
"arrugou",
"arsônio",
"asantes",
"asdingo",
"assacas",
"asseara",
"assetas",
"atarefo",
"atintar",
"atulhei",
"atupira",
"aturiás",
"autepsa",
"avacada",
"avelado",
"avelhar",
"avincar",
"axorcar",
"axorcas",
"axículo",
"axúngia",
"azedote",
"azeitas",
"azuloio",
"azêmela",
"açaimou",
"açotear",
"açoutam",
"bacabão",
"bafioso",
"bagaras",
"balasse",
"baldréu",
"balirem",
"balotas",
"bandões",
"banhâni",
"baniane",
"baronês",
"barquês",
"barrota",
"baruria",
"basteie",
"bastemo",
"bateava",
"batucas",
"beaqueo",
"beerita",
"beguira",
"belinos",
"bialado",
"bilênio",
"biopsie",
"bipamos",
"bipasse",
"bitinga",
"bixácea",
"bizogue",
"biólise",
"biólito",
"blefaia",
"bobarão",
"bolares",
"bolcões",
"bonaris",
"bonecro",
"bopiana",
"borboró",
"boseira",
"bozerra",
"braceia",
"braceio",
"bravoso",
"brijões",
"brumais",
"buliços",
"bumbada",
"buruaca",
"burusso",
"buseira",
"bóbonax",
"búculas",
"caatica",
"cablamo",
"cabroco",
"cabundá",
"caconda",
"cadouço",
"caiarei",
"cainhou",
"cajaleó",
"calacre",
"calense",
"calipal",
"calulos",
"calumbé",
"camalha",
"camanás",
"cambéua",
"campava",
"candona",
"canetam",
"canista",
"canudar",
"carales",
"carbite",
"cardear",
"cardeia",
"cariais",
"cariana",
"carinal",
"caruera",
"cataúba",
"catutos",
"cavedal",
"cavidar",
"cavídeo",
"caxaréu",
"cccviii",
"cccxxxv",
"cclviii",
"cclxvii",
"cclxxix",
"cclxxvi",
"cclxxxv",
"cdxviii",
"celeira",
"cenismo",
"ceramos",
"cerotos",
"cevadal",
"chairar",
"chameei",
"changar",
"charréu",
"chateai",
"cheiura",
"chertes",
"chiadão",
"chiarei",
"chilcal",
"chirrio",
"chorote",
"chuchua",
"chupite",
"cidônia",
"cilhado",
"cineres",
"cintava",
"cinteis",
"cisaria",
"cistoso",
"ciáveis",
"clipeal",
"clivais",
"clácson",
"clímace",
"coatara",
"cobramo",
"cocular",
"cocumbi",
"cofiará",
"coincho",
"colemia",
"colmava",
"colmear",
"comodou",
"conluie",
"conçado",
"conídeo",
"copraol",
"copules",
"cordoar",
"corigas",
"corneie",
"correjo",
"corutos",
"corônio",
"cousada",
"cranias",
"cratena",
"crautás",
"cravejo",
"crocite",
"cubetos",
"cuidaru",
"cujubas",
"cumbeba",
"cunamas",
"curiose",
"cutilei",
"cutúbea",
"cébidas",
"céricos",
"cúpreos",
"dafnina",
"damejar",
"danaida",
"datisca",
"decussa",
"demudam",
"dentuda",
"desazar",
"despões",
"desquis",
"dessuar",
"desvaio",
"desvale",
"dienses",
"digamia",
"digrida",
"diluais",
"diplomo",
"disemia",
"disodia",
"ditaras",
"dlxviii",
"dlxxiii",
"dobaram",
"dobação",
"domaras",
"dombeia",
"donosos",
"dopador",
"dormeço",
"doçaina",
"drainar",
"dxxviii",
"dxxxiii",
"dáctile",
"dístomo",
"ebuliam",
"eclegma",
"edenita",
"edenite",
"efialta",
"egilope",
"eivamos",
"eivasse",
"elassem",
"eligida",
"elogiai",
"elongam",
"elúvios",
"emalava",
"embarés",
"embauca",
"emblico",
"embonar",
"embotei",
"ementei",
"empalas",
"empolha",
"empófia",
"encovou",
"endoado",
"enebrio",
"enfaram",
"enfinco",
"enfusar",
"engalar",
"engrupi",
"engulem",
"enjicar",
"enodoem",
"enolado",
"enoutar",
"enripar",
"ensilam",
"entonem",
"entraje",
"entrita",
"entélea",
"envarar",
"envesar",
"enxoada",
"epagoge",
"epifilo",
"epigeia",
"epitalo",
"epílios",
"erodias",
"esbruga",
"escolmo",
"escopes",
"escorne",
"escotia",
"escoves",
"escupis",
"esmerim",
"esmolna",
"esmoído",
"espotar",
"esquiem",
"esquifo",
"estaiam",
"estibas",
"estocai",
"estojar",
"estonta",
"estucho",
"estumei",
"estumou",
"esurino",
"evacuas",
"evagine",
"exauste",
"exicial",
"extubou",
"faceeis",
"faiador",
"faixear",
"falvalá",
"fanchão",
"fararam",
"fardeis",
"farpeia",
"farrado",
"feladas",
"felarei",
"fenados",
"ferrejo",
"feófita",
"filhais",
"filitas",
"filípio",
"fisoide",
"fitoide",
"flamulo",
"flipara",
"focadão",
"focídeo",
"folecho",
"foleiam",
"folhato",
"fonista",
"foranto",
"forgoso",
"fornais",
"fosfata",
"fosfena",
"fossava",
"fracado",
"fradete",
"fradice",
"fragais",
"fragmas",
"fraldou",
"frangem",
"frascal",
"fraxina",
"frevava",
"frictor",
"frimans",
"frutear",
"fucoide",
"fucácea",
"fugadas",
"fugatos",
"fulecam",
"funador",
"fungões",
"furacar",
"fuseira",
"fuzilão",
"fáunula",
"férmios",
"fórfice",
"gabavas",
"gafonha",
"gahnite",
"gaiolim",
"gajados",
"galhaça",
"galimas",
"galipão",
"galopeá",
"ganchar",
"ganches",
"ganchou",
"garfear",
"garreou",
"garçota",
"gaspear",
"gatarem",
"gatesca",
"gaturar",
"gavasse",
"gazense",
"gazilar",
"gazinar",
"gazular",
"gazulas",
"gemicar",
"genrear",
"geodeta",
"gervões",
"gessais",
"gidiano",
"gilense",
"girolas",
"glucina",
"goiaúna",
"goivira",
"goleias",
"gonídia",
"gorrado",
"goteada",
"govento",
"graceta",
"gradins",
"graeira",
"grameal",
"granjeá",
"gratula",
"graçapé",
"grelava",
"grenhos",
"grimpam",
"guaiapá",
"guarirá",
"guarnes",
"guascar",
"guazava",
"gudiões",
"guingue",
"guiraca",
"gurunxe",
"gádidas",
"gálhico",
"gêminos",
"hachure",
"harteio",
"hastato",
"hepatal",
"hermete",
"herniou",
"hileano",
"hircana",
"hiálito",
"homilie",
"hopeíta",
"hélvios",
"iageína",
"iatrina",
"iboense",
"icosano",
"ideável",
"idiotam",
"ilídima",
"imbiras",
"imedias",
"imideto",
"imparem",
"impendi",
"impetar",
"impilam",
"inanida",
"infidos",
"insueto",
"invidie",
"inóxios",
"ipeense",
"iradora",
"irapurá",
"irianos",
"iriceca",
"iscaria",
"isoetes",
"isopata",
"isófago",
"isófano",
"iuberis",
"jamaxis",
"jamésia",
"janaras",
"jangado",
"japiaçó",
"jaspeou",
"jauaris",
"jequitá",
"joelham",
"jongara",
"judiara",
"judicas",
"jugulou",
"jupindá",
"jurumum",
"labrosa",
"labátia",
"labíolo",
"lacerem",
"lambaia",
"lambaze",
"lambião",
"lambote",
"lameire",
"lamines",
"languas",
"languis",
"laponas",
"lareais",
"lassara",
"latrias",
"lebreia",
"lemingo",
"leonada",
"leproma",
"lestava",
"letrudo",
"librava",
"licaste",
"licáone",
"limotes",
"linhote",
"lipária",
"litamos",
"litória",
"livelar",
"livusia",
"lixanço",
"lixivie",
"lizavam",
"liócomo",
"lobetes",
"lomária",
"luandês",
"luarize",
"ludroso",
"lugaram",
"lunular",
"lupoide",
"lusones",
"luzetro",
"lícidas",
"lúdrica",
"maarâni",
"machego",
"macista",
"macucar",
"macucau",
"madgiar",
"maginam",
"malolos",
"malumba",
"mametos",
"mamotes",
"manauês",
"mandibé",
"maniado",
"manicla",
"maniete",
"manjola",
"manteou",
"maperoá",
"marigui",
"marocho",
"marolos",
"massacá",
"matapim",
"matejar",
"matinai",
"mcmxlix",
"mdlxxxv",
"meigoso",
"melambo",
"mengara",
"meniéns",
"mepinda",
"merdais",
"metenos",
"miassem",
"michado",
"michola",
"mijadão",
"mijocas",
"miligal",
"mimicam",
"mimique",
"miniano",
"miolema",
"mioloso",
"miriare",
"mirtedo",
"misteca",
"mixorne",
"mocheta",
"modicou",
"mofatra",
"mofinez",
"monopse",
"moquida",
"morengo",
"morilho",
"mororós",
"mosanos",
"moxamar",
"moçaste",
"mucuaxe",
"mudante",
"murarem",
"murerus",
"murraya",
"murreta",
"murumbu",
"mururus",
"musanga",
"muxirom",
"muxirum",
"mápanai",
"nababia",
"nacaras",
"nagando",
"nandaia",
"napáceo",
"narcise",
"nasutos",
"natátil",
"necroso",
"negrola",
"negrote",
"nervina",
"neógala",
"ngandas",
"nhambuí",
"nidadas",
"niobite",
"nivosos",
"nodoada",
"nodoado",
"nogaico",
"nolanos",
"nolinas",
"nonense",
"normano",
"nuançam",
"nunação",
"nupciar",
"nutramo",
"nébride",
"oasiana",
"obrarás",
"obturem",
"obviara",
"occídio",
"ocreado",
"oculosa",
"oculoso",
"olhenta",
"olmédia",
"olíndia",
"onêmano",
"opaques",
"orgivão",
"oródino",
"osídios",
"otuituí",
"oxazina",
"oxibelo",
"pairais",
"palhote",
"palitei",
"palmeio",
"pampino",
"panejam",
"panorpa",
"papeata",
"papeias",
"parauás",
"pariano",
"parolou",
"paropia",
"pascerá",
"pascoar",
"pasmais",
"patamaz",
"patrole",
"pedresa",
"peidara",
"peiudos",
"pejoram",
"peleano",
"penejar",
"penicam",
"penujar",
"peptase",
"perseia",
"pervago",
"perínea",
"petrose",
"picarmo",
"picrite",
"pictava",
"piculas",
"piloada",
"pingoso",
"pinçote",
"pipinas",
"piracaá",
"piranos",
"piriguá",
"pirrila",
"pirônio",
"pistora",
"pitafes",
"pixunas",
"pixundu",
"pixundé",
"piócito",
"pocemas",
"podrece",
"podrura",
"poiaram",
"polinas",
"polínio",
"ponhada",
"pontari",
"ponteis",
"portajá",
"posseio",
"potages",
"povoamo",
"pracear",
"pratibu",
"prelevo",
"premiai",
"privemo",
"proejar",
"proluxo",
"proseie",
"prunina",
"pruídos",
"prásina",
"psamoma",
"psefito",
"pulheis",
"pululas",
"pulário",
"punilha",
"pávulos",
"pândias",
"pórfira",
"queines",
"quelava",
"quicuca",
"quimano",
"quinato",
"quinche",
"quirate",
"rabonar",
"raceada",
"racinar",
"raconta",
"rafante",
"ragoide",
"ramelei",
"rapiado",
"raponço",
"ratonar",
"rearmei",
"rebeija",
"rebimbo",
"rebitam",
"reborar",
"rebotas",
"rebrame",
"rebumba",
"recocto",
"recolam",
"rectriz",
"refujam",
"reimpôs",
"reiunar",
"remedis",
"remeira",
"renitem",
"renteou",
"repruir",
"resgava",
"resmuda",
"retesem",
"retruso",
"revelir",
"ribombe",
"rigodão",
"riolita",
"riscosa",
"riçando",
"rodoiça",
"romaica",
"roncear",
"roncice",
"roupido",
"roçadão",
"rufarão",
"rugitar",
"sabeana",
"saguari",
"samiana",
"sanioso",
"santico",
"sapatei",
"sapremo",
"saqueto",
"sardosa",
"sarjeis",
"sartava",
"satilha",
"sazonam",
"sebraju",
"sedador",
"sedunos",
"segaria",
"selamim",
"seleira",
"semotos",
"septeno",
"serenim",
"serpeio",
"serrear",
"sestrar",
"setenal",
"setiais",
"setáceo",
"sevicie",
"sfogato",
"siamise",
"sicuris",
"silvosa",
"silvoso",
"sinafia",
"sinoble",
"siracos",
"sismais",
"sissoma",
"siçuíra",
"sobarba",
"sobcapa",
"sobrace",
"socovão",
"solapão",
"sombrou",
"sonamos",
"sonetas",
"sopeada",
"sopitam",
"soqueie",
"sororós",
"sudados",
"suestes",
"sulfone",
"sumaque",
"sumersa",
"suruaná",
"sérpula",
"séviros",
"sêxtuor",
"símpulo",
"síneses",
"sínfilo",
"sóforas",
"súccino",
"tabicão",
"tabulão",
"tachear",
"taimada",
"taipava",
"tamaral",
"tambiús",
"tancado",
"tancara",
"tanquei",
"tapeara",
"tapiras",
"tapizes",
"tapíria",
"tardeza",
"targana",
"tarreca",
"tarsite",
"tarugar",
"taxales",
"taxódio",
"tejoula",
"teratia",
"teríaca",
"testaça",
"testeio",
"tibiras",
"tibúrio",
"ticacas",
"tifales",
"tifácea",
"tiguara",
"timbras",
"timbucu",
"tinidor",
"tiribaí",
"tiriris",
"tiólise",
"todeiro",
"togando",
"toleria",
"tonemas",
"tonário",
"torasse",
"toravam",
"torceio",
"torcula",
"tortuda",
"toutear",
"továria",
"toxicar",
"trangos",
"tratear",
"trenava",
"triaria",
"tribalo",
"trioico",
"tripeta",
"tripsis",
"trisava",
"tritina",
"trompei",
"trompes",
"tropeio",
"trotões",
"trícera",
"tucanuí",
"tucurus",
"tudicla",
"tufosos",
"turveis",
"tutuzão",
"tércias",
"tímbrea",
"uainumá",
"ugolina",
"uiraxué",
"uivavas",
"uiófobo",
"ulexite",
"umulucu",
"uralite",
"urinosa",
"usípios",
"vagueja",
"valadio",
"valaste",
"validio",
"varinel",
"vasento",
"veiudas",
"veiudos",
"veleado",
"veludas",
"venenar",
"venhamo",
"venário",
"verdote",
"vernamo",
"verçudo",
"videava",
"vidouro",
"vidrino",
"vigarei",
"vinhoca",
"violamo",
"vipomas",
"viscara",
"viçaram",
"viéreis",
"voltejo",
"volvamo",
"vêndica",
"xabrega",
"xecados",
"xiloses",
"ximbeva",
"xucrice",
"xáquema",
"xáquima",
"zangais",
"zerumba",
"zeófago",
"zoiatra",
"zotismo",
"zoários",
"zoófaga",
"zunzuna",
"zurzira",
"ítricas",
"abaiano",
"abaloes",
"abanano",
"abancas",
"abarraz",
"abastas",
"abastei",
"abatume",
"abeatou",
"abebere",
"abecedê",
"abestar",
"abicava",
"abjuras",
"abojada",
"abombou",
"abondou",
"abortai",
"abraque",
"absinta",
"abutilo",
"abásico",
"acaiana",
"acajuba",
"acalcou",
"acapitã",
"acaraiá",
"acarará",
"acareou",
"acarrou",
"acasear",
"acataia",
"acenais",
"acendra",
"aceptei",
"acerita",
"acerolo",
"acerras",
"acervou",
"acestra",
"achagar",
"achanou",
"acharou",
"achicou",
"acidrar",
"acnosos",
"acocham",
"acocoro",
"acolejo",
"acotiar",
"acotoar",
"acretos",
"acroase",
"acuitar",
"acursos",
"acurvam",
"acusemo",
"acôntio",
"adagada",
"adagiar",
"adalita",
"adarvar",
"adelfal",
"adelgas",
"adelhão",
"adeniza",
"adeusas",
"adeusem",
"admonas",
"admones",
"adnexão",
"adoidou",
"aduanar",
"aduchas",
"adunque",
"adéfago",
"adélido",
"adínamo",
"aeriana",
"afazias",
"afeador",
"afeavam",
"afilara",
"afilhou",
"afincoe",
"afinfem",
"afingem",
"afolhas",
"aforçar",
"afrutar",
"afrutou",
"afumada",
"afótico",
"ageleia",
"agoiral",
"agonais",
"agouras",
"agourei",
"agraste",
"agriano",
"agrumar",
"aguerês",
"aguinas",
"aguinia",
"aiabeba",
"ajorcar",
"ajoujou",
"ajulata",
"alacrar",
"alamedo",
"alanzoa",
"alassar",
"alazais",
"albende",
"albizzo",
"alcoílo",
"alcídeo",
"alcíona",
"aldeiam",
"aleitai",
"alemoar",
"alestas",
"alfarja",
"alfaçal",
"alfitra",
"alfovre",
"alfurje",
"alicali",
"alifana",
"aliguem",
"alineia",
"almafre",
"almexia",
"almudar",
"aloisar",
"alopras",
"alquime",
"altanes",
"aluaram",
"alumara",
"alumita",
"aluímos",
"aluírem",
"alvíneo",
"amaciai",
"amadias",
"amagada",
"amaguei",
"amainai",
"amaltar",
"amanajó",
"amanava",
"amanhai",
"ambrazô",
"ambulou",
"amechas",
"amileno",
"amniano",
"amocete",
"amochei",
"amorite",
"ampérea",
"amênico",
"amíngua",
"amódita",
"anaduva",
"anandro",
"anasala",
"ancudos",
"anervia",
"anforal",
"angiose",
"angitos",
"anojava",
"anomaza",
"antemos",
"antélix",
"anuloso",
"anáfalo",
"anóveas",
"apameus",
"apanhia",
"aparvar",
"apeanha",
"apedado",
"apedrou",
"apelamo",
"apenara",
"apenhou",
"apernar",
"apiabar",
"apirina",
"apneuse",
"apoitou",
"apontoa",
"apoídeo",
"aprovai",
"aptaram",
"aptarem",
"aptério",
"apótese",
"apótomo",
"aquedas",
"aquetem",
"arabina",
"aramava",
"aramudo",
"arancim",
"aranquã",
"arapene",
"ararapá",
"araréua",
"aravela",
"arcaras",
"arcário",
"arcânea",
"areamos",
"arearam",
"areísco",
"argolou",
"ariosas",
"arisaro",
"ariídeo",
"arneira",
"arrabel",
"arreara",
"arribai",
"arribem",
"arridar",
"arrobes",
"arruara",
"arrúdia",
"arrúgia",
"ascosos",
"asnares",
"aspredo",
"assamar",
"assedam",
"assedas",
"asseeis",
"assetes",
"assoeis",
"assonar",
"atabula",
"atacoar",
"atacoou",
"atardam",
"atastes",
"atavias",
"atempou",
"atermia",
"atloide",
"atoados",
"atopeta",
"atritas",
"atroara",
"atropas",
"atuarro",
"atuavas",
"atufada",
"atímica",
"aurense",
"autunal",
"auxilei",
"auxilem",
"averter",
"avindor",
"avivais",
"avoasse",
"avoavam",
"avoejar",
"axilose",
"azebres",
"azoados",
"azulear",
"azuleio",
"azuleje",
"azumara",
"azêmula",
"açacala",
"açoiaba",
"açucaro",
"bacelia",
"bacotas",
"bactros",
"badeixo",
"badiera",
"badorar",
"baforda",
"bagauri",
"baguear",
"baiacas",
"bailete",
"baldoar",
"baleque",
"balimos",
"balisse",
"baloada",
"bangalé",
"banivas",
"banzais",
"banzear",
"baquiti",
"baratai",
"barates",
"bastira",
"batanas",
"bataque",
"bataréu",
"beguava",
"beireis",
"bejagui",
"belfudo",
"beninos",
"benzóis",
"bererés",
"bernaca",
"berçudo",
"bibocal",
"bicotar",
"bifamos",
"bifaram",
"bigumes",
"binares",
"binasse",
"biscava",
"bisegre",
"bitesga",
"biófobo",
"bióforo",
"biógeno",
"bléfaro",
"bocaiús",
"bocarte",
"bodiano",
"bodosos",
"boizama",
"boizote",
"bolotal",
"bombiei",
"bombões",
"bondava",
"bonecra",
"bouchou",
"boçarda",
"bradina",
"braçona",
"bregmal",
"breviar",
"brinase",
"brivana",
"brulhos",
"bruxado",
"bruxais",
"brótula",
"bucuuva",
"bufarão",
"bundado",
"buracar",
"buramos",
"burlais",
"bursina",
"buvuari",
"buxuari",
"báculas",
"bájulos",
"báricas",
"bívoras",
"caaxira",
"cabodás",
"cabroca",
"cabrual",
"cacaios",
"caceteá",
"cachoam",
"cacical",
"cacifem",
"cacundê",
"cadavas",
"cadoira",
"caetetu",
"cafunje",
"cafétem",
"caicaís",
"caidiço",
"caitatu",
"calaque",
"caldado",
"calismo",
"cambacã",
"cambiem",
"camboar",
"camessa",
"camoati",
"camurus",
"canavês",
"canelou",
"capaxós",
"capebas",
"capemos",
"capiúna",
"caracha",
"caranás",
"carapós",
"caratiú",
"carazal",
"carenal",
"carmeia",
"carruca",
"carólia",
"cascato",
"caseias",
"casturi",
"catabil",
"catinas",
"catucão",
"caulina",
"cavilou",
"caxinxa",
"cazembe",
"caçoais",
"caçoiro",
"cccliii",
"ccclxii",
"ccclxiv",
"ccclxix",
"ccclxvi",
"ccclxxi",
"cccxcii",
"cccxciv",
"cccxcix",
"cccxcvi",
"cccxlii",
"cccxliv",
"cccxlix",
"cccxxiv",
"cccxxvi",
"cdxciii",
"cdxxiii",
"cdxxxii",
"cdxxxix",
"cdxxxvi",
"cebídeo",
"ceceado",
"ceceava",
"cedreno",
"cefalão",
"ceifões",
"celhado",
"celário",
"cementa",
"cenanto",
"cenrada",
"cermeto",
"ceroila",
"cerâmio",
"cetrina",
"chanura",
"chapeei",
"charlam",
"chavete",
"chelpas",
"cherico",
"chinear",
"chirria",
"chorfas",
"chorica",
"chãozão",
"cigerão",
"cilíolo",
"cincino",
"ciumoso",
"ciófilo",
"ciófito",
"ciótico",
"clipará",
"clipava",
"cobalta",
"cochamo",
"coirear",
"colacia",
"colguei",
"columim",
"colídio",
"comorem",
"compomo",
"comudes",
"conista",
"consoou",
"coproma",
"coradão",
"coreica",
"coricas",
"corixas",
"cornaço",
"corocha",
"corombó",
"correou",
"corripo",
"corroes",
"corteou",
"cosicar",
"cosmina",
"cotário",
"coucear",
"couchos",
"couvais",
"covendo",
"coxeias",
"cravela",
"craúnas",
"crebras",
"crejica",
"cresila",
"crespir",
"crilada",
"crispem",
"cruciou",
"cruzemo",
"cubilôs",
"cubramo",
"cuicuru",
"cuintau",
"cuiuira",
"cumburu",
"cunduru",
"cunhira",
"cunuaru",
"curimaí",
"curtata",
"cutaxós",
"cutiaia",
"cuviara",
"cáparis",
"cárdeos",
"cécegas",
"cêmbalo",
"cíprica",
"cônidas",
"dacites",
"danador",
"danarei",
"dardada",
"datadão",
"datante",
"datense",
"dcccxxv",
"dcclxix",
"dccviii",
"dcxliii",
"deaurar",
"debaque",
"debride",
"decapas",
"decimam",
"defluía",
"defumem",
"deganha",
"degoles",
"dejúrio",
"deluzir",
"dendros",
"denodou",
"denteis",
"derrogo",
"derruem",
"desbica",
"descava",
"desinça",
"desinço",
"deslodo",
"desoves",
"despelo",
"desriso",
"dessiso",
"dessoar",
"destará",
"desteça",
"destoco",
"desugar",
"desunes",
"desvaem",
"detorar",
"deutões",
"diabril",
"dianião",
"dicasta",
"dicazes",
"didução",
"dioense",
"diuense",
"dlxxvii",
"dlxxxii",
"dlxxxiv",
"dlxxxix",
"dlxxxvi",
"dobavam",
"dodorom",
"doestou",
"doidela",
"doudejo",
"doçando",
"drapeou",
"duetava",
"dxcviii",
"dxlviii",
"dêmicas",
"dídimos",
"dípsaco",
"díticos",
"dólicos",
"ecforia",
"echuabo",
"eclises",
"ecrises",
"edônios",
"efluxão",
"efluíam",
"efundem",
"efêndis",
"egitano",
"eguador",
"eiconal",
"eimaste",
"eiméria",
"eixados",
"elatora",
"eleates",
"eledina",
"elerões",
"eleóleo",
"eliciem",
"eliseia",
"elixira",
"emaciou",
"embosto",
"embraie",
"embrama",
"embudar",
"emiraém",
"empacas",
"empache",
"empapei",
"empapem",
"empasma",
"empeces",
"empunir",
"empuxam",
"emundar",
"enantal",
"encabou",
"encerta",
"enciumo",
"encênia",
"endouda",
"enevoei",
"enfarei",
"enfloro",
"enfrene",
"engavam",
"engodas",
"engoiar",
"engomas",
"engraza",
"enlutei",
"enluvam",
"enluvou",
"enolina",
"enrende",
"enrocou",
"enroxar",
"enruçar",
"enseira",
"ensorno",
"entanha",
"entapar",
"entomos",
"entufou",
"envides",
"enxalce",
"enxaras",
"enxeriu",
"enzoico",
"eníadas",
"enícola",
"eosinas",
"epigeus",
"epiloga",
"epizona",
"epítoga",
"epódico",
"epúlida",
"ermadas",
"erriçam",
"ervaram",
"esbirra",
"esbroar",
"esburga",
"escanes",
"escarce",
"escinas",
"escoico",
"escolme",
"escoque",
"escotel",
"esfazem",
"esfiava",
"esganai",
"esgaças",
"esmados",
"esmoles",
"esmolfo",
"esmurar",
"esmurou",
"esnocar",
"espadai",
"espalto",
"espatos",
"espeleu",
"espipou",
"estaiou",
"estamão",
"esteses",
"estilbe",
"estolem",
"estonou",
"estrame",
"estrigo",
"estruiu",
"esvazar",
"esvidar",
"etoxila",
"etática",
"eudoses",
"eupolia",
"euripos",
"eurites",
"exorada",
"exormia",
"expedes",
"fadarem",
"fadejar",
"faetons",
"fagulhe",
"faiadas",
"fajutou",
"famular",
"fancaia",
"fanicar",
"farante",
"farcino",
"fardara",
"fardete",
"farejai",
"farpeou",
"fatadas",
"fedença",
"fedonho",
"felupos",
"femeaça",
"fenasco",
"fendais",
"feneiro",
"festais",
"feófito",
"fiambra",
"fiantes",
"fiasses",
"filhara",
"filonar",
"fimbras",
"fletiam",
"florosa",
"flúmens",
"fobando",
"folecra",
"folhais",
"foliais",
"fonções",
"foreses",
"forrica",
"fracara",
"fradaço",
"franjal",
"frautar",
"freijós",
"freimão",
"fremida",
"frendia",
"frendor",
"froixel",
"frontem",
"frontou",
"frouxei",
"fruncho",
"frutava",
"frísico",
"fugição",
"fulecra",
"fumigue",
"funguem",
"fungues",
"fuscada",
"fuselas",
"fádicas",
"félidas",
"gabetes",
"gabiana",
"gademar",
"gaiatar",
"gaibeia",
"gaipapa",
"gaipapo",
"gaipara",
"galavam",
"galisia",
"galitas",
"galonar",
"gambota",
"ganoide",
"gargara",
"garimbo",
"garrião",
"gastemo",
"gatinês",
"gatoaçu",
"gaudina",
"gavarro",
"gedrita",
"genoesa",
"gibosas",
"gidiana",
"gimnoto",
"gimnuro",
"giraras",
"glomero",
"glotera",
"glotite",
"godério",
"golador",
"gomiada",
"gomável",
"gorarão",
"gorjais",
"gorjeei",
"gorrixo",
"gotejei",
"goétias",
"gradine",
"graxudo",
"grenado",
"grifara",
"grimaça",
"grongas",
"groçaís",
"grumará",
"grumaré",
"grápias",
"guajaru",
"guajuba",
"guavatã",
"gudunho",
"guembês",
"guenzos",
"gueriri",
"guleima",
"górdias",
"hainita",
"halalis",
"hastada",
"hasteai",
"helcose",
"heurema",
"hileana",
"hispada",
"homoide",
"honorou",
"hornfel",
"houbara",
"hálcion",
"héticas",
"héticos",
"húmiles",
"ialodês",
"ibaxama",
"ibixuma",
"icodidé",
"idoleto",
"ifrites",
"ilhavas",
"imageia",
"imanara",
"imantes",
"imbuais",
"imbuzal",
"imersam",
"imersou",
"imparas",
"imparia",
"improbe",
"inchamo",
"increpe",
"inexigi",
"injunge",
"insteis",
"insumem",
"intinos",
"intubou",
"inulase",
"inuptas",
"invecta",
"inzonas",
"inérveo",
"ioguins",
"ipíneos",
"iruexim",
"isofilo",
"isosmia",
"isódoma",
"isógona",
"itérico",
"ixóreas",
"iátrico",
"jacóbea",
"janaria",
"janasse",
"jantais",
"jarundo",
"jatemar",
"jaupoca",
"jejuada",
"jeneúna",
"jipoúba",
"juapoca",
"judeano",
"junônia",
"juçanas",
"lacrear",
"lagosto",
"lamaica",
"lambara",
"lamismo",
"lanceio",
"landuar",
"lanhara",
"lanital",
"lapílis",
"lardeia",
"lardeou",
"laregos",
"lascamo",
"latenta",
"laxando",
"lectica",
"leigaço",
"leitara",
"lemosim",
"lentava",
"lepoide",
"lepídio",
"leríeis",
"letrear",
"leucito",
"leveiro",
"libarem",
"lideiro",
"lidonas",
"liláceo",
"limaste",
"limnite",
"lincará",
"liqueno",
"listamo",
"litaram",
"lixarão",
"lixavas",
"lobosas",
"lobotos",
"lolista",
"lubrina",
"luciluz",
"lumiava",
"lupanga",
"lurares",
"lusácio",
"lusório",
"luxaram",
"luzecus",
"láctido",
"léticos",
"lêmnios",
"lígneos",
"lúcumos",
"macambo",
"macamãs",
"macetam",
"macumbo",
"macumãs",
"magrete",
"maipoca",
"maiuira",
"malferi",
"malquer",
"mamilho",
"manação",
"manduba",
"manduvá",
"maneges",
"mangras",
"mangrou",
"manguás",
"manhoco",
"maniata",
"mararem",
"maridal",
"marimãs",
"mariuôs",
"maroufa",
"marraco",
"marrins",
"marrote",
"marufle",
"massemo",
"massoca",
"massuas",
"matassa",
"matirão",
"matista",
"matombo",
"matruca",
"mazania",
"maçamos",
"mcmxxii",
"mechais",
"menelas",
"menipeu",
"mescais",
"messene",
"metição",
"metrada",
"milhear",
"milhoal",
"mimança",
"mimoseá",
"mioludo",
"miquear",
"miriqui",
"mirtais",
"mirtoso",
"mistros",
"mióporo",
"mobilem",
"mocador",
"mocadão",
"mocamau",
"mocanco",
"mogarim",
"moleado",
"molidão",
"monelha",
"monoílo",
"moqueia",
"mordais",
"moreota",
"morfine",
"mornava",
"morsolo",
"mostajo",
"motejem",
"motevos",
"motolos",
"mubambe",
"mudezes",
"mufumba",
"muginha",
"mujinha",
"muliado",
"mumbavo",
"mumbica",
"munamos",
"muquira",
"murasse",
"mureira",
"muruchi",
"muslemo",
"mutirum",
"mutucal",
"mutísia",
"muxique",
"muzundu",
"márcido",
"máteres",
"mãozudo",
"múrices",
"nacrite",
"nagares",
"narilão",
"nasardo",
"nauseei",
"nefélio",
"negraço",
"negrela",
"neuroso",
"nevroma",
"neófila",
"neógena",
"nhandiá",
"niiamas",
"nimbuia",
"nitriam",
"nitruro",
"nivosas",
"nonário",
"noutada",
"novedio",
"nucelos",
"násrida",
"nérvias",
"nêurico",
"níscaro",
"objetes",
"objurgo",
"oblatar",
"obongos",
"obrista",
"octorum",
"octânio",
"odrísia",
"ogdóada",
"oiropel",
"oitavam",
"oleares",
"oleáceo",
"ominado",
"onceira",
"ondando",
"ondeara",
"oniquia",
"onustas",
"oogenia",
"oomante",
"opereis",
"opiadas",
"opidana",
"opístio",
"orendas",
"orfaico",
"orissos",
"ornador",
"oromilá",
"orticon",
"ortigar",
"orçador",
"ouricei",
"ovampos",
"oxalilo",
"oxazóis",
"oxálide",
"oxídulo",
"oxígono",
"ozoniza",
"pachorô",
"pajurás",
"palares",
"paliata",
"palieis",
"paliúro",
"panriar",
"pantume",
"pantuna",
"papazes",
"papoque",
"parabus",
"parajus",
"pareres",
"pariatã",
"parúlia",
"pasítea",
"patados",
"pataréu",
"patejar",
"pautemo",
"paxilas",
"peações",
"pediais",
"pedicas",
"pedália",
"peganho",
"pegural",
"pelacho",
"pelitre",
"peloiro",
"pelores",
"penador",
"pendres",
"penedal",
"penisca",
"penácea",
"penáceo",
"perenga",
"perneei",
"persais",
"peruava",
"petilho",
"petique",
"pianice",
"piançar",
"pichori",
"picosas",
"picrito",
"pigarre",
"pilaras",
"pilasse",
"pilchei",
"pileado",
"pilheta",
"pilorda",
"pilrete",
"pingota",
"pintemo",
"piquiás",
"piracém",
"pirambé",
"piraoba",
"piratis",
"pirauás",
"pirgula",
"pixunxu",
"piônico",
"piúrias",
"placuna",
"plainem",
"plicara",
"ploimos",
"pocarem",
"pocavam",
"poetara",
"poiamos",
"pojadas",
"polacha",
"poldina",
"poldril",
"poliope",
"popúleo",
"potosis",
"pousemo",
"pracari",
"preasse",
"prechas",
"prefine",
"pregnar",
"preitar",
"preliou",
"premado",
"prenita",
"presaga",
"preseta",
"pretejo",
"preveis",
"proando",
"prosope",
"précias",
"prófago",
"psamito",
"pteroma",
"ptótico",
"pufismo",
"pupando",
"puparam",
"puparia",
"purumas",
"putirom",
"pécoras",
"píssica",
"púricos",
"quarteá",
"quatetê",
"quietez",
"quilelê",
"quimana",
"rabacué",
"rabanal",
"rabanes",
"rabilas",
"rabotar",
"rabulão",
"radicio",
"rafídeo",
"rageira",
"raizedo",
"ralarei",
"raleava",
"ralente",
"ramense",
"ranchel",
"rangada",
"rangava",
"rançada",
"rascote",
"rasgais",
"ravióis",
"rebalso",
"rebarbe",
"rebojou",
"rebramo",
"rebufos",
"rebuças",
"recalou",
"recapeá",
"recapte",
"recasem",
"recases",
"recedem",
"recluía",
"recudir",
"recudou",
"recuões",
"redamos",
"redenta",
"rederes",
"reenfie",
"reexpõe",
"refalam",
"refegas",
"reflori",
"refuljo",
"regatia",
"regesta",
"regreda",
"reixelo",
"relimar",
"reloque",
"remedam",
"remorro",
"remugir",
"renhiam",
"reninas",
"reoperá",
"reouçam",
"repagam",
"repisco",
"reptada",
"resedal",
"resquio",
"ressuou",
"restiro",
"retrama",
"revenas",
"revezas",
"revoejo",
"ribense",
"ricouço",
"ridinha",
"rimalho",
"ringiam",
"riríeis",
"rixador",
"rizanto",
"rocegas",
"rocioso",
"rodelos",
"roletam",
"romasse",
"romenho",
"rompemo",
"rondear",
"rondeou",
"rosasse",
"roseava",
"roussou",
"roçagou",
"rubinas",
"ruidava",
"ruãzona",
"róscido",
"sabacus",
"sabeias",
"sabogas",
"sacante",
"salazes",
"salenas",
"salsado",
"sanandu",
"sanaras",
"saputis",
"sarilha",
"sarroso",
"satirão",
"sebaste",
"sebitos",
"secadão",
"sectura",
"sedeará",
"segetal",
"seiches",
"seitoso",
"seixada",
"selavas",
"sembase",
"semones",
"senaíta",
"sepsias",
"sequelo",
"seringo",
"seroada",
"serrino",
"sesteio",
"setante",
"setosas",
"sevarem",
"sicorda",
"sienite",
"silabam",
"simbole",
"singras",
"sintrão",
"sinuada",
"siriaçu",
"sistilo",
"sitiais",
"sobraço",
"socares",
"sochiar",
"sofreio",
"soieira",
"sojigue",
"solaces",
"solecar",
"solêmia",
"sonoute",
"sopesas",
"sopitei",
"soraria",
"sornido",
"sorvemo",
"sorório",
"sotemos",
"sotopõe",
"sovines",
"soçocar",
"subpico",
"subsumo",
"sueiras",
"sujaras",
"sulaque",
"sulfina",
"sulfono",
"sumista",
"sungada",
"sungava",
"sunista",
"suprais",
"supurem",
"surdear",
"surriar",
"sustiam",
"sutaria",
"sélfica",
"síndese",
"sítaris",
"sóboles",
"sólitas",
"tachona",
"tacuité",
"tacuris",
"taiaçus",
"taimbés",
"taiobal",
"taiocas",
"tairoca",
"tajupar",
"talitre",
"talondo",
"taludão",
"tambaco",
"tanazar",
"tanchou",
"taraquá",
"tararas",
"tarumaí",
"tascará",
"tascina",
"tatalou",
"tatâmis",
"tauaris",
"taurins",
"tauteia",
"tavanês",
"taxiará",
"teiídeo",
"tejoila",
"telexar",
"telilha",
"temonar",
"tendemo",
"teredem",
"termíon",
"teroide",
"terçogo",
"tesados",
"testeia",
"tetados",
"tetaria",
"teômano",
"thulite",
"tiburos",
"ticante",
"tiflose",
"timbaba",
"timites",
"tiçoada",
"toitear",
"tolhiço",
"tongana",
"tonismo",
"tontões",
"toquemo",
"toreuma",
"torreio",
"tosaria",
"tracuás",
"tragano",
"trainas",
"trasdós",
"trateia",
"trefilo",
"treleio",
"tremava",
"triavam",
"tribufe",
"tricomo",
"tricosa",
"tricuro",
"triecia",
"trieres",
"trifilo",
"trilada",
"trilita",
"trioica",
"tripsia",
"triúris",
"troncou",
"tronear",
"tronejo",
"troneou",
"tropona",
"trotara",
"troteei",
"trotino",
"truania",
"trádita",
"tráquis",
"trípara",
"tubados",
"tufavam",
"tugidos",
"tuijuva",
"tundara",
"turavam",
"turcada",
"táureas",
"túrpido",
"uaicima",
"uaieira",
"uamirim",
"uberosa",
"ubucaba",
"ufanara",
"ulnário",
"umectam",
"unhasse",
"untarei",
"unóculo",
"urgevão",
"uribaco",
"urichoa",
"uritutu",
"ursadas",
"usurado",
"utadora",
"uvalhas",
"vacagem",
"vaganãs",
"vaiares",
"valamos",
"vandaus",
"vangião",
"vaporem",
"vapuaçu",
"variega",
"vedares",
"velelas",
"velózia",
"verdeai",
"verdeio",
"versudo",
"vezemos",
"viantes",
"vidaças",
"vinhaga",
"vinilas",
"visador",
"vispada",
"viuvado",
"vivavam",
"vivisco",
"vérmica",
"víncios",
"xaqueca",
"xaxarás",
"xenatos",
"xeretai",
"ximboré",
"xingamo",
"xipante",
"xocando",
"xântios",
"zagalas",
"zambros",
"zembras",
"zonchar",
"zopeiro",
"zoófobo",
"zumbrar",
"zuniada",
"zuraque",
"ácritos",
"ástatos",
"équidas",
"ênidros",
"êuclase",
"ínvidos",
"úmbrica",
"ababoni",
"abaciar",
"abacias",
"abadita",
"abagada",
"abaiúca",
"abambes",
"abardou",
"abariam",
"abativi",
"abceder",
"abdicai",
"abeatar",
"abfarad",
"abileno",
"abipone",
"ablutor",
"abobamo",
"abolara",
"aborras",
"abotina",
"aboubar",
"aboubas",
"aboízes",
"abrejou",
"abrevou",
"abscisa",
"absogra",
"abufelo",
"abético",
"acachoa",
"acagual",
"acampto",
"acanala",
"acapora",
"acarima",
"acastos",
"acedais",
"aceifei",
"aceiras",
"acenoso",
"acerdol",
"acetada",
"acetino",
"acharoe",
"achicam",
"achoute",
"acianas",
"acintam",
"acinzas",
"acirrei",
"aclimam",
"aclésia",
"aclísia",
"acnemia",
"acoalho",
"acocara",
"acoelha",
"acoiçar",
"acopulá",
"acorçoa",
"acosmia",
"acossei",
"acravou",
"acreceu",
"acrense",
"acretas",
"acromos",
"actinos",
"acuarão",
"acuasma",
"acuiuru",
"aculear",
"aculeia",
"acurará",
"acusmas",
"acutais",
"acácico",
"acárpio",
"acântor",
"acótilo",
"acúbito",
"adagias",
"adalbos",
"adamava",
"adeguem",
"adejada",
"adejará",
"adensai",
"adergou",
"aderimo",
"adermia",
"adeísmo",
"adiaras",
"adiarmo",
"adicara",
"adipati",
"adipoma",
"adjuram",
"adoadas",
"adoecei",
"adotemo",
"adrogar",
"adubale",
"adubeis",
"aducido",
"aduloso",
"adunara",
"adéques",
"afaneis",
"afanito",
"afariam",
"afatiar",
"afegava",
"afelear",
"aferros",
"afiaste",
"aficada",
"afinamo",
"afincas",
"afluída",
"afolada",
"aforrem",
"afoutam",
"afuzila",
"agariam",
"agarina",
"agastas",
"agastem",
"agirtas",
"agiteis",
"agostam",
"agredis",
"agreiro",
"agriana",
"agrumam",
"aguatal",
"agudada",
"aguista",
"agundus",
"aguçosa",
"agárica",
"airosia",
"ajeirar",
"ajorjar",
"ajudeei",
"ajuizai",
"ajulado",
"ajuízas",
"alacrai",
"alactar",
"alagoso",
"alalite",
"alamões",
"alanite",
"alaquem",
"alatoar",
"alavães",
"albense",
"albernó",
"alborco",
"alcaixa",
"alcaldo",
"alcaças",
"alceame",
"aldeaga",
"aleador",
"alearia",
"aleuria",
"alfaras",
"alfario",
"alfenes",
"alfurus",
"algazar",
"alginas",
"aliaras",
"aligada",
"aliguei",
"alijemo",
"alinhai",
"alitero",
"alizaba",
"aliósio",
"aljabão",
"aljafra",
"aljaroz",
"aljorce",
"almalha",
"almassa",
"almezar",
"almilha",
"almiças",
"almânia",
"alofilo",
"aloirei",
"aloitar",
"aloínas",
"alquiar",
"alquiem",
"alrotar",
"altamia",
"altanar",
"altoras",
"altosas",
"alugais",
"aluidor",
"alundum",
"aluniza",
"alustre",
"aluvias",
"alvaçuz",
"alvínea",
"alâmbar",
"alângio",
"alânico",
"alçarmo",
"amalham",
"amaloca",
"amanhes",
"amantam",
"amantou",
"amaremo",
"amarota",
"amaruja",
"amasisa",
"amavias",
"ambajos",
"ambrosa",
"ambúlia",
"ameleia",
"amenias",
"amenino",
"amerger",
"amesita",
"amicles",
"amicleu",
"amimias",
"amiosos",
"amirola",
"amiudei",
"amoirar",
"amolara",
"amolega",
"amoleis",
"amoquem",
"amossou",
"amoutar",
"ampulas",
"amuação",
"amureta",
"amíneas",
"anadéis",
"anafafe",
"anaguar",
"anauquá",
"anciona",
"ancílio",
"andejem",
"andiche",
"andóbia",
"aneando",
"anedral",
"anegrou",
"anerana",
"angiova",
"angites",
"angombe",
"anguada",
"aniilar",
"aninava",
"aniodol",
"anojosa",
"anomear",
"anotais",
"anovear",
"antamba",
"antante",
"antauge",
"antebém",
"antecos",
"antecéu",
"antedem",
"anteral",
"anturos",
"anumera",
"anáfise",
"anâmico",
"anãzado",
"anóbios",
"apacamã",
"apaleia",
"apanica",
"aparque",
"apateta",
"apealar",
"apepina",
"apetram",
"apetrei",
"apianço",
"apichou",
"apicida",
"apicite",
"apiloam",
"apinhas",
"apipado",
"aplúvio",
"apocina",
"apodias",
"apofoco",
"apolear",
"apoupou",
"apraças",
"aproara",
"aprosem",
"aptadas",
"aptéria",
"apócito",
"apófito",
"aquilhe",
"aradada",
"araniti",
"aranoso",
"arapopó",
"araquãs",
"aratauá",
"arauatu",
"araucas",
"aravari",
"arcaíza",
"arcózio",
"ardiano",
"arecale",
"arecina",
"areosos",
"arganas",
"arginas",
"arguais",
"arilada",
"aripene",
"armatão",
"armezim",
"arnosas",
"arnosos",
"arpejei",
"arpinos",
"arqueis",
"arraram",
"arratam",
"arraçou",
"arreale",
"arreate",
"arregra",
"arreigo",
"arrevém",
"arriota",
"arrizar",
"arrobem",
"arroxei",
"artomel",
"aríolos",
"ascaule",
"ascosas",
"ascunha",
"aspamos",
"aspirjo",
"aspídio",
"assedem",
"assedes",
"asselou",
"assoara",
"assolai",
"assolei",
"assêmio",
"astiana",
"astomia",
"atabala",
"atacino",
"atafego",
"atafina",
"atamais",
"atardou",
"atareis",
"atediar",
"atedias",
"ateimes",
"aterino",
"aterroe",
"atincal",
"atiplar",
"atitara",
"atiçoar",
"atombar",
"atopita",
"atouçar",
"atrapas",
"atrapei",
"atregue",
"atrepou",
"atrevei",
"atridos",
"atrigue",
"atríolo",
"atueira",
"atuosos",
"aturdas",
"atábulo",
"auaduri",
"auditas",
"augilas",
"aurados",
"aurinas",
"auritos",
"aurígia",
"aurônia",
"avacuei",
"avalita",
"avaloar",
"avaluou",
"avaries",
"avelane",
"avenado",
"aventes",
"avertas",
"avertem",
"avessei",
"avesses",
"avessou",
"aviaste",
"avinhou",
"avoação",
"avoeira",
"avozear",
"axópode",
"azcarah",
"azebuar",
"azimita",
"aziumou",
"azoeira",
"azonzar",
"azoques",
"azorita",
"azorite",
"azorrar",
"azotito",
"azurzir",
"açudado",
"babatou",
"babecos",
"babeiam",
"babujei",
"bacacuá",
"baciamo",
"bacupuá",
"badames",
"badanar",
"badines",
"bafejai",
"bagadus",
"bagarem",
"bagaçal",
"bagoado",
"bagueia",
"baiante",
"baicuri",
"baiucal",
"bajasse",
"bajogar",
"balache",
"balarem",
"baldoou",
"balissa",
"balsama",
"balsana",
"baludas",
"balulas",
"bandeje",
"bandemo",
"baniras",
"bantais",
"banzava",
"baratam",
"barbele",
"barchim",
"barocos",
"barquel",
"barroas",
"barruma",
"basimba",
"basteza",
"bateiro",
"batones",
"bauinia",
"baxtera",
"bebarro",
"bebeeru",
"bebidão",
"bebudas",
"bedelha",
"befênio",
"beijeis",
"beiçana",
"belazes",
"beldosa",
"belgate",
"beloiro",
"bemolar",
"benquis",
"bentais",
"benzeis",
"bernari",
"bertela",
"berzita",
"berçada",
"betarda",
"betonou",
"betumei",
"bezigue",
"beática",
"biafare",
"biaribu",
"biblino",
"bibocão",
"bicadão",
"bicarra",
"bichamo",
"bichava",
"bicilia",
"bicoque",
"bicudez",
"bidjogo",
"biflexo",
"bifário",
"bilante",
"bilrada",
"bimbava",
"binamos",
"binaram",
"bipavam",
"biralha",
"birremo",
"bisamos",
"bisante",
"bisavas",
"bismite",
"bispara",
"bisquei",
"bitalha",
"bitoiro",
"biupirá",
"biurana",
"bivacam",
"bizante",
"biófago",
"blênios",
"boanari",
"boardes",
"bobados",
"bobaste",
"bochava",
"bodanha",
"bogando",
"boganga",
"bogardo",
"boiacaá",
"boiense",
"boiunas",
"bolanas",
"bombaço",
"bonchis",
"bondada",
"bondeco",
"bonecam",
"bongues",
"bonçara",
"borbeto",
"bordeei",
"bordeie",
"borramo",
"botaras",
"botieus",
"boxeiam",
"bradeis",
"bragano",
"bramosa",
"bramota",
"brantos",
"brasais",
"brasona",
"bravava",
"bravear",
"breadas",
"brijara",
"briocas",
"britais",
"bromito",
"bronteu",
"broslam",
"brumada",
"brumaça",
"brunhir",
"brunias",
"brutelo",
"brômios",
"buchela",
"bucinos",
"bucurau",
"bulavas",
"buleira",
"bulhado",
"bulista",
"bumbado",
"bumbais",
"bumbava",
"bundara",
"bunhado",
"burizal",
"burloso",
"busanos",
"buseiro",
"butante",
"butarem",
"buticas",
"butucum",
"buxiqui",
"buágana",
"bácaris",
"bálneos",
"bálteos",
"báricos",
"búceros",
"búrsera",
"caapená",
"caataia",
"cabalem",
"cabanel",
"cabecem",
"cabedai",
"cabeçai",
"cabixis",
"cabreis",
"cabulem",
"cacajau",
"cachava",
"cachins",
"cactina",
"cadúsio",
"cafarro",
"cafelar",
"cafioto",
"cagafum",
"caicaco",
"caicurá",
"cainçar",
"caiongo",
"caiumba",
"caldais",
"caldeie",
"caloteá",
"caluros",
"calísia",
"calódio",
"camacus",
"cambiai",
"cambote",
"cambrão",
"cambudo",
"cambuto",
"caminaú",
"campida",
"campira",
"camuatá",
"camucis",
"camunzé",
"canamão",
"canango",
"cancarã",
"candear",
"candeei",
"candiar",
"canejas",
"caneles",
"canequi",
"cangolé",
"canguaí",
"canheta",
"caniana",
"canimos",
"canoula",
"cansemo",
"canteie",
"caparei",
"capares",
"caperom",
"capinem",
"caporro",
"caprona",
"captamo",
"capucos",
"carajaí",
"caranos",
"caraúno",
"carbure",
"cardeta",
"cardida",
"careias",
"cargues",
"caricie",
"carmano",
"carpite",
"carrile",
"cartava",
"carteta",
"carujar",
"casarca",
"cascosa",
"casinga",
"casqueá",
"castice",
"castila",
"casória",
"catagma",
"catambá",
"catauré",
"catavas",
"cateias",
"catombe",
"catonga",
"catrafa",
"catuena",
"catulos",
"catulés",
"caturos",
"cauloma",
"caxambá",
"caxandó",
"caçambe",
"caçante",
"ccclvii",
"cccxvii",
"cdlviii",
"cdlxiii",
"cdlxxii",
"cdlxxix",
"cdlxxvi",
"cdlxxxi",
"cdlxxxv",
"cdxliii",
"cdxxvii",
"cdxxxiv",
"ceariam",
"ceceiam",
"cecídia",
"cedrina",
"ceivada",
"celetos",
"cepáceo",
"cercona",
"cernada",
"ceromas",
"ceromel",
"cerzirá",
"cerídeo",
"cesuram",
"chagrim",
"chalara",
"champes",
"champil",
"chancar",
"chanelo",
"chapino",
"chaprão",
"charuta",
"chaudel",
"chemela",
"chiatar",
"chiatas",
"chicões",
"chifras",
"chifrem",
"chilido",
"chilrão",
"chimíer",
"chinite",
"chipaia",
"chocões",
"chofram",
"choremo",
"chorina",
"chousal",
"chousar",
"choutou",
"chufear",
"chupamo",
"chutamo",
"chúmeas",
"ciceras",
"cicínio",
"cilhada",
"cimarra",
"cimboas",
"cincado",
"cingalá",
"cinopse",
"cintais",
"circiar",
"cismais",
"cistres",
"cisória",
"citarra",
"citeras",
"ciânica",
"cladear",
"clismos",
"clivará",
"cmlxiii",
"cmxxxiv",
"cmxxxvi",
"coandus",
"coanhar",
"coaxixá",
"cobreja",
"cocoria",
"cocório",
"codeado",
"codemas",
"codetas",
"coevana",
"colarno",
"colatos",
"colegiá",
"colimbo",
"colomas",
"comatos",
"comboie",
"combuco",
"cominei",
"cominuí",
"comosas",
"compoer",
"compona",
"comptos",
"conairu",
"conairó",
"conambi",
"concute",
"concões",
"conduri",
"confute",
"conismo",
"consoem",
"conteco",
"copavam",
"copaúva",
"copilam",
"coprima",
"corango",
"coraras",
"coretus",
"corioma",
"corixão",
"cornipo",
"corotéu",
"corozil",
"corrume",
"corsear",
"corunas",
"corveje",
"coscoja",
"cotante",
"cotiada",
"cotizas",
"cotubas",
"coturna",
"coucelo",
"courona",
"coveado",
"covitas",
"coxeada",
"cracmum",
"craqueá",
"crasias",
"cravija",
"cremepa",
"crenato",
"creçudo",
"cricati",
"crioilo",
"crixaná",
"crizada",
"croissã",
"cromila",
"crueiro",
"crumena",
"crépida",
"críseos",
"cróceas",
"crômios",
"cubebas",
"cubique",
"cucarne",
"cueretu",
"cuipana",
"culepes",
"cultues",
"cuminol",
"cunabim",
"cuneato",
"cuprino",
"curetam",
"curetou",
"curiado",
"curuana",
"curuapé",
"curubas",
"curungo",
"curupiá",
"cuspada",
"custamo",
"cutilão",
"cádmium",
"cápsico",
"cárabos",
"cárbaso",
"câimbro",
"cérvulo",
"cínzeas",
"cíprias",
"cítreas",
"cótilas",
"côncano",
"côngios",
"dacitas",
"daimoso",
"danares",
"danavas",
"danismo",
"dasiúro",
"dccciii",
"dccclii",
"dcccliv",
"dccclix",
"dccclvi",
"dccclxi",
"dccclxv",
"dccclxx",
"dcccvii",
"dcccxci",
"dcccxcv",
"dcccxii",
"dcccxiv",
"dcccxix",
"dcccxli",
"dcccxlv",
"dcccxvi",
"dcccxxi",
"dcccxxx",
"dccliii",
"dcclvii",
"dcclxvi",
"dcclxxi",
"dcclxxx",
"dccxcii",
"dccxciv",
"dccxcix",
"dccxcvi",
"dccxiii",
"dccxlii",
"dccxlvi",
"dccxvii",
"dccxxii",
"dccxxiv",
"dccxxix",
"dccxxvi",
"dccxxxv",
"dclviii",
"dclxiii",
"dclxvii",
"dclxxiv",
"dclxxix",
"dclxxvi",
"dclxxxi",
"dclxxxv",
"dcxciii",
"dcxcvii",
"dcxlvii",
"dcxviii",
"dcxxiii",
"dcxxvii",
"dcxxxii",
"dcxxxiv",
"dcxxxix",
"dcxxxvi",
"debados",
"debagas",
"debarei",
"decanim",
"decanis",
"decimai",
"decimou",
"declivo",
"decroar",
"dedante",
"dedasse",
"dedemos",
"dedálea",
"degasar",
"delirai",
"delivra",
"deludiu",
"delusor",
"delusos",
"delíaco",
"demeias",
"demerso",
"demolis",
"dentama",
"dentona",
"denudes",
"denudou",
"deperdo",
"depiles",
"depravo",
"derisor",
"derrabe",
"derruía",
"desaira",
"desairo",
"desalmo",
"desapeá",
"desaram",
"desasas",
"descalo",
"descane",
"descare",
"desdida",
"deseixo",
"desfeou",
"desfrui",
"desgaso",
"desliou",
"desmoda",
"desmuni",
"despeno",
"desrizo",
"destema",
"desurdo",
"desviva",
"detoram",
"detraia",
"devisou",
"deícola",
"diariza",
"dibiose",
"dicadão",
"dicante",
"diclino",
"dicário",
"difenis",
"diferis",
"difrate",
"digeste",
"dignamo",
"dilombe",
"dilonga",
"dinódio",
"dipodia",
"discusa",
"dispais",
"dissido",
"disteno",
"distile",
"distilo",
"ditonga",
"ditável",
"diverja",
"divunga",
"dizimai",
"dizonho",
"diátoma",
"dobreza",
"donatal",
"dotarei",
"dotinho",
"doudelo",
"doudura",
"doçaram",
"draguei",
"draguem",
"drogamo",
"dublete",
"duetara",
"duftita",
"duíparo",
"dântico",
"dêutons",
"díglifo",
"ebanite",
"ebaniza",
"ecdemia",
"ecistas",
"ecnefia",
"ecoicas",
"edesseu",
"editamo",
"efireia",
"efórica",
"efórico",
"egineta",
"egirita",
"egiália",
"eiradas",
"eixista",
"elicias",
"eliciei",
"elisseu",
"elongou",
"eluírem",
"eluísse",
"eléboro",
"emaciam",
"emalada",
"emalara",
"emantem",
"embaces",
"embagar",
"embamba",
"embando",
"embasas",
"embeice",
"embioca",
"embobam",
"embobar",
"embobas",
"emboite",
"emborés",
"emeriom",
"emerjas",
"emoldam",
"emostar",
"empalmo",
"emparro",
"empecia",
"empisca",
"emproas",
"emproou",
"empulhe",
"enaipar",
"enaltar",
"enarrar",
"encamou",
"encarpo",
"encarva",
"encasca",
"encasou",
"enchuís",
"encimei",
"encocho",
"encofar",
"encouto",
"endiche",
"endinho",
"enduram",
"enenses",
"enesgar",
"enevoem",
"enfieis",
"enfixar",
"enfriem",
"enfuria",
"enféria",
"enganir",
"engarfa",
"engivas",
"engonce",
"engunha",
"eniálio",
"enjeriu",
"enjoosa",
"enlagar",
"enlapou",
"enlatei",
"enlodam",
"enlodei",
"enlouca",
"enogada",
"enrodou",
"enrolhe",
"enrufar",
"enrusti",
"ensaiai",
"enseiar",
"ensogar",
"entaloo",
"entanco",
"entapas",
"enteato",
"entecar",
"enteias",
"entejem",
"entesei",
"entesto",
"entinto",
"entique",
"entojei",
"entonto",
"envalar",
"envaram",
"envazou",
"enverar",
"enveses",
"envisco",
"enviçar",
"envoles",
"envulta",
"enxecar",
"enzampe",
"enérveo",
"enético",
"eocenos",
"eolídia",
"eozoico",
"epactal",
"epagogo",
"epinema",
"epirote",
"episemo",
"epistas",
"epiteta",
"epsilão",
"epânodo",
"epífane",
"epímera",
"epíscia",
"epíxilo",
"epódica",
"equidno",
"eretivo",
"erianto",
"ericale",
"erigimo",
"erineus",
"ermante",
"ermense",
"erriçou",
"erucato",
"eructas",
"ervecer",
"ervedos",
"esbilro",
"esbofou",
"esboiça",
"esbordo",
"esbuxar",
"escacou",
"escalfo",
"escamai",
"escamel",
"escirpo",
"escolie",
"escorie",
"escorio",
"escorvo",
"escumou",
"esfarei",
"esfarpa",
"esfatia",
"esfolai",
"esgarna",
"eslagam",
"eslúvio",
"esmadas",
"esmamos",
"esmeche",
"esmicha",
"esmicho",
"esmolei",
"espaçai",
"especai",
"especam",
"espicas",
"espilra",
"espinhe",
"espirai",
"espojei",
"esporai",
"espíneo",
"estasie",
"estasio",
"esterra",
"esterro",
"estevei",
"estilbo",
"estingo",
"estipas",
"estolam",
"estonce",
"estonte",
"estopei",
"estopão",
"estorie",
"estovar",
"estraba",
"estrugi",
"estrujo",
"estruís",
"estubes",
"estumam",
"esvazei",
"esvaímo",
"esópico",
"eternai",
"eteuete",
"etilado",
"euauaçu",
"eubages",
"eugeria",
"eugânea",
"euipnia",
"eupompo",
"euquimo",
"exarcos",
"excisou",
"exergos",
"exorado",
"exornou",
"exostro",
"exoteca",
"expedro",
"expetes",
"exsurgi",
"exsução",
"extubei",
"exódico",
"fabáceo",
"facetou",
"facites",
"fagando",
"faginas",
"faiarão",
"faladão",
"falhudo",
"faloide",
"falripa",
"falsava",
"fanaite",
"fanaste",
"fanavam",
"fanecão",
"fanisco",
"fanquis",
"faranta",
"farasse",
"fareias",
"farinam",
"farrada",
"farrona",
"fatecos",
"favares",
"façoila",
"febrosa",
"feiroto",
"feltres",
"felucho",
"femença",
"fenilas",
"ferezas",
"feriava",
"ferirmo",
"ferison",
"feroica",
"ferólia",
"fetáceo",
"fevroso",
"fiadões",
"fibrilo",
"ficáceo",
"fieital",
"figante",
"filarei",
"filarão",
"filetam",
"filetou",
"filádio",
"filípia",
"finadão",
"finamos",
"finarei",
"fintara",
"firmana",
"firulis",
"fisgues",
"fitáceo",
"flameia",
"flatoso",
"flautam",
"fletaço",
"floreis",
"florimo",
"fluíste",
"flâmeos",
"foceias",
"fofaram",
"foicear",
"foiçado",
"folacho",
"folecas",
"folgues",
"folgura",
"folheco",
"folhões",
"foqueio",
"foragia",
"forjoco",
"formóis",
"forneie",
"fortuns",
"fosques",
"fossada",
"foteado",
"fovente",
"frangiu",
"freeiro",
"fregada",
"fremais",
"frenava",
"fresava",
"frescum",
"fretene",
"friacho",
"friesta",
"frisais",
"fritido",
"fritilo",
"froncil",
"fronhos",
"fruteou",
"fruíste",
"fráguem",
"frágues",
"ftanito",
"fubeque",
"fugamos",
"fugarem",
"fugaste",
"fulgias",
"fulista",
"fumacei",
"fumarou",
"fumaçam",
"fumeará",
"furcate",
"furiais",
"fusques",
"fusório",
"futicou",
"futique",
"fuziles",
"fuçante",
"fuçarão",
"fárfara",
"fármaca",
"fásmido",
"férculo",
"fósgeno",
"fúlicas",
"gabaras",
"gadídea",
"gafadas",
"gafados",
"gafejar",
"gagueio",
"gaiados",
"gaianas",
"gaipelo",
"gaiveis",
"gajetas",
"galamos",
"galação",
"galdido",
"galeias",
"galenga",
"galhosa",
"galicar",
"galinhe",
"galonas",
"galreja",
"ganchem",
"gandura",
"ganires",
"garalha",
"garavim",
"garaúva",
"garfete",
"garguei",
"garinos",
"garnear",
"garotil",
"garrais",
"garraus",
"garremo",
"gasanol",
"gaseava",
"gatadas",
"gatarei",
"gatesco",
"gatesga",
"gatunou",
"gauchus",
"gavaram",
"gaviana",
"gaviete",
"gearmos",
"gedrite",
"gemante",
"gemidor",
"gemular",
"genaune",
"geradão",
"geranos",
"gestais",
"gilênia",
"ginguem",
"ginério",
"girento",
"gizaria",
"gizasse",
"gizinho",
"glaciou",
"glicila",
"glorial",
"glutino",
"glícide",
"gnômone",
"godiamo",
"goelado",
"goirana",
"goivete",
"golcori",
"goldres",
"golejar",
"golfará",
"golfins",
"golpara",
"golpeis",
"gomando",
"gomarem",
"gomarra",
"gominas",
"gondito",
"goníaca",
"goriana",
"gornope",
"gorrara",
"gorrião",
"gotinos",
"goéthia",
"gradura",
"gramara",
"granfos",
"granoso",
"granzal",
"grasnia",
"gravete",
"graçota",
"graúlho",
"gregueu",
"grifeis",
"gringal",
"gripará",
"grolada",
"grongar",
"grosmam",
"grudemo",
"grulham",
"grumado",
"grumixá",
"gruídeo",
"guacapi",
"guademã",
"guadimá",
"guaiado",
"guaicol",
"guaiuva",
"guajaná",
"gualdra",
"guarece",
"guarias",
"guataia",
"guebuçu",
"guelres",
"guengue",
"guiratã",
"guisará",
"gulaima",
"guropés",
"gurrião",
"guzunga",
"gábalos",
"haglura",
"halieto",
"haplito",
"hariola",
"harteei",
"hasteal",
"hastial",
"helícia",
"heptilo",
"hifenar",
"hiperão",
"hipoema",
"honoras",
"horarei",
"horavam",
"horemos",
"humanai",
"humente",
"hálices",
"hênetos",
"hírcico",
"ibiretê",
"ichimai",
"icoglãs",
"ictamol",
"ictíico",
"icários",
"ideante",
"ideares",
"idético",
"ieshiva",
"igaruçu",
"ignavas",
"ignitas",
"ignizam",
"iguaras",
"ileáceo",
"ilhalvo",
"ilharia",
"ilmênio",
"iluvias",
"ilíride",
"imbicam",
"imbrico",
"imerjam",
"imerjas",
"imistão",
"imizade",
"impedor",
"impemos",
"imperle",
"impetou",
"imponam",
"imponia",
"imprial",
"imundes",
"inabitá",
"inambuu",
"inambuí",
"incerne",
"increia",
"increpo",
"incubas",
"incubes",
"inercie",
"infixar",
"infleta",
"influas",
"inhaúba",
"inhengo",
"inioíta",
"insonio",
"instamo",
"insueta",
"inticou",
"intonar",
"intrata",
"intuías",
"invetes",
"invidio",
"inyoíta",
"iodosos",
"ipuruna",
"irassem",
"iriavam",
"irideca",
"iridiar",
"irritai",
"iráveis",
"irídico",
"irídios",
"isauras",
"islames",
"isolais",
"israéis",
"isíacas",
"isócolo",
"isócoro",
"isógama",
"isógeno",
"isógino",
"isópora",
"isótero",
"isômere",
"itamaca",
"itaocas",
"itapeuá",
"itapiri",
"iterara",
"itupeba",
"iuquicé",
"iuquiri",
"içarias",
"jactara",
"jaibros",
"jaleque",
"jamacaí",
"jamadar",
"jamboré",
"jampeia",
"jantela",
"japanim",
"japicaí",
"jarazal",
"jarlita",
"jatante",
"jataram",
"jatares",
"jatação",
"jatiuns",
"jazeres",
"jazeste",
"jejuíra",
"jenolim",
"jequito",
"jeraqui",
"jeremas",
"jeticas",
"jingimo",
"jipooca",
"jiquiró",
"jobares",
"jogralo",
"jomirim",
"jubatão",
"jubiles",
"juciris",
"judaízo",
"judeana",
"judique",
"jugarei",
"junquem",
"juquiás",
"juremei",
"jurguei",
"jurucuá",
"juruviá",
"jângais",
"labelas",
"labroso",
"lactada",
"ladreis",
"ladrina",
"ladriço",
"lagidas",
"lagides",
"laivada",
"lajiana",
"lambaro",
"lambita",
"lambuda",
"lambuje",
"lamiano",
"laminos",
"lampões",
"lanceto",
"lancine",
"lancino",
"landada",
"landumã",
"landuás",
"langite",
"lapijar",
"lapídea",
"laqueca",
"lareias",
"latinga",
"laudéis",
"laurilo",
"lavasco",
"lavegam",
"lazeiro",
"lazerem",
"lebroto",
"leiroto",
"leitava",
"leixeis",
"lemônia",
"lengada",
"leonite",
"lerdaça",
"lerdeio",
"leriaçu",
"lestara",
"lestros",
"letrara",
"letrava",
"leziras",
"liadura",
"libador",
"libasse",
"liceira",
"licinos",
"lidroso",
"lienite",
"liguras",
"limanos",
"limposa",
"limposo",
"linches",
"lindota",
"lineíte",
"lipídea",
"lisonjo",
"listrai",
"litadas",
"litante",
"litoras",
"litrite",
"lixaras",
"lixarei",
"lizares",
"lizaria",
"liçarol",
"lobarro",
"lobecão",
"lodaças",
"lodento",
"lofotes",
"logrona",
"lombais",
"lombear",
"loquial",
"loranto",
"lordaço",
"loricas",
"lotaras",
"lourado",
"loureja",
"lourejo",
"luchaze",
"lucidem",
"lugador",
"lugante",
"luimbes",
"luisina",
"lumiada",
"lunadas",
"lunanca",
"lunauta",
"lunguié",
"lustron",
"luvista",
"lâmures",
"lêmicos",
"lêmnias",
"lúbrigo",
"lúdrico",
"mabunda",
"macanda",
"macauês",
"macemba",
"maceras",
"maclado",
"macombo",
"macurus",
"madodas",
"madrija",
"mafiada",
"magelas",
"magonga",
"maiares",
"mainauá",
"maitatá",
"majubas",
"majubim",
"maleque",
"malinam",
"malsine",
"malsino",
"malucam",
"malumbo",
"malunda",
"malvela",
"mamaena",
"mamonos",
"mamujar",
"manadio",
"manamos",
"mancona",
"mandiús",
"mandrio",
"maneada",
"mangram",
"mangueá",
"mangula",
"manguxo",
"manimbé",
"manimos",
"manoseá",
"mansãos",
"mantara",
"maogani",
"mapilas",
"marachó",
"marajaú",
"maranos",
"margela",
"marimbu",
"maroneu",
"marrais",
"marrita",
"marroxo",
"marujei",
"marupaí",
"mascabo",
"massava",
"matacas",
"matinam",
"matondo",
"matonga",
"matrola",
"matulos",
"matundo",
"mavique",
"maxissa",
"maxixou",
"mazagrã",
"mazanas",
"mcmxiii",
"mcmxvii",
"mdcccli",
"mdccxxx",
"mdlxxii",
"mebunje",
"mecanal",
"mecheis",
"mechina",
"medimno",
"medinal",
"meduseu",
"meigosa",
"meldola",
"melense",
"melápio",
"meneias",
"meniano",
"mentóis",
"menálio",
"merluça",
"meroças",
"merques",
"merujes",
"mesarco",
"messeis",
"mestice",
"metexis",
"metilal",
"mexelhe",
"mexilho",
"mezungo",
"meônios",
"miadora",
"miarmos",
"micrito",
"micruro",
"micênia",
"miesita",
"mijicão",
"milhedo",
"miloide",
"mimalos",
"minanei",
"minaras",
"minchas",
"minimem",
"minorei",
"miocele",
"mioides",
"miringe",
"mirtale",
"mirídeo",
"missado",
"mitombo",
"mixanga",
"mizimos",
"miógono",
"mmxviii",
"mochado",
"mocozal",
"moesses",
"moestar",
"moestes",
"mofungo",
"moirejo",
"mojaras",
"mojicar",
"mojique",
"moletos",
"molhaça",
"molitos",
"molídeo",
"monices",
"monímia",
"morarmo",
"mordace",
"moroiço",
"morunas",
"mosquem",
"mostaço",
"mostela",
"motreco",
"moutedo",
"moutões",
"mouzaia",
"moxinga",
"mubanda",
"mucajal",
"mucando",
"mucumes",
"mucuoca",
"mucusas",
"mudadão",
"mugongo",
"muiunas",
"muldana",
"mulembo",
"muletim",
"mulondo",
"multani",
"mumbura",
"mundubi",
"munhata",
"munirão",
"munquém",
"mupicar",
"mupucar",
"murchez",
"muscoso",
"musgada",
"musques",
"mussina",
"mussuco",
"mutanas",
"mutelas",
"mutilão",
"mutirom",
"mutucão",
"muzambo",
"mádidos",
"máseres",
"médulos",
"mélicas",
"mérides",
"mênsula",
"mêntula",
"nadaras",
"nadense",
"nafteno",
"nafuquá",
"naiádeo",
"nambiju",
"nanavam",
"nanoled",
"nassada",
"naturei",
"naucuás",
"neblino",
"nebélia",
"negraça",
"nemésia",
"neoceno",
"nesgado",
"nesiota",
"netunal",
"neurina",
"neurito",
"nevraxe",
"nexinas",
"neógamo",
"nhambuu",
"nhanica",
"nhonhos",
"nidores",
"nigelas",
"nilíaco",
"nimbosa",
"ninquim",
"nitreta",
"nitrida",
"nitrose",
"nivação",
"niúngue",
"nobrece",
"nodical",
"nogadas",
"nonetos",
"noriano",
"notomia",
"novamos",
"novaras",
"novasse",
"novelei",
"nozados",
"nozelha",
"nubeles",
"nubilar",
"nugação",
"nuitões",
"náiadas",
"náufico",
"néctico",
"néfilas",
"níctago",
"obtunde",
"obumbro",
"obviais",
"ocnácea",
"ocrosia",
"octoica",
"odraria",
"ofensos",
"ofiúcos",
"ogervão",
"ogânico",
"olaeira",
"olearem",
"oleaste",
"oleicas",
"oleídeo",
"olhalva",
"olhento",
"olintos",
"oliveis",
"oloriza",
"olulana",
"ombreei",
"omófago",
"oncense",
"ondaria",
"onjimbi",
"oníscio",
"onívomo",
"oozoide",
"opugnam",
"orbília",
"oreando",
"oretana",
"orgevão",
"orijone",
"oritina",
"orjavão",
"orlador",
"orlante",
"orquido",
"orquita",
"oráveis",
"orófita",
"orófito",
"orótica",
"ossicos",
"ostrino",
"otítica",
"ouabaio",
"ourante",
"ourelar",
"ouripel",
"ourudos",
"ourégão",
"ouviela",
"oximéis",
"oxílito",
"oxônico",
"ozonize",
"pabulam",
"pacapiá",
"pacatus",
"pacites",
"pactuas",
"pagarmo",
"paginem",
"palejou",
"palense",
"palilho",
"palrada",
"panares",
"pandear",
"pangues",
"panqueu",
"paparão",
"papeais",
"papeeis",
"papujar",
"papíreo",
"paquino",
"paradeá",
"pardada",
"paricis",
"pariqui",
"parolam",
"parrais",
"parópio",
"passopa",
"patelha",
"patrata",
"patróis",
"paulara",
"pauteia",
"pauzama",
"pavejar",
"paxorôs",
"pazeais",
"pazeiam",
"pazeias",
"pealava",
"pebados",
"pectose",
"pedauca",
"pedinta",
"pedouro",
"pedrava",
"peguano",
"pegásea",
"pegáseo",
"peidoca",
"peineis",
"pejarás",
"pelaras",
"peleria",
"peltres",
"pelópio",
"pendora",
"penável",
"pequeio",
"percará",
"perfusa",
"pericio",
"perisca",
"perlons",
"perluís",
"pernaço",
"perneie",
"perneio",
"perneou",
"peroles",
"perotes",
"perxina",
"pesteia",
"pesunho",
"petares",
"petegar",
"petreco",
"petálio",
"pexorim",
"piaches",
"piardas",
"piaroas",
"piações",
"picaúro",
"picente",
"pierela",
"pifamos",
"pigarga",
"pilanos",
"pilarem",
"pilongo",
"pimpleu",
"pinalha",
"pinaras",
"pinaste",
"pinites",
"pinotam",
"pinotei",
"pináceo",
"pinções",
"pinéria",
"piocada",
"piojota",
"pionita",
"piorais",
"piorano",
"pipador",
"pipinou",
"pipiral",
"pipitou",
"pipuíra",
"piqueia",
"piracas",
"pirajus",
"pirange",
"piratou",
"pirauxi",
"pireras",
"piricão",
"piscamo",
"pisgues",
"pisteis",
"pitubas",
"pivotem",
"piéride",
"planjam",
"plaudem",
"plaudia",
"plebano",
"pleitam",
"plerose",
"plúvias",
"pocadas",
"pocaram",
"podagro",
"poduras",
"poisais",
"pojavam",
"polcava",
"polirão",
"polisto",
"pongará",
"ponçada",
"popanas",
"positão",
"possará",
"possear",
"posseia",
"potreio",
"poturus",
"pragará",
"praziam",
"predará",
"predera",
"prelibo",
"previvo",
"priscou",
"prismam",
"pristas",
"proaste",
"procelo",
"proejou",
"proemia",
"proseis",
"provica",
"proxema",
"pruriam",
"prínias",
"príones",
"prítiga",
"próceis",
"prómero",
"psicode",
"psiluro",
"psécade",
"psítaco",
"ptérico",
"pubando",
"pubarem",
"pugiles",
"pugilos",
"punceis",
"punceta",
"punctas",
"pungira",
"punçoar",
"punícea",
"puníeis",
"pupados",
"pápulos",
"párodos",
"pátrobo",
"páusias",
"pânfobo",
"pânteas",
"píctica",
"píricos",
"písceos",
"pítanas",
"quaiapá",
"quaraci",
"quariba",
"queijam",
"querudo",
"quetçal",
"quirato",
"quiteve",
"rabidar",
"rabotes",
"racamas",
"radameu",
"raiputo",
"raivudo",
"raiídeo",
"rajador",
"rajaria",
"rajavam",
"ralante",
"ralaras",
"ralheta",
"ramismo",
"ramoque",
"rampear",
"ramônia",
"ranales",
"rancear",
"rangeis",
"ranista",
"raparas",
"raposai",
"rapáceo",
"rareira",
"raremos",
"rascara",
"rascava",
"rascoas",
"raspite",
"rasquem",
"rasteou",
"ratinos",
"ravinar",
"realuga",
"reandar",
"reanexe",
"reatear",
"reateio",
"reatina",
"reatiça",
"reaviam",
"reavisa",
"rebebem",
"rebebia",
"rebibiu",
"rebicar",
"reboles",
"rebolia",
"rebomba",
"rebores",
"recacau",
"recambó",
"recenar",
"recenem",
"recepte",
"recerto",
"recheai",
"recheca",
"recluem",
"recocar",
"recoseu",
"recries",
"recucas",
"recudar",
"recudas",
"recumbe",
"redigam",
"redirei",
"redobem",
"redoras",
"reecoam",
"reecoar",
"reexpôs",
"refalou",
"refecem",
"refecho",
"referrá",
"refilmo",
"reflada",
"refocou",
"refreta",
"refusto",
"refutai",
"regateá",
"regelem",
"regeles",
"regesto",
"regidão",
"regines",
"reinata",
"rejecta",
"rejurar",
"relambe",
"releira",
"releiro",
"religas",
"relvedo",
"remalho",
"remelou",
"remense",
"remexas",
"reminar",
"reminha",
"remires",
"remoela",
"remolha",
"remoças",
"remudas",
"remugem",
"remuito",
"rendava",
"renilas",
"rentura",
"reobtém",
"repanho",
"repises",
"reprega",
"reptava",
"requiri",
"resbuna",
"resiliu",
"resinou",
"restive",
"resulho",
"retadão",
"retiana",
"retiano",
"retinge",
"retocai",
"retoiço",
"retorso",
"retovou",
"retraga",
"retrago",
"retreme",
"retrusa",
"retíolo",
"reumoso",
"reusará",
"reusava",
"revedes",
"revenha",
"revenho",
"revesio",
"revinha",
"revisai",
"reviçam",
"reviçou",
"reófilo",
"reótomo",
"riambas",
"ribando",
"ribésia",
"ricanho",
"ridelas",
"ridicam",
"rifarei",
"rilhada",
"rilhoto",
"rinanto",
"ripares",
"riscamo",
"riscote",
"ristilo",
"ritumba",
"rizagra",
"rizavam",
"roborem",
"rocedão",
"rochina",
"rociava",
"rocinal",
"rocinar",
"rocloró",
"rojadas",
"rojador",
"rojarem",
"romense",
"ronchar",
"rorante",
"rorejai",
"rostões",
"rotamos",
"rotaram",
"roupais",
"roxeava",
"roxeiam",
"roxuras",
"rubesce",
"rubigem",
"rucilho",
"rufasse",
"rufemos",
"rufista",
"rugando",
"ruginas",
"ruianas",
"ruidona",
"ruirias",
"ruirmos",
"rumante",
"rumbeei",
"rumbeou",
"ruquibo",
"russada",
"rustido",
"rábidas",
"râmbola",
"récipes",
"réspice",
"rúticas",
"rúticos",
"sabichã",
"sabujou",
"sacambu",
"saenses",
"safenos",
"saganho",
"sageira",
"saginar",
"saguaru",
"saguira",
"saibrão",
"saiense",
"saimeis",
"sainhos",
"sairara",
"sairaçu",
"sairuçu",
"saitauá",
"salagem",
"salaios",
"saltemo",
"saludem",
"saludou",
"saluçar",
"salveno",
"sambear",
"sambico",
"samborá",
"sambuio",
"sampava",
"sanares",
"sapadas",
"sapando",
"saparem",
"sapeado",
"sapindo",
"saponãs",
"sapuvão",
"sarcoso",
"sarrões",
"sativos",
"saxosos",
"searões",
"sebadas",
"sebeira",
"seciais",
"sedonho",
"sedores",
"segante",
"segeira",
"segueis",
"seitava",
"seiudas",
"seixosa",
"selagão",
"senceno",
"senoite",
"sepense",
"septoso",
"sepíola",
"sequiar",
"serreia",
"setoura",
"sevaras",
"sexaste",
"sicínio",
"signará",
"silense",
"silvais",
"sinadas",
"sinagro",
"sinamos",
"sinavam",
"singrai",
"sinrizo",
"sinuais",
"sipeira",
"sipibos",
"sipoúba",
"sirgado",
"sitaras",
"sizígio",
"soassar",
"sobraju",
"sobremo",
"sobrepé",
"sodagar",
"soeiras",
"sofenos",
"soforam",
"soidoso",
"solanca",
"solanco",
"solaste",
"solcris",
"soldões",
"solevou",
"solfado",
"solobro",
"solviam",
"solâneo",
"sonarem",
"sondaia",
"sonetei",
"sopapar",
"sopeara",
"sopeava",
"sorbita",
"sorimão",
"sortela",
"sortiam",
"sorveis",
"sotadeu",
"suangue",
"suardas",
"suastes",
"subface",
"sucatai",
"sucedei",
"suessiã",
"suessão",
"sufixai",
"sugilar",
"sulanas",
"suliota",
"sumarés",
"sumerso",
"sumosos",
"sundidé",
"suporás",
"suprias",
"surdeza",
"suécios",
"sáltria",
"sáuropo",
"sâmnios",
"sécteis",
"sélfico",
"séptula",
"séxviro",
"sêxtulo",
"sículas",
"sínofre",
"sórbias",
"súrculo",
"tacares",
"tacteie",
"tactura",
"tacurua",
"tadeias",
"taipado",
"tajaçus",
"talcita",
"talcosa",
"taletes",
"talhame",
"tamarus",
"tambetá",
"tambona",
"tanando",
"tanaria",
"tanchim",
"tanduju",
"tangões",
"taramos",
"taraxia",
"tarecaí",
"tarelar",
"tarolar",
"tarária",
"tasiano",
"tastear",
"tatalam",
"tatarés",
"taurite",
"tauxias",
"tauxiou",
"taxante",
"taxiada",
"taxácea",
"tealita",
"tecomas",
"tegúrio",
"teimice",
"telante",
"telexou",
"telhava",
"telhice",
"tendias",
"tenteno",
"tenídio",
"tepente",
"teraíra",
"terciar",
"ternais",
"ternara",
"terpena",
"terrola",
"tesamos",
"teslina",
"testais",
"testude",
"tetante",
"tetemos",
"tevente",
"teófago",
"tiamida",
"tiburte",
"ticador",
"ticarei",
"tigenol",
"tijupás",
"tilador",
"tildada",
"timbiro",
"timbuia",
"timenos",
"timicuí",
"tinhuma",
"tinisse",
"tisânia",
"titicam",
"titicuã",
"tituria",
"tobacas",
"tobeiro",
"togarem",
"toinhas",
"toireou",
"toiruno",
"tolinar",
"tolônio",
"tonarei",
"tonemos",
"topavas",
"tornete",
"torpeça",
"torquaz",
"torresã",
"torsais",
"totanos",
"toucara",
"touteia",
"tragina",
"traidão",
"trajais",
"trajeis",
"trameis",
"trapeia",
"traremo",
"traveja",
"treitos",
"trejuro",
"treneis",
"treplos",
"tresvas",
"trevado",
"trevite",
"triarem",
"triente",
"trifusa",
"trilara",
"triolés",
"triscas",
"trombes",
"trompão",
"tronais",
"troneja",
"tropada",
"trotina",
"trucava",
"trufais",
"truncas",
"trunfou",
"trágula",
"trímere",
"trípoda",
"trítios",
"tubança",
"tubação",
"tubáceo",
"tucaíra",
"tufosas",
"tugisse",
"tujucal",
"tujupar",
"tumefaz",
"tumenol",
"tumulas",
"tunamos",
"tundado",
"tunária",
"turiate",
"turingo",
"turucué",
"turvais",
"turário",
"tutorei",
"tutuque",
"tuxavas",
"tágueda",
"táquico",
"têutone",
"tórulos",
"túrnice",
"túrones",
"uabaína",
"uaiandi",
"uaruurá",
"ubapeba",
"ucamari",
"ufanear",
"ufômano",
"ugalhar",
"uleanto",
"ululara",
"umpeque",
"undeira",
"unguais",
"ungular",
"unhares",
"unitiza",
"untemos",
"unígamo",
"uníparo",
"uralina",
"urapará",
"urapuca",
"urchila",
"urdirão",
"urocele",
"urrarão",
"urreiro",
"uruense",
"urência",
"uróloga",
"usadões",
"usantes",
"ustrina",
"uvífero",
"uxórico",
"uxórios",
"vacares",
"vacaril",
"vacuoma",
"vadosos",
"vagador",
"vagueis",
"vaiaste",
"valagem",
"valiara",
"valseja",
"valseou",
"vandava",
"vaneceu",
"vaporou",
"vapular",
"vararão",
"varegas",
"vareias",
"vascoso",
"vauxite",
"vazieis",
"vedista",
"vedoras",
"veiaram",
"vejetes",
"velanis",
"velejai",
"velhori",
"velilho",
"ventril",
"ventusa",
"vereque",
"verrugo",
"vesicas",
"vesseis",
"vestará",
"vexaria",
"viageei",
"vicenal",
"viciais",
"vicinas",
"vidalas",
"vidamos",
"videada",
"videiam",
"vidonha",
"vigarão",
"vigasse",
"vigidas",
"vigonho",
"vijamos",
"viletas",
"vinhato",
"vinhoco",
"vipéreo",
"visagra",
"visarma",
"visoras",
"vitimei",
"vitinga",
"vivares",
"vividão",
"viçados",
"voagens",
"voareis",
"vocoide",
"vogadas",
"vogarão",
"volutes",
"volutou",
"volvado",
"volvele",
"volácio",
"volúsia",
"voorara",
"vozario",
"vurmoso",
"várdulo",
"várvico",
"vérmico",
"víperas",
"vômicas",
"wattado",
"xampanã",
"xaropou",
"xibalos",
"xilódia",
"xinando",
"xipefos",
"xipenes",
"xonaram",
"xrâmana",
"xumetós",
"xística",
"zambelô",
"zamboas",
"zanagra",
"zifídeo",
"zinindo",
"zipasse",
"zoiatro",
"zolismo",
"zolista",
"zorzais",
"zoáveis",
"zoética",
"zoógeno",
"zoólite",
"zoômano",
"zumbada",
"zunimos",
"zurvada",
"álticos",
"ástomos",
"áustrio",
"écranes",
"êxcetra",
"ídiches",
"íngrios",
"ínscios",
"ínstita",
"ababado",
"abacada",
"abaciou",
"abadalo",
"abadava",
"abadiem",
"abadiva",
"abaetem",
"abaetou",
"abafais",
"abaldes",
"abaleis",
"abaloam",
"abalofo",
"abalono",
"abaloso",
"abanais",
"abanamo",
"abaneis",
"abanemo",
"abanhei",
"abarate",
"abarias",
"abastor",
"abatais",
"abatate",
"abatino",
"abaçana",
"abcedar",
"abdalis",
"abderos",
"abeatas",
"abebras",
"abedale",
"abelano",
"abelgas",
"abelham",
"abelido",
"abemolo",
"aberras",
"abestim",
"abestou",
"abetino",
"abetuma",
"abifada",
"abiotos",
"abiques",
"abismes",
"abispas",
"abispei",
"abispou",
"abissas",
"abissim",
"abjurga",
"ablegam",
"ablegou",
"abluamo",
"abluíam",
"abnegas",
"aboboro",
"abocara",
"abocoçô",
"aboiara",
"abolada",
"abolces",
"aboleia",
"abolias",
"abolins",
"abolsas",
"abolses",
"abolças",
"abombas",
"abondem",
"aborres",
"aboscou",
"abotoas",
"aboucar",
"abradem",
"abradou",
"abrasoa",
"abrasor",
"abrejam",
"abreves",
"abrocar",
"abrocho",
"abrotas",
"abríeis",
"abscide",
"absomem",
"absorbo",
"abuanes",
"abufela",
"abugrar",
"abugres",
"abunhar",
"aburrar",
"aburrei",
"aburrem",
"abuscam",
"abuçado",
"acabela",
"acabemo",
"acacale",
"acacetá",
"acachei",
"acadada",
"acadara",
"acadima",
"acadiro",
"acafela",
"acaicos",
"acalaca",
"acalada",
"acalefa",
"acaloro",
"acalote",
"acandes",
"acangas",
"acanges",
"acanoou",
"acantoa",
"acarada",
"acareia",
"acareie",
"acarida",
"acarose",
"acarreá",
"acartas",
"acaçada",
"acaíras",
"acaírem",
"acefica",
"aceifas",
"aceimas",
"aceirei",
"aceirem",
"aceirou",
"acemias",
"acenamo",
"acenemo",
"acenosa",
"aceptam",
"acerará",
"acerato",
"acerbam",
"acerbou",
"acereja",
"acereje",
"acerias",
"acervam",
"acesono",
"acestor",
"acetava",
"acevada",
"achacas",
"achadio",
"achagas",
"achanes",
"achoada",
"achumbo",
"achusma",
"acidada",
"acidrou",
"aciesia",
"acigana",
"acinete",
"acintar",
"acintem",
"acintou",
"aciumar",
"aclares",
"aclareá",
"aclasto",
"aclivar",
"acmônia",
"acoanhe",
"acoarem",
"acochas",
"acochei",
"acolias",
"acolite",
"acoplas",
"acoposo",
"acoquei",
"acornou",
"acostos",
"acotiam",
"acotica",
"acotoou",
"acovada",
"acramas",
"acranto",
"acrecem",
"acrises",
"acrivar",
"acrives",
"acrocha",
"actuoso",
"actíaca",
"actídio",
"acuadão",
"acuante",
"acucula",
"acudais",
"acudamo",
"acudimo",
"acuemos",
"aculeou",
"acumina",
"acumine",
"acunhas",
"acunhem",
"acurrar",
"acurvei",
"acâmato",
"acérido",
"acólico",
"adagies",
"adajibe",
"adaucta",
"adelgam",
"adengar",
"adensas",
"adental",
"adentam",
"adentas",
"adentem",
"adepção",
"adergas",
"adiavas",
"adilara",
"adimpla",
"adionte",
"adiquei",
"adiscos",
"adiônio",
"adjazem",
"adjures",
"adjuves",
"adlecto",
"adligou",
"admonem",
"adnasal",
"adnotar",
"adoados",
"adoamos",
"adoaram",
"adoarem",
"adoidas",
"adortam",
"adoudar",
"adracne",
"adregas",
"adulais",
"adulita",
"adulona",
"adumbro",
"aduncar",
"aduramo",
"adverbo",
"advoque",
"adênico",
"aeremia",
"aerário",
"afagamo",
"afainam",
"afainou",
"afanais",
"afanias",
"afaxina",
"afeante",
"afeitei",
"afeleou",
"afemeio",
"afervar",
"afetais",
"aficava",
"afincoa",
"afincoo",
"afinfas",
"afitada",
"afitiva",
"afitivo",
"afiuzar",
"aflaram",
"aflatos",
"aflecho",
"afleima",
"aflemos",
"afofara",
"afogais",
"afolhou",
"afomear",
"aforais",
"aforias",
"aforras",
"aforrei",
"afoutas",
"afreima",
"afretam",
"afrigia",
"afrites",
"aftoide",
"aftosos",
"afumava",
"afumear",
"afunado",
"afunava",
"afundia",
"afusada",
"afusais",
"afágico",
"afático",
"afânico",
"afêmico",
"afírico",
"afítulo",
"afórico",
"agachai",
"agachis",
"agaitar",
"agalgou",
"agaloam",
"agantes",
"agarias",
"agastro",
"agazara",
"agazela",
"agaúcha",
"agaúcho",
"agemado",
"agendai",
"agerina",
"agestes",
"agiotam",
"agiotem",
"agirone",
"agradua",
"agrafem",
"agranar",
"agranas",
"agraras",
"agrasse",
"agraula",
"agrazes",
"agreada",
"agredam",
"agremie",
"agriota",
"agriote",
"agripno",
"agrumem",
"aguacha",
"aguarão",
"aguaçou",
"agudava",
"aguinir",
"aguçais",
"agássia",
"agáveas",
"agênias",
"agílima",
"aiardes",
"aiarmos",
"aiaçari",
"airiaçu",
"ajaezam",
"ajaezas",
"ajambre",
"ajarobá",
"ajeirou",
"ajereba",
"ajetiva",
"ajorjei",
"ajoujam",
"ajuagas",
"ajucará",
"ajúgeas",
"alacral",
"aladroa",
"alagens",
"alagoam",
"alagoou",
"alalita",
"alalito",
"alambro",
"alampar",
"alancas",
"alances",
"alanceá",
"alancou",
"alancão",
"alantos",
"alapoar",
"alaques",
"alariam",
"alarpar",
"alatine",
"alatino",
"alaudem",
"albarnó",
"albenas",
"albeque",
"albites",
"alboios",
"alcabol",
"alcafor",
"alcalas",
"alcenas",
"alcofor",
"alcouve",
"alcouço",
"alcáçam",
"aldegar",
"aldrope",
"aleires",
"aleluie",
"alemoou",
"alerdam",
"alestou",
"aleucia",
"alfaiou",
"alferga",
"alfirme",
"alforjo",
"algariá",
"algazes",
"algodoa",
"algosas",
"algosos",
"algueta",
"algúria",
"alianço",
"alibano",
"alicies",
"alienai",
"alindai",
"alindes",
"alipita",
"alisais",
"alisina",
"alisões",
"alixado",
"aljaves",
"aljofra",
"almarje",
"almaxar",
"alnaíbe",
"alocais",
"aloises",
"aloites",
"alombes",
"alorpam",
"alotado",
"alotava",
"aloucas",
"alouque",
"aloxina",
"aloxita",
"alozima",
"alpardo",
"alpinar",
"alpivre",
"alquiez",
"alsônia",
"altanem",
"alteína",
"altirna",
"altivou",
"aluarei",
"aluarem",
"aluavam",
"aludias",
"aludimo",
"aluiria",
"alumiei",
"alumies",
"alunito",
"alutara",
"alutero",
"alvacás",
"alvalar",
"alvaçar",
"alvelas",
"alvidar",
"alvidra",
"alvilha",
"alvorei",
"alxaima",
"alzaror",
"alálico",
"alândea",
"alânica",
"alçante",
"alçavas",
"alícula",
"alífera",
"alípilo",
"alóforo",
"alógios",
"amacaco",
"amacata",
"amadios",
"amagava",
"amagram",
"amainei",
"amalhou",
"amaltou",
"amamona",
"amanhia",
"amanses",
"amantei",
"amareço",
"amaripá",
"amateis",
"amauros",
"amazulo",
"ambones",
"amboína",
"ambrada",
"ambreie",
"ambrina",
"ambrone",
"amedeia",
"ameigas",
"ameigue",
"amenosa",
"amergeu",
"ametalo",
"ametino",
"amicose",
"amidona",
"amidosa",
"amidoso",
"amineus",
"aminora",
"aminose",
"amitado",
"amitina",
"amiídeo",
"amiúdem",
"amnesie",
"amnesio",
"amobata",
"amochai",
"amoedam",
"amoinar",
"amoires",
"amoitas",
"amoldas",
"amolego",
"amonais",
"amorava",
"amornai",
"amossas",
"amostou",
"amoucou",
"amoçava",
"amputai",
"amputes",
"amuaste",
"amulata",
"amumiou",
"amébido",
"amímico",
"amínguo",
"anacala",
"anacara",
"anadeno",
"anafara",
"anafava",
"anafear",
"anafeia",
"anagava",
"anagiro",
"anaguel",
"analdia",
"ananerá",
"anarico",
"anasalo",
"anatada",
"anatado",
"anatifo",
"ancados",
"ancheza",
"anciate",
"ancilai",
"anconas",
"ancília",
"ancônio",
"andacaá",
"andaima",
"andejei",
"andilha",
"andolas",
"andolos",
"anediam",
"aneguei",
"aneloso",
"aneuria",
"anfibos",
"anfiões",
"angalas",
"angicas",
"angomas",
"angreco",
"angreta",
"anguino",
"anguite",
"angules",
"anguzôs",
"anhimas",
"anianas",
"anicoré",
"anidada",
"anidais",
"anieras",
"aniilou",
"anilaçu",
"anilita",
"aninhai",
"anisato",
"anoesia",
"anojeis",
"anomeio",
"anotamo",
"anoutar",
"antaqui",
"anteado",
"antedar",
"antedei",
"antediz",
"antipos",
"antoide",
"antoros",
"antreno",
"anuirei",
"anulamo",
"anuleis",
"anulosa",
"anuvear",
"anuíres",
"anuíste",
"anvidos",
"anzinha",
"anzolas",
"anzolos",
"análabo",
"anápala",
"anãozão",
"anérveo",
"anódios",
"anóleno",
"anótino",
"apadroa",
"apaijar",
"apaiola",
"apaisar",
"apajava",
"apalado",
"apalaza",
"apalaís",
"apalmou",
"apancam",
"apandas",
"apandou",
"aparaiú",
"apareis",
"apatife",
"apaulas",
"apaules",
"apaulou",
"apazigo",
"apeares",
"apearmo",
"apearão",
"apeiria",
"apetava",
"apiadas",
"apiadem",
"apiadou",
"apichai",
"apichar",
"apilhou",
"apinaié",
"apintam",
"apintou",
"apinéis",
"apioide",
"apisina",
"apitamo",
"apiáceo",
"aplanei",
"aplomia",
"aplísio",
"apodais",
"apodamo",
"apodará",
"apodize",
"apodrem",
"apojada",
"apolega",
"apoleja",
"apondes",
"apormos",
"apossai",
"apossuo",
"apotrar",
"apraias",
"aprazas",
"aprazei",
"apreado",
"apremar",
"apreças",
"apseudo",
"aptados",
"aptamos",
"apteira",
"apuadas",
"apuados",
"apuavas",
"apulada",
"apulado",
"apunhou",
"apurais",
"apuramo",
"apáreon",
"apódida",
"apódoto",
"apútega",
"aquedou",
"aquelou",
"aquilou",
"aquivas",
"arabiza",
"aradado",
"araiaué",
"arangão",
"arapaju",
"arapuar",
"araranã",
"ararauá",
"ararixá",
"aratins",
"araturé",
"araviar",
"araxina",
"arcabém",
"arcavas",
"arcosos",
"arctado",
"arculho",
"ardingo",
"arduras",
"areaste",
"areavam",
"areavas",
"arfavas",
"arféria",
"argalha",
"argalho",
"arganiz",
"argeste",
"argomas",
"arimaru",
"ariosca",
"aripiar",
"arirama",
"aritauá",
"arjoada",
"arjunça",
"armenga",
"armilha",
"armorie",
"armósia",
"arnicar",
"arpador",
"arpados",
"arpando",
"arpante",
"arpasse",
"arpeado",
"arpeiam",
"arpento",
"arpoara",
"arrabes",
"arrafim",
"arraiei",
"arralar",
"arrarar",
"arraçal",
"arredoa",
"arredoe",
"arrefém",
"arreite",
"arrelha",
"arrelvo",
"arremos",
"arrenal",
"arrengo",
"arrento",
"arrerem",
"arretam",
"arreçal",
"arrifai",
"arrifar",
"arrigar",
"arrinco",
"arripar",
"arrizes",
"arriçam",
"arrióis",
"arrodeá",
"arrofos",
"arroiam",
"arroteá",
"arroupa",
"arrouço",
"arroxam",
"arruaço",
"arruele",
"arrufes",
"arruças",
"arruçou",
"artolar",
"aruacos",
"aruanás",
"arubeba",
"arvelas",
"aráceos",
"arádega",
"aráquis",
"asassem",
"ascaula",
"asclero",
"asiatas",
"asissem",
"asnadas",
"asnarem",
"asneiam",
"asneire",
"aspando",
"asparam",
"asparia",
"aspaste",
"asquizo",
"assapei",
"assapou",
"assavas",
"asselei",
"asseres",
"assetam",
"asseçoo",
"assisam",
"assisou",
"assocou",
"assonei",
"assonjo",
"assonso",
"assunam",
"assunar",
"assunga",
"assécio",
"astelma",
"astreva",
"astrola",
"asáfico",
"atabafe",
"atabafo",
"atabuar",
"atafera",
"atamano",
"atambia",
"atanada",
"atanava",
"atanazo",
"atarias",
"atassim",
"atauxia",
"atavolá",
"ataxite",
"atearas",
"atecada",
"atecuri",
"ateigar",
"atelhas",
"atelilê",
"atemora",
"atendai",
"atenram",
"atenues",
"atermal",
"atermas",
"ateruro",
"atestai",
"atextar",
"atibado",
"atibiar",
"atigrar",
"atigrou",
"atilhar",
"atilhas",
"atilhou",
"atimias",
"atinais",
"atirias",
"atiídeo",
"atoador",
"atoches",
"atochos",
"atoices",
"atoiçar",
"atolais",
"atomate",
"atontou",
"atoreis",
"atoucos",
"atracai",
"atramar",
"atrelai",
"atremou",
"atrepam",
"atrolar",
"atrolhe",
"atronou",
"atrusar",
"atréguo",
"atucane",
"atufara",
"atulhai",
"atulhos",
"atundir",
"atupida",
"aturari",
"atálamo",
"atérica",
"atérios",
"atínias",
"atócico",
"auacuri",
"aubrito",
"aucubas",
"audiana",
"auferis",
"aufiras",
"aufúgio",
"augurai",
"aulerco",
"aunadas",
"aunaram",
"auravam",
"aurimos",
"aurorou",
"aurosos",
"aurécia",
"austaga",
"autarco",
"auteiro",
"autoico",
"avangou",
"avanias",
"avarado",
"avelano",
"avences",
"averbas",
"avergas",
"avessam",
"avexeis",
"aviante",
"avidado",
"aviesse",
"avigoro",
"avilana",
"avilano",
"avilesa",
"avirias",
"avoaram",
"avoarem",
"avoaçam",
"avocamo",
"avondar",
"axandre",
"axandro",
"axantos",
"axinela",
"axoidea",
"axíngia",
"axófito",
"axótomo",
"azagres",
"azalina",
"azamboa",
"azarara",
"azarina",
"azebras",
"azedões",
"azentio",
"azerves",
"azigoto",
"aziúmes",
"azoadas",
"azoaram",
"azoinem",
"azorata",
"azorela",
"azoreta",
"azorres",
"azotoso",
"azougou",
"azuagos",
"azulara",
"azuleci",
"azumbra",
"azídico",
"azímico",
"açacaia",
"açaimam",
"açaimas",
"açapado",
"açodara",
"açorava",
"açorino",
"açoteou",
"açoutai",
"baandas",
"babatem",
"babeada",
"babeado",
"babordo",
"babujes",
"bacacus",
"bacalai",
"bacamas",
"bachope",
"bachões",
"bacinho",
"bacopas",
"bacoris",
"bacucus",
"bacuejo",
"bacálio",
"badales",
"badanau",
"baderno",
"bafadas",
"bafaram",
"baforem",
"baforão",
"bagaram",
"bagatas",
"bagocho",
"bagueis",
"baguiós",
"baiaram",
"baicuró",
"bainham",
"bainhem",
"baiotes",
"baiques",
"baitaco",
"bajanos",
"bajouja",
"bajulia",
"balafão",
"balbuti",
"baldais",
"balecar",
"balense",
"baleota",
"baloado",
"balobas",
"balouba",
"balouce",
"balsara",
"balsear",
"balsona",
"baludos",
"bambale",
"bamboas",
"bamboou",
"bambuns",
"bandoas",
"bandoou",
"banhema",
"banália",
"baqueti",
"baratem",
"baratou",
"barbião",
"barboto",
"barcote",
"bargela",
"barines",
"baroado",
"barramo",
"barreei",
"barreie",
"barremo",
"barroam",
"basango",
"basbana",
"basione",
"basteço",
"bastios",
"bastite",
"batacão",
"batador",
"batalou",
"batates",
"bataúda",
"bateiam",
"batiara",
"batilho",
"batolas",
"batucai",
"batácea",
"batíeis",
"baualas",
"baveira",
"bebicas",
"bebíeis",
"bedegar",
"beduins",
"begardo",
"beilhós",
"beiçote",
"bejoega",
"belenda",
"beleque",
"beletas",
"belfaça",
"belgita",
"belonas",
"beluoso",
"benairo",
"benfaze",
"benquer",
"benteca",
"beranas",
"beribás",
"berneia",
"berucha",
"bestigo",
"betarei",
"betarus",
"betoias",
"betonas",
"bexigam",
"bexuana",
"bezedor",
"beótica",
"bialada",
"biarcos",
"biastes",
"bibangu",
"bibicar",
"bibirus",
"bicante",
"bichaço",
"bicocos",
"bicunis",
"bicínio",
"bidogue",
"bifares",
"bifaste",
"biganau",
"bigerrã",
"bigotão",
"biguaço",
"biladas",
"biliada",
"biltras",
"binavam",
"bindele",
"bingava",
"binguem",
"biongos",
"biraias",
"biricas",
"biringa",
"biritam",
"biritou",
"birotas",
"birrudo",
"biseiro",
"biselou",
"bismela",
"bitadas",
"bitaras",
"biteiro",
"bitouro",
"bitrada",
"bitumar",
"bituvas",
"biófito",
"blasono",
"blocava",
"boastes",
"bobarem",
"bobeara",
"bobeies",
"bobeiou",
"bobinei",
"bobinão",
"bocamos",
"bocarei",
"bodalha",
"bodalho",
"bofante",
"bofeira",
"bofordo",
"bogarei",
"boiaste",
"boiaçus",
"boicelo",
"boiecos",
"boiotas",
"boiotes",
"boioçus",
"boiuçus",
"boizana",
"bojados",
"bojaram",
"bojavam",
"bojitos",
"bolaina",
"bolarda",
"bolatim",
"bolhais",
"bolhava",
"boloras",
"bombeja",
"bombies",
"bombolo",
"bonanço",
"bonecou",
"boneque",
"bongolo",
"bonotom",
"borasso",
"borbori",
"borista",
"borjeço",
"borquei",
"borrais",
"borrate",
"borrefa",
"borrefo",
"borreis",
"borrejo",
"borriça",
"bortalá",
"bosteje",
"botadia",
"botieia",
"botinos",
"botocou",
"botruco",
"botídeo",
"bouçais",
"boxando",
"boxeada",
"bozeira",
"boçalar",
"brabado",
"braceie",
"braceou",
"bracios",
"bramais",
"bramias",
"braminá",
"braseal",
"braseie",
"bravamo",
"bravosa",
"breador",
"breando",
"brecais",
"brechei",
"breveta",
"brevias",
"brevião",
"bribado",
"bridava",
"brioque",
"brisais",
"britará",
"britava",
"briteis",
"briácea",
"briáceo",
"broleis",
"brolham",
"bromeis",
"brongos",
"broquem",
"broqueá",
"brosila",
"broslou",
"brossam",
"brunham",
"brunira",
"bruxara",
"bróciga",
"brômide",
"bubento",
"bubuiou",
"bucares",
"bufarei",
"bufarra",
"bufaste",
"bufosos",
"bufônia",
"bugacho",
"bugiava",
"bujinga",
"bulamas",
"bularem",
"bulhara",
"bulhava",
"bulhona",
"bulidas",
"bulires",
"bulirão",
"bulídeo",
"bundeou",
"bungada",
"bunguei",
"bungues",
"bunolol",
"buracam",
"burgais",
"burilai",
"burilem",
"burleis",
"burmita",
"burnida",
"burnira",
"burrana",
"burucus",
"butadas",
"butador",
"butamos",
"buticos",
"butuque",
"butídeo",
"butíril",
"buxácea",
"buzegar",
"buzeiro",
"buzinai",
"buítres",
"báquios",
"bástulo",
"bânzara",
"béculas",
"bérnios",
"bíbulos",
"bíforos",
"bíjugos",
"bóstrix",
"caaingá",
"caanema",
"caaopiá",
"caapéua",
"cabalim",
"cabeará",
"cabedas",
"cabobos",
"caboila",
"cabouca",
"cabrara",
"cabrava",
"cabreei",
"cabrema",
"cabrite",
"cabuatã",
"cabumbo",
"cabundo",
"cabuíba",
"cacação",
"caceava",
"caceias",
"caceras",
"cacetei",
"cachoou",
"cachubo",
"cacicam",
"cacifei",
"cacocos",
"cacosas",
"cactale",
"caculou",
"cacório",
"cacúmen",
"cadamos",
"cadelar",
"cadeota",
"cadilha",
"cadimas",
"cadimes",
"cadinéu",
"cadoura",
"cafeona",
"cafeíno",
"cafifes",
"cafimas",
"cafiote",
"cafizes",
"cafofas",
"cafotos",
"cafucas",
"cafulos",
"cafunar",
"cafunge",
"cagoiço",
"caguatã",
"caguemo",
"cagunço",
"cagócio",
"caiapas",
"caiaria",
"caiarão",
"caiaxió",
"caibram",
"caidões",
"cainamé",
"caingai",
"caiorro",
"cairana",
"cajurim",
"cajurus",
"cajuuna",
"calajar",
"calasia",
"calcino",
"caldaça",
"caldeei",
"caldoça",
"calenos",
"caleras",
"caletes",
"calhais",
"calhoar",
"calhoas",
"calmões",
"caloses",
"caludas",
"calulas",
"calulus",
"calunas",
"calvina",
"calvura",
"calício",
"calônio",
"camarau",
"cambite",
"cambiço",
"cambocá",
"camurro",
"camúnio",
"canaica",
"cananas",
"cancanã",
"cancero",
"candeou",
"candola",
"canelei",
"canelim",
"canemas",
"canembo",
"canetem",
"cangues",
"cangões",
"canhava",
"canivão",
"canição",
"canocos",
"canoide",
"canoila",
"canoilo",
"canotão",
"cansona",
"canteou",
"canudas",
"canutio",
"canáceo",
"capante",
"caparas",
"capaças",
"capeará",
"capitéu",
"capixim",
"capsela",
"caqueia",
"caraaçu",
"carafás",
"caraiaí",
"carajés",
"carambó",
"caranço",
"carapuá",
"caratuã",
"carcave",
"carcerá",
"carcolé",
"carcéis",
"cardete",
"cardóis",
"cardões",
"carecei",
"careiem",
"carenou",
"cargava",
"cariará",
"carinão",
"carnara",
"carolim",
"carombó",
"carosse",
"caroupo",
"carpara",
"carpiar",
"carpias",
"carpimo",
"carrajó",
"carrava",
"carreei",
"carrejo",
"carrulo",
"carruço",
"carucaá",
"carunjo",
"carvalé",
"carveol",
"carácea",
"carícea",
"cascamo",
"cascana",
"caseiam",
"caseose",
"casolas",
"casotos",
"cassaba",
"cassica",
"catabis",
"catanam",
"cataste",
"cataxia",
"catimbe",
"catingo",
"cativai",
"catocos",
"catrofa",
"caturre",
"catário",
"cauauãs",
"caubila",
"cauline",
"causela",
"cavacou",
"cavacué",
"cavaque",
"cavaíba",
"cavidas",
"cavilam",
"cavilho",
"caxango",
"caxicos",
"caxuana",
"caxundé",
"ceassem",
"cebites",
"ceceada",
"cedrino",
"cedrita",
"cefísio",
"cegares",
"cegarmo",
"celação",
"celinas",
"celtiza",
"cendrar",
"cenemas",
"cenoses",
"cenosos",
"cenóbia",
"cepeira",
"ceradas",
"cerando",
"ceraria",
"cerdear",
"cerdões",
"cereira",
"cerniam",
"cernias",
"cerramo",
"cerreis",
"cerremo",
"certais",
"certava",
"cerumes",
"cerácio",
"cerésia",
"cetilha",
"cetínea",
"cetônia",
"cevares",
"cevaria",
"cevarão",
"cevasse",
"chacoli",
"chaeira",
"chaguer",
"chairel",
"chaires",
"chalava",
"chalaço",
"chaleis",
"chaliço",
"chalram",
"chancra",
"chaneco",
"chantai",
"chantam",
"chapais",
"chapode",
"chapote",
"chapoto",
"charela",
"charoam",
"charoar",
"chaçada",
"chaçado",
"cheadas",
"chechia",
"chedita",
"chefies",
"cheinas",
"cheirum",
"cherelo",
"chernas",
"chianha",
"chibamo",
"chibaço",
"chicaca",
"chicham",
"chichar",
"chichou",
"chidura",
"chilcas",
"chilida",
"chilres",
"chilrou",
"chimana",
"chiminé",
"chiolas",
"chiquel",
"chirina",
"chisnar",
"chitins",
"chocham",
"choches",
"chocosa",
"chofera",
"chofras",
"chofrem",
"choitar",
"choquel",
"chostra",
"choupes",
"choupou",
"chourém",
"chuanga",
"chuchei",
"chuchem",
"chuetar",
"chufeou",
"chuleou",
"chumaça",
"chupeis",
"chutais",
"chuvões",
"chuçado",
"cianado",
"cianela",
"cianoso",
"ciardes",
"cibando",
"ciciosa",
"cicônia",
"cidrões",
"cifemos",
"ciganou",
"cilenos",
"cilhais",
"cimbram",
"cimenos",
"cindias",
"cindila",
"cineias",
"cinisga",
"cinzada",
"cinzava",
"cinárea",
"cionite",
"ciperal",
"cipoíra",
"ciratas",
"circino",
"cirzeta",
"cisadas",
"cisados",
"cisalhe",
"cisaram",
"cisarão",
"ciselar",
"cisemos",
"cistoma",
"cisório",
"citrais",
"citérea",
"cizelar",
"ciáfilo",
"clareja",
"clarejo",
"clarkia",
"classar",
"clavais",
"clavava",
"cletras",
"clinuda",
"clivina",
"clocará",
"clotura",
"cmlviii",
"cmlxvii",
"cmlxxii",
"cmlxxiv",
"cmlxxix",
"cmlxxvi",
"cmlxxxi",
"cmlxxxv",
"cmxciii",
"cmxcvii",
"cmxliii",
"cmxlvii",
"cmxviii",
"cmxxiii",
"cmxxvii",
"cmxxxii",
"cmxxxix",
"cnidose",
"coagita",
"coagito",
"coalhei",
"coanhos",
"coaptou",
"coardes",
"coariam",
"coartes",
"coassem",
"coatada",
"coatras",
"cobremo",
"cobreou",
"cocegou",
"coconda",
"cocriei",
"cocriem",
"coculos",
"codejar",
"codejas",
"codeter",
"codilha",
"codizas",
"codizem",
"coedito",
"coendro",
"coerida",
"cofoses",
"cogedor",
"cogelou",
"cogerás",
"cogiado",
"coibais",
"coifada",
"coimada",
"coinado",
"coincha",
"coirona",
"coisico",
"coitiva",
"colator",
"colchoa",
"colegou",
"coleíte",
"colhais",
"colhemo",
"colhoal",
"colhuda",
"colijam",
"colimei",
"colmara",
"colmaço",
"colpada",
"coluiar",
"colurno",
"colário",
"colômia",
"colútea",
"combura",
"comedas",
"comilar",
"comines",
"comiram",
"comirei",
"comisca",
"comodem",
"comonia",
"comoram",
"comoras",
"comorim",
"comosos",
"compega",
"compono",
"comptas",
"comutai",
"comutas",
"concane",
"conches",
"conchor",
"concião",
"concrie",
"condiam",
"condias",
"condute",
"confluo",
"confugi",
"conotes",
"conques",
"constai",
"constes",
"conurbe",
"conviei",
"convimo",
"coobada",
"cooptes",
"copaque",
"coparei",
"copasse",
"copaços",
"copelha",
"copiemo",
"copudos",
"coquidá",
"coralim",
"coranas",
"corasmo",
"cordeia",
"cordela",
"coreara",
"coreida",
"coreixa",
"coristo",
"cormosa",
"cornale",
"corneja",
"cornina",
"coroamo",
"coroias",
"cororoá",
"correar",
"correie",
"corriol",
"corroló",
"corsaco",
"corsões",
"cortusa",
"corucha",
"corucho",
"corujai",
"corumbi",
"corvejo",
"corácea",
"coráceo",
"corêmio",
"coseras",
"cosicou",
"costino",
"cotaras",
"cotelão",
"cotetes",
"cotonam",
"cotonar",
"cotonia",
"cotoxós",
"cotrala",
"cotrino",
"coureia",
"coutais",
"coutava",
"covadas",
"coxeais",
"coxeará",
"coxilgo",
"coéforo",
"cracolé",
"craions",
"crambes",
"craniou",
"cranter",
"crastos",
"cravamo",
"craviri",
"crepins",
"crespor",
"crimpes",
"crinina",
"criptia",
"crisado",
"crisava",
"crismem",
"cromava",
"cruanha",
"crucito",
"cruente",
"crupina",
"cróceos",
"crócico",
"crócino",
"cuaruba",
"cuaxixá",
"cubaram",
"cubarem",
"cubaria",
"cubelas",
"cucadas",
"cucados",
"cucando",
"cucares",
"cueiras",
"cuietés",
"culapar",
"culhãos",
"culombó",
"culpemo",
"culumbi",
"cumbeca",
"cumelas",
"cumáceo",
"cunanãs",
"cunibos",
"cunques",
"cupaíba",
"cupaúba",
"cuquear",
"cuqueia",
"curabis",
"curadia",
"curiaca",
"curiavo",
"curinas",
"curiões",
"curotes",
"curtais",
"curtamo",
"curucas",
"curudiú",
"curumis",
"curuqui",
"cururuí",
"curvaça",
"curvejo",
"custais",
"cutelas",
"cutiana",
"cuvaiti",
"cuácuas",
"cálcido",
"cárdice",
"cântica",
"cércops",
"céricas",
"cóferdã",
"córdias",
"córnias",
"córreas",
"córsias",
"côncana",
"dabelos",
"dabécia",
"dafnite",
"daganha",
"dambirá",
"danaste",
"dancemo",
"danista",
"dealves",
"debacar",
"debanar",
"debante",
"debitai",
"debreei",
"debreio",
"decapam",
"decapes",
"deceino",
"decoado",
"decotei",
"decruam",
"decruas",
"decusse",
"decusso",
"dedamos",
"dedigne",
"deduras",
"defluam",
"degelei",
"degelem",
"delftia",
"delibas",
"delimos",
"deltoto",
"demeado",
"demoles",
"demovas",
"dendria",
"denguer",
"denodam",
"denodes",
"densais",
"denteia",
"denteou",
"denudas",
"denutri",
"departi",
"depores",
"deputem",
"dequite",
"derogou",
"derrego",
"derreio",
"desabai",
"desafez",
"desaino",
"desalme",
"desames",
"desamua",
"desanas",
"desarco",
"desarem",
"desareá",
"desasne",
"desasno",
"desauga",
"desbebê",
"descepa",
"descoma",
"descomi",
"descudo",
"desdado",
"desdava",
"desempo",
"desfaia",
"desfeie",
"desgase",
"desirmã",
"desisca",
"deslada",
"desmura",
"desnega",
"desneva",
"desnuca",
"desorna",
"desorne",
"desouça",
"despece",
"despeou",
"despole",
"despula",
"dessava",
"dessela",
"dessoas",
"dessuma",
"destela",
"destora",
"desusem",
"desvali",
"desveja",
"desverá",
"detence",
"detonai",
"detoras",
"detrame",
"detruso",
"deturbe",
"deturbo",
"develam",
"devenes",
"devenia",
"devermo",
"devesal",
"devirei",
"devisei",
"devulgo",
"diabões",
"diafone",
"dianela",
"dianema",
"diaspro",
"diatons",
"diazoma",
"diazota",
"diazóis",
"dicarem",
"dicaste",
"dicavam",
"dicline",
"difonia",
"digenia",
"digeris",
"diginia",
"digrido",
"digutis",
"digêneo",
"dijambo",
"diluvia",
"diluías",
"dimanai",
"dimanei",
"dimunho",
"dionina",
"diorese",
"dioxima",
"dipsose",
"diquele",
"dissaba",
"dissena",
"ditadão",
"ditemas",
"ditremo",
"dizamba",
"diálage",
"diátiro",
"diátomo",
"diótico",
"diúrias",
"djacutá",
"doarias",
"doasses",
"dobasse",
"dobemos",
"docidão",
"docista",
"doeiras",
"doestam",
"doirará",
"doleros",
"dolmita",
"doparão",
"dormeça",
"dorosos",
"dorsada",
"dorídeo",
"dosarão",
"dotaras",
"doudeja",
"doudete",
"doudona",
"doureis",
"doçares",
"doíamos",
"dracina",
"dragara",
"drainam",
"drapeio",
"drogais",
"drontes",
"dualize",
"dubador",
"dubieza",
"duchado",
"duetino",
"dulcadá",
"duplais",
"duplará",
"dureiro",
"durense",
"duritas",
"duriões",
"duríade",
"duziria",
"duzirão",
"duóbolo",
"dácrons",
"dâmares",
"désmico",
"díginas",
"dínames",
"dítonos",
"dóliman",
"dólmins",
"dórsulo",
"earcado",
"ebenale",
"eburina",
"ebâneas",
"ecoares",
"ecodemo",
"ecoicos",
"ecoxupé",
"ectimas",
"ectinos",
"ecólica",
"edeense",
"edentar",
"edentas",
"edilzão",
"eductos",
"edéssio",
"edética",
"edítimo",
"efemine",
"efronte",
"efundam",
"efébico",
"efética",
"efético",
"efípios",
"eguaria",
"egérias",
"egófilo",
"eirante",
"eireiro",
"eivavam",
"eivigar",
"eixaste",
"eixismo",
"eiçados",
"ejectos",
"elacina",
"elançam",
"eleemos",
"eleguás",
"elimeia",
"elitizo",
"elombre",
"elongue",
"eludirá",
"elutria",
"eluções",
"eluíram",
"elísias",
"emagram",
"emagrem",
"emagres",
"emanche",
"emarado",
"emarjar",
"emassai",
"emaçada",
"embacio",
"embagou",
"embambe",
"embanar",
"embardo",
"embauco",
"embaule",
"embaulá",
"embaças",
"embaíra",
"embeiço",
"embeque",
"emberne",
"embeste",
"embicas",
"embigos",
"embleme",
"emblemo",
"embobes",
"embocha",
"embojar",
"embosco",
"embrabo",
"embraio",
"embrame",
"embrete",
"embrico",
"embrido",
"embrute",
"embuaba",
"embuava",
"embudas",
"embutis",
"embuças",
"embólio",
"emechar",
"emenino",
"ementam",
"emolhei",
"emosque",
"emparda",
"emparei",
"emparou",
"empavoa",
"empeada",
"empedro",
"empegam",
"empegue",
"empeira",
"empelar",
"emperna",
"empesam",
"empesou",
"empetro",
"empezas",
"empiore",
"empiose",
"empique",
"empoces",
"empopar",
"empraze",
"empruma",
"empulho",
"empuxas",
"empuxes",
"emundai",
"enapupê",
"enastra",
"encasam",
"encauma",
"encaves",
"encedre",
"encenes",
"enceres",
"encerte",
"encimai",
"encimem",
"encimes",
"enciume",
"encobra",
"encobro",
"encofre",
"encolou",
"encomie",
"encorai",
"encoram",
"encorou",
"encovem",
"encruze",
"encubei",
"encunha",
"endeche",
"endejar",
"endumba",
"enduras",
"endurem",
"enfadai",
"enfaroo",
"enfarou",
"enfasou",
"enfebre",
"enfenar",
"enfeude",
"enfitei",
"enfitou",
"enflore",
"enfolhe",
"enfolho",
"enfriou",
"engajas",
"engalas",
"engalou",
"enganga",
"engaçou",
"engeada",
"enginas",
"engoiem",
"engoiou",
"engolam",
"engomes",
"engonhe",
"engrila",
"engripe",
"engrupa",
"enhader",
"enianes",
"enilema",
"enjicas",
"enjicou",
"enjoiar",
"enjugue",
"enlague",
"enlamei",
"enlaçai",
"enleiem",
"enlerda",
"enlevai",
"enleves",
"enlhear",
"enloira",
"enlojou",
"enlurar",
"enlutem",
"enodada",
"enoitam",
"enojamo",
"enojosa",
"enquita",
"enrabai",
"enramei",
"enrance",
"enregam",
"enregas",
"enresme",
"enresmo",
"enriado",
"enricem",
"enrijai",
"enrocam",
"enrolha",
"enroxou",
"enruçou",
"ensalça",
"ensanha",
"ensebes",
"ensecou",
"ensedar",
"enseiou",
"enseiva",
"enselva",
"ensilou",
"ensoada",
"ensocar",
"ensopes",
"ensujam",
"entalir",
"enteava",
"entecei",
"entecem",
"enteceu",
"enteiam",
"entejes",
"enteles",
"entelhe",
"entelho",
"entenal",
"enticam",
"enticou",
"entivas",
"entogar",
"entoial",
"entoira",
"entolha",
"entonei",
"entorpa",
"entouço",
"entrale",
"entrida",
"entufam",
"entupis",
"enublam",
"enubles",
"envarou",
"envazei",
"envazes",
"envidai",
"envigar",
"envisgá",
"enviúvo",
"enxeque",
"enxogou",
"enxotai",
"enzarel",
"enzinar",
"enzoose",
"enáguas",
"enófora",
"enóleos",
"enóplio",
"eolanto",
"eordeia",
"eordeus",
"epilada",
"epilais",
"epitaxe",
"epiteco",
"epíbolo",
"epíceas",
"epílobo",
"epímeno",
"epípola",
"equevas",
"erariam",
"erativo",
"erembos",
"eretriz",
"erigias",
"erijamo",
"eriteus",
"eriçará",
"ermaram",
"ermavam",
"erodira",
"erucina",
"eruídos",
"eráveis",
"erífera",
"erígona",
"eródios",
"erófilo",
"esbagoa",
"esbeiça",
"esbijar",
"esbofem",
"esbolar",
"esborda",
"esbroam",
"esbuxou",
"escacam",
"escacas",
"escadal",
"escalmo",
"escalva",
"escambe",
"escarda",
"escasca",
"escoais",
"escocar",
"escocas",
"escoime",
"escorda",
"escordo",
"escoseu",
"escâmeo",
"esfossa",
"esfries",
"esféria",
"esgacei",
"esgaiva",
"esgarra",
"esgavam",
"esgorje",
"esgotai",
"esguiei",
"esguita",
"esguite",
"esgúvio",
"esilato",
"esmaiam",
"esmaiei",
"esmaiou",
"esmania",
"esmavam",
"esmeias",
"esmiolo",
"esmocha",
"esmonda",
"esmoíam",
"esmoída",
"esopete",
"espadam",
"espanai",
"esparva",
"espetai",
"espoara",
"espojes",
"espulgo",
"esputou",
"espuída",
"espírea",
"espúmea",
"esquile",
"esquipo",
"esquixa",
"essivas",
"estacai",
"estafem",
"estalir",
"estamim",
"estazei",
"estazou",
"estearo",
"esticai",
"estigmo",
"estojas",
"estojes",
"estoles",
"estonei",
"estopem",
"estouvo",
"estreso",
"estries",
"estrija",
"estrile",
"estrime",
"estrimo",
"estruía",
"estréns",
"estucas",
"estugai",
"estólon",
"estômia",
"esurina",
"esvarou",
"esvurma",
"etilada",
"etiliza",
"etálico",
"etânica",
"eucaína",
"eucrita",
"eucrito",
"eucromo",
"eucômia",
"euedral",
"eugâneo",
"eulófia",
"eumatia",
"eumeros",
"eurreta",
"eutesia",
"evadamo",
"evariam",
"evasina",
"evirada",
"evódias",
"exaleis",
"exalças",
"exatora",
"exceles",
"exceleu",
"excelis",
"excisam",
"excusei",
"excusem",
"exerdem",
"exilamo",
"exileis",
"eximais",
"eximamo",
"exingue",
"exiteis",
"exoceto",
"exorara",
"expelam",
"expelis",
"expiais",
"expilem",
"expilou",
"expluía",
"expoles",
"expugne",
"expugno",
"expulam",
"exquisa",
"exsicou",
"exsolve",
"exstava",
"exulado",
"exâmito",
"exódica",
"exônima",
"fabismo",
"facataz",
"fachaca",
"fachoca",
"fachuda",
"factoto",
"faguice",
"faiaste",
"faichis",
"fainava",
"faitião",
"fajutam",
"fajutem",
"falacro",
"faladra",
"falagra",
"falhipo",
"falires",
"falmega",
"falocar",
"falques",
"falsada",
"faléria",
"famelgo",
"fanação",
"fangano",
"fanicou",
"fanique",
"fantins",
"faqueco",
"faradas",
"farados",
"farando",
"fararei",
"farauta",
"faravam",
"farazes",
"fardola",
"farelai",
"farelas",
"farpava",
"farpoam",
"farrape",
"farrara",
"farrava",
"farreis",
"farruma",
"farrupa",
"farsolo",
"fartuns",
"farádio",
"faseias",
"fastará",
"fatanar",
"fataria",
"fatejar",
"fatorai",
"fatorei",
"fauteza",
"favados",
"favante",
"favetas",
"favista",
"favonas",
"favosos",
"faxinai",
"feirara",
"feiumes",
"feixota",
"feixote",
"felados",
"felamos",
"felatas",
"felemas",
"felgudo",
"feloide",
"feloses",
"felposo",
"felsite",
"fenares",
"fenavam",
"fendeis",
"fenigma",
"fenigmo",
"fenilos",
"feníceo",
"ferremo",
"festeis",
"festoou",
"fiapico",
"fiatola",
"ficanço",
"ficácea",
"fidiana",
"fidiano",
"figadão",
"figarem",
"filaras",
"filaste",
"filaças",
"filhamo",
"filhare",
"filheis",
"filical",
"filinas",
"filipou",
"filírea",
"fimbrio",
"finador",
"finarão",
"fincais",
"finjais",
"firolas",
"fiseses",
"fitinas",
"flabele",
"flambem",
"flambes",
"flamões",
"flanará",
"flautou",
"flechem",
"flectes",
"flectis",
"fletirá",
"fleumão",
"flexuar",
"flimado",
"flipava",
"flirtei",
"flitava",
"flocule",
"florara",
"flotara",
"floucha",
"flébico",
"fléxeis",
"fobismo",
"fobitar",
"focaras",
"focáceo",
"fodrina",
"fogache",
"fogoiós",
"fogueou",
"fogueto",
"foiaíte",
"foiaíto",
"foiçava",
"folecar",
"folecha",
"folecro",
"folhepo",
"folhido",
"folilho",
"fonasco",
"fondiça",
"fonteca",
"foraria",
"fordeca",
"forecas",
"forjamo",
"forjeis",
"forjica",
"formole",
"formona",
"fornaço",
"forneja",
"forniam",
"fornira",
"forramo",
"forroia",
"forídea",
"foscara",
"fosqueá",
"fossais",
"fotadas",
"fotismo",
"foucear",
"foxinos",
"fracona",
"fradado",
"fragoro",
"frandil",
"frangam",
"frangis",
"frangue",
"franjes",
"frautes",
"frearei",
"frecham",
"frechem",
"freicha",
"freiram",
"frendas",
"frenela",
"fretamo",
"freteis",
"freteja",
"freteno",
"frevada",
"friasco",
"friezes",
"frigira",
"friosos",
"froixar",
"frolava",
"frondou",
"frouvas",
"frouxam",
"frouxem",
"frueira",
"frulhar",
"fruteia",
"fráguam",
"frágula",
"ftanite",
"ftálica",
"ftórico",
"fuampas",
"fubacas",
"fuceira",
"fucense",
"fucáceo",
"fugarei",
"fugavam",
"fuginte",
"fujicar",
"fulecou",
"fuliana",
"fuljais",
"fumarmo",
"funadas",
"funchão",
"fundeis",
"fundemo",
"fundias",
"fundujo",
"funçona",
"furacai",
"furacam",
"furegas",
"furrete",
"furriei",
"furunfe",
"fusadas",
"fusados",
"fuscado",
"fuscite",
"fusiloa",
"fusquem",
"futucas",
"fuçares",
"fuçarás",
"fáleras",
"fártens",
"fávicos",
"fênicos",
"fóridas",
"fúlvida",
"gabelos",
"gabordo",
"gadanhe",
"gaduína",
"gafeiro",
"gagueia",
"gaiorro",
"gaiubim",
"gajarem",
"gajeira",
"galaios",
"galandi",
"galania",
"galdras",
"galeava",
"galengo",
"galeína",
"galgamo",
"galhete",
"galhipo",
"galicam",
"galimos",
"galiões",
"galoada",
"galolos",
"galreio",
"galreou",
"galripo",
"galveta",
"gambinu",
"gamélia",
"gamélio",
"ganapão",
"gandule",
"ganhosa",
"ganites",
"ganizes",
"ganizou",
"gapeira",
"gapinar",
"gapuias",
"garapus",
"garauçá",
"garelas",
"garfara",
"garfeia",
"garfins",
"gargaru",
"garnado",
"garnava",
"garnela",
"garramo",
"garrara",
"garride",
"garriço",
"garulha",
"garunha",
"garçola",
"garçolo",
"garçona",
"gasalha",
"gascoas",
"gaseiem",
"gaspeia",
"gatavam",
"gateava",
"gaturda",
"gaturou",
"gaudada",
"gaudado",
"gaudiar",
"gavacho",
"gavanas",
"gaveira",
"gavemos",
"gaviama",
"gazanos",
"gazares",
"gazeara",
"gazeteá",
"gazolas",
"gazopas",
"gaúcham",
"gebadas",
"gebaras",
"gebarão",
"gebosos",
"gelanto",
"gelense",
"gemaras",
"gemeada",
"gemeias",
"geminai",
"geminei",
"geminem",
"genetia",
"genreia",
"geodina",
"gerecer",
"geridão",
"geriras",
"gerirei",
"gessete",
"gessoso",
"giberna",
"gigoias",
"gimnita",
"gincgos",
"gingrar",
"ginjais",
"giratas",
"giromas",
"girotos",
"girumba",
"gisnado",
"glaceis",
"gladiou",
"glaçara",
"glumado",
"glórica",
"glúcida",
"gnetale",
"gnátide",
"goajuru",
"goanesa",
"godalha",
"godemes",
"goderim",
"godorim",
"gogoias",
"gogosos",
"goiando",
"goiesco",
"golpado",
"gomador",
"gomação",
"gomedar",
"gomitei",
"gomoara",
"gomoses",
"gonadia",
"gongara",
"gonguro",
"gorgolo",
"goritas",
"gorjete",
"gorjilo",
"gornido",
"goteava",
"gotingo",
"gouânia",
"gracite",
"gradeei",
"gradeio",
"gralina",
"granava",
"graspas",
"grassai",
"gratule",
"grauçás",
"gravins",
"graxear",
"graúdes",
"gredosa",
"grelara",
"grevado",
"griledo",
"grilhas",
"grimpes",
"grogoió",
"gronhos",
"grotita",
"grotião",
"grudeis",
"grulhas",
"grumada",
"grumati",
"gruírem",
"grátulo",
"guacaré",
"guaiada",
"guaicás",
"guairás",
"gualdia",
"gualdis",
"guananá",
"guanhãs",
"guapuri",
"guaramo",
"guarite",
"guarnis",
"guarubu",
"guarula",
"guarupá",
"guberno",
"guendes",
"guenhas",
"guiacas",
"guiadão",
"guiamus",
"guinais",
"guindai",
"guindem",
"guineta",
"guinhou",
"guiunga",
"gulazar",
"guloses",
"gulosou",
"gumosos",
"gunchos",
"gurguês",
"gádidos",
"gájaras",
"gáticas",
"gáurico",
"gênitas",
"habadin",
"habenas",
"hagitas",
"halitar",
"haplomo",
"hararis",
"harlina",
"harmana",
"harpeou",
"hasside",
"haurias",
"hazazel",
"hecuste",
"heliões",
"heptodo",
"hepíalo",
"herejas",
"hernuto",
"heroizá",
"herôons",
"hesitai",
"hexanas",
"hexeres",
"hexitol",
"hexonas",
"hibleia",
"hibleus",
"hibride",
"hidrião",
"hilinas",
"himácio",
"hinodos",
"hipetro",
"hipnona",
"hipsilo",
"hipário",
"hircano",
"hirudos",
"hissopa",
"histoma",
"homeose",
"homezio",
"homéreo",
"honoram",
"horaras",
"hortada",
"hortais",
"houaris",
"houlita",
"hoódene",
"huainos",
"humadas",
"humlhar",
"huxléya",
"hádrion",
"hájibes",
"hálimos",
"háluces",
"háptero",
"héctigo",
"hélicas",
"hélions",
"hílicas",
"hílidas",
"hístrio",
"ibacaba",
"ibaibai",
"iberino",
"ibijaús",
"icacoré",
"icicaçu",
"iconize",
"icoroso",
"ictínia",
"idearem",
"ideasse",
"idiamim",
"idálias",
"idônica",
"iepense",
"igapeba",
"ignácia",
"iguados",
"iialaxé",
"ileosos",
"ileácea",
"ilicina",
"ilidiam",
"iliense",
"iliçada",
"iliçado",
"ilvaíta",
"ilvaíte",
"ilícico",
"imanamo",
"imbanda",
"imbuava",
"imbunde",
"imburis",
"imediam",
"imersar",
"imersei",
"imerses",
"imigrai",
"imissas",
"impacas",
"impados",
"impadão",
"impanar",
"impanes",
"imparão",
"impavam",
"impação",
"imperai",
"imperes",
"impotas",
"impunem",
"imundei",
"inalais",
"inanimo",
"incabal",
"inchemo",
"incidis",
"incinda",
"incisam",
"incisem",
"incitai",
"increde",
"incriso",
"incutis",
"induzis",
"indócis",
"ineixas",
"inerida",
"inervem",
"inerves",
"inesite",
"infamas",
"infinco",
"infinge",
"infinja",
"infixou",
"infleis",
"infleti",
"inflore",
"infunje",
"inganda",
"ingando",
"ingomes",
"ingriba",
"inguaiá",
"inguina",
"inhapas",
"inharés",
"inhenha",
"inibimo",
"inimitá",
"inimize",
"inimizo",
"iniídeo",
"injunçá",
"inorado",
"inorava",
"inosite",
"inquero",
"insolam",
"insolou",
"insumes",
"insumiu",
"intigos",
"intruje",
"inumamo",
"inumava",
"inuptos",
"invasai",
"invasam",
"invenal",
"invetas",
"invidar",
"invidem",
"invitei",
"invogal",
"inxerir",
"ináguas",
"inçaria",
"inçasse",
"inépica",
"inética",
"inódula",
"iodetes",
"iotizar",
"ipueras",
"ipuriná",
"iranche",
"irariam",
"irastes",
"iratins",
"iridina",
"irrupto",
"irídica",
"iserina",
"islenos",
"isoctil",
"isofoto",
"isolemo",
"isqueis",
"isócomo",
"isógamo",
"isópica",
"isótoma",
"isônico",
"iteques",
"ituense",
"ivaparé",
"ixuense",
"iápides",
"iódicas",
"jabeava",
"jabutia",
"jabutás",
"jacacal",
"jacaréu",
"jacatás",
"jactada",
"jactamo",
"jacurus",
"jaezado",
"jagrada",
"jaleias",
"jampeio",
"janadas",
"janambá",
"janelai",
"jantemo",
"japaras",
"japuçás",
"japígia",
"japônia",
"jardado",
"jarrafa",
"jarreou",
"jaspeia",
"jatador",
"jataria",
"jataúva",
"jateava",
"jatitou",
"jatobaí",
"jaupati",
"javense",
"javrado",
"jecuíba",
"jeitara",
"jemedar",
"jequedê",
"jerebas",
"jetaíba",
"jilabas",
"jimbuia",
"jingoto",
"jiraçal",
"jiritas",
"joelhes",
"jogatas",
"jojobas",
"joladas",
"jolemos",
"jolizes",
"jongada",
"jongava",
"jorgada",
"jorrais",
"jorrões",
"jubeias",
"jubilas",
"judieis",
"jugulas",
"jumanas",
"juncago",
"juncale",
"junguir",
"junjais",
"jupicaí",
"jupiede",
"juradão",
"juraico",
"juremou",
"jurgado",
"justará",
"justava",
"jáculos",
"jápides",
"jápiges",
"jáspico",
"jáziges",
"laberca",
"labitas",
"labreou",
"labutai",
"labuzei",
"labuzem",
"lacaiar",
"lacaços",
"laceiem",
"laceres",
"lacerto",
"laciais",
"lacramo",
"ladania",
"ladeará",
"ladeeis",
"ladeiem",
"ladroou",
"lafardo",
"lagamal",
"lagenas",
"laguéis",
"lagênia",
"laianos",
"laidrar",
"lajeiam",
"lajeola",
"lajeoso",
"lalante",
"lambefe",
"lambico",
"lambito",
"lambras",
"lamelou",
"lamiale",
"laminem",
"lampiar",
"lamutas",
"lanamos",
"landoás",
"landros",
"lanemos",
"langaas",
"langoia",
"languem",
"lanhaço",
"lanices",
"lapinga",
"lapista",
"lapouço",
"laqueta",
"laricha",
"laridão",
"larvejo",
"larécia",
"laspear",
"lassada",
"lassado",
"lasseou",
"lastrei",
"lastrão",
"latacha",
"latacho",
"lateada",
"latedor",
"latemos",
"latinou",
"lautite",
"laverco",
"lavorei",
"lavosos",
"laxadas",
"laxados",
"laxaram",
"lazaram",
"lazarão",
"lazerar",
"lealdou",
"leatina",
"leeácea",
"legador",
"legadão",
"legalhé",
"legalés",
"legaras",
"legreis",
"legremo",
"legumia",
"leigada",
"leirada",
"leirioa",
"leitais",
"leixais",
"lelégio",
"lembada",
"lemonal",
"lemural",
"lemário",
"lemúrio",
"lengais",
"lenhose",
"lenirem",
"lentear",
"leonuro",
"leptino",
"leptito",
"lequeça",
"lerdeei",
"lesares",
"lesavas",
"lesotos",
"levacos",
"leviado",
"levísia",
"lexiais",
"lhantas",
"liambas",
"liantes",
"liardes",
"libames",
"libaria",
"libecho",
"libelar",
"libnita",
"librame",
"liburno",
"licasta",
"licnuco",
"liconde",
"licênia",
"licênio",
"licídeo",
"lidmeia",
"lidosos",
"lienoso",
"lijonja",
"liliais",
"liliale",
"limoais",
"linalão",
"linchas",
"linfite",
"linfose",
"lingavá",
"lingone",
"linhóis",
"lináceo",
"liposes",
"liposos",
"liscada",
"liscado",
"lispros",
"lisíaco",
"litador",
"litarem",
"litarão",
"liteiro",
"lititos",
"litóbio",
"livedos",
"lixadão",
"lixante",
"lixarmo",
"lizasse",
"lizemos",
"liáveis",
"liópode",
"loangos",
"locante",
"locaras",
"logramo",
"loirada",
"loirado",
"loirais",
"loirear",
"loirejo",
"longais",
"lordaça",
"lorosas",
"lorotou",
"losanga",
"losanja",
"losinho",
"lourece",
"lourela",
"lualaba",
"lubenos",
"lucanga",
"luchans",
"luchava",
"lucidou",
"lucilem",
"lucilou",
"lucânia",
"ludiões",
"luengos",
"lufarão",
"lufária",
"luimbis",
"lumeiro",
"lundesa",
"lupadas",
"lupamba",
"luparas",
"luparia",
"luperco",
"luqueno",
"lurarem",
"luraria",
"luravam",
"lusmira",
"lusquir",
"lustrai",
"lusácia",
"lutagem",
"lutombo",
"lutosos",
"luxasse",
"luxurio",
"lábidos",
"lágaros",
"lárices",
"lásquia",
"lâmblia",
"lãzudas",
"léplica",
"lérneas",
"léticas",
"lênguas",
"lêucons",
"lícnide",
"língame",
"línicos",
"lípicos",
"líquias",
"lúculas",
"lúculos",
"mabelas",
"mabodes",
"macaios",
"macanga",
"macanho",
"macegão",
"macerai",
"maceres",
"machaco",
"macheie",
"macheou",
"machira",
"machoco",
"maciado",
"macobio",
"macocos",
"macouba",
"macuana",
"macubas",
"macujás",
"macutes",
"madersa",
"madismo",
"madjuba",
"madrace",
"mafulas",
"magadis",
"magarim",
"magiais",
"magiava",
"magodia",
"magorim",
"maiador",
"maimona",
"mainibu",
"maituca",
"majoral",
"majídeo",
"maladio",
"malaram",
"malavas",
"malebra",
"malecho",
"maleira",
"malemos",
"malesso",
"malevão",
"malgaxo",
"malilas",
"malocam",
"maloios",
"malpare",
"maltóis",
"malucua",
"malvale",
"malápia",
"malápio",
"maléola",
"mamelas",
"mamolas",
"mamosos",
"mamudes",
"mamulos",
"manaios",
"manarei",
"mancone",
"mandoro",
"manelos",
"manemas",
"mangama",
"mangaua",
"mangoro",
"manhuça",
"manilas",
"manimbu",
"maniste",
"manjuas",
"manteei",
"manteio",
"mantuca",
"mantões",
"manzari",
"manídeo",
"mapeias",
"mapiado",
"mapiava",
"mapichi",
"maquezo",
"maquira",
"maranis",
"marapuá",
"maratis",
"marceja",
"mareiem",
"marejas",
"marfara",
"marfava",
"margado",
"margota",
"marguis",
"maridam",
"marigué",
"marilho",
"marnete",
"marraxa",
"marroam",
"marroar",
"marroas",
"marrufo",
"marubas",
"marucas",
"marumbé",
"marvari",
"marúmia",
"mascais",
"massuca",
"mastuga",
"matabas",
"matambu",
"mateais",
"matemba",
"matilho",
"matinou",
"matoide",
"matonas",
"matíola",
"maulide",
"maunças",
"mausita",
"maxixal",
"maxixam",
"mazanga",
"maçares",
"mbacaiá",
"mcdxliv",
"mcmlxxi",
"mcmxcvi",
"mcmxxiv",
"mcmxxix",
"mcmxxxi",
"mcmxxxv",
"mdccciv",
"mdcccix",
"mdccclv",
"mdcccvi",
"mdcccxc",
"mdcccxi",
"mdcccxv",
"mdcciii",
"mdcclii",
"mdcclix",
"mdcclvi",
"mdcclxi",
"mdcclxv",
"mdccxlv",
"mdcviii",
"mdcxcvi",
"mdcxxxi",
"mdlviii",
"mdlxxix",
"mdxciii",
"mdxcvii",
"mdxxiii",
"mdxxxvi",
"meariam",
"mecalas",
"mediamo",
"medidão",
"mediões",
"medogos",
"megaerg",
"meidada",
"melagem",
"melarei",
"melares",
"melaste",
"meletas",
"melique",
"melombe",
"membura",
"memorei",
"mendete",
"meneais",
"mengita",
"menismo",
"mentalã",
"menteis",
"mepeque",
"mercais",
"merejam",
"mermava",
"merujas",
"merídia",
"mescada",
"messais",
"messara",
"messemo",
"mestais",
"mestemo",
"mestias",
"mesurou",
"mesápia",
"metamba",
"metasta",
"metecas",
"metilem",
"metilou",
"metinos",
"metáxia",
"meática",
"meático",
"miamina",
"mianada",
"miariam",
"miasses",
"michelo",
"mictado",
"migamos",
"migante",
"migaram",
"migarem",
"milabre",
"milfose",
"milhaça",
"mililux",
"militai",
"milombe",
"milvina",
"milênia",
"mimetos",
"mimídeo",
"mimóseo",
"mincção",
"mineias",
"mingada",
"miniais",
"minudas",
"minungo",
"minução",
"minuído",
"minuíra",
"miosuro",
"miqueou",
"miraitá",
"mirarmo",
"miraúba",
"mirrite",
"miruins",
"miscrar",
"missamo",
"missúri",
"misteco",
"mistria",
"misélia",
"misídeo",
"mitigai",
"mitotes",
"mixarei",
"mixarão",
"mixemos",
"mixilas",
"mixirra",
"miógena",
"miólogo",
"mobedes",
"mocanos",
"mocaíta",
"mochaco",
"modicam",
"modorno",
"modíola",
"moeiros",
"mofadão",
"mofante",
"mofaras",
"mofosos",
"mohsita",
"moiação",
"moirana",
"moitado",
"mojador",
"mojamos",
"molanas",
"molancã",
"molasso",
"molinai",
"moluene",
"molínia",
"momante",
"momaria",
"momeada",
"monanta",
"monaulo",
"moncais",
"monelho",
"monidas",
"monilha",
"monnina",
"monopso",
"monteco",
"monteei",
"monteou",
"mopanes",
"moquais",
"moqueou",
"moradio",
"moradão",
"moranes",
"morejam",
"moretos",
"morgeto",
"moringo",
"morique",
"morrace",
"morraco",
"morramo",
"morxama",
"moráceo",
"moscais",
"moscara",
"mosinas",
"mosseis",
"mossior",
"mosteia",
"motinai",
"motinou",
"moucado",
"mourera",
"mourizo",
"movongo",
"moxando",
"moxicão",
"moxubiá",
"moçamos",
"moçaria",
"moçarás",
"moçavam",
"muangas",
"muanhas",
"mubango",
"mucajás",
"mucanas",
"mucocos",
"mucrões",
"mucufas",
"mucufos",
"mucusso",
"mucuíba",
"mugeira",
"mugeiro",
"muimbos",
"muindos",
"muladei",
"mulalas",
"mulengo",
"multano",
"mulídeo",
"mumonha",
"munchis",
"mundacó",
"mundaús",
"mundiam",
"munduca",
"muneira",
"mungiam",
"munichi",
"munjolo",
"munques",
"munquir",
"munupiú",
"mupeque",
"muqueio",
"muradão",
"muragem",
"muralho",
"muranha",
"murares",
"murfela",
"murquir",
"murucus",
"murutis",
"murzela",
"murçado",
"murídea",
"musango",
"musanhi",
"musassa",
"musquem",
"mussale",
"mussita",
"mussuás",
"musáceo",
"mutanje",
"mutanos",
"mutista",
"mutunga",
"mututis",
"mututos",
"muxecas",
"muxicar",
"muxoxei",
"muzenze",
"muzombo",
"málacos",
"málagas",
"málicas",
"mássias",
"mátrios",
"méltons",
"mígalas",
"mírteas",
"móssega",
"múcrons",
"múridas",
"múrrino",
"mútilos",
"nagalhé",
"nagavam",
"nalgudo",
"namorai",
"namáqua",
"nanadas",
"nanador",
"nanante",
"nanaram",
"nanisco",
"napelos",
"narcina",
"narrais",
"narremo",
"nasalou",
"nasamão",
"nassari",
"navagem",
"naxiana",
"ndongos",
"nebrita",
"negruza",
"neineis",
"nemiano",
"nengras",
"neogeia",
"nepídeo",
"nerusos",
"nesgões",
"nessora",
"nestora",
"netapos",
"netúnia",
"neumado",
"neurino",
"nevamos",
"nevarei",
"nevação",
"nevrais",
"nevrino",
"neóloga",
"nguluve",
"nhangue",
"nhanida",
"nhenhas",
"nhurras",
"nicamos",
"nicante",
"nicanço",
"nicasse",
"nicense",
"nidoras",
"nilgais",
"ninhara",
"nisorro",
"nitônio",
"nivelai",
"nivéola",
"nobreça",
"nocadas",
"nocaria",
"nocebos",
"nocilho",
"nodemos",
"noiveis",
"nomeemo",
"nonatas",
"norrena",
"norteai",
"nosaria",
"nosemas",
"nosites",
"noteira",
"novadio",
"novaram",
"novelou",
"novenos",
"novidão",
"nuançou",
"nublamo",
"nugaria",
"numeíta",
"nunciei",
"nuquear",
"nursina",
"nutando",
"nutrais",
"náfegas",
"náfegos",
"nátices",
"nébeles",
"nébrida",
"néctria",
"nérveos",
"nérvulo",
"néstico",
"nêmatos",
"nêmetes",
"nêspara",
"nêspero",
"nínfico",
"núcegos",
"oapinas",
"obcecas",
"oberada",
"obfirmo",
"oblatou",
"obradar",
"obsidie",
"obtecto",
"obtedor",
"obtesto",
"obtidão",
"obtundo",
"obturas",
"obusite",
"obélias",
"obóveos",
"ocaleia",
"oceânea",
"ocisões",
"ocluais",
"ocluirá",
"ocreoso",
"ocroíte",
"octodos",
"oculada",
"ocursar",
"ocíduas",
"ocúleos",
"oddites",
"odônimo",
"ofendei",
"ofitina",
"ofitoso",
"ogueiro",
"ogígico",
"oigamos",
"oiranas",
"oirasse",
"oiriçam",
"oirotes",
"oitavei",
"ojerize",
"oleavam",
"oleonas",
"olerosa",
"oleroso",
"olivila",
"olmedos",
"oloniti",
"olorada",
"oláceas",
"olíntio",
"olívida",
"ombrino",
"omitemo",
"omuenhe",
"omóforo",
"ondaste",
"ondeiem",
"onerais",
"oneramo",
"onesite",
"onganda",
"ongonga",
"onocola",
"onotera",
"onzenou",
"oníparo",
"ooângio",
"opadora",
"opariam",
"operamo",
"opiasse",
"opidano",
"opilada",
"optador",
"opática",
"opósita",
"oralize",
"orbilha",
"orbínia",
"orcelas",
"orelhai",
"orelham",
"orelhou",
"orfeica",
"orgiada",
"orictos",
"orlaram",
"orlarem",
"orletas",
"ornavas",
"orneira",
"ornejou",
"ornitos",
"oroides",
"oroneta",
"orvetos",
"oríbata",
"orízeas",
"oróbios",
"orófila",
"orófilo",
"osfiite",
"osfrese",
"osmatos",
"osmonda",
"osseano",
"osseias",
"ossimas",
"ossuoso",
"ostagas",
"ostagra",
"ostante",
"osteíde",
"otimate",
"otávias",
"otólogo",
"ougados",
"ougares",
"ouramos",
"ouraria",
"ourejar",
"outante",
"outivas",
"outonas",
"ovalzão",
"ovarias",
"ovículo",
"ovífero",
"oxambim",
"oxamite",
"oxeóleo",
"oxidina",
"oxilite",
"oxissol",
"oxígala",
"oxônios",
"ozenoso",
"ozonado",
"ozonais",
"oófagas",
"oóforos",
"pachole",
"pactara",
"pacteou",
"pacuris",
"padecei",
"padixás",
"pagiças",
"painças",
"paleada",
"paleais",
"paleias",
"palejam",
"palhice",
"palhito",
"palhona",
"palmale",
"palmato",
"palmura",
"palpamo",
"paléola",
"panacus",
"panaram",
"panarmo",
"panaços",
"pancões",
"pandada",
"pandemo",
"pandeou",
"panemos",
"pangada",
"pangado",
"pangire",
"panotas",
"panrias",
"pantais",
"panteus",
"pantuns",
"panurga",
"paparas",
"paparei",
"papeies",
"papejar",
"papoiço",
"paposas",
"papoulo",
"papuses",
"paquina",
"paranji",
"paranto",
"paraprá",
"pararus",
"parauaí",
"paravar",
"pardado",
"pardece",
"pardeja",
"pardejo",
"pardesa",
"pareios",
"parergo",
"paridão",
"parirus",
"paristo",
"parlado",
"parmano",
"parmena",
"parrone",
"parsina",
"partura",
"parture",
"parídeo",
"pascemo",
"pasmamo",
"pasmeis",
"passacu",
"passota",
"patamas",
"patanas",
"patauás",
"patecos",
"pategar",
"pategas",
"pateias",
"patejam",
"patença",
"patetei",
"patiada",
"patinhe",
"patocho",
"patolei",
"patuguá",
"paulais",
"pauteou",
"pavesam",
"pavesas",
"pavoneá",
"pavunva",
"pazeado",
"pazenda",
"paziguá",
"pearmos",
"pebadas",
"pechava",
"pedalvo",
"pedanos",
"pedival",
"pedoiro",
"pedrara",
"pedreis",
"peganha",
"peguana",
"peguial",
"peidamo",
"peinada",
"peitais",
"pejador",
"pejante",
"pejarem",
"pejaria",
"pejasse",
"pejeiro",
"pejores",
"pejorou",
"pejosos",
"pelarão",
"pelaste",
"peleais",
"peleiem",
"pelemos",
"pelense",
"pelonas",
"pelotro",
"peltato",
"penache",
"penambi",
"pendamo",
"pendoam",
"penujes",
"pepasmo",
"pepinou",
"peptiza",
"pepuíra",
"percolo",
"peremas",
"periatã",
"perimem",
"perlavo",
"perlica",
"pername",
"perolem",
"peroneu",
"perorem",
"perpoém",
"perquei",
"pervado",
"pervera",
"pescais",
"pesgada",
"pesguei",
"pespita",
"pestiva",
"pestivo",
"peteada",
"petitar",
"petitor",
"petunga",
"pexelim",
"pexotar",
"pezorro",
"peãozão",
"piariam",
"piasses",
"piastes",
"piauaçu",
"picrina",
"picuaba",
"picuins",
"picídea",
"picídeo",
"pifarão",
"pigadas",
"pijames",
"pilacre",
"pilafes",
"pilarão",
"pilaste",
"pilchou",
"pildras",
"pileada",
"pilenta",
"pilumna",
"pimpins",
"pimplou",
"pimélia",
"pinhoãs",
"pinocam",
"pintale",
"pinácia",
"pinásio",
"pioreis",
"piorros",
"piovesa",
"piparem",
"pipetam",
"pipiava",
"pipitas",
"pipiões",
"pipídeo",
"piqueie",
"piquemo",
"piqueto",
"pirambo",
"pirapiá",
"piraqui",
"piratai",
"piratei",
"piratem",
"pirauru",
"pirenga",
"pirinas",
"pirlito",
"piroliz",
"pirrite",
"pisarmo",
"pisgara",
"pistais",
"pitarem",
"pitares",
"pitocha",
"pitosca",
"pitosos",
"pitribi",
"piuense",
"piuvais",
"pivotei",
"pizorga",
"piápias",
"piãozão",
"piélico",
"piêmese",
"piêmico",
"piúrico",
"planiza",
"plasmes",
"plasmão",
"plaudis",
"plengue",
"plenite",
"pleusto",
"pleária",
"pliquem",
"plosões",
"plágico",
"plêiona",
"pocador",
"poceira",
"podavas",
"podicas",
"poditos",
"podouro",
"poecilo",
"poermos",
"poiadas",
"poiados",
"poiarei",
"poiaria",
"poisios",
"pojados",
"pojaram",
"pojares",
"polador",
"polarão",
"poleada",
"poleava",
"poleira",
"polieto",
"polmaço",
"polmões",
"polodia",
"poloide",
"polonos",
"polênia",
"políbia",
"pomagem",
"pombear",
"pombeia",
"pombeou",
"pomeiro",
"pomosos",
"pompeou",
"pomécio",
"pongava",
"ponhemo",
"pontues",
"ponçado",
"poppela",
"popucas",
"popules",
"populeã",
"pororós",
"porrigo",
"posanga",
"posaste",
"pospões",
"posteie",
"posteja",
"postemo",
"posteou",
"potiqui",
"potrudo",
"potália",
"potósia",
"poutões",
"povense",
"poçanco",
"poçocas",
"pracate",
"prasmar",
"preacaá",
"predato",
"prefina",
"pregnam",
"pregoei",
"pregueá",
"preleve",
"prelibe",
"presiga",
"priangu",
"priorou",
"priscai",
"priônio",
"proadas",
"proales",
"proavas",
"procace",
"proctal",
"proctos",
"prosmas",
"protino",
"proturo",
"prumava",
"prussas",
"prísmeo",
"prítico",
"prócios",
"prófise",
"próporo",
"prótica",
"prótoma",
"psefite",
"psócido",
"ptilose",
"ptérica",
"pucaçus",
"pucelas",
"pucínia",
"pudermo",
"pudidos",
"pufinos",
"pugilou",
"pujaram",
"pulegol",
"pulhado",
"pulicam",
"pultada",
"pultado",
"pulvina",
"punarés",
"punense",
"punçada",
"pupilem",
"purgues",
"puteava",
"puxurim",
"puxuris",
"puíssem",
"pábulos",
"pálicas",
"páralos",
"pármula",
"párrias",
"pândito",
"péptide",
"pérseos",
"pínulos",
"pípedes",
"pólicas",
"quareis",
"quartai",
"queches",
"quedive",
"quejeme",
"queleis",
"quelone",
"quemeia",
"quereca",
"quianda",
"quicuta",
"quidans",
"quietes",
"quilaja",
"quilata",
"quimbar",
"quimose",
"quinecu",
"quintou",
"quiriba",
"quirite",
"quiseco",
"quitare",
"quitura",
"quitões",
"quotize",
"rabalde",
"rabanou",
"rabeiem",
"rabiais",
"rabiara",
"rabidez",
"rabiolo",
"rabitas",
"rabolas",
"rabosos",
"rabotai",
"rabotam",
"rabujam",
"rabunho",
"radicie",
"radobar",
"radomos",
"rafaria",
"rafiava",
"ragádia",
"raiaras",
"raieira",
"raieiro",
"raiosas",
"raiosos",
"rajarem",
"rajarão",
"rajeira",
"rajídeo",
"ralassa",
"ralavas",
"raleara",
"raleias",
"ralento",
"ralhais",
"ralheis",
"ramalda",
"ramante",
"ramilho",
"ramnale",
"ramudas",
"ramular",
"randita",
"rangias",
"rapelam",
"rapelha",
"rapinem",
"raquite",
"rareara",
"rasarão",
"rasasse",
"rascões",
"rasoiro",
"raspais",
"rasques",
"rasteie",
"rastões",
"ratagem",
"ratamos",
"ratanha",
"ratanhi",
"ratante",
"rataram",
"ratinam",
"ratinar",
"raudais",
"reabris",
"reachei",
"reachou",
"reacusa",
"readale",
"readota",
"reajais",
"realeja",
"realoje",
"reamado",
"reandou",
"reanexo",
"reateia",
"reavias",
"reaviei",
"reaviou",
"reavise",
"rebarem",
"rebeije",
"rebelim",
"rebojam",
"reboliu",
"rebulho",
"rebulia",
"rebuliu",
"recalam",
"recalma",
"recapei",
"recapto",
"recaram",
"recenas",
"recheco",
"rechine",
"rechupa",
"recomem",
"recoque",
"recorça",
"recosia",
"recozem",
"recozia",
"recreai",
"rectite",
"recumbo",
"recurei",
"recíolo",
"rederas",
"redesse",
"redijas",
"redimis",
"rediria",
"redobar",
"redoria",
"redúvio",
"reergas",
"refegou",
"referva",
"refiado",
"refilie",
"refirme",
"refitar",
"refitem",
"refluas",
"refluxa",
"refolha",
"refuste",
"reganos",
"regasto",
"regatem",
"regatoa",
"regense",
"regento",
"regerou",
"regeste",
"reginei",
"reginos",
"regiras",
"regires",
"regíneo",
"reifico",
"reimpõe",
"reinite",
"reinola",
"reiunou",
"relacra",
"relambo",
"relares",
"relargo",
"relavei",
"relavou",
"reledes",
"releixa",
"relengo",
"relhado",
"relimai",
"reloteá",
"relutes",
"relveja",
"remalha",
"remasca",
"remasco",
"remeada",
"remedem",
"remeias",
"remelga",
"remexei",
"remigra",
"remigro",
"reminta",
"reminto",
"remisga",
"remocem",
"remudou",
"remugia",
"remírea",
"rendões",
"renetem",
"renhões",
"renitia",
"renitiu",
"rentava",
"rentesa",
"renuais",
"renutra",
"renutri",
"reolise",
"repagas",
"repagou",
"repague",
"repaste",
"repesas",
"repesos",
"repesou",
"repeças",
"repicai",
"repicas",
"repolem",
"repular",
"repulou",
"reputai",
"resmado",
"resmais",
"resmoia",
"resmono",
"ressoos",
"restire",
"resuras",
"retacar",
"reteava",
"retedor",
"reteima",
"reteniu",
"retesia",
"retesto",
"retinim",
"retobar",
"retoras",
"retorsa",
"retosta",
"retouce",
"retraze",
"retroes",
"retusos",
"revelis",
"reveliu",
"revenem",
"revenes",
"revesas",
"revezai",
"revibra",
"revibro",
"revidor",
"revocai",
"revocam",
"revolca",
"revosso",
"rexelos",
"rezante",
"rezuela",
"reófila",
"reófito",
"reônomo",
"rideira",
"rideiro",
"ridores",
"rienses",
"rifarão",
"rigaram",
"rigoliz",
"rijosos",
"rilhara",
"rilhosa",
"rimosos",
"rincoto",
"rinhado",
"ripadão",
"ripetas",
"riscais",
"riscões",
"ristela",
"riteira",
"rixados",
"rixando",
"rixavam",
"rizante",
"rizinos",
"roboras",
"roborou",
"robóreo",
"rodalho",
"rodoiro",
"rodíaco",
"rodízia",
"roereis",
"roerias",
"rojamos",
"rojarei",
"rojaria",
"rojasse",
"roldada",
"roldear",
"rolices",
"romanho",
"romarem",
"romaris",
"romavam",
"romeína",
"rompias",
"rompões",
"roncais",
"roncões",
"rondeie",
"rondeme",
"rondões",
"roradas",
"rorário",
"rosarão",
"rosatos",
"roscara",
"rosicré",
"rostear",
"rostida",
"rotarem",
"roteiem",
"rotejar",
"rotfone",
"rotulai",
"roupava",
"roupida",
"roxicré",
"rozeimo",
"roçagam",
"roçarei",
"roçarmo",
"rubafos",
"rubejar",
"rubicãs",
"rudista",
"rugedor",
"rugitou",
"rujamos",
"rulasse",
"rulengo",
"rumavas",
"rumbado",
"rumbeia",
"rumiada",
"rumorem",
"rusgava",
"russado",
"rutaram",
"ruçadas",
"rádicos",
"rálidas",
"râncios",
"rânidas",
"réliquo",
"rênquea",
"rócheas",
"róridos",
"sabasto",
"sabitus",
"sabular",
"saburus",
"sacaias",
"sachará",
"sacholo",
"sacieis",
"saforil",
"saganha",
"sagezas",
"sagrais",
"saiadas",
"saianos",
"saieira",
"saietas",
"saiolas",
"saiotos",
"saitaia",
"sajaria",
"salalés",
"salmina",
"salteis",
"salésia",
"samanco",
"samaqui",
"sambeis",
"sambles",
"samboca",
"samiano",
"samocos",
"samídea",
"sandaba",
"sandada",
"sandios",
"sanfono",
"saniosa",
"sanisca",
"santale",
"santelo",
"sapatam",
"sapateá",
"sapatou",
"sapeiam",
"sapenos",
"saperda",
"sapotes",
"saraivo",
"sarapas",
"saraças",
"sargola",
"sarjara",
"sarjava",
"sarotes",
"sarotos",
"sarpais",
"sarrais",
"sarrudo",
"sartado",
"sassara",
"satisdo",
"saugate",
"saunita",
"savarim",
"savitus",
"sazoado",
"sazonas",
"sebites",
"sebruna",
"sedagem",
"sedarão",
"sedasse",
"sedaços",
"sedeiam",
"segarei",
"seisavo",
"sejagão",
"sejaria",
"seletam",
"selotes",
"sembeis",
"semeamo",
"semioco",
"sencelo",
"sensiva",
"senônio",
"sepalar",
"sepsina",
"septosa",
"sequiam",
"serguia",
"seridós",
"seriúva",
"sermona",
"serreis",
"serreou",
"serzete",
"serápia",
"sesmada",
"sesteei",
"setarão",
"seteias",
"setemês",
"setoira",
"sevadas",
"sevavam",
"sexatas",
"sextavo",
"siastes",
"sibalas",
"sibilus",
"sibitar",
"sicanas",
"sicinos",
"sidonal",
"siglara",
"sigliza",
"signava",
"signemo",
"silabem",
"silabes",
"silhais",
"siligem",
"siligos",
"silimba",
"silvato",
"sinabos",
"sinanta",
"sinaxes",
"sindiba",
"sinetou",
"sinêmio",
"siochos",
"sirenar",
"sirenou",
"siricto",
"siríase",
"siseiro",
"sissomo",
"sistola",
"sisífio",
"sisório",
"sitares",
"sitasse",
"sitavam",
"soabria",
"soagens",
"soalhes",
"sobiado",
"sobiava",
"sobiote",
"soborda",
"sobramo",
"socante",
"socatas",
"socates",
"sochear",
"socorna",
"socumes",
"sodando",
"sofenas",
"soframo",
"sogando",
"sogaços",
"sojigam",
"sojoada",
"sojorna",
"sojorno",
"solamas",
"soleína",
"solfava",
"solidei",
"solidem",
"solênio",
"somadão",
"somarmo",
"sombrai",
"sometem",
"sonadão",
"sonalha",
"sondemo",
"sonense",
"sonento",
"sonetam",
"sonurno",
"sonívio",
"sopeiam",
"sopelão",
"sopramo",
"sopreis",
"sopreso",
"soprior",
"soqueio",
"sorosos",
"sorraba",
"sorções",
"soródio",
"sorória",
"sotopôs",
"soutais",
"soveira",
"soveiro",
"sovemos",
"sovinei",
"soçocas",
"suadida",
"suadira",
"suantes",
"suardes",
"suareis",
"suasiva",
"suasões",
"subarás",
"subaste",
"subirmo",
"subnota",
"suburgo",
"subvale",
"sucadas",
"sucados",
"sucamos",
"sucatam",
"sucavam",
"sucuabo",
"sucuuva",
"suestar",
"suflado",
"sujicou",
"sujigou",
"suleira",
"sulento",
"sulques",
"sulídeo",
"sumagra",
"sumbram",
"sumosas",
"sunções",
"supuras",
"surcava",
"surriei",
"surriem",
"surtais",
"surucar",
"surucou",
"surucuí",
"surumas",
"susiano",
"susquir",
"sustoso",
"sutadas",
"sutados",
"sutéria",
"suumbas",
"suãzona",
"suçuaia",
"sálpido",
"sárdios",
"sátrape",
"sâmbula",
"sédulos",
"síconos",
"sílvica",
"símicos",
"síngilo",
"sínocos",
"sírfida",
"síticas",
"sítulas",
"súbulas",
"tabanas",
"tabaxir",
"tabelai",
"tabidez",
"tabiras",
"tabulai",
"tachamo",
"tachola",
"tachote",
"tacuaré",
"tacácea",
"tadorno",
"tagalar",
"tagazes",
"tagídeo",
"taiaúva",
"talapão",
"talaste",
"talemos",
"talimar",
"taliões",
"talocas",
"taludou",
"tameira",
"tamoela",
"tampamo",
"tamuria",
"tanguto",
"tanjões",
"taparas",
"tapecei",
"tapeina",
"tapeira",
"tapetem",
"tapigos",
"tapizam",
"tapiúba",
"tapuísa",
"taravam",
"tarbelo",
"tardeco",
"tardega",
"tareada",
"taredos",
"tarefai",
"tarefam",
"tarifem",
"tarifes",
"tarimbo",
"tarjava",
"tarocar",
"tarocas",
"tarrear",
"tarreco",
"tarrelo",
"tarucas",
"tarugou",
"tascoar",
"tatacaá",
"tateies",
"tauaras",
"tauatós",
"tauocas",
"tavaras",
"taxares",
"taxíneo",
"teagens",
"teamina",
"tebense",
"teclara",
"tecnita",
"tecível",
"tedoras",
"tegeata",
"teipoca",
"teiroga",
"telamos",
"telaram",
"telarem",
"telasse",
"televam",
"televir",
"telhuda",
"teléfio",
"temedor",
"temembó",
"temersó",
"templam",
"templem",
"temudas",
"tenador",
"tenazar",
"tendada",
"tenilha",
"teniope",
"tenioto",
"tenitas",
"tenterê",
"tençoar",
"teníola",
"terebro",
"terecaí",
"tereias",
"teresos",
"terineu",
"terreia",
"terriês",
"terícia",
"tesemos",
"tesidão",
"testemo",
"teófora",
"tiburno",
"ticamos",
"ticavam",
"ticolão",
"ticuras",
"tifemia",
"tifismo",
"tigarro",
"tijolas",
"tilanto",
"timbris",
"timbuar",
"tinelos",
"tingaçu",
"tinguei",
"tinguás",
"tinilho",
"tiniras",
"tintais",
"tiolzão",
"tiorega",
"tipicou",
"tipista",
"tipizar",
"tiquear",
"tiritem",
"tiritir",
"tiriúma",
"tirsoso",
"titanas",
"titelas",
"titinas",
"titique",
"titubeá",
"tiólico",
"tmólias",
"toarmos",
"tobiras",
"tocaris",
"tofanas",
"tofáceo",
"tojosos",
"toldeia",
"tolejou",
"tolerão",
"toluína",
"tombais",
"tombeia",
"tombeis",
"tonador",
"tonsuro",
"topetou",
"topinha",
"torceou",
"toremos",
"torengo",
"torilos",
"torinas",
"torique",
"tornino",
"toronai",
"torosos",
"torpeda",
"torpede",
"torreou",
"torrosa",
"tortudo",
"torvara",
"torveis",
"torínio",
"toscais",
"toscamo",
"toscara",
"tossias",
"totanga",
"totumas",
"totumos",
"touquem",
"tradeei",
"trainem",
"trameto",
"tramoso",
"tranava",
"trangla",
"transai",
"trapeio",
"trapeja",
"trapita",
"trassam",
"trauira",
"trausos",
"trautam",
"travamo",
"travese",
"travial",
"traçona",
"trebano",
"trebola",
"treitou",
"trelhos",
"treliam",
"trelias",
"trelida",
"trememo",
"tremete",
"trenete",
"trepais",
"trepeis",
"trepica",
"treslês",
"tressuo",
"tresves",
"tretear",
"trevelô",
"triaras",
"triaste",
"tribule",
"tricoso",
"triense",
"triglas",
"triguei",
"trigães",
"trilupa",
"trisada",
"trisado",
"tritamo",
"troador",
"troceis",
"trofias",
"trogmos",
"trompou",
"tropana",
"tropara",
"tropeei",
"tropelã",
"trotais",
"troteie",
"trouçou",
"trovoam",
"truncha",
"trupais",
"trutine",
"trábeas",
"trápala",
"trévias",
"tréviro",
"trévoas",
"tríonix",
"tríopas",
"tríquio",
"tuaiuçu",
"tuapoca",
"tubacas",
"tubadas",
"tubando",
"tuberta",
"tucanis",
"tucuari",
"tucupim",
"tucuris",
"tudista",
"tuesita",
"tufarão",
"tufácio",
"tugirem",
"tugires",
"tuinins",
"tuiroca",
"tuiuvas",
"tujonas",
"tumefez",
"tumulou",
"tuncuns",
"tunguem",
"tungula",
"tunguse",
"tunisis",
"tupaipi",
"tupisse",
"turamos",
"turaram",
"turgias",
"turiúva",
"turquis",
"tusébio",
"tutando",
"tutasse",
"tuteado",
"tuteiam",
"tuteias",
"tutinas",
"tutorem",
"tutucas",
"tutório",
"tuvalês",
"tuzinas",
"tzigano",
"tábidas",
"tágicas",
"tágicos",
"tágoras",
"tálcico",
"táptico",
"tármico",
"télsons",
"têmenis",
"tígrida",
"tígrido",
"tímbreo",
"tóssico",
"tômboro",
"tômicas",
"túpicos",
"túrdula",
"túrgica",
"uacurau",
"uaiorós",
"uaixima",
"uanderu",
"uapuçás",
"uaícana",
"uberana",
"uberara",
"uberoso",
"ucriana",
"uetacás",
"ufanais",
"ufaneia",
"ufanemo",
"uicungo",
"uiraçus",
"uiscada",
"uistiti",
"uivação",
"ulesina",
"ulisseu",
"ulmatos",
"ulmácea",
"uloncia",
"ululada",
"ulvácea",
"umbitzã",
"umblina",
"umbolas",
"umectem",
"umectou",
"undados",
"undinas",
"undosas",
"undosos",
"undular",
"unganjo",
"ungidor",
"unguari",
"unhador",
"unhamos",
"unhante",
"unharam",
"unharem",
"unhetas",
"unhudas",
"unicolo",
"unirias",
"unitize",
"unteira",
"untável",
"unítona",
"uranito",
"urcéola",
"urdirei",
"urdiria",
"urdiste",
"ureicos",
"uropigo",
"urravas",
"urticou",
"urômelo",
"usagres",
"usitado",
"usninas",
"ustória",
"usuanos",
"usucape",
"usípete",
"utahita",
"utahite",
"utantes",
"uxórias",
"uxórica",
"vacaram",
"vacilai",
"vadeara",
"vadense",
"vadiote",
"vadroil",
"vaesita",
"vaesite",
"vagiram",
"vagueai",
"vaiaras",
"valacas",
"valaram",
"valeral",
"valeril",
"valosas",
"valseis",
"valumas",
"valíeis",
"valônio",
"vanecem",
"vanense",
"vantaja",
"vapulas",
"vaqueio",
"vaqueou",
"vararei",
"varaste",
"vareada",
"vareado",
"varejai",
"varelos",
"vareque",
"vargins",
"varloas",
"varlopa",
"varolas",
"varvaro",
"vasaréu",
"vasates",
"vasitas",
"vasária",
"vazadão",
"vazares",
"vazavas",
"vaziais",
"vaziúda",
"veadora",
"vecejar",
"veiavam",
"veirada",
"veladão",
"velança",
"veleada",
"velúcia",
"venamos",
"venenam",
"venenas",
"venenou",
"ventage",
"vençamo",
"venídio",
"venúsio",
"veraneá",
"verbeta",
"verdeci",
"verdego",
"verdeço",
"verenda",
"verenos",
"vermino",
"verrume",
"versuda",
"vertais",
"vertamo",
"vessava",
"vetável",
"vexável",
"vezando",
"vezaram",
"vezavam",
"viadora",
"viageou",
"viandam",
"vibordo",
"vibramo",
"vicejei",
"vicuíba",
"vidagos",
"vidarem",
"vidavam",
"videará",
"vidocas",
"vidoras",
"vigados",
"vigeres",
"vigiamo",
"vigilai",
"vigilei",
"vigilem",
"viltosa",
"vimiais",
"vimosos",
"vinagem",
"vindime",
"vinhara",
"vinhava",
"viniais",
"vinques",
"vintavo",
"violale",
"vipérea",
"viramas",
"virosca",
"visares",
"viscada",
"visgava",
"visgosa",
"viságia",
"vitinas",
"vitrita",
"viuvava",
"vivezas",
"vizeiro",
"vizinhe",
"viçarem",
"viçasse",
"voborde",
"voejara",
"vogaria",
"volcais",
"volcará",
"volteje",
"volutam",
"volível",
"vorador",
"votista",
"votonas",
"vurapiá",
"várdula",
"vérdico",
"véspida",
"vênedas",
"vígilos",
"vírides",
"vísceas",
"vítrias",
"vítrice",
"vômicos",
"whígtia",
"xabouco",
"xacocos",
"xafetão",
"xaiméis",
"xainxás",
"xambuje",
"xanduca",
"xantena",
"xantose",
"xaputas",
"xaquear",
"xarafim",
"xaretas",
"xarocos",
"xavecas",
"xeroxou",
"xifídeo",
"xigogos",
"ximbelo",
"ximburu",
"xinabre",
"xingufo",
"xivitos",
"xocador",
"xonante",
"xonavam",
"xotemos",
"xrâmane",
"xudairo",
"xântica",
"xístico",
"zabumbe",
"zabumbo",
"zacinta",
"zacônio",
"zagaiei",
"zagorro",
"zagucho",
"zamaque",
"zanolha",
"zapeara",
"zapeias",
"zarasca",
"zavaria",
"zebrais",
"zebramo",
"zebrava",
"zebrura",
"zefires",
"zelaste",
"zelavas",
"zenéria",
"zeplins",
"zerbada",
"zeugita",
"zeófaga",
"zimbrou",
"zingrou",
"zipamos",
"zipares",
"zoneava",
"zorilha",
"zornais",
"zoócoro",
"zoólico",
"zumbava",
"zumbrem",
"zumbria",
"zunidas",
"zuniria",
"zupador",
"zurupar",
"zâimbos",
"zâmbias",
"zébrulo",
"ábsonos",
"álbumem",
"álcidas",
"álvidro",
"ápticas",
"árbutos",
"árdegas",
"ástrapa",
"ástrapo",
"átmicas",
"átricas",
"átrocos",
"ânditos",
"ânticas",
"éfedras",
"ênditos",
"êntases",
"êupodes",
"êutrico",
"ístmios",
"ítricos",
"aaboras",
"aalclim",
"aalense",
"aariano",
"ababade",
"ababela",
"ababele",
"ababelo",
"ababose",
"ababoso",
"ababroe",
"ababroo",
"ababroá",
"ababuás",
"ababuís",
"abacaro",
"abacaís",
"abacele",
"abacelo",
"abaceto",
"abaciai",
"abaciam",
"abaciei",
"abaciem",
"abacies",
"abacina",
"abacine",
"abacino",
"abactos",
"abaculu",
"abadada",
"abadais",
"abadale",
"abadamo",
"abadane",
"abadano",
"abadaná",
"abadará",
"abadeis",
"abadeja",
"abademo",
"abadiai",
"abadiam",
"abadico",
"abadiei",
"abadies",
"abadins",
"abadiou",
"abadons",
"abadora",
"abaetai",
"abaetam",
"abaetas",
"abaetei",
"abafamo",
"abafemo",
"abafura",
"abagace",
"abagado",
"abagais",
"abagará",
"abagava",
"abagaço",
"abagaçá",
"abagoai",
"abagoam",
"abagoar",
"abagoas",
"abagoei",
"abagoem",
"abagoes",
"abagoou",
"abaguei",
"abaguem",
"abagues",
"abaguim",
"abaguns",
"abaiane",
"abainhe",
"abainho",
"abairra",
"abairre",
"abaisir",
"abaités",
"abaiúco",
"abalaia",
"abalaie",
"abalaio",
"abalais",
"abalamo",
"abalaús",
"abalcei",
"abalcem",
"abalces",
"abaldai",
"abaldam",
"abaldar",
"abaldas",
"abaldei",
"abaldem",
"abaldeá",
"abaldoa",
"abaldoe",
"abaldoo",
"abaldou",
"abalemo",
"abalizo",
"abaloai",
"abaloas",
"abaloei",
"abaloem",
"abalofa",
"abalofe",
"abalosa",
"abalroo",
"abalsai",
"abalsam",
"abalsas",
"abalsei",
"abalsem",
"abalses",
"abalsou",
"abalume",
"abalumo",
"abalumá",
"abalzão",
"abalçai",
"abalçam",
"abalças",
"abalçou",
"abambai",
"abambam",
"abambar",
"abambas",
"abambei",
"abambem",
"abambou",
"abanane",
"abancai",
"abandai",
"abandam",
"abandas",
"abandem",
"abandes",
"abandeá",
"abandoe",
"abandoo",
"abaneia",
"abaneus",
"abaneém",
"abangas",
"abangos",
"abanhai",
"abanham",
"abanhas",
"abanhem",
"abanhes",
"abanhou",
"abanito",
"abantas",
"abanteu",
"abaorta",
"abapani",
"abaquie",
"abaquio",
"abaquiá",
"abarbai",
"abarbam",
"abarbas",
"abarbei",
"abarbem",
"abarbes",
"abarbeá",
"abardai",
"abardam",
"abardar",
"abardas",
"abardei",
"abardem",
"abardes",
"abareno",
"abargas",
"abarina",
"abarino",
"abarmos",
"abarmão",
"abarone",
"abarono",
"abaroná",
"abarroa",
"abarroe",
"abarroo",
"abasias",
"abasina",
"abasine",
"abasino",
"abasmai",
"abasmam",
"abasmas",
"abasmei",
"abasmem",
"abasmes",
"abasmou",
"abasses",
"abassor",
"abastai",
"abastes",
"abasões",
"abatamo",
"abatato",
"abatele",
"abatine",
"abatipó",
"abatiuí",
"abatixi",
"abatoco",
"abatumo",
"abatões",
"abaulai",
"abaulei",
"abaules",
"abaçane",
"abaçano",
"abaíbas",
"abaúlas",
"abaúlem",
"abcedai",
"abcedam",
"abcedas",
"abcedei",
"abcedem",
"abcedes",
"abcedeu",
"abcedia",
"abcedou",
"abcessa",
"abcidai",
"abcidam",
"abcidar",
"abcidas",
"abcidei",
"abcidem",
"abcides",
"abcidou",
"abcáris",
"abdalão",
"abdares",
"abderas",
"abdesta",
"abdeste",
"abditas",
"abdudes",
"abdutos",
"abduzas",
"abduzes",
"abduzis",
"abdímia",
"abeatai",
"abeatam",
"abeatei",
"abeatem",
"abeates",
"abecada",
"abecais",
"abecamo",
"abecará",
"abecava",
"abecedo",
"abeceis",
"abecemo",
"abecerá",
"abeciam",
"abecias",
"abecida",
"abecido",
"abedais",
"abegoas",
"abeicei",
"abeicem",
"abeices",
"abeirai",
"abeiçai",
"abeiçam",
"abeiçar",
"abeiças",
"abeiçou",
"abelana",
"abelere",
"abelgai",
"abelgam",
"abelgar",
"abelgou",
"abelgue",
"abelhai",
"abelhei",
"abelhem",
"abelhes",
"abelhoe",
"abelhoo",
"abelhou",
"abelhue",
"abelhuo",
"abelhuá",
"abelide",
"abelidá",
"abelins",
"abelite",
"abelota",
"abemola",
"abemole",
"abeonas",
"abeonos",
"abequei",
"abequem",
"abeques",
"aberdim",
"aberido",
"aberita",
"aberrai",
"aberrei",
"aberrem",
"aberres",
"aberrou",
"abesina",
"abesino",
"abesira",
"abespas",
"abespão",
"abestai",
"abestam",
"abestei",
"abestem",
"abestes",
"abestia",
"abestie",
"abestio",
"abetina",
"abetume",
"abetumo",
"abetuns",
"abevere",
"abevero",
"abeverá",
"abexeda",
"abexigo",
"abgrego",
"abgregá",
"abiabas",
"abianas",
"abicais",
"abicamo",
"abicará",
"abichai",
"abichas",
"abichei",
"abichem",
"abiches",
"abicore",
"abicoro",
"abieira",
"abienos",
"abietol",
"abietos",
"abifado",
"abifais",
"abifamo",
"abifará",
"abifava",
"abifeis",
"abifemo",
"abigeai",
"abigear",
"abigeei",
"abigeia",
"abigeie",
"abigeio",
"abigeou",
"abigode",
"abigodo",
"abigodá",
"abijira",
"abilhai",
"abilham",
"abilhas",
"abilhei",
"abilhem",
"abilhes",
"abilhou",
"abilola",
"abilole",
"abilolo",
"abinhai",
"abinham",
"abinhar",
"abinhei",
"abinhem",
"abinhes",
"abinhou",
"abiombe",
"abiombo",
"abiombá",
"abioris",
"abioses",
"abipona",
"abipões",
"abiquei",
"abiquem",
"abiraco",
"abiscas",
"abisele",
"abiselo",
"abiselá",
"abisgas",
"abispai",
"abispam",
"abispar",
"abispem",
"abispes",
"abissam",
"abissei",
"abissem",
"abisses",
"abissou",
"abistas",
"abitaga",
"abitais",
"abitamo",
"abiteis",
"abitemo",
"abitica",
"abitiga",
"abitole",
"abitolo",
"abituma",
"abitume",
"abitumo",
"abitões",
"abivaco",
"abivacá",
"abiíbas",
"abiúdas",
"abiúdos",
"abjugai",
"abjugam",
"abjugas",
"abjugou",
"abjugue",
"abjunge",
"abjungi",
"abjunja",
"abjunjo",
"abjunta",
"abjurai",
"abjurgo",
"ablacas",
"ablacta",
"ablacte",
"ablages",
"ablande",
"ablecte",
"ablegai",
"ablegas",
"ablegue",
"ableite",
"ableito",
"ableitá",
"ablenas",
"ablenos",
"ablocai",
"ablocam",
"ablocar",
"ablocas",
"ablocou",
"abloque",
"abluais",
"abluías",
"abluída",
"abluímo",
"abluíra",
"abmigre",
"abmigro",
"abmigrá",
"abnadai",
"abnadam",
"abnadar",
"abnadas",
"abnadei",
"abnadem",
"abnades",
"abnadou",
"abnegai",
"abnetos",
"abnodai",
"abnodam",
"abnodas",
"abnodei",
"abnodem",
"abnodes",
"abnodou",
"abnoite",
"abnoito",
"abnoitá",
"abnuais",
"abnuíam",
"abnuías",
"abnuída",
"abnuíra",
"abnóxia",
"aboadas",
"aboados",
"aboadão",
"aboamos",
"aboando",
"aboante",
"aboaram",
"aboaras",
"aboarei",
"aboarem",
"aboares",
"aboaria",
"aboarmo",
"aboarão",
"aboasse",
"aboaste",
"aboavam",
"aboavas",
"abobade",
"abobais",
"abobava",
"abobeis",
"abobemo",
"abobore",
"abocade",
"abocais",
"abocamo",
"aboceis",
"abocele",
"abocelo",
"abocelá",
"abocemo",
"abocete",
"aboceto",
"abocine",
"abocino",
"abociná",
"abodoco",
"abodocá",
"aboemia",
"aboemie",
"aboemio",
"aboemos",
"abofete",
"abofeto",
"abofetá",
"aboiais",
"aboieis",
"abojais",
"abojamo",
"abojará",
"abojava",
"abojeis",
"abojemo",
"abolamo",
"abolana",
"abolano",
"abolava",
"abolaza",
"abolcei",
"abolcem",
"aboleai",
"abolear",
"aboleei",
"aboleie",
"aboleio",
"aboleis",
"abolemo",
"aboleou",
"abolhai",
"abolham",
"abolhas",
"abolhei",
"abolhem",
"abolhes",
"abolhou",
"abolimo",
"aboline",
"abolino",
"abolsai",
"abolsam",
"abolsei",
"abolsem",
"abolsou",
"abolume",
"abolumo",
"abolumá",
"abolçai",
"abolçam",
"abolçou",
"abomasa",
"abombai",
"abombam",
"abombei",
"abombem",
"abombes",
"abonais",
"abonamo",
"abonaxi",
"aboncai",
"aboncam",
"aboncar",
"aboncas",
"aboncou",
"abondai",
"abondas",
"abondes",
"aboneco",
"aboneis",
"abonemo",
"abonque",
"aboquei",
"aboquem",
"aboques",
"aborcai",
"aborcam",
"aborcas",
"aborcei",
"aborcem",
"aborces",
"abordai",
"abordoe",
"abordoo",
"aborque",
"aborram",
"aborrem",
"aborria",
"aborris",
"aborriu",
"aborsos",
"aborçou",
"aboscai",
"aboscam",
"aboscas",
"abosque",
"aboteca",
"aboteco",
"abotica",
"abotico",
"abotije",
"abotijo",
"abotijá",
"abotine",
"abotino",
"abotoai",
"abotoca",
"abotoco",
"abotoes",
"abotume",
"abotumo",
"abotumá",
"aboubai",
"aboubam",
"aboubei",
"aboubem",
"aboubes",
"aboubou",
"aboucai",
"aboucam",
"aboucas",
"aboucou",
"abougai",
"abougam",
"abougar",
"abougas",
"abougou",
"abougue",
"abounas",
"abouque",
"abovila",
"abporal",
"abradai",
"abradam",
"abradas",
"abradei",
"abraseá",
"abrasim",
"abrasoe",
"abrasoo",
"abrazôs",
"abrecai",
"abrecam",
"abrecar",
"abrecas",
"abrecou",
"abregoe",
"abregoo",
"abregoá",
"abrejai",
"abrejas",
"abrejei",
"abrejem",
"abrejes",
"abrenha",
"abrenhe",
"abrenho",
"abreque",
"abrevai",
"abrevam",
"abrevas",
"abrevei",
"abrevem",
"abrigoi",
"abrinas",
"abrique",
"abrismo",
"abrista",
"abritas",
"abriões",
"abrobas",
"abrocai",
"abrocam",
"abrocas",
"abroche",
"abrocos",
"abrocou",
"abrodil",
"abrodis",
"abromas",
"abromia",
"abronza",
"abronze",
"abrotai",
"abrotam",
"abrotei",
"abrotem",
"abrotes",
"abrotou",
"abroâni",
"abrumai",
"abrumas",
"abrumei",
"abrumem",
"abrumes",
"abrunhe",
"abrunhá",
"abrutai",
"abrutam",
"abrutei",
"abrutem",
"abrutou",
"abrália",
"abrécia",
"abrétia",
"absceda",
"abscede",
"abscedi",
"abscedo",
"abscida",
"abscido",
"absecto",
"absicto",
"absidas",
"absomes",
"absonai",
"absonam",
"absonas",
"absonei",
"absonem",
"absones",
"absonou",
"absorbi",
"abstero",
"abstrua",
"abstruo",
"abstruí",
"abstrói",
"absumam",
"absumas",
"absumia",
"absumir",
"absumis",
"absumiu",
"absunta",
"abuanai",
"abuanam",
"abuanar",
"abuanas",
"abuanei",
"abuanem",
"abuanou",
"abucaia",
"abuceis",
"abucemo",
"abufele",
"abufeta",
"abufete",
"abufeto",
"abugrai",
"abugram",
"abugras",
"abugrei",
"abugrem",
"abugrou",
"abugões",
"abujede",
"abujões",
"abulada",
"abulais",
"abulamo",
"abulara",
"abulava",
"abulaza",
"abuleis",
"abulemo",
"abulzão",
"abundai",
"abundes",
"abunhai",
"abunham",
"abunhas",
"abunhei",
"abunhem",
"abunhes",
"abunhou",
"abunuro",
"aburaca",
"aburaco",
"aburela",
"aburele",
"aburelo",
"aburile",
"aburilo",
"aburilá",
"aburote",
"aburrai",
"aburram",
"aburras",
"aburrou",
"aburrão",
"abusamo",
"abuscai",
"abuscas",
"abuscou",
"abuseai",
"abusear",
"abuseei",
"abuseia",
"abuseie",
"abuseio",
"abusemo",
"abuseou",
"abutona",
"abutume",
"abutumo",
"abutumá",
"abuzine",
"abuzino",
"abuçada",
"abuçais",
"abuçamo",
"abuçava",
"abuízes",
"abvacue",
"abvacuo",
"abvacuá",
"abácula",
"abáreis",
"abásica",
"abáveis",
"abávias",
"abávios",
"abâmeas",
"abética",
"abétimo",
"abígeas",
"abígeos",
"acabane",
"acabele",
"acabiam",
"acabide",
"acabido",
"acabidá",
"acacalo",
"acaceis",
"acacemo",
"acacete",
"acaceto",
"acachai",
"acacham",
"acachas",
"acachem",
"acaches",
"acacheá",
"acachoe",
"acachoo",
"acachou",
"acacifa",
"acacife",
"acacifo",
"acacina",
"acacula",
"acacule",
"acaculo",
"acadais",
"acadamo",
"acadava",
"acadeis",
"acadime",
"acadimo",
"acaecei",
"acaecem",
"acaeces",
"acaeceu",
"acaecia",
"acaente",
"acaento",
"acaentá",
"acaeçam",
"acaeças",
"acafala",
"acafale",
"acafalo",
"acafele",
"acafelo",
"acafeta",
"acafete",
"acafeto",
"acafoba",
"acafobe",
"acafobo",
"acagace",
"acagaço",
"acagibe",
"acagibo",
"acagibá",
"acagula",
"acagule",
"acagulo",
"acaiais",
"acaibam",
"acaibas",
"acaidor",
"acaidão",
"acairei",
"acairia",
"acairão",
"acaiura",
"acajada",
"acajade",
"acajado",
"acajuís",
"acalais",
"acalamo",
"acalara",
"acalava",
"acalcai",
"acalcas",
"acalcei",
"acalcem",
"acalces",
"acaleis",
"acalemo",
"acalhoe",
"acalhoo",
"acalhoá",
"acalzão",
"acamacu",
"acamais",
"acamamo",
"acamboa",
"acamboe",
"acamboo",
"acamela",
"acamele",
"acamelo",
"acamise",
"acamiso",
"acamote",
"acanaca",
"acanada",
"acanale",
"acanalo",
"acaneia",
"acanele",
"acanelo",
"acangis",
"acanhai",
"acanhoa",
"acanhoe",
"acanhoo",
"acanito",
"acanjes",
"acanjis",
"acanoai",
"acanoam",
"acanoas",
"acanoei",
"acanoem",
"acanoes",
"acantal",
"acantas",
"acantes",
"acantia",
"acantoe",
"acantoo",
"acanude",
"acanudo",
"acanudá",
"acanula",
"acanule",
"acanulo",
"acanute",
"acanutá",
"acaovãs",
"acapare",
"acaparo",
"acapele",
"acapelo",
"acapnas",
"acapnos",
"acapota",
"acapote",
"acapoto",
"acapuas",
"acaramo",
"acarava",
"acardas",
"acardos",
"acareai",
"acareei",
"acareio",
"acareis",
"acarela",
"acaremo",
"acaride",
"acarnar",
"acarneu",
"acarnos",
"acaroai",
"acaroam",
"acaroas",
"acaroei",
"acaroem",
"acaroes",
"acaroou",
"acarpia",
"acarpos",
"acarrai",
"acarram",
"acarras",
"acarrei",
"acarrem",
"acarres",
"acartai",
"acartem",
"acarvai",
"acarvam",
"acarvar",
"acarvas",
"acarvei",
"acarvem",
"acarves",
"acarvoe",
"acarvoo",
"acarvou",
"acarvoá",
"acasaco",
"acasacá",
"acasdir",
"acaseai",
"acaseei",
"acaseia",
"acaseie",
"acaseio",
"acaseou",
"acastas",
"acastes",
"acastoe",
"acastoo",
"acastoá",
"acasula",
"acasule",
"acasulo",
"acatais",
"acatena",
"acateno",
"acatite",
"acatito",
"acauaio",
"acauais",
"acauens",
"acausta",
"acavale",
"acaçado",
"acaçais",
"acaçamo",
"acaçape",
"acaçapo",
"acaçará",
"acaçava",
"acaçoai",
"acaçoam",
"acaçoar",
"acaçoas",
"acaçoei",
"acaçoem",
"acaçoes",
"acaçoou",
"acaídos",
"acaíeis",
"acaíram",
"acaíres",
"acaísse",
"acaíste",
"acciana",
"acebada",
"acebado",
"acebais",
"acebamo",
"acebará",
"acebava",
"acebeis",
"acebemo",
"aceboco",
"acebocá",
"acebole",
"acebolo",
"aceches",
"acedemo",
"acefico",
"aceibai",
"aceibam",
"aceibar",
"aceibas",
"aceibei",
"aceibem",
"aceibes",
"aceibou",
"aceifai",
"aceifam",
"aceifem",
"aceifes",
"aceifou",
"aceifão",
"aceimai",
"aceimam",
"aceimei",
"aceimem",
"aceimes",
"aceimou",
"aceirai",
"aceires",
"acejada",
"acejado",
"acejais",
"acejamo",
"acejará",
"acejava",
"acejeis",
"acejemo",
"acelcas",
"acelias",
"acelifa",
"acelifo",
"acemela",
"acemeta",
"acemeto",
"acemita",
"acemite",
"acendre",
"acendro",
"aceneis",
"acenhas",
"acepipo",
"acepipá",
"aceptai",
"aceptem",
"acequie",
"acequio",
"acerais",
"acerale",
"aceramo",
"acerbai",
"acerbei",
"acerbem",
"acerbes",
"acereai",
"acerear",
"acereei",
"acereia",
"acereie",
"acereio",
"acereis",
"acerejo",
"aceremo",
"acereou",
"acerino",
"acerite",
"acervai",
"acervei",
"acervem",
"acerves",
"acesoai",
"acesoam",
"acesoar",
"acesoas",
"acesoei",
"acesoem",
"acesoes",
"acesone",
"acesoou",
"acestas",
"acesteu",
"acetamo",
"acetará",
"aceteis",
"acetemo",
"aceteno",
"acetias",
"acetile",
"acetine",
"acetito",
"acetola",
"acetole",
"acetolo",
"acetono",
"acetóis",
"acevade",
"acevais",
"acevamo",
"acevará",
"acevava",
"aceveis",
"acevemo",
"achacai",
"achadia",
"achagai",
"achagam",
"achagou",
"achague",
"achanai",
"achanam",
"achanas",
"achanci",
"achanei",
"achanem",
"achanto",
"achanze",
"achanzo",
"achanzá",
"acharco",
"achardo",
"acharoa",
"acharoo",
"achassa",
"achassi",
"achasso",
"achatai",
"achicai",
"achicas",
"achinai",
"achinam",
"achinas",
"achinei",
"achinem",
"achinou",
"achiota",
"achiris",
"achoais",
"achoamo",
"achoara",
"achoava",
"achocei",
"achocem",
"achoces",
"achoeis",
"achoemo",
"achouto",
"achoçai",
"achoçam",
"achoçar",
"achoças",
"achoçou",
"achucei",
"achucem",
"achuces",
"achumba",
"achumbe",
"achusme",
"achusmo",
"achuçai",
"achuçam",
"achuçar",
"achuças",
"achuçou",
"achânia",
"achãoze",
"achãozo",
"achãozá",
"acianto",
"acicala",
"acicale",
"acicalo",
"acicato",
"acicoca",
"acicule",
"aciculo",
"acidais",
"acidamo",
"acidara",
"acidava",
"acideis",
"acidemo",
"acidilo",
"acidoto",
"acidrai",
"acidram",
"acidras",
"acidrei",
"acidrem",
"acidres",
"acidóis",
"acieses",
"acifilo",
"acigane",
"acigano",
"acilais",
"acilamo",
"acilava",
"acileis",
"acilemo",
"acileno",
"acilepo",
"acilhai",
"acilham",
"acilhar",
"acilhas",
"acilhei",
"acilhem",
"acilhes",
"acilhou",
"acilias",
"acilrai",
"acilram",
"acilrar",
"acilras",
"acilrei",
"acilrem",
"acilres",
"acilrou",
"acimais",
"acimamo",
"acimava",
"acimeis",
"acimemo",
"acinges",
"acingia",
"acingir",
"acingis",
"acingiu",
"acinipe",
"acinjam",
"acinjas",
"acinopo",
"acintai",
"acintas",
"acintei",
"acintle",
"acinzai",
"acinzam",
"acinzei",
"acinzem",
"acinzes",
"acinzou",
"aciocás",
"aciotos",
"acirate",
"acirrai",
"acirras",
"acirres",
"acirsas",
"acisele",
"aciselo",
"aciselá",
"acisias",
"acismos",
"acistas",
"aciumai",
"aciumam",
"aciumas",
"aciumei",
"aciumem",
"aciumou",
"acizane",
"acizano",
"acizaná",
"acizela",
"acizele",
"aciário",
"acleias",
"aclidos",
"aclimai",
"aclimas",
"aclimei",
"aclimem",
"aclimes",
"aclinal",
"aclises",
"aclivai",
"aclivam",
"aclivas",
"aclivei",
"aclivem",
"aclivou",
"aclopes",
"aclopos",
"acládio",
"aclídeo",
"aclídia",
"aclínea",
"aclíneo",
"acmeias",
"acmelas",
"acmenas",
"acmitas",
"acmites",
"acmitos",
"acmônio",
"acnanto",
"acneias",
"acnemos",
"acneste",
"acnidas",
"acnides",
"acnisto",
"acnites",
"acnodos",
"acnosas",
"acnéria",
"acoador",
"acoadão",
"acoalhe",
"acoalhá",
"acoalte",
"acoamos",
"acoanho",
"acoanhá",
"acoante",
"acoarei",
"acoares",
"acoaria",
"acoarmo",
"acoarás",
"acoarão",
"acoasse",
"acoaste",
"acoavam",
"acoavas",
"acobreá",
"acocais",
"acocalã",
"acocamo",
"acocare",
"acocaro",
"acocava",
"acoceis",
"acocemo",
"acochai",
"acochem",
"acoches",
"acocore",
"acocoto",
"acoelas",
"acoelhe",
"acoemos",
"acofiai",
"acofiam",
"acofias",
"acofiei",
"acofiem",
"acofies",
"acofiou",
"acogula",
"acogule",
"acogulo",
"acoicei",
"acoicem",
"acoices",
"acoiceá",
"acoimai",
"acoimas",
"acoimei",
"acoimes",
"acoinai",
"acoinam",
"acoinar",
"acoinas",
"acoinei",
"acoinem",
"acoines",
"acoinol",
"acoinou",
"acoiçai",
"acoiçam",
"acoiças",
"acoiçou",
"acolais",
"acolalã",
"acolamo",
"acolará",
"acolava",
"acolaús",
"acoleis",
"acolemo",
"acolete",
"acoleto",
"acolins",
"acolune",
"acoluno",
"acomias",
"acomune",
"acomuno",
"aconape",
"aconapo",
"aconapá",
"aconato",
"aconcho",
"aconela",
"aconete",
"aconeto",
"aconetá",
"aconina",
"acontie",
"acontos",
"acoplai",
"acoposa",
"acopule",
"acopulo",
"acoquem",
"acoques",
"acorese",
"acorias",
"acormia",
"acormos",
"acornai",
"acornam",
"acornas",
"acornei",
"acornem",
"acornes",
"acorneá",
"acoroce",
"acoroço",
"acoroçá",
"acorras",
"acoruto",
"acorçoe",
"acorçoo",
"acosmas",
"acosmos",
"acosses",
"acostai",
"acostes",
"acotais",
"acotamo",
"acotará",
"acotava",
"acoteis",
"acotemo",
"acotiai",
"acotias",
"acotico",
"acotiei",
"acotiem",
"acoties",
"acotiou",
"acotoai",
"acotoam",
"acotoas",
"acotoei",
"acotoem",
"acotoes",
"acotona",
"acotone",
"acotono",
"acouber",
"acoucei",
"acoucem",
"acouces",
"acouceá",
"acoutem",
"acoutis",
"acouçai",
"acouçam",
"acouçar",
"acouças",
"acouçou",
"acovado",
"acovais",
"acovamo",
"acovará",
"acovate",
"acovato",
"acovatá",
"acovava",
"acoveis",
"acovemo",
"acoélio",
"acpalôs",
"acracne",
"acranta",
"acratos",
"acravai",
"acravam",
"acravas",
"acravei",
"acravem",
"acraves",
"acrecei",
"acreces",
"acrecia",
"acreias",
"acreçam",
"acreças",
"acrivai",
"acrivam",
"acrivas",
"acrivei",
"acrivem",
"acrivou",
"acriúlo",
"acroata",
"acroche",
"acrocho",
"acromas",
"acroses",
"acroíte",
"acroíto",
"acrídea",
"acrídia",
"acteias",
"actenas",
"acteões",
"actéana",
"actéano",
"actíaco",
"actídia",
"actígeo",
"actínon",
"acuaras",
"acuarei",
"acuares",
"acuarmo",
"acuaste",
"acuavas",
"acuchis",
"acucule",
"acuculo",
"acudias",
"acueras",
"acugula",
"acugule",
"acugulo",
"acuiari",
"aculeai",
"aculeei",
"aculeie",
"aculeio",
"acumbai",
"acumbam",
"acumbar",
"acumbas",
"acumbei",
"acumbem",
"acumbes",
"acumbou",
"acumeai",
"acumeei",
"acumeia",
"acumeie",
"acumeio",
"acumeou",
"acumino",
"acumões",
"acunhai",
"acunhes",
"acunheá",
"acurais",
"acuramo",
"acuraus",
"acurava",
"acurebe",
"acureis",
"acuremo",
"acuriós",
"acurrai",
"acurram",
"acurras",
"acurrei",
"acurrem",
"acurres",
"acurrou",
"acursai",
"acursam",
"acursas",
"acursei",
"acursem",
"acurses",
"acursou",
"acurtai",
"acurtam",
"acurtas",
"acurtei",
"acurtem",
"acurtes",
"acurtou",
"acuruís",
"acurvai",
"acurvas",
"acurvem",
"acurves",
"acusias",
"acustos",
"acutele",
"acutelo",
"acutelá",
"acutiai",
"acutiam",
"acutiar",
"acutias",
"acutiei",
"acutiem",
"acuties",
"acutile",
"acutilo",
"acutiou",
"acutize",
"acutizo",
"acutizá",
"acuubas",
"acácica",
"acárido",
"acântus",
"acíngem",
"acômico",
"acúleas",
"acúmens",
"adagiai",
"adagiam",
"adagiei",
"adagiem",
"adagiou",
"adagues",
"adaiado",
"adaiões",
"adamais",
"adamamo",
"adameis",
"adamemo",
"adamona",
"adanado",
"adanamo",
"adanará",
"adanava",
"adandus",
"adaneis",
"adanemo",
"adarbes",
"adarces",
"adargai",
"adargam",
"adargou",
"adargue",
"adarvai",
"adarvam",
"adarvas",
"adarvei",
"adarvem",
"adarvou",
"adectas",
"adectos",
"adefora",
"adegais",
"adegara",
"adegava",
"adeguei",
"adegues",
"adeiina",
"adeitos",
"adejais",
"adejamo",
"adejeis",
"adejemo",
"adeleia",
"adelgai",
"adelgou",
"adelgue",
"adelhas",
"adelope",
"adelzão",
"adengai",
"adengam",
"adengas",
"adengou",
"adenope",
"adenoso",
"adenoto",
"adensei",
"adenses",
"adentai",
"adentei",
"adeorbe",
"aderada",
"aderais",
"aderamo",
"aderará",
"aderava",
"adereis",
"aderemo",
"adergai",
"adergam",
"adergue",
"adernai",
"adernas",
"adernem",
"adernes",
"adesona",
"adesore",
"adesoro",
"adesorá",
"adesóis",
"adeusam",
"adeusei",
"adevões",
"adeínas",
"adeísta",
"adforme",
"adformo",
"adformá",
"adiadão",
"adianas",
"adibais",
"adibate",
"adicamo",
"adicava",
"adidona",
"adidora",
"adidões",
"adietai",
"adietam",
"adietas",
"adietei",
"adietem",
"adietes",
"adietou",
"adilada",
"adilado",
"adilais",
"adilamo",
"adilava",
"adilemo",
"adilzão",
"adimplo",
"adipita",
"adipite",
"adipsos",
"adipóis",
"adiquem",
"adirajá",
"adiramo",
"adirdes",
"adireis",
"adiremo",
"adiriam",
"adirias",
"adirmos",
"adiscal",
"adissem",
"adisses",
"adistes",
"aditais",
"aditamo",
"aditemo",
"adivais",
"adivira",
"adiçona",
"adjazam",
"adjazas",
"adjazei",
"adjazes",
"adjazeu",
"adjazia",
"adjeres",
"adjigar",
"adjonge",
"adjudai",
"adjudam",
"adjudas",
"adjudei",
"adjudem",
"adjudes",
"adjudou",
"adjugar",
"adjungi",
"adjunja",
"adjunjo",
"adjurai",
"adjuras",
"adjurei",
"adjurem",
"adjuvai",
"adjuvam",
"adjuvas",
"adjuvei",
"adjuvem",
"adjuvou",
"adligai",
"adligam",
"adligas",
"adligue",
"adléria",
"adlúmio",
"admonam",
"admonia",
"admonir",
"admonis",
"admoniu",
"adnexos",
"adnomes",
"adnotai",
"adnotam",
"adnotas",
"adnotei",
"adnotem",
"adnotes",
"adnotou",
"adoador",
"adoadão",
"adoante",
"adoarei",
"adoares",
"adoarmo",
"adoarás",
"adoarão",
"adoasse",
"adoaste",
"adoavam",
"adoavas",
"adobais",
"adobamo",
"adobará",
"adobava",
"adobeis",
"adobemo",
"adoceis",
"adocemo",
"adoemos",
"adoento",
"adoesta",
"adoeste",
"adoesto",
"adoidai",
"adoidam",
"adoidei",
"adoidem",
"adoides",
"adoleia",
"adolins",
"adolora",
"adolore",
"adoloro",
"adomada",
"adomais",
"adomamo",
"adomará",
"adomeis",
"adomemo",
"adonamo",
"adoneis",
"adonemo",
"adonisa",
"adonise",
"adoniso",
"adonita",
"adopere",
"adopero",
"adoreto",
"adoriás",
"adormem",
"adormes",
"adormia",
"adormis",
"adormiu",
"adortai",
"adortas",
"adortem",
"adortes",
"adortou",
"adotelo",
"adoudai",
"adoudam",
"adoudas",
"adoudei",
"adoudem",
"adoudes",
"adoudou",
"adoçais",
"adoçamo",
"adoçona",
"adragai",
"adragam",
"adragar",
"adragas",
"adragos",
"adragou",
"adrague",
"adregai",
"adreira",
"adricei",
"adrices",
"adrinas",
"adriçai",
"adriçam",
"adriçou",
"adrípia",
"adsires",
"adsorvi",
"aduadas",
"aduador",
"aduados",
"aduadão",
"aduamos",
"aduanai",
"aduanam",
"aduando",
"aduanem",
"aduanes",
"aduanou",
"aduante",
"aduaram",
"aduarei",
"aduarem",
"aduaria",
"aduarmo",
"aduarás",
"aduarão",
"aduasse",
"aduaste",
"aduavam",
"aduavas",
"adubais",
"adubamo",
"adubemo",
"adubiai",
"adubiam",
"adubiar",
"adubias",
"adubiei",
"adubiem",
"adubies",
"adubiou",
"aduchai",
"aducham",
"aduchei",
"aduchem",
"aduches",
"aduchou",
"aduciam",
"aducias",
"aducida",
"aducimo",
"aducira",
"adueira",
"aduemos",
"adufais",
"adufamo",
"adufará",
"adufava",
"adufeis",
"adufemo",
"adulamo",
"aduleis",
"adulemo",
"adulosa",
"adumada",
"adumado",
"adumais",
"adumamo",
"adumará",
"adumava",
"adumbre",
"adumeis",
"adumemo",
"adumere",
"adumero",
"adumerá",
"adunais",
"adunamo",
"aduncai",
"aduncam",
"aduncou",
"aduneis",
"adunemo",
"adurada",
"adurado",
"adurais",
"adurará",
"adureis",
"aduremo",
"aduriam",
"adurias",
"adurida",
"adurido",
"adurimo",
"adurirá",
"adurmam",
"adurmas",
"aduróis",
"adussia",
"adustez",
"aduzais",
"aduzamo",
"aduzias",
"aduzimo",
"aduçais",
"aduçamo",
"aduçona",
"adverbá",
"advidor",
"advidão",
"advinde",
"advirei",
"advires",
"advirás",
"advocai",
"advocas",
"advulte",
"advulto",
"advultá",
"adábias",
"adápide",
"adárige",
"adátila",
"adâmeas",
"adâmeos",
"adâmias",
"adéfaga",
"adélida",
"adênica",
"adênios",
"adínama",
"adínole",
"adípica",
"adísios",
"adítias",
"adólias",
"adólios",
"adórios",
"adôneos",
"adônida",
"adônide",
"adônido",
"adônios",
"adúbios",
"adúlica",
"adúlico",
"aedíneo",
"aeranta",
"aeranto",
"aeriano",
"aeridra",
"aeridro",
"aeritas",
"aerizai",
"aerizam",
"aerizas",
"aerizei",
"aerizem",
"aerizes",
"aerizou",
"aeromel",
"aerária",
"aerínea",
"aeríneo",
"aetitas",
"aetitos",
"aetomas",
"afabais",
"afabule",
"afabulo",
"afacias",
"afagais",
"afagias",
"afagosa",
"afagueá",
"afaimai",
"afaimam",
"afaimas",
"afaimei",
"afaimem",
"afaimes",
"afaimou",
"afainai",
"afainas",
"afainei",
"afainem",
"afaines",
"afalada",
"afalais",
"afalamo",
"afalará",
"afalcoa",
"afalcoe",
"afalcoo",
"afaleis",
"afalemo",
"afamais",
"afamamo",
"afamana",
"afamane",
"afamano",
"afamava",
"afameis",
"afamemo",
"afanamo",
"afancai",
"afancam",
"afancas",
"afancou",
"afanemo",
"afanfai",
"afanfam",
"afanfar",
"afanfas",
"afanfei",
"afanfem",
"afanfes",
"afanfou",
"afangai",
"afangam",
"afangar",
"afangas",
"afangou",
"afanita",
"afanite",
"afanopo",
"afanque",
"afaqueá",
"afarada",
"afarado",
"afarais",
"afaramo",
"afarara",
"afarava",
"afareis",
"afarele",
"afarelo",
"afarelá",
"afaremo",
"afarole",
"afarolo",
"afarolá",
"afarvai",
"afarvam",
"afarvar",
"afarvas",
"afarvei",
"afarvem",
"afarves",
"afarvou",
"afatiai",
"afatiam",
"afatias",
"afatiei",
"afatiem",
"afaties",
"afatiou",
"afaveco",
"afaxine",
"afaxino",
"afazeis",
"afaziam",
"afaçais",
"afeadão",
"afeamos",
"afearam",
"afearei",
"afearem",
"afeares",
"afearia",
"afearás",
"afearão",
"afeasse",
"afeaste",
"afeavas",
"afeemos",
"afegada",
"afegado",
"afegais",
"afegamo",
"afegará",
"afeiada",
"afeiado",
"afeiais",
"afeiamo",
"afeiará",
"afeiava",
"afeieis",
"afeiemo",
"afeijoa",
"afeijoe",
"afeijoo",
"afeitai",
"afeitem",
"afeiteá",
"afeleai",
"afeleei",
"afeleie",
"afeleio",
"afemeai",
"afemeei",
"afemeia",
"afemeie",
"afemeou",
"afemias",
"afemine",
"afemino",
"aferada",
"aferado",
"aferais",
"aferamo",
"aferará",
"aferava",
"afereis",
"aferemo",
"aferias",
"aferide",
"aferimo",
"aferrai",
"aferroo",
"afervai",
"afervam",
"afervas",
"afervei",
"afervem",
"aferves",
"afervou",
"afesias",
"afestoa",
"afestoe",
"afestoo",
"afetemo",
"afezoai",
"afezoam",
"afezoar",
"afezoas",
"afezoei",
"afezoem",
"afezoes",
"afezoou",
"afeável",
"afeçona",
"afganis",
"afiadão",
"afiaras",
"afiarmo",
"afiavas",
"aficais",
"aficamo",
"aficará",
"afifada",
"afifado",
"afifais",
"afifamo",
"afifana",
"afifano",
"afifará",
"afifava",
"afifeis",
"afifemo",
"afigada",
"afilais",
"afilamo",
"afileis",
"afilemo",
"afilhai",
"afilham",
"afilhei",
"afilhem",
"afilhes",
"afiliai",
"afilies",
"afincai",
"afineis",
"afinemo",
"afinfai",
"afinfam",
"afinfes",
"afinges",
"afingia",
"afingis",
"afingiu",
"afinhai",
"afinham",
"afinhar",
"afinhas",
"afinhei",
"afinhem",
"afinhes",
"afinhou",
"afinjam",
"afinjas",
"afiquei",
"afiquem",
"afiques",
"afirais",
"afiramo",
"afitais",
"afitamo",
"afitara",
"afitava",
"afiteis",
"afitemo",
"afiuzai",
"afiuzam",
"afiuzas",
"afiuzei",
"afiuzem",
"afiuzes",
"afiuzou",
"afixamo",
"afixeis",
"afixemo",
"afixiva",
"afixivo",
"afladas",
"aflador",
"aflados",
"afladão",
"aflamos",
"aflarei",
"aflarem",
"aflares",
"aflaria",
"aflarmo",
"aflarás",
"aflarão",
"aflasse",
"aflaste",
"aflaute",
"aflauto",
"aflavas",
"afleche",
"afleias",
"afleime",
"afleimo",
"afleuma",
"afleume",
"afleumo",
"afloixe",
"afloixo",
"afloixá",
"aflorai",
"afloxai",
"afloxam",
"afloxar",
"afloxas",
"afloxei",
"afloxem",
"afloxes",
"afloxou",
"afluais",
"afluamo",
"afluías",
"afluímo",
"afobais",
"afobamo",
"afobara",
"afobeis",
"afobemo",
"afocada",
"afocado",
"afocais",
"afocamo",
"afocará",
"afocava",
"afodais",
"afofais",
"afofamo",
"afofeis",
"afofemo",
"afofiês",
"afogueá",
"afoicei",
"afoicem",
"afoices",
"afoitai",
"afoiçai",
"afoiçam",
"afoiçar",
"afoiças",
"afoiçou",
"afolais",
"afolamo",
"afolará",
"afolava",
"afoleis",
"afolemo",
"afolhai",
"afolham",
"afolhei",
"afolhem",
"afolhes",
"afolheá",
"afoloza",
"afoloze",
"afolozo",
"afomeai",
"afomeei",
"afomeia",
"afomeie",
"afomeio",
"afomeou",
"afonese",
"afonina",
"afonjás",
"afonsas",
"afoquem",
"afoques",
"aforamo",
"aforcei",
"aforcem",
"aforces",
"aforcie",
"aforcio",
"aforciá",
"aforeis",
"aforemo",
"aformai",
"aformei",
"aformem",
"aformes",
"aforrai",
"aforres",
"aforçai",
"aforçam",
"aforçou",
"afosias",
"afoucei",
"afoucem",
"afouces",
"afoutai",
"afoutei",
"afoutem",
"afoutes",
"afoutou",
"afouçai",
"afouçam",
"afouçar",
"afouças",
"afouçou",
"afracai",
"afracam",
"afracas",
"afracou",
"afragar",
"afraque",
"afrecha",
"afreche",
"afreime",
"afreimo",
"afrento",
"afretai",
"afretas",
"afretei",
"afretem",
"afretes",
"africai",
"africou",
"afridol",
"afriges",
"afrigir",
"afrigis",
"afrigiu",
"afrijam",
"afrijas",
"afritas",
"afrodas",
"afrodos",
"afroixa",
"afroixe",
"afroixo",
"afronhe",
"afronho",
"afronhá",
"afrutai",
"afrutam",
"afrutas",
"afrutei",
"afrutem",
"afrutes",
"aftitos",
"aftoses",
"afulvai",
"afulvam",
"afulvar",
"afulvas",
"afulvei",
"afulvem",
"afulves",
"afulvou",
"afumais",
"afumamo",
"afumará",
"afumeai",
"afumeei",
"afumega",
"afumego",
"afumeia",
"afumeie",
"afumeio",
"afumeis",
"afumemo",
"afumeou",
"afunada",
"afunais",
"afunamo",
"afunará",
"afundeá",
"afundis",
"afuneis",
"afunemo",
"afuroai",
"afuroam",
"afuroas",
"afuroei",
"afuroem",
"afuroes",
"afuroou",
"afusamo",
"afusara",
"afusava",
"afuseis",
"afusele",
"afuselo",
"afuselá",
"afusemo",
"afusona",
"afustai",
"afustam",
"afustar",
"afustas",
"afustei",
"afustem",
"afustes",
"afustou",
"afustue",
"afustuo",
"afustuá",
"afuzile",
"afuzilo",
"afágica",
"afática",
"afânica",
"afêmica",
"afídeas",
"afínica",
"afódios",
"afórica",
"agabada",
"agabado",
"agabais",
"agabamo",
"agabará",
"agabava",
"agabeis",
"agabelo",
"agabemo",
"agabena",
"agabeno",
"agabões",
"agadada",
"agadado",
"agadina",
"agadona",
"agadora",
"agadões",
"agafelo",
"agafito",
"agaiada",
"agaiado",
"agaiais",
"agaiamo",
"agaiará",
"agaiate",
"agaiato",
"agaiatá",
"agaiava",
"agaieis",
"agaiemo",
"agaiole",
"agaiolo",
"agaitai",
"agaitam",
"agaitas",
"agaitei",
"agaitem",
"agaites",
"agaitou",
"agajada",
"agajado",
"agajais",
"agajamo",
"agajará",
"agajava",
"agajeis",
"agajemo",
"agalada",
"agalado",
"agalais",
"agalamo",
"agalana",
"agalane",
"agalano",
"agalari",
"agalará",
"agalava",
"agalego",
"agaleis",
"agalemo",
"agalena",
"agalgai",
"agalgam",
"agalgas",
"agalgue",
"agalhai",
"agalham",
"agalhei",
"agalhem",
"agalhes",
"agalhou",
"agalime",
"agalimo",
"agalimá",
"agalita",
"agalite",
"agalmas",
"agaloai",
"agaloas",
"agaloei",
"agaloem",
"agaloes",
"agaloou",
"agalopo",
"agalopá",
"agamias",
"agamita",
"aganada",
"aganado",
"aganais",
"aganamo",
"aganava",
"aganeai",
"aganear",
"aganeei",
"aganeia",
"aganeie",
"aganeio",
"aganeis",
"aganemo",
"aganeou",
"aganide",
"aganjus",
"agantai",
"agantam",
"agantar",
"agantas",
"agantei",
"agantem",
"agantou",
"aganões",
"agarani",
"agardes",
"agarejo",
"agarime",
"agarimá",
"agarmos",
"agarnel",
"agarote",
"agaruns",
"agarçal",
"agaróis",
"agassem",
"agasses",
"agastai",
"agastra",
"agatais",
"agateia",
"agatize",
"agatizo",
"agatizá",
"agatune",
"agatuno",
"agatuná",
"agavela",
"agavele",
"agavelo",
"agavete",
"agaveto",
"agavios",
"agavosa",
"agazada",
"agazado",
"agazais",
"agazamo",
"agazava",
"agazeis",
"agazemo",
"agazuai",
"agazuam",
"agazuar",
"agazuas",
"agazuei",
"agazuem",
"agazues",
"agazule",
"agazulo",
"agazulá",
"agazuou",
"agaítos",
"agaúche",
"agedras",
"agelaio",
"ageleus",
"agemada",
"agerais",
"ageramo",
"agerará",
"agerata",
"agerava",
"agereis",
"ageremo",
"agervão",
"agestai",
"agestam",
"agestar",
"agestas",
"agestei",
"agestem",
"agestos",
"agestou",
"ageusas",
"ageusos",
"ageusta",
"ageusto",
"agiares",
"agiasmo",
"agidona",
"agidora",
"agidões",
"agilito",
"aginias",
"agiotei",
"agiotes",
"agiotou",
"agiremo",
"agirina",
"agirono",
"agironá",
"agitemo",
"aglaopo",
"aglenas",
"aglenos",
"agmares",
"agminal",
"agmínea",
"agmíneo",
"agnadas",
"agnanto",
"agninas",
"agninos",
"agnoeta",
"agnoias",
"agnoíta",
"agnínio",
"agoguês",
"agoirai",
"agoiras",
"agoirei",
"agolpeá",
"agomada",
"agomamo",
"agomara",
"agomava",
"agomeis",
"agomemo",
"agomias",
"agomile",
"agomilo",
"agomilá",
"agomine",
"agomino",
"agominá",
"agomita",
"agomite",
"agomito",
"agonfas",
"agonfos",
"agoniai",
"agorins",
"agostai",
"agostes",
"agotais",
"agotamo",
"agotará",
"agotava",
"agoteis",
"agotemo",
"agradue",
"agraduo",
"agrafai",
"agramos",
"agranai",
"agranam",
"agranei",
"agranem",
"agranes",
"agranou",
"agrapim",
"agrarei",
"agrarem",
"agrares",
"agrarmo",
"agrarão",
"agravai",
"agravie",
"agraúda",
"agraúde",
"agraúdo",
"agreado",
"agreais",
"agreará",
"agreava",
"agredas",
"agredes",
"agreeis",
"agreiam",
"agreias",
"agreiem",
"agreies",
"agrelai",
"agrelam",
"agrelar",
"agrelei",
"agrelem",
"agreles",
"agrelou",
"agremio",
"agremos",
"agriais",
"agricei",
"agricem",
"agrices",
"agrifai",
"agrifar",
"agrifas",
"agrifei",
"agrifem",
"agrifes",
"agrifou",
"agrilai",
"agrilam",
"agrilar",
"agrilas",
"agrilei",
"agrilem",
"agriles",
"agrilha",
"agrilhe",
"agrilho",
"agrilou",
"agrimai",
"agrimam",
"agrimei",
"agrimem",
"agrimes",
"agrimou",
"agriope",
"agriopo",
"agrioto",
"agriçai",
"agriçam",
"agriçar",
"agriças",
"agriçou",
"agrores",
"agrosas",
"agrosos",
"agrumai",
"agrumas",
"agrumei",
"agrumou",
"agrupai",
"agrupes",
"aguacei",
"aguacem",
"aguaces",
"aguache",
"aguacho",
"aguadiá",
"aguains",
"aguamãi",
"aguapás",
"aguarmo",
"aguavas",
"aguaxai",
"aguaxam",
"aguaxar",
"aguaxas",
"aguaxei",
"aguaxem",
"aguaxes",
"aguaxou",
"aguaçai",
"aguaçam",
"aguaças",
"aguaçus",
"aguceis",
"agucemo",
"agudais",
"agudamo",
"agudará",
"agudeci",
"agudeis",
"agudemo",
"agudeça",
"agudeço",
"agudizo",
"agudião",
"agueire",
"aguiche",
"aguicho",
"aguichá",
"aguilhe",
"aguinam",
"aguinem",
"aguiniu",
"aguisai",
"aguisam",
"aguisas",
"aguisei",
"aguisem",
"aguises",
"aguisou",
"aguiões",
"agulhai",
"agulhem",
"agulhes",
"agulheá",
"agurina",
"aguçamo",
"agábios",
"agácias",
"agágias",
"agágios",
"agálias",
"agálica",
"agálico",
"agálito",
"agárdia",
"agáreas",
"agáreis",
"agáreos",
"agárice",
"agáteas",
"agáteos",
"agática",
"agátide",
"agáveis",
"agâmeta",
"agâmeto",
"agâmida",
"agédula",
"agêmate",
"agênios",
"agíneas",
"agínica",
"agínico",
"aiacaná",
"aiadona",
"aiadora",
"aiadões",
"aiantes",
"aiapuás",
"aiareba",
"aiaremo",
"aiariam",
"aiarias",
"aiassem",
"aiasses",
"aiastes",
"aicunas",
"aidoíte",
"aigotos",
"aijubas",
"aijunas",
"aililas",
"aimurus",
"ainhuns",
"aiodina",
"aipiris",
"airador",
"airadão",
"airamos",
"airante",
"airaram",
"airaras",
"airarei",
"airarem",
"airares",
"airaria",
"airarmo",
"airarão",
"airasse",
"airaste",
"airavas",
"airemos",
"airinis",
"airiris",
"airobas",
"airogas",
"airopse",
"airutes",
"aislais",
"aislamo",
"aislará",
"aislava",
"aisleis",
"aislemo",
"aissana",
"aitacás",
"aitonas",
"aitônia",
"aiuabas",
"aiumará",
"aiunada",
"aiunado",
"aiunais",
"aiunamo",
"aiunará",
"aiunava",
"aiuneis",
"aiunemo",
"aivacas",
"aizóons",
"aiáreis",
"aiáveis",
"aiãozão",
"aiênias",
"ajaezai",
"ajaezei",
"ajaezem",
"ajaezes",
"ajaezou",
"ajambra",
"ajambro",
"ajanota",
"ajanote",
"ajanoto",
"ajapone",
"ajapono",
"ajaponá",
"ajaraís",
"ajaulai",
"ajaulam",
"ajaular",
"ajaulas",
"ajaulei",
"ajaulem",
"ajaules",
"ajaulou",
"ajedras",
"ajeirai",
"ajeiram",
"ajeiras",
"ajeirei",
"ajeirem",
"ajeires",
"ajeitai",
"ajetive",
"ajicuba",
"ajoeira",
"ajoeire",
"ajoeiro",
"ajoiada",
"ajoiado",
"ajoiais",
"ajoiará",
"ajoiava",
"ajoieis",
"ajolzão",
"ajorcai",
"ajorcam",
"ajorcas",
"ajorcou",
"ajorjai",
"ajorjam",
"ajorjas",
"ajorjem",
"ajorjes",
"ajorjou",
"ajorque",
"ajoujai",
"ajoujas",
"ajoujei",
"ajoujem",
"ajoujes",
"ajudeai",
"ajudear",
"ajudeie",
"ajujuré",
"ajulada",
"ajulais",
"ajulamo",
"ajulará",
"ajulava",
"ajuleis",
"ajulemo",
"ajururé",
"ajuruís",
"ajôveas",
"alabais",
"alabamo",
"alabaro",
"alabava",
"alabeis",
"alabemo",
"alabiai",
"alabiam",
"alabiar",
"alabias",
"alabiei",
"alabiem",
"alabies",
"alabiou",
"alabões",
"alacada",
"alacado",
"alacaie",
"alacaio",
"alacais",
"alacaiá",
"alacamo",
"alacara",
"alacava",
"alacram",
"alacrei",
"alacrem",
"alacreá",
"alacrou",
"alactai",
"alactam",
"alactas",
"alactei",
"alactem",
"alactes",
"alactou",
"aladona",
"aladroe",
"aladroo",
"aladões",
"alaelas",
"alafias",
"alagais",
"alagamo",
"alagita",
"alagite",
"alagito",
"alagoai",
"alagoar",
"alagoei",
"alagoem",
"alagoes",
"alagosa",
"alagune",
"alaguno",
"alajada",
"alajado",
"alajais",
"alajamo",
"alajará",
"alajava",
"alajeis",
"alajemo",
"alalzão",
"alamais",
"alambas",
"alamias",
"alamins",
"alampai",
"alampam",
"alampas",
"alampei",
"alampem",
"alampes",
"alampou",
"alamutu",
"alancai",
"alancam",
"alancos",
"alanhai",
"alanham",
"alanhas",
"alanhei",
"alanhem",
"alanhes",
"alanhou",
"alanito",
"alanque",
"alantas",
"alanzoe",
"alanzoo",
"alapais",
"alapamo",
"alapará",
"alapeis",
"alapemo",
"alapoai",
"alapoam",
"alapoas",
"alapoei",
"alapoem",
"alapoes",
"alapoou",
"alaptes",
"alaptos",
"alapuze",
"alapuzo",
"alapuzá",
"alaquei",
"alaqueá",
"alarada",
"alarado",
"alarais",
"alaramo",
"alarará",
"alarava",
"alardai",
"alardas",
"alareis",
"alaremo",
"alarias",
"alarmai",
"alarosa",
"alaroso",
"alarpai",
"alarpam",
"alarpas",
"alarpei",
"alarpem",
"alarpes",
"alarpou",
"alaruás",
"alassai",
"alassam",
"alassas",
"alassei",
"alassem",
"alasses",
"alassou",
"alastes",
"alatita",
"alatite",
"alatito",
"alativa",
"alatoai",
"alatoam",
"alatoas",
"alatoei",
"alatoem",
"alatoes",
"alatoou",
"alauate",
"alaudai",
"alaudam",
"alaudar",
"alaudei",
"alaudou",
"alaures",
"alavela",
"alavete",
"alazada",
"alazado",
"alazamo",
"alazará",
"alazava",
"alazeis",
"alazemo",
"alaçona",
"alaçora",
"albalãs",
"albaraz",
"albarre",
"albarro",
"albarás",
"albatos",
"albação",
"albedém",
"albenos",
"albenoz",
"albeoge",
"albeogo",
"alberco",
"albesca",
"albesco",
"albidai",
"albidam",
"albidar",
"albidei",
"albidem",
"albidou",
"albiona",
"albitas",
"albitos",
"albizos",
"albocai",
"albocam",
"albocar",
"albocas",
"albocou",
"alboras",
"alborca",
"albucas",
"albucor",
"albucos",
"albufar",
"albugos",
"alburne",
"albário",
"albúnea",
"albúnia",
"alcabró",
"alcabós",
"alcaima",
"alcaldá",
"alcanas",
"alcateá",
"alcaxas",
"alcazes",
"alceais",
"alceará",
"alceava",
"alcedas",
"alcedos",
"alceeis",
"alceiam",
"alceias",
"alceiem",
"alceies",
"alceira",
"alceiro",
"alcenol",
"alcepão",
"alcinas",
"alcloxa",
"alcoato",
"alcocre",
"alcofai",
"alcofam",
"alcofei",
"alcofem",
"alcofes",
"alcofou",
"alcoile",
"alcopes",
"alcopos",
"alcovez",
"alcovês",
"alcoína",
"alcurse",
"alcurso",
"alcursá",
"alcuzas",
"alcácei",
"alcáces",
"alcáceu",
"alcádea",
"alcáfar",
"alcáfer",
"aldeago",
"aldeará",
"aldeava",
"aldebul",
"aldeeis",
"aldegai",
"aldegam",
"aldegas",
"aldegou",
"aldeiem",
"aldeies",
"aldeote",
"aldermã",
"aldioxa",
"aldrave",
"aldravo",
"aldruba",
"aldrube",
"aleadão",
"alealda",
"alealde",
"aleamos",
"aleante",
"alearam",
"alearas",
"alearei",
"alearem",
"aleares",
"alearão",
"aleasse",
"aleaste",
"aleavam",
"aleavas",
"alectos",
"aledada",
"aledado",
"aledais",
"aledamo",
"aledará",
"aledava",
"aledeis",
"aledemo",
"aleemos",
"alegamo",
"aleguás",
"aleijai",
"aleiloa",
"aleiloe",
"aleiloo",
"aleiona",
"aleirai",
"aleiram",
"aleirei",
"aleirem",
"aleirou",
"aleites",
"aleitoe",
"aleitoo",
"aleitoá",
"aleluio",
"alembai",
"alembam",
"alembar",
"alembas",
"alembei",
"alembem",
"alembes",
"alembou",
"alemoai",
"alemoam",
"alemoei",
"alemoem",
"alencós",
"alengas",
"aleonai",
"aleonam",
"aleonar",
"aleonas",
"aleonei",
"aleonem",
"aleones",
"aleonou",
"aleoses",
"alepiro",
"alerdai",
"alerdar",
"alerdas",
"alerdei",
"alerdem",
"alerdes",
"alerdou",
"alereão",
"alerteá",
"alesais",
"alesamo",
"alesara",
"alesava",
"aleseis",
"alesemo",
"alesmai",
"alesmam",
"alesmar",
"alesmas",
"alesmei",
"alesmem",
"alesmes",
"alesmou",
"alestai",
"alestam",
"alestei",
"alestem",
"aletina",
"aletino",
"aletrai",
"aletram",
"aletrei",
"aletrem",
"aletres",
"aleveda",
"alevede",
"alevedo",
"alfacos",
"alfadas",
"alfador",
"alfados",
"alfadão",
"alfaiai",
"alfaiam",
"alfaiei",
"alfaiem",
"alfaies",
"alfaifa",
"alfamia",
"alfamos",
"alfanai",
"alfanam",
"alfanar",
"alfanas",
"alfando",
"alfanei",
"alfanem",
"alfanes",
"alfanjo",
"alfanjá",
"alfanou",
"alfante",
"alfaram",
"alfarei",
"alfarmo",
"alfarão",
"alfarém",
"alfasse",
"alfaste",
"alfavam",
"alfavas",
"alfazar",
"alfaças",
"alfeces",
"alfeias",
"alfemos",
"alfenai",
"alfenam",
"alfenei",
"alfenem",
"alfenou",
"alfeque",
"alferro",
"alfesso",
"alfeças",
"alfires",
"alfolas",
"alforas",
"alforna",
"alforre",
"alforro",
"alforza",
"alfoses",
"alfouve",
"alfovas",
"alfujas",
"alfujes",
"alfuras",
"alfures",
"alfácia",
"algalio",
"algamia",
"algarie",
"algario",
"algazas",
"algazus",
"algaças",
"algaços",
"algedis",
"algedos",
"algelas",
"algemie",
"algemio",
"algeres",
"algiras",
"algiroz",
"algodoe",
"algoios",
"algomia",
"algoras",
"algores",
"algovão",
"algozai",
"algozam",
"algozas",
"algozei",
"algozem",
"algozou",
"algrame",
"algreme",
"alguais",
"alguide",
"alguido",
"algumia",
"algumim",
"alhages",
"alhaima",
"alhalme",
"alhanai",
"alhanam",
"alhanas",
"alhanei",
"alhanem",
"alhanes",
"alhanou",
"alharca",
"alheeis",
"alheemo",
"alheies",
"alhelas",
"alhetes",
"alhetos",
"alhodra",
"alhoras",
"alhores",
"alhácea",
"alháceo",
"alhágea",
"aliadai",
"aliadam",
"aliadar",
"aliadei",
"aliadem",
"aliades",
"aliador",
"aliadou",
"aliadão",
"aliavas",
"aliaxés",
"alibana",
"alicais",
"aliciai",
"alicuís",
"alidora",
"aligais",
"aligará",
"aligava",
"aliinas",
"alijais",
"alijamo",
"alijeis",
"alilena",
"alileno",
"alileto",
"alilina",
"alilzão",
"alimpes",
"alindas",
"alindem",
"alineai",
"alineei",
"alineie",
"alineio",
"alineou",
"alinfia",
"alinita",
"alinite",
"alioses",
"alipata",
"alipina",
"alipite",
"alipito",
"aliptas",
"aliptos",
"alisamo",
"aliseis",
"alisemo",
"aliseus",
"alismas",
"alismos",
"alitere",
"alitize",
"alitizo",
"alitizá",
"alixada",
"alizita",
"alizito",
"aliínia",
"aliínio",
"aliónia",
"aliósia",
"aljamie",
"aljamio",
"aljarze",
"aljavai",
"aljavam",
"aljavar",
"aljavei",
"aljavem",
"aljavou",
"aljofro",
"aljorge",
"aljorxe",
"aljorze",
"aljubas",
"aljuzes",
"aljôfai",
"aljôfam",
"aljôfas",
"aljôfei",
"aljôfem",
"aljôfes",
"aljôfou",
"almafas",
"almalas",
"almamil",
"almamis",
"almareá",
"almaças",
"almeces",
"almejai",
"almexar",
"almezas",
"almezes",
"almices",
"almixar",
"almoala",
"almoede",
"almudai",
"almudam",
"almudas",
"almudei",
"almudem",
"almudou",
"almugem",
"almuzes",
"alnedos",
"alneíta",
"alneíto",
"alnites",
"alocamo",
"alodapa",
"alofila",
"alofita",
"alofite",
"alogias",
"aloicas",
"aloicos",
"aloirai",
"aloiras",
"aloirem",
"aloires",
"aloisai",
"aloisam",
"aloisas",
"aloisei",
"aloisem",
"aloisol",
"aloisou",
"aloitai",
"aloitam",
"aloitas",
"aloitei",
"aloitem",
"aloitou",
"alojeis",
"alojemo",
"alombai",
"alombas",
"alombem",
"alonsas",
"aloprai",
"alopres",
"aloptas",
"aloquia",
"alorpai",
"alorpar",
"alorpas",
"alorpei",
"alorpem",
"alorpes",
"alorpou",
"alosnas",
"alotada",
"alotais",
"alotamo",
"alotará",
"aloteis",
"alotemo",
"alotone",
"aloucai",
"aloucam",
"aloucou",
"alourai",
"alouras",
"aloures",
"alousai",
"alousam",
"alousas",
"alousei",
"alousem",
"alouses",
"alousou",
"alovuco",
"aloínea",
"aloítos",
"alpacos",
"alpagas",
"alpaque",
"alpeces",
"alpedos",
"alpinai",
"alpinam",
"alpinei",
"alpinem",
"alpinol",
"alpinou",
"alpisto",
"alporco",
"alprons",
"alquiai",
"alquiam",
"alquias",
"alquiei",
"alquies",
"alquine",
"alquiná",
"alquiou",
"alretes",
"alriota",
"alrotai",
"alrotam",
"alrotas",
"alrotei",
"alrotem",
"alrotes",
"alrotou",
"alrunes",
"alrutes",
"alsafãs",
"alsinos",
"alsonas",
"alsídia",
"alsínea",
"alsódea",
"altanai",
"altanam",
"altanas",
"altaneá",
"altanou",
"altanto",
"altarum",
"altaruz",
"altaréu",
"alteais",
"alteamo",
"alteeis",
"alteemo",
"alteies",
"altinco",
"altincá",
"altisas",
"altivai",
"altivei",
"altivem",
"aluador",
"aluadão",
"aluamos",
"aluante",
"aluaria",
"aluarmo",
"aluarás",
"aluarão",
"aluasse",
"aluaste",
"aluatas",
"aluatos",
"aluavas",
"aludais",
"aludamo",
"aludemo",
"aludéis",
"alueira",
"alueiro",
"aluemos",
"alufada",
"aluidão",
"aluirei",
"aluirás",
"aluirão",
"alumada",
"alumado",
"alumamo",
"alumava",
"alumeis",
"alumemo",
"alumnel",
"alumnol",
"alunais",
"alunamo",
"alunará",
"alunava",
"aluneis",
"alunemo",
"alunize",
"alunizo",
"alunóis",
"alupada",
"alupado",
"alupais",
"alupamo",
"alupará",
"alupava",
"alupeis",
"alupemo",
"alurnes",
"alurnos",
"alusona",
"alustrá",
"alutada",
"alutado",
"alutais",
"alutamo",
"alutava",
"aluteis",
"alutemo",
"aluviai",
"aluviam",
"aluviei",
"aluviem",
"aluvies",
"aluviou",
"aluxada",
"aluxado",
"aluxais",
"aluxamo",
"aluxará",
"aluxava",
"aluxeis",
"aluxemo",
"aluzais",
"aluzamo",
"aluzeci",
"aluzecê",
"aluzeça",
"aluzeço",
"aluziai",
"aluziam",
"aluziar",
"aluzias",
"aluzida",
"aluzido",
"aluziei",
"aluziem",
"aluzies",
"aluzimo",
"aluziou",
"aluzirá",
"aluíeis",
"aluíres",
"aluíste",
"alvacim",
"alvacós",
"alvaiai",
"alvaiam",
"alvaiar",
"alvaias",
"alvaiei",
"alvaiem",
"alvaies",
"alvaiou",
"alvalai",
"alvalam",
"alvalas",
"alvalei",
"alvalem",
"alvalie",
"alvaliá",
"alvalou",
"alvanar",
"alvanis",
"alvaral",
"alvarda",
"alveada",
"alveais",
"alveará",
"alveava",
"alvecim",
"alveeis",
"alveiam",
"alveias",
"alveici",
"alveiem",
"alveies",
"alveire",
"alveita",
"alveito",
"alvejai",
"alvejes",
"alveloz",
"alvende",
"alvergo",
"alvesce",
"alvesci",
"alvesça",
"alvesço",
"alveína",
"alvidai",
"alvidam",
"alvidei",
"alvidem",
"alvidou",
"alvidre",
"alvitas",
"alvorai",
"alvoras",
"alvorem",
"alvoreá",
"alvotai",
"alvotam",
"alvotar",
"alvotas",
"alvotei",
"alvotem",
"alvotes",
"alvotou",
"alvéroa",
"alzaqui",
"alzubes",
"alzátia",
"alálias",
"alálica",
"alárave",
"alásias",
"aláveis",
"alânias",
"alãozão",
"alçadão",
"alécita",
"aléxica",
"alífuga",
"alífugo",
"alílios",
"alíseas",
"alísias",
"alítica",
"alóbara",
"alóbaro",
"alócero",
"alócroa",
"alócroo",
"alódapo",
"alógona",
"alógono",
"alópias",
"alópola",
"alópolo",
"alóporo",
"alótria",
"alótrio",
"alômera",
"alômero",
"alômias",
"alônima",
"alônoma",
"alônomo",
"alúnica",
"alúnico",
"amaceis",
"amacemo",
"amacies",
"amadria",
"amadrie",
"amadrio",
"amadões",
"amagado",
"amagais",
"amagará",
"amagota",
"amagote",
"amagoto",
"amagrai",
"amagras",
"amagrei",
"amagrem",
"amagres",
"amagrou",
"amaguem",
"amaines",
"amaldar",
"amalfil",
"amalfis",
"amalhai",
"amalhas",
"amalhei",
"amalhem",
"amalhes",
"amalhoa",
"amalhoe",
"amalhoo",
"amaloco",
"amaltai",
"amaltam",
"amaltei",
"amaltem",
"amaltes",
"amaluco",
"amalçoe",
"amalçoo",
"amalçoá",
"amanaca",
"amanaga",
"amanais",
"amanaiú",
"amanaja",
"amanamo",
"amanata",
"amanaçu",
"amanche",
"amancho",
"amandai",
"amaneis",
"amanemo",
"amangai",
"amangam",
"amangar",
"amangas",
"amangou",
"amanhio",
"amaniás",
"amaniús",
"amantai",
"amanças",
"amanões",
"amapuru",
"amaquie",
"amaquio",
"amaquiá",
"amareai",
"amarece",
"amareci",
"amareje",
"amarejo",
"amarejá",
"amareou",
"amareça",
"amargai",
"amarida",
"amaride",
"amarizo",
"amarizá",
"amarote",
"amaroto",
"amarroa",
"amarroe",
"amaruje",
"amasiai",
"amasiem",
"amasies",
"amastas",
"amastos",
"amatada",
"amatado",
"amatais",
"amatale",
"amatalo",
"amatamo",
"amatemo",
"amatule",
"amatulo",
"amatuta",
"amatute",
"amatuto",
"amatóis",
"amazela",
"amazele",
"amazula",
"amaçais",
"amaçamo",
"amaçará",
"amaçona",
"amaúcas",
"ambabas",
"ambabos",
"ambacas",
"ambacte",
"ambaida",
"ambalão",
"ambalós",
"ambarai",
"ambaram",
"ambarar",
"ambaras",
"ambarei",
"ambarem",
"ambarou",
"ambarra",
"ambarro",
"ambarós",
"ambasso",
"ambaúva",
"amberas",
"ambiada",
"ambiais",
"ambiamo",
"ambiana",
"ambiará",
"ambiava",
"ambieis",
"ambiemo",
"ambilas",
"ambiope",
"ambiras",
"amboins",
"ambolim",
"ambolés",
"amborás",
"ambrais",
"ambramo",
"ambrará",
"ambreai",
"ambreei",
"ambreia",
"ambreio",
"ambreis",
"ambremo",
"ambreou",
"ambrita",
"ambrite",
"ambrono",
"ambudes",
"ambuela",
"ambulai",
"ambulei",
"ambulem",
"ambules",
"ambília",
"ambítua",
"ambítuo",
"ambórea",
"ambóreo",
"ameador",
"ameadão",
"ameamos",
"ameando",
"ameante",
"amearam",
"amearei",
"amearem",
"ameares",
"amearia",
"amearás",
"amearão",
"ameaste",
"ameavam",
"ameavas",
"amebeia",
"amebeus",
"amebina",
"amebino",
"amebite",
"amebito",
"amechai",
"amecham",
"amechar",
"amechei",
"amechem",
"ameches",
"amechou",
"amedine",
"amedino",
"ameemos",
"ameense",
"ameiais",
"ameiamo",
"ameiará",
"ameiava",
"ameieis",
"ameiemo",
"ameigai",
"ameijoe",
"ameijoo",
"ameijua",
"ameijus",
"ameivas",
"amejuba",
"amelace",
"amelada",
"amelaça",
"amelaço",
"ameloma",
"amelpos",
"amenine",
"amentai",
"amentem",
"amentio",
"amerade",
"amerceá",
"amergei",
"amergem",
"amerges",
"amergia",
"amerjam",
"amerjas",
"ameruje",
"amerujo",
"amerujá",
"amesura",
"amesure",
"amesuro",
"ametala",
"ametale",
"ametina",
"ametisa",
"amiaras",
"amibose",
"amictas",
"amictos",
"amidais",
"amidamo",
"amidara",
"amidava",
"amideis",
"amidemo",
"amidoai",
"amidoam",
"amidoar",
"amidoas",
"amidoei",
"amidoem",
"amidoes",
"amidone",
"amidono",
"amidoou",
"amidose",
"amidros",
"amidóis",
"amielos",
"amigamo",
"amilana",
"amilato",
"amilhai",
"amilhas",
"amilhei",
"amilhem",
"amilhes",
"amilhou",
"amiloma",
"amimamo",
"amimara",
"amimeis",
"amimemo",
"aminamo",
"aminara",
"aminava",
"amineia",
"amineis",
"aminemo",
"aminore",
"aminoro",
"amintai",
"amintam",
"amintar",
"amintei",
"amintem",
"amintes",
"amintou",
"aminóis",
"amiocas",
"amiosas",
"amiquis",
"amiróis",
"amirões",
"amisaua",
"amisera",
"amisere",
"amisero",
"amistai",
"amistam",
"amistei",
"amistem",
"amistes",
"amistou",
"amisula",
"amisule",
"amisulo",
"amitino",
"amitros",
"amiudai",
"amiuder",
"amiudeu",
"amiudão",
"amixias",
"amiádea",
"amiádeo",
"amiúros",
"amniada",
"amniado",
"amobudu",
"amocado",
"amocamo",
"amocará",
"amocaís",
"amoceis",
"amocemo",
"amochas",
"amoches",
"amochoa",
"amochoe",
"amochoi",
"amochoo",
"amodele",
"amodelá",
"amoedai",
"amoedas",
"amoedei",
"amoedem",
"amoedes",
"amoedou",
"amoiada",
"amoiado",
"amoiais",
"amoiamo",
"amoiará",
"amoiava",
"amoieis",
"amoiemo",
"amoinai",
"amoinam",
"amoinas",
"amoinei",
"amoinem",
"amoines",
"amoinou",
"amoirai",
"amoiram",
"amoiras",
"amoirei",
"amoirem",
"amoiroe",
"amoiroo",
"amoirou",
"amoiroá",
"amoitai",
"amoitei",
"amoitem",
"amoixai",
"amoixam",
"amoixar",
"amoixas",
"amoixei",
"amoixem",
"amoixes",
"amoixou",
"amojais",
"amojamo",
"amojará",
"amojava",
"amojeis",
"amojemo",
"amojuda",
"amojudo",
"amolago",
"amolais",
"amolamo",
"amoldai",
"amoldes",
"amolemo",
"amolgai",
"amolgas",
"amolhai",
"amolham",
"amolhar",
"amolhas",
"amolhei",
"amolhem",
"amolhes",
"amolhou",
"amolina",
"amomada",
"amomado",
"amomamo",
"amomana",
"amomará",
"amomava",
"amomeis",
"amomemo",
"amonado",
"amonamo",
"amonará",
"amonava",
"amoneis",
"amonemo",
"amoniza",
"amonize",
"amonizo",
"amontai",
"amontas",
"amontom",
"amontão",
"amoquei",
"amorara",
"amoremo",
"amorene",
"amornes",
"amorroa",
"amorroe",
"amorroo",
"amoruda",
"amossai",
"amossei",
"amossem",
"amosses",
"amostai",
"amostam",
"amostei",
"amostem",
"amostes",
"amotada",
"amotado",
"amotais",
"amotamo",
"amotará",
"amotava",
"amoteia",
"amoteis",
"amotemo",
"amotino",
"amoucai",
"amoucam",
"amoucas",
"amourai",
"amouram",
"amourei",
"amourem",
"amouroe",
"amouroo",
"amourou",
"amouroá",
"amoutai",
"amoutam",
"amoutas",
"amoutei",
"amoutem",
"amoutes",
"amoutou",
"amouxai",
"amouxam",
"amouxas",
"amouxei",
"amouxem",
"amouxes",
"amouxou",
"amovais",
"amoveio",
"amovemo",
"amoviam",
"amovias",
"amovido",
"amovier",
"amovirá",
"amovéns",
"amoxama",
"amoxame",
"amoxamo",
"ampaque",
"ampiola",
"ampiole",
"ampiolo",
"ampolhe",
"ampolho",
"ampédio",
"ampélia",
"amuador",
"amuadão",
"amuante",
"amuarei",
"amuaria",
"amuarmo",
"amuarás",
"amuarão",
"amuavas",
"amuemos",
"amuimós",
"amulate",
"amulato",
"amumiai",
"amumiam",
"amumias",
"amumiei",
"amumiem",
"amumies",
"amuncei",
"amuncem",
"amunces",
"amuniga",
"amunçai",
"amunçam",
"amunçar",
"amunças",
"amunçou",
"amurais",
"amuramo",
"amurará",
"amurava",
"amurcas",
"amureis",
"amuremo",
"amussai",
"amussam",
"amussar",
"amussas",
"amussei",
"amussem",
"amusses",
"amussou",
"amálaca",
"amálica",
"amálico",
"amáteas",
"amáteos",
"amáuria",
"amânoas",
"amãozão",
"amébida",
"amélico",
"amênica",
"amílica",
"amílios",
"amítico",
"amólico",
"anabena",
"anabeno",
"anacado",
"anacalo",
"anacaná",
"anacare",
"anaceis",
"anacemo",
"anacral",
"anactas",
"anacujá",
"anacuse",
"anadais",
"anadeia",
"anadose",
"anafais",
"anafamo",
"anafeai",
"anafeei",
"anafeie",
"anafeio",
"anafeis",
"anafemo",
"anafeou",
"anafiai",
"anafiam",
"anafiar",
"anafias",
"anafiei",
"anafiem",
"anafies",
"anafilo",
"anafiou",
"anagada",
"anagado",
"anagais",
"anagará",
"anagire",
"anaguai",
"anaguei",
"anaguou",
"anaipai",
"anaipam",
"anaipar",
"anaipas",
"anaipei",
"anaipem",
"anaipes",
"anaipou",
"analgas",
"analgos",
"analipo",
"analota",
"analote",
"analtos",
"anambis",
"anampso",
"ananceu",
"anantos",
"ananzai",
"ananzam",
"ananzas",
"ananzei",
"ananzem",
"ananzes",
"ananzou",
"anaptas",
"anaques",
"anarete",
"anartas",
"anartos",
"anasais",
"anasale",
"anaspos",
"anassal",
"anastol",
"anastre",
"anastro",
"anasóis",
"anatais",
"anatava",
"anateis",
"anatemo",
"anatifa",
"anatrão",
"anaxeto",
"anaçada",
"anaçais",
"anaçamo",
"anaçava",
"anceias",
"anchaci",
"anchada",
"anchais",
"anchamo",
"anchava",
"ancheis",
"anchemo",
"anchões",
"ancilam",
"ancilei",
"ancilem",
"ancilou",
"ancinha",
"ancinhe",
"anclaca",
"ancoxas",
"ancrode",
"anculas",
"ancusas",
"ancácia",
"ancátia",
"ancônea",
"ancúsea",
"ancúseo",
"andadão",
"andaida",
"andaiás",
"andalha",
"andalim",
"andalão",
"andanho",
"andapés",
"andaval",
"andejai",
"andejes",
"andiraí",
"andoada",
"andoado",
"andoais",
"andoamo",
"andoará",
"andoava",
"andoeis",
"andoemo",
"andonde",
"andones",
"andoras",
"androma",
"andâmio",
"aneadas",
"aneador",
"aneados",
"aneadão",
"aneamos",
"aneante",
"aneaqui",
"anearam",
"anearei",
"anearem",
"aneares",
"anearia",
"anearás",
"anearão",
"aneasse",
"aneaste",
"aneavam",
"aneavas",
"anecral",
"anecril",
"anecris",
"anediai",
"anedias",
"anediei",
"anediem",
"anedies",
"anediou",
"aneemos",
"anegais",
"anegara",
"anegava",
"anegaça",
"anegrai",
"anegram",
"anegras",
"anegrei",
"anegrem",
"anegres",
"aneguem",
"anegues",
"aneixos",
"anelamo",
"aneleis",
"anelemo",
"anelhos",
"anemiai",
"anemiam",
"anemiei",
"anemiem",
"anemies",
"anemiou",
"anemize",
"anemizo",
"anepias",
"anesias",
"anesina",
"anesona",
"anestil",
"anetóis",
"aneuras",
"aneuros",
"anexamo",
"anexeis",
"anexemo",
"anfanto",
"anfideã",
"anfidos",
"anfioxa",
"anfiúma",
"anfunge",
"anfásia",
"anfígio",
"anfímia",
"anganas",
"angangá",
"anganja",
"angaços",
"angemas",
"angitas",
"angliza",
"anglize",
"anglizo",
"angojas",
"angolal",
"angonas",
"angores",
"angracá",
"angrela",
"angrins",
"angrite",
"anguaia",
"angulai",
"angulei",
"angulem",
"angurus",
"angídio",
"anhafia",
"anhamim",
"anhanho",
"anhapas",
"anhaúva",
"anhotes",
"anhotos",
"anhuíba",
"aniaras",
"aniaros",
"aniarto",
"aniavás",
"anichai",
"anichas",
"anichei",
"anichem",
"aniches",
"anidamo",
"anidará",
"anidava",
"anideis",
"anidemo",
"anielai",
"anielam",
"anielas",
"anielei",
"anielem",
"anieles",
"anielou",
"anifena",
"aniilai",
"aniilam",
"aniilas",
"aniilei",
"aniilem",
"aniiles",
"anilais",
"anilamo",
"anilara",
"anilava",
"anileis",
"anilema",
"anilemo",
"anilese",
"anilhai",
"anilhem",
"anilhes",
"anilido",
"anilzão",
"animina",
"animize",
"animizo",
"aninamo",
"aninará",
"aninauá",
"anineis",
"aninemo",
"anirias",
"anisais",
"anisamo",
"anisará",
"anisava",
"aniseis",
"anisemo",
"anisilo",
"anisope",
"anistos",
"anisóis",
"anitina",
"anitóis",
"anivele",
"anivelo",
"anivelá",
"anixias",
"anjango",
"anjeela",
"anjiras",
"anjubas",
"anjuriá",
"anjuões",
"anlagas",
"anociva",
"anocivo",
"anodais",
"anodias",
"anodizo",
"anodmia",
"anodões",
"anoemas",
"anoitai",
"anoitam",
"anoitas",
"anoitem",
"anoiuba",
"anojais",
"anojamo",
"anojará",
"anojemo",
"anolzão",
"anomeai",
"anomeei",
"anomeia",
"anomeie",
"anomeou",
"anomial",
"anomita",
"anomite",
"anomito",
"anonade",
"anonado",
"anonzés",
"anoopes",
"anoopia",
"anopias",
"anoques",
"anorcos",
"anorteá",
"anosada",
"anosado",
"anosias",
"anosóis",
"anotemo",
"anotias",
"anoutai",
"anoutam",
"anoutas",
"anoutei",
"anoutem",
"anoutes",
"anovada",
"anovado",
"anovais",
"anovamo",
"anovará",
"anovava",
"anoveai",
"anoveei",
"anoveia",
"anoveie",
"anoveio",
"anoveis",
"anovele",
"anovemo",
"anoveou",
"anoxiai",
"anoxiam",
"anoxiar",
"anoxiei",
"anoxiem",
"anoxies",
"anoxiou",
"anselim",
"ansenga",
"ansiamo",
"ansiemo",
"ansélia",
"antador",
"antados",
"antadão",
"antalma",
"antalos",
"antaram",
"antarei",
"antarem",
"antaria",
"antarmo",
"antarão",
"antasse",
"antaste",
"antavam",
"antavas",
"anteata",
"antecas",
"antedai",
"antedam",
"antedou",
"antedás",
"antedão",
"antedês",
"antefaz",
"antefez",
"antefiz",
"antelos",
"antemol",
"antevoe",
"antevoo",
"antevoá",
"antifal",
"antinas",
"antivéu",
"antojai",
"antojam",
"antojas",
"antojei",
"antojem",
"antojes",
"antoras",
"antróis",
"anturas",
"antáxia",
"antélia",
"antíada",
"antíade",
"antídea",
"antótia",
"anuamos",
"anuaçus",
"anubrai",
"anubram",
"anubras",
"anubrei",
"anubrem",
"anubres",
"anubrou",
"anuidor",
"anuidão",
"anuirmo",
"anuirás",
"anulemo",
"anumere",
"anunzés",
"anuviai",
"anuvias",
"anuvies",
"anuíbas",
"anuíeis",
"anuível",
"anvalós",
"anvulas",
"anzicas",
"anzinas",
"anzolai",
"anzolam",
"anzolei",
"anzolem",
"anzoles",
"anzolou",
"anábasa",
"anábate",
"anábato",
"anábola",
"anácola",
"anáguam",
"anáguem",
"anágues",
"anátida",
"anãzada",
"anãzais",
"anãzamo",
"anãzará",
"anãzava",
"anãzeis",
"anãzemo",
"anãzona",
"anélido",
"anérvea",
"anóculo",
"anófelo",
"anófito",
"anógina",
"anólena",
"anópoda",
"anóstea",
"anósteo",
"anótica",
"anótico",
"anômato",
"anômeas",
"anúdiva",
"anúrias",
"aosadas",
"apadreá",
"apadroe",
"apadroo",
"apagear",
"apagmas",
"apagoge",
"apaijai",
"apaijam",
"apaijas",
"apaijei",
"apaijem",
"apaijes",
"apaijou",
"apaiole",
"apaiolo",
"apaisai",
"apaisam",
"apaisas",
"apaisei",
"apaisem",
"apaisou",
"apajada",
"apajado",
"apajais",
"apajamo",
"apajará",
"apajeai",
"apajeei",
"apajeia",
"apajeie",
"apajeio",
"apajeis",
"apajemo",
"apajeou",
"apalace",
"apalade",
"apalaze",
"apalazo",
"apalaça",
"apalaço",
"apaleai",
"apaleei",
"apaleie",
"apaleio",
"apaleou",
"apalmai",
"apalmam",
"apalmas",
"apalmei",
"apalmem",
"apalmes",
"apameno",
"apancai",
"apancas",
"apancou",
"apandai",
"apandam",
"apandei",
"apandem",
"apandes",
"apanico",
"apanque",
"apantos",
"aparaci",
"aparamo",
"aparaço",
"aparcai",
"aparcam",
"aparcas",
"aparcou",
"aparemo",
"aparrai",
"aparram",
"aparras",
"aparrei",
"aparrem",
"aparres",
"aparrou",
"aparvai",
"aparvam",
"aparvas",
"aparvei",
"aparvem",
"aparves",
"aparvoa",
"aparvoe",
"aparvoo",
"aparvou",
"apascoe",
"apascoo",
"apastia",
"apataco",
"apatacá",
"apatete",
"apateto",
"apatifa",
"apatifo",
"apatito",
"apatiza",
"apatize",
"apatizo",
"apaulai",
"apaulam",
"apaulei",
"apaulem",
"apautai",
"apautam",
"apautar",
"apautas",
"apautei",
"apautem",
"apautes",
"apautou",
"apavesa",
"apavese",
"apaveso",
"apavone",
"apavono",
"apeacei",
"apeacem",
"apeaces",
"apeador",
"apeadão",
"apealai",
"apealam",
"apealas",
"apealei",
"apealem",
"apeales",
"apealou",
"apeance",
"apeanhe",
"apeanho",
"apeante",
"apeanço",
"apeançá",
"apearas",
"apearei",
"apeaste",
"apeavas",
"apeaçai",
"apeaçam",
"apeaçar",
"apeaças",
"apeaçou",
"apecuns",
"apedada",
"apedais",
"apedamo",
"apedara",
"apedava",
"apedeis",
"apedemo",
"apedrai",
"apedram",
"apedrem",
"apedres",
"apeemos",
"apeirai",
"apeiram",
"apeiras",
"apeirei",
"apeirem",
"apeires",
"apeirou",
"apejada",
"apejais",
"apejamo",
"apejará",
"apejava",
"apejeis",
"apejemo",
"apelemo",
"apelita",
"apenamo",
"apenari",
"apendai",
"apendes",
"apendia",
"apendoa",
"apendoe",
"apendoo",
"apendou",
"apeneis",
"apenemo",
"apenhai",
"apenham",
"apenhem",
"apenhes",
"apensai",
"apenses",
"apepine",
"apepino",
"aperais",
"aperamo",
"aperará",
"aperava",
"apereis",
"aperemo",
"apereás",
"apernai",
"apernam",
"apernei",
"apernem",
"apernes",
"apernou",
"aperole",
"aperolo",
"aperrai",
"aperram",
"aperras",
"aperrem",
"aperres",
"aperuai",
"aperuam",
"aperuas",
"aperuei",
"aperuem",
"aperues",
"aperuou",
"aperção",
"apesaro",
"apessoe",
"apessoo",
"apestai",
"apestam",
"apestei",
"apestem",
"apestes",
"apesteá",
"apestou",
"apetais",
"apetamo",
"apetare",
"apetaro",
"apetará",
"apeteis",
"apetemo",
"apetrai",
"apetrar",
"apetras",
"apetrem",
"apetrou",
"apetupá",
"apeturi",
"apeíbas",
"apiabai",
"apiabam",
"apiabei",
"apiabem",
"apiabes",
"apiabou",
"apiadai",
"apiadam",
"apiadei",
"apiance",
"apiança",
"apicace",
"apicaça",
"apicaço",
"apicham",
"apichas",
"apichei",
"apichem",
"apiches",
"apicoai",
"apicoam",
"apicoas",
"apicoei",
"apicoem",
"apicoes",
"apicoou",
"apicras",
"apicuru",
"apicuís",
"apiense",
"apiinas",
"apilare",
"apilaro",
"apilará",
"apildre",
"apildro",
"apildrá",
"apilema",
"apilese",
"apilhai",
"apilham",
"apilhei",
"apilhem",
"apilhes",
"apiloai",
"apiloas",
"apiloei",
"apiloem",
"apiloes",
"apiloou",
"apilrai",
"apilram",
"apilrar",
"apilras",
"apilrei",
"apilrem",
"apilres",
"apilrou",
"apinada",
"apinado",
"apinais",
"apinamo",
"apinará",
"apinava",
"apinche",
"apincho",
"apinemo",
"apingui",
"apinhai",
"apinhei",
"apinhes",
"apinhoa",
"apinhoe",
"apinhoo",
"apinoco",
"apinocá",
"apintai",
"apintas",
"apintei",
"apintem",
"apintes",
"apintoa",
"apintoe",
"apintoo",
"apiobas",
"apional",
"apionas",
"apiosas",
"apioses",
"apipada",
"apipais",
"apipamo",
"apipava",
"apipeis",
"apipemo",
"apireno",
"apirita",
"apirite",
"apirito",
"apisine",
"apisino",
"apisoai",
"apisoam",
"apisoas",
"apisoei",
"apisoem",
"apisoes",
"apisoou",
"apitaus",
"apiteis",
"apitemo",
"aplebeá",
"aplumai",
"aplumam",
"aplumas",
"aplumei",
"aplumem",
"aplumes",
"apocelo",
"apocopo",
"apodeis",
"apodeta",
"apodixe",
"apodiza",
"apodizo",
"apodram",
"apodras",
"apodres",
"apodria",
"apodris",
"apodriu",
"apofilo",
"apogões",
"apoitai",
"apoitas",
"apoitei",
"apoitem",
"apoites",
"apojais",
"apojamo",
"apojará",
"apojeis",
"apojemo",
"apolato",
"apolaze",
"apolazo",
"apolazá",
"apoldra",
"apoldre",
"apoldro",
"apoleai",
"apoleei",
"apolego",
"apoleie",
"apoleio",
"apoleje",
"apolejo",
"apoleou",
"apoleza",
"apoleze",
"apolezo",
"apombai",
"apombam",
"apombar",
"apombas",
"apombei",
"apombem",
"apombes",
"apombou",
"apompai",
"apompam",
"apompar",
"apompas",
"apompei",
"apompem",
"apompes",
"apompou",
"aponais",
"aponche",
"aponchá",
"aponhas",
"aponias",
"aponteá",
"apontoe",
"apontoo",
"apordes",
"aporeis",
"aporese",
"aporfie",
"aporral",
"aportai",
"aposias",
"aposseá",
"apossua",
"apoteco",
"apotijé",
"apoucai",
"apoupai",
"apoupam",
"apoupas",
"apoupei",
"apoupem",
"apoupes",
"apousai",
"apousam",
"apousar",
"apousas",
"apousei",
"apousem",
"apouses",
"apousou",
"apoutai",
"apoutam",
"apoutas",
"apoutei",
"apoutem",
"apoutes",
"apoutou",
"apozeme",
"apozemo",
"apragia",
"apraiai",
"apraiam",
"apraiar",
"apraiei",
"apraiem",
"apraies",
"apraiou",
"apraiús",
"aprajus",
"aprauás",
"aprazai",
"apreada",
"apreais",
"apreará",
"apreava",
"apreeis",
"apreiam",
"apreias",
"apreiem",
"apreies",
"apremam",
"apremas",
"apremei",
"apremem",
"apremes",
"apremeu",
"apremie",
"apremou",
"apresai",
"apreses",
"apreçai",
"apricas",
"apricos",
"aprisca",
"aprisoe",
"aprisoo",
"aprisoá",
"apristo",
"aproais",
"aproamo",
"aprocho",
"aprochá",
"aprocto",
"aproeis",
"aproeje",
"aproejo",
"aproemo",
"aprosai",
"aprosam",
"aprosas",
"aprosei",
"aprosou",
"aprouvi",
"aprouvo",
"aprumai",
"aptadão",
"aptante",
"aptaras",
"aptarei",
"aptares",
"aptarmo",
"aptarão",
"aptasse",
"aptaste",
"aptavam",
"aptavas",
"aptemos",
"apuador",
"apuadão",
"apuames",
"apuamos",
"apuando",
"apuante",
"apuaram",
"apuaras",
"apuarei",
"apuarem",
"apuares",
"apuaria",
"apuarmo",
"apuarão",
"apuasse",
"apuaste",
"apuavam",
"apudora",
"apudore",
"apudoro",
"apuemos",
"apujaré",
"apulais",
"apulamo",
"apulará",
"apulava",
"apuleis",
"apuleja",
"apulemo",
"apulsos",
"apunche",
"apuncho",
"apunchá",
"apungas",
"apunhai",
"apunhas",
"apunhei",
"apunhem",
"apunhes",
"apuntos",
"apupais",
"apupamo",
"apupará",
"apupeis",
"apupemo",
"apureis",
"apuremo",
"apuruís",
"apusais",
"apuseis",
"apusemo",
"apusiam",
"apusias",
"apusido",
"aputiás",
"apálage",
"apéxica",
"apéxico",
"apícios",
"apídeas",
"apífera",
"apífero",
"apífilo",
"apífugo",
"apígeas",
"apígeos",
"apíicas",
"apíicos",
"apíneas",
"apíonos",
"apívora",
"apóbata",
"apócrea",
"apócreo",
"apódica",
"apódico",
"apódise",
"apófana",
"apófano",
"apógamo",
"apógeno",
"apógino",
"apójove",
"apóloga",
"apóporo",
"apótafo",
"apóteto",
"apótipo",
"apômaco",
"apônica",
"apúlica",
"apúlico",
"apúnias",
"apúsida",
"aquaquá",
"aquedai",
"aquedam",
"aquedei",
"aquedem",
"aquedes",
"aquelai",
"aquelei",
"aquetam",
"aquetes",
"aquilai",
"aquilam",
"aquilei",
"aquilem",
"aquilhá",
"aquiros",
"aquiste",
"aquites",
"aquotie",
"aquotio",
"aquotiá",
"aquétea",
"aquéteo",
"arabaçu",
"araberu",
"arabize",
"arabizo",
"aracane",
"aracota",
"aracujá",
"aracuís",
"aradais",
"aradamo",
"aradará",
"aradava",
"aradeis",
"aradelo",
"arademo",
"aradica",
"aradico",
"aradona",
"aradões",
"araicus",
"aralhas",
"aralina",
"aramais",
"aramamo",
"aramané",
"arambás",
"arameis",
"aramemo",
"aramesa",
"aramite",
"aramosa",
"aramoso",
"aramuda",
"aranane",
"aranata",
"aranhai",
"aranhem",
"aranhes",
"arapace",
"araparu",
"arapaós",
"arapaús",
"arapeba",
"araperu",
"arapuai",
"arapuei",
"arapuem",
"arapues",
"arapuou",
"arapure",
"arapuro",
"araradá",
"ararari",
"araraís",
"arardes",
"arareis",
"araremo",
"arariam",
"ararias",
"ararina",
"arariua",
"araruás",
"arasses",
"arasões",
"arauiti",
"aravaco",
"araveca",
"araviai",
"araviam",
"aravias",
"araviei",
"araviem",
"aravies",
"aravine",
"araviou",
"araxaué",
"araxinu",
"araxués",
"araçada",
"araçaçu",
"araçona",
"arbenas",
"arbitas",
"arcarmo",
"arcaste",
"arcaíze",
"arcaízo",
"arceras",
"arcetes",
"arceuto",
"arcolim",
"arcosas",
"arctada",
"arctais",
"arctamo",
"arctará",
"arctava",
"arcteis",
"arctemo",
"arcuada",
"arcuais",
"arcuamo",
"arcuará",
"arcuava",
"arcueis",
"arcuemo",
"arcumãs",
"arcácea",
"arcáceo",
"arcíria",
"ardeate",
"ardedor",
"ardeida",
"ardejai",
"ardejam",
"ardejar",
"ardejas",
"ardejei",
"ardejem",
"ardejes",
"ardejou",
"ardermo",
"ardigai",
"ardigam",
"ardigar",
"ardigas",
"ardigou",
"ardigue",
"ardoras",
"ardoses",
"ardosie",
"ardosio",
"ardíeis",
"areacós",
"areadão",
"arealus",
"areante",
"arearei",
"arearem",
"areares",
"arearia",
"arearás",
"arearão",
"areasse",
"areatas",
"arecais",
"areemos",
"arejais",
"arejamo",
"arejeis",
"arejemo",
"arelzão",
"arenará",
"arenava",
"arencus",
"areneis",
"arenemo",
"arengai",
"arensai",
"arensam",
"arensas",
"arensei",
"arensem",
"arenses",
"arensou",
"areosca",
"arepaba",
"arepene",
"arevaco",
"areísca",
"areísmo",
"arfadão",
"arfaras",
"arfarei",
"arfaria",
"arfarmo",
"arfarão",
"arfaste",
"arfemos",
"arféloa",
"argalas",
"argalos",
"arganis",
"arganos",
"argites",
"argolai",
"argolam",
"argolei",
"argolem",
"argoles",
"argonai",
"argonam",
"argonas",
"argonei",
"argonem",
"argonou",
"arguamo",
"argucie",
"argucio",
"arguesa",
"arguias",
"arguimo",
"arguiço",
"argylis",
"argêmon",
"argília",
"argírea",
"argíreo",
"ariador",
"ariadão",
"ariaram",
"ariaras",
"ariarei",
"ariarem",
"ariares",
"ariaria",
"ariarmo",
"ariarão",
"ariasse",
"ariaste",
"ariauás",
"ariavam",
"ariavas",
"ariaxés",
"aribina",
"aricada",
"aricado",
"aricais",
"aricamo",
"aricava",
"aricinó",
"aricita",
"aricite",
"aricito",
"aricori",
"aricuiá",
"aricuna",
"aricuís",
"ariemos",
"arietai",
"arietam",
"arietar",
"arietei",
"arietem",
"arietou",
"ariinis",
"arilhos",
"arimbás",
"arimono",
"arincus",
"arinfeu",
"aringão",
"arintas",
"ariocós",
"aripada",
"aripado",
"aripais",
"aripamo",
"aripará",
"aripava",
"aripeis",
"aripemo",
"aripiai",
"aripiam",
"aripias",
"aripiei",
"aripiem",
"aripies",
"aripiou",
"aripuás",
"ariquei",
"arirana",
"ariscai",
"arisema",
"aritica",
"arivate",
"arjoado",
"arjoais",
"arjoamo",
"arjoará",
"arjoava",
"arjoeis",
"arjoemo",
"arjoona",
"arjozes",
"arlique",
"arlotas",
"arlotia",
"arlotão",
"armalol",
"armanha",
"armanso",
"armelos",
"armenha",
"armilai",
"armilam",
"armilei",
"armilem",
"armiles",
"armilou",
"arminhe",
"arminos",
"armismo",
"armolas",
"armosas",
"armosos",
"armozeu",
"arnabes",
"arnabos",
"arnadas",
"arnates",
"arnazes",
"arnecãs",
"arnedos",
"arnegai",
"arnegam",
"arnegar",
"arnegas",
"arnegou",
"arneire",
"arnelha",
"arnelho",
"arnelos",
"arnesai",
"arnesam",
"arnesas",
"arnesei",
"arnesem",
"arnesou",
"arnicai",
"arnicam",
"arnicou",
"arnique",
"arnitos",
"arnodos",
"arnolol",
"arnolta",
"arnotes",
"arnotos",
"arnébia",
"arnócia",
"aromamo",
"aromara",
"aromato",
"aromeis",
"aromemo",
"aromias",
"aromosa",
"arpadas",
"arpadão",
"arpamos",
"arparam",
"arparei",
"arparem",
"arpares",
"arparia",
"arparmo",
"arparás",
"arparão",
"arpaste",
"arpavam",
"arpavas",
"arpeada",
"arpeais",
"arpeará",
"arpeava",
"arpeeis",
"arpeias",
"arpeiem",
"arpeies",
"arpejai",
"arpejas",
"arpejem",
"arpejes",
"arpemos",
"arpoais",
"arpoamo",
"arpoeis",
"arpoemo",
"arpoões",
"arpuada",
"arpuado",
"arpuais",
"arpuamo",
"arpuara",
"arpuava",
"arpueis",
"arpuemo",
"arpédio",
"arqueai",
"arquemo",
"arrabai",
"arrabam",
"arrabas",
"arrabei",
"arrabem",
"arrabou",
"arracei",
"arracem",
"arraces",
"arraiai",
"arraiam",
"arraiem",
"arraies",
"arralai",
"arralam",
"arralas",
"arralei",
"arralem",
"arrales",
"arralou",
"arramai",
"arramam",
"arramem",
"arrarei",
"arrarem",
"arrarou",
"arratai",
"arratas",
"arratem",
"arraçai",
"arraçam",
"arraças",
"arraçoa",
"arraçoe",
"arraçoo",
"arraída",
"arraído",
"arraíra",
"arreais",
"arreala",
"arrealo",
"arreamo",
"arreato",
"arreceá",
"arrecto",
"arrecue",
"arrecuo",
"arredoo",
"arredre",
"arredro",
"arredrá",
"arreeis",
"arreemo",
"arregoe",
"arregoo",
"arregre",
"arregro",
"arreico",
"arreies",
"arreito",
"arrelhe",
"arrelho",
"arrelva",
"arrelve",
"arrengá",
"arrenos",
"arrequi",
"arreram",
"arrerei",
"arreres",
"arreria",
"arrermo",
"arrerás",
"arrerão",
"arresse",
"arretai",
"arretas",
"arretem",
"arreçoe",
"arreçoo",
"arreçoá",
"arreçãs",
"arriamo",
"arricei",
"arricem",
"arrices",
"arridai",
"arridam",
"arridei",
"arridem",
"arrides",
"arridos",
"arridou",
"arridão",
"arriemo",
"arrifam",
"arrifas",
"arrifei",
"arrifou",
"arrigai",
"arrigam",
"arrigou",
"arrigue",
"arrijai",
"arrijam",
"arrijas",
"arrijei",
"arrijem",
"arrijes",
"arrijou",
"arrimai",
"arrimem",
"arrimão",
"arrinas",
"arrinos",
"arripai",
"arripam",
"arripas",
"arripei",
"arripem",
"arripes",
"arripou",
"arrizai",
"arrizam",
"arrizas",
"arrizei",
"arrizem",
"arrizos",
"arrizou",
"arriçai",
"arriças",
"arriçou",
"arrobai",
"arrobis",
"arrocie",
"arrocio",
"arrociá",
"arrogai",
"arrogia",
"arroiai",
"arroias",
"arroiei",
"arroiem",
"arroies",
"arroiou",
"arrolai",
"arrotai",
"arroupe",
"arroupo",
"arroxai",
"arroxas",
"arroxem",
"arroxes",
"arroxeá",
"arruace",
"arruais",
"arruamo",
"arrubés",
"arrucei",
"arrucem",
"arruces",
"arrueis",
"arruelo",
"arruemo",
"arrufai",
"arrufas",
"arrufei",
"arrufem",
"arrufie",
"arrufio",
"arrufiá",
"arrugai",
"arrugam",
"arrugue",
"arruive",
"arruivo",
"arruivá",
"arrulai",
"arrulam",
"arrulas",
"arrulei",
"arrulem",
"arrules",
"arrulou",
"arrunha",
"arrunhe",
"arrunho",
"arrupie",
"arruçai",
"arruçam",
"arruíam",
"arruías",
"arruída",
"arruíra",
"artanto",
"artesoa",
"artesoe",
"artesoo",
"artigar",
"artolai",
"artolam",
"artolei",
"artolem",
"artoles",
"artolou",
"artopta",
"artâmia",
"aruagal",
"aruanós",
"aruaqui",
"aruarus",
"aruegas",
"aruense",
"arumaru",
"aruncos",
"arundas",
"arundos",
"arurana",
"arurões",
"arvadas",
"arvados",
"arviões",
"arvoada",
"arvoais",
"arvoamo",
"arvoara",
"arvoava",
"arvoeis",
"arvoemo",
"arxadas",
"arxador",
"arxados",
"arxadão",
"arxamos",
"arxando",
"arxante",
"arxaram",
"arxarei",
"arxarem",
"arxares",
"arxaria",
"arxarmo",
"arxarás",
"arxarão",
"arxasse",
"arxaste",
"arxavam",
"arxavas",
"arxemos",
"arzalar",
"arzilas",
"arzolas",
"arâmica",
"arílica",
"arôneas",
"asadona",
"asadões",
"asafias",
"asagres",
"asaqueu",
"asarato",
"asarcas",
"asarcia",
"asardes",
"asaremo",
"asariam",
"asarita",
"asarmos",
"asaroto",
"asasses",
"asastes",
"asbista",
"ascarim",
"ascelia",
"asceína",
"ascitas",
"ascomai",
"ascomam",
"ascomei",
"ascomem",
"ascomes",
"ascomou",
"ascoses",
"ascreia",
"ascreus",
"ascuada",
"ascuado",
"ascuais",
"ascuamo",
"ascuará",
"ascuava",
"ascueis",
"ascuemo",
"ascunas",
"aselina",
"asidona",
"asidora",
"asidões",
"asilais",
"asilamo",
"asileis",
"asilemo",
"asineus",
"asirdes",
"asireis",
"asiremo",
"asiriam",
"asirias",
"asirmos",
"asisses",
"asistes",
"asnador",
"asnados",
"asnadão",
"asnamos",
"asnando",
"asnante",
"asnaram",
"asnarei",
"asnarmo",
"asnarás",
"asnarão",
"asnasse",
"asnaste",
"asnavam",
"asnavas",
"asneada",
"asneais",
"asneará",
"asneava",
"asneeis",
"asneiem",
"asneies",
"asnemos",
"asofias",
"aspador",
"aspadão",
"aspante",
"asparei",
"asparem",
"aspares",
"asparmo",
"asparto",
"asparão",
"aspasse",
"aspavam",
"aspavas",
"aspaços",
"aspeais",
"aspeará",
"aspeava",
"aspeeis",
"aspegia",
"aspeiam",
"aspeias",
"aspeiem",
"aspeies",
"aspemos",
"asperse",
"aspreja",
"aspreje",
"asprejo",
"aspudas",
"aspudos",
"aspídia",
"asqueai",
"asquear",
"asqueei",
"asqueia",
"asqueie",
"asqueio",
"asqueou",
"assacai",
"assafio",
"assamis",
"assapai",
"assapam",
"assapas",
"assapem",
"assapes",
"assarmo",
"assaste",
"assaves",
"assazoe",
"assazoo",
"assazoá",
"asseais",
"asseamo",
"assedai",
"assedei",
"assedou",
"asseemo",
"asseias",
"asseiem",
"asseies",
"asselai",
"asselas",
"asselem",
"asseles",
"asseria",
"asseris",
"assetai",
"assetei",
"assetem",
"asseteá",
"assetou",
"assexos",
"assezoa",
"assezoe",
"assezoo",
"asseçoe",
"asseçoá",
"assideu",
"assidra",
"assidre",
"assidro",
"assigmo",
"assilhe",
"assilho",
"assilhá",
"assiras",
"assisai",
"assisas",
"assisei",
"assoais",
"assoamo",
"assobes",
"assocai",
"assocas",
"assoemo",
"assolde",
"assoldo",
"assoleá",
"assolhe",
"assolhá",
"assomai",
"assonai",
"assonam",
"assonas",
"assonem",
"assones",
"assonou",
"assonsa",
"assonse",
"assopeá",
"assoque",
"assotai",
"assotam",
"assotar",
"assotas",
"assotei",
"assotem",
"assotes",
"assotou",
"assuais",
"assuamo",
"assuará",
"assubam",
"assubas",
"assubis",
"assucai",
"assucam",
"assucas",
"assucou",
"assueis",
"assuemo",
"assunai",
"assunas",
"assunei",
"assunem",
"assungo",
"assunou",
"assuque",
"assurge",
"assurgi",
"assurja",
"assurjo",
"assuxar",
"assácio",
"assísio",
"astemas",
"asteral",
"asterol",
"astilhe",
"astilos",
"astisia",
"astismo",
"astrego",
"astrevi",
"astucie",
"astíria",
"asulana",
"asulano",
"asuróis",
"asvande",
"asvando",
"asvandá",
"asáfica",
"asáreis",
"asárica",
"asárico",
"asáveis",
"asélido",
"asíamos",
"atabais",
"atabalo",
"atabona",
"atabuai",
"atabuam",
"atabuco",
"atabucá",
"atabuei",
"atabuem",
"atabues",
"atabule",
"atabuou",
"atabões",
"atacoai",
"atacoam",
"atacoas",
"atacoei",
"atacoem",
"atadona",
"atadões",
"atafegá",
"atafule",
"atafulo",
"atafulá",
"atalaie",
"atalaio",
"atalhai",
"atamada",
"atamado",
"atamamo",
"atamana",
"atamane",
"atamará",
"atamava",
"atameis",
"atamemo",
"atamiça",
"atanais",
"atanamo",
"atanara",
"atanaze",
"atanche",
"atancho",
"atanchá",
"atandas",
"ataneis",
"atanemo",
"atangei",
"atangem",
"atanger",
"atanges",
"atangeu",
"atangia",
"atanjam",
"atanjas",
"atanoai",
"atanoam",
"atanoar",
"atanoas",
"atanoei",
"atanoem",
"atanoes",
"atanoou",
"atapala",
"atapata",
"atapato",
"atapera",
"atapere",
"atapero",
"ataraús",
"atardai",
"atardas",
"atardei",
"atardem",
"atardes",
"atareai",
"atarear",
"atareei",
"atareia",
"atareie",
"atareio",
"ataremo",
"atareou",
"atarugo",
"atarugá",
"atascai",
"atascas",
"atasses",
"atatino",
"ataudai",
"ataudam",
"ataudar",
"ataudas",
"ataudei",
"ataudem",
"ataudou",
"atauxie",
"atauxio",
"ataviai",
"atavies",
"atavize",
"atavizo",
"atavizá",
"atavole",
"atavolo",
"atavões",
"ataxita",
"ataçona",
"ateadão",
"ateante",
"atearmo",
"ateavas",
"atediai",
"atediam",
"atediei",
"atediem",
"atedies",
"atediou",
"atedora",
"ateemos",
"ateigai",
"ateigam",
"ateigas",
"ateigou",
"ateimai",
"ateimas",
"ateimem",
"ateirós",
"ateizai",
"ateizei",
"ateizou",
"atelhai",
"atelham",
"atelhei",
"atelhem",
"atelhes",
"atelhou",
"ateliam",
"atelias",
"ateliei",
"ateliem",
"atelieu",
"ateliia",
"atelila",
"atelili",
"atelilo",
"atelina",
"atelita",
"atemore",
"atemoro",
"atempai",
"atempam",
"atempas",
"atempei",
"atempem",
"atempes",
"atenaué",
"atenaze",
"atenazo",
"atenora",
"atenore",
"atenoro",
"atenrai",
"atenras",
"atenrei",
"atenrem",
"atenres",
"atenrou",
"atenuai",
"aterdes",
"aterece",
"atereci",
"atereis",
"atereça",
"atereço",
"ateribá",
"atermai",
"atermam",
"atermei",
"atermem",
"atermes",
"atermou",
"aterroo",
"aterroá",
"ateréua",
"atesada",
"atesais",
"atesamo",
"atesará",
"atesava",
"ateseis",
"atesemo",
"atesias",
"atesteá",
"atetese",
"atezane",
"atezano",
"atezaná",
"ateízam",
"ateízas",
"ateízem",
"ateízes",
"atiadeu",
"atialia",
"atiatis",
"atibada",
"atibamo",
"atibará",
"atibava",
"atibeis",
"atibemo",
"atibiai",
"atibiam",
"atibias",
"atibiei",
"atibiem",
"atibies",
"atibiou",
"aticeis",
"aticemo",
"aticite",
"aticize",
"aticizo",
"aticizá",
"atidona",
"atidões",
"atigrai",
"atigram",
"atigras",
"atigrei",
"atigrem",
"atigres",
"atijola",
"atijole",
"atijolo",
"atilais",
"atilamo",
"atilará",
"atilava",
"atileis",
"atilemo",
"atilhai",
"atilham",
"atilhei",
"atilhem",
"atilhes",
"atinamo",
"atinate",
"atineis",
"atinemo",
"atintai",
"atintam",
"atintas",
"atintei",
"atintem",
"atintes",
"atintou",
"atiplai",
"atiplam",
"atiplas",
"atiplei",
"atiplem",
"atiples",
"atiplou",
"atiquei",
"atiquem",
"atiques",
"atiriba",
"atitada",
"atitado",
"atitais",
"atitamo",
"atitava",
"atiteis",
"atitemo",
"atitule",
"atitulá",
"ativamo",
"atiçamo",
"atiçoai",
"atiçoam",
"atiçoas",
"atiçoei",
"atiçoem",
"atiçoes",
"atiçoou",
"atoadas",
"atoadão",
"atoalhe",
"atoalho",
"atoamos",
"atoante",
"atoaram",
"atoarei",
"atoarem",
"atoares",
"atoaria",
"atoarmo",
"atoarás",
"atoarão",
"atoasse",
"atoaste",
"atoavam",
"atoavas",
"atocaie",
"atocaio",
"atochai",
"atocias",
"atoemos",
"atoicei",
"atoicem",
"atoiçai",
"atoiçam",
"atoiças",
"atoiçou",
"atoldoe",
"atoldoo",
"atoldoá",
"atoleco",
"atoleis",
"atolemo",
"atolida",
"atomata",
"atomato",
"atombai",
"atombam",
"atombas",
"atombei",
"atombem",
"atombes",
"atombou",
"atomizo",
"atonada",
"atonado",
"atonamo",
"atonará",
"atonava",
"atoneis",
"atonemo",
"atoniai",
"atoniam",
"atoniar",
"atoniei",
"atoniem",
"atonies",
"atoniou",
"atoniza",
"atonize",
"atonizo",
"atontai",
"atontam",
"atontas",
"atontei",
"atontem",
"atontes",
"atonteá",
"atopais",
"atopeis",
"atopema",
"atopemo",
"atopete",
"atopeto",
"atopite",
"atopito",
"atoramo",
"atorará",
"atoremo",
"atorreá",
"atorroe",
"atorroo",
"atorroá",
"atorçoe",
"atorçoo",
"atorçoá",
"atoucai",
"atoucas",
"atoucei",
"atoucem",
"atouces",
"atoucou",
"atouque",
"atouçam",
"atoídea",
"atoídeo",
"atramai",
"atramam",
"atramas",
"atramei",
"atramem",
"atrames",
"atramou",
"atranas",
"atranos",
"atrapai",
"atrecei",
"atrecem",
"atreces",
"atreceu",
"atrecia",
"atredai",
"atredam",
"atredar",
"atredas",
"atredei",
"atredem",
"atredes",
"atredou",
"atreite",
"atreles",
"atremai",
"atremam",
"atremas",
"atremei",
"atremem",
"atremes",
"atremia",
"atrepai",
"atrepas",
"atrepei",
"atrepem",
"atrepes",
"atreçam",
"atreças",
"atriate",
"atrigai",
"atrigam",
"atrigas",
"atrigou",
"atriota",
"atristo",
"atritai",
"atritei",
"atroais",
"atroamo",
"atrodia",
"atroeis",
"atroemo",
"atrolai",
"atrolam",
"atrolas",
"atrolei",
"atrolem",
"atroles",
"atrolou",
"atrombo",
"atronai",
"atronal",
"atronam",
"atronas",
"atronei",
"atronem",
"atrones",
"atronia",
"atropai",
"atropam",
"atropei",
"atropem",
"atropes",
"atropou",
"atroçoa",
"atroçoe",
"atroçoo",
"atrunco",
"atruncá",
"atrusai",
"atrusam",
"atrusas",
"atrusei",
"atrusem",
"atruses",
"atrusou",
"atuadão",
"atuaras",
"atuarmo",
"atubiba",
"atubibe",
"atubibo",
"atucano",
"atufais",
"atufamo",
"atufeis",
"atufemo",
"atuidor",
"atuidão",
"atuindo",
"atuirei",
"atuiria",
"atuirás",
"atuirão",
"atulhes",
"atumbas",
"atundos",
"atuosas",
"atupais",
"atupiam",
"atupias",
"aturdam",
"aturdis",
"atureis",
"atureja",
"atureje",
"aturejo",
"aturemo",
"aturgai",
"aturgam",
"aturgar",
"aturgas",
"aturgem",
"aturges",
"aturgia",
"aturgir",
"aturgis",
"aturgiu",
"aturgou",
"aturgue",
"aturjam",
"aturjas",
"aturreá",
"aturvai",
"aturvam",
"aturvar",
"aturvas",
"aturvei",
"aturvem",
"aturves",
"aturvou",
"atutias",
"atuídas",
"atuídos",
"atuíeis",
"atuímos",
"atuíram",
"atuírem",
"atuíres",
"atuísse",
"atuíste",
"atáleas",
"atálica",
"atálico",
"atáveis",
"atânase",
"atélabo",
"atélica",
"atípida",
"atíquio",
"atócios",
"atúduas",
"aucrana",
"audiano",
"auditai",
"audites",
"audiões",
"auerita",
"auerite",
"auerito",
"augadas",
"augador",
"augadão",
"augalhe",
"augalho",
"augalhá",
"augamos",
"augando",
"augante",
"augaram",
"augarei",
"augarem",
"augares",
"augaria",
"augarás",
"augarão",
"augaste",
"augavam",
"augavas",
"augindo",
"augites",
"augueis",
"augíada",
"augíeis",
"auiquis",
"aularca",
"auletas",
"aulidas",
"aulidor",
"aulindo",
"auliram",
"aulirem",
"auliria",
"aulirão",
"aulisse",
"aulopos",
"aulorai",
"auloram",
"aulorar",
"auloras",
"aulorei",
"aulorem",
"aulores",
"aulorou",
"aulácia",
"aulônio",
"aunador",
"aunadão",
"aunamos",
"aunante",
"aunarei",
"aunarem",
"aunares",
"aunaria",
"aunarmo",
"aunarás",
"aunarão",
"aunasse",
"aunaste",
"aunavam",
"aunavas",
"aunemos",
"auradas",
"aurador",
"auradão",
"auramos",
"auranas",
"aurando",
"auraram",
"aurarei",
"aurarem",
"aurares",
"aurarmo",
"aurarás",
"aurarão",
"aurasse",
"auraste",
"auravas",
"auremos",
"auretos",
"auricol",
"aurigos",
"aurilas",
"aurinos",
"auriram",
"auriras",
"aurirei",
"aurirem",
"aurires",
"auriria",
"aurirmo",
"aurirão",
"aurisse",
"aurista",
"auriste",
"auropós",
"auroraú",
"aurorei",
"aurorem",
"auroreá",
"aurosas",
"auríeis",
"ausonas",
"auspico",
"auspicá",
"austada",
"austado",
"austais",
"austamo",
"austará",
"austava",
"austeis",
"austemo",
"autecos",
"auteira",
"autente",
"autoica",
"autuamo",
"autueis",
"autuemo",
"autália",
"autécio",
"auxenia",
"auxeses",
"auxilai",
"auxilas",
"auxiles",
"auxitas",
"avacate",
"avacote",
"avacuai",
"avacuam",
"avacuas",
"avacuem",
"avacues",
"avacuou",
"avaduta",
"avagare",
"avagaro",
"avagará",
"avalais",
"avalamo",
"avalava",
"avaleis",
"avalemo",
"avaloai",
"avaloam",
"avaloas",
"avaloei",
"avaloem",
"avaloes",
"avaloou",
"avaluai",
"avaluam",
"avaluas",
"avaluei",
"avaluem",
"avalues",
"avambas",
"avandis",
"avangai",
"avangam",
"avangas",
"avarada",
"avarari",
"avargai",
"avargam",
"avargar",
"avargou",
"avargue",
"avariai",
"avarita",
"avasita",
"avatada",
"avatado",
"avatais",
"avatamo",
"avatava",
"avateis",
"avatemo",
"aveadas",
"aveados",
"aveclas",
"avejate",
"avelamo",
"avelava",
"aveleis",
"avelemo",
"avelhai",
"avelham",
"avelhei",
"avelhem",
"avelhes",
"avelhou",
"avelude",
"avenada",
"avenais",
"avenhas",
"avenose",
"avençai",
"averana",
"averbai",
"averbes",
"avergai",
"avergam",
"avergem",
"averges",
"avergia",
"avergir",
"avergis",
"avergiu",
"avergoa",
"avergoe",
"avergoo",
"avergou",
"avergue",
"averroa",
"avertam",
"avertei",
"avertes",
"averteu",
"avertia",
"avespão",
"avessai",
"avessem",
"avexais",
"avexamo",
"avexará",
"avexemo",
"avezais",
"avezamo",
"avezará",
"avezava",
"avezeis",
"avezemo",
"aviacas",
"aviadão",
"aviarei",
"aviarmo",
"aviavas",
"avicela",
"avicule",
"aviculo",
"avidada",
"avidais",
"avidamo",
"avidara",
"avidava",
"avideis",
"avidemo",
"avidona",
"avidora",
"avidões",
"avieras",
"avierem",
"avieres",
"aviermo",
"aviesai",
"aviesam",
"aviesar",
"aviesas",
"aviesei",
"aviesem",
"avieses",
"aviesou",
"avieste",
"avilada",
"avilado",
"avilais",
"avilamo",
"avilane",
"avilará",
"avilava",
"avildai",
"avildam",
"avildar",
"avildas",
"avildei",
"avildem",
"avildes",
"avildou",
"avilemo",
"aviltai",
"avincai",
"avincam",
"avincas",
"avincou",
"avindes",
"avingai",
"avingam",
"avingar",
"avingas",
"avingou",
"avingue",
"avinhai",
"avinhas",
"avinhei",
"avinhem",
"avinhes",
"avinque",
"avirana",
"avirdes",
"avireis",
"aviremo",
"aviriam",
"avirmos",
"avisemo",
"avistai",
"avisuga",
"aviuzar",
"avivamo",
"avivemo",
"avoacei",
"avoacem",
"avoaces",
"avoamos",
"avoaras",
"avoarei",
"avoares",
"avoaria",
"avoarmo",
"avoarão",
"avoaste",
"avoavas",
"avoaçai",
"avoaças",
"avoaçou",
"avocais",
"avoejai",
"avoejam",
"avoejas",
"avoejei",
"avoejem",
"avoejes",
"avoejou",
"avoemos",
"avoiras",
"avondai",
"avondam",
"avondas",
"avondei",
"avondem",
"avondes",
"avondos",
"avondou",
"avoques",
"avorina",
"avosada",
"avosais",
"avosamo",
"avosara",
"avosava",
"avoseis",
"avosemo",
"avozeai",
"avozeei",
"avozeia",
"avozeie",
"avozeio",
"avozeou",
"avultai",
"avultei",
"avultes",
"avuscas",
"avècido",
"avélroa",
"avérnea",
"axaguás",
"axapona",
"axaraca",
"axelhos",
"axicara",
"axicare",
"axicaro",
"axineia",
"axinito",
"axipaie",
"axocela",
"axoideo",
"axoides",
"axonges",
"axonope",
"axorcai",
"axorcam",
"axorcou",
"axorque",
"axuajus",
"axungie",
"axungio",
"axífera",
"axínica",
"axínico",
"axólise",
"azabona",
"azadona",
"azadora",
"azadões",
"azafada",
"azafado",
"azafame",
"azafamo",
"azagaie",
"azagaio",
"azagais",
"azainas",
"azalzão",
"azamboe",
"azamboo",
"azangai",
"azangam",
"azangas",
"azangou",
"azangue",
"azantes",
"azaolas",
"azaquis",
"azarais",
"azaramo",
"azardes",
"azareis",
"azaremo",
"azariam",
"azarmos",
"azarolo",
"azassem",
"azasses",
"azastes",
"azatepa",
"azavere",
"azazéis",
"azebibe",
"azebrai",
"azebram",
"azebrei",
"azebrem",
"azebrou",
"azebuai",
"azebuam",
"azebuas",
"azebuei",
"azebuem",
"azebues",
"azebuou",
"azeches",
"azedais",
"azedamo",
"azedeis",
"azedemo",
"azeduns",
"azedéns",
"azegues",
"azeirar",
"azeitai",
"azelzão",
"azerada",
"azerais",
"azeramo",
"azerará",
"azerava",
"azerbes",
"azereis",
"azeremo",
"azetino",
"azevrai",
"azevram",
"azevras",
"azevrei",
"azevrem",
"azevres",
"azevrou",
"azevões",
"aziches",
"azidina",
"azigite",
"azilina",
"azimbas",
"azimias",
"azimida",
"azinabã",
"azinaga",
"azincho",
"aziumai",
"aziumam",
"aziumas",
"aziumei",
"aziumem",
"azoador",
"azoadão",
"azoamos",
"azoante",
"azoarei",
"azoarem",
"azoares",
"azoaria",
"azoarmo",
"azoarás",
"azoarão",
"azoasse",
"azoaste",
"azoavam",
"azoavas",
"azodóis",
"azoeire",
"azoeiro",
"azoemos",
"azogada",
"azoiana",
"azoiano",
"azoicas",
"azoinai",
"azoinam",
"azoinas",
"azoinei",
"azoines",
"azoinou",
"azonada",
"azonado",
"azonzai",
"azonzam",
"azonzas",
"azonzei",
"azonzem",
"azonzes",
"azonzou",
"azorate",
"azorato",
"azorete",
"azoreto",
"azorrai",
"azorram",
"azorras",
"azorrei",
"azorrem",
"azorrou",
"azosmas",
"azotais",
"azotamo",
"azotará",
"azotava",
"azoteis",
"azotemo",
"azotilo",
"azotina",
"azotita",
"azotize",
"azotizo",
"azotizá",
"azotosa",
"azotóis",
"azougai",
"azougam",
"azougas",
"azuelas",
"azulais",
"azulamo",
"azuleai",
"azuleei",
"azuleie",
"azuleis",
"azulemo",
"azuleou",
"azuleça",
"azuleço",
"azumbai",
"azumbam",
"azumbar",
"azumbas",
"azumbei",
"azumbem",
"azumbes",
"azumbou",
"azumbro",
"azurada",
"azurais",
"azuramo",
"azurava",
"azureis",
"azuremo",
"azurino",
"azurnai",
"azurnam",
"azurnar",
"azurnas",
"azurnei",
"azurnem",
"azurnes",
"azurnou",
"azurrai",
"azurrei",
"azurrem",
"azurres",
"azurrou",
"azurzam",
"azurzas",
"azurzem",
"azurzes",
"azurzia",
"azurzis",
"azurziu",
"azálios",
"azáveis",
"azélias",
"azélide",
"azêmala",
"azídica",
"azígica",
"azígico",
"azímica",
"azótana",
"azótica",
"azótido",
"azóxido",
"azúrica",
"azúrico",
"açacuís",
"açadacã",
"açafato",
"açafrar",
"açafras",
"açafroe",
"açafroo",
"açafões",
"açaimai",
"açaimei",
"açaimem",
"açalhai",
"açalham",
"açalhar",
"açalhas",
"açalhei",
"açalhem",
"açalhes",
"açalhou",
"açalmos",
"açaloie",
"açaloio",
"açaloiá",
"açameis",
"açamemo",
"açanico",
"açanicá",
"açapada",
"açapais",
"açapamo",
"açapava",
"açapeis",
"açapemo",
"açobada",
"açobado",
"açobais",
"açobamo",
"açobara",
"açobava",
"açobeis",
"açobemo",
"açodais",
"açodamo",
"açodeis",
"açodemo",
"açoeira",
"açoeiro",
"açofras",
"açogbás",
"açoitai",
"açorada",
"açorais",
"açoramo",
"açorará",
"açoreis",
"açoremo",
"açorito",
"açorzão",
"açoteai",
"açoteei",
"açoteie",
"açoteio",
"açoutas",
"açoutei",
"açucres",
"açudará",
"açudava",
"açudeis",
"açudemo",
"açudres",
"açulais",
"açulamo",
"açuleis",
"açulemo",
"açumbre",
"aósmica",
"aósmico",
"aônidas",
"baalzão",
"baamesa",
"baanita",
"babadés",
"babalas",
"babanim",
"babaras",
"babatai",
"babatam",
"babatei",
"babates",
"babauas",
"babaços",
"babeais",
"babeará",
"babeava",
"babecas",
"babeces",
"babeeis",
"babeias",
"babeiem",
"babeies",
"babilha",
"babocas",
"baboliá",
"babongo",
"baborés",
"baboseá",
"babucos",
"babuero",
"babuges",
"babujai",
"babujas",
"babumas",
"babunda",
"babuzai",
"babuzam",
"babuzas",
"babuzei",
"babuzem",
"babuzes",
"babuzou",
"bacaaís",
"bacalés",
"bacamba",
"bacango",
"bacaros",
"bacaréu",
"bacazis",
"bacelai",
"bacelam",
"bacelas",
"bacelei",
"bacelem",
"baceles",
"bacelou",
"bacetas",
"bachico",
"bachiné",
"bachuco",
"bachucá",
"baciana",
"baciará",
"baciava",
"bacieis",
"baciemo",
"bacieta",
"bacilas",
"bacoani",
"bacuaís",
"bacubai",
"bacudas",
"bacumbu",
"bacundo",
"bacupãs",
"bacusso",
"bacutos",
"bacuvas",
"bacuína",
"bacuóis",
"bacácea",
"bacásia",
"bacázio",
"bacânea",
"bacâneo",
"bacídia",
"badajes",
"badaleá",
"badamos",
"badamus",
"badanam",
"badanei",
"badanem",
"badanes",
"badanou",
"badaroa",
"badelas",
"badeles",
"badeleá",
"badelos",
"badicas",
"badicos",
"badigós",
"badinga",
"badista",
"badiste",
"badisto",
"badiões",
"badocho",
"badofes",
"badonas",
"badorai",
"badoram",
"badoras",
"badorem",
"badorou",
"badoéns",
"baducas",
"badulão",
"badusas",
"badâmia",
"baelebé",
"baetais",
"baetona",
"baetões",
"baeunas",
"bafador",
"bafados",
"bafadão",
"bafamos",
"bafante",
"bafarei",
"bafarem",
"bafares",
"bafaria",
"bafaris",
"bafarmo",
"bafarás",
"bafarão",
"bafasse",
"bafaste",
"bafavam",
"bafavas",
"bafejas",
"bafejes",
"bafemos",
"bafetás",
"bafiina",
"bafiosa",
"bafoira",
"baforai",
"baforas",
"baforde",
"bafores",
"bafoura",
"bafuges",
"bafumes",
"bafunta",
"bafunte",
"bafunto",
"bafurde",
"bafurdo",
"bafurdá",
"bagador",
"bagados",
"bagalas",
"bagamos",
"baganho",
"bagante",
"baganço",
"bagarei",
"bagares",
"bagarim",
"bagarmi",
"bagarão",
"bagaste",
"bagaudo",
"bagavam",
"bagavas",
"bagaxas",
"bageada",
"bageais",
"bageará",
"bageava",
"bageeis",
"bageiam",
"bageias",
"bageiem",
"bageies",
"bageris",
"bagiros",
"bagoais",
"bagoamo",
"bagoará",
"bagoava",
"bagobos",
"bagoeis",
"bagoemo",
"bagresa",
"bagudos",
"bagueai",
"bagueei",
"bagueie",
"bagueio",
"bagueou",
"baguins",
"baguixe",
"baguiás",
"bagumes",
"baguris",
"baiacos",
"baiadas",
"baiador",
"baiados",
"baiadão",
"baiagus",
"baiaiás",
"baiamos",
"baiapua",
"baiarei",
"baiarem",
"baiares",
"baiaria",
"baiarmo",
"baiarás",
"baiarão",
"baiasse",
"baiaste",
"baiavam",
"baiavas",
"baibiri",
"baicada",
"baicado",
"baicais",
"baicamo",
"baicari",
"baicava",
"baieira",
"baieiro",
"baieiés",
"baiemos",
"baietes",
"baifino",
"bailamo",
"bailata",
"baileia",
"bailemo",
"bailhai",
"bailham",
"bailhei",
"bailhem",
"bailhou",
"bailéya",
"bainhai",
"bainhei",
"bainhes",
"bainhou",
"bainica",
"baionas",
"baipiri",
"baiquei",
"baiquem",
"bairiri",
"bairões",
"baiânis",
"bajacus",
"bajadão",
"bajanas",
"bajaram",
"bajarei",
"bajarem",
"bajares",
"bajarmo",
"bajarão",
"bajavam",
"bajavas",
"bajeada",
"bajeado",
"bajeais",
"bajeará",
"bajeava",
"bajecas",
"bajeeis",
"bajeiam",
"bajeias",
"bajeiem",
"bajeies",
"bajerês",
"bajetos",
"bajirus",
"bajocos",
"bajogai",
"bajogam",
"bajogas",
"bajogou",
"bajogue",
"bajouje",
"bajouta",
"bajudes",
"bajulai",
"bajuris",
"balafós",
"balalas",
"balames",
"balamos",
"balanto",
"balarão",
"balatos",
"balaxes",
"balbuta",
"balbute",
"baldamo",
"baldará",
"baldeai",
"baldeie",
"baldeis",
"baldemo",
"baldoai",
"baldoam",
"baldoas",
"baldoei",
"baldoem",
"baleamo",
"balecai",
"balecam",
"balecas",
"balecou",
"baleeis",
"baleemo",
"baleiem",
"balemas",
"balempa",
"balesto",
"balhais",
"balhamo",
"balheis",
"balhemo",
"balhote",
"balhões",
"balidor",
"balilis",
"baliras",
"balirei",
"balires",
"baliria",
"balirmo",
"balirão",
"balizai",
"balneai",
"balneei",
"balneia",
"balneie",
"balneio",
"balneou",
"baloais",
"baloamo",
"baloana",
"baloará",
"baloava",
"baloeis",
"baloemo",
"balonda",
"balotai",
"balotam",
"balotar",
"balotei",
"balotem",
"balotou",
"balroai",
"balroam",
"balroei",
"balroem",
"balroes",
"balroou",
"balsado",
"balsais",
"balsava",
"balseis",
"balsemo",
"balucre",
"baluena",
"balugão",
"balules",
"balumas",
"balurde",
"balíeis",
"bambais",
"bambamo",
"bambava",
"bambaês",
"bambeai",
"bambeis",
"bambemo",
"bambera",
"bambiás",
"bamboai",
"bamboam",
"bamboei",
"bamboem",
"bamboes",
"bamboão",
"bambudo",
"bambura",
"bamporã",
"banamos",
"banaros",
"bancalé",
"bancamo",
"bancudu",
"bandamo",
"bandava",
"bandaze",
"bandeai",
"bandins",
"bandoai",
"bandoam",
"bandoar",
"bandoba",
"bandoei",
"bandoem",
"bandoga",
"bandrás",
"banfumo",
"bangbas",
"bangial",
"bangole",
"banguiá",
"bangulo",
"bangãos",
"banheis",
"baniara",
"baninis",
"banirmo",
"banjura",
"bantino",
"banzamo",
"banzeai",
"banzeei",
"banzeia",
"banzeie",
"banzeio",
"banzeis",
"banzemo",
"banzeou",
"banziri",
"banzões",
"baníeis",
"baoneta",
"baotita",
"bapebas",
"bapeira",
"bapende",
"bapevas",
"bapianá",
"baptina",
"bapuana",
"baquani",
"baqueai",
"baqueja",
"baqueje",
"baquejo",
"baquelo",
"baqueto",
"baquina",
"barabôs",
"barache",
"baracéu",
"baraias",
"barajos",
"baramas",
"barapos",
"barares",
"bararás",
"baraxus",
"baraúva",
"barbamo",
"barbava",
"barbeai",
"barbemo",
"barboai",
"barboam",
"barboar",
"barboas",
"barboei",
"barboem",
"barboes",
"barboou",
"barbota",
"barbuna",
"barcano",
"barceia",
"bardamo",
"bardava",
"bardaxa",
"bardeia",
"bardeis",
"bardemo",
"bardeus",
"bardias",
"bardito",
"bardões",
"bareias",
"barexas",
"barfóis",
"bargani",
"barilas",
"barimbé",
"bariome",
"baripas",
"bariris",
"barites",
"baroada",
"baroces",
"baromas",
"barqueá",
"barreai",
"barreje",
"barrejo",
"barrejá",
"barriam",
"barroai",
"barroei",
"barroem",
"barrufe",
"barruço",
"barsins",
"barujos",
"barurus",
"barutos",
"barvade",
"barzóis",
"barêmio",
"basador",
"basadão",
"basalar",
"basanas",
"basanés",
"basarei",
"basarem",
"basares",
"basarmo",
"basarás",
"basarão",
"basaréu",
"basasse",
"basaste",
"basavam",
"basavas",
"bascame",
"bascamo",
"baseeis",
"baselas",
"basemos",
"basenga",
"basiais",
"basides",
"basiial",
"basilas",
"basises",
"basites",
"basofas",
"basofie",
"basofio",
"bassuto",
"basteai",
"basteci",
"basteei",
"basteia",
"basteio",
"basteis",
"basteou",
"basteça",
"bastito",
"batacas",
"batages",
"batalós",
"batanda",
"batanos",
"batarás",
"batauás",
"bateais",
"bateará",
"batecar",
"batecas",
"batecol",
"bateeis",
"bategai",
"bategam",
"bategar",
"bategou",
"bateiai",
"bateiar",
"bateiei",
"bateiem",
"bateies",
"bateiou",
"batetês",
"batidal",
"batilol",
"batisas",
"batissa",
"batités",
"batocai",
"batocam",
"batocou",
"batoide",
"batoilo",
"batoiro",
"batosas",
"batotai",
"batotam",
"batotei",
"batotem",
"batotes",
"batoteá",
"batotou",
"batoulo",
"batoure",
"batouro",
"batourá",
"batudas",
"batáceo",
"batélia",
"batério",
"batésia",
"batíada",
"batíbio",
"batídea",
"batídeo",
"bauanas",
"bauaris",
"bauaçus",
"bauense",
"baueras",
"baulzão",
"baumita",
"baurins",
"bautize",
"bavinas",
"baxadas",
"baxalia",
"baxanas",
"baxetes",
"baxiará",
"baxinxe",
"baxixes",
"baxixás",
"baxiúba",
"bazemal",
"bazofas",
"bazofie",
"bazofos",
"bazzita",
"bazânia",
"bdélios",
"beaquea",
"beatões",
"bebearu",
"bebenas",
"beberai",
"bebermo",
"beberou",
"beberus",
"bebesto",
"bebinga",
"bebirus",
"bebudos",
"bebélis",
"becapai",
"becapam",
"becapar",
"becapas",
"becapei",
"becapem",
"becapou",
"becunas",
"becuíba",
"bedalha",
"bedalço",
"bedeada",
"bedeado",
"bedeais",
"bedeara",
"bedeava",
"bedeeis",
"bedeiam",
"bedeias",
"bedeiem",
"bedeies",
"bedelas",
"bedelhe",
"bediões",
"bedoira",
"bedulha",
"bedulho",
"bedúsis",
"beenéis",
"beerite",
"befamis",
"befária",
"befécio",
"begarim",
"begumes",
"begúnia",
"behoíta",
"beiceis",
"beicemo",
"beiinhã",
"beinhãs",
"beiramo",
"beiremo",
"beiçais",
"beiçamo",
"beiçará",
"beiçava",
"beiçosa",
"beiçoso",
"beiéria",
"bejabãs",
"bejalas",
"bejeira",
"bejeiro",
"bejogas",
"bejucos",
"bejulas",
"bejária",
"beladis",
"belafão",
"belalas",
"belambo",
"belanta",
"beldada",
"beldado",
"beldais",
"beldamo",
"beldava",
"beldeis",
"beldemo",
"beldres",
"beldões",
"belecai",
"belecam",
"belecas",
"belecou",
"beledis",
"belelês",
"belenas",
"beleuas",
"belfuda",
"belgite",
"belilas",
"belizes",
"beloire",
"beloirá",
"belopeu",
"belopos",
"beloure",
"belsofe",
"belucas",
"belulco",
"beluosa",
"belutas",
"belvais",
"belveda",
"belvedi",
"belvedo",
"belveis",
"belvemo",
"belverá",
"belviam",
"belvias",
"belvido",
"belzões",
"belêmia",
"belócia",
"belótia",
"belônia",
"bembara",
"bembéis",
"bemolai",
"bemolam",
"bemolas",
"bemolei",
"bemolem",
"bemolou",
"benadas",
"benados",
"bendara",
"benefes",
"beneque",
"benetes",
"benfará",
"benfaça",
"benfaço",
"bengiri",
"benguês",
"benjoís",
"benterê",
"benténs",
"benzais",
"benzamo",
"benzias",
"beotice",
"bequeia",
"beracas",
"berates",
"beraúna",
"berbaia",
"berbins",
"berbião",
"berbões",
"berceis",
"bercemo",
"berebis",
"berebés",
"berevas",
"bergela",
"bergões",
"berides",
"beridos",
"berifão",
"berijas",
"berivas",
"berjoga",
"berlata",
"bermana",
"bernaco",
"berneai",
"bernear",
"berneei",
"berneie",
"berneio",
"berneou",
"bernica",
"bernosa",
"bernoso",
"berobas",
"berosas",
"berrais",
"berramo",
"berreis",
"berremo",
"berroas",
"bervaiá",
"berçado",
"berçais",
"berçamo",
"berçará",
"berçava",
"berçola",
"berçuda",
"beríjia",
"berínea",
"beríneo",
"besanta",
"besanto",
"besoire",
"besoirá",
"besolha",
"besoure",
"bespiça",
"bespões",
"bestamo",
"bestear",
"besteis",
"bestemo",
"bestião",
"betador",
"betadão",
"betante",
"betaram",
"betarem",
"betares",
"betarmo",
"betarão",
"betaste",
"betavam",
"betavas",
"betecai",
"betecam",
"betecar",
"betecos",
"betecou",
"betelal",
"beteque",
"betilas",
"betoira",
"betoiro",
"betonai",
"betonam",
"betonei",
"betonem",
"betouca",
"betoura",
"betrais",
"betumai",
"betumam",
"betumas",
"betásio",
"betúlea",
"betúrio",
"beusita",
"bevônio",
"bexigai",
"bexigou",
"bexigue",
"bexucos",
"bexugos",
"bezoada",
"bezoado",
"bezoará",
"bezoava",
"bezogos",
"beático",
"beânias",
"beótico",
"beúrias",
"bialilo",
"biamilo",
"biandas",
"bianejo",
"biangos",
"biaquéu",
"biarões",
"biastas",
"biatlos",
"bibagas",
"bibicai",
"bibicam",
"bibicou",
"bibindi",
"bibique",
"bicanes",
"bicaras",
"bicarei",
"bicares",
"bicarmo",
"bicarós",
"bicaste",
"bicatos",
"bicaude",
"bicavas",
"bicaços",
"bichais",
"bichane",
"bicheis",
"bichemo",
"bicilie",
"bicilio",
"bicomas",
"bicorai",
"bicoram",
"bicoras",
"bicorei",
"bicorem",
"bicores",
"bicorou",
"bicosas",
"bicosos",
"bicotai",
"bicotam",
"bicotei",
"bicotem",
"bicotes",
"bicotou",
"bicuíva",
"bideiro",
"bidense",
"bideros",
"bidetos",
"bidinos",
"bidiões",
"bidumas",
"bidária",
"bifador",
"bifados",
"bifadão",
"bifando",
"bifante",
"bifaras",
"bifarei",
"bifarem",
"bifarmo",
"bifarão",
"bifasse",
"bifavam",
"bifavas",
"bifemos",
"bifilos",
"bifolco",
"bifólia",
"bifórea",
"bifóreo",
"bigalhó",
"bigarim",
"bigates",
"bigemia",
"bigerro",
"bigodas",
"bigodeá",
"bigolas",
"biguada",
"biguais",
"biguará",
"biguava",
"bigueis",
"bigundo",
"bigária",
"bigário",
"bigâmeo",
"bigêmea",
"bigúnia",
"bijacos",
"bijagas",
"bijarro",
"bijorro",
"bilador",
"bilados",
"biladão",
"bilamos",
"bilaram",
"bilarei",
"bilarem",
"bilargo",
"bilaria",
"bilarmo",
"bilarás",
"bilarão",
"bilasse",
"bilaste",
"bilatos",
"bilavam",
"bilavas",
"bilemia",
"bilemos",
"bilenes",
"bileuas",
"bilhoca",
"bilices",
"bilimis",
"bilinos",
"biliras",
"bilotos",
"bilrado",
"bilrais",
"bilramo",
"bilrará",
"bilrava",
"bilreis",
"bilremo",
"bilócia",
"bilótia",
"bimbais",
"bimbamo",
"bimbara",
"bimbeis",
"bimbemo",
"biméria",
"binador",
"binadão",
"binante",
"binarei",
"binarem",
"binarmo",
"binarás",
"binarão",
"binaste",
"binatos",
"binavas",
"bindera",
"binemos",
"bingais",
"binguei",
"bingues",
"binites",
"binótia",
"bioaere",
"bioaero",
"bioaerá",
"bioerma",
"bioneon",
"bionose",
"biopses",
"biopsio",
"biotito",
"biovule",
"biovulo",
"biovulá",
"bipadas",
"bipadão",
"bipanga",
"bipante",
"biparei",
"biparem",
"bipares",
"biparmo",
"biparta",
"biparto",
"biparão",
"bipaste",
"bipavas",
"bipemos",
"bipenes",
"bipálio",
"biqueai",
"biquear",
"biqueca",
"biqueei",
"biqueia",
"biqueie",
"biqueio",
"biqueis",
"biquemo",
"biqueou",
"birarós",
"biraúna",
"biribuí",
"biricai",
"biricam",
"biricou",
"birifos",
"birique",
"biritei",
"biritem",
"birites",
"biriçós",
"birmolo",
"birobas",
"birolas",
"birorós",
"birrais",
"birramo",
"birrara",
"birrava",
"birreis",
"birreto",
"birrota",
"birruda",
"birubas",
"birutai",
"birutam",
"birutar",
"birutei",
"birutem",
"birônia",
"bisador",
"bisadão",
"bisagos",
"bisanas",
"bisarei",
"bisares",
"bisarmo",
"bisarão",
"bisaste",
"bisavam",
"bisação",
"biscada",
"biscamo",
"biscará",
"biscuís",
"biseira",
"biselai",
"biselam",
"biselas",
"biselei",
"biselem",
"bisemos",
"bisgaia",
"bisinas",
"bismona",
"bisnaca",
"bisnato",
"bisonde",
"bisonhe",
"bisotai",
"bisotam",
"bisotas",
"bisotei",
"bisotem",
"bisotou",
"bispamo",
"bispeis",
"bispemo",
"bispiça",
"bisquem",
"bisseis",
"bissetá",
"bissexa",
"bistori",
"bistres",
"bisulco",
"bitacos",
"bitador",
"bitados",
"bitadão",
"bitafes",
"bitamos",
"bitaram",
"bitarei",
"bitarem",
"bitares",
"bitaria",
"bitarmo",
"bitarão",
"bitasse",
"bitaste",
"bitatás",
"bitavam",
"bitavas",
"biteira",
"bitemos",
"bitinos",
"bitióis",
"bitolai",
"bitoles",
"bitoncé",
"bitoncó",
"bitonta",
"bitrado",
"bitrais",
"bitramo",
"bitrará",
"bitrava",
"bitreis",
"bitremo",
"bitumai",
"bitumam",
"bitumas",
"bitumei",
"bitumem",
"bitumes",
"bitumou",
"bituros",
"bituíra",
"bityíta",
"biungos",
"bivacai",
"bivacas",
"bivalam",
"bivalas",
"bivalei",
"bivalem",
"bivaler",
"bivales",
"bivaleu",
"bivalia",
"bixinas",
"bixoxos",
"bixurro",
"bixáceo",
"bixínea",
"bixíneo",
"bizeros",
"bizuque",
"biáreas",
"biáreos",
"biélida",
"biênica",
"biófaga",
"biósofo",
"biótomo",
"biótono",
"biômero",
"biônima",
"blabosa",
"blatere",
"blatero",
"blefais",
"blefamo",
"blefeis",
"blefemo",
"blindai",
"blocais",
"blocamo",
"blocará",
"blátida",
"boadona",
"boadora",
"boadões",
"boalvos",
"boalzão",
"boambas",
"boantes",
"boanças",
"boaremo",
"boariam",
"boarmos",
"boassem",
"boasses",
"boatais",
"boatamo",
"boatará",
"boateis",
"boatemo",
"bobador",
"bobadão",
"bobamos",
"bobante",
"bobaram",
"bobarei",
"bobares",
"bobaria",
"bobarmo",
"bobarás",
"bobasse",
"bobatas",
"bobavam",
"bobavas",
"bobeais",
"bobeamo",
"bobeeis",
"bobeemo",
"bobeiai",
"bobemos",
"bobinai",
"bobinem",
"bobocai",
"bobocam",
"bobocar",
"bobocou",
"boburés",
"bobícia",
"bobótis",
"bocador",
"bocanas",
"bocanhe",
"bocante",
"bocaram",
"bocarem",
"bocares",
"bocaria",
"bocarmo",
"bocarás",
"bocarão",
"bocaréu",
"bocaste",
"bocavam",
"bocavas",
"bocaxis",
"bocaças",
"bocejai",
"bocelai",
"bocelam",
"bocelas",
"bocelei",
"bocelem",
"boceles",
"bocelou",
"bocemos",
"bochais",
"bochamo",
"bochará",
"bocheis",
"bochemo",
"bociada",
"bociado",
"bociosa",
"bocioso",
"bocotas",
"bocubas",
"bocuvas",
"bocágea",
"bocídio",
"bocória",
"bodador",
"bodadão",
"bodamos",
"bodanas",
"bodante",
"bodaram",
"bodarei",
"bodarem",
"bodares",
"bodaria",
"bodarmo",
"bodarás",
"bodarão",
"bodasse",
"bodaste",
"bodavam",
"bodavas",
"bodefes",
"bodegos",
"bodejai",
"bodejam",
"bodejas",
"bodejei",
"bodejem",
"bodejes",
"bodejou",
"bodiame",
"bodiamo",
"bodiana",
"bodigos",
"bodioas",
"bodiona",
"bodivas",
"bodivos",
"bodlega",
"bodonas",
"bodonis",
"bodriés",
"bofadas",
"bofador",
"bofados",
"bofadão",
"bofamos",
"bofando",
"bofaram",
"bofarei",
"bofarem",
"bofares",
"bofaria",
"bofarmo",
"bofarás",
"bofarão",
"bofasse",
"bofaste",
"bofavam",
"bofavas",
"bofejai",
"bofejam",
"bofejar",
"bofejas",
"bofejei",
"bofejem",
"bofejes",
"bofejou",
"bofemos",
"bofetai",
"bofetam",
"bofetar",
"bofetei",
"bofetem",
"bofeteá",
"bofetos",
"bofetou",
"boforos",
"bogacho",
"bogadas",
"bogador",
"bogados",
"bogadão",
"bogamos",
"bogaram",
"bogarem",
"bogares",
"bogaria",
"bogarás",
"bogarão",
"bogasse",
"bogaste",
"bogavam",
"bogavas",
"bogaxos",
"bogueis",
"bogunha",
"boiacas",
"boiamas",
"boiaras",
"boiarmo",
"boiatos",
"boiavas",
"boicaás",
"boiceis",
"boicemo",
"boicera",
"boidana",
"boiemos",
"boinona",
"boiobis",
"boiombo",
"boionas",
"boirada",
"boirado",
"boirais",
"boiramo",
"boirará",
"boirava",
"boireis",
"boiremo",
"boiubis",
"boiubus",
"boiunos",
"boiçada",
"boiçais",
"boiçamo",
"boiçará",
"boiçava",
"bojadas",
"bojadão",
"bojagas",
"bojamos",
"bojanga",
"bojança",
"bojaras",
"bojarei",
"bojarem",
"bojares",
"bojaria",
"bojarmo",
"bojarão",
"bojasse",
"bojaste",
"bojavas",
"bojegas",
"bojegos",
"bojemos",
"bojobis",
"bojéria",
"bolache",
"bolapés",
"bolaras",
"bolarmo",
"bolaros",
"bolaste",
"bolatas",
"bolatra",
"bolavas",
"bolcamo",
"bolceis",
"bolcemo",
"boldóis",
"boleais",
"boleará",
"boleeis",
"bolegos",
"boleiem",
"boleies",
"bolenol",
"bolenos",
"boletai",
"boletam",
"boletra",
"bolhaca",
"bolhaco",
"bolhamo",
"bolhara",
"bolheis",
"bolhemo",
"bolicha",
"bolinai",
"bolinem",
"bolines",
"bolisco",
"boliços",
"bolquei",
"bolquem",
"bolques",
"bolsais",
"bolsamo",
"bolsará",
"bolseis",
"bolsemo",
"bolçais",
"bolçará",
"bolétea",
"boléteo",
"bolínia",
"bombagi",
"bombais",
"bombavo",
"bombeai",
"bombeis",
"bombeje",
"bombejo",
"bombemo",
"bombiai",
"bombiam",
"bombias",
"bombiem",
"bombilo",
"bombins",
"bombiou",
"bomeria",
"bomoros",
"bonaias",
"bonceis",
"boncemo",
"bondais",
"bondamo",
"bondeis",
"bondemo",
"bonecai",
"bonefre",
"bonejas",
"bongais",
"bongaro",
"bongono",
"bonguei",
"bonguem",
"bonguis",
"bonhada",
"bonhado",
"bonhais",
"bonhamo",
"bonhará",
"bonhava",
"bonheis",
"bonhemo",
"bonicas",
"bonseai",
"bonsear",
"bonseei",
"bonseia",
"bonseie",
"bonseio",
"bonseou",
"bonteis",
"bonvoro",
"bonâmia",
"bonçada",
"bonçado",
"bonçais",
"bonçamo",
"bonçava",
"bonécia",
"bonélia",
"bonétia",
"boofane",
"boofone",
"bootita",
"bopiano",
"boqueai",
"boqueei",
"boqueis",
"boqueje",
"boquemo",
"boquins",
"boquize",
"boquizo",
"boquizá",
"boraces",
"boragos",
"borazol",
"borbulo",
"borcada",
"borcais",
"borcamo",
"borcana",
"borcano",
"borcará",
"borcava",
"borcela",
"borcele",
"borcelo",
"borcina",
"borcmes",
"bordamo",
"bordate",
"bordeai",
"bordegã",
"bordeio",
"bordemo",
"borneai",
"borneco",
"borneei",
"borneia",
"borneie",
"borneou",
"borquem",
"borrace",
"borraço",
"borremo",
"boseada",
"boseado",
"boseais",
"boseará",
"boseava",
"boseeis",
"boseiam",
"boseias",
"boseiem",
"boseies",
"bosense",
"bossada",
"bossado",
"bossamo",
"bossará",
"bossava",
"bosseis",
"bossemo",
"bossuda",
"bossudo",
"bostamo",
"bostara",
"bostava",
"bosteai",
"bosteei",
"bosteia",
"bosteie",
"bosteio",
"bosteis",
"bostemo",
"bosteou",
"botarmo",
"botauro",
"botecai",
"botecam",
"botecou",
"botefas",
"boteifa",
"boteina",
"botelgo",
"botelva",
"botense",
"botetos",
"botiços",
"botoais",
"botoamo",
"botoará",
"botoava",
"botocai",
"botocam",
"botocas",
"botoeis",
"botoemo",
"botoiro",
"botoris",
"botrilo",
"botrios",
"botídea",
"bouceis",
"boucemo",
"boucera",
"bouchai",
"boucham",
"bouchas",
"bouchem",
"boucins",
"boueias",
"bourada",
"bourado",
"bouramo",
"bourara",
"bourava",
"boureis",
"bouremo",
"bourgia",
"bouçada",
"bouçamo",
"bouçará",
"bouçava",
"bovínea",
"bovíneo",
"boxadas",
"boxados",
"boxadão",
"boxamos",
"boxante",
"boxaram",
"boxarei",
"boxarem",
"boxares",
"boxaria",
"boxarmo",
"boxarás",
"boxarão",
"boxasse",
"boxaste",
"boxavam",
"boxavas",
"boxeais",
"boxeamo",
"boxeara",
"boxeeis",
"boxeemo",
"boxegos",
"boxeias",
"boxeiem",
"boxeies",
"boxemos",
"boxismo",
"boáreis",
"boárias",
"boármia",
"boáveis",
"boçalai",
"boçalam",
"boçalas",
"boçalei",
"boçalem",
"boçales",
"boçalou",
"boídeas",
"boísmos",
"brabada",
"brabais",
"brabamo",
"brabava",
"brabeis",
"brabemo",
"braceai",
"braceei",
"braceje",
"braceus",
"bracobi",
"bracuís",
"bracéis",
"bradamo",
"bradeja",
"bradeje",
"bradejo",
"brademo",
"bradito",
"bragani",
"bragueá",
"braiera",
"bralhai",
"bralham",
"bralhas",
"bralhei",
"bralhem",
"bralhes",
"bralhou",
"bramada",
"bramamo",
"brameai",
"bramear",
"brameei",
"brameia",
"brameie",
"brameio",
"brameis",
"bramemo",
"brameou",
"bramimo",
"brandai",
"brandeá",
"branjos",
"branzas",
"braqueá",
"brarrum",
"brasamo",
"brasará",
"brasava",
"brascas",
"braseai",
"braseei",
"braseio",
"braseis",
"brasemo",
"braseou",
"brasios",
"brasoai",
"brasoam",
"brasoar",
"brasoas",
"brasoei",
"brasoem",
"brasone",
"brasono",
"brasoou",
"braulas",
"bravará",
"braveai",
"braveei",
"braveia",
"braveie",
"braveio",
"braveis",
"bravemo",
"braveou",
"bravuns",
"braxias",
"brazabu",
"breadão",
"breamos",
"breante",
"brearam",
"brearei",
"brearem",
"breares",
"brearia",
"brearás",
"brearão",
"breasse",
"breaste",
"breavam",
"breavas",
"brebião",
"brecamo",
"brechai",
"brecham",
"brechem",
"brechil",
"brechis",
"breeira",
"breeiro",
"breemos",
"bregais",
"bregmas",
"brejina",
"brelhes",
"brelina",
"brelins",
"brendos",
"brentos",
"brescas",
"bretese",
"breteso",
"bretesá",
"breveto",
"breviai",
"breviam",
"breviei",
"breviem",
"brevies",
"breviou",
"breínas",
"breúnos",
"briadas",
"brianto",
"briatis",
"bribada",
"bricões",
"bridada",
"bridais",
"bridamo",
"bridará",
"brideis",
"bridemo",
"brieias",
"brigosa",
"brimbau",
"brimbáo",
"brinjes",
"brinzão",
"briocai",
"briocam",
"briocar",
"briocou",
"briopse",
"briosma",
"brisamo",
"brisará",
"brisego",
"brisemo",
"britamo",
"britemo",
"brióbia",
"brióbio",
"broaças",
"brocamo",
"brocara",
"brochai",
"brocojó",
"brocões",
"brodíea",
"brofais",
"brolada",
"brolado",
"brolais",
"brolamo",
"brolara",
"brolava",
"brolemo",
"brolhai",
"brolhei",
"brolhem",
"brolhes",
"brolhou",
"bromais",
"bromamo",
"bromará",
"bromava",
"bromemo",
"bromite",
"bromóis",
"bronzos",
"broquim",
"broscos",
"brosita",
"brosite",
"broslai",
"broslas",
"broslei",
"broslem",
"brosles",
"brossai",
"brossas",
"brossei",
"brossem",
"brossou",
"brotamo",
"broteis",
"brotemo",
"broxais",
"broxamo",
"broxeis",
"broxemo",
"bruegas",
"brulhas",
"brumamo",
"brumará",
"brumava",
"brumeis",
"brumemo",
"brunais",
"brunamo",
"brunhas",
"brunhem",
"brunhia",
"brunhis",
"brunhiu",
"bruniam",
"brunimo",
"brussas",
"bruteai",
"brutear",
"bruteei",
"bruteia",
"bruteie",
"bruteio",
"brutela",
"bruteou",
"bruxada",
"bruxamo",
"bruxava",
"bruxeai",
"bruxeei",
"bruxeia",
"bruxeie",
"bruxeio",
"bruxeis",
"bruxemo",
"bruxeou",
"bruzelo",
"bruíços",
"brádeas",
"brísios",
"brítios",
"brívias",
"bróquia",
"brósimo",
"brósmio",
"bróssea",
"bróteon",
"bróteos",
"brózios",
"brômica",
"brômida",
"brôncia",
"brôntia",
"buambas",
"buanari",
"buanjas",
"bubelas",
"bubuiai",
"bubuiam",
"bubuias",
"bubuiei",
"bubuiem",
"bubuies",
"bubulas",
"buburés",
"bubânia",
"bubônio",
"bucanhe",
"bucelar",
"bucetos",
"buchins",
"buchite",
"buchona",
"bucidas",
"bucinas",
"bucobus",
"bucuibá",
"bucumbi",
"bucutás",
"bucuvas",
"bucáfer",
"bucórax",
"budanas",
"budites",
"budoada",
"budoado",
"budoais",
"budoamo",
"budoará",
"budoava",
"budoeis",
"budoemo",
"budunas",
"buendes",
"buenina",
"buerres",
"buezões",
"bufadão",
"bufaras",
"bufarmo",
"bufavas",
"bufemos",
"bufidas",
"bufidor",
"bufidão",
"bufinas",
"bufindo",
"bufiram",
"bufirem",
"bufiria",
"bufirão",
"bufisse",
"bufoneá",
"bufosas",
"bugdiós",
"bugegos",
"bugiais",
"bugiamo",
"bugiará",
"bugieis",
"bugiemo",
"buginas",
"buibila",
"buibile",
"buibilo",
"buidona",
"buidões",
"buirdes",
"buireis",
"buiriam",
"buirias",
"buirmos",
"buiuçus",
"bujagos",
"bujamés",
"bujanga",
"bulador",
"bulados",
"buladão",
"bulamos",
"bulante",
"bularam",
"bularei",
"bularia",
"bularmo",
"bularás",
"bularão",
"bulasse",
"bulaste",
"bulatai",
"bulatam",
"bulatar",
"bulatas",
"bulatei",
"bulatem",
"bulates",
"bulatou",
"bulauês",
"bulavam",
"bulaxos",
"bulbilo",
"bulbuis",
"buldras",
"bulemos",
"buleses",
"bulevai",
"bulevam",
"bulevas",
"bulevei",
"bulevem",
"buleves",
"bulevou",
"bulhada",
"bulhais",
"bulhamo",
"bulheis",
"bulhemo",
"bulidão",
"bulinos",
"buliras",
"bulirei",
"buliria",
"bulirmo",
"buliste",
"bulites",
"bulolos",
"bulzões",
"bulídea",
"bulíeis",
"bumbamo",
"bumbará",
"bumbeis",
"bumbemo",
"bunchos",
"bundamo",
"bundava",
"bundeai",
"bundeei",
"bundeia",
"bundeie",
"bundeio",
"bundeis",
"bundemo",
"bundras",
"bunevas",
"bungais",
"bungama",
"bungará",
"bungava",
"bunguem",
"bungule",
"bungulo",
"bunhada",
"bunhais",
"bunhamo",
"bunhará",
"bunhava",
"bunhedo",
"bunheis",
"bunhemo",
"bunicas",
"bunoide",
"bunídea",
"bunídeo",
"buquina",
"buquino",
"buracai",
"buracou",
"buraras",
"burevas",
"burgará",
"burgava",
"burguei",
"burguem",
"burilas",
"burinos",
"buriéis",
"burlamo",
"burlemo",
"burlosa",
"burmite",
"burnais",
"burnamo",
"burniam",
"burnias",
"burnimo",
"burrais",
"burramo",
"burrará",
"burrava",
"burreis",
"burremo",
"burudes",
"bururus",
"bururés",
"burusos",
"burutas",
"busanas",
"buscule",
"busculo",
"busculá",
"bussole",
"bustita",
"bustite",
"buszita",
"butados",
"butadão",
"butanas",
"butanis",
"butarei",
"butares",
"butarmo",
"butarás",
"butarão",
"butasse",
"butaste",
"butavam",
"butavas",
"buteias",
"buteira",
"butemos",
"butergo",
"butessa",
"buteões",
"butigas",
"butilas",
"butilos",
"butinos",
"butiros",
"butirão",
"butonga",
"butoris",
"butucai",
"butucam",
"butucou",
"butânio",
"butídea",
"butídio",
"butílio",
"butíris",
"buxetes",
"buxinas",
"buxáceo",
"buxínea",
"buxíneo",
"buzegai",
"buzegam",
"buzegas",
"buzegou",
"buzegue",
"buzeira",
"buzenos",
"buziada",
"buziado",
"buziais",
"buziamo",
"buziará",
"buziava",
"buzieis",
"buziemo",
"buzitas",
"buzugos",
"buzulai",
"buzulam",
"buzular",
"buzulas",
"buzulei",
"buzulem",
"buzules",
"buzulou",
"buáiida",
"buçalai",
"buçalam",
"buçalas",
"buçalei",
"buçales",
"buçalou",
"buélias",
"buéllia",
"buíamos",
"buíssem",
"buísses",
"buístes",
"buítras",
"báctrea",
"bálanas",
"bálaros",
"bálneas",
"bálteas",
"báquide",
"bárdeas",
"bárdulo",
"báriuns",
"bárneas",
"bártsia",
"básions",
"bássias",
"bátaros",
"bátemis",
"bátides",
"bátmica",
"bátmico",
"bátsuas",
"báucide",
"bâncios",
"bângias",
"bânzera",
"bébbias",
"bébrice",
"bédulos",
"béliuns",
"béqueas",
"béqueis",
"béquemo",
"béquerá",
"béquiam",
"béquias",
"béquida",
"béquido",
"bérgias",
"bérrias",
"bérulas",
"bételes",
"béteres",
"bétulos",
"bênicas",
"bênicos",
"bêveras",
"bíbaras",
"bíberas",
"bíbroco",
"bíbulas",
"bífaros",
"bíferas",
"bíferos",
"bígenas",
"bígenes",
"bímanas",
"bímanes",
"bímares",
"bímbios",
"bínubas",
"bínubos",
"bíparos",
"bíplice",
"bírrida",
"bíssono",
"bístone",
"bíviras",
"bóhrios",
"bólidas",
"bóricas",
"búbalos",
"búgulas",
"bútomos",
"búzeras",
"caacica",
"caacuís",
"caaetês",
"caandas",
"caantio",
"caapara",
"caapeno",
"caapeva",
"caapoãs",
"caapucá",
"caapuãs",
"caaroba",
"caatita",
"caaueti",
"caaxiós",
"cabaaçu",
"cababas",
"cabaceá",
"cabalai",
"cabalei",
"cabampé",
"cabanil",
"cabarro",
"cabatãs",
"cabaçus",
"cabaíba",
"cabaíva",
"cabeais",
"cabeava",
"cabecês",
"cabedam",
"cabedar",
"cabedei",
"cabedem",
"cabedes",
"cabedor",
"cabedou",
"cabeeis",
"cabeiam",
"cabeias",
"cabeiem",
"cabeies",
"cabenda",
"cabenga",
"cabeuas",
"cabiais",
"cabilão",
"cabiras",
"cabitus",
"cablais",
"cablará",
"cablava",
"cableis",
"cablemo",
"cabobas",
"cabocir",
"cabocós",
"cabojes",
"caboles",
"cabombo",
"caborta",
"caborte",
"caborto",
"cabotai",
"cabotam",
"cabotas",
"cabotei",
"cabotem",
"cabotes",
"cabotou",
"caboupa",
"cabraia",
"cabraze",
"cabrazo",
"cabrazá",
"cabreai",
"cabreie",
"cabreio",
"cabreja",
"cabreje",
"cabrejo",
"cabremo",
"cabreou",
"cabreta",
"cabrins",
"cabruco",
"cabruno",
"cabruás",
"cabrués",
"cabuete",
"cabueto",
"cabugão",
"cabuias",
"cabuins",
"cabulai",
"cabules",
"cabumba",
"caburis",
"caburos",
"cabutas",
"cabuçus",
"cabália",
"cabázia",
"cabázio",
"cabíris",
"cabódis",
"cacajos",
"cacanas",
"cacaosa",
"cacaoso",
"cacarel",
"cacareá",
"cacatus",
"cacauês",
"cacauís",
"caceada",
"caceado",
"caceais",
"caceará",
"cacebis",
"caceeis",
"caceiam",
"caceiem",
"caceies",
"cacembe",
"cacetai",
"cacetam",
"cacetem",
"cachabu",
"cachais",
"cachamo",
"cachari",
"cacheai",
"cacheis",
"cachemo",
"cacheto",
"cachiam",
"cachida",
"cachido",
"cachimo",
"cachimã",
"cachine",
"cachino",
"cachirá",
"cachoai",
"cachoas",
"cachoem",
"cachuda",
"cachuis",
"cachulo",
"cachuça",
"cacicai",
"cacicou",
"cacifai",
"cacifas",
"cacilho",
"cacimbe",
"cacites",
"cacizes",
"cacofia",
"cacolas",
"cacolim",
"cacomes",
"caconas",
"caconso",
"cacopas",
"cacosos",
"cactais",
"cactino",
"cacuais",
"cacuala",
"cacuata",
"cacubis",
"cacucos",
"cacuema",
"cacueme",
"cacuera",
"cacuias",
"caculai",
"caculam",
"caculei",
"caculem",
"cacules",
"cacumba",
"cacumbô",
"cacumes",
"cacumás",
"cacutus",
"cacória",
"cacóxis",
"cacózão",
"cadabas",
"cadaxos",
"cadelai",
"cadelam",
"cadelei",
"cadelem",
"cadeles",
"cadelou",
"cadetas",
"cadexos",
"cadimos",
"cadinas",
"cadinos",
"cadisco",
"cadivas",
"cadivos",
"cadixes",
"cadixos",
"cadmeia",
"cadmiai",
"cadmiam",
"cadmias",
"cadmiei",
"cadmiem",
"cadmies",
"cadmiou",
"cadogam",
"cadoiço",
"cadolos",
"cadorno",
"cadorra",
"cadoxes",
"cadoxos",
"cadozes",
"cadraço",
"cadsura",
"caducai",
"cadulos",
"cadurça",
"cadusos",
"caduveu",
"cafango",
"cafatar",
"cafeais",
"cafeica",
"cafelai",
"cafelam",
"cafelas",
"cafelei",
"cafelem",
"cafeles",
"cafelou",
"cafetai",
"cafetam",
"cafetar",
"cafetei",
"cafetes",
"cafetou",
"cafeóis",
"cafifai",
"cafifam",
"cafifei",
"cafifem",
"cafifou",
"cafrais",
"cafrina",
"cafrino",
"caftine",
"caftino",
"cafubás",
"cafumbe",
"cafumbo",
"cafumbã",
"cafunai",
"cafunam",
"cafunas",
"cafunei",
"cafunem",
"cafunou",
"cafutes",
"cafuzai",
"cafuzam",
"cafuzar",
"cafuzei",
"cafuzem",
"cafuzes",
"cafuzou",
"cafuões",
"cafífis",
"cagadol",
"cagarmo",
"cagauás",
"cagaval",
"caginga",
"cagoans",
"cagoros",
"caguani",
"caguará",
"caguaçu",
"cagueis",
"cagópio",
"cahnita",
"caiacus",
"caiacós",
"caiadão",
"caialas",
"caiante",
"caiança",
"caianés",
"caiares",
"caiarmo",
"caiaste",
"caiatés",
"caiavas",
"caibais",
"caibamo",
"caibrai",
"caibrei",
"caibrem",
"caibres",
"caibrou",
"caicaus",
"caidela",
"caidora",
"caietés",
"caiguás",
"caimais",
"caimbás",
"caimiri",
"caincas",
"caincei",
"caincem",
"cainces",
"caingás",
"cainhem",
"cainiti",
"cainçai",
"cainçam",
"cainçou",
"caipuna",
"caipões",
"caiquió",
"cairele",
"cairelo",
"cairemo",
"cairuás",
"caisuma",
"caitias",
"caititi",
"caitoca",
"caiugás",
"caiuias",
"caixoto",
"caiçava",
"cajadil",
"cajadis",
"cajajas",
"cajanas",
"cajanjá",
"cajanos",
"cajante",
"cajatas",
"cajataí",
"cajatis",
"cajaças",
"cajibás",
"cajilas",
"cajinga",
"cajitós",
"cajubis",
"cajuins",
"cajulós",
"cajuris",
"cajutis",
"cajânea",
"calaceá",
"calains",
"calalas",
"calalus",
"calaluz",
"calamei",
"calamel",
"calamão",
"calanas",
"calange",
"calanja",
"calanos",
"calanza",
"calatas",
"calatra",
"calatre",
"calaíta",
"calaíte",
"calceis",
"calcemo",
"calcesa",
"calcete",
"calcoré",
"caldamo",
"caldava",
"caldeai",
"caldeio",
"caldeis",
"caldemo",
"caleada",
"caleais",
"caleana",
"caleará",
"caleava",
"caleeis",
"caleiam",
"caleias",
"caleiem",
"caleies",
"calejai",
"calembe",
"calembo",
"calenas",
"calenes",
"calengo",
"calepim",
"calepos",
"caletos",
"caleços",
"calhamo",
"calheis",
"calhemo",
"calhoai",
"calhoam",
"calhoei",
"calhoem",
"calhoou",
"calhéus",
"caliada",
"caligos",
"calilus",
"calimbé",
"calimes",
"calingo",
"calique",
"calites",
"calmais",
"calmamo",
"calmeis",
"calmemo",
"calmiça",
"calogio",
"calojis",
"calolos",
"calombe",
"calonde",
"calongo",
"caloure",
"calovas",
"caloête",
"calraxo",
"calubas",
"caluena",
"caluete",
"calugas",
"caluios",
"calujes",
"calunos",
"caluqui",
"calvada",
"calvais",
"calvamo",
"calvará",
"calvava",
"calveis",
"calveja",
"calveje",
"calvejo",
"calvemo",
"calávea",
"calâmea",
"calâmeo",
"calídea",
"calídeo",
"calídio",
"calígio",
"camaguã",
"camaias",
"camaiuá",
"camajis",
"camalas",
"camaleá",
"camambu",
"camamum",
"camanxe",
"camapés",
"camarus",
"camaáxi",
"cambaco",
"cambaie",
"cambamo",
"cambeis",
"cambemo",
"cambiro",
"cambixo",
"camboai",
"camboam",
"camboei",
"camboem",
"cambola",
"cambole",
"camboou",
"cambram",
"cambrar",
"cambrei",
"cambrem",
"cambuda",
"cambule",
"cambulo",
"cambuva",
"cambéri",
"cambóis",
"cameada",
"cameado",
"cameais",
"cameará",
"cameava",
"camecrã",
"cameeis",
"cameiam",
"cameias",
"cameiem",
"cameies",
"camenal",
"camenes",
"camerte",
"camexes",
"camichi",
"camoatá",
"camocas",
"camocho",
"camoiro",
"camopim",
"camorço",
"camouro",
"campamo",
"campeai",
"campeba",
"campeis",
"campemo",
"campiam",
"campido",
"campimo",
"campine",
"campsim",
"campuda",
"campune",
"camuacá",
"camucus",
"camucás",
"camucés",
"camuelo",
"camugas",
"camundi",
"camunha",
"camupim",
"camurce",
"camuris",
"camurço",
"camutos",
"camásia",
"camúnia",
"canadol",
"canagra",
"cananés",
"canaveá",
"canavãs",
"canazes",
"canaçus",
"cancane",
"cancano",
"cancatá",
"canchal",
"cancheá",
"canchis",
"cancleu",
"cancras",
"cancurã",
"candabo",
"candabu",
"candeai",
"candeie",
"candena",
"candiai",
"candiam",
"candias",
"candiei",
"candiem",
"candila",
"candile",
"candilo",
"candins",
"candiou",
"candole",
"candolo",
"candros",
"canduns",
"canduás",
"candéis",
"candéns",
"caneada",
"caneais",
"caneará",
"caneava",
"canebás",
"caneeis",
"caneiam",
"caneias",
"caneiem",
"caneies",
"caneixo",
"canejos",
"canelai",
"canelam",
"canelem",
"canenas",
"canetai",
"canetes",
"canezes",
"canfana",
"canfano",
"canfato",
"canfina",
"canfore",
"canforo",
"canfóis",
"cangais",
"cangala",
"cangali",
"cangane",
"cangará",
"cangola",
"cangome",
"cangoás",
"canguei",
"canguem",
"canhaco",
"canhais",
"canhara",
"canharó",
"canheis",
"canhemo",
"canhine",
"canhins",
"canhone",
"canhári",
"canhóis",
"caniane",
"canidos",
"canifes",
"canijos",
"canipas",
"canjuís",
"canoata",
"canoona",
"canopeu",
"canorço",
"canoulo",
"canouro",
"cansins",
"canteai",
"cantela",
"cantite",
"cantuas",
"cantufa",
"canturo",
"canudai",
"canudam",
"canudei",
"canudem",
"canudes",
"canudou",
"canuque",
"canuxis",
"canxins",
"canzada",
"canzado",
"canzais",
"canzala",
"canzamo",
"canzará",
"canzava",
"canzeis",
"canzemo",
"canzuim",
"canzuás",
"canzóis",
"canádio",
"cançona",
"canícia",
"canória",
"canório",
"canúsio",
"caoboro",
"caolins",
"caolzão",
"caombes",
"caombos",
"caonhas",
"caopiás",
"caotize",
"caotizo",
"capadão",
"capages",
"capaias",
"capamba",
"capambo",
"capande",
"capango",
"capanho",
"capanás",
"caparmo",
"caparus",
"capaste",
"capauas",
"capaude",
"capavas",
"capaúva",
"capeais",
"capeeis",
"capegos",
"capeiem",
"capeies",
"capeles",
"caperas",
"capeuas",
"capiagá",
"capiana",
"capijis",
"capilho",
"capinai",
"capines",
"capioas",
"capitaú",
"capitem",
"capnias",
"capocas",
"capolim",
"capolos",
"capopas",
"caporos",
"capotai",
"capoteá",
"capotim",
"capoxos",
"caprela",
"caprila",
"caprilo",
"caprins",
"capruns",
"capsite",
"captemo",
"captias",
"captóis",
"capucim",
"capundó",
"capunza",
"caputis",
"capuxus",
"capuíbo",
"capuões",
"caqueai",
"caqueei",
"caqueie",
"caqueio",
"caqueje",
"caquene",
"caqueou",
"caquibe",
"caquina",
"caquine",
"carabus",
"carabés",
"caraetê",
"carafos",
"caraiás",
"carajus",
"carajuá",
"caramol",
"caramos",
"carapés",
"cararis",
"cararus",
"cararés",
"caratos",
"caratéu",
"caraxes",
"carbóis",
"carcaio",
"carcavo",
"carcero",
"carchas",
"carcheá",
"carcoda",
"carcomi",
"carcomo",
"carcoré",
"carcuno",
"carcões",
"cardana",
"cardara",
"cardeei",
"cardeie",
"cardeio",
"cardemo",
"cardeou",
"cardiam",
"cardido",
"cardimo",
"cardins",
"cardirá",
"cardita",
"cardoas",
"carduce",
"carduel",
"carduço",
"careara",
"careava",
"careeis",
"careies",
"carenai",
"carenam",
"carenei",
"carenem",
"carepai",
"carepam",
"carepar",
"carepei",
"carepem",
"carepes",
"carepos",
"carepou",
"careteá",
"caretém",
"carezas",
"cargais",
"carguei",
"carguem",
"cariamo",
"cariava",
"caricai",
"caricam",
"caricar",
"caricou",
"carieis",
"cariemo",
"carigos",
"carijai",
"carijam",
"carijar",
"carijas",
"carijei",
"carijem",
"carijes",
"carijou",
"carilos",
"carimis",
"carimés",
"caringo",
"carique",
"cariços",
"carmana",
"carmatu",
"carmeai",
"carmeei",
"carmeie",
"carmeio",
"carmeou",
"carmiar",
"carnaco",
"carnamo",
"carneai",
"carneei",
"carneie",
"carneis",
"carnemo",
"carneol",
"carnina",
"carnute",
"carnuto",
"caroada",
"caroado",
"caroais",
"carocai",
"carocam",
"carocar",
"carocou",
"carones",
"caroneá",
"caropai",
"caropam",
"caropar",
"caropas",
"caropei",
"caropem",
"caropes",
"caropou",
"carosol",
"caroujo",
"carovas",
"carpamo",
"carpata",
"carpava",
"carpeai",
"carpeei",
"carpeia",
"carpeie",
"carpeio",
"carpeis",
"carpemo",
"carpeno",
"carpeou",
"carpeto",
"carptor",
"carrado",
"carrais",
"carramo",
"carrane",
"carreai",
"carreis",
"carreja",
"carreje",
"carremo",
"carreus",
"carrexa",
"carrija",
"cartalo",
"carteai",
"carteei",
"carteie",
"cartemo",
"cartufa",
"caruani",
"carubas",
"carucas",
"carucha",
"carujai",
"carujam",
"carujas",
"carujei",
"carujem",
"carujes",
"carujos",
"carujou",
"carulha",
"carulos",
"carunfa",
"caruqué",
"caruros",
"carurés",
"carutos",
"carveje",
"carvejo",
"carveli",
"carveno",
"carvoal",
"carvóis",
"caráceo",
"carália",
"carápea",
"casalai",
"casalam",
"casalas",
"casalei",
"casalou",
"casapos",
"casatas",
"casbiai",
"casbiam",
"casbiar",
"casbias",
"casbiei",
"casbiem",
"casbies",
"casbiou",
"casbião",
"casceta",
"caseais",
"caseato",
"casecos",
"caseeis",
"caseica",
"caseico",
"caseiem",
"caseies",
"casemia",
"caserós",
"caseíno",
"casinas",
"casinéu",
"cassade",
"cassaje",
"cassame",
"cassana",
"casseai",
"cassear",
"casseei",
"casseia",
"casseie",
"casseio",
"casseis",
"cassemo",
"casseou",
"cassins",
"cassoas",
"cassupa",
"casteai",
"casteei",
"casteia",
"casteie",
"casteou",
"castrai",
"casturé",
"casucos",
"catabas",
"catacus",
"catacás",
"catafes",
"cataias",
"catajés",
"catalpo",
"catalós",
"catanai",
"catanei",
"catanem",
"catanes",
"cataneá",
"catanou",
"catante",
"cataolo",
"catapas",
"catapus",
"catapão",
"catarma",
"catarmo",
"catarta",
"catarte",
"catarto",
"catasol",
"catatas",
"catatos",
"cataxus",
"cateada",
"cateais",
"cateará",
"cateauá",
"cateava",
"catecos",
"cateeis",
"cateiam",
"cateiem",
"cateies",
"catejuá",
"catexes",
"catiauá",
"catibas",
"catioas",
"catipna",
"catipés",
"catitai",
"catitam",
"catitar",
"catitei",
"catitem",
"catites",
"catitou",
"catixas",
"catocar",
"catocas",
"catodal",
"catodas",
"catodão",
"catojés",
"catopse",
"catoris",
"catotal",
"catotol",
"catotos",
"catraie",
"catrais",
"catraus",
"catreta",
"catrofe",
"catroia",
"catroio",
"catuais",
"catucas",
"catuiti",
"catuixi",
"catulas",
"catumas",
"catumbo",
"catumua",
"caturna",
"caturno",
"catutas",
"catília",
"cauabas",
"cauaiás",
"cauanas",
"cauanda",
"cauanga",
"cauaris",
"cauarás",
"cauarés",
"cauaués",
"cauaxis",
"cauaçus",
"cauaíba",
"caucamo",
"caucela",
"cauchim",
"cauchus",
"caudela",
"caudele",
"caudelo",
"caudona",
"cauduta",
"caudéis",
"cauembe",
"caueras",
"cauiana",
"cauilas",
"cauipes",
"cauiras",
"cauireí",
"cauixis",
"caunhos",
"caureís",
"caurine",
"caurino",
"caurita",
"causemo",
"cauçona",
"cavacai",
"cavacam",
"cavadia",
"cavaleá",
"cavante",
"cavantã",
"cavanço",
"cavarmo",
"cavaués",
"cavavas",
"caveais",
"caveará",
"caveava",
"caveeis",
"caveiam",
"caveias",
"caveiem",
"caveies",
"cavelha",
"cavense",
"cavetos",
"caviado",
"caviais",
"caviamo",
"caviano",
"caviara",
"caviava",
"cavidai",
"cavidam",
"cavidei",
"cavidem",
"cavides",
"cavidou",
"cavieis",
"caviemo",
"cavilai",
"cavilas",
"cavilei",
"cavilem",
"caviles",
"cavilhe",
"cavitai",
"cavitas",
"cavitei",
"cavitem",
"cavites",
"cavitou",
"cavocai",
"cavocas",
"cavodás",
"cavucai",
"cavucas",
"cavédio",
"cavéolo",
"cavídea",
"cavíida",
"caxerim",
"caxexas",
"caxexes",
"caxibus",
"caxicas",
"caxinje",
"caxinés",
"caxique",
"caxitos",
"caxupas",
"cazembi",
"cazurés",
"caçambo",
"caçapeá",
"caçarmo",
"caçoamo",
"caçoeis",
"caçoemo",
"ceadona",
"ceadora",
"ceadões",
"ceanoto",
"ceantes",
"ceardes",
"ceareis",
"cearemo",
"cearias",
"ceasses",
"ceastes",
"cebares",
"cebelha",
"cebiana",
"cebiano",
"cebinas",
"cebinos",
"ceboide",
"cebreno",
"cebídea",
"ceceais",
"ceceara",
"ceceeis",
"ceceias",
"ceceiem",
"ceceies",
"ceceosa",
"cecices",
"cecites",
"cecídea",
"cecídeo",
"cededor",
"cedermo",
"cedidão",
"cedilhe",
"cedilho",
"cedoiro",
"cedoura",
"cedouro",
"cedrera",
"cedroís",
"cedíeis",
"ceeiras",
"ceeiros",
"cefaele",
"cefeles",
"cefenes",
"cefálea",
"cefáleo",
"cefélia",
"cegadão",
"cegavas",
"cegudas",
"cegudes",
"cegudos",
"ceguela",
"ceguemo",
"ceifamo",
"ceifeis",
"ceifemo",
"ceiotes",
"ceitões",
"ceivais",
"ceivamo",
"ceivará",
"ceivava",
"ceiveis",
"ceivemo",
"ceiçais",
"celenas",
"celenos",
"celense",
"celeume",
"celeumo",
"celhada",
"celiode",
"celioma",
"celioxe",
"celitas",
"celites",
"celoses",
"celtize",
"celtizo",
"celtuce",
"celuros",
"celvada",
"celvado",
"celvais",
"celvamo",
"celvará",
"celvava",
"celveis",
"celvemo",
"celária",
"celéria",
"celídio",
"cembros",
"cemonos",
"cempses",
"cenamil",
"cenamis",
"cencros",
"cendrai",
"cendram",
"cendras",
"cendrei",
"cendrem",
"cendrou",
"cenhosa",
"cenitos",
"cenizes",
"cenosas",
"centete",
"cenudas",
"cenudos",
"cenuros",
"cenósia",
"ceotada",
"ceotado",
"ceotais",
"ceotamo",
"ceotará",
"ceotava",
"ceoteis",
"ceotemo",
"cepelho",
"cepilhe",
"cepipas",
"cepisco",
"cepudas",
"cepudos",
"cepácea",
"cerador",
"ceradão",
"cerafro",
"cerames",
"cerante",
"ceraram",
"cerarei",
"cerarem",
"cerares",
"cerarmo",
"cerarás",
"cerarão",
"cerasma",
"cerasse",
"ceraste",
"ceravam",
"ceravas",
"cerbana",
"cerbano",
"cerceai",
"cercino",
"cercião",
"cercope",
"cercose",
"cercuda",
"cercudo",
"cercões",
"cerdana",
"cerdeai",
"cerdeei",
"cerdeia",
"cerdeie",
"cerdeio",
"cerdeou",
"cerebas",
"ceresil",
"ceresis",
"ceresos",
"cergiam",
"cergias",
"cergida",
"cergido",
"cergirá",
"ceriata",
"ceriato",
"ceribas",
"cerilas",
"ceriles",
"cerilos",
"cerilão",
"cerinas",
"cerinos",
"cerinta",
"cerinto",
"ceriosa",
"ceritas",
"cerivas",
"cerjais",
"cernado",
"cernais",
"cernamo",
"cernará",
"cernava",
"cerneia",
"cerneis",
"cernemo",
"cernida",
"cernimo",
"cernira",
"cernosa",
"cernoso",
"cernões",
"ceromes",
"ceronas",
"cerquer",
"cerrete",
"certamo",
"certara",
"certeis",
"certemo",
"ceruana",
"cerucos",
"cerudas",
"cerudes",
"ceruras",
"cerusas",
"cerussa",
"cervavo",
"cervuns",
"cerzias",
"cerátio",
"cerério",
"cerídea",
"cesaros",
"cesinas",
"cesoiro",
"cesouro",
"cespite",
"cespito",
"cespitá",
"cessamo",
"cessemo",
"cessona",
"cestros",
"cesurai",
"cesurei",
"cesurem",
"cesures",
"cesurou",
"cesúlia",
"cetenes",
"cetilas",
"cetilos",
"cetinas",
"cetoxal",
"cetílio",
"ceutrão",
"cevadão",
"cevante",
"cevarei",
"cevarmo",
"cevarás",
"cevavas",
"cevemos",
"cevinas",
"ceáveis",
"ceçonas",
"chabace",
"chabaço",
"chabéus",
"chaceis",
"chacemo",
"chacins",
"chacmas",
"chacrão",
"chactas",
"chafita",
"chagais",
"chagamo",
"chagues",
"chaimas",
"chainas",
"chaiola",
"chaiota",
"chaiote",
"chairai",
"chairam",
"chairei",
"chairem",
"chairou",
"chalace",
"chalamo",
"chaldra",
"chaldre",
"chaldro",
"chalemo",
"chaliam",
"chalida",
"chalido",
"chaliás",
"chalmas",
"chalrai",
"chalras",
"chalrei",
"chalrem",
"chalres",
"chalreá",
"chalrou",
"chalrão",
"chalões",
"chamalé",
"chamató",
"chambil",
"chambis",
"chambos",
"chameai",
"chamear",
"chameie",
"chameje",
"champai",
"champam",
"champar",
"champei",
"champem",
"champló",
"champor",
"champão",
"chamuar",
"chamuna",
"chancai",
"chancam",
"chancos",
"chancou",
"chandeu",
"chanele",
"chanfas",
"chanfos",
"changam",
"changou",
"chanhas",
"chaniló",
"chanoiú",
"chanque",
"chantei",
"chantem",
"chantoe",
"chantoo",
"chantoá",
"chapamo",
"chapeai",
"chapeca",
"chapeio",
"chapeje",
"chapejo",
"chapejá",
"chapemo",
"chapice",
"chapine",
"chapiço",
"chapiçá",
"chapodo",
"chapuce",
"chapuje",
"chapujo",
"chapujá",
"chapuze",
"chapuzo",
"chapuço",
"chapuçá",
"chaquer",
"chaquéu",
"charadó",
"chardas",
"chardia",
"charelo",
"charepe",
"chareta",
"chareto",
"chargeá",
"chariôs",
"charlai",
"charlem",
"charlós",
"charoai",
"charoas",
"charoei",
"charoem",
"charona",
"charoou",
"charroa",
"charruo",
"chartas",
"charteu",
"charute",
"charéus",
"chascai",
"chascam",
"chascar",
"chascas",
"chascou",
"chascão",
"chaspas",
"chatine",
"chauias",
"chavada",
"chaveai",
"chavens",
"chaveto",
"chaçamo",
"chaçava",
"chebate",
"checais",
"checamo",
"chedite",
"chedres",
"chefiai",
"cheides",
"cheilas",
"cheiume",
"cheldai",
"cheldam",
"cheldar",
"cheldas",
"cheldei",
"cheldem",
"cheldes",
"cheldou",
"cheldra",
"chelipa",
"chelipe",
"chelras",
"chelros",
"cheléns",
"chempos",
"chencau",
"chendos",
"chenita",
"cherica",
"chernir",
"chernos",
"cherrão",
"cherulé",
"chervas",
"cheteni",
"chetini",
"chevala",
"chevalo",
"cheveca",
"cheveco",
"chevrós",
"cheynes",
"cheúras",
"chiango",
"chiangá",
"chiarmo",
"chiaste",
"chiatai",
"chiatam",
"chiatei",
"chiatem",
"chiates",
"chiatou",
"chiavas",
"chibada",
"chibais",
"chibalé",
"chibará",
"chibate",
"chibaça",
"chibeis",
"chibemo",
"chibeus",
"chicaba",
"chicada",
"chiceis",
"chicemo",
"chicero",
"chichai",
"chichei",
"chichem",
"chichie",
"chichio",
"chicios",
"chicopa",
"chicoto",
"chicuas",
"chiemos",
"chifrai",
"chiista",
"chilide",
"chilrai",
"chilrei",
"chilreá",
"chimbeá",
"chimbie",
"chimbio",
"chimbiá",
"chimbés",
"chimbéu",
"chimela",
"chimiês",
"chimpai",
"chimpam",
"chimpei",
"chimpem",
"chimpes",
"chinais",
"chinamo",
"chinará",
"chinava",
"chinaço",
"chincai",
"chincam",
"chincas",
"chincra",
"chindau",
"chindim",
"chineai",
"chineei",
"chineia",
"chineie",
"chinemo",
"chineou",
"chineta",
"chinfre",
"chinfro",
"chingla",
"chinica",
"chinico",
"chinize",
"chinizo",
"chinizá",
"chinras",
"chintel",
"chintes",
"chiobas",
"chiotas",
"chiotes",
"chipuar",
"chirata",
"chirelo",
"chireta",
"chirias",
"chiriba",
"chirrie",
"chiscai",
"chiscam",
"chiscas",
"chiscos",
"chiscou",
"chisnai",
"chisnam",
"chisnas",
"chisnei",
"chisnem",
"chisnes",
"chisnou",
"chispai",
"chispeá",
"chitata",
"chitaus",
"chitela",
"chitoto",
"chizias",
"chiçado",
"chiçais",
"chiçamo",
"chiçava",
"choapta",
"chobias",
"chobili",
"chocais",
"chochai",
"chochei",
"chocrão",
"chofere",
"chofero",
"chofrai",
"chofrei",
"chofrão",
"choinai",
"choinam",
"choinar",
"choinas",
"choinei",
"choinem",
"choines",
"choinou",
"choiras",
"choitai",
"choitam",
"choitas",
"choitei",
"choitem",
"choites",
"choitos",
"choitou",
"cholaga",
"cholope",
"chonais",
"chonamo",
"chonará",
"chonava",
"choneis",
"chonemo",
"chonona",
"chontas",
"chonões",
"chorcos",
"chorece",
"choreci",
"choreça",
"choreço",
"chorlos",
"chornes",
"chorrai",
"chorrem",
"chorres",
"choruto",
"chotada",
"chotais",
"chotamo",
"chotara",
"chotava",
"choteis",
"chotemo",
"chotões",
"choucas",
"choupai",
"choupam",
"choupar",
"choupei",
"choupem",
"chouris",
"chousai",
"chousam",
"chousei",
"chousem",
"chouses",
"chousou",
"choussa",
"chousse",
"chousso",
"choutai",
"choutam",
"choutas",
"choutei",
"choutem",
"choutes",
"chouteá",
"choutiá",
"chovais",
"chovamo",
"choveis",
"chovemo",
"chovolo",
"chuabas",
"chubeto",
"chuceis",
"chucemo",
"chuchai",
"chuchue",
"chuchuo",
"chuetai",
"chuetam",
"chuetei",
"chuetem",
"chuetes",
"chuetou",
"chufada",
"chufais",
"chufamo",
"chufará",
"chufava",
"chufeai",
"chufeei",
"chufeia",
"chufeie",
"chufeio",
"chufeis",
"chufemo",
"chulapé",
"chulata",
"chulcas",
"chuleai",
"chuleei",
"chulpos",
"chumace",
"chumbai",
"chumbes",
"chumbeá",
"chumbum",
"chumeai",
"chumeei",
"chumeia",
"chumeie",
"chumeio",
"chumeou",
"chuname",
"chupais",
"chupemo",
"churdai",
"churdam",
"churdar",
"churdas",
"churdei",
"churdem",
"churdes",
"churdos",
"churdou",
"chureta",
"churine",
"churino",
"churiná",
"churmas",
"churreu",
"churrie",
"churtas",
"chusmai",
"chusmam",
"chusmei",
"chusmem",
"chusmes",
"chusmou",
"chusura",
"chuteis",
"chutemo",
"chuvins",
"chuçais",
"chuçamo",
"chuçara",
"chuçava",
"chúmbeo",
"ciadona",
"ciadões",
"ciameia",
"cianada",
"cianete",
"cianetá",
"cianize",
"cianizo",
"cianizá",
"cianosa",
"cianura",
"cianure",
"cianóis",
"ciaremo",
"ciariam",
"ciassem",
"ciastes",
"ciatron",
"ciavoga",
"cibadas",
"cibador",
"cibados",
"cibadão",
"cibamos",
"cibanas",
"cibanco",
"cibante",
"cibaque",
"cibaram",
"cibarco",
"cibarei",
"cibarem",
"cibares",
"cibarmo",
"cibarás",
"cibarão",
"cibasse",
"cibaste",
"cibatos",
"cibavam",
"cibavas",
"cibação",
"cibdelo",
"cibeias",
"cibemos",
"cibicas",
"cibonel",
"cibálio",
"cibócio",
"cibória",
"cicadal",
"cicatas",
"cicates",
"cicerai",
"ciceram",
"cicerei",
"cicerem",
"ciceres",
"cicerou",
"ciciais",
"ciciamo",
"ciciará",
"cicicas",
"cicieis",
"ciciemo",
"ciclena",
"ciclize",
"ciclizo",
"ciclosa",
"cicocas",
"cicádea",
"cicádeo",
"cicória",
"cicório",
"cicútio",
"cicútis",
"cidagãs",
"cidaras",
"cidaria",
"cidipes",
"cidmeno",
"cidoiro",
"cidoros",
"cidoses",
"cidouro",
"cidrais",
"cieciés",
"cieiras",
"cifador",
"cifados",
"cifadão",
"cifamos",
"cifando",
"cifanta",
"cifante",
"cifaram",
"cifaras",
"cifarei",
"cifarem",
"cifares",
"cifaria",
"cifarmo",
"cifarão",
"cifasse",
"cifaste",
"cifatos",
"cifavam",
"cifavas",
"cifelas",
"cifrais",
"ciframo",
"cifreis",
"cifremo",
"cifélio",
"cifídia",
"cifônio",
"ciganai",
"ciganam",
"ciganei",
"ciganem",
"ciganes",
"ciganeá",
"cigarre",
"cigudes",
"cijulas",
"cilaceu",
"ciladeá",
"cilaína",
"cileias",
"cileneu",
"cilhamo",
"cilhará",
"cilhava",
"cilheis",
"cilhemo",
"cilicie",
"cilicna",
"cilicno",
"ciliode",
"cilitas",
"cilitos",
"ciloses",
"cilênia",
"cilídea",
"cilídeo",
"cilídio",
"cilíida",
"cimarol",
"cimbeba",
"cimbela",
"cimbire",
"cimbrai",
"cimbrei",
"cimbrem",
"cimbrou",
"cimenol",
"ciminde",
"cimitas",
"cimoide",
"cimpaio",
"cimáceo",
"cimênio",
"cimínea",
"cimíneo",
"cimólia",
"cimólio",
"cimótoa",
"cinamol",
"cinanco",
"cinasco",
"cincais",
"cincamo",
"cincará",
"cincava",
"cinceno",
"cinchai",
"cincham",
"cinchei",
"cinchem",
"cinches",
"cinchol",
"cinchou",
"cinclos",
"cindais",
"cindamo",
"cindemo",
"cindimo",
"cindros",
"cindura",
"cineces",
"cinedos",
"cinerai",
"cineram",
"cineras",
"cinerei",
"cinerem",
"cinerou",
"cinetos",
"cingeis",
"cingule",
"ciniras",
"cinjais",
"cinquei",
"cinquem",
"cinques",
"cintamo",
"cintara",
"cintemo",
"cinzamo",
"cinzará",
"cinzemo",
"cináreo",
"cinécio",
"cioadas",
"cioados",
"ciochos",
"ciocoto",
"cionelo",
"ciopino",
"cipelas",
"ciperos",
"cipoama",
"cipoamo",
"cipoará",
"cipoava",
"cipoeis",
"cipoemo",
"ciprina",
"circada",
"circeio",
"circeus",
"circiai",
"circiam",
"circias",
"circiei",
"circiem",
"circies",
"circina",
"circine",
"circiou",
"cirrito",
"cirzais",
"cisador",
"cisadão",
"cisalho",
"cisante",
"cisarei",
"cisarem",
"cisares",
"cisarmo",
"cisarás",
"cisasse",
"cisaste",
"cisavam",
"cisavas",
"ciscais",
"ciscamo",
"ciscara",
"ciscosa",
"ciscoso",
"ciselai",
"ciselam",
"ciselas",
"ciselei",
"ciselem",
"ciseles",
"ciselou",
"cisirão",
"cismamo",
"cismemo",
"cisoiro",
"cisonas",
"cisouro",
"cispada",
"cispado",
"cispais",
"cispamo",
"cispara",
"cispava",
"cispeis",
"cispemo",
"cisques",
"cissona",
"cistase",
"cistela",
"cistelo",
"cistosa",
"cistros",
"cistuda",
"cistudo",
"cisídea",
"cisídeo",
"citarmo",
"citases",
"citeias",
"citemia",
"citereu",
"citeros",
"citilas",
"citilos",
"citimas",
"citinas",
"citissa",
"citodes",
"citoide",
"citolai",
"citolam",
"citolar",
"citolei",
"citolem",
"citoles",
"citolou",
"citolão",
"citopse",
"citoses",
"citotes",
"citrilo",
"citéreo",
"citínea",
"citônio",
"ciumais",
"ciumamo",
"ciumara",
"ciumata",
"ciumeis",
"ciumemo",
"ciuravo",
"civetos",
"cizanai",
"cizanam",
"cizanar",
"cizanas",
"cizanei",
"cizanem",
"cizanes",
"cizanie",
"cizanio",
"cizanou",
"cizelai",
"cizelam",
"cizelas",
"cizelei",
"cizelem",
"cizeles",
"cizelou",
"ciáfila",
"ciáreis",
"ciáteas",
"ciátula",
"ciâneas",
"ciâneos",
"ciãozão",
"ciãzona",
"ciênida",
"ciófila",
"ciófita",
"ciótica",
"ciótomo",
"ciônida",
"ciúrida",
"claclás",
"cladeai",
"cladeei",
"cladeia",
"cladeie",
"cladeio",
"cladeou",
"cladosa",
"cladoso",
"claimos",
"clamamo",
"clamemo",
"clampeá",
"clandôs",
"claraim",
"clareje",
"clareta",
"clasias",
"clasmas",
"clastra",
"clatros",
"clavamo",
"clavará",
"claveis",
"clavemo",
"cleftas",
"cleidos",
"cleomes",
"cleonos",
"clerões",
"cleônia",
"clianto",
"clicais",
"clicamo",
"clidite",
"climena",
"clionas",
"clipais",
"clipamo",
"clipeis",
"clipemo",
"clisada",
"clisado",
"clisais",
"clisamo",
"clisará",
"clisava",
"cliscos",
"cliseis",
"clisemo",
"clismas",
"clismea",
"clismeo",
"clissor",
"clitras",
"clivamo",
"cliveis",
"clivemo",
"cloanta",
"cloante",
"cloanto",
"clocais",
"clocamo",
"clocotó",
"clompão",
"clonamo",
"cloneis",
"clonemo",
"clorais",
"cloramo",
"clorará",
"clorava",
"cloreia",
"cloreis",
"cloremo",
"clorete",
"clorize",
"clorizo",
"clorizá",
"clorope",
"clorosa",
"clotila",
"cloveno",
"cluanto",
"clundos",
"clupeia",
"clupeio",
"clusino",
"cládios",
"cláusio",
"clávena",
"clávida",
"clélias",
"clérida",
"cléveas",
"clênios",
"clórias",
"clórida",
"clóride",
"clórion",
"clórios",
"clúnios",
"clúpeas",
"clúpeos",
"coactai",
"coactam",
"coactei",
"coactem",
"coactes",
"coactou",
"coadate",
"coadato",
"coadatá",
"coadela",
"coadona",
"coadões",
"coagias",
"coagimo",
"coagite",
"coagéis",
"coaiatá",
"coajais",
"coajamo",
"coajuba",
"coalhai",
"coalhas",
"coalhes",
"coalize",
"coalizo",
"coaluna",
"coanhai",
"coanham",
"coanhas",
"coanhei",
"coanhem",
"coanhes",
"coanhou",
"coantes",
"coaptai",
"coaptas",
"coaptei",
"coaptem",
"coaptes",
"coaptor",
"coareis",
"coaremo",
"coarias",
"coartai",
"coartas",
"coartei",
"coasses",
"coastes",
"coatais",
"coatamo",
"coatava",
"coateis",
"coatemo",
"coaxamo",
"coaxeis",
"coaxemo",
"coaçona",
"cobalos",
"cobeias",
"cobelas",
"cobiose",
"cobiões",
"cobocós",
"cobreai",
"cobreei",
"cobreie",
"cobreje",
"cobrejo",
"cobéuas",
"cobítis",
"cocadão",
"cocegai",
"cocegam",
"cochais",
"cochará",
"cochava",
"cocheis",
"cochela",
"cochemo",
"cochine",
"cocoeio",
"cocolas",
"cocolos",
"coconas",
"coconão",
"cocorai",
"cocoram",
"cocorar",
"cocorei",
"cocorem",
"cocores",
"cocorie",
"cocorou",
"cocozus",
"cocriai",
"cocrias",
"cocries",
"coculai",
"coculam",
"coculas",
"coculei",
"coculem",
"cocules",
"coculou",
"cocácea",
"cocáceo",
"cocçona",
"codeada",
"codeais",
"codeava",
"codeeis",
"codeiam",
"codeias",
"codeiem",
"codeies",
"codejai",
"codejei",
"codejem",
"codejes",
"codejos",
"codejou",
"codeosa",
"codeoso",
"codetém",
"codevam",
"codevas",
"codevei",
"codevem",
"codever",
"codeves",
"codeveu",
"codevia",
"codeçal",
"codeões",
"codeúda",
"codieus",
"codilhe",
"codinhe",
"coditos",
"codizai",
"codizam",
"codizei",
"codizes",
"codizou",
"codoiro",
"codíolo",
"codória",
"coedite",
"coeduco",
"coefora",
"coelege",
"coelegi",
"coeleja",
"coelejo",
"coelvas",
"coendos",
"coendus",
"coenhas",
"coenito",
"coerais",
"coeramo",
"coerdai",
"coerdam",
"coerdas",
"coerdei",
"coerdem",
"coerdes",
"coerdou",
"coeriam",
"coerias",
"coerido",
"coerimo",
"coerirá",
"coerões",
"coesona",
"coessos",
"cofemia",
"cofiais",
"cofiamo",
"cofieis",
"cofiemo",
"cofinha",
"cofinhe",
"cofinos",
"cogelai",
"cogelam",
"cogelas",
"cogelei",
"cogelem",
"cogeles",
"cogendo",
"cogerei",
"cogerem",
"cogeres",
"cogeria",
"cogeris",
"cogeriu",
"cogermo",
"cogerão",
"cogesse",
"cogeste",
"cogiada",
"cogiais",
"cogiamo",
"cogiará",
"cogiava",
"cogidão",
"cogieis",
"cogiemo",
"cogiram",
"cogiras",
"cogitai",
"cognada",
"cogotes",
"cogulai",
"cogulam",
"cogulei",
"cogulem",
"cogules",
"cogulou",
"coibamo",
"coibias",
"coibimo",
"coiceai",
"coiceei",
"coiceie",
"coiceio",
"coicoas",
"coifado",
"coifais",
"coifamo",
"coifará",
"coifava",
"coifeis",
"coifemo",
"coilões",
"coimais",
"coimamo",
"coimará",
"coimava",
"coimeis",
"coimemo",
"coinada",
"coinais",
"coinamo",
"coinará",
"coinava",
"coinche",
"coineis",
"coinemo",
"coioiar",
"coioíce",
"coipilo",
"coirace",
"coirada",
"coirais",
"coirans",
"coirata",
"coiraço",
"coireai",
"coireei",
"coireia",
"coireie",
"coireio",
"coireou",
"coiruda",
"coirudo",
"coisamo",
"coisara",
"coiseis",
"coisemo",
"coitamo",
"coitara",
"coitava",
"coiteis",
"coitemo",
"coitivo",
"coivare",
"coivaro",
"coivões",
"coiçona",
"cojamos",
"colabás",
"colamas",
"colança",
"colaíta",
"colaíte",
"colaíto",
"colchoe",
"colchoo",
"coleais",
"coleará",
"coleato",
"coleeis",
"colegai",
"coleias",
"coleiem",
"coleies",
"colejas",
"colemas",
"colepos",
"coletai",
"colevas",
"colexas",
"coleína",
"colgais",
"colgava",
"colguem",
"colgues",
"colhamo",
"colidis",
"coligai",
"coliges",
"coligis",
"colijas",
"colimai",
"colimas",
"colimes",
"colipeu",
"coliquá",
"colires",
"coliúro",
"colmace",
"colmais",
"colmamo",
"colmaça",
"colmeai",
"colmeei",
"colmeie",
"colmeio",
"colmeis",
"colmemo",
"colmeou",
"colodra",
"colodro",
"cololos",
"colomis",
"coloneu",
"colongo",
"colonho",
"colorai",
"coloreá",
"coltais",
"coltamo",
"coltará",
"coltava",
"colteis",
"coltemo",
"coludas",
"coludos",
"colugli",
"coluiai",
"coluiam",
"coluias",
"coluiei",
"coluiem",
"coluies",
"coluiou",
"columis",
"colunai",
"colunei",
"colunem",
"colunes",
"colunou",
"colécia",
"colênis",
"colíbia",
"colífio",
"colíquo",
"colófio",
"comagma",
"comanas",
"comanis",
"comaris",
"comarus",
"comasta",
"comatas",
"comatis",
"comazos",
"combaca",
"combala",
"combalo",
"combana",
"combane",
"combani",
"combano",
"combaro",
"combiós",
"comboca",
"combrão",
"combuas",
"comburi",
"comburo",
"comedam",
"comedem",
"comedis",
"comedon",
"comeios",
"cometeá",
"comilai",
"comilam",
"comilas",
"comilei",
"comilem",
"comiles",
"comilou",
"cominai",
"cominge",
"cominua",
"cominuo",
"comirai",
"comirar",
"comiras",
"comirem",
"comirou",
"comisco",
"comodai",
"comodei",
"comorai",
"compamu",
"compece",
"compego",
"componi",
"compume",
"comudai",
"comudam",
"comudar",
"comudas",
"comudei",
"comudem",
"comudou",
"comumbá",
"comutes",
"comácio",
"comúnia",
"conabis",
"conamis",
"conapai",
"conapam",
"conapar",
"conapas",
"conapei",
"conapem",
"conapes",
"conapos",
"conapou",
"conaros",
"concemo",
"conchai",
"concham",
"conchei",
"conchem",
"concheá",
"conchou",
"concons",
"concria",
"concrio",
"concuti",
"concuto",
"condamo",
"condimo",
"condoai",
"condoar",
"condoei",
"condoou",
"condora",
"condrio",
"condris",
"conetai",
"conetem",
"conetes",
"confoge",
"confuja",
"confujo",
"congorê",
"conguis",
"conhedo",
"coniato",
"conicar",
"coninas",
"conitas",
"conizas",
"conjuiz",
"conloio",
"conomeá",
"cononjo",
"conopes",
"conotai",
"conquam",
"conquas",
"conquia",
"conquir",
"conquiu",
"consaba",
"consabe",
"consabi",
"consabo",
"consoai",
"consoas",
"consoei",
"conurbo",
"conuros",
"convele",
"conveli",
"convias",
"convieu",
"conviia",
"convila",
"convilo",
"conárea",
"conáreo",
"conária",
"conçada",
"conçais",
"conçamo",
"conçara",
"coníase",
"conídea",
"conóbia",
"conópio",
"conóvia",
"conúlea",
"conúleo",
"coobado",
"coobais",
"coobamo",
"coobará",
"coobava",
"coobeis",
"coobemo",
"coocupa",
"coocupe",
"coocupo",
"cooptai",
"copadão",
"copaiés",
"copalmo",
"copanas",
"copanos",
"copante",
"copares",
"coparmo",
"coparás",
"coparão",
"copaste",
"copavas",
"copaína",
"copeica",
"copeire",
"copejai",
"copejam",
"copejas",
"copejei",
"copejem",
"copejes",
"copejou",
"copelai",
"copelam",
"copelei",
"copelem",
"copeles",
"copelou",
"copemos",
"copetes",
"copiata",
"copicas",
"copicos",
"copilai",
"copilem",
"copiles",
"coplano",
"copodar",
"coposes",
"copoxós",
"coptina",
"coptize",
"coptizo",
"coptizá",
"copudas",
"copulai",
"copília",
"copínia",
"coqueis",
"coquemo",
"coralos",
"corarei",
"corarmo",
"coraulo",
"coraxos",
"corazil",
"corazis",
"corbóis",
"corcado",
"corcais",
"corcamo",
"corcará",
"corcava",
"corchas",
"corcove",
"cordeai",
"cordeca",
"cordeei",
"cordeie",
"cordeou",
"cordila",
"cordilo",
"cordoai",
"cordoam",
"cordoas",
"cordoei",
"cordoem",
"cordoou",
"coreais",
"coreava",
"coreeis",
"coreiam",
"coreiem",
"coreies",
"coreras",
"coretro",
"corguis",
"corigos",
"corimbe",
"corinda",
"corines",
"corixes",
"corizos",
"corjedo",
"cormaró",
"cormelo",
"cormoso",
"cornais",
"cornamo",
"cornará",
"cornava",
"corneai",
"corneje",
"cornela",
"cornemo",
"cornino",
"cornude",
"corobós",
"coroeis",
"coromem",
"coronco",
"coronge",
"cororôs",
"corosil",
"corozis",
"corozos",
"corpica",
"corpico",
"corpilo",
"corpiés",
"corquei",
"corquem",
"corques",
"corramo",
"correai",
"correei",
"correja",
"correol",
"correxe",
"correxo",
"correxá",
"corripe",
"corripá",
"corrobó",
"corrugo",
"corrute",
"corseai",
"corseei",
"corseia",
"corseie",
"corseio",
"corseou",
"corsite",
"corsona",
"corteai",
"cortear",
"corteie",
"corubas",
"corujem",
"corujes",
"corunhe",
"corunho",
"corupiá",
"corutas",
"corvela",
"corvelo",
"corvéus",
"corxins",
"corício",
"corófio",
"cosamos",
"cosanas",
"cosanos",
"cosanza",
"coscore",
"coscorá",
"cosermo",
"coserão",
"cosicai",
"cosicam",
"cosidão",
"cosinas",
"cosipai",
"cosipam",
"cosipas",
"cosipei",
"cosipem",
"cosipes",
"cosipou",
"cosique",
"cosmeia",
"cosmine",
"cosmino",
"cosques",
"cosqueá",
"cossieu",
"cossono",
"costeai",
"costeei",
"costilo",
"cosíeis",
"cotadão",
"cotares",
"cotaris",
"cotarmo",
"cotarro",
"cotaste",
"cotavas",
"cotazão",
"cotecás",
"cotedua",
"coteife",
"coteira",
"cotejai",
"cotejas",
"cotejes",
"cotemos",
"cotetos",
"cotiais",
"cotiamo",
"cotiava",
"cotieis",
"cotiemo",
"cotinos",
"cotipos",
"cotitos",
"cotizai",
"cotizei",
"cotizes",
"cotonai",
"cotonas",
"cotonei",
"cotonem",
"cotones",
"cotoxés",
"cotoína",
"cotrins",
"cotrosa",
"cotruca",
"cotrões",
"cotulai",
"cotulam",
"cotular",
"cotulei",
"cotulem",
"cotules",
"cotulos",
"cotulou",
"cotumba",
"cotídea",
"cotídeo",
"cotílea",
"cotíleo",
"couceai",
"couceei",
"couceia",
"couceie",
"couceio",
"couceis",
"coucemo",
"couceou",
"coudece",
"coudeci",
"coudele",
"coudelo",
"coudelá",
"coudeça",
"coudeço",
"coudras",
"courace",
"courata",
"couraço",
"coureai",
"coureei",
"coureie",
"coureio",
"courele",
"courelo",
"coureou",
"courudo",
"couruno",
"couróis",
"courões",
"cousais",
"cousamo",
"cousará",
"cousava",
"couseis",
"cousemo",
"cousica",
"cousita",
"coussos",
"coutamo",
"coutará",
"couteis",
"coutemo",
"coutios",
"couvado",
"couvamo",
"couvana",
"couvará",
"couvava",
"couveis",
"couvemo",
"couçada",
"couçado",
"couçais",
"couçamo",
"couçará",
"couçava",
"couçona",
"couções",
"covache",
"covachá",
"covador",
"covante",
"covatas",
"coveada",
"coveais",
"coveará",
"coveava",
"coveeis",
"coveiam",
"coveias",
"coveiem",
"coveies",
"covende",
"covendá",
"covitos",
"covocas",
"covocós",
"covoões",
"covádis",
"covável",
"coxagra",
"coxanga",
"coxarro",
"coxeeis",
"coxeiem",
"coxeies",
"coxelas",
"coxelos",
"coxiela",
"coxites",
"cozamos",
"cozelas",
"cozerei",
"cozermo",
"cozíeis",
"coáctil",
"coápias",
"coáveis",
"coçarmo",
"coçaste",
"crabros",
"cracati",
"cracrás",
"cradina",
"craione",
"craiono",
"craioná",
"craiões",
"crajuás",
"crambos",
"crampas",
"cramuri",
"crancho",
"crangom",
"crangão",
"craniai",
"craniam",
"craniei",
"craniem",
"cranies",
"cranite",
"craonês",
"crapela",
"crapete",
"craptés",
"craseai",
"craseei",
"craseie",
"craseou",
"crauros",
"crausta",
"crauçás",
"craveis",
"craveje",
"cravelo",
"cravemo",
"cravine",
"creacas",
"creatos",
"credzes",
"crejuás",
"crelada",
"crelado",
"crelais",
"crelamo",
"crelará",
"crelava",
"creleis",
"crelemo",
"cremais",
"cremamo",
"cremara",
"cremeis",
"crememo",
"cremlim",
"crenais",
"crenamo",
"crenará",
"crenava",
"crencho",
"creneis",
"crenela",
"crenemo",
"crenita",
"crenjês",
"creosal",
"creplos",
"creremo",
"cresilo",
"crespai",
"crespam",
"crespei",
"crespem",
"crespia",
"crespis",
"crespiu",
"crespou",
"cressas",
"crestai",
"crestei",
"crestem",
"crestão",
"cretões",
"crevete",
"crevins",
"creçuda",
"creóbio",
"cricomé",
"cridona",
"cridões",
"crifeca",
"crifeia",
"crifias",
"crijoás",
"crimpai",
"crimpam",
"crimpas",
"crimpem",
"crimpou",
"crinais",
"crioses",
"criotão",
"crisamo",
"crisará",
"criseis",
"crisemo",
"crisite",
"crismai",
"crisola",
"crisole",
"crisolo",
"crisope",
"crispai",
"crispas",
"crispão",
"crissos",
"cristeá",
"critmos",
"criuris",
"crivais",
"crivamo",
"crivemo",
"criviri",
"croatol",
"crocais",
"crocola",
"crocose",
"cromais",
"cromamo",
"cromara",
"cromeis",
"cromemo",
"cromize",
"cromizo",
"cromizá",
"cromoma",
"cromosa",
"cronhas",
"croniza",
"cronize",
"cronizo",
"cronqui",
"croocal",
"cropogô",
"croscas",
"crostos",
"crotina",
"croômia",
"cruapés",
"crubula",
"cruchas",
"cruchos",
"cruciei",
"cruciem",
"crucita",
"crucite",
"cruciça",
"crudice",
"crueras",
"cruilis",
"crunhas",
"crupais",
"crupião",
"cruzano",
"cruória",
"crábeas",
"crábeos",
"crácida",
"cráibia",
"cráigia",
"crásica",
"crásico",
"crécito",
"créligo",
"crênico",
"crícula",
"crífalo",
"crífios",
"crínuns",
"críptão",
"crísias",
"crísida",
"críside",
"crísops",
"cróbilo",
"crócica",
"crômida",
"crômula",
"crúdias",
"crúmens",
"crúzeas",
"cteniza",
"ctâmalo",
"ctênida",
"ctênios",
"ctênomo",
"cuainhi",
"cualicá",
"cualvos",
"cualzão",
"cuamata",
"cuambis",
"cuambos",
"cuambus",
"cuandas",
"cuandro",
"cuangar",
"cuangas",
"cuanhai",
"cuanham",
"cuanhar",
"cuanhas",
"cuanhei",
"cuanhem",
"cuanhes",
"cuanhos",
"cuanhou",
"cuapada",
"cuapras",
"cuaraci",
"cuarlai",
"cuarlam",
"cuarlar",
"cuarlas",
"cuarlei",
"cuarlem",
"cuarles",
"cuarlou",
"cuatibo",
"cuazuma",
"cubador",
"cubadão",
"cubales",
"cubalos",
"cubante",
"cubarei",
"cubares",
"cubarmo",
"cubarás",
"cubarão",
"cubasse",
"cubaste",
"cubavam",
"cubavas",
"cubeira",
"cubemos",
"cubeíta",
"cubicai",
"cubicam",
"cubicou",
"cubondo",
"cuboíte",
"cubável",
"cucador",
"cucadão",
"cucamos",
"cucante",
"cucaram",
"cucaras",
"cucarei",
"cucarem",
"cucaria",
"cucarmo",
"cucarão",
"cucasse",
"cucaste",
"cucavam",
"cucavas",
"cuchada",
"cuchais",
"cuchite",
"cucolue",
"cucuiar",
"cucujos",
"cuculai",
"cuculam",
"cuculas",
"cuculei",
"cuculem",
"cucules",
"cuculim",
"cuculou",
"cucumbu",
"cucumes",
"cucuras",
"cucuris",
"cucurus",
"cucutas",
"cucuzas",
"cucuzos",
"cucúlea",
"cucúleo",
"cucúlia",
"cudanas",
"cudanos",
"cudilim",
"cudimba",
"cudinha",
"cudinho",
"cudismo",
"cudista",
"cudolim",
"cuelvas",
"cuenhes",
"cuenjes",
"cueruda",
"cufadas",
"cufador",
"cufados",
"cufadão",
"cufaias",
"cufalas",
"cufamos",
"cufando",
"cufante",
"cufaram",
"cufarei",
"cufarem",
"cufares",
"cufaria",
"cufarmo",
"cufarás",
"cufarão",
"cufasse",
"cufaste",
"cufavam",
"cufavas",
"cufeias",
"cufemos",
"cufênio",
"cugares",
"cugiada",
"cugiado",
"cugiais",
"cugiamo",
"cugiará",
"cugiava",
"cugieis",
"cugiemo",
"cuguará",
"cuiabém",
"cuiacas",
"cuiames",
"cuianas",
"cuiaras",
"cuiavém",
"cuibaba",
"cuiepiá",
"cuilada",
"cuilado",
"cuilais",
"cuilamo",
"cuilará",
"cuilava",
"cuileis",
"cuilemo",
"cuilili",
"cuilzão",
"cuinara",
"cuinche",
"cuinhai",
"cuinham",
"cuinhas",
"cuinhei",
"cuinhem",
"cuinhes",
"cuinhou",
"cuinira",
"cuipeva",
"cuiubas",
"cuiuras",
"cujaras",
"cujelos",
"cujetés",
"cujiada",
"cujiado",
"cujiais",
"cujiamo",
"cujiará",
"cujiava",
"cujieis",
"cujiemo",
"cujinha",
"cujinho",
"cujubis",
"cujudas",
"cujudos",
"culagas",
"culapai",
"culapam",
"culapas",
"culapei",
"culapem",
"culapes",
"culapou",
"culatre",
"culatro",
"culaças",
"culcada",
"culcado",
"culcais",
"culcamo",
"culcará",
"culcava",
"culdeus",
"culimai",
"culimam",
"culimar",
"culimei",
"culimem",
"culimes",
"culimãs",
"culitas",
"culoris",
"culquei",
"culquem",
"culques",
"cultros",
"culumis",
"cumacaí",
"cumacos",
"cumacuã",
"cumacás",
"cumafós",
"cumalas",
"cumanis",
"cumaquã",
"cumaras",
"cumarés",
"cumatis",
"cumatiá",
"cumatés",
"cumbios",
"cumbira",
"cumeado",
"cumenol",
"cumenos",
"cumerim",
"cumiana",
"cumichá",
"cumilos",
"cuminas",
"cuminos",
"cumixás",
"cumopse",
"cumules",
"cumácea",
"cumídea",
"cumídeo",
"cunabis",
"cunanta",
"cunapus",
"cunarca",
"cundins",
"cunduna",
"cundura",
"cuneais",
"cuneana",
"cunenas",
"cunfres",
"cungues",
"cunguto",
"cunhamo",
"cunhanu",
"cunheis",
"cunhemo",
"cunibas",
"cunicas",
"cunicos",
"cunifes",
"cunilas",
"cuninas",
"cunissa",
"cunonas",
"cununas",
"cununos",
"cunuris",
"cunzita",
"cunônia",
"cuojelo",
"cuorina",
"cuozada",
"cuozado",
"cuozais",
"cuozamo",
"cuozará",
"cuozava",
"cuozeis",
"cuozemo",
"cupanas",
"cupelas",
"cupezes",
"cuprase",
"cupreno",
"cuprito",
"cupréns",
"cupróis",
"cupuaís",
"cupuaús",
"cupuins",
"cuqueai",
"cuqueei",
"cuqueie",
"cuqueio",
"cuqueis",
"cuquemo",
"cuqueou",
"curabas",
"curacis",
"curadão",
"curairi",
"curames",
"curanau",
"curapaí",
"curapiá",
"curapás",
"curatás",
"curauás",
"curaxéu",
"curcuta",
"curcute",
"curcuti",
"curcuto",
"cureras",
"curerés",
"curetai",
"curetei",
"curetem",
"curetus",
"curiamo",
"curiató",
"curieis",
"curiemo",
"curilos",
"curixos",
"curixás",
"curixão",
"curpuna",
"curramo",
"currará",
"curreis",
"currela",
"curremo",
"currucu",
"cursais",
"cursamo",
"curseai",
"cursear",
"curseei",
"curseia",
"curseie",
"curseio",
"curseis",
"cursemo",
"curseou",
"curseta",
"curtato",
"curtemo",
"curuaca",
"curuaie",
"curuati",
"curuaés",
"curuaís",
"curubés",
"curucai",
"curucam",
"curucar",
"curucou",
"curucus",
"curucuí",
"curugus",
"curujos",
"curumbi",
"curumãs",
"curundu",
"curunuá",
"curupus",
"curupás",
"curupés",
"curuque",
"cururis",
"cururás",
"curutus",
"curutzu",
"curutás",
"curuzus",
"curvadi",
"curvane",
"curveje",
"curvejá",
"curvete",
"curveto",
"curvéus",
"curárea",
"cusilói",
"cusinos",
"cuspais",
"cuspamo",
"cuspimo",
"cussita",
"cussuca",
"custeai",
"custeis",
"custemo",
"cutarda",
"cutchim",
"cutelão",
"cutilai",
"cutilam",
"cutilem",
"cutiles",
"cutilou",
"cutinas",
"cutisai",
"cutisam",
"cutisas",
"cutisei",
"cutisem",
"cutises",
"cutisou",
"cutites",
"cutitis",
"cutoses",
"cutucai",
"cutudém",
"cutuiás",
"cutulás",
"cutúbeo",
"cuvatãs",
"cuveira",
"cuvicas",
"cuvicos",
"cuviera",
"cuxarrá",
"cuxarás",
"cuxeris",
"cuxibes",
"cuxibis",
"cuçambe",
"cuçaris",
"cuíques",
"cuíssis",
"czadora",
"cácimos",
"cágueda",
"cálcimo",
"cápides",
"cápreas",
"cápreos",
"cáravos",
"cárdeas",
"cárpeas",
"cárpeos",
"cárrebo",
"cébrios",
"cécidas",
"cécubas",
"cécubos",
"cédrias",
"cédrões",
"célases",
"célodon",
"célsias",
"céltios",
"cépolas",
"cépsios",
"cérapos",
"cérasos",
"cérasão",
"cérceos",
"cércere",
"cércide",
"cércios",
"cércnis",
"cérides",
"cérions",
"cérites",
"cérneas",
"cérneos",
"cértias",
"cértida",
"cérulos",
"cérvias",
"cérvida",
"céstida",
"céstodo",
"céstrea",
"cévolas",
"cêmpsio",
"cêntore",
"cílaros",
"cínipes",
"cínzeos",
"círcios",
"císteas",
"cístula",
"cítreos",
"cóbiris",
"cóbrega",
"cóccida",
"cócedra",
"códagos",
"códanas",
"códanos",
"códegas",
"cólebra",
"cólpoda",
"cólpode",
"cólulos",
"cóptera",
"cóptido",
"córaces",
"córbula",
"córdulo",
"córicos",
"córifas",
"córilas",
"córilos",
"córises",
"córniam",
"córnida",
"córnido",
"córrega",
"córvida",
"cósmeas",
"cósmeos",
"cósmias",
"cóssida",
"cóssifa",
"cóssifo",
"cóssios",
"cóstias",
"cóstula",
"cótidas",
"cótigos",
"cótilos",
"cótulas",
"cômaros",
"cômpede",
"cônvena",
"cúlcita",
"cúlices",
"cúlmens",
"cúmeras",
"cúmidas",
"cúpreas",
"cúrrios",
"cúrsios",
"cúrtica",
"cúrtico",
"dabadas",
"dabemas",
"daboias",
"dabulas",
"dabutos",
"dacares",
"dacelos",
"dachani",
"dachéns",
"dacilos",
"dacisco",
"dacolás",
"dacomas",
"dacrina",
"dadanes",
"dadivai",
"dadivei",
"dadivem",
"dadives",
"dadonas",
"dagussa",
"dahlina",
"daimosa",
"dainaca",
"daineca",
"dairena",
"damborá",
"damejai",
"damejam",
"damejas",
"damejei",
"damejem",
"damejes",
"damejou",
"damices",
"damnoso",
"danaras",
"danarmo",
"danceis",
"dandina",
"dandine",
"dandino",
"dandões",
"daninhe",
"daquéns",
"dardais",
"dardamo",
"dardava",
"dardeis",
"dardeje",
"dardemo",
"darista",
"daroesa",
"dartoso",
"dataras",
"datarei",
"datares",
"datarmo",
"dataste",
"datavas",
"datemos",
"datilar",
"davália",
"daçarum",
"daçonas",
"dealbai",
"dealbam",
"dealbas",
"dealbei",
"dealbem",
"dealbes",
"dealvai",
"dealvam",
"dealvas",
"dealvei",
"dealvem",
"dealvou",
"deanóis",
"deaurai",
"deauram",
"deauras",
"deaurei",
"deaurem",
"deaures",
"deaurou",
"debacai",
"debacam",
"debacas",
"debacou",
"debadas",
"debador",
"debadão",
"debagai",
"debagam",
"debagou",
"debague",
"debango",
"debangá",
"debaram",
"debarem",
"debaria",
"debarmo",
"debarás",
"debarão",
"debavam",
"debavas",
"debelai",
"debeles",
"debicai",
"debicas",
"deboice",
"deboiço",
"deboiçá",
"deborca",
"deborco",
"debotai",
"debotei",
"debotem",
"debotes",
"debouce",
"debouço",
"debouçá",
"debrase",
"debraso",
"debreai",
"debreie",
"debrido",
"debruai",
"debruas",
"debruem",
"debrues",
"debutai",
"debutas",
"debuxai",
"debuxas",
"debuxem",
"debuxes",
"decaias",
"decalve",
"decalvo",
"decalvá",
"decampa",
"decampe",
"decapai",
"decapem",
"decaímo",
"deceina",
"deceine",
"decepas",
"decerte",
"decimei",
"decimem",
"decoais",
"decoamo",
"decoava",
"decoeis",
"decoemo",
"decolai",
"decolas",
"decoles",
"decoral",
"decotai",
"decruai",
"decruei",
"decruem",
"decrues",
"decruou",
"decupai",
"decupes",
"decuple",
"decurie",
"decurse",
"decursá",
"dedador",
"dedadão",
"dedarei",
"dedares",
"dedarmo",
"dedarás",
"dedarão",
"dedaste",
"dedavas",
"dedurai",
"dedures",
"defasai",
"defasas",
"defasei",
"defecai",
"deferis",
"defiras",
"deflago",
"deflita",
"deflito",
"defluas",
"defluis",
"defumai",
"defumas",
"defumes",
"degasai",
"degasam",
"degasas",
"degasei",
"degasem",
"degasou",
"degasto",
"degelai",
"degelas",
"degeles",
"degotai",
"degotam",
"degotei",
"degotem",
"degotou",
"degrano",
"deguebo",
"deionas",
"dejejue",
"dejejuo",
"dejetai",
"dejetas",
"dejetei",
"dejetem",
"dejetes",
"dejetou",
"dejunge",
"dejungi",
"dejunja",
"dejunjo",
"delamba",
"delambe",
"delambi",
"delambo",
"delatai",
"delegai",
"deletai",
"delgace",
"delgaço",
"delgaçá",
"delibai",
"delibam",
"delibei",
"delibem",
"delibou",
"delidor",
"delidão",
"deligai",
"delingo",
"delingá",
"deliquá",
"delirão",
"deliste",
"deltota",
"deludam",
"deludas",
"deludem",
"deludes",
"deludia",
"deludis",
"delusas",
"delíeis",
"delíquo",
"delúcia",
"dembada",
"dembado",
"demeada",
"demeais",
"demeara",
"demeava",
"demeeis",
"demeiam",
"demeiem",
"demeies",
"demenso",
"demitis",
"demovei",
"demovim",
"demovir",
"demudai",
"demudei",
"demudem",
"demudes",
"demulce",
"demulci",
"demulos",
"demulça",
"demulço",
"denegai",
"denegas",
"denerva",
"denerve",
"denervo",
"dengais",
"dengamo",
"denguei",
"dengueu",
"denguia",
"denodai",
"denodas",
"denodei",
"denodem",
"denotai",
"densada",
"densamo",
"densará",
"densava",
"denseis",
"densemo",
"dentamo",
"dentará",
"denteai",
"denteei",
"denteie",
"denteio",
"dentele",
"dentelá",
"dentemo",
"dentoma",
"denudai",
"denudam",
"denudei",
"denudem",
"denutra",
"denutre",
"denutro",
"depenai",
"depenes",
"deperdi",
"depilai",
"depluma",
"deplume",
"deplumo",
"depolir",
"depolma",
"depolme",
"depolmo",
"depormo",
"depraça",
"depuras",
"depures",
"deputai",
"deputei",
"deputes",
"dequita",
"dequito",
"derisca",
"derivai",
"derogai",
"derogam",
"derogas",
"derrabo",
"derreai",
"derreei",
"derrega",
"derreie",
"derrice",
"derriso",
"derroce",
"derruam",
"derruas",
"derruís",
"deríbia",
"desabes",
"desabra",
"desacue",
"desacuo",
"desacuá",
"desador",
"desados",
"desadão",
"desaere",
"desaero",
"desaerá",
"desafeá",
"desafiz",
"desafua",
"desafue",
"desafuo",
"desaina",
"desalia",
"desalie",
"desalio",
"desamai",
"desamas",
"desamem",
"desameá",
"desamue",
"desamuo",
"desante",
"desapus",
"desapôs",
"desapõe",
"desarai",
"desaras",
"desarca",
"desarei",
"desarou",
"desarão",
"desasai",
"desasam",
"desasei",
"desasem",
"desases",
"desasia",
"desasis",
"desasiu",
"desasse",
"desaugo",
"desaura",
"desaure",
"desauri",
"desauro",
"desavam",
"desavas",
"desavie",
"desazai",
"desazam",
"desazas",
"desazei",
"desazem",
"desazes",
"desazou",
"desbaba",
"desbabe",
"desbabo",
"desbago",
"desbagá",
"desbare",
"desbaro",
"desbeba",
"desbebi",
"desbebo",
"desbico",
"desboco",
"desboie",
"desboio",
"desboiá",
"desbria",
"desbrie",
"desbroe",
"desbroo",
"desbroá",
"descapo",
"descapá",
"descave",
"descavo",
"descego",
"descegá",
"descepe",
"descepo",
"descocá",
"descomo",
"descope",
"descopo",
"descopá",
"desdada",
"desdais",
"desdará",
"desdeis",
"desdera",
"desdiam",
"desdias",
"desdido",
"desdoba",
"desdobe",
"desdobo",
"deseive",
"deseivo",
"deseivá",
"deseixa",
"deseixe",
"desempa",
"desende",
"desfaie",
"desfali",
"desfeai",
"desfeei",
"desfeio",
"desfiai",
"desfite",
"desfito",
"desfreá",
"desfrua",
"desfruo",
"desgaba",
"desgabe",
"desgafe",
"desgafo",
"desgafá",
"desgasa",
"desgele",
"desimpô",
"desince",
"desisco",
"deslaio",
"deslana",
"deslane",
"deslano",
"deslapa",
"deslape",
"deslapo",
"deslate",
"deslato",
"deslatá",
"deslave",
"deslavo",
"deslego",
"deslegá",
"desliai",
"desliam",
"deslias",
"desliei",
"desliem",
"deslies",
"deslime",
"deslimo",
"deslimá",
"desloda",
"deslode",
"desluza",
"desluze",
"desluzi",
"desluzo",
"desmeai",
"desmear",
"desmeei",
"desmeia",
"desmeie",
"desmeio",
"desmeou",
"desmeça",
"desmeço",
"desmine",
"desmino",
"desmoce",
"desmoma",
"desmose",
"desmoço",
"desmoçá",
"desmuca",
"desmude",
"desmuna",
"desmuno",
"desmure",
"desmuro",
"desnego",
"desneve",
"desnevo",
"desnoco",
"desnocá",
"desnota",
"desnote",
"desnoto",
"desnuai",
"desnuam",
"desnuas",
"desnuco",
"desnuei",
"desnuem",
"desnues",
"desnuou",
"desolai",
"desolas",
"desolem",
"desoles",
"desolho",
"desorno",
"desougo",
"desougá",
"desouve",
"desouvi",
"desouço",
"desovai",
"despapa",
"despape",
"despapo",
"despeai",
"despeei",
"despeia",
"despeie",
"despeio",
"despico",
"despoer",
"desporá",
"despulo",
"despume",
"despumo",
"despumá",
"desquia",
"desquie",
"desquio",
"desraba",
"desrabe",
"desrabo",
"desramo",
"desrate",
"desrato",
"desratá",
"desregi",
"desregê",
"desreja",
"desrejo",
"desrice",
"desrisa",
"desriza",
"desrize",
"desriça",
"desriço",
"desrugo",
"desruma",
"desrume",
"dessado",
"dessais",
"dessamo",
"dessara",
"desseco",
"dessele",
"desselo",
"dessemo",
"dessiga",
"dessigo",
"dessoai",
"dessoam",
"dessoca",
"dessoco",
"dessoei",
"dessoem",
"dessoes",
"dessola",
"dessole",
"dessolo",
"dessoou",
"dessore",
"dessoro",
"dessuai",
"dessuam",
"dessuas",
"dessude",
"dessudo",
"dessudá",
"dessuei",
"dessuem",
"dessues",
"dessuja",
"dessuje",
"dessuou",
"destale",
"destalo",
"destaro",
"desteci",
"destele",
"destemo",
"destoai",
"destoes",
"destone",
"destono",
"destons",
"destope",
"destore",
"destoro",
"destupa",
"destupi",
"destupo",
"desténs",
"desugai",
"desugam",
"desugas",
"desugou",
"desugue",
"desunas",
"desurda",
"desurde",
"desurdi",
"desusai",
"desusas",
"desusei",
"desusou",
"desvaco",
"desvacá",
"desvaia",
"desvais",
"desvaiu",
"desvare",
"desvaro",
"desvará",
"desvede",
"desveem",
"desveza",
"desveze",
"desvezo",
"desviga",
"desvigo",
"desvões",
"deszela",
"deszele",
"deszipo",
"desábil",
"desábis",
"detardo",
"detardá",
"detedor",
"detergi",
"deterja",
"deterjo",
"detetai",
"detetas",
"detetes",
"deteúda",
"deteúdo",
"detidão",
"detorai",
"detorei",
"detorem",
"detores",
"detorou",
"detramo",
"detramá",
"detrona",
"detrone",
"detrono",
"detuaná",
"deunces",
"deussum",
"deutovo",
"devaneá",
"develai",
"develei",
"develem",
"develes",
"develou",
"devenam",
"devenas",
"devenem",
"devenis",
"devinde",
"devirás",
"devirão",
"devisai",
"devisam",
"devisem",
"dexiare",
"dextans",
"dezincá",
"deãozão",
"deãzona",
"dhalina",
"diabame",
"diabeta",
"diabras",
"diabres",
"diabris",
"diabros",
"diacele",
"diademe",
"diademo",
"diadose",
"diafeno",
"diagais",
"dialaca",
"dialete",
"dialhos",
"dialiso",
"dialose",
"dialoés",
"diamazi",
"diambas",
"diambes",
"diamido",
"diamilo",
"diamoro",
"diamões",
"diangas",
"dianite",
"dianito",
"dianjás",
"diantia",
"diantos",
"dianuco",
"diapira",
"diapses",
"diarcos",
"diarize",
"diarizo",
"diascos",
"diascro",
"diastro",
"diatela",
"diatima",
"diatlos",
"diazona",
"diazote",
"dibalas",
"dibelas",
"dibixis",
"dibulos",
"dibungo",
"dibutes",
"dibólia",
"dicabas",
"dicalco",
"dicanas",
"dicanga",
"dicarei",
"dicares",
"dicarmo",
"dicarpo",
"dicarás",
"dicarão",
"dicavas",
"diceida",
"dicelas",
"dicelia",
"dicelos",
"dicerca",
"dicifos",
"dicimbe",
"dicliso",
"dicloma",
"diclume",
"dicocas",
"dicocos",
"dicodes",
"dicoles",
"dicomas",
"dicondo",
"dicongo",
"dicoreu",
"dicoria",
"dicoris",
"dicrana",
"dicrano",
"dicruro",
"dicteia",
"dicteus",
"dictite",
"dictriz",
"dicumbo",
"dicuris",
"dicáxis",
"dicçona",
"dicélio",
"dicéria",
"dicério",
"dicólon",
"didemno",
"didutor",
"didínio",
"diecias",
"diegeta",
"diembes",
"diembos",
"diepesa",
"diesada",
"diesado",
"diesais",
"diesamo",
"diesara",
"diesava",
"diesemo",
"dietina",
"difalos",
"difanda",
"difemos",
"difilas",
"difilos",
"difiope",
"difluam",
"difluas",
"difluem",
"difluis",
"difluiu",
"difluía",
"difoles",
"difrato",
"difubas",
"difumbe",
"difutas",
"difásio",
"difíida",
"difólis",
"digares",
"digiras",
"digleno",
"dignemo",
"digonia",
"digreda",
"digrede",
"digredo",
"digride",
"digênea",
"digênie",
"digícia",
"diiambo",
"diicana",
"diidrol",
"dijiças",
"dijoles",
"dilamos",
"dilanda",
"dilanie",
"dilanio",
"dilaniá",
"dilargo",
"dilecas",
"dilecos",
"dilepto",
"dileuós",
"diligós",
"dilobas",
"dilolos",
"dilonas",
"diluamo",
"dilulas",
"dilutos",
"diluvie",
"diluvos",
"diluímo",
"dimanas",
"dimanes",
"dimatis",
"dimazol",
"dimbuis",
"dimeias",
"dimetis",
"diminas",
"dimuros",
"dinamol",
"dinarda",
"dindada",
"dindado",
"dindais",
"dindamo",
"dindará",
"dindava",
"dindeis",
"dindemo",
"dindiés",
"dinebra",
"dinemas",
"dinemos",
"dinguis",
"dinoios",
"dinomis",
"dinopes",
"dinopse",
"dinotim",
"dinsede",
"dinunga",
"dinungo",
"dinópis",
"dinúvio",
"dioctis",
"diodina",
"diodona",
"diolena",
"diosmas",
"diotias",
"dioxias",
"dipigia",
"dipiros",
"dipneus",
"dipsias",
"dipígio",
"dipírio",
"dipônio",
"diquara",
"diqueia",
"diqueis",
"diqueje",
"diquemo",
"diquíxi",
"diracma",
"diriana",
"diriano",
"dirimas",
"dirimes",
"dirimis",
"dirinas",
"dirinos",
"diriris",
"dirquis",
"dirruir",
"diruais",
"diruirá",
"diruíam",
"diruías",
"diruída",
"disamis",
"disanha",
"discamo",
"discifo",
"discina",
"discuse",
"disdera",
"diselma",
"disemas",
"disferi",
"disfira",
"disfiro",
"disgras",
"disombe",
"disomia",
"dispoer",
"dissaco",
"dissana",
"dissano",
"dissava",
"dissida",
"dissomo",
"dissone",
"dissote",
"distada",
"distamo",
"distemo",
"distoma",
"ditanda",
"ditanga",
"ditarmo",
"ditaxes",
"ditaxia",
"ditazol",
"ditaína",
"ditecos",
"ditenda",
"ditieno",
"ditobas",
"ditofal",
"ditoxos",
"ditumas",
"ditutas",
"diundas",
"diungas",
"diurina",
"divagai",
"divalis",
"divanis",
"divedos",
"divetas",
"divisai",
"divunas",
"divunda",
"divídua",
"dixicas",
"dixissa",
"dixitas",
"dixídea",
"dixídeo",
"dizenze",
"dizidão",
"dizimes",
"dizoica",
"dizoico",
"dizumbe",
"diápero",
"diáscia",
"diáster",
"diçonas",
"diécica",
"diécico",
"diécios",
"diélias",
"diénias",
"diódios",
"diópros",
"diósmeo",
"diótipa",
"diúrica",
"diúrico",
"djambas",
"doadona",
"doadões",
"doaires",
"doairos",
"doareis",
"doaremo",
"doaçona",
"dobador",
"dobadão",
"dobales",
"dobamos",
"dobante",
"dobarei",
"dobarem",
"dobares",
"dobaria",
"dobarmo",
"dobarás",
"dobarão",
"dobaste",
"dobavas",
"doborus",
"dobremo",
"dobruns",
"doburus",
"dobínea",
"docemos",
"docimeu",
"docleia",
"docroti",
"docuins",
"dodoneu",
"dodônio",
"doedora",
"doerdes",
"doereis",
"doeremo",
"doerias",
"doestai",
"doestei",
"doestem",
"dofonas",
"dofonos",
"dofâmio",
"dogadas",
"dogados",
"dogesas",
"doideje",
"doidete",
"doideto",
"doirais",
"doiramo",
"doireis",
"doireja",
"doireje",
"doirejo",
"doiremo",
"dojicas",
"dojicos",
"dolmite",
"dolonca",
"dolonco",
"dolopeu",
"doloqué",
"dolquis",
"dolídea",
"dolídeo",
"dolíida",
"dolíolo",
"dolíone",
"dolópio",
"dolória",
"dolório",
"domadão",
"domante",
"domarmo",
"domavas",
"dombdis",
"dombica",
"dombolo",
"dombéya",
"domenas",
"dominim",
"domista",
"domitos",
"domoica",
"domoide",
"domícia",
"dondica",
"dondico",
"dondona",
"dondons",
"doneada",
"doneado",
"doneais",
"doneará",
"doneava",
"doneeis",
"doneiam",
"doneias",
"doneiem",
"doneies",
"donetas",
"donezes",
"dongris",
"dontres",
"donácia",
"doparas",
"doparei",
"dopares",
"doparmo",
"dopaste",
"dopavas",
"dopemos",
"dopsona",
"dopável",
"dorasca",
"doremas",
"doreíto",
"doriais",
"dorileu",
"doripes",
"dormeci",
"dornões",
"dorobos",
"dorosas",
"dorruns",
"dorsado",
"dorídea",
"dosadão",
"dosante",
"dosaras",
"dosarei",
"dosares",
"dosarmo",
"dosaste",
"dosavas",
"doseais",
"doseará",
"doseeis",
"doseias",
"doseiem",
"doseies",
"dossele",
"dosselo",
"dosselá",
"dossoró",
"dotante",
"dotares",
"dotavas",
"dotável",
"dotídea",
"dotídeo",
"doudeje",
"doudela",
"doudeto",
"doudões",
"doundos",
"dourais",
"douramo",
"doureja",
"doureje",
"dourejo",
"douremo",
"doutiai",
"doutiam",
"doutiar",
"doutias",
"doutiei",
"doutiem",
"douties",
"doutiou",
"doviale",
"dovorne",
"dovórni",
"dozenas",
"dozenos",
"doários",
"doçadas",
"doçador",
"doçados",
"doçadão",
"doçamos",
"doçarei",
"doçarem",
"doçarás",
"doçarão",
"doçasse",
"doçaste",
"doçavam",
"doçavas",
"doçotes",
"doçável",
"doódias",
"dragais",
"dragamo",
"dragues",
"drainai",
"drainas",
"drainem",
"draines",
"drainou",
"draivas",
"drapeai",
"drapeei",
"drapeie",
"drenais",
"drenamo",
"dreneis",
"drenemo",
"driblai",
"driófis",
"drogueá",
"duaires",
"duairos",
"dualina",
"dualizo",
"duandué",
"duarcas",
"dublais",
"dublamo",
"dubleis",
"dublemo",
"dubulés",
"duburus",
"dubúria",
"ducarás",
"duchais",
"duchamo",
"duchara",
"duchava",
"ducheis",
"duchemo",
"ducinas",
"ductora",
"ducucus",
"ducumes",
"ducunom",
"dudongo",
"dueiras",
"dueiros",
"duelais",
"duelamo",
"dueleis",
"duelemo",
"duelize",
"duelizo",
"duelizá",
"duenhas",
"duernos",
"duetais",
"duetamo",
"dueteis",
"duetemo",
"duganis",
"dugazas",
"duinzes",
"dulcite",
"dulcose",
"duleque",
"duliana",
"duliano",
"dulijás",
"dulimãs",
"duloses",
"duludis",
"dulácia",
"dulçore",
"dulçoro",
"dulídea",
"dulídeo",
"dumásia",
"dumória",
"dunalma",
"dunetas",
"dunália",
"dunária",
"dunário",
"duodias",
"duotais",
"dupiões",
"duplamo",
"duplava",
"dupleis",
"duplemo",
"duplete",
"dupreno",
"duradão",
"duralói",
"durames",
"duranis",
"durarmo",
"durasno",
"durazes",
"duraços",
"durbada",
"durbado",
"durbais",
"durbamo",
"durbará",
"durbava",
"durbeis",
"durbemo",
"durenos",
"durezes",
"duriais",
"durilas",
"durilos",
"durinas",
"durisso",
"durites",
"durmamo",
"duroias",
"durolas",
"duronze",
"dururas",
"durênio",
"duturós",
"duveias",
"duvelas",
"duvália",
"duzamos",
"duzidor",
"duzidão",
"duzinas",
"duzirei",
"duzires",
"duzirmo",
"duzirás",
"duzisse",
"duziste",
"duzíeis",
"duândua",
"dzongas",
"dábitis",
"dácicas",
"dácicos",
"dácrica",
"dácrico",
"dácrion",
"dácrios",
"dátilas",
"dântica",
"déctica",
"déctico",
"dédalas",
"délicos",
"dérbice",
"dérbios",
"dérmeas",
"dérmeos",
"désmica",
"dêntice",
"díceras",
"dícroas",
"dícrono",
"dídimas",
"díftera",
"dígnato",
"dígonos",
"dílofos",
"dílquea",
"dílqueo",
"dímeres",
"díndaro",
"díndias",
"dínetos",
"dínicas",
"dínicos",
"dípcade",
"dípigos",
"dípilos",
"díploas",
"díplodo",
"díploes",
"dípodes",
"díporos",
"dípsada",
"dípsade",
"dípsido",
"díquias",
"dírceas",
"dísacos",
"dísbase",
"dísfono",
"dísporo",
"díticas",
"dítiros",
"dítomas",
"dítomes",
"dítomos",
"dítrica",
"dítrico",
"dítropo",
"díxicos",
"dócmios",
"dódrans",
"dógicas",
"dógicos",
"dólicas",
"dólidas",
"dórides",
"dóxicas",
"dômicos",
"dônaces",
"dúbnios",
"dútilas",
"ealmada",
"ealmado",
"ealmais",
"ealmamo",
"ealmará",
"ealmava",
"ealmeis",
"ealmemo",
"eamboge",
"earcada",
"earcais",
"earcamo",
"earcará",
"earcava",
"earinas",
"earquei",
"earquem",
"earques",
"eatônia",
"ebambes",
"ebamins",
"ebandes",
"ebanino",
"ebanita",
"ebanize",
"ebanizo",
"ebenais",
"ebiaras",
"ebombes",
"ebomins",
"eborato",
"ebulais",
"ebulamo",
"ebulias",
"ebulida",
"ebulimo",
"ebulirá",
"eburino",
"eburnai",
"eburnam",
"eburnar",
"eburnas",
"eburnei",
"eburnem",
"eburnes",
"eburnou",
"ebálias",
"ebálida",
"ebálide",
"ebálido",
"ebálios",
"ebâneos",
"ebânica",
"ebóreas",
"ebóreos",
"ebúrica",
"ebúrico",
"ecabete",
"ecaboro",
"ecadona",
"ecadora",
"ecadões",
"ecagras",
"ecaiodo",
"ecalice",
"ecandas",
"ecantes",
"ecaremo",
"ecariam",
"ecarias",
"ecarmos",
"ecassem",
"ecastes",
"ecciese",
"ecderos",
"ecdoras",
"ecdúsia",
"ecfísia",
"echiões",
"ecieses",
"ecismos",
"eclimia",
"eclodes",
"eclodis",
"eclogal",
"ecludas",
"ecmeias",
"ecoadão",
"ecoaras",
"ecoarmo",
"ecoaste",
"ecoavas",
"ecofato",
"ecofila",
"ecolias",
"econgos",
"ecopada",
"ecopado",
"ecosito",
"ecoável",
"ecrisia",
"ecrucus",
"ecrurus",
"ecréxis",
"ectlima",
"ectoima",
"ectoréu",
"ectrima",
"ectrose",
"ectóbio",
"eculana",
"eculano",
"ecáreis",
"ecáveis",
"ecética",
"ecético",
"ecídios",
"ecílias",
"ecítons",
"ecóleos",
"ecólico",
"ecúleas",
"ecúlice",
"edamina",
"edeagos",
"edenate",
"edendro",
"edeniza",
"edenizo",
"edentai",
"edentam",
"edentei",
"edentem",
"edentou",
"edetana",
"edetano",
"edetóis",
"edeítes",
"edicule",
"editeis",
"editemo",
"ediçona",
"edopeza",
"edulcei",
"edulcem",
"edulces",
"edulçai",
"edulçam",
"edulçar",
"edulças",
"edulçou",
"eduzais",
"eduzamo",
"eduziam",
"eduzias",
"eduzimo",
"eduzirá",
"eduçona",
"edáfios",
"edésias",
"edêmero",
"edêmone",
"edêmono",
"edêneas",
"edêneos",
"edíneas",
"edíneos",
"edípoda",
"edípode",
"edítuos",
"edóbolo",
"edólito",
"efabule",
"efabulo",
"efarada",
"efarado",
"efebato",
"efebias",
"efebião",
"efeitue",
"efeituo",
"efemino",
"eferada",
"eferado",
"efesita",
"efigiai",
"efigiam",
"efigias",
"efigiei",
"efigiem",
"efigiou",
"efipida",
"efireus",
"efitias",
"efluais",
"efluías",
"efluída",
"efluíra",
"eforada",
"eforias",
"efratos",
"efrátea",
"efráteo",
"efuanga",
"efumeai",
"efumear",
"efumeei",
"efumeia",
"efumeie",
"efumeio",
"efumeou",
"efundas",
"efundes",
"efundis",
"efusais",
"efusona",
"efuzéis",
"efátide",
"efébica",
"efélias",
"eféstia",
"efílios",
"efípias",
"efóssil",
"efúgios",
"efúndia",
"egagras",
"egantos",
"egbanas",
"egbanos",
"egbeles",
"egeatos",
"egerana",
"egerina",
"egerino",
"egerita",
"egerito",
"egerses",
"egetino",
"egiense",
"egipana",
"egipano",
"egirito",
"egiálio",
"eglogal",
"eglísia",
"egoismá",
"egometa",
"egosomo",
"egoíces",
"eguados",
"eguamos",
"eguante",
"eguaram",
"eguarei",
"eguarem",
"eguares",
"eguarão",
"eguasse",
"eguaste",
"eguavam",
"eguavas",
"eguemos",
"egárrea",
"egérnia",
"egícera",
"egícola",
"egídeas",
"egídeos",
"egídios",
"egífila",
"egíneas",
"egíneos",
"egóbolo",
"egófaga",
"egófago",
"ególios",
"egópode",
"ehrétia",
"eibadas",
"eibados",
"eicemos",
"eicetas",
"eichões",
"eimadas",
"eimador",
"eimados",
"eimadão",
"eimamos",
"eimando",
"eimante",
"eimaram",
"eimarei",
"eimarem",
"eimares",
"eimaria",
"eimarmo",
"eimarás",
"eimarão",
"eimasse",
"eimavam",
"eimavas",
"eimemos",
"eirenho",
"eiriços",
"eirogas",
"eirogos",
"eitadas",
"eivador",
"eivadão",
"eivante",
"eivarei",
"eivarem",
"eivares",
"eivarmo",
"eivarás",
"eivarão",
"eivaste",
"eivavas",
"eivecas",
"eivemos",
"eixador",
"eixadão",
"eixante",
"eixarei",
"eixarem",
"eixares",
"eixaria",
"eixarmo",
"eixarás",
"eixarão",
"eixasse",
"eixavam",
"eixavas",
"eixiais",
"eiçadas",
"eiçador",
"eiçadão",
"eiçamos",
"eiçando",
"eiçante",
"eiçaram",
"eiçarei",
"eiçarem",
"eiçares",
"eiçaria",
"eiçarmo",
"eiçarás",
"eiçarão",
"eiçasse",
"eiçaste",
"eiçavam",
"eiçavas",
"ejectai",
"ejectas",
"ejectem",
"ejetais",
"ejetamo",
"ejeteis",
"ejetemo",
"ejeçona",
"elacate",
"elaches",
"eladona",
"eladões",
"elagato",
"elageno",
"elaicas",
"elaicos",
"elampos",
"elancei",
"elancem",
"elantes",
"elançai",
"elanças",
"elardes",
"elaremo",
"elariam",
"elarias",
"elarmos",
"elasses",
"elastes",
"elatros",
"elaçona",
"elaínas",
"elbense",
"elcasie",
"eleadas",
"eleador",
"eleadão",
"eleamos",
"eleanas",
"eleanto",
"elearam",
"elearei",
"elearem",
"eleares",
"elearia",
"elearás",
"elearão",
"eleasse",
"eleaste",
"eleavam",
"eleavas",
"eledato",
"eledona",
"eleense",
"elegbás",
"elejamo",
"elencai",
"elendes",
"elengue",
"eleomas",
"elescos",
"eleubás",
"eleítes",
"eleólea",
"eliciai",
"elicies",
"elicite",
"elicnia",
"elidais",
"elidamo",
"elidias",
"elidimo",
"eligiam",
"eligias",
"elijais",
"elimins",
"elingas",
"elingue",
"eliotas",
"elipsai",
"elipsam",
"elipsas",
"elipsei",
"elipsem",
"elipsou",
"elismas",
"elisona",
"elixada",
"elixado",
"elixais",
"elixemo",
"elixiam",
"elixias",
"eliximo",
"eliômis",
"elmetes",
"elodeia",
"elogial",
"elomués",
"elongai",
"elorino",
"eloísmo",
"eluamos",
"eludais",
"eludamo",
"eludiam",
"eludias",
"eludimo",
"eluidão",
"eluirei",
"eluiria",
"eluirás",
"eluirão",
"elumbas",
"elusana",
"elusano",
"elusate",
"elutrie",
"elutrio",
"eluçona",
"eluíeis",
"eluímos",
"eluíres",
"eluíste",
"eluível",
"elvásia",
"elxaíta",
"elyitas",
"eláreis",
"elásmia",
"eláveis",
"elédica",
"elédico",
"eléforo",
"eléquis",
"elêmica",
"elêmico",
"elênias",
"elófilo",
"elóforo",
"elórias",
"elórios",
"elósias",
"elúropo",
"emaceis",
"emacemo",
"emaciai",
"emaciei",
"emaciem",
"emacies",
"emacule",
"emaculo",
"emaculá",
"emagota",
"emagote",
"emagoto",
"emagrai",
"emagras",
"emagrou",
"emalais",
"emalamo",
"emaleis",
"emalemo",
"emalhai",
"emalhas",
"emalhei",
"emalhem",
"emalheá",
"emalhou",
"emalias",
"emanamo",
"emancho",
"emanchá",
"emaneis",
"emanoca",
"emanoco",
"emantai",
"emantam",
"emantas",
"emantei",
"emantes",
"emantou",
"emarada",
"emarais",
"emaramo",
"emarará",
"emarava",
"emareai",
"emarear",
"emareei",
"emareia",
"emareie",
"emareio",
"emareis",
"emaremo",
"emareou",
"emargeá",
"emarjai",
"emarjam",
"emarjas",
"emarjei",
"emarjem",
"emarjes",
"emarjou",
"emassam",
"emassem",
"emasses",
"emasteá",
"emastra",
"emastre",
"emastro",
"ematais",
"emateis",
"ematemo",
"ematera",
"ematiam",
"ematias",
"ematida",
"ematido",
"emaçais",
"emaçamo",
"emaçará",
"emaçava",
"embagai",
"embagam",
"embagas",
"embague",
"embaiam",
"embalso",
"embalça",
"embalço",
"embamas",
"embambo",
"embanai",
"embanam",
"embanas",
"embande",
"embanei",
"embanem",
"embanes",
"embanou",
"embarbe",
"embarbo",
"embarde",
"embarre",
"embasai",
"embasta",
"embaste",
"embasti",
"embasto",
"embatas",
"embatei",
"embaulo",
"embazos",
"embaçai",
"embaída",
"embebas",
"embebei",
"embecas",
"embelgo",
"emberis",
"emberna",
"emberno",
"embesto",
"embicai",
"embidas",
"embigas",
"embiles",
"embilhe",
"embilho",
"embilhá",
"embingo",
"embingá",
"embioco",
"embiote",
"embioto",
"embiotá",
"embirai",
"embiram",
"embirei",
"embirem",
"embires",
"embiris",
"embirou",
"embirus",
"embisca",
"embisco",
"embleco",
"embobai",
"embobei",
"embobem",
"embobou",
"embocai",
"embocas",
"embocei",
"embocem",
"emboces",
"emboito",
"emboitá",
"embojai",
"embojam",
"embojas",
"embojei",
"embojem",
"embojes",
"embojou",
"embolai",
"emboles",
"emboleá",
"embomas",
"embonai",
"embonam",
"embonas",
"embonei",
"embonem",
"embones",
"embonou",
"emborre",
"emborro",
"embosne",
"embosno",
"embosná",
"embosta",
"emboste",
"embotai",
"embotas",
"embotes",
"embougo",
"embougá",
"emboute",
"embouto",
"emboutá",
"emboíza",
"emboíze",
"emboízo",
"embrabe",
"embramo",
"embreai",
"embreio",
"embreto",
"embrida",
"embride",
"embroco",
"embrocá",
"embrume",
"embrumo",
"embruta",
"embruto",
"embruxe",
"embruxo",
"embubas",
"embucem",
"embuces",
"embudai",
"embudam",
"embudei",
"embudem",
"embudou",
"emburis",
"emburla",
"emburés",
"embutas",
"embuxis",
"embuzia",
"embuzie",
"embuzio",
"embuçai",
"embuíza",
"embuíze",
"embuízo",
"embélia",
"embófio",
"emechai",
"emecham",
"emechas",
"emechei",
"emechem",
"emeches",
"emechou",
"emedada",
"emedado",
"emedais",
"emedamo",
"emedará",
"emedava",
"emedeis",
"emedemo",
"emelada",
"emelado",
"emelais",
"emelamo",
"emelará",
"emelava",
"emeleai",
"emelear",
"emeleei",
"emeleia",
"emeleie",
"emeleio",
"emeleis",
"emelemo",
"emeleou",
"emelias",
"emenias",
"emenine",
"emenses",
"ementai",
"ementem",
"emeruja",
"emeruje",
"emerujo",
"emetiza",
"emetize",
"emetizo",
"emeziai",
"emeziam",
"emeziar",
"emezias",
"emeziei",
"emeziem",
"emezies",
"emeziou",
"emicons",
"emidina",
"emionos",
"emiseno",
"emitais",
"emitamo",
"emitemo",
"emoirai",
"emoiram",
"emoirar",
"emoiras",
"emoirei",
"emoirem",
"emoires",
"emoirou",
"emoitai",
"emoitam",
"emoitar",
"emoitas",
"emoitei",
"emoitem",
"emoites",
"emoitou",
"emoldai",
"emoldas",
"emoldei",
"emoldem",
"emoldes",
"emoldou",
"emolhai",
"emolham",
"emolhas",
"emolhem",
"emolhes",
"emolhou",
"emoliam",
"emolias",
"emolida",
"emolido",
"emolira",
"emonada",
"emonado",
"emonais",
"emonamo",
"emonará",
"emonava",
"emoneis",
"emonemo",
"emoreai",
"emorear",
"emoreei",
"emoreia",
"emoreie",
"emoreio",
"emoreou",
"emoscai",
"emoscam",
"emoscar",
"emoscas",
"emoscou",
"emostai",
"emostam",
"emostas",
"emostei",
"emostem",
"emostes",
"emostou",
"emourai",
"emouram",
"emourar",
"emouras",
"emourei",
"emourem",
"emoures",
"emourou",
"emoçona",
"empacai",
"empador",
"empales",
"empaleá",
"empalie",
"empalio",
"empamos",
"empanai",
"empanes",
"empanga",
"empango",
"empanhe",
"empapai",
"empapas",
"empapes",
"emparai",
"emparas",
"emparco",
"emparcá",
"emparde",
"empardo",
"emparem",
"empareá",
"emparmo",
"emparra",
"emparre",
"emparva",
"emparve",
"emparvo",
"emparão",
"empasme",
"empasmo",
"empavam",
"empavas",
"empaveá",
"empavoe",
"empavoo",
"empeado",
"empeais",
"empeará",
"empeava",
"empecha",
"empeeis",
"empegai",
"empegas",
"empegou",
"empeiam",
"empeias",
"empeiem",
"empeies",
"empeire",
"empeiro",
"empejai",
"empejam",
"empejar",
"empejas",
"empejei",
"empejem",
"empejes",
"empejou",
"empelai",
"empelam",
"empelas",
"empelei",
"empelem",
"empeles",
"empelou",
"empemos",
"empenai",
"empenco",
"empencá",
"empenes",
"empeole",
"empeolo",
"empeolá",
"emperla",
"emperle",
"emperlo",
"emperne",
"emperno",
"emperol",
"empesai",
"empesei",
"empesem",
"empeses",
"empesga",
"empesgo",
"empesos",
"empezai",
"empezam",
"empezem",
"empezes",
"empeçai",
"empicai",
"empicam",
"empicar",
"empicas",
"empicou",
"empiese",
"empinai",
"empines",
"empiole",
"empiolo",
"empiolá",
"empioro",
"empipai",
"empipam",
"empipar",
"empipas",
"empipei",
"empipem",
"empipes",
"empipou",
"empisco",
"emplumo",
"empoais",
"empoamo",
"empoará",
"empobro",
"empobrá",
"empocei",
"empocem",
"empoeis",
"empoemo",
"empofes",
"empofos",
"empoita",
"empoite",
"empoito",
"empolai",
"empoles",
"empoleá",
"empolhe",
"empolho",
"empolma",
"empolme",
"empolmo",
"empolto",
"empolve",
"empolvo",
"empolvá",
"empombe",
"empombo",
"emponda",
"emponte",
"empopai",
"empopam",
"empopas",
"empopei",
"empopem",
"empopes",
"empopou",
"emporco",
"emposte",
"empoçai",
"emproai",
"emproei",
"emproem",
"emproes",
"emprume",
"emprumo",
"empucas",
"empucos",
"empulgo",
"empulgá",
"empupai",
"empupas",
"empupei",
"empupem",
"empupes",
"empupos",
"empupou",
"empuxai",
"empuxei",
"empuxem",
"empália",
"empídea",
"empídeo",
"empínea",
"empíneo",
"empófio",
"emulais",
"emulamo",
"emuleis",
"emulemo",
"emundam",
"emundas",
"emundei",
"emundem",
"emundes",
"emundou",
"emurada",
"emurado",
"emurche",
"emurcho",
"emurchá",
"emátios",
"emânios",
"emênica",
"emênico",
"emínias",
"emófita",
"emônida",
"enaedro",
"enagene",
"enageno",
"enagená",
"enaifai",
"enaifam",
"enaifar",
"enaifas",
"enaifei",
"enaifem",
"enaifes",
"enaifou",
"enaipai",
"enaipam",
"enaipas",
"enaipei",
"enaipem",
"enaipes",
"enaipou",
"enalita",
"enaltai",
"enaltam",
"enaltas",
"enaltei",
"enaltem",
"enaltes",
"enaltou",
"enantol",
"enantos",
"enarrai",
"enarram",
"enarras",
"enarrei",
"enarrem",
"enarres",
"enarrou",
"enastre",
"enastro",
"enatada",
"enatado",
"enatais",
"enatamo",
"enatará",
"enatava",
"enateis",
"enatemo",
"enauseá",
"enaçona",
"encabai",
"encabam",
"encabas",
"encabei",
"encabem",
"encabes",
"encacia",
"encadas",
"encados",
"encadro",
"encafue",
"encafuo",
"encalai",
"encalam",
"encalas",
"encalde",
"encaldo",
"encaldá",
"encalei",
"encalem",
"encales",
"encalga",
"encalgo",
"encalia",
"encalis",
"encaliu",
"encalme",
"encalmo",
"encalou",
"encamai",
"encamam",
"encamas",
"encamba",
"encambe",
"encambo",
"encamei",
"encamem",
"encanai",
"encanes",
"encanga",
"encango",
"encanhe",
"encanho",
"encanhá",
"encanoe",
"encanoo",
"encapai",
"encapes",
"encarpa",
"encasai",
"encasas",
"encasco",
"encasei",
"encasem",
"encaude",
"encaudo",
"encaudá",
"encavai",
"encavas",
"encavei",
"encavem",
"encedra",
"encedro",
"enceire",
"enceiro",
"enceirá",
"encelai",
"encelam",
"encelas",
"encelei",
"encelem",
"enceles",
"encelou",
"encenai",
"encepai",
"encepam",
"encepas",
"encepei",
"encepem",
"encepes",
"encepou",
"encerai",
"enceras",
"encetai",
"encetes",
"enchoce",
"enchoça",
"enchoço",
"enciese",
"encinhe",
"encinhá",
"encinte",
"encinto",
"encinza",
"encinze",
"encinzo",
"encirre",
"encirro",
"encirrá",
"encisco",
"enciscá",
"encodai",
"encodas",
"encodem",
"encodeá",
"encodoe",
"encodoo",
"encodoá",
"encofai",
"encofam",
"encofas",
"encofei",
"encofem",
"encofes",
"encofou",
"encofro",
"encoifa",
"encoife",
"encoifo",
"encoima",
"encoime",
"encoimo",
"encoira",
"encoire",
"encoiro",
"encoite",
"encoitá",
"encolai",
"encolam",
"encolas",
"encolei",
"encolem",
"encoles",
"encombo",
"enconai",
"enconam",
"enconas",
"enconca",
"enconco",
"enconei",
"enconem",
"encones",
"enconou",
"encopai",
"encopam",
"encopas",
"encopei",
"encopem",
"encopes",
"encopou",
"encoras",
"encorco",
"encorcá",
"encorei",
"encorem",
"encorne",
"encorno",
"encoure",
"encouro",
"encoute",
"encoutá",
"encovai",
"encovas",
"encovei",
"encoves",
"encozam",
"encozas",
"encozei",
"encozem",
"encozer",
"encozes",
"encozeu",
"encozia",
"encrina",
"encrino",
"encrisa",
"encriso",
"encroco",
"encrocá",
"encrope",
"encruai",
"encruas",
"encruei",
"encruem",
"encrues",
"encrupa",
"encrupi",
"encrupo",
"encubai",
"encubas",
"encubes",
"encucai",
"encucas",
"enculpa",
"enculpe",
"enculpo",
"encumes",
"encumeá",
"encunhe",
"encunho",
"endacas",
"endarco",
"endaval",
"endecho",
"endejai",
"endejam",
"endejas",
"endejei",
"endejem",
"endejes",
"endejou",
"endezes",
"endilga",
"endilgo",
"endinhe",
"endinhá",
"endoada",
"endogás",
"endorar",
"endoude",
"endoudo",
"endouto",
"endrago",
"endragá",
"endrões",
"endudas",
"endudos",
"enduiás",
"endurai",
"endurei",
"endurou",
"endímio",
"endócia",
"endógea",
"endógeo",
"endótia",
"eneates",
"enelzão",
"enervai",
"enesgai",
"enesgam",
"enesgas",
"enesgou",
"enesgue",
"enesóis",
"enevada",
"enevado",
"enevais",
"enevamo",
"enevará",
"enevava",
"eneveis",
"enevemo",
"enevoai",
"enevoas",
"enevoes",
"enfango",
"enfangá",
"enfarai",
"enfaras",
"enfarem",
"enfares",
"enfareá",
"enfarne",
"enfarno",
"enfarná",
"enfaroe",
"enfaroá",
"enfarpe",
"enfarpo",
"enfarpá",
"enfasai",
"enfasam",
"enfasar",
"enfasas",
"enfasei",
"enfasem",
"enfatue",
"enfatuo",
"enfeais",
"enfeará",
"enfebro",
"enfebrá",
"enfeeis",
"enfeiai",
"enfeias",
"enfeies",
"enfeire",
"enfeiro",
"enfelpe",
"enfelpo",
"enfelpá",
"enfenai",
"enfenam",
"enfenas",
"enfenei",
"enfenem",
"enfenes",
"enfenou",
"enferre",
"enferro",
"enferrá",
"enfeste",
"enfeudo",
"enfezai",
"enfezas",
"enfezem",
"enfezes",
"enfiais",
"enfilos",
"enfirme",
"enfirmo",
"enfirmá",
"enfitai",
"enfitam",
"enfitas",
"enfitem",
"enfites",
"enfitia",
"enfixai",
"enfixam",
"enfixas",
"enfixei",
"enfixem",
"enfixes",
"enfixou",
"enfobia",
"enfobie",
"enfobio",
"enfocai",
"enfogai",
"enfogam",
"enfogas",
"enfogou",
"enfogue",
"enfoice",
"enfoiço",
"enfoiçá",
"enfolai",
"enfolam",
"enfolas",
"enfolei",
"enfolem",
"enfoles",
"enfolou",
"enfonda",
"enfonde",
"enfondi",
"enfondo",
"enforja",
"enforje",
"enforjo",
"enforno",
"enforre",
"enforrá",
"enforta",
"enforte",
"enforti",
"enforto",
"enfouce",
"enfouço",
"enfouçá",
"enfraco",
"enfracá",
"enfraga",
"enfrago",
"enfreai",
"enfreei",
"enfreie",
"enfreio",
"enfriai",
"enfriam",
"enfrias",
"enfriei",
"enfries",
"enfruta",
"enfruto",
"enfuada",
"enfuado",
"enfuais",
"enfuamo",
"enfuará",
"enfuava",
"enfueis",
"enfuemo",
"enfumai",
"enfumam",
"enfumas",
"enfumei",
"enfumem",
"enfumes",
"enfumou",
"enfunai",
"enfunei",
"enfunes",
"enfurde",
"enfurdo",
"enfurdá",
"enfurie",
"enfurio",
"enfusai",
"enfusam",
"enfusco",
"enfusei",
"enfusem",
"enfuses",
"enfusou",
"enfusto",
"engacei",
"engacem",
"engaces",
"engadão",
"engaiai",
"engaiam",
"engaias",
"engaiei",
"engaiem",
"engaies",
"engaiou",
"engajai",
"engajes",
"engalai",
"engalam",
"engalei",
"engalem",
"engales",
"engaleá",
"engalga",
"engalgo",
"engalhe",
"engalho",
"engalie",
"engalio",
"engaliá",
"engambo",
"enganço",
"engarei",
"engares",
"engarfe",
"engarfo",
"engaria",
"engarre",
"engarro",
"engarás",
"engarão",
"engasse",
"engatai",
"engavas",
"engaxos",
"engaçai",
"engaçam",
"engaças",
"engeado",
"engeais",
"engeará",
"engeava",
"engecos",
"engeeis",
"engeiam",
"engeias",
"engeiem",
"engeies",
"engelhe",
"engelim",
"engigai",
"engigam",
"engigar",
"engigas",
"engigou",
"engigue",
"enginos",
"englelê",
"engobai",
"engobam",
"engobar",
"engobas",
"engobei",
"engobem",
"engobou",
"engodai",
"engodem",
"engodes",
"engoiai",
"engoiam",
"engoias",
"engoiei",
"engoies",
"engolas",
"engomai",
"engomem",
"engonie",
"engonio",
"engonça",
"engorra",
"engorre",
"engorro",
"engraze",
"engrazo",
"engrela",
"engrele",
"engrelo",
"engrifa",
"engrife",
"engrifo",
"engrile",
"engrilo",
"engrime",
"engrimo",
"engrimá",
"engripo",
"engrope",
"engruma",
"engrume",
"engrumo",
"engueis",
"engulhe",
"engunhe",
"engunho",
"enhadir",
"enicuro",
"enidras",
"eniense",
"enigmai",
"enigmam",
"enigmei",
"enigmem",
"enigmou",
"enimagá",
"enjacas",
"enjaeza",
"enjaeze",
"enjaezo",
"enjalgo",
"enjalgá",
"enjanga",
"enjango",
"enjeira",
"enjeire",
"enjeiro",
"enjeram",
"enjeras",
"enjerem",
"enjeres",
"enjeria",
"enjeris",
"enjicai",
"enjicam",
"enjique",
"enjoais",
"enjoamo",
"enjoeis",
"enjoemo",
"enjogós",
"enjoiai",
"enjoiam",
"enjoias",
"enjoiei",
"enjoiem",
"enjoies",
"enjoine",
"enjoino",
"enjoiná",
"enjoiou",
"enjojos",
"enjolós",
"enjonai",
"enjonam",
"enjonar",
"enjonas",
"enjonei",
"enjonem",
"enjones",
"enjonou",
"enjorco",
"enjorcá",
"enjorgo",
"enjorgá",
"enjouje",
"enjoujo",
"enjoujá",
"enjuane",
"enjuano",
"enjuaná",
"enjugai",
"enjugam",
"enjugas",
"enjugou",
"enjunce",
"enjunço",
"enjunçá",
"enlabia",
"enlabie",
"enlabio",
"enlacre",
"enlacro",
"enlacrá",
"enlagai",
"enlagam",
"enlagas",
"enlagou",
"enlaiva",
"enlaive",
"enlaivo",
"enlamai",
"enlamam",
"enlamar",
"enlamas",
"enlamem",
"enlames",
"enlamou",
"enlanza",
"enlanze",
"enlanzo",
"enlapai",
"enlapam",
"enlapas",
"enlapei",
"enlapem",
"enlapes",
"enlatai",
"enlates",
"enleais",
"enleaça",
"enleeis",
"enleies",
"enleiva",
"enleive",
"enleivo",
"enlerde",
"enlerdo",
"enlheai",
"enlheei",
"enlheia",
"enlheie",
"enlheio",
"enlheou",
"enlicei",
"enlicem",
"enlices",
"enliçai",
"enliçam",
"enliças",
"enliçou",
"enlocai",
"enlocam",
"enlocas",
"enlocou",
"enlodai",
"enlodas",
"enlodem",
"enlodes",
"enloice",
"enloire",
"enloiro",
"enloisa",
"enloise",
"enloiso",
"enloiço",
"enloiçá",
"enlojai",
"enlojam",
"enlojas",
"enlojei",
"enlojem",
"enlojes",
"enlomba",
"enlombe",
"enlombo",
"enlouce",
"enlouco",
"enloura",
"enloure",
"enlouro",
"enlousa",
"enlouse",
"enlouso",
"enluare",
"enluaro",
"enludra",
"enludre",
"enludro",
"enlurai",
"enluram",
"enluras",
"enlurei",
"enlurem",
"enlures",
"enlurou",
"enlutai",
"enlutas",
"enlutes",
"enluvai",
"enluvas",
"enluvem",
"enluves",
"ennodar",
"enoctos",
"enodais",
"enodamo",
"enodará",
"enodava",
"enodeis",
"enodemo",
"enodoai",
"enodoas",
"enodoei",
"enodoes",
"enoemas",
"enogado",
"enogais",
"enogará",
"enogava",
"enoguei",
"enoguem",
"enogues",
"enoitai",
"enoitas",
"enoitei",
"enoitem",
"enoitou",
"enojais",
"enojeis",
"enojemo",
"enolada",
"enoliza",
"enolize",
"enolizo",
"enolzão",
"enoméis",
"enososa",
"enososo",
"enoutai",
"enoutam",
"enoutas",
"enoutei",
"enoutem",
"enoutes",
"enoutou",
"enovais",
"enovamo",
"enovará",
"enovava",
"enoveis",
"enovemo",
"enquere",
"enqueri",
"enquira",
"enquite",
"enquito",
"enrabes",
"enracei",
"enracem",
"enraces",
"enraiai",
"enraiam",
"enraias",
"enraiei",
"enraiem",
"enraies",
"enraiou",
"enraive",
"enraivo",
"enramai",
"enramas",
"enramem",
"enrames",
"enrameá",
"enranço",
"enrasco",
"enraçai",
"enraçam",
"enraçar",
"enraças",
"enraçou",
"enredai",
"enredeá",
"enregai",
"enreixe",
"enreixo",
"enreixá",
"enrelha",
"enrelhe",
"enrelho",
"enrelve",
"enrelvo",
"enrelvá",
"enrendá",
"enresma",
"enreste",
"enresto",
"enriada",
"enriais",
"enriamo",
"enriará",
"enriava",
"enricai",
"enricas",
"enricei",
"enrices",
"enrieis",
"enriemo",
"enrifai",
"enrifam",
"enrifar",
"enrifas",
"enrifei",
"enrifem",
"enrifes",
"enrifou",
"enrijas",
"enrijem",
"enrijes",
"enrilhe",
"enrilho",
"enrilhá",
"enrimai",
"enrimam",
"enrimar",
"enrimas",
"enrimei",
"enrimem",
"enrimes",
"enrimou",
"enripai",
"enripam",
"enripas",
"enripei",
"enripem",
"enripes",
"enripou",
"enristo",
"enrixai",
"enrixam",
"enrixar",
"enrixas",
"enrixei",
"enrixem",
"enrixes",
"enrixou",
"enrizai",
"enrizam",
"enrizas",
"enrizei",
"enrizem",
"enrizes",
"enrizou",
"enrocai",
"enrocas",
"enroche",
"enrocho",
"enrochá",
"enrodai",
"enrodam",
"enrodar",
"enrodas",
"enrodei",
"enrodem",
"enrodes",
"enrolho",
"enrotar",
"enroupe",
"enroupo",
"enroxai",
"enroxam",
"enroxas",
"enroxei",
"enroxem",
"enroxes",
"enrucei",
"enrucem",
"enruces",
"enrufai",
"enrufam",
"enrufas",
"enrufei",
"enrufem",
"enrufes",
"enrufou",
"enrugai",
"enrusta",
"enrusto",
"enruçai",
"enruçam",
"enruças",
"ensacai",
"ensalce",
"ensalme",
"ensalço",
"ensampe",
"ensampo",
"ensampá",
"ensanhe",
"ensanho",
"ensarto",
"enseais",
"enseara",
"enseava",
"ensebai",
"ensebas",
"ensebem",
"ensecai",
"ensecam",
"ensecas",
"ensedai",
"ensedam",
"ensedas",
"ensedei",
"ensedem",
"ensedes",
"ensedou",
"enseeis",
"enseiai",
"enseiam",
"enseias",
"enseiei",
"enseiem",
"enseies",
"enseire",
"enseiro",
"enseive",
"enseivo",
"ensejai",
"ensejes",
"enselve",
"enselvo",
"enseque",
"ensesgo",
"ensesgá",
"ensevai",
"ensevam",
"ensevar",
"ensevas",
"ensevei",
"ensevem",
"enseves",
"ensevou",
"ensilai",
"ensilas",
"ensilei",
"ensilem",
"ensiles",
"ensilve",
"ensilvo",
"ensoais",
"ensoamo",
"ensoara",
"ensoava",
"ensocai",
"ensocam",
"ensocas",
"ensocou",
"ensodai",
"ensodam",
"ensodar",
"ensodas",
"ensodei",
"ensodem",
"ensodes",
"ensodou",
"ensoeis",
"ensoemo",
"ensogai",
"ensogam",
"ensogas",
"ensogou",
"ensogue",
"ensolva",
"ensolve",
"ensolvo",
"ensonai",
"ensonam",
"ensonas",
"ensonei",
"ensonem",
"ensones",
"ensopai",
"ensopeá",
"ensopie",
"ensopio",
"ensopiá",
"ensoque",
"ensoreá",
"ensorna",
"ensorne",
"ensosse",
"ensujai",
"ensujar",
"ensujas",
"ensujei",
"ensujem",
"ensujes",
"ensujou",
"ensurre",
"ensurro",
"ensurrá",
"entabua",
"entabue",
"entabuo",
"entacas",
"entacha",
"entache",
"entacho",
"entaipe",
"entaipo",
"entalai",
"entalia",
"entaliu",
"entaloe",
"entaloá",
"entamai",
"entamam",
"entamar",
"entamas",
"entambe",
"entamei",
"entamem",
"entames",
"entamou",
"entanca",
"entanga",
"entango",
"entapai",
"entapam",
"entapei",
"entapem",
"entapes",
"entapou",
"enteará",
"entecai",
"entecas",
"enteces",
"entecia",
"entecou",
"enteeis",
"enteiem",
"enteies",
"enteixe",
"enteixo",
"enteixá",
"entejai",
"entejas",
"entejei",
"entejou",
"entelai",
"entelam",
"entelas",
"entelem",
"entelha",
"entelou",
"entenxa",
"entenxe",
"entenxo",
"entesai",
"entesas",
"entesem",
"entesoe",
"entesoo",
"enteçam",
"entibio",
"enticai",
"entinte",
"entisna",
"entisne",
"entisno",
"entivai",
"entivam",
"entivei",
"entivem",
"entives",
"entivou",
"entoamo",
"entocai",
"entodão",
"entoeis",
"entoema",
"entoemo",
"entogai",
"entogam",
"entogas",
"entogou",
"entogue",
"entoice",
"entoire",
"entoiro",
"entoiça",
"entoiço",
"entojai",
"entojas",
"entojem",
"entojes",
"entolam",
"entolar",
"entolde",
"entoldo",
"entoldá",
"entolei",
"entolem",
"entoles",
"entolhi",
"entolho",
"entolhê",
"entolou",
"entonai",
"entonas",
"entonte",
"entontá",
"entorpo",
"entosta",
"entouca",
"entouce",
"entoura",
"entouri",
"entrajo",
"entrapa",
"entrape",
"entrapo",
"entrepô",
"entreza",
"entreze",
"entrezo",
"entriza",
"entrize",
"entrizo",
"entroce",
"entroco",
"entrude",
"entruja",
"entruje",
"entrujo",
"entubai",
"entubas",
"entubes",
"entuche",
"entucho",
"entufai",
"entufas",
"entufei",
"entufem",
"entufes",
"entunas",
"entupes",
"enturba",
"enturbe",
"enturbo",
"enturve",
"enturvo",
"enublai",
"enublas",
"enublei",
"enublem",
"enucleá",
"enuviai",
"enuviam",
"enuvias",
"enuviei",
"enuviem",
"enuvies",
"envalai",
"envalam",
"envalas",
"envalei",
"envalem",
"envales",
"envalou",
"envarai",
"envaras",
"envarei",
"envarem",
"envares",
"envaris",
"envasai",
"envasas",
"envazai",
"envazas",
"envazem",
"enverai",
"enveram",
"enveras",
"enverei",
"enverem",
"enveres",
"enverou",
"envesai",
"envesam",
"envesas",
"envesei",
"envesem",
"envesgo",
"envesou",
"envessa",
"envesse",
"envicei",
"envicem",
"envices",
"envigai",
"envigam",
"envigas",
"envigou",
"envigue",
"envinhe",
"envinho",
"envisca",
"envisgo",
"enviçai",
"enviçam",
"enviças",
"enviçou",
"envolai",
"envolas",
"envolei",
"envolou",
"envulte",
"envulto",
"enxacas",
"enxadai",
"enxadam",
"enxadei",
"enxadem",
"enxades",
"enxadeá",
"enxadou",
"enxaire",
"enxalma",
"enxalme",
"enxalça",
"enxamas",
"enxameá",
"enxaral",
"enxauga",
"enxavos",
"enxecai",
"enxecam",
"enxecas",
"enxecos",
"enxecou",
"enxelhe",
"enxelho",
"enxelhá",
"enxeras",
"enxeres",
"enxeris",
"enxiada",
"enxiado",
"enxiais",
"enxiamo",
"enxiara",
"enxiava",
"enxieis",
"enxiemo",
"enxilha",
"enxilhe",
"enxilho",
"enxofro",
"enxogai",
"enxogam",
"enxogar",
"enxogas",
"enxogue",
"enxorai",
"enxoram",
"enxorar",
"enxoras",
"enxorei",
"enxorem",
"enxores",
"enxorou",
"enxovam",
"enxovei",
"enxovem",
"enxoves",
"enxovio",
"enxovou",
"enxudre",
"enxudrá",
"enxulha",
"enxulho",
"enxurde",
"enxurdo",
"enxurdá",
"enxurre",
"enxária",
"enxário",
"enxôfar",
"enzampo",
"enzarol",
"enzenas",
"enzenza",
"enzinai",
"enzinam",
"enzinei",
"enzinem",
"enzines",
"enzinou",
"enzoica",
"enzolos",
"enzonai",
"enzonam",
"enzonei",
"enzonem",
"enzones",
"enzonou",
"enzonza",
"enzonze",
"enzonzo",
"enáulio",
"enázimo",
"enância",
"enérvea",
"enêmica",
"enêmico",
"enócito",
"enógala",
"enógaro",
"enóleas",
"enópios",
"eolação",
"eolinas",
"eolizai",
"eolizam",
"eolizar",
"eolizas",
"eolizei",
"eolizem",
"eolizes",
"eolizou",
"eolonto",
"eombolo",
"eomorfo",
"eonitas",
"eositas",
"eosites",
"eosotos",
"eotério",
"eozoica",
"eozones",
"epacmos",
"epaltas",
"epaltos",
"eparmas",
"epecino",
"epeiras",
"epervas",
"epibata",
"epiceia",
"epicopo",
"epiecia",
"epifono",
"epilamo",
"epilara",
"epilava",
"epileis",
"epilemo",
"epimone",
"epipial",
"epipião",
"epiplos",
"episita",
"episito",
"epistai",
"epistam",
"epistei",
"epistes",
"epistou",
"epitete",
"epitoxe",
"epiódia",
"epopsia",
"epoquês",
"epulose",
"epumuno",
"epupulo",
"epureia",
"epéolos",
"epíalos",
"epícada",
"epícado",
"epícero",
"epícito",
"epícloe",
"epícomo",
"epídapo",
"epídese",
"epídose",
"epídota",
"epífana",
"epímona",
"epípedo",
"epírica",
"epíscio",
"epísios",
"epítimo",
"equalas",
"equeias",
"equemos",
"equenha",
"equesas",
"equevos",
"equices",
"equieia",
"equisão",
"equiões",
"equímis",
"eradona",
"eradora",
"eradões",
"erantes",
"erardes",
"eraremo",
"erarias",
"erarmos",
"erartas",
"erassem",
"erasses",
"erbabos",
"erbinas",
"ercilas",
"erefuês",
"eremuro",
"erepadá",
"erequês",
"eretino",
"eretite",
"ereçona",
"ergasmo",
"erguiço",
"ergônio",
"ericeis",
"ericemo",
"ericica",
"erictos",
"erijais",
"erilzão",
"erinita",
"eriopes",
"eriopse",
"eriribá",
"eririno",
"erisife",
"erisipe",
"erismas",
"eritino",
"eritios",
"eritros",
"eritrão",
"erizena",
"erizeno",
"eriçais",
"eriçamo",
"ermador",
"ermados",
"ermadão",
"ermamos",
"ermarei",
"ermarem",
"ermares",
"ermaria",
"ermarmo",
"ermarás",
"ermarão",
"ermasse",
"ermaste",
"ermavas",
"ermelos",
"ermemos",
"ernitas",
"erodais",
"erodeis",
"erodemo",
"eroderá",
"erodimo",
"eropata",
"erosais",
"erosamo",
"erosará",
"erosava",
"eroseis",
"erosemo",
"erosona",
"erpetão",
"erpódio",
"errarmo",
"erricei",
"erricem",
"errices",
"errinas",
"errinos",
"erritas",
"erriçai",
"erriças",
"eructai",
"eructei",
"eructem",
"eructes",
"erudais",
"erugita",
"ervacas",
"ervador",
"ervadão",
"ervamos",
"ervanas",
"ervanos",
"ervante",
"ervarei",
"ervarem",
"ervares",
"ervarmo",
"ervarás",
"ervarão",
"ervasco",
"ervasse",
"ervaste",
"ervavam",
"ervavas",
"ervaçum",
"ervecei",
"ervecem",
"erveces",
"erveceu",
"ervecia",
"ervedel",
"ervemos",
"erveçam",
"erveças",
"ervilhe",
"erviças",
"ervoada",
"ervoado",
"ervoais",
"ervoamo",
"ervoará",
"ervoava",
"ervoeis",
"ervoemo",
"ervília",
"eráceas",
"eráceos",
"eráreis",
"erândis",
"erânica",
"erânico",
"erântio",
"erântis",
"erébias",
"erécias",
"eréctis",
"erétias",
"erétrio",
"erêmias",
"erêncio",
"eríceas",
"eríceos",
"erícibe",
"erícios",
"erícola",
"erídana",
"erífero",
"erífias",
"erígeno",
"erígono",
"eríopos",
"erípede",
"erísibe",
"erítaco",
"erítreo",
"erófila",
"erótilo",
"esampai",
"esampam",
"esampas",
"esampei",
"esampem",
"esampes",
"esampou",
"esbafos",
"esbagoe",
"esbagoo",
"esbalge",
"esbalgi",
"esbalhe",
"esbalho",
"esbalhá",
"esbalja",
"esbaljo",
"esbamba",
"esbambe",
"esbambo",
"esbango",
"esbangá",
"esbarai",
"esbaras",
"esbarba",
"esbarbe",
"esbarbo",
"esbarde",
"esbardo",
"esbardá",
"esbarem",
"esbares",
"esbatas",
"esbatei",
"esbeata",
"esbeate",
"esbeato",
"esbeice",
"esbeije",
"esbeijo",
"esbeijá",
"esbeire",
"esbeiro",
"esbeirá",
"esbeiço",
"esbicai",
"esbicam",
"esbicar",
"esbiche",
"esbicho",
"esbichá",
"esbicou",
"esbijai",
"esbijam",
"esbijas",
"esbijei",
"esbijem",
"esbijes",
"esbijou",
"esbique",
"esbirre",
"esbofai",
"esbofas",
"esbofei",
"esbofes",
"esboice",
"esboiço",
"esbolai",
"esbolam",
"esbolas",
"esbolei",
"esbolem",
"esboles",
"esbolou",
"esborde",
"esborre",
"esbouce",
"esboçai",
"esbrace",
"esbrame",
"esbramo",
"esbramá",
"esbraço",
"esbraçá",
"esbrite",
"esbrito",
"esbritá",
"esbrize",
"esbrizo",
"esbrizá",
"esbroai",
"esbroas",
"esbroei",
"esbroem",
"esbroes",
"esbroou",
"esbrugo",
"esbrume",
"esbrumo",
"esbrumá",
"esburna",
"esburne",
"esburni",
"esburno",
"esbuxai",
"esbuxam",
"esbuxas",
"esbuxei",
"esbuxem",
"esbuxes",
"escabra",
"escabre",
"escacai",
"escache",
"escadim",
"escafas",
"escafos",
"escalve",
"escalvo",
"escanai",
"escanam",
"escanas",
"escance",
"escanem",
"escango",
"escangá",
"escanou",
"escarde",
"escareá",
"escarie",
"escarni",
"escaros",
"escarve",
"escasco",
"escauns",
"escavai",
"escaçoe",
"escaçoo",
"escaçoá",
"escaído",
"escelim",
"escinda",
"escindi",
"escindo",
"escoamo",
"escocai",
"escocam",
"escoche",
"escocho",
"escochá",
"escocou",
"escodai",
"escodam",
"escodei",
"escodes",
"escodeá",
"escodou",
"escoeis",
"escoemo",
"escogie",
"escogiá",
"escoice",
"escoimo",
"escoiçá",
"esconse",
"escopem",
"escopeá",
"escorai",
"escorde",
"escorje",
"escorjo",
"escorlo",
"escoroa",
"escoroe",
"escoroo",
"escotie",
"escotio",
"escouce",
"escouça",
"escouço",
"escozam",
"escozas",
"escozei",
"escozem",
"escozer",
"escozes",
"escozeu",
"escozia",
"escrute",
"escucir",
"escudai",
"escudes",
"esculco",
"escumai",
"escumei",
"escumem",
"escumes",
"escupam",
"escupas",
"escupia",
"escurai",
"escuria",
"escurir",
"escuris",
"escuriu",
"escáfio",
"escâmea",
"escãibo",
"escófia",
"esfaime",
"esfaimo",
"esfalco",
"esfalcá",
"esfarde",
"esfardo",
"esfardá",
"esfaria",
"esfarpe",
"esfarpo",
"esfarás",
"esfarão",
"esfatie",
"esfatio",
"esfazei",
"esfazes",
"esfazia",
"esfaçam",
"esfaças",
"esfeces",
"esfecos",
"esfelgo",
"esfelgá",
"esfenas",
"esfenos",
"esferos",
"esferva",
"esfervi",
"esfervo",
"esfervê",
"esfiais",
"esfiamo",
"esfiapo",
"esfiará",
"esfibre",
"esfibro",
"esfibrá",
"esfieis",
"esfiemo",
"esfizer",
"esfloco",
"esflocá",
"esflore",
"esfloro",
"esfodro",
"esfoice",
"esfoira",
"esfoire",
"esfoiro",
"esfoiço",
"esfoiçá",
"esfolhe",
"esfolho",
"esfomeá",
"esfosse",
"esfouce",
"esfouça",
"esfouço",
"esfrole",
"esfrolo",
"esfugir",
"esfumai",
"esfumes",
"esfumeá",
"esfurie",
"esfurio",
"esfuriá",
"esfuroe",
"esfuroo",
"esfuroá",
"esfuzie",
"esfério",
"esgacem",
"esgaces",
"esgadas",
"esgador",
"esgados",
"esgadão",
"esgaire",
"esgaive",
"esgaivo",
"esgamoe",
"esgamoo",
"esgamos",
"esgamoá",
"esgampa",
"esgampe",
"esgampo",
"esgando",
"esganir",
"esgante",
"esganão",
"esgarai",
"esgaram",
"esgarar",
"esgarei",
"esgarem",
"esgargo",
"esgargá",
"esgaria",
"esgarne",
"esgarno",
"esgarou",
"esgarre",
"esgarro",
"esgarás",
"esgarão",
"esgasse",
"esgateá",
"esgavas",
"esgazeá",
"esgaçai",
"esgodai",
"esgodam",
"esgodar",
"esgodas",
"esgodei",
"esgodem",
"esgodes",
"esgodou",
"esgorja",
"esgorjo",
"esgrela",
"esgrile",
"esgrilo",
"esgrilá",
"esgueis",
"esguiai",
"esguiem",
"esguies",
"esguito",
"esipras",
"eslagai",
"eslagar",
"eslagas",
"eslagou",
"eslague",
"eslingo",
"esloana",
"esmador",
"esmadão",
"esmaiai",
"esmaias",
"esmaiem",
"esmaies",
"esmalas",
"esmalha",
"esmalhe",
"esmalho",
"esmalme",
"esmalmo",
"esmalmá",
"esmando",
"esmanie",
"esmanio",
"esmanja",
"esmanje",
"esmanjo",
"esmaram",
"esmarco",
"esmarei",
"esmarem",
"esmares",
"esmaria",
"esmarmo",
"esmarás",
"esmarão",
"esmasse",
"esmaste",
"esmavas",
"esmeada",
"esmeado",
"esmeais",
"esmeara",
"esmeava",
"esmecha",
"esmecho",
"esmeeis",
"esmeiam",
"esmeiem",
"esmeies",
"esmelme",
"esmelmo",
"esmelmá",
"esmemos",
"esmenai",
"esmenam",
"esmenar",
"esmenas",
"esmenei",
"esmenem",
"esmenes",
"esmenou",
"esmerme",
"esmermo",
"esmermá",
"esmetia",
"esmetie",
"esmetio",
"esmiche",
"esmilhe",
"esmilho",
"esmilhá",
"esminto",
"esmiola",
"esmiole",
"esmirre",
"esmirro",
"esmiude",
"esmiúda",
"esmiúdo",
"esmoais",
"esmocai",
"esmocam",
"esmocas",
"esmoche",
"esmocho",
"esmocou",
"esmoeis",
"esmoerá",
"esmoico",
"esmoicá",
"esmoita",
"esmoite",
"esmoito",
"esmolai",
"esmolia",
"esmonai",
"esmonam",
"esmonar",
"esmonas",
"esmonca",
"esmonco",
"esmondo",
"esmonei",
"esmonem",
"esmones",
"esmonou",
"esmorce",
"esmorme",
"esmormo",
"esmormá",
"esmorra",
"esmorre",
"esmorro",
"esmorze",
"esmorzo",
"esmorzá",
"esmorço",
"esmorçá",
"esmosse",
"esmosso",
"esmossá",
"esmouca",
"esmouco",
"esmoute",
"esmouto",
"esmoutá",
"esmoías",
"esmurai",
"esmuram",
"esmuras",
"esmurei",
"esmurem",
"esmures",
"esmáris",
"esmítia",
"esnacai",
"esnacam",
"esnacar",
"esnacas",
"esnacou",
"esnaque",
"esnobai",
"esnocai",
"esnocam",
"esnocas",
"esnocou",
"esnoque",
"esnuada",
"esnuado",
"esnuais",
"esnuamo",
"esnuará",
"esnuava",
"esnueis",
"esnuemo",
"esopeto",
"espaceá",
"espadei",
"espadem",
"espades",
"espadeá",
"espadia",
"espadie",
"espadio",
"espadou",
"espadue",
"espaduo",
"espalde",
"espaleu",
"espanes",
"espapai",
"espapam",
"espapas",
"espapei",
"espapem",
"espapes",
"espapou",
"esparse",
"esparve",
"esparvo",
"esparzo",
"espasme",
"espauto",
"especei",
"especem",
"especte",
"espedem",
"espedes",
"espedia",
"espedis",
"espelde",
"esperge",
"espicés",
"espieis",
"espiemo",
"espifai",
"espifam",
"espifar",
"espifas",
"espifei",
"espifem",
"espifes",
"espifou",
"espigai",
"espigue",
"espilos",
"espilre",
"espince",
"espinço",
"espipai",
"espipam",
"espipas",
"espipei",
"espipem",
"espipes",
"esplins",
"espoada",
"espoais",
"espoamo",
"espoava",
"espocai",
"espoeis",
"espoemo",
"espojai",
"espojem",
"espolas",
"espolpe",
"espolpo",
"espolpá",
"esporoe",
"esporoo",
"esporoá",
"esposai",
"esposes",
"espotai",
"espotam",
"espotas",
"espotei",
"espotem",
"espotou",
"espouco",
"esprues",
"espréns",
"espuais",
"espuirá",
"espulga",
"espulha",
"espumai",
"espumes",
"esputai",
"esputam",
"esputas",
"esputei",
"esputem",
"esputes",
"espuíam",
"espuías",
"espuído",
"espálax",
"espínea",
"esquefe",
"esquiai",
"esquifa",
"essedão",
"essivos",
"essolva",
"essolve",
"essolvi",
"essolvo",
"estacei",
"estacem",
"estacoe",
"estacoo",
"estacoá",
"estadeá",
"estadie",
"estafai",
"estaiai",
"estaiei",
"estaiem",
"estaies",
"estapes",
"estazai",
"estazam",
"estazas",
"estazem",
"estazes",
"esteais",
"estebal",
"estebas",
"esteeis",
"estefos",
"esteiem",
"esteies",
"esteire",
"estesie",
"estevai",
"estevou",
"estiais",
"estiamo",
"esticta",
"esticte",
"estieis",
"estiela",
"estiemo",
"estifas",
"estiflo",
"estigme",
"estigno",
"estilai",
"estilem",
"estiles",
"estilhe",
"estinhe",
"estirai",
"estires",
"estocei",
"estocem",
"estoces",
"estofai",
"estofem",
"estojai",
"estojam",
"estojei",
"estojem",
"estojou",
"estolai",
"estonai",
"estonam",
"estonas",
"estonem",
"estonha",
"estonhe",
"estonho",
"estonto",
"estopai",
"estopam",
"estorci",
"estormo",
"estouve",
"estovai",
"estovam",
"estovas",
"estovei",
"estovem",
"estoves",
"estovou",
"estrabe",
"estramo",
"estrave",
"estreci",
"estresi",
"estriai",
"estrice",
"estriem",
"estrigi",
"estrijo",
"estrimá",
"estrina",
"estriço",
"estroce",
"estroem",
"estrope",
"estroça",
"estruam",
"estruas",
"estruem",
"estrupi",
"estróis",
"estuamo",
"estuará",
"estucai",
"estucam",
"estueis",
"estuemo",
"estufai",
"estufes",
"estugas",
"estugue",
"estumai",
"estumas",
"estumem",
"estumes",
"esturno",
"esturão",
"estânda",
"estélis",
"estévis",
"estômis",
"esvaeci",
"esvalie",
"esvalio",
"esvaliá",
"esvarai",
"esvaram",
"esvaras",
"esvarei",
"esvarem",
"esvares",
"esvarra",
"esvarre",
"esvarri",
"esvarro",
"esvazai",
"esvazam",
"esvazas",
"esvazem",
"esvazes",
"esventa",
"esvente",
"esverce",
"esverço",
"esverçá",
"esvidai",
"esvidam",
"esvidas",
"esvidei",
"esvidem",
"esvides",
"esvidou",
"esvinhe",
"esvinho",
"esvinhá",
"esvoeje",
"esvoejo",
"esvurme",
"esvurmo",
"esócida",
"esófaga",
"etalzão",
"etambos",
"etanais",
"etandas",
"etenilo",
"etenóis",
"eterilo",
"eterina",
"eterize",
"eterizo",
"eternei",
"eternes",
"eternou",
"eterona",
"eteróis",
"eteídea",
"eteídeo",
"eticiza",
"eticize",
"eticizo",
"etiessa",
"etilais",
"etilamo",
"etilará",
"etilava",
"etileis",
"etilemo",
"etilina",
"etilizo",
"etomias",
"etonans",
"etopeus",
"etoxilo",
"etoxina",
"etânico",
"etânios",
"etãozão",
"etãzona",
"etílios",
"etóxida",
"euanemo",
"euarcos",
"euarcto",
"euascal",
"euastro",
"eubazos",
"eubeias",
"eubenas",
"eublema",
"euboica",
"eubolia",
"eubrias",
"eucatas",
"euclena",
"eucnema",
"eucodal",
"eucoíla",
"eucrite",
"eucroma",
"eucópia",
"eudemas",
"eudipsa",
"eudrilo",
"euedros",
"euemias",
"euexias",
"eufilia",
"eufonos",
"eufânia",
"eugiras",
"eulemas",
"eulemos",
"eulepto",
"eulimas",
"eulimos",
"eulitas",
"eulépia",
"eulínea",
"eulóbio",
"eulófio",
"eulóxia",
"eumeque",
"eumicro",
"eumidas",
"eumorfo",
"eunecte",
"eunecto",
"euneias",
"eunócia",
"eupelte",
"eupiona",
"eupista",
"euplero",
"euplote",
"eupódio",
"euquelo",
"euquira",
"euquita",
"euremas",
"euresol",
"euretes",
"euriais",
"euriope",
"euriúsa",
"eurodol",
"eusemia",
"eusiros",
"eussomo",
"eutexia",
"eutéria",
"euvites",
"euxária",
"euxário",
"euxênia",
"euxônia",
"euzimas",
"euzimos",
"euófris",
"evacuai",
"evacues",
"evadais",
"evademo",
"evadias",
"evadimo",
"evadnos",
"evadona",
"evadora",
"evadões",
"evaente",
"evagino",
"evalves",
"evalvos",
"evalzão",
"evardes",
"evaremo",
"evarmos",
"evasona",
"evassem",
"evasses",
"evastes",
"evazada",
"evazado",
"evazais",
"evazamo",
"evazará",
"evazava",
"evazeis",
"evazemo",
"eveente",
"evemias",
"evencei",
"evencem",
"evences",
"evenino",
"evençam",
"evenças",
"evexias",
"eveítas",
"evictas",
"evipino",
"evirado",
"evirais",
"eviramo",
"evirará",
"evirava",
"evireis",
"eviremo",
"evodina",
"evolais",
"evolamo",
"evoleis",
"evolemo",
"evolvas",
"evolvei",
"eváctis",
"eváreis",
"eváveis",
"evânias",
"evérnia",
"evêmica",
"evêmico",
"evíctio",
"evímbis",
"exabela",
"exacina",
"exagita",
"exagite",
"exagito",
"exalces",
"exalemo",
"exalçai",
"exanias",
"exarais",
"exaramo",
"exareis",
"exaremo",
"exarmas",
"exauras",
"exaures",
"exauris",
"exaçona",
"excedei",
"excelam",
"excelas",
"excelei",
"exceliu",
"excelse",
"excilam",
"excilas",
"excisai",
"excisas",
"excisei",
"excisem",
"excorco",
"exculpo",
"excurse",
"excurve",
"excurvo",
"excurvá",
"excusai",
"execrai",
"execras",
"execres",
"exempte",
"exerase",
"exerdai",
"exerdam",
"exerdas",
"exerdei",
"exerdes",
"exerdou",
"exgrego",
"exgregá",
"exibais",
"exibamo",
"exibemo",
"exiciar",
"exifona",
"exijamo",
"exilais",
"exilemo",
"exilzão",
"eximemo",
"exinana",
"exinane",
"exinani",
"exinano",
"exingam",
"exingas",
"exingua",
"exingui",
"exinguo",
"exitais",
"exitamo",
"exitemo",
"exoasco",
"exocade",
"exogira",
"exogiro",
"exorais",
"exoramo",
"exorcas",
"exordie",
"exoreis",
"exoremo",
"exornai",
"exornas",
"exornei",
"exornem",
"exornes",
"exotipo",
"exouvem",
"exouves",
"exouvia",
"exouvir",
"exouvis",
"exouviu",
"exouçam",
"exouças",
"expedis",
"expedra",
"expedre",
"expelas",
"expetai",
"expetam",
"expetas",
"expetei",
"expetem",
"expetou",
"expiamo",
"expieis",
"expiemo",
"expilai",
"expilas",
"expilei",
"expiles",
"expiros",
"expluam",
"expluas",
"expluem",
"expluis",
"expluiu",
"expolem",
"expolir",
"expolis",
"expoliu",
"exprobe",
"expulas",
"expunja",
"expunjo",
"exsicai",
"exsicam",
"exsicas",
"exsique",
"exsolva",
"exsolvi",
"exsolvo",
"exstada",
"exstais",
"exstamo",
"exsteis",
"exstemo",
"exsuada",
"exsuado",
"exsuais",
"exsuamo",
"exsuará",
"exsuava",
"exsudai",
"exsudas",
"exsudei",
"exsudes",
"exsueis",
"exsuemo",
"exsurjo",
"extrapô",
"extrica",
"extrico",
"extubai",
"extubam",
"extubas",
"extubem",
"extubes",
"exturca",
"exturco",
"exubaté",
"exulada",
"exulais",
"exulamo",
"exulara",
"exulava",
"exuleis",
"exulemo",
"exumais",
"exumamo",
"exumeis",
"exumemo",
"exundai",
"exundam",
"exundar",
"exundas",
"exundei",
"exundem",
"exundes",
"exundou",
"exápate",
"exáscea",
"exásceo",
"exícios",
"exídias",
"exílias",
"exítica",
"exítico",
"exócomo",
"exódios",
"exófaga",
"exógono",
"exólobo",
"exômata",
"exômeno",
"exômide",
"exúrbia",
"exúrbio",
"exúvios",
"eznitas",
"ezrinas",
"ezteris",
"eãozões",
"fabista",
"fabulai",
"fabulem",
"fabules",
"facaias",
"facaios",
"facanés",
"facecie",
"facecio",
"faceiem",
"faceire",
"facejai",
"facejam",
"facejas",
"facejei",
"facejem",
"facejes",
"facejou",
"facetai",
"facetam",
"facetei",
"facetem",
"faceteá",
"fachais",
"fachamo",
"fachará",
"fachava",
"facheai",
"facheei",
"facheia",
"facheie",
"facheio",
"facheis",
"fachemo",
"fachoco",
"fachole",
"facholo",
"faciona",
"faciono",
"faciosa",
"facotes",
"factora",
"factoro",
"facçona",
"facélia",
"facídio",
"fadador",
"fadadão",
"fadante",
"fadarei",
"fadarmo",
"fadarás",
"fadarão",
"fadaste",
"fadavam",
"fadavas",
"fadejai",
"fadejam",
"fadejas",
"fadejei",
"fadejem",
"fadejes",
"fadejou",
"fadigai",
"fadiste",
"fadisto",
"fadiços",
"fadonas",
"fadógia",
"faengas",
"faeísmo",
"fagadas",
"fagador",
"fagados",
"fagadão",
"fagante",
"fagaram",
"fagarei",
"fagarem",
"fagares",
"fagarão",
"fagasse",
"fagaste",
"fagavam",
"fagavas",
"fagueis",
"fagáceo",
"fagônia",
"faiados",
"faiadão",
"faiarmo",
"faiarás",
"faiavas",
"failina",
"fainada",
"fainado",
"fainais",
"fainamo",
"fainará",
"faineis",
"fainemo",
"fairada",
"fairado",
"fairais",
"fairamo",
"fairará",
"fairava",
"faireis",
"fairemo",
"faiscai",
"faixamo",
"faixará",
"faixava",
"faixeai",
"faixeei",
"faixeia",
"faixeie",
"faixeio",
"faixeis",
"faixemo",
"faixeou",
"fajanas",
"fajecas",
"fajocos",
"fajutai",
"fajutei",
"fajutes",
"falacas",
"falacei",
"falacie",
"faladai",
"faladam",
"faladar",
"faladei",
"faladem",
"faladou",
"faladre",
"faladro",
"falaneu",
"falazai",
"falazam",
"falazas",
"falazei",
"falazem",
"falazou",
"falaçai",
"falaçam",
"falaçar",
"falaços",
"falaçou",
"falcace",
"falcais",
"falcamo",
"falcara",
"falcava",
"falcaço",
"falcoai",
"falcoam",
"falcoei",
"falcoem",
"falcoou",
"falejai",
"falejam",
"falejar",
"falejas",
"falejei",
"falejem",
"falejes",
"falejou",
"faleças",
"falgais",
"falgamo",
"falguei",
"falguem",
"falguer",
"falgues",
"falgueu",
"falguia",
"falhamo",
"falhemo",
"falhepa",
"falhoca",
"falhoco",
"falhopa",
"falhosa",
"falhuda",
"falhões",
"falidor",
"falidão",
"falijas",
"falinas",
"faliras",
"falirei",
"falirmo",
"falista",
"faliste",
"falites",
"falocai",
"falocam",
"falocas",
"falocou",
"falopes",
"falquei",
"falquem",
"falqueá",
"falquia",
"falsais",
"falsamo",
"falsará",
"falseai",
"falseis",
"falsemo",
"falsias",
"faltamo",
"faltemo",
"falucai",
"falucam",
"falucar",
"falucou",
"faludas",
"faludos",
"falupas",
"faluque",
"faluzes",
"faláceo",
"falépia",
"falério",
"falíeis",
"falínea",
"falúsia",
"famanãs",
"famegos",
"famulai",
"famulam",
"famulei",
"famulem",
"famules",
"famulou",
"famínea",
"famíneo",
"fanacos",
"fanador",
"fanadão",
"fanamos",
"fanante",
"fanarei",
"fanarmo",
"fanarás",
"fanarão",
"fanasse",
"fanavas",
"fanchos",
"fanecos",
"faneias",
"fanemos",
"fanfada",
"fanfado",
"fanfais",
"fanfamo",
"fanfava",
"fanfeis",
"fanfemo",
"fangane",
"fangina",
"fanicai",
"fanicam",
"fanicas",
"fanicha",
"faniche",
"fanicho",
"fanucai",
"fanucam",
"fanucar",
"fanucas",
"fanucho",
"fanucos",
"fanucou",
"fanuque",
"fanínea",
"faníneo",
"faqueai",
"faqueei",
"faqueia",
"faqueie",
"faqueio",
"faquete",
"faquins",
"faradão",
"fararem",
"farares",
"fararmo",
"fararás",
"fararão",
"faraste",
"faravas",
"farazoa",
"farbite",
"fardais",
"fardamo",
"fardemo",
"farejes",
"farelam",
"farelar",
"farelei",
"farelem",
"fareles",
"farelou",
"faretre",
"faretro",
"farinai",
"farinei",
"farinem",
"farinou",
"farofie",
"farofio",
"farolai",
"farolam",
"farolei",
"farolem",
"farolou",
"farotas",
"farpais",
"farpamo",
"farpará",
"farpeai",
"farpeei",
"farpeie",
"farpeio",
"farpeis",
"farpelo",
"farpemo",
"farpoai",
"farpoar",
"farpoas",
"farpoei",
"farpoem",
"farpoou",
"farrais",
"farrajo",
"farramo",
"farreai",
"farreie",
"farrejo",
"farrela",
"farremo",
"farrepa",
"farripo",
"farrobe",
"farrões",
"farsole",
"farsuda",
"fartece",
"farteci",
"farteça",
"farteço",
"fartões",
"faruaru",
"farulas",
"farungo",
"farungá",
"farâmea",
"faríaca",
"faríaco",
"fascais",
"fascaço",
"fascoto",
"faseais",
"faseará",
"faseava",
"faseeis",
"faseiam",
"faseiem",
"faseies",
"fasiane",
"fasiano",
"fasquie",
"fastais",
"fastamo",
"fasteis",
"fastemo",
"fasudil",
"fasudis",
"fasíaca",
"fasíaco",
"fasínea",
"fasíneo",
"fatageá",
"fatanai",
"fatanam",
"fatanas",
"fatanei",
"fatanem",
"fatanes",
"fatanou",
"fateias",
"fatejai",
"fatejam",
"fatejas",
"fatejei",
"fatejem",
"fatejes",
"fatejou",
"fatiais",
"fatiamo",
"fatiaça",
"fatieis",
"fatiemo",
"fatigai",
"fatolás",
"fatueje",
"fatuejo",
"fatuejá",
"faturai",
"fatário",
"fatícia",
"faucais",
"faulada",
"faulado",
"faulais",
"faulamo",
"faulará",
"faulava",
"fauleis",
"faulemo",
"faulhai",
"faulhei",
"faulhem",
"faulhes",
"faulhou",
"fauséis",
"favacos",
"favador",
"favadão",
"favamos",
"favando",
"favaram",
"favarei",
"favarem",
"favarmo",
"favarás",
"favasse",
"favaste",
"favavam",
"favavas",
"favecos",
"favemos",
"favento",
"favilas",
"faviona",
"favoneá",
"favonia",
"favonie",
"favosas",
"favulha",
"favéolo",
"faxeada",
"faxeado",
"faxeais",
"faxeará",
"faxeava",
"faxeeis",
"faxeiam",
"faxeias",
"faxeiem",
"faxeies",
"faxeque",
"faxetas",
"faxinem",
"faxines",
"fazidão",
"fazânio",
"façudas",
"façudos",
"faíscos",
"faúlham",
"faúlhos",
"fearrão",
"febeias",
"fedamos",
"fedanhe",
"fedanho",
"fedanhá",
"fededor",
"fedegas",
"fedelhe",
"federmo",
"fedeste",
"fedocas",
"fedonha",
"fedonhe",
"feduços",
"fedíeis",
"fefetai",
"fefetam",
"fefetar",
"fefetas",
"fefetei",
"fefetem",
"fefetou",
"feiezas",
"feiista",
"feiramo",
"feirava",
"feireis",
"feiremo",
"feirios",
"feitamo",
"feitará",
"feitava",
"feiteis",
"feitemo",
"feitiai",
"feitiam",
"feitias",
"feitiei",
"feitiem",
"feities",
"feitiou",
"feitore",
"feitoro",
"feiténs",
"feitões",
"feiudas",
"feiudos",
"feiulas",
"feiçona",
"feladão",
"felames",
"felanim",
"felante",
"felaram",
"felaras",
"felarem",
"felares",
"felaria",
"felarmo",
"felarão",
"felasse",
"felaste",
"felavam",
"felavas",
"felecas",
"felemes",
"feleuma",
"felguda",
"felocas",
"felocha",
"felonie",
"felosos",
"felpais",
"felpamo",
"felpará",
"felpava",
"felpeis",
"felpemo",
"felposa",
"feltrai",
"feltram",
"feltrem",
"felzões",
"fembada",
"fembado",
"fembais",
"fembamo",
"fembará",
"fembava",
"fembeis",
"fembemo",
"femeada",
"femeará",
"femeava",
"femeeis",
"femeiam",
"femeiem",
"femeies",
"femeira",
"femeiro",
"femence",
"femenço",
"femenês",
"fenaces",
"fenador",
"fenadão",
"fenamol",
"fenamos",
"fenante",
"fenaram",
"fenarei",
"fenarem",
"fenaria",
"fenarmo",
"fenarás",
"fenarão",
"fenasse",
"fenaste",
"fenatos",
"fenavas",
"fenaíte",
"fendamo",
"fendemo",
"feneata",
"fenecei",
"feneces",
"feneira",
"fenelas",
"fenemos",
"feneças",
"fenféns",
"fengita",
"fengite",
"feninha",
"fenobam",
"fenonas",
"fentais",
"fenusas",
"fenícea",
"feraces",
"fereias",
"feresas",
"feriamo",
"feribás",
"feriemo",
"fernéis",
"ferreja",
"ferreje",
"ferrina",
"ferrium",
"ferroai",
"ferroas",
"ferrobe",
"ferroei",
"fertora",
"ferusas",
"fervais",
"fervamo",
"ferveis",
"fervemo",
"fervias",
"ferécia",
"feríeis",
"ferúcua",
"fesapos",
"fescoço",
"festamo",
"festemo",
"festoai",
"festoam",
"festoas",
"festoei",
"festoem",
"festono",
"festora",
"festoso",
"fetamol",
"fetucas",
"fetusas",
"fetácea",
"fetídia",
"fevelho",
"feverão",
"fevrosa",
"fevílea",
"feãozão",
"feísmos",
"feócroa",
"feódios",
"feórnis",
"fiacuxo",
"fiadona",
"fiampai",
"fiampam",
"fiampar",
"fiampas",
"fiampei",
"fiampem",
"fiampes",
"fiampou",
"fiampua",
"fiandão",
"fiangos",
"fianhos",
"fianços",
"fiapica",
"fiardes",
"fiareis",
"fiaremo",
"fiarias",
"fiastes",
"fiaçona",
"fibrana",
"fibrela",
"fibrile",
"ficadão",
"ficatos",
"fichais",
"fichamo",
"ficheis",
"fichemo",
"fichoas",
"ficites",
"ficores",
"ficoíta",
"ficoíte",
"ficçona",
"ficídea",
"ficídeo",
"ficínea",
"ficíneo",
"fidônia",
"fieiros",
"figador",
"figamos",
"figança",
"figaram",
"figarei",
"figaria",
"figarás",
"figarão",
"figasse",
"figaste",
"figavam",
"figavas",
"figites",
"figotes",
"figueis",
"fijolas",
"filaceu",
"filadão",
"filagos",
"filames",
"filança",
"filarmo",
"filavas",
"filecos",
"fileles",
"filetai",
"filetem",
"fileteá",
"filharo",
"filhava",
"filhemo",
"filidro",
"filieis",
"filiemo",
"filines",
"filipai",
"filipam",
"filipar",
"filipei",
"filmais",
"filmeis",
"filmemo",
"filoela",
"filomas",
"filomia",
"filonto",
"filtrai",
"filuros",
"filáfis",
"filális",
"filícea",
"filídia",
"filóbea",
"filóbio",
"filódia",
"fimacte",
"fimanto",
"fimbrie",
"fimento",
"finanga",
"finante",
"finares",
"finarmo",
"finarás",
"finasco",
"finavas",
"fincamo",
"fincuda",
"findais",
"findemo",
"finemos",
"finfada",
"finfado",
"finfais",
"finfamo",
"finfará",
"finfava",
"finfeis",
"finfemo",
"finfins",
"fininga",
"finjamo",
"finotos",
"fintais",
"fintamo",
"finteis",
"fintemo",
"fintões",
"finável",
"fioméis",
"fiotice",
"firacos",
"firmões",
"firrada",
"firrado",
"firrais",
"firramo",
"firrará",
"firrava",
"firreis",
"firremo",
"fiscele",
"fiscelo",
"fisemas",
"fisgais",
"fisgamo",
"fisiose",
"fisoses",
"fissona",
"fistora",
"fistras",
"fistule",
"fistulo",
"fisácio",
"fisídea",
"fisídeo",
"fisídia",
"fitador",
"fitadão",
"fitante",
"fitarmo",
"fitaças",
"fitelho",
"fitonas",
"fitopto",
"fitoses",
"fitucai",
"fitucam",
"fitucar",
"fitucas",
"fitucou",
"fituque",
"fitácea",
"fitécia",
"fitóbio",
"fiuncos",
"fiunças",
"fixadão",
"fixezes",
"fixuras",
"fiônida",
"flaflai",
"flaflam",
"flaflar",
"flaflas",
"flaflei",
"flaflem",
"flafles",
"flaflou",
"flagrai",
"flagres",
"flainai",
"flainam",
"flainas",
"flainei",
"flainem",
"flaines",
"flaineá",
"flainos",
"flainou",
"flamada",
"flamais",
"flamamo",
"flamava",
"flambai",
"flambeá",
"flameai",
"flameei",
"flameie",
"flameio",
"flameis",
"flamemo",
"flameou",
"flamule",
"flanais",
"flanamo",
"flanate",
"flaneis",
"flanemo",
"flartai",
"flartam",
"flartas",
"flartei",
"flartem",
"flartes",
"flartou",
"flatosa",
"flautai",
"flautei",
"flautem",
"flautes",
"flauteá",
"flebina",
"flechai",
"flectam",
"flectas",
"fleimas",
"flertai",
"fletias",
"flexona",
"flexuai",
"flexuam",
"flexuas",
"flexuei",
"flexuem",
"flexues",
"flexuou",
"fleínas",
"fleóbio",
"fleômea",
"fleômeo",
"flieias",
"flimais",
"flimamo",
"flimará",
"flimava",
"flimeis",
"flimemo",
"flipais",
"flipamo",
"flipeis",
"flipemo",
"flirtai",
"flirtas",
"flirtem",
"flirtes",
"flitada",
"flitado",
"flitais",
"flitamo",
"flitará",
"fliteis",
"flitemo",
"fliásio",
"flocais",
"flocamo",
"flocará",
"flocava",
"flochas",
"flocone",
"flocono",
"floconá",
"flogoso",
"flogosá",
"floquem",
"floques",
"floreai",
"floreje",
"florejo",
"floremo",
"florose",
"flotais",
"flotamo",
"flotava",
"floteis",
"flotemo",
"fluatos",
"fluidai",
"fluidam",
"fluidar",
"fluidei",
"fluidem",
"fluidou",
"fluidão",
"fluirmo",
"fluirás",
"fluorai",
"fluoram",
"fluoras",
"fluorei",
"fluorem",
"fluorou",
"flutuai",
"fluíeis",
"flébica",
"fléolos",
"fléomis",
"flômide",
"flúgeas",
"flútuos",
"fobadas",
"fobador",
"fobadão",
"fobamos",
"fobante",
"fobaram",
"fobaras",
"fobarei",
"fobarem",
"fobares",
"fobaria",
"fobarmo",
"fobarão",
"fobasse",
"fobaste",
"fobavam",
"fobavas",
"fobemos",
"fobitai",
"fobitam",
"fobitas",
"fobitei",
"fobitem",
"fobites",
"fobitou",
"fobocas",
"focavas",
"focação",
"focenas",
"focinhe",
"focácea",
"focídea",
"focínea",
"focíneo",
"fodermo",
"fodipir",
"fodíeis",
"foeiras",
"fofadas",
"fofador",
"fofados",
"fofadão",
"fofamos",
"fofanas",
"fofante",
"fofarei",
"fofarem",
"fofares",
"fofaria",
"fofarmo",
"fofarás",
"fofarão",
"fofasse",
"fofaste",
"fofavam",
"fofavas",
"fofemos",
"fofocai",
"fofolis",
"fogachá",
"fogueai",
"fogueei",
"fogueio",
"foguéus",
"fogácea",
"foiceai",
"foiceei",
"foiceia",
"foiceie",
"foiceio",
"foiceis",
"foicemo",
"foiceou",
"foiçais",
"foiçamo",
"foiçará",
"foiçona",
"foições",
"folcoma",
"foleais",
"foleará",
"folecai",
"folecam",
"folecos",
"folecou",
"foleeis",
"folegai",
"folegam",
"folegas",
"folegou",
"folegue",
"foleias",
"foleiem",
"foleies",
"folepos",
"foleque",
"folerca",
"folerpa",
"folerpe",
"folerpo",
"folgamo",
"folgaze",
"folgazo",
"folhamo",
"folhará",
"folheis",
"folhemo",
"foliamo",
"foliara",
"folieis",
"foliemo",
"foliota",
"folipas",
"folipos",
"folosas",
"folídia",
"fomopse",
"fomutim",
"fonecas",
"fonfone",
"fonfono",
"fonices",
"fonisca",
"fonismo",
"fonolas",
"fonçona",
"foqueai",
"foquear",
"foqueei",
"foqueia",
"foqueie",
"foqueou",
"foragis",
"forajam",
"forajas",
"foratas",
"forbeia",
"forguis",
"forjemo",
"forjico",
"forleca",
"formena",
"fornamo",
"forneai",
"forneei",
"forneia",
"forneje",
"fornejo",
"forneou",
"fornigo",
"fornigá",
"fornimo",
"fornizo",
"forodão",
"foroias",
"foronas",
"forquei",
"forquem",
"forqueá",
"forrais",
"forrajo",
"forrajá",
"forrear",
"forreis",
"forreja",
"forreje",
"forrejo",
"forsina",
"fortaçá",
"forécio",
"foródon",
"foscada",
"foscais",
"foscamo",
"foscava",
"fosfais",
"fosfore",
"fosquei",
"fosquem",
"fossamo",
"fossará",
"fossona",
"fossões",
"fotados",
"foteada",
"foteais",
"foteará",
"foteava",
"foteeis",
"foteiam",
"foteias",
"foteiem",
"foteies",
"fotenos",
"foteíta",
"foteíte",
"fotista",
"fotínea",
"fotíneo",
"fouceai",
"fouceei",
"fouceia",
"fouceie",
"fouceio",
"fouceis",
"foucemo",
"fouceou",
"fouçado",
"fouçais",
"fouçamo",
"fouçará",
"fouçava",
"fouçona",
"fouções",
"fovelho",
"fovilas",
"fovocos",
"foxanto",
"foxídea",
"foxídeo",
"foísmos",
"foístas",
"fracada",
"fracais",
"fracamo",
"fracava",
"fracura",
"fradada",
"fradais",
"fradamo",
"fradará",
"fradava",
"fradeis",
"fradeja",
"fradeje",
"fradejo",
"frademo",
"fragadô",
"fragaia",
"fragoar",
"fragora",
"fragore",
"fraguai",
"fragueá",
"fraguou",
"frainel",
"fraires",
"fraldai",
"fraldam",
"fraldei",
"fraldem",
"fraldeá",
"frandis",
"frangei",
"frangeu",
"frangia",
"franjai",
"franjei",
"franjem",
"franjeá",
"fraquei",
"fraquem",
"fraqueá",
"fraseai",
"fraseei",
"frataxo",
"fraudai",
"frautai",
"frautam",
"frautei",
"frautem",
"frauteá",
"frautou",
"frearas",
"freares",
"frearmo",
"freaste",
"freavas",
"frechai",
"frechei",
"freeira",
"freemos",
"fregais",
"fregará",
"freguem",
"fregões",
"freiana",
"freiela",
"freimes",
"freirai",
"freirem",
"freirou",
"frejuco",
"frejucá",
"fremamo",
"fremeis",
"frememo",
"fremerá",
"fremias",
"fremimo",
"frendam",
"frendei",
"frendem",
"frendes",
"frendeu",
"freneis",
"frenemo",
"frenias",
"fresais",
"fresamo",
"fresara",
"frescai",
"freseis",
"fresemo",
"fretais",
"freteje",
"fretejo",
"fretemo",
"fretena",
"freteni",
"frevais",
"frevamo",
"frevará",
"freveis",
"frevemo",
"friames",
"friamos",
"frifrió",
"frigimo",
"frijais",
"frijamo",
"frimais",
"frimões",
"frinche",
"frincho",
"frinela",
"frinina",
"friníea",
"friosas",
"frisamo",
"friseia",
"friseis",
"frisemo",
"friseus",
"fritais",
"friteis",
"fritemo",
"frixeia",
"frixeus",
"friéria",
"friúras",
"frocada",
"frocais",
"frocamo",
"frocara",
"frocava",
"frogodô",
"froinas",
"froixai",
"froixam",
"froixas",
"froixei",
"froixem",
"froixes",
"froixou",
"frolada",
"frolado",
"frolais",
"frolamo",
"frolará",
"froleis",
"frolemo",
"frolhos",
"froncis",
"frondai",
"frondam",
"frondei",
"frondem",
"fronteá",
"froquei",
"froquem",
"froques",
"froscas",
"froumas",
"frouxai",
"frouxes",
"frouças",
"fruidão",
"fruirmo",
"frulhai",
"frulham",
"frulhas",
"frulhei",
"frulhem",
"frulhes",
"frulhou",
"fruncos",
"frunhir",
"frustai",
"frusteá",
"frutamo",
"frutará",
"fruteai",
"frutedo",
"fruteei",
"frutego",
"frutegá",
"fruteie",
"fruteio",
"fruteis",
"frutemo",
"frutina",
"frutual",
"frutões",
"fruzuês",
"fruíeis",
"frígana",
"frígica",
"frígico",
"frínida",
"frínios",
"frísica",
"frôndea",
"frônias",
"frônima",
"frônimo",
"frútulo",
"fuazada",
"fuazado",
"fubamba",
"fubanas",
"fubecai",
"fubecam",
"fubecou",
"fubeira",
"fubeiro",
"fubocas",
"fucanis",
"fucemos",
"fucinas",
"fucoses",
"fucsite",
"fucussa",
"fueirai",
"fueiram",
"fueirar",
"fueiras",
"fueirei",
"fueirem",
"fueires",
"fueirou",
"fugadão",
"fuganga",
"fugança",
"fugaras",
"fugaria",
"fugarão",
"fugasse",
"fugavas",
"fugecas",
"fugemia",
"fugidão",
"fugirmo",
"fugiãos",
"fugueis",
"fugíeis",
"fujaria",
"fujicai",
"fujicam",
"fujicas",
"fujicou",
"fujique",
"fujiras",
"fujirei",
"fujires",
"fujirmo",
"fujirão",
"fujista",
"fujiste",
"fujíeis",
"fulames",
"fulcito",
"fulecai",
"fulecha",
"fulejos",
"fuleque",
"fulgimo",
"fuliano",
"fuligos",
"fulmega",
"fuloada",
"fuloado",
"fuloais",
"fuloamo",
"fuloará",
"fuloava",
"fuloeis",
"fuloemo",
"fulosas",
"fulosos",
"fulupos",
"fumacem",
"fumaceá",
"fumagos",
"fumarai",
"fumaçai",
"fumbeca",
"fumeada",
"fumeado",
"fumeais",
"fumeava",
"fumeeis",
"fumegai",
"fumeiam",
"fumeias",
"fumeiem",
"fumeies",
"fumigai",
"fuminés",
"fumonas",
"fumélio",
"funados",
"funadão",
"funamos",
"funando",
"funaram",
"funarei",
"funarem",
"funares",
"funarmo",
"funarás",
"funarão",
"funasse",
"funaste",
"funavam",
"funavas",
"fundeai",
"fundula",
"funecas",
"funemos",
"funfais",
"funfamo",
"funfane",
"funfeis",
"funfemo",
"funfiai",
"funfiam",
"funfiar",
"funfias",
"funfiei",
"funfiem",
"funfies",
"funfiou",
"fungamo",
"fungita",
"fungite",
"funhais",
"funhamo",
"funhiam",
"funhias",
"funhida",
"funhido",
"funhimo",
"funhirá",
"furacas",
"furacou",
"furamar",
"furanas",
"furaque",
"furarmo",
"furavas",
"furazol",
"furdões",
"furiana",
"furicas",
"furilos",
"furinal",
"furisca",
"furisco",
"furjoco",
"furoada",
"furoais",
"furoamo",
"furoará",
"furoava",
"furoeis",
"furoemo",
"furonos",
"furoína",
"furriai",
"furriam",
"furriar",
"furrias",
"furriem",
"furriou",
"furtemo",
"furtico",
"furticá",
"fusador",
"fusadão",
"fusamos",
"fusanas",
"fusanos",
"fusante",
"fusaram",
"fusarei",
"fusarem",
"fusares",
"fusarmo",
"fusarás",
"fusarão",
"fusasse",
"fusaste",
"fusavam",
"fusavas",
"fuscais",
"fuscamo",
"fuscará",
"fuscava",
"fuscita",
"fuscito",
"fuselar",
"fusemos",
"fusgada",
"fusgado",
"fusgais",
"fusgara",
"fusgava",
"fusguei",
"fusguem",
"fusgues",
"fusiada",
"fusiado",
"fusilos",
"fusitas",
"fusitos",
"fusmila",
"fusonas",
"fusquei",
"fustéis",
"fusária",
"fusíola",
"fusória",
"futecos",
"futicai",
"futicam",
"futicas",
"futilas",
"futinho",
"futisso",
"futrete",
"futreto",
"futretá",
"futsais",
"futucai",
"futurai",
"futurei",
"futurem",
"fuxicai",
"fuxicas",
"fuãozão",
"fuçadão",
"fuçarmo",
"fuçaste",
"fuçavas",
"fáleris",
"fálgaro",
"fárbios",
"fárreas",
"fárreos",
"fásidos",
"fásmida",
"fátsias",
"fáureas",
"fávicas",
"fâneras",
"fãzonas",
"fêmicas",
"fênicas",
"fícteis",
"fículas",
"fígulos",
"fílicas",
"fílulas",
"fímatas",
"fímicas",
"fímicos",
"fípsias",
"físalos",
"físaros",
"físedra",
"fística",
"fíticas",
"fócidas",
"fóladas",
"fólades",
"fólcida",
"fósmeas",
"fósmeos",
"fóticon",
"fóxidas",
"fõezões",
"fúcaros",
"fúgueas",
"fúgueos",
"fúlmens",
"fúlmica",
"fúlmico",
"fúmidas",
"fúmidos",
"fúneras",
"fúngias",
"fúngida",
"fúngulo",
"fúnquia",
"fúrcica",
"fúrcico",
"fúsaros",
"gaaíras",
"gabadão",
"gabaias",
"gabante",
"gabarus",
"gabaços",
"gabedos",
"gabenos",
"gabiada",
"gabiado",
"gabiamo",
"gabiará",
"gabiava",
"gabieis",
"gabiemo",
"gabinos",
"gabiona",
"gabiono",
"gabonas",
"gabotes",
"gabutas",
"gadacha",
"gadacho",
"gadagem",
"gadamos",
"gadaras",
"gadares",
"gadarus",
"gadense",
"gadezas",
"gadibes",
"gadicei",
"gadicem",
"gadices",
"gadilas",
"gadinas",
"gadiçai",
"gadiçam",
"gadiçar",
"gadiças",
"gadiços",
"gadiçou",
"gadoide",
"gadunhe",
"gadínea",
"gadíneo",
"gadínia",
"gaelzão",
"gafador",
"gafadão",
"gafamos",
"gafanas",
"gafando",
"gafante",
"gafaram",
"gafaras",
"gafarei",
"gafarem",
"gafares",
"gafarmo",
"gafarão",
"gafasse",
"gafaste",
"gafavam",
"gafavas",
"gafeire",
"gafejai",
"gafejam",
"gafejas",
"gafejei",
"gafejem",
"gafejes",
"gafejou",
"gafemos",
"gafices",
"gaganho",
"gagatas",
"gagaíto",
"gagaúba",
"gageias",
"gageíta",
"gaginos",
"gagosas",
"gagueai",
"gagueei",
"gagueie",
"gagueou",
"gaiabéu",
"gaiadas",
"gaiador",
"gaiadão",
"gaiamos",
"gaiando",
"gaiaram",
"gaiarei",
"gaiarem",
"gaiaria",
"gaiarmo",
"gaiarás",
"gaiarão",
"gaiasse",
"gaiaste",
"gaiatai",
"gaiatam",
"gaiatei",
"gaiatem",
"gaiates",
"gaiatou",
"gaiavam",
"gaiavas",
"gaibins",
"gaidato",
"gaiemos",
"gaifone",
"gaifono",
"gaiinho",
"gailada",
"gaimilo",
"gaimira",
"gaimoai",
"gaimoam",
"gaimoar",
"gaimoas",
"gaimoei",
"gaimoem",
"gaimoou",
"gainato",
"gaiosas",
"gaiotos",
"gaipaba",
"gaitado",
"gaitais",
"gaitamo",
"gaitará",
"gaitava",
"gaiteai",
"gaiteei",
"gaiteia",
"gaiteie",
"gaiteio",
"gaiteis",
"gaitejo",
"gaitemo",
"gaiteou",
"gaitona",
"gaiulos",
"gaivada",
"gaivado",
"gaivais",
"gaivamo",
"gaivará",
"gaivava",
"gaivemo",
"gaivéus",
"gajadas",
"gajador",
"gajadão",
"gajamos",
"gajando",
"gajante",
"gajaram",
"gajarei",
"gajares",
"gajaria",
"gajarmo",
"gajarão",
"gajasse",
"gajaste",
"gajatas",
"gajatos",
"gajavam",
"gajavas",
"gajemos",
"gajerus",
"gajices",
"gajirus",
"gajolas",
"gajurus",
"galacés",
"galanai",
"galanam",
"galanei",
"galanem",
"galaneá",
"galangó",
"galanol",
"galanou",
"galarei",
"galares",
"galarmo",
"galarás",
"galarão",
"galaste",
"galaula",
"galavas",
"galeais",
"galecas",
"galeeis",
"galeiam",
"galeida",
"galeiem",
"galeies",
"galelas",
"galelos",
"galemos",
"galeode",
"galeoma",
"galeíta",
"galgais",
"galgues",
"galgões",
"galhena",
"galhofe",
"galicai",
"galicou",
"galimai",
"galimam",
"galimei",
"galimem",
"galimes",
"galimou",
"galioso",
"galipso",
"galipós",
"galique",
"galirés",
"galivai",
"galivam",
"galivas",
"galivei",
"galivem",
"galives",
"galivou",
"galizos",
"galizão",
"galoado",
"galoais",
"galoamo",
"galoará",
"galoava",
"galoeis",
"galoemo",
"galoles",
"galonai",
"galonam",
"galonei",
"galonem",
"galonou",
"galopai",
"galoílo",
"galrada",
"galrais",
"galramo",
"galrará",
"galrava",
"galreai",
"galreda",
"galreei",
"galreie",
"galreis",
"galreje",
"galrejo",
"galremo",
"galripa",
"galriço",
"galubés",
"galulas",
"galupas",
"galácea",
"galápio",
"galáxea",
"galégea",
"galésia",
"galênia",
"galólis",
"gamacha",
"gamador",
"gamaios",
"gamarei",
"gamarmo",
"gamasso",
"gamatão",
"gamavas",
"gambiai",
"gambiam",
"gambiei",
"gambiem",
"gambies",
"gambiou",
"gamedim",
"gamelos",
"gamemos",
"gamenhe",
"gameras",
"gamergu",
"gamotes",
"gamutas",
"gamutes",
"gamutos",
"gamóbio",
"gamútis",
"ganalos",
"ganapas",
"ganapés",
"ganceis",
"gancemo",
"ganchai",
"gancham",
"gancheá",
"gandaie",
"gandaru",
"gandeai",
"gandear",
"gandeei",
"gandeia",
"gandeie",
"gandeio",
"gandeou",
"ganduns",
"ganetes",
"ganfada",
"ganfado",
"ganfais",
"ganfamo",
"ganfana",
"ganfará",
"ganfava",
"ganfeis",
"ganfemo",
"gangris",
"gangueá",
"ganguji",
"gangões",
"ganhaço",
"ganhoal",
"ganhuço",
"ganicei",
"ganicem",
"ganices",
"ganidas",
"ganidor",
"ganidão",
"ganimos",
"ganiras",
"ganirei",
"ganiria",
"ganirmo",
"ganirra",
"ganirão",
"ganiste",
"ganizai",
"ganizas",
"ganizei",
"ganizem",
"ganiçai",
"ganiçam",
"ganiças",
"ganiços",
"ganiçou",
"ganjera",
"ganjeta",
"ganjões",
"ganletó",
"ganogas",
"ganrutó",
"gansões",
"ganzais",
"ganzamo",
"ganzará",
"ganzava",
"ganzeis",
"ganzemo",
"ganzulu",
"ganzuás",
"gançada",
"gançado",
"gançais",
"gançamo",
"gançará",
"gançava",
"ganéria",
"ganíeas",
"ganíeis",
"gapinai",
"gapinam",
"gapinas",
"gapinei",
"gapinem",
"gapines",
"gapinou",
"gapiras",
"gapuiai",
"gapuiam",
"gapuiei",
"gapuiem",
"gapuies",
"gapuiou",
"garabis",
"garabus",
"garacuí",
"garalhe",
"garceja",
"garcela",
"garcera",
"garcesa",
"garecer",
"garepes",
"gareras",
"garetes",
"garfais",
"garfamo",
"garfeai",
"garfeei",
"garfeie",
"garfeio",
"garfeis",
"garfeje",
"garfejo",
"garfejá",
"garfemo",
"garfiai",
"garfiam",
"garfiei",
"garfiem",
"garfies",
"garfila",
"garfiou",
"gargada",
"gargais",
"gargava",
"gargaús",
"garguem",
"gargues",
"garimbe",
"garinai",
"garinam",
"garinar",
"garinem",
"garines",
"garinou",
"garites",
"garjaus",
"garnada",
"garnais",
"garnamo",
"garnará",
"garneai",
"garneei",
"garneia",
"garneie",
"garneio",
"garneis",
"garnemo",
"garneou",
"garniam",
"garnias",
"garnida",
"garnido",
"garnimo",
"garnirá",
"garoais",
"garoamo",
"garoara",
"garoeis",
"garoemo",
"garomas",
"garonês",
"garopos",
"garotai",
"garotei",
"garotem",
"garoteá",
"garotir",
"garotis",
"garpoti",
"garrame",
"garreai",
"garreei",
"garreie",
"garreis",
"garrial",
"garriam",
"garrias",
"garrimo",
"garrira",
"garrule",
"garuada",
"garuado",
"garuais",
"garuamo",
"garuará",
"garuava",
"garubas",
"garueis",
"garuemo",
"garugas",
"garujas",
"garumno",
"garumás",
"garunas",
"garundo",
"garveia",
"garzeai",
"garzear",
"garzeei",
"garzeia",
"garzeie",
"garzeio",
"garzeou",
"garzina",
"garçoto",
"garípio",
"gasalhe",
"gaseais",
"gaseara",
"gaseeis",
"gaseias",
"gaseies",
"gasmada",
"gasmado",
"gasmais",
"gasmamo",
"gasmará",
"gasmava",
"gasmeis",
"gasmemo",
"gaspeai",
"gaspeei",
"gaspeie",
"gaspeio",
"gaspeou",
"gaspoia",
"gasólea",
"gatador",
"gatados",
"gatadão",
"gataguá",
"gatanha",
"gatanhe",
"gatares",
"gatarmo",
"gatarás",
"gatarão",
"gataste",
"gatavas",
"gateais",
"gateara",
"gateeis",
"gateiam",
"gateias",
"gateiem",
"gateies",
"gatemos",
"gatesgo",
"gatezas",
"gatunai",
"gatunam",
"gatunei",
"gatunem",
"gatunes",
"gatunhe",
"gaturai",
"gaturam",
"gaturas",
"gaturei",
"gaturem",
"gatures",
"gaturra",
"gaturre",
"gauchai",
"gauchei",
"gauchou",
"gaudais",
"gaudamo",
"gaudara",
"gaudava",
"gaudeis",
"gaudemo",
"gaudero",
"gauderá",
"gaudiai",
"gaudiam",
"gaudias",
"gaudiei",
"gaudiem",
"gaudine",
"gaudiou",
"gaugaus",
"gaulope",
"gaumine",
"gauncar",
"gaurata",
"gavadão",
"gavamos",
"gavante",
"gavarei",
"gavarem",
"gavares",
"gavarmo",
"gavarás",
"gavarão",
"gavaste",
"gavavam",
"gavavas",
"gaveada",
"gaveado",
"gaveais",
"gaveará",
"gaveava",
"gaveeis",
"gaveiam",
"gaveias",
"gaveiem",
"gaveies",
"gaveiro",
"gavejos",
"gavelos",
"gavetim",
"gavinas",
"gaviono",
"gavitas",
"gavitos",
"gazanas",
"gazatas",
"gazeais",
"gazeeis",
"gazeias",
"gazeiem",
"gazeies",
"gazenas",
"gazilai",
"gazilam",
"gazilas",
"gazilei",
"gazilem",
"gaziles",
"gazilhe",
"gazilho",
"gazilhá",
"gazilou",
"gazinai",
"gazinam",
"gazinas",
"gazinei",
"gazinem",
"gazines",
"gazinou",
"gazipos",
"gazitos",
"gazivas",
"gazolai",
"gazolam",
"gazolar",
"gazolei",
"gazolem",
"gazoles",
"gazolou",
"gazongo",
"gazopos",
"gazuada",
"gazuado",
"gazuais",
"gazuamo",
"gazuará",
"gazuava",
"gazueis",
"gazuemo",
"gazulai",
"gazulam",
"gazulei",
"gazulem",
"gazulou",
"gazupai",
"gazupam",
"gazupar",
"gazupas",
"gazupei",
"gazupem",
"gazupes",
"gazupou",
"gaçabas",
"gaçapos",
"gaúchem",
"geadona",
"geadora",
"geadões",
"geantes",
"geardes",
"geareis",
"gearemo",
"geariam",
"gearias",
"geassas",
"geassem",
"geasses",
"geastes",
"gebadeu",
"gebador",
"gebados",
"gebadão",
"gebamos",
"gebando",
"gebante",
"gebaram",
"gebarei",
"gebarem",
"gebares",
"gebaria",
"gebarmo",
"gebasse",
"gebaste",
"gebavam",
"gebavas",
"gebemos",
"gebices",
"gebosas",
"gebrada",
"gebrado",
"gebrais",
"gebramo",
"gebrara",
"gebrava",
"gebreis",
"gebremo",
"gecazum",
"gechira",
"gecóbia",
"gedebos",
"gedruso",
"geeiras",
"geeiros",
"geentes",
"geentos",
"gefiras",
"gefírea",
"gefíreo",
"geilolo",
"gelaras",
"gelarei",
"gelares",
"gelarmo",
"gelavas",
"gelemos",
"geleína",
"gelifaz",
"gelifez",
"gelifiz",
"gelinos",
"gelivas",
"gelonos",
"gelzões",
"gelídio",
"gelínea",
"gelíneo",
"gelível",
"gelônio",
"gemador",
"gemadão",
"gemaram",
"gemarei",
"gemarem",
"gemares",
"gemarmo",
"gemarão",
"gemasse",
"gemaste",
"gemavam",
"gemavas",
"gemeado",
"gemeais",
"gemeará",
"gemeava",
"gemeeis",
"gemeiam",
"gemeiem",
"gemeies",
"gemelgá",
"gemermo",
"gemiada",
"gemiado",
"gemiamo",
"gemiará",
"gemiava",
"gemicai",
"gemicam",
"gemicas",
"gemicou",
"gemiemo",
"gemique",
"gemulai",
"gemulam",
"gemulei",
"gemulem",
"gemules",
"gemulou",
"gemursa",
"gemíeis",
"gemítoa",
"genarca",
"genauno",
"genepis",
"genereá",
"genipas",
"genreai",
"genreei",
"genreie",
"genreio",
"genreou",
"genuate",
"genuesa",
"genócia",
"genótia",
"geococo",
"geodite",
"geofila",
"geofilo",
"geogalo",
"geoicas",
"geosita",
"geranas",
"gerarmo",
"gerbões",
"gerecei",
"gerecem",
"gereces",
"gereceu",
"geresãs",
"gerezas",
"gereçam",
"gereças",
"geribés",
"gerical",
"geridor",
"gerirmo",
"germões",
"geronas",
"gersais",
"geríeis",
"gesates",
"gesatos",
"gessamo",
"gessará",
"gessava",
"gesseis",
"gessemo",
"gessosa",
"gestamo",
"gesteis",
"gestema",
"gestemo",
"gesônia",
"geteias",
"geáster",
"geáveis",
"geóbata",
"geóbate",
"geóbios",
"geófaga",
"geógena",
"geógeno",
"geótomo",
"geômoro",
"geônima",
"geônomo",
"gibsite",
"gicebis",
"gidouro",
"giestos",
"giganho",
"gigotes",
"giguefo",
"giletas",
"gimboas",
"gimboto",
"gimbrar",
"gimbrés",
"gimbulo",
"gimneta",
"gimnete",
"gimneto",
"gimnite",
"gimnura",
"ginarca",
"ginaris",
"ginases",
"ginaste",
"gineteá",
"gingais",
"gingamo",
"gingara",
"gingoal",
"gingrai",
"gingram",
"gingrei",
"gingrem",
"gingres",
"gingros",
"gingrou",
"gingues",
"ginhais",
"ginsens",
"ginsona",
"ginsões",
"ginuras",
"ginécia",
"gipetos",
"gipsina",
"gipsosa",
"gipsoso",
"giradão",
"giraita",
"girames",
"girarmo",
"giravas",
"girbafo",
"girbões",
"gireias",
"girelas",
"girezas",
"girices",
"girobus",
"giroeta",
"giropos",
"giroses",
"girotes",
"girreia",
"girreus",
"girônea",
"girôneo",
"gisnada",
"gitagos",
"gitinas",
"gizador",
"gizadão",
"gizante",
"gizarei",
"gizares",
"gizarmo",
"gizarás",
"gizarão",
"gizaste",
"gizavas",
"gizemos",
"gizirão",
"giática",
"giático",
"glacemo",
"glaciam",
"gladiai",
"gladias",
"gladiei",
"gladiem",
"gladies",
"gladita",
"glastos",
"glaçais",
"glaçamo",
"glaçava",
"glerina",
"glicase",
"glicato",
"glifeia",
"glifode",
"gliglôs",
"gliptal",
"glissai",
"glissam",
"glissas",
"glissei",
"glissem",
"glisses",
"glissou",
"glizina",
"glorear",
"glosais",
"glosamo",
"gloseis",
"glosemo",
"glossam",
"glossei",
"glossou",
"glotere",
"glotero",
"glotize",
"glotizo",
"glotizá",
"glotora",
"glotore",
"glotoro",
"glucase",
"glumada",
"glutite",
"glácido",
"gláfica",
"gláfico",
"glãozão",
"glícica",
"glícico",
"glóbeas",
"glórico",
"glótide",
"glótula",
"glômere",
"gnóssio",
"goananá",
"gobídea",
"gobíida",
"gocetes",
"godelos",
"godemos",
"godenho",
"goderai",
"goderam",
"goderas",
"goderei",
"goderem",
"goderes",
"goderou",
"godiada",
"godiado",
"godiais",
"godiará",
"godiava",
"godieis",
"godiemo",
"godiões",
"godoias",
"godoyas",
"godrins",
"godécia",
"goelais",
"goelamo",
"goelana",
"goelano",
"goelará",
"goelava",
"goeleis",
"goelemo",
"goetões",
"gofaino",
"gofrais",
"goframo",
"gofrará",
"gofrava",
"gofreis",
"gofremo",
"gogadas",
"gogogôs",
"gogosas",
"goiacas",
"goiadas",
"goiador",
"goiados",
"goiadão",
"goiamos",
"goiante",
"goiaram",
"goiarei",
"goiarem",
"goiares",
"goiaria",
"goiarmo",
"goiarás",
"goiarão",
"goiasse",
"goiaste",
"goiavam",
"goiavas",
"goiaíte",
"goiaíto",
"goiemos",
"goiesca",
"goilões",
"goipava",
"goitada",
"goitado",
"goitais",
"goitamo",
"goitará",
"goitava",
"goiteis",
"goitemo",
"goivais",
"goivamo",
"goivara",
"goivava",
"goiveis",
"goivemo",
"gojobas",
"goladão",
"golamos",
"golando",
"golango",
"golante",
"golaram",
"golaras",
"golarei",
"golarem",
"golares",
"golaria",
"golarmo",
"golarão",
"golasse",
"golaste",
"golavam",
"golavas",
"goleais",
"goleamo",
"goleeis",
"goleemo",
"goleies",
"golejai",
"golejam",
"golejas",
"golejei",
"golejem",
"golejes",
"golejou",
"golelhe",
"golelho",
"golemos",
"golfais",
"golfamo",
"golfeis",
"golfeje",
"golfejo",
"golfemo",
"goliato",
"golipai",
"golipam",
"golipar",
"golipas",
"golipei",
"golipem",
"golipes",
"golipou",
"golipão",
"golmota",
"golpais",
"golpamo",
"golpava",
"golpemo",
"gomadão",
"gomamos",
"gomante",
"gomaram",
"gomarei",
"gomares",
"gomaria",
"gomarmo",
"gomarão",
"gomasse",
"gomaste",
"gomavam",
"gomavas",
"gombele",
"gombelo",
"gombelá",
"gomemos",
"gomeras",
"gomezas",
"gomilho",
"gomitai",
"gomitam",
"gomitem",
"gomites",
"gomitou",
"gomoada",
"gomoado",
"gomoais",
"gomoamo",
"gomoava",
"gomoeis",
"gomoemo",
"gomutos",
"gonador",
"gonados",
"gonadão",
"gonamos",
"gonando",
"gonante",
"gonaram",
"gonarca",
"gonarei",
"gonarem",
"gonares",
"gonaria",
"gonarmo",
"gonarás",
"gonarão",
"gonasse",
"gonaste",
"gonavam",
"gonavas",
"gondiis",
"gondões",
"gonelas",
"gonemos",
"gonetes",
"gongais",
"gongana",
"gongano",
"gongoma",
"gongons",
"gongore",
"goniode",
"gonites",
"goniuro",
"gononos",
"gonorol",
"gonospa",
"gonzelo",
"gonácio",
"gonáqua",
"goníada",
"gonímio",
"gopurás",
"gorador",
"goradão",
"goramos",
"goranas",
"gorante",
"goraras",
"gorarei",
"gorares",
"gorarmo",
"goraste",
"goravas",
"gorbaga",
"gorchos",
"gordieu",
"gordufo",
"gordume",
"gordure",
"goreira",
"goremos",
"gorente",
"goretos",
"gorgola",
"gorgole",
"gorgoli",
"gorgotó",
"gorgões",
"gorites",
"gorjeai",
"gorjéis",
"gornais",
"gornamo",
"gorniam",
"gornias",
"gornida",
"gornimo",
"gornira",
"goronas",
"gorongo",
"gorotil",
"gorotis",
"gorrada",
"gorrais",
"gorramo",
"gorrava",
"gorreis",
"gorremo",
"gorvata",
"gorvião",
"gosmada",
"gosmais",
"gosmamo",
"gosmará",
"gosmava",
"gosmeis",
"gosmemo",
"gosmões",
"gosseia",
"gotados",
"goteais",
"goteará",
"goteeis",
"goteiam",
"goteias",
"goteiem",
"goteies",
"gotejai",
"gotejas",
"gotejes",
"gotijas",
"gotismo",
"gotista",
"gotrins",
"gotulho",
"gotúlio",
"gotúrio",
"gougada",
"gougado",
"gougais",
"gougará",
"gougava",
"gougres",
"gouguei",
"gouguem",
"gougues",
"goulina",
"goulona",
"goundou",
"goupias",
"govares",
"goveras",
"govetas",
"govetes",
"govênia",
"gozarmo",
"goéteas",
"grabana",
"grabano",
"grabeia",
"grabeus",
"grabiço",
"grabosa",
"gracera",
"gracito",
"gracuís",
"gradais",
"gradamo",
"gradeci",
"gradeis",
"gradeje",
"gradejo",
"gradejá",
"grademo",
"gradues",
"graelos",
"grafamo",
"grafeis",
"grafemo",
"grafile",
"grafilo",
"grageus",
"gragido",
"graiada",
"graiais",
"graiamo",
"graiará",
"graiava",
"graieis",
"graiemo",
"grainça",
"grainço",
"graipas",
"graipus",
"gralhai",
"gralhei",
"gralhes",
"gralheá",
"gralzão",
"gramaia",
"gramamo",
"gramaçu",
"grambes",
"grameis",
"gramemo",
"gramilo",
"gramita",
"gramite",
"gramixa",
"gramiço",
"gramono",
"grampai",
"grampam",
"grampem",
"grampes",
"grampou",
"gramões",
"granamo",
"graneai",
"graneei",
"graneia",
"graneie",
"graneio",
"granemo",
"graneou",
"graniam",
"granias",
"granide",
"granimo",
"granirá",
"granisé",
"granize",
"granjos",
"granzas",
"granóis",
"graodos",
"grapibu",
"grapsos",
"grasnai",
"grasnas",
"grasnes",
"grasnis",
"grasniu",
"grateai",
"grateei",
"grateie",
"grateio",
"grateou",
"grauatá",
"graunha",
"graunço",
"gravais",
"gravajo",
"gravejo",
"gravemo",
"graviço",
"gravuro",
"graxeai",
"graxeei",
"graxeia",
"graxeie",
"graxeio",
"graxeou",
"graxuda",
"grazine",
"graçaim",
"graçaís",
"graçole",
"graçolo",
"graçosa",
"graçoso",
"graíços",
"greciza",
"grecize",
"grecizo",
"gregais",
"gregoge",
"greiras",
"grelais",
"grelamo",
"greleis",
"grelemo",
"grelhai",
"grelhes",
"grelões",
"grempas",
"grescas",
"gresoso",
"gretais",
"gretamo",
"gretará",
"greteis",
"gretemo",
"grevada",
"grevíea",
"gricena",
"grichas",
"grichos",
"gridefé",
"grielos",
"grifais",
"grifamo",
"grifemo",
"grifita",
"grijons",
"grilais",
"grilamo",
"grilara",
"grileis",
"grilemo",
"griliam",
"grilias",
"grilido",
"grilimo",
"grilirá",
"grilode",
"grimpai",
"grimpem",
"grimíea",
"grinfai",
"grinfam",
"grinfei",
"grinfem",
"grinfes",
"grinfos",
"grinfou",
"gripamo",
"gripeis",
"gripemo",
"gripote",
"grisada",
"grisais",
"grisamo",
"grisara",
"grisava",
"griseia",
"griseis",
"grisemo",
"grisoas",
"grisona",
"grisota",
"gritamo",
"gritemo",
"grivada",
"grivais",
"grivamo",
"grivará",
"grivava",
"griveis",
"grivemo",
"grizeta",
"grochos",
"grodote",
"grolais",
"grolamo",
"grolara",
"grolava",
"groleis",
"grolemo",
"gromado",
"gromais",
"gromamo",
"gromara",
"gromava",
"gromeis",
"gromemo",
"grongai",
"grongam",
"grongou",
"grongue",
"gronjas",
"gronope",
"grosada",
"grosado",
"grosais",
"grosamo",
"grosará",
"grosava",
"groseis",
"grosemo",
"grosmai",
"grosmar",
"grosmas",
"grosmei",
"grosmem",
"grosmes",
"grosmou",
"gruamos",
"gruaria",
"grudais",
"grudamo",
"grueira",
"grugrus",
"gruidor",
"gruidão",
"gruirei",
"gruiria",
"gruirão",
"grulhai",
"grulhei",
"grulhem",
"grulhes",
"grulhou",
"grumais",
"grumamo",
"grumava",
"grumece",
"grumeci",
"grumeis",
"grumelo",
"grumemo",
"grumeça",
"grumeço",
"grunham",
"grunhis",
"grupamo",
"grupeis",
"grupemo",
"gruziai",
"gruziam",
"gruziar",
"gruzias",
"gruziei",
"gruziem",
"gruzies",
"gruziou",
"gruídas",
"gruíeis",
"gruímos",
"gruíram",
"gruíras",
"gruíres",
"gruísse",
"gruíste",
"gráculo",
"grávios",
"grâmias",
"grângea",
"grévias",
"gréyias",
"grícuas",
"grífeas",
"grílida",
"grímeas",
"grímias",
"grímmia",
"gríseas",
"gríseos",
"gróbias",
"grômias",
"grômida",
"guacini",
"guaciás",
"guadais",
"guaiais",
"guaiamo",
"guaieis",
"guaiemo",
"guaipás",
"guaipés",
"guaiqui",
"guairós",
"guaitás",
"guaivas",
"guaióis",
"guajaco",
"guajere",
"guajicá",
"gualdam",
"gualdem",
"gualdes",
"gualdiu",
"guambas",
"guambus",
"guamixá",
"guampeá",
"guampos",
"guanase",
"guangau",
"guangos",
"guapeai",
"guapeei",
"guapeia",
"guapeie",
"guapeio",
"guapeou",
"guapuís",
"guaquis",
"guarais",
"guarane",
"guardeá",
"guareci",
"guareça",
"guareço",
"guariam",
"guarimo",
"guarito",
"guarixó",
"guarnam",
"guarnas",
"guarnem",
"guarnia",
"guarniu",
"guarupé",
"guascai",
"guascam",
"guascou",
"guaucás",
"guazada",
"guazado",
"guazais",
"guazamo",
"guazará",
"guazeis",
"guazemo",
"guazuís",
"guaçaís",
"guaçuti",
"gueblis",
"gueches",
"guechos",
"gueijos",
"guelmos",
"guemais",
"guembas",
"guembos",
"guembus",
"guengai",
"guengam",
"guengar",
"guengou",
"guensos",
"guenzes",
"guererê",
"guessos",
"guestos",
"guetita",
"gufongo",
"gugerno",
"guiabas",
"guibosa",
"guidais",
"guidamo",
"guidava",
"guideis",
"guidemo",
"guidibô",
"guimbis",
"guimbés",
"guimpes",
"guinamo",
"guinapa",
"guinaús",
"guindei",
"guinela",
"guinemo",
"guingau",
"guinhai",
"guinham",
"guinhar",
"guinhei",
"guinhem",
"guinhes",
"guinola",
"guipara",
"guiparu",
"guiquém",
"guiraru",
"guiraró",
"guirris",
"guisais",
"guisamo",
"guisava",
"guiseis",
"guisemo",
"guitano",
"guivras",
"guizais",
"guizamo",
"guizara",
"guizava",
"guizeis",
"guizemo",
"gujarás",
"gujires",
"gulaime",
"gulaimo",
"gulalas",
"gulapai",
"gulapam",
"gulapar",
"gulapas",
"gulapei",
"gulapem",
"gulapes",
"gulapou",
"gulapão",
"gulazai",
"gulazam",
"gulazas",
"gulazei",
"gulazem",
"gulazes",
"gulazou",
"guleime",
"guleimo",
"guleira",
"gulguis",
"gulosai",
"gulosam",
"gulosem",
"guloseá",
"gulpina",
"guluves",
"gumatas",
"gumates",
"guminas",
"gumitas",
"gumites",
"gumosas",
"gundras",
"gunduru",
"gunelos",
"gunfada",
"gunfado",
"gunfais",
"gunfamo",
"gunfará",
"gunfava",
"gunfeis",
"gunfemo",
"gungune",
"gunguno",
"gunites",
"gurabos",
"gurabus",
"guratau",
"guratia",
"guratãs",
"guraéns",
"guraíra",
"guraúra",
"gurente",
"gureris",
"guresis",
"gurgaus",
"gurgita",
"gurgito",
"gurguez",
"guribas",
"guribus",
"guritão",
"guriçás",
"gurnais",
"gurnamo",
"gurniam",
"gurnias",
"gurnida",
"gurnido",
"gurnimo",
"gurnirá",
"gurrino",
"gurubus",
"gurumim",
"gurunce",
"gurungo",
"gurupus",
"gururis",
"gurutis",
"guruçás",
"gurânia",
"gusanas",
"gustada",
"gustais",
"gustamo",
"gusteis",
"gustemo",
"gutadas",
"gutador",
"gutados",
"gutadão",
"gutambu",
"gutamos",
"gutando",
"gutante",
"gutaram",
"gutarei",
"gutarem",
"gutares",
"gutaria",
"gutarmo",
"gutarás",
"gutarão",
"gutasse",
"gutaste",
"gutavam",
"gutavas",
"gutedra",
"gutemos",
"gutinas",
"guzumba",
"guáreas",
"guânico",
"guíméis",
"gábales",
"gábatas",
"gábitos",
"gábulas",
"gábulos",
"gáiacos",
"gáiveas",
"gálacos",
"gálbana",
"gálbino",
"gálhica",
"gárgare",
"gárrios",
"gáurica",
"gáurios",
"gávatas",
"gávedos",
"gâmaros",
"gâmbues",
"gângara",
"gécinos",
"gélulas",
"gérrida",
"géstico",
"gíbulas",
"gípseas",
"gípseos",
"górgica",
"górgico",
"gômitos",
"gúmenas",
"gúmicas",
"gúmicos",
"gúndara",
"gúndias",
"gúneras",
"gútulas",
"gútulos",
"habrura",
"hacatas",
"hachuro",
"hacubes",
"hacélia",
"hadenas",
"hadimus",
"hadruso",
"hafnias",
"haggita",
"hagênia",
"haimeia",
"haimoré",
"haiques",
"halacas",
"halesos",
"haliais",
"halicto",
"halidre",
"halioma",
"halitai",
"halitam",
"halitas",
"halitei",
"halitem",
"halites",
"halitou",
"haltera",
"haléria",
"halésia",
"halízia",
"halóbio",
"hamarto",
"hamatos",
"hamidia",
"hamireu",
"hamosas",
"hamosos",
"hamélia",
"hamínea",
"hamíneo",
"hangolo",
"haploma",
"haptena",
"haptina",
"haquimo",
"hardela",
"harelda",
"hargios",
"haridim",
"haridis",
"hariole",
"haronga",
"harpada",
"harpado",
"harpais",
"harpamo",
"harpara",
"harpava",
"harpeai",
"harpeei",
"harpeia",
"harpeie",
"harpeio",
"harpeis",
"harpeje",
"harpemo",
"harpiia",
"harteai",
"hartear",
"harteia",
"harteie",
"harteou",
"hartina",
"hartita",
"hartite",
"hartito",
"harveia",
"harvela",
"hastins",
"hastita",
"hasária",
"hasário",
"hatamas",
"hauinas",
"haurais",
"hauramo",
"haurimo",
"havedor",
"havidão",
"hazenas",
"haástia",
"heangos",
"hebaute",
"hebetai",
"hebetam",
"hebetas",
"hebetei",
"hebetem",
"hebetes",
"hebetou",
"hecateu",
"hedeoma",
"hedicro",
"hediote",
"hedonal",
"hedruro",
"hedíbio",
"hedíboa",
"helenes",
"heligmo",
"helinos",
"heliote",
"heloide",
"heloses",
"helvela",
"helvita",
"helvite",
"heléria",
"helíeas",
"helóbia",
"helócio",
"helônia",
"hemanto",
"hemeras",
"heminas",
"hemiope",
"hemipos",
"hemites",
"hemixos",
"hemoide",
"hemonco",
"hemuato",
"hemíona",
"hemítea",
"hemópis",
"hemúlon",
"henides",
"henismo",
"henista",
"heníoco",
"heptina",
"heptona",
"hequeso",
"herbana",
"herbano",
"herdais",
"herdemo",
"herelas",
"hereras",
"heresie",
"heresio",
"herieus",
"hermeia",
"hermeus",
"herniai",
"herniei",
"herniem",
"hernies",
"heroize",
"heroizo",
"heródia",
"hessita",
"hessite",
"hesíone",
"hetadas",
"hetador",
"hetados",
"hetadão",
"hetamos",
"hetando",
"hetante",
"hetaram",
"hetaras",
"hetarei",
"hetarem",
"hetarmo",
"hetarão",
"hetasse",
"hetaste",
"hetavam",
"hetavas",
"hetemos",
"hetinho",
"hetávio",
"heumito",
"heveena",
"heveeno",
"hevítia",
"hexanco",
"hexapla",
"hexilas",
"hexilos",
"hexinas",
"hexitas",
"hexodos",
"hexísea",
"hexódio",
"heúrnia",
"hialoma",
"hialzão",
"hiapuás",
"hiatiza",
"hiatize",
"hiatizo",
"hibante",
"hibanto",
"hibaqui",
"hibomas",
"hidnora",
"hidrase",
"hidrena",
"hidrila",
"hidroas",
"hidrona",
"hidrone",
"hidróis",
"hienial",
"hifales",
"hifidro",
"higiama",
"higrima",
"hilacta",
"hilacte",
"hilarie",
"hilasta",
"hilaste",
"hilatas",
"hilaíra",
"hileias",
"hilelas",
"hiletos",
"hilines",
"hilinos",
"hiliota",
"hilipos",
"hilodes",
"hiloica",
"hiloico",
"hiléria",
"hilésia",
"hilídea",
"hilínea",
"hilíneo",
"hilóbio",
"himbári",
"himétio",
"hinamos",
"hinidas",
"hinidor",
"hinidos",
"hinidão",
"hinimos",
"hinindo",
"hiniram",
"hinirei",
"hinirem",
"hinires",
"hiniria",
"hinirmo",
"hinirás",
"hinirão",
"hinismo",
"hinisse",
"hiniste",
"hiníeis",
"hinóbio",
"hipateu",
"hipemia",
"hipenas",
"hiperas",
"hiperol",
"hipetra",
"hipiste",
"hipnais",
"hipnelo",
"hipnome",
"hipoxes",
"hipoxie",
"hipoxio",
"hiptage",
"hipural",
"hipures",
"hipuros",
"hipária",
"hipécoo",
"hipéria",
"hipídea",
"hipídeo",
"hipínea",
"hipíneo",
"hipônix",
"hiraras",
"hircosa",
"hireias",
"hirgóis",
"hirilco",
"hirinas",
"hirinos",
"hirirás",
"hirpina",
"hirpino",
"hirtada",
"hirtado",
"hirtais",
"hirtamo",
"hirtará",
"hirtava",
"hirteis",
"hirtela",
"hirtemo",
"hirudas",
"hirácea",
"hiráceo",
"hispado",
"hispais",
"hispamo",
"hispara",
"hispava",
"hispeis",
"hispela",
"hispemo",
"hispide",
"hissons",
"hiulcas",
"hiulcos",
"hiáleas",
"hiálica",
"hiálico",
"hiática",
"hiático",
"hiérnia",
"hiênida",
"hiósere",
"hoatchi",
"hoazins",
"hodamos",
"holasco",
"holerca",
"holetra",
"holetro",
"holmina",
"holongo",
"holtita",
"holzões",
"homanas",
"homaros",
"homaças",
"hombula",
"homelas",
"homilio",
"homoica",
"homoico",
"homoros",
"homoses",
"homérea",
"honorai",
"honorei",
"hoombes",
"hopeína",
"hopeíte",
"horador",
"horadão",
"horante",
"horares",
"horarmo",
"horarão",
"horaste",
"horavas",
"hordéea",
"horejai",
"horejam",
"horejar",
"horejas",
"horejei",
"horejem",
"horejes",
"horejou",
"horense",
"horonas",
"hortado",
"hortamo",
"hortará",
"hortava",
"horteis",
"horteje",
"hortejá",
"hortemo",
"horíola",
"hostaus",
"hoteias",
"hotônia",
"houchas",
"houvais",
"houveis",
"houvemo",
"houvias",
"hovênia",
"hoóbias",
"hoódias",
"huamais",
"huambas",
"huambes",
"huanóis",
"hubéria",
"hugônia",
"hulhita",
"hulhite",
"humanei",
"humanem",
"humetai",
"humetam",
"humetas",
"humetei",
"humetem",
"humetes",
"humetou",
"humiris",
"humites",
"hunismo",
"huntita",
"hurídea",
"hurídeo",
"huxleia",
"huáceas",
"huáceos",
"huérnia",
"huênias",
"háfnica",
"háfnico",
"háfnios",
"háltica",
"hápalos",
"háquias",
"hármato",
"hármica",
"hármico",
"hárpida",
"hárteas",
"hássios",
"hástias",
"hâhnium",
"hâmbria",
"hâmulas",
"héctias",
"héderas",
"hégiras",
"hélcion",
"hélicos",
"hélodas",
"hélodes",
"hélodos",
"hélopes",
"hélulas",
"hérbula",
"hérmeas",
"hérmeos",
"hérpons",
"héspere",
"hétegos",
"hétemãs",
"hétigos",
"hétmane",
"hétodos",
"hêimias",
"hêmaris",
"hêmases",
"hêmisos",
"hênrios",
"híbodes",
"hícleas",
"hícleos",
"híctias",
"hídneas",
"hídneos",
"hídrion",
"híficas",
"híficos",
"hígrica",
"hígrico",
"hílaras",
"híndsia",
"híodons",
"hípatas",
"hípidas",
"hípnala",
"hípneas",
"hírcica",
"hírculo",
"hírpice",
"hírtega",
"hírtego",
"hística",
"hólmias",
"hólmica",
"hólmico",
"hólmios",
"hólobos",
"hólopos",
"hóplias",
"hóplons",
"hórcias",
"hórdeas",
"hórdeos",
"hórmica",
"hórmico",
"hórtias",
"hômolas",
"hômolos",
"húmulos",
"iabacês",
"iabains",
"iabaniã",
"iaburus",
"iacumas",
"iadagãs",
"iademim",
"iaefuns",
"iaguara",
"ialaxés",
"ialhoís",
"ialilás",
"ialofos",
"iamacês",
"iambice",
"iameias",
"iamorôs",
"ianomãs",
"iantita",
"iapanas",
"iapuçás",
"iapígio",
"iaraíba",
"iarense",
"iarivás",
"iaruara",
"iarumás",
"iaruurá",
"iasinho",
"iataimi",
"iatinho",
"iatreus",
"iatrias",
"iauiras",
"iazeles",
"iaçanãs",
"ibabiús",
"ibacaus",
"ibacuri",
"ibapois",
"ibapoós",
"ibarana",
"ibarano",
"ibeinhe",
"iberina",
"iberite",
"iberiza",
"iberize",
"iberizo",
"ibibora",
"ibiocas",
"ibiraiú",
"ibiraré",
"ibiraró",
"ibiraém",
"ibirube",
"iboboca",
"iboxalá",
"ibácias",
"ibérida",
"ibéride",
"ibícter",
"ibídeas",
"ibídeos",
"ibídida",
"icacina",
"icangas",
"icariba",
"icelino",
"icenses",
"ichibis",
"ichibus",
"ichobas",
"icicana",
"icmádio",
"icnanto",
"icneumo",
"icnopos",
"icodema",
"iconizo",
"icorosa",
"icosana",
"icrídio",
"ictinas",
"ictióis",
"ictéria",
"ictíaca",
"ictícia",
"ictício",
"ictídio",
"icumbes",
"icungos",
"icérias",
"icílida",
"icóquis",
"idalcão",
"idalxas",
"idantes",
"idaítas",
"ideadão",
"idearei",
"idearás",
"idearão",
"ideavas",
"ideemos",
"ideenta",
"ideento",
"idejogô",
"idiando",
"idiliza",
"idilize",
"idilizo",
"idinhas",
"idinhos",
"idiotei",
"idiotem",
"iditóis",
"idjogós",
"idmônea",
"idoiras",
"idoiros",
"idonato",
"idorona",
"idorzão",
"idouras",
"idouros",
"idálica",
"idálios",
"idésias",
"idética",
"idílias",
"idítica",
"idítico",
"idórgão",
"idóteas",
"idônico",
"iebaros",
"ieboxis",
"ienitas",
"iererês",
"ietense",
"ietinas",
"ietinos",
"ifolzão",
"ifuches",
"ifumbes",
"ifusses",
"ifínoes",
"igangas",
"igarçus",
"igasóis",
"igbiras",
"igbonas",
"igelite",
"iglitas",
"iglites",
"iglitos",
"ignitos",
"ignizai",
"ignizas",
"ignizei",
"ignizem",
"ignizes",
"ignizou",
"igongos",
"igonhas",
"igrejós",
"iguadas",
"iguador",
"igualai",
"igualhe",
"igualho",
"iguamos",
"iguando",
"iguante",
"iguaram",
"iguarei",
"iguarem",
"iguares",
"iguaris",
"iguarão",
"iguarça",
"iguarço",
"iguasse",
"iguaste",
"iguavam",
"iguavas",
"iguemos",
"iguexás",
"iguiari",
"iguvina",
"iguvino",
"iiabacê",
"iiamorô",
"iianhás",
"iianlás",
"iijexás",
"ijindes",
"ijolita",
"ijolito",
"iladona",
"iladora",
"iladões",
"ilambas",
"ilambos",
"ilantes",
"ilapsos",
"ilaqueá",
"ilarcas",
"ilardes",
"ilareis",
"ilaremo",
"ilariam",
"ilarmos",
"ilassem",
"ilasses",
"ilastes",
"ilaçona",
"ilecuns",
"ileomos",
"ileosas",
"ilequés",
"ilergaã",
"ilesita",
"ilesite",
"ileísmo",
"ileítes",
"ilhador",
"ilhadão",
"ilhapas",
"ilharas",
"ilharei",
"ilharem",
"ilharmo",
"ilharão",
"ilhasse",
"ilhaste",
"ilhemos",
"ilhocos",
"ilhotos",
"ilianto",
"ilibais",
"ilibamo",
"ilibeis",
"ilibemo",
"iliceis",
"ilicemo",
"ilicíea",
"ilidais",
"ilidamo",
"ilidias",
"iligada",
"iligará",
"iligava",
"iliguei",
"iliguem",
"iligues",
"ilipina",
"iliçais",
"iliçamo",
"iliçará",
"iliçava",
"iliçona",
"ilições",
"ilogias",
"iluaiês",
"iludamo",
"iludemo",
"ilumbas",
"ilusais",
"ilusamo",
"ilusiam",
"ilusias",
"ilusida",
"ilusido",
"ilusimo",
"ilusirá",
"ilusona",
"ilutado",
"ilutais",
"ilutamo",
"ilutara",
"ilutava",
"iluteis",
"ilutemo",
"iluviai",
"iluviam",
"iluviar",
"iluviei",
"iluviem",
"iluvies",
"iluviou",
"ilvates",
"iláveis",
"ilíades",
"ilíbios",
"ilíceas",
"ilíceos",
"ilícica",
"ilígera",
"ilínios",
"ilíofis",
"ilísias",
"ilísida",
"ilúrias",
"ilúrios",
"ilúvios",
"imaduca",
"imaduco",
"imafeno",
"imageai",
"imageei",
"imageie",
"imamada",
"imanais",
"imaneis",
"imanemo",
"imaniza",
"imanize",
"imanizo",
"imantai",
"imantas",
"imantei",
"imbambi",
"imbembo",
"imberis",
"imbicai",
"imbicas",
"imbilas",
"imbique",
"imbiris",
"imbirus",
"imbiuás",
"imbonde",
"imbondá",
"imborãs",
"imbotês",
"imbrame",
"imbramo",
"imbramá",
"imbuins",
"imburim",
"imbúndi",
"imediai",
"imediar",
"imediei",
"imediem",
"imedies",
"imediou",
"imereci",
"imereça",
"imereço",
"imersai",
"imersem",
"imigres",
"imissos",
"imitias",
"imitimo",
"imoirai",
"imoiram",
"imoirar",
"imoiras",
"imoirei",
"imoirem",
"imoires",
"imoirou",
"imolamo",
"imoleis",
"imolemo",
"imoralã",
"imotada",
"imotado",
"imotamo",
"imotará",
"imotava",
"imoteis",
"imotemo",
"imotivo",
"imourai",
"imouram",
"imourar",
"imouras",
"imourei",
"imourem",
"imoures",
"imourou",
"imovais",
"imovemo",
"imovera",
"imoviam",
"imovida",
"imovido",
"impadas",
"impambo",
"impamos",
"impanai",
"impanam",
"impanas",
"impanei",
"impanem",
"impanou",
"imparei",
"imparmo",
"impavas",
"impelga",
"impelgo",
"impendo",
"impenes",
"imperei",
"imperlo",
"imperlá",
"impetai",
"impetam",
"impetas",
"impetei",
"impetem",
"impetes",
"impilas",
"impilos",
"impiote",
"impolto",
"imponas",
"imponem",
"imponir",
"imponis",
"imponiu",
"impontá",
"impreva",
"impreve",
"imprevo",
"improvo",
"impucos",
"impujes",
"impunam",
"impunas",
"impunia",
"impunir",
"impuniu",
"imputai",
"imundai",
"imundem",
"imutais",
"imutamo",
"imutara",
"imutava",
"imutemo",
"imãzada",
"imãzado",
"imãzais",
"imãzamo",
"imãzará",
"imãzava",
"imãzeis",
"imãzemo",
"imídica",
"inabite",
"inabito",
"inadate",
"inadato",
"inadatá",
"inadune",
"inaduno",
"inaduná",
"inaiaís",
"inajaís",
"inalamo",
"inaleis",
"inalemo",
"inamena",
"inameno",
"inamuus",
"inamuís",
"inanais",
"inanamo",
"inaniam",
"inanirá",
"inaçona",
"inaíbas",
"incabis",
"incalás",
"incanas",
"incanos",
"incaíta",
"incemos",
"incetor",
"inchais",
"incheis",
"inchimã",
"inciamo",
"incieis",
"inciemo",
"incindi",
"incinge",
"incingi",
"incinja",
"incinjo",
"incisei",
"incoais",
"incoamo",
"incoara",
"incoava",
"incocas",
"incoces",
"incoeis",
"incoemo",
"increda",
"increem",
"increio",
"increrá",
"incriam",
"incrias",
"incrida",
"incrido",
"incrisa",
"incrise",
"incuaia",
"incubai",
"incudas",
"incudes",
"incusas",
"incusos",
"incuças",
"indacas",
"indaiés",
"indajás",
"indalmo",
"indanas",
"indegre",
"indegro",
"indegrá",
"indenas",
"indenos",
"indexai",
"indiamã",
"indiaru",
"indilgo",
"indilgá",
"indilos",
"indinhe",
"indombe",
"indonas",
"indorme",
"indormi",
"indoxol",
"indoína",
"indrias",
"indumba",
"induras",
"indurma",
"indurmo",
"induros",
"indutai",
"indutam",
"indutas",
"indutei",
"indutem",
"indutes",
"indutou",
"induças",
"indúcia",
"indúvio",
"inelito",
"inembus",
"ineramo",
"ineriam",
"inerias",
"inerimo",
"inerirá",
"inervai",
"inervas",
"inervei",
"inexija",
"inexijo",
"ineínas",
"infamai",
"infamei",
"inferne",
"infessa",
"infesso",
"infetai",
"infetei",
"infetes",
"infingi",
"infinjo",
"infixai",
"infixam",
"infixei",
"infixem",
"infixes",
"infizes",
"infiões",
"inflais",
"inflemo",
"infleto",
"inflito",
"infloro",
"infonai",
"infonam",
"infonar",
"infonas",
"infonei",
"infonem",
"infones",
"infonou",
"infrata",
"infucas",
"infumos",
"infunge",
"infusai",
"infusam",
"infusei",
"infusem",
"infusou",
"infâmio",
"infúnji",
"ingalas",
"ingauna",
"ingauno",
"ingauçu",
"ingaçus",
"ingaíba",
"ingaíbo",
"ingaíva",
"ingevão",
"ingevãs",
"inglelê",
"ingombe",
"ingonda",
"ingondo",
"ingonha",
"ingonos",
"ingotés",
"ingrida",
"ingrido",
"ingrãos",
"inguaçu",
"inguefo",
"inguelo",
"ingunde",
"ingundo",
"ingundá",
"ingunga",
"inguíba",
"inhamal",
"inhambi",
"inhanho",
"inhapis",
"inharas",
"inhatas",
"inhatis",
"inhatos",
"inhenga",
"inhumar",
"inialas",
"inibais",
"inibamo",
"inibemo",
"inibias",
"inimbói",
"inimbós",
"inimite",
"inimito",
"iniomas",
"iniomos",
"iniopes",
"iniopia",
"iniquar",
"iniídea",
"iniínea",
"iniíneo",
"injalas",
"injesto",
"injetai",
"injetes",
"injunce",
"injungi",
"injunja",
"injunjo",
"injunço",
"inlhofo",
"inlicei",
"inlicem",
"inlices",
"inliçai",
"inliçam",
"inliçar",
"inliças",
"inliçou",
"inlusir",
"inobres",
"inocibe",
"inodada",
"inodado",
"inoense",
"inofila",
"inofilo",
"inoloma",
"inorada",
"inorais",
"inoramo",
"inorará",
"inoreis",
"inoremo",
"inosato",
"inovais",
"inovamo",
"inovemo",
"inquaia",
"inquera",
"inquini",
"inserve",
"insolai",
"insolas",
"insolei",
"insolem",
"insomem",
"insomes",
"insonie",
"insorge",
"insosse",
"inspeta",
"inspete",
"instais",
"instemo",
"insucos",
"insulai",
"insulei",
"insulem",
"insules",
"insumai",
"insumam",
"insumar",
"insumas",
"insumei",
"insumia",
"insumir",
"insumis",
"insumou",
"intalho",
"intambe",
"intaúba",
"inteigo",
"inteigá",
"inticai",
"inticam",
"inticas",
"intimai",
"intimua",
"intinas",
"intique",
"intisis",
"intoira",
"intoire",
"intoiri",
"intoiro",
"intonai",
"intonam",
"intonas",
"intonei",
"intonem",
"intones",
"intonou",
"intotos",
"intoura",
"intoure",
"intouri",
"intouro",
"intrato",
"intrita",
"intruda",
"intrudi",
"intruji",
"intuais",
"intuamo",
"intubai",
"intubam",
"intubas",
"intubei",
"intubem",
"intubes",
"intules",
"intulás",
"intuímo",
"inultas",
"inumais",
"inumeis",
"inumemo",
"invares",
"invarie",
"invario",
"invasar",
"invasas",
"invasei",
"invasem",
"invasou",
"invecas",
"invecte",
"invecto",
"invetai",
"invices",
"invidai",
"invidam",
"invidei",
"invides",
"invidou",
"inviris",
"invisas",
"invisos",
"invitai",
"invital",
"invitem",
"involuo",
"invulgo",
"invulgá",
"inxidro",
"inzalas",
"inzenza",
"inzibas",
"inzicas",
"inzonai",
"inzonam",
"inzonei",
"inzonem",
"inzones",
"inzonou",
"inácias",
"inçador",
"inçadão",
"inçamos",
"inçante",
"inçaram",
"inçarei",
"inçarem",
"inçares",
"inçarmo",
"inçarás",
"inçarão",
"inçaste",
"inçavas",
"inépico",
"inérvea",
"inócito",
"inópias",
"inósica",
"inósico",
"inúbila",
"inúbilo",
"inúleas",
"inúleos",
"iobadas",
"iobador",
"iobados",
"iobadão",
"iobamos",
"iobando",
"iobante",
"iobaram",
"iobarei",
"iobarem",
"iobares",
"iobaria",
"iobarmo",
"iobarás",
"iobarão",
"iobasse",
"iobaste",
"iobavam",
"iobavas",
"iobemos",
"iocroma",
"ioculas",
"ioculos",
"iodador",
"iodadão",
"iodalil",
"iodalis",
"iodamil",
"iodamis",
"iodamos",
"iodando",
"iodante",
"iodaram",
"iodarei",
"iodarem",
"iodares",
"iodaria",
"iodarmo",
"iodarás",
"iodarão",
"iodasse",
"iodaste",
"iodavam",
"iodavas",
"iodemia",
"iodemos",
"iodetai",
"iodetam",
"iodetar",
"iodetas",
"iodetem",
"iodetou",
"iodeína",
"iodinai",
"iodinam",
"iodinar",
"iodinei",
"iodinem",
"iodines",
"iodinho",
"iodinou",
"iodista",
"iodites",
"iodonas",
"iodosas",
"iodável",
"ioexóis",
"iofobia",
"ioiocas",
"iolemas",
"ionenos",
"ionitas",
"ionizai",
"ionizas",
"ionizes",
"ionones",
"iontize",
"iontizo",
"iontizá",
"ionídio",
"ionínea",
"ioníneo",
"iopidol",
"iopsilo",
"iorimãs",
"iotizai",
"iotizam",
"iotizas",
"iotizei",
"iotizem",
"iotizes",
"iotizou",
"iotério",
"iozitas",
"ipecaás",
"ipecina",
"iperana",
"ipeúbas",
"ipeúnas",
"ipeúvas",
"ipombos",
"iprense",
"ipsidas",
"ipsidos",
"ipsilos",
"iptimas",
"ipuabas",
"ipuadas",
"ipuanas",
"ipuanos",
"ipuaçus",
"ipídeas",
"ipídeos",
"ipíneas",
"iquitas",
"iracuís",
"iradona",
"iradões",
"iraiara",
"irambas",
"iranita",
"irantes",
"iranxim",
"irapoca",
"irapuia",
"irarana",
"irareis",
"iraremo",
"irarias",
"irasses",
"iratuãs",
"irauçus",
"iraxins",
"iraíbas",
"iresina",
"iretóis",
"irguiço",
"iriador",
"iriadão",
"iriaram",
"iriarei",
"iriarem",
"iriares",
"iriaria",
"iriarmo",
"iriarás",
"iriarão",
"iriasse",
"iriaste",
"iriates",
"iriavas",
"iriação",
"iridato",
"iridiai",
"iridiam",
"iridias",
"iridiei",
"iridiem",
"iridies",
"iridiou",
"iridóis",
"iriense",
"irinita",
"irisais",
"irisamo",
"irisará",
"iriseis",
"irisemo",
"irizais",
"irizamo",
"irizara",
"irizava",
"irizeis",
"irizemo",
"irmanai",
"irmanes",
"irmonas",
"irolzão",
"irosina",
"irretes",
"irrigai",
"irrival",
"irrogai",
"irrogas",
"irrorai",
"irroram",
"irroras",
"irrorei",
"irrorem",
"irrores",
"irrorou",
"irruais",
"irruirá",
"irruíam",
"irruías",
"irruída",
"irruído",
"irrígua",
"irríguo",
"irubaia",
"irávias",
"irânias",
"irênios",
"irídeos",
"irídese",
"isacnes",
"isamato",
"isamida",
"isandra",
"isarcas",
"isarcos",
"isatana",
"isatido",
"iscadal",
"iscadão",
"iscante",
"iscaram",
"iscarei",
"iscarem",
"iscares",
"iscarmo",
"iscarás",
"iscarão",
"iscasse",
"iscaste",
"iscavam",
"iscavas",
"iscaços",
"iscnura",
"iscnuro",
"isenite",
"iserita",
"isidela",
"isladas",
"islados",
"islenas",
"ismenes",
"ismária",
"ismário",
"isoamil",
"isoamis",
"isoaxes",
"isoctis",
"isofena",
"isofeno",
"isofila",
"isofote",
"isogais",
"isogira",
"isogiro",
"isogéis",
"isoloma",
"isolona",
"isombra",
"isombro",
"isomira",
"isonema",
"isopete",
"isopias",
"isotana",
"isotima",
"isotimo",
"isoélia",
"isoétea",
"isoéteo",
"isoípso",
"isquelê",
"isquemo",
"isquiro",
"isquião",
"issaica",
"issaico",
"isseias",
"issenas",
"issende",
"issense",
"issicas",
"issicos",
"issobos",
"issubos",
"issucas",
"issucos",
"issunhe",
"issária",
"issídea",
"issídeo",
"issínea",
"issíneo",
"issúbus",
"istevão",
"istevãs",
"istmite",
"isárias",
"isática",
"isáxica",
"isáxico",
"isâmica",
"isértia",
"isídeas",
"isídeos",
"isístio",
"isóbasa",
"isóbria",
"isóbrio",
"isócara",
"isócero",
"isócola",
"isócoma",
"isódica",
"isódico",
"isóetos",
"isófaga",
"isófito",
"isógena",
"isógina",
"isólepe",
"isólise",
"isópaco",
"isópaga",
"isópago",
"isópico",
"isópiro",
"isótaca",
"isótaco",
"isótele",
"isótera",
"isóvala",
"isóvalo",
"isômalo",
"isônefa",
"isônefo",
"isônica",
"isônima",
"isônimo",
"isúrias",
"isúrica",
"isúrico",
"itacuãs",
"itambis",
"itambás",
"itangás",
"itanhas",
"itapaba",
"itapriá",
"itapuás",
"itaíbas",
"itemiza",
"itemizo",
"itengos",
"iterais",
"iteramo",
"iterava",
"itereis",
"iteremo",
"itiassa",
"itifalo",
"itifila",
"itifilo",
"itiquis",
"itomisa",
"itoneia",
"itoneus",
"itoítas",
"itritas",
"itrites",
"itucale",
"ituiana",
"itumbos",
"itureia",
"itérica",
"itética",
"itético",
"iuaense",
"iucagir",
"iucajir",
"iucusso",
"iulídea",
"iulídeo",
"iumirim",
"iupatis",
"iuracos",
"iuraris",
"iurarás",
"iurumis",
"iuçaras",
"ivaínas",
"iviraro",
"ivorito",
"ixantos",
"ixinque",
"ixiódea",
"ixiódeo",
"ixodina",
"ixolita",
"ixolite",
"ixonoto",
"ixuabos",
"ixíneas",
"ixíneos",
"ixódida",
"ixódios",
"ixófaga",
"ixófago",
"izalcas",
"izalcos",
"izalzão",
"izarras",
"izipras",
"izombes",
"iáltris",
"iápices",
"iássios",
"iátrion",
"iátrios",
"iátrofa",
"iázigas",
"iázigos",
"iândons",
"iântina",
"iãozões",
"içadona",
"içadora",
"içadões",
"içantes",
"içardes",
"içaremo",
"içariam",
"içasses",
"içastes",
"içáreis",
"içáveis",
"iéricas",
"iéricos",
"iócrina",
"iócrino",
"iódicos",
"iódides",
"iólitos",
"iólogas",
"iólogos",
"iôldias",
"iôntica",
"iôntico",
"iúlidas",
"jabaras",
"jabeada",
"jabeado",
"jabeais",
"jabeara",
"jabecas",
"jabeeis",
"jabeiam",
"jabeias",
"jabeiem",
"jabeies",
"jabites",
"jabitás",
"jabonas",
"jabotas",
"jaburás",
"jacaiol",
"jacaiós",
"jacamas",
"jacapus",
"jacapás",
"jacariá",
"jacarás",
"jacaçus",
"jacicás",
"jacicôs",
"jacinas",
"jacobas",
"jacteis",
"jactemo",
"jactite",
"jactito",
"jactitá",
"jacuana",
"jaculai",
"jaculam",
"jaculas",
"jaculei",
"jaculem",
"jacules",
"jacumas",
"jacunas",
"jacundê",
"jacunés",
"jacupói",
"jacutas",
"jacutos",
"jacuvas",
"jadeira",
"jaderas",
"jadeíto",
"jadonas",
"jadonos",
"jaezada",
"jaezais",
"jaezamo",
"jaezará",
"jaezava",
"jaezeis",
"jaezemo",
"jafense",
"jagados",
"jagança",
"jaganço",
"jagaque",
"jagatos",
"jagazes",
"jagomas",
"jagoíta",
"jaguaís",
"jagunda",
"jagunãs",
"jaldeta",
"jalusia",
"jalutos",
"jamarus",
"jambale",
"jambelo",
"jamboas",
"jambona",
"jambuis",
"jamburi",
"jambóis",
"jambões",
"jamedar",
"jamijão",
"jampais",
"jampeai",
"jampeou",
"jamundo",
"janacas",
"janador",
"janamos",
"janando",
"janante",
"janaram",
"janarei",
"janarem",
"janares",
"janaris",
"janarmo",
"janarão",
"janaste",
"janauís",
"janavam",
"janavas",
"jancros",
"jandiás",
"jandona",
"janelei",
"janelem",
"janeles",
"janelou",
"janemos",
"jangana",
"jangano",
"jangava",
"jangoma",
"jangoto",
"janguei",
"janguem",
"jangués",
"jangões",
"janimbu",
"janiras",
"janitás",
"janotai",
"janotam",
"janotei",
"janotem",
"janotes",
"janoteá",
"janotou",
"jantamo",
"janteis",
"januais",
"janufos",
"janumás",
"janundá",
"japanas",
"japanis",
"japical",
"japiras",
"japonim",
"japubás",
"japígio",
"jaquira",
"jarapés",
"jarauás",
"jaravas",
"jaraçus",
"jaraíba",
"jardada",
"jardais",
"jardamo",
"jardará",
"jardava",
"jardemo",
"jarerés",
"jareuás",
"jarivás",
"jarreai",
"jarrear",
"jarreei",
"jarreia",
"jarreie",
"jarreio",
"jarruva",
"jarumas",
"jarunda",
"jarunde",
"jarurus",
"jaruvás",
"jasmino",
"jaspeai",
"jaspeei",
"jaspeio",
"jassaís",
"jassina",
"jasônia",
"jatados",
"jatadão",
"jatando",
"jatarei",
"jatarem",
"jatarmo",
"jatarás",
"jatarão",
"jatasse",
"jataste",
"jatavam",
"jatavas",
"jateais",
"jateará",
"jatecas",
"jateeis",
"jateiam",
"jateias",
"jateiem",
"jateies",
"jatemos",
"jateuns",
"jatibás",
"jaticom",
"jaticás",
"jatitai",
"jatitam",
"jatitar",
"jatitas",
"jatitei",
"jatitem",
"jatites",
"jatoras",
"jatores",
"jatubás",
"jaturas",
"jaumeas",
"jaunava",
"jaunavo",
"jaunita",
"javalus",
"javanco",
"javarro",
"javaíto",
"javevós",
"javiras",
"javites",
"javrada",
"javrais",
"javramo",
"javrará",
"javrava",
"javreis",
"javremo",
"jazamos",
"jazedas",
"jazedor",
"jazermo",
"jazíeis",
"jaçanha",
"jaçapés",
"jaúndea",
"jebaras",
"jebarus",
"jegadas",
"jegamas",
"jegodes",
"jegrada",
"jeirões",
"jeitais",
"jeitamo",
"jeitava",
"jeiteis",
"jeitemo",
"jejuamo",
"jejuemo",
"jejunis",
"jelabas",
"jelabia",
"jelalas",
"jelapas",
"jendaia",
"jenipás",
"jennita",
"jequiás",
"jeramus",
"jeremio",
"jerevas",
"jerezão",
"jerezãs",
"jerical",
"jericuá",
"jerimus",
"jerizas",
"jeromas",
"jeromós",
"jeropia",
"jerrecu",
"jerumaí",
"jerumba",
"jerumus",
"jeruvas",
"jervina",
"jesuata",
"jesuato",
"jetapes",
"jetense",
"jeticos",
"jetinas",
"jetinos",
"jetivis",
"jetucas",
"jetucus",
"jevivis",
"jevuras",
"jeóvica",
"jeóvico",
"jiboiai",
"jiboiam",
"jiboiei",
"jiboiem",
"jiboies",
"jiboiou",
"jibonãs",
"jicungo",
"jifumes",
"jigogas",
"jigotes",
"jijocas",
"jilhevo",
"jiloais",
"jimboas",
"jimbrai",
"jimbram",
"jimbrar",
"jimbrei",
"jimbrem",
"jimbres",
"jimbrou",
"jimbuda",
"jimbudo",
"jimbulo",
"jinaris",
"jingilo",
"jingins",
"jingoas",
"jingote",
"jingóis",
"jinjins",
"jinséns",
"jinsões",
"jinzeus",
"jipepes",
"jipubas",
"jiquiás",
"jirabas",
"jirambo",
"jiraués",
"jirbaço",
"jiribás",
"jirimim",
"jiritis",
"jirivás",
"jirixás",
"jiropes",
"jirotes",
"jirubás",
"jiruvás",
"jisabus",
"jitambe",
"jiticas",
"jitinga",
"jitonga",
"jitreus",
"jivaras",
"jixingo",
"jiçaras",
"jobadas",
"jobador",
"jobados",
"jobadão",
"jobamos",
"jobante",
"jobaram",
"jobarei",
"jobarem",
"jobarmo",
"jobarás",
"jobarão",
"jobasse",
"jobaste",
"jobavam",
"jobavas",
"jobemos",
"jobínia",
"jocotós",
"jocromo",
"jodinas",
"joeirai",
"joeirem",
"joelhai",
"joelhem",
"jogalhe",
"jogalho",
"jogalhá",
"jogatai",
"jogatam",
"jogatei",
"jogatem",
"jogates",
"jogatou",
"jograle",
"jogralá",
"jogrete",
"jogrões",
"jogueta",
"jogueto",
"joiense",
"joietas",
"jojogas",
"jolador",
"jolados",
"joladão",
"jolamos",
"jolante",
"jolaram",
"jolarei",
"jolarem",
"jolares",
"jolaria",
"jolarmo",
"jolarás",
"jolarão",
"jolasse",
"jolaste",
"jolavam",
"jolavas",
"joldras",
"jombele",
"joncaro",
"jongado",
"jongais",
"jonguei",
"jonguem",
"jongues",
"jonismo",
"jonista",
"jonjada",
"jonjado",
"jonjais",
"jonjamo",
"jonjara",
"jonjava",
"jonjeis",
"jonjemo",
"joníaca",
"joníaco",
"jorgado",
"jorgões",
"jorneia",
"joropas",
"jorramo",
"jorreis",
"jorremo",
"jorunas",
"jovevas",
"joósias",
"juaense",
"juangue",
"juarauá",
"jubadas",
"jubados",
"jubatas",
"jubatis",
"jubebas",
"juberis",
"jubosas",
"jubosos",
"juburus",
"jucunas",
"jucundu",
"jucuris",
"jucurus",
"judaíze",
"juddita",
"judiaga",
"judiamo",
"judicai",
"judiemo",
"jugadai",
"jugadam",
"jugadei",
"jugadem",
"jugades",
"jugadou",
"jugatas",
"jugavas",
"jugulai",
"jugulei",
"jugulem",
"jugules",
"juizais",
"juizará",
"juizava",
"julatas",
"julepes",
"julepos",
"julpais",
"julácea",
"juláceo",
"julídea",
"julídeo",
"julódis",
"jumadas",
"jumardo",
"jumarás",
"jumbaem",
"jumbaio",
"jumbair",
"jumbais",
"jumbaiu",
"jumbaía",
"jumense",
"jumprim",
"juncabo",
"juncamo",
"juncela",
"juncelo",
"jundaís",
"junedim",
"junense",
"junetia",
"jungias",
"jungirá",
"junhais",
"junhães",
"junonal",
"junoíta",
"junteia",
"junçona",
"junélia",
"junônio",
"jupebas",
"jupurás",
"jupuíra",
"juradia",
"juraica",
"juranas",
"jurarmo",
"jurebas",
"juremai",
"juremam",
"juremem",
"juremes",
"jurgada",
"jurgais",
"jurgará",
"jurgava",
"jurgues",
"jurueca",
"jurumis",
"jurumus",
"jurunus",
"juruquá",
"jururás",
"jurutés",
"juruuna",
"jurínea",
"juríneo",
"jusanas",
"jusanos",
"jusitas",
"jussiva",
"justamo",
"justeis",
"justemo",
"juturga",
"juturgo",
"juvenês",
"juvetis",
"juvevas",
"juvevês",
"juviras",
"juçapés",
"jácomas",
"jágaras",
"járdias",
"jáspeas",
"jáspeos",
"jáspica",
"jássias",
"jássida",
"jássios",
"játacas",
"jázigas",
"jópicas",
"jópicos",
"jórneas",
"jóticas",
"jóticos",
"labacês",
"labegos",
"labelai",
"labelam",
"labelei",
"labelem",
"labeles",
"labelou",
"laberco",
"labicas",
"labicos",
"labielo",
"labisas",
"labreai",
"labreei",
"labreia",
"labreie",
"labreio",
"labulas",
"labuzai",
"labuzam",
"labuzas",
"labuzes",
"labuzou",
"labácia",
"labíida",
"lacacãs",
"lacaiai",
"lacaiam",
"lacaica",
"lacaico",
"lacaiei",
"lacaiem",
"lacaies",
"lacaiou",
"lacalei",
"laceais",
"laceará",
"lacedos",
"laceeis",
"laceias",
"laceies",
"laceios",
"lacemos",
"lacense",
"lacerai",
"laceras",
"lacerno",
"lacinas",
"laclara",
"lacondé",
"lacosas",
"lacosos",
"lacrama",
"lacrame",
"lacrana",
"lacrane",
"lacrano",
"lacreai",
"lacreei",
"lacreia",
"lacreie",
"lacreio",
"lacreis",
"lacremo",
"lacreou",
"lacrões",
"lactais",
"lactame",
"lactamo",
"lactana",
"lactará",
"lactava",
"lacteia",
"lactemo",
"lactilo",
"lactime",
"lactimo",
"lactóis",
"lacunai",
"lacunam",
"lacunei",
"lacunem",
"lacunou",
"lacázia",
"lacóbio",
"ladanas",
"ladanes",
"ladeais",
"ladeies",
"ladeire",
"ladilha",
"ladrada",
"ladramo",
"ladraço",
"ladreje",
"ladrejo",
"ladrejá",
"ladremo",
"ladrida",
"ladripe",
"ladripá",
"ladroai",
"ladroam",
"ladroas",
"ladroei",
"ladroem",
"lafarda",
"lafaroa",
"lafarão",
"lafarãs",
"lafigma",
"lafigmo",
"lafiras",
"lafiros",
"lafolas",
"lafraus",
"lafânia",
"lagaias",
"lageoso",
"lageras",
"laginos",
"lagopos",
"lagreta",
"laguros",
"lagídio",
"lagótio",
"lagômio",
"laicada",
"laicizo",
"laivais",
"laivamo",
"laivará",
"laivava",
"laiveis",
"laivemo",
"laivosa",
"laivoso",
"laivões",
"lajadas",
"lajador",
"lajados",
"lajadão",
"lajamos",
"lajando",
"lajante",
"lajaram",
"lajarei",
"lajarem",
"lajaria",
"lajarmo",
"lajarás",
"lajarão",
"lajasse",
"lajaste",
"lajavam",
"lajavas",
"lajeais",
"lajeara",
"lajeava",
"lajeeis",
"lajeias",
"lajeiem",
"lajeies",
"lajemos",
"laladas",
"lalador",
"lalados",
"laladão",
"lalamos",
"lalaram",
"lalarei",
"lalarem",
"lalares",
"lalarmo",
"lalarás",
"lalarão",
"lalasse",
"lalaste",
"lalavam",
"lalavas",
"lalemos",
"lamaceá",
"lamagem",
"lamaços",
"lambamo",
"lambava",
"lambaza",
"lambazo",
"lambeai",
"lambeei",
"lambeia",
"lambeie",
"lambemo",
"lambeou",
"lambeão",
"lambios",
"lambite",
"lambréu",
"lambuce",
"lambude",
"lambudo",
"lambujo",
"lambuço",
"lameana",
"lameano",
"lamedos",
"lamelai",
"lamelam",
"lamelei",
"lamelem",
"lameles",
"lamiana",
"laminai",
"lamiras",
"lamisca",
"lamojas",
"lampais",
"lampamo",
"lampaso",
"lampava",
"lampeis",
"lampemo",
"lampiai",
"lampiam",
"lampiei",
"lampiem",
"lampies",
"lampiou",
"lampoga",
"lampogo",
"lampote",
"lampreá",
"lamproa",
"lamprãs",
"lamucas",
"lamujes",
"lanador",
"lanadão",
"lanante",
"lanarei",
"lanarem",
"lanarmo",
"lanarás",
"lanarão",
"lanasse",
"lanaste",
"lanavam",
"lanavas",
"lanceai",
"lanceei",
"lanchai",
"lanchal",
"lanchôs",
"lancoas",
"landobe",
"landoca",
"landolé",
"landona",
"landova",
"landuns",
"lanedos",
"langais",
"langaré",
"langita",
"langota",
"languam",
"languce",
"languia",
"languiu",
"languço",
"languçá",
"lanhais",
"lanhamo",
"lanheis",
"lanhemo",
"lanisca",
"lanisco",
"lanojas",
"lanoros",
"lanquim",
"lanteja",
"lantora",
"lanténs",
"lanuças",
"lanxuém",
"lanzoai",
"lanzoam",
"lanzoar",
"lanzoas",
"lanzoei",
"lanzoem",
"lanzoes",
"lanzoou",
"lanzude",
"laníada",
"laníida",
"lapacea",
"lapaceo",
"lapador",
"lapados",
"lapadão",
"lapagem",
"lapando",
"lapante",
"laparam",
"laparas",
"lapardã",
"laparei",
"laparem",
"lapares",
"laparia",
"laparmo",
"lapasse",
"lapaste",
"lapavam",
"lapavas",
"lapeada",
"lapeado",
"lapeais",
"lapeará",
"lapeava",
"lapedos",
"lapeeis",
"lapeiam",
"lapeias",
"lapeiem",
"lapeies",
"lapemos",
"lapiasa",
"lapiase",
"lapiaso",
"lapigas",
"lapijai",
"lapijam",
"lapijas",
"lapijei",
"lapijem",
"lapijes",
"lapijou",
"lapinas",
"lapisai",
"lapisam",
"lapisas",
"lapisei",
"lapisem",
"lapisou",
"lapiteu",
"lapouça",
"lapírio",
"laqueai",
"laqueie",
"laqueio",
"laqueis",
"laquemo",
"laracho",
"larafis",
"larapas",
"larapie",
"laraíta",
"larcões",
"lardeai",
"lardeei",
"lardeie",
"lardeio",
"lardita",
"lardose",
"lardões",
"lareada",
"lareado",
"lareará",
"lareava",
"larecei",
"larecem",
"lareces",
"lareceu",
"lareeis",
"laregas",
"lareiam",
"lareiem",
"lareies",
"laretas",
"lareçam",
"lareças",
"largina",
"largueá",
"laridas",
"larifas",
"larifos",
"larinos",
"lariões",
"larnita",
"laroiês",
"laronas",
"larotas",
"larotes",
"larozes",
"larpada",
"larpado",
"larpais",
"larpamo",
"larpava",
"larpeis",
"larpemo",
"larpona",
"larpões",
"larucha",
"larucho",
"larundo",
"larunje",
"larunjo",
"larunjá",
"larutos",
"laruças",
"larvamo",
"larvará",
"larvava",
"larveis",
"larveje",
"larvemo",
"larvina",
"larária",
"larídea",
"laríida",
"larínea",
"laríneo",
"larínia",
"larísia",
"lascais",
"laseias",
"laserol",
"lasiopa",
"lasiúro",
"laspeai",
"laspeei",
"laspeia",
"laspeie",
"laspeio",
"laspeou",
"lasques",
"lassais",
"lassamo",
"lassava",
"lasseai",
"lasseei",
"lasseie",
"lasseio",
"lasseis",
"lassemo",
"lastrai",
"lastrem",
"lastrim",
"laséola",
"lasíeas",
"latamos",
"latanas",
"latanes",
"latanha",
"latanhe",
"latanho",
"lateara",
"lateava",
"lateeis",
"lategai",
"lategam",
"lategar",
"lategas",
"lategou",
"lategue",
"lateiam",
"lateias",
"lateiem",
"lateies",
"latejai",
"latejes",
"latendo",
"laterei",
"laterem",
"laterás",
"latesci",
"latescê",
"latesça",
"latesço",
"laticai",
"laticam",
"laticou",
"latinai",
"latinei",
"latinem",
"latingo",
"latipás",
"latique",
"latiras",
"latires",
"latirmo",
"latiste",
"lativer",
"latoada",
"latosas",
"latânea",
"latíeis",
"laudéus",
"laueíta",
"laulaus",
"launeia",
"launins",
"laurale",
"laureai",
"lautais",
"lautelo",
"lautita",
"lautiás",
"lavajai",
"lavajam",
"lavajar",
"lavajas",
"lavajei",
"lavajes",
"lavajos",
"lavajou",
"lavasca",
"lavegai",
"lavegar",
"lavegas",
"lavegos",
"lavegou",
"lavegue",
"lavoire",
"lavoiro",
"lavorai",
"lavoram",
"lavorem",
"lavoreá",
"lavorou",
"lavosas",
"lavoure",
"lavramo",
"lavreis",
"lavremo",
"lavujai",
"lavujam",
"lavujar",
"lavujas",
"lavujei",
"lavujem",
"lavujes",
"lavujos",
"lavujou",
"laxador",
"laxadão",
"laxamos",
"laxarei",
"laxarem",
"laxares",
"laxarmo",
"laxarás",
"laxarão",
"laxasse",
"laxaste",
"laxavam",
"laxavas",
"laxemos",
"lazarai",
"lazarei",
"lazarem",
"lazeire",
"lazerai",
"lazeram",
"lazeras",
"lazerei",
"lazerou",
"lazonas",
"lazules",
"laçadão",
"laçarmo",
"laçarás",
"laçaste",
"laçavas",
"lealdai",
"lealdam",
"lealdas",
"lealdei",
"lealdem",
"lealdes",
"lealzão",
"lebruna",
"lebruno",
"lebunas",
"lebunos",
"lebíida",
"lechuês",
"lecites",
"lecídea",
"lecídeo",
"ledenos",
"lediças",
"leelito",
"leeáceo",
"lefanas",
"legarmo",
"legavas",
"legaços",
"legnoto",
"legrada",
"legrais",
"legramo",
"legrará",
"legrava",
"legueis",
"leguemo",
"lehiita",
"leiauta",
"leiauto",
"leidosa",
"leidoso",
"leifita",
"leigará",
"leigava",
"leigaça",
"leiguei",
"leiguem",
"leigues",
"leiloai",
"leiloas",
"leipoas",
"leirais",
"leiramo",
"leirará",
"leirava",
"leireis",
"leiremo",
"leitamo",
"leiteis",
"leitemo",
"leitiga",
"leitual",
"leituja",
"leivada",
"leivado",
"leivões",
"leixada",
"leixado",
"leixamo",
"leixará",
"leixemo",
"lelelês",
"lelégia",
"lelíeas",
"lemanas",
"lemanes",
"lemanos",
"lembais",
"lembamo",
"lembeis",
"lembemo",
"lemonol",
"lemosis",
"lemânea",
"lemâneo",
"lemóvia",
"lemóvio",
"lenamos",
"lenanas",
"lenanos",
"lengado",
"lengara",
"lengava",
"lenguei",
"lenguem",
"lengues",
"lenhais",
"lenhamo",
"lenhara",
"lenheis",
"lenhemo",
"lenidor",
"lenidos",
"lenidão",
"lenimos",
"leniram",
"leniras",
"lenirei",
"lenirmo",
"lenirão",
"leniste",
"lentais",
"lentamo",
"lentaço",
"lenteai",
"lenteei",
"lenteia",
"lenteie",
"lenteio",
"lenteis",
"lenteje",
"lentemo",
"lenteou",
"lentite",
"lentora",
"lenzite",
"leníeis",
"leocima",
"leonote",
"lepalar",
"lepanta",
"lepasta",
"lepetas",
"lepiros",
"leprada",
"leprado",
"lepraes",
"leprona",
"lepturo",
"leptusa",
"lepálio",
"lequita",
"lerdeai",
"lerdeie",
"lerdeou",
"lereses",
"leriada",
"leriais",
"leriamo",
"leriará",
"leriava",
"lericas",
"lericos",
"leriemo",
"lerivas",
"lerneia",
"lerneus",
"lerécia",
"lesaras",
"lesarei",
"lesarmo",
"lesaste",
"lesmada",
"lesmado",
"lesmais",
"lesmamo",
"lesmara",
"lesmava",
"lesmeis",
"lesmemo",
"lesmida",
"lesnela",
"lesonas",
"lesotas",
"lessole",
"lestais",
"lestamo",
"lesteis",
"lestemo",
"lesteva",
"lesável",
"leteias",
"letramo",
"letreai",
"letreei",
"letreia",
"letreie",
"letreis",
"letremo",
"letreou",
"letrino",
"letrize",
"letrizo",
"letrizá",
"letruda",
"letília",
"leuceta",
"leucila",
"leucóis",
"levacas",
"levedai",
"levedei",
"leveira",
"levezes",
"leviada",
"leviais",
"leviamo",
"leviara",
"leviava",
"levieis",
"leviemo",
"levigai",
"levigam",
"levigas",
"levigou",
"levigue",
"levitai",
"levubos",
"levynas",
"lexiana",
"lexiona",
"lexóvio",
"leáchia",
"leístos",
"lhantra",
"liadona",
"liadões",
"liagens",
"liaremo",
"liariam",
"liarias",
"liarmos",
"liassem",
"liasses",
"liastes",
"liaçona",
"libadão",
"libanco",
"libangu",
"libante",
"libaram",
"libarei",
"libares",
"libarmo",
"libarté",
"libarás",
"libarão",
"libaste",
"libavas",
"libelai",
"libelam",
"libelas",
"libelei",
"libelem",
"libeles",
"libelou",
"libitas",
"libolas",
"libonga",
"libramo",
"libreia",
"libreis",
"libremo",
"librine",
"libréus",
"libunas",
"libunos",
"libuzia",
"libável",
"libécio",
"libícia",
"libício",
"libítea",
"libônia",
"licaena",
"licanha",
"licatas",
"licaões",
"licetol",
"lichino",
"lichávi",
"liciina",
"licinas",
"licitai",
"licites",
"licnita",
"licnite",
"licnito",
"licodes",
"licoese",
"licoeze",
"licondo",
"liconos",
"liconte",
"licopse",
"licosas",
"licoses",
"licréus",
"lictora",
"licária",
"licídea",
"lidadão",
"lidagem",
"lidaire",
"lidairo",
"lidairá",
"lidarmo",
"lidimai",
"lidimam",
"lidimei",
"lidimem",
"lidimou",
"liditas",
"lidites",
"liditos",
"lidosas",
"lidrosa",
"lienais",
"lienase",
"lienosa",
"liernes",
"lifemia",
"lifemos",
"lifucos",
"lifutis",
"ligaida",
"ligaios",
"ligauna",
"ligauno",
"ligeida",
"liginas",
"ligiros",
"lignose",
"ligolas",
"ligulai",
"ligulam",
"ligulei",
"ligulem",
"ligules",
"ligulou",
"liguros",
"ligáeas",
"ligáeos",
"ligídea",
"ligídio",
"ligúlea",
"ligúleo",
"lilaias",
"lilaila",
"lilaseá",
"lileias",
"lilínea",
"lilíneo",
"limadão",
"limanas",
"limante",
"limaras",
"limarei",
"limarmo",
"limarão",
"limaças",
"limbado",
"limbali",
"limbera",
"limbéis",
"limemos",
"limiões",
"limneia",
"limnita",
"limnito",
"limoada",
"limoado",
"limoses",
"limpaça",
"limável",
"limídea",
"limídeo",
"limóbio",
"limônia",
"limônio",
"lincais",
"lincamo",
"linchai",
"linchei",
"lindais",
"lindamo",
"lindeis",
"lindemo",
"lineias",
"lineica",
"lineico",
"lineida",
"linetes",
"lineíta",
"linfada",
"linfado",
"linfais",
"linfamo",
"linfará",
"linfava",
"linfeis",
"linfemo",
"lingais",
"lingará",
"lingbia",
"lingota",
"lingoto",
"lingres",
"linguna",
"linhosa",
"lininas",
"linisco",
"linomas",
"linoses",
"linuras",
"lináloa",
"linífia",
"liobuno",
"lioceto",
"liolemo",
"lionoto",
"lionuro",
"liopica",
"liopico",
"liopoma",
"liornas",
"liornês",
"liotula",
"lipango",
"lipareu",
"lipates",
"lipatos",
"lipejas",
"lipeúro",
"lipinas",
"liposas",
"lipupos",
"liputos",
"lipíria",
"liraças",
"liricai",
"liricam",
"liricou",
"lirinas",
"lirinos",
"liriola",
"lirique",
"liriste",
"lirodos",
"liscais",
"liscamo",
"liscará",
"liscava",
"lisemia",
"liseres",
"lisilas",
"lismada",
"lismado",
"lismais",
"lismamo",
"lismava",
"lismeis",
"lismemo",
"lispras",
"lisquei",
"lisquem",
"lisques",
"listais",
"listemo",
"listrei",
"listrem",
"lisuros",
"lisável",
"lisíaca",
"litadão",
"litanto",
"litarei",
"litarmo",
"litarás",
"litasse",
"litaste",
"litavam",
"litavas",
"litemos",
"liticai",
"liticam",
"liticar",
"liticou",
"litigai",
"litigas",
"litigia",
"litigie",
"litinas",
"litites",
"litodes",
"litoica",
"litoico",
"litonde",
"litopes",
"litoses",
"litreia",
"lituada",
"lituado",
"lituria",
"litélio",
"litídea",
"litídeo",
"litínea",
"litíneo",
"litíopa",
"litórea",
"litósia",
"litótis",
"litúola",
"liurana",
"liurano",
"livelai",
"livelam",
"livelas",
"livelei",
"livelem",
"liveles",
"livelou",
"livetas",
"lividai",
"lividam",
"lividar",
"lividei",
"lividem",
"livides",
"lividou",
"livonas",
"lixoses",
"lixívio",
"lizadão",
"lizarei",
"lizarmo",
"lizarás",
"lizarão",
"lizaste",
"lizavas",
"lizelas",
"lizusas",
"liáculo",
"liácuti",
"liágora",
"liágoro",
"liáreis",
"liásica",
"liçonas",
"liênica",
"liênico",
"lióbata",
"liócoma",
"liódida",
"liólise",
"liópila",
"liópilo",
"lióplax",
"lióporo",
"lióteas",
"lióteos",
"liótias",
"liótrix",
"liômera",
"liômero",
"liômeso",
"liônias",
"liônica",
"liônico",
"liônsia",
"loadona",
"loadora",
"loadões",
"loalina",
"loardes",
"loareis",
"loaremo",
"loariam",
"loarias",
"loarmos",
"loassem",
"loasses",
"loastes",
"loaçona",
"loações",
"lobegão",
"lobenas",
"lobenos",
"lobetos",
"lobezas",
"lobidos",
"lobinas",
"lobites",
"lobolai",
"lobolam",
"lobolas",
"lobolei",
"lobolem",
"loboles",
"lobolou",
"lobonas",
"lobunas",
"lobália",
"lobária",
"locadão",
"locafas",
"locagos",
"locaina",
"locarmo",
"locavas",
"locelos",
"lociona",
"locione",
"lociono",
"locmeia",
"locnera",
"loconde",
"locondi",
"loctais",
"locundo",
"locuris",
"lodenta",
"loendas",
"loesses",
"loestes",
"lofinas",
"lofoeto",
"lofomas",
"lofotos",
"lofuras",
"lofuros",
"lofírea",
"lofótis",
"logarmo",
"logarão",
"logaste",
"logavas",
"logicai",
"logicou",
"logorra",
"logorre",
"logorro",
"logremo",
"logrões",
"logânea",
"logâneo",
"loimias",
"loiramo",
"loirará",
"loirava",
"loirece",
"loireis",
"loireja",
"loireje",
"loirela",
"loiremo",
"loireça",
"loireço",
"loisada",
"loisado",
"loisais",
"loisamo",
"loisará",
"loisava",
"loiseis",
"loisemo",
"loisões",
"lojeira",
"lojeiro",
"lolanas",
"lolioto",
"lomanco",
"lomanis",
"lombeai",
"lombeei",
"lombeia",
"lombeie",
"lombeio",
"lombeou",
"lombiai",
"lombiam",
"lombiar",
"lombias",
"lombiei",
"lombiem",
"lombies",
"lombiou",
"lomedro",
"loncuro",
"londavo",
"londobo",
"londreá",
"londuns",
"londões",
"lonqueá",
"lontros",
"lopadas",
"lopados",
"lopanas",
"lopanos",
"lopésia",
"lopézia",
"loqueis",
"loquemo",
"loraças",
"loraços",
"lorcada",
"lorcado",
"lorcais",
"lorcamo",
"lorcara",
"lorcava",
"lordada",
"lordado",
"lordais",
"lordamo",
"lordara",
"lordava",
"lordeis",
"lordemo",
"lordoma",
"loreias",
"lorgada",
"lorgado",
"lorgais",
"lorgará",
"lorgava",
"lorguei",
"lorguem",
"lorióis",
"lormana",
"lormano",
"loronga",
"lorongo",
"lorotai",
"lorotam",
"lorotei",
"lorotem",
"lorotes",
"lorquei",
"lorquem",
"lorques",
"lorária",
"lorídea",
"lorídeo",
"losaica",
"losaico",
"losismo",
"lossapo",
"lotante",
"lotares",
"lotarmo",
"lotases",
"lotaste",
"lotavas",
"loteais",
"loteamo",
"loteeis",
"loteemo",
"loteies",
"lotejai",
"lotejam",
"lotejar",
"lotejas",
"lotejei",
"lotejem",
"lotejes",
"lotejou",
"lotelas",
"lotoide",
"lotrita",
"lotrite",
"lotínea",
"lotíneo",
"loudéis",
"louqueá",
"louramo",
"lourará",
"lourava",
"loureis",
"loureje",
"louremo",
"loureça",
"lourões",
"lousais",
"lousamo",
"lousará",
"lousava",
"louseis",
"lousemo",
"lousiar",
"loutiás",
"lovales",
"lovaíta",
"lovênia",
"loxodes",
"lozarra",
"loáseas",
"loáseos",
"loáveis",
"loãozão",
"loçonas",
"loíases",
"lualuás",
"luangas",
"luangos",
"luareja",
"luareje",
"luarejo",
"luarizo",
"lubanga",
"lubanol",
"lubenas",
"lubiena",
"lubieno",
"lubires",
"lubitos",
"lubobas",
"lubobos",
"lubongo",
"lubunza",
"lubínia",
"lucaias",
"lucanda",
"lucange",
"lucango",
"lucasso",
"lucatas",
"lucaços",
"lucejai",
"lucejam",
"lucejar",
"lucejas",
"lucejei",
"lucejem",
"lucejes",
"lucejou",
"lucenos",
"luceque",
"lucerno",
"luchada",
"luchais",
"luchamo",
"lucheis",
"luchemo",
"luchosa",
"luchoso",
"lucidai",
"lucidam",
"lucidei",
"lucidol",
"lucilai",
"lucilas",
"luciles",
"lucinas",
"lucites",
"lucmões",
"luconjo",
"lucramo",
"lucreis",
"lucremo",
"lucrina",
"lucrino",
"lucubre",
"lucumas",
"lucumão",
"lucínea",
"lucíneo",
"lucíolo",
"lucúlea",
"lucúlia",
"lucúlio",
"lucúmbi",
"ludiano",
"ludinho",
"ludrosa",
"ludóvia",
"luengas",
"lueques",
"luetina",
"lufador",
"lufados",
"lufadão",
"lufamos",
"lufante",
"lufaram",
"lufaras",
"lufarei",
"lufarem",
"lufarmo",
"lufasse",
"lufaste",
"lufavam",
"lufavas",
"lufemos",
"lufense",
"luficas",
"lufumas",
"lugadas",
"lugadão",
"lugarei",
"lugaria",
"lugasse",
"lugaste",
"lugavam",
"lugavas",
"lugisos",
"lugueis",
"luibaba",
"luigita",
"luiidea",
"luiideo",
"luiidia",
"luisite",
"luitais",
"luiteis",
"lujanja",
"lulongo",
"lululas",
"lulundo",
"lumaica",
"lumaico",
"lumarão",
"lumbuda",
"lumbudo",
"lumeira",
"lumetos",
"lumiaco",
"lumiais",
"lumiamo",
"lumiará",
"lumieis",
"lumiemo",
"lumilho",
"lumuíno",
"lumínea",
"lumíneo",
"lungula",
"lungule",
"lungulo",
"lunidas",
"lunitas",
"lunites",
"lunnita",
"lunulai",
"lunulam",
"lunulei",
"lunulem",
"lunules",
"lunulou",
"lunzete",
"lunânia",
"lupador",
"lupados",
"lupadão",
"lupamos",
"lupanai",
"lupanam",
"lupanas",
"lupando",
"lupanei",
"lupanem",
"lupanes",
"lupango",
"lupanou",
"lupante",
"luparam",
"luparei",
"luparem",
"lupares",
"luparmo",
"luparol",
"luparão",
"lupasse",
"lupaste",
"lupatos",
"lupavam",
"lupavas",
"lupaças",
"lupemos",
"lupeoal",
"lupeona",
"lupeose",
"luperca",
"luperos",
"lupetas",
"lupeóis",
"lupomas",
"luposas",
"luposos",
"lupossa",
"lupoças",
"lupuiés",
"lupumbo",
"luquelo",
"luradão",
"lurante",
"lurarei",
"lurarmo",
"lurarás",
"lurarão",
"lurasse",
"luraste",
"luravas",
"lurdona",
"luremos",
"luridez",
"lururas",
"lusango",
"luscada",
"luscado",
"luscais",
"luscamo",
"luscará",
"luscava",
"lusmais",
"lusmamo",
"lusmiam",
"lusmias",
"lusmida",
"lusmido",
"lusmimo",
"lusquam",
"lusquas",
"lusquei",
"lusquem",
"lusques",
"lusquia",
"lusquis",
"lusquiu",
"lustrex",
"lustrim",
"lusônia",
"lusônio",
"lutadão",
"lutarmo",
"lutação",
"luteóis",
"lutiazi",
"lutisse",
"lutjana",
"lutjano",
"lutocas",
"lutombe",
"lutosas",
"lutrida",
"lutrido",
"lutrina",
"lutrino",
"lutumbo",
"lutário",
"lutável",
"lutério",
"luuiuís",
"luvares",
"luvetes",
"luvonas",
"luxador",
"luxadão",
"luxamos",
"luxante",
"luxarei",
"luxarem",
"luxares",
"luxarmo",
"luxarás",
"luxarão",
"luxaste",
"luxavas",
"luxazes",
"luxemos",
"luxetas",
"luxibas",
"luxurie",
"luxázis",
"luzamos",
"luzarda",
"luzarra",
"luzencu",
"luzença",
"luzicas",
"luzicus",
"luzidor",
"luzidão",
"luziluz",
"luziras",
"luzirei",
"luzirmo",
"luziste",
"luzulas",
"luzíeis",
"luzíola",
"luárias",
"luários",
"luídias",
"luíidas",
"luísias",
"lábdana",
"lábiuns",
"lábrace",
"lábrida",
"lábruns",
"lácases",
"láceros",
"lácicas",
"lácicos",
"lácifer",
"lácmida",
"lácneas",
"ládanos",
"láfrias",
"láganas",
"láganos",
"lápicas",
"lápicos",
"lápitos",
"láqueas",
"láqueos",
"láquese",
"lárrida",
"látebra",
"látipes",
"látiros",
"látmica",
"látmico",
"látreas",
"láudana",
"láurias",
"lâmanes",
"lâmedes",
"lâmnida",
"lâmpica",
"lâmpico",
"lâmpide",
"lâmpios",
"lâmpria",
"lâmpris",
"lâmpãos",
"lângias",
"lântzia",
"lânvios",
"lécitis",
"légbeas",
"légbeos",
"lélapes",
"lépades",
"lépalas",
"lépalos",
"lépomis",
"lépride",
"léptica",
"léptida",
"léptopo",
"lérneos",
"lésguia",
"lésguio",
"lésmias",
"lésquea",
"lésquio",
"lézaras",
"lézaros",
"lêidias",
"lêmneas",
"lêmneos",
"lênnoas",
"lêucica",
"lêuzeas",
"líbitos",
"lícnica",
"lícnico",
"lícodon",
"lícopos",
"líctida",
"lígneas",
"lígulos",
"límbeas",
"límbeos",
"límbias",
"límbios",
"límicas",
"límidas",
"límidos",
"límneas",
"límnias",
"límnios",
"línceas",
"línceos",
"língéis",
"línquia",
"línteas",
"líntias",
"lípares",
"líparos",
"lípicas",
"líppias",
"lístrio",
"líteras",
"lítidas",
"lítreas",
"lítrias",
"lítseas",
"lívicas",
"lívicos",
"lóbiger",
"lóbodon",
"lóbonix",
"lócmias",
"lócmios",
"lócrias",
"lófiras",
"lófiros",
"lófopos",
"lóligos",
"lórdops",
"lórulos",
"lósbias",
"lóxodon",
"lúceras",
"lúceros",
"lúcilos",
"lúdicra",
"lúndias",
"lúparos",
"lúpuros",
"lúrtias",
"lúsmeas",
"lúsmeos",
"lútaros",
"maalebe",
"maambas",
"maamude",
"maamúdi",
"maatmas",
"maavela",
"mabaias",
"mabalas",
"mabaloi",
"mabanje",
"mabatas",
"mabates",
"mabazas",
"mabação",
"mabeias",
"mabense",
"mabilas",
"mabiles",
"mabitas",
"mabocas",
"maboias",
"mabolas",
"maboles",
"mabolos",
"mabouiá",
"maboulá",
"mabubas",
"mabucos",
"mabudas",
"mabulás",
"mabunos",
"mabures",
"mabutas",
"mabírri",
"macacai",
"macacam",
"macacou",
"macaias",
"macajás",
"macalas",
"macalos",
"macamau",
"macanja",
"macasso",
"macatas",
"macauãs",
"macaxás",
"macaxós",
"macazas",
"macaíbo",
"macaíta",
"maceces",
"macemos",
"macesse",
"macetai",
"macetei",
"macetem",
"maceteá",
"machade",
"macheai",
"macheei",
"macheio",
"machiai",
"machiam",
"machica",
"machiei",
"machiem",
"machies",
"machiou",
"machoas",
"machobo",
"machoca",
"machuna",
"maciamo",
"maciava",
"macidra",
"maciemo",
"macimba",
"macinas",
"macioso",
"macleia",
"macobas",
"macocas",
"macocoa",
"macofus",
"macoias",
"macoice",
"macolas",
"macolos",
"macomas",
"maconas",
"macones",
"maconta",
"macopos",
"macorão",
"macorãs",
"macoteá",
"macoutá",
"macovio",
"macozas",
"macozos",
"macrais",
"macrone",
"macrose",
"macruus",
"macrões",
"mactima",
"mactras",
"macuara",
"macubar",
"macucai",
"macucam",
"macucas",
"macucou",
"macuini",
"macuins",
"macujés",
"maculai",
"macunas",
"macunda",
"macungá",
"macunis",
"macupis",
"macuque",
"macurás",
"macuves",
"macális",
"macédio",
"macópio",
"macóvia",
"macóxis",
"macúbio",
"macúmbi",
"macútis",
"madalém",
"madeixe",
"madeixo",
"madgiai",
"madgiam",
"madgias",
"madgiei",
"madgiem",
"madgies",
"madgiou",
"madiina",
"madijás",
"madimbo",
"madiobo",
"madjada",
"madorne",
"madorno",
"madorre",
"madorro",
"madrafã",
"madraga",
"madrias",
"maducas",
"madóqua",
"maengas",
"maenjes",
"maeruna",
"mafabés",
"mafafas",
"mafalis",
"mafanas",
"mafiais",
"mafiamo",
"mafiara",
"mafiava",
"mafieis",
"mafiemo",
"mafires",
"mafitas",
"mafites",
"mafiões",
"mafomes",
"mafucas",
"mafucos",
"mafumas",
"mafumba",
"mafumos",
"mafunge",
"mafunji",
"mafuras",
"mafutas",
"mafutra",
"magacia",
"maganaz",
"maganeá",
"maganja",
"magareb",
"magaris",
"magatôs",
"magaças",
"magedas",
"magelos",
"magiamo",
"magiara",
"magicai",
"magieis",
"magiemo",
"magilos",
"maginai",
"magines",
"magnita",
"magnole",
"magocha",
"magodos",
"magoemo",
"magondi",
"magruça",
"magréns",
"magudas",
"magudos",
"magueis",
"magujos",
"magulhe",
"magulho",
"magália",
"magônia",
"mahônia",
"maiacás",
"maiadas",
"maiadim",
"maiados",
"maiadão",
"maiaias",
"maiamos",
"maianas",
"maianos",
"maiante",
"maiaram",
"maiarei",
"maiarem",
"maiarmo",
"maiarás",
"maiarão",
"maiasse",
"maiaste",
"maiavam",
"maiavas",
"maiaíta",
"maidins",
"maidona",
"maidões",
"maielas",
"maiemos",
"maigaça",
"maiinea",
"maiineo",
"maimbus",
"maindos",
"mainira",
"mainota",
"mainças",
"maiobas",
"maiobos",
"maiocas",
"maiolos",
"maiosia",
"maiotas",
"maiotos",
"mairelo",
"maisais",
"maisqui",
"majambo",
"majerós",
"majorai",
"majovos",
"majugas",
"majídea",
"malabos",
"malacra",
"malador",
"maladão",
"malafas",
"malafos",
"malagom",
"malagou",
"malalas",
"malamos",
"malante",
"malarei",
"malarem",
"malarmo",
"malarás",
"malarão",
"malasse",
"malaste",
"malauis",
"malavam",
"malavim",
"malavir",
"malavos",
"malavra",
"malavém",
"malaxai",
"malaxam",
"malaxas",
"malaxei",
"malaxem",
"malaxes",
"malaxou",
"malcoza",
"malcozi",
"malcozo",
"malcozê",
"maldais",
"maldamo",
"maldana",
"maldano",
"maldará",
"maldeis",
"maldemo",
"maleais",
"maleará",
"maleava",
"maledos",
"maleeis",
"maleiam",
"maleias",
"maleiem",
"maleies",
"malejos",
"malembo",
"maleílo",
"maleíno",
"malfade",
"malfará",
"malfaze",
"malfaça",
"malfaço",
"malfetu",
"malfiro",
"malgaxa",
"malhamo",
"malhana",
"malheis",
"malhemo",
"malheto",
"malhios",
"malidos",
"malilis",
"malilos",
"malinai",
"malinei",
"malinem",
"malinus",
"malleis",
"malocai",
"maloias",
"malojas",
"malombe",
"malopas",
"maloras",
"malosas",
"malotos",
"malparo",
"malrege",
"malregi",
"malreja",
"malrejo",
"malsões",
"maltais",
"maltamo",
"maltará",
"maltava",
"malteis",
"maltemo",
"malteno",
"maltodo",
"maltoma",
"maluata",
"malucai",
"maludas",
"malufos",
"maluias",
"malumes",
"malundo",
"maluros",
"malutas",
"maluvos",
"malvais",
"malveze",
"malvezo",
"malvezá",
"maláris",
"malânea",
"malécia",
"malíolo",
"malópea",
"mamaene",
"mamaeno",
"mamantã",
"mamanês",
"mamanôs",
"mamarei",
"mamarmo",
"mamatar",
"mambala",
"mambari",
"mambaré",
"mambata",
"mambuco",
"mambude",
"mambúti",
"mamemos",
"mamengá",
"mamense",
"mamirás",
"mamixis",
"mamocos",
"mamoona",
"mamotas",
"mamotos",
"mampada",
"mampado",
"mampais",
"mampamo",
"mampava",
"mampeis",
"mampemo",
"mampofa",
"mamudos",
"mamujai",
"mamujam",
"mamujas",
"mamujei",
"mamujem",
"mamujes",
"mamujou",
"mamunas",
"mamuona",
"mamuris",
"manacho",
"manadia",
"manador",
"manadão",
"managés",
"manaixo",
"manaiés",
"manajás",
"manajés",
"manajós",
"manalha",
"manalva",
"mananau",
"manares",
"manarmo",
"manarás",
"manaste",
"manatos",
"manavas",
"manaxos",
"mancamo",
"mancheá",
"manchis",
"manchós",
"mancipe",
"mancipo",
"mancipá",
"mancoia",
"mancuba",
"manculo",
"mandaus",
"mandias",
"mandibi",
"mandiis",
"mandjoa",
"mandjua",
"mandobo",
"mandrie",
"mandros",
"mandrão",
"manduas",
"maneais",
"maneamo",
"maneara",
"manecar",
"maneeis",
"maneemo",
"manegas",
"manegos",
"maneiem",
"maneies",
"manejai",
"manelas",
"manemos",
"maneteá",
"manfafa",
"manfuís",
"mangabe",
"mangabo",
"mangaca",
"mangari",
"mangavi",
"mangbás",
"mangoal",
"mangoia",
"mangoio",
"mangolo",
"mangono",
"mangora",
"mangrai",
"mangrei",
"mangrem",
"mangres",
"mangulo",
"manguna",
"mangune",
"manguçu",
"manhema",
"manhose",
"maniate",
"maniato",
"manibas",
"manices",
"manidas",
"manidja",
"manieto",
"maniiba",
"manilhe",
"manilho",
"manimba",
"manindi",
"manindo",
"maninga",
"maninhe",
"maniosa",
"manioso",
"maniote",
"manipos",
"maniram",
"manirei",
"manirem",
"manires",
"maniria",
"manirmo",
"manirão",
"manisse",
"manites",
"manixis",
"maniúva",
"manjais",
"manjamo",
"manjata",
"manjeis",
"manjemo",
"manjica",
"manjobo",
"manjovo",
"manjual",
"manjuca",
"manocai",
"manocam",
"manocou",
"manoios",
"manopés",
"manoteá",
"manqueá",
"mansope",
"mansupa",
"mansupi",
"mansupo",
"mansura",
"mantamo",
"manteai",
"manteie",
"mantipo",
"mantosa",
"mantura",
"mantóis",
"manubas",
"manumas",
"manungo",
"manutam",
"manutas",
"manutem",
"manutes",
"manutia",
"manutir",
"manutis",
"manutiu",
"manvios",
"manzega",
"mançope",
"mançupa",
"mançupi",
"mançupo",
"manétia",
"manídea",
"maníeis",
"manínea",
"maníneo",
"manúlea",
"maombes",
"maonhes",
"maparis",
"maparés",
"mapatis",
"mapeais",
"mapeamo",
"mapeeis",
"mapeemo",
"mapeies",
"mapeque",
"mapiada",
"mapiais",
"mapiamo",
"mapiara",
"mapicas",
"mapicos",
"mapidiã",
"mapieis",
"mapiemo",
"mapiras",
"mapixis",
"mapoiós",
"mapoles",
"maponas",
"maponga",
"mapotes",
"mapoões",
"mapsele",
"mapucas",
"mapulas",
"mapunda",
"mapupas",
"mapurás",
"mapânia",
"maquefe",
"maqueje",
"maquiai",
"maquidi",
"maquies",
"maquixa",
"maquixo",
"marabôs",
"maraces",
"maracus",
"maradão",
"marafai",
"marafam",
"marafas",
"marafei",
"marafem",
"marafes",
"marafou",
"maragas",
"maraiás",
"marapás",
"marapés",
"mararei",
"marares",
"mararia",
"mararmo",
"mararás",
"mararão",
"marasse",
"maraste",
"marauás",
"maravas",
"maraúba",
"marcaia",
"marcegã",
"marceje",
"marcejo",
"mardano",
"marduna",
"marduno",
"mareará",
"marecas",
"mareeis",
"mareies",
"marejai",
"marejes",
"marelão",
"mareota",
"marfada",
"marfais",
"marfamo",
"marfeis",
"marfemo",
"margada",
"margais",
"margamo",
"margavá",
"margeai",
"margiam",
"margias",
"margida",
"margido",
"margimo",
"margios",
"margirá",
"margião",
"margoto",
"marguei",
"maribis",
"maridai",
"maridei",
"maridem",
"marides",
"marinim",
"marinôs",
"mariole",
"mariolo",
"maripus",
"maripás",
"maritas",
"marites",
"marjões",
"marlote",
"marloto",
"marmado",
"marmamo",
"marmava",
"marmaça",
"marmeis",
"marmele",
"marmemo",
"marneco",
"marnoia",
"marnosa",
"marnoso",
"marobas",
"marombe",
"marombo",
"maromes",
"maronda",
"maropas",
"maroteá",
"marouba",
"maroubo",
"maroufo",
"marouça",
"marovas",
"marramo",
"marrata",
"marraús",
"marreis",
"marremo",
"marrico",
"marricá",
"marriés",
"marroai",
"marroei",
"marroem",
"marroou",
"marrucá",
"marruim",
"martabã",
"martite",
"martuco",
"martéis",
"marubés",
"maruceu",
"marufos",
"maruges",
"marujai",
"marujal",
"marujam",
"marujem",
"marujes",
"marujou",
"marulhe",
"marulos",
"marumba",
"marumos",
"marupás",
"marurus",
"marzias",
"marzões",
"marátia",
"marçana",
"masacás",
"masangu",
"masaras",
"masares",
"masasis",
"masatas",
"masatos",
"masaíta",
"mascabá",
"mascamo",
"mascave",
"masdeia",
"masdeus",
"maseias",
"masongo",
"masoreu",
"masquir",
"masrita",
"masrite",
"massamo",
"massaus",
"masseis",
"massila",
"massuis",
"mastreá",
"mastuca",
"mastuco",
"masápio",
"masórea",
"masóreo",
"matades",
"matadão",
"matapás",
"mataris",
"matarus",
"mataíba",
"mateara",
"matebas",
"mateeis",
"mateiem",
"mateies",
"matejai",
"matejam",
"matejas",
"matejei",
"matejem",
"matejes",
"matejou",
"matelés",
"matenda",
"matetes",
"matevas",
"matiaru",
"maticai",
"maticam",
"maticou",
"matilhe",
"matinca",
"matinei",
"matinem",
"matipuí",
"matiras",
"matiris",
"matitas",
"matizai",
"matodis",
"matombe",
"matomos",
"matonia",
"matopes",
"matrais",
"matraxi",
"matriai",
"matriam",
"matriar",
"matriei",
"matriem",
"matries",
"matriou",
"matrizo",
"matroco",
"matruás",
"matsavo",
"matucas",
"matuiús",
"matulaz",
"matumas",
"matumos",
"maturai",
"maturim",
"matutai",
"matutis",
"matíaca",
"matíaco",
"matísia",
"mauacos",
"mauaiás",
"mauauas",
"mauaçus",
"mauezas",
"maufita",
"mauindo",
"maundos",
"maungos",
"mavembe",
"mavevés",
"mavitis",
"mavumba",
"mavuvis",
"maxacas",
"maxamba",
"maxanas",
"maxapós",
"maxaxas",
"maxenga",
"maxenim",
"maxesas",
"maxibua",
"maximbo",
"maxinga",
"maxinje",
"maxiras",
"maxitas",
"maxites",
"maxixai",
"maxixas",
"maxixei",
"maxixem",
"maxonas",
"maxongo",
"maxotos",
"maxuaís",
"maxubis",
"maxumba",
"maxuxos",
"maxíxia",
"mazaera",
"mazamas",
"mazambo",
"mazanes",
"mazanze",
"mazanzo",
"mazares",
"mazeias",
"mazelai",
"mazelam",
"mazelei",
"mazelem",
"mazeles",
"mazelou",
"mazenze",
"mazeras",
"mazinda",
"mazonga",
"mazundo",
"mazurco",
"mazutes",
"mazzita",
"maçadão",
"maçango",
"maçaste",
"maçavas",
"maçolar",
"maçonge",
"maçorro",
"maídeas",
"maônias",
"maúreas",
"mbatará",
"mccciii",
"mccclii",
"mcccliv",
"mccclix",
"mccclvi",
"mccclxi",
"mccclxv",
"mccclxx",
"mcccvii",
"mcccxci",
"mcccxcv",
"mcccxii",
"mcccxiv",
"mcccxix",
"mcccxli",
"mcccxlv",
"mcccxvi",
"mcccxxi",
"mcccxxv",
"mcccxxx",
"mccliii",
"mcclvii",
"mcclxii",
"mcclxiv",
"mcclxix",
"mcclxvi",
"mcclxxi",
"mcclxxv",
"mcclxxx",
"mccviii",
"mccxcii",
"mccxciv",
"mccxcix",
"mccxcvi",
"mccxiii",
"mccxlii",
"mccxliv",
"mccxlix",
"mccxlvi",
"mccxvii",
"mccxxii",
"mccxxiv",
"mccxxix",
"mccxxvi",
"mccxxxi",
"mccxxxv",
"mcdliii",
"mcdlvii",
"mcdlxii",
"mcdlxiv",
"mcdlxix",
"mcdlxvi",
"mcdlxxi",
"mcdlxxv",
"mcdlxxx",
"mcdviii",
"mcdxcii",
"mcdxciv",
"mcdxcix",
"mcdxcvi",
"mcdxiii",
"mcdxlii",
"mcdxlix",
"mcdxlvi",
"mcdxvii",
"mcdxxii",
"mcdxxiv",
"mcdxxix",
"mcdxxvi",
"mcdxxxi",
"mcdxxxv",
"mclviii",
"mclxiii",
"mclxvii",
"mclxxii",
"mclxxiv",
"mclxxix",
"mclxxvi",
"mclxxxi",
"mclxxxv",
"mcmlvii",
"mcmlxvi",
"mcmviii",
"mcmxxvi",
"mcxciii",
"mcxcvii",
"mcxliii",
"mcxlvii",
"mcxviii",
"mcxxiii",
"mcxxvii",
"mcxxxii",
"mcxxxiv",
"mcxxxix",
"mcxxxvi",
"mdcccii",
"mdccclx",
"mdcccxl",
"mdccliv",
"mdcclxx",
"mdccvii",
"mdccxci",
"mdccxcv",
"mdccxii",
"mdccxiv",
"mdccxix",
"mdccxli",
"mdccxvi",
"mdccxxi",
"mdccxxv",
"mdcliii",
"mdclvii",
"mdclxii",
"mdclxiv",
"mdclxix",
"mdclxxi",
"mdclxxv",
"mdclxxx",
"mdcxcii",
"mdcxciv",
"mdcxcix",
"mdcxiii",
"mdcxlii",
"mdcxliv",
"mdcxlix",
"mdcxlvi",
"mdcxvii",
"mdcxxii",
"mdcxxiv",
"mdcxxix",
"mdcxxvi",
"mdcxxxv",
"mdlxvii",
"mdlxxiv",
"mdlxxvi",
"mdlxxxi",
"mdxliii",
"mdxlvii",
"mdxviii",
"mdxxvii",
"mdxxxii",
"mdxxxiv",
"mdxxxix",
"meadade",
"meadona",
"meadora",
"meadões",
"mealhai",
"mealham",
"mealhei",
"mealhem",
"mealhes",
"mealhos",
"mealhou",
"meambás",
"meardes",
"mearias",
"mearmos",
"meassem",
"measses",
"meastes",
"meatite",
"meaçona",
"mebacos",
"mebamba",
"mebanda",
"mebanga",
"mebauas",
"meberis",
"mebezua",
"mebilas",
"mebinze",
"mebombo",
"mebosas",
"mebotas",
"mebouas",
"mebucas",
"mebueia",
"mebufos",
"mebulas",
"mebungu",
"mebutas",
"mebuzes",
"mebádis",
"mebínji",
"mecaias",
"mecajus",
"mecates",
"meceira",
"mecenos",
"mechara",
"mechava",
"mechela",
"mechemo",
"mechuas",
"mecimbi",
"mecinas",
"mecinos",
"mecolas",
"mecotas",
"mecucos",
"mecuias",
"mecuris",
"mecurre",
"mecurro",
"mecóvia",
"medeies",
"medelas",
"medenta",
"medento",
"medieis",
"mediemo",
"medirmo",
"medocho",
"medoiço",
"medonto",
"medorro",
"medouço",
"medrais",
"medramo",
"medreis",
"medremo",
"medrios",
"medruzã",
"medunha",
"meduíço",
"medéola",
"medêmia",
"medícua",
"medúsea",
"mefinze",
"megalas",
"megarad",
"megaste",
"megohns",
"megonha",
"meguaia",
"megábia",
"megária",
"megátoo",
"meidado",
"meideji",
"meigada",
"meigado",
"meigais",
"meigará",
"meigava",
"meiguei",
"meiguem",
"meigues",
"meijões",
"meilhas",
"meimões",
"meinaca",
"meinins",
"meitade",
"meiéria",
"melafas",
"melagra",
"melalas",
"melames",
"melampo",
"melanas",
"melaras",
"melarmo",
"melarão",
"melavas",
"melecta",
"meleias",
"melelas",
"meleles",
"melemos",
"melenos",
"melessa",
"melesse",
"melesso",
"melgaça",
"melibeu",
"melidas",
"melidos",
"melimba",
"melinas",
"melines",
"melinos",
"melitas",
"melites",
"meliteu",
"melitia",
"melmosa",
"melojes",
"melonge",
"melopes",
"melopos",
"melosal",
"meloses",
"melotas",
"melotes",
"melrões",
"melungo",
"melurso",
"melusas",
"melzina",
"melzões",
"melâmio",
"melânea",
"melânio",
"memulês",
"menabés",
"menadas",
"menador",
"menados",
"menadão",
"menaica",
"menamos",
"menando",
"menante",
"menaram",
"menarei",
"menarem",
"menares",
"menaria",
"menarmo",
"menarás",
"menarão",
"menasse",
"menaste",
"menavam",
"menavas",
"mendaca",
"mendaco",
"mendoso",
"menduís",
"mendões",
"meneamo",
"menebas",
"meneeis",
"meneemo",
"meneiem",
"menemos",
"menenua",
"menepes",
"menequi",
"menesas",
"menesto",
"mengais",
"mengava",
"menguei",
"menguem",
"mengues",
"menilho",
"menista",
"mentamo",
"mentana",
"mentemo",
"menténs",
"menuras",
"menuros",
"menuzis",
"menzada",
"menália",
"menápia",
"mençona",
"menévia",
"menênia",
"menênio",
"menídea",
"menídeo",
"meolhas",
"mepafus",
"mepenje",
"meperas",
"mepetes",
"mepezas",
"mepinge",
"mepingo",
"mepives",
"meporis",
"mepossa",
"mepotos",
"mepunga",
"mepuris",
"mepurus",
"merarós",
"meraças",
"meraços",
"mercamo",
"mercana",
"mercaço",
"merceai",
"mercear",
"merceei",
"merceie",
"merceio",
"merceou",
"mercuzã",
"merejai",
"merejas",
"merejei",
"merejem",
"merejes",
"merejou",
"merepes",
"mererés",
"mereuás",
"mergada",
"mergais",
"mergará",
"mergava",
"merguei",
"merguem",
"mergues",
"meriano",
"merinde",
"meringe",
"merioba",
"merista",
"meristo",
"mermais",
"mermamo",
"mermara",
"mermeis",
"mermemo",
"mermite",
"meroena",
"meroeno",
"merolho",
"meronas",
"merongo",
"meropes",
"merotes",
"merougo",
"merrine",
"merrumi",
"merucás",
"merufos",
"merujai",
"merujam",
"merujei",
"merujem",
"merujos",
"merujou",
"merunga",
"merungo",
"merunhe",
"merunho",
"merunhá",
"mesagra",
"mesamão",
"mesanga",
"mesango",
"mesarca",
"mesaula",
"mesaulo",
"mescabe",
"mescabo",
"mescabá",
"mescamo",
"mescará",
"mescava",
"mesclai",
"mescões",
"meselas",
"meselos",
"mesenha",
"mesiate",
"mesinga",
"mesitol",
"mesnado",
"mesocra",
"mesodos",
"mesoide",
"mesopes",
"mesopse",
"mesoris",
"mesosas",
"mesquei",
"mesquem",
"mesques",
"messado",
"messamo",
"messava",
"messefo",
"messiro",
"messoro",
"mesteis",
"mesterá",
"mestiam",
"mestida",
"mestido",
"mesuada",
"mesurai",
"mesurei",
"mesurem",
"mesusus",
"mesália",
"mesômio",
"mesônio",
"metalei",
"metanas",
"metanes",
"metaras",
"metargo",
"metaste",
"metasto",
"metenas",
"metetes",
"metiana",
"metibas",
"metidro",
"metilai",
"metilei",
"metiles",
"metinás",
"metiris",
"metocas",
"metocho",
"metombe",
"metopia",
"metoses",
"metoíta",
"metrais",
"metramo",
"metrará",
"metrava",
"metreco",
"metreis",
"metremo",
"metxina",
"metápio",
"metásia",
"metécio",
"metíeis",
"metólia",
"metória",
"metúbis",
"meudear",
"meulzão",
"mexates",
"mexedia",
"mexedio",
"mexefos",
"mexelha",
"mexelho",
"mexelim",
"mexidia",
"mexidio",
"mexilhe",
"mexilhá",
"mexinga",
"mexiras",
"mexires",
"mexição",
"mexudas",
"mexíeis",
"mezande",
"mezeias",
"mezinhe",
"mezíias",
"meáreis",
"meãozão",
"meãzona",
"meótica",
"meótico",
"meônias",
"meônida",
"mfúcuas",
"miadona",
"miadões",
"miagros",
"mialate",
"miambas",
"miamiás",
"miapias",
"miardes",
"miareis",
"miaremo",
"miarias",
"miasmai",
"miasmam",
"miasmar",
"miasmei",
"miasmem",
"miasmes",
"miasmou",
"miastes",
"miastor",
"miazais",
"miazina",
"miaçaba",
"miboras",
"micalex",
"micetas",
"micetes",
"michais",
"michana",
"michano",
"michará",
"michava",
"micheis",
"michemo",
"michosa",
"micipas",
"micocós",
"micolos",
"micoína",
"micrana",
"micrano",
"micreca",
"micrelo",
"micrina",
"microma",
"mictada",
"mictais",
"mictamo",
"mictará",
"mictava",
"micteis",
"mictemo",
"mictero",
"mictios",
"mictual",
"miculas",
"micunco",
"micurês",
"micária",
"micânia",
"micédio",
"micênea",
"micônia",
"micônio",
"midanes",
"mideias",
"mideira",
"mideiro",
"mideses",
"midinas",
"midinos",
"midróis",
"miedema",
"mielose",
"miembes",
"miemita",
"miemite",
"miengos",
"miesite",
"mifadol",
"migador",
"migadão",
"migalhe",
"migaras",
"migarei",
"migares",
"migaria",
"migarão",
"migasse",
"migaste",
"migavam",
"migavas",
"migdoas",
"migdões",
"miginda",
"migosas",
"migosos",
"migrais",
"migramo",
"migreis",
"migremo",
"migurno",
"miiagra",
"miiarco",
"miióbio",
"mijaras",
"mijarmo",
"mijinas",
"mijniha",
"mijolos",
"mijotes",
"mijubas",
"milaras",
"milaseu",
"milavos",
"milecia",
"milegrã",
"mileias",
"milenta",
"milento",
"miletes",
"milgrás",
"milhafo",
"milhana",
"milharó",
"milheai",
"milheei",
"milheia",
"milheie",
"milheio",
"milheou",
"milheus",
"milhéns",
"milimbo",
"milindó",
"milirém",
"miliúsa",
"milolas",
"milolós",
"milonas",
"milondo",
"milreus",
"milundo",
"milvulo",
"milásia",
"milásio",
"milétia",
"milíase",
"milíola",
"milíolo",
"mimante",
"mimaras",
"mimaças",
"mimbano",
"mimbuia",
"mimecos",
"mimicai",
"mimicou",
"mimálon",
"mimável",
"mimídea",
"minaces",
"minacus",
"minadão",
"minanço",
"minapes",
"minarei",
"minarmo",
"minaste",
"minavas",
"minches",
"mindjam",
"mindzãs",
"mineteá",
"mingará",
"minguai",
"minhafo",
"miniada",
"miniado",
"miniamo",
"miniará",
"miniava",
"minieis",
"miniemo",
"minimai",
"minimam",
"minimei",
"minimou",
"miniovo",
"minjolo",
"minorai",
"minotas",
"mintamo",
"mintóis",
"minuais",
"minucie",
"minudai",
"minudam",
"minudei",
"minudem",
"minudes",
"minudeá",
"minudou",
"minunga",
"minutai",
"minutem",
"minuíam",
"minuías",
"minções",
"miocoma",
"miodema",
"miodite",
"miodora",
"miofone",
"miolhas",
"miolosa",
"mioluda",
"miombas",
"mionema",
"miongas",
"miongos",
"mionite",
"mionose",
"miosota",
"miosote",
"miotina",
"miqueai",
"miqueei",
"miqueia",
"miqueie",
"miqueio",
"miquete",
"miradão",
"mirafra",
"miraias",
"miranas",
"miraobi",
"miratoá",
"miratuá",
"mirbane",
"mirdita",
"mirical",
"miricas",
"miricis",
"mirinas",
"mirixis",
"mirmica",
"mirmila",
"mirmosa",
"mirmóis",
"mironai",
"mironam",
"mironar",
"mironas",
"mironei",
"mironem",
"mironou",
"mirorós",
"mirosma",
"mirrais",
"mirramo",
"mirremo",
"mirróis",
"mirtosa",
"mirtóis",
"mirzada",
"mirábil",
"mirábis",
"mirícea",
"miríceo",
"mirídea",
"mirídio",
"mirínea",
"miríneo",
"mirólea",
"miróleo",
"misagas",
"miscada",
"miscado",
"miscais",
"miscamo",
"miscará",
"miscava",
"miscofo",
"miscrai",
"miscram",
"miscras",
"miscrei",
"miscrem",
"miscres",
"miscrou",
"misenas",
"miseram",
"miserei",
"miserem",
"miserou",
"misosas",
"misosos",
"misquei",
"misquem",
"misques",
"missada",
"missalo",
"missará",
"missava",
"missefo",
"missemo",
"missoco",
"missona",
"missure",
"mistate",
"mistito",
"mistóis",
"misídea",
"misória",
"mitamba",
"mitelas",
"mitetes",
"mitimno",
"mitiris",
"mitizai",
"mitizam",
"mitizas",
"mitizei",
"mitizem",
"mitizes",
"mitizou",
"mitonde",
"mitramo",
"mitrará",
"mitrava",
"mitreis",
"mitremo",
"mitreta",
"mitênia",
"mitília",
"miudeai",
"miudeei",
"miudeia",
"miudeie",
"miudeio",
"miudeou",
"miulzão",
"miungos",
"miunços",
"mixadão",
"mixante",
"mixares",
"mixarmo",
"mixarás",
"mixaste",
"mixavas",
"mixeira",
"mixetos",
"mixinho",
"mixinos",
"mixiras",
"mixitas",
"mixites",
"mixodes",
"mixoles",
"mixolos",
"mixonos",
"mixtões",
"mixutos",
"mixácio",
"mixídio",
"mizacrê",
"mizcais",
"mizefes",
"mizeias",
"mizenas",
"mizinas",
"mizines",
"mizitra",
"mizomia",
"miáceas",
"miáceos",
"miários",
"miáveis",
"miçalos",
"miélica",
"miélico",
"miélois",
"miérsia",
"mióbaro",
"miócama",
"miófila",
"miófilo",
"mióforo",
"miógale",
"miógeno",
"mióglia",
"miógona",
"mióloga",
"mióparo",
"miótono",
"miúdica",
"miúdico",
"mlxviii",
"mlxxiii",
"mlxxvii",
"mlxxxii",
"mlxxxiv",
"mlxxxix",
"mlxxxvi",
"mmlviii",
"mmlxiii",
"mmlxvii",
"mmlxxii",
"mmlxxiv",
"mmlxxix",
"mmlxxvi",
"mmlxxxi",
"mmlxxxv",
"mmxciii",
"mmxcvii",
"mmxliii",
"mmxlvii",
"mmxxiii",
"mmxxvii",
"mmxxxii",
"mmxxxiv",
"mmxxxix",
"mmxxxvi",
"mniaros",
"mnioide",
"mniopse",
"mniácea",
"mniáceo",
"mnêmias",
"moaicas",
"moalaca",
"moalzão",
"moambos",
"moandas",
"moandim",
"moandos",
"moangis",
"moanhas",
"moansas",
"moarbes",
"moarrão",
"moarrãs",
"moatras",
"mobatos",
"mobeles",
"mobicas",
"mobilai",
"mobilho",
"mobiros",
"mobolas",
"mocajás",
"mocanas",
"mocanca",
"mocante",
"mocarmo",
"mocassó",
"mocaíxe",
"moceada",
"moceado",
"moceais",
"moceará",
"moceava",
"mocedos",
"moceeis",
"moceiam",
"moceias",
"moceiem",
"moceies",
"moceira",
"mocelas",
"mocemos",
"mocetas",
"mocetes",
"mochais",
"mochamo",
"mochara",
"mochaus",
"mochava",
"mocheca",
"mocheis",
"mochela",
"mochemo",
"mochico",
"mochole",
"mocholi",
"mocholo",
"mochuda",
"mochões",
"mocicas",
"mociços",
"mocoiús",
"mocojés",
"mocomas",
"moconis",
"mocorós",
"mocosas",
"mocozeá",
"mocubas",
"mocudas",
"mocuguê",
"mocusos",
"mocúgio",
"modecas",
"modicai",
"modilha",
"modilhe",
"modorne",
"modorre",
"modotex",
"modubas",
"modulai",
"moeiras",
"moengos",
"moenzas",
"moerdes",
"moereis",
"moeremo",
"moeriam",
"moerias",
"moestai",
"moestam",
"moestas",
"moestei",
"moestem",
"moestou",
"moeçona",
"moeções",
"mofares",
"mofarmo",
"mofaste",
"mofavas",
"mofedas",
"mofedos",
"mofeira",
"mofeiro",
"mofemos",
"mofenda",
"mofendo",
"mofetis",
"mofofôs",
"mofosas",
"mofumba",
"mofumbe",
"mofumos",
"mofunde",
"mofundo",
"mofundá",
"mogegos",
"mogenga",
"mogenge",
"mogengo",
"mogicai",
"mogicam",
"mogicar",
"mogicas",
"mogicou",
"mogique",
"mogoris",
"mogrebi",
"moguina",
"mogáver",
"mogúlia",
"mohlana",
"moiador",
"moidona",
"moidões",
"moilele",
"moimbes",
"moinada",
"moinado",
"moinais",
"moinamo",
"moinará",
"moinava",
"moindos",
"moineis",
"moinemo",
"moinhai",
"moinham",
"moinhei",
"moinhem",
"moinhes",
"moinhou",
"moipira",
"moipuas",
"moirada",
"moirado",
"moiramo",
"moirará",
"moirava",
"moireis",
"moireje",
"moiremo",
"moirete",
"moiriza",
"moirize",
"moirizo",
"moitada",
"moitais",
"moitamo",
"moitava",
"moiteis",
"moitemo",
"moiçona",
"moições",
"mojadão",
"mojajas",
"mojajos",
"mojante",
"mojaram",
"mojarei",
"mojarem",
"mojares",
"mojaria",
"mojarmo",
"mojarão",
"mojasse",
"mojaste",
"mojavam",
"mojavas",
"mojemos",
"mojicai",
"mojicam",
"mojicou",
"mojojas",
"mojojos",
"mojolas",
"mojolos",
"moladas",
"moladôs",
"molamos",
"molaças",
"molaços",
"moldeai",
"moldeei",
"moldeia",
"moldeie",
"moldeio",
"moldemo",
"moldeou",
"moldina",
"moleada",
"moleais",
"moleará",
"moleava",
"molecai",
"molecam",
"molecou",
"moleeis",
"molegos",
"moleiam",
"moleias",
"moleiem",
"moleies",
"molejai",
"molejam",
"molejei",
"molejem",
"molejes",
"molejou",
"molembo",
"molense",
"molgada",
"molgado",
"molgais",
"molgará",
"molgava",
"molguei",
"molguem",
"molgues",
"molgueá",
"molheis",
"molhemo",
"molhida",
"molimas",
"molimes",
"molimos",
"molinam",
"molindo",
"molinei",
"molinem",
"molines",
"molinhe",
"molinou",
"molione",
"molipas",
"moliram",
"molirei",
"molirem",
"molires",
"moliria",
"molirmo",
"molirás",
"molirão",
"molisme",
"molismá",
"molisse",
"moliste",
"molites",
"molneta",
"molocas",
"molojes",
"mololas",
"molotro",
"moloços",
"molugos",
"molulos",
"molulus",
"moluras",
"moluros",
"molídea",
"molíeis",
"molísia",
"momadas",
"momador",
"momados",
"momadão",
"momamos",
"momando",
"momanás",
"momaram",
"momarei",
"momarem",
"momares",
"momarmo",
"momarás",
"momarão",
"momasse",
"momaste",
"momavam",
"momavas",
"momaxós",
"mombina",
"mombiús",
"mombucu",
"mombuta",
"mombuto",
"momeais",
"momeará",
"momeava",
"momeeis",
"momeiam",
"momeias",
"momeiem",
"momeies",
"momemos",
"momotas",
"momotos",
"monados",
"monailo",
"monamos",
"monasas",
"monaxós",
"moncado",
"moncamo",
"moncará",
"moncava",
"moncosa",
"mondais",
"mondamo",
"mondará",
"mondeis",
"mondemo",
"mondins",
"mondiás",
"monduís",
"mondéus",
"monecas",
"monecma",
"monecos",
"monedes",
"monerma",
"monesas",
"monesga",
"monesgo",
"monesos",
"monfera",
"monfere",
"monferi",
"monfero",
"mongeis",
"mongemo",
"mongerá",
"mongiam",
"mongias",
"mongida",
"mongido",
"mongoia",
"mongovo",
"monhiço",
"monidor",
"monidão",
"monilas",
"monilhe",
"monilho",
"monilos",
"monimas",
"monimos",
"moninas",
"monindo",
"moniram",
"moniras",
"monirei",
"monirem",
"monires",
"moniria",
"monirmo",
"moniros",
"monirão",
"monisse",
"moniste",
"monjais",
"monjamo",
"monjios",
"monoamo",
"monoato",
"monocas",
"monodie",
"monodio",
"monomas",
"monomes",
"mononte",
"monopsa",
"monoses",
"monotes",
"monoíla",
"monquei",
"monquem",
"monques",
"monteai",
"monteie",
"montemo",
"monteus",
"montoso",
"montujo",
"montuva",
"monuros",
"monvana",
"monápia",
"moníeis",
"monóbia",
"monóbio",
"monópis",
"monótoa",
"mopieçu",
"mopseia",
"moquamo",
"moqueai",
"moqueco",
"moqueei",
"moqueie",
"moqueis",
"moquemo",
"moquiam",
"moquias",
"moquido",
"moquimo",
"moquirá",
"moquite",
"moratas",
"moratos",
"morchal",
"morchão",
"morcuto",
"mordamo",
"mordaço",
"mordemo",
"mordexi",
"mordico",
"mordixi",
"mordofe",
"morejai",
"morejar",
"morejas",
"morejei",
"morejem",
"morejes",
"morejou",
"morenge",
"morerês",
"morexim",
"morfais",
"morfamo",
"morfará",
"morfemo",
"morfias",
"morfile",
"morfilo",
"morfilá",
"morfnos",
"morfóis",
"morfões",
"morgais",
"morgará",
"morgete",
"morguem",
"moriais",
"morilas",
"morilha",
"morilos",
"morinas",
"moritis",
"morivás",
"morlaca",
"mormada",
"mormado",
"mormiro",
"mormode",
"mormope",
"mormosa",
"mormões",
"mornamo",
"mornará",
"morneis",
"mornemo",
"mornita",
"mornite",
"morobas",
"morodas",
"moroixo",
"morones",
"mororês",
"morouxo",
"morquês",
"morruol",
"morsais",
"morsega",
"morseta",
"morucos",
"morundo",
"morunga",
"morungo",
"moruone",
"morurés",
"moruôni",
"morzela",
"moréeas",
"morétia",
"morínea",
"moríneo",
"morísia",
"mosanas",
"moscamo",
"moscope",
"moscosa",
"mosinos",
"mositas",
"mosites",
"moslemo",
"moslene",
"mosléns",
"mosquam",
"mosquas",
"mosqueá",
"mosquir",
"mosquis",
"mosquiu",
"mossado",
"mossais",
"mossamo",
"mossará",
"mossava",
"mosseco",
"mossecá",
"mossego",
"mossemo",
"mossina",
"mossino",
"mossita",
"mossite",
"mossuas",
"mossula",
"mossulo",
"mossués",
"mosséns",
"mostena",
"mosteno",
"mosélia",
"motacus",
"motacés",
"motanas",
"motanos",
"motavas",
"motazés",
"motejai",
"motejas",
"motejei",
"motejes",
"motelas",
"motenas",
"motenos",
"motetai",
"motetam",
"motetas",
"motetei",
"motetem",
"motetou",
"moteína",
"motilho",
"motinam",
"motinas",
"motinei",
"motinem",
"motiras",
"motobol",
"motocus",
"motomes",
"motondo",
"motrete",
"motuari",
"motucal",
"motucus",
"motulas",
"motungo",
"moucada",
"moucais",
"moucamo",
"moucará",
"moucava",
"mouchar",
"moufada",
"moufado",
"moufais",
"moufamo",
"moufará",
"moufava",
"moufeis",
"moufemo",
"moumada",
"moumado",
"moumais",
"moumamo",
"moumará",
"moumava",
"moumeis",
"moumemo",
"mounhas",
"mouquei",
"mouquem",
"mouques",
"mourado",
"mouramo",
"mourana",
"mourará",
"mourava",
"moureis",
"mouremo",
"mourita",
"mouriza",
"mourize",
"mourtes",
"moutais",
"moutulo",
"movença",
"movidão",
"moxadas",
"moxador",
"moxados",
"moxadão",
"moxamai",
"moxamam",
"moxamas",
"moxamei",
"moxamem",
"moxames",
"moxamos",
"moxamou",
"moxante",
"moxaram",
"moxarei",
"moxarem",
"moxares",
"moxaria",
"moxarmo",
"moxarás",
"moxarão",
"moxasse",
"moxaste",
"moxavam",
"moxavas",
"moxemos",
"moxolis",
"mozambo",
"mozenas",
"mozinas",
"moçafes",
"moçafos",
"moçalha",
"moçalho",
"moçames",
"moçaram",
"moçarei",
"moçarem",
"moçares",
"moçarão",
"moçasse",
"moçavas",
"moçaços",
"moíngis",
"moógono",
"muadiês",
"muadjir",
"mualaca",
"mualala",
"mualape",
"mualimo",
"muambos",
"muamuna",
"muanapó",
"muances",
"muancos",
"muandas",
"muandio",
"muandis",
"muandiú",
"muandji",
"muandos",
"muanges",
"muangos",
"muangue",
"muangui",
"muanhis",
"muanzas",
"muarrãs",
"muatulé",
"muavina",
"mubafos",
"mubambo",
"mubanga",
"mubanja",
"mubatas",
"mubebas",
"mubelas",
"mubicas",
"mubilus",
"mubires",
"mubonas",
"mubongo",
"mubonos",
"mubotas",
"mubulas",
"mubumbo",
"mubunda",
"mubungu",
"mucaade",
"mucacas",
"mucages",
"mucaino",
"mucaiás",
"mucajes",
"mucalas",
"mucalis",
"mucange",
"mucania",
"mucanje",
"mucasso",
"mucatas",
"mucatos",
"mucazos",
"mucaíxe",
"mucaúas",
"muceces",
"mucefas",
"mucefos",
"mucemba",
"muceras",
"mucetes",
"muchete",
"muchica",
"muchico",
"muchéns",
"muchões",
"mucites",
"mucitos",
"mucobas",
"mucobue",
"mucolos",
"mucomas",
"mucombe",
"mucombo",
"muconda",
"mucongo",
"mucoral",
"mucouco",
"mucoxos",
"mucoáli",
"mucuabô",
"mucuais",
"mucubas",
"mucubus",
"mucucos",
"mucucus",
"mucudas",
"mucudis",
"mucudos",
"mucuiós",
"mucuiús",
"mucujás",
"mucujés",
"muculas",
"mucumbi",
"mucumás",
"mucunda",
"mucunde",
"mucundo",
"mucunes",
"mucunis",
"mucures",
"mucuris",
"mucuros",
"mucurro",
"mucusos",
"mucutos",
"mucuves",
"mucuíce",
"mucáxis",
"mucândi",
"mucórea",
"mucóreo",
"mucóxis",
"mudende",
"mudimas",
"mudiris",
"mudirié",
"mudondo",
"muedens",
"muemada",
"muemado",
"muemais",
"muemamo",
"muemará",
"muemava",
"muemeis",
"muememo",
"muenada",
"muenado",
"muenais",
"muenamo",
"muenará",
"muenava",
"muendas",
"muendes",
"mueneis",
"muenemo",
"muenhas",
"muenhês",
"muenise",
"muenixe",
"muenque",
"muenéxi",
"muetete",
"mufetes",
"mufiate",
"muficai",
"muficam",
"muficar",
"muficas",
"muficos",
"muficou",
"mufilos",
"mufinas",
"mufique",
"mufitas",
"mufitos",
"mufixés",
"mufongo",
"mufufas",
"mufumas",
"mufumbe",
"mufumos",
"muganda",
"mugande",
"mugente",
"mugidas",
"mugidão",
"mugieia",
"muginge",
"mugiras",
"mugirei",
"mugires",
"mugirmo",
"mugirão",
"mugiste",
"mugondo",
"mugonha",
"mugunda",
"mugunge",
"mugungo",
"mugungá",
"mugunje",
"mugíeis",
"mugódis",
"muiajes",
"muianas",
"muicala",
"muichai",
"muimbas",
"muindas",
"muingas",
"muinges",
"muinjes",
"muiombo",
"muirita",
"muirris",
"muiuira",
"muiurés",
"mujajos",
"mujamos",
"mujeira",
"mujeiro",
"mujenas",
"mujicas",
"mujicos",
"mujijes",
"mujimas",
"mujimba",
"mujimbe",
"mujinai",
"mujinam",
"mujinar",
"mujinas",
"mujinei",
"mujinem",
"mujines",
"mujinje",
"mujinou",
"mujojos",
"mujolos",
"mulabes",
"mulabás",
"muladai",
"muladam",
"muladem",
"mulades",
"muladou",
"mulages",
"mulajes",
"mulalos",
"mulames",
"mulanas",
"mulango",
"muldera",
"muleada",
"muleado",
"muleias",
"mulelas",
"muleles",
"mulemos",
"mulemás",
"mulenas",
"mulenga",
"mulenos",
"muleras",
"muliada",
"mulinos",
"muloide",
"mulolos",
"mulombe",
"mulombo",
"mulonda",
"mulopes",
"mulosas",
"mulosos",
"mulsona",
"mulsões",
"multais",
"multamo",
"multana",
"multeis",
"multemo",
"multipá",
"mulucus",
"mululos",
"mulumba",
"mulumes",
"mulundo",
"mulális",
"muléria",
"mulídea",
"mulójis",
"mumanga",
"mumango",
"mumbiri",
"mumbucu",
"mumbula",
"mumbulo",
"mumizai",
"mumizam",
"mumizas",
"mumizei",
"mumizem",
"mumizes",
"mumizou",
"mumonas",
"mumonos",
"mumpapa",
"mumpepo",
"mumpuma",
"mumucas",
"mumungu",
"munanos",
"muncoto",
"muncure",
"mundelo",
"mundiei",
"mundiem",
"mundies",
"mundiou",
"mundobi",
"mundubé",
"munefes",
"munegas",
"munelho",
"munemas",
"muneres",
"mungaia",
"mungais",
"mungare",
"mungeis",
"mungemo",
"mungera",
"mungias",
"mungirá",
"mungolo",
"mungoma",
"mungone",
"munguai",
"mungubu",
"mungues",
"munguta",
"munhafo",
"munhere",
"munhime",
"munhimo",
"munidão",
"muningo",
"muninhé",
"munipiú",
"muniras",
"munirei",
"munires",
"muniste",
"munjais",
"munjamo",
"munjojo",
"munjues",
"munongo",
"munonos",
"munquam",
"munquas",
"munquia",
"munquis",
"munquiu",
"muntudo",
"munurus",
"muníeis",
"mupacos",
"mupapas",
"mupeixe",
"mupexes",
"mupeças",
"mupicai",
"mupicam",
"mupicas",
"mupicou",
"mupinda",
"mupique",
"mupiras",
"mupondo",
"muponga",
"mupucai",
"mupucam",
"mupucas",
"mupucou",
"mupumbe",
"mupupos",
"mupuque",
"mupéuas",
"muqueai",
"muquear",
"muqueei",
"muqueia",
"muqueie",
"muqueou",
"muquete",
"muquice",
"muquila",
"muquíxi",
"muracás",
"muralhe",
"muranho",
"murante",
"muraras",
"murarei",
"murarmo",
"murarão",
"muraste",
"muravas",
"murchai",
"murcuto",
"mureada",
"mureado",
"mureais",
"mureará",
"mureava",
"mureeis",
"mureiam",
"mureias",
"mureiem",
"mureies",
"mureita",
"murejão",
"muremos",
"murenas",
"murense",
"murerés",
"muretos",
"muriaçu",
"murindo",
"muriris",
"murixis",
"murjine",
"murjino",
"murjiná",
"murjona",
"murnaus",
"murquam",
"murquas",
"murquem",
"murques",
"murquia",
"murquis",
"murquiu",
"murraco",
"murraia",
"murreda",
"murrega",
"murrena",
"murrete",
"murroas",
"murruto",
"murrués",
"murtila",
"murtuço",
"muruari",
"murubus",
"murucas",
"muruins",
"murujai",
"murujam",
"murujar",
"murujas",
"murujei",
"murujem",
"murujes",
"murujou",
"murumos",
"murundo",
"murunga",
"murungo",
"murupis",
"mururuá",
"muruuás",
"muruxis",
"murçada",
"murínea",
"muríneo",
"musales",
"musamba",
"musanda",
"musaria",
"musaças",
"muscita",
"musenas",
"musesse",
"musgais",
"musgará",
"musgava",
"musgaça",
"musguei",
"musguem",
"musgues",
"musgões",
"musismo",
"muslema",
"musoles",
"musosas",
"musosos",
"mussafe",
"mussalo",
"mussapo",
"mussefa",
"mussefo",
"mussino",
"mussite",
"mussito",
"mussiús",
"mustelo",
"mustone",
"musumba",
"musunos",
"musurga",
"musurgo",
"mutacas",
"mutacos",
"mutalas",
"mutanta",
"mutapás",
"muteate",
"muteles",
"mutemba",
"mutembe",
"mutembo",
"mutenga",
"mutenge",
"mutenje",
"mutepas",
"mutetas",
"mutetes",
"mutibis",
"muticas",
"muticos",
"mutilai",
"mutiles",
"mutinos",
"mutobos",
"mutobué",
"mutocos",
"mutonas",
"mutones",
"mutonje",
"mutonto",
"mutopas",
"mutoves",
"mutoxes",
"mutrada",
"mutrado",
"mutrais",
"mutramo",
"mutrará",
"mutrava",
"mutreis",
"mutremo",
"mutrete",
"mutreto",
"mutuamo",
"mutuará",
"mutuava",
"mutueis",
"mutuemo",
"mutular",
"mutulas",
"mutumbu",
"mutunda",
"mutundo",
"mutunes",
"mutunge",
"mutunje",
"mutunte",
"mutuque",
"muturis",
"mututas",
"mututus",
"mutóbua",
"muumbes",
"muungos",
"muvalas",
"muvovos",
"muvugas",
"muvândi",
"muvúlia",
"muxaras",
"muxenxe",
"muxibis",
"muxicai",
"muxicam",
"muxicou",
"muximai",
"muximam",
"muximar",
"muximas",
"muximba",
"muximei",
"muximem",
"muximes",
"muximou",
"muxinda",
"muxinta",
"muxiris",
"muxirãs",
"muxixes",
"muxixão",
"muxoves",
"muxoxai",
"muxoxam",
"muxoxas",
"muxoxem",
"muxoxes",
"muxoxeá",
"muxuris",
"muzambê",
"muzanda",
"muzango",
"muzebas",
"muzemba",
"muzimba",
"muzimbo",
"muzimos",
"muzomba",
"muzombe",
"muzozos",
"muzucos",
"muzumba",
"muzumbi",
"muzumbo",
"muzunas",
"muzunos",
"muzuzus",
"muzúngu",
"muçacos",
"muçales",
"muçalos",
"muçande",
"muçanga",
"muçanha",
"muçanhi",
"muçapos",
"muçaxes",
"muçonas",
"muçondo",
"muçuíca",
"muédana",
"muédano",
"muônios",
"muônium",
"mxcviii",
"mxlviii",
"mxxviii",
"mxxxiii",
"mxxxvii",
"mácedas",
"máchios",
"máclies",
"mácropo",
"mácrote",
"mácroto",
"mágnios",
"málveas",
"málveos",
"márcida",
"mársaca",
"mársaco",
"másaris",
"másrios",
"másrium",
"máximes",
"mázacas",
"mãozota",
"mãozuda",
"médanas",
"mégaras",
"mélroas",
"mérgula",
"mérgulo",
"mérodon",
"mérulas",
"méscias",
"mésomis",
"mésoton",
"méspilo",
"métopos",
"mêmbrax",
"mêmnone",
"mênfias",
"mênfios",
"mênidas",
"mênidos",
"mênopon",
"mênsola",
"mêntica",
"mêntico",
"mêntore",
"mêntulo",
"mícropo",
"mícroto",
"mígdone",
"mílimas",
"mílimos",
"mílvios",
"mímidas",
"mímulos",
"mínulas",
"mírcias",
"mírofis",
"mírreas",
"mírreos",
"mírsina",
"mírsine",
"mírteis",
"mírteos",
"místace",
"místide",
"mítilos",
"mítrida",
"mítrofe",
"mítrula",
"míxicas",
"míxicos",
"móbulas",
"mógonos",
"mólgula",
"mórfeis",
"mórgias",
"mórgios",
"móricas",
"móropos",
"mômaros",
"môncias",
"môngoas",
"mônitas",
"mônonix",
"múfalos",
"múgrias",
"múlidas",
"múmures",
"múndios",
"múquias",
"múrrina",
"múscida",
"músquia",
"mútases",
"nabadas",
"nabades",
"nabanga",
"nabucos",
"nabídea",
"nabídeo",
"nabínea",
"nabíneo",
"nacacas",
"nacadar",
"nacadas",
"nacarai",
"nacaram",
"nacarei",
"nacarem",
"nacauãs",
"nacejas",
"nacerda",
"nacibos",
"naclito",
"nacodás",
"nacreás",
"nacuini",
"nacária",
"nacázia",
"nadabau",
"nadadão",
"nadapoa",
"nadarmo",
"nadidas",
"nadimba",
"nadinas",
"nadivas",
"nadivos",
"naegita",
"naegite",
"nafanas",
"naftasa",
"naftato",
"naftina",
"naftola",
"nafutes",
"nagador",
"nagados",
"nagadão",
"nagaica",
"nagamos",
"naganas",
"nagante",
"nagarei",
"nagarem",
"nagaria",
"nagarás",
"nagarão",
"nagasse",
"nagaste",
"nagavas",
"nagaína",
"nageras",
"nageres",
"nagibes",
"nagueis",
"naguins",
"naiadal",
"naianga",
"naibres",
"naifram",
"naifras",
"naifrem",
"naifres",
"naifria",
"naifrir",
"naifris",
"naifriu",
"naipais",
"naipamo",
"naipará",
"naipava",
"naipeis",
"naipemo",
"naiteia",
"naivaxa",
"naiveca",
"naiádea",
"najeras",
"najicas",
"najicos",
"najurus",
"nalagus",
"nalasso",
"nalatas",
"nalguda",
"nambete",
"nambuas",
"nambula",
"namburi",
"nambuus",
"nameias",
"namnete",
"namoins",
"namonge",
"namoxim",
"namunus",
"namázis",
"nanacis",
"nanadão",
"nanaras",
"nanarei",
"nanares",
"nanaria",
"nanarmo",
"nanarão",
"nanasse",
"nanaste",
"nanatos",
"nanauís",
"nanavas",
"nanavis",
"nandane",
"nanemos",
"nangone",
"nangoro",
"nangras",
"nanholo",
"nanines",
"nanista",
"nanoide",
"nantala",
"nantesa",
"nantuto",
"nanódea",
"nanódeo",
"nanófia",
"napalns",
"napeira",
"napevas",
"napitas",
"napopés",
"napulas",
"napupas",
"napuras",
"napácea",
"naquibe",
"naranol",
"narazes",
"narduro",
"nareces",
"nargóis",
"naricas",
"nariela",
"narisca",
"narlela",
"narnela",
"naropes",
"narosca",
"narouca",
"narouco",
"narramo",
"nartece",
"narânio",
"narésia",
"narésio",
"narícia",
"narício",
"nasalai",
"nasalas",
"nasalei",
"nasalem",
"nasamãs",
"nasaras",
"nasoreu",
"nassaná",
"nassela",
"nassipa",
"nastina",
"nastrai",
"nastram",
"nastrar",
"nastras",
"nastrei",
"nastrem",
"nastres",
"nastrou",
"nasális",
"nasávia",
"nasçamo",
"natabeu",
"natadas",
"natados",
"natafes",
"natalos",
"natenta",
"natioro",
"natreno",
"natrita",
"natruns",
"natrões",
"naturem",
"naturou",
"natuvas",
"natária",
"naudina",
"nauetes",
"naundes",
"naurana",
"naurano",
"nauseai",
"navalhe",
"navelai",
"navelam",
"navelar",
"navelei",
"navelem",
"naveles",
"navelou",
"naxanim",
"naxenim",
"naxiano",
"nazárea",
"nazéris",
"naçonas",
"naúclea",
"naúcleo",
"ndandos",
"ndarama",
"ndendos",
"ndimbas",
"ndombas",
"ndumbas",
"ndzavas",
"nealoto",
"nearcas",
"nearcos",
"neassos",
"nebafos",
"nebanga",
"nebasos",
"nebasão",
"nebondo",
"nebonzo",
"nebotos",
"nebrine",
"nebrino",
"nebrite",
"nebroma",
"nebruna",
"nebruno",
"nebália",
"neceada",
"neceado",
"neceais",
"neceará",
"neceava",
"necedro",
"neceeis",
"neceiam",
"neceias",
"neceiem",
"neceies",
"necocos",
"necodás",
"necoíta",
"necrode",
"necrodo",
"nectita",
"necturo",
"necuriá",
"necísia",
"nedendo",
"neditas",
"neegatu",
"nefelos",
"nefenas",
"nefrito",
"nefroge",
"nefrope",
"neftite",
"nefália",
"nefálio",
"negacei",
"negacem",
"negaces",
"negadão",
"negalha",
"negarça",
"negaçai",
"negaçam",
"negaçou",
"negilós",
"negombe",
"negreda",
"negrejo",
"neguila",
"negumas",
"negumbo",
"negungo",
"neguças",
"negácia",
"nelavãs",
"nelitas",
"nemausa",
"nemeana",
"nemeano",
"nemeeia",
"nemeeio",
"nemeeus",
"nemerte",
"nemetas",
"nemiana",
"nemplés",
"nemuras",
"nemália",
"nemálio",
"nemóbia",
"nemórea",
"nemóreo",
"nemósia",
"nenecai",
"nenecam",
"nenecar",
"nenecou",
"neneque",
"nengoné",
"nengoro",
"nengros",
"nenhice",
"neocana",
"neocena",
"neodine",
"neofeto",
"neogodo",
"neolane",
"neolita",
"neolzão",
"neonino",
"neorina",
"neotuno",
"neotíea",
"neoélia",
"nepanda",
"nepepes",
"nepoide",
"nepoíta",
"nepsera",
"nepétea",
"nepídea",
"nequeno",
"nereias",
"neriina",
"nerinas",
"neripas",
"neripos",
"neritas",
"nertera",
"nerusas",
"nerusca",
"nervuda",
"nervóis",
"nerínea",
"nerônia",
"nerúsia",
"nerúsio",
"nesanga",
"neseias",
"nesgais",
"nesgará",
"nesgava",
"nesguei",
"nesguem",
"nesgues",
"nesinga",
"neslita",
"nesteia",
"nestias",
"nestino",
"nesócia",
"netoide",
"neumada",
"neuromo",
"neustos",
"neuvita",
"nevador",
"nevadão",
"nevaras",
"nevarem",
"nevares",
"nevaris",
"nevarmo",
"nevarão",
"nevaste",
"nevavas",
"nevemos",
"nevisca",
"nevoace",
"nevoais",
"nevoamo",
"nevoará",
"nevoava",
"nevoaço",
"nevoeis",
"nevoemo",
"nevrina",
"nevromo",
"nevrona",
"nexibua",
"nexuais",
"nezaras",
"nezinze",
"neânias",
"neânica",
"neânico",
"neãozão",
"neésias",
"neísmos",
"neóboro",
"neócito",
"neócoro",
"neódria",
"neófoba",
"neóforo",
"neófron",
"neógona",
"neógono",
"neólito",
"neótias",
"neótomo",
"neônios",
"ngavito",
"nguembu",
"nhacoco",
"nhambis",
"nhampãs",
"nhamuís",
"nhandis",
"nhangos",
"nhanido",
"nhapins",
"nhaques",
"nhembas",
"nhocana",
"nhuaçus",
"nhumbos",
"nhumues",
"nhundes",
"nhundis",
"nhundus",
"nhurria",
"nháfete",
"niabele",
"nialzão",
"nianeca",
"niarana",
"nibalas",
"niboras",
"nicador",
"nicadão",
"nicanés",
"nicaram",
"nicarei",
"nicarem",
"nicares",
"nicaria",
"nicarmo",
"nicarás",
"nicarão",
"nicaste",
"nicates",
"nicavam",
"nicavas",
"nicesas",
"niceses",
"nichais",
"nichamo",
"nichará",
"nichava",
"nicheis",
"nichemo",
"nicomol",
"nicotol",
"nicrada",
"nicrado",
"nictada",
"nictado",
"nictais",
"nictamo",
"nictará",
"nictava",
"nicteis",
"nictemo",
"nictera",
"nicuris",
"nicázia",
"nicóbio",
"nidador",
"nidados",
"nidadão",
"nidamos",
"nidando",
"nidante",
"nidaram",
"nidarei",
"nidarem",
"nidares",
"nidaria",
"nidarmo",
"nidarás",
"nidarão",
"nidasse",
"nidaste",
"nidavam",
"nidavas",
"nidemas",
"nidemos",
"nieiras",
"nieiros",
"nielins",
"nifargo",
"nifelas",
"nifonas",
"nigalho",
"nigelai",
"nigelam",
"nigelei",
"nigelem",
"nigeles",
"nigelou",
"nigreta",
"nigrete",
"nigálio",
"nilense",
"niliada",
"niliado",
"niliais",
"niliamo",
"niliará",
"niliava",
"nilieis",
"niliemo",
"nilíaca",
"nimbais",
"nimbamo",
"nimbara",
"nimbeis",
"nimbemo",
"nimboís",
"nimitas",
"ninador",
"ninadão",
"ninaine",
"ninaino",
"ninainá",
"ninante",
"ninaras",
"ninarmo",
"ninarão",
"ninaste",
"ninavas",
"ninemos",
"ninfala",
"ninfale",
"ninfios",
"ninfite",
"ninhamo",
"ninhava",
"ninheis",
"ninhemo",
"niníada",
"nipeira",
"nipenes",
"nipevas",
"nipácea",
"nipáceo",
"niquelo",
"niquemo",
"niquile",
"niquilo",
"niquilá",
"niquira",
"nirocas",
"nirvane",
"nirvano",
"nisbita",
"niscato",
"niscona",
"niscros",
"niscões",
"niseias",
"nisinas",
"nisitas",
"nisorra",
"nisueta",
"nitelas",
"nitesci",
"nitescê",
"nitesça",
"nitesço",
"nitinga",
"nitonte",
"nitrais",
"nitramo",
"nitrará",
"nitratá",
"nitrava",
"nitreis",
"nitremo",
"nitrete",
"nitrias",
"nitrimo",
"nitrina",
"nitrira",
"nitrura",
"nitrure",
"nitróis",
"nitélia",
"niuambê",
"niumbós",
"nivator",
"niveais",
"nivênia",
"nizense",
"niçaces",
"nióbica",
"nióbida",
"nióbios",
"njangos",
"nobreci",
"nobreço",
"nobunda",
"nocador",
"nocados",
"nocadão",
"nocamos",
"nocando",
"nocante",
"nocaram",
"nocarei",
"nocarem",
"nocares",
"nocarmo",
"nocarás",
"nocarão",
"nocasse",
"nocaste",
"nocavam",
"nocavas",
"nocuini",
"nodadas",
"nodador",
"nodadão",
"nodamos",
"nodando",
"nodante",
"nodaram",
"nodarei",
"nodarem",
"nodares",
"nodaria",
"nodarmo",
"nodarás",
"nodarão",
"nodasse",
"nodaste",
"nodavam",
"nodavas",
"nodação",
"nodoais",
"nodoamo",
"nodoara",
"nodoava",
"nodoeis",
"nodoemo",
"nodoide",
"nodulou",
"nodulás",
"noelzão",
"noetana",
"noetano",
"nogaica",
"noguito",
"noiante",
"noiteci",
"noiteça",
"noiteço",
"noitóis",
"noitões",
"noivais",
"noivamo",
"noivemo",
"nojadas",
"nojador",
"nojados",
"nojadão",
"nojamos",
"nojando",
"nojante",
"nojaram",
"nojaras",
"nojarei",
"nojarem",
"nojares",
"nojaria",
"nojarmo",
"nojarão",
"nojasse",
"nojaste",
"nojavam",
"nojavas",
"nojemos",
"nojosas",
"nolanas",
"nolelas",
"nolense",
"nolitas",
"nolânea",
"nolétia",
"nolídea",
"nolídeo",
"nolínea",
"nolínio",
"nomasas",
"nomines",
"nomoxim",
"nonanas",
"nonanos",
"nonavos",
"nonenas",
"noninas",
"nonodos",
"nonoica",
"nonoico",
"nonoses",
"nonulas",
"nonusse",
"nonária",
"nonílio",
"nopálea",
"nopáleo",
"noqueis",
"noquemo",
"noqueta",
"noqueto",
"norbana",
"norbano",
"nordita",
"norense",
"norepol",
"noriana",
"norinas",
"noritas",
"norites",
"nortias",
"nortões",
"norícia",
"norício",
"noscado",
"nosemia",
"noseíta",
"nositas",
"notadão",
"notante",
"notarco",
"notarmo",
"notiode",
"notites",
"notolca",
"notoxos",
"notozos",
"notâmia",
"notélea",
"notília",
"notônis",
"noutece",
"nouteci",
"nouteça",
"nouteço",
"noutibó",
"noutões",
"novadão",
"novarei",
"novarem",
"novares",
"novaria",
"novarmo",
"novarão",
"novaste",
"novavam",
"novavas",
"noveada",
"noveado",
"novedia",
"novelai",
"novelam",
"novelem",
"novemos",
"novença",
"noviais",
"novicie",
"novisco",
"novídea",
"noxiúra",
"nozaris",
"nozedos",
"nozilha",
"noçonas",
"noêmias",
"noêmica",
"noêmico",
"noóloga",
"noólogo",
"nuancei",
"nuançai",
"nubeias",
"nubelai",
"nubelam",
"nubelar",
"nubelas",
"nubelei",
"nubelem",
"nubelou",
"nublais",
"nubleis",
"nublemo",
"nucelas",
"nucibes",
"nucinas",
"nucodes",
"nudinha",
"nudinho",
"nuelzão",
"nufárea",
"nuinhos",
"nuitone",
"numeíte",
"nunciai",
"nunciem",
"nuncies",
"nunismo",
"nunista",
"nunumas",
"nupciam",
"nupciei",
"nupciem",
"nupcies",
"nupciou",
"nupebas",
"nuqueai",
"nuqueei",
"nuqueia",
"nuqueie",
"nuqueio",
"nuqueou",
"nursino",
"nurumas",
"nuruzes",
"nutadas",
"nutador",
"nutados",
"nutadão",
"nutamos",
"nutaram",
"nutaras",
"nutarei",
"nutarem",
"nutares",
"nutaria",
"nutarmo",
"nutarão",
"nutasse",
"nutaste",
"nutavam",
"nutavas",
"nutemos",
"nutremo",
"nutália",
"nuveada",
"nuveais",
"nuveará",
"nuveava",
"nuveeis",
"nuveiam",
"nuveias",
"nuveiem",
"nuveies",
"nuvrada",
"nuvrado",
"nuçonas",
"nábatas",
"náblios",
"nábulos",
"nácharo",
"náfrica",
"náfrico",
"náideas",
"nárdias",
"nárdina",
"nárdoas",
"náscios",
"násicas",
"násicos",
"nássula",
"nátrios",
"náucora",
"náucore",
"náufica",
"náumaco",
"náupeta",
"náxaras",
"náxaros",
"nânopos",
"nãozões",
"nébrias",
"nébrido",
"néctica",
"néfanes",
"néfelis",
"néfodos",
"néfteas",
"néocrex",
"népetas",
"népidas",
"nérofis",
"nérolis",
"nérveas",
"néslias",
"nésquis",
"nêfetas",
"nêmedra",
"nêmofis",
"nêmonix",
"nêngara",
"nêngaro",
"nêurafe",
"nêurias",
"nêvedas",
"níctala",
"níctale",
"níctalo",
"níglaro",
"nígrica",
"nígrico",
"nílicas",
"nílicos",
"nímbios",
"nínfula",
"nínsias",
"níparas",
"níparos",
"nítrons",
"nízaros",
"nízeras",
"nóctula",
"nódicas",
"nóricas",
"nóropes",
"nótafos",
"nóteras",
"nóteros",
"nômonix",
"núbilos",
"núcegas",
"núfares",
"núndina",
"núperas",
"núperos",
"oacajus",
"oacauãs",
"oaianas",
"oajerus",
"oajurus",
"oanassu",
"oanaçus",
"oanuçus",
"oasinos",
"oasitas",
"oaçacus",
"obambas",
"obcecai",
"obdurai",
"obduram",
"obduras",
"obdurei",
"obdurem",
"obdures",
"obdurou",
"oberais",
"oberamo",
"oberará",
"oberava",
"obereis",
"oberemo",
"obfirma",
"obfirme",
"obiones",
"obipons",
"obirins",
"obladas",
"oblatai",
"oblatam",
"oblatei",
"oblatem",
"oboazes",
"obongas",
"oborita",
"obradai",
"obradam",
"obradei",
"obradem",
"obrades",
"obradão",
"obrarmo",
"obravas",
"obraçom",
"obrejai",
"obrejam",
"obrejar",
"obrejas",
"obrejei",
"obrejem",
"obrejes",
"obrejou",
"obrimas",
"obrável",
"obsecre",
"obsedai",
"obsedas",
"obsedei",
"obsedem",
"obsedie",
"obsedio",
"obstais",
"obstamo",
"obsteis",
"obstemo",
"obstipe",
"obstipo",
"obtesta",
"obtunda",
"obtundi",
"obturai",
"obtures",
"obumbre",
"obvenha",
"obvenho",
"obviamo",
"obvidor",
"obvidão",
"obviemo",
"obviera",
"obvimos",
"obvinde",
"obvindo",
"obvinha",
"obvirei",
"obvirem",
"obvires",
"obviria",
"obvirás",
"obvirão",
"obécias",
"obélios",
"obéreas",
"obísios",
"obólida",
"obóveas",
"ocadona",
"ocadora",
"ocadões",
"ocandas",
"ocanhos",
"ocantes",
"ocardes",
"ocaremo",
"ocariam",
"ocarias",
"ocarmos",
"ocassem",
"ocasses",
"ocastes",
"occídua",
"ocinhés",
"ocisona",
"ocleses",
"ocluíam",
"ocluías",
"ocládio",
"ocnáceo",
"ocnéria",
"ocoembo",
"oconite",
"ocorras",
"ocoteia",
"ocotona",
"ocozoal",
"ocozóis",
"ocrames",
"ocranas",
"ocranos",
"ocranta",
"ocranto",
"ocrases",
"ocreada",
"ocreato",
"ocreosa",
"ocreína",
"ocromas",
"ocroses",
"ocroíta",
"ocroíto",
"ocrálea",
"ocríase",
"ocrósis",
"ocrômia",
"octanai",
"octanam",
"octanar",
"octanei",
"octanem",
"octanou",
"octecas",
"octenos",
"octeras",
"octeros",
"octidis",
"octilas",
"octilos",
"octinos",
"octoico",
"octonas",
"octonos",
"octusse",
"octébio",
"octíase",
"octílio",
"ocujarê",
"ocumbas",
"ocursai",
"ocursam",
"ocursas",
"ocursei",
"ocursem",
"ocurses",
"ocursou",
"ocutava",
"ocuubas",
"ocálias",
"ocálios",
"ocápias",
"ocáreis",
"océleas",
"océleos",
"ocíales",
"ocíalos",
"ocídios",
"ocíduos",
"ocífabe",
"ocímeas",
"ocípoda",
"ocípode",
"ocíroes",
"ocítoes",
"ocófita",
"ocóteas",
"odalite",
"odenita",
"odiadão",
"odinero",
"odistas",
"odjadas",
"odobeno",
"odorada",
"odorais",
"odoramo",
"odorará",
"odorato",
"odoreis",
"odoremo",
"odorizo",
"odorzão",
"odradas",
"odreira",
"odulços",
"odátria",
"odézias",
"odínias",
"odórica",
"odôncia",
"odônima",
"odôntia",
"odôntio",
"odônton",
"oecanto",
"oecétis",
"oeduema",
"ofarita",
"ofegais",
"ofegosa",
"ofeguem",
"ofegues",
"ofierno",
"ofigles",
"ofiodes",
"ofioelo",
"ofioide",
"ofiones",
"ofiopes",
"ofirina",
"ofirino",
"ofismas",
"ofisuro",
"ofitosa",
"ofiúras",
"ofiúsas",
"ofomimo",
"ofreçom",
"ofreção",
"ofriope",
"ofrites",
"ofrídea",
"ofrídeo",
"ofrídio",
"ofrísia",
"ofsetes",
"ofuscai",
"ofélima",
"ofélimo",
"ofíases",
"ofíctis",
"ofídida",
"ofíodon",
"ofíonix",
"ofíopos",
"ofírias",
"ofírios",
"ofônios",
"ogadona",
"ogadora",
"ogadões",
"ogambás",
"ogangos",
"ogantes",
"ogardes",
"ogareis",
"ogariam",
"ogarias",
"ogarmos",
"ogassem",
"ogasses",
"ogastes",
"ogcodes",
"ogieras",
"ogivamo",
"ogivará",
"ogivava",
"ogiveis",
"ogivemo",
"ogiveta",
"oglifas",
"oguemos",
"oguiris",
"ogunhês",
"oguniês",
"ogáveis",
"ogãozão",
"ogígias",
"ogígica",
"ogígios",
"oiampim",
"oiaraná",
"oidemia",
"oidiais",
"oiedeia",
"oigadas",
"oigador",
"oigados",
"oigadão",
"oigalês",
"oigando",
"oigante",
"oigaram",
"oigarei",
"oigarem",
"oigares",
"oigaria",
"oigarás",
"oigarão",
"oigasse",
"oigaste",
"oigatés",
"oigavam",
"oigavas",
"oigueis",
"oilites",
"oinólea",
"oinóleo",
"oiradas",
"oirador",
"oirados",
"oiradão",
"oiramas",
"oiramos",
"oirando",
"oirante",
"oiraram",
"oirarei",
"oirarem",
"oirares",
"oiraria",
"oirarmo",
"oirarás",
"oirarão",
"oiraste",
"oiravam",
"oiravas",
"oiregas",
"oirejai",
"oirejam",
"oirejar",
"oirejas",
"oirejei",
"oirejem",
"oirejes",
"oirejou",
"oiricei",
"oiricem",
"oirices",
"oirincu",
"oiripel",
"oiriçai",
"oiriças",
"oiriçou",
"oirudas",
"oirudos",
"oitavai",
"oitaves",
"oitavou",
"oitchis",
"oitedas",
"oitibás",
"oitibós",
"oitonas",
"ojambas",
"ojambis",
"ojungue",
"olafita",
"olaflur",
"olampis",
"olandis",
"olangás",
"olanina",
"olbiana",
"oleadai",
"oleadam",
"oleadar",
"oleadei",
"oleadem",
"oleades",
"oleadou",
"oleadão",
"oleamos",
"oleante",
"olearei",
"olearás",
"olearão",
"oleasse",
"oleavas",
"oleemos",
"oleenta",
"oleilas",
"olembro",
"oleomel",
"oleulas",
"oleídea",
"oleídos",
"oleílos",
"oleínea",
"oleíneo",
"olfacte",
"olfactá",
"olfatai",
"olfatam",
"olfatas",
"olfatei",
"olfatem",
"olfates",
"olfateá",
"olfatou",
"olhicas",
"olhicos",
"olhinha",
"oliaris",
"olibros",
"oligoro",
"olimone",
"olindês",
"olivamo",
"olivava",
"olivele",
"olivelo",
"olivelá",
"olivemo",
"olivite",
"olococo",
"olofros",
"olorize",
"olorizo",
"olostra",
"olpídio",
"olulano",
"olulomo",
"olumués",
"olupalé",
"oláceos",
"olânica",
"olânico",
"oléases",
"olíaros",
"olíbana",
"olígias",
"olígota",
"olíncia",
"olíncio",
"olíneas",
"olínica",
"olínico",
"olíntia",
"olípios",
"olíreas",
"olíreos",
"olírias",
"olísbea",
"olíveas",
"olíveos",
"olótelo",
"omacola",
"omafias",
"omagras",
"omaliso",
"omalíea",
"omandas",
"omantos",
"omarins",
"omasona",
"omasões",
"omatela",
"ombacas",
"ombambe",
"ombanda",
"ombanja",
"ombiaxo",
"ombreai",
"ombulos",
"omeniés",
"omertis",
"omicros",
"ominada",
"ominamo",
"ominará",
"ominava",
"omineis",
"ominemo",
"omitamo",
"omitilo",
"omiziám",
"omoflos",
"omofros",
"omoidea",
"omoideo",
"omoides",
"omolucu",
"omorgas",
"ompatas",
"ompeque",
"omuande",
"omufito",
"omulucu",
"omulzão",
"omumbeu",
"omumbos",
"omuxate",
"omácios",
"omádios",
"omáleis",
"omálias",
"omálios",
"omáseas",
"omáseos",
"omátios",
"omátuas",
"omófaga",
"omólabo",
"onagres",
"onanize",
"onanizo",
"onanizá",
"onastro",
"oncaeia",
"onceias",
"onciais",
"oncimas",
"oncobas",
"oncodes",
"oncoses",
"oncídia",
"oncínio",
"oncóbea",
"oncóbeo",
"ondacas",
"ondador",
"ondadão",
"ondaios",
"ondalas",
"ondamos",
"ondante",
"ondaram",
"ondarei",
"ondarem",
"ondares",
"ondarmo",
"ondarás",
"ondarão",
"ondavam",
"ondavas",
"ondeais",
"ondeamo",
"ondeeis",
"ondeemo",
"ondeias",
"ondeies",
"ondejai",
"ondejam",
"ondejas",
"ondejei",
"ondejem",
"ondejes",
"ondejou",
"ondemos",
"ondingo",
"ondjóis",
"ondoada",
"ondoado",
"ondoais",
"ondoamo",
"ondoará",
"ondoava",
"ondocaí",
"ondoeis",
"ondoemo",
"ondudas",
"ondulai",
"ondules",
"onegita",
"onegite",
"onenses",
"onereis",
"oneremo",
"onesita",
"onesito",
"oneídas",
"onfácio",
"onfálea",
"ongamba",
"ongebos",
"ongolos",
"ongombo",
"ongonhe",
"ongotis",
"onguari",
"ongueve",
"onguáli",
"onhames",
"onhenga",
"onhense",
"onhimas",
"onhocas",
"onhuios",
"onhunga",
"onialai",
"onialia",
"oniamas",
"onichas",
"onicora",
"oninlés",
"oniosas",
"oniosos",
"oniscos",
"onjamba",
"onjélia",
"onoceto",
"onocolo",
"ononina",
"onosmas",
"onquela",
"onteros",
"ontivas",
"ontivos",
"onundos",
"onunhas",
"onussis",
"onutuva",
"onzenai",
"onzenam",
"onzenei",
"onzenem",
"onzenes",
"onzeneá",
"onágrea",
"onágreo",
"onçonas",
"onífera",
"onífero",
"onígena",
"onígeno",
"oníneas",
"oníneos",
"onípara",
"oníquio",
"oníscia",
"onítona",
"onítono",
"onívaga",
"onívago",
"onívoma",
"onônide",
"oociese",
"oociste",
"oocormo",
"oocroto",
"oocíano",
"oodéopo",
"oogemas",
"oolemas",
"ooleína",
"oometra",
"oomorfo",
"oopelta",
"oossomo",
"ootipia",
"ootomia",
"ootídio",
"opacais",
"opacala",
"opacamo",
"opacara",
"opacava",
"opacita",
"opadaça",
"opadona",
"opadões",
"opaliza",
"opalize",
"opalizo",
"opalzão",
"opanqui",
"opantes",
"opanóis",
"opaquei",
"opaquem",
"opardes",
"oparemo",
"oparias",
"oparmos",
"opassem",
"opasses",
"opastes",
"opatros",
"operemo",
"opertos",
"opiador",
"opiadão",
"opiamos",
"opiante",
"opiaram",
"opiarei",
"opiarem",
"opiares",
"opiaria",
"opiarmo",
"opiarás",
"opiarão",
"opiaste",
"opiavam",
"opiavas",
"opiemos",
"opilais",
"opilamo",
"opilará",
"opilava",
"opileis",
"opilemo",
"opinajé",
"opinamo",
"opineis",
"opinemo",
"opinosa",
"opistos",
"oplomos",
"oplária",
"opolzão",
"opomumo",
"oponais",
"oporeis",
"oporemo",
"oposina",
"optadão",
"optaras",
"optarmo",
"opugnai",
"opugnas",
"opugnei",
"opugnem",
"opugnes",
"opugnou",
"opuluís",
"opunhas",
"opálica",
"opálico",
"opáreis",
"opáveis",
"opçonas",
"opífera",
"opílias",
"opímias",
"opímios",
"opízias",
"opódima",
"opófila",
"opóleas",
"opóleos",
"opólica",
"opórina",
"oquedês",
"oqueira",
"oquemos",
"oquinas",
"oquétio",
"oquênia",
"orabins",
"oracule",
"oradona",
"oradões",
"oralizo",
"oralzão",
"oranesa",
"oranita",
"oraremo",
"orarias",
"orasses",
"oratela",
"oratelo",
"oraçona",
"orcemos",
"orciana",
"orcinos",
"ordinhe",
"ordumes",
"ordéolo",
"oreador",
"oreados",
"oreadão",
"oreamno",
"oreamos",
"oreante",
"orearam",
"orearei",
"orearem",
"oreares",
"orearia",
"orearás",
"orearão",
"oreasse",
"oreaste",
"oreavam",
"oreavas",
"orebita",
"orectas",
"oreemos",
"orelhei",
"orelhem",
"orelhes",
"oressas",
"oretano",
"orexias",
"oreóbio",
"orfaica",
"orfanai",
"orfanam",
"orfanas",
"orfanei",
"orfanem",
"orfanes",
"orfeons",
"orfneia",
"orfásio",
"orfânia",
"orgadas",
"orgiado",
"orgiamo",
"orgiará",
"orgiava",
"orgieis",
"orgiemo",
"orgonas",
"orgério",
"orgíias",
"oricina",
"oricino",
"oricter",
"orictes",
"origmas",
"orincos",
"orinque",
"orismos",
"orisóis",
"oritana",
"oritano",
"orizita",
"orizite",
"oriídea",
"oriídeo",
"oriólia",
"orladão",
"orlaias",
"orlamos",
"orlaras",
"orlarei",
"orlares",
"orlaria",
"orlarmo",
"orlarão",
"orlasse",
"orlaste",
"orlavas",
"orlemos",
"orlitas",
"ormiros",
"ormulus",
"ornadão",
"ornante",
"ornaras",
"ornarei",
"ornares",
"ornarmo",
"orneada",
"orneado",
"orneais",
"orneará",
"orneava",
"orneeis",
"orneiem",
"orneies",
"orneiro",
"ornejai",
"ornejam",
"ornejas",
"ornejei",
"ornejem",
"ornejes",
"orneode",
"orobita",
"orobite",
"orobito",
"orobodó",
"orodalo",
"orofeia",
"orongas",
"oronjas",
"oroodes",
"ororama",
"ororoba",
"orovais",
"oroípos",
"orquena",
"orqueno",
"orreira",
"orretas",
"orsamos",
"orseias",
"orselas",
"orsetas",
"orsilos",
"orteias",
"ortetro",
"ortigai",
"ortigam",
"ortigou",
"ortitas",
"ortites",
"ortivas",
"ortivos",
"ortoide",
"ortopes",
"ortosas",
"orucuri",
"oruncós",
"orungas",
"orungos",
"orungus",
"orvaeza",
"orzagas",
"orzunas",
"orágono",
"orçadão",
"orçante",
"orçaras",
"orçarei",
"orçares",
"orçarmo",
"orçaste",
"orçavas",
"orçazes",
"orçável",
"orélias",
"oréscio",
"orícias",
"orícios",
"orígamo",
"orígana",
"orígias",
"oríolos",
"orítias",
"orízeos",
"oróbias",
"orófoba",
"orófobo",
"orógena",
"oróxilo",
"orônima",
"osassas",
"osazona",
"oscilai",
"oscitai",
"oscitam",
"oscitas",
"oscitei",
"oscitem",
"oscites",
"oscitou",
"osculai",
"osculas",
"oscules",
"oscânio",
"osecrar",
"osfites",
"osfíias",
"osidase",
"osmanis",
"osmeros",
"osmiato",
"osmieto",
"osminas",
"osmiosa",
"osmioso",
"osmitas",
"osqueal",
"osqueol",
"osquite",
"ossamas",
"osseana",
"ossenas",
"ossenos",
"ossidez",
"ossobós",
"ossuosa",
"ostaxas",
"ostemia",
"ostexia",
"osteído",
"osteíto",
"ostiaca",
"ostites",
"ostrano",
"ostreal",
"ostreia",
"osérias",
"osísmia",
"osísmio",
"otaiana",
"otaiano",
"otantos",
"otavita",
"otelíea",
"otenize",
"otenizo",
"otenizá",
"otesina",
"otesino",
"oteátea",
"otiatro",
"otobite",
"otocião",
"otofono",
"otogipo",
"otomaca",
"otombas",
"otomita",
"otomões",
"otoques",
"ottélia",
"otulose",
"otuqués",
"otãozão",
"otélias",
"otídeas",
"otídias",
"otídida",
"otídios",
"otílofo",
"otínida",
"otítida",
"otócino",
"otófane",
"otóforo",
"otóloga",
"otóquis",
"otômice",
"otômios",
"otônias",
"ougadas",
"ougador",
"ougadão",
"ougamos",
"ougando",
"ougante",
"ougaram",
"ougarei",
"ougarem",
"ougaria",
"ougarás",
"ougarão",
"ougasse",
"ougaste",
"ougavam",
"ougavas",
"ougueis",
"ouquias",
"ourador",
"ouradão",
"ouramas",
"ouranas",
"ourando",
"ouraram",
"ourarei",
"ourarem",
"ourares",
"ourarmo",
"ourarás",
"ourarão",
"ourasse",
"ouraste",
"ourates",
"ouravam",
"ouravas",
"ouregas",
"ouregos",
"ourejai",
"ourejam",
"ourejas",
"ourejei",
"ourejem",
"ourejes",
"ourejou",
"ourelai",
"ourelam",
"ourelei",
"ourelem",
"oureles",
"ourelou",
"ouremos",
"ouresce",
"ouresci",
"ouresça",
"ouresço",
"oureças",
"ouricem",
"ourices",
"ourinai",
"ourinam",
"ourincu",
"ourinei",
"ourinem",
"ourines",
"ourinou",
"ouriçai",
"ourolos",
"ourreta",
"ourudas",
"ourátea",
"ousador",
"ousadão",
"ousante",
"ousarmo",
"ousiada",
"ousiado",
"ousiais",
"ousiamo",
"ousiara",
"ousiava",
"ousieis",
"ousiemo",
"ousável",
"outadas",
"outador",
"outados",
"outadão",
"outamos",
"outando",
"outaram",
"outarei",
"outarem",
"outares",
"outarmo",
"outarão",
"outasse",
"outaste",
"outavai",
"outavam",
"outavar",
"outavei",
"outavem",
"outaves",
"outavou",
"outemos",
"outonai",
"outonei",
"outonem",
"outones",
"outoneá",
"outrega",
"ouvente",
"ouvença",
"ouçandé",
"ouçonas",
"ovadela",
"ovadona",
"ovadora",
"ovadões",
"ovalais",
"ovalamo",
"ovalará",
"ovalava",
"ovaleis",
"ovalemo",
"ovalize",
"ovalizo",
"ovalura",
"ovambas",
"ovampas",
"ovardes",
"ovaremo",
"ovariam",
"ovarmos",
"ovassem",
"ovasses",
"ovastes",
"ovaçona",
"ovecate",
"ovenite",
"ovenças",
"overita",
"oviadas",
"oviados",
"ovicela",
"ovielas",
"ovilulo",
"ovilzão",
"ovismos",
"oviária",
"ovogais",
"ovonita",
"ovonito",
"ovulais",
"ovulamo",
"ovuleis",
"ovulemo",
"ováreis",
"ováveis",
"ovídeas",
"ovífera",
"ovígena",
"ovígeno",
"ovívora",
"ovófaga",
"ovófago",
"ovômero",
"oxalita",
"oxalmas",
"oxalmes",
"oxamina",
"oxamita",
"oxanilo",
"oxarita",
"oxazole",
"oxedudu",
"oxeólea",
"oxiafia",
"oxianto",
"oxicana",
"oxidais",
"oxidamo",
"oxideis",
"oxidemo",
"oxidora",
"oxidulá",
"oxigiro",
"oxilzão",
"oxinoto",
"oxiopes",
"oxirias",
"oxissal",
"oxiteca",
"oxiuras",
"oxiúres",
"oxonato",
"oxozone",
"oxâmica",
"oxíbafo",
"oxíbase",
"oxíbias",
"oxícara",
"oxícera",
"oxídios",
"oxíecos",
"oxígaro",
"oxígona",
"oxílios",
"oxímaco",
"oxínoes",
"oxíones",
"oxípilo",
"oxípoda",
"oxíporo",
"oxírris",
"oxítelo",
"oxóleas",
"oxóleos",
"oxônica",
"ozagres",
"ozeadas",
"ozeador",
"ozeados",
"ozeadão",
"ozeamos",
"ozeando",
"ozeante",
"ozearam",
"ozearas",
"ozearei",
"ozearem",
"ozeares",
"ozearia",
"ozearão",
"ozeasse",
"ozeaste",
"ozeavam",
"ozeavas",
"ozeemos",
"ozolemo",
"ozonada",
"ozonamo",
"ozonará",
"ozonava",
"ozoneis",
"ozonemo",
"ozonizo",
"ozueias",
"ozênica",
"ozíneas",
"ozíneos",
"ozóbias",
"ozódera",
"ozórias",
"ozóroas",
"ozômena",
"ozônide",
"oáricas",
"oáricos",
"oásicas",
"oásicos",
"oédalos",
"oófagos",
"oófitas",
"oófitos",
"oógamas",
"oógamos",
"oólises",
"oóptero",
"oóspora",
"oótides",
"oótipos",
"oótocas",
"oótocos",
"oótomas",
"pabolas",
"pabulai",
"pabulei",
"pabulem",
"pabules",
"pabulou",
"pabulão",
"pacahás",
"pacaios",
"pacaiás",
"pacarás",
"pacarés",
"pacasos",
"pacaços",
"pacaído",
"pacejai",
"pacejam",
"pacejas",
"pacejei",
"pacejem",
"pacejes",
"pacejou",
"pacholo",
"pachéis",
"pacolés",
"pactais",
"pactamo",
"pactava",
"pacteai",
"pacteei",
"pacteia",
"pacteie",
"pacteio",
"pacteis",
"pactemo",
"pacuará",
"pacubos",
"pacueza",
"paculas",
"pacumãs",
"pacunas",
"pacutuí",
"pacuçus",
"padagoz",
"padanes",
"padeada",
"padeado",
"padeias",
"padejai",
"padejam",
"padejas",
"padejei",
"padejem",
"padejes",
"padejou",
"padelas",
"padelos",
"padidas",
"padinas",
"padixos",
"padrais",
"padramo",
"padrará",
"padrava",
"padreai",
"padreei",
"padreie",
"padreio",
"padreis",
"padremo",
"padroom",
"paducai",
"paducam",
"paducar",
"paducas",
"paducou",
"padumar",
"paduque",
"paedero",
"pafínia",
"pagagas",
"pagaiai",
"pagaiam",
"pagaiei",
"pagaiem",
"pagaies",
"pagaiou",
"pagangu",
"pagarca",
"pageias",
"pagelos",
"paginai",
"pagines",
"pagitos",
"pagiços",
"pagodôs",
"pagotém",
"pagumas",
"pagâmea",
"paiabas",
"paianas",
"paicojé",
"paienas",
"pailona",
"pailões",
"paipuna",
"pairamo",
"paireis",
"pairemo",
"paiurás",
"paivada",
"paivado",
"paivais",
"paivamo",
"paivará",
"paivava",
"paiveia",
"paiveis",
"paivemo",
"paixóis",
"pajades",
"pajanas",
"pajeais",
"pajeamo",
"pajeara",
"pajeeis",
"pajeemo",
"pajeias",
"pajeiem",
"pajeies",
"pajiões",
"pajolas",
"pajurus",
"palaves",
"paldrão",
"paleado",
"paleara",
"paleava",
"paleeis",
"palefes",
"palegas",
"paleiam",
"paleica",
"paleico",
"paleiem",
"paleies",
"palejai",
"palejas",
"palejei",
"palejem",
"palejes",
"palenes",
"paleneu",
"paletai",
"paletam",
"paletei",
"paletem",
"paleteá",
"palhamo",
"palhará",
"palheis",
"palhemo",
"palhins",
"palhosa",
"paliamo",
"paliará",
"palibes",
"paliemo",
"palitai",
"palmamo",
"palmeai",
"palmeei",
"palmeie",
"palmeis",
"palmeje",
"palmejo",
"palmejá",
"palmemo",
"palmico",
"palmumá",
"palodes",
"paloias",
"paloios",
"palomai",
"palomam",
"palomei",
"palomem",
"palomes",
"palomou",
"palonga",
"palorai",
"paloram",
"palorar",
"paloras",
"palorei",
"palorem",
"palorou",
"palpeis",
"palpemo",
"palpumá",
"palrais",
"palramo",
"palrará",
"palreai",
"palreei",
"palreie",
"palreis",
"palremo",
"palreou",
"palrões",
"paludal",
"paludas",
"palumos",
"palurda",
"palutas",
"palásia",
"palíolo",
"pamanás",
"pamboro",
"pamonãs",
"pamplos",
"pampuãs",
"panacei",
"panaces",
"panacha",
"panador",
"panadão",
"panando",
"panante",
"panarei",
"panarem",
"panaris",
"panarto",
"panarão",
"panasos",
"panasse",
"panaste",
"panasão",
"panavam",
"panavas",
"panazos",
"panaçai",
"panaçam",
"panaçou",
"pandais",
"pandalo",
"pandamo",
"pandeai",
"pandeei",
"pandeie",
"pandeio",
"pandeis",
"pandeta",
"panedro",
"panejai",
"panejas",
"panejem",
"panejes",
"panejão",
"panenta",
"panento",
"panenuá",
"panenva",
"pangaie",
"pangais",
"pangaus",
"pangava",
"panguem",
"pangíea",
"panhote",
"paniame",
"panical",
"paniega",
"panigau",
"panisco",
"panocas",
"panoilo",
"panopse",
"panorés",
"panotos",
"panqueá",
"panriai",
"panriam",
"panriei",
"panriem",
"panries",
"panriou",
"panteai",
"panteei",
"panteie",
"panteio",
"panteou",
"panzuai",
"panzuam",
"panzuar",
"panzuas",
"panzuei",
"panzuem",
"panzues",
"panzuou",
"panéolo",
"panícea",
"paníceo",
"panúrea",
"papadão",
"papagas",
"papante",
"papanás",
"paparim",
"paparmo",
"papatas",
"papataz",
"papaíra",
"papeará",
"papeiem",
"papejai",
"papejam",
"papejas",
"papejei",
"papejem",
"papejes",
"papejou",
"papemos",
"papiada",
"papiado",
"papiais",
"papiamo",
"papiará",
"papiava",
"papieis",
"papiemo",
"papocai",
"papoias",
"papoile",
"papoilo",
"paposos",
"papotes",
"papoucá",
"papoule",
"papucos",
"papujai",
"papujam",
"papujas",
"papujei",
"papujem",
"papujes",
"papujou",
"papusso",
"papuzes",
"papuíra",
"papírea",
"paqueló",
"parabão",
"paracme",
"paraleu",
"pararis",
"parasca",
"paratoe",
"paravaí",
"parbiça",
"parbões",
"parceai",
"parcear",
"parceei",
"parceie",
"parceou",
"parchas",
"parcheá",
"parchos",
"pardamo",
"pardará",
"pardava",
"pardeci",
"pardeis",
"pardeje",
"pardelo",
"pardemo",
"pardexo",
"pardeça",
"pardeço",
"pardoso",
"pareais",
"pareamo",
"pareara",
"pareeis",
"pareemo",
"paregle",
"paregum",
"pareies",
"parejai",
"parejam",
"parejar",
"parejei",
"parejem",
"parejes",
"parejou",
"parelhe",
"paresie",
"paresio",
"pariara",
"parimás",
"parirmo",
"paritás",
"parlada",
"parlará",
"parleis",
"parmana",
"parnaus",
"parnope",
"parnões",
"paroica",
"paroico",
"parolai",
"parolei",
"parolem",
"paroleá",
"paropse",
"paroreu",
"parpejo",
"parrafa",
"parrafe",
"parramo",
"parrará",
"parrava",
"parreis",
"parrelo",
"parremo",
"parrica",
"parrico",
"parrolo",
"parrono",
"parroná",
"parrosa",
"parruma",
"parruás",
"parteje",
"partemo",
"partuno",
"partuns",
"parturo",
"partusa",
"partuso",
"partzia",
"parucas",
"parucos",
"parurus",
"parvajã",
"parvena",
"parvões",
"parábia",
"parídea",
"paríeis",
"parísia",
"paródon",
"pascada",
"pascaró",
"pascias",
"pascida",
"pascido",
"pascoai",
"pascoel",
"pascoem",
"pascoou",
"pasiras",
"pasires",
"pasmões",
"passajo",
"passeje",
"passejo",
"passejá",
"pastamo",
"pasteje",
"pastemo",
"pasçamo",
"patacoa",
"patagãs",
"pataica",
"pataico",
"pataleá",
"patanau",
"patanhe",
"patanho",
"patavar",
"patazes",
"pateais",
"patecal",
"pateeis",
"patefaz",
"patefez",
"patefiz",
"pategai",
"pategam",
"pategou",
"pategue",
"pateiem",
"pateies",
"patejai",
"patejas",
"patejei",
"patejem",
"patejes",
"patejou",
"patelos",
"patemar",
"patemas",
"patetai",
"patetam",
"patetem",
"pateteá",
"patetou",
"patiado",
"patiais",
"patiamo",
"patiará",
"patiava",
"patieis",
"patiemo",
"patilau",
"patimas",
"patinai",
"patismo",
"patoila",
"patoile",
"patolam",
"patolem",
"patoles",
"patonha",
"patorás",
"patorés",
"patrato",
"patrize",
"patrizo",
"patroai",
"patroam",
"patroei",
"patroem",
"patrolo",
"patuleá",
"patunos",
"paturés",
"patália",
"patálio",
"patélea",
"patíria",
"pauatês",
"pauiana",
"paulamo",
"paulava",
"pauleis",
"paulemo",
"paulite",
"paurope",
"paururo",
"pausamo",
"pauseis",
"pausemo",
"paussos",
"pauteai",
"pauteei",
"pauteie",
"pauteio",
"pauteis",
"pavames",
"pavejai",
"pavejam",
"pavejas",
"pavejei",
"pavejem",
"pavejes",
"pavejou",
"pavenas",
"pavesai",
"pavesei",
"pavesem",
"pavesou",
"pavetas",
"paviões",
"pavonas",
"pavunas",
"paxaril",
"paxaris",
"paxaxos",
"paxicás",
"paxilar",
"paxilos",
"paxitas",
"pazeada",
"pazeará",
"pazeava",
"pazeeis",
"pazeiem",
"pazeies",
"pazense",
"pazigue",
"pazonas",
"pazíguo",
"paçonas",
"paélias",
"paídias",
"peadona",
"peadora",
"peadões",
"pealada",
"pealais",
"pealamo",
"pealara",
"pealeis",
"pealemo",
"pealhas",
"pealhos",
"pealita",
"pealite",
"pealzão",
"peanhos",
"peantes",
"peanças",
"peardes",
"peariam",
"pearias",
"pearças",
"peascas",
"peassem",
"peasses",
"peastes",
"peaçona",
"pebense",
"pecarmo",
"pecelho",
"pecenha",
"pecenho",
"pecetas",
"pechais",
"pechamo",
"pecheis",
"pechemo",
"pechosa",
"pechéns",
"pecites",
"pecoapá",
"pecoreá",
"pedalai",
"pedalva",
"pedatas",
"pedeses",
"pedesia",
"pedicai",
"pedicam",
"pedicar",
"pedicou",
"pedingã",
"pedinhe",
"pedirmo",
"pediões",
"pedocal",
"pedramo",
"pedremo",
"pedreta",
"pedânea",
"pedíaca",
"pedíaco",
"pedícia",
"pedíeas",
"peeiros",
"peganhe",
"peganta",
"pegarmo",
"pegaseu",
"pegatas",
"pegmina",
"pegudas",
"pegudos",
"pegungo",
"pegunha",
"pegunho",
"pegásia",
"pegômia",
"peidais",
"peideis",
"peidemo",
"peinais",
"peinamo",
"peinara",
"peinava",
"peinaço",
"peindes",
"peinemo",
"peiotle",
"peiouga",
"peiraus",
"peirões",
"peitamo",
"peiteis",
"peitemo",
"peixado",
"peixais",
"peixamo",
"peixara",
"peixava",
"peixemo",
"peixãos",
"peixóis",
"pejadão",
"pejamos",
"pejarei",
"pejares",
"pejarmo",
"pejarão",
"pejavas",
"pejeira",
"pejemos",
"pejinho",
"pejorai",
"pejoras",
"pejorei",
"pejorem",
"pejosas",
"pelacil",
"pelacir",
"pelacis",
"pelagão",
"pelagãs",
"pelarei",
"pelarmo",
"pelecha",
"peleche",
"pelecos",
"pelecra",
"peleeis",
"peleies",
"pelemia",
"pelenes",
"peleneu",
"peleoas",
"peleona",
"pelicha",
"pelicés",
"pelidai",
"pelidam",
"pelidar",
"pelidas",
"pelidei",
"pelidem",
"pelidou",
"pelocos",
"peloira",
"pelopeu",
"peloteá",
"peloura",
"peltela",
"peltena",
"pelteno",
"peltope",
"peludeá",
"pelumba",
"pelécio",
"pemanas",
"pembere",
"pembero",
"pemberá",
"pembrai",
"pembram",
"pembrar",
"pembras",
"pembrei",
"pembrem",
"pembres",
"pembrou",
"pembéji",
"penachá",
"penaias",
"penaras",
"penarmo",
"penavas",
"pencões",
"pendais",
"pendemo",
"pendoai",
"pendoas",
"pendoei",
"pendoem",
"pendore",
"pendoro",
"pendros",
"penegos",
"peneias",
"penejai",
"penejam",
"penejas",
"penejei",
"penejem",
"penejes",
"penejou",
"penetas",
"penicai",
"penicou",
"penilho",
"pentoro",
"penujai",
"penujam",
"penujas",
"penujei",
"penujou",
"peoadas",
"peoagem",
"peonina",
"peonóis",
"peopaia",
"pepelés",
"pepevas",
"pepeúas",
"pepinai",
"pepinam",
"pepinas",
"pepinei",
"pepinem",
"pepines",
"pepione",
"pepiras",
"pepiças",
"pepoaza",
"pepoaça",
"pepsias",
"peptize",
"peptizo",
"pepuaça",
"pepuxis",
"pequeai",
"pequear",
"pequeei",
"pequeia",
"pequeie",
"pequeou",
"pequins",
"peragre",
"peragro",
"peragrá",
"peramas",
"peravas",
"perbuna",
"percava",
"perchai",
"percham",
"perchar",
"perchei",
"perchem",
"perchou",
"percuda",
"percude",
"percudi",
"percudo",
"perevas",
"perexis",
"perfiai",
"perfiam",
"perfiar",
"perfias",
"perfiei",
"perfiem",
"perfiou",
"pergeia",
"pergeus",
"periaca",
"periati",
"periato",
"peridás",
"perigai",
"perilas",
"perilos",
"perimam",
"perimas",
"perimes",
"perimia",
"perimis",
"perimiu",
"perimão",
"peritai",
"peritam",
"peritei",
"perites",
"peritou",
"perlais",
"perlamo",
"perlave",
"perleis",
"perleje",
"perlejo",
"perlejá",
"perlemo",
"perlico",
"perluiz",
"perluza",
"perluze",
"perluzi",
"perluzo",
"permeai",
"permude",
"permudo",
"perneai",
"perneja",
"perneje",
"pernejo",
"pernins",
"pernuca",
"perobeá",
"perogis",
"peroide",
"perolai",
"perolam",
"perolei",
"perolou",
"perorai",
"perores",
"perorsa",
"perorso",
"perosas",
"peroses",
"perotas",
"perovas",
"perozil",
"perozis",
"perpaus",
"perreai",
"perreba",
"perrebo",
"perreei",
"perreie",
"perreou",
"perrica",
"perruns",
"peruais",
"peruamo",
"peruará",
"perudas",
"perueis",
"peruemo",
"perunca",
"pervada",
"pervadi",
"pervage",
"pervais",
"perveis",
"pervemo",
"perviam",
"pervida",
"pervido",
"peréons",
"perézia",
"perónia",
"perótis",
"pesarmo",
"pescamo",
"pesgais",
"pesgará",
"pesgava",
"pesguem",
"pesgues",
"pesteai",
"pesteei",
"pesteio",
"pesumes",
"petacno",
"petadas",
"petador",
"petados",
"petadão",
"petamos",
"petaram",
"petarde",
"petarei",
"petarem",
"petaria",
"petarmo",
"petarás",
"petarão",
"petasse",
"petaste",
"petavam",
"petavas",
"peteais",
"peteará",
"peteava",
"petecai",
"petecam",
"peteeis",
"petefes",
"petegai",
"petegam",
"petegas",
"petegou",
"petegue",
"peteiam",
"peteias",
"peteiem",
"peteies",
"petemas",
"petemos",
"peteque",
"petilhe",
"petilhá",
"petimas",
"petipés",
"petitai",
"petitam",
"petitei",
"petitem",
"petiças",
"petorra",
"petrais",
"petrins",
"petrite",
"petumbo",
"petumes",
"petuncé",
"petunse",
"petênia",
"peucena",
"peuceno",
"peucina",
"peucino",
"peulzão",
"peuvais",
"pevidas",
"pevonas",
"pexetis",
"pexotai",
"pexotam",
"pexotas",
"pexotei",
"pexotem",
"pexoteá",
"pexotou",
"pezanha",
"pezanho",
"pezenha",
"pezizas",
"pezudas",
"pezunhe",
"pezunhá",
"pezóbio",
"peáreis",
"peáveis",
"peçotes",
"peúlbes",
"pfucada",
"pfucado",
"pfucais",
"pfucamo",
"pfucará",
"pfucava",
"pfuquei",
"pfuquem",
"pfuques",
"piabado",
"piabais",
"piabamo",
"piabará",
"piabava",
"piabeis",
"piabemo",
"piabonu",
"piabuca",
"piabuco",
"piachai",
"piacham",
"piachar",
"piachas",
"piachei",
"piachem",
"piachos",
"piachou",
"piadões",
"piafada",
"piafado",
"piafais",
"piafamo",
"piafará",
"piafava",
"piafeis",
"piafemo",
"piaiuva",
"pialada",
"pialais",
"pialamo",
"pialará",
"pialava",
"pialeis",
"pialemo",
"pialhai",
"pialham",
"pialhar",
"pialhas",
"pialhei",
"pialhem",
"pialhes",
"pialhou",
"pialzão",
"piambas",
"piambre",
"piancei",
"piancem",
"piances",
"pianize",
"pianizo",
"pianizá",
"pianole",
"pianolo",
"pianoma",
"piançai",
"piançam",
"pianças",
"piançou",
"piardes",
"piareis",
"piaremo",
"piarias",
"piarmos",
"piartro",
"piarças",
"piascos",
"piavuna",
"piazita",
"piaçona",
"pibrete",
"picains",
"picalva",
"picalvo",
"picamar",
"picaneá",
"picarça",
"picavas",
"picaxos",
"picaços",
"picaçus",
"piceína",
"piceóis",
"pichais",
"pichamo",
"pichela",
"pichelo",
"pichemo",
"pichias",
"pichica",
"pichobo",
"pichosa",
"pichões",
"picicas",
"picitas",
"picites",
"picnita",
"picnite",
"picnito",
"picnode",
"picoide",
"picolas",
"piconol",
"picotai",
"picrela",
"picrena",
"picrilo",
"picrita",
"picróis",
"pictita",
"pictite",
"picuada",
"picuado",
"picuais",
"picuamo",
"picuará",
"picuava",
"picueis",
"picuemo",
"picácea",
"picáceo",
"picínea",
"picóbia",
"pidneia",
"pidneus",
"piedora",
"pieiros",
"piemias",
"pieremo",
"pieriam",
"piermos",
"piesmas",
"piessem",
"piesses",
"piestes",
"pifadão",
"pifante",
"pifaras",
"pifarei",
"pifares",
"pifarmo",
"pifaste",
"pifavas",
"pifemos",
"pigador",
"pigadão",
"pigamos",
"pigante",
"pigaram",
"pigarei",
"pigarem",
"pigares",
"pigaria",
"pigarás",
"pigarão",
"pigaste",
"pigavam",
"pigavas",
"pigaças",
"pigeras",
"pigmena",
"pigueis",
"piidona",
"piidões",
"piistas",
"pijeira",
"pijeiro",
"pijucas",
"piladão",
"pilaira",
"pilamos",
"pilanas",
"pilanga",
"pilarei",
"pilaria",
"pilarmo",
"pilavas",
"pilchai",
"pilchem",
"pilches",
"pildada",
"pildado",
"pildais",
"pildamo",
"pildará",
"pildava",
"pildeis",
"pildemo",
"pildrai",
"pildram",
"pildrar",
"pildrei",
"pildrem",
"pildres",
"pildrou",
"pileato",
"pilecos",
"pilecra",
"pileela",
"pilegra",
"pilemos",
"pilense",
"pilento",
"pileras",
"pilhamo",
"pilheis",
"pilhemo",
"pilinas",
"pilites",
"piloias",
"piloira",
"piloiro",
"pilomas",
"pilonas",
"pilonge",
"piloria",
"piloses",
"piloteá",
"pilreta",
"pilrões",
"pilulai",
"pilulam",
"pilulei",
"pilulem",
"pilulou",
"pilácea",
"piláceo",
"piládia",
"piléolo",
"pilésia",
"pimbais",
"pimbamo",
"pimbará",
"pimbava",
"pimbeis",
"pimbemo",
"pimpais",
"pimpaiã",
"pimpamo",
"pimpara",
"pimpava",
"pimpeis",
"pimpemo",
"pimplai",
"pimplam",
"pimplar",
"pimplei",
"pimplem",
"pimpolo",
"pimpone",
"pimpono",
"pimpóis",
"pinadão",
"pinamas",
"pinanas",
"pinanos",
"pinarei",
"pinarmo",
"pinarão",
"pinavam",
"pinavas",
"pinazes",
"pinaúma",
"pincemo",
"pinchem",
"pincres",
"pindrai",
"pindram",
"pindrar",
"pindras",
"pindrei",
"pindrem",
"pindres",
"pindrou",
"pinelai",
"pinelam",
"pinelar",
"pinelei",
"pinelem",
"pinelou",
"pinemos",
"pinense",
"pingais",
"pingamo",
"pingolo",
"pingosa",
"pinheim",
"pinhoão",
"pinhuns",
"pinhéis",
"pinhéns",
"pinicai",
"pinilho",
"pinimbe",
"pinimbo",
"pininas",
"pinocai",
"pinocou",
"pinoias",
"pinoios",
"pinoque",
"pinotai",
"pinotas",
"pinotem",
"pinoíta",
"pinques",
"pinusco",
"pinçamo",
"pinçona",
"pinélea",
"pinélia",
"piocaba",
"piocele",
"piochos",
"pioemia",
"pioides",
"piolhai",
"piolham",
"piolhei",
"piolhem",
"piolhes",
"piolhou",
"piolins",
"pionona",
"pionoto",
"piopios",
"pioramo",
"piorana",
"pioremo",
"piorios",
"piornas",
"piornes",
"piotina",
"pipados",
"pipadão",
"pipamos",
"pipante",
"piparam",
"piparei",
"pipares",
"piparmo",
"piparás",
"piparão",
"pipasse",
"pipaste",
"pipavam",
"pipavas",
"pipelês",
"pipemos",
"piperal",
"pipetai",
"pipetei",
"pipetem",
"pipiada",
"pipiado",
"pipiais",
"pipiamo",
"pipiará",
"pipieis",
"pipiemo",
"pipilai",
"pipilas",
"pipilei",
"pipilem",
"pipilro",
"pipinai",
"pipinam",
"pipinei",
"pipinem",
"pipines",
"pipiris",
"pipitai",
"pipitam",
"pipitei",
"pipitem",
"pipites",
"pipocai",
"pipturo",
"pipucos",
"pipídea",
"piqueai",
"piquear",
"piqueei",
"piqueio",
"piqueis",
"piqueou",
"piraias",
"piraiús",
"pirajis",
"pirajiá",
"pirajém",
"pirales",
"pirango",
"piranta",
"piraoás",
"pirarei",
"pirarmo",
"pirarão",
"piravas",
"piraéns",
"pirchas",
"pirecos",
"pireias",
"piremas",
"pirenas",
"pirenes",
"pirengo",
"pirenol",
"pirezas",
"pirgoma",
"piriche",
"pirijau",
"pirimas",
"pirixis",
"pirizes",
"pirobos",
"pirocai",
"pirocam",
"pirocou",
"piroide",
"piroles",
"piroque",
"pirosal",
"piroses",
"pirotes",
"pirreia",
"pirrona",
"piruera",
"piruete",
"pirueto",
"pirusta",
"pirusto",
"pirális",
"pirâmia",
"pirélia",
"piscato",
"piseias",
"pisgada",
"pisgais",
"pisidas",
"pisitar",
"pisoais",
"pisoamo",
"pisoará",
"pisoava",
"pisoeis",
"pisoemo",
"pisoide",
"pisorga",
"pispeto",
"pissita",
"pissite",
"pissito",
"pissoda",
"pissode",
"pissota",
"pistamo",
"pistara",
"pistava",
"pistemo",
"pisueta",
"pisália",
"pisânia",
"pitacal",
"pitadeá",
"pitadão",
"pitafos",
"pitaléu",
"pitaneu",
"pitarei",
"pitarmo",
"pitarão",
"pitaste",
"pitaula",
"pitaulo",
"pitauás",
"pitavas",
"piteire",
"pitemos",
"pitigar",
"pitimas",
"pitinta",
"pitipés",
"pitiuns",
"pitomas",
"pitoras",
"pitosas",
"pitosgo",
"pitotes",
"pituins",
"pitulas",
"pituris",
"pitávia",
"pitécia",
"pitécio",
"pitíada",
"pitíade",
"pitídea",
"pitídeo",
"piucada",
"piucado",
"piucais",
"piucamo",
"piucará",
"piucava",
"piulcos",
"piuquei",
"piuques",
"piuquém",
"pivelos",
"pivocas",
"pivotai",
"pivotas",
"pixeada",
"pixeado",
"pixeais",
"pixeara",
"pixeava",
"pixeeis",
"pixeiam",
"pixeias",
"pixeiem",
"pixeies",
"pixerai",
"pixeram",
"pixerar",
"pixeras",
"pixerei",
"pixerem",
"pixeres",
"pixerou",
"pixorca",
"pixorco",
"pixorga",
"pixorgo",
"pixorés",
"pixuris",
"pixuíra",
"pixídea",
"pixídeo",
"pixínea",
"pixíneo",
"pixínia",
"pixúria",
"piáveis",
"piçarre",
"piérica",
"piérico",
"piérios",
"piêmica",
"piêreis",
"piíamos",
"piógena",
"piógeno",
"piósica",
"piósico",
"piôneas",
"piônios",
"piúrica",
"placite",
"placusa",
"plagiai",
"plagies",
"plagões",
"plainai",
"planamo",
"plancho",
"planeai",
"planeis",
"planges",
"plangeu",
"planham",
"planhas",
"planhem",
"planhes",
"planhia",
"planhir",
"planhis",
"planhiu",
"planize",
"planizo",
"planjas",
"planzém",
"plaqueá",
"plasmai",
"platido",
"platuro",
"plaudam",
"plaudas",
"plaudes",
"plaudiu",
"plebana",
"plebize",
"plebizo",
"plebizá",
"plecoto",
"plectos",
"pleitai",
"pleitem",
"pleites",
"plengas",
"plengos",
"plenita",
"plenito",
"plereta",
"pletore",
"pletoro",
"pleuros",
"plexias",
"pleínea",
"pleíneo",
"pleônio",
"plicais",
"plicamo",
"plidada",
"plidado",
"plidais",
"plidamo",
"plidará",
"plidava",
"plideis",
"plidemo",
"plinite",
"plioípo",
"plissai",
"plissam",
"plissei",
"plissem",
"ploeira",
"ploeiro",
"ploimas",
"ploiras",
"plorins",
"plosona",
"plotais",
"plotamo",
"ploteis",
"plotemo",
"plotosa",
"plotoso",
"plugais",
"plugamo",
"plumais",
"plumamo",
"plumará",
"plumava",
"plumeis",
"plumeja",
"plumeje",
"plumejo",
"plumemo",
"plutela",
"plutelo",
"plácopo",
"plátace",
"plátana",
"plátite",
"plázias",
"plésias",
"plévias",
"plínias",
"plóceas",
"plóceos",
"plócias",
"plódias",
"plótzia",
"plúmeas",
"plúteas",
"plúvios",
"pnoirmo",
"poalhos",
"pobilas",
"poburas",
"pocadão",
"pocanga",
"pocarei",
"pocares",
"pocarmo",
"pocarás",
"pocarão",
"pocasse",
"pocaste",
"pocavas",
"pocaçus",
"pocecas",
"pocecos",
"pocomão",
"podabro",
"podadão",
"podante",
"podarmo",
"podaste",
"podaxos",
"podedor",
"podical",
"podidas",
"podidão",
"podilho",
"podogós",
"podoide",
"podoios",
"podoiro",
"podones",
"podonta",
"podopse",
"podormo",
"podraga",
"podreci",
"podreça",
"podreço",
"podruns",
"poduros",
"podália",
"podóxia",
"poedora",
"poeirai",
"poeirei",
"poeirem",
"poeires",
"poeirou",
"poentai",
"poentam",
"poentar",
"poentei",
"poentem",
"poentou",
"poerdes",
"poereis",
"poeremo",
"poescos",
"poessem",
"poesses",
"poestes",
"poetamo",
"poetemo",
"pogonos",
"pogoros",
"pogônia",
"poiador",
"poiadão",
"poiages",
"poiante",
"poiarem",
"poiarás",
"poiarão",
"poiasse",
"poiaste",
"poiavam",
"poiavas",
"poidona",
"poidões",
"poiemos",
"poilosa",
"poiloso",
"poisamo",
"poiseis",
"poisemo",
"poisóis",
"poitais",
"poitamo",
"poitava",
"poiteis",
"poitemo",
"poitões",
"pojador",
"pojadão",
"pojamos",
"pojando",
"pojarei",
"pojarem",
"pojaria",
"pojarmo",
"pojarás",
"pojarão",
"pojasse",
"pojaste",
"pojauru",
"pojavas",
"pojeias",
"pojemos",
"pojinho",
"pojinos",
"pojojis",
"polacis",
"polarda",
"polcada",
"polcado",
"polcais",
"polcamo",
"polcará",
"poldris",
"poleado",
"poleais",
"poleará",
"poleeis",
"polegai",
"polegam",
"polegou",
"polegue",
"poleiam",
"poleiem",
"poleies",
"polelas",
"poliase",
"policro",
"polieca",
"polifie",
"poliras",
"polirmo",
"politmo",
"polmeai",
"polmeei",
"polmeia",
"polmeie",
"polmeio",
"polmeou",
"polonas",
"polonga",
"polpais",
"polpamo",
"polpeis",
"polpemo",
"polpões",
"polquei",
"polquem",
"poltreá",
"poluais",
"poluías",
"políeas",
"políeis",
"políneo",
"polínoe",
"políodo",
"polítea",
"políteo",
"pomadeá",
"pomaris",
"pombeai",
"pombeei",
"pombeie",
"pombeio",
"pombela",
"pomeada",
"pomeado",
"pomeais",
"pomeará",
"pomeava",
"pomeeis",
"pomeiam",
"pomeias",
"pomeiem",
"pomeies",
"pomeira",
"pomejai",
"pomejam",
"pomejar",
"pomejas",
"pomejei",
"pomejem",
"pomejes",
"pomejou",
"pomitas",
"pomitos",
"pomizai",
"pomizam",
"pomizar",
"pomizas",
"pomizei",
"pomizem",
"pomizes",
"pomizou",
"pomosas",
"pompeai",
"pompeei",
"pompeie",
"pomárea",
"pomícea",
"pomíceo",
"pomóxis",
"ponacas",
"ponceis",
"poncemo",
"ponciva",
"poncivo",
"poneras",
"ponguei",
"ponguem",
"ponhará",
"ponheis",
"ponilha",
"ponoses",
"pontamo",
"ponteai",
"pontela",
"pontemo",
"pontoai",
"pontoam",
"pontoei",
"ponçais",
"ponçamo",
"ponçará",
"ponçava",
"popelas",
"poperis",
"popiada",
"popiado",
"popiais",
"popiamo",
"popiará",
"popiava",
"popieis",
"popiemo",
"popinas",
"popocai",
"popocam",
"popocou",
"popoque",
"populai",
"popóvia",
"poqueis",
"poracás",
"poranas",
"porcaço",
"porcoça",
"porcuns",
"porejai",
"porejas",
"porejei",
"porejem",
"porejes",
"porneus",
"porocos",
"porodia",
"porodós",
"poromas",
"poroses",
"porotas",
"porpaus",
"porrege",
"porregi",
"porreja",
"porrejo",
"porroma",
"porruns",
"portajo",
"porteai",
"porteei",
"porteie",
"porteou",
"porucas",
"porutis",
"porzita",
"porácea",
"poráceo",
"porânia",
"porômia",
"porônia",
"posadão",
"posaras",
"posarmo",
"posavas",
"posinga",
"pospomo",
"posporá",
"posseai",
"posseei",
"posseie",
"posseis",
"possemo",
"posseou",
"possoca",
"possuca",
"posteai",
"postego",
"posteje",
"posteme",
"postice",
"postile",
"postilo",
"posuais",
"potabas",
"potança",
"potavas",
"poteias",
"potiais",
"potiale",
"poticis",
"potiris",
"potirom",
"potirão",
"potobós",
"potocai",
"potocam",
"potocou",
"potonas",
"pototes",
"potreai",
"potreei",
"potreie",
"potreou",
"potrosa",
"potruco",
"potruda",
"pottial",
"potâmea",
"potâmeo",
"potíeas",
"potóeas",
"poucões",
"poupona",
"poupões",
"pousamo",
"pousias",
"poutada",
"poutado",
"poutais",
"poutamo",
"poutará",
"poutava",
"pouteis",
"poutemo",
"povoeis",
"povoemo",
"povoréu",
"povotos",
"poxetis",
"poxoxôs",
"poáceos",
"poçaúna",
"poçucas",
"poéfaga",
"poéfago",
"pracato",
"praceai",
"pracebo",
"praceei",
"praceia",
"praceie",
"praceio",
"praceja",
"praceje",
"pracejo",
"praceou",
"pradeja",
"pradeje",
"pradejo",
"pradosa",
"praguei",
"praguem",
"praguás",
"pragões",
"prainas",
"praneza",
"prangas",
"praniza",
"prantai",
"prantem",
"praquia",
"praquio",
"prasmai",
"prasmam",
"prasmas",
"prasmei",
"prasmem",
"prasmes",
"prasmou",
"prateai",
"prateei",
"prateio",
"prateje",
"pratejo",
"pratejá",
"pratita",
"pratéis",
"praxais",
"praxamo",
"praxará",
"praxemo",
"prazida",
"preadas",
"preadão",
"preamai",
"preamam",
"preamas",
"preamei",
"preamem",
"preames",
"preamos",
"preamou",
"prearei",
"prearem",
"preares",
"prearia",
"prearás",
"prearão",
"preaste",
"preavas",
"preclua",
"precluo",
"predais",
"predeem",
"predeis",
"predite",
"prefará",
"prefaze",
"prefaça",
"prefaço",
"prefini",
"prefino",
"pregana",
"pregnai",
"pregnas",
"pregnei",
"pregnem",
"pregnes",
"pregnou",
"pregoai",
"pregoas",
"pregoem",
"preitai",
"preitam",
"preitei",
"preitem",
"preiteá",
"preitou",
"preitês",
"preliai",
"preliam",
"prelias",
"preliei",
"preliem",
"prelies",
"preluza",
"preluze",
"preluzi",
"preluzo",
"premais",
"premamo",
"premará",
"premava",
"premeis",
"prememo",
"premimo",
"premude",
"premudo",
"premuna",
"premuno",
"prenhai",
"prenhei",
"prenhem",
"prenhou",
"prenote",
"prenoto",
"prensai",
"prepaus",
"prepomo",
"preporá",
"presais",
"presamo",
"presará",
"presbis",
"preseis",
"preselo",
"presemo",
"preteai",
"preteei",
"preteie",
"preteio",
"prevali",
"prevemo",
"previva",
"previvi",
"prezemo",
"priacas",
"prianta",
"priapeu",
"prijuís",
"primamo",
"primemo",
"priorai",
"prioram",
"priorei",
"priscam",
"prismai",
"prismei",
"prismem",
"prismou",
"prisona",
"prisque",
"pristos",
"prixita",
"prióbio",
"priônia",
"proador",
"proados",
"proadão",
"proamos",
"proante",
"proaram",
"proarei",
"proarem",
"proaria",
"proarmo",
"proarás",
"proarão",
"proasse",
"proavam",
"prodajo",
"prodero",
"proejai",
"proejam",
"proejas",
"proejei",
"proejem",
"proejes",
"proemie",
"proemos",
"prognes",
"proizes",
"prolana",
"prolano",
"prolans",
"propaus",
"propoer",
"prorana",
"prorano",
"prosais",
"prosamo",
"prosará",
"proseai",
"prosena",
"protana",
"protaro",
"proteal",
"protoca",
"protono",
"protéea",
"prouvem",
"prouvia",
"prouvir",
"prouviu",
"prouvês",
"provimo",
"prozado",
"prozais",
"prozamo",
"prozara",
"prozava",
"prozeis",
"prozemo",
"proávia",
"pruamos",
"pruidor",
"pruidão",
"pruindo",
"pruirei",
"pruiria",
"pruirão",
"prumais",
"prumamo",
"prumará",
"prumeis",
"prumela",
"prumemo",
"prumões",
"prurais",
"pruramo",
"prurias",
"prurimo",
"prurirá",
"pruídas",
"pruíeis",
"pruímos",
"pruínas",
"pruíram",
"pruíras",
"pruírem",
"pruíres",
"pruísse",
"pruíste",
"pruível",
"prácias",
"prásios",
"prânale",
"préguas",
"préslia",
"prétiga",
"prézeas",
"prênica",
"prênico",
"prímnoa",
"príonos",
"prísmea",
"prísopo",
"prítego",
"prítive",
"próavos",
"prócemo",
"próciam",
"prócias",
"prócido",
"prócies",
"próclea",
"prócnia",
"pródica",
"pródita",
"prófaga",
"prógona",
"prólica",
"prórops",
"próteos",
"prótios",
"próçais",
"próçamo",
"prônoes",
"prúnulo",
"psameco",
"psamita",
"psamite",
"psefita",
"psefoto",
"psefuro",
"psiadas",
"psiados",
"psilago",
"psileta",
"psilope",
"psilura",
"psitino",
"psoítes",
"psálide",
"psátira",
"psélafo",
"psélios",
"psésias",
"psésios",
"psêudis",
"psídios",
"psílida",
"psílios",
"psílota",
"psíloto",
"psítaca",
"psítias",
"psítios",
"psítira",
"psítiro",
"psódimo",
"psófias",
"psófida",
"pteleia",
"pterela",
"pterial",
"ptialos",
"ptilino",
"ptéleas",
"ptéride",
"ptérila",
"ptérilo",
"ptérios",
"ptérois",
"ptéropa",
"ptéropo",
"ptérula",
"ptênios",
"ptênopo",
"puadona",
"puadora",
"puadões",
"puantes",
"puardes",
"puaremo",
"puariam",
"puarias",
"puarmos",
"puassem",
"puasses",
"puastes",
"pubadas",
"pubador",
"pubados",
"pubadão",
"pubamos",
"pubante",
"pubaram",
"pubarei",
"pubares",
"pubaria",
"pubarmo",
"pubarás",
"pubarão",
"pubasse",
"pubaste",
"pubavam",
"pubavas",
"pubeira",
"pubemos",
"pubesce",
"pubesci",
"pubesça",
"pubesço",
"pucarim",
"puchons",
"pucinas",
"pucinos",
"pucumãs",
"pucuris",
"pudamos",
"pudedor",
"pudevém",
"pudiana",
"pudiano",
"pudidas",
"pudidão",
"pudivão",
"pudlada",
"pudlais",
"pudlamo",
"pudlará",
"pudlava",
"pudleis",
"pudlemo",
"pudoras",
"pudvéns",
"pudíeis",
"puerice",
"puetana",
"pufista",
"pugilai",
"pugilam",
"pugilas",
"pugilei",
"pugilem",
"pugnais",
"pugneis",
"pugnemo",
"pugécia",
"puidona",
"puidora",
"puidões",
"puirdes",
"puireis",
"puiriam",
"puirias",
"puirmos",
"puitada",
"puitais",
"puitamo",
"puitará",
"puitava",
"puiteis",
"puitemo",
"puitena",
"pujacás",
"pujador",
"pujados",
"pujadão",
"pujamos",
"pujarei",
"pujarem",
"pujares",
"pujaria",
"pujarmo",
"pujarás",
"pujarão",
"pujasse",
"pujaste",
"pujavam",
"pujavas",
"pujemos",
"pujixás",
"puladão",
"pularmo",
"puleada",
"puleado",
"puleais",
"puleara",
"puleato",
"puleava",
"puleeis",
"puleiam",
"puleias",
"puleiem",
"puleies",
"pulhada",
"pulhais",
"pulhamo",
"pulhará",
"pulhava",
"pulhemo",
"pulhote",
"puliato",
"pulicai",
"pulinhe",
"pulizes",
"pulonas",
"pulques",
"pulsais",
"pulsamo",
"pulseai",
"pulseei",
"pulseie",
"pulseio",
"pulseis",
"pulsemo",
"pulseou",
"pulsona",
"pulsota",
"pultais",
"pultamo",
"pultará",
"pultava",
"pulteis",
"pultemo",
"puluaga",
"pululai",
"pululei",
"pulules",
"pulvato",
"pulvine",
"pulzões",
"pulênia",
"pumacaá",
"pumaris",
"pumpros",
"pumumos",
"punacas",
"punarus",
"punceje",
"puncejo",
"puncejá",
"puncemo",
"pungias",
"pungues",
"pungueá",
"punhões",
"punidão",
"punirmo",
"punjais",
"puntado",
"puntamo",
"puntará",
"puntava",
"punteis",
"puntemo",
"punçais",
"punçamo",
"punçará",
"punçava",
"punçoai",
"punçoam",
"punçoas",
"punçoei",
"punçoem",
"punçona",
"punçoou",
"pupadas",
"pupador",
"pupadão",
"pupamos",
"pupante",
"puparei",
"pupares",
"puparmo",
"puparás",
"puparão",
"pupasse",
"pupaste",
"pupavam",
"pupavas",
"pupemos",
"pupilai",
"pupilei",
"pupiles",
"pupilou",
"pupinas",
"pupália",
"pupável",
"pupídea",
"pupídeo",
"puracés",
"puravás",
"pureias",
"purgais",
"purgamo",
"puronas",
"purrona",
"purucas",
"pururus",
"putauás",
"puteará",
"puteeis",
"puteiam",
"puteias",
"puteiem",
"puteies",
"puticis",
"putilas",
"putreia",
"putucas",
"putória",
"putório",
"puxacar",
"puxança",
"puxarmo",
"puxiana",
"puxiris",
"puáreis",
"puáveis",
"puçaguá",
"puçucas",
"puélias",
"puérulo",
"puíamos",
"puísses",
"puístes",
"pábstia",
"págasas",
"pálades",
"pálpulo",
"pálreas",
"páquima",
"páragos",
"páreses",
"párifas",
"párpada",
"párrida",
"párseas",
"párulas",
"párvoas",
"párvuas",
"párvuos",
"pátulas",
"pátulos",
"páulias",
"páuropo",
"páuseas",
"pávulas",
"pâmelas",
"pâmpalo",
"pâmpana",
"pândino",
"pândios",
"pânfaga",
"pânfago",
"pânfoba",
"pângeno",
"pângios",
"pântara",
"pânteos",
"péciles",
"pécilos",
"péctase",
"péderos",
"pédipes",
"pédites",
"péfilos",
"péganas",
"péganos",
"pégnias",
"péguias",
"pélamis",
"pénulas",
"pénulos",
"péplida",
"pépsica",
"pércida",
"périfos",
"pérneas",
"pértugo",
"pésicas",
"pésuras",
"pésuros",
"péxicas",
"pêmenos",
"pêmpade",
"pênopos",
"pênsile",
"pêntese",
"pêramis",
"pêrsego",
"pícidas",
"pícnios",
"pícreas",
"pícreos",
"pícrias",
"pícrica",
"píctico",
"píctone",
"pífanas",
"pígopos",
"pílcias",
"pímplea",
"pímpleo",
"píndica",
"píndico",
"pínicos",
"píntega",
"pípidas",
"píprida",
"píretos",
"píricas",
"pírolos",
"pírrida",
"pírrula",
"pístias",
"pítagos",
"pítanos",
"pítigas",
"pítigos",
"pítilos",
"pódices",
"pódopes",
"pórions",
"pórrica",
"pórrico",
"pórrios",
"pórteas",
"pótamis",
"pótreas",
"póttias",
"pômices",
"pômpilo",
"pônciro",
"pôngida",
"púceros",
"púchara",
"púcharo",
"púlices",
"púlvego",
"púlvica",
"púlvico",
"púracas",
"púrrias",
"púrrios",
"quadrai",
"quadrei",
"quaigue",
"quairas",
"quamato",
"quanteu",
"quantés",
"quarais",
"quaramo",
"quarará",
"quaremo",
"quartem",
"quaruná",
"quarços",
"quassai",
"quassam",
"quassas",
"quassei",
"quassem",
"quasses",
"quassou",
"quatibo",
"quebucu",
"quedões",
"quegada",
"quegani",
"queguém",
"queijai",
"queijem",
"queijes",
"queilos",
"queinai",
"queinam",
"queinar",
"queinas",
"queinei",
"queinem",
"queinou",
"quelais",
"quelamo",
"quelara",
"quelemo",
"quelins",
"quelmas",
"quelmes",
"quelmos",
"quelmão",
"quelona",
"quelono",
"quelote",
"queluro",
"queluza",
"queléns",
"quembes",
"quenali",
"quendes",
"quendôs",
"quenhai",
"quenham",
"quenhar",
"quenhas",
"quenhei",
"quenhem",
"quenhes",
"quenhou",
"quentai",
"queomas",
"queomós",
"querabo",
"querene",
"querila",
"queruda",
"quesais",
"quesris",
"quetche",
"quetelo",
"quetote",
"quetris",
"quetuas",
"quetulo",
"quetura",
"queturo",
"quevéis",
"quezias",
"quezile",
"quezilo",
"quialas",
"quiamas",
"quiambo",
"quianja",
"quiaras",
"quiaris",
"quiassa",
"quiaças",
"quibabá",
"quibaco",
"quibana",
"quibeba",
"quibeto",
"quibire",
"quiboas",
"quibori",
"quibosa",
"quiboça",
"quibuto",
"quibuzo",
"quicabo",
"quicamo",
"quiceca",
"quiceco",
"quicemo",
"quicoas",
"quicole",
"quicuas",
"quidalê",
"quieira",
"quielas",
"quienol",
"quienza",
"quienze",
"quietai",
"quifaça",
"quifoci",
"quifuco",
"quifuge",
"quifuje",
"quifula",
"quifuxo",
"quifuça",
"quigalo",
"quiinas",
"quiismo",
"quiitas",
"quijara",
"quilato",
"quileba",
"quilema",
"quileto",
"quilhai",
"quilham",
"quilhau",
"quilhei",
"quilina",
"quilins",
"quiloiô",
"quilolo",
"quilota",
"quilote",
"quiloto",
"quilulo",
"quilzão",
"quilápi",
"quimama",
"quimaus",
"quimbai",
"quimbam",
"quimbei",
"quimbem",
"quimbes",
"quimboa",
"quimbou",
"quimias",
"quimone",
"quimosa",
"quimoso",
"quimões",
"quinais",
"quinama",
"quinamo",
"quinana",
"quinano",
"quinava",
"quineis",
"quinemo",
"quineno",
"quineto",
"quingai",
"quingam",
"quingar",
"quingas",
"quingou",
"quinhoe",
"quinhoo",
"quinins",
"quinite",
"quinola",
"quintei",
"quintem",
"quinzol",
"quinzos",
"quinóis",
"quioios",
"quiotas",
"quiovas",
"quiozas",
"quipata",
"quipede",
"quipele",
"quipica",
"quipico",
"quipiús",
"quipoto",
"quiquia",
"quiquis",
"quirabi",
"quirari",
"quirdis",
"quirial",
"quirins",
"quirita",
"quirule",
"quiruás",
"quisafu",
"quiscos",
"quisole",
"quispás",
"quissau",
"quitabo",
"quitaca",
"quitamo",
"quitece",
"quiteis",
"quitemo",
"quiteta",
"quiteus",
"quitias",
"quitira",
"quitiva",
"quitona",
"quitool",
"quitoto",
"quituio",
"quituis",
"quituxe",
"quituza",
"quitués",
"quitôli",
"quiumbe",
"quivúvi",
"quixiba",
"quixipá",
"quixobo",
"quixona",
"quizeco",
"quizile",
"quizilo",
"quizoco",
"quiâmbi",
"quiçafu",
"quiçapo",
"quiçaus",
"quotais",
"quotamo",
"quotará",
"quotava",
"quoteis",
"quotemo",
"quotizo",
"quáleas",
"québulo",
"quédios",
"quéleas",
"quélias",
"quépler",
"quérios",
"quéropo",
"quérops",
"quésias",
"quétida",
"quêilio",
"quênica",
"quênice",
"quênico",
"quênopo",
"quêntia",
"quêuper",
"quíbuas",
"quícola",
"quílios",
"quíloas",
"quínios",
"quíonis",
"quípais",
"quípeis",
"quípemo",
"quíperá",
"quípiam",
"quípias",
"quípida",
"quípido",
"quíries",
"quíxias",
"quócolo",
"quóculo",
"rababes",
"rabagem",
"rabanai",
"rabanam",
"rabanca",
"rabanei",
"rabanem",
"rabaneá",
"rabazes",
"rabaços",
"rabdita",
"rabdite",
"rabdito",
"rabdota",
"rabeais",
"rabeará",
"rabecos",
"rabeeis",
"rabeias",
"rabeies",
"rabeire",
"rabejai",
"rabejam",
"rabejas",
"rabejei",
"rabejem",
"rabejes",
"rabendo",
"rabetes",
"rabetos",
"rabiamo",
"rabidai",
"rabidam",
"rabidei",
"rabidem",
"rabides",
"rabidou",
"rabieis",
"rabiemo",
"rabigas",
"rabigos",
"rabilha",
"rabiose",
"rabolos",
"rabonai",
"rabonam",
"rabonei",
"rabonem",
"rabones",
"rabonou",
"rabosas",
"rabotas",
"rabotei",
"rabotem",
"rabotou",
"rabucha",
"rabucho",
"rabucos",
"rabuges",
"rabujai",
"rabujei",
"rabujes",
"rabujão",
"rabulai",
"rabulam",
"rabulei",
"rabulem",
"rabules",
"rabuleá",
"rabulou",
"rabunai",
"rabunam",
"rabunas",
"rabunei",
"rabunem",
"rabunes",
"rabuneá",
"rabunha",
"rabunhe",
"rabunou",
"rabusca",
"racaúte",
"raceais",
"raceará",
"raceava",
"raceeis",
"raceiam",
"raceias",
"raceiem",
"raceies",
"raceira",
"raceiro",
"rachais",
"rachamo",
"racheai",
"rachedo",
"racheia",
"racheie",
"racheio",
"racheis",
"rachemo",
"racheou",
"racinai",
"racinam",
"racinas",
"racinei",
"racinem",
"racinou",
"racoses",
"racódio",
"radiamo",
"radicia",
"radieis",
"radiemo",
"radobai",
"radobam",
"radobas",
"radobei",
"radobem",
"radobes",
"radobou",
"radolho",
"radotes",
"radubai",
"radubam",
"radubar",
"radubas",
"radubei",
"radubem",
"radubes",
"radubou",
"rafador",
"rafadão",
"rafalos",
"rafamos",
"rafando",
"rafania",
"rafaram",
"rafaras",
"rafarei",
"rafarem",
"rafares",
"rafarmo",
"rafarão",
"rafasse",
"rafaste",
"rafavam",
"rafavas",
"rafemos",
"raffada",
"raffado",
"raffais",
"raffamo",
"raffará",
"raffava",
"raffeis",
"raffemo",
"rafiada",
"rafiais",
"rafiamo",
"rafiara",
"rafieis",
"rafiemo",
"rafinai",
"rafinam",
"rafinar",
"rafinas",
"rafinaz",
"rafinei",
"rafinem",
"rafines",
"rafinou",
"rafiros",
"rafitas",
"rafites",
"rafânea",
"rafâneo",
"rafídea",
"rafídia",
"ragitas",
"ragites",
"ragiões",
"ragueda",
"raguita",
"raguras",
"raguseu",
"ragádio",
"ragódia",
"raiador",
"raiadão",
"raiamos",
"raiarei",
"raiarmo",
"raiaste",
"raiavas",
"raiemos",
"raigoto",
"raigões",
"raiinho",
"railhes",
"railuli",
"raiputa",
"raitana",
"raiunas",
"raivais",
"raivamo",
"raivará",
"raivaço",
"raivece",
"raiveci",
"raiveis",
"raiveja",
"raiveje",
"raivejo",
"raivemo",
"raiveça",
"raiveço",
"raivuda",
"raizepo",
"raiídea",
"rajabes",
"rajamos",
"rajaras",
"rajarei",
"rajares",
"rajarmo",
"rajasse",
"rajaste",
"rajavas",
"rajemos",
"rajidos",
"rajânia",
"rajídea",
"ralarmo",
"ralaste",
"raleais",
"raleeis",
"raleiem",
"raleies",
"raletes",
"ralezas",
"ralhamo",
"ralhemo",
"ralhões",
"ralicas",
"ralices",
"ralinas",
"ralutas",
"ralutos",
"ralável",
"ralídea",
"ralínea",
"ralíneo",
"ramalhe",
"ramamos",
"ramanga",
"ramango",
"ramaram",
"ramarei",
"ramarem",
"ramares",
"ramarmo",
"ramarás",
"ramarão",
"ramaste",
"ramavam",
"ramavas",
"ramazãs",
"rambana",
"rameada",
"rameais",
"rameará",
"rameava",
"rameeis",
"rameiam",
"rameias",
"rameiem",
"rameies",
"ramelai",
"ramelam",
"ramelem",
"rameles",
"ramemos",
"ramisas",
"ramisos",
"ramizal",
"ramnais",
"ramnina",
"ramnite",
"ramocai",
"ramocam",
"ramocar",
"ramocas",
"ramocou",
"rampana",
"rampane",
"rampano",
"rampeai",
"rampeei",
"rampeia",
"rampeie",
"rampeio",
"rampeou",
"ramísia",
"ranatra",
"ranceai",
"ranceei",
"ranceia",
"ranceie",
"ranceis",
"rancemo",
"ranceou",
"rancure",
"rancuro",
"randite",
"ranelas",
"ranfada",
"ranfado",
"ranfais",
"ranfamo",
"ranfará",
"ranfava",
"ranfeis",
"ranfemo",
"rangais",
"rangará",
"rangemo",
"rangirá",
"ranguem",
"ranhais",
"ranhamo",
"ranhará",
"ranhava",
"ranheis",
"ranhemo",
"ranhola",
"ranhure",
"ranhuro",
"ranhuze",
"ranhuzo",
"ranhuzá",
"ranhões",
"ranilhe",
"ranilho",
"raninas",
"raninos",
"ranismo",
"ranjais",
"ranjamo",
"ranoide",
"ranzais",
"ranária",
"rançais",
"rançamo",
"rançará",
"rançava",
"rançona",
"ranções",
"ranídea",
"ranília",
"ranínea",
"raníneo",
"rapagoa",
"rapanas",
"rapanos",
"raparmo",
"rapavas",
"rapelai",
"rapelas",
"rapelem",
"rapeles",
"rapemos",
"rapiais",
"rapiamo",
"rapiara",
"rapiava",
"rapiaça",
"rapicel",
"rapieis",
"rapiemo",
"rapigai",
"rapigam",
"rapigar",
"rapigas",
"rapigos",
"rapigou",
"rapigue",
"rapilha",
"rapilhe",
"rapinai",
"rapines",
"rapinhe",
"rapolas",
"raponso",
"raposam",
"raposei",
"raposem",
"raposes",
"raposeá",
"raposim",
"raposio",
"raposou",
"raptais",
"raptamo",
"raptemo",
"rapumes",
"rapácea",
"rapátea",
"rapânea",
"raquial",
"raquius",
"raradas",
"rarador",
"rarados",
"raradão",
"raramos",
"rarando",
"rarante",
"rararam",
"rararei",
"rararem",
"rarares",
"rararia",
"rararmo",
"rararás",
"rararão",
"rarasse",
"raraste",
"raravam",
"raravas",
"rareais",
"rareamo",
"rareeis",
"rareemo",
"rarefiz",
"rareias",
"rareies",
"rarejai",
"rarejam",
"rarejar",
"rarejas",
"rarejei",
"rarejem",
"rarejes",
"rarejou",
"raresce",
"raresci",
"raresça",
"raresço",
"rasador",
"rasadão",
"rasamos",
"rasarei",
"rasares",
"rasaria",
"rasarmo",
"rasarás",
"rasaste",
"rasavas",
"rasbuto",
"rascais",
"rascamo",
"rascole",
"rascóis",
"rasemos",
"rasense",
"rasinas",
"rasmono",
"rasoila",
"rasoilo",
"rasoire",
"rasolho",
"rasoure",
"rasouro",
"raspamo",
"raspeis",
"raspemo",
"raspita",
"rasqueá",
"rasteai",
"rasteei",
"rastiça",
"rastiço",
"rastral",
"rasulha",
"rasulho",
"rasurai",
"rasures",
"ratador",
"ratadão",
"ratalia",
"ratarei",
"ratarem",
"ratares",
"ratarmo",
"ratarás",
"ratarão",
"ratasse",
"rataste",
"ratatau",
"ratavas",
"rateais",
"rateamo",
"rateeis",
"rateemo",
"rateias",
"rateies",
"ratemos",
"rathita",
"raticum",
"ratimbó",
"ratinai",
"ratinas",
"ratinei",
"ratinem",
"ratines",
"ratinhe",
"ratinou",
"ratonai",
"ratonam",
"ratonei",
"ratonem",
"ratoneá",
"ratonou",
"ratoíce",
"raudiva",
"raudões",
"rauites",
"raumite",
"rauraca",
"rauraco",
"raurica",
"raurico",
"rausada",
"rausado",
"rausais",
"rausamo",
"rausará",
"rausava",
"rauseis",
"rausemo",
"rauvita",
"ravanas",
"ravenhe",
"ravenho",
"ravenhá",
"ravetas",
"ravinai",
"ravinam",
"ravinei",
"ravinem",
"ravinhe",
"ravinho",
"ravinou",
"ravênia",
"raxetas",
"raziado",
"raziais",
"raziamo",
"raziara",
"raziava",
"razieis",
"raziemo",
"razimos",
"razoamo",
"razoará",
"razoava",
"razoeis",
"razoemo",
"razonai",
"razonam",
"razonas",
"razonei",
"razonem",
"razonou",
"raçoada",
"raçoado",
"raçoais",
"raçoamo",
"raçoará",
"raçoava",
"raçoeis",
"raçoemo",
"raçonas",
"raíidas",
"reabite",
"reabito",
"reabras",
"reabres",
"reachai",
"reacham",
"reachas",
"reachem",
"reacuse",
"reacuso",
"readais",
"readote",
"readoto",
"reafete",
"reafeto",
"reafetá",
"reafixa",
"reafixe",
"reafixo",
"reagimo",
"reajamo",
"realeje",
"realeta",
"realeto",
"realojo",
"realugo",
"realçai",
"reamada",
"reamais",
"reamamo",
"reamará",
"reamava",
"reameis",
"reamemo",
"reandai",
"reandam",
"reandas",
"reandei",
"reandem",
"reandes",
"reanhas",
"reanita",
"reanite",
"reanode",
"reanodo",
"reanodá",
"rearmai",
"rearmas",
"reatais",
"reatamo",
"reateai",
"reateei",
"reateie",
"reateis",
"reatemo",
"reateou",
"reatice",
"reatiço",
"reautuo",
"reautuá",
"reaveio",
"reavemo",
"reaviai",
"reaviem",
"reavier",
"reavies",
"reavirá",
"reavéns",
"reaçona",
"rebabes",
"rebadas",
"rebador",
"rebados",
"rebadão",
"rebalsa",
"rebalse",
"rebalva",
"rebamos",
"rebanai",
"rebanam",
"rebanas",
"rebanei",
"rebanem",
"rebanes",
"rebaneá",
"rebanou",
"rebaram",
"rebaras",
"rebarbo",
"rebarei",
"rebares",
"rebaria",
"rebarmo",
"rebarão",
"rebasse",
"rebaste",
"rebatai",
"rebatas",
"rebavam",
"rebavas",
"rebaçãs",
"rebebam",
"rebebas",
"rebebei",
"rebeijo",
"rebelai",
"rebenza",
"rebenze",
"rebenzi",
"rebenzo",
"reberre",
"reberro",
"reberrá",
"rebicai",
"rebicam",
"rebicas",
"rebicou",
"rebimbe",
"rebisco",
"rebiscá",
"rebitai",
"rebitem",
"reboais",
"reboamo",
"rebocai",
"rebocas",
"reboeis",
"reboemo",
"rebojai",
"rebojas",
"rebojei",
"rebojem",
"rebojes",
"rebolal",
"reboleá",
"rebolis",
"rebombe",
"reborai",
"reboram",
"reboras",
"reborei",
"reborem",
"reborou",
"reboses",
"rebotai",
"rebotam",
"rebotem",
"rebouce",
"rebouço",
"rebrade",
"rebradá",
"rebrami",
"rebraça",
"rebrias",
"rebruna",
"rebrune",
"rebruni",
"rebruno",
"rebucei",
"rebucem",
"rebuces",
"rebufai",
"rebufam",
"rebufar",
"rebufas",
"rebufei",
"rebufem",
"rebufes",
"rebufou",
"rebulam",
"rebulas",
"rebulhe",
"rebulhá",
"rebulis",
"rebumbe",
"rebumbo",
"rebusne",
"rebutes",
"rebuçai",
"rebuçam",
"recabém",
"recacha",
"recache",
"recadai",
"recadam",
"recadei",
"recades",
"recadeá",
"recadém",
"recalai",
"recalas",
"recalei",
"recalem",
"recales",
"recalha",
"recalmo",
"recamai",
"recamas",
"recamei",
"recamem",
"recames",
"recapai",
"recapas",
"recapem",
"recaras",
"recarde",
"recarem",
"recares",
"recaria",
"recarmo",
"recarão",
"recasai",
"recasas",
"recasse",
"recaste",
"recatei",
"recates",
"recavai",
"recavam",
"recavas",
"recavei",
"recaves",
"recavou",
"recaímo",
"receamo",
"recedam",
"recedas",
"recedei",
"recedia",
"receemo",
"recenai",
"recenam",
"recendi",
"recenei",
"recenou",
"recerre",
"recerro",
"recerrá",
"recerta",
"rechana",
"rechape",
"rechapo",
"rechapá",
"rechiai",
"rechiam",
"rechias",
"rechiem",
"rechies",
"rechiou",
"rechupo",
"rechãos",
"reciana",
"reciano",
"recibai",
"recibam",
"recibei",
"recinge",
"recingi",
"recinja",
"recinjo",
"recluam",
"recluas",
"recluis",
"recocai",
"recocam",
"recocas",
"recocou",
"recocta",
"recodem",
"recoice",
"recoita",
"recoite",
"recolai",
"recolas",
"recolem",
"recoles",
"recolos",
"recolto",
"recomam",
"recomas",
"recomes",
"recomeu",
"recomia",
"recopio",
"recorce",
"recoroa",
"recoroe",
"recoroo",
"recorço",
"recosam",
"recosas",
"recosei",
"recosem",
"recoses",
"recouce",
"recouta",
"recoute",
"recovai",
"recovam",
"recovei",
"recovem",
"recoves",
"recovou",
"recozam",
"recozas",
"recozei",
"recozes",
"recravo",
"recriai",
"recruze",
"recruzo",
"recuamo",
"recucos",
"recudai",
"recudam",
"recudei",
"recudem",
"recudes",
"recudia",
"recudis",
"recudiu",
"recuemo",
"recuida",
"recuide",
"recuido",
"recuite",
"recuito",
"recuitá",
"reculão",
"recumba",
"recumbi",
"recunhe",
"recurai",
"recuras",
"recurem",
"recures",
"recábdo",
"redabes",
"redados",
"redadão",
"redamai",
"redamam",
"redamas",
"redamei",
"redamem",
"redames",
"redamou",
"redarei",
"redarem",
"redaria",
"redarás",
"redarão",
"redavam",
"redavas",
"redeais",
"redenga",
"redengo",
"redesce",
"redesci",
"redeste",
"redesça",
"redesço",
"redibam",
"redibas",
"redibem",
"redibes",
"redibia",
"redibiu",
"redigas",
"redigis",
"redirás",
"redirão",
"redispô",
"redizei",
"redizes",
"redobai",
"redobam",
"redobas",
"redobei",
"redobes",
"redobou",
"redoice",
"redoira",
"redoire",
"redoiro",
"redoiço",
"redolha",
"redolho",
"redonas",
"redopie",
"redorta",
"redouce",
"redoure",
"redouro",
"redouço",
"redovas",
"redrada",
"redrais",
"redramo",
"redrara",
"redrava",
"redreis",
"redremo",
"reduble",
"redublo",
"reduras",
"redúvia",
"reecoai",
"reecoas",
"reecoei",
"reecoem",
"reecoes",
"reecoou",
"reemito",
"reencho",
"reenfio",
"reenfiá",
"reenleá",
"reereta",
"reereto",
"reerige",
"reerigi",
"reerija",
"reerijo",
"reexiba",
"reexibo",
"reexite",
"reexito",
"reexitá",
"reexpie",
"reexpio",
"reexpiá",
"reexpus",
"refalai",
"refalas",
"refalem",
"refales",
"refalse",
"refalso",
"refalsá",
"refarte",
"refartá",
"refarás",
"refecei",
"refeceu",
"refeche",
"refechá",
"refecia",
"refegai",
"refegam",
"refegue",
"refegão",
"refenas",
"refende",
"refendi",
"refento",
"refentá",
"referro",
"referte",
"refervi",
"refervo",
"refesto",
"refezai",
"refezam",
"refezar",
"refezas",
"refezei",
"refezem",
"refezes",
"refezou",
"refeçam",
"refeças",
"refiada",
"refiais",
"refiamo",
"refiará",
"refiava",
"refieis",
"refiemo",
"refilai",
"refilha",
"refilhe",
"refilio",
"refinca",
"refinco",
"refinta",
"refinte",
"refinto",
"refitai",
"refitas",
"refitei",
"refites",
"refitou",
"refiões",
"reflais",
"reflamo",
"reflará",
"reflava",
"refleis",
"reflemo",
"refloro",
"refluis",
"refluxe",
"refocai",
"refocam",
"refocas",
"refogai",
"refoges",
"refolhe",
"reforjo",
"refrego",
"refreto",
"refrico",
"refricá",
"refrigi",
"refrija",
"refrijo",
"refrisa",
"refréns",
"refugai",
"refugis",
"refujas",
"refulgi",
"refumai",
"refumam",
"refumar",
"refumas",
"refumei",
"refumem",
"refumes",
"refumou",
"refunfe",
"refunfo",
"refunfá",
"refungo",
"refungá",
"refunhe",
"refunho",
"refunhá",
"refusai",
"refusas",
"refusei",
"refusem",
"refustá",
"regacem",
"regaces",
"regalez",
"reganas",
"regardo",
"regarmo",
"regaseá",
"regatai",
"regaçai",
"regaças",
"regelai",
"regelas",
"regelei",
"regerai",
"regiate",
"regibós",
"regilas",
"reginai",
"reginar",
"reginem",
"reginou",
"regirai",
"regirei",
"regoada",
"regoado",
"regoais",
"regoamo",
"regoara",
"regoava",
"regoeis",
"regoemo",
"regolas",
"regolfa",
"regolfe",
"regomai",
"regomam",
"regomar",
"regomas",
"regomei",
"regomem",
"regomou",
"regotas",
"regramo",
"regraxa",
"regraxe",
"regredo",
"regreis",
"regremo",
"regrãos",
"reguemo",
"reguiço",
"reguçar",
"regíeis",
"regínea",
"reicida",
"reicuas",
"reigrás",
"reimpus",
"reimões",
"reinamo",
"reinemo",
"reinume",
"reinumo",
"reinumá",
"reitres",
"reiunai",
"reiunam",
"reiunei",
"reiunem",
"reiunes",
"reixada",
"reixais",
"reixamo",
"reixará",
"reixava",
"reixeis",
"reixemo",
"rejamos",
"rejateá",
"rejecto",
"rejunco",
"rejuncá",
"rejurai",
"rejuram",
"rejuras",
"rejurei",
"rejurem",
"rejures",
"rejurou",
"relacro",
"reladão",
"relamba",
"relambi",
"relampe",
"relaras",
"relarei",
"relarmo",
"relarão",
"relaste",
"relavai",
"relavas",
"relavem",
"reledor",
"relegai",
"relejos",
"relenga",
"relepai",
"relepam",
"relepar",
"relepas",
"relepei",
"relepem",
"relepes",
"relepou",
"releras",
"relermo",
"relerão",
"relesas",
"relhais",
"relhamo",
"relhará",
"relhava",
"relheis",
"relhemo",
"relices",
"relidão",
"religai",
"relimam",
"relimas",
"relimei",
"relimem",
"relimes",
"relimou",
"relingo",
"relocai",
"relocas",
"relojai",
"relojam",
"relojar",
"relojas",
"relojei",
"relojem",
"relojou",
"relouca",
"relouco",
"relumai",
"relumam",
"relumar",
"relumas",
"relumei",
"relumem",
"relumes",
"relumeá",
"relumou",
"relutai",
"reluzas",
"reluzis",
"relvais",
"relvamo",
"relvará",
"relvava",
"relveis",
"relveje",
"relvejo",
"relvemo",
"relvões",
"relíeis",
"remalhe",
"remande",
"remango",
"remanir",
"remarmo",
"rematai",
"remavas",
"remeado",
"remeais",
"remeará",
"remeava",
"remedai",
"remedas",
"remedes",
"remeeis",
"remeiam",
"remeiem",
"remeies",
"remelai",
"remelei",
"remelem",
"remeles",
"remelgo",
"remeneá",
"rementa",
"rementi",
"remeçam",
"remeção",
"remidão",
"remigie",
"remigre",
"remimai",
"remimam",
"remimar",
"remimas",
"remimei",
"remimem",
"remimes",
"remimou",
"reminai",
"reminam",
"reminas",
"reminei",
"reminem",
"remines",
"reminou",
"remirai",
"remirmo",
"remirão",
"remitas",
"remites",
"remitis",
"remixai",
"remixas",
"remoais",
"remoamo",
"remoces",
"remoeis",
"remoele",
"remoelo",
"remoemo",
"remolco",
"remolcá",
"remolgo",
"remolgá",
"remolhe",
"remonda",
"remonde",
"remondo",
"remorai",
"remoram",
"remorcá",
"remorda",
"remorei",
"remorem",
"remorou",
"remorra",
"remorri",
"remorsa",
"remorta",
"remosta",
"remoste",
"remosto",
"remouco",
"remoucá",
"remoçai",
"remoías",
"remudai",
"remudam",
"remudem",
"remudes",
"remugai",
"remugam",
"remugar",
"remugas",
"remuges",
"remugis",
"remugiu",
"remugou",
"remugue",
"remujam",
"remujas",
"remunca",
"remunco",
"remusga",
"remusgo",
"remíeis",
"remígia",
"renacas",
"renacos",
"rendora",
"renetai",
"renetam",
"renetar",
"renetas",
"renetei",
"renetou",
"rengaço",
"renglão",
"rengras",
"rengues",
"rengueá",
"renguém",
"renhais",
"renhamo",
"renhane",
"renhias",
"renhimo",
"renhirá",
"reniera",
"renifla",
"renifle",
"reniflo",
"renitam",
"renitas",
"renitis",
"renodes",
"renréns",
"rentada",
"rentamo",
"rentará",
"renteai",
"renteei",
"renteie",
"renteio",
"renteis",
"rentemo",
"rentona",
"rentões",
"renuirá",
"renutre",
"renutro",
"renuíam",
"renuías",
"renuída",
"reocupo",
"reoliso",
"reolisá",
"reopere",
"reopero",
"reopine",
"reopino",
"reopiná",
"reouvam",
"reouvas",
"reouvei",
"reouvem",
"reouves",
"reouveu",
"reouvis",
"reouças",
"reoxide",
"reoxido",
"repagai",
"repairá",
"repanha",
"repanhe",
"repasce",
"repasci",
"repasça",
"repasço",
"repateá",
"repecei",
"repecem",
"repeces",
"repecha",
"repeche",
"repedai",
"repedam",
"repedar",
"repedas",
"repedei",
"repedem",
"repedes",
"repedis",
"repedou",
"repegai",
"repegam",
"repegas",
"repegue",
"repelai",
"repelas",
"repelei",
"repelou",
"reperca",
"reperco",
"reperdi",
"reperdê",
"repesai",
"repesam",
"repesei",
"repeçai",
"repeçam",
"repeçar",
"repeçou",
"repilgo",
"repilgá",
"repimpe",
"repimpo",
"repinal",
"repisai",
"repisca",
"replene",
"repodar",
"repoise",
"repoles",
"repolgo",
"repolhe",
"repolia",
"repolis",
"repoliu",
"repormo",
"reporás",
"repovoo",
"reprovi",
"repruam",
"repruas",
"repruem",
"repruis",
"repruiu",
"reprura",
"reprure",
"repruri",
"repruro",
"repruía",
"reptais",
"reptamo",
"reptará",
"reptemo",
"repulai",
"repulam",
"repulas",
"repulei",
"repulem",
"repules",
"repunge",
"repungi",
"repunja",
"repunjo",
"repurga",
"repurgo",
"repuxai",
"repuxas",
"requeis",
"requemo",
"requixó",
"rerietê",
"resbune",
"resbuno",
"resbuta",
"resbuto",
"rescume",
"rescumo",
"rescumá",
"resenos",
"resesso",
"resgais",
"resgará",
"resguei",
"resguem",
"resgues",
"resilam",
"resilas",
"resilem",
"resiles",
"resilia",
"resilis",
"resinai",
"resinam",
"resinem",
"resines",
"resinol",
"resitas",
"resites",
"resmada",
"resmamo",
"resmara",
"resmava",
"resmeis",
"resmemo",
"resmoam",
"resmoas",
"resmoei",
"resmoem",
"resmoes",
"resmoeu",
"resmune",
"resmuno",
"resmuná",
"resquie",
"resquiá",
"ressaga",
"ressais",
"ressega",
"ressego",
"ressela",
"ressele",
"resselo",
"ressica",
"ressico",
"ressiga",
"ressios",
"ressoai",
"ressobe",
"ressoes",
"ressome",
"ressuai",
"ressuam",
"ressuas",
"ressuba",
"ressubi",
"ressubo",
"ressude",
"ressudo",
"ressuei",
"ressuem",
"ressues",
"ressóis",
"restamo",
"resteis",
"restela",
"restele",
"restemo",
"restila",
"restile",
"restite",
"restiva",
"restuca",
"restuco",
"resudar",
"resumar",
"resélia",
"resínia",
"resínio",
"retacai",
"retacam",
"retacas",
"retacou",
"retames",
"retamos",
"retarei",
"retarem",
"retares",
"retarmo",
"retarás",
"retarão",
"retaste",
"retavam",
"retavas",
"retavos",
"reteada",
"reteado",
"reteais",
"reteará",
"reteeis",
"reteiam",
"reteias",
"reteiem",
"reteies",
"reteime",
"reteimo",
"retelha",
"retelhe",
"retempo",
"retenam",
"retenas",
"retenem",
"retenia",
"retenis",
"retenos",
"retesai",
"reteses",
"retesie",
"retesio",
"reteúdo",
"retines",
"retingi",
"retinis",
"retinja",
"retinjo",
"retisne",
"retisno",
"retisná",
"retoice",
"retonhe",
"retonho",
"retonhá",
"retosai",
"retosam",
"retosar",
"retosas",
"retosei",
"retosem",
"retoses",
"retosou",
"retoste",
"retosto",
"retovai",
"retovam",
"retovas",
"retovei",
"retovem",
"retoves",
"retrame",
"retramo",
"retrave",
"retravo",
"retrema",
"retremi",
"retremo",
"retrina",
"retrine",
"retrino",
"retroai",
"retroam",
"retroas",
"retroei",
"retroem",
"retubai",
"retubam",
"retubar",
"retubas",
"retubei",
"retubem",
"retubes",
"retubou",
"retunde",
"retundi",
"returca",
"retusas",
"retâmea",
"reumosa",
"reusais",
"reusamo",
"reuseis",
"reusemo",
"revejai",
"revenam",
"revenia",
"reveniu",
"revergo",
"revergá",
"revesai",
"revibre",
"revicei",
"revicem",
"revices",
"revidai",
"revilam",
"revilas",
"revinas",
"revinde",
"revinga",
"revingo",
"revinos",
"revirai",
"revirmo",
"revisca",
"revisco",
"reviçai",
"reviças",
"revoais",
"revoamo",
"revoará",
"revocas",
"revoeis",
"revoeja",
"revoeje",
"revoemo",
"revogai",
"revolco",
"revorai",
"revoram",
"revorar",
"revorei",
"revorem",
"revores",
"revorou",
"revulsa",
"revulse",
"revulso",
"revíeis",
"rexerta",
"rexoxós",
"rezadão",
"rezarmo",
"rezenta",
"rezente",
"rezento",
"rezingo",
"rezipai",
"rezipam",
"rezipar",
"rezipas",
"rezipei",
"rezipem",
"rezipes",
"rezipou",
"rezonas",
"rezoneá",
"rezumba",
"rezumbe",
"rezumbi",
"rezumbo",
"reédias",
"reídeas",
"reísmos",
"reístas",
"reóbias",
"reóbios",
"reófoba",
"reófobo",
"reônoma",
"reússia",
"riadara",
"rialeza",
"rialzão",
"riazeno",
"ribadas",
"ribador",
"ribados",
"ribadão",
"ribalde",
"ribamos",
"ribante",
"ribanço",
"ribaram",
"ribarei",
"ribarem",
"ribares",
"ribaria",
"ribarmo",
"ribarão",
"ribasse",
"ribaste",
"ribavam",
"ribavas",
"ribeire",
"ribemos",
"ribetas",
"ricalhã",
"ricanha",
"riceiro",
"ricemos",
"ricerco",
"riciela",
"ricinas",
"ricoiça",
"ricoiço",
"ricolas",
"ricongo",
"ricosso",
"ricouça",
"ricínea",
"ricíneo",
"ricótia",
"ridicai",
"ridicou",
"ridinho",
"ridique",
"riditas",
"ridonas",
"ridoras",
"rieiros",
"rifadão",
"rifanas",
"rifanos",
"rifante",
"rifaras",
"rifares",
"rifarmo",
"rifavas",
"rifeias",
"rifemos",
"rifetes",
"rififis",
"riflada",
"riflado",
"riflais",
"riflamo",
"riflara",
"riflava",
"rifleis",
"riflemo",
"rifável",
"rigadas",
"rigador",
"rigadão",
"rigando",
"rigarei",
"rigarem",
"rigares",
"rigaria",
"rigarás",
"rigarão",
"rigasse",
"rigaste",
"rigavam",
"rigavas",
"rigaços",
"rigeira",
"rigolas",
"rigolôs",
"rigueis",
"rigínia",
"rigódio",
"rijadas",
"rijador",
"rijados",
"rijadão",
"rijamos",
"rijando",
"rijante",
"rijaram",
"rijarei",
"rijarem",
"rijares",
"rijaria",
"rijarmo",
"rijarás",
"rijarão",
"rijasse",
"rijaste",
"rijavam",
"rijavas",
"rijemos",
"rijoada",
"rijosas",
"rijumes",
"riladas",
"rilador",
"rilados",
"riladão",
"rilamos",
"rilando",
"rilante",
"rilaram",
"rilarei",
"rilarem",
"rilares",
"rilaria",
"rilarmo",
"rilarás",
"rilarão",
"rilasse",
"rilaste",
"rilavam",
"rilavas",
"rilemos",
"rilhais",
"rilhamo",
"rilheis",
"rilhemo",
"rilhoso",
"rilzões",
"rimadão",
"rimaras",
"rimarmo",
"rimista",
"rimável",
"rimósia",
"rinasto",
"rinauas",
"rinceis",
"rincemo",
"rinchai",
"rinchei",
"rinchem",
"rinches",
"rincodo",
"rincona",
"rincone",
"rincono",
"rincope",
"rincota",
"rincote",
"rincóps",
"rinfada",
"rinfado",
"rinfais",
"rinfamo",
"rinfará",
"rinfava",
"rinfeis",
"rinfemo",
"rinfões",
"ringeis",
"ringemo",
"ringerá",
"ringias",
"ringida",
"ringirá",
"rinhada",
"rinhais",
"rinhamo",
"rinhará",
"rinhava",
"rinheis",
"rinhemo",
"rinhões",
"riniais",
"riniale",
"rinismo",
"rinista",
"rinjais",
"rinjamo",
"rinonco",
"rinopes",
"rinorta",
"rinoses",
"rinusas",
"rinçada",
"rinçado",
"rinçais",
"rinçamo",
"rinçará",
"rinçava",
"rinêmio",
"rinídea",
"rinídeo",
"rinóbio",
"riodina",
"riolite",
"rionita",
"rioscas",
"riostes",
"ripance",
"ripante",
"ripança",
"riparei",
"riparmo",
"riparás",
"riparão",
"ripasse",
"ripaste",
"ripavas",
"ripeias",
"ripemos",
"ripiada",
"ripinas",
"ripiste",
"ripolém",
"riponas",
"ripídia",
"ripóbio",
"riquesu",
"risanza",
"risdale",
"riseíta",
"riseíte",
"risinha",
"risodos",
"risonas",
"risopes",
"risotes",
"rissemo",
"rissoia",
"ritacne",
"ritebas",
"ritende",
"ritidos",
"ritinas",
"ritinho",
"ritiodo",
"ritista",
"ritiões",
"ritmais",
"ritmamo",
"ritmeis",
"ritmemo",
"ritmize",
"ritmizo",
"ritoaia",
"ritombe",
"ritonde",
"ritucos",
"ritutis",
"ritídio",
"rivanol",
"rivaíta",
"rivense",
"rivinas",
"rivélia",
"rivínea",
"rivínia",
"rixadas",
"rixadão",
"rixamos",
"rixante",
"rixaram",
"rixarei",
"rixarem",
"rixares",
"rixaria",
"rixarmo",
"rixarás",
"rixarão",
"rixasse",
"rixaste",
"rixavas",
"rixemos",
"rizadão",
"rizanta",
"rizarei",
"rizarem",
"rizares",
"rizaria",
"rizarmo",
"rizarás",
"rizarão",
"rizasse",
"rizaste",
"rizavas",
"rizecos",
"rizemos",
"rizodos",
"rizoeco",
"rizomis",
"rizopos",
"rizotas",
"rizídio",
"rizômio",
"riácias",
"riçador",
"riçadão",
"riçamos",
"riçante",
"riçaram",
"riçarei",
"riçarem",
"riçares",
"riçaria",
"riçarmo",
"riçarás",
"riçarão",
"riçasse",
"riçaste",
"riçavam",
"riçavas",
"roalzão",
"robafos",
"robazes",
"robeche",
"robecho",
"robechá",
"robinas",
"roborai",
"roborei",
"roboões",
"robórea",
"rocalhe",
"rocalho",
"rocanso",
"rocegai",
"rocegam",
"rocegou",
"rocegue",
"rocelas",
"rocenas",
"rochona",
"rociamo",
"rociara",
"rocieis",
"rociemo",
"rocinai",
"rocinam",
"rocinas",
"rocinei",
"rocinem",
"rocines",
"rocinou",
"rociosa",
"roclada",
"roclado",
"roclais",
"roclamo",
"roclará",
"roclava",
"rocleis",
"roclemo",
"rodanta",
"rodarmo",
"rodatos",
"rodeemo",
"rodeite",
"rodejai",
"rodejam",
"rodejar",
"rodejas",
"rodejei",
"rodejem",
"rodejes",
"rodejou",
"rodelha",
"rodeose",
"rodetas",
"rodeóis",
"rodilhe",
"rodinal",
"rodines",
"rodinol",
"rodiota",
"rodites",
"roditos",
"rodizie",
"rodoide",
"rodolho",
"rodomão",
"rodopeu",
"rodoras",
"roduras",
"rodácea",
"rodáceo",
"rodália",
"rodíaca",
"rodíola",
"rodíolo",
"rodólia",
"rodópis",
"rodônea",
"rodôneo",
"roelzão",
"roenita",
"roerdes",
"roeremo",
"roesses",
"roestes",
"rofegos",
"rogarmo",
"roguemo",
"rohaíta",
"rohânia",
"roidona",
"roidsia",
"roidões",
"roienas",
"rojadão",
"rojares",
"rojarmo",
"rojarás",
"rojarão",
"rojaste",
"rojavas",
"rojemos",
"rojoneá",
"rojásia",
"roladão",
"rolarmo",
"rolazes",
"rolaças",
"roldado",
"roldais",
"roldamo",
"roldará",
"roldava",
"roldeai",
"roldeei",
"roldeia",
"roldeie",
"roldeio",
"roldeis",
"roldemo",
"roldeou",
"roletai",
"roletei",
"roletem",
"roleteá",
"rolhais",
"rolhamo",
"rolhará",
"rolhava",
"rolheis",
"rolhemo",
"rolheta",
"rolhete",
"rolicei",
"rolicem",
"roliçai",
"roliçam",
"roliçou",
"rolínia",
"romador",
"romados",
"romadão",
"romalho",
"romanal",
"romaneá",
"romanim",
"romaram",
"romaras",
"romarei",
"romares",
"romarmo",
"romarão",
"romaste",
"romavas",
"romecas",
"romecos",
"romeica",
"romeico",
"romelas",
"romemos",
"rometal",
"romeíte",
"romeíto",
"romites",
"romneia",
"romonia",
"rompamo",
"romular",
"romélia",
"romélio",
"romúlea",
"roncamo",
"ronceai",
"ronceei",
"ronceia",
"ronceie",
"ronceio",
"ronceou",
"ronchai",
"roncham",
"ronchei",
"ronchem",
"ronches",
"ronchou",
"rondais",
"rondamo",
"rondeai",
"rondeei",
"rondeio",
"rondemo",
"rondola",
"rondons",
"ronflai",
"ronflam",
"ronflar",
"ronflas",
"ronflei",
"ronflem",
"ronfles",
"ronflou",
"ronhura",
"ronqueá",
"ronábea",
"roqueia",
"roqueis",
"roqueló",
"roquemo",
"roqueás",
"rorador",
"rorados",
"roradão",
"roramos",
"roraram",
"rorarei",
"rorarem",
"rorares",
"roraria",
"rorarmo",
"rorarás",
"rorarão",
"rorasse",
"roraste",
"roravam",
"roravas",
"rorejas",
"rorejei",
"rorejem",
"rorejes",
"rorejou",
"rorelas",
"roremos",
"roripas",
"rosador",
"rosante",
"rosarei",
"rosares",
"rosarmo",
"rosarás",
"rosaste",
"rosavas",
"rosaças",
"roscais",
"roscamo",
"roscava",
"roseais",
"roseeis",
"roseiam",
"roseias",
"roseiem",
"roseies",
"rosemas",
"rosemos",
"rosetai",
"rosetam",
"rosetei",
"rosetem",
"roseteá",
"rosetou",
"roseína",
"rosinas",
"rosinol",
"rosites",
"rositos",
"rosmana",
"rosmaro",
"rosmeai",
"rosmear",
"rosmeei",
"rosmeia",
"rosmeie",
"rosmeio",
"rosmeou",
"rosnais",
"rosnamo",
"rosneis",
"rosnemo",
"rosnore",
"rosnoro",
"rosnorá",
"rosquem",
"rosques",
"rossela",
"rossita",
"rostamo",
"rosteai",
"rosteei",
"rosteia",
"rosteie",
"rosteio",
"rosteou",
"rostiam",
"rostias",
"rostido",
"rostimo",
"rostirá",
"rosulho",
"rosuras",
"rosênia",
"rosínea",
"rotadão",
"rotarei",
"rotarmo",
"rotarás",
"rotarão",
"rotasse",
"rotaste",
"rotavas",
"roteais",
"roteamo",
"roteeis",
"roteemo",
"roteias",
"roteies",
"rotejai",
"rotejam",
"rotejas",
"rotejei",
"rotejem",
"rotejes",
"rotejou",
"rotelas",
"rotemos",
"rotlera",
"rotoria",
"rotália",
"rouceis",
"roucemo",
"roucura",
"roupamo",
"roupeis",
"roupemo",
"roupuda",
"roupudo",
"roussai",
"roussam",
"roussar",
"roussas",
"roussei",
"roussem",
"rouvais",
"rouçada",
"rouçado",
"rouçais",
"rouçamo",
"rouçará",
"rouçava",
"rouções",
"rovotes",
"roxeais",
"roxeará",
"roxecré",
"roxeeis",
"roxeias",
"roxeiem",
"roxeies",
"roxetas",
"roxetes",
"roânias",
"roçagai",
"roçagas",
"roçague",
"roçanas",
"roçarás",
"ruadona",
"ruadora",
"ruadões",
"rualzão",
"ruamons",
"ruantes",
"ruardes",
"ruaremo",
"ruariam",
"ruarias",
"ruarmos",
"ruasses",
"ruastes",
"rubagos",
"rubalas",
"rubanas",
"rubanos",
"rubasse",
"rubejai",
"rubejam",
"rubejas",
"rubejei",
"rubejem",
"rubejes",
"rubejou",
"rubelos",
"rubenos",
"rubesci",
"rubesça",
"rubesço",
"rubetas",
"rubiais",
"rubiale",
"rubieva",
"rubigal",
"rubisca",
"rubixás",
"rubiões",
"rubrita",
"rubrite",
"rubriza",
"rubrize",
"rubrizo",
"rubínia",
"rucegas",
"rucemos",
"rucetes",
"rucilha",
"rucuris",
"rudezes",
"rudinha",
"ruditas",
"rudites",
"rudraxa",
"ruelona",
"ruelíea",
"rufadão",
"rufamos",
"rufaras",
"rufarei",
"rufaria",
"rufarmo",
"rufaste",
"rufavas",
"rufiada",
"rufiado",
"rufiais",
"rufiamo",
"rufiara",
"rufiava",
"rufieis",
"rufiemo",
"ruflada",
"ruflais",
"ruflamo",
"ruflará",
"rufleis",
"ruflemo",
"rugador",
"rugados",
"rugadão",
"rugamos",
"rugante",
"rugaram",
"rugaras",
"rugarei",
"rugarem",
"rugares",
"rugaria",
"rugarmo",
"rugarão",
"rugasse",
"rugaste",
"rugavam",
"rugavas",
"rugibós",
"rugidão",
"ruginai",
"ruginam",
"ruginar",
"ruginei",
"ruginem",
"rugines",
"ruginou",
"rugiras",
"rugires",
"rugirmo",
"rugiste",
"rugitai",
"rugitam",
"rugitas",
"rugitei",
"rugitem",
"rugites",
"rugueis",
"ruguemo",
"rugusca",
"rugusco",
"rugíeis",
"ruidada",
"ruidado",
"ruidais",
"ruidamo",
"ruidará",
"ruideis",
"ruidemo",
"ruidora",
"ruidões",
"ruirdes",
"ruiremo",
"ruivais",
"ruivana",
"ruivano",
"ruivote",
"ruivões",
"ruizita",
"ruladas",
"rulados",
"ruladão",
"rulamos",
"rularei",
"rularem",
"rulares",
"rularia",
"rularmo",
"rularás",
"rularão",
"rulaste",
"rulavas",
"rulemos",
"rulhada",
"rulhado",
"rulhais",
"rulhamo",
"rulhará",
"rulhava",
"rulheis",
"rulhemo",
"rumador",
"rumadão",
"rumaras",
"rumarmo",
"rumbada",
"rumbais",
"rumbamo",
"rumbara",
"rumbava",
"rumbeai",
"rumbeie",
"rumbeio",
"rumbeis",
"rumbemo",
"rumbodo",
"rumeras",
"rumeros",
"rumiado",
"rumiais",
"rumiamo",
"rumiará",
"rumiava",
"rumieis",
"rumiemo",
"rumines",
"ruminho",
"rumorai",
"rumoram",
"rumoras",
"rumorei",
"rumorio",
"rumorou",
"rumpris",
"rumícea",
"runcina",
"rundemo",
"runfada",
"runfado",
"runfais",
"runfamo",
"runfará",
"runfava",
"runfeis",
"runfemo",
"runfões",
"runhada",
"runhado",
"runhais",
"runhamo",
"runhará",
"runhava",
"runheis",
"runhemo",
"runjefe",
"runruns",
"runíbia",
"ruolzes",
"rupadas",
"rupador",
"rupados",
"rupadão",
"rupamos",
"rupando",
"rupante",
"ruparam",
"ruparas",
"ruparei",
"ruparem",
"rupares",
"ruparia",
"ruparmo",
"ruparão",
"rupasse",
"rupaste",
"rupavam",
"rupavas",
"rupelas",
"rupemos",
"rupiada",
"rupipas",
"rupália",
"rupélia",
"rupínia",
"rusgada",
"rusgado",
"rusgais",
"rusgara",
"rusgata",
"rusguei",
"rusguem",
"rusgues",
"rusinas",
"ruspone",
"russais",
"russamo",
"russará",
"russava",
"russeis",
"russemo",
"russize",
"russizo",
"russizá",
"rustais",
"rustamo",
"rustiam",
"rustias",
"rustida",
"rustilo",
"rustimo",
"rustirá",
"rusélia",
"rutadas",
"rutador",
"rutados",
"rutadão",
"rutales",
"rutamos",
"rutando",
"rutante",
"rutarei",
"rutarem",
"rutares",
"rutaria",
"rutarmo",
"rutarás",
"rutarão",
"rutasse",
"rutaste",
"rutavam",
"rutavas",
"rutelas",
"rutemos",
"rutilai",
"rutilei",
"rutilem",
"rutiles",
"rutonal",
"rutubas",
"rutárea",
"rutáreo",
"rutídea",
"rutília",
"ruvetos",
"ruxaxás",
"ruxoxós",
"ruzagás",
"ruáreis",
"ruáveis",
"ruãozão",
"ruçador",
"ruçados",
"ruçadão",
"ruçamos",
"ruçando",
"ruçante",
"ruçaram",
"ruçarei",
"ruçarem",
"ruçares",
"ruçaria",
"ruçarmo",
"ruçarás",
"ruçarão",
"ruçasse",
"ruçaste",
"ruçavam",
"ruçavas",
"ruélias",
"ruéllia",
"ruíamos",
"ruísses",
"ruístes",
"rábdias",
"rádiuns",
"ráfanas",
"ráfanos",
"ráfidas",
"ráfnias",
"rájidas",
"rálfsia",
"ráppias",
"ráquios",
"rátulos",
"râmneas",
"râmneos",
"râmulas",
"râncida",
"rândias",
"rânfias",
"règuatê",
"réculas",
"rédones",
"rédonos",
"réfluos",
"régulon",
"réquias",
"réquies",
"rétzias",
"révoras",
"rênicas",
"rênicos",
"rícinos",
"rícquia",
"rímulas",
"ríncolo",
"rínemis",
"rínions",
"rínodon",
"rínofes",
"rínofis",
"rípides",
"rípsale",
"ríptica",
"ríptico",
"ríquias",
"ríramos",
"rísseis",
"rítidas",
"rívulas",
"rívulos",
"rízulas",
"róbbias",
"rócheos",
"ródicas",
"ródicos",
"ródnios",
"rópalas",
"rópalos",
"róptria",
"róricas",
"róricos",
"róridas",
"rósceas",
"róscida",
"róssias",
"rôureas",
"rúcolas",
"rúdgeas",
"rúivica",
"rúivico",
"rúmbias",
"rúminos",
"rúppias",
"rúridas",
"rússios",
"rússula",
"rútulas",
"saamona",
"sabadeá",
"sabaios",
"sabajos",
"sabelha",
"sabetos",
"sabiabe",
"sabiche",
"sabicho",
"sabieno",
"sabraca",
"sabreca",
"sabreco",
"sabucai",
"sabucam",
"sabucas",
"sabucou",
"sabucus",
"sabugai",
"sabugam",
"sabugas",
"sabugou",
"sabugue",
"sabuiás",
"sabujai",
"sabujei",
"sabujem",
"sabujes",
"sabulai",
"sabulam",
"sabulas",
"sabulei",
"sabulem",
"sabules",
"sabulou",
"sabuque",
"saburre",
"sabácon",
"sabálea",
"sabátia",
"sabícea",
"sabínia",
"sacaios",
"sacalho",
"sacateá",
"sacaíta",
"sacenas",
"sachais",
"sachamo",
"sacheis",
"sachemo",
"sachole",
"sachéns",
"sachões",
"sacocas",
"sacolai",
"sacolam",
"sacolem",
"sacoteá",
"sacotos",
"sacovás",
"sacrana",
"sacrano",
"sacudis",
"sacudus",
"sacuias",
"saculai",
"saculam",
"saculas",
"saculei",
"saculem",
"sacules",
"saculou",
"sacureu",
"sacurês",
"sacódio",
"sadacas",
"saelina",
"saelino",
"saelzão",
"safanai",
"safanam",
"safanar",
"safanas",
"safanei",
"safanem",
"safanes",
"safanos",
"safanou",
"safante",
"safiros",
"saforis",
"safreja",
"safreje",
"safrejo",
"safreno",
"safróis",
"safrões",
"safutas",
"sageria",
"sagezes",
"saginai",
"saginam",
"saginas",
"saginei",
"saginem",
"sagines",
"saginou",
"sagitai",
"sagitam",
"sagitei",
"sagitem",
"sagites",
"sagitou",
"sagonas",
"sagonha",
"sagonho",
"sagorre",
"sagorro",
"sagorrá",
"sagotai",
"sagotam",
"sagotar",
"sagotas",
"sagotei",
"sagotem",
"sagotes",
"sagotou",
"sagreis",
"sagremo",
"sagrina",
"saguais",
"sagucho",
"sagócia",
"saianas",
"saibrai",
"saibram",
"saibras",
"saibrei",
"saibrem",
"saibres",
"saibrou",
"saidona",
"saidora",
"saierês",
"sailzão",
"sainita",
"saioada",
"saioado",
"saionia",
"saioria",
"sairemo",
"sajenes",
"sajense",
"sajicas",
"salafré",
"salagué",
"salaléu",
"salameá",
"salamos",
"salanca",
"salango",
"salassa",
"salaxes",
"saldais",
"saldamo",
"saldemo",
"salenos",
"salepos",
"salerne",
"salfeno",
"salgais",
"salgamo",
"salhada",
"salhado",
"salhais",
"salhamo",
"salhará",
"salhava",
"salheis",
"salhemo",
"salical",
"saligas",
"salinai",
"salinam",
"salinei",
"salinem",
"salinou",
"salites",
"salivai",
"salives",
"salmana",
"salmano",
"salmaça",
"salmaço",
"salmeai",
"salmeei",
"salmeie",
"salmeio",
"salmeje",
"salmejo",
"salmejá",
"salmeou",
"salmino",
"salmite",
"salmize",
"salmizo",
"salmizá",
"salpina",
"salpino",
"salpins",
"salsais",
"salsamo",
"salsará",
"salsava",
"salsedo",
"salseis",
"salsemo",
"salsona",
"saltamo",
"salteai",
"saltico",
"salubás",
"salucei",
"salucem",
"saluces",
"saludai",
"saludei",
"saludor",
"salugas",
"saluçai",
"saluçam",
"saluças",
"saluçou",
"salviol",
"salvíea",
"salzões",
"salênia",
"samanga",
"samarau",
"samaúva",
"sambais",
"sambamo",
"sambeai",
"sambeei",
"sambeia",
"sambeie",
"sambeio",
"sambemo",
"sambeou",
"sambeta",
"samblai",
"samblam",
"samblas",
"samblei",
"samblem",
"samblou",
"samboco",
"sambojô",
"sambrai",
"sambram",
"sambrar",
"sambrei",
"sambrem",
"sambres",
"sambrou",
"sambuda",
"samburo",
"sameias",
"samelos",
"sameode",
"saminos",
"samites",
"samitos",
"samiéis",
"samnite",
"samoris",
"samoíta",
"sampada",
"sampado",
"sampais",
"sampamo",
"sampará",
"sampeis",
"sampemo",
"samádis",
"samânea",
"samídeo",
"samínea",
"samíneo",
"samóbia",
"samólea",
"samóleo",
"sanadão",
"sanarmo",
"sanaste",
"sanates",
"sanavas",
"sanceno",
"sanchim",
"sanchus",
"sanculo",
"sandado",
"sandais",
"sandamo",
"sandaná",
"sandava",
"sandave",
"sandeis",
"sandeja",
"sandeje",
"sandejo",
"sandemo",
"sandiva",
"saneais",
"saneamo",
"saneeis",
"saneemo",
"sanefai",
"sanefam",
"sanefar",
"sanefei",
"sanefem",
"sanefes",
"sanefou",
"saneias",
"saneies",
"sanense",
"sangage",
"sangesa",
"sanguim",
"sangões",
"sanhosa",
"sanibás",
"sanicai",
"sanicam",
"sanicar",
"sanicas",
"sanicou",
"sanioje",
"sanique",
"sanjada",
"sanjado",
"sanjais",
"sanjamo",
"sanjara",
"sanjava",
"sanjeis",
"sanjemo",
"sanjica",
"sanjoão",
"sanocas",
"sanocos",
"sanonas",
"santais",
"santica",
"santiga",
"santois",
"santora",
"sanvori",
"sançona",
"sapadão",
"sapajos",
"sapamos",
"sapante",
"sapares",
"saparmo",
"saparão",
"sapasse",
"sapaste",
"sapatai",
"sapatem",
"sapates",
"sapavam",
"sapavas",
"sapeais",
"sapeará",
"sapecai",
"sapeeis",
"sapeias",
"sapeiem",
"sapeies",
"sapejai",
"sapejam",
"sapejas",
"sapejei",
"sapejem",
"sapejes",
"sapejou",
"sapelos",
"sapemos",
"saperês",
"sapicas",
"sapicos",
"sapirão",
"sapocus",
"sapoila",
"sapoile",
"sapoilo",
"sapolga",
"sapoléu",
"sapopés",
"saporie",
"saporio",
"saporiá",
"saporés",
"sapoula",
"sapoule",
"sapoulo",
"sapreme",
"saprina",
"saprino",
"sapuche",
"sapuias",
"sapuleu",
"sapupos",
"saputás",
"sapuvas",
"sapário",
"sapótea",
"sapóteo",
"saqueis",
"saquelo",
"saquemo",
"saquins",
"saragos",
"saraive",
"saraiús",
"sarames",
"sarante",
"sarapuá",
"sararmo",
"sarauês",
"saraços",
"sarcita",
"sarcite",
"sarcosa",
"sardada",
"sardado",
"sardais",
"sardamo",
"sardará",
"sardava",
"sardeis",
"sardemo",
"saresma",
"sarguça",
"sariaçu",
"saribas",
"sarigas",
"sarilhe",
"saritãs",
"sarjais",
"sarjamo",
"sarjemo",
"sarjota",
"sarjões",
"sarmões",
"sarnada",
"sarnado",
"sarnais",
"sarnamo",
"sarnará",
"sarnava",
"sarnemo",
"sarneta",
"sarnius",
"sarnões",
"saroido",
"sarovas",
"sarpada",
"sarpamo",
"sarpara",
"sarpava",
"sarpeis",
"sarpemo",
"sarraco",
"sarrafe",
"sarraia",
"sarramo",
"sarrará",
"sarraus",
"sarreis",
"sarremo",
"sarrica",
"sarrifo",
"sarrona",
"sarrons",
"sarrosa",
"sarruda",
"sarrufo",
"sarsará",
"sarsina",
"sartada",
"sartais",
"sartamo",
"sartará",
"sarteis",
"sartemo",
"saruada",
"saruado",
"sarugas",
"sarumas",
"sassabi",
"sassada",
"sassado",
"sassais",
"sassamo",
"sassava",
"sasseis",
"sassemo",
"sasseus",
"sasuras",
"sasuros",
"satanie",
"satanio",
"satarca",
"satisda",
"satisde",
"satures",
"satádia",
"satária",
"sauaçus",
"saubais",
"saudeem",
"saudera",
"saurina",
"saurino",
"saurita",
"saurite",
"saururo",
"sautora",
"sauvais",
"savasto",
"savatas",
"savates",
"savetes",
"savicas",
"savicão",
"savites",
"savitos",
"savogas",
"savoras",
"saxinas",
"saxosas",
"sazeira",
"sazeiro",
"sazoada",
"sazoais",
"sazoamo",
"sazoará",
"sazoava",
"sazoeis",
"sazoemo",
"sazonei",
"sazonem",
"sazones",
"saítica",
"saúrion",
"scheois",
"scleros",
"scopias",
"searedo",
"sebatos",
"sebaças",
"sebinas",
"sebinos",
"sebismo",
"sebiubu",
"sebória",
"sebório",
"secadio",
"secanas",
"secança",
"secarmo",
"secarrã",
"secasto",
"seciada",
"seciado",
"seciamo",
"seciará",
"seciava",
"secieis",
"seciemo",
"seciono",
"secluso",
"secusse",
"secçona",
"sedadão",
"sedarei",
"sedares",
"sedarmo",
"sedarás",
"sedaste",
"sedavas",
"sedeais",
"sedeava",
"sedecas",
"sedeeis",
"sedeias",
"sedeiem",
"sedeies",
"sedeira",
"sedenha",
"sedense",
"sedeúda",
"sediais",
"sediamo",
"sedieis",
"sediemo",
"sedoras",
"sedunas",
"sefelas",
"sefelos",
"sefelpa",
"sefinas",
"seflosa",
"seféria",
"segadão",
"segasse",
"segaste",
"segavas",
"seguais",
"segécia",
"seiadas",
"seibões",
"seidiço",
"seifias",
"seiinho",
"seimiri",
"seirita",
"seitada",
"seitado",
"seitais",
"seitamo",
"seitará",
"seiteis",
"seitemo",
"seitosa",
"seiudos",
"seivado",
"seivais",
"seivamo",
"seivara",
"seivava",
"seiveis",
"seivemo",
"seixais",
"seiçais",
"seladão",
"selarmo",
"selaíta",
"selaíte",
"selaíto",
"selbite",
"seletai",
"seletei",
"seletem",
"seletou",
"seliera",
"selinas",
"selisca",
"selista",
"selites",
"selvela",
"selácia",
"selácio",
"selásia",
"selâmia",
"selênis",
"selínea",
"selíneo",
"selísia",
"sembada",
"sembado",
"sembais",
"sembamo",
"sembará",
"sembava",
"sembela",
"sembemo",
"sembrai",
"sembram",
"sembrei",
"sembrem",
"sembres",
"sembrou",
"semeemo",
"semianã",
"semiaça",
"semiaço",
"semimis",
"seminos",
"semiosa",
"semioto",
"semitoe",
"semitoo",
"semitoá",
"semivoa",
"semivoe",
"semivoo",
"semotas",
"semásia",
"senabós",
"senagra",
"senatas",
"senaíte",
"senaíto",
"sendais",
"senevol",
"sengada",
"sengado",
"sengais",
"sengara",
"sengava",
"senguei",
"sengues",
"senouro",
"senoute",
"senrada",
"sepeese",
"seperus",
"sepiela",
"sepitas",
"sepites",
"seposas",
"seposos",
"septamo",
"septará",
"septava",
"septemo",
"sepídio",
"sequace",
"sequemo",
"sequiai",
"sequias",
"sequiei",
"sequiem",
"sequies",
"sequiou",
"seracas",
"seracos",
"seraiva",
"serampo",
"serande",
"seranza",
"seranze",
"seranzo",
"serbros",
"serdões",
"serenga",
"sereras",
"sereros",
"seriava",
"seribas",
"seriemo",
"serinos",
"serioba",
"sermoai",
"sermoam",
"sermoar",
"sermoei",
"sermoem",
"sermono",
"sermoou",
"sernais",
"seroado",
"seroais",
"seroamo",
"seroará",
"seroava",
"seroeis",
"seroemo",
"serpeai",
"serpeei",
"serpeie",
"serpeje",
"serpejo",
"serpeou",
"serpóis",
"serpões",
"serrafo",
"serramo",
"serreai",
"serredo",
"serreei",
"serreie",
"serreio",
"serremo",
"serrete",
"serrial",
"serrina",
"serrine",
"serrins",
"serroto",
"sertuns",
"serténs",
"serudas",
"servans",
"serzias",
"serzido",
"serzimo",
"serzino",
"serzira",
"serábia",
"serídea",
"serídia",
"serífio",
"seríola",
"sesarna",
"sesmais",
"sesmamo",
"sesmará",
"sesmava",
"sesmeis",
"sesmemo",
"sessais",
"sessemo",
"sessene",
"sessona",
"sesteai",
"sesteie",
"sestrai",
"sestram",
"sestrei",
"sestres",
"sestrou",
"sesudas",
"sesâmea",
"sesâmeo",
"sesâmia",
"sesélio",
"sesúvio",
"setador",
"setadão",
"setarda",
"setarei",
"setares",
"setarmo",
"setarás",
"setaste",
"setavas",
"seteada",
"seteará",
"seteava",
"seteeis",
"seteiam",
"seteiem",
"seteies",
"seteire",
"setenos",
"setfari",
"setinai",
"setinam",
"setinar",
"setinas",
"setinei",
"setinem",
"setines",
"setinos",
"setinou",
"setosos",
"setélia",
"seutera",
"sevados",
"sevadão",
"sevante",
"sevaram",
"sevarei",
"sevares",
"sevaria",
"sevarmo",
"sevarão",
"sevasse",
"sevaste",
"sevasto",
"sevavas",
"seviral",
"sexadão",
"sexante",
"sexaram",
"sexarei",
"sexarem",
"sexares",
"sexaria",
"sexarmo",
"sexarás",
"sexarão",
"sexasse",
"sexatro",
"sexavam",
"sexavas",
"sexavôs",
"sexemos",
"sextave",
"sexuamo",
"sexuana",
"sexuará",
"sexuava",
"sexueis",
"sexuemo",
"sezenas",
"sezenos",
"sezoada",
"sezoado",
"sezoais",
"sezoamo",
"sezoara",
"sezoava",
"sezoeis",
"sezoemo",
"sezutos",
"seçonas",
"siadona",
"siadora",
"siadões",
"sialite",
"sialzão",
"siampão",
"siantes",
"siardes",
"siareis",
"siaremo",
"siariam",
"siarias",
"siarmos",
"siassem",
"siasses",
"sibanas",
"sibares",
"sibilai",
"sibildo",
"sibiles",
"sibiras",
"sibitai",
"sibitam",
"sibitas",
"sibitei",
"sibitem",
"sibitou",
"sibivas",
"sibivos",
"sicates",
"sicense",
"sicinas",
"sicitas",
"sicites",
"sicoias",
"sicomas",
"sicoses",
"sicular",
"sicínis",
"sidagãs",
"sidamos",
"sidense",
"sideras",
"siderei",
"siderem",
"sideres",
"sidetas",
"sidinas",
"sidoiro",
"sidonas",
"sidouro",
"sidêmia",
"sidídea",
"sidídeo",
"sidínea",
"sidíneo",
"sienita",
"siflada",
"siflado",
"siflais",
"siflamo",
"siflará",
"siflava",
"sifleis",
"siflemo",
"sifoide",
"sifonai",
"sifonam",
"sifonas",
"sifonem",
"sifonou",
"sigilai",
"sigilam",
"sigilei",
"sigilem",
"sigiles",
"siglais",
"siglamo",
"siglava",
"sigleis",
"siglemo",
"siglize",
"siglizo",
"signamo",
"signeis",
"signina",
"silabai",
"silanas",
"silenas",
"silepta",
"silerca",
"silhões",
"sililos",
"silinas",
"silinga",
"silingo",
"silundo",
"siluras",
"silvamo",
"silveis",
"silvemo",
"silvões",
"siléria",
"silésis",
"silênea",
"silêneo",
"silênia",
"silíaca",
"silíaco",
"silíceo",
"silídea",
"silídeo",
"simabas",
"simbaoé",
"simelia",
"simetes",
"simiras",
"simiris",
"simocas",
"simocos",
"simpexe",
"simulai",
"simélio",
"simíida",
"simória",
"simúlia",
"simúlio",
"sinabas",
"sinador",
"sinadão",
"sinalai",
"sinalas",
"sinante",
"sinapes",
"sinapta",
"sinapto",
"sinarei",
"sinarem",
"sinares",
"sinarmo",
"sinarás",
"sinarão",
"sinasse",
"sinaste",
"sinavas",
"sinaíte",
"sinaíto",
"sincele",
"sincopo",
"sindora",
"sinecas",
"sinecia",
"sinecos",
"sinedra",
"sinedro",
"sinelas",
"sinemos",
"sinergo",
"sinervo",
"sinetai",
"sinetam",
"sinetei",
"sinetem",
"sinetia",
"sineura",
"singres",
"siniana",
"siniano",
"sinieta",
"sinizai",
"sinizam",
"sinizas",
"sinizei",
"sinizem",
"sinizes",
"sinizou",
"sinoica",
"sinoico",
"sinotas",
"sinotia",
"sinotos",
"sinoíta",
"sinriza",
"sinsará",
"sinsins",
"sintans",
"sinteca",
"sintros",
"sinuamo",
"sinuará",
"sinuava",
"sinucai",
"sinucam",
"sinucos",
"sinucou",
"sinueis",
"sinuemo",
"sinuose",
"sinuque",
"sinuras",
"sinzais",
"sinápea",
"sinópea",
"sinópeo",
"siobona",
"sipatão",
"sipibas",
"sipiris",
"sipália",
"sipária",
"sipânea",
"siracus",
"sirages",
"siralis",
"sirbato",
"sirbota",
"sirdeno",
"sirecas",
"sirembo",
"sirenai",
"sirenam",
"sirenei",
"sirenem",
"sirgada",
"sirgais",
"sirgará",
"sirgava",
"sirguei",
"sirguem",
"sirgues",
"siribás",
"siricha",
"siriena",
"sirieno",
"sirigmo",
"sirinas",
"sirinos",
"sirioba",
"siritas",
"siritos",
"sirixas",
"sirotes",
"sirriza",
"sirrizo",
"sirsaca",
"sirzais",
"sirzamo",
"sirzino",
"sirênia",
"sisadas",
"sisador",
"sisados",
"sisadão",
"sisamos",
"sisante",
"sisaram",
"sisarei",
"sisarem",
"sisares",
"sisaria",
"sisarmo",
"sisarra",
"sisarás",
"sisarão",
"sisasse",
"sisaste",
"sisavam",
"sisavas",
"siseira",
"sisemos",
"sisgola",
"sismamo",
"sismara",
"sismeis",
"sismemo",
"sisonas",
"sisteco",
"sistila",
"sistolo",
"sisuras",
"sisífia",
"sisória",
"sitador",
"sitadão",
"sitanas",
"sitarca",
"sitarmo",
"sitaste",
"sitavas",
"sitelas",
"sitemos",
"sitiamo",
"sitiana",
"sitieis",
"sitiemo",
"sititas",
"sitites",
"sititos",
"sitonas",
"sitídea",
"sitídeo",
"sivales",
"sivanas",
"sivanes",
"sivanis",
"siálias",
"siálida",
"siáveis",
"siãozão",
"smogues",
"snifais",
"snifamo",
"snifará",
"snifeis",
"snifemo",
"soabram",
"soabras",
"soabrem",
"soabris",
"soadona",
"soadora",
"soadões",
"soalhai",
"soalham",
"soalhei",
"soalhem",
"soalhou",
"soardes",
"soareis",
"soaremo",
"soarias",
"soassai",
"soassam",
"soassas",
"soassei",
"soasses",
"soassou",
"soastes",
"sobadas",
"sobalce",
"sobalça",
"sobalço",
"sobaúra",
"sobejai",
"sobejei",
"sobejes",
"sobelho",
"soberbe",
"sobesca",
"sobessa",
"sobeste",
"sobesto",
"sobiada",
"sobiais",
"sobiamo",
"sobiará",
"sobieis",
"sobiemo",
"sobnega",
"sobnego",
"soboagã",
"soborós",
"sobpear",
"sobporá",
"sobpõem",
"sobpões",
"sobraso",
"sobrego",
"sobreir",
"sobrigo",
"sobsaia",
"socalca",
"socantã",
"socaras",
"socarmo",
"socarrã",
"socarão",
"socaste",
"socavai",
"socavei",
"socavem",
"socaves",
"sochiai",
"sochiam",
"sochias",
"sochiei",
"sochiem",
"sochies",
"sochiou",
"sochões",
"soclopé",
"sococós",
"socopos",
"socopés",
"socorne",
"socorno",
"socorós",
"socotos",
"socovás",
"sodadas",
"sodadão",
"sodamos",
"sodante",
"sodaram",
"sodarei",
"sodarem",
"sodares",
"sodaria",
"sodarmo",
"sodarás",
"sodarão",
"sodasse",
"sodaste",
"sodatol",
"sodavam",
"sodavas",
"sodaíta",
"sodaíte",
"sodaíto",
"soddita",
"sodemos",
"soditas",
"sodites",
"soedora",
"soerdes",
"soereis",
"soergas",
"soeriam",
"soerias",
"soermos",
"soessem",
"soesses",
"soestes",
"soestra",
"soestro",
"soezice",
"sofenha",
"sofenho",
"sofolié",
"soforai",
"soforar",
"soforei",
"soforem",
"sofores",
"soforim",
"soforou",
"sofreai",
"sofreie",
"sofrene",
"sofórea",
"sofóreo",
"sogadas",
"sogador",
"sogados",
"sogadão",
"sogamos",
"sogante",
"sogaram",
"sogaras",
"sogarei",
"sogarem",
"sogares",
"sogaria",
"sogarão",
"sogasse",
"sogaste",
"sogavam",
"sogavas",
"sograda",
"sogrado",
"sograis",
"sogramo",
"sogrará",
"sograva",
"sogreis",
"sogremo",
"sogueis",
"soidona",
"soidosa",
"soionas",
"soiosas",
"soiotas",
"soiotes",
"soiéria",
"sojigai",
"sojigas",
"sojigou",
"sojorne",
"sojugai",
"sojugam",
"sojugas",
"sojugou",
"sojugue",
"solacei",
"solacem",
"solapai",
"solapei",
"solapes",
"solarei",
"solarmo",
"solazai",
"solazam",
"solazar",
"solazas",
"solazei",
"solazem",
"solazes",
"solazou",
"solaçai",
"solaçam",
"solaçar",
"solaças",
"solaçou",
"soldais",
"soldamo",
"soldeis",
"soldemo",
"soldras",
"soleais",
"soleara",
"soleava",
"solecai",
"solecam",
"solecas",
"solecou",
"soleeis",
"soleiam",
"soleias",
"soleiem",
"soleies",
"soleque",
"solevai",
"solevas",
"solevei",
"solevem",
"soleves",
"solfada",
"solfais",
"solfamo",
"solfará",
"solfeis",
"solfemo",
"solhada",
"solhais",
"solhamo",
"solhará",
"solhava",
"solheis",
"solhemo",
"solicas",
"solidai",
"solinhe",
"soliota",
"solipés",
"solmize",
"solmizo",
"solmizá",
"solvais",
"solveio",
"solvemo",
"solveol",
"solvias",
"solvier",
"solvirá",
"solvéns",
"solzões",
"somavas",
"sombrem",
"sometam",
"sometei",
"sometia",
"somnata",
"somneca",
"somnial",
"sompada",
"sompado",
"sompais",
"sompamo",
"sompava",
"sompeis",
"sompemo",
"sonaram",
"sonarei",
"sonarmo",
"sonarão",
"sonasse",
"sonavam",
"sonavas",
"sondamo",
"sonegai",
"sonemos",
"sonetai",
"sonetem",
"sonetes",
"soneteá",
"sonetou",
"sonhemo",
"sonidas",
"sonismo",
"sonneis",
"sonsões",
"sonínia",
"sonívia",
"sopadas",
"sopaina",
"sopanga",
"sopapai",
"sopapam",
"sopapas",
"sopapei",
"sopapem",
"sopapes",
"sopapeá",
"sopapou",
"sopeais",
"sopeeis",
"sopegai",
"sopegam",
"sopegar",
"sopegas",
"sopegou",
"sopegue",
"sopeias",
"sopeiem",
"sopeies",
"sopendo",
"sopense",
"sopesai",
"sopeteá",
"sopiada",
"sopiado",
"sopiais",
"sopiamo",
"sopiará",
"sopiava",
"sopieis",
"sopiemo",
"sopilho",
"sopilão",
"sopitai",
"sopitem",
"sopites",
"soponta",
"soponte",
"soponto",
"soporal",
"sopores",
"soprese",
"sopreze",
"soprezo",
"sopuxai",
"sopuxam",
"sopuxar",
"sopuxas",
"sopuxei",
"sopuxem",
"sopuxes",
"sopuxou",
"soquais",
"soquamo",
"soqueai",
"soqueei",
"soqueis",
"soquemo",
"soquiam",
"soquias",
"soquida",
"soquido",
"soquimo",
"soquira",
"soradas",
"sorador",
"sorados",
"soradão",
"sorames",
"soramos",
"soranas",
"sorante",
"soraram",
"soraras",
"sorarei",
"sorarem",
"sorarmo",
"sorarão",
"sorasse",
"soraste",
"soravam",
"soravas",
"sorbicá",
"sorbina",
"sorbite",
"sorbois",
"sordões",
"soreada",
"soreado",
"soreais",
"soreará",
"soreava",
"soreeis",
"soreiam",
"soreias",
"soreiem",
"soreies",
"sorelos",
"soremos",
"soriela",
"soritas",
"sornada",
"sornado",
"sornais",
"sornamo",
"sornará",
"sornava",
"sorneai",
"sornear",
"sorneei",
"sorneia",
"sorneie",
"sorneio",
"sorneis",
"sornemo",
"sorneou",
"sorniam",
"sornias",
"sornida",
"sornimo",
"sornirá",
"sorocós",
"soropas",
"soroses",
"sorrabe",
"sorrabo",
"sorrena",
"sorrene",
"sorreno",
"sorroda",
"sortamo",
"sorteai",
"sortias",
"sortimo",
"sorumas",
"sorvada",
"sorvais",
"sorvamo",
"sorvará",
"sorvava",
"sorçona",
"sorédia",
"sorídio",
"sorócea",
"soródia",
"sorósio",
"sosanas",
"sosanes",
"sosanos",
"sosiana",
"soslaie",
"sospite",
"sospito",
"sostras",
"sostros",
"sotaans",
"sotadas",
"sotador",
"sotados",
"sotadão",
"sotalas",
"sotalhe",
"sotalho",
"sotalhá",
"sotamos",
"sotante",
"sotaram",
"sotarei",
"sotarem",
"sotares",
"sotaria",
"sotarmo",
"sotarás",
"sotarão",
"sotasse",
"sotaste",
"sotavam",
"sotavas",
"soteias",
"soterim",
"sotiate",
"sotobas",
"sotopus",
"sotroço",
"soturne",
"sotádea",
"sotádeo",
"sotíaca",
"soupico",
"soupicá",
"sousana",
"sousona",
"soutada",
"soutamo",
"soutará",
"souteis",
"soutemo",
"sovadão",
"sovante",
"sovaras",
"sovarei",
"sovares",
"sovarmo",
"sovarão",
"sovaste",
"sovavas",
"soveios",
"sovelai",
"sovelam",
"sovelei",
"sovelem",
"soveles",
"sovelou",
"soverta",
"soverti",
"soverto",
"sovessa",
"sovetas",
"sovetos",
"sovinai",
"sovinam",
"sovinem",
"sovinou",
"soáveis",
"soãozão",
"soçocai",
"soçocam",
"soçocou",
"soçoque",
"stalses",
"stasita",
"statohm",
"stereas",
"strumas",
"stuzita",
"stórmia",
"stúrmia",
"suabita",
"suabite",
"suadais",
"suadamo",
"suadiam",
"suadias",
"suadimo",
"suadora",
"suadões",
"suainas",
"suanita",
"suardão",
"suaremo",
"suarias",
"suarnas",
"suarnos",
"suasano",
"suasona",
"suasora",
"suasses",
"suaçuás",
"suaçuçu",
"subabia",
"subabos",
"subanal",
"subaves",
"subchão",
"subcopo",
"subcujo",
"subdeão",
"subdeãs",
"subedra",
"subedro",
"subeios",
"subenta",
"suberal",
"suberol",
"subexpô",
"subfila",
"subfixa",
"subjazi",
"subjazo",
"submuro",
"subnego",
"subnegá",
"subnula",
"subnulo",
"subpica",
"subporá",
"subpuja",
"subpuje",
"subpujo",
"subpõem",
"subpões",
"subragi",
"subsiga",
"subsigo",
"subulão",
"subíeis",
"sucadão",
"sucante",
"sucapés",
"sucaram",
"sucarei",
"sucarem",
"sucares",
"sucarmo",
"sucaros",
"sucarás",
"sucarão",
"sucasse",
"sucaste",
"sucatei",
"sucatem",
"sucates",
"sucatou",
"sucavas",
"sucedas",
"suciada",
"suciado",
"suciais",
"suciamo",
"suciará",
"suciava",
"sucieis",
"suciemo",
"sucisas",
"sucrato",
"sucrosa",
"sucróis",
"sucuama",
"sucupis",
"sucuíba",
"sucçona",
"sucínea",
"sucíneo",
"sucóvia",
"sudadas",
"sudador",
"sudadão",
"sudairo",
"sudamos",
"sudante",
"sudaram",
"sudarei",
"sudarem",
"sudares",
"sudarmo",
"sudarás",
"sudarão",
"sudasse",
"sudaste",
"sudavam",
"sudavas",
"sudeira",
"sudeiro",
"sudemos",
"sudotox",
"sudoíta",
"suessãs",
"suestai",
"suestam",
"suestas",
"suestei",
"suestem",
"suestou",
"suetras",
"suetros",
"sufarás",
"sufeita",
"sufixam",
"sufixas",
"sufixei",
"sufixem",
"sufixes",
"sufixou",
"suflada",
"suflais",
"suflamo",
"suflará",
"suflava",
"sufleis",
"suflemo",
"sufolié",
"sufufes",
"sufídea",
"sufídeo",
"sugadão",
"sugarda",
"sugarmo",
"sugilai",
"sugilam",
"sugilas",
"sugilei",
"sugilem",
"sugiles",
"sugilou",
"suguemo",
"suguiru",
"suilate",
"suilzão",
"suindás",
"suiruás",
"suiônia",
"suiônio",
"sujadão",
"sujante",
"sujelas",
"sujicai",
"sujicam",
"sujicar",
"sujicas",
"sujicés",
"sujigai",
"sujigam",
"sujigas",
"sujigue",
"sujique",
"sujugai",
"sujugam",
"sujugar",
"sujugas",
"sujugou",
"sujugue",
"suladas",
"sulador",
"sulados",
"suladão",
"sulamos",
"sulanco",
"sulando",
"sulante",
"sularam",
"sularas",
"sularei",
"sularem",
"sulares",
"sularia",
"sularmo",
"sularão",
"sulasse",
"sulaste",
"sulavam",
"sulavas",
"sulcamo",
"sulemos",
"sulfeta",
"sulfete",
"sulfino",
"sulfitá",
"sulídea",
"sulínea",
"sumagro",
"sumalar",
"sumalés",
"sumanal",
"sumbaia",
"sumbrai",
"sumbras",
"sumbrei",
"sumbrem",
"sumbres",
"sumbrou",
"sumbuis",
"sumelga",
"sumicha",
"sumicho",
"sumigas",
"sumirmo",
"sumptas",
"sumptos",
"sumulai",
"sumulam",
"sumulei",
"sumulem",
"sumules",
"sumíeis",
"sungais",
"sungara",
"sunguei",
"sunguem",
"sungues",
"sunhaçu",
"sunisga",
"sunisgo",
"sunucas",
"sunucos",
"sunçona",
"supenas",
"supenos",
"suponte",
"supontá",
"supormo",
"supramo",
"supurai",
"supures",
"suputai",
"suputam",
"suputas",
"suputei",
"suputem",
"suputes",
"suputou",
"suquais",
"suquamo",
"suqueis",
"suquemo",
"suquiam",
"suquias",
"suquida",
"suquido",
"suquimo",
"suquirá",
"suranes",
"suranga",
"surbião",
"surcais",
"surcamo",
"surcara",
"surdais",
"surdamo",
"surdeai",
"surdeei",
"surdeia",
"surdeie",
"surdeio",
"surdeou",
"surdias",
"surdimo",
"surdine",
"surfais",
"surfamo",
"surfeis",
"surfemo",
"surgimo",
"suribis",
"surilha",
"surilho",
"surjais",
"surjamo",
"suropos",
"surotes",
"surquei",
"surquem",
"surramo",
"surremo",
"surriai",
"surriam",
"surrias",
"surribe",
"surribo",
"surries",
"surriou",
"surtamo",
"surteis",
"surtemo",
"surtias",
"surtimo",
"surtuns",
"surucai",
"surucam",
"surucas",
"suruiás",
"surujés",
"surungá",
"suruque",
"suruíba",
"susanos",
"susfito",
"susinas",
"susquam",
"susquas",
"susquem",
"susquia",
"susquis",
"susquiu",
"sustais",
"sustamo",
"sustemo",
"sustias",
"sustosa",
"susunus",
"sutador",
"sutadão",
"sutagem",
"sutamos",
"sutanas",
"sutante",
"sutaram",
"sutarei",
"sutarem",
"sutares",
"sutarmo",
"sutarás",
"sutarão",
"sutasse",
"sutaste",
"sutates",
"sutavam",
"sutavas",
"sutemos",
"suteras",
"sutoras",
"sutrino",
"sutrões",
"suturai",
"sutória",
"suvinos",
"suxadas",
"suxador",
"suxados",
"suxadão",
"suxamos",
"suxando",
"suxante",
"suxaram",
"suxarei",
"suxarem",
"suxares",
"suxaria",
"suxarmo",
"suxarás",
"suxarão",
"suxasse",
"suxaste",
"suxavam",
"suxavas",
"suxemos",
"suzunus",
"suãozão",
"suícero",
"suídeas",
"suízaro",
"svabita",
"sábanos",
"sábenas",
"sácaros",
"sádidas",
"ságenas",
"ságreas",
"ságulos",
"sájenas",
"sáldula",
"sálices",
"sálpias",
"sálpida",
"sálpios",
"sálseas",
"sálseos",
"sárdias",
"sáricas",
"sáricos",
"sáurida",
"sáurido",
"sâmagos",
"sâmaris",
"sâmolos",
"sântalo",
"sântias",
"sântono",
"séjanas",
"sélgica",
"sélgico",
"sépions",
"sépside",
"sépteis",
"séptulo",
"séptunx",
"séquana",
"séquios",
"sérbias",
"sérgias",
"sérides",
"sérolas",
"sérolis",
"sérreas",
"sérreos",
"sértula",
"sértulo",
"sésegas",
"séseles",
"séselis",
"sésicas",
"sésigas",
"séssega",
"sêbados",
"sêmnone",
"sênegas",
"sênseis",
"sêssego",
"síagros",
"síbaras",
"síbaris",
"síbinas",
"síbines",
"síceras",
"sícones",
"sífneas",
"sífneos",
"sífnias",
"sífnios",
"sífulas",
"sígmodo",
"síleres",
"sílfida",
"sílfion",
"sílfios",
"sílibos",
"sílvias",
"sílvida",
"símbama",
"símelas",
"símelos",
"símicas",
"símpode",
"símpodo",
"símulos",
"sínafes",
"síncito",
"sínfana",
"sínfano",
"sínfase",
"sínfila",
"sínfita",
"sínfodo",
"síngama",
"síngamo",
"síngila",
"síngona",
"síngono",
"sínocas",
"sínodas",
"sínofro",
"sínteta",
"síntona",
"síracas",
"sírnios",
"sírtica",
"sírtico",
"síspono",
"sístate",
"síticos",
"sítidas",
"sítones",
"sólimas",
"sóracos",
"sórices",
"sósibes",
"sótnias",
"sônquia",
"sôveros",
"súbulos",
"súcinos",
"súlidas",
"súnhaca",
"súpetas",
"súpetos",
"súpitas",
"súpitos",
"súrnias",
"sútrias",
"sútrios",
"taanhés",
"tabagia",
"tabagos",
"tabalos",
"tabarzé",
"tabazes",
"tabecas",
"tabedai",
"tabedáe",
"tabicai",
"tabicam",
"tabicou",
"tabilha",
"tabinos",
"tabizai",
"tabizam",
"tabizas",
"tabizei",
"tabizem",
"tabizes",
"tabizou",
"tablina",
"tabolim",
"taborro",
"tabucai",
"tabucam",
"tabucar",
"tabucas",
"tabucou",
"tabujos",
"tabuona",
"taburne",
"taburná",
"tabuíza",
"tabuíze",
"tabuízo",
"tacadão",
"tacaras",
"tacarmo",
"tacaste",
"tacauás",
"tacavas",
"taceiro",
"tachais",
"tacheai",
"tacheei",
"tacheie",
"tacheio",
"tacheis",
"tachemo",
"tacheou",
"tachins",
"tachone",
"tachono",
"taciaís",
"tacibas",
"tacoide",
"tacoila",
"taconas",
"tacoses",
"tacosão",
"tacteai",
"tactema",
"tacuela",
"tacujás",
"taculas",
"tacunás",
"tacáceo",
"tacócua",
"tadiate",
"taelzão",
"tafeiro",
"tafiana",
"tafiano",
"tafifes",
"tafiles",
"tafires",
"tafonés",
"tafrias",
"tafrina",
"tafulai",
"tafulam",
"tafulas",
"tafulei",
"tafulem",
"tafules",
"tafulha",
"tafulhe",
"tafulou",
"tagalas",
"taganos",
"taganta",
"taganto",
"tagetos",
"tagonas",
"taguaús",
"tagênia",
"tagídea",
"taiamar",
"taietês",
"tainado",
"tainais",
"tainamo",
"tainava",
"taineis",
"tainemo",
"taioval",
"taiovas",
"taipamo",
"taipará",
"taipeis",
"taipemo",
"taipões",
"tairoco",
"taiseno",
"taiuras",
"taiutás",
"taiuvas",
"tajasno",
"tajibás",
"tajipás",
"tajubas",
"tajujás",
"tajupás",
"tajuvas",
"taladão",
"talagui",
"talapõe",
"talarei",
"talarem",
"talarmo",
"talarás",
"talarão",
"talavas",
"talaços",
"talcite",
"talcito",
"talerai",
"taleram",
"talerar",
"taleras",
"talerei",
"talerem",
"talerou",
"talevas",
"talhais",
"talhamo",
"talheis",
"talhemo",
"talhona",
"taliate",
"taliato",
"talicão",
"talides",
"taligas",
"talimai",
"talimam",
"talimei",
"talimem",
"talimes",
"talimou",
"talingo",
"talinos",
"taliona",
"talione",
"taliono",
"taliosa",
"talioso",
"talipos",
"talmuda",
"talocos",
"talodis",
"talonai",
"talonam",
"talonas",
"talonei",
"talonem",
"taloneá",
"talonou",
"taloras",
"taloros",
"taloses",
"talpose",
"talucta",
"taludai",
"taludam",
"taludei",
"taludem",
"talzões",
"tamajuá",
"tamarma",
"tamatis",
"tambaru",
"tambiós",
"tambono",
"tambuis",
"tamiago",
"tamiano",
"tamisai",
"tamisam",
"tamisas",
"tamisei",
"tamisem",
"tamisou",
"tamiças",
"tamoões",
"tampais",
"tampeis",
"tampemo",
"tampono",
"tamposa",
"tamuana",
"tamucus",
"tamujes",
"tamujos",
"tamungo",
"tamusco",
"tamuscá",
"tamuzes",
"tanacol",
"tanadas",
"tanador",
"tanados",
"tanadão",
"tanaide",
"tanamos",
"tananás",
"tanaram",
"tanarei",
"tanarem",
"tanares",
"tanarmo",
"tanarás",
"tanarão",
"tanasse",
"tanaste",
"tanavam",
"tanavas",
"tanazai",
"tanazam",
"tanazas",
"tanazei",
"tanazem",
"tanazou",
"tanaúba",
"tancais",
"tancame",
"tancamo",
"tancava",
"tanchai",
"tancham",
"tanchas",
"tanchei",
"tanchem",
"tanches",
"tandéis",
"tanecos",
"tanemos",
"tangada",
"tangais",
"tangava",
"tangeis",
"tangemo",
"tangias",
"tanguei",
"tanguem",
"tangueá",
"tanguim",
"tanguta",
"tanguás",
"tanhada",
"taninai",
"taninam",
"taninar",
"taninei",
"taninem",
"tanines",
"taninou",
"tanjais",
"tanjamo",
"tanjudo",
"tanjugo",
"tanoada",
"tanoado",
"tanoais",
"tanoamo",
"tanoara",
"tanoava",
"tanoeis",
"tanoemo",
"tanoide",
"tanonas",
"tanosal",
"tanquem",
"tanquia",
"tanteai",
"tanteei",
"tanteia",
"tanteie",
"tanteio",
"tanteou",
"tanuana",
"tanuíne",
"tanável",
"tanécio",
"tapacum",
"tapante",
"tapaquá",
"taparis",
"taparmo",
"tapeais",
"tapeamo",
"tapecem",
"tapeces",
"tapeeis",
"tapeemo",
"tapeies",
"tapeixa",
"tapemas",
"tapenas",
"taperus",
"tapetai",
"tapetei",
"tapetou",
"tapeçai",
"tapeçam",
"tapeças",
"tapeçou",
"tapiaís",
"tapicei",
"tapicem",
"tapisas",
"tapitim",
"tapiuás",
"tapixis",
"tapixós",
"tapizai",
"tapizas",
"tapizei",
"tapizem",
"tapizou",
"tapiçai",
"tapiçam",
"tapiçar",
"tapiças",
"tapiçou",
"taporas",
"tapotim",
"tapujis",
"tapulha",
"tapulhe",
"taputém",
"tapável",
"taqueai",
"taqueei",
"taqueie",
"taqueis",
"taquemo",
"taqueou",
"taquirá",
"tarabos",
"tarabés",
"tarador",
"taralha",
"taralhe",
"taramás",
"tarapés",
"tararei",
"tararem",
"tararmo",
"tarasse",
"taraste",
"taravas",
"taraxes",
"tarbesa",
"tarcena",
"tardamo",
"tardemo",
"tardina",
"tardora",
"tardões",
"tareado",
"tareais",
"tareará",
"tareava",
"tareeis",
"tarefei",
"tarefem",
"tarefou",
"tareiam",
"tareiem",
"tareies",
"tarelai",
"tarelam",
"tarelas",
"tarelei",
"tarelem",
"tareles",
"tarelou",
"tarenas",
"tarerés",
"targras",
"tarifai",
"tarifei",
"tarimbe",
"tariras",
"tariris",
"tarisas",
"tarjais",
"tarjamo",
"tarjará",
"tarjeis",
"tarjemo",
"tarjões",
"tarocai",
"tarocam",
"tarocos",
"tarocou",
"tarolai",
"tarolam",
"tarolei",
"tarolem",
"taroles",
"tarolou",
"taronos",
"taropés",
"tarotes",
"tarraxe",
"tarreai",
"tarreei",
"tarreia",
"tarreie",
"tarreio",
"tarreou",
"tarreto",
"tarrute",
"tarruto",
"tarrutá",
"tarréus",
"tarrões",
"tarubás",
"tarucos",
"tarugai",
"tarugam",
"tarugue",
"tarvias",
"tascais",
"tascamo",
"tascoai",
"tascoam",
"tascoas",
"tascoei",
"tascoem",
"tascoes",
"tascoou",
"tasgada",
"tasgado",
"tasgais",
"tasgará",
"tasgava",
"tasguei",
"tasguem",
"tasgues",
"tasnona",
"tasteai",
"tasteei",
"tasteia",
"tasteie",
"tasteio",
"tasteou",
"tatabus",
"tatalai",
"tatalas",
"tatalei",
"tatalem",
"tatales",
"tatanés",
"tatarez",
"tatareá",
"tateamo",
"tateeis",
"tateemo",
"tateras",
"tatogas",
"tatuamo",
"tatucaá",
"tatueis",
"tatuemo",
"tatuiás",
"taturas",
"taturus",
"tatuços",
"tauanãs",
"tauaçus",
"tauguis",
"tauiris",
"tauités",
"tauncho",
"taurilo",
"taurita",
"tautaus",
"tauteai",
"tautear",
"tauteei",
"tauteie",
"tauteio",
"tauteou",
"tauxiai",
"tauxiam",
"tauxiei",
"tauxiem",
"tauxies",
"tavalas",
"tavenos",
"tavilas",
"tavitas",
"tavites",
"tavocas",
"taxaras",
"taxarmo",
"taxaste",
"taxavas",
"taxemas",
"taxiais",
"taxiamo",
"taxieis",
"taxiemo",
"taxinas",
"taxipás",
"taxiras",
"taxites",
"taxizal",
"taxupás",
"taxuris",
"taxáceo",
"taxínea",
"tazolol",
"teagoga",
"teagogo",
"tealite",
"tebedaí",
"tebeias",
"tebexês",
"tebuane",
"tecador",
"tecadão",
"tecante",
"tecaram",
"tecarei",
"tecarem",
"tecares",
"tecaria",
"tecarás",
"tecarão",
"tecasse",
"tecaste",
"tecavam",
"tecavas",
"tecebás",
"tecermo",
"technas",
"tecites",
"teclais",
"teclamo",
"tecleai",
"tecleei",
"tecleia",
"tecleie",
"tecleio",
"tecleis",
"teclemo",
"tecleou",
"tecoles",
"tecuena",
"tecueno",
"tecumas",
"tecunas",
"tecália",
"tecídea",
"tecíeis",
"tecômea",
"tedores",
"tedânia",
"teedora",
"teflões",
"tefrina",
"tefrita",
"tefrite",
"tefrose",
"tegeeus",
"tegmais",
"tegéria",
"teimada",
"teimamo",
"teimeis",
"teimemo",
"teiteis",
"tejaroz",
"tejubus",
"tejucos",
"tejupim",
"tejutiú",
"tejuíba",
"tejídea",
"tejídeo",
"telador",
"teladão",
"telarei",
"telarmo",
"telaros",
"telarás",
"telarão",
"telaste",
"telavam",
"telavas",
"telebês",
"telecal",
"teleses",
"televas",
"televei",
"televem",
"televeu",
"televim",
"telexai",
"telexam",
"telexas",
"telexei",
"telexem",
"telhamo",
"telheis",
"telhemo",
"teliano",
"telinte",
"telinto",
"telites",
"telopes",
"telopse",
"teléboa",
"teléboo",
"telídio",
"telínio",
"temblai",
"temblam",
"temblas",
"temblei",
"temblem",
"tembles",
"temblou",
"temembu",
"temermo",
"temetos",
"temidão",
"temnita",
"temodox",
"temonai",
"temonam",
"temonas",
"temonei",
"temonem",
"temones",
"temonou",
"templai",
"templei",
"tenalhe",
"tenalho",
"tenalim",
"tenazai",
"tenazam",
"tenazei",
"tenazem",
"tenazou",
"tendamo",
"tendona",
"tengues",
"tenhosa",
"teniada",
"teniado",
"teniata",
"teniota",
"tenitos",
"teniúra",
"tenosal",
"tenrada",
"tensona",
"tenteai",
"tenteal",
"tentigo",
"tenténs",
"tençoai",
"tençoam",
"tençoas",
"tençoei",
"tençoem",
"tençona",
"tençoou",
"tenédia",
"tenédio",
"teocais",
"teocina",
"teonela",
"teonino",
"teopeia",
"teoteia",
"tepores",
"tequeis",
"tequemo",
"tequete",
"tequeto",
"tequetá",
"terarca",
"terbina",
"terceis",
"tercemo",
"terceta",
"terciai",
"terciei",
"terciem",
"tercies",
"terciou",
"terebre",
"teredão",
"tergite",
"terités",
"terjure",
"terjuro",
"terjurá",
"terlada",
"terlade",
"terlado",
"terluca",
"termião",
"ternamo",
"ternava",
"terneis",
"ternemo",
"terpola",
"terramo",
"terrava",
"terreai",
"terreei",
"terreie",
"terreje",
"terrejo",
"terrejá",
"terreou",
"terréus",
"teruela",
"teruelo",
"terçais",
"terçamo",
"terções",
"teríaco",
"terídio",
"tesadas",
"tesador",
"tesadão",
"tesaram",
"tesarei",
"tesarem",
"tesares",
"tesaria",
"tesarmo",
"tesarás",
"tesarão",
"tesasse",
"tesaste",
"tesavam",
"tesavas",
"tescões",
"teseias",
"tesicam",
"tesidas",
"teslaia",
"teslaio",
"teslino",
"tesmina",
"tesmine",
"tesmino",
"tesoire",
"tesonas",
"tespieu",
"tesselo",
"testeai",
"testeie",
"testicá",
"testugo",
"testugá",
"tesíeas",
"tetador",
"tetadão",
"tetanis",
"tetares",
"tetarmo",
"tetarás",
"tetarão",
"tetasse",
"tetaste",
"tetavas",
"tetetés",
"tetilas",
"tetrilo",
"tetrite",
"tetrope",
"tetróis",
"tetídea",
"tetídeo",
"tetígia",
"tevécia",
"tevétia",
"textora",
"texturo",
"tezimes",
"teáceos",
"teálias",
"teédias",
"teíneas",
"teófaga",
"teófoba",
"teórida",
"teômana",
"teônima",
"teônoes",
"thúrnia",
"tialina",
"tialzão",
"tiambos",
"tianeia",
"tianeus",
"tianhas",
"tiarela",
"tiarena",
"tiareno",
"tiarina",
"tiarode",
"tiasote",
"tiatida",
"tiaçuba",
"tibacas",
"tibadas",
"tibador",
"tibados",
"tibadão",
"tibamos",
"tibanco",
"tibando",
"tibante",
"tibaram",
"tibaras",
"tibarei",
"tibarem",
"tibaria",
"tibarmo",
"tibarão",
"tibasse",
"tibaste",
"tibavam",
"tibavas",
"tibelos",
"tibirai",
"tibiram",
"tibirar",
"tibirei",
"tibirem",
"tibires",
"tibirou",
"tibrina",
"tibrino",
"tibunas",
"tibunga",
"tiburna",
"ticadão",
"ticaras",
"ticarem",
"ticaria",
"ticarmo",
"ticasse",
"ticaste",
"ticavas",
"tichita",
"ticonos",
"ticopás",
"ticorós",
"ticosas",
"ticosos",
"ticuara",
"ticucas",
"ticupis",
"ticupás",
"tidonas",
"tidoras",
"tielita",
"tiemias",
"tienzus",
"tietais",
"tietamo",
"tietará",
"tieteis",
"tietemo",
"tifeias",
"tifenta",
"tifento",
"tifista",
"tiflina",
"tiflono",
"tiflope",
"tifoias",
"tifosas",
"tifoses",
"tifáceo",
"tiféola",
"tifídea",
"tifídeo",
"tifóbia",
"tigelos",
"tiglina",
"tiglões",
"tiguras",
"tigúlia",
"tigúlio",
"tiieras",
"tijibus",
"tijocos",
"tijolai",
"tijolam",
"tijolei",
"tijolem",
"tijoles",
"tiladas",
"tilados",
"tiladão",
"tilamos",
"tilanda",
"tilando",
"tilante",
"tilaram",
"tilaras",
"tilarei",
"tilarem",
"tilares",
"tilarmo",
"tilarão",
"tilasse",
"tilaste",
"tilavam",
"tilavas",
"tilaído",
"tilaíta",
"tilaíte",
"tildais",
"tildamo",
"tildará",
"tildava",
"tildeis",
"tildemo",
"tileias",
"tilemos",
"tilenco",
"tilinas",
"tilodes",
"tilodos",
"tilosas",
"tilosos",
"tilácio",
"tilécia",
"tiléria",
"tilétia",
"tilíbix",
"tilíeas",
"tilínea",
"tilíneo",
"tilômio",
"timalho",
"timalos",
"timaneu",
"timarca",
"timares",
"timbrai",
"timbuai",
"timbuam",
"timbuas",
"timbuei",
"timbuem",
"timbues",
"timbuou",
"timbuís",
"timeleu",
"timenas",
"timicus",
"timilos",
"timiose",
"timonai",
"timonam",
"timonas",
"timonei",
"timonem",
"timones",
"timoneá",
"timonou",
"timália",
"timáris",
"timélia",
"timênio",
"tinaria",
"tincais",
"tindins",
"tinebre",
"tinefes",
"tineias",
"tinemba",
"tinetas",
"tinfeia",
"tinfeus",
"tingada",
"tingado",
"tingais",
"tingava",
"tingias",
"tingimo",
"tingote",
"tingura",
"tinhões",
"tinidão",
"tinimos",
"tinirei",
"tinires",
"tiniria",
"tinirmo",
"tiniste",
"tinitos",
"tinjais",
"tinjamo",
"tinjema",
"tinoada",
"tinocas",
"tinodes",
"tinotes",
"tinotos",
"tinquis",
"tintamo",
"tintará",
"tintava",
"tinteis",
"tintemo",
"tintile",
"tintine",
"tintini",
"tintlai",
"tintlam",
"tintlar",
"tintlas",
"tintlei",
"tintlem",
"tintles",
"tintlou",
"tintora",
"tinéola",
"tiníaca",
"tiníaco",
"tinídea",
"tinídeo",
"tiníeis",
"tinória",
"tioanil",
"tioanis",
"tiocole",
"tiocóis",
"tiofana",
"tiofano",
"tioicas",
"tioicos",
"tiombes",
"tionato",
"tionida",
"tionido",
"tionóis",
"tiorgas",
"tiossal",
"tipicai",
"tipique",
"tipiris",
"tipixas",
"tipizai",
"tipizam",
"tipizas",
"tipizei",
"tipizem",
"tipizes",
"tipizou",
"tiponas",
"tipotes",
"tipucas",
"tipuiús",
"tipânea",
"tipâneo",
"tipória",
"tipório",
"tiqueai",
"tiqueei",
"tiqueia",
"tiqueie",
"tiqueio",
"tiqueis",
"tiquemo",
"tiqueou",
"tirapés",
"tirazes",
"tircana",
"tireial",
"tiretes",
"tiriamã",
"tiriras",
"tiritai",
"tiroial",
"tiromas",
"tironeá",
"tiroteá",
"tirsito",
"tirsosa",
"tiríase",
"tirídia",
"tirídio",
"tisicai",
"tisicam",
"tisicar",
"tisicou",
"tisique",
"tisitas",
"tisites",
"tisitos",
"tismíea",
"tisnais",
"tisnamo",
"tisneis",
"tisnemo",
"tistias",
"tisúria",
"titereá",
"titeris",
"titicai",
"titicou",
"titilai",
"titilas",
"titilei",
"titilem",
"titiles",
"titiras",
"titubai",
"titubam",
"titubas",
"titubem",
"titubes",
"titubou",
"titulai",
"titânox",
"tiufada",
"tiureto",
"tixadil",
"tixadis",
"tixanox",
"tizitas",
"tiçalho",
"tiéstea",
"tiésteo",
"tiêmica",
"tiêmico",
"tiíadas",
"tiófila",
"tiógena",
"tiógeno",
"tiólase",
"tiônica",
"tlaspes",
"tlintai",
"tlintam",
"tlintas",
"tlintei",
"tlintem",
"tlintes",
"tlintou",
"tlipses",
"tluquis",
"tláspea",
"tmolita",
"tmólios",
"toadona",
"toadora",
"toadões",
"toardas",
"toardes",
"toaremo",
"toariam",
"toarias",
"toassem",
"toasses",
"toastes",
"tobeira",
"tobotes",
"tocadão",
"tocaiai",
"tocaiei",
"tocaiem",
"tocaies",
"tocaião",
"tocajés",
"toceada",
"toceado",
"toceais",
"toceará",
"toceava",
"toceeis",
"toceiam",
"toceias",
"toceiem",
"toceies",
"tococas",
"toddita",
"todeira",
"todália",
"todídea",
"todídeo",
"todínea",
"toeiros",
"toenita",
"toenite",
"toesada",
"toesado",
"toesais",
"toesamo",
"toesará",
"toesava",
"toeseis",
"toesemo",
"tofeira",
"tofácio",
"togador",
"togadão",
"togamos",
"togante",
"togaram",
"togarei",
"togaria",
"togarão",
"togasse",
"togaste",
"togatas",
"togatos",
"togavam",
"togavas",
"togueis",
"toguesa",
"toiceis",
"toicemo",
"toirado",
"toirais",
"toiramo",
"toirará",
"toirava",
"toireai",
"toireei",
"toireia",
"toireie",
"toireis",
"toireje",
"toirejo",
"toirejá",
"toiremo",
"toirigo",
"toiruna",
"toiteai",
"toiteei",
"toiteia",
"toiteie",
"toiteio",
"toiteou",
"toiçada",
"toiçado",
"toiçais",
"toiçamo",
"toiçará",
"toiçava",
"tojosas",
"tolamos",
"tolanas",
"tolanga",
"tolanos",
"tolazes",
"tolaças",
"tolaços",
"toldais",
"toldamo",
"toldana",
"toldeis",
"toldemo",
"tolejai",
"tolejam",
"tolejas",
"tolejei",
"tolejem",
"tolejes",
"tolemos",
"tolenos",
"tolermo",
"tolesse",
"toleste",
"toletas",
"tolhais",
"tolhamo",
"tolheis",
"tolhemo",
"tolhias",
"tolidão",
"tolilas",
"tolilos",
"tolinai",
"tolinam",
"tolinei",
"tolinem",
"tolines",
"tolinou",
"tolismo",
"tolista",
"tolitas",
"tolites",
"tolonas",
"toluato",
"toluena",
"toluica",
"toluizá",
"toluíla",
"toluílo",
"toluíno",
"toluíze",
"toluízo",
"toluóis",
"tolíeis",
"tolílio",
"tolúcia",
"tomabeu",
"tomarto",
"tomaína",
"tomaíta",
"tomaíte",
"tomaíto",
"tombamo",
"tombeai",
"tombear",
"tombeei",
"tombeie",
"tombeio",
"tombemo",
"tombeou",
"tombole",
"tombões",
"tomisos",
"tomoatá",
"tomômio",
"tonadão",
"tonares",
"tonarmo",
"tonarás",
"tonaste",
"tonavas",
"toncaís",
"tonizai",
"tonizam",
"tonizas",
"tonizei",
"tonizem",
"tonizes",
"tonizou",
"tonoses",
"tonsada",
"tonsado",
"tonsais",
"tonsamo",
"tonsará",
"tonsava",
"tonseis",
"tonsemo",
"tontada",
"tonteai",
"tonteje",
"tontejo",
"tontejá",
"tontino",
"tonésia",
"tonínia",
"topante",
"toparmo",
"toparós",
"topazas",
"topeada",
"topeado",
"topeais",
"topeará",
"topeava",
"topeeis",
"topeiam",
"topeias",
"topeiem",
"topeies",
"topejai",
"topejam",
"topejar",
"topejas",
"topejei",
"topejem",
"topejes",
"topejou",
"topense",
"topetai",
"topetam",
"topetas",
"topetei",
"topetem",
"topiais",
"topiamo",
"topiava",
"topieis",
"topiemo",
"topinas",
"topinos",
"topista",
"topitás",
"topóbea",
"toquelô",
"toquila",
"toradão",
"toralho",
"torarei",
"torares",
"torarmo",
"torarás",
"torarão",
"toraste",
"toravas",
"torceai",
"torcear",
"torceei",
"torcego",
"torcegá",
"torceia",
"torceie",
"torcemo",
"torcule",
"tordeio",
"tordela",
"torecta",
"torenga",
"torgote",
"torgões",
"toricto",
"torites",
"torneai",
"torneje",
"tornejo",
"tornina",
"tornois",
"torofos",
"toronam",
"toronas",
"toronei",
"toronem",
"toroneu",
"torosas",
"torpece",
"torpeci",
"torramo",
"torreai",
"torreei",
"torreie",
"torreje",
"torrela",
"torroas",
"tortuno",
"tortões",
"torumãs",
"torvais",
"torvamo",
"torvate",
"torvemo",
"torânia",
"torçamo",
"torçona",
"torçóis",
"torúlea",
"tosadão",
"tosante",
"tosaras",
"tosarei",
"tosares",
"tosarmo",
"tosarão",
"tosasse",
"tosaste",
"tosavas",
"toscada",
"toscava",
"toselas",
"tosemos",
"tosilas",
"tosquei",
"tosquem",
"tosques",
"tossego",
"tossegá",
"tossiga",
"tossigo",
"tossila",
"tossimo",
"tostais",
"tostamo",
"tostego",
"tostegá",
"tosteis",
"tostemo",
"tosulur",
"totalux",
"totanas",
"touaíte",
"toucais",
"touceis",
"toucemo",
"toufões",
"touquei",
"touramo",
"tourará",
"tourava",
"toureai",
"toureis",
"toureja",
"toureje",
"tourejo",
"touremo",
"touruna",
"touteai",
"touteei",
"touteie",
"touteio",
"toutelo",
"touteou",
"touçamo",
"toviada",
"toviado",
"toxicai",
"toxicam",
"toxicou",
"toxonas",
"toxógum",
"toxúria",
"toáreis",
"toáveis",
"toância",
"trabais",
"trabola",
"trabota",
"trabuis",
"trabula",
"trabule",
"traceis",
"tracemo",
"tracuro",
"tradais",
"tradamo",
"tradará",
"tradava",
"tradeai",
"tradeia",
"tradeie",
"tradeio",
"tradela",
"trademo",
"tradeou",
"tragine",
"traguer",
"tragueá",
"traguás",
"traiamo",
"trainai",
"trainam",
"trainei",
"trairmo",
"traiuçu",
"trajamo",
"trajemo",
"tralade",
"traladá",
"tralhai",
"tralhem",
"tralhes",
"tramamo",
"tramele",
"tramemo",
"trameta",
"tramete",
"trameti",
"tramosa",
"trampeá",
"tranada",
"tranais",
"tranamo",
"tranará",
"traneis",
"tranoca",
"tranoco",
"trapeai",
"trapeei",
"trapeie",
"trapeje",
"trapejo",
"trapeou",
"trapios",
"trapuca",
"trapéis",
"traquás",
"trasgas",
"trassai",
"trasses",
"trasteá",
"trasvie",
"trasvio",
"trateai",
"trateei",
"trateie",
"trateou",
"tratoro",
"trausas",
"trautai",
"trautas",
"trautei",
"trautem",
"trautes",
"trautou",
"traveje",
"travejo",
"travemo",
"traveta",
"travias",
"travota",
"traçamo",
"traíeis",
"trealas",
"trebana",
"treboai",
"treboam",
"treboar",
"treboas",
"treboei",
"treboem",
"treboes",
"trebois",
"treboou",
"treboçu",
"trebulo",
"trefile",
"trefine",
"trefino",
"treitai",
"treitam",
"treitei",
"treitem",
"treites",
"trejugo",
"trejugá",
"trejura",
"trejure",
"trelais",
"treleai",
"trelear",
"treleei",
"treleia",
"treleie",
"treleis",
"trelemo",
"treleou",
"trelerá",
"trelice",
"tremamo",
"tremará",
"tremesa",
"tremeta",
"tremeti",
"tremeto",
"tremoce",
"trempem",
"tremude",
"tremudo",
"tremudá",
"tremões",
"trenais",
"trenamo",
"trenará",
"trenemo",
"trenlas",
"trenode",
"trenças",
"treoses",
"trepamo",
"trepane",
"trepeço",
"trepico",
"trepola",
"tresfie",
"tresfio",
"tresfiá",
"tresias",
"trespor",
"tressue",
"tresvam",
"tresvei",
"tresvem",
"tresver",
"tresveu",
"tresvie",
"tresvio",
"treteai",
"treteei",
"treteia",
"treteie",
"treteio",
"treteou",
"treçóis",
"treícia",
"treício",
"triadão",
"triales",
"trianas",
"trianto",
"triarei",
"triarmo",
"triarão",
"triaspe",
"triasse",
"triavas",
"tribica",
"tribico",
"triboca",
"triboco",
"tribofa",
"tribofo",
"tribuis",
"tribuçu",
"tricles",
"tridias",
"triecas",
"triecos",
"trielco",
"triemos",
"trietis",
"trifaca",
"trigará",
"trigava",
"triglos",
"triguem",
"trilais",
"trilamo",
"trileis",
"trilemo",
"trilina",
"trimeza",
"trinais",
"trinamo",
"trincai",
"trineis",
"trinema",
"trinemo",
"trinfai",
"trinfam",
"trinfas",
"trinfei",
"trinfem",
"trinfes",
"tringas",
"triniam",
"trinias",
"trinido",
"trinimo",
"trinirá",
"trinode",
"trinoto",
"trintai",
"trintam",
"trintem",
"trintes",
"trinule",
"trinulá",
"triopes",
"triosga",
"tripamo",
"tripava",
"tripeis",
"tripemo",
"tripeço",
"trisamo",
"triscai",
"triseis",
"trisemo",
"triseto",
"trisnas",
"trissai",
"trissal",
"trissam",
"trissas",
"trissei",
"trissem",
"trissou",
"tritado",
"tritais",
"tritará",
"tritava",
"triteis",
"tritilo",
"tritine",
"tritino",
"tritião",
"tritois",
"trivato",
"triásia",
"triásio",
"triânea",
"triódia",
"triúras",
"triúsas",
"triúsos",
"troados",
"troadão",
"troamos",
"troarei",
"troaria",
"troarmo",
"troarás",
"troasse",
"troaste",
"troavas",
"trocemo",
"trochai",
"trocham",
"trochei",
"trochem",
"troches",
"trochou",
"trocmas",
"trocmos",
"troctes",
"troctos",
"troemos",
"trogias",
"trogina",
"trogino",
"trogmas",
"troiada",
"troiado",
"troiais",
"troiará",
"troiava",
"troieis",
"troiles",
"troiras",
"troixel",
"troixos",
"troiões",
"trolins",
"trolzão",
"trombai",
"trombis",
"tromila",
"trompai",
"trompam",
"trompem",
"trompeá",
"tronamo",
"troncai",
"troncam",
"trondas",
"trondão",
"troneai",
"troneei",
"troneia",
"troneie",
"troneis",
"troneje",
"tronemo",
"trongas",
"tronque",
"tropado",
"tropais",
"tropamo",
"tropato",
"tropava",
"tropeai",
"tropeie",
"tropeje",
"tropejo",
"tropejá",
"tropemo",
"tropeno",
"tropido",
"tropita",
"tropões",
"trotamo",
"troteai",
"troteis",
"troteje",
"trotejo",
"trotejá",
"trotemo",
"trotine",
"troucei",
"troucem",
"trouces",
"trouxel",
"trouxos",
"trouçai",
"trouçam",
"trouçar",
"trouças",
"trovais",
"trovamo",
"trovará",
"troveis",
"trovemo",
"trovoai",
"trovoas",
"trovoei",
"trovoem",
"trovosa",
"truaneá",
"truaria",
"trucais",
"trucamo",
"trucará",
"trucila",
"trucile",
"trucilo",
"trucuás",
"trufamo",
"trufará",
"trufava",
"trufeis",
"trufemo",
"trumaís",
"truncai",
"trunfai",
"trunfam",
"trunfei",
"trunfem",
"trunfes",
"trupado",
"trupamo",
"trupará",
"trupava",
"trupeis",
"trupemo",
"trupiam",
"trupias",
"trupida",
"trupido",
"trupirá",
"trupite",
"trupito",
"trupitá",
"truquem",
"trussas",
"trusteá",
"trutino",
"truxona",
"trádito",
"trágias",
"trágios",
"trágopa",
"trázios",
"trébios",
"trérons",
"tréssis",
"trévera",
"trévero",
"trévira",
"tríacis",
"tríbada",
"tríbado",
"trícero",
"trícias",
"trícopo",
"trídimo",
"trífera",
"trífero",
"tríforo",
"trígama",
"trígeos",
"trígina",
"trígons",
"trílabo",
"trílios",
"trílobo",
"trílofo",
"trímios",
"trímoda",
"trímodo",
"trínace",
"trínios",
"trínulo",
"tríodon",
"tríparo",
"trípila",
"trípton",
"tríquia",
"tríquis",
"trístia",
"trítica",
"trítoma",
"trízias",
"trócopo",
"trófide",
"trófons",
"trógios",
"trógono",
"trógons",
"trógulo",
"trólios",
"trônios",
"trúxalo",
"tuamina",
"tuatuas",
"tuauçus",
"tubabos",
"tubador",
"tubadão",
"tubamos",
"tubanas",
"tubanha",
"tubarei",
"tubarem",
"tubarmo",
"tubasse",
"tubaste",
"tubavam",
"tubavas",
"tubemos",
"tubilho",
"tubiqué",
"tubácea",
"tubérea",
"tucaiás",
"tucajés",
"tucanai",
"tucanam",
"tucanem",
"tucaris",
"tucujás",
"tucunaí",
"tucupás",
"tudacas",
"tuenita",
"tuenite",
"tuenito",
"tuesite",
"tufador",
"tufadão",
"tufamos",
"tufante",
"tufaram",
"tufarei",
"tufarem",
"tufares",
"tufaria",
"tufarmo",
"tufarás",
"tufasse",
"tufaste",
"tufavas",
"tufaíta",
"tufemos",
"tufnois",
"tufácia",
"tufável",
"tugidas",
"tugidor",
"tugidão",
"tugirei",
"tugiria",
"tugirás",
"tugirão",
"tugiste",
"tugíeis",
"tugônia",
"tuiaíca",
"tuietês",
"tuijuba",
"tuindás",
"tuionas",
"tuiteis",
"tuitiri",
"tuiutis",
"tuiçona",
"tujamos",
"tujanas",
"tujanos",
"tujenos",
"tujubas",
"tujucas",
"tujucos",
"tujujus",
"tujupis",
"tujuvas",
"tulinga",
"tulingo",
"tulitas",
"tulites",
"tulonês",
"tulpais",
"tulupas",
"tulípea",
"tumbamo",
"tumbará",
"tumbava",
"tumbeis",
"tumbemo",
"tumecei",
"tumecem",
"tumecer",
"tumeces",
"tumeceu",
"tumecia",
"tumefiz",
"tumesce",
"tumesci",
"tumesça",
"tumesço",
"tumeçam",
"tumeças",
"tumitas",
"tumulai",
"tumulam",
"tumulei",
"tumulem",
"tumules",
"tumurás",
"tunarei",
"tunarem",
"tunares",
"tunarmo",
"tunarás",
"tunarão",
"tunasse",
"tunaste",
"tunavam",
"tunavas",
"tundada",
"tundais",
"tundamo",
"tundava",
"tundeis",
"tundemo",
"tunemos",
"tungais",
"tungose",
"tungras",
"tungueá",
"tungões",
"tunisil",
"tunídea",
"tupamos",
"tupeias",
"tupeneá",
"tupetis",
"tupidor",
"tupimos",
"tupinaí",
"tupindo",
"tupiram",
"tupirei",
"tupirem",
"tupires",
"tupiria",
"tupirás",
"tupirão",
"tupiste",
"tupixás",
"tuputás",
"tupíeis",
"tupônia",
"turadão",
"turaias",
"turanda",
"turarei",
"turarem",
"turares",
"turaris",
"turarmo",
"turarás",
"turarão",
"turasse",
"turaste",
"turavas",
"turbamo",
"turbela",
"turbemo",
"turbita",
"turbosa",
"turbões",
"turcize",
"turcizo",
"turcizá",
"turcois",
"turdino",
"turebas",
"turejos",
"turemos",
"turfita",
"turfite",
"turfóis",
"turgiam",
"turgirá",
"turgita",
"turgite",
"turgito",
"turimua",
"turiris",
"turiúas",
"turjais",
"turmais",
"turmamo",
"turmará",
"turmava",
"turmeis",
"turmemo",
"turmeno",
"turrada",
"turrais",
"turramo",
"turrará",
"turrava",
"turreia",
"turreis",
"turremo",
"turrila",
"turrite",
"turubas",
"turubis",
"turueís",
"tururié",
"tururus",
"turusás",
"turuçus",
"turvamo",
"turveja",
"turveje",
"turvejo",
"turvemo",
"turária",
"turônia",
"turônio",
"turúlia",
"tusques",
"tussais",
"tussamo",
"tussois",
"tutadas",
"tutador",
"tutados",
"tutadão",
"tutamos",
"tutante",
"tutaram",
"tutaras",
"tutarei",
"tutarem",
"tutares",
"tutaria",
"tutarmo",
"tutarão",
"tutaste",
"tutavam",
"tutavas",
"tuteada",
"tuteais",
"tuteará",
"tuteeis",
"tuteiem",
"tuteies",
"tutejai",
"tutejam",
"tutejar",
"tutejas",
"tutejei",
"tutejem",
"tutejes",
"tutejou",
"tutelei",
"tuteles",
"tutemos",
"tutiada",
"tutiado",
"tutiais",
"tutiamo",
"tutiara",
"tutiava",
"tutieis",
"tutiemo",
"tutinos",
"tutorai",
"tutoreá",
"tutucai",
"tutucam",
"tutucou",
"tutuveá",
"tutuvie",
"tutuvio",
"tutuviá",
"tutânia",
"tuvinos",
"tuvucué",
"tuvunas",
"tuxinau",
"tzigana",
"tàpisso",
"tácaros",
"tádegas",
"tágoros",
"tálcica",
"tápiros",
"tápsias",
"táqueas",
"táquica",
"táquide",
"táquina",
"táquino",
"táquipo",
"tárfios",
"tárigue",
"társeas",
"társeos",
"tártega",
"tártego",
"tásgios",
"tátaras",
"táureos",
"táurida",
"távedas",
"távegas",
"táxicas",
"táxicos",
"táxilas",
"tâmbico",
"tâmnios",
"tânases",
"tângano",
"tânjaro",
"tçarina",
"técleas",
"tégmens",
"tégumen",
"télepos",
"térbica",
"térbico",
"térbios",
"térmodo",
"téstula",
"tétrado",
"tétraos",
"téxtrix",
"têigula",
"tíbrico",
"tíflops",
"tífulas",
"tíglica",
"tíglios",
"tígride",
"tílomis",
"tímacas",
"tímelas",
"tímeles",
"tíndalo",
"típtons",
"tíquios",
"tísanos",
"tíscias",
"tíscios",
"tísmias",
"tócaros",
"tólipes",
"tólpide",
"tóracos",
"tóretas",
"tórinos",
"tórmina",
"tóropas",
"tórtrix",
"tórulas",
"tósalis",
"tóxotas",
"tóxotes",
"tóxotis",
"tóxotos",
"tômicos",
"tômomis",
"tômoros",
"tôncica",
"tôncico",
"túbifex",
"túluvas",
"túpicas",
"túrgico",
"túricos",
"túrnias",
"túronas",
"túronos",
"túrpida",
"túrreas",
"túrrias",
"túrrios",
"tústios",
"uabaios",
"uabaías",
"uacamba",
"uacapus",
"uacaruá",
"uacarás",
"uacauãs",
"uaconás",
"uacuará",
"uacumos",
"uacumãs",
"uadadás",
"uaeiras",
"uaganda",
"uagenia",
"uagogos",
"uaiapés",
"uaibama",
"uaicués",
"uaieués",
"uaimani",
"uairari",
"uaitacá",
"uaiumás",
"uaiupés",
"uaiurus",
"uajarás",
"uajerus",
"uajurus",
"ualabás",
"ualalas",
"ualongo",
"uamamis",
"uambiza",
"uambuti",
"uananau",
"uananim",
"uanapos",
"uanauau",
"uanhais",
"uanicas",
"uanioro",
"uanjila",
"uantuia",
"uapacas",
"uapuins",
"uaracus",
"uaraicu",
"uarangá",
"uaranás",
"uarapás",
"uararas",
"uarerui",
"uariuás",
"uarixis",
"uaruará",
"uarubés",
"uarumãs",
"uasenas",
"uatapis",
"uatapus",
"uatitas",
"uatites",
"uatitos",
"uatotós",
"uaturás",
"uatúsis",
"uauaçus",
"uauiras",
"uauirus",
"uavaona",
"uaxupés",
"uaçaçus",
"ubaaçus",
"ubaetés",
"ubaibás",
"ubangos",
"ubatins",
"ubatões",
"ubaínas",
"ubeguês",
"uberado",
"uberais",
"uberamo",
"ubereis",
"uberemo",
"uberina",
"uberino",
"ubiaçus",
"ubiquai",
"ubiquei",
"ubiquou",
"ubirana",
"ubiscas",
"ubiscos",
"ubixuma",
"ubochea",
"ubojara",
"ubuesca",
"ubuçual",
"ubândgi",
"ubículo",
"ubíquem",
"ubóquea",
"ucaciás",
"ucanhas",
"uchivas",
"ucumbas",
"ucuquis",
"ucussos",
"ucuíssi",
"udamina",
"udecrás",
"udgatri",
"udiiana",
"udonela",
"udungas",
"udóteas",
"ueranas",
"uerimás",
"ufanamo",
"ufaneai",
"ufaneei",
"ufaneie",
"ufaneio",
"ufaneis",
"ufaneou",
"ufômana",
"ugadona",
"ugadora",
"ugadões",
"ugalhai",
"ugalham",
"ugalhas",
"ugalhei",
"ugalhem",
"ugalhes",
"ugalhos",
"ugalhou",
"ugantes",
"ugardes",
"ugareis",
"ugariam",
"ugarias",
"ugarmos",
"ugassem",
"ugasses",
"ugastes",
"ugerbos",
"ugerbão",
"ugernas",
"ugernos",
"uguemos",
"ugáveis",
"uiabuís",
"uiacima",
"uiaeira",
"uianari",
"uianuri",
"uigitas",
"uintato",
"uiranas",
"uiraris",
"uiriris",
"uitotas",
"uivadão",
"uivaras",
"uivarmo",
"uivaste",
"uivinho",
"ulalgia",
"ulcerei",
"ulcerem",
"ulceres",
"ulcoide",
"ulearos",
"ulembas",
"uleotas",
"ulexina",
"ulexito",
"uliense",
"ulminas",
"ulmáris",
"ulnária",
"ulocace",
"ulocola",
"uloncos",
"ulosomo",
"ultimai",
"ultoras",
"ultores",
"ultrame",
"ultramo",
"ultória",
"ultório",
"ululais",
"ululamo",
"ululato",
"ululeis",
"ululemo",
"ulvinas",
"ulvitas",
"ulváceo",
"uláctis",
"ulângia",
"ulética",
"ulético",
"ulídias",
"ulóboro",
"umaçona",
"umações",
"umbalas",
"umbarus",
"umbarés",
"umbauas",
"umbaíba",
"umbelal",
"umbelol",
"umbreta",
"umbrita",
"umbrões",
"umbuias",
"umbulas",
"umbônia",
"umbônio",
"umectai",
"umectas",
"umectes",
"umpadas",
"umpanzo",
"umãozão",
"unacopo",
"unadona",
"unadora",
"unadões",
"unantes",
"unardes",
"unaremo",
"unariam",
"unarmos",
"unassem",
"unasses",
"unastes",
"uncitos",
"uncoche",
"uncínea",
"uncíneo",
"uncíola",
"undadas",
"undadão",
"undalos",
"undamos",
"undando",
"undaram",
"undarei",
"undarem",
"undares",
"undarmo",
"undarás",
"undarão",
"undasse",
"undaste",
"undavam",
"undavas",
"undembe",
"undemos",
"undulai",
"undulam",
"undulas",
"undulei",
"undulem",
"undules",
"undulou",
"unelcos",
"ungajas",
"ungajos",
"ungaíta",
"ungaíto",
"ungiris",
"ungirmo",
"ungotes",
"unguebe",
"ungália",
"ungíeis",
"unhadão",
"unhames",
"unharei",
"unharia",
"unharmo",
"unharás",
"unharão",
"unhaste",
"unhatas",
"unhavam",
"unhavas",
"unhaços",
"unhemos",
"unhosas",
"unhosos",
"unhudos",
"unicola",
"unidona",
"unidora",
"unidões",
"uniente",
"unioras",
"unireme",
"uniremo",
"unitizo",
"unjiris",
"untadão",
"untante",
"untares",
"untarmo",
"untavas",
"untação",
"unteiro",
"untitai",
"untitam",
"untitar",
"untitas",
"untitei",
"untitem",
"untites",
"untitou",
"untoras",
"untuéns",
"untória",
"untório",
"unáreis",
"unáspis",
"unáveis",
"unçonas",
"unífora",
"uníforo",
"unígama",
"unígeno",
"uníjuga",
"uníloca",
"uníloco",
"unímamo",
"unímana",
"uníolas",
"uníveis",
"unócula",
"uolongo",
"uoraçus",
"upadona",
"upadora",
"upadões",
"upandas",
"upantes",
"upardes",
"uparemo",
"upariam",
"uparias",
"upasses",
"upastes",
"upiúbas",
"uplotes",
"upsilão",
"uptioto",
"upáreis",
"upáveis",
"upúpida",
"uquetés",
"uracais",
"uracicu",
"uracupa",
"uragogo",
"uralito",
"uralize",
"uralizo",
"uralizá",
"uralzão",
"uramaçá",
"uramila",
"uramilo",
"uranada",
"uranado",
"uranais",
"uranamo",
"uranará",
"uranava",
"uranemo",
"uraniza",
"uranize",
"uranizo",
"uranosa",
"urapeba",
"uraricu",
"urbeque",
"urdamos",
"urdidão",
"urdires",
"urdirmo",
"urdíeis",
"uredepa",
"urgidor",
"urgidão",
"urgiras",
"urgirei",
"urgires",
"urgirmo",
"urgirão",
"urgiste",
"urgíeis",
"uriates",
"urinamo",
"urineis",
"urinemo",
"urjamos",
"urningo",
"urnária",
"urofilo",
"urradão",
"urraras",
"urrarei",
"urrares",
"urrarmo",
"urraste",
"urremos",
"urrinho",
"urticai",
"urticam",
"urtigai",
"urtigam",
"urtigou",
"urtigue",
"urtique",
"urubuze",
"urubuzo",
"urvílea",
"urzedos",
"urzeiro",
"urálias",
"urálios",
"urárias",
"urética",
"urócloa",
"urócopo",
"uródeos",
"urófora",
"usadiça",
"usagens",
"uscense",
"uscufes",
"usinais",
"usinamo",
"usineis",
"usinemo",
"usitada",
"usitais",
"usitamo",
"usitará",
"usitava",
"usiteis",
"usitemo",
"usmadas",
"usmador",
"usmados",
"usmadão",
"usmamos",
"usmando",
"usmante",
"usmaram",
"usmarei",
"usmarem",
"usmares",
"usmaria",
"usmarmo",
"usmarás",
"usmarão",
"usmasse",
"usmaste",
"usmavam",
"usmavas",
"usmeira",
"usmeiro",
"usmemos",
"usnatos",
"usneais",
"usneois",
"usneína",
"usovita",
"ussilas",
"ustamos",
"ustidas",
"ustidos",
"ustimos",
"ustinas",
"ustindo",
"ustiram",
"ustirei",
"ustirem",
"ustires",
"ustiria",
"ustirmo",
"ustirás",
"ustirão",
"ustisse",
"ustiste",
"ustulai",
"ustulam",
"ustulas",
"ustulei",
"ustulem",
"ustules",
"ustulou",
"ustíeis",
"ustível",
"usuanas",
"usucapa",
"usucapo",
"usurada",
"usurais",
"usuramo",
"usurará",
"usurava",
"usureis",
"usuremo",
"usurpai",
"usâmbio",
"usípias",
"utadona",
"utadões",
"utardes",
"utaremo",
"utariam",
"utarias",
"utarmos",
"utassem",
"utasses",
"utastes",
"utaítas",
"utaítes",
"utléria",
"utopize",
"utopizo",
"utopizá",
"utpalas",
"utrária",
"utrário",
"utuabas",
"utuambé",
"utáreis",
"utáveis",
"uutenis",
"uuçango",
"uvaaias",
"uvaeira",
"uvaense",
"uvalzão",
"uvanita",
"uvaríea",
"uveiros",
"uvespim",
"uveítas",
"uvilhas",
"uvitato",
"uvárias",
"uvários",
"uvífera",
"uvítica",
"uxianas",
"uxianos",
"uxicrim",
"uximono",
"uzambas",
"uzambau",
"uzarina",
"uzélias",
"uádicas",
"uádicos",
"vaalita",
"vaalite",
"vaambos",
"vacador",
"vacados",
"vacadão",
"vacamos",
"vacanas",
"vacanos",
"vacarei",
"vacarem",
"vacarmo",
"vacarás",
"vacarão",
"vacasse",
"vacaste",
"vacavam",
"vacavas",
"vaceana",
"vaceano",
"vaceias",
"vacense",
"vacibás",
"vacisco",
"vadeada",
"vadeais",
"vadeamo",
"vadeeis",
"vadeemo",
"vadeias",
"vadeiem",
"vadeies",
"vadeosa",
"vadiais",
"vadiamo",
"vadieis",
"vadiemo",
"vadrois",
"vagadão",
"vagamão",
"vaganeá",
"vaganão",
"vagarmo",
"vageada",
"vageado",
"vageais",
"vageará",
"vageava",
"vageeis",
"vageias",
"vageiem",
"vageies",
"vageira",
"vageiro",
"vagense",
"vagidas",
"vagidor",
"vagidão",
"vagiena",
"vagieno",
"vagimos",
"vagiras",
"vagirei",
"vagirem",
"vagires",
"vagiria",
"vagirmo",
"vagirão",
"vagisse",
"vagiste",
"vagitas",
"vagites",
"vagitos",
"vagruço",
"vaguedo",
"vagueje",
"vaguejo",
"vaguemo",
"vagâmen",
"vagíeis",
"vaiadão",
"vaiarmo",
"vaiavas",
"vaiorós",
"vairona",
"vaivais",
"vaivoda",
"vajanas",
"valaate",
"valabuá",
"valabás",
"valadia",
"valapié",
"valarei",
"valarmo",
"valarás",
"valarão",
"valavam",
"valavas",
"valaíta",
"valaíte",
"valedia",
"valegos",
"valeias",
"valeíta",
"valeíte",
"valiais",
"valiamo",
"valiato",
"valiava",
"validai",
"validia",
"validol",
"valiemo",
"valilos",
"valimba",
"valinca",
"valocas",
"valoide",
"valorai",
"valosos",
"valotas",
"valsais",
"valsamo",
"valseai",
"valseei",
"valseie",
"valseje",
"valsejo",
"valsemo",
"valsois",
"valuiús",
"valuras",
"valzões",
"valâmen",
"valísia",
"vamacur",
"vamiris",
"vamonez",
"vanadol",
"vanaçus",
"vancões",
"vandada",
"vandado",
"vandais",
"vandamo",
"vandeis",
"vandemo",
"vanecei",
"vaneces",
"vanelos",
"vanesci",
"vanescê",
"vanesça",
"vanesço",
"vaneçam",
"vaneças",
"vangiãs",
"vanhais",
"vanhame",
"vanilal",
"vanilas",
"vanzeai",
"vanzear",
"vanzeei",
"vanzeia",
"vanzeie",
"vanzeio",
"vanzeou",
"vanzima",
"vanzura",
"vanzões",
"vançali",
"vaporai",
"vaporas",
"vapulai",
"vapulam",
"vapulei",
"vapulem",
"vapules",
"vapulou",
"vaqueai",
"vaqueei",
"vaqueia",
"vaqueie",
"vaqueis",
"vaqueje",
"vaquemo",
"vaquias",
"varacus",
"varagos",
"varalis",
"varames",
"varanas",
"varango",
"varapós",
"vararas",
"varares",
"vararmo",
"varasca",
"varavas",
"varbara",
"varbaro",
"vardeia",
"vardeus",
"vardiás",
"vareais",
"vareará",
"vareava",
"vareeis",
"vareiem",
"vareies",
"varejem",
"varejes",
"varezas",
"vargeta",
"variamo",
"varicha",
"variego",
"varieis",
"variemo",
"variças",
"variços",
"varotes",
"varrais",
"varramo",
"varreis",
"varuana",
"varuano",
"varudas",
"varzins",
"varênea",
"varíolo",
"vasatas",
"vascada",
"vascado",
"vascais",
"vascamo",
"vascará",
"vascava",
"vascosa",
"vaseira",
"vasenta",
"vasilho",
"vasites",
"vasiúda",
"vasiúdo",
"vasonas",
"vasquei",
"vasquem",
"vasqueá",
"vasroli",
"vassale",
"vasseia",
"vasseus",
"vastais",
"vastamo",
"vastará",
"vastava",
"vasteis",
"vastemo",
"vasélia",
"vatapis",
"vatapus",
"vatinha",
"vatismo",
"vatuate",
"vatínea",
"vatíneo",
"vauense",
"vauxita",
"vavavás",
"vavucai",
"vavucam",
"vavucar",
"vavucas",
"vavucos",
"vavucou",
"vavuque",
"vaxelos",
"vazaras",
"vazarmo",
"vazeias",
"vazeiro",
"vazeirã",
"vaziamo",
"vaziará",
"vaziava",
"vaziemo",
"veamina",
"veamino",
"veaçona",
"vecejai",
"vecejam",
"vecejas",
"vecejei",
"vecejem",
"vecejes",
"vecejos",
"vecejou",
"vectriz",
"vectões",
"vedadão",
"vedaica",
"vedaico",
"vedalha",
"vedaras",
"vedarmo",
"vedavas",
"vedoias",
"vedoide",
"vedonho",
"veeiras",
"vegados",
"vegetes",
"veiador",
"veiadão",
"veiando",
"veiante",
"veiarei",
"veiarem",
"veiares",
"veiaria",
"veiarmo",
"veiarás",
"veiarão",
"veiasse",
"veiaste",
"veiavas",
"veiemos",
"veiente",
"veigais",
"vejeira",
"vejente",
"velarmo",
"velauna",
"velavos",
"veleais",
"veleará",
"veleava",
"veleeis",
"veleiam",
"veleias",
"veleiem",
"veleies",
"velejes",
"veletes",
"velicai",
"velicam",
"velicas",
"velicou",
"velidos",
"velique",
"velisca",
"veludai",
"veludam",
"veludar",
"veludei",
"veludem",
"veludes",
"veludou",
"velutai",
"velutam",
"velutar",
"velutas",
"velutei",
"velutem",
"velutes",
"velutou",
"velézia",
"velídea",
"velídeo",
"velíida",
"velósia",
"venamas",
"vendudo",
"venelas",
"venelos",
"venenai",
"venenei",
"venenem",
"venenes",
"venense",
"veniago",
"ventamo",
"venteis",
"ventemo",
"venária",
"veragra",
"veragro",
"veranal",
"verbeje",
"verbejo",
"verbejá",
"verbeto",
"verdais",
"verdece",
"verdeei",
"verdegá",
"verdeie",
"verdeça",
"verdora",
"verduns",
"vereais",
"vereara",
"vereava",
"vereeis",
"vereiam",
"vereias",
"vereiem",
"vereies",
"verenas",
"verguia",
"verguio",
"verguna",
"verguno",
"verilos",
"verinas",
"veritos",
"verlete",
"verlite",
"verlito",
"vermens",
"vermeto",
"vermite",
"vermito",
"vermitá",
"vermuto",
"vernado",
"vernará",
"vernava",
"verneis",
"vernemo",
"vernina",
"verrino",
"versamo",
"verseis",
"versemo",
"versora",
"verstes",
"vertemo",
"verteás",
"verunas",
"verunos",
"verutos",
"verária",
"verçuda",
"vesanas",
"vesbina",
"vescina",
"vescino",
"vesgueá",
"vesicai",
"vesicam",
"vesicou",
"vesique",
"vespiça",
"vessais",
"vessamo",
"vessará",
"vessemo",
"vestada",
"vestado",
"vestamo",
"vestava",
"vesteis",
"vestemo",
"vesugos",
"vetador",
"vetadão",
"vetante",
"vetaras",
"vetarmo",
"vetaste",
"vetavas",
"vetemos",
"vetilas",
"vevuias",
"vexadão",
"vexaras",
"vexarei",
"vexares",
"vexarmo",
"vexarão",
"vexaste",
"vexavas",
"vexemos",
"vezadas",
"vezador",
"vezadão",
"vezamos",
"vezante",
"vezarei",
"vezarem",
"vezares",
"vezaria",
"vezarmo",
"vezarás",
"vezarão",
"vezasse",
"vezaste",
"vezavas",
"veêmica",
"veêmico",
"viageai",
"viageia",
"viageie",
"viageio",
"vialzão",
"viandai",
"viandei",
"viandem",
"viandou",
"vianona",
"viascas",
"viascos",
"viaçona",
"vibelas",
"vibelos",
"viberas",
"viberos",
"vibremo",
"vibutes",
"vibídea",
"vicalói",
"vicejai",
"vicejes",
"vicieis",
"viciemo",
"vicoíta",
"vicoíte",
"vicácia",
"vidador",
"vidadão",
"vidania",
"vidaram",
"vidarei",
"vidares",
"vidaria",
"vidarmo",
"vidarás",
"vidarão",
"vidaste",
"vidavas",
"vidaços",
"videado",
"videais",
"videeis",
"videias",
"videiem",
"videies",
"videmos",
"vidoiro",
"vidramo",
"vidrara",
"vidreis",
"vidremo",
"vidrina",
"vierina",
"vigadão",
"vigamos",
"vigante",
"vigares",
"vigarás",
"vigaste",
"vigavam",
"vigavas",
"vigedor",
"vigemos",
"vigenel",
"vigeras",
"vigerei",
"vigermo",
"vigeste",
"vigidos",
"vigidão",
"vigilou",
"vignita",
"vignite",
"vignito",
"vigorai",
"vigorei",
"vigueis",
"vilecos",
"vilesce",
"vilesci",
"vilesça",
"vilesço",
"viliada",
"viliado",
"viliais",
"viliamo",
"viliará",
"viliava",
"vilicai",
"vilicam",
"vilicas",
"vilicos",
"vilicou",
"vilieis",
"viliemo",
"vilique",
"vilnita",
"vilnito",
"vilocas",
"vilomas",
"vilotas",
"viltoso",
"viluíta",
"viluíte",
"vimadas",
"vimador",
"vimados",
"vimadão",
"vimamos",
"vimando",
"vimante",
"vimaram",
"vimarei",
"vimarem",
"vimares",
"vimarmo",
"vimarás",
"vimarão",
"vimasse",
"vimaste",
"vimavam",
"vimavas",
"vimosas",
"vimínea",
"vinagos",
"vinaias",
"vinatos",
"vincais",
"vincamo",
"vinhamo",
"vinhatu",
"vinhogo",
"vinhuça",
"viniaga",
"vinions",
"viocuro",
"violana",
"violebo",
"violemo",
"viomais",
"viquita",
"virarus",
"viratão",
"viravós",
"virgada",
"virgado",
"virgosa",
"virgoso",
"viridez",
"virosas",
"virtiga",
"virtigo",
"virtuam",
"virtuei",
"virtuou",
"viruçus",
"visadão",
"visaias",
"visaios",
"visarmo",
"visaste",
"visavas",
"viscado",
"viscais",
"viscamo",
"viscava",
"visceno",
"viseíta",
"visgais",
"visgará",
"visgoto",
"visguei",
"visguem",
"visgues",
"visonas",
"vispado",
"vispais",
"vispamo",
"vispará",
"vispava",
"vispeis",
"vispemo",
"vispero",
"vispore",
"visporo",
"visquei",
"visquem",
"visques",
"vissiás",
"vistemo",
"vistões",
"visácea",
"visáceo",
"visíbis",
"vitibás",
"vitimai",
"vitiriê",
"vitrito",
"vitáceo",
"vitícea",
"viuvais",
"viuvamo",
"viuveis",
"viuvemo",
"viuveza",
"vivador",
"vivadão",
"vivalhe",
"vivalho",
"vivalhá",
"vivarei",
"vivarmo",
"vivarás",
"vivarão",
"vivaste",
"vivavas",
"viventa",
"vivisca",
"vivungo",
"vivória",
"vixudas",
"vizinal",
"viçadas",
"viçador",
"viçadão",
"viçamos",
"viçante",
"viçarei",
"viçares",
"viçarmo",
"viçarás",
"viçarão",
"viçaste",
"viçavas",
"vióleas",
"vióleos",
"viólica",
"viólico",
"vldlzão",
"voadona",
"voaduru",
"voadões",
"voaremo",
"vobisco",
"vocabro",
"vocacas",
"vocates",
"vodunôs",
"voeiras",
"voejada",
"voejais",
"voejamo",
"voejeis",
"voejemo",
"vogarei",
"vogares",
"vogarás",
"vogaste",
"vogavas",
"voglita",
"voglite",
"voglito",
"vogtita",
"vogueis",
"vogélia",
"volanas",
"volanos",
"volapés",
"volateá",
"volcamo",
"volcava",
"volfros",
"volfrão",
"volitai",
"volitas",
"volitem",
"volites",
"volofos",
"volovãs",
"volquei",
"volquem",
"volques",
"volteai",
"voltije",
"voltijo",
"voltijá",
"voltize",
"voltizo",
"voltizá",
"volumai",
"volumam",
"volumei",
"volumou",
"volutai",
"volutar",
"volutei",
"volutem",
"voluteá",
"volutue",
"volutuo",
"volutuá",
"volvada",
"volúbis",
"vondeia",
"vonthur",
"vopisca",
"vopisco",
"vorozol",
"voseada",
"voseais",
"voseara",
"voseava",
"voseiam",
"voseias",
"voseiem",
"voseies",
"votadão",
"votarmo",
"votiaga",
"votiago",
"votismo",
"votiáco",
"voturos",
"voturão",
"voturãs",
"vozeais",
"vozeará",
"vozeeis",
"vozeias",
"vozeiem",
"vozeies",
"vozeire",
"vozidos",
"voícias",
"vulinas",
"vulnero",
"vulpato",
"vulscas",
"vulscos",
"vulsela",
"vultada",
"vultado",
"vultais",
"vultamo",
"vultará",
"vultava",
"vulteis",
"vultemo",
"vumbada",
"vunadas",
"vunador",
"vunados",
"vunadão",
"vunamos",
"vunando",
"vunante",
"vunaram",
"vunarei",
"vunarem",
"vunares",
"vunaria",
"vunarmo",
"vunarás",
"vunarão",
"vunasse",
"vunaste",
"vunavam",
"vunavas",
"vundéns",
"vunemos",
"vungada",
"vungado",
"vungais",
"vungará",
"vungava",
"vunguei",
"vunguem",
"vungues",
"vunjice",
"vunvuns",
"vunzada",
"vunzado",
"vunzais",
"vunzamo",
"vunzará",
"vunzava",
"vunzeis",
"vunzemo",
"vurbana",
"vurinas",
"vurmada",
"vurmado",
"vurmais",
"vurmamo",
"vurmará",
"vurmava",
"vurmeis",
"vurmemo",
"vurmosa",
"vuvucai",
"vuvucam",
"vuvucar",
"vuvucas",
"vuvucou",
"vuvuque",
"vágulas",
"vágulos",
"válgios",
"válseas",
"válseos",
"vápidos",
"várdeas",
"várvica",
"várzeos",
"vásseas",
"vásseos",
"váticas",
"váticos",
"vândeas",
"vândeos",
"vânicas",
"vânicos",
"vãosões",
"végetos",
"véneros",
"vérdica",
"vérnias",
"véscias",
"véseris",
"véspula",
"vézeras",
"vêntulo",
"víperos",
"vírbias",
"vísceos",
"víscida",
"vísmeas",
"vísmeos",
"vísmias",
"vísneas",
"víteles",
"vítices",
"vítoras",
"vítulos",
"vívulas",
"vóglias",
"vólitos",
"vóltzia",
"vólvula",
"vórmias",
"vórmios",
"vóssias",
"vôirias",
"vúlpias",
"vúlpica",
"vúlpico",
"xabebas",
"xabepas",
"xabocos",
"xaboucá",
"xacatai",
"xacatam",
"xacatar",
"xacatas",
"xacatei",
"xacatem",
"xacates",
"xacatou",
"xacoina",
"xacotos",
"xacotéu",
"xadoras",
"xadrezo",
"xaguões",
"xaimões",
"xalocos",
"xamatas",
"xamates",
"xambaré",
"xambivá",
"xangaia",
"xantada",
"xantado",
"xantais",
"xantamo",
"xantará",
"xantava",
"xanteis",
"xantemo",
"xantens",
"xantiol",
"xantipa",
"xantita",
"xantosa",
"xantoso",
"xanxões",
"xapanãs",
"xaperus",
"xaponãs",
"xaqueai",
"xaqueei",
"xaqueia",
"xaqueie",
"xaqueio",
"xaqueou",
"xaquete",
"xaquetá",
"xarafos",
"xarapas",
"xaraque",
"xardosa",
"xaropai",
"xaropam",
"xaropas",
"xaropei",
"xaropem",
"xaropeá",
"xarruas",
"xauianá",
"xavecai",
"xaxatai",
"xaxatam",
"xaxatar",
"xaxatas",
"xaxatei",
"xaxatem",
"xaxates",
"xaxatou",
"xecadas",
"xelitas",
"xenadas",
"xenador",
"xenados",
"xenadão",
"xenamos",
"xenando",
"xenante",
"xenaram",
"xenarei",
"xenarem",
"xenares",
"xenaria",
"xenarmo",
"xenarás",
"xenarão",
"xenasse",
"xenaste",
"xenavam",
"xenavas",
"xenemos",
"xequeai",
"xequear",
"xequeei",
"xequeia",
"xequeie",
"xequeio",
"xequeou",
"xerbros",
"xererés",
"xeretes",
"xereteá",
"xerocai",
"xeromas",
"xeroxai",
"xeroxam",
"xeroxas",
"xeroxei",
"xeroxem",
"xerumes",
"xetetês",
"xetrada",
"xetrado",
"xetrais",
"xetramo",
"xetrará",
"xetrava",
"xetreis",
"xetremo",
"xexeira",
"xiamens",
"xibaros",
"xibarás",
"xibatãs",
"xibebes",
"xibongo",
"xicacas",
"xigonos",
"xigubos",
"xigugos",
"xiismos",
"xiladas",
"xilados",
"xilenas",
"xilobam",
"xilomas",
"xilreus",
"xiluvas",
"xilódio",
"ximanas",
"ximbuás",
"ximocos",
"xinadas",
"xinador",
"xinados",
"xinadão",
"xinamos",
"xinanes",
"xinante",
"xinaram",
"xinaras",
"xinarei",
"xinarem",
"xinares",
"xinaria",
"xinarmo",
"xinarão",
"xinasse",
"xinaste",
"xinavam",
"xinavas",
"xinemos",
"xingais",
"xipalas",
"xipocas",
"xipocos",
"xipócue",
"xiricaá",
"xistros",
"xitacas",
"xitalas",
"xitolos",
"xitombe",
"xixicas",
"xixixis",
"xixuões",
"xocadas",
"xocadão",
"xocamos",
"xocaram",
"xocarei",
"xocarem",
"xocares",
"xocaria",
"xocarmo",
"xocarás",
"xocarão",
"xocasse",
"xocaste",
"xocavam",
"xocavas",
"xonador",
"xonamos",
"xonarei",
"xonarem",
"xonares",
"xonaria",
"xonarmo",
"xonarás",
"xonarão",
"xonasse",
"xonaste",
"xonavas",
"xonemos",
"xoqueis",
"xoquemo",
"xorocas",
"xotadas",
"xotador",
"xotados",
"xotadão",
"xotamos",
"xotando",
"xotante",
"xotaram",
"xotarei",
"xotarem",
"xotares",
"xotaria",
"xotarmo",
"xotarás",
"xotarão",
"xotasse",
"xotaste",
"xotavam",
"xotavas",
"xoxombo",
"xurdais",
"xurdamo",
"xurdiam",
"xurdias",
"xurdimo",
"xábegas",
"xácomas",
"xágaras",
"xântias",
"xântopo",
"xénicas",
"xénicos",
"xílicas",
"xílicos",
"zaadona",
"zabalés",
"zabelos",
"zabiana",
"zagaiai",
"zagaiam",
"zagaiem",
"zagaies",
"zagaiou",
"zagatés",
"zagrões",
"zainfes",
"zaipana",
"zairita",
"zalacas",
"zalumai",
"zalumam",
"zalumar",
"zalumas",
"zalumei",
"zalumem",
"zalumes",
"zalumou",
"zalzões",
"zambulá",
"zamenas",
"zamense",
"zamicro",
"zampada",
"zampado",
"zampais",
"zampamo",
"zampara",
"zampava",
"zampeis",
"zampemo",
"zamumos",
"zanagos",
"zancleu",
"zanclos",
"zangule",
"zangulo",
"zangulá",
"zanizos",
"zanoias",
"zanzais",
"zanzamo",
"zanzeis",
"zanzemo",
"zanzino",
"zapeais",
"zapeeis",
"zapeiem",
"zapeies",
"zapupes",
"zaranze",
"zaranzo",
"zarelhe",
"zarpais",
"zarpamo",
"zarpeis",
"zarpemo",
"zarucas",
"zarucos",
"zavadas",
"zavador",
"zavados",
"zavadão",
"zavamos",
"zavante",
"zavaram",
"zavarei",
"zavarem",
"zavarmo",
"zavarás",
"zavarão",
"zavasse",
"zavaste",
"zavavam",
"zavavas",
"zavemos",
"zazinta",
"zaírida",
"zebrará",
"zebreis",
"zebremo",
"zebruna",
"zebruns",
"zeimões",
"zeladão",
"zelares",
"zelarmo",
"zelosia",
"zembros",
"zenamos",
"zenepro",
"zenidas",
"zenidor",
"zenidos",
"zenidão",
"zenimos",
"zenindo",
"zeniram",
"zenirei",
"zenirem",
"zenires",
"zeniria",
"zenirmo",
"zenirás",
"zenirão",
"zenisse",
"zeniste",
"zenzéns",
"zeníeis",
"zequeis",
"zerante",
"zeraras",
"zerares",
"zerarmo",
"zeraste",
"zeravas",
"zeísmos",
"ziadona",
"ziadora",
"ziadões",
"ziantes",
"ziardes",
"ziareis",
"ziaremo",
"ziariam",
"ziarias",
"ziarmos",
"ziassem",
"ziasses",
"ziastes",
"zibetas",
"zichada",
"zichado",
"zichais",
"zichamo",
"zichara",
"zichava",
"zicheis",
"zichemo",
"zigoses",
"zimbrai",
"zimbram",
"zimbrei",
"zimbrem",
"zimoses",
"zinamos",
"zincais",
"zincamo",
"zincará",
"zincava",
"zingada",
"zingais",
"zingava",
"zingrai",
"zingram",
"zingras",
"zingrei",
"zingrem",
"zingres",
"zinguei",
"zinguem",
"zingues",
"zinidas",
"zinidos",
"zinidão",
"zinimos",
"ziniram",
"ziniras",
"zinirei",
"zinirem",
"zinires",
"ziniria",
"zinirmo",
"zinirão",
"zinisse",
"ziniste",
"zinquei",
"zinquem",
"zinques",
"ziníeis",
"zipadão",
"zipante",
"ziparei",
"ziparem",
"ziparia",
"ziparmo",
"ziparás",
"ziparão",
"zipaste",
"zipavam",
"zipavas",
"zipemos",
"zirbais",
"zirrada",
"zirrado",
"zirrais",
"zirramo",
"zirrará",
"zirrava",
"zirreis",
"zirremo",
"zizanio",
"ziziada",
"ziziado",
"ziziais",
"ziziamo",
"ziziará",
"ziziava",
"zizieis",
"ziziemo",
"ziáveis",
"zoadona",
"zoadões",
"zoantes",
"zoardes",
"zoaremo",
"zoarias",
"zoasses",
"zoastes",
"zoaçona",
"zogadas",
"zolaica",
"zolaico",
"zolesca",
"zolesco",
"zombamo",
"zombemo",
"zonador",
"zonadão",
"zonamos",
"zonante",
"zonaram",
"zonarei",
"zonarem",
"zonares",
"zonarmo",
"zonarão",
"zonasse",
"zonaste",
"zonavam",
"zonavas",
"zonchai",
"zoncham",
"zonchas",
"zonchei",
"zonchem",
"zonches",
"zonchos",
"zonchou",
"zondoca",
"zoneais",
"zoneará",
"zoneeis",
"zoneias",
"zoneiem",
"zoneies",
"zonemos",
"zongole",
"zongolo",
"zongolá",
"zonzada",
"zonzado",
"zonzais",
"zonzamo",
"zonzará",
"zonzava",
"zonzeai",
"zonzeei",
"zonzeie",
"zonzeio",
"zonzeis",
"zonzemo",
"zonzeou",
"zonzone",
"zonzono",
"zonzons",
"zonzoná",
"zoobias",
"zoonato",
"zoontes",
"zopeira",
"zopissa",
"zoratas",
"zoratos",
"zorlito",
"zornada",
"zornamo",
"zornará",
"zornava",
"zorneis",
"zornemo",
"zornãos",
"zorraga",
"zorrago",
"zorrais",
"zorrãos",
"zortada",
"zortado",
"zortais",
"zortamo",
"zortará",
"zortava",
"zorteis",
"zortemo",
"zotecas",
"zoáreis",
"zoísmos",
"zoóbios",
"zoófita",
"zoófoba",
"zoógama",
"zoógamo",
"zoógona",
"zoógono",
"zoótico",
"zuaques",
"zucadas",
"zucador",
"zucados",
"zucadão",
"zucamos",
"zucando",
"zucante",
"zucaram",
"zucarei",
"zucarem",
"zucares",
"zucaria",
"zucarmo",
"zucarás",
"zucarão",
"zucasse",
"zucaste",
"zucavam",
"zucavas",
"zuelada",
"zuelado",
"zuelais",
"zuelamo",
"zuelará",
"zuelava",
"zueleis",
"zuelemo",
"zuidona",
"zuidora",
"zuidões",
"zuirdes",
"zuireis",
"zuiriam",
"zuirias",
"zuirmos",
"zuladas",
"zulador",
"zulados",
"zuladão",
"zulamos",
"zulando",
"zulante",
"zularam",
"zularei",
"zularem",
"zulares",
"zularia",
"zularmo",
"zularás",
"zularão",
"zulasse",
"zulaste",
"zulavam",
"zulavas",
"zulemos",
"zumbaie",
"zumbaio",
"zumbais",
"zumbamo",
"zumbará",
"zumbemo",
"zumbimo",
"zumbrai",
"zumbram",
"zumbras",
"zumbrei",
"zumbres",
"zumbris",
"zumbriu",
"zumbrou",
"zunadas",
"zunador",
"zunados",
"zunadão",
"zunamos",
"zunante",
"zunaram",
"zunarei",
"zunarem",
"zunares",
"zunaria",
"zunarmo",
"zunarás",
"zunarão",
"zunasse",
"zunaste",
"zunavam",
"zunavas",
"zunemos",
"zungada",
"zungado",
"zungais",
"zungará",
"zungato",
"zungava",
"zunguei",
"zunguem",
"zungues",
"zunidão",
"zuniras",
"zunirei",
"zunires",
"zunirmo",
"zunirão",
"zuniste",
"zunzune",
"zunzuni",
"zunzuno",
"zuníeis",
"zupadas",
"zupados",
"zupadão",
"zupamos",
"zupante",
"zuparam",
"zuparei",
"zuparem",
"zupares",
"zuparia",
"zuparmo",
"zuparás",
"zuparão",
"zupasse",
"zupaste",
"zupavam",
"zupavas",
"zupemos",
"zuqueis",
"zuquemo",
"zurrais",
"zurramo",
"zurreis",
"zurremo",
"zurupai",
"zurupam",
"zurupas",
"zurupei",
"zurupem",
"zurupes",
"zurupou",
"zurzais",
"zurzamo",
"zurzias",
"zurzimo",
"zuíamos",
"zuíssem",
"zuísses",
"zuístes",
"zácoros",
"zápetes",
"zâmbios",
"zâmenis",
"zângana",
"zântios",
"zânzare",
"zécoras",
"zígnias",
"zímases",
"zímicas",
"zímicos",
"zízifos",
"ábditos",
"ábregos",
"ábsuses",
"ácladas",
"áclados",
"áclases",
"ácopras",
"ácopros",
"ácragas",
"ácridas",
"ácrodes",
"ácrodos",
"ácronas",
"ácronos",
"áfricos",
"áftonos",
"áglifos",
"ágrions",
"ágrotes",
"álbicas",
"álbicos",
"álbidas",
"álbidos",
"álbulas",
"álbulos",
"álcions",
"álpicas",
"álpicos",
"álsinas",
"álsines",
"álticas",
"ápetros",
"áplidos",
"ápticos",
"árctoas",
"árculas",
"árgemas",
"árgiras",
"árgiros",
"árgulos",
"ármicas",
"áscelas",
"ásceles",
"áscelos",
"ásciros",
"ásporas",
"ásporos",
"ássonos",
"ástacos",
"ástases",
"ástatas",
"ástates",
"ástomas",
"ástreas",
"ástreos",
"átricos",
"átrocas",
"átrofas",
"átrofos",
"átropis",
"áugnata",
"áugnato",
"áulacas",
"áulaces",
"áulacos",
"ânfidas",
"ântemis",
"écbases",
"écbolas",
"écdicas",
"écdicos",
"écforas",
"éctases",
"écteses",
"éctipos",
"égagros",
"élafros",
"épacres",
"épsilos",
"équenes",
"érbicas",
"érbicos",
"érdimos",
"érgatas",
"érgatos",
"érgolis",
"érvodos",
"éssedos",
"étroplo",
"êmbolon",
"êmitron",
"êmpidas",
"êmpises",
"ênderos",
"ênfobos",
"ênuplos",
"êucares",
"êuceras",
"êuceros",
"êucomes",
"êuglifa",
"êulofos",
"êumenes",
"êumenos",
"êupelix",
"êurinas",
"êurinos",
"êuscelo",
"êuspora",
"êutocas",
"êutocos",
"êutrica",
"êxulzão",
"ícteros",
"ínculos",
"índiuns",
"íngreda",
"íngredo",
"íngrias",
"ínscias",
"ínsonas",
"ínsonos",
"ínsulos",
"ínvidas",
"íssidas",
"ístmias",
"ístrica",
"ístrico",
"órfonas",
"ósmicos",
"óssames",
"úlmicas",
"úlmicos",
"únhulas",
"úniceps",
"úrgicas",
"úrgicos",
"úrnulas",
"úsnicas",
]