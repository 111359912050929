export const ACENTOS = {
  aaboras: 'aaboras',
  aalclim: 'aalclim',
  aalenio: 'aalênio',
  aalense: 'aalense',
  aariana: 'aariana',
  aariano: 'aariano',
  ababada: 'ababada',
  ababade: 'ababade',
  ababado: 'ababado',
  ababaia: 'ababaia',
  ababela: 'ababela',
  ababele: 'ababele',
  ababelo: 'ababelo',
  ababone: 'ababone',
  ababoni: 'ababoni',
  ababosa: 'ababosa',
  ababose: 'ababose',
  ababoso: 'ababoso',
  ababroa: 'ababroá',
  ababroe: 'ababroe',
  ababroo: 'ababroo',
  ababuas: 'ababuás',
  ababuis: 'ababuís',
  abacada: 'abacada',
  abacado: 'abacado',
  abacais: 'abacaís',
  abacana: 'abaçana',
  abacane: 'abaçane',
  abacano: 'abaçano',
  abacaro: 'abacaro',
  abacate: 'abacate',
  abacaxi: 'abacaxi',
  abacela: 'abacela',
  abacele: 'abacele',
  abacelo: 'abacelo',
  abacena: 'abacena',
  abaceto: 'abaceto',
  abaciai: 'abaciai',
  abacial: 'abacial',
  abaciam: 'abaciam',
  abaciar: 'abaciar',
  abacias: 'abacias',
  abacida: 'abácida',
  abaciei: 'abaciei',
  abaciem: 'abaciem',
  abacies: 'abacies',
  abacina: 'abacina',
  abacine: 'abacine',
  abacino: 'abacino',
  abaciou: 'abaciou',
  abacote: 'abacote',
  abactos: 'abactos',
  abacula: 'abácula',
  abaculo: 'abáculo',
  abaculu: 'abaculu',
  abadada: 'abadada',
  abadado: 'abadado',
  abadais: 'abadais',
  abadala: 'abadala',
  abadale: 'abadale',
  abadalo: 'abadalo',
  abadamo: 'abadamo',
  abadana: 'abadaná',
  abadane: 'abadane',
  abadano: 'abadano',
  abadara: 'abadará',
  abadava: 'abadava',
  abadeis: 'abadeis',
  abadeja: 'abadeja',
  abadejo: 'abadejo',
  abademo: 'abademo',
  abadiai: 'abadiai',
  abadiam: 'abadiam',
  abadiar: 'abadiar',
  abadias: 'abadias',
  abadico: 'abadico',
  abadida: 'abádida',
  abadiei: 'abadiei',
  abadiem: 'abadiem',
  abadies: 'abadies',
  abadins: 'abadins',
  abadiou: 'abadiou',
  abadita: 'abadita',
  abadiva: 'abadiva',
  abadoes: 'abadões',
  abadona: 'abadona',
  abadons: 'abadons',
  abadora: 'abadora',
  abaetai: 'abaetai',
  abaetam: 'abaetam',
  abaetar: 'abaetar',
  abaetas: 'abaetas',
  abaetei: 'abaetei',
  abaetem: 'abaetem',
  abaetes: 'abaetes',
  abaetou: 'abaetou',
  abafada: 'abafada',
  abafado: 'abafado',
  abafais: 'abafais',
  abafamo: 'abafamo',
  abafara: 'abafara',
  abafava: 'abafava',
  abafeis: 'abafeis',
  abafemo: 'abafemo',
  abafura: 'abafura',
  abagaca: 'abagaçá',
  abagace: 'abagace',
  abagaco: 'abagaço',
  abagada: 'abagada',
  abagado: 'abagado',
  abagais: 'abagais',
  abagara: 'abagará',
  abagava: 'abagava',
  abagoai: 'abagoai',
  abagoam: 'abagoam',
  abagoar: 'abagoar',
  abagoas: 'abagoas',
  abagoei: 'abagoei',
  abagoem: 'abagoem',
  abagoes: 'abagoes',
  abagoou: 'abagoou',
  abaguei: 'abaguei',
  abaguem: 'abaguem',
  abagues: 'abagues',
  abaguim: 'abaguim',
  abaguns: 'abaguns',
  abaiana: 'abaiana',
  abaiane: 'abaiane',
  abaiano: 'abaiano',
  abaibas: 'abaíbas',
  abainha: 'abainha',
  abainhe: 'abainhe',
  abainho: 'abainho',
  abairra: 'abairra',
  abairre: 'abairre',
  abairro: 'abairro',
  abaisir: 'abaisir',
  abaites: 'abaités',
  abaiuca: 'abaiúca',
  abaiuco: 'abaiúco',
  abaixai: 'abaixai',
  abaixam: 'abaixam',
  abaixar: 'abaixar',
  abaixas: 'abaixas',
  abaixei: 'abaixei',
  abaixem: 'abaixem',
  abaixes: 'abaixes',
  abaixou: 'abaixou',
  abajeru: 'abajeru',
  abalada: 'abalada',
  abalado: 'abalado',
  abalaia: 'abalaia',
  abalaie: 'abalaie',
  abalaio: 'abalaio',
  abalais: 'abalais',
  abalamo: 'abalamo',
  abalara: 'abalara',
  abalaus: 'abalaús',
  abalava: 'abalava',
  abalcai: 'abalçai',
  abalcam: 'abalçam',
  abalcar: 'abalçar',
  abalcas: 'abalças',
  abalcei: 'abalcei',
  abalcem: 'abalcem',
  abalces: 'abalces',
  abalcou: 'abalçou',
  abaldai: 'abaldai',
  abaldam: 'abaldam',
  abaldar: 'abaldar',
  abaldas: 'abaldas',
  abaldea: 'abaldeá',
  abaldei: 'abaldei',
  abaldem: 'abaldem',
  abaldes: 'abaldes',
  abaldoa: 'abaldoa',
  abaldoe: 'abaldoe',
  abaldoo: 'abaldoo',
  abaldou: 'abaldou',
  abaleis: 'abaleis',
  abalemo: 'abalemo',
  abaliza: 'abaliza',
  abalize: 'abalize',
  abalizo: 'abalizo',
  abaloai: 'abaloai',
  abaloam: 'abaloam',
  abaloar: 'abaloar',
  abaloas: 'abaloas',
  abaloei: 'abaloei',
  abaloem: 'abaloem',
  abaloes: 'abaloes',
  abalofa: 'abalofa',
  abalofe: 'abalofe',
  abalofo: 'abalofo',
  abalona: 'abalona',
  abalone: 'abalone',
  abalono: 'abalono',
  abaloou: 'abaloou',
  abalosa: 'abalosa',
  abaloso: 'abaloso',
  abalroa: 'abalroa',
  abalroe: 'abalroe',
  abalroo: 'abalroo',
  abalsai: 'abalsai',
  abalsam: 'abalsam',
  abalsar: 'abalsar',
  abalsas: 'abalsas',
  abalsei: 'abalsei',
  abalsem: 'abalsem',
  abalses: 'abalses',
  abalsou: 'abalsou',
  abaluae: 'abaluaê',
  abaluma: 'abalumá',
  abalume: 'abalume',
  abalumo: 'abalumo',
  abalzao: 'abalzão',
  abambai: 'abambai',
  abambam: 'abambam',
  abambar: 'abambar',
  abambas: 'abambas',
  abambei: 'abambei',
  abambem: 'abambem',
  abambes: 'abambes',
  abambou: 'abambou',
  abameas: 'abâmeas',
  abamita: 'abâmita',
  abanada: 'abanada',
  abanado: 'abanado',
  abanais: 'abanais',
  abanamo: 'abanamo',
  abanana: 'abanana',
  abanane: 'abanane',
  abanano: 'abanano',
  abanara: 'abanara',
  abanava: 'abanava',
  abancai: 'abancai',
  abancam: 'abancam',
  abancar: 'abancar',
  abancas: 'abancas',
  abancou: 'abancou',
  abandai: 'abandai',
  abandam: 'abandam',
  abandar: 'abandar',
  abandas: 'abandas',
  abandea: 'abandeá',
  abandei: 'abandei',
  abandem: 'abandem',
  abandes: 'abandes',
  abandoa: 'abandoa',
  abandoe: 'abandoe',
  abandoo: 'abandoo',
  abandou: 'abandou',
  abaneem: 'abaneém',
  abaneia: 'abaneia',
  abaneis: 'abaneis',
  abanemo: 'abanemo',
  abanete: 'abanete',
  abaneus: 'abaneus',
  abangas: 'abangas',
  abangos: 'abangos',
  abangue: 'abangue',
  abanhai: 'abanhai',
  abanham: 'abanham',
  abanhar: 'abanhar',
  abanhas: 'abanhas',
  abanhei: 'abanhei',
  abanhem: 'abanhem',
  abanhes: 'abanhes',
  abanhou: 'abanhou',
  abanica: 'abanica',
  abanico: 'abanico',
  abanito: 'abanito',
  abanoes: 'abanões',
  abanque: 'abanque',
  abantas: 'abantas',
  abantes: 'abantes',
  abanteu: 'abanteu',
  abantos: 'abantos',
  abaorta: 'abaorta',
  abapani: 'abapani',
  abaporu: 'abaporu',
  abaquia: 'abaquiá',
  abaquie: 'abaquie',
  abaquio: 'abaquio',
  abarata: 'abarata',
  abarate: 'abarate',
  abarato: 'abarato',
  abarbai: 'abarbai',
  abarbam: 'abarbam',
  abarbar: 'abarbar',
  abarbas: 'abarbas',
  abarbea: 'abarbeá',
  abarbei: 'abarbei',
  abarbem: 'abarbem',
  abarbes: 'abarbes',
  abarbou: 'abarbou',
  abarcai: 'abarcai',
  abarcam: 'abarcam',
  abarcar: 'abarcar',
  abarcas: 'abarcas',
  abarcia: 'abarcia',
  abarcou: 'abarcou',
  abardai: 'abardai',
  abardam: 'abardam',
  abardar: 'abardar',
  abardas: 'abardas',
  abardei: 'abardei',
  abardem: 'abardem',
  abardes: 'abardes',
  abardou: 'abardou',
  abareis: 'abáreis',
  abaremo: 'abaremo',
  abareno: 'abareno',
  abargas: 'abargas',
  abariam: 'abariam',
  abarias: 'abarias',
  abariga: 'abariga',
  abarina: 'abarina',
  abarino: 'abarino',
  abarmao: 'abarmão',
  abarmos: 'abarmos',
  abarona: 'abaroná',
  abarone: 'abarone',
  abarono: 'abarono',
  abarque: 'abarque',
  abarraz: 'abarraz',
  abarroa: 'abarroa',
  abarroe: 'abarroe',
  abarroo: 'abarroo',
  abaruna: 'abaruna',
  abasgos: 'abasgos',
  abasias: 'abasias',
  abasica: 'abásica',
  abasico: 'abásico',
  abasina: 'abasina',
  abasine: 'abasine',
  abasino: 'abasino',
  abasmai: 'abasmai',
  abasmam: 'abasmam',
  abasmar: 'abasmar',
  abasmas: 'abasmas',
  abasmei: 'abasmei',
  abasmem: 'abasmem',
  abasmes: 'abasmes',
  abasmou: 'abasmou',
  abasoes: 'abasões',
  abassem: 'abassem',
  abasses: 'abasses',
  abassia: 'abássia',
  abassis: 'abassis',
  abassor: 'abassor',
  abastai: 'abastai',
  abastam: 'abastam',
  abastar: 'abastar',
  abastas: 'abastas',
  abastei: 'abastei',
  abastem: 'abastem',
  abaster: 'abáster',
  abastes: 'abastes',
  abastor: 'abastor',
  abastou: 'abastou',
  abatais: 'abatais',
  abatamo: 'abatamo',
  abatata: 'abatata',
  abatate: 'abatate',
  abatato: 'abatato',
  abateis: 'abateis',
  abatela: 'abatela',
  abatele: 'abatele',
  abatelo: 'abatelo',
  abatemo: 'abatemo',
  abatera: 'abatera',
  abatiam: 'abatiam',
  abatias: 'abatias',
  abatida: 'abatida',
  abatido: 'abatido',
  abatina: 'abatina',
  abatine: 'abatine',
  abatini: 'abatini',
  abatino: 'abatino',
  abatipo: 'abatipó',
  abatira: 'abatirá',
  abatiui: 'abatiuí',
  abativi: 'abativi',
  abatixi: 'abatixi',
  abatoca: 'abatoca',
  abatoco: 'abatoco',
  abatoes: 'abatões',
  abatuma: 'abatuma',
  abatume: 'abatume',
  abatumo: 'abatumo',
  abaulai: 'abaulai',
  abaulam: 'abaúlam',
  abaular: 'abaular',
  abaulas: 'abaúlas',
  abaulei: 'abaulei',
  abaulem: 'abaúlem',
  abaules: 'abaules',
  abaulou: 'abaulou',
  abaunas: 'abaúnas',
  abaveis: 'abáveis',
  abavias: 'abávias',
  abavios: 'abávios',
  abaxial: 'abaxial',
  abbadia: 'abbadia',
  abcaris: 'abcáris',
  abcasio: 'abcásio',
  abcazia: 'abcázia',
  abcazio: 'abcázio',
  abcedai: 'abcedai',
  abcedam: 'abcedam',
  abcedar: 'abcedar',
  abcedas: 'abcedas',
  abcedei: 'abcedei',
  abcedem: 'abcedem',
  abceder: 'abceder',
  abcedes: 'abcedes',
  abcedeu: 'abcedeu',
  abcedia: 'abcedia',
  abcedou: 'abcedou',
  abcessa: 'abcessa',
  abcesso: 'abcesso',
  abcidai: 'abcidai',
  abcidam: 'abcidam',
  abcidar: 'abcidar',
  abcidas: 'abcidas',
  abcidei: 'abcidei',
  abcidem: 'abcidem',
  abcides: 'abcides',
  abcidou: 'abcidou',
  abcisao: 'abcisão',
  abcissa: 'abcissa',
  abdalao: 'abdalão',
  abdalas: 'abdalas',
  abdalis: 'abdalis',
  abdares: 'abdares',
  abderas: 'abderas',
  abderos: 'abderos',
  abdesta: 'abdesta',
  abdeste: 'abdeste',
  abdesto: 'abdesto',
  abdicai: 'abdicai',
  abdicam: 'abdicam',
  abdicar: 'abdicar',
  abdicas: 'abdicas',
  abdicou: 'abdicou',
  abdimia: 'abdímia',
  abdique: 'abdique',
  abditas: 'abditas',
  abditos: 'ábditos',
  abdomen: 'abdômen',
  abdomes: 'abdomes',
  abducao: 'abdução',
  abdudes: 'abdudes',
  abdutor: 'abdutor',
  abdutos: 'abdutos',
  abduzam: 'abduzam',
  abduzas: 'abduzas',
  abduzem: 'abduzem',
  abduzes: 'abduzes',
  abduzia: 'abduzia',
  abduzir: 'abduzir',
  abduzis: 'abduzis',
  abduziu: 'abduziu',
  abeatai: 'abeatai',
  abeatam: 'abeatam',
  abeatar: 'abeatar',
  abeatas: 'abeatas',
  abeatei: 'abeatei',
  abeatem: 'abeatem',
  abeates: 'abeates',
  abeatou: 'abeatou',
  abebera: 'abêbera',
  abebere: 'abebere',
  abebero: 'abebero',
  abebras: 'abebras',
  abecada: 'abecada',
  abecado: 'abecado',
  abecais: 'abecais',
  abecamo: 'abecamo',
  abecara: 'abecará',
  abecava: 'abecava',
  abeceda: 'abeceda',
  abecede: 'abecedê',
  abecedo: 'abecedo',
  abeceis: 'abeceis',
  abecemo: 'abecemo',
  abecera: 'abecerá',
  abeciam: 'abeciam',
  abecias: 'abecias',
  abecida: 'abecida',
  abecido: 'abecido',
  abecula: 'abécula',
  abedais: 'abedais',
  abedale: 'abedale',
  abegoas: 'abegoas',
  abegoes: 'abegões',
  abeicai: 'abeiçai',
  abeicam: 'abeiçam',
  abeicar: 'abeiçar',
  abeicas: 'abeiças',
  abeicei: 'abeicei',
  abeicem: 'abeicem',
  abeices: 'abeices',
  abeicou: 'abeiçou',
  abeirai: 'abeirai',
  abeiram: 'abeiram',
  abeirao: 'abeirão',
  abeirar: 'abeirar',
  abeiras: 'abeiras',
  abeirei: 'abeirei',
  abeirem: 'abeirem',
  abeires: 'abeires',
  abeirou: 'abeirou',
  abelana: 'abelana',
  abelano: 'abelano',
  abelere: 'abelere',
  abelgai: 'abelgai',
  abelgam: 'abelgam',
  abelgar: 'abelgar',
  abelgas: 'abelgas',
  abelgou: 'abelgou',
  abelgue: 'abelgue',
  abelhai: 'abelhai',
  abelhal: 'abelhal',
  abelham: 'abelham',
  abelhao: 'abelhão',
  abelhar: 'abelhar',
  abelhas: 'abelhas',
  abelhei: 'abelhei',
  abelhem: 'abelhem',
  abelhes: 'abelhes',
  abelhoa: 'abelhoa',
  abelhoe: 'abelhoe',
  abelhoo: 'abelhoo',
  abelhou: 'abelhou',
  abelhua: 'abelhuá',
  abelhue: 'abelhue',
  abelhuo: 'abelhuo',
  abelida: 'abelidá',
  abelide: 'abelide',
  abelido: 'abelido',
  abelina: 'abelina',
  abelino: 'abelino',
  abelins: 'abelins',
  abelita: 'abelita',
  abelite: 'abelite',
  abelota: 'abelota',
  abemola: 'abemola',
  abemole: 'abemole',
  abemolo: 'abemolo',
  abencao: 'abênção',
  abencoa: 'abençoa',
  abencoe: 'abençoe',
  abencoo: 'abençoo',
  abeonas: 'abeonas',
  abeonos: 'abeonos',
  abequei: 'abequei',
  abequem: 'abequem',
  abeques: 'abeques',
  aberdim: 'aberdim',
  aberema: 'aberema',
  aberemo: 'aberemo',
  aberido: 'aberido',
  aberita: 'aberita',
  aberrai: 'aberrai',
  aberram: 'aberram',
  aberrar: 'aberrar',
  aberras: 'aberras',
  aberrei: 'aberrei',
  aberrem: 'aberrem',
  aberres: 'aberres',
  aberrou: 'aberrou',
  abertal: 'abertal',
  abertao: 'abertão',
  abertas: 'abertas',
  abertos: 'abertos',
  abesana: 'abesana',
  abesina: 'abesina',
  abesino: 'abesino',
  abesira: 'abesira',
  abespao: 'abespão',
  abespas: 'abespas',
  abessim: 'abessim',
  abestai: 'abestai',
  abestam: 'abestam',
  abestar: 'abestar',
  abestas: 'abestas',
  abestei: 'abestei',
  abestem: 'abestem',
  abestes: 'abestes',
  abestia: 'abestia',
  abestie: 'abestie',
  abestim: 'abestim',
  abestio: 'abestio',
  abestos: 'abestos',
  abestou: 'abestou',
  abetais: 'abetais',
  abetica: 'abética',
  abetico: 'abético',
  abetimo: 'abétimo',
  abetina: 'abetina',
  abetino: 'abetino',
  abetuma: 'abetuma',
  abetume: 'abetume',
  abetumo: 'abetumo',
  abetuns: 'abetuns',
  abevera: 'abeverá',
  abevere: 'abevere',
  abevero: 'abevero',
  abexeda: 'abexeda',
  abexedo: 'abexedo',
  abexiga: 'abexiga',
  abexigo: 'abexigo',
  abexins: 'abexins',
  abfarad: 'abfarad',
  abgrega: 'abgregá',
  abgrego: 'abgrego',
  abiabas: 'abiabas',
  abianas: 'abianas',
  abianos: 'abianos',
  abibura: 'abibura',
  abicada: 'abicada',
  abicado: 'abicado',
  abicais: 'abicais',
  abicamo: 'abicamo',
  abicara: 'abicará',
  abicava: 'abicava',
  abichai: 'abichai',
  abicham: 'abicham',
  abichar: 'abichar',
  abichas: 'abichas',
  abichei: 'abichei',
  abichem: 'abichem',
  abiches: 'abiches',
  abichou: 'abichou',
  abicora: 'abicora',
  abicore: 'abicore',
  abicoro: 'abicoro',
  abideno: 'abideno',
  abiegna: 'abiegna',
  abiegno: 'abiegno',
  abieira: 'abieira',
  abieiro: 'abieiro',
  abienos: 'abienos',
  abietol: 'abietol',
  abietos: 'abietos',
  abifada: 'abifada',
  abifado: 'abifado',
  abifais: 'abifais',
  abifamo: 'abifamo',
  abifara: 'abifará',
  abifava: 'abifava',
  abifeis: 'abifeis',
  abifemo: 'abifemo',
  abigeai: 'abigeai',
  abigear: 'abigear',
  abigeas: 'abígeas',
  abigeei: 'abigeei',
  abigeia: 'abigeia',
  abigeie: 'abigeie',
  abigeio: 'abigeio',
  abigeos: 'abígeos',
  abigeou: 'abigeou',
  abigoda: 'abigodá',
  abigode: 'abigode',
  abigodo: 'abigodo',
  abiibas: 'abiíbas',
  abijira: 'abijira',
  abileno: 'abileno',
  abilhai: 'abilhai',
  abilham: 'abilham',
  abilhar: 'abilhar',
  abilhas: 'abilhas',
  abilhei: 'abilhei',
  abilhem: 'abilhem',
  abilhes: 'abilhes',
  abilhou: 'abilhou',
  abilola: 'abilola',
  abilole: 'abilole',
  abilolo: 'abilolo',
  abinhai: 'abinhai',
  abinham: 'abinham',
  abinhar: 'abinhar',
  abinhas: 'abinhas',
  abinhei: 'abinhei',
  abinhem: 'abinhem',
  abinhes: 'abinhes',
  abinhou: 'abinhou',
  abiomba: 'abiombá',
  abiombe: 'abiombe',
  abiombo: 'abiombo',
  abioris: 'abioris',
  abioses: 'abioses',
  abiotos: 'abiotos',
  abipoes: 'abipões',
  abipona: 'abipona',
  abipone: 'abipone',
  abiquei: 'abiquei',
  abiquem: 'abiquem',
  abiques: 'abiques',
  abiraco: 'abiraco',
  abiscas: 'abiscas',
  abisela: 'abiselá',
  abisele: 'abisele',
  abiselo: 'abiselo',
  abisgas: 'abisgas',
  abismai: 'abismai',
  abismal: 'abismal',
  abismam: 'abismam',
  abismar: 'abismar',
  abismas: 'abismas',
  abismei: 'abismei',
  abismem: 'abismem',
  abismes: 'abismes',
  abismos: 'abismos',
  abismou: 'abismou',
  abispai: 'abispai',
  abispam: 'abispam',
  abispar: 'abispar',
  abispas: 'abispas',
  abispei: 'abispei',
  abispem: 'abispem',
  abispes: 'abispes',
  abispou: 'abispou',
  abissai: 'abissai',
  abissal: 'abissal',
  abissam: 'abissam',
  abissar: 'abissar',
  abissas: 'abissas',
  abissei: 'abissei',
  abissem: 'abissem',
  abisses: 'abisses',
  abissim: 'abissim',
  abissos: 'abissos',
  abissou: 'abissou',
  abistas: 'abistas',
  abitada: 'abitada',
  abitado: 'abitado',
  abitaga: 'abitaga',
  abitais: 'abitais',
  abitamo: 'abitamo',
  abitana: 'abitana',
  abitara: 'abitara',
  abitava: 'abitava',
  abiteis: 'abiteis',
  abitemo: 'abitemo',
  abitica: 'abitica',
  abitiga: 'abitiga',
  abitoes: 'abitões',
  abitola: 'abitola',
  abitole: 'abitole',
  abitolo: 'abitolo',
  abituma: 'abituma',
  abitume: 'abitume',
  abitumo: 'abitumo',
  abiudas: 'abiúdas',
  abiudos: 'abiúdos',
  abivaca: 'abivacá',
  abivaco: 'abivaco',
  abjecao: 'abjeção',
  abjecto: 'abjecto',
  abjetas: 'abjetas',
  abjetos: 'abjetos',
  abjugai: 'abjugai',
  abjugam: 'abjugam',
  abjugar: 'abjugar',
  abjugas: 'abjugas',
  abjugou: 'abjugou',
  abjugue: 'abjugue',
  abjunge: 'abjunge',
  abjungi: 'abjungi',
  abjunja: 'abjunja',
  abjunjo: 'abjunjo',
  abjunta: 'abjunta',
  abjunto: 'abjunto',
  abjurai: 'abjurai',
  abjuram: 'abjuram',
  abjurar: 'abjurar',
  abjuras: 'abjuras',
  abjurei: 'abjurei',
  abjurem: 'abjurem',
  abjures: 'abjures',
  abjurga: 'abjurga',
  abjurgo: 'abjurgo',
  abjurou: 'abjurou',
  ablabes: 'ablabes',
  ablacao: 'ablação',
  ablacas: 'ablacas',
  ablacta: 'ablacta',
  ablacte: 'ablacte',
  ablages: 'ablages',
  ablanda: 'ablanda',
  ablande: 'ablande',
  ablando: 'ablando',
  ablania: 'ablânia',
  ablaqua: 'abláqua',
  ablator: 'ablator',
  ablecte: 'ablecte',
  ablecto: 'ablecto',
  ablegai: 'ablegai',
  ablegam: 'ablegam',
  ablegar: 'ablegar',
  ablegas: 'ablegas',
  ablegou: 'ablegou',
  ablegue: 'ablegue',
  ableias: 'ableias',
  ableita: 'ableitá',
  ableite: 'ableite',
  ableito: 'ableito',
  ablenas: 'ablenas',
  ablenos: 'ablenos',
  ablocai: 'ablocai',
  ablocam: 'ablocam',
  ablocar: 'ablocar',
  ablocas: 'ablocas',
  ablocou: 'ablocou',
  abloque: 'abloque',
  abluais: 'abluais',
  abluamo: 'abluamo',
  ablucao: 'ablução',
  abluiam: 'abluíam',
  abluias: 'abluías',
  abluida: 'abluída',
  abluido: 'abluído',
  abluimo: 'abluímo',
  abluira: 'abluíra',
  ablutor: 'ablutor',
  abmigra: 'abmigrá',
  abmigre: 'abmigre',
  abmigro: 'abmigro',
  abnadai: 'abnadai',
  abnadam: 'abnadam',
  abnadar: 'abnadar',
  abnadas: 'abnadas',
  abnadei: 'abnadei',
  abnadem: 'abnadem',
  abnades: 'abnades',
  abnadou: 'abnadou',
  abnegai: 'abnegai',
  abnegam: 'abnegam',
  abnegar: 'abnegar',
  abnegas: 'abnegas',
  abnegou: 'abnegou',
  abnegue: 'abnegue',
  abnetos: 'abnetos',
  abnodai: 'abnodai',
  abnodam: 'abnodam',
  abnodar: 'abnodar',
  abnodas: 'abnodas',
  abnodei: 'abnodei',
  abnodem: 'abnodem',
  abnodes: 'abnodes',
  abnodou: 'abnodou',
  abnoita: 'abnoitá',
  abnoite: 'abnoite',
  abnoito: 'abnoito',
  abnorme: 'abnorme',
  abnoxia: 'abnóxia',
  abnoxio: 'abnóxio',
  abnuais: 'abnuais',
  abnuiam: 'abnuíam',
  abnuias: 'abnuías',
  abnuida: 'abnuída',
  abnuido: 'abnuído',
  abnuira: 'abnuíra',
  aboadao: 'aboadão',
  aboadas: 'aboadas',
  aboador: 'aboador',
  aboados: 'aboados',
  aboamos: 'aboamos',
  aboando: 'aboando',
  aboante: 'aboante',
  aboaram: 'aboaram',
  aboarao: 'aboarão',
  aboaras: 'aboaras',
  aboarei: 'aboarei',
  aboarem: 'aboarem',
  aboares: 'aboares',
  aboaria: 'aboaria',
  aboarmo: 'aboarmo',
  aboasse: 'aboasse',
  aboaste: 'aboaste',
  aboavam: 'aboavam',
  aboavas: 'aboavas',
  abobada: 'abóbada',
  abobade: 'abobade',
  abobado: 'abobado',
  abobais: 'abobais',
  abobamo: 'abobamo',
  abobara: 'abobara',
  abobava: 'abobava',
  abobeda: 'abóbeda',
  abobeis: 'abobeis',
  abobemo: 'abobemo',
  aboboda: 'abóboda',
  abobora: 'abóbora',
  abobore: 'abobore',
  aboboro: 'aboboro',
  abobras: 'abobras',
  abocada: 'abocada',
  abocade: 'abocade',
  abocado: 'abocado',
  abocais: 'abocais',
  abocamo: 'abocamo',
  abocara: 'abocara',
  abocava: 'abocava',
  aboceis: 'aboceis',
  abocela: 'abocelá',
  abocele: 'abocele',
  abocelo: 'abocelo',
  abocemo: 'abocemo',
  aboceta: 'aboceta',
  abocete: 'abocete',
  aboceto: 'aboceto',
  abocina: 'abociná',
  abocine: 'abocine',
  abocino: 'abocino',
  abococo: 'abocoçô',
  abodega: 'abodega',
  abodego: 'abodego',
  abodoca: 'abodocá',
  abodoco: 'abodoco',
  aboemia: 'aboemia',
  aboemie: 'aboemie',
  aboemio: 'aboemio',
  aboemos: 'aboemos',
  abofeta: 'abofetá',
  abofete: 'abofete',
  abofeto: 'abofeto',
  abogoes: 'abogões',
  aboiada: 'aboiada',
  aboiado: 'aboiado',
  aboiais: 'aboiais',
  aboiara: 'aboiara',
  aboiava: 'aboiava',
  aboieis: 'aboieis',
  aboinha: 'aboinha',
  aboizes: 'aboízes',
  abojada: 'abojada',
  abojado: 'abojado',
  abojais: 'abojais',
  abojamo: 'abojamo',
  abojara: 'abojará',
  abojava: 'abojava',
  abojeis: 'abojeis',
  abojemo: 'abojemo',
  abolada: 'abolada',
  abolado: 'abolado',
  abolais: 'abolais',
  abolamo: 'abolamo',
  abolana: 'abolana',
  abolano: 'abolano',
  abolara: 'abolara',
  abolava: 'abolava',
  abolaza: 'abolaza',
  abolcai: 'abolçai',
  abolcam: 'abolçam',
  abolcar: 'abolçar',
  abolcas: 'abolças',
  abolcei: 'abolcei',
  abolcem: 'abolcem',
  abolces: 'abolces',
  abolcou: 'abolçou',
  aboleai: 'aboleai',
  abolear: 'abolear',
  aboleei: 'aboleei',
  aboleia: 'aboleia',
  aboleie: 'aboleie',
  aboleio: 'aboleio',
  aboleis: 'aboleis',
  abolemo: 'abolemo',
  aboleou: 'aboleou',
  aboleta: 'aboleta',
  abolete: 'abolete',
  aboleto: 'aboleto',
  abolhai: 'abolhai',
  abolham: 'abolham',
  abolhar: 'abolhar',
  abolhas: 'abolhas',
  abolhei: 'abolhei',
  abolhem: 'abolhem',
  abolhes: 'abolhes',
  abolhou: 'abolhou',
  aboliam: 'aboliam',
  abolias: 'abolias',
  abolida: 'abolida',
  abolido: 'abolido',
  abolimo: 'abolimo',
  abolina: 'abolina',
  aboline: 'aboline',
  abolino: 'abolino',
  abolins: 'abolins',
  abolira: 'abolira',
  abolsai: 'abolsai',
  abolsam: 'abolsam',
  abolsar: 'abolsar',
  abolsas: 'abolsas',
  abolsei: 'abolsei',
  abolsem: 'abolsem',
  abolses: 'abolses',
  abolsou: 'abolsou',
  aboluma: 'abolumá',
  abolume: 'abolume',
  abolumo: 'abolumo',
  abomasa: 'abomasa',
  abomaso: 'abomaso',
  abombai: 'abombai',
  abombam: 'abombam',
  abombar: 'abombar',
  abombas: 'abombas',
  abombei: 'abombei',
  abombem: 'abombem',
  abombes: 'abombes',
  abombou: 'abombou',
  abomina: 'abomina',
  abomine: 'abomine',
  abomino: 'abomino',
  abonada: 'abonada',
  abonado: 'abonado',
  abonais: 'abonais',
  abonamo: 'abonamo',
  abonara: 'abonará',
  abonava: 'abonava',
  abonaxi: 'abonaxi',
  aboncai: 'aboncai',
  aboncam: 'aboncam',
  aboncar: 'aboncar',
  aboncas: 'aboncas',
  aboncou: 'aboncou',
  abondai: 'abondai',
  abondam: 'abondam',
  abondar: 'abondar',
  abondas: 'abondas',
  abondei: 'abondei',
  abondem: 'abondem',
  abondes: 'abondes',
  abondou: 'abondou',
  aboneca: 'aboneca',
  aboneco: 'aboneco',
  aboneis: 'aboneis',
  abonemo: 'abonemo',
  abonque: 'abonque',
  aboquei: 'aboquei',
  aboquem: 'aboquem',
  aboques: 'aboques',
  aborais: 'aborais',
  aborcai: 'aborcai',
  aborcam: 'aborcam',
  aborcao: 'aborção',
  aborcar: 'aborcar',
  aborcas: 'aborcas',
  aborcei: 'aborcei',
  aborcem: 'aborcem',
  aborces: 'aborces',
  aborcou: 'aborçou',
  abordai: 'abordai',
  abordam: 'abordam',
  abordar: 'abordar',
  abordas: 'abordas',
  abordei: 'abordei',
  abordem: 'abordem',
  abordes: 'abordes',
  abordoa: 'abordoa',
  abordoe: 'abordoe',
  abordoo: 'abordoo',
  abordos: 'abordos',
  abordou: 'abordou',
  aborque: 'aborque',
  aborram: 'aborram',
  aborras: 'aborras',
  aborrem: 'aborrem',
  aborres: 'aborres',
  aborria: 'aborria',
  aborrir: 'aborrir',
  aborris: 'aborris',
  aborriu: 'aborriu',
  aborsos: 'aborsos',
  abortai: 'abortai',
  abortam: 'abortam',
  abortar: 'abortar',
  abortas: 'abortas',
  abortei: 'abortei',
  abortem: 'abortem',
  abortes: 'abortes',
  abortos: 'abortos',
  abortou: 'abortou',
  aboruma: 'aboruma',
  aboscai: 'aboscai',
  aboscam: 'aboscam',
  aboscar: 'aboscar',
  aboscas: 'aboscas',
  aboscou: 'aboscou',
  abosque: 'abosque',
  aboteca: 'aboteca',
  aboteco: 'aboteco',
  abotica: 'abotica',
  abotico: 'abotico',
  abotija: 'abotijá',
  abotije: 'abotije',
  abotijo: 'abotijo',
  abotina: 'abotina',
  abotine: 'abotine',
  abotino: 'abotino',
  abotoai: 'abotoai',
  abotoam: 'abotoam',
  abotoar: 'abotoar',
  abotoas: 'abotoas',
  abotoca: 'abotoca',
  abotoco: 'abotoco',
  abotoei: 'abotoei',
  abotoem: 'abotoem',
  abotoes: 'abotoes',
  abotoou: 'abotoou',
  abotuma: 'abotumá',
  abotume: 'abotume',
  abotumo: 'abotumo',
  aboubai: 'aboubai',
  aboubam: 'aboubam',
  aboubar: 'aboubar',
  aboubas: 'aboubas',
  aboubei: 'aboubei',
  aboubem: 'aboubem',
  aboubes: 'aboubes',
  aboubou: 'aboubou',
  aboucai: 'aboucai',
  aboucam: 'aboucam',
  aboucar: 'aboucar',
  aboucas: 'aboucas',
  aboucou: 'aboucou',
  abougai: 'abougai',
  abougam: 'abougam',
  abougar: 'abougar',
  abougas: 'abougas',
  abougou: 'abougou',
  abougue: 'abougue',
  abounas: 'abounas',
  abouque: 'abouque',
  abovila: 'abovila',
  abporal: 'abporal',
  abracai: 'abraçai',
  abracam: 'abraçam',
  abracao: 'abração',
  abracar: 'abraçar',
  abracas: 'abraças',
  abracei: 'abracei',
  abracem: 'abracem',
  abraces: 'abraces',
  abracos: 'abraços',
  abracou: 'abraçou',
  abradai: 'abradai',
  abradam: 'abradam',
  abradar: 'abradar',
  abradas: 'abradas',
  abradei: 'abradei',
  abradem: 'abradem',
  abrades: 'abrades',
  abradou: 'abradou',
  abraemo: 'abraemo',
  abralia: 'abrália',
  abramis: 'abrâmis',
  abramos: 'abramos',
  abranca: 'abranca',
  abranco: 'abranco',
  abranda: 'abranda',
  abrande: 'abrande',
  abrando: 'abrando',
  abrange: 'abrange',
  abrangi: 'abrangi',
  abranja: 'abranja',
  abranjo: 'abranjo',
  abraoes: 'abraões',
  abraque: 'abraque',
  abrasai: 'abrasai',
  abrasam: 'abrasam',
  abrasao: 'abrasão',
  abrasar: 'abrasar',
  abrasas: 'abrasas',
  abrasax: 'abrásax',
  abrasea: 'abraseá',
  abrasei: 'abrasei',
  abrasem: 'abrasem',
  abrases: 'abrases',
  abrasim: 'abrasim',
  abrasoa: 'abrasoa',
  abrasoe: 'abrasoe',
  abrasoo: 'abrasoo',
  abrasor: 'abrasor',
  abrasou: 'abrasou',
  abraxas: 'abraxas',
  abrazos: 'abrazôs',
  abrecai: 'abrecai',
  abrecam: 'abrecam',
  abrecar: 'abrecar',
  abrecas: 'abrecas',
  abrecia: 'abrécia',
  abrecou: 'abrecou',
  abregoa: 'abregoá',
  abregoe: 'abregoe',
  abregoo: 'abregoo',
  abregos: 'ábregos',
  abrejai: 'abrejai',
  abrejam: 'abrejam',
  abrejar: 'abrejar',
  abrejas: 'abrejas',
  abrejei: 'abrejei',
  abrejem: 'abrejem',
  abrejes: 'abrejes',
  abrejou: 'abrejou',
  abrenha: 'abrenha',
  abrenhe: 'abrenhe',
  abrenho: 'abrenho',
  abreque: 'abreque',
  abretia: 'abrétia',
  abrevai: 'abrevai',
  abrevam: 'abrevam',
  abrevar: 'abrevar',
  abrevas: 'abrevas',
  abrevei: 'abrevei',
  abrevem: 'abrevem',
  abreves: 'abreves',
  abrevia: 'abrevia',
  abrevie: 'abrevie',
  abrevio: 'abrevio',
  abrevou: 'abrevou',
  abricao: 'abrição',
  abricos: 'abricós',
  abridao: 'abridão',
  abridas: 'abridas',
  abridor: 'abridor',
  abridos: 'abridos',
  abrieis: 'abríeis',
  abrigai: 'abrigai',
  abrigam: 'abrigam',
  abrigar: 'abrigar',
  abrigas: 'abrigas',
  abrigoa: 'abrigoa',
  abrigoe: 'abrigoe',
  abrigoi: 'abrigoi',
  abrigoo: 'abrigoo',
  abrigos: 'abrigos',
  abrigou: 'abrigou',
  abrigue: 'abrigue',
  abrimos: 'abrimos',
  abrinas: 'abrinas',
  abrindo: 'abrindo',
  abrioes: 'abriões',
  abrique: 'abrique',
  abriram: 'abriram',
  abrirao: 'abrirão',
  abriras: 'abriras',
  abrirei: 'abrirei',
  abrirem: 'abrirem',
  abrires: 'abrires',
  abriria: 'abriria',
  abrirmo: 'abrirmo',
  abrismo: 'abrismo',
  abrisse: 'abrisse',
  abrista: 'abrista',
  abriste: 'abriste',
  abritas: 'abritas',
  abroani: 'abroâni',
  abrobas: 'abrobas',
  abrocai: 'abrocai',
  abrocam: 'abrocam',
  abrocar: 'abrocar',
  abrocas: 'abrocas',
  abrocha: 'abrocha',
  abroche: 'abroche',
  abrocho: 'abrocho',
  abrocos: 'abrocos',
  abrocou: 'abrocou',
  abrodil: 'abrodil',
  abrodis: 'abrodis',
  abrogar: 'abrogar',
  abroito: 'abroito',
  abrojos: 'abrojos',
  abrolha: 'abrolha',
  abrolhe: 'abrolhe',
  abrolho: 'abrolho',
  abromas: 'abromas',
  abromia: 'abromia',
  abronza: 'abronza',
  abronze: 'abronze',
  abronzo: 'abronzo',
  abroque: 'abroque',
  abrotai: 'abrotai',
  abrotal: 'abrotal',
  abrotam: 'abrotam',
  abrotar: 'abrotar',
  abrotas: 'abrotas',
  abrotea: 'abrótea',
  abrotei: 'abrotei',
  abrotem: 'abrotem',
  abrotes: 'abrotes',
  abrotou: 'abrotou',
  abrulho: 'abrulho',
  abrumai: 'abrumai',
  abrumam: 'abrumam',
  abrumar: 'abrumar',
  abrumas: 'abrumas',
  abrumei: 'abrumei',
  abrumem: 'abrumem',
  abrumes: 'abrumes',
  abrumou: 'abrumou',
  abrunha: 'abrunhá',
  abrunhe: 'abrunhe',
  abrunho: 'abrunho',
  abrupta: 'abrupta',
  abrupto: 'abrupto',
  abrutai: 'abrutai',
  abrutam: 'abrutam',
  abrutar: 'abrutar',
  abrutas: 'abrutas',
  abrutei: 'abrutei',
  abrutem: 'abrutem',
  abrutes: 'abrutes',
  abrutou: 'abrutou',
  absceda: 'absceda',
  abscede: 'abscede',
  abscedi: 'abscedi',
  abscedo: 'abscedo',
  abscida: 'abscida',
  abscide: 'abscide',
  abscido: 'abscido',
  abscisa: 'abscisa',
  absciso: 'absciso',
  absecto: 'absecto',
  absenta: 'absenta',
  absente: 'absente',
  absento: 'absento',
  absicto: 'absicto',
  absidal: 'absidal',
  absidas: 'absidas',
  absides: 'absides',
  absinta: 'absinta',
  absinte: 'absinte',
  absinto: 'absinto',
  absogra: 'absogra',
  absogro: 'absogro',
  absolta: 'absolta',
  absolto: 'absolto',
  absolva: 'absolva',
  absolve: 'absolve',
  absolvi: 'absolvi',
  absolvo: 'absolvo',
  absomem: 'absomem',
  absomes: 'absomes',
  absonai: 'absonai',
  absonam: 'absonam',
  absonar: 'absonar',
  absonas: 'absonas',
  absonei: 'absonei',
  absonem: 'absonem',
  absones: 'absones',
  absonos: 'ábsonos',
  absonou: 'absonou',
  absorba: 'absorba',
  absorbe: 'absorbe',
  absorbi: 'absorbi',
  absorbo: 'absorbo',
  absorta: 'absorta',
  absorto: 'absorto',
  absorva: 'absorva',
  absorve: 'absorve',
  absorvi: 'absorvi',
  absorvo: 'absorvo',
  abstemo: 'abstemo',
  abstens: 'absténs',
  abstera: 'absterá',
  abstero: 'abstero',
  absteve: 'absteve',
  abstida: 'abstida',
  abstido: 'abstido',
  abstive: 'abstive',
  abstrai: 'abstrai',
  abstroi: 'abstrói',
  abstrua: 'abstrua',
  abstrui: 'abstruí',
  abstruo: 'abstruo',
  absumam: 'absumam',
  absumas: 'absumas',
  absumia: 'absumia',
  absumir: 'absumir',
  absumis: 'absumis',
  absumiu: 'absumiu',
  absunta: 'absunta',
  absunto: 'absunto',
  absurda: 'absurda',
  absurdo: 'absurdo',
  absuses: 'ábsuses',
  abuanai: 'abuanai',
  abuanam: 'abuanam',
  abuanar: 'abuanar',
  abuanas: 'abuanas',
  abuanei: 'abuanei',
  abuanem: 'abuanem',
  abuanes: 'abuanes',
  abuanou: 'abuanou',
  abucada: 'abuçada',
  abucado: 'abuçado',
  abucaia: 'abucaia',
  abucais: 'abuçais',
  abucamo: 'abuçamo',
  abucara: 'abuçará',
  abucava: 'abuçava',
  abuceis: 'abuceis',
  abucemo: 'abucemo',
  abufela: 'abufela',
  abufele: 'abufele',
  abufelo: 'abufelo',
  abufeta: 'abufeta',
  abufete: 'abufete',
  abufeto: 'abufeto',
  abugoes: 'abugões',
  abugrai: 'abugrai',
  abugram: 'abugram',
  abugrar: 'abugrar',
  abugras: 'abugras',
  abugrei: 'abugrei',
  abugrem: 'abugrem',
  abugres: 'abugres',
  abugrou: 'abugrou',
  abuizes: 'abuízes',
  abujede: 'abujede',
  abujoes: 'abujões',
  abulada: 'abulada',
  abulado: 'abulado',
  abulais: 'abulais',
  abulamo: 'abulamo',
  abulara: 'abulara',
  abulava: 'abulava',
  abulaza: 'abulaza',
  abuleis: 'abuleis',
  abulemo: 'abulemo',
  abulias: 'abulias',
  abulica: 'abúlica',
  abulico: 'abúlico',
  abulzao: 'abulzão',
  abundai: 'abundai',
  abundam: 'abundam',
  abundar: 'abundar',
  abundas: 'abundas',
  abundei: 'abundei',
  abundem: 'abundem',
  abundes: 'abundes',
  abundou: 'abundou',
  abunhai: 'abunhai',
  abunham: 'abunham',
  abunhar: 'abunhar',
  abunhas: 'abunhas',
  abunhei: 'abunhei',
  abunhem: 'abunhem',
  abunhes: 'abunhes',
  abunhou: 'abunhou',
  abunuro: 'abunuro',
  aburaca: 'aburaca',
  aburaco: 'aburaco',
  aburela: 'aburela',
  aburele: 'aburele',
  aburelo: 'aburelo',
  aburila: 'aburilá',
  aburile: 'aburile',
  aburilo: 'aburilo',
  aburote: 'aburote',
  aburrai: 'aburrai',
  aburram: 'aburram',
  aburrao: 'aburrão',
  aburrar: 'aburrar',
  aburras: 'aburras',
  aburrei: 'aburrei',
  aburrem: 'aburrem',
  aburres: 'aburres',
  aburrou: 'aburrou',
  abusada: 'abusada',
  abusado: 'abusado',
  abusais: 'abusais',
  abusamo: 'abusamo',
  abusara: 'abusara',
  abusava: 'abusava',
  abuscai: 'abuscai',
  abuscam: 'abuscam',
  abuscar: 'abuscar',
  abuscas: 'abuscas',
  abuscou: 'abuscou',
  abuseai: 'abuseai',
  abusear: 'abusear',
  abuseei: 'abuseei',
  abuseia: 'abuseia',
  abuseie: 'abuseie',
  abuseio: 'abuseio',
  abuseis: 'abuseis',
  abusemo: 'abusemo',
  abuseou: 'abuseou',
  abusiva: 'abusiva',
  abusivo: 'abusivo',
  abusoes: 'abusões',
  abusque: 'abusque',
  abutilo: 'abutilo',
  abutona: 'abutona',
  abutres: 'abutres',
  abutuas: 'abutuas',
  abutuma: 'abutumá',
  abutume: 'abutume',
  abutumo: 'abutumo',
  abuzina: 'abuzina',
  abuzine: 'abuzine',
  abuzino: 'abuzino',
  abvacua: 'abvacuá',
  abvacue: 'abvacue',
  abvacuo: 'abvacuo',
  acabaca: 'acabaça',
  acabace: 'acabace',
  acabaco: 'acabaço',
  acabada: 'acabada',
  acabado: 'acabado',
  acabais: 'acabais',
  acabamo: 'acabamo',
  acabana: 'acabana',
  acabane: 'acabane',
  acabano: 'acabano',
  acabara: 'acabara',
  acabava: 'acabava',
  acabeis: 'acabeis',
  acabela: 'acabela',
  acabele: 'acabele',
  acabelo: 'acabelo',
  acabemo: 'acabemo',
  acabera: 'acaberá',
  acabiam: 'acabiam',
  acabias: 'acabias',
  acabida: 'acabidá',
  acabide: 'acabide',
  acabido: 'acabido',
  acacada: 'acaçada',
  acacado: 'acaçado',
  acacaia: 'açacaia',
  acacais: 'acaçais',
  acacala: 'açacala',
  acacale: 'acacale',
  acacalo: 'acacalo',
  acacamo: 'acaçamo',
  acacapa: 'acaçapa',
  acacape: 'acaçape',
  acacapo: 'acaçapo',
  acacara: 'acaçará',
  acacava: 'acaçava',
  acaceis: 'acaceis',
  acacemo: 'acacemo',
  acaceta: 'acacetá',
  acacete: 'acacete',
  acaceto: 'acaceto',
  acachai: 'acachai',
  acacham: 'acacham',
  acachar: 'acachar',
  acachas: 'acachas',
  acachea: 'acacheá',
  acachei: 'acachei',
  acachem: 'acachem',
  acaches: 'acaches',
  acachoa: 'acachoa',
  acachoe: 'acachoe',
  acachoo: 'acachoo',
  acachou: 'acachou',
  acacias: 'acácias',
  acacica: 'acácica',
  acacico: 'acácico',
  acacifa: 'acacifa',
  acacife: 'acacife',
  acacifo: 'acacifo',
  acacina: 'acacina',
  acacios: 'acácios',
  acacoai: 'acaçoai',
  acacoam: 'acaçoam',
  acacoar: 'acaçoar',
  acacoas: 'acaçoas',
  acacoei: 'acaçoei',
  acacoem: 'acaçoem',
  acacoes: 'acaçoes',
  acacoou: 'acaçoou',
  acacuis: 'açacuís',
  acacula: 'acacula',
  acacule: 'acacule',
  acaculo: 'acaculo',
  acadaca: 'açadacã',
  acadada: 'acadada',
  acadado: 'acadado',
  acadais: 'acadais',
  acadamo: 'acadamo',
  acadara: 'acadara',
  acadava: 'acadava',
  acadeis: 'acadeis',
  academo: 'academo',
  acadias: 'acádias',
  acadico: 'acádico',
  acadima: 'acadima',
  acadime: 'acadime',
  acadimo: 'acadimo',
  acadios: 'acádios',
  acadiro: 'acadiro',
  acaecam: 'acaeçam',
  acaecas: 'acaeças',
  acaecei: 'acaecei',
  acaecem: 'acaecem',
  acaecer: 'acaecer',
  acaeces: 'acaeces',
  acaeceu: 'acaeceu',
  acaecia: 'acaecia',
  acaenta: 'acaentá',
  acaente: 'acaente',
  acaento: 'acaento',
  acafala: 'acafala',
  acafale: 'acafale',
  acafalo: 'acafalo',
  acafata: 'açafata',
  acafate: 'açafate',
  acafato: 'açafato',
  acafela: 'acafela',
  acafele: 'acafele',
  acafelo: 'acafelo',
  acafeta: 'acafeta',
  acafete: 'acafete',
  acafeto: 'acafeto',
  acaflor: 'açaflor',
  acafoba: 'acafoba',
  acafobe: 'acafobe',
  acafobo: 'acafobo',
  acafoes: 'açafões',
  acafrao: 'açafrão',
  acafrar: 'açafrar',
  acafras: 'açafras',
  acafroa: 'açafroa',
  acafroe: 'açafroe',
  acafrol: 'açafrol',
  acafroo: 'açafroo',
  acagaca: 'acagaça',
  acagace: 'acagace',
  acagaco: 'acagaço',
  acagiba: 'acagibá',
  acagibe: 'acagibe',
  acagibo: 'acagibo',
  acagina: 'acagina',
  acagual: 'acagual',
  acagula: 'acagula',
  acagule: 'acagule',
  acagulo: 'acagulo',
  acaiaba: 'acaiaba',
  acaiaca: 'acaiacá',
  acaiais: 'acaiais',
  acaiana: 'acaiana',
  acaiano: 'acaiano',
  acaibam: 'acaibam',
  acaibas: 'acaibas',
  acaicos: 'acaicos',
  acaidao: 'acaidão',
  acaidas: 'acaídas',
  acaidor: 'acaidor',
  acaidos: 'acaídos',
  acaieis: 'acaíeis',
  acaimai: 'açaimai',
  acaimam: 'açaimam',
  acaimar: 'açaimar',
  acaimas: 'açaimas',
  acaimei: 'açaimei',
  acaimem: 'açaimem',
  acaimes: 'açaimes',
  acaimos: 'açaimos',
  acaimou: 'açaimou',
  acaindo: 'acaindo',
  acairam: 'acaíram',
  acairao: 'acairão',
  acairas: 'acaíras',
  acairei: 'acairei',
  acairem: 'acaírem',
  acaires: 'acaíres',
  acairia: 'acairia',
  acaisse: 'acaísse',
  acaiste: 'acaíste',
  acaiura: 'acaiura',
  acaizal: 'açaizal',
  acajada: 'acajada',
  acajade: 'acajade',
  acajado: 'acajado',
  acajuba: 'acajuba',
  acajuis: 'acajuís',
  acalaca: 'acalaca',
  acalada: 'acalada',
  acalado: 'acalado',
  acalais: 'acalais',
  acalamo: 'acalamo',
  acalara: 'acalara',
  acalava: 'acalava',
  acalcai: 'acalcai',
  acalcam: 'acalcam',
  acalcar: 'acalcar',
  acalcas: 'acalcas',
  acalcei: 'acalcei',
  acalcem: 'acalcem',
  acalces: 'acalces',
  acalcou: 'acalcou',
  acalefa: 'acalefa',
  acalefo: 'acalefo',
  acaleis: 'acaleis',
  acalemo: 'acalemo',
  acalhai: 'açalhai',
  acalham: 'açalham',
  acalhar: 'açalhar',
  acalhas: 'açalhas',
  acalhei: 'açalhei',
  acalhem: 'açalhem',
  acalhes: 'açalhes',
  acalhoa: 'acalhoá',
  acalhoe: 'acalhoe',
  acalhoo: 'acalhoo',
  acalhou: 'açalhou',
  acalice: 'acálice',
  acalifa: 'acalifa',
  acalmai: 'acalmai',
  acalmam: 'acalmam',
  acalmar: 'acalmar',
  acalmas: 'acalmas',
  acalmei: 'acalmei',
  acalmem: 'acalmem',
  acalmes: 'acalmes',
  acalmia: 'acalmia',
  acalmos: 'açalmos',
  acalmou: 'acalmou',
  acaloia: 'açaloiá',
  acaloie: 'açaloie',
  acaloio: 'açaloio',
  acalora: 'acalora',
  acalore: 'acalore',
  acaloro: 'acaloro',
  acalote: 'acalote',
  acalpao: 'açalpão',
  acalque: 'acalque',
  acalzao: 'acalzão',
  acamacu: 'acamacu',
  acamada: 'acamada',
  acamado: 'acamado',
  acamais: 'acamais',
  acamamo: 'acamamo',
  acamara: 'acamara',
  acamato: 'acâmato',
  acamava: 'acamava',
  acamboa: 'acamboa',
  acamboe: 'acamboe',
  acamboo: 'acamboo',
  acameis: 'açameis',
  acamela: 'acamela',
  acamele: 'acamele',
  acamelo: 'acamelo',
  acamemo: 'açamemo',
  acamisa: 'acamisa',
  acamise: 'acamise',
  acamiso: 'acamiso',
  acamote: 'acamote',
  acampai: 'acampai',
  acampam: 'acampam',
  acampar: 'acampar',
  acampas: 'acampas',
  acampei: 'acampei',
  acampem: 'acampem',
  acampes: 'acampes',
  acampou: 'acampou',
  acampto: 'acampto',
  acanaca: 'acanaca',
  acanada: 'acanada',
  acanala: 'acanala',
  acanale: 'acanale',
  acanalo: 'acanalo',
  acanati: 'acanati',
  acandes: 'acandes',
  acaneia: 'acaneia',
  acanela: 'acanela',
  acanele: 'acanele',
  acanelo: 'acanelo',
  acangas: 'acangas',
  acanges: 'acanges',
  acangis: 'acangis',
  acanhai: 'acanhai',
  acanham: 'acanham',
  acanhar: 'acanhar',
  acanhas: 'acanhas',
  acanhei: 'acanhei',
  acanhem: 'acanhem',
  acanhes: 'acanhes',
  acanhoa: 'acanhoa',
  acanhoe: 'acanhoe',
  acanhoo: 'acanhoo',
  acanhou: 'acanhou',
  acanica: 'açanicá',
  acanico: 'açanico',
  acanito: 'acanito',
  acanjes: 'acanjes',
  acanjis: 'acanjis',
  acanoai: 'acanoai',
  acanoam: 'acanoam',
  acanoar: 'acanoar',
  acanoas: 'acanoas',
  acanoei: 'acanoei',
  acanoem: 'acanoem',
  acanoes: 'acanoes',
  acanoou: 'acanoou',
  acantal: 'acantal',
  acantas: 'acantas',
  acantes: 'acantes',
  acantia: 'acantia',
  acantoa: 'acantoa',
  acantoe: 'acantoe',
  acantoo: 'acantoo',
  acantor: 'acântor',
  acantos: 'acantos',
  acantus: 'acântus',
  acanuda: 'acanudá',
  acanude: 'acanude',
  acanudo: 'acanudo',
  acanula: 'acanula',
  acanule: 'acanule',
  acanulo: 'acanulo',
  acanuta: 'acanutá',
  acanute: 'acanute',
  acanuto: 'acanuto',
  acaovas: 'acaovãs',
  acapada: 'açapada',
  acapado: 'açapado',
  acapais: 'açapais',
  acapamo: 'açapamo',
  acapara: 'acapara',
  acapare: 'acapare',
  acaparo: 'acaparo',
  acapava: 'açapava',
  acapeis: 'açapeis',
  acapela: 'acapela',
  acapele: 'acapele',
  acapelo: 'acapelo',
  acapemo: 'açapemo',
  acapita: 'acapitã',
  acapnas: 'acapnas',
  acapnia: 'acapnia',
  acapnos: 'acapnos',
  acapora: 'acapora',
  acapori: 'acapori',
  acapota: 'acapota',
  acapote: 'acapote',
  acapoto: 'acapoto',
  acapuas: 'acapuas',
  acapuva: 'açapuva',
  acaracu: 'acaraçu',
  acarada: 'acarada',
  acarado: 'acarado',
  acaraia: 'acaraiá',
  acarais: 'acarais',
  acaraja: 'acarajá',
  acaraje: 'acarajé',
  acaramo: 'acaramo',
  acarape: 'acarape',
  acarapi: 'acarapi',
  acarara: 'acarará',
  acarava: 'acarava',
  acardas: 'acardas',
  acardia: 'acardia',
  acardos: 'acardos',
  acareai: 'acareai',
  acarear: 'acarear',
  acareei: 'acareei',
  acareia: 'acareia',
  acareie: 'acareie',
  acareio: 'acareio',
  acareis: 'acareis',
  acarela: 'acarela',
  acaremo: 'acaremo',
  acareou: 'acareou',
  acarida: 'acarida',
  acaride: 'acaride',
  acarido: 'acárido',
  acarima: 'acarima',
  acarino: 'acarino',
  acarmos: 'acarmos',
  acarnar: 'acarnar',
  acarnas: 'acarnas',
  acarneu: 'acarneu',
  acarnos: 'acarnos',
  acaroai: 'acaroai',
  acaroam: 'acaroam',
  acaroar: 'acaroar',
  acaroas: 'acaroas',
  acaroei: 'acaroei',
  acaroem: 'acaroem',
  acaroes: 'acaroes',
  acaroou: 'acaroou',
  acarose: 'acarose',
  acarpia: 'acarpia',
  acarpio: 'acárpio',
  acarpos: 'acarpos',
  acarrai: 'acarrai',
  acarram: 'acarram',
  acarrar: 'acarrar',
  acarras: 'acarras',
  acarrea: 'acarreá',
  acarrei: 'acarrei',
  acarrem: 'acarrem',
  acarres: 'acarres',
  acarrou: 'acarrou',
  acartai: 'acartai',
  acartam: 'acartam',
  acartar: 'acartar',
  acartas: 'acartas',
  acartei: 'acartei',
  acartem: 'acartem',
  acartes: 'acartes',
  acartou: 'acartou',
  acarvai: 'acarvai',
  acarvam: 'acarvam',
  acarvar: 'acarvar',
  acarvas: 'acarvas',
  acarvei: 'acarvei',
  acarvem: 'acarvem',
  acarves: 'acarves',
  acarvoa: 'acarvoá',
  acarvoe: 'acarvoe',
  acarvoo: 'acarvoo',
  acarvou: 'acarvou',
  acasaca: 'acasacá',
  acasaco: 'acasaco',
  acasala: 'acasala',
  acasale: 'acasale',
  acasalo: 'acasalo',
  acasdir: 'acasdir',
  acaseai: 'acaseai',
  acasear: 'acasear',
  acaseei: 'acaseei',
  acaseia: 'acaseia',
  acaseie: 'acaseie',
  acaseio: 'acaseio',
  acaseou: 'acaseou',
  acastas: 'acastas',
  acastes: 'acastes',
  acastoa: 'acastoá',
  acastoe: 'acastoe',
  acastoo: 'acastoo',
  acastos: 'acastos',
  acasula: 'acasula',
  acasule: 'acasule',
  acasulo: 'acasulo',
  acasuso: 'acasuso',
  acatada: 'acatada',
  acatado: 'acatado',
  acataia: 'acataia',
  acatais: 'acatais',
  acatamo: 'acatamo',
  acatara: 'acatara',
  acatava: 'acatava',
  acateis: 'acateis',
  acatemo: 'acatemo',
  acatena: 'acatena',
  acateno: 'acateno',
  acatina: 'acatina',
  acatita: 'acatita',
  acatite: 'acatite',
  acatito: 'acatito',
  acauaio: 'acauaio',
  acauais: 'acauais',
  acauens: 'acauens',
  acaules: 'acaules',
  acausta: 'acausta',
  acausto: 'acausto',
  acavala: 'acavala',
  acavale: 'acavale',
  acavalo: 'acavalo',
  acciana: 'acciana',
  acciano: 'acciano',
  acciona: 'acciona',
  accione: 'accione',
  acciono: 'acciono',
  acebada: 'acebada',
  acebado: 'acebado',
  acebais: 'acebais',
  acebamo: 'acebamo',
  acebara: 'acebará',
  acebava: 'acebava',
  acebeis: 'acebeis',
  acebemo: 'acebemo',
  aceboca: 'acebocá',
  aceboco: 'aceboco',
  acebola: 'acebola',
  acebole: 'acebole',
  acebolo: 'acebolo',
  aceches: 'aceches',
  acedais: 'acedais',
  acedeis: 'acedeis',
  acedemo: 'acedemo',
  acedera: 'acedera',
  acediam: 'acediam',
  acedias: 'acedias',
  acedida: 'acedida',
  acedido: 'acedido',
  acefala: 'acéfala',
  acefalo: 'acéfalo',
  acefica: 'acefica',
  acefico: 'acefico',
  aceibai: 'aceibai',
  aceibam: 'aceibam',
  aceibar: 'aceibar',
  aceibas: 'aceibas',
  aceibei: 'aceibei',
  aceibem: 'aceibem',
  aceibes: 'aceibes',
  aceibou: 'aceibou',
  aceifai: 'aceifai',
  aceifam: 'aceifam',
  aceifao: 'aceifão',
  aceifar: 'aceifar',
  aceifas: 'aceifas',
  aceifei: 'aceifei',
  aceifem: 'aceifem',
  aceifes: 'aceifes',
  aceifou: 'aceifou',
  aceimai: 'aceimai',
  aceimam: 'aceimam',
  aceimar: 'aceimar',
  aceimas: 'aceimas',
  aceimei: 'aceimei',
  aceimem: 'aceimem',
  aceimes: 'aceimes',
  aceimou: 'aceimou',
  aceirai: 'aceirai',
  aceiram: 'aceiram',
  aceirar: 'aceirar',
  aceiras: 'aceiras',
  aceirei: 'aceirei',
  aceirem: 'aceirem',
  aceires: 'aceires',
  aceiros: 'aceiros',
  aceirou: 'aceirou',
  aceitai: 'aceitai',
  aceitam: 'aceitam',
  aceitar: 'aceitar',
  aceitas: 'aceitas',
  aceitei: 'aceitei',
  aceitem: 'aceitem',
  aceites: 'aceites',
  aceitos: 'aceitos',
  aceitou: 'aceitou',
  acejada: 'acejada',
  acejado: 'acejado',
  acejais: 'acejais',
  acejamo: 'acejamo',
  acejara: 'acejará',
  acejava: 'acejava',
  acejeis: 'acejeis',
  acejemo: 'acejemo',
  acelcas: 'acelcas',
  acelera: 'acelera',
  acelere: 'acelere',
  acelero: 'acelero',
  acelgas: 'acelgas',
  acelias: 'acelias',
  acelifa: 'acelifa',
  acelifo: 'acelifo',
  acemela: 'acemela',
  acemeta: 'acemeta',
  acemeto: 'acemeto',
  acemias: 'acemias',
  acemita: 'acemita',
  acemite: 'acemite',
  acenada: 'acenada',
  acenado: 'acenado',
  acenais: 'acenais',
  acenamo: 'acenamo',
  acenara: 'acenara',
  acenava: 'acenava',
  acendam: 'acendam',
  acendao: 'acendão',
  acendas: 'acendas',
  acendea: 'acendeá',
  acendei: 'acendei',
  acendem: 'acendem',
  acender: 'acender',
  acendes: 'acendes',
  acendeu: 'acendeu',
  acendia: 'acendia',
  acendra: 'acendra',
  acendre: 'acendre',
  acendro: 'acendro',
  aceneis: 'aceneis',
  acenemo: 'acenemo',
  acenhas: 'acenhas',
  acenosa: 'acenosa',
  acenoso: 'acenoso',
  acensao: 'acensão',
  acensos: 'acensos',
  acentor: 'acentor',
  acentos: 'acentos',
  acentra: 'acentra',
  acentro: 'acentro',
  acentua: 'acentua',
  acentue: 'acentue',
  acentuo: 'acentuo',
  acepcao: 'acepção',
  acepipa: 'acepipá',
  acepipe: 'acepipe',
  acepipo: 'acepipo',
  aceptai: 'aceptai',
  aceptam: 'aceptam',
  aceptar: 'aceptar',
  aceptas: 'aceptas',
  aceptei: 'aceptei',
  aceptem: 'aceptem',
  aceptes: 'aceptes',
  aceptor: 'aceptor',
  aceptou: 'aceptou',
  acequia: 'acéquia',
  acequie: 'acequie',
  acequio: 'acequio',
  acerada: 'acerada',
  acerado: 'acerado',
  acerais: 'acerais',
  acerale: 'acerale',
  aceramo: 'aceramo',
  acerara: 'acerará',
  acerato: 'acerato',
  acerava: 'acerava',
  acerbai: 'acerbai',
  acerbam: 'acerbam',
  acerbar: 'acerbar',
  acerbas: 'acerbas',
  acerbei: 'acerbei',
  acerbem: 'acerbem',
  acerbes: 'acerbes',
  acerbos: 'acerbos',
  acerbou: 'acerbou',
  acercai: 'acercai',
  acercam: 'acercam',
  acercar: 'acercar',
  acercas: 'acercas',
  acercou: 'acercou',
  acerdol: 'acerdol',
  acereai: 'acereai',
  acerear: 'acerear',
  acereei: 'acereei',
  acereia: 'acereia',
  acereie: 'acereie',
  acereio: 'acereio',
  acereis: 'acereis',
  acereja: 'acereja',
  acereje: 'acereje',
  acerejo: 'acerejo',
  aceremo: 'aceremo',
  acereou: 'acereou',
  acerias: 'acerias',
  acerido: 'acérido',
  acerina: 'acerina',
  acerino: 'acerino',
  acerita: 'acerita',
  acerite: 'acerite',
  acerola: 'acerola',
  acerolo: 'acerolo',
  acerosa: 'acerosa',
  aceroso: 'aceroso',
  acerque: 'acerque',
  acerras: 'acerras',
  acertai: 'acertai',
  acertam: 'acertam',
  acertar: 'acertar',
  acertas: 'acertas',
  acertei: 'acertei',
  acertem: 'acertem',
  acertes: 'acertes',
  acertos: 'acertos',
  acertou: 'acertou',
  acervai: 'acervai',
  acervam: 'acervam',
  acervar: 'acervar',
  acervas: 'acervas',
  acervei: 'acervei',
  acervem: 'acervem',
  acerves: 'acerves',
  acervos: 'acervos',
  acervou: 'acervou',
  acesoai: 'acesoai',
  acesoam: 'acesoam',
  acesoar: 'acesoar',
  acesoas: 'acesoas',
  acesoei: 'acesoei',
  acesoem: 'acesoem',
  acesoes: 'acesoes',
  acesona: 'acesona',
  acesone: 'acesone',
  acesono: 'acesono',
  acesoou: 'acesoou',
  acessai: 'acessai',
  acessam: 'acessam',
  acessao: 'acessão',
  acessar: 'acessar',
  acessas: 'acessas',
  acessei: 'acessei',
  acessem: 'acessem',
  acesses: 'acesses',
  acessoa: 'acessoa',
  acessoe: 'acessoe',
  acessoo: 'acessoo',
  acessos: 'acessos',
  acessou: 'acessou',
  acestas: 'acestas',
  acestes: 'acestes',
  acesteu: 'acesteu',
  acestor: 'acestor',
  acestra: 'acestra',
  acesume: 'acesume',
  acetada: 'acetada',
  acetado: 'acetado',
  acetais: 'acetais',
  acetamo: 'acetamo',
  acetara: 'acetará',
  acetato: 'acetato',
  acetava: 'acetava',
  aceteis: 'aceteis',
  acetemo: 'acetemo',
  aceteno: 'aceteno',
  acetias: 'acetias',
  acetica: 'acética',
  acetico: 'acético',
  acetila: 'acetila',
  acetile: 'acetile',
  acetilo: 'acetilo',
  acetina: 'acetina',
  acetine: 'acetine',
  acetino: 'acetino',
  acetito: 'acetito',
  acetois: 'acetóis',
  acetola: 'acetola',
  acetole: 'acetole',
  acetolo: 'acetolo',
  acetona: 'acetona',
  acetono: 'acetono',
  acetosa: 'acetosa',
  acetose: 'acetose',
  acetoso: 'acetoso',
  acevada: 'acevada',
  acevade: 'acevade',
  acevado: 'acevado',
  acevais: 'acevais',
  acevamo: 'acevamo',
  acevara: 'acevará',
  acevava: 'acevava',
  aceveis: 'aceveis',
  acevemo: 'acevemo',
  achacai: 'achacai',
  achacam: 'achacam',
  achacar: 'achacar',
  achacas: 'achacas',
  achachi: 'achachi',
  achacou: 'achacou',
  achadao: 'achadão',
  achadas: 'achadas',
  achadia: 'achadia',
  achadio: 'achadio',
  achador: 'achador',
  achados: 'achados',
  achagai: 'achagai',
  achagam: 'achagam',
  achagar: 'achagar',
  achagas: 'achagas',
  achagou: 'achagou',
  achagua: 'achaguá',
  achague: 'achague',
  achamos: 'achamos',
  achanai: 'achanai',
  achanam: 'achanam',
  achanar: 'achanar',
  achanas: 'achanas',
  achanci: 'achanci',
  achando: 'achando',
  achanei: 'achanei',
  achanem: 'achanem',
  achanes: 'achanes',
  achania: 'achânia',
  achanou: 'achanou',
  achanta: 'achanta',
  achante: 'achante',
  achanti: 'achânti',
  achanto: 'achanto',
  achanza: 'achanzá',
  achanze: 'achanze',
  achanzo: 'achanzo',
  achaoza: 'achãozá',
  achaoze: 'achãoze',
  achaozo: 'achãozo',
  achaque: 'achaque',
  acharai: 'acharai',
  acharam: 'acharam',
  acharao: 'acharão',
  acharar: 'acharar',
  acharas: 'acharas',
  acharca: 'acharca',
  acharco: 'acharco',
  achardo: 'achardo',
  acharei: 'acharei',
  acharem: 'acharem',
  achares: 'achares',
  acharia: 'acharia',
  acharmo: 'acharmo',
  acharoa: 'acharoa',
  acharoe: 'acharoe',
  acharoo: 'acharoo',
  acharou: 'acharou',
  achassa: 'achassa',
  achasse: 'achasse',
  achassi: 'achassi',
  achasso: 'achasso',
  achaste: 'achaste',
  achatai: 'achatai',
  achatam: 'achatam',
  achatar: 'achatar',
  achatas: 'achatas',
  achatei: 'achatei',
  achatem: 'achatem',
  achates: 'achates',
  achatou: 'achatou',
  achavam: 'achavam',
  achavas: 'achavas',
  achavel: 'achável',
  achegai: 'achegai',
  achegam: 'achegam',
  achegar: 'achegar',
  achegas: 'achegas',
  achegos: 'achegos',
  achegou: 'achegou',
  achegue: 'achegue',
  acheira: 'acheira',
  achemos: 'achemos',
  achicai: 'achicai',
  achicam: 'achicam',
  achicar: 'achicar',
  achicas: 'achicas',
  achicou: 'achicou',
  achinai: 'achinai',
  achinam: 'achinam',
  achinar: 'achinar',
  achinas: 'achinas',
  achinei: 'achinei',
  achinem: 'achinem',
  achines: 'achinês',
  achinou: 'achinou',
  achiota: 'achiota',
  achiote: 'achiote',
  achique: 'achique',
  achiris: 'achiris',
  achismo: 'achismo',
  achista: 'achista',
  achoada: 'achoada',
  achoado: 'achoado',
  achoais: 'achoais',
  achoamo: 'achoamo',
  achoara: 'achoara',
  achoava: 'achoava',
  achocai: 'achoçai',
  achocam: 'achoçam',
  achocar: 'achoçar',
  achocas: 'achoças',
  achocei: 'achocei',
  achocem: 'achocem',
  achoces: 'achoces',
  achocou: 'achoçou',
  achoeis: 'achoeis',
  achoemo: 'achoemo',
  achoque: 'achoque',
  achouta: 'achouta',
  achoute: 'achoute',
  achouto: 'achouto',
  achucai: 'achuçai',
  achucam: 'achuçam',
  achucar: 'achuçar',
  achucas: 'achuças',
  achucei: 'achucei',
  achucem: 'achucem',
  achuces: 'achuces',
  achucou: 'achuçou',
  achumba: 'achumba',
  achumbe: 'achumbe',
  achumbo: 'achumbo',
  achusma: 'achusma',
  achusme: 'achusme',
  achusmo: 'achusmo',
  acianas: 'acianas',
  acianos: 'acianos',
  acianto: 'acianto',
  aciaria: 'aciaria',
  aciario: 'aciário',
  acicala: 'acicala',
  acicale: 'acicale',
  acicalo: 'acicalo',
  acicata: 'acicata',
  acicate: 'acicate',
  acicato: 'acicato',
  aciclia: 'aciclia',
  acicoca: 'acicoca',
  acicula: 'acícula',
  acicule: 'acicule',
  aciculo: 'aciculo',
  acidada: 'acidada',
  acidade: 'acidade',
  acidado: 'acidado',
  acidais: 'acidais',
  acidamo: 'acidamo',
  acidara: 'acidara',
  acidava: 'acidava',
  acideis: 'acideis',
  acidemo: 'acidemo',
  acidias: 'acídias',
  acidica: 'acídica',
  acidico: 'acídico',
  acidilo: 'acidilo',
  acidois: 'acidóis',
  acidose: 'acidose',
  acidoto: 'acidoto',
  acidrai: 'acidrai',
  acidram: 'acidram',
  acidrar: 'acidrar',
  acidras: 'acidras',
  acidrei: 'acidrei',
  acidrem: 'acidrem',
  acidres: 'acidres',
  acidrou: 'acidrou',
  acidula: 'acidula',
  acidule: 'acidule',
  acidulo: 'acídulo',
  acieses: 'acieses',
  aciesia: 'aciesia',
  acifilo: 'acifilo',
  acigana: 'acigana',
  acigane: 'acigane',
  acigano: 'acigano',
  acilada: 'acilada',
  acilado: 'acilado',
  acilais: 'acilais',
  acilamo: 'acilamo',
  acilara: 'acilara',
  acilava: 'acilava',
  acileis: 'acileis',
  acilemo: 'acilemo',
  acileno: 'acileno',
  acilepo: 'acilepo',
  acilhai: 'acilhai',
  acilham: 'acilham',
  acilhar: 'acilhar',
  acilhas: 'acilhas',
  acilhei: 'acilhei',
  acilhem: 'acilhem',
  acilhes: 'acilhes',
  acilhou: 'acilhou',
  acilias: 'acilias',
  acilrai: 'acilrai',
  acilram: 'acilram',
  acilrar: 'acilrar',
  acilras: 'acilras',
  acilrei: 'acilrei',
  acilrem: 'acilrem',
  acilres: 'acilres',
  acilrou: 'acilrou',
  acimada: 'acimada',
  acimado: 'acimado',
  acimais: 'acimais',
  acimamo: 'acimamo',
  acimara: 'acimara',
  acimava: 'acimava',
  acimeis: 'acimeis',
  acimemo: 'acimemo',
  acinace: 'acínace',
  acincho: 'acincho',
  acinese: 'acinese',
  acineta: 'acineta',
  acinete: 'acinete',
  acineto: 'acineto',
  acingem: 'acíngem',
  acinges: 'acinges',
  acingia: 'acingia',
  acingir: 'acingir',
  acingis: 'acingis',
  acingiu: 'acingiu',
  acinipe: 'acinipe',
  acinipo: 'acinipo',
  acinjam: 'acinjam',
  acinjas: 'acinjas',
  acinopo: 'acinopo',
  acinosa: 'acinosa',
  acinoso: 'acinoso',
  acintai: 'acintai',
  acintam: 'acintam',
  acintar: 'acintar',
  acintas: 'acintas',
  acintei: 'acintei',
  acintem: 'acintem',
  acintes: 'acintes',
  acintle: 'acintle',
  acintou: 'acintou',
  acinzai: 'acinzai',
  acinzam: 'acinzam',
  acinzar: 'acinzar',
  acinzas: 'acinzas',
  acinzei: 'acinzei',
  acinzem: 'acinzem',
  acinzes: 'acinzes',
  acinzou: 'acinzou',
  aciocas: 'aciocás',
  acionai: 'acionai',
  acional: 'acional',
  acionam: 'acionam',
  acionar: 'acionar',
  acionas: 'acionas',
  acionei: 'acionei',
  acionem: 'acionem',
  aciones: 'aciones',
  acionou: 'acionou',
  aciosas: 'aciosas',
  aciotos: 'aciotos',
  acirate: 'acirate',
  acirrai: 'acirrai',
  acirram: 'acirram',
  acirrar: 'acirrar',
  acirras: 'acirras',
  acirrei: 'acirrei',
  acirrem: 'acirrem',
  acirres: 'acirres',
  acirrou: 'acirrou',
  acirsas: 'acirsas',
  acisela: 'aciselá',
  acisele: 'acisele',
  aciselo: 'aciselo',
  acisias: 'acisias',
  acismos: 'acismos',
  acistas: 'acistas',
  acistia: 'acistia',
  acitara: 'acitara',
  aciumai: 'aciumai',
  aciumam: 'aciumam',
  aciumar: 'aciumar',
  aciumas: 'aciumas',
  aciumei: 'aciumei',
  aciumem: 'aciumem',
  aciumes: 'aciumes',
  aciumou: 'aciumou',
  acizana: 'acizaná',
  acizane: 'acizane',
  acizano: 'acizano',
  acizela: 'acizela',
  acizele: 'acizele',
  acizelo: 'acizelo',
  acladas: 'ácladas',
  acladio: 'acládio',
  aclados: 'áclados',
  aclamai: 'aclamai',
  aclamam: 'aclamam',
  aclamar: 'aclamar',
  aclamas: 'aclamas',
  aclamei: 'aclamei',
  aclamem: 'aclamem',
  aclames: 'aclames',
  aclamou: 'aclamou',
  aclarai: 'aclarai',
  aclaram: 'aclaram',
  aclarar: 'aclarar',
  aclaras: 'aclaras',
  aclarea: 'aclareá',
  aclarei: 'aclarei',
  aclarem: 'aclarem',
  aclares: 'aclares',
  aclarou: 'aclarou',
  aclases: 'áclases',
  aclasse: 'aclasse',
  aclasta: 'aclasta',
  aclasto: 'aclasto',
  acleias: 'acleias',
  aclesia: 'aclésia',
  aclideo: 'aclídeo',
  aclides: 'áclides',
  aclidia: 'aclídia',
  aclidio: 'aclídio',
  aclidos: 'aclidos',
  aclimai: 'aclimai',
  aclimam: 'aclimam',
  aclimar: 'aclimar',
  aclimas: 'aclimas',
  aclimei: 'aclimei',
  aclimem: 'aclimem',
  aclimes: 'aclimes',
  aclimou: 'aclimou',
  aclinal: 'aclinal',
  aclinea: 'aclínea',
  aclineo: 'aclíneo',
  aclises: 'aclises',
  aclisia: 'aclísia',
  aclivai: 'aclivai',
  aclivam: 'aclivam',
  aclivar: 'aclivar',
  aclivas: 'aclivas',
  aclivei: 'aclivei',
  aclivem: 'aclivem',
  aclives: 'aclives',
  aclivou: 'aclivou',
  aclopes: 'aclopes',
  aclopos: 'aclopos',
  aclusao: 'aclusão',
  acmeias: 'acmeias',
  acmelas: 'acmelas',
  acmenas: 'acmenas',
  acmitas: 'acmitas',
  acmites: 'acmites',
  acmitos: 'acmitos',
  acmonia: 'acmônia',
  acmonio: 'acmônio',
  acnanto: 'acnanto',
  acneias: 'acneias',
  acneica: 'acneica',
  acneico: 'acneico',
  acnemia: 'acnemia',
  acnemos: 'acnemos',
  acneria: 'acnéria',
  acneste: 'acneste',
  acnidas: 'acnidas',
  acnides: 'acnides',
  acnisto: 'acnisto',
  acnites: 'acnites',
  acnodal: 'acnodal',
  acnodos: 'acnodos',
  acnosas: 'acnosas',
  acnosos: 'acnosos',
  acoadao: 'acoadão',
  acoadas: 'acoadas',
  acoador: 'acoador',
  acoados: 'acoados',
  acoalha: 'acoalhá',
  acoalhe: 'acoalhe',
  acoalho: 'acoalho',
  acoalte: 'acoalte',
  acoamos: 'acoamos',
  acoando: 'acoando',
  acoanha: 'acoanhá',
  acoanhe: 'acoanhe',
  acoanho: 'acoanho',
  acoante: 'acoante',
  acoaram: 'acoaram',
  acoarao: 'acoarão',
  acoaras: 'acoarás',
  acoarei: 'acoarei',
  acoarem: 'acoarem',
  acoares: 'acoares',
  acoaria: 'acoaria',
  acoarmo: 'acoarmo',
  acoasse: 'acoasse',
  acoaste: 'acoaste',
  acoavam: 'acoavam',
  acoavas: 'acoavas',
  acobada: 'açobada',
  acobado: 'açobado',
  acobais: 'açobais',
  acobamo: 'açobamo',
  acobara: 'açobara',
  acobava: 'açobava',
  acobeis: 'açobeis',
  acobemo: 'açobemo',
  acobrea: 'acobreá',
  acocada: 'acocada',
  acocado: 'acocado',
  acocais: 'acocais',
  acocala: 'acocalã',
  acocamo: 'acocamo',
  acocara: 'acocara',
  acocare: 'acocare',
  acocaro: 'acocaro',
  acocava: 'acocava',
  acoceis: 'acoceis',
  acocemo: 'acocemo',
  acochai: 'acochai',
  acocham: 'acocham',
  acochar: 'acochar',
  acochas: 'acochas',
  acochei: 'acochei',
  acochem: 'acochem',
  acoches: 'acoches',
  acochou: 'acochou',
  acocora: 'acocora',
  acocore: 'acocore',
  acocoro: 'acocoro',
  acocoto: 'acocoto',
  acodada: 'açodada',
  acodado: 'açodado',
  acodais: 'açodais',
  acodamo: 'açodamo',
  acodara: 'açodara',
  acodava: 'açodava',
  acodeis: 'açodeis',
  acodemo: 'açodemo',
  acoeira: 'açoeira',
  acoeiro: 'açoeiro',
  acoelas: 'acoelas',
  acoelha: 'acoelha',
  acoelhe: 'acoelhe',
  acoelho: 'acoelho',
  acoelio: 'acoélio',
  acoemos: 'acoemos',
  acofiai: 'acofiai',
  acofiam: 'acofiam',
  acofiar: 'acofiar',
  acofias: 'acofias',
  acofiei: 'acofiei',
  acofiem: 'acofiem',
  acofies: 'acofies',
  acofiou: 'acofiou',
  acofras: 'açofras',
  acogbas: 'açogbás',
  acogula: 'acogula',
  acogule: 'acogule',
  acogulo: 'acogulo',
  acoiaba: 'açoiaba',
  acoicai: 'acoiçai',
  acoicam: 'acoiçam',
  acoicar: 'acoiçar',
  acoicas: 'acoiças',
  acoicea: 'acoiceá',
  acoicei: 'acoicei',
  acoicem: 'acoicem',
  acoices: 'acoices',
  acoicou: 'acoiçou',
  acoimai: 'acoimai',
  acoimam: 'acoimam',
  acoimar: 'acoimar',
  acoimas: 'acoimas',
  acoimei: 'acoimei',
  acoimem: 'acoimem',
  acoimes: 'acoimes',
  acoimou: 'acoimou',
  acoinai: 'acoinai',
  acoinam: 'acoinam',
  acoinar: 'acoinar',
  acoinas: 'acoinas',
  acoinei: 'acoinei',
  acoinem: 'acoinem',
  acoines: 'acoines',
  acoinol: 'acoinol',
  acoinou: 'acoinou',
  acoitai: 'açoitai',
  acoitam: 'açoitam',
  acoitar: 'açoitar',
  acoitas: 'açoitas',
  acoitei: 'açoitei',
  acoitem: 'açoitem',
  acoites: 'açoites',
  acoitou: 'açoitou',
  acolada: 'acolada',
  acolado: 'acolado',
  acolais: 'acolais',
  acolala: 'acolalã',
  acolamo: 'acolamo',
  acolara: 'acolará',
  acolaus: 'acolaús',
  acolava: 'acolava',
  acoleis: 'acoleis',
  acolejo: 'acolejo',
  acolemo: 'acolemo',
  acoleta: 'acoleta',
  acolete: 'acolete',
  acoleto: 'acoleto',
  acolham: 'acolham',
  acolhas: 'acolhas',
  acolhei: 'acolhei',
  acolhem: 'acolhem',
  acolher: 'acolher',
  acolhes: 'acolhes',
  acolheu: 'acolheu',
  acolhia: 'acolhia',
  acolias: 'acolias',
  acolico: 'acólico',
  acolins: 'acolins',
  acolita: 'acólita',
  acolite: 'acolite',
  acolito: 'acólito',
  acoluna: 'acoluna',
  acolune: 'acolune',
  acoluno: 'acoluno',
  acometa: 'acometa',
  acomete: 'acomete',
  acometi: 'acometi',
  acometo: 'acometo',
  acomias: 'acomias',
  acomica: 'acômica',
  acomico: 'acômico',
  acomoda: 'acomoda',
  acomode: 'acomode',
  acomodo: 'acômodo',
  acomuna: 'acomuna',
  acomune: 'acomune',
  acomuno: 'acomuno',
  aconapa: 'aconapá',
  aconape: 'aconape',
  aconapo: 'aconapo',
  aconato: 'aconato',
  aconcha: 'aconcha',
  aconche: 'aconche',
  aconcho: 'aconcho',
  aconcio: 'acôncio',
  aconela: 'aconela',
  aconeta: 'aconetá',
  aconete: 'aconete',
  aconeto: 'aconeto',
  aconina: 'aconina',
  aconita: 'aconita',
  aconito: 'acônito',
  acontia: 'acontia',
  acontie: 'acontie',
  acontio: 'acôntio',
  acontos: 'acontos',
  acoplai: 'acoplai',
  acoplam: 'acoplam',
  acoplar: 'acoplar',
  acoplas: 'acoplas',
  acoplei: 'acoplei',
  acoplem: 'acoplem',
  acoples: 'acoples',
  acoplou: 'acoplou',
  acoposa: 'acoposa',
  acoposo: 'acoposo',
  acopras: 'ácopras',
  acopros: 'ácopros',
  acopula: 'acopulá',
  acopule: 'acopule',
  acopulo: 'acopulo',
  acoquei: 'acoquei',
  acoquem: 'acoquem',
  acoques: 'acoques',
  acorada: 'açorada',
  acorado: 'açorado',
  acorais: 'açorais',
  acoramo: 'açoramo',
  acorara: 'açorará',
  acorava: 'açorava',
  acorcoa: 'acorçoa',
  acorcoe: 'acorçoe',
  acorcoo: 'acorçoo',
  acordai: 'acordai',
  acordal: 'acordal',
  acordam: 'acordam',
  acordao: 'acórdão',
  acordar: 'acordar',
  acordas: 'acordas',
  acordei: 'acordei',
  acordem: 'acordem',
  acordes: 'acordes',
  acordoa: 'acordoa',
  acordoe: 'acordoe',
  acordoo: 'acordoo',
  acordos: 'acordos',
  acordou: 'acordou',
  acoreia: 'acoreia',
  acoreis: 'açoreis',
  acoremo: 'açoremo',
  acorese: 'acorese',
  acorias: 'acorias',
  acorica: 'açórica',
  acorico: 'açórico',
  acorina: 'acorina',
  acorino: 'açorino',
  acorita: 'açorita',
  acorite: 'açorite',
  acorito: 'açorito',
  acormia: 'acormia',
  acormos: 'acormos',
  acornai: 'acornai',
  acornam: 'acornam',
  acornar: 'acornar',
  acornas: 'acornas',
  acornea: 'acorneá',
  acornei: 'acornei',
  acornem: 'acornem',
  acornes: 'acornes',
  acornou: 'acornou',
  acoroas: 'acoroás',
  acoroca: 'acoroçá',
  acoroce: 'acoroce',
  acoroco: 'acoroço',
  acorram: 'acorram',
  acorras: 'acorras',
  acorrei: 'acorrei',
  acorrem: 'acorrem',
  acorrer: 'acorrer',
  acorres: 'acorres',
  acorreu: 'acorreu',
  acorria: 'acorria',
  acoruto: 'acoruto',
  acorzao: 'açorzão',
  acosmas: 'acosmas',
  acosmia: 'acosmia',
  acosmos: 'acosmos',
  acossai: 'acossai',
  acossam: 'acossam',
  acossar: 'acossar',
  acossas: 'acossas',
  acossei: 'acossei',
  acossem: 'acossem',
  acosses: 'acosses',
  acossou: 'acossou',
  acostai: 'acostai',
  acostam: 'acostam',
  acostar: 'acostar',
  acostas: 'acostas',
  acostei: 'acostei',
  acostem: 'acostem',
  acostes: 'acostes',
  acostos: 'acostos',
  acostou: 'acostou',
  acotada: 'acotada',
  acotado: 'acotado',
  acotais: 'acotais',
  acotamo: 'acotamo',
  acotara: 'acotará',
  acotava: 'acotava',
  acoteai: 'açoteai',
  acotear: 'açotear',
  acoteei: 'açoteei',
  acoteia: 'açoteia',
  acoteie: 'açoteie',
  acoteio: 'açoteio',
  acoteis: 'acoteis',
  acotemo: 'acotemo',
  acoteou: 'açoteou',
  acotiai: 'acotiai',
  acotiam: 'acotiam',
  acotiar: 'acotiar',
  acotias: 'acotias',
  acotica: 'acotica',
  acotico: 'acotico',
  acotiei: 'acotiei',
  acotiem: 'acotiem',
  acoties: 'acoties',
  acotilo: 'acótilo',
  acotiou: 'acotiou',
  acotoai: 'acotoai',
  acotoam: 'acotoam',
  acotoar: 'acotoar',
  acotoas: 'acotoas',
  acotoei: 'acotoei',
  acotoem: 'acotoem',
  acotoes: 'acotoes',
  acotona: 'acotona',
  acotone: 'acotone',
  acotono: 'acotono',
  acotoou: 'acotoou',
  acouber: 'acouber',
  acoucai: 'acouçai',
  acoucam: 'acouçam',
  acoucar: 'acouçar',
  acoucas: 'acouças',
  acoucea: 'acouceá',
  acoucei: 'acoucei',
  acoucem: 'acoucem',
  acouces: 'acouces',
  acoucou: 'acouçou',
  acougue: 'açougue',
  acoutai: 'açoutai',
  acoutam: 'açoutam',
  acoutar: 'açoutar',
  acoutas: 'açoutas',
  acoutei: 'açoutei',
  acoutem: 'acoutem',
  acoutes: 'açoutes',
  acoutis: 'acoutis',
  acoutou: 'acoutou',
  acovada: 'acovada',
  acovado: 'acovado',
  acovais: 'acovais',
  acovamo: 'acovamo',
  acovara: 'acovará',
  acovata: 'acovatá',
  acovate: 'acovate',
  acovato: 'acovato',
  acovava: 'acovava',
  acoveis: 'acoveis',
  acovemo: 'acovemo',
  acpalos: 'acpalôs',
  acracia: 'acracia',
  acracne: 'acracne',
  acragas: 'ácragas',
  acramas: 'acramas',
  acrania: 'acrania',
  acranio: 'acrânio',
  acranta: 'acranta',
  acranto: 'acranto',
  acrasia: 'acrasia',
  acratas: 'acratas',
  acratos: 'acratos',
  acravai: 'acravai',
  acravam: 'acravam',
  acravar: 'acravar',
  acravas: 'acravas',
  acravei: 'acravei',
  acravem: 'acravem',
  acraves: 'acraves',
  acravou: 'acravou',
  acreano: 'acreano',
  acrecam: 'acreçam',
  acrecao: 'acreção',
  acrecas: 'acreças',
  acrecei: 'acrecei',
  acrecem: 'acrecem',
  acrecer: 'acrecer',
  acreces: 'acreces',
  acreceu: 'acreceu',
  acrecia: 'acrecia',
  acredor: 'acredor',
  acreias: 'acreias',
  acrense: 'acrense',
  acresca: 'acresça',
  acresce: 'acresce',
  acresci: 'acresci',
  acresco: 'acresço',
  acretas: 'acretas',
  acretos: 'acretos',
  acriana: 'acriana',
  acriano: 'acriano',
  acribia: 'acribia',
  acridao: 'acridão',
  acridas: 'ácridas',
  acridea: 'acrídea',
  acrideo: 'acrídeo',
  acridez: 'acridez',
  acridia: 'acrídia',
  acridio: 'acrídio',
  acrinia: 'acrinia',
  acrises: 'acrises',
  acrisia: 'acrisia',
  acritos: 'ácritos',
  acriulo: 'acriúlo',
  acrivai: 'acrivai',
  acrivam: 'acrivam',
  acrivar: 'acrivar',
  acrivas: 'acrivas',
  acrivei: 'acrivei',
  acrivem: 'acrivem',
  acrives: 'acrives',
  acrivou: 'acrivou',
  acroama: 'acroama',
  acroase: 'acroase',
  acroata: 'acroata',
  acrocha: 'acrocha',
  acroche: 'acroche',
  acrocho: 'acrocho',
  acrodes: 'ácrodes',
  acrodos: 'ácrodos',
  acroita: 'acroíta',
  acroite: 'acroíte',
  acroito: 'acroíto',
  acromas: 'acromas',
  acromia: 'acromia',
  acromio: 'acrômio',
  acromos: 'acromos',
  acronas: 'ácronas',
  acronia: 'acronia',
  acronos: 'ácronos',
  acroses: 'acroses',
  actante: 'actante',
  acteana: 'actéana',
  acteano: 'actéano',
  actebia: 'actébia',
  acteias: 'acteias',
  actenas: 'actenas',
  acteoes: 'acteões',
  actiaca: 'actíaca',
  actiaco: 'actíaco',
  actidia: 'actídia',
  actidio: 'actídio',
  actigeo: 'actígeo',
  actinas: 'actinas',
  actinia: 'actínia',
  actinio: 'actínio',
  actinon: 'actínon',
  actinos: 'actinos',
  actites: 'actites',
  activar: 'activar',
  activos: 'activos',
  actuoso: 'actuoso',
  acuacao: 'acuação',
  acuadao: 'acuadão',
  acuadas: 'acuadas',
  acuador: 'acuador',
  acuados: 'acuados',
  acuamos: 'acuamos',
  acuando: 'acuando',
  acuante: 'acuante',
  acuaram: 'acuaram',
  acuarao: 'acuarão',
  acuaras: 'acuaras',
  acuarei: 'acuarei',
  acuarem: 'acuarem',
  acuares: 'acuares',
  acuaria: 'acuaria',
  acuarmo: 'acuarmo',
  acuasma: 'acuasma',
  acuasse: 'acuasse',
  acuaste: 'acuaste',
  acuavam: 'acuavam',
  acuavas: 'acuavas',
  acubina: 'acubina',
  acubito: 'acúbito',
  acucara: 'açucara',
  acucare: 'açucare',
  acucaro: 'açucaro',
  acucena: 'açucena',
  acuchis: 'acuchis',
  acucres: 'açucres',
  acucula: 'acucula',
  acucule: 'acucule',
  acuculo: 'acuculo',
  acudada: 'açudada',
  acudado: 'açudado',
  acudais: 'acudais',
  acudamo: 'acudamo',
  acudara: 'açudará',
  acudava: 'açudava',
  acudeco: 'açudeco',
  acudeis: 'açudeis',
  acudemo: 'açudemo',
  acudiam: 'acudiam',
  acudias: 'acudias',
  acudida: 'acudida',
  acudido: 'acudido',
  acudimo: 'acudimo',
  acudira: 'acudira',
  acudres: 'açudres',
  acuemos: 'acuemos',
  acuense: 'açuense',
  acueras: 'acueras',
  acufeno: 'acufeno',
  acugula: 'acugula',
  acugule: 'acugule',
  acugulo: 'acugulo',
  acuiari: 'acuiari',
  acuicao: 'acuição',
  acuitar: 'acuitar',
  acuiuru: 'acuiuru',
  aculada: 'açulada',
  aculado: 'açulado',
  aculais: 'açulais',
  aculamo: 'açulamo',
  aculara: 'açulará',
  aculava: 'açulava',
  aculeai: 'aculeai',
  aculear: 'aculear',
  aculeas: 'acúleas',
  aculeei: 'aculeei',
  aculeia: 'aculeia',
  aculeie: 'aculeie',
  aculeio: 'aculeio',
  aculeis: 'açuleis',
  aculemo: 'açulemo',
  aculeos: 'acúleos',
  aculeou: 'aculeou',
  acumbai: 'acumbai',
  acumbam: 'acumbam',
  acumbar: 'acumbar',
  acumbas: 'acumbas',
  acumbei: 'acumbei',
  acumbem: 'acumbem',
  acumbes: 'acumbes',
  acumbou: 'acumbou',
  acumbre: 'açumbre',
  acumeai: 'acumeai',
  acumear: 'acumear',
  acumeei: 'acumeei',
  acumeia: 'acumeia',
  acumeie: 'acumeie',
  acumeio: 'acumeio',
  acumens: 'acúmens',
  acumeou: 'acumeou',
  acumina: 'acumina',
  acumine: 'acumine',
  acumino: 'acumino',
  acumoes: 'acumões',
  acumula: 'acumula',
  acumule: 'acumule',
  acumulo: 'acúmulo',
  acunati: 'acunati',
  acunhai: 'acunhai',
  acunham: 'acunham',
  acunhar: 'acunhar',
  acunhas: 'acunhas',
  acunhea: 'acunheá',
  acunhei: 'acunhei',
  acunhem: 'acunhem',
  acunhes: 'acunhes',
  acunhou: 'acunhou',
  acurada: 'acurada',
  acurado: 'acurado',
  acurais: 'acurais',
  acuramo: 'acuramo',
  acurana: 'acurana',
  acurara: 'acurará',
  acuraua: 'acuraua',
  acuraus: 'acuraus',
  acurava: 'acurava',
  acurebe: 'acurebe',
  acureis: 'acureis',
  acuremo: 'acuremo',
  acurios: 'acuriós',
  acurrai: 'acurrai',
  acurram: 'acurram',
  acurrar: 'acurrar',
  acurras: 'acurras',
  acurrei: 'acurrei',
  acurrem: 'acurrem',
  acurres: 'acurres',
  acurrou: 'acurrou',
  acursai: 'acursai',
  acursam: 'acursam',
  acursar: 'acursar',
  acursas: 'acursas',
  acursei: 'acursei',
  acursem: 'acursem',
  acurses: 'acurses',
  acursos: 'acursos',
  acursou: 'acursou',
  acurtai: 'acurtai',
  acurtam: 'acurtam',
  acurtar: 'acurtar',
  acurtas: 'acurtas',
  acurtei: 'acurtei',
  acurtem: 'acurtem',
  acurtes: 'acurtes',
  acurtou: 'acurtou',
  acuruis: 'acuruís',
  acurvai: 'acurvai',
  acurvam: 'acurvam',
  acurvar: 'acurvar',
  acurvas: 'acurvas',
  acurvei: 'acurvei',
  acurvem: 'acurvem',
  acurves: 'acurves',
  acurvou: 'acurvou',
  acusada: 'acusada',
  acusado: 'acusado',
  acusais: 'acusais',
  acusamo: 'acusamo',
  acusara: 'acusara',
  acusava: 'acusava',
  acuseis: 'acuseis',
  acusemo: 'acusemo',
  acusias: 'acusias',
  acusmas: 'acusmas',
  acustos: 'acustos',
  acutais: 'acutais',
  acutela: 'acutelá',
  acutele: 'acutele',
  acutelo: 'acutelo',
  acutiai: 'acutiai',
  acutiam: 'acutiam',
  acutiar: 'acutiar',
  acutias: 'acutias',
  acutiei: 'acutiei',
  acutiem: 'acutiem',
  acuties: 'acuties',
  acutila: 'acutila',
  acutile: 'acutile',
  acutilo: 'acutilo',
  acutiou: 'acutiou',
  acutiza: 'acutizá',
  acutize: 'acutize',
  acutizo: 'acutizo',
  acuubas: 'acuubas',
  adabias: 'adábias',
  adafina: 'adafina',
  adagada: 'adagada',
  adagiai: 'adagiai',
  adagial: 'adagial',
  adagiam: 'adagiam',
  adagiar: 'adagiar',
  adagias: 'adagias',
  adagiei: 'adagiei',
  adagiem: 'adagiem',
  adagies: 'adagies',
  adagios: 'adágios',
  adagiou: 'adagiou',
  adagues: 'adagues',
  adaiado: 'adaiado',
  adaioes: 'adaiões',
  adajibe: 'adajibe',
  adalbos: 'adalbos',
  adalide: 'adalide',
  adalita: 'adalita',
  adamada: 'adamada',
  adamado: 'adamado',
  adamais: 'adamais',
  adamamo: 'adamamo',
  adamane: 'adamane',
  adamara: 'adamara',
  adamava: 'adamava',
  adameas: 'adâmeas',
  adameis: 'adameis',
  adamemo: 'adamemo',
  adameos: 'adâmeos',
  adamias: 'adâmias',
  adamica: 'adâmica',
  adamico: 'adâmico',
  adamita: 'adamita',
  adamite: 'adamite',
  adamona: 'adamona',
  adanada: 'adanada',
  adanado: 'adanado',
  adanais: 'adanais',
  adanamo: 'adanamo',
  adanara: 'adanará',
  adanava: 'adanava',
  adandus: 'adandus',
  adaneis: 'adaneis',
  adanemo: 'adanemo',
  adapide: 'adápide',
  adaptai: 'adaptai',
  adaptam: 'adaptam',
  adaptar: 'adaptar',
  adaptas: 'adaptas',
  adaptei: 'adaptei',
  adaptem: 'adaptem',
  adaptes: 'adaptes',
  adaptou: 'adaptou',
  adarbes: 'adarbes',
  adarces: 'adarces',
  adargai: 'adargai',
  adargam: 'adargam',
  adargar: 'adargar',
  adargas: 'adargas',
  adargou: 'adargou',
  adargue: 'adargue',
  adarige: 'adárige',
  adarmes: 'adarmes',
  adarrum: 'adarrum',
  adarvai: 'adarvai',
  adarvam: 'adarvam',
  adarvar: 'adarvar',
  adarvas: 'adarvas',
  adarvei: 'adarvei',
  adarvem: 'adarvem',
  adarves: 'adarves',
  adarvou: 'adarvou',
  adastra: 'adastra',
  adastre: 'adastre',
  adastro: 'adastro',
  adatila: 'adátila',
  adatilo: 'adátilo',
  adaucta: 'adaucta',
  adaucto: 'adaucto',
  adaxial: 'adaxial',
  adeante: 'adeante',
  adectas: 'adectas',
  adectos: 'adectos',
  adefaga: 'adéfaga',
  adefago: 'adéfago',
  adefora: 'adefora',
  adegada: 'adegada',
  adegado: 'adegado',
  adegais: 'adegais',
  adegara: 'adegara',
  adegava: 'adegava',
  adeguei: 'adeguei',
  adeguem: 'adeguem',
  adegues: 'adegues',
  adeiina: 'adeiina',
  adeinas: 'adeínas',
  adeiras: 'adeiras',
  adeiros: 'adeiros',
  adeismo: 'adeísmo',
  adeista: 'adeísta',
  adeitos: 'adeitos',
  adejada: 'adejada',
  adejado: 'adejado',
  adejais: 'adejais',
  adejamo: 'adejamo',
  adejara: 'adejará',
  adejava: 'adejava',
  adejeis: 'adejeis',
  adejemo: 'adejemo',
  adeleia: 'adeleia',
  adelfal: 'adelfal',
  adelfas: 'adelfas',
  adelfia: 'adelfia',
  adelfos: 'adelfos',
  adelgai: 'adelgai',
  adelgam: 'adelgam',
  adelgar: 'adelgar',
  adelgas: 'adelgas',
  adelgou: 'adelgou',
  adelgue: 'adelgue',
  adelhao: 'adelhão',
  adelhas: 'adelhas',
  adelida: 'adélida',
  adelido: 'adélido',
  adelita: 'adelita',
  adelite: 'adelite',
  adelito: 'adelito',
  adelope: 'adelope',
  adelosa: 'adelosa',
  adelzao: 'adelzão',
  ademaes: 'ademães',
  ademais: 'ademais',
  ademane: 'ademane',
  adencao: 'adenção',
  adendas: 'adendas',
  adendos: 'adendos',
  adengai: 'adengai',
  adengam: 'adengam',
  adengar: 'adengar',
  adengas: 'adengas',
  adengou: 'adengou',
  adengue: 'adengue',
  adenias: 'adenias',
  adenica: 'adênica',
  adenico: 'adênico',
  adenina: 'adenina',
  adenios: 'adênios',
  adenite: 'adenite',
  adeniza: 'adeniza',
  adenize: 'adenize',
  adenizo: 'adenizo',
  adenoma: 'adenoma',
  adenope: 'adenope',
  adenosa: 'adenosa',
  adenose: 'adenose',
  adenoso: 'adenoso',
  adenoto: 'adenoto',
  adensai: 'adensai',
  adensam: 'adensam',
  adensar: 'adensar',
  adensas: 'adensas',
  adensei: 'adensei',
  adensem: 'adensem',
  adenses: 'adenses',
  adensou: 'adensou',
  adentai: 'adentai',
  adental: 'adental',
  adentam: 'adentam',
  adentar: 'adentar',
  adentas: 'adentas',
  adentei: 'adentei',
  adentem: 'adentem',
  adentes: 'adentes',
  adentou: 'adentou',
  adentra: 'adentra',
  adentre: 'adentre',
  adentro: 'adentro',
  adeorbe: 'adeorbe',
  adepcao: 'adepção',
  adeptas: 'adeptas',
  adeptos: 'adeptos',
  adequai: 'adequai',
  adequam: 'adéquam',
  adequar: 'adequar',
  adequas: 'adéquas',
  adequei: 'adequei',
  adequem: 'adéquem',
  adeques: 'adéques',
  adequou: 'adequou',
  aderada: 'aderada',
  aderado: 'aderado',
  aderais: 'aderais',
  aderamo: 'aderamo',
  aderara: 'aderará',
  aderava: 'aderava',
  adereca: 'adereça',
  aderece: 'aderece',
  adereco: 'adereço',
  adereis: 'adereis',
  aderemo: 'aderemo',
  adergai: 'adergai',
  adergam: 'adergam',
  adergar: 'adergar',
  adergas: 'adergas',
  adergou: 'adergou',
  adergue: 'adergue',
  aderiam: 'aderiam',
  aderias: 'aderias',
  aderida: 'aderida',
  aderido: 'aderido',
  aderimo: 'aderimo',
  aderira: 'aderira',
  adermia: 'adermia',
  adernai: 'adernai',
  adernal: 'adernal',
  adernam: 'adernam',
  adernar: 'adernar',
  adernas: 'adernas',
  adernei: 'adernei',
  adernem: 'adernem',
  adernes: 'adernes',
  adernos: 'adernos',
  adernou: 'adernou',
  adesina: 'adesina',
  adesiva: 'adesiva',
  adesivo: 'adesivo',
  adesmia: 'adesmia',
  adesoes: 'adesões',
  adesois: 'adesóis',
  adesona: 'adesona',
  adesora: 'adesorá',
  adesore: 'adesore',
  adesoro: 'adesoro',
  adestra: 'adestra',
  adestre: 'adestre',
  adestro: 'adestro',
  adeusai: 'adeusai',
  adeusam: 'adeusam',
  adeusar: 'adeusar',
  adeusas: 'adeusas',
  adeusei: 'adeusei',
  adeusem: 'adeusem',
  adeuses: 'adeuses',
  adeusou: 'adeusou',
  adevoes: 'adevões',
  adforma: 'adformá',
  adforme: 'adforme',
  adformo: 'adformo',
  adherir: 'adherir',
  adhesao: 'adhesão',
  adiadao: 'adiadão',
  adiadas: 'adiadas',
  adiador: 'adiador',
  adiados: 'adiados',
  adiafas: 'adiafas',
  adiamos: 'adiamos',
  adianas: 'adianas',
  adiando: 'adiando',
  adianta: 'adianta',
  adiante: 'adiante',
  adianto: 'adianto',
  adiaram: 'adiaram',
  adiarao: 'adiarão',
  adiaras: 'adiaras',
  adiarei: 'adiarei',
  adiarem: 'adiarem',
  adiares: 'adiares',
  adiaria: 'adiaria',
  adiarmo: 'adiarmo',
  adiasse: 'adiasse',
  adiaste: 'adiaste',
  adiavam: 'adiavam',
  adiavas: 'adiavas',
  adiavel: 'adiável',
  adibais: 'adibais',
  adibate: 'adibate',
  adibuda: 'adibuda',
  adicada: 'adicada',
  adicado: 'adicado',
  adicais: 'adicais',
  adicamo: 'adicamo',
  adicara: 'adicara',
  adicava: 'adicava',
  adiccao: 'adicção',
  adicica: 'adiciça',
  adicoes: 'adições',
  adicona: 'adiçona',
  adictas: 'adictas',
  adictos: 'adictos',
  adidoes: 'adidões',
  adidona: 'adidona',
  adidora: 'adidora',
  adiemos: 'adiemos',
  adietai: 'adietai',
  adietam: 'adietam',
  adietar: 'adietar',
  adietas: 'adietas',
  adietei: 'adietei',
  adietem: 'adietem',
  adietes: 'adietes',
  adietou: 'adietou',
  adilada: 'adilada',
  adilado: 'adilado',
  adilais: 'adilais',
  adilamo: 'adilamo',
  adilara: 'adilara',
  adilava: 'adilava',
  adileis: 'adileis',
  adilemo: 'adilemo',
  adilzao: 'adilzão',
  adimpla: 'adimpla',
  adimple: 'adimple',
  adimpli: 'adimpli',
  adimplo: 'adimplo',
  adinama: 'adínama',
  adinamo: 'adínamo',
  adinhos: 'adinhos',
  adinole: 'adínole',
  adionio: 'adiônio',
  adionte: 'adionte',
  adipati: 'adipati',
  adipato: 'adipato',
  adipica: 'adípica',
  adipico: 'adípico',
  adipita: 'adipita',
  adipite: 'adipite',
  adipois: 'adipóis',
  adipoma: 'adipoma',
  adiposa: 'adiposa',
  adipose: 'adipose',
  adiposo: 'adiposo',
  adipsia: 'adipsia',
  adipsos: 'adipsos',
  adiquei: 'adiquei',
  adiquem: 'adiquem',
  adiques: 'adiques',
  adirais: 'adirais',
  adiraja: 'adirajá',
  adiramo: 'adiramo',
  adirdes: 'adirdes',
  adireis: 'adireis',
  adiremo: 'adiremo',
  adiriam: 'adiriam',
  adirias: 'adirias',
  adirmos: 'adirmos',
  adiscal: 'adiscal',
  adiscos: 'adiscos',
  adisios: 'adísios',
  adissem: 'adissem',
  adisses: 'adisses',
  adistes: 'adistes',
  aditada: 'aditada',
  aditado: 'aditado',
  aditais: 'aditais',
  aditamo: 'aditamo',
  aditara: 'aditara',
  aditava: 'aditava',
  aditeis: 'aditeis',
  aditemo: 'aditemo',
  aditias: 'adítias',
  aditiva: 'aditiva',
  aditivo: 'aditivo',
  aditora: 'aditora',
  adivais: 'adivais',
  adivira: 'adivira',
  adjazam: 'adjazam',
  adjazas: 'adjazas',
  adjazei: 'adjazei',
  adjazem: 'adjazem',
  adjazer: 'adjazer',
  adjazes: 'adjazes',
  adjazeu: 'adjazeu',
  adjazia: 'adjazia',
  adjecao: 'adjeção',
  adjecto: 'adjecto',
  adjeres: 'adjeres',
  adjetas: 'adjetas',
  adjetos: 'adjetos',
  adjigar: 'adjigar',
  adjonge: 'adjonge',
  adjudai: 'adjudai',
  adjudam: 'adjudam',
  adjudar: 'adjudar',
  adjudas: 'adjudas',
  adjudei: 'adjudei',
  adjudem: 'adjudem',
  adjudes: 'adjudes',
  adjudou: 'adjudou',
  adjugar: 'adjugar',
  adjunge: 'adjunge',
  adjungi: 'adjungi',
  adjunja: 'adjunja',
  adjunjo: 'adjunjo',
  adjunta: 'adjunta',
  adjunte: 'adjunte',
  adjunto: 'adjunto',
  adjurai: 'adjurai',
  adjuram: 'adjuram',
  adjurar: 'adjurar',
  adjuras: 'adjuras',
  adjurei: 'adjurei',
  adjurem: 'adjurem',
  adjures: 'adjures',
  adjurou: 'adjurou',
  adjutor: 'adjutor',
  adjuvai: 'adjuvai',
  adjuvam: 'adjuvam',
  adjuvar: 'adjuvar',
  adjuvas: 'adjuvas',
  adjuvei: 'adjuvei',
  adjuvem: 'adjuvem',
  adjuves: 'adjuves',
  adjuvou: 'adjuvou',
  adlecto: 'adlecto',
  adleria: 'adléria',
  adligai: 'adligai',
  adligam: 'adligam',
  adligar: 'adligar',
  adligas: 'adligas',
  adligou: 'adligou',
  adligue: 'adligue',
  adlumia: 'adlúmia',
  adlumio: 'adlúmio',
  admirai: 'admirai',
  admiram: 'admiram',
  admirar: 'admirar',
  admiras: 'admiras',
  admirei: 'admirei',
  admirem: 'admirem',
  admires: 'admires',
  admirou: 'admirou',
  admitam: 'admitam',
  admitas: 'admitas',
  admitem: 'admitem',
  admites: 'admites',
  admitia: 'admitia',
  admitir: 'admitir',
  admitis: 'admitis',
  admitiu: 'admitiu',
  admonam: 'admonam',
  admonas: 'admonas',
  admonem: 'admonem',
  admones: 'admones',
  admonia: 'admonia',
  admonir: 'admonir',
  admonis: 'admonis',
  admoniu: 'admoniu',
  adnasal: 'adnasal',
  adnatas: 'adnatas',
  adnatos: 'adnatos',
  adnexao: 'adnexão',
  adnexas: 'adnexas',
  adnexos: 'adnexos',
  adnomes: 'adnomes',
  adnotai: 'adnotai',
  adnotam: 'adnotam',
  adnotar: 'adnotar',
  adnotas: 'adnotas',
  adnotei: 'adnotei',
  adnotem: 'adnotem',
  adnotes: 'adnotes',
  adnotou: 'adnotou',
  adoadao: 'adoadão',
  adoadas: 'adoadas',
  adoador: 'adoador',
  adoados: 'adoados',
  adoamos: 'adoamos',
  adoando: 'adoando',
  adoante: 'adoante',
  adoaram: 'adoaram',
  adoarao: 'adoarão',
  adoaras: 'adoarás',
  adoarei: 'adoarei',
  adoarem: 'adoarem',
  adoares: 'adoares',
  adoaria: 'adoaria',
  adoarmo: 'adoarmo',
  adoasse: 'adoasse',
  adoaste: 'adoaste',
  adoavam: 'adoavam',
  adoavas: 'adoavas',
  adobada: 'adobada',
  adobado: 'adobado',
  adobais: 'adobais',
  adobale: 'adobale',
  adobamo: 'adobamo',
  adobara: 'adobará',
  adobava: 'adobava',
  adobeis: 'adobeis',
  adobemo: 'adobemo',
  adocada: 'adoçada',
  adocado: 'adoçado',
  adocais: 'adoçais',
  adocamo: 'adoçamo',
  adocara: 'adoçará',
  adocava: 'adoçava',
  adoceis: 'adoceis',
  adocemo: 'adocemo',
  adocica: 'adocica',
  adocico: 'adocico',
  adocoes: 'adoções',
  adocona: 'adoçona',
  adoecam: 'adoeçam',
  adoecas: 'adoeças',
  adoecei: 'adoecei',
  adoecem: 'adoecem',
  adoecer: 'adoecer',
  adoeces: 'adoeces',
  adoeceu: 'adoeceu',
  adoecia: 'adoecia',
  adoemos: 'adoemos',
  adoenta: 'adoenta',
  adoente: 'adoente',
  adoento: 'adoento',
  adoesta: 'adoesta',
  adoeste: 'adoeste',
  adoesto: 'adoesto',
  adofiro: 'adofiró',
  adoidai: 'adoidai',
  adoidam: 'adoidam',
  adoidar: 'adoidar',
  adoidas: 'adoidas',
  adoidei: 'adoidei',
  adoidem: 'adoidem',
  adoides: 'adoides',
  adoidou: 'adoidou',
  adoleia: 'adoleia',
  adolias: 'adólias',
  adolins: 'adolins',
  adolios: 'adólios',
  adolora: 'adolora',
  adolore: 'adolore',
  adoloro: 'adoloro',
  adomada: 'adomada',
  adomado: 'adomado',
  adomais: 'adomais',
  adomamo: 'adomamo',
  adomara: 'adomará',
  adomava: 'adomava',
  adomeis: 'adomeis',
  adomemo: 'adomemo',
  adonada: 'adonada',
  adonado: 'adonado',
  adonais: 'adonais',
  adonamo: 'adonamo',
  adonara: 'adonara',
  adonava: 'adonava',
  adoneis: 'adoneis',
  adonemo: 'adonemo',
  adoneos: 'adôneos',
  adonica: 'adônica',
  adonico: 'adônico',
  adonida: 'adônida',
  adonide: 'adônide',
  adonido: 'adônido',
  adonina: 'adonina',
  adonios: 'adônios',
  adonisa: 'adonisa',
  adonise: 'adonise',
  adoniso: 'adoniso',
  adonita: 'adonita',
  adopcao: 'adopção',
  adopera: 'adopera',
  adopere: 'adopere',
  adopero: 'adopero',
  adoquim: 'adoquim',
  adorada: 'adorada',
  adorado: 'adorado',
  adorais: 'adorais',
  adoramo: 'adoramo',
  adorara: 'adorara',
  adorava: 'adorava',
  adoreia: 'adoreia',
  adoreis: 'adoreis',
  adoremo: 'adoremo',
  adoreto: 'adoreto',
  adorias: 'adoriás',
  adorios: 'adórios',
  adormem: 'adormem',
  adormes: 'adormes',
  adormia: 'adormia',
  adormir: 'adormir',
  adormis: 'adormis',
  adormiu: 'adormiu',
  adornai: 'adornai',
  adornam: 'adornam',
  adornar: 'adornar',
  adornas: 'adornas',
  adornei: 'adornei',
  adornem: 'adornem',
  adornes: 'adornes',
  adornos: 'adornos',
  adornou: 'adornou',
  adortai: 'adortai',
  adortam: 'adortam',
  adortar: 'adortar',
  adortas: 'adortas',
  adortei: 'adortei',
  adortem: 'adortem',
  adortes: 'adortes',
  adortou: 'adortou',
  adotada: 'adotada',
  adotado: 'adotado',
  adotais: 'adotais',
  adotamo: 'adotamo',
  adotara: 'adotara',
  adotava: 'adotava',
  adoteis: 'adoteis',
  adotelo: 'adotelo',
  adotemo: 'adotemo',
  adotiva: 'adotiva',
  adotivo: 'adotivo',
  adoudai: 'adoudai',
  adoudam: 'adoudam',
  adoudar: 'adoudar',
  adoudas: 'adoudas',
  adoudei: 'adoudei',
  adoudem: 'adoudem',
  adoudes: 'adoudes',
  adoudou: 'adoudou',
  adquira: 'adquira',
  adquire: 'adquire',
  adquiri: 'adquiri',
  adquiro: 'adquiro',
  adracne: 'adracne',
  adragai: 'adragai',
  adragam: 'adragam',
  adragar: 'adragar',
  adragas: 'adragas',
  adragos: 'adragos',
  adragou: 'adragou',
  adrague: 'adrague',
  adrasto: 'adrasto',
  adregai: 'adregai',
  adregam: 'adregam',
  adregar: 'adregar',
  adregas: 'adregas',
  adregou: 'adregou',
  adregue: 'adregue',
  adreira: 'adreira',
  adrenal: 'adrenal',
  adresse: 'adresse',
  adricai: 'adriçai',
  adricam: 'adriçam',
  adricar: 'adriçar',
  adricas: 'adriças',
  adricei: 'adricei',
  adricem: 'adricem',
  adrices: 'adrices',
  adricou: 'adriçou',
  adrinas: 'adrinas',
  adripia: 'adrípia',
  adrogar: 'adrogar',
  adsires: 'adsires',
  adsorve: 'adsorvê',
  adsorvi: 'adsorvi',
  aduadao: 'aduadão',
  aduadas: 'aduadas',
  aduador: 'aduador',
  aduados: 'aduados',
  aduagem: 'aduagem',
  aduamos: 'aduamos',
  aduanai: 'aduanai',
  aduanam: 'aduanam',
  aduanar: 'aduanar',
  aduanas: 'aduanas',
  aduando: 'aduando',
  aduanei: 'aduanei',
  aduanem: 'aduanem',
  aduanes: 'aduanes',
  aduanou: 'aduanou',
  aduante: 'aduante',
  aduaram: 'aduaram',
  aduarao: 'aduarão',
  aduaras: 'aduarás',
  aduarei: 'aduarei',
  aduarem: 'aduarem',
  aduares: 'aduares',
  aduaria: 'aduaria',
  aduarmo: 'aduarmo',
  aduasse: 'aduasse',
  aduaste: 'aduaste',
  aduavam: 'aduavam',
  aduavas: 'aduavas',
  adubada: 'adubada',
  adubado: 'adubado',
  adubais: 'adubais',
  adubale: 'adubale',
  adubamo: 'adubamo',
  adubara: 'adubara',
  adubava: 'adubava',
  adubeis: 'adubeis',
  adubemo: 'adubemo',
  adubiai: 'adubiai',
  adubiam: 'adubiam',
  adubiar: 'adubiar',
  adubias: 'adubias',
  adubiei: 'adubiei',
  adubiem: 'adubiem',
  adubies: 'adubies',
  adubios: 'adúbios',
  adubiou: 'adubiou',
  aducais: 'aduçais',
  aducamo: 'aduçamo',
  aduchai: 'aduchai',
  aducham: 'aducham',
  aduchar: 'aduchar',
  aduchas: 'aduchas',
  aduchei: 'aduchei',
  aduchem: 'aduchem',
  aduches: 'aduches',
  aduchou: 'aduchou',
  aduciam: 'aduciam',
  aducias: 'aducias',
  aducida: 'aducida',
  aducido: 'aducido',
  aducimo: 'aducimo',
  aducira: 'aducira',
  aducoes: 'aduções',
  aducona: 'aduçona',
  adueira: 'adueira',
  adueiro: 'adueiro',
  aduelas: 'aduelas',
  aduemos: 'aduemos',
  adufada: 'adufada',
  adufado: 'adufado',
  adufais: 'adufais',
  adufamo: 'adufamo',
  adufara: 'adufará',
  adufava: 'adufava',
  adufeis: 'adufeis',
  adufemo: 'adufemo',
  adulada: 'adulada',
  adulado: 'adulado',
  adulais: 'adulais',
  adulamo: 'adulamo',
  adulara: 'adulara',
  adulava: 'adulava',
  aduleis: 'aduleis',
  adulemo: 'adulemo',
  adulica: 'adúlica',
  adulico: 'adúlico',
  adulita: 'adulita',
  aduloes: 'adulões',
  adulona: 'adulona',
  adulosa: 'adulosa',
  aduloso: 'aduloso',
  adultas: 'adultas',
  adultos: 'adultos',
  adumada: 'adumada',
  adumado: 'adumado',
  adumais: 'adumais',
  adumamo: 'adumamo',
  adumara: 'adumará',
  adumava: 'adumava',
  adumbra: 'adumbra',
  adumbre: 'adumbre',
  adumbro: 'adumbro',
  adumeis: 'adumeis',
  adumemo: 'adumemo',
  adumera: 'adumerá',
  adumere: 'adumere',
  adumero: 'adumero',
  adunada: 'adunada',
  adunado: 'adunado',
  adunais: 'adunais',
  adunamo: 'adunamo',
  adunara: 'adunara',
  adunava: 'adunava',
  aduncai: 'aduncai',
  aduncam: 'aduncam',
  aduncar: 'aduncar',
  aduncas: 'aduncas',
  aduncos: 'aduncos',
  aduncou: 'aduncou',
  aduneis: 'aduneis',
  adunemo: 'adunemo',
  adunque: 'adunque',
  adurada: 'adurada',
  adurado: 'adurado',
  adurais: 'adurais',
  aduramo: 'aduramo',
  adurara: 'adurará',
  adurava: 'adurava',
  adureis: 'adureis',
  aduremo: 'aduremo',
  aduriam: 'aduriam',
  adurias: 'adurias',
  adurida: 'adurida',
  adurido: 'adurido',
  adurimo: 'adurimo',
  adurira: 'adurirá',
  adurmam: 'adurmam',
  adurmas: 'adurmas',
  adurois: 'aduróis',
  adussia: 'adussia',
  adustao: 'adustão',
  adustas: 'adustas',
  adustez: 'adustez',
  adustos: 'adustos',
  adutero: 'adútero',
  adutiva: 'adutiva',
  adutivo: 'adutivo',
  adutora: 'adutora',
  aduzais: 'aduzais',
  aduzamo: 'aduzamo',
  aduziam: 'aduziam',
  aduzias: 'aduzias',
  aduzida: 'aduzida',
  aduzido: 'aduzido',
  aduzimo: 'aduzimo',
  aduzira: 'aduzira',
  advenas: 'ádvenas',
  advenha: 'advenha',
  advenho: 'advenho',
  advento: 'advento',
  adverba: 'adverbá',
  adverbe: 'adverbe',
  adverbo: 'adverbo',
  adversa: 'adversa',
  adverse: 'adverse',
  adverso: 'adverso',
  adverta: 'adverta',
  adverte: 'adverte',
  adverti: 'adverti',
  adverto: 'adverto',
  advidao: 'advidão',
  advidor: 'advidor',
  adviera: 'adviera',
  advimos: 'advimos',
  advinda: 'advinda',
  advinde: 'advinde',
  advindo: 'advindo',
  advinha: 'advinha',
  advirao: 'advirão',
  adviras: 'advirás',
  advirei: 'advirei',
  advirem: 'advirem',
  advires: 'advires',
  adviria: 'adviria',
  advirta: 'advirta',
  advirto: 'advirto',
  advocai: 'advocai',
  advocam: 'advocam',
  advocar: 'advocar',
  advocas: 'advocas',
  advocou: 'advocou',
  advogai: 'advogai',
  advogam: 'advogam',
  advogar: 'advogar',
  advogas: 'advogas',
  advogou: 'advogou',
  advogue: 'advogue',
  advoque: 'advoque',
  advulta: 'advultá',
  advulte: 'advulte',
  advulto: 'advulto',
  aeciana: 'aeciana',
  aeciano: 'aeciano',
  aedineo: 'aedíneo',
  aegypti: 'aegypti',
  aeracao: 'aeração',
  aeradas: 'aeradas',
  aerador: 'aerador',
  aerados: 'aerados',
  aeragem: 'aeragem',
  aeranta: 'aeranta',
  aeranto: 'aeranto',
  aeraria: 'aerária',
  aerario: 'aerário',
  aeremia: 'aeremia',
  aeriana: 'aeriana',
  aeriano: 'aeriano',
  aeridra: 'aeridra',
  aeridro: 'aeridro',
  aerinea: 'aerínea',
  aerineo: 'aeríneo',
  aeritas: 'aeritas',
  aerizai: 'aerizai',
  aerizam: 'aerizam',
  aerizar: 'aerizar',
  aerizas: 'aerizas',
  aerizei: 'aerizei',
  aerizem: 'aerizem',
  aerizes: 'aerizes',
  aerizou: 'aerizou',
  aerobia: 'aeróbia',
  aerobio: 'aeróbio',
  aerobus: 'aeróbus',
  aerogel: 'aerogel',
  aeroide: 'aeroide',
  aeromel: 'aeromel',
  aerosol: 'aerosol',
  aerovia: 'aerovia',
  aeterno: 'aeterno',
  aeticas: 'aéticas',
  aeticos: 'aéticos',
  aetitas: 'aetitas',
  aetites: 'aetites',
  aetitos: 'aetitos',
  aetomas: 'aetomas',
  afabais: 'afabais',
  afabula: 'afabula',
  afabule: 'afabule',
  afabulo: 'afabulo',
  afacais: 'afaçais',
  afacias: 'afacias',
  afacica: 'afácica',
  afacico: 'afácico',
  afadiga: 'afadiga',
  afadigo: 'afadigo',
  afagada: 'afagada',
  afagado: 'afagado',
  afagais: 'afagais',
  afagamo: 'afagamo',
  afagara: 'afagara',
  afagava: 'afagava',
  afagias: 'afagias',
  afagica: 'afágica',
  afagico: 'afágico',
  afagosa: 'afagosa',
  afagoso: 'afagoso',
  afaguea: 'afagueá',
  afaguei: 'afaguei',
  afaguem: 'afaguem',
  afagues: 'afagues',
  afaimai: 'afaimai',
  afaimam: 'afaimam',
  afaimar: 'afaimar',
  afaimas: 'afaimas',
  afaimei: 'afaimei',
  afaimem: 'afaimem',
  afaimes: 'afaimes',
  afaimou: 'afaimou',
  afainai: 'afainai',
  afainam: 'afainam',
  afainar: 'afainar',
  afainas: 'afainas',
  afainei: 'afainei',
  afainem: 'afainem',
  afaines: 'afaines',
  afainou: 'afainou',
  afalada: 'afalada',
  afalado: 'afalado',
  afalais: 'afalais',
  afalamo: 'afalamo',
  afalara: 'afalará',
  afalava: 'afalava',
  afalcoa: 'afalcoa',
  afalcoe: 'afalcoe',
  afalcoo: 'afalcoo',
  afaleis: 'afaleis',
  afalemo: 'afalemo',
  afamada: 'afamada',
  afamado: 'afamado',
  afamais: 'afamais',
  afamamo: 'afamamo',
  afamana: 'afamana',
  afamane: 'afamane',
  afamano: 'afamano',
  afamara: 'afamara',
  afamava: 'afamava',
  afameis: 'afameis',
  afamemo: 'afamemo',
  afanada: 'afanada',
  afanado: 'afanado',
  afanais: 'afanais',
  afanamo: 'afanamo',
  afanara: 'afanara',
  afanava: 'afanava',
  afancai: 'afancai',
  afancam: 'afancam',
  afancar: 'afancar',
  afancas: 'afancas',
  afancou: 'afancou',
  afaneis: 'afaneis',
  afanemo: 'afanemo',
  afanfai: 'afanfai',
  afanfam: 'afanfam',
  afanfar: 'afanfar',
  afanfas: 'afanfas',
  afanfei: 'afanfei',
  afanfem: 'afanfem',
  afanfes: 'afanfes',
  afanfou: 'afanfou',
  afangai: 'afangai',
  afangam: 'afangam',
  afangar: 'afangar',
  afangas: 'afangas',
  afangou: 'afangou',
  afanias: 'afanias',
  afanica: 'afânica',
  afanico: 'afânico',
  afanise: 'afânise',
  afanita: 'afanita',
  afanite: 'afanite',
  afanito: 'afanito',
  afanopo: 'afanopo',
  afanosa: 'afanosa',
  afanoso: 'afanoso',
  afanque: 'afanque',
  afaquea: 'afaqueá',
  afaquia: 'afaquia',
  afarada: 'afarada',
  afarado: 'afarado',
  afarais: 'afarais',
  afaramo: 'afaramo',
  afarara: 'afarara',
  afarava: 'afarava',
  afareis: 'afareis',
  afarela: 'afarelá',
  afarele: 'afarele',
  afarelo: 'afarelo',
  afaremo: 'afaremo',
  afariam: 'afariam',
  afarias: 'afarias',
  afarola: 'afarolá',
  afarole: 'afarole',
  afarolo: 'afarolo',
  afarvai: 'afarvai',
  afarvam: 'afarvam',
  afarvar: 'afarvar',
  afarvas: 'afarvas',
  afarvei: 'afarvei',
  afarvem: 'afarvem',
  afarves: 'afarves',
  afarvou: 'afarvou',
  afasias: 'afasias',
  afasica: 'afásica',
  afasico: 'afásico',
  afastai: 'afastai',
  afastam: 'afastam',
  afastar: 'afastar',
  afastas: 'afastas',
  afastei: 'afastei',
  afastem: 'afastem',
  afastes: 'afastes',
  afastou: 'afastou',
  afatiai: 'afatiai',
  afatiam: 'afatiam',
  afatiar: 'afatiar',
  afatias: 'afatias',
  afatica: 'afática',
  afatico: 'afático',
  afatiei: 'afatiei',
  afatiem: 'afatiem',
  afaties: 'afaties',
  afatiou: 'afatiou',
  afaveco: 'afaveco',
  afaveis: 'afáveis',
  afaxina: 'afaxina',
  afaxine: 'afaxine',
  afaxino: 'afaxino',
  afazeis: 'afazeis',
  afaziam: 'afaziam',
  afazias: 'afazias',
  afeadao: 'afeadão',
  afeadas: 'afeadas',
  afeador: 'afeador',
  afeados: 'afeados',
  afeamos: 'afeamos',
  afeando: 'afeando',
  afeante: 'afeante',
  afearam: 'afearam',
  afearao: 'afearão',
  afearas: 'afearás',
  afearei: 'afearei',
  afearem: 'afearem',
  afeares: 'afeares',
  afearia: 'afearia',
  afeasse: 'afeasse',
  afeaste: 'afeaste',
  afeavam: 'afeavam',
  afeavas: 'afeavas',
  afeavel: 'afeável',
  afebril: 'afebril',
  afebris: 'afebris',
  afeccao: 'afecção',
  afecoes: 'afeções',
  afecona: 'afeçona',
  afectar: 'afectar',
  afeemos: 'afeemos',
  afegada: 'afegada',
  afegado: 'afegado',
  afegais: 'afegais',
  afegamo: 'afegamo',
  afegane: 'afegane',
  afegani: 'afegani',
  afegaos: 'afegãos',
  afegara: 'afegará',
  afegava: 'afegava',
  afeiada: 'afeiada',
  afeiado: 'afeiado',
  afeiais: 'afeiais',
  afeiamo: 'afeiamo',
  afeiara: 'afeiará',
  afeiava: 'afeiava',
  afeicao: 'afeição',
  afeicoa: 'afeiçoa',
  afeicoe: 'afeiçoe',
  afeicoo: 'afeiçoo',
  afeieis: 'afeieis',
  afeiemo: 'afeiemo',
  afeijoa: 'afeijoa',
  afeijoe: 'afeijoe',
  afeijoo: 'afeijoo',
  afeitai: 'afeitai',
  afeitam: 'afeitam',
  afeitar: 'afeitar',
  afeitas: 'afeitas',
  afeitea: 'afeiteá',
  afeitei: 'afeitei',
  afeitem: 'afeitem',
  afeites: 'afeites',
  afeitos: 'afeitos',
  afeitou: 'afeitou',
  afeleai: 'afeleai',
  afelear: 'afelear',
  afeleei: 'afeleei',
  afeleia: 'afeleia',
  afeleie: 'afeleie',
  afeleio: 'afeleio',
  afeleou: 'afeleou',
  afelios: 'afélios',
  afemeai: 'afemeai',
  afemear: 'afemear',
  afemeei: 'afemeei',
  afemeia: 'afemeia',
  afemeie: 'afemeie',
  afemeio: 'afemeio',
  afemeou: 'afemeou',
  afemias: 'afemias',
  afemica: 'afêmica',
  afemico: 'afêmico',
  afemina: 'afemina',
  afemine: 'afemine',
  afemino: 'afemino',
  aferada: 'aferada',
  aferado: 'aferado',
  aferais: 'aferais',
  aferamo: 'aferamo',
  aferara: 'aferará',
  aferava: 'aferava',
  afereis: 'afereis',
  aferemo: 'aferemo',
  aferese: 'aférese',
  aferiam: 'aferiam',
  aferias: 'aferias',
  aferida: 'aferida',
  aferide: 'aferide',
  aferido: 'aferido',
  aferimo: 'aferimo',
  aferira: 'aferira',
  aferrai: 'aferrai',
  aferram: 'aferram',
  aferrar: 'aferrar',
  aferras: 'aferras',
  aferrei: 'aferrei',
  aferrem: 'aferrem',
  aferres: 'aferres',
  aferroa: 'aferroa',
  aferroe: 'aferroe',
  aferroo: 'aferroo',
  aferros: 'aferros',
  aferrou: 'aferrou',
  afervai: 'afervai',
  afervam: 'afervam',
  afervar: 'afervar',
  afervas: 'afervas',
  afervei: 'afervei',
  afervem: 'afervem',
  aferves: 'aferves',
  afervou: 'afervou',
  afesias: 'afesias',
  afestoa: 'afestoa',
  afestoe: 'afestoe',
  afestoo: 'afestoo',
  afetada: 'afetada',
  afetado: 'afetado',
  afetais: 'afetais',
  afetamo: 'afetamo',
  afetara: 'afetara',
  afetava: 'afetava',
  afeteis: 'afeteis',
  afetemo: 'afetemo',
  afetiva: 'afetiva',
  afetivo: 'afetivo',
  afezoai: 'afezoai',
  afezoam: 'afezoam',
  afezoar: 'afezoar',
  afezoas: 'afezoas',
  afezoei: 'afezoei',
  afezoem: 'afezoem',
  afezoes: 'afezoes',
  afezoou: 'afezoou',
  affaire: 'affaire',
  afganis: 'afganis',
  afiacao: 'afiação',
  afiadao: 'afiadão',
  afiadas: 'afiadas',
  afiador: 'afiador',
  afiados: 'afiados',
  afiamos: 'afiamos',
  afianca: 'afiança',
  afiance: 'afiance',
  afianco: 'afianço',
  afiando: 'afiando',
  afiante: 'afiante',
  afiaram: 'afiaram',
  afiarao: 'afiarão',
  afiaras: 'afiaras',
  afiarei: 'afiarei',
  afiarem: 'afiarem',
  afiares: 'afiares',
  afiaria: 'afiaria',
  afiarmo: 'afiarmo',
  afiasse: 'afiasse',
  afiaste: 'afiaste',
  afiavam: 'afiavam',
  afiavas: 'afiavas',
  afiavel: 'afiável',
  aficada: 'aficada',
  aficado: 'aficado',
  aficais: 'aficais',
  aficamo: 'aficamo',
  aficara: 'aficará',
  aficava: 'aficava',
  afideas: 'afídeas',
  afideos: 'afídeos',
  afidios: 'afídios',
  afiemos: 'afiemos',
  afifada: 'afifada',
  afifado: 'afifado',
  afifais: 'afifais',
  afifamo: 'afifamo',
  afifana: 'afifana',
  afifano: 'afifano',
  afifara: 'afifará',
  afifava: 'afifava',
  afifeis: 'afifeis',
  afifemo: 'afifemo',
  afigada: 'afigada',
  afigura: 'afigura',
  afigure: 'afigure',
  afiguro: 'afiguro',
  afilada: 'afilada',
  afilado: 'afilado',
  afilais: 'afilais',
  afilamo: 'afilamo',
  afilara: 'afilara',
  afilava: 'afilava',
  afileis: 'afileis',
  afilemo: 'afilemo',
  afilhai: 'afilhai',
  afilham: 'afilham',
  afilhar: 'afilhar',
  afilhas: 'afilhas',
  afilhei: 'afilhei',
  afilhem: 'afilhem',
  afilhes: 'afilhes',
  afilhou: 'afilhou',
  afiliai: 'afiliai',
  afiliam: 'afiliam',
  afiliar: 'afiliar',
  afilias: 'afilias',
  afiliei: 'afiliei',
  afiliem: 'afiliem',
  afilies: 'afilies',
  afiliou: 'afiliou',
  afinada: 'afinada',
  afinado: 'afinado',
  afinais: 'afinais',
  afinamo: 'afinamo',
  afinara: 'afinara',
  afinava: 'afinava',
  afincai: 'afincai',
  afincam: 'afincam',
  afincar: 'afincar',
  afincas: 'afincas',
  afincoa: 'afincoa',
  afincoe: 'afincoe',
  afincoo: 'afincoo',
  afincos: 'afincos',
  afincou: 'afincou',
  afineis: 'afineis',
  afinemo: 'afinemo',
  afinfai: 'afinfai',
  afinfam: 'afinfam',
  afinfar: 'afinfar',
  afinfas: 'afinfas',
  afinfei: 'afinfei',
  afinfem: 'afinfem',
  afinfes: 'afinfes',
  afinfou: 'afinfou',
  afingem: 'afingem',
  afinges: 'afinges',
  afingia: 'afingia',
  afingir: 'afingir',
  afingis: 'afingis',
  afingiu: 'afingiu',
  afinhai: 'afinhai',
  afinham: 'afinham',
  afinhar: 'afinhar',
  afinhas: 'afinhas',
  afinhei: 'afinhei',
  afinhem: 'afinhem',
  afinhes: 'afinhes',
  afinhou: 'afinhou',
  afinica: 'afínica',
  afinico: 'afínico',
  afinjam: 'afinjam',
  afinjas: 'afinjas',
  afinque: 'afinque',
  afiquei: 'afiquei',
  afiquem: 'afiquem',
  afiques: 'afiques',
  afirais: 'afirais',
  afiramo: 'afiramo',
  afirica: 'afírica',
  afirico: 'afírico',
  afirmai: 'afirmai',
  afirmam: 'afirmam',
  afirmar: 'afirmar',
  afirmas: 'afirmas',
  afirmei: 'afirmei',
  afirmem: 'afirmem',
  afirmes: 'afirmes',
  afirmou: 'afirmou',
  afitada: 'afitada',
  afitado: 'afitado',
  afitais: 'afitais',
  afitamo: 'afitamo',
  afitara: 'afitara',
  afitava: 'afitava',
  afiteis: 'afiteis',
  afitemo: 'afitemo',
  afitiva: 'afitiva',
  afitivo: 'afitivo',
  afitulo: 'afítulo',
  afiuzai: 'afiuzai',
  afiuzam: 'afiuzam',
  afiuzar: 'afiuzar',
  afiuzas: 'afiuzas',
  afiuzei: 'afiuzei',
  afiuzem: 'afiuzem',
  afiuzes: 'afiuzes',
  afiuzou: 'afiuzou',
  afivela: 'afivela',
  afivele: 'afivele',
  afivelo: 'afivelo',
  afixada: 'afixada',
  afixado: 'afixado',
  afixais: 'afixais',
  afixamo: 'afixamo',
  afixara: 'afixara',
  afixava: 'afixava',
  afixeis: 'afixeis',
  afixemo: 'afixemo',
  afixiva: 'afixiva',
  afixivo: 'afixivo',
  afizera: 'afizera',
  afladao: 'afladão',
  afladas: 'afladas',
  aflador: 'aflador',
  aflados: 'aflados',
  aflamos: 'aflamos',
  aflando: 'aflando',
  aflante: 'aflante',
  aflaram: 'aflaram',
  aflarao: 'aflarão',
  aflaras: 'aflarás',
  aflarei: 'aflarei',
  aflarem: 'aflarem',
  aflares: 'aflares',
  aflaria: 'aflaria',
  aflarmo: 'aflarmo',
  aflasse: 'aflasse',
  aflaste: 'aflaste',
  aflatos: 'aflatos',
  aflauta: 'aflauta',
  aflaute: 'aflaute',
  aflauto: 'aflauto',
  aflavam: 'aflavam',
  aflavas: 'aflavas',
  aflecha: 'aflecha',
  afleche: 'afleche',
  aflecho: 'aflecho',
  afleias: 'afleias',
  afleima: 'afleima',
  afleime: 'afleime',
  afleimo: 'afleimo',
  aflemos: 'aflemos',
  afleuma: 'afleuma',
  afleume: 'afleume',
  afleumo: 'afleumo',
  aflicao: 'aflição',
  afligem: 'afligem',
  afliges: 'afliges',
  afligia: 'afligia',
  afligir: 'afligir',
  afligis: 'afligis',
  afligiu: 'afligiu',
  aflijam: 'aflijam',
  aflijas: 'aflijas',
  aflitas: 'aflitas',
  aflitos: 'aflitos',
  afloixa: 'afloixá',
  afloixe: 'afloixe',
  afloixo: 'afloixo',
  aflorai: 'aflorai',
  afloram: 'afloram',
  aflorar: 'aflorar',
  afloras: 'afloras',
  aflorei: 'aflorei',
  aflorem: 'aflorem',
  aflores: 'aflores',
  aflorou: 'aflorou',
  afloxai: 'afloxai',
  afloxam: 'afloxam',
  afloxar: 'afloxar',
  afloxas: 'afloxas',
  afloxei: 'afloxei',
  afloxem: 'afloxem',
  afloxes: 'afloxes',
  afloxou: 'afloxou',
  afluais: 'afluais',
  afluamo: 'afluamo',
  afluiam: 'afluíam',
  afluias: 'afluías',
  afluida: 'afluída',
  afluido: 'afluído',
  afluimo: 'afluímo',
  afluira: 'afluirá',
  afluxos: 'afluxos',
  afobada: 'afobada',
  afobado: 'afobado',
  afobais: 'afobais',
  afobamo: 'afobamo',
  afobara: 'afobara',
  afobava: 'afobava',
  afobeis: 'afobeis',
  afobemo: 'afobemo',
  afocada: 'afocada',
  afocado: 'afocado',
  afocais: 'afocais',
  afocamo: 'afocamo',
  afocara: 'afocará',
  afocava: 'afocava',
  afodais: 'afodais',
  afodios: 'afódios',
  afofada: 'afofada',
  afofado: 'afofado',
  afofais: 'afofais',
  afofamo: 'afofamo',
  afofara: 'afofara',
  afofava: 'afofava',
  afofeis: 'afofeis',
  afofemo: 'afofemo',
  afofies: 'afofiês',
  afogada: 'afogada',
  afogado: 'afogado',
  afogais: 'afogais',
  afogamo: 'afogamo',
  afogara: 'afogara',
  afogava: 'afogava',
  afoguea: 'afogueá',
  afoguei: 'afoguei',
  afoguem: 'afoguem',
  afogues: 'afogues',
  afoicai: 'afoiçai',
  afoicam: 'afoiçam',
  afoicar: 'afoiçar',
  afoicas: 'afoiças',
  afoicei: 'afoicei',
  afoicem: 'afoicem',
  afoices: 'afoices',
  afoicou: 'afoiçou',
  afoitai: 'afoitai',
  afoitam: 'afoitam',
  afoitar: 'afoitar',
  afoitas: 'afoitas',
  afoitei: 'afoitei',
  afoitem: 'afoitem',
  afoites: 'afoites',
  afoitos: 'afoitos',
  afoitou: 'afoitou',
  afolada: 'afolada',
  afolado: 'afolado',
  afolais: 'afolais',
  afolamo: 'afolamo',
  afolara: 'afolará',
  afolava: 'afolava',
  afoleis: 'afoleis',
  afolemo: 'afolemo',
  afolhai: 'afolhai',
  afolham: 'afolham',
  afolhar: 'afolhar',
  afolhas: 'afolhas',
  afolhea: 'afolheá',
  afolhei: 'afolhei',
  afolhem: 'afolhem',
  afolhes: 'afolhes',
  afolhou: 'afolhou',
  afoloza: 'afoloza',
  afoloze: 'afoloze',
  afolozo: 'afolozo',
  afomeai: 'afomeai',
  afomear: 'afomear',
  afomeei: 'afomeei',
  afomeia: 'afomeia',
  afomeie: 'afomeie',
  afomeio: 'afomeio',
  afomeou: 'afomeou',
  afonese: 'afonese',
  afonias: 'afonias',
  afonica: 'afônica',
  afonico: 'afônico',
  afonina: 'afonina',
  afonjas: 'afonjás',
  afonsas: 'afonsas',
  afonsim: 'afonsim',
  afonsos: 'afonsos',
  afoquei: 'afoquei',
  afoquem: 'afoquem',
  afoques: 'afoques',
  aforada: 'aforada',
  aforado: 'aforado',
  aforais: 'aforais',
  aforamo: 'aforamo',
  aforara: 'aforara',
  aforava: 'aforava',
  aforcai: 'aforçai',
  aforcam: 'aforçam',
  aforcar: 'aforçar',
  aforcas: 'aforças',
  aforcei: 'aforcei',
  aforcem: 'aforcem',
  aforces: 'aforces',
  aforcia: 'aforciá',
  aforcie: 'aforcie',
  aforcio: 'aforcio',
  aforcou: 'aforçou',
  aforeis: 'aforeis',
  aforemo: 'aforemo',
  aforese: 'aforese',
  aforias: 'aforias',
  aforica: 'afórica',
  aforico: 'afórico',
  aformai: 'aformai',
  aformal: 'aformal',
  aformam: 'aformam',
  aformar: 'aformar',
  aformas: 'aformas',
  aformei: 'aformei',
  aformem: 'aformem',
  aformes: 'aformes',
  aformou: 'aformou',
  aforrai: 'aforrai',
  aforram: 'aforram',
  aforrar: 'aforrar',
  aforras: 'aforras',
  aforrei: 'aforrei',
  aforrem: 'aforrem',
  aforres: 'aforres',
  aforros: 'aforros',
  aforrou: 'aforrou',
  afosias: 'afosias',
  afotica: 'afótica',
  afotico: 'afótico',
  afoucai: 'afouçai',
  afoucam: 'afouçam',
  afoucar: 'afouçar',
  afoucas: 'afouças',
  afoucei: 'afoucei',
  afoucem: 'afoucem',
  afouces: 'afouces',
  afoucou: 'afouçou',
  afoutai: 'afoutai',
  afoutam: 'afoutam',
  afoutar: 'afoutar',
  afoutas: 'afoutas',
  afoutei: 'afoutei',
  afoutem: 'afoutem',
  afoutes: 'afoutes',
  afoutou: 'afoutou',
  afracai: 'afracai',
  afracam: 'afracam',
  afracar: 'afracar',
  afracas: 'afracas',
  afracou: 'afracou',
  afragar: 'afragar',
  afrania: 'afrânia',
  afraque: 'afraque',
  afrasia: 'afrasia',
  afrecha: 'afrecha',
  afreche: 'afreche',
  afrecho: 'afrecho',
  afreima: 'afreima',
  afreime: 'afreime',
  afreimo: 'afreimo',
  afrenta: 'afrenta',
  afrente: 'afrente',
  afrento: 'afrento',
  afresca: 'afresca',
  afresco: 'afresco',
  afretai: 'afretai',
  afretam: 'afretam',
  afretar: 'afretar',
  afretas: 'afretas',
  afretei: 'afretei',
  afretem: 'afretem',
  afretes: 'afretes',
  afretou: 'afretou',
  africai: 'africai',
  africam: 'africam',
  africar: 'africar',
  africas: 'áfricas',
  africos: 'áfricos',
  africou: 'africou',
  afridol: 'afridol',
  afrigem: 'afrigem',
  afriges: 'afriges',
  afrigia: 'afrigia',
  afrigir: 'afrigir',
  afrigis: 'afrigis',
  afrigiu: 'afrigiu',
  afrijam: 'afrijam',
  afrijas: 'afrijas',
  afrique: 'afrique',
  afritas: 'afritas',
  afrites: 'afrites',
  afritos: 'afritos',
  afrodas: 'afrodas',
  afrodes: 'afrodes',
  afrodos: 'afrodos',
  afroixa: 'afroixa',
  afroixe: 'afroixe',
  afroixo: 'afroixo',
  afronha: 'afronhá',
  afronhe: 'afronhe',
  afronho: 'afronho',
  afronta: 'afronta',
  afronte: 'afronte',
  afronto: 'afronto',
  afrouxa: 'afrouxa',
  afrouxe: 'afrouxe',
  afrouxo: 'afrouxo',
  afrutai: 'afrutai',
  afrutam: 'afrutam',
  afrutar: 'afrutar',
  afrutas: 'afrutas',
  afrutei: 'afrutei',
  afrutem: 'afrutem',
  afrutes: 'afrutes',
  afrutou: 'afrutou',
  aftitos: 'aftitos',
  aftoide: 'aftoide',
  aftonos: 'áftonos',
  aftosas: 'aftosas',
  aftoses: 'aftoses',
  aftosos: 'aftosos',
  afulvai: 'afulvai',
  afulvam: 'afulvam',
  afulvar: 'afulvar',
  afulvas: 'afulvas',
  afulvei: 'afulvei',
  afulvem: 'afulvem',
  afulves: 'afulves',
  afulvou: 'afulvou',
  afumada: 'afumada',
  afumado: 'afumado',
  afumais: 'afumais',
  afumamo: 'afumamo',
  afumara: 'afumará',
  afumava: 'afumava',
  afumeai: 'afumeai',
  afumear: 'afumear',
  afumeei: 'afumeei',
  afumega: 'afumega',
  afumego: 'afumego',
  afumeia: 'afumeia',
  afumeie: 'afumeie',
  afumeio: 'afumeio',
  afumeis: 'afumeis',
  afumemo: 'afumemo',
  afumeou: 'afumeou',
  afunada: 'afunada',
  afunado: 'afunado',
  afunais: 'afunais',
  afunamo: 'afunamo',
  afunara: 'afunará',
  afunava: 'afunava',
  afundai: 'afundai',
  afundam: 'afundam',
  afundar: 'afundar',
  afundas: 'afundas',
  afundea: 'afundeá',
  afundei: 'afundei',
  afundem: 'afundem',
  afundes: 'afundes',
  afundia: 'afundia',
  afundir: 'afundir',
  afundis: 'afundis',
  afundiu: 'afundiu',
  afundou: 'afundou',
  afuneis: 'afuneis',
  afunemo: 'afunemo',
  afunila: 'afunila',
  afunile: 'afunile',
  afunilo: 'afunilo',
  afuroai: 'afuroai',
  afuroam: 'afuroam',
  afuroar: 'afuroar',
  afuroas: 'afuroas',
  afuroei: 'afuroei',
  afuroem: 'afuroem',
  afuroes: 'afuroes',
  afuroou: 'afuroou',
  afusada: 'afusada',
  afusado: 'afusado',
  afusais: 'afusais',
  afusamo: 'afusamo',
  afusara: 'afusara',
  afusava: 'afusava',
  afuseis: 'afuseis',
  afusela: 'afuselá',
  afusele: 'afusele',
  afuselo: 'afuselo',
  afusemo: 'afusemo',
  afusoes: 'afusões',
  afusona: 'afusona',
  afustai: 'afustai',
  afustam: 'afustam',
  afustar: 'afustar',
  afustas: 'afustas',
  afustei: 'afustei',
  afustem: 'afustem',
  afustes: 'afustes',
  afustou: 'afustou',
  afustua: 'afustuá',
  afustue: 'afustue',
  afustuo: 'afustuo',
  afuzila: 'afuzila',
  afuzile: 'afuzile',
  afuzilo: 'afuzilo',
  afzelia: 'afzélia',
  agabada: 'agabada',
  agabado: 'agabado',
  agabais: 'agabais',
  agabamo: 'agabamo',
  agabara: 'agabará',
  agabava: 'agabava',
  agabeis: 'agabeis',
  agabelo: 'agabelo',
  agabemo: 'agabemo',
  agabena: 'agabena',
  agabeno: 'agabeno',
  agabios: 'agábios',
  agaboes: 'agabões',
  agachai: 'agachai',
  agacham: 'agacham',
  agachar: 'agachar',
  agachas: 'agachas',
  agachei: 'agachei',
  agachem: 'agachem',
  agaches: 'agaches',
  agachis: 'agachis',
  agachou: 'agachou',
  agacias: 'agácias',
  agadada: 'agadada',
  agadado: 'agadado',
  agadina: 'agadina',
  agadoes: 'agadões',
  agadona: 'agadona',
  agadora: 'agadora',
  agafelo: 'agafelo',
  agafita: 'agafita',
  agafite: 'agafite',
  agafito: 'agafito',
  agagias: 'agágias',
  agagios: 'agágios',
  agaiada: 'agaiada',
  agaiado: 'agaiado',
  agaiais: 'agaiais',
  agaiamo: 'agaiamo',
  agaiara: 'agaiará',
  agaiata: 'agaiatá',
  agaiate: 'agaiate',
  agaiato: 'agaiato',
  agaiava: 'agaiava',
  agaieis: 'agaieis',
  agaiemo: 'agaiemo',
  agaiola: 'agaiola',
  agaiole: 'agaiole',
  agaiolo: 'agaiolo',
  agaitai: 'agaitai',
  agaitam: 'agaitam',
  agaitar: 'agaitar',
  agaitas: 'agaitas',
  agaitei: 'agaitei',
  agaitem: 'agaitem',
  agaites: 'agaites',
  agaitos: 'agaítos',
  agaitou: 'agaitou',
  agajada: 'agajada',
  agajado: 'agajado',
  agajais: 'agajais',
  agajamo: 'agajamo',
  agajara: 'agajará',
  agajava: 'agajava',
  agajeis: 'agajeis',
  agajemo: 'agajemo',
  agalada: 'agalada',
  agalado: 'agalado',
  agalais: 'agalais',
  agalamo: 'agalamo',
  agalana: 'agalana',
  agalane: 'agalane',
  agalano: 'agalano',
  agalara: 'agalará',
  agalari: 'agalari',
  agalava: 'agalava',
  agalega: 'agalega',
  agalego: 'agalego',
  agaleis: 'agaleis',
  agalemo: 'agalemo',
  agalena: 'agalena',
  agalgai: 'agalgai',
  agalgam: 'agalgam',
  agalgar: 'agalgar',
  agalgas: 'agalgas',
  agalgou: 'agalgou',
  agalgue: 'agalgue',
  agalhai: 'agalhai',
  agalham: 'agalham',
  agalhar: 'agalhar',
  agalhas: 'agalhas',
  agalhei: 'agalhei',
  agalhem: 'agalhem',
  agalhes: 'agalhes',
  agalhou: 'agalhou',
  agalias: 'agálias',
  agalica: 'agálica',
  agalico: 'agálico',
  agalima: 'agalimá',
  agalime: 'agalime',
  agalimo: 'agalimo',
  agalita: 'agalita',
  agalite: 'agalite',
  agalito: 'agálito',
  agalmas: 'agalmas',
  agaloai: 'agaloai',
  agaloam: 'agaloam',
  agaloar: 'agaloar',
  agaloas: 'agaloas',
  agaloco: 'agáloco',
  agaloei: 'agaloei',
  agaloem: 'agaloem',
  agaloes: 'agaloes',
  agaloou: 'agaloou',
  agalopa: 'agalopá',
  agalope: 'agalope',
  agalopo: 'agalopo',
  agameta: 'agâmeta',
  agameto: 'agâmeto',
  agamias: 'agamias',
  agamica: 'agâmica',
  agamico: 'agâmico',
  agamida: 'agâmida',
  agamita: 'agamita',
  aganada: 'aganada',
  aganado: 'aganado',
  aganais: 'aganais',
  aganamo: 'aganamo',
  aganara: 'aganara',
  aganava: 'aganava',
  aganeai: 'aganeai',
  aganear: 'aganear',
  aganeei: 'aganeei',
  aganeia: 'aganeia',
  aganeie: 'aganeie',
  aganeio: 'aganeio',
  aganeis: 'aganeis',
  aganemo: 'aganemo',
  aganeou: 'aganeou',
  aganide: 'aganide',
  aganipe: 'aganipe',
  aganjus: 'aganjus',
  aganoes: 'aganões',
  agantai: 'agantai',
  agantam: 'agantam',
  agantar: 'agantar',
  agantas: 'agantas',
  agantei: 'agantei',
  agantem: 'agantem',
  agantes: 'agantes',
  agantou: 'agantou',
  agapeta: 'agapeta',
  agapeto: 'agapeto',
  agarani: 'agarani',
  agarcal: 'agarçal',
  agardes: 'agardes',
  agardia: 'agárdia',
  agareas: 'agáreas',
  agareis: 'agáreis',
  agarejo: 'agarejo',
  agarena: 'agarena',
  agareno: 'agareno',
  agareos: 'agáreos',
  agariam: 'agariam',
  agarias: 'agarias',
  agarica: 'agárica',
  agarice: 'agárice',
  agarico: 'agárico',
  agarima: 'agarimá',
  agarime: 'agarime',
  agarimo: 'agarimo',
  agarina: 'agarina',
  agarmos: 'agarmos',
  agarnel: 'agarnel',
  agarois: 'agaróis',
  agarose: 'agarose',
  agarota: 'agarota',
  agarote: 'agarote',
  agaroto: 'agaroto',
  agarrai: 'agarrai',
  agarram: 'agarram',
  agarrao: 'agarrão',
  agarrar: 'agarrar',
  agarras: 'agarras',
  agarrei: 'agarrei',
  agarrem: 'agarrem',
  agarres: 'agarres',
  agarrou: 'agarrou',
  agaruns: 'agaruns',
  agassem: 'agassem',
  agasses: 'agasses',
  agassia: 'agássia',
  agastai: 'agastai',
  agastam: 'agastam',
  agastar: 'agastar',
  agastas: 'agastas',
  agastei: 'agastei',
  agastem: 'agastem',
  agastes: 'agastes',
  agastou: 'agastou',
  agastra: 'agastra',
  agastro: 'agastro',
  agatais: 'agatais',
  agateas: 'agáteas',
  agateia: 'agateia',
  agateos: 'agáteos',
  agatias: 'agátias',
  agatica: 'agática',
  agatico: 'agático',
  agatide: 'agátide',
  agatina: 'agatina',
  agatino: 'agatino',
  agatiza: 'agatizá',
  agatize: 'agatize',
  agatizo: 'agatizo',
  agatuna: 'agatuná',
  agatune: 'agatune',
  agatuno: 'agatuno',
  agaucha: 'agaúcha',
  agauche: 'agaúche',
  agaucho: 'agaúcho',
  agaveas: 'agáveas',
  agaveis: 'agáveis',
  agavela: 'agavela',
  agavele: 'agavele',
  agavelo: 'agavelo',
  agaveta: 'agaveta',
  agavete: 'agavete',
  agaveto: 'agaveto',
  agavios: 'agavios',
  agavosa: 'agavosa',
  agazada: 'agazada',
  agazado: 'agazado',
  agazais: 'agazais',
  agazamo: 'agazamo',
  agazara: 'agazara',
  agazava: 'agazava',
  agazeis: 'agazeis',
  agazela: 'agazela',
  agazemo: 'agazemo',
  agazuai: 'agazuai',
  agazuam: 'agazuam',
  agazuar: 'agazuar',
  agazuas: 'agazuas',
  agazuei: 'agazuei',
  agazuem: 'agazuem',
  agazues: 'agazues',
  agazula: 'agazulá',
  agazule: 'agazule',
  agazulo: 'agazulo',
  agazuou: 'agazuou',
  agedras: 'agedras',
  agedula: 'agédula',
  agelaia: 'agelaia',
  agelaio: 'agelaio',
  ageleia: 'ageleia',
  agelena: 'agelena',
  ageleus: 'ageleus',
  agemada: 'agemada',
  agemado: 'agemado',
  agemate: 'agêmate',
  agencia: 'agência',
  agencie: 'agencie',
  agencio: 'agencio',
  agendai: 'agendai',
  agendam: 'agendam',
  agendar: 'agendar',
  agendas: 'agendas',
  agendei: 'agendei',
  agendem: 'agendem',
  agendes: 'agendes',
  agendou: 'agendou',
  agenere: 'agênere',
  agenese: 'agênese',
  agenias: 'agênias',
  agenios: 'agênios',
  agenora: 'agenora',
  agentes: 'agentes',
  agerada: 'agerada',
  agerado: 'agerado',
  agerais: 'agerais',
  ageramo: 'ageramo',
  agerara: 'agerará',
  agerata: 'agerata',
  agerato: 'agerato',
  agerava: 'agerava',
  agereis: 'agereis',
  ageremo: 'ageremo',
  agerina: 'agerina',
  agervao: 'agervão',
  agestai: 'agestai',
  agestam: 'agestam',
  agestar: 'agestar',
  agestas: 'agestas',
  agestei: 'agestei',
  agestem: 'agestem',
  agestes: 'agestes',
  agestos: 'agestos',
  agestou: 'agestou',
  ageusas: 'ageusas',
  ageusia: 'ageusia',
  ageusos: 'ageusos',
  ageusta: 'ageusta',
  ageusto: 'ageusto',
  agiamos: 'agíamos',
  agiares: 'agiares',
  agiasmo: 'agiasmo',
  agidoes: 'agidões',
  agidona: 'agidona',
  agidora: 'agidora',
  agilima: 'agílima',
  agilimo: 'agílimo',
  agilita: 'agilita',
  agilite: 'agilite',
  agilito: 'agilito',
  agiliza: 'agiliza',
  agilize: 'agilize',
  agilizo: 'agilizo',
  agineas: 'agíneas',
  aginias: 'aginias',
  aginica: 'agínica',
  aginico: 'agínico',
  agiotai: 'agiotai',
  agiotam: 'agiotam',
  agiotar: 'agiotar',
  agiotas: 'agiotas',
  agiotei: 'agiotei',
  agiotem: 'agiotem',
  agiotes: 'agiotes',
  agiotou: 'agiotou',
  agirdes: 'agirdes',
  agireis: 'agireis',
  agiremo: 'agiremo',
  agiriam: 'agiriam',
  agirias: 'agirias',
  agirina: 'agirina',
  agirmos: 'agirmos',
  agirona: 'agironá',
  agirone: 'agirone',
  agirono: 'agirono',
  agirtas: 'agirtas',
  agissem: 'agissem',
  agisses: 'agisses',
  agistes: 'agistes',
  agitada: 'agitada',
  agitado: 'agitado',
  agitais: 'agitais',
  agitamo: 'agitamo',
  agitara: 'agitara',
  agitata: 'agitata',
  agitato: 'agitato',
  agitava: 'agitava',
  agiteis: 'agiteis',
  agitemo: 'agitemo',
  aglaias: 'aglaias',
  aglaito: 'aglaíto',
  aglaope: 'aglaope',
  aglaopo: 'aglaopo',
  aglenas: 'aglenas',
  aglenos: 'aglenos',
  aglifas: 'áglifas',
  aglifos: 'áglifos',
  aglossa: 'aglossa',
  aglosso: 'aglosso',
  agmares: 'agmares',
  agminal: 'agminal',
  agminea: 'agmínea',
  agmineo: 'agmíneo',
  agnacao: 'agnação',
  agnadas: 'agnadas',
  agnados: 'agnados',
  agnanto: 'agnanto',
  agnatas: 'agnatas',
  agnatia: 'agnatia',
  agnatos: 'agnatos',
  agnelos: 'agnelos',
  agnesia: 'agnésia',
  agnicao: 'agnição',
  agninas: 'agninas',
  agninio: 'agnínio',
  agninos: 'agninos',
  agnoeta: 'agnoeta',
  agnoias: 'agnoias',
  agnoita: 'agnoíta',
  agnomes: 'agnomes',
  agnosia: 'agnosia',
  agogica: 'agógica',
  agogico: 'agógico',
  agogues: 'agoguês',
  agoirai: 'agoirai',
  agoiral: 'agoiral',
  agoiram: 'agoiram',
  agoirar: 'agoirar',
  agoiras: 'agoiras',
  agoirei: 'agoirei',
  agoirem: 'agoirem',
  agoires: 'agoires',
  agoiros: 'agoiros',
  agoirou: 'agoirou',
  agolpea: 'agolpeá',
  agomada: 'agomada',
  agomado: 'agomado',
  agomais: 'agomais',
  agomamo: 'agomamo',
  agomara: 'agomara',
  agomava: 'agomava',
  agomeis: 'agomeis',
  agomemo: 'agomemo',
  agomias: 'agomias',
  agomila: 'agomilá',
  agomile: 'agomile',
  agomilo: 'agomilo',
  agomina: 'agominá',
  agomine: 'agomine',
  agomino: 'agomino',
  agomita: 'agomita',
  agomite: 'agomite',
  agomito: 'agomito',
  agonais: 'agonais',
  agonfas: 'agonfas',
  agonfos: 'agonfos',
  agoniai: 'agoniai',
  agoniam: 'agoniam',
  agoniar: 'agoniar',
  agonias: 'agonias',
  agonica: 'agônica',
  agonico: 'agônico',
  agoniei: 'agoniei',
  agoniem: 'agoniem',
  agonies: 'agonies',
  agoniou: 'agoniou',
  agoniza: 'agoniza',
  agonize: 'agonize',
  agonizo: 'agonizo',
  agorins: 'agorins',
  agostai: 'agostai',
  agostam: 'agostam',
  agostar: 'agostar',
  agostas: 'agostas',
  agostei: 'agostei',
  agostem: 'agostem',
  agostes: 'agostes',
  agostos: 'agostos',
  agostou: 'agostou',
  agotada: 'agotada',
  agotado: 'agotado',
  agotais: 'agotais',
  agotamo: 'agotamo',
  agotara: 'agotará',
  agotava: 'agotava',
  agoteis: 'agoteis',
  agotemo: 'agotemo',
  agourai: 'agourai',
  agoural: 'agoural',
  agouram: 'agouram',
  agourar: 'agourar',
  agouras: 'agouras',
  agourei: 'agourei',
  agourem: 'agourem',
  agoures: 'agoures',
  agouros: 'agouros',
  agourou: 'agourou',
  agracia: 'agracia',
  agracie: 'agracie',
  agracio: 'agracio',
  agracos: 'agraços',
  agradai: 'agradai',
  agradam: 'agradam',
  agradao: 'agradão',
  agradar: 'agradar',
  agradas: 'agradas',
  agradei: 'agradei',
  agradem: 'agradem',
  agrades: 'agrades',
  agrador: 'agrador',
  agrados: 'agrados',
  agradou: 'agradou',
  agradua: 'agradua',
  agradue: 'agradue',
  agraduo: 'agraduo',
  agrafai: 'agrafai',
  agrafam: 'agrafam',
  agrafar: 'agrafar',
  agrafas: 'agrafas',
  agrafei: 'agrafei',
  agrafem: 'agrafem',
  agrafes: 'agrafes',
  agrafia: 'agrafia',
  agrafos: 'agrafos',
  agrafou: 'agrafou',
  agramos: 'agramos',
  agranai: 'agranai',
  agranam: 'agranam',
  agranar: 'agranar',
  agranas: 'agranas',
  agranda: 'agranda',
  agrande: 'agrande',
  agrando: 'agrando',
  agranei: 'agranei',
  agranem: 'agranem',
  agranes: 'agranes',
  agranou: 'agranou',
  agrante: 'agrante',
  agrapim: 'agrapim',
  agraram: 'agraram',
  agrarao: 'agrarão',
  agraras: 'agraras',
  agrarei: 'agrarei',
  agrarem: 'agrarem',
  agrares: 'agrares',
  agraria: 'agrária',
  agrario: 'agrário',
  agrarmo: 'agrarmo',
  agrasse: 'agrasse',
  agraste: 'agraste',
  agrauda: 'agraúda',
  agraude: 'agraúde',
  agraudo: 'agraúdo',
  agraula: 'agraula',
  agraulo: 'agraulo',
  agravai: 'agravai',
  agravam: 'agravam',
  agravar: 'agravar',
  agravas: 'agravas',
  agravei: 'agravei',
  agravem: 'agravem',
  agraves: 'agraves',
  agravia: 'agravia',
  agravie: 'agravie',
  agravio: 'agravio',
  agravos: 'agravos',
  agravou: 'agravou',
  agrazes: 'agrazes',
  agreada: 'agreada',
  agreado: 'agreado',
  agreais: 'agreais',
  agreara: 'agreará',
  agreava: 'agreava',
  agredam: 'agredam',
  agredas: 'agredas',
  agredem: 'agredem',
  agredes: 'agredes',
  agredia: 'agredia',
  agredir: 'agredir',
  agredis: 'agredis',
  agrediu: 'agrediu',
  agreeis: 'agreeis',
  agregai: 'agregai',
  agregam: 'agregam',
  agregar: 'agregar',
  agregas: 'agregas',
  agregou: 'agregou',
  agregue: 'agregue',
  agreiam: 'agreiam',
  agreias: 'agreias',
  agreiem: 'agreiem',
  agreies: 'agreies',
  agreira: 'agreira',
  agreiro: 'agreiro',
  agrelai: 'agrelai',
  agrelam: 'agrelam',
  agrelar: 'agrelar',
  agrelas: 'agrelas',
  agrelei: 'agrelei',
  agrelem: 'agrelem',
  agreles: 'agreles',
  agrelou: 'agrelou',
  agremia: 'agremia',
  agremie: 'agremie',
  agremio: 'agremio',
  agremos: 'agremos',
  agresta: 'agresta',
  agreste: 'agreste',
  agresto: 'agresto',
  agriais: 'agriais',
  agriana: 'agriana',
  agriano: 'agriano',
  agricai: 'agriçai',
  agricam: 'agriçam',
  agricar: 'agriçar',
  agricas: 'agriças',
  agricei: 'agricei',
  agricem: 'agricem',
  agrices: 'agrices',
  agricou: 'agriçou',
  agridam: 'agridam',
  agridas: 'agridas',
  agridem: 'agridem',
  agrides: 'agrides',
  agrifai: 'agrifai',
  agrifam: 'agrifam',
  agrifar: 'agrifar',
  agrifas: 'agrifas',
  agrifei: 'agrifei',
  agrifem: 'agrifem',
  agrifes: 'agrifes',
  agrifou: 'agrifou',
  agrilai: 'agrilai',
  agrilam: 'agrilam',
  agrilar: 'agrilar',
  agrilas: 'agrilas',
  agrilei: 'agrilei',
  agrilem: 'agrilem',
  agriles: 'agriles',
  agrilha: 'agrilha',
  agrilhe: 'agrilhe',
  agrilho: 'agrilho',
  agrilou: 'agrilou',
  agrimai: 'agrimai',
  agrimam: 'agrimam',
  agrimar: 'agrimar',
  agrimas: 'agrimas',
  agrimei: 'agrimei',
  agrimem: 'agrimem',
  agrimes: 'agrimes',
  agrimou: 'agrimou',
  agrioes: 'agriões',
  agrions: 'ágrions',
  agriope: 'agriope',
  agriopo: 'agriopo',
  agriota: 'agriota',
  agriote: 'agriote',
  agrioto: 'agrioto',
  agripas: 'agripas',
  agripno: 'agripno',
  agrores: 'agrores',
  agrosas: 'agrosas',
  agrosos: 'agrosos',
  agroste: 'agroste',
  agrotes: 'ágrotes',
  agrovia: 'agrovia',
  agrumai: 'agrumai',
  agrumam: 'agrumam',
  agrumar: 'agrumar',
  agrumas: 'agrumas',
  agrumei: 'agrumei',
  agrumem: 'agrumem',
  agrumes: 'agrumes',
  agrumou: 'agrumou',
  agrupai: 'agrupai',
  agrupam: 'agrupam',
  agrupar: 'agrupar',
  agrupas: 'agrupas',
  agrupei: 'agrupei',
  agrupem: 'agrupem',
  agrupes: 'agrupes',
  agrupou: 'agrupou',
  agruras: 'agruras',
  aguacai: 'aguaçai',
  aguacal: 'aguaçal',
  aguacam: 'aguaçam',
  aguacar: 'aguaçar',
  aguacas: 'aguaças',
  aguacei: 'aguacei',
  aguacem: 'aguacem',
  aguaces: 'aguaces',
  aguacha: 'aguacha',
  aguache: 'aguache',
  aguacho: 'aguacho',
  aguacou: 'aguaçou',
  aguacus: 'aguaçus',
  aguadas: 'aguadas',
  aguadia: 'aguadiá',
  aguador: 'aguador',
  aguados: 'aguados',
  aguagem: 'aguagem',
  aguains: 'aguains',
  aguamae: 'águamãe',
  aguamai: 'aguamãi',
  aguamos: 'aguamos',
  aguanas: 'aguanas',
  aguando: 'aguando',
  aguanos: 'aguanos',
  aguante: 'aguante',
  aguapas: 'aguapás',
  aguapes: 'aguapés',
  aguaram: 'aguaram',
  aguarao: 'aguarão',
  aguaras: 'aguarás',
  aguarda: 'aguarda',
  aguarde: 'aguarde',
  aguardo: 'aguardo',
  aguarei: 'aguarei',
  aguarem: 'aguarem',
  aguares: 'aguares',
  aguaria: 'aguaria',
  aguarmo: 'aguarmo',
  aguasse: 'aguasse',
  aguaste: 'aguaste',
  aguatal: 'aguatal',
  aguavam: 'aguavam',
  aguavas: 'aguavas',
  aguaxai: 'aguaxai',
  aguaxam: 'aguaxam',
  aguaxar: 'aguaxar',
  aguaxas: 'aguaxas',
  aguaxei: 'aguaxei',
  aguaxem: 'aguaxem',
  aguaxes: 'aguaxes',
  aguaxou: 'aguaxou',
  aguazil: 'aguazil',
  aguazis: 'aguazis',
  agucada: 'aguçada',
  agucado: 'aguçado',
  agucais: 'aguçais',
  agucamo: 'aguçamo',
  agucara: 'aguçará',
  agucava: 'aguçava',
  aguceis: 'aguceis',
  agucemo: 'agucemo',
  agucosa: 'aguçosa',
  agucoso: 'aguçoso',
  agudada: 'agudada',
  agudado: 'agudado',
  agudais: 'agudais',
  agudamo: 'agudamo',
  agudara: 'agudará',
  agudava: 'agudava',
  agudeca: 'agudeça',
  agudece: 'agudece',
  agudeci: 'agudeci',
  agudeco: 'agudeço',
  agudeis: 'agudeis',
  agudemo: 'agudemo',
  agudeza: 'agudeza',
  agudiao: 'agudião',
  agudias: 'agúdias',
  agudiza: 'agudiza',
  agudize: 'agudize',
  agudizo: 'agudizo',
  agueira: 'agueira',
  agueire: 'agueire',
  agueiro: 'agueiro',
  aguemos: 'aguemos',
  aguenta: 'aguenta',
  aguente: 'aguente',
  aguento: 'aguento',
  agueres: 'aguerês',
  aguerra: 'aguerra',
  aguerre: 'aguerre',
  aguerri: 'aguerri',
  aguerro: 'aguerro',
  aguicha: 'aguichá',
  aguiche: 'aguiche',
  aguicho: 'aguicho',
  aguieta: 'aguieta',
  aguilas: 'aguilas',
  aguilha: 'aguilha',
  aguilhe: 'aguilhe',
  aguilho: 'aguilhó',
  aguinam: 'aguinam',
  aguinas: 'aguinas',
  aguinem: 'aguinem',
  aguines: 'aguines',
  aguinha: 'aguinha',
  aguinia: 'aguinia',
  aguinir: 'aguinir',
  aguinis: 'aguinis',
  aguiniu: 'aguiniu',
  aguioes: 'aguiões',
  aguioto: 'aguioto',
  aguisai: 'aguisai',
  aguisam: 'aguisam',
  aguisar: 'aguisar',
  aguisas: 'aguisas',
  aguisei: 'aguisei',
  aguisem: 'aguisem',
  aguises: 'aguises',
  aguisou: 'aguisou',
  aguista: 'aguista',
  agulhai: 'agulhai',
  agulham: 'agulham',
  agulhao: 'agulhão',
  agulhar: 'agulhar',
  agulhas: 'agulhas',
  agulhea: 'agulheá',
  agulhei: 'agulhei',
  agulhem: 'agulhem',
  agulhes: 'agulhes',
  agulhou: 'agulhou',
  agundus: 'agundus',
  agurina: 'agurina',
  agustia: 'agustia',
  aiabeba: 'aiabeba',
  aiacana: 'aiacaná',
  aiacari: 'aiaçari',
  aiadoes: 'aiadões',
  aiadona: 'aiadona',
  aiadora: 'aiadora',
  aiantes: 'aiantes',
  aiaozao: 'aiãozão',
  aiapana: 'aiapana',
  aiapuas: 'aiapuás',
  aiaracu: 'aiaraçu',
  aiardes: 'aiardes',
  aiareba: 'aiareba',
  aiareis: 'aiáreis',
  aiaremo: 'aiaremo',
  aiariam: 'aiariam',
  aiarias: 'aiarias',
  aiarmos: 'aiarmos',
  aiassem: 'aiassem',
  aiasses: 'aiasses',
  aiastes: 'aiastes',
  aiatola: 'aiatolá',
  aiaveis: 'aiáveis',
  aicanas: 'aicanãs',
  aicunas: 'aicunas',
  aidoite: 'aidoíte',
  aienias: 'aiênias',
  aiereba: 'aiereba',
  aigotos: 'aigotos',
  aijubas: 'aijubas',
  aijunas: 'aijunas',
  aikidos: 'aikidos',
  ailanto: 'ailanto',
  ailerao: 'ailerão',
  aileron: 'aileron',
  aililas: 'aililas',
  ailuros: 'ailuros',
  aimaras: 'aimarás',
  aimbire: 'aimbiré',
  aimirim: 'aimirim',
  aimores: 'aimorés',
  aimurus: 'aimurus',
  ainhuns: 'ainhuns',
  aiodina: 'aiodina',
  aipiris: 'aipiris',
  aiquido: 'aiquidô',
  airadao: 'airadão',
  airadas: 'airadas',
  airador: 'airador',
  airados: 'airados',
  airamos: 'airamos',
  airando: 'airando',
  airante: 'airante',
  airaram: 'airaram',
  airarao: 'airarão',
  airaras: 'airaras',
  airarei: 'airarei',
  airarem: 'airarem',
  airares: 'airares',
  airaria: 'airaria',
  airarmo: 'airarmo',
  airasse: 'airasse',
  airaste: 'airaste',
  airavam: 'airavam',
  airavas: 'airavas',
  airelas: 'airelas',
  airemos: 'airemos',
  airense: 'airense',
  airglow: 'airglow',
  airiacu: 'airiaçu',
  airinis: 'airinis',
  airiris: 'airiris',
  airobas: 'airobas',
  airogas: 'airogas',
  airopse: 'airopse',
  airosas: 'airosas',
  airosia: 'airosia',
  airosos: 'airosos',
  airutes: 'airutes',
  aislada: 'aislada',
  aislado: 'aislado',
  aislais: 'aislais',
  aislamo: 'aislamo',
  aislara: 'aislará',
  aislava: 'aislava',
  aisleis: 'aisleis',
  aislemo: 'aislemo',
  aissana: 'aissana',
  aisuari: 'aisuari',
  aitacas: 'aitacás',
  aitonas: 'aitonas',
  aitonia: 'aitônia',
  aiuabas: 'aiuabas',
  aiubida: 'aiúbida',
  aiumara: 'aiumará',
  aiunada: 'aiunada',
  aiunado: 'aiunado',
  aiunais: 'aiunais',
  aiunamo: 'aiunamo',
  aiunara: 'aiunará',
  aiunava: 'aiunava',
  aiuneis: 'aiuneis',
  aiunemo: 'aiunemo',
  aivacas: 'aivacas',
  aivados: 'aivados',
  aivecas: 'aivecas',
  aizoons: 'aizóons',
  ajaezai: 'ajaezai',
  ajaezam: 'ajaezam',
  ajaezar: 'ajaezar',
  ajaezas: 'ajaezas',
  ajaezei: 'ajaezei',
  ajaezem: 'ajaezem',
  ajaezes: 'ajaezes',
  ajaezou: 'ajaezou',
  ajambra: 'ajambra',
  ajambre: 'ajambre',
  ajambro: 'ajambro',
  ajanota: 'ajanota',
  ajanote: 'ajanote',
  ajanoto: 'ajanoto',
  ajapona: 'ajaponá',
  ajapone: 'ajapone',
  ajapono: 'ajapono',
  ajarais: 'ajaraís',
  ajaroba: 'ajarobá',
  ajaulai: 'ajaulai',
  ajaulam: 'ajaulam',
  ajaular: 'ajaular',
  ajaulas: 'ajaulas',
  ajaulei: 'ajaulei',
  ajaulem: 'ajaulem',
  ajaules: 'ajaules',
  ajaulou: 'ajaulou',
  ajedras: 'ajedras',
  ajeirai: 'ajeirai',
  ajeiram: 'ajeiram',
  ajeirar: 'ajeirar',
  ajeiras: 'ajeiras',
  ajeirei: 'ajeirei',
  ajeirem: 'ajeirem',
  ajeires: 'ajeires',
  ajeirou: 'ajeirou',
  ajeitai: 'ajeitai',
  ajeitam: 'ajeitam',
  ajeitar: 'ajeitar',
  ajeitas: 'ajeitas',
  ajeitei: 'ajeitei',
  ajeitem: 'ajeitem',
  ajeites: 'ajeites',
  ajeitou: 'ajeitou',
  ajereba: 'ajereba',
  ajetiva: 'ajetiva',
  ajetive: 'ajetive',
  ajetivo: 'ajetivo',
  ajibona: 'ajibonã',
  ajicuba: 'ajicuba',
  ajicubo: 'ajicubo',
  ajoeira: 'ajoeira',
  ajoeire: 'ajoeire',
  ajoeiro: 'ajoeiro',
  ajoelha: 'ajoelha',
  ajoelhe: 'ajoelhe',
  ajoelho: 'ajoelho',
  ajoiada: 'ajoiada',
  ajoiado: 'ajoiado',
  ajoiais: 'ajoiais',
  ajoiara: 'ajoiará',
  ajoiava: 'ajoiava',
  ajoieis: 'ajoieis',
  ajolzao: 'ajolzão',
  ajorcai: 'ajorcai',
  ajorcam: 'ajorcam',
  ajorcar: 'ajorcar',
  ajorcas: 'ajorcas',
  ajorcou: 'ajorcou',
  ajorjai: 'ajorjai',
  ajorjam: 'ajorjam',
  ajorjar: 'ajorjar',
  ajorjas: 'ajorjas',
  ajorjei: 'ajorjei',
  ajorjem: 'ajorjem',
  ajorjes: 'ajorjes',
  ajorjou: 'ajorjou',
  ajorque: 'ajorque',
  ajoujai: 'ajoujai',
  ajoujam: 'ajoujam',
  ajoujar: 'ajoujar',
  ajoujas: 'ajoujas',
  ajoujei: 'ajoujei',
  ajoujem: 'ajoujem',
  ajoujes: 'ajoujes',
  ajoujos: 'ajoujos',
  ajoujou: 'ajoujou',
  ajoveas: 'ajôveas',
  ajoviar: 'ajoviar',
  ajuagas: 'ajuagas',
  ajucara: 'ajucará',
  ajudada: 'ajudada',
  ajudado: 'ajudado',
  ajudais: 'ajudais',
  ajudamo: 'ajudamo',
  ajudara: 'ajudará',
  ajudava: 'ajudava',
  ajudeai: 'ajudeai',
  ajudear: 'ajudear',
  ajudeei: 'ajudeei',
  ajudeia: 'ajudeia',
  ajudeie: 'ajudeie',
  ajudeio: 'ajudeio',
  ajudeis: 'ajudeis',
  ajudemo: 'ajudemo',
  ajudeou: 'ajudeou',
  ajugeas: 'ajúgeas',
  ajuizai: 'ajuizai',
  ajuizam: 'ajuízam',
  ajuizar: 'ajuizar',
  ajuizas: 'ajuízas',
  ajuizei: 'ajuizei',
  ajuizem: 'ajuízem',
  ajuizes: 'ajuízes',
  ajuizou: 'ajuizou',
  ajujure: 'ajujuré',
  ajulada: 'ajulada',
  ajulado: 'ajulado',
  ajulais: 'ajulais',
  ajulamo: 'ajulamo',
  ajulara: 'ajulará',
  ajulata: 'ajulata',
  ajulava: 'ajulava',
  ajuleis: 'ajuleis',
  ajulemo: 'ajulemo',
  ajuntai: 'ajuntai',
  ajuntam: 'ajuntam',
  ajuntar: 'ajuntar',
  ajuntas: 'ajuntas',
  ajuntei: 'ajuntei',
  ajuntem: 'ajuntem',
  ajuntes: 'ajuntes',
  ajuntou: 'ajuntou',
  ajurana: 'ajurana',
  ajuruis: 'ajuruís',
  ajurure: 'ajururé',
  ajustai: 'ajustai',
  ajustam: 'ajustam',
  ajustar: 'ajustar',
  ajustas: 'ajustas',
  ajustei: 'ajustei',
  ajustem: 'ajustem',
  ajustes: 'ajustes',
  ajustou: 'ajustou',
  alabaca: 'alabaça',
  alabada: 'alabada',
  alabado: 'alabado',
  alabais: 'alabais',
  alabama: 'alabama',
  alabamo: 'alabamo',
  alabara: 'alabara',
  alabare: 'alabare',
  alabaro: 'alabaro',
  alabava: 'alabava',
  alabeis: 'alabeis',
  alabemo: 'alabemo',
  alabiai: 'alabiai',
  alabiam: 'alabiam',
  alabiar: 'alabiar',
  alabias: 'alabias',
  alabiei: 'alabiei',
  alabiem: 'alabiem',
  alabies: 'alabies',
  alabiou: 'alabiou',
  alaboes: 'alabões',
  alabuga: 'alabuga',
  alacada: 'alacada',
  alacado: 'alacado',
  alacaia: 'alacaiá',
  alacaie: 'alacaie',
  alacaio: 'alacaio',
  alacais: 'alacais',
  alacamo: 'alacamo',
  alacara: 'alacara',
  alacava: 'alacava',
  alacoes: 'alações',
  alacona: 'alaçona',
  alacora: 'alaçora',
  alacrai: 'alacrai',
  alacral: 'alacral',
  alacram: 'alacram',
  alacrao: 'alacrão',
  alacrar: 'alacrar',
  alacras: 'alacras',
  alacrau: 'alacrau',
  alacrea: 'alacreá',
  alacrei: 'alacrei',
  alacrem: 'alacrem',
  alacres: 'álacres',
  alacrou: 'alacrou',
  alactai: 'alactai',
  alactam: 'alactam',
  alactar: 'alactar',
  alactas: 'alactas',
  alactei: 'alactei',
  alactem: 'alactem',
  alactes: 'alactes',
  alactou: 'alactou',
  aladina: 'aladina',
  aladino: 'aladino',
  aladoes: 'aladões',
  aladona: 'aladona',
  aladora: 'aladora',
  aladroa: 'aladroa',
  aladroe: 'aladroe',
  aladroo: 'aladroo',
  alaelas: 'alaelas',
  alafias: 'alafias',
  alagada: 'alagada',
  alagado: 'alagado',
  alagais: 'alagais',
  alagamo: 'alagamo',
  alagara: 'alagara',
  alagava: 'alagava',
  alagens: 'alagens',
  alagita: 'alagita',
  alagite: 'alagite',
  alagito: 'alagito',
  alagoai: 'alagoai',
  alagoam: 'alagoam',
  alagoar: 'alagoar',
  alagoas: 'alagoas',
  alagoei: 'alagoei',
  alagoem: 'alagoem',
  alagoes: 'alagoes',
  alagoou: 'alagoou',
  alagosa: 'alagosa',
  alagoso: 'alagoso',
  alaguei: 'alaguei',
  alaguem: 'alaguem',
  alagues: 'alagues',
  alaguna: 'alaguna',
  alagune: 'alagune',
  alaguno: 'alaguno',
  alajada: 'alajada',
  alajado: 'alajado',
  alajais: 'alajais',
  alajamo: 'alajamo',
  alajara: 'alajará',
  alajava: 'alajava',
  alajeis: 'alajeis',
  alajemo: 'alajemo',
  alalias: 'alálias',
  alalica: 'alálica',
  alalico: 'alálico',
  alalita: 'alalita',
  alalite: 'alalite',
  alalito: 'alalito',
  alalzao: 'alalzão',
  alamais: 'alamais',
  alamana: 'alamana',
  alamano: 'alamano',
  alambar: 'alâmbar',
  alambas: 'alambas',
  alambel: 'alambel',
  alambor: 'alambor',
  alambra: 'alambra',
  alambre: 'alambre',
  alambro: 'alambro',
  alameda: 'alameda',
  alamede: 'alamede',
  alamedo: 'alamedo',
  alamias: 'alamias',
  alamina: 'alamina',
  alamins: 'alamins',
  alamire: 'alamiré',
  alamoas: 'alamoas',
  alamoes: 'alamões',
  alampai: 'alampai',
  alampam: 'alampam',
  alampar: 'alampar',
  alampas: 'alampas',
  alampei: 'alampei',
  alampem: 'alampem',
  alampes: 'alampes',
  alampou: 'alampou',
  alamutu: 'alamutu',
  alancai: 'alancai',
  alancam: 'alancam',
  alancao: 'alancão',
  alancar: 'alancar',
  alancas: 'alancas',
  alancea: 'alanceá',
  alances: 'alances',
  alancos: 'alancos',
  alancou: 'alancou',
  alandea: 'alândea',
  alandia: 'alândia',
  alandra: 'alandra',
  alandro: 'alandro',
  alangio: 'alângio',
  alanhai: 'alanhai',
  alanham: 'alanham',
  alanhar: 'alanhar',
  alanhas: 'alanhas',
  alanhei: 'alanhei',
  alanhem: 'alanhem',
  alanhes: 'alanhes',
  alanhou: 'alanhou',
  alanias: 'alânias',
  alanica: 'alânica',
  alanico: 'alânico',
  alanina: 'alanina',
  alanita: 'alanita',
  alanite: 'alanite',
  alanito: 'alanito',
  alanque: 'alanque',
  alantas: 'alantas',
  alantes: 'alantes',
  alantol: 'alantol',
  alantos: 'alantos',
  alanzoa: 'alanzoa',
  alanzoe: 'alanzoe',
  alanzoo: 'alanzoo',
  alaozao: 'alãozão',
  alapada: 'alapada',
  alapado: 'alapado',
  alapais: 'alapais',
  alapamo: 'alapamo',
  alapara: 'alapará',
  alapava: 'alapava',
  alapeis: 'alapeis',
  alapemo: 'alapemo',
  alapoai: 'alapoai',
  alapoam: 'alapoam',
  alapoar: 'alapoar',
  alapoas: 'alapoas',
  alapoei: 'alapoei',
  alapoem: 'alapoem',
  alapoes: 'alapoes',
  alapoou: 'alapoou',
  alaptes: 'alaptes',
  alaptos: 'alaptos',
  alapuza: 'alapuzá',
  alapuze: 'alapuze',
  alapuzo: 'alapuzo',
  alaquea: 'alaqueá',
  alaquei: 'alaquei',
  alaquem: 'alaquem',
  alaques: 'alaques',
  alarabe: 'alárabe',
  alarada: 'alarada',
  alarado: 'alarado',
  alarais: 'alarais',
  alaramo: 'alaramo',
  alarara: 'alarará',
  alarava: 'alarava',
  alarave: 'alárave',
  alardai: 'alardai',
  alardam: 'alardam',
  alardar: 'alardar',
  alardas: 'alardas',
  alardea: 'alardeá',
  alardei: 'alardei',
  alardem: 'alardem',
  alardes: 'alardes',
  alardou: 'alardou',
  alareis: 'alareis',
  alaremo: 'alaremo',
  alargai: 'alargai',
  alargam: 'alargam',
  alargar: 'alargar',
  alargas: 'alargas',
  alargou: 'alargou',
  alargue: 'alargue',
  alariam: 'alariam',
  alarias: 'alarias',
  alarida: 'alarida',
  alarido: 'alarido',
  alarife: 'alarife',
  alarmai: 'alarmai',
  alarmam: 'alarmam',
  alarmar: 'alarmar',
  alarmas: 'alarmas',
  alarmei: 'alarmei',
  alarmem: 'alarmem',
  alarmes: 'alarmes',
  alarmos: 'alarmos',
  alarmou: 'alarmou',
  alarosa: 'alarosa',
  alaroso: 'alaroso',
  alarpai: 'alarpai',
  alarpam: 'alarpam',
  alarpar: 'alarpar',
  alarpas: 'alarpas',
  alarpei: 'alarpei',
  alarpem: 'alarpem',
  alarpes: 'alarpes',
  alarpou: 'alarpou',
  alaruas: 'alaruás',
  alarves: 'alarves',
  alarvia: 'alarvia',
  alasias: 'alásias',
  alassai: 'alassai',
  alassam: 'alassam',
  alassar: 'alassar',
  alassas: 'alassas',
  alassei: 'alassei',
  alassem: 'alassem',
  alasses: 'alasses',
  alassou: 'alassou',
  alastes: 'alastes',
  alastor: 'alastor',
  alastra: 'alastra',
  alastre: 'alastre',
  alastro: 'alastro',
  alatina: 'alatina',
  alatine: 'alatine',
  alatino: 'alatino',
  alatita: 'alatita',
  alatite: 'alatite',
  alatito: 'alatito',
  alativa: 'alativa',
  alativo: 'alativo',
  alatoai: 'alatoai',
  alatoam: 'alatoam',
  alatoar: 'alatoar',
  alatoas: 'alatoas',
  alatoei: 'alatoei',
  alatoem: 'alatoem',
  alatoes: 'alatoes',
  alatoou: 'alatoou',
  alauate: 'alauate',
  alaudai: 'alaudai',
  alaudam: 'alaudam',
  alaudar: 'alaudar',
  alaudas: 'alaudas',
  alaudei: 'alaudei',
  alaudem: 'alaudem',
  alaudes: 'alaúdes',
  alaudou: 'alaudou',
  alauita: 'alauita',
  alaures: 'alaures',
  alavaes: 'alavães',
  alaveis: 'aláveis',
  alavela: 'alavela',
  alavete: 'alavete',
  alazada: 'alazada',
  alazado: 'alazado',
  alazaes: 'alazães',
  alazais: 'alazais',
  alazamo: 'alazamo',
  alazara: 'alazará',
  alazava: 'alazava',
  alazeis: 'alazeis',
  alazemo: 'alazemo',
  alazoes: 'alazões',
  albacao: 'albação',
  albacar: 'albacar',
  albacor: 'albacor',
  albafar: 'albafar',
  albafor: 'albafor',
  albalas: 'albalãs',
  albanas: 'albanas',
  albanel: 'albanel',
  albanes: 'albanês',
  albanos: 'albanos',
  albaras: 'albarás',
  albaraz: 'albaraz',
  albarca: 'albarca',
  albarda: 'albarda',
  albarde: 'albarde',
  albardo: 'albardo',
  albario: 'albário',
  albarno: 'albarnó',
  albarra: 'albarrã',
  albarre: 'albarre',
  albarro: 'albarro',
  albatos: 'albatos',
  albedem: 'albedém',
  albedos: 'albedos',
  albenas: 'albenas',
  albende: 'albende',
  albenos: 'albenos',
  albenoz: 'albenoz',
  albense: 'albense',
  albente: 'albente',
  albeoge: 'albeoge',
  albeogo: 'albeogo',
  albeque: 'albeque',
  alberca: 'alberca',
  alberco: 'alberco',
  alberga: 'alberga',
  alberge: 'alberge',
  albergo: 'albergo',
  alberno: 'albernó',
  albesca: 'albesca',
  albesco: 'albesco',
  albiana: 'albiana',
  albiano: 'albiano',
  albicas: 'álbicas',
  albicos: 'álbicos',
  albidai: 'albidai',
  albidam: 'albidam',
  albidar: 'albidar',
  albidas: 'álbidas',
  albidei: 'albidei',
  albidem: 'albidem',
  albides: 'albides',
  albidos: 'álbidos',
  albidou: 'albidou',
  albinas: 'albinas',
  albinia: 'albinia',
  albinos: 'albinos',
  albioes: 'albiões',
  albiona: 'albiona',
  albitas: 'albitas',
  albites: 'albites',
  albitos: 'albitos',
  albizos: 'albizos',
  albizzo: 'albizzo',
  albocai: 'albocai',
  albocam: 'albocam',
  albocar: 'albocar',
  albocas: 'albocas',
  albocou: 'albocou',
  albogue: 'albogue',
  alboios: 'alboios',
  alboque: 'alboque',
  alboras: 'alboras',
  alborca: 'alborca',
  alborco: 'alborco',
  alborea: 'alboreá',
  albores: 'albores',
  alborno: 'albornó',
  albucas: 'albucas',
  albucor: 'albucor',
  albucos: 'albucos',
  albufar: 'albufar',
  albugem: 'albugem',
  albugos: 'albugos',
  albulas: 'álbulas',
  albulos: 'álbulos',
  albumem: 'álbumem',
  albumen: 'albúmen',
  albumes: 'albumes',
  albunea: 'albúnea',
  albunia: 'albúnia',
  albures: 'albures',
  alburne: 'alburne',
  alburno: 'alburno',
  alcabol: 'alcabol',
  alcabos: 'alcabós',
  alcaboz: 'alcaboz',
  alcabro: 'alcabró',
  alcacam: 'alcáçam',
  alcacao: 'alçação',
  alcacar: 'alcáçar',
  alcacas: 'alcaças',
  alcacei: 'alcácei',
  alcacel: 'alcacel',
  alcacer: 'alcácer',
  alcaces: 'alcáces',
  alcaceu: 'alcáceu',
  alcacus: 'alcaçus',
  alcacuz: 'alcaçuz',
  alcadao: 'alçadão',
  alcadas: 'alçadas',
  alcadea: 'alcádea',
  alcades: 'alcades',
  alcador: 'alçador',
  alcados: 'alçados',
  alcafar: 'alcáfar',
  alcafer: 'alcáfer',
  alcafor: 'alcafor',
  alcagem: 'alçagem',
  alcaica: 'alcaica',
  alcaico: 'alcaico',
  alcaide: 'alcaide',
  alcaima: 'alcaima',
  alcaixa: 'alcaixa',
  alcalas: 'alcalas',
  alcalda: 'alcaldá',
  alcalde: 'alcalde',
  alcaldo: 'alcaldo',
  alcalis: 'álcalis',
  alcamos: 'alçamos',
  alcanas: 'alcanas',
  alcanca: 'alcança',
  alcance: 'alcance',
  alcanco: 'alcanço',
  alcando: 'alçando',
  alcanol: 'alcanol',
  alcanos: 'alcanos',
  alcante: 'alçante',
  alcapao: 'alçapão',
  alcaram: 'alçaram',
  alcarao: 'alçarão',
  alcaras: 'alçarás',
  alcarei: 'alçarei',
  alcarem: 'alçarem',
  alcares: 'alçares',
  alcaria: 'alcaria',
  alcarmo: 'alçarmo',
  alcasse: 'alçasse',
  alcaste: 'alçaste',
  alcatea: 'alcateá',
  alcatra: 'alcatra',
  alcatre: 'alcatre',
  alcavam: 'alçavam',
  alcavas: 'alçavas',
  alcaxas: 'alcaxas',
  alcazes: 'alcazes',
  alceada: 'alceada',
  alceado: 'alceado',
  alceais: 'alceais',
  alceame: 'alceame',
  alceara: 'alceará',
  alceava: 'alceava',
  alcedas: 'alcedas',
  alcedos: 'alcedos',
  alceeis: 'alceeis',
  alceiam: 'alceiam',
  alceias: 'alceias',
  alceiem: 'alceiem',
  alceies: 'alceies',
  alceira: 'alceira',
  alceiro: 'alceiro',
  alcemos: 'alcemos',
  alcenas: 'alcenas',
  alcenol: 'alcenol',
  alcenos: 'alcenos',
  alcepao: 'alcepão',
  alcidas: 'álcidas',
  alcidea: 'alcídea',
  alcideo: 'alcídeo',
  alcides: 'alcides',
  alcinas: 'alcinas',
  alcinha: 'alcinha',
  alcinos: 'alcinos',
  alcioes: 'alciões',
  alciona: 'alcíona',
  alcione: 'alcione',
  alcions: 'álcions',
  alcista: 'alcista',
  alcloxa: 'alcloxa',
  alcmena: 'alcmena',
  alcoato: 'alcoato',
  alcobas: 'alcobas',
  alcocre: 'alcocre',
  alcofai: 'alcofai',
  alcofam: 'alcofam',
  alcofar: 'alcofar',
  alcofas: 'alcofas',
  alcofei: 'alcofei',
  alcofem: 'alcofem',
  alcofes: 'alcofes',
  alcofor: 'alcofor',
  alcofou: 'alcofou',
  alcofra: 'alcofra',
  alcoice: 'alcoice',
  alcoila: 'alcoíla',
  alcoile: 'alcoile',
  alcoilo: 'alcoílo',
  alcoina: 'alcoína',
  alcoois: 'alcoóis',
  alcopes: 'alcopes',
  alcopos: 'alcopos',
  alcorao: 'alcorão',
  alcorca: 'alcorça',
  alcorce: 'alcorce',
  alcouce: 'alcouce',
  alcouco: 'alcouço',
  alcouve: 'alcouve',
  alcovas: 'alcovas',
  alcoves: 'alcovês',
  alcovez: 'alcovez',
  alcunha: 'alcunha',
  alcunhe: 'alcunhe',
  alcunho: 'alcunho',
  alcursa: 'alcursá',
  alcurse: 'alcurse',
  alcurso: 'alcurso',
  alcuzas: 'alcuzas',
  aldamas: 'aldamas',
  aldeada: 'aldeada',
  aldeado: 'aldeado',
  aldeaes: 'aldeães',
  aldeaga: 'aldeaga',
  aldeago: 'aldeago',
  aldeais: 'aldeais',
  aldeana: 'aldeana',
  aldeano: 'aldeano',
  aldeaos: 'aldeãos',
  aldeara: 'aldeará',
  aldeava: 'aldeava',
  aldebul: 'aldebul',
  aldeeis: 'aldeeis',
  aldegai: 'aldegai',
  aldegam: 'aldegam',
  aldegar: 'aldegar',
  aldegas: 'aldegas',
  aldegou: 'aldegou',
  aldeiam: 'aldeiam',
  aldeias: 'aldeias',
  aldeido: 'aldeído',
  aldeiem: 'aldeiem',
  aldeies: 'aldeies',
  aldeoes: 'aldeões',
  aldeola: 'aldeola',
  aldeota: 'aldeota',
  aldeote: 'aldeote',
  alderma: 'aldermã',
  aldinas: 'aldinas',
  aldinos: 'aldinos',
  aldioxa: 'aldioxa',
  aldoses: 'aldoses',
  aldraba: 'aldraba',
  aldrabe: 'aldrabe',
  aldrabo: 'aldrabo',
  aldrava: 'aldrava',
  aldrave: 'aldrave',
  aldravo: 'aldravo',
  aldrina: 'aldrina',
  aldrope: 'aldrope',
  aldruba: 'aldruba',
  aldrube: 'aldrube',
  aleadao: 'aleadão',
  aleadas: 'aleadas',
  aleador: 'aleador',
  aleados: 'aleados',
  alealda: 'alealda',
  alealde: 'alealde',
  alealdo: 'alealdo',
  aleamos: 'aleamos',
  aleando: 'aleando',
  aleante: 'aleante',
  alearam: 'alearam',
  alearao: 'alearão',
  alearas: 'alearas',
  alearei: 'alearei',
  alearem: 'alearem',
  aleares: 'aleares',
  alearia: 'alearia',
  aleasse: 'aleasse',
  aleaste: 'aleaste',
  aleavam: 'aleavam',
  aleavas: 'aleavas',
  alecita: 'alécita',
  alecito: 'alécito',
  alecrim: 'alecrim',
  alector: 'alector',
  alectos: 'alectos',
  alectra: 'alectra',
  aledada: 'aledada',
  aledado: 'aledado',
  aledais: 'aledais',
  aledamo: 'aledamo',
  aledara: 'aledará',
  aledava: 'aledava',
  aledeis: 'aledeis',
  aledemo: 'aledemo',
  aleemos: 'aleemos',
  alefriz: 'alefriz',
  alegada: 'alegada',
  alegado: 'alegado',
  alegais: 'alegais',
  alegamo: 'alegamo',
  alegara: 'alegara',
  alegava: 'alegava',
  alegrai: 'alegrai',
  alegram: 'alegram',
  alegrao: 'alegrão',
  alegrar: 'alegrar',
  alegras: 'alegras',
  alegrei: 'alegrei',
  alegrem: 'alegrem',
  alegres: 'alegres',
  alegria: 'alegria',
  alegros: 'alegros',
  alegrou: 'alegrou',
  aleguas: 'aleguás',
  aleguei: 'aleguei',
  aleguem: 'aleguem',
  alegues: 'alegues',
  aleijai: 'aleijai',
  aleijam: 'aleijam',
  aleijao: 'aleijão',
  aleijar: 'aleijar',
  aleijas: 'aleijas',
  aleijei: 'aleijei',
  aleijem: 'aleijem',
  aleijes: 'aleijes',
  aleijou: 'aleijou',
  aleiloa: 'aleiloa',
  aleiloe: 'aleiloe',
  aleiloo: 'aleiloo',
  aleiona: 'aleiona',
  aleirai: 'aleirai',
  aleiram: 'aleiram',
  aleirar: 'aleirar',
  aleiras: 'aleiras',
  aleirei: 'aleirei',
  aleirem: 'aleirem',
  aleires: 'aleires',
  aleirou: 'aleirou',
  aleitai: 'aleitai',
  aleitam: 'aleitam',
  aleitar: 'aleitar',
  aleitas: 'aleitas',
  aleitei: 'aleitei',
  aleitem: 'aleitem',
  aleites: 'aleites',
  aleitoa: 'aleitoá',
  aleitoe: 'aleitoe',
  aleitoo: 'aleitoo',
  aleitou: 'aleitou',
  aleives: 'aleives',
  aleixar: 'aleixar',
  alelico: 'alélico',
  aleluia: 'aleluia',
  aleluie: 'aleluie',
  aleluio: 'aleluio',
  alemaes: 'alemães',
  alembai: 'alembai',
  alembam: 'alembam',
  alembar: 'alembar',
  alembas: 'alembas',
  alembei: 'alembei',
  alembem: 'alembem',
  alembes: 'alembes',
  alembou: 'alembou',
  alembra: 'alembra',
  alembre: 'alembre',
  alembro: 'alembro',
  alemoai: 'alemoai',
  alemoam: 'alemoam',
  alemoar: 'alemoar',
  alemoas: 'alemoas',
  alemoei: 'alemoei',
  alemoem: 'alemoem',
  alemoes: 'alemoes',
  alemoou: 'alemoou',
  alencos: 'alencós',
  alendar: 'alendar',
  alengas: 'alengas',
  alentai: 'alentai',
  alentam: 'alentam',
  alentar: 'alentar',
  alentas: 'alentas',
  alentei: 'alentei',
  alentem: 'alentem',
  alentes: 'alentes',
  alentos: 'alentos',
  alentou: 'alentou',
  aleonai: 'aleonai',
  aleonam: 'aleonam',
  aleonar: 'aleonar',
  aleonas: 'aleonas',
  aleonei: 'aleonei',
  aleonem: 'aleonem',
  aleones: 'aleones',
  aleonou: 'aleonou',
  aleoses: 'aleoses',
  alepina: 'alepina',
  alepino: 'alepino',
  alepiro: 'alepiro',
  alerces: 'alerces',
  alerdai: 'alerdai',
  alerdam: 'alerdam',
  alerdar: 'alerdar',
  alerdas: 'alerdas',
  alerdei: 'alerdei',
  alerdem: 'alerdem',
  alerdes: 'alerdes',
  alerdou: 'alerdou',
  alereao: 'alereão',
  alergia: 'alergia',
  aleriao: 'alerião',
  alertai: 'alertai',
  alertam: 'alertam',
  alertar: 'alertar',
  alertas: 'alertas',
  alertea: 'alerteá',
  alertei: 'alertei',
  alertem: 'alertem',
  alertes: 'alertes',
  alertou: 'alertou',
  alesada: 'alesada',
  alesado: 'alesado',
  alesais: 'alesais',
  alesamo: 'alesamo',
  alesara: 'alesara',
  alesava: 'alesava',
  aleseis: 'aleseis',
  alesemo: 'alesemo',
  alesmai: 'alesmai',
  alesmam: 'alesmam',
  alesmar: 'alesmar',
  alesmas: 'alesmas',
  alesmei: 'alesmei',
  alesmem: 'alesmem',
  alesmes: 'alesmes',
  alesmou: 'alesmou',
  alestai: 'alestai',
  alestam: 'alestam',
  alestar: 'alestar',
  alestas: 'alestas',
  alestei: 'alestei',
  alestem: 'alestem',
  alestes: 'alestes',
  alestou: 'alestou',
  aletico: 'alético',
  aletina: 'aletina',
  aletino: 'aletino',
  aletrai: 'aletrai',
  aletram: 'aletram',
  aletrar: 'aletrar',
  aletras: 'aletras',
  aletrei: 'aletrei',
  aletrem: 'aletrem',
  aletres: 'aletres',
  aletria: 'aletria',
  aletris: 'aletris',
  aletrou: 'aletrou',
  aleucia: 'aleucia',
  aleuria: 'aleuria',
  aleutes: 'aleútes',
  aleveda: 'aleveda',
  alevede: 'alevede',
  alevedo: 'alevedo',
  alevino: 'alevino',
  alevins: 'alevins',
  alevita: 'alevita',
  alexias: 'alexias',
  alexica: 'aléxica',
  alexico: 'aléxico',
  alexina: 'alexina',
  alfabar: 'alfábar',
  alfacal: 'alfaçal',
  alfacas: 'alfaças',
  alfaces: 'alfaces',
  alfacia: 'alfácia',
  alfacos: 'alfacos',
  alfadao: 'alfadão',
  alfadas: 'alfadas',
  alfadex: 'alfadex',
  alfador: 'alfador',
  alfados: 'alfados',
  alfafal: 'alfafal',
  alfafas: 'alfafas',
  alfaiai: 'alfaiai',
  alfaiam: 'alfaiam',
  alfaiar: 'alfaiar',
  alfaias: 'alfaias',
  alfaiei: 'alfaiei',
  alfaiem: 'alfaiem',
  alfaies: 'alfaies',
  alfaifa: 'alfaifa',
  alfaiou: 'alfaiou',
  alfalfa: 'alfalfa',
  alfamar: 'alfâmar',
  alfamas: 'alfamas',
  alfamia: 'alfamia',
  alfamos: 'alfamos',
  alfanai: 'alfanai',
  alfanam: 'alfanam',
  alfanar: 'alfanar',
  alfanas: 'alfanas',
  alfando: 'alfando',
  alfanei: 'alfanei',
  alfanem: 'alfanem',
  alfanes: 'alfanes',
  alfange: 'alfange',
  alfanja: 'alfanjá',
  alfanje: 'alfanje',
  alfanjo: 'alfanjo',
  alfanou: 'alfanou',
  alfante: 'alfante',
  alfaque: 'alfaque',
  alfaqui: 'alfaqui',
  alfaram: 'alfaram',
  alfarao: 'alfarão',
  alfaras: 'alfaras',
  alfaraz: 'alfaraz',
  alfarda: 'alfarda',
  alfarei: 'alfarei',
  alfarem: 'alfarém',
  alfares: 'alfares',
  alfarge: 'alfarge',
  alfaria: 'alfaria',
  alfario: 'alfario',
  alfarja: 'alfarja',
  alfarje: 'alfarje',
  alfarmo: 'alfarmo',
  alfarva: 'alfarva',
  alfasse: 'alfasse',
  alfaste: 'alfaste',
  alfavam: 'alfavam',
  alfavas: 'alfavas',
  alfazar: 'alfazar',
  alfecas: 'alfeças',
  alfeces: 'alfeces',
  alfeias: 'alfeias',
  alfeira: 'alfeira',
  alfeire: 'alfeire',
  alfeiro: 'alfeiro',
  alfeloa: 'alféloa',
  alfemos: 'alfemos',
  alfenai: 'alfenai',
  alfenam: 'alfenam',
  alfenar: 'alfenar',
  alfenas: 'alfenas',
  alfenei: 'alfenei',
  alfenem: 'alfenem',
  alfenes: 'alfenes',
  alfenim: 'alfenim',
  alfenis: 'alfenis',
  alfenou: 'alfenou',
  alfeque: 'alfeque',
  alferca: 'alferça',
  alferce: 'alferce',
  alferes: 'alferes',
  alferga: 'alferga',
  alferro: 'alferro',
  alfesso: 'alfesso',
  alfires: 'alfires',
  alfirme: 'alfirme',
  alfitra: 'alfitra',
  alfobre: 'alfobre',
  alfofre: 'alfofre',
  alfolas: 'alfolas',
  alfonsi: 'alfonsi',
  alforas: 'alforas',
  alforba: 'alforba',
  alforbe: 'alforbe',
  alforfa: 'alforfa',
  alforge: 'alforge',
  alforja: 'alforja',
  alforje: 'alforje',
  alforjo: 'alforjo',
  alforna: 'alforna',
  alforra: 'alforra',
  alforre: 'alforre',
  alforro: 'alforro',
  alforva: 'alforva',
  alforza: 'alforza',
  alfoses: 'alfoses',
  alfouve: 'alfouve',
  alfovas: 'alfovas',
  alfovre: 'alfovre',
  alfozes: 'alfozes',
  alfredo: 'alfredo',
  alfujas: 'alfujas',
  alfujes: 'alfujes',
  alfunda: 'alfunda',
  alfuras: 'alfuras',
  alfures: 'alfures',
  alfurja: 'alfurja',
  alfurje: 'alfurje',
  alfurus: 'alfurus',
  algacas: 'algaças',
  algacea: 'algácea',
  algaceo: 'algáceo',
  algacos: 'algaços',
  algalia: 'algália',
  algalie: 'algalie',
  algalio: 'algalio',
  algamia: 'algamia',
  algarao: 'algarão',
  algares: 'algares',
  algaria: 'algariá',
  algarie: 'algarie',
  algario: 'algario',
  algarve: 'algarve',
  algazar: 'algazar',
  algazas: 'algazas',
  algazel: 'algazel',
  algazes: 'algazes',
  algazus: 'algazus',
  algebra: 'álgebra',
  algedis: 'algedis',
  algedos: 'algedos',
  algelas: 'algelas',
  algemai: 'algemai',
  algemam: 'algemam',
  algemar: 'algemar',
  algemas: 'algemas',
  algemei: 'algemei',
  algemem: 'algemem',
  algemes: 'algemes',
  algemia: 'algemia',
  algemie: 'algemie',
  algemio: 'algemio',
  algemou: 'algemou',
  algenil: 'algenil',
  algenis: 'algenis',
  algente: 'algente',
  algeres: 'algeres',
  algeria: 'algéria',
  algeroz: 'algeroz',
  algesia: 'algesia',
  algibes: 'algibes',
  algicas: 'álgicas',
  algicos: 'álgicos',
  algidas: 'álgidas',
  algidez: 'algidez',
  algidos: 'álgidos',
  alginas: 'alginas',
  algirao: 'algirão',
  algiras: 'algiras',
  algiroz: 'algiroz',
  algodao: 'algodão',
  algodoa: 'algodoa',
  algodoe: 'algodoe',
  algodoo: 'algodoo',
  algoide: 'algoide',
  algoios: 'algoios',
  algomia: 'algomia',
  algoras: 'algoras',
  algores: 'algores',
  algosas: 'algosas',
  algoses: 'algoses',
  algosos: 'algosos',
  algovao: 'algovão',
  algozai: 'algozai',
  algozam: 'algozam',
  algozar: 'algozar',
  algozas: 'algozas',
  algozei: 'algozei',
  algozem: 'algozem',
  algozes: 'algozes',
  algozou: 'algozou',
  algrame: 'algrame',
  algreme: 'algreme',
  alguais: 'alguais',
  algueta: 'algueta',
  alguida: 'alguidá',
  alguide: 'alguide',
  alguido: 'alguido',
  algumas: 'algumas',
  algumia: 'algumia',
  algumim: 'algumim',
  algures: 'algures',
  alguria: 'algúria',
  alhacea: 'alhácea',
  alhaceo: 'alháceo',
  alhadas: 'alhadas',
  alhagea: 'alhágea',
  alhages: 'alhages',
  alhaima: 'alhaima',
  alhalme: 'alhalme',
  alhanai: 'alhanai',
  alhanam: 'alhanam',
  alhanar: 'alhanar',
  alhanas: 'alhanas',
  alhanei: 'alhanei',
  alhanem: 'alhanem',
  alhanes: 'alhanes',
  alhanou: 'alhanou',
  alharca: 'alharca',
  alhares: 'alhares',
  alheada: 'alheada',
  alheado: 'alheado',
  alheais: 'alheais',
  alheamo: 'alheamo',
  alheara: 'alheara',
  alheava: 'alheava',
  alheeis: 'alheeis',
  alheemo: 'alheemo',
  alheiam: 'alheiam',
  alheias: 'alheias',
  alheiem: 'alheiem',
  alheies: 'alheies',
  alheios: 'alheios',
  alheira: 'alheira',
  alheiro: 'alheiro',
  alhelas: 'alhelas',
  alhetas: 'alhetas',
  alhetes: 'alhetes',
  alhetos: 'alhetos',
  alhinho: 'alhinho',
  alhodra: 'alhodra',
  alhoras: 'alhoras',
  alhorca: 'alhorca',
  alhores: 'alhores',
  alhures: 'alhures',
  aliacea: 'aliácea',
  aliaceo: 'aliáceo',
  aliadai: 'aliadai',
  aliadam: 'aliadam',
  aliadao: 'aliadão',
  aliadar: 'aliadar',
  aliadas: 'aliadas',
  aliadei: 'aliadei',
  aliadem: 'aliadem',
  aliades: 'aliades',
  aliador: 'aliador',
  aliados: 'aliados',
  aliadou: 'aliadou',
  aliagem: 'aliagem',
  aliamba: 'aliamba',
  aliamos: 'aliamos',
  alianca: 'aliança',
  aliance: 'aliance',
  alianco: 'alianço',
  aliando: 'aliando',
  aliante: 'aliante',
  aliaram: 'aliaram',
  aliarao: 'aliarão',
  aliaras: 'aliaras',
  aliarei: 'aliarei',
  aliarem: 'aliarem',
  aliares: 'aliares',
  aliaria: 'aliária',
  aliarmo: 'aliarmo',
  aliasse: 'aliasse',
  aliaste: 'aliaste',
  aliates: 'aliates',
  aliavam: 'aliavam',
  aliavas: 'aliavas',
  aliavel: 'aliável',
  aliaxes: 'aliaxés',
  aliazar: 'aliazar',
  alibana: 'alibana',
  alibano: 'alibano',
  alicais: 'alicais',
  alicali: 'alicali',
  alicate: 'alicate',
  alicece: 'alicece',
  aliciai: 'aliciai',
  aliciam: 'aliciam',
  aliciar: 'aliciar',
  alicias: 'alicias',
  aliciei: 'aliciei',
  aliciem: 'aliciem',
  alicies: 'alicies',
  aliciou: 'aliciou',
  alicota: 'alícota',
  alicuis: 'alicuís',
  alicula: 'alícula',
  alicuri: 'alicuri',
  alidada: 'alidada',
  alidade: 'alidade',
  alidora: 'alidora',
  aliemos: 'aliemos',
  alienai: 'alienai',
  alienam: 'alienam',
  alienar: 'alienar',
  alienas: 'alienas',
  alienei: 'alienei',
  alienem: 'alienem',
  alienes: 'alienes',
  alienia: 'alienia',
  alienou: 'alienou',
  alifafe: 'alifafe',
  alifana: 'alifana',
  alifano: 'alifano',
  alifera: 'alífera',
  alifero: 'alífero',
  alifuga: 'alífuga',
  alifugo: 'alífugo',
  aligada: 'aligada',
  aligado: 'aligado',
  aligais: 'aligais',
  aligara: 'aligará',
  aligava: 'aligava',
  aligera: 'alígera',
  aligero: 'alígero',
  aliguei: 'aliguei',
  aliguem: 'aliguem',
  aligues: 'aligues',
  aliinas: 'aliinas',
  aliinia: 'aliínia',
  aliinio: 'aliínio',
  alijada: 'alijada',
  alijado: 'alijado',
  alijais: 'alijais',
  alijamo: 'alijamo',
  alijara: 'alijara',
  alijava: 'alijava',
  alijeis: 'alijeis',
  alijemo: 'alijemo',
  alilena: 'alilena',
  alileno: 'alileno',
  alileto: 'alileto',
  alilica: 'alílica',
  alilico: 'alílico',
  alilina: 'alilina',
  alilios: 'alílios',
  alilzao: 'alilzão',
  alimada: 'alimada',
  alimado: 'alimado',
  alimais: 'alimais',
  alimpai: 'alimpai',
  alimpam: 'alimpam',
  alimpar: 'alimpar',
  alimpas: 'alimpas',
  alimpei: 'alimpei',
  alimpem: 'alimpem',
  alimpes: 'alimpes',
  alimpou: 'alimpou',
  alindai: 'alindai',
  alindam: 'alindam',
  alindar: 'alindar',
  alindas: 'alindas',
  alindei: 'alindei',
  alindem: 'alindem',
  alindes: 'alindes',
  alindou: 'alindou',
  alineai: 'alineai',
  alinear: 'alinear',
  alineas: 'alíneas',
  alineei: 'alineei',
  alineia: 'alineia',
  alineie: 'alineie',
  alineio: 'alineio',
  alineou: 'alineou',
  alinfia: 'alinfia',
  alinhai: 'alinhai',
  alinham: 'alinham',
  alinhar: 'alinhar',
  alinhas: 'alinhas',
  alinhei: 'alinhei',
  alinhem: 'alinhem',
  alinhes: 'alinhes',
  alinhos: 'alinhos',
  alinhou: 'alinhou',
  alinita: 'alinita',
  alinite: 'alinite',
  alionia: 'aliónia',
  alioses: 'alioses',
  aliosia: 'aliósia',
  aliosio: 'aliósio',
  alipata: 'alipata',
  alipede: 'alípede',
  alipilo: 'alípilo',
  alipina: 'alipina',
  alipita: 'alipita',
  alipite: 'alipite',
  alipito: 'alipito',
  aliptas: 'aliptas',
  aliptos: 'aliptos',
  alisada: 'alisada',
  alisado: 'alisado',
  alisais: 'alisais',
  alisamo: 'alisamo',
  alisara: 'alisara',
  alisava: 'alisava',
  aliseas: 'alíseas',
  aliseis: 'aliseis',
  alisemo: 'alisemo',
  aliseos: 'alíseos',
  aliseus: 'aliseus',
  alisias: 'alísias',
  alisina: 'alisina',
  alisios: 'alísios',
  alismas: 'alismas',
  alismos: 'alismos',
  alisoes: 'alisões',
  alissos: 'alissos',
  alistai: 'alistai',
  alistam: 'alistam',
  alistao: 'alistão',
  alistar: 'alistar',
  alistas: 'alistas',
  alistei: 'alistei',
  alistem: 'alistem',
  alistes: 'alistes',
  alistou: 'alistou',
  alitera: 'alitera',
  alitere: 'alitere',
  alitero: 'alitero',
  alitica: 'alítica',
  alitiza: 'alitizá',
  alitize: 'alitize',
  alitizo: 'alitizo',
  aliviai: 'aliviai',
  aliviam: 'aliviam',
  aliviar: 'aliviar',
  alivias: 'alivias',
  aliviei: 'aliviei',
  aliviem: 'aliviem',
  alivies: 'alivies',
  alivios: 'alívios',
  aliviou: 'aliviou',
  alixada: 'alixada',
  alixado: 'alixado',
  alizaba: 'alizaba',
  alizari: 'alizari',
  alizita: 'alizita',
  alizito: 'alizito',
  aljabao: 'aljabão',
  aljafra: 'aljafra',
  aljamia: 'aljamia',
  aljamie: 'aljamie',
  aljamio: 'aljamio',
  aljaraz: 'aljaraz',
  aljaroz: 'aljaroz',
  aljarze: 'aljarze',
  aljavai: 'aljavai',
  aljavam: 'aljavam',
  aljavar: 'aljavar',
  aljavas: 'aljavas',
  aljavei: 'aljavei',
  aljavem: 'aljavem',
  aljaves: 'aljaves',
  aljavou: 'aljavou',
  aljazar: 'aljazar',
  aljofai: 'aljôfai',
  aljofam: 'aljôfam',
  aljofar: 'aljôfar',
  aljofas: 'aljôfas',
  aljofei: 'aljôfei',
  aljofem: 'aljôfem',
  aljofes: 'aljôfes',
  aljofou: 'aljôfou',
  aljofra: 'aljofra',
  aljofre: 'aljofre',
  aljofro: 'aljofro',
  aljorce: 'aljorce',
  aljorge: 'aljorge',
  aljorxe: 'aljorxe',
  aljorze: 'aljorze',
  aljubas: 'aljubas',
  aljubes: 'aljubes',
  aljuzes: 'aljuzes',
  allegro: 'allegro',
  alltype: 'alltype',
  almaala: 'almaala',
  almacas: 'almaças',
  almacos: 'almaços',
  almadia: 'almadia',
  almadra: 'almadra',
  almafas: 'almafas',
  almafre: 'almafre',
  almagra: 'almagra',
  almagre: 'almagre',
  almagro: 'almagro',
  almalas: 'almalas',
  almalha: 'almalha',
  almalho: 'almalho',
  almamil: 'almamil',
  almamis: 'almamis',
  almanca: 'almança',
  almania: 'almânia',
  almaraz: 'almaraz',
  almarea: 'almareá',
  almarge: 'almarge',
  almario: 'almário',
  almarje: 'almarje',
  almassa: 'almassa',
  almatra: 'almatrá',
  almaxar: 'almaxar',
  almazem: 'almazém',
  almeces: 'almeces',
  almegue: 'almegue',
  almeias: 'almeias',
  almeice: 'almeice',
  almeida: 'almeida',
  almeira: 'almeira',
  almeiro: 'almeiro',
  almejai: 'almejai',
  almejam: 'almejam',
  almejar: 'almejar',
  almejas: 'almejas',
  almejei: 'almejei',
  almejem: 'almejem',
  almejes: 'almejes',
  almejos: 'almejos',
  almejou: 'almejou',
  almenar: 'almenar',
  almenos: 'almenos',
  almense: 'almense',
  almexar: 'almexar',
  almexia: 'almexia',
  almezar: 'almezar',
  almezas: 'almezas',
  almezes: 'almezes',
  almiara: 'almiara',
  almicas: 'almiças',
  almices: 'almices',
  almicos: 'almiços',
  almilha: 'almilha',
  alminha: 'alminha',
  almiqui: 'almiqui',
  almixar: 'almixar',
  almoada: 'almóada',
  almoade: 'almôade',
  almoala: 'almoala',
  almocai: 'almoçai',
  almocam: 'almoçam',
  almocar: 'almoçar',
  almocas: 'almoças',
  almocei: 'almocei',
  almocem: 'almocem',
  almoces: 'almoces',
  almocos: 'almoços',
  almocou: 'almoçou',
  almoeda: 'almoeda',
  almoede: 'almoede',
  almoedo: 'almoedo',
  almofar: 'almôfar',
  almofia: 'almofia',
  almofre: 'almofre',
  almudai: 'almudai',
  almudam: 'almudam',
  almudar: 'almudar',
  almudas: 'almudas',
  almudei: 'almudei',
  almudem: 'almudem',
  almudes: 'almudes',
  almudou: 'almudou',
  almugem: 'almugem',
  almugue: 'almugue',
  almuzes: 'almuzes',
  alnaibe: 'alnaíbe',
  alnedos: 'alnedos',
  alneita: 'alneíta',
  alneito: 'alneíto',
  alnicos: 'alnicos',
  alnites: 'alnites',
  alobara: 'alóbara',
  alobaro: 'alóbaro',
  alocada: 'alocada',
  alocado: 'alocado',
  alocais: 'alocais',
  alocamo: 'alocamo',
  alocara: 'alocara',
  alocava: 'alocava',
  alocero: 'alócero',
  alocroa: 'alócroa',
  alocroo: 'alócroo',
  alodapa: 'alodapa',
  alodapo: 'alódapo',
  alodial: 'alodial',
  alodios: 'alódios',
  alofana: 'alofana',
  alofano: 'alofano',
  alofila: 'alofila',
  alofilo: 'alofilo',
  alofita: 'alofita',
  alofite: 'alofite',
  alofone: 'alofone',
  alofono: 'alófono',
  aloforo: 'alóforo',
  alogama: 'alógama',
  alogamo: 'alógamo',
  alogena: 'alógena',
  alogeno: 'alógeno',
  alogias: 'alogias',
  alogica: 'alógica',
  alogico: 'alógico',
  alogios: 'alógios',
  alogona: 'alógona',
  alogono: 'alógono',
  aloicas: 'aloicas',
  aloicos: 'aloicos',
  aloides: 'aloides',
  aloinas: 'aloínas',
  aloinea: 'aloínea',
  aloirai: 'aloirai',
  aloiram: 'aloiram',
  aloirar: 'aloirar',
  aloiras: 'aloiras',
  aloirei: 'aloirei',
  aloirem: 'aloirem',
  aloires: 'aloires',
  aloirou: 'aloirou',
  aloisai: 'aloisai',
  aloisam: 'aloisam',
  aloisar: 'aloisar',
  aloisas: 'aloisas',
  aloisei: 'aloisei',
  aloisem: 'aloisem',
  aloises: 'aloises',
  aloisol: 'aloisol',
  aloisou: 'aloisou',
  aloitai: 'aloitai',
  aloitam: 'aloitam',
  aloitar: 'aloitar',
  aloitas: 'aloitas',
  aloitei: 'aloitei',
  aloitem: 'aloitem',
  aloites: 'aloites',
  aloitos: 'aloítos',
  aloitou: 'aloitou',
  alojada: 'alojada',
  alojado: 'alojado',
  alojais: 'alojais',
  alojamo: 'alojamo',
  alojara: 'alojara',
  alojava: 'alojava',
  alojeis: 'alojeis',
  alojemo: 'alojemo',
  alombai: 'alombai',
  alombam: 'alombam',
  alombar: 'alombar',
  alombas: 'alombas',
  alombei: 'alombei',
  alombem: 'alombem',
  alombes: 'alombes',
  alombou: 'alombou',
  alomear: 'alomear',
  alomera: 'alômera',
  alomero: 'alômero',
  alomias: 'alômias',
  alongai: 'alongai',
  alongam: 'alongam',
  alongar: 'alongar',
  alongas: 'alongas',
  alongou: 'alongou',
  alongue: 'alongue',
  alonima: 'alônima',
  alonimo: 'alônimo',
  alonoma: 'alônoma',
  alonomo: 'alônomo',
  alonsas: 'alonsas',
  alonsoa: 'alônsoa',
  alonsos: 'alonsos',
  alopata: 'alopata',
  alopias: 'alópias',
  alopola: 'alópola',
  alopolo: 'alópolo',
  aloporo: 'alóporo',
  aloprai: 'aloprai',
  alopram: 'alopram',
  aloprar: 'aloprar',
  alopras: 'alopras',
  aloprei: 'aloprei',
  aloprem: 'aloprem',
  alopres: 'alopres',
  aloprou: 'aloprou',
  aloptas: 'aloptas',
  aloquei: 'aloquei',
  aloquem: 'aloquem',
  aloques: 'aloques',
  aloquia: 'aloquia',
  alorita: 'alorita',
  alorpai: 'alorpai',
  alorpam: 'alorpam',
  alorpar: 'alorpar',
  alorpas: 'alorpas',
  alorpei: 'alorpei',
  alorpem: 'alorpem',
  alorpes: 'alorpes',
  alorpou: 'alorpou',
  alosnas: 'alosnas',
  alotada: 'alotada',
  alotado: 'alotado',
  alotais: 'alotais',
  alotamo: 'alotamo',
  alotara: 'alotará',
  alotava: 'alotava',
  aloteis: 'aloteis',
  alotemo: 'alotemo',
  alotipo: 'alótipo',
  alotone: 'alotone',
  alotria: 'alótria',
  alotrio: 'alótrio',
  aloucai: 'aloucai',
  aloucam: 'aloucam',
  aloucar: 'aloucar',
  aloucas: 'aloucas',
  aloucou: 'aloucou',
  alouque: 'alouque',
  alourai: 'alourai',
  alouram: 'alouram',
  alourar: 'alourar',
  alouras: 'alouras',
  alourei: 'alourei',
  alourem: 'alourem',
  aloures: 'aloures',
  alourou: 'alourou',
  alousai: 'alousai',
  alousam: 'alousam',
  alousar: 'alousar',
  alousas: 'alousas',
  alousei: 'alousei',
  alousem: 'alousem',
  alouses: 'alouses',
  alousou: 'alousou',
  alovuco: 'alovuco',
  aloxana: 'aloxana',
  aloxina: 'aloxina',
  aloxita: 'aloxita',
  alozima: 'alozima',
  alpacas: 'alpacas',
  alpacos: 'alpacos',
  alpagas: 'alpagas',
  alpaque: 'alpaque',
  alparca: 'alparca',
  alpardo: 'alpardo',
  alpeces: 'alpeces',
  alpedos: 'alpedos',
  alpense: 'alpense',
  alperce: 'alperce',
  alpicas: 'álpicas',
  alpicos: 'álpicos',
  alpidem: 'alpidem',
  alpinai: 'alpinai',
  alpinam: 'alpinam',
  alpinar: 'alpinar',
  alpinas: 'alpinas',
  alpinei: 'alpinei',
  alpinem: 'alpinem',
  alpines: 'alpines',
  alpinia: 'alpínia',
  alpinol: 'alpinol',
  alpinos: 'alpinos',
  alpinou: 'alpinou',
  alpista: 'alpista',
  alpiste: 'alpiste',
  alpisto: 'alpisto',
  alpivre: 'alpivre',
  alporao: 'alporão',
  alporca: 'alporca',
  alporco: 'alporco',
  alprons: 'alprons',
  alqueno: 'alqueno',
  alqueve: 'alqueve',
  alquiai: 'alquiai',
  alquiam: 'alquiam',
  alquiar: 'alquiar',
  alquias: 'alquias',
  alquice: 'alquicé',
  alquiei: 'alquiei',
  alquiem: 'alquiem',
  alquies: 'alquies',
  alquiez: 'alquiez',
  alquifa: 'alquifa',
  alquifu: 'alquifu',
  alquila: 'alquila',
  alquile: 'alquilé',
  alquilo: 'alquilo',
  alquime: 'alquime',
  alquina: 'alquiná',
  alquine: 'alquine',
  alquino: 'alquino',
  alquiou: 'alquiou',
  alretes: 'alretes',
  alriota: 'alriota',
  alrotai: 'alrotai',
  alrotam: 'alrotam',
  alrotar: 'alrotar',
  alrotas: 'alrotas',
  alrotei: 'alrotei',
  alrotem: 'alrotem',
  alrotes: 'alrotes',
  alrotou: 'alrotou',
  alrunes: 'alrunes',
  alrutes: 'alrutes',
  alsacia: 'alsácia',
  alsafas: 'alsafãs',
  alsidia: 'alsídia',
  alsinas: 'álsinas',
  alsinea: 'alsínea',
  alsines: 'álsines',
  alsinos: 'alsinos',
  alsodea: 'alsódea',
  alsonas: 'alsonas',
  alsonia: 'alsônia',
  altaica: 'altaica',
  altaico: 'altaico',
  altaita: 'altaíta',
  altamia: 'altamia',
  altanai: 'altanai',
  altanam: 'altanam',
  altanar: 'altanar',
  altanas: 'altanas',
  altanea: 'altaneá',
  altanei: 'altanei',
  altanem: 'altanem',
  altanes: 'altanes',
  altania: 'altania',
  altanou: 'altanou',
  altanto: 'altanto',
  altares: 'altares',
  altareu: 'altaréu',
  altarum: 'altarum',
  altaruz: 'altaruz',
  alteada: 'alteada',
  alteado: 'alteado',
  alteais: 'alteais',
  alteamo: 'alteamo',
  alteara: 'alteara',
  alteava: 'alteava',
  alteeis: 'alteeis',
  alteemo: 'alteemo',
  alteiam: 'alteiam',
  alteias: 'alteias',
  alteiem: 'alteiem',
  alteies: 'alteies',
  alteina: 'alteína',
  altenia: 'altênia',
  altense: 'altense',
  alterai: 'alterai',
  alteram: 'alteram',
  alterar: 'alterar',
  alteras: 'alteras',
  alterca: 'alterca',
  alterco: 'alterco',
  alterei: 'alterei',
  alterem: 'alterem',
  alteres: 'alteres',
  alterna: 'alterna',
  alterne: 'alterne',
  alterno: 'alterno',
  alterou: 'alterou',
  altezas: 'altezas',
  alticas: 'álticas',
  alticos: 'álticos',
  altinca: 'altincá',
  altinco: 'altinco',
  altinha: 'altinha',
  altinho: 'altinho',
  altinos: 'altinos',
  altirna: 'altirna',
  altisas: 'altisas',
  altismo: 'altismo',
  altista: 'altista',
  altivai: 'altivai',
  altivam: 'altivam',
  altivar: 'altivar',
  altivas: 'altivas',
  altivei: 'altivei',
  altivem: 'altivem',
  altives: 'altives',
  altivez: 'altivez',
  altivos: 'altivos',
  altivou: 'altivou',
  altoras: 'altoras',
  altores: 'altores',
  altosas: 'altosas',
  altrose: 'altrose',
  alturas: 'alturas',
  aluadao: 'aluadão',
  aluadas: 'aluadas',
  aluador: 'aluador',
  aluados: 'aluados',
  aluamos: 'aluamos',
  aluanda: 'aluanda',
  aluando: 'aluando',
  aluante: 'aluante',
  aluaram: 'aluaram',
  aluarao: 'aluarão',
  aluaras: 'aluarás',
  aluarei: 'aluarei',
  aluarem: 'aluarem',
  aluares: 'aluares',
  aluaria: 'aluaria',
  aluarmo: 'aluarmo',
  aluasse: 'aluasse',
  aluaste: 'aluaste',
  aluatas: 'aluatas',
  aluatos: 'aluatos',
  aluavam: 'aluavam',
  aluavas: 'aluavas',
  alucina: 'alucina',
  alucine: 'alucine',
  alucino: 'alucino',
  alucita: 'alucita',
  aludais: 'aludais',
  aludamo: 'aludamo',
  aludeis: 'aludéis',
  aludemo: 'aludemo',
  aludiam: 'aludiam',
  aludias: 'aludias',
  aludida: 'aludida',
  aludido: 'aludido',
  aludimo: 'aludimo',
  aludira: 'aludira',
  alueira: 'alueira',
  alueiro: 'alueiro',
  aluemos: 'aluemos',
  alufada: 'alufada',
  alugada: 'alugada',
  alugado: 'alugado',
  alugais: 'alugais',
  alugamo: 'alugamo',
  alugara: 'alugara',
  alugava: 'alugava',
  aluguei: 'aluguei',
  aluguel: 'aluguel',
  aluguem: 'aluguem',
  aluguer: 'aluguer',
  alugues: 'alugues',
  aluicao: 'aluição',
  aluidao: 'aluidão',
  aluidas: 'aluídas',
  aluidor: 'aluidor',
  aluidos: 'aluídos',
  aluieis: 'aluíeis',
  aluimos: 'aluímos',
  aluindo: 'aluindo',
  aluiram: 'aluíram',
  aluirao: 'aluirão',
  aluiras: 'aluirás',
  aluirei: 'aluirei',
  aluirem: 'aluírem',
  aluires: 'aluíres',
  aluiria: 'aluiria',
  aluisse: 'aluísse',
  aluiste: 'aluíste',
  alumada: 'alumada',
  alumado: 'alumado',
  alumais: 'alumais',
  alumamo: 'alumamo',
  alumara: 'alumara',
  alumava: 'alumava',
  alumbra: 'alumbra',
  alumbre: 'alumbre',
  alumbro: 'alumbro',
  alumeis: 'alumeis',
  alumemo: 'alumemo',
  alumens: 'alumens',
  alumiai: 'alumiai',
  alumiam: 'alumiam',
  alumiar: 'alumiar',
  alumias: 'alumias',
  alumiei: 'alumiei',
  alumiem: 'alumiem',
  alumies: 'alumies',
  alumina: 'alumina',
  alumine: 'alumine',
  alumino: 'alumino',
  alumiou: 'alumiou',
  alumita: 'alumita',
  alumnel: 'alumnel',
  alumnol: 'alumnol',
  alunada: 'alunada',
  alunado: 'alunado',
  alunais: 'alunais',
  alunamo: 'alunamo',
  alunara: 'alunará',
  alunato: 'alunato',
  alunava: 'alunava',
  alundos: 'alundos',
  alundum: 'alundum',
  aluneis: 'aluneis',
  alunemo: 'alunemo',
  alunica: 'alúnica',
  alunico: 'alúnico',
  alunita: 'alunita',
  alunite: 'alunite',
  alunito: 'alunito',
  aluniza: 'aluniza',
  alunize: 'alunize',
  alunizo: 'alunizo',
  alunois: 'alunóis',
  alupada: 'alupada',
  alupado: 'alupado',
  alupais: 'alupais',
  alupamo: 'alupamo',
  alupara: 'alupará',
  alupava: 'alupava',
  alupeis: 'alupeis',
  alupemo: 'alupemo',
  alurnes: 'alurnes',
  alurnos: 'alurnos',
  alusiva: 'alusiva',
  alusivo: 'alusivo',
  alusoes: 'alusões',
  alusona: 'alusona',
  alustra: 'alustrá',
  alustre: 'alustre',
  alustro: 'alustro',
  alutada: 'alutada',
  alutado: 'alutado',
  alutais: 'alutais',
  alutamo: 'alutamo',
  alutara: 'alutara',
  alutava: 'alutava',
  aluteis: 'aluteis',
  alutemo: 'alutemo',
  alutero: 'alutero',
  aluviai: 'aluviai',
  aluvial: 'aluvial',
  aluviam: 'aluviam',
  aluviao: 'aluvião',
  aluviar: 'aluviar',
  aluvias: 'aluvias',
  aluviei: 'aluviei',
  aluviem: 'aluviem',
  aluvies: 'aluvies',
  aluvios: 'alúvios',
  aluviou: 'aluviou',
  aluxada: 'aluxada',
  aluxado: 'aluxado',
  aluxais: 'aluxais',
  aluxamo: 'aluxamo',
  aluxara: 'aluxará',
  aluxava: 'aluxava',
  aluxeis: 'aluxeis',
  aluxemo: 'aluxemo',
  aluzais: 'aluzais',
  aluzamo: 'aluzamo',
  aluzeca: 'aluzeça',
  aluzece: 'aluzecê',
  aluzeci: 'aluzeci',
  aluzeco: 'aluzeço',
  aluziai: 'aluziai',
  aluziam: 'aluziam',
  aluziar: 'aluziar',
  aluzias: 'aluzias',
  aluzida: 'aluzida',
  aluzido: 'aluzido',
  aluziei: 'aluziei',
  aluziem: 'aluziem',
  aluzies: 'aluzies',
  aluzimo: 'aluzimo',
  aluziou: 'aluziou',
  aluzira: 'aluzirá',
  alvacao: 'alvação',
  alvacar: 'alvaçar',
  alvacas: 'alvacás',
  alvacim: 'alvacim',
  alvacos: 'alvacós',
  alvacuz: 'alvaçuz',
  alvadia: 'alvadia',
  alvadio: 'alvadio',
  alvaiai: 'alvaiai',
  alvaiam: 'alvaiam',
  alvaiar: 'alvaiar',
  alvaias: 'alvaias',
  alvaiei: 'alvaiei',
  alvaiem: 'alvaiem',
  alvaies: 'alvaies',
  alvaiou: 'alvaiou',
  alvalai: 'alvalai',
  alvalam: 'alvalam',
  alvalar: 'alvalar',
  alvalas: 'alvalas',
  alvalei: 'alvalei',
  alvalem: 'alvalem',
  alvales: 'alvales',
  alvalia: 'alvaliá',
  alvalie: 'alvalie',
  alvalio: 'alvalio',
  alvalou: 'alvalou',
  alvanar: 'alvanar',
  alvanel: 'alvanel',
  alvaner: 'alvaner',
  alvaneu: 'alvanéu',
  alvanil: 'alvanil',
  alvanir: 'alvanir',
  alvanis: 'alvanis',
  alvaral: 'alvaral',
  alvaras: 'alvarás',
  alvaraz: 'alvaraz',
  alvarda: 'alvarda',
  alvaros: 'alvarós',
  alvarra: 'alvarrã',
  alvazil: 'alvazil',
  alvazir: 'alvazir',
  alvazis: 'alvazis',
  alveada: 'alveada',
  alveado: 'alveado',
  alveais: 'alveais',
  alveara: 'alveará',
  alveava: 'alveava',
  alvecim: 'alvecim',
  alveeis: 'alveeis',
  alveiam: 'alveiam',
  alveias: 'alveias',
  alveici: 'alveici',
  alveiem: 'alveiem',
  alveies: 'alveies',
  alveina: 'alveína',
  alveira: 'alveira',
  alveire: 'alveire',
  alveiro: 'alveiro',
  alveita: 'alveita',
  alveite: 'alveite',
  alveito: 'alveito',
  alvejai: 'alvejai',
  alvejam: 'alvejam',
  alvejar: 'alvejar',
  alvejas: 'alvejas',
  alvejei: 'alvejei',
  alvejem: 'alvejem',
  alvejes: 'alvejes',
  alvejou: 'alvejou',
  alvelas: 'alvelas',
  alveloa: 'alvéloa',
  alveloz: 'alveloz',
  alvenas: 'alvenas',
  alvende: 'alvende',
  alvenel: 'alvenel',
  alvener: 'alvener',
  alveneu: 'alvenéu',
  alvense: 'alvense',
  alveola: 'alvéola',
  alveolo: 'alvéolo',
  alverca: 'alverca',
  alverga: 'alverga',
  alvergo: 'alvergo',
  alveroa: 'alvéroa',
  alvesca: 'alvesça',
  alvesce: 'alvesce',
  alvesci: 'alvesci',
  alvesco: 'alvesço',
  alvesia: 'alvesia',
  alvidai: 'alvidai',
  alvidam: 'alvidam',
  alvidar: 'alvidar',
  alvidas: 'alvidas',
  alvidei: 'alvidei',
  alvidem: 'alvidem',
  alvides: 'alvides',
  alvidou: 'alvidou',
  alvidra: 'alvidra',
  alvidre: 'alvidre',
  alvidro: 'álvidro',
  alvilha: 'alvilha',
  alvilho: 'alvilho',
  alvinas: 'alvinas',
  alvinea: 'alvínea',
  alvineo: 'alvíneo',
  alvinha: 'alvinha',
  alvinho: 'alvinho',
  alvinos: 'alvinos',
  alvioes: 'alviões',
  alvitas: 'alvitas',
  alvites: 'alvites',
  alvitra: 'alvitra',
  alvitre: 'alvitre',
  alvitro: 'alvitro',
  alvorai: 'alvorai',
  alvoram: 'alvoram',
  alvorar: 'alvorar',
  alvoras: 'alvoras',
  alvorea: 'alvoreá',
  alvorei: 'alvorei',
  alvorem: 'alvorem',
  alvores: 'alvores',
  alvorou: 'alvorou',
  alvotai: 'alvotai',
  alvotam: 'alvotam',
  alvotar: 'alvotar',
  alvotas: 'alvotas',
  alvotei: 'alvotei',
  alvotem: 'alvotem',
  alvotes: 'alvotes',
  alvotou: 'alvotou',
  alvuras: 'alvuras',
  alxaima: 'alxaima',
  alzaqui: 'alzaqui',
  alzaror: 'alzaror',
  alzatia: 'alzátia',
  alzubes: 'alzubes',
  amabile: 'amabile',
  amacaca: 'amacaca',
  amacaco: 'amacaco',
  amacada: 'amaçada',
  amacado: 'amaçado',
  amacais: 'amaçais',
  amacamo: 'amaçamo',
  amacara: 'amaçará',
  amacata: 'amacata',
  amacava: 'amaçava',
  amacaxi: 'amacaxi',
  amaceis: 'amaceis',
  amacemo: 'amacemo',
  amaciai: 'amaciai',
  amaciam: 'amaciam',
  amaciar: 'amaciar',
  amacias: 'amacias',
  amaciei: 'amaciei',
  amaciem: 'amaciem',
  amacies: 'amacies',
  amaciou: 'amaciou',
  amacoes: 'amações',
  amacona: 'amaçona',
  amadeus: 'amadeus',
  amadias: 'amadias',
  amadiga: 'amádiga',
  amadigo: 'amádigo',
  amadina: 'amadina',
  amadino: 'amadino',
  amadios: 'amadios',
  amadoes: 'amadões',
  amadona: 'amadona',
  amadora: 'amadora',
  amadria: 'amadria',
  amadrie: 'amadrie',
  amadrio: 'amadrio',
  amadura: 'amadura',
  amadure: 'amadure',
  amaduro: 'amaduro',
  amagada: 'amagada',
  amagado: 'amagado',
  amagais: 'amagais',
  amagara: 'amagará',
  amagava: 'amagava',
  amagota: 'amagota',
  amagote: 'amagote',
  amagoto: 'amagoto',
  amagrai: 'amagrai',
  amagram: 'amagram',
  amagrar: 'amagrar',
  amagras: 'amagras',
  amagrei: 'amagrei',
  amagrem: 'amagrem',
  amagres: 'amagres',
  amagrou: 'amagrou',
  amaguei: 'amaguei',
  amaguem: 'amaguem',
  amagues: 'amagues',
  amainai: 'amainai',
  amainam: 'amainam',
  amainar: 'amainar',
  amainas: 'amainas',
  amainei: 'amainei',
  amainem: 'amainem',
  amaines: 'amaines',
  amainho: 'amainho',
  amainou: 'amainou',
  amalaca: 'amálaca',
  amalago: 'amálago',
  amalcoa: 'amalçoá',
  amalcoe: 'amalçoe',
  amalcoo: 'amalçoo',
  amaldar: 'amaldar',
  amalfil: 'amalfil',
  amalfis: 'amalfis',
  amalhai: 'amalhai',
  amalham: 'amalham',
  amalhar: 'amalhar',
  amalhas: 'amalhas',
  amalhei: 'amalhei',
  amalhem: 'amalhem',
  amalhes: 'amalhes',
  amalhoa: 'amalhoa',
  amalhoe: 'amalhoe',
  amalhoo: 'amalhoo',
  amalhou: 'amalhou',
  amalica: 'amálica',
  amalico: 'amálico',
  amaloca: 'amaloca',
  amaloco: 'amaloco',
  amaltai: 'amaltai',
  amaltam: 'amaltam',
  amaltar: 'amaltar',
  amaltas: 'amaltas',
  amaltei: 'amaltei',
  amaltem: 'amaltem',
  amaltes: 'amaltes',
  amaltou: 'amaltou',
  amaluca: 'amaluca',
  amaluco: 'amaluco',
  amamona: 'amamona',
  amanaca: 'amanaca',
  amanaci: 'amanaci',
  amanacu: 'amanaçu',
  amanada: 'amanada',
  amanado: 'amanado',
  amanaga: 'amanaga',
  amanaie: 'amanaié',
  amanais: 'amanais',
  amanaiu: 'amanaiú',
  amanaja: 'amanaja',
  amanaje: 'amanajé',
  amanajo: 'amanajó',
  amanamo: 'amanamo',
  amanara: 'amanara',
  amanata: 'amanata',
  amanava: 'amanava',
  amancas: 'amanças',
  amancha: 'amancha',
  amanche: 'amanche',
  amancho: 'amancho',
  amandai: 'amandai',
  amandam: 'amandam',
  amandar: 'amandar',
  amandas: 'amandas',
  amandei: 'amandei',
  amandem: 'amandem',
  amandes: 'amandes',
  amandou: 'amandou',
  amaneis: 'amaneis',
  amanemo: 'amanemo',
  amangai: 'amangai',
  amangam: 'amangam',
  amangar: 'amangar',
  amangas: 'amangas',
  amangou: 'amangou',
  amangue: 'amangue',
  amanhai: 'amanhai',
  amanham: 'amanham',
  amanhar: 'amanhar',
  amanhas: 'amanhas',
  amanhei: 'amanhei',
  amanhem: 'amanhem',
  amanhes: 'amanhes',
  amanhia: 'amanhia',
  amanhio: 'amanhio',
  amanhos: 'amanhos',
  amanhou: 'amanhou',
  amanias: 'amaniás',
  amanita: 'amanita',
  amanius: 'amaniús',
  amanoas: 'amânoas',
  amanoes: 'amanões',
  amansai: 'amansai',
  amansam: 'amansam',
  amansar: 'amansar',
  amansas: 'amansas',
  amansei: 'amansei',
  amansem: 'amansem',
  amanses: 'amanses',
  amansia: 'amansia',
  amansou: 'amansou',
  amantai: 'amantai',
  amantam: 'amantam',
  amantar: 'amantar',
  amantas: 'amantas',
  amantei: 'amantei',
  amantem: 'amantem',
  amantes: 'amantes',
  amantou: 'amantou',
  amaozao: 'amãozão',
  amapola: 'amapola',
  amapuru: 'amapuru',
  amaquia: 'amaquiá',
  amaquie: 'amaquie',
  amaquio: 'amaquio',
  amaraco: 'amáraco',
  amarada: 'amarada',
  amarado: 'amarado',
  amarais: 'amarais',
  amaramo: 'amaramo',
  amarara: 'amarará',
  amarava: 'amarava',
  amardas: 'amardas',
  amardes: 'amardes',
  amardos: 'amardos',
  amareai: 'amareai',
  amarear: 'amarear',
  amareca: 'amareça',
  amarece: 'amarece',
  amareci: 'amareci',
  amareco: 'amareço',
  amareei: 'amareei',
  amareia: 'amareia',
  amareie: 'amareie',
  amareio: 'amareio',
  amareis: 'amareis',
  amareja: 'amarejá',
  amareje: 'amareje',
  amarejo: 'amarejo',
  amarela: 'amarela',
  amarele: 'amarele',
  amareli: 'amareli',
  amarelo: 'amarelo',
  amaremo: 'amaremo',
  amarena: 'amarena',
  amareou: 'amareou',
  amargai: 'amargai',
  amargam: 'amargam',
  amargar: 'amargar',
  amargas: 'amargas',
  amargor: 'amargor',
  amargos: 'amargos',
  amargou: 'amargou',
  amargue: 'amargue',
  amariam: 'amariam',
  amarias: 'amarias',
  amariba: 'amaribá',
  amarica: 'amarica',
  amarice: 'amarice',
  amarico: 'amárico',
  amarida: 'amarida',
  amaride: 'amaride',
  amarido: 'amarido',
  amarila: 'amarila',
  amarilo: 'amarilo',
  amarina: 'amarina',
  amarios: 'amarios',
  amaripa: 'amaripá',
  amariza: 'amarizá',
  amarize: 'amarize',
  amarizo: 'amarizo',
  amarmos: 'amarmos',
  amarona: 'amarona',
  amarosa: 'amarosa',
  amaroso: 'amaroso',
  amarota: 'amarota',
  amarote: 'amarote',
  amaroto: 'amaroto',
  amarrai: 'amarrai',
  amarram: 'amarram',
  amarrar: 'amarrar',
  amarras: 'amarras',
  amarrei: 'amarrei',
  amarrem: 'amarrem',
  amarres: 'amarres',
  amarroa: 'amarroa',
  amarroe: 'amarroe',
  amarroo: 'amarroo',
  amarrou: 'amarrou',
  amaruja: 'amaruja',
  amaruje: 'amaruje',
  amarujo: 'amarujo',
  amarume: 'amarume',
  amasiai: 'amasiai',
  amasiam: 'amasiam',
  amasiar: 'amasiar',
  amasias: 'amasias',
  amasiei: 'amasiei',
  amasiem: 'amasiem',
  amasies: 'amasies',
  amasios: 'amasios',
  amasiou: 'amasiou',
  amasisa: 'amasisa',
  amassai: 'amassai',
  amassam: 'amassam',
  amassar: 'amassar',
  amassas: 'amassas',
  amassei: 'amassei',
  amassem: 'amassem',
  amasses: 'amasses',
  amassos: 'amassos',
  amassou: 'amassou',
  amastas: 'amastas',
  amastes: 'amastes',
  amastia: 'amastia',
  amastos: 'amastos',
  amatada: 'amatada',
  amatado: 'amatado',
  amatais: 'amatais',
  amatala: 'amatala',
  amatale: 'amatale',
  amatalo: 'amatalo',
  amatamo: 'amatamo',
  amatara: 'amatara',
  amatava: 'amatava',
  amateas: 'amáteas',
  amateia: 'amateia',
  amateis: 'amateis',
  amatemo: 'amatemo',
  amateos: 'amáteos',
  amateus: 'amateus',
  amativa: 'amativa',
  amativo: 'amativo',
  amatois: 'amatóis',
  amatula: 'amatula',
  amatule: 'amatule',
  amatulo: 'amatulo',
  amatuta: 'amatuta',
  amatute: 'amatute',
  amatuto: 'amatuto',
  amauaca: 'amauaca',
  amaucas: 'amaúcas',
  amauria: 'amáuria',
  amauros: 'amauros',
  amautas: 'amautas',
  amaveis: 'amáveis',
  amavias: 'amavias',
  amavios: 'amavios',
  amazela: 'amazela',
  amazele: 'amazele',
  amazelo: 'amazelo',
  amazias: 'amazias',
  amazona: 'amazona',
  amazula: 'amazula',
  amazulo: 'amazulo',
  ambabas: 'ambabas',
  ambabos: 'ambabos',
  ambacas: 'ambacas',
  ambacte: 'ambacte',
  ambages: 'ambages',
  ambagio: 'ambágio',
  ambaiba: 'ambaíba',
  ambaida: 'ambaida',
  ambajos: 'ambajos',
  ambalao: 'ambalão',
  ambalos: 'ambalós',
  ambarai: 'ambarai',
  ambaram: 'ambaram',
  ambarar: 'ambarar',
  ambaras: 'ambaras',
  ambarei: 'ambarei',
  ambarem: 'ambarem',
  ambares: 'âmbares',
  ambaros: 'ambarós',
  ambarou: 'ambarou',
  ambarra: 'ambarra',
  ambarro: 'ambarro',
  ambasso: 'ambasso',
  ambauba: 'ambaúba',
  ambauva: 'ambaúva',
  amberas: 'amberas',
  amberol: 'amberol',
  ambiada: 'ambiada',
  ambiado: 'ambiado',
  ambiais: 'ambiais',
  ambiamo: 'ambiamo',
  ambiana: 'ambiana',
  ambiano: 'ambiano',
  ambiara: 'ambiará',
  ambiava: 'ambiava',
  ambicao: 'ambição',
  ambieis: 'ambieis',
  ambiemo: 'ambiemo',
  ambigua: 'ambígua',
  ambiguo: 'ambíguo',
  ambilas: 'ambilas',
  ambilia: 'ambília',
  ambiope: 'ambiope',
  ambiras: 'ambiras',
  ambires: 'ambires',
  ambitos: 'âmbitos',
  ambitua: 'ambítua',
  ambituo: 'ambítuo',
  ambivio: 'ambívio',
  amblema: 'amblema',
  amblose: 'amblose',
  amboina: 'amboína',
  amboins: 'amboins',
  amboles: 'ambolés',
  ambolim: 'ambolim',
  ambolos: 'ambolós',
  ambones: 'ambones',
  amboras: 'amborás',
  amborea: 'ambórea',
  amboreo: 'ambóreo',
  ambores: 'amborés',
  ambrada: 'ambrada',
  ambrado: 'ambrado',
  ambrais: 'ambrais',
  ambramo: 'ambramo',
  ambrara: 'ambrará',
  ambrava: 'ambrava',
  ambrazo: 'ambrazô',
  ambreai: 'ambreai',
  ambrear: 'ambrear',
  ambreei: 'ambreei',
  ambreia: 'ambreia',
  ambreie: 'ambreie',
  ambreio: 'ambreio',
  ambreis: 'ambreis',
  ambremo: 'ambremo',
  ambreou: 'ambreou',
  ambreta: 'ambreta',
  ambrina: 'ambrina',
  ambrita: 'ambrita',
  ambrite: 'ambrite',
  ambroes: 'ambrões',
  ambroma: 'ambroma',
  ambrona: 'ambrona',
  ambrone: 'ambrone',
  ambrono: 'ambrono',
  ambrosa: 'ambrosa',
  ambroso: 'ambroso',
  ambrozo: 'ambrozô',
  ambudes: 'ambudes',
  ambuela: 'ambuela',
  ambulai: 'ambulai',
  ambulam: 'ambulam',
  ambular: 'ambular',
  ambulas: 'ambulas',
  ambulei: 'ambulei',
  ambulem: 'ambulem',
  ambules: 'ambules',
  ambulia: 'ambúlia',
  ambulou: 'ambulou',
  ambunda: 'ambunda',
  ambundo: 'ambundo',
  ameacai: 'ameaçai',
  ameacam: 'ameaçam',
  ameacar: 'ameaçar',
  ameacas: 'ameaças',
  ameacei: 'ameacei',
  ameacem: 'ameacem',
  ameaces: 'ameaces',
  ameacos: 'ameaços',
  ameacou: 'ameaçou',
  ameadao: 'ameadão',
  ameadas: 'ameadas',
  ameador: 'ameador',
  ameados: 'ameados',
  amealha: 'amealha',
  amealhe: 'amealhe',
  amealho: 'amealho',
  ameamos: 'ameamos',
  ameando: 'ameando',
  ameante: 'ameante',
  amearam: 'amearam',
  amearao: 'amearão',
  amearas: 'amearás',
  amearei: 'amearei',
  amearem: 'amearem',
  ameares: 'ameares',
  amearia: 'amearia',
  ameasse: 'ameasse',
  ameaste: 'ameaste',
  ameavam: 'ameavam',
  ameavas: 'ameavas',
  amebeia: 'amebeia',
  amebeus: 'amebeus',
  amebica: 'amébica',
  amebico: 'amébico',
  amebida: 'amébida',
  amebido: 'amébido',
  amebina: 'amebina',
  amebino: 'amebino',
  amebita: 'amebita',
  amebite: 'amebite',
  amebito: 'amebito',
  amebose: 'amebose',
  amechai: 'amechai',
  amecham: 'amecham',
  amechar: 'amechar',
  amechas: 'amechas',
  amechei: 'amechei',
  amechem: 'amechem',
  ameches: 'ameches',
  amechou: 'amechou',
  amedeia: 'amedeia',
  amedina: 'amedina',
  amedine: 'amedine',
  amedino: 'amedino',
  ameemos: 'ameemos',
  ameense: 'ameense',
  ameiada: 'ameiada',
  ameiado: 'ameiado',
  ameiais: 'ameiais',
  ameiamo: 'ameiamo',
  ameiara: 'ameiará',
  ameiava: 'ameiava',
  ameieis: 'ameieis',
  ameiemo: 'ameiemo',
  ameigai: 'ameigai',
  ameigam: 'ameigam',
  ameigar: 'ameigar',
  ameigas: 'ameigas',
  ameigou: 'ameigou',
  ameigue: 'ameigue',
  ameijas: 'ameijas',
  ameijoa: 'ameijoa',
  ameijoe: 'ameijoe',
  ameijoo: 'ameijoo',
  ameijua: 'ameijua',
  ameijus: 'ameijus',
  ameivas: 'ameivas',
  ameixal: 'ameixal',
  ameixas: 'ameixas',
  ameixoa: 'amêixoa',
  amejuba: 'amejuba',
  amelaca: 'amelaça',
  amelace: 'amelace',
  amelaco: 'amelaço',
  amelada: 'amelada',
  amelado: 'amelado',
  ameleia: 'ameleia',
  amelias: 'amélias',
  amelica: 'amélica',
  amelico: 'amélico',
  amelida: 'amelida',
  amelina: 'amelina',
  amelios: 'amélios',
  ameloma: 'ameloma',
  amelpos: 'amelpos',
  amenaca: 'amenaça',
  amencia: 'amência',
  amendoa: 'amêndoa',
  amendoi: 'amendoí',
  amenias: 'amenias',
  amenica: 'amênica',
  amenico: 'amênico',
  amenina: 'amenina',
  amenine: 'amenine',
  amenino: 'amenino',
  ameniza: 'ameniza',
  amenize: 'amenize',
  amenizo: 'amenizo',
  amenosa: 'amenosa',
  amenoso: 'amenoso',
  amensal: 'amensal',
  amentai: 'amentai',
  amental: 'amental',
  amentam: 'amentam',
  amentar: 'amentar',
  amentas: 'amentas',
  amentei: 'amentei',
  amentem: 'amentem',
  amentes: 'amentes',
  amentio: 'amentio',
  amentos: 'amentos',
  amentou: 'amentou',
  ameraba: 'ameraba',
  amerade: 'amerade',
  amercea: 'amerceá',
  amergei: 'amergei',
  amergem: 'amergem',
  amerger: 'amerger',
  amerges: 'amerges',
  amergeu: 'amergeu',
  amergia: 'amergia',
  america: 'américa',
  amerina: 'amerina',
  amerino: 'amerino',
  amerita: 'amerita',
  amerjam: 'amerjam',
  amerjas: 'amerjas',
  ameruja: 'amerujá',
  ameruje: 'ameruje',
  amerujo: 'amerujo',
  amesita: 'amesita',
  amestra: 'amestra',
  amestre: 'amestre',
  amestro: 'amestro',
  amesura: 'amesura',
  amesure: 'amesure',
  amesuro: 'amesuro',
  ametade: 'ametade',
  ametais: 'ametais',
  ametala: 'ametala',
  ametale: 'ametale',
  ametalo: 'ametalo',
  ametina: 'ametina',
  ametino: 'ametino',
  ametisa: 'ametisa',
  ametria: 'ametria',
  amiadea: 'amiádea',
  amiadeo: 'amiádeo',
  amianto: 'amianto',
  amiaras: 'amiaras',
  amibose: 'amibose',
  amicais: 'amicais',
  amicles: 'amicles',
  amicleu: 'amicleu',
  amicose: 'amicose',
  amictas: 'amictas',
  amictos: 'amictos',
  amiculo: 'amículo',
  amidada: 'amidada',
  amidado: 'amidado',
  amidais: 'amidais',
  amidala: 'amídala',
  amidamo: 'amidamo',
  amidara: 'amidara',
  amidava: 'amidava',
  amideis: 'amideis',
  amidemo: 'amidemo',
  amideto: 'amideto',
  amidica: 'amídica',
  amidico: 'amídico',
  amidina: 'amidina',
  amidino: 'amidino',
  amidoai: 'amidoai',
  amidoam: 'amidoam',
  amidoar: 'amidoar',
  amidoas: 'amidoas',
  amidoei: 'amidoei',
  amidoem: 'amidoem',
  amidoes: 'amidoes',
  amidois: 'amidóis',
  amidona: 'amidona',
  amidone: 'amidone',
  amidono: 'amidono',
  amidoou: 'amidoou',
  amidosa: 'amidosa',
  amidose: 'amidose',
  amidoso: 'amidoso',
  amidros: 'amidros',
  amieira: 'amieira',
  amieiro: 'amieiro',
  amielia: 'amielia',
  amielos: 'amielos',
  amiense: 'amiense',
  amigaca: 'amigaça',
  amigaco: 'amigaço',
  amigada: 'amigada',
  amigado: 'amigado',
  amigais: 'amigais',
  amigamo: 'amigamo',
  amigara: 'amigara',
  amigava: 'amigava',
  amigoes: 'amigões',
  amigona: 'amigona',
  amigote: 'amigote',
  amiguei: 'amiguei',
  amiguem: 'amiguem',
  amigues: 'amigues',
  amiideo: 'amiídeo',
  amilana: 'amilana',
  amilase: 'amilase',
  amilato: 'amilato',
  amileno: 'amileno',
  amilhai: 'amilhai',
  amilham: 'amilham',
  amilhar: 'amilhar',
  amilhas: 'amilhas',
  amilhei: 'amilhei',
  amilhem: 'amilhem',
  amilhes: 'amilhes',
  amilhou: 'amilhou',
  amilica: 'amílica',
  amilico: 'amílico',
  amilina: 'amilina',
  amilios: 'amílios',
  amiloma: 'amiloma',
  amilose: 'amilose',
  amimada: 'amimada',
  amimado: 'amimado',
  amimais: 'amimais',
  amimamo: 'amimamo',
  amimara: 'amimara',
  amimava: 'amimava',
  amimeis: 'amimeis',
  amimemo: 'amimemo',
  amimias: 'amimias',
  amimico: 'amímico',
  aminada: 'aminada',
  aminado: 'aminado',
  aminais: 'aminais',
  aminamo: 'aminamo',
  aminara: 'aminara',
  aminava: 'aminava',
  amineas: 'amíneas',
  amineia: 'amineia',
  amineis: 'amineis',
  aminemo: 'aminemo',
  amineus: 'amineus',
  amingua: 'amíngua',
  amingue: 'amíngue',
  aminguo: 'amínguo',
  aminico: 'amínico',
  aminois: 'aminóis',
  aminora: 'aminora',
  aminore: 'aminore',
  aminoro: 'aminoro',
  aminose: 'aminose',
  amintai: 'amintai',
  amintam: 'amintam',
  amintar: 'amintar',
  amintas: 'amintas',
  amintei: 'amintei',
  amintem: 'amintem',
  amintes: 'amintes',
  amintou: 'amintou',
  amiocas: 'amiocas',
  amiosas: 'amiosas',
  amiosos: 'amiosos',
  amiquis: 'amiquis',
  amirado: 'amirado',
  amirina: 'amirina',
  amiroes: 'amirões',
  amirois: 'amiróis',
  amirola: 'amirola',
  amisaua: 'amisaua',
  amisera: 'amisera',
  amisere: 'amisere',
  amisero: 'amisero',
  amissao: 'amissão',
  amistai: 'amistai',
  amistam: 'amistam',
  amistar: 'amistar',
  amistas: 'amistas',
  amistei: 'amistei',
  amistem: 'amistem',
  amistes: 'amistes',
  amistou: 'amistou',
  amisula: 'amisula',
  amisule: 'amisule',
  amisulo: 'amisulo',
  amitado: 'amitado',
  amitico: 'amítico',
  amitina: 'amitina',
  amitino: 'amitino',
  amitose: 'amitose',
  amitraz: 'amitraz',
  amitros: 'amitros',
  amiudai: 'amiudai',
  amiudam: 'amiúdam',
  amiudao: 'amiudão',
  amiudar: 'amiudar',
  amiudas: 'amiúdas',
  amiudei: 'amiudei',
  amiudem: 'amiúdem',
  amiuder: 'amiuder',
  amiudes: 'amiúdes',
  amiudeu: 'amiudeu',
  amiudou: 'amiudou',
  amiuros: 'amiúros',
  amixias: 'amixias',
  amizade: 'amizade',
  amnesia: 'amnésia',
  amnesie: 'amnesie',
  amnesio: 'amnesio',
  amniada: 'amniada',
  amniado: 'amniado',
  amniano: 'amniano',
  amniota: 'amniota',
  amobata: 'amobata',
  amobudu: 'amobudu',
  amocada: 'amocada',
  amocado: 'amocado',
  amocais: 'amocaís',
  amocamo: 'amocamo',
  amocara: 'amocará',
  amocava: 'amoçava',
  amoceis: 'amoceis',
  amocemo: 'amocemo',
  amocete: 'amocete',
  amochai: 'amochai',
  amocham: 'amocham',
  amochar: 'amochar',
  amochas: 'amochas',
  amochei: 'amochei',
  amochem: 'amochem',
  amoches: 'amoches',
  amochoa: 'amochoa',
  amochoe: 'amochoe',
  amochoi: 'amochoi',
  amochoo: 'amochoo',
  amochou: 'amochou',
  amodais: 'amodais',
  amodela: 'amodelá',
  amodele: 'amodele',
  amodelo: 'amodelo',
  amodita: 'amódita',
  amoedai: 'amoedai',
  amoedam: 'amoedam',
  amoedar: 'amoedar',
  amoedas: 'amoedas',
  amoedei: 'amoedei',
  amoedem: 'amoedem',
  amoedes: 'amoedes',
  amoedou: 'amoedou',
  amofina: 'amofina',
  amofine: 'amofine',
  amofino: 'amofino',
  amoiada: 'amoiada',
  amoiado: 'amoiado',
  amoiais: 'amoiais',
  amoiamo: 'amoiamo',
  amoiara: 'amoiará',
  amoiava: 'amoiava',
  amoieis: 'amoieis',
  amoiemo: 'amoiemo',
  amoinai: 'amoinai',
  amoinam: 'amoinam',
  amoinar: 'amoinar',
  amoinas: 'amoinas',
  amoinei: 'amoinei',
  amoinem: 'amoinem',
  amoines: 'amoines',
  amoinou: 'amoinou',
  amoirai: 'amoirai',
  amoiram: 'amoiram',
  amoirar: 'amoirar',
  amoiras: 'amoiras',
  amoirei: 'amoirei',
  amoirem: 'amoirem',
  amoires: 'amoires',
  amoiroa: 'amoiroá',
  amoiroe: 'amoiroe',
  amoiroo: 'amoiroo',
  amoirou: 'amoirou',
  amoitai: 'amoitai',
  amoitam: 'amoitam',
  amoitar: 'amoitar',
  amoitas: 'amoitas',
  amoitei: 'amoitei',
  amoitem: 'amoitem',
  amoites: 'amoites',
  amoitou: 'amoitou',
  amoixai: 'amoixai',
  amoixam: 'amoixam',
  amoixar: 'amoixar',
  amoixas: 'amoixas',
  amoixei: 'amoixei',
  amoixem: 'amoixem',
  amoixes: 'amoixes',
  amoixou: 'amoixou',
  amojada: 'amojada',
  amojado: 'amojado',
  amojais: 'amojais',
  amojamo: 'amojamo',
  amojara: 'amojará',
  amojava: 'amojava',
  amojeis: 'amojeis',
  amojemo: 'amojemo',
  amojuda: 'amojuda',
  amojudo: 'amojudo',
  amolada: 'amolada',
  amolado: 'amolado',
  amolago: 'amolago',
  amolais: 'amolais',
  amolamo: 'amolamo',
  amolara: 'amolara',
  amolava: 'amolava',
  amoldai: 'amoldai',
  amoldam: 'amoldam',
  amoldar: 'amoldar',
  amoldas: 'amoldas',
  amoldei: 'amoldei',
  amoldem: 'amoldem',
  amoldes: 'amoldes',
  amoldou: 'amoldou',
  amoleca: 'amoleça',
  amolece: 'amolece',
  amoleci: 'amoleci',
  amoleco: 'amoleço',
  amolega: 'amolega',
  amolego: 'amolego',
  amoleis: 'amoleis',
  amolemo: 'amolemo',
  amolgai: 'amolgai',
  amolgam: 'amolgam',
  amolgar: 'amolgar',
  amolgas: 'amolgas',
  amolgou: 'amolgou',
  amolgue: 'amolgue',
  amolhai: 'amolhai',
  amolham: 'amolham',
  amolhar: 'amolhar',
  amolhas: 'amolhas',
  amolhei: 'amolhei',
  amolhem: 'amolhem',
  amolhes: 'amolhes',
  amolhou: 'amolhou',
  amolico: 'amólico',
  amolina: 'amolina',
  amomada: 'amomada',
  amomado: 'amomado',
  amomais: 'amomais',
  amomamo: 'amomamo',
  amomana: 'amomana',
  amomara: 'amomará',
  amomava: 'amomava',
  amomeis: 'amomeis',
  amomemo: 'amomemo',
  amonada: 'amonada',
  amonado: 'amonado',
  amonais: 'amonais',
  amonamo: 'amonamo',
  amonara: 'amonará',
  amonava: 'amonava',
  amoneis: 'amoneis',
  amonemo: 'amonemo',
  amonias: 'amônias',
  amonios: 'amônios',
  amonita: 'amonita',
  amonite: 'amonite',
  amoniza: 'amoniza',
  amonize: 'amonize',
  amonizo: 'amonizo',
  amontai: 'amontai',
  amontam: 'amontam',
  amontao: 'amontão',
  amontar: 'amontar',
  amontas: 'amontas',
  amontei: 'amontei',
  amontem: 'amontem',
  amontes: 'amontes',
  amontoa: 'amontoa',
  amontoe: 'amontoe',
  amontom: 'amontom',
  amontoo: 'amontoo',
  amontou: 'amontou',
  amoquei: 'amoquei',
  amoquem: 'amoquem',
  amoques: 'amoques',
  amorada: 'amorada',
  amorado: 'amorado',
  amorais: 'amorais',
  amoramo: 'amoramo',
  amorara: 'amorara',
  amorava: 'amorava',
  amoreco: 'amoreco',
  amoreia: 'amoreia',
  amoreis: 'amoreis',
  amoremo: 'amoremo',
  amorena: 'amorena',
  amorene: 'amorene',
  amoreno: 'amoreno',
  amorete: 'amorete',
  amorfas: 'amorfas',
  amorfia: 'amorfia',
  amorfos: 'amorfos',
  amorica: 'amorica',
  amorico: 'amorico',
  amorins: 'amorins',
  amorios: 'amorios',
  amorita: 'amorita',
  amorite: 'amorite',
  amornai: 'amornai',
  amornam: 'amornam',
  amornar: 'amornar',
  amornas: 'amornas',
  amornei: 'amornei',
  amornem: 'amornem',
  amornes: 'amornes',
  amornou: 'amornou',
  amorosa: 'amorosa',
  amoroso: 'amoroso',
  amorreu: 'amorreu',
  amorroa: 'amorroa',
  amorroe: 'amorroe',
  amorroo: 'amorroo',
  amoruda: 'amoruda',
  amorudo: 'amorudo',
  amosita: 'amosita',
  amossai: 'amossai',
  amossam: 'amossam',
  amossar: 'amossar',
  amossas: 'amossas',
  amossei: 'amossei',
  amossem: 'amossem',
  amosses: 'amosses',
  amossou: 'amossou',
  amostai: 'amostai',
  amostam: 'amostam',
  amostar: 'amostar',
  amostas: 'amostas',
  amostei: 'amostei',
  amostem: 'amostem',
  amostes: 'amostes',
  amostou: 'amostou',
  amostra: 'amostra',
  amostre: 'amostre',
  amostro: 'amostro',
  amotada: 'amotada',
  amotado: 'amotado',
  amotais: 'amotais',
  amotamo: 'amotamo',
  amotara: 'amotará',
  amotava: 'amotava',
  amoteia: 'amoteia',
  amoteis: 'amoteis',
  amotemo: 'amotemo',
  amotina: 'amotina',
  amotine: 'amotine',
  amotino: 'amotino',
  amoucai: 'amoucai',
  amoucam: 'amoucam',
  amoucar: 'amoucar',
  amoucas: 'amoucas',
  amoucou: 'amoucou',
  amouque: 'amouque',
  amourai: 'amourai',
  amouram: 'amouram',
  amourar: 'amourar',
  amouras: 'amouras',
  amourei: 'amourei',
  amourem: 'amourem',
  amoures: 'amoures',
  amouroa: 'amouroá',
  amouroe: 'amouroe',
  amouroo: 'amouroo',
  amourou: 'amourou',
  amoutai: 'amoutai',
  amoutam: 'amoutam',
  amoutar: 'amoutar',
  amoutas: 'amoutas',
  amoutei: 'amoutei',
  amoutem: 'amoutem',
  amoutes: 'amoutes',
  amoutou: 'amoutou',
  amouxai: 'amouxai',
  amouxam: 'amouxam',
  amouxar: 'amouxar',
  amouxas: 'amouxas',
  amouxei: 'amouxei',
  amouxem: 'amouxem',
  amouxes: 'amouxes',
  amouxou: 'amouxou',
  amovais: 'amovais',
  amoveio: 'amoveio',
  amoveis: 'amoveis',
  amovemo: 'amovemo',
  amovens: 'amovéns',
  amovera: 'amovera',
  amoviam: 'amoviam',
  amovias: 'amovias',
  amovida: 'amovida',
  amovido: 'amovido',
  amovier: 'amovier',
  amovira: 'amovirá',
  amoxama: 'amoxama',
  amoxame: 'amoxame',
  amoxamo: 'amoxamo',
  ampaque: 'ampaque',
  amparai: 'amparai',
  amparam: 'amparam',
  amparar: 'amparar',
  amparas: 'amparas',
  amparei: 'amparei',
  amparem: 'amparem',
  ampares: 'ampares',
  amparos: 'amparos',
  amparou: 'amparou',
  ampedio: 'ampédio',
  ampelia: 'ampélia',
  ampelio: 'ampélio',
  amperea: 'ampérea',
  amperes: 'amperes',
  amperio: 'ampério',
  ampiola: 'ampiola',
  ampiole: 'ampiole',
  ampiolo: 'ampiolo',
  amplexa: 'amplexa',
  amplexo: 'amplexo',
  ampliai: 'ampliai',
  ampliam: 'ampliam',
  ampliar: 'ampliar',
  amplias: 'amplias',
  ampliei: 'ampliei',
  ampliem: 'ampliem',
  amplies: 'amplies',
  ampliou: 'ampliou',
  ampolas: 'ampolas',
  ampolha: 'ampolha',
  ampolhe: 'ampolhe',
  ampolho: 'ampolho',
  ampular: 'ampular',
  ampulas: 'ampulas',
  amputai: 'amputai',
  amputam: 'amputam',
  amputar: 'amputar',
  amputas: 'amputas',
  amputei: 'amputei',
  amputem: 'amputem',
  amputes: 'amputes',
  amputou: 'amputou',
  amuacao: 'amuação',
  amuadao: 'amuadão',
  amuadas: 'amuadas',
  amuador: 'amuador',
  amuados: 'amuados',
  amuamos: 'amuamos',
  amuando: 'amuando',
  amuante: 'amuante',
  amuaram: 'amuaram',
  amuarao: 'amuarão',
  amuaras: 'amuarás',
  amuarei: 'amuarei',
  amuarem: 'amuarem',
  amuares: 'amuares',
  amuaria: 'amuaria',
  amuarmo: 'amuarmo',
  amuasse: 'amuasse',
  amuaste: 'amuaste',
  amuavam: 'amuavam',
  amuavas: 'amuavas',
  amuemos: 'amuemos',
  amuimos: 'amuimós',
  amulata: 'amulata',
  amulate: 'amulate',
  amulato: 'amulato',
  amuleto: 'amuleto',
  amumiai: 'amumiai',
  amumiam: 'amumiam',
  amumiar: 'amumiar',
  amumias: 'amumias',
  amumiei: 'amumiei',
  amumiem: 'amumiem',
  amumies: 'amumies',
  amumiou: 'amumiou',
  amuncai: 'amunçai',
  amuncam: 'amunçam',
  amuncar: 'amunçar',
  amuncas: 'amunças',
  amuncei: 'amuncei',
  amuncem: 'amuncem',
  amunces: 'amunces',
  amuncou: 'amunçou',
  amuniga: 'amuniga',
  amurada: 'amurada',
  amurado: 'amurado',
  amurais: 'amurais',
  amuramo: 'amuramo',
  amurara: 'amurará',
  amurava: 'amurava',
  amurcas: 'amurcas',
  amureis: 'amureis',
  amuremo: 'amuremo',
  amureta: 'amureta',
  amusias: 'amusias',
  amusico: 'amúsico',
  amussai: 'amussai',
  amussam: 'amussam',
  amussar: 'amussar',
  amussas: 'amussas',
  amussei: 'amussei',
  amussem: 'amussem',
  amusses: 'amusses',
  amussou: 'amussou',
  anabasa: 'anábasa',
  anabase: 'anábase',
  anabata: 'anábata',
  anabate: 'anábate',
  anabato: 'anábato',
  anabela: 'anabela',
  anabena: 'anabena',
  anabeno: 'anabeno',
  anabola: 'anábola',
  anacada: 'anaçada',
  anacado: 'anacado',
  anacais: 'anaçais',
  anacala: 'anacala',
  anacalo: 'anacalo',
  anacamo: 'anaçamo',
  anacana: 'anacaná',
  anacara: 'anacara',
  anacare: 'anacare',
  anacaro: 'anacaro',
  anacava: 'anaçava',
  anaceis: 'anaceis',
  anacemo: 'anacemo',
  anacola: 'anácola',
  anacral: 'anacral',
  anactas: 'anactas',
  anacuja: 'anacujá',
  anacuse: 'anacuse',
  anadais: 'anadais',
  anadara: 'anadara',
  anadeia: 'anadeia',
  anadeis: 'anadéis',
  anadema: 'anadema',
  anadeno: 'anadeno',
  anadose: 'anadose',
  anaduva: 'anaduva',
  anafada: 'anafada',
  anafado: 'anafado',
  anafafe: 'anafafe',
  anafaia: 'anafaia',
  anafais: 'anafais',
  anafalo: 'anáfalo',
  anafamo: 'anafamo',
  anafara: 'anafara',
  anafase: 'anáfase',
  anafava: 'anafava',
  anafeai: 'anafeai',
  anafear: 'anafear',
  anafeei: 'anafeei',
  anafega: 'anáfega',
  anafeia: 'anafeia',
  anafeie: 'anafeie',
  anafeio: 'anafeio',
  anafeis: 'anafeis',
  anafemo: 'anafemo',
  anafeou: 'anafeou',
  anafiai: 'anafiai',
  anafiam: 'anafiam',
  anafiar: 'anafiar',
  anafias: 'anafias',
  anafiei: 'anafiei',
  anafiem: 'anafiem',
  anafies: 'anafies',
  anafilo: 'anafilo',
  anafiou: 'anafiou',
  anafise: 'anáfise',
  anafora: 'anáfora',
  anagada: 'anagada',
  anagado: 'anagado',
  anagais: 'anagais',
  anagami: 'anagami',
  anagara: 'anagará',
  anagava: 'anagava',
  anagire: 'anagire',
  anagiro: 'anagiro',
  anagoge: 'anagoge',
  anaguai: 'anaguai',
  anaguam: 'anáguam',
  anaguar: 'anaguar',
  anaguas: 'anáguas',
  anaguei: 'anaguei',
  anaguel: 'anaguel',
  anaguem: 'anáguem',
  anagues: 'anágues',
  anaguou: 'anaguou',
  anainho: 'anainho',
  anaipai: 'anaipai',
  anaipam: 'anaipam',
  anaipar: 'anaipar',
  anaipas: 'anaipas',
  anaipei: 'anaipei',
  anaipem: 'anaipem',
  anaipes: 'anaipes',
  anaipou: 'anaipou',
  anaiuri: 'anaiuri',
  analabo: 'análabo',
  analdia: 'analdia',
  analema: 'analema',
  analgas: 'analgas',
  analgia: 'analgia',
  analgos: 'analgos',
  analina: 'analina',
  analipo: 'analipo',
  analisa: 'analisa',
  analise: 'análise',
  analiso: 'analiso',
  analita: 'analita',
  analoga: 'análoga',
  analogo: 'análogo',
  analose: 'analose',
  analota: 'analota',
  analote: 'analote',
  analtos: 'analtos',
  anambes: 'anambés',
  anambis: 'anambis',
  anamesa: 'anamesa',
  anameus: 'anameus',
  anamico: 'anâmico',
  anamiri: 'anamiri',
  anamita: 'anamita',
  anampse: 'anampse',
  anampso: 'anampso',
  ananais: 'ananaís',
  ananceu: 'ananceu',
  anandro: 'anandro',
  ananera: 'ananerá',
  ananica: 'ananica',
  ananico: 'ananico',
  ananins: 'ananins',
  ananita: 'ananita',
  anantas: 'anantas',
  anantos: 'anantos',
  ananzai: 'ananzai',
  ananzam: 'ananzam',
  ananzar: 'ananzar',
  ananzas: 'ananzas',
  ananzei: 'ananzei',
  ananzem: 'ananzem',
  ananzes: 'ananzes',
  ananzou: 'ananzou',
  anaozao: 'anãozão',
  anapala: 'anápala',
  anaptas: 'anaptas',
  anapuru: 'anapuru',
  anaques: 'anaques',
  anarcas: 'anarcas',
  anarcos: 'anarcos',
  anarete: 'anarete',
  anarico: 'anarico',
  anartas: 'anartas',
  anartes: 'anartes',
  anartos: 'anartos',
  anartro: 'anartro',
  anasais: 'anasais',
  anasala: 'anasala',
  anasale: 'anasale',
  anasalo: 'anasalo',
  anasois: 'anasóis',
  anaspos: 'anaspos',
  anassal: 'anassal',
  anastol: 'anastol',
  anastra: 'anastra',
  anastre: 'anastre',
  anastro: 'anastro',
  anatada: 'anatada',
  anatado: 'anatado',
  anatais: 'anatais',
  anatamo: 'anatamo',
  anatara: 'anatara',
  anatase: 'anátase',
  anatava: 'anatava',
  anateis: 'anateis',
  anatema: 'anátema',
  anatemo: 'anatemo',
  anatida: 'anátida',
  anatifa: 'anatifa',
  anatifo: 'anatifo',
  anatina: 'anatina',
  anatrao: 'anatrão',
  anaudia: 'anaudia',
  anauera: 'anauerá',
  anauqua: 'anauquá',
  anaxeto: 'anaxeto',
  anaxial: 'anaxial',
  anazada: 'anãzada',
  anazado: 'anãzado',
  anazais: 'anãzais',
  anazamo: 'anãzamo',
  anazara: 'anãzará',
  anazava: 'anãzava',
  anazeis: 'anãzeis',
  anazemo: 'anãzemo',
  anazona: 'anãzona',
  ancacia: 'ancácia',
  ancadas: 'ancadas',
  ancados: 'ancados',
  ancatia: 'ancátia',
  anceias: 'anceias',
  anchaci: 'anchaci',
  anchada: 'anchada',
  anchado: 'anchado',
  anchais: 'anchais',
  anchamo: 'anchamo',
  anchara: 'anchara',
  anchava: 'anchava',
  ancheis: 'ancheis',
  anchemo: 'anchemo',
  ancheza: 'ancheza',
  anchoes: 'anchões',
  anchova: 'anchova',
  anchura: 'anchura',
  anciaes: 'anciães',
  anciana: 'anciana',
  anciano: 'anciano',
  anciaos: 'anciãos',
  anciate: 'anciate',
  ancilai: 'ancilai',
  ancilam: 'ancilam',
  ancilar: 'ancilar',
  ancilas: 'ancilas',
  ancilei: 'ancilei',
  ancilem: 'ancilem',
  anciles: 'anciles',
  ancilia: 'ancília',
  ancilio: 'ancílio',
  ancilou: 'ancilou',
  ancinha: 'ancinha',
  ancinhe: 'ancinhe',
  ancinho: 'ancinho',
  ancioes: 'anciões',
  anciona: 'anciona',
  anclaca: 'anclaca',
  ancolia: 'ancólia',
  anconas: 'anconas',
  anconea: 'ancônea',
  anconeo: 'ancôneo',
  anconeu: 'anconeu',
  anconio: 'ancônio',
  ancorai: 'ancorai',
  ancoram: 'ancoram',
  ancorar: 'ancorar',
  ancoras: 'ancoras',
  ancorei: 'ancorei',
  ancorem: 'ancorem',
  ancores: 'ancores',
  ancorou: 'ancorou',
  ancoxas: 'ancoxas',
  ancrode: 'ancrode',
  ancudas: 'ancudas',
  ancudos: 'ancudos',
  anculas: 'anculas',
  ancusas: 'ancusas',
  ancusea: 'ancúsea',
  ancuseo: 'ancúseo',
  andacaa: 'andacaá',
  andacao: 'andação',
  andacas: 'andacas',
  andacos: 'andaços',
  andadao: 'andadão',
  andadas: 'andadas',
  andador: 'andador',
  andados: 'andados',
  andagem: 'andagem',
  andaias: 'andaiás',
  andaida: 'andaida',
  andaima: 'andaima',
  andaime: 'andaime',
  andaimo: 'andaimo',
  andaina: 'andaina',
  andalao: 'andalão',
  andalas: 'andalas',
  andalha: 'andalha',
  andalim: 'andalim',
  andaluz: 'andaluz',
  andamio: 'andâmio',
  andamos: 'andamos',
  andanas: 'andanas',
  andanca: 'andança',
  andando: 'andando',
  andanho: 'andanho',
  andante: 'andante',
  andapes: 'andapés',
  andaram: 'andaram',
  andarao: 'andarão',
  andaras: 'andaras',
  andarei: 'andarei',
  andarem: 'andarem',
  andares: 'andares',
  andaria: 'andaria',
  andarim: 'andarim',
  andarmo: 'andarmo',
  andasse: 'andasse',
  andaste: 'andaste',
  andaval: 'andaval',
  andavam: 'andavam',
  andavas: 'andavas',
  andavel: 'andável',
  andebol: 'andebol',
  andeira: 'andeira',
  andeiro: 'andeiro',
  andejai: 'andejai',
  andejam: 'andejam',
  andejar: 'andejar',
  andejas: 'andejas',
  andejei: 'andejei',
  andejem: 'andejem',
  andejes: 'andejes',
  andejos: 'andejos',
  andejou: 'andejou',
  andemos: 'andemos',
  andiche: 'andiche',
  andilha: 'andilha',
  andinas: 'andinas',
  andinha: 'andinha',
  andinos: 'andinos',
  andirai: 'andiraí',
  andiras: 'andirás',
  anditos: 'ânditos',
  andoada: 'andoada',
  andoado: 'andoado',
  andoais: 'andoais',
  andoamo: 'andoamo',
  andoara: 'andoará',
  andoava: 'andoava',
  andobia: 'andóbia',
  andoeis: 'andoeis',
  andoemo: 'andoemo',
  andolas: 'andolas',
  andolos: 'andolos',
  andonde: 'andonde',
  andones: 'andones',
  andongo: 'andongo',
  andoras: 'andoras',
  andores: 'andores',
  andoria: 'andoria',
  andrade: 'andrade',
  andrajo: 'andrajo',
  andrios: 'andrios',
  androma: 'androma',
  aneadao: 'aneadão',
  aneadas: 'aneadas',
  aneador: 'aneador',
  aneados: 'aneados',
  aneamos: 'aneamos',
  aneando: 'aneando',
  aneante: 'aneante',
  aneaqui: 'aneaqui',
  anearam: 'anearam',
  anearao: 'anearão',
  anearas: 'anearás',
  anearei: 'anearei',
  anearem: 'anearem',
  aneares: 'aneares',
  anearia: 'anearia',
  aneasse: 'aneasse',
  aneaste: 'aneaste',
  aneavam: 'aneavam',
  aneavas: 'aneavas',
  anecral: 'anecral',
  anecril: 'anecril',
  anecris: 'anecris',
  anediai: 'anediai',
  anediam: 'anediam',
  anediar: 'anediar',
  anedias: 'anedias',
  anediei: 'anediei',
  anediem: 'anediem',
  anedies: 'anedies',
  anediou: 'anediou',
  anedota: 'anedota',
  anedral: 'anedral',
  aneemos: 'aneemos',
  anegaca: 'anegaça',
  anegada: 'anegada',
  anegado: 'anegado',
  anegais: 'anegais',
  anegara: 'anegara',
  anegava: 'anegava',
  anegrai: 'anegrai',
  anegram: 'anegram',
  anegrar: 'anegrar',
  anegras: 'anegras',
  anegrei: 'anegrei',
  anegrem: 'anegrem',
  anegres: 'anegres',
  anegrou: 'anegrou',
  aneguei: 'aneguei',
  aneguem: 'aneguem',
  anegues: 'anegues',
  aneiras: 'aneiras',
  aneiros: 'aneiros',
  aneixas: 'aneixas',
  aneixos: 'aneixos',
  anelada: 'anelada',
  anelado: 'anelado',
  anelais: 'anelais',
  anelamo: 'anelamo',
  anelara: 'anelara',
  anelava: 'anelava',
  aneleis: 'aneleis',
  anelemo: 'anelemo',
  anelete: 'anelete',
  anelhos: 'anelhos',
  anelido: 'anélido',
  anelito: 'anélito',
  aneloes: 'anelões',
  anelosa: 'anelosa',
  aneloso: 'aneloso',
  anemiai: 'anemiai',
  anemiam: 'anemiam',
  anemiar: 'anemiar',
  anemias: 'anemias',
  anemica: 'anêmica',
  anemico: 'anêmico',
  anemiei: 'anemiei',
  anemiem: 'anemiem',
  anemies: 'anemies',
  anemiou: 'anemiou',
  anemiza: 'anemiza',
  anemize: 'anemize',
  anemizo: 'anemizo',
  anemola: 'anêmola',
  anemona: 'anémona',
  anepias: 'anepias',
  anequim: 'anequim',
  anerana: 'anerana',
  anergia: 'anergia',
  anervea: 'anérvea',
  anerveo: 'anérveo',
  anervia: 'anervia',
  anesias: 'anesias',
  anesina: 'anesina',
  anesona: 'anesona',
  anestia: 'anestia',
  anestil: 'anestil',
  anestis: 'anestis',
  anestro: 'anestro',
  anetico: 'anético',
  anetois: 'anetóis',
  aneuras: 'aneuras',
  aneuria: 'aneuria',
  aneuros: 'aneuros',
  anexada: 'anexada',
  anexado: 'anexado',
  anexais: 'anexais',
  anexamo: 'anexamo',
  anexara: 'anexara',
  anexava: 'anexava',
  anexeis: 'anexeis',
  anexemo: 'anexemo',
  anexins: 'anexins',
  anexite: 'anexite',
  anfanto: 'anfanto',
  anfasia: 'anfásia',
  anfibia: 'anfíbia',
  anfibio: 'anfíbio',
  anfibos: 'anfibos',
  anfidas: 'ânfidas',
  anfidea: 'anfideã',
  anfidio: 'anfídio',
  anfidos: 'anfidos',
  anfigio: 'anfígio',
  anfimia: 'anfímia',
  anfioes: 'anfiões',
  anfioxa: 'anfioxa',
  anfioxo: 'anfioxo',
  anfiuma: 'anfiúma',
  anforal: 'anforal',
  anforas: 'ânforas',
  anfunge: 'anfunge',
  angacos: 'angaços',
  angaiba: 'angaíba',
  angalas: 'angalas',
  anganas: 'anganas',
  angando: 'angando',
  anganga: 'angangá',
  anganja: 'anganja',
  angaria: 'angaria',
  angarie: 'angarie',
  angario: 'angario',
  angelia: 'angélia',
  angelim: 'angelim',
  angelus: 'angelus',
  angemas: 'angemas',
  angical: 'angical',
  angicas: 'angicas',
  angicos: 'angicos',
  angidio: 'angídio',
  angiite: 'angiite',
  anginas: 'anginas',
  angioma: 'angioma',
  angiose: 'angiose',
  angiova: 'angiova',
  angiras: 'angiras',
  angitas: 'angitas',
  angites: 'angites',
  angitos: 'angitos',
  anglica: 'ânglica',
  anglico: 'ânglico',
  angliza: 'angliza',
  anglize: 'anglize',
  anglizo: 'anglizo',
  angojas: 'angojas',
  angolal: 'angolal',
  angolao: 'angolão',
  angolar: 'angolar',
  angolas: 'angolas',
  angoles: 'angolês',
  angomas: 'angomas',
  angombe: 'angombe',
  angonal: 'angonal',
  angonas: 'angonas',
  angoras: 'angorás',
  angores: 'angores',
  angosto: 'angosto',
  angraca: 'angracá',
  angreco: 'angreco',
  angrela: 'angrela',
  angreta: 'angreta',
  angrins: 'angrins',
  angrite: 'angrite',
  angrito: 'angrito',
  anguada: 'anguada',
  anguaia: 'anguaia',
  anguias: 'anguias',
  anguina: 'anguina',
  anguino: 'anguino',
  anguite: 'anguite',
  angulai: 'angulai',
  angulam: 'angulam',
  angular: 'angular',
  angulas: 'angulas',
  angulei: 'angulei',
  angulem: 'angulem',
  angules: 'angules',
  angulos: 'ângulos',
  angulou: 'angulou',
  angurus: 'angurus',
  angusto: 'angusto',
  anguzos: 'anguzôs',
  anhafia: 'anhafia',
  anhambi: 'anhambi',
  anhamim: 'anhamim',
  anhanga: 'anhanga',
  anhanha: 'anhanha',
  anhanho: 'anhanho',
  anhapas: 'anhapas',
  anharas: 'anharas',
  anhauva: 'anhaúva',
  anhimas: 'anhimas',
  anhinga: 'anhinga',
  anhotes: 'anhotes',
  anhotos: 'anhotos',
  anhuiba: 'anhuíba',
  anhumas: 'anhumas',
  aniagem: 'aniagem',
  anianas: 'anianas',
  aniaras: 'aniaras',
  aniaros: 'aniaros',
  aniarto: 'aniarto',
  aniavas: 'aniavás',
  anichai: 'anichai',
  anicham: 'anicham',
  anichar: 'anichar',
  anichas: 'anichas',
  anichei: 'anichei',
  anichem: 'anichem',
  aniches: 'aniches',
  anichou: 'anichou',
  anicore: 'anicoré',
  anidada: 'anidada',
  anidade: 'anidade',
  anidado: 'anidado',
  anidais: 'anidais',
  anidamo: 'anidamo',
  anidara: 'anidará',
  anidava: 'anidava',
  anideis: 'anideis',
  anidemo: 'anidemo',
  anidras: 'anidras',
  anidros: 'anidros',
  anielai: 'anielai',
  anielam: 'anielam',
  anielar: 'anielar',
  anielas: 'anielas',
  anielei: 'anielei',
  anielem: 'anielem',
  anieles: 'anieles',
  anielou: 'anielou',
  anieras: 'anieras',
  anifena: 'anifena',
  aniilai: 'aniilai',
  aniilam: 'aniilam',
  aniilar: 'aniilar',
  aniilas: 'aniilas',
  aniilei: 'aniilei',
  aniilem: 'aniilem',
  aniiles: 'aniiles',
  aniilou: 'aniilou',
  anilacu: 'anilaçu',
  anilada: 'anilada',
  anilado: 'anilado',
  anilais: 'anilais',
  anilamo: 'anilamo',
  anilara: 'anilara',
  anilava: 'anilava',
  anileis: 'anileis',
  anilema: 'anilema',
  anilemo: 'anilemo',
  anilese: 'anilese',
  anilhai: 'anilhai',
  anilham: 'anilham',
  anilhar: 'anilhar',
  anilhas: 'anilhas',
  anilhei: 'anilhei',
  anilhem: 'anilhem',
  anilhes: 'anilhes',
  anilhos: 'anilhos',
  anilhou: 'anilhou',
  anilida: 'anilida',
  anilido: 'anilido',
  anilina: 'anilina',
  aniline: 'aniline',
  anilino: 'anilino',
  anilita: 'anilita',
  anilite: 'anilite',
  anilzao: 'anilzão',
  animada: 'animada',
  animado: 'animado',
  animais: 'animais',
  animamo: 'animamo',
  animara: 'animara',
  animato: 'animato',
  animava: 'animava',
  animeis: 'animeis',
  animemo: 'animemo',
  animica: 'anímica',
  animico: 'anímico',
  animina: 'animina',
  animiza: 'animiza',
  animize: 'animize',
  animizo: 'animizo',
  animosa: 'animosa',
  animoso: 'animoso',
  aninada: 'aninada',
  aninado: 'aninado',
  aninais: 'aninais',
  aninamo: 'aninamo',
  aninara: 'aninará',
  aninaua: 'aninauá',
  aninava: 'aninava',
  anineis: 'anineis',
  aninemo: 'aninemo',
  aningal: 'aningal',
  aningas: 'aningas',
  aninhai: 'aninhai',
  aninham: 'aninham',
  aninhar: 'aninhar',
  aninhas: 'aninhas',
  aninhei: 'aninhei',
  aninhem: 'aninhem',
  aninhes: 'aninhes',
  aninhos: 'aninhos',
  aninhou: 'aninhou',
  aniodol: 'aniodol',
  anionte: 'anionte',
  anipnia: 'anipnia',
  aniquim: 'aniquim',
  anirias: 'anirias',
  anisada: 'anisada',
  anisado: 'anisado',
  anisais: 'anisais',
  anisamo: 'anisamo',
  anisara: 'anisará',
  anisato: 'anisato',
  anisava: 'anisava',
  aniseis: 'aniseis',
  anisemo: 'anisemo',
  aniseta: 'aniseta',
  anisete: 'anisete',
  anisica: 'anísica',
  anisico: 'anísico',
  anisilo: 'anisilo',
  anisina: 'anisina',
  anismos: 'anismos',
  anisois: 'anisóis',
  anisope: 'anisope',
  anistas: 'anistas',
  anistia: 'anistia',
  anistie: 'anistie',
  anistio: 'anistio',
  anistos: 'anistos',
  anitina: 'anitina',
  anitois: 'anitóis',
  anivela: 'anivelá',
  anivele: 'anivele',
  anivelo: 'anivelo',
  anixias: 'anixias',
  anjango: 'anjango',
  anjeela: 'anjeela',
  anjinha: 'anjinha',
  anjinho: 'anjinho',
  anjiras: 'anjiras',
  anjolas: 'anjolas',
  anjubas: 'anjubas',
  anjuoes: 'anjuões',
  anjuria: 'anjuriá',
  anlagas: 'anlagas',
  anobios: 'anóbios',
  anociva: 'anociva',
  anocivo: 'anocivo',
  anoculo: 'anóculo',
  anodais: 'anodais',
  anodias: 'anodias',
  anodica: 'anódica',
  anodico: 'anódico',
  anodina: 'anódina',
  anodine: 'anodine',
  anodino: 'anódino',
  anodios: 'anódios',
  anodiza: 'anodiza',
  anodize: 'anodize',
  anodizo: 'anodizo',
  anodmia: 'anodmia',
  anodoes: 'anodões',
  anoemas: 'anoemas',
  anoesia: 'anoesia',
  anofele: 'anófele',
  anofelo: 'anófelo',
  anofito: 'anófito',
  anogina: 'anógina',
  anoitai: 'anoitai',
  anoitam: 'anoitam',
  anoitar: 'anoitar',
  anoitas: 'anoitas',
  anoitei: 'anoitei',
  anoitem: 'anoitem',
  anoites: 'anoites',
  anoitou: 'anoitou',
  anoiuba: 'anoiuba',
  anojada: 'anojada',
  anojado: 'anojado',
  anojais: 'anojais',
  anojamo: 'anojamo',
  anojara: 'anojará',
  anojava: 'anojava',
  anojeis: 'anojeis',
  anojemo: 'anojemo',
  anojosa: 'anojosa',
  anojoso: 'anojoso',
  anolena: 'anólena',
  anoleno: 'anóleno',
  anolzao: 'anolzão',
  anomala: 'anômala',
  anomalo: 'anômalo',
  anomato: 'anômato',
  anomaza: 'anomaza',
  anomeai: 'anomeai',
  anomear: 'anomear',
  anomeas: 'anômeas',
  anomeei: 'anomeei',
  anomeia: 'anomeia',
  anomeie: 'anomeie',
  anomeio: 'anomeio',
  anomeou: 'anomeou',
  anomial: 'anomial',
  anomias: 'anomias',
  anomica: 'anômica',
  anomico: 'anômico',
  anomita: 'anomita',
  anomite: 'anomite',
  anomito: 'anomito',
  anomuro: 'anomuro',
  anonada: 'anonada',
  anonade: 'anonade',
  anonado: 'anonado',
  anonima: 'anônima',
  anonime: 'anonime',
  anonimo: 'anônimo',
  anonzes: 'anonzés',
  anonzus: 'anonzus',
  anoopes: 'anoopes',
  anoopia: 'anoopia',
  anopias: 'anopias',
  anoplos: 'anoplos',
  anopoda: 'anópoda',
  anopsia: 'anopsia',
  anoques: 'anoques',
  anorcos: 'anorcos',
  anormal: 'anormal',
  anortea: 'anorteá',
  anosada: 'anosada',
  anosado: 'anosado',
  anosias: 'anosias',
  anosmia: 'anosmia',
  anosois: 'anosóis',
  anostea: 'anóstea',
  anosteo: 'anósteo',
  anotada: 'anotada',
  anotado: 'anotado',
  anotais: 'anotais',
  anotamo: 'anotamo',
  anotara: 'anotara',
  anotava: 'anotava',
  anoteis: 'anoteis',
  anotemo: 'anotemo',
  anotias: 'anotias',
  anotica: 'anótica',
  anotico: 'anótico',
  anotino: 'anótino',
  anoutai: 'anoutai',
  anoutam: 'anoutam',
  anoutar: 'anoutar',
  anoutas: 'anoutas',
  anoutei: 'anoutei',
  anoutem: 'anoutem',
  anoutes: 'anoutes',
  anoutou: 'anoutou',
  anovada: 'anovada',
  anovado: 'anovado',
  anovais: 'anovais',
  anovamo: 'anovamo',
  anovara: 'anovará',
  anovava: 'anovava',
  anoveai: 'anoveai',
  anovear: 'anovear',
  anoveas: 'anóveas',
  anoveei: 'anoveei',
  anoveia: 'anoveia',
  anoveie: 'anoveie',
  anoveio: 'anoveio',
  anoveis: 'anoveis',
  anovela: 'anovela',
  anovele: 'anovele',
  anovelo: 'anovelo',
  anovemo: 'anovemo',
  anoveou: 'anoveou',
  anoxiai: 'anoxiai',
  anoxiam: 'anoxiam',
  anoxiar: 'anoxiar',
  anoxias: 'anoxias',
  anoxica: 'anóxica',
  anoxico: 'anóxico',
  anoxiei: 'anoxiei',
  anoxiem: 'anoxiem',
  anoxies: 'anoxies',
  anoxiou: 'anoxiou',
  anquilo: 'anquilo',
  anrique: 'anrique',
  anseiam: 'anseiam',
  anseias: 'anseias',
  anseiem: 'anseiem',
  anseies: 'anseies',
  anseios: 'anseios',
  anselia: 'ansélia',
  anselim: 'anselim',
  ansenga: 'ansenga',
  ansiada: 'ansiada',
  ansiado: 'ansiado',
  ansiais: 'ansiais',
  ansiamo: 'ansiamo',
  ansiara: 'ansiara',
  ansiava: 'ansiava',
  ansieis: 'ansieis',
  ansiemo: 'ansiemo',
  ansiosa: 'ansiosa',
  ansioso: 'ansioso',
  ansonia: 'ansônia',
  antadao: 'antadão',
  antadas: 'antadas',
  antador: 'antador',
  antados: 'antados',
  antalas: 'antalas',
  antalma: 'antalma',
  antalos: 'antalos',
  antamba: 'antamba',
  antamos: 'antamos',
  antando: 'antando',
  antanho: 'antanho',
  antante: 'antante',
  antaqui: 'antaqui',
  antaram: 'antaram',
  antarao: 'antarão',
  antaras: 'antaras',
  antarei: 'antarei',
  antarem: 'antarem',
  antares: 'antares',
  antaria: 'antaria',
  antario: 'antário',
  antarmo: 'antarmo',
  antasse: 'antasse',
  antaste: 'antaste',
  antauge: 'antauge',
  antavam: 'antavam',
  antavas: 'antavas',
  antaxia: 'antáxia',
  anteado: 'anteado',
  anteata: 'anteata',
  anteato: 'anteato',
  antebem: 'antebém',
  antecas: 'antecas',
  anteceu: 'antecéu',
  antecio: 'antécio',
  antecor: 'antecor',
  antecos: 'antecos',
  antedai: 'antedai',
  antedam: 'antedam',
  antedao: 'antedão',
  antedar: 'antedar',
  antedas: 'antedás',
  antedei: 'antedei',
  antedem: 'antedem',
  anteder: 'anteder',
  antedes: 'antedês',
  antedeu: 'antedeu',
  antedia: 'antedia',
  antediz: 'antediz',
  antedou: 'antedou',
  antefaz: 'antefaz',
  antefez: 'antefez',
  antefiz: 'antefiz',
  anteira: 'anteira',
  anteiro: 'anteiro',
  antelas: 'antelas',
  antelia: 'antélia',
  antelio: 'antélio',
  antelix: 'antélix',
  antelos: 'antelos',
  antemao: 'antemão',
  antemis: 'ântemis',
  antemol: 'antemol',
  antemos: 'antemos',
  antenal: 'antenal',
  antenar: 'antenar',
  antenas: 'antenas',
  antense: 'antense',
  antepoe: 'antepõe',
  antepor: 'antepor',
  antepos: 'antepôs',
  antepus: 'antepus',
  anteral: 'anteral',
  anteras: 'anteras',
  anteses: 'anteses',
  antevem: 'antevem',
  antever: 'antever',
  anteves: 'antevês',
  antevia: 'antevia',
  antevir: 'antevir',
  anteviu: 'anteviu',
  antevoa: 'antevoá',
  antevoe: 'antevoe',
  antevoo: 'antevoo',
  anthrax: 'anthrax',
  antiada: 'antíada',
  antiade: 'antíade',
  anticas: 'ânticas',
  anticos: 'ânticos',
  antidea: 'antídea',
  antidio: 'antídio',
  antifal: 'antifal',
  antifaz: 'antifaz',
  antifen: 'antífen',
  antigao: 'antigão',
  antigas: 'antigas',
  antigel: 'antigel',
  antigos: 'antigos',
  antinas: 'antinas',
  antinha: 'antinha',
  antinos: 'antinós',
  antiopa: 'antíopa',
  antiope: 'antíope',
  antipos: 'antipos',
  antiqua: 'antíqua',
  antique: 'antique',
  antiquo: 'antíquo',
  antiste: 'antiste',
  antiveu: 'antivéu',
  antobio: 'antóbio',
  antodio: 'antódio',
  antoide: 'antoide',
  antojai: 'antojai',
  antojam: 'antojam',
  antojar: 'antojar',
  antojas: 'antojas',
  antojei: 'antojei',
  antojem: 'antojem',
  antojes: 'antojes',
  antojou: 'antojou',
  antolha: 'antolha',
  antolhe: 'antolhe',
  antolho: 'antolho',
  antomio: 'antomio',
  antonas: 'antonas',
  antoras: 'antoras',
  antoros: 'antoros',
  antotia: 'antótia',
  antraco: 'antraco',
  antrais: 'antrais',
  antreno: 'antreno',
  antrite: 'antrite',
  antrois: 'antróis',
  antrona: 'antrona',
  antropo: 'antropo',
  anturas: 'anturas',
  anturio: 'antúrio',
  anturos: 'anturos',
  antusas: 'antusas',
  anuacus: 'anuaçus',
  anuamos: 'anuamos',
  anuaria: 'anuária',
  anuario: 'anuário',
  anubias: 'anúbias',
  anubrai: 'anubrai',
  anubram: 'anubram',
  anubrar: 'anubrar',
  anubras: 'anubras',
  anubrei: 'anubrei',
  anubrem: 'anubrem',
  anubres: 'anubres',
  anubrou: 'anubrou',
  anudiva: 'anúdiva',
  anuduva: 'anúduva',
  anuenio: 'anuênio',
  anuente: 'anuente',
  anuibas: 'anuíbas',
  anuicao: 'anuição',
  anuidao: 'anuidão',
  anuidas: 'anuídas',
  anuidor: 'anuidor',
  anuidos: 'anuídos',
  anuieis: 'anuíeis',
  anuimos: 'anuímos',
  anuindo: 'anuindo',
  anuiram: 'anuíram',
  anuirao: 'anuirão',
  anuiras: 'anuirás',
  anuirei: 'anuirei',
  anuirem: 'anuírem',
  anuires: 'anuíres',
  anuiria: 'anuiria',
  anuirmo: 'anuirmo',
  anuisse: 'anuísse',
  anuiste: 'anuíste',
  anuivel: 'anuível',
  anulada: 'anulada',
  anulado: 'anulado',
  anulais: 'anulais',
  anulamo: 'anulamo',
  anulara: 'anulara',
  anulava: 'anulava',
  anuleis: 'anuleis',
  anulemo: 'anulemo',
  anulosa: 'anulosa',
  anuloso: 'anuloso',
  anumera: 'anumera',
  anumere: 'anumere',
  anumero: 'anumero',
  anuncia: 'anuncia',
  anuncie: 'anuncie',
  anuncio: 'anúncio',
  anunzes: 'anunzés',
  anurese: 'anurese',
  anurias: 'anúrias',
  anurica: 'anúrica',
  anurico: 'anúrico',
  anuvear: 'anuvear',
  anuviai: 'anuviai',
  anuviam: 'anuviam',
  anuviar: 'anuviar',
  anuvias: 'anuvias',
  anuviei: 'anuviei',
  anuviem: 'anuviem',
  anuvies: 'anuvies',
  anuviou: 'anuviou',
  anvalos: 'anvalós',
  anverso: 'anverso',
  anvidos: 'anvidos',
  anvulas: 'anvulas',
  anzicas: 'anzicas',
  anzicos: 'anzicos',
  anzinas: 'anzinas',
  anzinha: 'anzinha',
  anzinho: 'anzinho',
  anzolai: 'anzolai',
  anzolam: 'anzolam',
  anzolar: 'anzolar',
  anzolas: 'anzolas',
  anzolei: 'anzolei',
  anzolem: 'anzolem',
  anzoles: 'anzoles',
  anzolos: 'anzolos',
  anzolou: 'anzolou',
  aonidas: 'aônidas',
  aoristo: 'aoristo',
  aortica: 'aórtica',
  aortico: 'aórtico',
  aortite: 'aortite',
  aosadas: 'aosadas',
  aosmica: 'aósmica',
  aosmico: 'aósmico',
  apacama: 'apacamã',
  apaches: 'apaches',
  apadana: 'apadana',
  apadrea: 'apadreá',
  apadroa: 'apadroa',
  apadroe: 'apadroe',
  apadroo: 'apadroo',
  apagada: 'apagada',
  apagado: 'apagado',
  apagais: 'apagais',
  apagamo: 'apagamo',
  apagara: 'apagara',
  apagava: 'apagava',
  apagear: 'apagear',
  apagmas: 'apagmas',
  apagoes: 'apagões',
  apagoge: 'apagoge',
  apaguei: 'apaguei',
  apaguem: 'apaguem',
  apagues: 'apagues',
  apaiari: 'apaiari',
  apaijai: 'apaijai',
  apaijam: 'apaijam',
  apaijar: 'apaijar',
  apaijas: 'apaijas',
  apaijei: 'apaijei',
  apaijem: 'apaijem',
  apaijes: 'apaijes',
  apaijou: 'apaijou',
  apaiola: 'apaiola',
  apaiole: 'apaiole',
  apaiolo: 'apaiolo',
  apaisai: 'apaisai',
  apaisam: 'apaisam',
  apaisar: 'apaisar',
  apaisas: 'apaisas',
  apaisei: 'apaisei',
  apaisem: 'apaisem',
  apaises: 'apaises',
  apaisou: 'apaisou',
  apajada: 'apajada',
  apajado: 'apajado',
  apajais: 'apajais',
  apajamo: 'apajamo',
  apajara: 'apajará',
  apajava: 'apajava',
  apajeai: 'apajeai',
  apajear: 'apajear',
  apajeei: 'apajeei',
  apajeia: 'apajeia',
  apajeie: 'apajeie',
  apajeio: 'apajeio',
  apajeis: 'apajeis',
  apajemo: 'apajemo',
  apajeou: 'apajeou',
  apalaca: 'apalaça',
  apalace: 'apalace',
  apalaco: 'apalaço',
  apalada: 'apalada',
  apalade: 'apalade',
  apalado: 'apalado',
  apalage: 'apálage',
  apalais: 'apalaís',
  apalaza: 'apalaza',
  apalaze: 'apalaze',
  apalazo: 'apalazo',
  apaleai: 'apaleai',
  apalear: 'apalear',
  apaleei: 'apaleei',
  apaleia: 'apaleia',
  apaleie: 'apaleie',
  apaleio: 'apaleio',
  apaleou: 'apaleou',
  apalmai: 'apalmai',
  apalmam: 'apalmam',
  apalmar: 'apalmar',
  apalmas: 'apalmas',
  apalmei: 'apalmei',
  apalmem: 'apalmem',
  apalmes: 'apalmes',
  apalmou: 'apalmou',
  apalpai: 'apalpai',
  apalpam: 'apalpam',
  apalpao: 'apalpão',
  apalpar: 'apalpar',
  apalpas: 'apalpas',
  apalpei: 'apalpei',
  apalpem: 'apalpem',
  apalpes: 'apalpes',
  apalpos: 'apalpos',
  apalpou: 'apalpou',
  apameia: 'apameia',
  apameno: 'apameno',
  apameus: 'apameus',
  apancai: 'apancai',
  apancam: 'apancam',
  apancar: 'apancar',
  apancas: 'apancas',
  apancou: 'apancou',
  apandai: 'apandai',
  apandam: 'apandam',
  apandar: 'apandar',
  apandas: 'apandas',
  apandei: 'apandei',
  apandem: 'apandem',
  apandes: 'apandes',
  apandou: 'apandou',
  apanhai: 'apanhai',
  apanham: 'apanham',
  apanhar: 'apanhar',
  apanhas: 'apanhas',
  apanhei: 'apanhei',
  apanhem: 'apanhem',
  apanhes: 'apanhes',
  apanhia: 'apanhia',
  apanhos: 'apanhos',
  apanhou: 'apanhou',
  apanica: 'apanica',
  apanico: 'apanico',
  apanque: 'apanque',
  apantos: 'apantos',
  aparaca: 'aparaça',
  aparace: 'aparace',
  aparaci: 'aparaci',
  aparaco: 'aparaço',
  aparada: 'aparada',
  aparado: 'aparado',
  aparais: 'aparais',
  aparaiu: 'aparaiú',
  aparaju: 'aparaju',
  aparamo: 'aparamo',
  aparara: 'aparara',
  aparata: 'aparata',
  aparate: 'aparate',
  aparato: 'aparato',
  aparava: 'aparava',
  aparcai: 'aparcai',
  aparcam: 'aparcam',
  aparcar: 'aparcar',
  aparcas: 'aparcas',
  aparcou: 'aparcou',
  apareca: 'apareça',
  aparece: 'aparece',
  apareci: 'apareci',
  apareco: 'apareço',
  apareis: 'apareis',
  aparemo: 'aparemo',
  apareon: 'apáreon',
  aparque: 'aparque',
  aparrai: 'aparrai',
  aparram: 'aparram',
  aparrar: 'aparrar',
  aparras: 'aparras',
  aparrei: 'aparrei',
  aparrem: 'aparrem',
  aparres: 'aparres',
  aparrou: 'aparrou',
  apartai: 'apartai',
  apartam: 'apartam',
  apartar: 'apartar',
  apartas: 'apartas',
  apartea: 'aparteá',
  apartei: 'apartei',
  apartem: 'apartem',
  apartes: 'apartes',
  apartou: 'apartou',
  aparvai: 'aparvai',
  aparvam: 'aparvam',
  aparvar: 'aparvar',
  aparvas: 'aparvas',
  aparvei: 'aparvei',
  aparvem: 'aparvem',
  aparves: 'aparves',
  aparvoa: 'aparvoa',
  aparvoe: 'aparvoe',
  aparvoo: 'aparvoo',
  aparvou: 'aparvou',
  apascoa: 'apascoa',
  apascoe: 'apascoe',
  apascoo: 'apascoo',
  apastia: 'apastia',
  apataca: 'apatacá',
  apataco: 'apataco',
  apateta: 'apateta',
  apatete: 'apatete',
  apateto: 'apateto',
  apatias: 'apatias',
  apatica: 'apática',
  apatico: 'apático',
  apatifa: 'apatifa',
  apatife: 'apatife',
  apatifo: 'apatifo',
  apatita: 'apatita',
  apatite: 'apatite',
  apatito: 'apatito',
  apatiza: 'apatiza',
  apatize: 'apatize',
  apatizo: 'apatizo',
  apaulai: 'apaulai',
  apaulam: 'apaulam',
  apaular: 'apaular',
  apaulas: 'apaulas',
  apaulei: 'apaulei',
  apaulem: 'apaulem',
  apaules: 'apaules',
  apaulou: 'apaulou',
  apautai: 'apautai',
  apautam: 'apautam',
  apautar: 'apautar',
  apautas: 'apautas',
  apautei: 'apautei',
  apautem: 'apautem',
  apautes: 'apautes',
  apautou: 'apautou',
  apavesa: 'apavesa',
  apavese: 'apavese',
  apaveso: 'apaveso',
  apavona: 'apavona',
  apavone: 'apavone',
  apavono: 'apavono',
  apavora: 'apavora',
  apavore: 'apavore',
  apavoro: 'apavoro',
  apazigo: 'apazigo',
  apeacai: 'apeaçai',
  apeacam: 'apeaçam',
  apeacar: 'apeaçar',
  apeacas: 'apeaças',
  apeacei: 'apeacei',
  apeacem: 'apeacem',
  apeaces: 'apeaces',
  apeacou: 'apeaçou',
  apeadao: 'apeadão',
  apeadas: 'apeadas',
  apeador: 'apeador',
  apeados: 'apeados',
  apealai: 'apealai',
  apealam: 'apealam',
  apealar: 'apealar',
  apealas: 'apealas',
  apealei: 'apealei',
  apealem: 'apealem',
  apeales: 'apeales',
  apealou: 'apealou',
  apeamos: 'apeamos',
  apeanca: 'apeançá',
  apeance: 'apeance',
  apeanco: 'apeanço',
  apeando: 'apeando',
  apeanha: 'apeanha',
  apeanhe: 'apeanhe',
  apeanho: 'apeanho',
  apeante: 'apeante',
  apearam: 'apearam',
  apearao: 'apearão',
  apearas: 'apearas',
  apearei: 'apearei',
  apearem: 'apearem',
  apeares: 'apeares',
  apearia: 'apearia',
  apearmo: 'apearmo',
  apeasse: 'apeasse',
  apeaste: 'apeaste',
  apeavam: 'apeavam',
  apeavas: 'apeavas',
  apecuns: 'apecuns',
  apedada: 'apedada',
  apedado: 'apedado',
  apedais: 'apedais',
  apedamo: 'apedamo',
  apedara: 'apedara',
  apedava: 'apedava',
  apedeis: 'apedeis',
  apedemo: 'apedemo',
  apedido: 'apedido',
  apedrai: 'apedrai',
  apedram: 'apedram',
  apedrar: 'apedrar',
  apedras: 'apedras',
  apedrei: 'apedrei',
  apedrem: 'apedrem',
  apedres: 'apedres',
  apedrou: 'apedrou',
  apeemos: 'apeemos',
  apegada: 'apegada',
  apegado: 'apegado',
  apegais: 'apegais',
  apegamo: 'apegamo',
  apegara: 'apegara',
  apegava: 'apegava',
  apeguei: 'apeguei',
  apeguem: 'apeguem',
  apegues: 'apegues',
  apeibas: 'apeíbas',
  apeirai: 'apeirai',
  apeiram: 'apeiram',
  apeirar: 'apeirar',
  apeiras: 'apeiras',
  apeirei: 'apeirei',
  apeirem: 'apeirem',
  apeires: 'apeires',
  apeiria: 'apeiria',
  apeiron: 'ápeiron',
  apeiros: 'apeiros',
  apeirou: 'apeirou',
  apejada: 'apejada',
  apejado: 'apejado',
  apejais: 'apejais',
  apejamo: 'apejamo',
  apejara: 'apejará',
  apejava: 'apejava',
  apejeis: 'apejeis',
  apejemo: 'apejemo',
  apelada: 'apelada',
  apelado: 'apelado',
  apelais: 'apelais',
  apelamo: 'apelamo',
  apelara: 'apelara',
  apelava: 'apelava',
  apeleis: 'apeleis',
  apelemo: 'apelemo',
  apelida: 'apelida',
  apelide: 'apelide',
  apelido: 'apelido',
  apelita: 'apelita',
  apenada: 'apenada',
  apenado: 'apenado',
  apenais: 'apenais',
  apenamo: 'apenamo',
  apenara: 'apenara',
  apenari: 'apenari',
  apenava: 'apenava',
  apendai: 'apendai',
  apendam: 'apendam',
  apendar: 'apendar',
  apendas: 'apendas',
  apendei: 'apendei',
  apendem: 'apendem',
  apender: 'apender',
  apendes: 'apendes',
  apendeu: 'apendeu',
  apendia: 'apendia',
  apendix: 'apêndix',
  apendoa: 'apendoa',
  apendoe: 'apendoe',
  apendoo: 'apendoo',
  apendou: 'apendou',
  apeneis: 'apeneis',
  apenemo: 'apenemo',
  apenhai: 'apenhai',
  apenham: 'apenham',
  apenhar: 'apenhar',
  apenhas: 'apenhas',
  apenhei: 'apenhei',
  apenhem: 'apenhem',
  apenhes: 'apenhes',
  apenhou: 'apenhou',
  apensai: 'apensai',
  apensam: 'apensam',
  apensao: 'apensão',
  apensar: 'apensar',
  apensas: 'apensas',
  apensei: 'apensei',
  apensem: 'apensem',
  apenses: 'apenses',
  apensos: 'apensos',
  apensou: 'apensou',
  apepina: 'apepina',
  apepine: 'apepine',
  apepino: 'apepino',
  apepsia: 'apepsia',
  aperada: 'aperada',
  aperado: 'aperado',
  aperais: 'aperais',
  aperamo: 'aperamo',
  aperana: 'aperana',
  aperara: 'aperará',
  aperava: 'aperava',
  apercao: 'aperção',
  apereas: 'apereás',
  apereis: 'apereis',
  aperema: 'aperema',
  aperemo: 'aperemo',
  apernai: 'apernai',
  apernam: 'apernam',
  apernar: 'apernar',
  apernas: 'apernas',
  apernei: 'apernei',
  apernem: 'apernem',
  apernes: 'apernes',
  apernou: 'apernou',
  aperola: 'aperola',
  aperole: 'aperole',
  aperolo: 'aperolo',
  aperrai: 'aperrai',
  aperram: 'aperram',
  aperrar: 'aperrar',
  aperras: 'aperras',
  aperrea: 'aperreá',
  aperrei: 'aperrei',
  aperrem: 'aperrem',
  aperres: 'aperres',
  aperrou: 'aperrou',
  apertai: 'apertai',
  apertam: 'apertam',
  apertao: 'apertão',
  apertar: 'apertar',
  apertas: 'apertas',
  apertei: 'apertei',
  apertem: 'apertem',
  apertes: 'apertes',
  apertos: 'apertos',
  apertou: 'apertou',
  aperuai: 'aperuai',
  aperuam: 'aperuam',
  aperuar: 'aperuar',
  aperuas: 'aperuas',
  aperuei: 'aperuei',
  aperuem: 'aperuem',
  aperues: 'aperues',
  aperuou: 'aperuou',
  apesara: 'apesara',
  apesare: 'apesare',
  apesaro: 'apesaro',
  apessoa: 'apessoa',
  apessoe: 'apessoe',
  apessoo: 'apessoo',
  apestai: 'apestai',
  apestam: 'apestam',
  apestar: 'apestar',
  apestas: 'apestas',
  apestea: 'apesteá',
  apestei: 'apestei',
  apestem: 'apestem',
  apestes: 'apestes',
  apestou: 'apestou',
  apetada: 'apetada',
  apetado: 'apetado',
  apetais: 'apetais',
  apetala: 'apétala',
  apetalo: 'apétalo',
  apetamo: 'apetamo',
  apetara: 'apetará',
  apetare: 'apetare',
  apetaro: 'apetaro',
  apetava: 'apetava',
  apeteca: 'apeteça',
  apetece: 'apetece',
  apeteci: 'apeteci',
  apeteco: 'apeteço',
  apeteis: 'apeteis',
  apetemo: 'apetemo',
  apetita: 'apetita',
  apetite: 'apetite',
  apetito: 'apetito',
  apetrai: 'apetrai',
  apetram: 'apetram',
  apetrar: 'apetrar',
  apetras: 'apetras',
  apetrei: 'apetrei',
  apetrem: 'apetrem',
  apetres: 'apetres',
  apetros: 'ápetros',
  apetrou: 'apetrou',
  apetupa: 'apetupá',
  apeturi: 'apeturi',
  apexica: 'apéxica',
  apexico: 'apéxico',
  apiabai: 'apiabai',
  apiabam: 'apiabam',
  apiabar: 'apiabar',
  apiabas: 'apiabas',
  apiabei: 'apiabei',
  apiabem: 'apiabem',
  apiabes: 'apiabes',
  apiabou: 'apiabou',
  apiacas: 'apiacás',
  apiacea: 'apiácea',
  apiaceo: 'apiáceo',
  apiadai: 'apiadai',
  apiadam: 'apiadam',
  apiadar: 'apiadar',
  apiadas: 'apiadas',
  apiadei: 'apiadei',
  apiadem: 'apiadem',
  apiades: 'apiades',
  apiadou: 'apiadou',
  apiales: 'apiales',
  apianca: 'apiança',
  apiance: 'apiance',
  apianco: 'apianço',
  apiaria: 'apiária',
  apiario: 'apiário',
  apicaca: 'apicaça',
  apicace: 'apicace',
  apicaco: 'apicaço',
  apicada: 'apicada',
  apicado: 'apicado',
  apicais: 'apicais',
  apichai: 'apichai',
  apicham: 'apicham',
  apichar: 'apichar',
  apichas: 'apichas',
  apichei: 'apichei',
  apichem: 'apichem',
  apiches: 'apiches',
  apichou: 'apichou',
  apicida: 'apicida',
  apicios: 'apícios',
  apicite: 'apicite',
  apicoai: 'apicoai',
  apicoam: 'apicoam',
  apicoar: 'apicoar',
  apicoas: 'apicoas',
  apicoei: 'apicoei',
  apicoem: 'apicoem',
  apicoes: 'apicoes',
  apicola: 'apícola',
  apicoou: 'apicoou',
  apicras: 'apicras',
  apicuis: 'apicuís',
  apicula: 'apícula',
  apiculo: 'apículo',
  apicuns: 'apicuns',
  apicuru: 'apicuru',
  apideas: 'apídeas',
  apideos: 'apídeos',
  apiedai: 'apiedai',
  apiedam: 'apiedam',
  apiedar: 'apiedar',
  apiedas: 'apiedas',
  apiedei: 'apiedei',
  apiedem: 'apiedem',
  apiedes: 'apiedes',
  apiedou: 'apiedou',
  apiense: 'apiense',
  apifera: 'apífera',
  apifero: 'apífero',
  apifilo: 'apífilo',
  apifugo: 'apífugo',
  apigeas: 'apígeas',
  apigeos: 'apígeos',
  apiicas: 'apíicas',
  apiicos: 'apíicos',
  apiinas: 'apiinas',
  apilara: 'apilará',
  apilare: 'apilare',
  apilaro: 'apilaro',
  apildra: 'apildrá',
  apildre: 'apildre',
  apildro: 'apildro',
  apilema: 'apilema',
  apilese: 'apilese',
  apilhai: 'apilhai',
  apilham: 'apilham',
  apilhar: 'apilhar',
  apilhas: 'apilhas',
  apilhei: 'apilhei',
  apilhem: 'apilhem',
  apilhes: 'apilhes',
  apilhou: 'apilhou',
  apiloai: 'apiloai',
  apiloam: 'apiloam',
  apiloar: 'apiloar',
  apiloas: 'apiloas',
  apiloei: 'apiloei',
  apiloem: 'apiloem',
  apiloes: 'apiloes',
  apiloou: 'apiloou',
  apilrai: 'apilrai',
  apilram: 'apilram',
  apilrar: 'apilrar',
  apilras: 'apilras',
  apilrei: 'apilrei',
  apilrem: 'apilrem',
  apilres: 'apilres',
  apilrou: 'apilrou',
  apinada: 'apinada',
  apinado: 'apinado',
  apinaie: 'apinaié',
  apinais: 'apinais',
  apinaje: 'apinajé',
  apinamo: 'apinamo',
  apinara: 'apinará',
  apinava: 'apinava',
  apincha: 'apincha',
  apinche: 'apinche',
  apincho: 'apincho',
  apineas: 'apíneas',
  apineis: 'apinéis',
  apinemo: 'apinemo',
  apineos: 'apíneos',
  apingui: 'apingui',
  apinhai: 'apinhai',
  apinham: 'apinham',
  apinhar: 'apinhar',
  apinhas: 'apinhas',
  apinhei: 'apinhei',
  apinhem: 'apinhem',
  apinhes: 'apinhes',
  apinhoa: 'apinhoa',
  apinhoe: 'apinhoe',
  apinhoo: 'apinhoo',
  apinhou: 'apinhou',
  apinoca: 'apinocá',
  apinoco: 'apinoco',
  apintai: 'apintai',
  apintam: 'apintam',
  apintar: 'apintar',
  apintas: 'apintas',
  apintei: 'apintei',
  apintem: 'apintem',
  apintes: 'apintes',
  apintoa: 'apintoa',
  apintoe: 'apintoe',
  apintoo: 'apintoo',
  apintou: 'apintou',
  apiobas: 'apiobas',
  apioide: 'apioide',
  apional: 'apional',
  apionas: 'apionas',
  apionos: 'apíonos',
  apiosas: 'apiosas',
  apioses: 'apioses',
  apipada: 'apipada',
  apipado: 'apipado',
  apipais: 'apipais',
  apipamo: 'apipamo',
  apipara: 'apipará',
  apipava: 'apipava',
  apipeis: 'apipeis',
  apipemo: 'apipemo',
  apireno: 'apireno',
  apirina: 'apirina',
  apirita: 'apirita',
  apirite: 'apirite',
  apirito: 'apirito',
  apisina: 'apisina',
  apisine: 'apisine',
  apisino: 'apisino',
  apisoai: 'apisoai',
  apisoam: 'apisoam',
  apisoar: 'apisoar',
  apisoas: 'apisoas',
  apisoei: 'apisoei',
  apisoem: 'apisoem',
  apisoes: 'apisoes',
  apisoou: 'apisoou',
  apistos: 'apistos',
  apitada: 'apitada',
  apitado: 'apitado',
  apitais: 'apitais',
  apitamo: 'apitamo',
  apitara: 'apitara',
  apitaus: 'apitaus',
  apitava: 'apitava',
  apiteis: 'apiteis',
  apitemo: 'apitemo',
  apivora: 'apívora',
  apivoro: 'apívoro',
  aplacai: 'aplacai',
  aplacam: 'aplacam',
  aplacar: 'aplacar',
  aplacas: 'aplacas',
  aplacou: 'aplacou',
  aplaina: 'aplaina',
  aplaine: 'aplaine',
  aplaino: 'aplaino',
  aplanai: 'aplanai',
  aplanam: 'aplanam',
  aplanar: 'aplanar',
  aplanas: 'aplanas',
  aplanei: 'aplanei',
  aplanem: 'aplanem',
  aplanes: 'aplanes',
  aplanou: 'aplanou',
  aplaque: 'aplaque',
  aplasia: 'aplasia',
  aplasta: 'aplasta',
  aplaste: 'aplaste',
  aplasto: 'aplasto',
  aplauda: 'aplauda',
  aplaude: 'aplaude',
  aplaudi: 'aplaudi',
  aplaudo: 'aplaudo',
  aplauso: 'aplauso',
  aplebea: 'aplebeá',
  aplicai: 'aplicai',
  aplicam: 'aplicam',
  aplicar: 'aplicar',
  aplicas: 'aplicas',
  aplicou: 'aplicou',
  aplidos: 'áplidos',
  aplique: 'aplique',
  aplisia: 'aplísia',
  aplisio: 'aplísio',
  aplitos: 'aplitos',
  aplomia: 'aplomia',
  aplumai: 'aplumai',
  aplumam: 'aplumam',
  aplumar: 'aplumar',
  aplumas: 'aplumas',
  aplumei: 'aplumei',
  aplumem: 'aplumem',
  aplumes: 'aplumes',
  aplumou: 'aplumou',
  apluvio: 'aplúvio',
  apneias: 'apneias',
  apneica: 'apneica',
  apneico: 'apneico',
  apneuse: 'apneuse',
  apobata: 'apóbata',
  apocelo: 'apocelo',
  apocina: 'apocina',
  apocino: 'apócino',
  apocito: 'apócito',
  apocopa: 'apocopa',
  apocope: 'apócope',
  apocopo: 'apocopo',
  apocrea: 'apócrea',
  apocreo: 'apócreo',
  apodada: 'apodada',
  apodado: 'apodado',
  apodais: 'apodais',
  apodamo: 'apodamo',
  apodara: 'apodará',
  apodava: 'apodava',
  apodeis: 'apodeis',
  apodema: 'apódema',
  apodemo: 'apodemo',
  apodera: 'apodera',
  apodere: 'apodere',
  apodero: 'apodero',
  apodeta: 'apodeta',
  apodias: 'apodias',
  apodica: 'apódica',
  apodico: 'apódico',
  apodida: 'apódida',
  apodise: 'apódise',
  apodixe: 'apodixe',
  apodiza: 'apodiza',
  apodize: 'apodize',
  apodizo: 'apodizo',
  apodose: 'apódose',
  apodoto: 'apódoto',
  apodram: 'apodram',
  apodras: 'apodras',
  apodrem: 'apodrem',
  apodres: 'apodres',
  apodria: 'apodria',
  apodrir: 'apodrir',
  apodris: 'apodris',
  apodriu: 'apodriu',
  apofana: 'apófana',
  apofano: 'apófano',
  apofase: 'apófase',
  apofige: 'apófige',
  apofilo: 'apofilo',
  apofise: 'apófise',
  apofito: 'apófito',
  apofoco: 'apofoco',
  apogamo: 'apógamo',
  apogeno: 'apógeno',
  apogeus: 'apogeus',
  apogino: 'apógino',
  apogoes: 'apogões',
  apoiada: 'apoiada',
  apoiado: 'apoiado',
  apoiais: 'apoiais',
  apoiamo: 'apoiamo',
  apoiara: 'apoiara',
  apoiava: 'apoiava',
  apoideo: 'apoídeo',
  apoieis: 'apoieis',
  apoiemo: 'apoiemo',
  apoitai: 'apoitai',
  apoitam: 'apoitam',
  apoitar: 'apoitar',
  apoitas: 'apoitas',
  apoitei: 'apoitei',
  apoitem: 'apoitem',
  apoites: 'apoites',
  apoitou: 'apoitou',
  apojada: 'apojada',
  apojado: 'apojado',
  apojais: 'apojais',
  apojamo: 'apojamo',
  apojara: 'apojará',
  apojava: 'apojava',
  apojeis: 'apojeis',
  apojemo: 'apojemo',
  apojove: 'apójove',
  apolato: 'apolato',
  apolaza: 'apolazá',
  apolaze: 'apolaze',
  apolazo: 'apolazo',
  apoldra: 'apoldra',
  apoldre: 'apoldre',
  apoldro: 'apoldro',
  apoleai: 'apoleai',
  apolear: 'apolear',
  apoleei: 'apoleei',
  apolega: 'apolega',
  apolego: 'apolego',
  apoleia: 'apoleia',
  apoleie: 'apoleie',
  apoleio: 'apoleio',
  apoleja: 'apoleja',
  apoleje: 'apoleje',
  apolejo: 'apolejo',
  apoleou: 'apoleou',
  apoleza: 'apoleza',
  apoleze: 'apoleze',
  apolezo: 'apolezo',
  apolice: 'apólice',
  apolida: 'apólida',
  apolise: 'apólise',
  apologa: 'apóloga',
  apologo: 'apólogo',
  apomaco: 'apômaco',
  apombai: 'apombai',
  apombam: 'apombam',
  apombar: 'apombar',
  apombas: 'apombas',
  apombei: 'apombei',
  apombem: 'apombem',
  apombes: 'apombes',
  apombou: 'apombou',
  apompai: 'apompai',
  apompam: 'apompam',
  apompar: 'apompar',
  apompas: 'apompas',
  apompei: 'apompei',
  apompem: 'apompem',
  apompes: 'apompes',
  apompou: 'apompou',
  aponais: 'aponais',
  aponcha: 'aponchá',
  aponche: 'aponche',
  aponcho: 'aponcho',
  apondes: 'apondes',
  aponham: 'aponham',
  aponhas: 'aponhas',
  aponias: 'aponias',
  aponica: 'apônica',
  aponico: 'apônico',
  apontai: 'apontai',
  apontam: 'apontam',
  apontar: 'apontar',
  apontas: 'apontas',
  apontea: 'aponteá',
  apontei: 'apontei',
  apontem: 'apontem',
  apontes: 'apontes',
  apontoa: 'apontoa',
  apontoe: 'apontoe',
  apontoo: 'apontoo',
  apontou: 'apontou',
  apoporo: 'apóporo',
  apordes: 'apordes',
  aporeis: 'aporeis',
  aporema: 'aporema',
  aporese: 'aporese',
  aporfia: 'aporfia',
  aporfie: 'aporfie',
  aporfio: 'aporfio',
  aporiam: 'aporiam',
  aporias: 'aporias',
  apormos: 'apormos',
  aporosa: 'aporosa',
  aporral: 'aporral',
  aporrea: 'aporreá',
  aportai: 'aportai',
  aportam: 'aportam',
  aportar: 'aportar',
  aportas: 'aportas',
  aportei: 'aportei',
  aportem: 'aportem',
  aportes: 'aportes',
  aportou: 'aportou',
  aposias: 'aposias',
  aposito: 'apósito',
  apossai: 'apossai',
  apossam: 'apossam',
  apossar: 'apossar',
  apossas: 'apossas',
  apossea: 'aposseá',
  apossei: 'apossei',
  apossem: 'apossem',
  aposses: 'aposses',
  apossou: 'apossou',
  apossua: 'apossua',
  apossui: 'apossui',
  apossuo: 'apossuo',
  apostai: 'apostai',
  apostam: 'apostam',
  apostar: 'apostar',
  apostas: 'apostas',
  apostei: 'apostei',
  apostem: 'apostem',
  apostes: 'apostes',
  apostia: 'apostia',
  apostos: 'apostos',
  apostou: 'apostou',
  apotafo: 'apótafo',
  apoteca: 'apoteca',
  apoteco: 'apoteco',
  apotema: 'apótema',
  apotese: 'apótese',
  apoteto: 'apóteto',
  apotije: 'apotijé',
  apotipo: 'apótipo',
  apotomo: 'apótomo',
  apotrar: 'apotrar',
  apoucai: 'apoucai',
  apoucam: 'apoucam',
  apoucar: 'apoucar',
  apoucas: 'apoucas',
  apoucou: 'apoucou',
  apoupai: 'apoupai',
  apoupam: 'apoupam',
  apoupar: 'apoupar',
  apoupas: 'apoupas',
  apoupei: 'apoupei',
  apoupem: 'apoupem',
  apoupes: 'apoupes',
  apoupou: 'apoupou',
  apouque: 'apouque',
  apousai: 'apousai',
  apousam: 'apousam',
  apousar: 'apousar',
  apousas: 'apousas',
  apousei: 'apousei',
  apousem: 'apousem',
  apouses: 'apouses',
  apousou: 'apousou',
  apoutai: 'apoutai',
  apoutam: 'apoutam',
  apoutar: 'apoutar',
  apoutas: 'apoutas',
  apoutei: 'apoutei',
  apoutem: 'apoutem',
  apoutes: 'apoutes',
  apoutou: 'apoutou',
  apozema: 'apózema',
  apozeme: 'apozeme',
  apozemo: 'apozemo',
  apracas: 'apraças',
  apragia: 'apragia',
  apraiai: 'apraiai',
  apraiam: 'apraiam',
  apraiar: 'apraiar',
  apraias: 'apraias',
  apraiei: 'apraiei',
  apraiem: 'apraiem',
  apraies: 'apraies',
  apraiou: 'apraiou',
  apraius: 'apraiús',
  aprajus: 'aprajus',
  aprauas: 'aprauás',
  apraxia: 'apraxia',
  aprazai: 'aprazai',
  aprazam: 'aprazam',
  aprazar: 'aprazar',
  aprazas: 'aprazas',
  aprazei: 'aprazei',
  aprazem: 'aprazem',
  aprazer: 'aprazer',
  aprazes: 'aprazes',
  aprazia: 'aprazia',
  aprazou: 'aprazou',
  apreada: 'apreada',
  apreado: 'apreado',
  apreais: 'apreais',
  apreara: 'apreará',
  apreava: 'apreava',
  aprecai: 'apreçai',
  aprecam: 'apreçam',
  aprecar: 'apreçar',
  aprecas: 'apreças',
  aprecei: 'aprecei',
  aprecem: 'aprecem',
  apreces: 'apreces',
  aprecia: 'aprecia',
  aprecie: 'aprecie',
  aprecio: 'aprecio',
  aprecos: 'apreços',
  aprecou: 'apreçou',
  apreeis: 'apreeis',
  apregoa: 'apregoa',
  apregoe: 'apregoe',
  apregoo: 'apregoo',
  apreiam: 'apreiam',
  apreias: 'apreias',
  apreiem: 'apreiem',
  apreies: 'apreies',
  apremai: 'apremai',
  apremam: 'apremam',
  apremar: 'apremar',
  apremas: 'apremas',
  apremei: 'apremei',
  apremem: 'apremem',
  apremer: 'apremer',
  apremes: 'apremes',
  apremeu: 'apremeu',
  apremia: 'apremia',
  apremie: 'apremie',
  apremio: 'apremio',
  apremou: 'apremou',
  aprenda: 'aprenda',
  aprende: 'aprende',
  aprendi: 'aprendi',
  aprendo: 'aprendo',
  apresai: 'apresai',
  apresam: 'apresam',
  apresar: 'apresar',
  apresas: 'apresas',
  apresei: 'apresei',
  apresem: 'apresem',
  apreses: 'apreses',
  apresou: 'apresou',
  apressa: 'apressa',
  apresse: 'apresse',
  apresso: 'apresso',
  apresta: 'apresta',
  apreste: 'apreste',
  apresto: 'apresto',
  apricar: 'apricar',
  apricas: 'apricas',
  apricos: 'apricos',
  aprisca: 'aprisca',
  aprisco: 'aprisco',
  aprismo: 'aprismo',
  aprisoa: 'aprisoá',
  aprisoe: 'aprisoe',
  aprisoo: 'aprisoo',
  aprista: 'aprista',
  apristo: 'apristo',
  aproada: 'aproada',
  aproado: 'aproado',
  aproais: 'aproais',
  aproamo: 'aproamo',
  aproara: 'aproara',
  aproava: 'aproava',
  aprocha: 'aprochá',
  aproche: 'aproche',
  aprocho: 'aprocho',
  aprocto: 'aprocto',
  aproeis: 'aproeis',
  aproeja: 'aproeja',
  aproeje: 'aproeje',
  aproejo: 'aproejo',
  aproemo: 'aproemo',
  apronia: 'aprônia',
  apronta: 'apronta',
  apronte: 'apronte',
  apronto: 'apronto',
  aprosai: 'aprosai',
  aprosam: 'aprosam',
  aprosar: 'aprosar',
  aprosas: 'aprosas',
  aprosei: 'aprosei',
  aprosem: 'aprosem',
  aproses: 'aproses',
  aprosou: 'aprosou',
  aprouve: 'aprouve',
  aprouvi: 'aprouvi',
  aprouvo: 'aprouvo',
  aprovai: 'aprovai',
  aprovam: 'aprovam',
  aprovar: 'aprovar',
  aprovas: 'aprovas',
  aprovei: 'aprovei',
  aprovem: 'aprovem',
  aproves: 'aproves',
  aprovou: 'aprovou',
  aprumai: 'aprumai',
  aprumam: 'aprumam',
  aprumar: 'aprumar',
  aprumas: 'aprumas',
  aprumei: 'aprumei',
  aprumem: 'aprumem',
  aprumes: 'aprumes',
  aprumos: 'aprumos',
  aprumou: 'aprumou',
  apsaras: 'apsarás',
  apseudo: 'apseudo',
  apsides: 'apsides',
  aptadao: 'aptadão',
  aptadas: 'aptadas',
  aptador: 'aptador',
  aptados: 'aptados',
  aptamos: 'aptamos',
  aptando: 'aptando',
  aptante: 'aptante',
  aptaram: 'aptaram',
  aptarao: 'aptarão',
  aptaras: 'aptaras',
  aptarei: 'aptarei',
  aptarem: 'aptarem',
  aptares: 'aptares',
  aptaria: 'aptaria',
  aptarmo: 'aptarmo',
  aptasse: 'aptasse',
  aptaste: 'aptaste',
  aptavam: 'aptavam',
  aptavas: 'aptavas',
  apteira: 'apteira',
  aptemos: 'aptemos',
  apteras: 'ápteras',
  apteria: 'aptéria',
  apterio: 'aptério',
  apterix: 'ápterix',
  apteros: 'ápteros',
  aptiano: 'aptiano',
  apticas: 'ápticas',
  apticos: 'ápticos',
  aptidao: 'aptidão',
  apuadao: 'apuadão',
  apuadas: 'apuadas',
  apuador: 'apuador',
  apuados: 'apuados',
  apuames: 'apuames',
  apuamos: 'apuamos',
  apuanas: 'apuanas',
  apuando: 'apuando',
  apuanos: 'apuanos',
  apuante: 'apuante',
  apuaram: 'apuaram',
  apuarao: 'apuarão',
  apuaras: 'apuaras',
  apuarei: 'apuarei',
  apuarem: 'apuarem',
  apuares: 'apuares',
  apuaria: 'apuaria',
  apuarmo: 'apuarmo',
  apuasse: 'apuasse',
  apuaste: 'apuaste',
  apuavam: 'apuavam',
  apuavas: 'apuavas',
  apudora: 'apudora',
  apudore: 'apudore',
  apudoro: 'apudoro',
  apuemos: 'apuemos',
  apujare: 'apujaré',
  apulada: 'apulada',
  apulado: 'apulado',
  apulais: 'apulais',
  apulamo: 'apulamo',
  apulara: 'apulará',
  apulava: 'apulava',
  apuleia: 'apuleia',
  apuleis: 'apuleis',
  apuleja: 'apuleja',
  apulemo: 'apulemo',
  apulica: 'apúlica',
  apulico: 'apúlico',
  apulios: 'apúlios',
  apulsos: 'apulsos',
  apuncha: 'apunchá',
  apunche: 'apunche',
  apuncho: 'apuncho',
  apungas: 'apungas',
  apunhai: 'apunhai',
  apunham: 'apunham',
  apunhar: 'apunhar',
  apunhas: 'apunhas',
  apunhei: 'apunhei',
  apunhem: 'apunhem',
  apunhes: 'apunhes',
  apunhou: 'apunhou',
  apunias: 'apúnias',
  apuntos: 'apuntos',
  apupada: 'apupada',
  apupado: 'apupado',
  apupais: 'apupais',
  apupamo: 'apupamo',
  apupara: 'apupará',
  apupava: 'apupava',
  apupeis: 'apupeis',
  apupemo: 'apupemo',
  apurada: 'apurada',
  apurado: 'apurado',
  apurais: 'apurais',
  apuramo: 'apuramo',
  apurara: 'apurara',
  apurava: 'apurava',
  apureis: 'apureis',
  apuremo: 'apuremo',
  apurina: 'apuriná',
  apuruis: 'apuruís',
  apusais: 'apusais',
  apuseis: 'apuseis',
  apusemo: 'apusemo',
  apusera: 'apusera',
  apusiam: 'apusiam',
  apusias: 'apusias',
  apusida: 'apúsida',
  apusido: 'apusido',
  aputega: 'apútega',
  aputias: 'aputiás',
  aquador: 'aquador',
  aquando: 'aquando',
  aquaqua: 'aquaquá',
  aquaria: 'aquária',
  aquario: 'aquário',
  aquatil: 'aquátil',
  aquavia: 'aquavia',
  aquecam: 'aqueçam',
  aquecas: 'aqueças',
  aquecei: 'aquecei',
  aquecem: 'aquecem',
  aquecer: 'aquecer',
  aqueces: 'aqueces',
  aqueceu: 'aqueceu',
  aquecia: 'aquecia',
  aquedai: 'aquedai',
  aquedam: 'aquedam',
  aquedar: 'aquedar',
  aquedas: 'aquedas',
  aquedei: 'aquedei',
  aquedem: 'aquedem',
  aquedes: 'aquedes',
  aquedou: 'aquedou',
  aqueias: 'aqueias',
  aquelai: 'aquelai',
  aquelam: 'aquelam',
  aquelar: 'aquelar',
  aquelas: 'aquelas',
  aquelei: 'aquelei',
  aquelem: 'aquelem',
  aqueles: 'aqueles',
  aquelou: 'aquelou',
  aquenio: 'aquênio',
  aquense: 'aquense',
  aquenta: 'aquenta',
  aquente: 'aquente',
  aquento: 'aquento',
  aquesse: 'aquesse',
  aqueste: 'aqueste',
  aquesto: 'aquesto',
  aquetai: 'aquetai',
  aquetam: 'aquetam',
  aquetar: 'aquetar',
  aquetas: 'aquetas',
  aquetea: 'aquétea',
  aquetei: 'aquetei',
  aquetem: 'aquetem',
  aqueteo: 'aquéteo',
  aquetes: 'aquetes',
  aquetos: 'aquetos',
  aquetou: 'aquetou',
  aquieta: 'aquieta',
  aquiete: 'aquiete',
  aquieto: 'aquieto',
  aquilai: 'aquilai',
  aquilam: 'aquilam',
  aquilao: 'aquilão',
  aquilar: 'aquilar',
  aquilas: 'aquilas',
  aquilea: 'aquiléa',
  aquilei: 'aquilei',
  aquilem: 'aquilem',
  aquiles: 'aquiles',
  aquileu: 'aquileu',
  aquilha: 'aquilhá',
  aquilhe: 'aquilhe',
  aquilho: 'aquilho',
  aquilia: 'aquilia',
  aquilos: 'aquilos',
  aquilou: 'aquilou',
  aquimia: 'aquimia',
  aquiqui: 'aquiqui',
  aquiria: 'aquiria',
  aquirir: 'aquirir',
  aquiros: 'aquiros',
  aquista: 'aquista',
  aquiste: 'aquiste',
  aquisto: 'aquisto',
  aquites: 'aquites',
  aquivas: 'aquivas',
  aquivos: 'aquivos',
  aquosas: 'aquosas',
  aquosos: 'aquosos',
  aquotia: 'aquotiá',
  aquotie: 'aquotie',
  aquotio: 'aquotio',
  arabaca: 'arabaca',
  arabacu: 'arabaçu',
  arabana: 'arabana',
  arabata: 'arabata',
  araberu: 'araberu',
  arabeta: 'arabeta',
  arabias: 'arábias',
  arabica: 'arábica',
  arabico: 'arábico',
  arabigo: 'arábigo',
  arabina: 'arabina',
  arabios: 'arábios',
  arabita: 'arabita',
  arabiza: 'arabiza',
  arabize: 'arabize',
  arabizo: 'arabizo',
  araboia: 'araboia',
  arabote: 'arabote',
  arabuta: 'arabutã',
  aracacu: 'araçaçu',
  aracada: 'araçada',
  aracaji: 'araçaji',
  aracane: 'aracane',
  aracari: 'araçari',
  aracati: 'aracati',
  araceas: 'aráceas',
  araceos: 'aráceos',
  araches: 'araches',
  arachis: 'arachis',
  aracnos: 'aracnos',
  aracoes: 'arações',
  aracoia: 'araçoia',
  aracona: 'araçona',
  aracota: 'aracota',
  aracuao: 'aracuão',
  aracuas: 'aracuãs',
  aracuis: 'aracuís',
  aracuja: 'aracujá',
  aracuri: 'aracuri',
  aradada: 'aradada',
  aradado: 'aradado',
  aradais: 'aradais',
  aradamo: 'aradamo',
  aradara: 'aradará',
  aradava: 'aradava',
  aradega: 'arádega',
  aradeis: 'aradeis',
  aradelo: 'aradelo',
  arademo: 'arademo',
  aradica: 'aradica',
  aradico: 'aradico',
  aradoes: 'aradões',
  aradona: 'aradona',
  aradora: 'aradora',
  aradura: 'aradura',
  aragana: 'aragana',
  aragano: 'aragano',
  aragens: 'aragens',
  aragoes: 'aragoês',
  araguai: 'araguaí',
  araiaue: 'araiaué',
  araicas: 'araicás',
  araicus: 'araicus',
  arajuba: 'arajuba',
  aralhas: 'aralhas',
  aralina: 'aralina',
  aramaca: 'aramaçã',
  aramada: 'aramada',
  aramado: 'aramado',
  aramais: 'aramais',
  aramamo: 'aramamo',
  aramane: 'aramané',
  aramara: 'aramara',
  aramare: 'aramaré',
  aramata: 'aramatá',
  aramava: 'aramava',
  arambas: 'arambás',
  arameia: 'arameia',
  arameis: 'arameis',
  aramemo: 'aramemo',
  aramesa: 'aramesa',
  arameus: 'arameus',
  aramica: 'arâmica',
  aramico: 'arâmico',
  aramida: 'aramida',
  aramina: 'aramina',
  aramita: 'aramita',
  aramite: 'aramite',
  aramosa: 'aramosa',
  aramoso: 'aramoso',
  aramuda: 'aramuda',
  aramudo: 'aramudo',
  aramuru: 'aramuru',
  aranane: 'aranane',
  aranata: 'aranata',
  arancim: 'arancim',
  arancua: 'arancuã',
  arangao: 'arangão',
  aranhai: 'aranhai',
  aranham: 'aranham',
  aranhao: 'aranhão',
  aranhar: 'aranhar',
  aranhas: 'aranhas',
  aranhei: 'aranhei',
  aranhem: 'aranhem',
  aranhes: 'aranhes',
  aranhol: 'aranhol',
  aranhou: 'aranhou',
  araniti: 'araniti',
  aranoso: 'aranoso',
  aranqua: 'aranquã',
  arantes: 'arantes',
  aranzel: 'aranzel',
  arapace: 'arapace',
  arapaco: 'arapaço',
  arapacu: 'arapaçu',
  arapaju: 'arapaju',
  arapaos: 'arapaós',
  arapapa: 'arapapá',
  arapari: 'arapari',
  araparu: 'araparu',
  arapati: 'arapati',
  arapaus: 'arapaús',
  arapeba: 'arapeba',
  arapene: 'arapene',
  araperu: 'araperu',
  arapium: 'arapium',
  arapoca: 'arapoca',
  arapopo: 'arapopó',
  arapuai: 'arapuai',
  arapuam: 'arapuam',
  arapuar: 'arapuar',
  arapuas: 'arapuas',
  arapuca: 'arapuca',
  arapucu: 'arapucu',
  arapuei: 'arapuei',
  arapuem: 'arapuem',
  arapues: 'arapues',
  arapuou: 'arapuou',
  arapura: 'arapura',
  arapure: 'arapure',
  arapuro: 'arapuro',
  arapuru: 'arapuru',
  araquas: 'araquãs',
  araques: 'araques',
  araquis: 'aráquis',
  araracu: 'araracu',
  ararada: 'araradá',
  ararais: 'araraís',
  ararama: 'ararama',
  ararana: 'araranã',
  ararapa: 'ararapá',
  ararari: 'ararari',
  araraua: 'ararauá',
  araraus: 'araraus',
  arardes: 'arardes',
  arareis: 'arareis',
  araremo: 'araremo',
  arareua: 'araréua',
  arariam: 'arariam',
  ararias: 'ararias',
  arariba: 'arariba',
  ararica: 'ararica',
  arariga: 'ararigã',
  ararina: 'ararina',
  ararita: 'ararita',
  arariua: 'arariua',
  ararixa: 'ararixá',
  ararmos: 'ararmos',
  araroba: 'araroba',
  araruas: 'araruás',
  araruba: 'araruba',
  araruna: 'araruna',
  araruta: 'araruta',
  araruva: 'araruva',
  arasoes: 'arasões',
  arassem: 'arassem',
  arasses: 'arasses',
  arastes: 'arastes',
  arataca: 'arataca',
  arataia: 'arataiá',
  arataua: 'aratauá',
  araticu: 'araticu',
  aratins: 'aratins',
  arature: 'araturé',
  arauana: 'arauaná',
  arauari: 'arauari',
  arauato: 'arauató',
  arauatu: 'arauatu',
  araucas: 'araucas',
  arauete: 'araueté',
  arauiri: 'arauiri',
  arauiti: 'arauiti',
  araujos: 'araújos',
  arautos: 'arautos',
  aravaco: 'aravaco',
  aravari: 'aravari',
  araveca: 'araveca',
  araveis: 'aráveis',
  aravela: 'aravela',
  araviai: 'araviai',
  araviam: 'araviam',
  araviar: 'araviar',
  aravias: 'aravias',
  araviei: 'araviei',
  araviem: 'araviem',
  aravies: 'aravies',
  aravine: 'aravine',
  araviou: 'araviou',
  araxana: 'araxana',
  araxaue: 'araxaué',
  araxina: 'araxina',
  araxinu: 'araxinu',
  araxixa: 'araxixá',
  araxixu: 'araxixu',
  araxues: 'araxués',
  arazoia: 'arazoia',
  arbacia: 'arbácia',
  arbelos: 'arbelos',
  arbenas: 'arbenas',
  arbitas: 'arbitas',
  arbitra: 'arbitra',
  arbitre: 'arbitre',
  arbitro: 'árbitro',
  arboral: 'arboral',
  arborea: 'arbórea',
  arboreo: 'arbóreo',
  arbusto: 'arbusto',
  arbutos: 'árbutos',
  arcabem: 'arcabém',
  arcabuz: 'arcabuz',
  arcacea: 'arcácea',
  arcaceo: 'arcáceo',
  arcadao: 'arcadão',
  arcadas: 'arcadas',
  arcades: 'árcades',
  arcadia: 'arcádia',
  arcadio: 'arcádio',
  arcador: 'arcador',
  arcados: 'arcados',
  arcaica: 'arcaica',
  arcaico: 'arcaico',
  arcaiza: 'arcaíza',
  arcaize: 'arcaíze',
  arcaizo: 'arcaízo',
  arcamos: 'arcamos',
  arcanas: 'arcanas',
  arcando: 'arcando',
  arcanea: 'arcânea',
  arcanes: 'arcanes',
  arcanha: 'arçanha',
  arcanja: 'arcanja',
  arcanjo: 'arcanjo',
  arcanos: 'arcanos',
  arcante: 'arcante',
  arcaram: 'arcaram',
  arcarao: 'arcarão',
  arcaras: 'arcaras',
  arcarei: 'arcarei',
  arcarem: 'arcarem',
  arcares: 'arcares',
  arcaria: 'arcaria',
  arcario: 'arcário',
  arcarmo: 'arcarmo',
  arcasse: 'arcasse',
  arcaste: 'arcaste',
  arcavam: 'arcavam',
  arcavas: 'arcavas',
  arcazes: 'arcazes',
  arcelas: 'arcelas',
  arcella: 'arcella',
  arcense: 'arcense',
  arceras: 'arceras',
  arcetes: 'arcetes',
  arceuto: 'arceuto',
  archaea: 'archaea',
  archete: 'archete',
  archote: 'archote',
  arciria: 'arcíria',
  arcolim: 'arcolim',
  arconte: 'arconte',
  arcosas: 'arcosas',
  arcoseo: 'arcóseo',
  arcoses: 'arcoses',
  arcosio: 'arcósio',
  arcosos: 'arcosos',
  arcozio: 'arcózio',
  arctada: 'arctada',
  arctado: 'arctado',
  arctais: 'arctais',
  arctamo: 'arctamo',
  arctara: 'arctará',
  arctava: 'arctava',
  arcteis: 'arcteis',
  arctemo: 'arctemo',
  arctica: 'árctica',
  arctico: 'árctico',
  arctoas: 'árctoas',
  arcturo: 'arcturo',
  arcuada: 'arcuada',
  arcuado: 'arcuado',
  arcuais: 'arcuais',
  arcuamo: 'arcuamo',
  arcuara: 'arcuará',
  arcuava: 'arcuava',
  arcueis: 'arcueis',
  arcuemo: 'arcuemo',
  arculas: 'árculas',
  arculho: 'arculho',
  arcumas: 'arcumãs',
  ardamos: 'ardamos',
  ardeate: 'ardeate',
  ardedor: 'ardedor',
  ardegas: 'árdegas',
  ardegos: 'árdegos',
  ardeida: 'ardeida',
  ardejai: 'ardejai',
  ardejam: 'ardejam',
  ardejar: 'ardejar',
  ardejas: 'ardejas',
  ardejei: 'ardejei',
  ardejem: 'ardejem',
  ardejes: 'ardejes',
  ardejou: 'ardejou',
  ardemos: 'ardemos',
  ardendo: 'ardendo',
  ardenes: 'ardenês',
  ardente: 'ardente',
  arderam: 'arderam',
  arderao: 'arderão',
  arderas: 'arderas',
  arderei: 'arderei',
  arderem: 'arderem',
  arderes: 'arderes',
  arderia: 'arderia',
  ardermo: 'ardermo',
  ardesse: 'ardesse',
  ardeste: 'ardeste',
  ardiana: 'ardiana',
  ardiano: 'ardiano',
  ardidao: 'ardidão',
  ardidas: 'ardidas',
  ardidez: 'ardidez',
  ardidos: 'ardidos',
  ardieis: 'ardíeis',
  ardieus: 'ardieus',
  ardigai: 'ardigai',
  ardigam: 'ardigam',
  ardigar: 'ardigar',
  ardigas: 'ardigas',
  ardigou: 'ardigou',
  ardigue: 'ardigue',
  ardilao: 'ardilão',
  ardilez: 'ardilez',
  ardinas: 'ardinas',
  ardingo: 'ardingo',
  ardisia: 'ardísia',
  arditas: 'arditas',
  ardoras: 'ardoras',
  ardores: 'ardores',
  ardosas: 'ardosas',
  ardoses: 'ardoses',
  ardosia: 'ardósia',
  ardosie: 'ardosie',
  ardosio: 'ardosio',
  ardosos: 'ardosos',
  arduina: 'arduína',
  ardumes: 'ardumes',
  arduras: 'arduras',
  areacao: 'areação',
  areacos: 'areacós',
  areadao: 'areadão',
  areadas: 'areadas',
  areador: 'areador',
  areados: 'areados',
  arealus: 'arealus',
  areamos: 'areamos',
  areando: 'areando',
  areante: 'areante',
  arearam: 'arearam',
  arearao: 'arearão',
  arearas: 'arearás',
  arearei: 'arearei',
  arearem: 'arearem',
  areares: 'areares',
  arearia: 'arearia',
  areasse: 'areasse',
  areaste: 'areaste',
  areatas: 'areatas',
  areavam: 'areavam',
  areavas: 'areavas',
  arecais: 'arecais',
  arecale: 'arecale',
  arecina: 'arecina',
  arecuna: 'arecuna',
  arecuno: 'arecuno',
  areeira: 'areeira',
  areeiro: 'areeiro',
  areemos: 'areemos',
  areense: 'areense',
  areenta: 'areenta',
  areento: 'areento',
  areinho: 'areinho',
  areiras: 'areiras',
  areisca: 'areísca',
  areisco: 'areísco',
  areismo: 'areísmo',
  arejada: 'arejada',
  arejado: 'arejado',
  arejais: 'arejais',
  arejamo: 'arejamo',
  arejara: 'arejara',
  arejava: 'arejava',
  arejeis: 'arejeis',
  arejemo: 'arejemo',
  arelhas: 'arelhas',
  arelzao: 'arelzão',
  arenada: 'arenada',
  arenado: 'arenado',
  arenais: 'arenais',
  arenamo: 'arenamo',
  arenara: 'arenará',
  arenata: 'arenata',
  arenato: 'arenato',
  arenava: 'arenava',
  arencus: 'arencus',
  areneis: 'areneis',
  arenemo: 'arenemo',
  arengai: 'arengai',
  arengam: 'arengam',
  arengar: 'arengar',
  arengas: 'arengas',
  arengou: 'arengou',
  arengue: 'arengue',
  arenico: 'arênico',
  arenito: 'arenito',
  arenosa: 'arenosa',
  arenoso: 'arenoso',
  arenque: 'arenque',
  arensai: 'arensai',
  arensam: 'arensam',
  arensar: 'arensar',
  arensas: 'arensas',
  arensei: 'arensei',
  arensem: 'arensem',
  arenses: 'arenses',
  arensou: 'arensou',
  areolar: 'areolar',
  areolas: 'aréolas',
  areosas: 'areosas',
  areosca: 'areosca',
  areosos: 'areosos',
  arepaba: 'arepaba',
  arepene: 'arepene',
  arestal: 'arestal',
  arestas: 'arestas',
  arestim: 'arestim',
  arestos: 'arestos',
  aretina: 'aretina',
  aretino: 'aretino',
  arevaco: 'arevaco',
  arfadao: 'arfadão',
  arfadas: 'arfadas',
  arfador: 'arfador',
  arfados: 'arfados',
  arfagem: 'arfagem',
  arfamos: 'arfamos',
  arfando: 'arfando',
  arfante: 'arfante',
  arfaram: 'arfaram',
  arfarao: 'arfarão',
  arfaras: 'arfaras',
  arfarei: 'arfarei',
  arfarem: 'arfarem',
  arfares: 'arfares',
  arfaria: 'arfaria',
  arfarmo: 'arfarmo',
  arfasse: 'arfasse',
  arfaste: 'arfaste',
  arfavam: 'arfavam',
  arfavas: 'arfavas',
  arfeloa: 'arféloa',
  arfemos: 'arfemos',
  arferia: 'arféria',
  argacos: 'argaços',
  argalas: 'argalas',
  argalha: 'argalha',
  argalho: 'argalho',
  argalia: 'argália',
  argalos: 'argalos',
  arganas: 'arganas',
  arganaz: 'arganaz',
  arganel: 'arganel',
  arganeu: 'arganéu',
  argania: 'argânia',
  arganil: 'arganil',
  arganis: 'arganis',
  arganiz: 'arganiz',
  arganos: 'arganos',
  argemas: 'árgemas',
  argemon: 'argêmon',
  argenta: 'argenta',
  argente: 'argente',
  argento: 'argento',
  argeste: 'argeste',
  argiana: 'argiana',
  argiano: 'argiano',
  argilas: 'argilas',
  argilia: 'argília',
  argilos: 'argilos',
  arginas: 'arginas',
  argiope: 'argiope',
  argiras: 'árgiras',
  argirea: 'argírea',
  argireo: 'argíreo',
  argiria: 'argiria',
  argirol: 'argirol',
  argiros: 'árgiros',
  argites: 'argites',
  argivas: 'argivas',
  argivos: 'argivos',
  argolai: 'argolai',
  argolam: 'argolam',
  argolao: 'argolão',
  argolar: 'argolar',
  argolas: 'argolas',
  argolei: 'argolei',
  argolem: 'argolem',
  argoles: 'argoles',
  argolou: 'argolou',
  argomas: 'argomas',
  argonai: 'argonai',
  argonam: 'argonam',
  argonar: 'argonar',
  argonas: 'argonas',
  argonei: 'argonei',
  argonem: 'argonem',
  argones: 'argones',
  argonio: 'argônio',
  argonou: 'argonou',
  arguais: 'arguais',
  arguamo: 'arguamo',
  argucia: 'argúcia',
  argucie: 'argucie',
  argucio: 'argucio',
  arguesa: 'arguesa',
  arguiam: 'arguiam',
  arguias: 'arguias',
  arguico: 'arguiço',
  arguida: 'arguida',
  arguido: 'arguido',
  arguimo: 'arguimo',
  arguira: 'arguira',
  argulos: 'árgulos',
  argutas: 'argutas',
  argutos: 'argutos',
  argylis: 'argylis',
  ariacos: 'ariacós',
  ariadao: 'ariadão',
  ariadas: 'ariadas',
  ariadne: 'ariadne',
  ariador: 'ariador',
  ariados: 'ariados',
  ariamos: 'ariamos',
  arianas: 'arianas',
  ariando: 'ariando',
  arianos: 'arianos',
  ariante: 'ariante',
  ariaram: 'ariaram',
  ariarao: 'ariarão',
  ariaras: 'ariaras',
  ariarei: 'ariarei',
  ariarem: 'ariarem',
  ariares: 'ariares',
  ariaria: 'ariaria',
  ariarmo: 'ariarmo',
  ariasse: 'ariasse',
  ariaste: 'ariaste',
  ariauas: 'ariauás',
  ariavam: 'ariavam',
  ariavas: 'ariavas',
  ariaxes: 'ariaxés',
  aribalo: 'aribalo',
  aribina: 'aribina',
  aricada: 'aricada',
  aricado: 'aricado',
  aricais: 'aricais',
  aricamo: 'aricamo',
  aricana: 'aricana',
  aricapu: 'aricapu',
  aricara: 'aricará',
  aricava: 'aricava',
  aricino: 'aricinó',
  aricita: 'aricita',
  aricite: 'aricite',
  aricito: 'aricito',
  aricobe: 'aricobé',
  aricori: 'aricori',
  aricuia: 'aricuiá',
  aricuis: 'aricuís',
  aricuna: 'aricuna',
  aricuri: 'aricuri',
  aridade: 'aridade',
  ariemos: 'ariemos',
  ariense: 'ariense',
  arietai: 'arietai',
  arietam: 'arietam',
  arietar: 'arietar',
  arietas: 'arietas',
  arietei: 'arietei',
  arietem: 'arietem',
  arietes: 'aríetes',
  arietou: 'arietou',
  arigofe: 'arigofe',
  ariideo: 'ariídeo',
  ariinis: 'ariinis',
  arilada: 'arilada',
  arilado: 'arilado',
  arileno: 'arileno',
  arilhos: 'arilhos',
  arilica: 'arílica',
  arilico: 'arílico',
  arimaru: 'arimaru',
  arimbas: 'arimbás',
  arimono: 'arimono',
  arincus: 'arincus',
  arinfeu: 'arinfeu',
  aringao: 'aringão',
  aringas: 'aringas',
  arinhas: 'arinhas',
  arinhos: 'arinhos',
  arinque: 'arinque',
  arintas: 'arintas',
  arintos: 'arintos',
  ariocos: 'ariocós',
  ariolos: 'aríolos',
  ariosas: 'ariosas',
  ariosca: 'ariosca',
  ariosos: 'ariosos',
  aripada: 'aripada',
  aripado: 'aripado',
  aripais: 'aripais',
  aripamo: 'aripamo',
  aripara: 'aripará',
  aripava: 'aripava',
  aripeis: 'aripeis',
  aripemo: 'aripemo',
  aripene: 'aripene',
  aripiai: 'aripiai',
  aripiam: 'aripiam',
  aripiar: 'aripiar',
  aripias: 'aripias',
  aripiei: 'aripiei',
  aripiem: 'aripiem',
  aripies: 'aripies',
  aripiou: 'aripiou',
  aripuas: 'aripuás',
  ariquei: 'ariquei',
  ariquem: 'ariquém',
  ariques: 'ariques',
  arirama: 'arirama',
  arirana: 'arirana',
  aririba: 'ariribá',
  arisaro: 'arisaro',
  ariscai: 'ariscai',
  ariscam: 'ariscam',
  ariscar: 'ariscar',
  ariscas: 'ariscas',
  ariscos: 'ariscos',
  ariscou: 'ariscou',
  arisema: 'arisema',
  arisque: 'arisque',
  aristao: 'aristão',
  aristas: 'aristas',
  aristol: 'aristol',
  ariston: 'aríston',
  aristos: 'aristos',
  aristus: 'aristus',
  aritaua: 'aritauá',
  aritica: 'aritica',
  arivate: 'arivate',
  arjoada: 'arjoada',
  arjoado: 'arjoado',
  arjoais: 'arjoais',
  arjoamo: 'arjoamo',
  arjoara: 'arjoará',
  arjoava: 'arjoava',
  arjoeis: 'arjoeis',
  arjoemo: 'arjoemo',
  arjonas: 'arjonas',
  arjoona: 'arjoona',
  arjozes: 'arjozes',
  arjunca: 'arjunça',
  arlique: 'arlique',
  arlotao: 'arlotão',
  arlotas: 'arlotas',
  arlotia: 'arlotia',
  armacao: 'armação',
  armadao: 'armadão',
  armadas: 'armadas',
  armador: 'armador',
  armados: 'armados',
  armalol: 'armalol',
  armamos: 'armamos',
  armanco: 'armanço',
  armando: 'armando',
  armanha: 'armanha',
  armania: 'armânia',
  armanso: 'armanso',
  armante: 'armante',
  armaram: 'armaram',
  armarao: 'armarão',
  armaras: 'armaras',
  armarei: 'armarei',
  armarem: 'armarem',
  armares: 'armares',
  armaria: 'armaria',
  armario: 'armário',
  armarmo: 'armarmo',
  armasse: 'armasse',
  armaste: 'armaste',
  armatao: 'armatão',
  armavam: 'armavam',
  armavas: 'armavas',
  armazem: 'armazém',
  armeira: 'armeira',
  armeiro: 'armeiro',
  armelas: 'armelas',
  armelim: 'armelim',
  armelos: 'armelos',
  armemos: 'armemos',
  armenga: 'armenga',
  armengo: 'armengo',
  armenha: 'armenha',
  armenio: 'armênio',
  armenta: 'armenta',
  armento: 'armento',
  armeria: 'arméria',
  armezim: 'armezim',
  armicas: 'ármicas',
  armicos: 'ármicos',
  armilai: 'armilai',
  armilam: 'armilam',
  armilar: 'armilar',
  armilas: 'armilas',
  armilei: 'armilei',
  armilem: 'armilem',
  armiles: 'armiles',
  armilha: 'armilha',
  armilou: 'armilou',
  arminas: 'arminas',
  arminha: 'arminha',
  arminhe: 'arminhe',
  arminho: 'arminho',
  arminos: 'arminos',
  armismo: 'armismo',
  armista: 'armista',
  armolao: 'armolão',
  armolas: 'armolas',
  armoles: 'armoles',
  armoria: 'armoria',
  armorie: 'armorie',
  armorio: 'armorio',
  armosas: 'armosas',
  armosia: 'armósia',
  armosos: 'armosos',
  armozeu: 'armozeu',
  arnabes: 'arnabes',
  arnabos: 'arnabos',
  arnadas: 'arnadas',
  arnados: 'arnados',
  arnates: 'arnates',
  arnazes: 'arnazes',
  arnebia: 'arnébia',
  arnecas: 'arnecãs',
  arnedos: 'arnedos',
  arnegai: 'arnegai',
  arnegam: 'arnegam',
  arnegar: 'arnegar',
  arnegas: 'arnegas',
  arnegou: 'arnegou',
  arneira: 'arneira',
  arneire: 'arneire',
  arneiro: 'arneiro',
  arnelas: 'arnelas',
  arnelha: 'arnelha',
  arnelho: 'arnelho',
  arnelia: 'arnélia',
  arnelos: 'arnelos',
  arnento: 'arnento',
  arnesai: 'arnesai',
  arnesam: 'arnesam',
  arnesar: 'arnesar',
  arnesas: 'arnesas',
  arnesei: 'arnesei',
  arnesem: 'arnesem',
  arneses: 'arneses',
  arnesou: 'arnesou',
  arnicai: 'arnicai',
  arnicam: 'arnicam',
  arnicar: 'arnicar',
  arnicas: 'arnicas',
  arnicou: 'arnicou',
  arnilha: 'arnilha',
  arnique: 'arnique',
  arnitos: 'arnitos',
  arnocia: 'arnócia',
  arnodos: 'arnodos',
  arnolol: 'arnolol',
  arnolta: 'arnolta',
  arnosas: 'arnosas',
  arnosos: 'arnosos',
  arnotes: 'arnotes',
  arnotos: 'arnotos',
  aroaqui: 'aroaqui',
  aroeira: 'aroeira',
  aroeiro: 'aroeiro',
  aromada: 'aromada',
  aromado: 'aromado',
  aromais: 'aromais',
  aromamo: 'aromamo',
  aromara: 'aromara',
  aromata: 'arômata',
  aromato: 'aromato',
  aromava: 'aromava',
  aromeis: 'aromeis',
  aromemo: 'aromemo',
  aromias: 'aromias',
  aromosa: 'aromosa',
  aromoso: 'aromoso',
  aroneas: 'arôneas',
  arpadao: 'arpadão',
  arpadas: 'arpadas',
  arpador: 'arpador',
  arpados: 'arpados',
  arpamos: 'arpamos',
  arpando: 'arpando',
  arpante: 'arpante',
  arparam: 'arparam',
  arparao: 'arparão',
  arparas: 'arparás',
  arparei: 'arparei',
  arparem: 'arparem',
  arpares: 'arpares',
  arparia: 'arparia',
  arparmo: 'arparmo',
  arpasse: 'arpasse',
  arpaste: 'arpaste',
  arpavam: 'arpavam',
  arpavas: 'arpavas',
  arpeada: 'arpeada',
  arpeado: 'arpeado',
  arpeais: 'arpeais',
  arpeara: 'arpeará',
  arpeava: 'arpeava',
  arpedio: 'arpédio',
  arpeeis: 'arpeeis',
  arpeiam: 'arpeiam',
  arpeias: 'arpeias',
  arpeiem: 'arpeiem',
  arpeies: 'arpeies',
  arpejai: 'arpejai',
  arpejam: 'arpejam',
  arpejar: 'arpejar',
  arpejas: 'arpejas',
  arpejei: 'arpejei',
  arpejem: 'arpejem',
  arpejes: 'arpejes',
  arpejos: 'arpejos',
  arpejou: 'arpejou',
  arpemos: 'arpemos',
  arpente: 'arpente',
  arpento: 'arpento',
  arpinos: 'arpinos',
  arpista: 'arpista',
  arpoada: 'arpoada',
  arpoado: 'arpoado',
  arpoais: 'arpoais',
  arpoamo: 'arpoamo',
  arpoara: 'arpoara',
  arpoava: 'arpoava',
  arpoeis: 'arpoeis',
  arpoemo: 'arpoemo',
  arpooes: 'arpoões',
  arpuada: 'arpuada',
  arpuado: 'arpuado',
  arpuais: 'arpuais',
  arpuamo: 'arpuamo',
  arpuara: 'arpuara',
  arpuava: 'arpuava',
  arpueis: 'arpueis',
  arpuemo: 'arpuemo',
  arqueai: 'arqueai',
  arquear: 'arquear',
  arqueei: 'arqueei',
  arqueia: 'arqueia',
  arqueie: 'arqueie',
  arqueio: 'arqueio',
  arqueis: 'arqueis',
  arqueja: 'arqueja',
  arqueje: 'arqueje',
  arquejo: 'arquejo',
  arquemo: 'arquemo',
  arqueol: 'arqueol',
  arqueou: 'arqueou',
  arqueta: 'arqueta',
  arquete: 'arquete',
  arqueus: 'arqueus',
  arquico: 'arquico',
  arquios: 'arquios',
  arquita: 'arquita',
  arquite: 'arquite',
  arquiva: 'arquiva',
  arquive: 'arquive',
  arquivo: 'arquivo',
  arrabai: 'arrabai',
  arrabam: 'arrabam',
  arrabar: 'arrabar',
  arrabas: 'arrabas',
  arrabei: 'arrabei',
  arrabel: 'arrabel',
  arrabem: 'arrabem',
  arrabes: 'arrabes',
  arrabil: 'arrabil',
  arrabio: 'arrabio',
  arrabis: 'arrabis',
  arrabou: 'arrabou',
  arracai: 'arraçai',
  arracal: 'arraçal',
  arracam: 'arraçam',
  arracar: 'arraçar',
  arracas: 'arraças',
  arracei: 'arracei',
  arracem: 'arracem',
  arraces: 'arraces',
  arracoa: 'arraçoa',
  arracoe: 'arraçoe',
  arracoo: 'arraçoo',
  arracou: 'arraçou',
  arrafim: 'arrafim',
  arraiai: 'arraiai',
  arraial: 'arraial',
  arraiam: 'arraiam',
  arraiao: 'arraião',
  arraiar: 'arraiar',
  arraias: 'arraias',
  arraida: 'arraída',
  arraido: 'arraído',
  arraiei: 'arraiei',
  arraiem: 'arraiem',
  arraies: 'arraies',
  arraiga: 'arraiga',
  arraigo: 'arraigo',
  arraiou: 'arraiou',
  arraira: 'arraíra',
  arralai: 'arralai',
  arralam: 'arralam',
  arralar: 'arralar',
  arralas: 'arralas',
  arralei: 'arralei',
  arralem: 'arralem',
  arrales: 'arrales',
  arralou: 'arralou',
  arramai: 'arramai',
  arramam: 'arramam',
  arramar: 'arramar',
  arramas: 'arramas',
  arramei: 'arramei',
  arramem: 'arramem',
  arrames: 'arrames',
  arramos: 'arramos',
  arramou: 'arramou',
  arranca: 'arranca',
  arrance: 'arrance',
  arranco: 'arranco',
  arranha: 'arranha',
  arranhe: 'arranhe',
  arranho: 'arranho',
  arranja: 'arranja',
  arranje: 'arranje',
  arranjo: 'arranjo',
  arrarai: 'arrarai',
  arraram: 'arraram',
  arrarar: 'arrarar',
  arraras: 'arraras',
  arrarei: 'arrarei',
  arrarem: 'arrarem',
  arrares: 'arrares',
  arrarou: 'arrarou',
  arrasai: 'arrasai',
  arrasam: 'arrasam',
  arrasar: 'arrasar',
  arrasas: 'arrasas',
  arrasei: 'arrasei',
  arrasem: 'arrasem',
  arrases: 'arrases',
  arrasou: 'arrasou',
  arrasta: 'arrasta',
  arraste: 'arraste',
  arrasto: 'arrasto',
  arratai: 'arratai',
  arratam: 'arratam',
  arratar: 'arratar',
  arratas: 'arratas',
  arratei: 'arratei',
  arratel: 'arrátel',
  arratem: 'arratem',
  arrates: 'arrates',
  arratou: 'arratou',
  arrazoa: 'arrazoa',
  arrazoe: 'arrazoe',
  arrazoo: 'arrazoo',
  arreada: 'arreada',
  arreado: 'arreado',
  arreais: 'arreais',
  arreala: 'arreala',
  arreale: 'arreale',
  arrealo: 'arrealo',
  arreamo: 'arreamo',
  arreara: 'arreara',
  arreata: 'arreata',
  arreate: 'arreate',
  arreato: 'arreato',
  arreava: 'arreava',
  arrebem: 'arrebém',
  arrebol: 'arrebol',
  arrecal: 'arreçal',
  arrecas: 'arreçãs',
  arrecea: 'arreceá',
  arrecoa: 'arreçoá',
  arrecoe: 'arreçoe',
  arrecoo: 'arreçoo',
  arrecto: 'arrecto',
  arrecua: 'arrecua',
  arrecue: 'arrecue',
  arrecuo: 'arrecuo',
  arredai: 'arredai',
  arredam: 'arredam',
  arredar: 'arredar',
  arredas: 'arredas',
  arredei: 'arredei',
  arredem: 'arredem',
  arredes: 'arredes',
  arredia: 'arredia',
  arredio: 'arredio',
  arredoa: 'arredoa',
  arredoe: 'arredoe',
  arredoo: 'arredoo',
  arredor: 'arredor',
  arredou: 'arredou',
  arredra: 'arredrá',
  arredre: 'arredre',
  arredro: 'arredro',
  arreeis: 'arreeis',
  arreemo: 'arreemo',
  arrefem: 'arrefém',
  arregla: 'arregla',
  arregle: 'arregle',
  arreglo: 'arreglo',
  arregoa: 'arregoa',
  arregoe: 'arregoe',
  arregoo: 'arregoo',
  arregos: 'arregos',
  arregra: 'arregra',
  arregre: 'arregre',
  arregro: 'arregro',
  arreiam: 'arreiam',
  arreias: 'arreias',
  arreica: 'arreica',
  arreico: 'arreico',
  arreiem: 'arreiem',
  arreies: 'arreies',
  arreiga: 'arreiga',
  arreigo: 'arreigo',
  arreios: 'arreios',
  arreita: 'arreita',
  arreite: 'arreite',
  arreito: 'arreito',
  arrelha: 'arrelha',
  arrelhe: 'arrelhe',
  arrelho: 'arrelho',
  arrelia: 'arrelia',
  arrelie: 'arrelie',
  arrelio: 'arrelio',
  arrelva: 'arrelva',
  arrelve: 'arrelve',
  arrelvo: 'arrelvo',
  arremos: 'arremos',
  arrenal: 'arrenal',
  arrenda: 'arrenda',
  arrende: 'arrende',
  arrendo: 'arrendo',
  arrenga: 'arrengá',
  arrengo: 'arrengo',
  arrenos: 'arrenos',
  arrenta: 'arrenta',
  arrente: 'arrente',
  arrento: 'arrento',
  arrepia: 'arrepia',
  arrepie: 'arrepie',
  arrepio: 'arrepio',
  arrequi: 'arrequi',
  arreram: 'arreram',
  arrerao: 'arrerão',
  arreras: 'arrerás',
  arrerei: 'arrerei',
  arrerem: 'arrerem',
  arreres: 'arreres',
  arreria: 'arreria',
  arrermo: 'arrermo',
  arresse: 'arresse',
  arresta: 'arresta',
  arreste: 'arreste',
  arresto: 'arresto',
  arretai: 'arretai',
  arretam: 'arretam',
  arretar: 'arretar',
  arretas: 'arretas',
  arretei: 'arretei',
  arretem: 'arretem',
  arretes: 'arretes',
  arretou: 'arretou',
  arrevem: 'arrevém',
  arriada: 'arriada',
  arriado: 'arriado',
  arriais: 'arriais',
  arriamo: 'arriamo',
  arriara: 'arriara',
  arriava: 'arriava',
  arribai: 'arribai',
  arribam: 'arribam',
  arribar: 'arribar',
  arribas: 'arribas',
  arribei: 'arribei',
  arribem: 'arribem',
  arribes: 'arribes',
  arribou: 'arribou',
  arricai: 'arriçai',
  arricam: 'arriçam',
  arricar: 'arriçar',
  arricas: 'arriças',
  arricei: 'arricei',
  arricem: 'arricem',
  arrices: 'arrices',
  arricou: 'arriçou',
  arridai: 'arridai',
  arridam: 'arridam',
  arridao: 'arridão',
  arridar: 'arridar',
  arridas: 'arridas',
  arridei: 'arridei',
  arridem: 'arridem',
  arrides: 'arrides',
  arridos: 'arridos',
  arridou: 'arridou',
  arrieis: 'arrieis',
  arriemo: 'arriemo',
  arrifai: 'arrifai',
  arrifam: 'arrifam',
  arrifar: 'arrifar',
  arrifas: 'arrifas',
  arrifei: 'arrifei',
  arrifem: 'arrifem',
  arrifes: 'arrifes',
  arrifou: 'arrifou',
  arrigai: 'arrigai',
  arrigam: 'arrigam',
  arrigar: 'arrigar',
  arrigas: 'arrigas',
  arrigou: 'arrigou',
  arrigue: 'arrigue',
  arrijai: 'arrijai',
  arrijam: 'arrijam',
  arrijar: 'arrijar',
  arrijas: 'arrijas',
  arrijei: 'arrijei',
  arrijem: 'arrijem',
  arrijes: 'arrijes',
  arrijou: 'arrijou',
  arrimai: 'arrimai',
  arrimam: 'arrimam',
  arrimao: 'arrimão',
  arrimar: 'arrimar',
  arrimas: 'arrimas',
  arrimei: 'arrimei',
  arrimem: 'arrimem',
  arrimes: 'arrimes',
  arrimos: 'arrimos',
  arrimou: 'arrimou',
  arrinas: 'arrinas',
  arrinca: 'arrinca',
  arrinco: 'arrinco',
  arrinho: 'arrinho',
  arrinia: 'arrinia',
  arrinos: 'arrinos',
  arriois: 'arrióis',
  arriota: 'arriota',
  arripai: 'arripai',
  arripam: 'arripam',
  arripar: 'arripar',
  arripas: 'arripas',
  arripei: 'arripei',
  arripem: 'arripem',
  arripes: 'arripes',
  arripou: 'arripou',
  arrisca: 'arrisca',
  arrisco: 'arrisco',
  arritmo: 'arritmo',
  arrival: 'arrival',
  arrizai: 'arrizai',
  arrizam: 'arrizam',
  arrizar: 'arrizar',
  arrizas: 'arrizas',
  arrizei: 'arrizei',
  arrizem: 'arrizem',
  arrizes: 'arrizes',
  arrizos: 'arrizos',
  arrizou: 'arrizou',
  arrière: 'arrière',
  arrobai: 'arrobai',
  arrobam: 'arrobam',
  arrobar: 'arrobar',
  arrobas: 'arrobas',
  arrobei: 'arrobei',
  arrobem: 'arrobem',
  arrobes: 'arrobes',
  arrobis: 'arrobis',
  arrobou: 'arrobou',
  arrocha: 'arrocha',
  arroche: 'arroche',
  arrocho: 'arrocho',
  arrocia: 'arrociá',
  arrocie: 'arrocie',
  arrocio: 'arrocio',
  arrodea: 'arrodeá',
  arrofos: 'arrofos',
  arrogai: 'arrogai',
  arrogam: 'arrogam',
  arrogar: 'arrogar',
  arrogas: 'arrogas',
  arrogia: 'arrogia',
  arrogou: 'arrogou',
  arrogue: 'arrogue',
  arroiai: 'arroiai',
  arroiam: 'arroiam',
  arroiar: 'arroiar',
  arroias: 'arroias',
  arroiei: 'arroiei',
  arroiem: 'arroiem',
  arroies: 'arroies',
  arroios: 'arroios',
  arroiou: 'arroiou',
  arrojai: 'arrojai',
  arrojam: 'arrojam',
  arrojao: 'arrojão',
  arrojar: 'arrojar',
  arrojas: 'arrojas',
  arrojei: 'arrojei',
  arrojem: 'arrojem',
  arrojes: 'arrojes',
  arrojos: 'arrojos',
  arrojou: 'arrojou',
  arrolai: 'arrolai',
  arrolam: 'arrolam',
  arrolar: 'arrolar',
  arrolas: 'arrolas',
  arrolei: 'arrolei',
  arrolem: 'arrolem',
  arroles: 'arroles',
  arrolha: 'arrolha',
  arrolhe: 'arrolhe',
  arrolho: 'arrolho',
  arrolos: 'arrolos',
  arrolou: 'arrolou',
  arromba: 'arromba',
  arrombe: 'arrombe',
  arrombo: 'arrombo',
  arrosta: 'arrosta',
  arroste: 'arroste',
  arrosto: 'arrosto',
  arrotai: 'arrotai',
  arrotam: 'arrotam',
  arrotar: 'arrotar',
  arrotas: 'arrotas',
  arrotea: 'arroteá',
  arrotei: 'arrotei',
  arrotem: 'arrotem',
  arrotes: 'arrotes',
  arrotos: 'arrotos',
  arrotou: 'arrotou',
  arrouba: 'arrouba',
  arroube: 'arroube',
  arroubo: 'arroubo',
  arrouco: 'arrouço',
  arroupa: 'arroupa',
  arroupe: 'arroupe',
  arroupo: 'arroupo',
  arroxai: 'arroxai',
  arroxam: 'arroxam',
  arroxar: 'arroxar',
  arroxas: 'arroxas',
  arroxea: 'arroxeá',
  arroxei: 'arroxei',
  arroxem: 'arroxem',
  arroxes: 'arroxes',
  arroxou: 'arroxou',
  arrozal: 'arrozal',
  arrozao: 'arrozão',
  arrozes: 'arrozes',
  arrozia: 'arrózia',
  arruaca: 'arruaça',
  arruace: 'arruace',
  arruaco: 'arruaço',
  arruada: 'arruada',
  arruado: 'arruado',
  arruais: 'arruais',
  arruamo: 'arruamo',
  arruara: 'arruara',
  arruava: 'arruava',
  arrubes: 'arrubés',
  arrucai: 'arruçai',
  arrucam: 'arruçam',
  arrucar: 'arruçar',
  arrucas: 'arruças',
  arrucei: 'arrucei',
  arrucem: 'arrucem',
  arruces: 'arruces',
  arrucou: 'arruçou',
  arrudao: 'arrudão',
  arrudas: 'arrudas',
  arrudia: 'arrúdia',
  arrueis: 'arrueis',
  arruela: 'arruela',
  arruele: 'arruele',
  arruelo: 'arruelo',
  arruemo: 'arruemo',
  arrufai: 'arrufai',
  arrufam: 'arrufam',
  arrufar: 'arrufar',
  arrufas: 'arrufas',
  arrufei: 'arrufei',
  arrufem: 'arrufem',
  arrufes: 'arrufes',
  arrufia: 'arrufiá',
  arrufie: 'arrufie',
  arrufio: 'arrufio',
  arrufos: 'arrufos',
  arrufou: 'arrufou',
  arrugai: 'arrugai',
  arrugam: 'arrugam',
  arrugar: 'arrugar',
  arrugas: 'arrugas',
  arrugia: 'arrúgia',
  arrugou: 'arrugou',
  arrugue: 'arrugue',
  arruiam: 'arruíam',
  arruias: 'arruías',
  arruida: 'arruída',
  arruido: 'arruído',
  arruina: 'arruiná',
  arruine: 'arruíne',
  arruino: 'arruíno',
  arruira: 'arruíra',
  arruiva: 'arruivá',
  arruive: 'arruive',
  arruivo: 'arruivo',
  arrulai: 'arrulai',
  arrulam: 'arrulam',
  arrular: 'arrular',
  arrulas: 'arrulas',
  arrulei: 'arrulei',
  arrulem: 'arrulem',
  arrules: 'arrules',
  arrulha: 'arrulha',
  arrulhe: 'arrulhe',
  arrulho: 'arrulho',
  arrulou: 'arrulou',
  arrumai: 'arrumai',
  arrumam: 'arrumam',
  arrumar: 'arrumar',
  arrumas: 'arrumas',
  arrumei: 'arrumei',
  arrumem: 'arrumem',
  arrumes: 'arrumes',
  arrumou: 'arrumou',
  arrunha: 'arrunha',
  arrunhe: 'arrunhe',
  arrunho: 'arrunho',
  arrupia: 'arrupia',
  arrupie: 'arrupie',
  arrupio: 'arrupio',
  arsenal: 'arsenal',
  arsenio: 'arsênio',
  arsinas: 'arsinas',
  arsonio: 'arsônio',
  artabra: 'ártabra',
  artabro: 'ártabro',
  artamia: 'artâmia',
  artanto: 'artanto',
  arteira: 'arteira',
  arteiro: 'arteiro',
  artelho: 'artelho',
  artemao: 'artemão',
  artemia: 'artêmia',
  artemio: 'artêmio',
  artenas: 'artenas',
  arteria: 'artéria',
  arterio: 'artério',
  artesao: 'artesão',
  artesas: 'artesãs',
  artesoa: 'artesoa',
  artesoe: 'artesoe',
  artesoo: 'artesoo',
  articas: 'árticas',
  artices: 'artices',
  articos: 'árticos',
  artigar: 'artigar',
  artigos: 'artigos',
  artilha: 'artilha',
  artilhe: 'artilhe',
  artilho: 'artilho',
  artimao: 'artimão',
  artinha: 'artinha',
  artista: 'artista',
  artolai: 'artolai',
  artolam: 'artolam',
  artolar: 'artolar',
  artolas: 'artolas',
  artolei: 'artolei',
  artolem: 'artolem',
  artoles: 'artoles',
  artolou: 'artolou',
  artomel: 'artomel',
  artopta: 'artopta',
  artrite: 'artrite',
  artrose: 'artrose',
  aruacos: 'aruacos',
  aruagal: 'aruagal',
  aruanas: 'aruanás',
  aruanda: 'aruanda',
  aruande: 'aruandê',
  aruanos: 'aruanós',
  aruaque: 'aruaque',
  aruaqui: 'aruaqui',
  aruarus: 'aruarus',
  arubana: 'arubana',
  arubano: 'arubano',
  arubeba: 'arubeba',
  aruegas: 'aruegas',
  aruenda: 'aruenda',
  aruense: 'aruense',
  arumaca: 'arumaçá',
  arumara: 'arumará',
  arumaru: 'arumaru',
  aruncos: 'aruncos',
  arundas: 'arundas',
  arundos: 'arundos',
  arupema: 'arupema',
  arurana: 'arurana',
  aruroes: 'arurões',
  arvadas: 'arvadas',
  arvadeu: 'arvadeu',
  arvados: 'arvados',
  arvelas: 'arvelas',
  arveloa: 'arvéloa',
  arvense: 'arvense',
  arveola: 'arvéola',
  arverno: 'arverno',
  arvioes: 'arviões',
  arvoada: 'arvoada',
  arvoado: 'arvoado',
  arvoais: 'arvoais',
  arvoamo: 'arvoamo',
  arvoara: 'arvoara',
  arvoava: 'arvoava',
  arvoeis: 'arvoeis',
  arvoemo: 'arvoemo',
  arvorai: 'arvorai',
  arvoram: 'arvoram',
  arvorar: 'arvorar',
  arvoras: 'arvoras',
  arvorei: 'arvorei',
  arvorem: 'arvorem',
  arvores: 'árvores',
  arvorou: 'arvorou',
  arxadao: 'arxadão',
  arxadas: 'arxadas',
  arxador: 'arxador',
  arxados: 'arxados',
  arxamos: 'arxamos',
  arxando: 'arxando',
  arxante: 'arxante',
  arxaram: 'arxaram',
  arxarao: 'arxarão',
  arxaras: 'arxarás',
  arxarei: 'arxarei',
  arxarem: 'arxarem',
  arxares: 'arxares',
  arxaria: 'arxaria',
  arxarmo: 'arxarmo',
  arxasse: 'arxasse',
  arxaste: 'arxaste',
  arxavam: 'arxavam',
  arxavas: 'arxavas',
  arxemos: 'arxemos',
  arzalar: 'arzalar',
  arzilas: 'arzilas',
  arzinho: 'arzinho',
  arzolas: 'arzolas',
  asadoes: 'asadões',
  asadona: 'asadona',
  asadora: 'asadora',
  asafias: 'asafias',
  asafica: 'asáfica',
  asafico: 'asáfico',
  asagres: 'asagres',
  asantes: 'asantes',
  asaprol: 'asaprol',
  asaqueu: 'asaqueu',
  asarato: 'asarato',
  asarcas: 'asarcas',
  asarcia: 'asarcia',
  asardes: 'asardes',
  asareis: 'asáreis',
  asaremo: 'asaremo',
  asariam: 'asariam',
  asarias: 'asarias',
  asarica: 'asárica',
  asarico: 'asárico',
  asarina: 'asarina',
  asarita: 'asarita',
  asarmos: 'asarmos',
  asarona: 'asarona',
  asaroto: 'asaroto',
  asassem: 'asassem',
  asasses: 'asasses',
  asastes: 'asastes',
  asaveis: 'asáveis',
  asbesto: 'asbesto',
  asbista: 'asbista',
  ascarel: 'ascarel',
  ascarim: 'ascarim',
  ascaris: 'áscaris',
  ascaula: 'ascaula',
  ascaule: 'ascaule',
  asceina: 'asceína',
  ascelas: 'áscelas',
  asceles: 'ásceles',
  ascelia: 'ascelia',
  ascelos: 'áscelos',
  ascenda: 'ascenda',
  ascende: 'ascende',
  ascendi: 'ascendi',
  ascendo: 'ascendo',
  ascenso: 'ascenso',
  asceses: 'asceses',
  ascetas: 'ascetas',
  ascidia: 'ascídia',
  ascidio: 'ascídio',
  asciros: 'ásciros',
  ascitas: 'ascitas',
  ascites: 'ascites',
  asclero: 'asclero',
  ascomai: 'ascomai',
  ascomam: 'ascomam',
  ascomar: 'ascomar',
  ascomas: 'ascomas',
  ascomei: 'ascomei',
  ascomem: 'ascomem',
  ascomes: 'ascomes',
  ascomou: 'ascomou',
  ascosas: 'ascosas',
  ascoses: 'ascoses',
  ascosos: 'ascosos',
  ascreia: 'ascreia',
  ascreus: 'ascreus',
  ascuada: 'ascuada',
  ascuado: 'ascuado',
  ascuais: 'ascuais',
  ascuamo: 'ascuamo',
  ascuara: 'ascuará',
  ascuava: 'ascuava',
  ascueis: 'ascueis',
  ascuemo: 'ascuemo',
  ascumas: 'ascumas',
  ascunas: 'ascunas',
  ascunha: 'ascunha',
  asdingo: 'asdingo',
  asdodeu: 'asdodeu',
  aselhas: 'aselhas',
  aselido: 'asélido',
  aselina: 'aselina',
  asepsia: 'asepsia',
  asexual: 'asexual',
  asfalta: 'asfalta',
  asfalte: 'asfalte',
  asfalto: 'asfalto',
  asfixia: 'asfixia',
  asfixie: 'asfixie',
  asfixio: 'asfixio',
  asiamos: 'asíamos',
  asianas: 'asianas',
  asianos: 'asianos',
  asiarca: 'asiarca',
  asiatas: 'asiatas',
  asidoes: 'asidões',
  asidona: 'asidona',
  asidora: 'asidora',
  asilada: 'asilada',
  asilado: 'asilado',
  asilais: 'asilais',
  asilamo: 'asilamo',
  asilara: 'asilara',
  asilava: 'asilava',
  asileis: 'asileis',
  asilemo: 'asilemo',
  asimina: 'asimina',
  asinais: 'asinais',
  asineus: 'asineus',
  asinhas: 'asinhas',
  asinina: 'asinina',
  asinino: 'asinino',
  asirdes: 'asirdes',
  asireis: 'asireis',
  asiremo: 'asiremo',
  asiriam: 'asiriam',
  asirias: 'asirias',
  asirmos: 'asirmos',
  asissem: 'asissem',
  asisses: 'asisses',
  asistes: 'asistes',
  asmento: 'asmento',
  asmodeu: 'asmodeu',
  asmoneu: 'asmoneu',
  asnadao: 'asnadão',
  asnadas: 'asnadas',
  asnador: 'asnador',
  asnados: 'asnados',
  asnamos: 'asnamos',
  asnando: 'asnando',
  asnante: 'asnante',
  asnaram: 'asnaram',
  asnarao: 'asnarão',
  asnaras: 'asnarás',
  asnarei: 'asnarei',
  asnarem: 'asnarem',
  asnares: 'asnares',
  asnaria: 'asnaria',
  asnarmo: 'asnarmo',
  asnasse: 'asnasse',
  asnaste: 'asnaste',
  asnavam: 'asnavam',
  asnavas: 'asnavas',
  asneada: 'asneada',
  asneado: 'asneado',
  asneais: 'asneais',
  asneara: 'asneará',
  asneava: 'asneava',
  asneeis: 'asneeis',
  asneiam: 'asneiam',
  asneias: 'asneias',
  asneiem: 'asneiem',
  asneies: 'asneies',
  asneira: 'asneira',
  asneire: 'asneire',
  asneiro: 'asneiro',
  asnemos: 'asnemos',
  asnices: 'asnices',
  asnilho: 'asnilho',
  asofias: 'asofias',
  aspacos: 'aspaços',
  aspadao: 'aspadão',
  aspadas: 'aspadas',
  aspador: 'aspador',
  aspados: 'aspados',
  aspamos: 'aspamos',
  aspando: 'aspando',
  aspante: 'aspante',
  asparam: 'asparam',
  asparao: 'asparão',
  asparas: 'asparas',
  asparei: 'asparei',
  asparem: 'asparem',
  aspares: 'aspares',
  aspargo: 'aspargo',
  asparia: 'asparia',
  asparmo: 'asparmo',
  asparte: 'asparte',
  asparto: 'asparto',
  aspasia: 'aspásia',
  aspasse: 'aspasse',
  aspaste: 'aspaste',
  aspavam: 'aspavam',
  aspavas: 'aspavas',
  aspeada: 'aspeada',
  aspeado: 'aspeado',
  aspeais: 'aspeais',
  aspeara: 'aspeará',
  aspeava: 'aspeava',
  aspecto: 'aspecto',
  aspeeis: 'aspeeis',
  aspegia: 'aspegia',
  aspeiam: 'aspeiam',
  aspeias: 'aspeias',
  aspeiem: 'aspeiem',
  aspeies: 'aspeies',
  aspeito: 'aspeito',
  aspemos: 'aspemos',
  asperas: 'ásperas',
  asperge: 'asperge',
  aspergi: 'aspergi',
  asperja: 'asperja',
  asperjo: 'asperjo',
  aspermo: 'aspermo',
  asperos: 'ásperos',
  aspersa: 'aspersa',
  asperse: 'asperse',
  asperso: 'asperso',
  aspetos: 'aspetos',
  aspides: 'áspides',
  aspidia: 'aspídia',
  aspidio: 'aspídio',
  aspilia: 'aspília',
  aspirai: 'aspirai',
  aspiral: 'aspiral',
  aspiram: 'aspiram',
  aspirar: 'aspirar',
  aspiras: 'aspiras',
  aspirei: 'aspirei',
  aspirem: 'aspirem',
  aspires: 'aspires',
  aspirja: 'aspirja',
  aspirjo: 'aspirjo',
  aspirou: 'aspirou',
  asporas: 'ásporas',
  asporos: 'ásporos',
  aspredo: 'aspredo',
  aspreja: 'aspreja',
  aspreje: 'aspreje',
  asprejo: 'asprejo',
  aspudas: 'aspudas',
  aspudos: 'aspudos',
  asqueai: 'asqueai',
  asquear: 'asquear',
  asqueei: 'asqueei',
  asqueia: 'asqueia',
  asqueie: 'asqueie',
  asqueio: 'asqueio',
  asqueou: 'asqueou',
  asquino: 'asquino',
  asquizo: 'asquizo',
  assabao: 'assabão',
  assacai: 'assacai',
  assacam: 'assacam',
  assacao: 'assação',
  assacar: 'assacar',
  assacas: 'assacas',
  assacio: 'assácio',
  assacou: 'assacou',
  assadao: 'assadão',
  assadas: 'assadas',
  assador: 'assador',
  assados: 'assados',
  assafio: 'assafio',
  assalta: 'assalta',
  assalte: 'assalte',
  assalto: 'assalto',
  assamar: 'assamar',
  assames: 'assamês',
  assamis: 'assamis',
  assamos: 'assamos',
  assanas: 'ássanas',
  assando: 'assando',
  assanha: 'assanha',
  assanhe: 'assanhe',
  assanho: 'assanho',
  assante: 'assante',
  assapai: 'assapai',
  assapam: 'assapam',
  assapar: 'assapar',
  assapas: 'assapas',
  assapei: 'assapei',
  assapem: 'assapem',
  assapes: 'assapes',
  assapou: 'assapou',
  assaque: 'assaque',
  assaram: 'assaram',
  assarao: 'assarão',
  assaras: 'assaras',
  assarei: 'assarei',
  assarem: 'assarem',
  assares: 'assares',
  assaria: 'assaria',
  assario: 'assario',
  assarmo: 'assarmo',
  assasse: 'assasse',
  assassi: 'assassi',
  assaste: 'assaste',
  assavam: 'assavam',
  assavas: 'assavas',
  assaves: 'assaves',
  assazes: 'assazes',
  assazoa: 'assazoá',
  assazoe: 'assazoe',
  assazoo: 'assazoo',
  asseada: 'asseada',
  asseado: 'asseado',
  asseais: 'asseais',
  asseamo: 'asseamo',
  asseara: 'asseara',
  asseava: 'asseava',
  assecio: 'assécio',
  assecla: 'assecla',
  assecoa: 'asseçoá',
  assecoe: 'asseçoe',
  assecoo: 'asseçoo',
  assedai: 'assedai',
  assedam: 'assedam',
  assedar: 'assedar',
  assedas: 'assedas',
  assedei: 'assedei',
  assedem: 'assedem',
  assedes: 'assedes',
  assedia: 'assedia',
  assedie: 'assedie',
  assedio: 'assédio',
  assedou: 'assedou',
  asseeis: 'asseeis',
  asseemo: 'asseemo',
  asseiam: 'asseiam',
  asseias: 'asseias',
  asseiem: 'asseiem',
  asseies: 'asseies',
  asseios: 'asseios',
  asseita: 'asseita',
  asseite: 'asseite',
  asseito: 'asseito',
  asselai: 'asselai',
  asselam: 'asselam',
  asselar: 'asselar',
  asselas: 'asselas',
  asselei: 'asselei',
  asselem: 'asselem',
  asseles: 'asseles',
  asselou: 'asselou',
  assemia: 'assemia',
  assemio: 'assêmio',
  assemos: 'assemos',
  assenso: 'assenso',
  assenta: 'assenta',
  assente: 'assente',
  assenti: 'assenti',
  assento: 'assento',
  asserem: 'asserem',
  asseres: 'asseres',
  asseria: 'asseria',
  asserio: 'asserio',
  asserir: 'asserir',
  asseris: 'asseris',
  asseriu: 'asseriu',
  asserta: 'asserta',
  asserte: 'asserte',
  asserto: 'asserto',
  assesta: 'assesta',
  asseste: 'asseste',
  assesto: 'assesto',
  assetai: 'assetai',
  assetam: 'assetam',
  assetar: 'assetar',
  assetas: 'assetas',
  assetea: 'asseteá',
  assetei: 'assetei',
  assetem: 'assetem',
  assetes: 'assetes',
  assetou: 'assetou',
  assexos: 'assexos',
  assezoa: 'assezoa',
  assezoe: 'assezoe',
  assezoo: 'assezoo',
  assideu: 'assideu',
  assidio: 'assídio',
  assidra: 'assidra',
  assidre: 'assidre',
  assidro: 'assidro',
  assidua: 'assídua',
  assiduo: 'assíduo',
  assigmo: 'assigmo',
  assilha: 'assilhá',
  assilhe: 'assilhe',
  assilho: 'assilho',
  assinai: 'assinai',
  assinam: 'assinam',
  assinar: 'assinar',
  assinas: 'assinas',
  assinei: 'assinei',
  assinem: 'assinem',
  assines: 'assines',
  assinou: 'assinou',
  assinta: 'assinta',
  assinto: 'assinto',
  assiram: 'assiram',
  assiras: 'assiras',
  assiria: 'assíria',
  assirio: 'assírio',
  assisai: 'assisai',
  assisam: 'assisam',
  assisar: 'assisar',
  assisas: 'assisas',
  assisei: 'assisei',
  assisem: 'assisem',
  assises: 'assises',
  assisio: 'assísio',
  assisou: 'assisou',
  assista: 'assista',
  assiste: 'assiste',
  assisti: 'assisti',
  assisto: 'assisto',
  assitia: 'assitia',
  assoada: 'assoada',
  assoado: 'assoado',
  assoais: 'assoais',
  assoamo: 'assoamo',
  assoara: 'assoara',
  assoava: 'assoava',
  assobem: 'assobem',
  assobes: 'assobes',
  assobia: 'assobia',
  assobie: 'assobie',
  assobio: 'assobio',
  assocai: 'assocai',
  assocam: 'assocam',
  assocar: 'assocar',
  assocas: 'assocas',
  associa: 'associa',
  associe: 'associe',
  associo: 'associo',
  assocou: 'assocou',
  assoeis: 'assoeis',
  assoemo: 'assoemo',
  assolai: 'assolai',
  assolam: 'assolam',
  assolar: 'assolar',
  assolas: 'assolas',
  assolda: 'assolda',
  assolde: 'assolde',
  assoldo: 'assoldo',
  assolea: 'assoleá',
  assolei: 'assolei',
  assolem: 'assolem',
  assoles: 'assoles',
  assolha: 'assolhá',
  assolhe: 'assolhe',
  assolho: 'assolho',
  assolou: 'assolou',
  assolto: 'assolto',
  assomai: 'assomai',
  assomam: 'assomam',
  assomar: 'assomar',
  assomas: 'assomas',
  assomei: 'assomei',
  assomem: 'assomem',
  assomes: 'assomes',
  assomos: 'assomos',
  assomou: 'assomou',
  assonai: 'assonai',
  assonam: 'assonam',
  assonar: 'assonar',
  assonas: 'assonas',
  assonei: 'assonei',
  assonem: 'assonem',
  assones: 'assones',
  assonia: 'assonia',
  assonjo: 'assonjo',
  assonos: 'ássonos',
  assonou: 'assonou',
  assonsa: 'assonsa',
  assonse: 'assonse',
  assonso: 'assonso',
  assopea: 'assopeá',
  assopra: 'assopra',
  assopre: 'assopre',
  assopro: 'assopro',
  assoque: 'assoque',
  assorea: 'assoreá',
  assotai: 'assotai',
  assotam: 'assotam',
  assotar: 'assotar',
  assotas: 'assotas',
  assotei: 'assotei',
  assotem: 'assotem',
  assotes: 'assotes',
  assotou: 'assotou',
  assovia: 'assovia',
  assovie: 'assovie',
  assovio: 'assovio',
  assuada: 'assuada',
  assuado: 'assuado',
  assuais: 'assuais',
  assuamo: 'assuamo',
  assuara: 'assuará',
  assuava: 'assuava',
  assubam: 'assubam',
  assubas: 'assubas',
  assubia: 'assubia',
  assubir: 'assubir',
  assubis: 'assubis',
  assubiu: 'assubiu',
  assucai: 'assucai',
  assucam: 'assucam',
  assucar: 'assucar',
  assucas: 'assucas',
  assucou: 'assucou',
  assueis: 'assueis',
  assuemo: 'assuemo',
  assumam: 'assumam',
  assumas: 'assumas',
  assumem: 'assumem',
  assumes: 'assumes',
  assumia: 'assumia',
  assumir: 'assumir',
  assumis: 'assumis',
  assumiu: 'assumiu',
  assunai: 'assunai',
  assunam: 'assunam',
  assunar: 'assunar',
  assunas: 'assunas',
  assunei: 'assunei',
  assunem: 'assunem',
  assunes: 'assunes',
  assunga: 'assunga',
  assungo: 'assungo',
  assunou: 'assunou',
  assunta: 'assunta',
  assunte: 'assunte',
  assunto: 'assunto',
  assuque: 'assuque',
  assurge: 'assurge',
  assurgi: 'assurgi',
  assurja: 'assurja',
  assurjo: 'assurjo',
  assusta: 'assusta',
  assuste: 'assuste',
  assusto: 'assusto',
  assuxar: 'assuxar',
  astacos: 'ástacos',
  astarte: 'astarte',
  astases: 'ástases',
  astasia: 'astasia',
  astatas: 'ástatas',
  astates: 'ástates',
  astatos: 'ástatos',
  astecas: 'astecas',
  astelas: 'astelas',
  astelia: 'astélia',
  astelma: 'astelma',
  astemas: 'astemas',
  astenia: 'astenia',
  astense: 'astense',
  asteque: 'asteque',
  asteral: 'asteral',
  asteres: 'ásteres',
  asteria: 'astéria',
  asterio: 'astério',
  asterol: 'asterol',
  astiana: 'astiana',
  astiano: 'astiano',
  asticas: 'ásticas',
  asticos: 'ásticos',
  astilbe: 'astilbe',
  astilha: 'astilha',
  astilhe: 'astilhe',
  astilho: 'astilho',
  astilos: 'astilos',
  astiria: 'astíria',
  astisia: 'astisia',
  astismo: 'astismo',
  astista: 'astista',
  astomas: 'ástomas',
  astomia: 'astomia',
  astomos: 'ástomos',
  astraca: 'astracã',
  astrais: 'astrais',
  astrapa: 'ástrapa',
  astrapo: 'ástrapo',
  astreas: 'ástreas',
  astrego: 'astrego',
  astreia: 'astreia',
  astreos: 'ástreos',
  astreva: 'astreva',
  astreve: 'astreve',
  astrevi: 'astrevi',
  astrevo: 'astrevo',
  astrica: 'ástrica',
  astrico: 'ástrico',
  astrola: 'astrola',
  astrosa: 'astrosa',
  astroso: 'astroso',
  astucia: 'astúcia',
  astucie: 'astucie',
  astucio: 'astucio',
  astures: 'ástures',
  astutas: 'astutas',
  astutos: 'astutos',
  asulana: 'asulana',
  asulano: 'asulano',
  asurois: 'asuróis',
  asvanda: 'asvandá',
  asvande: 'asvande',
  asvando: 'asvando',
  atabafa: 'atabafa',
  atabafe: 'atabafe',
  atabafo: 'atabafo',
  atabais: 'atabais',
  atabala: 'atabala',
  atabale: 'atabale',
  atabalo: 'atabalo',
  atabefe: 'atabefe',
  ataboes: 'atabões',
  atabona: 'atabona',
  atabuai: 'atabuai',
  atabuam: 'atabuam',
  atabuar: 'atabuar',
  atabuas: 'atabuas',
  atabuca: 'atabucá',
  atabuco: 'atabuco',
  atabuei: 'atabuei',
  atabuem: 'atabuem',
  atabues: 'atabues',
  atabula: 'atabula',
  atabule: 'atabule',
  atabulo: 'atábulo',
  atabuou: 'atabuou',
  atacada: 'atacada',
  atacado: 'atacado',
  atacais: 'atacais',
  atacamo: 'atacamo',
  atacara: 'atacara',
  atacava: 'atacava',
  atachar: 'atachar',
  atacino: 'atacino',
  atacoai: 'atacoai',
  atacoam: 'atacoam',
  atacoar: 'atacoar',
  atacoas: 'atacoas',
  atacoei: 'atacoei',
  atacoem: 'atacoem',
  atacoes: 'atacoes',
  atacona: 'ataçona',
  atacoou: 'atacoou',
  atadoes: 'atadões',
  atadona: 'atadona',
  atadora: 'atadora',
  atadura: 'atadura',
  atafais: 'atafais',
  atafega: 'atafegá',
  atafego: 'atafego',
  atafera: 'atafera',
  atafina: 'atafina',
  atafona: 'atafona',
  atafula: 'atafulá',
  atafule: 'atafule',
  atafulo: 'atafulo',
  atalaia: 'atalaia',
  atalaie: 'atalaie',
  atalaio: 'atalaio',
  atalamo: 'atálamo',
  ataleas: 'atáleas',
  ataleia: 'ataleia',
  atalhai: 'atalhai',
  atalham: 'atalham',
  atalhar: 'atalhar',
  atalhas: 'atalhas',
  atalhei: 'atalhei',
  atalhem: 'atalhem',
  atalhes: 'atalhes',
  atalhos: 'atalhos',
  atalhou: 'atalhou',
  atalica: 'atálica',
  atalico: 'atálico',
  atamada: 'atamada',
  atamado: 'atamado',
  atamais: 'atamais',
  atamamo: 'atamamo',
  atamana: 'atamana',
  atamane: 'atamane',
  atamano: 'atamano',
  atamara: 'atamará',
  atamava: 'atamava',
  atambia: 'atambia',
  atambor: 'atambor',
  atameis: 'atameis',
  atamemo: 'atamemo',
  atamica: 'atamiça',
  atanada: 'atanada',
  atanado: 'atanado',
  atanais: 'atanais',
  atanamo: 'atanamo',
  atanara: 'atanara',
  atanase: 'atânase',
  atanava: 'atanava',
  atanaza: 'atanaza',
  atanaze: 'atanaze',
  atanazo: 'atanazo',
  atancha: 'atanchá',
  atanche: 'atanche',
  atancho: 'atancho',
  atandas: 'atandas',
  ataneis: 'ataneis',
  atanemo: 'atanemo',
  atangei: 'atangei',
  atangem: 'atangem',
  atanger: 'atanger',
  atanges: 'atanges',
  atangeu: 'atangeu',
  atangia: 'atangia',
  atanjam: 'atanjam',
  atanjas: 'atanjas',
  atanoai: 'atanoai',
  atanoam: 'atanoam',
  atanoar: 'atanoar',
  atanoas: 'atanoas',
  atanoei: 'atanoei',
  atanoem: 'atanoem',
  atanoes: 'atanoes',
  atanoou: 'atanoou',
  atantes: 'atantes',
  atapala: 'atapala',
  atapata: 'atapata',
  atapato: 'atapato',
  atapera: 'atapera',
  atapere: 'atapere',
  atapero: 'atapero',
  atapeta: 'atapeta',
  atapete: 'atapete',
  atapeto: 'atapeto',
  ataquei: 'ataquei',
  ataquem: 'ataquem',
  ataques: 'ataques',
  ataraus: 'ataraús',
  atardai: 'atardai',
  atardam: 'atardam',
  atardar: 'atardar',
  atardas: 'atardas',
  atardei: 'atardei',
  atardem: 'atardem',
  atardes: 'atardes',
  atardou: 'atardou',
  atareai: 'atareai',
  atarear: 'atarear',
  atareei: 'atareei',
  atarefa: 'atarefa',
  atarefe: 'atarefe',
  atarefo: 'atarefo',
  atareia: 'atareia',
  atareie: 'atareie',
  atareio: 'atareio',
  atareis: 'atareis',
  ataremo: 'ataremo',
  atareou: 'atareou',
  atariam: 'atariam',
  atarias: 'atarias',
  atarmos: 'atarmos',
  ataruga: 'atarugá',
  atarugo: 'atarugo',
  atascai: 'atascai',
  atascal: 'atascal',
  atascam: 'atascam',
  atascar: 'atascar',
  atascas: 'atascas',
  atascou: 'atascou',
  atasque: 'atasque',
  atassem: 'atassem',
  atasses: 'atasses',
  atassim: 'atassim',
  atastes: 'atastes',
  atatica: 'atática',
  atatico: 'atático',
  atatino: 'atatino',
  ataudai: 'ataudai',
  ataudam: 'ataudam',
  ataudar: 'ataudar',
  ataudas: 'ataudas',
  ataudei: 'ataudei',
  ataudem: 'ataudem',
  ataudes: 'ataúdes',
  ataudou: 'ataudou',
  atauxia: 'atauxia',
  atauxie: 'atauxie',
  atauxio: 'atauxio',
  ataveis: 'atáveis',
  ataviai: 'ataviai',
  ataviam: 'ataviam',
  ataviar: 'ataviar',
  atavias: 'atavias',
  atavica: 'atávica',
  atavico: 'atávico',
  ataviei: 'ataviei',
  ataviem: 'ataviem',
  atavies: 'atavies',
  atavios: 'atavios',
  ataviou: 'ataviou',
  ataviza: 'atavizá',
  atavize: 'atavize',
  atavizo: 'atavizo',
  atavoes: 'atavões',
  atavola: 'atavolá',
  atavole: 'atavole',
  atavolo: 'atavolo',
  ataxias: 'ataxias',
  ataxica: 'atáxica',
  ataxico: 'atáxico',
  ataxita: 'ataxita',
  ataxite: 'ataxite',
  ataxito: 'ataxito',
  atazana: 'atazana',
  atazane: 'atazane',
  atazano: 'atazano',
  ateadao: 'ateadão',
  ateadas: 'ateadas',
  ateador: 'ateador',
  ateados: 'ateados',
  ateamos: 'ateamos',
  ateando: 'ateando',
  ateante: 'ateante',
  atearam: 'atearam',
  atearao: 'atearão',
  atearas: 'atearas',
  atearei: 'atearei',
  atearem: 'atearem',
  ateares: 'ateares',
  atearia: 'atearia',
  atearmo: 'atearmo',
  ateasse: 'ateasse',
  ateaste: 'ateaste',
  ateavam: 'ateavam',
  ateavas: 'ateavas',
  atecada: 'atecada',
  atecado: 'atecado',
  atecnia: 'atecnia',
  atecuri: 'atecuri',
  atediai: 'atediai',
  atediam: 'atediam',
  atediar: 'atediar',
  atedias: 'atedias',
  atediei: 'atediei',
  atediem: 'atediem',
  atedies: 'atedies',
  atediou: 'atediou',
  atedora: 'atedora',
  ateemos: 'ateemos',
  ateigai: 'ateigai',
  ateigam: 'ateigam',
  ateigar: 'ateigar',
  ateigas: 'ateigas',
  ateigou: 'ateigou',
  ateimai: 'ateimai',
  ateimam: 'ateimam',
  ateimar: 'ateimar',
  ateimas: 'ateimas',
  ateimei: 'ateimei',
  ateimem: 'ateimem',
  ateimes: 'ateimes',
  ateimou: 'ateimou',
  ateiras: 'ateiras',
  ateiros: 'ateirós',
  ateismo: 'ateísmo',
  ateista: 'ateísta',
  ateizai: 'ateizai',
  ateizam: 'ateízam',
  ateizar: 'ateizar',
  ateizas: 'ateízas',
  ateizei: 'ateizei',
  ateizem: 'ateízem',
  ateizes: 'ateízes',
  ateizou: 'ateizou',
  atelabo: 'atélabo',
  atelana: 'atelana',
  atelano: 'atelano',
  ateleia: 'ateleia',
  atelhai: 'atelhai',
  atelham: 'atelham',
  atelhar: 'atelhar',
  atelhas: 'atelhas',
  atelhei: 'atelhei',
  atelhem: 'atelhem',
  atelhes: 'atelhes',
  atelhou: 'atelhou',
  ateliam: 'ateliam',
  atelias: 'atelias',
  atelica: 'atélica',
  atelico: 'atélico',
  ateliei: 'ateliei',
  ateliem: 'ateliem',
  atelier: 'atelier',
  atelies: 'ateliês',
  atelieu: 'atelieu',
  ateliia: 'ateliia',
  atelila: 'atelila',
  atelile: 'atelilê',
  atelili: 'atelili',
  atelilo: 'atelilo',
  atelina: 'atelina',
  atelita: 'atelita',
  atelogo: 'atélogo',
  atemoia: 'atemoia',
  atemora: 'atemora',
  atemore: 'atemore',
  atemoro: 'atemoro',
  atempai: 'atempai',
  atempam: 'atempam',
  atempar: 'atempar',
  atempas: 'atempas',
  atempei: 'atempei',
  atempem: 'atempem',
  atempes: 'atempes',
  atempou: 'atempou',
  atenaue: 'atenaué',
  atenaza: 'atenaza',
  atenaze: 'atenaze',
  atenazo: 'atenazo',
  atencao: 'atenção',
  atencas: 'atenças',
  atendai: 'atendai',
  atendam: 'atendam',
  atendar: 'atendar',
  atendas: 'atendas',
  atendei: 'atendei',
  atendem: 'atendem',
  atender: 'atender',
  atendes: 'atendes',
  atendeu: 'atendeu',
  atendia: 'atendia',
  atendou: 'atendou',
  ateneia: 'ateneia',
  ateneus: 'ateneus',
  atenham: 'atenham',
  atenhas: 'atenhas',
  atenora: 'atenora',
  atenore: 'atenore',
  atenoro: 'atenoro',
  atenrai: 'atenrai',
  atenram: 'atenram',
  atenrar: 'atenrar',
  atenras: 'atenras',
  atenrei: 'atenrei',
  atenrem: 'atenrem',
  atenres: 'atenres',
  atenrou: 'atenrou',
  atentai: 'atentai',
  atentam: 'atentam',
  atentar: 'atentar',
  atentas: 'atentas',
  atentei: 'atentei',
  atentem: 'atentem',
  atentes: 'atentes',
  atentos: 'atentos',
  atentou: 'atentou',
  atenuai: 'atenuai',
  atenuam: 'atenuam',
  atenuar: 'atenuar',
  atenuas: 'atenuas',
  atenuei: 'atenuei',
  atenuem: 'atenuem',
  atenues: 'atenues',
  atenuou: 'atenuou',
  aterdes: 'aterdes',
  atereca: 'atereça',
  aterece: 'aterece',
  atereci: 'atereci',
  atereco: 'atereço',
  atereis: 'atereis',
  ateremo: 'ateremo',
  atereua: 'ateréua',
  ateriam: 'ateriam',
  aterias: 'aterias',
  ateriba: 'ateribá',
  aterica: 'atérica',
  aterina: 'aterina',
  aterino: 'aterino',
  aterios: 'atérios',
  atermai: 'atermai',
  atermal: 'atermal',
  atermam: 'atermam',
  atermar: 'atermar',
  atermas: 'atermas',
  atermei: 'atermei',
  atermem: 'atermem',
  atermes: 'atermes',
  atermia: 'atermia',
  atermos: 'atermos',
  atermou: 'atermou',
  ateroma: 'ateroma',
  aterrai: 'aterrai',
  aterram: 'aterram',
  aterrar: 'aterrar',
  aterras: 'aterras',
  aterrei: 'aterrei',
  aterrem: 'aterrem',
  aterres: 'aterres',
  aterroa: 'aterroá',
  aterroe: 'aterroe',
  aterroo: 'aterroo',
  aterros: 'aterros',
  aterrou: 'aterrou',
  ateruro: 'ateruro',
  atesada: 'atesada',
  atesado: 'atesado',
  atesais: 'atesais',
  atesamo: 'atesamo',
  atesara: 'atesará',
  atesava: 'atesava',
  ateseis: 'ateseis',
  atesemo: 'atesemo',
  atesias: 'atesias',
  atestai: 'atestai',
  atestam: 'atestam',
  atestar: 'atestar',
  atestas: 'atestas',
  atestea: 'atesteá',
  atestei: 'atestei',
  atestem: 'atestem',
  atestes: 'atestes',
  atestou: 'atestou',
  atetese: 'atetese',
  atetose: 'atetose',
  atextar: 'atextar',
  atezana: 'atezaná',
  atezane: 'atezane',
  atezano: 'atezano',
  atiadeu: 'atiadeu',
  atialia: 'atialia',
  atiatis: 'atiatis',
  atibacu: 'atibaçu',
  atibada: 'atibada',
  atibado: 'atibado',
  atibais: 'atibais',
  atibamo: 'atibamo',
  atibara: 'atibará',
  atibava: 'atibava',
  atibeis: 'atibeis',
  atibemo: 'atibemo',
  atibiai: 'atibiai',
  atibiam: 'atibiam',
  atibiar: 'atibiar',
  atibias: 'atibias',
  atibiei: 'atibiei',
  atibiem: 'atibiem',
  atibies: 'atibies',
  atibiou: 'atibiou',
  aticada: 'atiçada',
  aticado: 'atiçado',
  aticais: 'atiçais',
  aticamo: 'atiçamo',
  aticara: 'atiçará',
  aticava: 'atiçava',
  aticeis: 'aticeis',
  aticemo: 'aticemo',
  aticite: 'aticite',
  aticiza: 'aticizá',
  aticize: 'aticize',
  aticizo: 'aticizo',
  aticoai: 'atiçoai',
  aticoam: 'atiçoam',
  aticoar: 'atiçoar',
  aticoas: 'atiçoas',
  aticoei: 'atiçoei',
  aticoem: 'atiçoem',
  aticoes: 'atiçoes',
  aticoou: 'atiçoou',
  aticuns: 'aticuns',
  atidoes: 'atidões',
  atidona: 'atidona',
  atigrai: 'atigrai',
  atigram: 'atigram',
  atigrar: 'atigrar',
  atigras: 'atigras',
  atigrei: 'atigrei',
  atigrem: 'atigrem',
  atigres: 'atigres',
  atigrou: 'atigrou',
  atiideo: 'atiídeo',
  atijola: 'atijola',
  atijole: 'atijole',
  atijolo: 'atijolo',
  atilada: 'atilada',
  atilado: 'atilado',
  atilais: 'atilais',
  atilamo: 'atilamo',
  atilara: 'atilará',
  atilava: 'atilava',
  atileis: 'atileis',
  atilemo: 'atilemo',
  atilhai: 'atilhai',
  atilham: 'atilham',
  atilhar: 'atilhar',
  atilhas: 'atilhas',
  atilhei: 'atilhei',
  atilhem: 'atilhem',
  atilhes: 'atilhes',
  atilhos: 'atilhos',
  atilhou: 'atilhou',
  atimias: 'atimias',
  atimica: 'atímica',
  atimico: 'atímico',
  atinada: 'atinada',
  atinado: 'atinado',
  atinais: 'atinais',
  atinamo: 'atinamo',
  atinara: 'atinara',
  atinate: 'atinate',
  atinava: 'atinava',
  atincal: 'atincal',
  atineis: 'atineis',
  atinemo: 'atinemo',
  atingas: 'atingas',
  atingau: 'atingaú',
  atingem: 'atingem',
  atinges: 'atinges',
  atingia: 'atingia',
  atingir: 'atingir',
  atingis: 'atingis',
  atingiu: 'atingiu',
  atinham: 'atinham',
  atinhas: 'atinhas',
  atinias: 'atínias',
  atinjam: 'atinjam',
  atinjas: 'atinjas',
  atintai: 'atintai',
  atintam: 'atintam',
  atintar: 'atintar',
  atintas: 'atintas',
  atintei: 'atintei',
  atintem: 'atintem',
  atintes: 'atintes',
  atintou: 'atintou',
  atipias: 'atipias',
  atipica: 'atípica',
  atipico: 'atípico',
  atipida: 'atípida',
  atiplai: 'atiplai',
  atiplam: 'atiplam',
  atiplar: 'atiplar',
  atiplas: 'atiplas',
  atiplei: 'atiplei',
  atiplem: 'atiplem',
  atiples: 'atiples',
  atiplou: 'atiplou',
  atiquei: 'atiquei',
  atiquem: 'atiquem',
  atiques: 'atiques',
  atiquio: 'atíquio',
  atirada: 'atirada',
  atirado: 'atirado',
  atirais: 'atirais',
  atiramo: 'atiramo',
  atirara: 'atirara',
  atirava: 'atirava',
  atireis: 'atireis',
  atiremo: 'atiremo',
  atirias: 'atirias',
  atiriba: 'atiriba',
  atitada: 'atitada',
  atitado: 'atitado',
  atitais: 'atitais',
  atitamo: 'atitamo',
  atitara: 'atitara',
  atitava: 'atitava',
  atiteis: 'atiteis',
  atitemo: 'atitemo',
  atitude: 'atitude',
  atitula: 'atitulá',
  atitule: 'atitule',
  atitulo: 'atitulo',
  atiuacu: 'atiuaçu',
  ativada: 'ativada',
  ativado: 'ativado',
  ativais: 'ativais',
  ativamo: 'ativamo',
  ativara: 'ativara',
  ativava: 'ativava',
  ativeis: 'ativeis',
  ativemo: 'ativemo',
  ativera: 'ativera',
  atlanta: 'atlanta',
  atlante: 'atlante',
  atlanto: 'atlanto',
  atletas: 'atletas',
  atloide: 'atloide',
  atmicas: 'átmicas',
  atmicos: 'átmicos',
  atoadao: 'atoadão',
  atoadas: 'atoadas',
  atoador: 'atoador',
  atoados: 'atoados',
  atoagem: 'atoagem',
  atoalha: 'atoalha',
  atoalhe: 'atoalhe',
  atoalho: 'atoalho',
  atoamos: 'atoamos',
  atoando: 'atoando',
  atoante: 'atoante',
  atoaram: 'atoaram',
  atoarao: 'atoarão',
  atoaras: 'atoarás',
  atoarda: 'atoarda',
  atoarei: 'atoarei',
  atoarem: 'atoarem',
  atoares: 'atoares',
  atoaria: 'atoaria',
  atoarmo: 'atoarmo',
  atoasse: 'atoasse',
  atoaste: 'atoaste',
  atoavam: 'atoavam',
  atoavas: 'atoavas',
  atocaia: 'atocaia',
  atocaie: 'atocaie',
  atocaio: 'atocaio',
  atochai: 'atochai',
  atocham: 'atocham',
  atochar: 'atochar',
  atochas: 'atochas',
  atochei: 'atochei',
  atochem: 'atochem',
  atoches: 'atoches',
  atochos: 'atochos',
  atochou: 'atochou',
  atocias: 'atocias',
  atocico: 'atócico',
  atocios: 'atócios',
  atoemos: 'atoemos',
  atoicai: 'atoiçai',
  atoicam: 'atoiçam',
  atoicar: 'atoiçar',
  atoicas: 'atoiças',
  atoicei: 'atoicei',
  atoicem: 'atoicem',
  atoices: 'atoices',
  atoicou: 'atoiçou',
  atoidea: 'atoídea',
  atoideo: 'atoídeo',
  atolada: 'atolada',
  atolado: 'atolado',
  atolais: 'atolais',
  atolamo: 'atolamo',
  atolara: 'atolara',
  atolava: 'atolava',
  atoldoa: 'atoldoá',
  atoldoe: 'atoldoe',
  atoldoo: 'atoldoo',
  atoleco: 'atoleco',
  atoledo: 'atoledo',
  atoleis: 'atoleis',
  atolemo: 'atolemo',
  atolida: 'atolida',
  atomata: 'atomata',
  atomate: 'atomate',
  atomato: 'atomato',
  atombai: 'atombai',
  atombam: 'atombam',
  atombar: 'atombar',
  atombas: 'atombas',
  atombei: 'atombei',
  atombem: 'atombem',
  atombes: 'atombes',
  atombou: 'atombou',
  atomica: 'atômica',
  atomico: 'atômico',
  atomiza: 'atomiza',
  atomize: 'atomize',
  atomizo: 'atomizo',
  atonada: 'atonada',
  atonado: 'atonado',
  atonais: 'atonais',
  atonamo: 'atonamo',
  atonara: 'atonará',
  atonava: 'atonava',
  atoneis: 'atoneis',
  atonemo: 'atonemo',
  atoniai: 'atoniai',
  atoniam: 'atoniam',
  atoniar: 'atoniar',
  atonias: 'atonias',
  atonica: 'atônica',
  atonico: 'atônico',
  atoniei: 'atoniei',
  atoniem: 'atoniem',
  atonies: 'atonies',
  atoniou: 'atoniou',
  atonita: 'atônita',
  atonito: 'atônito',
  atoniza: 'atoniza',
  atonize: 'atonize',
  atonizo: 'atonizo',
  atontai: 'atontai',
  atontam: 'atontam',
  atontar: 'atontar',
  atontas: 'atontas',
  atontea: 'atonteá',
  atontei: 'atontei',
  atontem: 'atontem',
  atontes: 'atontes',
  atontou: 'atontou',
  atopada: 'atopada',
  atopado: 'atopado',
  atopais: 'atopais',
  atopamo: 'atopamo',
  atopara: 'atopara',
  atopava: 'atopava',
  atopeis: 'atopeis',
  atopema: 'atopema',
  atopemo: 'atopemo',
  atopeta: 'atopeta',
  atopete: 'atopete',
  atopeto: 'atopeto',
  atopias: 'atopias',
  atopica: 'atópica',
  atopico: 'atópico',
  atopita: 'atopita',
  atopite: 'atopite',
  atopito: 'atopito',
  atorada: 'atorada',
  atorado: 'atorado',
  atorais: 'atorais',
  atoramo: 'atoramo',
  atorara: 'atorará',
  atorava: 'atorava',
  atorcoa: 'atorçoá',
  atorcoe: 'atorçoe',
  atorcoo: 'atorçoo',
  atordoa: 'atordoa',
  atordoe: 'atordoe',
  atordoo: 'atordoo',
  atoreis: 'atoreis',
  atoremo: 'atoremo',
  atorrea: 'atorreá',
  atorroa: 'atorroá',
  atorroe: 'atorroe',
  atorroo: 'atorroo',
  atorzao: 'atorzão',
  atouaou: 'atouaou',
  atoucai: 'atoucai',
  atoucam: 'atouçam',
  atoucar: 'atouçar',
  atoucas: 'atoucas',
  atoucei: 'atoucei',
  atoucem: 'atoucem',
  atouces: 'atouces',
  atoucos: 'atoucos',
  atoucou: 'atoucou',
  atouque: 'atouque',
  atoxica: 'atóxica',
  atoxico: 'atóxico',
  atracai: 'atracai',
  atracam: 'atracam',
  atracao: 'atração',
  atracar: 'atracar',
  atracas: 'atracas',
  atracou: 'atracou',
  atraiam: 'atraiam',
  atraias: 'atraias',
  atraida: 'atraída',
  atraido: 'atraído',
  atraimo: 'atraímo',
  atraira: 'atrairá',
  atramai: 'atramai',
  atramam: 'atramam',
  atramar: 'atramar',
  atramas: 'atramas',
  atramei: 'atramei',
  atramem: 'atramem',
  atrames: 'atrames',
  atramou: 'atramou',
  atranas: 'atranas',
  atranca: 'atranca',
  atranco: 'atranco',
  atranos: 'atranos',
  atrapai: 'atrapai',
  atrapam: 'atrapam',
  atrapar: 'atrapar',
  atrapas: 'atrapas',
  atrapei: 'atrapei',
  atrapem: 'atrapem',
  atrapes: 'atrapes',
  atrapou: 'atrapou',
  atraque: 'atraque',
  atrasai: 'atrasai',
  atrasam: 'atrasam',
  atrasar: 'atrasar',
  atrasas: 'atrasas',
  atrasei: 'atrasei',
  atrasem: 'atrasem',
  atrases: 'atrases',
  atrasos: 'atrasos',
  atrasou: 'atrasou',
  atrator: 'atrator',
  atraves: 'através',
  atrecam: 'atreçam',
  atrecas: 'atreças',
  atrecei: 'atrecei',
  atrecem: 'atrecem',
  atrecer: 'atrecer',
  atreces: 'atreces',
  atreceu: 'atreceu',
  atrecia: 'atrecia',
  atredai: 'atredai',
  atredam: 'atredam',
  atredar: 'atredar',
  atredas: 'atredas',
  atredei: 'atredei',
  atredem: 'atredem',
  atredes: 'atredes',
  atredou: 'atredou',
  atregua: 'atrégua',
  atregue: 'atregue',
  atreguo: 'atréguo',
  atreita: 'atreita',
  atreite: 'atreite',
  atreito: 'atreito',
  atrelai: 'atrelai',
  atrelam: 'atrelam',
  atrelar: 'atrelar',
  atrelas: 'atrelas',
  atrelei: 'atrelei',
  atrelem: 'atrelem',
  atreles: 'atreles',
  atrelou: 'atrelou',
  atremai: 'atremai',
  atremam: 'atremam',
  atremar: 'atremar',
  atremas: 'atremas',
  atremei: 'atremei',
  atremem: 'atremem',
  atremes: 'atremes',
  atremia: 'atremia',
  atremou: 'atremou',
  atrepai: 'atrepai',
  atrepam: 'atrepam',
  atrepar: 'atrepar',
  atrepas: 'atrepas',
  atrepei: 'atrepei',
  atrepem: 'atrepem',
  atrepes: 'atrepes',
  atrepou: 'atrepou',
  atresia: 'atresia',
  atrevam: 'atrevam',
  atrevas: 'atrevas',
  atrevei: 'atrevei',
  atrevem: 'atrevem',
  atrever: 'atrever',
  atreves: 'atreves',
  atreveu: 'atreveu',
  atrevia: 'atrevia',
  atriais: 'atriais',
  atriana: 'atriana',
  atriano: 'atriano',
  atriate: 'atriate',
  atribua: 'atribua',
  atribui: 'atribui',
  atribuo: 'atribuo',
  atricao: 'atrição',
  atricas: 'átricas',
  atricos: 'átricos',
  atridas: 'atridas',
  atridos: 'atridos',
  atrigai: 'atrigai',
  atrigam: 'atrigam',
  atrigar: 'atrigar',
  atrigas: 'atrigas',
  atrigou: 'atrigou',
  atrigue: 'atrigue',
  atriolo: 'atríolo',
  atriota: 'atriota',
  atrista: 'atrista',
  atriste: 'atriste',
  atristo: 'atristo',
  atritai: 'atritai',
  atritam: 'atritam',
  atritar: 'atritar',
  atritas: 'atritas',
  atritei: 'atritei',
  atritem: 'atritem',
  atrites: 'atrites',
  atritos: 'atritos',
  atritou: 'atritou',
  atrizes: 'atrizes',
  atroada: 'atroada',
  atroado: 'atroado',
  atroais: 'atroais',
  atroamo: 'atroamo',
  atroara: 'atroara',
  atroari: 'atroari',
  atroava: 'atroava',
  atrocas: 'átrocas',
  atroces: 'atroces',
  atrocoa: 'atroçoa',
  atrocoe: 'atroçoe',
  atrocoo: 'atroçoo',
  atrocos: 'átrocos',
  atrodia: 'atrodia',
  atroeis: 'atroeis',
  atroemo: 'atroemo',
  atrofas: 'átrofas',
  atrofia: 'atrofia',
  atrofie: 'atrofie',
  atrofio: 'atrofio',
  atrofos: 'átrofos',
  atrolai: 'atrolai',
  atrolam: 'atrolam',
  atrolar: 'atrolar',
  atrolas: 'atrolas',
  atrolei: 'atrolei',
  atrolem: 'atrolem',
  atroles: 'atroles',
  atrolha: 'atrolha',
  atrolhe: 'atrolhe',
  atrolho: 'atrolho',
  atrolou: 'atrolou',
  atromba: 'atromba',
  atrombe: 'atrombe',
  atrombo: 'atrombo',
  atronai: 'atronai',
  atronal: 'atronal',
  atronam: 'atronam',
  atronar: 'atronar',
  atronas: 'atronas',
  atronei: 'atronei',
  atronem: 'atronem',
  atrones: 'atrones',
  atronia: 'atronia',
  atronou: 'atronou',
  atropai: 'atropai',
  atropam: 'atropam',
  atropar: 'atropar',
  atropas: 'atropas',
  atropei: 'atropei',
  atropem: 'atropem',
  atropes: 'atropes',
  atropis: 'átropis',
  atropos: 'átropos',
  atropou: 'atropou',
  atrozes: 'atrozes',
  atrunca: 'atruncá',
  atrunco: 'atrunco',
  atrusai: 'atrusai',
  atrusam: 'atrusam',
  atrusar: 'atrusar',
  atrusas: 'atrusas',
  atrusei: 'atrusei',
  atrusem: 'atrusem',
  atruses: 'atruses',
  atrusou: 'atrusou',
  attache: 'attaché',
  atuacao: 'atuação',
  atuadao: 'atuadão',
  atuadas: 'atuadas',
  atuador: 'atuador',
  atuados: 'atuados',
  atuamos: 'atuamos',
  atuando: 'atuando',
  atuante: 'atuante',
  atuaram: 'atuaram',
  atuarao: 'atuarão',
  atuaras: 'atuaras',
  atuarei: 'atuarei',
  atuarem: 'atuarem',
  atuares: 'atuares',
  atuaria: 'atuaria',
  atuario: 'atuário',
  atuarmo: 'atuarmo',
  atuarro: 'atuarro',
  atuasse: 'atuasse',
  atuaste: 'atuaste',
  atuavam: 'atuavam',
  atuavas: 'atuavas',
  atuavel: 'atuável',
  atubiba: 'atubiba',
  atubibe: 'atubibe',
  atubibo: 'atubibo',
  atucana: 'atucana',
  atucane: 'atucane',
  atucano: 'atucano',
  atuduas: 'atúduas',
  atueira: 'atueira',
  atuemos: 'atuemos',
  atufada: 'atufada',
  atufado: 'atufado',
  atufais: 'atufais',
  atufamo: 'atufamo',
  atufara: 'atufara',
  atufava: 'atufava',
  atufeis: 'atufeis',
  atufemo: 'atufemo',
  atuidao: 'atuidão',
  atuidas: 'atuídas',
  atuidor: 'atuidor',
  atuidos: 'atuídos',
  atuieis: 'atuíeis',
  atuimos: 'atuímos',
  atuindo: 'atuindo',
  atuiram: 'atuíram',
  atuirao: 'atuirão',
  atuiras: 'atuirás',
  atuirei: 'atuirei',
  atuirem: 'atuírem',
  atuires: 'atuíres',
  atuiria: 'atuiria',
  atuisse: 'atuísse',
  atuiste: 'atuíste',
  atulhai: 'atulhai',
  atulham: 'atulham',
  atulhar: 'atulhar',
  atulhas: 'atulhas',
  atulhei: 'atulhei',
  atulhem: 'atulhem',
  atulhes: 'atulhes',
  atulhos: 'atulhos',
  atulhou: 'atulhou',
  atumbas: 'atumbas',
  atundir: 'atundir',
  atundos: 'atundos',
  atuosas: 'atuosas',
  atuosos: 'atuosos',
  atupais: 'atupais',
  atupiam: 'atupiam',
  atupias: 'atupias',
  atupida: 'atupida',
  atupido: 'atupido',
  atupira: 'atupira',
  aturada: 'aturada',
  aturado: 'aturado',
  aturais: 'aturais',
  aturamo: 'aturamo',
  aturara: 'aturara',
  aturari: 'aturari',
  aturava: 'aturava',
  aturdam: 'aturdam',
  aturdas: 'aturdas',
  aturdem: 'aturdem',
  aturdes: 'aturdes',
  aturdia: 'aturdia',
  aturdir: 'aturdir',
  aturdis: 'aturdis',
  aturdiu: 'aturdiu',
  atureis: 'atureis',
  atureja: 'atureja',
  atureje: 'atureje',
  aturejo: 'aturejo',
  aturemo: 'aturemo',
  aturgai: 'aturgai',
  aturgam: 'aturgam',
  aturgar: 'aturgar',
  aturgas: 'aturgas',
  aturgem: 'aturgem',
  aturges: 'aturges',
  aturgia: 'aturgia',
  aturgir: 'aturgir',
  aturgis: 'aturgis',
  aturgiu: 'aturgiu',
  aturgou: 'aturgou',
  aturgue: 'aturgue',
  aturias: 'aturiás',
  aturjam: 'aturjam',
  aturjas: 'aturjas',
  aturrea: 'aturreá',
  aturvai: 'aturvai',
  aturvam: 'aturvam',
  aturvar: 'aturvar',
  aturvas: 'aturvas',
  aturvei: 'aturvei',
  aturvem: 'aturvem',
  aturves: 'aturves',
  aturvou: 'aturvou',
  atutias: 'atutias',
  auacuri: 'auacuri',
  auaduri: 'auaduri',
  aubrito: 'aubrito',
  aucrana: 'aucrana',
  aucubas: 'aucubas',
  aucupio: 'aucúpio',
  audacia: 'audácia',
  audazes: 'audazes',
  audiana: 'audiana',
  audiano: 'audiano',
  audicao: 'audição',
  audioes: 'audiões',
  auditai: 'auditai',
  auditam: 'auditam',
  auditar: 'auditar',
  auditas: 'auditas',
  auditei: 'auditei',
  auditem: 'auditem',
  audites: 'audites',
  auditor: 'auditor',
  auditou: 'auditou',
  audivel: 'audível',
  auerita: 'auerita',
  auerite: 'auerite',
  auerito: 'auerito',
  auferem: 'auferem',
  auferes: 'auferes',
  auferia: 'auferia',
  auferir: 'auferir',
  auferis: 'auferis',
  auferiu: 'auferiu',
  aufiram: 'aufiram',
  aufiras: 'aufiras',
  aufugio: 'aufúgio',
  augadao: 'augadão',
  augadas: 'augadas',
  augador: 'augador',
  augados: 'augados',
  augalha: 'augalhá',
  augalhe: 'augalhe',
  augalho: 'augalho',
  augamos: 'augamos',
  augando: 'augando',
  augante: 'augante',
  augaram: 'augaram',
  augarao: 'augarão',
  augaras: 'augarás',
  augarei: 'augarei',
  augarem: 'augarem',
  augares: 'augares',
  augaria: 'augaria',
  augasse: 'augasse',
  augaste: 'augaste',
  augavam: 'augavam',
  augavas: 'augavas',
  augiada: 'augíada',
  augieis: 'augíeis',
  augilas: 'augilas',
  augindo: 'augindo',
  augitas: 'augitas',
  augites: 'augites',
  augnata: 'áugnata',
  augnato: 'áugnato',
  augueis: 'augueis',
  augurai: 'augurai',
  augural: 'augural',
  auguram: 'auguram',
  augurar: 'augurar',
  auguras: 'auguras',
  augurei: 'augurei',
  augurem: 'augurem',
  augures: 'augures',
  augurio: 'augúrio',
  augurou: 'augurou',
  augusta: 'augusta',
  augusto: 'augusto',
  auiquis: 'auiquis',
  aulacas: 'áulacas',
  aulaces: 'áulaces',
  aulacia: 'aulácia',
  aulacos: 'áulacos',
  aularca: 'aularca',
  aulerco: 'aulerco',
  auletas: 'auletas',
  auletes: 'auletes',
  aulicas: 'áulicas',
  aulicos: 'áulicos',
  aulidas: 'aulidas',
  aulidor: 'aulidor',
  aulidos: 'aulidos',
  aulindo: 'aulindo',
  aulinha: 'aulinha',
  auliram: 'auliram',
  aulirao: 'aulirão',
  aulirem: 'aulirem',
  auliria: 'auliria',
  aulisse: 'aulisse',
  aulista: 'aulista',
  aulodia: 'aulodia',
  aulonio: 'aulônio',
  aulopos: 'aulopos',
  aulorai: 'aulorai',
  auloram: 'auloram',
  aulorar: 'aulorar',
  auloras: 'auloras',
  aulorei: 'aulorei',
  aulorem: 'aulorem',
  aulores: 'aulores',
  aulorou: 'aulorou',
  aumenta: 'aumenta',
  aumente: 'aumente',
  aumento: 'aumento',
  aunadao: 'aunadão',
  aunadas: 'aunadas',
  aunador: 'aunador',
  aunados: 'aunados',
  aunamos: 'aunamos',
  aunando: 'aunando',
  aunante: 'aunante',
  aunaram: 'aunaram',
  aunarao: 'aunarão',
  aunaras: 'aunarás',
  aunarei: 'aunarei',
  aunarem: 'aunarem',
  aunares: 'aunares',
  aunaria: 'aunaria',
  aunarmo: 'aunarmo',
  aunasse: 'aunasse',
  aunaste: 'aunaste',
  aunavam: 'aunavam',
  aunavas: 'aunavas',
  aunemos: 'aunemos',
  auradao: 'auradão',
  auradas: 'auradas',
  aurador: 'aurador',
  aurados: 'aurados',
  auramos: 'auramos',
  auranas: 'auranas',
  aurando: 'aurando',
  aurante: 'aurante',
  auraram: 'auraram',
  aurarao: 'aurarão',
  auraras: 'aurarás',
  aurarei: 'aurarei',
  aurarem: 'aurarem',
  aurares: 'aurares',
  auraria: 'auraria',
  aurarmo: 'aurarmo',
  aurasse: 'aurasse',
  auraste: 'auraste',
  auratos: 'auratos',
  aurauna: 'auraúna',
  auravam: 'auravam',
  auravas: 'auravas',
  aurecia: 'aurécia',
  aurelas: 'aurelas',
  aurelia: 'aurélia',
  auremos: 'auremos',
  aurense: 'aurense',
  aureola: 'auréola',
  aureole: 'aureole',
  aureolo: 'aureolo',
  auretos: 'auretos',
  auricas: 'áuricas',
  auricol: 'auricol',
  auricos: 'áuricos',
  auridas: 'auridas',
  auridor: 'auridor',
  auridos: 'auridos',
  aurieis: 'auríeis',
  aurigas: 'aurigas',
  aurigia: 'aurígia',
  aurigos: 'aurigos',
  aurilas: 'aurilas',
  auriluz: 'auriluz',
  aurimos: 'aurimos',
  aurinas: 'aurinas',
  aurindo: 'aurindo',
  aurinia: 'aurínia',
  aurinos: 'aurinos',
  auriram: 'auriram',
  aurirao: 'aurirão',
  auriras: 'auriras',
  aurirei: 'aurirei',
  aurirem: 'aurirem',
  aurires: 'aurires',
  auriria: 'auriria',
  aurirmo: 'aurirmo',
  aurisse: 'aurisse',
  aurista: 'aurista',
  auriste: 'auriste',
  auritos: 'auritos',
  auronia: 'aurônia',
  auropos: 'auropós',
  auroque: 'auroque',
  aurorai: 'aurorai',
  auroral: 'auroral',
  auroram: 'auroram',
  aurorar: 'aurorar',
  auroras: 'auroras',
  aurorau: 'auroraú',
  aurorea: 'auroreá',
  aurorei: 'aurorei',
  aurorem: 'aurorem',
  aurores: 'aurores',
  aurorou: 'aurorou',
  aurosas: 'aurosas',
  aurosos: 'aurosos',
  aurunco: 'aurunco',
  ausenta: 'ausenta',
  ausente: 'ausente',
  ausento: 'ausento',
  ausonas: 'ausonas',
  ausonio: 'ausônio',
  auspica: 'auspicá',
  auspice: 'áuspice',
  auspico: 'auspico',
  austada: 'austada',
  austado: 'austado',
  austaga: 'austaga',
  austais: 'austais',
  austamo: 'austamo',
  austara: 'austará',
  austava: 'austava',
  austeis: 'austeis',
  austemo: 'austemo',
  austera: 'austera',
  austero: 'austero',
  austral: 'austral',
  austrio: 'áustrio',
  austros: 'austros',
  autalia: 'autália',
  autarca: 'autarca',
  autarco: 'autarco',
  autecio: 'autécio',
  autecos: 'autecos',
  auteira: 'auteira',
  auteiro: 'auteiro',
  autente: 'autente',
  autepsa: 'autepsa',
  autismo: 'autismo',
  autista: 'autista',
  autoica: 'autoica',
  autoico: 'autoico',
  autopse: 'autopse',
  autoral: 'autoral',
  autoras: 'autoras',
  autores: 'autores',
  autoria: 'autoria',
  autovia: 'autovia',
  autuada: 'autuada',
  autuado: 'autuado',
  autuais: 'autuais',
  autuamo: 'autuamo',
  autuara: 'autuara',
  autuava: 'autuava',
  autueis: 'autueis',
  autuemo: 'autuemo',
  autumno: 'autumno',
  autunal: 'autunal',
  auxenia: 'auxenia',
  auxeses: 'auxeses',
  auxesia: 'auxesia',
  auxilai: 'auxilai',
  auxilam: 'auxilam',
  auxilar: 'auxilar',
  auxilas: 'auxilas',
  auxilei: 'auxilei',
  auxilem: 'auxilem',
  auxiles: 'auxiles',
  auxilia: 'auxilia',
  auxilie: 'auxilie',
  auxilio: 'auxílio',
  auxilou: 'auxilou',
  auxinas: 'auxinas',
  auxitas: 'auxitas',
  avacada: 'avacada',
  avacado: 'avacado',
  avacate: 'avacate',
  avacote: 'avacote',
  avacuai: 'avacuai',
  avacuam: 'avacuam',
  avacuar: 'avacuar',
  avacuas: 'avacuas',
  avacuei: 'avacuei',
  avacuem: 'avacuem',
  avacues: 'avacues',
  avacuou: 'avacuou',
  avadana: 'avadana',
  avaduta: 'avaduta',
  avagara: 'avagará',
  avagare: 'avagare',
  avagaro: 'avagaro',
  avalada: 'avalada',
  avalade: 'avalade',
  avalado: 'avalado',
  avalais: 'avalais',
  avalamo: 'avalamo',
  avalara: 'avalara',
  avalava: 'avalava',
  avaleis: 'avaleis',
  avalemo: 'avalemo',
  avaliai: 'avaliai',
  avaliam: 'avaliam',
  avaliar: 'avaliar',
  avalias: 'avalias',
  avaliei: 'avaliei',
  avaliem: 'avaliem',
  avalies: 'avalies',
  avaliou: 'avaliou',
  avalita: 'avalita',
  avaliza: 'avaliza',
  avalize: 'avalize',
  avalizo: 'avalizo',
  avaloai: 'avaloai',
  avaloam: 'avaloam',
  avaloar: 'avaloar',
  avaloas: 'avaloas',
  avaloei: 'avaloei',
  avaloem: 'avaloem',
  avaloes: 'avaloes',
  avaloou: 'avaloou',
  avaluai: 'avaluai',
  avaluam: 'avaluam',
  avaluar: 'avaluar',
  avaluas: 'avaluas',
  avaluei: 'avaluei',
  avaluem: 'avaluem',
  avalues: 'avalues',
  avaluou: 'avaluou',
  avambas: 'avambas',
  avancai: 'avançai',
  avancam: 'avançam',
  avancar: 'avançar',
  avancas: 'avanças',
  avancei: 'avancei',
  avancem: 'avancem',
  avances: 'avances',
  avancos: 'avanços',
  avancou: 'avançou',
  avandis: 'avandis',
  avangai: 'avangai',
  avangam: 'avangam',
  avangar: 'avangar',
  avangas: 'avangas',
  avangou: 'avangou',
  avanias: 'avanias',
  avarada: 'avarada',
  avarado: 'avarado',
  avarari: 'avarari',
  avarcas: 'avarcas',
  avareza: 'avareza',
  avargai: 'avargai',
  avargam: 'avargam',
  avargar: 'avargar',
  avargas: 'avargas',
  avargou: 'avargou',
  avargue: 'avargue',
  avariai: 'avariai',
  avariam: 'avariam',
  avariar: 'avariar',
  avarias: 'avarias',
  avariei: 'avariei',
  avariem: 'avariem',
  avaries: 'avaries',
  avariou: 'avariou',
  avarita: 'avarita',
  avasita: 'avasita',
  avatada: 'avatada',
  avatado: 'avatado',
  avatais: 'avatais',
  avatamo: 'avatamo',
  avatara: 'avatara',
  avatava: 'avatava',
  avateis: 'avateis',
  avatemo: 'avatemo',
  aveadas: 'aveadas',
  aveados: 'aveados',
  aveclas: 'aveclas',
  avejate: 'avejate',
  avejoes: 'avejões',
  avelada: 'avelada',
  avelado: 'avelado',
  avelais: 'avelais',
  avelamo: 'avelamo',
  avelana: 'avelana',
  avelane: 'avelane',
  avelano: 'avelano',
  avelara: 'avelara',
  avelava: 'avelava',
  aveleis: 'aveleis',
  avelemo: 'avelemo',
  avelhai: 'avelhai',
  avelham: 'avelham',
  avelhar: 'avelhar',
  avelhas: 'avelhas',
  avelhei: 'avelhei',
  avelhem: 'avelhem',
  avelhes: 'avelhes',
  avelhou: 'avelhou',
  avelina: 'avelina',
  avelroa: 'avélroa',
  aveluda: 'aveluda',
  avelude: 'avelude',
  aveludo: 'aveludo',
  avenada: 'avenada',
  avenado: 'avenado',
  avenais: 'avenais',
  avencai: 'avençai',
  avencal: 'avencal',
  avencam: 'avençam',
  avencao: 'avencão',
  avencar: 'avençar',
  avencas: 'avencas',
  avencei: 'avencei',
  avencem: 'avencem',
  avences: 'avences',
  avencou: 'avençou',
  avenham: 'avenham',
  avenhas: 'avenhas',
  avenida: 'avenida',
  avenina: 'avenina',
  avenose: 'avenose',
  avenses: 'avenses',
  aventai: 'aventai',
  avental: 'avental',
  aventam: 'aventam',
  aventar: 'aventar',
  aventas: 'aventas',
  aventei: 'aventei',
  aventem: 'aventem',
  aventes: 'aventes',
  aventou: 'aventou',
  averana: 'averana',
  averano: 'averano',
  averbai: 'averbai',
  averbam: 'averbam',
  averbar: 'averbar',
  averbas: 'averbas',
  averbei: 'averbei',
  averbem: 'averbem',
  averbes: 'averbes',
  averbou: 'averbou',
  avergai: 'avergai',
  avergam: 'avergam',
  avergar: 'avergar',
  avergas: 'avergas',
  avergem: 'avergem',
  averges: 'averges',
  avergia: 'avergia',
  avergir: 'avergir',
  avergis: 'avergis',
  avergiu: 'avergiu',
  avergoa: 'avergoa',
  avergoe: 'avergoe',
  avergoo: 'avergoo',
  avergou: 'avergou',
  avergue: 'avergue',
  avernal: 'avernal',
  avernas: 'avernas',
  avernea: 'avérnea',
  averneo: 'avérneo',
  avernos: 'avernos',
  averroa: 'averroa',
  aversao: 'aversão',
  aversos: 'aversos',
  avertam: 'avertam',
  avertas: 'avertas',
  avertei: 'avertei',
  avertem: 'avertem',
  averter: 'averter',
  avertes: 'avertes',
  averteu: 'averteu',
  avertia: 'avertia',
  avespao: 'avespão',
  avessai: 'avessai',
  avessam: 'avessam',
  avessar: 'avessar',
  avessas: 'avessas',
  avessei: 'avessei',
  avessem: 'avessem',
  avesses: 'avesses',
  avessia: 'avessia',
  avessos: 'avessos',
  avessou: 'avessou',
  avestas: 'avestas',
  avexada: 'avexada',
  avexado: 'avexado',
  avexais: 'avexais',
  avexame: 'avexame',
  avexamo: 'avexamo',
  avexara: 'avexará',
  avexava: 'avexava',
  avexeis: 'avexeis',
  avexemo: 'avexemo',
  avezada: 'avezada',
  avezado: 'avezado',
  avezais: 'avezais',
  avezamo: 'avezamo',
  avezara: 'avezará',
  avezava: 'avezava',
  avezeis: 'avezeis',
  avezemo: 'avezemo',
  aviacao: 'aviação',
  aviacas: 'aviacas',
  aviadao: 'aviadão',
  aviadas: 'aviadas',
  aviador: 'aviador',
  aviados: 'aviados',
  aviamos: 'aviamos',
  aviando: 'aviando',
  aviante: 'aviante',
  aviaram: 'aviaram',
  aviarao: 'aviarão',
  aviaras: 'aviaras',
  aviarei: 'aviarei',
  aviarem: 'aviarem',
  aviares: 'aviares',
  aviaria: 'aviaria',
  aviario: 'aviário',
  aviarmo: 'aviarmo',
  aviasse: 'aviasse',
  aviaste: 'aviaste',
  aviavam: 'aviavam',
  aviavas: 'aviavas',
  avicela: 'avicela',
  avicida: 'avicida',
  avicola: 'avícola',
  avicula: 'avícula',
  avicule: 'avicule',
  aviculo: 'aviculo',
  avidada: 'avidada',
  avidado: 'avidado',
  avidais: 'avidais',
  avidamo: 'avidamo',
  avidara: 'avidara',
  avidava: 'avidava',
  avideis: 'avideis',
  avidemo: 'avidemo',
  avidoes: 'avidões',
  avidona: 'avidona',
  avidora: 'avidora',
  avieira: 'avieira',
  avieiro: 'avieiro',
  aviemos: 'aviemos',
  avieram: 'avieram',
  avieras: 'avieras',
  avierem: 'avierem',
  avieres: 'avieres',
  aviermo: 'aviermo',
  aviesai: 'aviesai',
  aviesam: 'aviesam',
  aviesar: 'aviesar',
  aviesas: 'aviesas',
  aviesei: 'aviesei',
  aviesem: 'aviesem',
  avieses: 'avieses',
  aviesou: 'aviesou',
  aviesse: 'aviesse',
  avieste: 'avieste',
  avigora: 'avigora',
  avigore: 'avigore',
  avigoro: 'avigoro',
  avilada: 'avilada',
  avilado: 'avilado',
  avilais: 'avilais',
  avilamo: 'avilamo',
  avilana: 'avilana',
  avilane: 'avilane',
  avilano: 'avilano',
  avilara: 'avilará',
  avilava: 'avilava',
  avildai: 'avildai',
  avildam: 'avildam',
  avildar: 'avildar',
  avildas: 'avildas',
  avildei: 'avildei',
  avildem: 'avildem',
  avildes: 'avildes',
  avildou: 'avildou',
  avileis: 'avileis',
  avilemo: 'avilemo',
  avilesa: 'avilesa',
  aviltai: 'aviltai',
  aviltam: 'aviltam',
  aviltar: 'aviltar',
  aviltas: 'aviltas',
  aviltei: 'aviltei',
  aviltem: 'aviltem',
  aviltes: 'aviltes',
  aviltou: 'aviltou',
  avincai: 'avincai',
  avincam: 'avincam',
  avincar: 'avincar',
  avincas: 'avincas',
  avincou: 'avincou',
  avindas: 'avindas',
  avindes: 'avindes',
  avindor: 'avindor',
  avindos: 'avindos',
  avingai: 'avingai',
  avingam: 'avingam',
  avingar: 'avingar',
  avingas: 'avingas',
  avingou: 'avingou',
  avingue: 'avingue',
  avinhai: 'avinhai',
  avinham: 'avinham',
  avinhar: 'avinhar',
  avinhas: 'avinhas',
  avinhei: 'avinhei',
  avinhem: 'avinhem',
  avinhes: 'avinhes',
  avinhou: 'avinhou',
  avinque: 'avinque',
  avionar: 'avionar',
  avirama: 'avirama',
  avirana: 'avirana',
  avirdes: 'avirdes',
  avireis: 'avireis',
  aviremo: 'aviremo',
  aviriam: 'aviriam',
  avirias: 'avirias',
  avirmos: 'avirmos',
  avisada: 'avisada',
  avisado: 'avisado',
  avisais: 'avisais',
  avisamo: 'avisamo',
  avisara: 'avisara',
  avisava: 'avisava',
  aviseis: 'aviseis',
  avisemo: 'avisemo',
  avistai: 'avistai',
  avistam: 'avistam',
  avistar: 'avistar',
  avistas: 'avistas',
  avistei: 'avistei',
  avistem: 'avistem',
  avistes: 'avistes',
  avistou: 'avistou',
  avisuga: 'avisuga',
  aviuzar: 'aviuzar',
  avivada: 'avivada',
  avivado: 'avivado',
  avivais: 'avivais',
  avivamo: 'avivamo',
  avivara: 'avivara',
  avivava: 'avivava',
  aviveis: 'aviveis',
  avivemo: 'avivemo',
  avoacai: 'avoaçai',
  avoacam: 'avoaçam',
  avoacao: 'avoação',
  avoacar: 'avoaçar',
  avoacas: 'avoaças',
  avoacei: 'avoacei',
  avoacem: 'avoacem',
  avoaces: 'avoaces',
  avoacou: 'avoaçou',
  avoadao: 'avoadão',
  avoadas: 'avoadas',
  avoador: 'avoador',
  avoados: 'avoados',
  avoamos: 'avoamos',
  avoando: 'avoando',
  avoante: 'avoante',
  avoaram: 'avoaram',
  avoarao: 'avoarão',
  avoaras: 'avoaras',
  avoarei: 'avoarei',
  avoarem: 'avoarem',
  avoares: 'avoares',
  avoaria: 'avoaria',
  avoarmo: 'avoarmo',
  avoasse: 'avoasse',
  avoaste: 'avoaste',
  avoavam: 'avoavam',
  avoavas: 'avoavas',
  avocada: 'avocada',
  avocado: 'avocado',
  avocais: 'avocais',
  avocamo: 'avocamo',
  avocara: 'avocará',
  avocava: 'avocava',
  avoceta: 'avoceta',
  avoeira: 'avoeira',
  avoejai: 'avoejai',
  avoejam: 'avoejam',
  avoejar: 'avoejar',
  avoejas: 'avoejas',
  avoejei: 'avoejei',
  avoejem: 'avoejem',
  avoejes: 'avoejes',
  avoejou: 'avoejou',
  avoemos: 'avoemos',
  avoenga: 'avoenga',
  avoengo: 'avoengo',
  avogado: 'avogado',
  avoiras: 'avoiras',
  avoluma: 'avoluma',
  avolume: 'avolume',
  avolumo: 'avolumo',
  avondai: 'avondai',
  avondam: 'avondam',
  avondar: 'avondar',
  avondas: 'avondas',
  avondei: 'avondei',
  avondem: 'avondem',
  avondes: 'avondes',
  avondos: 'avondos',
  avondou: 'avondou',
  avoquei: 'avoquei',
  avoquem: 'avoquem',
  avoques: 'avoques',
  avorina: 'avorina',
  avosada: 'avosada',
  avosado: 'avosado',
  avosais: 'avosais',
  avosamo: 'avosamo',
  avosara: 'avosara',
  avosava: 'avosava',
  avoseis: 'avoseis',
  avosemo: 'avosemo',
  avozeai: 'avozeai',
  avozear: 'avozear',
  avozeei: 'avozeei',
  avozeia: 'avozeia',
  avozeie: 'avozeie',
  avozeio: 'avozeio',
  avozeou: 'avozeou',
  avulsao: 'avulsão',
  avulsas: 'avulsas',
  avulsos: 'avulsos',
  avultai: 'avultai',
  avultam: 'avultam',
  avultar: 'avultar',
  avultas: 'avultas',
  avultei: 'avultei',
  avultem: 'avultem',
  avultes: 'avultes',
  avultou: 'avultou',
  avuscas: 'avuscas',
  avècido: 'avècido',
  axabeba: 'axabeba',
  axaguas: 'axaguás',
  axandra: 'axandra',
  axandre: 'axandre',
  axandro: 'axandro',
  axantis: 'axântis',
  axantos: 'axantos',
  axapona: 'axapona',
  axaraca: 'axaraca',
  axelhos: 'axelhos',
  axenica: 'axênica',
  axenico: 'axênico',
  axicara: 'axicara',
  axicare: 'axicare',
  axicaro: 'axicaro',
  axiculo: 'axículo',
  axifera: 'axífera',
  axifero: 'axífero',
  axifugo: 'axífugo',
  axilose: 'axilose',
  axineia: 'axineia',
  axinela: 'axinela',
  axingia: 'axíngia',
  axinica: 'axínica',
  axinico: 'axínico',
  axinita: 'axinita',
  axinite: 'axinite',
  axinito: 'axinito',
  axiomas: 'axiomas',
  axipaie: 'axipaie',
  axipeto: 'axípeto',
  axocela: 'axocela',
  axofito: 'axófito',
  axoguns: 'axoguns',
  axoidea: 'axoidea',
  axoideo: 'axoideo',
  axoides: 'axoides',
  axolise: 'axólise',
  axolote: 'axolote',
  axonais: 'axonais',
  axonema: 'axonema',
  axonges: 'axonges',
  axonica: 'axônica',
  axonico: 'axônico',
  axonios: 'axônios',
  axonope: 'axonope',
  axopode: 'axópode',
  axorcai: 'axorcai',
  axorcam: 'axorcam',
  axorcar: 'axorcar',
  axorcas: 'axorcas',
  axorcou: 'axorcou',
  axorque: 'axorque',
  axotomo: 'axótomo',
  axuajus: 'axuajus',
  axungia: 'axúngia',
  axungie: 'axungie',
  axungio: 'axungio',
  azabona: 'azabona',
  azadoes: 'azadões',
  azadona: 'azadona',
  azadora: 'azadora',
  azafada: 'azafada',
  azafado: 'azafado',
  azafama: 'azáfama',
  azafame: 'azafame',
  azafamo: 'azafamo',
  azagaia: 'azagaia',
  azagaie: 'azagaie',
  azagaio: 'azagaio',
  azagais: 'azagais',
  azagres: 'azagres',
  azainas: 'azainas',
  azaleas: 'azáleas',
  azaleia: 'azaleia',
  azalina: 'azalina',
  azalios: 'azálios',
  azalzao: 'azalzão',
  azamboa: 'azamboa',
  azamboe: 'azamboe',
  azamboo: 'azamboo',
  azangai: 'azangai',
  azangam: 'azangam',
  azangar: 'azangar',
  azangas: 'azangas',
  azangou: 'azangou',
  azangue: 'azangue',
  azantes: 'azantes',
  azaolas: 'azaolas',
  azaquis: 'azaquis',
  azarada: 'azarada',
  azarado: 'azarado',
  azarais: 'azarais',
  azaramo: 'azaramo',
  azarara: 'azarara',
  azarava: 'azarava',
  azarcao: 'azarcão',
  azardes: 'azardes',
  azareis: 'azareis',
  azaremo: 'azaremo',
  azariam: 'azariam',
  azarias: 'azarias',
  azarina: 'azarina',
  azarmos: 'azarmos',
  azaroes: 'azarões',
  azarola: 'azarola',
  azarolo: 'azarolo',
  azaruja: 'azaruja',
  azassem: 'azassem',
  azasses: 'azasses',
  azastes: 'azastes',
  azatepa: 'azatepa',
  azaveis: 'azáveis',
  azavere: 'azavere',
  azazeis: 'azazéis',
  azcarah: 'azcarah',
  azebibe: 'azebibe',
  azebrai: 'azebrai',
  azebram: 'azebram',
  azebrar: 'azebrar',
  azebras: 'azebras',
  azebrei: 'azebrei',
  azebrem: 'azebrem',
  azebres: 'azebres',
  azebrou: 'azebrou',
  azebuai: 'azebuai',
  azebuam: 'azebuam',
  azebuar: 'azebuar',
  azebuas: 'azebuas',
  azebuei: 'azebuei',
  azebuem: 'azebuem',
  azebues: 'azebues',
  azebuou: 'azebuou',
  azeches: 'azeches',
  azedada: 'azedada',
  azedado: 'azedado',
  azedais: 'azedais',
  azedamo: 'azedamo',
  azedara: 'azedara',
  azedava: 'azedava',
  azedeis: 'azedeis',
  azedemo: 'azedemo',
  azedens: 'azedéns',
  azedete: 'azedete',
  azedias: 'azedias',
  azedoes: 'azedões',
  azedote: 'azedote',
  azedume: 'azedume',
  azeduns: 'azeduns',
  azedura: 'azedura',
  azegues: 'azegues',
  azeirar: 'azeirar',
  azeitai: 'azeitai',
  azeitam: 'azeitam',
  azeitao: 'azeitão',
  azeitar: 'azeitar',
  azeitas: 'azeitas',
  azeitei: 'azeitei',
  azeitem: 'azeitem',
  azeites: 'azeites',
  azeitou: 'azeitou',
  azelias: 'azélias',
  azelide: 'azélide',
  azelzao: 'azelzão',
  azemala: 'azêmala',
  azemeis: 'azeméis',
  azemela: 'azêmela',
  azemola: 'azêmola',
  azemula: 'azêmula',
  azenhas: 'azenhas',
  azentio: 'azentio',
  azerada: 'azerada',
  azerado: 'azerado',
  azerais: 'azerais',
  azeramo: 'azeramo',
  azerara: 'azerará',
  azerava: 'azerava',
  azerbes: 'azerbes',
  azeredo: 'azeredo',
  azereis: 'azereis',
  azeremo: 'azeremo',
  azerola: 'azerola',
  azerves: 'azerves',
  azetino: 'azetino',
  azevens: 'azevéns',
  azevias: 'azevias',
  azevoes: 'azevões',
  azevrai: 'azevrai',
  azevram: 'azevram',
  azevrar: 'azevrar',
  azevras: 'azevras',
  azevrei: 'azevrei',
  azevrem: 'azevrem',
  azevres: 'azevres',
  azevrou: 'azevrou',
  aziagas: 'aziagas',
  aziagos: 'aziagos',
  aziches: 'aziches',
  azidica: 'azídica',
  azidico: 'azídico',
  azidina: 'azidina',
  azienda: 'azienda',
  azigica: 'azígica',
  azigico: 'azígico',
  azigite: 'azigite',
  azigoto: 'azigoto',
  azilina: 'azilina',
  azimbas: 'azimbas',
  azimbre: 'azimbre',
  azimias: 'azimias',
  azimica: 'azímica',
  azimico: 'azímico',
  azimida: 'azimida',
  azimita: 'azimita',
  azimute: 'azimute',
  azinaba: 'azinabã',
  azinaga: 'azinaga',
  azincho: 'azincho',
  azinhal: 'azinhal',
  azinhas: 'azinhas',
  azinhos: 'azinhos',
  aziumai: 'aziumai',
  aziumam: 'aziumam',
  aziumar: 'aziumar',
  aziumas: 'aziumas',
  aziumei: 'aziumei',
  aziumem: 'aziumem',
  aziumes: 'aziúmes',
  aziumou: 'aziumou',
  azoadao: 'azoadão',
  azoadas: 'azoadas',
  azoador: 'azoador',
  azoados: 'azoados',
  azoamos: 'azoamos',
  azoando: 'azoando',
  azoante: 'azoante',
  azoaram: 'azoaram',
  azoarao: 'azoarão',
  azoaras: 'azoarás',
  azoarei: 'azoarei',
  azoarem: 'azoarem',
  azoares: 'azoares',
  azoaria: 'azoaria',
  azoarmo: 'azoarmo',
  azoasse: 'azoasse',
  azoaste: 'azoaste',
  azoavam: 'azoavam',
  azoavas: 'azoavas',
  azodois: 'azodóis',
  azoeira: 'azoeira',
  azoeire: 'azoeire',
  azoeiro: 'azoeiro',
  azoemos: 'azoemos',
  azogada: 'azogada',
  azogado: 'azogado',
  azoiana: 'azoiana',
  azoiano: 'azoiano',
  azoicas: 'azoicas',
  azoicos: 'azoicos',
  azoinai: 'azoinai',
  azoinam: 'azoinam',
  azoinar: 'azoinar',
  azoinas: 'azoinas',
  azoinei: 'azoinei',
  azoinem: 'azoinem',
  azoines: 'azoines',
  azoinou: 'azoinou',
  azonada: 'azonada',
  azonado: 'azonado',
  azonais: 'azonais',
  azonzai: 'azonzai',
  azonzam: 'azonzam',
  azonzar: 'azonzar',
  azonzas: 'azonzas',
  azonzei: 'azonzei',
  azonzem: 'azonzem',
  azonzes: 'azonzes',
  azonzou: 'azonzou',
  azoques: 'azoques',
  azorata: 'azorata',
  azorate: 'azorate',
  azorato: 'azorato',
  azorela: 'azorela',
  azoreta: 'azoreta',
  azorete: 'azorete',
  azoreto: 'azoreto',
  azorita: 'azorita',
  azorite: 'azorite',
  azorrai: 'azorrai',
  azorram: 'azorram',
  azorrar: 'azorrar',
  azorras: 'azorras',
  azorrei: 'azorrei',
  azorrem: 'azorrem',
  azorres: 'azorres',
  azorrou: 'azorrou',
  azosmas: 'azosmas',
  azotada: 'azotada',
  azotado: 'azotado',
  azotais: 'azotais',
  azotamo: 'azotamo',
  azotana: 'azótana',
  azotara: 'azotará',
  azotato: 'azotato',
  azotava: 'azotava',
  azoteis: 'azoteis',
  azotemo: 'azotemo',
  azoteto: 'azoteto',
  azotica: 'azótica',
  azotico: 'azótico',
  azotido: 'azótido',
  azotilo: 'azotilo',
  azotina: 'azotina',
  azotita: 'azotita',
  azotito: 'azotito',
  azotiza: 'azotizá',
  azotize: 'azotize',
  azotizo: 'azotizo',
  azotois: 'azotóis',
  azotosa: 'azotosa',
  azotoso: 'azotoso',
  azougai: 'azougai',
  azougam: 'azougam',
  azougar: 'azougar',
  azougas: 'azougas',
  azougou: 'azougou',
  azougue: 'azougue',
  azoxido: 'azóxido',
  aztecas: 'aztecas',
  azuagos: 'azuagos',
  azucrim: 'azucrim',
  azuelas: 'azuelas',
  azulada: 'azulada',
  azulado: 'azulado',
  azulais: 'azulais',
  azulamo: 'azulamo',
  azulara: 'azulara',
  azulava: 'azulava',
  azuleai: 'azuleai',
  azulear: 'azulear',
  azuleca: 'azuleça',
  azulece: 'azulece',
  azuleci: 'azuleci',
  azuleco: 'azuleço',
  azuleei: 'azuleei',
  azulego: 'azulego',
  azuleia: 'azuleia',
  azuleie: 'azuleie',
  azuleio: 'azuleio',
  azuleis: 'azuleis',
  azuleja: 'azuleja',
  azuleje: 'azuleje',
  azulejo: 'azulejo',
  azulemo: 'azulemo',
  azuleno: 'azuleno',
  azuleou: 'azuleou',
  azulina: 'azulina',
  azuline: 'azuline',
  azulino: 'azulino',
  azulita: 'azulita',
  azuloes: 'azulões',
  azuloio: 'azuloio',
  azulona: 'azulona',
  azumara: 'azumara',
  azumbai: 'azumbai',
  azumbam: 'azumbam',
  azumbar: 'azumbar',
  azumbas: 'azumbas',
  azumbei: 'azumbei',
  azumbem: 'azumbem',
  azumbes: 'azumbes',
  azumbou: 'azumbou',
  azumbra: 'azumbra',
  azumbre: 'azumbre',
  azumbro: 'azumbro',
  azurada: 'azurada',
  azurado: 'azurado',
  azurais: 'azurais',
  azuramo: 'azuramo',
  azurara: 'azurara',
  azurava: 'azurava',
  azureis: 'azureis',
  azuremo: 'azuremo',
  azurica: 'azúrica',
  azurico: 'azúrico',
  azurina: 'azurina',
  azurino: 'azurino',
  azurita: 'azurita',
  azurite: 'azurite',
  azurnai: 'azurnai',
  azurnam: 'azurnam',
  azurnar: 'azurnar',
  azurnas: 'azurnas',
  azurnei: 'azurnei',
  azurnem: 'azurnem',
  azurnes: 'azurnes',
  azurnou: 'azurnou',
  azurrai: 'azurrai',
  azurram: 'azurram',
  azurrar: 'azurrar',
  azurras: 'azurras',
  azurrei: 'azurrei',
  azurrem: 'azurrem',
  azurres: 'azurres',
  azurrou: 'azurrou',
  azurzam: 'azurzam',
  azurzas: 'azurzas',
  azurzem: 'azurzem',
  azurzes: 'azurzes',
  azurzia: 'azurzia',
  azurzir: 'azurzir',
  azurzis: 'azurzis',
  azurziu: 'azurziu',
  baaismo: 'baaísmo',
  baaista: 'baaísta',
  baalins: 'baalins',
  baalita: 'baalita',
  baalzao: 'baalzão',
  baamesa: 'baamesa',
  baamito: 'baamito',
  baandas: 'baandas',
  baanita: 'baanita',
  babacas: 'babacas',
  babacos: 'babaços',
  babacue: 'babaçuê',
  babacus: 'babaçus',
  babadao: 'babadão',
  babadas: 'babadas',
  babades: 'babadés',
  babador: 'babador',
  babados: 'babados',
  babalao: 'babalaô',
  babalas: 'babalas',
  babalia: 'babália',
  babamos: 'babamos',
  babanca: 'babanca',
  babando: 'babando',
  babania: 'babaniá',
  babanim: 'babanim',
  babanla: 'babanlá',
  babante: 'babante',
  babaoje: 'babaojé',
  babaram: 'babaram',
  babarao: 'babarão',
  babaras: 'babaras',
  babarei: 'babarei',
  babarem: 'babarem',
  babares: 'babares',
  babareu: 'babaréu',
  babaria: 'babaria',
  babarmo: 'babarmo',
  babassa: 'babassa',
  babasse: 'babasse',
  babaste: 'babaste',
  babatai: 'babatai',
  babatam: 'babatam',
  babatar: 'babatar',
  babatas: 'babatas',
  babatei: 'babatei',
  babatem: 'babatem',
  babates: 'babates',
  babatou: 'babatou',
  babauas: 'babauas',
  babauca: 'babaúca',
  babavam: 'babavam',
  babavas: 'babavas',
  babeada: 'babeada',
  babeado: 'babeado',
  babeais: 'babeais',
  babeara: 'babeará',
  babeava: 'babeava',
  babecas: 'babecas',
  babeces: 'babeces',
  babecos: 'babecos',
  babeeis: 'babeeis',
  babeiam: 'babeiam',
  babeias: 'babeias',
  babeiem: 'babeiem',
  babeies: 'babeies',
  babeira: 'babeira',
  babeiro: 'babeiro',
  babelas: 'babelas',
  babemos: 'babemos',
  babesia: 'babésia',
  babetes: 'babetes',
  babiana: 'babiana',
  babilha: 'babilha',
  babismo: 'babismo',
  babista: 'babista',
  babocas: 'babocas',
  babolia: 'baboliá',
  babonas: 'babonas',
  babongo: 'babongo',
  babordo: 'babordo',
  babores: 'baborés',
  babosas: 'babosas',
  babosea: 'baboseá',
  babosos: 'babosos',
  babucha: 'babucha',
  babuche: 'babuche',
  babucos: 'babucos',
  babuero: 'babuero',
  babugem: 'babugem',
  babuges: 'babuges',
  babuino: 'babuíno',
  babujai: 'babujai',
  babujam: 'babujam',
  babujar: 'babujar',
  babujas: 'babujas',
  babujei: 'babujei',
  babujem: 'babujem',
  babujes: 'babujes',
  babujou: 'babujou',
  babumas: 'babumas',
  babunda: 'babunda',
  babunha: 'babunha',
  babuzai: 'babuzai',
  babuzam: 'babuzam',
  babuzar: 'babuzar',
  babuzas: 'babuzas',
  babuzei: 'babuzei',
  babuzem: 'babuzem',
  babuzes: 'babuzes',
  babuzou: 'babuzou',
  bacaais: 'bacaaís',
  bacabai: 'bacabaí',
  bacabal: 'bacabal',
  bacabao: 'bacabão',
  bacabas: 'bacabas',
  bacacas: 'bacacas',
  bacacea: 'bacácea',
  bacaceo: 'bacáceo',
  bacacua: 'bacacuá',
  bacacus: 'bacacus',
  bacadas: 'bacadas',
  bacairi: 'bacairi',
  bacalai: 'bacalai',
  bacalar: 'bacalar',
  bacales: 'bacalés',
  bacalio: 'bacálio',
  bacamas: 'bacamas',
  bacamba: 'bacamba',
  bacanal: 'bacanal',
  bacanas: 'bacanas',
  bacanea: 'bacânea',
  bacaneo: 'bacâneo',
  bacango: 'bacango',
  bacanos: 'bacanos',
  bacante: 'bacante',
  bacarai: 'bacaraí',
  bacaras: 'bacarás',
  bacares: 'bacares',
  bacareu: 'bacaréu',
  bacarim: 'bacarim',
  bacaris: 'bácaris',
  bacaros: 'bacaros',
  bacasia: 'bacásia',
  bacazio: 'bacázio',
  bacazis: 'bacazis',
  baceira: 'baceira',
  baceiro: 'baceiro',
  bacelai: 'bacelai',
  bacelam: 'bacelam',
  bacelar: 'bacelar',
  bacelas: 'bacelas',
  bacelei: 'bacelei',
  bacelem: 'bacelem',
  baceles: 'baceles',
  bacelia: 'bacelia',
  bacelos: 'bacelos',
  bacelou: 'bacelou',
  bacento: 'bacento',
  bacetas: 'bacetas',
  bachata: 'bachata',
  bachica: 'bachica',
  bachico: 'bachico',
  bachine: 'bachiné',
  bachoes: 'bachões',
  bachope: 'bachope',
  bachuca: 'bachucá',
  bachuco: 'bachuco',
  baciada: 'baciada',
  baciado: 'baciado',
  baciais: 'baciais',
  baciamo: 'baciamo',
  baciana: 'baciana',
  baciano: 'baciano',
  baciara: 'baciará',
  baciava: 'baciava',
  bacidez: 'bacidez',
  bacidia: 'bacídia',
  bacieis: 'bacieis',
  baciemo: 'baciemo',
  bacieta: 'bacieta',
  bacilar: 'bacilar',
  bacilas: 'bacilas',
  bacilos: 'bacilos',
  bacinho: 'bacinho',
  backups: 'backups',
  bacoani: 'bacoani',
  bacocas: 'bacocas',
  bacocos: 'bacocos',
  bacolas: 'bacolas',
  baconde: 'bacondê',
  bacongo: 'bacongo',
  bacopas: 'bacopas',
  bacoral: 'bacoral',
  bacoras: 'bácoras',
  bacorim: 'bacorim',
  bacoris: 'bacoris',
  bacoros: 'bácoros',
  bacotas: 'bacotas',
  bactrea: 'báctrea',
  bactrio: 'báctrio',
  bactris: 'báctris',
  bactros: 'bactros',
  bacuais: 'bacuaís',
  bacuara: 'bacuara',
  bacubai: 'bacubai',
  bacubas: 'bacubas',
  bacucus: 'bacucus',
  bacudas: 'bacudas',
  bacuejo: 'bacuejo',
  bacuens: 'bacuéns',
  bacuina: 'bacuína',
  baculas: 'báculas',
  baculos: 'báculos',
  bacumbu: 'bacumbu',
  bacundo: 'bacundo',
  bacuois: 'bacuóis',
  bacupas: 'bacupãs',
  bacupua: 'bacupuá',
  bacuras: 'bacuras',
  bacurau: 'bacurau',
  bacuris: 'bacuris',
  bacusso: 'bacusso',
  bacutos: 'bacutos',
  bacuvas: 'bacuvas',
  badagas: 'badagas',
  badajes: 'badajes',
  badajos: 'badajos',
  badalai: 'badalai',
  badalam: 'badalam',
  badalao: 'badalão',
  badalar: 'badalar',
  badalas: 'badalas',
  badalea: 'badaleá',
  badalei: 'badalei',
  badalem: 'badalem',
  badales: 'badales',
  badalho: 'badalhó',
  badalim: 'badalim',
  badalos: 'badalos',
  badalou: 'badalou',
  badames: 'badames',
  badamia: 'badâmia',
  badamos: 'badamos',
  badamus: 'badamus',
  badanai: 'badanai',
  badanal: 'badanal',
  badanam: 'badanam',
  badanar: 'badanar',
  badanas: 'badanas',
  badanau: 'badanau',
  badanei: 'badanei',
  badanem: 'badanem',
  badanes: 'badanes',
  badania: 'badania',
  badanou: 'badanou',
  badante: 'badante',
  badaroa: 'badaroa',
  badeixo: 'badeixo',
  badejas: 'badejas',
  badejos: 'badejos',
  badelas: 'badelas',
  badelea: 'badeleá',
  badeles: 'badeles',
  badelos: 'badelos',
  baderna: 'baderna',
  baderne: 'baderne',
  baderno: 'baderno',
  badiana: 'badiana',
  badicas: 'badicas',
  badicos: 'badicos',
  badiera: 'badiera',
  badigos: 'badigós',
  badines: 'badines',
  badinga: 'badinga',
  badioes: 'badiões',
  badista: 'badista',
  badiste: 'badiste',
  badisto: 'badisto',
  badocho: 'badocho',
  badoens: 'badoéns',
  badofes: 'badofes',
  badogue: 'badogue',
  badolas: 'badolas',
  badonas: 'badonas',
  badoque: 'badoque',
  badorai: 'badorai',
  badoram: 'badoram',
  badorar: 'badorar',
  badoras: 'badoras',
  badorei: 'badorei',
  badorem: 'badorem',
  badores: 'badores',
  badorou: 'badorou',
  baducas: 'baducas',
  badulao: 'badulão',
  badusas: 'badusas',
  baelebe: 'baelebé',
  baetais: 'baetais',
  baetoes: 'baetões',
  baetona: 'baetona',
  baeunas: 'baeunas',
  bafadao: 'bafadão',
  bafadas: 'bafadas',
  bafador: 'bafador',
  bafados: 'bafados',
  bafafas: 'bafafás',
  bafagem: 'bafagem',
  bafamos: 'bafamos',
  bafando: 'bafando',
  bafante: 'bafante',
  bafaram: 'bafaram',
  bafarao: 'bafarão',
  bafaras: 'bafarás',
  bafarei: 'bafarei',
  bafarem: 'bafarem',
  bafares: 'bafares',
  bafaria: 'bafaria',
  bafaris: 'bafaris',
  bafarmo: 'bafarmo',
  bafassa: 'bafassá',
  bafasse: 'bafasse',
  bafaste: 'bafaste',
  bafavam: 'bafavam',
  bafavas: 'bafavas',
  bafejai: 'bafejai',
  bafejam: 'bafejam',
  bafejar: 'bafejar',
  bafejas: 'bafejas',
  bafejei: 'bafejei',
  bafejem: 'bafejem',
  bafejes: 'bafejes',
  bafejos: 'bafejos',
  bafejou: 'bafejou',
  bafemos: 'bafemos',
  bafetas: 'bafetás',
  bafiina: 'bafiina',
  bafiosa: 'bafiosa',
  bafioso: 'bafioso',
  bafoira: 'bafoira',
  baforai: 'baforai',
  baforam: 'baforam',
  baforao: 'baforão',
  baforar: 'baforar',
  baforas: 'baforas',
  baforda: 'baforda',
  baforde: 'baforde',
  bafordo: 'bafordo',
  baforei: 'baforei',
  baforem: 'baforem',
  bafores: 'bafores',
  baforou: 'baforou',
  bafoura: 'bafoura',
  bafugem: 'bafugem',
  bafuges: 'bafuges',
  bafumes: 'bafumes',
  bafunta: 'bafunta',
  bafunte: 'bafunte',
  bafunto: 'bafunto',
  bafurda: 'bafurdá',
  bafurde: 'bafurde',
  bafurdo: 'bafurdo',
  bagacal: 'bagaçal',
  bagacao: 'bagação',
  bagacas: 'bagaças',
  bagacos: 'bagaços',
  bagadao: 'bagadão',
  bagadas: 'bagadas',
  bagador: 'bagador',
  bagados: 'bagados',
  bagadus: 'bagadus',
  bagagem: 'bagagem',
  bagalas: 'bagalas',
  bagalho: 'bagalho',
  bagamos: 'bagamos',
  baganas: 'baganas',
  baganco: 'baganço',
  baganda: 'baganda',
  bagando: 'bagando',
  baganha: 'baganha',
  baganho: 'baganho',
  bagante: 'bagante',
  bagaram: 'bagaram',
  bagarao: 'bagarão',
  bagaras: 'bagaras',
  bagarei: 'bagarei',
  bagarem: 'bagarem',
  bagares: 'bagares',
  bagaria: 'bagaria',
  bagarim: 'bagarim',
  bagarmi: 'bagarmi',
  bagasse: 'bagasse',
  bagaste: 'bagaste',
  bagatas: 'bagatas',
  bagauda: 'bagauda',
  bagaudo: 'bagaudo',
  bagauri: 'bagauri',
  bagavam: 'bagavam',
  bagavas: 'bagavas',
  bagaxas: 'bagaxas',
  bagdali: 'bagdali',
  bageada: 'bageada',
  bageado: 'bageado',
  bageais: 'bageais',
  bageara: 'bageará',
  bageava: 'bageava',
  bageeis: 'bageeis',
  bageiam: 'bageiam',
  bageias: 'bageias',
  bageiem: 'bageiem',
  bageies: 'bageies',
  bageira: 'bageira',
  bageiro: 'bageiro',
  bageris: 'bageris',
  bagiros: 'bagiros',
  baglama: 'baglama',
  bagoada: 'bagoada',
  bagoado: 'bagoado',
  bagoais: 'bagoais',
  bagoamo: 'bagoamo',
  bagoara: 'bagoará',
  bagoava: 'bagoava',
  bagobos: 'bagobos',
  bagocho: 'bagocho',
  bagoeis: 'bagoeis',
  bagoemo: 'bagoemo',
  bagresa: 'bagresa',
  baguaca: 'baguaça',
  baguacu: 'baguaçu',
  baguais: 'baguais',
  baguala: 'baguala',
  baguari: 'baguari',
  bagudas: 'bagudas',
  bagudos: 'bagudos',
  bagueai: 'bagueai',
  baguear: 'baguear',
  bagueei: 'bagueei',
  bagueia: 'bagueia',
  bagueie: 'bagueie',
  bagueio: 'bagueio',
  bagueis: 'bagueis',
  bagueou: 'bagueou',
  baguera: 'baguera',
  bagueta: 'bagueta',
  baguete: 'baguete',
  baguias: 'baguiás',
  baguins: 'baguins',
  baguios: 'baguiós',
  baguixe: 'baguixe',
  bagulho: 'bagulho',
  bagumes: 'bagumes',
  bagunca: 'bagunça',
  bagunce: 'bagunce',
  bagunco: 'bagunço',
  baguris: 'baguris',
  baiacas: 'baiacas',
  baiacos: 'baiacos',
  baiacus: 'baiacus',
  baiadao: 'baiadão',
  baiadas: 'baiadas',
  baiador: 'baiador',
  baiados: 'baiados',
  baiagus: 'baiagus',
  baiaias: 'baiaiás',
  baiamos: 'baiamos',
  baianal: 'baianal',
  baianas: 'baianas',
  baianca: 'baianca',
  baianco: 'baianço',
  baiando: 'baiando',
  baianim: 'baianim',
  baianis: 'baiânis',
  baianos: 'baianos',
  baiante: 'baiante',
  baiapua: 'baiapua',
  baiaram: 'baiaram',
  baiarao: 'baiarão',
  baiaras: 'baiarás',
  baiardo: 'baiardo',
  baiarei: 'baiarei',
  baiarem: 'baiarem',
  baiares: 'baiares',
  baiaria: 'baiaria',
  baiarmo: 'baiarmo',
  baiasse: 'baiasse',
  baiaste: 'baiaste',
  baiavam: 'baiavam',
  baiavas: 'baiavas',
  baibiri: 'baibiri',
  baicada: 'baicada',
  baicado: 'baicado',
  baicais: 'baicais',
  baicamo: 'baicamo',
  baicara: 'baicara',
  baicari: 'baicari',
  baicava: 'baicava',
  baicuri: 'baicuri',
  baicuro: 'baicuró',
  baicuru: 'baicuru',
  baieies: 'baieiés',
  baieira: 'baieira',
  baieiro: 'baieiro',
  baiemos: 'baiemos',
  baiense: 'baiense',
  baietes: 'baietes',
  baifino: 'baifino',
  bailada: 'bailada',
  bailado: 'bailado',
  bailais: 'bailais',
  bailamo: 'bailamo',
  bailara: 'bailara',
  bailata: 'bailata',
  bailava: 'bailava',
  baileco: 'baileco',
  baileia: 'baileia',
  baileis: 'baileis',
  bailemo: 'bailemo',
  bailete: 'bailete',
  baileus: 'bailéus',
  baileya: 'bailéya',
  bailhai: 'bailhai',
  bailham: 'bailham',
  bailhao: 'bailhão',
  bailhar: 'bailhar',
  bailhas: 'bailhas',
  bailhei: 'bailhei',
  bailhem: 'bailhem',
  bailhes: 'bailhes',
  bailhos: 'bailhos',
  bailhou: 'bailhou',
  bailias: 'bailias',
  bailios: 'bailios',
  bailoes: 'bailões',
  bainhai: 'bainhai',
  bainham: 'bainham',
  bainhar: 'bainhar',
  bainhas: 'bainhas',
  bainhei: 'bainhei',
  bainhem: 'bainhem',
  bainhes: 'bainhes',
  bainhou: 'bainhou',
  bainica: 'bainica',
  bainita: 'bainita',
  baionas: 'baionas',
  baiones: 'baionês',
  baiotes: 'baiotes',
  baipiri: 'baipiri',
  baiquei: 'baiquei',
  baiquem: 'baiquem',
  baiques: 'baiques',
  bairari: 'bairari',
  bairiri: 'bairiri',
  bairoes: 'bairões',
  bairrao: 'bairrão',
  bairros: 'bairros',
  baitaca: 'baitaca',
  baitaco: 'baitaco',
  baitata: 'baitatá',
  baitola: 'baitola',
  baitolo: 'baitolo',
  baiucal: 'baiucal',
  baiucas: 'baiucas',
  baixada: 'baixada',
  baixado: 'baixado',
  baixais: 'baixais',
  baixamo: 'baixamo',
  baixara: 'baixara',
  baixate: 'baixate',
  baixava: 'baixava',
  baixeis: 'baixeis',
  baixela: 'baixela',
  baixemo: 'baixemo',
  baixete: 'baixete',
  baixeza: 'baixeza',
  baixias: 'baixias',
  baixios: 'baixios',
  baixoes: 'baixões',
  baixote: 'baixote',
  baixura: 'baixura',
  bajacus: 'bajacus',
  bajadao: 'bajadão',
  bajadas: 'bajadas',
  bajador: 'bajador',
  bajados: 'bajados',
  bajamos: 'bajamos',
  bajanas: 'bajanas',
  bajanco: 'bajanco',
  bajando: 'bajando',
  bajanos: 'bajanos',
  bajante: 'bajante',
  bajaram: 'bajaram',
  bajarao: 'bajarão',
  bajaras: 'bajaras',
  bajarei: 'bajarei',
  bajarem: 'bajarem',
  bajares: 'bajares',
  bajaria: 'bajaria',
  bajarmo: 'bajarmo',
  bajasse: 'bajasse',
  bajaste: 'bajaste',
  bajavam: 'bajavam',
  bajavas: 'bajavas',
  bajeada: 'bajeada',
  bajeado: 'bajeado',
  bajeais: 'bajeais',
  bajeara: 'bajeará',
  bajeava: 'bajeava',
  bajecas: 'bajecas',
  bajeeis: 'bajeeis',
  bajeiam: 'bajeiam',
  bajeias: 'bajeias',
  bajeiem: 'bajeiem',
  bajeies: 'bajeies',
  bajemos: 'bajemos',
  bajeres: 'bajerês',
  bajesto: 'bajesto',
  bajetos: 'bajetos',
  bajirus: 'bajirus',
  bajocos: 'bajocos',
  bajogai: 'bajogai',
  bajogam: 'bajogam',
  bajogar: 'bajogar',
  bajogas: 'bajogas',
  bajogou: 'bajogou',
  bajogue: 'bajogue',
  bajouja: 'bajouja',
  bajouje: 'bajouje',
  bajoujo: 'bajoujo',
  bajoulo: 'bajoulo',
  bajouta: 'bajouta',
  bajudas: 'bajudas',
  bajudes: 'bajudes',
  bajulai: 'bajulai',
  bajulam: 'bajulam',
  bajular: 'bajular',
  bajulas: 'bajulas',
  bajulei: 'bajulei',
  bajulem: 'bajulem',
  bajules: 'bajules',
  bajulia: 'bajulia',
  bajulos: 'bájulos',
  bajulou: 'bajulou',
  bajunca: 'bajunça',
  bajuris: 'bajuris',
  balacas: 'balacas',
  balache: 'balache',
  balacos: 'balaços',
  baladao: 'baladão',
  baladas: 'baladas',
  balador: 'balador',
  balados: 'balados',
  balafao: 'balafão',
  balafom: 'balafom',
  balafos: 'balafós',
  balaias: 'balaias',
  balaios: 'balaios',
  balalao: 'balalão',
  balalas: 'balalas',
  balames: 'balames',
  balamos: 'balamos',
  balanas: 'bálanas',
  balanca: 'balança',
  balance: 'balance',
  balanco: 'balanço',
  balando: 'balando',
  balanga: 'balanga',
  balango: 'balango',
  balanos: 'bálanos',
  balanta: 'balanta',
  balante: 'balante',
  balanto: 'balanto',
  balaram: 'balaram',
  balarao: 'balarão',
  balarem: 'balarem',
  balaria: 'balária',
  balaros: 'bálaros',
  balasco: 'balasco',
  balasse: 'balasse',
  balasto: 'balasto',
  balatal: 'balatal',
  balatas: 'balatas',
  balatos: 'balatos',
  balatro: 'balatro',
  balavam: 'balavam',
  balaxes: 'balaxes',
  balazio: 'balázio',
  balboas: 'balboas',
  balbuta: 'balbuta',
  balbute: 'balbute',
  balbuti: 'balbuti',
  balbuto: 'balbuto',
  balcedo: 'balcedo',
  balcoes: 'balcões',
  baldada: 'baldada',
  baldado: 'baldado',
  baldais: 'baldais',
  baldamo: 'baldamo',
  baldara: 'baldará',
  baldava: 'baldava',
  baldeai: 'baldeai',
  baldear: 'baldear',
  baldeei: 'baldeei',
  baldeia: 'baldeia',
  baldeie: 'baldeie',
  baldeio: 'baldeio',
  baldeis: 'baldeis',
  baldemo: 'baldemo',
  baldeou: 'baldeou',
  baldias: 'baldias',
  baldios: 'baldios',
  baldoai: 'baldoai',
  baldoam: 'baldoam',
  baldoar: 'baldoar',
  baldoas: 'baldoas',
  baldoei: 'baldoei',
  baldoem: 'baldoem',
  baldoes: 'baldões',
  baldoou: 'baldoou',
  baldosa: 'baldosa',
  baldoso: 'baldoso',
  baldreu: 'baldréu',
  baleada: 'baleada',
  baleado: 'baleado',
  baleais: 'baleais',
  baleamo: 'baleamo',
  baleara: 'baleara',
  baleato: 'baleato',
  baleava: 'baleava',
  balecai: 'balecai',
  balecam: 'balecam',
  balecar: 'balecar',
  balecas: 'balecas',
  balecou: 'balecou',
  baleeis: 'baleeis',
  baleemo: 'baleemo',
  balegao: 'balegão',
  baleiam: 'baleiam',
  baleias: 'baleias',
  baleiem: 'baleiem',
  baleies: 'baleies',
  baleira: 'baleira',
  baleiro: 'baleiro',
  balelas: 'balelas',
  balemas: 'balemas',
  balempa: 'balempa',
  balenga: 'balenga',
  balense: 'balense',
  baleota: 'baleota',
  baleote: 'baleote',
  baleque: 'baleque',
  balesta: 'balesta',
  baleste: 'baleste',
  balesto: 'balesto',
  balhada: 'balhada',
  balhado: 'balhado',
  balhais: 'balhais',
  balhamo: 'balhamo',
  balhana: 'balhana',
  balhara: 'balhara',
  balhata: 'balhata',
  balhaus: 'balhaus',
  balhava: 'balhava',
  balheis: 'balheis',
  balhemo: 'balhemo',
  balhoes: 'balhões',
  balhote: 'balhote',
  baliada: 'baliada',
  baliado: 'baliado',
  baliana: 'baliana',
  balidao: 'balidão',
  balidas: 'balidas',
  balidor: 'balidor',
  balidos: 'balidos',
  balieis: 'balíeis',
  balilas: 'balilas',
  balilis: 'balilis',
  balimos: 'balimos',
  balindo: 'balindo',
  balines: 'balinês',
  balinha: 'balinha',
  baliram: 'baliram',
  balirao: 'balirão',
  baliras: 'baliras',
  balirei: 'balirei',
  balirem: 'balirem',
  balires: 'balires',
  baliria: 'baliria',
  balirmo: 'balirmo',
  balismo: 'balismo',
  balissa: 'balissa',
  balisse: 'balisse',
  balista: 'balista',
  baliste: 'baliste',
  balizai: 'balizai',
  balizam: 'balizam',
  balizar: 'balizar',
  balizas: 'balizas',
  balizei: 'balizei',
  balizem: 'balizem',
  balizes: 'balizes',
  balizou: 'balizou',
  balneai: 'balneai',
  balnear: 'balnear',
  balneas: 'bálneas',
  balneei: 'balneei',
  balneia: 'balneia',
  balneie: 'balneie',
  balneio: 'balneio',
  balneos: 'bálneos',
  balneou: 'balneou',
  baloada: 'baloada',
  baloado: 'baloado',
  baloais: 'baloais',
  baloamo: 'baloamo',
  baloana: 'baloana',
  baloara: 'baloará',
  baloava: 'baloava',
  balobas: 'balobas',
  baloche: 'baloche',
  baloeis: 'baloeis',
  baloemo: 'baloemo',
  balofas: 'balofas',
  balofos: 'balofos',
  balogum: 'balogum',
  baloica: 'baloiça',
  baloice: 'baloice',
  baloico: 'baloiço',
  baloiso: 'baloiso',
  balonas: 'balonas',
  balonda: 'balonda',
  balordo: 'balordo',
  balotai: 'balotai',
  balotam: 'balotam',
  balotar: 'balotar',
  balotas: 'balotas',
  balotei: 'balotei',
  balotem: 'balotem',
  balotes: 'balotes',
  balotou: 'balotou',
  balouba: 'balouba',
  balouca: 'balouça',
  balouce: 'balouce',
  balouco: 'balouço',
  balroai: 'balroai',
  balroam: 'balroam',
  balroar: 'balroar',
  balroas: 'balroas',
  balroei: 'balroei',
  balroem: 'balroem',
  balroes: 'balroes',
  balroou: 'balroou',
  balsada: 'balsada',
  balsado: 'balsado',
  balsais: 'balsais',
  balsama: 'balsama',
  balsame: 'balsame',
  balsamo: 'bálsamo',
  balsana: 'balsana',
  balsara: 'balsara',
  balsava: 'balsava',
  balsear: 'balsear',
  balsedo: 'balsedo',
  balseis: 'balseis',
  balsemo: 'balsemo',
  balsero: 'balsero',
  balsoes: 'balsões',
  balsona: 'balsona',
  balteas: 'bálteas',
  balteos: 'bálteos',
  baltica: 'báltica',
  baltico: 'báltico',
  balubas: 'balubas',
  baluchi: 'balúchi',
  balucre: 'balucre',
  baludas: 'baludas',
  baludos: 'baludos',
  baluena: 'baluena',
  balugao: 'balugão',
  balugas: 'balugas',
  balulas: 'balulas',
  balules: 'balules',
  balumas: 'balumas',
  balundo: 'balundo',
  balurde: 'balurde',
  balurdo: 'balurdo',
  balzaca: 'balzaca',
  bambada: 'bambada',
  bambado: 'bambado',
  bambaes: 'bambaês',
  bambais: 'bambais',
  bambala: 'bambala',
  bambale: 'bambale',
  bambamo: 'bambamo',
  bambara: 'bambara',
  bambare: 'bambaré',
  bambava: 'bambava',
  bambeai: 'bambeai',
  bambear: 'bambear',
  bambeei: 'bambeei',
  bambeia: 'bambeia',
  bambeie: 'bambeie',
  bambeio: 'bambeio',
  bambeis: 'bambeis',
  bambelo: 'bambelô',
  bambemo: 'bambemo',
  bambeou: 'bambeou',
  bambera: 'bambera',
  bambeza: 'bambeza',
  bambias: 'bambiás',
  bambina: 'bambina',
  bambine: 'bambine',
  bambino: 'bambino',
  bamboai: 'bamboai',
  bamboam: 'bamboam',
  bamboao: 'bamboão',
  bamboar: 'bamboar',
  bamboas: 'bamboas',
  bamboei: 'bamboei',
  bamboem: 'bamboem',
  bamboes: 'bamboes',
  bambole: 'bambolê',
  bamboou: 'bamboou',
  bambore: 'bamboré',
  bambual: 'bambual',
  bambuco: 'bambuco',
  bambuda: 'bambuda',
  bambudo: 'bambudo',
  bambula: 'bambula',
  bambuns: 'bambuns',
  bambura: 'bambura',
  bambusa: 'bambusa',
  bambuto: 'bambuto',
  bampora: 'bamporã',
  banacas: 'banacas',
  banalia: 'banália',
  banamos: 'banamos',
  bananal: 'bananal',
  bananao: 'bananão',
  bananas: 'bananas',
  bananos: 'bananos',
  banaras: 'banaras',
  banares: 'banares',
  banaros: 'banaros',
  banatos: 'banatos',
  bancada: 'bancada',
  bancado: 'bancado',
  bancais: 'bancais',
  bancale: 'bancalé',
  bancamo: 'bancamo',
  bancara: 'bancara',
  bancava: 'bancava',
  bancios: 'bâncios',
  bancoes: 'bancões',
  bancudu: 'bancudu',
  bandada: 'bandada',
  bandado: 'bandado',
  bandage: 'bandage',
  bandais: 'bandais',
  bandala: 'bandala',
  bandamo: 'bandamo',
  bandana: 'bandana',
  bandara: 'bandara',
  bandari: 'bandari',
  bandava: 'bandava',
  bandaze: 'bandaze',
  bandeai: 'bandeai',
  bandear: 'bandear',
  bandeei: 'bandeei',
  bandeia: 'bandeia',
  bandeie: 'bandeie',
  bandeio: 'bandeio',
  bandeis: 'bandeis',
  bandeja: 'bandeja',
  bandeje: 'bandeje',
  bandejo: 'bandejo',
  bandemo: 'bandemo',
  bandeou: 'bandeou',
  bandeta: 'bandeta',
  bandida: 'bandida',
  bandido: 'bandido',
  bandins: 'bandins',
  bandiva: 'bandiva',
  bandoai: 'bandoai',
  bandoam: 'bandoam',
  bandoar: 'bandoar',
  bandoas: 'bandoas',
  bandoba: 'bandoba',
  bandoei: 'bandoei',
  bandoem: 'bandoem',
  bandoes: 'bandões',
  bandoga: 'bandoga',
  bandola: 'bandola',
  bandoou: 'bandoou',
  bandora: 'bandora',
  bandras: 'bandrás',
  baneane: 'baneane',
  banfumo: 'banfumo',
  bangala: 'bangala',
  bangale: 'bangalé',
  bangalo: 'bangalô',
  bangaos: 'bangãos',
  bangbas: 'bangbas',
  bangial: 'bangial',
  bangias: 'bângias',
  bangola: 'bangola',
  bangole: 'bangole',
  bangolo: 'bangolo',
  bangues: 'bangués',
  banguia: 'banguiá',
  bangula: 'bangula',
  bangule: 'bangulê',
  bangulo: 'bangulo',
  banhada: 'banhada',
  banhado: 'banhado',
  banhais: 'banhais',
  banhamo: 'banhamo',
  banhani: 'banhâni',
  banhara: 'banhara',
  banhava: 'banhava',
  banheis: 'banheis',
  banhema: 'banhema',
  banhemo: 'banhemo',
  banhuns: 'banhuns',
  baniana: 'baniana',
  baniane: 'baniane',
  baniano: 'baniano',
  baniara: 'baniara',
  banibas: 'banibas',
  banicao: 'banição',
  banidao: 'banidão',
  banidas: 'banidas',
  banidor: 'banidor',
  banidos: 'banidos',
  banieis: 'baníeis',
  banimos: 'banimos',
  banindo: 'banindo',
  baninis: 'baninis',
  baniram: 'baniram',
  banirao: 'banirão',
  baniras: 'baniras',
  banirei: 'banirei',
  banirem: 'banirem',
  banires: 'banires',
  baniria: 'baniria',
  banirmo: 'banirmo',
  banisse: 'banisse',
  baniste: 'baniste',
  baniuas: 'baniuas',
  banivas: 'banivas',
  banivel: 'banível',
  banjura: 'banjura',
  banquei: 'banquei',
  banquem: 'banquem',
  banques: 'banques',
  bantaba: 'bantabá',
  bantais: 'bantais',
  bantina: 'bantina',
  bantino: 'bantino',
  bantins: 'bantins',
  banzada: 'banzada',
  banzado: 'banzado',
  banzais: 'banzais',
  banzamo: 'banzamo',
  banzara: 'bânzara',
  banzava: 'banzava',
  banzeai: 'banzeai',
  banzear: 'banzear',
  banzeei: 'banzeei',
  banzeia: 'banzeia',
  banzeie: 'banzeie',
  banzeio: 'banzeio',
  banzeis: 'banzeis',
  banzemo: 'banzemo',
  banzeou: 'banzeou',
  banzera: 'bânzera',
  banziri: 'banziri',
  banzoes: 'banzões',
  baobabe: 'baobabe',
  baonesa: 'baonesa',
  baoneta: 'baoneta',
  baotita: 'baotita',
  bapebas: 'bapebas',
  bapeira: 'bapeira',
  bapende: 'bapende',
  bapevas: 'bapevas',
  bapiana: 'bapianá',
  baptina: 'baptina',
  baptiza: 'baptiza',
  baptize: 'baptize',
  baptizo: 'baptizo',
  bapuana: 'bapuana',
  baquani: 'baquani',
  baquara: 'baquara',
  baqueai: 'baqueai',
  baquear: 'baquear',
  baqueei: 'baqueei',
  baqueia: 'baqueia',
  baqueie: 'baqueie',
  baqueio: 'baqueio',
  baqueja: 'baqueja',
  baqueje: 'baqueje',
  baquejo: 'baquejo',
  baquelo: 'baquelo',
  baqueou: 'baqueou',
  baqueta: 'baqueta',
  baquete: 'baquete',
  baqueti: 'baqueti',
  baqueto: 'baqueto',
  baquica: 'báquica',
  baquice: 'baquice',
  baquico: 'báquico',
  baquide: 'báquide',
  baquina: 'baquina',
  baquios: 'báquios',
  baquite: 'baquité',
  baquiti: 'baquiti',
  barabas: 'barabas',
  barabos: 'barabôs',
  barabus: 'barabus',
  baracao: 'baração',
  baracas: 'baraças',
  baraceu: 'baracéu',
  baracha: 'baracha',
  barache: 'barache',
  baracho: 'baracho',
  barachu: 'barachu',
  baracos: 'baraços',
  baracui: 'baracuí',
  baradas: 'baradas',
  baradel: 'baradel',
  baraias: 'baraias',
  barajos: 'barajos',
  baralha: 'baralha',
  baralhe: 'baralhe',
  baralho: 'baralho',
  baramas: 'baramas',
  barambo: 'barambo',
  baranga: 'baranga',
  baranha: 'baranha',
  baranho: 'baranho',
  baranto: 'baranto',
  barapos: 'barapos',
  bararas: 'bararás',
  bararau: 'bararau',
  barares: 'barares',
  bararua: 'bararuá',
  baratai: 'baratai',
  baratam: 'baratam',
  baratao: 'baratão',
  baratar: 'baratar',
  baratas: 'baratas',
  baratea: 'barateá',
  baratei: 'baratei',
  baratem: 'baratem',
  barates: 'barates',
  baratim: 'baratim',
  baratos: 'baratos',
  baratou: 'baratou',
  baratro: 'báratro',
  barauna: 'baraúna',
  barauva: 'baraúva',
  baraxus: 'baraxus',
  barbaca: 'barbacã',
  barbaco: 'barbaço',
  barbada: 'barbada',
  barbado: 'barbado',
  barbais: 'barbais',
  barbamo: 'barbamo',
  barbana: 'barbana',
  barbara: 'bárbara',
  barbaro: 'bárbaro',
  barbata: 'barbata',
  barbate: 'barbate',
  barbato: 'barbato',
  barbava: 'barbava',
  barbeai: 'barbeai',
  barbear: 'barbear',
  barbeei: 'barbeei',
  barbeia: 'barbeia',
  barbeie: 'barbeie',
  barbeio: 'barbeio',
  barbeis: 'barbeis',
  barbela: 'barbela',
  barbele: 'barbele',
  barbelo: 'barbelo',
  barbemo: 'barbemo',
  barbena: 'barbena',
  barbeou: 'barbeou',
  barbeta: 'barbeta',
  barbete: 'barbete',
  barbiao: 'barbião',
  barbica: 'barbica',
  barbiel: 'barbiel',
  barbino: 'barbino',
  barbito: 'bárbito',
  barboai: 'barboai',
  barboam: 'barboam',
  barboar: 'barboar',
  barboas: 'barboas',
  barboei: 'barboei',
  barboem: 'barboem',
  barboes: 'barboes',
  barbona: 'barbona',
  barbono: 'barbono',
  barboou: 'barboou',
  barbota: 'barbota',
  barbote: 'barbote',
  barboto: 'barboto',
  barbuda: 'barbuda',
  barbudo: 'barbudo',
  barbula: 'bárbula',
  barbuna: 'barbuna',
  barbuta: 'barbuta',
  barcaca: 'barcaça',
  barcada: 'barcada',
  barcana: 'barcana',
  barcane: 'barcane',
  barcano: 'barcano',
  barcego: 'barcego',
  barceia: 'barceia',
  barcelo: 'barcelo',
  barcena: 'barcena',
  barceus: 'barceus',
  barchim: 'barchim',
  barcida: 'bárcida',
  barcino: 'barcino',
  barcote: 'barcote',
  bardada: 'bardada',
  bardado: 'bardado',
  bardais: 'bardais',
  bardamo: 'bardamo',
  bardana: 'bardana',
  bardara: 'bardara',
  bardava: 'bardava',
  bardaxa: 'bardaxa',
  bardeas: 'bárdeas',
  bardeia: 'bardeia',
  bardeis: 'bardeis',
  bardemo: 'bardemo',
  bardeus: 'bardeus',
  bardias: 'bardias',
  bardica: 'bárdica',
  bardico: 'bárdico',
  bardino: 'bardino',
  bardito: 'bardito',
  bardoes: 'bardões',
  bardoto: 'bardoto',
  bardulo: 'bárdulo',
  bareges: 'bareges',
  bareias: 'bareias',
  barelas: 'barelas',
  baremas: 'baremas',
  baremes: 'baremês',
  baremio: 'barêmio',
  baremos: 'baremos',
  baretas: 'baretas',
  barexas: 'barexas',
  barfois: 'barfóis',
  bargada: 'bargada',
  bargado: 'bargado',
  bargani: 'bargani',
  bargela: 'bargela',
  baribas: 'baribas',
  baricas: 'báricas',
  baricos: 'báricos',
  barigas: 'barigas',
  barigui: 'barigui',
  barilas: 'barilas',
  barilha: 'barilha',
  barimbe: 'barimbé',
  barinel: 'barinel',
  barines: 'barines',
  baringa: 'baringa',
  barioes: 'bariões',
  bariome: 'bariome',
  barions: 'bárions',
  baripas: 'baripas',
  bariris: 'bariris',
  barista: 'barista',
  baritas: 'baritas',
  barites: 'barites',
  baritos: 'baritos',
  bariuns: 'báriuns',
  barjona: 'barjona',
  barlete: 'barlete',
  barline: 'barline',
  barnabe: 'barnabé',
  barneas: 'bárneas',
  baroada: 'baroada',
  baroado: 'baroado',
  baroces: 'baroces',
  barocos: 'barocos',
  barolos: 'barolos',
  baromas: 'baromas',
  baronal: 'baronal',
  baronas: 'baronas',
  barones: 'baronês',
  baronia: 'baronia',
  barosma: 'barosma',
  barquea: 'barqueá',
  barquel: 'barquel',
  barques: 'barquês',
  barraca: 'barraca',
  barraco: 'barraco',
  barrada: 'barrada',
  barrado: 'barrado',
  barrais: 'barrais',
  barramo: 'barramo',
  barrana: 'barrana',
  barrano: 'barrano',
  barrara: 'barrara',
  barrava: 'barrava',
  barraza: 'barraza',
  barreai: 'barreai',
  barrear: 'barrear',
  barreei: 'barreei',
  barrega: 'barregã',
  barrego: 'barrego',
  barreia: 'barreia',
  barreie: 'barreie',
  barreio: 'barreio',
  barreis: 'barreis',
  barreja: 'barrejá',
  barreje: 'barreje',
  barrejo: 'barrejo',
  barrela: 'barrela',
  barrelo: 'barrelo',
  barremo: 'barremo',
  barrena: 'barrena',
  barrene: 'barrene',
  barreno: 'barreno',
  barreou: 'barreou',
  barrera: 'barrera',
  barreta: 'barreta',
  barrete: 'barrete',
  barreto: 'barreto',
  barriam: 'barriam',
  barrias: 'barrias',
  barrica: 'barrica',
  barrico: 'barrico',
  barrida: 'barrida',
  barrido: 'barrido',
  barriga: 'barriga',
  barrira: 'barrira',
  barrito: 'barrito',
  barroai: 'barroai',
  barroam: 'barroam',
  barroar: 'barroar',
  barroas: 'barroas',
  barroca: 'barroca',
  barroco: 'barroco',
  barroei: 'barroei',
  barroem: 'barroem',
  barroes: 'barrões',
  barroou: 'barroou',
  barrosa: 'barrosa',
  barroso: 'barroso',
  barrota: 'barrota',
  barrote: 'barrote',
  barroto: 'barroto',
  barruco: 'barruço',
  barrufa: 'barrufa',
  barrufe: 'barrufe',
  barrufo: 'barrufo',
  barruma: 'barruma',
  barsins: 'barsins',
  bartsia: 'bártsia',
  barujos: 'barujos',
  barulha: 'barulha',
  barulhe: 'barulhe',
  barulho: 'barulho',
  baruria: 'baruria',
  barurus: 'barurus',
  barutos: 'barutos',
  barvade: 'barvade',
  barzabu: 'barzabu',
  barzois: 'barzóis',
  basadao: 'basadão',
  basadas: 'basadas',
  basador: 'basador',
  basados: 'basados',
  basalar: 'basalar',
  basalto: 'basalto',
  basamos: 'basamos',
  basanas: 'basanas',
  basando: 'basando',
  basanes: 'basanés',
  basango: 'basango',
  basante: 'basante',
  basaram: 'basaram',
  basarao: 'basarão',
  basaras: 'basarás',
  basarei: 'basarei',
  basarem: 'basarem',
  basares: 'basares',
  basareu: 'basaréu',
  basaria: 'basaria',
  basarmo: 'basarmo',
  basasse: 'basasse',
  basaste: 'basaste',
  basavam: 'basavam',
  basavas: 'basavas',
  basbana: 'basbana',
  bascama: 'bascama',
  bascame: 'bascame',
  bascamo: 'bascamo',
  bascula: 'báscula',
  bascule: 'bascule',
  basculo: 'básculo',
  baseada: 'baseada',
  baseado: 'baseado',
  baseais: 'baseais',
  baseamo: 'baseamo',
  baseara: 'baseara',
  baseava: 'baseava',
  basebol: 'basebol',
  baseeis: 'baseeis',
  baseemo: 'baseemo',
  baseiam: 'baseiam',
  baseias: 'baseias',
  baseiem: 'baseiem',
  baseies: 'baseies',
  baselas: 'baselas',
  baselga: 'baselga',
  basemos: 'basemos',
  basenga: 'basenga',
  basenji: 'basenji',
  basiais: 'basiais',
  basicao: 'basicão',
  basicas: 'básicas',
  basicos: 'básicos',
  basides: 'basides',
  basidio: 'basídio',
  basiial: 'basiial',
  basilar: 'basilar',
  basilas: 'basilas',
  basimba: 'basimba',
  basione: 'basione',
  basions: 'básions',
  basises: 'basises',
  basismo: 'basismo',
  basista: 'basista',
  basites: 'basites',
  basofas: 'basofas',
  basofia: 'basofia',
  basofie: 'basofie',
  basofio: 'basofio',
  basofos: 'basofos',
  basquir: 'basquir',
  bassari: 'bassari',
  bassias: 'bássias',
  bassopa: 'bassopa',
  bassula: 'bassula',
  bassuto: 'bassuto',
  bastada: 'bastada',
  bastado: 'bastado',
  bastais: 'bastais',
  bastamo: 'bastamo',
  bastara: 'bastara',
  bastava: 'bastava',
  basteai: 'basteai',
  bastear: 'bastear',
  basteca: 'basteça',
  bastece: 'bastece',
  basteci: 'basteci',
  basteco: 'basteço',
  basteei: 'basteei',
  basteia: 'basteia',
  basteie: 'basteie',
  basteio: 'basteio',
  basteis: 'basteis',
  bastemo: 'bastemo',
  basteou: 'basteou',
  basteza: 'basteza',
  bastiam: 'bastiam',
  bastiao: 'bastião',
  bastias: 'bastias',
  bastida: 'bastida',
  bastido: 'bastido',
  bastimo: 'bastimo',
  bastios: 'bastios',
  bastira: 'bastira',
  bastita: 'bastita',
  bastite: 'bastite',
  bastito: 'bastito',
  bastoes: 'bastões',
  bastose: 'bastose',
  bastulo: 'bástulo',
  bastura: 'bastura',
  basutos: 'basutos',
  batacao: 'batacão',
  batacas: 'batacas',
  batacea: 'batácea',
  bataceo: 'batáceo',
  batacla: 'bataclã',
  batador: 'batador',
  batagem: 'batagem',
  batages: 'batages',
  batalha: 'batalha',
  batalhe: 'batalhe',
  batalho: 'batalho',
  batalos: 'batalós',
  batalou: 'batalou',
  batamos: 'batamos',
  batanas: 'batanas',
  batanca: 'batanca',
  batanda: 'batanda',
  batanga: 'batanga',
  batanos: 'batanos',
  bataque: 'bataque',
  bataras: 'batarás',
  batarda: 'batarda',
  batardo: 'batardo',
  batareu: 'bataréu',
  bataria: 'bataria',
  bataros: 'bátaros',
  batatal: 'batatal',
  batatao: 'batatão',
  batatas: 'batatas',
  batates: 'batates',
  batauas: 'batauás',
  batauda: 'bataúda',
  batavas: 'batavas',
  batavia: 'batávia',
  batavio: 'batávio',
  batavos: 'batavos',
  batcara: 'batcará',
  bateada: 'bateada',
  bateado: 'bateado',
  bateais: 'bateais',
  bateara: 'bateará',
  bateava: 'bateava',
  batecao: 'bateção',
  batecar: 'batecar',
  batecas: 'batecas',
  batecol: 'batecol',
  batecum: 'batecum',
  batedor: 'batedor',
  bateeis: 'bateeis',
  bategai: 'bategai',
  bategam: 'bategam',
  bategar: 'bategar',
  bategas: 'bátegas',
  bategou: 'bategou',
  bateiai: 'bateiai',
  bateiam: 'bateiam',
  bateiar: 'bateiar',
  bateias: 'bateias',
  bateiei: 'bateiei',
  bateiem: 'bateiem',
  bateies: 'bateies',
  bateiou: 'bateiou',
  bateira: 'bateira',
  bateiro: 'bateiro',
  batelao: 'batelão',
  batelas: 'batelas',
  batelia: 'batélia',
  batelos: 'batelos',
  batemis: 'bátemis',
  batemos: 'batemos',
  batendo: 'batendo',
  batente: 'batente',
  bateram: 'bateram',
  baterao: 'baterão',
  bateras: 'bateras',
  baterei: 'baterei',
  baterem: 'baterem',
  bateres: 'bateres',
  bateria: 'bateria',
  baterio: 'batério',
  batermo: 'batermo',
  baterna: 'baterna',
  batesia: 'batésia',
  batesse: 'batesse',
  bateste: 'bateste',
  batetes: 'batetês',
  batiada: 'batíada',
  batiais: 'batiais',
  batiara: 'batiara',
  batibio: 'batíbio',
  baticao: 'batição',
  baticum: 'baticum',
  batidal: 'batidal',
  batidao: 'batidão',
  batidas: 'batidas',
  batidea: 'batídea',
  batideo: 'batídeo',
  batides: 'bátides',
  batidos: 'batidos',
  batieis: 'batíeis',
  batilha: 'batilha',
  batilhe: 'batilhe',
  batilho: 'batilho',
  batilol: 'batilol',
  batimos: 'batimos',
  batinas: 'batinas',
  batinga: 'batinga',
  batique: 'batique',
  batisas: 'batisas',
  batismo: 'batismo',
  batissa: 'batissa',
  batista: 'batista',
  batites: 'batités',
  batizai: 'batizai',
  batizam: 'batizam',
  batizar: 'batizar',
  batizas: 'batizas',
  batizei: 'batizei',
  batizem: 'batizem',
  batizes: 'batizes',
  batizou: 'batizou',
  batmica: 'bátmica',
  batmico: 'bátmico',
  batocai: 'batocai',
  batocam: 'batocam',
  batocar: 'batocar',
  batocas: 'batocas',
  batocou: 'batocou',
  batoide: 'batoide',
  batoilo: 'batoilo',
  batoiro: 'batoiro',
  batolas: 'batolas',
  batones: 'batones',
  batonga: 'batonga',
  batoque: 'batoque',
  batores: 'batorés',
  batoreu: 'batoréu',
  batosas: 'batosas',
  batotai: 'batotai',
  batotam: 'batotam',
  batotar: 'batotar',
  batotas: 'batotas',
  batotea: 'batoteá',
  batotei: 'batotei',
  batotem: 'batotem',
  batotes: 'batotes',
  batotou: 'batotou',
  batoulo: 'batoulo',
  batoura: 'batourá',
  batoure: 'batoure',
  batouro: 'batouro',
  batsuas: 'bátsuas',
  batucai: 'batucai',
  batucam: 'batucam',
  batucar: 'batucar',
  batucas: 'batucas',
  batucou: 'batucou',
  batudas: 'batudas',
  batuera: 'batuera',
  batuira: 'batuíra',
  batuque: 'batuque',
  batutas: 'batutas',
  bauacus: 'bauaçus',
  baualas: 'baualas',
  bauanas: 'bauanas',
  bauaris: 'bauaris',
  baubaus: 'baubaus',
  baucide: 'báucide',
  bauense: 'bauense',
  baueras: 'baueras',
  bauhaus: 'bauhaus',
  bauinia: 'bauinia',
  baulzao: 'baulzão',
  baumita: 'baumita',
  baurins: 'baurins',
  bautiza: 'bautiza',
  bautize: 'bautize',
  bautizo: 'bautizo',
  bauxita: 'bauxita',
  bauxite: 'bauxite',
  bauxito: 'bauxito',
  bavaras: 'bávaras',
  bavaros: 'bávaros',
  baveira: 'baveira',
  bavetes: 'bavetes',
  bavinas: 'bavinas',
  baxadas: 'baxadas',
  baxados: 'baxados',
  baxalia: 'baxalia',
  baxanas: 'baxanas',
  baxetes: 'baxetes',
  baxiara: 'baxiará',
  baxinxe: 'baxinxe',
  baxiuba: 'baxiúba',
  baxixas: 'baxixás',
  baxixes: 'baxixes',
  baxorum: 'baxorum',
  baxtera: 'baxtera',
  bazanas: 'bazanas',
  bazania: 'bazânia',
  bazares: 'bazares',
  bazemal: 'bazemal',
  bazofas: 'bazofas',
  bazofia: 'bazófia',
  bazofie: 'bazofie',
  bazofio: 'bazófio',
  bazofos: 'bazofos',
  bazucas: 'bazucas',
  bazzita: 'bazzita',
  bdelios: 'bdélios',
  beafada: 'beafada',
  beanias: 'beânias',
  beaquea: 'beaquea',
  beaqueo: 'beaqueo',
  bearnes: 'bearnês',
  beatica: 'beática',
  beatice: 'beatice',
  beatico: 'beático',
  beatles: 'beatles',
  beatnik: 'beatnik',
  beatoes: 'beatões',
  bebacas: 'bebaças',
  bebacos: 'bebaços',
  bebadas: 'bêbadas',
  bebados: 'bêbados',
  bebamos: 'bebamos',
  bebarro: 'bebarro',
  bebbias: 'bébbias',
  bebearu: 'bebearu',
  bebedas: 'bêbedas',
  bebedor: 'bebedor',
  bebedos: 'bêbedos',
  bebeeru: 'bebeeru',
  bebelis: 'bebélis',
  bebemos: 'bebemos',
  bebenas: 'bebenas',
  bebendo: 'bebendo',
  beberai: 'beberai',
  beberam: 'beberam',
  beberao: 'beberão',
  beberar: 'beberar',
  beberas: 'beberas',
  beberei: 'beberei',
  beberem: 'beberem',
  beberes: 'beberes',
  beberia: 'beberia',
  bebermo: 'bebermo',
  beberou: 'beberou',
  beberus: 'beberus',
  bebesse: 'bebesse',
  bebeste: 'bebeste',
  bebesto: 'bebesto',
  bebezao: 'bebezão',
  bebiana: 'bebiana',
  bebiano: 'bebiano',
  bebicas: 'bebicas',
  bebidao: 'bebidão',
  bebidas: 'bebidas',
  bebidos: 'bebidos',
  bebieis: 'bebíeis',
  bebinca: 'bebinca',
  bebinga: 'bebinga',
  bebinha: 'bebinha',
  bebinho: 'bebinho',
  bebirus: 'bebirus',
  bebivel: 'bebível',
  bebrice: 'bébrice',
  bebudas: 'bebudas',
  bebudos: 'bebudos',
  becapai: 'becapai',
  becapam: 'becapam',
  becapar: 'becapar',
  becapas: 'becapas',
  becapei: 'becapei',
  becapem: 'becapem',
  becapes: 'becapes',
  becapou: 'becapou',
  becuiba: 'becuíba',
  beculas: 'béculas',
  becunas: 'becunas',
  bedalco: 'bedalço',
  bedalha: 'bedalha',
  bedames: 'bedames',
  bedeada: 'bedeada',
  bedeado: 'bedeado',
  bedeais: 'bedeais',
  bedeara: 'bedeara',
  bedeava: 'bedeava',
  bedeeis: 'bedeeis',
  bedegar: 'bedegar',
  bedeiam: 'bedeiam',
  bedeias: 'bedeias',
  bedeiem: 'bedeiem',
  bedeies: 'bedeies',
  bedelas: 'bedelas',
  bedelha: 'bedelha',
  bedelhe: 'bedelhe',
  bedelho: 'bedelho',
  bedelia: 'bedelia',
  bedelio: 'bedélio',
  bedengo: 'bedengó',
  bedioes: 'bediões',
  bedoira: 'bedoira',
  beduina: 'beduína',
  beduino: 'beduíno',
  beduins: 'beduins',
  bedulha: 'bedulha',
  bedulho: 'bedulho',
  bedulos: 'bédulos',
  bedusis: 'bedúsis',
  beemote: 'beemote',
  beeneis: 'beenéis',
  beerita: 'beerita',
  beerite: 'beerite',
  beetria: 'beetria',
  befamis: 'befamis',
  befaria: 'befária',
  befecio: 'befécio',
  befenio: 'befênio',
  begardo: 'begardo',
  begarim: 'begarim',
  begonia: 'begônia',
  beguaba: 'beguaba',
  beguava: 'beguava',
  beguina: 'beguina',
  beguine: 'beguine',
  beguino: 'beguino',
  beguira: 'beguira',
  beguiri: 'beguiri',
  begumes: 'begumes',
  begunia: 'begúnia',
  behoita: 'behoíta',
  beicada: 'beiçada',
  beicado: 'beicado',
  beicais: 'beiçais',
  beicamo: 'beiçamo',
  beicana: 'beiçana',
  beicara: 'beiçará',
  beicava: 'beiçava',
  beiceis: 'beiceis',
  beicemo: 'beicemo',
  beicoca: 'beiçoca',
  beicola: 'beiçola',
  beicosa: 'beiçosa',
  beicoso: 'beiçoso',
  beicote: 'beiçote',
  beicuda: 'beiçuda',
  beicudo: 'beiçudo',
  beieria: 'beiéria',
  beiinha: 'beiinhã',
  beijaco: 'beijaço',
  beijada: 'beijada',
  beijado: 'beijado',
  beijais: 'beijais',
  beijamo: 'beijamo',
  beijara: 'beijara',
  beijava: 'beijava',
  beijeis: 'beijeis',
  beijemo: 'beijemo',
  beijoca: 'beijoca',
  beijoco: 'beijoco',
  beijoes: 'beijões',
  beijoim: 'beijoim',
  beilhos: 'beilhós',
  beinhas: 'beinhãs',
  beirada: 'beirada',
  beirado: 'beirado',
  beirais: 'beirais',
  beirame: 'beirame',
  beiramo: 'beiramo',
  beirara: 'beirara',
  beirava: 'beirava',
  beireis: 'beireis',
  beiremo: 'beiremo',
  beiroas: 'beiroas',
  beiroes: 'beirões',
  beirute: 'beirute',
  bejabas: 'bejabãs',
  bejagui: 'bejagui',
  bejalas: 'bejalas',
  bejaqui: 'bejaqui',
  bejaria: 'bejária',
  bejeira: 'bejeira',
  bejeiro: 'bejeiro',
  bejense: 'bejense',
  bejinho: 'bejinho',
  bejoega: 'bejoega',
  bejogas: 'bejogas',
  bejucos: 'bejucos',
  bejulas: 'bejulas',
  beladis: 'beladis',
  belafao: 'belafão',
  belalas: 'belalas',
  belambo: 'belambo',
  belanta: 'belanta',
  belarte: 'belarte',
  belazes: 'belazes',
  belbute: 'belbute',
  beldada: 'beldada',
  beldade: 'beldade',
  beldado: 'beldado',
  beldais: 'beldais',
  beldamo: 'beldamo',
  beldara: 'beldará',
  beldava: 'beldava',
  beldeis: 'beldeis',
  beldemo: 'beldemo',
  beldoes: 'beldões',
  beldosa: 'beldosa',
  beldres: 'beldres',
  beldros: 'beldros',
  belecai: 'belecai',
  belecam: 'belecam',
  belecar: 'belecar',
  belecas: 'belecas',
  belecou: 'belecou',
  beledis: 'beledis',
  beleles: 'belelês',
  beleleu: 'beleléu',
  belemia: 'belêmia',
  belenas: 'belenas',
  belenda: 'belenda',
  belenos: 'belenos',
  belense: 'belense',
  beleque: 'beleque',
  beletas: 'beletas',
  beleuas: 'beleuas',
  belezas: 'belezas',
  belfaca: 'belfaça',
  belfuda: 'belfuda',
  belfudo: 'belfudo',
  belgata: 'belgata',
  belgate: 'belgate',
  belgico: 'bélgico',
  belgita: 'belgita',
  belgite: 'belgite',
  belicas: 'bélicas',
  beliche: 'beliche',
  belicos: 'bélicos',
  belidas: 'belidas',
  belilas: 'belilas',
  belinas: 'belinas',
  belinha: 'belinha',
  belinho: 'belinho',
  belinos: 'belinos',
  belisca: 'belisca',
  belisco: 'belisco',
  beliuns: 'béliuns',
  belizes: 'belizes',
  bellita: 'bellita',
  belocia: 'belócia',
  beloira: 'beloirá',
  beloire: 'beloire',
  beloiro: 'beloiro',
  belonas: 'belonas',
  belones: 'belones',
  belonia: 'belônia',
  belopeu: 'belopeu',
  belopos: 'belopos',
  belotas: 'belotas',
  belotia: 'belótia',
  beloura: 'beloura',
  beloure: 'beloure',
  belouro: 'belouro',
  belsofe: 'belsofe',
  beltrao: 'beltrão',
  belucas: 'belucas',
  beluchi: 'beluchi',
  belugas: 'belugas',
  beluino: 'beluíno',
  belulco: 'belulco',
  beluosa: 'beluosa',
  beluoso: 'beluoso',
  belutas: 'belutas',
  belvais: 'belvais',
  belveda: 'belveda',
  belvedi: 'belvedi',
  belvedo: 'belvedo',
  belveis: 'belveis',
  belvemo: 'belvemo',
  belvera: 'belverá',
  belviam: 'belviam',
  belvias: 'belvias',
  belvido: 'belvido',
  belzebu: 'belzebu',
  belzoes: 'belzões',
  bembara: 'bembara',
  bembeis: 'bembéis',
  bemolai: 'bemolai',
  bemolam: 'bemolam',
  bemolar: 'bemolar',
  bemolas: 'bemolas',
  bemolei: 'bemolei',
  bemolem: 'bemolem',
  bemoles: 'bemoles',
  bemolou: 'bemolou',
  benadas: 'benadas',
  benados: 'benados',
  benairo: 'benairo',
  bencaos: 'bênçãos',
  bencoar: 'bençoar',
  bendala: 'bendala',
  bendara: 'bendara',
  bendega: 'bendega',
  bendego: 'bendegó',
  bendiga: 'bendiga',
  bendigo: 'bendigo',
  bendira: 'bendirá',
  bendita: 'bendita',
  bendito: 'bendito',
  bendize: 'bendize',
  benefes: 'benefes',
  beneque: 'beneque',
  benesse: 'benesse',
  benetes: 'benetes',
  benfaca: 'benfaça',
  benfaco: 'benfaço',
  benfara: 'benfará',
  benfaze: 'benfaze',
  benfica: 'benfica',
  bengala: 'bengala',
  bengale: 'bengalé',
  bengali: 'bengali',
  bengiri: 'bengiri',
  bengola: 'bengola',
  bengues: 'benguês',
  benicas: 'bênicas',
  benicos: 'bênicos',
  benigna: 'benigna',
  benigno: 'benigno',
  benines: 'beninês',
  beninos: 'beninos',
  benique: 'beniquê',
  benissa: 'benissa',
  benjoim: 'benjoim',
  benjois: 'benjoís',
  benjuim: 'benjuim',
  benquer: 'benquer',
  benquis: 'benquis',
  bentais: 'bentais',
  bentana: 'bentana',
  benteca: 'benteca',
  bentens: 'benténs',
  bentere: 'benterê',
  bentica: 'bêntica',
  bentico: 'bêntico',
  benzais: 'benzais',
  benzamo: 'benzamo',
  benzeis: 'benzeis',
  benzemo: 'benzemo',
  benzeno: 'benzeno',
  benzera: 'benzera',
  benziam: 'benziam',
  benzias: 'benzias',
  benzida: 'benzida',
  benzido: 'benzido',
  benzila: 'benzila',
  benzilo: 'benzilo',
  benzina: 'benzina',
  benzoil: 'benzoil',
  benzois: 'benzóis',
  benzola: 'benzola',
  benzona: 'benzona',
  beocias: 'beócias',
  beocios: 'beócios',
  beotica: 'beótica',
  beotice: 'beotice',
  beotico: 'beótico',
  bequeas: 'béqueas',
  bequeia: 'bequeia',
  bequeis: 'béqueis',
  bequemo: 'béquemo',
  bequera: 'béquerá',
  bequiam: 'béquiam',
  bequias: 'béquias',
  bequica: 'béquica',
  bequico: 'béquico',
  bequida: 'béquida',
  bequido: 'béquido',
  beracas: 'beracas',
  beranas: 'beranas',
  berates: 'berates',
  berauna: 'beraúna',
  berbaia: 'berbaia',
  berbera: 'berbera',
  berbere: 'berbere',
  berbiao: 'berbião',
  berbins: 'berbins',
  berboes: 'berbões',
  bercada: 'berçada',
  bercado: 'berçado',
  bercais: 'berçais',
  bercamo: 'berçamo',
  bercara: 'berçará',
  bercava: 'berçava',
  berceis: 'berceis',
  bercemo: 'bercemo',
  bercola: 'berçola',
  bercuda: 'berçuda',
  bercudo: 'berçudo',
  berebas: 'berebas',
  berebes: 'berebés',
  berebis: 'berebis',
  bereres: 'bererés',
  berevas: 'berevas',
  bergamo: 'bérgamo',
  bergela: 'bergela',
  bergias: 'bérgias',
  bergoes: 'bergões',
  bergère: 'bergère',
  beribas: 'beribás',
  berides: 'berides',
  beridos: 'beridos',
  berifao: 'berifão',
  berijas: 'berijas',
  berijia: 'beríjia',
  berilio: 'berílio',
  berilos: 'berilos',
  berinea: 'berínea',
  berineo: 'beríneo',
  beritas: 'beritas',
  berivas: 'berivas',
  berjoga: 'berjoga',
  berlata: 'berlata',
  berlina: 'berlina',
  berlota: 'berlota',
  bermana: 'bermana',
  bermano: 'bermano',
  bermuda: 'bermuda',
  bermudo: 'bermudo',
  bernaca: 'bernaca',
  bernaco: 'bernaco',
  bernari: 'bernari',
  berneai: 'berneai',
  bernear: 'bernear',
  berneei: 'berneei',
  berneia: 'berneia',
  berneie: 'berneie',
  berneio: 'berneio',
  berneou: 'berneou',
  bernesa: 'bernesa',
  bernica: 'bernica',
  bernios: 'bérnios',
  bernosa: 'bernosa',
  bernoso: 'bernoso',
  berobas: 'berobas',
  beronha: 'beronha',
  berosas: 'berosas',
  berosos: 'berosos',
  berrada: 'berrada',
  berrado: 'berrado',
  berrais: 'berrais',
  berramo: 'berramo',
  berrara: 'berrara',
  berrata: 'berrata',
  berrava: 'berrava',
  berrega: 'berrega',
  berrego: 'berrego',
  berreis: 'berreis',
  berremo: 'berremo',
  berrias: 'bérrias',
  berrida: 'berrida',
  berroas: 'berroas',
  berroes: 'berrões',
  berrona: 'berrona',
  berruga: 'berruga',
  bersama: 'bersama',
  bertela: 'bertela',
  berucha: 'berucha',
  berulas: 'bérulas',
  bervaia: 'bervaiá',
  berzabu: 'berzabu',
  berzebu: 'berzebu',
  berzita: 'berzita',
  besanta: 'besanta',
  besante: 'besante',
  besanto: 'besanto',
  beselga: 'beselga',
  beselho: 'beselho',
  besigue: 'besigue',
  besnica: 'besnica',
  besnico: 'besnico',
  besoira: 'besoirá',
  besoire: 'besoire',
  besoiro: 'besoiro',
  besolha: 'besolha',
  besonha: 'besonha',
  besoura: 'besoura',
  besoure: 'besoure',
  besouro: 'besouro',
  bespica: 'bespiça',
  bespoes: 'bespões',
  bessera: 'bessera',
  bestada: 'bestada',
  bestado: 'bestado',
  bestais: 'bestais',
  bestamo: 'bestamo',
  bestara: 'bestara',
  bestava: 'bestava',
  bestear: 'bestear',
  besteis: 'besteis',
  bestemo: 'bestemo',
  bestial: 'bestial',
  bestiao: 'bestião',
  bestias: 'béstias',
  bestice: 'bestice',
  bestigo: 'bestigo',
  besugos: 'besugos',
  besunta: 'besunta',
  besunte: 'besunte',
  besunto: 'besunto',
  betadao: 'betadão',
  betadas: 'betadas',
  betadex: 'betadex',
  betador: 'betador',
  betados: 'betados',
  betaina: 'betaína',
  betamos: 'betamos',
  betando: 'betando',
  betante: 'betante',
  betaram: 'betaram',
  betarao: 'betarão',
  betaras: 'betaras',
  betarda: 'betarda',
  betarei: 'betarei',
  betarem: 'betarem',
  betares: 'betares',
  betaria: 'betaria',
  betarmo: 'betarmo',
  betarus: 'betarus',
  betasio: 'betásio',
  betasse: 'betasse',
  betaste: 'betaste',
  betavam: 'betavam',
  betavas: 'betavas',
  betazol: 'betazol',
  betecai: 'betecai',
  betecam: 'betecam',
  betecar: 'betecar',
  betecas: 'betecas',
  betecos: 'betecos',
  betecou: 'betecou',
  betelal: 'betelal',
  beteles: 'bételes',
  betemos: 'betemos',
  beteque: 'beteque',
  beteres: 'béteres',
  betesga: 'betesga',
  beticas: 'béticas',
  beticos: 'béticos',
  betilas: 'betilas',
  betilho: 'betilho',
  betilos: 'bétilos',
  betoias: 'betoias',
  betoira: 'betoira',
  betoiro: 'betoiro',
  betonai: 'betonai',
  betonam: 'betonam',
  betonar: 'betonar',
  betonas: 'betonas',
  betonei: 'betonei',
  betonem: 'betonem',
  betones: 'betones',
  betonia: 'betônia',
  betonou: 'betonou',
  betouca: 'betouca',
  betoura: 'betoura',
  betouro: 'betouro',
  betrais: 'betrais',
  betting: 'betting',
  betulas: 'bétulas',
  betulea: 'betúlea',
  betulos: 'bétulos',
  betumai: 'betumai',
  betumam: 'betumam',
  betumar: 'betumar',
  betumas: 'betumas',
  betumei: 'betumei',
  betumem: 'betumem',
  betumes: 'betumes',
  betumou: 'betumou',
  betunes: 'betunes',
  beturio: 'betúrio',
  beurias: 'beúrias',
  beusita: 'beusita',
  beveras: 'bêveras',
  bevonio: 'bevônio',
  bexigai: 'bexigai',
  bexigal: 'bexigal',
  bexigam: 'bexigam',
  bexigao: 'bexigão',
  bexigar: 'bexigar',
  bexigas: 'bexigas',
  bexigou: 'bexigou',
  bexigue: 'bexigue',
  bexuana: 'bexuana',
  bexucos: 'bexucos',
  bexugos: 'bexugos',
  bezanas: 'bezanas',
  bezedor: 'bezedor',
  bezerra: 'bezerra',
  bezerro: 'bezerro',
  bezigue: 'bezigue',
  bezinho: 'bezinho',
  bezoada: 'bezoada',
  bezoado: 'bezoado',
  bezoara: 'bezoará',
  bezoava: 'bezoava',
  bezogos: 'bezogos',
  biacido: 'biácido',
  biafada: 'biafada',
  biafare: 'biafare',
  bialada: 'bialada',
  bialado: 'bialado',
  bialilo: 'bialilo',
  biamilo: 'biamilo',
  biancas: 'biancas',
  biancos: 'biancos',
  biandas: 'biandas',
  bianejo: 'bianejo',
  biangos: 'biangos',
  bianual: 'bianual',
  biaqueu: 'biaquéu',
  biarcos: 'biarcos',
  biareas: 'biáreas',
  biareos: 'biáreos',
  biaribi: 'biaribi',
  biaribu: 'biaribu',
  biaroes: 'biarões',
  biastas: 'biastas',
  biastes: 'biastes',
  biatata: 'biatatá',
  biatlon: 'biátlon',
  biatlos: 'biatlos',
  biatora: 'biatora',
  biaxial: 'biaxial',
  bibagas: 'bibagas',
  bibalos: 'bíbalos',
  bibangu: 'bibangu',
  bibaras: 'bíbaras',
  bibelos: 'bibelôs',
  bibelot: 'bibelot',
  biberao: 'biberão',
  biberas: 'bíberas',
  bibiana: 'bibiana',
  bibiano: 'bibiano',
  bibicai: 'bibicai',
  bibicam: 'bibicam',
  bibicar: 'bibicar',
  bibicas: 'bibicas',
  bibicos: 'bibicos',
  bibicou: 'bibicou',
  bibindi: 'bibindi',
  bibique: 'bibique',
  bibirus: 'bibirus',
  biblias: 'bíblias',
  biblica: 'bíblica',
  biblico: 'bíblico',
  biblino: 'biblino',
  biblios: 'biblios',
  bibocal: 'bibocal',
  bibocao: 'bibocão',
  bibocas: 'bibocas',
  bibroco: 'bíbroco',
  bibulas: 'bíbulas',
  bibulos: 'bíbulos',
  bicacos: 'bicaços',
  bicadao: 'bicadão',
  bicadas: 'bicadas',
  bicador: 'bicador',
  bicados: 'bicados',
  bicamas: 'bicamas',
  bicames: 'bicames',
  bicamos: 'bicamos',
  bicanca: 'bicanca',
  bicanco: 'bicanço',
  bicando: 'bicando',
  bicanes: 'bicanes',
  bicante: 'bicante',
  bicaram: 'bicaram',
  bicarao: 'bicarão',
  bicaras: 'bicaras',
  bicarei: 'bicarei',
  bicarem: 'bicarem',
  bicares: 'bicares',
  bicaria: 'bicaria',
  bicarmo: 'bicarmo',
  bicaros: 'bicarós',
  bicarra: 'bicarra',
  bicasse: 'bicasse',
  bicaste: 'bicaste',
  bicatos: 'bicatos',
  bicaude: 'bicaude',
  bicavam: 'bicavam',
  bicavas: 'bicavas',
  bichaco: 'bichaço',
  bichada: 'bichada',
  bichado: 'bichado',
  bichais: 'bichais',
  bichamo: 'bichamo',
  bichana: 'bichana',
  bichane: 'bichane',
  bichano: 'bichano',
  bichara: 'bichará',
  bichava: 'bichava',
  bicheis: 'bicheis',
  bichemo: 'bichemo',
  bicheza: 'bicheza',
  bichice: 'bichice',
  bichoca: 'bichoca',
  bichoco: 'bichoco',
  bichoes: 'bichões',
  bichona: 'bichona',
  bichosa: 'bichosa',
  bichoso: 'bichoso',
  biciclo: 'biciclo',
  bicilia: 'bicilia',
  bicilie: 'bicilie',
  bicilio: 'bicilio',
  bicinio: 'bicínio',
  bicocos: 'bicocos',
  bicolor: 'bicolor',
  bicomas: 'bicomas',
  bicones: 'bicones',
  bicoque: 'bicoque',
  bicorai: 'bicorai',
  bicoram: 'bicoram',
  bicorar: 'bicorar',
  bicoras: 'bicoras',
  bicorei: 'bicorei',
  bicorem: 'bicorem',
  bicores: 'bicores',
  bicorne: 'bicorne',
  bicorno: 'bicorno',
  bicorou: 'bicorou',
  bicosas: 'bicosas',
  bicosos: 'bicosos',
  bicotai: 'bicotai',
  bicotam: 'bicotam',
  bicotar: 'bicotar',
  bicotas: 'bicotas',
  bicotei: 'bicotei',
  bicotem: 'bicotem',
  bicotes: 'bicotes',
  bicotou: 'bicotou',
  bicudas: 'bicudas',
  bicudez: 'bicudez',
  bicudos: 'bicudos',
  bicuiba: 'bicuíba',
  bicuiva: 'bicuíva',
  biculas: 'biculas',
  bicunis: 'bicunis',
  bidaria: 'bidária',
  bideira: 'bideira',
  bideiro: 'bideiro',
  bidense: 'bidense',
  bidente: 'bidente',
  bideros: 'bideros',
  bidetos: 'bidetos',
  bidinos: 'bidinos',
  bidioes: 'bidiões',
  bidjogo: 'bidjogo',
  bidogue: 'bidogue',
  bidumas: 'bidumas',
  bidunga: 'bidunga',
  bielida: 'biélida',
  bienais: 'bienais',
  bienica: 'biênica',
  bienico: 'biênico',
  bienios: 'biênios',
  bifaces: 'bifaces',
  bifadao: 'bifadão',
  bifadas: 'bifadas',
  bifador: 'bifador',
  bifados: 'bifados',
  bifamos: 'bifamos',
  bifanas: 'bifanas',
  bifando: 'bifando',
  bifante: 'bifante',
  bifaram: 'bifaram',
  bifarao: 'bifarão',
  bifaras: 'bifaras',
  bifarei: 'bifarei',
  bifarem: 'bifarem',
  bifares: 'bifares',
  bifaria: 'bifaria',
  bifario: 'bifário',
  bifarmo: 'bifarmo',
  bifaros: 'bífaros',
  bifasse: 'bifasse',
  bifaste: 'bifaste',
  bifavam: 'bifavam',
  bifavas: 'bifavas',
  bifeira: 'bifeira',
  bifemos: 'bifemos',
  biferas: 'bíferas',
  biferos: 'bíferos',
  bifidas: 'bífidas',
  bifidos: 'bífidos',
  bifilar: 'bifilar',
  bifilos: 'bifilos',
  bifinho: 'bifinho',
  biflexo: 'biflexo',
  bifloro: 'bifloro',
  bifocal: 'bifocal',
  bifolco: 'bifolco',
  bifolia: 'bifólia',
  bifolio: 'bifólio',
  biforas: 'bíforas',
  biforea: 'bifórea',
  biforeo: 'bifóreo',
  bifores: 'bífores',
  biforme: 'biforme',
  biforos: 'bíforos',
  bifurca: 'bifurca',
  bifurco: 'bifurco',
  bigalho: 'bigalhó',
  bigamas: 'bígamas',
  bigamea: 'bigâmea',
  bigameo: 'bigâmeo',
  bigamia: 'bigamia',
  bigamos: 'bígamos',
  biganau: 'biganau',
  bigaria: 'bigária',
  bigarim: 'bigarim',
  bigario: 'bigário',
  bigates: 'bigates',
  bigatos: 'bigatos',
  bigemea: 'bigêmea',
  bigemeo: 'bigêmeo',
  bigemia: 'bigemia',
  bigenas: 'bígenas',
  bigenes: 'bígenes',
  bigerra: 'bigerrã',
  bigerro: 'bigerro',
  bigodas: 'bigodas',
  bigodea: 'bigodeá',
  bigodes: 'bigodes',
  bigodis: 'bigodis',
  bigolas: 'bigolas',
  bigorna: 'bigorna',
  bigorne: 'bigorne',
  bigotao: 'bigotão',
  bigotas: 'bigotas',
  bigotes: 'bigotes',
  bigoudi: 'bigoudi',
  bigrama: 'bigrama',
  biguaco: 'biguaço',
  biguacu: 'biguaçu',
  biguada: 'biguada',
  biguado: 'biguado',
  biguais: 'biguais',
  biguana: 'biguana',
  biguane: 'biguane',
  biguano: 'biguano',
  biguara: 'biguará',
  biguava: 'biguava',
  bigudis: 'bigudis',
  bigueis: 'bigueis',
  bigumea: 'bigúmea',
  bigumeo: 'bigúmeo',
  bigumes: 'bigumes',
  bigundo: 'bigundo',
  bigunia: 'bigúnia',
  bijacos: 'bijacos',
  bijagas: 'bijagas',
  bijagos: 'bijagós',
  bijarro: 'bijarro',
  bijecao: 'bijeção',
  bijetor: 'bijetor',
  bijorro: 'bijorro',
  bijucas: 'bijucas',
  bijugos: 'bíjugos',
  biladao: 'biladão',
  biladas: 'biladas',
  bilador: 'bilador',
  bilados: 'bilados',
  bilamos: 'bilamos',
  bilando: 'bilando',
  bilante: 'bilante',
  bilaram: 'bilaram',
  bilarao: 'bilarão',
  bilaras: 'bilarás',
  bilarei: 'bilarei',
  bilarem: 'bilarem',
  bilares: 'bilares',
  bilargo: 'bilargo',
  bilaria: 'bilaria',
  bilarmo: 'bilarmo',
  bilasse: 'bilasse',
  bilaste: 'bilaste',
  bilatos: 'bilatos',
  bilavam: 'bilavam',
  bilavas: 'bilavas',
  bilbode: 'bilbode',
  bilemia: 'bilemia',
  bilemos: 'bilemos',
  bilenar: 'bilenar',
  bilenes: 'bilenes',
  bilenio: 'bilênio',
  bileuas: 'bileuas',
  bilheta: 'bilheta',
  bilhete: 'bilhete',
  bilhoca: 'bilhoca',
  bilhoes: 'bilhões',
  biliada: 'biliada',
  biliado: 'biliado',
  bilicas: 'bílicas',
  bilices: 'bilices',
  bilicos: 'bílicos',
  bilimbi: 'bilimbi',
  bilimis: 'bilimis',
  bilinas: 'bilinas',
  bilinga: 'bilinga',
  bilinos: 'bilinos',
  bilioes: 'biliões',
  biliosa: 'biliosa',
  bilioso: 'bilioso',
  biliras: 'biliras',
  biliros: 'biliros',
  bilocal: 'bilocal',
  bilocas: 'bilocas',
  bilocia: 'bilócia',
  bilogia: 'bilogia',
  bilolas: 'bilolas',
  bilosca: 'bilosca',
  bilotia: 'bilótia',
  bilotos: 'bilotos',
  biloura: 'biloura',
  bilrada: 'bilrada',
  bilrado: 'bilrado',
  bilrais: 'bilrais',
  bilramo: 'bilramo',
  bilrara: 'bilrará',
  bilrava: 'bilrava',
  bilreis: 'bilreis',
  bilremo: 'bilremo',
  biltras: 'biltras',
  biltres: 'biltres',
  bilunga: 'bilunga',
  bimanas: 'bímanas',
  bimanes: 'bímanes',
  bimanos: 'bímanos',
  bimares: 'bímares',
  bimbada: 'bimbada',
  bimbado: 'bimbado',
  bimbais: 'bimbais',
  bimbamo: 'bimbamo',
  bimbara: 'bimbara',
  bimbava: 'bimbava',
  bimbeis: 'bimbeis',
  bimbemo: 'bimbemo',
  bimbios: 'bímbios',
  bimeria: 'biméria',
  bimetal: 'bimetal',
  bimodal: 'bimodal',
  bimotor: 'bimotor',
  binacao: 'binação',
  binadao: 'binadão',
  binadas: 'binadas',
  binador: 'binador',
  binados: 'binados',
  binagem: 'binagem',
  binagio: 'binágio',
  binamos: 'binamos',
  binando: 'binando',
  binante: 'binante',
  binaram: 'binaram',
  binarao: 'binarão',
  binaras: 'binarás',
  binarei: 'binarei',
  binarem: 'binarem',
  binares: 'binares',
  binaria: 'binária',
  binario: 'binário',
  binarmo: 'binarmo',
  binasse: 'binasse',
  binaste: 'binaste',
  binatos: 'binatos',
  binavam: 'binavam',
  binavas: 'binavas',
  bindele: 'bindele',
  bindera: 'bindera',
  binemos: 'binemos',
  bingada: 'bingada',
  bingado: 'bingado',
  bingais: 'bingais',
  bingara: 'bingara',
  bingava: 'bingava',
  binguei: 'binguei',
  binguem: 'binguem',
  bingues: 'bingues',
  binitas: 'binitas',
  binites: 'binites',
  binodal: 'binodal',
  binomes: 'binomes',
  binomio: 'binômio',
  binotia: 'binótia',
  binubas: 'bínubas',
  binubos: 'bínubos',
  bioaera: 'bioaerá',
  bioaere: 'bioaere',
  bioaero: 'bioaero',
  biochip: 'biochip',
  biocida: 'biocida',
  biocito: 'biócito',
  biocopo: 'biocopo',
  biocoro: 'biocoro',
  bioerma: 'bioerma',
  biofaga: 'biófaga',
  biofago: 'biófago',
  biofilo: 'biófilo',
  biofito: 'biófito',
  biofobo: 'biófobo',
  bioforo: 'bióforo',
  biogeno: 'biógeno',
  biolise: 'biólise',
  biolito: 'biólito',
  biologa: 'bióloga',
  biologo: 'biólogo',
  biombos: 'biombos',
  biomero: 'biômero',
  bioneon: 'bioneon',
  biongos: 'biongos',
  bionica: 'biônica',
  bionico: 'biônico',
  bionima: 'biônima',
  bionimo: 'biônimo',
  bionose: 'bionose',
  biontes: 'biontes',
  biopses: 'biopses',
  biopsia: 'biópsia',
  biopsie: 'biopsie',
  biopsio: 'biopsio',
  biorana: 'biorana',
  biosofo: 'biósofo',
  biotica: 'biótica',
  biotico: 'biótico',
  biotina: 'biotina',
  biotipo: 'biótipo',
  biotita: 'biotita',
  biotite: 'biotite',
  biotito: 'biotito',
  biotomo: 'biótomo',
  biotono: 'biótono',
  biotopo: 'biótopo',
  biovula: 'biovulá',
  biovule: 'biovule',
  biovulo: 'biovulo',
  bioxido: 'bióxido',
  biozona: 'biozona',
  bipadao: 'bipadão',
  bipadas: 'bipadas',
  bipador: 'bipador',
  bipados: 'bipados',
  bipalio: 'bipálio',
  bipamos: 'bipamos',
  bipando: 'bipando',
  bipanga: 'bipanga',
  bipante: 'bipante',
  biparam: 'biparam',
  biparao: 'biparão',
  biparas: 'bíparas',
  biparei: 'biparei',
  biparem: 'biparem',
  bipares: 'bipares',
  biparia: 'biparia',
  biparmo: 'biparmo',
  biparos: 'bíparos',
  biparta: 'biparta',
  biparte: 'biparte',
  biparti: 'biparti',
  biparto: 'biparto',
  bipasse: 'bipasse',
  bipaste: 'bipaste',
  bipavam: 'bipavam',
  bipavas: 'bipavas',
  bipedal: 'bipedal',
  bipedes: 'bípedes',
  bipemos: 'bipemos',
  bipenes: 'bipenes',
  biplana: 'biplana',
  biplano: 'biplano',
  biplice: 'bíplice',
  biplume: 'biplume',
  bipolar: 'bipolar',
  bipolos: 'bipolos',
  biquara: 'biquara',
  biqueai: 'biqueai',
  biquear: 'biquear',
  biqueca: 'biqueca',
  biqueei: 'biqueei',
  biqueia: 'biqueia',
  biqueie: 'biqueie',
  biqueio: 'biqueio',
  biqueis: 'biqueis',
  biquemo: 'biquemo',
  biqueou: 'biqueou',
  biquine: 'biquine',
  biquini: 'biquíni',
  biraias: 'biraias',
  biralha: 'biralha',
  biraros: 'birarós',
  birauna: 'biraúna',
  biribas: 'biribas',
  biribui: 'biribuí',
  biricai: 'biricai',
  biricam: 'biricam',
  biricar: 'biricar',
  biricas: 'biricas',
  biricos: 'biriçós',
  biricou: 'biricou',
  birifos: 'birifos',
  birigui: 'birigui',
  biringa: 'biringa',
  birique: 'birique',
  biritai: 'biritai',
  biritam: 'biritam',
  biritar: 'biritar',
  biritas: 'biritas',
  biritei: 'biritei',
  biritem: 'biritem',
  birites: 'birites',
  biritou: 'biritou',
  birivas: 'birivas',
  birmana: 'birmana',
  birmane: 'birmane',
  birmano: 'birmano',
  birmolo: 'birmolo',
  birobas: 'birobas',
  birolas: 'birolas',
  birolho: 'birolho',
  bironha: 'bironha',
  bironia: 'birônia',
  biroros: 'birorós',
  birosca: 'birosca',
  birotas: 'birotas',
  birotes: 'birotes',
  birrada: 'birrada',
  birrado: 'birrado',
  birrais: 'birrais',
  birramo: 'birramo',
  birrara: 'birrara',
  birrava: 'birrava',
  birreis: 'birreis',
  birreme: 'birreme',
  birremo: 'birremo',
  birreto: 'birreto',
  birrida: 'bírrida',
  birrota: 'birrota',
  birruda: 'birruda',
  birrudo: 'birrudo',
  birubas: 'birubas',
  birutai: 'birutai',
  birutam: 'birutam',
  birutar: 'birutar',
  birutas: 'birutas',
  birutei: 'birutei',
  birutem: 'birutem',
  birutes: 'birutes',
  birutou: 'birutou',
  bisacao: 'bisação',
  bisacos: 'bisacos',
  bisadao: 'bisadão',
  bisadas: 'bisadas',
  bisador: 'bisador',
  bisados: 'bisados',
  bisagio: 'biságio',
  bisagos: 'bisagos',
  bisagra: 'bisagra',
  bisalho: 'bisalho',
  bisalta: 'bisalta',
  bisamos: 'bisamos',
  bisanas: 'bisanas',
  bisando: 'bisando',
  bisante: 'bisante',
  bisaram: 'bisaram',
  bisarao: 'bisarão',
  bisaras: 'bisaras',
  bisarei: 'bisarei',
  bisarem: 'bisarem',
  bisares: 'bisares',
  bisaria: 'bisaria',
  bisarma: 'bisarma',
  bisarmo: 'bisarmo',
  bisaros: 'bísaros',
  bisasse: 'bisasse',
  bisaste: 'bisaste',
  bisavam: 'bisavam',
  bisavas: 'bisavas',
  bisavos: 'bisavós',
  biscada: 'biscada',
  biscado: 'biscado',
  biscaia: 'biscaia',
  biscaio: 'biscaio',
  biscais: 'biscais',
  biscamo: 'biscamo',
  biscara: 'biscará',
  biscata: 'biscata',
  biscate: 'biscate',
  biscato: 'biscato',
  biscava: 'biscava',
  biscuis: 'biscuís',
  biscuit: 'biscuit',
  bisegre: 'bisegre',
  biseira: 'biseira',
  biseiro: 'biseiro',
  biselai: 'biselai',
  biselam: 'biselam',
  biselar: 'biselar',
  biselas: 'biselas',
  biselei: 'biselei',
  biselem: 'biselem',
  biseles: 'biseles',
  biselho: 'biselho',
  biselou: 'biselou',
  bisemos: 'bisemos',
  bisgaia: 'bisgaia',
  bisinas: 'bisinas',
  bislama: 'bislamá',
  bismais: 'bismais',
  bismela: 'bismela',
  bismita: 'bismita',
  bismite: 'bismite',
  bismona: 'bismona',
  bismuto: 'bismuto',
  bisnaca: 'bisnaca',
  bisnaga: 'bisnaga',
  bisnago: 'bisnago',
  bisnato: 'bisnato',
  bisnaus: 'bisnaus',
  bisneta: 'bisneta',
  bisneto: 'bisneto',
  bisonde: 'bisonde',
  bisonha: 'bisonha',
  bisonhe: 'bisonhe',
  bisonho: 'bisonho',
  bisonte: 'bisonte',
  bisotai: 'bisotai',
  bisotam: 'bisotam',
  bisotar: 'bisotar',
  bisotas: 'bisotas',
  bisotei: 'bisotei',
  bisotem: 'bisotem',
  bisotes: 'bisotes',
  bisotou: 'bisotou',
  bispada: 'bispada',
  bispado: 'bispado',
  bispais: 'bispais',
  bispamo: 'bispamo',
  bispara: 'bispara',
  bispava: 'bispava',
  bispeis: 'bispeis',
  bispemo: 'bispemo',
  bispica: 'bispiça',
  bispote: 'bispote',
  bisquei: 'bisquei',
  bisquem: 'bisquem',
  bisques: 'bisques',
  bissaco: 'bissaco',
  bissapa: 'bissapa',
  bisseis: 'bisseis',
  bisseta: 'bissetá',
  bissete: 'bissete',
  bisseto: 'bisseto',
  bissexa: 'bissexa',
  bissexo: 'bissexo',
  bissono: 'bíssono',
  bisteca: 'bisteca',
  bistone: 'bístone',
  bistori: 'bistori',
  bistres: 'bistres',
  bistros: 'bistrôs',
  bistrot: 'bistrot',
  bisturi: 'bisturi',
  bisulco: 'bisulco',
  bitacas: 'bitacas',
  bitacos: 'bitacos',
  bitadao: 'bitadão',
  bitadas: 'bitadas',
  bitador: 'bitador',
  bitados: 'bitados',
  bitafes: 'bitafes',
  bitaite: 'bitaite',
  bitalha: 'bitalha',
  bitamos: 'bitamos',
  bitando: 'bitando',
  bitante: 'bitante',
  bitaram: 'bitaram',
  bitarao: 'bitarão',
  bitaras: 'bitaras',
  bitarei: 'bitarei',
  bitarem: 'bitarem',
  bitares: 'bitares',
  bitaria: 'bitaria',
  bitarmo: 'bitarmo',
  bitasse: 'bitasse',
  bitaste: 'bitaste',
  bitatas: 'bitatás',
  bitates: 'bitates',
  bitavam: 'bitavam',
  bitavas: 'bitavas',
  bitecas: 'bitecas',
  biteira: 'biteira',
  biteiro: 'biteiro',
  bitelos: 'bitelos',
  bitemos: 'bitemos',
  bitesga: 'bitesga',
  bitinga: 'bitinga',
  bitinio: 'bitínio',
  bitinos: 'bitinos',
  bitiois: 'bitióis',
  bitocar: 'bitocar',
  bitoiro: 'bitoiro',
  bitolai: 'bitolai',
  bitolam: 'bitolam',
  bitolar: 'bitolar',
  bitolas: 'bitolas',
  bitolei: 'bitolei',
  bitolem: 'bitolem',
  bitoles: 'bitoles',
  bitolou: 'bitolou',
  bitonal: 'bitonal',
  bitonce: 'bitoncé',
  bitonco: 'bitoncó',
  bitonga: 'bitonga',
  bitonta: 'bitonta',
  bitoque: 'bitoque',
  bitouro: 'bitouro',
  bitrada: 'bitrada',
  bitrado: 'bitrado',
  bitrais: 'bitrais',
  bitramo: 'bitramo',
  bitrara: 'bitrará',
  bitrava: 'bitrava',
  bitreis: 'bitreis',
  bitremo: 'bitremo',
  bitruca: 'bitruca',
  bitucas: 'bitucas',
  bituira: 'bituíra',
  bitumai: 'bitumai',
  bitumam: 'bitumam',
  bitumar: 'bitumar',
  bitumas: 'bitumas',
  bitumei: 'bitumei',
  bitumem: 'bitumem',
  bitumes: 'bitumes',
  bitumou: 'bitumou',
  bituros: 'bituros',
  bituvas: 'bituvas',
  bityita: 'bityíta',
  biungos: 'biungos',
  biupira: 'biupirá',
  biurana: 'biurana',
  biureto: 'biureto',
  bivacai: 'bivacai',
  bivacam: 'bivacam',
  bivacar: 'bivacar',
  bivacas: 'bivacas',
  bivacou: 'bivacou',
  bivalam: 'bivalam',
  bivalas: 'bivalas',
  bivalei: 'bivalei',
  bivalem: 'bivalem',
  bivaler: 'bivaler',
  bivales: 'bivales',
  bivaleu: 'bivaleu',
  bivalia: 'bivalia',
  bivalve: 'bivalve',
  bivaque: 'bivaque',
  biviras: 'bíviras',
  bivocal: 'bivocal',
  bivolts: 'bivolts',
  bivoras: 'bívoras',
  bixacea: 'bixácea',
  bixaceo: 'bixáceo',
  bixbita: 'bixbita',
  bixbite: 'bixbite',
  bixeira: 'bixeira',
  bixeiro: 'bixeiro',
  bixinas: 'bixinas',
  bixinea: 'bixínea',
  bixineo: 'bixíneo',
  bixoxos: 'bixoxos',
  bixurro: 'bixurro',
  bizante: 'bizante',
  bizarra: 'bizarra',
  bizarro: 'bizarro',
  bizeros: 'bizeros',
  bizogue: 'bizogue',
  bizuque: 'bizuque',
  bizzaro: 'bizzaro',
  blablas: 'blablás',
  blabosa: 'blabosa',
  blagues: 'blagues',
  blasona: 'blasona',
  blasone: 'blasone',
  blasono: 'blasono',
  blastos: 'blastos',
  blatera: 'blatera',
  blatere: 'blatere',
  blatero: 'blatero',
  blatida: 'blátida',
  blefada: 'blefada',
  blefado: 'blefado',
  blefaia: 'blefaia',
  blefais: 'blefais',
  blefamo: 'blefamo',
  blefara: 'blefara',
  blefaro: 'bléfaro',
  blefava: 'blefava',
  blefeis: 'blefeis',
  blefemo: 'blefemo',
  blefore: 'bleforé',
  bleizer: 'blêizer',
  blendas: 'blendas',
  blenios: 'blênios',
  blindai: 'blindai',
  blindam: 'blindam',
  blindar: 'blindar',
  blindas: 'blindas',
  blindei: 'blindei',
  blindem: 'blindem',
  blindes: 'blindes',
  blindou: 'blindou',
  blister: 'blister',
  blitzes: 'blitzes',
  blocada: 'blocada',
  blocado: 'blocado',
  blocais: 'blocais',
  blocamo: 'blocamo',
  blocara: 'blocará',
  blocava: 'blocava',
  blogado: 'blogado',
  blogues: 'blogues',
  bloquea: 'bloqueá',
  bloquei: 'bloquei',
  bloquem: 'bloquem',
  bloques: 'bloques',
  blusoes: 'blusões',
  blusona: 'blusona',
  boadoes: 'boadões',
  boadona: 'boadona',
  boadora: 'boadora',
  boalvos: 'boalvos',
  boalzao: 'boalzão',
  boambas: 'boambas',
  boanari: 'boanari',
  boancas: 'boanças',
  boanova: 'boanova',
  boantes: 'boantes',
  boardes: 'boardes',
  boareis: 'boáreis',
  boaremo: 'boaremo',
  boariam: 'boariam',
  boarias: 'boárias',
  boarmia: 'boármia',
  boarmos: 'boarmos',
  boassem: 'boassem',
  boasses: 'boasses',
  boastes: 'boastes',
  boatada: 'boatada',
  boatado: 'boatado',
  boatais: 'boatais',
  boatamo: 'boatamo',
  boatara: 'boatará',
  boatava: 'boatava',
  boateis: 'boateis',
  boatemo: 'boatemo',
  boatice: 'boatice',
  boaveis: 'boáveis',
  boazona: 'boazona',
  boazuda: 'boazuda',
  bobadao: 'bobadão',
  bobadas: 'bobadas',
  bobador: 'bobador',
  bobados: 'bobados',
  bobagem: 'bobagem',
  bobamos: 'bobamos',
  bobando: 'bobando',
  bobante: 'bobante',
  bobaram: 'bobaram',
  bobarao: 'bobarão',
  bobaras: 'bobarás',
  bobarei: 'bobarei',
  bobarem: 'bobarem',
  bobares: 'bobares',
  bobaria: 'bobaria',
  bobarmo: 'bobarmo',
  bobasse: 'bobasse',
  bobaste: 'bobaste',
  bobatas: 'bobatas',
  bobavam: 'bobavam',
  bobavas: 'bobavas',
  bobeada: 'bobeada',
  bobeado: 'bobeado',
  bobeais: 'bobeais',
  bobeamo: 'bobeamo',
  bobeara: 'bobeara',
  bobeava: 'bobeava',
  bobeche: 'bobeche',
  bobeeis: 'bobeeis',
  bobeemo: 'bobeemo',
  bobeiai: 'bobeiai',
  bobeiam: 'bobeiam',
  bobeiar: 'bobeiar',
  bobeias: 'bobeias',
  bobeiem: 'bobeiem',
  bobeies: 'bobeies',
  bobeiou: 'bobeiou',
  bobeira: 'bobeira',
  bobemos: 'bobemos',
  bobices: 'bobices',
  bobicia: 'bobícia',
  bobinai: 'bobinai',
  bobinam: 'bobinam',
  bobinao: 'bobinão',
  bobinar: 'bobinar',
  bobinas: 'bobinas',
  bobinei: 'bobinei',
  bobinem: 'bobinem',
  bobines: 'bobines',
  bobinha: 'bobinha',
  bobinho: 'bobinho',
  bobinou: 'bobinou',
  bobocai: 'bobocai',
  bobocam: 'bobocam',
  bobocar: 'bobocar',
  bobocas: 'bobocas',
  bobocou: 'bobocou',
  bobonas: 'bobonas',
  bobonax: 'bóbonax',
  boboque: 'boboque',
  bobotis: 'bobótis',
  bobsled: 'bobsled',
  bobures: 'boburés',
  bocacas: 'bocaças',
  bocadao: 'bocadão',
  bocadas: 'bocadas',
  bocador: 'bocador',
  bocados: 'bocados',
  bocagea: 'bocágea',
  bocagem: 'bocagem',
  bocaina: 'bocaina',
  bocaius: 'bocaiús',
  bocalai: 'boçalai',
  bocalam: 'boçalam',
  bocalar: 'boçalar',
  bocalas: 'boçalas',
  bocalei: 'boçalei',
  bocalem: 'boçalem',
  bocales: 'boçales',
  bocalou: 'boçalou',
  bocalvo: 'bocalvo',
  bocamos: 'bocamos',
  bocanas: 'bocanas',
  bocando: 'bocando',
  bocanha: 'bocanha',
  bocanhe: 'bocanhe',
  bocanho: 'bocanho',
  bocante: 'bocante',
  bocapiu: 'bocapiú',
  bocaram: 'bocaram',
  bocarao: 'bocarão',
  bocaras: 'bocarás',
  bocarda: 'boçarda',
  bocardo: 'bocardo',
  bocarei: 'bocarei',
  bocarem: 'bocarem',
  bocares: 'bocares',
  bocareu: 'bocaréu',
  bocaria: 'bocaria',
  bocarmo: 'bocarmo',
  bocarra: 'bocarra',
  bocarte: 'bocarte',
  bocasse: 'bocasse',
  bocassi: 'bocassi',
  bocaste: 'bocaste',
  bocavam: 'bocavam',
  bocavas: 'bocavas',
  bocaxim: 'bocaxim',
  bocaxis: 'bocaxis',
  bocejai: 'bocejai',
  bocejam: 'bocejam',
  bocejar: 'bocejar',
  bocejas: 'bocejas',
  bocejei: 'bocejei',
  bocejem: 'bocejem',
  bocejes: 'bocejes',
  bocejos: 'bocejos',
  bocejou: 'bocejou',
  bocelai: 'bocelai',
  bocelam: 'bocelam',
  bocelao: 'bocelão',
  bocelar: 'bocelar',
  bocelas: 'bocelas',
  bocelei: 'bocelei',
  bocelem: 'bocelem',
  boceles: 'boceles',
  bocelim: 'bocelim',
  bocelou: 'bocelou',
  bocemos: 'bocemos',
  bocetas: 'bocetas',
  bocetes: 'bocetes',
  bocetos: 'bocetos',
  bochaca: 'bochaca',
  bochada: 'bochada',
  bochado: 'bochado',
  bochais: 'bochais',
  bochamo: 'bochamo',
  bochara: 'bochará',
  bochava: 'bochava',
  bocheis: 'bocheis',
  bochemo: 'bochemo',
  bociada: 'bociada',
  bociado: 'bociado',
  bocidio: 'bocídio',
  bocinha: 'bocinha',
  bociosa: 'bociosa',
  bocioso: 'bocioso',
  bocoios: 'bocoiós',
  boconas: 'boconas',
  boconia: 'bocônia',
  bocoria: 'bocória',
  bocorio: 'bocório',
  bocotas: 'bocotas',
  bocubas: 'bocubas',
  bocudas: 'bocudas',
  bocudos: 'bocudos',
  bocuvas: 'bocuvas',
  bodadao: 'bodadão',
  bodadas: 'bodadas',
  bodador: 'bodador',
  bodados: 'bodados',
  bodalha: 'bodalha',
  bodalho: 'bodalho',
  bodamos: 'bodamos',
  bodanas: 'bodanas',
  bodando: 'bodando',
  bodanha: 'bodanha',
  bodante: 'bodante',
  bodaram: 'bodaram',
  bodarao: 'bodarão',
  bodaras: 'bodarás',
  bodarei: 'bodarei',
  bodarem: 'bodarem',
  bodares: 'bodares',
  bodaria: 'bodaria',
  bodarmo: 'bodarmo',
  bodasse: 'bodasse',
  bodaste: 'bodaste',
  bodavam: 'bodavam',
  bodavas: 'bodavas',
  bodeada: 'bodeada',
  bodeado: 'bodeado',
  bodecos: 'bodecos',
  bodefes: 'bodefes',
  bodegao: 'bodegão',
  bodegas: 'bodegas',
  bodegos: 'bodegos',
  bodeira: 'bodeira',
  bodeiro: 'bodeiro',
  bodejai: 'bodejai',
  bodejam: 'bodejam',
  bodejar: 'bodejar',
  bodejas: 'bodejas',
  bodejei: 'bodejei',
  bodejem: 'bodejem',
  bodejes: 'bodejes',
  bodejou: 'bodejou',
  bodelgo: 'bodelgo',
  bodelha: 'bodelha',
  bodelho: 'bodelho',
  bodemos: 'bodemos',
  bodetes: 'bodetes',
  bodiame: 'bodiame',
  bodiamo: 'bodiamo',
  bodiana: 'bodiana',
  bodiano: 'bodiano',
  bodigos: 'bodigos',
  bodinho: 'bodinho',
  bodioas: 'bodioas',
  bodioes: 'bodiões',
  bodiona: 'bodiona',
  bodivas: 'bodivas',
  bodivos: 'bodivos',
  bodlega: 'bodlega',
  bodonas: 'bodonas',
  bodonis: 'bodonis',
  bodoque: 'bodoque',
  bodosas: 'bodosas',
  bodosos: 'bodosos',
  bodries: 'bodriés',
  boeings: 'boeings',
  boeiras: 'boeiras',
  boeiros: 'boeiros',
  boemias: 'boêmias',
  boemica: 'boêmica',
  boemico: 'boêmico',
  boemios: 'boêmios',
  boemita: 'boemita',
  boencas: 'boencas',
  bofadao: 'bofadão',
  bofadas: 'bofadas',
  bofador: 'bofador',
  bofados: 'bofados',
  bofamos: 'bofamos',
  bofando: 'bofando',
  bofante: 'bofante',
  bofaram: 'bofaram',
  bofarao: 'bofarão',
  bofaras: 'bofarás',
  bofarei: 'bofarei',
  bofarem: 'bofarem',
  bofares: 'bofares',
  bofaria: 'bofaria',
  bofarmo: 'bofarmo',
  bofasse: 'bofasse',
  bofaste: 'bofaste',
  bofavam: 'bofavam',
  bofavas: 'bofavas',
  bofeira: 'bofeira',
  bofejai: 'bofejai',
  bofejam: 'bofejam',
  bofejar: 'bofejar',
  bofejas: 'bofejas',
  bofejei: 'bofejei',
  bofejem: 'bofejem',
  bofejes: 'bofejes',
  bofejou: 'bofejou',
  bofelia: 'bofélia',
  bofemos: 'bofemos',
  bofetai: 'bofetai',
  bofetam: 'bofetam',
  bofetao: 'bofetão',
  bofetar: 'bofetar',
  bofetas: 'bofetas',
  bofetea: 'bofeteá',
  bofetei: 'bofetei',
  bofetem: 'bofetem',
  bofetes: 'bofetes',
  bofetos: 'bofetos',
  bofetou: 'bofetou',
  bofordo: 'bofordo',
  boforos: 'boforos',
  bogacho: 'bogacho',
  bogadao: 'bogadão',
  bogadas: 'bogadas',
  bogador: 'bogador',
  bogados: 'bogados',
  bogalha: 'bogalha',
  bogalho: 'bogalhó',
  bogamos: 'bogamos',
  bogando: 'bogando',
  boganga: 'boganga',
  bogango: 'bogango',
  bogante: 'bogante',
  bogaram: 'bogaram',
  bogarao: 'bogarão',
  bogaras: 'bogarás',
  bogardo: 'bogardo',
  bogarei: 'bogarei',
  bogarem: 'bogarem',
  bogares: 'bogares',
  bogaria: 'bogaria',
  bogarim: 'bogarim',
  bogaris: 'bogaris',
  bogasse: 'bogasse',
  bogaste: 'bogaste',
  bogavam: 'bogavam',
  bogavas: 'bogavas',
  bogaxos: 'bogaxos',
  bogueis: 'bogueis',
  bogunha: 'bogunha',
  bohemia: 'bohêmia',
  bohemio: 'bohêmio',
  bohrios: 'bóhrios',
  boiacaa: 'boiacaá',
  boiacas: 'boiacas',
  boiacus: 'boiaçus',
  boiadao: 'boiadão',
  boiadas: 'boiadas',
  boiador: 'boiador',
  boiados: 'boiados',
  boiamas: 'boiamas',
  boiamos: 'boiamos',
  boiando: 'boiando',
  boiante: 'boiante',
  boiaram: 'boiaram',
  boiarao: 'boiarão',
  boiaras: 'boiaras',
  boiardo: 'boiardo',
  boiarei: 'boiarei',
  boiarem: 'boiarem',
  boiares: 'boiares',
  boiaria: 'boiaria',
  boiarmo: 'boiarmo',
  boiasse: 'boiasse',
  boiaste: 'boiaste',
  boiatos: 'boiatos',
  boiavam: 'boiavam',
  boiavas: 'boiavas',
  boicaas: 'boicaás',
  boicada: 'boiçada',
  boicado: 'boiçado',
  boicais: 'boiçais',
  boicamo: 'boiçamo',
  boicara: 'boiçará',
  boicava: 'boiçava',
  boiceis: 'boiceis',
  boicelo: 'boicelo',
  boicemo: 'boicemo',
  boicera: 'boicera',
  boicipo: 'boicipó',
  boicora: 'boicorá',
  boicota: 'boicota',
  boicote: 'boicote',
  boicoto: 'boicoto',
  boidana: 'boidana',
  boideas: 'boídeas',
  boideos: 'boídeos',
  boiecos: 'boiecos',
  boieira: 'boieira',
  boieiro: 'boieiro',
  boiemos: 'boiemos',
  boiense: 'boiense',
  boinhas: 'boinhas',
  boinona: 'boinona',
  boiobis: 'boiobis',
  boiocus: 'boioçus',
  boiolas: 'boiolas',
  boiombo: 'boiombo',
  boionas: 'boionas',
  boiotas: 'boiotas',
  boiotes: 'boiotes',
  boipeba: 'boipeba',
  boipeva: 'boipeva',
  boirada: 'boirada',
  boirado: 'boirado',
  boirais: 'boirais',
  boiramo: 'boiramo',
  boirara: 'boirará',
  boirava: 'boirava',
  boireis: 'boireis',
  boiremo: 'boiremo',
  boismos: 'boísmos',
  boitata: 'boitatá',
  boiubis: 'boiubis',
  boiubus: 'boiubus',
  boiucus: 'boiuçus',
  boiunas: 'boiunas',
  boiunos: 'boiunos',
  boizama: 'boizama',
  boizana: 'boizana',
  boizote: 'boizote',
  bojadao: 'bojadão',
  bojadas: 'bojadas',
  bojador: 'bojador',
  bojados: 'bojados',
  bojagas: 'bojagas',
  bojamos: 'bojamos',
  bojanca: 'bojança',
  bojando: 'bojando',
  bojanga: 'bojanga',
  bojante: 'bojante',
  bojaram: 'bojaram',
  bojarao: 'bojarão',
  bojaras: 'bojaras',
  bojarda: 'bojarda',
  bojarei: 'bojarei',
  bojarem: 'bojarem',
  bojares: 'bojares',
  bojaria: 'bojaria',
  bojarmo: 'bojarmo',
  bojasse: 'bojasse',
  bojaste: 'bojaste',
  bojavam: 'bojavam',
  bojavas: 'bojavas',
  bojecos: 'bojeços',
  bojegas: 'bojegas',
  bojegos: 'bojegos',
  bojemos: 'bojemos',
  bojeria: 'bojéria',
  bojitos: 'bojitos',
  bojobis: 'bojobis',
  bojudas: 'bojudas',
  bojudos: 'bojudos',
  bolacao: 'bolação',
  bolacha: 'bolacha',
  bolache: 'bolache',
  bolacho: 'bolacho',
  bolacos: 'bolaços',
  boladao: 'boladão',
  boladas: 'boladas',
  bolador: 'bolador',
  bolados: 'bolados',
  bolaina: 'bolaina',
  bolamos: 'bolamos',
  bolanas: 'bolanas',
  bolando: 'bolando',
  bolanha: 'bolanha',
  bolanos: 'bolanos',
  bolante: 'bolante',
  bolapes: 'bolapés',
  bolaram: 'bolaram',
  bolarao: 'bolarão',
  bolaras: 'bolaras',
  bolarda: 'bolarda',
  bolarei: 'bolarei',
  bolarem: 'bolarem',
  bolares: 'bolares',
  bolaria: 'bolaria',
  bolarmo: 'bolarmo',
  bolaros: 'bolaros',
  bolasse: 'bolasse',
  bolaste: 'bolaste',
  bolatas: 'bolatas',
  bolatim: 'bolatim',
  bolatra: 'bolatra',
  bolavam: 'bolavam',
  bolavas: 'bolavas',
  bolbosa: 'bolbosa',
  bolboso: 'bolboso',
  bolcada: 'bolçada',
  bolcado: 'bolçado',
  bolcais: 'bolçais',
  bolcamo: 'bolcamo',
  bolcara: 'bolçará',
  bolcava: 'bolçava',
  bolceis: 'bolceis',
  bolcemo: 'bolcemo',
  bolcoes: 'bolcões',
  boldina: 'boldina',
  boldois: 'boldóis',
  boldrie: 'boldrié',
  boleada: 'boleada',
  boleado: 'boleado',
  boleais: 'boleais',
  boleara: 'boleará',
  boleava: 'boleava',
  bolecos: 'bolecos',
  boleeis: 'boleeis',
  bolegos: 'bolegos',
  boleiam: 'boleiam',
  boleias: 'boleias',
  boleiem: 'boleiem',
  boleies: 'boleies',
  boleima: 'boleima',
  boleira: 'boleira',
  boleiro: 'boleiro',
  boleita: 'boleíta',
  bolemos: 'bolemos',
  bolenga: 'bolenga',
  bolenol: 'bolenol',
  bolenos: 'bolenos',
  boleros: 'boleros',
  boletai: 'boletai',
  boletam: 'boletam',
  boletar: 'boletar',
  boletas: 'boletas',
  boletea: 'bolétea',
  boletei: 'boletei',
  boletem: 'boletem',
  boleteo: 'boléteo',
  boletes: 'boletes',
  boletim: 'boletim',
  boletos: 'boletos',
  boletou: 'boletou',
  boletra: 'boletra',
  bolhaca: 'bolhaca',
  bolhaco: 'bolhaco',
  bolhada: 'bolhada',
  bolhado: 'bolhado',
  bolhais: 'bolhais',
  bolhamo: 'bolhamo',
  bolhara: 'bolhara',
  bolhava: 'bolhava',
  bolheis: 'bolheis',
  bolhemo: 'bolhemo',
  bolhoes: 'bolhões',
  bolhosa: 'bolhosa',
  bolhoso: 'bolhoso',
  bolhuda: 'bolhuda',
  bolhudo: 'bolhudo',
  bolicas: 'bolicas',
  bolicha: 'bolicha',
  boliche: 'boliche',
  bolicho: 'bolicho',
  bolicos: 'boliços',
  bolidas: 'bólidas',
  bolides: 'bólides',
  bolidos: 'bólidos',
  bolinai: 'bolinai',
  bolinam: 'bolinam',
  bolinar: 'bolinar',
  bolinas: 'bolinas',
  bolinei: 'bolinei',
  bolinem: 'bolinem',
  bolines: 'bolines',
  bolinha: 'bolinha',
  bolinho: 'bolinho',
  bolinia: 'bolínia',
  bolinou: 'bolinou',
  bolisco: 'bolisco',
  bolitas: 'bolitas',
  bolivar: 'bolívar',
  bolonia: 'bolônia',
  bolonio: 'bolônio',
  boloras: 'boloras',
  bolores: 'bolores',
  bolotal: 'bolotal',
  bolotas: 'bolotas',
  bolquei: 'bolquei',
  bolquem: 'bolquem',
  bolques: 'bolques',
  bolsada: 'bolsada',
  bolsado: 'bolsado',
  bolsais: 'bolsais',
  bolsamo: 'bolsamo',
  bolsara: 'bolsará',
  bolsava: 'bolsava',
  bolseis: 'bolseis',
  bolsemo: 'bolsemo',
  bolsoes: 'bolsões',
  bomarea: 'bomárea',
  bomassa: 'bomassa',
  bombaca: 'bombaça',
  bombaco: 'bombaço',
  bombada: 'bombada',
  bombado: 'bombado',
  bombagi: 'bombagi',
  bombais: 'bombais',
  bombamo: 'bombamo',
  bombara: 'bombara',
  bombava: 'bombava',
  bombavo: 'bombavo',
  bombeai: 'bombeai',
  bombear: 'bombear',
  bombeei: 'bombeei',
  bombeia: 'bombeia',
  bombeie: 'bombeie',
  bombeio: 'bombeio',
  bombeis: 'bombeis',
  bombeja: 'bombeja',
  bombeje: 'bombeje',
  bombejo: 'bombejo',
  bombemo: 'bombemo',
  bombeou: 'bombeou',
  bombiai: 'bombiai',
  bombiam: 'bombiam',
  bombiar: 'bombiar',
  bombias: 'bombias',
  bombica: 'bombica',
  bombice: 'bômbice',
  bombico: 'bombico',
  bombiei: 'bombiei',
  bombiem: 'bombiem',
  bombies: 'bombies',
  bombilo: 'bombilo',
  bombins: 'bombins',
  bombiou: 'bombiou',
  bombita: 'bombita',
  bomboes: 'bombões',
  bombolo: 'bombolo',
  bombona: 'bombona',
  bombons: 'bombons',
  bomeria: 'bomeria',
  bomoros: 'bomoros',
  bonacho: 'bonacho',
  bonaias: 'bonaias',
  bonamia: 'bonâmia',
  bonanca: 'bonança',
  bonance: 'bonance',
  bonanco: 'bonanço',
  bonaris: 'bonaris',
  boncada: 'bonçada',
  boncado: 'bonçado',
  boncais: 'bonçais',
  boncamo: 'bonçamo',
  boncara: 'bonçara',
  boncava: 'bonçava',
  bonceis: 'bonceis',
  boncemo: 'boncemo',
  bonchis: 'bonchis',
  bondada: 'bondada',
  bondade: 'bondade',
  bondado: 'bondado',
  bondais: 'bondais',
  bondamo: 'bondamo',
  bondara: 'bondara',
  bondava: 'bondava',
  bondeco: 'bondeco',
  bondeis: 'bondeis',
  bondemo: 'bondemo',
  bondosa: 'bondosa',
  bondoso: 'bondoso',
  bonecai: 'bonecai',
  bonecam: 'bonecam',
  bonecao: 'bonecão',
  bonecar: 'bonecar',
  bonecas: 'bonecas',
  bonecia: 'bonécia',
  bonecos: 'bonecos',
  bonecou: 'bonecou',
  bonecra: 'bonecra',
  bonecro: 'bonecro',
  bonefre: 'bonefre',
  bonejas: 'bonejas',
  bonelia: 'bonélia',
  bonense: 'bonense',
  boneque: 'boneque',
  bonetes: 'bonetes',
  bonetia: 'bonétia',
  bongada: 'bongada',
  bongado: 'bongado',
  bongais: 'bongais',
  bongara: 'bongará',
  bongaro: 'bongaro',
  bongava: 'bongava',
  bongolo: 'bongolo',
  bongono: 'bongono',
  bonguei: 'bonguei',
  bonguem: 'bonguem',
  bongues: 'bongues',
  bonguis: 'bonguis',
  bonhada: 'bonhada',
  bonhado: 'bonhado',
  bonhais: 'bonhais',
  bonhamo: 'bonhamo',
  bonhara: 'bonhará',
  bonhava: 'bonhava',
  bonheis: 'bonheis',
  bonhemo: 'bonhemo',
  bonheur: 'bonheur',
  bonicas: 'bonicas',
  bonicos: 'bonicos',
  boninal: 'boninal',
  boninas: 'boninas',
  bonisco: 'bonisco',
  bonitao: 'bonitão',
  bonitas: 'bonitas',
  bonitez: 'bonitez',
  bonitos: 'bonitos',
  bonobos: 'bonobos',
  bonomia: 'bonomia',
  bonotom: 'bonotom',
  bonsais: 'bonsais',
  bonseai: 'bonseai',
  bonsear: 'bonsear',
  bonseei: 'bonseei',
  bonseia: 'bonseia',
  bonseie: 'bonseie',
  bonseio: 'bonseio',
  bonseou: 'bonseou',
  bonsuca: 'bonsuça',
  bonteis: 'bonteis',
  bonvoro: 'bonvoro',
  bonzoes: 'bonzões',
  boofane: 'boofane',
  boofone: 'boofone',
  bootita: 'bootita',
  bopiana: 'bopiana',
  bopiano: 'bopiano',
  boqueai: 'boqueai',
  boquear: 'boquear',
  boqueei: 'boqueei',
  boqueia: 'boqueia',
  boqueie: 'boqueie',
  boqueio: 'boqueio',
  boqueis: 'boqueis',
  boqueja: 'boqueja',
  boqueje: 'boqueje',
  boquejo: 'boquejo',
  boquemo: 'boquemo',
  boqueou: 'boqueou',
  boquete: 'boquete',
  boquila: 'boquila',
  boquins: 'boquins',
  boquiza: 'boquizá',
  boquize: 'boquize',
  boquizo: 'boquizo',
  boraces: 'boraces',
  boradas: 'boradas',
  borados: 'borados',
  boragem: 'boragem',
  boragos: 'boragos',
  boranas: 'boranas',
  boranos: 'boranos',
  borasso: 'borasso',
  boratos: 'boratos',
  borazol: 'borazol',
  borbeto: 'borbeto',
  borbori: 'borbori',
  borboro: 'borboró',
  borbota: 'borbota',
  borbote: 'borbote',
  borboto: 'borboto',
  borbulo: 'borbulo',
  borcada: 'borcada',
  borcado: 'borcado',
  borcais: 'borcais',
  borcamo: 'borcamo',
  borcana: 'borcana',
  borcano: 'borcano',
  borcara: 'borcará',
  borcava: 'borcava',
  borcela: 'borcela',
  borcele: 'borcele',
  borcelo: 'borcelo',
  borcina: 'borcina',
  borcmes: 'borcmes',
  bordaca: 'bordaça',
  bordada: 'bordada',
  bordado: 'bordado',
  bordais: 'bordais',
  bordalo: 'bordalo',
  bordamo: 'bordamo',
  bordana: 'bordana',
  bordara: 'bordara',
  bordate: 'bordate',
  bordava: 'bordava',
  bordeai: 'bordeai',
  bordear: 'bordear',
  bordeei: 'bordeei',
  bordega: 'bordegã',
  bordeia: 'bordeia',
  bordeie: 'bordeie',
  bordeio: 'bordeio',
  bordeis: 'bordeis',
  bordeja: 'bordeja',
  bordeje: 'bordeje',
  bordejo: 'bordejo',
  bordemo: 'bordemo',
  bordeou: 'bordeou',
  bordero: 'borderô',
  bordeus: 'bordéus',
  bordoes: 'bordões',
  borduna: 'borduna',
  boreais: 'boreais',
  boresca: 'boresca',
  boreste: 'boreste',
  boricas: 'bóricas',
  boricos: 'bóricos',
  borismo: 'borismo',
  borista: 'borista',
  borjaca: 'borjaca',
  borjeco: 'borjeço',
  borlada: 'borlada',
  borlado: 'borlado',
  borleta: 'borleta',
  bornais: 'bornais',
  borneai: 'borneai',
  bornear: 'bornear',
  borneco: 'borneco',
  borneei: 'borneei',
  borneia: 'borneia',
  borneie: 'borneie',
  borneio: 'borneio',
  borneol: 'borneol',
  borneou: 'borneou',
  bornita: 'bornita',
  bornite: 'bornite',
  bororos: 'bororos',
  borquei: 'borquei',
  borquem: 'borquem',
  borques: 'borques',
  borraca: 'borraça',
  borrace: 'borrace',
  borraco: 'borraço',
  borrada: 'borrada',
  borrado: 'borrado',
  borrais: 'borrais',
  borramo: 'borramo',
  borrara: 'borrara',
  borrata: 'borrata',
  borrate: 'borrate',
  borrato: 'borrato',
  borrava: 'borrava',
  borrefa: 'borrefa',
  borrefo: 'borrefo',
  borrega: 'borrega',
  borrego: 'borrego',
  borreis: 'borreis',
  borrejo: 'borrejo',
  borremo: 'borremo',
  borrica: 'borriça',
  borrice: 'borrice',
  borrico: 'borriço',
  borrifa: 'borrifa',
  borrife: 'borrife',
  borrifo: 'borrifo',
  borroes: 'borrões',
  borrosa: 'borrosa',
  borroso: 'borroso',
  bortala: 'bortalá',
  borusso: 'borusso',
  boscosa: 'boscosa',
  boscoso: 'boscoso',
  boseada: 'boseada',
  boseado: 'boseado',
  boseais: 'boseais',
  boseara: 'boseará',
  boseava: 'boseava',
  boseeis: 'boseeis',
  boseiam: 'boseiam',
  boseias: 'boseias',
  boseiem: 'boseiem',
  boseies: 'boseies',
  boseira: 'boseira',
  bosense: 'bosense',
  bosmina: 'bosmina',
  bosnias: 'bósnias',
  bosnios: 'bósnios',
  bosques: 'bosques',
  bossada: 'bossada',
  bossado: 'bossado',
  bossais: 'bossais',
  bossamo: 'bossamo',
  bossara: 'bossará',
  bossava: 'bossava',
  bosseis: 'bosseis',
  bossemo: 'bossemo',
  bossuda: 'bossuda',
  bossudo: 'bossudo',
  bostada: 'bostada',
  bostado: 'bostado',
  bostais: 'bostais',
  bostamo: 'bostamo',
  bostara: 'bostara',
  bostava: 'bostava',
  bosteai: 'bosteai',
  bostear: 'bostear',
  bosteei: 'bosteei',
  bosteia: 'bosteia',
  bosteie: 'bosteie',
  bosteio: 'bosteio',
  bosteis: 'bosteis',
  bosteja: 'bosteja',
  bosteje: 'bosteje',
  bostejo: 'bostejo',
  bostela: 'bostela',
  bostelo: 'bostelo',
  bostemo: 'bostemo',
  bosteou: 'bosteou',
  bostrix: 'bóstrix',
  botacao: 'botação',
  botadao: 'botadão',
  botadas: 'botadas',
  botadia: 'botadia',
  botador: 'botador',
  botados: 'botados',
  botaina: 'botaina',
  botalos: 'botalós',
  botamos: 'botamos',
  botanas: 'botanas',
  botando: 'botando',
  botante: 'botante',
  botaram: 'botaram',
  botarao: 'botarão',
  botaras: 'botaras',
  botarei: 'botarei',
  botarem: 'botarem',
  botares: 'botares',
  botareu: 'botaréu',
  botaria: 'botaria',
  botarmo: 'botarmo',
  botarra: 'botarra',
  botasse: 'botasse',
  botaste: 'botaste',
  botauro: 'botauro',
  botavam: 'botavam',
  botavas: 'botavas',
  botecai: 'botecai',
  botecam: 'botecam',
  botecar: 'botecar',
  botecas: 'botecas',
  botecos: 'botecos',
  botecou: 'botecou',
  botefas: 'botefas',
  boteifa: 'boteifa',
  boteina: 'boteina',
  boteira: 'boteira',
  boteiro: 'boteiro',
  botelgo: 'botelgo',
  botelha: 'botelha',
  botelho: 'botelho',
  botelva: 'botelva',
  botemos: 'botemos',
  botense: 'botense',
  boteque: 'boteque',
  botetos: 'botetos',
  boticao: 'boticão',
  boticas: 'boticas',
  boticos: 'botiços',
  botidea: 'botídea',
  botideo: 'botídeo',
  botieia: 'botieia',
  botieus: 'botieus',
  botijao: 'botijão',
  botijas: 'botijas',
  botilho: 'botilho',
  botinas: 'botinas',
  botinha: 'botinha',
  botinho: 'botinho',
  botinos: 'botinos',
  botirao: 'botirão',
  botoada: 'botoada',
  botoado: 'botoado',
  botoais: 'botoais',
  botoamo: 'botoamo',
  botoara: 'botoará',
  botoava: 'botoava',
  botocai: 'botocai',
  botocam: 'botocam',
  botocar: 'botocar',
  botocas: 'botocas',
  botocou: 'botocou',
  botoeis: 'botoeis',
  botoemo: 'botoemo',
  botoiro: 'botoiro',
  botoque: 'botoque',
  botoris: 'botoris',
  botriao: 'botrião',
  botrilo: 'botrilo',
  botrios: 'botrios',
  botruco: 'botruco',
  boubela: 'boubela',
  boucada: 'bouçada',
  boucado: 'bouçado',
  boucais: 'bouçais',
  boucamo: 'bouçamo',
  boucara: 'bouçará',
  boucava: 'bouçava',
  bouceis: 'bouceis',
  boucelo: 'boucelo',
  boucemo: 'boucemo',
  boucera: 'boucera',
  bouchai: 'bouchai',
  boucham: 'boucham',
  bouchar: 'bouchar',
  bouchas: 'bouchas',
  bouchea: 'bouchea',
  bouchei: 'bouchei',
  bouchem: 'bouchem',
  bouches: 'bouches',
  bouchou: 'bouchou',
  boucins: 'boucins',
  boudoir: 'boudoir',
  boueias: 'boueias',
  bougies: 'bougies',
  bouquet: 'bouquet',
  bourada: 'bourada',
  bourado: 'bourado',
  bourais: 'bourais',
  bouramo: 'bouramo',
  bourara: 'bourara',
  bourava: 'bourava',
  bourbon: 'bourbon',
  boureis: 'boureis',
  bouremo: 'bouremo',
  bourgia: 'bourgia',
  bourree: 'bourrée',
  boutade: 'boutade',
  bovidea: 'bovídea',
  bovideo: 'bovídeo',
  bovinas: 'bovinas',
  bovinea: 'bovínea',
  bovineo: 'bovíneo',
  bovinos: 'bovinos',
  bovista: 'bovista',
  boxadao: 'boxadão',
  boxadas: 'boxadas',
  boxador: 'boxador',
  boxados: 'boxados',
  boxamos: 'boxamos',
  boxando: 'boxando',
  boxante: 'boxante',
  boxaram: 'boxaram',
  boxarao: 'boxarão',
  boxaras: 'boxarás',
  boxarei: 'boxarei',
  boxarem: 'boxarem',
  boxares: 'boxares',
  boxaria: 'boxaria',
  boxarmo: 'boxarmo',
  boxasse: 'boxasse',
  boxaste: 'boxaste',
  boxavam: 'boxavam',
  boxavas: 'boxavas',
  boxeada: 'boxeada',
  boxeado: 'boxeado',
  boxeais: 'boxeais',
  boxeamo: 'boxeamo',
  boxeara: 'boxeara',
  boxeava: 'boxeava',
  boxeeis: 'boxeeis',
  boxeemo: 'boxeemo',
  boxegos: 'boxegos',
  boxeiam: 'boxeiam',
  boxeias: 'boxeias',
  boxeiem: 'boxeiem',
  boxeies: 'boxeies',
  boxemos: 'boxemos',
  boxismo: 'boxismo',
  boxista: 'boxista',
  bozeira: 'bozeira',
  bozenga: 'bozenga',
  bozerra: 'bozerra',
  brabada: 'brabada',
  brabado: 'brabado',
  brabais: 'brabais',
  brabamo: 'brabamo',
  brabara: 'brabara',
  brabava: 'brabava',
  brabeis: 'brabeis',
  brabejo: 'brabejo',
  brabemo: 'brabemo',
  brabeza: 'brabeza',
  brabila: 'brabila',
  brabosa: 'brabosa',
  braboso: 'braboso',
  brabura: 'brabura',
  bracada: 'braçada',
  bracado: 'braçado',
  bracaia: 'bracaiá',
  bracais: 'braçais',
  bracaja: 'bracajá',
  bracaro: 'brácaro',
  braceai: 'braceai',
  bracear: 'bracear',
  braceei: 'braceei',
  braceia: 'braceia',
  braceie: 'braceie',
  braceio: 'braceio',
  braceis: 'bracéis',
  braceja: 'braceja',
  braceje: 'braceje',
  bracejo: 'bracejo',
  braceou: 'braceou',
  bracera: 'bracera',
  braceus: 'braceus',
  bracios: 'bracios',
  bracobi: 'bracobi',
  bracoes: 'brações',
  bracola: 'braçola',
  bracona: 'braçona',
  bracons: 'brácons',
  bractea: 'bráctea',
  bracuda: 'braçuda',
  bracudo: 'braçudo',
  bracuis: 'bracuís',
  bradada: 'bradada',
  bradado: 'bradado',
  bradais: 'bradais',
  bradamo: 'bradamo',
  bradara: 'bradara',
  bradava: 'bradava',
  bradeas: 'brádeas',
  bradeis: 'bradeis',
  bradeja: 'bradeja',
  bradeje: 'bradeje',
  bradejo: 'bradejo',
  brademo: 'brademo',
  bradina: 'bradina',
  bradipo: 'brádipo',
  bradito: 'bradito',
  bragada: 'bragada',
  bragado: 'bragado',
  bragais: 'bragais',
  bragana: 'bragana',
  bragani: 'bragani',
  bragano: 'bragano',
  braguea: 'bragueá',
  bragues: 'braguês',
  braiera: 'braiera',
  braille: 'braille',
  brajola: 'brajola',
  bralhai: 'bralhai',
  bralham: 'bralham',
  bralhar: 'bralhar',
  bralhas: 'bralhas',
  bralhei: 'bralhei',
  bralhem: 'bralhem',
  bralhes: 'bralhes',
  bralhou: 'bralhou',
  bramada: 'bramada',
  bramado: 'bramado',
  bramais: 'bramais',
  bramamo: 'bramamo',
  bramane: 'brâmane',
  bramara: 'bramara',
  bramava: 'bramava',
  brameai: 'brameai',
  bramear: 'bramear',
  brameei: 'brameei',
  brameia: 'brameia',
  brameie: 'brameie',
  brameio: 'brameio',
  brameis: 'brameis',
  bramemo: 'bramemo',
  brameou: 'brameou',
  bramiam: 'bramiam',
  bramias: 'bramias',
  bramida: 'bramida',
  bramido: 'bramido',
  bramimo: 'bramimo',
  bramina: 'braminá',
  bramine: 'brâmine',
  bramira: 'bramira',
  bramosa: 'bramosa',
  bramoso: 'bramoso',
  bramota: 'bramota',
  brancal: 'brancal',
  brancao: 'brancão',
  brancas: 'brancas',
  brancor: 'brancor',
  brancos: 'brancos',
  brandai: 'brandai',
  brandal: 'brandal',
  brandam: 'brandam',
  brandao: 'brandão',
  brandar: 'brandar',
  brandas: 'brandas',
  brandea: 'brandeá',
  brandei: 'brandei',
  brandem: 'brandem',
  brandes: 'brandes',
  brandia: 'brandia',
  brandir: 'brandir',
  brandis: 'brandis',
  brandiu: 'brandiu',
  brandos: 'brandos',
  brandou: 'brandou',
  branhas: 'branhas',
  branjos: 'branjos',
  branqui: 'branqui',
  brantas: 'brantas',
  brantos: 'brantos',
  branzas: 'branzas',
  braquea: 'braqueá',
  braques: 'braquês',
  braquia: 'braquia',
  braquio: 'braquio',
  braquis: 'braquis',
  brarrum: 'brarrum',
  brasada: 'brasada',
  brasado: 'brasado',
  brasais: 'brasais',
  brasamo: 'brasamo',
  brasara: 'brasará',
  brasava: 'brasava',
  brascas: 'brascas',
  braseai: 'braseai',
  braseal: 'braseal',
  brasear: 'brasear',
  braseei: 'braseei',
  braseia: 'braseia',
  braseie: 'braseie',
  braseio: 'braseio',
  braseis: 'braseis',
  brasemo: 'brasemo',
  braseou: 'braseou',
  brasida: 'brasida',
  brasido: 'brasido',
  brasino: 'brasino',
  brasios: 'brasios',
  brasoai: 'brasoai',
  brasoam: 'brasoam',
  brasoar: 'brasoar',
  brasoas: 'brasoas',
  brasoei: 'brasoei',
  brasoem: 'brasoem',
  brasoes: 'brasões',
  brasona: 'brasona',
  brasone: 'brasone',
  brasono: 'brasono',
  brasoou: 'brasoou',
  brasuca: 'brasuca',
  brasume: 'brasume',
  braulas: 'braulas',
  braulia: 'bráulia',
  braunas: 'braúnas',
  bravada: 'bravada',
  bravado: 'bravado',
  bravais: 'bravais',
  bravamo: 'bravamo',
  bravara: 'bravará',
  bravata: 'bravata',
  bravava: 'bravava',
  braveai: 'braveai',
  bravear: 'bravear',
  braveei: 'braveei',
  braveia: 'braveia',
  braveie: 'braveie',
  braveio: 'braveio',
  braveis: 'braveis',
  braveja: 'braveja',
  braveje: 'braveje',
  bravejo: 'bravejo',
  bravemo: 'bravemo',
  braveou: 'braveou',
  braveza: 'braveza',
  bravias: 'bravias',
  bravios: 'bravios',
  bravito: 'bravito',
  bravosa: 'bravosa',
  bravoso: 'bravoso',
  bravuns: 'bravuns',
  bravura: 'bravura',
  braxias: 'braxias',
  braxton: 'braxton',
  brazabu: 'brazabu',
  breadao: 'breadão',
  breadas: 'breadas',
  breador: 'breador',
  breados: 'breados',
  breagem: 'breagem',
  breamos: 'breamos',
  breando: 'breando',
  breante: 'breante',
  brearam: 'brearam',
  brearao: 'brearão',
  brearas: 'brearás',
  brearei: 'brearei',
  brearem: 'brearem',
  breares: 'breares',
  brearia: 'brearia',
  breasse: 'breasse',
  breaste: 'breaste',
  breavam: 'breavam',
  breavas: 'breavas',
  brebiao: 'brebião',
  brecada: 'brecada',
  brecado: 'brecado',
  brecais: 'brecais',
  brecamo: 'brecamo',
  brecara: 'brecara',
  brecava: 'brecava',
  brechai: 'brechai',
  brecham: 'brecham',
  brechao: 'brechão',
  brechar: 'brechar',
  brechas: 'brechas',
  brechei: 'brechei',
  brechem: 'brechem',
  breches: 'breches',
  brechil: 'brechil',
  brechis: 'brechis',
  brechos: 'brechós',
  brechou: 'brechou',
  breeira: 'breeira',
  breeiro: 'breeiro',
  breemos: 'breemos',
  bregada: 'bregada',
  bregado: 'bregado',
  bregais: 'bregais',
  bregmal: 'bregmal',
  bregmas: 'bregmas',
  breinas: 'breínas',
  brejada: 'brejada',
  brejado: 'brejado',
  brejais: 'brejais',
  brejina: 'brejina',
  brejoes: 'brejões',
  brejosa: 'brejosa',
  brejoso: 'brejoso',
  brelhes: 'brelhes',
  brelhos: 'brelhos',
  brelina: 'brelina',
  brelins: 'brelins',
  brendos: 'brendos',
  brenhas: 'brenhas',
  brentos: 'brentos',
  brequei: 'brequei',
  brequem: 'brequem',
  breques: 'breques',
  brescas: 'brescas',
  breslau: 'breslau',
  breteau: 'breteau',
  bretema: 'brêtema',
  bretesa: 'bretesá',
  bretese: 'bretese',
  breteso: 'breteso',
  bretoes: 'bretões',
  breunos: 'breúnos',
  breveta: 'breveta',
  brevete: 'brevete',
  breveto: 'breveto',
  breveza: 'breveza',
  breviai: 'breviai',
  breviam: 'breviam',
  breviao: 'brevião',
  breviar: 'breviar',
  brevias: 'brevias',
  breviei: 'breviei',
  breviem: 'breviem',
  brevies: 'brevies',
  breviou: 'breviou',
  briacea: 'briácea',
  briaceo: 'briáceo',
  briadas: 'briadas',
  briales: 'briales',
  brianto: 'brianto',
  briareu: 'briareu',
  briatis: 'briatis',
  bribada: 'bribada',
  bribado: 'bribado',
  briches: 'briches',
  bricoes: 'bricões',
  bricola: 'bricola',
  bridada: 'bridada',
  bridado: 'bridado',
  bridais: 'bridais',
  bridamo: 'bridamo',
  bridara: 'bridará',
  bridava: 'bridava',
  bridege: 'brídege',
  brideis: 'brideis',
  bridemo: 'bridemo',
  bridoes: 'bridões',
  brieias: 'brieias',
  brigada: 'brigada',
  brigado: 'brigado',
  brigais: 'brigais',
  brigamo: 'brigamo',
  brigara: 'brigara',
  brigava: 'brigava',
  brigoes: 'brigões',
  brigona: 'brigona',
  brigosa: 'brigosa',
  brigoso: 'brigoso',
  briguei: 'briguei',
  briguem: 'briguem',
  brigues: 'brigues',
  brijara: 'brijara',
  brijoes: 'brijões',
  brilhai: 'brilhai',
  brilham: 'brilham',
  brilhar: 'brilhar',
  brilhas: 'brilhas',
  brilhei: 'brilhei',
  brilhem: 'brilhem',
  brilhes: 'brilhes',
  brilhos: 'brilhos',
  brilhou: 'brilhou',
  brimbao: 'brimbáo',
  brimbau: 'brimbau',
  brinase: 'brinase',
  brincai: 'brincai',
  brincam: 'brincam',
  brincao: 'brincão',
  brincar: 'brincar',
  brincas: 'brincas',
  brincos: 'brincos',
  brincou: 'brincou',
  brindai: 'brindai',
  brindam: 'brindam',
  brindao: 'brindão',
  brindar: 'brindar',
  brindas: 'brindas',
  brindei: 'brindei',
  brindem: 'brindem',
  brindes: 'brindes',
  brindou: 'brindou',
  brinhol: 'brinhol',
  brinjes: 'brinjes',
  brinque: 'brinque',
  brinzao: 'brinzão',
  briobia: 'brióbia',
  briobio: 'brióbio',
  briocai: 'briocai',
  briocam: 'briocam',
  briocar: 'briocar',
  briocas: 'briocas',
  brioche: 'brioche',
  briocou: 'briocou',
  brionia: 'briônia',
  briopse: 'briopse',
  brioque: 'brioque',
  briosas: 'briosas',
  briosma: 'briosma',
  briosos: 'briosos',
  briques: 'briques',
  brisada: 'brisada',
  brisado: 'brisado',
  brisais: 'brisais',
  brisamo: 'brisamo',
  brisara: 'brisará',
  brisava: 'brisava',
  briscas: 'briscas',
  brisego: 'brisego',
  briseis: 'briseis',
  brisemo: 'brisemo',
  brisios: 'brísios',
  brissos: 'brissos',
  bristol: 'bristol',
  britada: 'britada',
  britado: 'britado',
  britais: 'britais',
  britamo: 'britamo',
  britana: 'britana',
  britano: 'britano',
  britara: 'britará',
  britava: 'britava',
  briteis: 'briteis',
  britemo: 'britemo',
  britios: 'brítios',
  brivana: 'brivana',
  brivias: 'brívias',
  broacas: 'broaças',
  brocada: 'brocada',
  brocado: 'brocado',
  brocais: 'brocais',
  brocamo: 'brocamo',
  brocara: 'brocara',
  brocava: 'brocava',
  brochai: 'brochai',
  brocham: 'brocham',
  brochao: 'brochão',
  brochar: 'brochar',
  brochas: 'brochas',
  brochei: 'brochei',
  brochem: 'brochem',
  broches: 'broches',
  brochou: 'brochou',
  brociga: 'bróciga',
  brocoes: 'brocões',
  brocoio: 'brocoió',
  brocojo: 'brocojó',
  brocoli: 'brócoli',
  brocolo: 'brócolo',
  brocoto: 'brocotó',
  brodiea: 'brodíea',
  brodios: 'bródios',
  broegas: 'broegas',
  broeira: 'broeira',
  broeiro: 'broeiro',
  brofais: 'brofais',
  brogues: 'brogues',
  broinha: 'broinha',
  brolada: 'brolada',
  brolado: 'brolado',
  brolais: 'brolais',
  brolamo: 'brolamo',
  brolara: 'brolara',
  brolava: 'brolava',
  broleis: 'broleis',
  brolemo: 'brolemo',
  brolhai: 'brolhai',
  brolham: 'brolham',
  brolhar: 'brolhar',
  brolhas: 'brolhas',
  brolhei: 'brolhei',
  brolhem: 'brolhem',
  brolhes: 'brolhes',
  brolhos: 'brolhos',
  brolhou: 'brolhou',
  bromada: 'bromada',
  bromado: 'bromado',
  bromais: 'bromais',
  bromamo: 'bromamo',
  bromara: 'bromará',
  bromato: 'bromato',
  bromava: 'bromava',
  bromeis: 'bromeis',
  bromemo: 'bromemo',
  brometo: 'brometo',
  bromica: 'brômica',
  bromico: 'brômico',
  bromida: 'brômida',
  bromide: 'brômide',
  bromina: 'bromina',
  bromios: 'brômios',
  bromita: 'bromita',
  bromite: 'bromite',
  bromito: 'bromito',
  bromois: 'bromóis',
  bromuro: 'bromuro',
  broncas: 'broncas',
  broncia: 'brôncia',
  broncos: 'broncos',
  brongos: 'brongos',
  bronhas: 'bronhas',
  brontes: 'brontes',
  bronteu: 'bronteu',
  brontia: 'brôntia',
  bronzea: 'bronzeá',
  bronzeo: 'brônzeo',
  bronzes: 'bronzes',
  bronzos: 'bronzos',
  broquea: 'broqueá',
  broquei: 'broquei',
  broquel: 'broquel',
  broquem: 'broquem',
  broques: 'broques',
  broquia: 'bróquia',
  broquim: 'broquim',
  broscos: 'broscos',
  brosila: 'brosila',
  brosimo: 'brósimo',
  brosita: 'brosita',
  brosite: 'brosite',
  broslai: 'broslai',
  broslam: 'broslam',
  broslar: 'broslar',
  broslas: 'broslas',
  broslei: 'broslei',
  broslem: 'broslem',
  brosles: 'brosles',
  broslou: 'broslou',
  brosmio: 'brósmio',
  brossai: 'brossai',
  brossam: 'brossam',
  brossar: 'brossar',
  brossas: 'brossas',
  brossea: 'bróssea',
  brossei: 'brossei',
  brossem: 'brossem',
  brosses: 'brosses',
  brossou: 'brossou',
  brotada: 'brotada',
  brotado: 'brotado',
  brotais: 'brotais',
  brotamo: 'brotamo',
  brotara: 'brotara',
  brotava: 'brotava',
  broteas: 'bróteas',
  broteis: 'broteis',
  brotemo: 'brotemo',
  broteon: 'bróteon',
  broteos: 'bróteos',
  brotera: 'brotera',
  brotula: 'brótula',
  brousse: 'brousse',
  brownie: 'brownie',
  browser: 'browser',
  broxada: 'broxada',
  broxado: 'broxado',
  broxais: 'broxais',
  broxamo: 'broxamo',
  broxara: 'broxará',
  broxava: 'broxava',
  broxeis: 'broxeis',
  broxemo: 'broxemo',
  broxura: 'broxura',
  brozios: 'brózios',
  bruacas: 'bruacas',
  brucela: 'brucela',
  brucina: 'brucina',
  brucita: 'brucita',
  brucite: 'brucite',
  brucutu: 'brucutu',
  bruegas: 'bruegas',
  bruicos: 'bruíços',
  brulhas: 'brulhas',
  brulhos: 'brulhos',
  brulote: 'brulote',
  brumaca: 'brumaça',
  brumada: 'brumada',
  brumado: 'brumado',
  brumais: 'brumais',
  brumamo: 'brumamo',
  brumara: 'brumará',
  brumava: 'brumava',
  brumeis: 'brumeis',
  brumemo: 'brumemo',
  brumosa: 'brumosa',
  brumoso: 'brumoso',
  brunais: 'brunais',
  brunamo: 'brunamo',
  brunela: 'brunela',
  brunete: 'brunete',
  brunham: 'brunham',
  brunhas: 'brunhas',
  brunhem: 'brunhem',
  brunhes: 'brunhes',
  brunhia: 'brunhia',
  brunhir: 'brunhir',
  brunhis: 'brunhis',
  brunhiu: 'brunhiu',
  bruniam: 'bruniam',
  brunias: 'brunias',
  brunida: 'brunida',
  brunido: 'brunido',
  brunimo: 'brunimo',
  brunira: 'brunira',
  bruscas: 'bruscas',
  bruscos: 'bruscos',
  brusone: 'brusone',
  brussas: 'brussas',
  brutais: 'brutais',
  bruteai: 'bruteai',
  brutear: 'brutear',
  bruteei: 'bruteei',
  bruteia: 'bruteia',
  bruteie: 'bruteie',
  bruteio: 'bruteio',
  brutela: 'brutela',
  brutelo: 'brutelo',
  bruteou: 'bruteou',
  bruteza: 'bruteza',
  bruxada: 'bruxada',
  bruxado: 'bruxado',
  bruxais: 'bruxais',
  bruxamo: 'bruxamo',
  bruxara: 'bruxara',
  bruxava: 'bruxava',
  bruxeai: 'bruxeai',
  bruxear: 'bruxear',
  bruxedo: 'bruxedo',
  bruxeei: 'bruxeei',
  bruxeia: 'bruxeia',
  bruxeie: 'bruxeie',
  bruxeio: 'bruxeio',
  bruxeis: 'bruxeis',
  bruxemo: 'bruxemo',
  bruxeou: 'bruxeou',
  bruzelo: 'bruzelo',
  buagana: 'buágana',
  buaiida: 'buáiida',
  buambas: 'buambas',
  buanari: 'buanari',
  buanjas: 'buanjas',
  bubalos: 'búbalos',
  bubania: 'bubânia',
  bubelas: 'bubelas',
  bubento: 'bubento',
  bubidas: 'bubidas',
  bubinga: 'bubinga',
  bubonia: 'bubônia',
  bubonio: 'bubônio',
  bubuiai: 'bubuiai',
  bubuiam: 'bubuiam',
  bubuiar: 'bubuiar',
  bubuias: 'bubuias',
  bubuiei: 'bubuiei',
  bubuiem: 'bubuiem',
  bubuies: 'bubuies',
  bubuiou: 'bubuiou',
  bubulas: 'bubulas',
  bubulco: 'bubulco',
  bubures: 'buburés',
  bucafer: 'bucáfer',
  bucalai: 'buçalai',
  bucalam: 'buçalam',
  bucalar: 'buçalar',
  bucalas: 'buçalas',
  bucalei: 'buçalei',
  bucalem: 'buçalem',
  bucales: 'buçales',
  bucalou: 'buçalou',
  bucanhe: 'bucanhe',
  bucarda: 'buçarda',
  bucares: 'bucares',
  bucaria: 'bucária',
  bucelar: 'bucelar',
  bucelas: 'bucelas',
  buceros: 'búceros',
  bucetos: 'bucetos',
  buchada: 'buchada',
  buchado: 'buchado',
  buchela: 'buchela',
  buchete: 'buchete',
  buchins: 'buchins',
  buchita: 'buchita',
  buchite: 'buchite',
  buchona: 'buchona',
  buchuda: 'buchuda',
  buchudo: 'buchudo',
  bucidas: 'bucidas',
  bucinas: 'bucinas',
  bucinho: 'bucinho',
  bucinos: 'bucinos',
  bucobus: 'bucobus',
  bucorax: 'bucórax',
  bucuiba: 'bucuibá',
  buculas: 'búculas',
  bucumbi: 'bucumbi',
  bucurau: 'bucurau',
  bucutas: 'bucutás',
  bucuuva: 'bucuuva',
  bucuvas: 'bucuvas',
  budanas: 'budanas',
  budicas: 'búdicas',
  budicos: 'búdicos',
  budinos: 'budinos',
  budioes: 'budiões',
  budismo: 'budismo',
  budista: 'budista',
  budites: 'budites',
  budleia: 'budleia',
  budoada: 'budoada',
  budoado: 'budoado',
  budoais: 'budoais',
  budoamo: 'budoamo',
  budoara: 'budoará',
  budoava: 'budoava',
  budoeis: 'budoeis',
  budoemo: 'budoemo',
  budunas: 'budunas',
  bueiras: 'bueiras',
  bueiros: 'bueiros',
  buelias: 'buélias',
  buellia: 'buéllia',
  buenaco: 'buenaço',
  buendes: 'buendes',
  buenina: 'buenina',
  buerere: 'buereré',
  buerres: 'buerres',
  buezoes: 'buezões',
  bufadao: 'bufadão',
  bufadas: 'bufadas',
  bufador: 'bufador',
  bufados: 'bufados',
  bufalas: 'búfalas',
  bufalos: 'búfalos',
  bufamos: 'bufamos',
  bufando: 'bufando',
  bufante: 'bufante',
  bufaram: 'bufaram',
  bufarao: 'bufarão',
  bufaras: 'bufaras',
  bufarei: 'bufarei',
  bufarem: 'bufarem',
  bufares: 'bufares',
  bufaria: 'bufaria',
  bufarmo: 'bufarmo',
  bufarra: 'bufarra',
  bufasse: 'bufasse',
  bufaste: 'bufaste',
  bufavam: 'bufavam',
  bufavas: 'bufavas',
  bufeira: 'bufeira',
  bufemos: 'bufemos',
  bufento: 'bufento',
  bufetes: 'bufetes',
  bufidao: 'bufidão',
  bufidas: 'bufidas',
  bufidor: 'bufidor',
  bufidos: 'bufidos',
  bufinas: 'bufinas',
  bufindo: 'bufindo',
  bufiram: 'bufiram',
  bufirao: 'bufirão',
  bufirem: 'bufirem',
  bufiria: 'bufiria',
  bufisse: 'bufisse',
  bufonas: 'bufonas',
  bufonea: 'bufoneá',
  bufonia: 'bufônia',
  bufosas: 'bufosas',
  bufosos: 'bufosos',
  bufunfa: 'bufunfa',
  bugacho: 'bugacho',
  bugalha: 'bugalha',
  bugalho: 'bugalho',
  buganda: 'buganda',
  bugdios: 'bugdiós',
  bugegos: 'bugegos',
  bugiada: 'bugiada',
  bugiado: 'bugiado',
  bugiais: 'bugiais',
  bugiamo: 'bugiamo',
  bugiara: 'bugiará',
  bugiava: 'bugiava',
  bugieis: 'bugieis',
  bugiemo: 'bugiemo',
  buginas: 'buginas',
  bugrada: 'bugrada',
  bugrina: 'bugrina',
  bugrino: 'bugrino',
  bugulas: 'búgulas',
  buiamos: 'buíamos',
  buibila: 'buibila',
  buibile: 'buibile',
  buibilo: 'buibilo',
  buidoes: 'buidões',
  buidona: 'buidona',
  buidora: 'buidora',
  buinhos: 'buinhos',
  buirdes: 'buirdes',
  buireis: 'buireis',
  buiriam: 'buiriam',
  buirias: 'buirias',
  buirmos: 'buirmos',
  buissem: 'buíssem',
  buisses: 'buísses',
  buistes: 'buístes',
  buitras: 'buítras',
  buitres: 'buítres',
  buiucus: 'buiuçus',
  bujagos: 'bujagos',
  bujames: 'bujamés',
  bujanga: 'bujanga',
  bujarda: 'bujarda',
  bujinga: 'bujinga',
  buladao: 'buladão',
  buladas: 'buladas',
  bulador: 'bulador',
  bulados: 'bulados',
  bulalas: 'bulalas',
  bulamas: 'bulamas',
  bulamos: 'bulamos',
  bulando: 'bulando',
  bulante: 'bulante',
  bularam: 'bularam',
  bularao: 'bularão',
  bularas: 'bularás',
  bularei: 'bularei',
  bularem: 'bularem',
  bulares: 'bulares',
  bularia: 'bularia',
  bulario: 'bulário',
  bularmo: 'bularmo',
  bulasse: 'bulasse',
  bulaste: 'bulaste',
  bulatai: 'bulatai',
  bulatam: 'bulatam',
  bulatar: 'bulatar',
  bulatas: 'bulatas',
  bulatei: 'bulatei',
  bulatem: 'bulatem',
  bulates: 'bulates',
  bulatou: 'bulatou',
  bulaues: 'bulauês',
  bulavam: 'bulavam',
  bulavas: 'bulavas',
  bulaxos: 'bulaxos',
  bulbilo: 'bulbilo',
  bulbina: 'bulbina',
  bulbite: 'bulbite',
  bulbosa: 'bulbosa',
  bulboso: 'bulboso',
  bulbuis: 'bulbuis',
  bulbulo: 'bulbulo',
  bulcoes: 'bulcões',
  buldras: 'buldras',
  buleira: 'buleira',
  buleiro: 'buleiro',
  bulemos: 'bulemos',
  buleses: 'buleses',
  bulevai: 'bulevai',
  bulevam: 'bulevam',
  bulevar: 'bulevar',
  bulevas: 'bulevas',
  bulevei: 'bulevei',
  bulevem: 'bulevem',
  buleves: 'buleves',
  bulevou: 'bulevou',
  bulgara: 'búlgara',
  bulgaro: 'búlgaro',
  bulhaco: 'bulhaco',
  bulhada: 'bulhada',
  bulhado: 'bulhado',
  bulhais: 'bulhais',
  bulhamo: 'bulhamo',
  bulhara: 'bulhara',
  bulhava: 'bulhava',
  bulheis: 'bulheis',
  bulhemo: 'bulhemo',
  bulhoes: 'bulhões',
  bulhona: 'bulhona',
  bulicio: 'bulício',
  bulicos: 'buliços',
  bulidao: 'bulidão',
  bulidas: 'bulidas',
  bulidea: 'bulídea',
  bulideo: 'bulídeo',
  bulidor: 'bulidor',
  bulidos: 'bulidos',
  bulieis: 'bulíeis',
  bulimia: 'bulimia',
  bulimos: 'bulimos',
  bulindo: 'bulindo',
  bulinos: 'bulinos',
  buliram: 'buliram',
  bulirao: 'bulirão',
  buliras: 'buliras',
  bulirei: 'bulirei',
  bulirem: 'bulirem',
  bulires: 'bulires',
  buliria: 'buliria',
  bulirmo: 'bulirmo',
  bulisse: 'bulisse',
  bulista: 'bulista',
  buliste: 'buliste',
  bulites: 'bulites',
  bulolos: 'bulolos',
  buloses: 'buloses',
  bultrim: 'bultrim',
  bulufas: 'bulufas',
  bululus: 'bululus',
  bulunga: 'bulunga',
  bulzoes: 'bulzões',
  bumbada: 'bumbada',
  bumbado: 'bumbado',
  bumbais: 'bumbais',
  bumbamo: 'bumbamo',
  bumbara: 'bumbará',
  bumbava: 'bumbava',
  bumbeis: 'bumbeis',
  bumbemo: 'bumbemo',
  bumbuns: 'bumbuns',
  bumelia: 'bumélia',
  bunchos: 'bunchos',
  bundaca: 'bundaça',
  bundada: 'bundada',
  bundado: 'bundado',
  bundais: 'bundais',
  bundamo: 'bundamo',
  bundana: 'bundana',
  bundara: 'bundara',
  bundava: 'bundava',
  bundeai: 'bundeai',
  bundear: 'bundear',
  bundeei: 'bundeei',
  bundeia: 'bundeia',
  bundeie: 'bundeie',
  bundeio: 'bundeio',
  bundeis: 'bundeis',
  bundemo: 'bundemo',
  bundeou: 'bundeou',
  bundoes: 'bundões',
  bundona: 'bundona',
  bundras: 'bundras',
  bunduda: 'bunduda',
  bundudo: 'bundudo',
  bunevas: 'bunevas',
  bungada: 'bungada',
  bungado: 'bungado',
  bungais: 'bungais',
  bungama: 'bungama',
  bungara: 'bungará',
  bungava: 'bungava',
  bunguei: 'bunguei',
  bunguem: 'bunguem',
  bungues: 'bungues',
  bungula: 'bungula',
  bungule: 'bungule',
  bungulo: 'bungulo',
  bunhada: 'bunhada',
  bunhado: 'bunhado',
  bunhais: 'bunhais',
  bunhamo: 'bunhamo',
  bunhara: 'bunhará',
  bunhava: 'bunhava',
  bunhedo: 'bunhedo',
  bunheis: 'bunheis',
  bunhemo: 'bunhemo',
  bunicas: 'bunicas',
  bunidea: 'bunídea',
  bunideo: 'bunídeo',
  bunodes: 'bunodes',
  bunoide: 'bunoide',
  bunolol: 'bunolol',
  bunquer: 'búnquer',
  bunraku: 'bunraku',
  buquete: 'buquete',
  buquina: 'buquina',
  buquine: 'buquine',
  buquino: 'buquino',
  burabas: 'burabas',
  buracai: 'buracai',
  buracam: 'buracam',
  buracao: 'buracão',
  buracar: 'buracar',
  buracas: 'buracas',
  buracos: 'buracos',
  buracou: 'buracou',
  buramos: 'buramos',
  buranda: 'buranda',
  buraque: 'buraque',
  buraras: 'buraras',
  buratos: 'buratos',
  burbons: 'burbons',
  bureaus: 'bureaus',
  burelas: 'burelas',
  buretas: 'buretas',
  burevas: 'burevas',
  burgada: 'burgada',
  burgado: 'burgado',
  burgais: 'burgais',
  burgara: 'burgará',
  burgaus: 'burgaus',
  burgava: 'burgava',
  burguei: 'burguei',
  burguem: 'burguem',
  burgues: 'burguês',
  buriata: 'buriata',
  buriato: 'buriato',
  burieis: 'buriéis',
  burilai: 'burilai',
  burilam: 'burilam',
  burilar: 'burilar',
  burilas: 'burilas',
  burilei: 'burilei',
  burilem: 'burilem',
  buriles: 'buriles',
  burilou: 'burilou',
  burinos: 'burinos',
  buriqui: 'buriqui',
  buritis: 'buritis',
  burizal: 'burizal',
  burjaca: 'burjaca',
  burlada: 'burlada',
  burlado: 'burlado',
  burlais: 'burlais',
  burlamo: 'burlamo',
  burlara: 'burlara',
  burlava: 'burlava',
  burleis: 'burleis',
  burlemo: 'burlemo',
  burleta: 'burleta',
  burlete: 'burlete',
  burlina: 'burlina',
  burloes: 'burlões',
  burlona: 'burlona',
  burlosa: 'burlosa',
  burloso: 'burloso',
  burmesa: 'burmesa',
  burmita: 'burmita',
  burmite: 'burmite',
  burnais: 'burnais',
  burnamo: 'burnamo',
  burniam: 'burniam',
  burnias: 'burnias',
  burnida: 'burnida',
  burnido: 'burnido',
  burnimo: 'burnimo',
  burnira: 'burnira',
  burraca: 'burraca',
  burrada: 'burrada',
  burrado: 'burrado',
  burrais: 'burrais',
  burrama: 'burrama',
  burramo: 'burramo',
  burrana: 'burrana',
  burrara: 'burrará',
  burrava: 'burrava',
  burreca: 'burreca',
  burreco: 'burreco',
  burrega: 'burrega',
  burrego: 'burrego',
  burreis: 'burreis',
  burremo: 'burremo',
  burreza: 'burreza',
  burrica: 'burrica',
  burrice: 'burrice',
  burrico: 'burrico',
  burries: 'burriés',
  burrito: 'burrito',
  burroes: 'burrões',
  burrona: 'burrona',
  bursera: 'búrsera',
  bursina: 'bursina',
  bursite: 'bursite',
  buruaca: 'buruaca',
  burucus: 'burucus',
  burudes: 'burudes',
  buruque: 'buruquê',
  burures: 'bururés',
  bururus: 'bururus',
  burusos: 'burusos',
  burusso: 'burusso',
  burutas: 'burutas',
  busanas: 'busanas',
  busanos: 'busanos',
  busardo: 'busardo',
  buscada: 'buscada',
  buscado: 'buscado',
  buscais: 'buscais',
  buscamo: 'buscamo',
  buscape: 'buscapé',
  buscara: 'buscara',
  buscava: 'buscava',
  buscula: 'busculá',
  buscule: 'buscule',
  busculo: 'busculo',
  buseira: 'buseira',
  buseiro: 'buseiro',
  busilis: 'busílis',
  busquei: 'busquei',
  busquem: 'busquem',
  busques: 'busques',
  bussola: 'bússola',
  bussole: 'bussole',
  bussolo: 'bussolo',
  busties: 'bustiês',
  bustita: 'bustita',
  bustite: 'bustite',
  buszita: 'buszita',
  butacas: 'butacas',
  butadao: 'butadão',
  butadas: 'butadas',
  butador: 'butador',
  butados: 'butados',
  butamos: 'butamos',
  butanal: 'butanal',
  butanas: 'butanas',
  butando: 'butando',
  butanes: 'butanês',
  butanio: 'butânio',
  butanis: 'butanis',
  butanol: 'butanol',
  butanos: 'butanos',
  butante: 'butante',
  butaram: 'butaram',
  butarao: 'butarão',
  butaras: 'butarás',
  butarei: 'butarei',
  butarem: 'butarem',
  butares: 'butares',
  butarga: 'butarga',
  butaria: 'butaria',
  butarmo: 'butarmo',
  butasse: 'butasse',
  butaste: 'butaste',
  butavam: 'butavam',
  butavas: 'butavas',
  buteias: 'buteias',
  buteira: 'buteira',
  buteiro: 'buteiro',
  butelos: 'butelos',
  butemos: 'butemos',
  butenos: 'butenos',
  buteoes: 'buteões',
  butergo: 'butergo',
  butessa: 'butessa',
  buticas: 'buticas',
  buticos: 'buticos',
  butidea: 'butídea',
  butideo: 'butídeo',
  butidio: 'butídio',
  butigas: 'butigas',
  butilas: 'butilas',
  butilio: 'butílio',
  butilos: 'butilos',
  butinha: 'butinha',
  butinos: 'butinos',
  butique: 'butique',
  butiral: 'butiral',
  butirao: 'butirão',
  butiril: 'butíril',
  butiris: 'butíris',
  butiros: 'butiros',
  butomos: 'bútomos',
  butonga: 'butonga',
  butoris: 'butoris',
  butucai: 'butucai',
  butucam: 'butucam',
  butucar: 'butucar',
  butucas: 'butucas',
  butucou: 'butucou',
  butucum: 'butucum',
  butuque: 'butuque',
  buvuari: 'buvuari',
  buxacea: 'buxácea',
  buxaceo: 'buxáceo',
  buxales: 'buxales',
  buxeira: 'buxeira',
  buxeiro: 'buxeiro',
  buxetes: 'buxetes',
  buxinas: 'buxinas',
  buxinea: 'buxínea',
  buxineo: 'buxíneo',
  buxinha: 'buxinha',
  buxiqui: 'buxiqui',
  buxuari: 'buxuari',
  buzanfa: 'buzanfã',
  buzegai: 'buzegai',
  buzegam: 'buzegam',
  buzegar: 'buzegar',
  buzegas: 'buzegas',
  buzegou: 'buzegou',
  buzegue: 'buzegue',
  buzeira: 'buzeira',
  buzeiro: 'buzeiro',
  buzenos: 'buzenos',
  buzeras: 'búzeras',
  buziada: 'buziada',
  buziado: 'buziado',
  buziais: 'buziais',
  buziamo: 'buziamo',
  buziara: 'buziará',
  buziava: 'buziava',
  buzieis: 'buzieis',
  buziemo: 'buziemo',
  buzinai: 'buzinai',
  buzinam: 'buzinam',
  buzinao: 'buzinão',
  buzinar: 'buzinar',
  buzinas: 'buzinas',
  buzinei: 'buzinei',
  buzinem: 'buzinem',
  buzines: 'buzines',
  buzinou: 'buzinou',
  buzitas: 'buzitas',
  buzugos: 'buzugos',
  buzulai: 'buzulai',
  buzulam: 'buzulam',
  buzular: 'buzular',
  buzulas: 'buzulas',
  buzulei: 'buzulei',
  buzulem: 'buzulem',
  buzules: 'buzules',
  buzulou: 'buzulou',
  caacica: 'caacica',
  caacuis: 'caacuís',
  caaetes: 'caaetês',
  caainga: 'caaingá',
  caandas: 'caandas',
  caanema: 'caanema',
  caantio: 'caantio',
  caaopia: 'caaopiá',
  caapara: 'caapara',
  caapeba: 'caapeba',
  caapena: 'caapená',
  caapeno: 'caapeno',
  caapeua: 'caapéua',
  caapeva: 'caapeva',
  caapias: 'caapiás',
  caapoas: 'caapoãs',
  caapora: 'caapora',
  caapuas: 'caapuãs',
  caapuca: 'caapucá',
  caarina: 'caarina',
  caaroba: 'caaroba',
  caataia: 'caataia',
  caatica: 'caatica',
  caatita: 'caatita',
  caaueti: 'caaueti',
  caaxios: 'caaxiós',
  caaxira: 'caaxira',
  cabaacu: 'cabaaçu',
  cababas: 'cababas',
  cabacal: 'cabaçal',
  cabacao: 'cabação',
  cabacas: 'cabaças',
  cabacea: 'cabaceá',
  cabacos: 'cabaços',
  cabacus: 'cabaçus',
  cabadas: 'cabadas',
  cabaias: 'cabaias',
  cabaiba: 'cabaíba',
  cabaiva: 'cabaíva',
  cabalai: 'cabalai',
  cabalam: 'cabalam',
  cabalar: 'cabalar',
  cabalas: 'cabalas',
  cabalei: 'cabalei',
  cabalem: 'cabalem',
  cabales: 'cabales',
  cabalia: 'cabália',
  cabalim: 'cabalim',
  cabalou: 'cabalou',
  cabamba: 'cabamba',
  cabampe: 'cabampé',
  cabanal: 'cabanal',
  cabanao: 'cabanão',
  cabanas: 'cabanas',
  cabanel: 'cabanel',
  cabanes: 'cabanes',
  cabanga: 'cabanga',
  cabanha: 'cabanha',
  cabanil: 'cabanil',
  cabanis: 'cabanis',
  cabanos: 'cabanos',
  cabares: 'cabarés',
  cabaret: 'cabaret',
  cabarro: 'cabarro',
  cabatas: 'cabatãs',
  cabazes: 'cabazes',
  cabazia: 'cabázia',
  cabazio: 'cabázio',
  cabeada: 'cabeada',
  cabeado: 'cabeado',
  cabeais: 'cabeais',
  cabeara: 'cabeará',
  cabeava: 'cabeava',
  cabecai: 'cabeçai',
  cabecal: 'cabeçal',
  cabecam: 'cabeçam',
  cabecao: 'cabeção',
  cabecar: 'cabeçar',
  cabecas: 'cabeças',
  cabecea: 'cabeceá',
  cabecei: 'cabecei',
  cabecel: 'cabecel',
  cabecem: 'cabecem',
  cabeces: 'cabecês',
  cabecos: 'cabeços',
  cabecou: 'cabeçou',
  cabedai: 'cabedai',
  cabedal: 'cabedal',
  cabedam: 'cabedam',
  cabedar: 'cabedar',
  cabedas: 'cabedas',
  cabedei: 'cabedei',
  cabedem: 'cabedem',
  cabedes: 'cabedes',
  cabedor: 'cabedor',
  cabedou: 'cabedou',
  cabeeis: 'cabeeis',
  cabeiam: 'cabeiam',
  cabeias: 'cabeias',
  cabeiem: 'cabeiem',
  cabeies: 'cabeies',
  cabeira: 'cabeira',
  cabeiro: 'cabeiro',
  cabelas: 'cabelas',
  cabelos: 'cabelos',
  cabemos: 'cabemos',
  cabenda: 'cabenda',
  cabendo: 'cabendo',
  cabenga: 'cabenga',
  cabense: 'cabense',
  cabente: 'cabente',
  caberao: 'caberão',
  caberas: 'caberás',
  caberei: 'caberei',
  caberem: 'caberem',
  caberes: 'caberes',
  caberia: 'caberia',
  cabeuas: 'cabeuas',
  cabiais: 'cabiais',
  cabidao: 'cabidão',
  cabidas: 'cabidas',
  cabides: 'cabides',
  cabidos: 'cabidos',
  cabieis: 'cabíeis',
  cabilao: 'cabilão',
  cabilas: 'cabilas',
  cabilda: 'cabilda',
  cabinas: 'cabinas',
  cabinda: 'cabinda',
  cabines: 'cabines',
  cabinho: 'cabinho',
  cabiras: 'cabiras',
  cabiris: 'cabíris',
  cabista: 'cabista',
  cabitus: 'cabitus',
  cabiuna: 'cabiúna',
  cabivel: 'cabível',
  cabixis: 'cabixis',
  cablada: 'cablada',
  cablado: 'cablado',
  cablais: 'cablais',
  cablamo: 'cablamo',
  cablara: 'cablará',
  cablava: 'cablava',
  cableis: 'cableis',
  cablemo: 'cablemo',
  caboata: 'caboatã',
  cabobas: 'cabobas',
  cabobos: 'cabobos',
  cabocir: 'cabocir',
  cabocla: 'cabocla',
  caboclo: 'caboclo',
  cabocos: 'cabocós',
  cabodas: 'cabodás',
  cabodis: 'cabódis',
  caboila: 'caboila',
  cabojes: 'cabojes',
  caboles: 'caboles',
  cabomba: 'cabomba',
  cabombo: 'cabombo',
  cabonde: 'cabonde',
  cabores: 'caborés',
  caborge: 'caborge',
  caborje: 'caborje',
  caborta: 'caborta',
  caborte: 'caborte',
  caborto: 'caborto',
  cabotai: 'cabotai',
  cabotam: 'cabotam',
  cabotar: 'cabotar',
  cabotas: 'cabotas',
  cabotei: 'cabotei',
  cabotem: 'cabotem',
  cabotes: 'cabotes',
  cabotou: 'cabotou',
  cabouca: 'cabouca',
  cabouco: 'cabouco',
  caboupa: 'caboupa',
  cabozes: 'cabozes',
  cabrada: 'cabrada',
  cabrado: 'cabrado',
  cabraia: 'cabraia',
  cabrais: 'cabrais',
  cabramo: 'cabramo',
  cabrara: 'cabrara',
  cabrava: 'cabrava',
  cabraza: 'cabrazá',
  cabraze: 'cabraze',
  cabrazo: 'cabrazo',
  cabreai: 'cabreai',
  cabrear: 'cabrear',
  cabreas: 'cábreas',
  cabreei: 'cabreei',
  cabreia: 'cabreia',
  cabreie: 'cabreie',
  cabreio: 'cabreio',
  cabreis: 'cabreis',
  cabreja: 'cabreja',
  cabreje: 'cabreje',
  cabrejo: 'cabrejo',
  cabrema: 'cabrema',
  cabremo: 'cabremo',
  cabreou: 'cabreou',
  cabreta: 'cabreta',
  cabriao: 'cabrião',
  cabrice: 'cabrice',
  cabrins: 'cabrins',
  cabrita: 'cabrita',
  cabrite: 'cabrite',
  cabrito: 'cabrito',
  cabroca: 'cabroca',
  cabroco: 'cabroco',
  cabroes: 'cabrões',
  cabrona: 'cabrona',
  cabrual: 'cabrual',
  cabruas: 'cabruás',
  cabruca: 'cabruca',
  cabruco: 'cabruco',
  cabrues: 'cabrués',
  cabruno: 'cabruno',
  cabruns: 'cabruns',
  cabuata: 'cabuatã',
  cabucho: 'cabucho',
  cabucus: 'cabuçus',
  cabueta: 'cabueta',
  cabuete: 'cabuete',
  cabueto: 'cabueto',
  cabugao: 'cabugão',
  cabuias: 'cabuias',
  cabuiba: 'cabuíba',
  cabuins: 'cabuins',
  cabulai: 'cabulai',
  cabulam: 'cabulam',
  cabular: 'cabular',
  cabulas: 'cábulas',
  cabulei: 'cabulei',
  cabulem: 'cabulem',
  cabules: 'cabules',
  cabulou: 'cabulou',
  cabumba: 'cabumba',
  cabumbo: 'cabumbo',
  cabunda: 'cabundá',
  cabundo: 'cabundo',
  cabungo: 'cabungo',
  cabuqui: 'cabúqui',
  cabures: 'caburés',
  caburis: 'caburis',
  caburos: 'caburos',
  cabutas: 'cabutas',
  cacacao: 'cacação',
  cacadao: 'caçadão',
  cacadas: 'caçadas',
  cacador: 'caçador',
  cacados: 'caçados',
  cacaios: 'cacaios',
  cacajau: 'cacajau',
  cacajos: 'cacajos',
  cacalia: 'cacália',
  cacamba: 'caçamba',
  cacambe: 'caçambe',
  cacambo: 'caçambo',
  cacamos: 'caçamos',
  cacanas: 'cacanas',
  cacando: 'caçando',
  cacanje: 'caçanje',
  cacante: 'caçante',
  cacaosa: 'cacaosa',
  cacaoso: 'cacaoso',
  cacapas: 'caçapas',
  cacapea: 'caçapeá',
  cacapos: 'caçapos',
  cacaram: 'caçaram',
  cacarao: 'caçarão',
  cacaras: 'caçarás',
  cacarea: 'cacareá',
  cacarei: 'caçarei',
  cacarel: 'cacarel',
  cacarem: 'caçarem',
  cacares: 'caçares',
  cacareu: 'cacaréu',
  cacaria: 'cacaria',
  cacarmo: 'caçarmo',
  cacasse: 'caçasse',
  cacaste: 'caçaste',
  cacatas: 'cacatas',
  cacatua: 'cacatua',
  cacatus: 'cacatus',
  cacaual: 'cacaual',
  cacaues: 'cacauês',
  cacauis: 'cacauís',
  cacavam: 'caçavam',
  cacavas: 'caçavas',
  caceada: 'caceada',
  caceado: 'caceado',
  caceais: 'caceais',
  caceara: 'caceará',
  caceava: 'caceava',
  cacebis: 'cacebis',
  caceeis: 'caceeis',
  caceiam: 'caceiam',
  caceias: 'caceias',
  caceiem: 'caceiem',
  caceies: 'caceies',
  cacembe: 'cacembe',
  cacemos: 'cacemos',
  caceras: 'caceras',
  cacetai: 'cacetai',
  cacetam: 'cacetam',
  cacetao: 'cacetão',
  cacetar: 'cacetar',
  cacetas: 'cacetas',
  cacetea: 'caceteá',
  cacetei: 'cacetei',
  cacetem: 'cacetem',
  cacetes: 'cacetes',
  cacetou: 'cacetou',
  cachabu: 'cachabu',
  cachaca: 'cachaça',
  cachaco: 'cachaço',
  cachada: 'cachada',
  cachado: 'cachado',
  cachais: 'cachais',
  cachamo: 'cachamo',
  cachara: 'cachara',
  cachari: 'cachari',
  cachava: 'cachava',
  cacheai: 'cacheai',
  cachear: 'cachear',
  cacheei: 'cacheei',
  cacheia: 'cacheia',
  cacheie: 'cacheie',
  cacheio: 'cacheio',
  cacheis: 'cacheis',
  cachemo: 'cachemo',
  cachene: 'cachené',
  cacheou: 'cacheou',
  cachepo: 'cachepô',
  cachera: 'cacherá',
  cacheta: 'cacheta',
  cachete: 'cachete',
  cacheto: 'cacheto',
  cachiam: 'cachiam',
  cachias: 'cachias',
  cachica: 'cachiça',
  cachico: 'cachiço',
  cachida: 'cachida',
  cachido: 'cachido',
  cachima: 'cachimã',
  cachimo: 'cachimo',
  cachina: 'cachina',
  cachine: 'cachine',
  cachino: 'cachino',
  cachins: 'cachins',
  cachira: 'cachirá',
  cachoai: 'cachoai',
  cachoam: 'cachoam',
  cachoar: 'cachoar',
  cachoas: 'cachoas',
  cachoca: 'cachoça',
  cachoei: 'cachoei',
  cachoem: 'cachoem',
  cachoes: 'cachões',
  cachola: 'cachola',
  cacholo: 'cacholo',
  cachoou: 'cachoou',
  cachopa: 'cachopa',
  cachopo: 'cachopo',
  cachuba: 'cachuba',
  cachubo: 'cachubo',
  cachuca: 'cachuça',
  cachuda: 'cachuda',
  cachudo: 'cachudo',
  cachuis: 'cachuis',
  cachulo: 'cachulo',
  cachupa: 'cachupa',
  cacicai: 'cacicai',
  cacical: 'cacical',
  cacicam: 'cacicam',
  cacicar: 'cacicar',
  cacicas: 'cacicas',
  cacicou: 'cacicou',
  cacifai: 'cacifai',
  cacifam: 'cacifam',
  cacifar: 'cacifar',
  cacifas: 'cacifas',
  cacifei: 'cacifei',
  cacifem: 'cacifem',
  cacifes: 'cacifes',
  cacifos: 'cacifos',
  cacifou: 'cacifou',
  cacifre: 'cacifre',
  cacifro: 'cacifro',
  cacilda: 'cacilda',
  cacilho: 'cacilho',
  cacimba: 'cacimba',
  cacimbe: 'cacimbe',
  cacimbo: 'cacimbo',
  cacimos: 'cácimos',
  cacique: 'cacique',
  cacites: 'cacites',
  cacizes: 'cacizes',
  cacoada: 'caçoada',
  cacoado: 'caçoado',
  cacoais: 'caçoais',
  cacoamo: 'caçoamo',
  cacoara: 'caçoara',
  cacoava: 'caçoava',
  cacocos: 'cacocos',
  cacoeis: 'caçoeis',
  cacoemo: 'caçoemo',
  cacoete: 'cacoete',
  cacofia: 'cacofia',
  cacoila: 'caçoila',
  cacoilo: 'caçoilo',
  cacoiro: 'caçoiro',
  cacolas: 'cacolas',
  cacolim: 'cacolim',
  cacomes: 'cacomes',
  caconas: 'caconas',
  caconda: 'caconda',
  caconde: 'cacondê',
  cacongo: 'cacongo',
  caconso: 'caconso',
  cacopas: 'cacopas',
  cacoria: 'cacória',
  cacorio: 'cacório',
  cacosas: 'cacosas',
  cacosos: 'cacosos',
  cacoula: 'caçoula',
  cacoulo: 'caçoulo',
  cacouro: 'caçouro',
  cacoxis: 'cacóxis',
  cacozao: 'cacózão',
  cactais: 'cactais',
  cactale: 'cactale',
  cactina: 'cactina',
  cactino: 'cactino',
  cacuais: 'cacuais',
  cacuala: 'cacuala',
  cacuana: 'cacuana',
  cacuata: 'cacuata',
  cacubis: 'cacubis',
  cacucos: 'cacucos',
  cacuema: 'cacuema',
  cacueme: 'cacueme',
  cacuera: 'cacuera',
  cacuias: 'cacuias',
  caculai: 'caculai',
  caculam: 'caculam',
  cacular: 'cacular',
  caculas: 'caçulas',
  caculei: 'caculei',
  caculem: 'caculem',
  cacules: 'cacules',
  caculos: 'caculos',
  caculou: 'caculou',
  cacumas: 'cacumás',
  cacumba: 'cacumba',
  cacumbi: 'cacumbi',
  cacumbo: 'cacumbô',
  cacumbu: 'cacumbu',
  cacumen: 'cacúmen',
  cacumes: 'cacumes',
  cacunda: 'cacunda',
  cacunde: 'cacundê',
  cacundo: 'cacundo',
  cacuris: 'cacuris',
  cacurro: 'caçurro',
  cacusso: 'cacusso',
  cacutus: 'cacutus',
  cadabas: 'cadabas',
  cadamos: 'cadamos',
  cadarco: 'cadarço',
  cadaste: 'cadaste',
  cadaval: 'cadaval',
  cadavas: 'cadavas',
  cadaver: 'cadáver',
  cadaxos: 'cadaxos',
  caddies: 'caddies',
  cadeado: 'cadeado',
  cadeiao: 'cadeião',
  cadeias: 'cadeias',
  cadeira: 'cadeira',
  cadeixo: 'cadeixo',
  cadelai: 'cadelai',
  cadelam: 'cadelam',
  cadelar: 'cadelar',
  cadelas: 'cadelas',
  cadelei: 'cadelei',
  cadelem: 'cadelem',
  cadeles: 'cadeles',
  cadelos: 'cadelos',
  cadelou: 'cadelou',
  cadenas: 'cadenas',
  cadente: 'cadente',
  cadeota: 'cadeota',
  caderna: 'caderna',
  caderno: 'caderno',
  cadetas: 'cadetas',
  cadetes: 'cadetes',
  cadexos: 'cadexos',
  cadilha: 'cadilha',
  cadilho: 'cadilho',
  cadimas: 'cadimas',
  cadimes: 'cadimes',
  cadimos: 'cadimos',
  cadinas: 'cadinas',
  cadineu: 'cadinéu',
  cadinha: 'cadinha',
  cadinhe: 'cadinhe',
  cadinho: 'cadinho',
  cadinos: 'cadinos',
  cadisco: 'cadisco',
  cadista: 'cadista',
  cadiueu: 'cadiuéu',
  cadivas: 'cadivas',
  cadiveu: 'cadivéu',
  cadivos: 'cadivos',
  cadixes: 'cadixes',
  cadixos: 'cadixos',
  cadmeia: 'cadmeia',
  cadmeus: 'cadmeus',
  cadmiai: 'cadmiai',
  cadmiam: 'cadmiam',
  cadmiar: 'cadmiar',
  cadmias: 'cadmias',
  cadmiei: 'cadmiei',
  cadmiem: 'cadmiem',
  cadmies: 'cadmies',
  cadmios: 'cádmios',
  cadmiou: 'cadmiou',
  cadmium: 'cádmium',
  cadogam: 'cadogam',
  cadoico: 'cadoiço',
  cadoira: 'cadoira',
  cadolos: 'cadolos',
  cadorna: 'cadorna',
  cadorno: 'cadorno',
  cadorra: 'cadorra',
  cadouco: 'cadouço',
  cadoura: 'cadoura',
  cadoxes: 'cadoxes',
  cadoxos: 'cadoxos',
  cadozes: 'cadozes',
  cadraco: 'cadraço',
  cadsura: 'cadsura',
  caducai: 'caducai',
  caducam: 'caducam',
  caducar: 'caducar',
  caducas: 'caducas',
  caduceu: 'caduceu',
  caducos: 'caducos',
  caducou: 'caducou',
  cadulos: 'cadulos',
  caduque: 'caduque',
  cadurca: 'cadurça',
  cadurco: 'cadurco',
  cadusio: 'cadúsio',
  cadusos: 'cadusos',
  caduveu: 'caduveu',
  caeiras: 'caeiras',
  caeiros: 'caeiros',
  caetana: 'caetana',
  caetano: 'caetano',
  caetetu: 'caetetu',
  cafanga: 'cafanga',
  cafango: 'cafango',
  cafarro: 'cafarro',
  cafatar: 'cafatar',
  cafeais: 'cafeais',
  cafeato: 'cafeato',
  cafecos: 'cafecos',
  cafeica: 'cafeica',
  cafeico: 'cafeico',
  cafeina: 'cafeína',
  cafeine: 'cafeíne',
  cafeino: 'cafeíno',
  cafelai: 'cafelai',
  cafelam: 'cafelam',
  cafelar: 'cafelar',
  cafelas: 'cafelas',
  cafelei: 'cafelei',
  cafelem: 'cafelem',
  cafeles: 'cafeles',
  cafelou: 'cafelou',
  cafeois: 'cafeóis',
  cafeona: 'cafeona',
  cafetai: 'cafetai',
  cafetal: 'cafetal',
  cafetam: 'cafetam',
  cafetao: 'cafetão',
  cafetar: 'cafetar',
  cafetas: 'cafetãs',
  cafetei: 'cafetei',
  cafetem: 'cafétem',
  cafetes: 'cafetes',
  cafetou: 'cafetou',
  cafezal: 'cafezal',
  cafifai: 'cafifai',
  cafifam: 'cafifam',
  cafifar: 'cafifar',
  cafifas: 'cafifas',
  cafifei: 'cafifei',
  cafifem: 'cafifem',
  cafifes: 'cafifes',
  cafifis: 'cafífis',
  cafifou: 'cafifou',
  cafilas: 'cáfilas',
  cafimas: 'cafimas',
  cafinfa: 'cafinfa',
  cafiote: 'cafiote',
  cafioto: 'cafioto',
  cafizes: 'cafizes',
  cafofas: 'cafofas',
  cafofos: 'cafofos',
  cafonas: 'cafonas',
  cafotos: 'cafotos',
  cafrais: 'cafrais',
  cafreal: 'cafreal',
  cafrice: 'cafrice',
  cafrina: 'cafrina',
  cafrino: 'cafrino',
  caftans: 'caftans',
  caftina: 'caftina',
  caftine: 'caftine',
  caftino: 'caftino',
  cafubas: 'cafubás',
  cafucas: 'cafucas',
  cafucus: 'cafuçus',
  cafulos: 'cafulos',
  cafumba: 'cafumbã',
  cafumbe: 'cafumbe',
  cafumbo: 'cafumbo',
  cafunai: 'cafunai',
  cafunam: 'cafunam',
  cafunar: 'cafunar',
  cafunas: 'cafunas',
  cafundo: 'cafundó',
  cafunei: 'cafunei',
  cafunem: 'cafunem',
  cafunes: 'cafunés',
  cafunga: 'cafunga',
  cafunge: 'cafunge',
  cafungo: 'cafungo',
  cafunje: 'cafunje',
  cafunou: 'cafunou',
  cafuoes: 'cafuões',
  cafurna: 'cafurna',
  cafutes: 'cafutes',
  cafuzai: 'cafuzai',
  cafuzam: 'cafuzam',
  cafuzar: 'cafuzar',
  cafuzas: 'cafuzas',
  cafuzei: 'cafuzei',
  cafuzem: 'cafuzem',
  cafuzes: 'cafuzes',
  cafuzos: 'cafuzos',
  cafuzou: 'cafuzou',
  cagacal: 'cagaçal',
  cagacao: 'cagação',
  cagacos: 'cagaços',
  cagadao: 'cagadão',
  cagadas: 'cagadas',
  cagadol: 'cagadol',
  cagador: 'cagador',
  cagados: 'cágados',
  cagafum: 'cagafum',
  cagaita: 'cagaita',
  cagalho: 'cagalho',
  cagamos: 'cagamos',
  caganca: 'cagança',
  cagando: 'cagando',
  cagante: 'cagante',
  cagaram: 'cagaram',
  cagarao: 'cagarão',
  cagaras: 'cagaras',
  cagarei: 'cagarei',
  cagarem: 'cagarem',
  cagares: 'cagares',
  cagareu: 'cagaréu',
  cagaria: 'cagaria',
  cagarim: 'cagarim',
  cagarmo: 'cagarmo',
  cagarra: 'cagarra',
  cagarro: 'cagarro',
  cagasse: 'cagasse',
  cagaste: 'cagaste',
  cagauas: 'cagauás',
  cagaval: 'cagaval',
  cagavam: 'cagavam',
  cagavas: 'cagavas',
  caginga: 'caginga',
  cagoans: 'cagoans',
  cagocio: 'cagócio',
  cagoico: 'cagoiço',
  cagonas: 'cagonas',
  cagopio: 'cagópio',
  cagoros: 'cagoros',
  cagotes: 'cagotes',
  caguacu: 'caguaçu',
  caguama: 'caguama',
  caguani: 'caguani',
  caguara: 'caguará',
  caguata: 'caguatã',
  cagueda: 'cágueda',
  cagueis: 'cagueis',
  caguemo: 'caguemo',
  cagueta: 'cagueta',
  caguete: 'caguete',
  cagueto: 'cagueto',
  cagufas: 'cagufas',
  caguira: 'caguira',
  cagunco: 'cagunço',
  cagunfa: 'cagunfa',
  cahnita: 'cahnita',
  caiabis: 'caiabis',
  caiacao: 'caiação',
  caiacos: 'caiacós',
  caiacus: 'caiacus',
  caiadao: 'caiadão',
  caiadas: 'caiadas',
  caiador: 'caiador',
  caiados: 'caiados',
  caialas: 'caialas',
  caiamos: 'caiamos',
  caianas: 'caianas',
  caianca: 'caiança',
  caiando: 'caiando',
  caianes: 'caianés',
  caiante: 'caiante',
  caiapas: 'caiapas',
  caiapia: 'caiapiá',
  caiapos: 'caiapós',
  caiaque: 'caiaque',
  caiaram: 'caiaram',
  caiarao: 'caiarão',
  caiaras: 'caiaras',
  caiarei: 'caiarei',
  caiarem: 'caiarem',
  caiares: 'caiares',
  caiaria: 'caiaria',
  caiarmo: 'caiarmo',
  caiasse: 'caiasse',
  caiaste: 'caiaste',
  caiates: 'caiatés',
  caiavam: 'caiavam',
  caiavas: 'caiavas',
  caiaxio: 'caiaxió',
  caibais: 'caibais',
  caibamo: 'caibamo',
  caibrai: 'caibrai',
  caibral: 'caibral',
  caibram: 'caibram',
  caibrar: 'caibrar',
  caibras: 'cãibras',
  caibrei: 'caibrei',
  caibrem: 'caibrem',
  caibres: 'caibres',
  caibros: 'caibros',
  caibrou: 'caibrou',
  caicaca: 'caiçaca',
  caicaco: 'caicaco',
  caicais: 'caicaís',
  caicara: 'caiçara',
  caicaus: 'caicaus',
  caicava: 'caiçava',
  caicuma: 'caiçuma',
  caicura: 'caicurá',
  caidela: 'caidela',
  caidico: 'caidiço',
  caidoes: 'caidões',
  caidona: 'caidona',
  caidora: 'caidora',
  caieira: 'caieira',
  caieiro: 'caieiro',
  caiemos: 'caiemos',
  caienas: 'caienas',
  caiense: 'caiense',
  caieque: 'caieque',
  caietes: 'caietés',
  caiguas: 'caiguás',
  caimais: 'caimais',
  caimbas: 'caimbás',
  caimbes: 'caimbés',
  caimbra: 'câimbra',
  caimbro: 'câimbro',
  caimiri: 'caimiri',
  caimita: 'caimita',
  caimito: 'caimito',
  caimoes: 'caimões',
  cainame: 'cainamé',
  cainana: 'cainana',
  caincai: 'cainçai',
  caincam: 'cainçam',
  caincar: 'cainçar',
  caincas: 'caincas',
  caincei: 'caincei',
  caincem: 'caincem',
  cainces: 'cainces',
  caincou: 'cainçou',
  caingai: 'caingai',
  caingas: 'caingás',
  caingua: 'cainguá',
  cainham: 'cainham',
  cainhar: 'cainhar',
  cainhem: 'cainhem',
  cainhos: 'cainhos',
  cainhou: 'cainhou',
  cainico: 'caínico',
  cainita: 'cainita',
  cainiti: 'cainiti',
  cainito: 'cainito',
  caiongo: 'caiongo',
  caiorda: 'caiorda',
  caiorro: 'caiorro',
  caiotas: 'caiotas',
  caiovas: 'caiovás',
  caipira: 'caipira',
  caipoes: 'caipões',
  caipora: 'caipora',
  caipuna: 'caipuna',
  caiques: 'caíques',
  caiquio: 'caiquió',
  cairana: 'cairana',
  cairara: 'cairara',
  cairdes: 'cairdes',
  caireis: 'caireis',
  cairela: 'cairela',
  cairele: 'cairele',
  cairelo: 'cairelo',
  cairemo: 'cairemo',
  cairiam: 'cairiam',
  cairias: 'cairias',
  cairina: 'cairina',
  cairiri: 'cairiri',
  cairmos: 'cairmos',
  cairota: 'cairota',
  cairuas: 'cairuás',
  cairucu: 'cairuçu',
  caissem: 'caíssem',
  caisses: 'caísses',
  caistes: 'caístes',
  caisuma: 'caisuma',
  caitatu: 'caitatu',
  caitias: 'caitias',
  caitite: 'caitité',
  caititi: 'caititi',
  caititu: 'caititu',
  caitoca: 'caitoca',
  caiugas: 'caiugás',
  caiuias: 'caiuias',
  caiumba: 'caiumba',
  caixana: 'caixana',
  caixela: 'caixela',
  caixeta: 'caixeta',
  caixoes: 'caixões',
  caixola: 'caixola',
  caixona: 'caixona',
  caixota: 'caixota',
  caixote: 'caixote',
  caixoto: 'caixoto',
  cajabis: 'cajabis',
  cajacas: 'cajaças',
  cajadil: 'cajadil',
  cajadis: 'cajadis',
  cajados: 'cajados',
  cajaiba: 'cajaíba',
  cajajas: 'cajajas',
  cajaleo: 'cajaleó',
  cajaleu: 'cajaléu',
  cajanas: 'cajanas',
  cajanea: 'cajânea',
  cajanja: 'cajanjá',
  cajanos: 'cajanos',
  cajante: 'cajante',
  cajatai: 'cajataí',
  cajatas: 'cajatas',
  cajatis: 'cajatis',
  cajibas: 'cajibás',
  cajilas: 'cajilas',
  cajinga: 'cajinga',
  cajitas: 'cajitas',
  cajitos: 'cajitós',
  cajuacu: 'cajuaçu',
  cajuada: 'cajuada',
  cajuais: 'cajuais',
  cajubim: 'cajubim',
  cajubis: 'cajubis',
  cajuina: 'cajuína',
  cajuins: 'cajuins',
  cajulos: 'cajulós',
  cajurim: 'cajurim',
  cajuris: 'cajuris',
  cajurus: 'cajurus',
  cajutis: 'cajutis',
  cajuuna: 'cajuuna',
  calabar: 'calabar',
  calabas: 'calabas',
  calabre: 'calabre',
  calacao: 'calação',
  calacas: 'calaças',
  calacea: 'calaceá',
  calacre: 'calacre',
  caladao: 'caladão',
  caladas: 'caladas',
  caladio: 'caládio',
  calador: 'calador',
  calados: 'calados',
  calagem: 'calagem',
  calaica: 'calaica',
  calaico: 'calaico',
  calains: 'calains',
  calaita: 'calaíta',
  calaite: 'calaíte',
  calajar: 'calajar',
  calalas: 'calalas',
  calalus: 'calalus',
  calaluz: 'calaluz',
  calamao: 'calamão',
  calamar: 'calamar',
  calamba: 'calambá',
  calamea: 'calâmea',
  calamei: 'calamei',
  calamel: 'calamel',
  calameo: 'calâmeo',
  calamos: 'calamos',
  calanas: 'calanas',
  calanca: 'calanca',
  calando: 'calando',
  calanga: 'calanga',
  calange: 'calange',
  calango: 'calango',
  calanja: 'calanja',
  calanos: 'calanos',
  calante: 'calante',
  calanza: 'calanza',
  calaque: 'calaque',
  calaram: 'calaram',
  calarao: 'calarão',
  calaras: 'calaras',
  calarei: 'calarei',
  calarem: 'calarem',
  calares: 'calares',
  calaria: 'calaria',
  calarmo: 'calarmo',
  calasia: 'calasia',
  calasse: 'calasse',
  calaste: 'calaste',
  calatas: 'calatas',
  calatra: 'calatra',
  calatre: 'calatre',
  calavam: 'calavam',
  calavas: 'calavas',
  calavea: 'calávea',
  calazal: 'calazal',
  calazar: 'calazar',
  calazas: 'calazas',
  calazio: 'calázio',
  calcada: 'calçada',
  calcado: 'calçado',
  calcais: 'calcais',
  calcamo: 'calcamo',
  calcara: 'calcara',
  calcare: 'calcaré',
  calcava: 'calcava',
  calceis: 'calceis',
  calcemo: 'calcemo',
  calcesa: 'calcesa',
  calceta: 'calceta',
  calcete: 'calcete',
  calceto: 'calceto',
  calcica: 'cálcica',
  calcico: 'cálcico',
  calcido: 'cálcido',
  calcimo: 'cálcimo',
  calcina: 'calcina',
  calcine: 'calcine',
  calcino: 'calcino',
  calcios: 'cálcios',
  calcita: 'calcita',
  calcite: 'calcite',
  calcoes: 'calções',
  calcola: 'calçola',
  calcore: 'calcoré',
  calcote: 'calçote',
  calcuda: 'calçuda',
  calcudo: 'calçudo',
  calcula: 'calcula',
  calcule: 'calcule',
  calculo: 'cálculo',
  caldaca: 'caldaça',
  caldada: 'caldada',
  caldado: 'caldado',
  caldais: 'caldais',
  caldamo: 'caldamo',
  caldara: 'caldara',
  caldava: 'caldava',
  caldeai: 'caldeai',
  caldear: 'caldear',
  caldeei: 'caldeei',
  caldeia: 'caldeia',
  caldeie: 'caldeie',
  caldeio: 'caldeio',
  caldeis: 'caldeis',
  caldemo: 'caldemo',
  caldeou: 'caldeou',
  caldeta: 'caldeta',
  caldeus: 'caldeus',
  caldoca: 'caldoça',
  caldosa: 'caldosa',
  caldoso: 'caldoso',
  caldudo: 'caldudo',
  caleada: 'caleada',
  caleado: 'caleado',
  caleais: 'caleais',
  caleana: 'caleana',
  caleara: 'caleará',
  caleava: 'caleava',
  calecas: 'caleças',
  caleche: 'caleche',
  calecos: 'caleços',
  caleeis: 'caleeis',
  caleiam: 'caleiam',
  caleias: 'caleias',
  caleiem: 'caleiem',
  caleies: 'caleies',
  caleira: 'caleira',
  caleiro: 'caleiro',
  calejai: 'calejai',
  calejam: 'calejam',
  calejar: 'calejar',
  calejas: 'calejas',
  calejei: 'calejei',
  calejem: 'calejem',
  calejes: 'calejes',
  calejou: 'calejou',
  calemas: 'calemas',
  calemba: 'calemba',
  calembe: 'calembe',
  calembo: 'calembo',
  calemia: 'calemia',
  calemos: 'calemos',
  calenas: 'calenas',
  calenes: 'calenes',
  calengo: 'calengo',
  calenos: 'calenos',
  calense: 'calense',
  calento: 'calento',
  calepim: 'calepim',
  calepos: 'calepos',
  caleras: 'caleras',
  caletas: 'caletas',
  caletes: 'caletes',
  caletos: 'caletos',
  calhada: 'calhada',
  calhado: 'calhado',
  calhais: 'calhais',
  calhamo: 'calhamo',
  calhara: 'calhara',
  calhaus: 'calhaus',
  calhava: 'calhava',
  calheis: 'calheis',
  calhemo: 'calhemo',
  calheta: 'calheta',
  calheus: 'calhéus',
  calhoai: 'calhoai',
  calhoam: 'calhoam',
  calhoar: 'calhoar',
  calhoas: 'calhoas',
  calhoei: 'calhoei',
  calhoem: 'calhoem',
  calhoes: 'calhões',
  calhoou: 'calhoou',
  calhota: 'calhota',
  caliada: 'caliada',
  caliana: 'caliana',
  calibio: 'calíbio',
  calibra: 'calibra',
  calibre: 'calibre',
  calibro: 'calibro',
  calicas: 'caliças',
  calices: 'cálices',
  caliche: 'caliche',
  calicio: 'calício',
  calicos: 'caliços',
  calidas: 'cálidas',
  calidea: 'calídea',
  calideo: 'calídeo',
  calidez: 'calidez',
  calidio: 'calídio',
  calidos: 'cálidos',
  califas: 'califas',
  califom: 'califom',
  caligas: 'cáligas',
  caligem: 'caligem',
  caligio: 'calígio',
  caligos: 'caligos',
  calilus: 'calilus',
  calimba: 'calimba',
  calimbe: 'calimbé',
  calimes: 'calimes',
  calinas: 'calinas',
  calingo: 'calingo',
  calinho: 'calinho',
  calinos: 'calinos',
  caliope: 'calíope',
  calipal: 'calipal',
  calipso: 'calipso',
  calipto: 'calipto',
  calique: 'calique',
  calisia: 'calísia',
  calismo: 'calismo',
  calista: 'calista',
  calisto: 'calisto',
  calites: 'calites',
  calmada: 'calmada',
  calmado: 'calmado',
  calmais: 'calmais',
  calmamo: 'calmamo',
  calmara: 'calmara',
  calmava: 'calmava',
  calmeis: 'calmeis',
  calmemo: 'calmemo',
  calmica: 'calmiça',
  calmoes: 'calmões',
  calmosa: 'calmosa',
  calmoso: 'calmoso',
  calmuco: 'calmuco',
  calodio: 'calódio',
  caloete: 'caloête',
  calogio: 'calogio',
  caloira: 'caloira',
  caloiro: 'caloiro',
  calojis: 'calojis',
  calolos: 'calolos',
  calombe: 'calombe',
  calombo: 'calombo',
  calomel: 'calomel',
  calonas: 'calonas',
  calonde: 'calonde',
  calongo: 'calongo',
  calonio: 'calônio',
  calorao: 'calorão',
  calores: 'calores',
  caloria: 'caloria',
  calorim: 'calorim',
  calosas: 'calosas',
  caloses: 'caloses',
  calosos: 'calosos',
  calotas: 'calotas',
  calotea: 'caloteá',
  calotes: 'calotes',
  caloura: 'caloura',
  caloure: 'caloure',
  calouro: 'calouro',
  calovas: 'calovas',
  calquei: 'calquei',
  calquem: 'calquem',
  calques: 'calques',
  calraxo: 'calraxo',
  calubas: 'calubas',
  caludas: 'caludas',
  caluena: 'caluena',
  caluete: 'caluete',
  calugas: 'calugas',
  caluios: 'caluios',
  calujes: 'calujes',
  calulas: 'calulas',
  calulos: 'calulos',
  calulus: 'calulus',
  calumba: 'calumba',
  calumbe: 'calumbé',
  calumbi: 'calumbi',
  calunas: 'calunas',
  calundu: 'calundu',
  calunga: 'calunga',
  calungo: 'calungo',
  calunia: 'calúnia',
  calunie: 'calunie',
  calunio: 'calunio',
  calunos: 'calunos',
  caluqui: 'caluqui',
  caluros: 'caluros',
  calvada: 'calvada',
  calvado: 'calvado',
  calvais: 'calvais',
  calvamo: 'calvamo',
  calvara: 'calvará',
  calvava: 'calvava',
  calveis: 'calveis',
  calveja: 'calveja',
  calveje: 'calveje',
  calvejo: 'calvejo',
  calvemo: 'calvemo',
  calvice: 'calvice',
  calvina: 'calvina',
  calvino: 'calvino',
  calvura: 'calvura',
  calzone: 'calzone',
  camaaxi: 'camaáxi',
  camacas: 'camacãs',
  camacho: 'camacho',
  camacus: 'camacus',
  camadas: 'camadas',
  camafeu: 'camafeu',
  camagua: 'camaguã',
  camaias: 'camaias',
  camaiua: 'camaiuá',
  camajis: 'camajis',
  camalas: 'camalas',
  camalea: 'camaleá',
  camalha: 'camalha',
  camalho: 'camalho',
  camambu: 'camambu',
  camamum: 'camamum',
  camanas: 'camanás',
  camanga: 'camanga',
  camanho: 'camanho',
  camanxe: 'camanxe',
  camapes: 'camapés',
  camapus: 'camapus',
  camaqua: 'camaquã',
  camaral: 'camaral',
  camarao: 'camarão',
  camaras: 'câmaras',
  camarau: 'camarau',
  camarco: 'camarço',
  camargo: 'camargo',
  camaria: 'camaria',
  camarim: 'camarim',
  camarus: 'camarus',
  camasia: 'camásia',
  camauro: 'camauro',
  camaïeu: 'camaïeu',
  cambaca: 'cambacã',
  cambaco: 'cambaco',
  cambada: 'cambada',
  cambado: 'cambado',
  cambaia: 'cambaia',
  cambaie: 'cambaie',
  cambaio: 'cambaio',
  cambais: 'cambais',
  cambala: 'cambala',
  cambamo: 'cambamo',
  cambape: 'cambapé',
  cambara: 'cambará',
  cambaus: 'cambaus',
  cambava: 'cambava',
  cambeba: 'cambeba',
  cambeia: 'cambeia',
  cambeis: 'cambeis',
  cambeja: 'cambeja',
  cambemo: 'cambemo',
  camberi: 'cambéri',
  cambeta: 'cambeta',
  cambeua: 'cambéua',
  cambeva: 'cambeva',
  cambiai: 'cambiai',
  cambial: 'cambial',
  cambiam: 'cambiam',
  cambiar: 'cambiar',
  cambias: 'cambias',
  cambica: 'cambica',
  cambico: 'cambiço',
  cambiei: 'cambiei',
  cambiem: 'cambiem',
  cambies: 'cambies',
  cambios: 'câmbios',
  cambiou: 'cambiou',
  cambiro: 'cambiro',
  cambita: 'cambita',
  cambite: 'cambite',
  cambito: 'cambito',
  cambiua: 'cambiuá',
  cambixo: 'cambixo',
  camboai: 'camboai',
  camboam: 'camboam',
  camboar: 'camboar',
  camboas: 'camboas',
  camboca: 'cambocá',
  camboei: 'camboei',
  camboem: 'camboem',
  camboes: 'cambões',
  camboim: 'camboim',
  cambois: 'cambóis',
  cambola: 'cambola',
  cambole: 'cambole',
  cambolo: 'cambolo',
  cambona: 'cambona',
  cambone: 'cambone',
  cambono: 'cambono',
  camboou: 'camboou',
  cambota: 'cambota',
  cambote: 'cambote',
  cambrai: 'cambrai',
  cambram: 'cambram',
  cambrao: 'cambrão',
  cambrar: 'cambrar',
  cambras: 'cambras',
  cambrei: 'cambrei',
  cambrem: 'cambrem',
  cambres: 'cambres',
  cambrou: 'cambrou',
  cambuba: 'cambuba',
  cambuca: 'cambucá',
  cambuci: 'cambuci',
  cambucu: 'cambucu',
  cambuda: 'cambuda',
  cambudo: 'cambudo',
  cambula: 'cambula',
  cambule: 'cambule',
  cambulo: 'cambulo',
  camburi: 'camburi',
  cambuta: 'cambuta',
  cambuto: 'cambuto',
  cambuva: 'cambuva',
  cameada: 'cameada',
  cameado: 'cameado',
  cameais: 'cameais',
  cameara: 'cameará',
  cameava: 'cameava',
  camecra: 'camecrã',
  cameeis: 'cameeis',
  cameiam: 'cameiam',
  cameias: 'cameias',
  cameiem: 'cameiem',
  cameies: 'cameies',
  camelao: 'camelão',
  camelas: 'camelas',
  camelia: 'camélia',
  camelos: 'camelos',
  camenal: 'camenal',
  camenas: 'camenas',
  camenes: 'camenes',
  cameral: 'cameral',
  cameras: 'câmeras',
  camerte: 'camerte',
  camessa: 'camessa',
  cametau: 'cametaú',
  camexes: 'camexes',
  camichi: 'camichi',
  camilas: 'camilas',
  camilha: 'camilha',
  caminau: 'caminaú',
  caminha: 'caminha',
  caminhe: 'caminhe',
  caminho: 'caminho',
  camioes: 'camiões',
  camisao: 'camisão',
  camisas: 'camisas',
  camitas: 'camitas',
  camoata: 'camoatá',
  camoati: 'camoati',
  camocas: 'camocas',
  camocho: 'camocho',
  camoeca: 'camoeca',
  camoesa: 'camoesa',
  camoiro: 'camoiro',
  camones: 'camones',
  camonha: 'camonha',
  camopim: 'camopim',
  camorco: 'camorço',
  camorim: 'camorim',
  camorra: 'camorra',
  camotes: 'camotes',
  camotim: 'camotim',
  camouco: 'camouco',
  camouro: 'camouro',
  campada: 'campada',
  campado: 'campado',
  campais: 'campais',
  campamo: 'campamo',
  campana: 'campana',
  campane: 'campane',
  campano: 'campano',
  campara: 'campara',
  campava: 'campava',
  campeai: 'campeai',
  campeao: 'campeão',
  campear: 'campear',
  campeas: 'campeãs',
  campeba: 'campeba',
  campeei: 'campeei',
  campeia: 'campeia',
  campeie: 'campeie',
  campeio: 'campeio',
  campeis: 'campeis',
  campelo: 'campelo',
  campemo: 'campemo',
  campeou: 'campeou',
  campeva: 'campeva',
  campiam: 'campiam',
  campiao: 'campião',
  campias: 'campias',
  campida: 'campida',
  campido: 'campido',
  campimo: 'campimo',
  campina: 'campina',
  campine: 'campine',
  camping: 'camping',
  campino: 'campino',
  campira: 'campira',
  campoes: 'campões',
  camposa: 'camposa',
  camposo: 'camposo',
  campsim: 'campsim',
  campuda: 'campuda',
  campune: 'campune',
  camuaca: 'camuacá',
  camuata: 'camuatá',
  camucas: 'camucás',
  camuces: 'camucés',
  camucim: 'camucim',
  camucis: 'camucis',
  camucus: 'camucus',
  camueca: 'camueca',
  camuelo: 'camuelo',
  camufla: 'camufla',
  camufle: 'camufle',
  camuflo: 'camuflo',
  camugas: 'camugas',
  camundi: 'camundi',
  camunha: 'camunha',
  camunia: 'camúnia',
  camunio: 'camúnio',
  camunos: 'camunos',
  camunze: 'camunzé',
  camupim: 'camupim',
  camurca: 'camurça',
  camurce: 'camurce',
  camurco: 'camurço',
  camurim: 'camurim',
  camuris: 'camuris',
  camurro: 'camurro',
  camurus: 'camurus',
  camutos: 'camutos',
  canabis: 'canabis',
  canacas: 'canacas',
  canacea: 'canácea',
  canaceo: 'canáceo',
  canacus: 'canaçus',
  canadas: 'canadas',
  canadio: 'canádio',
  canadol: 'canadol',
  canados: 'canados',
  canagra: 'canagra',
  canaica: 'canaica',
  canaime: 'canaimé',
  canalar: 'canalar',
  canalha: 'canalha',
  canamao: 'canamão',
  cananas: 'cananas',
  cananes: 'cananés',
  cananeu: 'cananeu',
  cananga: 'cananga',
  canango: 'canango',
  canapes: 'canapés',
  canapus: 'canapus',
  canaras: 'canarás',
  canares: 'canarês',
  canaria: 'canaria',
  canarim: 'canarim',
  canario: 'canário',
  canaris: 'canaris',
  canasta: 'canasta',
  canatos: 'canatos',
  canaval: 'canaval',
  canavas: 'canavãs',
  canavea: 'canaveá',
  canaves: 'canavês',
  canavez: 'canavez',
  canazes: 'canazes',
  cancana: 'cancanã',
  cancane: 'cancane',
  cancano: 'cancano',
  cancara: 'cancarã',
  cancaro: 'cancaro',
  cancata: 'cancatá',
  cancela: 'cancela',
  cancele: 'cancele',
  cancelo: 'cancelo',
  cancera: 'cancera',
  cancere: 'cancere',
  cancero: 'cancero',
  canchal: 'canchal',
  canchas: 'canchas',
  canchea: 'cancheá',
  canchim: 'canchim',
  canchis: 'canchis',
  canchos: 'canchos',
  cancleu: 'cancleu',
  cancoes: 'canções',
  cancona: 'cançona',
  cancras: 'cancras',
  cancros: 'cancros',
  cancura: 'cancurã',
  candabo: 'candabo',
  candabu: 'candabu',
  candace: 'cândace',
  candada: 'candada',
  candado: 'candado',
  candeai: 'candeai',
  candeal: 'candeal',
  candear: 'candear',
  candeas: 'candeás',
  candeei: 'candeei',
  candeia: 'candeia',
  candeie: 'candeie',
  candeio: 'candeio',
  candeis: 'candéis',
  candela: 'candela',
  candena: 'candena',
  candens: 'candéns',
  candeou: 'candeou',
  candeus: 'candeus',
  candiai: 'candiai',
  candial: 'candial',
  candiam: 'candiam',
  candiar: 'candiar',
  candias: 'candias',
  candida: 'cândida',
  candido: 'cândido',
  candiei: 'candiei',
  candiem: 'candiem',
  candies: 'candies',
  candila: 'candila',
  candile: 'candile',
  candilo: 'candilo',
  candins: 'candins',
  candiou: 'candiou',
  candiru: 'candiru',
  candola: 'candola',
  candole: 'candole',
  candolo: 'candolo',
  candona: 'candona',
  candora: 'candora',
  candros: 'candros',
  canduas: 'canduás',
  canduns: 'canduns',
  candura: 'candura',
  caneada: 'caneada',
  caneado: 'caneado',
  caneais: 'caneais',
  caneara: 'caneará',
  caneava: 'caneava',
  canebas: 'canebás',
  canecao: 'canecão',
  canecas: 'canecas',
  canecos: 'canecos',
  caneeis: 'caneeis',
  caneiam: 'caneiam',
  caneias: 'caneias',
  caneiem: 'caneiem',
  caneies: 'caneies',
  caneira: 'caneira',
  caneiro: 'caneiro',
  caneixo: 'caneixo',
  canejas: 'canejas',
  canejos: 'canejos',
  canelai: 'canelai',
  canelam: 'canelam',
  canelao: 'canelão',
  canelar: 'canelar',
  canelas: 'canelas',
  canelei: 'canelei',
  canelem: 'canelem',
  caneles: 'caneles',
  canelha: 'canelha',
  canelho: 'canelho',
  canelim: 'canelim',
  canelou: 'canelou',
  canemas: 'canemas',
  canembo: 'canembo',
  canenas: 'canenas',
  canense: 'canense',
  canequi: 'canequi',
  canetai: 'canetai',
  canetam: 'canetam',
  canetar: 'canetar',
  canetas: 'canetas',
  canetei: 'canetei',
  canetem: 'canetem',
  canetes: 'canetes',
  canetou: 'canetou',
  canevao: 'canevão',
  canevas: 'canevás',
  canezes: 'canezes',
  canfana: 'canfana',
  canfano: 'canfano',
  canfato: 'canfato',
  canfeno: 'canfeno',
  canfina: 'canfina',
  canfois: 'canfóis',
  canfora: 'cânfora',
  canfore: 'canfore',
  canforo: 'canforo',
  cangaco: 'cangaço',
  cangada: 'cangada',
  cangado: 'cangado',
  cangais: 'cangais',
  cangala: 'cangala',
  cangali: 'cangali',
  cangane: 'cangane',
  cangape: 'cangapé',
  cangara: 'cangará',
  cangata: 'cangatá',
  cangati: 'cangati',
  cangaua: 'cangauá',
  cangava: 'cangava',
  cangira: 'cangira',
  cangoas: 'cangoás',
  cangoes: 'cangões',
  cangola: 'cangola',
  cangole: 'cangolé',
  cangome: 'cangome',
  cangote: 'cangote',
  canguai: 'canguaí',
  canguas: 'canguás',
  cangucu: 'canguçu',
  canguei: 'canguei',
  canguem: 'canguem',
  cangues: 'cangues',
  cangula: 'cangula',
  cangulo: 'cangulo',
  canguru: 'canguru',
  canhaco: 'canhaco',
  canhada: 'canhada',
  canhado: 'canhado',
  canhais: 'canhais',
  canhamo: 'cânhamo',
  canhara: 'canhara',
  canhari: 'canhári',
  canharo: 'canharó',
  canhava: 'canhava',
  canheis: 'canheis',
  canhemo: 'canhemo',
  canheta: 'canheta',
  canhine: 'canhine',
  canhins: 'canhins',
  canhoes: 'canhões',
  canhois: 'canhóis',
  canhola: 'canhola',
  canhona: 'canhona',
  canhone: 'canhone',
  canhono: 'canhono',
  canhota: 'canhota',
  canhoto: 'canhoto',
  caniana: 'caniana',
  caniane: 'caniane',
  canibal: 'canibal',
  canical: 'caniçal',
  canicao: 'canição',
  canicas: 'caniças',
  caniche: 'caniche',
  canicho: 'canicho',
  canicia: 'canícia',
  canicie: 'canície',
  canicos: 'caniços',
  canidea: 'canídea',
  canideo: 'canídeo',
  canidos: 'canidos',
  canifes: 'canifes',
  canijos: 'canijos',
  canilha: 'canilha',
  canimos: 'canimos',
  caninas: 'caninas',
  caninde: 'canindé',
  caninga: 'caninga',
  caninha: 'caninha',
  caninos: 'caninos',
  canions: 'cânions',
  canipas: 'canipas',
  canista: 'canista',
  canitar: 'canitar',
  canitos: 'canitos',
  canivao: 'canivão',
  canjala: 'canjala',
  canjere: 'canjerê',
  canjica: 'canjica',
  canjira: 'canjira',
  canjuis: 'canjuís',
  canoata: 'canoata',
  canocos: 'canocos',
  canoide: 'canoide',
  canoila: 'canoila',
  canoilo: 'canoilo',
  canoira: 'canoira',
  canolas: 'canolas',
  canones: 'cânones',
  canooes: 'canoões',
  canoona: 'canoona',
  canopeu: 'canopeu',
  canopis: 'canopis',
  canopla: 'canopla',
  canopos: 'canopos',
  canoras: 'canoras',
  canorco: 'canorço',
  canoria: 'canória',
  canorio: 'canório',
  canoros: 'canoros',
  canotao: 'canotão',
  canoula: 'canoula',
  canoulo: 'canoulo',
  canoura: 'canoura',
  canouro: 'canouro',
  cansaco: 'cansaço',
  cansada: 'cansada',
  cansado: 'cansado',
  cansais: 'cansais',
  cansamo: 'cansamo',
  cansara: 'cansara',
  cansava: 'cansava',
  canseis: 'canseis',
  cansemo: 'cansemo',
  cansins: 'cansins',
  cansoes: 'cansões',
  cansona: 'cansona',
  cantada: 'cantada',
  cantado: 'cantado',
  cantais: 'cantais',
  cantamo: 'cantamo',
  cantara: 'cantara',
  cantari: 'cantari',
  cantaro: 'cântaro',
  cantata: 'cantata',
  cantate: 'cantate',
  cantava: 'cantava',
  canteai: 'canteai',
  cantear: 'cantear',
  canteei: 'canteei',
  canteia: 'canteia',
  canteie: 'canteie',
  canteio: 'canteio',
  canteis: 'canteis',
  cantela: 'cantela',
  cantemo: 'cantemo',
  canteou: 'canteou',
  cantica: 'cântica',
  cantico: 'cântico',
  cantiga: 'cantiga',
  cantigo: 'cântigo',
  cantina: 'cantina',
  cantite: 'cantite',
  cantoes: 'cantões',
  cantora: 'cantora',
  cantuas: 'cantuas',
  cantufa: 'cantufa',
  canturo: 'canturo',
  canudai: 'canudai',
  canudam: 'canudam',
  canudar: 'canudar',
  canudas: 'canudas',
  canudei: 'canudei',
  canudem: 'canudem',
  canudes: 'canudes',
  canudos: 'canudos',
  canudou: 'canudou',
  canulas: 'cânulas',
  canuque: 'canuque',
  canuris: 'canúris',
  canusio: 'canúsio',
  canutio: 'canutio',
  canutos: 'canutos',
  canuxis: 'canuxis',
  canxins: 'canxins',
  canzada: 'canzada',
  canzado: 'canzado',
  canzais: 'canzais',
  canzala: 'canzala',
  canzamo: 'canzamo',
  canzana: 'canzana',
  canzara: 'canzará',
  canzava: 'canzava',
  canzeis: 'canzeis',
  canzemo: 'canzemo',
  canzoal: 'canzoal',
  canzois: 'canzóis',
  canzuas: 'canzuás',
  canzuim: 'canzuim',
  caoboro: 'caoboro',
  caolhas: 'caolhas',
  caolhos: 'caolhos',
  caolins: 'caolins',
  caolzao: 'caolzão',
  caombes: 'caombes',
  caombos: 'caombos',
  caonhas: 'caonhas',
  caonios: 'caônios',
  caopias: 'caopiás',
  caotica: 'caótica',
  caotico: 'caótico',
  caotiza: 'caotiza',
  caotize: 'caotize',
  caotizo: 'caotizo',
  caozito: 'cãozito',
  capacao: 'capação',
  capacas: 'capaças',
  capacha: 'capacha',
  capacho: 'capacho',
  capadao: 'capadão',
  capadas: 'capadas',
  capador: 'capador',
  capados: 'capados',
  capages: 'capages',
  capaias: 'capaias',
  capamba: 'capamba',
  capambo: 'capambo',
  capamos: 'capamos',
  capanas: 'capanás',
  capanda: 'capanda',
  capande: 'capande',
  capando: 'capando',
  capanga: 'capanga',
  capango: 'capango',
  capanho: 'capanho',
  capante: 'capante',
  caparam: 'caparam',
  caparao: 'caparão',
  caparas: 'caparas',
  caparei: 'caparei',
  caparem: 'caparem',
  capares: 'capares',
  caparia: 'caparia',
  caparis: 'cáparis',
  caparmo: 'caparmo',
  caparro: 'caparro',
  caparus: 'caparus',
  capassa: 'capassa',
  capasse: 'capasse',
  capaste: 'capaste',
  capatao: 'capatão',
  capatas: 'capatas',
  capataz: 'capataz',
  capauas: 'capauas',
  capaude: 'capaude',
  capauva: 'capaúva',
  capavam: 'capavam',
  capavas: 'capavas',
  capaxos: 'capaxós',
  capazes: 'capazes',
  capeada: 'capeada',
  capeado: 'capeado',
  capeais: 'capeais',
  capeara: 'capeará',
  capeava: 'capeava',
  capebas: 'capebas',
  capeeis: 'capeeis',
  capegos: 'capegos',
  capeiam: 'capeiam',
  capeias: 'capeias',
  capeiem: 'capeiem',
  capeies: 'capeies',
  capeira: 'capeira',
  capeiro: 'capeiro',
  capelao: 'capelão',
  capelas: 'capelas',
  capeles: 'capeles',
  capella: 'capella',
  capelos: 'capelos',
  capemba: 'capemba',
  capemos: 'capemos',
  capenga: 'capenga',
  capengo: 'capengo',
  caperas: 'caperas',
  caperom: 'caperom',
  capetao: 'capetão',
  capetas: 'capetas',
  capetes: 'capetes',
  capeuas: 'capeuas',
  capiaga: 'capiagá',
  capiana: 'capiana',
  capiaus: 'capiaus',
  capicha: 'capicha',
  capicua: 'capicua',
  capides: 'cápides',
  capijis: 'capijis',
  capilar: 'capilar',
  capiles: 'capilés',
  capilha: 'capilha',
  capilho: 'capilho',
  capinai: 'capinai',
  capinal: 'capinal',
  capinam: 'capinam',
  capinar: 'capinar',
  capinas: 'capinas',
  capindo: 'capindó',
  capinei: 'capinei',
  capinem: 'capinem',
  capines: 'capines',
  capinha: 'capinha',
  capinou: 'capinou',
  capioas: 'capioas',
  capisca: 'capisca',
  capisco: 'capisco',
  capista: 'capista',
  capitai: 'capitai',
  capital: 'capital',
  capitam: 'capitam',
  capitao: 'capitão',
  capitar: 'capitar',
  capitas: 'capitãs',
  capitau: 'capitaú',
  capitei: 'capitei',
  capitel: 'capitel',
  capitem: 'capitem',
  capites: 'capites',
  capiteu: 'capitéu',
  capitia: 'capitia',
  capitis: 'capitis',
  capitiu: 'capitiú',
  capitoa: 'capitoa',
  capitol: 'capitol',
  capitou: 'capitou',
  capiuna: 'capiúna',
  capixim: 'capixim',
  capnias: 'capnias',
  capocas: 'capocas',
  capolim: 'capolim',
  capolos: 'capolos',
  caponas: 'caponas',
  caponga: 'caponga',
  capopas: 'capopas',
  capoque: 'capoque',
  caporal: 'caporal',
  caporos: 'caporos',
  caporro: 'caporro',
  capotai: 'capotai',
  capotam: 'capotam',
  capotao: 'capotão',
  capotar: 'capotar',
  capotas: 'capotas',
  capotea: 'capoteá',
  capotei: 'capotei',
  capotem: 'capotem',
  capotes: 'capotes',
  capotim: 'capotim',
  capotou: 'capotou',
  capoxos: 'capoxos',
  caprato: 'caprato',
  capreas: 'cápreas',
  caprela: 'caprela',
  capreos: 'cápreos',
  caprico: 'cáprico',
  caprila: 'caprila',
  caprilo: 'caprilo',
  caprina: 'caprina',
  caprino: 'caprino',
  caprins: 'caprins',
  caprona: 'caprona',
  capruns: 'capruns',
  capsela: 'capsela',
  capsico: 'cápsico',
  capsite: 'capsite',
  capsula: 'cápsula',
  capsule: 'capsule',
  capsulo: 'capsulo',
  captada: 'captada',
  captado: 'captado',
  captais: 'captais',
  captamo: 'captamo',
  captana: 'captana',
  captara: 'captara',
  captava: 'captava',
  capteis: 'capteis',
  captemo: 'captemo',
  captias: 'captias',
  captois: 'captóis',
  captora: 'captora',
  captura: 'captura',
  capture: 'capture',
  capturo: 'capturo',
  capuaba: 'capuaba',
  capuana: 'capuana',
  capuano: 'capuano',
  capuava: 'capuava',
  capucha: 'capucha',
  capuche: 'capuche',
  capucho: 'capucho',
  capucim: 'capucim',
  capucos: 'capucos',
  capuibo: 'capuíbo',
  capulho: 'capulho',
  capundo: 'capundó',
  capunza: 'capunza',
  capuoes: 'capuões',
  capurro: 'capurro',
  caputis: 'caputis',
  capuxus: 'capuxus',
  capuzes: 'capuzes',
  caqueai: 'caqueai',
  caquear: 'caquear',
  caqueei: 'caqueei',
  caqueia: 'caqueia',
  caqueie: 'caqueie',
  caqueio: 'caqueio',
  caqueje: 'caqueje',
  caquene: 'caquene',
  caqueou: 'caqueou',
  caquera: 'caquera',
  caquibe: 'caquibe',
  caquile: 'caquile',
  caquina: 'caquina',
  caquine: 'caquine',
  caquino: 'caquino',
  caraacu: 'caraaçu',
  carabas: 'carabas',
  carabes: 'carabés',
  carabos: 'cárabos',
  carabus: 'carabus',
  caracal: 'caracal',
  caracas: 'caracas',
  caracea: 'carácea',
  caraceo: 'caráceo',
  caracha: 'caracha',
  caracio: 'carácio',
  caracol: 'caracol',
  caracos: 'caraços',
  caracul: 'caracul',
  caracus: 'caracus',
  caraete: 'caraetê',
  carafas: 'carafás',
  carafos: 'carafos',
  carafuz: 'carafuz',
  caragas: 'caragas',
  caragos: 'caragos',
  caragua: 'caraguá',
  caraiai: 'caraiaí',
  caraias: 'caraiás',
  caraiba: 'caraíba',
  caraipa: 'caraípa',
  caraipe: 'caraipé',
  caraita: 'caraíta',
  caraiva: 'caraíva',
  carajai: 'carajaí',
  carajas: 'carajás',
  carajes: 'carajés',
  carajua: 'carajuá',
  carajus: 'carajus',
  carales: 'carales',
  caralho: 'caralho',
  caralia: 'carália',
  caramba: 'caramba',
  carambo: 'carambó',
  caramol: 'caramol',
  caramos: 'caramos',
  carampa: 'carampã',
  caranai: 'caranaí',
  caranas: 'caranás',
  caranco: 'caranço',
  caranda: 'caranda',
  caranga: 'caranga',
  carango: 'carango',
  caranha: 'caranha',
  caranho: 'caranho',
  caranos: 'caranos',
  carapas: 'carapas',
  carapau: 'carapau',
  carapea: 'carápea',
  carapes: 'carapés',
  carapia: 'carapiá',
  carapim: 'carapim',
  carapos: 'carapós',
  carapua: 'carapuá',
  cararao: 'cararaô',
  cararas: 'cararás',
  carares: 'cararés',
  cararis: 'cararis',
  cararus: 'cararus',
  caratai: 'carataí',
  caratas: 'caratas',
  carater: 'caráter',
  carates: 'carates',
  carateu: 'caratéu',
  caratiu: 'caratiú',
  caratos: 'caratos',
  caratua: 'caratuã',
  carauba: 'caraúba',
  carauna: 'caraúna',
  carauno: 'caraúno',
  caravas: 'caravas',
  caravos: 'cáravos',
  caraxas: 'caraxas',
  caraxes: 'caraxes',
  caraxue: 'caraxué',
  carazal: 'carazal',
  carazas: 'carazas',
  carbaso: 'cárbaso',
  carbeno: 'carbeno',
  carbeto: 'carbeto',
  carbino: 'carbino',
  carbite: 'carbite',
  carbois: 'carbóis',
  carbona: 'carbona',
  carbone: 'carbone',
  carbono: 'carbono',
  carbura: 'carbura',
  carbure: 'carbure',
  carburo: 'carburo',
  carcaca: 'carcaça',
  carcaio: 'carcaio',
  carcaju: 'carcaju',
  carcara: 'carcará',
  carcava: 'cárcava',
  carcave: 'carcave',
  carcavo: 'carcavo',
  carceis: 'carcéis',
  carcela: 'carcela',
  carcera: 'carcerá',
  carcere: 'cárcere',
  carcero: 'carcero',
  carchas: 'carchas',
  carchea: 'carcheá',
  carcino: 'carcino',
  carcoda: 'carcoda',
  carcoes: 'carcões',
  carcola: 'cárcola',
  carcole: 'carcolé',
  carcoma: 'carcoma',
  carcome: 'carcome',
  carcomi: 'carcomi',
  carcomo: 'carcomo',
  carcore: 'carcoré',
  carcova: 'cárcova',
  carcuno: 'carcuno',
  cardaco: 'cardaço',
  cardada: 'cardada',
  cardado: 'cardado',
  cardais: 'cardais',
  cardamo: 'cardamo',
  cardana: 'cardana',
  cardano: 'cardano',
  cardara: 'cardara',
  cardava: 'cardava',
  cardeai: 'cardeai',
  cardeal: 'cardeal',
  cardear: 'cardear',
  cardeas: 'cárdeas',
  cardeei: 'cardeei',
  cardeia: 'cardeia',
  cardeie: 'cardeie',
  cardeio: 'cardeio',
  cardeis: 'cardeis',
  cardemo: 'cardemo',
  cardena: 'cardena',
  cardeno: 'cárdeno',
  cardeos: 'cárdeos',
  cardeou: 'cardeou',
  cardeta: 'cardeta',
  cardete: 'cardete',
  cardial: 'cardial',
  cardiam: 'cardiam',
  cardias: 'cárdias',
  cardica: 'cárdica',
  cardice: 'cárdice',
  cardico: 'cárdico',
  cardida: 'cardida',
  cardido: 'cardido',
  cardife: 'cardife',
  cardiga: 'cardigã',
  cardimo: 'cardimo',
  cardina: 'cardina',
  cardino: 'cardino',
  cardins: 'cardins',
  cardios: 'cardios',
  cardira: 'cardirá',
  cardita: 'cardita',
  cardite: 'cardite',
  cardoas: 'cardoas',
  cardoca: 'cardoça',
  cardoes: 'cardões',
  cardois: 'cardóis',
  cardosa: 'cardosa',
  carduca: 'carduça',
  carduce: 'carduce',
  carduco: 'carduço',
  carduel: 'carduel',
  cardume: 'cardume',
  careada: 'careada',
  careado: 'careado',
  careais: 'careais',
  careara: 'careara',
  careava: 'careava',
  carecam: 'careçam',
  carecas: 'carecas',
  carecei: 'carecei',
  carecem: 'carecem',
  carecer: 'carecer',
  careces: 'careces',
  careceu: 'careceu',
  carecia: 'carecia',
  careeis: 'careeis',
  careiam: 'careiam',
  careias: 'careias',
  careiem: 'careiem',
  careies: 'careies',
  careira: 'careira',
  careiro: 'careiro',
  carelia: 'carélia',
  carelio: 'carélio',
  carenai: 'carenai',
  carenal: 'carenal',
  carenam: 'carenam',
  carenar: 'carenar',
  carenas: 'carenas',
  carenei: 'carenei',
  carenem: 'carenem',
  carenes: 'carenes',
  carenou: 'carenou',
  carente: 'carente',
  carepai: 'carepai',
  carepam: 'carepam',
  carepar: 'carepar',
  carepas: 'carepas',
  carepei: 'carepei',
  carepem: 'carepem',
  carepes: 'carepes',
  carepos: 'carepos',
  carepou: 'carepou',
  caretas: 'caretas',
  caretea: 'careteá',
  caretem: 'caretém',
  caretos: 'caretos',
  carezas: 'carezas',
  cargada: 'cargada',
  cargado: 'cargado',
  cargais: 'cargais',
  cargara: 'cargará',
  cargava: 'cargava',
  cargosa: 'cargosa',
  cargoso: 'cargoso',
  carguei: 'carguei',
  carguem: 'carguem',
  cargues: 'cargues',
  cariacu: 'cariacu',
  cariada: 'cariada',
  cariado: 'cariado',
  cariais: 'cariais',
  cariama: 'cariama',
  cariamo: 'cariamo',
  cariana: 'cariana',
  cariano: 'cariano',
  cariara: 'cariará',
  cariate: 'cariate',
  cariava: 'cariava',
  caribes: 'caribes',
  caribus: 'caribus',
  caricai: 'caricai',
  caricam: 'caricam',
  caricar: 'caricar',
  caricas: 'caricas',
  caricea: 'carícea',
  caricia: 'carícia',
  caricie: 'caricie',
  caricio: 'caricio',
  caricos: 'cariços',
  caricou: 'caricou',
  carieis: 'carieis',
  cariemo: 'cariemo',
  carigos: 'carigos',
  carijai: 'carijai',
  carijam: 'carijam',
  carijar: 'carijar',
  carijas: 'carijas',
  carijei: 'carijei',
  carijem: 'carijem',
  carijes: 'carijes',
  carijos: 'carijos',
  carijou: 'carijou',
  carilha: 'carilha',
  carilho: 'carilho',
  carilos: 'carilos',
  carimas: 'carimãs',
  carimba: 'carimba',
  carimbe: 'carimbe',
  carimbo: 'carimbo',
  carimes: 'carimés',
  carimis: 'carimis',
  carinal: 'carinal',
  carinao: 'carinão',
  carinas: 'carinas',
  caringo: 'caringo',
  carinha: 'carinha',
  carinho: 'carinho',
  carinii: 'carinii',
  carinos: 'carinos',
  carioba: 'carioba',
  carioca: 'carioca',
  cariosa: 'cariosa',
  carioso: 'carioso',
  cariota: 'cariota',
  carique: 'carique',
  cariris: 'cariris',
  carisma: 'carisma',
  caritas: 'cáritas',
  caritel: 'caritel',
  caritos: 'caritos',
  carizes: 'carizes',
  carlina: 'carlina',
  carlino: 'carlino',
  carlins: 'carlins',
  carlito: 'carlito',
  carlota: 'carlota',
  carmana: 'carmana',
  carmano: 'carmano',
  carmatu: 'carmatu',
  carmeai: 'carmeai',
  carmear: 'carmear',
  carmeei: 'carmeei',
  carmeia: 'carmeia',
  carmeie: 'carmeie',
  carmeio: 'carmeio',
  carmelo: 'carmelo',
  carmeou: 'carmeou',
  carmesi: 'carmesi',
  carmiar: 'carmiar',
  carmica: 'cármica',
  carmico: 'cármico',
  carmina: 'carmina',
  carmine: 'cármine',
  carmino: 'carmino',
  carmins: 'carmins',
  carmona: 'carmona',
  carmosa: 'carmosa',
  carmoso: 'carmoso',
  carnaca: 'carnaça',
  carnaco: 'carnaco',
  carnada: 'carnada',
  carnado: 'carnado',
  carnais: 'carnais',
  carname: 'carname',
  carnamo: 'carnamo',
  carnara: 'carnara',
  carnava: 'carnava',
  carneai: 'carneai',
  carnear: 'carnear',
  carneas: 'cárneas',
  carneei: 'carneei',
  carneia: 'carneia',
  carneie: 'carneie',
  carneio: 'carneio',
  carneis: 'carneis',
  carnemo: 'carnemo',
  carneol: 'carneol',
  carneos: 'cárneos',
  carneou: 'carneou',
  carneus: 'carneus',
  carnica: 'carniça',
  carnijo: 'carnijó',
  carnina: 'carnina',
  carnita: 'carnita',
  carnosa: 'carnosa',
  carnoso: 'carnoso',
  carnuca: 'carnuça',
  carnuda: 'carnuda',
  carnudo: 'carnudo',
  carnute: 'carnute',
  carnuto: 'carnuto',
  caroada: 'caroada',
  caroado: 'caroado',
  caroais: 'caroais',
  caroara: 'caroara',
  caroata: 'caroatá',
  carocai: 'carocai',
  carocam: 'carocam',
  carocar: 'carocar',
  carocas: 'carocas',
  carocha: 'carocha',
  carocho: 'carocho',
  carocos: 'caroços',
  carocou: 'carocou',
  carolas: 'carolas',
  carolia: 'carólia',
  carolim: 'carolim',
  carolos: 'carolos',
  carombo: 'carombó',
  caronas: 'caronas',
  caronea: 'caroneá',
  carones: 'carones',
  caronha: 'caronha',
  caropai: 'caropai',
  caropam: 'caropam',
  caropar: 'caropar',
  caropas: 'caropas',
  caropei: 'caropei',
  caropem: 'caropem',
  caropes: 'caropes',
  caropou: 'caropou',
  caroque: 'caroque',
  carosol: 'carosol',
  carosse: 'carosse',
  caroujo: 'caroujo',
  caroupo: 'caroupo',
  carovas: 'carovas',
  carpada: 'carpada',
  carpado: 'carpado',
  carpais: 'carpais',
  carpamo: 'carpamo',
  carpara: 'carpara',
  carpata: 'carpata',
  carpava: 'carpava',
  carpeai: 'carpeai',
  carpear: 'carpear',
  carpeas: 'cárpeas',
  carpeei: 'carpeei',
  carpeia: 'carpeia',
  carpeie: 'carpeie',
  carpeio: 'carpeio',
  carpeis: 'carpeis',
  carpela: 'carpela',
  carpelo: 'carpelo',
  carpemo: 'carpemo',
  carpeno: 'carpeno',
  carpeos: 'cárpeos',
  carpeou: 'carpeou',
  carpeta: 'carpeta',
  carpete: 'carpete',
  carpeto: 'carpeto',
  carpiam: 'carpiam',
  carpiar: 'carpiar',
  carpias: 'carpias',
  carpica: 'cárpica',
  carpico: 'cárpico',
  carpida: 'carpida',
  carpido: 'carpido',
  carpimo: 'carpimo',
  carpina: 'carpina',
  carpino: 'carpino',
  carpins: 'carpins',
  carpira: 'carpira',
  carpite: 'carpite',
  carptor: 'carptor',
  carques: 'carques',
  carraca: 'carraça',
  carraco: 'carraço',
  carrada: 'carrada',
  carrado: 'carrado',
  carrais: 'carrais',
  carrajo: 'carrajó',
  carramo: 'carramo',
  carrane: 'carrane',
  carrara: 'carrara',
  carrava: 'carrava',
  carreai: 'carreai',
  carrear: 'carrear',
  carrebo: 'cárrebo',
  carreco: 'carreço',
  carreei: 'carreei',
  carrega: 'carrega',
  carrego: 'carrego',
  carreia: 'carreia',
  carreie: 'carreie',
  carreio: 'carreio',
  carreis: 'carreis',
  carreja: 'carreja',
  carreje: 'carreje',
  carrejo: 'carrejo',
  carrela: 'carrela',
  carrelo: 'carrelo',
  carremo: 'carremo',
  carreno: 'carreno',
  carreou: 'carreou',
  carreta: 'carreta',
  carrete: 'carrete',
  carreto: 'carreto',
  carreus: 'carreus',
  carrexa: 'carrexa',
  carriao: 'carrião',
  carrica: 'carriça',
  carrico: 'carriço',
  carrija: 'carrija',
  carrijo: 'carrijó',
  carrila: 'carrila',
  carrile: 'carrile',
  carrilo: 'carrilo',
  carroca: 'carroça',
  carroce: 'carroce',
  carroco: 'carroço',
  carroes: 'carrões',
  carrola: 'carrola',
  carruca: 'carruca',
  carruco: 'carruço',
  carrulo: 'carrulo',
  carsica: 'cársica',
  carsico: 'cársico',
  carstes: 'carstes',
  cartada: 'cartada',
  cartado: 'cartado',
  cartago: 'cartago',
  cartais: 'cartais',
  cartalo: 'cartalo',
  cartamo: 'cártamo',
  cartape: 'cartapé',
  cartara: 'cartara',
  cartava: 'cartava',
  cartaxo: 'cartaxo',
  carteai: 'carteai',
  cartear: 'cartear',
  carteei: 'carteei',
  carteia: 'carteia',
  carteie: 'carteie',
  carteio: 'carteio',
  carteis: 'cartéis',
  cartela: 'cartela',
  cartema: 'cartema',
  cartemo: 'cartemo',
  carteou: 'carteou',
  carteta: 'carteta',
  cartoes: 'cartões',
  cartola: 'cartola',
  cartona: 'cartona',
  cartone: 'cartone',
  cartono: 'cartono',
  cartoon: 'cartoon',
  cartufa: 'cartufa',
  cartula: 'cártula',
  cartune: 'cartune',
  cartuns: 'cartuns',
  cartuxa: 'cartuxa',
  cartuxo: 'cartuxo',
  caruana: 'caruana',
  caruani: 'caruani',
  caruara: 'caruara',
  caruaru: 'caruaru',
  caruata: 'caruatá',
  carubas: 'carubas',
  carucaa: 'carucaá',
  carucas: 'carucas',
  carucha: 'carucha',
  carucho: 'carucho',
  caruera: 'caruera',
  carugem: 'carugem',
  caruiri: 'caruiri',
  carujai: 'carujai',
  carujam: 'carujam',
  carujar: 'carujar',
  carujas: 'carujas',
  carujei: 'carujei',
  carujem: 'carujem',
  carujes: 'carujes',
  carujos: 'carujos',
  carujou: 'carujou',
  carulha: 'carulha',
  carulos: 'carulos',
  carumas: 'carumas',
  carumba: 'carumba',
  carumbe: 'carumbé',
  carunfa: 'carunfa',
  carunha: 'carunha',
  carunho: 'carunho',
  carunjo: 'carunjo',
  caruque: 'caruqué',
  carures: 'carurés',
  caruros: 'caruros',
  carurus: 'carurus',
  carusma: 'carusma',
  carutos: 'carutos',
  carvale: 'carvalé',
  carveja: 'carveja',
  carveje: 'carveje',
  carvejo: 'carvejo',
  carveli: 'carveli',
  carveno: 'carveno',
  carveol: 'carveol',
  carvoal: 'carvoal',
  carvoes: 'carvões',
  carvois: 'carvóis',
  carvona: 'carvona',
  casacao: 'casacão',
  casacas: 'casacas',
  casacos: 'casacos',
  casadao: 'casadão',
  casadas: 'casadas',
  casador: 'casador',
  casados: 'casados',
  casalai: 'casalai',
  casalam: 'casalam',
  casalar: 'casalar',
  casalas: 'casalas',
  casalei: 'casalei',
  casalem: 'casalem',
  casales: 'casales',
  casalia: 'casália',
  casalou: 'casalou',
  casamos: 'casamos',
  casanas: 'casanas',
  casando: 'casando',
  casante: 'casante',
  casapos: 'casapos',
  casaram: 'casaram',
  casarao: 'casarão',
  casaras: 'casaras',
  casarca: 'casarca',
  casarei: 'casarei',
  casarem: 'casarem',
  casares: 'casares',
  casareu: 'casaréu',
  casaria: 'casaria',
  casario: 'casario',
  casarmo: 'casarmo',
  casasse: 'casasse',
  casaste: 'casaste',
  casatas: 'casatas',
  casavam: 'casavam',
  casavas: 'casavas',
  casavel: 'casável',
  casbiai: 'casbiai',
  casbiam: 'casbiam',
  casbiao: 'casbião',
  casbiar: 'casbiar',
  casbias: 'casbias',
  casbiei: 'casbiei',
  casbiem: 'casbiem',
  casbies: 'casbies',
  casbiou: 'casbiou',
  cascada: 'cascada',
  cascado: 'cascado',
  cascais: 'cascais',
  cascamo: 'cascamo',
  cascana: 'cascana',
  cascara: 'cáscara',
  cascata: 'cascata',
  cascate: 'cascate',
  cascato: 'cascato',
  cascava: 'cascava',
  casceta: 'casceta',
  cascoes: 'cascões',
  cascois: 'cascóis',
  cascona: 'cascona',
  cascosa: 'cascosa',
  cascoso: 'cascoso',
  cascuda: 'cascuda',
  cascudo: 'cascudo',
  caseada: 'caseada',
  caseado: 'caseado',
  caseais: 'caseais',
  caseara: 'caseara',
  casease: 'casease',
  caseato: 'caseato',
  caseava: 'caseava',
  casebre: 'casebre',
  casecos: 'casecos',
  caseeis: 'caseeis',
  caseiam: 'caseiam',
  caseias: 'caseias',
  caseica: 'caseica',
  caseico: 'caseico',
  caseiem: 'caseiem',
  caseies: 'caseies',
  caseina: 'caseína',
  caseino: 'caseíno',
  caseira: 'caseira',
  caseiro: 'caseiro',
  caselas: 'caselas',
  casemia: 'casemia',
  casemos: 'casemos',
  caseosa: 'caseosa',
  caseose: 'caseose',
  caseoso: 'caseoso',
  caseque: 'caseque',
  caseria: 'caseria',
  caserna: 'caserna',
  caseros: 'caserós',
  casetao: 'casetão',
  casetas: 'casetas',
  casinas: 'casinas',
  casineu: 'casinéu',
  casinga: 'casinga',
  casinha: 'casinha',
  casinho: 'casinho',
  casinos: 'casinos',
  casitas: 'casitas',
  casiteu: 'casitéu',
  casolas: 'casolas',
  casonas: 'casonas',
  casoria: 'casória',
  casorio: 'casório',
  casotas: 'casotas',
  casotos: 'casotos',
  caspite: 'cáspite',
  casposa: 'casposa',
  casposo: 'casposo',
  casquea: 'casqueá',
  casquei: 'casquei',
  casquem: 'casquem',
  casques: 'casques',
  cassaba: 'cassaba',
  cassaco: 'cassaco',
  cassada: 'cassada',
  cassade: 'cassade',
  cassado: 'cassado',
  cassais: 'cassais',
  cassaje: 'cassaje',
  cassame: 'cassame',
  cassamo: 'cassamo',
  cassana: 'cassana',
  cassara: 'cassara',
  cassata: 'cassata',
  cassava: 'cassava',
  casseai: 'casseai',
  cassear: 'cassear',
  casseei: 'casseei',
  casseia: 'casseia',
  casseie: 'casseie',
  casseio: 'casseio',
  casseis: 'casseis',
  cassemo: 'cassemo',
  casseou: 'casseou',
  cassete: 'cassete',
  cassias: 'cássias',
  cassica: 'cassica',
  cassico: 'cassico',
  cassida: 'cássida',
  cassina: 'cassina',
  cassino: 'cassino',
  cassins: 'cassins',
  cassita: 'cassita',
  cassoas: 'cassoas',
  cassoco: 'cassoco',
  cassupa: 'cassupa',
  casteai: 'casteai',
  castear: 'castear',
  casteei: 'casteei',
  casteia: 'casteia',
  casteie: 'casteie',
  casteio: 'casteio',
  castela: 'castela',
  castelo: 'castelo',
  casteou: 'casteou',
  castica: 'castiça',
  castice: 'castice',
  castico: 'castiço',
  castiga: 'castiga',
  castigo: 'castigo',
  castila: 'castila',
  castina: 'castina',
  castoes: 'castões',
  castrai: 'castrai',
  castram: 'castram',
  castrao: 'castrão',
  castrar: 'castrar',
  castras: 'castras',
  castrei: 'castrei',
  castrem: 'castrem',
  castres: 'castres',
  castrou: 'castrou',
  casture: 'casturé',
  casturi: 'casturi',
  casuais: 'casuais',
  casucha: 'casucha',
  casucos: 'casucos',
  casulas: 'casulas',
  casulos: 'casulos',
  catabas: 'catabas',
  catabil: 'catabil',
  catabis: 'catabis',
  catacao: 'catação',
  catacas: 'catacás',
  catacus: 'catacus',
  catadao: 'catadão',
  catadas: 'catadas',
  catador: 'catador',
  catados: 'catados',
  catafes: 'catafes',
  catagma: 'catagma',
  catagua: 'cataguá',
  cataias: 'cataias',
  catajes: 'catajés',
  catalao: 'catalão',
  catalas: 'catalãs',
  catalos: 'catalós',
  catalpa: 'catalpa',
  catalpo: 'catalpo',
  catamba: 'catambá',
  catamos: 'catamos',
  catanai: 'catanai',
  catanam: 'catanam',
  catanar: 'catanar',
  catanas: 'catanas',
  catanca: 'catança',
  catanda: 'catanda',
  catando: 'catando',
  catanea: 'cataneá',
  catanei: 'catanei',
  catanem: 'catanem',
  catanes: 'catanes',
  catanga: 'catanga',
  catanos: 'catanos',
  catanou: 'catanou',
  catante: 'catante',
  cataolo: 'cataolo',
  catapao: 'catapão',
  catapas: 'catapas',
  catapus: 'catapus',
  cataram: 'cataram',
  catarao: 'catarão',
  cataras: 'cataras',
  catarei: 'catarei',
  catarem: 'catarem',
  catares: 'catares',
  cataria: 'cataria',
  catario: 'catário',
  cataris: 'cataris',
  catarma: 'catarma',
  catarmo: 'catarmo',
  cataros: 'cátaros',
  catarro: 'catarro',
  catarse: 'catarse',
  catarta: 'catarta',
  catarte: 'catarte',
  catarto: 'catarto',
  catasol: 'catasol',
  catasse: 'catasse',
  catasta: 'catasta',
  cataste: 'cataste',
  catatas: 'catatas',
  catatau: 'catatau',
  catatos: 'catatos',
  catatua: 'catatua',
  catauba: 'cataúba',
  cataure: 'catauré',
  catavam: 'catavam',
  catavas: 'catavas',
  cataxia: 'cataxia',
  cataxus: 'cataxus',
  cateada: 'cateada',
  cateado: 'cateado',
  cateais: 'cateais',
  cateara: 'cateará',
  cateaua: 'cateauá',
  cateava: 'cateava',
  catechu: 'catechu',
  catecol: 'catecol',
  catecos: 'catecos',
  catedra: 'cátedra',
  cateeis: 'cateeis',
  cateiam: 'cateiam',
  cateias: 'cateias',
  cateiem: 'cateiem',
  cateies: 'cateies',
  catejua: 'catejuá',
  catembe: 'catembe',
  catemos: 'catemos',
  catenas: 'catenas',
  catenga: 'catenga',
  caterva: 'caterva',
  catetao: 'catetão',
  cateter: 'cateter',
  catetes: 'catetes',
  catetos: 'catetos',
  catexes: 'catexes',
  catexia: 'catexia',
  catiana: 'catiana',
  catiano: 'catiano',
  catiaua: 'catiauá',
  catibas: 'catibas',
  caticos: 'caticós',
  catigua: 'catiguá',
  catilia: 'catília',
  catimba: 'catimba',
  catimbe: 'catimbe',
  catimbo: 'catimbó',
  catinas: 'catinas',
  catinga: 'catinga',
  catingo: 'catingo',
  catioas: 'catioas',
  catioes: 'catiões',
  cations: 'cátions',
  catipes: 'catipés',
  catipna: 'catipna',
  catiras: 'catiras',
  catitai: 'catitai',
  catitam: 'catitam',
  catitar: 'catitar',
  catitas: 'catitas',
  catitei: 'catitei',
  catitem: 'catitem',
  catites: 'catites',
  catitou: 'catitou',
  catitus: 'catitus',
  cativai: 'cativai',
  cativam: 'cativam',
  cativar: 'cativar',
  cativas: 'cativas',
  cativei: 'cativei',
  cativem: 'cativem',
  catives: 'catives',
  cativos: 'cativos',
  cativou: 'cativou',
  catixas: 'catixas',
  catleia: 'catleia',
  catocar: 'catocar',
  catocas: 'catocas',
  catocos: 'catocos',
  catodal: 'catodal',
  catodao: 'catodão',
  catodas: 'catodas',
  catodio: 'catódio',
  catodos: 'catodos',
  catojes: 'catojés',
  catombe: 'catombe',
  catombo: 'catombo',
  catonga: 'catonga',
  catopes: 'catopês',
  catopse: 'catopse',
  catoris: 'catoris',
  catorra: 'catorra',
  catorze: 'catorze',
  catotal: 'catotal',
  catotas: 'catotas',
  catotol: 'catotol',
  catotos: 'catotos',
  catraca: 'catraca',
  catrafa: 'catrafa',
  catraia: 'catraia',
  catraie: 'catraie',
  catraio: 'catraio',
  catrais: 'catrais',
  catrame: 'catrame',
  catraus: 'catraus',
  catrefa: 'catrefa',
  catreta: 'catreta',
  catreva: 'catreva',
  catrina: 'catrina',
  catrino: 'catrino',
  catrofa: 'catrofa',
  catrofe: 'catrofe',
  catroia: 'catroia',
  catroio: 'catroio',
  catuaba: 'catuaba',
  catuais: 'catuais',
  catucai: 'catucai',
  catucam: 'catucam',
  catucao: 'catucão',
  catucar: 'catucar',
  catucas: 'catucas',
  catucou: 'catucou',
  catuena: 'catuena',
  catuiba: 'catuíba',
  catuiti: 'catuiti',
  catuixi: 'catuixi',
  catulas: 'catulas',
  catules: 'catulés',
  catulos: 'catulos',
  catumas: 'catumas',
  catumbi: 'catumbi',
  catumbo: 'catumbo',
  catumua: 'catumua',
  catupes: 'catupés',
  catuque: 'catuque',
  catuqui: 'catuqui',
  caturna: 'caturna',
  caturno: 'caturno',
  caturos: 'caturos',
  caturra: 'caturra',
  caturre: 'caturre',
  caturro: 'caturro',
  catutas: 'catutas',
  catutos: 'catutos',
  cauabas: 'cauabas',
  cauacus: 'cauaçus',
  cauaias: 'cauaiás',
  cauaiba: 'cauaíba',
  cauanas: 'cauanas',
  cauanda: 'cauanda',
  cauanga: 'cauanga',
  cauaras: 'cauarás',
  cauares: 'cauarés',
  cauaris: 'cauaris',
  cauauas: 'cauauãs',
  cauaues: 'cauaués',
  cauaxis: 'cauaxis',
  caubila: 'caubila',
  caubois: 'caubóis',
  caucamo: 'caucamo',
  caucaso: 'cáucaso',
  caucela: 'caucela',
  cauchal: 'cauchal',
  cauchim: 'cauchim',
  cauchos: 'cauchos',
  cauchus: 'cauchus',
  caucoes: 'cauções',
  caucona: 'cauçona',
  caudada: 'caudada',
  caudado: 'caudado',
  caudais: 'caudais',
  caudato: 'caudato',
  caudeis: 'caudéis',
  caudela: 'caudela',
  caudele: 'caudele',
  caudelo: 'caudelo',
  caudice: 'cáudice',
  caudino: 'caudino',
  caudona: 'caudona',
  cauduta: 'cauduta',
  cauembe: 'cauembe',
  caueras: 'caueras',
  cauiana: 'cauiana',
  cauilas: 'cauilas',
  cauinta: 'cauintã',
  cauipes: 'cauipes',
  cauiras: 'cauiras',
  cauirei: 'cauireí',
  cauixis: 'cauixis',
  caulina: 'caulina',
  cauline: 'cauline',
  caulino: 'caulino',
  caulins: 'caulins',
  cauloma: 'cauloma',
  caunhos: 'caunhos',
  caureis: 'caureís',
  caurina: 'caurina',
  caurine: 'caurine',
  caurino: 'caurino',
  caurins: 'caurins',
  caurita: 'caurita',
  causada: 'causada',
  causado: 'causado',
  causais: 'causais',
  causamo: 'causamo',
  causara: 'causara',
  causava: 'causava',
  causeis: 'causeis',
  causela: 'causela',
  causemo: 'causemo',
  causeur: 'causeur',
  cautchu: 'cautchu',
  cautela: 'cautela',
  cautele: 'cautele',
  cautelo: 'cautelo',
  cavacai: 'cavacai',
  cavacam: 'cavacam',
  cavacao: 'cavação',
  cavacar: 'cavacar',
  cavacas: 'cavacas',
  cavacos: 'cavacos',
  cavacou: 'cavacou',
  cavacue: 'cavacué',
  cavadao: 'cavadão',
  cavadas: 'cavadas',
  cavadia: 'cavadia',
  cavador: 'cavador',
  cavados: 'cavados',
  cavaiba: 'cavaíba',
  cavalao: 'cavalão',
  cavalar: 'cavalar',
  cavalas: 'cavalas',
  cavalea: 'cavaleá',
  cavalga: 'cavalga',
  cavalgo: 'cavalgo',
  cavalho: 'cavalho',
  cavalim: 'cavalim',
  cavaloa: 'cavaloa',
  cavaloe: 'cavaloe',
  cavaloo: 'cavaloo',
  cavalos: 'cavalos',
  cavamos: 'cavamos',
  cavanco: 'cavanço',
  cavando: 'cavando',
  cavanha: 'cavanha',
  cavanis: 'cavanis',
  cavanta: 'cavantã',
  cavante: 'cavante',
  cavaque: 'cavaque',
  cavaram: 'cavaram',
  cavarao: 'cavarão',
  cavaras: 'cavaras',
  cavarei: 'cavarei',
  cavarem: 'cavarem',
  cavares: 'cavares',
  cavaria: 'cavaria',
  cavarmo: 'cavarmo',
  cavasse: 'cavasse',
  cavaste: 'cavaste',
  cavaues: 'cavaués',
  cavavam: 'cavavam',
  cavavas: 'cavavas',
  caveada: 'caveada',
  caveado: 'caveado',
  caveais: 'caveais',
  caveara: 'caveará',
  caveava: 'caveava',
  cavedal: 'cavedal',
  cavedio: 'cavédio',
  caveeis: 'caveeis',
  caveiam: 'caveiam',
  caveias: 'caveias',
  caveiem: 'caveiem',
  caveies: 'caveies',
  caveira: 'caveira',
  caveiro: 'caveiro',
  cavelha: 'cavelha',
  cavemos: 'cavemos',
  cavense: 'cavense',
  caveolo: 'cavéolo',
  caverna: 'caverna',
  caverne: 'caverne',
  caverno: 'caverno',
  cavetos: 'cavetos',
  caviado: 'caviado',
  caviais: 'caviais',
  caviamo: 'caviamo',
  caviana: 'caviana',
  caviano: 'caviano',
  caviara: 'caviara',
  caviava: 'caviava',
  cavidai: 'cavidai',
  cavidam: 'cavidam',
  cavidar: 'cavidar',
  cavidas: 'cavidas',
  cavidea: 'cavídea',
  cavidei: 'cavidei',
  cavidem: 'cavidem',
  cavideo: 'cavídeo',
  cavides: 'cavides',
  cavidou: 'cavidou',
  cavieis: 'cavieis',
  caviemo: 'caviemo',
  caviida: 'cavíida',
  cavilai: 'cavilai',
  cavilam: 'cavilam',
  cavilar: 'cavilar',
  cavilas: 'cavilas',
  cavilei: 'cavilei',
  cavilem: 'cavilem',
  caviles: 'caviles',
  cavilha: 'cavilha',
  cavilhe: 'cavilhe',
  cavilho: 'cavilho',
  cavilou: 'cavilou',
  cavirao: 'cavirão',
  cavitai: 'cavitai',
  cavitam: 'cavitam',
  cavitar: 'cavitar',
  cavitas: 'cavitas',
  cavitei: 'cavitei',
  cavitem: 'cavitem',
  cavites: 'cavites',
  cavitou: 'cavitou',
  caviuna: 'caviúna',
  cavocai: 'cavocai',
  cavocam: 'cavocam',
  cavocar: 'cavocar',
  cavocas: 'cavocas',
  cavocou: 'cavocou',
  cavodas: 'cavodás',
  cavoque: 'cavoque',
  cavouca: 'cavouca',
  cavouco: 'cavouco',
  cavucai: 'cavucai',
  cavucam: 'cavucam',
  cavucar: 'cavucar',
  cavucas: 'cavucas',
  cavucou: 'cavucou',
  cavuque: 'cavuque',
  caxamba: 'caxambá',
  caxambu: 'caxambu',
  caxando: 'caxandó',
  caxanga: 'caxangá',
  caxango: 'caxango',
  caxareu: 'caxaréu',
  caxerim: 'caxerim',
  caxetas: 'caxetas',
  caxexas: 'caxexas',
  caxexes: 'caxexes',
  caxibos: 'caxibos',
  caxibus: 'caxibus',
  caxicas: 'caxicas',
  caxicos: 'caxicos',
  caxinde: 'caxinde',
  caxines: 'caxinés',
  caxinga: 'caxinga',
  caxingo: 'caxingó',
  caxinje: 'caxinje',
  caxinxa: 'caxinxa',
  caxinxe: 'caxinxe',
  caxique: 'caxique',
  caxirim: 'caxirim',
  caxiris: 'caxiris',
  caxitos: 'caxitos',
  caxixes: 'caxixes',
  caxixis: 'caxixis',
  caxotao: 'caxotão',
  caxuana: 'caxuana',
  caxumba: 'caxumba',
  caxunde: 'caxundé',
  caxupas: 'caxupas',
  cazaque: 'cazaque',
  cazembe: 'cazembe',
  cazembi: 'cazembi',
  cazumba: 'cazumba',
  cazumbi: 'cazumbi',
  cazures: 'cazurés',
  cccliii: 'cccliii',
  ccclvii: 'ccclvii',
  ccclxii: 'ccclxii',
  ccclxiv: 'ccclxiv',
  ccclxix: 'ccclxix',
  ccclxvi: 'ccclxvi',
  ccclxxi: 'ccclxxi',
  ccclxxv: 'ccclxxv',
  ccclxxx: 'ccclxxx',
  cccviii: 'cccviii',
  cccxcii: 'cccxcii',
  cccxciv: 'cccxciv',
  cccxcix: 'cccxcix',
  cccxcvi: 'cccxcvi',
  cccxiii: 'cccxiii',
  cccxlii: 'cccxlii',
  cccxliv: 'cccxliv',
  cccxlix: 'cccxlix',
  cccxlvi: 'cccxlvi',
  cccxvii: 'cccxvii',
  cccxxii: 'cccxxii',
  cccxxiv: 'cccxxiv',
  cccxxix: 'cccxxix',
  cccxxvi: 'cccxxvi',
  cccxxxi: 'cccxxxi',
  cccxxxv: 'cccxxxv',
  cclviii: 'cclviii',
  cclxiii: 'cclxiii',
  cclxvii: 'cclxvii',
  cclxxii: 'cclxxii',
  cclxxiv: 'cclxxiv',
  cclxxix: 'cclxxix',
  cclxxvi: 'cclxxvi',
  cclxxxi: 'cclxxxi',
  cclxxxv: 'cclxxxv',
  ccxciii: 'ccxciii',
  ccxcvii: 'ccxcvii',
  ccxliii: 'ccxliii',
  ccxlvii: 'ccxlvii',
  ccxviii: 'ccxviii',
  ccxxiii: 'ccxxiii',
  ccxxvii: 'ccxxvii',
  ccxxxii: 'ccxxxii',
  ccxxxiv: 'ccxxxiv',
  ccxxxix: 'ccxxxix',
  ccxxxvi: 'ccxxxvi',
  cdlviii: 'cdlviii',
  cdlxiii: 'cdlxiii',
  cdlxvii: 'cdlxvii',
  cdlxxii: 'cdlxxii',
  cdlxxiv: 'cdlxxiv',
  cdlxxix: 'cdlxxix',
  cdlxxvi: 'cdlxxvi',
  cdlxxxi: 'cdlxxxi',
  cdlxxxv: 'cdlxxxv',
  cdxciii: 'cdxciii',
  cdxcvii: 'cdxcvii',
  cdxliii: 'cdxliii',
  cdxlvii: 'cdxlvii',
  cdxviii: 'cdxviii',
  cdxxiii: 'cdxxiii',
  cdxxvii: 'cdxxvii',
  cdxxxii: 'cdxxxii',
  cdxxxiv: 'cdxxxiv',
  cdxxxix: 'cdxxxix',
  cdxxxvi: 'cdxxxvi',
  ceadoes: 'ceadões',
  ceadona: 'ceadona',
  ceadora: 'ceadora',
  ceanoto: 'ceanoto',
  ceantes: 'ceantes',
  ceardes: 'ceardes',
  ceareis: 'ceareis',
  cearemo: 'cearemo',
  ceariam: 'ceariam',
  cearias: 'cearias',
  cearmos: 'cearmos',
  ceassem: 'ceassem',
  ceasses: 'ceasses',
  ceastes: 'ceastes',
  ceaveis: 'ceáveis',
  cebares: 'cebares',
  cebelha: 'cebelha',
  cebiana: 'cebiana',
  cebiano: 'cebiano',
  cebiche: 'cebiche',
  cebidas: 'cébidas',
  cebidea: 'cebídea',
  cebideo: 'cebídeo',
  cebinas: 'cebinas',
  cebinos: 'cebinos',
  cebites: 'cebites',
  ceboide: 'ceboide',
  cebolal: 'cebolal',
  cebolao: 'cebolão',
  cebolas: 'cebolas',
  cebolos: 'cebolos',
  cebreno: 'cebreno',
  cebrios: 'cébrios',
  cebruno: 'cebruno',
  cebuana: 'cebuana',
  cebuano: 'cebuano',
  ceceada: 'ceceada',
  ceceado: 'ceceado',
  ceceais: 'ceceais',
  ceceara: 'ceceara',
  ceceava: 'ceceava',
  ceceeis: 'ceceeis',
  cecegas: 'cécegas',
  ceceiam: 'ceceiam',
  ceceias: 'ceceias',
  ceceiem: 'ceceiem',
  ceceies: 'ceceies',
  ceceosa: 'ceceosa',
  ceceoso: 'ceceoso',
  cecices: 'cecices',
  cecidas: 'cécidas',
  cecidea: 'cecídea',
  cecideo: 'cecídeo',
  cecidia: 'cecídia',
  cecidio: 'cecídio',
  cecilio: 'cecílio',
  cecites: 'cecites',
  ceconas: 'ceçonas',
  cecrops: 'cécrops',
  cecubas: 'cécubas',
  cecubos: 'cécubos',
  cedamos: 'cedamos',
  cededor: 'cededor',
  cedeira: 'cedeira',
  cedemos: 'cedemos',
  cedendo: 'cedendo',
  cedente: 'cedente',
  cederam: 'cederam',
  cederao: 'cederão',
  cederas: 'cederas',
  cederei: 'cederei',
  cederem: 'cederem',
  cederes: 'cederes',
  cederia: 'cederia',
  cedermo: 'cedermo',
  cedesse: 'cedesse',
  cedeste: 'cedeste',
  cedicos: 'cediços',
  cedidao: 'cedidão',
  cedidas: 'cedidas',
  cedidos: 'cedidos',
  cedieis: 'cedíeis',
  cedilha: 'cedilha',
  cedilhe: 'cedilhe',
  cedilho: 'cedilho',
  cedinha: 'cedinha',
  cedinho: 'cedinho',
  cedivel: 'cedível',
  cedoiro: 'cedoiro',
  cedoura: 'cedoura',
  cedouro: 'cedouro',
  cedovem: 'cedovém',
  cedrela: 'cedrela',
  cedreno: 'cedreno',
  cedrera: 'cedrera',
  cedrias: 'cédrias',
  cedrina: 'cedrina',
  cedrino: 'cedrino',
  cedrita: 'cedrita',
  cedroes: 'cédrões',
  cedrois: 'cedroís',
  cedular: 'cedular',
  cedulas: 'cédulas',
  ceeiras: 'ceeiras',
  ceeiros: 'ceeiros',
  cefaele: 'cefaele',
  cefalao: 'cefalão',
  cefalas: 'céfalas',
  cefalea: 'cefálea',
  cefaleo: 'cefáleo',
  cefalia: 'cefalia',
  cefalio: 'cefálio',
  cefalos: 'céfalos',
  cefeida: 'cefeida',
  cefeles: 'cefeles',
  cefelia: 'cefélia',
  cefenes: 'cefenes',
  cefisio: 'cefísio',
  cegadao: 'cegadão',
  cegadas: 'cegadas',
  cegador: 'cegador',
  cegados: 'cegados',
  cegagem: 'cegagem',
  cegamos: 'cegamos',
  cegando: 'cegando',
  cegante: 'cegante',
  cegaram: 'cegaram',
  cegarao: 'cegarão',
  cegaras: 'cegaras',
  cegarei: 'cegarei',
  cegarem: 'cegarem',
  cegares: 'cegares',
  cegaria: 'cegaria',
  cegarmo: 'cegarmo',
  cegasse: 'cegasse',
  cegaste: 'cegaste',
  cegavam: 'cegavam',
  cegavas: 'cegavas',
  cegonha: 'cegonha',
  cegonho: 'cegonho',
  cegudas: 'cegudas',
  cegudes: 'cegudes',
  cegudos: 'cegudos',
  cegueis: 'cegueis',
  ceguela: 'ceguela',
  ceguemo: 'ceguemo',
  cegueta: 'cegueta',
  ceicais: 'ceiçais',
  ceifada: 'ceifada',
  ceifado: 'ceifado',
  ceifais: 'ceifais',
  ceifamo: 'ceifamo',
  ceifara: 'ceifara',
  ceifava: 'ceifava',
  ceifeis: 'ceifeis',
  ceifemo: 'ceifemo',
  ceifoes: 'ceifões',
  ceiotes: 'ceiotes',
  ceitoes: 'ceitões',
  ceivada: 'ceivada',
  ceivado: 'ceivado',
  ceivais: 'ceivais',
  ceivamo: 'ceivamo',
  ceivara: 'ceivará',
  ceivava: 'ceivava',
  ceiveis: 'ceiveis',
  ceivemo: 'ceivemo',
  celacao: 'celação',
  celadas: 'celadas',
  celagem: 'celagem',
  celamim: 'celamim',
  celaria: 'celária',
  celario: 'celário',
  celases: 'célases',
  celcios: 'célcios',
  celebra: 'celebra',
  celebre: 'célebre',
  celebro: 'celebro',
  celeira: 'celeira',
  celeiro: 'celeiro',
  celenas: 'celenas',
  celenos: 'celenos',
  celense: 'celense',
  celepar: 'celepar',
  celeres: 'céleres',
  celeria: 'celéria',
  celesta: 'celesta',
  celeste: 'celeste',
  celetos: 'celetos',
  celeuma: 'celeuma',
  celeume: 'celeume',
  celeumo: 'celeumo',
  celhada: 'celhada',
  celhado: 'celhado',
  celiaca: 'celíaca',
  celiaco: 'celíaco',
  celibes: 'célibes',
  celicas: 'célicas',
  celicos: 'célicos',
  celidio: 'celídio',
  celinas: 'celinas',
  celiode: 'celiode',
  celioma: 'celioma',
  celioxe: 'celioxe',
  celista: 'celista',
  celitas: 'celitas',
  celites: 'celites',
  celodon: 'célodon',
  celomas: 'celomas',
  celopes: 'celopes',
  celosas: 'celosas',
  celoses: 'celoses',
  celsias: 'célsias',
  celsius: 'celsius',
  celtica: 'céltica',
  celtico: 'céltico',
  celtios: 'céltios',
  celtiza: 'celtiza',
  celtize: 'celtize',
  celtizo: 'celtizo',
  celtuce: 'celtuce',
  celular: 'celular',
  celulas: 'células',
  celuros: 'celuros',
  celvada: 'celvada',
  celvado: 'celvado',
  celvais: 'celvais',
  celvamo: 'celvamo',
  celvara: 'celvará',
  celvava: 'celvava',
  celveis: 'celveis',
  celvemo: 'celvemo',
  cembalo: 'cêmbalo',
  cembros: 'cembros',
  cementa: 'cementa',
  cemente: 'cemente',
  cemento: 'cemento',
  cemonos: 'cemonos',
  cempses: 'cempses',
  cempsio: 'cêmpsio',
  cempsos: 'cempsos',
  cenagal: 'cenagal',
  cenamil: 'cenamil',
  cenamis: 'cenamis',
  cenanto: 'cenanto',
  cenario: 'cenário',
  cencros: 'cencros',
  cendais: 'cendais',
  cendrai: 'cendrai',
  cendram: 'cendram',
  cendrar: 'cendrar',
  cendras: 'cendras',
  cendrei: 'cendrei',
  cendrem: 'cendrem',
  cendres: 'cendres',
  cendrou: 'cendrou',
  cenemas: 'cenemas',
  cenhosa: 'cenhosa',
  cenhoso: 'cenhoso',
  cenicas: 'cênicas',
  cenicos: 'cênicos',
  cenismo: 'cenismo',
  cenista: 'cenista',
  cenitas: 'cenitas',
  cenitos: 'cenitos',
  cenizes: 'cenizes',
  cenobia: 'cenóbia',
  cenobio: 'cenóbio',
  cenoira: 'cenoira',
  cenosas: 'cenosas',
  cenoses: 'cenoses',
  cenosia: 'cenósia',
  cenosos: 'cenosos',
  cenotes: 'cenotes',
  cenoura: 'cenoura',
  cenrada: 'cenrada',
  censora: 'censora',
  censual: 'censual',
  censura: 'censura',
  censure: 'censure',
  censuro: 'censuro',
  centavo: 'centavo',
  centeal: 'centeal',
  centeia: 'centeia',
  centeio: 'centeio',
  centela: 'centela',
  centena: 'centena',
  centers: 'centers',
  centese: 'centese',
  centete: 'centete',
  centimo: 'cêntimo',
  centoes: 'centões',
  centola: 'centola',
  centore: 'cêntore',
  centrai: 'centrai',
  central: 'central',
  centram: 'centram',
  centrao: 'centrão',
  centrar: 'centrar',
  centras: 'centras',
  centrei: 'centrei',
  centrem: 'centrem',
  centres: 'centres',
  centros: 'centros',
  centrou: 'centrou',
  cenudas: 'cenudas',
  cenudos: 'cenudos',
  cenuros: 'cenuros',
  ceotada: 'ceotada',
  ceotado: 'ceotado',
  ceotais: 'ceotais',
  ceotamo: 'ceotamo',
  ceotara: 'ceotará',
  ceotava: 'ceotava',
  ceoteis: 'ceoteis',
  ceotemo: 'ceotemo',
  cepacea: 'cepácea',
  cepaceo: 'cepáceo',
  cepacia: 'cepacia',
  cepeira: 'cepeira',
  cepelho: 'cepelho',
  cepilha: 'cepilha',
  cepilhe: 'cepilhe',
  cepilho: 'cepilho',
  cepinho: 'cepinho',
  cepipas: 'cepipas',
  cepisco: 'cepisco',
  cepolas: 'cépolas',
  cepsios: 'cépsios',
  ceptica: 'céptica',
  ceptico: 'céptico',
  cepudas: 'cepudas',
  cepudos: 'cepudos',
  cequins: 'cequins',
  ceracea: 'cerácea',
  ceraceo: 'ceráceo',
  ceracio: 'cerácio',
  ceradao: 'ceradão',
  ceradas: 'ceradas',
  cerador: 'cerador',
  cerados: 'cerados',
  cerafro: 'cerafro',
  cerames: 'cerames',
  ceramia: 'cerâmia',
  ceramio: 'cerâmio',
  ceramos: 'ceramos',
  cerando: 'cerando',
  cerante: 'cerante',
  cerapos: 'cérapos',
  ceraram: 'ceraram',
  cerarao: 'cerarão',
  ceraras: 'cerarás',
  cerarei: 'cerarei',
  cerarem: 'cerarem',
  cerares: 'cerares',
  ceraria: 'ceraria',
  cerarmo: 'cerarmo',
  cerasao: 'cérasão',
  cerasma: 'cerasma',
  cerasos: 'cérasos',
  cerasse: 'cerasse',
  cerasta: 'cerasta',
  ceraste: 'ceraste',
  ceratio: 'cerátio',
  ceratos: 'ceratos',
  ceravam: 'ceravam',
  ceravas: 'ceravas',
  cerbana: 'cerbana',
  cerbano: 'cerbano',
  cerbera: 'cerbera',
  cerbero: 'cérbero',
  cercada: 'cercada',
  cercado: 'cercado',
  cercais: 'cercais',
  cercamo: 'cercamo',
  cercana: 'cercana',
  cercano: 'cercano',
  cercara: 'cercara',
  cercava: 'cercava',
  cerceai: 'cerceai',
  cerceal: 'cerceal',
  cercear: 'cercear',
  cerceas: 'cérceas',
  cerceei: 'cerceei',
  cercefi: 'cercefi',
  cerceia: 'cerceia',
  cerceie: 'cerceie',
  cerceio: 'cerceio',
  cerceos: 'cérceos',
  cerceou: 'cerceou',
  cercere: 'cércere',
  cerceta: 'cerceta',
  cerciao: 'cercião',
  cercide: 'cércide',
  cercino: 'cercino',
  cercios: 'cércios',
  cercnis: 'cércnis',
  cercoes: 'cercões',
  cercona: 'cercona',
  cercope: 'cercope',
  cercops: 'cércops',
  cercose: 'cercose',
  cercuda: 'cercuda',
  cercudo: 'cercudo',
  cerdana: 'cerdana',
  cerdeai: 'cerdeai',
  cerdear: 'cerdear',
  cerdeei: 'cerdeei',
  cerdeia: 'cerdeia',
  cerdeie: 'cerdeie',
  cerdeio: 'cerdeio',
  cerdeou: 'cerdeou',
  cerdoes: 'cerdões',
  cerdosa: 'cerdosa',
  cerdoso: 'cerdoso',
  cereais: 'cereais',
  cerebas: 'cerebas',
  cerebro: 'cérebro',
  cereira: 'cereira',
  cerejal: 'cerejal',
  cerejas: 'cerejas',
  cerejos: 'cerejos',
  ceremos: 'ceremos',
  cerense: 'cerense',
  cererio: 'cerério',
  ceresia: 'cerésia',
  ceresil: 'ceresil',
  ceresis: 'ceresis',
  ceresos: 'ceresos',
  cergiam: 'cergiam',
  cergias: 'cergias',
  cergida: 'cergida',
  cergido: 'cergido',
  cergira: 'cergirá',
  ceriata: 'ceriata',
  ceriato: 'ceriato',
  ceribas: 'ceribas',
  cericas: 'céricas',
  cericos: 'céricos',
  ceridea: 'cerídea',
  cerideo: 'cerídeo',
  cerides: 'cérides',
  cerilao: 'cerilão',
  cerilas: 'cerilas',
  ceriles: 'ceriles',
  cerilha: 'cerilha',
  cerilos: 'cerilos',
  cerinas: 'cerinas',
  cerinos: 'cerinos',
  cerinta: 'cerinta',
  cerinto: 'cerinto',
  cerions: 'cérions',
  ceriosa: 'ceriosa',
  ceritas: 'ceritas',
  cerites: 'cérites',
  cerivas: 'cerivas',
  cerjais: 'cerjais',
  cermeto: 'cermeto',
  cernada: 'cernada',
  cernado: 'cernado',
  cernais: 'cernais',
  cernamo: 'cernamo',
  cernara: 'cernará',
  cernava: 'cernava',
  cerneas: 'cérneas',
  cerneia: 'cerneia',
  cerneis: 'cerneis',
  cernemo: 'cernemo',
  cerneos: 'cérneos',
  cerniam: 'cerniam',
  cernias: 'cernias',
  cernida: 'cernida',
  cernido: 'cernido',
  cernimo: 'cernimo',
  cernira: 'cernira',
  cernoes: 'cernões',
  cernosa: 'cernosa',
  cernoso: 'cernoso',
  ceroide: 'ceroide',
  ceroila: 'ceroila',
  cerolha: 'cerolha',
  ceromas: 'ceromas',
  ceromel: 'ceromel',
  ceromes: 'ceromes',
  ceronas: 'ceronas',
  cerosas: 'cerosas',
  cerosos: 'cerosos',
  cerotos: 'cerotos',
  ceroula: 'ceroula',
  cerquei: 'cerquei',
  cerquem: 'cerquem',
  cerquer: 'cerquer',
  cerques: 'cerques',
  cerrada: 'cerrada',
  cerrado: 'cerrado',
  cerrais: 'cerrais',
  cerramo: 'cerramo',
  cerrara: 'cerrara',
  cerrava: 'cerrava',
  cerreis: 'cerreis',
  cerremo: 'cerremo',
  cerrete: 'cerrete',
  cerrito: 'cerrito',
  cerrote: 'cerrote',
  certada: 'certada',
  certado: 'certado',
  certais: 'certais',
  certame: 'certame',
  certamo: 'certamo',
  certara: 'certara',
  certava: 'certava',
  certeis: 'certeis',
  certela: 'certela',
  certemo: 'certemo',
  certeza: 'certeza',
  certias: 'cértias',
  certida: 'cértida',
  ceruana: 'ceruana',
  cerucos: 'cerucos',
  cerudas: 'cerudas',
  cerudes: 'cerudes',
  cerulea: 'cerúlea',
  ceruleo: 'cerúleo',
  cerulos: 'cérulos',
  cerumen: 'cerúmen',
  cerumes: 'cerumes',
  ceruras: 'ceruras',
  cerusas: 'cerusas',
  cerussa: 'cerussa',
  cervais: 'cervais',
  cervato: 'cervato',
  cervavo: 'cervavo',
  cerveja: 'cerveja',
  cerveje: 'cerveje',
  cervejo: 'cervejo',
  cervela: 'cervela',
  cervelo: 'cervelo',
  cervias: 'cérvias',
  cervice: 'cervice',
  cervida: 'cérvida',
  cervina: 'cervina',
  cervino: 'cervino',
  cervulo: 'cérvulo',
  cervuns: 'cervuns',
  cerziam: 'cerziam',
  cerzias: 'cerzias',
  cerzida: 'cerzida',
  cerzido: 'cerzido',
  cerzira: 'cerzirá',
  cesarea: 'cesárea',
  cesareo: 'cesáreo',
  cesares: 'césares',
  cesaria: 'cesaria',
  cesario: 'cesário',
  cesaros: 'cesaros',
  cesinas: 'cesinas',
  cesoiro: 'cesoiro',
  cesouro: 'cesouro',
  cespede: 'céspede',
  cespita: 'cespitá',
  cespite: 'cespite',
  cespito: 'cespito',
  cessada: 'cessada',
  cessado: 'cessado',
  cessais: 'cessais',
  cessamo: 'cessamo',
  cessara: 'cessara',
  cessava: 'cessava',
  cesseis: 'cesseis',
  cessemo: 'cessemo',
  cessoes: 'cessões',
  cessona: 'cessona',
  cestada: 'cestada',
  cestida: 'céstida',
  cestina: 'cestina',
  cestode: 'cestode',
  cestodo: 'céstodo',
  cestoes: 'cestões',
  cestrea: 'céstrea',
  cestros: 'cestros',
  cesulia: 'cesúlia',
  cesurai: 'cesurai',
  cesuram: 'cesuram',
  cesurar: 'cesurar',
  cesuras: 'cesuras',
  cesurei: 'cesurei',
  cesurem: 'cesurem',
  cesures: 'cesures',
  cesurou: 'cesurou',
  cetaceo: 'cetáceo',
  cetanas: 'cetanas',
  cetanos: 'cetanos',
  cetenas: 'cetenas',
  cetenes: 'cetenes',
  cetenos: 'cetenos',
  ceticas: 'céticas',
  ceticos: 'céticos',
  cetilas: 'cetilas',
  cetilha: 'cetilha',
  cetilio: 'cetílio',
  cetilos: 'cetilos',
  cetinas: 'cetinas',
  cetinea: 'cetínea',
  cetineo: 'cetíneo',
  cetonas: 'cetonas',
  cetonia: 'cetônia',
  cetoses: 'cetoses',
  cetoxal: 'cetoxal',
  cetrina: 'cetrina',
  cetrino: 'cetrino',
  ceutrao: 'ceutrão',
  cevadal: 'cevadal',
  cevadao: 'cevadão',
  cevadas: 'cevadas',
  cevador: 'cevador',
  cevados: 'cevados',
  cevagem: 'cevagem',
  cevalho: 'cevalho',
  cevamos: 'cevamos',
  cevando: 'cevando',
  cevante: 'cevante',
  cevaram: 'cevaram',
  cevarao: 'cevarão',
  cevaras: 'cevarás',
  cevarei: 'cevarei',
  cevarem: 'cevarem',
  cevares: 'cevares',
  cevaria: 'cevaria',
  cevarmo: 'cevarmo',
  cevasse: 'cevasse',
  cevaste: 'cevaste',
  cevavam: 'cevavam',
  cevavas: 'cevavas',
  ceveira: 'ceveira',
  ceveiro: 'ceveiro',
  cevemos: 'cevemos',
  cevenol: 'cevenol',
  ceviana: 'ceviana',
  ceviano: 'ceviano',
  ceviche: 'ceviche',
  cevinas: 'cevinas',
  cevolas: 'cévolas',
  cezinho: 'cezinho',
  chaapta: 'chaapta',
  chabaca: 'chabaçá',
  chabace: 'chabace',
  chabaco: 'chabaço',
  chabelo: 'chabelo',
  chabeta: 'chabeta',
  chabeus: 'chabéus',
  chabuco: 'chabuco',
  chacada: 'chaçada',
  chacado: 'chaçado',
  chacais: 'chacais',
  chacamo: 'chaçamo',
  chacara: 'chácara',
  chacava: 'chaçava',
  chaceis: 'chaceis',
  chacemo: 'chacemo',
  chachal: 'chachal',
  chachas: 'chachas',
  chacina: 'chacina',
  chacine: 'chacine',
  chacino: 'chacino',
  chacins: 'chacins',
  chacmas: 'chacmas',
  chacoal: 'chacoal',
  chacoli: 'chacoli',
  chacona: 'chacona',
  chacota: 'chacota',
  chacrao: 'chacrão',
  chacras: 'chacras',
  chactas: 'chactas',
  chacuru: 'chacuru',
  chaeira: 'chaeira',
  chafita: 'chafita',
  chagada: 'chagada',
  chagado: 'chagado',
  chagais: 'chagais',
  chagamo: 'chagamo',
  chagara: 'chagara',
  chagava: 'chagava',
  chagoma: 'chagoma',
  chagrem: 'chagrém',
  chagrim: 'chagrim',
  chaguei: 'chaguei',
  chaguem: 'chaguem',
  chaguer: 'chaguer',
  chagues: 'chagues',
  chaimas: 'chaimas',
  chainas: 'chainas',
  chainca: 'chainça',
  chainha: 'chainha',
  chaiola: 'chaiola',
  chaiota: 'chaiota',
  chaiote: 'chaiote',
  chairai: 'chairai',
  chairam: 'chairam',
  chairar: 'chairar',
  chairas: 'chairas',
  chairei: 'chairei',
  chairel: 'chairel',
  chairem: 'chairem',
  chaires: 'chaires',
  chairou: 'chairou',
  chalaca: 'chalaça',
  chalace: 'chalace',
  chalaco: 'chalaço',
  chalada: 'chalada',
  chalado: 'chalado',
  chalais: 'chalais',
  chalamo: 'chalamo',
  chalana: 'chalana',
  chalara: 'chalara',
  chalava: 'chalava',
  chaldra: 'chaldra',
  chaldre: 'chaldre',
  chaldro: 'chaldro',
  chaleis: 'chaleis',
  chalemo: 'chalemo',
  chalera: 'chalera',
  chaliam: 'chaliam',
  chalias: 'chaliás',
  chalico: 'chaliço',
  chalida: 'chalida',
  chalido: 'chalido',
  chalmas: 'chalmas',
  chaloes: 'chalões',
  chalota: 'chalota',
  chalrai: 'chalrai',
  chalram: 'chalram',
  chalrao: 'chalrão',
  chalrar: 'chalrar',
  chalras: 'chalras',
  chalrea: 'chalreá',
  chalrei: 'chalrei',
  chalrem: 'chalrem',
  chalres: 'chalres',
  chalrou: 'chalrou',
  chalupa: 'chalupa',
  chamada: 'chamada',
  chamado: 'chamado',
  chamais: 'chamais',
  chamale: 'chamalé',
  chamame: 'chamamé',
  chamamo: 'chamamo',
  chamara: 'chamara',
  chamato: 'chamató',
  chamava: 'chamava',
  chambao: 'chambão',
  chambas: 'chambas',
  chambil: 'chambil',
  chambis: 'chambis',
  chambos: 'chambos',
  chambre: 'chambre',
  chameai: 'chameai',
  chamear: 'chamear',
  chameei: 'chameei',
  chamega: 'chamega',
  chamego: 'chamego',
  chameia: 'chameia',
  chameie: 'chameie',
  chameio: 'chameio',
  chameis: 'chameis',
  chameja: 'chameja',
  chameje: 'chameje',
  chamejo: 'chamejo',
  chameli: 'chameli',
  chamemo: 'chamemo',
  chameou: 'chameou',
  chamica: 'chamiça',
  chamico: 'chamiço',
  chamine: 'chaminé',
  chamota: 'chamota',
  chamote: 'chamote',
  champai: 'champai',
  champam: 'champam',
  champao: 'champão',
  champar: 'champar',
  champas: 'champas',
  champei: 'champei',
  champem: 'champem',
  champes: 'champes',
  champil: 'champil',
  champis: 'champis',
  champlo: 'champló',
  champor: 'champor',
  champos: 'champôs',
  champou: 'champou',
  chamuar: 'chamuar',
  chamuca: 'chamuça',
  chamuna: 'chamuna',
  chanana: 'chanana',
  chanato: 'chanato',
  chancai: 'chancai',
  chancam: 'chancam',
  chancar: 'chancar',
  chancas: 'chanças',
  chancea: 'chanceá',
  chances: 'chances',
  chancha: 'chanchã',
  chancho: 'chancho',
  chancos: 'chancos',
  chancou: 'chancou',
  chancra: 'chancra',
  chandel: 'chandel',
  chandes: 'chandes',
  chandeu: 'chandeu',
  chaneco: 'chaneco',
  chanela: 'chanela',
  chanele: 'chanele',
  chanelo: 'chanelo',
  chaneza: 'chaneza',
  chanfas: 'chanfas',
  chanfos: 'chanfos',
  chanfra: 'chanfra',
  chanfre: 'chanfre',
  chanfro: 'chanfro',
  changai: 'changai',
  changam: 'changam',
  changar: 'changar',
  changas: 'changas',
  changou: 'changou',
  changue: 'changue',
  changui: 'changui',
  chanhas: 'chanhas',
  chanilo: 'chaniló',
  chanoiu: 'chanoiú',
  chanque: 'chanque',
  chantai: 'chantai',
  chantam: 'chantam',
  chantao: 'chantão',
  chantar: 'chantar',
  chantas: 'chantas',
  chantei: 'chantei',
  chantel: 'chantel',
  chantem: 'chantem',
  chantes: 'chantes',
  chantoa: 'chantoá',
  chantoe: 'chantoe',
  chantoo: 'chantoo',
  chantou: 'chantou',
  chantra: 'chantra',
  chantre: 'chantre',
  chanuca: 'chanuca',
  chanura: 'chanura',
  chaozao: 'chãozão',
  chapada: 'chapada',
  chapado: 'chapado',
  chapais: 'chapais',
  chapamo: 'chapamo',
  chapara: 'chapara',
  chapava: 'chapava',
  chapeai: 'chapeai',
  chapear: 'chapear',
  chapeca: 'chapeca',
  chapeei: 'chapeei',
  chapeia: 'chapeia',
  chapeie: 'chapeie',
  chapeio: 'chapeio',
  chapeis: 'chapeis',
  chapeja: 'chapejá',
  chapeje: 'chapeje',
  chapejo: 'chapejo',
  chapemo: 'chapemo',
  chapeou: 'chapeou',
  chapeus: 'chapéus',
  chapica: 'chapiçá',
  chapice: 'chapice',
  chapico: 'chapiço',
  chapina: 'chapina',
  chapine: 'chapine',
  chapino: 'chapino',
  chapins: 'chapins',
  chapoda: 'chapoda',
  chapode: 'chapode',
  chapodo: 'chapodo',
  chapoes: 'chapões',
  chapota: 'chapota',
  chapote: 'chapote',
  chapoto: 'chapoto',
  chaprao: 'chaprão',
  chapriz: 'chapriz',
  chapuca: 'chapuçá',
  chapuce: 'chapuce',
  chapuco: 'chapuço',
  chapuja: 'chapujá',
  chapuje: 'chapuje',
  chapujo: 'chapujo',
  chaputa: 'chaputa',
  chapuza: 'chapuza',
  chapuze: 'chapuze',
  chapuzo: 'chapuzo',
  chaquer: 'chaquer',
  chaqueu: 'chaquéu',
  charaba: 'charabã',
  charada: 'charada',
  charado: 'charadó',
  charais: 'charais',
  charana: 'charana',
  charapa: 'charapa',
  charcas: 'charcas',
  charcos: 'charcos',
  chardas: 'chardas',
  chardia: 'chardia',
  chardos: 'chardós',
  charela: 'charela',
  charelo: 'charelo',
  charepe: 'charepe',
  chareta: 'chareta',
  chareto: 'chareto',
  chareus: 'charéus',
  chargea: 'chargeá',
  charges: 'charges',
  charios: 'chariôs',
  charlai: 'charlai',
  charlam: 'charlam',
  charlar: 'charlar',
  charlas: 'charlas',
  charlei: 'charlei',
  charlem: 'charlem',
  charles: 'charles',
  charlos: 'charlós',
  charlou: 'charlou',
  charmes: 'charmes',
  charoai: 'charoai',
  charoam: 'charoam',
  charoar: 'charoar',
  charoas: 'charoas',
  charodo: 'charodó',
  charoei: 'charoei',
  charoem: 'charoem',
  charoes: 'charões',
  charola: 'charola',
  charole: 'charole',
  charolo: 'charolo',
  charona: 'charona',
  charoou: 'charoou',
  charpas: 'charpas',
  charque: 'charque',
  charreu: 'charréu',
  charroa: 'charroa',
  charros: 'charros',
  charrua: 'charrua',
  charrue: 'charrue',
  charruo: 'charruo',
  chartas: 'chartas',
  charter: 'charter',
  charteu: 'charteu',
  charuta: 'charuta',
  charute: 'charute',
  charuto: 'charuto',
  chascai: 'chascai',
  chascam: 'chascam',
  chascao: 'chascão',
  chascar: 'chascar',
  chascas: 'chascas',
  chascos: 'chascos',
  chascou: 'chascou',
  chaspas: 'chaspas',
  chasque: 'chasque',
  chassis: 'chassis',
  chatada: 'chatada',
  chateai: 'chateai',
  chatear: 'chatear',
  chateei: 'chateei',
  chateia: 'chateia',
  chateie: 'chateie',
  chateio: 'chateio',
  chateou: 'chateou',
  chateza: 'chateza',
  chatice: 'chatice',
  chatina: 'chatina',
  chatine: 'chatine',
  chatino: 'chatino',
  chatins: 'chatins',
  chatoes: 'chatões',
  chatria: 'chátria',
  chatura: 'chatura',
  chaudel: 'chaudel',
  chauias: 'chauias',
  chauvin: 'chauvin',
  chavada: 'chavada',
  chavado: 'chavado',
  chavala: 'chavala',
  chavalo: 'chavalo',
  chaveai: 'chaveai',
  chavear: 'chavear',
  chaveco: 'chaveco',
  chaveei: 'chaveei',
  chaveia: 'chaveia',
  chaveie: 'chaveie',
  chaveio: 'chaveio',
  chavena: 'chávena',
  chavens: 'chavens',
  chaveou: 'chaveou',
  chaveta: 'chaveta',
  chavete: 'chavete',
  chaveto: 'chaveto',
  chavina: 'chavina',
  chavoes: 'chavões',
  chavona: 'chavona',
  chavosa: 'chavosa',
  chavoso: 'chavoso',
  chazada: 'chazada',
  chazais: 'chazais',
  cheadas: 'cheadas',
  chebate: 'chebate',
  checada: 'checada',
  checado: 'checado',
  checais: 'checais',
  checamo: 'checamo',
  checape: 'checape',
  checara: 'checara',
  checava: 'checava',
  chechas: 'chechas',
  cheches: 'cheches',
  chechia: 'chechia',
  checkup: 'checkup',
  cheddar: 'cheddar',
  chedita: 'chedita',
  chedite: 'chedite',
  chedres: 'chedres',
  chefada: 'chefada',
  chefado: 'chefado',
  chefaos: 'chefãos',
  chefete: 'chefete',
  chefiai: 'chefiai',
  chefiam: 'chefiam',
  chefiar: 'chefiar',
  chefias: 'chefias',
  chefiei: 'chefiei',
  chefiem: 'chefiem',
  chefies: 'chefies',
  chefiou: 'chefiou',
  chefoes: 'chefões',
  chegada: 'chegada',
  chegado: 'chegado',
  chegais: 'chegais',
  chegamo: 'chegamo',
  chegara: 'chegará',
  chegava: 'chegava',
  cheguei: 'cheguei',
  cheguel: 'cheguel',
  cheguem: 'cheguem',
  chegues: 'chegues',
  cheides: 'cheides',
  cheiene: 'cheiene',
  cheilas: 'cheilas',
  cheinas: 'cheinas',
  cheirai: 'cheirai',
  cheiram: 'cheiram',
  cheirao: 'cheirão',
  cheirar: 'cheirar',
  cheiras: 'cheiras',
  cheirei: 'cheirei',
  cheirem: 'cheirem',
  cheires: 'cheires',
  cheiros: 'cheiros',
  cheirou: 'cheirou',
  cheirum: 'cheirum',
  cheiume: 'cheiume',
  cheiura: 'cheiura',
  cheldai: 'cheldai',
  cheldam: 'cheldam',
  cheldar: 'cheldar',
  cheldas: 'cheldas',
  cheldei: 'cheldei',
  cheldem: 'cheldem',
  cheldes: 'cheldes',
  cheldou: 'cheldou',
  cheldra: 'cheldra',
  cheleme: 'cheleme',
  chelens: 'cheléns',
  chelins: 'chelins',
  chelipa: 'chelipa',
  chelipe: 'chelipe',
  chelpas: 'chelpas',
  chelras: 'chelras',
  chelros: 'chelros',
  chemela: 'chemela',
  chemine: 'cheminé',
  chemise: 'chemise',
  chempos: 'chempos',
  chencau: 'chencau',
  chendos: 'chendos',
  chengue: 'chengue',
  chenile: 'chenile',
  chenita: 'chenita',
  chequei: 'chequei',
  chequem: 'chequem',
  cheques: 'cheques',
  cherelo: 'cherelo',
  cherica: 'cherica',
  cherico: 'cherico',
  chernas: 'chernas',
  chernes: 'chernes',
  chernir: 'chernir',
  chernos: 'chernos',
  cherrao: 'cherrão',
  chertes: 'chertes',
  cherule: 'cherulé',
  chervas: 'chervas',
  cheteni: 'cheteni',
  chetini: 'chetini',
  cheuras: 'cheúras',
  chevala: 'chevala',
  chevalo: 'chevalo',
  cheveca: 'cheveca',
  cheveco: 'cheveco',
  chevron: 'chevron',
  chevros: 'chevrós',
  cheynes: 'cheynes',
  chiadao: 'chiadão',
  chiadas: 'chiadas',
  chiador: 'chiador',
  chiados: 'chiados',
  chiamos: 'chiamos',
  chianca: 'chiança',
  chiando: 'chiando',
  chianga: 'chiangá',
  chiango: 'chiango',
  chianha: 'chianha',
  chiante: 'chiante',
  chiaram: 'chiaram',
  chiarao: 'chiarão',
  chiaras: 'chiaras',
  chiarei: 'chiarei',
  chiarem: 'chiarem',
  chiares: 'chiares',
  chiaria: 'chiaria',
  chiarmo: 'chiarmo',
  chiasco: 'chiasco',
  chiasse: 'chiasse',
  chiaste: 'chiaste',
  chiatai: 'chiatai',
  chiatam: 'chiatam',
  chiatar: 'chiatar',
  chiatas: 'chiatas',
  chiatei: 'chiatei',
  chiatem: 'chiatem',
  chiates: 'chiates',
  chiatou: 'chiatou',
  chiavam: 'chiavam',
  chiavas: 'chiavas',
  chibaba: 'chibaba',
  chibaca: 'chibaça',
  chibaco: 'chibaço',
  chibada: 'chibada',
  chibado: 'chibado',
  chibais: 'chibais',
  chibale: 'chibalé',
  chibamo: 'chibamo',
  chibara: 'chibará',
  chibata: 'chibata',
  chibate: 'chibate',
  chibato: 'chibato',
  chibava: 'chibava',
  chibcha: 'chibcha',
  chibeis: 'chibeis',
  chibemo: 'chibemo',
  chibeus: 'chibeus',
  chicaba: 'chicaba',
  chicaca: 'chicaca',
  chicada: 'chicada',
  chicado: 'chiçado',
  chicais: 'chiçais',
  chicamo: 'chiçamo',
  chicana: 'chicana',
  chicane: 'chicane',
  chicano: 'chicano',
  chicara: 'chícara',
  chicava: 'chiçava',
  chiceis: 'chiceis',
  chicemo: 'chicemo',
  chicero: 'chicero',
  chichai: 'chichai',
  chicham: 'chicham',
  chichar: 'chichar',
  chichas: 'chichas',
  chichei: 'chichei',
  chichem: 'chichem',
  chiches: 'chiches',
  chichia: 'chichia',
  chichie: 'chichie',
  chichio: 'chichio',
  chichis: 'chichis',
  chichou: 'chichou',
  chicios: 'chicios',
  chiclas: 'chiclãs',
  chicles: 'chicles',
  chicoes: 'chicões',
  chicomo: 'chicomo',
  chicopa: 'chicopa',
  chicosa: 'chicosa',
  chicoso: 'chicoso',
  chicota: 'chicota',
  chicote: 'chicote',
  chicoto: 'chicoto',
  chicuas: 'chicuas',
  chicuta: 'chicuta',
  chidova: 'chidova',
  chidura: 'chidura',
  chieira: 'chieira',
  chiemos: 'chiemos',
  chiffon: 'chiffon',
  chifons: 'chifons',
  chifrai: 'chifrai',
  chifram: 'chifram',
  chifrao: 'chifrão',
  chifrar: 'chifrar',
  chifras: 'chifras',
  chifrei: 'chifrei',
  chifrem: 'chifrem',
  chifres: 'chifres',
  chifrou: 'chifrou',
  chifuta: 'chifuta',
  chiismo: 'chiismo',
  chiista: 'chiista',
  chiitas: 'chiitas',
  chilcal: 'chilcal',
  chilcas: 'chilcas',
  chilena: 'chilena',
  chileno: 'chileno',
  chilete: 'chilete',
  chilida: 'chilida',
  chilide: 'chilide',
  chilido: 'chilido',
  chilrai: 'chilrai',
  chilram: 'chilram',
  chilrao: 'chilrão',
  chilrar: 'chilrar',
  chilras: 'chilras',
  chilrea: 'chilreá',
  chilrei: 'chilrei',
  chilrem: 'chilrem',
  chilres: 'chilres',
  chilrou: 'chilrou',
  chimana: 'chimana',
  chimano: 'chimano',
  chimbas: 'chimbas',
  chimbea: 'chimbeá',
  chimbes: 'chimbés',
  chimbeu: 'chimbéu',
  chimbia: 'chimbiá',
  chimbie: 'chimbie',
  chimbio: 'chimbio',
  chimela: 'chimela',
  chimier: 'chimíer',
  chimies: 'chimiês',
  chimine: 'chiminé',
  chimpai: 'chimpai',
  chimpam: 'chimpam',
  chimpar: 'chimpar',
  chimpas: 'chimpas',
  chimpei: 'chimpei',
  chimpem: 'chimpem',
  chimpes: 'chimpes',
  chimpou: 'chimpou',
  chinaco: 'chinaço',
  chinada: 'chinada',
  chinado: 'chinado',
  chinais: 'chinais',
  chinamo: 'chinamo',
  chinara: 'chinará',
  chinava: 'chinava',
  chincai: 'chincai',
  chincam: 'chincam',
  chincar: 'chincar',
  chincas: 'chincas',
  chincha: 'chincha',
  chinche: 'chinche',
  chincho: 'chincho',
  chincoa: 'chincoã',
  chincou: 'chincou',
  chincra: 'chincra',
  chindau: 'chindau',
  chindim: 'chindim',
  chineai: 'chineai',
  chinear: 'chinear',
  chineei: 'chineei',
  chineia: 'chineia',
  chineie: 'chineie',
  chineio: 'chineio',
  chineis: 'chineis',
  chinela: 'chinela',
  chinele: 'chinele',
  chinelo: 'chinelo',
  chinemo: 'chinemo',
  chineou: 'chineou',
  chinesa: 'chinesa',
  chineta: 'chineta',
  chinfra: 'chinfra',
  chinfre: 'chinfre',
  chinfro: 'chinfro',
  chingar: 'chingar',
  chingla: 'chingla',
  chingos: 'chingos',
  chinica: 'chinica',
  chinico: 'chinico',
  chinita: 'chinita',
  chinite: 'chinite',
  chiniza: 'chinizá',
  chinize: 'chinize',
  chinizo: 'chinizo',
  chinoca: 'chinoca',
  chinque: 'chinque',
  chinras: 'chinras',
  chintel: 'chintel',
  chintes: 'chintes',
  chiobas: 'chiobas',
  chiolas: 'chiolas',
  chiotas: 'chiotas',
  chiotes: 'chiotes',
  chipada: 'chipada',
  chipado: 'chipado',
  chipaia: 'chipaia',
  chipala: 'chipala',
  chipuar: 'chipuar',
  chiquel: 'chiquel',
  chiques: 'chiques',
  chirata: 'chirata',
  chircal: 'chircal',
  chircas: 'chircas',
  chirelo: 'chirelo',
  chireta: 'chireta',
  chirias: 'chirias',
  chiriba: 'chiriba',
  chirila: 'chirila',
  chirina: 'chirina',
  chiripa: 'chiripá',
  chirria: 'chirria',
  chirrie: 'chirrie',
  chirrio: 'chirrio',
  chiscai: 'chiscai',
  chiscam: 'chiscam',
  chiscar: 'chiscar',
  chiscas: 'chiscas',
  chiscos: 'chiscos',
  chiscou: 'chiscou',
  chismes: 'chismes',
  chisnai: 'chisnai',
  chisnam: 'chisnam',
  chisnar: 'chisnar',
  chisnas: 'chisnas',
  chisnei: 'chisnei',
  chisnem: 'chisnem',
  chisnes: 'chisnes',
  chisnou: 'chisnou',
  chispai: 'chispai',
  chispam: 'chispam',
  chispar: 'chispar',
  chispas: 'chispas',
  chispea: 'chispeá',
  chispei: 'chispei',
  chispem: 'chispem',
  chispes: 'chispes',
  chispou: 'chispou',
  chisque: 'chisque',
  chistes: 'chistes',
  chitada: 'chitada',
  chitado: 'chitado',
  chitata: 'chitata',
  chitaus: 'chitaus',
  chitela: 'chitela',
  chitina: 'chitina',
  chitins: 'chitins',
  chitoes: 'chitões',
  chitons: 'chitons',
  chitoto: 'chitoto',
  chizias: 'chizias',
  choapta: 'choapta',
  chobias: 'chobias',
  chobili: 'chobili',
  chocada: 'chocada',
  chocado: 'chocado',
  chocais: 'chocais',
  chocamo: 'chocamo',
  chocara: 'chocara',
  chocava: 'chocava',
  chochai: 'chochai',
  chocham: 'chocham',
  chochar: 'chochar',
  chochas: 'chochas',
  chochei: 'chochei',
  chochem: 'chochem',
  choches: 'choches',
  chochim: 'chochim',
  chochos: 'chochos',
  chochou: 'chochou',
  chocoes: 'chocões',
  chocosa: 'chocosa',
  chocoso: 'chocoso',
  chocrao: 'chocrão',
  chocues: 'chócues',
  chofera: 'chofera',
  chofere: 'chofere',
  chofero: 'chofero',
  chofrai: 'chofrai',
  chofram: 'chofram',
  chofrao: 'chofrão',
  chofrar: 'chofrar',
  chofras: 'chofras',
  chofrei: 'chofrei',
  chofrem: 'chofrem',
  chofres: 'chofres',
  chofrou: 'chofrou',
  choinai: 'choinai',
  choinam: 'choinam',
  choinar: 'choinar',
  choinas: 'choinas',
  choinei: 'choinei',
  choinem: 'choinem',
  choines: 'choines',
  choinou: 'choinou',
  choiras: 'choiras',
  choitai: 'choitai',
  choitam: 'choitam',
  choitar: 'choitar',
  choitas: 'choitas',
  choitei: 'choitei',
  choitem: 'choitem',
  choites: 'choites',
  choitos: 'choitos',
  choitou: 'choitou',
  cholaga: 'cholaga',
  choldra: 'choldra',
  cholope: 'cholope',
  chomage: 'chômage',
  chonada: 'chonada',
  chonado: 'chonado',
  chonais: 'chonais',
  chonamo: 'chonamo',
  chonara: 'chonará',
  chonava: 'chonava',
  choneis: 'choneis',
  chonemo: 'chonemo',
  chonoes: 'chonões',
  chonona: 'chonona',
  chontas: 'chontas',
  chopada: 'chopada',
  chopins: 'chopins',
  choquei: 'choquei',
  choquel: 'choquel',
  choquem: 'choquem',
  choques: 'choques',
  chorada: 'chorada',
  chorado: 'chorado',
  chorais: 'chorais',
  choramo: 'choramo',
  chorara: 'chorara',
  chorava: 'chorava',
  chorcos: 'chorcos',
  choreca: 'choreça',
  chorece: 'chorece',
  choreci: 'choreci',
  choreco: 'choreço',
  choreis: 'choreis',
  choremo: 'choremo',
  chorfas: 'chorfas',
  chorica: 'chorica',
  chorina: 'chorina',
  chorlos: 'chorlos',
  chornes: 'chornes',
  choroes: 'chorões',
  chorona: 'chorona',
  chororo: 'chororó',
  chorosa: 'chorosa',
  choroso: 'choroso',
  chorote: 'chorote',
  chorrai: 'chorrai',
  chorram: 'chorram',
  chorrar: 'chorrar',
  chorras: 'chorras',
  chorrei: 'chorrei',
  chorrem: 'chorrem',
  chorres: 'chorres',
  chorros: 'chorros',
  chorrou: 'chorrou',
  choruda: 'choruda',
  chorudo: 'chorudo',
  chorume: 'chorume',
  choruto: 'choruto',
  chostra: 'chostra',
  chotada: 'chotada',
  chotado: 'chotado',
  chotais: 'chotais',
  chotamo: 'chotamo',
  chotara: 'chotara',
  chotava: 'chotava',
  choteis: 'choteis',
  chotemo: 'chotemo',
  chotoes: 'chotões',
  choucas: 'choucas',
  choucha: 'choucha',
  choupai: 'choupai',
  choupal: 'choupal',
  choupam: 'choupam',
  choupar: 'choupar',
  choupas: 'choupas',
  choupei: 'choupei',
  choupem: 'choupem',
  choupes: 'choupes',
  choupos: 'choupos',
  choupou: 'choupou',
  chouras: 'chouras',
  chourem: 'chourém',
  chouris: 'chouris',
  chousai: 'chousai',
  chousal: 'chousal',
  chousam: 'chousam',
  chousar: 'chousar',
  chousas: 'chousas',
  chousei: 'chousei',
  chousem: 'chousem',
  chouses: 'chouses',
  chousos: 'chousos',
  chousou: 'chousou',
  choussa: 'choussa',
  chousse: 'chousse',
  chousso: 'chousso',
  choutai: 'choutai',
  choutam: 'choutam',
  choutao: 'choutão',
  choutar: 'choutar',
  choutas: 'choutas',
  choutea: 'chouteá',
  choutei: 'choutei',
  choutem: 'choutem',
  choutes: 'choutes',
  choutia: 'choutiá',
  choutou: 'choutou',
  chovais: 'chovais',
  chovamo: 'chovamo',
  choveis: 'choveis',
  chovemo: 'chovemo',
  chovera: 'chovera',
  choviam: 'choviam',
  chovias: 'chovias',
  chovida: 'chovida',
  chovido: 'chovido',
  chovolo: 'chovolo',
  christi: 'christi',
  chuabas: 'chuabas',
  chuabos: 'chuabos',
  chuanga: 'chuanga',
  chubeto: 'chubeto',
  chucada: 'chuçada',
  chucado: 'chuçado',
  chucais: 'chuçais',
  chucamo: 'chuçamo',
  chucara: 'chuçara',
  chucava: 'chuçava',
  chuceis: 'chuceis',
  chucemo: 'chucemo',
  chuchai: 'chuchai',
  chucham: 'chucham',
  chuchar: 'chuchar',
  chuchas: 'chuchas',
  chuchei: 'chuchei',
  chuchem: 'chuchem',
  chuches: 'chuches',
  chuchou: 'chuchou',
  chuchua: 'chuchua',
  chuchue: 'chuchue',
  chuchuo: 'chuchuo',
  chuchus: 'chuchus',
  chucras: 'chucras',
  chucros: 'chucros',
  chuetai: 'chuetai',
  chuetam: 'chuetam',
  chuetar: 'chuetar',
  chuetas: 'chuetas',
  chuetei: 'chuetei',
  chuetem: 'chuetem',
  chuetes: 'chuetes',
  chuetou: 'chuetou',
  chufada: 'chufada',
  chufado: 'chufado',
  chufais: 'chufais',
  chufamo: 'chufamo',
  chufara: 'chufará',
  chufava: 'chufava',
  chufeai: 'chufeai',
  chufear: 'chufear',
  chufeei: 'chufeei',
  chufeia: 'chufeia',
  chufeie: 'chufeie',
  chufeio: 'chufeio',
  chufeis: 'chufeis',
  chufemo: 'chufemo',
  chufeou: 'chufeou',
  chuinga: 'chuinga',
  chuinho: 'chuinho',
  chulada: 'chulada',
  chulape: 'chulapé',
  chulata: 'chulata',
  chulcas: 'chulcas',
  chuleai: 'chuleai',
  chulear: 'chulear',
  chuleco: 'chuleco',
  chuleei: 'chuleei',
  chuleia: 'chuleia',
  chuleie: 'chuleie',
  chuleio: 'chuleio',
  chuleou: 'chuleou',
  chuleta: 'chuleta',
  chulice: 'chulice',
  chulipa: 'chulipa',
  chulpos: 'chulpos',
  chumaca: 'chumaça',
  chumace: 'chumace',
  chumaco: 'chumaço',
  chumbai: 'chumbai',
  chumbam: 'chumbam',
  chumbar: 'chumbar',
  chumbas: 'chumbas',
  chumbea: 'chumbeá',
  chumbei: 'chumbei',
  chumbem: 'chumbem',
  chumbeo: 'chúmbeo',
  chumbes: 'chumbes',
  chumbim: 'chumbim',
  chumbos: 'chumbos',
  chumbou: 'chumbou',
  chumbum: 'chumbum',
  chumeai: 'chumeai',
  chumear: 'chumear',
  chumeas: 'chúmeas',
  chumeco: 'chumeco',
  chumeei: 'chumeei',
  chumeia: 'chumeia',
  chumeie: 'chumeie',
  chumeio: 'chumeio',
  chumela: 'chumela',
  chumeou: 'chumeou',
  chumico: 'chumiço',
  chumpim: 'chumpim',
  chuname: 'chuname',
  chuncho: 'chuncho',
  chungas: 'chungas',
  chupada: 'chupada',
  chupado: 'chupado',
  chupais: 'chupais',
  chupamo: 'chupamo',
  chupara: 'chupara',
  chupava: 'chupava',
  chupeis: 'chupeis',
  chupemo: 'chupemo',
  chupeta: 'chupeta',
  chupins: 'chupins',
  chupita: 'chupita',
  chupite: 'chupite',
  chupito: 'chupito',
  chupoes: 'chupões',
  churdai: 'churdai',
  churdam: 'churdam',
  churdar: 'churdar',
  churdas: 'churdas',
  churdei: 'churdei',
  churdem: 'churdem',
  churdes: 'churdes',
  churdos: 'churdos',
  churdou: 'churdou',
  chureta: 'chureta',
  churina: 'churiná',
  churine: 'churine',
  churino: 'churino',
  churmas: 'churmas',
  churras: 'churras',
  churreu: 'churreu',
  churria: 'churria',
  churrie: 'churrie',
  churrio: 'churrio',
  churros: 'churros',
  churtas: 'churtas',
  chusmai: 'chusmai',
  chusmam: 'chusmam',
  chusmar: 'chusmar',
  chusmas: 'chusmas',
  chusmei: 'chusmei',
  chusmem: 'chusmem',
  chusmes: 'chusmes',
  chusmou: 'chusmou',
  chusura: 'chusura',
  chutada: 'chutada',
  chutado: 'chutado',
  chutais: 'chutais',
  chutamo: 'chutamo',
  chutara: 'chutara',
  chutava: 'chutava',
  chuteis: 'chuteis',
  chutemo: 'chutemo',
  chutney: 'chutney',
  chuvada: 'chuvada',
  chuvins: 'chuvins',
  chuvoes: 'chuvões',
  chuvosa: 'chuvosa',
  chuvoso: 'chuvoso',
  ciadoes: 'ciadões',
  ciadona: 'ciadona',
  ciadora: 'ciadora',
  ciafila: 'ciáfila',
  ciafilo: 'ciáfilo',
  ciameia: 'ciameia',
  cianada: 'cianada',
  cianado: 'cianado',
  cianato: 'cianato',
  cianeas: 'ciâneas',
  cianela: 'cianela',
  cianeos: 'ciâneos',
  cianeta: 'cianetá',
  cianete: 'cianete',
  cianeto: 'cianeto',
  cianica: 'ciânica',
  cianico: 'ciânico',
  cianido: 'cianido',
  cianina: 'cianina',
  cianita: 'cianita',
  cianite: 'cianite',
  cianito: 'cianito',
  cianiza: 'cianizá',
  cianize: 'cianize',
  cianizo: 'cianizo',
  cianois: 'cianóis',
  cianosa: 'cianosa',
  cianose: 'cianose',
  cianoso: 'cianoso',
  ciantes: 'ciantes',
  cianura: 'cianura',
  cianure: 'cianure',
  cianuro: 'cianuro',
  ciaozao: 'ciãozão',
  ciardes: 'ciardes',
  ciareis: 'ciáreis',
  ciaremo: 'ciaremo',
  ciariam: 'ciariam',
  ciarias: 'ciarias',
  ciarmos: 'ciarmos',
  ciassem: 'ciassem',
  ciasses: 'ciasses',
  ciastes: 'ciastes',
  ciateas: 'ciáteas',
  ciatica: 'ciática',
  ciatico: 'ciático',
  ciatios: 'ciátios',
  ciatron: 'ciatron',
  ciatula: 'ciátula',
  ciaveis: 'ciáveis',
  ciavoga: 'ciavoga',
  ciazona: 'ciãzona',
  cibacao: 'cibação',
  cibadao: 'cibadão',
  cibadas: 'cibadas',
  cibador: 'cibador',
  cibados: 'cibados',
  cibalho: 'cibalho',
  cibalio: 'cibálio',
  cibalos: 'cíbalos',
  cibamos: 'cibamos',
  cibanas: 'cibanas',
  cibanco: 'cibanco',
  cibando: 'cibando',
  cibante: 'cibante',
  cibaque: 'cibaque',
  cibaram: 'cibaram',
  cibarao: 'cibarão',
  cibaras: 'cibarás',
  cibarco: 'cibarco',
  cibarei: 'cibarei',
  cibarem: 'cibarem',
  cibares: 'cibares',
  cibaria: 'cibaria',
  cibario: 'cibário',
  cibarmo: 'cibarmo',
  cibasse: 'cibasse',
  cibaste: 'cibaste',
  cibatos: 'cibatos',
  cibavam: 'cibavam',
  cibavas: 'cibavas',
  cibdelo: 'cibdelo',
  cibeias: 'cibeias',
  cibemos: 'cibemos',
  cibicas: 'cibicas',
  cibicos: 'cibicos',
  cibinho: 'cibinho',
  cibocio: 'cibócio',
  cibonel: 'cibonel',
  ciboria: 'cibória',
  ciborio: 'cibório',
  cicadal: 'cicadal',
  cicadas: 'cicadas',
  cicadea: 'cicádea',
  cicadeo: 'cicádeo',
  cicatas: 'cicatas',
  cicates: 'cicates',
  cicerai: 'cicerai',
  ciceram: 'ciceram',
  cicerar: 'cicerar',
  ciceras: 'ciceras',
  cicerei: 'cicerei',
  cicerem: 'cicerem',
  ciceres: 'ciceres',
  cicerou: 'cicerou',
  ciciada: 'ciciada',
  ciciado: 'ciciado',
  ciciais: 'ciciais',
  ciciamo: 'ciciamo',
  ciciara: 'ciciará',
  ciciava: 'ciciava',
  cicicas: 'cicicas',
  cicieis: 'cicieis',
  ciciemo: 'ciciemo',
  cicinio: 'cicínio',
  ciciosa: 'ciciosa',
  cicioso: 'cicioso',
  ciclade: 'cíclade',
  ciclame: 'cíclame',
  ciclana: 'ciclana',
  ciclano: 'ciclano',
  ciclena: 'ciclena',
  cicleno: 'cicleno',
  cicleta: 'cicleta',
  ciclica: 'cíclica',
  ciclico: 'cíclico',
  ciclina: 'ciclina',
  ciclite: 'ciclite',
  cicliza: 'cicliza',
  ciclize: 'ciclize',
  ciclizo: 'ciclizo',
  ciclone: 'ciclone',
  ciclope: 'ciclope',
  ciclosa: 'ciclosa',
  ciclose: 'ciclose',
  cicocas: 'cicocas',
  ciconia: 'cicônia',
  cicoria: 'cicória',
  cicorio: 'cicório',
  cicutas: 'cicutas',
  cicutio: 'cicútio',
  cicutis: 'cicútis',
  cidadao: 'cidadão',
  cidadas: 'cidadãs',
  cidades: 'cidades',
  cidadoa: 'cidadoa',
  cidagas: 'cidagãs',
  cidaras: 'cidaras',
  cidaria: 'cidaria',
  cidinha: 'cidinha',
  cidipes: 'cidipes',
  cidmeno: 'cidmeno',
  cidoiro: 'cidoiro',
  cidonia: 'cidônia',
  cidoros: 'cidoros',
  cidoses: 'cidoses',
  cidouro: 'cidouro',
  cidrada: 'cidrada',
  cidrais: 'cidrais',
  cidroes: 'cidrões',
  ciecies: 'cieciés',
  cieiras: 'cieiras',
  cieiros: 'cieiros',
  ciencia: 'ciência',
  cienida: 'ciênida',
  cientes: 'cientes',
  cifadao: 'cifadão',
  cifadas: 'cifadas',
  cifador: 'cifador',
  cifados: 'cifados',
  cifamos: 'cifamos',
  cifando: 'cifando',
  cifanta: 'cifanta',
  cifante: 'cifante',
  cifaram: 'cifaram',
  cifarao: 'cifarão',
  cifaras: 'cifaras',
  cifarei: 'cifarei',
  cifarem: 'cifarem',
  cifares: 'cifares',
  cifaria: 'cifaria',
  cifarmo: 'cifarmo',
  cifasse: 'cifasse',
  cifaste: 'cifaste',
  cifatos: 'cifatos',
  cifavam: 'cifavam',
  cifavas: 'cifavas',
  cifelas: 'cifelas',
  cifelio: 'cifélio',
  cifemos: 'cifemos',
  cifidia: 'cifídia',
  cifonio: 'cifônio',
  cifoses: 'cifoses',
  cifrada: 'cifrada',
  cifrado: 'cifrado',
  cifrais: 'cifrais',
  ciframo: 'ciframo',
  cifrara: 'cifrara',
  cifrava: 'cifrava',
  cifreis: 'cifreis',
  cifremo: 'cifremo',
  cifroes: 'cifrões',
  cigalho: 'cigalho',
  ciganai: 'ciganai',
  ciganam: 'ciganam',
  ciganar: 'ciganar',
  ciganas: 'ciganas',
  ciganea: 'ciganeá',
  ciganei: 'ciganei',
  ciganem: 'ciganem',
  ciganes: 'ciganes',
  ciganos: 'ciganos',
  ciganou: 'ciganou',
  cigarra: 'cigarra',
  cigarre: 'cigarre',
  cigarro: 'cigarro',
  cigerao: 'cigerão',
  cigudes: 'cigudes',
  cijulas: 'cijulas',
  cilaceu: 'cilaceu',
  ciladas: 'ciladas',
  ciladea: 'ciladeá',
  cilaina: 'cilaína',
  cilaros: 'cílaros',
  cileias: 'cileias',
  cileneu: 'cileneu',
  cilenia: 'cilênia',
  cilenos: 'cilenos',
  cilhada: 'cilhada',
  cilhado: 'cilhado',
  cilhais: 'cilhais',
  cilhamo: 'cilhamo',
  cilhara: 'cilhará',
  cilhava: 'cilhava',
  cilheis: 'cilheis',
  cilhemo: 'cilhemo',
  cilhoes: 'cilhões',
  ciliada: 'ciliada',
  ciliado: 'ciliado',
  cilices: 'cílices',
  cilicia: 'cilícia',
  cilicie: 'cilicie',
  cilicio: 'cilício',
  cilicna: 'cilicna',
  cilicno: 'cilicno',
  cilidea: 'cilídea',
  cilideo: 'cilídeo',
  cilidio: 'cilídio',
  ciliida: 'cilíida',
  ciliode: 'ciliode',
  ciliolo: 'cilíolo',
  cilista: 'cilista',
  cilitas: 'cilitas',
  cilitos: 'cilitos',
  ciloses: 'ciloses',
  cimaceo: 'cimáceo',
  cimacio: 'cimácio',
  cimadas: 'cimadas',
  cimados: 'cimados',
  cimalha: 'cimalha',
  cimarol: 'cimarol',
  cimarra: 'cimarra',
  cimbalo: 'címbalo',
  cimbeba: 'cimbeba',
  cimbela: 'cimbela',
  cimbire: 'cimbire',
  cimboas: 'cimboas',
  cimbrai: 'cimbrai',
  cimbram: 'cimbram',
  cimbrar: 'cimbrar',
  cimbras: 'cimbras',
  cimbrei: 'cimbrei',
  cimbrem: 'cimbrem',
  cimbres: 'cimbres',
  cimbrio: 'címbrio',
  cimbrou: 'cimbrou',
  cimeira: 'cimeira',
  cimeiro: 'cimeiro',
  cimelio: 'cimélio',
  cimenio: 'cimênio',
  cimenol: 'cimenol',
  cimenos: 'cimenos',
  cimenta: 'cimenta',
  cimente: 'cimente',
  cimento: 'cimento',
  cimerio: 'cimério',
  ciminde: 'ciminde',
  ciminea: 'cimínea',
  cimineo: 'cimíneo',
  cimitas: 'cimitas',
  cimoide: 'cimoide',
  cimolia: 'cimólia',
  cimolio: 'cimólio',
  cimosas: 'cimosas',
  cimosos: 'cimosos',
  cimotoa: 'cimótoa',
  cimpaio: 'cimpaio',
  cinabre: 'cinabre',
  cinamol: 'cinamol',
  cinanco: 'cinanco',
  cinarea: 'cinárea',
  cinareo: 'cináreo',
  cinasco: 'cinasco',
  cincada: 'cincada',
  cincado: 'cincado',
  cincais: 'cincais',
  cincamo: 'cincamo',
  cincara: 'cincará',
  cincava: 'cincava',
  cinceno: 'cinceno',
  cinchai: 'cinchai',
  cincham: 'cincham',
  cinchao: 'cinchão',
  cinchar: 'cinchar',
  cinchas: 'cinchas',
  cinchei: 'cinchei',
  cinchem: 'cinchem',
  cinches: 'cinches',
  cinchol: 'cinchol',
  cinchou: 'cinchou',
  cincino: 'cincino',
  cinclos: 'cinclos',
  cincoes: 'cincões',
  cindais: 'cindais',
  cindamo: 'cindamo',
  cindemo: 'cindemo',
  cindiam: 'cindiam',
  cindias: 'cindias',
  cindida: 'cindida',
  cindido: 'cindido',
  cindila: 'cindila',
  cindimo: 'cindimo',
  cindira: 'cindira',
  cindros: 'cindros',
  cindura: 'cindura',
  cineces: 'cineces',
  cinecio: 'cinécio',
  cinedos: 'cinedos',
  cineias: 'cineias',
  cinemao: 'cinemão',
  cinemas: 'cinemas',
  cinerai: 'cinerai',
  cineral: 'cineral',
  cineram: 'cineram',
  cinerar: 'cinerar',
  cineras: 'cineras',
  cinerea: 'cinérea',
  cinerei: 'cinerei',
  cinerem: 'cinerem',
  cinereo: 'cinéreo',
  cineres: 'cineres',
  cinerou: 'cinerou',
  cinesia: 'cinesia',
  cinetos: 'cinetos',
  cingala: 'cingalá',
  cingeis: 'cingeis',
  cingiam: 'cingiam',
  cingias: 'cingias',
  cingida: 'cingida',
  cingido: 'cingido',
  cingimo: 'cingimo',
  cingira: 'cingira',
  cingula: 'cingula',
  cingule: 'cingule',
  cingulo: 'cíngulo',
  cinicas: 'cínicas',
  cinicos: 'cínicos',
  cinipes: 'cínipes',
  ciniras: 'ciniras',
  cinisga: 'cinisga',
  cinismo: 'cinismo',
  cinista: 'cinista',
  cinjais: 'cinjais',
  cinjamo: 'cinjamo',
  cinopse: 'cinopse',
  cinquei: 'cinquei',
  cinquem: 'cinquem',
  cinques: 'cinques',
  cintada: 'cintada',
  cintado: 'cintado',
  cintais: 'cintais',
  cintamo: 'cintamo',
  cintara: 'cintara',
  cintava: 'cintava',
  cinteis: 'cinteis',
  cintemo: 'cintemo',
  cintila: 'cintila',
  cintile: 'cintile',
  cintilo: 'cintilo',
  cintrao: 'cintrão',
  cintura: 'cintura',
  cinture: 'cinture',
  cinturo: 'cinturo',
  cinzada: 'cinzada',
  cinzado: 'cinzado',
  cinzais: 'cinzais',
  cinzamo: 'cinzamo',
  cinzara: 'cinzará',
  cinzava: 'cinzava',
  cinzeas: 'cínzeas',
  cinzeis: 'cinzéis',
  cinzela: 'cinzela',
  cinzele: 'cinzele',
  cinzelo: 'cinzelo',
  cinzemo: 'cinzemo',
  cinzeos: 'cínzeos',
  cioadas: 'cioadas',
  cioados: 'cioados',
  ciochos: 'ciochos',
  ciocoto: 'ciocoto',
  ciofila: 'ciófila',
  ciofilo: 'ciófilo',
  ciofita: 'ciófita',
  ciofito: 'ciófito',
  cionelo: 'cionelo',
  cionida: 'ciônida',
  cionite: 'cionite',
  ciopino: 'ciopino',
  ciotica: 'ciótica',
  ciotico: 'ciótico',
  ciotomo: 'ciótomo',
  cipaios: 'cipaios',
  cipelas: 'cipelas',
  ciperal: 'ciperal',
  ciperos: 'ciperos',
  cipista: 'cipista',
  cipoaba: 'cipoaba',
  cipoada: 'cipoada',
  cipoado: 'cipoado',
  cipoais: 'cipoais',
  cipoama: 'cipoama',
  cipoamo: 'cipoamo',
  cipoara: 'cipoará',
  cipoava: 'cipoava',
  cipoeis: 'cipoeis',
  cipoemo: 'cipoemo',
  cipoira: 'cipoíra',
  ciprias: 'cíprias',
  ciprica: 'cíprica',
  ciprico: 'cíprico',
  ciprina: 'ciprina',
  ciprino: 'ciprino',
  ciprios: 'cíprios',
  cipsela: 'cípsela',
  ciranda: 'ciranda',
  cirande: 'cirande',
  cirando: 'cirando',
  ciratas: 'ciratas',
  circada: 'circada',
  circeia: 'circeia',
  circeio: 'circeio',
  circeus: 'circeus',
  circiai: 'circiai',
  circiam: 'circiam',
  circiar: 'circiar',
  circias: 'circias',
  circiei: 'circiei',
  circiem: 'circiem',
  circies: 'circies',
  circina: 'circina',
  circine: 'circine',
  circino: 'circino',
  circios: 'círcios',
  circiou: 'circiou',
  circula: 'circula',
  circule: 'circule',
  circulo: 'círculo',
  cireneu: 'cireneu',
  ciriais: 'ciriais',
  cirrito: 'cirrito',
  cirrosa: 'cirrosa',
  cirrose: 'cirrose',
  cirroso: 'cirroso',
  ciruela: 'ciruela',
  cirzais: 'cirzais',
  cirzeta: 'cirzeta',
  cisadao: 'cisadão',
  cisadas: 'cisadas',
  cisador: 'cisador',
  cisados: 'cisados',
  cisalha: 'cisalha',
  cisalhe: 'cisalhe',
  cisalho: 'cisalho',
  cisamos: 'cisamos',
  cisando: 'cisando',
  cisante: 'cisante',
  cisaram: 'cisaram',
  cisarao: 'cisarão',
  cisaras: 'cisarás',
  cisarei: 'cisarei',
  cisarem: 'cisarem',
  cisares: 'cisares',
  cisaria: 'cisaria',
  cisarmo: 'cisarmo',
  cisasse: 'cisasse',
  cisaste: 'cisaste',
  cisavam: 'cisavam',
  cisavas: 'cisavas',
  ciscada: 'ciscada',
  ciscado: 'ciscado',
  ciscais: 'ciscais',
  ciscamo: 'ciscamo',
  ciscara: 'ciscara',
  ciscava: 'ciscava',
  ciscosa: 'ciscosa',
  ciscoso: 'ciscoso',
  ciselai: 'ciselai',
  ciselam: 'ciselam',
  ciselar: 'ciselar',
  ciselas: 'ciselas',
  ciselei: 'ciselei',
  ciselem: 'ciselem',
  ciseles: 'ciseles',
  ciselou: 'ciselou',
  cisemos: 'cisemos',
  cisgola: 'cisgola',
  cisidea: 'cisídea',
  cisideo: 'cisídeo',
  cisirao: 'cisirão',
  cismada: 'cismada',
  cismado: 'cismado',
  cismais: 'cismais',
  cismamo: 'cismamo',
  cismara: 'cismara',
  cismava: 'cismava',
  cismeis: 'cismeis',
  cismemo: 'cismemo',
  cisoiro: 'cisoiro',
  cisonas: 'cisonas',
  cisoria: 'cisória',
  cisorio: 'cisório',
  cisouro: 'cisouro',
  cispada: 'cispada',
  cispado: 'cispado',
  cispais: 'cispais',
  cispamo: 'cispamo',
  cispara: 'cispara',
  cispava: 'cispava',
  cispeis: 'cispeis',
  cispemo: 'cispemo',
  cisquei: 'cisquei',
  cisquem: 'cisquem',
  cisques: 'cisques',
  cissoes: 'cissões',
  cissona: 'cissona',
  cissura: 'cissura',
  cistase: 'cistase',
  cisteas: 'císteas',
  cistela: 'cistela',
  cistelo: 'cistelo',
  cistica: 'cística',
  cistico: 'cístico',
  cistina: 'cistina',
  cistite: 'cistite',
  cistoma: 'cistoma',
  cistosa: 'cistosa',
  cistoso: 'cistoso',
  cistres: 'cistres',
  cistron: 'cístron',
  cistros: 'cistros',
  cistuda: 'cistuda',
  cistudo: 'cistudo',
  cistula: 'cístula',
  cisuras: 'cisuras',
  citacao: 'citação',
  citadao: 'citadão',
  citadas: 'citadas',
  citador: 'citador',
  citados: 'citados',
  citalos: 'citalos',
  citamos: 'citamos',
  citando: 'citando',
  citania: 'citânia',
  citante: 'citante',
  citanto: 'citanto',
  citaram: 'citaram',
  citarao: 'citarão',
  citaras: 'citaras',
  citarei: 'citarei',
  citarem: 'citarem',
  citares: 'citares',
  citaria: 'citaria',
  citarmo: 'citarmo',
  citarra: 'citarra',
  citases: 'citases',
  citasse: 'citasse',
  citaste: 'citaste',
  citavam: 'citavam',
  citavas: 'citavas',
  citavel: 'citável',
  citeias: 'citeias',
  citemia: 'citemia',
  citemos: 'citemos',
  citeras: 'citeras',
  citerea: 'citérea',
  citereo: 'citéreo',
  citereu: 'citereu',
  citeros: 'citeros',
  citicas: 'cíticas',
  citicos: 'cíticos',
  citilas: 'citilas',
  citilos: 'citilos',
  citimas: 'citimas',
  citinas: 'citinas',
  citinea: 'citínea',
  citissa: 'citissa',
  citodes: 'citodes',
  citodio: 'citódio',
  citoide: 'citoide',
  citolai: 'citolai',
  citolam: 'citolam',
  citolao: 'citolão',
  citolar: 'citolar',
  citolas: 'cítolas',
  citolei: 'citolei',
  citolem: 'citolem',
  citoles: 'citoles',
  citolou: 'citolou',
  citomas: 'citomas',
  citonio: 'citônio',
  citopse: 'citopse',
  citoses: 'citoses',
  citosol: 'citosol',
  citotes: 'citotes',
  citrais: 'citrais',
  citrato: 'citrato',
  citreas: 'cítreas',
  citreos: 'cítreos',
  citrica: 'cítrica',
  citrico: 'cítrico',
  citrilo: 'citrilo',
  citrina: 'citrina',
  citrino: 'citrino',
  ciumada: 'ciumada',
  ciumado: 'ciumado',
  ciumais: 'ciumais',
  ciumamo: 'ciumamo',
  ciumara: 'ciumara',
  ciumata: 'ciumata',
  ciumava: 'ciumava',
  ciumeis: 'ciumeis',
  ciumemo: 'ciumemo',
  ciumosa: 'ciumosa',
  ciumoso: 'ciumoso',
  ciuravo: 'ciuravo',
  ciurida: 'ciúrida',
  civetas: 'civetas',
  civetos: 'civetos',
  civicas: 'cívicas',
  civicos: 'cívicos',
  civismo: 'civismo',
  civista: 'civista',
  civitas: 'civitas',
  cizanai: 'cizanai',
  cizanam: 'cizanam',
  cizanar: 'cizanar',
  cizanas: 'cizanas',
  cizanei: 'cizanei',
  cizanem: 'cizanem',
  cizanes: 'cizanes',
  cizania: 'cizânia',
  cizanie: 'cizanie',
  cizanio: 'cizanio',
  cizanou: 'cizanou',
  cizelai: 'cizelai',
  cizelam: 'cizelam',
  cizelar: 'cizelar',
  cizelas: 'cizelas',
  cizelei: 'cizelei',
  cizelem: 'cizelem',
  cizeles: 'cizeles',
  cizelou: 'cizelou',
  cizirao: 'cizirão',
  claclas: 'claclás',
  clacson: 'clácson',
  cladeai: 'cladeai',
  cladear: 'cladear',
  cladeei: 'cladeei',
  cladeia: 'cladeia',
  cladeie: 'cladeie',
  cladeio: 'cladeio',
  cladeou: 'cladeou',
  cladios: 'cládios',
  cladode: 'cladode',
  cladosa: 'cladosa',
  cladoso: 'cladoso',
  claimos: 'claimos',
  clamada: 'clamada',
  clamado: 'clamado',
  clamais: 'clamais',
  clamamo: 'clamamo',
  clamara: 'clamara',
  clamava: 'clamava',
  clameis: 'clameis',
  clamemo: 'clamemo',
  clamide: 'clâmide',
  clampea: 'clampeá',
  clampes: 'clampes',
  clandos: 'clandôs',
  clangor: 'clangor',
  clanica: 'clânica',
  clanico: 'clânico',
  claques: 'claques',
  claraim: 'claraim',
  clareai: 'clareai',
  clarear: 'clarear',
  clareei: 'clareei',
  clareia: 'clareia',
  clareie: 'clareie',
  clareio: 'clareio',
  clareja: 'clareja',
  clareje: 'clareje',
  clarejo: 'clarejo',
  clareou: 'clareou',
  clareta: 'clareta',
  clarete: 'clarete',
  clareza: 'clareza',
  clarias: 'clarias',
  clarina: 'clarina',
  clarine: 'clarine',
  clarino: 'clarino',
  clarins: 'clarins',
  clarita: 'clarita',
  clarito: 'clarito',
  clarkia: 'clarkia',
  claroes: 'clarões',
  clarone: 'clarone',
  clasias: 'clasias',
  clasmas: 'clasmas',
  classar: 'classar',
  classes: 'classes',
  classia: 'clássia',
  clastos: 'clastos',
  clastra: 'clastra',
  clatros: 'clatros',
  clausal: 'clausal',
  clausio: 'cláusio',
  clausos: 'clausos',
  clavada: 'clavada',
  clavado: 'clavado',
  clavais: 'clavais',
  clavamo: 'clavamo',
  clavara: 'clavará',
  clavava: 'clavava',
  claveis: 'claveis',
  clavemo: 'clavemo',
  clavena: 'clávena',
  clavida: 'clávida',
  clavija: 'clavija',
  clavina: 'clavina',
  clavula: 'clávula',
  cleares: 'cleares',
  cleftas: 'cleftas',
  cleidos: 'cleidos',
  clelias: 'clélias',
  clenios: 'clênios',
  cleomes: 'cleomes',
  cleonia: 'cleônia',
  cleonos: 'cleonos',
  clerida: 'clérida',
  cleriga: 'clériga',
  clerigo: 'clérigo',
  cleroes: 'clerões',
  cleruco: 'cleruco',
  cletras: 'cletras',
  cleveas: 'cléveas',
  clianto: 'clianto',
  clicada: 'clicada',
  clicado: 'clicado',
  clicais: 'clicais',
  clicamo: 'clicamo',
  clicara: 'clicará',
  clicava: 'clicava',
  cliches: 'clichês',
  clidite: 'clidite',
  clienta: 'clienta',
  cliente: 'cliente',
  climace: 'clímace',
  climena: 'climena',
  cliname: 'cliname',
  clinica: 'clínica',
  clinico: 'clínico',
  clinton: 'clinton',
  clinuda: 'clinuda',
  clinudo: 'clinudo',
  clionas: 'clionas',
  clipada: 'clipada',
  clipado: 'clipado',
  clipais: 'clipais',
  clipamo: 'clipamo',
  clipara: 'clipará',
  clipart: 'clipart',
  clipava: 'clipava',
  clipeal: 'clipeal',
  clipeis: 'clipeis',
  clipemo: 'clipemo',
  clipper: 'clipper',
  cliquei: 'cliquei',
  cliquem: 'cliquem',
  cliques: 'cliques',
  clisada: 'clisada',
  clisado: 'clisado',
  clisais: 'clisais',
  clisamo: 'clisamo',
  clisara: 'clisará',
  clisava: 'clisava',
  cliscos: 'cliscos',
  cliseis: 'cliseis',
  clisemo: 'clisemo',
  clismas: 'clismas',
  clismea: 'clismea',
  clismeo: 'clismeo',
  clismos: 'clismos',
  clissor: 'clissor',
  clister: 'clister',
  clitelo: 'clitelo',
  clitica: 'clítica',
  clitico: 'clítico',
  clitras: 'clitras',
  clivada: 'clivada',
  clivado: 'clivado',
  clivais: 'clivais',
  clivamo: 'clivamo',
  clivara: 'clivará',
  clivava: 'clivava',
  cliveis: 'cliveis',
  clivemo: 'clivemo',
  clivina: 'clivina',
  clivosa: 'clivosa',
  clivoso: 'clivoso',
  cloacae: 'cloacae',
  cloacal: 'cloacal',
  cloacas: 'cloacas',
  cloanta: 'cloanta',
  cloante: 'cloante',
  cloanto: 'cloanto',
  cloasma: 'cloasma',
  clocada: 'clocada',
  clocado: 'clocado',
  clocais: 'clocais',
  clocamo: 'clocamo',
  clocara: 'clocará',
  clocava: 'clocava',
  clocoto: 'clocotó',
  clompao: 'clompão',
  clonada: 'clonada',
  clonado: 'clonado',
  clonais: 'clonais',
  clonamo: 'clonamo',
  clonara: 'clonará',
  clonava: 'clonava',
  cloneis: 'cloneis',
  clonemo: 'clonemo',
  clonica: 'clônica',
  clonico: 'clônico',
  cloquei: 'cloquei',
  cloquem: 'cloquem',
  cloques: 'cloques',
  clorada: 'clorada',
  clorado: 'clorado',
  clorais: 'clorais',
  cloramo: 'cloramo',
  clorara: 'clorará',
  clorato: 'clorato',
  clorava: 'clorava',
  cloreia: 'cloreia',
  cloreis: 'cloreis',
  clorela: 'clorela',
  cloremo: 'cloremo',
  clorete: 'clorete',
  cloreto: 'cloreto',
  clorias: 'clórias',
  clorica: 'clórica',
  clorico: 'clórico',
  clorida: 'clórida',
  cloride: 'clóride',
  clorido: 'clorido',
  clorino: 'clorino',
  clorion: 'clórion',
  clorios: 'clórios',
  clorita: 'clorita',
  clorite: 'clorite',
  clorito: 'clorito',
  cloriza: 'clorizá',
  clorize: 'clorize',
  clorizo: 'clorizo',
  cloroma: 'cloroma',
  clorope: 'clorope',
  clorosa: 'clorosa',
  clorose: 'clorose',
  cloroso: 'cloroso',
  cloruro: 'cloruro',
  clotila: 'clotila',
  clotura: 'clotura',
  cloveno: 'cloveno',
  cluanto: 'cluanto',
  clubber: 'clubber',
  clubeco: 'clubeco',
  clundos: 'clundos',
  clunios: 'clúnios',
  clupeas: 'clúpeas',
  clupeia: 'clupeia',
  clupeio: 'clupeio',
  clupeos: 'clúpeos',
  clusias: 'clúsias',
  clusino: 'clusino',
  cluster: 'cluster',
  clxviii: 'clxviii',
  clxxiii: 'clxxiii',
  clxxvii: 'clxxvii',
  clxxxii: 'clxxxii',
  clxxxiv: 'clxxxiv',
  clxxxix: 'clxxxix',
  clxxxvi: 'clxxxvi',
  cmlviii: 'cmlviii',
  cmlxiii: 'cmlxiii',
  cmlxvii: 'cmlxvii',
  cmlxxii: 'cmlxxii',
  cmlxxiv: 'cmlxxiv',
  cmlxxix: 'cmlxxix',
  cmlxxvi: 'cmlxxvi',
  cmlxxxi: 'cmlxxxi',
  cmlxxxv: 'cmlxxxv',
  cmxciii: 'cmxciii',
  cmxcvii: 'cmxcvii',
  cmxliii: 'cmxliii',
  cmxlvii: 'cmxlvii',
  cmxviii: 'cmxviii',
  cmxxiii: 'cmxxiii',
  cmxxvii: 'cmxxvii',
  cmxxxii: 'cmxxxii',
  cmxxxiv: 'cmxxxiv',
  cmxxxix: 'cmxxxix',
  cmxxxvi: 'cmxxxvi',
  cnemida: 'cnêmida',
  cnemide: 'cnêmide',
  cnidose: 'cnidose',
  coabita: 'coabita',
  coabite: 'coabite',
  coabito: 'coabito',
  coacoes: 'coações',
  coacona: 'coaçona',
  coactai: 'coactai',
  coactam: 'coactam',
  coactar: 'coactar',
  coactas: 'coactas',
  coactei: 'coactei',
  coactem: 'coactem',
  coactes: 'coactes',
  coactil: 'coáctil',
  coactos: 'coactos',
  coactou: 'coactou',
  coadata: 'coadatá',
  coadate: 'coadate',
  coadato: 'coadato',
  coadela: 'coadela',
  coadoes: 'coadões',
  coadona: 'coadona',
  coadora: 'coadora',
  coaduna: 'coaduna',
  coadune: 'coadune',
  coaduno: 'coaduno',
  coadura: 'coadura',
  coageis: 'coagéis',
  coagiam: 'coagiam',
  coagias: 'coagias',
  coagida: 'coagida',
  coagido: 'coagido',
  coagimo: 'coagimo',
  coagira: 'coagira',
  coagita: 'coagita',
  coagite: 'coagite',
  coagito: 'coagito',
  coagula: 'coagula',
  coagule: 'coagule',
  coagulo: 'coagulo',
  coaiata: 'coaiatá',
  coajais: 'coajais',
  coajamo: 'coajamo',
  coajuba: 'coajuba',
  coalhai: 'coalhai',
  coalham: 'coalham',
  coalhar: 'coalhar',
  coalhas: 'coalhas',
  coalhei: 'coalhei',
  coalhem: 'coalhem',
  coalhes: 'coalhes',
  coalhos: 'coalhos',
  coalhou: 'coalhou',
  coaliza: 'coaliza',
  coalize: 'coalize',
  coalizo: 'coalizo',
  coaltar: 'coaltar',
  coaluna: 'coaluna',
  coaluno: 'coaluno',
  coandus: 'coandus',
  coanhai: 'coanhai',
  coanham: 'coanham',
  coanhar: 'coanhar',
  coanhas: 'coanhas',
  coanhei: 'coanhei',
  coanhem: 'coanhem',
  coanhes: 'coanhes',
  coanhos: 'coanhos',
  coanhou: 'coanhou',
  coantes: 'coantes',
  coapias: 'coápias',
  coaptai: 'coaptai',
  coaptam: 'coaptam',
  coaptar: 'coaptar',
  coaptas: 'coaptas',
  coaptei: 'coaptei',
  coaptem: 'coaptem',
  coaptes: 'coaptes',
  coaptor: 'coaptor',
  coaptou: 'coaptou',
  coarcta: 'coarcta',
  coarcte: 'coarcte',
  coarcto: 'coarcto',
  coardes: 'coardes',
  coareis: 'coareis',
  coaremo: 'coaremo',
  coariam: 'coariam',
  coarias: 'coarias',
  coarmos: 'coarmos',
  coartai: 'coartai',
  coartam: 'coartam',
  coartar: 'coartar',
  coartas: 'coartas',
  coartei: 'coartei',
  coartem: 'coartem',
  coartes: 'coartes',
  coartou: 'coartou',
  coassem: 'coassem',
  coasses: 'coasses',
  coastes: 'coastes',
  coatada: 'coatada',
  coatado: 'coatado',
  coatais: 'coatais',
  coatamo: 'coatamo',
  coatara: 'coatara',
  coatava: 'coatava',
  coateis: 'coateis',
  coatemo: 'coatemo',
  coativa: 'coativa',
  coativo: 'coativo',
  coatora: 'coatora',
  coatras: 'coatras',
  coautor: 'coautor',
  coaveis: 'coáveis',
  coaxada: 'coaxada',
  coaxado: 'coaxado',
  coaxais: 'coaxais',
  coaxamo: 'coaxamo',
  coaxara: 'coaxara',
  coaxava: 'coaxava',
  coaxeis: 'coaxeis',
  coaxemo: 'coaxemo',
  coaxial: 'coaxial',
  coaxixa: 'coaxixá',
  cobaias: 'cobaias',
  cobaios: 'cobaios',
  cobalos: 'cobalos',
  cobalta: 'cobalta',
  cobalte: 'cobalte',
  cobalto: 'cobalto',
  cobarde: 'cobarde',
  cobeias: 'cobeias',
  cobelas: 'cobelas',
  coberta: 'coberta',
  coberto: 'coberto',
  cobeuas: 'cobéuas',
  cobicai: 'cobiçai',
  cobicam: 'cobiçam',
  cobicar: 'cobiçar',
  cobicas: 'cobiças',
  cobicei: 'cobicei',
  cobicem: 'cobicem',
  cobices: 'cobices',
  cobicou: 'cobiçou',
  cobioes: 'cobiões',
  cobiose: 'cobiose',
  cobiris: 'cóbiris',
  cobitis: 'cobítis',
  cobocos: 'cobocós',
  cobogos: 'cobogós',
  cobrada: 'cobrada',
  cobrado: 'cobrado',
  cobrais: 'cobrais',
  cobramo: 'cobramo',
  cobrara: 'cobrara',
  cobrava: 'cobrava',
  cobreai: 'cobreai',
  cobrear: 'cobrear',
  cobreei: 'cobreei',
  cobrega: 'cóbrega',
  cobreia: 'cobreia',
  cobreie: 'cobreie',
  cobreio: 'cobreio',
  cobreis: 'cobreis',
  cobreja: 'cobreja',
  cobreje: 'cobreje',
  cobrejo: 'cobrejo',
  cobrelo: 'cobrelo',
  cobremo: 'cobremo',
  cobreou: 'cobreou',
  cobriam: 'cobriam',
  cobrias: 'cobrias',
  cobrida: 'cobrida',
  cobrido: 'cobrido',
  cobrimo: 'cobrimo',
  cobrira: 'cobrira',
  cobroes: 'cobrões',
  coburgo: 'coburgo',
  cocacea: 'cocácea',
  cocaceo: 'cocáceo',
  cocadao: 'cocadão',
  cocadas: 'cocadas',
  cocador: 'coçador',
  cocados: 'coçados',
  cocaina: 'cocaína',
  cocamas: 'cocamas',
  cocamos: 'coçamos',
  cocando: 'coçando',
  cocanha: 'cocanha',
  cocante: 'cocante',
  cocaram: 'coçaram',
  cocarao: 'coçarão',
  cocaras: 'cócaras',
  cocarda: 'cocarda',
  cocarei: 'coçarei',
  cocarem: 'coçarem',
  cocares: 'coçares',
  cocaria: 'cocaria',
  cocarmo: 'coçarmo',
  cocasse: 'coçasse',
  cocaste: 'coçaste',
  cocavam: 'coçavam',
  cocavas: 'coçavas',
  coccida: 'cóccida',
  coccige: 'coccige',
  coccina: 'coccina',
  coccoes: 'cocções',
  coccona: 'cocçona',
  cocedra: 'cócedra',
  cocegai: 'cocegai',
  cocegam: 'cocegam',
  cocegar: 'cocegar',
  cocegas: 'cócegas',
  cocegou: 'cocegou',
  cocegue: 'cocegue',
  coceira: 'coceira',
  cocemos: 'cocemos',
  cochada: 'cochada',
  cochado: 'cochado',
  cochais: 'cochais',
  cochamo: 'cochamo',
  cochara: 'cochará',
  cochava: 'cochava',
  cocheis: 'cocheis',
  cochela: 'cochela',
  cochemo: 'cochemo',
  cochila: 'cochila',
  cochile: 'cochile',
  cochilo: 'cochilo',
  cochina: 'cochina',
  cochine: 'cochine',
  cochino: 'cochino',
  cociclo: 'cociclo',
  cockpit: 'cockpit',
  coclear: 'coclear',
  cocleas: 'cócleas',
  cocoeio: 'cocoeio',
  cocoide: 'cocoide',
  cocolas: 'cocolas',
  cocolos: 'cocolos',
  coconao: 'coconão',
  coconas: 'coconas',
  coconda: 'coconda',
  cocones: 'cocones',
  cocorai: 'cocorai',
  cocoram: 'cocoram',
  cocorar: 'cocorar',
  cocoras: 'cócoras',
  cocorei: 'cocorei',
  cocorem: 'cocorem',
  cocores: 'cocores',
  cocoria: 'cocoria',
  cocorie: 'cocorie',
  cocorio: 'cocório',
  cocorou: 'cocorou',
  cocotas: 'cocotas',
  cocotes: 'cocotes',
  cocozao: 'cocôzão',
  cocozus: 'cocozus',
  cocriai: 'cocriai',
  cocriam: 'cocriam',
  cocriar: 'cocriar',
  cocrias: 'cocrias',
  cocriei: 'cocriei',
  cocriem: 'cocriem',
  cocries: 'cocries',
  cocriou: 'cocriou',
  cocuana: 'cocuana',
  coculai: 'coculai',
  coculam: 'coculam',
  cocular: 'cocular',
  coculas: 'coculas',
  coculei: 'coculei',
  coculem: 'coculem',
  cocules: 'cocules',
  coculos: 'coculos',
  coculou: 'coculou',
  cocumbi: 'cocumbi',
  codagem: 'codagem',
  codagos: 'códagos',
  codanas: 'códanas',
  codanos: 'códanos',
  codaque: 'codaque',
  codaste: 'codaste',
  codeada: 'codeada',
  codeado: 'codeado',
  codeais: 'codeais',
  codeara: 'codeara',
  codeava: 'codeava',
  codecal: 'codeçal',
  codeeis: 'codeeis',
  codegas: 'códegas',
  codeiam: 'codeiam',
  codeias: 'codeias',
  codeiem: 'codeiem',
  codeies: 'codeies',
  codeina: 'codeína',
  codejai: 'codejai',
  codejam: 'codejam',
  codejar: 'codejar',
  codejas: 'codejas',
  codejei: 'codejei',
  codejem: 'codejem',
  codejes: 'codejes',
  codejos: 'codejos',
  codejou: 'codejou',
  codemas: 'codemas',
  codeoes: 'codeões',
  codeosa: 'codeosa',
  codeoso: 'codeoso',
  codesso: 'codesso',
  codetas: 'codetas',
  codetem: 'codetém',
  codeter: 'codeter',
  codeuda: 'codeúda',
  codeudo: 'codeúdo',
  codevam: 'codevam',
  codevas: 'codevas',
  codevei: 'codevei',
  codevem: 'codevem',
  codever: 'codever',
  codeves: 'codeves',
  codeveu: 'codeveu',
  codevia: 'codevia',
  codices: 'códices',
  codieus: 'codieus',
  codigos: 'códigos',
  codilha: 'codilha',
  codilhe: 'codilhe',
  codilho: 'codilho',
  codinha: 'codinha',
  codinhe: 'codinhe',
  codinho: 'codinho',
  codiolo: 'codíolo',
  coditos: 'coditos',
  codizai: 'codizai',
  codizam: 'codizam',
  codizar: 'codizar',
  codizas: 'codizas',
  codizei: 'codizei',
  codizem: 'codizem',
  codizes: 'codizes',
  codizou: 'codizou',
  codoiro: 'codoiro',
  codoria: 'codória',
  codorio: 'codório',
  codorna: 'codorna',
  codorno: 'codorno',
  coedita: 'coedita',
  coedite: 'coedite',
  coedito: 'coedito',
  coeduca: 'coeduca',
  coeduco: 'coeduco',
  coefora: 'coefora',
  coeforo: 'coéforo',
  coelege: 'coelege',
  coelegi: 'coelegi',
  coeleja: 'coeleja',
  coelejo: 'coelejo',
  coelhal: 'coelhal',
  coelhas: 'coelhas',
  coelhos: 'coelhos',
  coelvas: 'coelvas',
  coendos: 'coendos',
  coendro: 'coendro',
  coendus: 'coendus',
  coenhas: 'coenhas',
  coenito: 'coenito',
  coentro: 'coentro',
  coerais: 'coerais',
  coeramo: 'coeramo',
  coerana: 'coerana',
  coercao: 'coerção',
  coerdai: 'coerdai',
  coerdam: 'coerdam',
  coerdar: 'coerdar',
  coerdas: 'coerdas',
  coerdei: 'coerdei',
  coerdem: 'coerdem',
  coerdes: 'coerdes',
  coerdou: 'coerdou',
  coeriam: 'coeriam',
  coerias: 'coerias',
  coerida: 'coerida',
  coerido: 'coerido',
  coerimo: 'coerimo',
  coerira: 'coerirá',
  coeroes: 'coerões',
  coeruna: 'coeruna',
  coesita: 'coesita',
  coesiva: 'coesiva',
  coesivo: 'coesivo',
  coesoes: 'coesões',
  coesona: 'coesona',
  coessos: 'coessos',
  coevana: 'coevana',
  cofator: 'cofator',
  cofemia: 'cofemia',
  coferda: 'cóferdã',
  cofiada: 'cofiada',
  cofiado: 'cofiado',
  cofiais: 'cofiais',
  cofiamo: 'cofiamo',
  cofiara: 'cofiará',
  cofiava: 'cofiava',
  cofieis: 'cofieis',
  cofiemo: 'cofiemo',
  cofinal: 'cofinal',
  cofinha: 'cofinha',
  cofinhe: 'cofinhe',
  cofinho: 'cofinho',
  cofinos: 'cofinos',
  cofoses: 'cofoses',
  cogedor: 'cogedor',
  cogelai: 'cogelai',
  cogelam: 'cogelam',
  cogelar: 'cogelar',
  cogelas: 'cogelas',
  cogelei: 'cogelei',
  cogelem: 'cogelem',
  cogeles: 'cogeles',
  cogelou: 'cogelou',
  cogemos: 'cogemos',
  cogendo: 'cogendo',
  cogente: 'cogente',
  cogeram: 'cogeram',
  cogerao: 'cogerão',
  cogeras: 'cogerás',
  cogerei: 'cogerei',
  cogerem: 'cogerem',
  cogeres: 'cogeres',
  cogeria: 'cogeria',
  cogerir: 'cogerir',
  cogeris: 'cogeris',
  cogeriu: 'cogeriu',
  cogermo: 'cogermo',
  cogesse: 'cogesse',
  cogeste: 'cogeste',
  cogiada: 'cogiada',
  cogiado: 'cogiado',
  cogiais: 'cogiais',
  cogiamo: 'cogiamo',
  cogiara: 'cogiará',
  cogiava: 'cogiava',
  cogidao: 'cogidão',
  cogidas: 'cogidas',
  cogidos: 'cogidos',
  cogieis: 'cogieis',
  cogiemo: 'cogiemo',
  cogiram: 'cogiram',
  cogiras: 'cogiras',
  cogitai: 'cogitai',
  cogitam: 'cogitam',
  cogitar: 'cogitar',
  cogitas: 'cogitas',
  cogitei: 'cogitei',
  cogitem: 'cogitem',
  cogites: 'cogites',
  cogitou: 'cogitou',
  cognada: 'cognada',
  cognado: 'cognado',
  cognata: 'cognata',
  cognato: 'cognato',
  cognita: 'cógnita',
  cognito: 'cógnito',
  cognome: 'cognome',
  cogoilo: 'cogoilo',
  cogotes: 'cogotes',
  cogulai: 'cogulai',
  cogulam: 'cogulam',
  cogular: 'cogular',
  cogulas: 'cogulas',
  cogulei: 'cogulei',
  cogulem: 'cogulem',
  cogules: 'cogules',
  cogulho: 'cogulho',
  cogulou: 'cogulou',
  coibais: 'coibais',
  coibamo: 'coibamo',
  coibiam: 'coibiam',
  coibias: 'coibias',
  coibida: 'coibida',
  coibido: 'coibido',
  coibimo: 'coibimo',
  coibira: 'coibira',
  coiceai: 'coiceai',
  coicear: 'coicear',
  coiceei: 'coiceei',
  coiceia: 'coiceia',
  coiceie: 'coiceie',
  coiceio: 'coiceio',
  coiceou: 'coiceou',
  coicoas: 'coicoas',
  coicoes: 'coições',
  coicona: 'coiçona',
  coifada: 'coifada',
  coifado: 'coifado',
  coifais: 'coifais',
  coifamo: 'coifamo',
  coifara: 'coifará',
  coifava: 'coifava',
  coifeis: 'coifeis',
  coifemo: 'coifemo',
  coigual: 'coigual',
  coiloes: 'coilões',
  coimada: 'coimada',
  coimado: 'coimado',
  coimais: 'coimais',
  coimamo: 'coimamo',
  coimara: 'coimará',
  coimava: 'coimava',
  coimeis: 'coimeis',
  coimemo: 'coimemo',
  coinada: 'coinada',
  coinado: 'coinado',
  coinais: 'coinais',
  coinamo: 'coinamo',
  coinara: 'coinará',
  coinava: 'coinava',
  coincha: 'coincha',
  coinche: 'coinche',
  coincho: 'coincho',
  coineis: 'coineis',
  coinemo: 'coinemo',
  coioiar: 'coioiar',
  coioice: 'coioíce',
  coiotes: 'coiotes',
  coipilo: 'coipilo',
  coiraca: 'coiraça',
  coirace: 'coirace',
  coiraco: 'coiraço',
  coirada: 'coirada',
  coirais: 'coirais',
  coirama: 'coirama',
  coirana: 'coirana',
  coirans: 'coirans',
  coirata: 'coirata',
  coirato: 'coirato',
  coireai: 'coireai',
  coirear: 'coirear',
  coireei: 'coireei',
  coireia: 'coireia',
  coireie: 'coireie',
  coireio: 'coireio',
  coirela: 'coirela',
  coireou: 'coireou',
  coirmao: 'coirmão',
  coirmas: 'coirmãs',
  coiroes: 'coirões',
  coirona: 'coirona',
  coiruda: 'coiruda',
  coirudo: 'coirudo',
  coisada: 'coisada',
  coisado: 'coisado',
  coisais: 'coisais',
  coisamo: 'coisamo',
  coisara: 'coisara',
  coisava: 'coisava',
  coiseis: 'coiseis',
  coisemo: 'coisemo',
  coisica: 'coisica',
  coisico: 'coisico',
  coisita: 'coisita',
  coitada: 'coitada',
  coitado: 'coitado',
  coitais: 'coitais',
  coitamo: 'coitamo',
  coitara: 'coitara',
  coitava: 'coitava',
  coiteis: 'coiteis',
  coitelo: 'coitelo',
  coitemo: 'coitemo',
  coitiva: 'coitiva',
  coitivo: 'coitivo',
  coivara: 'coivara',
  coivare: 'coivare',
  coivaro: 'coivaro',
  coivoes: 'coivões',
  cojamos: 'cojamos',
  colabas: 'colabás',
  colacao: 'colação',
  colacas: 'colaças',
  colacia: 'colacia',
  colacos: 'colaços',
  coladao: 'coladão',
  coladas: 'coladas',
  colador: 'colador',
  colados: 'colados',
  colagem: 'colagem',
  colaita: 'colaíta',
  colaite: 'colaíte',
  colaito: 'colaíto',
  colamas: 'colamas',
  colamos: 'colamos',
  colanca: 'colança',
  colando: 'colando',
  colante: 'colante',
  colapsa: 'colapsa',
  colapse: 'colapse',
  colapso: 'colapso',
  colaram: 'colaram',
  colarao: 'colarão',
  colaras: 'colaras',
  colarei: 'colarei',
  colarem: 'colarem',
  colares: 'colares',
  colaria: 'colaria',
  colario: 'colário',
  colarmo: 'colarmo',
  colarno: 'colarno',
  colasse: 'colasse',
  colaste: 'colaste',
  colatas: 'colatas',
  colator: 'colator',
  colatos: 'colatos',
  colavam: 'colavam',
  colavas: 'colavas',
  colchao: 'colchão',
  colchas: 'colchas',
  colchoa: 'colchoa',
  colchoe: 'colchoe',
  colchoo: 'colchoo',
  coldres: 'coldres',
  coleada: 'coleada',
  coleado: 'coleado',
  coleais: 'coleais',
  coleara: 'coleará',
  coleato: 'coleato',
  coleava: 'coleava',
  colebra: 'cólebra',
  colecao: 'coleção',
  colecia: 'colécia',
  colecta: 'colecta',
  colecte: 'colecte',
  colecto: 'colecto',
  coleeis: 'coleeis',
  colegai: 'colegai',
  colegam: 'colegam',
  colegar: 'colegar',
  colegas: 'colegas',
  colegia: 'colegiá',
  colegio: 'colégio',
  colegou: 'colegou',
  colegue: 'colegue',
  coleiam: 'coleiam',
  coleias: 'coleias',
  coleiem: 'coleiem',
  coleies: 'coleies',
  coleina: 'coleína',
  coleira: 'coleira',
  coleiro: 'coleiro',
  coleita: 'coleita',
  coleite: 'coleíte',
  colejas: 'colejas',
  colemas: 'colemas',
  colemia: 'colemia',
  colemos: 'colemos',
  colenda: 'colenda',
  colendo: 'colendo',
  colenis: 'colênis',
  colepos: 'colepos',
  coleras: 'cóleras',
  coletai: 'coletai',
  coletam: 'coletam',
  coletar: 'coletar',
  coletas: 'coletas',
  coletei: 'coletei',
  coletem: 'coletem',
  coletes: 'coletes',
  coletia: 'colétia',
  coletor: 'coletor',
  coletou: 'coletou',
  colevas: 'colevas',
  colexas: 'colexas',
  colgada: 'colgada',
  colgado: 'colgado',
  colgais: 'colgais',
  colgara: 'colgará',
  colgava: 'colgava',
  colguei: 'colguei',
  colguem: 'colguem',
  colgues: 'colgues',
  colhada: 'colhada',
  colhado: 'colhado',
  colhais: 'colhais',
  colhamo: 'colhamo',
  colheis: 'colheis',
  colhemo: 'colhemo',
  colhera: 'colhera',
  colhiam: 'colhiam',
  colhias: 'colhias',
  colhida: 'colhida',
  colhido: 'colhido',
  colhoal: 'colhoal',
  colhoes: 'colhões',
  colhuda: 'colhuda',
  colhudo: 'colhudo',
  colibia: 'colíbia',
  colibri: 'colibri',
  colicas: 'cólicas',
  colicos: 'cólicos',
  colidam: 'colidam',
  colidas: 'colidas',
  colidem: 'colidem',
  colider: 'colíder',
  colides: 'colides',
  colidia: 'colidia',
  colidio: 'colídio',
  colidir: 'colidir',
  colidis: 'colidis',
  colidiu: 'colidiu',
  colifio: 'colífio',
  coligai: 'coligai',
  coligam: 'coligam',
  coligar: 'coligar',
  coligas: 'coligas',
  coligem: 'coligem',
  coliges: 'coliges',
  coligia: 'coligia',
  coligir: 'coligir',
  coligis: 'coligis',
  coligiu: 'coligiu',
  coligou: 'coligou',
  coligue: 'coligue',
  colijam: 'colijam',
  colijas: 'colijas',
  colimai: 'colimai',
  colimam: 'colimam',
  colimar: 'colimar',
  colimas: 'colimas',
  colimbo: 'colimbo',
  colimei: 'colimei',
  colimem: 'colimem',
  colimes: 'colimes',
  colimou: 'colimou',
  colinas: 'colinas',
  colinha: 'colinha',
  colinho: 'colinho',
  colinos: 'colinos',
  colipeu: 'colipeu',
  coliqua: 'coliquá',
  colique: 'colique',
  coliquo: 'colíquo',
  colires: 'colires',
  colirio: 'colírio',
  colisao: 'colisão',
  coliseu: 'coliseu',
  colisor: 'colisor',
  colitas: 'colitas',
  colites: 'colites',
  coliuro: 'coliúro',
  collant: 'collant',
  colmaca: 'colmaça',
  colmace: 'colmace',
  colmaco: 'colmaço',
  colmada: 'colmada',
  colmado: 'colmado',
  colmais: 'colmais',
  colmamo: 'colmamo',
  colmara: 'colmara',
  colmata: 'colmata',
  colmate: 'colmate',
  colmato: 'colmato',
  colmava: 'colmava',
  colmeai: 'colmeai',
  colmeal: 'colmeal',
  colmear: 'colmear',
  colmeei: 'colmeei',
  colmeia: 'colméia',
  colmeie: 'colmeie',
  colmeio: 'colmeio',
  colmeis: 'colmeis',
  colmemo: 'colmemo',
  colmeou: 'colmeou',
  colniza: 'colniza',
  colobio: 'colóbio',
  colobos: 'colobos',
  colocai: 'colocai',
  colocam: 'colocam',
  colocar: 'colocar',
  colocas: 'colocas',
  colocou: 'colocou',
  colodio: 'colódio',
  colodra: 'colodra',
  colodro: 'colodro',
  colofao: 'colofão',
  colofio: 'colófio',
  colofon: 'cólofon',
  coloide: 'coloide',
  cololos: 'cololos',
  colomas: 'colomas',
  colomba: 'colomba',
  colombo: 'colombo',
  colomia: 'colômia',
  colomim: 'colomim',
  colomis: 'colomis',
  colonas: 'colonas',
  coloneu: 'coloneu',
  colongo: 'colongo',
  colonho: 'colonho',
  colonia: 'colônia',
  colonos: 'colonos',
  coloque: 'coloque',
  colorai: 'colorai',
  coloram: 'coloram',
  colorar: 'colorar',
  coloras: 'coloras',
  colorau: 'colorau',
  colorea: 'coloreá',
  colorei: 'colorei',
  colorem: 'colorem',
  colores: 'colores',
  coloria: 'coloria',
  colorir: 'colorir',
  coloris: 'coloris',
  coloriu: 'coloriu',
  colorou: 'colorou',
  colosso: 'colosso',
  colotes: 'colotes',
  colpada: 'colpada',
  colpado: 'colpado',
  colpite: 'colpite',
  colpoda: 'cólpoda',
  colpode: 'cólpode',
  coltada: 'coltada',
  coltado: 'coltado',
  coltais: 'coltais',
  coltamo: 'coltamo',
  coltara: 'coltará',
  coltava: 'coltava',
  colteis: 'colteis',
  coltemo: 'coltemo',
  coludas: 'coludas',
  coludir: 'coludir',
  coludos: 'coludos',
  colugli: 'colugli',
  colugos: 'colugos',
  coluiai: 'coluiai',
  coluiam: 'coluiam',
  coluiar: 'coluiar',
  coluias: 'coluias',
  coluiei: 'coluiei',
  coluiem: 'coluiem',
  coluies: 'coluies',
  coluiou: 'coluiou',
  colulos: 'cólulos',
  columbo: 'columbo',
  columim: 'columim',
  columis: 'columis',
  columna: 'columna',
  colunai: 'colunai',
  colunal: 'colunal',
  colunam: 'colunam',
  colunar: 'colunar',
  colunas: 'colunas',
  colunei: 'colunei',
  colunem: 'colunem',
  colunes: 'colunes',
  colunou: 'colunou',
  coluria: 'coluria',
  colurno: 'colurno',
  coluros: 'coluros',
  colusao: 'colusão',
  colutea: 'colútea',
  coluvio: 'colúvio',
  comacio: 'comácio',
  comadas: 'comadas',
  comados: 'comados',
  comadre: 'comadre',
  comagma: 'comagma',
  comamos: 'comamos',
  comanas: 'comanas',
  comanda: 'comanda',
  comande: 'comande',
  comando: 'comando',
  comanis: 'comanis',
  comanos: 'comanos',
  comante: 'comante',
  comarca: 'comarca',
  comarco: 'comarco',
  comarim: 'comarim',
  comaris: 'comaris',
  comaros: 'cômaros',
  comarus: 'comarus',
  comasta: 'comasta',
  comatas: 'comatas',
  comatis: 'comatis',
  comatos: 'comatos',
  comazos: 'comazos',
  combaca: 'combaca',
  combala: 'combala',
  combale: 'combale',
  combali: 'combali',
  combalo: 'combalo',
  combana: 'combana',
  combane: 'combane',
  combani: 'combani',
  combano: 'combano',
  combaro: 'combaro',
  combata: 'combata',
  combate: 'combate',
  combati: 'combati',
  combato: 'combato',
  combina: 'combina',
  combine: 'combine',
  combino: 'combino',
  combios: 'combiós',
  comboca: 'comboca',
  combogo: 'combogó',
  comboia: 'comboia',
  comboie: 'comboie',
  comboio: 'comboio',
  combois: 'combóis',
  combona: 'combona',
  combrao: 'combrão',
  combros: 'combros',
  combuas: 'combuas',
  combuca: 'combuca',
  combuco: 'combuco',
  combura: 'combura',
  combure: 'combure',
  comburi: 'comburi',
  comburo: 'comburo',
  comecai: 'começai',
  comecam: 'começam',
  comecao: 'começão',
  comecar: 'começar',
  comecas: 'começas',
  comecei: 'comecei',
  comecem: 'comecem',
  comeces: 'comeces',
  comecos: 'começos',
  comecou: 'começou',
  comedam: 'comedam',
  comedao: 'comedão',
  comedas: 'comedas',
  comedem: 'comedem',
  comedes: 'comedes',
  comedia: 'comédia',
  comedie: 'comedie',
  comedio: 'comedio',
  comedir: 'comedir',
  comedis: 'comedis',
  comediu: 'comediu',
  comedon: 'comedon',
  comedor: 'comedor',
  comedos: 'cômedos',
  comeios: 'comeios',
  comemos: 'comemos',
  comenda: 'comenda',
  comende: 'comende',
  comendo: 'comendo',
  comenos: 'comenos',
  comenta: 'comenta',
  comente: 'comente',
  comento: 'comento',
  comeram: 'comeram',
  comerao: 'comerão',
  comeras: 'comeras',
  comerei: 'comerei',
  comerem: 'comerem',
  comeres: 'comeres',
  comeria: 'comeria',
  comermo: 'comermo',
  comesse: 'comesse',
  comeste: 'comeste',
  comesto: 'comesto',
  cometam: 'cometam',
  cometar: 'cometar',
  cometas: 'cometas',
  cometea: 'cometeá',
  cometei: 'cometei',
  cometem: 'cometem',
  cometer: 'cometer',
  cometes: 'cometes',
  cometeu: 'cometeu',
  cometia: 'cometia',
  comicas: 'cômicas',
  comicha: 'comicha',
  comiche: 'comiche',
  comicho: 'comicho',
  comicio: 'comício',
  comicos: 'cômicos',
  comidao: 'comidão',
  comidas: 'comidas',
  comidos: 'comidos',
  comieis: 'comíeis',
  comilai: 'comilai',
  comilam: 'comilam',
  comilao: 'comilão',
  comilar: 'comilar',
  comilas: 'comilas',
  comilei: 'comilei',
  comilem: 'comilem',
  comiles: 'comiles',
  comilou: 'comilou',
  cominai: 'cominai',
  cominam: 'cominam',
  cominar: 'cominar',
  cominas: 'cominas',
  cominei: 'cominei',
  cominem: 'cominem',
  comines: 'comines',
  cominge: 'cominge',
  cominho: 'cominho',
  cominou: 'cominou',
  cominua: 'cominua',
  cominui: 'cominuí',
  cominuo: 'cominuo',
  comirai: 'comirai',
  comiram: 'comiram',
  comirar: 'comirar',
  comiras: 'comiras',
  comirei: 'comirei',
  comirem: 'comirem',
  comires: 'comires',
  comirou: 'comirou',
  comisca: 'comisca',
  comisco: 'comisco',
  comissa: 'comissa',
  comisso: 'comisso',
  comites: 'comitês',
  comitre: 'comitre',
  comivel: 'comível',
  comocao: 'comoção',
  comodai: 'comodai',
  comodam: 'comodam',
  comodar: 'comodar',
  comodas: 'cômodas',
  comodei: 'comodei',
  comodem: 'comodem',
  comodes: 'comodes',
  comodos: 'cômodos',
  comodou: 'comodou',
  comonia: 'comonia',
  comorai: 'comorai',
  comoram: 'comoram',
  comorar: 'comorar',
  comoras: 'comoras',
  comorei: 'comorei',
  comorem: 'comorem',
  comorim: 'comorim',
  comoros: 'comoros',
  comorou: 'comorou',
  comosas: 'comosas',
  comosos: 'comosos',
  comovam: 'comovam',
  comovas: 'comovas',
  comovei: 'comovei',
  comovem: 'comovem',
  comover: 'comover',
  comoves: 'comoves',
  comoveu: 'comoveu',
  comovia: 'comovia',
  compamu: 'compamu',
  compara: 'compara',
  compare: 'compare',
  comparo: 'comparo',
  compeca: 'compeça',
  compece: 'compece',
  compeco: 'compeço',
  compede: 'cômpede',
  compega: 'compega',
  compego: 'compego',
  compele: 'compele',
  compeli: 'compeli',
  compete: 'compete',
  competi: 'competi',
  compila: 'compila',
  compile: 'compile',
  compilo: 'compilo',
  compita: 'compita',
  compito: 'cômpito',
  complos: 'complôs',
  complot: 'complot',
  compoem: 'compõem',
  compoer: 'compoer',
  compoes: 'compões',
  compomo: 'compomo',
  compona: 'compona',
  compone: 'compone',
  componi: 'componi',
  compono: 'compono',
  compora: 'comporá',
  compota: 'compota',
  comprai: 'comprai',
  compram: 'compram',
  comprar: 'comprar',
  compras: 'compras',
  compraz: 'compraz',
  comprei: 'comprei',
  comprem: 'comprem',
  compres: 'compres',
  comprir: 'comprir',
  comprou: 'comprou',
  comptas: 'comptas',
  comptos: 'comptos',
  compume: 'compume',
  computa: 'computa',
  compute: 'compute',
  computo: 'cômputo',
  comudai: 'comudai',
  comudam: 'comudam',
  comudar: 'comudar',
  comudas: 'comudas',
  comudei: 'comudei',
  comudem: 'comudem',
  comudes: 'comudes',
  comudou: 'comudou',
  comumba: 'comumbá',
  comunal: 'comunal',
  comunas: 'comunas',
  comunga: 'comunga',
  comungo: 'comungo',
  comunia: 'comúnia',
  comutai: 'comutai',
  comutam: 'comutam',
  comutar: 'comutar',
  comutas: 'comutas',
  comutei: 'comutei',
  comutem: 'comutem',
  comutes: 'comutes',
  comutou: 'comutou',
  conabis: 'conabis',
  conacao: 'conação',
  conacas: 'conaças',
  conairo: 'conairó',
  conairu: 'conairu',
  conambi: 'conambi',
  conamis: 'conamis',
  conanas: 'conanas',
  conapai: 'conapai',
  conapam: 'conapam',
  conapar: 'conapar',
  conapas: 'conapas',
  conapei: 'conapei',
  conapem: 'conapem',
  conapes: 'conapes',
  conapos: 'conapos',
  conapou: 'conapou',
  conarea: 'conárea',
  conareo: 'conáreo',
  conaria: 'conária',
  conario: 'conário',
  conaros: 'conaros',
  conatos: 'conatos',
  conatus: 'conatus',
  concada: 'conçada',
  concado: 'conçado',
  concais: 'conçais',
  concamo: 'conçamo',
  concana: 'côncana',
  concane: 'concane',
  concani: 'concani',
  concano: 'côncano',
  concara: 'conçara',
  concava: 'côncava',
  concave: 'concave',
  concavo: 'côncavo',
  conceba: 'conceba',
  concebe: 'concebe',
  concebi: 'concebi',
  concebo: 'concebo',
  conceda: 'conceda',
  concede: 'concede',
  concedi: 'concedi',
  concedo: 'concedo',
  conceis: 'conceis',
  concela: 'concela',
  concemo: 'concemo',
  conchai: 'conchai',
  concham: 'concham',
  conchar: 'conchar',
  conchas: 'conchas',
  conchea: 'concheá',
  conchei: 'conchei',
  conchem: 'conchem',
  conches: 'conches',
  conchor: 'conchor',
  conchos: 'conchos',
  conchou: 'conchou',
  conciao: 'concião',
  concisa: 'concisa',
  conciso: 'conciso',
  concita: 'concita',
  concite: 'concite',
  concito: 'concito',
  concliz: 'concliz',
  conclua: 'conclua',
  conclui: 'conclui',
  concluo: 'concluo',
  concoes: 'concões',
  concons: 'concons',
  concria: 'concria',
  concrie: 'concrie',
  concrio: 'concrio',
  concriz: 'concriz',
  concuta: 'concuta',
  concute: 'concute',
  concuti: 'concuti',
  concuto: 'concuto',
  condado: 'condado',
  condais: 'condais',
  condamo: 'condamo',
  condeia: 'condeia',
  condena: 'condena',
  condene: 'condene',
  condeno: 'condeno',
  condiam: 'condiam',
  condias: 'condias',
  condida: 'condida',
  condido: 'condido',
  condiga: 'condiga',
  condigo: 'condigo',
  condilo: 'côndilo',
  condimo: 'condimo',
  condira: 'condirá',
  condita: 'condita',
  condito: 'condito',
  condize: 'condize',
  condoai: 'condoai',
  condoam: 'condoam',
  condoar: 'condoar',
  condoas: 'condoas',
  condoei: 'condoei',
  condoem: 'condoem',
  condoer: 'condoer',
  condoes: 'condoes',
  condoeu: 'condoeu',
  condoia: 'condoía',
  condois: 'condóis',
  condoma: 'condoma',
  condons: 'condons',
  condoou: 'condoou',
  condora: 'condora',
  condral: 'condral',
  condrio: 'condrio',
  condris: 'condris',
  condros: 'condros',
  conduri: 'conduri',
  conduru: 'conduru',
  conduta: 'conduta',
  condute: 'condute',
  conduto: 'conduto',
  conduza: 'conduza',
  conduze: 'conduze',
  conduzi: 'conduzi',
  conduzo: 'conduzo',
  conecta: 'conecta',
  conecte: 'conecte',
  conecto: 'conecto',
  conegas: 'cônegas',
  conegos: 'cônegos',
  conetai: 'conetai',
  conetam: 'conetam',
  conetar: 'conetar',
  conetas: 'conetas',
  conetei: 'conetei',
  conetem: 'conetem',
  conetes: 'conetes',
  conetor: 'conetor',
  conetou: 'conetou',
  conexao: 'conexão',
  conexas: 'conexas',
  conexos: 'conexos',
  conezia: 'conezia',
  confere: 'confere',
  conferi: 'conferi',
  confete: 'confete',
  confiai: 'confiai',
  confiam: 'confiam',
  confiar: 'confiar',
  confias: 'confias',
  confiei: 'confiei',
  confiem: 'confiem',
  confies: 'confies',
  confina: 'confina',
  confine: 'confine',
  confino: 'confino',
  confins: 'confins',
  confiou: 'confiou',
  confira: 'confira',
  confiro: 'confiro',
  confita: 'confita',
  conflua: 'conflua',
  conflui: 'conflui',
  confluo: 'confluo',
  confoge: 'confoge',
  confrei: 'confrei',
  confugi: 'confugi',
  confuja: 'confuja',
  confujo: 'confujo',
  confusa: 'confusa',
  confuso: 'confuso',
  confuta: 'confuta',
  confute: 'confute',
  confuto: 'confuto',
  congada: 'congada',
  congado: 'congado',
  congeia: 'congeia',
  congela: 'congela',
  congele: 'congele',
  congelo: 'congelo',
  congios: 'côngios',
  congore: 'congorê',
  congote: 'congote',
  congoxa: 'congoxa',
  congros: 'congros',
  congrua: 'côngrua',
  congruo: 'côngruo',
  congues: 'conguês',
  conguis: 'conguis',
  conheca: 'conheça',
  conhece: 'conhece',
  conheci: 'conheci',
  conheco: 'conheço',
  conhedo: 'conhedo',
  coniase: 'coníase',
  coniato: 'coniato',
  conicar: 'conicar',
  conicas: 'cônicas',
  conicos: 'cônicos',
  conidas: 'cônidas',
  conidea: 'conídea',
  conideo: 'conídeo',
  conidia: 'conídia',
  conidio: 'conídio',
  coniina: 'coniina',
  coninas: 'coninas',
  coninha: 'coninha',
  coniose: 'coniose',
  conismo: 'conismo',
  conista: 'conista',
  conitas: 'conitas',
  conizas: 'conizas',
  conjuga: 'conjuga',
  conjuge: 'cônjuge',
  conjugo: 'conjugo',
  conjuiz: 'conjuiz',
  conjura: 'conjura',
  conjure: 'conjure',
  conjuro: 'conjuro',
  conloio: 'conloio',
  conluia: 'conluia',
  conluie: 'conluie',
  conluio: 'conluio',
  conobia: 'conóbia',
  conocer: 'conocer',
  conoide: 'conoide',
  conomea: 'conomeá',
  cononjo: 'cononjo',
  conopes: 'conopes',
  conopeu: 'conopeu',
  conopio: 'conópio',
  conosco: 'conosco',
  conotai: 'conotai',
  conotam: 'conotam',
  conotar: 'conotar',
  conotas: 'conotas',
  conotei: 'conotei',
  conotem: 'conotem',
  conotes: 'conotes',
  conotou: 'conotou',
  conovia: 'conóvia',
  conquam: 'conquam',
  conquas: 'conquas',
  conquem: 'conquém',
  conques: 'conques',
  conquia: 'conquia',
  conquir: 'conquir',
  conquis: 'conquis',
  conquiu: 'conquiu',
  consaba: 'consaba',
  consabe: 'consabe',
  consabi: 'consabi',
  consabo: 'consabo',
  conscia: 'cônscia',
  conscio: 'cônscio',
  consego: 'consego',
  consiga: 'consiga',
  consigo: 'consigo',
  consoai: 'consoai',
  consoam: 'consoam',
  consoar: 'consoar',
  consoas: 'consoas',
  consoei: 'consoei',
  consoem: 'consoem',
  consoes: 'consoes',
  consola: 'consola',
  console: 'console',
  consolo: 'consolo',
  consome: 'consome',
  consona: 'consona',
  consone: 'consone',
  consono: 'cônsono',
  consoou: 'consoou',
  constai: 'constai',
  constam: 'constam',
  constar: 'constar',
  constas: 'constas',
  constei: 'constei',
  constem: 'constem',
  constes: 'constes',
  constou: 'constou',
  consuma: 'consuma',
  consume: 'consume',
  consumi: 'consumi',
  consumo: 'consumo',
  contada: 'contada',
  contado: 'contado',
  contais: 'contais',
  contamo: 'contamo',
  contana: 'contana',
  contano: 'contano',
  contara: 'contará',
  contata: 'contata',
  contate: 'contate',
  contato: 'contato',
  contava: 'contava',
  conteco: 'conteco',
  contego: 'contego',
  conteis: 'conteis',
  contemo: 'contemo',
  contens: 'conténs',
  contera: 'conterá',
  conteur: 'conteur',
  conteve: 'conteve',
  contias: 'contias',
  contida: 'contida',
  contido: 'contido',
  contigo: 'contigo',
  contina: 'contina',
  contino: 'contino',
  contive: 'contive',
  contrai: 'contrai',
  contran: 'contran',
  contras: 'contras',
  contros: 'contros',
  contrui: 'contruí',
  contudo: 'contudo',
  contusa: 'contusa',
  contuso: 'contuso',
  conubio: 'conúbio',
  conulea: 'conúlea',
  conuleo: 'conúleo',
  conurba: 'conurba',
  conurbe: 'conurbe',
  conurbo: 'conurbo',
  conuros: 'conuros',
  convale: 'convale',
  conveio: 'conveio',
  convele: 'convele',
  conveli: 'conveli',
  convena: 'cônvena',
  convens: 'convéns',
  convexa: 'convexa',
  convexo: 'convexo',
  conviam: 'conviam',
  convias: 'convias',
  convida: 'convida',
  convide: 'convide',
  convido: 'convido',
  conviei: 'conviei',
  conviem: 'conviem',
  convier: 'convier',
  convies: 'convies',
  convieu: 'convieu',
  conviia: 'conviia',
  convila: 'convila',
  convilo: 'convilo',
  convimo: 'convimo',
  convira: 'convirá',
  convite: 'convite',
  conviva: 'conviva',
  convive: 'convive',
  convivi: 'convivi',
  convivo: 'convivo',
  convoca: 'convoca',
  convoco: 'convoco',
  convola: 'convola',
  convole: 'convole',
  convolo: 'convolo',
  coobada: 'coobada',
  coobado: 'coobado',
  coobais: 'coobais',
  coobamo: 'coobamo',
  coobara: 'coobará',
  coobava: 'coobava',
  coobeis: 'coobeis',
  coobemo: 'coobemo',
  coocupa: 'coocupa',
  coocupe: 'coocupe',
  coocupo: 'coocupo',
  coopera: 'coopera',
  coopere: 'coopere',
  coopero: 'coopero',
  cooptai: 'cooptai',
  cooptam: 'cooptam',
  cooptar: 'cooptar',
  cooptas: 'cooptas',
  cooptei: 'cooptei',
  cooptem: 'cooptem',
  cooptes: 'cooptes',
  cooptou: 'cooptou',
  coortes: 'coortes',
  copacos: 'copaços',
  copadao: 'copadão',
  copadas: 'copadas',
  copador: 'copador',
  copados: 'copados',
  copagem: 'copagem',
  copaiba: 'copaíba',
  copaies: 'copaiés',
  copaina: 'copaína',
  copalmo: 'copalmo',
  copamos: 'copamos',
  copanas: 'copanas',
  copando: 'copando',
  copanos: 'copanos',
  copante: 'copante',
  copaque: 'copaque',
  coparam: 'coparam',
  coparao: 'coparão',
  coparas: 'coparás',
  coparei: 'coparei',
  coparem: 'coparem',
  copares: 'copares',
  coparia: 'coparia',
  coparmo: 'coparmo',
  copasse: 'copasse',
  copaste: 'copaste',
  copauba: 'copaúba',
  copauva: 'copaúva',
  copavam: 'copavam',
  copavas: 'copavas',
  copazio: 'copázio',
  copeica: 'copeica',
  copeira: 'copeira',
  copeire: 'copeire',
  copeiro: 'copeiro',
  copejai: 'copejai',
  copejam: 'copejam',
  copejar: 'copejar',
  copejas: 'copejas',
  copejei: 'copejei',
  copejem: 'copejem',
  copejes: 'copejes',
  copejou: 'copejou',
  copelai: 'copelai',
  copelam: 'copelam',
  copelar: 'copelar',
  copelas: 'copelas',
  copelei: 'copelei',
  copelem: 'copelem',
  copeles: 'copeles',
  copelha: 'copelha',
  copelou: 'copelou',
  copemos: 'copemos',
  copeque: 'copeque',
  copetes: 'copetes',
  copiada: 'copiada',
  copiado: 'copiado',
  copiais: 'copiais',
  copiamo: 'copiamo',
  copiara: 'copiara',
  copiata: 'copiata',
  copiava: 'copiava',
  copicas: 'copicas',
  copicos: 'copicos',
  copieis: 'copieis',
  copiemo: 'copiemo',
  copilai: 'copilai',
  copilam: 'copilam',
  copilar: 'copilar',
  copilas: 'copilas',
  copilei: 'copilei',
  copilem: 'copilem',
  copiles: 'copiles',
  copilia: 'copília',
  copilou: 'copilou',
  copinha: 'copinha',
  copinho: 'copinho',
  copinia: 'copínia',
  copioes: 'copiões',
  copiosa: 'copiosa',
  copioso: 'copioso',
  copista: 'copista',
  copitas: 'copitas',
  copitos: 'copitos',
  copiuba: 'copiúba',
  copiuva: 'copiúva',
  coplana: 'coplana',
  coplano: 'coplano',
  copodar: 'copodar',
  coposes: 'coposes',
  copoxos: 'copoxós',
  copraol: 'copraol',
  coprima: 'coprima',
  coprimo: 'coprimo',
  coproma: 'coproma',
  coptera: 'cóptera',
  coptica: 'cóptica',
  coptico: 'cóptico',
  coptido: 'cóptido',
  coptina: 'coptina',
  coptiza: 'coptizá',
  coptize: 'coptize',
  coptizo: 'coptizo',
  copuacu: 'copuaçu',
  copudas: 'copudas',
  copudos: 'copudos',
  copulai: 'copulai',
  copulam: 'copulam',
  copular: 'copular',
  copulas: 'cópulas',
  copulei: 'copulei',
  copulem: 'copulem',
  copules: 'copules',
  copulou: 'copulou',
  coqueis: 'coqueis',
  coquemo: 'coquemo',
  coqueta: 'coqueta',
  coquete: 'coquete',
  coquida: 'coquidá',
  coquito: 'coquito',
  coracao: 'coração',
  coracea: 'corácea',
  coraceo: 'coráceo',
  coraces: 'córaces',
  coradao: 'coradão',
  coradas: 'coradas',
  corador: 'corador',
  corados: 'corados',
  coragem: 'coragem',
  coraita: 'coraíta',
  coralim: 'coralim',
  coralio: 'corálio',
  coralos: 'coralos',
  coramos: 'coramos',
  coranas: 'coranas',
  corando: 'corando',
  corange: 'corange',
  corango: 'corango',
  coranos: 'coranos',
  corante: 'corante',
  coraram: 'coraram',
  corarao: 'corarão',
  coraras: 'coraras',
  corarei: 'corarei',
  corarem: 'corarem',
  corares: 'corares',
  coraria: 'coraria',
  corarmo: 'corarmo',
  corasmo: 'corasmo',
  corasse: 'corasse',
  coraste: 'coraste',
  coraulo: 'coraulo',
  coravam: 'coravam',
  coravas: 'coravas',
  coravel: 'corável',
  coraxos: 'coraxos',
  corazil: 'corazil',
  corazis: 'corazis',
  corbois: 'corbóis',
  corbula: 'córbula',
  corcada: 'corcada',
  corcado: 'corcado',
  corcais: 'corcais',
  corcamo: 'corcamo',
  corcara: 'corcará',
  corcava: 'corcava',
  corceis: 'corcéis',
  corchas: 'corchas',
  corchos: 'corchos',
  corcoro: 'córcoro',
  corcova: 'corcova',
  corcove: 'corcove',
  corcovo: 'corcovo',
  corculo: 'córculo',
  cordada: 'cordada',
  cordado: 'cordado',
  cordais: 'cordais',
  cordame: 'cordame',
  cordata: 'cordata',
  cordato: 'cordato',
  cordeai: 'cordeai',
  cordear: 'cordear',
  cordeca: 'cordeca',
  cordeei: 'cordeei',
  cordeia: 'cordeia',
  cordeie: 'cordeie',
  cordeio: 'cordeio',
  cordeis: 'cordéis',
  cordela: 'cordela',
  cordeou: 'cordeou',
  cordial: 'cordial',
  cordias: 'córdias',
  cordila: 'cordila',
  cordilo: 'cordilo',
  cordina: 'cordina',
  cordite: 'cordite',
  cordoai: 'cordoai',
  cordoam: 'cordoam',
  cordoar: 'cordoar',
  cordoas: 'cordoas',
  cordoba: 'córdoba',
  cordoei: 'cordoei',
  cordoem: 'cordoem',
  cordoes: 'cordões',
  cordois: 'cordóis',
  cordoma: 'cordoma',
  cordoou: 'cordoou',
  cordula: 'córdula',
  cordulo: 'córdulo',
  cordura: 'cordura',
  coreada: 'coreada',
  coreado: 'coreado',
  coreais: 'coreais',
  coreana: 'coreana',
  coreano: 'coreano',
  coreara: 'coreara',
  coreava: 'coreava',
  coreeis: 'coreeis',
  coregia: 'coregia',
  coregos: 'coregos',
  coreiam: 'coreiam',
  coreias: 'coreias',
  coreica: 'coreica',
  coreico: 'coreico',
  coreida: 'coreida',
  coreiem: 'coreiem',
  coreies: 'coreies',
  coreira: 'coreira',
  coreiro: 'coreiro',
  coreixa: 'coreixa',
  coremas: 'coremas',
  coremio: 'corêmio',
  coremos: 'coremos',
  corenta: 'corenta',
  coreras: 'coreras',
  coresma: 'coresma',
  coretos: 'coretos',
  coretro: 'coretro',
  coretus: 'coretus',
  coreuta: 'coreuta',
  corguis: 'corguis',
  coriais: 'coriais',
  coriavo: 'coriavo',
  coricas: 'coricas',
  coricio: 'corício',
  coricos: 'córicos',
  corifas: 'córifas',
  corifeu: 'corifeu',
  corigas: 'corigas',
  corigos: 'corigos',
  corilas: 'córilas',
  corilos: 'córilos',
  corimas: 'corimás',
  corimbe: 'corimbe',
  corimbo: 'corimbo',
  corinas: 'corinas',
  corinda: 'corinda',
  corindo: 'corindo',
  corines: 'corines',
  coringa: 'coringa',
  corinto: 'corinto',
  corioma: 'corioma',
  corions: 'córions',
  corisca: 'corisca',
  corisco: 'corisco',
  corises: 'córises',
  corista: 'corista',
  coristo: 'coristo',
  corixao: 'corixão',
  corixas: 'corixas',
  corixes: 'corixes',
  corixos: 'corixos',
  corizas: 'corizas',
  corizos: 'corizos',
  corjedo: 'corjedo',
  cormaro: 'cormaró',
  cormelo: 'cormelo',
  cormica: 'córmica',
  cormico: 'córmico',
  cormosa: 'cormosa',
  cormoso: 'cormoso',
  cornaca: 'cornaca',
  cornaco: 'cornaço',
  cornada: 'cornada',
  cornado: 'cornado',
  cornais: 'cornais',
  cornale: 'cornale',
  cornamo: 'cornamo',
  cornara: 'cornará',
  cornava: 'cornava',
  corneai: 'corneai',
  corneal: 'corneal',
  cornear: 'cornear',
  corneas: 'córneas',
  corneei: 'corneei',
  corneia: 'corneia',
  corneie: 'corneie',
  corneio: 'corneio',
  corneis: 'corneis',
  corneja: 'corneja',
  corneje: 'corneje',
  cornejo: 'cornejo',
  cornela: 'cornela',
  cornemo: 'cornemo',
  corneos: 'córneos',
  corneou: 'corneou',
  corneta: 'corneta',
  cornete: 'cornete',
  corneto: 'corneto',
  corniam: 'córniam',
  cornias: 'córnias',
  cornica: 'córnica',
  cornice: 'cornice',
  cornico: 'córnico',
  cornida: 'córnida',
  cornido: 'córnido',
  cornija: 'cornija',
  cornina: 'cornina',
  cornino: 'cornino',
  cornipo: 'cornipo',
  corniso: 'corniso',
  cornizo: 'cornizo',
  cornuda: 'cornuda',
  cornude: 'cornude',
  cornudo: 'cornudo',
  cornuta: 'cornuta',
  cornuto: 'cornuto',
  coroada: 'coroada',
  coroado: 'coroado',
  coroais: 'coroais',
  coroamo: 'coroamo',
  coroara: 'coroara',
  coroata: 'coroatá',
  coroava: 'coroava',
  corobos: 'corobós',
  corocas: 'corocas',
  corocha: 'corocha',
  corocos: 'coroços',
  coroeis: 'coroeis',
  coroemo: 'coroemo',
  corofio: 'corófio',
  coroias: 'coroias',
  coroide: 'coroide',
  corolas: 'corolas',
  corombo: 'corombó',
  coromem: 'coromem',
  coronal: 'coronal',
  coronas: 'coronas',
  coronco: 'coronco',
  corondo: 'corondó',
  coronel: 'coronel',
  coroneu: 'coroneu',
  coronge: 'coronge',
  corongo: 'corongo',
  coronha: 'coronha',
  coronho: 'coronho',
  coronio: 'corônio',
  coronis: 'corônis',
  coropos: 'coropós',
  coroque: 'coroque',
  cororoa: 'cororoá',
  cororos: 'cororôs',
  corosil: 'corosil',
  corotes: 'corotes',
  coroteu: 'corotéu',
  corozil: 'corozil',
  corozis: 'corozis',
  corozos: 'corozos',
  corpaco: 'corpaço',
  corpete: 'corpete',
  corpica: 'corpica',
  corpico: 'corpico',
  corpies: 'corpiés',
  corpilo: 'corpilo',
  corpora: 'corpora',
  corpuda: 'corpuda',
  corpudo: 'corpudo',
  corquei: 'corquei',
  corquem: 'corquem',
  corques: 'corques',
  corrais: 'corrais',
  corramo: 'corramo',
  correai: 'correai',
  correal: 'correal',
  correao: 'correão',
  correar: 'correar',
  correas: 'córreas',
  correei: 'correei',
  correga: 'córrega',
  correge: 'correge',
  corregi: 'corregi',
  corrego: 'córrego',
  correia: 'correia',
  correie: 'correie',
  correio: 'correio',
  correis: 'correis',
  correja: 'correja',
  correjo: 'correjo',
  corremo: 'corremo',
  correol: 'correol',
  correou: 'correou',
  correra: 'correra',
  correta: 'correta',
  correte: 'correte',
  correto: 'correto',
  correus: 'corréus',
  correxa: 'correxá',
  correxe: 'correxe',
  correxo: 'correxo',
  corriam: 'corriam',
  corriao: 'corrião',
  corrias: 'corrias',
  corrica: 'corrica',
  corrico: 'corrico',
  corrida: 'corrida',
  corrido: 'corrido',
  corrige: 'corrige',
  corrigi: 'corrigi',
  corrija: 'corrija',
  corrijo: 'corrijo',
  corrina: 'corrina',
  corriol: 'corriol',
  corripa: 'corripá',
  corripe: 'corripe',
  corripo: 'corripo',
  corrixo: 'corrixo',
  corroam: 'corroam',
  corroas: 'corroas',
  corrobo: 'corrobó',
  corroei: 'corroei',
  corroem: 'corroem',
  corroer: 'corroer',
  corroes: 'corroes',
  corroeu: 'corroeu',
  corroia: 'corroía',
  corroio: 'corroio',
  corrois: 'corróis',
  corrolo: 'corroló',
  corruda: 'corruda',
  corruga: 'corruga',
  corrugo: 'corrugo',
  corrume: 'corrume',
  corruta: 'corruta',
  corrute: 'corrute',
  corruto: 'corruto',
  corsaco: 'corsaco',
  corseai: 'corseai',
  corsear: 'corsear',
  corseei: 'corseei',
  corseia: 'corseia',
  corseie: 'corseie',
  corseio: 'corseio',
  corseou: 'corseou',
  corsias: 'córsias',
  corsica: 'córsica',
  corsico: 'córsico',
  corsita: 'corsita',
  corsite: 'corsite',
  corsoes: 'corsões',
  corsona: 'corsona',
  cortada: 'cortada',
  cortado: 'cortado',
  cortais: 'cortais',
  cortamo: 'cortamo',
  cortara: 'cortara',
  cortava: 'cortava',
  corteai: 'corteai',
  cortear: 'cortear',
  corteei: 'corteei',
  corteia: 'corteia',
  corteie: 'corteie',
  corteio: 'corteio',
  corteis: 'corteis',
  corteja: 'corteja',
  corteje: 'corteje',
  cortejo: 'cortejo',
  cortemo: 'cortemo',
  corteou: 'corteou',
  cortesa: 'cortesã',
  corteza: 'corteza',
  cortica: 'cortiça',
  cortice: 'córtice',
  cortico: 'cortiço',
  cortijo: 'cortijo',
  cortina: 'cortina',
  cortine: 'cortine',
  cortino: 'cortino',
  cortins: 'cortins',
  cortume: 'cortume',
  cortusa: 'cortusa',
  corubas: 'corubas',
  corucao: 'corucão',
  corucha: 'corucha',
  coruche: 'coruche',
  corucho: 'corucho',
  corujai: 'corujai',
  corujam: 'corujam',
  corujao: 'corujão',
  corujar: 'corujar',
  corujas: 'corujas',
  corujei: 'corujei',
  corujem: 'corujem',
  corujes: 'corujes',
  corujou: 'corujou',
  corumba: 'corumbá',
  corumbi: 'corumbi',
  corumim: 'corumim',
  corunas: 'corunas',
  corundo: 'corundo',
  corunha: 'corunha',
  corunhe: 'corunhe',
  corunho: 'corunho',
  corupia: 'corupiá',
  corusca: 'corusca',
  corusco: 'corusco',
  corutas: 'corutas',
  corutos: 'corutos',
  corveia: 'corveia',
  corveja: 'corveja',
  corveje: 'corveje',
  corvejo: 'corvejo',
  corvela: 'corvela',
  corvelo: 'corvelo',
  corveta: 'corveta',
  corveus: 'corvéus',
  corvida: 'córvida',
  corvina: 'corvina',
  corvino: 'corvino',
  corxins: 'corxins',
  cosamos: 'cosamos',
  cosanas: 'cosanas',
  cosanos: 'cosanos',
  cosanza: 'cosanza',
  coscoja: 'coscoja',
  coscora: 'coscorá',
  coscore: 'coscore',
  coscoro: 'cóscoro',
  cosedor: 'cosedor',
  cosemos: 'cosemos',
  cosendo: 'cosendo',
  coseram: 'coseram',
  coserao: 'coserão',
  coseras: 'coseras',
  coserei: 'coserei',
  coserem: 'coserem',
  coseres: 'coseres',
  coseria: 'coseria',
  cosermo: 'cosermo',
  cosesse: 'cosesse',
  coseste: 'coseste',
  cosicai: 'cosicai',
  cosicam: 'cosicam',
  cosicar: 'cosicar',
  cosicas: 'cosicas',
  cosicou: 'cosicou',
  cosidao: 'cosidão',
  cosidas: 'cosidas',
  cosidos: 'cosidos',
  cosieis: 'cosíeis',
  cosinas: 'cosinas',
  cosipai: 'cosipai',
  cosipam: 'cosipam',
  cosipar: 'cosipar',
  cosipas: 'cosipas',
  cosipei: 'cosipei',
  cosipem: 'cosipem',
  cosipes: 'cosipes',
  cosipou: 'cosipou',
  cosique: 'cosique',
  cosivel: 'cosível',
  cosmeas: 'cósmeas',
  cosmeia: 'cosmeia',
  cosmeos: 'cósmeos',
  cosmias: 'cósmias',
  cosmica: 'cósmica',
  cosmico: 'cósmico',
  cosmina: 'cosmina',
  cosmine: 'cosmine',
  cosmino: 'cosmino',
  cosquea: 'cosqueá',
  cosques: 'cosques',
  cossaca: 'cossaca',
  cossaco: 'cossaco',
  cosseno: 'cosseno',
  cossida: 'cóssida',
  cossieu: 'cossieu',
  cossifa: 'cóssifa',
  cossifo: 'cóssifo',
  cossios: 'cóssios',
  cossono: 'cossono',
  cossumo: 'cossumo',
  costada: 'costada',
  costado: 'costado',
  costais: 'costais',
  costeai: 'costeai',
  costear: 'costear',
  costeei: 'costeei',
  costeia: 'costeia',
  costeie: 'costeie',
  costeio: 'costeio',
  costela: 'costela',
  costele: 'costele',
  costelo: 'costelo',
  costeou: 'costeou',
  costias: 'cóstias',
  costilo: 'costilo',
  costina: 'costina',
  costino: 'costino',
  costoes: 'costões',
  costula: 'cóstula',
  costuma: 'costuma',
  costume: 'costume',
  costumo: 'costumo',
  costura: 'costura',
  costure: 'costure',
  costuro: 'costuro',
  cotacao: 'cotação',
  cotadao: 'cotadão',
  cotadas: 'cotadas',
  cotador: 'cotador',
  cotados: 'cotados',
  cotamos: 'cotamos',
  cotando: 'cotando',
  cotanes: 'cotanês',
  cotante: 'cotante',
  cotaram: 'cotaram',
  cotarao: 'cotarão',
  cotaras: 'cotaras',
  cotarei: 'cotarei',
  cotarem: 'cotarem',
  cotares: 'cotares',
  cotaria: 'cotaria',
  cotario: 'cotário',
  cotaris: 'cotaris',
  cotarmo: 'cotarmo',
  cotarro: 'cotarro',
  cotasse: 'cotasse',
  cotaste: 'cotaste',
  cotavam: 'cotavam',
  cotavas: 'cotavas',
  cotavel: 'cotável',
  cotazao: 'cotazão',
  cotecas: 'cotecás',
  cotedua: 'cotedua',
  coteife: 'coteife',
  coteira: 'coteira',
  coteiro: 'coteiro',
  cotejai: 'cotejai',
  cotejam: 'cotejam',
  cotejar: 'cotejar',
  cotejas: 'cotejas',
  cotejei: 'cotejei',
  cotejem: 'cotejem',
  cotejes: 'cotejes',
  cotejou: 'cotejou',
  cotelao: 'cotelão',
  cotemos: 'cotemos',
  coterie: 'coterie',
  cotetes: 'cotetes',
  cotetos: 'cotetos',
  cotexto: 'cotexto',
  cotiada: 'cotiada',
  cotiado: 'cotiado',
  cotiais: 'cotiais',
  cotiamo: 'cotiamo',
  cotiana: 'cotiana',
  cotiano: 'cotiano',
  cotiara: 'cotiara',
  cotiava: 'cotiava',
  coticas: 'coticas',
  cotidal: 'cotidal',
  cotidas: 'cótidas',
  cotidea: 'cotídea',
  cotideo: 'cotídeo',
  cotieis: 'cotieis',
  cotiemo: 'cotiemo',
  cotigos: 'cótigos',
  cotilas: 'cótilas',
  cotilea: 'cotílea',
  cotileo: 'cotíleo',
  cotilos: 'cótilos',
  cotinga: 'cotinga',
  cotinha: 'cotinha',
  cotinos: 'cotinos',
  cotipos: 'cotipos',
  cotista: 'cotista',
  cotitos: 'cotitos',
  cotizai: 'cotizai',
  cotizam: 'cotizam',
  cotizar: 'cotizar',
  cotizas: 'cotizas',
  cotizei: 'cotizei',
  cotizem: 'cotizem',
  cotizes: 'cotizes',
  cotizou: 'cotizou',
  cotocos: 'cotocos',
  cotoina: 'cotoína',
  cotonai: 'cotonai',
  cotonam: 'cotonam',
  cotonar: 'cotonar',
  cotonas: 'cotonas',
  cotonei: 'cotonei',
  cotonem: 'cotonem',
  cotones: 'cotones',
  cotonia: 'cotonia',
  cotonou: 'cotonou',
  cotovia: 'cotovia',
  cotoxes: 'cotoxés',
  cotoxos: 'cotoxós',
  cotrala: 'cotrala',
  cotreia: 'cotreia',
  cotriba: 'cotriba',
  cotrino: 'cotrino',
  cotrins: 'cotrins',
  cotroes: 'cotrões',
  cotrofe: 'cotrofe',
  cotrosa: 'cotrosa',
  cotroso: 'cotroso',
  cotruca: 'cotruca',
  cotruco: 'cotruco',
  cottage: 'cottage',
  cotubas: 'cotubas',
  cotulai: 'cotulai',
  cotulam: 'cotulam',
  cotular: 'cotular',
  cotulas: 'cótulas',
  cotulei: 'cotulei',
  cotulem: 'cotulem',
  cotules: 'cotules',
  cotulos: 'cotulos',
  cotulou: 'cotulou',
  cotumba: 'cotumba',
  coturna: 'coturna',
  coturno: 'coturno',
  cotutor: 'cotutor',
  coubera: 'coubera',
  coucada: 'couçada',
  coucado: 'couçado',
  coucais: 'couçais',
  coucamo: 'couçamo',
  coucara: 'couçará',
  coucava: 'couçava',
  couceai: 'couceai',
  coucear: 'coucear',
  couceei: 'couceei',
  couceia: 'couceia',
  couceie: 'couceie',
  couceio: 'couceio',
  couceis: 'couceis',
  coucelo: 'coucelo',
  coucemo: 'coucemo',
  couceou: 'couceou',
  couches: 'couchés',
  couchos: 'couchos',
  coucoes: 'couções',
  coucona: 'couçona',
  coudeca: 'coudeça',
  coudece: 'coudece',
  coudeci: 'coudeci',
  coudeco: 'coudeço',
  coudeis: 'coudéis',
  coudela: 'coudelá',
  coudele: 'coudele',
  coudelo: 'coudelo',
  coudras: 'coudras',
  coulomb: 'coulomb',
  country: 'country',
  couplet: 'couplet',
  couraca: 'couraça',
  courace: 'courace',
  couraco: 'couraço',
  courada: 'courada',
  courama: 'courama',
  courana: 'courana',
  courata: 'courata',
  courato: 'courato',
  coureai: 'coureai',
  courear: 'courear',
  coureei: 'coureei',
  coureia: 'coureia',
  coureie: 'coureie',
  coureio: 'coureio',
  courela: 'courela',
  courele: 'courele',
  courelo: 'courelo',
  coureou: 'coureou',
  couroes: 'courões',
  courois: 'couróis',
  courona: 'courona',
  couruda: 'couruda',
  courudo: 'courudo',
  couruno: 'couruno',
  cousada: 'cousada',
  cousado: 'cousado',
  cousais: 'cousais',
  cousamo: 'cousamo',
  cousara: 'cousará',
  cousava: 'cousava',
  couseis: 'couseis',
  cousemo: 'cousemo',
  cousica: 'cousica',
  cousita: 'cousita',
  coussos: 'coussos',
  coutada: 'coutada',
  coutado: 'coutado',
  coutais: 'coutais',
  coutamo: 'coutamo',
  coutara: 'coutará',
  coutava: 'coutava',
  couteis: 'couteis',
  coutemo: 'coutemo',
  coutios: 'coutios',
  couvada: 'couvada',
  couvade: 'couvade',
  couvado: 'couvado',
  couvais: 'couvais',
  couvamo: 'couvamo',
  couvana: 'couvana',
  couvara: 'couvará',
  couvava: 'couvava',
  couveis: 'couveis',
  couvemo: 'couvemo',
  couvert: 'couvert',
  couvoes: 'couvões',
  covacha: 'covachá',
  covache: 'covache',
  covacho: 'covacho',
  covadas: 'covadas',
  covadis: 'covádis',
  covador: 'covador',
  covados: 'côvados',
  covagem: 'covagem',
  covanca: 'covanca',
  covante: 'covante',
  covarde: 'covarde',
  covardo: 'covardo',
  covatas: 'covatas',
  covatos: 'covatos',
  covavel: 'covável',
  coveada: 'coveada',
  coveado: 'coveado',
  coveais: 'coveais',
  coveara: 'coveará',
  coveava: 'coveava',
  coveeis: 'coveeis',
  coveiam: 'coveiam',
  coveias: 'coveias',
  coveiem: 'coveiem',
  coveies: 'coveies',
  coveira: 'coveira',
  coveiro: 'coveiro',
  covelas: 'covelas',
  covenda: 'covendá',
  covende: 'covende',
  covendo: 'covendo',
  covetas: 'covetas',
  covinha: 'covinha',
  covitas: 'covitas',
  covites: 'covites',
  covitos: 'covitos',
  covoada: 'covoada',
  covocas: 'covocas',
  covocos: 'covocós',
  covooes: 'covoões',
  coxagra: 'coxagra',
  coxanga: 'coxanga',
  coxarro: 'coxarro',
  coxeada: 'coxeada',
  coxeado: 'coxeado',
  coxeais: 'coxeais',
  coxeara: 'coxeará',
  coxeava: 'coxeava',
  coxeeis: 'coxeeis',
  coxeiam: 'coxeiam',
  coxeias: 'coxeias',
  coxeiem: 'coxeiem',
  coxeies: 'coxeies',
  coxeira: 'coxeira',
  coxelas: 'coxelas',
  coxelos: 'coxelos',
  coxetes: 'coxetes',
  coxiela: 'coxiela',
  coxilgo: 'coxilgo',
  coxilha: 'coxilha',
  coxinha: 'coxinha',
  coxinho: 'coxinho',
  coxites: 'coxites',
  coxotes: 'coxotes',
  cozamos: 'cozamos',
  cozedor: 'cozedor',
  cozelas: 'cozelas',
  cozemos: 'cozemos',
  cozendo: 'cozendo',
  cozeram: 'cozeram',
  cozerao: 'cozerão',
  cozeras: 'cozeras',
  cozerei: 'cozerei',
  cozerem: 'cozerem',
  cozeres: 'cozeres',
  cozeria: 'cozeria',
  cozermo: 'cozermo',
  cozesse: 'cozesse',
  cozeste: 'cozeste',
  cozidao: 'cozidão',
  cozidas: 'cozidas',
  cozidos: 'cozidos',
  cozieis: 'cozíeis',
  cozinha: 'cozinha',
  cozinhe: 'cozinhe',
  cozinho: 'cozinho',
  crabeas: 'crábeas',
  crabeos: 'crábeos',
  crabros: 'crabros',
  cracati: 'cracati',
  cracaxa: 'cracaxá',
  crachas: 'crachás',
  cracida: 'crácida',
  cracker: 'cracker',
  cracmum: 'cracmum',
  cracole: 'cracolé',
  cracras: 'cracrás',
  cradina: 'cradina',
  craibia: 'cráibia',
  craigia: 'cráigia',
  craioes: 'craiões',
  craiona: 'craioná',
  craione: 'craione',
  craiono: 'craiono',
  craions: 'craions',
  crajuas: 'crajuás',
  crajuru: 'crajuru',
  crambes: 'crambes',
  crambos: 'crambos',
  crampas: 'crampas',
  cramuri: 'cramuri',
  crancho: 'crancho',
  crangao: 'crangão',
  crangom: 'crangom',
  craniai: 'craniai',
  cranial: 'cranial',
  craniam: 'craniam',
  craniar: 'craniar',
  cranias: 'cranias',
  craniei: 'craniei',
  craniem: 'craniem',
  cranies: 'cranies',
  cranios: 'crânios',
  craniou: 'craniou',
  cranite: 'cranite',
  cranque: 'cranque',
  cranter: 'cranter',
  craones: 'craonês',
  crapela: 'crapela',
  crapete: 'crapete',
  craptes: 'craptés',
  crapuca: 'crapuça',
  crapula: 'crápula',
  craquea: 'craqueá',
  craques: 'craques',
  craseai: 'craseai',
  crasear: 'crasear',
  craseei: 'craseei',
  craseia: 'craseia',
  craseie: 'craseie',
  craseio: 'craseio',
  craseou: 'craseou',
  crasias: 'crasias',
  crasica: 'crásica',
  crasico: 'crásico',
  crassas: 'crassas',
  crassos: 'crassos',
  crastas: 'crastas',
  crastos: 'crastos',
  cratego: 'cratego',
  cratena: 'cratena',
  cratera: 'cratera',
  cratica: 'crática',
  cratico: 'crático',
  cratoes: 'cratões',
  cratons: 'crátons',
  crauacu: 'crauaçu',
  crauata: 'crauatá',
  craucas: 'crauçás',
  craunas: 'craúnas',
  crauros: 'crauros',
  crausta: 'crausta',
  crautas: 'crautás',
  cravada: 'cravada',
  cravado: 'cravado',
  cravais: 'cravais',
  cravamo: 'cravamo',
  cravara: 'cravara',
  cravata: 'cravatá',
  cravava: 'cravava',
  craveis: 'craveis',
  craveja: 'craveja',
  craveje: 'craveje',
  cravejo: 'cravejo',
  cravela: 'cravela',
  cravelo: 'cravelo',
  cravemo: 'cravemo',
  cravete: 'cravete',
  cravija: 'cravija',
  cravina: 'cravina',
  cravine: 'cravine',
  cravino: 'cravino',
  craviri: 'craviri',
  creacas: 'creacas',
  creador: 'creador',
  creatos: 'creatos',
  crebras: 'crebras',
  crebros: 'crebros',
  creches: 'creches',
  crecito: 'crécito',
  crecuda: 'creçuda',
  crecudo: 'creçudo',
  credita: 'credita',
  credite: 'credite',
  credito: 'crédito',
  credora: 'credora',
  credula: 'crédula',
  credulo: 'crédulo',
  credzes: 'credzes',
  creiais: 'creiais',
  creiamo: 'creiamo',
  creions: 'creions',
  creivel: 'creível',
  crejica: 'crejica',
  crejuas: 'crejuás',
  crelada: 'crelada',
  crelado: 'crelado',
  crelais: 'crelais',
  crelamo: 'crelamo',
  crelara: 'crelará',
  crelava: 'crelava',
  creleis: 'creleis',
  crelemo: 'crelemo',
  creligo: 'créligo',
  cremada: 'cremada',
  cremado: 'cremado',
  cremais: 'cremais',
  cremamo: 'cremamo',
  cremara: 'cremara',
  cremava: 'cremava',
  cremego: 'cremego',
  cremeis: 'cremeis',
  crememo: 'crememo',
  cremepa: 'cremepa',
  cremepe: 'cremepe',
  cremern: 'cremern',
  cremero: 'cremero',
  cremers: 'cremers',
  cremesc: 'cremesc',
  cremese: 'cremese',
  cremesp: 'cremesp',
  cremlim: 'cremlim',
  cremona: 'cremona',
  cremosa: 'cremosa',
  cremoso: 'cremoso',
  crenada: 'crenada',
  crenado: 'crenado',
  crenais: 'crenais',
  crenamo: 'crenamo',
  crenara: 'crenará',
  crenato: 'crenato',
  crenava: 'crenava',
  crencas: 'crenças',
  crencho: 'crencho',
  creneis: 'creneis',
  crenela: 'crenela',
  crenemo: 'crenemo',
  crenico: 'crênico',
  crenita: 'crenita',
  crenjes: 'crenjês',
  crentes: 'crentes',
  crenula: 'crênula',
  creobio: 'creóbio',
  creosal: 'creosal',
  creosol: 'creosol',
  crepida: 'crépida',
  crepido: 'crépido',
  crepins: 'crepins',
  crepita: 'crepita',
  crepite: 'crepite',
  crepito: 'crepito',
  creplos: 'creplos',
  crepons: 'crepons',
  crerdes: 'crerdes',
  crereis: 'crereis',
  creremo: 'creremo',
  creriam: 'creriam',
  crerias: 'crerias',
  crermos: 'crermos',
  crescam: 'cresçam',
  crescas: 'cresças',
  crescei: 'crescei',
  crescem: 'crescem',
  crescer: 'crescer',
  cresces: 'cresces',
  cresceu: 'cresceu',
  crescia: 'crescia',
  cresila: 'cresila',
  cresilo: 'cresilo',
  cresois: 'cresóis',
  crespai: 'crespai',
  crespam: 'crespam',
  crespao: 'crespão',
  crespar: 'crespar',
  crespas: 'crespas',
  crespei: 'crespei',
  crespem: 'crespem',
  crespes: 'crespes',
  crespia: 'crespia',
  crespir: 'crespir',
  crespis: 'crespis',
  crespiu: 'crespiu',
  crespor: 'crespor',
  crespos: 'crespos',
  crespou: 'crespou',
  cressas: 'cressas',
  cressem: 'cressem',
  cresses: 'cresses',
  crestai: 'crestai',
  crestam: 'crestam',
  crestao: 'crestão',
  crestar: 'crestar',
  crestas: 'crestas',
  crestei: 'crestei',
  crestem: 'crestem',
  crestes: 'crestes',
  crestou: 'crestou',
  cretica: 'crética',
  cretico: 'crético',
  cretina: 'cretina',
  cretino: 'cretino',
  cretoes: 'cretões',
  cretone: 'cretone',
  crevete: 'crevete',
  crevins: 'crevins',
  criacao: 'criação',
  criadao: 'criadão',
  criadas: 'criadas',
  criador: 'criador',
  criados: 'criados',
  criamos: 'criamos',
  crianca: 'criança',
  crianco: 'crianço',
  criando: 'criando',
  criante: 'criante',
  criaram: 'criaram',
  criarao: 'criarão',
  criaras: 'criaras',
  criarei: 'criarei',
  criarem: 'criarem',
  criares: 'criares',
  criaria: 'criaria',
  criarmo: 'criarmo',
  criasse: 'criasse',
  criaste: 'criaste',
  criavam: 'criavam',
  criavas: 'criavas',
  criavel: 'criável',
  cribelo: 'cribelo',
  cricare: 'cricaré',
  cricati: 'cricati',
  criceto: 'criceto',
  cricome: 'cricomé',
  cricrio: 'cricrió',
  cricris: 'cricris',
  cricula: 'crícula',
  cridoes: 'cridões',
  cridona: 'cridona',
  criemos: 'criemos',
  crifalo: 'crífalo',
  crifeca: 'crifeca',
  crifeia: 'crifeia',
  crifias: 'crifias',
  crifios: 'crífios',
  crijoas: 'crijoás',
  crilada: 'crilada',
  crimeza: 'crimeza',
  crimina: 'crimina',
  crimine: 'crimine',
  crimino: 'crimino',
  crimose: 'crimose',
  crimpai: 'crimpai',
  crimpam: 'crimpam',
  crimpar: 'crimpar',
  crimpas: 'crimpas',
  crimpei: 'crimpei',
  crimpem: 'crimpem',
  crimpes: 'crimpes',
  crimpou: 'crimpou',
  crinais: 'crinais',
  crinina: 'crinina',
  crinito: 'crinito',
  crinone: 'crinone',
  crinuda: 'crinuda',
  crinudo: 'crinudo',
  crinuns: 'crínuns',
  crioilo: 'crioilo',
  crioses: 'crioses',
  criotao: 'criotão',
  crioula: 'crioula',
  crioulo: 'crioulo',
  criptao: 'críptão',
  criptas: 'criptas',
  criptia: 'criptia',
  cripton: 'crípton',
  criptos: 'criptos',
  crisada: 'crisada',
  crisado: 'crisado',
  crisais: 'crisais',
  crisamo: 'crisamo',
  crisara: 'crisará',
  crisava: 'crisava',
  criseas: 'críseas',
  criseis: 'criseis',
  crisemo: 'crisemo',
  criseno: 'criseno',
  criseos: 'críseos',
  crisias: 'crísias',
  crisida: 'crísida',
  criside: 'críside',
  crisina: 'crisina',
  crisite: 'crisite',
  crismai: 'crismai',
  crismal: 'crismal',
  crismam: 'crismam',
  crismar: 'crismar',
  crismas: 'crismas',
  crismei: 'crismei',
  crismem: 'crismem',
  crismes: 'crismes',
  crismou: 'crismou',
  crisois: 'crisóis',
  crisola: 'crisola',
  crisole: 'crisole',
  crisolo: 'crisolo',
  crisopa: 'crisopa',
  crisope: 'crisope',
  crisops: 'crísops',
  crispai: 'crispai',
  crispam: 'crispam',
  crispao: 'crispão',
  crispar: 'crispar',
  crispas: 'crispas',
  crispei: 'crispei',
  crispem: 'crispem',
  crispes: 'crispes',
  crispim: 'crispim',
  crispou: 'crispou',
  crissos: 'crissos',
  cristal: 'cristal',
  cristao: 'cristão',
  cristas: 'cristãs',
  cristea: 'cristeá',
  cristel: 'cristel',
  cristos: 'cristos',
  critica: 'crítica',
  critico: 'crítico',
  critmos: 'critmos',
  criuris: 'criuris',
  crivada: 'crivada',
  crivado: 'crivado',
  crivais: 'crivais',
  crivamo: 'crivamo',
  crivara: 'crivara',
  crivava: 'crivava',
  criveis: 'criveis',
  crivemo: 'crivemo',
  criviri: 'criviri',
  crivosa: 'crivosa',
  crivoso: 'crivoso',
  crixana: 'crixaná',
  crizada: 'crizada',
  croacio: 'croácio',
  croatas: 'croatas',
  croatol: 'croatol',
  crobilo: 'cróbilo',
  crocais: 'crocais',
  croceas: 'cróceas',
  croceos: 'cróceos',
  croches: 'crochês',
  crocica: 'crócica',
  crocico: 'crócico',
  crocina: 'crocina',
  crocino: 'crócino',
  crocita: 'crocita',
  crocite: 'crocite',
  crocito: 'crocito',
  crocoio: 'crocoió',
  crocola: 'crocola',
  crocose: 'crocose',
  crocota: 'crocota',
  crocoto: 'crocotó',
  crocuta: 'crocuta',
  croeira: 'croeira',
  croinha: 'croinha',
  croissa: 'croissã',
  cromada: 'cromada',
  cromado: 'cromado',
  cromais: 'cromais',
  cromamo: 'cromamo',
  cromara: 'cromara',
  cromato: 'cromato',
  cromava: 'cromava',
  cromeis: 'cromeis',
  cromemo: 'cromemo',
  cromias: 'cromias',
  cromica: 'crômica',
  cromico: 'crômico',
  cromida: 'crômida',
  cromila: 'cromila',
  cromilo: 'cromilo',
  cromios: 'crômios',
  cromita: 'cromita',
  cromite: 'cromite',
  cromito: 'cromito',
  cromiza: 'cromizá',
  cromize: 'cromize',
  cromizo: 'cromizo',
  cromoma: 'cromoma',
  cromosa: 'cromosa',
  cromoso: 'cromoso',
  cromula: 'crômula',
  cronema: 'cronema',
  cronhas: 'cronhas',
  cronica: 'crônica',
  cronico: 'crônico',
  croniza: 'croniza',
  cronize: 'cronize',
  cronizo: 'cronizo',
  cronqui: 'cronqui',
  croocal: 'croocal',
  croomia: 'croômia',
  crooner: 'crooner',
  cropogo: 'cropogô',
  croques: 'croques',
  croquet: 'croquet',
  croquis: 'croquis',
  croscas: 'croscas',
  crossas: 'crossas',
  crostal: 'crostal',
  crostas: 'crostas',
  crostos: 'crostos',
  crotalo: 'crótalo',
  crotina: 'crotina',
  crotoes: 'crotões',
  crotons: 'crótons',
  cruanha: 'cruanha',
  cruapes: 'cruapés',
  crubixa: 'crubixá',
  crubula: 'crubula',
  cruchas: 'cruchas',
  cruchos: 'cruchos',
  cruciai: 'cruciai',
  crucial: 'crucial',
  cruciam: 'cruciam',
  cruciar: 'cruciar',
  crucias: 'crucias',
  crucica: 'cruciça',
  cruciei: 'cruciei',
  cruciem: 'cruciem',
  crucies: 'crucies',
  cruciou: 'cruciou',
  crucita: 'crucita',
  crucite: 'crucite',
  crucito: 'crucito',
  crudeza: 'crudeza',
  crudias: 'crúdias',
  crudice: 'crudice',
  crueira: 'crueira',
  crueiro: 'crueiro',
  cruenta: 'cruenta',
  cruente: 'cruente',
  cruento: 'cruento',
  crueras: 'crueras',
  cruezas: 'cruezas',
  cruilis: 'cruilis',
  crumata: 'crumatá',
  crumena: 'crumena',
  crumens: 'crúmens',
  crunhas: 'crunhas',
  cruoria: 'cruória',
  crupais: 'crupais',
  crupiao: 'crupião',
  crupies: 'crupiês',
  crupina: 'crupina',
  crurais: 'crurais',
  crustal: 'crustal',
  crustas: 'crustas',
  cruzada: 'cruzada',
  cruzado: 'cruzado',
  cruzais: 'cruzais',
  cruzamo: 'cruzamo',
  cruzano: 'cruzano',
  cruzara: 'cruzara',
  cruzava: 'cruzava',
  cruzeas: 'crúzeas',
  cruzeis: 'cruzeis',
  cruzemo: 'cruzemo',
  cruzeta: 'cruzeta',
  cruzios: 'crúzios',
  ctamalo: 'ctâmalo',
  ctenida: 'ctênida',
  ctenios: 'ctênios',
  cteniza: 'cteniza',
  ctenomo: 'ctênomo',
  ctonica: 'ctônica',
  ctonico: 'ctônico',
  ctonios: 'ctônios',
  cuacuas: 'cuácuas',
  cuainhi: 'cuainhi',
  cualica: 'cualicá',
  cualvos: 'cualvos',
  cualzao: 'cualzão',
  cuamata: 'cuamata',
  cuambis: 'cuambis',
  cuambos: 'cuambos',
  cuambus: 'cuambus',
  cuandas: 'cuandas',
  cuandro: 'cuandro',
  cuandus: 'cuandus',
  cuangar: 'cuangar',
  cuangas: 'cuangas',
  cuanhai: 'cuanhai',
  cuanham: 'cuanham',
  cuanhar: 'cuanhar',
  cuanhas: 'cuanhas',
  cuanhei: 'cuanhei',
  cuanhem: 'cuanhem',
  cuanhes: 'cuanhes',
  cuanhos: 'cuanhos',
  cuanhou: 'cuanhou',
  cuanzas: 'cuanzas',
  cuapada: 'cuapada',
  cuapras: 'cuapras',
  cuaraci: 'cuaraci',
  cuarlai: 'cuarlai',
  cuarlam: 'cuarlam',
  cuarlar: 'cuarlar',
  cuarlas: 'cuarlas',
  cuarlei: 'cuarlei',
  cuarlem: 'cuarlem',
  cuarles: 'cuarles',
  cuarlou: 'cuarlou',
  cuaruba: 'cuaruba',
  cuatibo: 'cuatibo',
  cuaxixa: 'cuaxixá',
  cuazuma: 'cuazuma',
  cubacao: 'cubação',
  cubadao: 'cubadão',
  cubadas: 'cubadas',
  cubador: 'cubador',
  cubados: 'cubados',
  cubagem: 'cubagem',
  cubales: 'cubales',
  cubalos: 'cubalos',
  cubamos: 'cubamos',
  cubanas: 'cubanas',
  cubando: 'cubando',
  cubanos: 'cubanos',
  cubante: 'cubante',
  cubaram: 'cubaram',
  cubarao: 'cubarão',
  cubaras: 'cubarás',
  cubarei: 'cubarei',
  cubarem: 'cubarem',
  cubares: 'cubares',
  cubaria: 'cubaria',
  cubarmo: 'cubarmo',
  cubasse: 'cubasse',
  cubaste: 'cubaste',
  cubatao: 'cubatão',
  cubatas: 'cubatas',
  cubavam: 'cubavam',
  cubavas: 'cubavas',
  cubavel: 'cubável',
  cubebas: 'cubebas',
  cubeira: 'cubeira',
  cubeiro: 'cubeiro',
  cubeita: 'cubeíta',
  cubelas: 'cubelas',
  cubelos: 'cubelos',
  cubemos: 'cubemos',
  cubense: 'cubense',
  cubetas: 'cubetas',
  cubetos: 'cubetos',
  cubicai: 'cubicai',
  cubicam: 'cubicam',
  cubicar: 'cubicar',
  cubicas: 'cúbicas',
  cubicos: 'cúbicos',
  cubicou: 'cubicou',
  cubilos: 'cubilôs',
  cubinha: 'cubinha',
  cubinho: 'cubinho',
  cubique: 'cubique',
  cubismo: 'cubismo',
  cubista: 'cubista',
  cubital: 'cubital',
  cubitos: 'cúbitos',
  cuboide: 'cuboide',
  cuboite: 'cuboíte',
  cubondo: 'cubondo',
  cubrais: 'cubrais',
  cubramo: 'cubramo',
  cucadao: 'cucadão',
  cucadas: 'cucadas',
  cucador: 'cucador',
  cucados: 'cucados',
  cucambe: 'cuçambe',
  cucamos: 'cucamos',
  cucando: 'cucando',
  cucante: 'cucante',
  cucaram: 'cucaram',
  cucarao: 'cucarão',
  cucaras: 'cucaras',
  cucarei: 'cucarei',
  cucarem: 'cucarem',
  cucares: 'cucares',
  cucaria: 'cucaria',
  cucaris: 'cuçaris',
  cucarmo: 'cucarmo',
  cucarne: 'cucarne',
  cucasse: 'cucasse',
  cucaste: 'cucaste',
  cucavam: 'cucavam',
  cucavas: 'cucavas',
  cuchada: 'cuchada',
  cuchais: 'cuchais',
  cuchila: 'cuchila',
  cuchita: 'cuchita',
  cuchite: 'cuchite',
  cuckold: 'cuckold',
  cucolue: 'cucolue',
  cucuiar: 'cucuiar',
  cucuias: 'cucuias',
  cucujos: 'cucujos',
  cuculai: 'cuculai',
  cuculam: 'cuculam',
  cucular: 'cucular',
  cuculas: 'cuculas',
  cuculea: 'cucúlea',
  cuculei: 'cuculei',
  cuculem: 'cuculem',
  cuculeo: 'cucúleo',
  cucules: 'cucules',
  cuculia: 'cucúlia',
  cuculim: 'cuculim',
  cuculou: 'cuculou',
  cucumbe: 'cucumbe',
  cucumbi: 'cucumbi',
  cucumbu: 'cucumbu',
  cucumes: 'cucumes',
  cucuras: 'cucuras',
  cucuris: 'cucuris',
  cucurus: 'cucurus',
  cucutas: 'cucutas',
  cucuzas: 'cucuzas',
  cucuzos: 'cucuzos',
  cudanas: 'cudanas',
  cudanos: 'cudanos',
  cudilim: 'cudilim',
  cudimba: 'cudimba',
  cudinha: 'cudinha',
  cudinho: 'cudinho',
  cudismo: 'cudismo',
  cudista: 'cudista',
  cudolim: 'cudolim',
  cudurru: 'cudurru',
  cuecoes: 'cuecões',
  cuecona: 'cuecona',
  cueiras: 'cueiras',
  cueiros: 'cueiros',
  cuelvas: 'cuelvas',
  cuencas: 'cuencas',
  cuenhes: 'cuenhes',
  cuenjes: 'cuenjes',
  cueretu: 'cueretu',
  cueruda: 'cueruda',
  cuerudo: 'cuerudo',
  cufadao: 'cufadão',
  cufadas: 'cufadas',
  cufador: 'cufador',
  cufados: 'cufados',
  cufaias: 'cufaias',
  cufalas: 'cufalas',
  cufamos: 'cufamos',
  cufando: 'cufando',
  cufante: 'cufante',
  cufaram: 'cufaram',
  cufarao: 'cufarão',
  cufaras: 'cufarás',
  cufarei: 'cufarei',
  cufarem: 'cufarem',
  cufares: 'cufares',
  cufaria: 'cufaria',
  cufarmo: 'cufarmo',
  cufasse: 'cufasse',
  cufaste: 'cufaste',
  cufavam: 'cufavam',
  cufavas: 'cufavas',
  cufeias: 'cufeias',
  cufemos: 'cufemos',
  cufenio: 'cufênio',
  cuficas: 'cúficas',
  cuficos: 'cúficos',
  cugares: 'cugares',
  cugiada: 'cugiada',
  cugiado: 'cugiado',
  cugiais: 'cugiais',
  cugiamo: 'cugiamo',
  cugiara: 'cugiará',
  cugiava: 'cugiava',
  cugieis: 'cugieis',
  cugiemo: 'cugiemo',
  cuguara: 'cuguará',
  cuiabem: 'cuiabém',
  cuiacas: 'cuiacas',
  cuiadas: 'cuiadas',
  cuiames: 'cuiames',
  cuianas: 'cuianas',
  cuiaras: 'cuiaras',
  cuiavem: 'cuiavém',
  cuibaba: 'cuibaba',
  cuicuro: 'cuicuro',
  cuicuru: 'cuicuru',
  cuidada: 'cuidada',
  cuidado: 'cuidado',
  cuidais: 'cuidais',
  cuidamo: 'cuidamo',
  cuidara: 'cuidara',
  cuidaru: 'cuidaru',
  cuidava: 'cuidava',
  cuideis: 'cuideis',
  cuidemo: 'cuidemo',
  cuidosa: 'cuidosa',
  cuidoso: 'cuidoso',
  cuieira: 'cuieira',
  cuiepia: 'cuiepiá',
  cuietes: 'cuietés',
  cuilada: 'cuilada',
  cuilado: 'cuilado',
  cuilais: 'cuilais',
  cuilamo: 'cuilamo',
  cuilara: 'cuilará',
  cuilava: 'cuilava',
  cuileis: 'cuileis',
  cuilemo: 'cuilemo',
  cuilili: 'cuilili',
  cuilzao: 'cuilzão',
  cuinara: 'cuinara',
  cuincar: 'cuincar',
  cuincha: 'cuincha',
  cuinche: 'cuinche',
  cuincho: 'cuincho',
  cuinhai: 'cuinhai',
  cuinham: 'cuinham',
  cuinhar: 'cuinhar',
  cuinhas: 'cuinhas',
  cuinhei: 'cuinhei',
  cuinhem: 'cuinhem',
  cuinhes: 'cuinhes',
  cuinhou: 'cuinhou',
  cuinira: 'cuinira',
  cuintau: 'cuintau',
  cuipana: 'cuipana',
  cuipeva: 'cuipeva',
  cuipuna: 'cuipuna',
  cuiques: 'cuíques',
  cuissis: 'cuíssis',
  cuitado: 'cuitado',
  cuitelo: 'cuitelo',
  cuiubas: 'cuiubas',
  cuiuira: 'cuiuira',
  cuiuras: 'cuiuras',
  cujaras: 'cujaras',
  cujelos: 'cujelos',
  cujetes: 'cujetés',
  cujiada: 'cujiada',
  cujiado: 'cujiado',
  cujiais: 'cujiais',
  cujiamo: 'cujiamo',
  cujiara: 'cujiará',
  cujiava: 'cujiava',
  cujieis: 'cujieis',
  cujiemo: 'cujiemo',
  cujinha: 'cujinha',
  cujinho: 'cujinho',
  cujubas: 'cujubas',
  cujubim: 'cujubim',
  cujubis: 'cujubis',
  cujudas: 'cujudas',
  cujudos: 'cujudos',
  culacas: 'culaças',
  culagas: 'culagas',
  culapai: 'culapai',
  culapam: 'culapam',
  culapar: 'culapar',
  culapas: 'culapas',
  culapei: 'culapei',
  culapem: 'culapem',
  culapes: 'culapes',
  culapou: 'culapou',
  culaque: 'cúlaque',
  culatra: 'culatra',
  culatre: 'culatre',
  culatro: 'culatro',
  culcada: 'culcada',
  culcado: 'culcado',
  culcais: 'culcais',
  culcamo: 'culcamo',
  culcara: 'culcará',
  culcava: 'culcava',
  culcita: 'cúlcita',
  culdeus: 'culdeus',
  culepes: 'culepes',
  culhaos: 'culhãos',
  culhoes: 'culhões',
  culhuda: 'culhuda',
  culices: 'cúlices',
  culimai: 'culimai',
  culimam: 'culimam',
  culimar: 'culimar',
  culimas: 'culimãs',
  culimei: 'culimei',
  culimem: 'culimem',
  culimes: 'culimes',
  culimou: 'culimou',
  culinas: 'culinas',
  culinos: 'culinos',
  culitas: 'culitas',
  cullens: 'cullens',
  culmens: 'cúlmens',
  culmina: 'culmina',
  culmine: 'culmine',
  culmino: 'culmino',
  culombo: 'culombó',
  culoris: 'culoris',
  culotes: 'culotes',
  culpada: 'culpada',
  culpado: 'culpado',
  culpais: 'culpais',
  culpamo: 'culpamo',
  culpara: 'culpara',
  culpava: 'culpava',
  culpeis: 'culpeis',
  culpemo: 'culpemo',
  culposa: 'culposa',
  culposo: 'culposo',
  culquei: 'culquei',
  culquem: 'culquem',
  culques: 'culques',
  cultica: 'cúltica',
  cultico: 'cúltico',
  cultiva: 'cultiva',
  cultive: 'cultive',
  cultivo: 'cultivo',
  cultora: 'cultora',
  cultros: 'cultros',
  cultuai: 'cultuai',
  cultual: 'cultual',
  cultuam: 'cultuam',
  cultuar: 'cultuar',
  cultuas: 'cultuas',
  cultuei: 'cultuei',
  cultuem: 'cultuem',
  cultues: 'cultues',
  cultuou: 'cultuou',
  cultura: 'cultura',
  culumbi: 'culumbi',
  culumim: 'culumim',
  culumis: 'culumis',
  cumacaa: 'cumacaá',
  cumacai: 'cumacaí',
  cumacas: 'cumacás',
  cumacea: 'cumácea',
  cumaceo: 'cumáceo',
  cumacos: 'cumacos',
  cumacua: 'cumacuã',
  cumafos: 'cumafós',
  cumalas: 'cumalas',
  cumanas: 'cumanas',
  cumanda: 'cumandá',
  cumanis: 'cumanis',
  cumanos: 'cumanos',
  cumaqua: 'cumaquã',
  cumaras: 'cumaras',
  cumares: 'cumarés',
  cumarim: 'cumarim',
  cumaris: 'cumaris',
  cumates: 'cumatés',
  cumatia: 'cumatiá',
  cumatis: 'cumatis',
  cumbaca: 'cumbaca',
  cumbari: 'cumbari',
  cumbaru: 'cumbaru',
  cumbeba: 'cumbeba',
  cumbeca: 'cumbeca',
  cumbias: 'cumbias',
  cumbios: 'cumbios',
  cumbira: 'cumbira',
  cumbuca: 'cumbuca',
  cumbuco: 'cumbuco',
  cumburu: 'cumburu',
  cumeada: 'cumeada',
  cumeado: 'cumeado',
  cumelas: 'cumelas',
  cumenol: 'cumenol',
  cumenos: 'cumenos',
  cumeras: 'cúmeras',
  cumerim: 'cumerim',
  cumiada: 'cumiada',
  cumiana: 'cumiana',
  cumicha: 'cumichá',
  cumidas: 'cúmidas',
  cumidea: 'cumídea',
  cumideo: 'cumídeo',
  cumilos: 'cumilos',
  cuminal: 'cuminal',
  cuminas: 'cuminas',
  cuminho: 'cuminho',
  cuminol: 'cuminol',
  cuminos: 'cuminos',
  cumixas: 'cumixás',
  cumopse: 'cumopse',
  cumpram: 'cumpram',
  cumpras: 'cumpras',
  cumprem: 'cumprem',
  cumpres: 'cumpres',
  cumpria: 'cumpria',
  cumprir: 'cumprir',
  cumpris: 'cumpris',
  cumpriu: 'cumpriu',
  cumulai: 'cumulai',
  cumulam: 'cumulam',
  cumular: 'cumular',
  cumulas: 'cumulas',
  cumulei: 'cumulei',
  cumulem: 'cumulem',
  cumules: 'cumules',
  cumulos: 'cúmulos',
  cumulou: 'cumulou',
  cunabim: 'cunabim',
  cunabis: 'cunabis',
  cunamas: 'cunamas',
  cunambi: 'cunambi',
  cunanas: 'cunanãs',
  cunanta: 'cunanta',
  cunapus: 'cunapus',
  cunarca: 'cunarca',
  cundins: 'cundins',
  cunduna: 'cunduna',
  cundura: 'cundura',
  cunduru: 'cunduru',
  cuneada: 'cuneada',
  cuneado: 'cuneado',
  cuneais: 'cuneais',
  cuneana: 'cuneana',
  cuneano: 'cuneano',
  cuneato: 'cuneato',
  cunenas: 'cunenas',
  cunetas: 'cunetas',
  cunetes: 'cunetes',
  cunfres: 'cunfres',
  cungues: 'cungues',
  cunguto: 'cunguto',
  cunhada: 'cunhada',
  cunhado: 'cunhado',
  cunhais: 'cunhais',
  cunhamo: 'cunhamo',
  cunhanu: 'cunhanu',
  cunhara: 'cunhara',
  cunhata: 'cunhatã',
  cunhava: 'cunhava',
  cunheis: 'cunheis',
  cunhemo: 'cunhemo',
  cunhete: 'cunhete',
  cunhira: 'cunhira',
  cunibas: 'cunibas',
  cunibos: 'cunibos',
  cunicas: 'cunicas',
  cunicos: 'cunicos',
  cunifes: 'cunifes',
  cunilas: 'cunilas',
  cuninas: 'cuninas',
  cunissa: 'cunissa',
  cunonas: 'cunonas',
  cunonia: 'cunônia',
  cunques: 'cunques',
  cunuaru: 'cunuaru',
  cununas: 'cununas',
  cununos: 'cununos',
  cunuris: 'cunuris',
  cunzita: 'cunzita',
  cuojelo: 'cuojelo',
  cuorina: 'cuorina',
  cuozada: 'cuozada',
  cuozado: 'cuozado',
  cuozais: 'cuozais',
  cuozamo: 'cuozamo',
  cuozara: 'cuozará',
  cuozava: 'cuozava',
  cuozeis: 'cuozeis',
  cuozemo: 'cuozemo',
  cupaiba: 'cupaíba',
  cupanas: 'cupanas',
  cupania: 'cupânia',
  cupauba: 'cupaúba',
  cupelas: 'cupelas',
  cupezes: 'cupezes',
  cupidas: 'cupidas',
  cupidez: 'cupidez',
  cupidos: 'cupidos',
  cupiuba: 'cupiúba',
  cupiuva: 'cupiúva',
  cupopia: 'cupópia',
  cuprase: 'cuprase',
  cuprato: 'cuprato',
  cupreas: 'cúpreas',
  cupreno: 'cupreno',
  cuprens: 'cupréns',
  cupreos: 'cúpreos',
  cuprico: 'cúprico',
  cuprina: 'cuprina',
  cuprino: 'cuprino',
  cuprita: 'cuprita',
  cuprite: 'cuprite',
  cuprito: 'cuprito',
  cuprois: 'cupróis',
  cuprosa: 'cuprosa',
  cuproso: 'cuproso',
  cupuacu: 'cupuaçu',
  cupuais: 'cupuaís',
  cupuaus: 'cupuaús',
  cupuins: 'cupuins',
  cupular: 'cupular',
  cupulas: 'cúpulas',
  cupulim: 'cupulim',
  cuqueai: 'cuqueai',
  cuquear: 'cuquear',
  cuqueei: 'cuqueei',
  cuqueia: 'cuqueia',
  cuqueie: 'cuqueie',
  cuqueio: 'cuqueio',
  cuqueis: 'cuqueis',
  cuquemo: 'cuquemo',
  cuqueou: 'cuqueou',
  curabas: 'curabas',
  curabis: 'curabis',
  curacao: 'curaçao',
  curacas: 'curacas',
  curacau: 'curaçau',
  curacis: 'curacis',
  curadao: 'curadão',
  curadas: 'curadas',
  curadia: 'curadia',
  curador: 'curador',
  curados: 'curados',
  curagem: 'curagem',
  curairi: 'curairi',
  curames: 'curames',
  curamos: 'curamos',
  curanau: 'curanau',
  curando: 'curando',
  curante: 'curante',
  curapai: 'curapaí',
  curapas: 'curapás',
  curapia: 'curapiá',
  curaram: 'curaram',
  curarao: 'curarão',
  curaras: 'curaras',
  curarea: 'curárea',
  curarei: 'curarei',
  curarem: 'curarem',
  curares: 'curares',
  curaria: 'curaria',
  curarmo: 'curarmo',
  curasse: 'curasse',
  curaste: 'curaste',
  curatas: 'curatás',
  curatos: 'curatos',
  curauas: 'curauás',
  curavam: 'curavam',
  curavas: 'curavas',
  curavel: 'curável',
  curaxeu: 'curaxéu',
  curcuma: 'cúrcuma',
  curcuta: 'curcuta',
  curcute: 'curcute',
  curcuti: 'curcuti',
  curcuto: 'curcuto',
  curemas: 'curemas',
  curemos: 'curemos',
  curense: 'curense',
  cureras: 'cureras',
  cureres: 'curerés',
  curetai: 'curetai',
  curetam: 'curetam',
  curetar: 'curetar',
  curetas: 'curetas',
  curetei: 'curetei',
  curetem: 'curetem',
  curetes: 'curetes',
  curetou: 'curetou',
  curetus: 'curetus',
  curgete: 'curgete',
  curiaca: 'curiaca',
  curiada: 'curiada',
  curiado: 'curiado',
  curiais: 'curiais',
  curiamo: 'curiamo',
  curiara: 'curiara',
  curiata: 'curiatã',
  curiato: 'curiató',
  curiava: 'curiava',
  curiavo: 'curiavo',
  curicas: 'curicas',
  curieis: 'curieis',
  curiemo: 'curiemo',
  curilos: 'curilos',
  curimai: 'curimaí',
  curimas: 'curimãs',
  curimba: 'curimba',
  curimbo: 'curimbó',
  curinas: 'curinas',
  curinga: 'curinga',
  curioes: 'curiões',
  curiola: 'curiola',
  curiosa: 'curiosa',
  curiose: 'curiose',
  curioso: 'curioso',
  curismo: 'curismo',
  curista: 'curista',
  curitas: 'curitas',
  curiuva: 'curiúva',
  curixao: 'curixão',
  curixas: 'curixás',
  curixos: 'curixos',
  curotes: 'curotes',
  curpuna: 'curpuna',
  currada: 'currada',
  currado: 'currado',
  currais: 'currais',
  curramo: 'curramo',
  currara: 'currará',
  currava: 'currava',
  curreis: 'curreis',
  currela: 'currela',
  currelo: 'currelo',
  curremo: 'curremo',
  currica: 'curriça',
  currios: 'cúrrios',
  currucu: 'currucu',
  cursada: 'cursada',
  cursado: 'cursado',
  cursais: 'cursais',
  cursamo: 'cursamo',
  cursara: 'cursara',
  cursava: 'cursava',
  curseai: 'curseai',
  cursear: 'cursear',
  curseei: 'curseei',
  curseia: 'curseia',
  curseie: 'curseie',
  curseio: 'curseio',
  curseis: 'curseis',
  cursemo: 'cursemo',
  curseou: 'curseou',
  curseta: 'curseta',
  cursios: 'cúrsios',
  cursiva: 'cursiva',
  cursivo: 'cursivo',
  curtais: 'curtais',
  curtamo: 'curtamo',
  curtata: 'curtata',
  curtato: 'curtato',
  curtemo: 'curtemo',
  curteza: 'curteza',
  curtiam: 'curtiam',
  curtias: 'curtias',
  curtica: 'cúrtica',
  curtico: 'cúrtico',
  curtida: 'curtida',
  curtido: 'curtido',
  curtimo: 'curtimo',
  curtins: 'curtins',
  curtira: 'curtira',
  curtose: 'curtose',
  curtume: 'curtume',
  curuaca: 'curuaca',
  curuaes: 'curuaés',
  curuaia: 'curuaia',
  curuaie: 'curuaie',
  curuais: 'curuaís',
  curuana: 'curuana',
  curuape: 'curuapé',
  curuata: 'curuatá',
  curuati: 'curuati',
  curubas: 'curubas',
  curubes: 'curubés',
  curucai: 'curucai',
  curucam: 'curucam',
  curucar: 'curucar',
  curucas: 'curucas',
  curucou: 'curucou',
  curucui: 'curucuí',
  curucus: 'curucus',
  curudiu: 'curudiú',
  curuera: 'curuera',
  curugus: 'curugus',
  curuiri: 'curuiri',
  curujos: 'curujos',
  curules: 'curules',
  curumas: 'curumãs',
  curumba: 'curumba',
  curumbi: 'curumbi',
  curumim: 'curumim',
  curumis: 'curumis',
  curundu: 'curundu',
  curungo: 'curungo',
  curunua: 'curunuá',
  curupai: 'curupaí',
  curupas: 'curupás',
  curupes: 'curupés',
  curupia: 'curupiá',
  curupus: 'curupus',
  curuque: 'curuque',
  curuqui: 'curuqui',
  cururas: 'cururás',
  cururis: 'cururis',
  cururua: 'cururuá',
  cururui: 'cururuí',
  cururus: 'cururus',
  curutas: 'curutás',
  curutie: 'curutié',
  curutus: 'curutus',
  curutzu: 'curutzu',
  curuzus: 'curuzus',
  curvaca: 'curvaça',
  curvada: 'curvada',
  curvadi: 'curvadi',
  curvado: 'curvado',
  curvais: 'curvais',
  curvamo: 'curvamo',
  curvane: 'curvane',
  curvara: 'curvara',
  curvava: 'curvava',
  curveis: 'curveis',
  curveja: 'curvejá',
  curveje: 'curveje',
  curvejo: 'curvejo',
  curvemo: 'curvemo',
  curveta: 'curveta',
  curvete: 'curvete',
  curveto: 'curveto',
  curveus: 'curvéus',
  cuscada: 'cuscada',
  cuscuta: 'cuscuta',
  cusiloi: 'cusilói',
  cusinos: 'cusinos',
  cusitas: 'cusitas',
  cuspada: 'cuspada',
  cuspais: 'cuspais',
  cuspamo: 'cuspamo',
  cuspiam: 'cuspiam',
  cuspias: 'cuspias',
  cuspida: 'cuspida',
  cuspide: 'cúspide',
  cuspido: 'cuspido',
  cuspimo: 'cuspimo',
  cuspira: 'cuspira',
  cussita: 'cussita',
  cussuca: 'cussuca',
  custada: 'custada',
  custado: 'custado',
  custais: 'custais',
  custamo: 'custamo',
  custara: 'custara',
  custava: 'custava',
  custeai: 'custeai',
  custear: 'custear',
  custeei: 'custeei',
  custeia: 'custeia',
  custeie: 'custeie',
  custeio: 'custeio',
  custeis: 'custeis',
  custemo: 'custemo',
  custeou: 'custeou',
  custode: 'custode',
  custosa: 'custosa',
  custoso: 'custoso',
  custura: 'custura',
  cutanea: 'cutânea',
  cutaneo: 'cutâneo',
  cutarda: 'cutarda',
  cutaxos: 'cutaxós',
  cutchim: 'cutchim',
  cutelao: 'cutelão',
  cutelas: 'cutelas',
  cutelos: 'cutelos',
  cutiaia: 'cutiaia',
  cutiana: 'cutiana',
  cutiano: 'cutiano',
  cutiara: 'cutiara',
  cutilai: 'cutilai',
  cutilam: 'cutilam',
  cutilao: 'cutilão',
  cutilar: 'cutilar',
  cutilas: 'cutilas',
  cutilei: 'cutilei',
  cutilem: 'cutilem',
  cutiles: 'cutiles',
  cutilou: 'cutilou',
  cutinas: 'cutinas',
  cutinha: 'cutinha',
  cutinho: 'cutinho',
  cutisai: 'cutisai',
  cutisam: 'cutisam',
  cutisar: 'cutisar',
  cutisas: 'cutisas',
  cutisei: 'cutisei',
  cutisem: 'cutisem',
  cutises: 'cutises',
  cutisou: 'cutisou',
  cutista: 'cutista',
  cutites: 'cutites',
  cutitis: 'cutitis',
  cutiuba: 'cutiúba',
  cutoses: 'cutoses',
  cutruca: 'cutruca',
  cutruco: 'cutruco',
  cutubas: 'cutubas',
  cutubea: 'cutúbea',
  cutubeo: 'cutúbeo',
  cutucai: 'cutucai',
  cutucam: 'cutucam',
  cutucao: 'cutucão',
  cutucar: 'cutucar',
  cutucas: 'cutucas',
  cutucou: 'cutucou',
  cutudem: 'cutudém',
  cutuias: 'cutuiás',
  cutulas: 'cutulás',
  cutuque: 'cutuque',
  cuvaiti: 'cuvaiti',
  cuvatas: 'cuvatãs',
  cuveira: 'cuveira',
  cuveiro: 'cuveiro',
  cuvetas: 'cuvetas',
  cuvetes: 'cuvetes',
  cuviara: 'cuviara',
  cuvicas: 'cuvicas',
  cuvicos: 'cuvicos',
  cuviera: 'cuviera',
  cuxaras: 'cuxarás',
  cuxarra: 'cuxarrá',
  cuxeris: 'cuxeris',
  cuxibes: 'cuxibes',
  cuxibis: 'cuxibis',
  cuxitas: 'cuxitas',
  cuzadas: 'cuzadas',
  cxcviii: 'cxcviii',
  cxlviii: 'cxlviii',
  cxxviii: 'cxxviii',
  cxxxiii: 'cxxxiii',
  cxxxvii: 'cxxxvii',
  czadora: 'czadora',
  czardas: 'czardas',
  czarina: 'czarina',
  dabadas: 'dabadas',
  dabecia: 'dabécia',
  dabelos: 'dabelos',
  dabemas: 'dabemas',
  dabitis: 'dábitis',
  dablios: 'dáblios',
  dablius: 'dáblius',
  daboias: 'daboias',
  dabulas: 'dabulas',
  dabutos: 'dabutos',
  dacares: 'dacares',
  dacarum: 'daçarum',
  dacelos: 'dacelos',
  dachani: 'dachani',
  dachens: 'dachéns',
  dacicas: 'dácicas',
  dacicos: 'dácicos',
  dacilos: 'dacilos',
  dacisco: 'dacisco',
  dacitas: 'dacitas',
  dacites: 'dacites',
  dacitos: 'dacitos',
  dacolas: 'dacolás',
  dacomas: 'dacomas',
  daconas: 'daçonas',
  dacotas: 'dacotas',
  dacrica: 'dácrica',
  dacrico: 'dácrico',
  dacrina: 'dacrina',
  dacrion: 'dácrion',
  dacrios: 'dácrios',
  dacrons: 'dácrons',
  dactila: 'dáctila',
  dactile: 'dáctile',
  dactilo: 'dactilo',
  dadanes: 'dadanes',
  dadeira: 'dadeira',
  dadinha: 'dadinha',
  dadinho: 'dadinho',
  dadivai: 'dadivai',
  dadivam: 'dadivam',
  dadivar: 'dadivar',
  dadivas: 'dádivas',
  dadivei: 'dadivei',
  dadivem: 'dadivem',
  dadives: 'dadives',
  dadivou: 'dadivou',
  dadonas: 'dadonas',
  dadoras: 'dadoras',
  dadores: 'dadores',
  dafnias: 'dáfnias',
  dafnina: 'dafnina',
  dafnite: 'dafnite',
  daganha: 'daganha',
  dagussa: 'dagussa',
  dahlina: 'dahlina',
  daiaque: 'daiaque',
  daimios: 'dáimios',
  daimosa: 'daimosa',
  daimoso: 'daimoso',
  dainaca: 'dainaca',
  daineca: 'daineca',
  dairena: 'dairena',
  dalasis: 'dalasis',
  dalguem: 'dalguém',
  dalguma: 'dalguma',
  dalguns: 'dalguns',
  dalinas: 'dalinas',
  dalmata: 'dálmata',
  damaras: 'damaras',
  damares: 'dâmares',
  damaria: 'damaria',
  damasco: 'damasco',
  dambira: 'dambirá',
  dambora: 'damborá',
  damejai: 'damejai',
  damejam: 'damejam',
  damejar: 'damejar',
  damejas: 'damejas',
  damejei: 'damejei',
  damejem: 'damejem',
  damejes: 'damejes',
  damejou: 'damejou',
  damiana: 'damiana',
  damices: 'damices',
  damismo: 'damismo',
  damista: 'damista',
  damnoso: 'damnoso',
  danacao: 'danação',
  danacas: 'dânacas',
  danadao: 'danadão',
  danadas: 'danadas',
  danador: 'danador',
  danados: 'danados',
  danaida: 'danaida',
  danaide: 'danaide',
  danamos: 'danamos',
  danando: 'danando',
  danante: 'danante',
  danaram: 'danaram',
  danarao: 'danarão',
  danaras: 'danaras',
  danarei: 'danarei',
  danarem: 'danarem',
  danares: 'danares',
  danaria: 'danaria',
  danarmo: 'danarmo',
  danasse: 'danasse',
  danaste: 'danaste',
  danavam: 'danavam',
  danavas: 'danavas',
  danazol: 'danazol',
  dancada: 'dançada',
  dancado: 'dançado',
  dancais: 'dançais',
  dancamo: 'dançamo',
  dancara: 'dançará',
  dancata: 'dançata',
  dancava: 'dançava',
  danceis: 'danceis',
  dancemo: 'dancemo',
  dancing: 'dancing',
  dandina: 'dandina',
  dandine: 'dandine',
  dandino: 'dandino',
  dandoes: 'dandões',
  danemos: 'danemos',
  danesas: 'danesas',
  daneses: 'daneses',
  daninha: 'daninha',
  daninhe: 'daninhe',
  daninho: 'daninho',
  danisco: 'danisco',
  danismo: 'danismo',
  danista: 'danista',
  danitas: 'danitas',
  danosas: 'danosas',
  danosos: 'danosos',
  dantica: 'dântica',
  dantico: 'dântico',
  dapsona: 'dapsona',
  daquela: 'daquela',
  daquele: 'daquele',
  daquens: 'daquéns',
  daquilo: 'daquilo',
  dardada: 'dardada',
  dardado: 'dardado',
  dardais: 'dardais',
  dardamo: 'dardamo',
  dardara: 'dardara',
  dardava: 'dardava',
  dardeis: 'dardeis',
  dardeja: 'dardeja',
  dardeje: 'dardeje',
  dardejo: 'dardejo',
  dardemo: 'dardemo',
  daremos: 'daremos',
  darieis: 'daríeis',
  darista: 'darista',
  daroesa: 'daroesa',
  darsana: 'darsana',
  dartoso: 'dartoso',
  dartros: 'dartros',
  dasiuro: 'dasiúro',
  datacao: 'datação',
  datadao: 'datadão',
  datadas: 'datadas',
  datador: 'datador',
  datados: 'datados',
  datamos: 'datamos',
  datando: 'datando',
  datante: 'datante',
  dataram: 'dataram',
  datarao: 'datarão',
  dataras: 'dataras',
  datarei: 'datarei',
  datarem: 'datarem',
  datares: 'datares',
  dataria: 'dataria',
  datario: 'datário',
  datarmo: 'datarmo',
  datasse: 'datasse',
  dataste: 'dataste',
  datavam: 'datavam',
  datavas: 'datavas',
  datavel: 'datável',
  datemos: 'datemos',
  datense: 'datense',
  datilar: 'datilar',
  datilas: 'dátilas',
  datilos: 'dátilos',
  datisca: 'datisca',
  datismo: 'datismo',
  datista: 'datista',
  dativas: 'dativas',
  dativos: 'dativos',
  daturas: 'daturas',
  davalia: 'davália',
  davamos: 'dávamos',
  dazibao: 'dazibao',
  dccciii: 'dccciii',
  dccclii: 'dccclii',
  dcccliv: 'dcccliv',
  dccclix: 'dccclix',
  dccclvi: 'dccclvi',
  dccclxi: 'dccclxi',
  dccclxv: 'dccclxv',
  dccclxx: 'dccclxx',
  dcccvii: 'dcccvii',
  dcccxci: 'dcccxci',
  dcccxcv: 'dcccxcv',
  dcccxii: 'dcccxii',
  dcccxiv: 'dcccxiv',
  dcccxix: 'dcccxix',
  dcccxli: 'dcccxli',
  dcccxlv: 'dcccxlv',
  dcccxvi: 'dcccxvi',
  dcccxxi: 'dcccxxi',
  dcccxxv: 'dcccxxv',
  dcccxxx: 'dcccxxx',
  dccliii: 'dccliii',
  dcclvii: 'dcclvii',
  dcclxii: 'dcclxii',
  dcclxiv: 'dcclxiv',
  dcclxix: 'dcclxix',
  dcclxvi: 'dcclxvi',
  dcclxxi: 'dcclxxi',
  dcclxxv: 'dcclxxv',
  dcclxxx: 'dcclxxx',
  dccviii: 'dccviii',
  dccxcii: 'dccxcii',
  dccxciv: 'dccxciv',
  dccxcix: 'dccxcix',
  dccxcvi: 'dccxcvi',
  dccxiii: 'dccxiii',
  dccxlii: 'dccxlii',
  dccxliv: 'dccxliv',
  dccxlix: 'dccxlix',
  dccxlvi: 'dccxlvi',
  dccxvii: 'dccxvii',
  dccxxii: 'dccxxii',
  dccxxiv: 'dccxxiv',
  dccxxix: 'dccxxix',
  dccxxvi: 'dccxxvi',
  dccxxxi: 'dccxxxi',
  dccxxxv: 'dccxxxv',
  dclviii: 'dclviii',
  dclxiii: 'dclxiii',
  dclxvii: 'dclxvii',
  dclxxii: 'dclxxii',
  dclxxiv: 'dclxxiv',
  dclxxix: 'dclxxix',
  dclxxvi: 'dclxxvi',
  dclxxxi: 'dclxxxi',
  dclxxxv: 'dclxxxv',
  dcxciii: 'dcxciii',
  dcxcvii: 'dcxcvii',
  dcxliii: 'dcxliii',
  dcxlvii: 'dcxlvii',
  dcxviii: 'dcxviii',
  dcxxiii: 'dcxxiii',
  dcxxvii: 'dcxxvii',
  dcxxxii: 'dcxxxii',
  dcxxxiv: 'dcxxxiv',
  dcxxxix: 'dcxxxix',
  dcxxxvi: 'dcxxxvi',
  dealbai: 'dealbai',
  dealbam: 'dealbam',
  dealbar: 'dealbar',
  dealbas: 'dealbas',
  dealbei: 'dealbei',
  dealbem: 'dealbem',
  dealbes: 'dealbes',
  dealbou: 'dealbou',
  dealvai: 'dealvai',
  dealvam: 'dealvam',
  dealvar: 'dealvar',
  dealvas: 'dealvas',
  dealvei: 'dealvei',
  dealvem: 'dealvem',
  dealves: 'dealves',
  dealvou: 'dealvou',
  deamons: 'deamons',
  deanois: 'deanóis',
  deaozao: 'deãozão',
  deaurai: 'deaurai',
  deauram: 'deauram',
  deaurar: 'deaurar',
  deauras: 'deauras',
  deaurei: 'deaurei',
  deaurem: 'deaurem',
  deaures: 'deaures',
  deaurou: 'deaurou',
  deazona: 'deãzona',
  debacai: 'debacai',
  debacam: 'debacam',
  debacar: 'debacar',
  debacas: 'debacas',
  debacle: 'debacle',
  debacou: 'debacou',
  debadao: 'debadão',
  debadas: 'debadas',
  debador: 'debador',
  debados: 'debados',
  debagai: 'debagai',
  debagam: 'debagam',
  debagar: 'debagar',
  debagas: 'debagas',
  debagou: 'debagou',
  debague: 'debague',
  debaixo: 'debaixo',
  debalde: 'debalde',
  debamos: 'debamos',
  debanar: 'debanar',
  debanda: 'debanda',
  debande: 'debande',
  debando: 'debando',
  debanga: 'debangá',
  debango: 'debango',
  debante: 'debante',
  debaque: 'debaque',
  debaram: 'debaram',
  debarao: 'debarão',
  debaras: 'debarás',
  debarei: 'debarei',
  debarem: 'debarem',
  debares: 'debares',
  debaria: 'debaria',
  debarmo: 'debarmo',
  debasse: 'debasse',
  debaste: 'debaste',
  debatam: 'debatam',
  debatas: 'debatas',
  debatei: 'debatei',
  debatem: 'debatem',
  debater: 'debater',
  debates: 'debates',
  debateu: 'debateu',
  debatia: 'debatia',
  debavam: 'debavam',
  debavas: 'debavas',
  debelai: 'debelai',
  debelam: 'debelam',
  debelar: 'debelar',
  debelas: 'debelas',
  debelei: 'debelei',
  debelem: 'debelem',
  debeles: 'debeles',
  debelou: 'debelou',
  debemos: 'debemos',
  debicai: 'debicai',
  debicam: 'debicam',
  debicar: 'debicar',
  debicas: 'debicas',
  debicou: 'debicou',
  debique: 'debique',
  debitai: 'debitai',
  debitam: 'debitam',
  debitar: 'debitar',
  debitas: 'debitas',
  debitei: 'debitei',
  debitem: 'debitem',
  debites: 'debites',
  debitos: 'débitos',
  debitou: 'debitou',
  debocha: 'debocha',
  deboche: 'deboche',
  debocho: 'debocho',
  deboica: 'deboiçá',
  deboice: 'deboice',
  deboico: 'deboiço',
  deborca: 'deborca',
  deborco: 'deborco',
  deborda: 'deborda',
  deborde: 'deborde',
  debordo: 'debordo',
  debotai: 'debotai',
  debotam: 'debotam',
  debotar: 'debotar',
  debotas: 'debotas',
  debotei: 'debotei',
  debotem: 'debotem',
  debotes: 'debotes',
  debotou: 'debotou',
  debouca: 'debouçá',
  debouce: 'debouce',
  debouco: 'debouço',
  debrasa: 'debrasa',
  debrase: 'debrase',
  debraso: 'debraso',
  debreai: 'debreai',
  debrear: 'debrear',
  debreei: 'debreei',
  debreia: 'debreia',
  debreie: 'debreie',
  debreio: 'debreio',
  debreou: 'debreou',
  debrida: 'debrida',
  debride: 'debride',
  debrido: 'debrido',
  debruai: 'debruai',
  debruam: 'debruam',
  debruar: 'debruar',
  debruas: 'debruas',
  debruca: 'debruça',
  debruce: 'debruce',
  debruco: 'debruço',
  debruei: 'debruei',
  debruem: 'debruem',
  debrues: 'debrues',
  debruou: 'debruou',
  debugar: 'debugar',
  debulha: 'debulha',
  debulhe: 'debulhe',
  debulho: 'debulho',
  debutai: 'debutai',
  debutam: 'debutam',
  debutar: 'debutar',
  debutas: 'debutas',
  debutei: 'debutei',
  debutem: 'debutem',
  debutes: 'debutes',
  debutou: 'debutou',
  debuxai: 'debuxai',
  debuxam: 'debuxam',
  debuxar: 'debuxar',
  debuxas: 'debuxas',
  debuxei: 'debuxei',
  debuxem: 'debuxem',
  debuxes: 'debuxes',
  debuxos: 'debuxos',
  debuxou: 'debuxou',
  decadas: 'décadas',
  decaiam: 'decaiam',
  decaias: 'decaias',
  decaida: 'decaída',
  decaido: 'decaído',
  decaimo: 'decaímo',
  decaira: 'decairá',
  decalca: 'decalca',
  decalco: 'decalco',
  decalva: 'decalvá',
  decalve: 'decalve',
  decalvo: 'decalvo',
  decampa: 'decampa',
  decampe: 'decampe',
  decampo: 'decampo',
  decanal: 'decanal',
  decanas: 'decanas',
  decania: 'decania',
  decanim: 'decanim',
  decanis: 'decanis',
  decanol: 'decanol',
  decanos: 'decanos',
  decanta: 'decanta',
  decante: 'decante',
  decanto: 'decanto',
  decapai: 'decapai',
  decapam: 'decapam',
  decapar: 'decapar',
  decapas: 'decapas',
  decapei: 'decapei',
  decapem: 'decapem',
  decapes: 'decapes',
  decapou: 'decapou',
  decatlo: 'decatlo',
  dececao: 'deceção',
  deceina: 'deceina',
  deceine: 'deceine',
  deceino: 'deceino',
  decenal: 'decenal',
  decenio: 'decênio',
  decente: 'decente',
  decepai: 'decepai',
  decepam: 'decepam',
  decepar: 'decepar',
  decepas: 'decepas',
  decepei: 'decepei',
  decepem: 'decepem',
  decepes: 'decepes',
  decepou: 'decepou',
  decerta: 'decerta',
  decerte: 'decerte',
  decerto: 'decerto',
  decesso: 'decesso',
  deciare: 'deciare',
  decibel: 'decibel',
  decidam: 'decidam',
  decidas: 'decidas',
  decidem: 'decidem',
  decides: 'decides',
  decidia: 'decidia',
  decidir: 'decidir',
  decidis: 'decidis',
  decidiu: 'decidiu',
  decidua: 'decídua',
  deciduo: 'decíduo',
  decifra: 'decifra',
  decifre: 'decifre',
  decifro: 'decifro',
  decimai: 'decimai',
  decimal: 'decimal',
  decimam: 'decimam',
  decimar: 'decimar',
  decimas: 'décimas',
  decimei: 'decimei',
  decimem: 'decimem',
  decimes: 'decimes',
  decimos: 'décimos',
  decimou: 'decimou',
  decisao: 'decisão',
  decisor: 'decisor',
  declama: 'declama',
  declame: 'declame',
  declamo: 'declamo',
  declara: 'declara',
  declare: 'declare',
  declaro: 'declaro',
  declina: 'declina',
  decline: 'decline',
  declino: 'declino',
  decliva: 'decliva',
  declive: 'declive',
  declivo: 'declivo',
  decoada: 'decoada',
  decoado: 'decoado',
  decoais: 'decoais',
  decoamo: 'decoamo',
  decoara: 'decoara',
  decoava: 'decoava',
  decocao: 'decoção',
  decocto: 'decocto',
  decoeis: 'decoeis',
  decoemo: 'decoemo',
  decolai: 'decolai',
  decolam: 'decolam',
  decolar: 'decolar',
  decolas: 'decolas',
  decolei: 'decolei',
  decolem: 'decolem',
  decoles: 'decoles',
  decolou: 'decolou',
  decompo: 'decompô',
  decorai: 'decorai',
  decoral: 'decoral',
  decoram: 'decoram',
  decorar: 'decorar',
  decoras: 'decoras',
  decorei: 'decorei',
  decorem: 'decorem',
  decores: 'decores',
  decoros: 'decoros',
  decorou: 'decorou',
  decorra: 'decorra',
  decorre: 'decorre',
  decorri: 'decorri',
  decorro: 'decorro',
  decotai: 'decotai',
  decotam: 'decotam',
  decotao: 'decotão',
  decotar: 'decotar',
  decotas: 'decotas',
  decotei: 'decotei',
  decotem: 'decotem',
  decotes: 'decotes',
  decotou: 'decotou',
  decreta: 'decreta',
  decrete: 'decrete',
  decreto: 'decreto',
  decroar: 'decroar',
  decruai: 'decruai',
  decruam: 'decruam',
  decruar: 'decruar',
  decruas: 'decruas',
  decruei: 'decruei',
  decruem: 'decruem',
  decrues: 'decrues',
  decruou: 'decruou',
  dectica: 'déctica',
  dectico: 'déctico',
  decupai: 'decupai',
  decupam: 'decupam',
  decupar: 'decupar',
  decupas: 'decupas',
  decupei: 'decupei',
  decupem: 'decupem',
  decupes: 'decupes',
  decupla: 'décupla',
  decuple: 'decuple',
  decuplo: 'décuplo',
  decupou: 'decupou',
  decuria: 'decúria',
  decurie: 'decurie',
  decurio: 'decurio',
  decursa: 'decursá',
  decurse: 'decurse',
  decurso: 'decurso',
  decussa: 'decussa',
  decusse: 'decusse',
  decusso: 'decusso',
  dedadao: 'dedadão',
  dedadas: 'dedadas',
  dedador: 'dedador',
  dedados: 'dedados',
  dedalas: 'dédalas',
  dedalea: 'dedálea',
  dedaleo: 'dedáleo',
  dedalos: 'dédalos',
  dedamos: 'dedamos',
  dedando: 'dedando',
  dedante: 'dedante',
  dedaram: 'dedaram',
  dedarao: 'dedarão',
  dedaras: 'dedarás',
  dedarei: 'dedarei',
  dedarem: 'dedarem',
  dedares: 'dedares',
  dedaria: 'dedaria',
  dedarmo: 'dedarmo',
  dedasse: 'dedasse',
  dedaste: 'dedaste',
  dedavam: 'dedavam',
  dedavas: 'dedavas',
  dedeira: 'dedeira',
  dedemos: 'dedemos',
  dedicai: 'dedicai',
  dedicam: 'dedicam',
  dedicar: 'dedicar',
  dedicas: 'dedicas',
  dedicou: 'dedicou',
  dedigna: 'dedigna',
  dedigne: 'dedigne',
  dedigno: 'dedigno',
  dedilha: 'dedilha',
  dedilhe: 'dedilhe',
  dedilho: 'dedilho',
  dedinha: 'dedinha',
  dedinho: 'dedinho',
  dedique: 'dedique',
  deducao: 'dedução',
  dedurai: 'dedurai',
  deduram: 'deduram',
  dedurar: 'dedurar',
  deduras: 'deduras',
  dedurei: 'dedurei',
  dedurem: 'dedurem',
  dedures: 'dedures',
  dedurou: 'dedurou',
  deduzam: 'deduzam',
  deduzas: 'deduzas',
  deduzem: 'deduzem',
  deduzes: 'deduzes',
  deduzia: 'deduzia',
  deduzir: 'deduzir',
  deduzis: 'deduzis',
  deduziu: 'deduziu',
  defasai: 'defasai',
  defasam: 'defasam',
  defasar: 'defasar',
  defasas: 'defasas',
  defasei: 'defasei',
  defasem: 'defasem',
  defases: 'defases',
  defasou: 'defasou',
  default: 'default',
  defecai: 'defecai',
  defecam: 'defecam',
  defecao: 'defeção',
  defecar: 'defecar',
  defecas: 'defecas',
  defecou: 'defecou',
  defeito: 'defeito',
  defenda: 'defenda',
  defende: 'defende',
  defendi: 'defendi',
  defendo: 'defendo',
  defensa: 'defensa',
  defense: 'defense',
  defenso: 'defenso',
  defeque: 'defeque',
  deferem: 'deferem',
  deferes: 'deferes',
  deferia: 'deferia',
  deferir: 'deferir',
  deferis: 'deferis',
  deferiu: 'deferiu',
  defesas: 'defesas',
  defesos: 'defesos',
  defessa: 'defessa',
  defesso: 'defesso',
  defetos: 'defetos',
  defices: 'défices',
  deficit: 'déficit',
  definam: 'definam',
  definas: 'definas',
  definem: 'definem',
  defines: 'defines',
  definha: 'definha',
  definhe: 'definhe',
  definho: 'definho',
  definia: 'definia',
  definir: 'definir',
  definis: 'definis',
  definiu: 'definiu',
  defiram: 'defiram',
  defiras: 'defiras',
  deflaga: 'deflaga',
  deflago: 'deflago',
  deflete: 'deflete',
  defleti: 'defleti',
  deflexo: 'deflexo',
  deflita: 'deflita',
  deflito: 'deflito',
  deflora: 'deflora',
  deflore: 'deflore',
  defloro: 'defloro',
  defluam: 'defluam',
  defluas: 'defluas',
  defluem: 'defluem',
  defluia: 'defluía',
  defluir: 'defluir',
  defluis: 'defluis',
  defluiu: 'defluiu',
  defluxo: 'defluxo',
  deforma: 'deforma',
  deforme: 'deforme',
  deformo: 'deformo',
  defumai: 'defumai',
  defumam: 'defumam',
  defumar: 'defumar',
  defumas: 'defumas',
  defumei: 'defumei',
  defumem: 'defumem',
  defumes: 'defumes',
  defumou: 'defumou',
  defunta: 'defunta',
  defunte: 'defunte',
  defunto: 'defunto',
  deganha: 'deganha',
  deganho: 'deganho',
  degasai: 'degasai',
  degasam: 'degasam',
  degasar: 'degasar',
  degasas: 'degasas',
  degasei: 'degasei',
  degasem: 'degasem',
  degases: 'degases',
  degasou: 'degasou',
  degasta: 'degasta',
  degaste: 'degaste',
  degasto: 'degasto',
  degelai: 'degelai',
  degelam: 'degelam',
  degelar: 'degelar',
  degelas: 'degelas',
  degelei: 'degelei',
  degelem: 'degelem',
  degeles: 'degeles',
  degelou: 'degelou',
  degluta: 'degluta',
  deglute: 'deglute',
  degluti: 'degluti',
  degluto: 'degluto',
  degolai: 'degolai',
  degolam: 'degolam',
  degolar: 'degolar',
  degolas: 'degolas',
  degolei: 'degolei',
  degolem: 'degolem',
  degoles: 'degoles',
  degolou: 'degolou',
  degotai: 'degotai',
  degotam: 'degotam',
  degotar: 'degotar',
  degotas: 'degotas',
  degotei: 'degotei',
  degotem: 'degotem',
  degotes: 'degotes',
  degotou: 'degotou',
  degrada: 'degrada',
  degrade: 'degradê',
  degrado: 'degrado',
  degrana: 'degrana',
  degrane: 'degrane',
  degrano: 'degrano',
  degraus: 'degraus',
  degreda: 'degreda',
  degrede: 'degrede',
  degredo: 'degredo',
  deguebo: 'deguebo',
  degusta: 'degusta',
  deguste: 'deguste',
  degusto: 'degusto',
  deicida: 'deicida',
  deicola: 'deícola',
  deidade: 'deidade',
  deifica: 'deifica',
  deifico: 'deífico',
  deionas: 'deionas',
  deipara: 'deípara',
  deismos: 'deísmos',
  deistas: 'deístas',
  deitada: 'deitada',
  deitado: 'deitado',
  deitais: 'deitais',
  deitamo: 'deitamo',
  deitara: 'deitara',
  deitava: 'deitava',
  deiteis: 'deiteis',
  deitemo: 'deitemo',
  deitica: 'dêitica',
  deitico: 'dêitico',
  deixada: 'deixada',
  deixado: 'deixado',
  deixais: 'deixais',
  deixamo: 'deixamo',
  deixara: 'deixará',
  deixava: 'deixava',
  deixeis: 'deixeis',
  deixemo: 'deixemo',
  dejecao: 'dejeção',
  dejecto: 'dejecto',
  dejejua: 'dejejua',
  dejejue: 'dejejue',
  dejejum: 'dejejum',
  dejejuo: 'dejejuo',
  dejetai: 'dejetai',
  dejetam: 'dejetam',
  dejetar: 'dejetar',
  dejetas: 'dejetas',
  dejetei: 'dejetei',
  dejetem: 'dejetem',
  dejetes: 'dejetes',
  dejetos: 'dejetos',
  dejetou: 'dejetou',
  dejunge: 'dejunge',
  dejungi: 'dejungi',
  dejunja: 'dejunja',
  dejunjo: 'dejunjo',
  dejurio: 'dejúrio',
  delacao: 'delação',
  delamba: 'delamba',
  delambe: 'delambe',
  delambi: 'delambi',
  delambo: 'delambo',
  delatai: 'delatai',
  delatam: 'delatam',
  delatar: 'delatar',
  delatas: 'delatas',
  delatei: 'delatei',
  delatem: 'delatem',
  delates: 'delates',
  delator: 'delator',
  delatou: 'delatou',
  delecao: 'deleção',
  delegai: 'delegai',
  delegam: 'delegam',
  delegar: 'delegar',
  delegas: 'delegas',
  delegou: 'delegou',
  delegue: 'delegue',
  deleita: 'deleita',
  deleite: 'deleite',
  deleito: 'deleito',
  deleixo: 'deleixo',
  deletai: 'deletai',
  deletam: 'deletam',
  deletar: 'deletar',
  deletas: 'deletas',
  deletei: 'deletei',
  deletem: 'deletem',
  deletes: 'deletes',
  deletou: 'deletou',
  delevel: 'delével',
  delfica: 'délfica',
  delfico: 'délfico',
  delfina: 'delfina',
  delfino: 'delfino',
  delfins: 'delfins',
  delftia: 'delftia',
  delgaca: 'delgaçá',
  delgace: 'delgace',
  delgaco: 'delgaço',
  delgada: 'delgada',
  delgado: 'delgado',
  deliaco: 'delíaco',
  delibai: 'delibai',
  delibam: 'delibam',
  delibar: 'delibar',
  delibas: 'delibas',
  delibei: 'delibei',
  delibem: 'delibem',
  delibes: 'delibes',
  delibou: 'delibou',
  delicas: 'délicas',
  delicia: 'delicia',
  delicie: 'delicie',
  delicio: 'delicio',
  delicos: 'délicos',
  delidao: 'delidão',
  delidas: 'delidas',
  delidor: 'delidor',
  delidos: 'delidos',
  delieis: 'delíeis',
  deligai: 'deligai',
  deligam: 'deligam',
  deligar: 'deligar',
  deligas: 'deligas',
  deligou: 'deligou',
  deligue: 'deligue',
  delimos: 'delimos',
  delindo: 'delindo',
  delinea: 'delineá',
  delinga: 'delingá',
  delingo: 'delingo',
  deliqua: 'deliquá',
  delique: 'delíque',
  deliquo: 'delíquo',
  delirai: 'delirai',
  deliram: 'deliram',
  delirao: 'delirão',
  delirar: 'delirar',
  deliras: 'deliras',
  delirei: 'delirei',
  delirem: 'delirem',
  delires: 'delires',
  deliria: 'deliria',
  delirio: 'delírio',
  delirou: 'delirou',
  delisse: 'delisse',
  deliste: 'deliste',
  delitos: 'delitos',
  delivra: 'delivra',
  delivre: 'delivre',
  delivro: 'delivro',
  delogum: 'delogum',
  delonga: 'delonga',
  delongo: 'delongo',
  deltota: 'deltota',
  deltoto: 'deltoto',
  delubro: 'delubro',
  delucia: 'delúcia',
  deludam: 'deludam',
  deludas: 'deludas',
  deludem: 'deludem',
  deludes: 'deludes',
  deludia: 'deludia',
  deludir: 'deludir',
  deludis: 'deludis',
  deludiu: 'deludiu',
  delusao: 'delusão',
  delusas: 'delusas',
  delusor: 'delusor',
  delusos: 'delusos',
  deluzir: 'deluzir',
  demanda: 'demanda',
  demande: 'demande',
  demando: 'demando',
  demarca: 'demarca',
  demarco: 'demarco',
  demasia: 'demasia',
  demasie: 'demasie',
  demasio: 'demasio',
  dembada: 'dembada',
  dembado: 'dembado',
  demeada: 'demeada',
  demeado: 'demeado',
  demeais: 'demeais',
  demeara: 'demeara',
  demeava: 'demeava',
  demeeis: 'demeeis',
  demeiam: 'demeiam',
  demeias: 'demeias',
  demeiem: 'demeiem',
  demeies: 'demeies',
  demenso: 'demenso',
  dementa: 'dementa',
  demente: 'demente',
  demento: 'demento',
  demerso: 'demerso',
  demicas: 'dêmicas',
  demicos: 'dêmicos',
  demissa: 'demissa',
  demisso: 'demisso',
  demitam: 'demitam',
  demitas: 'demitas',
  demitem: 'demitem',
  demites: 'demites',
  demitia: 'demitia',
  demitir: 'demitir',
  demitis: 'demitis',
  demitiu: 'demitiu',
  demodes: 'démodés',
  demodex: 'dêmodex',
  demolem: 'demolem',
  demoles: 'demoles',
  demolha: 'demolha',
  demolhe: 'demolhe',
  demolho: 'demolho',
  demolia: 'demolia',
  demolir: 'demolir',
  demolis: 'demolis',
  demoliu: 'demoliu',
  demonho: 'demonho',
  demonia: 'demônia',
  demonio: 'demônio',
  demorai: 'demorai',
  demoram: 'demoram',
  demorar: 'demorar',
  demoras: 'demoras',
  demorei: 'demorei',
  demorem: 'demorem',
  demores: 'demores',
  demorou: 'demorou',
  demovam: 'demovam',
  demovas: 'demovas',
  demovei: 'demovei',
  demovem: 'demovem',
  demover: 'demover',
  demoves: 'demoves',
  demoveu: 'demoveu',
  demovia: 'demovia',
  demovim: 'demovim',
  demovir: 'demovir',
  demudai: 'demudai',
  demudam: 'demudam',
  demudar: 'demudar',
  demudas: 'demudas',
  demudei: 'demudei',
  demudem: 'demudem',
  demudes: 'demudes',
  demudou: 'demudou',
  demulca: 'demulça',
  demulce: 'demulce',
  demulci: 'demulci',
  demulco: 'demulço',
  demulos: 'demulos',
  denario: 'denário',
  dendria: 'dendria',
  dendros: 'dendros',
  denegai: 'denegai',
  denegam: 'denegam',
  denegar: 'denegar',
  denegas: 'denegas',
  denegou: 'denegou',
  denegri: 'denegri',
  denegue: 'denegue',
  denerva: 'denerva',
  denerve: 'denerve',
  denervo: 'denervo',
  dengais: 'dengais',
  dengamo: 'dengamo',
  dengosa: 'dengosa',
  dengoso: 'dengoso',
  denguei: 'denguei',
  denguem: 'denguem',
  denguer: 'denguer',
  dengues: 'dengues',
  dengueu: 'dengueu',
  denguia: 'denguia',
  denguim: 'denguim',
  denigra: 'denigra',
  denigre: 'denigre',
  denigri: 'denigri',
  denigro: 'denigro',
  denodai: 'denodai',
  denodam: 'denodam',
  denodar: 'denodar',
  denodas: 'denodas',
  denodei: 'denodei',
  denodem: 'denodem',
  denodes: 'denodes',
  denodos: 'denodos',
  denodou: 'denodou',
  denotai: 'denotai',
  denotam: 'denotam',
  denotar: 'denotar',
  denotas: 'denotas',
  denotei: 'denotei',
  denotem: 'denotem',
  denotes: 'denotes',
  denotou: 'denotou',
  densada: 'densada',
  densado: 'densado',
  densais: 'densais',
  densamo: 'densamo',
  densara: 'densará',
  densava: 'densava',
  denseis: 'denseis',
  densemo: 'densemo',
  dentada: 'dentada',
  dentado: 'dentado',
  dentais: 'dentais',
  dentama: 'dentama',
  dentamo: 'dentamo',
  dentara: 'dentará',
  dentava: 'dentava',
  denteai: 'denteai',
  dentear: 'dentear',
  denteei: 'denteei',
  denteia: 'denteia',
  denteie: 'denteie',
  denteio: 'denteio',
  denteis: 'denteis',
  dentela: 'dentelá',
  dentele: 'dentele',
  dentelo: 'dentelo',
  dentemo: 'dentemo',
  denteou: 'denteou',
  dentice: 'dêntice',
  dentina: 'dentina',
  dentoes: 'dentões',
  dentola: 'dentola',
  dentoma: 'dentoma',
  dentona: 'dentona',
  dentuca: 'dentuça',
  dentuco: 'dentuço',
  dentuda: 'dentuda',
  dentudo: 'dentudo',
  denudai: 'denudai',
  denudam: 'denudam',
  denudar: 'denudar',
  denudas: 'denudas',
  denudei: 'denudei',
  denudem: 'denudem',
  denudes: 'denudes',
  denudou: 'denudou',
  denutra: 'denutra',
  denutre: 'denutre',
  denutri: 'denutri',
  denutro: 'denutro',
  deontos: 'deontos',
  deparai: 'deparai',
  deparam: 'deparam',
  deparar: 'deparar',
  deparas: 'deparas',
  deparei: 'deparei',
  deparem: 'deparem',
  depares: 'depares',
  deparou: 'deparou',
  departa: 'departa',
  departe: 'departe',
  departi: 'departi',
  departo: 'departo',
  depelar: 'depelar',
  depenai: 'depenai',
  depenam: 'depenam',
  depenar: 'depenar',
  depenas: 'depenas',
  dependa: 'dependa',
  depende: 'depende',
  dependi: 'dependi',
  dependo: 'dependo',
  depenei: 'depenei',
  depenem: 'depenem',
  depenes: 'depenes',
  depenou: 'depenou',
  deperda: 'deperda',
  deperde: 'deperde',
  deperdi: 'deperdi',
  deperdo: 'deperdo',
  depilai: 'depilai',
  depilam: 'depilam',
  depilar: 'depilar',
  depilas: 'depilas',
  depilei: 'depilei',
  depilem: 'depilem',
  depiles: 'depiles',
  depilou: 'depilou',
  deplora: 'deplora',
  deplore: 'deplore',
  deploro: 'deploro',
  depluma: 'depluma',
  deplume: 'deplume',
  deplumo: 'deplumo',
  depolir: 'depolir',
  depolma: 'depolma',
  depolme: 'depolme',
  depolmo: 'depolmo',
  depomos: 'depomos',
  deponde: 'deponde',
  depondo: 'depondo',
  deponha: 'deponha',
  deponho: 'deponho',
  deporao: 'deporão',
  deporas: 'deporás',
  deporei: 'deporei',
  deporem: 'deporem',
  depores: 'depores',
  deporia: 'deporia',
  depormo: 'depormo',
  deporta: 'deporta',
  deporte: 'deporte',
  deporto: 'deporto',
  deposta: 'deposta',
  deposto: 'deposto',
  depraca: 'depraça',
  deprava: 'deprava',
  deprave: 'deprave',
  depravo: 'depravo',
  depreca: 'depreca',
  depreco: 'depreco',
  depreda: 'depreda',
  deprede: 'deprede',
  depredo: 'depredo',
  deprezo: 'deprezo',
  deprima: 'deprima',
  deprime: 'deprime',
  deprimi: 'deprimi',
  deprimo: 'deprimo',
  depunha: 'depunha',
  depurai: 'depurai',
  depuram: 'depuram',
  depurar: 'depurar',
  depuras: 'depuras',
  depurei: 'depurei',
  depurem: 'depurem',
  depures: 'depures',
  depurou: 'depurou',
  depuser: 'depuser',
  deputai: 'deputai',
  deputam: 'deputam',
  deputar: 'deputar',
  deputas: 'deputas',
  deputei: 'deputei',
  deputem: 'deputem',
  deputes: 'deputes',
  deputou: 'deputou',
  dequita: 'dequita',
  dequite: 'dequite',
  dequito: 'dequito',
  deramar: 'deramar',
  deramos: 'déramos',
  derbice: 'dérbice',
  derbios: 'dérbios',
  dereito: 'dereito',
  deribia: 'deríbia',
  derisao: 'derisão',
  derisca: 'derisca',
  derisor: 'derisor',
  derivai: 'derivai',
  derivam: 'derivam',
  derivar: 'derivar',
  derivas: 'derivas',
  derivei: 'derivei',
  derivem: 'derivem',
  derives: 'derives',
  derivou: 'derivou',
  dermato: 'dermato',
  dermeas: 'dérmeas',
  dermeos: 'dérmeos',
  dermica: 'dérmica',
  dermico: 'dérmico',
  dermite: 'dermite',
  derogai: 'derogai',
  derogam: 'derogam',
  derogar: 'derogar',
  derogas: 'derogas',
  derogou: 'derogou',
  derogue: 'derogue',
  derraba: 'derraba',
  derrabe: 'derrabe',
  derrabo: 'derrabo',
  derrama: 'derrama',
  derrame: 'derrame',
  derramo: 'derramo',
  derrapa: 'derrapa',
  derrape: 'derrape',
  derrapo: 'derrapo',
  derreai: 'derreai',
  derrear: 'derrear',
  derreei: 'derreei',
  derrega: 'derrega',
  derrego: 'derrego',
  derreia: 'derreia',
  derreie: 'derreie',
  derreio: 'derreio',
  derreou: 'derreou',
  derreta: 'derreta',
  derrete: 'derrete',
  derreti: 'derreti',
  derreto: 'derreto',
  derriba: 'derriba',
  derribe: 'derribe',
  derribo: 'derribo',
  derrica: 'derriça',
  derrice: 'derrice',
  derrico: 'derriço',
  derriso: 'derriso',
  derroca: 'derroca',
  derroce: 'derroce',
  derroco: 'derroco',
  derroga: 'derroga',
  derrogo: 'derrogo',
  derrota: 'derrota',
  derrote: 'derrote',
  derroto: 'derroto',
  derruam: 'derruam',
  derruas: 'derruas',
  derruba: 'derruba',
  derrube: 'derrube',
  derrubo: 'derrubo',
  derruem: 'derruem',
  derruia: 'derruía',
  derruir: 'derruir',
  derruis: 'derruís',
  derruiu: 'derruiu',
  dervixe: 'dervixe',
  desabai: 'desabai',
  desabam: 'desabam',
  desabar: 'desabar',
  desabas: 'desabas',
  desabei: 'desabei',
  desabem: 'desabem',
  desabes: 'desabes',
  desabil: 'desábil',
  desabis: 'desábis',
  desabou: 'desabou',
  desabra: 'desabra',
  desabre: 'desabre',
  desabri: 'desabri',
  desabro: 'desabro',
  desacua: 'desacuá',
  desacue: 'desacue',
  desacuo: 'desacuo',
  desadao: 'desadão',
  desadas: 'desadas',
  desador: 'desador',
  desados: 'desados',
  desaera: 'desaerá',
  desaere: 'desaere',
  desaero: 'desaero',
  desafaz: 'desafaz',
  desafea: 'desafeá',
  desafez: 'desafez',
  desafia: 'desafia',
  desafie: 'desafie',
  desafio: 'desafio',
  desafiz: 'desafiz',
  desafua: 'desafua',
  desafue: 'desafue',
  desafuo: 'desafuo',
  desagio: 'deságio',
  desagua: 'deságua',
  desague: 'desague',
  desaguo: 'deságuo',
  desaina: 'desaina',
  desaine: 'desaine',
  desaino: 'desaino',
  desaira: 'desaira',
  desaire: 'desaire',
  desairo: 'desairo',
  desalia: 'desalia',
  desalie: 'desalie',
  desalio: 'desalio',
  desalma: 'desalma',
  desalme: 'desalme',
  desalmo: 'desalmo',
  desamai: 'desamai',
  desamam: 'desamam',
  desamao: 'desamão',
  desamar: 'desamar',
  desamas: 'desamas',
  desamea: 'desameá',
  desamei: 'desamei',
  desamem: 'desamem',
  desames: 'desames',
  desamor: 'desamor',
  desamos: 'desamos',
  desamou: 'desamou',
  desamua: 'desamua',
  desamue: 'desamue',
  desamuo: 'desamuo',
  desanas: 'desanas',
  desanca: 'desanca',
  desanco: 'desanco',
  desanda: 'desanda',
  desande: 'desande',
  desando: 'desando',
  desanos: 'desanos',
  desante: 'desante',
  desapea: 'desapeá',
  desapoe: 'desapõe',
  desapor: 'desapor',
  desapos: 'desapôs',
  desapus: 'desapus',
  desarai: 'desarai',
  desaram: 'desaram',
  desarao: 'desarão',
  desarar: 'desarar',
  desaras: 'desaras',
  desarca: 'desarca',
  desarco: 'desarco',
  desarea: 'desareá',
  desarei: 'desarei',
  desarem: 'desarem',
  desares: 'desares',
  desaria: 'desaria',
  desarma: 'desarma',
  desarme: 'desarme',
  desarmo: 'desarmo',
  desarou: 'desarou',
  desasai: 'desasai',
  desasam: 'desasam',
  desasar: 'desasar',
  desasas: 'desasas',
  desasei: 'desasei',
  desasem: 'desasem',
  desases: 'desases',
  desasia: 'desasia',
  desasir: 'desasir',
  desasis: 'desasis',
  desasiu: 'desasiu',
  desasna: 'desasna',
  desasne: 'desasne',
  desasno: 'desasno',
  desasou: 'desasou',
  desasse: 'desasse',
  desaste: 'desaste',
  desatai: 'desatai',
  desatam: 'desatam',
  desatar: 'desatar',
  desatas: 'desatas',
  desatei: 'desatei',
  desatem: 'desatem',
  desates: 'desates',
  desatou: 'desatou',
  desauga: 'desauga',
  desaugo: 'desaugo',
  desaura: 'desaura',
  desaure: 'desaure',
  desauri: 'desauri',
  desauro: 'desauro',
  desavam: 'desavam',
  desavas: 'desavas',
  desavem: 'desavém',
  desavia: 'desavia',
  desavie: 'desavie',
  desavim: 'desavim',
  desavio: 'desavio',
  desavir: 'desavir',
  desazai: 'desazai',
  desazam: 'desazam',
  desazar: 'desazar',
  desazas: 'desazas',
  desazei: 'desazei',
  desazem: 'desazem',
  desazes: 'desazes',
  desazos: 'desazos',
  desazou: 'desazou',
  desbaba: 'desbaba',
  desbabe: 'desbabe',
  desbabo: 'desbabo',
  desbaga: 'desbagá',
  desbago: 'desbago',
  desbara: 'desbara',
  desbare: 'desbare',
  desbaro: 'desbaro',
  desbeba: 'desbeba',
  desbebe: 'desbebê',
  desbebi: 'desbebi',
  desbebo: 'desbebo',
  desbica: 'desbica',
  desbico: 'desbico',
  desboca: 'desboca',
  desboco: 'desboco',
  desboia: 'desboiá',
  desboie: 'desboie',
  desboio: 'desboio',
  desbota: 'desbota',
  desbote: 'desbote',
  desboto: 'desboto',
  desbria: 'desbria',
  desbrie: 'desbrie',
  desbrio: 'desbrio',
  desbroa: 'desbroá',
  desbroe: 'desbroe',
  desbroo: 'desbroo',
  descabe: 'descabe',
  descaca: 'descaça',
  descace: 'descace',
  descaco: 'descaço',
  descaem: 'descaem',
  descaia: 'descaia',
  descaio: 'descaio',
  descair: 'descair',
  descais: 'desçais',
  descaiu: 'descaiu',
  descala: 'descala',
  descale: 'descale',
  descalo: 'descalo',
  descama: 'descama',
  descame: 'descame',
  descamo: 'desçamo',
  descana: 'descana',
  descane: 'descane',
  descano: 'descano',
  descapa: 'descapá',
  descape: 'descape',
  descapo: 'descapo',
  descara: 'descara',
  descare: 'descare',
  descaro: 'descaro',
  descasa: 'descasa',
  descase: 'descase',
  descaso: 'descaso',
  descava: 'descava',
  descave: 'descave',
  descavo: 'descavo',
  descega: 'descegá',
  descego: 'descego',
  desceis: 'desceis',
  descemo: 'descemo',
  descepa: 'descepa',
  descepe: 'descepe',
  descepo: 'descepo',
  descera: 'descera',
  desciam: 'desciam',
  descias: 'descias',
  descida: 'descida',
  descido: 'descido',
  descoca: 'descocá',
  descoco: 'descoco',
  descola: 'descola',
  descole: 'descole',
  descolo: 'descolo',
  descoma: 'descoma',
  descome: 'descome',
  descomi: 'descomi',
  descomo: 'descomo',
  descont: 'descont',
  descopa: 'descopá',
  descope: 'descope',
  descopo: 'descopo',
  descora: 'descora',
  descore: 'descore',
  descoro: 'descoro',
  descosa: 'descosa',
  descose: 'descose',
  descosi: 'descosi',
  descoso: 'descoso',
  descrer: 'descrer',
  descres: 'descrês',
  descreu: 'descreu',
  descria: 'descria',
  descudo: 'descudo',
  descura: 'descura',
  descure: 'descure',
  descuro: 'descuro',
  desdada: 'desdada',
  desdado: 'desdado',
  desdais: 'desdais',
  desdara: 'desdará',
  desdava: 'desdava',
  desdeem: 'desdeem',
  desdeis: 'desdeis',
  desdemo: 'desdemo',
  desdens: 'desdéns',
  desdera: 'desdera',
  desdiam: 'desdiam',
  desdias: 'desdias',
  desdida: 'desdida',
  desdido: 'desdido',
  desdiga: 'desdiga',
  desdigo: 'desdigo',
  desdira: 'desdirá',
  desdita: 'desdita',
  desdito: 'desdito',
  desdize: 'desdize',
  desdoba: 'desdoba',
  desdobe: 'desdobe',
  desdobo: 'desdobo',
  desecar: 'desecar',
  deseiva: 'deseivá',
  deseive: 'deseive',
  deseivo: 'deseivo',
  deseixa: 'deseixa',
  deseixe: 'deseixe',
  deseixo: 'deseixo',
  desejai: 'desejai',
  desejam: 'desejam',
  desejar: 'desejar',
  desejas: 'desejas',
  desejei: 'desejei',
  desejem: 'desejem',
  desejes: 'desejes',
  desejos: 'desejos',
  desejou: 'desejou',
  desejum: 'desejum',
  desemos: 'desemos',
  desempa: 'desempa',
  desempe: 'desempe',
  desempo: 'desempo',
  desende: 'desende',
  desenha: 'desenha',
  desenhe: 'desenhe',
  desenho: 'desenho',
  deserda: 'deserda',
  deserde: 'deserde',
  deserdo: 'deserdo',
  deserta: 'deserta',
  deserte: 'deserte',
  deserto: 'deserto',
  deserts: 'deserts',
  desfaca: 'desfaça',
  desface: 'desface',
  desfaco: 'desfaço',
  desfaia: 'desfaia',
  desfaie: 'desfaie',
  desfaio: 'desfaio',
  desfala: 'desfala',
  desfale: 'desfale',
  desfali: 'desfali',
  desfalo: 'desfalo',
  desfara: 'desfará',
  desfasa: 'desfasa',
  desfase: 'desfase',
  desfaso: 'desfaso',
  desfaza: 'desfaza',
  desfaze: 'desfaze',
  desfazo: 'desfazo',
  desfeai: 'desfeai',
  desfear: 'desfear',
  desfeei: 'desfeei',
  desfeia: 'desfeia',
  desfeie: 'desfeie',
  desfeio: 'desfeio',
  desfeou: 'desfeou',
  desfere: 'desfere',
  desferi: 'desferi',
  desfiai: 'desfiai',
  desfiam: 'desfiam',
  desfiar: 'desfiar',
  desfias: 'desfias',
  desfiei: 'desfiei',
  desfiem: 'desfiem',
  desfies: 'desfies',
  desfila: 'desfila',
  desfile: 'desfile',
  desfilo: 'desfilo',
  desfiou: 'desfiou',
  desfira: 'desfira',
  desfiro: 'desfiro',
  desfita: 'desfita',
  desfite: 'desfite',
  desfito: 'desfito',
  desfoca: 'desfoca',
  desfoco: 'desfoco',
  desfrea: 'desfreá',
  desfrua: 'desfrua',
  desfrui: 'desfrui',
  desfruo: 'desfruo',
  desgaba: 'desgaba',
  desgabe: 'desgabe',
  desgabo: 'desgabo',
  desgafa: 'desgafá',
  desgafe: 'desgafe',
  desgafo: 'desgafo',
  desgasa: 'desgasa',
  desgase: 'desgase',
  desgaso: 'desgaso',
  desgela: 'desgela',
  desgele: 'desgele',
  desgelo: 'desgelo',
  desguia: 'desguia',
  desguie: 'desguie',
  desguio: 'desguio',
  desidia: 'desídia',
  desidio: 'desídio',
  designa: 'designa',
  designe: 'designe',
  designo: 'designo',
  designs: 'designs',
  desimpo: 'desimpô',
  desinca: 'desinça',
  desince: 'desince',
  desinco: 'desinço',
  desirma: 'desirmã',
  desisca: 'desisca',
  desisco: 'desisco',
  desista: 'desista',
  desiste: 'desiste',
  desisti: 'desisti',
  desisto: 'desisto',
  desktop: 'desktop',
  deslaca: 'deslaça',
  deslace: 'deslace',
  deslaco: 'deslaço',
  deslada: 'deslada',
  deslado: 'deslado',
  deslaio: 'deslaio',
  deslana: 'deslana',
  deslane: 'deslane',
  deslano: 'deslano',
  deslapa: 'deslapa',
  deslape: 'deslape',
  deslapo: 'deslapo',
  deslata: 'deslatá',
  deslate: 'deslate',
  deslato: 'deslato',
  deslava: 'deslava',
  deslave: 'deslave',
  deslavo: 'deslavo',
  desleal: 'desleal',
  deslega: 'deslegá',
  deslego: 'deslego',
  desliai: 'desliai',
  desliam: 'desliam',
  desliar: 'desliar',
  deslias: 'deslias',
  desliei: 'desliei',
  desliem: 'desliem',
  deslies: 'deslies',
  desliga: 'desliga',
  desligo: 'desligo',
  deslima: 'deslimá',
  deslime: 'deslime',
  deslimo: 'deslimo',
  desliou: 'desliou',
  deslisa: 'deslisa',
  deslise: 'deslise',
  desliso: 'desliso',
  desliza: 'desliza',
  deslize: 'deslize',
  deslizo: 'deslizo',
  desloca: 'desloca',
  desloco: 'desloco',
  desloda: 'desloda',
  deslode: 'deslode',
  deslodo: 'deslodo',
  desluza: 'desluza',
  desluze: 'desluze',
  desluzi: 'desluzi',
  desluzo: 'desluzo',
  desmaia: 'desmaia',
  desmaie: 'desmaie',
  desmaio: 'desmaio',
  desmama: 'desmama',
  desmame: 'desmame',
  desmamo: 'desmamo',
  desmana: 'desmana',
  desmane: 'desmane',
  desmano: 'desmano',
  desmata: 'desmata',
  desmate: 'desmate',
  desmato: 'désmato',
  desmeai: 'desmeai',
  desmear: 'desmear',
  desmeca: 'desmeça',
  desmeco: 'desmeço',
  desmede: 'desmede',
  desmedi: 'desmedi',
  desmeei: 'desmeei',
  desmeia: 'desmeia',
  desmeie: 'desmeie',
  desmeio: 'desmeio',
  desmeou: 'desmeou',
  desmica: 'désmica',
  desmico: 'désmico',
  desmina: 'desmina',
  desmine: 'desmine',
  desmino: 'desmino',
  desmite: 'desmite',
  desmoca: 'desmoçá',
  desmoce: 'desmoce',
  desmoco: 'desmoço',
  desmoda: 'desmoda',
  desmoma: 'desmoma',
  desmose: 'desmose',
  desmuca: 'desmuca',
  desmuda: 'desmuda',
  desmude: 'desmude',
  desmudo: 'desmudo',
  desmuna: 'desmuna',
  desmune: 'desmune',
  desmuni: 'desmuni',
  desmuno: 'desmuno',
  desmura: 'desmura',
  desmure: 'desmure',
  desmuro: 'desmuro',
  desnata: 'desnata',
  desnate: 'desnate',
  desnato: 'desnato',
  desnega: 'desnega',
  desnego: 'desnego',
  desneva: 'desneva',
  desneve: 'desneve',
  desnevo: 'desnevo',
  desnexo: 'desnexo',
  desnoca: 'desnocá',
  desnoco: 'desnoco',
  desnota: 'desnota',
  desnote: 'desnote',
  desnoto: 'desnoto',
  desnuai: 'desnuai',
  desnuam: 'desnuam',
  desnuar: 'desnuar',
  desnuas: 'desnuas',
  desnuca: 'desnuca',
  desnuco: 'desnuco',
  desnuda: 'desnuda',
  desnude: 'desnude',
  desnudo: 'desnudo',
  desnuei: 'desnuei',
  desnuem: 'desnuem',
  desnues: 'desnues',
  desnuou: 'desnuou',
  desobra: 'desobra',
  desolai: 'desolai',
  desolam: 'desolam',
  desolar: 'desolar',
  desolas: 'desolas',
  desolei: 'desolei',
  desolem: 'desolem',
  desoles: 'desoles',
  desolha: 'desolha',
  desolhe: 'desolhe',
  desolho: 'desolho',
  desolou: 'desolou',
  desonra: 'desonra',
  desonre: 'desonre',
  desonro: 'desonro',
  desoras: 'desoras',
  desorna: 'desorna',
  desorne: 'desorne',
  desorno: 'desorno',
  desossa: 'desossa',
  desosse: 'desosse',
  desosso: 'desosso',
  desouca: 'desouça',
  desouco: 'desouço',
  desouga: 'desougá',
  desougo: 'desougo',
  desouve: 'desouve',
  desouvi: 'desouvi',
  desovai: 'desovai',
  desovam: 'desovam',
  desovar: 'desovar',
  desovas: 'desovas',
  desovei: 'desovei',
  desovem: 'desovem',
  desoves: 'desoves',
  desovou: 'desovou',
  despapa: 'despapa',
  despape: 'despape',
  despapo: 'despapo',
  despeai: 'despeai',
  despear: 'despear',
  despeca: 'despeça',
  despece: 'despece',
  despeco: 'despeço',
  despeda: 'despeda',
  despede: 'despede',
  despedi: 'despedi',
  despeei: 'despeei',
  despega: 'despega',
  despego: 'despego',
  despeia: 'despeia',
  despeie: 'despeie',
  despeio: 'despeio',
  despeja: 'despeja',
  despeje: 'despeje',
  despejo: 'despejo',
  despela: 'despela',
  despele: 'despele',
  despelo: 'despelo',
  despena: 'despena',
  despene: 'despene',
  despeno: 'despeno',
  despeou: 'despeou',
  despesa: 'despesa',
  despese: 'despese',
  despeso: 'despeso',
  despiam: 'despiam',
  despias: 'despias',
  despica: 'despica',
  despico: 'despico',
  despida: 'despida',
  despido: 'despido',
  despimo: 'despimo',
  despira: 'despira',
  despoem: 'despõem',
  despoer: 'despoer',
  despoes: 'despões',
  despois: 'despois',
  despoja: 'despoja',
  despoje: 'despoje',
  despojo: 'despojo',
  despole: 'despole',
  despoli: 'despoli',
  despora: 'desporá',
  desposa: 'desposa',
  despose: 'despose',
  desposo: 'desposo',
  despota: 'déspota',
  despraz: 'despraz',
  despula: 'despula',
  despulo: 'despulo',
  despuma: 'despumá',
  despume: 'despume',
  despumo: 'despumo',
  desquer: 'desquer',
  desquia: 'desquia',
  desquie: 'desquie',
  desquio: 'desquio',
  desquis: 'desquis',
  desraba: 'desraba',
  desrabe: 'desrabe',
  desrabo: 'desrabo',
  desrama: 'desrama',
  desrame: 'desrame',
  desramo: 'desramo',
  desrata: 'desratá',
  desrate: 'desrate',
  desrato: 'desrato',
  desrege: 'desregê',
  desregi: 'desregi',
  desreja: 'desreja',
  desrejo: 'desrejo',
  desrica: 'desriça',
  desrice: 'desrice',
  desrico: 'desriço',
  desrisa: 'desrisa',
  desriso: 'desriso',
  desriza: 'desriza',
  desrize: 'desrize',
  desrizo: 'desrizo',
  desruga: 'desruga',
  desrugo: 'desrugo',
  desruma: 'desruma',
  desrume: 'desrume',
  desrumo: 'desrumo',
  dessabe: 'dessabe',
  dessada: 'dessada',
  dessado: 'dessado',
  dessais: 'dessais',
  dessamo: 'dessamo',
  dessara: 'dessara',
  dessava: 'dessava',
  desseca: 'desseca',
  desseco: 'desseco',
  desseis: 'désseis',
  dessela: 'dessela',
  dessele: 'dessele',
  desselo: 'desselo',
  dessemo: 'dessemo',
  dessiga: 'dessiga',
  dessigo: 'dessigo',
  dessiso: 'dessiso',
  dessoai: 'dessoai',
  dessoam: 'dessoam',
  dessoar: 'dessoar',
  dessoas: 'dessoas',
  dessoca: 'dessoca',
  dessoco: 'dessoco',
  dessoei: 'dessoei',
  dessoem: 'dessoem',
  dessoes: 'dessoes',
  dessola: 'dessola',
  dessole: 'dessole',
  dessolo: 'dessolo',
  dessome: 'dessome',
  dessoou: 'dessoou',
  dessora: 'dessora',
  dessore: 'dessore',
  dessoro: 'dessoro',
  dessuai: 'dessuai',
  dessuam: 'dessuam',
  dessuar: 'dessuar',
  dessuas: 'dessuas',
  dessuda: 'dessudá',
  dessude: 'dessude',
  dessudo: 'dessudo',
  dessuei: 'dessuei',
  dessuem: 'dessuem',
  dessues: 'dessues',
  dessuja: 'dessuja',
  dessuje: 'dessuje',
  dessujo: 'dessujo',
  dessuma: 'dessuma',
  dessumi: 'dessumi',
  dessumo: 'dessumo',
  dessuou: 'dessuou',
  destaca: 'destaca',
  destaco: 'destaco',
  destala: 'destala',
  destale: 'destale',
  destalo: 'destalo',
  destapa: 'destapa',
  destape: 'destape',
  destapo: 'destapo',
  destara: 'destará',
  destare: 'destare',
  destaro: 'destaro',
  desteca: 'desteça',
  destece: 'destece',
  desteci: 'desteci',
  desteco: 'desteço',
  destela: 'destela',
  destele: 'destele',
  destelo: 'destelo',
  destema: 'destema',
  desteme: 'desteme',
  destemi: 'destemi',
  destemo: 'destemo',
  destens: 'desténs',
  desteta: 'desteta',
  destete: 'destete',
  desteto: 'desteto',
  destila: 'destila',
  destile: 'destile',
  destilo: 'destilo',
  destina: 'destina',
  destine: 'destine',
  destino: 'destino',
  destoai: 'destoai',
  destoam: 'destoam',
  destoar: 'destoar',
  destoas: 'destoas',
  destoca: 'destoca',
  destoco: 'destoco',
  destoei: 'destoei',
  destoem: 'destoem',
  destoes: 'destoes',
  destona: 'destona',
  destone: 'destone',
  destono: 'destono',
  destons: 'destons',
  destoou: 'destoou',
  destope: 'destope',
  destora: 'destora',
  destore: 'destore',
  destoro: 'destoro',
  destras: 'destras',
  destroi: 'destrói',
  destros: 'destros',
  destrua: 'destrua',
  destrui: 'destruí',
  destruo: 'destruo',
  destupa: 'destupa',
  destupi: 'destupi',
  destupo: 'destupo',
  desugai: 'desugai',
  desugam: 'desugam',
  desugar: 'desugar',
  desugas: 'desugas',
  desugou: 'desugou',
  desugue: 'desugue',
  desumir: 'desumir',
  desunam: 'desunam',
  desunas: 'desunas',
  desunem: 'desunem',
  desunes: 'desunes',
  desunha: 'desunha',
  desunhe: 'desunhe',
  desunho: 'desunho',
  desunia: 'desunia',
  desunir: 'desunir',
  desunis: 'desunis',
  desuniu: 'desuniu',
  desurda: 'desurda',
  desurde: 'desurde',
  desurdi: 'desurdi',
  desurdo: 'desurdo',
  desusai: 'desusai',
  desusam: 'desusam',
  desusar: 'desusar',
  desusas: 'desusas',
  desusei: 'desusei',
  desusem: 'desusem',
  desuses: 'desuses',
  desusos: 'desusos',
  desusou: 'desusou',
  desutil: 'desútil',
  desvaca: 'desvacá',
  desvaco: 'desvaco',
  desvaem: 'desvaem',
  desvaia: 'desvaia',
  desvaio: 'desvaio',
  desvair: 'desvair',
  desvais: 'desvais',
  desvaiu: 'desvaiu',
  desvale: 'desvale',
  desvali: 'desvali',
  desvaos: 'desvãos',
  desvara: 'desvará',
  desvare: 'desvare',
  desvaro: 'desvaro',
  desvede: 'desvede',
  desveem: 'desveem',
  desveja: 'desveja',
  desvejo: 'desvejo',
  desvela: 'desvela',
  desvele: 'desvele',
  desvelo: 'desvelo',
  desvera: 'desverá',
  desveza: 'desveza',
  desveze: 'desveze',
  desvezo: 'desvezo',
  desviai: 'desviai',
  desviam: 'desviam',
  desviar: 'desviar',
  desvias: 'desvias',
  desviei: 'desviei',
  desviem: 'desviem',
  desvies: 'desvies',
  desviga: 'desviga',
  desvigo: 'desvigo',
  desvios: 'desvios',
  desviou: 'desviou',
  desvira: 'desvira',
  desvire: 'desvire',
  desviro: 'desviro',
  desviva: 'desviva',
  desvive: 'desvive',
  desvivi: 'desvivi',
  desvivo: 'desvivo',
  desvoes: 'desvões',
  deszela: 'deszela',
  deszele: 'deszele',
  deszelo: 'deszelo',
  deszipa: 'deszipa',
  deszipe: 'deszipe',
  deszipo: 'deszipo',
  detalha: 'detalha',
  detalhe: 'detalhe',
  detalho: 'detalho',
  detarda: 'detardá',
  detarde: 'detarde',
  detardo: 'detardo',
  detecao: 'deteção',
  detecta: 'detecta',
  detecte: 'detecte',
  detecto: 'detecto',
  detedor: 'detedor',
  detemir: 'detemir',
  detemos: 'detemos',
  detenca: 'detença',
  detence: 'detence',
  detenco: 'detenço',
  detende: 'detende',
  detendo: 'detendo',
  detenha: 'detenha',
  detenho: 'detenho',
  detenta: 'detenta',
  detente: 'détente',
  detento: 'detento',
  deterao: 'deterão',
  deteras: 'deterás',
  deterei: 'deterei',
  deterem: 'deterem',
  deteres: 'deteres',
  deterge: 'deterge',
  detergi: 'detergi',
  deteria: 'deteria',
  deterja: 'deterja',
  deterjo: 'deterjo',
  detesta: 'detesta',
  deteste: 'deteste',
  detesto: 'detesto',
  detetai: 'detetai',
  detetam: 'detetam',
  detetar: 'detetar',
  detetas: 'detetas',
  detetei: 'detetei',
  detetem: 'detetem',
  detetes: 'detetes',
  detetor: 'detetor',
  detetou: 'detetou',
  deteuda: 'deteúda',
  deteudo: 'deteúdo',
  detidao: 'detidão',
  detidas: 'detidas',
  detidos: 'detidos',
  detinha: 'detinha',
  detiver: 'detiver',
  detonai: 'detonai',
  detonam: 'detonam',
  detonar: 'detonar',
  detonas: 'detonas',
  detonei: 'detonei',
  detonem: 'detonem',
  detones: 'detones',
  detonou: 'detonou',
  detorai: 'detorai',
  detoram: 'detoram',
  detorar: 'detorar',
  detoras: 'detoras',
  detorei: 'detorei',
  detorem: 'detorem',
  detores: 'detores',
  detorou: 'detorou',
  detraem: 'detraem',
  detraia: 'detraia',
  detraio: 'detraio',
  detrair: 'detrair',
  detrais: 'detrais',
  detraiu: 'detraiu',
  detrama: 'detramá',
  detrame: 'detrame',
  detramo: 'detramo',
  detrata: 'detrata',
  detrate: 'detrate',
  detrato: 'detrato',
  detrito: 'detrito',
  detrona: 'detrona',
  detrone: 'detrone',
  detrono: 'detrono',
  detruso: 'detruso',
  detuana: 'detuaná',
  deturba: 'deturba',
  deturbe: 'deturbe',
  deturbo: 'deturbo',
  deturpa: 'deturpa',
  deturpe: 'deturpe',
  deturpo: 'deturpo',
  deunces: 'deunces',
  deussum: 'deussum',
  deutero: 'dêutero',
  deutoes: 'deutões',
  deutons: 'dêutons',
  deutovo: 'deutovo',
  deutzia: 'dêutzia',
  devacao: 'devação',
  devagar: 'devagar',
  devamos: 'devamos',
  devanea: 'devaneá',
  devassa: 'devassa',
  devasse: 'devasse',
  devasso: 'devasso',
  devasta: 'devasta',
  devaste: 'devaste',
  devasto: 'devasto',
  devedor: 'devedor',
  develai: 'develai',
  develam: 'develam',
  develar: 'develar',
  develas: 'develas',
  develei: 'develei',
  develem: 'develem',
  develes: 'develes',
  develou: 'develou',
  devemos: 'devemos',
  devenam: 'devenam',
  devenas: 'devenas',
  devendo: 'devendo',
  devenem: 'devenem',
  devenes: 'devenes',
  devenha: 'devenha',
  devenho: 'devenho',
  devenia: 'devenia',
  devenir: 'devenir',
  devenis: 'devenis',
  deveniu: 'deveniu',
  devente: 'devente',
  deveram: 'deveram',
  deverao: 'deverão',
  deveras: 'deveras',
  deverei: 'deverei',
  deverem: 'deverem',
  deveres: 'deveres',
  deveria: 'deveria',
  devermo: 'devermo',
  devesal: 'devesal',
  devesas: 'devesas',
  devesse: 'devesse',
  deveste: 'deveste',
  devidao: 'devidão',
  devidas: 'devidas',
  devidor: 'devidor',
  devidos: 'devidos',
  devieis: 'devíeis',
  deviera: 'deviera',
  devimos: 'devimos',
  devinde: 'devinde',
  devindo: 'devindo',
  devinha: 'devinha',
  devirao: 'devirão',
  deviras: 'devirás',
  devirei: 'devirei',
  devirem: 'devirem',
  devires: 'devires',
  deviria: 'deviria',
  devisai: 'devisai',
  devisam: 'devisam',
  devisar: 'devisar',
  devisas: 'devisas',
  devisei: 'devisei',
  devisem: 'devisem',
  devises: 'devises',
  devisou: 'devisou',
  devocao: 'devoção',
  devolva: 'devolva',
  devolve: 'devolve',
  devolvi: 'devolvi',
  devolvo: 'devolvo',
  devorai: 'devorai',
  devoram: 'devoram',
  devorar: 'devorar',
  devoras: 'devoras',
  devorei: 'devorei',
  devorem: 'devorem',
  devores: 'devores',
  devorou: 'devorou',
  devotai: 'devotai',
  devotam: 'devotam',
  devotar: 'devotar',
  devotas: 'devotas',
  devotei: 'devotei',
  devotem: 'devotem',
  devotes: 'devotes',
  devotos: 'devotos',
  devotou: 'devotou',
  devulga: 'devulga',
  devulgo: 'devulgo',
  dexiare: 'dexiare',
  dextans: 'dextans',
  dezenas: 'dezenas',
  dezenos: 'dezenos',
  dezinca: 'dezincá',
  dezinco: 'dezinco',
  dezinho: 'dezinho',
  dezoito: 'dezoito',
  dhalina: 'dhalina',
  diabada: 'diabada',
  diabame: 'diabame',
  diabase: 'diábase',
  diabeta: 'diabeta',
  diabete: 'diabete',
  diaboas: 'diáboas',
  diaboes: 'diabões',
  diabolo: 'diabolô',
  diabras: 'diabras',
  diabres: 'diabres',
  diabril: 'diabril',
  diabris: 'diabris',
  diabros: 'diabros',
  diacele: 'diacele',
  diachos: 'diachos',
  diacido: 'diácido',
  diacono: 'diácono',
  diacope: 'diácope',
  diadema: 'diadema',
  diademe: 'diademe',
  diademo: 'diademo',
  diadica: 'diádica',
  diadico: 'diádico',
  diadoco: 'diádoco',
  diadose: 'diadose',
  diafana: 'diáfana',
  diafano: 'diáfano',
  diafeno: 'diafeno',
  diafise: 'diáfise',
  diafone: 'diafone',
  diafora: 'diáfora',
  diagais: 'diagais',
  dialaca: 'dialaca',
  dialage: 'diálage',
  dialelo: 'dialelo',
  dialeta: 'dialeta',
  dialete: 'dialete',
  dialeto: 'dialeto',
  dialhos: 'dialhos',
  dialila: 'dialila',
  dialilo: 'dialilo',
  dialisa: 'dialisa',
  dialise: 'diálise',
  dialiso: 'dialiso',
  dialoes: 'dialoés',
  dialoga: 'dialoga',
  dialogo: 'diálogo',
  dialose: 'dialose',
  diamazi: 'diamazi',
  diambas: 'diambas',
  diambes: 'diambes',
  diamida: 'diamida',
  diamido: 'diamido',
  diamilo: 'diamilo',
  diamina: 'diamina',
  diamoes: 'diamões',
  diamoro: 'diamoro',
  diandro: 'diandro',
  dianela: 'dianela',
  dianema: 'dianema',
  diangas: 'diangas',
  dianhos: 'dianhos',
  dianiao: 'dianião',
  dianion: 'diânion',
  dianita: 'dianita',
  dianite: 'dianite',
  dianito: 'dianito',
  dianjas: 'dianjás',
  diantia: 'diantia',
  diantos: 'diantos',
  dianuco: 'dianuco',
  diapero: 'diápero',
  diapira: 'diapira',
  diapiro: 'diapiro',
  diapses: 'diapses',
  diarcas: 'diarcas',
  diarcos: 'diarcos',
  diarias: 'diárias',
  diarios: 'diários',
  diariza: 'diariza',
  diarize: 'diarize',
  diarizo: 'diarizo',
  diascia: 'diáscia',
  diascos: 'diascos',
  diascro: 'diascro',
  diaspro: 'diaspro',
  diaster: 'diáster',
  diastro: 'diastro',
  diatela: 'diatela',
  diatese: 'diátese',
  diatima: 'diatima',
  diatiro: 'diátiro',
  diatlos: 'diatlos',
  diatoma: 'diátoma',
  diatomo: 'diátomo',
  diatons: 'diatons',
  diaulos: 'diaulos',
  diazina: 'diazina',
  diazois: 'diazóis',
  diazoma: 'diazoma',
  diazona: 'diazona',
  diazota: 'diazota',
  diazote: 'diazote',
  diazoto: 'diazoto',
  dibalas: 'dibalas',
  dibases: 'dibases',
  dibelas: 'dibelas',
  dibiose: 'dibiose',
  dibixis: 'dibixis',
  dibolia: 'dibólia',
  dibraco: 'díbraco',
  dibulos: 'dibulos',
  dibungo: 'dibungo',
  dibutes: 'dibutes',
  dicabas: 'dicabas',
  dicacao: 'dicação',
  dicadao: 'dicadão',
  dicadas: 'dicadas',
  dicador: 'dicador',
  dicados: 'dicados',
  dicalco: 'dicalco',
  dicamos: 'dicamos',
  dicanas: 'dicanas',
  dicando: 'dicando',
  dicanga: 'dicanga',
  dicante: 'dicante',
  dicanza: 'dicanza',
  dicaram: 'dicaram',
  dicarao: 'dicarão',
  dicaras: 'dicarás',
  dicarei: 'dicarei',
  dicarem: 'dicarem',
  dicares: 'dicares',
  dicaria: 'dicaria',
  dicario: 'dicário',
  dicarmo: 'dicarmo',
  dicarpo: 'dicarpo',
  dicasio: 'dicásio',
  dicasse: 'dicasse',
  dicasso: 'dicasso',
  dicasta: 'dicasta',
  dicaste: 'dicaste',
  dicavam: 'dicavam',
  dicavas: 'dicavas',
  dicaxis: 'dicáxis',
  dicazes: 'dicazes',
  diccoes: 'dicções',
  diccona: 'dicçona',
  diceida: 'diceida',
  dicelas: 'dicelas',
  dicelia: 'dicelia',
  dicelio: 'dicélio',
  dicelos: 'dicelos',
  diceras: 'díceras',
  dicerca: 'dicerca',
  diceria: 'dicéria',
  dicerio: 'dicério',
  diceros: 'díceros',
  dichote: 'dichote',
  diciclo: 'diciclo',
  dicifos: 'dicifos',
  dicimbe: 'dicimbe',
  dicline: 'dicline',
  diclino: 'diclino',
  dicliso: 'dicliso',
  dicloma: 'dicloma',
  diclume: 'diclume',
  dicocas: 'dicocas',
  dicocos: 'dicocos',
  dicodes: 'dicodes',
  dicoles: 'dicoles',
  dicolon: 'dicólon',
  dicomas: 'dicomas',
  diconas: 'diçonas',
  dicondo: 'dicondo',
  dicongo: 'dicongo',
  dicoreu: 'dicoreu',
  dicoria: 'dicoria',
  dicoris: 'dicoris',
  dicrana: 'dicrana',
  dicrano: 'dicrano',
  dicroas: 'dícroas',
  dicromo: 'dicromo',
  dicrono: 'dícrono',
  dicroto: 'dícroto',
  dicruro: 'dicruro',
  dicteia: 'dicteia',
  dicteus: 'dicteus',
  dictica: 'díctica',
  dictico: 'díctico',
  dictina: 'dictina',
  dictite: 'dictite',
  dictriz: 'dictriz',
  dicumbo: 'dicumbo',
  dicuris: 'dicuris',
  didacta: 'didacta',
  didatas: 'didatas',
  didelfo: 'didelfo',
  didemno: 'didemno',
  didimas: 'dídimas',
  didimio: 'didímio',
  didimos: 'dídimos',
  didinio: 'didínio',
  didisco: 'didisco',
  diducao: 'didução',
  didutor: 'didutor',
  diecias: 'diecias',
  diecica: 'diécica',
  diecico: 'diécico',
  diecios: 'diécios',
  diedral: 'diedral',
  diedros: 'diedros',
  diegese: 'diegese',
  diegeta: 'diegeta',
  dielias: 'diélias',
  diembes: 'diembes',
  diembos: 'diembos',
  dienias: 'diénias',
  dienica: 'diênica',
  dienico: 'diênico',
  dienses: 'dienses',
  diepesa: 'diepesa',
  dierese: 'diérese',
  diesada: 'diesada',
  diesado: 'diesado',
  diesais: 'diesais',
  diesamo: 'diesamo',
  diesara: 'diesara',
  diesava: 'diesava',
  dieseis: 'dieseis',
  diesemo: 'diesemo',
  diester: 'diéster',
  diestro: 'diestro',
  dieteta: 'dieteta',
  dietilo: 'dietilo',
  dietina: 'dietina',
  difalia: 'difalia',
  difalos: 'difalos',
  difamai: 'difamai',
  difamam: 'difamam',
  difamar: 'difamar',
  difamas: 'difamas',
  difamei: 'difamei',
  difamem: 'difamem',
  difames: 'difames',
  difamou: 'difamou',
  difanda: 'difanda',
  difasio: 'difásio',
  difemos: 'difemos',
  difenil: 'difenil',
  difenis: 'difenis',
  difenol: 'difenol',
  diferem: 'diferem',
  diferes: 'diferes',
  diferia: 'diferia',
  diferir: 'diferir',
  diferis: 'diferis',
  diferiu: 'diferiu',
  dificil: 'difícil',
  difiida: 'difíida',
  difilas: 'difilas',
  difilos: 'difilos',
  difiope: 'difiope',
  difiram: 'difiram',
  difiras: 'difiras',
  difluam: 'difluam',
  difluas: 'difluas',
  difluem: 'difluem',
  difluia: 'difluía',
  difluir: 'difluir',
  difluis: 'difluis',
  difluiu: 'difluiu',
  difoles: 'difoles',
  difolis: 'difólis',
  difonia: 'difonia',
  difrata: 'difrata',
  difrate: 'difrate',
  difrato: 'difrato',
  diftera: 'díftera',
  difubas: 'difubas',
  difumbe: 'difumbe',
  difunda: 'difunda',
  difunde: 'difunde',
  difundi: 'difundi',
  difundo: 'difundo',
  difusao: 'difusão',
  difusas: 'difusas',
  difusor: 'difusor',
  difusos: 'difusos',
  difutas: 'difutas',
  digamas: 'digamas',
  digamia: 'digamia',
  digamos: 'digamos',
  digares: 'digares',
  digenea: 'digênea',
  digeneo: 'digêneo',
  digenia: 'digenia',
  digenie: 'digênie',
  digerem: 'digerem',
  digeres: 'digeres',
  digeria: 'digeria',
  digerir: 'digerir',
  digeris: 'digeris',
  digeriu: 'digeriu',
  digesta: 'digesta',
  digeste: 'digeste',
  digesti: 'digesti',
  digesto: 'digesto',
  digicia: 'digícia',
  diginas: 'díginas',
  diginia: 'diginia',
  diginos: 'diginos',
  digiram: 'digiram',
  digiras: 'digiras',
  digitai: 'digitai',
  digital: 'digital',
  digitam: 'digitam',
  digitar: 'digitar',
  digitas: 'digitas',
  digitei: 'digitei',
  digitem: 'digitem',
  digites: 'digites',
  digitos: 'dígitos',
  digitou: 'digitou',
  digleno: 'digleno',
  diglifo: 'díglifo',
  dignada: 'dignada',
  dignado: 'dignado',
  dignais: 'dignais',
  dignamo: 'dignamo',
  dignara: 'dignara',
  dignato: 'dígnato',
  dignava: 'dignava',
  digneis: 'digneis',
  dignemo: 'dignemo',
  digonal: 'digonal',
  digonia: 'digonia',
  digonos: 'dígonos',
  digrafo: 'dígrafo',
  digrama: 'digrama',
  digreda: 'digreda',
  digrede: 'digrede',
  digredi: 'digredi',
  digredo: 'digredo',
  digrida: 'digrida',
  digride: 'digride',
  digrido: 'digrido',
  diguice: 'diguice',
  digutis: 'digutis',
  diiambo: 'diiambo',
  diicana: 'diicana',
  diidrol: 'diidrol',
  dijambo: 'dijambo',
  dijicas: 'dijiças',
  dijinas: 'dijinas',
  dijoles: 'dijoles',
  dilacao: 'dilação',
  dilamos: 'dilamos',
  dilanda: 'dilanda',
  dilania: 'dilaniá',
  dilanie: 'dilanie',
  dilanio: 'dilanio',
  dilarga: 'dilarga',
  dilargo: 'dilargo',
  dilatai: 'dilatai',
  dilatam: 'dilatam',
  dilatar: 'dilatar',
  dilatas: 'dilatas',
  dilatei: 'dilatei',
  dilatem: 'dilatem',
  dilates: 'dilates',
  dilatou: 'dilatou',
  dilecao: 'dileção',
  dilecas: 'dilecas',
  dilecos: 'dilecos',
  dilecto: 'dilecto',
  dilemas: 'dilemas',
  dilenia: 'dilênia',
  dilepto: 'dilepto',
  diletas: 'diletas',
  diletos: 'diletos',
  dileuos: 'dileuós',
  diligos: 'diligós',
  dilobas: 'dilobas',
  dilofos: 'dílofos',
  dilogia: 'dilogia',
  dilogum: 'dilogum',
  dilolos: 'dilolos',
  dilombe: 'dilombe',
  dilonas: 'dilonas',
  dilonga: 'dilonga',
  dilquea: 'dílquea',
  dilqueo: 'dílqueo',
  diluais: 'diluais',
  diluamo: 'diluamo',
  diluiam: 'diluíam',
  diluias: 'diluías',
  diluida: 'diluída',
  diluido: 'diluído',
  diluimo: 'diluímo',
  diluira: 'diluirá',
  dilulas: 'dilulas',
  dilutos: 'dilutos',
  diluvia: 'diluvia',
  diluvie: 'diluvie',
  diluvio: 'dilúvio',
  diluvos: 'diluvos',
  dimanai: 'dimanai',
  dimanam: 'dimanam',
  dimanar: 'dimanar',
  dimanas: 'dimanas',
  dimanei: 'dimanei',
  dimanem: 'dimanem',
  dimanes: 'dimanes',
  dimanou: 'dimanou',
  dimatis: 'dimatis',
  dimazol: 'dimazol',
  dimbuis: 'dimbuis',
  dimeias: 'dimeias',
  dimeres: 'dímeres',
  dimeros: 'dímeros',
  dimesna: 'dimesna',
  dimetil: 'dimetil',
  dimetis: 'dimetis',
  dimetro: 'dímetro',
  dimidia: 'dimidia',
  dimidie: 'dimidie',
  dimidio: 'dimídio',
  diminas: 'diminas',
  diminua: 'diminua',
  diminui: 'diminui',
  diminuo: 'diminuo',
  dimitis: 'dimitis',
  dimorfo: 'dimorfo',
  dimunho: 'dimunho',
  dimuros: 'dimuros',
  dinames: 'dínames',
  dinamia: 'dinamia',
  dinamol: 'dinamol',
  dinamos: 'dínamos',
  dinarda: 'dinarda',
  dinares: 'dinares',
  dinasta: 'dinasta',
  dindada: 'dindada',
  dindado: 'dindado',
  dindais: 'dindais',
  dindamo: 'dindamo',
  dindara: 'dindará',
  dindaro: 'díndaro',
  dindava: 'dindava',
  dindeis: 'dindeis',
  dindemo: 'dindemo',
  dindias: 'díndias',
  dindies: 'dindiés',
  dindins: 'dindins',
  dinebra: 'dinebra',
  dineina: 'dineína',
  dinemas: 'dinemas',
  dinemos: 'dinemos',
  dinetos: 'dínetos',
  dinguis: 'dinguis',
  dinicas: 'dínicas',
  dinicos: 'dínicos',
  dinitro: 'dinitro',
  dinodio: 'dinódio',
  dinodos: 'dínodos',
  dinoios: 'dinoios',
  dinomis: 'dinomis',
  dinopes: 'dinopes',
  dinopis: 'dinópis',
  dinopse: 'dinopse',
  dinotim: 'dinotim',
  dinsede: 'dinsede',
  dinteis: 'dintéis',
  dinunga: 'dinunga',
  dinungo: 'dinungo',
  dinuvio: 'dinúvio',
  diocese: 'diocese',
  dioctil: 'dioctil',
  dioctis: 'dioctis',
  diodina: 'diodina',
  diodios: 'diódios',
  diodona: 'diodona',
  dioense: 'dioense',
  dioicas: 'dioicas',
  dioicia: 'dioicia',
  dioicos: 'dioicos',
  diolena: 'diolena',
  dioneia: 'dioneia',
  dionina: 'dionina',
  dioniso: 'dioniso',
  diopros: 'diópros',
  dioptra: 'dioptra',
  dioptro: 'dioptro',
  diorama: 'diorama',
  diorese: 'diorese',
  diorita: 'diorita',
  diorite: 'diorite',
  diorito: 'diorito',
  diosmas: 'diosmas',
  diosmeo: 'diósmeo',
  diotias: 'diotias',
  diotico: 'diótico',
  diotipa: 'diótipa',
  dioxana: 'dioxana',
  dioxano: 'dioxano',
  dioxias: 'dioxias',
  dioxide: 'dioxide',
  dioxido: 'dióxido',
  dioxima: 'dioxima',
  dioxina: 'dioxina',
  dipcade: 'dípcade',
  dipigia: 'dipigia',
  dipigio: 'dipígio',
  dipigos: 'dípigos',
  dipilos: 'dípilos',
  dipirio: 'dipírio',
  dipiros: 'dipiros',
  diploas: 'díploas',
  diplodo: 'díplodo',
  diploes: 'díploes',
  diploma: 'diploma',
  diplome: 'diplome',
  diplomo: 'diplomo',
  dipluro: 'dipluro',
  dipneus: 'dipneus',
  dipodes: 'dípodes',
  dipodia: 'dipodia',
  dipolar: 'dipolar',
  dipolos: 'dipolos',
  diponio: 'dipônio',
  diporos: 'díporos',
  dipsaco: 'dípsaco',
  dipsada: 'dípsada',
  dipsade: 'dípsade',
  dipsias: 'dipsias',
  dipsido: 'dípsido',
  dipsose: 'dipsose',
  diptera: 'díptera',
  diptero: 'díptero',
  diptica: 'díptica',
  diptico: 'díptico',
  diquara: 'diquara',
  diqueia: 'diqueia',
  diqueis: 'diqueis',
  diqueje: 'diqueje',
  diquele: 'diquele',
  diquemo: 'diquemo',
  diquias: 'díquias',
  diquixi: 'diquíxi',
  diracma: 'diracma',
  dirceas: 'dírceas',
  direcao: 'direção',
  directa: 'directa',
  directo: 'directo',
  direita: 'direita',
  direito: 'direito',
  diremos: 'diremos',
  diretas: 'diretas',
  diretor: 'diretor',
  diretos: 'diretos',
  diriana: 'diriana',
  diriano: 'diriano',
  dirieis: 'diríeis',
  dirigem: 'dirigem',
  diriges: 'diriges',
  dirigia: 'dirigia',
  dirigio: 'dirígio',
  dirigir: 'dirigir',
  dirigis: 'dirigis',
  dirigiu: 'dirigiu',
  dirijam: 'dirijam',
  dirijas: 'dirijas',
  dirimam: 'dirimam',
  dirimas: 'dirimas',
  dirimem: 'dirimem',
  dirimes: 'dirimes',
  dirimia: 'dirimia',
  dirimir: 'dirimir',
  dirimis: 'dirimis',
  dirimiu: 'dirimiu',
  dirinas: 'dirinas',
  dirinos: 'dirinos',
  diriris: 'diriris',
  diritos: 'diritos',
  dirquis: 'dirquis',
  dirruir: 'dirruir',
  diruais: 'diruais',
  diruiam: 'diruíam',
  diruias: 'diruías',
  diruida: 'diruída',
  diruido: 'diruído',
  diruira: 'diruirá',
  disacos: 'dísacos',
  disafia: 'disafia',
  disamis: 'disamis',
  disanha: 'disanha',
  disbase: 'dísbase',
  discada: 'discada',
  discado: 'discado',
  discais: 'discais',
  discamo: 'discamo',
  discara: 'discara',
  discava: 'discava',
  discifo: 'discifo',
  discina: 'discina',
  discite: 'discite',
  discman: 'discman',
  discoes: 'discões',
  discolo: 'díscolo',
  discusa: 'discusa',
  discuse: 'discuse',
  discuso: 'discuso',
  discuta: 'discuta',
  discute: 'discute',
  discuti: 'discuti',
  discuto: 'discuto',
  disdera: 'disdera',
  diselma: 'diselma',
  disemas: 'disemas',
  disemia: 'disemia',
  diserta: 'diserta',
  diserto: 'diserto',
  disfere: 'disfere',
  disferi: 'disferi',
  disfira: 'disfira',
  disfiro: 'disfiro',
  disfono: 'dísfono',
  disgras: 'disgras',
  dislate: 'dislate',
  disodia: 'disodia',
  disombe: 'disombe',
  disomia: 'disomia',
  disopia: 'disopia',
  dispais: 'dispais',
  dispara: 'dispara',
  dispare: 'dispare',
  disparo: 'disparo',
  display: 'display',
  dispoem: 'dispõem',
  dispoer: 'dispoer',
  dispoes: 'dispões',
  dispomo: 'dispomo',
  dispora: 'disporá',
  disporo: 'dísporo',
  disputa: 'disputa',
  dispute: 'dispute',
  disputo: 'disputo',
  disquei: 'disquei',
  disquem: 'disquem',
  disques: 'disques',
  dissaba: 'dissaba',
  dissaco: 'dissaco',
  dissana: 'dissana',
  dissano: 'dissano',
  dissava: 'dissava',
  disseca: 'disseca',
  disseco: 'disseco',
  dissena: 'dissena',
  dissera: 'dissera',
  dissida: 'dissida',
  disside: 'disside',
  dissidi: 'dissidi',
  dissido: 'dissido',
  dissipa: 'dissipa',
  dissipe: 'dissipe',
  dissipo: 'dissipo',
  dissomo: 'dissomo',
  dissona: 'dissona',
  dissone: 'dissone',
  dissono: 'díssono',
  dissote: 'dissote',
  distada: 'distada',
  distado: 'distado',
  distais: 'distais',
  distamo: 'distamo',
  distara: 'distara',
  distava: 'distava',
  disteis: 'disteis',
  distemo: 'distemo',
  distena: 'distena',
  disteno: 'disteno',
  distica: 'dística',
  distico: 'dístico',
  distila: 'distila',
  distile: 'distile',
  distilo: 'distilo',
  distoma: 'distoma',
  distomo: 'dístomo',
  distrai: 'distrai',
  distros: 'distros',
  disuria: 'disúria',
  ditadao: 'ditadão',
  ditadas: 'ditadas',
  ditador: 'ditador',
  ditados: 'ditados',
  ditaina: 'ditaína',
  ditames: 'ditames',
  ditamno: 'ditamno',
  ditamos: 'ditamos',
  ditanda: 'ditanda',
  ditando: 'ditando',
  ditanga: 'ditanga',
  ditante: 'ditante',
  ditaram: 'ditaram',
  ditarao: 'ditarão',
  ditaras: 'ditaras',
  ditarei: 'ditarei',
  ditarem: 'ditarem',
  ditares: 'ditares',
  ditaria: 'ditaria',
  ditarmo: 'ditarmo',
  ditasse: 'ditasse',
  ditaste: 'ditaste',
  ditavam: 'ditavam',
  ditavas: 'ditavas',
  ditavel: 'ditável',
  ditaxes: 'ditaxes',
  ditaxia: 'ditaxia',
  ditazol: 'ditazol',
  ditecas: 'ditecas',
  ditecos: 'ditecos',
  diteira: 'diteira',
  diteiro: 'diteiro',
  ditemas: 'ditemas',
  ditemos: 'ditemos',
  ditenda: 'ditenda',
  diteque: 'diteque',
  diterio: 'ditério',
  diticas: 'díticas',
  diticos: 'díticos',
  ditieno: 'ditieno',
  ditinho: 'ditinho',
  ditiros: 'dítiros',
  ditisco: 'ditisco',
  ditobas: 'ditobas',
  ditofal: 'ditofal',
  ditomas: 'dítomas',
  ditomes: 'dítomes',
  ditomos: 'dítomos',
  ditonga: 'ditonga',
  ditongo: 'ditongo',
  ditonos: 'dítonos',
  ditosas: 'ditosas',
  ditosos: 'ditosos',
  ditotes: 'ditotes',
  ditoxos: 'ditoxos',
  ditrema: 'ditrema',
  ditremo: 'ditremo',
  ditrica: 'dítrica',
  ditrico: 'dítrico',
  ditropo: 'dítropo',
  ditumas: 'ditumas',
  ditutas: 'ditutas',
  diuense: 'diuense',
  diundas: 'diundas',
  diungas: 'diungas',
  diurese: 'diurese',
  diurias: 'diúrias',
  diurica: 'diúrica',
  diurico: 'diúrico',
  diurina: 'diurina',
  diurnal: 'diurnal',
  diurnas: 'diurnas',
  diurnos: 'diurnos',
  divagai: 'divagai',
  divagam: 'divagam',
  divagar: 'divagar',
  divagas: 'divagas',
  divagou: 'divagou',
  divague: 'divague',
  divalis: 'divalis',
  divanis: 'divanis',
  divedos: 'divedos',
  diverge: 'diverge',
  divergi: 'divergi',
  diverja: 'diverja',
  diversa: 'diversa',
  diverso: 'diverso',
  diverta: 'diverta',
  diverte: 'diverte',
  diverti: 'diverti',
  diverto: 'diverto',
  divetas: 'divetas',
  divicia: 'divícia',
  dividam: 'dividam',
  dividas: 'dívidas',
  dividem: 'dividem',
  divides: 'divides',
  dividia: 'dividia',
  dividir: 'dividir',
  dividis: 'dividis',
  dividiu: 'dividiu',
  dividua: 'divídua',
  dividuo: 'divíduo',
  divinal: 'divinal',
  divinas: 'divinas',
  divinos: 'divinos',
  divirja: 'divirja',
  divirjo: 'divirjo',
  divirta: 'divirta',
  divirto: 'divirto',
  divisai: 'divisai',
  divisam: 'divisam',
  divisao: 'divisão',
  divisar: 'divisar',
  divisas: 'divisas',
  divisei: 'divisei',
  divisem: 'divisem',
  divises: 'divises',
  divismo: 'divismo',
  divisor: 'divisor',
  divisos: 'divisos',
  divisou: 'divisou',
  divista: 'divista',
  divulga: 'divulga',
  divulgo: 'divulgo',
  divunas: 'divunas',
  divunda: 'divunda',
  divunga: 'divunga',
  dixicas: 'dixicas',
  dixicos: 'díxicos',
  dixidea: 'dixídea',
  dixideo: 'dixídeo',
  dixissa: 'dixissa',
  dixitas: 'dixitas',
  dizamba: 'dizamba',
  dizanga: 'dizanga',
  dizedor: 'dizedor',
  dizemos: 'dizemos',
  dizendo: 'dizendo',
  dizente: 'dizente',
  dizenze: 'dizenze',
  dizerem: 'dizerem',
  dizeres: 'dizeres',
  dizidao: 'dizidão',
  dizieis: 'dizíeis',
  dizimai: 'dizimai',
  dizimam: 'dizimam',
  dizimar: 'dizimar',
  dizimas: 'dizimas',
  dizimei: 'dizimei',
  dizimem: 'dizimem',
  dizimes: 'dizimes',
  dizimos: 'dízimos',
  dizimou: 'dizimou',
  dizivel: 'dizível',
  dizoica: 'dizoica',
  dizoico: 'dizoico',
  dizonho: 'dizonho',
  dizumbe: 'dizumbe',
  djacuta: 'djacutá',
  djambas: 'djambas',
  djelaba: 'djelaba',
  djumbai: 'djumbai',
  dlxviii: 'dlxviii',
  dlxxiii: 'dlxxiii',
  dlxxvii: 'dlxxvii',
  dlxxxii: 'dlxxxii',
  dlxxxiv: 'dlxxxiv',
  dlxxxix: 'dlxxxix',
  dlxxxvi: 'dlxxxvi',
  doacoes: 'doações',
  doacona: 'doaçona',
  doadoes: 'doadões',
  doadona: 'doadona',
  doadora: 'doadora',
  doaires: 'doaires',
  doairos: 'doairos',
  doantes: 'doantes',
  doardes: 'doardes',
  doareis: 'doareis',
  doaremo: 'doaremo',
  doariam: 'doariam',
  doarias: 'doarias',
  doarios: 'doários',
  doarmos: 'doarmos',
  doassem: 'doassem',
  doasses: 'doasses',
  doastes: 'doastes',
  doaveis: 'doáveis',
  dobacao: 'dobação',
  dobadao: 'dobadão',
  dobadas: 'dobadas',
  dobador: 'dobador',
  dobados: 'dobados',
  dobagem: 'dobagem',
  dobales: 'dobales',
  dobamos: 'dobamos',
  dobando: 'dobando',
  dobante: 'dobante',
  dobaram: 'dobaram',
  dobarao: 'dobarão',
  dobaras: 'dobarás',
  dobarei: 'dobarei',
  dobarem: 'dobarem',
  dobares: 'dobares',
  dobaria: 'dobaria',
  dobarmo: 'dobarmo',
  dobasse: 'dobasse',
  dobaste: 'dobaste',
  dobavam: 'dobavam',
  dobavas: 'dobavas',
  dobemos: 'dobemos',
  dobinea: 'dobínea',
  doblete: 'doblete',
  doborus: 'doborus',
  dobrada: 'dobrada',
  dobrado: 'dobrado',
  dobrais: 'dobrais',
  dobramo: 'dobramo',
  dobrara: 'dobrara',
  dobrava: 'dobrava',
  dobreis: 'dobreis',
  dobremo: 'dobremo',
  dobrete: 'dobrete',
  dobreza: 'dobreza',
  dobroes: 'dobrões',
  dobruns: 'dobruns',
  doburus: 'doburus',
  docadao: 'doçadão',
  docadas: 'doçadas',
  docador: 'doçador',
  docados: 'doçados',
  docagem: 'docagem',
  docaina: 'doçaina',
  docamos: 'doçamos',
  docando: 'doçando',
  docante: 'doçante',
  docaram: 'doçaram',
  docarao: 'doçarão',
  docaras: 'doçarás',
  docarei: 'doçarei',
  docarem: 'doçarem',
  docares: 'doçares',
  docaria: 'doçaria',
  docarmo: 'doçarmo',
  docasse: 'doçasse',
  docaste: 'doçaste',
  docavam: 'doçavam',
  docavas: 'doçavas',
  docavel: 'doçável',
  doceira: 'doceira',
  doceiro: 'doceiro',
  docemos: 'docemos',
  docense: 'docense',
  docente: 'docente',
  doceria: 'doceria',
  docetas: 'docetas',
  docidao: 'docidão',
  docimeu: 'docimeu',
  docinho: 'docinho',
  docista: 'docista',
  docleia: 'docleia',
  docmios: 'dócmios',
  docotes: 'doçotes',
  docroti: 'docroti',
  doctora: 'doctora',
  docuins: 'docuins',
  docuras: 'doçuras',
  dodoneu: 'dodoneu',
  dodonio: 'dodônio',
  dodorom: 'dodorom',
  dodrans: 'dódrans',
  doedora: 'doedora',
  doeiras: 'doeiras',
  doencas: 'doenças',
  doentes: 'doentes',
  doentia: 'doentia',
  doentio: 'doentio',
  doerdes: 'doerdes',
  doereis: 'doereis',
  doeremo: 'doeremo',
  doeriam: 'doeriam',
  doerias: 'doerias',
  doermos: 'doermos',
  doessem: 'doessem',
  doesses: 'doesses',
  doestai: 'doestai',
  doestam: 'doestam',
  doestar: 'doestar',
  doestas: 'doestas',
  doestei: 'doestei',
  doestem: 'doestem',
  doestes: 'doestes',
  doestou: 'doestou',
  dofamio: 'dofâmio',
  dofonas: 'dofonas',
  dofonos: 'dofonos',
  dogadas: 'dogadas',
  dogados: 'dogados',
  dogcart: 'dogcart',
  dogesas: 'dogesas',
  dogicas: 'dógicas',
  dogicos: 'dógicos',
  doiamos: 'doíamos',
  doideja: 'doideja',
  doideje: 'doideje',
  doidejo: 'doidejo',
  doidela: 'doidela',
  doidelo: 'doidelo',
  doidete: 'doidete',
  doideto: 'doideto',
  doidice: 'doidice',
  doidoes: 'doidões',
  doidona: 'doidona',
  doidura: 'doidura',
  doirada: 'doirada',
  doirado: 'doirado',
  doirais: 'doirais',
  doiramo: 'doiramo',
  doirara: 'doirará',
  doirava: 'doirava',
  doireis: 'doireis',
  doireja: 'doireja',
  doireje: 'doireje',
  doirejo: 'doirejo',
  doiremo: 'doiremo',
  dojicas: 'dojicas',
  dojicos: 'dojicos',
  dolares: 'dólares',
  doleira: 'doleira',
  doleiro: 'doleiro',
  dolente: 'dolente',
  doleros: 'doleros',
  dolicas: 'dólicas',
  dolicos: 'dólicos',
  dolidas: 'dólidas',
  dolidea: 'dolídea',
  dolideo: 'dolídeo',
  doliida: 'dolíida',
  doliman: 'dóliman',
  dolinas: 'dolinas',
  dolinha: 'dolinha',
  doliolo: 'dolíolo',
  dolione: 'dolíone',
  dolmens: 'dolmens',
  dolmins: 'dólmins',
  dolmita: 'dolmita',
  dolmite: 'dolmite',
  dolomia: 'dolomia',
  dolonca: 'dolonca',
  dolonco: 'dolonco',
  dolopes: 'dólopes',
  dolopeu: 'dolopeu',
  dolopio: 'dolópio',
  doloque: 'doloqué',
  dolores: 'dolores',
  doloria: 'dolória',
  dolorio: 'dolório',
  dolosas: 'dolosas',
  dolosos: 'dolosos',
  dolquis: 'dolquis',
  domacao: 'domação',
  domacia: 'domácia',
  domadao: 'domadão',
  domadas: 'domadas',
  domador: 'domador',
  domados: 'domados',
  domamos: 'domamos',
  domando: 'domando',
  domante: 'domante',
  domaram: 'domaram',
  domarao: 'domarão',
  domaras: 'domaras',
  domarei: 'domarei',
  domarem: 'domarem',
  domares: 'domares',
  domaria: 'domaria',
  domario: 'domário',
  domarmo: 'domarmo',
  domasse: 'domasse',
  domaste: 'domaste',
  domavam: 'domavam',
  domavas: 'domavas',
  domavel: 'domável',
  dombdis: 'dombdis',
  dombeia: 'dombeia',
  dombeya: 'dombéya',
  dombica: 'dombica',
  dombolo: 'dombolo',
  domemos: 'domemos',
  domenas: 'domenas',
  domicas: 'dômicas',
  domicia: 'domícia',
  domicos: 'dômicos',
  dominai: 'dominai',
  dominam: 'dominam',
  dominar: 'dominar',
  dominas: 'dominas',
  dominei: 'dominei',
  dominem: 'dominem',
  domines: 'domines',
  dominga: 'dominga',
  domingo: 'domingo',
  dominim: 'dominim',
  dominio: 'domínio',
  dominos: 'dominós',
  dominou: 'dominou',
  domista: 'domista',
  domitos: 'domitos',
  domoica: 'domoica',
  domoico: 'domoico',
  domoide: 'domoide',
  donacao: 'donação',
  donaces: 'dônaces',
  donacia: 'donácia',
  donadio: 'donadio',
  donaire: 'donaire',
  donairo: 'donairo',
  donatal: 'donatal',
  donatas: 'donatas',
  donatos: 'donatos',
  dondica: 'dondica',
  dondico: 'dondico',
  dondoca: 'dondoca',
  dondona: 'dondona',
  dondons: 'dondons',
  doneada: 'doneada',
  doneado: 'doneado',
  doneais: 'doneais',
  doneara: 'doneará',
  doneava: 'doneava',
  doneeis: 'doneeis',
  doneiam: 'doneiam',
  doneias: 'doneias',
  doneiem: 'doneiem',
  doneies: 'doneies',
  donetas: 'donetas',
  donezes: 'donezes',
  dongris: 'dongris',
  doninha: 'doninha',
  donjuan: 'donjuán',
  donosas: 'donosas',
  donosos: 'donosos',
  dontres: 'dontres',
  donzeis: 'donzéis',
  donzela: 'donzela',
  donzelo: 'donzelo',
  doodias: 'doódias',
  dopadao: 'dopadão',
  dopadas: 'dopadas',
  dopador: 'dopador',
  dopados: 'dopados',
  dopagem: 'dopagem',
  dopamos: 'dopamos',
  dopando: 'dopando',
  dopante: 'dopante',
  doparam: 'doparam',
  doparao: 'doparão',
  doparas: 'doparas',
  doparei: 'doparei',
  doparem: 'doparem',
  dopares: 'dopares',
  doparia: 'doparia',
  doparmo: 'doparmo',
  dopasse: 'dopasse',
  dopaste: 'dopaste',
  dopavam: 'dopavam',
  dopavas: 'dopavas',
  dopavel: 'dopável',
  dopemos: 'dopemos',
  doppler: 'doppler',
  dopsona: 'dopsona',
  doradas: 'doradas',
  dorados: 'dorados',
  dorasca: 'dorasca',
  dorcada: 'dórcada',
  doreito: 'doreíto',
  doremas: 'doremas',
  dorense: 'dorense',
  doriais: 'doriais',
  doricas: 'dóricas',
  doricos: 'dóricos',
  doridas: 'doridas',
  doridea: 'dorídea',
  dorideo: 'dorídeo',
  dorides: 'dórides',
  doridos: 'doridos',
  dorileu: 'dorileu',
  doripes: 'doripes',
  dorisco: 'dorisco',
  dormeca: 'dormeça',
  dormece: 'dormece',
  dormeci: 'dormeci',
  dormeco: 'dormeço',
  dormiam: 'dormiam',
  dormiao: 'dormião',
  dormias: 'dormias',
  dormida: 'dormida',
  dormido: 'dormido',
  dormimo: 'dormimo',
  dormira: 'dormira',
  dormita: 'dormita',
  dormite: 'dormite',
  dormito: 'dormito',
  dornada: 'dornada',
  dornoes: 'dornões',
  dorobos: 'dorobos',
  dorosas: 'dorosas',
  dorosos: 'dorosos',
  dorruns: 'dorruns',
  dorsada: 'dorsada',
  dorsado: 'dorsado',
  dorsais: 'dorsais',
  dorsulo: 'dórsulo',
  dorzita: 'dorzita',
  dosadao: 'dosadão',
  dosadas: 'dosadas',
  dosador: 'dosador',
  dosados: 'dosados',
  dosagem: 'dosagem',
  dosamos: 'dosamos',
  dosando: 'dosando',
  dosante: 'dosante',
  dosaram: 'dosaram',
  dosarao: 'dosarão',
  dosaras: 'dosaras',
  dosarei: 'dosarei',
  dosarem: 'dosarem',
  dosares: 'dosares',
  dosaria: 'dosaria',
  dosarmo: 'dosarmo',
  dosasse: 'dosasse',
  dosaste: 'dosaste',
  dosavam: 'dosavam',
  dosavas: 'dosavas',
  dosavel: 'dosável',
  doseada: 'doseada',
  doseado: 'doseado',
  doseais: 'doseais',
  doseara: 'doseará',
  doseava: 'doseava',
  doseeis: 'doseeis',
  doseiam: 'doseiam',
  doseias: 'doseias',
  doseiem: 'doseiem',
  doseies: 'doseies',
  dosemos: 'dosemos',
  dosseis: 'dosséis',
  dossela: 'dosselá',
  dossele: 'dossele',
  dosselo: 'dosselo',
  dossier: 'dossier',
  dossies: 'dossiês',
  dossoro: 'dossoró',
  dotacao: 'dotação',
  dotadao: 'dotadão',
  dotadas: 'dotadas',
  dotador: 'dotador',
  dotados: 'dotados',
  dotamos: 'dotamos',
  dotando: 'dotando',
  dotante: 'dotante',
  dotaram: 'dotaram',
  dotarao: 'dotarão',
  dotaras: 'dotaras',
  dotarei: 'dotarei',
  dotarem: 'dotarem',
  dotares: 'dotares',
  dotaria: 'dotaria',
  dotarmo: 'dotarmo',
  dotasse: 'dotasse',
  dotaste: 'dotaste',
  dotavam: 'dotavam',
  dotavas: 'dotavas',
  dotavel: 'dotável',
  dotemos: 'dotemos',
  dotidea: 'dotídea',
  dotideo: 'dotídeo',
  dotinho: 'dotinho',
  doudeja: 'doudeja',
  doudeje: 'doudeje',
  doudejo: 'doudejo',
  doudela: 'doudela',
  doudelo: 'doudelo',
  doudete: 'doudete',
  doudeto: 'doudeto',
  doudice: 'doudice',
  doudoes: 'doudões',
  doudona: 'doudona',
  doudura: 'doudura',
  doundos: 'doundos',
  dourada: 'dourada',
  dourado: 'dourado',
  dourais: 'dourais',
  douramo: 'douramo',
  dourara: 'dourara',
  dourava: 'dourava',
  doureis: 'doureis',
  doureja: 'doureja',
  doureje: 'doureje',
  dourejo: 'dourejo',
  douremo: 'douremo',
  dourina: 'dourina',
  doutiai: 'doutiai',
  doutiam: 'doutiam',
  doutiar: 'doutiar',
  doutias: 'doutias',
  doutiei: 'doutiei',
  doutiem: 'doutiem',
  douties: 'douties',
  doutiou: 'doutiou',
  doutora: 'doutora',
  doutore: 'doutore',
  doutoro: 'doutoro',
  doutras: 'doutras',
  doutrem: 'doutrem',
  doutros: 'doutros',
  doviale: 'doviale',
  dovorne: 'dovorne',
  dovorni: 'dovórni',
  doxicas: 'dóxicas',
  doxicos: 'dóxicos',
  dozenas: 'dozenas',
  dozenos: 'dozenos',
  dracena: 'dracena',
  dracina: 'dracina',
  dracmas: 'dracmas',
  dragada: 'dragada',
  dragado: 'dragado',
  dragais: 'dragais',
  dragamo: 'dragamo',
  dragara: 'dragara',
  dragava: 'dragava',
  drageas: 'drágeas',
  drageia: 'drageia',
  dragoes: 'dragões',
  dragona: 'dragona',
  draguei: 'draguei',
  draguem: 'draguem',
  dragues: 'dragues',
  drainai: 'drainai',
  drainam: 'drainam',
  drainar: 'drainar',
  drainas: 'drainas',
  drainei: 'drainei',
  drainem: 'drainem',
  draines: 'draines',
  drainou: 'drainou',
  draivas: 'draivas',
  drapeai: 'drapeai',
  drapear: 'drapear',
  drapeei: 'drapeei',
  drapeia: 'drapeia',
  drapeie: 'drapeie',
  drapeio: 'drapeio',
  drapeja: 'drapeja',
  drapeje: 'drapeje',
  drapejo: 'drapejo',
  drapeou: 'drapeou',
  dravida: 'drávida',
  drenada: 'drenada',
  drenado: 'drenado',
  drenais: 'drenais',
  drenamo: 'drenamo',
  drenara: 'drenara',
  drenava: 'drenava',
  dreneis: 'dreneis',
  drenemo: 'drenemo',
  dresina: 'dresina',
  driadas: 'dríadas',
  driades: 'dríades',
  driblai: 'driblai',
  driblam: 'driblam',
  driblar: 'driblar',
  driblas: 'driblas',
  driblei: 'driblei',
  driblem: 'driblem',
  dribles: 'dribles',
  driblou: 'driblou',
  drinque: 'drinque',
  driofis: 'driófis',
  drogada: 'drogada',
  drogado: 'drogado',
  drogais: 'drogais',
  drogamo: 'drogamo',
  drogara: 'drogara',
  drogava: 'drogava',
  droguea: 'drogueá',
  droguei: 'droguei',
  droguem: 'droguem',
  drogues: 'drogues',
  drongos: 'drongos',
  drontes: 'drontes',
  drosera: 'drósera',
  druidas: 'druidas',
  drunfos: 'drunfos',
  duaires: 'duaires',
  duairos: 'duairos',
  dualina: 'dualina',
  dualiza: 'dualiza',
  dualize: 'dualize',
  dualizo: 'dualizo',
  duandua: 'duândua',
  duandue: 'duandué',
  duarcas: 'duarcas',
  duatlos: 'duatlos',
  dubador: 'dubador',
  dubieza: 'dubieza',
  dublada: 'dublada',
  dublado: 'dublado',
  dublais: 'dublais',
  dublamo: 'dublamo',
  dublara: 'dublara',
  dublava: 'dublava',
  dubleis: 'dubleis',
  dublemo: 'dublemo',
  dublete: 'dublete',
  dubleto: 'dubleto',
  dubnios: 'dúbnios',
  dubules: 'dubulés',
  duburia: 'dubúria',
  duburus: 'duburus',
  ducados: 'ducados',
  ducaras: 'ducarás',
  ducatao: 'ducatão',
  duccoes: 'ducções',
  duchada: 'duchada',
  duchado: 'duchado',
  duchais: 'duchais',
  duchamo: 'duchamo',
  duchara: 'duchara',
  duchava: 'duchava',
  ducheis: 'ducheis',
  duchemo: 'duchemo',
  ducinas: 'ducinas',
  ducreyi: 'ducreyi',
  ductais: 'ductais',
  ducteis: 'dúcteis',
  ductora: 'ductora',
  ducucus: 'ducucus',
  ducumes: 'ducumes',
  ducunom: 'ducunom',
  dudongo: 'dudongo',
  dueiras: 'dueiras',
  dueiros: 'dueiros',
  duelada: 'duelada',
  duelado: 'duelado',
  duelais: 'duelais',
  duelamo: 'duelamo',
  duelara: 'duelara',
  duelava: 'duelava',
  dueleis: 'dueleis',
  duelemo: 'duelemo',
  dueliza: 'duelizá',
  duelize: 'duelize',
  duelizo: 'duelizo',
  duendes: 'duendes',
  duenhas: 'duenhas',
  duernos: 'duernos',
  duetada: 'duetada',
  duetado: 'duetado',
  duetais: 'duetais',
  duetamo: 'duetamo',
  duetara: 'duetara',
  duetava: 'duetava',
  dueteis: 'dueteis',
  duetemo: 'duetemo',
  duetino: 'duetino',
  duftita: 'duftita',
  duganis: 'duganis',
  dugazas: 'dugazas',
  dugongo: 'dugongo',
  duidade: 'duidade',
  duinzes: 'duinzes',
  duiparo: 'duíparo',
  dulacia: 'dulácia',
  dulcada: 'dulcadá',
  dulcida: 'dúlcida',
  dulcido: 'dúlcido',
  dulcina: 'dulcina',
  dulcita: 'dulcita',
  dulcite: 'dulcite',
  dulcora: 'dulçora',
  dulcore: 'dulçore',
  dulcoro: 'dulçoro',
  dulcose: 'dulcose',
  dulcura: 'dulçura',
  duleque: 'duleque',
  duliana: 'duliana',
  duliano: 'duliano',
  dulidea: 'dulídea',
  dulideo: 'dulídeo',
  dulijas: 'dulijás',
  dulimas: 'dulimãs',
  duloses: 'duloses',
  duludis: 'duludis',
  dumasia: 'dumásia',
  dummont: 'dummont',
  dumoria: 'dumória',
  dumping: 'dumping',
  dunalia: 'dunália',
  dunalma: 'dunalma',
  dunares: 'dunares',
  dunaria: 'dunária',
  dunario: 'dunário',
  dunduns: 'dunduns',
  dunetas: 'dunetas',
  dunitos: 'dunitos',
  duobolo: 'duóbolo',
  duodeno: 'duodeno',
  duodias: 'duodias',
  duotais: 'duotais',
  dupioes: 'dupiões',
  duplada: 'duplada',
  duplado: 'duplado',
  duplais: 'duplais',
  duplamo: 'duplamo',
  duplara: 'duplará',
  duplava: 'duplava',
  dupleis: 'dupleis',
  duplemo: 'duplemo',
  duplete: 'duplete',
  dupleto: 'dupleto',
  duplica: 'duplica',
  duplice: 'dúplice',
  duplico: 'duplico',
  dupreno: 'dupreno',
  duquesa: 'duquesa',
  duracao: 'duração',
  duracos: 'duraços',
  duradao: 'duradão',
  duradas: 'duradas',
  durador: 'durador',
  durados: 'durados',
  duraloi: 'duralói',
  duramen: 'durâmen',
  durames: 'durames',
  duramos: 'duramos',
  duranca: 'durança',
  durando: 'durando',
  duranis: 'duranis',
  duranta: 'duranta',
  durante: 'durante',
  duraque: 'duraque',
  duraram: 'duraram',
  durarao: 'durarão',
  duraras: 'duraras',
  durarei: 'durarei',
  durarem: 'durarem',
  durares: 'durares',
  duraria: 'duraria',
  durarmo: 'durarmo',
  durasno: 'durasno',
  durasse: 'durasse',
  duraste: 'duraste',
  duravam: 'duravam',
  duravas: 'duravas',
  duravel: 'durável',
  durazes: 'durazes',
  durazia: 'durázia',
  durazio: 'durázio',
  durbada: 'durbada',
  durbado: 'durbado',
  durbais: 'durbais',
  durbamo: 'durbamo',
  durbara: 'durbará',
  durbava: 'durbava',
  durbeis: 'durbeis',
  durbemo: 'durbemo',
  dureira: 'dureira',
  dureiro: 'dureiro',
  duremos: 'duremos',
  durenio: 'durênio',
  durenos: 'durenos',
  durense: 'durense',
  durezas: 'durezas',
  durezes: 'durezes',
  duriade: 'duríade',
  duriais: 'duriais',
  durilas: 'durilas',
  durilos: 'durilos',
  durinas: 'durinas',
  durinha: 'durinha',
  durinho: 'durinho',
  durioes: 'duriões',
  durismo: 'durismo',
  durisso: 'durisso',
  duritas: 'duritas',
  durites: 'durites',
  duritos: 'duritos',
  durmais: 'durmais',
  durmamo: 'durmamo',
  duroias: 'duroias',
  durolas: 'durolas',
  duronas: 'duronas',
  duronze: 'duronze',
  dururas: 'dururas',
  dutilas: 'dútilas',
  duturos: 'duturós',
  duvalia: 'duvália',
  duveias: 'duveias',
  duvelas: 'duvelas',
  duvidai: 'duvidai',
  duvidam: 'duvidam',
  duvidar: 'duvidar',
  duvidas: 'dúvidas',
  duvidei: 'duvidei',
  duvidem: 'duvidem',
  duvides: 'duvides',
  duvidou: 'duvidou',
  duzamos: 'duzamos',
  duzidao: 'duzidão',
  duzidas: 'duzidas',
  duzidor: 'duzidor',
  duzidos: 'duzidos',
  duzieis: 'duzíeis',
  duzimos: 'duzimos',
  duzinas: 'duzinas',
  duzindo: 'duzindo',
  duziram: 'duziram',
  duzirao: 'duzirão',
  duziras: 'duzirás',
  duzirei: 'duzirei',
  duzirem: 'duzirem',
  duzires: 'duzires',
  duziria: 'duziria',
  duzirmo: 'duzirmo',
  duzisse: 'duzisse',
  duziste: 'duziste',
  dxcviii: 'dxcviii',
  dxlviii: 'dxlviii',
  dxxviii: 'dxxviii',
  dxxxiii: 'dxxxiii',
  dxxxvii: 'dxxxvii',
  dzongas: 'dzongas',
  ealmada: 'ealmada',
  ealmado: 'ealmado',
  ealmais: 'ealmais',
  ealmamo: 'ealmamo',
  ealmara: 'ealmará',
  ealmava: 'ealmava',
  ealmeis: 'ealmeis',
  ealmemo: 'ealmemo',
  eamboge: 'eamboge',
  eaozoes: 'eãozões',
  earcada: 'earcada',
  earcado: 'earcado',
  earcais: 'earcais',
  earcamo: 'earcamo',
  earcara: 'earcará',
  earcava: 'earcava',
  earinas: 'earinas',
  earquei: 'earquei',
  earquem: 'earquem',
  earques: 'earques',
  eatonia: 'eatônia',
  ebalias: 'ebálias',
  ebalida: 'ebálida',
  ebalide: 'ebálide',
  ebalido: 'ebálido',
  ebalios: 'ebálios',
  ebambes: 'ebambes',
  ebamins: 'ebamins',
  ebandes: 'ebandes',
  ebaneas: 'ebâneas',
  ebaneos: 'ebâneos',
  ebanica: 'ebânica',
  ebanico: 'ebânico',
  ebanino: 'ebanino',
  ebanita: 'ebanita',
  ebanite: 'ebanite',
  ebaniza: 'ebaniza',
  ebanize: 'ebanize',
  ebanizo: 'ebanizo',
  ebenais: 'ebenais',
  ebenale: 'ebenale',
  ebiaras: 'ebiaras',
  ebombes: 'ebombes',
  ebomins: 'ebomins',
  ebonite: 'ebonite',
  eborato: 'eborato',
  eboreas: 'ebóreas',
  eboreos: 'ebóreos',
  ebriosa: 'ebriosa',
  ebrioso: 'ebrioso',
  ebulais: 'ebulais',
  ebulamo: 'ebulamo',
  ebuliam: 'ebuliam',
  ebulias: 'ebulias',
  ebulida: 'ebulida',
  ebulido: 'ebulido',
  ebulimo: 'ebulimo',
  ebulira: 'ebulirá',
  eburica: 'ebúrica',
  eburico: 'ebúrico',
  eburina: 'eburina',
  eburino: 'eburino',
  eburnai: 'eburnai',
  eburnam: 'eburnam',
  eburnar: 'eburnar',
  eburnas: 'eburnas',
  eburnea: 'ebúrnea',
  eburnei: 'eburnei',
  eburnem: 'eburnem',
  eburneo: 'ebúrneo',
  eburnes: 'eburnes',
  eburnou: 'eburnou',
  eburoes: 'eburões',
  ecabete: 'ecabete',
  ecaboro: 'ecaboro',
  ecadoes: 'ecadões',
  ecadona: 'ecadona',
  ecadora: 'ecadora',
  ecagras: 'ecagras',
  ecaiodo: 'ecaiodo',
  ecalice: 'ecalice',
  ecandas: 'ecandas',
  ecantes: 'ecantes',
  ecantos: 'ecantos',
  ecardes: 'ecardes',
  ecareis: 'ecáreis',
  ecaremo: 'ecaremo',
  ecariam: 'ecariam',
  ecarias: 'ecarias',
  ecarmos: 'ecarmos',
  ecassem: 'ecassem',
  ecasses: 'ecasses',
  ecastes: 'ecastes',
  ecaveis: 'ecáveis',
  ecbases: 'écbases',
  ecbolas: 'écbolas',
  ecciese: 'ecciese',
  ecdemia: 'ecdemia',
  ecderos: 'ecderos',
  ecdicas: 'écdicas',
  ecdicos: 'écdicos',
  ecdises: 'ecdises',
  ecdoras: 'ecdoras',
  ecdusia: 'ecdúsia',
  ecetica: 'ecética',
  ecetico: 'ecético',
  ecfisia: 'ecfísia',
  ecforas: 'écforas',
  ecforia: 'ecforia',
  ecfrase: 'écfrase',
  echarpe: 'echarpe',
  echioes: 'echiões',
  echuabo: 'echuabo',
  ecianas: 'ecianas',
  ecianos: 'ecianos',
  ecidios: 'ecídios',
  ecieses: 'ecieses',
  ecilias: 'ecílias',
  ecismos: 'ecismos',
  ecistas: 'ecistas',
  ecitons: 'ecítons',
  eclabio: 'eclábio',
  eclegma: 'eclegma',
  eclesia: 'eclésia',
  eclimia: 'eclimia',
  eclipsa: 'eclipsa',
  eclipse: 'eclipse',
  eclipso: 'eclipso',
  eclipta: 'eclipta',
  eclises: 'eclises',
  eclisse: 'eclisse',
  eclodem: 'eclodem',
  eclodes: 'eclodes',
  eclodia: 'eclodia',
  eclodir: 'eclodir',
  eclodis: 'eclodis',
  eclodiu: 'eclodiu',
  eclogal: 'eclogal',
  eclogas: 'éclogas',
  eclosao: 'eclosão',
  ecludam: 'ecludam',
  ecludas: 'ecludas',
  eclusas: 'eclusas',
  ecmeias: 'ecmeias',
  ecnefia: 'ecnefia',
  ecoadao: 'ecoadão',
  ecoadas: 'ecoadas',
  ecoador: 'ecoador',
  ecoados: 'ecoados',
  ecoamos: 'ecoamos',
  ecoando: 'ecoando',
  ecoante: 'ecoante',
  ecoaram: 'ecoaram',
  ecoarao: 'ecoarão',
  ecoaras: 'ecoaras',
  ecoarei: 'ecoarei',
  ecoarem: 'ecoarem',
  ecoares: 'ecoares',
  ecoaria: 'ecoaria',
  ecoarmo: 'ecoarmo',
  ecoasse: 'ecoasse',
  ecoaste: 'ecoaste',
  ecoavam: 'ecoavam',
  ecoavas: 'ecoavas',
  ecoavel: 'ecoável',
  ecocasa: 'ecocasa',
  ecocida: 'ecocida',
  ecodemo: 'ecodemo',
  ecodide: 'ecodidé',
  ecoemos: 'ecoemos',
  ecofato: 'ecofato',
  ecofila: 'ecofila',
  ecoicas: 'ecoicas',
  ecoicos: 'ecoicos',
  ecoides: 'ecoides',
  ecoismo: 'ecoísmo',
  ecoleos: 'ecóleos',
  ecolias: 'ecolias',
  ecolica: 'ecólica',
  ecolico: 'ecólico',
  ecoloca: 'ecoloca',
  ecoloco: 'ecoloco',
  ecologa: 'ecóloga',
  ecologo: 'ecólogo',
  econgos: 'econgos',
  economo: 'ecônomo',
  ecopada: 'ecopada',
  ecopado: 'ecopado',
  ecosito: 'ecosito',
  ecotaxa: 'ecotaxa',
  ecotipo: 'ecótipo',
  ecotono: 'ecótono',
  ecovias: 'ecovias',
  ecovila: 'ecovila',
  ecoxupe: 'ecoxupé',
  ecpiema: 'ecpiema',
  ecranes: 'écranes',
  ecrexis: 'ecréxis',
  ecrises: 'ecrises',
  ecrisia: 'ecrisia',
  ecroneu: 'ecroneu',
  ecrucus: 'ecrucus',
  ecrurus: 'ecrurus',
  ecstasy: 'ecstasy',
  ectases: 'éctases',
  ectasia: 'ectasia',
  ectenia: 'ectenia',
  ecteses: 'écteses',
  ectimas: 'ectimas',
  ectinos: 'ectinos',
  ectipos: 'éctipos',
  ectlima: 'ectlima',
  ectobio: 'ectóbio',
  ectoima: 'ectoima',
  ectomia: 'ectomia',
  ectopia: 'ectopia',
  ectoreu: 'ectoréu',
  ectrima: 'ectrima',
  ectrose: 'ectrose',
  eculana: 'eculana',
  eculano: 'eculano',
  eculeas: 'ecúleas',
  eculeos: 'ecúleos',
  eculice: 'ecúlice',
  ecumena: 'ecúmena',
  ecumeno: 'ecúmeno',
  eczemas: 'eczemas',
  edafica: 'edáfica',
  edafico: 'edáfico',
  edafios: 'edáfios',
  edamina: 'edamina',
  edeagos: 'edeagos',
  edeense: 'edeense',
  edeites: 'edeítes',
  edemero: 'edêmero',
  edemias: 'edemias',
  edemone: 'edêmone',
  edemono: 'edêmono',
  edenate: 'edenate',
  edendro: 'edendro',
  edeneas: 'edêneas',
  edeneos: 'edêneos',
  edenica: 'edênica',
  edenico: 'edênico',
  edenita: 'edenita',
  edenite: 'edenite',
  edeniza: 'edeniza',
  edenize: 'edenize',
  edenizo: 'edenizo',
  edentai: 'edentai',
  edentam: 'edentam',
  edentar: 'edentar',
  edentas: 'edentas',
  edentei: 'edentei',
  edentem: 'edentem',
  edentes: 'edentes',
  edentou: 'edentou',
  edesias: 'edésias',
  edesseu: 'edesseu',
  edessio: 'edéssio',
  edetana: 'edetana',
  edetano: 'edetano',
  edetato: 'edetato',
  edetica: 'edética',
  edetico: 'edético',
  edetois: 'edetóis',
  edicoes: 'edições',
  edicona: 'ediçona',
  edictal: 'edictal',
  edicula: 'edícula',
  edicule: 'edicule',
  ediculo: 'edículo',
  edifica: 'edifica',
  edifico: 'edifico',
  edilica: 'edílica',
  edilico: 'edílico',
  edilzao: 'edilzão',
  edineas: 'edíneas',
  edineos: 'edíneos',
  edipica: 'edípica',
  edipico: 'edípico',
  edipoda: 'edípoda',
  edipode: 'edípode',
  editada: 'editada',
  editado: 'editado',
  editais: 'editais',
  editamo: 'editamo',
  editara: 'editara',
  editava: 'editava',
  editeis: 'editeis',
  editemo: 'editemo',
  editimo: 'edítimo',
  editora: 'editora',
  editore: 'editore',
  editoro: 'editoro',
  edituos: 'edítuos',
  ediveis: 'edíveis',
  edobolo: 'edóbolo',
  edolito: 'edólito',
  edomeus: 'edomeus',
  edomita: 'edomita',
  edonios: 'edônios',
  edopeza: 'edopeza',
  edotica: 'edótica',
  edredao: 'edredão',
  edredom: 'edredom',
  edredon: 'edredon',
  eduarda: 'eduarda',
  educada: 'educada',
  educado: 'educado',
  educais: 'educais',
  educamo: 'educamo',
  educara: 'educara',
  educava: 'educava',
  educoes: 'eduções',
  educona: 'eduçona',
  eductos: 'eductos',
  edulcai: 'edulçai',
  edulcam: 'edulçam',
  edulcar: 'edulçar',
  edulcas: 'edulças',
  edulcei: 'edulcei',
  edulcem: 'edulcem',
  edulces: 'edulces',
  edulcou: 'edulçou',
  eduquei: 'eduquei',
  eduquem: 'eduquem',
  eduques: 'eduques',
  edutora: 'edutora',
  eduzais: 'eduzais',
  eduzamo: 'eduzamo',
  eduziam: 'eduziam',
  eduzias: 'eduzias',
  eduzida: 'eduzida',
  eduzido: 'eduzido',
  eduzimo: 'eduzimo',
  eduzira: 'eduzirá',
  efabula: 'efabula',
  efabule: 'efabule',
  efabulo: 'efabulo',
  efarada: 'efarada',
  efarado: 'efarado',
  efatide: 'efátide',
  efebato: 'efebato',
  efebiao: 'efebião',
  efebias: 'efebias',
  efebica: 'efébica',
  efebico: 'efébico',
  efector: 'efector',
  efedras: 'éfedras',
  efeitos: 'efeitos',
  efeitua: 'efeitua',
  efeitue: 'efeitue',
  efeituo: 'efeituo',
  efelias: 'efélias',
  efelide: 'efélide',
  efemera: 'efêmera',
  efemero: 'efêmero',
  efemina: 'efemina',
  efemine: 'efemine',
  efemino: 'efemino',
  efendis: 'efêndis',
  eferada: 'eferada',
  eferado: 'eferado',
  efesino: 'efesino',
  efesita: 'efesita',
  efestia: 'eféstia',
  efetica: 'efética',
  efetico: 'efético',
  efetiva: 'efetiva',
  efetive: 'efetive',
  efetivo: 'efetivo',
  efetora: 'efetora',
  efetuai: 'efetuai',
  efetuam: 'efetuam',
  efetuar: 'efetuar',
  efetuas: 'efetuas',
  efetuei: 'efetuei',
  efetuem: 'efetuem',
  efetues: 'efetues',
  efetuou: 'efetuou',
  efialta: 'efialta',
  efigiai: 'efigiai',
  efigiam: 'efigiam',
  efigiar: 'efigiar',
  efigias: 'efigias',
  efigiei: 'efigiei',
  efigiem: 'efigiem',
  efigies: 'efígies',
  efigiou: 'efigiou',
  efilios: 'efílios',
  efipias: 'efípias',
  efipida: 'efipida',
  efipios: 'efípios',
  efireia: 'efireia',
  efireus: 'efireus',
  efitias: 'efitias',
  efluais: 'efluais',
  efluiam: 'efluíam',
  efluias: 'efluías',
  efluida: 'efluída',
  efluido: 'efluído',
  efluira: 'efluíra',
  efluvio: 'eflúvio',
  efluxao: 'efluxão',
  efluxos: 'efluxos',
  eforada: 'eforada',
  eforado: 'eforado',
  eforato: 'eforato',
  eforias: 'eforias',
  eforica: 'efórica',
  eforico: 'efórico',
  efossil: 'efóssil',
  efracao: 'efração',
  efratea: 'efrátea',
  efrateo: 'efráteo',
  efrateu: 'efrateu',
  efratos: 'efratos',
  efronte: 'efronte',
  efuanga: 'efuanga',
  efugios: 'efúgios',
  efumeai: 'efumeai',
  efumear: 'efumear',
  efumeei: 'efumeei',
  efumeia: 'efumeia',
  efumeie: 'efumeie',
  efumeio: 'efumeio',
  efumeou: 'efumeou',
  efundam: 'efundam',
  efundas: 'efundas',
  efundem: 'efundem',
  efundes: 'efundes',
  efundia: 'efúndia',
  efundir: 'efundir',
  efundis: 'efundis',
  efundiu: 'efundiu',
  efusais: 'efusais',
  efusiva: 'efusiva',
  efusivo: 'efusivo',
  efusoes: 'efusões',
  efusona: 'efusona',
  efuzeis: 'efuzéis',
  egagras: 'egagras',
  egagros: 'égagros',
  egantos: 'egantos',
  egarrea: 'egárrea',
  egbanas: 'egbanas',
  egbanos: 'egbanos',
  egbeles: 'egbeles',
  egeatos: 'egeatos',
  egerana: 'egerana',
  egerias: 'egérias',
  egerina: 'egerina',
  egerino: 'egerino',
  egerita: 'egerita',
  egerito: 'egerito',
  egernia: 'egérnia',
  egerses: 'egerses',
  egestao: 'egestão',
  egestas: 'egestas',
  egetino: 'egetino',
  egialia: 'egiália',
  egialio: 'egiálio',
  egicera: 'egícera',
  egicola: 'egícola',
  egideas: 'egídeas',
  egideos: 'egídeos',
  egidios: 'egídios',
  egiense: 'egiense',
  egifila: 'egífila',
  egilope: 'egilope',
  egineas: 'egíneas',
  egineos: 'egíneos',
  egineta: 'egineta',
  egipana: 'egipana',
  egipano: 'egipano',
  egipcia: 'egípcia',
  egipcio: 'egípcio',
  egirina: 'egirina',
  egirita: 'egirita',
  egirito: 'egirito',
  egitana: 'egitana',
  egitano: 'egitano',
  eglefim: 'eglefim',
  egletes: 'egletes',
  eglisia: 'eglísia',
  eglogal: 'eglogal',
  eglogas: 'églogas',
  egobolo: 'egóbolo',
  egofaga: 'egófaga',
  egofago: 'egófago',
  egofilo: 'egófilo',
  egoicas: 'egoicas',
  egoices: 'egoíces',
  egoicos: 'egoicos',
  egoisma: 'egoismá',
  egoisme: 'egoisme',
  egoismo: 'egoísmo',
  egoista: 'egoísta',
  egolios: 'ególios',
  egometa: 'egometa',
  egopode: 'egópode',
  egosomo: 'egosomo',
  egotica: 'egótica',
  egotico: 'egótico',
  egregia: 'egrégia',
  egregio: 'egrégio',
  egressa: 'egressa',
  egresso: 'egresso',
  egretas: 'egretas',
  egretes: 'egretes',
  eguadas: 'eguadas',
  eguador: 'eguador',
  eguados: 'eguados',
  eguamos: 'eguamos',
  eguando: 'eguando',
  eguante: 'eguante',
  eguaram: 'eguaram',
  eguarao: 'eguarão',
  eguaras: 'eguaras',
  eguarei: 'eguarei',
  eguarem: 'eguarem',
  eguares: 'eguares',
  eguaria: 'eguaria',
  eguasse: 'eguasse',
  eguaste: 'eguaste',
  eguavam: 'eguavam',
  eguavas: 'eguavas',
  eguemos: 'eguemos',
  egungum: 'egungum',
  ehretia: 'ehrétia',
  eibadas: 'eibadas',
  eibados: 'eibados',
  eicadao: 'eiçadão',
  eicadas: 'eiçadas',
  eicador: 'eiçador',
  eicados: 'eiçados',
  eicamos: 'eiçamos',
  eicando: 'eiçando',
  eicante: 'eiçante',
  eicaram: 'eiçaram',
  eicarao: 'eiçarão',
  eicaras: 'eiçarás',
  eicarei: 'eiçarei',
  eicarem: 'eiçarem',
  eicares: 'eiçares',
  eicaria: 'eiçaria',
  eicarmo: 'eiçarmo',
  eicasse: 'eiçasse',
  eicaste: 'eiçaste',
  eicavam: 'eiçavam',
  eicavas: 'eiçavas',
  eicemos: 'eicemos',
  eicetas: 'eicetas',
  eichoes: 'eichões',
  eiconal: 'eiconal',
  eigreja: 'eigreja',
  eimadao: 'eimadão',
  eimadas: 'eimadas',
  eimador: 'eimador',
  eimados: 'eimados',
  eimamos: 'eimamos',
  eimando: 'eimando',
  eimante: 'eimante',
  eimaram: 'eimaram',
  eimarao: 'eimarão',
  eimaras: 'eimarás',
  eimarei: 'eimarei',
  eimarem: 'eimarem',
  eimares: 'eimares',
  eimaria: 'eimaria',
  eimarmo: 'eimarmo',
  eimasse: 'eimasse',
  eimaste: 'eimaste',
  eimavam: 'eimavam',
  eimavas: 'eimavas',
  eimemos: 'eimemos',
  eimeria: 'eiméria',
  eiradas: 'eiradas',
  eirados: 'eirados',
  eirante: 'eirante',
  eireira: 'eireira',
  eireiro: 'eireiro',
  eirenho: 'eirenho',
  eirense: 'eirense',
  eiricos: 'eiriços',
  eirinha: 'eirinha',
  eirogas: 'eirogas',
  eirogos: 'eirogos',
  eitadas: 'eitadas',
  eivadao: 'eivadão',
  eivadas: 'eivadas',
  eivador: 'eivador',
  eivados: 'eivados',
  eivamos: 'eivamos',
  eivando: 'eivando',
  eivante: 'eivante',
  eivaram: 'eivaram',
  eivarao: 'eivarão',
  eivaras: 'eivarás',
  eivarei: 'eivarei',
  eivarem: 'eivarem',
  eivares: 'eivares',
  eivaria: 'eivaria',
  eivarmo: 'eivarmo',
  eivasse: 'eivasse',
  eivaste: 'eivaste',
  eivavam: 'eivavam',
  eivavas: 'eivavas',
  eivecas: 'eivecas',
  eivemos: 'eivemos',
  eivigar: 'eivigar',
  eixadao: 'eixadão',
  eixadas: 'eixadas',
  eixador: 'eixador',
  eixados: 'eixados',
  eixamos: 'eixamos',
  eixando: 'eixando',
  eixante: 'eixante',
  eixaram: 'eixaram',
  eixarao: 'eixarão',
  eixaras: 'eixarás',
  eixarei: 'eixarei',
  eixarem: 'eixarem',
  eixares: 'eixares',
  eixaria: 'eixaria',
  eixarmo: 'eixarmo',
  eixasse: 'eixasse',
  eixaste: 'eixaste',
  eixavam: 'eixavam',
  eixavas: 'eixavas',
  eixemos: 'eixemos',
  eixiais: 'eixiais',
  eixinho: 'eixinho',
  eixismo: 'eixismo',
  eixista: 'eixista',
  ejacula: 'ejacula',
  ejacule: 'ejacule',
  ejaculo: 'ejaculo',
  ejeccao: 'ejecção',
  ejecoes: 'ejeções',
  ejecona: 'ejeçona',
  ejectai: 'ejectai',
  ejectam: 'ejectam',
  ejectar: 'ejectar',
  ejectas: 'ejectas',
  ejectei: 'ejectei',
  ejectem: 'ejectem',
  ejectes: 'ejectes',
  ejector: 'ejector',
  ejectos: 'ejectos',
  ejectou: 'ejectou',
  ejetada: 'ejetada',
  ejetado: 'ejetado',
  ejetais: 'ejetais',
  ejetamo: 'ejetamo',
  ejetara: 'ejetara',
  ejetava: 'ejetava',
  ejeteis: 'ejeteis',
  ejetemo: 'ejetemo',
  ejetiva: 'ejetiva',
  ejetivo: 'ejetivo',
  ejetora: 'ejetora',
  elabora: 'elabora',
  elabore: 'elabore',
  elaboro: 'elaboro',
  elacate: 'elacate',
  elaches: 'elaches',
  elacina: 'elacina',
  elacoes: 'elações',
  elacona: 'elaçona',
  eladoes: 'eladões',
  eladona: 'eladona',
  eladora: 'eladora',
  elafrio: 'eláfrio',
  elafros: 'élafros',
  elagato: 'elagato',
  elageno: 'elageno',
  elagico: 'elágico',
  elaicas: 'elaicas',
  elaicos: 'elaicos',
  elainas: 'elaínas',
  elamita: 'elamita',
  elampos: 'elampos',
  elancai: 'elançai',
  elancam: 'elançam',
  elancar: 'elançar',
  elancas: 'elanças',
  elancei: 'elancei',
  elancem: 'elancem',
  elances: 'elances',
  elancou: 'elançou',
  elandes: 'elandes',
  elantes: 'elantes',
  elardes: 'elardes',
  elareis: 'eláreis',
  elaremo: 'elaremo',
  elariam: 'elariam',
  elarias: 'elarias',
  elarmos: 'elarmos',
  elasmas: 'elasmas',
  elasmia: 'elásmia',
  elassem: 'elassem',
  elasses: 'elasses',
  elastes: 'elastes',
  elatero: 'elátero',
  elatina: 'elatina',
  elatine: 'elatine',
  elativa: 'elativa',
  elativo: 'elativo',
  elatora: 'elatora',
  elatros: 'elatros',
  elaveis: 'eláveis',
  elbaita: 'elbaíta',
  elbense: 'elbense',
  elcasie: 'elcasie',
  eleadao: 'eleadão',
  eleadas: 'eleadas',
  eleador: 'eleador',
  eleados: 'eleados',
  eleagno: 'eleagno',
  eleamos: 'eleamos',
  eleanas: 'eleanas',
  eleando: 'eleando',
  eleanos: 'eleanos',
  eleante: 'eleante',
  eleanto: 'eleanto',
  elearam: 'elearam',
  elearao: 'elearão',
  elearas: 'elearás',
  elearei: 'elearei',
  elearem: 'elearem',
  eleares: 'eleares',
  elearia: 'elearia',
  eleasse: 'eleasse',
  eleaste: 'eleaste',
  eleatas: 'eleatas',
  eleates: 'eleates',
  eleavam: 'eleavam',
  eleavas: 'eleavas',
  eleboro: 'eléboro',
  electro: 'electro',
  eledato: 'eledato',
  eledica: 'elédica',
  eledico: 'elédico',
  eledina: 'eledina',
  eledona: 'eledona',
  eleemos: 'eleemos',
  eleense: 'eleense',
  elefoas: 'elefoas',
  eleforo: 'eléforo',
  elegbas: 'elegbás',
  elegeis: 'elegeis',
  elegemo: 'elegemo',
  elegera: 'elegera',
  elegiam: 'elegiam',
  elegias: 'elegias',
  elegida: 'elegida',
  elegido: 'elegido',
  eleguas: 'eleguás',
  eleicao: 'eleição',
  eleitas: 'eleitas',
  eleites: 'eleítes',
  eleitor: 'eleitor',
  eleitos: 'eleitos',
  elejais: 'elejais',
  elejamo: 'elejamo',
  elemica: 'elêmica',
  elemico: 'elêmico',
  elemina: 'elemina',
  elencai: 'elencai',
  elencam: 'elencam',
  elencar: 'elencar',
  elencas: 'elencas',
  elencos: 'elencos',
  elencou: 'elencou',
  elendes: 'elendes',
  elengue: 'elengue',
  elenias: 'elênias',
  elenque: 'elenque',
  eleolea: 'eleólea',
  eleoleo: 'eleóleo',
  eleomas: 'eleomas',
  elequis: 'eléquis',
  eleroes: 'elerões',
  elescos: 'elescos',
  eletiva: 'eletiva',
  eletivo: 'eletivo',
  eletrao: 'eletrão',
  eletrio: 'elétrio',
  eletriz: 'eletriz',
  eletron: 'elétron',
  eletros: 'eletros',
  eleubas: 'eleubás',
  eleuria: 'eleúria',
  elevada: 'elevada',
  elevado: 'elevado',
  elevais: 'elevais',
  elevamo: 'elevamo',
  elevara: 'elevara',
  elevava: 'elevava',
  eleveis: 'eleveis',
  elevemo: 'elevemo',
  eliciai: 'eliciai',
  eliciam: 'eliciam',
  eliciar: 'eliciar',
  elicias: 'elicias',
  eliciei: 'eliciei',
  eliciem: 'eliciem',
  elicies: 'elicies',
  eliciou: 'eliciou',
  elicita: 'elicita',
  elicite: 'elicite',
  elicito: 'elícito',
  elicnia: 'elicnia',
  elidais: 'elidais',
  elidamo: 'elidamo',
  elidiam: 'elidiam',
  elidias: 'elidias',
  elidida: 'elidida',
  elidido: 'elidido',
  elidimo: 'elidimo',
  elidira: 'elidirá',
  eliense: 'eliense',
  eligiam: 'eligiam',
  eligias: 'eligias',
  eligida: 'eligida',
  eligido: 'eligido',
  eligira: 'eligira',
  elijais: 'elijais',
  elimeia: 'elimeia',
  elimeus: 'elimeus',
  elimina: 'elimina',
  elimine: 'elimine',
  elimino: 'elimino',
  elimins: 'elimins',
  elingas: 'elingas',
  elingue: 'elingue',
  elinvar: 'elinvar',
  eliomis: 'eliômis',
  eliotas: 'eliotas',
  elipsai: 'elipsai',
  elipsam: 'elipsam',
  elipsar: 'elipsar',
  elipsas: 'elipsas',
  elipsei: 'elipsei',
  elipsem: 'elipsem',
  elipses: 'elipses',
  elipsou: 'elipsou',
  eliseia: 'eliseia',
  elisias: 'elísias',
  elisios: 'elísios',
  elisiva: 'elisiva',
  elisivo: 'elisivo',
  elismas: 'elismas',
  elisoes: 'elisões',
  elisona: 'elisona',
  elisseu: 'elisseu',
  elitica: 'elítica',
  elitico: 'elítico',
  elitiza: 'elitiza',
  elitize: 'elitize',
  elitizo: 'elitizo',
  elitros: 'élitros',
  elixada: 'elixada',
  elixado: 'elixado',
  elixais: 'elixais',
  elixemo: 'elixemo',
  elixiam: 'elixiam',
  elixias: 'elixias',
  elixido: 'elixido',
  eliximo: 'eliximo',
  elixira: 'elixira',
  elmetes: 'elmetes',
  elminia: 'elmínia',
  elodeas: 'elódeas',
  elodeia: 'elodeia',
  elofilo: 'elófilo',
  eloforo: 'elóforo',
  elogiai: 'elogiai',
  elogial: 'elogial',
  elogiam: 'elogiam',
  elogiar: 'elogiar',
  elogias: 'elogias',
  elogiei: 'elogiei',
  elogiem: 'elogiem',
  elogies: 'elogies',
  elogios: 'elogios',
  elogiou: 'elogiou',
  eloismo: 'eloísmo',
  eloista: 'eloísta',
  elombre: 'elombre',
  elomues: 'elomués',
  elongai: 'elongai',
  elongam: 'elongam',
  elongar: 'elongar',
  elongas: 'elongas',
  elongou: 'elongou',
  elongue: 'elongue',
  eloquio: 'elóquio',
  elorias: 'elórias',
  elorino: 'elorino',
  elorios: 'elórios',
  elosias: 'elósias',
  eluamos: 'eluamos',
  elucida: 'elucida',
  elucide: 'elucide',
  elucido: 'elucido',
  elucoes: 'eluções',
  elucona: 'eluçona',
  eludais: 'eludais',
  eludamo: 'eludamo',
  eludiam: 'eludiam',
  eludias: 'eludias',
  eludida: 'eludida',
  eludido: 'eludido',
  eludimo: 'eludimo',
  eludira: 'eludirá',
  eluicao: 'eluição',
  eluidao: 'eluidão',
  eluidas: 'eluídas',
  eluidor: 'eluidor',
  eluidos: 'eluídos',
  eluieis: 'eluíeis',
  eluimos: 'eluímos',
  eluindo: 'eluindo',
  eluiram: 'eluíram',
  eluirao: 'eluirão',
  eluiras: 'eluirás',
  eluirei: 'eluirei',
  eluirem: 'eluírem',
  eluires: 'eluíres',
  eluiria: 'eluiria',
  eluisse: 'eluísse',
  eluiste: 'eluíste',
  eluivel: 'eluível',
  elumbas: 'elumbas',
  eluropo: 'elúropo',
  elusana: 'elusana',
  elusano: 'elusano',
  elusate: 'elusate',
  elusiva: 'elusiva',
  elusivo: 'elusivo',
  elutria: 'elutria',
  elutrie: 'elutrie',
  elutrio: 'elutrio',
  eluvial: 'eluvial',
  eluviao: 'eluvião',
  eluvios: 'elúvios',
  elvasia: 'elvásia',
  elvense: 'elvense',
  elviras: 'elviras',
  elxaita: 'elxaíta',
  elyitas: 'elyitas',
  elzevir: 'elzevir',
  emacada: 'emaçada',
  emacado: 'emaçado',
  emacais: 'emaçais',
  emacamo: 'emaçamo',
  emacara: 'emaçará',
  emacava: 'emaçava',
  emaceis: 'emaceis',
  emacemo: 'emacemo',
  emaciai: 'emaciai',
  emaciam: 'emaciam',
  emaciar: 'emaciar',
  emacias: 'emacias',
  emaciei: 'emaciei',
  emaciem: 'emaciem',
  emacies: 'emacies',
  emaciou: 'emaciou',
  emacula: 'emaculá',
  emacule: 'emacule',
  emaculo: 'emaculo',
  emagota: 'emagota',
  emagote: 'emagote',
  emagoto: 'emagoto',
  emagrai: 'emagrai',
  emagram: 'emagram',
  emagrar: 'emagrar',
  emagras: 'emagras',
  emagrei: 'emagrei',
  emagrem: 'emagrem',
  emagres: 'emagres',
  emagrou: 'emagrou',
  emalada: 'emalada',
  emalado: 'emalado',
  emalais: 'emalais',
  emalamo: 'emalamo',
  emalara: 'emalara',
  emalava: 'emalava',
  emaleis: 'emaleis',
  emalemo: 'emalemo',
  emalhai: 'emalhai',
  emalham: 'emalham',
  emalhar: 'emalhar',
  emalhas: 'emalhas',
  emalhea: 'emalheá',
  emalhei: 'emalhei',
  emalhem: 'emalhem',
  emalhes: 'emalhes',
  emalhou: 'emalhou',
  emalias: 'emalias',
  emanada: 'emanada',
  emanado: 'emanado',
  emanais: 'emanais',
  emanamo: 'emanamo',
  emanara: 'emanara',
  emanava: 'emanava',
  emancha: 'emanchá',
  emanche: 'emanche',
  emancho: 'emancho',
  emaneis: 'emaneis',
  emanemo: 'emanemo',
  emanios: 'emânios',
  emanoca: 'emanoca',
  emanoco: 'emanoco',
  emantai: 'emantai',
  emantam: 'emantam',
  emantar: 'emantar',
  emantas: 'emantas',
  emantei: 'emantei',
  emantem: 'emantem',
  emantes: 'emantes',
  emantou: 'emantou',
  emarada: 'emarada',
  emarado: 'emarado',
  emarais: 'emarais',
  emaramo: 'emaramo',
  emarara: 'emarará',
  emarava: 'emarava',
  emareai: 'emareai',
  emarear: 'emarear',
  emareei: 'emareei',
  emareia: 'emareia',
  emareie: 'emareie',
  emareio: 'emareio',
  emareis: 'emareis',
  emaremo: 'emaremo',
  emareou: 'emareou',
  emargea: 'emargeá',
  emarjai: 'emarjai',
  emarjam: 'emarjam',
  emarjar: 'emarjar',
  emarjas: 'emarjas',
  emarjei: 'emarjei',
  emarjem: 'emarjem',
  emarjes: 'emarjes',
  emarjou: 'emarjou',
  emassai: 'emassai',
  emassam: 'emassam',
  emassar: 'emassar',
  emassas: 'emassas',
  emassei: 'emassei',
  emassem: 'emassem',
  emasses: 'emasses',
  emassou: 'emassou',
  emastea: 'emasteá',
  emastra: 'emastra',
  emastre: 'emastre',
  emastro: 'emastro',
  ematais: 'ematais',
  emateis: 'emateis',
  ematemo: 'ematemo',
  ematera: 'ematera',
  ematiam: 'ematiam',
  ematias: 'ematias',
  ematida: 'ematida',
  ematido: 'ematido',
  ematios: 'emátios',
  embacai: 'embaçai',
  embacam: 'embaçam',
  embacar: 'embaçar',
  embacas: 'embaças',
  embacei: 'embacei',
  embacem: 'embacem',
  embaces: 'embaces',
  embacia: 'embacia',
  embacie: 'embacie',
  embacio: 'embacio',
  embacou: 'embaçou',
  embagai: 'embagai',
  embagam: 'embagam',
  embagar: 'embagar',
  embagas: 'embagas',
  embagou: 'embagou',
  embague: 'embague',
  embaiam: 'embaiam',
  embaias: 'embaías',
  embaiba: 'embaíba',
  embaida: 'embaída',
  embaido: 'embaído',
  embaira: 'embaíra',
  embaixo: 'embaixo',
  embalai: 'embalai',
  embalam: 'embalam',
  embalao: 'embalão',
  embalar: 'embalar',
  embalas: 'embalas',
  embalca: 'embalça',
  embalce: 'embalce',
  embalco: 'embalço',
  embalde: 'embalde',
  embalei: 'embalei',
  embalem: 'embalem',
  embales: 'embales',
  embalou: 'embalou',
  embalsa: 'embalsa',
  embalse: 'embalse',
  embalso: 'embalso',
  embamas: 'embamas',
  embamba: 'embamba',
  embambe: 'embambe',
  embambo: 'embambo',
  embanai: 'embanai',
  embanam: 'embanam',
  embanar: 'embanar',
  embanas: 'embanas',
  embanda: 'embanda',
  embande: 'embande',
  embando: 'embando',
  embanei: 'embanei',
  embanem: 'embanem',
  embanes: 'embanes',
  embanou: 'embanou',
  embarba: 'embarba',
  embarbe: 'embarbe',
  embarbo: 'embarbo',
  embarca: 'embarca',
  embarco: 'embarco',
  embarda: 'embarda',
  embarde: 'embarde',
  embardo: 'embardo',
  embares: 'embarés',
  embarga: 'embarga',
  embargo: 'embargo',
  embarra: 'embarra',
  embarre: 'embarre',
  embarro: 'embarro',
  embasai: 'embasai',
  embasam: 'embasam',
  embasar: 'embasar',
  embasas: 'embasas',
  embasei: 'embasei',
  embasem: 'embasem',
  embases: 'embases',
  embasou: 'embasou',
  embasta: 'embasta',
  embaste: 'embaste',
  embasti: 'embasti',
  embasto: 'embasto',
  embatam: 'embatam',
  embatas: 'embatas',
  embatei: 'embatei',
  embatem: 'embatem',
  embater: 'embater',
  embates: 'embates',
  embateu: 'embateu',
  embatia: 'embatia',
  embauba: 'embaúba',
  embauca: 'embauca',
  embauco: 'embauco',
  embaula: 'embaulá',
  embaule: 'embaule',
  embaulo: 'embaulo',
  embauva: 'embaúva',
  embazos: 'embazos',
  embebam: 'embebam',
  embebas: 'embebas',
  embebei: 'embebei',
  embebem: 'embebem',
  embeber: 'embeber',
  embebes: 'embebes',
  embebeu: 'embebeu',
  embebia: 'embebia',
  embecas: 'embecas',
  embeica: 'embeiça',
  embeice: 'embeice',
  embeico: 'embeiço',
  embelga: 'embelga',
  embelgo: 'embelgo',
  embelia: 'embélia',
  embeque: 'embeque',
  emberis: 'emberis',
  emberna: 'emberna',
  emberne: 'emberne',
  emberno: 'emberno',
  embesta: 'embesta',
  embeste: 'embeste',
  embesto: 'embesto',
  embiara: 'embiara',
  embicai: 'embicai',
  embicam: 'embicam',
  embicar: 'embicar',
  embicas: 'embicas',
  embicou: 'embicou',
  embidas: 'embidas',
  embigas: 'embigas',
  embigos: 'embigos',
  embiles: 'embiles',
  embilha: 'embilhá',
  embilhe: 'embilhe',
  embilho: 'embilho',
  embinga: 'embingá',
  embingo: 'embingo',
  embioca: 'embioca',
  embioco: 'embioco',
  embiota: 'embiotá',
  embiote: 'embiote',
  embioto: 'embioto',
  embique: 'embique',
  embirai: 'embirai',
  embiram: 'embiram',
  embirar: 'embirar',
  embiras: 'embiras',
  embirei: 'embirei',
  embirem: 'embirem',
  embires: 'embires',
  embiris: 'embiris',
  embirou: 'embirou',
  embirra: 'embirra',
  embirre: 'embirre',
  embirro: 'embirro',
  embirus: 'embirus',
  embisca: 'embisca',
  embisco: 'embisco',
  embleco: 'embleco',
  emblema: 'emblema',
  embleme: 'embleme',
  emblemo: 'emblemo',
  emblica: 'emblica',
  emblico: 'emblico',
  emboaba: 'emboaba',
  emboava: 'emboava',
  embobai: 'embobai',
  embobam: 'embobam',
  embobar: 'embobar',
  embobas: 'embobas',
  embobei: 'embobei',
  embobem: 'embobem',
  embobes: 'embobes',
  embobou: 'embobou',
  embocai: 'embocai',
  embocam: 'embocam',
  embocar: 'embocar',
  embocas: 'embocas',
  embocei: 'embocei',
  embocem: 'embocem',
  emboces: 'emboces',
  embocha: 'embocha',
  embocos: 'emboços',
  embocou: 'embocou',
  embofia: 'embófia',
  embofio: 'embófio',
  emboici: 'emboici',
  emboita: 'emboitá',
  emboite: 'emboite',
  emboito: 'emboito',
  emboiza: 'emboíza',
  emboize: 'emboíze',
  emboizo: 'emboízo',
  embojai: 'embojai',
  embojam: 'embojam',
  embojar: 'embojar',
  embojas: 'embojas',
  embojei: 'embojei',
  embojem: 'embojem',
  embojes: 'embojes',
  embojou: 'embojou',
  embolai: 'embolai',
  embolam: 'embolam',
  embolar: 'embolar',
  embolas: 'embolas',
  embolea: 'emboleá',
  embolei: 'embolei',
  embolem: 'embolem',
  emboles: 'emboles',
  emboleu: 'emboléu',
  embolha: 'embolha',
  embolia: 'embolia',
  embolio: 'embólio',
  embolon: 'êmbolon',
  embolos: 'êmbolos',
  embolou: 'embolou',
  embolsa: 'embolsa',
  embolse: 'embolse',
  embolso: 'embolso',
  embomas: 'embomas',
  embonai: 'embonai',
  embonam: 'embonam',
  embonar: 'embonar',
  embonas: 'embonas',
  embondo: 'embondo',
  embonei: 'embonei',
  embonem: 'embonem',
  embones: 'embones',
  embonou: 'embonou',
  emboque: 'emboque',
  emboras: 'emboras',
  emborca: 'emborca',
  emborco: 'emborco',
  embores: 'emborés',
  emborra: 'emborra',
  emborre: 'emborre',
  emborro: 'emborro',
  embosca: 'embosca',
  embosco: 'embosco',
  embosna: 'embosná',
  embosne: 'embosne',
  embosno: 'embosno',
  embosta: 'embosta',
  emboste: 'emboste',
  embosto: 'embosto',
  embotai: 'embotai',
  embotam: 'embotam',
  embotar: 'embotar',
  embotas: 'embotas',
  embotei: 'embotei',
  embotem: 'embotem',
  embotes: 'embotes',
  embotou: 'embotou',
  embouga: 'embougá',
  embougo: 'embougo',
  embouta: 'emboutá',
  emboute: 'emboute',
  embouto: 'embouto',
  embraba: 'embraba',
  embrabe: 'embrabe',
  embrabo: 'embrabo',
  embraca: 'embraça',
  embrace: 'embrace',
  embraco: 'embraço',
  embraga: 'embraga',
  embrago: 'embrago',
  embraia: 'embraia',
  embraie: 'embraie',
  embraio: 'embraio',
  embrama: 'embrama',
  embrame: 'embrame',
  embramo: 'embramo',
  embreai: 'embreai',
  embrear: 'embrear',
  embreei: 'embreei',
  embreia: 'embreia',
  embreie: 'embreie',
  embreio: 'embreio',
  embreou: 'embreou',
  embreta: 'embreta',
  embrete: 'embrete',
  embreto: 'embreto',
  embriao: 'embrião',
  embrica: 'embrica',
  embrico: 'embrico',
  embrida: 'embrida',
  embride: 'embride',
  embrido: 'embrido',
  embroca: 'embrocá',
  embroco: 'embroco',
  embroma: 'embroma',
  embrome: 'embrome',
  embromo: 'embromo',
  embruma: 'embruma',
  embrume: 'embrume',
  embrumo: 'embrumo',
  embruta: 'embruta',
  embrute: 'embrute',
  embruto: 'embruto',
  embruxa: 'embruxa',
  embruxe: 'embruxe',
  embruxo: 'embruxo',
  embuaba: 'embuaba',
  embuacu: 'embuaçu',
  embuava: 'embuava',
  embubas: 'embubas',
  embucai: 'embuçai',
  embucam: 'embuçam',
  embucar: 'embuçar',
  embucas: 'embuças',
  embucei: 'embucei',
  embucem: 'embucem',
  embuces: 'embuces',
  embucha: 'embucha',
  embuche: 'embuche',
  embucho: 'embucho',
  embucou: 'embuçou',
  embudai: 'embudai',
  embudam: 'embudam',
  embudar: 'embudar',
  embudas: 'embudas',
  embudei: 'embudei',
  embudem: 'embudem',
  embudes: 'embudes',
  embudou: 'embudou',
  embuias: 'embuias',
  embuiza: 'embuíza',
  embuize: 'embuíze',
  embuizo: 'embuízo',
  embures: 'emburés',
  emburis: 'emburis',
  emburla: 'emburla',
  emburra: 'emburra',
  emburre: 'emburre',
  emburro: 'emburro',
  embuste: 'embuste',
  embutam: 'embutam',
  embutas: 'embutas',
  embutem: 'embutem',
  embutes: 'embutes',
  embutia: 'embutia',
  embutir: 'embutir',
  embutis: 'embutis',
  embutiu: 'embutiu',
  embuxis: 'embuxis',
  embuzia: 'embuzia',
  embuzie: 'embuzie',
  embuzio: 'embuzio',
  emechai: 'emechai',
  emecham: 'emecham',
  emechar: 'emechar',
  emechas: 'emechas',
  emechei: 'emechei',
  emechem: 'emechem',
  emeches: 'emeches',
  emechou: 'emechou',
  emedada: 'emedada',
  emedado: 'emedado',
  emedais: 'emedais',
  emedamo: 'emedamo',
  emedara: 'emedará',
  emedava: 'emedava',
  emedeis: 'emedeis',
  emedemo: 'emedemo',
  emelada: 'emelada',
  emelado: 'emelado',
  emelais: 'emelais',
  emelamo: 'emelamo',
  emelara: 'emelará',
  emelava: 'emelava',
  emeleai: 'emeleai',
  emelear: 'emelear',
  emeleei: 'emeleei',
  emeleia: 'emeleia',
  emeleie: 'emeleie',
  emeleio: 'emeleio',
  emeleis: 'emeleis',
  emelemo: 'emelemo',
  emeleou: 'emeleou',
  emelias: 'emelias',
  emendai: 'emendai',
  emendam: 'emendam',
  emendar: 'emendar',
  emendas: 'emendas',
  emendei: 'emendei',
  emendem: 'emendem',
  emendes: 'emendes',
  emendou: 'emendou',
  emenias: 'emenias',
  emenica: 'emênica',
  emenico: 'emênico',
  emenina: 'emenina',
  emenine: 'emenine',
  emenino: 'emenino',
  emenses: 'emenses',
  ementai: 'ementai',
  ementam: 'ementam',
  ementar: 'ementar',
  ementas: 'ementas',
  ementei: 'ementei',
  ementem: 'ementem',
  ementes: 'ementes',
  ementou: 'ementou',
  emergem: 'emergem',
  emerges: 'emerges',
  emergia: 'emergia',
  emergir: 'emergir',
  emergis: 'emergis',
  emergiu: 'emergiu',
  emeriom: 'emeriom',
  emerita: 'emérita',
  emerito: 'emérito',
  emerjam: 'emerjam',
  emerjas: 'emerjas',
  emersao: 'emersão',
  emersas: 'emersas',
  emersos: 'emersos',
  emeruja: 'emeruja',
  emeruje: 'emeruje',
  emerujo: 'emerujo',
  emetica: 'emética',
  emetico: 'emético',
  emetina: 'emetina',
  emetiza: 'emetiza',
  emetize: 'emetize',
  emetizo: 'emetizo',
  emeziai: 'emeziai',
  emeziam: 'emeziam',
  emeziar: 'emeziar',
  emezias: 'emezias',
  emeziei: 'emeziei',
  emeziem: 'emeziem',
  emezies: 'emezies',
  emeziou: 'emeziou',
  emicons: 'emicons',
  emidias: 'emídias',
  emidina: 'emidina',
  emigrai: 'emigrai',
  emigram: 'emigram',
  emigrar: 'emigrar',
  emigras: 'emigras',
  emigrei: 'emigrei',
  emigrem: 'emigrem',
  emigres: 'emigres',
  emigrou: 'emigrou',
  emilias: 'emílias',
  emilios: 'emílios',
  eminias: 'emínias',
  emionos: 'emionos',
  emirado: 'emirado',
  emiraem: 'emiraém',
  emirato: 'emirato',
  emiseno: 'emiseno',
  emissao: 'emissão',
  emissor: 'emissor',
  emitais: 'emitais',
  emitamo: 'emitamo',
  emitemo: 'emitemo',
  emitiam: 'emitiam',
  emitias: 'emitias',
  emitida: 'emitida',
  emitido: 'emitido',
  emitimo: 'emitimo',
  emitira: 'emitira',
  emitron: 'êmitron',
  emmeios: 'emmeios',
  emocoes: 'emoções',
  emocona: 'emoçona',
  emodina: 'emodina',
  emofita: 'emófita',
  emoirai: 'emoirai',
  emoiram: 'emoiram',
  emoirar: 'emoirar',
  emoiras: 'emoiras',
  emoirei: 'emoirei',
  emoirem: 'emoirem',
  emoires: 'emoires',
  emoirou: 'emoirou',
  emoitai: 'emoitai',
  emoitam: 'emoitam',
  emoitar: 'emoitar',
  emoitas: 'emoitas',
  emoitei: 'emoitei',
  emoitem: 'emoitem',
  emoites: 'emoites',
  emoitou: 'emoitou',
  emoldai: 'emoldai',
  emoldam: 'emoldam',
  emoldar: 'emoldar',
  emoldas: 'emoldas',
  emoldei: 'emoldei',
  emoldem: 'emoldem',
  emoldes: 'emoldes',
  emoldou: 'emoldou',
  emolhai: 'emolhai',
  emolham: 'emolham',
  emolhar: 'emolhar',
  emolhas: 'emolhas',
  emolhei: 'emolhei',
  emolhem: 'emolhem',
  emolhes: 'emolhes',
  emolhou: 'emolhou',
  emoliam: 'emoliam',
  emolias: 'emolias',
  emolida: 'emolida',
  emolido: 'emolido',
  emolira: 'emolira',
  emonada: 'emonada',
  emonado: 'emonado',
  emonais: 'emonais',
  emonamo: 'emonamo',
  emonara: 'emonará',
  emonava: 'emonava',
  emoneis: 'emoneis',
  emonemo: 'emonemo',
  emonida: 'emônida',
  emoreai: 'emoreai',
  emorear: 'emorear',
  emoreei: 'emoreei',
  emoreia: 'emoreia',
  emoreie: 'emoreie',
  emoreio: 'emoreio',
  emoreou: 'emoreou',
  emoscai: 'emoscai',
  emoscam: 'emoscam',
  emoscar: 'emoscar',
  emoscas: 'emoscas',
  emoscou: 'emoscou',
  emosque: 'emosque',
  emostai: 'emostai',
  emostam: 'emostam',
  emostar: 'emostar',
  emostas: 'emostas',
  emostei: 'emostei',
  emostem: 'emostem',
  emostes: 'emostes',
  emostou: 'emostou',
  emotiva: 'emotiva',
  emotivo: 'emotivo',
  emourai: 'emourai',
  emouram: 'emouram',
  emourar: 'emourar',
  emouras: 'emouras',
  emourei: 'emourei',
  emourem: 'emourem',
  emoures: 'emoures',
  emourou: 'emourou',
  empacai: 'empacai',
  empacam: 'empacam',
  empacar: 'empacar',
  empacas: 'empacas',
  empacha: 'empacha',
  empache: 'empache',
  empacho: 'empacho',
  empacou: 'empacou',
  empadao: 'empadão',
  empadas: 'empadas',
  empador: 'empador',
  empados: 'empados',
  empafia: 'empáfia',
  empafio: 'empáfio',
  empalai: 'empalai',
  empalam: 'empalam',
  empalar: 'empalar',
  empalas: 'empalas',
  empalea: 'empaleá',
  empalei: 'empalei',
  empalem: 'empalem',
  empales: 'empales',
  empalha: 'empalha',
  empalhe: 'empalhe',
  empalho: 'empalho',
  empalia: 'empália',
  empalie: 'empalie',
  empalio: 'empalio',
  empalma: 'empalma',
  empalme: 'empalme',
  empalmo: 'empalmo',
  empalou: 'empalou',
  empamos: 'empamos',
  empanai: 'empanai',
  empanam: 'empanam',
  empanar: 'empanar',
  empanas: 'empanas',
  empanca: 'empanca',
  empanco: 'empanco',
  empanda: 'empanda',
  empando: 'empando',
  empanei: 'empanei',
  empanem: 'empanem',
  empanes: 'empanes',
  empanga: 'empanga',
  empango: 'empango',
  empanha: 'empanha',
  empanhe: 'empanhe',
  empanho: 'empanho',
  empanou: 'empanou',
  empante: 'empante',
  empapai: 'empapai',
  empapam: 'empapam',
  empapar: 'empapar',
  empapas: 'empapas',
  empapei: 'empapei',
  empapem: 'empapem',
  empapes: 'empapes',
  empapou: 'empapou',
  empaque: 'empaque',
  emparai: 'emparai',
  emparam: 'emparam',
  emparao: 'emparão',
  emparar: 'emparar',
  emparas: 'emparas',
  emparca: 'emparcá',
  emparco: 'emparco',
  emparda: 'emparda',
  emparde: 'emparde',
  empardo: 'empardo',
  emparea: 'empareá',
  emparei: 'emparei',
  emparem: 'emparem',
  empares: 'empares',
  emparia: 'emparia',
  emparmo: 'emparmo',
  emparou: 'emparou',
  emparra: 'emparra',
  emparre: 'emparre',
  emparro: 'emparro',
  emparva: 'emparva',
  emparve: 'emparve',
  emparvo: 'emparvo',
  empasma: 'empasma',
  empasme: 'empasme',
  empasmo: 'empasmo',
  empasse: 'empasse',
  empasta: 'empasta',
  empaste: 'empaste',
  empasto: 'empasto',
  empatai: 'empatai',
  empatam: 'empatam',
  empatar: 'empatar',
  empatas: 'empatas',
  empatei: 'empatei',
  empatem: 'empatem',
  empates: 'empates',
  empatia: 'empatia',
  empatou: 'empatou',
  empavam: 'empavam',
  empavas: 'empavas',
  empavea: 'empaveá',
  empavoa: 'empavoa',
  empavoe: 'empavoe',
  empavoo: 'empavoo',
  empeada: 'empeada',
  empeado: 'empeado',
  empeais: 'empeais',
  empeara: 'empeará',
  empeava: 'empeava',
  empecai: 'empeçai',
  empecam: 'empeçam',
  empecar: 'empeçar',
  empecas: 'empeças',
  empecei: 'empecei',
  empecem: 'empecem',
  empecer: 'empecer',
  empeces: 'empeces',
  empeceu: 'empeceu',
  empecha: 'empecha',
  empecia: 'empecia',
  empecos: 'empeços',
  empecou: 'empeçou',
  empedra: 'empedra',
  empedre: 'empedre',
  empedro: 'empedro',
  empeeis: 'empeeis',
  empegai: 'empegai',
  empegam: 'empegam',
  empegar: 'empegar',
  empegas: 'empegas',
  empegou: 'empegou',
  empegue: 'empegue',
  empeiam: 'empeiam',
  empeias: 'empeias',
  empeiem: 'empeiem',
  empeies: 'empeies',
  empeira: 'empeira',
  empeire: 'empeire',
  empeiro: 'empeiro',
  empejai: 'empejai',
  empejam: 'empejam',
  empejar: 'empejar',
  empejas: 'empejas',
  empejei: 'empejei',
  empejem: 'empejem',
  empejes: 'empejes',
  empejou: 'empejou',
  empelai: 'empelai',
  empelam: 'empelam',
  empelar: 'empelar',
  empelas: 'empelas',
  empelei: 'empelei',
  empelem: 'empelem',
  empeles: 'empeles',
  empelos: 'empelos',
  empelou: 'empelou',
  empemos: 'empemos',
  empenai: 'empenai',
  empenam: 'empenam',
  empenar: 'empenar',
  empenas: 'empenas',
  empenca: 'empencá',
  empenco: 'empenco',
  empenei: 'empenei',
  empenem: 'empenem',
  empenes: 'empenes',
  empenha: 'empenha',
  empenhe: 'empenhe',
  empenho: 'empenho',
  empenos: 'empenos',
  empenou: 'empenou',
  empeola: 'empeolá',
  empeole: 'empeole',
  empeolo: 'empeolo',
  emperla: 'emperla',
  emperle: 'emperle',
  emperlo: 'emperlo',
  emperna: 'emperna',
  emperne: 'emperne',
  emperno: 'emperno',
  emperol: 'emperol',
  emperra: 'emperra',
  emperre: 'emperre',
  emperro: 'emperro',
  empesai: 'empesai',
  empesam: 'empesam',
  empesar: 'empesar',
  empesas: 'empesas',
  empesei: 'empesei',
  empesem: 'empesem',
  empeses: 'empeses',
  empesga: 'empesga',
  empesgo: 'empesgo',
  empesos: 'empesos',
  empesou: 'empesou',
  empesta: 'empesta',
  empeste: 'empeste',
  empesto: 'empesto',
  empetro: 'empetro',
  empezai: 'empezai',
  empezam: 'empezam',
  empezar: 'empezar',
  empezas: 'empezas',
  empezei: 'empezei',
  empezem: 'empezem',
  empezes: 'empezes',
  empezou: 'empezou',
  empicai: 'empicai',
  empicam: 'empicam',
  empicar: 'empicar',
  empicas: 'empicas',
  empicou: 'empicou',
  empidas: 'êmpidas',
  empidea: 'empídea',
  empideo: 'empídeo',
  empiema: 'empiema',
  empiese: 'empiese',
  empigem: 'empigem',
  empilha: 'empilha',
  empilhe: 'empilhe',
  empilho: 'empilho',
  empinai: 'empinai',
  empinam: 'empinam',
  empinar: 'empinar',
  empinas: 'empinas',
  empinea: 'empínea',
  empinei: 'empinei',
  empinem: 'empinem',
  empineo: 'empíneo',
  empines: 'empines',
  empinou: 'empinou',
  empiola: 'empiolá',
  empiole: 'empiole',
  empiolo: 'empiolo',
  empiora: 'empiora',
  empiore: 'empiore',
  empioro: 'empioro',
  empiose: 'empiose',
  empipai: 'empipai',
  empipam: 'empipam',
  empipar: 'empipar',
  empipas: 'empipas',
  empipei: 'empipei',
  empipem: 'empipem',
  empipes: 'empipes',
  empipou: 'empipou',
  empique: 'empique',
  empirea: 'empírea',
  empireo: 'empíreo',
  empiria: 'empiria',
  empisca: 'empisca',
  empisco: 'empisco',
  empises: 'êmpises',
  emplaca: 'emplaca',
  emplaco: 'emplaco',
  empluma: 'empluma',
  emplume: 'emplume',
  emplumo: 'emplumo',
  empoada: 'empoada',
  empoado: 'empoado',
  empoais: 'empoais',
  empoamo: 'empoamo',
  empoara: 'empoará',
  empoava: 'empoava',
  empobra: 'empobrá',
  empobre: 'empobre',
  empobro: 'empobro',
  empocai: 'empoçai',
  empocam: 'empoçam',
  empocar: 'empoçar',
  empocas: 'empoças',
  empocei: 'empocei',
  empocem: 'empocem',
  empoces: 'empoces',
  empocou: 'empoçou',
  empoeis: 'empoeis',
  empoemo: 'empoemo',
  empofes: 'empofes',
  empofia: 'empófia',
  empofio: 'empófio',
  empofos: 'empofos',
  empoita: 'empoita',
  empoite: 'empoite',
  empoito: 'empoito',
  empolai: 'empolai',
  empolam: 'empolam',
  empolar: 'empolar',
  empolas: 'empolas',
  empolea: 'empoleá',
  empolei: 'empolei',
  empolem: 'empolem',
  empoles: 'empoles',
  empolga: 'empolga',
  empolgo: 'empolgo',
  empolha: 'empolha',
  empolhe: 'empolhe',
  empolho: 'empolho',
  empolma: 'empolma',
  empolme: 'empolme',
  empolmo: 'empolmo',
  empolou: 'empolou',
  empolto: 'empolto',
  empolva: 'empolvá',
  empolve: 'empolve',
  empolvo: 'empolvo',
  empomba: 'empomba',
  empombe: 'empombe',
  empombo: 'empombo',
  emponda: 'emponda',
  emponta: 'emponta',
  emponte: 'emponte',
  emponto: 'emponto',
  empopai: 'empopai',
  empopam: 'empopam',
  empopar: 'empopar',
  empopas: 'empopas',
  empopei: 'empopei',
  empopem: 'empopem',
  empopes: 'empopes',
  empopou: 'empopou',
  emporca: 'emporca',
  emporco: 'emporco',
  emporia: 'empória',
  emporio: 'empório',
  empossa: 'empossa',
  emposse: 'emposse',
  emposso: 'emposso',
  emposta: 'emposta',
  emposte: 'emposte',
  emposto: 'emposto',
  empraza: 'empraza',
  empraze: 'empraze',
  emprazo: 'emprazo',
  emprega: 'emprega',
  emprego: 'emprego',
  empresa: 'empresa',
  emprese: 'emprese',
  empreso: 'empreso',
  emprimo: 'emprimo',
  emproai: 'emproai',
  emproam: 'emproam',
  emproar: 'emproar',
  emproas: 'emproas',
  emproei: 'emproei',
  emproem: 'emproem',
  emproes: 'emproes',
  emproou: 'emproou',
  empruma: 'empruma',
  emprume: 'emprume',
  emprumo: 'emprumo',
  empucas: 'empucas',
  empucos: 'empucos',
  empulga: 'empulgá',
  empulgo: 'empulgo',
  empulha: 'empulha',
  empulhe: 'empulhe',
  empulho: 'empulho',
  empunha: 'empunha',
  empunhe: 'empunhe',
  empunho: 'empunho',
  empunir: 'empunir',
  empupai: 'empupai',
  empupam: 'empupam',
  empupar: 'empupar',
  empupas: 'empupas',
  empupei: 'empupei',
  empupem: 'empupem',
  empupes: 'empupes',
  empupos: 'empupos',
  empupou: 'empupou',
  empurra: 'empurra',
  empurre: 'empurre',
  empurro: 'empurro',
  empusas: 'empusas',
  empuxai: 'empuxai',
  empuxam: 'empuxam',
  empuxao: 'empuxão',
  empuxar: 'empuxar',
  empuxas: 'empuxas',
  empuxei: 'empuxei',
  empuxem: 'empuxem',
  empuxes: 'empuxes',
  empuxos: 'empuxos',
  empuxou: 'empuxou',
  emudeca: 'emudeça',
  emudece: 'emudece',
  emudeci: 'emudeci',
  emudeco: 'emudeço',
  emulada: 'emulada',
  emulado: 'emulado',
  emulais: 'emulais',
  emulamo: 'emulamo',
  emulara: 'emulara',
  emulava: 'emulava',
  emuleis: 'emuleis',
  emulemo: 'emulemo',
  emulsao: 'emulsão',
  emulsor: 'emulsor',
  emundai: 'emundai',
  emundam: 'emundam',
  emundar: 'emundar',
  emundas: 'emundas',
  emundei: 'emundei',
  emundem: 'emundem',
  emundes: 'emundes',
  emundou: 'emundou',
  emurada: 'emurada',
  emurado: 'emurado',
  emurcha: 'emurchá',
  emurche: 'emurche',
  emurcho: 'emurcho',
  enacoes: 'enações',
  enacona: 'enaçona',
  enaedro: 'enaedro',
  enagena: 'enagená',
  enagene: 'enagene',
  enageno: 'enageno',
  enaguas: 'enáguas',
  enaifai: 'enaifai',
  enaifam: 'enaifam',
  enaifar: 'enaifar',
  enaifas: 'enaifas',
  enaifei: 'enaifei',
  enaifem: 'enaifem',
  enaifes: 'enaifes',
  enaifou: 'enaifou',
  enaipai: 'enaipai',
  enaipam: 'enaipam',
  enaipar: 'enaipar',
  enaipas: 'enaipas',
  enaipei: 'enaipei',
  enaipem: 'enaipem',
  enaipes: 'enaipes',
  enaipou: 'enaipou',
  enalage: 'enálage',
  enalita: 'enalita',
  enaltai: 'enaltai',
  enaltam: 'enaltam',
  enaltar: 'enaltar',
  enaltas: 'enaltas',
  enaltei: 'enaltei',
  enaltem: 'enaltem',
  enaltes: 'enaltes',
  enaltou: 'enaltou',
  enamina: 'enamina',
  enamora: 'enamora',
  enamore: 'enamore',
  enamoro: 'enamoro',
  enancia: 'enância',
  enantal: 'enantal',
  enantes: 'enantes',
  enantol: 'enantol',
  enantos: 'enantos',
  enapupe: 'enapupê',
  enargia: 'enargia',
  enarrai: 'enarrai',
  enarram: 'enarram',
  enarrar: 'enarrar',
  enarras: 'enarras',
  enarrei: 'enarrei',
  enarrem: 'enarrem',
  enarres: 'enarres',
  enarrou: 'enarrou',
  enastra: 'enastra',
  enastre: 'enastre',
  enastro: 'enastro',
  enatada: 'enatada',
  enatado: 'enatado',
  enatais: 'enatais',
  enatamo: 'enatamo',
  enatara: 'enatará',
  enatava: 'enatava',
  enateis: 'enateis',
  enatemo: 'enatemo',
  enaulio: 'enáulio',
  enausea: 'enauseá',
  enazimo: 'enázimo',
  encabai: 'encabai',
  encabam: 'encabam',
  encabar: 'encabar',
  encabas: 'encabas',
  encabei: 'encabei',
  encabem: 'encabem',
  encabes: 'encabes',
  encabou: 'encabou',
  encacha: 'encacha',
  encache: 'encache',
  encacho: 'encacho',
  encacia: 'encacia',
  encadas: 'encadas',
  encadea: 'encadeá',
  encados: 'encados',
  encadro: 'encadro',
  encafua: 'encafua',
  encafue: 'encafue',
  encafuo: 'encafuo',
  encaixa: 'encaixa',
  encaixe: 'encaixe',
  encaixo: 'encaixo',
  encalai: 'encalai',
  encalam: 'encalam',
  encalar: 'encalar',
  encalas: 'encalas',
  encalca: 'encalca',
  encalce: 'encalce',
  encalco: 'encalço',
  encalda: 'encaldá',
  encalde: 'encalde',
  encaldo: 'encaldo',
  encalei: 'encalei',
  encalem: 'encalem',
  encales: 'encales',
  encalga: 'encalga',
  encalgo: 'encalgo',
  encalha: 'encalha',
  encalhe: 'encalhe',
  encalho: 'encalho',
  encalia: 'encalia',
  encalir: 'encalir',
  encalis: 'encalis',
  encaliu: 'encaliu',
  encalma: 'encalma',
  encalme: 'encalme',
  encalmo: 'encalmo',
  encalou: 'encalou',
  encamai: 'encamai',
  encamam: 'encamam',
  encamar: 'encamar',
  encamas: 'encamas',
  encamba: 'encamba',
  encambe: 'encambe',
  encambo: 'encambo',
  encamei: 'encamei',
  encamem: 'encamem',
  encames: 'encames',
  encamou: 'encamou',
  encampa: 'encampa',
  encampe: 'encampe',
  encampo: 'encampo',
  encanai: 'encanai',
  encanam: 'encanam',
  encanar: 'encanar',
  encanas: 'encanas',
  encanei: 'encanei',
  encanem: 'encanem',
  encanes: 'encanes',
  encanga: 'encanga',
  encango: 'encango',
  encanha: 'encanhá',
  encanhe: 'encanhe',
  encanho: 'encanho',
  encanoa: 'encanoa',
  encanoe: 'encanoe',
  encanoo: 'encanoo',
  encanou: 'encanou',
  encanta: 'encanta',
  encante: 'encante',
  encanto: 'encanto',
  encapai: 'encapai',
  encapam: 'encapam',
  encapar: 'encapar',
  encapas: 'encapas',
  encapei: 'encapei',
  encapem: 'encapem',
  encapes: 'encapes',
  encapou: 'encapou',
  encarai: 'encarai',
  encaram: 'encaram',
  encarar: 'encarar',
  encaras: 'encaras',
  encarda: 'encarda',
  encarde: 'encarde',
  encardi: 'encardi',
  encardo: 'encardo',
  encarei: 'encarei',
  encarem: 'encarem',
  encares: 'encares',
  encarga: 'encarga',
  encargo: 'encargo',
  encarna: 'encarna',
  encarne: 'encarne',
  encarno: 'encarno',
  encarou: 'encarou',
  encarpa: 'encarpa',
  encarpo: 'encarpo',
  encarra: 'encarra',
  encarre: 'encarre',
  encarro: 'encarro',
  encarta: 'encarta',
  encarte: 'encarte',
  encarto: 'encarto',
  encarva: 'encarva',
  encasai: 'encasai',
  encasam: 'encasam',
  encasar: 'encasar',
  encasas: 'encasas',
  encasca: 'encasca',
  encasco: 'encasco',
  encasei: 'encasei',
  encasem: 'encasem',
  encases: 'encases',
  encasou: 'encasou',
  encauda: 'encaudá',
  encaude: 'encaude',
  encaudo: 'encaudo',
  encauma: 'encauma',
  encavai: 'encavai',
  encavam: 'encavam',
  encavar: 'encavar',
  encavas: 'encavas',
  encavei: 'encavei',
  encavem: 'encavem',
  encaves: 'encaves',
  encavou: 'encavou',
  encedra: 'encedra',
  encedre: 'encedre',
  encedro: 'encedro',
  enceira: 'enceirá',
  enceire: 'enceire',
  enceiro: 'enceiro',
  encelai: 'encelai',
  encelam: 'encelam',
  encelar: 'encelar',
  encelas: 'encelas',
  encelei: 'encelei',
  encelem: 'encelem',
  enceles: 'enceles',
  encelia: 'encélia',
  encelou: 'encelou',
  encenai: 'encenai',
  encenam: 'encenam',
  encenar: 'encenar',
  encenas: 'encenas',
  encenda: 'encenda',
  encende: 'encende',
  encendi: 'encendi',
  encendo: 'encendo',
  encenei: 'encenei',
  encenem: 'encenem',
  encenes: 'encenes',
  encenia: 'encênia',
  encenou: 'encenou',
  encenso: 'encenso',
  encepai: 'encepai',
  encepam: 'encepam',
  encepar: 'encepar',
  encepas: 'encepas',
  encepei: 'encepei',
  encepem: 'encepem',
  encepes: 'encepes',
  encepou: 'encepou',
  encerai: 'encerai',
  enceram: 'enceram',
  encerar: 'encerar',
  enceras: 'enceras',
  encerei: 'encerei',
  encerem: 'encerem',
  enceres: 'enceres',
  encerou: 'encerou',
  encerra: 'encerra',
  encerre: 'encerre',
  encerro: 'encerro',
  encerta: 'encerta',
  encerte: 'encerte',
  encerto: 'encerto',
  encesta: 'encesta',
  enceste: 'enceste',
  encesto: 'encesto',
  encetai: 'encetai',
  encetam: 'encetam',
  encetar: 'encetar',
  encetas: 'encetas',
  encetei: 'encetei',
  encetem: 'encetem',
  encetes: 'encetes',
  encetou: 'encetou',
  enchais: 'enchais',
  enchamo: 'enchamo',
  encheio: 'encheio',
  encheis: 'encheis',
  enchemo: 'enchemo',
  enchera: 'enchera',
  enchiam: 'enchiam',
  enchias: 'enchias',
  enchida: 'enchida',
  enchido: 'enchido',
  enchoca: 'enchoça',
  enchoce: 'enchoce',
  enchoco: 'enchoço',
  enchova: 'enchova',
  enchuis: 'enchuís',
  enciese: 'enciese',
  encilha: 'encilha',
  encilhe: 'encilhe',
  encilho: 'encilho',
  encimai: 'encimai',
  encimam: 'encimam',
  encimar: 'encimar',
  encimas: 'encimas',
  encimei: 'encimei',
  encimem: 'encimem',
  encimes: 'encimes',
  encimou: 'encimou',
  encinha: 'encinhá',
  encinhe: 'encinhe',
  encinho: 'encinho',
  encinta: 'encinta',
  encinte: 'encinte',
  encinto: 'encinto',
  encinza: 'encinza',
  encinze: 'encinze',
  encinzo: 'encinzo',
  enciosa: 'enciosa',
  encioso: 'encioso',
  encirra: 'encirrá',
  encirre: 'encirre',
  encirro: 'encirro',
  encisca: 'enciscá',
  encisco: 'encisco',
  encista: 'encista',
  enciste: 'enciste',
  encisto: 'encisto',
  enciuma: 'enciuma',
  enciume: 'enciume',
  enciumo: 'enciumo',
  enclave: 'enclave',
  enclise: 'ênclise',
  encobra: 'encobra',
  encobre: 'encobre',
  encobri: 'encobri',
  encobro: 'encobro',
  encocha: 'encocha',
  encoche: 'encoche',
  encocho: 'encocho',
  encodai: 'encodai',
  encodam: 'encodam',
  encodar: 'encodar',
  encodas: 'encodas',
  encodea: 'encodeá',
  encodei: 'encodei',
  encodem: 'encodem',
  encodes: 'encodes',
  encodoa: 'encodoá',
  encodoe: 'encodoe',
  encodoo: 'encodoo',
  encodou: 'encodou',
  encofai: 'encofai',
  encofam: 'encofam',
  encofar: 'encofar',
  encofas: 'encofas',
  encofei: 'encofei',
  encofem: 'encofem',
  encofes: 'encofes',
  encofou: 'encofou',
  encofra: 'encofra',
  encofre: 'encofre',
  encofro: 'encofro',
  encoifa: 'encoifa',
  encoife: 'encoife',
  encoifo: 'encoifo',
  encoima: 'encoima',
  encoime: 'encoime',
  encoimo: 'encoimo',
  encoira: 'encoira',
  encoire: 'encoire',
  encoiro: 'encoiro',
  encoita: 'encoitá',
  encoite: 'encoite',
  encoito: 'encoito',
  encolai: 'encolai',
  encolam: 'encolam',
  encolar: 'encolar',
  encolas: 'encolas',
  encolei: 'encolei',
  encolem: 'encolem',
  encoles: 'encoles',
  encolha: 'encolha',
  encolhe: 'encolhe',
  encolhi: 'encolhi',
  encolho: 'encolho',
  encolou: 'encolou',
  encombo: 'encombo',
  encomia: 'encomia',
  encomie: 'encomie',
  encomio: 'encômio',
  enconai: 'enconai',
  enconam: 'enconam',
  enconar: 'enconar',
  enconas: 'enconas',
  enconca: 'enconca',
  enconco: 'enconco',
  enconda: 'enconda',
  enconde: 'enconde',
  encondo: 'encondo',
  enconei: 'enconei',
  enconem: 'enconem',
  encones: 'encones',
  enconou: 'enconou',
  encopai: 'encopai',
  encopam: 'encopam',
  encopar: 'encopar',
  encopas: 'encopas',
  encopei: 'encopei',
  encopem: 'encopem',
  encopes: 'encopes',
  encopou: 'encopou',
  encorai: 'encorai',
  encoram: 'encoram',
  encorar: 'encorar',
  encoras: 'encoras',
  encorca: 'encorcá',
  encorco: 'encorco',
  encorei: 'encorei',
  encorem: 'encorem',
  encores: 'encores',
  encorna: 'encorna',
  encorne: 'encorne',
  encorno: 'encorno',
  encorou: 'encorou',
  encorpa: 'encorpa',
  encorpe: 'encorpe',
  encorpo: 'encorpo',
  encosta: 'encosta',
  encoste: 'encoste',
  encosto: 'encosto',
  encoura: 'encoura',
  encoure: 'encoure',
  encouro: 'encouro',
  encouta: 'encoutá',
  encoute: 'encoute',
  encouto: 'encouto',
  encovai: 'encovai',
  encovam: 'encovam',
  encovar: 'encovar',
  encovas: 'encovas',
  encovei: 'encovei',
  encovem: 'encovem',
  encoves: 'encoves',
  encovou: 'encovou',
  encozam: 'encozam',
  encozas: 'encozas',
  encozei: 'encozei',
  encozem: 'encozem',
  encozer: 'encozer',
  encozes: 'encozes',
  encozeu: 'encozeu',
  encozia: 'encozia',
  encrava: 'encrava',
  encrave: 'encrave',
  encravo: 'encravo',
  encrina: 'encrina',
  encrino: 'encrino',
  encrisa: 'encrisa',
  encrise: 'encrise',
  encriso: 'encriso',
  encroca: 'encrocá',
  encroco: 'encroco',
  encrope: 'encrope',
  encruai: 'encruai',
  encruam: 'encruam',
  encruar: 'encruar',
  encruas: 'encruas',
  encruei: 'encruei',
  encruem: 'encruem',
  encrues: 'encrues',
  encruou: 'encruou',
  encrupa: 'encrupa',
  encrupi: 'encrupi',
  encrupo: 'encrupo',
  encruza: 'encruza',
  encruze: 'encruze',
  encruzo: 'encruzo',
  encubai: 'encubai',
  encubam: 'encubam',
  encubar: 'encubar',
  encubas: 'encubas',
  encubei: 'encubei',
  encubem: 'encubem',
  encubes: 'encubes',
  encubou: 'encubou',
  encubra: 'encubra',
  encubro: 'encubro',
  encucai: 'encucai',
  encucam: 'encucam',
  encucar: 'encucar',
  encucas: 'encucas',
  encucou: 'encucou',
  enculpa: 'enculpa',
  enculpe: 'enculpe',
  enculpo: 'enculpo',
  encumea: 'encumeá',
  encumes: 'encumes',
  encunha: 'encunha',
  encunhe: 'encunhe',
  encunho: 'encunho',
  encuque: 'encuque',
  encurta: 'encurta',
  encurte: 'encurte',
  encurto: 'encurto',
  encurva: 'encurva',
  encurve: 'encurve',
  encurvo: 'encurvo',
  endacas: 'endacas',
  endarco: 'endarco',
  endaval: 'endaval',
  endecha: 'endecha',
  endeche: 'endeche',
  endecho: 'endecho',
  endejai: 'endejai',
  endejam: 'endejam',
  endejar: 'endejar',
  endejas: 'endejas',
  endejei: 'endejei',
  endejem: 'endejem',
  endejes: 'endejes',
  endejou: 'endejou',
  endemia: 'endemia',
  endenta: 'endenta',
  endente: 'endente',
  endento: 'endento',
  enderos: 'ênderos',
  endeusa: 'endeusa',
  endeuse: 'endeuse',
  endeuso: 'endeuso',
  endezes: 'endezes',
  endiche: 'endiche',
  endilga: 'endilga',
  endilgo: 'endilgo',
  endimio: 'endímio',
  endinha: 'endinhá',
  endinhe: 'endinhe',
  endinho: 'endinho',
  enditos: 'ênditos',
  endivas: 'endivas',
  endivia: 'endívia',
  endoada: 'endoada',
  endoado: 'endoado',
  endocia: 'endócia',
  endogas: 'endogás',
  endogea: 'endógea',
  endogeo: 'endógeo',
  endoida: 'endoida',
  endoide: 'endoide',
  endoido: 'endoído',
  endorar: 'endorar',
  endorsa: 'endorsa',
  endorse: 'endorse',
  endorso: 'endorso',
  endossa: 'endossa',
  endosse: 'endosse',
  endosso: 'endosso',
  endotia: 'endótia',
  endouda: 'endouda',
  endoude: 'endoude',
  endoudo: 'endoudo',
  endouto: 'endouto',
  endraga: 'endragá',
  endrago: 'endrago',
  endrina: 'endrina',
  endroes: 'endrões',
  enduape: 'enduape',
  endudas: 'endudas',
  endudos: 'endudos',
  enduias: 'enduiás',
  endumba: 'endumba',
  endurai: 'endurai',
  enduram: 'enduram',
  endurar: 'endurar',
  enduras: 'enduras',
  endurei: 'endurei',
  endurem: 'endurem',
  endures: 'endures',
  enduros: 'enduros',
  endurou: 'endurou',
  eneadas: 'enéadas',
  eneades: 'enéades',
  eneates: 'eneates',
  enebria: 'enebria',
  enebrie: 'enebrie',
  enebrio: 'enebrio',
  eneense: 'eneense',
  eneides: 'eneides',
  enelzao: 'enelzão',
  enemica: 'enêmica',
  enemico: 'enêmico',
  enenses: 'enenses',
  enequim: 'enequim',
  energia: 'energia',
  enervai: 'enervai',
  enervam: 'enervam',
  enervar: 'enervar',
  enervas: 'enervas',
  enervea: 'enérvea',
  enervei: 'enervei',
  enervem: 'enervem',
  enerveo: 'enérveo',
  enerves: 'enerves',
  enervou: 'enervou',
  enesgai: 'enesgai',
  enesgam: 'enesgam',
  enesgar: 'enesgar',
  enesgas: 'enesgas',
  enesgou: 'enesgou',
  enesgue: 'enesgue',
  enesima: 'enésima',
  enesimo: 'enésimo',
  enesois: 'enesóis',
  enetica: 'enética',
  enetico: 'enético',
  enevada: 'enevada',
  enevado: 'enevado',
  enevais: 'enevais',
  enevamo: 'enevamo',
  enevara: 'enevará',
  enevava: 'enevava',
  eneveis: 'eneveis',
  enevemo: 'enevemo',
  enevoai: 'enevoai',
  enevoam: 'enevoam',
  enevoar: 'enevoar',
  enevoas: 'enevoas',
  enevoei: 'enevoei',
  enevoem: 'enevoem',
  enevoes: 'enevoes',
  enevoou: 'enevoou',
  enfadai: 'enfadai',
  enfadam: 'enfadam',
  enfadar: 'enfadar',
  enfadas: 'enfadas',
  enfadei: 'enfadei',
  enfadem: 'enfadem',
  enfades: 'enfades',
  enfados: 'enfados',
  enfadou: 'enfadou',
  enfaixa: 'enfaixa',
  enfaixe: 'enfaixe',
  enfaixo: 'enfaixo',
  enfanga: 'enfangá',
  enfango: 'enfango',
  enfarai: 'enfarai',
  enfaram: 'enfaram',
  enfarar: 'enfarar',
  enfaras: 'enfaras',
  enfarda: 'enfarda',
  enfarde: 'enfarde',
  enfardo: 'enfardo',
  enfarea: 'enfareá',
  enfarei: 'enfarei',
  enfarem: 'enfarem',
  enfares: 'enfares',
  enfarna: 'enfarná',
  enfarne: 'enfarne',
  enfarno: 'enfarno',
  enfaroa: 'enfaroá',
  enfaroe: 'enfaroe',
  enfaroo: 'enfaroo',
  enfarou: 'enfarou',
  enfarpa: 'enfarpá',
  enfarpe: 'enfarpe',
  enfarpo: 'enfarpo',
  enfarta: 'enfarta',
  enfarte: 'enfarte',
  enfarto: 'enfarto',
  enfasai: 'enfasai',
  enfasam: 'enfasam',
  enfasar: 'enfasar',
  enfasas: 'enfasas',
  enfasei: 'enfasei',
  enfasem: 'enfasem',
  enfases: 'ênfases',
  enfasou: 'enfasou',
  enfatua: 'enfatua',
  enfatue: 'enfatue',
  enfatuo: 'enfatuo',
  enfeada: 'enfeada',
  enfeado: 'enfeado',
  enfeais: 'enfeais',
  enfeara: 'enfeará',
  enfeava: 'enfeava',
  enfebra: 'enfebrá',
  enfebre: 'enfebre',
  enfebro: 'enfebro',
  enfeeis: 'enfeeis',
  enfeiai: 'enfeiai',
  enfeiam: 'enfeiam',
  enfeiar: 'enfeiar',
  enfeias: 'enfeias',
  enfeiei: 'enfeiei',
  enfeiem: 'enfeiem',
  enfeies: 'enfeies',
  enfeiou: 'enfeiou',
  enfeira: 'enfeira',
  enfeire: 'enfeire',
  enfeiro: 'enfeiro',
  enfeita: 'enfeita',
  enfeite: 'enfeite',
  enfeito: 'enfeito',
  enfeixa: 'enfeixa',
  enfeixe: 'enfeixe',
  enfeixo: 'enfeixo',
  enfelpa: 'enfelpá',
  enfelpe: 'enfelpe',
  enfelpo: 'enfelpo',
  enfenai: 'enfenai',
  enfenam: 'enfenam',
  enfenar: 'enfenar',
  enfenas: 'enfenas',
  enfenei: 'enfenei',
  enfenem: 'enfenem',
  enfenes: 'enfenes',
  enfenou: 'enfenou',
  enferia: 'enféria',
  enferma: 'enferma',
  enferme: 'enferme',
  enfermo: 'enfermo',
  enferra: 'enferrá',
  enferre: 'enferre',
  enferro: 'enferro',
  enfesta: 'enfesta',
  enfeste: 'enfeste',
  enfesto: 'enfesto',
  enfeuda: 'enfeuda',
  enfeude: 'enfeude',
  enfeudo: 'enfeudo',
  enfezai: 'enfezai',
  enfezam: 'enfezam',
  enfezar: 'enfezar',
  enfezas: 'enfezas',
  enfezei: 'enfezei',
  enfezem: 'enfezem',
  enfezes: 'enfezes',
  enfezou: 'enfezou',
  enfiada: 'enfiada',
  enfiado: 'enfiado',
  enfiais: 'enfiais',
  enfiamo: 'enfiamo',
  enfiara: 'enfiara',
  enfiava: 'enfiava',
  enficar: 'enficar',
  enfieis: 'enfieis',
  enfiemo: 'enfiemo',
  enfilos: 'enfilos',
  enfinca: 'enfinca',
  enfinco: 'enfinco',
  enfirma: 'enfirmá',
  enfirme: 'enfirme',
  enfirmo: 'enfirmo',
  enfitai: 'enfitai',
  enfitam: 'enfitam',
  enfitar: 'enfitar',
  enfitas: 'enfitas',
  enfitei: 'enfitei',
  enfitem: 'enfitem',
  enfites: 'enfites',
  enfitia: 'enfitia',
  enfitou: 'enfitou',
  enfixai: 'enfixai',
  enfixam: 'enfixam',
  enfixar: 'enfixar',
  enfixas: 'enfixas',
  enfixei: 'enfixei',
  enfixem: 'enfixem',
  enfixes: 'enfixes',
  enfixou: 'enfixou',
  enflora: 'enflora',
  enflore: 'enflore',
  enfloro: 'enfloro',
  enfobia: 'enfobia',
  enfobie: 'enfobie',
  enfobio: 'enfobio',
  enfobos: 'ênfobos',
  enfocai: 'enfocai',
  enfocam: 'enfocam',
  enfocar: 'enfocar',
  enfocas: 'enfocas',
  enfocou: 'enfocou',
  enfogai: 'enfogai',
  enfogam: 'enfogam',
  enfogar: 'enfogar',
  enfogas: 'enfogas',
  enfogou: 'enfogou',
  enfogue: 'enfogue',
  enfoica: 'enfoiçá',
  enfoice: 'enfoice',
  enfoico: 'enfoiço',
  enfolai: 'enfolai',
  enfolam: 'enfolam',
  enfolar: 'enfolar',
  enfolas: 'enfolas',
  enfolei: 'enfolei',
  enfolem: 'enfolem',
  enfoles: 'enfoles',
  enfolha: 'enfolha',
  enfolhe: 'enfolhe',
  enfolho: 'enfolho',
  enfolou: 'enfolou',
  enfonda: 'enfonda',
  enfonde: 'enfonde',
  enfondi: 'enfondi',
  enfondo: 'enfondo',
  enfoque: 'enfoque',
  enforca: 'enforca',
  enforco: 'enforco',
  enforja: 'enforja',
  enforje: 'enforje',
  enforjo: 'enforjo',
  enforma: 'enforma',
  enforme: 'enforme',
  enformo: 'enformo',
  enforna: 'enforna',
  enforne: 'enforne',
  enforno: 'enforno',
  enforra: 'enforrá',
  enforre: 'enforre',
  enforro: 'enforro',
  enforta: 'enforta',
  enforte: 'enforte',
  enforti: 'enforti',
  enforto: 'enforto',
  enfouca: 'enfouçá',
  enfouce: 'enfouce',
  enfouco: 'enfouço',
  enfraca: 'enfracá',
  enfraco: 'enfraco',
  enfraga: 'enfraga',
  enfrago: 'enfrago',
  enfreai: 'enfreai',
  enfrear: 'enfrear',
  enfreei: 'enfreei',
  enfreia: 'enfreia',
  enfreie: 'enfreie',
  enfreio: 'enfreio',
  enfrena: 'enfrena',
  enfrene: 'enfrene',
  enfreno: 'enfreno',
  enfreou: 'enfreou',
  enfriai: 'enfriai',
  enfriam: 'enfriam',
  enfriar: 'enfriar',
  enfrias: 'enfrias',
  enfriei: 'enfriei',
  enfriem: 'enfriem',
  enfries: 'enfries',
  enfriou: 'enfriou',
  enfruta: 'enfruta',
  enfrute: 'enfrute',
  enfruto: 'enfruto',
  enfuada: 'enfuada',
  enfuado: 'enfuado',
  enfuais: 'enfuais',
  enfuamo: 'enfuamo',
  enfuara: 'enfuará',
  enfuava: 'enfuava',
  enfueis: 'enfueis',
  enfuemo: 'enfuemo',
  enfumai: 'enfumai',
  enfumam: 'enfumam',
  enfumar: 'enfumar',
  enfumas: 'enfumas',
  enfumei: 'enfumei',
  enfumem: 'enfumem',
  enfumes: 'enfumes',
  enfumou: 'enfumou',
  enfunai: 'enfunai',
  enfunam: 'enfunam',
  enfunar: 'enfunar',
  enfunas: 'enfunas',
  enfunei: 'enfunei',
  enfunem: 'enfunem',
  enfunes: 'enfunes',
  enfunou: 'enfunou',
  enfurda: 'enfurdá',
  enfurde: 'enfurde',
  enfurdo: 'enfurdo',
  enfuria: 'enfuria',
  enfurie: 'enfurie',
  enfurio: 'enfurio',
  enfurna: 'enfurna',
  enfurne: 'enfurne',
  enfurno: 'enfurno',
  enfusai: 'enfusai',
  enfusam: 'enfusam',
  enfusar: 'enfusar',
  enfusas: 'enfusas',
  enfusca: 'enfusca',
  enfusco: 'enfusco',
  enfusei: 'enfusei',
  enfusem: 'enfusem',
  enfuses: 'enfuses',
  enfusou: 'enfusou',
  enfusta: 'enfusta',
  enfuste: 'enfuste',
  enfusto: 'enfusto',
  engacai: 'engaçai',
  engacam: 'engaçam',
  engacar: 'engaçar',
  engacas: 'engaças',
  engacei: 'engacei',
  engacem: 'engacem',
  engaces: 'engaces',
  engacou: 'engaçou',
  engadao: 'engadão',
  engadas: 'engadas',
  engador: 'engador',
  engados: 'engados',
  engaiai: 'engaiai',
  engaiam: 'engaiam',
  engaiar: 'engaiar',
  engaias: 'engaias',
  engaiei: 'engaiei',
  engaiem: 'engaiem',
  engaies: 'engaies',
  engaiou: 'engaiou',
  engajai: 'engajai',
  engajam: 'engajam',
  engajar: 'engajar',
  engajas: 'engajas',
  engajei: 'engajei',
  engajem: 'engajem',
  engajes: 'engajes',
  engajou: 'engajou',
  engalai: 'engalai',
  engalam: 'engalam',
  engalar: 'engalar',
  engalas: 'engalas',
  engalea: 'engaleá',
  engalei: 'engalei',
  engalem: 'engalem',
  engales: 'engales',
  engalga: 'engalga',
  engalgo: 'engalgo',
  engalha: 'engalha',
  engalhe: 'engalhe',
  engalho: 'engalho',
  engalia: 'engaliá',
  engalie: 'engalie',
  engalio: 'engalio',
  engalou: 'engalou',
  engambo: 'engambo',
  engamos: 'engamos',
  enganai: 'enganai',
  enganam: 'enganam',
  enganar: 'enganar',
  enganas: 'enganas',
  enganco: 'enganço',
  engando: 'engando',
  enganei: 'enganei',
  enganem: 'enganem',
  enganes: 'enganes',
  enganga: 'enganga',
  enganha: 'enganha',
  enganir: 'enganir',
  enganos: 'enganos',
  enganou: 'enganou',
  engante: 'engante',
  engaram: 'engaram',
  engarao: 'engarão',
  engaras: 'engarás',
  engarei: 'engarei',
  engarem: 'engarem',
  engares: 'engares',
  engarfa: 'engarfa',
  engarfe: 'engarfe',
  engarfo: 'engarfo',
  engaria: 'engaria',
  engarra: 'engarra',
  engarre: 'engarre',
  engarro: 'engarro',
  engasga: 'engasga',
  engasgo: 'engasgo',
  engasse: 'engasse',
  engasta: 'engasta',
  engaste: 'engaste',
  engasto: 'engasto',
  engatai: 'engatai',
  engatam: 'engatam',
  engatar: 'engatar',
  engatas: 'engatas',
  engatei: 'engatei',
  engatem: 'engatem',
  engates: 'engates',
  engatou: 'engatou',
  engavam: 'engavam',
  engavas: 'engavas',
  engaxos: 'engaxos',
  engeada: 'engeada',
  engeado: 'engeado',
  engeais: 'engeais',
  engeara: 'engeará',
  engeava: 'engeava',
  engecos: 'engecos',
  engeeis: 'engeeis',
  engeiam: 'engeiam',
  engeias: 'engeias',
  engeiem: 'engeiem',
  engeies: 'engeies',
  engelha: 'engelha',
  engelhe: 'engelhe',
  engelho: 'engelho',
  engelim: 'engelim',
  engenha: 'engenha',
  engenhe: 'engenhe',
  engenho: 'engenho',
  engerir: 'engerir',
  engessa: 'engessa',
  engesse: 'engesse',
  engesso: 'engesso',
  engigai: 'engigai',
  engigam: 'engigam',
  engigar: 'engigar',
  engigas: 'engigas',
  engigou: 'engigou',
  engigue: 'engigue',
  enginas: 'enginas',
  enginos: 'enginos',
  engivas: 'engivas',
  englele: 'englelê',
  engloba: 'engloba',
  englobe: 'englobe',
  englobo: 'englobo',
  engobai: 'engobai',
  engobam: 'engobam',
  engobar: 'engobar',
  engobas: 'engobas',
  engobei: 'engobei',
  engobem: 'engobem',
  engobes: 'engobes',
  engobos: 'engobos',
  engobou: 'engobou',
  engodai: 'engodai',
  engodam: 'engodam',
  engodar: 'engodar',
  engodas: 'engodas',
  engodei: 'engodei',
  engodem: 'engodem',
  engodes: 'engodes',
  engodos: 'engôdos',
  engodou: 'engodou',
  engoiai: 'engoiai',
  engoiam: 'engoiam',
  engoiar: 'engoiar',
  engoias: 'engoias',
  engoiei: 'engoiei',
  engoiem: 'engoiem',
  engoies: 'engoies',
  engoiou: 'engoiou',
  engolam: 'engolam',
  engolas: 'engolas',
  engolem: 'engolem',
  engoles: 'engoles',
  engolfa: 'engolfa',
  engolfe: 'engolfe',
  engolfo: 'engolfo',
  engolia: 'engolia',
  engolir: 'engolir',
  engolis: 'engolis',
  engoliu: 'engoliu',
  engomai: 'engomai',
  engomam: 'engomam',
  engomar: 'engomar',
  engomas: 'engomas',
  engomei: 'engomei',
  engomem: 'engomem',
  engomes: 'engomes',
  engomou: 'engomou',
  engonca: 'engonça',
  engonce: 'engonce',
  engonco: 'engonço',
  engonha: 'engonha',
  engonhe: 'engonhe',
  engonho: 'engonho',
  engonia: 'engonia',
  engonie: 'engonie',
  engonio: 'engonio',
  engorda: 'engorda',
  engorde: 'engorde',
  engordo: 'engordo',
  engorra: 'engorra',
  engorre: 'engorre',
  engorro: 'engorro',
  engraca: 'engraça',
  engrace: 'engrace',
  engraco: 'engraço',
  engrada: 'engrada',
  engrade: 'engrade',
  engrado: 'engrado',
  engrama: 'engrama',
  engraxa: 'engraxa',
  engraxe: 'engraxe',
  engraxo: 'engraxo',
  engraza: 'engraza',
  engraze: 'engraze',
  engrazo: 'engrazo',
  engrela: 'engrela',
  engrele: 'engrele',
  engrelo: 'engrelo',
  engrena: 'engrena',
  engrene: 'engrene',
  engreno: 'engreno',
  engrifa: 'engrifa',
  engrife: 'engrife',
  engrifo: 'engrifo',
  engrila: 'engrila',
  engrile: 'engrile',
  engrilo: 'engrilo',
  engrima: 'engrimá',
  engrime: 'engrime',
  engrimo: 'engrimo',
  engripa: 'engripa',
  engripe: 'engripe',
  engripo: 'engripo',
  engrola: 'engrola',
  engrole: 'engrole',
  engrolo: 'engrolo',
  engrope: 'engrope',
  engruma: 'engruma',
  engrume: 'engrume',
  engrumo: 'engrumo',
  engrupa: 'engrupa',
  engrupi: 'engrupi',
  engrupo: 'engrupo',
  engueis: 'engueis',
  enguias: 'enguias',
  enguica: 'enguiça',
  enguice: 'enguice',
  enguico: 'enguiço',
  engulam: 'engulam',
  engulas: 'engulas',
  engulem: 'engulem',
  engulha: 'engulha',
  engulhe: 'engulhe',
  engulho: 'engulho',
  engulia: 'engulia',
  engulir: 'engulir',
  engulis: 'engulis',
  enguliu: 'enguliu',
  engunha: 'engunha',
  engunhe: 'engunhe',
  engunho: 'engunho',
  enhader: 'enhader',
  enhadir: 'enhadir',
  eniadas: 'eníadas',
  enialio: 'eniálio',
  enianes: 'enianes',
  enicola: 'enícola',
  enicuro: 'enicuro',
  enidras: 'enidras',
  enidros: 'ênidros',
  eniense: 'eniense',
  enigmai: 'enigmai',
  enigmam: 'enigmam',
  enigmar: 'enigmar',
  enigmas: 'enigmas',
  enigmei: 'enigmei',
  enigmem: 'enigmem',
  enigmes: 'enigmes',
  enigmou: 'enigmou',
  enilema: 'enilema',
  enimaga: 'enimagá',
  enjacas: 'enjacas',
  enjaeza: 'enjaeza',
  enjaeze: 'enjaeze',
  enjaezo: 'enjaezo',
  enjalga: 'enjalgá',
  enjalgo: 'enjalgo',
  enjanga: 'enjanga',
  enjango: 'enjango',
  enjaula: 'enjaula',
  enjaule: 'enjaule',
  enjaulo: 'enjaulo',
  enjeira: 'enjeira',
  enjeire: 'enjeire',
  enjeiro: 'enjeiro',
  enjeita: 'enjeita',
  enjeite: 'enjeite',
  enjeito: 'enjeito',
  enjeram: 'enjeram',
  enjeras: 'enjeras',
  enjerem: 'enjerem',
  enjeres: 'enjeres',
  enjeria: 'enjeria',
  enjerir: 'enjerir',
  enjeris: 'enjeris',
  enjeriu: 'enjeriu',
  enjicai: 'enjicai',
  enjicam: 'enjicam',
  enjicar: 'enjicar',
  enjicas: 'enjicas',
  enjicou: 'enjicou',
  enjique: 'enjique',
  enjoada: 'enjoada',
  enjoado: 'enjoado',
  enjoais: 'enjoais',
  enjoamo: 'enjoamo',
  enjoara: 'enjoara',
  enjoava: 'enjoava',
  enjoeis: 'enjoeis',
  enjoemo: 'enjoemo',
  enjogos: 'enjogós',
  enjoiai: 'enjoiai',
  enjoiam: 'enjoiam',
  enjoiar: 'enjoiar',
  enjoias: 'enjoias',
  enjoiei: 'enjoiei',
  enjoiem: 'enjoiem',
  enjoies: 'enjoies',
  enjoina: 'enjoiná',
  enjoine: 'enjoine',
  enjoino: 'enjoino',
  enjoiou: 'enjoiou',
  enjojos: 'enjojos',
  enjolos: 'enjolós',
  enjonai: 'enjonai',
  enjonam: 'enjonam',
  enjonar: 'enjonar',
  enjonas: 'enjonas',
  enjonei: 'enjonei',
  enjonem: 'enjonem',
  enjones: 'enjones',
  enjonou: 'enjonou',
  enjoosa: 'enjoosa',
  enjooso: 'enjooso',
  enjorca: 'enjorcá',
  enjorco: 'enjorco',
  enjorga: 'enjorgá',
  enjorgo: 'enjorgo',
  enjouja: 'enjoujá',
  enjouje: 'enjouje',
  enjoujo: 'enjoujo',
  enjuana: 'enjuaná',
  enjuane: 'enjuane',
  enjuano: 'enjuano',
  enjugai: 'enjugai',
  enjugam: 'enjugam',
  enjugar: 'enjugar',
  enjugas: 'enjugas',
  enjugou: 'enjugou',
  enjugue: 'enjugue',
  enjunca: 'enjunçá',
  enjunce: 'enjunce',
  enjunco: 'enjunço',
  enjunta: 'enjunta',
  enlabia: 'enlabia',
  enlabie: 'enlabie',
  enlabio: 'enlabio',
  enlacai: 'enlaçai',
  enlacam: 'enlaçam',
  enlacar: 'enlaçar',
  enlacas: 'enlaças',
  enlacei: 'enlacei',
  enlacem: 'enlacem',
  enlaces: 'enlaces',
  enlacou: 'enlaçou',
  enlacra: 'enlacrá',
  enlacre: 'enlacre',
  enlacro: 'enlacro',
  enlagai: 'enlagai',
  enlagam: 'enlagam',
  enlagar: 'enlagar',
  enlagas: 'enlagas',
  enlagou: 'enlagou',
  enlague: 'enlague',
  enlaiva: 'enlaiva',
  enlaive: 'enlaive',
  enlaivo: 'enlaivo',
  enlamai: 'enlamai',
  enlamam: 'enlamam',
  enlamar: 'enlamar',
  enlamas: 'enlamas',
  enlamea: 'enlameá',
  enlamei: 'enlamei',
  enlamem: 'enlamem',
  enlames: 'enlames',
  enlamou: 'enlamou',
  enlanza: 'enlanza',
  enlanze: 'enlanze',
  enlanzo: 'enlanzo',
  enlapai: 'enlapai',
  enlapam: 'enlapam',
  enlapar: 'enlapar',
  enlapas: 'enlapas',
  enlapei: 'enlapei',
  enlapem: 'enlapem',
  enlapes: 'enlapes',
  enlapou: 'enlapou',
  enlatai: 'enlatai',
  enlatam: 'enlatam',
  enlatar: 'enlatar',
  enlatas: 'enlatas',
  enlatei: 'enlatei',
  enlatem: 'enlatem',
  enlates: 'enlates',
  enlatou: 'enlatou',
  enleaca: 'enleaça',
  enleada: 'enleada',
  enleado: 'enleado',
  enleais: 'enleais',
  enleara: 'enleara',
  enleava: 'enleava',
  enleeis: 'enleeis',
  enleiam: 'enleiam',
  enleias: 'enleias',
  enleiem: 'enleiem',
  enleies: 'enleies',
  enleios: 'enleios',
  enleiva: 'enleiva',
  enleive: 'enleive',
  enleivo: 'enleivo',
  enlerda: 'enlerda',
  enlerde: 'enlerde',
  enlerdo: 'enlerdo',
  enlevai: 'enlevai',
  enlevam: 'enlevam',
  enlevar: 'enlevar',
  enlevas: 'enlevas',
  enlevei: 'enlevei',
  enlevem: 'enlevem',
  enleves: 'enleves',
  enlevos: 'enlevos',
  enlevou: 'enlevou',
  enlheai: 'enlheai',
  enlhear: 'enlhear',
  enlheei: 'enlheei',
  enlheia: 'enlheia',
  enlheie: 'enlheie',
  enlheio: 'enlheio',
  enlheou: 'enlheou',
  enlicai: 'enliçai',
  enlicam: 'enliçam',
  enlicar: 'enliçar',
  enlicas: 'enliças',
  enlicei: 'enlicei',
  enlicem: 'enlicem',
  enlices: 'enlices',
  enlicou: 'enliçou',
  enlocai: 'enlocai',
  enlocam: 'enlocam',
  enlocar: 'enlocar',
  enlocas: 'enlocas',
  enlocou: 'enlocou',
  enlodai: 'enlodai',
  enlodam: 'enlodam',
  enlodar: 'enlodar',
  enlodas: 'enlodas',
  enlodei: 'enlodei',
  enlodem: 'enlodem',
  enlodes: 'enlodes',
  enlodou: 'enlodou',
  enloica: 'enloiçá',
  enloice: 'enloice',
  enloico: 'enloiço',
  enloira: 'enloira',
  enloire: 'enloire',
  enloiro: 'enloiro',
  enloisa: 'enloisa',
  enloise: 'enloise',
  enloiso: 'enloiso',
  enlojai: 'enlojai',
  enlojam: 'enlojam',
  enlojar: 'enlojar',
  enlojas: 'enlojas',
  enlojei: 'enlojei',
  enlojem: 'enlojem',
  enlojes: 'enlojes',
  enlojou: 'enlojou',
  enlomba: 'enlomba',
  enlombe: 'enlombe',
  enlombo: 'enlombo',
  enloque: 'enloque',
  enlouca: 'enlouca',
  enlouce: 'enlouce',
  enlouco: 'enlouco',
  enloura: 'enloura',
  enloure: 'enloure',
  enlouro: 'enlouro',
  enlousa: 'enlousa',
  enlouse: 'enlouse',
  enlouso: 'enlouso',
  enluara: 'enluara',
  enluare: 'enluare',
  enluaro: 'enluaro',
  enludra: 'enludra',
  enludre: 'enludre',
  enludro: 'enludro',
  enlurai: 'enlurai',
  enluram: 'enluram',
  enlurar: 'enlurar',
  enluras: 'enluras',
  enlurei: 'enlurei',
  enlurem: 'enlurem',
  enlures: 'enlures',
  enlurou: 'enlurou',
  enlutai: 'enlutai',
  enlutam: 'enlutam',
  enlutar: 'enlutar',
  enlutas: 'enlutas',
  enlutei: 'enlutei',
  enlutem: 'enlutem',
  enlutes: 'enlutes',
  enlutou: 'enlutou',
  enluvai: 'enluvai',
  enluvam: 'enluvam',
  enluvar: 'enluvar',
  enluvas: 'enluvas',
  enluvei: 'enluvei',
  enluvem: 'enluvem',
  enluves: 'enluves',
  enluvou: 'enluvou',
  ennodar: 'ennodar',
  enocito: 'enócito',
  enocoas: 'enócoas',
  enoctos: 'enoctos',
  enodada: 'enodada',
  enodado: 'enodado',
  enodais: 'enodais',
  enodamo: 'enodamo',
  enodara: 'enodará',
  enodava: 'enodava',
  enodeis: 'enodeis',
  enodemo: 'enodemo',
  enodoai: 'enodoai',
  enodoam: 'enodoam',
  enodoar: 'enodoar',
  enodoas: 'enodoas',
  enodoei: 'enodoei',
  enodoem: 'enodoem',
  enodoes: 'enodoes',
  enodoou: 'enodoou',
  enoemas: 'enoemas',
  enofilo: 'enófilo',
  enofobo: 'enófobo',
  enofora: 'enófora',
  enoforo: 'enóforo',
  enogada: 'enogada',
  enogado: 'enogado',
  enogais: 'enogais',
  enogala: 'enógala',
  enogara: 'enogará',
  enogaro: 'enógaro',
  enogava: 'enogava',
  enoguei: 'enoguei',
  enoguem: 'enoguem',
  enogues: 'enogues',
  enoitai: 'enoitai',
  enoitam: 'enoitam',
  enoitar: 'enoitar',
  enoitas: 'enoitas',
  enoitei: 'enoitei',
  enoitem: 'enoitem',
  enoites: 'enoites',
  enoitou: 'enoitou',
  enojada: 'enojada',
  enojado: 'enojado',
  enojais: 'enojais',
  enojamo: 'enojamo',
  enojara: 'enojara',
  enojava: 'enojava',
  enojeis: 'enojeis',
  enojemo: 'enojemo',
  enojosa: 'enojosa',
  enojoso: 'enojoso',
  enolada: 'enolada',
  enolado: 'enolado',
  enolato: 'enolato',
  enoleas: 'enóleas',
  enoleos: 'enóleos',
  enolica: 'enólica',
  enolico: 'enólico',
  enolina: 'enolina',
  enoliza: 'enoliza',
  enolize: 'enolize',
  enolizo: 'enolizo',
  enologa: 'enóloga',
  enologo: 'enólogo',
  enolzao: 'enolzão',
  enomeis: 'enoméis',
  enopios: 'enópios',
  enoplio: 'enóplio',
  enoplos: 'enoplos',
  enoques: 'enoques',
  enormes: 'enormes',
  enososa: 'enososa',
  enososo: 'enososo',
  enoteca: 'enoteca',
  enotera: 'enotera',
  enotrio: 'enótrio',
  enoutai: 'enoutai',
  enoutam: 'enoutam',
  enoutar: 'enoutar',
  enoutas: 'enoutas',
  enoutei: 'enoutei',
  enoutem: 'enoutem',
  enoutes: 'enoutes',
  enoutou: 'enoutou',
  enovada: 'enovada',
  enovado: 'enovado',
  enovais: 'enovais',
  enovamo: 'enovamo',
  enovara: 'enovará',
  enovava: 'enovava',
  enoveis: 'enoveis',
  enovela: 'enovela',
  enovele: 'enovele',
  enovelo: 'enovelo',
  enovemo: 'enovemo',
  enquere: 'enquere',
  enqueri: 'enqueri',
  enquete: 'enquete',
  enquice: 'enquice',
  enquira: 'enquira',
  enquiro: 'enquiro',
  enquisa: 'enquisa',
  enquita: 'enquita',
  enquite: 'enquite',
  enquito: 'enquito',
  enrabai: 'enrabai',
  enrabam: 'enrabam',
  enrabar: 'enrabar',
  enrabas: 'enrabas',
  enrabei: 'enrabei',
  enrabem: 'enrabem',
  enrabes: 'enrabes',
  enrabou: 'enrabou',
  enracai: 'enraçai',
  enracam: 'enraçam',
  enracar: 'enraçar',
  enracas: 'enraças',
  enracei: 'enracei',
  enracem: 'enracem',
  enraces: 'enraces',
  enracou: 'enraçou',
  enraiai: 'enraiai',
  enraiam: 'enraiam',
  enraiar: 'enraiar',
  enraias: 'enraias',
  enraiei: 'enraiei',
  enraiem: 'enraiem',
  enraies: 'enraies',
  enraiou: 'enraiou',
  enraiva: 'enraiva',
  enraive: 'enraive',
  enraivo: 'enraivo',
  enraiza: 'enraizá',
  enraize: 'enraíze',
  enraizo: 'enraízo',
  enramai: 'enramai',
  enramam: 'enramam',
  enramar: 'enramar',
  enramas: 'enramas',
  enramea: 'enrameá',
  enramei: 'enramei',
  enramem: 'enramem',
  enrames: 'enrames',
  enramou: 'enramou',
  enranca: 'enrança',
  enrance: 'enrance',
  enranco: 'enranço',
  enrasca: 'enrasca',
  enrasco: 'enrasco',
  enredai: 'enredai',
  enredam: 'enredam',
  enredar: 'enredar',
  enredas: 'enredas',
  enredea: 'enredeá',
  enredei: 'enredei',
  enredem: 'enredem',
  enredes: 'enredes',
  enredos: 'enredos',
  enredou: 'enredou',
  enregai: 'enregai',
  enregam: 'enregam',
  enregar: 'enregar',
  enregas: 'enregas',
  enregou: 'enregou',
  enregue: 'enregue',
  enreixa: 'enreixá',
  enreixe: 'enreixe',
  enreixo: 'enreixo',
  enrelha: 'enrelha',
  enrelhe: 'enrelhe',
  enrelho: 'enrelho',
  enrelva: 'enrelvá',
  enrelve: 'enrelve',
  enrelvo: 'enrelvo',
  enrenda: 'enrendá',
  enrende: 'enrende',
  enrendo: 'enrendo',
  enresma: 'enresma',
  enresme: 'enresme',
  enresmo: 'enresmo',
  enresta: 'enresta',
  enreste: 'enreste',
  enresto: 'enresto',
  enriada: 'enriada',
  enriado: 'enriado',
  enriais: 'enriais',
  enriamo: 'enriamo',
  enriara: 'enriará',
  enriava: 'enriava',
  enricai: 'enricai',
  enricam: 'enricam',
  enricar: 'enricar',
  enricas: 'enricas',
  enricei: 'enricei',
  enricem: 'enricem',
  enrices: 'enrices',
  enricou: 'enricou',
  enrieis: 'enrieis',
  enriemo: 'enriemo',
  enrifai: 'enrifai',
  enrifam: 'enrifam',
  enrifar: 'enrifar',
  enrifas: 'enrifas',
  enrifei: 'enrifei',
  enrifem: 'enrifem',
  enrifes: 'enrifes',
  enrifou: 'enrifou',
  enrijai: 'enrijai',
  enrijam: 'enrijam',
  enrijar: 'enrijar',
  enrijas: 'enrijas',
  enrijei: 'enrijei',
  enrijem: 'enrijem',
  enrijes: 'enrijes',
  enrijou: 'enrijou',
  enrilha: 'enrilhá',
  enrilhe: 'enrilhe',
  enrilho: 'enrilho',
  enrimai: 'enrimai',
  enrimam: 'enrimam',
  enrimar: 'enrimar',
  enrimas: 'enrimas',
  enrimei: 'enrimei',
  enrimem: 'enrimem',
  enrimes: 'enrimes',
  enrimou: 'enrimou',
  enripai: 'enripai',
  enripam: 'enripam',
  enripar: 'enripar',
  enripas: 'enripas',
  enripei: 'enripei',
  enripem: 'enripem',
  enripes: 'enripes',
  enripou: 'enripou',
  enrique: 'enrique',
  enrista: 'enrista',
  enriste: 'enriste',
  enristo: 'enristo',
  enrixai: 'enrixai',
  enrixam: 'enrixam',
  enrixar: 'enrixar',
  enrixas: 'enrixas',
  enrixei: 'enrixei',
  enrixem: 'enrixem',
  enrixes: 'enrixes',
  enrixou: 'enrixou',
  enrizai: 'enrizai',
  enrizam: 'enrizam',
  enrizar: 'enrizar',
  enrizas: 'enrizas',
  enrizei: 'enrizei',
  enrizem: 'enrizem',
  enrizes: 'enrizes',
  enrizou: 'enrizou',
  enrocai: 'enrocai',
  enrocam: 'enrocam',
  enrocar: 'enrocar',
  enrocas: 'enrocas',
  enrocha: 'enrochá',
  enroche: 'enroche',
  enrocho: 'enrocho',
  enrocou: 'enrocou',
  enrodai: 'enrodai',
  enrodam: 'enrodam',
  enrodar: 'enrodar',
  enrodas: 'enrodas',
  enrodei: 'enrodei',
  enrodem: 'enrodem',
  enrodes: 'enrodes',
  enrodou: 'enrodou',
  enrolai: 'enrolai',
  enrolam: 'enrolam',
  enrolao: 'enrolão',
  enrolar: 'enrolar',
  enrolas: 'enrolas',
  enrolei: 'enrolei',
  enrolem: 'enrolem',
  enroles: 'enroles',
  enrolha: 'enrolha',
  enrolhe: 'enrolhe',
  enrolho: 'enrolho',
  enrolou: 'enrolou',
  enroque: 'enroque',
  enrosca: 'enrosca',
  enrosco: 'enrosco',
  enrotar: 'enrotar',
  enroupa: 'enroupa',
  enroupe: 'enroupe',
  enroupo: 'enroupo',
  enroxai: 'enroxai',
  enroxam: 'enroxam',
  enroxar: 'enroxar',
  enroxas: 'enroxas',
  enroxei: 'enroxei',
  enroxem: 'enroxem',
  enroxes: 'enroxes',
  enroxou: 'enroxou',
  enrucai: 'enruçai',
  enrucam: 'enruçam',
  enrucar: 'enruçar',
  enrucas: 'enruças',
  enrucei: 'enrucei',
  enrucem: 'enrucem',
  enruces: 'enruces',
  enrucou: 'enruçou',
  enrufai: 'enrufai',
  enrufam: 'enrufam',
  enrufar: 'enrufar',
  enrufas: 'enrufas',
  enrufei: 'enrufei',
  enrufem: 'enrufem',
  enrufes: 'enrufes',
  enrufou: 'enrufou',
  enrugai: 'enrugai',
  enrugam: 'enrugam',
  enrugar: 'enrugar',
  enrugas: 'enrugas',
  enrugou: 'enrugou',
  enrugue: 'enrugue',
  enrusta: 'enrusta',
  enruste: 'enruste',
  enrusti: 'enrusti',
  enrusto: 'enrusto',
  ensaboa: 'ensaboa',
  ensaboe: 'ensaboe',
  ensaboo: 'ensaboo',
  ensacai: 'ensacai',
  ensacam: 'ensacam',
  ensacar: 'ensacar',
  ensacas: 'ensacas',
  ensacou: 'ensacou',
  ensaiai: 'ensaiai',
  ensaiam: 'ensaiam',
  ensaiao: 'ensaião',
  ensaiar: 'ensaiar',
  ensaias: 'ensaias',
  ensaiei: 'ensaiei',
  ensaiem: 'ensaiem',
  ensaies: 'ensaies',
  ensaios: 'ensaios',
  ensaiou: 'ensaiou',
  ensalca: 'ensalça',
  ensalce: 'ensalce',
  ensalco: 'ensalço',
  ensalma: 'ensalma',
  ensalme: 'ensalme',
  ensalmo: 'ensalmo',
  ensampa: 'ensampá',
  ensampe: 'ensampe',
  ensampo: 'ensampo',
  ensanha: 'ensanha',
  ensanhe: 'ensanhe',
  ensanho: 'ensanho',
  ensaque: 'ensaque',
  ensarta: 'ensarta',
  ensarte: 'ensarte',
  ensarto: 'ensarto',
  enseada: 'enseada',
  enseado: 'enseado',
  enseais: 'enseais',
  enseara: 'enseara',
  enseava: 'enseava',
  ensebai: 'ensebai',
  ensebam: 'ensebam',
  ensebar: 'ensebar',
  ensebas: 'ensebas',
  ensebei: 'ensebei',
  ensebem: 'ensebem',
  ensebes: 'ensebes',
  ensebou: 'ensebou',
  ensecai: 'ensecai',
  ensecam: 'ensecam',
  ensecar: 'ensecar',
  ensecas: 'ensecas',
  ensecou: 'ensecou',
  ensedai: 'ensedai',
  ensedam: 'ensedam',
  ensedar: 'ensedar',
  ensedas: 'ensedas',
  ensedei: 'ensedei',
  ensedem: 'ensedem',
  ensedes: 'ensedes',
  ensedou: 'ensedou',
  enseeis: 'enseeis',
  enseiai: 'enseiai',
  enseiam: 'enseiam',
  enseiar: 'enseiar',
  enseias: 'enseias',
  enseiei: 'enseiei',
  enseiem: 'enseiem',
  enseies: 'enseies',
  enseios: 'enseios',
  enseiou: 'enseiou',
  enseira: 'enseira',
  enseire: 'enseire',
  enseiro: 'enseiro',
  enseiva: 'enseiva',
  enseive: 'enseive',
  enseivo: 'enseivo',
  ensejai: 'ensejai',
  ensejam: 'ensejam',
  ensejar: 'ensejar',
  ensejas: 'ensejas',
  ensejei: 'ensejei',
  ensejem: 'ensejem',
  ensejes: 'ensejes',
  ensejos: 'ensejos',
  ensejou: 'ensejou',
  enselva: 'enselva',
  enselve: 'enselve',
  enselvo: 'enselvo',
  enseque: 'enseque',
  ensesga: 'ensesgá',
  ensesgo: 'ensesgo',
  ensevai: 'ensevai',
  ensevam: 'ensevam',
  ensevar: 'ensevar',
  ensevas: 'ensevas',
  ensevei: 'ensevei',
  ensevem: 'ensevem',
  enseves: 'enseves',
  ensevou: 'ensevou',
  ensilai: 'ensilai',
  ensilam: 'ensilam',
  ensilar: 'ensilar',
  ensilas: 'ensilas',
  ensilei: 'ensilei',
  ensilem: 'ensilem',
  ensiles: 'ensiles',
  ensilou: 'ensilou',
  ensilva: 'ensilva',
  ensilve: 'ensilve',
  ensilvo: 'ensilvo',
  ensinai: 'ensinai',
  ensinam: 'ensinam',
  ensinar: 'ensinar',
  ensinas: 'ensinas',
  ensinei: 'ensinei',
  ensinem: 'ensinem',
  ensines: 'ensines',
  ensinos: 'ensinos',
  ensinou: 'ensinou',
  ensoada: 'ensoada',
  ensoado: 'ensoado',
  ensoais: 'ensoais',
  ensoamo: 'ensoamo',
  ensoara: 'ensoara',
  ensoava: 'ensoava',
  ensocai: 'ensocai',
  ensocam: 'ensocam',
  ensocar: 'ensocar',
  ensocas: 'ensocas',
  ensocou: 'ensocou',
  ensodai: 'ensodai',
  ensodam: 'ensodam',
  ensodar: 'ensodar',
  ensodas: 'ensodas',
  ensodei: 'ensodei',
  ensodem: 'ensodem',
  ensodes: 'ensodes',
  ensodou: 'ensodou',
  ensoeis: 'ensoeis',
  ensoemo: 'ensoemo',
  ensogai: 'ensogai',
  ensogam: 'ensogam',
  ensogar: 'ensogar',
  ensogas: 'ensogas',
  ensogou: 'ensogou',
  ensogue: 'ensogue',
  ensolva: 'ensolva',
  ensolve: 'ensolve',
  ensolvo: 'ensolvo',
  ensonai: 'ensonai',
  ensonam: 'ensonam',
  ensonar: 'ensonar',
  ensonas: 'ensonas',
  ensonei: 'ensonei',
  ensonem: 'ensonem',
  ensones: 'ensones',
  ensonou: 'ensonou',
  ensopai: 'ensopai',
  ensopam: 'ensopam',
  ensopar: 'ensopar',
  ensopas: 'ensopas',
  ensopea: 'ensopeá',
  ensopei: 'ensopei',
  ensopem: 'ensopem',
  ensopes: 'ensopes',
  ensopia: 'ensopiá',
  ensopie: 'ensopie',
  ensopio: 'ensopio',
  ensopou: 'ensopou',
  ensoque: 'ensoque',
  ensorea: 'ensoreá',
  ensorna: 'ensorna',
  ensorne: 'ensorne',
  ensorno: 'ensorno',
  ensossa: 'ensossa',
  ensosse: 'ensosse',
  ensosso: 'ensosso',
  ensujai: 'ensujai',
  ensujam: 'ensujam',
  ensujar: 'ensujar',
  ensujas: 'ensujas',
  ensujei: 'ensujei',
  ensujem: 'ensujem',
  ensujes: 'ensujes',
  ensujou: 'ensujou',
  ensurra: 'ensurrá',
  ensurre: 'ensurre',
  ensurro: 'ensurro',
  entabua: 'entabua',
  entabue: 'entabue',
  entabuo: 'entabuo',
  entacas: 'entacas',
  entacha: 'entacha',
  entache: 'entache',
  entacho: 'entacho',
  entadas: 'entadas',
  entaipa: 'entaipa',
  entaipe: 'entaipe',
  entaipo: 'entaipo',
  entalai: 'entalai',
  entalam: 'entalam',
  entalao: 'entalão',
  entalar: 'entalar',
  entalas: 'entalas',
  entalei: 'entalei',
  entalem: 'entalem',
  entales: 'entales',
  entalha: 'entalha',
  entalhe: 'entalhe',
  entalho: 'entalho',
  entalia: 'entalia',
  entalir: 'entalir',
  entalis: 'entalis',
  entaliu: 'entaliu',
  entaloa: 'entaloá',
  entaloe: 'entaloe',
  entaloo: 'entaloo',
  entalou: 'entalou',
  entamai: 'entamai',
  entamam: 'entamam',
  entamar: 'entamar',
  entamas: 'entamas',
  entambe: 'entambe',
  entamei: 'entamei',
  entamem: 'entamem',
  entames: 'entames',
  entamou: 'entamou',
  entanca: 'entanca',
  entanco: 'entanco',
  entanga: 'entanga',
  entango: 'entango',
  entanha: 'entanha',
  entanto: 'entanto',
  entapai: 'entapai',
  entapam: 'entapam',
  entapar: 'entapar',
  entapas: 'entapas',
  entapei: 'entapei',
  entapem: 'entapem',
  entapes: 'entapes',
  entapou: 'entapou',
  entases: 'êntases',
  enteada: 'enteada',
  enteado: 'enteado',
  enteais: 'enteais',
  enteara: 'enteará',
  enteato: 'enteato',
  enteava: 'enteava',
  entecai: 'entecai',
  entecam: 'enteçam',
  entecar: 'entecar',
  entecas: 'entecas',
  entecei: 'entecei',
  entecem: 'entecem',
  entecer: 'entecer',
  enteces: 'enteces',
  enteceu: 'enteceu',
  entecia: 'entecia',
  entecou: 'entecou',
  entedia: 'entedia',
  entedie: 'entedie',
  entedio: 'entedio',
  enteeis: 'enteeis',
  enteiam: 'enteiam',
  enteias: 'enteias',
  enteiem: 'enteiem',
  enteies: 'enteies',
  enteiro: 'enteiro',
  enteixa: 'enteixá',
  enteixe: 'enteixe',
  enteixo: 'enteixo',
  entejai: 'entejai',
  entejam: 'entejam',
  entejar: 'entejar',
  entejas: 'entejas',
  entejei: 'entejei',
  entejem: 'entejem',
  entejes: 'entejes',
  entejou: 'entejou',
  entelai: 'entelai',
  entelam: 'entelam',
  entelar: 'entelar',
  entelas: 'entelas',
  entelea: 'entélea',
  entelei: 'entelei',
  entelem: 'entelem',
  enteles: 'enteles',
  entelha: 'entelha',
  entelhe: 'entelhe',
  entelho: 'entelho',
  entelou: 'entelou',
  entenal: 'entenal',
  entenca: 'entença',
  entenda: 'entenda',
  entende: 'entende',
  entendi: 'entendi',
  entendo: 'entendo',
  entente: 'entente',
  entenxa: 'entenxa',
  entenxe: 'entenxe',
  entenxo: 'entenxo',
  enteque: 'enteque',
  enteral: 'enteral',
  enteron: 'ênteron',
  enterra: 'enterra',
  enterre: 'enterre',
  enterro: 'enterro',
  entesai: 'entesai',
  entesam: 'entesam',
  entesar: 'entesar',
  entesas: 'entesas',
  entesei: 'entesei',
  entesem: 'entesem',
  enteses: 'enteses',
  entesoa: 'entesoa',
  entesoe: 'entesoe',
  entesoo: 'entesoo',
  entesou: 'entesou',
  entesta: 'entesta',
  enteste: 'enteste',
  entesto: 'entesto',
  entiais: 'entiais',
  entibia: 'entibia',
  entibie: 'entibie',
  entibio: 'entibio',
  enticai: 'enticai',
  enticam: 'enticam',
  enticar: 'enticar',
  enticas: 'enticas',
  enticou: 'enticou',
  entimos: 'entimos',
  entinta: 'entinta',
  entinte: 'entinte',
  entinto: 'entinto',
  entique: 'entique',
  entisna: 'entisna',
  entisne: 'entisne',
  entisno: 'entisno',
  entivai: 'entivai',
  entivam: 'entivam',
  entivar: 'entivar',
  entivas: 'entivas',
  entivei: 'entivei',
  entivem: 'entivem',
  entives: 'entives',
  entivou: 'entivou',
  entoada: 'entoada',
  entoado: 'entoado',
  entoais: 'entoais',
  entoamo: 'entoamo',
  entoara: 'entoara',
  entoava: 'entoava',
  entocai: 'entocai',
  entocam: 'entocam',
  entocar: 'entocar',
  entocas: 'entocas',
  entocou: 'entocou',
  entodao: 'entodão',
  entoeis: 'entoeis',
  entoema: 'entoema',
  entoemo: 'entoemo',
  entogai: 'entogai',
  entogam: 'entogam',
  entogar: 'entogar',
  entogas: 'entogas',
  entogou: 'entogou',
  entogue: 'entogue',
  entoial: 'entoial',
  entoica: 'entoiça',
  entoice: 'entoice',
  entoico: 'entoiço',
  entoira: 'entoira',
  entoire: 'entoire',
  entoiro: 'entoiro',
  entojai: 'entojai',
  entojam: 'entojam',
  entojar: 'entojar',
  entojas: 'entojas',
  entojei: 'entojei',
  entojem: 'entojem',
  entojes: 'entojes',
  entojou: 'entojou',
  entolai: 'entolai',
  entolam: 'entolam',
  entolar: 'entolar',
  entolas: 'entolas',
  entolda: 'entoldá',
  entolde: 'entolde',
  entoldo: 'entoldo',
  entolei: 'entolei',
  entolem: 'entolem',
  entoles: 'entoles',
  entolha: 'entolha',
  entolhe: 'entolhê',
  entolhi: 'entolhi',
  entolho: 'entolho',
  entolou: 'entolou',
  entomos: 'entomos',
  entonai: 'entonai',
  entonam: 'entonam',
  entonar: 'entonar',
  entonas: 'entonas',
  entonce: 'entonce',
  entonei: 'entonei',
  entonem: 'entonem',
  entones: 'entones',
  entonou: 'entonou',
  entonta: 'entontá',
  entonte: 'entonte',
  entonto: 'entonto',
  entopem: 'entopem',
  entopes: 'entopes',
  entoque: 'entoque',
  entorna: 'entorna',
  entorne: 'entorne',
  entorno: 'entorno',
  entorpa: 'entorpa',
  entorpe: 'entorpe',
  entorpo: 'entorpo',
  entorse: 'entorse',
  entorta: 'entorta',
  entorte: 'entorte',
  entorto: 'entorto',
  entosta: 'entosta',
  entouca: 'entouca',
  entouce: 'entouce',
  entouco: 'entouço',
  entoura: 'entoura',
  entoure: 'entoure',
  entouri: 'entouri',
  entouro: 'entouro',
  entrada: 'entrada',
  entrado: 'entrado',
  entrais: 'entrais',
  entraja: 'entraja',
  entraje: 'entraje',
  entrajo: 'entrajo',
  entrale: 'entrale',
  entrama: 'entrama',
  entrame: 'entrame',
  entramo: 'entramo',
  entrapa: 'entrapa',
  entrape: 'entrape',
  entrapo: 'entrapo',
  entrara: 'entrará',
  entrava: 'entrava',
  entrave: 'entrave',
  entravo: 'entravo',
  entrega: 'entrega',
  entrego: 'entrego',
  entreis: 'entreis',
  entremo: 'entremo',
  entreno: 'entrenó',
  entrepo: 'entrepô',
  entreta: 'entreta',
  entrete: 'entretê',
  entreti: 'entreti',
  entreto: 'entreto',
  entreva: 'entreva',
  entreve: 'entrevê',
  entrevi: 'entrevi',
  entrevo: 'entrevo',
  entreza: 'entreza',
  entreze: 'entreze',
  entrezo: 'entrezo',
  entrida: 'entrida',
  entrita: 'entrita',
  entriza: 'entriza',
  entrize: 'entrize',
  entrizo: 'entrizo',
  entroca: 'entroca',
  entroce: 'entroce',
  entroco: 'entroco',
  entrona: 'entrona',
  entrone: 'entrone',
  entrono: 'entrono',
  entrosa: 'entrosa',
  entrose: 'entrose',
  entroso: 'entroso',
  entruda: 'entruda',
  entrude: 'entrude',
  entrudo: 'entrudo',
  entruja: 'entruja',
  entruje: 'entruje',
  entrujo: 'entrujo',
  entubai: 'entubai',
  entubam: 'entubam',
  entubar: 'entubar',
  entubas: 'entubas',
  entubei: 'entubei',
  entubem: 'entubem',
  entubes: 'entubes',
  entubou: 'entubou',
  entucha: 'entucha',
  entuche: 'entuche',
  entucho: 'entucho',
  entufai: 'entufai',
  entufam: 'entufam',
  entufar: 'entufar',
  entufas: 'entufas',
  entufei: 'entufei',
  entufem: 'entufem',
  entufes: 'entufes',
  entufou: 'entufou',
  entulha: 'entulha',
  entulhe: 'entulhe',
  entulho: 'entulho',
  entunas: 'entunas',
  entupam: 'entupam',
  entupas: 'entupas',
  entupem: 'entupem',
  entupes: 'entupes',
  entupia: 'entupia',
  entupir: 'entupir',
  entupis: 'entupis',
  entupiu: 'entupiu',
  enturba: 'enturba',
  enturbe: 'enturbe',
  enturbo: 'enturbo',
  enturma: 'enturma',
  enturme: 'enturme',
  enturmo: 'enturmo',
  enturva: 'enturva',
  enturve: 'enturve',
  enturvo: 'enturvo',
  enublai: 'enublai',
  enublam: 'enublam',
  enublar: 'enublar',
  enublas: 'enublas',
  enublei: 'enublei',
  enublem: 'enublem',
  enubles: 'enubles',
  enublou: 'enublou',
  enuclea: 'enucleá',
  enumera: 'enumera',
  enumere: 'enumere',
  enumero: 'enumero',
  enuncia: 'enuncia',
  enuncie: 'enuncie',
  enuncio: 'enuncio',
  enuplas: 'ênuplas',
  enuplos: 'ênuplos',
  enurese: 'enurese',
  enuviai: 'enuviai',
  enuviam: 'enuviam',
  enuviar: 'enuviar',
  enuvias: 'enuvias',
  enuviei: 'enuviei',
  enuviem: 'enuviem',
  enuvies: 'enuvies',
  enuviou: 'enuviou',
  envaida: 'envaida',
  envaide: 'envaide',
  envaido: 'envaido',
  envalai: 'envalai',
  envalam: 'envalam',
  envalar: 'envalar',
  envalas: 'envalas',
  envalei: 'envalei',
  envalem: 'envalem',
  envales: 'envales',
  envalou: 'envalou',
  envarai: 'envarai',
  envaram: 'envaram',
  envarar: 'envarar',
  envaras: 'envaras',
  envarei: 'envarei',
  envarem: 'envarem',
  envares: 'envares',
  envaris: 'envaris',
  envarou: 'envarou',
  envasai: 'envasai',
  envasam: 'envasam',
  envasar: 'envasar',
  envasas: 'envasas',
  envasei: 'envasei',
  envasem: 'envasem',
  envases: 'envases',
  envasou: 'envasou',
  envazai: 'envazai',
  envazam: 'envazam',
  envazar: 'envazar',
  envazas: 'envazas',
  envazei: 'envazei',
  envazem: 'envazem',
  envazes: 'envazes',
  envazou: 'envazou',
  enverai: 'enverai',
  enveram: 'enveram',
  enverar: 'enverar',
  enveras: 'enveras',
  enverei: 'enverei',
  enverem: 'enverem',
  enveres: 'enveres',
  enverga: 'enverga',
  envergo: 'envergo',
  enverou: 'enverou',
  envesai: 'envesai',
  envesam: 'envesam',
  envesar: 'envesar',
  envesas: 'envesas',
  envesei: 'envesei',
  envesem: 'envesem',
  enveses: 'enveses',
  envesga: 'envesga',
  envesgo: 'envesgo',
  envesou: 'envesou',
  envessa: 'envessa',
  envesse: 'envesse',
  envesso: 'envesso',
  enviada: 'enviada',
  enviado: 'enviado',
  enviais: 'enviais',
  enviamo: 'enviamo',
  enviara: 'enviara',
  enviava: 'enviava',
  envicai: 'enviçai',
  envicam: 'enviçam',
  envicar: 'enviçar',
  envicas: 'enviças',
  envicei: 'envicei',
  envicem: 'envicem',
  envices: 'envices',
  envicou: 'enviçou',
  envidai: 'envidai',
  envidam: 'envidam',
  envidar: 'envidar',
  envidas: 'envidas',
  envidei: 'envidei',
  envidem: 'envidem',
  envides: 'envides',
  envidou: 'envidou',
  envieis: 'envieis',
  enviemo: 'enviemo',
  enviesa: 'enviesa',
  enviese: 'enviese',
  envieso: 'envieso',
  envigai: 'envigai',
  envigam: 'envigam',
  envigar: 'envigar',
  envigas: 'envigas',
  envigou: 'envigou',
  envigue: 'envigue',
  envinha: 'envinha',
  envinhe: 'envinhe',
  envinho: 'envinho',
  envisca: 'envisca',
  envisco: 'envisco',
  envisga: 'envisgá',
  envisgo: 'envisgo',
  envites: 'envites',
  enviuva: 'enviuvá',
  enviuve: 'enviúve',
  enviuvo: 'enviúvo',
  envolai: 'envolai',
  envolam: 'envolam',
  envolar: 'envolar',
  envolas: 'envolas',
  envolei: 'envolei',
  envolem: 'envolem',
  envoles: 'envoles',
  envolou: 'envolou',
  envolta: 'envolta',
  envolto: 'envolto',
  envolva: 'envolva',
  envolve: 'envolve',
  envolvi: 'envolvi',
  envolvo: 'envolvo',
  envulta: 'envulta',
  envulte: 'envulte',
  envulto: 'envulto',
  enxacas: 'enxacas',
  enxadai: 'enxadai',
  enxadam: 'enxadam',
  enxadao: 'enxadão',
  enxadar: 'enxadar',
  enxadas: 'enxadas',
  enxadea: 'enxadeá',
  enxadei: 'enxadei',
  enxadem: 'enxadem',
  enxades: 'enxades',
  enxadou: 'enxadou',
  enxagua: 'enxaguá',
  enxague: 'enxague',
  enxaguo: 'enxáguo',
  enxaire: 'enxaire',
  enxalca: 'enxalça',
  enxalce: 'enxalce',
  enxalco: 'enxalço',
  enxalma: 'enxalma',
  enxalme: 'enxalme',
  enxalmo: 'enxalmo',
  enxamas: 'enxamas',
  enxamea: 'enxameá',
  enxamel: 'enxamel',
  enxames: 'enxames',
  enxaral: 'enxaral',
  enxaras: 'enxaras',
  enxarca: 'enxarca',
  enxareu: 'enxaréu',
  enxaria: 'enxária',
  enxario: 'enxário',
  enxauga: 'enxauga',
  enxaugo: 'enxaugo',
  enxavos: 'enxavos',
  enxebre: 'enxebre',
  enxecai: 'enxecai',
  enxecam: 'enxecam',
  enxecar: 'enxecar',
  enxecas: 'enxecas',
  enxecos: 'enxecos',
  enxecou: 'enxecou',
  enxelha: 'enxelhá',
  enxelhe: 'enxelhe',
  enxelho: 'enxelho',
  enxeque: 'enxeque',
  enxeram: 'enxeram',
  enxeras: 'enxeras',
  enxerca: 'enxerca',
  enxerco: 'enxerco',
  enxerem: 'enxerem',
  enxeres: 'enxeres',
  enxerga: 'enxerga',
  enxergo: 'enxergo',
  enxeria: 'enxeria',
  enxerir: 'enxerir',
  enxeris: 'enxeris',
  enxeriu: 'enxeriu',
  enxerta: 'enxerta',
  enxerte: 'enxerte',
  enxerto: 'enxerto',
  enxiada: 'enxiada',
  enxiado: 'enxiado',
  enxiais: 'enxiais',
  enxiamo: 'enxiamo',
  enxiara: 'enxiara',
  enxiava: 'enxiava',
  enxidro: 'enxidro',
  enxieis: 'enxieis',
  enxiemo: 'enxiemo',
  enxilha: 'enxilha',
  enxilhe: 'enxilhe',
  enxilho: 'enxilho',
  enxoada: 'enxoada',
  enxofar: 'enxôfar',
  enxofra: 'enxofra',
  enxofre: 'enxofre',
  enxofro: 'enxofro',
  enxogai: 'enxogai',
  enxogam: 'enxogam',
  enxogar: 'enxogar',
  enxogas: 'enxogas',
  enxogou: 'enxogou',
  enxogue: 'enxogue',
  enxorai: 'enxorai',
  enxoram: 'enxoram',
  enxorar: 'enxorar',
  enxoras: 'enxoras',
  enxorei: 'enxorei',
  enxorem: 'enxorem',
  enxores: 'enxores',
  enxorou: 'enxorou',
  enxotai: 'enxotai',
  enxotam: 'enxotam',
  enxotar: 'enxotar',
  enxotas: 'enxotas',
  enxotei: 'enxotei',
  enxotem: 'enxotem',
  enxotes: 'enxotes',
  enxotou: 'enxotou',
  enxovai: 'enxovai',
  enxoval: 'enxoval',
  enxovam: 'enxovam',
  enxovar: 'enxovar',
  enxovas: 'enxovas',
  enxovei: 'enxovei',
  enxovem: 'enxovem',
  enxoves: 'enxoves',
  enxovia: 'enxovia',
  enxovio: 'enxovio',
  enxovou: 'enxovou',
  enxudra: 'enxudrá',
  enxudre: 'enxudre',
  enxudro: 'enxudro',
  enxugai: 'enxugai',
  enxugam: 'enxugam',
  enxugar: 'enxugar',
  enxugas: 'enxugas',
  enxugou: 'enxugou',
  enxugue: 'enxugue',
  enxulha: 'enxulha',
  enxulho: 'enxulho',
  enxurda: 'enxurdá',
  enxurde: 'enxurde',
  enxurdo: 'enxurdo',
  enxurra: 'enxurra',
  enxurre: 'enxurre',
  enxurro: 'enxurro',
  enxutas: 'enxutas',
  enxutos: 'enxutos',
  enzampa: 'enzampa',
  enzampe: 'enzampe',
  enzampo: 'enzampo',
  enzarel: 'enzarel',
  enzarol: 'enzarol',
  enzenas: 'enzenas',
  enzenza: 'enzenza',
  enzimas: 'enzimas',
  enzinai: 'enzinai',
  enzinam: 'enzinam',
  enzinar: 'enzinar',
  enzinas: 'enzinas',
  enzinei: 'enzinei',
  enzinem: 'enzinem',
  enzines: 'enzines',
  enzinha: 'enzinha',
  enzinho: 'enzinho',
  enzinou: 'enzinou',
  enzoica: 'enzoica',
  enzoico: 'enzoico',
  enzolos: 'enzolos',
  enzonai: 'enzonai',
  enzonam: 'enzonam',
  enzonar: 'enzonar',
  enzonas: 'enzonas',
  enzonei: 'enzonei',
  enzonem: 'enzonem',
  enzones: 'enzones',
  enzonou: 'enzonou',
  enzonza: 'enzonza',
  enzonze: 'enzonze',
  enzonzo: 'enzonzo',
  enzoose: 'enzoose',
  eocenes: 'eocenes',
  eocenos: 'eocenos',
  eofilos: 'eofilos',
  eolacao: 'eolação',
  eolanto: 'eolanto',
  eoliana: 'eoliana',
  eoliano: 'eoliano',
  eolicas: 'eólicas',
  eolicos: 'eólicos',
  eolidia: 'eolídia',
  eolinas: 'eolinas',
  eolismo: 'eolismo',
  eolista: 'eolista',
  eolitos: 'eólitos',
  eolizai: 'eolizai',
  eolizam: 'eolizam',
  eolizar: 'eolizar',
  eolizas: 'eolizas',
  eolizei: 'eolizei',
  eolizem: 'eolizem',
  eolizes: 'eolizes',
  eolizou: 'eolizou',
  eolonto: 'eolonto',
  eombolo: 'eombolo',
  eomorfo: 'eomorfo',
  eonismo: 'eonismo',
  eonista: 'eonista',
  eonitas: 'eonitas',
  eordeia: 'eordeia',
  eordeus: 'eordeus',
  eosinas: 'eosinas',
  eositas: 'eositas',
  eosites: 'eosites',
  eosotos: 'eosotos',
  eoterio: 'eotério',
  eozoica: 'eozoica',
  eozoico: 'eozoico',
  eozones: 'eozones',
  epacmos: 'epacmos',
  epacres: 'épacres',
  epactal: 'epactal',
  epactas: 'epactas',
  epagoge: 'epagoge',
  epagogo: 'epagogo',
  epaltas: 'epaltas',
  epaltos: 'epaltos',
  epanodo: 'epânodo',
  eparcas: 'eparcas',
  eparmas: 'eparmas',
  epaxial: 'epaxial',
  epecino: 'epecino',
  epeiras: 'epeiras',
  epeolos: 'epéolos',
  epervas: 'epervas',
  epialos: 'epíalos',
  epibata: 'epibata',
  epibole: 'epíbole',
  epibolo: 'epíbolo',
  epicada: 'epícada',
  epicado: 'epícado',
  epiceas: 'epíceas',
  epiceia: 'epiceia',
  epiceno: 'epiceno',
  epicero: 'epícero',
  epicito: 'epícito',
  epicloe: 'epícloe',
  epicomo: 'epícomo',
  epicopo: 'epicopo',
  epidapo: 'epídapo',
  epidema: 'epídema',
  epidese: 'epídese',
  epidose: 'epídose',
  epidota: 'epídota',
  epidoto: 'epídoto',
  epiecia: 'epiecia',
  epifana: 'epífana',
  epifane: 'epífane',
  epifano: 'epífano',
  epifase: 'epífase',
  epifilo: 'epifilo',
  epifise: 'epífise',
  epifita: 'epífita',
  epifito: 'epífito',
  epifono: 'epifono',
  epifora: 'epífora',
  epigeia: 'epigeia',
  epigeno: 'epígeno',
  epigeus: 'epigeus',
  epigino: 'epígino',
  epigono: 'epígono',
  epilada: 'epilada',
  epilado: 'epilado',
  epilais: 'epilais',
  epilamo: 'epilamo',
  epilara: 'epilara',
  epilava: 'epilava',
  epileis: 'epileis',
  epilemo: 'epilemo',
  epilios: 'epílios',
  epilobo: 'epílobo',
  epiloga: 'epiloga',
  epilogo: 'epílogo',
  epimeno: 'epímeno',
  epimera: 'epímera',
  epimero: 'epímero',
  epimona: 'epímona',
  epimone: 'epimone',
  epinema: 'epinema',
  epiodia: 'epiódia',
  epipedo: 'epípedo',
  epipial: 'epipial',
  epipiao: 'epipião',
  epiploo: 'epíploo',
  epiplos: 'epiplos',
  epipode: 'epípode',
  epipola: 'epípola',
  epirica: 'epírica',
  epirico: 'epírico',
  epirota: 'epirota',
  epirote: 'epirote',
  episcia: 'epíscia',
  episcio: 'epíscio',
  episema: 'episema',
  episemo: 'episemo',
  episios: 'epísios',
  episita: 'episita',
  episito: 'episito',
  epistai: 'epistai',
  epistam: 'epistam',
  epistar: 'epistar',
  epistas: 'epistas',
  epistei: 'epistei',
  epistem: 'epistem',
  epistes: 'epistes',
  epistou: 'epistou',
  epitalo: 'epitalo',
  epitase: 'epítase',
  epitaxe: 'epitaxe',
  epiteca: 'epiteca',
  epiteco: 'epiteco',
  epitema: 'epítema',
  epitese: 'epítese',
  epiteta: 'epiteta',
  epitete: 'epitete',
  epiteto: 'epíteto',
  epitimo: 'epítimo',
  epitoga: 'epítoga',
  epitoma: 'epitoma',
  epitome: 'epítome',
  epitomo: 'epitomo',
  epitopo: 'epítopo',
  epitoxe: 'epitoxe',
  epixilo: 'epíxilo',
  epizona: 'epizona',
  epocais: 'epocais',
  epodica: 'epódica',
  epodico: 'epódico',
  eponima: 'epônima',
  eponimo: 'epônimo',
  epopeia: 'epopéia',
  epopsia: 'epopsia',
  epoptas: 'epoptas',
  epoques: 'epoquês',
  epoxica: 'epóxica',
  epoxico: 'epóxico',
  epoxido: 'epóxido',
  epsilao: 'epsilão',
  epsilon: 'épsilon',
  epsilos: 'épsilos',
  epulida: 'epúlida',
  epulide: 'epúlide',
  epuloes: 'epulões',
  epulose: 'epulose',
  epumuno: 'epumuno',
  epupulo: 'epupulo',
  epureia: 'epureia',
  equacao: 'equação',
  equador: 'equador',
  equalas: 'equalas',
  equante: 'equante',
  equavel: 'equável',
  equedes: 'equedes',
  equeias: 'equeias',
  equemos: 'equemos',
  equenes: 'équenes',
  equenha: 'equenha',
  equesas: 'equesas',
  equesos: 'equesos',
  equevas: 'equevas',
  equevos: 'equevos',
  equices: 'equices',
  equidas: 'équidas',
  equidea: 'equídea',
  equideo: 'equídeo',
  equidna: 'equidna',
  equidno: 'equidno',
  equieia: 'equieia',
  equimis: 'equímis',
  equinas: 'equinas',
  equinos: 'equinos',
  equioes: 'equiões',
  equipai: 'equipai',
  equipam: 'equipam',
  equipar: 'equipar',
  equipas: 'equipas',
  equipei: 'equipei',
  equipem: 'equipem',
  equipes: 'equipes',
  equipou: 'equipou',
  equisao: 'equisão',
  equitas: 'equitas',
  equorea: 'equórea',
  equoreo: 'equóreo',
  eraceas: 'eráceas',
  eraceos: 'eráceos',
  eradoes: 'eradões',
  eradona: 'eradona',
  eradora: 'eradora',
  erandes: 'erandes',
  erandis: 'erândis',
  eranica: 'erânica',
  eranico: 'erânico',
  erantes: 'erantes',
  erantio: 'erântio',
  erantis: 'erântis',
  erardes: 'erardes',
  erareis: 'eráreis',
  eraremo: 'eraremo',
  erariam: 'erariam',
  erarias: 'erarias',
  erarios: 'erários',
  erarmos: 'erarmos',
  erartas: 'erartas',
  erassem: 'erassem',
  erasses: 'erasses',
  erastes: 'erastes',
  eratema: 'erátema',
  erativa: 'erativa',
  erativo: 'erativo',
  eraveis: 'eráveis',
  erbabos: 'erbabos',
  erbicas: 'érbicas',
  erbicos: 'érbicos',
  erbinas: 'erbinas',
  ercilas: 'ercilas',
  erdimos: 'érdimos',
  erebias: 'erébias',
  ereccao: 'erecção',
  erecias: 'erécias',
  erecoes: 'ereções',
  erecona: 'ereçona',
  erectas: 'erectas',
  erectil: 'eréctil',
  erectis: 'eréctis',
  erector: 'erector',
  erectos: 'erectos',
  erectus: 'erectus',
  erefues: 'erefuês',
  erembos: 'erembos',
  eremias: 'erêmias',
  eremita: 'eremita',
  eremuro: 'eremuro',
  erencio: 'erêncio',
  erepada: 'erepadá',
  ereques: 'erequês',
  ereteis: 'eréteis',
  eretias: 'erétias',
  eretino: 'eretino',
  eretite: 'eretite',
  eretiva: 'eretiva',
  eretivo: 'eretivo',
  eretora: 'eretora',
  eretria: 'erétria',
  eretrio: 'erétrio',
  eretriz: 'eretriz',
  ergamos: 'ergamos',
  ergasmo: 'ergasmo',
  ergatas: 'érgatas',
  ergatos: 'érgatos',
  ergolis: 'érgolis',
  ergonio: 'ergônio',
  ergueis: 'ergueis',
  erguemo: 'erguemo',
  erguera: 'erguera',
  erguiam: 'erguiam',
  erguias: 'erguias',
  erguico: 'erguiço',
  erguida: 'erguida',
  erguido: 'erguido',
  ergulho: 'ergulho',
  erianto: 'erianto',
  ericada: 'eriçada',
  ericado: 'eriçado',
  ericais: 'eriçais',
  ericale: 'ericale',
  ericamo: 'eriçamo',
  ericara: 'eriçará',
  ericava: 'eriçava',
  ericeas: 'eríceas',
  ericeis: 'ericeis',
  ericemo: 'ericemo',
  ericeos: 'eríceos',
  ericibe: 'erícibe',
  ericica: 'ericica',
  ericina: 'ericina',
  ericino: 'ericino',
  ericios: 'erícios',
  ericola: 'erícola',
  erictos: 'erictos',
  eridana: 'erídana',
  eridano: 'erídano',
  erifera: 'erífera',
  erifero: 'erífero',
  erifias: 'erífias',
  erigena: 'erígena',
  erigeno: 'erígeno',
  erigiam: 'erigiam',
  erigias: 'erigias',
  erigida: 'erigida',
  erigido: 'erigido',
  erigimo: 'erigimo',
  erigira: 'erigira',
  erigona: 'erígona',
  erigono: 'erígono',
  erijais: 'erijais',
  erijamo: 'erijamo',
  erilzao: 'erilzão',
  erineus: 'erineus',
  eringio: 'eríngio',
  erinias: 'erínias',
  erinita: 'erinita',
  erinose: 'erinose',
  eriopes: 'eriopes',
  eriopos: 'eríopos',
  eriopse: 'eriopse',
  eripede: 'erípede',
  eririba: 'eriribá',
  eririno: 'eririno',
  erisibe: 'erísibe',
  erisife: 'erisife',
  erisimo: 'erísimo',
  erisipe: 'erisipe',
  erismas: 'erismas',
  eritaco: 'erítaco',
  eriteia: 'eriteia',
  eritema: 'eritema',
  eriteus: 'eriteus',
  eritino: 'eritino',
  eritios: 'eritios',
  eritrao: 'eritrão',
  eritrea: 'erítrea',
  eritreo: 'erítreo',
  eritreu: 'eritreu',
  eritrol: 'eritrol',
  eritros: 'eritros',
  erizena: 'erizena',
  erizeno: 'erizeno',
  ermadao: 'ermadão',
  ermadas: 'ermadas',
  ermador: 'ermador',
  ermados: 'ermados',
  ermamos: 'ermamos',
  ermando: 'ermando',
  ermante: 'ermante',
  ermaram: 'ermaram',
  ermarao: 'ermarão',
  ermaras: 'ermarás',
  ermarei: 'ermarei',
  ermarem: 'ermarem',
  ermares: 'ermares',
  ermaria: 'ermaria',
  ermarmo: 'ermarmo',
  ermasse: 'ermasse',
  ermaste: 'ermaste',
  ermavam: 'ermavam',
  ermavas: 'ermavas',
  ermelos: 'ermelos',
  ermemos: 'ermemos',
  ermense: 'ermense',
  ermidas: 'ermidas',
  ermitao: 'ermitão',
  ermitas: 'ermitãs',
  ermitoa: 'ermitoa',
  ernitas: 'ernitas',
  erodais: 'erodais',
  erodeis: 'erodeis',
  erodemo: 'erodemo',
  erodera: 'eroderá',
  erodiam: 'erodiam',
  erodias: 'erodias',
  erodida: 'erodida',
  erodido: 'erodido',
  erodimo: 'erodimo',
  erodios: 'eródios',
  erodira: 'erodira',
  erofila: 'erófila',
  erofilo: 'erófilo',
  erogena: 'erógena',
  erogeno: 'erógeno',
  eropata: 'eropata',
  erosada: 'erosada',
  erosado: 'erosado',
  erosais: 'erosais',
  erosamo: 'erosamo',
  erosara: 'erosará',
  erosava: 'erosava',
  eroseis: 'eroseis',
  erosemo: 'erosemo',
  erosiva: 'erosiva',
  erosivo: 'erosivo',
  erosoes: 'erosões',
  erosona: 'erosona',
  erotica: 'erótica',
  erotico: 'erótico',
  erotilo: 'erótilo',
  erotiza: 'erotiza',
  erotize: 'erotize',
  erotizo: 'erotizo',
  erpetao: 'erpetão',
  erpodio: 'erpódio',
  erradao: 'erradão',
  erradas: 'erradas',
  erradia: 'erradia',
  erradio: 'erradio',
  errador: 'errador',
  errados: 'errados',
  erramos: 'erramos',
  errando: 'errando',
  errante: 'errante',
  erraram: 'erraram',
  errarao: 'errarão',
  erraras: 'erraras',
  errarei: 'errarei',
  errarem: 'errarem',
  errares: 'errares',
  erraria: 'erraria',
  errarmo: 'errarmo',
  errasse: 'errasse',
  erraste: 'erraste',
  erratas: 'erratas',
  erratil: 'errátil',
  erratum: 'erratum',
  erravam: 'erravam',
  erravas: 'erravas',
  erreira: 'erreira',
  erreiro: 'erreiro',
  erremos: 'erremos',
  erricai: 'erriçai',
  erricam: 'erriçam',
  erricar: 'erriçar',
  erricas: 'erriças',
  erricei: 'erricei',
  erricem: 'erricem',
  errices: 'errices',
  erricou: 'erriçou',
  errinas: 'errinas',
  errinho: 'errinho',
  errinos: 'errinos',
  erritas: 'erritas',
  erronea: 'errônea',
  erroneo: 'errôneo',
  erronia: 'erronia',
  erucato: 'erucato',
  erucico: 'erúcico',
  erucina: 'erucina',
  eructai: 'eructai',
  eructam: 'eructam',
  eructar: 'eructar',
  eructas: 'eructas',
  eructei: 'eructei',
  eructem: 'eructem',
  eructes: 'eructes',
  eructou: 'eructou',
  erudais: 'erudais',
  erudita: 'erudita',
  erudite: 'erudite',
  erudito: 'erudito',
  eruexim: 'eruexim',
  erugita: 'erugita',
  eruidos: 'eruídos',
  erupcao: 'erupção',
  ervacal: 'ervaçal',
  ervacas: 'ervacas',
  ervacum: 'ervaçum',
  ervadao: 'ervadão',
  ervadas: 'ervadas',
  ervador: 'ervador',
  ervados: 'ervados',
  ervagem: 'ervagem',
  ervamos: 'ervamos',
  ervanas: 'ervanas',
  ervanco: 'ervanço',
  ervando: 'ervando',
  ervanos: 'ervanos',
  ervante: 'ervante',
  ervaram: 'ervaram',
  ervarao: 'ervarão',
  ervaras: 'ervarás',
  ervarei: 'ervarei',
  ervarem: 'ervarem',
  ervares: 'ervares',
  ervaria: 'ervaria',
  ervario: 'ervário',
  ervarmo: 'ervarmo',
  ervasco: 'ervasco',
  ervasse: 'ervasse',
  ervaste: 'ervaste',
  ervatao: 'ervatão',
  ervavam: 'ervavam',
  ervavas: 'ervavas',
  ervecam: 'erveçam',
  ervecas: 'erveças',
  ervecei: 'ervecei',
  ervecem: 'ervecem',
  ervecer: 'ervecer',
  erveces: 'erveces',
  erveceu: 'erveceu',
  ervecia: 'ervecia',
  ervedal: 'ervedal',
  ervedel: 'ervedel',
  ervedos: 'ervedos',
  erveira: 'erveira',
  erveiro: 'erveiro',
  ervemos: 'ervemos',
  ervicas: 'erviças',
  ervicos: 'erviços',
  ervilha: 'ervilha',
  ervilhe: 'ervilhe',
  ervilho: 'ervilho',
  ervilia: 'ervília',
  ervinha: 'ervinha',
  ervoada: 'ervoada',
  ervoado: 'ervoado',
  ervoais: 'ervoais',
  ervoamo: 'ervoamo',
  ervoara: 'ervoará',
  ervoava: 'ervoava',
  ervodos: 'érvodos',
  ervoeis: 'ervoeis',
  ervoemo: 'ervoemo',
  ervosas: 'ervosas',
  ervosos: 'ervosos',
  esampai: 'esampai',
  esampam: 'esampam',
  esampar: 'esampar',
  esampas: 'esampas',
  esampei: 'esampei',
  esampem: 'esampem',
  esampes: 'esampes',
  esampou: 'esampou',
  esbafos: 'esbafos',
  esbagoa: 'esbagoa',
  esbagoe: 'esbagoe',
  esbagoo: 'esbagoo',
  esbalda: 'esbalda',
  esbalde: 'esbalde',
  esbaldo: 'esbaldo',
  esbalge: 'esbalge',
  esbalgi: 'esbalgi',
  esbalha: 'esbalhá',
  esbalhe: 'esbalhe',
  esbalho: 'esbalho',
  esbalja: 'esbalja',
  esbaljo: 'esbaljo',
  esbamba: 'esbamba',
  esbambe: 'esbambe',
  esbambo: 'esbambo',
  esbanga: 'esbangá',
  esbango: 'esbango',
  esbanja: 'esbanja',
  esbanje: 'esbanje',
  esbanjo: 'esbanjo',
  esbarai: 'esbarai',
  esbaram: 'esbaram',
  esbarar: 'esbarar',
  esbaras: 'esbaras',
  esbarba: 'esbarba',
  esbarbe: 'esbarbe',
  esbarbo: 'esbarbo',
  esbarda: 'esbardá',
  esbarde: 'esbarde',
  esbardo: 'esbardo',
  esbarei: 'esbarei',
  esbarem: 'esbarem',
  esbares: 'esbares',
  esbarou: 'esbarou',
  esbarra: 'esbarra',
  esbarre: 'esbarre',
  esbarro: 'esbarro',
  esbatam: 'esbatam',
  esbatas: 'esbatas',
  esbatei: 'esbatei',
  esbatem: 'esbatem',
  esbater: 'esbater',
  esbates: 'esbates',
  esbateu: 'esbateu',
  esbatia: 'esbatia',
  esbeata: 'esbeata',
  esbeate: 'esbeate',
  esbeato: 'esbeato',
  esbeica: 'esbeiça',
  esbeice: 'esbeice',
  esbeico: 'esbeiço',
  esbeija: 'esbeijá',
  esbeije: 'esbeije',
  esbeijo: 'esbeijo',
  esbeira: 'esbeirá',
  esbeire: 'esbeire',
  esbeiro: 'esbeiro',
  esbelta: 'esbelta',
  esbelte: 'esbelte',
  esbelto: 'esbelto',
  esbicai: 'esbicai',
  esbicam: 'esbicam',
  esbicar: 'esbicar',
  esbicas: 'esbicas',
  esbicha: 'esbichá',
  esbiche: 'esbiche',
  esbicho: 'esbicho',
  esbicou: 'esbicou',
  esbijai: 'esbijai',
  esbijam: 'esbijam',
  esbijar: 'esbijar',
  esbijas: 'esbijas',
  esbijei: 'esbijei',
  esbijem: 'esbijem',
  esbijes: 'esbijes',
  esbijou: 'esbijou',
  esbilro: 'esbilro',
  esbique: 'esbique',
  esbirra: 'esbirra',
  esbirre: 'esbirre',
  esbirro: 'esbirro',
  esbocai: 'esboçai',
  esbocam: 'esboçam',
  esbocar: 'esboçar',
  esbocas: 'esboças',
  esbocei: 'esbocei',
  esbocem: 'esbocem',
  esboces: 'esboces',
  esbocos: 'esboços',
  esbocou: 'esboçou',
  esbofai: 'esbofai',
  esbofam: 'esbofam',
  esbofar: 'esbofar',
  esbofas: 'esbofas',
  esbofei: 'esbofei',
  esbofem: 'esbofem',
  esbofes: 'esbofes',
  esbofou: 'esbofou',
  esboica: 'esboiça',
  esboice: 'esboice',
  esboico: 'esboiço',
  esbolai: 'esbolai',
  esbolam: 'esbolam',
  esbolar: 'esbolar',
  esbolas: 'esbolas',
  esbolei: 'esbolei',
  esbolem: 'esbolem',
  esboles: 'esboles',
  esbolou: 'esbolou',
  esborda: 'esborda',
  esborde: 'esborde',
  esbordo: 'esbordo',
  esboroa: 'esboroa',
  esboroe: 'esboroe',
  esboroo: 'esboroo',
  esborra: 'esborra',
  esborre: 'esborre',
  esborro: 'esborro',
  esbouca: 'esbouça',
  esbouce: 'esbouce',
  esbouco: 'esbouço',
  esbraca: 'esbraçá',
  esbrace: 'esbrace',
  esbraco: 'esbraço',
  esbrama: 'esbramá',
  esbrame: 'esbrame',
  esbramo: 'esbramo',
  esbrita: 'esbritá',
  esbrite: 'esbrite',
  esbrito: 'esbrito',
  esbriza: 'esbrizá',
  esbrize: 'esbrize',
  esbrizo: 'esbrizo',
  esbroai: 'esbroai',
  esbroam: 'esbroam',
  esbroar: 'esbroar',
  esbroas: 'esbroas',
  esbroei: 'esbroei',
  esbroem: 'esbroem',
  esbroes: 'esbroes',
  esbroou: 'esbroou',
  esbruga: 'esbruga',
  esbrugo: 'esbrugo',
  esbruma: 'esbrumá',
  esbrume: 'esbrume',
  esbrumo: 'esbrumo',
  esbulha: 'esbulha',
  esbulhe: 'esbulhe',
  esbulho: 'esbulho',
  esburga: 'esburga',
  esburgo: 'esburgo',
  esburna: 'esburna',
  esburne: 'esburne',
  esburni: 'esburni',
  esburno: 'esburno',
  esbuxai: 'esbuxai',
  esbuxam: 'esbuxam',
  esbuxar: 'esbuxar',
  esbuxas: 'esbuxas',
  esbuxei: 'esbuxei',
  esbuxem: 'esbuxem',
  esbuxes: 'esbuxes',
  esbuxou: 'esbuxou',
  escabel: 'escabel',
  escabra: 'escabra',
  escabre: 'escabre',
  escabro: 'escabro',
  escacai: 'escacai',
  escacam: 'escacam',
  escacar: 'escacar',
  escacas: 'escacas',
  escacha: 'escacha',
  escache: 'escache',
  escacho: 'escacho',
  escacoa: 'escaçoá',
  escacoe: 'escaçoe',
  escacoo: 'escaçoo',
  escacos: 'escaços',
  escacou: 'escacou',
  escadal: 'escadal',
  escadao: 'escadão',
  escadas: 'escadas',
  escadea: 'escádea',
  escadim: 'escadim',
  escados: 'escadós',
  escafas: 'escafas',
  escafio: 'escáfio',
  escafos: 'escafos',
  escaibo: 'escãibo',
  escaido: 'escaído',
  escalai: 'escalai',
  escalam: 'escalam',
  escalao: 'escalão',
  escalar: 'escalar',
  escalas: 'escalas',
  escalda: 'escalda',
  escalde: 'escalde',
  escaldo: 'escaldo',
  escalei: 'escalei',
  escalem: 'escalem',
  escaler: 'escaler',
  escales: 'escales',
  escalfa: 'escalfa',
  escalfe: 'escalfe',
  escalfo: 'escalfo',
  escalho: 'escalho',
  escalmo: 'escalmo',
  escalou: 'escalou',
  escalpa: 'escalpa',
  escalpe: 'escalpe',
  escalpo: 'escalpo',
  escalte: 'escalte',
  escalva: 'escalva',
  escalve: 'escalve',
  escalvo: 'escalvo',
  escamai: 'escamai',
  escamam: 'escamam',
  escamao: 'escamão',
  escamar: 'escamar',
  escamas: 'escamas',
  escamba: 'escamba',
  escambe: 'escambe',
  escambo: 'escambo',
  escamea: 'escâmea',
  escamei: 'escamei',
  escamel: 'escamel',
  escamem: 'escamem',
  escameo: 'escâmeo',
  escames: 'escames',
  escamou: 'escamou',
  escampa: 'escampa',
  escampe: 'escampe',
  escampo: 'escampo',
  escanai: 'escanai',
  escanam: 'escanam',
  escanar: 'escanar',
  escanas: 'escanas',
  escanca: 'escança',
  escance: 'escance',
  escanco: 'escanço',
  escanda: 'escanda',
  escande: 'escande',
  escandi: 'escandi',
  escando: 'escando',
  escanea: 'escaneá',
  escanei: 'escanei',
  escanem: 'escanem',
  escaner: 'escâner',
  escanes: 'escanes',
  escanga: 'escangá',
  escango: 'escango',
  escanho: 'escanho',
  escanou: 'escanou',
  escapai: 'escapai',
  escapam: 'escapam',
  escapar: 'escapar',
  escapas: 'escapas',
  escapei: 'escapei',
  escapem: 'escapem',
  escapes: 'escapes',
  escapos: 'escapos',
  escapou: 'escapou',
  escaque: 'escaque',
  escarar: 'escarar',
  escaras: 'escaras',
  escarca: 'escarça',
  escarce: 'escarce',
  escarco: 'escarço',
  escarda: 'escarda',
  escarde: 'escarde',
  escardo: 'escardo',
  escarea: 'escareá',
  escarga: 'escarga',
  escargo: 'escargo',
  escaria: 'escaria',
  escarie: 'escarie',
  escario: 'escario',
  escarna: 'escarna',
  escarne: 'escarne',
  escarni: 'escarni',
  escarno: 'escarno',
  escaros: 'escaros',
  escarpa: 'escarpa',
  escarpe: 'escarpe',
  escarpo: 'escarpo',
  escarra: 'escarra',
  escarre: 'escarre',
  escarro: 'escarro',
  escarva: 'escarva',
  escarve: 'escarve',
  escarvo: 'escarvo',
  escasca: 'escasca',
  escasco: 'escasco',
  escassa: 'escassa',
  escasso: 'escasso',
  escatel: 'escatel',
  escatol: 'escatol',
  escatos: 'escatos',
  escauns: 'escauns',
  escauro: 'escauro',
  escavai: 'escavai',
  escavam: 'escavam',
  escavao: 'escavão',
  escavar: 'escavar',
  escavas: 'escavas',
  escavei: 'escavei',
  escavem: 'escavem',
  escaves: 'escaves',
  escavou: 'escavou',
  escelim: 'escelim',
  escinas: 'escinas',
  escinco: 'escinco',
  escinda: 'escinda',
  escinde: 'escinde',
  escindi: 'escindi',
  escindo: 'escindo',
  escirpo: 'escirpo',
  esclavo: 'esclavo',
  esclera: 'esclera',
  esclero: 'esclero',
  esclusa: 'esclusa',
  escoada: 'escoada',
  escoado: 'escoado',
  escoais: 'escoais',
  escoamo: 'escoamo',
  escoara: 'escoara',
  escoava: 'escoava',
  escobar: 'escobar',
  escocai: 'escocai',
  escocam: 'escocam',
  escocar: 'escocar',
  escocas: 'escocas',
  escoces: 'escocês',
  escocha: 'escochá',
  escoche: 'escoche',
  escocho: 'escocho',
  escocia: 'escócia',
  escocio: 'escócio',
  escocou: 'escocou',
  escodai: 'escodai',
  escodam: 'escodam',
  escodar: 'escodar',
  escodas: 'escodas',
  escodea: 'escodeá',
  escodei: 'escodei',
  escodem: 'escodem',
  escodes: 'escodes',
  escodou: 'escodou',
  escodro: 'escodro',
  escoeis: 'escoeis',
  escoemo: 'escoemo',
  escofia: 'escófia',
  escogia: 'escogiá',
  escogie: 'escogie',
  escogio: 'escogio',
  escoica: 'escoiçá',
  escoice: 'escoice',
  escoico: 'escoico',
  escoima: 'escoima',
  escoime: 'escoime',
  escoimo: 'escoimo',
  escoira: 'escoira',
  escolar: 'escolar',
  escolas: 'escolas',
  escolex: 'escólex',
  escolha: 'escolha',
  escolhe: 'escolhe',
  escolhi: 'escolhi',
  escolho: 'escolho',
  escolia: 'escólia',
  escolie: 'escolie',
  escolio: 'escólio',
  escolma: 'escolma',
  escolme: 'escolme',
  escolmo: 'escolmo',
  escolta: 'escolta',
  escolte: 'escolte',
  escolto: 'escolto',
  esconda: 'esconda',
  esconde: 'esconde',
  escondi: 'escondi',
  escondo: 'escondo',
  esconsa: 'esconsa',
  esconse: 'esconse',
  esconso: 'esconso',
  escopas: 'escopas',
  escopea: 'escopeá',
  escopem: 'escopem',
  escopes: 'escopes',
  escopia: 'escopia',
  escopos: 'escopos',
  escopro: 'escopro',
  escoque: 'escoque',
  escorai: 'escorai',
  escoram: 'escoram',
  escorao: 'escorão',
  escorar: 'escorar',
  escoras: 'escoras',
  escorca: 'escorça',
  escorce: 'escorce',
  escorco: 'escorço',
  escorda: 'escorda',
  escorde: 'escorde',
  escordo: 'escordo',
  escorei: 'escorei',
  escorem: 'escorem',
  escores: 'escores',
  escoria: 'escória',
  escorie: 'escorie',
  escorio: 'escorio',
  escorja: 'escorja',
  escorje: 'escorje',
  escorjo: 'escorjo',
  escorlo: 'escorlo',
  escorna: 'escorna',
  escorne: 'escorne',
  escorno: 'escorno',
  escoroa: 'escoroa',
  escoroe: 'escoroe',
  escoroo: 'escoroo',
  escorou: 'escorou',
  escorra: 'escorra',
  escorre: 'escorre',
  escorri: 'escorri',
  escorro: 'escorro',
  escorva: 'escorva',
  escorve: 'escorve',
  escorvo: 'escorvo',
  escoseu: 'escoseu',
  escotas: 'escotas',
  escotel: 'escotel',
  escotes: 'escotes',
  escotia: 'escotia',
  escotie: 'escotie',
  escotio: 'escotio',
  escotos: 'escotos',
  escotro: 'escotro',
  escouca: 'escouça',
  escouce: 'escouce',
  escouco: 'escouço',
  escovai: 'escovai',
  escovam: 'escovam',
  escovao: 'escovão',
  escovar: 'escovar',
  escovas: 'escovas',
  escovei: 'escovei',
  escovem: 'escovém',
  escoves: 'escoves',
  escovou: 'escovou',
  escozam: 'escozam',
  escozas: 'escozas',
  escozei: 'escozei',
  escozem: 'escozem',
  escozer: 'escozer',
  escozes: 'escozes',
  escozeu: 'escozeu',
  escozia: 'escozia',
  escrava: 'escrava',
  escravo: 'escravo',
  escreta: 'escreta',
  escrete: 'escrete',
  escreto: 'escreto',
  escreva: 'escreva',
  escreve: 'escreve',
  escrevi: 'escrevi',
  escrevo: 'escrevo',
  escriba: 'escriba',
  escrino: 'escrino',
  escrita: 'escrita',
  escrito: 'escrito',
  escriva: 'escrivã',
  escrive: 'escrive',
  escrivo: 'escrivo',
  escrota: 'escrota',
  escroto: 'escroto',
  escruta: 'escruta',
  escrute: 'escrute',
  escruto: 'escruto',
  escucir: 'escucir',
  escudai: 'escudai',
  escudam: 'escudam',
  escudar: 'escudar',
  escudas: 'escudas',
  escudei: 'escudei',
  escudem: 'escudem',
  escudes: 'escudes',
  escudos: 'escudos',
  escudou: 'escudou',
  esculca: 'esculca',
  esculco: 'esculco',
  esculos: 'ésculos',
  esculpa: 'esculpa',
  esculpe: 'esculpe',
  esculpi: 'esculpi',
  esculpo: 'esculpo',
  esculta: 'esculta',
  esculte: 'esculte',
  esculto: 'esculto',
  escumai: 'escumai',
  escumam: 'escumam',
  escumar: 'escumar',
  escumas: 'escumas',
  escumei: 'escumei',
  escumem: 'escumem',
  escumes: 'escumes',
  escumou: 'escumou',
  escunas: 'escunas',
  escupam: 'escupam',
  escupas: 'escupas',
  escupia: 'escupia',
  escupir: 'escupir',
  escupis: 'escupis',
  escupiu: 'escupiu',
  escurai: 'escurai',
  escuram: 'escuram',
  escurao: 'escurão',
  escurar: 'escurar',
  escuras: 'escuras',
  escurei: 'escurei',
  escurem: 'escurem',
  escures: 'escures',
  escuria: 'escuria',
  escurir: 'escurir',
  escuris: 'escuris',
  escuriu: 'escuriu',
  escuros: 'escuros',
  escurou: 'escurou',
  escurra: 'escurra',
  escusai: 'escusai',
  escusam: 'escusam',
  escusar: 'escusar',
  escusas: 'escusas',
  escusei: 'escusei',
  escusem: 'escusem',
  escuses: 'escuses',
  escusos: 'escusos',
  escusou: 'escusou',
  escutai: 'escutai',
  escutam: 'escutam',
  escutar: 'escutar',
  escutas: 'escutas',
  escutei: 'escutei',
  escutem: 'escutem',
  escutes: 'escutes',
  escutou: 'escutou',
  eserina: 'eserina',
  esfacam: 'esfaçam',
  esfacas: 'esfaças',
  esfagno: 'esfagno',
  esfaima: 'esfaima',
  esfaime: 'esfaime',
  esfaimo: 'esfaimo',
  esfalca: 'esfalcá',
  esfalco: 'esfalco',
  esfalfa: 'esfalfa',
  esfalfe: 'esfalfe',
  esfalfo: 'esfalfo',
  esfarao: 'esfarão',
  esfaras: 'esfarás',
  esfarda: 'esfardá',
  esfarde: 'esfarde',
  esfardo: 'esfardo',
  esfarei: 'esfarei',
  esfaria: 'esfaria',
  esfarpa: 'esfarpa',
  esfarpe: 'esfarpe',
  esfarpo: 'esfarpo',
  esfatia: 'esfatia',
  esfatie: 'esfatie',
  esfatio: 'esfatio',
  esfazei: 'esfazei',
  esfazem: 'esfazem',
  esfazer: 'esfazer',
  esfazes: 'esfazes',
  esfazia: 'esfazia',
  esfeces: 'esfeces',
  esfecos: 'esfecos',
  esfeita: 'esfeita',
  esfeito: 'esfeito',
  esfelga: 'esfelgá',
  esfelgo: 'esfelgo',
  esfenas: 'esfenas',
  esfenio: 'esfênio',
  esfenos: 'esfenos',
  esferal: 'esferal',
  esferas: 'esferas',
  esferia: 'esféria',
  esferio: 'esfério',
  esferos: 'esferos',
  esferva: 'esferva',
  esferve: 'esfervê',
  esfervi: 'esfervi',
  esfervo: 'esfervo',
  esfiada: 'esfiada',
  esfiado: 'esfiado',
  esfiais: 'esfiais',
  esfiamo: 'esfiamo',
  esfiapa: 'esfiapa',
  esfiape: 'esfiape',
  esfiapo: 'esfiapo',
  esfiara: 'esfiará',
  esfiava: 'esfiava',
  esfibra: 'esfibrá',
  esfibre: 'esfibre',
  esfibro: 'esfibro',
  esfieis: 'esfieis',
  esfiemo: 'esfiemo',
  esfigmo: 'esfigmo',
  esfinge: 'esfinge',
  esfirra: 'esfirra',
  esfizer: 'esfizer',
  esfloca: 'esflocá',
  esfloco: 'esfloco',
  esflora: 'esflora',
  esflore: 'esflore',
  esfloro: 'esfloro',
  esfodro: 'esfodro',
  esfoica: 'esfoiçá',
  esfoice: 'esfoice',
  esfoico: 'esfoiço',
  esfoira: 'esfoira',
  esfoire: 'esfoire',
  esfoiro: 'esfoiro',
  esfolai: 'esfolai',
  esfolam: 'esfolam',
  esfolar: 'esfolar',
  esfolas: 'esfolas',
  esfolei: 'esfolei',
  esfolem: 'esfolem',
  esfoles: 'esfoles',
  esfolha: 'esfolha',
  esfolhe: 'esfolhe',
  esfolho: 'esfolho',
  esfolia: 'esfolia',
  esfolie: 'esfolie',
  esfolio: 'esfolio',
  esfolou: 'esfolou',
  esfomea: 'esfomeá',
  esforca: 'esforça',
  esforce: 'esforce',
  esforco: 'esforço',
  esfossa: 'esfossa',
  esfosse: 'esfosse',
  esfosso: 'esfosso',
  esfouca: 'esfouça',
  esfouce: 'esfouce',
  esfouco: 'esfouço',
  esfrega: 'esfrega',
  esfrego: 'esfrego',
  esfriai: 'esfriai',
  esfriam: 'esfriam',
  esfriar: 'esfriar',
  esfrias: 'esfrias',
  esfriei: 'esfriei',
  esfriem: 'esfriem',
  esfries: 'esfries',
  esfriou: 'esfriou',
  esfrola: 'esfrola',
  esfrole: 'esfrole',
  esfrolo: 'esfrolo',
  esfugir: 'esfugir',
  esfumai: 'esfumai',
  esfumam: 'esfumam',
  esfumar: 'esfumar',
  esfumas: 'esfumas',
  esfumea: 'esfumeá',
  esfumei: 'esfumei',
  esfumem: 'esfumem',
  esfumes: 'esfumes',
  esfumou: 'esfumou',
  esfuria: 'esfuriá',
  esfurie: 'esfurie',
  esfurio: 'esfurio',
  esfuroa: 'esfuroá',
  esfuroe: 'esfuroe',
  esfuroo: 'esfuroo',
  esfuzia: 'esfuzia',
  esfuzie: 'esfuzie',
  esfuzio: 'esfuzio',
  esgacai: 'esgaçai',
  esgacam: 'esgaçam',
  esgacar: 'esgaçar',
  esgacas: 'esgaças',
  esgacei: 'esgacei',
  esgacem: 'esgacem',
  esgaces: 'esgaces',
  esgache: 'esgache',
  esgacou: 'esgaçou',
  esgadao: 'esgadão',
  esgadas: 'esgadas',
  esgador: 'esgador',
  esgados: 'esgados',
  esgaire: 'esgaire',
  esgaiva: 'esgaiva',
  esgaive: 'esgaive',
  esgaivo: 'esgaivo',
  esgalga: 'esgalga',
  esgalgo: 'esgalgo',
  esgalha: 'esgalha',
  esgalhe: 'esgalhe',
  esgalho: 'esgalho',
  esgamoa: 'esgamoá',
  esgamoe: 'esgamoe',
  esgamoo: 'esgamoo',
  esgamos: 'esgamos',
  esgampa: 'esgampa',
  esgampe: 'esgampe',
  esgampo: 'esgampo',
  esganai: 'esganai',
  esganam: 'esganam',
  esganao: 'esganão',
  esganar: 'esganar',
  esganas: 'esganas',
  esgando: 'esgando',
  esganei: 'esganei',
  esganem: 'esganem',
  esganes: 'esganes',
  esganir: 'esganir',
  esganou: 'esganou',
  esgante: 'esgante',
  esgarai: 'esgarai',
  esgaram: 'esgaram',
  esgarao: 'esgarão',
  esgarar: 'esgarar',
  esgaras: 'esgarás',
  esgarca: 'esgarça',
  esgarce: 'esgarce',
  esgarco: 'esgarço',
  esgarei: 'esgarei',
  esgarem: 'esgarem',
  esgares: 'esgares',
  esgarga: 'esgargá',
  esgargo: 'esgargo',
  esgaria: 'esgaria',
  esgarna: 'esgarna',
  esgarne: 'esgarne',
  esgarno: 'esgarno',
  esgarou: 'esgarou',
  esgarra: 'esgarra',
  esgarre: 'esgarre',
  esgarro: 'esgarro',
  esgasse: 'esgasse',
  esgaste: 'esgaste',
  esgatea: 'esgateá',
  esgavam: 'esgavam',
  esgavas: 'esgavas',
  esgazea: 'esgazeá',
  esgodai: 'esgodai',
  esgodam: 'esgodam',
  esgodar: 'esgodar',
  esgodas: 'esgodas',
  esgodei: 'esgodei',
  esgodem: 'esgodem',
  esgodes: 'esgodes',
  esgodou: 'esgodou',
  esgoela: 'esgoela',
  esgoele: 'esgoele',
  esgoelo: 'esgoelo',
  esgorja: 'esgorja',
  esgorje: 'esgorje',
  esgorjo: 'esgorjo',
  esgotai: 'esgotai',
  esgotam: 'esgotam',
  esgotar: 'esgotar',
  esgotas: 'esgotas',
  esgotei: 'esgotei',
  esgotem: 'esgotem',
  esgotes: 'esgotes',
  esgotos: 'esgotos',
  esgotou: 'esgotou',
  esgrela: 'esgrela',
  esgrila: 'esgrilá',
  esgrile: 'esgrile',
  esgrilo: 'esgrilo',
  esgrima: 'esgrima',
  esgrime: 'esgrime',
  esgrimi: 'esgrimi',
  esgrimo: 'esgrimo',
  esgueis: 'esgueis',
  esguiai: 'esguiai',
  esguiam: 'esguiam',
  esguiao: 'esguião',
  esguiar: 'esguiar',
  esguias: 'esguias',
  esguiei: 'esguiei',
  esguiem: 'esguiem',
  esguies: 'esguies',
  esguiez: 'esguiez',
  esguios: 'esguios',
  esguiou: 'esguiou',
  esguita: 'esguita',
  esguite: 'esguite',
  esguito: 'esguito',
  esguvio: 'esgúvio',
  esilato: 'esilato',
  esipras: 'esipras',
  eslabao: 'eslabão',
  eslagai: 'eslagai',
  eslagam: 'eslagam',
  eslagar: 'eslagar',
  eslagas: 'eslagas',
  eslagou: 'eslagou',
  eslague: 'eslague',
  eslaide: 'eslaide',
  eslavao: 'eslavão',
  eslavas: 'eslavas',
  eslavos: 'eslavos',
  eslinga: 'eslinga',
  eslingo: 'eslingo',
  esloana: 'esloana',
  esluvio: 'eslúvio',
  esmadao: 'esmadão',
  esmadas: 'esmadas',
  esmador: 'esmador',
  esmados: 'esmados',
  esmaeca: 'esmaeça',
  esmaece: 'esmaece',
  esmaeci: 'esmaeci',
  esmaeco: 'esmaeço',
  esmagai: 'esmagai',
  esmagam: 'esmagam',
  esmagao: 'esmagão',
  esmagar: 'esmagar',
  esmagas: 'esmagas',
  esmagou: 'esmagou',
  esmague: 'esmague',
  esmaiai: 'esmaiai',
  esmaiam: 'esmaiam',
  esmaiar: 'esmaiar',
  esmaias: 'esmaias',
  esmaiei: 'esmaiei',
  esmaiem: 'esmaiem',
  esmaies: 'esmaies',
  esmaiou: 'esmaiou',
  esmalas: 'esmalas',
  esmalha: 'esmalha',
  esmalhe: 'esmalhe',
  esmalho: 'esmalho',
  esmalma: 'esmalmá',
  esmalme: 'esmalme',
  esmalmo: 'esmalmo',
  esmalta: 'esmalta',
  esmalte: 'esmalte',
  esmalto: 'esmalto',
  esmamos: 'esmamos',
  esmando: 'esmando',
  esmania: 'esmania',
  esmanie: 'esmanie',
  esmanio: 'esmanio',
  esmanja: 'esmanja',
  esmanje: 'esmanje',
  esmanjo: 'esmanjo',
  esmante: 'esmante',
  esmaram: 'esmaram',
  esmarao: 'esmarão',
  esmaras: 'esmarás',
  esmarca: 'esmarcá',
  esmarco: 'esmarco',
  esmarei: 'esmarei',
  esmarem: 'esmarem',
  esmares: 'esmares',
  esmaria: 'esmaria',
  esmaris: 'esmáris',
  esmarmo: 'esmarmo',
  esmasse: 'esmasse',
  esmaste: 'esmaste',
  esmavam: 'esmavam',
  esmavas: 'esmavas',
  esmeada: 'esmeada',
  esmeado: 'esmeado',
  esmeais: 'esmeais',
  esmeara: 'esmeara',
  esmeava: 'esmeava',
  esmecha: 'esmecha',
  esmeche: 'esmeche',
  esmecho: 'esmecho',
  esmeeis: 'esmeeis',
  esmegma: 'esmegma',
  esmeiam: 'esmeiam',
  esmeias: 'esmeias',
  esmeiem: 'esmeiem',
  esmeies: 'esmeies',
  esmelma: 'esmelmá',
  esmelme: 'esmelme',
  esmelmo: 'esmelmo',
  esmemos: 'esmemos',
  esmenai: 'esmenai',
  esmenam: 'esmenam',
  esmenar: 'esmenar',
  esmenas: 'esmenas',
  esmenei: 'esmenei',
  esmenem: 'esmenem',
  esmenes: 'esmenes',
  esmenou: 'esmenou',
  esmerai: 'esmerai',
  esmeram: 'esmeram',
  esmerar: 'esmerar',
  esmeras: 'esmeras',
  esmerei: 'esmerei',
  esmerem: 'esmerem',
  esmeres: 'esmeres',
  esmeril: 'esmeril',
  esmerim: 'esmerim',
  esmeris: 'esmeris',
  esmerma: 'esmermá',
  esmerme: 'esmerme',
  esmermo: 'esmermo',
  esmeros: 'esmeros',
  esmerou: 'esmerou',
  esmetia: 'esmetia',
  esmetie: 'esmetie',
  esmetio: 'esmetio',
  esmicha: 'esmicha',
  esmiche: 'esmiche',
  esmicho: 'esmicho',
  esmifra: 'esmifra',
  esmifre: 'esmifre',
  esmifro: 'esmifro',
  esmilha: 'esmilhá',
  esmilhe: 'esmilhe',
  esmilho: 'esmilho',
  esminto: 'esminto',
  esmiola: 'esmiola',
  esmiole: 'esmiole',
  esmiolo: 'esmiolo',
  esmirna: 'esmirna',
  esmirra: 'esmirra',
  esmirre: 'esmirre',
  esmirro: 'esmirro',
  esmitia: 'esmítia',
  esmiuca: 'esmiúça',
  esmiuce: 'esmiúce',
  esmiuco: 'esmiúço',
  esmiuda: 'esmiúda',
  esmiude: 'esmiude',
  esmiudo: 'esmiúdo',
  esmoais: 'esmoais',
  esmocai: 'esmocai',
  esmocam: 'esmocam',
  esmocar: 'esmocar',
  esmocas: 'esmocas',
  esmocha: 'esmocha',
  esmoche: 'esmoche',
  esmocho: 'esmocho',
  esmocou: 'esmocou',
  esmoeis: 'esmoeis',
  esmoera: 'esmoerá',
  esmoiam: 'esmoíam',
  esmoias: 'esmoías',
  esmoica: 'esmoicá',
  esmoico: 'esmoico',
  esmoida: 'esmoída',
  esmoido: 'esmoído',
  esmoita: 'esmoita',
  esmoite: 'esmoite',
  esmoito: 'esmoito',
  esmolai: 'esmolai',
  esmolam: 'esmolam',
  esmolar: 'esmolar',
  esmolas: 'esmolas',
  esmolei: 'esmolei',
  esmolem: 'esmolem',
  esmoler: 'esmoler',
  esmoles: 'esmoles',
  esmoleu: 'esmoleu',
  esmolfo: 'esmolfo',
  esmolia: 'esmolia',
  esmolna: 'esmolna',
  esmolol: 'esmolol',
  esmolou: 'esmolou',
  esmonai: 'esmonai',
  esmonam: 'esmonam',
  esmonar: 'esmonar',
  esmonas: 'esmonas',
  esmonca: 'esmonca',
  esmonco: 'esmonco',
  esmonda: 'esmonda',
  esmonde: 'esmonde',
  esmondo: 'esmondo',
  esmonei: 'esmonei',
  esmonem: 'esmonem',
  esmones: 'esmones',
  esmonou: 'esmonou',
  esmoque: 'esmoque',
  esmorca: 'esmorçá',
  esmorce: 'esmorce',
  esmorco: 'esmorço',
  esmorma: 'esmormá',
  esmorme: 'esmorme',
  esmormo: 'esmormo',
  esmorra: 'esmorra',
  esmorre: 'esmorre',
  esmorro: 'esmorro',
  esmorza: 'esmorzá',
  esmorze: 'esmorze',
  esmorzo: 'esmorzo',
  esmossa: 'esmossá',
  esmosse: 'esmosse',
  esmosso: 'esmosso',
  esmouca: 'esmouca',
  esmouco: 'esmouco',
  esmouta: 'esmoutá',
  esmoute: 'esmoute',
  esmouto: 'esmouto',
  esmurai: 'esmurai',
  esmuram: 'esmuram',
  esmurar: 'esmurar',
  esmuras: 'esmuras',
  esmurei: 'esmurei',
  esmurem: 'esmurem',
  esmures: 'esmures',
  esmurou: 'esmurou',
  esmurra: 'esmurra',
  esmurre: 'esmurre',
  esmurro: 'esmurro',
  esnacai: 'esnacai',
  esnacam: 'esnacam',
  esnacar: 'esnacar',
  esnacas: 'esnacas',
  esnacou: 'esnacou',
  esnaque: 'esnaque',
  esnobai: 'esnobai',
  esnobam: 'esnobam',
  esnobar: 'esnobar',
  esnobas: 'esnobas',
  esnobei: 'esnobei',
  esnobem: 'esnobem',
  esnobes: 'esnobes',
  esnobou: 'esnobou',
  esnocai: 'esnocai',
  esnocam: 'esnocam',
  esnocar: 'esnocar',
  esnocas: 'esnocas',
  esnocou: 'esnocou',
  esnogas: 'esnogas',
  esnoque: 'esnoque',
  esnuada: 'esnuada',
  esnuado: 'esnuado',
  esnuais: 'esnuais',
  esnuamo: 'esnuamo',
  esnuara: 'esnuará',
  esnuava: 'esnuava',
  esnueis: 'esnueis',
  esnuemo: 'esnuemo',
  esocida: 'esócida',
  esofaga: 'esófaga',
  esofago: 'esôfago',
  esopete: 'esopete',
  esopeto: 'esopeto',
  esopica: 'esópica',
  esopico: 'esópico',
  espacai: 'espaçai',
  espacam: 'espaçam',
  espacar: 'espaçar',
  espacas: 'espaças',
  espacea: 'espaceá',
  espacei: 'espacei',
  espacem: 'espacem',
  espaces: 'espaces',
  espacia: 'espacia',
  espacie: 'espacie',
  espacio: 'espácio',
  espacos: 'espaços',
  espacou: 'espaçou',
  espadai: 'espadai',
  espadal: 'espadal',
  espadam: 'espadam',
  espadao: 'espadão',
  espadar: 'espadar',
  espadas: 'espadas',
  espadea: 'espadeá',
  espadei: 'espadei',
  espadem: 'espadem',
  espades: 'espades',
  espadia: 'espadia',
  espadie: 'espadie',
  espadim: 'espadim',
  espadio: 'espadio',
  espadou: 'espadou',
  espadua: 'espádua',
  espadue: 'espadue',
  espaduo: 'espaduo',
  espalax: 'espálax',
  espalda: 'espalda',
  espalde: 'espalde',
  espaldo: 'espaldo',
  espaleu: 'espaleu',
  espalha: 'espalha',
  espalhe: 'espalhe',
  espalho: 'espalho',
  espalma: 'espalma',
  espalme: 'espalme',
  espalmo: 'espalmo',
  espalto: 'espalto',
  espanai: 'espanai',
  espanam: 'espanam',
  espanar: 'espanar',
  espanas: 'espanas',
  espanca: 'espanca',
  espanco: 'espanco',
  espanei: 'espanei',
  espanem: 'espanem',
  espanes: 'espanes',
  espanou: 'espanou',
  espanta: 'espanta',
  espante: 'espante',
  espanto: 'espanto',
  espapai: 'espapai',
  espapam: 'espapam',
  espapar: 'espapar',
  espapas: 'espapas',
  espapei: 'espapei',
  espapem: 'espapem',
  espapes: 'espapes',
  espapou: 'espapou',
  esparge: 'esparge',
  espargi: 'espargi',
  espargo: 'espargo',
  esparja: 'esparja',
  esparjo: 'esparjo',
  esparra: 'esparra',
  esparre: 'esparre',
  esparro: 'esparro',
  esparsa: 'esparsa',
  esparse: 'esparse',
  esparso: 'esparso',
  esparto: 'esparto',
  esparva: 'esparva',
  esparve: 'esparve',
  esparvo: 'esparvo',
  esparza: 'esparza',
  esparze: 'esparze',
  esparzi: 'esparzi',
  esparzo: 'esparzo',
  espasma: 'espasma',
  espasme: 'espasme',
  espasmo: 'espasmo',
  espatas: 'espatas',
  espatos: 'espatos',
  espauto: 'espauto',
  especai: 'especai',
  especam: 'especam',
  especar: 'especar',
  especas: 'especas',
  especei: 'especei',
  especem: 'especem',
  especes: 'especes',
  especia: 'espécia',
  especie: 'espécie',
  especio: 'especio',
  especou: 'especou',
  especta: 'especta',
  especte: 'especte',
  especto: 'especto',
  espedem: 'espedem',
  espedes: 'espedes',
  espedia: 'espedia',
  espedir: 'espedir',
  espedis: 'espedis',
  espediu: 'espediu',
  espeita: 'espeita',
  espeite: 'espeite',
  espeito: 'espeito',
  espelde: 'espelde',
  espeleu: 'espeleu',
  espelha: 'espelha',
  espelhe: 'espelhe',
  espelho: 'espelho',
  espelir: 'espelir',
  espelta: 'espelta',
  espenda: 'espenda',
  espeque: 'espeque',
  esperai: 'esperai',
  esperam: 'esperam',
  esperar: 'esperar',
  esperas: 'esperas',
  esperei: 'esperei',
  esperem: 'esperem',
  esperes: 'esperes',
  esperge: 'esperge',
  esperma: 'esperma',
  esperou: 'esperou',
  esperta: 'esperta',
  esperte: 'esperte',
  esperto: 'esperto',
  espessa: 'espessa',
  espesse: 'espesse',
  espesso: 'espesso',
  espetai: 'espetai',
  espetam: 'espetam',
  espetao: 'espetão',
  espetar: 'espetar',
  espetas: 'espetas',
  espetei: 'espetei',
  espetem: 'espetem',
  espetes: 'espetes',
  espetos: 'espetos',
  espetou: 'espetou',
  espetro: 'espetro',
  espiada: 'espiada',
  espiado: 'espiado',
  espiais: 'espiais',
  espiamo: 'espiamo',
  espiara: 'espiara',
  espiava: 'espiava',
  espicas: 'espicas',
  espices: 'espicés',
  espicha: 'espicha',
  espiche: 'espiche',
  espicho: 'espicho',
  espieis: 'espieis',
  espiemo: 'espiemo',
  espifai: 'espifai',
  espifam: 'espifam',
  espifar: 'espifar',
  espifas: 'espifas',
  espifei: 'espifei',
  espifem: 'espifem',
  espifes: 'espifes',
  espifou: 'espifou',
  espigai: 'espigai',
  espigam: 'espigam',
  espigao: 'espigão',
  espigar: 'espigar',
  espigas: 'espigas',
  espigos: 'espigos',
  espigou: 'espigou',
  espigue: 'espigue',
  espilos: 'espilos',
  espilra: 'espilra',
  espilre: 'espilre',
  espilro: 'espilro',
  espinal: 'espinal',
  espinas: 'espinas',
  espinca: 'espinça',
  espince: 'espince',
  espinco: 'espinço',
  espinea: 'espínea',
  espinel: 'espinel',
  espineo: 'espíneo',
  espinha: 'espinha',
  espinhe: 'espinhe',
  espinho: 'espinho',
  espinol: 'espinol',
  espinor: 'espinor',
  espinos: 'espinos',
  espioes: 'espiões',
  espiona: 'espiona',
  espione: 'espione',
  espiono: 'espiono',
  espipai: 'espipai',
  espipam: 'espipam',
  espipar: 'espipar',
  espipas: 'espipas',
  espipei: 'espipei',
  espipem: 'espipem',
  espipes: 'espipes',
  espipou: 'espipou',
  espique: 'espique',
  espirai: 'espirai',
  espiral: 'espiral',
  espiram: 'espiram',
  espirar: 'espirar',
  espiras: 'espiras',
  espirea: 'espírea',
  espirei: 'espirei',
  espirem: 'espirem',
  espires: 'espires',
  espirou: 'espirou',
  espirra: 'espirra',
  espirre: 'espirre',
  espirro: 'espirro',
  espital: 'espital',
  espleno: 'espleno',
  esplins: 'esplins',
  espoada: 'espoada',
  espoado: 'espoado',
  espoais: 'espoais',
  espoamo: 'espoamo',
  espoara: 'espoara',
  espoava: 'espoava',
  espocai: 'espocai',
  espocam: 'espocam',
  espocar: 'espocar',
  espocas: 'espocas',
  espocou: 'espocou',
  espoeis: 'espoeis',
  espoemo: 'espoemo',
  espojai: 'espojai',
  espojam: 'espojam',
  espojar: 'espojar',
  espojas: 'espojas',
  espojei: 'espojei',
  espojem: 'espojem',
  espojes: 'espojes',
  espojou: 'espojou',
  espolas: 'espolas',
  espolia: 'espolia',
  espolie: 'espolie',
  espolim: 'espolim',
  espolio: 'espólio',
  espolpa: 'espolpá',
  espolpe: 'espolpe',
  espolpo: 'espolpo',
  esponda: 'esponda',
  esponja: 'esponja',
  esponje: 'esponje',
  esponjo: 'esponjo',
  esponta: 'esponta',
  esponte: 'esponte',
  esponto: 'esponto',
  espoque: 'espoque',
  esporai: 'esporai',
  esporam: 'esporam',
  esporao: 'esporão',
  esporar: 'esporar',
  esporas: 'esporas',
  esporea: 'esporeá',
  esporei: 'esporei',
  esporem: 'esporem',
  espores: 'espores',
  esporim: 'esporim',
  esporio: 'espório',
  esporoa: 'esporoá',
  esporoe: 'esporoe',
  esporoo: 'esporoo',
  esporos: 'esporos',
  esporou: 'esporou',
  esporra: 'esporra',
  esporre: 'esporre',
  esporro: 'esporro',
  esporta: 'esporta',
  esporte: 'esporte',
  esposai: 'esposai',
  esposam: 'esposam',
  esposar: 'esposar',
  esposas: 'esposas',
  esposei: 'esposei',
  esposem: 'esposem',
  esposes: 'esposes',
  esposos: 'esposos',
  esposou: 'esposou',
  espotai: 'espotai',
  espotam: 'espotam',
  espotar: 'espotar',
  espotas: 'espotas',
  espotei: 'espotei',
  espotem: 'espotem',
  espotes: 'espotes',
  espotou: 'espotou',
  espouca: 'espouca',
  espouco: 'espouco',
  espraia: 'espraia',
  espraie: 'espraie',
  espraio: 'espraio',
  esprema: 'esprema',
  espreme: 'espreme',
  espremi: 'espremi',
  espremo: 'espremo',
  esprens: 'espréns',
  esprita: 'esprita',
  esprite: 'esprite',
  esprito: 'esprito',
  espruce: 'espruce',
  esprues: 'esprues',
  espuais: 'espuais',
  espuiam: 'espuíam',
  espuias: 'espuías',
  espuida: 'espuída',
  espuido: 'espuído',
  espuira: 'espuirá',
  espulga: 'espulga',
  espulgo: 'espulgo',
  espulha: 'espulha',
  espumai: 'espumai',
  espumam: 'espumam',
  espumar: 'espumar',
  espumas: 'espumas',
  espumea: 'espúmea',
  espumei: 'espumei',
  espumem: 'espumem',
  espumeo: 'espúmeo',
  espumes: 'espumes',
  espumou: 'espumou',
  espurco: 'espurco',
  espuria: 'espúria',
  espurio: 'espúrio',
  esputai: 'esputai',
  esputam: 'esputam',
  esputar: 'esputar',
  esputas: 'esputas',
  esputei: 'esputei',
  esputem: 'esputem',
  esputes: 'esputes',
  esputou: 'esputou',
  esqualo: 'esqualo',
  esqueca: 'esqueça',
  esquece: 'esquece',
  esqueci: 'esqueci',
  esqueco: 'esqueço',
  esquefe: 'esquefe',
  esquema: 'esquema',
  esqueno: 'esqueno',
  esquete: 'esquete',
  esquiai: 'esquiai',
  esquiam: 'esquiam',
  esquiar: 'esquiar',
  esquias: 'esquias',
  esquica: 'esquiça',
  esquice: 'esquice',
  esquico: 'esquiço',
  esquiei: 'esquiei',
  esquiem: 'esquiem',
  esquies: 'esquies',
  esquifa: 'esquifa',
  esquife: 'esquife',
  esquifo: 'esquifo',
  esquila: 'esquila',
  esquile: 'esquile',
  esquilo: 'esquilo',
  esquimo: 'esquimó',
  esquina: 'esquina',
  esquine: 'esquine',
  esquino: 'esquino',
  esquiou: 'esquiou',
  esquipa: 'esquipa',
  esquipe: 'esquipe',
  esquipo: 'esquipo',
  esquita: 'esquita',
  esquite: 'esquite',
  esquito: 'esquito',
  esquiva: 'esquiva',
  esquive: 'esquive',
  esquivo: 'esquivo',
  esquixa: 'esquixa',
  esquizo: 'esquizo',
  essedao: 'essedão',
  essedas: 'éssedas',
  essedos: 'éssedos',
  essenio: 'essênio',
  essenos: 'essenos',
  essivas: 'essivas',
  essivos: 'essivos',
  essolva: 'essolva',
  essolve: 'essolve',
  essolvi: 'essolvi',
  essolvo: 'essolvo',
  estacai: 'estacai',
  estacal: 'estacal',
  estacam: 'estacam',
  estacao: 'estação',
  estacar: 'estacar',
  estacas: 'estacas',
  estacei: 'estacei',
  estacem: 'estacem',
  estaces: 'estaces',
  estacoa: 'estacoá',
  estacoe: 'estacoe',
  estacoo: 'estacoo',
  estacou: 'estacou',
  estadao: 'estadão',
  estadas: 'estadas',
  estadea: 'estadeá',
  estadia: 'estadia',
  estadie: 'estadie',
  estadio: 'estádio',
  estador: 'estador',
  estados: 'estados',
  estafai: 'estafai',
  estafam: 'estafam',
  estafar: 'estafar',
  estafas: 'estafas',
  estafei: 'estafei',
  estafem: 'estafem',
  estafes: 'estafes',
  estafim: 'estafim',
  estafou: 'estafou',
  estagia: 'estagia',
  estagie: 'estagie',
  estagio: 'estágio',
  estagna: 'estagna',
  estagne: 'estagne',
  estagno: 'estagno',
  estaiai: 'estaiai',
  estaiam: 'estaiam',
  estaiar: 'estaiar',
  estaias: 'estaias',
  estaiei: 'estaiei',
  estaiem: 'estaiem',
  estaies: 'estaies',
  estaiou: 'estaiou',
  estalai: 'estalai',
  estalam: 'estalam',
  estalao: 'estalão',
  estalar: 'estalar',
  estalas: 'estalas',
  estalei: 'estalei',
  estalem: 'estalem',
  estales: 'estales',
  estalia: 'estalia',
  estalir: 'estalir',
  estalis: 'estalis',
  estaliu: 'estaliu',
  estalos: 'estalos',
  estalou: 'estalou',
  estamao: 'estamão',
  estames: 'estames',
  estamim: 'estamim',
  estamos: 'estamos',
  estampa: 'estampa',
  estampe: 'estampe',
  estampo: 'estampo',
  estanca: 'estanca',
  estanco: 'estanco',
  estanda: 'estânda',
  estande: 'estande',
  estando: 'estando',
  estanga: 'estanga',
  estanha: 'estanha',
  estanhe: 'estanhe',
  estanho: 'estanho',
  estante: 'estante',
  estapea: 'estapeá',
  estapes: 'estapes',
  estapor: 'estapor',
  estaque: 'estaque',
  estarao: 'estarão',
  estaras: 'estarás',
  estarda: 'estarda',
  estarde: 'estarde',
  estardo: 'estardo',
  estarei: 'estarei',
  estarem: 'estarem',
  estares: 'estares',
  estaria: 'estaria',
  estarim: 'estarim',
  estarmo: 'estarmo',
  estarna: 'estarna',
  estases: 'estases',
  estasia: 'estasia',
  estasie: 'estasie',
  estasio: 'estasio',
  estatal: 'estatal',
  estater: 'estáter',
  estator: 'estator',
  estatua: 'estátua',
  estatue: 'estatue',
  estatui: 'estatui',
  estatuo: 'estatuo',
  estavam: 'estavam',
  estavas: 'estavas',
  estavel: 'estável',
  estazai: 'estazai',
  estazam: 'estazam',
  estazar: 'estazar',
  estazas: 'estazas',
  estazei: 'estazei',
  estazem: 'estazem',
  estazes: 'estazes',
  estazou: 'estazou',
  esteada: 'esteada',
  esteado: 'esteado',
  esteais: 'esteais',
  esteara: 'esteará',
  estearo: 'estearo',
  esteato: 'esteato',
  esteava: 'esteava',
  estebal: 'estebal',
  estebas: 'estebas',
  esteeis: 'esteeis',
  estefos: 'estefos',
  esteiam: 'esteiam',
  esteias: 'esteias',
  esteiem: 'esteiem',
  esteies: 'esteies',
  esteios: 'esteios',
  esteira: 'esteira',
  esteire: 'esteire',
  esteiro: 'esteiro',
  estejam: 'estejam',
  estejas: 'estejas',
  estelar: 'estelar',
  estelas: 'estelas',
  estelio: 'estélio',
  estelis: 'estélis',
  estemas: 'estemas',
  estenda: 'estenda',
  estende: 'estende',
  estendi: 'estendi',
  estendo: 'estendo',
  estenia: 'estenia',
  estenos: 'estenos',
  estepes: 'estepes',
  esterai: 'esterai',
  esteram: 'esteram',
  esterar: 'esterar',
  esteras: 'esteras',
  esterca: 'esterca',
  esterce: 'esterce',
  esterco: 'esterco',
  esterea: 'estérea',
  esterei: 'esterei',
  esterem: 'esterem',
  estereo: 'estéreo',
  esteres: 'esteres',
  esteria: 'estéria',
  esteril: 'estéril',
  esterna: 'esterna',
  esterno: 'esterno',
  esterol: 'esterol',
  esteros: 'esteros',
  esterou: 'esterou',
  esterra: 'esterra',
  esterre: 'esterre',
  esterro: 'esterro',
  esteses: 'esteses',
  estesia: 'estesia',
  estesie: 'estesie',
  estesio: 'estesio',
  estesos: 'estesos',
  estetas: 'estetas',
  estetos: 'estetos',
  estevai: 'estevai',
  esteval: 'esteval',
  estevam: 'estevam',
  estevao: 'estêvão',
  estevar: 'estevar',
  estevas: 'estevas',
  estevei: 'estevei',
  estevem: 'estevem',
  esteves: 'esteves',
  estevia: 'estévia',
  estevis: 'estévis',
  estevou: 'estevou',
  estiada: 'estiada',
  estiado: 'estiado',
  estiais: 'estiais',
  estiamo: 'estiamo',
  estiara: 'estiara',
  estiava: 'estiava',
  estibal: 'estibal',
  estibas: 'estibas',
  estibio: 'estíbio',
  esticai: 'esticai',
  esticam: 'esticam',
  esticao: 'esticão',
  esticar: 'esticar',
  esticas: 'esticas',
  esticou: 'esticou',
  esticta: 'esticta',
  esticte: 'esticte',
  estieis: 'estieis',
  estiela: 'estiela',
  estiemo: 'estiemo',
  estifas: 'estifas',
  estiflo: 'estiflo',
  estigia: 'estígia',
  estigio: 'estígio',
  estigma: 'estigma',
  estigme: 'estigme',
  estigmo: 'estigmo',
  estigno: 'estigno',
  estilai: 'estilai',
  estilam: 'estilam',
  estilar: 'estilar',
  estilas: 'estilas',
  estilbe: 'estilbe',
  estilbo: 'estilbo',
  estilei: 'estilei',
  estilem: 'estilem',
  estiles: 'estiles',
  estilha: 'estilha',
  estilhe: 'estilhe',
  estilho: 'estilho',
  estilos: 'estilos',
  estilou: 'estilou',
  estimai: 'estimai',
  estimam: 'estimam',
  estimar: 'estimar',
  estimas: 'estimas',
  estimei: 'estimei',
  estimem: 'estimem',
  estimes: 'estimes',
  estimou: 'estimou',
  estinga: 'estinga',
  estingo: 'estingo',
  estinha: 'estinha',
  estinhe: 'estinhe',
  estinho: 'estinho',
  estiola: 'estiola',
  estiole: 'estiole',
  estiolo: 'estiolo',
  estipas: 'estipas',
  estipes: 'estipes',
  estique: 'estique',
  estirai: 'estirai',
  estiram: 'estiram',
  estirao: 'estirão',
  estirar: 'estirar',
  estiras: 'estiras',
  estirax: 'estírax',
  estirei: 'estirei',
  estirem: 'estirem',
  estires: 'estires',
  estirio: 'estírio',
  estirol: 'estirol',
  estirou: 'estirou',
  estirpe: 'estirpe',
  estivai: 'estivai',
  estival: 'estival',
  estivam: 'estivam',
  estivar: 'estivar',
  estivas: 'estivas',
  estivei: 'estivei',
  estivem: 'estivem',
  estiver: 'estiver',
  estives: 'estives',
  estivou: 'estivou',
  estocai: 'estocai',
  estocam: 'estocam',
  estocar: 'estocar',
  estocas: 'estocas',
  estocei: 'estocei',
  estocem: 'estocem',
  estoces: 'estoces',
  estocou: 'estocou',
  estofai: 'estofai',
  estofam: 'estofam',
  estofar: 'estofar',
  estofas: 'estofas',
  estofei: 'estofei',
  estofem: 'estofem',
  estofes: 'estofes',
  estofos: 'estofos',
  estofou: 'estofou',
  estoica: 'estoica',
  estoico: 'estóico',
  estoira: 'estoira',
  estoire: 'estoire',
  estoiro: 'estoiro',
  estojai: 'estojai',
  estojam: 'estojam',
  estojar: 'estojar',
  estojas: 'estojas',
  estojei: 'estojei',
  estojem: 'estojem',
  estojes: 'estojes',
  estojos: 'estojos',
  estojou: 'estojou',
  estolai: 'estolai',
  estolam: 'estolam',
  estolao: 'estolão',
  estolar: 'estolar',
  estolas: 'estolas',
  estolei: 'estolei',
  estolem: 'estolem',
  estoles: 'estoles',
  estolho: 'estolho',
  estolon: 'estólon',
  estolou: 'estolou',
  estomas: 'estomas',
  estomia: 'estômia',
  estomis: 'estômis',
  estonai: 'estonai',
  estonam: 'estonam',
  estonar: 'estonar',
  estonas: 'estonas',
  estonce: 'estonce',
  estonei: 'estonei',
  estonem: 'estonem',
  estones: 'estones',
  estonha: 'estonha',
  estonhe: 'estonhe',
  estonho: 'estonho',
  estonio: 'estônio',
  estonou: 'estonou',
  estonta: 'estonta',
  estonte: 'estonte',
  estonto: 'estonto',
  estopai: 'estopai',
  estopam: 'estopam',
  estopao: 'estopão',
  estopar: 'estopar',
  estopas: 'estopas',
  estopei: 'estopei',
  estopem: 'estopem',
  estopes: 'estopes',
  estopim: 'estopim',
  estopou: 'estopou',
  estoque: 'estoque',
  estorca: 'estorça',
  estorce: 'estorce',
  estorci: 'estorci',
  estorco: 'estorço',
  estores: 'estores',
  estorga: 'estorga',
  estoria: 'estória',
  estorie: 'estorie',
  estorio: 'estorio',
  estormo: 'estormo',
  estorna: 'estorna',
  estorne: 'estorne',
  estorno: 'estorno',
  estorva: 'estorva',
  estorve: 'estorve',
  estorvo: 'estorvo',
  estoura: 'estoura',
  estoure: 'estoure',
  estouro: 'estouro',
  estouva: 'estouva',
  estouve: 'estouve',
  estouvo: 'estouvo',
  estovai: 'estovai',
  estovam: 'estovam',
  estovar: 'estovar',
  estovas: 'estovas',
  estovei: 'estovei',
  estovem: 'estovem',
  estoves: 'estoves',
  estovou: 'estovou',
  estraba: 'estraba',
  estrabe: 'estrabe',
  estrabo: 'estrabo',
  estrada: 'estrada',
  estrade: 'estrade',
  estrado: 'estrado',
  estraga: 'estraga',
  estrago: 'estrago',
  estrais: 'estrais',
  estrala: 'estrala',
  estrale: 'estrale',
  estralo: 'estralo',
  estrame: 'estrame',
  estramo: 'estramo',
  estrara: 'estrara',
  estrato: 'estrato',
  estrava: 'estrava',
  estrave: 'estrave',
  estravo: 'estravo',
  estreai: 'estreai',
  estrear: 'estrear',
  estreca: 'estreça',
  estrece: 'estrece',
  estreci: 'estreci',
  estreco: 'estreço',
  estreei: 'estreei',
  estrega: 'estrega',
  estrego: 'estrego',
  estreia: 'estreia',
  estreie: 'estreie',
  estreio: 'estreio',
  estreis: 'estreis',
  estrela: 'estrela',
  estrele: 'estrele',
  estreli: 'estreli',
  estrelo: 'estrelo',
  estrema: 'estrema',
  estreme: 'estreme',
  estremi: 'estremi',
  estremo: 'estremo',
  estrens: 'estréns',
  estreou: 'estreou',
  estrepa: 'estrepa',
  estrepe: 'estrepe',
  estrepo: 'estrepo',
  estresa: 'estresa',
  estrese: 'estrese',
  estresi: 'estresi',
  estreso: 'estreso',
  estreto: 'estreto',
  estriai: 'estriai',
  estriam: 'estriam',
  estriar: 'estriar',
  estrias: 'estrias',
  estriba: 'estriba',
  estribe: 'estribe',
  estribo: 'estribo',
  estrica: 'estriça',
  estrice: 'estrice',
  estrico: 'estriço',
  estriei: 'estriei',
  estriem: 'estriem',
  estries: 'estries',
  estriga: 'estriga',
  estrige: 'estrige',
  estrigi: 'estrigi',
  estrigo: 'estrigo',
  estrija: 'estrija',
  estrijo: 'estrijo',
  estrila: 'estrila',
  estrile: 'estrile',
  estrilo: 'estrilo',
  estrima: 'estrimá',
  estrime: 'estrime',
  estrimo: 'estrimo',
  estrina: 'estrina',
  estriol: 'estriol',
  estriou: 'estriou',
  estripa: 'estripa',
  estripe: 'estripe',
  estripo: 'estripo',
  estrita: 'estrita',
  estrito: 'estrito',
  estroca: 'estroça',
  estroce: 'estroce',
  estroco: 'estroço',
  estroem: 'estroem',
  estrofe: 'estrofe',
  estrois: 'estróis',
  estroma: 'estroma',
  estrona: 'estrona',
  estrope: 'estrope',
  estropo: 'estropo',
  estrosa: 'estrosa',
  estroso: 'estroso',
  estrovo: 'estrovo',
  estruam: 'estruam',
  estruas: 'estruas',
  estruem: 'estruem',
  estruge: 'estruge',
  estrugi: 'estrugi',
  estruia: 'estruía',
  estruir: 'estruir',
  estruis: 'estruís',
  estruiu: 'estruiu',
  estruja: 'estruja',
  estrujo: 'estrujo',
  estruma: 'estruma',
  estrume: 'estrume',
  estrumo: 'estrumo',
  estrupa: 'estrupa',
  estrupi: 'estrupi',
  estrupo: 'estrupo',
  estuada: 'estuada',
  estuado: 'estuado',
  estuais: 'estuais',
  estuamo: 'estuamo',
  estuara: 'estuará',
  estuava: 'estuava',
  estubes: 'estubes',
  estucai: 'estucai',
  estucam: 'estucam',
  estucar: 'estucar',
  estucas: 'estucas',
  estucha: 'estucha',
  estuche: 'estuche',
  estucho: 'estucho',
  estucou: 'estucou',
  estudai: 'estudai',
  estudam: 'estudam',
  estudar: 'estudar',
  estudas: 'estudas',
  estudei: 'estudei',
  estudem: 'estudem',
  estudes: 'estudes',
  estudio: 'estúdio',
  estudos: 'estudos',
  estudou: 'estudou',
  estueis: 'estueis',
  estuemo: 'estuemo',
  estufai: 'estufai',
  estufam: 'estufam',
  estufar: 'estufar',
  estufas: 'estufas',
  estufei: 'estufei',
  estufem: 'estufem',
  estufes: 'estufes',
  estufim: 'estufim',
  estufou: 'estufou',
  estugai: 'estugai',
  estugam: 'estugam',
  estugar: 'estugar',
  estugas: 'estugas',
  estugou: 'estugou',
  estugue: 'estugue',
  estulta: 'estulta',
  estulto: 'estulto',
  estumai: 'estumai',
  estumam: 'estumam',
  estumar: 'estumar',
  estumas: 'estumas',
  estumei: 'estumei',
  estumem: 'estumem',
  estumes: 'estumes',
  estumou: 'estumou',
  estuosa: 'estuosa',
  estuoso: 'estuoso',
  estupas: 'estupas',
  estupor: 'estupor',
  estupra: 'estupra',
  estupre: 'estupre',
  estupro: 'estupro',
  estuque: 'estuque',
  esturao: 'esturão',
  esturno: 'esturno',
  esturra: 'esturra',
  esturre: 'esturre',
  esturro: 'esturro',
  esurina: 'esurina',
  esurino: 'esurino',
  esvaeca: 'esvaeça',
  esvaece: 'esvaece',
  esvaeci: 'esvaeci',
  esvaeco: 'esvaeço',
  esvaiam: 'esvaiam',
  esvaias: 'esvaias',
  esvaida: 'esvaída',
  esvaido: 'esvaído',
  esvaimo: 'esvaímo',
  esvaira: 'esvaíra',
  esvalia: 'esvaliá',
  esvalie: 'esvalie',
  esvalio: 'esvalio',
  esvarai: 'esvarai',
  esvaram: 'esvaram',
  esvarar: 'esvarar',
  esvaras: 'esvaras',
  esvarei: 'esvarei',
  esvarem: 'esvarem',
  esvares: 'esvares',
  esvarou: 'esvarou',
  esvarra: 'esvarra',
  esvarre: 'esvarre',
  esvarri: 'esvarri',
  esvarro: 'esvarro',
  esvazai: 'esvazai',
  esvazam: 'esvazam',
  esvazar: 'esvazar',
  esvazas: 'esvazas',
  esvazei: 'esvazei',
  esvazem: 'esvazem',
  esvazes: 'esvazes',
  esvazia: 'esvazia',
  esvazie: 'esvazie',
  esvazio: 'esvazio',
  esvazou: 'esvazou',
  esvelto: 'esvelto',
  esventa: 'esventa',
  esvente: 'esvente',
  esvento: 'esvento',
  esverca: 'esverçá',
  esverce: 'esverce',
  esverco: 'esverço',
  esvidai: 'esvidai',
  esvidam: 'esvidam',
  esvidar: 'esvidar',
  esvidas: 'esvidas',
  esvidei: 'esvidei',
  esvidem: 'esvidem',
  esvides: 'esvides',
  esvidou: 'esvidou',
  esvinha: 'esvinhá',
  esvinhe: 'esvinhe',
  esvinho: 'esvinho',
  esvoaca: 'esvoaça',
  esvoace: 'esvoace',
  esvoaco: 'esvoaço',
  esvoeja: 'esvoeja',
  esvoeje: 'esvoeje',
  esvoejo: 'esvoejo',
  esvurma: 'esvurma',
  esvurme: 'esvurme',
  esvurmo: 'esvurmo',
  etagere: 'etagere',
  etalica: 'etálica',
  etalico: 'etálico',
  etalzao: 'etalzão',
  etambos: 'etambos',
  etamina: 'etamina',
  etamine: 'étamine',
  etanais: 'etanais',
  etandas: 'etandas',
  etanica: 'etânica',
  etanico: 'etânico',
  etanios: 'etânios',
  etanois: 'etanóis',
  etaozao: 'etãozão',
  etarias: 'etárias',
  etarios: 'etários',
  etarras: 'etarras',
  etatica: 'etática',
  etatico: 'etático',
  etazona: 'etãzona',
  eteidea: 'eteídea',
  eteideo: 'eteídeo',
  etenilo: 'etenilo',
  etenois: 'etenóis',
  eterais: 'eterais',
  eterato: 'etérato',
  etereal: 'etereal',
  etereas: 'etéreas',
  etereos: 'etéreos',
  eterica: 'etérica',
  eterico: 'etérico',
  eterilo: 'eterilo',
  eterina: 'eterina',
  eteriza: 'eteriza',
  eterize: 'eterize',
  eterizo: 'eterizo',
  eternai: 'eternai',
  eternal: 'eternal',
  eternam: 'eternam',
  eternar: 'eternar',
  eternas: 'eternas',
  eternei: 'eternei',
  eternem: 'eternem',
  eternes: 'eternes',
  eternos: 'eternos',
  eternou: 'eternou',
  eterois: 'eteróis',
  eterona: 'eterona',
  etesias: 'etésias',
  etesios: 'etésios',
  eteuete: 'eteuete',
  eticiza: 'eticiza',
  eticize: 'eticize',
  eticizo: 'eticizo',
  etiessa: 'etiessa',
  etilada: 'etilada',
  etilado: 'etilado',
  etilais: 'etilais',
  etilamo: 'etilamo',
  etilara: 'etilará',
  etilato: 'etilato',
  etilava: 'etilava',
  etileis: 'etileis',
  etilemo: 'etilemo',
  etilena: 'etilena',
  etileno: 'etileno',
  etilica: 'etílica',
  etilico: 'etílico',
  etilina: 'etilina',
  etilios: 'etílios',
  etiliza: 'etiliza',
  etilize: 'etilize',
  etilizo: 'etilizo',
  etiopes: 'etiopês',
  etiopio: 'etiópio',
  etmoide: 'etmoide',
  etnarca: 'etnarca',
  etnicas: 'étnicas',
  etnicos: 'étnicos',
  etolias: 'etólias',
  etolico: 'etólico',
  etolios: 'etólios',
  etologa: 'etóloga',
  etologo: 'etólogo',
  etomias: 'etomias',
  etonans: 'etonans',
  etopeia: 'etopeia',
  etopeus: 'etopeus',
  etoxida: 'etóxida',
  etoxido: 'etóxido',
  etoxila: 'etoxila',
  etoxilo: 'etoxilo',
  etoxina: 'etoxina',
  etroplo: 'étroplo',
  etrusca: 'etrusca',
  etrusco: 'etrusco',
  euanemo: 'euanemo',
  euarcos: 'euarcos',
  euarcto: 'euarcto',
  euascal: 'euascal',
  euastro: 'euastro',
  euauacu: 'euauaçu',
  eubages: 'eubages',
  eubazos: 'eubazos',
  eubeias: 'eubeias',
  eubenas: 'eubenas',
  eubiose: 'eubiose',
  eublema: 'eublema',
  euboica: 'euboica',
  euboico: 'euboico',
  eubolia: 'eubolia',
  eubrias: 'eubrias',
  eucaina: 'eucaína',
  eucanto: 'eucanto',
  eucares: 'êucares',
  eucatas: 'eucatas',
  euceras: 'êuceras',
  euceros: 'êuceros',
  euclase: 'êuclase',
  euclena: 'euclena',
  eucnema: 'eucnema',
  eucodal: 'eucodal',
  eucoila: 'eucoíla',
  eucomes: 'êucomes',
  eucomia: 'eucômia',
  eucopia: 'eucópia',
  eucrita: 'eucrita',
  eucrite: 'eucrite',
  eucrito: 'eucrito',
  eucroma: 'eucroma',
  eucromo: 'eucromo',
  eudemas: 'eudemas',
  euderma: 'euderma',
  eudipsa: 'eudipsa',
  eudista: 'eudista',
  eudoses: 'eudoses',
  eudosia: 'eudósia',
  eudrilo: 'eudrilo',
  euedral: 'euedral',
  euedros: 'euedros',
  euemias: 'euemias',
  euexias: 'euexias',
  eufania: 'eufânia',
  eufemia: 'eufemia',
  eufilia: 'eufilia',
  eufonia: 'eufonia',
  eufonio: 'eufônio',
  eufonos: 'eufonos',
  euforia: 'euforia',
  euganea: 'eugânea',
  euganeo: 'eugâneo',
  eugenia: 'eugênia',
  eugenol: 'eugenol',
  eugeria: 'eugeria',
  eugiras: 'eugiras',
  euglena: 'euglena',
  eugleno: 'eugleno',
  euglifa: 'êuglifa',
  eugrafo: 'êugrafo',
  euipnia: 'euipnia',
  eulalia: 'eulália',
  eulemas: 'eulemas',
  eulemos: 'eulemos',
  eulepia: 'eulépia',
  eulepto: 'eulepto',
  eulimas: 'eulimas',
  eulimos: 'eulimos',
  eulinea: 'eulínea',
  eulitas: 'eulitas',
  eulobio: 'eulóbio',
  eulofia: 'eulófia',
  eulofio: 'eulófio',
  eulofos: 'êulofos',
  eulogia: 'eulógia',
  euloxia: 'eulóxia',
  eumatia: 'eumatia',
  eumenes: 'êumenes',
  eumenos: 'êumenos',
  eumeque: 'eumeque',
  eumeros: 'eumeros',
  eumicro: 'eumicro',
  eumidas: 'eumidas',
  eumolpo: 'eumolpo',
  eumorfo: 'eumorfo',
  eunecte: 'eunecte',
  eunecto: 'eunecto',
  euneias: 'euneias',
  eunocia: 'eunócia',
  eunomia: 'eunômia',
  eunotia: 'eunótia',
  eunucos: 'eunucos',
  euofris: 'euófris',
  eupatia: 'eupatia',
  eupelix: 'êupelix',
  eupelte: 'eupelte',
  eupiona: 'eupiona',
  eupista: 'eupista',
  euplero: 'euplero',
  euplote: 'euplote',
  eupneia: 'eupneia',
  eupodes: 'êupodes',
  eupodio: 'eupódio',
  eupolia: 'eupolia',
  eupompo: 'eupompo',
  euquelo: 'euquelo',
  euquimo: 'euquimo',
  euquira: 'euquira',
  euquita: 'euquita',
  eurasio: 'eurásio',
  eurecas: 'eurecas',
  euremas: 'euremas',
  euresol: 'euresol',
  euretes: 'euretes',
  euriais: 'euriais',
  euriale: 'euriale',
  eurinas: 'êurinas',
  eurinos: 'êurinos',
  euriope: 'euriope',
  euripos: 'euripos',
  eurites: 'eurites',
  euriusa: 'euriúsa',
  eurodol: 'eurodol',
  europeu: 'europeu',
  europio: 'európio',
  eurreta: 'eurreta',
  euscara: 'euscara',
  euscaro: 'euscaro',
  euscelo: 'êuscelo',
  eusemia: 'eusemia',
  eusiros: 'eusiros',
  eusomos: 'eusomos',
  euspora: 'êuspora',
  eussomo: 'eussomo',
  eustelo: 'eustelo',
  eustilo: 'eustilo',
  eutaxia: 'eutaxia',
  euteria: 'eutéria',
  euterio: 'eutério',
  euterpe: 'euterpe',
  eutesia: 'eutesia',
  eutexia: 'eutexia',
  eutimia: 'eutimia',
  eutocas: 'êutocas',
  eutocia: 'eutocia',
  eutocos: 'êutocos',
  eutrica: 'êutrica',
  eutrico: 'êutrico',
  euvites: 'euvites',
  euxaria: 'euxária',
  euxario: 'euxário',
  euxenia: 'euxênia',
  euxonia: 'euxônia',
  euzimas: 'euzimas',
  euzimos: 'euzimos',
  euzinha: 'euzinha',
  euzinho: 'euzinho',
  evactis: 'eváctis',
  evacuai: 'evacuai',
  evacuam: 'evacuam',
  evacuar: 'evacuar',
  evacuas: 'evacuas',
  evacuei: 'evacuei',
  evacuem: 'evacuem',
  evacues: 'evacues',
  evacuou: 'evacuou',
  evadais: 'evadais',
  evadamo: 'evadamo',
  evademo: 'evademo',
  evadiam: 'evadiam',
  evadias: 'evadias',
  evadida: 'evadida',
  evadido: 'evadido',
  evadimo: 'evadimo',
  evadira: 'evadira',
  evadnos: 'evadnos',
  evadoes: 'evadões',
  evadona: 'evadona',
  evadora: 'evadora',
  evaente: 'evaente',
  evagina: 'evagina',
  evagine: 'evagine',
  evagino: 'evagino',
  evalves: 'evalves',
  evalvos: 'evalvos',
  evalzao: 'evalzão',
  evanias: 'evânias',
  evantes: 'evantes',
  evapora: 'evapora',
  evapore: 'evapore',
  evaporo: 'evaporo',
  evardes: 'evardes',
  evareis: 'eváreis',
  evaremo: 'evaremo',
  evariam: 'evariam',
  evarias: 'evarias',
  evarmos: 'evarmos',
  evasina: 'evasina',
  evasiva: 'evasiva',
  evasivo: 'evasivo',
  evasoes: 'evasões',
  evasona: 'evasona',
  evassem: 'evassem',
  evasses: 'evasses',
  evastes: 'evastes',
  evaveis: 'eváveis',
  evazada: 'evazada',
  evazado: 'evazado',
  evazais: 'evazais',
  evazamo: 'evazamo',
  evazara: 'evazará',
  evazava: 'evazava',
  evazeis: 'evazeis',
  evazemo: 'evazemo',
  eveccao: 'evecção',
  eveente: 'eveente',
  eveitas: 'eveítas',
  evelina: 'evelina',
  evemias: 'evemias',
  evemica: 'evêmica',
  evemico: 'evêmico',
  evencam: 'evençam',
  evencas: 'evenças',
  evencei: 'evencei',
  evencem: 'evencem',
  evencer: 'evencer',
  evences: 'evences',
  evenceu: 'evenceu',
  evencia: 'evencia',
  evenino: 'evenino',
  evenque: 'evenque',
  eventos: 'eventos',
  eventra: 'eventra',
  eventre: 'eventre',
  eventro: 'eventro',
  evernia: 'evérnia',
  eversao: 'eversão',
  eversor: 'eversor',
  eversos: 'eversos',
  evertem: 'evertém',
  everter: 'everter',
  evexias: 'evexias',
  eviccao: 'evicção',
  evictas: 'evictas',
  evictio: 'evíctio',
  evictor: 'evictor',
  evictos: 'evictos',
  evimbis: 'evímbis',
  evipino: 'evipino',
  evirada: 'evirada',
  evirado: 'evirado',
  evirais: 'evirais',
  eviramo: 'eviramo',
  evirara: 'evirará',
  evirava: 'evirava',
  evireis: 'evireis',
  eviremo: 'eviremo',
  evitada: 'evitada',
  evitado: 'evitado',
  evitais: 'evitais',
  evitamo: 'evitamo',
  evitara: 'evitara',
  evitava: 'evitava',
  eviteis: 'eviteis',
  evitemo: 'evitemo',
  evocada: 'evocada',
  evocado: 'evocado',
  evocais: 'evocais',
  evocamo: 'evocamo',
  evocara: 'evocara',
  evocava: 'evocava',
  evodias: 'evódias',
  evodina: 'evodina',
  evolada: 'evolada',
  evolado: 'evolado',
  evolais: 'evolais',
  evolamo: 'evolamo',
  evolara: 'evolara',
  evolava: 'evolava',
  evoleis: 'evoleis',
  evolemo: 'evolemo',
  evoluam: 'evoluam',
  evoluas: 'evoluas',
  evoluem: 'evoluem',
  evoluia: 'evoluía',
  evoluir: 'evoluir',
  evoluis: 'evoluis',
  evoluiu: 'evoluiu',
  evoluta: 'evoluta',
  evoluto: 'evoluto',
  evolvam: 'evolvam',
  evolvas: 'evolvas',
  evolvei: 'evolvei',
  evolvem: 'evolvem',
  evolver: 'evolver',
  evolves: 'evolves',
  evolveu: 'evolveu',
  evolvia: 'evolvia',
  evonimo: 'evônimo',
  evoquei: 'evoquei',
  evoquem: 'evoquem',
  evoques: 'evoques',
  evulsao: 'evulsão',
  exabela: 'exabela',
  exabyte: 'exabyte',
  exacina: 'exacina',
  exacoes: 'exações',
  exacona: 'exaçona',
  exagera: 'exagera',
  exagere: 'exagere',
  exagero: 'exagero',
  exagita: 'exagita',
  exagite: 'exagite',
  exagito: 'exagito',
  exalada: 'exalada',
  exalado: 'exalado',
  exalais: 'exalais',
  exalamo: 'exalamo',
  exalara: 'exalara',
  exalava: 'exalava',
  exalcai: 'exalçai',
  exalcam: 'exalçam',
  exalcar: 'exalçar',
  exalcas: 'exalças',
  exalcei: 'exalcei',
  exalcem: 'exalcem',
  exalces: 'exalces',
  exalcou: 'exalçou',
  exaleis: 'exaleis',
  exalemo: 'exalemo',
  exaltai: 'exaltai',
  exaltam: 'exaltam',
  exaltar: 'exaltar',
  exaltas: 'exaltas',
  exaltei: 'exaltei',
  exaltem: 'exaltem',
  exaltes: 'exaltes',
  exaltou: 'exaltou',
  examina: 'examina',
  examine: 'examine',
  examino: 'examino',
  examito: 'exâmito',
  exangue: 'exangue',
  exanias: 'exanias',
  exanime: 'exânime',
  exapate: 'exápate',
  exarada: 'exarada',
  exarado: 'exarado',
  exarais: 'exarais',
  exaramo: 'exaramo',
  exarara: 'exarara',
  exarava: 'exarava',
  exarcas: 'exarcas',
  exarcos: 'exarcos',
  exareis: 'exareis',
  exaremo: 'exaremo',
  exarmas: 'exarmas',
  exascea: 'exáscea',
  exasceo: 'exásceo',
  exatora: 'exatora',
  exauram: 'exauram',
  exauras: 'exauras',
  exaurem: 'exaurem',
  exaures: 'exaures',
  exauria: 'exauria',
  exaurir: 'exaurir',
  exauris: 'exauris',
  exauriu: 'exauriu',
  exausta: 'exausta',
  exauste: 'exauste',
  exausto: 'exausto',
  excecao: 'exceção',
  excedam: 'excedam',
  excedas: 'excedas',
  excedei: 'excedei',
  excedem: 'excedem',
  exceder: 'exceder',
  excedes: 'excedes',
  excedeu: 'excedeu',
  excedia: 'excedia',
  excelam: 'excelam',
  excelas: 'excelas',
  excelei: 'excelei',
  excelem: 'excelem',
  exceler: 'exceler',
  exceles: 'exceles',
  exceleu: 'exceleu',
  excelia: 'excelia',
  excelir: 'excelir',
  excelis: 'excelis',
  exceliu: 'exceliu',
  excelsa: 'excelsa',
  excelse: 'excelse',
  excelso: 'excelso',
  excepto: 'excepto',
  excerto: 'excerto',
  excesso: 'excesso',
  excetor: 'excetor',
  excetra: 'êxcetra',
  excetua: 'excetua',
  excetue: 'excetue',
  excetuo: 'excetuo',
  excidio: 'excídio',
  excilam: 'excilam',
  excilas: 'excilas',
  excisai: 'excisai',
  excisam: 'excisam',
  excisao: 'excisão',
  excisar: 'excisar',
  excisas: 'excisas',
  excisei: 'excisei',
  excisem: 'excisem',
  excises: 'excises',
  excisou: 'excisou',
  excitai: 'excitai',
  excitam: 'excitam',
  excitar: 'excitar',
  excitas: 'excitas',
  excitei: 'excitei',
  excitem: 'excitem',
  excites: 'excites',
  exciton: 'éxciton',
  excitou: 'excitou',
  exclama: 'exclama',
  exclame: 'exclame',
  exclamo: 'exclamo',
  excluam: 'excluam',
  excluas: 'excluas',
  excluem: 'excluem',
  excluia: 'excluía',
  excluir: 'excluir',
  excluis: 'excluis',
  excluiu: 'excluiu',
  excluso: 'excluso',
  excorco: 'excorco',
  excreta: 'excreta',
  excrete: 'excrete',
  excreto: 'excreto',
  exculpa: 'exculpa',
  exculpe: 'exculpe',
  exculpo: 'exculpo',
  excursa: 'excursa',
  excurse: 'excurse',
  excurso: 'excurso',
  excurva: 'excurvá',
  excurve: 'excurve',
  excurvo: 'excurvo',
  excusai: 'excusai',
  excusam: 'excusam',
  excusar: 'excusar',
  excusas: 'excusas',
  excusei: 'excusei',
  excusem: 'excusem',
  excuses: 'excuses',
  excusou: 'excusou',
  excutir: 'excutir',
  execrai: 'execrai',
  execram: 'execram',
  execrar: 'execrar',
  execras: 'execras',
  execrei: 'execrei',
  execrem: 'execrem',
  execres: 'execres',
  execrou: 'execrou',
  executa: 'executa',
  execute: 'execute',
  executo: 'executo',
  exedras: 'êxedras',
  exegese: 'exegese',
  exegeta: 'exegeta',
  exempla: 'exempla',
  exemple: 'exemple',
  exempli: 'exempli',
  exemplo: 'exemplo',
  exempro: 'exempro',
  exempta: 'exempta',
  exempte: 'exempte',
  exempto: 'exempto',
  exerase: 'exerase',
  exercam: 'exerçam',
  exercao: 'exerção',
  exercas: 'exerças',
  exercei: 'exercei',
  exercem: 'exercem',
  exercer: 'exercer',
  exerces: 'exerces',
  exerceu: 'exerceu',
  exercia: 'exercia',
  exerdai: 'exerdai',
  exerdam: 'exerdam',
  exerdar: 'exerdar',
  exerdas: 'exerdas',
  exerdei: 'exerdei',
  exerdem: 'exerdem',
  exerdes: 'exerdes',
  exerdou: 'exerdou',
  exerese: 'exérese',
  exergia: 'exergia',
  exergos: 'exergos',
  exgrega: 'exgregá',
  exgrego: 'exgrego',
  exhalar: 'exhalar',
  exhibir: 'exhibir',
  exhumar: 'exhumar',
  exibais: 'exibais',
  exibamo: 'exibamo',
  exibemo: 'exibemo',
  exibiam: 'exibiam',
  exibias: 'exibias',
  exibida: 'exibida',
  exibido: 'exibido',
  exibimo: 'exibimo',
  exibira: 'exibira',
  exicial: 'exicial',
  exiciar: 'exiciar',
  exicios: 'exícios',
  exidias: 'exídias',
  exifona: 'exifona',
  exigiam: 'exigiam',
  exigias: 'exigias',
  exigida: 'exigida',
  exigido: 'exigido',
  exigimo: 'exigimo',
  exigira: 'exigira',
  exiguas: 'exíguas',
  exiguos: 'exíguos',
  exijais: 'exijais',
  exijamo: 'exijamo',
  exilada: 'exilada',
  exilado: 'exilado',
  exilais: 'exilais',
  exilamo: 'exilamo',
  exilara: 'exilara',
  exilava: 'exilava',
  exileis: 'exileis',
  exilemo: 'exilemo',
  exiliar: 'exiliar',
  exilias: 'exílias',
  exilica: 'exílica',
  exilico: 'exílico',
  exilios: 'exílios',
  exilzao: 'exilzão',
  eximais: 'eximais',
  eximamo: 'eximamo',
  eximemo: 'eximemo',
  eximiam: 'eximiam',
  eximias: 'eximias',
  eximida: 'eximida',
  eximido: 'eximido',
  eximimo: 'eximimo',
  eximios: 'exímios',
  eximira: 'eximira',
  exinana: 'exinana',
  exinane: 'exinane',
  exinani: 'exinani',
  exinano: 'exinano',
  exingam: 'exingam',
  exingas: 'exingas',
  exingua: 'exingua',
  exingue: 'exingue',
  exingui: 'exingui',
  exinguo: 'exinguo',
  existam: 'existam',
  existas: 'existas',
  existem: 'existem',
  existes: 'existes',
  existia: 'existia',
  existir: 'existir',
  existis: 'existis',
  existiu: 'existiu',
  exitada: 'exitada',
  exitado: 'exitado',
  exitais: 'exitais',
  exitamo: 'exitamo',
  exitara: 'exitará',
  exitava: 'exitava',
  exiteis: 'exiteis',
  exitemo: 'exitemo',
  exitica: 'exítica',
  exitico: 'exítico',
  exitosa: 'exitosa',
  exitoso: 'exitoso',
  exoasco: 'exoasco',
  exocade: 'exocade',
  exoceto: 'exoceto',
  exocomo: 'exócomo',
  exodica: 'exódica',
  exodico: 'exódico',
  exodios: 'exódios',
  exofaga: 'exófaga',
  exofago: 'exófago',
  exogamo: 'exógamo',
  exogena: 'exógena',
  exogeno: 'exógeno',
  exogino: 'exógino',
  exogira: 'exogira',
  exogiro: 'exogiro',
  exogono: 'exógono',
  exolobo: 'exólobo',
  exomata: 'exômata',
  exomeno: 'exômeno',
  exomide: 'exômide',
  exonera: 'exonera',
  exonere: 'exonere',
  exonero: 'exonero',
  exonima: 'exônima',
  exonimo: 'exônimo',
  exopode: 'exópode',
  exorada: 'exorada',
  exorado: 'exorado',
  exorais: 'exorais',
  exoramo: 'exoramo',
  exorara: 'exorara',
  exorava: 'exorava',
  exorcas: 'exorcas',
  exordia: 'exordia',
  exordie: 'exordie',
  exordio: 'exórdio',
  exoreis: 'exoreis',
  exoremo: 'exoremo',
  exormia: 'exormia',
  exornai: 'exornai',
  exornam: 'exornam',
  exornar: 'exornar',
  exornas: 'exornas',
  exornei: 'exornei',
  exornem: 'exornem',
  exornes: 'exornes',
  exornou: 'exornou',
  exortai: 'exortai',
  exortam: 'exortam',
  exortar: 'exortar',
  exortas: 'exortas',
  exortei: 'exortei',
  exortem: 'exortem',
  exortes: 'exortes',
  exortou: 'exortou',
  exostra: 'exostra',
  exostro: 'exostro',
  exoteca: 'exoteca',
  exotica: 'exótica',
  exotico: 'exótico',
  exotipo: 'exotipo',
  exoucam: 'exouçam',
  exoucas: 'exouças',
  exouvem: 'exouvem',
  exouves: 'exouves',
  exouvia: 'exouvia',
  exouvir: 'exouvir',
  exouvis: 'exouvis',
  exouviu: 'exouviu',
  expanda: 'expanda',
  expande: 'expande',
  expandi: 'expandi',
  expando: 'expando',
  expanso: 'expanso',
  expecam: 'expeçam',
  expecas: 'expeças',
  expecta: 'expecta',
  expecte: 'expecte',
  expecto: 'expecto',
  expedem: 'expedem',
  expedes: 'expedes',
  expedia: 'expedia',
  expedir: 'expedir',
  expedis: 'expedis',
  expediu: 'expediu',
  expedra: 'expedra',
  expedre: 'expedre',
  expedro: 'expedro',
  expelam: 'expelam',
  expelas: 'expelas',
  expelem: 'expelem',
  expeles: 'expeles',
  expelia: 'expelia',
  expelir: 'expelir',
  expelis: 'expelis',
  expeliu: 'expeliu',
  expenda: 'expenda',
  expende: 'expende',
  expendi: 'expendi',
  expendo: 'expendo',
  expensa: 'expensa',
  experta: 'experta',
  experto: 'experto',
  expetai: 'expetai',
  expetam: 'expetam',
  expetar: 'expetar',
  expetas: 'expetas',
  expetei: 'expetei',
  expetem: 'expetem',
  expetes: 'expetes',
  expetou: 'expetou',
  expiada: 'expiada',
  expiado: 'expiado',
  expiais: 'expiais',
  expiamo: 'expiamo',
  expiara: 'expiara',
  expiava: 'expiava',
  expieis: 'expieis',
  expiemo: 'expiemo',
  expilai: 'expilai',
  expilam: 'expilam',
  expilar: 'expilar',
  expilas: 'expilas',
  expilei: 'expilei',
  expilem: 'expilem',
  expiles: 'expiles',
  expilou: 'expilou',
  expirai: 'expirai',
  expiram: 'expiram',
  expirar: 'expirar',
  expiras: 'expiras',
  expirei: 'expirei',
  expirem: 'expirem',
  expires: 'expires',
  expiros: 'expiros',
  expirou: 'expirou',
  explana: 'explana',
  explane: 'explane',
  explano: 'explano',
  explica: 'explica',
  explico: 'explico',
  exploda: 'exploda',
  explode: 'explode',
  explodi: 'explodi',
  explodo: 'explodo',
  explora: 'explora',
  explore: 'explore',
  exploro: 'exploro',
  explota: 'explota',
  explote: 'explote',
  exploto: 'exploto',
  expluam: 'expluam',
  expluas: 'expluas',
  expluda: 'expluda',
  expludo: 'expludo',
  expluem: 'expluem',
  expluia: 'expluía',
  expluir: 'expluir',
  expluis: 'expluis',
  expluiu: 'expluiu',
  expolem: 'expolem',
  expoles: 'expoles',
  expolia: 'expolia',
  expolir: 'expolir',
  expolis: 'expolis',
  expoliu: 'expoliu',
  expomos: 'expomos',
  exponde: 'exponde',
  expondo: 'expondo',
  exponha: 'exponha',
  exponho: 'exponho',
  exporao: 'exporão',
  exporas: 'exporás',
  exporei: 'exporei',
  exporem: 'exporem',
  expores: 'expores',
  exporia: 'exporia',
  expormo: 'expormo',
  exporta: 'exporta',
  exporte: 'exporte',
  exporto: 'exporto',
  exposta: 'exposta',
  exposto: 'exposto',
  exprima: 'exprima',
  exprime: 'exprime',
  exprimi: 'exprimi',
  exprimo: 'exprimo',
  exproba: 'exproba',
  exprobe: 'exprobe',
  exprobo: 'exprobo',
  expugna: 'expugna',
  expugne: 'expugne',
  expugno: 'expugno',
  expulam: 'expulam',
  expulas: 'expulas',
  expulsa: 'expulsa',
  expulse: 'expulse',
  expulso: 'expulso',
  expunge: 'expunge',
  expungi: 'expungi',
  expunha: 'expunha',
  expunja: 'expunja',
  expunjo: 'expunjo',
  expurga: 'expurga',
  expurgo: 'expurgo',
  expuser: 'expuser',
  exquisa: 'exquisa',
  exsicai: 'exsicai',
  exsicam: 'exsicam',
  exsicar: 'exsicar',
  exsicas: 'exsicas',
  exsicou: 'exsicou',
  exsique: 'exsique',
  exsolva: 'exsolva',
  exsolve: 'exsolve',
  exsolvi: 'exsolvi',
  exsolvo: 'exsolvo',
  exstada: 'exstada',
  exstado: 'exstado',
  exstais: 'exstais',
  exstamo: 'exstamo',
  exstara: 'exstara',
  exstava: 'exstava',
  exsteis: 'exsteis',
  exstemo: 'exstemo',
  exsuada: 'exsuada',
  exsuado: 'exsuado',
  exsuais: 'exsuais',
  exsuamo: 'exsuamo',
  exsuara: 'exsuará',
  exsuava: 'exsuava',
  exsucao: 'exsução',
  exsudai: 'exsudai',
  exsudam: 'exsudam',
  exsudar: 'exsudar',
  exsudas: 'exsudas',
  exsudei: 'exsudei',
  exsudem: 'exsudem',
  exsudes: 'exsudes',
  exsudou: 'exsudou',
  exsueis: 'exsueis',
  exsuemo: 'exsuemo',
  exsurge: 'exsurge',
  exsurgi: 'exsurgi',
  exsurja: 'exsurja',
  exsurjo: 'exsurjo',
  extante: 'extante',
  extases: 'êxtases',
  extasia: 'extasia',
  extasie: 'extasie',
  extasio: 'extasio',
  extensa: 'extensa',
  extenso: 'extenso',
  extenua: 'extenua',
  extenue: 'extenue',
  extenuo: 'extenuo',
  externa: 'externa',
  externe: 'externe',
  externo: 'externo',
  extinga: 'extinga',
  extingo: 'extingo',
  extinta: 'extinta',
  extinto: 'extinto',
  extirpa: 'extirpa',
  extirpe: 'extirpe',
  extirpo: 'extirpo',
  extorso: 'extorso',
  extraem: 'extraem',
  extraia: 'extraia',
  extraio: 'extraio',
  extrair: 'extrair',
  extrais: 'extrais',
  extraiu: 'extraiu',
  extrapo: 'extrapô',
  extrata: 'extrata',
  extrate: 'extrate',
  extrato: 'extrato',
  extrema: 'extrema',
  extreme: 'extreme',
  extremo: 'extremo',
  extrica: 'extrica',
  extrico: 'extrico',
  extubai: 'extubai',
  extubam: 'extubam',
  extubar: 'extubar',
  extubas: 'extubas',
  extubei: 'extubei',
  extubem: 'extubem',
  extubes: 'extubes',
  extubou: 'extubou',
  exturca: 'exturca',
  exturco: 'exturco',
  exubate: 'exubaté',
  exubera: 'exubera',
  exubere: 'exúbere',
  exubero: 'exubero',
  exuense: 'exuense',
  exulada: 'exulada',
  exulado: 'exulado',
  exulais: 'exulais',
  exulamo: 'exulamo',
  exulara: 'exulara',
  exulava: 'exulava',
  exuleis: 'exuleis',
  exulemo: 'exulemo',
  exultai: 'exultai',
  exultam: 'exultam',
  exultar: 'exultar',
  exultas: 'exultas',
  exultei: 'exultei',
  exultem: 'exultem',
  exultes: 'exultes',
  exultou: 'exultou',
  exulzao: 'êxulzão',
  exumada: 'exumada',
  exumado: 'exumado',
  exumais: 'exumais',
  exumamo: 'exumamo',
  exumara: 'exumara',
  exumava: 'exumava',
  exumeis: 'exumeis',
  exumemo: 'exumemo',
  exundai: 'exundai',
  exundam: 'exundam',
  exundar: 'exundar',
  exundas: 'exundas',
  exundei: 'exundei',
  exundem: 'exundem',
  exundes: 'exundes',
  exundou: 'exundou',
  exurbia: 'exúrbia',
  exurbio: 'exúrbio',
  exuvias: 'exúvias',
  exuvios: 'exúvios',
  eznitas: 'eznitas',
  ezraita: 'ezraíta',
  ezrinas: 'ezrinas',
  ezteris: 'ezteris',
  fabacea: 'fabácea',
  fabaceo: 'fabáceo',
  fabales: 'fabales',
  fabelas: 'fabelas',
  fabiana: 'fabiana',
  fabiano: 'fabiano',
  fabismo: 'fabismo',
  fabista: 'fabista',
  fabrica: 'fábrica',
  fabrico: 'fabrico',
  fabulai: 'fabulai',
  fabulam: 'fabulam',
  fabular: 'fabular',
  fabulas: 'fábulas',
  fabulei: 'fabulei',
  fabulem: 'fabulem',
  fabules: 'fabules',
  fabulou: 'fabulou',
  facadas: 'facadas',
  facaias: 'facaias',
  facaios: 'facaios',
  facalvo: 'façalvo',
  facamos: 'façamos',
  facanes: 'facanés',
  facanha: 'façanha',
  facataz: 'facataz',
  faccoes: 'facções',
  faccona: 'facçona',
  faceada: 'faceada',
  faceado: 'faceado',
  faceais: 'faceais',
  faceara: 'faceará',
  faceava: 'faceava',
  facecia: 'facécia',
  facecie: 'facecie',
  facecio: 'facecio',
  faceeis: 'faceeis',
  faceiam: 'faceiam',
  faceias: 'faceias',
  faceiem: 'faceiem',
  faceies: 'faceies',
  faceira: 'faceira',
  faceire: 'faceire',
  faceiro: 'faceiro',
  facejai: 'facejai',
  facejam: 'facejam',
  facejar: 'facejar',
  facejas: 'facejas',
  facejei: 'facejei',
  facejem: 'facejem',
  facejes: 'facejes',
  facejou: 'facejou',
  facelia: 'facélia',
  facetai: 'facetai',
  facetam: 'facetam',
  facetar: 'facetar',
  facetas: 'facetas',
  facetea: 'faceteá',
  facetei: 'facetei',
  facetem: 'facetem',
  facetes: 'facetes',
  facetou: 'facetou',
  fachaca: 'fachaca',
  fachada: 'fachada',
  fachado: 'fachado',
  fachais: 'fachais',
  fachamo: 'fachamo',
  fachara: 'fachará',
  fachava: 'fachava',
  facheai: 'facheai',
  fachear: 'fachear',
  facheei: 'facheei',
  facheia: 'facheia',
  facheie: 'facheie',
  facheio: 'facheio',
  facheis: 'facheis',
  fachemo: 'fachemo',
  facheou: 'facheou',
  fachoca: 'fachoca',
  fachoco: 'fachoco',
  fachola: 'fachola',
  fachole: 'fachole',
  facholo: 'facholo',
  fachuco: 'fachuco',
  fachuda: 'fachuda',
  fachudo: 'fachudo',
  faciais: 'faciais',
  facidio: 'facídio',
  facinha: 'facinha',
  faciona: 'faciona',
  facione: 'facione',
  faciono: 'faciono',
  faciosa: 'faciosa',
  facioso: 'facioso',
  facismo: 'facismo',
  facista: 'facista',
  facites: 'facites',
  facoide: 'facoide',
  facoila: 'façoila',
  facomas: 'facomas',
  faconas: 'faconas',
  facotes: 'facotes',
  factico: 'fáctico',
  factiva: 'factiva',
  factivo: 'factivo',
  factora: 'factora',
  factore: 'factore',
  factoro: 'factoro',
  factoto: 'factoto',
  factual: 'factual',
  factura: 'factura',
  facudas: 'façudas',
  facudos: 'façudos',
  faculas: 'fáculas',
  faculta: 'faculta',
  faculte: 'faculte',
  faculto: 'faculto',
  facunda: 'facunda',
  facundo: 'facundo',
  fadadao: 'fadadão',
  fadadas: 'fadadas',
  fadador: 'fadador',
  fadados: 'fadados',
  fadamos: 'fadamos',
  fadando: 'fadando',
  fadante: 'fadante',
  fadaram: 'fadaram',
  fadarao: 'fadarão',
  fadaras: 'fadarás',
  fadarei: 'fadarei',
  fadarem: 'fadarem',
  fadares: 'fadares',
  fadaria: 'fadaria',
  fadario: 'fadário',
  fadarmo: 'fadarmo',
  fadasse: 'fadasse',
  fadaste: 'fadaste',
  fadavam: 'fadavam',
  fadavas: 'fadavas',
  fadejai: 'fadejai',
  fadejam: 'fadejam',
  fadejar: 'fadejar',
  fadejas: 'fadejas',
  fadejei: 'fadejei',
  fadejem: 'fadejem',
  fadejes: 'fadejes',
  fadejou: 'fadejou',
  fademos: 'fademos',
  fadicas: 'fádicas',
  fadicos: 'fadiços',
  fadigai: 'fadigai',
  fadigam: 'fadigam',
  fadigar: 'fadigar',
  fadigas: 'fadigas',
  fadigou: 'fadigou',
  fadigue: 'fadigue',
  fadinha: 'fadinha',
  fadinho: 'fadinho',
  fadismo: 'fadismo',
  fadista: 'fadista',
  fadiste: 'fadiste',
  fadisto: 'fadisto',
  fadogia: 'fadógia',
  fadonas: 'fadonas',
  faecium: 'faecium',
  faeismo: 'faeísmo',
  faengas: 'faengas',
  faetons: 'faetons',
  fafense: 'fafense',
  fagacea: 'fagácea',
  fagaceo: 'fagáceo',
  fagadao: 'fagadão',
  fagadas: 'fagadas',
  fagador: 'fagador',
  fagados: 'fagados',
  fagales: 'fagales',
  fagamos: 'fagamos',
  fagando: 'fagando',
  fagante: 'fagante',
  fagaram: 'fagaram',
  fagarao: 'fagarão',
  fagaras: 'fagaras',
  fagarei: 'fagarei',
  fagarem: 'fagarem',
  fagares: 'fagares',
  fagaria: 'fagaria',
  fagasse: 'fagasse',
  fagaste: 'fagaste',
  fagavam: 'fagavam',
  fagavas: 'fagavas',
  faginas: 'faginas',
  fagonia: 'fagônia',
  fagotes: 'fagotes',
  fagueis: 'fagueis',
  faguice: 'faguice',
  fagulha: 'fagulha',
  fagulhe: 'fagulhe',
  fagulho: 'fagulho',
  faiadao: 'faiadão',
  faiadas: 'faiadas',
  faiador: 'faiador',
  faiados: 'faiados',
  faiamos: 'faiamos',
  faianca: 'faiança',
  faiando: 'faiando',
  faiante: 'faiante',
  faiaram: 'faiaram',
  faiarao: 'faiarão',
  faiaras: 'faiarás',
  faiarei: 'faiarei',
  faiarem: 'faiarem',
  faiares: 'faiares',
  faiaria: 'faiaria',
  faiarmo: 'faiarmo',
  faiasse: 'faiasse',
  faiaste: 'faiaste',
  faiavam: 'faiavam',
  faiavas: 'faiavas',
  faichis: 'faichis',
  faiemos: 'faiemos',
  faienca: 'faiença',
  failina: 'failina',
  fainada: 'fainada',
  fainado: 'fainado',
  fainais: 'fainais',
  fainamo: 'fainamo',
  fainara: 'fainará',
  fainava: 'fainava',
  faineis: 'faineis',
  fainemo: 'fainemo',
  fairada: 'fairada',
  fairado: 'fairado',
  fairais: 'fairais',
  fairamo: 'fairamo',
  fairara: 'fairará',
  fairava: 'fairava',
  faireis: 'faireis',
  fairemo: 'fairemo',
  faiscai: 'faiscai',
  faiscam: 'faíscam',
  faiscar: 'faiscar',
  faiscas: 'faíscas',
  faiscos: 'faíscos',
  faiscou: 'faiscou',
  faisoas: 'faisoas',
  faisoes: 'faisões',
  faisque: 'faísque',
  faitiao: 'faitião',
  faixada: 'faixada',
  faixado: 'faixado',
  faixais: 'faixais',
  faixamo: 'faixamo',
  faixara: 'faixará',
  faixava: 'faixava',
  faixeai: 'faixeai',
  faixear: 'faixear',
  faixeei: 'faixeei',
  faixeia: 'faixeia',
  faixeie: 'faixeie',
  faixeio: 'faixeio',
  faixeis: 'faixeis',
  faixemo: 'faixemo',
  faixeou: 'faixeou',
  faixeta: 'faixeta',
  fajanas: 'fajanas',
  fajarda: 'fajarda',
  fajardo: 'fajardo',
  fajecas: 'fajecas',
  fajocos: 'fajocos',
  fajutai: 'fajutai',
  fajutam: 'fajutam',
  fajutao: 'fajutão',
  fajutar: 'fajutar',
  fajutas: 'fajutas',
  fajutei: 'fajutei',
  fajutem: 'fajutem',
  fajutes: 'fajutes',
  fajutos: 'fajutos',
  fajutou: 'fajutou',
  falacai: 'falaçai',
  falacam: 'falaçam',
  falacao: 'falação',
  falacar: 'falaçar',
  falacas: 'falacas',
  falacea: 'falácea',
  falacei: 'falacei',
  falacem: 'falacem',
  falaceo: 'faláceo',
  falaces: 'falaces',
  falacha: 'falacha',
  falacia: 'falácia',
  falacie: 'falacie',
  falacio: 'falacio',
  falacos: 'falaços',
  falacou: 'falaçou',
  falacro: 'falacro',
  faladai: 'faladai',
  faladam: 'faladam',
  faladao: 'faladão',
  faladar: 'faladar',
  faladas: 'faladas',
  faladei: 'faladei',
  faladem: 'faladem',
  falades: 'falades',
  falador: 'falador',
  falados: 'falados',
  faladou: 'faladou',
  faladra: 'faladra',
  faladre: 'faladre',
  faladro: 'faladro',
  falagra: 'falagra',
  falamos: 'falamos',
  falanca: 'falança',
  falando: 'falando',
  falaneu: 'falaneu',
  falange: 'falange',
  falante: 'falante',
  falaram: 'falaram',
  falarao: 'falarão',
  falaras: 'falaras',
  falaraz: 'falaraz',
  falarei: 'falarei',
  falarem: 'falarem',
  falares: 'falares',
  falaria: 'falaria',
  falario: 'falario',
  falaris: 'faláris',
  falarmo: 'falarmo',
  falasse: 'falasse',
  falaste: 'falaste',
  falavam: 'falavam',
  falavas: 'falavas',
  falazai: 'falazai',
  falazam: 'falazam',
  falazar: 'falazar',
  falazas: 'falazas',
  falazei: 'falazei',
  falazem: 'falazem',
  falazes: 'falazes',
  falazou: 'falazou',
  falbala: 'falbalá',
  falcaca: 'falcaça',
  falcace: 'falcace',
  falcaco: 'falcaço',
  falcada: 'falcada',
  falcado: 'falcado',
  falcais: 'falcais',
  falcamo: 'falcamo',
  falcara: 'falcara',
  falcata: 'falcata',
  falcato: 'falcato',
  falcava: 'falcava',
  falcoai: 'falcoai',
  falcoam: 'falcoam',
  falcoar: 'falcoar',
  falcoas: 'falcoas',
  falcoei: 'falcoei',
  falcoem: 'falcoem',
  falcoes: 'falcões',
  falcoou: 'falcoou',
  falcula: 'fálcula',
  faldras: 'faldras',
  falecam: 'faleçam',
  falecas: 'faleças',
  falecei: 'falecei',
  falecem: 'falecem',
  falecer: 'falecer',
  faleces: 'faleces',
  faleceu: 'faleceu',
  falecia: 'falecia',
  falecio: 'falécio',
  falejai: 'falejai',
  falejam: 'falejam',
  falejar: 'falejar',
  falejas: 'falejas',
  falejei: 'falejei',
  falejem: 'falejem',
  falejes: 'falejes',
  falejou: 'falejou',
  falemos: 'falemos',
  falenas: 'falenas',
  falenia: 'falênia',
  falenos: 'falenos',
  falepia: 'falépia',
  faleras: 'fáleras',
  faleria: 'faléria',
  falerio: 'falério',
  faleris: 'fáleris',
  falerno: 'falerno',
  falesia: 'falésia',
  falgais: 'falgais',
  falgamo: 'falgamo',
  falgaro: 'fálgaro',
  falguei: 'falguei',
  falguem: 'falguem',
  falguer: 'falguer',
  falgues: 'falgues',
  falgueu: 'falgueu',
  falguia: 'falguia',
  falhada: 'falhada',
  falhado: 'falhado',
  falhais: 'falhais',
  falhamo: 'falhamo',
  falhara: 'falhara',
  falhava: 'falhava',
  falheis: 'falheis',
  falhemo: 'falhemo',
  falhepa: 'falhepa',
  falhipo: 'falhipo',
  falhoca: 'falhoca',
  falhoco: 'falhoco',
  falhoes: 'falhões',
  falhopa: 'falhopa',
  falhosa: 'falhosa',
  falhoso: 'falhoso',
  falhuda: 'falhuda',
  falhudo: 'falhudo',
  falicas: 'fálicas',
  falicos: 'fálicos',
  falidao: 'falidão',
  falidas: 'falidas',
  falidor: 'falidor',
  falidos: 'falidos',
  falieis: 'falíeis',
  falijas: 'falijas',
  falimos: 'falimos',
  falinas: 'falinas',
  falindo: 'falindo',
  falinea: 'falínea',
  falinha: 'falinha',
  faliram: 'faliram',
  falirao: 'falirão',
  faliras: 'faliras',
  falirei: 'falirei',
  falirem: 'falirem',
  falires: 'falires',
  faliria: 'faliria',
  falirmo: 'falirmo',
  falisco: 'falisco',
  falismo: 'falismo',
  falisse: 'falisse',
  falista: 'falista',
  faliste: 'faliste',
  falites: 'falites',
  falivel: 'falível',
  falmega: 'falmega',
  falocai: 'falocai',
  falocam: 'falocam',
  falocar: 'falocar',
  falocas: 'falocas',
  falocou: 'falocou',
  faloide: 'faloide',
  falopes: 'falopes',
  faloque: 'faloque',
  falquea: 'falqueá',
  falquei: 'falquei',
  falquem: 'falquem',
  falques: 'falques',
  falquia: 'falquia',
  falripa: 'falripa',
  falsada: 'falsada',
  falsado: 'falsado',
  falsais: 'falsais',
  falsamo: 'falsamo',
  falsara: 'falsará',
  falsava: 'falsava',
  falseai: 'falseai',
  falsear: 'falsear',
  falseei: 'falseei',
  falseia: 'falseia',
  falseie: 'falseie',
  falseio: 'falseio',
  falseis: 'falseis',
  falsemo: 'falsemo',
  falseou: 'falseou',
  falseta: 'falseta',
  falsete: 'falsete',
  falsias: 'falsias',
  falsura: 'falsura',
  faltada: 'faltada',
  faltado: 'faltado',
  faltais: 'faltais',
  faltamo: 'faltamo',
  faltara: 'faltara',
  faltava: 'faltava',
  falteis: 'falteis',
  faltemo: 'faltemo',
  faltosa: 'faltosa',
  faltoso: 'faltoso',
  falucai: 'falucai',
  falucam: 'falucam',
  falucar: 'falucar',
  falucas: 'falucas',
  falucho: 'falucho',
  falucou: 'falucou',
  faludas: 'faludas',
  faludos: 'faludos',
  falupas: 'falupas',
  faluque: 'faluque',
  falusia: 'falúsia',
  faluzes: 'faluzes',
  falvala: 'falvalá',
  famalia: 'famaliá',
  famanas: 'famanãs',
  famanaz: 'famanaz',
  famegos: 'famegos',
  famelga: 'famelga',
  famelgo: 'famelgo',
  famense: 'famense',
  familia: 'família',
  familie: 'familie',
  familio: 'familio',
  faminea: 'famínea',
  famineo: 'famíneo',
  faminta: 'faminta',
  faminto: 'faminto',
  famosas: 'famosas',
  famosos: 'famosos',
  famulai: 'famulai',
  famulam: 'famulam',
  famular: 'famular',
  famulas: 'famulas',
  famulei: 'famulei',
  famulem: 'famulem',
  famules: 'famules',
  famulos: 'fâmulos',
  famulou: 'famulou',
  fanacao: 'fanação',
  fanacos: 'fanacos',
  fanadao: 'fanadão',
  fanadas: 'fanadas',
  fanador: 'fanador',
  fanados: 'fanados',
  fanaite: 'fanaite',
  fanamos: 'fanamos',
  fanando: 'fanando',
  fanante: 'fanante',
  fanaram: 'fanaram',
  fanarao: 'fanarão',
  fanaras: 'fanarás',
  fanarei: 'fanarei',
  fanarem: 'fanarem',
  fanares: 'fanares',
  fanaria: 'fanaria',
  fanarmo: 'fanarmo',
  fanasse: 'fanasse',
  fanaste: 'fanaste',
  fanates: 'fanates',
  fanavam: 'fanavam',
  fanavas: 'fanavas',
  fancaia: 'fancaia',
  fanchao: 'fanchão',
  fanchos: 'fanchos',
  fanconi: 'fanconi',
  fanecao: 'fanecão',
  fanecas: 'fanecas',
  fanecos: 'fanecos',
  fanegas: 'fanegas',
  faneias: 'faneias',
  fanemos: 'fanemos',
  faneras: 'fâneras',
  faneros: 'fâneros',
  fanfada: 'fanfada',
  fanfado: 'fanfado',
  fanfais: 'fanfais',
  fanfamo: 'fanfamo',
  fanfara: 'fanfara',
  fanfava: 'fanfava',
  fanfeis: 'fanfeis',
  fanfemo: 'fanfemo',
  fanfula: 'fanfula',
  fangana: 'fangana',
  fangane: 'fangane',
  fangano: 'fangano',
  fangina: 'fangina',
  fanhosa: 'fanhosa',
  fanhoso: 'fanhoso',
  fanicai: 'fanicai',
  fanicam: 'fanicam',
  fanicar: 'fanicar',
  fanicas: 'fanicas',
  fanicha: 'fanicha',
  faniche: 'faniche',
  fanicho: 'fanicho',
  fanicos: 'fanicos',
  fanicou: 'fanicou',
  faninea: 'fanínea',
  fanineo: 'faníneo',
  fanique: 'fanique',
  fanisco: 'fanisco',
  fanquis: 'fanquis',
  fantins: 'fantins',
  fantons: 'fantons',
  fanucai: 'fanucai',
  fanucam: 'fanucam',
  fanucar: 'fanucar',
  fanucas: 'fanucas',
  fanucho: 'fanucho',
  fanucos: 'fanucos',
  fanucou: 'fanucou',
  fanuque: 'fanuque',
  fanzine: 'fanzine',
  fanzoca: 'fanzoca',
  faqueai: 'faqueai',
  faquear: 'faquear',
  faqueco: 'faqueco',
  faqueei: 'faqueei',
  faqueia: 'faqueia',
  faqueie: 'faqueie',
  faqueio: 'faqueio',
  faqueou: 'faqueou',
  faquete: 'faquete',
  faquino: 'faquino',
  faquins: 'faquins',
  faradao: 'faradão',
  faradas: 'faradas',
  faraday: 'faraday',
  faradio: 'farádio',
  farador: 'farador',
  farados: 'farados',
  faramea: 'farâmea',
  faramos: 'faramos',
  farando: 'farando',
  faranta: 'faranta',
  farante: 'farante',
  fararam: 'fararam',
  fararao: 'fararão',
  fararas: 'fararás',
  fararei: 'fararei',
  fararem: 'fararem',
  farares: 'farares',
  fararia: 'fararia',
  fararmo: 'fararmo',
  farasse: 'farasse',
  faraste: 'faraste',
  farauta: 'farauta',
  faraute: 'faraute',
  faravam: 'faravam',
  faravas: 'faravas',
  farazes: 'farazes',
  farazoa: 'farazoa',
  farbios: 'fárbios',
  farbite: 'farbite',
  farcino: 'farcino',
  fardada: 'fardada',
  fardado: 'fardado',
  fardais: 'fardais',
  fardamo: 'fardamo',
  fardara: 'fardara',
  fardava: 'fardava',
  fardeis: 'fardeis',
  fardemo: 'fardemo',
  fardeta: 'fardeta',
  fardete: 'fardete',
  fardoes: 'fardões',
  fardola: 'fardola',
  fareias: 'fareias',
  farejai: 'farejai',
  farejam: 'farejam',
  farejar: 'farejar',
  farejas: 'farejas',
  farejei: 'farejei',
  farejem: 'farejem',
  farejes: 'farejes',
  farejou: 'farejou',
  farelai: 'farelai',
  farelam: 'farelam',
  farelao: 'farelão',
  farelar: 'farelar',
  farelas: 'farelas',
  farelei: 'farelei',
  farelem: 'farelem',
  fareles: 'fareles',
  farelos: 'farelos',
  farelou: 'farelou',
  faremos: 'faremos',
  farense: 'farense',
  faretra: 'fáretra',
  faretre: 'faretre',
  faretro: 'faretro',
  farfana: 'farfana',
  farfara: 'fárfara',
  fariaca: 'faríaca',
  fariaco: 'faríaco',
  farieis: 'faríeis',
  farinai: 'farinai',
  farinam: 'farinam',
  farinar: 'farinar',
  farinas: 'farinas',
  farinei: 'farinei',
  farinem: 'farinem',
  farines: 'farines',
  faringe: 'faringe',
  farinha: 'farinha',
  farinhe: 'farinhe',
  farinho: 'farinho',
  farinou: 'farinou',
  farisca: 'farisca',
  farisco: 'farisco',
  fariseu: 'fariseu',
  faritas: 'faritas',
  farjuta: 'farjuta',
  farjuto: 'farjuto',
  farmaca: 'fármaca',
  farmaco: 'fármaco',
  farneis: 'farnéis',
  farofas: 'farofas',
  farofia: 'farófia',
  farofie: 'farofie',
  farofio: 'farofio',
  farolai: 'farolai',
  farolam: 'farolam',
  farolar: 'farolar',
  farolas: 'farolas',
  farolei: 'farolei',
  farolem: 'farolem',
  faroles: 'faroles',
  farolim: 'farolim',
  farolou: 'farolou',
  farotas: 'farotas',
  farpada: 'farpada',
  farpado: 'farpado',
  farpais: 'farpais',
  farpamo: 'farpamo',
  farpara: 'farpará',
  farpava: 'farpava',
  farpeai: 'farpeai',
  farpear: 'farpear',
  farpeei: 'farpeei',
  farpeia: 'farpeia',
  farpeie: 'farpeie',
  farpeio: 'farpeio',
  farpeis: 'farpeis',
  farpela: 'farpela',
  farpelo: 'farpelo',
  farpemo: 'farpemo',
  farpeou: 'farpeou',
  farpoai: 'farpoai',
  farpoam: 'farpoam',
  farpoar: 'farpoar',
  farpoas: 'farpoas',
  farpoei: 'farpoei',
  farpoem: 'farpoem',
  farpoes: 'farpões',
  farpoou: 'farpoou',
  farrada: 'farrada',
  farrado: 'farrado',
  farrais: 'farrais',
  farrajo: 'farrajo',
  farramo: 'farramo',
  farrapa: 'farrapa',
  farrape: 'farrape',
  farrapo: 'farrapo',
  farrara: 'farrara',
  farrava: 'farrava',
  farreai: 'farreai',
  farrear: 'farrear',
  farreas: 'fárreas',
  farreei: 'farreei',
  farreia: 'farreia',
  farreie: 'farreie',
  farreio: 'farreio',
  farreis: 'farreis',
  farrejo: 'farrejo',
  farrela: 'farrela',
  farremo: 'farremo',
  farreos: 'fárreos',
  farreou: 'farreou',
  farrepa: 'farrepa',
  farripa: 'farripa',
  farripo: 'farripo',
  farroba: 'farroba',
  farrobe: 'farrobe',
  farroes: 'farrões',
  farroma: 'farroma',
  farrona: 'farrona',
  farruco: 'farruco',
  farruma: 'farruma',
  farrupa: 'farrupa',
  farsada: 'farsada',
  farsola: 'farsola',
  farsole: 'farsole',
  farsolo: 'farsolo',
  farsuda: 'farsuda',
  farsudo: 'farsudo',
  fartada: 'fartada',
  fartado: 'fartado',
  fartais: 'fartais',
  fartamo: 'fartamo',
  fartara: 'fartara',
  fartava: 'fartava',
  farteca: 'farteça',
  fartece: 'fartece',
  farteci: 'farteci',
  farteco: 'farteço',
  farteis: 'fartéis',
  fartemo: 'fartemo',
  fartens: 'fártens',
  farteza: 'farteza',
  fartoes: 'fartões',
  fartote: 'fartote',
  fartuns: 'fartuns',
  fartura: 'fartura',
  faruaru: 'faruaru',
  farulas: 'farulas',
  farunga: 'farungá',
  farungo: 'farungo',
  fascaco: 'fascaço',
  fascais: 'fascais',
  fascial: 'fascial',
  fascias: 'fáscias',
  fascina: 'fascina',
  fascine: 'fascine',
  fascino: 'fascino',
  fascios: 'fáscios',
  fascite: 'fascite',
  fascoto: 'fascoto',
  faseada: 'faseada',
  faseado: 'faseado',
  faseais: 'faseais',
  faseara: 'faseará',
  faseava: 'faseava',
  faseeis: 'faseeis',
  faseiam: 'faseiam',
  faseias: 'faseias',
  faseiem: 'faseiem',
  faseies: 'faseies',
  faseolo: 'faséolo',
  fashion: 'fashion',
  fasiaca: 'fasíaca',
  fasiaco: 'fasíaco',
  fasiana: 'fasiana',
  fasiane: 'fasiane',
  fasiano: 'fasiano',
  fasidos: 'fásidos',
  fasinea: 'fasínea',
  fasineo: 'fasíneo',
  fasmida: 'fásmida',
  fasmido: 'fásmido',
  fasquia: 'fasquia',
  fasquie: 'fasquie',
  fasquio: 'fasquio',
  fastada: 'fastada',
  fastado: 'fastado',
  fastais: 'fastais',
  fastamo: 'fastamo',
  fastara: 'fastará',
  fastava: 'fastava',
  fasteis: 'fasteis',
  fastemo: 'fastemo',
  fastios: 'fastios',
  fastosa: 'fastosa',
  fastoso: 'fastoso',
  fasudil: 'fasudil',
  fasudis: 'fasudis',
  fatacas: 'fataças',
  fatacaz: 'fatacaz',
  fatadas: 'fatadas',
  fatagea: 'fatageá',
  fatagem: 'fatagem',
  fatanai: 'fatanai',
  fatanam: 'fatanam',
  fatanar: 'fatanar',
  fatanas: 'fatanas',
  fatanei: 'fatanei',
  fatanem: 'fatanem',
  fatanes: 'fatanes',
  fatanou: 'fatanou',
  fataria: 'fataria',
  fatario: 'fatário',
  fatecos: 'fatecos',
  fateias: 'fateias',
  fateira: 'fateira',
  fateiro: 'fateiro',
  fateixa: 'fateixa',
  fatejai: 'fatejai',
  fatejam: 'fatejam',
  fatejar: 'fatejar',
  fatejas: 'fatejas',
  fatejei: 'fatejei',
  fatejem: 'fatejem',
  fatejes: 'fatejes',
  fatejou: 'fatejou',
  fatelas: 'fatelas',
  fatiaca: 'fatiaça',
  fatiada: 'fatiada',
  fatiado: 'fatiado',
  fatiais: 'fatiais',
  fatiamo: 'fatiamo',
  fatiara: 'fatiara',
  fatiava: 'fatiava',
  faticas: 'fáticas',
  faticia: 'fatícia',
  faticio: 'fatício',
  faticos: 'fáticos',
  fatieis: 'fatieis',
  fatiemo: 'fatiemo',
  fatigai: 'fatigai',
  fatigam: 'fatigam',
  fatigar: 'fatigar',
  fatigas: 'fatigas',
  fatigou: 'fatigou',
  fatigue: 'fatigue',
  fatinho: 'fatinho',
  fatiota: 'fatiota',
  fatismo: 'fatismo',
  fativel: 'fatível',
  fatolas: 'fatolás',
  fatorai: 'fatorai',
  fatoram: 'fatoram',
  fatorar: 'fatorar',
  fatoras: 'fatoras',
  fatorei: 'fatorei',
  fatorem: 'fatorem',
  fatores: 'fatores',
  fatorou: 'fatorou',
  fatsias: 'fátsias',
  fatuais: 'fatuais',
  fatueja: 'fatuejá',
  fatueje: 'fatueje',
  fatuejo: 'fatuejo',
  faturai: 'faturai',
  faturam: 'faturam',
  faturar: 'faturar',
  faturas: 'faturas',
  faturei: 'faturei',
  faturem: 'faturem',
  fatures: 'fatures',
  faturou: 'faturou',
  faucais: 'faucais',
  faulada: 'faulada',
  faulado: 'faulado',
  faulais: 'faulais',
  faulamo: 'faulamo',
  faulara: 'faulará',
  faulava: 'faulava',
  fauleis: 'fauleis',
  faulemo: 'faulemo',
  faulhai: 'faulhai',
  faulham: 'faúlham',
  faulhar: 'faulhar',
  faulhas: 'faúlhas',
  faulhei: 'faulhei',
  faulhem: 'faulhem',
  faulhes: 'faulhes',
  faulhos: 'faúlhos',
  faulhou: 'faulhou',
  faunula: 'fáunula',
  faureas: 'fáureas',
  fauseis: 'fauséis',
  faustao: 'faustão',
  faustas: 'faustas',
  faustos: 'faustos',
  fauteza: 'fauteza',
  fautora: 'fautora',
  fautriz: 'fautriz',
  favacos: 'favacos',
  favadao: 'favadão',
  favadas: 'favadas',
  favador: 'favador',
  favados: 'favados',
  favamos: 'favamos',
  favando: 'favando',
  favante: 'favante',
  favaram: 'favaram',
  favarao: 'favarão',
  favaras: 'favarás',
  favarei: 'favarei',
  favarem: 'favarem',
  favares: 'favares',
  favaria: 'favária',
  favarmo: 'favarmo',
  favasse: 'favasse',
  favaste: 'favaste',
  favavam: 'favavam',
  favavas: 'favavas',
  favecas: 'favecas',
  favecos: 'favecos',
  faveira: 'faveira',
  faveiro: 'faveiro',
  favelao: 'favelão',
  favelas: 'favelas',
  favemos: 'favemos',
  favento: 'favento',
  faveolo: 'favéolo',
  favetas: 'favetas',
  favicas: 'fávicas',
  favicos: 'fávicos',
  favilas: 'favilas',
  favinha: 'favinha',
  faviona: 'faviona',
  favismo: 'favismo',
  favista: 'favista',
  favolas: 'favolas',
  favonas: 'favonas',
  favonea: 'favoneá',
  favonia: 'favonia',
  favonie: 'favonie',
  favonio: 'favônio',
  favores: 'favores',
  favosas: 'favosas',
  favosos: 'favosos',
  favulha: 'favulha',
  faxadas: 'faxadas',
  faxeada: 'faxeada',
  faxeado: 'faxeado',
  faxeais: 'faxeais',
  faxeara: 'faxeará',
  faxeava: 'faxeava',
  faxeeis: 'faxeeis',
  faxeiam: 'faxeiam',
  faxeias: 'faxeias',
  faxeiem: 'faxeiem',
  faxeies: 'faxeies',
  faxeque: 'faxeque',
  faxetas: 'faxetas',
  faxinai: 'faxinai',
  faxinal: 'faxinal',
  faxinam: 'faxinam',
  faxinar: 'faxinar',
  faxinas: 'faxinas',
  faxinei: 'faxinei',
  faxinem: 'faxinem',
  faxines: 'faxines',
  faxinou: 'faxinou',
  fazanio: 'fazânio',
  fazecao: 'fazeção',
  fazedor: 'fazedor',
  fazemos: 'fazemos',
  fazenda: 'fazenda',
  fazendo: 'fazendo',
  fazente: 'fazente',
  fazerem: 'fazerem',
  fazeres: 'fazeres',
  fazidao: 'fazidão',
  fazieis: 'fazíeis',
  fazinha: 'fãzinha',
  fazivel: 'fazível',
  fazonas: 'fãzonas',
  feacios: 'feácios',
  feaozao: 'feãozão',
  fearrao: 'fearrão',
  febeapa: 'febeapá',
  febeias: 'febeias',
  febrina: 'febrina',
  febroes: 'febrões',
  febrosa: 'febrosa',
  febroso: 'febroso',
  fechada: 'fechada',
  fechado: 'fechado',
  fechais: 'fechais',
  fechamo: 'fechamo',
  fechara: 'fechara',
  fechava: 'fechava',
  fecheis: 'fecheis',
  fechemo: 'fechemo',
  feciais: 'feciais',
  feculas: 'féculas',
  fecunda: 'fecunda',
  fecunde: 'fecunde',
  fecundo: 'fecundo',
  fedains: 'fedains',
  fedamos: 'fedamos',
  fedanha: 'fedanhá',
  fedanhe: 'fedanhe',
  fedanho: 'fedanho',
  fedayin: 'fedayin',
  fededor: 'fededor',
  fedegas: 'fedegas',
  fedelha: 'fedelha',
  fedelhe: 'fedelhe',
  fedelho: 'fedelho',
  fedemos: 'fedemos',
  fedenca: 'fedença',
  fedendo: 'fedendo',
  fedente: 'fedente',
  federai: 'federai',
  federal: 'federal',
  federam: 'federam',
  federao: 'federão',
  federar: 'federar',
  federas: 'federas',
  federei: 'federei',
  federem: 'federem',
  federes: 'federes',
  federia: 'federia',
  federmo: 'federmo',
  federou: 'federou',
  fedesse: 'fedesse',
  fedeste: 'fedeste',
  fedidao: 'fedidão',
  fedidas: 'fedidas',
  fedidos: 'fedidos',
  fedieis: 'fedíeis',
  fedocas: 'fedocas',
  fedonha: 'fedonha',
  fedonhe: 'fedonhe',
  fedonho: 'fedonho',
  fedoras: 'fedoras',
  fedores: 'fedores',
  feducia: 'fedúcia',
  feducos: 'feduços',
  feeling: 'feeling',
  feerias: 'feerias',
  feerica: 'feérica',
  feerico: 'feérico',
  feeries: 'féeries',
  fefetai: 'fefetai',
  fefetam: 'fefetam',
  fefetar: 'fefetar',
  fefetas: 'fefetas',
  fefetei: 'fefetei',
  fefetem: 'fefetem',
  fefetes: 'fefetes',
  fefetou: 'fefetou',
  feicoes: 'feições',
  feicona: 'feiçona',
  feiezas: 'feiezas',
  feiinha: 'feiinha',
  feiinho: 'feiinho',
  feiista: 'feiista',
  feijoal: 'feijoal',
  feijoas: 'feijoas',
  feijoca: 'feijoca',
  feijoco: 'feijoco',
  feijoes: 'feijões',
  feionas: 'feionas',
  feiosas: 'feiosas',
  feiosos: 'feiosos',
  feirada: 'feirada',
  feirado: 'feirado',
  feirais: 'feirais',
  feiramo: 'feiramo',
  feirara: 'feirara',
  feirava: 'feirava',
  feireis: 'feireis',
  feiremo: 'feiremo',
  feirios: 'feirios',
  feiroes: 'feirões',
  feiroto: 'feiroto',
  feismos: 'feísmos',
  feitada: 'feitada',
  feitado: 'feitado',
  feitais: 'feitais',
  feitamo: 'feitamo',
  feitara: 'feitará',
  feitava: 'feitava',
  feiteis: 'feiteis',
  feitemo: 'feitemo',
  feitens: 'feiténs',
  feitiai: 'feitiai',
  feitiam: 'feitiam',
  feitiar: 'feitiar',
  feitias: 'feitias',
  feitico: 'feitiço',
  feitiei: 'feitiei',
  feitiem: 'feitiem',
  feities: 'feities',
  feitios: 'feitios',
  feitiou: 'feitiou',
  feitoes: 'feitões',
  feitora: 'feitora',
  feitore: 'feitore',
  feitoro: 'feitoro',
  feitura: 'feitura',
  feiudas: 'feiudas',
  feiudos: 'feiudos',
  feiulas: 'feiulas',
  feiumes: 'feiumes',
  feiuras: 'feiuras',
  feixota: 'feixota',
  feixote: 'feixote',
  felacao: 'felação',
  feladao: 'feladão',
  feladas: 'feladas',
  felador: 'felador',
  felados: 'felados',
  felaina: 'felaína',
  felames: 'felames',
  felamos: 'felamos',
  felando: 'felando',
  felanim: 'felanim',
  felante: 'felante',
  felaram: 'felaram',
  felarao: 'felarão',
  felaras: 'felaras',
  felarei: 'felarei',
  felarem: 'felarem',
  felares: 'felares',
  felaria: 'felaria',
  felarmo: 'felarmo',
  felasse: 'felasse',
  felaste: 'felaste',
  felatas: 'felatas',
  felavam: 'felavam',
  felavas: 'felavas',
  felecas: 'felecas',
  felemas: 'felemas',
  felemes: 'felemes',
  felemos: 'felemos',
  feleuma: 'feleuma',
  felguda: 'felguda',
  felgudo: 'felgudo',
  felibre: 'felibre',
  felicia: 'felícia',
  felidas: 'félidas',
  felidea: 'felídea',
  felideo: 'felídeo',
  felinas: 'felinas',
  felinos: 'felinos',
  felista: 'felista',
  felizao: 'felizão',
  felizes: 'felizes',
  felocas: 'felocas',
  felocha: 'felocha',
  feloide: 'feloide',
  felonia: 'felonia',
  felonie: 'felonie',
  felonio: 'felônio',
  felosas: 'felosas',
  feloses: 'feloses',
  felosos: 'felosos',
  felpada: 'felpada',
  felpado: 'felpado',
  felpais: 'felpais',
  felpamo: 'felpamo',
  felpara: 'felpará',
  felpava: 'felpava',
  felpeis: 'felpeis',
  felpemo: 'felpemo',
  felposa: 'felposa',
  felposo: 'felposo',
  felpuda: 'felpuda',
  felpudo: 'felpudo',
  felsica: 'félsica',
  felsico: 'félsico',
  felsite: 'felsite',
  felsito: 'felsito',
  feltrai: 'feltrai',
  feltram: 'feltram',
  feltrar: 'feltrar',
  feltras: 'feltras',
  feltrei: 'feltrei',
  feltrem: 'feltrem',
  feltres: 'feltres',
  feltros: 'feltros',
  feltrou: 'feltrou',
  felucho: 'felucho',
  felugem: 'felugem',
  felupes: 'felupes',
  felupos: 'felupos',
  felzoes: 'felzões',
  fembada: 'fembada',
  fembado: 'fembado',
  fembais: 'fembais',
  fembamo: 'fembamo',
  fembara: 'fembará',
  fembava: 'fembava',
  fembeis: 'fembeis',
  fembemo: 'fembemo',
  femeaca: 'femeaça',
  femeaco: 'femeaço',
  femeada: 'femeada',
  femeado: 'femeado',
  femeais: 'femeais',
  femeara: 'femeará',
  femeava: 'femeava',
  femeeis: 'femeeis',
  femeiam: 'femeiam',
  femeias: 'femeias',
  femeiem: 'femeiem',
  femeies: 'femeies',
  femeira: 'femeira',
  femeiro: 'femeiro',
  femenca: 'femença',
  femence: 'femence',
  femenco: 'femenço',
  femenes: 'femenês',
  femicas: 'fêmicas',
  femicos: 'fêmicos',
  feminal: 'feminal',
  feminea: 'femínea',
  femineo: 'femíneo',
  feminil: 'feminil',
  feminis: 'feminis',
  femoral: 'femoral',
  femural: 'femural',
  femures: 'fêmures',
  fenaban: 'fenaban',
  fenacao: 'fenação',
  fenaces: 'fenaces',
  fenacho: 'fenacho',
  fenadao: 'fenadão',
  fenadas: 'fenadas',
  fenador: 'fenador',
  fenados: 'fenados',
  fenagem: 'fenagem',
  fenaite: 'fenaíte',
  fenamol: 'fenamol',
  fenamos: 'fenamos',
  fenando: 'fenando',
  fenante: 'fenante',
  fenaram: 'fenaram',
  fenarao: 'fenarão',
  fenaras: 'fenarás',
  fenarei: 'fenarei',
  fenarem: 'fenarem',
  fenares: 'fenares',
  fenaria: 'fenaria',
  fenarmo: 'fenarmo',
  fenasco: 'fenasco',
  fenasse: 'fenasse',
  fenaste: 'fenaste',
  fenatos: 'fenatos',
  fenavam: 'fenavam',
  fenavas: 'fenavas',
  fendais: 'fendais',
  fendamo: 'fendamo',
  fendeis: 'fendeis',
  fendemo: 'fendemo',
  fendera: 'fendera',
  fendiam: 'fendiam',
  fendias: 'fendias',
  fendida: 'fendida',
  fendido: 'fendido',
  feneata: 'feneata',
  fenecam: 'feneçam',
  fenecas: 'feneças',
  fenecei: 'fenecei',
  fenecem: 'fenecem',
  fenecer: 'fenecer',
  feneces: 'feneces',
  feneceu: 'feneceu',
  fenecia: 'fenecia',
  fenecos: 'fenecos',
  feneira: 'feneira',
  feneiro: 'feneiro',
  fenelas: 'fenelas',
  fenelho: 'fenelho',
  fenemos: 'fenemos',
  fenetol: 'fenetol',
  fenfens: 'fenféns',
  fengita: 'fengita',
  fengite: 'fengite',
  feniana: 'feniana',
  feniano: 'feniano',
  fenicas: 'fênicas',
  fenicea: 'fenícea',
  feniceo: 'feníceo',
  fenices: 'fenices',
  fenicia: 'fenícia',
  fenicio: 'fenício',
  fenicol: 'fenicol',
  fenicos: 'fênicos',
  fenigma: 'fenigma',
  fenigmo: 'fenigmo',
  fenigue: 'fênigue',
  fenilas: 'fenilas',
  fenilos: 'fenilos',
  feninha: 'feninha',
  fenobam: 'fenobam',
  fenonas: 'fenonas',
  fentais: 'fentais',
  fenusas: 'fenusas',
  feocroa: 'feócroa',
  feodios: 'feódios',
  feofita: 'feófita',
  feofito: 'feófito',
  feornis: 'feórnis',
  feraces: 'feraces',
  feralia: 'ferália',
  ferazes: 'ferazes',
  ferculo: 'férculo',
  ferdina: 'ferdina',
  ferecia: 'ferécia',
  fereias: 'fereias',
  ferente: 'ferente',
  feresas: 'feresas',
  feretro: 'féretro',
  ferezas: 'ferezas',
  ferezeu: 'ferezeu',
  feriada: 'feriada',
  feriado: 'feriado',
  feriais: 'feriais',
  feriamo: 'feriamo',
  feriara: 'feriara',
  feriava: 'feriava',
  feribas: 'feribás',
  feridao: 'feridão',
  feridas: 'feridas',
  feridor: 'feridor',
  feridos: 'feridos',
  ferieis: 'feríeis',
  feriemo: 'feriemo',
  ferimos: 'ferimos',
  ferinas: 'ferinas',
  ferindo: 'ferindo',
  ferinos: 'ferinos',
  feriram: 'feriram',
  ferirao: 'ferirão',
  feriras: 'feriras',
  ferirei: 'ferirei',
  ferirem: 'ferirem',
  ferires: 'ferires',
  feriria: 'feriria',
  ferirmo: 'ferirmo',
  ferison: 'ferison',
  ferisse: 'ferisse',
  feriste: 'feriste',
  ferivel: 'ferível',
  fermata: 'fermata',
  fermiao: 'fermião',
  fermion: 'férmion',
  fermios: 'férmios',
  fermoso: 'fermoso',
  ferneis: 'fernéis',
  feroces: 'feroces',
  ferocia: 'ferócia',
  feroesa: 'feroesa',
  feroica: 'feroica',
  feroico: 'feroico',
  ferolia: 'ferólia',
  feronia: 'ferônia',
  ferozes: 'ferozes',
  ferraca: 'ferraça',
  ferrada: 'ferrada',
  ferrado: 'ferrado',
  ferrais: 'ferrais',
  ferramo: 'ferramo',
  ferrara: 'ferrara',
  ferrato: 'ferrato',
  ferrava: 'ferrava',
  ferreas: 'férreas',
  ferreis: 'ferreis',
  ferreja: 'ferreja',
  ferreje: 'ferreje',
  ferrejo: 'ferrejo',
  ferremo: 'ferremo',
  ferreos: 'férreos',
  ferreta: 'ferreta',
  ferrete: 'ferrete',
  ferreto: 'ferreto',
  ferrica: 'férrica',
  ferrico: 'férrico',
  ferrina: 'ferrina',
  ferrita: 'ferrita',
  ferrite: 'ferrite',
  ferrito: 'ferrito',
  ferrium: 'ferrium',
  ferroai: 'ferroai',
  ferroam: 'ferroam',
  ferroar: 'ferroar',
  ferroas: 'ferroas',
  ferroba: 'ferroba',
  ferrobe: 'ferrobe',
  ferrobo: 'ferrobo',
  ferroei: 'ferroei',
  ferroem: 'ferroem',
  ferroes: 'ferrões',
  ferroou: 'ferroou',
  ferrosa: 'ferrosa',
  ferroso: 'ferroso',
  ferrule: 'ferrule',
  ferteis: 'férteis',
  fertora: 'fertora',
  ferucua: 'ferúcua',
  ferulas: 'férulas',
  ferusas: 'ferusas',
  fervais: 'fervais',
  fervamo: 'fervamo',
  ferveis: 'ferveis',
  fervemo: 'fervemo',
  fervera: 'fervera',
  ferviam: 'ferviam',
  fervias: 'fervias',
  fervida: 'fervida',
  fervido: 'fervido',
  fervora: 'fervora',
  fervore: 'fervore',
  fervoro: 'fervoro',
  fervura: 'fervura',
  fesapos: 'fesapos',
  fescoco: 'fescoço',
  festada: 'festada',
  festado: 'festado',
  festais: 'festais',
  festamo: 'festamo',
  festara: 'festara',
  festava: 'festava',
  festeis: 'festeis',
  festeja: 'festeja',
  festeje: 'festeje',
  festejo: 'festejo',
  festemo: 'festemo',
  festina: 'festina',
  festine: 'festine',
  festino: 'festino',
  festins: 'festins',
  festiva: 'festiva',
  festivo: 'festivo',
  festoai: 'festoai',
  festoam: 'festoam',
  festoar: 'festoar',
  festoas: 'festoas',
  festoei: 'festoei',
  festoem: 'festoem',
  festoes: 'festões',
  festona: 'festona',
  festone: 'festonê',
  festono: 'festono',
  festoou: 'festoou',
  festora: 'festora',
  festosa: 'festosa',
  festoso: 'festoso',
  festuca: 'festuca',
  fetacao: 'fetação',
  fetacea: 'fetácea',
  fetaceo: 'fetáceo',
  fetamol: 'fetamol',
  feteira: 'feteira',
  fetiche: 'fetiche',
  fetidas: 'fétidas',
  fetidez: 'fetidez',
  fetidia: 'fetídia',
  fetidos: 'fétidos',
  fetucas: 'fetucas',
  fetusas: 'fetusas',
  fetusca: 'fetusca',
  feudais: 'feudais',
  fevelho: 'fevelho',
  feverao: 'feverão',
  feveras: 'fêveras',
  fevilea: 'fevílea',
  fevrosa: 'fevrosa',
  fevroso: 'fevroso',
  fezadas: 'fezadas',
  fezinha: 'fezinha',
  fiacoes: 'fiações',
  fiacona: 'fiaçona',
  fiacres: 'fiacres',
  fiacuxo: 'fiacuxo',
  fiadoes: 'fiadões',
  fiadona: 'fiadona',
  fiadora: 'fiadora',
  fiadura: 'fiadura',
  fialhos: 'fialhos',
  fiambra: 'fiambra',
  fiambre: 'fiambre',
  fiampai: 'fiampai',
  fiampam: 'fiampam',
  fiampar: 'fiampar',
  fiampas: 'fiampas',
  fiampei: 'fiampei',
  fiampem: 'fiampem',
  fiampes: 'fiampes',
  fiampou: 'fiampou',
  fiampua: 'fiampua',
  fiancas: 'fianças',
  fiancos: 'fianços',
  fiandao: 'fiandão',
  fiangos: 'fiangos',
  fianhos: 'fianhos',
  fiantes: 'fiantes',
  fiapica: 'fiapica',
  fiapico: 'fiapico',
  fiardes: 'fiardes',
  fiareis: 'fiareis',
  fiaremo: 'fiaremo',
  fiariam: 'fiariam',
  fiarias: 'fiarias',
  fiarmos: 'fiarmos',
  fiascos: 'fiascos',
  fiassem: 'fiassem',
  fiasses: 'fiasses',
  fiastes: 'fiastes',
  fiatola: 'fiatola',
  fiaveis: 'fiáveis',
  fibrada: 'fibrada',
  fibrado: 'fibrado',
  fibrana: 'fibrana',
  fibrela: 'fibrela',
  fibrila: 'fibrila',
  fibrile: 'fibrile',
  fibrilo: 'fibrilo',
  fibrina: 'fibrina',
  fibrino: 'fibrino',
  fibroma: 'fibroma',
  fibrosa: 'fibrosa',
  fibrose: 'fibrose',
  fibroso: 'fibroso',
  fibular: 'fibular',
  fibulas: 'fíbulas',
  ficacea: 'ficácea',
  ficaceo: 'ficáceo',
  ficadao: 'ficadão',
  ficadas: 'ficadas',
  ficador: 'ficador',
  ficados: 'ficados',
  ficamos: 'ficamos',
  ficanco: 'ficanço',
  ficando: 'ficando',
  ficante: 'ficante',
  ficaram: 'ficaram',
  ficarao: 'ficarão',
  ficaras: 'ficaras',
  ficarei: 'ficarei',
  ficarem: 'ficarem',
  ficares: 'ficares',
  ficaria: 'ficaria',
  ficario: 'ficário',
  ficarmo: 'ficarmo',
  ficasse: 'ficasse',
  ficaste: 'ficaste',
  ficatos: 'ficatos',
  ficavam: 'ficavam',
  ficavas: 'ficavas',
  ficcoes: 'ficções',
  ficcona: 'ficçona',
  ficelas: 'ficelas',
  ficelle: 'ficelle',
  fichada: 'fichada',
  fichado: 'fichado',
  fichais: 'fichais',
  fichamo: 'fichamo',
  fichara: 'fichara',
  fichava: 'fichava',
  ficheis: 'ficheis',
  fichemo: 'fichemo',
  fichoas: 'fichoas',
  ficidea: 'ficídea',
  ficideo: 'ficídeo',
  ficinas: 'ficinas',
  ficinea: 'ficínea',
  ficineo: 'ficíneo',
  ficinia: 'ficínia',
  ficites: 'ficites',
  ficoide: 'ficoide',
  ficoita: 'ficoíta',
  ficoite: 'ficoíte',
  ficores: 'ficores',
  ficteis: 'fícteis',
  fictora: 'fictora',
  ficulas: 'fículas',
  fidagal: 'fidagal',
  fidalga: 'fidalga',
  fidalgo: 'fidalgo',
  fidente: 'fidente',
  fidiana: 'fidiana',
  fidiano: 'fidiano',
  fidonia: 'fidônia',
  fiducia: 'fidúcia',
  fieiras: 'fieiras',
  fieiros: 'fieiros',
  fieital: 'fieital',
  fieitos: 'fieitos',
  figadal: 'figadal',
  figadao: 'figadão',
  figadas: 'figadas',
  figador: 'figador',
  figados: 'fígados',
  figalia: 'figália',
  figamos: 'figamos',
  figanca: 'figança',
  figando: 'figando',
  figante: 'figante',
  figaram: 'figaram',
  figarao: 'figarão',
  figaras: 'figarás',
  figarei: 'figarei',
  figarem: 'figarem',
  figares: 'figares',
  figaria: 'figaria',
  figaros: 'fígaros',
  figasse: 'figasse',
  figaste: 'figaste',
  figavam: 'figavam',
  figavas: 'figavas',
  figites: 'figites',
  figotes: 'figotes',
  figueis: 'figueis',
  figulos: 'fígulos',
  figurai: 'figurai',
  figural: 'figural',
  figuram: 'figuram',
  figurao: 'figurão',
  figurar: 'figurar',
  figuras: 'figuras',
  figurei: 'figurei',
  figurem: 'figurem',
  figures: 'figures',
  figurou: 'figurou',
  fijiana: 'fijiana',
  fijiano: 'fijiano',
  fijolas: 'fijolas',
  filacas: 'filaças',
  filaceu: 'filaceu',
  filadao: 'filadão',
  filadas: 'filadas',
  filadio: 'filádio',
  filador: 'filador',
  filados: 'filados',
  filafis: 'filáfis',
  filagem: 'filagem',
  filagos: 'filagos',
  filalis: 'filális',
  filames: 'filames',
  filamos: 'filamos',
  filanca: 'filança',
  filando: 'filando',
  filante: 'filante',
  filanto: 'filanto',
  filaram: 'filaram',
  filarao: 'filarão',
  filaras: 'filaras',
  filarco: 'filarco',
  filarei: 'filarei',
  filarem: 'filarem',
  filares: 'filares',
  filaria: 'filária',
  filarmo: 'filarmo',
  filasse: 'filasse',
  filaste: 'filaste',
  filavam: 'filavam',
  filavas: 'filavas',
  filaxia: 'filaxia',
  filecos: 'filecos',
  fileira: 'fileira',
  fileiro: 'fileiro',
  fileles: 'fileles',
  filemos: 'filemos',
  filenos: 'filenos',
  filetai: 'filetai',
  filetam: 'filetam',
  filetar: 'filetar',
  filetas: 'filetas',
  filetea: 'fileteá',
  filetei: 'filetei',
  filetem: 'filetem',
  filetes: 'filetes',
  filetou: 'filetou',
  filhada: 'filhada',
  filhado: 'filhado',
  filhais: 'filhais',
  filhamo: 'filhamo',
  filhara: 'filhara',
  filhare: 'filhare',
  filharo: 'filharo',
  filhava: 'filhava',
  filheis: 'filheis',
  filhemo: 'filhemo',
  filhoes: 'filhões',
  filhona: 'filhona',
  filhota: 'filhota',
  filhote: 'filhote',
  filiada: 'filiada',
  filiado: 'filiado',
  filiais: 'filiais',
  filiamo: 'filiamo',
  filiara: 'filiara',
  filiava: 'filiava',
  filical: 'filical',
  filicas: 'fílicas',
  filicea: 'filícea',
  filidia: 'filídia',
  filidio: 'filídio',
  filidor: 'filidor',
  filidro: 'filidro',
  filieis: 'filieis',
  filiemo: 'filiemo',
  filinas: 'filinas',
  filines: 'filines',
  filipai: 'filipai',
  filipam: 'filipam',
  filipar: 'filipar',
  filipas: 'filipas',
  filipei: 'filipei',
  filipem: 'filipem',
  filipes: 'filipes',
  filipia: 'filípia',
  filipio: 'filípio',
  filipou: 'filipou',
  filirea: 'filírea',
  filitas: 'filitas',
  filites: 'filites',
  filitos: 'filitos',
  filmaco: 'filmaço',
  filmada: 'filmada',
  filmado: 'filmado',
  filmais: 'filmais',
  filmamo: 'filmamo',
  filmara: 'filmara',
  filmava: 'filmava',
  filmeco: 'filmeco',
  filmeis: 'filmeis',
  filmemo: 'filmemo',
  filmete: 'filmete',
  filmica: 'fílmica',
  filmico: 'fílmico',
  filmoes: 'filmões',
  filobea: 'filóbea',
  filobio: 'filóbio',
  filodes: 'filodes',
  filodia: 'filódia',
  filodio: 'filódio',
  filoela: 'filoela',
  filoide: 'filoide',
  filomas: 'filomas',
  filomia: 'filomia',
  filonar: 'filonar',
  filonio: 'filônio',
  filonto: 'filonto',
  filosas: 'filosas',
  filosia: 'filosia',
  filosos: 'filosos',
  filtrai: 'filtrai',
  filtram: 'filtram',
  filtrar: 'filtrar',
  filtras: 'filtras',
  filtrei: 'filtrei',
  filtrem: 'filtrem',
  filtres: 'filtres',
  filtros: 'filtros',
  filtrou: 'filtrou',
  filulas: 'fílulas',
  filuros: 'filuros',
  fimacte: 'fimacte',
  fimanto: 'fimanto',
  fimatas: 'fímatas',
  fimbras: 'fimbras',
  fimbria: 'fímbria',
  fimbrie: 'fimbrie',
  fimbrio: 'fimbrio',
  fimento: 'fimento',
  fimicas: 'fímicas',
  fimicos: 'fímicos',
  fimoses: 'fimoses',
  finacao: 'finação',
  finadao: 'finadão',
  finadas: 'finadas',
  finador: 'finador',
  finados: 'finados',
  finagem: 'finagem',
  finamos: 'finamos',
  financa: 'finança',
  finando: 'finando',
  finanga: 'finanga',
  finante: 'finante',
  finaram: 'finaram',
  finarao: 'finarão',
  finaras: 'finarás',
  finarei: 'finarei',
  finarem: 'finarem',
  finares: 'finares',
  finaria: 'finaria',
  finarmo: 'finarmo',
  finasco: 'finasco',
  finasse: 'finasse',
  finaste: 'finaste',
  finavam: 'finavam',
  finavas: 'finavas',
  finavel: 'finável',
  fincada: 'fincada',
  fincado: 'fincado',
  fincais: 'fincais',
  fincamo: 'fincamo',
  fincara: 'fincara',
  fincava: 'fincava',
  fincoes: 'fincões',
  fincuda: 'fincuda',
  fincudo: 'fincudo',
  findada: 'findada',
  findado: 'findado',
  findais: 'findais',
  findamo: 'findamo',
  findara: 'findara',
  findava: 'findava',
  findeis: 'findeis',
  findemo: 'findemo',
  finemos: 'finemos',
  finense: 'finense',
  finesas: 'finesas',
  fineses: 'fineses',
  finesse: 'finesse',
  finezas: 'finezas',
  finfada: 'finfada',
  finfado: 'finfado',
  finfais: 'finfais',
  finfamo: 'finfamo',
  finfara: 'finfará',
  finfava: 'finfava',
  finfeis: 'finfeis',
  finfemo: 'finfemo',
  finfins: 'finfins',
  fingiam: 'fingiam',
  fingias: 'fingias',
  fingida: 'fingida',
  fingido: 'fingido',
  fingimo: 'fingimo',
  fingira: 'fingira',
  finicas: 'fínicas',
  finicos: 'fínicos',
  fininga: 'fininga',
  fininha: 'fininha',
  fininho: 'fininho',
  finismo: 'finismo',
  finitas: 'finitas',
  finitos: 'finitos',
  finjais: 'finjais',
  finjamo: 'finjamo',
  finoria: 'finória',
  finorio: 'finório',
  finotos: 'finotos',
  finquei: 'finquei',
  finquem: 'finquem',
  finques: 'finques',
  fintada: 'fintada',
  fintado: 'fintado',
  fintais: 'fintais',
  fintamo: 'fintamo',
  fintara: 'fintara',
  fintava: 'fintava',
  finteis: 'finteis',
  fintemo: 'fintemo',
  fintoes: 'fintões',
  finuras: 'finuras',
  fiolhal: 'fiolhal',
  fiolhos: 'fiolhos',
  fiomeis: 'fioméis',
  fionida: 'fiônida',
  fiordes: 'fiordes',
  fiorina: 'fiorina',
  fiorita: 'fiorita',
  fiorite: 'fiorite',
  fioteza: 'fioteza',
  fiotice: 'fiotice',
  fipsias: 'fípsias',
  fiqueis: 'fiqueis',
  fiquemo: 'fiquemo',
  firacos: 'firacos',
  firamos: 'firamos',
  firmada: 'firmada',
  firmado: 'firmado',
  firmais: 'firmais',
  firmamo: 'firmamo',
  firmana: 'firmana',
  firmano: 'firmano',
  firmara: 'firmara',
  firmava: 'firmava',
  firmeis: 'firmeis',
  firmemo: 'firmemo',
  firmeza: 'firmeza',
  firmina: 'firmina',
  firmino: 'firmino',
  firmoes: 'firmões',
  firolas: 'firolas',
  firrada: 'firrada',
  firrado: 'firrado',
  firrais: 'firrais',
  firramo: 'firramo',
  firrara: 'firrará',
  firrava: 'firrava',
  firreis: 'firreis',
  firremo: 'firremo',
  firulas: 'firulas',
  firulis: 'firulis',
  fisacio: 'fisácio',
  fisales: 'fisales',
  fisalia: 'fisália',
  fisalis: 'fisális',
  fisalos: 'físalos',
  fisaros: 'físaros',
  fiscais: 'fiscais',
  fiscela: 'fiscela',
  fiscele: 'fiscele',
  fiscelo: 'fiscelo',
  fiscias: 'físcias',
  fisedra: 'físedra',
  fisemas: 'fisemas',
  fiseses: 'fiseses',
  fisgada: 'fisgada',
  fisgado: 'fisgado',
  fisgais: 'fisgais',
  fisgamo: 'fisgamo',
  fisgara: 'fisgara',
  fisgava: 'fisgava',
  fisguei: 'fisguei',
  fisguem: 'fisguem',
  fisgues: 'fisgues',
  fisicas: 'físicas',
  fisicos: 'físicos',
  fisidea: 'fisídea',
  fisideo: 'fisídeo',
  fisidia: 'fisídia',
  fisiose: 'fisiose',
  fisoide: 'fisoide',
  fisoses: 'fisoses',
  fisseis: 'físseis',
  fissoes: 'fissões',
  fissona: 'fissona',
  fissura: 'fissura',
  fissure: 'fissure',
  fissuro: 'fissuro',
  fistica: 'fística',
  fistico: 'fístico',
  fistora: 'fistora',
  fistras: 'fistras',
  fistula: 'fístula',
  fistule: 'fistule',
  fistulo: 'fistulo',
  fitacas: 'fitaças',
  fitacea: 'fitácea',
  fitaceo: 'fitáceo',
  fitadao: 'fitadão',
  fitadas: 'fitadas',
  fitador: 'fitador',
  fitados: 'fitados',
  fitamos: 'fitamos',
  fitanca: 'fitança',
  fitando: 'fitando',
  fitante: 'fitante',
  fitaram: 'fitaram',
  fitarao: 'fitarão',
  fitaras: 'fitaras',
  fitarei: 'fitarei',
  fitarem: 'fitarem',
  fitares: 'fitares',
  fitaria: 'fitaria',
  fitarmo: 'fitarmo',
  fitasse: 'fitasse',
  fitaste: 'fitaste',
  fitatos: 'fitatos',
  fitavam: 'fitavam',
  fitavas: 'fitavas',
  fitecia: 'fitécia',
  fiteira: 'fiteira',
  fiteiro: 'fiteiro',
  fitelho: 'fitelho',
  fitemos: 'fitemos',
  fiticas: 'fíticas',
  fiticos: 'fíticos',
  fitilha: 'fitilha',
  fitilho: 'fitilho',
  fitinas: 'fitinas',
  fitinha: 'fitinha',
  fitobio: 'fitóbio',
  fitoide: 'fitoide',
  fitonas: 'fitonas',
  fitonia: 'fitônia',
  fitopto: 'fitopto',
  fitoses: 'fitoses',
  fitucai: 'fitucai',
  fitucam: 'fitucam',
  fitucar: 'fitucar',
  fitucas: 'fitucas',
  fitucou: 'fitucou',
  fituque: 'fituque',
  fiuncas: 'fiunças',
  fiuncos: 'fiuncos',
  fivelao: 'fivelão',
  fivelas: 'fivelas',
  fixacao: 'fixação',
  fixadao: 'fixadão',
  fixadas: 'fixadas',
  fixador: 'fixador',
  fixados: 'fixados',
  fixagem: 'fixagem',
  fixamos: 'fixamos',
  fixando: 'fixando',
  fixante: 'fixante',
  fixaram: 'fixaram',
  fixarao: 'fixarão',
  fixaras: 'fixaras',
  fixarei: 'fixarei',
  fixarem: 'fixarem',
  fixares: 'fixares',
  fixaria: 'fixaria',
  fixarmo: 'fixarmo',
  fixasse: 'fixasse',
  fixaste: 'fixaste',
  fixavam: 'fixavam',
  fixavas: 'fixavas',
  fixavel: 'fixável',
  fixemos: 'fixemos',
  fixezes: 'fixezes',
  fixidez: 'fixidez',
  fixismo: 'fixismo',
  fixista: 'fixista',
  fixuras: 'fixuras',
  fizemos: 'fizemos',
  fizeram: 'fizeram',
  fizeras: 'fizeras',
  fizerem: 'fizerem',
  fizeres: 'fizeres',
  fizermo: 'fizermo',
  fizesse: 'fizesse',
  fizeste: 'fizeste',
  flabela: 'flabela',
  flabele: 'flabele',
  flabelo: 'flabelo',
  flaches: 'flaches',
  flacida: 'flácida',
  flacido: 'flácido',
  flaflai: 'flaflai',
  flaflam: 'flaflam',
  flaflar: 'flaflar',
  flaflas: 'flaflas',
  flaflei: 'flaflei',
  flaflem: 'flaflem',
  flafles: 'flafles',
  flaflou: 'flaflou',
  flagela: 'flagela',
  flagele: 'flagele',
  flagelo: 'flagelo',
  flagrai: 'flagrai',
  flagram: 'flagram',
  flagrar: 'flagrar',
  flagras: 'flagras',
  flagrei: 'flagrei',
  flagrem: 'flagrem',
  flagres: 'flagres',
  flagrou: 'flagrou',
  flainai: 'flainai',
  flainam: 'flainam',
  flainar: 'flainar',
  flainas: 'flainas',
  flainea: 'flaineá',
  flainei: 'flainei',
  flainem: 'flainem',
  flaines: 'flaines',
  flainos: 'flainos',
  flainou: 'flainou',
  flajole: 'flajolé',
  flamada: 'flamada',
  flamado: 'flamado',
  flamais: 'flamais',
  flamamo: 'flamamo',
  flamara: 'flamara',
  flamava: 'flamava',
  flambai: 'flambai',
  flambam: 'flambam',
  flambar: 'flambar',
  flambas: 'flambas',
  flambea: 'flambeá',
  flambei: 'flambei',
  flambem: 'flambem',
  flambes: 'flambes',
  flambou: 'flambou',
  flameai: 'flameai',
  flamear: 'flamear',
  flameas: 'flâmeas',
  flameei: 'flameei',
  flameia: 'flameia',
  flameie: 'flameie',
  flameio: 'flameio',
  flameis: 'flameis',
  flameja: 'flameja',
  flameje: 'flameje',
  flamejo: 'flamejo',
  flamemo: 'flamemo',
  flameos: 'flâmeos',
  flameou: 'flameou',
  flamine: 'flâmine',
  flamoes: 'flamões',
  flamula: 'flâmula',
  flamule: 'flamule',
  flamulo: 'flamulo',
  flanada: 'flanada',
  flanado: 'flanado',
  flanais: 'flanais',
  flanamo: 'flanamo',
  flanara: 'flanará',
  flanate: 'flanate',
  flanava: 'flanava',
  flancos: 'flancos',
  flandes: 'flandes',
  flandre: 'flandre',
  flaneis: 'flaneis',
  flanela: 'flanela',
  flanemo: 'flanemo',
  flanges: 'flanges',
  flartai: 'flartai',
  flartam: 'flartam',
  flartar: 'flartar',
  flartas: 'flartas',
  flartei: 'flartei',
  flartem: 'flartem',
  flartes: 'flartes',
  flartou: 'flartou',
  flashes: 'flashes',
  flatosa: 'flatosa',
  flatoso: 'flatoso',
  flautai: 'flautai',
  flautam: 'flautam',
  flautar: 'flautar',
  flautas: 'flautas',
  flautea: 'flauteá',
  flautei: 'flautei',
  flautem: 'flautem',
  flautes: 'flautes',
  flautim: 'flautim',
  flautou: 'flautou',
  flavona: 'flavona',
  flebeis: 'flébeis',
  flebica: 'flébica',
  flebico: 'flébico',
  flebina: 'flebina',
  flebite: 'flebite',
  flechai: 'flechai',
  flechal: 'flechal',
  flecham: 'flecham',
  flechar: 'flechar',
  flechas: 'flechas',
  flechei: 'flechei',
  flechem: 'flechem',
  fleches: 'fleches',
  flechou: 'flechou',
  flectam: 'flectam',
  flectas: 'flectas',
  flectem: 'flectem',
  flectes: 'flectes',
  flectia: 'flectia',
  flectir: 'flectir',
  flectis: 'flectis',
  flectiu: 'flectiu',
  flector: 'flector',
  flegmao: 'flegmão',
  flegmas: 'flegmas',
  fleimao: 'fleimão',
  fleimas: 'fleimas',
  fleinas: 'fleínas',
  flentes: 'flentes',
  fleobio: 'fleóbio',
  fleolos: 'fléolos',
  fleomea: 'fleômea',
  fleomeo: 'fleômeo',
  fleomis: 'fléomis',
  flertai: 'flertai',
  flertam: 'flertam',
  flertar: 'flertar',
  flertas: 'flertas',
  flertei: 'flertei',
  flertem: 'flertem',
  flertes: 'flertes',
  flertou: 'flertou',
  fletaco: 'fletaço',
  fletiam: 'fletiam',
  fletias: 'fletias',
  fletida: 'fletida',
  fletido: 'fletido',
  fletira: 'fletirá',
  fletora: 'fletora',
  fleugma: 'fleugma',
  fleumao: 'fleumão',
  fleumas: 'fleumas',
  flexeis: 'fléxeis',
  flexiva: 'flexiva',
  flexivo: 'flexivo',
  flexoes: 'flexões',
  flexona: 'flexona',
  flexora: 'flexora',
  flexuai: 'flexuai',
  flexuam: 'flexuam',
  flexuar: 'flexuar',
  flexuas: 'flexuas',
  flexuei: 'flexuei',
  flexuem: 'flexuem',
  flexues: 'flexues',
  flexuou: 'flexuou',
  flexura: 'flexura',
  fliasio: 'fliásio',
  flieias: 'flieias',
  flimada: 'flimada',
  flimado: 'flimado',
  flimais: 'flimais',
  flimamo: 'flimamo',
  flimara: 'flimará',
  flimava: 'flimava',
  flimeis: 'flimeis',
  flimemo: 'flimemo',
  flipada: 'flipada',
  flipado: 'flipado',
  flipais: 'flipais',
  flipamo: 'flipamo',
  flipara: 'flipara',
  flipava: 'flipava',
  flipeis: 'flipeis',
  flipemo: 'flipemo',
  flipper: 'flipper',
  flirtai: 'flirtai',
  flirtam: 'flirtam',
  flirtar: 'flirtar',
  flirtas: 'flirtas',
  flirtei: 'flirtei',
  flirtem: 'flirtem',
  flirtes: 'flirtes',
  flirtou: 'flirtou',
  flitada: 'flitada',
  flitado: 'flitado',
  flitais: 'flitais',
  flitamo: 'flitamo',
  flitara: 'flitará',
  flitava: 'flitava',
  fliteis: 'fliteis',
  flitemo: 'flitemo',
  flocada: 'flocada',
  flocado: 'flocado',
  flocais: 'flocais',
  flocamo: 'flocamo',
  flocara: 'flocará',
  flocava: 'flocava',
  flochas: 'flochas',
  flocona: 'floconá',
  flocone: 'flocone',
  flocono: 'flocono',
  flocosa: 'flocosa',
  flocoso: 'flocoso',
  flocula: 'flocula',
  flocule: 'flocule',
  floculo: 'flóculo',
  floemas: 'floemas',
  flogosa: 'flogosá',
  flogose: 'flogose',
  flogoso: 'flogoso',
  flomide: 'flômide',
  floquei: 'floquei',
  floquem: 'floquem',
  floques: 'floques',
  florada: 'florada',
  florado: 'florado',
  florais: 'florais',
  floramo: 'floramo',
  florara: 'florara',
  florava: 'florava',
  floreai: 'floreai',
  floreal: 'floreal',
  florear: 'florear',
  floreas: 'flóreas',
  floreei: 'floreei',
  floreia: 'floreia',
  floreie: 'floreie',
  floreio: 'floreio',
  floreis: 'floreis',
  floreja: 'floreja',
  floreje: 'floreje',
  florejo: 'florejo',
  floremo: 'floremo',
  florena: 'florena',
  floreos: 'flóreos',
  floreou: 'floreou',
  floreta: 'floreta',
  florete: 'florete',
  floriam: 'floriam',
  florias: 'florias',
  florido: 'florido',
  florimo: 'florimo',
  florina: 'florina',
  florins: 'florins',
  florira: 'florira',
  floroes: 'florões',
  florona: 'florona',
  florosa: 'florosa',
  florose: 'florose',
  florula: 'flórula',
  flotada: 'flotada',
  flotado: 'flotado',
  flotais: 'flotais',
  flotamo: 'flotamo',
  flotara: 'flotara',
  flotava: 'flotava',
  floteis: 'floteis',
  flotemo: 'flotemo',
  floucha: 'floucha',
  floxina: 'floxina',
  fluagem: 'fluagem',
  fluamos: 'fluamos',
  fluatos: 'fluatos',
  fluente: 'fluente',
  flugeas: 'flúgeas',
  fluidai: 'fluidai',
  fluidal: 'fluidal',
  fluidam: 'fluidam',
  fluidao: 'fluidão',
  fluidar: 'fluidar',
  fluidas: 'fluidas',
  fluidei: 'fluidei',
  fluidem: 'fluidem',
  fluides: 'fluides',
  fluidez: 'fluidez',
  fluidor: 'fluidor',
  fluidos: 'fluidos',
  fluidou: 'fluidou',
  fluieis: 'fluíeis',
  fluimos: 'fluímos',
  fluindo: 'fluindo',
  fluiram: 'fluíram',
  fluirao: 'fluirão',
  fluiras: 'fluirás',
  fluirei: 'fluirei',
  fluirem: 'fluírem',
  fluires: 'fluíres',
  fluiria: 'fluiria',
  fluirmo: 'fluirmo',
  fluisse: 'fluísse',
  fluiste: 'fluíste',
  flumens: 'flúmens',
  fluorai: 'fluorai',
  fluoram: 'fluoram',
  fluorar: 'fluorar',
  fluoras: 'fluoras',
  fluorei: 'fluorei',
  fluorem: 'fluorem',
  fluores: 'fluores',
  fluorol: 'fluorol',
  fluorou: 'fluorou',
  flustra: 'flustra',
  flutter: 'flutter',
  flutuai: 'flutuai',
  flutuam: 'flutuam',
  flutuar: 'flutuar',
  flutuas: 'flutuas',
  flutuei: 'flutuei',
  flutuem: 'flutuem',
  flutues: 'flutues',
  flutuos: 'flútuos',
  flutuou: 'flutuou',
  fluvial: 'fluvial',
  fluvico: 'flúvico',
  fluxoes: 'fluxões',
  flyback: 'flyback',
  fobadao: 'fobadão',
  fobadas: 'fobadas',
  fobador: 'fobador',
  fobados: 'fobados',
  fobamos: 'fobamos',
  fobando: 'fobando',
  fobante: 'fobante',
  fobaram: 'fobaram',
  fobarao: 'fobarão',
  fobaras: 'fobaras',
  fobarei: 'fobarei',
  fobarem: 'fobarem',
  fobares: 'fobares',
  fobaria: 'fobaria',
  fobarmo: 'fobarmo',
  fobasse: 'fobasse',
  fobaste: 'fobaste',
  fobavam: 'fobavam',
  fobavas: 'fobavas',
  fobemos: 'fobemos',
  fobicas: 'fóbicas',
  fobicos: 'fóbicos',
  fobismo: 'fobismo',
  fobista: 'fobista',
  fobitai: 'fobitai',
  fobitam: 'fobitam',
  fobitar: 'fobitar',
  fobitas: 'fobitas',
  fobitei: 'fobitei',
  fobitem: 'fobitem',
  fobites: 'fobites',
  fobitou: 'fobitou',
  fobocas: 'fobocas',
  focacao: 'focação',
  focacea: 'focácea',
  focaceo: 'focáceo',
  focadao: 'focadão',
  focadas: 'focadas',
  focador: 'foçador',
  focados: 'focados',
  focagem: 'focagem',
  focales: 'focales',
  focamos: 'focamos',
  focando: 'focando',
  focante: 'focante',
  focaram: 'focaram',
  focarao: 'focarão',
  focaras: 'focaras',
  focarei: 'focarei',
  focarem: 'focarem',
  focares: 'focares',
  focaria: 'focaria',
  focarmo: 'focarmo',
  focasse: 'focasse',
  focaste: 'focaste',
  focavam: 'focavam',
  focavas: 'focavas',
  foceias: 'foceias',
  focemos: 'focemos',
  focenas: 'focenas',
  focense: 'focense',
  focidas: 'fócidas',
  focidea: 'focídea',
  focideo: 'focídeo',
  focinea: 'focínea',
  focineo: 'focíneo',
  focinha: 'focinha',
  focinhe: 'focinhe',
  focinho: 'focinho',
  fodamos: 'fodamos',
  fodedor: 'fodedor',
  fodemos: 'fodemos',
  fodendo: 'fodendo',
  foderam: 'foderam',
  foderao: 'foderão',
  foderas: 'foderas',
  foderei: 'foderei',
  foderem: 'foderem',
  foderes: 'foderes',
  foderia: 'foderia',
  fodermo: 'fodermo',
  fodesse: 'fodesse',
  fodeste: 'fodeste',
  fodidao: 'fodidão',
  fodidas: 'fodidas',
  fodidos: 'fodidos',
  fodieis: 'fodíeis',
  fodinha: 'fodinha',
  fodipir: 'fodipir',
  fodrina: 'fodrina',
  foeiras: 'foeiras',
  foezoes: 'fõezões',
  fofadao: 'fofadão',
  fofadas: 'fofadas',
  fofador: 'fofador',
  fofados: 'fofados',
  fofamos: 'fofamos',
  fofanas: 'fofanas',
  fofando: 'fofando',
  fofante: 'fofante',
  fofaram: 'fofaram',
  fofarao: 'fofarão',
  fofaras: 'fofarás',
  fofarei: 'fofarei',
  fofarem: 'fofarem',
  fofares: 'fofares',
  fofaria: 'fofaria',
  fofarmo: 'fofarmo',
  fofasse: 'fofasse',
  fofaste: 'fofaste',
  fofavam: 'fofavam',
  fofavas: 'fofavas',
  fofemos: 'fofemos',
  fofices: 'fofices',
  fofinha: 'fofinha',
  fofinho: 'fofinho',
  fofocai: 'fofocai',
  fofocam: 'fofocam',
  fofocar: 'fofocar',
  fofocas: 'fofocas',
  fofocou: 'fofocou',
  fofolis: 'fofolis',
  fofonas: 'fofonas',
  fofoque: 'fofoque',
  fofuras: 'fofuras',
  fogacas: 'fogaças',
  fogacea: 'fogácea',
  fogacha: 'fogachá',
  fogache: 'fogache',
  fogacho: 'fogacho',
  fogagem: 'fogagem',
  fogalha: 'fogalha',
  fogares: 'fogares',
  fogareu: 'fogaréu',
  fogoios: 'fogoiós',
  fogones: 'fogones',
  fogosas: 'fogosas',
  fogosos: 'fogosos',
  fogueai: 'fogueai',
  foguear: 'foguear',
  fogueei: 'fogueei',
  fogueia: 'fogueia',
  fogueie: 'fogueie',
  fogueio: 'fogueio',
  fogueou: 'fogueou',
  foguete: 'foguete',
  fogueto: 'fogueto',
  fogueus: 'foguéus',
  foiaite: 'foiaíte',
  foiaito: 'foiaíto',
  foicada: 'foiçada',
  foicado: 'foiçado',
  foicais: 'foiçais',
  foicamo: 'foiçamo',
  foicara: 'foiçará',
  foicava: 'foiçava',
  foiceai: 'foiceai',
  foicear: 'foicear',
  foiceei: 'foiceei',
  foiceia: 'foiceia',
  foiceie: 'foiceie',
  foiceio: 'foiceio',
  foiceis: 'foiceis',
  foicemo: 'foicemo',
  foiceou: 'foiceou',
  foicoes: 'foições',
  foicona: 'foiçona',
  foismos: 'foísmos',
  foistas: 'foístas',
  foiteza: 'foiteza',
  folacho: 'folacho',
  foladas: 'fóladas',
  folades: 'fólades',
  folares: 'folares',
  folatos: 'folatos',
  folcida: 'fólcida',
  folcoma: 'folcoma',
  foleada: 'foleada',
  foleado: 'foleado',
  foleais: 'foleais',
  foleara: 'foleará',
  foleava: 'foleava',
  folecai: 'folecai',
  folecam: 'folecam',
  folecar: 'folecar',
  folecas: 'folecas',
  folecha: 'folecha',
  folecho: 'folecho',
  folecos: 'folecos',
  folecou: 'folecou',
  folecra: 'folecra',
  folecro: 'folecro',
  foleeis: 'foleeis',
  folegai: 'folegai',
  folegam: 'folegam',
  folegar: 'folegar',
  folegas: 'folegas',
  folegos: 'fôlegos',
  folegou: 'folegou',
  folegue: 'folegue',
  foleiam: 'foleiam',
  foleias: 'foleias',
  foleiem: 'foleiem',
  foleies: 'foleies',
  foleira: 'foleira',
  foleiro: 'foleiro',
  folepos: 'folepos',
  foleque: 'foleque',
  folerca: 'folerca',
  folerpa: 'folerpa',
  folerpe: 'folerpe',
  folerpo: 'folerpo',
  folgada: 'folgada',
  folgado: 'folgado',
  folgais: 'folgais',
  folgamo: 'folgamo',
  folgara: 'folgara',
  folgava: 'folgava',
  folgaza: 'folgazã',
  folgaze: 'folgaze',
  folgazo: 'folgazo',
  folguei: 'folguei',
  folguem: 'folguem',
  folgues: 'folgues',
  folgura: 'folgura',
  folhaco: 'folhaco',
  folhada: 'folhada',
  folhado: 'folhado',
  folhais: 'folhais',
  folhame: 'folhame',
  folhamo: 'folhamo',
  folhara: 'folhará',
  folhato: 'folhato',
  folhava: 'folhava',
  folheai: 'folheai',
  folhear: 'folhear',
  folheca: 'folheca',
  folheco: 'folheco',
  folhedo: 'folhedo',
  folheei: 'folheei',
  folheia: 'folheia',
  folheie: 'folheie',
  folheio: 'folheio',
  folheis: 'folheis',
  folhemo: 'folhemo',
  folheou: 'folheou',
  folhepo: 'folhepo',
  folheta: 'folheta',
  folheto: 'folheto',
  folhico: 'folhiço',
  folhido: 'folhido',
  folhoes: 'folhões',
  folhosa: 'folhosa',
  folhoso: 'folhoso',
  folhuda: 'folhuda',
  folhudo: 'folhudo',
  foliada: 'foliada',
  foliado: 'foliado',
  foliais: 'foliais',
  foliamo: 'foliamo',
  foliara: 'foliara',
  foliava: 'foliava',
  folicos: 'fólicos',
  folidia: 'folídia',
  folidol: 'folidol',
  folieis: 'folieis',
  foliemo: 'foliemo',
  folilho: 'folilho',
  folioes: 'foliões',
  foliolo: 'folíolo',
  foliona: 'foliona',
  foliosa: 'foliosa',
  folioso: 'folioso',
  foliota: 'foliota',
  folipas: 'folipas',
  folipos: 'folipos',
  folosas: 'folosas',
  folotes: 'folotes',
  fomenta: 'fomenta',
  fomente: 'fomente',
  fomento: 'fomento',
  fominha: 'fominha',
  fomites: 'fômites',
  fomopse: 'fomopse',
  fomutim: 'fomutim',
  fonacao: 'fonação',
  fonadas: 'fonadas',
  fonador: 'fonador',
  fonados: 'fonados',
  fonasco: 'fonasco',
  foncoes: 'fonções',
  foncona: 'fonçona',
  fondant: 'fondant',
  fondica: 'fondiça',
  fondues: 'fondues',
  fonecas: 'fonecas',
  fonemas: 'fonemas',
  fonfona: 'fonfona',
  fonfone: 'fonfone',
  fonfono: 'fonfono',
  fonfons: 'fonfons',
  fonicas: 'fônicas',
  fonices: 'fonices',
  fonicos: 'fônicos',
  fonisca: 'fonisca',
  fonismo: 'fonismo',
  fonista: 'fonista',
  fonolas: 'fonolas',
  fontais: 'fontais',
  fontana: 'fontana',
  fontano: 'fontano',
  fonteca: 'fonteca',
  fontela: 'fontela',
  fontino: 'fontino',
  footing: 'footing',
  foqueai: 'foqueai',
  foquear: 'foquear',
  foqueei: 'foqueei',
  foqueia: 'foqueia',
  foqueie: 'foqueie',
  foqueio: 'foqueio',
  foqueis: 'foqueis',
  foquemo: 'foquemo',
  foqueou: 'foqueou',
  foragem: 'foragem',
  forages: 'forages',
  foragia: 'foragia',
  foragir: 'foragir',
  foragis: 'foragis',
  foragiu: 'foragiu',
  forajam: 'forajam',
  forajas: 'forajas',
  foramen: 'forâmen',
  forames: 'forames',
  foramos: 'fôramos',
  foranea: 'forânea',
  foraneo: 'forâneo',
  forania: 'forania',
  foranto: 'foranto',
  foraria: 'foraria',
  foratas: 'foratas',
  forbeia: 'forbeia',
  forcada: 'forçada',
  forcado: 'forçado',
  forcais: 'forçais',
  forcamo: 'forçamo',
  forcara: 'forçara',
  forcava: 'forçava',
  forceis: 'forceis',
  forceja: 'forceja',
  forceje: 'forceje',
  forcejo: 'forcejo',
  forcemo: 'forcemo',
  forceps: 'fórceps',
  forcing: 'forcing',
  forcipe: 'fórcipe',
  forcoes: 'forcões',
  forcona: 'forçona',
  forcosa: 'forçosa',
  forcoso: 'forçoso',
  forcuda: 'forçuda',
  forcudo: 'forçudo',
  forcura: 'forçura',
  fordeca: 'fordeca',
  fordeco: 'fordeco',
  forecas: 'forecas',
  forecio: 'forécio',
  foreira: 'foreira',
  foreiro: 'foreiro',
  forense: 'forense',
  foreses: 'foreses',
  foresia: 'forésia',
  forfait: 'forfait',
  forfeit: 'forfeit',
  forfete: 'forfete',
  forfice: 'fórfice',
  forgoes: 'forgões',
  forgoso: 'forgoso',
  forguis: 'forguis',
  foricas: 'fóricas',
  foricos: 'fóricos',
  foridas: 'fóridas',
  foridea: 'forídea',
  forideo: 'forídeo',
  forinte: 'forinte',
  forints: 'forints',
  forjada: 'forjada',
  forjado: 'forjado',
  forjais: 'forjais',
  forjamo: 'forjamo',
  forjara: 'forjara',
  forjava: 'forjava',
  forjeis: 'forjeis',
  forjemo: 'forjemo',
  forjica: 'forjica',
  forjico: 'forjico',
  forjoco: 'forjoco',
  forlana: 'forlana',
  forleca: 'forleca',
  formada: 'formada',
  formado: 'formado',
  formais: 'formais',
  formamo: 'formamo',
  formara: 'formara',
  formata: 'formata',
  formate: 'formate',
  formato: 'formato',
  formava: 'formava',
  formeis: 'formeis',
  formemo: 'formemo',
  formena: 'formena',
  formeno: 'formeno',
  formica: 'formica',
  formico: 'fórmico',
  formiga: 'formiga',
  formigo: 'formigo',
  formila: 'formila',
  formilo: 'formilo',
  formina: 'formina',
  formios: 'fórmios',
  formoes: 'formões',
  formois: 'formóis',
  formola: 'formola',
  formole: 'formole',
  formolo: 'formolo',
  formona: 'formona',
  formosa: 'formosa',
  formose: 'formose',
  formoso: 'formoso',
  formula: 'fórmula',
  formule: 'formule',
  formulo: 'formulo',
  fornaca: 'fornaça',
  fornaco: 'fornaço',
  fornada: 'fornada',
  fornais: 'fornais',
  fornamo: 'fornamo',
  forneai: 'forneai',
  fornear: 'fornear',
  forneca: 'forneça',
  fornece: 'fornece',
  forneci: 'forneci',
  forneco: 'forneco',
  forneei: 'forneei',
  forneia: 'forneia',
  forneie: 'forneie',
  forneio: 'forneio',
  forneja: 'forneja',
  forneje: 'forneje',
  fornejo: 'fornejo',
  forneou: 'forneou',
  forniam: 'forniam',
  fornias: 'fornias',
  fornica: 'fornica',
  fornice: 'fórnice',
  fornico: 'fornico',
  fornida: 'fornida',
  fornido: 'fornido',
  forniga: 'fornigá',
  fornigo: 'fornigo',
  fornimo: 'fornimo',
  fornira: 'fornira',
  fornizo: 'fornizo',
  forodao: 'forodão',
  forodon: 'foródon',
  foroias: 'foroias',
  foronas: 'foronas',
  forquea: 'forqueá',
  forquei: 'forquei',
  forquem: 'forquem',
  forques: 'forques',
  forrada: 'forrada',
  forrado: 'forrado',
  forrais: 'forrais',
  forraja: 'forrajá',
  forraje: 'forraje',
  forrajo: 'forrajo',
  forramo: 'forramo',
  forrara: 'forrara',
  forrava: 'forrava',
  forrear: 'forrear',
  forreca: 'forreca',
  forreis: 'forreis',
  forreja: 'forreja',
  forreje: 'forreje',
  forrejo: 'forrejo',
  forremo: 'forremo',
  forreta: 'forreta',
  forrica: 'forrica',
  forrico: 'forrico',
  forroia: 'forroia',
  forsina: 'forsina',
  fortaca: 'fortaçá',
  fortace: 'fortace',
  fortaco: 'fortaço',
  forteza: 'forteza',
  fortins: 'fortins',
  fortoes: 'fortões',
  fortona: 'fortona',
  fortran: 'fortran',
  fortuna: 'fortuna',
  fortune: 'fortune',
  fortuno: 'fortuno',
  fortuns: 'fortuns',
  forward: 'forward',
  foscada: 'foscada',
  foscado: 'foscado',
  foscais: 'foscais',
  foscamo: 'foscamo',
  foscara: 'foscara',
  foscava: 'foscava',
  fosfais: 'fosfais',
  fosfata: 'fosfata',
  fosfate: 'fosfate',
  fosfato: 'fosfato',
  fosfena: 'fosfena',
  fosfeno: 'fosfeno',
  fosfeto: 'fosfeto',
  fosfina: 'fosfina',
  fosfito: 'fosfito',
  fosfora: 'fosfora',
  fosfore: 'fosfore',
  fosforo: 'fósforo',
  fosgeno: 'fósgeno',
  fosmeas: 'fósmeas',
  fosmeos: 'fósmeos',
  fosquea: 'fosqueá',
  fosquei: 'fosquei',
  fosquem: 'fosquem',
  fosques: 'fosques',
  fossada: 'fossada',
  fossado: 'fossado',
  fossais: 'fossais',
  fossamo: 'fossamo',
  fossara: 'fossará',
  fossava: 'fossava',
  fosseis: 'fósseis',
  fossemo: 'fossemo',
  fosseta: 'fosseta',
  fossete: 'fossete',
  fossoes: 'fossões',
  fossona: 'fossona',
  fossula: 'fóssula',
  fotadas: 'fotadas',
  fotados: 'fotados',
  foteada: 'foteada',
  foteado: 'foteado',
  foteais: 'foteais',
  foteara: 'foteará',
  foteava: 'foteava',
  foteeis: 'foteeis',
  foteiam: 'foteiam',
  foteias: 'foteias',
  foteiem: 'foteiem',
  foteies: 'foteies',
  foteita: 'foteíta',
  foteite: 'foteíte',
  fotenos: 'fotenos',
  foticas: 'fóticas',
  foticon: 'fóticon',
  foticos: 'fóticos',
  fotinea: 'fotínea',
  fotineo: 'fotíneo',
  fotinho: 'fotinho',
  fotinia: 'fotínia',
  fotismo: 'fotismo',
  fotista: 'fotista',
  fotonio: 'fotônio',
  foucada: 'fouçada',
  foucado: 'fouçado',
  foucais: 'fouçais',
  foucamo: 'fouçamo',
  foucara: 'fouçará',
  foucava: 'fouçava',
  fouceai: 'fouceai',
  foucear: 'foucear',
  fouceei: 'fouceei',
  fouceia: 'fouceia',
  fouceie: 'fouceie',
  fouceio: 'fouceio',
  fouceis: 'fouceis',
  foucemo: 'foucemo',
  fouceou: 'fouceou',
  foucoes: 'fouções',
  foucona: 'fouçona',
  fovelho: 'fovelho',
  fovente: 'fovente',
  foveola: 'fovéola',
  fovilas: 'fovilas',
  fovismo: 'fovismo',
  fovista: 'fovista',
  fovocos: 'fovocos',
  foxanto: 'foxanto',
  foxidas: 'fóxidas',
  foxidea: 'foxídea',
  foxideo: 'foxídeo',
  foxinos: 'foxinos',
  fracada: 'fracada',
  fracado: 'fracado',
  fracais: 'fracais',
  fracamo: 'fracamo',
  fracara: 'fracara',
  fracava: 'fracava',
  fracoes: 'frações',
  fracola: 'fracola',
  fracona: 'fracona',
  fracote: 'fracote',
  fractal: 'fractal',
  fracura: 'fracura',
  fradaco: 'fradaço',
  fradada: 'fradada',
  fradado: 'fradado',
  fradais: 'fradais',
  fradamo: 'fradamo',
  fradara: 'fradará',
  fradava: 'fradava',
  fradeco: 'fradeco',
  fradeis: 'fradeis',
  fradeja: 'fradeja',
  fradeje: 'fradeje',
  fradejo: 'fradejo',
  frademo: 'frademo',
  fradete: 'fradete',
  fradice: 'fradice',
  fragada: 'fragada',
  fragado: 'fragadô',
  fragaia: 'fragaia',
  fragais: 'fragais',
  fragata: 'fragata',
  frageis: 'frágeis',
  fragmas: 'fragmas',
  fragoar: 'fragoar',
  fragoes: 'fragões',
  fragora: 'fragora',
  fragore: 'fragore',
  fragoro: 'fragoro',
  fragosa: 'fragosa',
  fragoso: 'fragoso',
  fraguai: 'fraguai',
  fraguam: 'fráguam',
  fraguar: 'fraguar',
  fraguas: 'fráguas',
  fraguea: 'fragueá',
  fraguei: 'fraguei',
  fraguem: 'fráguem',
  fragues: 'frágues',
  fragula: 'frágula',
  fraguou: 'fraguou',
  fragura: 'fragura',
  frainel: 'frainel',
  fraires: 'fraires',
  fraitas: 'fraitas',
  frajola: 'frajola',
  fraldai: 'fraldai',
  fraldam: 'fraldam',
  fraldao: 'fraldão',
  fraldar: 'fraldar',
  fraldas: 'fraldas',
  fraldea: 'fraldeá',
  fraldei: 'fraldei',
  fraldem: 'fraldem',
  fraldes: 'fraldes',
  fraldou: 'fraldou',
  frameas: 'frâmeas',
  francas: 'franças',
  francea: 'franceá',
  frances: 'francês',
  francia: 'frância',
  francio: 'frâncio',
  franciu: 'franciú',
  francoa: 'frâncoa',
  francos: 'francos',
  frandes: 'frandes',
  frandil: 'frandil',
  frandis: 'frandis',
  frangam: 'frangam',
  frangao: 'frangão',
  frangas: 'frangas',
  frangei: 'frangei',
  frangem: 'frangem',
  franger: 'franger',
  franges: 'franges',
  frangeu: 'frangeu',
  frangia: 'frangia',
  frangir: 'frangir',
  frangis: 'frangis',
  frangiu: 'frangiu',
  frangos: 'frangos',
  frangue: 'frangue',
  franjai: 'franjai',
  franjal: 'franjal',
  franjam: 'franjam',
  franjao: 'franjão',
  franjar: 'franjar',
  franjas: 'franjas',
  franjea: 'franjeá',
  franjei: 'franjei',
  franjem: 'franjem',
  franjes: 'franjes',
  franjou: 'franjou',
  franque: 'franque',
  franzam: 'franzam',
  franzas: 'franzas',
  franzem: 'franzem',
  franzes: 'franzes',
  franzia: 'franzia',
  franzir: 'franzir',
  franzis: 'franzis',
  franziu: 'franziu',
  fraquea: 'fraqueá',
  fraquei: 'fraquei',
  fraquem: 'fraquem',
  fraques: 'fraques',
  frasais: 'frasais',
  frascal: 'frascal',
  frascas: 'frascas',
  frascos: 'frascos',
  fraseai: 'fraseai',
  frasear: 'frasear',
  fraseei: 'fraseei',
  fraseia: 'fraseia',
  fraseie: 'fraseie',
  fraseio: 'fraseio',
  fraseou: 'fraseou',
  frasica: 'frásica',
  frasico: 'frásico',
  frataxo: 'frataxo',
  fratria: 'fratria',
  fratura: 'fratura',
  frature: 'frature',
  fraturo: 'fraturo',
  fraudai: 'fraudai',
  fraudam: 'fraudam',
  fraudar: 'fraudar',
  fraudas: 'fraudas',
  fraudei: 'fraudei',
  fraudem: 'fraudem',
  fraudes: 'fraudes',
  fraudou: 'fraudou',
  frautai: 'frautai',
  frautam: 'frautam',
  frautar: 'frautar',
  frautas: 'frautas',
  frautea: 'frauteá',
  frautei: 'frautei',
  frautem: 'frautem',
  frautes: 'frautes',
  frautou: 'frautou',
  fraxina: 'fraxina',
  fraxino: 'fraxino',
  freadao: 'freadão',
  freadas: 'freadas',
  freador: 'freador',
  freados: 'freados',
  freagem: 'freagem',
  freamos: 'freamos',
  freando: 'freando',
  freante: 'freante',
  frearam: 'frearam',
  frearao: 'frearão',
  frearas: 'frearas',
  frearei: 'frearei',
  frearem: 'frearem',
  freares: 'freares',
  frearia: 'frearia',
  frearmo: 'frearmo',
  freasse: 'freasse',
  freaste: 'freaste',
  freavam: 'freavam',
  freavas: 'freavas',
  frechai: 'frechai',
  frechal: 'frechal',
  frecham: 'frecham',
  frechar: 'frechar',
  frechas: 'frechas',
  frechei: 'frechei',
  frechem: 'frechem',
  freches: 'freches',
  frechou: 'frechou',
  freeira: 'freeira',
  freeiro: 'freeiro',
  freemos: 'freemos',
  freezer: 'freezer',
  fregada: 'fregada',
  fregado: 'fregado',
  fregais: 'fregais',
  fregara: 'fregará',
  fregata: 'fregata',
  fregava: 'fregava',
  fregoes: 'fregões',
  fregona: 'fregona',
  freguei: 'freguei',
  freguem: 'freguem',
  fregues: 'freguês',
  freiana: 'freiana',
  freicha: 'freicha',
  freiela: 'freiela',
  freijos: 'freijós',
  freimao: 'freimão',
  freimas: 'freimas',
  freimes: 'freimes',
  freirai: 'freirai',
  freiral: 'freiral',
  freiram: 'freiram',
  freirar: 'freirar',
  freiras: 'freiras',
  freirei: 'freirei',
  freirem: 'freirem',
  freires: 'freires',
  freiria: 'freiria',
  freirou: 'freirou',
  freixal: 'freixal',
  freixos: 'freixos',
  frejuca: 'frejucá',
  frejuco: 'frejuco',
  fremais: 'fremais',
  fremamo: 'fremamo',
  fremeis: 'fremeis',
  frememo: 'frememo',
  fremera: 'fremerá',
  fremiam: 'fremiam',
  fremias: 'fremias',
  fremida: 'fremida',
  fremido: 'fremido',
  fremimo: 'fremimo',
  fremira: 'fremira',
  fremito: 'frêmito',
  frenada: 'frenada',
  frenado: 'frenado',
  frenais: 'frenais',
  frenamo: 'frenamo',
  frenara: 'frenará',
  frenava: 'frenava',
  frendam: 'frendam',
  frendas: 'frendas',
  frendei: 'frendei',
  frendem: 'frendem',
  frender: 'frender',
  frendes: 'frendes',
  frendeu: 'frendeu',
  frendia: 'frendia',
  frendor: 'frendor',
  freneis: 'freneis',
  frenela: 'frenela',
  frenemo: 'frenemo',
  frenesi: 'frenesi',
  frenias: 'frenias',
  frenica: 'frênica',
  frenico: 'frênico',
  frenite: 'frenite',
  frentea: 'frentea',
  frentes: 'frentes',
  frenulo: 'frênulo',
  fresada: 'fresada',
  fresado: 'fresado',
  fresais: 'fresais',
  fresamo: 'fresamo',
  fresara: 'fresara',
  fresava: 'fresava',
  frescai: 'frescai',
  frescal: 'frescal',
  frescam: 'frescam',
  frescao: 'frescão',
  frescar: 'frescar',
  frescas: 'frescas',
  frescor: 'frescor',
  frescos: 'frescos',
  frescou: 'frescou',
  frescum: 'frescum',
  freseis: 'freseis',
  fresemo: 'fresemo',
  fresias: 'frésias',
  fresnel: 'fresnel',
  fresque: 'fresque',
  frestao: 'frestão',
  frestas: 'frestas',
  fretada: 'fretada',
  fretado: 'fretado',
  fretais: 'fretais',
  fretamo: 'fretamo',
  fretara: 'fretara',
  fretava: 'fretava',
  freteis: 'freteis',
  freteja: 'freteja',
  freteje: 'freteje',
  fretejo: 'fretejo',
  fretemo: 'fretemo',
  fretena: 'fretena',
  fretene: 'fretene',
  freteni: 'freteni',
  freteno: 'freteno',
  frevada: 'frevada',
  frevado: 'frevado',
  frevais: 'frevais',
  frevamo: 'frevamo',
  frevara: 'frevará',
  frevava: 'frevava',
  freveis: 'freveis',
  frevemo: 'frevemo',
  friacho: 'friacho',
  friagem: 'friagem',
  friamas: 'friamas',
  friames: 'friames',
  friamos: 'friamos',
  friasco: 'friasco',
  friavel: 'friável',
  friccao: 'fricção',
  fricote: 'fricote',
  frictor: 'frictor',
  frieira: 'frieira',
  frieiro: 'frieiro',
  friense: 'friense',
  friento: 'friento',
  frieria: 'friéria',
  friesta: 'friesta',
  friezas: 'friezas',
  friezes: 'friezes',
  frifrio: 'frifrió',
  frigana: 'frígana',
  frigiam: 'frigiam',
  frigias: 'frigias',
  frigica: 'frígica',
  frigico: 'frígico',
  frigida: 'frígida',
  frigido: 'frígido',
  frigimo: 'frigimo',
  frigios: 'frígios',
  frigira: 'frigira',
  friinha: 'friinha',
  frijais: 'frijais',
  frijamo: 'frijamo',
  frimais: 'frimais',
  frimans: 'frimans',
  frimoes: 'frimões',
  frincha: 'frincha',
  frinche: 'frinche',
  frincho: 'frincho',
  frinela: 'frinela',
  frinida: 'frínida',
  friniea: 'friníea',
  frinina: 'frinina',
  frinios: 'frínios',
  friosas: 'friosas',
  friosos: 'friosos',
  frisada: 'frisada',
  frisado: 'frisado',
  frisais: 'frisais',
  frisamo: 'frisamo',
  frisara: 'frisara',
  frisava: 'frisava',
  frisbee: 'frisbee',
  friseia: 'friseia',
  friseis: 'friseis',
  frisemo: 'frisemo',
  friseus: 'friseus',
  frisias: 'frísias',
  frisica: 'frísica',
  frisico: 'frísico',
  frisios: 'frísios',
  frisoes: 'frisões',
  frisson: 'frisson',
  frisura: 'frisura',
  fritada: 'fritada',
  fritado: 'fritado',
  fritais: 'fritais',
  fritamo: 'fritamo',
  fritara: 'fritara',
  fritava: 'fritava',
  friteis: 'friteis',
  fritemo: 'fritemo',
  fritido: 'fritido',
  fritilo: 'fritilo',
  fritura: 'fritura',
  friumes: 'friúmes',
  friuras: 'friúras',
  frivola: 'frívola',
  frivolo: 'frívolo',
  frixeia: 'frixeia',
  frixeus: 'frixeus',
  frocada: 'frocada',
  frocado: 'frocado',
  frocais: 'frocais',
  frocamo: 'frocamo',
  frocara: 'frocara',
  frocava: 'frocava',
  frogodo: 'frogodô',
  froinas: 'froinas',
  froixai: 'froixai',
  froixam: 'froixam',
  froixar: 'froixar',
  froixas: 'froixas',
  froixei: 'froixei',
  froixel: 'froixel',
  froixem: 'froixem',
  froixes: 'froixes',
  froixos: 'froixos',
  froixou: 'froixou',
  frolada: 'frolada',
  frolado: 'frolado',
  frolais: 'frolais',
  frolamo: 'frolamo',
  frolara: 'frolará',
  frolava: 'frolava',
  froleis: 'froleis',
  frolemo: 'frolemo',
  frolhos: 'frolhos',
  frolido: 'frolido',
  froncas: 'fronças',
  froncil: 'froncil',
  froncis: 'froncis',
  frondai: 'frondai',
  frondam: 'frondam',
  frondar: 'frondar',
  frondas: 'frondas',
  frondea: 'frôndea',
  frondei: 'frondei',
  frondem: 'frondem',
  frondeo: 'frôndeo',
  frondes: 'frondes',
  frondou: 'frondou',
  fronema: 'fronema',
  fronhas: 'fronhas',
  fronhos: 'fronhos',
  fronias: 'frônias',
  fronima: 'frônima',
  fronimo: 'frônimo',
  frontai: 'frontai',
  frontal: 'frontal',
  frontam: 'frontam',
  frontao: 'frontão',
  frontar: 'frontar',
  frontas: 'frontas',
  frontea: 'fronteá',
  frontei: 'frontei',
  frontem: 'frontem',
  frontes: 'frontes',
  frontou: 'frontou',
  froquei: 'froquei',
  froquem: 'froquem',
  froques: 'froques',
  froscas: 'froscas',
  frotola: 'frótola',
  froucas: 'frouças',
  froumas: 'froumas',
  frouvas: 'frouvas',
  frouxai: 'frouxai',
  frouxam: 'frouxam',
  frouxar: 'frouxar',
  frouxas: 'frouxas',
  frouxei: 'frouxei',
  frouxel: 'frouxel',
  frouxem: 'frouxem',
  frouxes: 'frouxes',
  frouxos: 'frouxos',
  frouxou: 'frouxou',
  fruamos: 'fruamos',
  frueira: 'frueira',
  frufrus: 'frufrus',
  frugais: 'frugais',
  fruicao: 'fruição',
  fruidao: 'fruidão',
  fruidas: 'fruídas',
  fruidor: 'fruidor',
  fruidos: 'fruídos',
  fruieis: 'fruíeis',
  fruimos: 'fruímos',
  fruindo: 'fruindo',
  fruiram: 'fruíram',
  fruirao: 'fruirão',
  fruiras: 'fruirás',
  fruirei: 'fruirei',
  fruirem: 'fruírem',
  fruires: 'fruíres',
  fruiria: 'fruiria',
  fruirmo: 'fruirmo',
  fruisse: 'fruísse',
  fruiste: 'fruíste',
  fruitas: 'fruitas',
  fruitos: 'fruitos',
  fruivel: 'fruível',
  frulhai: 'frulhai',
  frulham: 'frulham',
  frulhar: 'frulhar',
  frulhas: 'frulhas',
  frulhei: 'frulhei',
  frulhem: 'frulhem',
  frulhes: 'frulhes',
  frulhos: 'frulhos',
  frulhou: 'frulhou',
  fruncho: 'fruncho',
  fruncos: 'fruncos',
  frunhir: 'frunhir',
  frustai: 'frustai',
  frustam: 'frustam',
  frustar: 'frustar',
  frustas: 'frustas',
  frustea: 'frusteá',
  frustei: 'frustei',
  frustem: 'frustem',
  frustes: 'frustes',
  frustou: 'frustou',
  frustra: 'frustra',
  frustre: 'frustre',
  frustro: 'frustro',
  frutada: 'frutada',
  frutado: 'frutado',
  frutais: 'frutais',
  frutamo: 'frutamo',
  frutara: 'frutará',
  frutava: 'frutava',
  fruteai: 'fruteai',
  frutear: 'frutear',
  frutedo: 'frutedo',
  fruteei: 'fruteei',
  frutega: 'frutegá',
  frutego: 'frutego',
  fruteia: 'fruteia',
  fruteie: 'fruteie',
  fruteio: 'fruteio',
  fruteis: 'fruteis',
  frutemo: 'frutemo',
  fruteou: 'fruteou',
  frutice: 'frútice',
  frutina: 'frutina',
  frutoes: 'frutões',
  frutose: 'frutose',
  frutual: 'frutual',
  frutulo: 'frútulo',
  fruzues: 'fruzuês',
  ftalato: 'ftalato',
  ftalica: 'ftálica',
  ftalico: 'ftálico',
  ftanite: 'ftanite',
  ftanito: 'ftanito',
  ftorico: 'ftórico',
  fuampas: 'fuampas',
  fuaozao: 'fuãozão',
  fuazada: 'fuazada',
  fuazado: 'fuazado',
  fuazona: 'fuãzona',
  fubacas: 'fubacas',
  fubamba: 'fubamba',
  fubanas: 'fubanas',
  fubecai: 'fubecai',
  fubecam: 'fubecam',
  fubecar: 'fubecar',
  fubecas: 'fubecas',
  fubecou: 'fubecou',
  fubeira: 'fubeira',
  fubeiro: 'fubeiro',
  fubento: 'fubento',
  fubeque: 'fubeque',
  fubicas: 'fubicas',
  fubocas: 'fubocas',
  fubuias: 'fubuias',
  fucacea: 'fucácea',
  fucaceo: 'fucáceo',
  fucadao: 'fuçadão',
  fucadas: 'fuçadas',
  fucador: 'fuçador',
  fucados: 'fuçados',
  fucales: 'fucales',
  fucamos: 'fuçamos',
  fucando: 'fuçando',
  fucanis: 'fucanis',
  fucante: 'fuçante',
  fucaram: 'fuçaram',
  fucarao: 'fuçarão',
  fucaras: 'fuçarás',
  fucarei: 'fuçarei',
  fucarem: 'fuçarem',
  fucares: 'fuçares',
  fucaria: 'fuçaria',
  fucarmo: 'fuçarmo',
  fucaros: 'fúcaros',
  fucasse: 'fuçasse',
  fucaste: 'fuçaste',
  fucavam: 'fuçavam',
  fucavas: 'fuçavas',
  fuceira: 'fuceira',
  fucemos: 'fucemos',
  fucense: 'fucense',
  fuchsia: 'fuchsia',
  fucinas: 'fucinas',
  fucoide: 'fucoide',
  fucoses: 'fucoses',
  fucsias: 'fúcsias',
  fucsina: 'fucsina',
  fucsita: 'fucsita',
  fucsite: 'fucsite',
  fucussa: 'fucussa',
  fueirai: 'fueirai',
  fueiram: 'fueiram',
  fueirar: 'fueirar',
  fueiras: 'fueiras',
  fueirei: 'fueirei',
  fueirem: 'fueirem',
  fueires: 'fueires',
  fueiros: 'fueiros',
  fueirou: 'fueirou',
  fugaces: 'fugaces',
  fugadao: 'fugadão',
  fugadas: 'fugadas',
  fugador: 'fugadór',
  fugados: 'fugados',
  fugamos: 'fugamos',
  fuganca: 'fugança',
  fugando: 'fugando',
  fuganga: 'fuganga',
  fugante: 'fugante',
  fugaram: 'fugaram',
  fugarao: 'fugarão',
  fugaras: 'fugaras',
  fugarei: 'fugarei',
  fugarem: 'fugarem',
  fugares: 'fugares',
  fugaria: 'fugaria',
  fugasse: 'fugasse',
  fugaste: 'fugaste',
  fugatos: 'fugatos',
  fugavam: 'fugavam',
  fugavas: 'fugavas',
  fugazes: 'fugazes',
  fugecas: 'fugecas',
  fugemia: 'fugemia',
  fugente: 'fugente',
  fugiaos: 'fugiãos',
  fugicao: 'fugição',
  fugidao: 'fugidão',
  fugidas: 'fugidas',
  fugidia: 'fugidia',
  fugidio: 'fugidio',
  fugidor: 'fugidor',
  fugidos: 'fugidos',
  fugieis: 'fugíeis',
  fugimos: 'fugimos',
  fugindo: 'fugindo',
  fuginte: 'fuginte',
  fugiram: 'fugiram',
  fugirao: 'fugirão',
  fugiras: 'fugiras',
  fugirei: 'fugirei',
  fugirem: 'fugirem',
  fugires: 'fugires',
  fugiria: 'fugiria',
  fugirmo: 'fugirmo',
  fugisse: 'fugisse',
  fugiste: 'fugiste',
  fugueas: 'fúgueas',
  fugueis: 'fugueis',
  fugueos: 'fúgueos',
  fugueta: 'fugueta',
  fuinhas: 'fuinhas',
  fuinhos: 'fuinhos',
  fujamos: 'fujamos',
  fujaria: 'fujaria',
  fujicai: 'fujicai',
  fujicam: 'fujicam',
  fujicar: 'fujicar',
  fujicas: 'fujicas',
  fujicou: 'fujicou',
  fujieis: 'fujíeis',
  fujimos: 'fujimos',
  fujindo: 'fujindo',
  fujique: 'fujique',
  fujiram: 'fujiram',
  fujirao: 'fujirão',
  fujiras: 'fujiras',
  fujirei: 'fujirei',
  fujirem: 'fujirem',
  fujires: 'fujires',
  fujiria: 'fujiria',
  fujirmo: 'fujirmo',
  fujisse: 'fujisse',
  fujista: 'fujista',
  fujiste: 'fujiste',
  fujonas: 'fujonas',
  fulames: 'fulames',
  fulanas: 'fulanas',
  fulanos: 'fulanos',
  fulares: 'fulares',
  fulcito: 'fulcito',
  fulcral: 'fulcral',
  fulcros: 'fulcros',
  fulecai: 'fulecai',
  fulecam: 'fulecam',
  fulecar: 'fulecar',
  fulecas: 'fulecas',
  fulecha: 'fulecha',
  fulecou: 'fulecou',
  fulecra: 'fulecra',
  fuleira: 'fuleira',
  fuleiro: 'fuleiro',
  fulejos: 'fulejos',
  fuleque: 'fuleque',
  fulgiam: 'fulgiam',
  fulgias: 'fulgias',
  fulgida: 'fúlgida',
  fulgido: 'fúlgido',
  fulgimo: 'fulgimo',
  fulgira: 'fulgira',
  fulgora: 'fúlgora',
  fulgura: 'fulgura',
  fulgure: 'fulgure',
  fulguro: 'fúlguro',
  fuliana: 'fuliana',
  fuliano: 'fuliano',
  fulicas: 'fúlicas',
  fuligem: 'fuligem',
  fuligos: 'fuligos',
  fulinha: 'fulinha',
  fulista: 'fulista',
  fuljais: 'fuljais',
  fulmega: 'fulmega',
  fulmens: 'fúlmens',
  fulmica: 'fúlmica',
  fulmico: 'fúlmico',
  fulmina: 'fulmina',
  fulmine: 'fulmine',
  fulmino: 'fulmino',
  fulnios: 'fulniôs',
  fuloada: 'fuloada',
  fuloado: 'fuloado',
  fuloais: 'fuloais',
  fuloamo: 'fuloamo',
  fuloara: 'fuloará',
  fuloava: 'fuloava',
  fuloeis: 'fuloeis',
  fuloemo: 'fuloemo',
  fulosas: 'fulosas',
  fulosos: 'fulosos',
  fulupos: 'fulupos',
  fulveno: 'fulveno',
  fulvida: 'fúlvida',
  fulvido: 'fúlvido',
  fumacai: 'fumaçai',
  fumacam: 'fumaçam',
  fumacar: 'fumaçar',
  fumacas: 'fumaças',
  fumacea: 'fumaceá',
  fumacei: 'fumacei',
  fumacem: 'fumacem',
  fumaces: 'fumaces',
  fumacou: 'fumaçou',
  fumadao: 'fumadão',
  fumadas: 'fumadas',
  fumador: 'fumador',
  fumados: 'fumados',
  fumagem: 'fumagem',
  fumagos: 'fumagos',
  fumamos: 'fumamos',
  fumando: 'fumando',
  fumante: 'fumante',
  fumarai: 'fumarai',
  fumaram: 'fumaram',
  fumarao: 'fumarão',
  fumarar: 'fumarar',
  fumaras: 'fumaras',
  fumarei: 'fumarei',
  fumarem: 'fumarem',
  fumares: 'fumares',
  fumaria: 'fumaria',
  fumarmo: 'fumarmo',
  fumarou: 'fumarou',
  fumasse: 'fumasse',
  fumaste: 'fumaste',
  fumavam: 'fumavam',
  fumavas: 'fumavas',
  fumavel: 'fumável',
  fumbeca: 'fumbeca',
  fumeada: 'fumeada',
  fumeado: 'fumeado',
  fumeais: 'fumeais',
  fumeara: 'fumeará',
  fumeava: 'fumeava',
  fumeeis: 'fumeeis',
  fumegai: 'fumegai',
  fumegam: 'fumegam',
  fumegar: 'fumegar',
  fumegas: 'fumegas',
  fumegou: 'fumegou',
  fumegue: 'fumegue',
  fumeiam: 'fumeiam',
  fumeias: 'fumeias',
  fumeiem: 'fumeiem',
  fumeies: 'fumeies',
  fumeira: 'fumeira',
  fumeiro: 'fumeiro',
  fumelio: 'fumélio',
  fumemos: 'fumemos',
  fumidas: 'fúmidas',
  fumidos: 'fúmidos',
  fumigai: 'fumigai',
  fumigam: 'fumigam',
  fumigar: 'fumigar',
  fumigas: 'fumigas',
  fumigou: 'fumigou',
  fumigue: 'fumigue',
  fumines: 'fuminés',
  fuminho: 'fuminho',
  fumista: 'fumista',
  fumonas: 'fumonas',
  fumosas: 'fumosas',
  fumosos: 'fumosos',
  funadao: 'funadão',
  funadas: 'funadas',
  funador: 'funador',
  funados: 'funados',
  funamos: 'funamos',
  funanas: 'funanás',
  funando: 'funando',
  funante: 'funante',
  funaram: 'funaram',
  funarao: 'funarão',
  funaras: 'funarás',
  funarei: 'funarei',
  funarem: 'funarem',
  funares: 'funares',
  funaria: 'funária',
  funarmo: 'funarmo',
  funasse: 'funasse',
  funaste: 'funaste',
  funavam: 'funavam',
  funavas: 'funavas',
  funchal: 'funchal',
  funchao: 'funchão',
  funchos: 'funchos',
  funcoes: 'funções',
  funcona: 'funçona',
  functor: 'functor',
  fundada: 'fundada',
  fundado: 'fundado',
  fundais: 'fundais',
  fundamo: 'fundamo',
  fundana: 'fundana',
  fundano: 'fundano',
  fundara: 'fundara',
  fundava: 'fundava',
  fundeai: 'fundeai',
  fundear: 'fundear',
  fundeei: 'fundeei',
  fundego: 'fundego',
  fundeia: 'fundeia',
  fundeie: 'fundeie',
  fundeio: 'fundeio',
  fundeis: 'fundeis',
  fundemo: 'fundemo',
  fundeou: 'fundeou',
  fundiam: 'fundiam',
  fundias: 'fundias',
  fundica: 'fúndica',
  fundico: 'fúndico',
  fundida: 'fundida',
  fundido: 'fundido',
  fundimo: 'fundimo',
  funding: 'funding',
  fundira: 'fundira',
  fundoes: 'fundões',
  fundujo: 'fundujo',
  fundula: 'fundula',
  fundulo: 'fúndulo',
  fundura: 'fundura',
  funebre: 'fúnebre',
  funecas: 'funecas',
  funelho: 'funelho',
  funemos: 'funemos',
  funeral: 'funeral',
  funeras: 'fúneras',
  funerea: 'funérea',
  funereo: 'funéreo',
  funesta: 'funesta',
  funeste: 'funeste',
  funesto: 'funesto',
  funfada: 'funfada',
  funfado: 'funfado',
  funfais: 'funfais',
  funfamo: 'funfamo',
  funfane: 'funfane',
  funfara: 'funfará',
  funfava: 'funfava',
  funfeis: 'funfeis',
  funfemo: 'funfemo',
  funfiai: 'funfiai',
  funfiam: 'funfiam',
  funfiar: 'funfiar',
  funfias: 'funfias',
  funfiei: 'funfiei',
  funfiem: 'funfiem',
  funfies: 'funfies',
  funfiou: 'funfiou',
  funfuns: 'funfuns',
  fungada: 'fungada',
  fungado: 'fungado',
  fungaga: 'fungagá',
  fungais: 'fungais',
  fungamo: 'fungamo',
  fungara: 'fungara',
  fungava: 'fungava',
  fungias: 'fúngias',
  fungica: 'fúngica',
  fungico: 'fúngico',
  fungida: 'fúngida',
  fungina: 'fungina',
  fungita: 'fungita',
  fungite: 'fungite',
  fungoes: 'fungões',
  fungosa: 'fungosa',
  fungoso: 'fungoso',
  funguei: 'funguei',
  funguem: 'funguem',
  fungues: 'fungues',
  fungulo: 'fúngulo',
  funhais: 'funhais',
  funhamo: 'funhamo',
  funhiam: 'funhiam',
  funhias: 'funhias',
  funhida: 'funhida',
  funhido: 'funhido',
  funhimo: 'funhimo',
  funhira: 'funhirá',
  funquia: 'fúnquia',
  furacai: 'furacai',
  furacam: 'furacam',
  furacao: 'furacão',
  furacar: 'furacar',
  furacas: 'furacas',
  furacou: 'furacou',
  furadao: 'furadão',
  furadas: 'furadas',
  furador: 'furador',
  furados: 'furados',
  furagem: 'furagem',
  furamar: 'furamar',
  furamos: 'furamos',
  furanas: 'furanas',
  furando: 'furando',
  furanos: 'furanos',
  furante: 'furante',
  furaque: 'furaque',
  furaram: 'furaram',
  furarao: 'furarão',
  furaras: 'furaras',
  furarei: 'furarei',
  furarem: 'furarem',
  furares: 'furares',
  furaria: 'furaria',
  furarmo: 'furarmo',
  furasse: 'furasse',
  furaste: 'furaste',
  furavam: 'furavam',
  furavas: 'furavas',
  furavel: 'furável',
  furazol: 'furazol',
  furcada: 'furcada',
  furcado: 'furcado',
  furcate: 'furcate',
  furcica: 'fúrcica',
  furcico: 'fúrcico',
  furcula: 'fúrcula',
  furdoes: 'furdões',
  furegas: 'furegas',
  furemos: 'furemos',
  furente: 'furente',
  furgoes: 'furgões',
  furiais: 'furiais',
  furiana: 'furiana',
  furiano: 'furiano',
  furicas: 'furicas',
  furicos: 'furicos',
  furilos: 'furilos',
  furinal: 'furinal',
  furinho: 'furinho',
  furiosa: 'furiosa',
  furioso: 'furioso',
  furisca: 'furisca',
  furisco: 'furisco',
  furjoco: 'furjoco',
  furlana: 'furlana',
  furoada: 'furoada',
  furoado: 'furoado',
  furoais: 'furoais',
  furoamo: 'furoamo',
  furoara: 'furoará',
  furoato: 'furoato',
  furoava: 'furoava',
  furoeis: 'furoeis',
  furoemo: 'furoemo',
  furoina: 'furoína',
  furonos: 'furonos',
  furores: 'furores',
  furreca: 'furreca',
  furrete: 'furrete',
  furriai: 'furriai',
  furriam: 'furriam',
  furriar: 'furriar',
  furrias: 'furrias',
  furrica: 'furrica',
  furriei: 'furriei',
  furriel: 'furriel',
  furriem: 'furriem',
  furries: 'furries',
  furriou: 'furriou',
  furtada: 'furtada',
  furtado: 'furtado',
  furtais: 'furtais',
  furtamo: 'furtamo',
  furtara: 'furtara',
  furtava: 'furtava',
  furteis: 'furteis',
  furtemo: 'furtemo',
  furtica: 'furticá',
  furtico: 'furtico',
  furtiva: 'furtiva',
  furtivo: 'furtivo',
  furunfa: 'furunfa',
  furunfe: 'furunfe',
  furunfo: 'furunfo',
  furunga: 'furunga',
  furungo: 'furungo',
  fusadao: 'fusadão',
  fusadas: 'fusadas',
  fusador: 'fusador',
  fusados: 'fusados',
  fusamos: 'fusamos',
  fusanas: 'fusanas',
  fusando: 'fusando',
  fusanos: 'fusanos',
  fusante: 'fusante',
  fusaram: 'fusaram',
  fusarao: 'fusarão',
  fusaras: 'fusarás',
  fusarei: 'fusarei',
  fusarem: 'fusarem',
  fusares: 'fusares',
  fusaria: 'fusária',
  fusario: 'fusário',
  fusarmo: 'fusarmo',
  fusaros: 'fúsaros',
  fusasse: 'fusasse',
  fusaste: 'fusaste',
  fusavam: 'fusavam',
  fusavas: 'fusavas',
  fuscada: 'fuscada',
  fuscado: 'fuscado',
  fuscais: 'fuscais',
  fuscamo: 'fuscamo',
  fuscara: 'fuscará',
  fuscava: 'fuscava',
  fuscina: 'fuscina',
  fuscita: 'fuscita',
  fuscite: 'fuscite',
  fuscito: 'fuscito',
  fuscoes: 'fuscões',
  fuseira: 'fuseira',
  fuseiro: 'fuseiro',
  fuselar: 'fuselar',
  fuselas: 'fuselas',
  fuselos: 'fuselos',
  fusemos: 'fusemos',
  fusgada: 'fusgada',
  fusgado: 'fusgado',
  fusgais: 'fusgais',
  fusgara: 'fusgara',
  fusgava: 'fusgava',
  fusguei: 'fusguei',
  fusguem: 'fusguem',
  fusgues: 'fusgues',
  fusiada: 'fusiada',
  fusiado: 'fusiado',
  fusiloa: 'fusiloa',
  fusilos: 'fusilos',
  fusiola: 'fusíola',
  fusiona: 'fusiona',
  fusione: 'fusione',
  fusiono: 'fusiono',
  fusitas: 'fusitas',
  fusitos: 'fusitos',
  fusivel: 'fusível',
  fusmila: 'fusmila',
  fusoide: 'fusoide',
  fusonas: 'fusonas',
  fusores: 'fusores',
  fusoria: 'fusória',
  fusorio: 'fusório',
  fusquei: 'fusquei',
  fusquem: 'fusquem',
  fusques: 'fusques',
  fusteis: 'fustéis',
  fustete: 'fustete',
  fustiga: 'fustiga',
  fustigo: 'fustigo',
  fustina: 'fustina',
  fustoes: 'fustões',
  futebol: 'futebol',
  futecos: 'futecos',
  futicai: 'futicai',
  futicam: 'futicam',
  futicar: 'futicar',
  futicas: 'futicas',
  futicou: 'futicou',
  futilas: 'futilas',
  futinho: 'futinho',
  futique: 'futique',
  futisso: 'futisso',
  futreta: 'futretá',
  futrete: 'futrete',
  futreto: 'futreto',
  futrica: 'futrica',
  futrico: 'futrico',
  futsais: 'futsais',
  futucai: 'futucai',
  futucam: 'futucam',
  futucar: 'futucar',
  futucas: 'futucas',
  futucou: 'futucou',
  futuque: 'futuque',
  futurai: 'futurai',
  futuram: 'futuram',
  futurar: 'futurar',
  futuras: 'futuras',
  futurei: 'futurei',
  futurem: 'futurem',
  futures: 'futures',
  futuros: 'futuros',
  futurou: 'futurou',
  fuxicai: 'fuxicai',
  fuxicam: 'fuxicam',
  fuxicar: 'fuxicar',
  fuxicas: 'fuxicas',
  fuxicou: 'fuxicou',
  fuxique: 'fuxique',
  fuzarca: 'fuzarca',
  fuzilai: 'fuzilai',
  fuzilam: 'fuzilam',
  fuzilao: 'fuzilão',
  fuzilar: 'fuzilar',
  fuzilas: 'fuzilas',
  fuzilei: 'fuzilei',
  fuzilem: 'fuzilem',
  fuziles: 'fuziles',
  fuzilou: 'fuzilou',
  gaairas: 'gaaíras',
  gabacao: 'gabação',
  gabacho: 'gabacho',
  gabacos: 'gabaços',
  gabadao: 'gabadão',
  gabadas: 'gabadas',
  gabador: 'gabador',
  gabados: 'gabados',
  gabaias: 'gabaias',
  gabales: 'gábales',
  gabalos: 'gábalos',
  gabamos: 'gabamos',
  gabanca: 'gabança',
  gabanco: 'gabanço',
  gabando: 'gabando',
  gabante: 'gabante',
  gabaram: 'gabaram',
  gabarao: 'gabarão',
  gabaras: 'gabaras',
  gabardo: 'gabardo',
  gabarei: 'gabarei',
  gabarem: 'gabarem',
  gabares: 'gabares',
  gabaria: 'gabaria',
  gabarmo: 'gabarmo',
  gabarra: 'gabarra',
  gabarro: 'gabarro',
  gabarus: 'gabarus',
  gabasse: 'gabasse',
  gabaste: 'gabaste',
  gabatas: 'gábatas',
  gabavam: 'gabavam',
  gabavas: 'gabavas',
  gabedos: 'gabedos',
  gabelas: 'gabelas',
  gabelos: 'gabelos',
  gabemos: 'gabemos',
  gabenos: 'gabenos',
  gabetes: 'gabetes',
  gabiada: 'gabiada',
  gabiado: 'gabiado',
  gabiais: 'gabiais',
  gabiamo: 'gabiamo',
  gabiana: 'gabiana',
  gabiano: 'gabiano',
  gabiara: 'gabiará',
  gabiava: 'gabiava',
  gabieis: 'gabieis',
  gabiemo: 'gabiemo',
  gabinos: 'gabinos',
  gabioes: 'gabiões',
  gabiona: 'gabiona',
  gabione: 'gabione',
  gabiono: 'gabiono',
  gabirus: 'gabirus',
  gabitos: 'gábitos',
  gablete: 'gablete',
  gabolas: 'gabolas',
  gabonas: 'gabonas',
  gabones: 'gabonês',
  gabordo: 'gabordo',
  gabotes: 'gabotes',
  gabrito: 'gabrito',
  gabulas: 'gábulas',
  gabulos: 'gábulos',
  gabutas: 'gabutas',
  gacabas: 'gaçabas',
  gacapos: 'gaçapos',
  gacheta: 'gacheta',
  gadacha: 'gadacha',
  gadacho: 'gadacho',
  gadagem: 'gadagem',
  gadames: 'gadames',
  gadamos: 'gadamos',
  gadanha: 'gadanha',
  gadanhe: 'gadanhe',
  gadanho: 'gadanho',
  gadaras: 'gadaras',
  gadares: 'gadares',
  gadaria: 'gadaria',
  gadarus: 'gadarus',
  gadeira: 'gadeira',
  gadeiro: 'gadeiro',
  gadelha: 'gadelha',
  gadelho: 'gadelho',
  gademar: 'gademar',
  gadense: 'gadense',
  gadezas: 'gadezas',
  gadibes: 'gadibes',
  gadicai: 'gadiçai',
  gadicam: 'gadiçam',
  gadicar: 'gadiçar',
  gadicas: 'gadiças',
  gadicei: 'gadicei',
  gadicem: 'gadicem',
  gadices: 'gadices',
  gadicos: 'gadiços',
  gadicou: 'gadiçou',
  gadidas: 'gádidas',
  gadidea: 'gadídea',
  gadideo: 'gadídeo',
  gadidos: 'gádidos',
  gadilas: 'gadilas',
  gadinas: 'gadinas',
  gadinea: 'gadínea',
  gadineo: 'gadíneo',
  gadinho: 'gadinho',
  gadinia: 'gadínia',
  gaditas: 'gaditas',
  gadoide: 'gadoide',
  gaduina: 'gaduína',
  gadunha: 'gadunha',
  gadunhe: 'gadunhe',
  gadunho: 'gadunho',
  gaelica: 'gaélica',
  gaelico: 'gaélico',
  gaelzao: 'gaelzão',
  gafadao: 'gafadão',
  gafadas: 'gafadas',
  gafador: 'gafador',
  gafados: 'gafados',
  gafamos: 'gafamos',
  gafanas: 'gafanas',
  gafando: 'gafando',
  gafante: 'gafante',
  gafaram: 'gafaram',
  gafarao: 'gafarão',
  gafaras: 'gafaras',
  gafarei: 'gafarei',
  gafarem: 'gafarem',
  gafares: 'gafares',
  gafaria: 'gafaria',
  gafarmo: 'gafarmo',
  gafasse: 'gafasse',
  gafaste: 'gafaste',
  gafavam: 'gafavam',
  gafavas: 'gafavas',
  gafeira: 'gafeira',
  gafeire: 'gafeire',
  gafeiro: 'gafeiro',
  gafejai: 'gafejai',
  gafejam: 'gafejam',
  gafejar: 'gafejar',
  gafejas: 'gafejas',
  gafejei: 'gafejei',
  gafejem: 'gafejem',
  gafejes: 'gafejes',
  gafejou: 'gafejou',
  gafemos: 'gafemos',
  gafenho: 'gafenho',
  gafento: 'gafento',
  gafices: 'gafices',
  gafonha: 'gafonha',
  gagaito: 'gagaíto',
  gaganho: 'gaganho',
  gagatas: 'gagatas',
  gagauba: 'gagaúba',
  gageias: 'gageias',
  gageiro: 'gageiro',
  gageita: 'gageíta',
  gaginos: 'gaginos',
  gagosas: 'gagosas',
  gagueai: 'gagueai',
  gaguear: 'gaguear',
  gagueei: 'gagueei',
  gagueia: 'gagueia',
  gagueie: 'gagueie',
  gagueio: 'gagueio',
  gagueja: 'gagueja',
  gagueje: 'gagueje',
  gaguejo: 'gaguejo',
  gagueou: 'gagueou',
  gaguice: 'gaguice',
  gahnita: 'gahnita',
  gahnite: 'gahnite',
  gaiabeu: 'gaiabéu',
  gaiacol: 'gaiacol',
  gaiacos: 'gáiacos',
  gaiadao: 'gaiadão',
  gaiadas: 'gaiadas',
  gaiador: 'gaiador',
  gaiados: 'gaiados',
  gaiamos: 'gaiamos',
  gaianas: 'gaianas',
  gaiando: 'gaiando',
  gaianos: 'gaianos',
  gaiante: 'gaiante',
  gaiaram: 'gaiaram',
  gaiarao: 'gaiarão',
  gaiaras: 'gaiarás',
  gaiarei: 'gaiarei',
  gaiarem: 'gaiarem',
  gaiares: 'gaiares',
  gaiaria: 'gaiaria',
  gaiarmo: 'gaiarmo',
  gaiasse: 'gaiasse',
  gaiaste: 'gaiaste',
  gaiatai: 'gaiatai',
  gaiatam: 'gaiatam',
  gaiatar: 'gaiatar',
  gaiatas: 'gaiatas',
  gaiatei: 'gaiatei',
  gaiatem: 'gaiatem',
  gaiates: 'gaiates',
  gaiatos: 'gaiatos',
  gaiatou: 'gaiatou',
  gaiatri: 'gaiatri',
  gaiavam: 'gaiavam',
  gaiavas: 'gaiavas',
  gaibeia: 'gaibeia',
  gaibeus: 'gaibéus',
  gaibins: 'gaibins',
  gaidato: 'gaidato',
  gaiemos: 'gaiemos',
  gaiense: 'gaiense',
  gaifana: 'gaifana',
  gaifona: 'gaifona',
  gaifone: 'gaifone',
  gaifono: 'gaifono',
  gaiinho: 'gaiinho',
  gailada: 'gailada',
  gaimilo: 'gaimilo',
  gaimira: 'gaimira',
  gaimoai: 'gaimoai',
  gaimoam: 'gaimoam',
  gaimoar: 'gaimoar',
  gaimoas: 'gaimoas',
  gaimoei: 'gaimoei',
  gaimoem: 'gaimoem',
  gaimoes: 'gaimões',
  gaimoou: 'gaimoou',
  gainato: 'gainato',
  gaiolas: 'gaiolas',
  gaiolim: 'gaiolim',
  gaiolos: 'gaiolos',
  gaiorro: 'gaiorro',
  gaiosas: 'gaiosas',
  gaiotos: 'gaiotos',
  gaipaba: 'gaipaba',
  gaipapa: 'gaipapa',
  gaipapo: 'gaipapo',
  gaipara: 'gaipara',
  gaipava: 'gaipava',
  gaipelo: 'gaipelo',
  gaitada: 'gaitada',
  gaitado: 'gaitado',
  gaitais: 'gaitais',
  gaitamo: 'gaitamo',
  gaitara: 'gaitará',
  gaitava: 'gaitava',
  gaiteai: 'gaiteai',
  gaitear: 'gaitear',
  gaiteei: 'gaiteei',
  gaiteia: 'gaiteia',
  gaiteie: 'gaiteie',
  gaiteio: 'gaiteio',
  gaiteis: 'gaiteis',
  gaitejo: 'gaitejo',
  gaitemo: 'gaitemo',
  gaiteou: 'gaiteou',
  gaitona: 'gaitona',
  gaiubim: 'gaiubim',
  gaiulos: 'gaiulos',
  gaiutas: 'gaiutas',
  gaivada: 'gaivada',
  gaivado: 'gaivado',
  gaivais: 'gaivais',
  gaivamo: 'gaivamo',
  gaivara: 'gaivará',
  gaivava: 'gaivava',
  gaiveas: 'gáiveas',
  gaiveis: 'gaiveis',
  gaivemo: 'gaivemo',
  gaiveus: 'gaivéus',
  gaivina: 'gaivina',
  gaivoes: 'gaivões',
  gaivota: 'gaivota',
  gaivoto: 'gaivoto',
  gajadao: 'gajadão',
  gajadas: 'gajadas',
  gajador: 'gajador',
  gajados: 'gajados',
  gajajas: 'gajajas',
  gajamos: 'gajamos',
  gajando: 'gajando',
  gajante: 'gajante',
  gajaram: 'gajaram',
  gajarao: 'gajarão',
  gajaras: 'gájaras',
  gajarei: 'gajarei',
  gajarem: 'gajarem',
  gajares: 'gajares',
  gajaria: 'gajaria',
  gajarmo: 'gajarmo',
  gajasse: 'gajasse',
  gajaste: 'gajaste',
  gajatas: 'gajatas',
  gajatos: 'gajatos',
  gajavam: 'gajavam',
  gajavas: 'gajavas',
  gajeira: 'gajeira',
  gajeiro: 'gajeiro',
  gajemos: 'gajemos',
  gajerus: 'gajerus',
  gajetas: 'gajetas',
  gajices: 'gajices',
  gajirus: 'gajirus',
  gajolas: 'gajolas',
  gajurus: 'gajurus',
  galacao: 'galação',
  galacea: 'galácea',
  galaces: 'galacés',
  galacos: 'gálacos',
  galadao: 'galadão',
  galadas: 'galadas',
  galador: 'galador',
  galados: 'galados',
  galagos: 'gálagos',
  galaica: 'galaica',
  galaico: 'galaico',
  galaios: 'galaios',
  galalao: 'galalão',
  galalau: 'galalau',
  galamos: 'galamos',
  galanai: 'galanai',
  galanam: 'galanam',
  galanar: 'galanar',
  galanas: 'galanas',
  galandi: 'galandi',
  galando: 'galando',
  galanea: 'galaneá',
  galanei: 'galanei',
  galanem: 'galanem',
  galanes: 'galanes',
  galanga: 'galanga',
  galango: 'galangó',
  galania: 'galania',
  galanol: 'galanol',
  galanou: 'galanou',
  galante: 'galante',
  galanto: 'galanto',
  galapio: 'galápio',
  galapos: 'galapos',
  galaram: 'galaram',
  galarao: 'galarão',
  galaras: 'galarás',
  galarei: 'galarei',
  galarem: 'galarem',
  galares: 'galares',
  galaria: 'galaria',
  galarim: 'galarim',
  galarmo: 'galarmo',
  galaroz: 'galaroz',
  galasse: 'galasse',
  galaste: 'galaste',
  galatas: 'gálatas',
  galatos: 'galatos',
  galaula: 'galaula',
  galavam: 'galavam',
  galavas: 'galavas',
  galaxea: 'galáxea',
  galaxia: 'galáxia',
  galazia: 'galazia',
  galbada: 'galbada',
  galbado: 'galbado',
  galbana: 'gálbana',
  galbano: 'gálbano',
  galbino: 'gálbino',
  galbula: 'gálbula',
  galbulo: 'gálbulo',
  galdido: 'galdido',
  galdina: 'galdina',
  galdino: 'galdino',
  galdras: 'galdras',
  galeaca: 'galeaça',
  galeada: 'galeada',
  galeado: 'galeado',
  galeais: 'galeais',
  galeara: 'galeara',
  galeato: 'galeato',
  galeava: 'galeava',
  galecas: 'galecas',
  galecio: 'galécio',
  galeeis: 'galeeis',
  galegas: 'galegas',
  galegea: 'galégea',
  galegos: 'galegos',
  galeiam: 'galeiam',
  galeias: 'galeias',
  galeida: 'galeida',
  galeiem: 'galeiem',
  galeies: 'galeies',
  galeina: 'galeína',
  galeira: 'galeira',
  galeiro: 'galeiro',
  galeita: 'galeíta',
  galelas: 'galelas',
  galelos: 'galelos',
  galemos: 'galemos',
  galenas: 'galenas',
  galenga: 'galenga',
  galengo: 'galengo',
  galenia: 'galênia',
  galenos: 'galenos',
  galense: 'galense',
  galeode: 'galeode',
  galeoes: 'galeões',
  galeola: 'galéola',
  galeoma: 'galeoma',
  galeota: 'galeota',
  galeote: 'galeote',
  galeoto: 'galeoto',
  galerao: 'galerão',
  galeras: 'galeras',
  galeria: 'galeria',
  galerno: 'galerno',
  galeros: 'galeros',
  galesas: 'galesas',
  galeses: 'galeses',
  galesia: 'galésia',
  galetas: 'galetas',
  galetos: 'galetos',
  galezia: 'galezia',
  galgada: 'galgada',
  galgado: 'galgado',
  galgais: 'galgais',
  galgamo: 'galgamo',
  galgara: 'galgara',
  galgava: 'galgava',
  galgoes: 'galgões',
  galguei: 'galguei',
  galguem: 'galguem',
  galgues: 'galgues',
  galhaca: 'galhaça',
  galhada: 'galhada',
  galhana: 'galhana',
  galhano: 'galhano',
  galhato: 'galhato',
  galhena: 'galhena',
  galheta: 'galheta',
  galhete: 'galhete',
  galheto: 'galheto',
  galhica: 'gálhica',
  galhico: 'gálhico',
  galhipo: 'galhipo',
  galhofa: 'galhofa',
  galhofe: 'galhofe',
  galhofo: 'galhofo',
  galhosa: 'galhosa',
  galhoso: 'galhoso',
  galhoto: 'galhoto',
  galhuca: 'galhuça',
  galhuda: 'galhuda',
  galhudo: 'galhudo',
  galibis: 'galibis',
  galicai: 'galicai',
  galicam: 'galicam',
  galicar: 'galicar',
  galicas: 'gálicas',
  galicia: 'galícia',
  galicio: 'galício',
  galicos: 'gálicos',
  galicou: 'galicou',
  galiles: 'galilés',
  galileu: 'galileu',
  galimai: 'galimai',
  galimam: 'galimam',
  galimar: 'galimar',
  galimas: 'galimas',
  galimei: 'galimei',
  galimem: 'galimem',
  galimes: 'galimes',
  galimos: 'galimos',
  galimou: 'galimou',
  galinha: 'galinha',
  galinhe: 'galinhe',
  galinho: 'galinho',
  galioes: 'galiões',
  galiosa: 'galiosa',
  galioso: 'galioso',
  galipao: 'galipão',
  galipos: 'galipós',
  galipso: 'galipso',
  galique: 'galique',
  galires: 'galirés',
  galisia: 'galisia',
  galismo: 'galismo',
  galispo: 'galispo',
  galista: 'galista',
  galitas: 'galitas',
  galitos: 'galitos',
  galivai: 'galivai',
  galivam: 'galivam',
  galivar: 'galivar',
  galivas: 'galivas',
  galivei: 'galivei',
  galivem: 'galivem',
  galives: 'galives',
  galivou: 'galivou',
  galizao: 'galizão',
  galizia: 'galizia',
  galizos: 'galizos',
  galoada: 'galoada',
  galoado: 'galoado',
  galoais: 'galoais',
  galoamo: 'galoamo',
  galoara: 'galoará',
  galoava: 'galoava',
  galocha: 'galocha',
  galoeis: 'galoeis',
  galoemo: 'galoemo',
  galoilo: 'galoílo',
  galoles: 'galoles',
  galolis: 'galólis',
  galolos: 'galolos',
  galonai: 'galonai',
  galonam: 'galonam',
  galonar: 'galonar',
  galonas: 'galonas',
  galonei: 'galonei',
  galonem: 'galonem',
  galones: 'galones',
  galonou: 'galonou',
  galopai: 'galopai',
  galopam: 'galopam',
  galopao: 'galopão',
  galopar: 'galopar',
  galopas: 'galopas',
  galopea: 'galopeá',
  galopei: 'galopei',
  galopem: 'galopem',
  galopes: 'galopes',
  galopim: 'galopim',
  galopou: 'galopou',
  galpoes: 'galpões',
  galrada: 'galrada',
  galrado: 'galrado',
  galrais: 'galrais',
  galramo: 'galramo',
  galrara: 'galrará',
  galrava: 'galrava',
  galreai: 'galreai',
  galrear: 'galrear',
  galreda: 'galreda',
  galreei: 'galreei',
  galreia: 'galreia',
  galreie: 'galreie',
  galreio: 'galreio',
  galreis: 'galreis',
  galreja: 'galreja',
  galreje: 'galreje',
  galrejo: 'galrejo',
  galremo: 'galremo',
  galreou: 'galreou',
  galrico: 'galriço',
  galripa: 'galripa',
  galripo: 'galripo',
  galrito: 'galrito',
  galroes: 'galrões',
  galubes: 'galubés',
  galucha: 'galucha',
  galucho: 'galucho',
  galulas: 'galulas',
  galupas: 'galupas',
  galures: 'galurês',
  galvana: 'galvana',
  galvano: 'galvano',
  galveta: 'galveta',
  gamacao: 'gamação',
  gamacha: 'gamacha',
  gamadao: 'gamadão',
  gamadas: 'gamadas',
  gamador: 'gamador',
  gamados: 'gamados',
  gamaios: 'gamaios',
  gamamos: 'gamamos',
  gamanco: 'gamanço',
  gamando: 'gamando',
  gamante: 'gamante',
  gamaram: 'gamaram',
  gamarao: 'gamarão',
  gamaras: 'gamaras',
  gamarei: 'gamarei',
  gamarem: 'gamarem',
  gamares: 'gamares',
  gamaria: 'gamaria',
  gamarmo: 'gamarmo',
  gamaros: 'gâmaros',
  gamarra: 'gamarra',
  gamasse: 'gamasse',
  gamasso: 'gamasso',
  gamaste: 'gamaste',
  gamatao: 'gamatão',
  gamavam: 'gamavam',
  gamavas: 'gamavas',
  gambela: 'gambela',
  gambele: 'gambele',
  gambelo: 'gambelo',
  gambeta: 'gambeta',
  gambiai: 'gambiai',
  gambiam: 'gambiam',
  gambiar: 'gambiar',
  gambias: 'gâmbias',
  gambiei: 'gambiei',
  gambiem: 'gambiem',
  gambies: 'gambies',
  gambina: 'gambina',
  gambinu: 'gambinu',
  gambiou: 'gambiou',
  gambito: 'gambito',
  gamboas: 'gamboas',
  gambota: 'gambota',
  gambues: 'gâmbues',
  gamedim: 'gamedim',
  gameira: 'gameira',
  gameiro: 'gameiro',
  gamelao: 'gamelão',
  gamelas: 'gamelas',
  gamelia: 'gamélia',
  gamelio: 'gamélio',
  gamelos: 'gamelos',
  gamemos: 'gamemos',
  gamenha: 'gamenha',
  gamenhe: 'gamenhe',
  gamenho: 'gamenho',
  gamense: 'gamense',
  gameras: 'gameras',
  gamergu: 'gamergu',
  gametal: 'gametal',
  gametas: 'gametas',
  gametos: 'gametos',
  gamicas: 'gâmicas',
  gamicos: 'gâmicos',
  gamobio: 'gamóbio',
  gamonte: 'gamonte',
  gamotes: 'gamotes',
  gamutas: 'gamutas',
  gamutes: 'gamutes',
  gamutis: 'gamútis',
  gamutos: 'gamutos',
  ganacha: 'ganacha',
  ganalos: 'ganalos',
  ganamos: 'ganamos',
  ganapao: 'ganapão',
  ganapas: 'ganapas',
  ganapes: 'ganapés',
  ganapos: 'ganapos',
  ganaras: 'ganaras',
  gancada: 'gançada',
  gancado: 'gançado',
  gancais: 'gançais',
  gancamo: 'gançamo',
  gancara: 'gançará',
  gancava: 'gançava',
  ganceis: 'ganceis',
  gancemo: 'gancemo',
  ganchai: 'ganchai',
  gancham: 'gancham',
  ganchar: 'ganchar',
  ganchas: 'ganchas',
  ganchea: 'gancheá',
  ganchei: 'ganchei',
  ganchem: 'ganchem',
  ganches: 'ganches',
  ganchos: 'ganchos',
  ganchou: 'ganchou',
  gandaia: 'gandaia',
  gandaie: 'gandaie',
  gandaio: 'gandaio',
  gandara: 'gândara',
  gandaru: 'gandaru',
  gandavo: 'gandavo',
  gandeai: 'gandeai',
  gandear: 'gandear',
  gandeei: 'gandeei',
  gandeia: 'gandeia',
  gandeie: 'gandeie',
  gandeio: 'gandeio',
  gandeou: 'gandeou',
  gandola: 'gandola',
  gandras: 'gandras',
  gandres: 'gandrês',
  gandula: 'gandula',
  gandule: 'gandule',
  gandulo: 'gandulo',
  ganduns: 'ganduns',
  gandura: 'gandura',
  ganeira: 'ganeira',
  ganeiro: 'ganeiro',
  ganense: 'ganense',
  ganeria: 'ganéria',
  ganesas: 'ganesas',
  ganeses: 'ganeses',
  ganetes: 'ganetes',
  ganfada: 'ganfada',
  ganfado: 'ganfado',
  ganfais: 'ganfais',
  ganfamo: 'ganfamo',
  ganfana: 'ganfana',
  ganfara: 'ganfará',
  ganfava: 'ganfava',
  ganfeis: 'ganfeis',
  ganfemo: 'ganfemo',
  gangana: 'gangana',
  gangara: 'gângara',
  gangela: 'gangela',
  ganglio: 'gânglio',
  gangoes: 'gangões',
  gangosa: 'gangosa',
  gangoso: 'gangoso',
  gangris: 'gangris',
  ganguea: 'gangueá',
  gangues: 'gangues',
  ganguji: 'ganguji',
  gangula: 'gângula',
  ganhaco: 'ganhaço',
  ganhada: 'ganhada',
  ganhado: 'ganhado',
  ganhais: 'ganhais',
  ganhame: 'ganhame',
  ganhamo: 'ganhamo',
  ganhara: 'ganhará',
  ganhava: 'ganhava',
  ganheis: 'ganheis',
  ganhemo: 'ganhemo',
  ganhoal: 'ganhoal',
  ganhoas: 'ganhoas',
  ganhoes: 'ganhões',
  ganhosa: 'ganhosa',
  ganhoso: 'ganhoso',
  ganhoto: 'ganhoto',
  ganhuca: 'ganhuça',
  ganhuco: 'ganhuço',
  ganicai: 'ganiçai',
  ganicam: 'ganiçam',
  ganicar: 'ganiçar',
  ganicas: 'ganiças',
  ganicei: 'ganicei',
  ganicem: 'ganicem',
  ganices: 'ganices',
  ganicos: 'ganiços',
  ganicou: 'ganiçou',
  ganidao: 'ganidão',
  ganidas: 'ganidas',
  ganidor: 'ganidor',
  ganidos: 'ganidos',
  ganieas: 'ganíeas',
  ganieis: 'ganíeis',
  ganimos: 'ganimos',
  ganindo: 'ganindo',
  ganiram: 'ganiram',
  ganirao: 'ganirão',
  ganiras: 'ganiras',
  ganirei: 'ganirei',
  ganirem: 'ganirem',
  ganires: 'ganires',
  ganiria: 'ganiria',
  ganirmo: 'ganirmo',
  ganirra: 'ganirra',
  ganisse: 'ganisse',
  ganiste: 'ganiste',
  ganitas: 'ganitas',
  ganites: 'ganites',
  ganizai: 'ganizai',
  ganizam: 'ganizam',
  ganizar: 'ganizar',
  ganizas: 'ganizas',
  ganizei: 'ganizei',
  ganizem: 'ganizem',
  ganizes: 'ganizes',
  ganizou: 'ganizou',
  ganjera: 'ganjera',
  ganjeta: 'ganjeta',
  ganjoes: 'ganjões',
  ganleto: 'ganletó',
  ganogas: 'ganogas',
  ganoide: 'ganoide',
  ganoina: 'ganoína',
  ganruto: 'ganrutó',
  gansada: 'gansada',
  gansoes: 'gansões',
  ganzada: 'ganzada',
  ganzado: 'ganzado',
  ganzais: 'ganzais',
  ganzamo: 'ganzamo',
  ganzara: 'ganzará',
  ganzava: 'ganzava',
  ganzeis: 'ganzeis',
  ganzemo: 'ganzemo',
  ganzepe: 'ganzepe',
  ganzola: 'ganzola',
  ganzuas: 'ganzuás',
  ganzulu: 'ganzulu',
  gapeira: 'gapeira',
  gapinai: 'gapinai',
  gapinam: 'gapinam',
  gapinar: 'gapinar',
  gapinas: 'gapinas',
  gapinei: 'gapinei',
  gapinem: 'gapinem',
  gapines: 'gapines',
  gapinou: 'gapinou',
  gapiras: 'gapiras',
  gaponga: 'gaponga',
  gapuiai: 'gapuiai',
  gapuiam: 'gapuiam',
  gapuiar: 'gapuiar',
  gapuias: 'gapuias',
  gapuiei: 'gapuiei',
  gapuiem: 'gapuiem',
  gapuies: 'gapuies',
  gapuiou: 'gapuiou',
  garabis: 'garabis',
  garabus: 'garabus',
  garacui: 'garacuí',
  garagem: 'garagem',
  garages: 'garages',
  garajao: 'garajão',
  garajau: 'garajau',
  garalha: 'garalha',
  garalhe: 'garalhe',
  garalho: 'garalho',
  garanca: 'garança',
  garance: 'garance',
  garanco: 'garanço',
  garanho: 'garanho',
  garanta: 'garanta',
  garante: 'garante',
  garanti: 'garanti',
  garanto: 'garanto',
  garapao: 'garapão',
  garapas: 'garapas',
  garapau: 'garapau',
  garapes: 'garapés',
  garapus: 'garapus',
  garauca: 'garauçá',
  garauna: 'garaúna',
  garauva: 'garaúva',
  garavim: 'garavim',
  garbosa: 'garbosa',
  garboso: 'garboso',
  garceja: 'garceja',
  garcela: 'garcela',
  garcera: 'garcera',
  garcesa: 'garcesa',
  garceta: 'garceta',
  garcina: 'garcina',
  garcoes: 'garções',
  garcola: 'garçola',
  garcolo: 'garçolo',
  garcona: 'garçona',
  garcone: 'garçone',
  garcons: 'garçons',
  garcota: 'garçota',
  garcote: 'garçote',
  garcoto: 'garçoto',
  garecer: 'garecer',
  garelas: 'garelas',
  garepes: 'garepes',
  gareras: 'gareras',
  garetes: 'garetes',
  garfada: 'garfada',
  garfado: 'garfado',
  garfais: 'garfais',
  garfamo: 'garfamo',
  garfara: 'garfara',
  garfava: 'garfava',
  garfeai: 'garfeai',
  garfear: 'garfear',
  garfeei: 'garfeei',
  garfeia: 'garfeia',
  garfeie: 'garfeie',
  garfeio: 'garfeio',
  garfeis: 'garfeis',
  garfeja: 'garfejá',
  garfeje: 'garfeje',
  garfejo: 'garfejo',
  garfemo: 'garfemo',
  garfeou: 'garfeou',
  garfete: 'garfete',
  garfiai: 'garfiai',
  garfiam: 'garfiam',
  garfiar: 'garfiar',
  garfias: 'garfias',
  garfiei: 'garfiei',
  garfiem: 'garfiem',
  garfies: 'garfies',
  garfila: 'garfila',
  garfins: 'garfins',
  garfiou: 'garfiou',
  gargada: 'gargada',
  gargado: 'gargado',
  gargais: 'gargais',
  gargalo: 'gargalo',
  gargara: 'gargara',
  gargare: 'gárgare',
  gargaro: 'gárgaro',
  gargaru: 'gargaru',
  gargaus: 'gargaús',
  gargava: 'gargava',
  garguei: 'garguei',
  garguem: 'garguem',
  gargues: 'gargues',
  gargula: 'gárgula',
  garimba: 'garimba',
  garimbe: 'garimbe',
  garimbo: 'garimbo',
  garimpa: 'garimpa',
  garimpe: 'garimpe',
  garimpo: 'garimpo',
  garinai: 'garinai',
  garinam: 'garinam',
  garinar: 'garinar',
  garinas: 'garinas',
  garinei: 'garinei',
  garinem: 'garinem',
  garines: 'garines',
  garinos: 'garinos',
  garinou: 'garinou',
  garipio: 'garípio',
  garitas: 'garitas',
  garites: 'garites',
  garitos: 'garitos',
  garjaus: 'garjaus',
  garlopa: 'garlopa',
  garmita: 'garmita',
  garnada: 'garnada',
  garnado: 'garnado',
  garnais: 'garnais',
  garnamo: 'garnamo',
  garnara: 'garnará',
  garnava: 'garnava',
  garneai: 'garneai',
  garnear: 'garnear',
  garneei: 'garneei',
  garneia: 'garneia',
  garneie: 'garneie',
  garneio: 'garneio',
  garneis: 'garneis',
  garnela: 'garnela',
  garnemo: 'garnemo',
  garneou: 'garneou',
  garniam: 'garniam',
  garnias: 'garnias',
  garnida: 'garnida',
  garnido: 'garnido',
  garnimo: 'garnimo',
  garnira: 'garnirá',
  garnise: 'garnisé',
  garoada: 'garoada',
  garoado: 'garoado',
  garoais: 'garoais',
  garoamo: 'garoamo',
  garoara: 'garoara',
  garoava: 'garoava',
  garoeis: 'garoeis',
  garoemo: 'garoemo',
  garomas: 'garomas',
  garones: 'garonês',
  garopos: 'garopos',
  garotai: 'garotai',
  garotal: 'garotal',
  garotam: 'garotam',
  garotao: 'garotão',
  garotar: 'garotar',
  garotas: 'garotas',
  garotea: 'garoteá',
  garotei: 'garotei',
  garotem: 'garotem',
  garotes: 'garotes',
  garotil: 'garotil',
  garotio: 'garotio',
  garotir: 'garotir',
  garotis: 'garotis',
  garotos: 'garotos',
  garotou: 'garotou',
  garoupa: 'garoupa',
  garpoti: 'garpoti',
  garrada: 'garrada',
  garrado: 'garrado',
  garrafa: 'garrafa',
  garraio: 'garraio',
  garrais: 'garrais',
  garrama: 'garrama',
  garrame: 'garrame',
  garramo: 'garramo',
  garrana: 'garrana',
  garrano: 'garrano',
  garrara: 'garrara',
  garraus: 'garraus',
  garrava: 'garrava',
  garreai: 'garreai',
  garreao: 'garreão',
  garrear: 'garrear',
  garreei: 'garreei',
  garreia: 'garreia',
  garreie: 'garreie',
  garreio: 'garreio',
  garreis: 'garreis',
  garremo: 'garremo',
  garreou: 'garreou',
  garrial: 'garrial',
  garriam: 'garriam',
  garriao: 'garrião',
  garrias: 'garrias',
  garrica: 'garriça',
  garrico: 'garriço',
  garrida: 'garrida',
  garride: 'garride',
  garrido: 'garrido',
  garriga: 'garriga',
  garrimo: 'garrimo',
  garrios: 'gárrios',
  garrira: 'garrira',
  garroas: 'garroas',
  garroba: 'garroba',
  garroes: 'garrões',
  garrota: 'garrota',
  garrote: 'garrote',
  garroto: 'garroto',
  garruco: 'garruço',
  garruda: 'garruda',
  garrudo: 'garrudo',
  garrula: 'garrula',
  garrule: 'garrule',
  garrulo: 'gárrulo',
  garuada: 'garuada',
  garuado: 'garuado',
  garuais: 'garuais',
  garuamo: 'garuamo',
  garuara: 'garuará',
  garuava: 'garuava',
  garubas: 'garubas',
  garudas: 'garudas',
  garueis: 'garueis',
  garuemo: 'garuemo',
  garugas: 'garugas',
  garujas: 'garujas',
  garulas: 'garulas',
  garulha: 'garulha',
  garumas: 'garumás',
  garumno: 'garumno',
  garunas: 'garunas',
  garundo: 'garundo',
  garunha: 'garunha',
  garupas: 'garupas',
  garvaia: 'garvaia',
  garveia: 'garveia',
  garzeai: 'garzeai',
  garzear: 'garzear',
  garzeei: 'garzeei',
  garzeia: 'garzeia',
  garzeie: 'garzeie',
  garzeio: 'garzeio',
  garzeou: 'garzeou',
  garzeta: 'garzeta',
  garzina: 'garzina',
  gasalha: 'gasalha',
  gasalhe: 'gasalhe',
  gasalho: 'gasalho',
  gasanol: 'gasanol',
  gascoas: 'gascoas',
  gascoes: 'gascões',
  gascona: 'gascona',
  gaseada: 'gaseada',
  gaseado: 'gaseado',
  gaseais: 'gaseais',
  gaseara: 'gaseara',
  gaseava: 'gaseava',
  gaseeis: 'gaseeis',
  gaseiam: 'gaseiam',
  gaseias: 'gaseias',
  gaseiem: 'gaseiem',
  gaseies: 'gaseies',
  gaseira: 'gaseira',
  gaseiro: 'gaseiro',
  gasista: 'gasista',
  gasmada: 'gasmada',
  gasmado: 'gasmado',
  gasmais: 'gasmais',
  gasmamo: 'gasmamo',
  gasmara: 'gasmará',
  gasmava: 'gasmava',
  gasmeis: 'gasmeis',
  gasmemo: 'gasmemo',
  gasnate: 'gasnate',
  gasnete: 'gasnete',
  gasolea: 'gasólea',
  gasoleo: 'gasóleo',
  gasosas: 'gasosas',
  gasosos: 'gasosos',
  gaspeai: 'gaspeai',
  gaspear: 'gaspear',
  gaspeas: 'gáspeas',
  gaspeei: 'gaspeei',
  gaspeia: 'gaspeia',
  gaspeie: 'gaspeie',
  gaspeio: 'gaspeio',
  gaspeou: 'gaspeou',
  gaspoia: 'gaspoia',
  gastada: 'gastada',
  gastado: 'gastado',
  gastais: 'gastais',
  gastamo: 'gastamo',
  gastara: 'gastara',
  gastava: 'gastava',
  gasteis: 'gasteis',
  gastemo: 'gastemo',
  gastoes: 'gastões',
  gastros: 'gastros',
  gastura: 'gastura',
  gatadao: 'gatadão',
  gatadas: 'gatadas',
  gatador: 'gatador',
  gatados: 'gatados',
  gatagua: 'gataguá',
  gatamos: 'gatamos',
  gatando: 'gatando',
  gatanha: 'gatanha',
  gatanhe: 'gatanhe',
  gatanho: 'gatanho',
  gatante: 'gatante',
  gataram: 'gataram',
  gatarao: 'gatarão',
  gataras: 'gatarás',
  gatarei: 'gatarei',
  gatarem: 'gatarem',
  gatares: 'gatares',
  gataria: 'gataria',
  gatarmo: 'gatarmo',
  gatasse: 'gatasse',
  gataste: 'gataste',
  gatavam: 'gatavam',
  gatavas: 'gatavas',
  gatazio: 'gatázio',
  gateada: 'gateada',
  gateado: 'gateado',
  gateais: 'gateais',
  gateara: 'gateara',
  gateava: 'gateava',
  gateeis: 'gateeis',
  gateiam: 'gateiam',
  gateias: 'gateias',
  gateiem: 'gateiem',
  gateies: 'gateies',
  gateira: 'gateira',
  gateiro: 'gateiro',
  gatemos: 'gatemos',
  gatenho: 'gatenho',
  gatense: 'gatense',
  gatesca: 'gatesca',
  gatesco: 'gatesco',
  gatesga: 'gatesga',
  gatesgo: 'gatesgo',
  gateway: 'gateway',
  gatezas: 'gatezas',
  gaticas: 'gáticas',
  gatices: 'gatices',
  gaticos: 'gáticos',
  gatilho: 'gatilho',
  gatinas: 'gatinas',
  gatines: 'gatinês',
  gatinha: 'gatinha',
  gatinhe: 'gatinhe',
  gatinho: 'gatinho',
  gatismo: 'gatismo',
  gatoacu: 'gatoaçu',
  gatonas: 'gatonas',
  gatorro: 'gatorro',
  gatosas: 'gatosas',
  gatosos: 'gatosos',
  gatunai: 'gatunai',
  gatunam: 'gatunam',
  gatunar: 'gatunar',
  gatunas: 'gatunas',
  gatunei: 'gatunei',
  gatunem: 'gatunem',
  gatunes: 'gatunes',
  gatunha: 'gatunha',
  gatunhe: 'gatunhe',
  gatunho: 'gatunho',
  gatunos: 'gatunos',
  gatunou: 'gatunou',
  gaturai: 'gaturai',
  gaturam: 'gaturam',
  gaturar: 'gaturar',
  gaturas: 'gaturas',
  gaturda: 'gaturda',
  gaturei: 'gaturei',
  gaturem: 'gaturem',
  gatures: 'gatures',
  gaturou: 'gaturou',
  gaturra: 'gaturra',
  gaturre: 'gaturre',
  gaturro: 'gaturro',
  gauchai: 'gauchai',
  gaucham: 'gaúcham',
  gauchao: 'gauchão',
  gauchar: 'gauchar',
  gauchas: 'gaúchas',
  gauchei: 'gauchei',
  gauchem: 'gaúchem',
  gauches: 'gaúches',
  gauchos: 'gaúchos',
  gauchou: 'gauchou',
  gauchus: 'gauchus',
  gaudada: 'gaudada',
  gaudado: 'gaudado',
  gaudais: 'gaudais',
  gaudamo: 'gaudamo',
  gaudara: 'gaudara',
  gaudava: 'gaudava',
  gaudeis: 'gaudeis',
  gaudemo: 'gaudemo',
  gaudera: 'gauderá',
  gaudere: 'gaudere',
  gaudero: 'gaudero',
  gaudiai: 'gaudiai',
  gaudiam: 'gaudiam',
  gaudiar: 'gaudiar',
  gaudias: 'gaudias',
  gaudiei: 'gaudiei',
  gaudiem: 'gaudiem',
  gaudies: 'gaudies',
  gaudina: 'gaudina',
  gaudine: 'gaudine',
  gaudino: 'gaudino',
  gaudios: 'gáudios',
  gaudiou: 'gaudiou',
  gaugaus: 'gaugaus',
  gaulesa: 'gaulesa',
  gaulope: 'gaulope',
  gaumine: 'gaumine',
  gauncar: 'gauncar',
  gaurata: 'gaurata',
  gaurica: 'gáurica',
  gaurico: 'gáurico',
  gaurios: 'gáurios',
  gavacho: 'gavacho',
  gavadao: 'gavadão',
  gavadas: 'gavadas',
  gavador: 'gavador',
  gavados: 'gavados',
  gavagem: 'gavagem',
  gavamos: 'gavamos',
  gavanas: 'gavanas',
  gavando: 'gavando',
  gavante: 'gavante',
  gavaram: 'gavaram',
  gavarao: 'gavarão',
  gavaras: 'gavarás',
  gavarei: 'gavarei',
  gavarem: 'gavarem',
  gavares: 'gavares',
  gavaria: 'gavaria',
  gavarmo: 'gavarmo',
  gavarro: 'gavarro',
  gavasse: 'gavasse',
  gavaste: 'gavaste',
  gavatas: 'gávatas',
  gavavam: 'gavavam',
  gavavas: 'gavavas',
  gaveada: 'gaveada',
  gaveado: 'gaveado',
  gaveais: 'gaveais',
  gaveara: 'gaveará',
  gaveava: 'gaveava',
  gavedos: 'gávedos',
  gaveeis: 'gaveeis',
  gaveiam: 'gaveiam',
  gaveias: 'gaveias',
  gaveiem: 'gaveiem',
  gaveies: 'gaveies',
  gaveira: 'gaveira',
  gaveiro: 'gaveiro',
  gavejos: 'gavejos',
  gavelas: 'gavelas',
  gavelos: 'gavelos',
  gavemos: 'gavemos',
  gavetao: 'gavetão',
  gavetas: 'gavetas',
  gavetim: 'gavetim',
  gavetos: 'gavetos',
  gaviais: 'gaviais',
  gaviama: 'gaviama',
  gaviana: 'gaviana',
  gaviete: 'gaviete',
  gavilha: 'gavilha',
  gavinas: 'gavinas',
  gavinha: 'gavinha',
  gavioes: 'gaviões',
  gaviola: 'gaviola',
  gaviona: 'gaviona',
  gavione: 'gavione',
  gaviono: 'gaviono',
  gavitas: 'gavitas',
  gavitos: 'gavitos',
  gavotas: 'gavotas',
  gaxetas: 'gaxetas',
  gazanas: 'gazanas',
  gazania: 'gazânia',
  gazanos: 'gazanos',
  gazares: 'gazares',
  gazatas: 'gazatas',
  gazeada: 'gazeada',
  gazeado: 'gazeado',
  gazeais: 'gazeais',
  gazeara: 'gazeara',
  gazeava: 'gazeava',
  gazebos: 'gazebos',
  gazeeis: 'gazeeis',
  gazeiam: 'gazeiam',
  gazeias: 'gazeias',
  gazeiem: 'gazeiem',
  gazeies: 'gazeies',
  gazelas: 'gazelas',
  gazenas: 'gazenas',
  gazense: 'gazense',
  gazetal: 'gazetal',
  gazetas: 'gazetas',
  gazetea: 'gazeteá',
  gazilai: 'gazilai',
  gazilam: 'gazilam',
  gazilar: 'gazilar',
  gazilas: 'gazilas',
  gazilei: 'gazilei',
  gazilem: 'gazilem',
  gaziles: 'gaziles',
  gazilha: 'gazilhá',
  gazilhe: 'gazilhe',
  gazilho: 'gazilho',
  gazilou: 'gazilou',
  gazinai: 'gazinai',
  gazinam: 'gazinam',
  gazinar: 'gazinar',
  gazinas: 'gazinas',
  gazinei: 'gazinei',
  gazinem: 'gazinem',
  gazines: 'gazines',
  gazinou: 'gazinou',
  gazipos: 'gazipos',
  gazitas: 'gazitas',
  gazitos: 'gazitos',
  gazivas: 'gazivas',
  gazolai: 'gazolai',
  gazolam: 'gazolam',
  gazolar: 'gazolar',
  gazolas: 'gazolas',
  gazolei: 'gazolei',
  gazolem: 'gazolem',
  gazoles: 'gazoles',
  gazolou: 'gazolou',
  gazongo: 'gazongo',
  gazopas: 'gazopas',
  gazopos: 'gazopos',
  gazuada: 'gazuada',
  gazuado: 'gazuado',
  gazuais: 'gazuais',
  gazuamo: 'gazuamo',
  gazuara: 'gazuará',
  gazuava: 'gazuava',
  gazueis: 'gazueis',
  gazuemo: 'gazuemo',
  gazulai: 'gazulai',
  gazulam: 'gazulam',
  gazular: 'gazular',
  gazulas: 'gazulas',
  gazulei: 'gazulei',
  gazulem: 'gazulem',
  gazules: 'gazules',
  gazulou: 'gazulou',
  gazupai: 'gazupai',
  gazupam: 'gazupam',
  gazupar: 'gazupar',
  gazupas: 'gazupas',
  gazupei: 'gazupei',
  gazupem: 'gazupem',
  gazupes: 'gazupes',
  gazupou: 'gazupou',
  geadoes: 'geadões',
  geadona: 'geadona',
  geadora: 'geadora',
  geantes: 'geantes',
  geardes: 'geardes',
  geareis: 'geareis',
  gearemo: 'gearemo',
  geariam: 'geariam',
  gearias: 'gearias',
  gearmos: 'gearmos',
  geassas: 'geassas',
  geassem: 'geassem',
  geasses: 'geasses',
  geaster: 'geáster',
  geastes: 'geastes',
  geaveis: 'geáveis',
  gebadao: 'gebadão',
  gebadas: 'gebadas',
  gebadeu: 'gebadeu',
  gebador: 'gebador',
  gebados: 'gebados',
  gebamos: 'gebamos',
  gebando: 'gebando',
  gebante: 'gebante',
  gebaram: 'gebaram',
  gebarao: 'gebarão',
  gebaras: 'gebaras',
  gebarei: 'gebarei',
  gebarem: 'gebarem',
  gebares: 'gebares',
  gebaria: 'gebaria',
  gebarmo: 'gebarmo',
  gebasse: 'gebasse',
  gebaste: 'gebaste',
  gebavam: 'gebavam',
  gebavas: 'gebavas',
  gebemos: 'gebemos',
  gebices: 'gebices',
  gebosas: 'gebosas',
  gebosos: 'gebosos',
  gebrada: 'gebrada',
  gebrado: 'gebrado',
  gebrais: 'gebrais',
  gebramo: 'gebramo',
  gebrara: 'gebrara',
  gebrava: 'gebrava',
  gebreis: 'gebreis',
  gebremo: 'gebremo',
  gecazum: 'gecazum',
  gechira: 'gechira',
  gecinos: 'gécinos',
  gecobia: 'gecóbia',
  gedebos: 'gedebos',
  gedrita: 'gedrita',
  gedrite: 'gedrite',
  gedruso: 'gedruso',
  geeiras: 'geeiras',
  geeiros: 'geeiros',
  geentes: 'geentes',
  geentos: 'geentos',
  gefiras: 'gefiras',
  gefirea: 'gefírea',
  gefireo: 'gefíreo',
  gegania: 'gegânia',
  geilolo: 'geilolo',
  gelacao: 'gelação',
  geladao: 'geladão',
  geladas: 'geladas',
  gelador: 'gelador',
  gelados: 'gelados',
  gelamos: 'gelamos',
  gelando: 'gelando',
  gelante: 'gelante',
  gelanto: 'gelanto',
  gelaram: 'gelaram',
  gelarao: 'gelarão',
  gelaras: 'gelaras',
  gelarei: 'gelarei',
  gelarem: 'gelarem',
  gelares: 'gelares',
  gelaria: 'gelaria',
  gelarmo: 'gelarmo',
  gelasse: 'gelasse',
  gelaste: 'gelaste',
  gelavam: 'gelavam',
  gelavas: 'gelavas',
  geleada: 'geleada',
  geleias: 'geleias',
  geleina: 'geleína',
  geleira: 'geleira',
  geleiro: 'geleiro',
  gelemos: 'gelemos',
  gelense: 'gelense',
  gelidas: 'gélidas',
  gelidez: 'gelidez',
  gelidio: 'gelídio',
  gelidos: 'gélidos',
  gelifaz: 'gelifaz',
  gelifez: 'gelifez',
  gelifiz: 'gelifiz',
  gelinas: 'gelinas',
  gelinea: 'gelínea',
  gelineo: 'gelíneo',
  gelinos: 'gelinos',
  gelivas: 'gelivas',
  gelivel: 'gelível',
  geloide: 'geloide',
  gelonio: 'gelônio',
  gelonos: 'gelonos',
  gelosas: 'gelosas',
  geloses: 'geloses',
  gelosia: 'gelosia',
  gelulas: 'gélulas',
  gelzoes: 'gelzões',
  gemacao: 'gemação',
  gemadao: 'gemadão',
  gemadas: 'gemadas',
  gemador: 'gemador',
  gemados: 'gemados',
  gemagem: 'gemagem',
  gemamos: 'gemamos',
  gemando: 'gemando',
  gemante: 'gemante',
  gemaram: 'gemaram',
  gemarao: 'gemarão',
  gemaras: 'gemaras',
  gemarei: 'gemarei',
  gemarem: 'gemarem',
  gemares: 'gemares',
  gemaria: 'gemaria',
  gemario: 'gemário',
  gemarmo: 'gemarmo',
  gemasse: 'gemasse',
  gemaste: 'gemaste',
  gemavam: 'gemavam',
  gemavas: 'gemavas',
  gemeada: 'gemeada',
  gemeado: 'gemeado',
  gemeais: 'gemeais',
  gemeara: 'gemeará',
  gemeava: 'gemeava',
  gemedor: 'gemedor',
  gemeeis: 'gemeeis',
  gemeiam: 'gemeiam',
  gemeias: 'gemeias',
  gemeiem: 'gemeiem',
  gemeies: 'gemeies',
  gemelar: 'gemelar',
  gemelga: 'gemelgá',
  gemelgo: 'gemelgo',
  gemelos: 'gemelos',
  gememos: 'gememos',
  gemendo: 'gemendo',
  gemente: 'gemente',
  gemeram: 'gemeram',
  gemerao: 'gemerão',
  gemeras: 'gemeras',
  gemerei: 'gemerei',
  gemerem: 'gemerem',
  gemeres: 'gemeres',
  gemeria: 'gemeria',
  gemermo: 'gemermo',
  gemesse: 'gemesse',
  gemeste: 'gemeste',
  gemiada: 'gemiada',
  gemiado: 'gemiado',
  gemiais: 'gemiais',
  gemiamo: 'gemiamo',
  gemiara: 'gemiará',
  gemiava: 'gemiava',
  gemicai: 'gemicai',
  gemicam: 'gemicam',
  gemicar: 'gemicar',
  gemicas: 'gemicas',
  gemicou: 'gemicou',
  gemidao: 'gemidão',
  gemidas: 'gemidas',
  gemidor: 'gemidor',
  gemidos: 'gemidos',
  gemieis: 'gemíeis',
  gemiemo: 'gemiemo',
  geminai: 'geminai',
  geminam: 'geminam',
  geminar: 'geminar',
  geminas: 'geminas',
  geminei: 'geminei',
  geminem: 'geminem',
  gemines: 'gemines',
  geminos: 'gêminos',
  geminou: 'geminou',
  gemique: 'gemique',
  gemitoa: 'gemítoa',
  gemulai: 'gemulai',
  gemulam: 'gemulam',
  gemular: 'gemular',
  gemulas: 'gêmulas',
  gemulei: 'gemulei',
  gemulem: 'gemulem',
  gemules: 'gemules',
  gemulou: 'gemulou',
  gemursa: 'gemursa',
  genarca: 'genarca',
  genaria: 'genária',
  genaune: 'genaune',
  genauno: 'genauno',
  genebra: 'genebra',
  genebro: 'genebro',
  genepis: 'genepis',
  general: 'general',
  generea: 'genereá',
  generia: 'genéria',
  generis: 'generis',
  generos: 'gêneros',
  geneses: 'gêneses',
  genesim: 'genesim',
  genesis: 'gênesis',
  genetas: 'genetas',
  genetia: 'genetia',
  gengele: 'gengele',
  gengiva: 'gengiva',
  geniais: 'geniais',
  geniana: 'geniana',
  geniano: 'geniano',
  genicas: 'gênicas',
  genicos: 'gênicos',
  geninas: 'geninas',
  geninha: 'geninha',
  geniosa: 'geniosa',
  genioso: 'genioso',
  genipas: 'genipas',
  genista: 'genista',
  genital: 'genital',
  genitas: 'gênitas',
  genitor: 'genitor',
  genitos: 'gênitos',
  genocia: 'genócia',
  genoesa: 'genoesa',
  genomas: 'genomas',
  genomos: 'genomos',
  genotia: 'genótia',
  genoves: 'genovês',
  genovez: 'genovez',
  genreai: 'genreai',
  genrear: 'genrear',
  genreei: 'genreei',
  genreia: 'genreia',
  genreie: 'genreie',
  genreio: 'genreio',
  genreou: 'genreou',
  gentaca: 'gentaça',
  gentama: 'gentama',
  gentias: 'gentias',
  gentios: 'gentios',
  gentita: 'gentita',
  gentuca: 'gentuça',
  genuate: 'genuate',
  genuesa: 'genuesa',
  genuina: 'genuína',
  genuino: 'genuíno',
  geobata: 'geóbata',
  geobate: 'geóbate',
  geobios: 'geóbios',
  geococo: 'geococo',
  geodeta: 'geodeta',
  geodina: 'geodina',
  geodite: 'geodite',
  geofaga: 'geófaga',
  geofago: 'geófago',
  geofila: 'geofila',
  geofilo: 'geofilo',
  geofita: 'geófita',
  geofito: 'geófito',
  geofone: 'geofone',
  geofono: 'geofono',
  geogalo: 'geogalo',
  geogena: 'geógena',
  geogeno: 'geógeno',
  geoicas: 'geoicas',
  geoides: 'geoides',
  geolhos: 'geolhos',
  geologa: 'geóloga',
  geologo: 'geólogo',
  geomoro: 'geômoro',
  geonima: 'geônima',
  geonimo: 'geônimo',
  geonoma: 'geonoma',
  geonomo: 'geônomo',
  georama: 'georama',
  geosita: 'geosita',
  geotomo: 'geótomo',
  gepidas: 'gépidas',
  geracao: 'geração',
  geradao: 'geradão',
  geradas: 'geradas',
  gerador: 'gerador',
  gerados: 'gerados',
  geramos: 'geramos',
  geranas: 'geranas',
  gerando: 'gerando',
  geranio: 'gerânio',
  geranos: 'geranos',
  gerante: 'gerante',
  geraram: 'geraram',
  gerarao: 'gerarão',
  geraras: 'geraras',
  gerarei: 'gerarei',
  gerarem: 'gerarem',
  gerares: 'gerares',
  geraria: 'geraria',
  gerarmo: 'gerarmo',
  gerasse: 'gerasse',
  geraste: 'geraste',
  geravam: 'geravam',
  geravas: 'geravas',
  gerbera: 'gérbera',
  gerbilo: 'gerbilo',
  gerboes: 'gerbões',
  gerecam: 'gereçam',
  gerecas: 'gereças',
  gerecei: 'gerecei',
  gerecem: 'gerecem',
  gerecer: 'gerecer',
  gereces: 'gereces',
  gereceu: 'gereceu',
  gerecia: 'gerecia',
  gereira: 'gereira',
  gereiro: 'gereiro',
  geremos: 'geremos',
  gerente: 'gerente',
  geresao: 'geresão',
  geresas: 'geresãs',
  gerezas: 'gerezas',
  geribes: 'geribés',
  gerical: 'gerical',
  gericas: 'gericas',
  gericos: 'gericos',
  geridao: 'geridão',
  geridas: 'geridas',
  geridor: 'geridor',
  geridos: 'geridos',
  gerieis: 'geríeis',
  gerimos: 'gerimos',
  gerindo: 'gerindo',
  geriram: 'geriram',
  gerirao: 'gerirão',
  geriras: 'geriras',
  gerirei: 'gerirei',
  gerirem: 'gerirem',
  gerires: 'gerires',
  geriria: 'geriria',
  gerirmo: 'gerirmo',
  gerisse: 'gerisse',
  geriste: 'geriste',
  germana: 'germana',
  germane: 'germane',
  germano: 'germano',
  germelo: 'germelo',
  germens: 'germens',
  germina: 'germina',
  germine: 'germine',
  germino: 'germino',
  germoes: 'germões',
  geronas: 'geronas',
  geronte: 'geronte',
  geronto: 'geronto',
  gerrida: 'gérrida',
  gersais: 'gersais',
  gerusia: 'gerúsia',
  gervoes: 'gervões',
  gerzeli: 'gerzeli',
  gesates: 'gesates',
  gesatos: 'gesatos',
  gesonia: 'gesônia',
  gessada: 'gessada',
  gessado: 'gessado',
  gessais: 'gessais',
  gessamo: 'gessamo',
  gessara: 'gessará',
  gessava: 'gessava',
  gesseis: 'gesseis',
  gessemo: 'gessemo',
  gessete: 'gessete',
  gessosa: 'gessosa',
  gessoso: 'gessoso',
  gestada: 'gestada',
  gestado: 'gestado',
  gestais: 'gestais',
  gestalt: 'gestalt',
  gestamo: 'gestamo',
  gestapo: 'gestapo',
  gestara: 'gestara',
  gestava: 'gestava',
  gesteis: 'gesteis',
  gestema: 'gestema',
  gestemo: 'gestemo',
  gestica: 'géstica',
  gestico: 'géstico',
  gestoes: 'gestões',
  gestora: 'gestora',
  gestose: 'gestose',
  gestual: 'gestual',
  gestuno: 'gestuno',
  geteias: 'geteias',
  geticas: 'géticas',
  geticos: 'géticos',
  getulos: 'getulos',
  gezinho: 'gêzinho',
  giardia: 'giardia',
  giatica: 'giática',
  giatico: 'giático',
  gibarra: 'gibarra',
  gibatao: 'gibatão',
  giberna: 'giberna',
  gibosas: 'gibosas',
  gibosos: 'gibosos',
  gibsita: 'gibsita',
  gibsite: 'gibsite',
  gibulas: 'gíbulas',
  gicebis: 'gicebis',
  gideoes: 'gideões',
  gidiana: 'gidiana',
  gidiano: 'gidiano',
  gidouro: 'gidouro',
  giestal: 'giestal',
  giestas: 'giestas',
  giestos: 'giestos',
  gigabit: 'gigabit',
  giganho: 'giganho',
  giganta: 'giganta',
  gigante: 'gigante',
  gigaton: 'gigaton',
  gigogas: 'gigogas',
  gigoias: 'gigoias',
  gigolos: 'gigolôs',
  gigotes: 'gigotes',
  giguefo: 'giguefo',
  gigurro: 'gigurro',
  gilbert: 'gilbert',
  gilenia: 'gilênia',
  gilense: 'gilense',
  giletas: 'giletas',
  giletes: 'giletes',
  gilista: 'gilista',
  gimboas: 'gimboas',
  gimboto: 'gimboto',
  gimbrar: 'gimbrar',
  gimbres: 'gimbrés',
  gimbulo: 'gimbulo',
  gimnema: 'gimnema',
  gimneta: 'gimneta',
  gimnete: 'gimnete',
  gimneto: 'gimneto',
  gimnica: 'gímnica',
  gimnico: 'gímnico',
  gimnita: 'gimnita',
  gimnite: 'gimnite',
  gimnoto: 'gimnoto',
  gimnura: 'gimnura',
  gimnuro: 'gimnuro',
  ginarca: 'ginarca',
  ginaris: 'ginaris',
  ginases: 'ginases',
  ginasio: 'ginásio',
  ginasta: 'ginasta',
  ginaste: 'ginaste',
  gincana: 'gincana',
  gincgos: 'gincgos',
  gineceu: 'gineceu',
  ginecia: 'ginécia',
  ginerio: 'ginério',
  ginetas: 'ginetas',
  ginetea: 'gineteá',
  ginetes: 'ginetes',
  ginetos: 'ginetos',
  gingada: 'gingada',
  gingado: 'gingado',
  gingais: 'gingais',
  gingamo: 'gingamo',
  gingara: 'gingara',
  gingava: 'gingava',
  gingiva: 'gingiva',
  gingoal: 'gingoal',
  gingoes: 'gingões',
  gingona: 'gingona',
  gingrai: 'gingrai',
  gingram: 'gingram',
  gingrar: 'gingrar',
  gingras: 'gingras',
  gingrei: 'gingrei',
  gingrem: 'gingrem',
  gingres: 'gingres',
  gingros: 'gingros',
  gingrou: 'gingrou',
  ginguba: 'ginguba',
  ginguei: 'ginguei',
  ginguem: 'ginguem',
  gingues: 'gingues',
  ginhais: 'ginhais',
  ginjais: 'ginjais',
  ginkgos: 'ginkgos',
  ginseng: 'ginseng',
  ginsens: 'ginsens',
  ginsoes: 'ginsões',
  ginsona: 'ginsona',
  ginuras: 'ginuras',
  giolhos: 'giolhos',
  gipaeto: 'gipaeto',
  gipetos: 'gipetos',
  gipseas: 'gípseas',
  gipseos: 'gípseos',
  gipsina: 'gipsina',
  gipsita: 'gipsita',
  gipsite: 'gipsite',
  gipsito: 'gipsito',
  gipsosa: 'gipsosa',
  gipsoso: 'gipsoso',
  giracao: 'giração',
  giradao: 'giradão',
  giradas: 'giradas',
  girador: 'girador',
  girados: 'girados',
  girafas: 'girafas',
  giraita: 'giraita',
  girames: 'girames',
  giramos: 'giramos',
  girando: 'girando',
  girante: 'girante',
  giraram: 'giraram',
  girarao: 'girarão',
  giraras: 'giraras',
  girarei: 'girarei',
  girarem: 'girarem',
  girares: 'girares',
  giraria: 'giraria',
  girarmo: 'girarmo',
  girasol: 'girasol',
  girasse: 'girasse',
  giraste: 'giraste',
  giratas: 'giratas',
  giravam: 'giravam',
  giravas: 'giravas',
  girbafo: 'girbafo',
  girboes: 'girbões',
  gireias: 'gireias',
  girelas: 'girelas',
  giremos: 'giremos',
  girento: 'girento',
  girezas: 'girezas',
  girices: 'girices',
  girimum: 'girimum',
  girinos: 'girinos',
  girobus: 'girobus',
  giroeta: 'giroeta',
  girofle: 'girofle',
  giroide: 'giroide',
  girolas: 'girolas',
  giromas: 'giromas',
  gironda: 'gironda',
  gironea: 'girônea',
  gironeo: 'girôneo',
  giropos: 'giropos',
  giroses: 'giroses',
  girotes: 'girotes',
  girotos: 'girotos',
  girreia: 'girreia',
  girreus: 'girreus',
  girumba: 'girumba',
  gisnada: 'gisnada',
  gisnado: 'gisnado',
  gitagos: 'gitagos',
  gitanas: 'gitanas',
  gitania: 'gitânia',
  gitanos: 'gitanos',
  gitinas: 'gitinas',
  gizadao: 'gizadão',
  gizadas: 'gizadas',
  gizador: 'gizador',
  gizados: 'gizados',
  gizamos: 'gizamos',
  gizando: 'gizando',
  gizante: 'gizante',
  gizaram: 'gizaram',
  gizarao: 'gizarão',
  gizaras: 'gizarás',
  gizarei: 'gizarei',
  gizarem: 'gizarem',
  gizares: 'gizares',
  gizaria: 'gizaria',
  gizarmo: 'gizarmo',
  gizasse: 'gizasse',
  gizaste: 'gizaste',
  gizavam: 'gizavam',
  gizavas: 'gizavas',
  gizemos: 'gizemos',
  gizinho: 'gizinho',
  gizirao: 'gizirão',
  glabela: 'glabela',
  glabras: 'glabras',
  glabros: 'glabros',
  glacada: 'glaçada',
  glacado: 'glaçado',
  glacais: 'glaçais',
  glacamo: 'glaçamo',
  glacara: 'glaçara',
  glacava: 'glaçava',
  glaceis: 'glaceis',
  glacemo: 'glacemo',
  glacial: 'glacial',
  glaciam: 'glaciam',
  glaciar: 'glaciar',
  glacido: 'glácido',
  glaciou: 'glaciou',
  gladiai: 'gladiai',
  gladiam: 'gladiam',
  gladiar: 'gladiar',
  gladias: 'gladias',
  gladiei: 'gladiei',
  gladiem: 'gladiem',
  gladies: 'gladies',
  gladios: 'gládios',
  gladiou: 'gladiou',
  gladita: 'gladita',
  glafica: 'gláfica',
  glafico: 'gláfico',
  glamour: 'glamour',
  glandes: 'glandes',
  glaozao: 'glãozão',
  glastos: 'glastos',
  glasura: 'glasura',
  glaucas: 'glaucas',
  glaucia: 'gláucia',
  glaucio: 'gláucio',
  glaucos: 'glaucos',
  glecoma: 'glecoma',
  glerina: 'glerina',
  glicada: 'glicada',
  glicado: 'glicado',
  glicana: 'glicana',
  glicano: 'glicano',
  glicase: 'glicase',
  glicato: 'glicato',
  glicera: 'glícera',
  glicica: 'glícica',
  glicico: 'glícico',
  glicide: 'glícide',
  glicido: 'glícido',
  glicila: 'glicila',
  glicina: 'glicina',
  glicine: 'glicine',
  glicois: 'glicóis',
  glicose: 'glicose',
  glifeia: 'glifeia',
  glifica: 'glífica',
  glifico: 'glífico',
  glifode: 'glifode',
  gliglos: 'gliglôs',
  gliomas: 'gliomas',
  glioses: 'glioses',
  glioxal: 'glioxal',
  gliptal: 'gliptal',
  glissai: 'glissai',
  glissam: 'glissam',
  glissar: 'glissar',
  glissas: 'glissas',
  glissei: 'glissei',
  glissem: 'glissem',
  glisses: 'glisses',
  glissou: 'glissou',
  glizina: 'glizina',
  globais: 'globais',
  globeas: 'glóbeas',
  globias: 'globias',
  globina: 'globina',
  globosa: 'globosa',
  globoso: 'globoso',
  globulo: 'glóbulo',
  glocais: 'glocais',
  glomera: 'glomera',
  glomere: 'glômere',
  glomero: 'glomero',
  glorear: 'glorear',
  gloriai: 'gloriai',
  glorial: 'glorial',
  gloriam: 'gloriam',
  gloriar: 'gloriar',
  glorias: 'glórias',
  glorica: 'glórica',
  glorico: 'glórico',
  gloriei: 'gloriei',
  gloriem: 'gloriem',
  glories: 'glories',
  gloriou: 'gloriou',
  glosada: 'glosada',
  glosado: 'glosado',
  glosais: 'glosais',
  glosamo: 'glosamo',
  glosara: 'glosara',
  glosava: 'glosava',
  gloseis: 'gloseis',
  glosemo: 'glosemo',
  glossai: 'glossai',
  glossam: 'glossam',
  glossar: 'glossar',
  glossas: 'glossas',
  glossei: 'glossei',
  glossem: 'glossem',
  glosses: 'glosses',
  glossou: 'glossou',
  glotais: 'glotais',
  glotera: 'glotera',
  glotere: 'glotere',
  glotero: 'glotero',
  glotica: 'glótica',
  glotico: 'glótico',
  glotide: 'glótide',
  glotite: 'glotite',
  glotiza: 'glotizá',
  glotize: 'glotize',
  glotizo: 'glotizo',
  glotora: 'glotora',
  glotore: 'glotore',
  glotoro: 'glotoro',
  glotula: 'glótula',
  glucase: 'glucase',
  glucida: 'glúcida',
  glucide: 'glúcide',
  glucido: 'glúcido',
  glucina: 'glucina',
  glucose: 'glucose',
  gluglus: 'gluglus',
  glumada: 'glumada',
  glumado: 'glumado',
  glumela: 'glumela',
  gluteas: 'glúteas',
  glutens: 'glútens',
  gluteos: 'glúteos',
  glutina: 'glutina',
  glutine: 'glutine',
  glutino: 'glutino',
  glutite: 'glutite',
  glutoes: 'glutões',
  glutona: 'glutona',
  gnaisse: 'gnaisse',
  gnatide: 'gnátide',
  gneisse: 'gneisse',
  gnetale: 'gnetale',
  gnomica: 'gnômica',
  gnomico: 'gnômico',
  gnomone: 'gnômone',
  gnosias: 'gnosias',
  gnossio: 'gnóssio',
  goajuru: 'goajuru',
  goanana: 'goananá',
  goanesa: 'goanesa',
  gobelim: 'gobelim',
  gobiana: 'gobiana',
  gobidea: 'gobídea',
  gobideo: 'gobídeo',
  gobiida: 'gobíida',
  gobioes: 'gobiões',
  gocetes: 'gocetes',
  godalha: 'godalha',
  godecia: 'godécia',
  godelos: 'godelos',
  godemes: 'godemes',
  godemos: 'godemos',
  godenho: 'godenho',
  goderai: 'goderai',
  goderam: 'goderam',
  goderar: 'goderar',
  goderas: 'goderas',
  goderei: 'goderei',
  goderem: 'goderem',
  goderes: 'goderes',
  goderim: 'goderim',
  goderio: 'godério',
  goderou: 'goderou',
  godetia: 'godétia',
  godiada: 'godiada',
  godiado: 'godiado',
  godiais: 'godiais',
  godiamo: 'godiamo',
  godiara: 'godiará',
  godiava: 'godiava',
  godieis: 'godieis',
  godiemo: 'godiemo',
  godilho: 'godilho',
  godioes: 'godiões',
  godoias: 'godoias',
  godorim: 'godorim',
  godoyas: 'godoyas',
  godrins: 'godrins',
  goelada: 'goelada',
  goelado: 'goelado',
  goelais: 'goelais',
  goelamo: 'goelamo',
  goelana: 'goelana',
  goelano: 'goelano',
  goelara: 'goelará',
  goelava: 'goelava',
  goeleis: 'goeleis',
  goelemo: 'goelemo',
  goenses: 'goenses',
  goeteas: 'goéteas',
  goethia: 'goéthia',
  goetias: 'goétias',
  goetita: 'goetita',
  goetoes: 'goetões',
  gofaino: 'gofaino',
  gofrada: 'gofrada',
  gofrado: 'gofrado',
  gofrais: 'gofrais',
  goframo: 'goframo',
  gofrara: 'gofrará',
  gofrava: 'gofrava',
  gofreis: 'gofreis',
  gofremo: 'gofremo',
  gogadas: 'gogadas',
  gogogos: 'gogogôs',
  gogoias: 'gogoias',
  gogosas: 'gogosas',
  gogosos: 'gogosos',
  goiabal: 'goiabal',
  goiabas: 'goiabas',
  goiacas: 'goiacas',
  goiadao: 'goiadão',
  goiadas: 'goiadas',
  goiador: 'goiador',
  goiados: 'goiados',
  goiaite: 'goiaíte',
  goiaito: 'goiaíto',
  goiamos: 'goiamos',
  goiamum: 'goiamum',
  goiamus: 'goiamus',
  goianas: 'goianas',
  goiando: 'goiando',
  goianos: 'goianos',
  goiante: 'goiante',
  goiaram: 'goiaram',
  goiarao: 'goiarão',
  goiaras: 'goiarás',
  goiarei: 'goiarei',
  goiarem: 'goiarem',
  goiares: 'goiares',
  goiaria: 'goiaria',
  goiarmo: 'goiarmo',
  goiases: 'goiases',
  goiasse: 'goiasse',
  goiaste: 'goiaste',
  goiauna: 'goiaúna',
  goiavam: 'goiavam',
  goiavas: 'goiavas',
  goiemos: 'goiemos',
  goiense: 'goiense',
  goiesca: 'goiesca',
  goiesco: 'goiesco',
  goiloes: 'goilões',
  goipava: 'goipava',
  goipeba: 'goipeba',
  goirana: 'goirana',
  goitaca: 'goitacá',
  goitada: 'goitada',
  goitado: 'goitado',
  goitais: 'goitais',
  goitamo: 'goitamo',
  goitara: 'goitará',
  goitava: 'goitava',
  goiteis: 'goiteis',
  goitemo: 'goitemo',
  goivada: 'goivada',
  goivado: 'goivado',
  goivais: 'goivais',
  goivamo: 'goivamo',
  goivara: 'goivara',
  goivava: 'goivava',
  goiveis: 'goiveis',
  goivemo: 'goivemo',
  goivete: 'goivete',
  goivira: 'goivira',
  gojobas: 'gojobas',
  golacos: 'golaços',
  goladao: 'goladão',
  goladas: 'goladas',
  golador: 'golador',
  golados: 'golados',
  golamos: 'golamos',
  golando: 'golando',
  golango: 'golango',
  golante: 'golante',
  golaram: 'golaram',
  golarao: 'golarão',
  golaras: 'golaras',
  golarei: 'golarei',
  golarem: 'golarem',
  golares: 'golares',
  golaria: 'golaria',
  golarmo: 'golarmo',
  golasse: 'golasse',
  golaste: 'golaste',
  golavam: 'golavam',
  golavas: 'golavas',
  golcori: 'golcori',
  goldres: 'goldres',
  goleada: 'goleada',
  goleado: 'goleado',
  goleais: 'goleais',
  goleamo: 'goleamo',
  goleara: 'goleara',
  goleava: 'goleava',
  goleeis: 'goleeis',
  goleemo: 'goleemo',
  goleiam: 'goleiam',
  goleias: 'goleias',
  goleiem: 'goleiem',
  goleies: 'goleies',
  goleira: 'goleira',
  goleiro: 'goleiro',
  golejai: 'golejai',
  golejam: 'golejam',
  golejar: 'golejar',
  golejas: 'golejas',
  golejei: 'golejei',
  golejem: 'golejem',
  golejes: 'golejes',
  golejou: 'golejou',
  golelha: 'golelha',
  golelhe: 'golelhe',
  golelho: 'golelho',
  golemos: 'golemos',
  goletas: 'goletas',
  golfada: 'golfada',
  golfado: 'golfado',
  golfais: 'golfais',
  golfamo: 'golfamo',
  golfara: 'golfará',
  golfava: 'golfava',
  golfeis: 'golfeis',
  golfeja: 'golfeja',
  golfeje: 'golfeje',
  golfejo: 'golfejo',
  golfemo: 'golfemo',
  golfins: 'golfins',
  golfoes: 'golfões',
  golgota: 'gólgota',
  goliato: 'goliato',
  golilha: 'golilha',
  golinha: 'golinha',
  golinho: 'golinho',
  golipai: 'golipai',
  golipam: 'golipam',
  golipao: 'golipão',
  golipar: 'golipar',
  golipas: 'golipas',
  golipei: 'golipei',
  golipem: 'golipem',
  golipes: 'golipes',
  golipou: 'golipou',
  golmota: 'golmota',
  golpada: 'golpada',
  golpado: 'golpado',
  golpais: 'golpais',
  golpamo: 'golpamo',
  golpara: 'golpara',
  golpava: 'golpava',
  golpeai: 'golpeai',
  golpeao: 'golpeão',
  golpear: 'golpear',
  golpeei: 'golpeei',
  golpeia: 'golpeia',
  golpeie: 'golpeie',
  golpeio: 'golpeio',
  golpeis: 'golpeis',
  golpemo: 'golpemo',
  golpeou: 'golpeou',
  golunda: 'golunda',
  golungo: 'golungo',
  gomacao: 'gomação',
  gomadao: 'gomadão',
  gomadas: 'gomadas',
  gomador: 'gomador',
  gomados: 'gomados',
  gomagem: 'gomagem',
  gomamos: 'gomamos',
  gomando: 'gomando',
  gomante: 'gomante',
  gomaram: 'gomaram',
  gomarao: 'gomarão',
  gomaras: 'gomaras',
  gomarei: 'gomarei',
  gomarem: 'gomarem',
  gomares: 'gomares',
  gomaria: 'gomaria',
  gomarmo: 'gomarmo',
  gomarra: 'gomarra',
  gomasse: 'gomasse',
  gomaste: 'gomaste',
  gomavam: 'gomavam',
  gomavas: 'gomavas',
  gomavel: 'gomável',
  gombela: 'gombelá',
  gombele: 'gombele',
  gombelo: 'gombelo',
  gomedar: 'gomedar',
  gomeira: 'gomeira',
  gomeiro: 'gomeiro',
  gomemos: 'gomemos',
  gomenol: 'gomenol',
  gomense: 'gomense',
  gomeras: 'gomeras',
  gomesas: 'gomesas',
  gomezas: 'gomezas',
  gomiada: 'gomiada',
  gomilho: 'gomilho',
  gominas: 'gominas',
  gomitai: 'gomitai',
  gomitam: 'gomitam',
  gomitar: 'gomitar',
  gomitas: 'gomitas',
  gomitei: 'gomitei',
  gomitem: 'gomitem',
  gomites: 'gomites',
  gomitos: 'gômitos',
  gomitou: 'gomitou',
  gomoada: 'gomoada',
  gomoado: 'gomoado',
  gomoais: 'gomoais',
  gomoamo: 'gomoamo',
  gomoara: 'gomoara',
  gomoava: 'gomoava',
  gomoeis: 'gomoeis',
  gomoemo: 'gomoemo',
  gomosas: 'gomosas',
  gomoses: 'gomoses',
  gomosos: 'gomosos',
  gomutos: 'gomutos',
  gonacio: 'gonácio',
  gonadal: 'gonadal',
  gonadao: 'gonadão',
  gonadas: 'gônadas',
  gonadia: 'gonadia',
  gonador: 'gonador',
  gonados: 'gonados',
  gonagra: 'gonagra',
  gonamos: 'gonamos',
  gonando: 'gonando',
  gonante: 'gonante',
  gonaqua: 'gonáqua',
  gonaram: 'gonaram',
  gonarao: 'gonarão',
  gonaras: 'gonarás',
  gonarca: 'gonarca',
  gonarei: 'gonarei',
  gonarem: 'gonarem',
  gonares: 'gonares',
  gonaria: 'gonaria',
  gonarmo: 'gonarmo',
  gonasse: 'gonasse',
  gonaste: 'gonaste',
  gonavam: 'gonavam',
  gonavas: 'gonavas',
  goncala: 'gonçala',
  gondiis: 'gondiis',
  gondito: 'gondito',
  gondoes: 'gondões',
  gondola: 'gôndola',
  gondole: 'gondole',
  gondolo: 'gondolo',
  gondula: 'gôndula',
  gonelas: 'gonelas',
  gonemos: 'gonemos',
  gonetes: 'gonetes',
  gonfose: 'gonfose',
  gongada: 'gongada',
  gongado: 'gongado',
  gongais: 'gongais',
  gongana: 'gongana',
  gongano: 'gongano',
  gongara: 'gongara',
  gongava: 'gongava',
  gongilo: 'gôngilo',
  gongolo: 'gongolo',
  gongoma: 'gongoma',
  gongons: 'gongons',
  gongora: 'gongora',
  gongore: 'gongore',
  gongoro: 'gongoro',
  gonguei: 'gonguei',
  gonguem: 'gonguem',
  gongues: 'gongues',
  gonguro: 'gonguro',
  goniaca: 'goníaca',
  goniaco: 'goníaco',
  goniada: 'goníada',
  gonidia: 'gonídia',
  gonidio: 'gonídio',
  gonilha: 'gonilha',
  gonimio: 'gonímio',
  goniode: 'goniode',
  gonites: 'gonites',
  goniuro: 'goniuro',
  gononos: 'gononos',
  gonorol: 'gonorol',
  gonospa: 'gonospa',
  gonzada: 'gonzada',
  gonzado: 'gonzado',
  gonzelo: 'gonzelo',
  gonzemo: 'gonzemo',
  googlar: 'googlar',
  gopiara: 'gopiara',
  gopuras: 'gopurás',
  goradao: 'goradão',
  goradas: 'goradas',
  gorador: 'gorador',
  gorados: 'gorados',
  goramos: 'goramos',
  goranas: 'goranas',
  gorando: 'gorando',
  gorante: 'gorante',
  goraram: 'goraram',
  gorarao: 'gorarão',
  goraras: 'goraras',
  gorarei: 'gorarei',
  gorarem: 'gorarem',
  gorares: 'gorares',
  goraria: 'goraria',
  gorarmo: 'gorarmo',
  gorasse: 'gorasse',
  goraste: 'goraste',
  goravam: 'goravam',
  goravas: 'goravas',
  gorazes: 'gorazes',
  gorbaga: 'gorbaga',
  gorchos: 'gorchos',
  gordaca: 'gordaça',
  gordaco: 'gordaço',
  gordias: 'górdias',
  gordieu: 'gordieu',
  gordios: 'górdios',
  gordoes: 'gordões',
  gordona: 'gordona',
  gordote: 'gordote',
  gordufo: 'gordufo',
  gordume: 'gordume',
  gordura: 'gordura',
  gordure: 'gordure',
  gorduro: 'gorduro',
  goreira: 'goreira',
  goreiro: 'goreiro',
  goremos: 'goremos',
  gorente: 'gorente',
  goretos: 'goretos',
  gorgeio: 'gorgeio',
  gorgeta: 'gorgeta',
  gorgica: 'górgica',
  gorgico: 'górgico',
  gorgoes: 'gorgões',
  gorgola: 'gorgola',
  gorgole: 'gorgole',
  gorgoli: 'gorgoli',
  gorgolo: 'gorgolo',
  gorgona: 'górgona',
  gorgone: 'górgone',
  gorgota: 'gorgota',
  gorgoto: 'gorgotó',
  goriana: 'goriana',
  gorilao: 'gorilão',
  gorilas: 'gorilas',
  gorilha: 'gorilha',
  gorinos: 'gorinos',
  goritas: 'goritas',
  gorites: 'gorites',
  gorjais: 'gorjais',
  gorjala: 'gorjala',
  gorjeai: 'gorjeai',
  gorjear: 'gorjear',
  gorjeei: 'gorjeei',
  gorjeia: 'gorjeia',
  gorjeie: 'gorjeie',
  gorjeio: 'gorjeio',
  gorjeis: 'gorjéis',
  gorjeou: 'gorjeou',
  gorjeta: 'gorjeta',
  gorjete: 'gorjete',
  gorjilo: 'gorjilo',
  gorjoes: 'gorjões',
  gornais: 'gornais',
  gornamo: 'gornamo',
  gorniam: 'gorniam',
  gornias: 'gornias',
  gornida: 'gornida',
  gornido: 'gornido',
  gornimo: 'gornimo',
  gornira: 'gornira',
  gornope: 'gornope',
  goronas: 'goronas',
  gorongo: 'gorongo',
  gorotil: 'gorotil',
  gorotis: 'gorotis',
  gorrada: 'gorrada',
  gorrado: 'gorrado',
  gorrais: 'gorrais',
  gorramo: 'gorramo',
  gorrara: 'gorrara',
  gorrava: 'gorrava',
  gorreis: 'gorreis',
  gorremo: 'gorremo',
  gorriao: 'gorrião',
  gorrixo: 'gorrixo',
  gorvata: 'gorvata',
  gorviao: 'gorvião',
  gosmada: 'gosmada',
  gosmado: 'gosmado',
  gosmais: 'gosmais',
  gosmamo: 'gosmamo',
  gosmara: 'gosmará',
  gosmava: 'gosmava',
  gosmeis: 'gosmeis',
  gosmemo: 'gosmemo',
  gosmoes: 'gosmões',
  gosmosa: 'gosmosa',
  gosmoso: 'gosmoso',
  gospeis: 'gospeis',
  gosseia: 'gosseia',
  gostada: 'gostada',
  gostado: 'gostado',
  gostais: 'gostais',
  gostamo: 'gostamo',
  gostara: 'gostara',
  gostava: 'gostava',
  gosteis: 'gosteis',
  gostemo: 'gostemo',
  gostosa: 'gostosa',
  gostoso: 'gostoso',
  gotadas: 'gotadas',
  gotados: 'gotados',
  goteada: 'goteada',
  goteado: 'goteado',
  goteais: 'goteais',
  goteara: 'goteará',
  goteava: 'goteava',
  goteeis: 'goteeis',
  goteiam: 'goteiam',
  goteias: 'goteias',
  goteiem: 'goteiem',
  goteies: 'goteies',
  goteira: 'goteira',
  goteiro: 'goteiro',
  gotejai: 'gotejai',
  gotejam: 'gotejam',
  gotejar: 'gotejar',
  gotejas: 'gotejas',
  gotejei: 'gotejei',
  gotejem: 'gotejem',
  gotejes: 'gotejes',
  gotejou: 'gotejou',
  goticas: 'góticas',
  goticos: 'góticos',
  gotijas: 'gotijas',
  gotingo: 'gotingo',
  gotinha: 'gotinha',
  gotinho: 'gotinho',
  gotinos: 'gotinos',
  gotismo: 'gotismo',
  gotista: 'gotista',
  gotosas: 'gotosas',
  gotosos: 'gotosos',
  gotrins: 'gotrins',
  gotulho: 'gotulho',
  gotulio: 'gotúlio',
  goturio: 'gotúrio',
  gouania: 'gouânia',
  gougada: 'gougada',
  gougado: 'gougado',
  gougais: 'gougais',
  gougara: 'gougará',
  gougava: 'gougava',
  gougres: 'gougres',
  gouguei: 'gouguei',
  gouguem: 'gouguem',
  gougues: 'gougues',
  goulash: 'goulash',
  goulina: 'goulina',
  gouloes: 'goulões',
  goulona: 'goulona',
  goundou: 'goundou',
  goupias: 'goupias',
  gourdes: 'gourdes',
  gourmet: 'gourmet',
  gouveio: 'gouveio',
  govares: 'govares',
  govenia: 'govênia',
  govento: 'govento',
  goveras: 'goveras',
  governa: 'governa',
  governe: 'governe',
  governo: 'governo',
  govetas: 'govetas',
  govetes: 'govetes',
  gozacao: 'gozação',
  gozadao: 'gozadão',
  gozadas: 'gozadas',
  gozador: 'gozador',
  gozados: 'gozados',
  gozamos: 'gozamos',
  gozando: 'gozando',
  gozante: 'gozante',
  gozaram: 'gozaram',
  gozarao: 'gozarão',
  gozaras: 'gozaras',
  gozarei: 'gozarei',
  gozarem: 'gozarem',
  gozares: 'gozares',
  gozaria: 'gozaria',
  gozarmo: 'gozarmo',
  gozasse: 'gozasse',
  gozaste: 'gozaste',
  gozavam: 'gozavam',
  gozavas: 'gozavas',
  gozavel: 'gozável',
  gozeira: 'gozeira',
  gozemos: 'gozemos',
  gozonas: 'gozonas',
  gozosas: 'gozosas',
  gozosos: 'gozosos',
  grabada: 'grabada',
  grabado: 'grabado',
  grabana: 'grabana',
  grabano: 'grabano',
  grabato: 'grabato',
  grabeia: 'grabeia',
  grabeus: 'grabeus',
  grabico: 'grabiço',
  grabosa: 'grabosa',
  gracaim: 'graçaim',
  gracais: 'graçaís',
  gracape: 'graçapé',
  graceis: 'gráceis',
  graceja: 'graceja',
  graceje: 'graceje',
  gracejo: 'gracejo',
  gracera: 'gracera',
  graceta: 'graceta',
  gracita: 'gracita',
  gracite: 'gracite',
  gracito: 'gracito',
  gracola: 'graçola',
  gracole: 'graçole',
  gracolo: 'graçolo',
  gracona: 'graçona',
  gracosa: 'graçosa',
  gracoso: 'graçoso',
  gracota: 'graçota',
  gracuis: 'gracuís',
  graculo: 'gráculo',
  gradada: 'gradada',
  gradado: 'gradado',
  gradais: 'gradais',
  gradamo: 'gradamo',
  gradaos: 'gradaós',
  gradara: 'gradara',
  gradaus: 'gradaús',
  gradava: 'gradava',
  gradeai: 'gradeai',
  gradear: 'gradear',
  gradeca: 'gradeça',
  gradece: 'gradece',
  gradeci: 'gradeci',
  gradeco: 'gradeço',
  gradeei: 'gradeei',
  gradeia: 'gradeia',
  gradeie: 'gradeie',
  gradeio: 'gradeio',
  gradeis: 'gradeis',
  gradeja: 'gradejá',
  gradeje: 'gradeje',
  gradejo: 'gradejo',
  grademo: 'grademo',
  gradeou: 'gradeou',
  gradeza: 'gradeza',
  gradina: 'gradina',
  gradine: 'gradine',
  gradino: 'gradino',
  gradins: 'gradins',
  gradiva: 'gradiva',
  gradivo: 'gradivo',
  gradoes: 'gradões',
  graduai: 'graduai',
  gradual: 'gradual',
  graduam: 'graduam',
  graduar: 'graduar',
  graduas: 'graduas',
  graduei: 'graduei',
  graduem: 'graduem',
  gradues: 'gradues',
  graduou: 'graduou',
  gradura: 'gradura',
  graeira: 'graeira',
  graeiro: 'graeiro',
  graelos: 'graelos',
  grafada: 'grafada',
  grafado: 'grafado',
  grafais: 'grafais',
  grafamo: 'grafamo',
  grafara: 'grafara',
  grafava: 'grafava',
  grafeis: 'grafeis',
  grafema: 'grafema',
  grafemo: 'grafemo',
  grafeno: 'grafeno',
  grafias: 'grafias',
  grafica: 'gráfica',
  grafico: 'gráfico',
  grafila: 'grafila',
  grafile: 'grafile',
  grafilo: 'grafilo',
  grafios: 'gráfios',
  grafita: 'grafita',
  grafite: 'grafite',
  grafiti: 'grafiti',
  grafito: 'grafito',
  grageia: 'grageia',
  grageus: 'grageus',
  gragido: 'gragido',
  graiada: 'graiada',
  graiado: 'graiado',
  graiais: 'graiais',
  graiamo: 'graiamo',
  graiara: 'graiará',
  graiava: 'graiava',
  graicos: 'graíços',
  graieis: 'graieis',
  graiemo: 'graiemo',
  grainca: 'grainça',
  grainco: 'grainço',
  grainha: 'grainha',
  graipas: 'graipas',
  graipus: 'graipus',
  grajeia: 'grajeia',
  gralhai: 'gralhai',
  gralham: 'gralham',
  gralhao: 'gralhão',
  gralhar: 'gralhar',
  gralhas: 'gralhas',
  gralhea: 'gralheá',
  gralhei: 'gralhei',
  gralhem: 'gralhem',
  gralhes: 'gralhes',
  gralhou: 'gralhou',
  gralina: 'gralina',
  gralzao: 'gralzão',
  gramacu: 'gramaçu',
  gramada: 'gramada',
  gramado: 'gramado',
  gramaia: 'gramaia',
  gramais: 'gramais',
  gramamo: 'gramamo',
  gramara: 'gramara',
  gramata: 'gramata',
  gramava: 'gramava',
  grambes: 'grambes',
  grameal: 'grameal',
  grameis: 'grameis',
  gramema: 'gramema',
  gramemo: 'gramemo',
  gramias: 'grâmias',
  gramico: 'gramiço',
  gramilo: 'gramilo',
  gramina: 'gramina',
  gramita: 'gramita',
  gramite: 'gramite',
  gramixa: 'gramixa',
  gramoes: 'gramões',
  gramono: 'gramono',
  gramosa: 'gramosa',
  gramoso: 'gramoso',
  grampai: 'grampai',
  grampam: 'grampam',
  grampar: 'grampar',
  grampas: 'grampas',
  grampea: 'grampeá',
  grampei: 'grampei',
  grampem: 'grampem',
  grampes: 'grampes',
  grampos: 'grampos',
  grampou: 'grampou',
  granada: 'granada',
  granado: 'granado',
  granais: 'granais',
  granamo: 'granamo',
  granara: 'granara',
  granate: 'granate',
  granava: 'granava',
  grancas: 'granças',
  grandao: 'grandão',
  grandea: 'grandeá',
  grandes: 'grandes',
  grandor: 'grandor',
  graneai: 'graneai',
  granear: 'granear',
  graneei: 'graneei',
  graneia: 'graneia',
  graneie: 'graneie',
  graneio: 'graneio',
  graneis: 'granéis',
  granemo: 'granemo',
  graneou: 'graneou',
  granete: 'granete',
  granfas: 'granfas',
  granfos: 'granfos',
  grangea: 'grângea',
  granhar: 'granhar',
  graniam: 'graniam',
  granias: 'granias',
  granida: 'granida',
  granide: 'granide',
  granido: 'granido',
  granimo: 'granimo',
  granira: 'granirá',
  granise: 'granisé',
  granita: 'granita',
  granite: 'granite',
  granito: 'granito',
  graniza: 'graniza',
  granize: 'granize',
  granizo: 'granizo',
  granjas: 'granjas',
  granjea: 'granjeá',
  granjos: 'granjos',
  granois: 'granóis',
  granola: 'granola',
  granosa: 'granosa',
  granoso: 'granoso',
  granula: 'granula',
  granule: 'granule',
  granulo: 'grânulo',
  granzal: 'granzal',
  granzas: 'granzas',
  graodos: 'graodos',
  grapias: 'grápias',
  grapibu: 'grapibu',
  grapira: 'grapirá',
  grapsos: 'grapsos',
  grasnai: 'grasnai',
  grasnam: 'grasnam',
  grasnar: 'grasnar',
  grasnas: 'grasnas',
  grasnei: 'grasnei',
  grasnem: 'grasnem',
  grasnes: 'grasnes',
  grasnia: 'grasnia',
  grasnir: 'grasnir',
  grasnis: 'grasnis',
  grasniu: 'grasniu',
  grasnou: 'grasnou',
  graspas: 'graspas',
  grassai: 'grassai',
  grassam: 'grassam',
  grassar: 'grassar',
  grassas: 'grassas',
  grassei: 'grassei',
  grassem: 'grassem',
  grasses: 'grasses',
  grassia: 'grássia',
  grassou: 'grassou',
  grateai: 'grateai',
  gratear: 'gratear',
  grateei: 'grateei',
  grateia: 'grateia',
  grateie: 'grateie',
  grateio: 'grateio',
  grateou: 'grateou',
  gratina: 'gratina',
  gratine: 'gratine',
  gratino: 'gratino',
  gratula: 'gratula',
  gratule: 'gratule',
  gratulo: 'grátulo',
  grauata: 'grauatá',
  graucas: 'grauçás',
  graudas: 'graúdas',
  graudes: 'graúdes',
  graudos: 'graúdos',
  graulho: 'graúlho',
  graunas: 'graúnas',
  graunco: 'graunço',
  graunha: 'graunha',
  gravada: 'gravada',
  gravado: 'gravado',
  gravais: 'gravais',
  gravajo: 'gravajo',
  gravame: 'gravame',
  gravamo: 'gravamo',
  gravana: 'gravana',
  gravano: 'gravano',
  gravara: 'gravara',
  gravata: 'gravata',
  gravato: 'gravato',
  gravava: 'gravava',
  graveis: 'graveis',
  gravejo: 'gravejo',
  gravela: 'gravela',
  gravemo: 'gravemo',
  graveta: 'graveta',
  gravete: 'gravete',
  graveto: 'graveto',
  graveza: 'graveza',
  gravico: 'graviço',
  gravida: 'grávida',
  gravide: 'gravide',
  gravido: 'grávido',
  gravins: 'gravins',
  gravios: 'grávios',
  gravita: 'gravita',
  gravite: 'gravite',
  gravito: 'gravito',
  gravosa: 'gravosa',
  gravoso: 'gravoso',
  gravura: 'gravura',
  gravure: 'gravure',
  gravuro: 'gravuro',
  graxaim: 'graxaim',
  graxeai: 'graxeai',
  graxear: 'graxear',
  graxeei: 'graxeei',
  graxeia: 'graxeia',
  graxeie: 'graxeie',
  graxeio: 'graxeio',
  graxeou: 'graxeou',
  graxuda: 'graxuda',
  graxudo: 'graxudo',
  grazina: 'grazina',
  grazine: 'grazine',
  grazino: 'grazino',
  greciza: 'greciza',
  grecize: 'grecize',
  grecizo: 'grecizo',
  gredosa: 'gredosa',
  gredoso: 'gredoso',
  gregais: 'gregais',
  gregoge: 'gregoge',
  gregues: 'greguês',
  gregueu: 'gregueu',
  greides: 'greides',
  greiras: 'greiras',
  greiros: 'greiros',
  grelada: 'grelada',
  grelado: 'grelado',
  grelais: 'grelais',
  grelamo: 'grelamo',
  grelara: 'grelara',
  grelava: 'grelava',
  greleis: 'greleis',
  grelemo: 'grelemo',
  grelhai: 'grelhai',
  grelham: 'grelham',
  grelhar: 'grelhar',
  grelhas: 'grelhas',
  grelhei: 'grelhei',
  grelhem: 'grelhem',
  grelhes: 'grelhes',
  grelhou: 'grelhou',
  grelina: 'grelina',
  greloes: 'grelões',
  gremial: 'gremial',
  gremios: 'grêmios',
  grempas: 'grempas',
  grenada: 'grenada',
  grenado: 'grenado',
  grencho: 'grencho',
  grenhas: 'grenhas',
  grenhos: 'grenhos',
  grescas: 'grescas',
  gresosa: 'gresosa',
  gresoso: 'gresoso',
  gretada: 'gretada',
  gretado: 'gretado',
  gretais: 'gretais',
  gretamo: 'gretamo',
  gretara: 'gretará',
  gretava: 'gretava',
  greteis: 'greteis',
  gretemo: 'gretemo',
  grevada: 'grevada',
  grevado: 'grevado',
  grevias: 'grévias',
  greviea: 'grevíea',
  greyias: 'gréyias',
  gricena: 'gricena',
  grichas: 'grichas',
  grichos: 'grichos',
  gricuas: 'grícuas',
  gridefe: 'gridefé',
  grielos: 'grielos',
  grifada: 'grifada',
  grifado: 'grifado',
  grifais: 'grifais',
  grifamo: 'grifamo',
  grifara: 'grifara',
  grifava: 'grifava',
  grifeas: 'grífeas',
  grifeis: 'grifeis',
  grifemo: 'grifemo',
  grifica: 'grífica',
  grifico: 'grífico',
  grifita: 'grifita',
  grifons: 'grifons',
  grigris: 'grigris',
  grijons: 'grijons',
  grilada: 'grilada',
  grilado: 'grilado',
  grilais: 'grilais',
  grilamo: 'grilamo',
  grilara: 'grilara',
  grilava: 'grilava',
  griledo: 'griledo',
  grileis: 'grileis',
  grilemo: 'grilemo',
  grilhao: 'grilhão',
  grilhas: 'grilhas',
  grilhos: 'grilhos',
  griliam: 'griliam',
  grilias: 'grilias',
  grilida: 'grílida',
  grilido: 'grilido',
  grilimo: 'grilimo',
  grilira: 'grilirá',
  grilode: 'grilode',
  grimaca: 'grimaça',
  grimeas: 'grímeas',
  grimias: 'grímias',
  grimiea: 'grimíea',
  grimmia: 'grímmia',
  grimpai: 'grimpai',
  grimpam: 'grimpam',
  grimpar: 'grimpar',
  grimpas: 'grimpas',
  grimpei: 'grimpei',
  grimpem: 'grimpem',
  grimpes: 'grimpes',
  grimpou: 'grimpou',
  grinfai: 'grinfai',
  grinfam: 'grinfam',
  grinfar: 'grinfar',
  grinfas: 'grinfas',
  grinfei: 'grinfei',
  grinfem: 'grinfem',
  grinfes: 'grinfes',
  grinfos: 'grinfos',
  grinfou: 'grinfou',
  gringal: 'gringal',
  gringas: 'gringas',
  gringos: 'gringos',
  gripada: 'gripada',
  gripado: 'gripado',
  gripais: 'gripais',
  gripamo: 'gripamo',
  gripara: 'gripará',
  gripava: 'gripava',
  gripeis: 'gripeis',
  gripemo: 'gripemo',
  gripina: 'gripina',
  gripose: 'gripose',
  gripote: 'gripote',
  grisada: 'grisada',
  grisado: 'grisado',
  grisais: 'grisais',
  grisamo: 'grisamo',
  grisara: 'grisara',
  grisava: 'grisava',
  griseas: 'gríseas',
  griseia: 'griseia',
  griseis: 'griseis',
  grisemo: 'grisemo',
  griseos: 'gríseos',
  griseta: 'griseta',
  griseus: 'griséus',
  grisoas: 'grisoas',
  grisoes: 'grisões',
  grisona: 'grisona',
  grisota: 'grisota',
  gritada: 'gritada',
  gritado: 'gritado',
  gritais: 'gritais',
  gritamo: 'gritamo',
  gritara: 'gritara',
  gritava: 'gritava',
  griteis: 'griteis',
  gritemo: 'gritemo',
  gritoes: 'gritões',
  gritona: 'gritona',
  grivada: 'grivada',
  grivado: 'grivado',
  grivais: 'grivais',
  grivamo: 'grivamo',
  grivara: 'grivará',
  grivava: 'grivava',
  griveis: 'griveis',
  grivemo: 'grivemo',
  grizeta: 'grizeta',
  grobias: 'gróbias',
  grocais: 'groçaís',
  grochos: 'grochos',
  grodote: 'grodote',
  grogoio: 'grogoió',
  grogojo: 'grogojó',
  grogoto: 'grogotó',
  grogues: 'grogues',
  grolada: 'grolada',
  grolado: 'grolado',
  grolais: 'grolais',
  grolamo: 'grolamo',
  grolara: 'grolara',
  grolava: 'grolava',
  groleis: 'groleis',
  grolemo: 'grolemo',
  gromada: 'gromada',
  gromado: 'gromado',
  gromais: 'gromais',
  gromamo: 'gromamo',
  gromara: 'gromara',
  gromava: 'gromava',
  gromeis: 'gromeis',
  gromemo: 'gromemo',
  gromias: 'grômias',
  gromida: 'grômida',
  grongai: 'grongai',
  grongam: 'grongam',
  grongar: 'grongar',
  grongas: 'grongas',
  grongou: 'grongou',
  grongue: 'grongue',
  gronhos: 'gronhos',
  gronjas: 'gronjas',
  gronope: 'gronope',
  grosada: 'grosada',
  grosado: 'grosado',
  grosais: 'grosais',
  grosamo: 'grosamo',
  grosara: 'grosará',
  grosava: 'grosava',
  groseis: 'groseis',
  grosemo: 'grosemo',
  grosmai: 'grosmai',
  grosmam: 'grosmam',
  grosmar: 'grosmar',
  grosmas: 'grosmas',
  grosmei: 'grosmei',
  grosmem: 'grosmem',
  grosmes: 'grosmes',
  grosmou: 'grosmou',
  grossar: 'grossar',
  grossas: 'grossas',
  grossor: 'grossor',
  grossos: 'grossos',
  grotiao: 'grotião',
  grotita: 'grotita',
  grotoes: 'grotões',
  grovios: 'gróvios',
  gruamos: 'gruamos',
  gruaria: 'gruaria',
  grudada: 'grudada',
  grudado: 'grudado',
  grudais: 'grudais',
  grudamo: 'grudamo',
  grudara: 'grudara',
  grudava: 'grudava',
  grudeis: 'grudeis',
  grudemo: 'grudemo',
  grueira: 'grueira',
  grueiro: 'grueiro',
  grugrus: 'grugrus',
  gruidao: 'gruidão',
  gruidas: 'gruídas',
  gruideo: 'gruídeo',
  gruidor: 'gruidor',
  gruidos: 'gruídos',
  gruieis: 'gruíeis',
  gruimos: 'gruímos',
  gruindo: 'gruindo',
  gruiram: 'gruíram',
  gruirao: 'gruirão',
  gruiras: 'gruíras',
  gruirei: 'gruirei',
  gruirem: 'gruírem',
  gruires: 'gruíres',
  gruiria: 'gruiria',
  gruisse: 'gruísse',
  gruiste: 'gruíste',
  grulhai: 'grulhai',
  grulham: 'grulham',
  grulhar: 'grulhar',
  grulhas: 'grulhas',
  grulhei: 'grulhei',
  grulhem: 'grulhem',
  grulhes: 'grulhes',
  grulhou: 'grulhou',
  grumada: 'grumada',
  grumado: 'grumado',
  grumais: 'grumais',
  grumamo: 'grumamo',
  grumara: 'grumará',
  grumare: 'grumaré',
  grumata: 'grumatá',
  grumati: 'grumati',
  grumava: 'grumava',
  grumeca: 'grumeça',
  grumece: 'grumece',
  grumeci: 'grumeci',
  grumeco: 'grumeço',
  grumeis: 'grumeis',
  grumelo: 'grumelo',
  grumemo: 'grumemo',
  grumete: 'grumete',
  grumixa: 'grumixá',
  grumosa: 'grumosa',
  grumoso: 'grumoso',
  grumulo: 'grúmulo',
  grunado: 'grunado',
  grunges: 'grunges',
  grunham: 'grunham',
  grunhas: 'grunhas',
  grunhem: 'grunhem',
  grunhes: 'grunhes',
  grunhia: 'grunhia',
  grunhir: 'grunhir',
  grunhis: 'grunhis',
  grunhiu: 'grunhiu',
  grupada: 'grupada',
  grupado: 'grupado',
  grupais: 'grupais',
  grupamo: 'grupamo',
  grupara: 'grupara',
  grupava: 'grupava',
  grupeis: 'grupeis',
  grupemo: 'grupemo',
  grupeto: 'grupeto',
  grupoes: 'grupões',
  gruyère: 'gruyère',
  gruziai: 'gruziai',
  gruziam: 'gruziam',
  gruziar: 'gruziar',
  gruzias: 'gruzias',
  gruziei: 'gruziei',
  gruziem: 'gruziem',
  gruzies: 'gruzies',
  gruziou: 'gruziou',
  guabiju: 'guabiju',
  guabira: 'guabira',
  guabiru: 'guabiru',
  guaburu: 'guaburu',
  guacais: 'guaçaís',
  guacapi: 'guacapi',
  guacare: 'guacaré',
  guacari: 'guacari',
  guaches: 'guaches',
  guachos: 'guachos',
  guacias: 'guaciás',
  guacina: 'guacina',
  guacini: 'guacini',
  guacuma: 'guacumã',
  guacuri: 'guacuri',
  guacuru: 'guacuru',
  guacuti: 'guaçuti',
  guadais: 'guadais',
  guadema: 'guademã',
  guadima: 'guadimá',
  guaduas: 'guaduas',
  guaiaba: 'guaiaba',
  guaiaca: 'guaiaca',
  guaiaco: 'guaiaco',
  guaiada: 'guaiada',
  guaiado: 'guaiado',
  guaiais: 'guaiais',
  guaiama: 'guaiama',
  guaiamo: 'guaiamo',
  guaiamu: 'guaiamu',
  guaiana: 'guaianá',
  guaiapa: 'guaiapá',
  guaiapo: 'guaiapó',
  guaiara: 'guaiara',
  guaiava: 'guaiava',
  guaibim: 'guaibim',
  guaicas: 'guaicás',
  guaicol: 'guaicol',
  guaieis: 'guaieis',
  guaiemo: 'guaiemo',
  guaieno: 'guaieno',
  guaimbe: 'guaimbé',
  guaiois: 'guaióis',
  guaipas: 'guaipás',
  guaipes: 'guaipés',
  guaiqui: 'guaiqui',
  guairas: 'guairás',
  guairos: 'guairós',
  guaitas: 'guaitás',
  guaiuba: 'guaiuba',
  guaiule: 'guaiule',
  guaiuva: 'guaiuva',
  guaivas: 'guaivas',
  guajaco: 'guajaco',
  guajana: 'guajaná',
  guajara: 'guajará',
  guajaru: 'guajaru',
  guajere: 'guajere',
  guajeru: 'guajeru',
  guajica: 'guajicá',
  guajira: 'guajira',
  guajiro: 'guajiro',
  guajiru: 'guajiru',
  guajuba: 'guajuba',
  guajuru: 'guajuru',
  gualdam: 'gualdam',
  gualdas: 'gualdas',
  gualdem: 'gualdem',
  gualdes: 'gualdes',
  gualdia: 'gualdia',
  gualdir: 'gualdir',
  gualdis: 'gualdis',
  gualdiu: 'gualdiu',
  gualdra: 'gualdra',
  guambas: 'guambas',
  guambus: 'guambus',
  guamesa: 'guamesa',
  guamixa: 'guamixá',
  guampas: 'guampas',
  guampea: 'guampeá',
  guampos: 'guampos',
  guanaca: 'guanacá',
  guanaco: 'guanaco',
  guanana: 'guananá',
  guanare: 'guanaré',
  guanase: 'guanase',
  guanche: 'guanche',
  guancho: 'guancho',
  guandos: 'guandos',
  guandus: 'guandus',
  guangau: 'guangau',
  guangos: 'guangos',
  guanhas: 'guanhãs',
  guanico: 'guânico',
  guanina: 'guanina',
  guanita: 'guanita',
  guantes: 'guantes',
  guapari: 'guapari',
  guapeai: 'guapeai',
  guapear: 'guapear',
  guapeba: 'guapeba',
  guapeca: 'guapeca',
  guapeei: 'guapeei',
  guapeia: 'guapeia',
  guapeie: 'guapeie',
  guapeio: 'guapeio',
  guapeou: 'guapeou',
  guapeva: 'guapeva',
  guapeza: 'guapeza',
  guapice: 'guapice',
  guapira: 'guapira',
  guapuis: 'guapuís',
  guapuri: 'guapuri',
  guaquis: 'guaquis',
  guaraba: 'guarabá',
  guarabu: 'guarabu',
  guaracu: 'guaraçu',
  guaraio: 'guaraio',
  guarais: 'guarais',
  guaraji: 'guaraji',
  guaraju: 'guaraju',
  guaramo: 'guaramo',
  guarana: 'guaraná',
  guarane: 'guarane',
  guarani: 'guarani',
  guarapa: 'guarapa',
  guarape: 'guarapé',
  guarapu: 'guarapu',
  guarata: 'guaratã',
  guarayo: 'guarayo',
  guardai: 'guardai',
  guardam: 'guardam',
  guardao: 'guardão',
  guardar: 'guardar',
  guardas: 'guardas',
  guardea: 'guardeá',
  guardei: 'guardei',
  guardem: 'guardem',
  guardes: 'guardes',
  guardia: 'guardiã',
  guardim: 'guardim',
  guardou: 'guardou',
  guareas: 'guáreas',
  guareca: 'guareça',
  guarece: 'guarece',
  guareci: 'guareci',
  guareco: 'guareço',
  guarema: 'guarema',
  guareta: 'guaretã',
  guariam: 'guariam',
  guarias: 'guarias',
  guariba: 'guariba',
  guaribu: 'guaribu',
  guarida: 'guarida',
  guarido: 'guarido',
  guarimo: 'guarimo',
  guarino: 'guarino',
  guaripe: 'guaripé',
  guaripu: 'guaripu',
  guarira: 'guarirá',
  guarita: 'guarita',
  guarite: 'guarite',
  guarito: 'guarito',
  guarixo: 'guarixó',
  guarnam: 'guarnam',
  guarnas: 'guarnas',
  guarnem: 'guarnem',
  guarnes: 'guarnes',
  guarnia: 'guarnia',
  guarnir: 'guarnir',
  guarnis: 'guarnis',
  guarniu: 'guarniu',
  guarras: 'guarras',
  guarros: 'guarros',
  guaruba: 'guaruba',
  guarubu: 'guarubu',
  guaruca: 'guaruça',
  guarula: 'guarula',
  guaruma: 'guarumá',
  guarupa: 'guarupá',
  guarupe: 'guarupé',
  guarupu: 'guarupu',
  guascai: 'guascai',
  guascam: 'guascam',
  guascar: 'guascar',
  guascas: 'guascas',
  guascou: 'guascou',
  guasque: 'guasque',
  guataia: 'guataia',
  guatapi: 'guatapi',
  guaucas: 'guaucás',
  guavata: 'guavatã',
  guavina: 'guavina',
  guaxica: 'guaxica',
  guaxima: 'guaxima',
  guaxuma: 'guaxuma',
  guaxupe: 'guaxupé',
  guazada: 'guazada',
  guazado: 'guazado',
  guazais: 'guazais',
  guazamo: 'guazamo',
  guazara: 'guazará',
  guazava: 'guazava',
  guazeis: 'guazeis',
  guazemo: 'guazemo',
  guazuis: 'guazuís',
  guazuma: 'guazuma',
  guberno: 'guberno',
  gudinha: 'gudinha',
  gudioes: 'gudiões',
  gudunho: 'gudunho',
  gueblis: 'gueblis',
  guebros: 'guebros',
  guebucu: 'guebuçu',
  gueches: 'gueches',
  guechos: 'guechos',
  guedice: 'guedice',
  guegues: 'gueguês',
  gueijos: 'gueijos',
  gueiras: 'gueiras',
  gueiros: 'gueiros',
  gueixas: 'gueixas',
  gueixos: 'gueixos',
  guelede: 'gueledé',
  guelfos: 'guelfos',
  guelmos: 'guelmos',
  guelras: 'guelras',
  guelres: 'guelres',
  guelros: 'guelros',
  guemais: 'guemais',
  guembas: 'guembas',
  guembes: 'guembês',
  guembos: 'guembos',
  guembus: 'guembus',
  guendes: 'guendes',
  guengai: 'guengai',
  guengam: 'guengam',
  guengar: 'guengar',
  guengas: 'guengas',
  guengou: 'guengou',
  guengue: 'guengue',
  guenhas: 'guenhas',
  guensos: 'guensos',
  guenzas: 'guenzas',
  guenzes: 'guenzes',
  guenzos: 'guenzos',
  guerche: 'guerche',
  guerens: 'gueréns',
  guerere: 'guererê',
  gueriri: 'gueriri',
  guerras: 'guerras',
  guerrea: 'guerreá',
  guessos: 'guessos',
  guestos: 'guestos',
  guetita: 'guetita',
  gufongo: 'gufongo',
  gugerno: 'gugerno',
  guiabas: 'guiabas',
  guiacas: 'guiacas',
  guiadao: 'guiadão',
  guiadas: 'guiadas',
  guiador: 'guiador',
  guiados: 'guiados',
  guiagem: 'guiagem',
  guiamos: 'guiamos',
  guiamus: 'guiamus',
  guianas: 'guianas',
  guiando: 'guiando',
  guianes: 'guianês',
  guiante: 'guiante',
  guiaram: 'guiaram',
  guiarao: 'guiarão',
  guiaras: 'guiaras',
  guiarei: 'guiarei',
  guiarem: 'guiarem',
  guiares: 'guiares',
  guiaria: 'guiaria',
  guiarmo: 'guiarmo',
  guiasse: 'guiasse',
  guiaste: 'guiaste',
  guiauto: 'guiauto',
  guiavam: 'guiavam',
  guiavas: 'guiavas',
  guibosa: 'guibosa',
  guiches: 'guichês',
  guichos: 'guichos',
  guicoxo: 'guicoxo',
  guicuru: 'guicuru',
  guidada: 'guidada',
  guidado: 'guidado',
  guidais: 'guidais',
  guidamo: 'guidamo',
  guidara: 'guidara',
  guidava: 'guidava',
  guideis: 'guideis',
  guidemo: 'guidemo',
  guidibo: 'guidibô',
  guidoes: 'guidões',
  guieira: 'guieira',
  guieiro: 'guieiro',
  guiemos: 'guiemos',
  guiense: 'guiense',
  guildas: 'guildas',
  guilhas: 'guilhas',
  guilhos: 'guilhos',
  guimara: 'guimara',
  guimbas: 'guimbas',
  guimbes: 'guimbés',
  guimbis: 'guimbis',
  guimeis: 'guíméis',
  guimpes: 'guimpes',
  guinada: 'guinada',
  guinado: 'guinado',
  guinais: 'guinais',
  guinamo: 'guinamo',
  guinapa: 'guinapa',
  guinara: 'guinara',
  guinaus: 'guinaús',
  guinava: 'guinava',
  guincha: 'guincha',
  guinche: 'guinche',
  guincho: 'guincho',
  guindai: 'guindai',
  guindam: 'guindam',
  guindar: 'guindar',
  guindas: 'guindas',
  guindei: 'guindei',
  guindem: 'guindem',
  guindes: 'guindes',
  guindou: 'guindou',
  guineia: 'guineia',
  guineis: 'guineis',
  guinela: 'guinela',
  guinemo: 'guinemo',
  guineta: 'guineta',
  guineus: 'guinéus',
  guingao: 'guingão',
  guingas: 'guingãs',
  guingau: 'guingau',
  guingue: 'guingue',
  guinhai: 'guinhai',
  guinham: 'guinham',
  guinhar: 'guinhar',
  guinhas: 'guinhas',
  guinhei: 'guinhei',
  guinhem: 'guinhem',
  guinhes: 'guinhes',
  guinhol: 'guinhol',
  guinhou: 'guinhou',
  guinola: 'guinola',
  guipara: 'guipara',
  guiparu: 'guiparu',
  guipura: 'guipura',
  guipure: 'guipure',
  guiquem: 'guiquém',
  guiraca: 'guiraca',
  guiraro: 'guiraró',
  guiraru: 'guiraru',
  guirata: 'guiratã',
  guirris: 'guirris',
  guirucu: 'guiruçu',
  guisada: 'guisada',
  guisado: 'guisado',
  guisais: 'guisais',
  guisamo: 'guisamo',
  guisara: 'guisará',
  guisava: 'guisava',
  guiseis: 'guiseis',
  guisemo: 'guisemo',
  guitana: 'guitana',
  guitano: 'guitano',
  guiunga: 'guiunga',
  guivras: 'guivras',
  guizada: 'guizada',
  guizado: 'guizado',
  guizais: 'guizais',
  guizamo: 'guizamo',
  guizara: 'guizara',
  guizava: 'guizava',
  guizeis: 'guizeis',
  guizemo: 'guizemo',
  gujaras: 'gujarás',
  gujires: 'gujires',
  gulache: 'gulache',
  gulaima: 'gulaima',
  gulaime: 'gulaime',
  gulaimo: 'gulaimo',
  gulalas: 'gulalas',
  gulapai: 'gulapai',
  gulapam: 'gulapam',
  gulapao: 'gulapão',
  gulapar: 'gulapar',
  gulapas: 'gulapas',
  gulapei: 'gulapei',
  gulapem: 'gulapem',
  gulapes: 'gulapes',
  gulapou: 'gulapou',
  gulares: 'gulares',
  gulazai: 'gulazai',
  gulazam: 'gulazam',
  gulazar: 'gulazar',
  gulazas: 'gulazas',
  gulazei: 'gulazei',
  gulazem: 'gulazem',
  gulazes: 'gulazes',
  gulazou: 'gulazou',
  guleima: 'guleima',
  guleime: 'guleime',
  guleimo: 'guleimo',
  guleira: 'guleira',
  gulguis: 'gulguis',
  gulosai: 'gulosai',
  gulosam: 'gulosam',
  gulosar: 'gulosar',
  gulosas: 'gulosas',
  gulosea: 'guloseá',
  gulosei: 'gulosei',
  gulosem: 'gulosem',
  guloses: 'guloses',
  gulosos: 'gulosos',
  gulosou: 'gulosou',
  gulpina: 'gulpina',
  gulungo: 'gulungo',
  guluves: 'guluves',
  gumatas: 'gumatas',
  gumates: 'gumates',
  gumenas: 'gúmenas',
  gumicas: 'gúmicas',
  gumicos: 'gúmicos',
  guminas: 'guminas',
  gumitas: 'gumitas',
  gumites: 'gumites',
  gumosas: 'gumosas',
  gumosos: 'gumosos',
  gunchos: 'gunchos',
  gundara: 'gúndara',
  gundias: 'gúndias',
  gundras: 'gundras',
  gunduru: 'gunduru',
  gunelos: 'gunelos',
  guneras: 'gúneras',
  gunfada: 'gunfada',
  gunfado: 'gunfado',
  gunfais: 'gunfais',
  gunfamo: 'gunfamo',
  gunfara: 'gunfará',
  gunfava: 'gunfava',
  gunfeis: 'gunfeis',
  gunfemo: 'gunfemo',
  gunguna: 'gunguna',
  gungune: 'gungune',
  gunguno: 'gunguno',
  gunitas: 'gunitas',
  gunites: 'gunites',
  gupiara: 'gupiara',
  gurabos: 'gurabos',
  gurabus: 'gurabus',
  guraens: 'guraéns',
  guraira: 'guraíra',
  guramis: 'guramis',
  gurandi: 'gurandi',
  gurania: 'gurânia',
  guratas: 'guratãs',
  guratau: 'guratau',
  guratia: 'guratia',
  guraura: 'guraúra',
  gurente: 'gurente',
  gureris: 'gureris',
  guresis: 'guresis',
  gurgaus: 'gurgaus',
  gurgita: 'gurgita',
  gurgite: 'gurgite',
  gurgito: 'gurgito',
  gurgues: 'gurguês',
  gurguez: 'gurguez',
  gurguri: 'gurguri',
  guriaba: 'guriabá',
  guriacu: 'guriaçu',
  guriata: 'guriatã',
  guribas: 'guribas',
  guribus: 'guribus',
  guricas: 'guriçás',
  guriris: 'guriris',
  guritao: 'guritão',
  guritas: 'guritas',
  gurnais: 'gurnais',
  gurnamo: 'gurnamo',
  gurniam: 'gurniam',
  gurnias: 'gurnias',
  gurnida: 'gurnida',
  gurnido: 'gurnido',
  gurnimo: 'gurnimo',
  gurnira: 'gurnirá',
  guropes: 'guropés',
  gurriao: 'gurrião',
  gurrino: 'gurrino',
  gurubus: 'gurubus',
  gurucas: 'guruçás',
  gurufim: 'gurufim',
  gurumim: 'gurumim',
  gurunce: 'gurunce',
  gurundi: 'gurundi',
  gurunga: 'gurunga',
  gurungo: 'gurungo',
  gurunxe: 'gurunxe',
  gurupes: 'gurupés',
  gurupia: 'gurupiá',
  gurupus: 'gurupus',
  gururis: 'gururis',
  gurutil: 'gurutil',
  gurutis: 'gurutis',
  gusanas: 'gusanas',
  gusanos: 'gusanos',
  gustada: 'gustada',
  gustado: 'gustado',
  gustais: 'gustais',
  gustamo: 'gustamo',
  gustara: 'gustará',
  gustava: 'gustava',
  gusteis: 'gusteis',
  gustemo: 'gustemo',
  gutacao: 'gutação',
  gutadao: 'gutadão',
  gutadas: 'gutadas',
  gutador: 'gutador',
  gutados: 'gutados',
  gutambu: 'gutambu',
  gutamos: 'gutamos',
  gutando: 'gutando',
  gutante: 'gutante',
  gutaram: 'gutaram',
  gutarao: 'gutarão',
  gutaras: 'gutarás',
  gutarei: 'gutarei',
  gutarem: 'gutarem',
  gutares: 'gutares',
  gutaria: 'gutaria',
  gutarmo: 'gutarmo',
  gutasse: 'gutasse',
  gutaste: 'gutaste',
  gutavam: 'gutavam',
  gutavas: 'gutavas',
  gutedra: 'gutedra',
  guteira: 'guteira',
  gutemos: 'gutemos',
  gutinas: 'gutinas',
  gutulas: 'gútulas',
  gutulos: 'gútulos',
  gutural: 'gutural',
  guzeras: 'guzerás',
  guzumba: 'guzumba',
  guzunga: 'guzunga',
  haastia: 'haástia',
  habadin: 'habadin',
  habenas: 'habenas',
  habille: 'habillé',
  habitai: 'habitai',
  habitam: 'habitam',
  habitar: 'habitar',
  habitas: 'habitas',
  habitat: 'habitat',
  habitei: 'habitei',
  habitem: 'habitem',
  habites: 'habites',
  habitos: 'hábitos',
  habitou: 'habitou',
  habitua: 'habitua',
  habitue: 'habitué',
  habituo: 'habituo',
  habitus: 'habitus',
  habrura: 'habrura',
  hacatas: 'hacatas',
  hacelia: 'hacélia',
  hachura: 'hachura',
  hachure: 'hachure',
  hachuro: 'hachuro',
  hackear: 'hackear',
  hackers: 'hackers',
  hacubes: 'hacubes',
  hadenas: 'hadenas',
  hadimus: 'hadimus',
  hadoque: 'hadoque',
  hadrion: 'hádrion',
  hadroes: 'hadrões',
  hadroma: 'hadroma',
  hadrons: 'hádrons',
  hadruso: 'hadruso',
  hafnias: 'hafnias',
  hafnica: 'háfnica',
  hafnico: 'háfnico',
  hafnios: 'háfnios',
  hagenia: 'hagênia',
  haggita: 'haggita',
  hagitas: 'hagitas',
  haglura: 'haglura',
  hagrita: 'hagrita',
  hahnium: 'hâhnium',
  haimeia: 'haimeia',
  haimore: 'haimoré',
  hainita: 'hainita',
  haiques: 'haiques',
  hajamos: 'hajamos',
  hajibes: 'hájibes',
  halacao: 'halação',
  halacas: 'halacas',
  halalis: 'halalis',
  halcion: 'hálcion',
  halecio: 'halécio',
  halenia: 'halênia',
  haleria: 'haléria',
  halesia: 'halésia',
  halesos: 'halesos',
  haletos: 'haletos',
  haliais: 'haliais',
  halices: 'hálices',
  halicto: 'halicto',
  halidre: 'halidre',
  halieto: 'halieto',
  haliida: 'halíida',
  halimio: 'halímio',
  halimos: 'hálimos',
  halioma: 'halioma',
  haliote: 'haliote',
  haliplo: 'haliplo',
  halitai: 'halitai',
  halitam: 'halitam',
  halitar: 'halitar',
  halitas: 'halitas',
  halitei: 'halitei',
  halitem: 'halitem',
  halites: 'halites',
  halitos: 'hálitos',
  halitou: 'halitou',
  halizao: 'halizão',
  halizia: 'halízia',
  halobio: 'halóbio',
  haloide: 'haloide',
  haltera: 'haltera',
  haltere: 'haltere',
  haltica: 'háltica',
  haluces: 'háluces',
  hamadas: 'hamadas',
  hamaide: 'hamaide',
  hamarto: 'hamarto',
  hamateu: 'hamateu',
  hamatos: 'hamatos',
  hambria: 'hâmbria',
  hamelia: 'hamélia',
  hameque: 'hameque',
  hamidia: 'hamidia',
  haminea: 'hamínea',
  hamineo: 'hamíneo',
  hamireu: 'hamireu',
  hamitas: 'hamitas',
  hamosas: 'hamosas',
  hamosos: 'hamosos',
  hamster: 'hamster',
  hamular: 'hamular',
  hamulas: 'hâmulas',
  hamulos: 'hâmulos',
  hangolo: 'hangolo',
  hapalos: 'hápalos',
  haplico: 'háplico',
  haplito: 'haplito',
  haploma: 'haploma',
  haplomo: 'haplomo',
  haptena: 'haptena',
  hapteno: 'hapteno',
  haptero: 'háptero',
  haptica: 'háptica',
  haptico: 'háptico',
  haptina: 'haptina',
  haquias: 'háquias',
  haquimo: 'haquimo',
  hararis: 'hararis',
  hardela: 'hardela',
  harelda: 'harelda',
  hargios: 'hargios',
  haridim: 'haridim',
  haridis: 'haridis',
  hariola: 'hariola',
  hariole: 'hariole',
  hariolo: 'haríolo',
  harlina: 'harlina',
  harmala: 'harmala',
  harmana: 'harmana',
  harmata: 'harmatã',
  harmato: 'hármato',
  harmica: 'hármica',
  harmico: 'hármico',
  harmina: 'harmina',
  haronga: 'haronga',
  harpada: 'harpada',
  harpado: 'harpado',
  harpaga: 'hárpaga',
  harpago: 'hárpago',
  harpais: 'harpais',
  harpalo: 'hárpalo',
  harpamo: 'harpamo',
  harpara: 'harpara',
  harpava: 'harpava',
  harpeai: 'harpeai',
  harpear: 'harpear',
  harpeei: 'harpeei',
  harpeia: 'harpeia',
  harpeie: 'harpeie',
  harpeio: 'harpeio',
  harpeis: 'harpeis',
  harpeja: 'harpeja',
  harpeje: 'harpeje',
  harpejo: 'harpejo',
  harpemo: 'harpemo',
  harpeou: 'harpeou',
  harpias: 'harpias',
  harpida: 'hárpida',
  harpiia: 'harpiia',
  harteai: 'harteai',
  hartear: 'hartear',
  harteas: 'hárteas',
  harteei: 'harteei',
  harteia: 'harteia',
  harteie: 'harteie',
  harteio: 'harteio',
  harteou: 'harteou',
  hartina: 'hartina',
  hartita: 'hartita',
  hartite: 'hartite',
  hartito: 'hartito',
  hartree: 'hartree',
  harudes: 'harudes',
  harveia: 'harveia',
  harvela: 'harvela',
  hasaria: 'hasária',
  hasario: 'hasário',
  hassani: 'hassani',
  hasside: 'hasside',
  hassios: 'hássios',
  hastada: 'hastada',
  hastado: 'hastado',
  hastato: 'hastato',
  hasteai: 'hasteai',
  hasteal: 'hasteal',
  hastear: 'hastear',
  hasteas: 'hásteas',
  hasteei: 'hasteei',
  hasteia: 'hasteia',
  hasteie: 'hasteie',
  hasteio: 'hasteio',
  hasteou: 'hasteou',
  hastial: 'hastial',
  hastias: 'hástias',
  hastins: 'hastins',
  hastita: 'hastita',
  hatamas: 'hatamas',
  hauinas: 'hauinas',
  haurais: 'haurais',
  hauramo: 'hauramo',
  hauriam: 'hauriam',
  haurias: 'haurias',
  haurida: 'haurida',
  haurido: 'haurido',
  haurimo: 'haurimo',
  haurira: 'haurira',
  haustos: 'haustos',
  havanas: 'havanas',
  havanes: 'havanês',
  havanos: 'havanos',
  havedor: 'havedor',
  havemos: 'havemos',
  havendo: 'havendo',
  haverao: 'haverão',
  haveras: 'haverás',
  haverei: 'haverei',
  haverem: 'haverem',
  haveres: 'haveres',
  haveria: 'haveria',
  havidao: 'havidão',
  havidas: 'havidas',
  havidos: 'havidos',
  havieis: 'havíeis',
  hazazel: 'hazazel',
  hazenas: 'hazenas',
  headset: 'headset',
  heangos: 'heangos',
  hebaute: 'hebaute',
  hebetai: 'hebetai',
  hebetam: 'hebetam',
  hebetar: 'hebetar',
  hebetas: 'hebetas',
  hebetei: 'hebetei',
  hebetem: 'hebetem',
  hebetes: 'hebetes',
  hebetou: 'hebetou',
  hebreia: 'hebreia',
  hebreus: 'hebreus',
  hebrida: 'hébrida',
  hecateu: 'hecateu',
  hectare: 'hectare',
  hectias: 'héctias',
  hectica: 'héctica',
  hectico: 'héctico',
  hectigo: 'héctigo',
  hecuste: 'hecuste',
  hedeoma: 'hedeoma',
  hederas: 'héderas',
  hedibio: 'hedíbio',
  hediboa: 'hedíboa',
  hedicro: 'hedicro',
  hediote: 'hediote',
  hedonal: 'hedonal',
  hedruro: 'hedruro',
  hegiras: 'hégiras',
  heimias: 'hêimias',
  helcion: 'hélcion',
  helcose: 'helcose',
  helenas: 'helenas',
  helenes: 'helenes',
  helenia: 'helênia',
  helenio: 'helênio',
  helenos: 'helenos',
  heleria: 'heléria',
  heliaca: 'helíaca',
  heliaco: 'helíaco',
  helicas: 'hélicas',
  helices: 'hélices',
  helicia: 'helícia',
  helicon: 'hélicon',
  helicos: 'hélicos',
  helieas: 'helíeas',
  helieia: 'helieia',
  heligmo: 'heligmo',
  helinos: 'helinos',
  helioes: 'heliões',
  helions: 'hélions',
  heliose: 'heliose',
  heliote: 'heliote',
  helobia: 'helóbia',
  helocio: 'helócio',
  helodas: 'hélodas',
  helodes: 'hélodes',
  helodos: 'hélodos',
  heloide: 'heloide',
  helonia: 'helônia',
  helopes: 'hélopes',
  heloses: 'heloses',
  helulas: 'hélulas',
  helvela: 'helvela',
  helveto: 'helveto',
  helvina: 'helvina',
  helvios: 'hélvios',
  helvita: 'helvita',
  helvite: 'helvite',
  helxina: 'helxina',
  hemacia: 'hemácia',
  hemanto: 'hemanto',
  hemaris: 'hêmaris',
  hemases: 'hêmases',
  hematia: 'hematia',
  hematos: 'hematos',
  hemeras: 'hemeras',
  heminas: 'heminas',
  hemiona: 'hemíona',
  hemiono: 'hemíono',
  hemiope: 'hemiope',
  hemipos: 'hemipos',
  hemisos: 'hêmisos',
  hemitea: 'hemítea',
  hemites: 'hemites',
  hemixos: 'hemixos',
  hemodia: 'hemodia',
  hemoide: 'hemoide',
  hemonco: 'hemonco',
  hemopis: 'hemópis',
  hemuato: 'hemuato',
  hemulon: 'hemúlon',
  henetos: 'hênetos',
  henides: 'henides',
  henioco: 'heníoco',
  henismo: 'henismo',
  henista: 'henista',
  henrios: 'hênrios',
  hepatal: 'hepatal',
  hepatos: 'hepatos',
  hepialo: 'hepíalo',
  heptana: 'heptana',
  heptano: 'heptano',
  hepteno: 'hepteno',
  hepteto: 'hepteto',
  heptila: 'heptila',
  heptilo: 'heptilo',
  heptina: 'heptina',
  heptodo: 'heptodo',
  heptona: 'heptona',
  heptose: 'heptose',
  hequeso: 'hequeso',
  heraldo: 'heraldo',
  heranca: 'herança',
  herbana: 'herbana',
  herbano: 'herbano',
  herbosa: 'herbosa',
  herboso: 'herboso',
  herbula: 'hérbula',
  herdada: 'herdada',
  herdade: 'herdade',
  herdado: 'herdado',
  herdais: 'herdais',
  herdamo: 'herdamo',
  herdara: 'herdara',
  herdava: 'herdava',
  herdeis: 'herdeis',
  herdemo: 'herdemo',
  heredar: 'heredar',
  hereges: 'hereges',
  heregia: 'heregia',
  hereias: 'hereias',
  hereira: 'hereira',
  herejas: 'herejas',
  herelas: 'herelas',
  hereras: 'hereras',
  hereros: 'hererós',
  heresia: 'heresia',
  heresie: 'heresie',
  heresio: 'heresio',
  herieus: 'herieus',
  hermeas: 'hérmeas',
  hermeia: 'hermeia',
  hermela: 'hermela',
  hermeos: 'hérmeos',
  hermeta: 'hermeta',
  hermete: 'hermete',
  hermeus: 'hermeus',
  hermias: 'hérmias',
  herniai: 'herniai',
  hernial: 'hernial',
  herniam: 'herniam',
  herniar: 'herniar',
  hernias: 'hérnias',
  hernica: 'hérnica',
  hernico: 'hérnico',
  herniei: 'herniei',
  herniem: 'herniem',
  hernies: 'hernies',
  herniou: 'herniou',
  hernuto: 'hernuto',
  herodia: 'heródia',
  heroica: 'heroica',
  heroico: 'heróico',
  heroide: 'heroide',
  heroina: 'heroína',
  heroiza: 'heroizá',
  heroize: 'heroize',
  heroizo: 'heroizo',
  heroons: 'herôons',
  herpete: 'hérpete',
  herpons: 'hérpons',
  herulos: 'hérulos',
  hesione: 'hesíone',
  hesitai: 'hesitai',
  hesitam: 'hesitam',
  hesitar: 'hesitar',
  hesitas: 'hesitas',
  hesitei: 'hesitei',
  hesitem: 'hesitem',
  hesites: 'hesites',
  hesitou: 'hesitou',
  hespera: 'héspera',
  hespere: 'héspere',
  hespero: 'héspero',
  hessita: 'hessita',
  hessite: 'hessite',
  hetadao: 'hetadão',
  hetadas: 'hetadas',
  hetador: 'hetador',
  hetados: 'hetados',
  hetaira: 'hetaira',
  hetamos: 'hetamos',
  hetando: 'hetando',
  hetante: 'hetante',
  hetaram: 'hetaram',
  hetarao: 'hetarão',
  hetaras: 'hetaras',
  hetarei: 'hetarei',
  hetarem: 'hetarem',
  hetares: 'hetares',
  hetaria: 'hetaria',
  hetarmo: 'hetarmo',
  hetasse: 'hetasse',
  hetaste: 'hetaste',
  hetavam: 'hetavam',
  hetavas: 'hetavas',
  hetavio: 'hetávio',
  hetegos: 'hétegos',
  heteias: 'heteias',
  hetemas: 'hétemãs',
  hetemos: 'hetemos',
  heteras: 'heteras',
  heteria: 'heteria',
  heteros: 'héteros',
  heticas: 'héticas',
  heticos: 'héticos',
  hetigos: 'hétigos',
  hetinho: 'hetinho',
  hetmane: 'hétmane',
  hetodos: 'hétodos',
  heumito: 'heumito',
  heureca: 'heureca',
  heurema: 'heurema',
  heurnia: 'heúrnia',
  heveena: 'heveena',
  heveeno: 'heveeno',
  hevitia: 'hevítia',
  hexalfa: 'hexalfa',
  hexanas: 'hexanas',
  hexanco: 'hexanco',
  hexanol: 'hexanol',
  hexanos: 'hexanos',
  hexapla: 'hexapla',
  hexatlo: 'hexatlo',
  hexeres: 'hexeres',
  hexilas: 'hexilas',
  hexilos: 'hexilos',
  hexinas: 'hexinas',
  hexisea: 'hexísea',
  hexitas: 'hexitas',
  hexitol: 'hexitol',
  hexodio: 'hexódio',
  hexodos: 'hexodos',
  hexonas: 'hexonas',
  hexoses: 'hexoses',
  hialeas: 'hiáleas',
  hialica: 'hiálica',
  hialico: 'hiálico',
  hialina: 'hialina',
  hialino: 'hialino',
  hialita: 'hialita',
  hialite: 'hialite',
  hialito: 'hiálito',
  hialoma: 'hialoma',
  hialzao: 'hialzão',
  hiantes: 'hiantes',
  hiapuas: 'hiapuás',
  hiatais: 'hiatais',
  hiatica: 'hiática',
  hiatico: 'hiático',
  hiatiza: 'hiatiza',
  hiatize: 'hiatize',
  hiatizo: 'hiatizo',
  hibante: 'hibante',
  hibanto: 'hibanto',
  hibaqui: 'hibaqui',
  hiberna: 'hiberna',
  hiberne: 'hiberne',
  hiberno: 'hiberno',
  hibisco: 'hibisco',
  hibleia: 'hibleia',
  hibleus: 'hibleus',
  hibodes: 'híbodes',
  hibomas: 'hibomas',
  hibrida: 'híbrida',
  hibride: 'hibride',
  hibrido: 'híbrido',
  hiclato: 'hiclato',
  hicleas: 'hícleas',
  hicleos: 'hícleos',
  hicoria: 'hicória',
  hictias: 'híctias',
  hidneas: 'hídneas',
  hidneos: 'hídneos',
  hidnora: 'hidnora',
  hidrase: 'hidrase',
  hidrata: 'hidrata',
  hidrate: 'hidrate',
  hidrato: 'hidrato',
  hidrena: 'hidrena',
  hidreto: 'hidreto',
  hidriao: 'hidrião',
  hidrias: 'hídrias',
  hidrica: 'hídrica',
  hidrico: 'hídrico',
  hidrila: 'hidrila',
  hidrion: 'hídrion',
  hidroas: 'hidroas',
  hidrois: 'hidróis',
  hidroma: 'hidroma',
  hidrona: 'hidrona',
  hidrone: 'hidrone',
  hidrose: 'hidrose',
  hidrote: 'hidrote',
  hidruro: 'hidruro',
  hiemais: 'hiemais',
  hienial: 'hienial',
  hienida: 'hiênida',
  hiernia: 'hiérnia',
  hifales: 'hifales',
  hifemas: 'hifemas',
  hifemia: 'hifemia',
  hifenar: 'hifenar',
  hifenes: 'hífenes',
  hificas: 'híficas',
  hificos: 'híficos',
  hifidro: 'hifidro',
  higiama: 'higiama',
  higidas: 'hígidas',
  higidez: 'higidez',
  higidos: 'hígidos',
  higiene: 'higiene',
  higrica: 'hígrica',
  higrico: 'hígrico',
  higrima: 'higrima',
  higroma: 'higroma',
  hilacta: 'hilacta',
  hilacte: 'hilacte',
  hilaira: 'hilaíra',
  hilaras: 'hílaras',
  hilares: 'hilares',
  hilaria: 'hilária',
  hilarie: 'hilarie',
  hilario: 'hilário',
  hilasta: 'hilasta',
  hilaste: 'hilaste',
  hilatas: 'hilatas',
  hileana: 'hileana',
  hileano: 'hileano',
  hileias: 'hileias',
  hilelas: 'hilelas',
  hileria: 'hiléria',
  hilesia: 'hilésia',
  hiletos: 'hiletos',
  hilicas: 'hílicas',
  hilicos: 'hílicos',
  hilidas: 'hílidas',
  hilidea: 'hilídea',
  hilideo: 'hilídeo',
  hilinas: 'hilinas',
  hilinea: 'hilínea',
  hilineo: 'hilíneo',
  hilines: 'hilines',
  hilinos: 'hilinos',
  hiliota: 'hiliota',
  hilipos: 'hilipos',
  hilobio: 'hilóbio',
  hilodes: 'hilodes',
  hiloica: 'hiloica',
  hiloico: 'hiloico',
  hilotas: 'hilotas',
  himacio: 'himácio',
  himbari: 'himbári',
  himenal: 'himenal',
  himeneu: 'himeneu',
  himenio: 'himênio',
  himeras: 'hímeras',
  himetio: 'himétio',
  hinamos: 'hinamos',
  hinario: 'hinário',
  hindsia: 'híndsia',
  hinicas: 'hínicas',
  hinicos: 'hínicos',
  hinidao: 'hinidão',
  hinidas: 'hinidas',
  hinidor: 'hinidor',
  hinidos: 'hinidos',
  hinieis: 'hiníeis',
  hinimos: 'hinimos',
  hinindo: 'hinindo',
  hiniram: 'hiniram',
  hinirao: 'hinirão',
  hiniras: 'hinirás',
  hinirei: 'hinirei',
  hinirem: 'hinirem',
  hinires: 'hinires',
  hiniria: 'hiniria',
  hinirmo: 'hinirmo',
  hinismo: 'hinismo',
  hinisse: 'hinisse',
  hinista: 'hinista',
  hiniste: 'hiniste',
  hinobio: 'hinóbio',
  hinodia: 'hinódia',
  hinodos: 'hinodos',
  hiodons: 'híodons',
  hioidea: 'hioidea',
  hioideo: 'hioideo',
  hioides: 'hioides',
  hioideu: 'hioideu',
  hiosere: 'hiósere',
  hipanto: 'hipanto',
  hiparca: 'hiparca',
  hiparco: 'hiparco',
  hiparia: 'hipária',
  hipario: 'hipário',
  hipatas: 'hípatas',
  hipateu: 'hipateu',
  hipecoo: 'hipécoo',
  hipemia: 'hipemia',
  hipenas: 'hipenas',
  hiperao: 'hiperão',
  hiperas: 'hiperas',
  hiperia: 'hipéria',
  hiperol: 'hiperol',
  hiperon: 'híperon',
  hipetra: 'hipetra',
  hipetro: 'hipetro',
  hipicas: 'hípicas',
  hipicos: 'hípicos',
  hipidas: 'hípidas',
  hipidea: 'hipídea',
  hipideo: 'hipídeo',
  hipinea: 'hipínea',
  hipineo: 'hipíneo',
  hipismo: 'hipismo',
  hipista: 'hipista',
  hipiste: 'hipiste',
  hipnais: 'hipnais',
  hipnala: 'hípnala',
  hipneas: 'hípneas',
  hipnelo: 'hipnelo',
  hipnome: 'hipnome',
  hipnona: 'hipnona',
  hipnose: 'hipnose',
  hipoema: 'hipoema',
  hipogea: 'hipógea',
  hipogeo: 'hipógeo',
  hipogeu: 'hipogeu',
  hipoide: 'hipoide',
  hiponix: 'hipônix',
  hipopio: 'hipópio',
  hipotes: 'hipotes',
  hipoxes: 'hipoxes',
  hipoxia: 'hipóxia',
  hipoxie: 'hipoxie',
  hipoxio: 'hipoxio',
  hippies: 'hippies',
  hipsilo: 'hipsilo',
  hiptage: 'hiptage',
  hipural: 'hipural',
  hipures: 'hipures',
  hipuria: 'hipuria',
  hipuros: 'hipuros',
  hiracea: 'hirácea',
  hiraceo: 'hiráceo',
  hiraces: 'híraces',
  hiraras: 'hiraras',
  hircana: 'hircana',
  hircano: 'hircano',
  hircica: 'hírcica',
  hircico: 'hírcico',
  hircina: 'hircina',
  hircino: 'hircino',
  hircosa: 'hircosa',
  hircoso: 'hircoso',
  hirculo: 'hírculo',
  hireias: 'hireias',
  hirgois: 'hirgóis',
  hirilco: 'hirilco',
  hirinas: 'hirinas',
  hirinos: 'hirinos',
  hiriras: 'hirirás',
  hiroito: 'hiroito',
  hirpice: 'hírpice',
  hirpina: 'hirpina',
  hirpino: 'hirpino',
  hirsuto: 'hirsuto',
  hirtada: 'hirtada',
  hirtado: 'hirtado',
  hirtais: 'hirtais',
  hirtamo: 'hirtamo',
  hirtara: 'hirtará',
  hirtava: 'hirtava',
  hirtega: 'hírtega',
  hirtego: 'hírtego',
  hirteis: 'hirteis',
  hirtela: 'hirtela',
  hirtemo: 'hirtemo',
  hirteza: 'hirteza',
  hirudas: 'hirudas',
  hirudos: 'hirudos',
  hirundo: 'hirundo',
  hispada: 'hispada',
  hispado: 'hispado',
  hispais: 'hispais',
  hispamo: 'hispamo',
  hispana: 'hispana',
  hispano: 'hispano',
  hispara: 'hispara',
  hispava: 'hispava',
  hispeis: 'hispeis',
  hispela: 'hispela',
  hispemo: 'hispemo',
  hispida: 'híspida',
  hispide: 'hispide',
  hispido: 'híspido',
  hissons: 'hissons',
  hissopa: 'hissopa',
  hissope: 'hissope',
  hissopo: 'hissopo',
  histero: 'histero',
  histica: 'hística',
  histico: 'hístico',
  histoma: 'histoma',
  histona: 'histona',
  histrio: 'hístrio',
  histrix: 'hístrix',
  hititas: 'hititas',
  hiulcas: 'hiulcas',
  hiulcos: 'hiulcos',
  hoatchi: 'hoatchi',
  hoazins: 'hoazins',
  hobista: 'hobista',
  hodamos: 'hodamos',
  holanda: 'holanda',
  holando: 'holando',
  holasco: 'holasco',
  holding: 'holding',
  holerca: 'holerca',
  holetra: 'holetra',
  holetro: 'holetro',
  holismo: 'holismo',
  holista: 'holista',
  holmias: 'hólmias',
  holmica: 'hólmica',
  holmico: 'hólmico',
  holmina: 'holmina',
  holmios: 'hólmios',
  holobos: 'hólobos',
  holongo: 'holongo',
  holopos: 'hólopos',
  holtita: 'holtita',
  holzoes: 'holzões',
  homacas: 'homaças',
  homagem: 'homagem',
  homanas: 'homanas',
  homaros: 'homaros',
  hombula: 'hombula',
  homelas: 'homelas',
  homeose: 'homeose',
  homerea: 'homérea',
  homereo: 'homéreo',
  homessa: 'homessa',
  homezio: 'homezio',
  homidio: 'homídio',
  homilia: 'homilia',
  homilie: 'homilie',
  homilio: 'homilio',
  hominal: 'hominal',
  homines: 'homines',
  hominho: 'hominho',
  hominis: 'hominis',
  homizia: 'homizia',
  homizie: 'homizie',
  homizio: 'homizio',
  homoica: 'homoica',
  homoico: 'homoico',
  homoide: 'homoide',
  homolas: 'hômolas',
  homolos: 'hômolos',
  homoros: 'homoros',
  homoses: 'homoses',
  honesta: 'honesta',
  honeste: 'honeste',
  honesto: 'honesto',
  honorai: 'honorai',
  honoram: 'honoram',
  honorar: 'honorar',
  honoras: 'honoras',
  honorei: 'honorei',
  honorem: 'honorem',
  honores: 'honores',
  honorou: 'honorou',
  honrada: 'honrada',
  honrado: 'honrado',
  honrais: 'honrais',
  honramo: 'honramo',
  honrara: 'honrara',
  honrava: 'honrava',
  honreis: 'honreis',
  honremo: 'honremo',
  honrosa: 'honrosa',
  honroso: 'honroso',
  hoobias: 'hoóbias',
  hoodene: 'hoódene',
  hoodias: 'hoódias',
  hoombes: 'hoombes',
  hopeina: 'hopeína',
  hopeita: 'hopeíta',
  hopeite: 'hopeíte',
  hoplias: 'hóplias',
  hoplita: 'hoplita',
  hoplons: 'hóplons',
  hoqueto: 'hoqueto',
  horadao: 'horadão',
  horadas: 'horadas',
  horador: 'horador',
  horados: 'horados',
  horamos: 'horamos',
  horando: 'horando',
  horante: 'horante',
  horaram: 'horaram',
  horarao: 'horarão',
  horaras: 'horaras',
  horarei: 'horarei',
  horarem: 'horarem',
  horares: 'horares',
  horaria: 'horária',
  horario: 'horário',
  horarmo: 'horarmo',
  horasse: 'horasse',
  horaste: 'horaste',
  horavam: 'horavam',
  horavas: 'horavas',
  horcias: 'hórcias',
  hordeas: 'hórdeas',
  hordeea: 'hordéea',
  hordeos: 'hórdeos',
  horejai: 'horejai',
  horejam: 'horejam',
  horejar: 'horejar',
  horejas: 'horejas',
  horejei: 'horejei',
  horejem: 'horejem',
  horejes: 'horejes',
  horejou: 'horejou',
  horemos: 'horemos',
  horense: 'horense',
  horinha: 'horinha',
  horiola: 'horíola',
  horista: 'horista',
  hormica: 'hórmica',
  hormico: 'hórmico',
  hormino: 'hormino',
  hormona: 'hormona',
  hornera: 'hornera',
  hornfel: 'hornfel',
  horonas: 'horonas',
  horrida: 'hórrida',
  horrido: 'hórrido',
  hortada: 'hortada',
  hortado: 'hortado',
  hortais: 'hortais',
  hortamo: 'hortamo',
  hortara: 'hortará',
  hortava: 'hortava',
  horteis: 'horteis',
  horteja: 'hortejá',
  horteje: 'horteje',
  hortejo: 'hortejo',
  hortela: 'hortelã',
  hortemo: 'hortemo',
  hortias: 'hórtias',
  hortico: 'hórtico',
  hosanas: 'hosanas',
  hospeda: 'hospeda',
  hospede: 'hóspede',
  hospedo: 'hospedo',
  hossana: 'hossana',
  hostais: 'hostais',
  hostaus: 'hostaus',
  hosteia: 'hosteia',
  hostess: 'hostess',
  hostias: 'hóstias',
  hoteias: 'hoteias',
  hotline: 'hotline',
  hotonia: 'hotônia',
  houaris: 'houaris',
  houbara: 'houbara',
  houchas: 'houchas',
  houlita: 'houlita',
  houston: 'houston',
  houvais: 'houvais',
  houveis: 'houveis',
  houvemo: 'houvemo',
  houvera: 'houvera',
  houviam: 'houviam',
  houvias: 'houvias',
  houvida: 'houvida',
  houvido: 'houvido',
  hovenia: 'hovênia',
  howlita: 'howlita',
  huaceas: 'huáceas',
  huaceos: 'huáceos',
  huainos: 'huainos',
  huamais: 'huamais',
  huambas: 'huambas',
  huambes: 'huambes',
  huambos: 'huambos',
  huanois: 'huanóis',
  huberia: 'hubéria',
  huenias: 'huênias',
  huernia: 'huérnia',
  huertas: 'huertas',
  hugoana: 'hugoana',
  hugoano: 'hugoano',
  hugonia: 'hugônia',
  hulhita: 'hulhita',
  hulhite: 'hulhite',
  hullita: 'hullita',
  hulsita: 'hulsita',
  humadas: 'humadas',
  humaita: 'humaitá',
  humanai: 'humanai',
  humanal: 'humanal',
  humanam: 'humanam',
  humanar: 'humanar',
  humanas: 'humanas',
  humanei: 'humanei',
  humanem: 'humanem',
  humanes: 'humanes',
  humanos: 'humanos',
  humanou: 'humanou',
  humbral: 'humbral',
  humente: 'humente',
  humeral: 'humeral',
  humetai: 'humetai',
  humetam: 'humetam',
  humetar: 'humetar',
  humetas: 'humetas',
  humetei: 'humetei',
  humetem: 'humetem',
  humetes: 'humetes',
  humetou: 'humetou',
  humiana: 'humiana',
  humiano: 'humiano',
  humicas: 'húmicas',
  humicos: 'húmicos',
  humilda: 'humilda',
  humilde: 'humilde',
  humildo: 'humildo',
  humiles: 'húmiles',
  humilha: 'humilha',
  humilhe: 'humilhe',
  humilho: 'humilho',
  huminas: 'huminas',
  humiria: 'humíria',
  humiris: 'humiris',
  humismo: 'humismo',
  humitas: 'humitas',
  humites: 'humites',
  humlhar: 'humlhar',
  humoral: 'humoral',
  humores: 'humores',
  humosas: 'humosas',
  humosos: 'humosos',
  humulos: 'húmulos',
  hungara: 'húngara',
  hungaro: 'húngaro',
  hunismo: 'hunismo',
  huntita: 'huntita',
  huridea: 'hurídea',
  hurideo: 'hurídeo',
  hurrita: 'hurrita',
  hussita: 'hussita',
  huxleia: 'huxleia',
  huxleya: 'huxléya',
  hyblita: 'hyblita',
  iabaces: 'iabacês',
  iabains: 'iabains',
  iabania: 'iabaniã',
  iabasse: 'iabassê',
  iaburus: 'iaburus',
  iacanas: 'iaçanãs',
  iacumas: 'iacumas',
  iacutos: 'iacutos',
  iadagas: 'iadagãs',
  iademim: 'iademim',
  iaefuns: 'iaefuns',
  iageina: 'iageína',
  iaguara: 'iaguara',
  ialaxes: 'ialaxés',
  ialhois: 'ialhoís',
  ialilas: 'ialilás',
  ialodes: 'ialodês',
  ialofos: 'ialofos',
  ialtris: 'iáltris',
  iamaces: 'iamacês',
  iambica: 'iâmbica',
  iambice: 'iambice',
  iambico: 'iâmbico',
  iameias: 'iameias',
  iamoros: 'iamorôs',
  iandons: 'iândons',
  ianomam: 'ianomam',
  ianomas: 'ianomãs',
  ianques: 'ianques',
  iantina: 'iântina',
  iantino: 'iântino',
  iantita: 'iantita',
  iantras: 'iantras',
  iaozoes: 'iãozões',
  iapanas: 'iapanas',
  iapices: 'iápices',
  iapides: 'iápides',
  iapigio: 'iapígio',
  iapoque: 'iapoque',
  iapucas: 'iapuçás',
  iaraiba: 'iaraíba',
  iarense: 'iarense',
  iarivas: 'iarivás',
  iaruara: 'iaruara',
  iarumas: 'iarumás',
  iaruura: 'iaruurá',
  iasinho: 'iasinho',
  iassios: 'iássios',
  iatagas: 'iatagãs',
  iataimi: 'iataimi',
  iatinho: 'iatinho',
  iatismo: 'iatismo',
  iatista: 'iatista',
  iatreus: 'iatreus',
  iatrias: 'iatrias',
  iatrica: 'iátrica',
  iatrico: 'iátrico',
  iatrina: 'iatrina',
  iatrion: 'iátrion',
  iatrios: 'iátrios',
  iatrofa: 'iátrofa',
  iauiras: 'iauiras',
  iazeles: 'iazeles',
  iazigas: 'iázigas',
  iaziges: 'iáziges',
  iazigos: 'iázigos',
  ibabius: 'ibabiús',
  ibacaba: 'ibacaba',
  ibacaus: 'ibacaus',
  ibacias: 'ibácias',
  ibacuri: 'ibacuri',
  ibadita: 'ibadita',
  ibaibai: 'ibaibai',
  ibapois: 'ibapois',
  ibapoos: 'ibapoós',
  ibarana: 'ibarana',
  ibarano: 'ibarano',
  ibatimo: 'ibatimô',
  ibaxama: 'ibaxama',
  ibeinhe: 'ibeinhe',
  iberias: 'ibérias',
  iberica: 'ibérica',
  iberico: 'ibérico',
  iberida: 'ibérida',
  iberide: 'ibéride',
  iberina: 'iberina',
  iberino: 'iberino',
  iberios: 'ibérios',
  iberite: 'iberite',
  iberiza: 'iberiza',
  iberize: 'iberize',
  iberizo: 'iberizo',
  ibgeana: 'ibgeana',
  ibgeano: 'ibgeano',
  ibibios: 'ibibios',
  ibiboca: 'ibiboca',
  ibibora: 'ibibora',
  ibicara: 'ibicara',
  ibicter: 'ibícter',
  ibideas: 'ibídeas',
  ibideos: 'ibídeos',
  ibidida: 'ibídida',
  ibiense: 'ibiense',
  ibijara: 'ibijara',
  ibijaus: 'ibijaús',
  ibiocas: 'ibiocas',
  ibiraem: 'ibiraém',
  ibiraiu: 'ibiraiú',
  ibirare: 'ibiraré',
  ibiraro: 'ibiraró',
  ibirete: 'ibiretê',
  ibiruba: 'ibirubá',
  ibirube: 'ibirube',
  ibixuma: 'ibixuma',
  ibixuna: 'ibixuna',
  iboboca: 'iboboca',
  iboense: 'iboense',
  iboxala: 'iboxalá',
  icabitu: 'içabitu',
  icacina: 'icacina',
  icacore: 'icacoré',
  icadoes: 'içadões',
  icadona: 'içadona',
  icadora: 'içadora',
  icangas: 'icangas',
  icantes: 'içantes',
  icardes: 'içardes',
  icareis: 'içáreis',
  icaremo: 'içaremo',
  icariam: 'içariam',
  icarias: 'içarias',
  icariba: 'icariba',
  icarios: 'icários',
  icarmos: 'içarmos',
  icassem: 'içassem',
  icasses: 'içasses',
  icastes: 'içastes',
  icaveis: 'içáveis',
  iceberg: 'iceberg',
  icelino: 'icelino',
  icenses: 'icenses',
  icerias: 'icérias',
  ichibis: 'ichibis',
  ichibus: 'ichibus',
  ichimai: 'ichimai',
  ichobas: 'ichobas',
  icicacu: 'icicaçu',
  icicana: 'icicana',
  icilida: 'icílida',
  icmadio: 'icmádio',
  icnanto: 'icnanto',
  icneumo: 'icneumo',
  icnitos: 'icnitos',
  icnopos: 'icnopos',
  icodema: 'icodema',
  icodide: 'icodidé',
  icoense: 'icoense',
  icoglas: 'icoglãs',
  iconica: 'icônica',
  iconico: 'icônico',
  iconiza: 'iconiza',
  iconize: 'iconize',
  iconizo: 'iconizo',
  icoquis: 'icóquis',
  icorosa: 'icorosa',
  icoroso: 'icoroso',
  icosana: 'icosana',
  icosano: 'icosano',
  icridio: 'icrídio',
  ictamol: 'ictamol',
  icteria: 'ictéria',
  icteros: 'ícteros',
  ictiaca: 'ictíaca',
  ictiaco: 'ictíaco',
  icticia: 'ictícia',
  icticio: 'ictício',
  ictidio: 'ictídio',
  ictiica: 'ictíica',
  ictiico: 'ictíico',
  ictinas: 'ictinas',
  ictinia: 'ictínia',
  ictiois: 'ictióis',
  ictiose: 'ictiose',
  icumbes: 'icumbes',
  icungos: 'icungos',
  idaitas: 'idaítas',
  idalcao: 'idalcão',
  idalias: 'idálias',
  idalica: 'idálica',
  idalico: 'idálico',
  idalios: 'idálios',
  idalxas: 'idalxas',
  idantes: 'idantes',
  ideacao: 'ideação',
  ideadao: 'ideadão',
  ideadas: 'ideadas',
  ideador: 'ideador',
  ideados: 'ideados',
  ideamos: 'ideamos',
  ideando: 'ideando',
  ideante: 'ideante',
  idearam: 'idearam',
  idearao: 'idearão',
  idearas: 'idearás',
  idearei: 'idearei',
  idearem: 'idearem',
  ideares: 'ideares',
  idearia: 'idearia',
  ideario: 'ideário',
  ideasse: 'ideasse',
  ideaste: 'ideaste',
  ideavam: 'ideavam',
  ideavas: 'ideavas',
  ideavel: 'ideável',
  ideemos: 'ideemos',
  ideenta: 'ideenta',
  ideento: 'ideento',
  idejogo: 'idejogô',
  idesias: 'idésias',
  idetica: 'idética',
  idetico: 'idético',
  idiamim: 'idiamim',
  idiando: 'idiando',
  idiches: 'ídiches',
  idilias: 'idílias',
  idilica: 'idílica',
  idilico: 'idílico',
  idilios: 'idílios',
  idiliza: 'idiliza',
  idilize: 'idilize',
  idilizo: 'idilizo',
  idinhas: 'idinhas',
  idinhos: 'idinhos',
  idiomas: 'idiomas',
  idiotai: 'idiotai',
  idiotam: 'idiotam',
  idiotar: 'idiotar',
  idiotas: 'idiotas',
  idiotei: 'idiotei',
  idiotem: 'idiotem',
  idiotes: 'idiotes',
  idiotez: 'idiotez',
  idiotia: 'idiotia',
  idiotou: 'idiotou',
  iditica: 'idítica',
  iditico: 'idítico',
  iditois: 'iditóis',
  idjogos: 'idjogós',
  idmonea: 'idmônea',
  idoiras: 'idoiras',
  idoiros: 'idoiros',
  idoleto: 'idoleto',
  idonato: 'idonato',
  idoneas: 'idôneas',
  idoneos: 'idôneos',
  idonica: 'idônica',
  idonico: 'idônico',
  idorgao: 'idórgão',
  idorona: 'idorona',
  idorzao: 'idorzão',
  idoteas: 'idóteas',
  idouras: 'idouras',
  idouros: 'idouros',
  idumeia: 'idumeia',
  idumeus: 'idumeus',
  iebaros: 'iebaros',
  ieboxis: 'ieboxis',
  iecuana: 'iecuana',
  iemanja: 'iemanjá',
  ienitas: 'ienitas',
  iepense: 'iepense',
  iereres: 'iererês',
  iericas: 'iéricas',
  iericos: 'iéricos',
  ieshiva: 'ieshiva',
  ietense: 'ietense',
  ietinas: 'ietinas',
  ietinos: 'ietinos',
  ifinoes: 'ifínoes',
  ifolzao: 'ifolzão',
  ifrites: 'ifrites',
  ifuches: 'ifuches',
  ifumbes: 'ifumbes',
  ifusses: 'ifusses',
  igacaba: 'igaçaba',
  igangas: 'igangas',
  igapara: 'igapará',
  igapeba: 'igapeba',
  igaracu: 'igaraçu',
  igarape: 'igarapé',
  igarate: 'igaraté',
  igarcus: 'igarçus',
  igarite: 'igarité',
  igarucu: 'igaruçu',
  igasois: 'igasóis',
  igbiras: 'igbiras',
  igbonas: 'igbonas',
  igelite: 'igelite',
  iglitas: 'iglitas',
  iglites: 'iglites',
  iglitos: 'iglitos',
  ignacia: 'ignácia',
  ignaras: 'ignaras',
  ignaros: 'ignaros',
  ignavas: 'ignavas',
  ignavia: 'ignávia',
  ignavos: 'ignavos',
  ignicao: 'ignição',
  ignitas: 'ignitas',
  ignitor: 'ignitor',
  ignitos: 'ignitos',
  ignizai: 'ignizai',
  ignizam: 'ignizam',
  ignizar: 'ignizar',
  ignizas: 'ignizas',
  ignizei: 'ignizei',
  ignizem: 'ignizem',
  ignizes: 'ignizes',
  ignizou: 'ignizou',
  ignobil: 'ignóbil',
  ignorai: 'ignorai',
  ignoram: 'ignoram',
  ignorar: 'ignorar',
  ignoras: 'ignoras',
  ignorei: 'ignorei',
  ignorem: 'ignorem',
  ignores: 'ignores',
  ignorou: 'ignorou',
  ignotas: 'ignotas',
  ignotos: 'ignotos',
  igongos: 'igongos',
  igonhas: 'igonhas',
  igrejas: 'igrejas',
  igrejos: 'igrejós',
  iguadas: 'iguadas',
  iguador: 'iguador',
  iguados: 'iguados',
  igualai: 'igualai',
  igualam: 'igualam',
  igualar: 'igualar',
  igualas: 'igualas',
  igualda: 'igualda',
  igualde: 'igualde',
  igualdo: 'igualdo',
  igualei: 'igualei',
  igualem: 'igualem',
  iguales: 'iguales',
  igualha: 'igualha',
  igualhe: 'igualhe',
  igualho: 'igualho',
  igualou: 'igualou',
  iguamos: 'iguamos',
  iguanas: 'iguanas',
  iguando: 'iguando',
  iguanos: 'iguanos',
  iguante: 'iguante',
  iguaram: 'iguaram',
  iguarao: 'iguarão',
  iguaras: 'iguaras',
  iguarca: 'iguarça',
  iguarco: 'iguarço',
  iguarei: 'iguarei',
  iguarem: 'iguarem',
  iguares: 'iguares',
  iguaria: 'iguaria',
  iguaris: 'iguaris',
  iguasse: 'iguasse',
  iguaste: 'iguaste',
  iguavam: 'iguavam',
  iguavas: 'iguavas',
  iguemos: 'iguemos',
  iguexas: 'iguexás',
  iguiari: 'iguiari',
  iguvina: 'iguvina',
  iguvino: 'iguvino',
  iiabace: 'iiabacê',
  iialaxe: 'iialaxé',
  iiamoro: 'iiamorô',
  iianhas: 'iianhás',
  iianlas: 'iianlás',
  iidiche: 'iídiche',
  iijexas: 'iijexás',
  ijindes: 'ijindes',
  ijolita: 'ijolita',
  ijolito: 'ijolito',
  ikebana: 'ikebana',
  ilacoes: 'ilações',
  ilacona: 'ilaçona',
  iladoes: 'iladões',
  iladona: 'iladona',
  iladora: 'iladora',
  ilambas: 'ilambas',
  ilambos: 'ilambos',
  ilangue: 'ilangue',
  ilantes: 'ilantes',
  ilapsos: 'ilapsos',
  ilaquea: 'ilaqueá',
  ilarcas: 'ilarcas',
  ilardes: 'ilardes',
  ilareis: 'ilareis',
  ilaremo: 'ilaremo',
  ilariam: 'ilariam',
  ilarias: 'ilarias',
  ilarmos: 'ilarmos',
  ilassem: 'ilassem',
  ilasses: 'ilasses',
  ilastes: 'ilastes',
  ilativa: 'ilativa',
  ilativo: 'ilativo',
  ilaveis: 'iláveis',
  ileacea: 'ileácea',
  ileaceo: 'ileáceo',
  ilecuns: 'ilecuns',
  ilegais: 'ilegais',
  ileismo: 'ileísmo',
  ileites: 'ileítes',
  ileomos: 'ileomos',
  ileosas: 'ileosas',
  ileoses: 'ileoses',
  ileosos: 'ileosos',
  ileques: 'ilequés',
  ilergaa: 'ilergaã',
  ilesita: 'ilesita',
  ilesite: 'ilesite',
  ilhadao: 'ilhadão',
  ilhadas: 'ilhadas',
  ilhador: 'ilhador',
  ilhados: 'ilhados',
  ilhalvo: 'ilhalvo',
  ilhamas: 'ilhamas',
  ilhamos: 'ilhamos',
  ilhando: 'ilhando',
  ilhante: 'ilhante',
  ilhapas: 'ilhapas',
  ilharam: 'ilharam',
  ilharao: 'ilharão',
  ilharas: 'ilharas',
  ilharei: 'ilharei',
  ilharem: 'ilharem',
  ilhares: 'ilhares',
  ilharga: 'ilharga',
  ilharia: 'ilharia',
  ilharmo: 'ilharmo',
  ilhasse: 'ilhasse',
  ilhaste: 'ilhaste',
  ilhavam: 'ilhavam',
  ilhavas: 'ilhavas',
  ilhavos: 'ílhavos',
  ilhemos: 'ilhemos',
  ilhense: 'ilhense',
  ilhetas: 'ilhetas',
  ilhocos: 'ilhocos',
  ilhoses: 'ilhoses',
  ilhotas: 'ilhotas',
  ilhotes: 'ilhotes',
  ilhotos: 'ilhotos',
  iliacas: 'ilíacas',
  iliacos: 'ilíacos',
  iliadas: 'ilíadas',
  iliades: 'ilíades',
  ilianto: 'ilianto',
  ilibada: 'ilibada',
  ilibado: 'ilibado',
  ilibais: 'ilibais',
  ilibamo: 'ilibamo',
  ilibara: 'ilibará',
  ilibava: 'ilibava',
  ilibeis: 'ilibeis',
  ilibemo: 'ilibemo',
  ilibios: 'ilíbios',
  ilicada: 'iliçada',
  ilicado: 'iliçado',
  ilicais: 'iliçais',
  ilicamo: 'iliçamo',
  ilicara: 'iliçará',
  ilicava: 'iliçava',
  iliceas: 'ilíceas',
  iliceis: 'iliceis',
  ilicemo: 'ilicemo',
  iliceos: 'ilíceos',
  ilicica: 'ilícica',
  ilicico: 'ilícico',
  iliciea: 'ilicíea',
  ilicina: 'ilicina',
  ilicios: 'ilícios',
  ilicita: 'ilícita',
  ilicito: 'ilícito',
  ilicoes: 'ilições',
  ilicona: 'iliçona',
  ilidais: 'ilidais',
  ilidamo: 'ilidamo',
  ilidiam: 'ilidiam',
  ilidias: 'ilidias',
  ilidida: 'ilidida',
  ilidido: 'ilidido',
  ilidima: 'ilídima',
  ilidimo: 'ilídimo',
  ilidira: 'ilidirá',
  iliense: 'iliense',
  iligada: 'iligada',
  iligado: 'iligado',
  iligais: 'iligais',
  iligara: 'iligará',
  iligava: 'iligava',
  iligera: 'ilígera',
  iliguei: 'iliguei',
  iliguem: 'iliguem',
  iligues: 'iligues',
  ilimita: 'ilimita',
  ilimite: 'ilimite',
  ilimito: 'ilimito',
  ilinios: 'ilínios',
  iliofis: 'ilíofis',
  ilipina: 'ilipina',
  ilirias: 'ilírias',
  ilirica: 'ilírica',
  ilirico: 'ilírico',
  iliride: 'ilíride',
  ilirios: 'ilírios',
  ilisias: 'ilísias',
  ilisida: 'ilísida',
  illitas: 'illitas',
  ilmenio: 'ilmênio',
  ilocana: 'ilocana',
  ilocano: 'ilocano',
  ilogias: 'ilogias',
  ilogica: 'ilógica',
  ilogico: 'ilógico',
  iluaies: 'iluaiês',
  iludais: 'iludais',
  iludamo: 'iludamo',
  iludemo: 'iludemo',
  iludiam: 'iludiam',
  iludias: 'iludias',
  iludida: 'iludida',
  iludido: 'iludido',
  iludimo: 'iludimo',
  iludira: 'iludira',
  ilumbas: 'ilumbas',
  ilumina: 'ilumina',
  ilumine: 'ilumine',
  ilumino: 'ilumino',
  ilurias: 'ilúrias',
  ilurios: 'ilúrios',
  ilusais: 'ilusais',
  ilusamo: 'ilusamo',
  ilusiam: 'ilusiam',
  ilusias: 'ilusias',
  ilusida: 'ilusida',
  ilusido: 'ilusido',
  ilusimo: 'ilusimo',
  ilusira: 'ilusirá',
  ilusiva: 'ilusiva',
  ilusivo: 'ilusivo',
  ilusoes: 'ilusões',
  ilusona: 'ilusona',
  ilusora: 'ilusora',
  ilustra: 'ilustra',
  ilustre: 'ilustre',
  ilustro: 'ilustro',
  ilutada: 'ilutada',
  ilutado: 'ilutado',
  ilutais: 'ilutais',
  ilutamo: 'ilutamo',
  ilutara: 'ilutara',
  ilutava: 'ilutava',
  iluteis: 'iluteis',
  ilutemo: 'ilutemo',
  iluviai: 'iluviai',
  iluvial: 'iluvial',
  iluviam: 'iluviam',
  iluviar: 'iluviar',
  iluvias: 'iluvias',
  iluviei: 'iluviei',
  iluviem: 'iluviem',
  iluvies: 'iluvies',
  iluvios: 'ilúvios',
  iluviou: 'iluviou',
  ilvaita: 'ilvaíta',
  ilvaite: 'ilvaíte',
  ilvates: 'ilvates',
  imacula: 'imácula',
  imaculo: 'imáculo',
  imaduca: 'imaduca',
  imaduco: 'imaduco',
  imafeno: 'imafeno',
  imageai: 'imageai',
  imagear: 'imagear',
  imageei: 'imageei',
  imageia: 'imageia',
  imageie: 'imageie',
  imageio: 'imageio',
  imagens: 'imagens',
  imageou: 'imageou',
  imagina: 'imagina',
  imagine: 'imagine',
  imagino: 'imagino',
  imamada: 'imamada',
  imamado: 'imamado',
  imamato: 'imamato',
  imanada: 'imanada',
  imanado: 'imanado',
  imanais: 'imanais',
  imanamo: 'imanamo',
  imanara: 'imanara',
  imanato: 'imanato',
  imanava: 'imanava',
  imaneis: 'imaneis',
  imanemo: 'imanemo',
  imaniza: 'imaniza',
  imanize: 'imanize',
  imanizo: 'imanizo',
  imantai: 'imantai',
  imantam: 'imantam',
  imantar: 'imantar',
  imantas: 'imantas',
  imantei: 'imantei',
  imantem: 'imantem',
  imantes: 'imantes',
  imantou: 'imantou',
  imatura: 'imatura',
  imaturo: 'imaturo',
  imazada: 'imãzada',
  imazado: 'imãzado',
  imazais: 'imãzais',
  imazamo: 'imãzamo',
  imazara: 'imãzará',
  imazava: 'imãzava',
  imazeis: 'imãzeis',
  imazemo: 'imãzemo',
  imbaiba: 'imbaíba',
  imbamba: 'imbamba',
  imbambi: 'imbambi',
  imbanda: 'imbanda',
  imbauba: 'imbaúba',
  imbauva: 'imbaúva',
  imbecil: 'imbecil',
  imbecis: 'imbecis',
  imbeles: 'imbeles',
  imbembo: 'imbembo',
  imberbe: 'imberbe',
  imberis: 'imberis',
  imbicai: 'imbicai',
  imbicam: 'imbicam',
  imbicar: 'imbicar',
  imbicas: 'imbicas',
  imbicou: 'imbicou',
  imbilas: 'imbilas',
  imbique: 'imbique',
  imbiras: 'imbiras',
  imbiris: 'imbiris',
  imbirus: 'imbirus',
  imbiuas: 'imbiuás',
  imbonda: 'imbondá',
  imbonde: 'imbonde',
  imbondo: 'imbondo',
  imboras: 'imborãs',
  imbotes: 'imbotês',
  imbrama: 'imbramá',
  imbrame: 'imbrame',
  imbramo: 'imbramo',
  imbrica: 'imbrica',
  imbrico: 'imbrico',
  imbuais: 'imbuais',
  imbuava: 'imbuava',
  imbuiam: 'imbuíam',
  imbuias: 'imbuias',
  imbuida: 'imbuída',
  imbuido: 'imbuído',
  imbuins: 'imbuins',
  imbuira: 'imbuíra',
  imbunde: 'imbunde',
  imbundi: 'imbúndi',
  imburim: 'imburim',
  imburis: 'imburis',
  imbuzal: 'imbuzal',
  imediai: 'imediai',
  imediam: 'imediam',
  imediar: 'imediar',
  imedias: 'imedias',
  imediei: 'imediei',
  imediem: 'imediem',
  imedies: 'imedies',
  imediou: 'imediou',
  imemore: 'imémore',
  imensas: 'imensas',
  imensos: 'imensos',
  imentos: 'imentos',
  imereca: 'imereça',
  imerece: 'imerece',
  imereci: 'imereci',
  imereco: 'imereço',
  imergem: 'imergem',
  imerges: 'imerges',
  imergia: 'imergia',
  imergir: 'imergir',
  imergis: 'imergis',
  imergiu: 'imergiu',
  imerita: 'imérita',
  imerito: 'imérito',
  imerjam: 'imerjam',
  imerjas: 'imerjas',
  imersai: 'imersai',
  imersam: 'imersam',
  imersao: 'imersão',
  imersar: 'imersar',
  imersas: 'imersas',
  imersei: 'imersei',
  imersem: 'imersem',
  imerses: 'imerses',
  imersor: 'imersor',
  imersos: 'imersos',
  imersou: 'imersou',
  imideto: 'imideto',
  imidica: 'imídica',
  imidico: 'imídico',
  imigrai: 'imigrai',
  imigram: 'imigram',
  imigrar: 'imigrar',
  imigras: 'imigras',
  imigrei: 'imigrei',
  imigrem: 'imigrem',
  imigres: 'imigres',
  imigrou: 'imigrou',
  imiscao: 'imisção',
  imiscua: 'imiscua',
  imiscui: 'imiscui',
  imiscuo: 'imiscuo',
  imissao: 'imissão',
  imissas: 'imissas',
  imissos: 'imissos',
  imistao: 'imistão',
  imitada: 'imitada',
  imitado: 'imitado',
  imitais: 'imitais',
  imitamo: 'imitamo',
  imitara: 'imitara',
  imitava: 'imitava',
  imiteis: 'imiteis',
  imitemo: 'imitemo',
  imitiam: 'imitiam',
  imitias: 'imitias',
  imitida: 'imitida',
  imitido: 'imitido',
  imitimo: 'imitimo',
  imitira: 'imitirá',
  imizade: 'imizade',
  imodica: 'imódica',
  imodico: 'imódico',
  imoirai: 'imoirai',
  imoiram: 'imoiram',
  imoirar: 'imoirar',
  imoiras: 'imoiras',
  imoirei: 'imoirei',
  imoirem: 'imoirem',
  imoires: 'imoires',
  imoirou: 'imoirou',
  imolada: 'imolada',
  imolado: 'imolado',
  imolais: 'imolais',
  imolamo: 'imolamo',
  imolara: 'imolara',
  imolava: 'imolava',
  imoleis: 'imoleis',
  imolemo: 'imolemo',
  imorais: 'imorais',
  imorala: 'imoralã',
  imortal: 'imortal',
  imotada: 'imotada',
  imotado: 'imotado',
  imotais: 'imotais',
  imotamo: 'imotamo',
  imotara: 'imotará',
  imotava: 'imotava',
  imoteis: 'imoteis',
  imotemo: 'imotemo',
  imotiva: 'imotiva',
  imotivo: 'imotivo',
  imourai: 'imourai',
  imouram: 'imouram',
  imourar: 'imourar',
  imouras: 'imouras',
  imourei: 'imourei',
  imourem: 'imourem',
  imoures: 'imoures',
  imourou: 'imourou',
  imovais: 'imovais',
  imoveis: 'imóveis',
  imovemo: 'imovemo',
  imovera: 'imovera',
  imoviam: 'imoviam',
  imovias: 'imovias',
  imovida: 'imovida',
  imovido: 'imovido',
  impacao: 'impação',
  impacas: 'impacas',
  impacta: 'impacta',
  impacte: 'impacte',
  impadao: 'impadão',
  impadas: 'impadas',
  impador: 'impador',
  impados: 'impados',
  impalas: 'impalas',
  impambo: 'impambo',
  impamos: 'impamos',
  impanai: 'impanai',
  impanam: 'impanam',
  impanar: 'impanar',
  impanas: 'impanas',
  impando: 'impando',
  impanei: 'impanei',
  impanem: 'impanem',
  impanes: 'impanes',
  impanou: 'impanou',
  impante: 'impante',
  imparam: 'imparam',
  imparao: 'imparão',
  imparas: 'imparas',
  imparei: 'imparei',
  imparem: 'imparem',
  impares: 'ímpares',
  imparia: 'imparia',
  imparmo: 'imparmo',
  impasse: 'impasse',
  impaste: 'impaste',
  impavam: 'impavam',
  impavas: 'impavas',
  impecam: 'impeçam',
  impecas: 'impeças',
  impedem: 'impedem',
  impedes: 'impedes',
  impedia: 'impedia',
  impedir: 'impedir',
  impedis: 'impedis',
  impediu: 'impediu',
  impedor: 'impedor',
  impelem: 'impelem',
  impeles: 'impeles',
  impelga: 'impelga',
  impelgo: 'impelgo',
  impelia: 'impelia',
  impelir: 'impelir',
  impelis: 'impelis',
  impeliu: 'impeliu',
  impemos: 'impemos',
  impenda: 'impenda',
  impende: 'impende',
  impendi: 'impendi',
  impendo: 'impendo',
  impenes: 'impenes',
  imperai: 'imperai',
  imperam: 'imperam',
  imperar: 'imperar',
  imperas: 'imperas',
  imperei: 'imperei',
  imperem: 'imperem',
  imperes: 'imperes',
  imperio: 'império',
  imperla: 'imperlá',
  imperle: 'imperle',
  imperlo: 'imperlo',
  imperou: 'imperou',
  impetai: 'impetai',
  impetam: 'impetam',
  impetar: 'impetar',
  impetas: 'impetas',
  impetei: 'impetei',
  impetem: 'impetem',
  impetes: 'impetes',
  impetos: 'ímpetos',
  impetou: 'impetou',
  impetra: 'impetra',
  impetre: 'impetre',
  impetro: 'impetro',
  impigem: 'impigem',
  impilam: 'impilam',
  impilas: 'impilas',
  impilos: 'impilos',
  impinge: 'impinge',
  impingi: 'impingi',
  impinja: 'impinja',
  impinjo: 'impinjo',
  impiote: 'impiote',
  implexo: 'implexo',
  implica: 'implica',
  implico: 'implico',
  imploda: 'imploda',
  implode: 'implode',
  implodi: 'implodi',
  implodo: 'implodo',
  implora: 'implora',
  implore: 'implore',
  imploro: 'imploro',
  impluda: 'impluda',
  impludo: 'impludo',
  implume: 'implume',
  impolto: 'impolto',
  impomos: 'impomos',
  imponam: 'imponam',
  imponas: 'imponas',
  imponde: 'imponde',
  impondo: 'impondo',
  imponem: 'imponem',
  impones: 'impones',
  imponha: 'imponha',
  imponho: 'imponho',
  imponia: 'imponia',
  imponir: 'imponir',
  imponis: 'imponis',
  imponiu: 'imponiu',
  imponta: 'impontá',
  imponte: 'imponte',
  imponto: 'imponto',
  imporao: 'imporão',
  imporas: 'imporás',
  imporei: 'imporei',
  imporem: 'imporem',
  impores: 'impores',
  imporia: 'imporia',
  impormo: 'impormo',
  importa: 'importa',
  importe: 'importe',
  importo: 'importo',
  imposta: 'imposta',
  imposte: 'imposte',
  imposto: 'imposto',
  impotas: 'impotas',
  impreca: 'impreca',
  impreco: 'impreco',
  impreva: 'impreva',
  impreve: 'impreve',
  imprevi: 'imprevi',
  imprevo: 'imprevo',
  imprial: 'imprial',
  imprima: 'imprima',
  imprime: 'imprime',
  imprimi: 'imprimi',
  imprimo: 'imprimo',
  improba: 'ímproba',
  improbe: 'improbe',
  improbo: 'ímprobo',
  improva: 'improva',
  improve: 'improve',
  improvo: 'improvo',
  impucos: 'impucos',
  impudor: 'impudor',
  impugna: 'impugna',
  impugne: 'impugne',
  impugno: 'impugno',
  impujes: 'impujes',
  impulsa: 'impulsa',
  impulse: 'impulse',
  impulso: 'impulso',
  impunam: 'impunam',
  impunas: 'impunas',
  impunem: 'impunem',
  impunes: 'impunes',
  impunha: 'impunha',
  impunia: 'impunia',
  impunir: 'impunir',
  impunis: 'impunis',
  impuniu: 'impuniu',
  impuras: 'impuras',
  impuros: 'impuros',
  impuser: 'impuser',
  imputai: 'imputai',
  imputam: 'imputam',
  imputar: 'imputar',
  imputas: 'imputas',
  imputei: 'imputei',
  imputem: 'imputem',
  imputes: 'imputes',
  imputou: 'imputou',
  imundai: 'imundai',
  imundam: 'imundam',
  imundar: 'imundar',
  imundas: 'imundas',
  imundei: 'imundei',
  imundem: 'imundem',
  imundes: 'imundes',
  imundos: 'imundos',
  imundou: 'imundou',
  imuniza: 'imuniza',
  imunize: 'imunize',
  imunizo: 'imunizo',
  imutada: 'imutada',
  imutado: 'imutado',
  imutais: 'imutais',
  imutamo: 'imutamo',
  imutara: 'imutara',
  imutava: 'imutava',
  imuteis: 'imuteis',
  imutemo: 'imutemo',
  inabeis: 'inábeis',
  inabita: 'inabitá',
  inabite: 'inabite',
  inabito: 'inabito',
  inacias: 'inácias',
  inacoes: 'inações',
  inacona: 'inaçona',
  inadata: 'inadatá',
  inadate: 'inadate',
  inadato: 'inadato',
  inaduna: 'inaduná',
  inadune: 'inadune',
  inaduno: 'inaduno',
  inaguas: 'ináguas',
  inaiais: 'inaiaís',
  inaibas: 'inaíbas',
  inajais: 'inajaís',
  inalada: 'inalada',
  inalado: 'inalado',
  inalais: 'inalais',
  inalamo: 'inalamo',
  inalara: 'inalara',
  inalava: 'inalava',
  inaleis: 'inaleis',
  inalemo: 'inalemo',
  inambui: 'inambuí',
  inambus: 'inambus',
  inambuu: 'inambuu',
  inamena: 'inamena',
  inameno: 'inameno',
  inamuis: 'inamuís',
  inamuus: 'inamuus',
  inanais: 'inanais',
  inanamo: 'inanamo',
  inaniam: 'inaniam',
  inanias: 'inânias',
  inanida: 'inanida',
  inanido: 'inanido',
  inanima: 'inanima',
  inanime: 'inânime',
  inanimo: 'inanimo',
  inanira: 'inanirá',
  inaptas: 'inaptas',
  inaptos: 'inaptos',
  inativa: 'inativa',
  inative: 'inative',
  inativo: 'inativo',
  inatual: 'inatual',
  incabal: 'incabal',
  incabis: 'incabis',
  incadao: 'inçadão',
  incadas: 'inçadas',
  incador: 'inçador',
  incados: 'inçados',
  incaica: 'incaica',
  incaico: 'incaico',
  incaita: 'incaíta',
  incalas: 'incalás',
  incamos: 'inçamos',
  incanas: 'incanas',
  incando: 'inçando',
  incanos: 'incanos',
  incante: 'inçante',
  incapaz: 'incapaz',
  incaram: 'inçaram',
  incarao: 'inçarão',
  incaras: 'inçarás',
  incarei: 'inçarei',
  incarem: 'inçarem',
  incares: 'inçares',
  incaria: 'inçaria',
  incarmo: 'inçarmo',
  incarna: 'incarna',
  incarne: 'incarne',
  incarno: 'incarno',
  incasse: 'inçasse',
  incasta: 'incasta',
  incaste: 'inçaste',
  incasto: 'incasto',
  incauta: 'incauta',
  incauto: 'incauto',
  incavam: 'inçavam',
  incavas: 'inçavas',
  incemos: 'incemos',
  incenda: 'incenda',
  incende: 'incende',
  incendi: 'incendi',
  incendo: 'incendo',
  incensa: 'incensa',
  incense: 'incense',
  incenso: 'incenso',
  incerne: 'incerne',
  incerta: 'incerta',
  incerte: 'incerte',
  incerto: 'incerto',
  incesso: 'incesso',
  incesta: 'incesta',
  inceste: 'inceste',
  incesto: 'incesto',
  incetor: 'incetor',
  inchaco: 'inchaço',
  inchada: 'inchada',
  inchado: 'inchado',
  inchais: 'inchais',
  inchamo: 'inchamo',
  inchara: 'inchara',
  inchava: 'inchava',
  incheis: 'incheis',
  inchemo: 'inchemo',
  inchima: 'inchimã',
  inchume: 'inchume',
  inciada: 'inciada',
  inciado: 'inciado',
  inciais: 'inciais',
  inciamo: 'inciamo',
  inciara: 'inciará',
  inciava: 'inciava',
  incidam: 'incidam',
  incidas: 'incidas',
  incidem: 'incidem',
  incides: 'incides',
  incidia: 'incidia',
  incidir: 'incidir',
  incidis: 'incidis',
  incidiu: 'incidiu',
  incieis: 'incieis',
  inciemo: 'inciemo',
  incinda: 'incinda',
  incinde: 'incinde',
  incindi: 'incindi',
  incindo: 'incindo',
  incinge: 'incinge',
  incingi: 'incingi',
  incinja: 'incinja',
  incinjo: 'incinjo',
  incipit: 'incipit',
  incisai: 'incisai',
  incisam: 'incisam',
  incisao: 'incisão',
  incisar: 'incisar',
  incisas: 'incisas',
  incisei: 'incisei',
  incisem: 'incisem',
  incises: 'incises',
  incisor: 'incisor',
  incisos: 'incisos',
  incisou: 'incisou',
  incitai: 'incitai',
  incitam: 'incitam',
  incitar: 'incitar',
  incitas: 'incitas',
  incitei: 'incitei',
  incitem: 'incitem',
  incites: 'incites',
  incitou: 'incitou',
  incivil: 'incivil',
  incivis: 'incivis',
  inclina: 'inclina',
  incline: 'incline',
  inclino: 'inclino',
  inclito: 'ínclito',
  incluam: 'incluam',
  incluas: 'incluas',
  includa: 'includa',
  include: 'include',
  includi: 'includi',
  includo: 'includo',
  incluem: 'incluem',
  incluia: 'incluía',
  incluir: 'incluir',
  incluis: 'incluis',
  incluiu: 'incluiu',
  inclusa: 'inclusa',
  incluso: 'incluso',
  incoada: 'incoada',
  incoado: 'incoado',
  incoais: 'incoais',
  incoamo: 'incoamo',
  incoara: 'incoara',
  incoava: 'incoava',
  incocas: 'incocas',
  incoces: 'incoces',
  incoeis: 'incoeis',
  incoemo: 'incoemo',
  incoesa: 'incoesa',
  incoeso: 'incoeso',
  incolas: 'íncolas',
  incolor: 'incolor',
  incomum: 'incomum',
  incomun: 'incomun',
  inconel: 'inconel',
  inconha: 'inconha',
  inconho: 'inconho',
  incorra: 'incorra',
  incorre: 'incorre',
  incorri: 'incorri',
  incorro: 'incorro',
  increda: 'increda',
  increde: 'increde',
  incredo: 'incredo',
  increem: 'increem',
  increia: 'increia',
  increio: 'increio',
  increpa: 'increpa',
  increpe: 'increpe',
  increpo: 'increpo',
  increra: 'increrá',
  increus: 'incréus',
  incriam: 'incriam',
  incrias: 'incrias',
  incrida: 'incrida',
  incrido: 'incrido',
  incrisa: 'incrisa',
  incrise: 'incrise',
  incriso: 'incriso',
  incuaia: 'incuaia',
  incubai: 'incubai',
  incubam: 'incubam',
  incubar: 'incubar',
  incubas: 'incubas',
  incubei: 'incubei',
  incubem: 'incubem',
  incubes: 'incubes',
  incubos: 'íncubos',
  incubou: 'incubou',
  incucas: 'incuças',
  incudas: 'incudas',
  incudes: 'incudes',
  inculca: 'inculca',
  inculco: 'inculco',
  inculos: 'ínculos',
  inculpa: 'inculpa',
  inculpe: 'inculpe',
  inculpo: 'inculpo',
  inculta: 'inculta',
  inculto: 'inculto',
  incumba: 'incumba',
  incumbe: 'incumbe',
  incumbi: 'incumbi',
  incumbo: 'incumbo',
  incuria: 'incúria',
  incursa: 'incursa',
  incurso: 'incurso',
  incusas: 'incusas',
  incusos: 'incusos',
  incutam: 'incutam',
  incutas: 'incutas',
  incutem: 'incutem',
  incutes: 'incutes',
  incutia: 'incutia',
  incutir: 'incutir',
  incutis: 'incutis',
  incutiu: 'incutiu',
  indacas: 'indacas',
  indagai: 'indagai',
  indagam: 'indagam',
  indagar: 'indagar',
  indagas: 'indagas',
  indagou: 'indagou',
  indague: 'indague',
  indaias: 'indaiás',
  indaies: 'indaiés',
  indajas: 'indajás',
  indalmo: 'indalmo',
  indanas: 'indanas',
  indanos: 'indanos',
  indazol: 'indazol',
  indegra: 'indegrá',
  indegre: 'indegre',
  indegro: 'indegro',
  indemne: 'indemne',
  indenas: 'indenas',
  indenes: 'indenes',
  indenos: 'indenos',
  indexai: 'indexai',
  indexam: 'indexam',
  indexar: 'indexar',
  indexas: 'indexas',
  indexei: 'indexei',
  indexem: 'indexem',
  indexes: 'indexes',
  indexou: 'indexou',
  indiada: 'indiada',
  indiama: 'indiamã',
  indiana: 'indiana',
  indiano: 'indiano',
  indiaru: 'indiaru',
  indicai: 'indicai',
  indicam: 'indicam',
  indicao: 'indicão',
  indicar: 'indicar',
  indicas: 'indicas',
  indices: 'índices',
  indicia: 'indicia',
  indicie: 'indicie',
  indicio: 'indício',
  indicos: 'índicos',
  indicou: 'indicou',
  indigna: 'indigna',
  indigne: 'indigne',
  indigno: 'indigno',
  indigos: 'índigos',
  indilga: 'indilgá',
  indilgo: 'indilgo',
  indilos: 'indilos',
  indinha: 'indinha',
  indinhe: 'indinhe',
  indinho: 'indinho',
  indique: 'indique',
  indispo: 'indispô',
  inditas: 'inditas',
  indiuns: 'índiuns',
  indocil: 'indócil',
  indocis: 'indócis',
  indoina: 'indoína',
  indoles: 'índoles',
  indolor: 'indolor',
  indombe: 'indombe',
  indonas: 'indonas',
  indorme: 'indorme',
  indormi: 'indormi',
  indouta: 'indouta',
  indouto: 'indouto',
  indoxol: 'indoxol',
  indrias: 'indrias',
  inducao: 'indução',
  inducas: 'induças',
  inducia: 'indúcia',
  indulta: 'indulta',
  indulte: 'indulte',
  indulto: 'indulto',
  indumba: 'indumba',
  indunas: 'indunas',
  induras: 'induras',
  indurma: 'indurma',
  indurmo: 'indurmo',
  induros: 'induros',
  indusia: 'indúsia',
  indusio: 'indúsio',
  indutai: 'indutai',
  indutam: 'indutam',
  indutar: 'indutar',
  indutas: 'indutas',
  indutei: 'indutei',
  indutem: 'indutem',
  indutes: 'indutes',
  indutor: 'indutor',
  indutou: 'indutou',
  induvia: 'indúvia',
  induvio: 'indúvio',
  induzam: 'induzam',
  induzas: 'induzas',
  induzem: 'induzem',
  induzes: 'induzes',
  induzia: 'induzia',
  induzir: 'induzir',
  induzis: 'induzis',
  induziu: 'induziu',
  inebria: 'inebria',
  inebrie: 'inebrie',
  inebrio: 'inebrio',
  inedias: 'inédias',
  inedita: 'inédita',
  inedito: 'inédito',
  ineinas: 'ineínas',
  ineixas: 'ineixas',
  inelito: 'inelito',
  inembus: 'inembus',
  inepcia: 'inépcia',
  inepica: 'inépica',
  inepico: 'inépico',
  ineptas: 'ineptas',
  ineptos: 'ineptos',
  inerais: 'inerais',
  ineramo: 'ineramo',
  inercia: 'inércia',
  inercie: 'inercie',
  inercio: 'inercio',
  ineriam: 'ineriam',
  inerias: 'inerias',
  inerida: 'inerida',
  inerido: 'inerido',
  inerimo: 'inerimo',
  inerira: 'inerirá',
  inermes: 'inermes',
  inertes: 'inertes',
  inervai: 'inervai',
  inervam: 'inervam',
  inervar: 'inervar',
  inervas: 'inervas',
  inervea: 'inérvea',
  inervei: 'inervei',
  inervem: 'inervem',
  inerveo: 'inérveo',
  inerves: 'inerves',
  inervou: 'inervou',
  inesita: 'inesita',
  inesite: 'inesite',
  inetica: 'inética',
  inexata: 'inexata',
  inexato: 'inexato',
  inexige: 'inexige',
  inexigi: 'inexigi',
  inexija: 'inexija',
  inexijo: 'inexijo',
  infamai: 'infamai',
  infamam: 'infamam',
  infamar: 'infamar',
  infamas: 'infamas',
  infamei: 'infamei',
  infamem: 'infamem',
  infames: 'infames',
  infamia: 'infâmia',
  infamio: 'infâmio',
  infamou: 'infamou',
  infanda: 'infanda',
  infando: 'infando',
  infanta: 'infanta',
  infante: 'infante',
  infanto: 'infanto',
  infarta: 'infarta',
  infarte: 'infarte',
  infarto: 'infarto',
  infecao: 'infeção',
  infecta: 'infecta',
  infecte: 'infecte',
  infecto: 'infecto',
  infeliz: 'infeliz',
  infensa: 'infensa',
  infenso: 'infenso',
  inferas: 'ínferas',
  inferem: 'inferem',
  inferes: 'inferes',
  inferia: 'inferia',
  inferir: 'inferir',
  inferis: 'inferis',
  inferiu: 'inferiu',
  inferna: 'inferna',
  inferne: 'inferne',
  inferno: 'inferno',
  inferos: 'ínferos',
  infessa: 'infessa',
  infesso: 'infesso',
  infesta: 'infesta',
  infeste: 'infeste',
  infesto: 'infesto',
  infetai: 'infetai',
  infetam: 'infetam',
  infetar: 'infetar',
  infetas: 'infetas',
  infetei: 'infetei',
  infetem: 'infetem',
  infetes: 'infetes',
  infetou: 'infetou',
  infidas: 'infidas',
  infidos: 'infidos',
  infieis: 'infiéis',
  infimas: 'ínfimas',
  infimos: 'ínfimos',
  infinca: 'infinca',
  infinco: 'infinco',
  infinda: 'infinda',
  infindo: 'infindo',
  infinge: 'infinge',
  infingi: 'infingi',
  infinit: 'infinit',
  infinja: 'infinja',
  infinjo: 'infinjo',
  infinta: 'infinta',
  infinto: 'infinto',
  infioes: 'infiões',
  infiram: 'infiram',
  infiras: 'infiras',
  infirma: 'infirma',
  infirme: 'infirme',
  infirmo: 'infirmo',
  infixai: 'infixai',
  infixam: 'infixam',
  infixar: 'infixar',
  infixas: 'infixas',
  infixei: 'infixei',
  infixem: 'infixem',
  infixes: 'infixes',
  infixou: 'infixou',
  infizes: 'infizes',
  inflada: 'inflada',
  inflado: 'inflado',
  inflais: 'inflais',
  inflama: 'inflama',
  inflame: 'inflame',
  inflamo: 'inflamo',
  inflara: 'inflara',
  inflava: 'inflava',
  infleis: 'infleis',
  inflemo: 'inflemo',
  infleta: 'infleta',
  inflete: 'inflete',
  infleti: 'infleti',
  infleto: 'infleto',
  inflexa: 'inflexa',
  inflexo: 'inflexo',
  inflige: 'inflige',
  infligi: 'infligi',
  inflija: 'inflija',
  inflijo: 'inflijo',
  inflita: 'inflita',
  inflito: 'inflito',
  inflora: 'inflora',
  inflore: 'inflore',
  infloro: 'infloro',
  influam: 'influam',
  influas: 'influas',
  influem: 'influem',
  influia: 'influía',
  influir: 'influir',
  influis: 'influis',
  influiu: 'influiu',
  influxo: 'influxo',
  infolio: 'infólio',
  infonai: 'infonai',
  infonam: 'infonam',
  infonar: 'infonar',
  infonas: 'infonas',
  infonei: 'infonei',
  infonem: 'infonem',
  infones: 'infones',
  infonou: 'infonou',
  informa: 'informa',
  informe: 'informe',
  informo: 'informo',
  infovia: 'infovia',
  infrata: 'infrata',
  infrato: 'infrato',
  infrene: 'infrene',
  infucas: 'infucas',
  infulas: 'ínfulas',
  infumos: 'infumos',
  infunda: 'infunda',
  infunde: 'infunde',
  infundi: 'infúndi',
  infundo: 'infundo',
  infunge: 'infunge',
  infunje: 'infunje',
  infunji: 'infúnji',
  infusai: 'infusai',
  infusam: 'infusam',
  infusao: 'infusão',
  infusar: 'infusar',
  infusas: 'infusas',
  infusei: 'infusei',
  infusem: 'infusem',
  infuses: 'infuses',
  infusos: 'infusos',
  infusou: 'infusou',
  ingacus: 'ingaçus',
  ingaiba: 'ingaíba',
  ingaibo: 'ingaíbo',
  ingaiva: 'ingaíva',
  ingalas: 'ingalas',
  inganda: 'inganda',
  ingando: 'ingando',
  ingaucu: 'ingauçu',
  ingauna: 'ingauna',
  ingauno: 'ingauno',
  ingenio: 'ingênio',
  ingente: 'ingente',
  ingenua: 'ingênua',
  ingenue: 'ingenue',
  ingenuo: 'ingênuo',
  ingerem: 'ingerem',
  ingeres: 'ingeres',
  ingeria: 'ingeria',
  ingerir: 'ingerir',
  ingeris: 'ingeris',
  ingeriu: 'ingeriu',
  ingesta: 'ingesta',
  ingesto: 'ingesto',
  ingevao: 'ingevão',
  ingevas: 'ingevãs',
  ingiram: 'ingiram',
  ingiras: 'ingiras',
  inglele: 'inglelê',
  inglesa: 'inglesa',
  inglese: 'inglese',
  ingleso: 'ingleso',
  ingomas: 'ingomas',
  ingomba: 'ingomba',
  ingombe: 'ingombe',
  ingomes: 'ingomes',
  ingonda: 'ingonda',
  ingondo: 'ingondo',
  ingonha: 'ingonha',
  ingonos: 'ingonos',
  ingotes: 'ingotés',
  ingraos: 'ingrãos',
  ingrata: 'ingrata',
  ingrato: 'ingrato',
  ingreda: 'íngreda',
  ingrede: 'ingrede',
  ingredi: 'ingredi',
  ingredo: 'íngredo',
  ingreme: 'íngreme',
  ingrias: 'íngrias',
  ingriba: 'ingriba',
  ingrida: 'ingrida',
  ingride: 'ingride',
  ingrido: 'ingrido',
  ingrime: 'ingrime',
  ingrios: 'íngrios',
  inguacu: 'inguaçu',
  inguaia: 'inguaiá',
  inguche: 'inguche',
  inguefo: 'inguefo',
  inguelo: 'inguelo',
  inguiba: 'inguíba',
  inguina: 'inguina',
  ingunda: 'ingundá',
  ingunde: 'ingunde',
  ingundo: 'ingundo',
  ingunga: 'ingunga',
  inhabil: 'inhábil',
  inhacas: 'inhacas',
  inhaiba: 'inhaíba',
  inhalar: 'inhalar',
  inhalas: 'inhalas',
  inhamal: 'inhamal',
  inhambi: 'inhambi',
  inhambu: 'inhambu',
  inhames: 'inhames',
  inhamui: 'inhamuí',
  inhanga: 'inhanga',
  inhanha: 'inhanha',
  inhanho: 'inhanho',
  inhapas: 'inhapas',
  inhapim: 'inhapim',
  inhapis: 'inhapis',
  inharas: 'inharas',
  inhares: 'inharés',
  inhatas: 'inhatas',
  inhatis: 'inhatis',
  inhatos: 'inhatos',
  inhauba: 'inhaúba',
  inhauma: 'inhaúma',
  inhenga: 'inhenga',
  inhengo: 'inhengo',
  inhenha: 'inhenha',
  inhenho: 'inhenho',
  inhibir: 'inhibir',
  inhumar: 'inhumar',
  inhumas: 'inhumas',
  inialas: 'inialas',
  inibais: 'inibais',
  inibamo: 'inibamo',
  inibemo: 'inibemo',
  inibiam: 'inibiam',
  inibias: 'inibias',
  inibida: 'inibida',
  inibido: 'inibido',
  inibimo: 'inibimo',
  inibina: 'inibina',
  inibira: 'inibira',
  iniciai: 'iniciai',
  inicial: 'inicial',
  iniciam: 'iniciam',
  iniciar: 'iniciar',
  inicias: 'inicias',
  iniciei: 'iniciei',
  iniciem: 'iniciem',
  inicies: 'inicies',
  inicios: 'inícios',
  iniciou: 'iniciou',
  iniidea: 'iniídea',
  iniideo: 'iniídeo',
  iniinea: 'iniínea',
  iniineo: 'iniíneo',
  inimboi: 'inimbói',
  inimbos: 'inimbós',
  inimiga: 'inimiga',
  inimigo: 'inimigo',
  inimita: 'inimitá',
  inimite: 'inimite',
  inimito: 'inimito',
  inimiza: 'inimiza',
  inimize: 'inimize',
  inimizo: 'inimizo',
  inioita: 'inioíta',
  iniomas: 'iniomas',
  iniomos: 'iniomos',
  iniopes: 'iniopes',
  iniopia: 'iniopia',
  iniquar: 'iniquar',
  iniquas: 'iníquas',
  iniquos: 'iníquos',
  injalas: 'injalas',
  injecao: 'injeção',
  injecto: 'injecto',
  injesta: 'injesta',
  injesto: 'injesto',
  injetai: 'injetai',
  injetam: 'injetam',
  injetar: 'injetar',
  injetas: 'injetas',
  injetei: 'injetei',
  injetem: 'injetem',
  injetes: 'injetes',
  injetor: 'injetor',
  injetou: 'injetou',
  injunca: 'injunçá',
  injunce: 'injunce',
  injunco: 'injunço',
  injunge: 'injunge',
  injungi: 'injungi',
  injunja: 'injunja',
  injunjo: 'injunjo',
  injuria: 'injúria',
  injurie: 'injurie',
  injurio: 'injúrio',
  injusta: 'injusta',
  injusto: 'injusto',
  inlhofo: 'inlhofo',
  inlicai: 'inliçai',
  inlicam: 'inliçam',
  inlicar: 'inliçar',
  inlicas: 'inliças',
  inlicei: 'inlicei',
  inlicem: 'inlicem',
  inlices: 'inlices',
  inlicou: 'inliçou',
  inlusir: 'inlusir',
  inmetro: 'inmetro',
  inobres: 'inobres',
  inocibe: 'inocibe',
  inocito: 'inócito',
  inocuas: 'inócuas',
  inocula: 'inocula',
  inocule: 'inocule',
  inoculo: 'inoculo',
  inocuos: 'inócuos',
  inodada: 'inodada',
  inodado: 'inodado',
  inodora: 'inodora',
  inodoro: 'inodoro',
  inodula: 'inódula',
  inoense: 'inoense',
  inofila: 'inofila',
  inofilo: 'inofilo',
  inolito: 'inólito',
  inoloma: 'inoloma',
  inopias: 'inópias',
  inopina: 'inopina',
  inopino: 'inopino',
  inorada: 'inorada',
  inorado: 'inorado',
  inorais: 'inorais',
  inoramo: 'inoramo',
  inorara: 'inorará',
  inorava: 'inorava',
  inoreis: 'inoreis',
  inoremo: 'inoremo',
  inosato: 'inosato',
  inosica: 'inósica',
  inosico: 'inósico',
  inosina: 'inosina',
  inosita: 'inosita',
  inosite: 'inosite',
  inovada: 'inovada',
  inovado: 'inovado',
  inovais: 'inovais',
  inovamo: 'inovamo',
  inovara: 'inovara',
  inovava: 'inovava',
  inoveis: 'inoveis',
  inovemo: 'inovemo',
  inoxios: 'inóxios',
  inquaia: 'inquaia',
  inquera: 'inquera',
  inquere: 'inquere',
  inqueri: 'inqueri',
  inquero: 'inquero',
  inquice: 'inquice',
  inquina: 'inquina',
  inquine: 'inquine',
  inquini: 'inquini',
  inquino: 'inquino',
  inquira: 'inquira',
  inquire: 'inquire',
  inquiri: 'inquiri',
  inquiro: 'inquiro',
  inquisa: 'inquisa',
  inquita: 'inquita',
  insanas: 'insanas',
  insania: 'insânia',
  insanos: 'insanos',
  inscias: 'ínscias',
  inscios: 'ínscios',
  insecta: 'insecta',
  insecto: 'insecto',
  inserem: 'inserem',
  inseres: 'inseres',
  inseria: 'inseria',
  inserir: 'inserir',
  inseris: 'inseris',
  inseriu: 'inseriu',
  inserta: 'inserta',
  inserte: 'inserte',
  inserto: 'inserto',
  inserve: 'inserve',
  insetas: 'insetas',
  insetil: 'insétil',
  insetos: 'insetos',
  insider: 'insider',
  insidia: 'insídia',
  insidie: 'insidie',
  insidio: 'insidio',
  insight: 'insight',
  insigne: 'insigne',
  insinua: 'insinua',
  insinue: 'insinue',
  insinuo: 'insinuo',
  insiram: 'insiram',
  insiras: 'insiras',
  insista: 'insista',
  insiste: 'insiste',
  insisti: 'insisti',
  insisto: 'insisto',
  insitas: 'ínsitas',
  insitos: 'ínsitos',
  insolai: 'insolai',
  insolam: 'insolam',
  insolar: 'insolar',
  insolas: 'insolas',
  insolei: 'insolei',
  insolem: 'insolem',
  insoles: 'insoles',
  insolou: 'insolou',
  insomem: 'insomem',
  insomes: 'insomes',
  insomne: 'insomne',
  insonas: 'ínsonas',
  insones: 'insones',
  insonia: 'insônia',
  insonie: 'insonie',
  insonio: 'insonio',
  insonos: 'ínsonos',
  insonsa: 'insonsa',
  insonso: 'insonso',
  insonte: 'insonte',
  insorge: 'insorge',
  insossa: 'insossa',
  insosse: 'insosse',
  insosso: 'insosso',
  inspeta: 'inspeta',
  inspete: 'inspete',
  inspeto: 'inspeto',
  inspira: 'inspira',
  inspire: 'inspire',
  inspiro: 'inspiro',
  instada: 'instada',
  instado: 'instado',
  instais: 'instais',
  instala: 'instala',
  instale: 'instale',
  instalo: 'instalo',
  instamo: 'instamo',
  instara: 'instara',
  instava: 'instava',
  insteis: 'insteis',
  instemo: 'instemo',
  instiga: 'instiga',
  instigo: 'instigo',
  instila: 'instila',
  instile: 'instile',
  instilo: 'instilo',
  instita: 'ínstita',
  instrua: 'instrua',
  instrui: 'instrui',
  instruo: 'instruo',
  insuave: 'insuave',
  insubre: 'ínsubre',
  insucos: 'insucos',
  insueta: 'insueta',
  insueto: 'insueto',
  insufla: 'insufla',
  insufle: 'insufle',
  insuflo: 'insuflo',
  insulai: 'insulai',
  insulam: 'insulam',
  insular: 'insular',
  insulas: 'insulas',
  insulei: 'insulei',
  insulem: 'insulem',
  insules: 'insules',
  insulin: 'insulin',
  insulos: 'ínsulos',
  insulou: 'insulou',
  insulsa: 'insulsa',
  insulso: 'insulso',
  insulta: 'insulta',
  insulte: 'insulte',
  insulto: 'insulto',
  insumai: 'insumai',
  insumam: 'insumam',
  insumar: 'insumar',
  insumas: 'insumas',
  insumei: 'insumei',
  insumem: 'insumem',
  insumes: 'insumes',
  insumia: 'insumia',
  insumir: 'insumir',
  insumis: 'insumis',
  insumiu: 'insumiu',
  insumos: 'insumos',
  insumou: 'insumou',
  insurge: 'insurge',
  insurgi: 'insurgi',
  insurja: 'insurja',
  insurjo: 'insurjo',
  intacta: 'intacta',
  intalho: 'intalho',
  intambe: 'intambe',
  intanha: 'intanha',
  intatas: 'intatas',
  intatil: 'intátil',
  intatos: 'intatos',
  intauba: 'intaúba',
  integra: 'integra',
  integre: 'integre',
  integro: 'íntegro',
  inteiga: 'inteigá',
  inteigo: 'inteigo',
  inteira: 'inteira',
  inteire: 'inteire',
  inteiro: 'inteiro',
  intenda: 'intenda',
  intende: 'intende',
  intendi: 'intendi',
  intendo: 'intendo',
  intensa: 'intensa',
  intense: 'intense',
  intenso: 'intenso',
  intenta: 'intenta',
  intente: 'intente',
  intento: 'intento',
  interes: 'interés',
  interim: 'ínterim',
  interma: 'interma',
  interme: 'interme',
  intermo: 'intermo',
  interna: 'interna',
  interne: 'interne',
  interno: 'interno',
  interpo: 'interpô',
  interve: 'intervê',
  intervi: 'intervi',
  intexto: 'intexto',
  inticai: 'inticai',
  inticam: 'inticam',
  inticar: 'inticar',
  inticas: 'inticas',
  inticou: 'inticou',
  intigas: 'intigas',
  intigos: 'intigos',
  intimai: 'intimai',
  intimam: 'intimam',
  intimar: 'intimar',
  intimas: 'íntimas',
  intimei: 'intimei',
  intimem: 'intimem',
  intimes: 'intimes',
  intimos: 'íntimos',
  intimou: 'intimou',
  intimua: 'intimua',
  intinas: 'intinas',
  intinos: 'intinos',
  intique: 'intique',
  intisis: 'intisis',
  intoira: 'intoira',
  intoire: 'intoire',
  intoiri: 'intoiri',
  intoiro: 'intoiro',
  intonai: 'intonai',
  intonam: 'intonam',
  intonar: 'intonar',
  intonas: 'intonas',
  intonei: 'intonei',
  intonem: 'intonem',
  intones: 'intones',
  intonou: 'intonou',
  intonsa: 'intonsa',
  intonso: 'intonso',
  intotos: 'intotos',
  intoura: 'intoura',
  intoure: 'intoure',
  intouri: 'intouri',
  intouro: 'intouro',
  intrata: 'intrata',
  intrato: 'intrato',
  intrica: 'intrica',
  intrico: 'intrico',
  intriga: 'intriga',
  intrigo: 'intrigo',
  intrita: 'intrita',
  introes: 'intrões',
  intruda: 'intruda',
  intrude: 'intrude',
  intrudi: 'intrudi',
  intrudo: 'intrudo',
  intruja: 'intruja',
  intruje: 'intruje',
  intruji: 'intruji',
  intrujo: 'intrujo',
  intrusa: 'intrusa',
  intruso: 'intruso',
  intuais: 'intuais',
  intuamo: 'intuamo',
  intubai: 'intubai',
  intubam: 'intubam',
  intubar: 'intubar',
  intubas: 'intubas',
  intubei: 'intubei',
  intubem: 'intubem',
  intubes: 'intubes',
  intubou: 'intubou',
  intuiam: 'intuíam',
  intuias: 'intuías',
  intuida: 'intuída',
  intuido: 'intuído',
  intuimo: 'intuímo',
  intuira: 'intuirá',
  intuito: 'intuito',
  intulas: 'intulás',
  intules: 'intules',
  inubias: 'inúbias',
  inubila: 'inúbila',
  inubilo: 'inúbilo',
  inuites: 'inuítes',
  inulase: 'inulase',
  inuleas: 'inúleas',
  inuleos: 'inúleos',
  inulina: 'inulina',
  inultas: 'inultas',
  inultos: 'inultos',
  inumada: 'inumada',
  inumado: 'inumado',
  inumais: 'inumais',
  inumamo: 'inumamo',
  inumana: 'inumana',
  inumano: 'inumano',
  inumara: 'inumara',
  inumava: 'inumava',
  inumeis: 'inumeis',
  inumemo: 'inumemo',
  inumera: 'inúmera',
  inumero: 'inúmero',
  inundai: 'inundai',
  inundam: 'inundam',
  inundar: 'inundar',
  inundas: 'inundas',
  inundei: 'inundei',
  inundem: 'inundem',
  inundes: 'inundes',
  inundou: 'inundou',
  inuptas: 'inuptas',
  inuptos: 'inuptos',
  inusual: 'inusual',
  inuteis: 'inúteis',
  invadam: 'invadam',
  invadas: 'invadas',
  invadem: 'invadem',
  invades: 'invades',
  invadia: 'invadia',
  invadir: 'invadir',
  invadis: 'invadis',
  invadiu: 'invadiu',
  invares: 'invares',
  invaria: 'invaria',
  invarie: 'invarie',
  invario: 'invario',
  invasai: 'invasai',
  invasam: 'invasam',
  invasao: 'invasão',
  invasar: 'invasar',
  invasas: 'invasas',
  invasei: 'invasei',
  invasem: 'invasem',
  invases: 'invases',
  invasor: 'invasor',
  invasou: 'invasou',
  invecas: 'invecas',
  invecta: 'invecta',
  invecte: 'invecte',
  invecto: 'invecto',
  invejai: 'invejai',
  invejam: 'invejam',
  invejar: 'invejar',
  invejas: 'invejas',
  invejei: 'invejei',
  invejem: 'invejem',
  invejes: 'invejes',
  invejou: 'invejou',
  invenal: 'invenal',
  inventa: 'inventa',
  invente: 'invente',
  invento: 'invento',
  inveraz: 'inveraz',
  inverna: 'inverna',
  inverne: 'inverne',
  inverno: 'inverno',
  inversa: 'inversa',
  inverso: 'inverso',
  inverta: 'inverta',
  inverte: 'inverte',
  inverti: 'inverti',
  inverto: 'inverto',
  investe: 'investe',
  investi: 'investi',
  invetai: 'invetai',
  invetam: 'invetam',
  invetar: 'invetar',
  invetas: 'invetas',
  invetei: 'invetei',
  invetem: 'invetem',
  invetes: 'invetes',
  invetou: 'invetou',
  invices: 'invices',
  invicta: 'invicta',
  invicto: 'invicto',
  invidai: 'invidai',
  invidam: 'invidam',
  invidar: 'invidar',
  invidas: 'ínvidas',
  invidei: 'invidei',
  invidem: 'invidem',
  invides: 'invides',
  invidia: 'invídia',
  invidie: 'invidie',
  invidio: 'invidio',
  invidos: 'ínvidos',
  invidou: 'invidou',
  inviril: 'inviril',
  inviris: 'inviris',
  invisas: 'invisas',
  invisos: 'invisos',
  invista: 'invista',
  invisto: 'invisto',
  invitai: 'invitai',
  invital: 'invital',
  invitam: 'invitam',
  invitar: 'invitar',
  invitas: 'invitas',
  invitei: 'invitei',
  invitem: 'invitem',
  invites: 'invites',
  invitou: 'invitou',
  invocai: 'invocai',
  invocam: 'invocam',
  invocar: 'invocar',
  invocas: 'invocas',
  invocou: 'invocou',
  invogal: 'invogal',
  involua: 'involua',
  involui: 'involui',
  involuo: 'involuo',
  invoque: 'invoque',
  invulga: 'invulgá',
  invulgo: 'invulgo',
  inxerir: 'inxerir',
  inxidro: 'inxidro',
  inyoita: 'inyoíta',
  inzalas: 'inzalas',
  inzenza: 'inzenza',
  inzibas: 'inzibas',
  inzicas: 'inzicas',
  inzonai: 'inzonai',
  inzonam: 'inzonam',
  inzonar: 'inzonar',
  inzonas: 'inzonas',
  inzonei: 'inzonei',
  inzonem: 'inzonem',
  inzones: 'inzones',
  inzonou: 'inzonou',
  iobadao: 'iobadão',
  iobadas: 'iobadas',
  iobador: 'iobador',
  iobados: 'iobados',
  iobamos: 'iobamos',
  iobando: 'iobando',
  iobante: 'iobante',
  iobaram: 'iobaram',
  iobarao: 'iobarão',
  iobaras: 'iobarás',
  iobarei: 'iobarei',
  iobarem: 'iobarem',
  iobares: 'iobares',
  iobaria: 'iobaria',
  iobarmo: 'iobarmo',
  iobasse: 'iobasse',
  iobaste: 'iobaste',
  iobavam: 'iobavam',
  iobavas: 'iobavas',
  iobemos: 'iobemos',
  iocrina: 'iócrina',
  iocrino: 'iócrino',
  iocroma: 'iocroma',
  ioculas: 'ioculas',
  ioculos: 'ioculos',
  iodacao: 'iodação',
  iodadao: 'iodadão',
  iodadas: 'iodadas',
  iodador: 'iodador',
  iodados: 'iodados',
  iodalil: 'iodalil',
  iodalis: 'iodalis',
  iodamil: 'iodamil',
  iodamis: 'iodamis',
  iodamos: 'iodamos',
  iodando: 'iodando',
  iodante: 'iodante',
  iodaram: 'iodaram',
  iodarao: 'iodarão',
  iodaras: 'iodarás',
  iodarei: 'iodarei',
  iodarem: 'iodarem',
  iodares: 'iodares',
  iodaria: 'iodaria',
  iodarmo: 'iodarmo',
  iodasse: 'iodasse',
  iodaste: 'iodaste',
  iodatos: 'iodatos',
  iodavam: 'iodavam',
  iodavas: 'iodavas',
  iodavel: 'iodável',
  iodeina: 'iodeína',
  iodemia: 'iodemia',
  iodemos: 'iodemos',
  iodetai: 'iodetai',
  iodetam: 'iodetam',
  iodetar: 'iodetar',
  iodetas: 'iodetas',
  iodetei: 'iodetei',
  iodetem: 'iodetem',
  iodetes: 'iodetes',
  iodetos: 'iodetos',
  iodetou: 'iodetou',
  iodicas: 'iódicas',
  iodicos: 'iódicos',
  iodides: 'iódides',
  iodinai: 'iodinai',
  iodinam: 'iodinam',
  iodinar: 'iodinar',
  iodinas: 'iodinas',
  iodinei: 'iodinei',
  iodinem: 'iodinem',
  iodines: 'iodines',
  iodinho: 'iodinho',
  iodinou: 'iodinou',
  iodismo: 'iodismo',
  iodista: 'iodista',
  iodites: 'iodites',
  iodonas: 'iodonas',
  iodonio: 'iodônio',
  iodosas: 'iodosas',
  iodosos: 'iodosos',
  ioexois: 'ioexóis',
  iofobia: 'iofobia',
  ioguica: 'ióguica',
  ioguico: 'ióguico',
  ioguins: 'ioguins',
  iogurte: 'iogurte',
  ioiocas: 'ioiocas',
  ioldias: 'iôldias',
  iolemas: 'iolemas',
  iolitas: 'iolitas',
  iolitos: 'iólitos',
  iologas: 'iólogas',
  iologia: 'iologia',
  iologos: 'iólogos',
  ionenos: 'ionenos',
  ionicas: 'iônicas',
  ionicos: 'iônicos',
  ionidio: 'ionídio',
  ioninea: 'ionínea',
  ionineo: 'ioníneo',
  ionismo: 'ionismo',
  ionista: 'ionista',
  ionitas: 'ionitas',
  ionizai: 'ionizai',
  ionizam: 'ionizam',
  ionizar: 'ionizar',
  ionizas: 'ionizas',
  ionizei: 'ionizei',
  ionizem: 'ionizem',
  ionizes: 'ionizes',
  ionizou: 'ionizou',
  iononas: 'iononas',
  ionones: 'ionones',
  iontica: 'iôntica',
  iontico: 'iôntico',
  iontiza: 'iontizá',
  iontize: 'iontize',
  iontizo: 'iontizo',
  iopidol: 'iopidol',
  iopsilo: 'iopsilo',
  iorimas: 'iorimãs',
  iorubas: 'iorubas',
  ioterio: 'iotério',
  iotizai: 'iotizai',
  iotizam: 'iotizam',
  iotizar: 'iotizar',
  iotizas: 'iotizas',
  iotizei: 'iotizei',
  iotizem: 'iotizem',
  iotizes: 'iotizes',
  iotizou: 'iotizou',
  iozitas: 'iozitas',
  ipecaas: 'ipecaás',
  ipecina: 'ipecina',
  ipeense: 'ipeense',
  ipequis: 'ipequis',
  iperana: 'iperana',
  iperita: 'iperita',
  iperite: 'iperite',
  ipeubas: 'ipeúbas',
  ipeunas: 'ipeúnas',
  ipeuvas: 'ipeúvas',
  ipideas: 'ipídeas',
  ipideos: 'ipídeos',
  ipineas: 'ipíneas',
  ipineos: 'ipíneos',
  ipombos: 'ipombos',
  ipomeia: 'ipomeia',
  iprense: 'iprense',
  ipsidas: 'ipsidas',
  ipsidos: 'ipsidos',
  ipsilao: 'ipsilão',
  ipsilon: 'ípsilon',
  ipsilos: 'ipsilos',
  iptimas: 'iptimas',
  ipuabas: 'ipuabas',
  ipuacus: 'ipuaçus',
  ipuadas: 'ipuadas',
  ipuanas: 'ipuanas',
  ipuanos: 'ipuanos',
  ipueira: 'ipueira',
  ipuense: 'ipuense',
  ipueras: 'ipueras',
  ipurina: 'ipuriná',
  ipuruna: 'ipuruna',
  iquitas: 'iquitas',
  iquitos: 'iquitos',
  iracuis: 'iracuís',
  iradoes: 'iradões',
  iradona: 'iradona',
  iradora: 'iradora',
  iraiara: 'iraiara',
  iraibas: 'iraíbas',
  irambas: 'irambas',
  iranche: 'iranche',
  iranias: 'irânias',
  iranica: 'irânica',
  iranico: 'irânico',
  iranios: 'irânios',
  iranita: 'iranita',
  irantes: 'irantes',
  iranxes: 'iranxes',
  iranxim: 'iranxim',
  irapoca: 'irapoca',
  irapuas: 'irapuás',
  irapuia: 'irapuia',
  irapura: 'irapurá',
  irapuru: 'irapuru',
  irarana: 'irarana',
  irardes: 'irardes',
  irareis: 'irareis',
  iraremo: 'iraremo',
  irariam: 'irariam',
  irarias: 'irarias',
  irarmos: 'irarmos',
  irassem: 'irassem',
  irasses: 'irasses',
  irastes: 'irastes',
  irataua: 'iratauá',
  iratins: 'iratins',
  iratuas: 'iratuãs',
  iraucus: 'irauçus',
  iraunas: 'iraúnas',
  iraveis: 'iráveis',
  iravias: 'irávias',
  iraxins: 'iraxins',
  irenica: 'irênica',
  irenico: 'irênico',
  irenios: 'irênios',
  iresina: 'iresina',
  iresine: 'iresine',
  iretois: 'iretóis',
  irguico: 'irguiço',
  iriacao: 'iriação',
  iriadao: 'iriadão',
  iriadas: 'iriadas',
  iriador: 'iriador',
  iriados: 'iriados',
  iriamos: 'iríamos',
  irianas: 'irianas',
  iriando: 'iriando',
  irianos: 'irianos',
  iriante: 'iriante',
  iriaram: 'iriaram',
  iriarao: 'iriarão',
  iriaras: 'iriarás',
  iriarei: 'iriarei',
  iriarem: 'iriarem',
  iriares: 'iriares',
  iriaria: 'iriaria',
  iriarmo: 'iriarmo',
  iriasse: 'iriasse',
  iriaste: 'iriaste',
  iriates: 'iriates',
  iriavam: 'iriavam',
  iriavas: 'iriavas',
  iriceca: 'iriceca',
  iricuri: 'iricuri',
  iridato: 'iridato',
  irideas: 'irídeas',
  irideca: 'irideca',
  irideos: 'irídeos',
  iridese: 'irídese',
  iridiai: 'iridiai',
  iridiam: 'iridiam',
  iridiar: 'iridiar',
  iridias: 'iridias',
  iridica: 'irídica',
  iridico: 'irídico',
  iridiei: 'iridiei',
  iridiem: 'iridiem',
  iridies: 'iridies',
  iridina: 'iridina',
  iridios: 'irídios',
  iridiou: 'iridiou',
  iridite: 'iridite',
  iridois: 'iridóis',
  iriemos: 'iriemos',
  iriense: 'iriense',
  irinita: 'irinita',
  iririba: 'iriribá',
  irisada: 'irisada',
  irisado: 'irisado',
  irisais: 'irisais',
  irisamo: 'irisamo',
  irisara: 'irisará',
  irisava: 'irisava',
  iriseis: 'iriseis',
  irisemo: 'irisemo',
  irisina: 'irisina',
  irizada: 'irizada',
  irizado: 'irizado',
  irizais: 'irizais',
  irizamo: 'irizamo',
  irizara: 'irizara',
  irizava: 'irizava',
  irizeis: 'irizeis',
  irizemo: 'irizemo',
  irmanai: 'irmanai',
  irmanam: 'irmanam',
  irmanar: 'irmanar',
  irmanas: 'irmanas',
  irmanei: 'irmanei',
  irmanem: 'irmanem',
  irmanes: 'irmanes',
  irmanou: 'irmanou',
  irmonas: 'irmonas',
  irolzao: 'irolzão',
  ironias: 'ironias',
  ironica: 'irônica',
  ironico: 'irônico',
  ironiza: 'ironiza',
  ironize: 'ironize',
  ironizo: 'ironizo',
  iroques: 'iroquês',
  irosina: 'irosina',
  irradia: 'irradia',
  irradie: 'irradie',
  irradio: 'irradio',
  irreais: 'irreais',
  irretes: 'irretes',
  irrigai: 'irrigai',
  irrigam: 'irrigam',
  irrigar: 'irrigar',
  irrigas: 'irrigas',
  irrigou: 'irrigou',
  irrigua: 'irrígua',
  irrigue: 'irrigue',
  irriguo: 'irríguo',
  irrisao: 'irrisão',
  irrisor: 'irrisor',
  irritai: 'irritai',
  irritam: 'irritam',
  irritar: 'irritar',
  irritas: 'irritas',
  irritei: 'irritei',
  irritem: 'irritem',
  irrites: 'irrites',
  irritos: 'írritos',
  irritou: 'irritou',
  irrival: 'irrival',
  irrogai: 'irrogai',
  irrogam: 'irrogam',
  irrogar: 'irrogar',
  irrogas: 'irrogas',
  irrogou: 'irrogou',
  irrogue: 'irrogue',
  irrompa: 'irrompa',
  irrompe: 'irrompe',
  irrompi: 'irrompi',
  irrompo: 'irrompo',
  irrorai: 'irrorai',
  irroram: 'irroram',
  irrorar: 'irrorar',
  irroras: 'irroras',
  irrorei: 'irrorei',
  irrorem: 'irrorem',
  irrores: 'irrores',
  irrorou: 'irrorou',
  irruais: 'irruais',
  irruiam: 'irruíam',
  irruias: 'irruías',
  irruida: 'irruída',
  irruido: 'irruído',
  irruira: 'irruirá',
  irrupta: 'irrupta',
  irrupto: 'irrupto',
  irubaia: 'irubaia',
  iruexim: 'iruexim',
  isabeis: 'isabeis',
  isabela: 'isabela',
  isacnes: 'isacnes',
  isagoge: 'isagoge',
  isamato: 'isamato',
  isamica: 'isâmica',
  isamico: 'isâmico',
  isamida: 'isamida',
  isamila: 'isamila',
  isandra: 'isandra',
  isandro: 'isandro',
  isantos: 'isantos',
  isanuzi: 'isanúzi',
  isarcas: 'isarcas',
  isarcos: 'isarcos',
  isarias: 'isárias',
  isatana: 'isatana',
  isatato: 'isatato',
  isatica: 'isática',
  isatico: 'isático',
  isatido: 'isatido',
  isatina: 'isatina',
  isauras: 'isauras',
  isauros: 'isauros',
  isaxica: 'isáxica',
  isaxico: 'isáxico',
  isaxone: 'isáxone',
  iscacos: 'iscaços',
  iscadal: 'iscadal',
  iscadao: 'iscadão',
  iscadas: 'iscadas',
  iscador: 'iscador',
  iscados: 'iscados',
  iscamos: 'iscamos',
  iscando: 'iscando',
  iscante: 'iscante',
  iscaram: 'iscaram',
  iscarao: 'iscarão',
  iscaras: 'iscarás',
  iscarei: 'iscarei',
  iscarem: 'iscarem',
  iscares: 'iscares',
  iscaria: 'iscaria',
  iscarmo: 'iscarmo',
  iscasse: 'iscasse',
  iscaste: 'iscaste',
  iscavam: 'iscavam',
  iscavas: 'iscavas',
  iscnura: 'iscnura',
  iscnuro: 'iscnuro',
  iscuria: 'iscuria',
  isencao: 'isenção',
  isenite: 'isenite',
  isentai: 'isentai',
  isentam: 'isentam',
  isentar: 'isentar',
  isentas: 'isentas',
  isentei: 'isentei',
  isentem: 'isentem',
  isentes: 'isentes',
  isentos: 'isentos',
  isentou: 'isentou',
  iserina: 'iserina',
  iserita: 'iserita',
  isertia: 'isértia',
  isiacas: 'isíacas',
  isiacos: 'isíacos',
  isideas: 'isídeas',
  isidela: 'isidela',
  isideos: 'isídeos',
  isidios: 'isídios',
  isidora: 'isidora',
  isidoro: 'isidoro',
  isistio: 'isístio',
  isladas: 'isladas',
  islados: 'islados',
  islames: 'islames',
  islenas: 'islenas',
  islenha: 'islenha',
  islenho: 'islenho',
  islenos: 'islenos',
  ismaria: 'ismária',
  ismario: 'ismário',
  ismenes: 'ismenes',
  ismenia: 'ismênia',
  isoamil: 'isoamil',
  isoamis: 'isoamis',
  isoaxes: 'isoaxes',
  isobara: 'isóbara',
  isobare: 'isóbare',
  isobaro: 'isóbaro',
  isobasa: 'isóbasa',
  isobata: 'isóbata',
  isobria: 'isóbria',
  isobrio: 'isóbrio',
  isocara: 'isócara',
  isocero: 'isócero',
  isocola: 'isócola',
  isocolo: 'isócolo',
  isocoma: 'isócoma',
  isocomo: 'isócomo',
  isocora: 'isócora',
  isocoro: 'isócoro',
  isoctil: 'isoctil',
  isoctis: 'isoctis',
  isodica: 'isódica',
  isodico: 'isódico',
  isodoma: 'isódoma',
  isodomo: 'isódomo',
  isoelia: 'isoélia',
  isoetal: 'isoetal',
  isoetea: 'isoétea',
  isoeteo: 'isoéteo',
  isoetes: 'isoetes',
  isoetos: 'isóetos',
  isofaga: 'isófaga',
  isofago: 'isófago',
  isofana: 'isofana',
  isofano: 'isófano',
  isofena: 'isofena',
  isofeno: 'isofeno',
  isofila: 'isofila',
  isofilo: 'isofilo',
  isofito: 'isófito',
  isofona: 'isófona',
  isofono: 'isófono',
  isofote: 'isofote',
  isofoto: 'isofoto',
  isogais: 'isogais',
  isogama: 'isógama',
  isogamo: 'isógamo',
  isogeis: 'isogéis',
  isogena: 'isógena',
  isogeno: 'isógeno',
  isogina: 'isógina',
  isogino: 'isógino',
  isogira: 'isogira',
  isogiro: 'isogiro',
  isogona: 'isógona',
  isogono: 'isógono',
  isoieta: 'isoieta',
  isoipsa: 'isoípsa',
  isoipso: 'isoípso',
  isolada: 'isolada',
  isolado: 'isolado',
  isolais: 'isolais',
  isolamo: 'isolamo',
  isolara: 'isolara',
  isolato: 'isolato',
  isolava: 'isolava',
  isoleis: 'isoleis',
  isolemo: 'isolemo',
  isolepe: 'isólepe',
  isolina: 'isolina',
  isolise: 'isólise',
  isologa: 'isóloga',
  isologo: 'isólogo',
  isoloma: 'isoloma',
  isolona: 'isolona',
  isomalo: 'isômalo',
  isombra: 'isombra',
  isombro: 'isombro',
  isomera: 'isômera',
  isomere: 'isômere',
  isomero: 'isômero',
  isomira: 'isomira',
  isonefa: 'isônefa',
  isonefo: 'isônefo',
  isonema: 'isonema',
  isonica: 'isônica',
  isonico: 'isônico',
  isonima: 'isônima',
  isonimo: 'isônimo',
  isonoma: 'isônoma',
  isonomo: 'isônomo',
  isopaca: 'isópaca',
  isopaco: 'isópaco',
  isopaga: 'isópaga',
  isopago: 'isópago',
  isopata: 'isopata',
  isopete: 'isopete',
  isopias: 'isopias',
  isopica: 'isópica',
  isopico: 'isópico',
  isopiro: 'isópiro',
  isopode: 'isópode',
  isopora: 'isópora',
  isosmia: 'isosmia',
  isospin: 'isospin',
  isotaca: 'isótaca',
  isotaco: 'isótaco',
  isotana: 'isotana',
  isotele: 'isótele',
  isotera: 'isótera',
  isotero: 'isótero',
  isotima: 'isotima',
  isotimo: 'isotimo',
  isotipo: 'isótipo',
  isotoma: 'isótoma',
  isotono: 'isótono',
  isotopo: 'isótopo',
  isotron: 'isotron',
  isovala: 'isóvala',
  isovalo: 'isóvalo',
  isozima: 'isozima',
  isqueis: 'isqueis',
  isquele: 'isquelê',
  isquemo: 'isquemo',
  isquial: 'isquial',
  isquiao: 'isquião',
  isquion: 'ísquion',
  isquios: 'ísquios',
  isquiro: 'isquiro',
  israeis: 'israéis',
  israeli: 'israeli',
  israita: 'israíta',
  issaica: 'issaica',
  issaico: 'issaico',
  issaria: 'issária',
  isseias: 'isseias',
  issenas: 'issenas',
  issende: 'issende',
  issense: 'issense',
  issicas: 'issicas',
  issicos: 'issicos',
  issidas: 'íssidas',
  issidea: 'issídea',
  issideo: 'issídeo',
  issinea: 'issínea',
  issineo: 'issíneo',
  issobos: 'issobos',
  issubos: 'issubos',
  issubus: 'issúbus',
  issucas: 'issucas',
  issucos: 'issucos',
  issunhe: 'issunhe',
  istevao: 'istevão',
  istevas: 'istevãs',
  istmias: 'ístmias',
  istmica: 'ístmica',
  istmico: 'ístmico',
  istmios: 'ístmios',
  istmite: 'istmite',
  istrica: 'ístrica',
  istrico: 'ístrico',
  isurias: 'isúrias',
  isurica: 'isúrica',
  isurico: 'isúrico',
  isvitas: 'isvitas',
  itacava: 'itacava',
  itaciba: 'itaciba',
  itacuas: 'itacuãs',
  itacuru: 'itacuru',
  itaense: 'itaense',
  itaibas: 'itaíbas',
  itaimbe: 'itaimbé',
  itajuba: 'itajuba',
  italica: 'itálica',
  italico: 'itálico',
  itamaca: 'itamaca',
  itambas: 'itambás',
  itambes: 'itambés',
  itambis: 'itambis',
  itangas: 'itangás',
  itanhas: 'itanhas',
  itaocas: 'itaocas',
  itapaba: 'itapaba',
  itapeba: 'itapeba',
  itapema: 'itapema',
  itapeua: 'itapeuá',
  itapeva: 'itapeva',
  itapiri: 'itapiri',
  itapria: 'itapriá',
  itapuas: 'itapuás',
  itarare: 'itararé',
  itatiba: 'itatiba',
  itaubas: 'itaúbas',
  itaunas: 'itaúnas',
  itemiza: 'itemiza',
  itemize: 'itemize',
  itemizo: 'itemizo',
  itengos: 'itengos',
  iteques: 'iteques',
  iterada: 'iterada',
  iterado: 'iterado',
  iterais: 'iterais',
  iteramo: 'iteramo',
  iterara: 'iterara',
  iterava: 'iterava',
  iterbio: 'itérbio',
  itereis: 'itereis',
  iteremo: 'iteremo',
  iterica: 'itérica',
  iterico: 'itérico',
  itetica: 'itética',
  itetico: 'itético',
  itiassa: 'itiassa',
  itifalo: 'itifalo',
  itifila: 'itifila',
  itifilo: 'itifilo',
  itiquis: 'itiquis',
  itoitas: 'itoítas',
  itomisa: 'itomisa',
  itoneia: 'itoneia',
  itoneus: 'itoneus',
  itororo: 'itororó',
  itricas: 'ítricas',
  itricos: 'ítricos',
  itritas: 'itritas',
  itrites: 'itrites',
  ituanas: 'ituanas',
  ituanos: 'ituanos',
  itucale: 'itucale',
  ituense: 'ituense',
  ituiana: 'ituiana',
  ituitui: 'ituituí',
  itumbos: 'itumbos',
  itupava: 'itupava',
  itupeba: 'itupeba',
  itupeva: 'itupeva',
  itureia: 'itureia',
  itureus: 'itureus',
  iuaense: 'iuaense',
  iuberis: 'iuberis',
  iucagir: 'iucagir',
  iucajir: 'iucajir',
  iucaras: 'iuçaras',
  iucusso: 'iucusso',
  iulidas: 'iúlidas',
  iulidea: 'iulídea',
  iulideo: 'iulídeo',
  iumirim: 'iumirim',
  iunense: 'iunense',
  iupatis: 'iupatis',
  iuquice: 'iuquicé',
  iuquiri: 'iuquiri',
  iuracos: 'iuracos',
  iuraras: 'iurarás',
  iuraris: 'iuraris',
  iurumis: 'iurumis',
  ivainas: 'ivaínas',
  ivapare: 'ivaparé',
  iviraro: 'iviraro',
  ivorito: 'ivorito',
  ixamata: 'ixâmata',
  ixantos: 'ixantos',
  ixineas: 'ixíneas',
  ixineos: 'ixíneos',
  ixinque: 'ixinque',
  ixiodea: 'ixiódea',
  ixiodeo: 'ixiódeo',
  ixodida: 'ixódida',
  ixodina: 'ixodina',
  ixodios: 'ixódios',
  ixofaga: 'ixófaga',
  ixofago: 'ixófago',
  ixolita: 'ixolita',
  ixolite: 'ixolite',
  ixonoto: 'ixonoto',
  ixoreas: 'ixóreas',
  ixuabos: 'ixuabos',
  ixuense: 'ixuense',
  izalcas: 'izalcas',
  izalcos: 'izalcos',
  izalzao: 'izalzão',
  izarras: 'izarras',
  izipras: 'izipras',
  izombes: 'izombes',
  jabaana: 'jabaana',
  jabaras: 'jabaras',
  jabeada: 'jabeada',
  jabeado: 'jabeado',
  jabeais: 'jabeais',
  jabeara: 'jabeara',
  jabeava: 'jabeava',
  jabecas: 'jabecas',
  jabeeis: 'jabeeis',
  jabeiam: 'jabeiam',
  jabeias: 'jabeias',
  jabeiem: 'jabeiem',
  jabeies: 'jabeies',
  jabirus: 'jabirus',
  jabitas: 'jabitás',
  jabites: 'jabites',
  jabonas: 'jabonas',
  jabotas: 'jabotas',
  jaburas: 'jaburás',
  jaburus: 'jaburus',
  jabutas: 'jabutás',
  jabutia: 'jabutia',
  jabutim: 'jabutim',
  jabutis: 'jabutis',
  jacacal: 'jacacal',
  jacacus: 'jacaçus',
  jacaiol: 'jacaiol',
  jacaios: 'jacaiós',
  jacamar: 'jacamar',
  jacamas: 'jacamas',
  jacamim: 'jacamim',
  jacanas: 'jaçanãs',
  jacanha: 'jaçanha',
  jacapas: 'jacapás',
  jacapau: 'jacapau',
  jacapes: 'jaçapés',
  jacapus: 'jacapus',
  jacaras: 'jacarás',
  jacarei: 'jacareí',
  jacares: 'jacarés',
  jacareu: 'jacaréu',
  jacaria: 'jacariá',
  jacatas: 'jacatás',
  jacauna: 'jacaúna',
  jacente: 'jacente',
  jaciaba: 'jaciaba',
  jacicas: 'jacicás',
  jacicos: 'jacicôs',
  jacinas: 'jacinas',
  jacinto: 'jacinto',
  jacobas: 'jacobas',
  jacobea: 'jacóbea',
  jacobeu: 'jacobeu',
  jacobos: 'jacobos',
  jacomas: 'jácomas',
  jactada: 'jactada',
  jactado: 'jactado',
  jactais: 'jactais',
  jactamo: 'jactamo',
  jactara: 'jactara',
  jactava: 'jactava',
  jacteis: 'jacteis',
  jactemo: 'jactemo',
  jactita: 'jactitá',
  jactite: 'jactite',
  jactito: 'jactito',
  jactura: 'jactura',
  jacuacu: 'jacuaçu',
  jacuana: 'jacuana',
  jacuaru: 'jacuaru',
  jacubas: 'jacubas',
  jacuipe: 'jacuipê',
  jaculai: 'jaculai',
  jaculam: 'jaculam',
  jacular: 'jacular',
  jaculas: 'jaculas',
  jaculei: 'jaculei',
  jaculem: 'jaculem',
  jacules: 'jacules',
  jaculos: 'jáculos',
  jaculou: 'jaculou',
  jacumas: 'jacumas',
  jacunas: 'jacunas',
  jacunda: 'jacundá',
  jacunde: 'jacundê',
  jacunes: 'jacunés',
  jacupoi: 'jacupói',
  jacurus: 'jacurus',
  jacutas: 'jacutas',
  jacutos: 'jacutos',
  jacuvas: 'jacuvas',
  jacuzis: 'jacúzis',
  jacuzzi: 'jacuzzi',
  jadeira: 'jadeira',
  jadeita: 'jadeíta',
  jadeite: 'jadeíte',
  jadeito: 'jadeíto',
  jaderas: 'jaderas',
  jadonas: 'jadonas',
  jadonos: 'jadonos',
  jaezada: 'jaezada',
  jaezado: 'jaezado',
  jaezais: 'jaezais',
  jaezamo: 'jaezamo',
  jaezara: 'jaezará',
  jaezava: 'jaezava',
  jaezeis: 'jaezeis',
  jaezemo: 'jaezemo',
  jafense: 'jafense',
  jagadas: 'jagadas',
  jagados: 'jagados',
  jaganca: 'jagança',
  jaganco: 'jaganço',
  jagaque: 'jagaque',
  jagaras: 'jágaras',
  jagatos: 'jagatos',
  jagazes: 'jagazes',
  jagodes: 'jagodes',
  jagoita: 'jagoíta',
  jagomas: 'jagomas',
  jagonca: 'jagonça',
  jagrada: 'jagrada',
  jaguais: 'jaguaís',
  jaguane: 'jaguané',
  jaguape: 'jaguapé',
  jaguara: 'jaguara',
  jaguare: 'jaguaré',
  jagubes: 'jagubes',
  jagudis: 'jagudis',
  jagunas: 'jagunãs',
  jagunco: 'jagunço',
  jagunda: 'jagunda',
  jaibara: 'jaibara',
  jaibros: 'jaibros',
  jairita: 'jairita',
  jalapao: 'jalapão',
  jalapas: 'jalapas',
  jaldeta: 'jaldeta',
  jaldete: 'jaldete',
  jalecas: 'jalecas',
  jalecos: 'jalecos',
  jaleias: 'jaleias',
  jalense: 'jalense',
  jaleque: 'jaleque',
  jalisos: 'jalisos',
  jalofos: 'jalofos',
  jalusia: 'jalusia',
  jalutos: 'jalutos',
  jamacai: 'jamacaí',
  jamadar: 'jamadar',
  jamaias: 'jamaias',
  jamanta: 'jamanta',
  jamaris: 'jamaris',
  jamarus: 'jamarus',
  jamaxim: 'jamaxim',
  jamaxis: 'jamaxis',
  jambale: 'jambale',
  jambelo: 'jambelo',
  jambete: 'jambete',
  jambica: 'jâmbica',
  jambico: 'jâmbico',
  jambire: 'jambire',
  jamboas: 'jamboas',
  jamboes: 'jambões',
  jambois: 'jambóis',
  jambona: 'jambona',
  jambore: 'jamboré',
  jambosa: 'jambosa',
  jambuis: 'jambuis',
  jamburi: 'jamburi',
  jamedar: 'jamedar',
  jamegao: 'jamegão',
  jamelao: 'jamelão',
  jamesia: 'jamésia',
  jamijao: 'jamijão',
  jampais: 'jampais',
  jampeai: 'jampeai',
  jampear: 'jampear',
  jampeei: 'jampeei',
  jampeia: 'jampeia',
  jampeie: 'jampeie',
  jampeio: 'jampeio',
  jampeou: 'jampeou',
  jamunda: 'jamunda',
  jamundo: 'jamundo',
  janacas: 'janacas',
  janadao: 'janadão',
  janadas: 'janadas',
  janador: 'janador',
  janados: 'janados',
  janaina: 'janaína',
  janamba: 'janambá',
  janamos: 'janamos',
  janando: 'janando',
  janante: 'janante',
  janaram: 'janaram',
  janarao: 'janarão',
  janaras: 'janaras',
  janarei: 'janarei',
  janarem: 'janarem',
  janares: 'janares',
  janaria: 'janaria',
  janaris: 'janaris',
  janarmo: 'janarmo',
  janasse: 'janasse',
  janaste: 'janaste',
  janauba: 'janaúba',
  janauis: 'janauís',
  janavam: 'janavam',
  janavas: 'janavas',
  jancros: 'jancros',
  jandaia: 'jandaia',
  jandias: 'jandiás',
  jandona: 'jandona',
  janduim: 'janduim',
  janduis: 'janduís',
  janecas: 'janecas',
  janeira: 'janeira',
  janeiro: 'janeiro',
  janeiru: 'janeiru',
  janelai: 'janelai',
  janelam: 'janelam',
  janelao: 'janelão',
  janelar: 'janelar',
  janelas: 'janelas',
  janelei: 'janelei',
  janelem: 'janelem',
  janeles: 'janeles',
  janelos: 'janelos',
  janelou: 'janelou',
  janemos: 'janemos',
  jangada: 'jangada',
  jangado: 'jangado',
  jangais: 'jângais',
  jangala: 'jângala',
  jangana: 'jangana',
  jangano: 'jangano',
  jangara: 'jangara',
  jangava: 'jangava',
  jangoes: 'jangões',
  jangoma: 'jangoma',
  jangoto: 'jangoto',
  janguei: 'janguei',
  janguem: 'janguem',
  jangues: 'jangués',
  janimbu: 'janimbu',
  janiras: 'janiras',
  janismo: 'janismo',
  janista: 'janista',
  janitas: 'janitás',
  janitor: 'janitor',
  janotai: 'janotai',
  janotam: 'janotam',
  janotar: 'janotar',
  janotas: 'janotas',
  janotea: 'janoteá',
  janotei: 'janotei',
  janotem: 'janotem',
  janotes: 'janotes',
  janotou: 'janotou',
  jantada: 'jantada',
  jantado: 'jantado',
  jantais: 'jantais',
  jantamo: 'jantamo',
  jantara: 'jantara',
  jantava: 'jantava',
  janteis: 'janteis',
  jantela: 'jantela',
  jantemo: 'jantemo',
  jantina: 'jantina',
  januais: 'januais',
  janubia: 'janúbia',
  janufos: 'janufos',
  janumas: 'janumás',
  janunda: 'janundá',
  janusia: 'janúsia',
  japanas: 'japanas',
  japanim: 'japanim',
  japanis: 'japanis',
  japaras: 'japaras',
  japiaco: 'japiaçó',
  japiacu: 'japiaçu',
  japicai: 'japicaí',
  japical: 'japical',
  japides: 'jápides',
  japiges: 'jápiges',
  japigia: 'japígia',
  japigio: 'japígio',
  japiins: 'japiins',
  japiras: 'japiras',
  japonas: 'japonas',
  japones: 'japonês',
  japonia: 'japônia',
  japonim: 'japonim',
  japuacu: 'japuaçu',
  japubas: 'japubás',
  japucas: 'japuçás',
  japuira: 'japuíra',
  japuras: 'japurás',
  jaqueta: 'jaqueta',
  jaquete: 'jaquete',
  jaquira: 'jaquira',
  jaracus: 'jaraçus',
  jaragua: 'jaraguá',
  jaraiba: 'jaraíba',
  jaranas: 'jaranas',
  jarapes: 'jarapés',
  jaraqui: 'jaraqui',
  jarauas: 'jarauás',
  jaravas: 'jaravas',
  jarazal: 'jarazal',
  jardada: 'jardada',
  jardado: 'jardado',
  jardais: 'jardais',
  jardamo: 'jardamo',
  jardara: 'jardará',
  jardava: 'jardava',
  jardeis: 'jardeis',
  jardemo: 'jardemo',
  jardias: 'járdias',
  jardina: 'jardina',
  jardine: 'jardine',
  jardino: 'jardino',
  jardins: 'jardins',
  jareres: 'jarerés',
  jareuas: 'jareuás',
  jargoes: 'jargões',
  jarimba: 'jarimba',
  jarinas: 'jarinas',
  jarivas: 'jarivás',
  jarlita: 'jarlita',
  jarobas: 'jarobas',
  jaronda: 'jaronda',
  jarrafa: 'jarrafa',
  jarreai: 'jarreai',
  jarrear: 'jarrear',
  jarreei: 'jarreei',
  jarreia: 'jarreia',
  jarreie: 'jarreie',
  jarreio: 'jarreio',
  jarreou: 'jarreou',
  jarreta: 'jarreta',
  jarrete: 'jarrete',
  jarreto: 'jarreto',
  jarroes: 'jarrões',
  jarruva: 'jarruva',
  jarumas: 'jarumas',
  jarunda: 'jarunda',
  jarunde: 'jarunde',
  jarundo: 'jarundo',
  jarurus: 'jarurus',
  jaruvas: 'jaruvás',
  jasione: 'jasione',
  jasmino: 'jasmino',
  jasmins: 'jasmins',
  jasmona: 'jasmona',
  jasonia: 'jasônia',
  jaspeai: 'jaspeai',
  jaspear: 'jaspear',
  jaspeas: 'jáspeas',
  jaspeei: 'jaspeei',
  jaspeia: 'jaspeia',
  jaspeie: 'jaspeie',
  jaspeio: 'jaspeio',
  jaspeos: 'jáspeos',
  jaspeou: 'jaspeou',
  jaspica: 'jáspica',
  jaspico: 'jáspico',
  jassais: 'jassaís',
  jassias: 'jássias',
  jassida: 'jássida',
  jassina: 'jassina',
  jassios: 'jássios',
  jatacao: 'jatação',
  jatacas: 'játacas',
  jatadao: 'jatadão',
  jatadas: 'jatadas',
  jatador: 'jatador',
  jatados: 'jatados',
  jataiba: 'jataíba',
  jatamos: 'jatamos',
  jatando: 'jatando',
  jatante: 'jatante',
  jataram: 'jataram',
  jatarao: 'jatarão',
  jataras: 'jatarás',
  jatarei: 'jatarei',
  jatarem: 'jatarem',
  jatares: 'jatares',
  jataria: 'jataria',
  jatarmo: 'jatarmo',
  jatasse: 'jatasse',
  jataste: 'jataste',
  jatauba: 'jataúba',
  jatauva: 'jataúva',
  jatavam: 'jatavam',
  jatavas: 'jatavas',
  jateada: 'jateada',
  jateado: 'jateado',
  jateais: 'jateais',
  jateara: 'jateará',
  jateava: 'jateava',
  jatecas: 'jatecas',
  jateeis: 'jateeis',
  jateiam: 'jateiam',
  jateias: 'jateias',
  jateiem: 'jateiem',
  jateies: 'jateies',
  jatemar: 'jatemar',
  jatemos: 'jatemos',
  jateuns: 'jateuns',
  jatibas: 'jatibás',
  jaticas: 'jaticás',
  jaticom: 'jaticom',
  jatinho: 'jatinho',
  jatitai: 'jatitai',
  jatitam: 'jatitam',
  jatitar: 'jatitar',
  jatitas: 'jatitas',
  jatitei: 'jatitei',
  jatitem: 'jatitem',
  jatites: 'jatites',
  jatitou: 'jatitou',
  jatiuns: 'jatiuns',
  jatobai: 'jatobaí',
  jatobas: 'jatobás',
  jatoras: 'jatoras',
  jatores: 'jatores',
  jatrofa: 'jatrofa',
  jatubas: 'jatubás',
  jaturas: 'jaturas',
  jauaris: 'jauaris',
  jauense: 'jauense',
  jaumeas: 'jaumeas',
  jaunava: 'jaunava',
  jaunavo: 'jaunavo',
  jaundea: 'jaúndea',
  jaunita: 'jaunita',
  jaupati: 'jaupati',
  jaupoca: 'jaupoca',
  javaito: 'javaíto',
  javalis: 'javalis',
  javalus: 'javalus',
  javanco: 'javanco',
  javanes: 'javanês',
  javarda: 'javarda',
  javardo: 'javardo',
  javaris: 'javaris',
  javarro: 'javarro',
  javense: 'javense',
  javevos: 'javevós',
  javiras: 'javiras',
  javismo: 'javismo',
  javista: 'javista',
  javites: 'javites',
  javrada: 'javrada',
  javrado: 'javrado',
  javrais: 'javrais',
  javramo: 'javramo',
  javrara: 'javrará',
  javrava: 'javrava',
  javreis: 'javreis',
  javremo: 'javremo',
  jazamos: 'jazamos',
  jazedas: 'jazedas',
  jazedor: 'jazedor',
  jazemos: 'jazemos',
  jazendo: 'jazendo',
  jazente: 'jazente',
  jazeram: 'jazeram',
  jazerao: 'jazerão',
  jazeras: 'jazeras',
  jazerei: 'jazerei',
  jazerem: 'jazerem',
  jazeres: 'jazeres',
  jazeria: 'jazeria',
  jazermo: 'jazermo',
  jazesse: 'jazesse',
  jazeste: 'jazeste',
  jazidao: 'jazidão',
  jazidas: 'jazidas',
  jazidos: 'jazidos',
  jazieis: 'jazíeis',
  jazigas: 'jázigas',
  jaziges: 'jáziges',
  jazigos: 'jazigos',
  jebaras: 'jebaras',
  jebarus: 'jebarus',
  jebimba: 'jebimba',
  jebuseu: 'jebuseu',
  jecoral: 'jecoral',
  jecuiba: 'jecuíba',
  jegadas: 'jegadas',
  jegamas: 'jegamas',
  jegodes: 'jegodes',
  jegrada: 'jegrada',
  jegrama: 'jegrama',
  jeguede: 'jeguedê',
  jeguiri: 'jeguiri',
  jeiroes: 'jeirões',
  jeitada: 'jeitada',
  jeitado: 'jeitado',
  jeitais: 'jeitais',
  jeitamo: 'jeitamo',
  jeitara: 'jeitara',
  jeitava: 'jeitava',
  jeiteis: 'jeiteis',
  jeitemo: 'jeitemo',
  jeitoes: 'jeitões',
  jeitosa: 'jeitosa',
  jeitoso: 'jeitoso',
  jejuada: 'jejuada',
  jejuado: 'jejuado',
  jejuais: 'jejuais',
  jejuamo: 'jejuamo',
  jejuara: 'jejuara',
  jejuava: 'jejuava',
  jejueis: 'jejueis',
  jejuemo: 'jejuemo',
  jejuira: 'jejuíra',
  jejunal: 'jejunal',
  jejunis: 'jejunis',
  jejunos: 'jejunos',
  jelabas: 'jelabas',
  jelabia: 'jelabia',
  jelalas: 'jelalas',
  jelapas: 'jelapas',
  jemedar: 'jemedar',
  jendaia: 'jendaia',
  jeneuna: 'jeneúna',
  jenipas: 'jenipás',
  jennita: 'jennita',
  jenolim: 'jenolim',
  jeovica: 'jeóvica',
  jeovico: 'jeóvico',
  jequede: 'jequedê',
  jequeri: 'jequeri',
  jequias: 'jequiás',
  jequice: 'jequice',
  jequiri: 'jequiri',
  jequita: 'jequitá',
  jequito: 'jequito',
  jeramus: 'jeramus',
  jeraqui: 'jeraqui',
  jerarca: 'jerarca',
  jerebas: 'jerebas',
  jeremai: 'jeremaí',
  jeremas: 'jeremas',
  jeremia: 'jeremia',
  jeremie: 'jeremie',
  jeremio: 'jeremio',
  jereres: 'jererés',
  jerevas: 'jerevas',
  jerezao: 'jerezão',
  jerezas: 'jerezãs',
  jeribas: 'jeribás',
  jerical: 'jerical',
  jericos: 'jericos',
  jericua: 'jericuá',
  jerimum: 'jerimum',
  jerimus: 'jerimus',
  jerivas: 'jerivás',
  jerizas: 'jerizas',
  jeromas: 'jeromas',
  jeromos: 'jeromós',
  jeropia: 'jeropia',
  jerrecu: 'jerrecu',
  jerseis: 'jérseis',
  jerumai: 'jerumaí',
  jerumba: 'jerumba',
  jerumus: 'jerumus',
  jerutis: 'jerutis',
  jeruvas: 'jeruvas',
  jervina: 'jervina',
  jesuata: 'jesuata',
  jesuato: 'jesuato',
  jesuina: 'jesuína',
  jesuita: 'jesuíta',
  jetaiba: 'jetaíba',
  jetapes: 'jetapes',
  jetense: 'jetense',
  jeticas: 'jeticas',
  jeticos: 'jeticos',
  jetinas: 'jetinas',
  jetinos: 'jetinos',
  jetivis: 'jetivis',
  jetucas: 'jetucas',
  jetucus: 'jetucus',
  jevivis: 'jevivis',
  jevuras: 'jevuras',
  jibarra: 'jibarra',
  jibelim: 'jibelim',
  jiboiai: 'jiboiai',
  jiboiam: 'jiboiam',
  jiboiar: 'jiboiar',
  jiboias: 'jiboias',
  jiboiei: 'jiboiei',
  jiboiem: 'jiboiem',
  jiboies: 'jiboies',
  jiboiou: 'jiboiou',
  jibonas: 'jibonãs',
  jibongo: 'jibongo',
  jibungo: 'jibungo',
  jicaras: 'jiçaras',
  jiconge: 'jiçonge',
  jicungo: 'jicungo',
  jifingo: 'jifingo',
  jifumes: 'jifumes',
  jigogas: 'jigogas',
  jigotes: 'jigotes',
  jiguefo: 'jiguefo',
  jijocas: 'jijocas',
  jilabas: 'jilabas',
  jilhevo: 'jilhevo',
  jiloais: 'jiloais',
  jilozal: 'jilozal',
  jimbele: 'jimbelê',
  jimboas: 'jimboas',
  jimbolo: 'jimbolo',
  jimbrai: 'jimbrai',
  jimbram: 'jimbram',
  jimbrar: 'jimbrar',
  jimbras: 'jimbras',
  jimbrei: 'jimbrei',
  jimbrem: 'jimbrem',
  jimbres: 'jimbres',
  jimbrou: 'jimbrou',
  jimbuda: 'jimbuda',
  jimbudo: 'jimbudo',
  jimbuia: 'jimbuia',
  jimbulo: 'jimbulo',
  jinaris: 'jinaris',
  jingilo: 'jingilo',
  jingimo: 'jingimo',
  jingins: 'jingins',
  jingles: 'jingles',
  jingoas: 'jingoas',
  jingois: 'jingóis',
  jingola: 'jingola',
  jingote: 'jingote',
  jingoto: 'jingoto',
  jinguba: 'jinguba',
  jinjilo: 'jinjilo',
  jinjins: 'jinjins',
  jinsens: 'jinséns',
  jinsoes: 'jinsões',
  jinsona: 'jinsona',
  jinzeus: 'jinzeus',
  jipepes: 'jipepes',
  jipioca: 'jipioca',
  jipooca: 'jipooca',
  jipouba: 'jipoúba',
  jipubas: 'jipubas',
  jiquias: 'jiquiás',
  jiquiri: 'jiquiri',
  jiquiro: 'jiquiró',
  jirabas: 'jirabas',
  jiracal: 'jiraçal',
  jirambo: 'jirambo',
  jiraues: 'jiraués',
  jirbaco: 'jirbaço',
  jiribas: 'jiribás',
  jirimim: 'jirimim',
  jirimum: 'jirimum',
  jiritas: 'jiritas',
  jiritis: 'jiritis',
  jirivas: 'jirivás',
  jirixas: 'jirixás',
  jiropes: 'jiropes',
  jirotes: 'jirotes',
  jirubas: 'jirubás',
  jiruvas: 'jiruvás',
  jisabus: 'jisabus',
  jitambe: 'jitambe',
  jiticas: 'jiticas',
  jitinga: 'jitinga',
  jitonga: 'jitonga',
  jitreus: 'jitreus',
  jivaras: 'jivaras',
  jivaros: 'jivaros',
  jixingo: 'jixingo',
  joanete: 'joanete',
  joanica: 'joanica',
  joanico: 'joanico',
  joanina: 'joanina',
  joanino: 'joanino',
  joanita: 'joanita',
  joaozao: 'joãozão',
  jobadao: 'jobadão',
  jobadas: 'jobadas',
  jobador: 'jobador',
  jobados: 'jobados',
  jobamos: 'jobamos',
  jobando: 'jobando',
  jobante: 'jobante',
  jobaram: 'jobaram',
  jobarao: 'jobarão',
  jobaras: 'jobarás',
  jobarei: 'jobarei',
  jobarem: 'jobarem',
  jobares: 'jobares',
  jobaria: 'jobaria',
  jobarmo: 'jobarmo',
  jobasse: 'jobasse',
  jobaste: 'jobaste',
  jobavam: 'jobavam',
  jobavas: 'jobavas',
  jobemos: 'jobemos',
  jobinia: 'jobínia',
  jocosas: 'jocosas',
  jocosos: 'jocosos',
  jocotos: 'jocotós',
  jocromo: 'jocromo',
  jocunda: 'jocunda',
  jocundo: 'jocundo',
  jodinas: 'jodinas',
  joeirai: 'joeirai',
  joeiram: 'joeiram',
  joeirar: 'joeirar',
  joeiras: 'joeiras',
  joeirei: 'joeirei',
  joeirem: 'joeirem',
  joeires: 'joeires',
  joeirou: 'joeirou',
  joelhai: 'joelhai',
  joelham: 'joelham',
  joelhar: 'joelhar',
  joelhas: 'joelhas',
  joelhei: 'joelhei',
  joelhem: 'joelhem',
  joelhes: 'joelhes',
  joelhos: 'joelhos',
  joelhou: 'joelhou',
  jogadao: 'jogadão',
  jogadas: 'jogadas',
  jogador: 'jogador',
  jogados: 'jogados',
  jogalha: 'jogalhá',
  jogalhe: 'jogalhe',
  jogalho: 'jogalho',
  jogamos: 'jogamos',
  jogando: 'jogando',
  jogante: 'jogante',
  jogaram: 'jogaram',
  jogarao: 'jogarão',
  jogaras: 'jogaras',
  jogarei: 'jogarei',
  jogarem: 'jogarem',
  jogares: 'jogares',
  jogaria: 'jogaria',
  jogarmo: 'jogarmo',
  jogasse: 'jogasse',
  jogaste: 'jogaste',
  jogatai: 'jogatai',
  jogatam: 'jogatam',
  jogatar: 'jogatar',
  jogatas: 'jogatas',
  jogatei: 'jogatei',
  jogatem: 'jogatem',
  jogates: 'jogates',
  jogatou: 'jogatou',
  jogavam: 'jogavam',
  jogavas: 'jogavas',
  jogavel: 'jogável',
  jogging: 'jogging',
  jograis: 'jograis',
  jograla: 'jogralá',
  jograle: 'jograle',
  jogralo: 'jogralo',
  jogrete: 'jogrete',
  jogroes: 'jogrões',
  jogueis: 'jogueis',
  joguemo: 'joguemo',
  jogueta: 'jogueta',
  joguete: 'joguete',
  jogueto: 'jogueto',
  joiadas: 'joiadas',
  joiados: 'joiados',
  joiense: 'joiense',
  joietas: 'joietas',
  jojobas: 'jojobas',
  jojogas: 'jojogas',
  joladao: 'joladão',
  joladas: 'joladas',
  jolador: 'jolador',
  jolados: 'jolados',
  jolamos: 'jolamos',
  jolando: 'jolando',
  jolante: 'jolante',
  jolaram: 'jolaram',
  jolarao: 'jolarão',
  jolaras: 'jolarás',
  jolarei: 'jolarei',
  jolarem: 'jolarem',
  jolares: 'jolares',
  jolaria: 'jolaria',
  jolarmo: 'jolarmo',
  jolasse: 'jolasse',
  jolaste: 'jolaste',
  jolavam: 'jolavam',
  jolavas: 'jolavas',
  joldras: 'joldras',
  jolemos: 'jolemos',
  jolizes: 'jolizes',
  jombele: 'jombele',
  jomirim: 'jomirim',
  joncaro: 'joncaro',
  jongada: 'jongada',
  jongado: 'jongado',
  jongais: 'jongais',
  jongara: 'jongara',
  jongava: 'jongava',
  jonguei: 'jonguei',
  jonguem: 'jonguem',
  jongues: 'jongues',
  joniaca: 'joníaca',
  joniaco: 'joníaco',
  jonicas: 'jônicas',
  jonicos: 'jônicos',
  jonismo: 'jonismo',
  jonista: 'jonista',
  jonjada: 'jonjada',
  jonjado: 'jonjado',
  jonjais: 'jonjais',
  jonjamo: 'jonjamo',
  jonjara: 'jonjara',
  jonjava: 'jonjava',
  jonjeis: 'jonjeis',
  jonjemo: 'jonjemo',
  joosias: 'joósias',
  jopicas: 'jópicas',
  jopicos: 'jópicos',
  joqueis: 'jóqueis',
  joqueta: 'joqueta',
  jordana: 'jordana',
  jordano: 'jordano',
  jorgada: 'jorgada',
  jorgado: 'jorgado',
  jorgoes: 'jorgões',
  jornada: 'jornada',
  jornais: 'jornais',
  jorneas: 'jórneas',
  jorneia: 'jorneia',
  joropas: 'joropas',
  jorrada: 'jorrada',
  jorrado: 'jorrado',
  jorrais: 'jorrais',
  jorramo: 'jorramo',
  jorrara: 'jorrara',
  jorrava: 'jorrava',
  jorreis: 'jorreis',
  jorremo: 'jorremo',
  jorroes: 'jorrões',
  jorunas: 'jorunas',
  joseita: 'joseíta',
  joseito: 'joseíto',
  joticas: 'jóticas',
  joticos: 'jóticos',
  jotinha: 'jotinha',
  jotinho: 'jotinho',
  jovevas: 'jovevas',
  joviais: 'joviais',
  joviana: 'joviana',
  joviano: 'joviano',
  juaense: 'juaense',
  juangue: 'juangue',
  juanita: 'juanita',
  juapoca: 'juapoca',
  juarana: 'juarana',
  juaraua: 'juarauá',
  jubadas: 'jubadas',
  jubados: 'jubados',
  jubarte: 'jubarte',
  jubatao: 'jubatão',
  jubatas: 'jubatas',
  jubatis: 'jubatis',
  jubebas: 'jubebas',
  jubeias: 'jubeias',
  juberis: 'juberis',
  jubilai: 'jubilai',
  jubilam: 'jubilam',
  jubilar: 'jubilar',
  jubilas: 'jubilas',
  jubilei: 'jubilei',
  jubilem: 'jubilem',
  jubiles: 'jubiles',
  jubileu: 'jubileu',
  jubilos: 'júbilos',
  jubilou: 'jubilou',
  jubosas: 'jubosas',
  jubosos: 'jubosos',
  juburus: 'juburus',
  jucanas: 'juçanas',
  jucapes: 'juçapés',
  jucaral: 'juçaral',
  jucaras: 'juçaras',
  juciris: 'juciris',
  jucunas: 'jucunas',
  jucunda: 'jucunda',
  jucundo: 'jucundo',
  jucundu: 'jucundu',
  jucuris: 'jucuris',
  jucurus: 'jucurus',
  judaica: 'judaica',
  judaico: 'judaico',
  judaiza: 'judaíza',
  judaize: 'judaíze',
  judaizo: 'judaízo',
  judaria: 'judaria',
  juddita: 'juddita',
  judeana: 'judeana',
  judeano: 'judeano',
  judeias: 'judeias',
  judenga: 'judenga',
  judengo: 'judengo',
  judiada: 'judiada',
  judiado: 'judiado',
  judiaga: 'judiaga',
  judiais: 'judiais',
  judiamo: 'judiamo',
  judiara: 'judiara',
  judiava: 'judiava',
  judicai: 'judicai',
  judicam: 'judicam',
  judicar: 'judicar',
  judicas: 'judicas',
  judicia: 'judicia',
  judicie: 'judicie',
  judicio: 'judicio',
  judicou: 'judicou',
  judieis: 'judieis',
  judiemo: 'judiemo',
  judique: 'judique',
  judites: 'judites',
  juditia: 'juditia',
  judocas: 'judocas',
  juerana: 'juerana',
  jugadai: 'jugadai',
  jugadam: 'jugadam',
  jugadar: 'jugadar',
  jugadas: 'jugadas',
  jugadei: 'jugadei',
  jugadem: 'jugadem',
  jugades: 'jugades',
  jugador: 'jugador',
  jugados: 'jugados',
  jugadou: 'jugadou',
  jugamos: 'jugamos',
  jugando: 'jugando',
  jugaram: 'jugaram',
  jugarao: 'jugarão',
  jugaras: 'jugaras',
  jugarei: 'jugarei',
  jugarem: 'jugarem',
  jugares: 'jugares',
  jugaria: 'jugaria',
  jugario: 'jugário',
  jugasse: 'jugasse',
  jugaste: 'jugaste',
  jugatas: 'jugatas',
  jugavam: 'jugavam',
  jugavas: 'jugavas',
  juglona: 'juglona',
  jugosas: 'jugosas',
  jugosos: 'jugosos',
  jugueis: 'jugueis',
  jugulai: 'jugulai',
  jugulam: 'jugulam',
  jugular: 'jugular',
  jugulas: 'jugulas',
  jugulei: 'jugulei',
  jugulem: 'jugulem',
  jugules: 'jugules',
  jugulou: 'jugulou',
  juizada: 'juizada',
  juizado: 'juizado',
  juizais: 'juizais',
  juizara: 'juizará',
  juizava: 'juizava',
  juizeis: 'juizeis',
  jujitsu: 'jujitsu',
  jujubas: 'jujubas',
  jujutsu: 'jujútsu',
  jukebox: 'jukebox',
  julacea: 'julácea',
  julaceo: 'juláceo',
  julatas: 'julatas',
  julepes: 'julepes',
  julepos: 'julepos',
  julgada: 'julgada',
  julgado: 'julgado',
  julgais: 'julgais',
  julgamo: 'julgamo',
  julgara: 'julgara',
  julgava: 'julgava',
  julguei: 'julguei',
  julguem: 'julguem',
  julgues: 'julgues',
  juliana: 'juliana',
  juliano: 'juliano',
  julidea: 'julídea',
  julideo: 'julídeo',
  julieta: 'julieta',
  julioes: 'juliões',
  julodis: 'julódis',
  julpais: 'julpais',
  jumadas: 'jumadas',
  jumanas: 'jumanas',
  jumaras: 'jumarás',
  jumardo: 'jumardo',
  jumbaem: 'jumbaem',
  jumbaia: 'jumbaía',
  jumbaio: 'jumbaio',
  jumbair: 'jumbair',
  jumbais: 'jumbais',
  jumbaiu: 'jumbaiu',
  jumbeba: 'jumbeba',
  jumelos: 'jumelos',
  jumense: 'jumense',
  jumenta: 'jumenta',
  jumento: 'jumento',
  jumping: 'jumping',
  jumprim: 'jumprim',
  juncabo: 'juncabo',
  juncada: 'juncada',
  juncado: 'juncado',
  juncago: 'juncago',
  juncais: 'juncais',
  juncale: 'juncale',
  juncamo: 'juncamo',
  juncara: 'juncara',
  juncava: 'juncava',
  juncela: 'juncela',
  juncelo: 'juncelo',
  juncoes: 'junções',
  juncona: 'junçona',
  juncosa: 'juncosa',
  juncoso: 'juncoso',
  jundais: 'jundaís',
  jundiai: 'jundiaí',
  jundias: 'jundiás',
  junedim: 'junedim',
  junelia: 'junélia',
  junense: 'junense',
  junetia: 'junetia',
  jungiam: 'jungiam',
  jungias: 'jungias',
  jungida: 'jungida',
  jungido: 'jungido',
  jungira: 'jungirá',
  junguer: 'junguer',
  jungues: 'jungues',
  junguir: 'junguir',
  junhaes: 'junhães',
  junhais: 'junhais',
  juninas: 'juninas',
  juninos: 'juninos',
  junjais: 'junjais',
  junoita: 'junoíta',
  junonal: 'junonal',
  junonia: 'junônia',
  junonio: 'junônio',
  junquei: 'junquei',
  junquem: 'junquem',
  junques: 'junques',
  juntada: 'juntada',
  juntado: 'juntado',
  juntais: 'juntais',
  juntamo: 'juntamo',
  juntara: 'juntara',
  juntava: 'juntava',
  junteia: 'junteia',
  junteis: 'junteis',
  juntemo: 'juntemo',
  juntiva: 'juntiva',
  juntivo: 'juntivo',
  juntura: 'juntura',
  juparas: 'juparás',
  jupatis: 'jupatis',
  jupebas: 'jupebas',
  jupicai: 'jupicaí',
  jupiede: 'jupiede',
  jupinda: 'jupindá',
  jupiter: 'júpiter',
  jupuira: 'jupuíra',
  jupuras: 'jupurás',
  jupuuba: 'jupuuba',
  juqueri: 'juqueri',
  juquias: 'juquiás',
  juquiri: 'juquiri',
  juradao: 'juradão',
  juradas: 'juradas',
  juradia: 'juradia',
  jurador: 'jurador',
  jurados: 'jurados',
  juraica: 'juraica',
  juraico: 'juraico',
  juramos: 'juramos',
  juranas: 'juranas',
  juranda: 'juranda',
  jurando: 'jurando',
  jurante: 'jurante',
  juraram: 'juraram',
  jurarao: 'jurarão',
  juraras: 'jurarás',
  jurarei: 'jurarei',
  jurarem: 'jurarem',
  jurares: 'jurares',
  juraria: 'juraria',
  jurarmo: 'jurarmo',
  jurasse: 'jurasse',
  juraste: 'juraste',
  juravam: 'juravam',
  juravas: 'juravas',
  jurebas: 'jurebas',
  juremai: 'juremai',
  juremal: 'juremal',
  juremam: 'juremam',
  juremar: 'juremar',
  juremas: 'juremas',
  juremei: 'juremei',
  juremem: 'juremem',
  juremes: 'juremes',
  juremos: 'juremos',
  juremou: 'juremou',
  jurgada: 'jurgada',
  jurgado: 'jurgado',
  jurgais: 'jurgais',
  jurgara: 'jurgará',
  jurgava: 'jurgava',
  jurguei: 'jurguei',
  jurguem: 'jurguem',
  jurgues: 'jurgues',
  jurinea: 'jurínea',
  jurineo: 'juríneo',
  jurismo: 'jurismo',
  jurista: 'jurista',
  juritis: 'juritis',
  juruacu: 'juruaçu',
  jurucua: 'jurucuá',
  jurueba: 'jurueba',
  jurueca: 'jurueca',
  jurumim: 'jurumim',
  jurumis: 'jurumis',
  jurumum: 'jurumum',
  jurumus: 'jurumus',
  jurunas: 'jurunas',
  jurunus: 'jurunus',
  juruqua: 'juruquá',
  jururas: 'jururás',
  jururus: 'jururus',
  jurutai: 'jurutaí',
  jurutau: 'jurutau',
  jurutes: 'jurutés',
  jurutis: 'jurutis',
  juruuna: 'juruuna',
  juruvas: 'juruvas',
  juruvia: 'juruviá',
  jusanas: 'jusanas',
  jusanos: 'jusanos',
  jusante: 'jusante',
  jusitas: 'jusitas',
  jussiva: 'jussiva',
  jussivo: 'jussivo',
  justada: 'justada',
  justado: 'justado',
  justais: 'justais',
  justamo: 'justamo',
  justapo: 'justapô',
  justara: 'justará',
  justava: 'justava',
  justeis: 'justeis',
  justemo: 'justemo',
  justeza: 'justeza',
  justica: 'justiça',
  justice: 'justice',
  justico: 'justiço',
  justita: 'justita',
  justura: 'justura',
  jutauba: 'jutaúba',
  juturga: 'juturga',
  juturgo: 'juturgo',
  juvenal: 'juvenal',
  juvenca: 'juvenca',
  juvenco: 'juvenco',
  juvenes: 'juvenês',
  juvenil: 'juvenil',
  juvenis: 'juvenis',
  juventa: 'juventa',
  juvetis: 'juvetis',
  juvevas: 'juvevas',
  juveves: 'juvevês',
  juviras: 'juviras',
  kabuqui: 'kabuqui',
  karaoke: 'karaokê',
  karates: 'karatês',
  karting: 'karting',
  kazinho: 'kazinho',
  kerning: 'kerning',
  kernita: 'kernita',
  ketchup: 'ketchup',
  khoisan: 'khoisan',
  kilobit: 'kilobit',
  kingpin: 'kingpin',
  kirundi: 'kirundi',
  kosovar: 'kosovar',
  kunzita: 'kunzita',
  kwanzas: 'kwanzas',
  labacal: 'labaçal',
  labacas: 'labaças',
  labaces: 'labacês',
  labacia: 'labácia',
  labacol: 'labaçol',
  labarda: 'labarda',
  labaros: 'lábaros',
  labatia: 'labátia',
  labdana: 'lábdana',
  labdano: 'lábdano',
  labeata: 'labeata',
  labegos: 'labegos',
  labelai: 'labelai',
  labelam: 'labelam',
  labelar: 'labelar',
  labelas: 'labelas',
  labelei: 'labelei',
  labelem: 'labelem',
  labeles: 'labeles',
  labelos: 'labelos',
  labelou: 'labelou',
  laberca: 'laberca',
  laberco: 'laberco',
  labiada: 'labiada',
  labiado: 'labiado',
  labiais: 'labiais',
  labicas: 'labicas',
  labicos: 'labicos',
  labidos: 'lábidos',
  labielo: 'labielo',
  labiida: 'labíida',
  labiolo: 'labíolo',
  labiosa: 'labiosa',
  labioso: 'labioso',
  labisas: 'labisas',
  labitas: 'labitas',
  labiuns: 'lábiuns',
  lablabe: 'lablabe',
  laborai: 'laborai',
  laboral: 'laboral',
  laboram: 'laboram',
  laborao: 'laborão',
  laborar: 'laborar',
  laboras: 'laboras',
  laborda: 'laborda',
  laborei: 'laborei',
  laborem: 'laborem',
  labores: 'labores',
  laborou: 'laborou',
  labrace: 'lábrace',
  labrais: 'labrais',
  labreai: 'labreai',
  labrear: 'labrear',
  labreei: 'labreei',
  labrega: 'labrega',
  labrego: 'labrego',
  labreia: 'labreia',
  labreie: 'labreie',
  labreio: 'labreio',
  labreou: 'labreou',
  labrete: 'labrete',
  labrida: 'lábrida',
  labrosa: 'labrosa',
  labroso: 'labroso',
  labruge: 'labruge',
  labruns: 'lábruns',
  labulas: 'labulas',
  laburno: 'laburno',
  labutai: 'labutai',
  labutam: 'labutam',
  labutar: 'labutar',
  labutas: 'labutas',
  labutei: 'labutei',
  labutem: 'labutem',
  labutes: 'labutes',
  labutou: 'labutou',
  labuzai: 'labuzai',
  labuzam: 'labuzam',
  labuzar: 'labuzar',
  labuzas: 'labuzas',
  labuzei: 'labuzei',
  labuzem: 'labuzem',
  labuzes: 'labuzes',
  labuzou: 'labuzou',
  lacacao: 'lacação',
  lacacas: 'lacacãs',
  lacacos: 'lacaços',
  lacadao: 'laçadão',
  lacadas: 'laçadas',
  lacador: 'laçador',
  lacados: 'laçados',
  lacagem: 'lacagem',
  lacaiai: 'lacaiai',
  lacaiam: 'lacaiam',
  lacaiar: 'lacaiar',
  lacaias: 'lacaias',
  lacaica: 'lacaica',
  lacaico: 'lacaico',
  lacaiei: 'lacaiei',
  lacaiem: 'lacaiem',
  lacaies: 'lacaies',
  lacaios: 'lacaios',
  lacaiou: 'lacaiou',
  lacalei: 'lacalei',
  lacamos: 'laçamos',
  lacando: 'laçando',
  lacante: 'lacante',
  lacaram: 'laçaram',
  lacarao: 'laçarão',
  lacaras: 'laçarás',
  lacarei: 'laçarei',
  lacarem: 'laçarem',
  lacares: 'laçares',
  lacaria: 'laçaria',
  lacarmo: 'laçarmo',
  lacases: 'lácases',
  lacassa: 'lacassá',
  lacasse: 'laçasse',
  lacaste: 'laçaste',
  lacavam: 'laçavam',
  lacavas: 'laçavas',
  lacazia: 'lacázia',
  laceada: 'laceada',
  laceado: 'laceado',
  laceais: 'laceais',
  laceara: 'laceará',
  laceava: 'laceava',
  lacedos: 'lacedos',
  laceeis: 'laceeis',
  laceiam: 'laceiam',
  laceias: 'laceias',
  laceiem: 'laceiem',
  laceies: 'laceies',
  laceios: 'laceios',
  laceira: 'laceira',
  lacemos: 'lacemos',
  lacense: 'lacense',
  lacerai: 'lacerai',
  laceram: 'laceram',
  lacerar: 'lacerar',
  laceras: 'laceras',
  lacerei: 'lacerei',
  lacerem: 'lacerem',
  laceres: 'laceres',
  lacerna: 'lacerna',
  lacerno: 'lacerno',
  laceros: 'láceros',
  lacerou: 'lacerou',
  lacerta: 'lacerta',
  lacerto: 'lacerto',
  lacetes: 'lacetes',
  laciada: 'laciada',
  laciais: 'laciais',
  lacicas: 'lácicas',
  lacicos: 'lácicos',
  lacifer: 'lácifer',
  lacinas: 'lacinas',
  lacinho: 'lacinho',
  lacinia: 'lacínia',
  lacinio: 'lacínio',
  laclara: 'laclara',
  lacmida: 'lácmida',
  lacneas: 'lácneas',
  lacobio: 'lacóbio',
  laconde: 'lacondé',
  laconia: 'lacônia',
  laconio: 'lacônio',
  lacosas: 'lacosas',
  lacosos: 'lacosos',
  lacrada: 'lacrada',
  lacrado: 'lacrado',
  lacraia: 'lacraia',
  lacrais: 'lacrais',
  lacrama: 'lacrama',
  lacrame: 'lacrame',
  lacramo: 'lacramo',
  lacrana: 'lacrana',
  lacrane: 'lacrane',
  lacrano: 'lacrano',
  lacrara: 'lacrara',
  lacraus: 'lacraus',
  lacrava: 'lacrava',
  lacreai: 'lacreai',
  lacrear: 'lacrear',
  lacreei: 'lacreei',
  lacreia: 'lacreia',
  lacreie: 'lacreie',
  lacreio: 'lacreio',
  lacreis: 'lacreis',
  lacremo: 'lacremo',
  lacreou: 'lacreou',
  lacrima: 'lacrima',
  lacrime: 'lacrime',
  lacrimo: 'lacrimo',
  lacroes: 'lacrões',
  lactada: 'lactada',
  lactado: 'lactado',
  lactais: 'lactais',
  lactama: 'lactama',
  lactame: 'lactame',
  lactamo: 'lactamo',
  lactana: 'lactana',
  lactara: 'lactará',
  lactase: 'lactase',
  lactato: 'lactato',
  lactava: 'lactava',
  lacteas: 'lácteas',
  lacteia: 'lacteia',
  lacteis: 'lacteis',
  lactemo: 'lactemo',
  lacteos: 'lácteos',
  lactica: 'láctica',
  lactico: 'láctico',
  lactide: 'láctide',
  lactido: 'láctido',
  lactilo: 'lactilo',
  lactime: 'lactime',
  lactimo: 'lactimo',
  lactina: 'lactina',
  lactois: 'lactóis',
  lactona: 'lactona',
  lactosa: 'lactosa',
  lactose: 'lactose',
  lactoso: 'lactoso',
  lactuca: 'lactuca',
  lactume: 'lactume',
  lacunai: 'lacunai',
  lacunam: 'lacunam',
  lacunar: 'lacunar',
  lacunas: 'lacunas',
  lacunei: 'lacunei',
  lacunem: 'lacunem',
  lacunes: 'lacunes',
  lacunou: 'lacunou',
  ladairo: 'ladairo',
  ladanas: 'ladanas',
  ladanes: 'ladanes',
  ladania: 'ladania',
  ladanos: 'ládanos',
  ladaria: 'ladária',
  ladario: 'ladário',
  ladeada: 'ladeada',
  ladeado: 'ladeado',
  ladeais: 'ladeais',
  ladeara: 'ladeará',
  ladeava: 'ladeava',
  ladeeis: 'ladeeis',
  ladeiam: 'ladeiam',
  ladeias: 'ladeias',
  ladeiem: 'ladeiem',
  ladeies: 'ladeies',
  ladeira: 'ladeira',
  ladeire: 'ladeire',
  ladeiro: 'ladeiro',
  ladezas: 'ladezas',
  ladilha: 'ladilha',
  ladinas: 'ladinas',
  ladinho: 'ladinho',
  ladinos: 'ladinos',
  ladraco: 'ladraço',
  ladrada: 'ladrada',
  ladrado: 'ladrado',
  ladrais: 'ladrais',
  ladramo: 'ladramo',
  ladrara: 'ladrara',
  ladrava: 'ladrava',
  ladreis: 'ladreis',
  ladreja: 'ladrejá',
  ladreje: 'ladreje',
  ladrejo: 'ladrejo',
  ladremo: 'ladremo',
  ladrico: 'ladriço',
  ladrida: 'ladrida',
  ladrido: 'ladrido',
  ladrina: 'ladrina',
  ladripa: 'ladripá',
  ladripe: 'ladripe',
  ladripo: 'ladripo',
  ladroai: 'ladroai',
  ladroam: 'ladroam',
  ladroar: 'ladroar',
  ladroas: 'ladroas',
  ladroei: 'ladroei',
  ladroem: 'ladroem',
  ladroes: 'ladrões',
  ladrona: 'ladrona',
  ladroou: 'ladroou',
  laertes: 'laertes',
  lafania: 'lafânia',
  lafarao: 'lafarão',
  lafaras: 'lafarãs',
  lafarda: 'lafarda',
  lafardo: 'lafardo',
  lafaroa: 'lafaroa',
  lafigma: 'lafigma',
  lafigmo: 'lafigmo',
  lafiras: 'lafiras',
  lafiros: 'lafiros',
  lafolas: 'lafolas',
  lafraus: 'lafraus',
  lafrias: 'láfrias',
  lagaias: 'lagaias',
  lagalhe: 'lagalhé',
  lagamal: 'lagamal',
  lagamar: 'lagamar',
  laganas: 'láganas',
  laganha: 'laganha',
  laganos: 'láganos',
  lagares: 'lagares',
  lagaros: 'lágaros',
  lagarta: 'lagarta',
  lagarto: 'lagarto',
  lagasca: 'lagasca',
  lageana: 'lageana',
  lageano: 'lageano',
  lagenas: 'lagenas',
  lagenia: 'lagênia',
  lageoso: 'lageoso',
  lageras: 'lageras',
  lagetas: 'lagetas',
  lagidas: 'lagidas',
  lagides: 'lagides',
  lagidio: 'lagídio',
  lagimas: 'lágimas',
  laginha: 'laginha',
  laginos: 'laginos',
  lagoaca: 'lagoaça',
  lagoana: 'lagoana',
  lagoano: 'lagoano',
  lagomio: 'lagômio',
  lagomis: 'lágomis',
  lagonas: 'lagonas',
  lagooes: 'lagoões',
  lagoona: 'lagoona',
  lagopos: 'lagopos',
  lagosta: 'lagosta',
  lagosto: 'lagosto',
  lagotio: 'lagótio',
  lagreta: 'lagreta',
  lagrias: 'lágrias',
  lagrima: 'lágrima',
  lagrime: 'lagrime',
  lagrimo: 'lagrimo',
  lagueis: 'laguéis',
  lagunar: 'lagunar',
  lagunas: 'lagunas',
  laguros: 'laguros',
  laianas: 'laianas',
  laianos: 'laianos',
  laicada: 'laicada',
  laicado: 'laicado',
  laicais: 'laicais',
  laicato: 'laicato',
  laiciza: 'laiciza',
  laicize: 'laicize',
  laicizo: 'laicizo',
  laidrar: 'laidrar',
  laivada: 'laivada',
  laivado: 'laivado',
  laivais: 'laivais',
  laivamo: 'laivamo',
  laivara: 'laivará',
  laivava: 'laivava',
  laiveis: 'laiveis',
  laivemo: 'laivemo',
  laivoes: 'laivões',
  laivosa: 'laivosa',
  laivoso: 'laivoso',
  lajadao: 'lajadão',
  lajadas: 'lajadas',
  lajador: 'lajador',
  lajados: 'lajados',
  lajamos: 'lajamos',
  lajando: 'lajando',
  lajante: 'lajante',
  lajaram: 'lajaram',
  lajarao: 'lajarão',
  lajaras: 'lajarás',
  lajarei: 'lajarei',
  lajarem: 'lajarem',
  lajares: 'lajares',
  lajaria: 'lajaria',
  lajarmo: 'lajarmo',
  lajasse: 'lajasse',
  lajaste: 'lajaste',
  lajavam: 'lajavam',
  lajavas: 'lajavas',
  lajeada: 'lajeada',
  lajeado: 'lajeado',
  lajeais: 'lajeais',
  lajeana: 'lajeana',
  lajeano: 'lajeano',
  lajeara: 'lajeara',
  lajeava: 'lajeava',
  lajedos: 'lajedos',
  lajeeis: 'lajeeis',
  lajeiam: 'lajeiam',
  lajeias: 'lajeias',
  lajeiem: 'lajeiem',
  lajeies: 'lajeies',
  lajeira: 'lajeira',
  lajeiro: 'lajeiro',
  lajemos: 'lajemos',
  lajense: 'lajense',
  lajeola: 'lajeola',
  lajeosa: 'lajeosa',
  lajeoso: 'lajeoso',
  lajetas: 'lajetas',
  lajiana: 'lajiana',
  lajiano: 'lajiano',
  lajinha: 'lajinha',
  lajista: 'lajista',
  lajotas: 'lajotas',
  lalacao: 'lalação',
  laladao: 'laladão',
  laladas: 'laladas',
  lalador: 'lalador',
  lalados: 'lalados',
  lalamos: 'lalamos',
  lalando: 'lalando',
  lalante: 'lalante',
  lalaram: 'lalaram',
  lalarao: 'lalarão',
  lalaras: 'lalarás',
  lalarei: 'lalarei',
  lalarem: 'lalarem',
  lalares: 'lalares',
  lalaria: 'lalaria',
  lalarmo: 'lalarmo',
  lalasse: 'lalasse',
  lalaste: 'lalaste',
  lalavam: 'lalavam',
  lalavas: 'lalavas',
  lalemos: 'lalemos',
  lamacal: 'lamaçal',
  lamacao: 'lamação',
  lamacea: 'lamaceá',
  lamacos: 'lamaços',
  lamagem: 'lamagem',
  lamaica: 'lamaica',
  lamaico: 'lamaico',
  lamanes: 'lâmanes',
  lamarao: 'lamarão',
  lambada: 'lambada',
  lambado: 'lambado',
  lambaia: 'lambaia',
  lambaio: 'lambaio',
  lambais: 'lambais',
  lambamo: 'lambamo',
  lambana: 'lambana',
  lambara: 'lambara',
  lambare: 'lambare',
  lambari: 'lambari',
  lambaro: 'lambaro',
  lambaru: 'lambaru',
  lambava: 'lambava',
  lambaza: 'lambaza',
  lambaze: 'lambaze',
  lambazo: 'lambazo',
  lambdas: 'lambdas',
  lambeai: 'lambeai',
  lambeao: 'lambeão',
  lambear: 'lambear',
  lambeei: 'lambeei',
  lambefe: 'lambefe',
  lambeia: 'lambeia',
  lambeie: 'lambeie',
  lambeio: 'lambeio',
  lambeis: 'lambeis',
  lambemo: 'lambemo',
  lambeou: 'lambeou',
  lambera: 'lambera',
  lambeta: 'lambeta',
  lambiam: 'lambiam',
  lambiao: 'lambião',
  lambias: 'lambias',
  lambica: 'lambica',
  lambico: 'lambico',
  lambida: 'lambida',
  lambido: 'lambido',
  lambios: 'lambios',
  lambita: 'lambita',
  lambite: 'lambite',
  lambito: 'lambito',
  lamblia: 'lâmblia',
  lamboes: 'lambões',
  lambona: 'lambona',
  lambote: 'lambote',
  lambras: 'lambras',
  lambreu: 'lambréu',
  lambril: 'lambril',
  lambrim: 'lambrim',
  lambris: 'lambris',
  lambros: 'lambros',
  lambuca: 'lambuça',
  lambuce: 'lambuce',
  lambuco: 'lambuço',
  lambuda: 'lambuda',
  lambude: 'lambude',
  lambudo: 'lambudo',
  lambuja: 'lambuja',
  lambuje: 'lambuje',
  lambujo: 'lambujo',
  lambuza: 'lambuza',
  lambuze: 'lambuze',
  lambuzo: 'lambuzo',
  lameada: 'lameada',
  lameado: 'lameado',
  lameana: 'lameana',
  lameano: 'lameano',
  lamecha: 'lamecha',
  lameche: 'lameche',
  lamecho: 'lamecho',
  lamedes: 'lâmedes',
  lamedos: 'lamedos',
  lamegao: 'lamegão',
  lamegos: 'lamegos',
  lameira: 'lameira',
  lameire: 'lameire',
  lameiro: 'lameiro',
  lamelai: 'lamelai',
  lamelam: 'lamelam',
  lamelar: 'lamelar',
  lamelas: 'lamelas',
  lamelei: 'lamelei',
  lamelem: 'lamelem',
  lameles: 'lameles',
  lamelou: 'lamelou',
  lamenta: 'lamenta',
  lamente: 'lamente',
  lamento: 'lamento',
  lamiale: 'lamiale',
  lamiana: 'lamiana',
  lamiano: 'lamiano',
  laminai: 'laminai',
  laminal: 'laminal',
  laminam: 'laminam',
  laminar: 'laminar',
  laminas: 'lâminas',
  laminei: 'laminei',
  laminem: 'laminem',
  lamines: 'lamines',
  laminos: 'laminos',
  laminou: 'laminou',
  lamiras: 'lamiras',
  lamires: 'lamirés',
  lamisca: 'lamisca',
  lamismo: 'lamismo',
  lamista: 'lamista',
  lamnida: 'lâmnida',
  lamojas: 'lamojas',
  lamosas: 'lamosas',
  lamosos: 'lamosos',
  lampaca: 'lampaça',
  lampada: 'lâmpada',
  lampado: 'lâmpado',
  lampais: 'lampais',
  lampamo: 'lampamo',
  lampana: 'lampana',
  lampaos: 'lâmpãos',
  lampara: 'lampara',
  lampaso: 'lampaso',
  lampava: 'lampava',
  lampeda: 'lâmpeda',
  lampedo: 'lâmpedo',
  lampeis: 'lampeis',
  lampeja: 'lampeja',
  lampeje: 'lampeje',
  lampejo: 'lampejo',
  lampemo: 'lampemo',
  lampiai: 'lampiai',
  lampiam: 'lampiam',
  lampiao: 'lampião',
  lampiar: 'lampiar',
  lampias: 'lampias',
  lampica: 'lâmpica',
  lampico: 'lâmpico',
  lampide: 'lâmpide',
  lampiei: 'lampiei',
  lampiem: 'lampiem',
  lampies: 'lampies',
  lampios: 'lâmpios',
  lampiou: 'lampiou',
  lampiro: 'lampiro',
  lampoes: 'lampões',
  lampoga: 'lampoga',
  lampogo: 'lampogo',
  lampona: 'lampona',
  lampote: 'lampote',
  lampras: 'lamprãs',
  lamprea: 'lampreá',
  lampria: 'lâmpria',
  lampris: 'lâmpris',
  lamproa: 'lamproa',
  lampuga: 'lampuga',
  lamucas: 'lamucas',
  lamujes: 'lamujes',
  lamures: 'lâmures',
  lamuria: 'lamúria',
  lamurie: 'lamurie',
  lamurio: 'lamurio',
  lamutas: 'lamutas',
  lanadao: 'lanadão',
  lanadas: 'lanadas',
  lanador: 'lanador',
  lanados: 'lanados',
  lanamos: 'lanamos',
  lanando: 'lanando',
  lanante: 'lanante',
  lanaram: 'lanaram',
  lanarao: 'lanarão',
  lanaras: 'lanarás',
  lanarei: 'lanarei',
  lanarem: 'lanarem',
  lanares: 'lanares',
  lanaria: 'lanaria',
  lanarmo: 'lanarmo',
  lanasse: 'lanasse',
  lanaste: 'lanaste',
  lanavam: 'lanavam',
  lanavas: 'lanavas',
  lancaco: 'lançaço',
  lancada: 'lançada',
  lancado: 'lançado',
  lancais: 'lançais',
  lancamo: 'lançamo',
  lancara: 'lançará',
  lancava: 'lançava',
  lanceai: 'lanceai',
  lancear: 'lancear',
  lanceei: 'lanceei',
  lanceia: 'lanceia',
  lanceie: 'lanceie',
  lanceio: 'lanceio',
  lanceis: 'lanceis',
  lancemo: 'lancemo',
  lanceou: 'lanceou',
  lanceta: 'lanceta',
  lancete: 'lancete',
  lanceto: 'lanceto',
  lanchai: 'lanchai',
  lanchal: 'lanchal',
  lancham: 'lancham',
  lanchao: 'lanchão',
  lanchar: 'lanchar',
  lanchas: 'lanchas',
  lanchei: 'lanchei',
  lanchem: 'lanchem',
  lanches: 'lanches',
  lanchos: 'lanchôs',
  lanchou: 'lanchou',
  lancina: 'lancina',
  lancine: 'lancine',
  lancino: 'lancino',
  lancoas: 'lancoas',
  landada: 'landada',
  landado: 'landado',
  landais: 'landais',
  landaus: 'landaus',
  landesa: 'landesa',
  landina: 'landina',
  landino: 'landino',
  landins: 'landins',
  landoas: 'landoás',
  landobe: 'landobe',
  landoca: 'landoca',
  landole: 'landolé',
  landona: 'landona',
  landova: 'landova',
  landras: 'landras',
  landres: 'landres',
  landros: 'landros',
  landrus: 'landrus',
  landuar: 'landuar',
  landuas: 'landuás',
  landuma: 'landumã',
  landuns: 'landuns',
  lanedos: 'lanedos',
  laneira: 'laneira',
  laneiro: 'laneiro',
  lanemos: 'lanemos',
  lanetas: 'lanetas',
  langaas: 'langaas',
  langais: 'langais',
  langara: 'langará',
  langare: 'langaré',
  langias: 'lângias',
  langita: 'langita',
  langite: 'langite',
  langoia: 'langoia',
  langota: 'langota',
  langres: 'langres',
  languam: 'languam',
  languas: 'languas',
  languca: 'languçá',
  languce: 'languce',
  languco: 'languço',
  languem: 'languem',
  langues: 'langues',
  languia: 'languia',
  languir: 'languir',
  languis: 'languis',
  languiu: 'languiu',
  lanhaco: 'lanhaço',
  lanhada: 'lanhada',
  lanhado: 'lanhado',
  lanhais: 'lanhais',
  lanhamo: 'lanhamo',
  lanhara: 'lanhara',
  lanhava: 'lanhava',
  lanheis: 'lanheis',
  lanhemo: 'lanhemo',
  laniada: 'laníada',
  laniado: 'laniado',
  lanices: 'lanices',
  laniida: 'laníida',
  lanilha: 'lanilha',
  lanisca: 'lanisca',
  lanisco: 'lanisco',
  lanista: 'lanista',
  lanital: 'lanital',
  lanojas: 'lanojas',
  lanoros: 'lanoros',
  lanosas: 'lanosas',
  lanosos: 'lanosos',
  lanquim: 'lanquim',
  lantana: 'lantana',
  lantano: 'lantano',
  lanteia: 'lanteia',
  lanteja: 'lanteja',
  lantens: 'lanténs',
  lantins: 'lantins',
  lantora: 'lantora',
  lantzia: 'lântzia',
  lanucas: 'lanuças',
  lanudas: 'lanudas',
  lanudos: 'lanudos',
  lanugem: 'lanugem',
  lanugos: 'lanugos',
  lanvios: 'lânvios',
  lanxuem: 'lanxuém',
  lanzoai: 'lanzoai',
  lanzoam: 'lanzoam',
  lanzoar: 'lanzoar',
  lanzoas: 'lanzoas',
  lanzoei: 'lanzoei',
  lanzoem: 'lanzoem',
  lanzoes: 'lanzoes',
  lanzoou: 'lanzoou',
  lanzuda: 'lanzuda',
  lanzude: 'lanzude',
  lanzudo: 'lanzudo',
  lapacea: 'lapacea',
  lapaceo: 'lapaceo',
  lapacho: 'lapacho',
  lapadao: 'lapadão',
  lapadas: 'lapadas',
  lapador: 'lapador',
  lapados: 'lapados',
  lapagem: 'lapagem',
  lapamos: 'lapamos',
  lapando: 'lapando',
  lapante: 'lapante',
  laparam: 'laparam',
  laparao: 'laparão',
  laparas: 'laparas',
  laparda: 'lapardã',
  lapardo: 'lapardo',
  laparei: 'laparei',
  laparem: 'laparem',
  lapares: 'lapares',
  laparia: 'laparia',
  laparmo: 'laparmo',
  laparos: 'láparos',
  lapasse: 'lapasse',
  lapaste: 'lapaste',
  lapavam: 'lapavam',
  lapavas: 'lapavas',
  lapeada: 'lapeada',
  lapeado: 'lapeado',
  lapeais: 'lapeais',
  lapeara: 'lapeará',
  lapeava: 'lapeava',
  lapedos: 'lapedos',
  lapeeis: 'lapeeis',
  lapeiam: 'lapeiam',
  lapeias: 'lapeias',
  lapeiem: 'lapeiem',
  lapeies: 'lapeies',
  lapeira: 'lapeira',
  lapeiro: 'lapeiro',
  lapelas: 'lapelas',
  lapemos: 'lapemos',
  lapense: 'lapense',
  lapiana: 'lapiana',
  lapiano: 'lapiano',
  lapiasa: 'lapiasa',
  lapiase: 'lapiase',
  lapiaso: 'lapiaso',
  lapicas: 'lápicas',
  lapicos: 'lápicos',
  lapidai: 'lapidai',
  lapidam: 'lapidam',
  lapidar: 'lapidar',
  lapidas: 'lápidas',
  lapidea: 'lapídea',
  lapidei: 'lapidei',
  lapidem: 'lapidem',
  lapideo: 'lapídeo',
  lapides: 'lápides',
  lapidou: 'lapidou',
  lapigas: 'lapigas',
  lapijai: 'lapijai',
  lapijam: 'lapijam',
  lapijar: 'lapijar',
  lapijas: 'lapijas',
  lapijei: 'lapijei',
  lapijem: 'lapijem',
  lapijes: 'lapijes',
  lapijou: 'lapijou',
  lapilis: 'lapílis',
  lapinas: 'lapinas',
  lapinga: 'lapinga',
  lapinha: 'lapinha',
  lapirio: 'lapírio',
  lapisai: 'lapisai',
  lapisam: 'lapisam',
  lapisao: 'lapisão',
  lapisar: 'lapisar',
  lapisas: 'lapisas',
  lapisei: 'lapisei',
  lapisem: 'lapisem',
  lapises: 'lapises',
  lapisou: 'lapisou',
  lapista: 'lapista',
  lapitas: 'lápitas',
  lapiteu: 'lapiteu',
  lapitos: 'lápitos',
  laponas: 'laponas',
  laponia: 'lapônia',
  laponio: 'lapônio',
  lapouca: 'lapouça',
  lapouco: 'lapouço',
  lapsana: 'lápsana',
  lapuzes: 'lapuzes',
  laqueai: 'laqueai',
  laquear: 'laquear',
  laqueas: 'láqueas',
  laqueca: 'laqueca',
  laqueei: 'laqueei',
  laqueia: 'laqueia',
  laqueie: 'laqueie',
  laqueio: 'laqueio',
  laqueis: 'laqueis',
  laquemo: 'laquemo',
  laqueos: 'láqueos',
  laqueou: 'laqueou',
  laquese: 'láquese',
  laqueta: 'laqueta',
  laracha: 'laracha',
  larache: 'larache',
  laracho: 'laracho',
  laradas: 'laradas',
  larafis: 'larafis',
  laraita: 'laraíta',
  laranja: 'laranja',
  laranjo: 'laranjo',
  larapas: 'larapas',
  larapia: 'larapia',
  larapie: 'larapie',
  larapio: 'larápio',
  lararia: 'larária',
  larario: 'larário',
  larcoes: 'larcões',
  lardeai: 'lardeai',
  lardear: 'lardear',
  lardeei: 'lardeei',
  lardeia: 'lardeia',
  lardeie: 'lardeie',
  lardeio: 'lardeio',
  lardeou: 'lardeou',
  lardita: 'lardita',
  lardoes: 'lardões',
  lardose: 'lardose',
  lareada: 'lareada',
  lareado: 'lareado',
  lareais: 'lareais',
  lareara: 'lareará',
  lareava: 'lareava',
  larecam: 'lareçam',
  larecas: 'lareças',
  larecei: 'larecei',
  larecem: 'larecem',
  larecer: 'larecer',
  lareces: 'lareces',
  lareceu: 'lareceu',
  larecia: 'larécia',
  laredos: 'laredos',
  lareeis: 'lareeis',
  laregas: 'laregas',
  laregos: 'laregos',
  lareiam: 'lareiam',
  lareias: 'lareias',
  lareiem: 'lareiem',
  lareies: 'lareies',
  lareira: 'lareira',
  lareiro: 'lareiro',
  larense: 'larense',
  laretas: 'laretas',
  laretia: 'larétia',
  largada: 'largada',
  largado: 'largado',
  largais: 'largais',
  largamo: 'largamo',
  largara: 'largara',
  largata: 'largata',
  largava: 'largava',
  largina: 'largina',
  larguea: 'largueá',
  larguei: 'larguei',
  larguem: 'larguem',
  largues: 'largues',
  largura: 'largura',
  laricas: 'laricas',
  larices: 'lárices',
  laricha: 'laricha',
  laricio: 'larício',
  laricos: 'lariços',
  laridao: 'laridão',
  laridas: 'laridas',
  laridea: 'larídea',
  larideo: 'larídeo',
  larifas: 'larifas',
  larifos: 'larifos',
  lariida: 'laríida',
  larilas: 'larilas',
  larinas: 'larinas',
  larinea: 'larínea',
  larineo: 'laríneo',
  laringe: 'laringe',
  laringo: 'laringo',
  larinia: 'larínia',
  larinos: 'larinos',
  larioes: 'lariões',
  larisia: 'larísia',
  larnita: 'larnita',
  larocas: 'larocas',
  laroies: 'laroiês',
  laronas: 'laronas',
  larotas: 'larotas',
  larotes: 'larotes',
  larozes: 'larozes',
  larpada: 'larpada',
  larpado: 'larpado',
  larpais: 'larpais',
  larpamo: 'larpamo',
  larpara: 'larpara',
  larpava: 'larpava',
  larpeis: 'larpeis',
  larpemo: 'larpemo',
  larpoes: 'larpões',
  larpona: 'larpona',
  larrida: 'lárrida',
  larucas: 'laruças',
  larucha: 'larucha',
  larucho: 'larucho',
  larunda: 'larunda',
  larundo: 'larundo',
  larunja: 'larunjá',
  larunje: 'larunje',
  larunjo: 'larunjo',
  larutos: 'larutos',
  larvada: 'larvada',
  larvado: 'larvado',
  larvais: 'larvais',
  larvamo: 'larvamo',
  larvara: 'larvará',
  larvava: 'larvava',
  larveis: 'larveis',
  larveja: 'larveja',
  larveje: 'larveje',
  larvejo: 'larvejo',
  larvemo: 'larvemo',
  larvina: 'larvina',
  lasanha: 'lasanha',
  lascada: 'lascada',
  lascado: 'lascado',
  lascais: 'lascais',
  lascamo: 'lascamo',
  lascara: 'lascara',
  lascari: 'lascari',
  lascava: 'lascava',
  lasciva: 'lasciva',
  lascivo: 'lascivo',
  laseias: 'laseias',
  laseola: 'laséola',
  laserol: 'laserol',
  lasieas: 'lasíeas',
  lasiopa: 'lasiopa',
  lasiuro: 'lasiúro',
  laspeai: 'laspeai',
  laspear: 'laspear',
  laspeei: 'laspeei',
  laspeia: 'laspeia',
  laspeie: 'laspeie',
  laspeio: 'laspeio',
  laspeou: 'laspeou',
  lasquei: 'lasquei',
  lasquem: 'lasquem',
  lasques: 'lasques',
  lasquia: 'lásquia',
  lassada: 'lassada',
  lassado: 'lassado',
  lassais: 'lassais',
  lassamo: 'lassamo',
  lassara: 'lassara',
  lassava: 'lassava',
  lasseai: 'lasseai',
  lassear: 'lassear',
  lasseei: 'lasseei',
  lasseia: 'lasseia',
  lasseie: 'lasseie',
  lasseio: 'lasseio',
  lasseis: 'lasseis',
  lassemo: 'lassemo',
  lasseou: 'lasseou',
  lastima: 'lástima',
  lastime: 'lastime',
  lastimo: 'lastimo',
  lastrai: 'lastrai',
  lastram: 'lastram',
  lastrao: 'lastrão',
  lastrar: 'lastrar',
  lastras: 'lastras',
  lastrea: 'lástrea',
  lastrei: 'lastrei',
  lastrem: 'lastrem',
  lastres: 'lastres',
  lastrim: 'lastrim',
  lastros: 'lastros',
  lastrou: 'lastrou',
  latacha: 'latacha',
  latacho: 'latacho',
  latadas: 'latadas',
  latagao: 'latagão',
  latamos: 'latamos',
  latanas: 'latanas',
  latanea: 'latânea',
  lataneo: 'latâneo',
  latanes: 'latanes',
  latanha: 'latanha',
  latanhe: 'latanhe',
  latanho: 'latanho',
  latania: 'latânia',
  lataria: 'lataria',
  lateada: 'lateada',
  lateado: 'lateado',
  lateais: 'lateais',
  lateara: 'lateara',
  lateava: 'lateava',
  latebra: 'látebra',
  latedor: 'latedor',
  lateeis: 'lateeis',
  lategai: 'lategai',
  lategam: 'lategam',
  lategar: 'lategar',
  lategas: 'lategas',
  lategos: 'látegos',
  lategou: 'lategou',
  lategue: 'lategue',
  lateiam: 'lateiam',
  lateias: 'lateias',
  lateiem: 'lateiem',
  lateies: 'lateies',
  lateira: 'lateira',
  lateiro: 'lateiro',
  latejai: 'latejai',
  latejam: 'latejam',
  latejar: 'latejar',
  latejas: 'latejas',
  latejei: 'latejei',
  latejem: 'latejem',
  latejes: 'latejes',
  latejos: 'latejos',
  latejou: 'latejou',
  latemos: 'latemos',
  latende: 'latende',
  latendo: 'latendo',
  latenha: 'latenha',
  latenho: 'latenho',
  latenta: 'latenta',
  latente: 'latente',
  latento: 'latento',
  lateral: 'lateral',
  laterao: 'laterão',
  lateras: 'laterás',
  laterei: 'laterei',
  laterem: 'laterem',
  lateres: 'lateres',
  lateria: 'lateria',
  latesca: 'latesça',
  latesce: 'latescê',
  latesci: 'latesci',
  latesco: 'latesço',
  laticai: 'laticai',
  laticam: 'laticam',
  laticar: 'laticar',
  laticas: 'láticas',
  latices: 'látices',
  laticos: 'láticos',
  laticou: 'laticou',
  latidao: 'latidão',
  latidas: 'latidas',
  latidor: 'latidor',
  latidos: 'latidos',
  latieis: 'latíeis',
  latilos: 'latilos',
  latimos: 'latimos',
  latinai: 'latinai',
  latinam: 'latinam',
  latinar: 'latinar',
  latinas: 'latinas',
  latindo: 'latindo',
  latinei: 'latinei',
  latinem: 'latinem',
  latines: 'latines',
  latinga: 'latinga',
  latingo: 'latingo',
  latinha: 'latinha',
  latinos: 'latinos',
  latinou: 'latinou',
  latipas: 'latipás',
  latipes: 'látipes',
  latique: 'latique',
  latiram: 'latiram',
  latirao: 'latirão',
  latiras: 'latiras',
  latirei: 'latirei',
  latirem: 'latirem',
  latires: 'latires',
  latiria: 'latiria',
  latirmo: 'latirmo',
  latiros: 'látiros',
  latismo: 'latismo',
  latisse: 'latisse',
  latiste: 'latiste',
  lativer: 'lativer',
  latmica: 'látmica',
  latmico: 'látmico',
  latoada: 'latoada',
  latomia: 'latomia',
  latonas: 'latonas',
  latosas: 'latosas',
  latreas: 'látreas',
  latrias: 'latrias',
  latrina: 'latrina',
  laudana: 'láudana',
  laudano: 'láudano',
  laudeis: 'laudéis',
  laudeus: 'laudéus',
  laudina: 'laudina',
  laudino: 'laudino',
  laueita: 'laueíta',
  laulaus: 'laulaus',
  launeia: 'launeia',
  launins: 'launins',
  laurale: 'laurale',
  laurana: 'laurana',
  laurano: 'laurano',
  laurato: 'laurato',
  laureai: 'laureai',
  laurear: 'laurear',
  laureas: 'láureas',
  laureei: 'laureei',
  laureia: 'laureia',
  laureie: 'laureie',
  laureio: 'laureio',
  laureis: 'lauréis',
  laureno: 'laureno',
  laureos: 'láureos',
  laureou: 'laureou',
  laureta: 'laureta',
  laurias: 'láurias',
  laurica: 'láurica',
  laurico: 'láurico',
  laurilo: 'laurilo',
  laurina: 'laurina',
  laurino: 'laurino',
  laurita: 'laurita',
  laurite: 'laurite',
  laurona: 'laurona',
  lautais: 'lautais',
  lautelo: 'lautelo',
  lautias: 'lautiás',
  lautita: 'lautita',
  lautite: 'lautite',
  lavabos: 'lavabos',
  lavacao: 'lavação',
  lavacra: 'lavacra',
  lavacro: 'lavacro',
  lavadao: 'lavadão',
  lavadas: 'lavadas',
  lavador: 'lavador',
  lavados: 'lavados',
  lavagem: 'lavagem',
  lavajai: 'lavajai',
  lavajam: 'lavajam',
  lavajao: 'lavajão',
  lavajar: 'lavajar',
  lavajas: 'lavajas',
  lavajei: 'lavajei',
  lavajem: 'lavajem',
  lavajes: 'lavajes',
  lavajos: 'lavajos',
  lavajou: 'lavajou',
  lavamos: 'lavamos',
  lavanca: 'lavanca',
  lavanco: 'lavanco',
  lavanda: 'lavanda',
  lavando: 'lavando',
  lavante: 'lavante',
  lavaram: 'lavaram',
  lavarao: 'lavarão',
  lavaras: 'lavaras',
  lavarei: 'lavarei',
  lavarem: 'lavarem',
  lavares: 'lavares',
  lavaria: 'lavaria',
  lavarmo: 'lavarmo',
  lavasca: 'lavasca',
  lavasco: 'lavasco',
  lavasse: 'lavasse',
  lavaste: 'lavaste',
  lavavam: 'lavavam',
  lavavas: 'lavavas',
  lavavel: 'lavável',
  lavegai: 'lavegai',
  lavegam: 'lavegam',
  lavegar: 'lavegar',
  lavegas: 'lavegas',
  lavegos: 'lavegos',
  lavegou: 'lavegou',
  lavegue: 'lavegue',
  lavemos: 'lavemos',
  laverca: 'laverca',
  laverco: 'laverco',
  lavicas: 'lávicas',
  lavicos: 'lávicos',
  lavinia: 'lavínia',
  lavinio: 'lavínio',
  lavitas: 'lavitas',
  lavoira: 'lavoira',
  lavoire: 'lavoire',
  lavoiro: 'lavoiro',
  lavorai: 'lavorai',
  lavoram: 'lavoram',
  lavorar: 'lavorar',
  lavoras: 'lavoras',
  lavorea: 'lavoreá',
  lavorei: 'lavorei',
  lavorem: 'lavorem',
  lavores: 'lavores',
  lavorou: 'lavorou',
  lavosas: 'lavosas',
  lavosos: 'lavosos',
  lavoura: 'lavoura',
  lavoure: 'lavoure',
  lavouro: 'lavouro',
  lavrada: 'lavrada',
  lavrado: 'lavrado',
  lavrais: 'lavrais',
  lavramo: 'lavramo',
  lavrara: 'lavrara',
  lavrava: 'lavrava',
  lavreis: 'lavreis',
  lavremo: 'lavremo',
  lavrita: 'lavrita',
  lavujai: 'lavujai',
  lavujam: 'lavujam',
  lavujar: 'lavujar',
  lavujas: 'lavujas',
  lavujei: 'lavujei',
  lavujem: 'lavujem',
  lavujes: 'lavujes',
  lavujos: 'lavujos',
  lavujou: 'lavujou',
  laxacao: 'laxação',
  laxadao: 'laxadão',
  laxadas: 'laxadas',
  laxador: 'laxador',
  laxados: 'laxados',
  laxamos: 'laxamos',
  laxando: 'laxando',
  laxante: 'laxante',
  laxaram: 'laxaram',
  laxarao: 'laxarão',
  laxaras: 'laxarás',
  laxarei: 'laxarei',
  laxarem: 'laxarem',
  laxares: 'laxares',
  laxaria: 'laxaria',
  laxarmo: 'laxarmo',
  laxasse: 'laxasse',
  laxaste: 'laxaste',
  laxavam: 'laxavam',
  laxavas: 'laxavas',
  laxemos: 'laxemos',
  laxidao: 'laxidão',
  laxismo: 'laxismo',
  laxista: 'laxista',
  layouts: 'layouts',
  lazarai: 'lazarai',
  lazaram: 'lazaram',
  lazarao: 'lazarão',
  lazarar: 'lazarar',
  lazaras: 'lázaras',
  lazarei: 'lazarei',
  lazarem: 'lazarem',
  lazares: 'lazares',
  lazaria: 'lazaria',
  lazaros: 'lázaros',
  lazarou: 'lazarou',
  lazeira: 'lazeira',
  lazeire: 'lazeire',
  lazeiro: 'lazeiro',
  lazerai: 'lazerai',
  lazeram: 'lazeram',
  lazerar: 'lazerar',
  lazeras: 'lazeras',
  lazerei: 'lazerei',
  lazerem: 'lazerem',
  lazeres: 'lazeres',
  lazerou: 'lazerou',
  lazinha: 'lãzinha',
  lazonas: 'lazonas',
  lazudas: 'lãzudas',
  lazudos: 'lãzudos',
  lazules: 'lazules',
  lazulis: 'lazúlis',
  leabeus: 'leabeus',
  leachia: 'leáchia',
  lealdai: 'lealdai',
  lealdam: 'lealdam',
  lealdar: 'lealdar',
  lealdas: 'lealdas',
  lealdei: 'lealdei',
  lealdem: 'lealdem',
  lealdes: 'lealdes',
  lealdou: 'lealdou',
  lealeza: 'lealeza',
  lealzao: 'lealzão',
  leandra: 'leandra',
  leaozao: 'leãozão',
  leasing: 'leasing',
  leatina: 'leatina',
  lebiida: 'lebíida',
  lebrada: 'lebrada',
  lebrado: 'lebrado',
  lebreia: 'lebreia',
  lebreis: 'lebréis',
  lebreus: 'lebréus',
  lebroes: 'lebrões',
  lebroto: 'lebroto',
  lebruna: 'lebruna',
  lebruno: 'lebruno',
  lebunas: 'lebunas',
  lebunos: 'lebunos',
  lecanio: 'lecânio',
  leceira: 'leceira',
  leceiro: 'leceiro',
  lechias: 'lechias',
  lechues: 'lechuês',
  lecidea: 'lecídea',
  lecideo: 'lecídeo',
  leciona: 'leciona',
  lecione: 'lecione',
  leciono: 'leciono',
  lecites: 'lecites',
  lecitis: 'lécitis',
  lecitol: 'lecitol',
  lecitos: 'lécitos',
  lectica: 'lectica',
  lectina: 'lectina',
  lectiva: 'lectiva',
  lectivo: 'lectivo',
  lectora: 'lectora',
  ledenos: 'ledenos',
  ledicas: 'lediças',
  ledices: 'ledices',
  ledoras: 'ledoras',
  ledores: 'ledores',
  leeacea: 'leeácea',
  leeaceo: 'leeáceo',
  leelite: 'leelite',
  leelito: 'leelito',
  leersia: 'leérsia',
  lefanas: 'lefanas',
  legacao: 'legação',
  legacia: 'legacia',
  legacos: 'legaços',
  legadao: 'legadão',
  legadas: 'legadas',
  legador: 'legador',
  legados: 'legados',
  legales: 'legalés',
  legalhe: 'legalhé',
  legames: 'legames',
  legamos: 'legamos',
  legando: 'legando',
  legante: 'legante',
  legaram: 'legaram',
  legarao: 'legarão',
  legaras: 'legaras',
  legarei: 'legarei',
  legarem: 'legarem',
  legares: 'legares',
  legaria: 'legaria',
  legarmo: 'legarmo',
  legasse: 'legasse',
  legaste: 'legaste',
  legatos: 'legatos',
  legavam: 'legavam',
  legavas: 'legavas',
  legbeas: 'légbeas',
  legbeos: 'légbeos',
  legenda: 'legenda',
  legende: 'legende',
  legendo: 'legendo',
  legente: 'legente',
  legioes: 'legiões',
  legisla: 'legisla',
  legisle: 'legisle',
  legislo: 'legislo',
  legismo: 'legismo',
  legista: 'legista',
  legivel: 'legível',
  legnoto: 'legnoto',
  legorne: 'legorne',
  legrada: 'legrada',
  legrado: 'legrado',
  legrais: 'legrais',
  legramo: 'legramo',
  legrara: 'legrará',
  legrava: 'legrava',
  legreis: 'legreis',
  legremo: 'legremo',
  legueis: 'legueis',
  leguemo: 'leguemo',
  legumes: 'legumes',
  legumia: 'legumia',
  lehiita: 'lehiita',
  leiamos: 'leiamos',
  leiauta: 'leiauta',
  leiaute: 'leiaute',
  leiauto: 'leiauto',
  leidias: 'lêidias',
  leidosa: 'leidosa',
  leidoso: 'leidoso',
  leifita: 'leifita',
  leigaca: 'leigaça',
  leigaco: 'leigaço',
  leigada: 'leigada',
  leigado: 'leigado',
  leigais: 'leigais',
  leigara: 'leigará',
  leigava: 'leigava',
  leiguei: 'leiguei',
  leiguem: 'leiguem',
  leigues: 'leigues',
  leiloai: 'leiloai',
  leiloam: 'leiloam',
  leiloar: 'leiloar',
  leiloas: 'leiloas',
  leiloei: 'leiloei',
  leiloem: 'leiloem',
  leiloes: 'leilões',
  leiloou: 'leiloou',
  leipoas: 'leipoas',
  leirada: 'leirada',
  leirado: 'leirado',
  leirais: 'leirais',
  leiramo: 'leiramo',
  leirara: 'leirará',
  leirava: 'leirava',
  leireis: 'leireis',
  leiremo: 'leiremo',
  leiriao: 'leirião',
  leirias: 'leirias',
  leirioa: 'leirioa',
  leiroes: 'leirões',
  leiroto: 'leiroto',
  leistos: 'leístos',
  leitada: 'leitada',
  leitado: 'leitado',
  leitais: 'leitais',
  leitamo: 'leitamo',
  leitara: 'leitara',
  leitava: 'leitava',
  leiteis: 'leiteis',
  leitemo: 'leitemo',
  leitiga: 'leitiga',
  leitoas: 'leitoas',
  leitoes: 'leitões',
  leitora: 'leitora',
  leitosa: 'leitosa',
  leitoso: 'leitoso',
  leitras: 'leitras',
  leitual: 'leitual',
  leituga: 'leituga',
  leituja: 'leituja',
  leitura: 'leitura',
  leivada: 'leivada',
  leivado: 'leivado',
  leivoes: 'leivões',
  leixada: 'leixada',
  leixado: 'leixado',
  leixais: 'leixais',
  leixamo: 'leixamo',
  leixara: 'leixará',
  leixava: 'leixava',
  leixeis: 'leixeis',
  leixemo: 'leixemo',
  leixoes: 'leixões',
  lelapes: 'lélapes',
  leleges: 'léleges',
  lelegia: 'lelégia',
  lelegio: 'lelégio',
  leleles: 'lelelês',
  lelieas: 'lelíeas',
  lemanas: 'lemanas',
  lemanea: 'lemânea',
  lemaneo: 'lemâneo',
  lemanes: 'lemanes',
  lemanos: 'lemanos',
  lemario: 'lemário',
  lembada: 'lembada',
  lembado: 'lembado',
  lembais: 'lembais',
  lembamo: 'lembamo',
  lembara: 'lembara',
  lembava: 'lembava',
  lembefe: 'lembefe',
  lembeis: 'lembeis',
  lembemo: 'lembemo',
  lembrai: 'lembrai',
  lembram: 'lembram',
  lembrar: 'lembrar',
  lembras: 'lembras',
  lembrei: 'lembrei',
  lembrem: 'lembrem',
  lembres: 'lembres',
  lembrou: 'lembrou',
  lemense: 'lemense',
  lemicas: 'lêmicas',
  lemicos: 'lêmicos',
  lemingo: 'lemingo',
  lemiste: 'lemiste',
  lemneas: 'lêmneas',
  lemneos: 'lêmneos',
  lemnias: 'lêmnias',
  lemnios: 'lêmnios',
  lemonal: 'lemonal',
  lemonia: 'lemônia',
  lemonol: 'lemonol',
  lemosim: 'lemosim',
  lemosis: 'lemosis',
  lemovia: 'lemóvia',
  lemovio: 'lemóvio',
  lempira: 'lempira',
  lemural: 'lemural',
  lemures: 'lêmures',
  lemuria: 'lemúria',
  lemurio: 'lemúrio',
  lenamos: 'lenamos',
  lenanas: 'lenanas',
  lenanos: 'lenanos',
  lencoes: 'lenções',
  lencois: 'lençóis',
  lendeas: 'lêndeas',
  leneias: 'leneias',
  lengada: 'lengada',
  lengado: 'lengado',
  lengais: 'lengais',
  lengara: 'lengara',
  lengava: 'lengava',
  lenguas: 'lênguas',
  lenguei: 'lenguei',
  lenguem: 'lenguem',
  lengues: 'lengues',
  lenhada: 'lenhada',
  lenhado: 'lenhado',
  lenhais: 'lenhais',
  lenhamo: 'lenhamo',
  lenhara: 'lenhara',
  lenhava: 'lenhava',
  lenheis: 'lenheis',
  lenhemo: 'lenhemo',
  lenhina: 'lenhina',
  lenhita: 'lenhita',
  lenhite: 'lenhite',
  lenhito: 'lenhito',
  lenhosa: 'lenhosa',
  lenhose: 'lenhose',
  lenhoso: 'lenhoso',
  lenicao: 'lenição',
  lenidao: 'lenidão',
  lenidas: 'lenidas',
  lenidor: 'lenidor',
  lenidos: 'lenidos',
  lenieis: 'leníeis',
  lenimos: 'lenimos',
  lenindo: 'lenindo',
  leninha: 'leninha',
  leninho: 'leninho',
  leniram: 'leniram',
  lenirao: 'lenirão',
  leniras: 'leniras',
  lenirei: 'lenirei',
  lenirem: 'lenirem',
  lenires: 'lenires',
  leniria: 'leniria',
  lenirmo: 'lenirmo',
  lenisse: 'lenisse',
  leniste: 'leniste',
  lennoas: 'lênnoas',
  lentaco: 'lentaço',
  lentada: 'lentada',
  lentado: 'lentado',
  lentais: 'lentais',
  lentamo: 'lentamo',
  lentara: 'lentara',
  lentava: 'lentava',
  lenteai: 'lenteai',
  lentear: 'lentear',
  lenteei: 'lenteei',
  lenteia: 'lenteia',
  lenteie: 'lenteie',
  lenteio: 'lenteio',
  lenteis: 'lenteis',
  lenteja: 'lenteja',
  lenteje: 'lenteje',
  lentejo: 'lentejo',
  lentemo: 'lentemo',
  lenteou: 'lenteou',
  lenteza: 'lenteza',
  lentica: 'lêntica',
  lentico: 'lêntico',
  lentigo: 'lentigo',
  lentina: 'lentina',
  lentino: 'lentino',
  lentite: 'lentite',
  lentora: 'lentora',
  lentura: 'lentura',
  lenzite: 'lenzite',
  leocima: 'leocima',
  leonada: 'leonada',
  leonado: 'leonado',
  leonesa: 'leonesa',
  leonica: 'leônica',
  leonico: 'leônico',
  leonina: 'leonina',
  leonino: 'leonino',
  leonita: 'leonita',
  leonite: 'leonite',
  leonote: 'leonote',
  leonuro: 'leonuro',
  lepades: 'lépades',
  lepalar: 'lepalar',
  lepalas: 'lépalas',
  lepalio: 'lepálio',
  lepalos: 'lépalos',
  lepanta: 'lepanta',
  lepanto: 'lepanto',
  lepasta: 'lepasta',
  lepchas: 'lepchas',
  lepetas: 'lepetas',
  lepidas: 'lépidas',
  lepidez: 'lepidez',
  lepidio: 'lepídio',
  lepidos: 'lépidos',
  lepiota: 'lepiota',
  lepiros: 'lepiros',
  lepisma: 'lepisma',
  lepista: 'lepista',
  leplica: 'léplica',
  lepoide: 'lepoide',
  lepomis: 'lépomis',
  leprada: 'leprada',
  leprado: 'leprado',
  lepraes: 'lepraes',
  lepride: 'lépride',
  leproma: 'leproma',
  leprona: 'leprona',
  leprosa: 'leprosa',
  leprose: 'leprose',
  leproso: 'leproso',
  leptica: 'léptica',
  leptico: 'léptico',
  leptida: 'léptida',
  leptino: 'leptino',
  leptito: 'leptito',
  leptoma: 'leptoma',
  leptons: 'léptons',
  leptopo: 'léptopo',
  leptura: 'leptura',
  lepturo: 'lepturo',
  leptusa: 'leptusa',
  lequeca: 'lequeça',
  lequias: 'léquias',
  lequios: 'léquios',
  lequita: 'lequita',
  leramos: 'lêramos',
  lerdaca: 'lerdaça',
  lerdaco: 'lerdaço',
  lerdeai: 'lerdeai',
  lerdear: 'lerdear',
  lerdeei: 'lerdeei',
  lerdeia: 'lerdeia',
  lerdeie: 'lerdeie',
  lerdeio: 'lerdeio',
  lerdeou: 'lerdeou',
  lerdeza: 'lerdeza',
  lerdice: 'lerdice',
  lerecia: 'lerécia',
  lereias: 'lereias',
  leremos: 'leremos',
  lereses: 'lereses',
  leriacu: 'leriaçu',
  leriada: 'leriada',
  leriado: 'leriado',
  leriais: 'leriais',
  leriamo: 'leriamo',
  leriara: 'leriará',
  leriava: 'leriava',
  lericas: 'lericas',
  lericos: 'lericos',
  lerieis: 'leríeis',
  leriemo: 'leriemo',
  lerista: 'lerista',
  lerivas: 'lerivas',
  lerneas: 'lérneas',
  lerneia: 'lerneia',
  lerneos: 'lérneos',
  lerneus: 'lerneus',
  lesadao: 'lesadão',
  lesadas: 'lesadas',
  lesador: 'lesador',
  lesados: 'lesados',
  lesagem: 'lesagem',
  lesamos: 'lesamos',
  lesando: 'lesando',
  lesante: 'lesante',
  lesaram: 'lesaram',
  lesarao: 'lesarão',
  lesaras: 'lesaras',
  lesarei: 'lesarei',
  lesarem: 'lesarem',
  lesares: 'lesares',
  lesaria: 'lesaria',
  lesarmo: 'lesarmo',
  lesasse: 'lesasse',
  lesaste: 'lesaste',
  lesavam: 'lesavam',
  lesavas: 'lesavas',
  lesavel: 'lesável',
  lesbias: 'lésbias',
  lesbica: 'lésbica',
  lesbico: 'lésbico',
  lesbios: 'lésbios',
  leseira: 'leseira',
  lesemos: 'lesemos',
  lesguia: 'lésguia',
  lesguio: 'lésguio',
  lesiona: 'lesiona',
  lesione: 'lesione',
  lesiono: 'lesiono',
  lesivas: 'lesivas',
  lesivos: 'lesivos',
  lesmada: 'lesmada',
  lesmado: 'lesmado',
  lesmais: 'lesmais',
  lesmamo: 'lesmamo',
  lesmara: 'lesmara',
  lesmava: 'lesmava',
  lesmeis: 'lesmeis',
  lesmemo: 'lesmemo',
  lesmias: 'lésmias',
  lesmice: 'lesmice',
  lesmida: 'lesmida',
  lesnela: 'lesnela',
  lesonas: 'lesonas',
  lesotas: 'lesotas',
  lesotos: 'lesotos',
  lesquea: 'lésquea',
  lesques: 'lesques',
  lesquio: 'lésquio',
  lesseis: 'lêsseis',
  lessole: 'lessole',
  lestada: 'lestada',
  lestado: 'lestado',
  lestais: 'lestais',
  lestamo: 'lestamo',
  lestara: 'lestara',
  lestava: 'lestava',
  lesteis: 'lesteis',
  lestemo: 'lestemo',
  lesteva: 'lesteva',
  lestias: 'lestias',
  lestras: 'lestras',
  lestros: 'lestros',
  letargo: 'letargo',
  leteias: 'leteias',
  leticas: 'léticas',
  leticia: 'letícia',
  leticos: 'léticos',
  letilia: 'letília',
  letivas: 'letivas',
  letivos: 'letivos',
  letonio: 'letônio',
  letrada: 'letrada',
  letrado: 'letrado',
  letrais: 'letrais',
  letramo: 'letramo',
  letrara: 'letrara',
  letrava: 'letrava',
  letreai: 'letreai',
  letrear: 'letrear',
  letreei: 'letreei',
  letreia: 'letreia',
  letreie: 'letreie',
  letreio: 'letreio',
  letreis: 'letreis',
  letremo: 'letremo',
  letreou: 'letreou',
  letrias: 'letrias',
  letrina: 'letrina',
  letrino: 'letrino',
  letriza: 'letrizá',
  letrize: 'letrize',
  letrizo: 'letrizo',
  letruda: 'letruda',
  letrudo: 'letrudo',
  letztes: 'letztes',
  leucena: 'leucena',
  leuceta: 'leuceta',
  leucica: 'lêucica',
  leucico: 'lêucico',
  leucila: 'leucila',
  leucina: 'leucina',
  leucipo: 'leucipo',
  leucita: 'leucita',
  leucite: 'leucite',
  leucito: 'leucito',
  leucois: 'leucóis',
  leucoma: 'leucoma',
  leucona: 'leucona',
  leucons: 'lêucons',
  leucose: 'leucose',
  leuzeas: 'lêuzeas',
  levacao: 'levação',
  levacas: 'levacas',
  levacos: 'levacos',
  levadao: 'levadão',
  levadas: 'levadas',
  levadia: 'levadia',
  levadio: 'levadio',
  levador: 'levador',
  levados: 'levados',
  levamos: 'levamos',
  levando: 'levando',
  levanta: 'levanta',
  levante: 'levante',
  levanto: 'levanto',
  levaram: 'levaram',
  levarao: 'levarão',
  levaras: 'levaras',
  levarei: 'levarei',
  levarem: 'levarem',
  levares: 'levares',
  levaria: 'levaria',
  levarmo: 'levarmo',
  levasse: 'levasse',
  levaste: 'levaste',
  levavam: 'levavam',
  levavas: 'levavas',
  levavel: 'levável',
  levedai: 'levedai',
  levedam: 'levedam',
  levedar: 'levedar',
  levedas: 'lêvedas',
  levedei: 'levedei',
  levedem: 'levedem',
  levedes: 'levedes',
  levedos: 'lêvedos',
  levedou: 'levedou',
  leveira: 'leveira',
  leveiro: 'leveiro',
  leveler: 'leveler',
  levemos: 'levemos',
  levezas: 'levezas',
  levezes: 'levezes',
  leviada: 'leviada',
  leviado: 'leviado',
  leviais: 'leviais',
  leviamo: 'leviamo',
  leviana: 'leviana',
  leviano: 'leviano',
  leviara: 'leviara',
  leviata: 'leviatã',
  leviava: 'leviava',
  levidao: 'levidão',
  levieis: 'levieis',
  leviemo: 'leviemo',
  levigai: 'levigai',
  levigam: 'levigam',
  levigar: 'levigar',
  levigas: 'levigas',
  levigou: 'levigou',
  levigue: 'levigue',
  levinha: 'levinha',
  levinho: 'levinho',
  levisia: 'levísia',
  levitai: 'levitai',
  levitam: 'levitam',
  levitar: 'levitar',
  levitas: 'levitas',
  levitei: 'levitei',
  levitem: 'levitem',
  levites: 'levites',
  levitou: 'levitou',
  levubos: 'levubos',
  levynas: 'levynas',
  lexemas: 'lexemas',
  lexiais: 'lexiais',
  lexiana: 'lexiana',
  lexical: 'lexical',
  lexicas: 'léxicas',
  lexicon: 'léxicon',
  lexicos: 'léxicos',
  lexiona: 'lexiona',
  lexivia: 'lexívia',
  lexovio: 'lexóvio',
  lezaras: 'lézaras',
  lezaros: 'lézaros',
  lezirao: 'lezirão',
  leziras: 'leziras',
  leziria: 'lezíria',
  lhandra: 'lhandra',
  lhaneza: 'lhaneza',
  lhantas: 'lhantas',
  lhantra: 'lhantra',
  lhanura: 'lhanura',
  lharufo: 'lharufo',
  lheismo: 'lheísmo',
  lhicada: 'lhiçada',
  liacoes: 'liações',
  liacona: 'liaçona',
  liaculo: 'liáculo',
  liacuti: 'liácuti',
  liadoes: 'liadões',
  liadona: 'liadona',
  liadora: 'liadora',
  liadura: 'liadura',
  liagens: 'liagens',
  liagora: 'liágora',
  liagoro: 'liágoro',
  liambas: 'liambas',
  liancas: 'lianças',
  liantes: 'liantes',
  liardes: 'liardes',
  liareis: 'liáreis',
  liaremo: 'liaremo',
  liariam: 'liariam',
  liarias: 'liarias',
  liarmos: 'liarmos',
  liasica: 'liásica',
  liasico: 'liásico',
  liassem: 'liassem',
  liasses: 'liasses',
  liastes: 'liastes',
  liaveis: 'liáveis',
  libacao: 'libação',
  libadao: 'libadão',
  libadas: 'libadas',
  libador: 'libador',
  libados: 'libados',
  libambo: 'libambo',
  libames: 'libames',
  libamos: 'libamos',
  libanco: 'libanco',
  libando: 'libando',
  libanes: 'libanês',
  libangu: 'libangu',
  libania: 'libânia',
  libanio: 'libânio',
  libante: 'libante',
  libaram: 'libaram',
  libarao: 'libarão',
  libaras: 'libarás',
  libarei: 'libarei',
  libarem: 'libarem',
  libares: 'libares',
  libaria: 'libaria',
  libarmo: 'libarmo',
  libarte: 'libarté',
  libasse: 'libasse',
  libaste: 'libaste',
  libatas: 'libatas',
  libavam: 'libavam',
  libavas: 'libavas',
  libavel: 'libável',
  libecho: 'libecho',
  libecio: 'libécio',
  libelai: 'libelai',
  libelam: 'libelam',
  libelar: 'libelar',
  libelas: 'libelas',
  libelei: 'libelei',
  libelem: 'libelem',
  libeles: 'libeles',
  libelos: 'libelos',
  libelou: 'libelou',
  libemos: 'libemos',
  libente: 'libente',
  liberai: 'liberai',
  liberal: 'liberal',
  liberam: 'liberam',
  liberar: 'liberar',
  liberas: 'liberas',
  liberei: 'liberei',
  liberem: 'liberem',
  liberes: 'liberes',
  liberia: 'libéria',
  liberio: 'libério',
  liberne: 'liberne',
  liberos: 'líberos',
  liberou: 'liberou',
  liberta: 'liberta',
  liberte: 'liberte',
  liberto: 'liberto',
  libicas: 'líbicas',
  libicia: 'libícia',
  libicio: 'libício',
  libicos: 'líbicos',
  libidos: 'líbidos',
  libinia: 'libínia',
  libitas: 'libitas',
  libitea: 'libítea',
  libitos: 'líbitos',
  libnita: 'libnita',
  libolas: 'libolas',
  libolos: 'libolos',
  libombo: 'libombo',
  libonga: 'libonga',
  libongo: 'libongo',
  libonia: 'libônia',
  librada: 'librada',
  librado: 'librado',
  librais: 'librais',
  librame: 'librame',
  libramo: 'libramo',
  librara: 'librará',
  librava: 'librava',
  libreia: 'libreia',
  libreis: 'libreis',
  libremo: 'libremo',
  libreto: 'libreto',
  libreus: 'libréus',
  librina: 'librina',
  librine: 'librine',
  librino: 'librino',
  libunas: 'libunas',
  libunos: 'libunos',
  liburna: 'liburna',
  liburno: 'liburno',
  libuzia: 'libuzia',
  licadas: 'licadas',
  licaena: 'licaena',
  licanco: 'licanço',
  licanha: 'licanha',
  licania: 'licânia',
  licaoes: 'licaões',
  licaone: 'licáone',
  licaria: 'licária',
  licarol: 'liçarol',
  licasta: 'licasta',
  licaste: 'licaste',
  licatas: 'licatas',
  liceais: 'liceais',
  liceana: 'liceana',
  liceano: 'liceano',
  liceira: 'liceira',
  licenas: 'licenas',
  licenca: 'licença',
  licenia: 'licênia',
  licenio: 'licênio',
  licetol: 'licetol',
  lichavi: 'lichávi',
  lichias: 'lichias',
  lichino: 'lichino',
  licidas: 'lícidas',
  licidea: 'licídea',
  licideo: 'licídeo',
  liciina: 'liciina',
  licinas: 'licinas',
  licinia: 'licínia',
  licinio: 'licínio',
  licinos: 'licinos',
  licitai: 'licitai',
  licitam: 'licitam',
  licitar: 'licitar',
  licitas: 'licitas',
  licitei: 'licitei',
  licitem: 'licitem',
  licites: 'licites',
  licitos: 'lícitos',
  licitou: 'licitou',
  licnica: 'lícnica',
  licnico: 'lícnico',
  licnide: 'lícnide',
  licnita: 'licnita',
  licnite: 'licnite',
  licnito: 'licnito',
  licnuco: 'licnuco',
  licodes: 'licodes',
  licodon: 'lícodon',
  licoese: 'licoese',
  licoeze: 'licoeze',
  liconas: 'liçonas',
  liconde: 'liconde',
  licondo: 'licondo',
  liconos: 'liconos',
  liconte: 'liconte',
  licopos: 'lícopos',
  licopse: 'licopse',
  licores: 'licores',
  licorne: 'licorne',
  licosas: 'licosas',
  licoses: 'licoses',
  licreus: 'licréus',
  lictida: 'líctida',
  lictora: 'lictora',
  licuris: 'licuris',
  lidacao: 'lidação',
  lidadao: 'lidadão',
  lidadas: 'lidadas',
  lidador: 'lidador',
  lidados: 'lidados',
  lidagem: 'lidagem',
  lidaira: 'lidairá',
  lidaire: 'lidaire',
  lidairo: 'lidairo',
  lidamos: 'lidamos',
  lidando: 'lidando',
  lidante: 'lidante',
  lidaram: 'lidaram',
  lidarao: 'lidarão',
  lidaras: 'lidaras',
  lidarei: 'lidarei',
  lidarem: 'lidarem',
  lidares: 'lidares',
  lidaria: 'lidaria',
  lidarmo: 'lidarmo',
  lidasse: 'lidasse',
  lidaste: 'lidaste',
  lidavam: 'lidavam',
  lidavas: 'lidavas',
  lideira: 'lideira',
  lideiro: 'lideiro',
  lidemos: 'lidemos',
  lidente: 'lidente',
  liderai: 'liderai',
  lideram: 'lideram',
  liderar: 'liderar',
  lideras: 'lideras',
  liderei: 'liderei',
  liderem: 'liderem',
  lideres: 'líderes',
  liderou: 'liderou',
  lidiana: 'lidiana',
  lidimai: 'lidimai',
  lidimam: 'lidimam',
  lidimar: 'lidimar',
  lidimas: 'lídimas',
  lidimei: 'lidimei',
  lidimem: 'lidimem',
  lidimes: 'lidimes',
  lidimos: 'lídimos',
  lidimou: 'lidimou',
  lidinha: 'lidinha',
  lidinho: 'lidinho',
  liditas: 'liditas',
  lidites: 'lidites',
  liditos: 'liditos',
  lidmeia: 'lidmeia',
  lidonas: 'lidonas',
  lidosas: 'lidosas',
  lidosos: 'lidosos',
  lidrosa: 'lidrosa',
  lidroso: 'lidroso',
  lienais: 'lienais',
  lienase: 'lienase',
  lienica: 'liênica',
  lienico: 'liênico',
  lienite: 'lienite',
  lienosa: 'lienosa',
  lienoso: 'lienoso',
  liernes: 'liernes',
  lifemia: 'lifemia',
  lifemos: 'lifemos',
  liforme: 'liforme',
  lifting: 'lifting',
  lifucos: 'lifucos',
  lifutis: 'lifutis',
  ligacao: 'ligação',
  ligadao: 'ligadão',
  ligadas: 'ligadas',
  ligador: 'ligador',
  ligados: 'ligados',
  ligaeas: 'ligáeas',
  ligaeos: 'ligáeos',
  ligaida: 'ligaida',
  ligaios: 'ligaios',
  ligamen: 'ligâmen',
  ligames: 'ligames',
  ligamos: 'ligamos',
  ligando: 'ligando',
  ligante: 'ligante',
  ligaram: 'ligaram',
  ligarao: 'ligarão',
  ligaras: 'ligaras',
  ligarei: 'ligarei',
  ligarem: 'ligarem',
  ligares: 'ligares',
  ligaria: 'ligaria',
  ligario: 'ligário',
  ligarmo: 'ligarmo',
  ligases: 'ligases',
  ligasse: 'ligasse',
  ligaste: 'ligaste',
  ligauna: 'ligauna',
  ligauno: 'ligauno',
  ligavam: 'ligavam',
  ligavas: 'ligavas',
  ligavel: 'ligável',
  ligeida: 'ligeida',
  ligeira: 'ligeira',
  ligeiro: 'ligeiro',
  ligidea: 'ligídea',
  ligideo: 'ligídeo',
  ligidio: 'ligídio',
  ligiida: 'ligíida',
  liginas: 'liginas',
  ligiros: 'ligiros',
  lignano: 'lignano',
  ligneas: 'lígneas',
  ligneos: 'lígneos',
  lignina: 'lignina',
  lignita: 'lignita',
  lignite: 'lignite',
  lignito: 'lignito',
  lignose: 'lignose',
  ligodio: 'ligódio',
  ligolas: 'ligolas',
  ligoria: 'ligória',
  ligorio: 'ligório',
  ligueis: 'ligueis',
  liguemo: 'liguemo',
  ligulai: 'ligulai',
  ligulam: 'ligulam',
  ligular: 'ligular',
  ligulas: 'lígulas',
  ligulea: 'ligúlea',
  ligulei: 'ligulei',
  ligulem: 'ligulem',
  liguleo: 'ligúleo',
  ligules: 'ligules',
  ligulos: 'lígulos',
  ligulou: 'ligulou',
  liguras: 'liguras',
  ligures: 'lígures',
  liguria: 'ligúria',
  ligurio: 'ligúrio',
  liguros: 'liguros',
  lijonja: 'lijonja',
  lilacea: 'lilácea',
  lilaceo: 'liláceo',
  lilaias: 'lilaias',
  lilaila: 'lilaila',
  lilasea: 'lilaseá',
  lilases: 'lilases',
  lileias: 'lileias',
  liliais: 'liliais',
  liliale: 'liliale',
  lilinea: 'lilínea',
  lilineo: 'lilíneo',
  limacao: 'limação',
  limacas: 'limaças',
  limaces: 'limaces',
  limadao: 'limadão',
  limadas: 'limadas',
  limador: 'limador',
  limados: 'limados',
  limagem: 'limagem',
  limalha: 'limalha',
  limamos: 'limamos',
  limanas: 'limanas',
  limando: 'limando',
  limanos: 'limanos',
  limante: 'limante',
  limaram: 'limaram',
  limarao: 'limarão',
  limaras: 'limaras',
  limarei: 'limarei',
  limarem: 'limarem',
  limares: 'limares',
  limaria: 'limaria',
  limarmo: 'limarmo',
  limasse: 'limasse',
  limaste: 'limaste',
  limatao: 'limatão',
  limavam: 'limavam',
  limavas: 'limavas',
  limavel: 'limável',
  limbada: 'limbada',
  limbado: 'limbado',
  limbali: 'limbali',
  limbeas: 'límbeas',
  limbeis: 'limbéis',
  limbeos: 'límbeos',
  limbera: 'limbera',
  limbias: 'límbias',
  limbica: 'límbica',
  limbico: 'límbico',
  limbios: 'límbios',
  limbosa: 'limbosa',
  limeira: 'limeira',
  limemos: 'limemos',
  limenha: 'limenha',
  limenho: 'limenho',
  limenos: 'limenos',
  limense: 'limense',
  limento: 'limento',
  limiana: 'limiana',
  limiano: 'limiano',
  limicas: 'límicas',
  limicos: 'límicos',
  limidas: 'límidas',
  limidea: 'limídea',
  limideo: 'limídeo',
  limidos: 'límidos',
  liminar: 'liminar',
  limioes: 'limiões',
  limitai: 'limitai',
  limitam: 'limitam',
  limitar: 'limitar',
  limitas: 'limitas',
  limitei: 'limitei',
  limitem: 'limitem',
  limites: 'limites',
  limitou: 'limitou',
  limneas: 'límneas',
  limneia: 'limneia',
  limnias: 'límnias',
  limnica: 'límnica',
  limnico: 'límnico',
  limnios: 'límnios',
  limnita: 'limnita',
  limnite: 'limnite',
  limnito: 'limnito',
  limoada: 'limoada',
  limoado: 'limoado',
  limoais: 'limoais',
  limobio: 'limóbio',
  limonia: 'limônia',
  limonio: 'limônio',
  limosas: 'limosas',
  limoses: 'limoses',
  limosos: 'limosos',
  limotes: 'limotes',
  limpaca: 'limpaça',
  limpada: 'limpada',
  limpado: 'limpado',
  limpais: 'limpais',
  limpamo: 'limpamo',
  limpara: 'limpara',
  limpava: 'limpava',
  limpeis: 'limpeis',
  limpemo: 'limpemo',
  limpeza: 'limpeza',
  limpias: 'límpias',
  limpida: 'límpida',
  limpido: 'límpido',
  limpios: 'límpios',
  limposa: 'limposa',
  limposo: 'limposo',
  limugem: 'limugem',
  limulos: 'límulos',
  linacea: 'linácea',
  linaceo: 'lináceo',
  linagem: 'linagem',
  linalao: 'linalão',
  linales: 'linales',
  linaloa: 'lináloa',
  linalol: 'linalol',
  linaria: 'linária',
  lincada: 'lincada',
  lincado: 'lincado',
  lincais: 'lincais',
  lincamo: 'lincamo',
  lincara: 'lincará',
  lincava: 'lincava',
  linceas: 'línceas',
  linceos: 'línceos',
  linchai: 'linchai',
  lincham: 'lincham',
  linchar: 'linchar',
  linchas: 'linchas',
  linchei: 'linchei',
  linchem: 'linchem',
  linches: 'linches',
  linchou: 'linchou',
  lindaca: 'lindaça',
  lindaco: 'lindaço',
  lindada: 'lindada',
  lindado: 'lindado',
  lindais: 'lindais',
  lindamo: 'lindamo',
  lindane: 'lindane',
  lindano: 'lindano',
  lindara: 'lindará',
  lindava: 'lindava',
  lindeis: 'lindeis',
  lindemo: 'lindemo',
  lindera: 'lindera',
  lindeza: 'lindeza',
  lindice: 'lindice',
  lindoes: 'lindões',
  lindona: 'lindona',
  lindota: 'lindota',
  lindote: 'lindote',
  lindura: 'lindura',
  lineada: 'lineada',
  lineado: 'lineado',
  lineais: 'lineais',
  lineana: 'lineana',
  lineano: 'lineano',
  lineias: 'lineias',
  lineica: 'lineica',
  lineico: 'lineico',
  lineida: 'lineida',
  lineita: 'lineíta',
  lineite: 'lineíte',
  linense: 'linense',
  linetes: 'linetes',
  linfada: 'linfada',
  linfado: 'linfado',
  linfais: 'linfais',
  linfamo: 'linfamo',
  linfara: 'linfará',
  linfava: 'linfava',
  linfeis: 'linfeis',
  linfemo: 'linfemo',
  linfite: 'linfite',
  linfoma: 'linfoma',
  linfose: 'linfose',
  lingada: 'lingada',
  lingado: 'lingado',
  lingais: 'lingais',
  lingala: 'lingala',
  lingame: 'língame',
  lingara: 'lingará',
  lingava: 'lingavá',
  lingbia: 'lingbia',
  lingeis: 'língéis',
  lingoes: 'lingões',
  lingone: 'lingone',
  lingota: 'lingota',
  lingote: 'lingote',
  lingoto: 'lingoto',
  lingres: 'lingres',
  lingual: 'lingual',
  linguas: 'línguas',
  linguei: 'linguei',
  linguem: 'linguem',
  lingues: 'lingues',
  lingula: 'língula',
  linguna: 'linguna',
  linhaca: 'linhaça',
  linhada: 'linhada',
  linhais: 'linhais',
  linhita: 'linhita',
  linhite: 'linhite',
  linhito: 'linhito',
  linhoes: 'linhões',
  linhois: 'linhóis',
  linhosa: 'linhosa',
  linhoso: 'linhoso',
  linhote: 'linhote',
  linicas: 'línicas',
  linicos: 'línicos',
  linifia: 'linífia',
  lininas: 'lininas',
  linisco: 'linisco',
  linites: 'linites',
  linneia: 'linneia',
  linoleo: 'linóleo',
  linomas: 'linomas',
  linoses: 'linoses',
  linquei: 'linquei',
  linquem: 'linquem',
  linques: 'linques',
  linquia: 'línquia',
  linteas: 'línteas',
  linteis: 'linteis',
  lintias: 'líntias',
  linuras: 'linuras',
  liobata: 'lióbata',
  liobuno: 'liobuno',
  lioceto: 'lioceto',
  liocoma: 'liócoma',
  liocomo: 'liócomo',
  liodida: 'liódida',
  liofila: 'liófila',
  liofilo: 'liófilo',
  liofoba: 'liófoba',
  liofobo: 'liófobo',
  liolemo: 'liolemo',
  liolise: 'liólise',
  liomera: 'liômera',
  liomero: 'liômero',
  liomeso: 'liômeso',
  lionesa: 'lionesa',
  lionias: 'liônias',
  lionica: 'liônica',
  lionico: 'liônico',
  lionoto: 'lionoto',
  lionsia: 'liônsia',
  lionuro: 'lionuro',
  liopica: 'liopica',
  liopico: 'liopico',
  liopila: 'liópila',
  liopilo: 'liópilo',
  lioplax: 'lióplax',
  liopode: 'liópode',
  liopoma: 'liopoma',
  lioporo: 'lióporo',
  liornas: 'liornas',
  liornes: 'liornês',
  lioteas: 'lióteas',
  lioteos: 'lióteos',
  liotias: 'liótias',
  liotrix: 'liótrix',
  liotula: 'liotula',
  lipango: 'lipango',
  lipares: 'lípares',
  lipareu: 'lipareu',
  liparia: 'lipária',
  liparis: 'líparis',
  liparos: 'líparos',
  lipases: 'lipases',
  lipates: 'lipates',
  lipatos: 'lipatos',
  lipejas: 'lipejas',
  lipemia: 'lipemia',
  lipeuro: 'lipeúro',
  lipicas: 'lípicas',
  lipicos: 'lípicos',
  lipidea: 'lipídea',
  lipideo: 'lipídeo',
  lipides: 'lípides',
  lipidio: 'lipídio',
  lipidos: 'lípidos',
  lipinas: 'lipinas',
  lipiria: 'lipíria',
  lipoato: 'lipoato',
  lipoide: 'lipoide',
  lipomas: 'lipomas',
  liposas: 'liposas',
  liposes: 'liposes',
  liposos: 'liposos',
  lipotes: 'lipotes',
  lippias: 'líppias',
  lipupos: 'lipupos',
  lipuria: 'lipuria',
  liputos: 'liputos',
  liquene: 'líquene',
  liqueno: 'liqueno',
  liquens: 'líquens',
  liquias: 'líquias',
  liquida: 'líquida',
  liquide: 'liquide',
  liquido: 'líquido',
  liracas: 'liraças',
  liradas: 'liradas',
  lirados: 'lirados',
  liriais: 'liriais',
  liricai: 'liricai',
  liricam: 'liricam',
  liricar: 'liricar',
  liricas: 'líricas',
  liricos: 'líricos',
  liricou: 'liricou',
  lirinas: 'lirinas',
  lirinos: 'lirinos',
  liriola: 'liriola',
  liriope: 'liríope',
  lirique: 'lirique',
  lirismo: 'lirismo',
  lirista: 'lirista',
  liriste: 'liriste',
  lirodos: 'lirodos',
  lisacao: 'lisação',
  lisadas: 'lisadas',
  lisador: 'lisador',
  lisados: 'lisados',
  lisavel: 'lisável',
  lisboas: 'lisboas',
  lisboes: 'lisboês',
  liscada: 'liscada',
  liscado: 'liscado',
  liscais: 'liscais',
  liscamo: 'liscamo',
  liscara: 'liscará',
  liscava: 'liscava',
  liseira: 'liseirã',
  lisemia: 'lisemia',
  liseres: 'liseres',
  liseuse: 'liseuse',
  lisiaca: 'lisíaca',
  lisiaco: 'lisíaco',
  lisilas: 'lisilas',
  lisinas: 'lisinas',
  lisinha: 'lisinha',
  lisinho: 'lisinho',
  lismada: 'lismada',
  lismado: 'lismado',
  lismais: 'lismais',
  lismamo: 'lismamo',
  lismara: 'lismara',
  lismava: 'lismava',
  lismeis: 'lismeis',
  lismemo: 'lismemo',
  lisonja: 'lisonja',
  lisonje: 'lisonje',
  lisonjo: 'lisonjo',
  lispras: 'lispras',
  lispros: 'lispros',
  lisquei: 'lisquei',
  lisquem: 'lisquem',
  lisques: 'lisques',
  listada: 'listada',
  listado: 'listado',
  listais: 'listais',
  listamo: 'listamo',
  listara: 'listara',
  listava: 'listava',
  listeis: 'listeis',
  listelo: 'listelo',
  listemo: 'listemo',
  listera: 'listera',
  listese: 'listese',
  listoes: 'listões',
  listrai: 'listrai',
  listram: 'listram',
  listrao: 'listrão',
  listrar: 'listrar',
  listras: 'listras',
  listrei: 'listrei',
  listrem: 'listrem',
  listres: 'listres',
  listrio: 'lístrio',
  listrou: 'listrou',
  lisuras: 'lisuras',
  lisuros: 'lisuros',
  litacao: 'litação',
  litadao: 'litadão',
  litadas: 'litadas',
  litador: 'litador',
  litados: 'litados',
  litamos: 'litamos',
  litando: 'litando',
  litania: 'litania',
  litante: 'litante',
  litanto: 'litanto',
  litaram: 'litaram',
  litarao: 'litarão',
  litaras: 'litarás',
  litarei: 'litarei',
  litarem: 'litarem',
  litares: 'litares',
  litargo: 'litargo',
  litaria: 'litaria',
  litarmo: 'litarmo',
  litasse: 'litasse',
  litaste: 'litaste',
  litavam: 'litavam',
  litavas: 'litavas',
  litchis: 'litchis',
  liteira: 'liteira',
  liteiro: 'liteiro',
  litelio: 'litélio',
  litemia: 'litemia',
  litemos: 'litemos',
  literal: 'literal',
  literas: 'líteras',
  litiase: 'litíase',
  liticai: 'liticai',
  liticam: 'liticam',
  liticar: 'liticar',
  liticas: 'líticas',
  liticos: 'líticos',
  liticou: 'liticou',
  litidas: 'lítidas',
  litidea: 'litídea',
  litideo: 'litídeo',
  litigai: 'litigai',
  litigam: 'litigam',
  litigar: 'litigar',
  litigas: 'litigas',
  litigia: 'litigia',
  litigie: 'litigie',
  litigio: 'litígio',
  litigou: 'litigou',
  litigue: 'litigue',
  litinas: 'litinas',
  litinea: 'litínea',
  litineo: 'litíneo',
  litinha: 'litinha',
  litinho: 'litinho',
  litiopa: 'litíopa',
  litique: 'litique',
  litites: 'litites',
  lititos: 'lititos',
  litobio: 'litóbio',
  litodes: 'litodes',
  litoica: 'litoica',
  litoico: 'litoico',
  litoide: 'litoide',
  litonde: 'litonde',
  litondo: 'litondo',
  litopes: 'litopes',
  litoral: 'litoral',
  litoras: 'litoras',
  litorea: 'litórea',
  litoreo: 'litóreo',
  litores: 'litores',
  litoria: 'litória',
  litorio: 'litório',
  litoses: 'litoses',
  litosia: 'litósia',
  litotes: 'litotes',
  litotis: 'litótis',
  litreas: 'lítreas',
  litreia: 'litreia',
  litrias: 'lítrias',
  litrite: 'litrite',
  litseas: 'lítseas',
  lituada: 'lituada',
  lituado: 'lituado',
  lituana: 'lituana',
  lituano: 'lituano',
  lituola: 'litúola',
  lituras: 'lituras',
  liturgo: 'liturgo',
  lituria: 'lituria',
  liurais: 'liurais',
  liurana: 'liurana',
  liurano: 'liurano',
  livedos: 'livedos',
  livelai: 'livelai',
  livelam: 'livelam',
  livelar: 'livelar',
  livelas: 'livelas',
  livelei: 'livelei',
  livelem: 'livelem',
  liveles: 'liveles',
  livelou: 'livelou',
  livetas: 'livetas',
  livicas: 'lívicas',
  livicos: 'lívicos',
  lividai: 'lividai',
  lividam: 'lividam',
  lividar: 'lividar',
  lividas: 'lívidas',
  lividei: 'lividei',
  lividem: 'lividem',
  livides: 'livides',
  lividez: 'lividez',
  lividos: 'lívidos',
  lividou: 'lividou',
  livonas: 'livonas',
  livonia: 'livônia',
  livonio: 'livônio',
  livores: 'livores',
  livrada: 'livrada',
  livrado: 'livrado',
  livrais: 'livrais',
  livramo: 'livramo',
  livrara: 'livrara',
  livrava: 'livrava',
  livreco: 'livreco',
  livreis: 'livreis',
  livremo: 'livremo',
  livreta: 'livreta',
  livrete: 'livrete',
  livreto: 'livreto',
  livusia: 'livusia',
  lixacao: 'lixação',
  lixadao: 'lixadão',
  lixadas: 'lixadas',
  lixador: 'lixador',
  lixados: 'lixados',
  lixamos: 'lixamos',
  lixanco: 'lixanço',
  lixando: 'lixando',
  lixante: 'lixante',
  lixaram: 'lixaram',
  lixarao: 'lixarão',
  lixaras: 'lixaras',
  lixarei: 'lixarei',
  lixarem: 'lixarem',
  lixares: 'lixares',
  lixaria: 'lixaria',
  lixarmo: 'lixarmo',
  lixasse: 'lixasse',
  lixaste: 'lixaste',
  lixavam: 'lixavam',
  lixavas: 'lixavas',
  lixeira: 'lixeira',
  lixeiro: 'lixeiro',
  lixemos: 'lixemos',
  lixenta: 'lixenta',
  lixento: 'lixento',
  lixinha: 'lixinha',
  lixinho: 'lixinho',
  lixivia: 'lixívia',
  lixivie: 'lixivie',
  lixivio: 'lixívio',
  lixosas: 'lixosas',
  lixoses: 'lixoses',
  lixosos: 'lixosos',
  lizadao: 'lizadão',
  lizadas: 'lizadas',
  lizador: 'lizador',
  lizados: 'lizados',
  lizamos: 'lizamos',
  lizando: 'lizando',
  lizante: 'lizante',
  lizaram: 'lizaram',
  lizarao: 'lizarão',
  lizaras: 'lizarás',
  lizarei: 'lizarei',
  lizarem: 'lizarem',
  lizares: 'lizares',
  lizaria: 'lizaria',
  lizarmo: 'lizarmo',
  lizasse: 'lizasse',
  lizaste: 'lizaste',
  lizavam: 'lizavam',
  lizavas: 'lizavas',
  lizelas: 'lizelas',
  lizemos: 'lizemos',
  lizusas: 'lizusas',
  loacoes: 'loações',
  loacona: 'loaçona',
  loadoes: 'loadões',
  loadona: 'loadona',
  loadora: 'loadora',
  loalina: 'loalina',
  loangas: 'loangas',
  loangos: 'loangos',
  loantes: 'loantes',
  loaozao: 'loãozão',
  loardes: 'loardes',
  loareis: 'loareis',
  loaremo: 'loaremo',
  loariam: 'loariam',
  loarias: 'loarias',
  loarmos: 'loarmos',
  loaseas: 'loáseas',
  loaseos: 'loáseos',
  loassem: 'loassem',
  loasses: 'loasses',
  loastes: 'loastes',
  loaveis: 'loáveis',
  lobacho: 'lobacho',
  lobadas: 'lobadas',
  lobados: 'lobados',
  lobalia: 'lobália',
  lobares: 'lobares',
  lobaria: 'lobária',
  lobarro: 'lobarro',
  lobatos: 'lobatos',
  lobazes: 'lobazes',
  lobecao: 'lobecão',
  lobegao: 'lobegão',
  lobeira: 'lobeira',
  lobeiro: 'lobeiro',
  lobelia: 'lobélia',
  lobelio: 'lobélio',
  lobenas: 'lobenas',
  lobenos: 'lobenos',
  lobense: 'lobense',
  lobetes: 'lobetes',
  lobetos: 'lobetos',
  lobezas: 'lobezas',
  lobidos: 'lobidos',
  lobiger: 'lóbiger',
  lobinas: 'lobinas',
  lobinha: 'lobinha',
  lobinho: 'lobinho',
  lobismo: 'lobismo',
  lobista: 'lobista',
  lobites: 'lobites',
  lobitos: 'lobitos',
  lobodon: 'lóbodon',
  lobolai: 'lobolai',
  lobolam: 'lobolam',
  lobolar: 'lobolar',
  lobolas: 'lobolas',
  lobolei: 'lobolei',
  lobolem: 'lobolem',
  loboles: 'loboles',
  lobolou: 'lobolou',
  lobonas: 'lobonas',
  lobonix: 'lóbonix',
  lobosas: 'lobosas',
  lobosos: 'lobosos',
  lobotes: 'lobotes',
  lobotos: 'lobotos',
  lobrega: 'lobrega',
  lobrego: 'lôbrego',
  lobriga: 'lobriga',
  lobrigo: 'lobrigo',
  lobular: 'lobular',
  lobulos: 'lóbulos',
  lobunas: 'lobunas',
  lobunos: 'lobunos',
  locacao: 'locação',
  locadao: 'locadão',
  locadas: 'locadas',
  locador: 'locador',
  locados: 'locados',
  locafas: 'locafas',
  locagos: 'locagos',
  locaias: 'locaias',
  locaina: 'locaina',
  locamos: 'locamos',
  locanda: 'locanda',
  locando: 'locando',
  locante: 'locante',
  locaram: 'locaram',
  locarao: 'locarão',
  locaras: 'locaras',
  locarei: 'locarei',
  locarem: 'locarem',
  locares: 'locares',
  locaria: 'locaria',
  locario: 'locário',
  locarmo: 'locarmo',
  locasse: 'locasse',
  locaste: 'locaste',
  locaute: 'locaute',
  locavam: 'locavam',
  locavas: 'locavas',
  locavel: 'locável',
  locelos: 'locelos',
  lociona: 'lociona',
  locione: 'locione',
  lociono: 'lociono',
  lockout: 'lockout',
  locmeia: 'locmeia',
  locmias: 'lócmias',
  locmios: 'lócmios',
  locnera: 'locnera',
  loconas: 'loçonas',
  loconde: 'loconde',
  locondi: 'locondi',
  locotes: 'locotes',
  locrias: 'lócrias',
  locrios: 'lócrios',
  loctais: 'loctais',
  locucao: 'locução',
  locular: 'locular',
  loculos: 'lóculos',
  locundo: 'locundo',
  locuris: 'locuris',
  locusta: 'locusta',
  locutor: 'locutor',
  lodacal: 'lodaçal',
  lodacas: 'lodaças',
  lodeira: 'lodeira',
  lodeiro: 'lodeiro',
  lodenta: 'lodenta',
  lodento: 'lodento',
  lodosas: 'lodosas',
  lodosos: 'lodosos',
  loendas: 'loendas',
  loendro: 'loendro',
  loengos: 'loengos',
  loesses: 'loesses',
  loestes: 'loestes',
  lofiida: 'lofíida',
  lofinas: 'lofinas',
  lofiras: 'lófiras',
  lofirea: 'lofírea',
  lofiros: 'lófiros',
  lofoeto: 'lofoeto',
  lofomas: 'lofomas',
  lofopos: 'lófopos',
  lofotes: 'lofotes',
  lofotis: 'lofótis',
  lofotos: 'lofotos',
  lofuras: 'lofuras',
  lofuros: 'lofuros',
  logadas: 'logadas',
  logados: 'logados',
  logamos: 'logamos',
  logando: 'logando',
  loganea: 'logânea',
  loganeo: 'logâneo',
  logania: 'logânia',
  logaram: 'logaram',
  logarao: 'logarão',
  logarei: 'logarei',
  logarem: 'logarem',
  logares: 'logares',
  logaria: 'logaria',
  logarmo: 'logarmo',
  logasse: 'logasse',
  logaste: 'logaste',
  logavam: 'logavam',
  logavas: 'logavas',
  logicai: 'logicai',
  logical: 'logical',
  logicam: 'logicam',
  logicar: 'logicar',
  logicas: 'lógicas',
  logicos: 'lógicos',
  logicou: 'logicou',
  logique: 'logique',
  logismo: 'logismo',
  logista: 'logista',
  logorra: 'logorra',
  logorre: 'logorre',
  logorro: 'logorro',
  logouts: 'logouts',
  lograda: 'lograda',
  logrado: 'logrado',
  lograis: 'lograis',
  logramo: 'logramo',
  lograra: 'lograra',
  lograva: 'lograva',
  logreis: 'logreis',
  logremo: 'logremo',
  logroes: 'logrões',
  logrona: 'logrona',
  loiases: 'loíases',
  loimias: 'loimias',
  loiolas: 'loiolas',
  loiraca: 'loiraça',
  loiraco: 'loiraço',
  loirada: 'loirada',
  loirado: 'loirado',
  loirais: 'loirais',
  loiramo: 'loiramo',
  loirara: 'loirará',
  loirava: 'loirava',
  loirear: 'loirear',
  loireca: 'loireça',
  loirece: 'loirece',
  loireci: 'loireci',
  loireco: 'loireço',
  loireis: 'loireis',
  loireja: 'loireja',
  loireje: 'loireje',
  loirejo: 'loirejo',
  loirela: 'loirela',
  loiremo: 'loiremo',
  loiroes: 'loirões',
  loirona: 'loirona',
  loisada: 'loisada',
  loisado: 'loisado',
  loisais: 'loisais',
  loisamo: 'loisamo',
  loisara: 'loisará',
  loisava: 'loisava',
  loiseis: 'loiseis',
  loisemo: 'loisemo',
  loisoes: 'loisões',
  lojecas: 'lojecas',
  lojeira: 'lojeira',
  lojeiro: 'lojeiro',
  lojicas: 'lojicas',
  lojinha: 'lojinha',
  lojista: 'lojista',
  lolanas: 'lolanas',
  lolarda: 'lolarda',
  lolardo: 'lolardo',
  loligos: 'lóligos',
  lolioto: 'lolioto',
  lolismo: 'lolismo',
  lolista: 'lolista',
  lomanco: 'lomanco',
  lomanis: 'lomanis',
  lomaria: 'lomária',
  lomatia: 'lomátia',
  lombada: 'lombada',
  lombais: 'lombais',
  lombeai: 'lombeai',
  lombear: 'lombear',
  lombeei: 'lombeei',
  lombeia: 'lombeia',
  lombeie: 'lombeie',
  lombeio: 'lombeio',
  lombelo: 'lombelo',
  lombeou: 'lombeou',
  lombiai: 'lombiai',
  lombiam: 'lombiam',
  lombiar: 'lombiar',
  lombias: 'lombias',
  lombiei: 'lombiei',
  lombiem: 'lombiem',
  lombies: 'lombies',
  lombiou: 'lombiou',
  lombras: 'lombras',
  lombuda: 'lombuda',
  lombudo: 'lombudo',
  lomedro: 'lomedro',
  lomento: 'lomento',
  lonadas: 'lonadas',
  lonados: 'lonados',
  loncuro: 'loncuro',
  londavo: 'londavo',
  londobo: 'londobo',
  londoes: 'londões',
  londrea: 'londreá',
  londuns: 'londuns',
  longada: 'longada',
  longado: 'longado',
  longais: 'longais',
  longana: 'longana',
  longeva: 'longeva',
  longevo: 'longevo',
  longina: 'longina',
  longuim: 'longuim',
  longura: 'longura',
  lonitas: 'lonitas',
  lonjura: 'lonjura',
  lonomia: 'lonômia',
  lonquea: 'lonqueá',
  lontras: 'lontras',
  lontros: 'lontros',
  looping: 'looping',
  looques: 'looques',
  lopadas: 'lopadas',
  lopados: 'lopados',
  lopanas: 'lopanas',
  lopanos: 'lopanos',
  lopense: 'lopense',
  lopesco: 'lopesco',
  lopesia: 'lopésia',
  lopezia: 'lopézia',
  loqueis: 'loqueis',
  loquela: 'loquela',
  loquemo: 'loquemo',
  loquete: 'loquete',
  loquial: 'loquial',
  loquios: 'lóquios',
  loracas: 'loraças',
  loracos: 'loraços',
  loranto: 'loranto',
  loraria: 'lorária',
  lorario: 'lorário',
  lorcada: 'lorcada',
  lorcado: 'lorcado',
  lorcais: 'lorcais',
  lorcamo: 'lorcamo',
  lorcara: 'lorcara',
  lorcava: 'lorcava',
  lorchas: 'lorchas',
  lordaca: 'lordaça',
  lordaco: 'lordaço',
  lordada: 'lordada',
  lordado: 'lordado',
  lordais: 'lordais',
  lordamo: 'lordamo',
  lordara: 'lordara',
  lordava: 'lordava',
  lordeis: 'lordeis',
  lordemo: 'lordemo',
  lordeza: 'lordeza',
  lordoma: 'lordoma',
  lordops: 'lórdops',
  lordose: 'lordose',
  loreais: 'loreais',
  loreias: 'loreias',
  lorenas: 'lorenas',
  lorenes: 'lorenês',
  lorenos: 'lorenos',
  loretas: 'loretas',
  lorgada: 'lorgada',
  lorgado: 'lorgado',
  lorgais: 'lorgais',
  lorgara: 'lorgará',
  lorgava: 'lorgava',
  lorgnon: 'lorgnon',
  lorguei: 'lorguei',
  lorguem: 'lorguem',
  lorgues: 'lorgues',
  loricas: 'loricas',
  loricos: 'loricos',
  loridea: 'lorídea',
  lorideo: 'lorídeo',
  lorigao: 'lorigão',
  lorigas: 'lorigas',
  loriois: 'lorióis',
  lormana: 'lormana',
  lormano: 'lormano',
  lornhao: 'lornhão',
  loronga: 'loronga',
  lorongo: 'lorongo',
  lorosas: 'lorosas',
  lorotai: 'lorotai',
  lorotam: 'lorotam',
  lorotar: 'lorotar',
  lorotas: 'lorotas',
  lorotei: 'lorotei',
  lorotem: 'lorotem',
  lorotes: 'lorotes',
  lorotou: 'lorotou',
  lorpice: 'lorpice',
  lorquei: 'lorquei',
  lorquem: 'lorquem',
  lorques: 'lorques',
  lorulos: 'lórulos',
  losaica: 'losaica',
  losaico: 'losaico',
  losanga: 'losanga',
  losango: 'losango',
  losanja: 'losanja',
  losbias: 'lósbias',
  losinha: 'losinha',
  losinho: 'losinho',
  losismo: 'losismo',
  lossapo: 'lossapo',
  lostras: 'lostras',
  lotacao: 'lotação',
  lotadao: 'lotadão',
  lotadas: 'lotadas',
  lotador: 'lotador',
  lotados: 'lotados',
  lotamos: 'lotamos',
  lotando: 'lotando',
  lotante: 'lotante',
  lotaram: 'lotaram',
  lotarao: 'lotarão',
  lotaras: 'lotaras',
  lotarei: 'lotarei',
  lotarem: 'lotarem',
  lotares: 'lotares',
  lotaria: 'lotaria',
  lotarmo: 'lotarmo',
  lotases: 'lotases',
  lotasse: 'lotasse',
  lotaste: 'lotaste',
  lotavam: 'lotavam',
  lotavas: 'lotavas',
  loteada: 'loteada',
  loteado: 'loteado',
  loteais: 'loteais',
  loteamo: 'loteamo',
  loteara: 'loteara',
  loteava: 'loteava',
  lotecas: 'lotecas',
  loteeis: 'loteeis',
  loteemo: 'loteemo',
  loteiam: 'loteiam',
  loteias: 'loteias',
  loteiem: 'loteiem',
  loteies: 'loteies',
  loteira: 'loteira',
  loteiro: 'loteiro',
  lotejai: 'lotejai',
  lotejam: 'lotejam',
  lotejar: 'lotejar',
  lotejas: 'lotejas',
  lotejei: 'lotejei',
  lotejem: 'lotejem',
  lotejes: 'lotejes',
  lotejou: 'lotejou',
  lotelas: 'lotelas',
  lotemos: 'lotemos',
  loteria: 'loteria',
  loticas: 'lóticas',
  loticos: 'lóticos',
  lotinea: 'lotínea',
  lotineo: 'lotíneo',
  lotinho: 'lotinho',
  lotoide: 'lotoide',
  lotrita: 'lotrita',
  lotrite: 'lotrite',
  loucana: 'louçana',
  loucoes: 'loucões',
  loucona: 'loucona',
  loucura: 'loucura',
  loudeis: 'loudéis',
  lounges: 'lounges',
  louquea: 'louqueá',
  louraca: 'louraça',
  louraco: 'louraço',
  lourada: 'lourada',
  lourado: 'lourado',
  lourais: 'lourais',
  louramo: 'louramo',
  lourara: 'lourará',
  lourava: 'lourava',
  lourear: 'lourear',
  loureca: 'loureça',
  lourece: 'lourece',
  loureci: 'loureci',
  loureco: 'loureço',
  loureis: 'loureis',
  loureja: 'loureja',
  loureje: 'loureje',
  lourejo: 'lourejo',
  lourela: 'lourela',
  louremo: 'louremo',
  louroes: 'lourões',
  lousada: 'lousada',
  lousado: 'lousado',
  lousais: 'lousais',
  lousamo: 'lousamo',
  lousara: 'lousará',
  lousava: 'lousava',
  louseis: 'louseis',
  lousemo: 'lousemo',
  lousiar: 'lousiar',
  lousoes: 'lousões',
  loutias: 'loutiás',
  louvada: 'louvada',
  louvado: 'louvado',
  louvais: 'louvais',
  louvamo: 'louvamo',
  louvara: 'louvara',
  louvava: 'louvava',
  louveis: 'louveis',
  louvemo: 'louvemo',
  lovaita: 'lovaíta',
  lovales: 'lovales',
  lovenia: 'lovênia',
  loxodes: 'loxodes',
  loxodon: 'lóxodon',
  lozarra: 'lozarra',
  lualaba: 'lualaba',
  lualuas: 'lualuás',
  luancos: 'luancos',
  luandas: 'luandas',
  luandes: 'luandês',
  luandos: 'luandos',
  luangas: 'luangas',
  luangos: 'luangos',
  luareja: 'luareja',
  luareje: 'luareje',
  luarejo: 'luarejo',
  luarias: 'luárias',
  luarios: 'luários',
  luariza: 'luariza',
  luarize: 'luarize',
  luarizo: 'luarizo',
  lubambo: 'lubambo',
  lubanga: 'lubanga',
  lubango: 'lubango',
  lubanol: 'lubanol',
  lubenas: 'lubenas',
  lubenos: 'lubenos',
  lubiena: 'lubiena',
  lubieno: 'lubieno',
  lubinas: 'lubinas',
  lubinia: 'lubínia',
  lubires: 'lubires',
  lubitos: 'lubitos',
  lubobas: 'lubobas',
  lubobos: 'lubobos',
  lubongo: 'lubongo',
  lubrica: 'lúbrica',
  lubrico: 'lúbrico',
  lubrigo: 'lúbrigo',
  lubrina: 'lubrina',
  lubunza: 'lubunza',
  lucacos: 'lucaços',
  lucaias: 'lucaias',
  lucaios: 'lucaios',
  lucanas: 'lucanas',
  lucanda: 'lucanda',
  lucanga: 'lucanga',
  lucange: 'lucange',
  lucango: 'lucango',
  lucania: 'lucânia',
  lucanos: 'lucanos',
  lucarna: 'lucarna',
  lucasso: 'lucasso',
  lucatas: 'lucatas',
  lucejai: 'lucejai',
  lucejam: 'lucejam',
  lucejar: 'lucejar',
  lucejas: 'lucejas',
  lucejei: 'lucejei',
  lucejem: 'lucejem',
  lucejes: 'lucejes',
  lucejou: 'lucejou',
  lucenas: 'lucenas',
  lucenos: 'lucenos',
  lucense: 'lucense',
  lucente: 'lucente',
  luceque: 'luceque',
  luceras: 'lúceras',
  luceres: 'lúceres',
  lucerna: 'lucerna',
  lucerne: 'lucerne',
  lucerno: 'lucerno',
  luceros: 'lúceros',
  luchada: 'luchada',
  luchado: 'luchado',
  luchais: 'luchais',
  luchamo: 'luchamo',
  luchans: 'luchans',
  luchara: 'luchará',
  luchava: 'luchava',
  luchaze: 'luchaze',
  lucheis: 'lucheis',
  luchemo: 'luchemo',
  luchosa: 'luchosa',
  luchoso: 'luchoso',
  luciana: 'luciana',
  lucidai: 'lucidai',
  lucidam: 'lucidam',
  lucidar: 'lucidar',
  lucidas: 'lúcidas',
  lucidei: 'lucidei',
  lucidem: 'lucidem',
  lucides: 'lucides',
  lucidez: 'lucidez',
  lucidol: 'lucidol',
  lucidos: 'lúcidos',
  lucidou: 'lucidou',
  lucifer: 'lúcifer',
  lucilai: 'lucilai',
  lucilam: 'lucilam',
  lucilar: 'lucilar',
  lucilas: 'lucilas',
  lucilei: 'lucilei',
  lucilem: 'lucilem',
  luciles: 'luciles',
  lucilia: 'lucília',
  lucilos: 'lúcilos',
  lucilou: 'lucilou',
  luciluz: 'luciluz',
  lucinas: 'lucinas',
  lucinea: 'lucínea',
  lucineo: 'lucíneo',
  lucinha: 'lucinha',
  luciola: 'lucíola',
  luciole: 'luciole',
  luciolo: 'lucíolo',
  lucites: 'lucites',
  luciveu: 'lucivéu',
  lucmoes: 'lucmões',
  luconjo: 'luconjo',
  lucrada: 'lucrada',
  lucrado: 'lucrado',
  lucrais: 'lucrais',
  lucramo: 'lucramo',
  lucrara: 'lucrara',
  lucrava: 'lucrava',
  lucreis: 'lucreis',
  lucremo: 'lucremo',
  lucrina: 'lucrina',
  lucrino: 'lucrino',
  lucrosa: 'lucrosa',
  lucroso: 'lucroso',
  lucubra: 'lucubra',
  lucubre: 'lucubre',
  lucubro: 'lucubro',
  luculas: 'lúculas',
  luculea: 'lucúlea',
  luculeo: 'lucúleo',
  luculia: 'lucúlia',
  luculio: 'lucúlio',
  luculos: 'lúculos',
  lucumao: 'lucumão',
  lucumas: 'lucumas',
  lucumbi: 'lucúmbi',
  lucumos: 'lúcumos',
  ludares: 'ludares',
  luddita: 'luddita',
  ludiana: 'ludiana',
  ludiano: 'ludiano',
  ludicas: 'lúdicas',
  ludicos: 'lúdicos',
  ludicra: 'lúdicra',
  ludicro: 'lúdicro',
  ludinha: 'ludinha',
  ludinho: 'ludinho',
  ludioes: 'ludiões',
  ludismo: 'ludismo',
  ludista: 'ludista',
  luditas: 'luditas',
  ludovia: 'ludóvia',
  ludrica: 'lúdrica',
  ludrico: 'lúdrico',
  ludrosa: 'ludrosa',
  ludroso: 'ludroso',
  luengas: 'luengas',
  luengos: 'luengos',
  lueques: 'lueques',
  luetica: 'luética',
  luetico: 'luético',
  luetina: 'luetina',
  lufadao: 'lufadão',
  lufadas: 'lufadas',
  lufador: 'lufador',
  lufados: 'lufados',
  lufamos: 'lufamos',
  lufando: 'lufando',
  lufante: 'lufante',
  lufaram: 'lufaram',
  lufarao: 'lufarão',
  lufaras: 'lufaras',
  lufarei: 'lufarei',
  lufarem: 'lufarem',
  lufares: 'lufares',
  lufaria: 'lufária',
  lufarmo: 'lufarmo',
  lufasse: 'lufasse',
  lufaste: 'lufaste',
  lufavam: 'lufavam',
  lufavas: 'lufavas',
  lufemos: 'lufemos',
  lufense: 'lufense',
  luficas: 'luficas',
  lufumas: 'lufumas',
  lugadao: 'lugadão',
  lugadas: 'lugadas',
  lugador: 'lugador',
  lugados: 'lugados',
  lugamos: 'lugamos',
  luganda: 'luganda',
  lugando: 'lugando',
  lugante: 'lugante',
  lugaram: 'lugaram',
  lugarao: 'lugarão',
  lugaras: 'lugaras',
  lugarei: 'lugarei',
  lugarem: 'lugarem',
  lugares: 'lugares',
  lugaria: 'lugaria',
  lugasse: 'lugasse',
  lugaste: 'lugaste',
  lugavam: 'lugavam',
  lugavas: 'lugavas',
  lugente: 'lugente',
  lugisos: 'lugisos',
  lugrebe: 'lúgrebe',
  lugubre: 'lúgubre',
  lugueis: 'lugueis',
  luibaba: 'luibaba',
  luidias: 'luídias',
  luigita: 'luigita',
  luiidas: 'luíidas',
  luiidea: 'luiidea',
  luiideo: 'luiideo',
  luiidia: 'luiidia',
  luimbes: 'luimbes',
  luimbis: 'luimbis',
  luinhas: 'luinhas',
  luisias: 'luísias',
  luisina: 'luisina',
  luisita: 'luisita',
  luisite: 'luisite',
  luitado: 'luitado',
  luitais: 'luitais',
  luitara: 'luitará',
  luitava: 'luitava',
  luiteis: 'luiteis',
  lujanja: 'lujanja',
  luliana: 'luliana',
  luliano: 'luliano',
  lulinha: 'lulinha',
  lulismo: 'lulismo',
  lulista: 'lulista',
  lulongo: 'lulongo',
  lululas: 'lululas',
  lulundo: 'lulundo',
  lumache: 'lumache',
  lumaica: 'lumaica',
  lumaico: 'lumaico',
  lumarao: 'lumarão',
  lumareu: 'lumaréu',
  lumbago: 'lumbago',
  lumbuda: 'lumbuda',
  lumbudo: 'lumbudo',
  lumeira: 'lumeira',
  lumeiro: 'lumeiro',
  lumenes: 'lúmenes',
  lumeque: 'lumeque',
  lumetos: 'lumetos',
  lumiaco: 'lumiaco',
  lumiada: 'lumiada',
  lumiado: 'lumiado',
  lumiais: 'lumiais',
  lumiamo: 'lumiamo',
  lumiara: 'lumiará',
  lumiava: 'lumiava',
  lumieis: 'lumieis',
  lumiemo: 'lumiemo',
  lumilho: 'lumilho',
  luminal: 'luminal',
  luminar: 'luminar',
  luminea: 'lumínea',
  lumineo: 'lumíneo',
  lumines: 'lumines',
  luminol: 'luminol',
  lumiosa: 'lumiosa',
  lumioso: 'lumioso',
  lumpens: 'lúmpens',
  lumuino: 'lumuíno',
  lunacao: 'lunação',
  lunadas: 'lunadas',
  lunados: 'lunados',
  lunanca: 'lunanca',
  lunanco: 'lunanco',
  lunania: 'lunânia',
  lunares: 'lunares',
  lunaria: 'lunária',
  lunario: 'lunário',
  lunauta: 'lunauta',
  lundesa: 'lundesa',
  lundias: 'lúndias',
  lunduns: 'lunduns',
  lunetas: 'lunetas',
  lunguie: 'lunguié',
  lungula: 'lungula',
  lungule: 'lungule',
  lungulo: 'lungulo',
  lunidas: 'lunidas',
  lunitas: 'lunitas',
  lunites: 'lunites',
  lunnita: 'lunnita',
  lunulai: 'lunulai',
  lunulam: 'lunulam',
  lunular: 'lunular',
  lunulas: 'lúnulas',
  lunulei: 'lunulei',
  lunulem: 'lunulem',
  lunules: 'lunules',
  lunulou: 'lunulou',
  lunzete: 'lunzete',
  lupacas: 'lupaças',
  lupadao: 'lupadão',
  lupadas: 'lupadas',
  lupador: 'lupador',
  lupados: 'lupados',
  lupamba: 'lupamba',
  lupamos: 'lupamos',
  lupanai: 'lupanai',
  lupanam: 'lupanam',
  lupanar: 'lupanar',
  lupanas: 'lupanas',
  lupando: 'lupando',
  lupanei: 'lupanei',
  lupanem: 'lupanem',
  lupanes: 'lupanes',
  lupanga: 'lupanga',
  lupango: 'lupango',
  lupanou: 'lupanou',
  lupante: 'lupante',
  luparam: 'luparam',
  luparao: 'luparão',
  luparas: 'luparas',
  luparei: 'luparei',
  luparem: 'luparem',
  lupares: 'lupares',
  luparia: 'luparia',
  luparmo: 'luparmo',
  luparol: 'luparol',
  luparos: 'lúparos',
  lupasse: 'lupasse',
  lupaste: 'lupaste',
  lupatos: 'lupatos',
  lupavam: 'lupavam',
  lupavas: 'lupavas',
  lupemos: 'lupemos',
  lupeoal: 'lupeoal',
  lupeois: 'lupeóis',
  lupeona: 'lupeona',
  lupeose: 'lupeose',
  luperca: 'luperca',
  luperco: 'luperco',
  luperos: 'luperos',
  lupetas: 'lupetas',
  lupicas: 'lúpicas',
  lupicos: 'lúpicos',
  lupinas: 'lupinas',
  lupinos: 'lupinos',
  lupocas: 'lupoças',
  lupoide: 'lupoide',
  lupomas: 'lupomas',
  luposas: 'luposas',
  luposos: 'luposos',
  lupossa: 'lupossa',
  lupuies: 'lupuiés',
  lupulos: 'lúpulos',
  lupumbo: 'lupumbo',
  lupuros: 'lúpuros',
  luquelo: 'luquelo',
  luqueno: 'luqueno',
  luradao: 'luradão',
  luradas: 'luradas',
  lurador: 'lurador',
  lurados: 'lurados',
  luramos: 'luramos',
  lurando: 'lurando',
  lurante: 'lurante',
  luraram: 'luraram',
  lurarao: 'lurarão',
  luraras: 'lurarás',
  lurarei: 'lurarei',
  lurarem: 'lurarem',
  lurares: 'lurares',
  luraria: 'luraria',
  lurarmo: 'lurarmo',
  lurasse: 'lurasse',
  luraste: 'luraste',
  luravam: 'luravam',
  luravas: 'luravas',
  lurdona: 'lurdona',
  luremos: 'luremos',
  luridas: 'lúridas',
  luridez: 'luridez',
  luridos: 'lúridos',
  lurtias: 'lúrtias',
  lururas: 'lururas',
  lusacia: 'lusácia',
  lusacio: 'lusácio',
  lusango: 'lusango',
  luscada: 'luscada',
  luscado: 'luscado',
  luscais: 'luscais',
  luscamo: 'luscamo',
  luscara: 'luscará',
  luscava: 'luscava',
  lusiada: 'lusíada',
  lusismo: 'lusismo',
  lusista: 'lusista',
  lusitos: 'lusitos',
  lusmais: 'lusmais',
  lusmamo: 'lusmamo',
  lusmeas: 'lúsmeas',
  lusmeos: 'lúsmeos',
  lusmiam: 'lusmiam',
  lusmias: 'lusmias',
  lusmida: 'lusmida',
  lusmido: 'lusmido',
  lusmimo: 'lusmimo',
  lusmira: 'lusmira',
  lusones: 'lusones',
  lusonia: 'lusônia',
  lusonio: 'lusônio',
  lusoria: 'lusória',
  lusorio: 'lusório',
  lusquam: 'lusquam',
  lusquas: 'lusquas',
  lusquei: 'lusquei',
  lusquem: 'lusquem',
  lusques: 'lusques',
  lusquia: 'lusquia',
  lusquir: 'lusquir',
  lusquis: 'lusquis',
  lusquiu: 'lusquiu',
  lustrai: 'lustrai',
  lustral: 'lustral',
  lustram: 'lustram',
  lustrar: 'lustrar',
  lustras: 'lustras',
  lustrei: 'lustrei',
  lustrem: 'lustrem',
  lustres: 'lustres',
  lustrex: 'lustrex',
  lustrim: 'lustrim',
  lustron: 'lustron',
  lustros: 'lustros',
  lustrou: 'lustrou',
  lutacao: 'lutação',
  lutadao: 'lutadão',
  lutadas: 'lutadas',
  lutador: 'lutador',
  lutados: 'lutados',
  lutagem: 'lutagem',
  lutamos: 'lutamos',
  lutando: 'lutando',
  lutante: 'lutante',
  lutaram: 'lutaram',
  lutarao: 'lutarão',
  lutaras: 'lutaras',
  lutarei: 'lutarei',
  lutarem: 'lutarem',
  lutares: 'lutares',
  lutaria: 'lutaria',
  lutario: 'lutário',
  lutarmo: 'lutarmo',
  lutaros: 'lútaros',
  lutasse: 'lutasse',
  lutaste: 'lutaste',
  lutavam: 'lutavam',
  lutavas: 'lutavas',
  lutavel: 'lutável',
  luteais: 'luteais',
  lutecia: 'lutécia',
  lutecio: 'lutécio',
  luteina: 'luteína',
  lutemos: 'lutemos',
  luteois: 'luteóis',
  luteoma: 'luteoma',
  luteria: 'luteria',
  luterio: 'lutério',
  luthier: 'luthier',
  lutiazi: 'lutiazi',
  lutinas: 'lutinas',
  lutisse: 'lutisse',
  lutitos: 'lutitos',
  lutjana: 'lutjana',
  lutjano: 'lutjano',
  lutocas: 'lutocas',
  lutombe: 'lutombe',
  lutombo: 'lutombo',
  lutosas: 'lutosas',
  lutosos: 'lutosos',
  lutrida: 'lutrida',
  lutrido: 'lutrido',
  lutrina: 'lutrina',
  lutrino: 'lutrino',
  lutumbo: 'lutumbo',
  lutuosa: 'lutuosa',
  lutuoso: 'lutuoso',
  luuiuis: 'luuiuís',
  luvales: 'luvales',
  luvares: 'luvares',
  luvaria: 'luvaria',
  luveira: 'luveira',
  luveiro: 'luveiro',
  luvetes: 'luvetes',
  luvinha: 'luvinha',
  luvista: 'luvista',
  luvitas: 'luvitas',
  luvonas: 'luvonas',
  luxacao: 'luxação',
  luxadao: 'luxadão',
  luxadas: 'luxadas',
  luxador: 'luxador',
  luxados: 'luxados',
  luxamos: 'luxamos',
  luxanca: 'luxança',
  luxando: 'luxando',
  luxante: 'luxante',
  luxaram: 'luxaram',
  luxarao: 'luxarão',
  luxaras: 'luxarás',
  luxarei: 'luxarei',
  luxarem: 'luxarem',
  luxares: 'luxares',
  luxaria: 'luxaria',
  luxarmo: 'luxarmo',
  luxasse: 'luxasse',
  luxaste: 'luxaste',
  luxavam: 'luxavam',
  luxavas: 'luxavas',
  luxazes: 'luxazes',
  luxazis: 'luxázis',
  luxemos: 'luxemos',
  luxenta: 'luxenta',
  luxento: 'luxento',
  luxetas: 'luxetas',
  luxibas: 'luxibas',
  luxinho: 'luxinho',
  luxosas: 'luxosas',
  luxosos: 'luxosos',
  luxuosa: 'luxuosa',
  luxuoso: 'luxuoso',
  luxuria: 'luxúria',
  luxurie: 'luxurie',
  luxurio: 'luxurio',
  luzamos: 'luzamos',
  luzarda: 'luzarda',
  luzarra: 'luzarra',
  luzecus: 'luzecus',
  luzeira: 'luzeira',
  luzeiro: 'luzeiro',
  luzenca: 'luzença',
  luzencu: 'luzencu',
  luzense: 'luzense',
  luzente: 'luzente',
  luzerna: 'luzerna',
  luzetro: 'luzetro',
  luzicas: 'luzicas',
  luzicus: 'luzicus',
  luzidao: 'luzidão',
  luzidas: 'luzidas',
  luzidia: 'luzidia',
  luzidio: 'luzidio',
  luzidor: 'luzidor',
  luzidos: 'luzidos',
  luzieis: 'luzíeis',
  luziluz: 'luziluz',
  luzimos: 'luzimos',
  luzincu: 'luzincu',
  luzindo: 'luzindo',
  luzinha: 'luzinha',
  luziola: 'luzíola',
  luziram: 'luziram',
  luzirao: 'luzirão',
  luziras: 'luziras',
  luzirei: 'luzirei',
  luzirem: 'luzirem',
  luzires: 'luzires',
  luziria: 'luziria',
  luzirmo: 'luzirmo',
  luzisse: 'luzisse',
  luziste: 'luziste',
  luzulas: 'luzulas',
  lxxviii: 'lxxviii',
  lxxxiii: 'lxxxiii',
  lxxxvii: 'lxxxvii',
  lyonita: 'lyonita',
  maaiana: 'maaiana',
  maalebe: 'maalebe',
  maambas: 'maambas',
  maamude: 'maamude',
  maamudi: 'maamúdi',
  maarani: 'maarâni',
  maatmas: 'maatmas',
  maavela: 'maavela',
  maavita: 'maavita',
  mabacao: 'mabação',
  mabacas: 'mabaças',
  mabacos: 'mabaços',
  mabaias: 'mabaias',
  mabalas: 'mabalas',
  mabaloi: 'mabaloi',
  mabanga: 'mabanga',
  mabanje: 'mabanje',
  mabatas: 'mabatas',
  mabates: 'mabates',
  mabazas: 'mabazas',
  mabecos: 'mabecos',
  mabeias: 'mabeias',
  mabelas: 'mabelas',
  mabense: 'mabense',
  mabilas: 'mabilas',
  mabiles: 'mabiles',
  mabirri: 'mabírri',
  mabitas: 'mabitas',
  mabocas: 'mabocas',
  mabodes: 'mabodes',
  maboias: 'maboias',
  mabolas: 'mabolas',
  maboles: 'maboles',
  mabolos: 'mabolos',
  maboque: 'maboque',
  mabouia: 'mabouiá',
  maboula: 'maboulá',
  mabubas: 'mabubas',
  mabucos: 'mabucos',
  mabudas: 'mabudas',
  mabuias: 'mabuias',
  mabulas: 'mabulás',
  mabunda: 'mabunda',
  mabunos: 'mabunos',
  mabures: 'mabures',
  mabutas: 'mabutas',
  macabas: 'macabas',
  macabeu: 'macabeu',
  macabra: 'macabra',
  macabro: 'macabro',
  macacai: 'macacai',
  macacal: 'macacal',
  macacam: 'macacam',
  macacao: 'macacão',
  macacar: 'macacar',
  macacas: 'macacas',
  macacoa: 'macacoa',
  macacos: 'macacos',
  macacou: 'macacou',
  macacus: 'macacus',
  macadao: 'maçadão',
  macadas: 'maçadas',
  macador: 'maçador',
  macados: 'maçados',
  macagem: 'maçagem',
  macagua: 'macaguá',
  macaias: 'macaias',
  macaiba: 'macaíba',
  macaibo: 'macaíbo',
  macaios: 'macaios',
  macaira: 'macaíra',
  macaita: 'macaíta',
  macajas: 'macajás',
  macalas: 'macalas',
  macalis: 'macális',
  macalos: 'macalos',
  macamas: 'macamãs',
  macamau: 'macamau',
  macamba: 'macamba',
  macambo: 'macambo',
  macamos: 'maçamos',
  macanas: 'macanas',
  macanda: 'macanda',
  macando: 'maçando',
  macanga: 'macanga',
  macango: 'maçango',
  macanha: 'macanha',
  macanho: 'macanho',
  macanja: 'macanja',
  macanjo: 'macanjo',
  macante: 'maçante',
  macaoes: 'macaões',
  macapao: 'maçapão',
  macaque: 'macaque',
  macaram: 'maçaram',
  macarao: 'maçarão',
  macaras: 'maçaras',
  macarei: 'maçarei',
  macarem: 'maçarem',
  macares: 'maçares',
  macareu: 'macaréu',
  macaria: 'maçaria',
  macario: 'macário',
  macarmo: 'maçarmo',
  macasse: 'maçasse',
  macasso: 'macasso',
  macaste: 'maçaste',
  macatas: 'macatas',
  macates: 'macates',
  macauas: 'macauãs',
  macauba: 'macaúba',
  macaues: 'macauês',
  macauva: 'macaúva',
  macavam: 'maçavam',
  macavas: 'maçavas',
  macaxas: 'macaxás',
  macaxos: 'macaxós',
  macazas: 'macazas',
  maceces: 'maceces',
  macedas: 'mácedas',
  macedio: 'macédio',
  macedos: 'macedos',
  macegal: 'macegal',
  macegao: 'macegão',
  macegas: 'macegas',
  maceios: 'maceiós',
  maceira: 'maceira',
  maceiro: 'maceiro',
  macelao: 'macelão',
  macelas: 'macelas',
  macemba: 'macemba',
  macemos: 'macemos',
  macerai: 'macerai',
  maceram: 'maceram',
  macerar: 'macerar',
  maceras: 'maceras',
  macerei: 'macerei',
  macerem: 'macerem',
  maceres: 'maceres',
  maceria: 'macéria',
  macerou: 'macerou',
  macesse: 'macesse',
  macetai: 'macetai',
  macetam: 'macetam',
  macetar: 'macetar',
  macetas: 'macetas',
  macetea: 'maceteá',
  macetei: 'macetei',
  macetem: 'macetem',
  macetes: 'macetes',
  macetou: 'macetou',
  machaca: 'machacá',
  machaco: 'machaco',
  machada: 'machada',
  machade: 'machade',
  machado: 'machado',
  macheai: 'macheai',
  machear: 'machear',
  macheei: 'macheei',
  machego: 'machego',
  macheia: 'macheia',
  macheie: 'macheie',
  macheio: 'macheio',
  macheou: 'macheou',
  machete: 'machete',
  macheza: 'macheza',
  machiai: 'machiai',
  machial: 'machial',
  machiam: 'machiam',
  machiar: 'machiar',
  machias: 'machias',
  machica: 'machica',
  machico: 'machico',
  machiei: 'machiei',
  machiem: 'machiem',
  machies: 'machies',
  machila: 'machila',
  machina: 'machina',
  machine: 'machine',
  machino: 'machino',
  machins: 'machins',
  machios: 'máchios',
  machiou: 'machiou',
  machira: 'machira',
  machoas: 'machoas',
  machobo: 'machobo',
  machoca: 'machoca',
  machoco: 'machoco',
  machoes: 'machões',
  machona: 'machona',
  machote: 'machote',
  machuca: 'machuca',
  machuco: 'machuco',
  machuna: 'machuna',
  maciada: 'maciada',
  maciado: 'maciado',
  maciais: 'maciais',
  maciamo: 'maciamo',
  maciana: 'maciana',
  maciano: 'maciano',
  maciara: 'maciara',
  maciava: 'maciava',
  macicas: 'maciças',
  macicez: 'macicez',
  macicos: 'maciços',
  macidra: 'macidra',
  macieis: 'macieis',
  maciemo: 'maciemo',
  macieza: 'macieza',
  macimba: 'macimba',
  macinas: 'macinas',
  macinho: 'macinho',
  maciosa: 'maciosa',
  macioso: 'macioso',
  maciota: 'maciota',
  macista: 'macista',
  maclada: 'maclada',
  maclado: 'maclado',
  macleia: 'macleia',
  maclies: 'máclies',
  maclura: 'maclura',
  macobas: 'macobas',
  macobio: 'macobio',
  macocas: 'macocas',
  macocoa: 'macocoa',
  macocos: 'macocos',
  macofus: 'macofus',
  macoias: 'macoias',
  macoice: 'macoice',
  macolar: 'maçolar',
  macolas: 'macolas',
  macolos: 'macolos',
  macomas: 'macomas',
  macomba: 'macomba',
  macombo: 'macombo',
  maconas: 'maconas',
  maconde: 'maconde',
  macones: 'macones',
  maconge: 'maçonge',
  maconha: 'maconha',
  maconhe: 'maconhe',
  maconho: 'maconho',
  maconis: 'maconis',
  maconta: 'maconta',
  macopio: 'macópio',
  macopos: 'macopos',
  macorao: 'macorão',
  macoras: 'macorãs',
  macorro: 'maçorro',
  macotas: 'macotas',
  macotea: 'macoteá',
  macotes: 'macotes',
  macouba: 'macouba',
  macouta: 'macoutá',
  macovia: 'macóvia',
  macovio: 'macovio',
  macoxis: 'macóxis',
  macozas: 'macozas',
  macozos: 'macozos',
  macrais: 'macrais',
  macrame: 'macramé',
  macroes: 'macrões',
  macroje: 'macrojê',
  macrone: 'macrone',
  macrons: 'mácrons',
  macropo: 'mácropo',
  macrose: 'macrose',
  macrote: 'mácrote',
  macroto: 'mácroto',
  macruro: 'macruro',
  macruus: 'macruus',
  mactima: 'mactima',
  mactras: 'mactras',
  macuana: 'macuana',
  macuara: 'macuara',
  macubar: 'macubar',
  macubas: 'macubas',
  macubio: 'macúbio',
  macucai: 'macucai',
  macucam: 'macucam',
  macucar: 'macucar',
  macucas: 'macucas',
  macucau: 'macucau',
  macucos: 'macucos',
  macucou: 'macucou',
  macucus: 'macucus',
  macudas: 'maçudas',
  macudos: 'maçudos',
  macuini: 'macuini',
  macuins: 'macuins',
  macujas: 'macujás',
  macujes: 'macujés',
  maculai: 'maculai',
  maculam: 'maculam',
  macular: 'macular',
  maculas: 'maculas',
  maculei: 'maculei',
  maculem: 'maculem',
  macules: 'macules',
  maculou: 'maculou',
  macumas: 'macumãs',
  macumba: 'macumba',
  macumbe: 'macumbé',
  macumbi: 'macúmbi',
  macumbo: 'macumbo',
  macunas: 'macunas',
  macunda: 'macunda',
  macunde: 'macunde',
  macundi: 'macúndi',
  macunga: 'macungá',
  macungo: 'macungo',
  macunim: 'maçunim',
  macunis: 'macunis',
  macupis: 'macupis',
  macuque: 'macuque',
  macuras: 'macurás',
  macuria: 'macúria',
  macurus: 'macurus',
  macutas: 'macutas',
  macutes: 'macutes',
  macutis: 'macútis',
  macutos: 'macutos',
  macuves: 'macuves',
  macuxis: 'macuxis',
  madalas: 'madalas',
  madalem: 'madalém',
  madamas: 'madamas',
  madames: 'madames',
  madeira: 'madeira',
  madeire: 'madeire',
  madeiro: 'madeiro',
  madeixa: 'madeixa',
  madeixe: 'madeixe',
  madeixo: 'madeixo',
  madente: 'madente',
  madersa: 'madersa',
  madgiai: 'madgiai',
  madgiam: 'madgiam',
  madgiar: 'madgiar',
  madgias: 'madgias',
  madgiei: 'madgiei',
  madgiem: 'madgiem',
  madgies: 'madgies',
  madgiou: 'madgiou',
  madidas: 'mádidas',
  madidez: 'madidez',
  madidos: 'mádidos',
  madiina: 'madiina',
  madijas: 'madijás',
  madimbo: 'madimbo',
  madinho: 'madinho',
  madiobo: 'madiobo',
  madismo: 'madismo',
  madista: 'madista',
  madjada: 'madjada',
  madjuba: 'madjuba',
  madodas: 'madodas',
  madonas: 'madonas',
  madonna: 'madonna',
  madoqua: 'madóqua',
  madorna: 'madorna',
  madorne: 'madorne',
  madorno: 'madorno',
  madorra: 'madorra',
  madorre: 'madorre',
  madorro: 'madorro',
  madraca: 'madraça',
  madrace: 'madrace',
  madraco: 'madraço',
  madrafa: 'madrafã',
  madraga: 'madraga',
  madrias: 'madrias',
  madride: 'madride',
  madrija: 'madrija',
  madrijo: 'madrijo',
  madruga: 'madruga',
  madrugo: 'madrugo',
  maducas: 'maducas',
  madurai: 'madurai',
  madural: 'madural',
  maduram: 'maduram',
  madurao: 'madurão',
  madurar: 'madurar',
  maduras: 'maduras',
  madurei: 'madurei',
  madurem: 'madurem',
  madures: 'madurês',
  madurez: 'madurez',
  maduros: 'maduros',
  madurou: 'madurou',
  maengas: 'maengas',
  maenjes: 'maenjes',
  maeruna: 'maeruna',
  maestra: 'maestra',
  maestre: 'maestre',
  maestro: 'maestro',
  maezona: 'mãezona',
  mafabes: 'mafabés',
  mafafas: 'mafafas',
  mafalis: 'mafalis',
  mafanas: 'mafanas',
  mafiada: 'mafiada',
  mafiado: 'mafiado',
  mafiais: 'mafiais',
  mafiamo: 'mafiamo',
  mafiara: 'mafiara',
  mafiava: 'mafiava',
  maficas: 'máficas',
  maficos: 'máficos',
  mafieis: 'mafieis',
  mafiemo: 'mafiemo',
  mafioes: 'mafiões',
  mafiosa: 'mafiosa',
  mafioso: 'mafioso',
  mafires: 'mafires',
  mafitas: 'mafitas',
  mafites: 'mafites',
  mafomas: 'mafomas',
  mafomes: 'mafomes',
  mafucas: 'mafucas',
  mafucos: 'mafucos',
  mafulas: 'mafulas',
  mafulos: 'mafulos',
  mafumas: 'mafumas',
  mafumba: 'mafumba',
  mafumos: 'mafumos',
  mafunge: 'mafunge',
  mafunji: 'mafunji',
  mafuras: 'mafuras',
  mafurra: 'mafurra',
  mafutas: 'mafutas',
  mafutra: 'mafutra',
  magacas: 'magaças',
  magacia: 'magacia',
  magadis: 'magadis',
  magaica: 'magaíça',
  magalas: 'magalas',
  magalia: 'magália',
  maganao: 'maganão',
  maganas: 'maganas',
  maganaz: 'maganaz',
  maganea: 'maganeá',
  maganja: 'maganja',
  maganos: 'maganos',
  maganza: 'maganza',
  magarca: 'magarça',
  magareb: 'magareb',
  magarim: 'magarim',
  magaris: 'magaris',
  magatos: 'magatôs',
  magedas: 'magedas',
  magelas: 'magelas',
  magelos: 'magelos',
  magenta: 'magenta',
  magiada: 'magiada',
  magiado: 'magiado',
  magiais: 'magiais',
  magiamo: 'magiamo',
  magiara: 'magiara',
  magiava: 'magiava',
  magicai: 'magicai',
  magicam: 'magicam',
  magicar: 'magicar',
  magicas: 'mágicas',
  magicos: 'mágicos',
  magicou: 'magicou',
  magieis: 'magieis',
  magiemo: 'magiemo',
  magilos: 'magilos',
  maginai: 'maginai',
  maginam: 'maginam',
  maginar: 'maginar',
  maginas: 'maginas',
  maginei: 'maginei',
  maginem: 'maginem',
  magines: 'magines',
  maginou: 'maginou',
  magique: 'magique',
  magismo: 'magismo',
  magista: 'magista',
  magnata: 'magnata',
  magnate: 'magnate',
  magnete: 'magnete',
  magneto: 'magneto',
  magnios: 'mágnios',
  magnita: 'magnita',
  magnole: 'magnole',
  magnons: 'mágnons',
  magoada: 'magoada',
  magoado: 'magoado',
  magoais: 'magoais',
  magoamo: 'magoamo',
  magoara: 'magoara',
  magoava: 'magoava',
  magocha: 'magocha',
  magodia: 'magodia',
  magodos: 'magodos',
  magoeis: 'magoeis',
  magoemo: 'magoemo',
  magondi: 'magondi',
  magonga: 'magonga',
  magonia: 'magônia',
  magorim: 'magorim',
  magotes: 'magotes',
  magouas: 'magouas',
  magrama: 'magrama',
  magrebe: 'magrebe',
  magrela: 'magrela',
  magrelo: 'magrelo',
  magrens: 'magréns',
  magrete: 'magrete',
  magreza: 'magreza',
  magrica: 'magriça',
  magrico: 'magriço',
  magroes: 'magrões',
  magrote: 'magrote',
  magruca: 'magruça',
  magruco: 'magruço',
  maguala: 'maguala',
  maguari: 'maguari',
  magudas: 'magudas',
  magudos: 'magudos',
  magueis: 'magueis',
  magujos: 'magujos',
  magulha: 'magulha',
  magulhe: 'magulhe',
  magulho: 'magulho',
  magumba: 'magumba',
  magusto: 'magusto',
  mahatma: 'mahatma',
  mahonia: 'mahônia',
  maiacas: 'maiacás',
  maiadao: 'maiadão',
  maiadas: 'maiadas',
  maiadim: 'maiadim',
  maiador: 'maiador',
  maiados: 'maiados',
  maiaias: 'maiaias',
  maiaita: 'maiaíta',
  maiambu: 'maiambu',
  maiamos: 'maiamos',
  maianas: 'maianas',
  maiando: 'maiando',
  maianga: 'maianga',
  maianos: 'maianos',
  maiante: 'maiante',
  maiaram: 'maiaram',
  maiarao: 'maiarão',
  maiaras: 'maiarás',
  maiarei: 'maiarei',
  maiarem: 'maiarem',
  maiares: 'maiares',
  maiaria: 'maiaria',
  maiarmo: 'maiarmo',
  maiasse: 'maiasse',
  maiaste: 'maiaste',
  maiatas: 'maiatas',
  maiatos: 'maiatos',
  maiavam: 'maiavam',
  maiavas: 'maiavas',
  maideas: 'maídeas',
  maidins: 'maidins',
  maidoes: 'maidões',
  maidona: 'maidona',
  maielas: 'maielas',
  maiemos: 'maiemos',
  maiense: 'maiense',
  maigaca: 'maigaça',
  maiinea: 'maiinea',
  maiineo: 'maiineo',
  maimbus: 'maimbus',
  maimona: 'maimona',
  mainata: 'mainata',
  mainate: 'mainate',
  mainato: 'mainato',
  mainaua: 'mainauá',
  maincas: 'mainças',
  maindos: 'maindos',
  maineis: 'mainéis',
  mainibu: 'mainibu',
  mainira: 'mainira',
  mainota: 'mainota',
  maiobas: 'maiobas',
  maiobos: 'maiobos',
  maiocas: 'maiocas',
  maiolos: 'maiolos',
  maiombe: 'maiombe',
  maionga: 'maionga',
  maioral: 'maioral',
  maiorca: 'maiorca',
  maiores: 'maiores',
  maioria: 'maioria',
  maiosia: 'maiosia',
  maiotas: 'maiotas',
  maiotos: 'maiotos',
  maipoca: 'maipoca',
  maipure: 'maipuré',
  mairata: 'mairata',
  mairelo: 'mairelo',
  maisais: 'maisais',
  maisena: 'maisena',
  maisque: 'maisque',
  maisqui: 'maisqui',
  maitaca: 'maitaca',
  maitata: 'maitatá',
  maiteno: 'maiteno',
  maituca: 'maituca',
  maiuira: 'maiuira',
  maizena: 'maizena',
  majambo: 'majambo',
  majeros: 'majerós',
  majidea: 'majídea',
  majideo: 'majídeo',
  majorai: 'majorai',
  majoral: 'majoral',
  majoram: 'majoram',
  majorar: 'majorar',
  majoras: 'majoras',
  majorei: 'majorei',
  majorem: 'majorem',
  majores: 'majores',
  majoria: 'majoria',
  majorou: 'majorou',
  majovos: 'majovos',
  majubas: 'majubas',
  majubim: 'majubim',
  majugas: 'majugas',
  malabar: 'malabar',
  malabos: 'malabos',
  malacao: 'malacão',
  malacas: 'malacas',
  malacia: 'malacia',
  malacio: 'malácio',
  malacos: 'málacos',
  malacra: 'malacra',
  maladao: 'maladão',
  maladas: 'maladas',
  maladia: 'maladia',
  maladio: 'maladio',
  malador: 'malador',
  malados: 'malados',
  malafas: 'malafas',
  malafos: 'malafos',
  malagas: 'málagas',
  malagma: 'malagma',
  malagom: 'malagom',
  malagou: 'malagou',
  malaias: 'malaias',
  malaica: 'malaica',
  malaico: 'malaico',
  malaios: 'malaios',
  malalas: 'malalas',
  malalis: 'malalis',
  malamba: 'malamba',
  malambo: 'malambo',
  malamos: 'malamos',
  malanca: 'malanca',
  malanda: 'malanda',
  malando: 'malando',
  malanea: 'malânea',
  malanga: 'malanga',
  malange: 'malange',
  malanje: 'malanje',
  malante: 'malante',
  malapia: 'malápia',
  malapio: 'malápio',
  malaram: 'malaram',
  malarao: 'malarão',
  malaras: 'malarás',
  malarei: 'malarei',
  malarem: 'malarem',
  malares: 'malares',
  malaria: 'malária',
  malaris: 'maláris',
  malarmo: 'malarmo',
  malasio: 'malásio',
  malasse: 'malasse',
  malaste: 'malaste',
  malatos: 'malatos',
  malauis: 'malauis',
  malavam: 'malavam',
  malavas: 'malavas',
  malavem: 'malavém',
  malavim: 'malavim',
  malavir: 'malavir',
  malavos: 'malavos',
  malavra: 'malavra',
  malaxai: 'malaxai',
  malaxam: 'malaxam',
  malaxar: 'malaxar',
  malaxas: 'malaxas',
  malaxei: 'malaxei',
  malaxem: 'malaxem',
  malaxes: 'malaxes',
  malaxou: 'malaxou',
  malcoza: 'malcoza',
  malcoze: 'malcozê',
  malcozi: 'malcozi',
  malcozo: 'malcozo',
  maldada: 'maldada',
  maldade: 'maldade',
  maldado: 'maldado',
  maldais: 'maldais',
  maldamo: 'maldamo',
  maldana: 'maldana',
  maldano: 'maldano',
  maldara: 'maldará',
  maldava: 'maldava',
  maldeis: 'maldeis',
  maldemo: 'maldemo',
  maldiga: 'maldiga',
  maldigo: 'maldigo',
  maldira: 'maldirá',
  maldita: 'maldita',
  maldito: 'maldito',
  maldiva: 'maldiva',
  maldivo: 'maldivo',
  maldize: 'maldize',
  maldosa: 'maldosa',
  maldoso: 'maldoso',
  maleada: 'maleada',
  maleado: 'maleado',
  maleais: 'maleais',
  maleara: 'maleará',
  maleato: 'maleato',
  maleava: 'maleava',
  malebra: 'malebra',
  malecha: 'malecha',
  malecho: 'malecho',
  malecia: 'malécia',
  maledos: 'maledos',
  maleeis: 'maleeis',
  maleiam: 'maleiam',
  maleias: 'maleias',
  maleica: 'maleica',
  maleico: 'maleico',
  maleiem: 'maleiem',
  maleies: 'maleies',
  maleilo: 'maleílo',
  maleina: 'maleína',
  maleino: 'maleíno',
  maleira: 'maleira',
  maleiro: 'maleiro',
  maleita: 'maleita',
  malejos: 'malejos',
  malemba: 'malemba',
  malembe: 'malembe',
  malembo: 'malembo',
  malemos: 'malemos',
  maleola: 'maléola',
  maleolo: 'maléolo',
  maleque: 'maleque',
  malesso: 'malesso',
  maletas: 'maletas',
  malevao: 'malevão',
  malevas: 'malevas',
  malevos: 'malevos',
  malezas: 'malezas',
  malfaca: 'malfaça',
  malfaco: 'malfaço',
  malfada: 'malfada',
  malfade: 'malfade',
  malfado: 'malfado',
  malfara: 'malfará',
  malfaze: 'malfaze',
  malfere: 'malfere',
  malferi: 'malferi',
  malfetu: 'malfetu',
  malfira: 'malfira',
  malfiro: 'malfiro',
  malgaxa: 'malgaxa',
  malgaxe: 'malgaxe',
  malgaxo: 'malgaxo',
  malhada: 'malhada',
  malhado: 'malhado',
  malhais: 'malhais',
  malhamo: 'malhamo',
  malhana: 'malhana',
  malhara: 'malhara',
  malhava: 'malhava',
  malheis: 'malheis',
  malhemo: 'malhemo',
  malheta: 'malheta',
  malhete: 'malhete',
  malheto: 'malheto',
  malhios: 'malhios',
  malhoes: 'malhões',
  maliana: 'maliana',
  maliano: 'maliano',
  malicas: 'málicas',
  malicia: 'malícia',
  malicie: 'malicie',
  malicio: 'malicio',
  malicos: 'málicos',
  malidos: 'malidos',
  maligna: 'maligna',
  maligne: 'maligne',
  maligno: 'maligno',
  malilas: 'malilas',
  malilis: 'malilis',
  malilos: 'malilos',
  malinai: 'malinai',
  malinam: 'malinam',
  malinar: 'malinar',
  malinas: 'malinas',
  malinei: 'malinei',
  malinem: 'malinem',
  malines: 'malinês',
  malinha: 'malinha',
  malinou: 'malinou',
  malinus: 'malinus',
  maliolo: 'malíolo',
  malismo: 'malismo',
  malista: 'malista',
  malitas: 'malitas',
  malleis: 'malleis',
  malocai: 'malocai',
  malocam: 'malocam',
  malocar: 'malocar',
  malocas: 'malocas',
  malocou: 'malocou',
  malogra: 'malogra',
  malogre: 'malogre',
  malogro: 'malogro',
  maloias: 'maloias',
  maloios: 'maloios',
  malojas: 'malojas',
  malolos: 'malolos',
  malombe: 'malombe',
  malopas: 'malopas',
  malopea: 'malópea',
  malopes: 'malopes',
  maloque: 'maloque',
  maloras: 'maloras',
  malosas: 'malosas',
  malotao: 'malotão',
  malotes: 'malotes',
  malotos: 'malotos',
  malpara: 'malpara',
  malpare: 'malpare',
  malparo: 'malparo',
  malpica: 'malpica',
  malquer: 'malquer',
  malquis: 'malquis',
  malrege: 'malrege',
  malregi: 'malregi',
  malreja: 'malreja',
  malrejo: 'malrejo',
  malsina: 'malsina',
  malsine: 'malsine',
  malsino: 'malsino',
  malsins: 'malsins',
  malsoes: 'malsões',
  maltada: 'maltada',
  maltado: 'maltado',
  maltais: 'maltais',
  maltamo: 'maltamo',
  maltara: 'maltará',
  maltase: 'maltase',
  maltava: 'maltava',
  malteis: 'malteis',
  maltemo: 'maltemo',
  malteno: 'malteno',
  maltesa: 'maltesa',
  maltina: 'maltina',
  maltino: 'maltino',
  maltodo: 'maltodo',
  maltois: 'maltóis',
  maltoma: 'maltoma',
  maltosa: 'maltosa',
  maltose: 'maltose',
  maltoso: 'maltoso',
  maluata: 'maluata',
  malucai: 'malucai',
  malucam: 'malucam',
  malucar: 'malucar',
  malucas: 'malucas',
  malucos: 'malucos',
  malucou: 'malucou',
  malucua: 'malucua',
  maludas: 'maludas',
  maludos: 'maludos',
  malufos: 'malufos',
  maluias: 'maluias',
  malumba: 'malumba',
  malumbo: 'malumbo',
  malumes: 'malumes',
  malunda: 'malunda',
  malundo: 'malundo',
  malunga: 'malunga',
  malungo: 'malungo',
  maluque: 'maluque',
  maluros: 'maluros',
  malutas: 'malutas',
  maluvos: 'maluvos',
  malvada: 'malvada',
  malvado: 'malvado',
  malvais: 'malvais',
  malvale: 'malvale',
  malveas: 'málveas',
  malvela: 'malvela',
  malveos: 'málveos',
  malveza: 'malvezá',
  malveze: 'malveze',
  malvezo: 'malvezo',
  malvina: 'malvina',
  malvino: 'malvino',
  mamadao: 'mamadão',
  mamadas: 'mamadas',
  mamador: 'mamador',
  mamados: 'mamados',
  mamaena: 'mamaena',
  mamaene: 'mamaene',
  mamaeno: 'mamaeno',
  mamagem: 'mamagem',
  mamamos: 'mamamos',
  mamanas: 'mamanas',
  mamando: 'mamando',
  mamanes: 'mamanês',
  mamanga: 'mamanga',
  mamanos: 'mamanôs',
  mamanta: 'mamantã',
  mamante: 'mamante',
  mamaram: 'mamaram',
  mamarao: 'mamarão',
  mamaras: 'mamaras',
  mamarei: 'mamarei',
  mamarem: 'mamarem',
  mamares: 'mamares',
  mamaria: 'mamaria',
  mamario: 'mamário',
  mamarmo: 'mamarmo',
  mamasse: 'mamasse',
  mamaste: 'mamaste',
  mamatar: 'mamatar',
  mamatas: 'mamatas',
  mamavam: 'mamavam',
  mamavas: 'mamavas',
  mambala: 'mambala',
  mambare: 'mambaré',
  mambari: 'mambari',
  mambata: 'mambata',
  mambira: 'mambira',
  mambuca: 'mambuca',
  mambuco: 'mambuco',
  mambude: 'mambude',
  mambuti: 'mambúti',
  mameira: 'mameira',
  mamelao: 'mamelão',
  mamelas: 'mamelas',
  mamemos: 'mamemos',
  mamenga: 'mamengá',
  mamense: 'mamense',
  mamente: 'mamente',
  mametos: 'mametos',
  mamicas: 'mamicas',
  mamilao: 'mamilão',
  mamilar: 'mamilar',
  mamilas: 'mamilas',
  mamilho: 'mamilho',
  mamilos: 'mamilos',
  maminha: 'maminha',
  mamiras: 'mamirás',
  mamites: 'mamites',
  mamixis: 'mamixis',
  mamocas: 'mamocas',
  mamocos: 'mamocos',
  mamolas: 'mamolas',
  mamonas: 'mamonas',
  mamonos: 'mamonos',
  mamoona: 'mamoona',
  mamorim: 'mamorim',
  mamosas: 'mamosas',
  mamosos: 'mamosos',
  mamotas: 'mamotas',
  mamotes: 'mamotes',
  mamotos: 'mamotos',
  mampada: 'mampada',
  mampado: 'mampado',
  mampais: 'mampais',
  mampamo: 'mampamo',
  mampara: 'mampara',
  mampava: 'mampava',
  mampeis: 'mampeis',
  mampemo: 'mampemo',
  mampofa: 'mampofa',
  mamucha: 'mamucha',
  mamudas: 'mamudas',
  mamudes: 'mamudes',
  mamudos: 'mamudos',
  mamujai: 'mamujai',
  mamujam: 'mamujam',
  mamujar: 'mamujar',
  mamujas: 'mamujas',
  mamujei: 'mamujei',
  mamujem: 'mamujem',
  mamujes: 'mamujes',
  mamujou: 'mamujou',
  mamulos: 'mamulos',
  mamunas: 'mamunas',
  mamunha: 'mamunha',
  mamuona: 'mamuona',
  mamuris: 'mamuris',
  mamutes: 'mamutes',
  manacao: 'manação',
  manacas: 'manacás',
  manacho: 'manacho',
  manadao: 'manadão',
  manadas: 'manadas',
  manadia: 'manadia',
  manadio: 'manadio',
  manador: 'manador',
  manados: 'manados',
  manager: 'manager',
  manages: 'managés',
  manaias: 'manaias',
  manaiba: 'manaíba',
  manaies: 'manaiés',
  manaios: 'manaios',
  manaixo: 'manaixo',
  manajas: 'manajás',
  manajes: 'manajés',
  manajos: 'manajós',
  manalha: 'manalha',
  manalva: 'manalva',
  manalvo: 'manalvo',
  manamos: 'manamos',
  mananas: 'mananas',
  mananau: 'mananau',
  manando: 'manando',
  mananga: 'mananga',
  manante: 'manante',
  manaram: 'manaram',
  manarao: 'manarão',
  manaras: 'manarás',
  manarei: 'manarei',
  manarem: 'manarem',
  manares: 'manares',
  manaria: 'manaria',
  manarmo: 'manarmo',
  manasse: 'manasse',
  manaste: 'manaste',
  manatas: 'manatas',
  manates: 'manates',
  manatim: 'manatim',
  manatis: 'manatis',
  manatos: 'manatos',
  manaues: 'manauês',
  manavam: 'manavam',
  manavas: 'manavas',
  manaxos: 'manaxos',
  mancada: 'mancada',
  mancado: 'mancado',
  mancais: 'mancais',
  mancamo: 'mancamo',
  mancara: 'mancara',
  mancava: 'mancava',
  manceba: 'manceba',
  mancebo: 'mancebo',
  manchai: 'manchai',
  mancham: 'mancham',
  manchao: 'manchão',
  manchar: 'manchar',
  manchas: 'manchas',
  manchea: 'mancheá',
  manchei: 'manchei',
  manchem: 'manchem',
  manches: 'manches',
  manchil: 'manchil',
  manchis: 'manchis',
  manchos: 'manchós',
  manchou: 'manchou',
  manchua: 'manchua',
  manchus: 'manchus',
  mancipa: 'mancipá',
  mancipe: 'mancipe',
  mancipo: 'mancipo',
  mancoes: 'mancões',
  mancoia: 'mancoia',
  mancona: 'mancona',
  mancone: 'mancone',
  mancope: 'mançope',
  mancuba: 'mancuba',
  manculo: 'manculo',
  mancupa: 'mançupa',
  mancupi: 'mançupi',
  mancupo: 'mançupo',
  mandada: 'mandada',
  mandado: 'mandado',
  mandais: 'mandais',
  mandala: 'mandala',
  mandamo: 'mandamo',
  mandara: 'mandara',
  mandare: 'mandaré',
  mandari: 'mandari',
  mandato: 'mandato',
  mandaus: 'mandaus',
  mandava: 'mandava',
  mandchu: 'mandchu',
  mandeia: 'mandeia',
  mandeis: 'mandeis',
  mandemo: 'mandemo',
  mandeus: 'mandeus',
  mandias: 'mandias',
  mandiba: 'mandiba',
  mandibe: 'mandibé',
  mandibi: 'mandibi',
  mandiis: 'mandiis',
  mandins: 'mandins',
  mandius: 'mandiús',
  mandiva: 'mandiva',
  mandjoa: 'mandjoa',
  mandjua: 'mandjua',
  mandobi: 'mandobi',
  mandobo: 'mandobo',
  mandoes: 'mandões',
  mandola: 'mandola',
  mandona: 'mandona',
  mandora: 'mandora',
  mandoro: 'mandoro',
  mandovi: 'mandovi',
  mandrao: 'mandrão',
  mandras: 'mandras',
  mandria: 'mândria',
  mandrie: 'mandrie',
  mandril: 'mandril',
  mandrio: 'mandrio',
  mandris: 'mandris',
  mandros: 'mandros',
  manduas: 'manduas',
  manduba: 'manduba',
  mandube: 'mandubé',
  mandubi: 'mandubi',
  manduca: 'manduça',
  manduco: 'manduco',
  manduri: 'manduri',
  manduva: 'manduvá',
  maneada: 'maneada',
  maneado: 'maneado',
  maneais: 'maneais',
  maneamo: 'maneamo',
  maneara: 'maneara',
  maneava: 'maneava',
  manecar: 'manecar',
  manecas: 'manecas',
  manecos: 'manecos',
  maneeis: 'maneeis',
  maneemo: 'maneemo',
  manegar: 'manegar',
  manegas: 'manegas',
  maneges: 'maneges',
  manegos: 'manegos',
  maneiam: 'maneiam',
  maneias: 'maneias',
  maneiem: 'maneiem',
  maneies: 'maneies',
  maneios: 'maneios',
  maneira: 'maneira',
  maneire: 'maneire',
  maneiro: 'maneiro',
  manejai: 'manejai',
  manejam: 'manejam',
  manejar: 'manejar',
  manejas: 'manejas',
  manejei: 'manejei',
  manejem: 'manejem',
  manejes: 'manejes',
  manejos: 'manejos',
  manejou: 'manejou',
  manelas: 'manelas',
  manelos: 'manelos',
  manemas: 'manemas',
  manemos: 'manemos',
  manente: 'manente',
  maneria: 'maneria',
  manerio: 'manerio',
  manesas: 'manesas',
  maneses: 'maneses',
  manetas: 'manetas',
  manetea: 'maneteá',
  manetes: 'manetes',
  manetia: 'manétia',
  manette: 'manette',
  manfafa: 'manfafa',
  manfuis: 'manfuís',
  mangaba: 'mangaba',
  mangabe: 'mangabe',
  mangabo: 'mangabo',
  mangaca: 'mangaca',
  mangaco: 'mangaço',
  mangada: 'mangada',
  mangado: 'mangado',
  mangaga: 'mangagá',
  mangais: 'mangais',
  mangala: 'mangala',
  mangalo: 'mangalô',
  mangama: 'mangama',
  mangana: 'mangana',
  mangano: 'mangano',
  mangara: 'mangará',
  mangari: 'mangari',
  mangaua: 'mangaua',
  mangava: 'mangava',
  mangavi: 'mangavi',
  mangbas: 'mangbás',
  mangles: 'mangles',
  mangoal: 'mangoal',
  mangoca: 'mangoça',
  mangoes: 'mangões',
  mangofa: 'mangofa',
  mangoia: 'mangoia',
  mangoio: 'mangoio',
  mangola: 'mangola',
  mangole: 'mangole',
  mangolo: 'mangolo',
  mangona: 'mangona',
  mangone: 'mangone',
  mangono: 'mangono',
  mangora: 'mangora',
  mangoro: 'mangoro',
  mangote: 'mangote',
  mangrai: 'mangrai',
  mangram: 'mangram',
  mangrar: 'mangrar',
  mangras: 'mangras',
  mangrei: 'mangrei',
  mangrem: 'mangrem',
  mangres: 'mangres',
  mangrou: 'mangrou',
  mangual: 'mangual',
  manguas: 'manguás',
  manguco: 'manguço',
  mangucu: 'manguçu',
  manguea: 'mangueá',
  manguei: 'manguei',
  manguem: 'manguem',
  mangues: 'mangues',
  mangula: 'mangula',
  mangulo: 'mangulo',
  manguna: 'manguna',
  mangune: 'mangune',
  manguxo: 'manguxo',
  manguza: 'manguzá',
  manhema: 'manhema',
  manhoco: 'manhoco',
  manhosa: 'manhosa',
  manhose: 'manhose',
  manhoso: 'manhoso',
  manhuca: 'manhuça',
  manhuco: 'manhuço',
  maniaca: 'maníaca',
  maniaco: 'maníaco',
  maniada: 'maniada',
  maniado: 'maniado',
  maniata: 'maniata',
  maniate: 'maniate',
  maniato: 'maniato',
  manibas: 'manibas',
  manibus: 'manibus',
  manicas: 'manicas',
  manices: 'manices',
  manicla: 'manicla',
  manicos: 'manicos',
  manicue: 'manicue',
  manicus: 'manicus',
  manidas: 'manidas',
  manidea: 'manídea',
  manideo: 'manídeo',
  manidja: 'manidja',
  manidos: 'manidos',
  manieis: 'maníeis',
  manieta: 'manieta',
  maniete: 'maniete',
  manieto: 'manieto',
  maniiba: 'maniiba',
  manilas: 'manilas',
  manilha: 'manilha',
  manilhe: 'manilhe',
  manilho: 'manilho',
  manimba: 'manimba',
  manimbe: 'manimbé',
  manimbu: 'manimbu',
  manimos: 'manimos',
  maninas: 'maninas',
  manindi: 'manindi',
  manindo: 'manindo',
  maninea: 'manínea',
  manineo: 'maníneo',
  maninga: 'maninga',
  maninha: 'maninha',
  maninhe: 'maninhe',
  maninho: 'maninho',
  maninos: 'maninos',
  maniosa: 'maniosa',
  manioso: 'manioso',
  maniota: 'maniota',
  maniote: 'maniote',
  manipos: 'manipos',
  maniram: 'maniram',
  manirao: 'manirão',
  maniras: 'maniras',
  manirei: 'manirei',
  manirem: 'manirem',
  manires: 'manires',
  maniria: 'maniria',
  manirmo: 'manirmo',
  manismo: 'manismo',
  manisse: 'manisse',
  maniste: 'maniste',
  manitas: 'manitas',
  manites: 'manites',
  manitol: 'manitol',
  manitos: 'manitos',
  manitus: 'manitus',
  maniuva: 'maniúva',
  manival: 'manival',
  manivas: 'manivas',
  manixis: 'manixis',
  manjaca: 'manjaca',
  manjaco: 'manjaco',
  manjada: 'manjada',
  manjado: 'manjado',
  manjais: 'manjais',
  manjamo: 'manjamo',
  manjara: 'manjara',
  manjata: 'manjata',
  manjava: 'manjava',
  manjeis: 'manjeis',
  manjemo: 'manjemo',
  manjica: 'manjica',
  manjobo: 'manjobo',
  manjola: 'manjola',
  manjovo: 'manjovo',
  manjual: 'manjual',
  manjuas: 'manjuas',
  manjuba: 'manjuba',
  manjuca: 'manjuca',
  manjuva: 'manjuva',
  manobra: 'manobra',
  manobre: 'manobre',
  manobro: 'manobro',
  manocai: 'manocai',
  manocam: 'manocam',
  manocar: 'manocar',
  manocas: 'manocas',
  manocou: 'manocou',
  manoios: 'manoios',
  manojos: 'manojos',
  manolas: 'manolas',
  manolho: 'manolho',
  manolos: 'manolos',
  manonas: 'manonas',
  manopes: 'manopés',
  manopla: 'manopla',
  manoque: 'manoque',
  manosea: 'manoseá',
  manoses: 'manoses',
  manotea: 'manoteá',
  manquea: 'manqueá',
  manquei: 'manquei',
  manquem: 'manquem',
  manques: 'manquês',
  mansada: 'mansada',
  mansado: 'mansado',
  mansaos: 'mansãos',
  mansoes: 'mansões',
  mansope: 'mansope',
  mansupa: 'mansupa',
  mansupi: 'mansupi',
  mansupo: 'mansupo',
  mansura: 'mansura',
  mantada: 'mantada',
  mantado: 'mantado',
  mantais: 'mantais',
  mantamo: 'mantamo',
  mantana: 'mantana',
  mantara: 'mantara',
  mantava: 'mantava',
  manteai: 'manteai',
  mantear: 'mantear',
  manteca: 'manteca',
  manteei: 'manteei',
  manteia: 'manteia',
  manteie: 'manteie',
  manteio: 'manteio',
  manteis: 'mantéis',
  mantela: 'mantela',
  mantemo: 'mantemo',
  mantena: 'mantena',
  mantens: 'manténs',
  manteou: 'manteou',
  mantera: 'manterá',
  manteus: 'mantéus',
  manteve: 'manteve',
  mantica: 'mântica',
  mantico: 'mântico',
  mantida: 'mantida',
  mantido: 'mantido',
  mantipo: 'mantipo',
  mantive: 'mantive',
  mantoes: 'mantões',
  mantois: 'mantóis',
  mantosa: 'mantosa',
  mantras: 'mantras',
  mantuca: 'mantuca',
  mantura: 'mantura',
  manuais: 'manuais',
  manubas: 'manubas',
  manuche: 'manuche',
  manueis: 'manuéis',
  manulea: 'manúlea',
  manumas: 'manumas',
  manungo: 'manungo',
  manusca: 'manusca',
  manusea: 'manuseá',
  manutam: 'manutam',
  manutas: 'manutas',
  manutem: 'manutem',
  manutes: 'manutes',
  manutia: 'manutia',
  manutir: 'manutir',
  manutis: 'manutis',
  manutiu: 'manutiu',
  manvios: 'manvios',
  manzape: 'manzape',
  manzari: 'manzari',
  manzega: 'manzega',
  manzuas: 'manzuás',
  maogani: 'maogani',
  maoismo: 'maoismo',
  maoista: 'maoista',
  maombes: 'maombes',
  maometa: 'maometa',
  maonhes: 'maonhes',
  maonias: 'maônias',
  maozada: 'mãozada',
  maozota: 'mãozota',
  maozuda: 'mãozuda',
  maozudo: 'mãozudo',
  mapanai: 'mápanai',
  mapania: 'mapânia',
  maparas: 'maparás',
  mapares: 'maparés',
  maparis: 'maparis',
  mapatis: 'mapatis',
  mapeada: 'mapeada',
  mapeado: 'mapeado',
  mapeais: 'mapeais',
  mapeamo: 'mapeamo',
  mapeara: 'mapeara',
  mapeava: 'mapeava',
  mapeeis: 'mapeeis',
  mapeemo: 'mapeemo',
  mapeiam: 'mapeiam',
  mapeias: 'mapeias',
  mapeiem: 'mapeiem',
  mapeies: 'mapeies',
  mapeque: 'mapeque',
  maperoa: 'maperoá',
  mapiada: 'mapiada',
  mapiado: 'mapiado',
  mapiais: 'mapiais',
  mapiamo: 'mapiamo',
  mapiara: 'mapiara',
  mapiava: 'mapiava',
  mapicas: 'mapicas',
  mapichi: 'mapichi',
  mapicos: 'mapicos',
  mapidia: 'mapidiã',
  mapieis: 'mapieis',
  mapiemo: 'mapiemo',
  mapilas: 'mapilas',
  mapinha: 'mapinha',
  mapiras: 'mapiras',
  mapixis: 'mapixis',
  mapoios: 'mapoiós',
  mapoles: 'mapoles',
  maponas: 'maponas',
  maponga: 'maponga',
  mapooes: 'mapoões',
  mapotes: 'mapotes',
  mapsele: 'mapsele',
  mapucas: 'mapucas',
  mapuche: 'mapuche',
  mapulas: 'mapulas',
  mapunda: 'mapunda',
  mapupas: 'mapupas',
  mapuras: 'mapurás',
  maquefe: 'maquefe',
  maqueje: 'maqueje',
  maquela: 'maquela',
  maquera: 'maquera',
  maqueta: 'maqueta',
  maquete: 'maquete',
  maquezo: 'maquezo',
  maquiai: 'maquiai',
  maquiam: 'maquiam',
  maquiar: 'maquiar',
  maquias: 'maquias',
  maquidi: 'maquidi',
  maquiei: 'maquiei',
  maquiem: 'maquiem',
  maquies: 'maquies',
  maquila: 'maquila',
  maquile: 'maquile',
  maquilo: 'maquilo',
  maquina: 'máquina',
  maquine: 'maquiné',
  maquino: 'maquino',
  maquins: 'maquins',
  maquiou: 'maquiou',
  maquira: 'maquira',
  maquixa: 'maquixa',
  maquixo: 'maquixo',
  marabas: 'marabás',
  marabos: 'marabôs',
  marabus: 'marabus',
  maracao: 'maração',
  maracas: 'maracas',
  maraces: 'maraces',
  maracha: 'maracha',
  maracho: 'marachó',
  maracus: 'maracus',
  maradao: 'maradão',
  maradas: 'maradas',
  marador: 'marador',
  marados: 'marados',
  marafai: 'marafai',
  marafam: 'marafam',
  marafar: 'marafar',
  marafas: 'marafas',
  marafei: 'marafei',
  marafem: 'marafem',
  marafes: 'marafes',
  marafos: 'marafos',
  marafou: 'marafou',
  maragas: 'maragas',
  maragua: 'maraguá',
  maraias: 'maraiás',
  marajas: 'marajás',
  marajau: 'marajaú',
  marajoa: 'marajoa',
  marajos: 'marajós',
  maralha: 'maralha',
  maramba: 'marambá',
  maramos: 'maramos',
  maranas: 'maranas',
  marando: 'marando',
  maranga: 'maranga',
  maranha: 'maranha',
  maranhe: 'maranhe',
  maranho: 'maranho',
  maranis: 'maranis',
  maranos: 'maranos',
  maranta: 'maranta',
  marante: 'marante',
  marapas: 'marapás',
  marapes: 'marapés',
  marapua: 'marapuá',
  mararam: 'mararam',
  mararao: 'mararão',
  mararas: 'mararás',
  mararei: 'mararei',
  mararem: 'mararem',
  marares: 'marares',
  mararia: 'mararia',
  mararmo: 'mararmo',
  marasca: 'marasca',
  marasma: 'marasma',
  marasme: 'marasme',
  marasmo: 'marasmo',
  marasse: 'marasse',
  maraste: 'maraste',
  maratas: 'maratas',
  maratia: 'marátia',
  maratis: 'maratis',
  maratro: 'maratro',
  marauas: 'marauás',
  marauba: 'maraúba',
  maravam: 'maravam',
  maravas: 'maravas',
  maraves: 'maraves',
  marcada: 'marcada',
  marcado: 'marcado',
  marcaia: 'marcaia',
  marcais: 'marcais',
  marcamo: 'marcamo',
  marcana: 'marçana',
  marcano: 'marçano',
  marcara: 'marcara',
  marcava: 'marcava',
  marcega: 'marcegã',
  marceja: 'marceja',
  marceje: 'marceje',
  marcejo: 'marcejo',
  marcela: 'marcela',
  marchai: 'marchai',
  marchal: 'marchal',
  marcham: 'marcham',
  marchar: 'marchar',
  marchas: 'marchas',
  marchei: 'marchei',
  marchem: 'marchem',
  marches: 'marches',
  marchou: 'marchou',
  marcial: 'marcial',
  marcias: 'márcias',
  marcida: 'márcida',
  marcido: 'márcido',
  marcios: 'márcios',
  marcita: 'marcita',
  marconi: 'marconi',
  mardana: 'mardana',
  mardano: 'mardano',
  marduna: 'marduna',
  marduno: 'marduno',
  mareada: 'mareada',
  mareado: 'mareado',
  mareais: 'mareais',
  mareara: 'mareará',
  mareato: 'mareato',
  mareava: 'mareava',
  marecas: 'marecas',
  mareeis: 'mareeis',
  mareiam: 'mareiam',
  mareias: 'mareias',
  mareiem: 'mareiem',
  mareies: 'mareies',
  mareira: 'mareira',
  mareiro: 'mareiro',
  marejai: 'marejai',
  marejam: 'marejam',
  marejar: 'marejar',
  marejas: 'marejas',
  marejei: 'marejei',
  marejem: 'marejem',
  marejes: 'marejes',
  marejou: 'marejou',
  marelao: 'marelão',
  marelas: 'marelas',
  maremas: 'maremas',
  maremos: 'maremos',
  mareota: 'mareota',
  maresia: 'maresia',
  maretas: 'maretas',
  marfada: 'marfada',
  marfado: 'marfado',
  marfais: 'marfais',
  marfamo: 'marfamo',
  marfara: 'marfara',
  marfava: 'marfava',
  marfeis: 'marfeis',
  marfemo: 'marfemo',
  marfins: 'marfins',
  margaca: 'margaça',
  margada: 'margada',
  margado: 'margado',
  margais: 'margais',
  margamo: 'margamo',
  margara: 'margara',
  margava: 'margavá',
  margeai: 'margeai',
  margear: 'margear',
  margeei: 'margeei',
  margeia: 'margeia',
  margeie: 'margeie',
  margeio: 'margeio',
  margela: 'margela',
  margens: 'margens',
  margeou: 'margeou',
  margiam: 'margiam',
  margiao: 'margião',
  margias: 'margias',
  margida: 'margida',
  margido: 'margido',
  margimo: 'margimo',
  margina: 'margina',
  margine: 'margine',
  margino: 'margino',
  margios: 'margios',
  margira: 'margirá',
  margosa: 'margosa',
  margoso: 'margoso',
  margota: 'margota',
  margoto: 'margoto',
  marguei: 'marguei',
  marguem: 'marguem',
  margues: 'margues',
  marguis: 'marguis',
  mariada: 'mariada',
  mariado: 'mariado',
  mariais: 'mariais',
  mariama: 'mariama',
  mariana: 'mariana',
  mariano: 'mariano',
  mariate: 'mariaté',
  mariato: 'mariato',
  maribis: 'maribis',
  maricao: 'maricão',
  maricas: 'maricas',
  maricos: 'maricos',
  maridai: 'maridai',
  maridal: 'maridal',
  maridam: 'maridam',
  maridao: 'maridão',
  maridar: 'maridar',
  maridas: 'maridas',
  maridei: 'maridei',
  maridem: 'maridem',
  marides: 'marides',
  maridos: 'maridos',
  maridou: 'maridou',
  mariela: 'mariela',
  marigue: 'marigué',
  marigui: 'marigui',
  marilha: 'marilha',
  marilho: 'marilho',
  marimas: 'marimãs',
  marimba: 'marimba',
  marimbe: 'marimbe',
  marimbo: 'marimbo',
  marimbu: 'marimbu',
  marimos: 'marimos',
  marinai: 'marinai',
  marinam: 'marinam',
  marinar: 'marinar',
  marinas: 'marinas',
  marinei: 'marinei',
  marinem: 'marinem',
  marines: 'marines',
  maringa: 'maringá',
  marinha: 'marinha',
  marinhe: 'marinhe',
  marinho: 'marinho',
  marinim: 'marinim',
  marinos: 'marinôs',
  marinou: 'marinou',
  marinum: 'marinum',
  marioca: 'marioca',
  mariode: 'mariode',
  mariola: 'mariola',
  mariole: 'mariole',
  mariolo: 'mariolo',
  maripas: 'maripás',
  maripus: 'maripus',
  marique: 'mariqué',
  marisas: 'marisas',
  marisca: 'marisca',
  marisco: 'marisco',
  marisma: 'marisma',
  marista: 'marista',
  marital: 'marital',
  maritas: 'maritas',
  marites: 'marites',
  mariuos: 'mariuôs',
  marizal: 'marizal',
  marjoes: 'marjões',
  marlins: 'marlins',
  marlota: 'marlota',
  marlote: 'marlote',
  marloto: 'marloto',
  marmaca: 'marmaça',
  marmaco: 'marmaço',
  marmada: 'marmada',
  marmado: 'marmado',
  marmais: 'marmais',
  marmamo: 'marmamo',
  marmara: 'marmara',
  marmava: 'marmava',
  marmeis: 'marmeis',
  marmela: 'marmela',
  marmele: 'marmele',
  marmelo: 'marmelo',
  marmemo: 'marmemo',
  marmita: 'marmita',
  marmore: 'mármore',
  marmosa: 'marmosa',
  marmota: 'marmota',
  marmoto: 'marmoto',
  marneco: 'marneco',
  marneis: 'marnéis',
  marnete: 'marnete',
  marnoia: 'marnoia',
  marnosa: 'marnosa',
  marnoso: 'marnoso',
  marnota: 'marnota',
  marnoto: 'marnoto',
  marobas: 'marobas',
  marocas: 'marocas',
  marocha: 'marocha',
  marocho: 'marocho',
  maroico: 'maroiço',
  marolas: 'marolas',
  marolos: 'marolos',
  maromas: 'maromas',
  maromba: 'maromba',
  marombe: 'marombe',
  marombo: 'marombo',
  maromes: 'maromes',
  maronas: 'maronas',
  maronda: 'maronda',
  marones: 'maronês',
  maroneu: 'maroneu',
  maropas: 'maropas',
  marosca: 'marosca',
  marotas: 'marotas',
  marotea: 'maroteá',
  marotos: 'marotos',
  marouba: 'marouba',
  maroubo: 'maroubo',
  marouca: 'marouça',
  marouco: 'marouço',
  maroufa: 'maroufa',
  maroufo: 'maroufo',
  marouva: 'marouva',
  marovas: 'marovas',
  marquei: 'marquei',
  marquem: 'marquem',
  marques: 'marques',
  marraco: 'marraco',
  marrada: 'marrada',
  marrado: 'marrado',
  marrafa: 'marrafa',
  marraio: 'marraio',
  marrais: 'marrais',
  marramo: 'marramo',
  marrana: 'marrana',
  marrano: 'marrano',
  marrara: 'marrara',
  marrata: 'marrata',
  marraus: 'marraús',
  marrava: 'marrava',
  marraxa: 'marraxa',
  marraxo: 'marraxo',
  marreca: 'marreca',
  marreco: 'marreco',
  marreis: 'marreis',
  marrela: 'marrela',
  marremo: 'marremo',
  marreta: 'marreta',
  marrete: 'marrete',
  marreto: 'marreto',
  marrica: 'marricá',
  marrico: 'marrico',
  marries: 'marriés',
  marrins: 'marrins',
  marrita: 'marrita',
  marroai: 'marroai',
  marroam: 'marroam',
  marroar: 'marroar',
  marroas: 'marroas',
  marroaz: 'marroaz',
  marroca: 'marroca',
  marroco: 'marroco',
  marroei: 'marroei',
  marroem: 'marroem',
  marroes: 'marrões',
  marroio: 'marroio',
  marrona: 'marrona',
  marrons: 'marrons',
  marroou: 'marroou',
  marrote: 'marrote',
  marroxo: 'marroxo',
  marruas: 'marruás',
  marruaz: 'marruaz',
  marruca: 'marrucá',
  marruco: 'marruco',
  marrufo: 'marrufo',
  marruim: 'marruim',
  marsaca: 'mársaca',
  marsaco: 'mársaco',
  marsala: 'marsala',
  marsopa: 'marsopa',
  martaba: 'martabã',
  marteis: 'martéis',
  martela: 'martela',
  martele: 'martele',
  martelo: 'martelo',
  martini: 'martíni',
  martins: 'martins',
  martire: 'mártire',
  martita: 'martita',
  martite: 'martite',
  martuco: 'martuco',
  marubas: 'marubas',
  marubes: 'marubés',
  marucas: 'marucas',
  maruceu: 'maruceu',
  marufle: 'marufle',
  marufos: 'marufos',
  marugem: 'marugem',
  maruges: 'maruges',
  maruins: 'maruins',
  marujai: 'marujai',
  marujal: 'marujal',
  marujam: 'marujam',
  marujar: 'marujar',
  marujas: 'marujas',
  marujei: 'marujei',
  marujem: 'marujem',
  marujes: 'marujes',
  marujos: 'marujos',
  marujou: 'marujou',
  marulha: 'marulha',
  marulhe: 'marulhe',
  marulho: 'marulho',
  marulos: 'marulos',
  marumba: 'marumba',
  marumbe: 'marumbé',
  marumbi: 'marumbi',
  marumia: 'marúmia',
  marumos: 'marumos',
  marungo: 'marungo',
  marupai: 'marupaí',
  marupas: 'marupás',
  marurus: 'marurus',
  marutas: 'marutas',
  marvada: 'marvada',
  marvari: 'marvari',
  marzapo: 'marzapo',
  marzias: 'marzias',
  marzipa: 'marzipã',
  marzoco: 'marzoco',
  marzoes: 'marzões',
  masacas: 'masacás',
  masaita: 'masaíta',
  masangu: 'masangu',
  masapio: 'masápio',
  masaras: 'masaras',
  masares: 'masares',
  masaris: 'másaris',
  masasis: 'masasis',
  masatas: 'masatas',
  masatos: 'masatos',
  mascaba: 'mascabá',
  mascabe: 'mascabe',
  mascabo: 'mascabo',
  mascada: 'mascada',
  mascado: 'mascado',
  mascais: 'mascais',
  mascamo: 'mascamo',
  mascara: 'máscara',
  mascare: 'mascare',
  mascaro: 'mascaro',
  mascate: 'mascate',
  mascato: 'mascato',
  mascava: 'mascava',
  mascave: 'mascave',
  mascavo: 'mascavo',
  mascota: 'mascota',
  mascote: 'mascote',
  mascoto: 'mascoto',
  mascula: 'máscula',
  masculo: 'másculo',
  masdeia: 'masdeia',
  masdeus: 'masdeus',
  maseias: 'maseias',
  maseres: 'máseres',
  masmuda: 'masmuda',
  masongo: 'masongo',
  masorea: 'masórea',
  masoreo: 'masóreo',
  masoreu: 'masoreu',
  masquei: 'masquei',
  masquem: 'masquem',
  masques: 'masques',
  masquir: 'masquir',
  masrios: 'másrios',
  masrita: 'masrita',
  masrite: 'masrite',
  masrium: 'másrium',
  massaca: 'massacá',
  massada: 'massada',
  massado: 'massado',
  massais: 'massais',
  massaja: 'massaja',
  massaje: 'massaje',
  massajo: 'massajo',
  massala: 'massala',
  massame: 'massame',
  massamo: 'massamo',
  massape: 'massapé',
  massara: 'massará',
  massaus: 'massaus',
  massava: 'massava',
  masseis: 'masseis',
  massemo: 'massemo',
  masseve: 'masseve',
  massias: 'mássias',
  massica: 'mássica',
  massico: 'mássico',
  massila: 'massila',
  massilo: 'massilo',
  massita: 'massita',
  massiva: 'massiva',
  massivo: 'massivo',
  massoca: 'massoca',
  massona: 'massona',
  massora: 'massorá',
  massuas: 'massuas',
  massuca: 'massuca',
  massuco: 'massuco',
  massuda: 'massuda',
  massudo: 'massudo',
  massuis: 'massuis',
  massula: 'mássula',
  mastaba: 'mastaba',
  mastica: 'mástica',
  mastiga: 'mastiga',
  mastigo: 'mastigo',
  mastins: 'mastins',
  mastite: 'mastite',
  mastrea: 'mastreá',
  mastros: 'mastros',
  mastruz: 'mastruz',
  mastuca: 'mastuca',
  mastuco: 'mastuco',
  mastuga: 'mastuga',
  mastura: 'mastura',
  masurio: 'masúrio',
  matabas: 'matabas',
  matacao: 'matação',
  matacas: 'matacas',
  matacos: 'matacos',
  matadao: 'matadão',
  matadas: 'matadas',
  matades: 'matades',
  matador: 'matador',
  matados: 'matados',
  matagal: 'matagal',
  matagem: 'matagem',
  mataiba: 'mataíba',
  mataime: 'mataime',
  matamba: 'matamba',
  matambu: 'matambu',
  matames: 'matames',
  matamos: 'matamos',
  matanca: 'matança',
  matando: 'matando',
  matanga: 'matanga',
  matante: 'matante',
  matapas: 'matapás',
  matapim: 'matapim',
  matapis: 'matapis',
  mataram: 'mataram',
  matarao: 'matarão',
  mataras: 'mataras',
  matarei: 'matarei',
  matarem: 'matarem',
  matares: 'matares',
  matareu: 'mataréu',
  mataria: 'mataria',
  mataris: 'mataris',
  matariz: 'matariz',
  matarmo: 'matarmo',
  matarus: 'matarus',
  matassa: 'matassa',
  matasse: 'matasse',
  mataste: 'mataste',
  matateu: 'matateu',
  matavam: 'matavam',
  matavas: 'matavas',
  mateada: 'mateada',
  mateado: 'mateado',
  mateais: 'mateais',
  mateara: 'mateara',
  mateava: 'mateava',
  matebas: 'matebas',
  mateeis: 'mateeis',
  mateiam: 'mateiam',
  mateias: 'mateias',
  mateiem: 'mateiem',
  mateies: 'mateies',
  mateina: 'mateína',
  mateira: 'mateira',
  mateiro: 'mateiro',
  matejai: 'matejai',
  matejam: 'matejam',
  matejar: 'matejar',
  matejas: 'matejas',
  matejei: 'matejei',
  matejem: 'matejem',
  matejes: 'matejes',
  matejou: 'matejou',
  mateles: 'matelés',
  matemba: 'matemba',
  matemos: 'matemos',
  matenda: 'matenda',
  matense: 'matense',
  materes: 'máteres',
  materia: 'matéria',
  materio: 'matério',
  materna: 'materna',
  materno: 'materno',
  mateses: 'mateses',
  matetes: 'matetes',
  matevas: 'matevas',
  matiaca: 'matíaca',
  matiaco: 'matíaco',
  matiaru: 'matiaru',
  maticai: 'maticai',
  matical: 'matical',
  maticam: 'maticam',
  maticar: 'maticar',
  maticas: 'máticas',
  maticos: 'máticos',
  maticou: 'maticou',
  matidez: 'matidez',
  matilda: 'matilda',
  matilha: 'matilha',
  matilhe: 'matilhe',
  matilho: 'matilho',
  matinai: 'matinai',
  matinal: 'matinal',
  matinam: 'matinam',
  matinar: 'matinar',
  matinas: 'matinas',
  matinca: 'matinca',
  matinee: 'matinée',
  matinei: 'matinei',
  matinem: 'matinem',
  matines: 'matinês',
  matinha: 'matinha',
  matinho: 'matinho',
  matinou: 'matinou',
  matiola: 'matíola',
  matipui: 'matipuí',
  matipus: 'matipus',
  matique: 'matique',
  matirao: 'matirão',
  matiras: 'matiras',
  matiris: 'matiris',
  matisia: 'matísia',
  matismo: 'matismo',
  matista: 'matista',
  matitas: 'matitas',
  matizai: 'matizai',
  matizam: 'matizam',
  matizar: 'matizar',
  matizas: 'matizas',
  matizei: 'matizei',
  matizem: 'matizem',
  matizes: 'matizes',
  matizou: 'matizou',
  matodis: 'matodis',
  matoide: 'matoide',
  matolao: 'matolão',
  matolas: 'matolas',
  matombe: 'matombe',
  matombo: 'matombo',
  matomos: 'matomos',
  matonas: 'matonas',
  matondo: 'matondo',
  matonga: 'matonga',
  matonia: 'matonia',
  matopes: 'matopes',
  matosas: 'matosas',
  matosos: 'matosos',
  matraca: 'matraca',
  matraco: 'matraco',
  matrais: 'matrais',
  matraxi: 'matraxi',
  matriai: 'matriai',
  matriam: 'matriam',
  matriar: 'matriar',
  matrias: 'mátrias',
  matriei: 'matriei',
  matriem: 'matriem',
  matries: 'matries',
  matrios: 'mátrios',
  matriou: 'matriou',
  matriza: 'matriza',
  matrize: 'matrize',
  matrizo: 'matrizo',
  matroca: 'matroca',
  matroco: 'matroco',
  matrola: 'matrola',
  matrona: 'matrona',
  matruas: 'matruás',
  matruca: 'matruca',
  matruco: 'matruco',
  matsavo: 'matsavo',
  matucas: 'matucas',
  matuius: 'matuiús',
  matulao: 'matulão',
  matulas: 'matulas',
  matulaz: 'matulaz',
  matulos: 'matulos',
  matumas: 'matumas',
  matumba: 'matumba',
  matumbo: 'matumbo',
  matumos: 'matumos',
  matundo: 'matundo',
  matunga: 'matunga',
  matungo: 'matungo',
  matupas: 'matupás',
  maturai: 'maturai',
  maturam: 'maturam',
  maturar: 'maturar',
  maturas: 'maturas',
  maturei: 'maturei',
  maturem: 'maturem',
  matures: 'matures',
  maturim: 'maturim',
  maturis: 'maturis',
  maturou: 'maturou',
  maturus: 'maturus',
  matusca: 'matusca',
  matutai: 'matutai',
  matutam: 'matutam',
  matutar: 'matutar',
  matutas: 'matutas',
  matutei: 'matutei',
  matutem: 'matutem',
  matutes: 'matutes',
  matutis: 'matutis',
  matutos: 'matutos',
  matutou: 'matutou',
  mauacos: 'mauacos',
  mauacus: 'mauaçus',
  mauaias: 'mauaiás',
  mauaris: 'mauaris',
  mauauas: 'mauauas',
  maubere: 'maubere',
  mauense: 'mauense',
  mauezas: 'mauezas',
  maufita: 'maufita',
  mauindo: 'mauindo',
  maulide: 'maulide',
  mauncas: 'maunças',
  maundos: 'maundos',
  maungos: 'maungos',
  maureas: 'maúreas',
  maurina: 'maurina',
  maurino: 'maurino',
  mausita: 'mausita',
  mauzoes: 'mauzões',
  mauzona: 'mauzona',
  mauzote: 'mauzote',
  mavembe: 'mavembe',
  maveves: 'mavevés',
  maviosa: 'maviosa',
  mavioso: 'mavioso',
  mavique: 'mavique',
  mavitis: 'mavitis',
  mavorte: 'mavorte',
  mavumba: 'mavumba',
  mavumbo: 'mavumbo',
  mavuvis: 'mavuvis',
  maxacas: 'maxacas',
  maxamba: 'maxamba',
  maxanas: 'maxanas',
  maxapos: 'maxapós',
  maxaxas: 'maxaxas',
  maxenga: 'maxenga',
  maxenim: 'maxenim',
  maxesas: 'maxesas',
  maxibua: 'maxibua',
  maxilar: 'maxilar',
  maxilas: 'maxilas',
  maximal: 'maximal',
  maximar: 'maximar',
  maximas: 'máximas',
  maximbo: 'maximbo',
  maximes: 'máximes',
  maximos: 'máximos',
  maxinga: 'maxinga',
  maxinje: 'maxinje',
  maxiras: 'maxiras',
  maxissa: 'maxissa',
  maxitas: 'maxitas',
  maxites: 'maxites',
  maxixai: 'maxixai',
  maxixal: 'maxixal',
  maxixam: 'maxixam',
  maxixar: 'maxixar',
  maxixas: 'maxixas',
  maxixei: 'maxixei',
  maxixem: 'maxixem',
  maxixes: 'maxixes',
  maxixia: 'maxíxia',
  maxixou: 'maxixou',
  maxonas: 'maxonas',
  maxongo: 'maxongo',
  maxotos: 'maxotos',
  maxuais: 'maxuaís',
  maxubis: 'maxubis',
  maxumba: 'maxumba',
  maxuxos: 'maxuxos',
  maxwell: 'maxwell',
  mazacas: 'mázacas',
  mazaces: 'mázaces',
  mazaera: 'mazaera',
  mazagra: 'mazagrã',
  mazamas: 'mazamas',
  mazambo: 'mazambo',
  mazanas: 'mazanas',
  mazanes: 'mazanes',
  mazanga: 'mazanga',
  mazania: 'mazania',
  mazanza: 'mazanza',
  mazanze: 'mazanze',
  mazanzo: 'mazanzo',
  mazares: 'mazares',
  mazeias: 'mazeias',
  mazelai: 'mazelai',
  mazelam: 'mazelam',
  mazelar: 'mazelar',
  mazelas: 'mazelas',
  mazelei: 'mazelei',
  mazelem: 'mazelem',
  mazeles: 'mazeles',
  mazelou: 'mazelou',
  mazembe: 'mazembe',
  mazenze: 'mazenze',
  mazeras: 'mazeras',
  mazinda: 'mazinda',
  mazinha: 'mazinha',
  mazomba: 'mazomba',
  mazombo: 'mazombo',
  mazonga: 'mazonga',
  mazorca: 'mazorca',
  mazorro: 'mazorro',
  mazundo: 'mazundo',
  mazurca: 'mazurca',
  mazurco: 'mazurco',
  mazutes: 'mazutes',
  mazzita: 'mazzita',
  mbacaia: 'mbacaiá',
  mbatara: 'mbatará',
  mccciii: 'mccciii',
  mccclii: 'mccclii',
  mcccliv: 'mcccliv',
  mccclix: 'mccclix',
  mccclvi: 'mccclvi',
  mccclxi: 'mccclxi',
  mccclxv: 'mccclxv',
  mccclxx: 'mccclxx',
  mcccvii: 'mcccvii',
  mcccxci: 'mcccxci',
  mcccxcv: 'mcccxcv',
  mcccxii: 'mcccxii',
  mcccxiv: 'mcccxiv',
  mcccxix: 'mcccxix',
  mcccxli: 'mcccxli',
  mcccxlv: 'mcccxlv',
  mcccxvi: 'mcccxvi',
  mcccxxi: 'mcccxxi',
  mcccxxv: 'mcccxxv',
  mcccxxx: 'mcccxxx',
  mccliii: 'mccliii',
  mcclvii: 'mcclvii',
  mcclxii: 'mcclxii',
  mcclxiv: 'mcclxiv',
  mcclxix: 'mcclxix',
  mcclxvi: 'mcclxvi',
  mcclxxi: 'mcclxxi',
  mcclxxv: 'mcclxxv',
  mcclxxx: 'mcclxxx',
  mccviii: 'mccviii',
  mccxcii: 'mccxcii',
  mccxciv: 'mccxciv',
  mccxcix: 'mccxcix',
  mccxcvi: 'mccxcvi',
  mccxiii: 'mccxiii',
  mccxlii: 'mccxlii',
  mccxliv: 'mccxliv',
  mccxlix: 'mccxlix',
  mccxlvi: 'mccxlvi',
  mccxvii: 'mccxvii',
  mccxxii: 'mccxxii',
  mccxxiv: 'mccxxiv',
  mccxxix: 'mccxxix',
  mccxxvi: 'mccxxvi',
  mccxxxi: 'mccxxxi',
  mccxxxv: 'mccxxxv',
  mcdliii: 'mcdliii',
  mcdlvii: 'mcdlvii',
  mcdlxii: 'mcdlxii',
  mcdlxiv: 'mcdlxiv',
  mcdlxix: 'mcdlxix',
  mcdlxvi: 'mcdlxvi',
  mcdlxxi: 'mcdlxxi',
  mcdlxxv: 'mcdlxxv',
  mcdlxxx: 'mcdlxxx',
  mcdviii: 'mcdviii',
  mcdxcii: 'mcdxcii',
  mcdxciv: 'mcdxciv',
  mcdxcix: 'mcdxcix',
  mcdxcvi: 'mcdxcvi',
  mcdxiii: 'mcdxiii',
  mcdxlii: 'mcdxlii',
  mcdxliv: 'mcdxliv',
  mcdxlix: 'mcdxlix',
  mcdxlvi: 'mcdxlvi',
  mcdxvii: 'mcdxvii',
  mcdxxii: 'mcdxxii',
  mcdxxiv: 'mcdxxiv',
  mcdxxix: 'mcdxxix',
  mcdxxvi: 'mcdxxvi',
  mcdxxxi: 'mcdxxxi',
  mcdxxxv: 'mcdxxxv',
  mclviii: 'mclviii',
  mclxiii: 'mclxiii',
  mclxvii: 'mclxvii',
  mclxxii: 'mclxxii',
  mclxxiv: 'mclxxiv',
  mclxxix: 'mclxxix',
  mclxxvi: 'mclxxvi',
  mclxxxi: 'mclxxxi',
  mclxxxv: 'mclxxxv',
  mcmliii: 'mcmliii',
  mcmlvii: 'mcmlvii',
  mcmlxii: 'mcmlxii',
  mcmlxiv: 'mcmlxiv',
  mcmlxix: 'mcmlxix',
  mcmlxvi: 'mcmlxvi',
  mcmlxxi: 'mcmlxxi',
  mcmlxxv: 'mcmlxxv',
  mcmlxxx: 'mcmlxxx',
  mcmviii: 'mcmviii',
  mcmxcii: 'mcmxcii',
  mcmxciv: 'mcmxciv',
  mcmxcix: 'mcmxcix',
  mcmxcvi: 'mcmxcvi',
  mcmxiii: 'mcmxiii',
  mcmxlii: 'mcmxlii',
  mcmxliv: 'mcmxliv',
  mcmxlix: 'mcmxlix',
  mcmxlvi: 'mcmxlvi',
  mcmxvii: 'mcmxvii',
  mcmxxii: 'mcmxxii',
  mcmxxiv: 'mcmxxiv',
  mcmxxix: 'mcmxxix',
  mcmxxvi: 'mcmxxvi',
  mcmxxxi: 'mcmxxxi',
  mcmxxxv: 'mcmxxxv',
  mcxciii: 'mcxciii',
  mcxcvii: 'mcxcvii',
  mcxliii: 'mcxliii',
  mcxlvii: 'mcxlvii',
  mcxviii: 'mcxviii',
  mcxxiii: 'mcxxiii',
  mcxxvii: 'mcxxvii',
  mcxxxii: 'mcxxxii',
  mcxxxiv: 'mcxxxiv',
  mcxxxix: 'mcxxxix',
  mcxxxvi: 'mcxxxvi',
  mdcccii: 'mdcccii',
  mdccciv: 'mdccciv',
  mdcccix: 'mdcccix',
  mdcccli: 'mdcccli',
  mdccclv: 'mdccclv',
  mdccclx: 'mdccclx',
  mdcccvi: 'mdcccvi',
  mdcccxc: 'mdcccxc',
  mdcccxi: 'mdcccxi',
  mdcccxl: 'mdcccxl',
  mdcccxv: 'mdcccxv',
  mdcccxx: 'mdcccxx',
  mdcciii: 'mdcciii',
  mdcclii: 'mdcclii',
  mdccliv: 'mdccliv',
  mdcclix: 'mdcclix',
  mdcclvi: 'mdcclvi',
  mdcclxi: 'mdcclxi',
  mdcclxv: 'mdcclxv',
  mdcclxx: 'mdcclxx',
  mdccvii: 'mdccvii',
  mdccxci: 'mdccxci',
  mdccxcv: 'mdccxcv',
  mdccxii: 'mdccxii',
  mdccxiv: 'mdccxiv',
  mdccxix: 'mdccxix',
  mdccxli: 'mdccxli',
  mdccxlv: 'mdccxlv',
  mdccxvi: 'mdccxvi',
  mdccxxi: 'mdccxxi',
  mdccxxv: 'mdccxxv',
  mdccxxx: 'mdccxxx',
  mdcliii: 'mdcliii',
  mdclvii: 'mdclvii',
  mdclxii: 'mdclxii',
  mdclxiv: 'mdclxiv',
  mdclxix: 'mdclxix',
  mdclxvi: 'mdclxvi',
  mdclxxi: 'mdclxxi',
  mdclxxv: 'mdclxxv',
  mdclxxx: 'mdclxxx',
  mdcviii: 'mdcviii',
  mdcxcii: 'mdcxcii',
  mdcxciv: 'mdcxciv',
  mdcxcix: 'mdcxcix',
  mdcxcvi: 'mdcxcvi',
  mdcxiii: 'mdcxiii',
  mdcxlii: 'mdcxlii',
  mdcxliv: 'mdcxliv',
  mdcxlix: 'mdcxlix',
  mdcxlvi: 'mdcxlvi',
  mdcxvii: 'mdcxvii',
  mdcxxii: 'mdcxxii',
  mdcxxiv: 'mdcxxiv',
  mdcxxix: 'mdcxxix',
  mdcxxvi: 'mdcxxvi',
  mdcxxxi: 'mdcxxxi',
  mdcxxxv: 'mdcxxxv',
  mdlviii: 'mdlviii',
  mdlxiii: 'mdlxiii',
  mdlxvii: 'mdlxvii',
  mdlxxii: 'mdlxxii',
  mdlxxiv: 'mdlxxiv',
  mdlxxix: 'mdlxxix',
  mdlxxvi: 'mdlxxvi',
  mdlxxxi: 'mdlxxxi',
  mdlxxxv: 'mdlxxxv',
  mdxciii: 'mdxciii',
  mdxcvii: 'mdxcvii',
  mdxliii: 'mdxliii',
  mdxlvii: 'mdxlvii',
  mdxviii: 'mdxviii',
  mdxxiii: 'mdxxiii',
  mdxxvii: 'mdxxvii',
  mdxxxii: 'mdxxxii',
  mdxxxiv: 'mdxxxiv',
  mdxxxix: 'mdxxxix',
  mdxxxvi: 'mdxxxvi',
  meacaba: 'meaçaba',
  meacoes: 'meações',
  meacona: 'meaçona',
  meadade: 'meadade',
  meadoes: 'meadões',
  meadona: 'meadona',
  meadora: 'meadora',
  mealhai: 'mealhai',
  mealham: 'mealham',
  mealhar: 'mealhar',
  mealhas: 'mealhas',
  mealhei: 'mealhei',
  mealhem: 'mealhem',
  mealhes: 'mealhes',
  mealhos: 'mealhos',
  mealhou: 'mealhou',
  meambas: 'meambás',
  meandra: 'meandra',
  meandre: 'meandre',
  meandro: 'meandro',
  meantes: 'meantes',
  meaozao: 'meãozão',
  meardes: 'meardes',
  meareis: 'meáreis',
  meariam: 'meariam',
  mearias: 'mearias',
  mearmos: 'mearmos',
  meassem: 'meassem',
  measses: 'measses',
  meastes: 'meastes',
  meatica: 'meática',
  meatico: 'meático',
  meatite: 'meatite',
  meaveis: 'meáveis',
  meazona: 'meãzona',
  mebacos: 'mebacos',
  mebadis: 'mebádis',
  mebamba: 'mebamba',
  mebanda: 'mebanda',
  mebanga: 'mebanga',
  mebauas: 'mebauas',
  meberis: 'meberis',
  mebezua: 'mebezua',
  mebilas: 'mebilas',
  mebinji: 'mebínji',
  mebinze: 'mebinze',
  mebombo: 'mebombo',
  mebosas: 'mebosas',
  mebotas: 'mebotas',
  mebouas: 'mebouas',
  mebucas: 'mebucas',
  mebueia: 'mebueia',
  mebufos: 'mebufos',
  mebulas: 'mebulas',
  mebungu: 'mebungu',
  mebunje: 'mebunje',
  mebutas: 'mebutas',
  mebuzes: 'mebuzes',
  mecaias: 'mecaias',
  mecajus: 'mecajus',
  mecalas: 'mecalas',
  mecamos: 'meçamos',
  mecanal: 'mecanal',
  mecates: 'mecates',
  meceira: 'meceira',
  mecenas: 'mecenas',
  mecenos: 'mecenos',
  mechada: 'mechada',
  mechado: 'mechado',
  mechais: 'mechais',
  mechamo: 'mechamo',
  mechara: 'mechara',
  mechava: 'mechava',
  mecheis: 'mecheis',
  mechela: 'mechela',
  mechemo: 'mechemo',
  mechina: 'mechina',
  mechuas: 'mechuas',
  mecimbi: 'mecimbi',
  mecinas: 'mecinas',
  mecinho: 'mecinho',
  mecinos: 'mecinos',
  mecolas: 'mecolas',
  meconio: 'mecônio',
  mecotas: 'mecotas',
  mecovia: 'mecóvia',
  mecucos: 'mecucos',
  mecuias: 'mecuias',
  mecuris: 'mecuris',
  mecurre: 'mecurre',
  mecurro: 'mecurro',
  medalha: 'medalha',
  medalhe: 'medalhe',
  medalho: 'medalho',
  medanas: 'médanas',
  medanos: 'médanos',
  medeiam: 'medeiam',
  medeias: 'medeias',
  medeiem: 'medeiem',
  medeies: 'medeies',
  medeira: 'medeira',
  medeiro: 'medeiro',
  medelas: 'medelas',
  medelim: 'medelim',
  medemia: 'medêmia',
  medense: 'medense',
  medenta: 'medenta',
  medento: 'medento',
  medeola: 'medéola',
  medersa: 'medersa',
  mediada: 'mediada',
  mediado: 'mediado',
  mediais: 'mediais',
  mediamo: 'mediamo',
  mediana: 'mediana',
  mediano: 'mediano',
  mediara: 'mediara',
  mediata: 'mediata',
  mediate: 'mediate',
  mediato: 'mediato',
  mediava: 'mediava',
  medicai: 'medicai',
  medical: 'medical',
  medicam: 'medicam',
  medicao: 'medição',
  medicar: 'medicar',
  medicas: 'médicas',
  medicos: 'médicos',
  medicou: 'medicou',
  medicua: 'medícua',
  medidao: 'medidão',
  medidas: 'medidas',
  medidor: 'medidor',
  medidos: 'medidos',
  medieis: 'medieis',
  mediemo: 'mediemo',
  medievo: 'medievo',
  medimno: 'medimno',
  medimos: 'medimos',
  medinal: 'medinal',
  medinas: 'medinas',
  medindo: 'medindo',
  medinho: 'medinho',
  medioes: 'mediões',
  medique: 'medique',
  mediram: 'mediram',
  medirao: 'medirão',
  mediras: 'mediras',
  medirei: 'medirei',
  medirem: 'medirem',
  medires: 'medires',
  mediria: 'mediria',
  medirmo: 'medirmo',
  medismo: 'medismo',
  medisse: 'medisse',
  medista: 'medista',
  mediste: 'mediste',
  meditai: 'meditai',
  meditam: 'meditam',
  meditar: 'meditar',
  meditas: 'meditas',
  meditei: 'meditei',
  meditem: 'meditem',
  medites: 'medites',
  meditou: 'meditou',
  mediuns: 'médiuns',
  medivel: 'medível',
  medocho: 'medocho',
  medogos: 'medogos',
  medoico: 'medoiço',
  medonha: 'medonha',
  medonho: 'medonho',
  medonto: 'medonto',
  medorro: 'medorro',
  medouco: 'medouço',
  medrada: 'medrada',
  medrado: 'medrado',
  medrais: 'medrais',
  medramo: 'medramo',
  medrara: 'medrara',
  medrava: 'medrava',
  medreis: 'medreis',
  medremo: 'medremo',
  medrica: 'medrica',
  medrios: 'medrios',
  medrosa: 'medrosa',
  medroso: 'medroso',
  medruza: 'medruzã',
  meduico: 'meduíço',
  medular: 'medular',
  medulas: 'medulas',
  medulos: 'médulos',
  medunha: 'medunha',
  medusas: 'medusas',
  medusea: 'medúsea',
  meduseo: 'medúseo',
  meduseu: 'meduseu',
  meeiras: 'meeiras',
  meeiros: 'meeiros',
  meenses: 'meenses',
  meeting: 'meeting',
  mefinze: 'mefinze',
  megabar: 'megabar',
  megabia: 'megábia',
  megabit: 'megabit',
  megaerg: 'megaerg',
  megalas: 'megalas',
  megalia: 'megalia',
  megalio: 'megálio',
  megalos: 'megalos',
  meganha: 'meganha',
  megaohm: 'megaohm',
  megarad: 'megarad',
  megaras: 'mégaras',
  megaria: 'megária',
  megario: 'megário',
  megaros: 'mégaros',
  megaste: 'megaste',
  megaton: 'megaton',
  megatoo: 'megátoo',
  megeras: 'megeras',
  megohns: 'megohns',
  megonha: 'megonha',
  meguaia: 'meguaia',
  meiagua: 'meiágua',
  meidada: 'meidada',
  meidado: 'meidado',
  meideji: 'meideji',
  meieira: 'meieira',
  meieiro: 'meieiro',
  meieria: 'meiéria',
  meigada: 'meigada',
  meigado: 'meigado',
  meigais: 'meigais',
  meigara: 'meigará',
  meigava: 'meigava',
  meigosa: 'meigosa',
  meigoso: 'meigoso',
  meiguei: 'meiguei',
  meiguem: 'meiguem',
  meigues: 'meigues',
  meiinho: 'meiinho',
  meijoes: 'meijões',
  meilhas: 'meilhas',
  meimoes: 'meimões',
  meinaca: 'meinaca',
  meinaco: 'meinaco',
  meinins: 'meinins',
  meioses: 'meioses',
  meiotas: 'meiotas',
  meiotes: 'meiotes',
  meitade: 'meitade',
  meitega: 'meitega',
  meixoes: 'meixões',
  melacao: 'melação',
  melacos: 'melaços',
  meladao: 'meladão',
  meladas: 'meladas',
  melador: 'melador',
  melados: 'melados',
  melafas: 'melafas',
  melagem: 'melagem',
  melagra: 'melagra',
  melaina: 'melaína',
  melalas: 'melalas',
  melambo: 'melambo',
  melames: 'melames',
  melamio: 'melâmio',
  melamos: 'melamos',
  melampo: 'melampo',
  melanas: 'melanas',
  melanca: 'melança',
  melando: 'melando',
  melanea: 'melânea',
  melania: 'melania',
  melanio: 'melânio',
  melanos: 'melanos',
  melante: 'melante',
  melanto: 'melanto',
  melapio: 'melápio',
  melaram: 'melaram',
  melarao: 'melarão',
  melaras: 'melaras',
  melarei: 'melarei',
  melarem: 'melarem',
  melares: 'melares',
  melaria: 'melaria',
  melarmo: 'melarmo',
  melasma: 'melasma',
  melasmo: 'melasmo',
  melasse: 'melasse',
  melaste: 'melaste',
  melatos: 'melatos',
  melavam: 'melavam',
  melavas: 'melavas',
  meldola: 'meldola',
  melecar: 'melecar',
  melecas: 'meleças',
  melecta: 'melecta',
  meleias: 'meleias',
  meleira: 'meleira',
  meleiro: 'meleiro',
  melelas: 'melelas',
  meleles: 'meleles',
  melemos: 'melemos',
  melenas: 'melenas',
  melenos: 'melenos',
  melense: 'melense',
  melessa: 'melessa',
  melesse: 'melesse',
  melesso: 'melesso',
  meletas: 'meletas',
  meletes: 'meletes',
  melezes: 'melezes',
  melgaca: 'melgaça',
  melgaco: 'melgaço',
  melhora: 'melhora',
  melhore: 'melhore',
  melhoro: 'melhoro',
  meliana: 'meliana',
  meliano: 'meliano',
  melibeu: 'melibeu',
  melicas: 'mélicas',
  melicia: 'melícia',
  melicos: 'mélicos',
  melidas: 'melidas',
  melidos: 'melidos',
  melimba: 'melimba',
  melinas: 'melinas',
  melines: 'melines',
  melinos: 'melinos',
  melique: 'melique',
  melisma: 'melisma',
  melissa: 'melissa',
  melitas: 'melitas',
  melites: 'melites',
  meliteu: 'meliteu',
  melitia: 'melitia',
  melitos: 'melitos',
  melmosa: 'melmosa',
  meloais: 'meloais',
  melodia: 'melodia',
  melodie: 'melodie',
  melodio: 'melódio',
  melojes: 'melojes',
  melombe: 'melombe',
  melonge: 'melonge',
  melopes: 'melopes',
  melopos: 'melopos',
  melosal: 'melosal',
  melosas: 'melosas',
  meloses: 'meloses',
  melosos: 'melosos',
  melotas: 'melotas',
  melotes: 'melotes',
  melroas: 'mélroas',
  melroes: 'melrões',
  meltons: 'méltons',
  melungo: 'melungo',
  meluria: 'melúria',
  melurso: 'melurso',
  melusas: 'melusas',
  melzina: 'melzina',
  melzoes: 'melzões',
  membeca: 'membeca',
  membira: 'membira',
  membras: 'membras',
  membrax: 'mêmbrax',
  membros: 'membros',
  membura: 'membura',
  memento: 'memento',
  meminas: 'meminas',
  meminha: 'meminha',
  meminho: 'meminho',
  meminos: 'meminos',
  memnone: 'mêmnone',
  memorai: 'memorai',
  memoram: 'memoram',
  memorar: 'memorar',
  memoras: 'memoras',
  memorei: 'memorei',
  memorem: 'memorem',
  memores: 'memores',
  memoria: 'memória',
  memorie: 'memorie',
  memorio: 'memorio',
  memorou: 'memorou',
  memules: 'memulês',
  menabes: 'menabés',
  menaces: 'menaces',
  menacma: 'menacma',
  menacme: 'menacme',
  menadao: 'menadão',
  menadas: 'menadas',
  menades: 'mênades',
  menador: 'menador',
  menados: 'menados',
  menagem: 'menagem',
  menaica: 'menaica',
  menalia: 'menália',
  menalio: 'menálio',
  menamos: 'menamos',
  menando: 'menando',
  menante: 'menante',
  menapia: 'menápia',
  menapio: 'menápio',
  menaram: 'menaram',
  menarao: 'menarão',
  menaras: 'menarás',
  menarca: 'menarca',
  menarei: 'menarei',
  menarem: 'menarem',
  menares: 'menares',
  menaria: 'menaria',
  menarmo: 'menarmo',
  menasse: 'menasse',
  menaste: 'menaste',
  menavam: 'menavam',
  menavas: 'menavas',
  mencoes: 'menções',
  mencona: 'mençona',
  mendaca: 'mendaca',
  mendace: 'mendace',
  mendaco: 'mendaco',
  mendete: 'mendete',
  mendica: 'mendica',
  mendico: 'mendico',
  mendiga: 'mendiga',
  mendigo: 'mendigo',
  mendobi: 'mendobi',
  mendoca: 'mendoca',
  mendoes: 'mendões',
  mendola: 'mêndola',
  mendosa: 'mendosa',
  mendoso: 'mendoso',
  mendubi: 'mendubi',
  menduis: 'menduís',
  meneada: 'meneada',
  meneado: 'meneado',
  meneais: 'meneais',
  meneamo: 'meneamo',
  meneara: 'meneara',
  meneava: 'meneava',
  menebas: 'menebas',
  menecma: 'menecma',
  meneeis: 'meneeis',
  meneemo: 'meneemo',
  meneiam: 'meneiam',
  meneias: 'meneias',
  meneiem: 'meneiem',
  meneies: 'meneies',
  meneios: 'meneios',
  menelas: 'menelas',
  menemos: 'menemos',
  menenia: 'menênia',
  menenio: 'menênio',
  menente: 'menente',
  menenua: 'menenua',
  menepes: 'menepes',
  menequi: 'menequi',
  menesas: 'menesas',
  meneses: 'meneses',
  menesto: 'menesto',
  menevia: 'menévia',
  menfias: 'mênfias',
  menfios: 'mênfios',
  menfita: 'menfita',
  mengada: 'mengada',
  mengado: 'mengado',
  mengais: 'mengais',
  mengara: 'mengara',
  mengava: 'mengava',
  mengita: 'mengita',
  menguei: 'menguei',
  menguem: 'menguem',
  mengues: 'mengues',
  meniana: 'meniana',
  meniano: 'meniano',
  menidas: 'mênidas',
  menidea: 'menídea',
  menideo: 'menídeo',
  menidos: 'mênidos',
  meniens: 'meniéns',
  menilho: 'menilho',
  meninao: 'meninão',
  meninas: 'meninas',
  meninez: 'meninez',
  meninge: 'meninge',
  meninho: 'meninho',
  meninil: 'meninil',
  meninis: 'meninis',
  meninos: 'meninos',
  menipeu: 'menipeu',
  menires: 'menires',
  menisco: 'menisco',
  menismo: 'menismo',
  menista: 'menista',
  menopon: 'mênopon',
  menoras: 'menorás',
  menores: 'menores',
  mensais: 'mensais',
  mensana: 'mensana',
  mensola: 'mênsola',
  mensual: 'mensual',
  mensula: 'mênsula',
  mensura: 'mensura',
  mensure: 'mensure',
  mensuro: 'mensuro',
  mentada: 'mentada',
  mentado: 'mentado',
  mentais: 'mentais',
  mentala: 'mentalã',
  mentamo: 'mentamo',
  mentana: 'mentana',
  mentano: 'mentano',
  mentara: 'mentará',
  mentava: 'mentava',
  menteis: 'menteis',
  mentemo: 'mentemo',
  menteno: 'menteno',
  mentens: 'menténs',
  mentiam: 'mentiam',
  mentias: 'mentias',
  mentica: 'mêntica',
  mentico: 'mêntico',
  mentida: 'mentida',
  mentido: 'mentido',
  mentigo: 'mentigo',
  mentila: 'mentila',
  mentilo: 'mentilo',
  mentimo: 'mentimo',
  mentina: 'mentina',
  mentira: 'mentira',
  mentire: 'mentire',
  mentiro: 'mentiro',
  mentois: 'mentóis',
  mentona: 'mentona',
  mentora: 'mentora',
  mentore: 'mêntore',
  mentres: 'mentres',
  mentruz: 'mentruz',
  mentual: 'mentual',
  mentula: 'mêntula',
  mentulo: 'mêntulo',
  menuras: 'menuras',
  menuros: 'menuros',
  menuzis: 'menuzis',
  menzada: 'menzada',
  meolhas: 'meolhas',
  meonias: 'meônias',
  meonida: 'meônida',
  meonios: 'meônios',
  meotica: 'meótica',
  meotico: 'meótico',
  mepafus: 'mepafus',
  mepenje: 'mepenje',
  mepeque: 'mepeque',
  meperas: 'meperas',
  mepetes: 'mepetes',
  mepezas: 'mepezas',
  mepinda: 'mepinda',
  mepinge: 'mepinge',
  mepingo: 'mepingo',
  mepives: 'mepives',
  meporis: 'meporis',
  mepossa: 'mepossa',
  mepotos: 'mepotos',
  mepunga: 'mepunga',
  mepuris: 'mepuris',
  mepurus: 'mepurus',
  mequens: 'mequéns',
  meracas: 'meraças',
  meracos: 'meraços',
  meradas: 'meradas',
  merarca: 'merarca',
  meraros: 'merarós',
  mercaco: 'mercaço',
  mercada: 'mercada',
  mercado: 'mercado',
  mercais: 'mercais',
  mercamo: 'mercamo',
  mercana: 'mercana',
  mercara: 'mercara',
  mercava: 'mercava',
  merceai: 'merceai',
  mercear: 'mercear',
  merceei: 'merceei',
  merceia: 'merceia',
  merceie: 'merceie',
  merceio: 'merceio',
  merceou: 'merceou',
  mercias: 'mércias',
  mercios: 'mércios',
  mercola: 'mércola',
  mercuza: 'mercuzã',
  merdais: 'merdais',
  merdice: 'merdice',
  merdona: 'merdona',
  merdosa: 'merdosa',
  merdoso: 'merdoso',
  merecam: 'mereçam',
  merecas: 'mereças',
  merecei: 'merecei',
  merecem: 'merecem',
  merecer: 'merecer',
  mereces: 'mereces',
  mereceu: 'mereceu',
  merecia: 'merecia',
  merejai: 'merejai',
  merejam: 'merejam',
  merejar: 'merejar',
  merejas: 'merejas',
  merejei: 'merejei',
  merejem: 'merejem',
  merejes: 'merejes',
  merejou: 'merejou',
  merenda: 'merenda',
  merende: 'merende',
  merendo: 'merendo',
  merepes: 'merepes',
  mereres: 'mererés',
  meretiz: 'meretiz',
  mereuas: 'mereuás',
  mergada: 'mergada',
  mergado: 'mergado',
  mergais: 'mergais',
  mergara: 'mergará',
  mergava: 'mergava',
  merguei: 'merguei',
  merguem: 'merguem',
  mergues: 'mergues',
  mergula: 'mérgula',
  mergulo: 'mérgulo',
  meriana: 'meriana',
  meriano: 'meriano',
  merides: 'mérides',
  meridia: 'merídia',
  meridio: 'merídio',
  merinas: 'merinas',
  merinde: 'merinde',
  meringe: 'meringe',
  merinos: 'merinos',
  merioba: 'merioba',
  merisma: 'merisma',
  merista: 'merista',
  meristo: 'meristo',
  merital: 'merital',
  meritis: 'meritis',
  meritos: 'méritos',
  merleta: 'merleta',
  merlins: 'merlins',
  merloes: 'merlões',
  merluca: 'merluça',
  merluza: 'merluza',
  mermada: 'mermada',
  mermado: 'mermado',
  mermais: 'mermais',
  mermamo: 'mermamo',
  mermara: 'mermara',
  mermava: 'mermava',
  mermeis: 'mermeis',
  mermemo: 'mermemo',
  mermite: 'mermite',
  merocas: 'meroças',
  merodon: 'mérodon',
  meroena: 'meroena',
  meroeno: 'meroeno',
  meroita: 'meroíta',
  merolho: 'merolho',
  meronas: 'meronas',
  merongo: 'merongo',
  meropes: 'meropes',
  merotes: 'merotes',
  merouco: 'merouço',
  merougo: 'merougo',
  merquei: 'merquei',
  merquem: 'merquem',
  merques: 'merques',
  merreca: 'merreca',
  merrine: 'merrine',
  merrumi: 'merrumi',
  merucas: 'merucás',
  merufos: 'merufos',
  merugem: 'merugem',
  meruges: 'meruges',
  meruins: 'meruins',
  merujai: 'merujai',
  merujam: 'merujam',
  merujar: 'merujar',
  merujas: 'merujas',
  merujei: 'merujei',
  merujem: 'merujem',
  merujes: 'merujes',
  merujos: 'merujos',
  merujou: 'merujou',
  merulas: 'mérulas',
  merunga: 'merunga',
  merungo: 'merungo',
  merunha: 'merunhá',
  merunhe: 'merunhe',
  merunho: 'merunho',
  mervais: 'mervais',
  mesadas: 'mesadas',
  mesagra: 'mesagra',
  mesalia: 'mesália',
  mesamao: 'mesamão',
  mesamas: 'mesamãs',
  mesanga: 'mesanga',
  mesango: 'mesango',
  mesapia: 'mesápia',
  mesapio: 'mesápio',
  mesarca: 'mesarca',
  mesarco: 'mesarco',
  mesaria: 'mesária',
  mesario: 'mesário',
  mesaula: 'mesaula',
  mesaulo: 'mesaulo',
  mescaba: 'mescabá',
  mescabe: 'mescabe',
  mescabo: 'mescabo',
  mescada: 'mescada',
  mescado: 'mescado',
  mescais: 'mescais',
  mescamo: 'mescamo',
  mescara: 'mescará',
  mescava: 'mescava',
  mescias: 'méscias',
  mesclai: 'mesclai',
  mesclam: 'mesclam',
  mesclar: 'mesclar',
  mesclas: 'mesclas',
  mesclei: 'mesclei',
  mesclem: 'mesclem',
  mescles: 'mescles',
  mesclou: 'mesclou',
  mescoes: 'mescões',
  meselas: 'meselas',
  meselos: 'meselos',
  mesenha: 'mesenha',
  mesetas: 'mesetas',
  mesiais: 'mesiais',
  mesiate: 'mesiate',
  mesicas: 'mésicas',
  mesicos: 'mésicos',
  mesidio: 'mesídio',
  mesinga: 'mesinga',
  mesinha: 'mesinha',
  mesites: 'mesites',
  mesitol: 'mesitol',
  mesmice: 'mesmice',
  mesnada: 'mesnada',
  mesnado: 'mesnado',
  mesocra: 'mesocra',
  mesodos: 'mesodos',
  mesoide: 'mesoide',
  mesomio: 'mesômio',
  mesomis: 'mésomis',
  mesonas: 'mesonas',
  mesonio: 'mesônio',
  mesopes: 'mesopes',
  mesopse: 'mesopse',
  mesoris: 'mesoris',
  mesosas: 'mesosas',
  mesoton: 'mésoton',
  mespilo: 'méspilo',
  mesquei: 'mesquei',
  mesquem: 'mesquem',
  mesques: 'mesques',
  messada: 'messada',
  messado: 'messado',
  messais: 'messais',
  messamo: 'messamo',
  messara: 'messara',
  messava: 'messava',
  messefo: 'messefo',
  messeis: 'messeis',
  messemo: 'messemo',
  messene: 'messene',
  messias: 'messias',
  messiro: 'messiro',
  messoro: 'messoro',
  mestais: 'mestais',
  mesteis: 'mesteis',
  mestemo: 'mestemo',
  mestera: 'mesterá',
  mestiam: 'mestiam',
  mestias: 'mestias',
  mestica: 'mestiça',
  mestice: 'mestice',
  mestico: 'mestiço',
  mestida: 'mestida',
  mestido: 'mestido',
  mestoma: 'mestoma',
  mestral: 'mestral',
  mestrao: 'mestrão',
  mestras: 'mestras',
  mestrea: 'mestreá',
  mestres: 'mestres',
  mestria: 'mestria',
  mesuada: 'mesuada',
  mesurai: 'mesurai',
  mesuram: 'mesuram',
  mesurar: 'mesurar',
  mesuras: 'mesuras',
  mesurei: 'mesurei',
  mesurem: 'mesurem',
  mesures: 'mesures',
  mesurou: 'mesurou',
  mesusus: 'mesusus',
  metades: 'metades',
  metalei: 'metalei',
  metalia: 'metália',
  metamba: 'metamba',
  metamos: 'metamos',
  metanal: 'metanal',
  metanas: 'metanas',
  metanes: 'metanes',
  metanio: 'metânio',
  metanol: 'metanol',
  metanos: 'metanos',
  metapio: 'metápio',
  metaras: 'metaras',
  metargo: 'metargo',
  metasia: 'metásia',
  metasta: 'metasta',
  metaste: 'metaste',
  metasto: 'metasto',
  metator: 'metator',
  metaxia: 'metáxia',
  metecao: 'meteção',
  metecas: 'metecas',
  metecio: 'metécio',
  metecos: 'metecos',
  metedor: 'metedor',
  metemos: 'metemos',
  metenas: 'metenas',
  metendo: 'metendo',
  metenos: 'metenos',
  metense: 'metense',
  meteoro: 'meteoro',
  meteram: 'meteram',
  meterao: 'meterão',
  meteras: 'meteras',
  meterei: 'meterei',
  meterem: 'meterem',
  meteres: 'meteres',
  meteria: 'meteria',
  metermo: 'metermo',
  metesse: 'metesse',
  meteste: 'meteste',
  metetes: 'metetes',
  metexis: 'metexis',
  metiana: 'metiana',
  metibas: 'metibas',
  metical: 'metical',
  meticao: 'metição',
  metidao: 'metidão',
  metidas: 'metidas',
  metidos: 'metidos',
  metidro: 'metidro',
  metieis: 'metíeis',
  metilai: 'metilai',
  metilal: 'metilal',
  metilam: 'metilam',
  metilar: 'metilar',
  metilas: 'metilas',
  metilei: 'metilei',
  metilem: 'metilem',
  metiles: 'metiles',
  metilol: 'metilol',
  metilou: 'metilou',
  metinas: 'metinás',
  metinos: 'metinos',
  metiris: 'metiris',
  metocas: 'metocas',
  metocho: 'metocho',
  metodos: 'métodos',
  metoita: 'metoíta',
  metolia: 'metólia',
  metombe: 'metombe',
  metopas: 'métopas',
  metopes: 'métopes',
  metopia: 'metopia',
  metopio: 'metópio',
  metopos: 'métopos',
  metoria: 'metória',
  metoses: 'metoses',
  metrada: 'metrada',
  metrado: 'metrado',
  metrais: 'metrais',
  metramo: 'metramo',
  metrara: 'metrará',
  metrava: 'metrava',
  metreco: 'metreco',
  metreis: 'metreis',
  metremo: 'metremo',
  metreta: 'metreta',
  metrias: 'metrias',
  metrica: 'métrica',
  metrico: 'métrico',
  metrite: 'metrite',
  metubis: 'metúbis',
  metulas: 'métulas',
  metxina: 'metxina',
  meudear: 'meudear',
  meulzao: 'meulzão',
  mexamos: 'mexamos',
  mexates: 'mexates',
  mexecao: 'mexeção',
  mexedia: 'mexedia',
  mexedio: 'mexedio',
  mexedor: 'mexedor',
  mexefos: 'mexefos',
  mexelha: 'mexelha',
  mexelhe: 'mexelhe',
  mexelho: 'mexelho',
  mexelim: 'mexelim',
  mexemos: 'mexemos',
  mexendo: 'mexendo',
  mexeram: 'mexeram',
  mexerao: 'mexerão',
  mexeras: 'mexeras',
  mexerei: 'mexerei',
  mexerem: 'mexerem',
  mexeres: 'mexeres',
  mexeria: 'mexeria',
  mexermo: 'mexermo',
  mexesse: 'mexesse',
  mexeste: 'mexeste',
  mexicao: 'mexição',
  mexidao: 'mexidão',
  mexidas: 'mexidas',
  mexidia: 'mexidia',
  mexidio: 'mexidio',
  mexidos: 'mexidos',
  mexieis: 'mexíeis',
  mexilha: 'mexilhá',
  mexilhe: 'mexilhe',
  mexilho: 'mexilho',
  mexinga: 'mexinga',
  mexiras: 'mexiras',
  mexires: 'mexires',
  mexivel: 'mexível',
  mexudas: 'mexudas',
  mezande: 'mezande',
  mezeias: 'mezeias',
  mezenas: 'mezenas',
  mezereu: 'mezeréu',
  meziias: 'mezíias',
  mezinha: 'mezinha',
  mezinhe: 'mezinhe',
  mezinho: 'mezinho',
  mezungo: 'mezungo',
  mezuzas: 'mezuzás',
  mfucuas: 'mfúcuas',
  miacaba: 'miaçaba',
  miaceas: 'miáceas',
  miaceos: 'miáceos',
  miadela: 'miadela',
  miadoes: 'miadões',
  miadona: 'miadona',
  miadora: 'miadora',
  miadura: 'miadura',
  miagros: 'miagros',
  mialate: 'mialate',
  mialgia: 'mialgia',
  mialhar: 'mialhar',
  miambas: 'miambas',
  miamias: 'miamiás',
  miamina: 'miamina',
  mianada: 'mianada',
  miantes: 'miantes',
  miapias: 'miapias',
  miardes: 'miardes',
  miareis: 'miareis',
  miaremo: 'miaremo',
  miariam: 'miariam',
  miarias: 'miarias',
  miarios: 'miários',
  miarmos: 'miarmos',
  miasmai: 'miasmai',
  miasmam: 'miasmam',
  miasmar: 'miasmar',
  miasmas: 'miasmas',
  miasmei: 'miasmei',
  miasmem: 'miasmem',
  miasmes: 'miasmes',
  miasmou: 'miasmou',
  miassem: 'miassem',
  miasses: 'miasses',
  miastes: 'miastes',
  miastor: 'miastor',
  miaveis: 'miáveis',
  miazais: 'miazais',
  miazina: 'miazina',
  miboras: 'miboras',
  micacea: 'micácea',
  micaceo: 'micáceo',
  micadas: 'micadas',
  micados: 'micados',
  micagem: 'micagem',
  micaias: 'micaias',
  micalex: 'micalex',
  micalos: 'miçalos',
  micanga: 'miçanga',
  micania: 'micânia',
  micante: 'micante',
  micares: 'micares',
  micaria: 'micária',
  micarta: 'micarta',
  miccoes: 'micções',
  micedio: 'micédio',
  micelas: 'micelas',
  micelio: 'micélio',
  micenas: 'micenas',
  micenea: 'micênea',
  miceneu: 'miceneu',
  micenia: 'micênia',
  micenio: 'micênio',
  micenos: 'micenos',
  micetas: 'micetas',
  micetes: 'micetes',
  michada: 'michada',
  michado: 'michado',
  michais: 'michais',
  michamo: 'michamo',
  michana: 'michana',
  michano: 'michano',
  michara: 'michará',
  michava: 'michava',
  micheis: 'micheis',
  michela: 'michela',
  michelo: 'michelo',
  michemo: 'michemo',
  michola: 'michola',
  michole: 'michole',
  michosa: 'michosa',
  micipas: 'micipas',
  micocos: 'micocós',
  micoina: 'micoína',
  micolos: 'micolos',
  micondo: 'micondó',
  miconia: 'micônia',
  miconio: 'micônio',
  micoses: 'micoses',
  micotas: 'micotas',
  micrana: 'micrana',
  micrano: 'micrano',
  micreca: 'micreca',
  micrelo: 'micrelo',
  micrina: 'micrina',
  micrito: 'micrito',
  microhm: 'microhm',
  microma: 'microma',
  microns: 'mícrons',
  micropo: 'mícropo',
  microto: 'mícroto',
  micrura: 'micrura',
  micruro: 'micruro',
  mictada: 'mictada',
  mictado: 'mictado',
  mictais: 'mictais',
  mictamo: 'mictamo',
  mictara: 'mictará',
  mictava: 'mictava',
  micteis: 'micteis',
  mictemo: 'mictemo',
  mictero: 'mictero',
  mictios: 'mictios',
  mictual: 'mictual',
  micuins: 'micuins',
  miculas: 'miculas',
  micunco: 'micunco',
  micures: 'micurês',
  midanes: 'midanes',
  mideias: 'mideias',
  mideira: 'mideira',
  mideiro: 'mideiro',
  mideses: 'mideses',
  midinas: 'midinas',
  midinos: 'midinos',
  midique: 'midique',
  midrois: 'midróis',
  miedema: 'miedema',
  mielica: 'miélica',
  mielico: 'miélico',
  mielina: 'mielina',
  mielite: 'mielite',
  mielois: 'miélois',
  mieloma: 'mieloma',
  mielose: 'mielose',
  miembes: 'miembes',
  miemita: 'miemita',
  miemite: 'miemite',
  miengos: 'miengos',
  miersia: 'miérsia',
  miesita: 'miesita',
  miesite: 'miesite',
  mifadol: 'mifadol',
  mifongo: 'mifongo',
  migadao: 'migadão',
  migadas: 'migadas',
  migador: 'migador',
  migados: 'migados',
  migalas: 'mígalas',
  migalha: 'migalha',
  migalhe: 'migalhe',
  migalho: 'migalho',
  migamos: 'migamos',
  migando: 'migando',
  migante: 'migante',
  migaram: 'migaram',
  migarao: 'migarão',
  migaras: 'migaras',
  migarei: 'migarei',
  migarem: 'migarem',
  migares: 'migares',
  migaria: 'migaria',
  migasse: 'migasse',
  migaste: 'migaste',
  migavam: 'migavam',
  migavas: 'migavas',
  migdoas: 'migdoas',
  migdoes: 'migdões',
  migdone: 'mígdone',
  miginda: 'miginda',
  migosas: 'migosas',
  migosos: 'migosos',
  migrada: 'migrada',
  migrado: 'migrado',
  migrais: 'migrais',
  migramo: 'migramo',
  migrans: 'migrans',
  migrara: 'migrara',
  migrava: 'migrava',
  migreis: 'migreis',
  migremo: 'migremo',
  migueis: 'migueis',
  miguins: 'miguins',
  migurno: 'migurno',
  miiagra: 'miiagra',
  miiarco: 'miiarco',
  miiases: 'miíases',
  miiobio: 'miióbio',
  mijacao: 'mijação',
  mijadao: 'mijadão',
  mijadas: 'mijadas',
  mijador: 'mijador',
  mijados: 'mijados',
  mijamos: 'mijamos',
  mijando: 'mijando',
  mijante: 'mijante',
  mijaram: 'mijaram',
  mijarao: 'mijarão',
  mijaras: 'mijaras',
  mijarei: 'mijarei',
  mijarem: 'mijarem',
  mijares: 'mijares',
  mijaria: 'mijaria',
  mijarmo: 'mijarmo',
  mijasse: 'mijasse',
  mijaste: 'mijaste',
  mijavam: 'mijavam',
  mijavas: 'mijavas',
  mijemos: 'mijemos',
  mijicao: 'mijicão',
  mijinas: 'mijinas',
  mijinha: 'mijinha',
  mijniha: 'mijniha',
  mijocas: 'mijocas',
  mijolos: 'mijolos',
  mijonas: 'mijonas',
  mijotes: 'mijotes',
  mijubas: 'mijubas',
  milabre: 'milabre',
  milagre: 'milagre',
  milando: 'milando',
  milanes: 'milanês',
  milaras: 'milaras',
  milaseu: 'milaseu',
  milasia: 'milásia',
  milasio: 'milásio',
  milavos: 'milavos',
  mildios: 'míldios',
  milecia: 'milecia',
  milegra: 'milegrã',
  mileias: 'mileias',
  milenar: 'milenar',
  milenas: 'milenas',
  milenia: 'milênia',
  milenio: 'milênio',
  milenta: 'milenta',
  milento: 'milento',
  milesia: 'milésia',
  milesio: 'milésio',
  miletes: 'miletes',
  miletia: 'milétia',
  milfose: 'milfose',
  milgras: 'milgrás',
  milhaca: 'milhaça',
  milhada: 'milhada',
  milhado: 'milhado',
  milhafo: 'milhafo',
  milhais: 'milhais',
  milhana: 'milhana',
  milhano: 'milhano',
  milhara: 'mílhara',
  milharo: 'milharó',
  milheai: 'milheai',
  milhear: 'milhear',
  milhedo: 'milhedo',
  milheei: 'milheei',
  milheia: 'milheia',
  milheie: 'milheie',
  milheio: 'milheio',
  milhens: 'milhéns',
  milheou: 'milheou',
  milhete: 'milhete',
  milheto: 'milheto',
  milheus: 'milheus',
  milhoal: 'milhoal',
  milhoes: 'milhões',
  miliana: 'miliana',
  miliano: 'miliano',
  miliare: 'miliare',
  miliase: 'milíase',
  milibar: 'milibar',
  milicas: 'milicas',
  milicia: 'milícia',
  milicie: 'milicie',
  milicos: 'milicos',
  miligal: 'miligal',
  miligra: 'miligrã',
  mililux: 'mililux',
  milimas: 'mílimas',
  milimbo: 'milimbo',
  milimol: 'milimol',
  milimos: 'mílimos',
  milindo: 'milindó',
  miliohm: 'miliohm',
  miliola: 'milíola',
  miliolo: 'milíolo',
  milirem: 'milirém',
  militai: 'militai',
  militam: 'militam',
  militar: 'militar',
  militas: 'militas',
  militei: 'militei',
  militem: 'militem',
  milites: 'milites',
  militou: 'militou',
  miliusa: 'miliúsa',
  milocas: 'milocas',
  miloide: 'miloide',
  milolas: 'milolas',
  milolos: 'milolós',
  milombe: 'milombe',
  milonas: 'milonas',
  milondo: 'milondo',
  milonga: 'milonga',
  milongo: 'milongo',
  milorde: 'milorde',
  milreus: 'milreus',
  milundo: 'milundo',
  milvina: 'milvina',
  milvios: 'mílvios',
  milvulo: 'milvulo',
  mimacas: 'mimaças',
  mimadao: 'mimadão',
  mimadas: 'mimadas',
  mimador: 'mimador',
  mimados: 'mimados',
  mimalha: 'mimalha',
  mimalho: 'mimalho',
  mimalon: 'mimálon',
  mimalos: 'mimalos',
  mimamos: 'mimamos',
  mimanca: 'mimança',
  mimanco: 'mimanço',
  mimando: 'mimando',
  mimansa: 'mimansa',
  mimante: 'mimante',
  mimaram: 'mimaram',
  mimarao: 'mimarão',
  mimaras: 'mimaras',
  mimarei: 'mimarei',
  mimarem: 'mimarem',
  mimares: 'mimares',
  mimaria: 'mimaria',
  mimarmo: 'mimarmo',
  mimasse: 'mimasse',
  mimaste: 'mimaste',
  mimavam: 'mimavam',
  mimavas: 'mimavas',
  mimavel: 'mimável',
  mimbano: 'mimbano',
  mimbuia: 'mimbuia',
  mimbura: 'mimbura',
  mimecos: 'mimecos',
  mimeira: 'mimeira',
  mimemos: 'mimemos',
  mimenta: 'mimenta',
  mimento: 'mimento',
  mimeses: 'mimeses',
  mimesis: 'mimésis',
  mimetes: 'mimetes',
  mimetos: 'mimetos',
  mimicai: 'mimicai',
  mimicam: 'mimicam',
  mimicar: 'mimicar',
  mimicas: 'mímicas',
  mimices: 'mimices',
  mimicos: 'mímicos',
  mimicou: 'mimicou',
  mimidas: 'mímidas',
  mimidea: 'mimídea',
  mimideo: 'mimídeo',
  miminho: 'miminho',
  mimique: 'mimique',
  mimosas: 'mimosas',
  mimosea: 'mimoseá',
  mimoseo: 'mimóseo',
  mimosos: 'mimosos',
  mimulos: 'mímulos',
  minaces: 'minaces',
  minacus: 'minacus',
  minadao: 'minadão',
  minadas: 'minadas',
  minador: 'minador',
  minados: 'minados',
  minagem: 'minagem',
  minamos: 'minamos',
  minanas: 'minanas',
  minanco: 'minanço',
  minando: 'minando',
  minanei: 'minanei',
  minante: 'minante',
  minapes: 'minapes',
  minaram: 'minaram',
  minarao: 'minarão',
  minaras: 'minaras',
  minarei: 'minarei',
  minarem: 'minarem',
  minares: 'minares',
  minaria: 'minaria',
  minarmo: 'minarmo',
  minasse: 'minasse',
  minaste: 'minaste',
  minavam: 'minavam',
  minavas: 'minavas',
  minavel: 'minável',
  minazes: 'minazes',
  minccao: 'mincção',
  minchas: 'minchas',
  minches: 'minches',
  mincoes: 'minções',
  mindins: 'mindins',
  mindjam: 'mindjam',
  minduba: 'minduba',
  mindubi: 'mindubi',
  minduri: 'minduri',
  mindzas: 'mindzãs',
  mineana: 'mineana',
  mineano: 'mineano',
  mineias: 'mineias',
  mineira: 'mineira',
  mineiro: 'mineiro',
  minemos: 'minemos',
  minense: 'minense',
  minerai: 'minerai',
  mineral: 'mineral',
  mineram: 'mineram',
  minerar: 'minerar',
  mineras: 'mineras',
  minerei: 'minerei',
  minerem: 'minerem',
  mineres: 'mineres',
  minerio: 'minério',
  minerou: 'minerou',
  minerva: 'minerva',
  minetea: 'mineteá',
  minetes: 'minetes',
  mingada: 'mingada',
  mingado: 'mingado',
  mingais: 'mingais',
  mingara: 'mingará',
  mingaus: 'mingaus',
  mingava: 'mingava',
  mingola: 'mingola',
  mingote: 'mingote',
  minguai: 'minguai',
  minguam: 'mínguam',
  minguar: 'minguar',
  minguas: 'mínguas',
  minguei: 'minguei',
  minguem: 'minguem',
  mingues: 'mingues',
  minguou: 'minguou',
  minguta: 'minguta',
  minhafo: 'minhafo',
  minhana: 'minhana',
  minhoca: 'minhoca',
  minhoco: 'minhoco',
  minhoes: 'minhões',
  minhota: 'minhota',
  minhoto: 'minhoto',
  miniada: 'miniada',
  miniado: 'miniado',
  miniais: 'miniais',
  miniamo: 'miniamo',
  miniana: 'miniana',
  miniano: 'miniano',
  miniara: 'miniará',
  miniava: 'miniava',
  minibar: 'minibar',
  minibus: 'minibus',
  minieis: 'minieis',
  miniemo: 'miniemo',
  minimai: 'minimai',
  minimal: 'minimal',
  minimam: 'minimam',
  minimar: 'minimar',
  minimas: 'mínimas',
  minimax: 'minimax',
  minimei: 'minimei',
  minimem: 'minimem',
  minimes: 'minimes',
  minimos: 'mínimos',
  minimou: 'minimou',
  minimum: 'minimum',
  miniovo: 'miniovo',
  minivan: 'minivan',
  minjolo: 'minjolo',
  minoana: 'minoana',
  minoano: 'minoano',
  minoica: 'minoica',
  minoico: 'minoico',
  minorai: 'minorai',
  minoram: 'minoram',
  minorar: 'minorar',
  minoras: 'minoras',
  minorca: 'minorca',
  minorei: 'minorei',
  minorem: 'minorem',
  minores: 'minores',
  minoria: 'minoria',
  minorou: 'minorou',
  minotas: 'minotas',
  minotos: 'minotos',
  mintais: 'mintais',
  mintamo: 'mintamo',
  mintois: 'mintóis',
  minuais: 'minuais',
  minuana: 'minuana',
  minuano: 'minuano',
  minucao: 'minução',
  minucia: 'minúcia',
  minucie: 'minucie',
  minucio: 'minucio',
  minudai: 'minudai',
  minudam: 'minudam',
  minudar: 'minudar',
  minudas: 'minudas',
  minudea: 'minudeá',
  minudei: 'minudei',
  minudem: 'minudem',
  minudes: 'minudes',
  minudou: 'minudou',
  minuete: 'minuete',
  minueto: 'minueto',
  minuiam: 'minuíam',
  minuias: 'minuías',
  minuida: 'minuída',
  minuido: 'minuído',
  minuira: 'minuíra',
  minulas: 'mínulas',
  minunga: 'minunga',
  minungo: 'minungo',
  minutai: 'minutai',
  minutam: 'minutam',
  minutar: 'minutar',
  minutas: 'minutas',
  minutei: 'minutei',
  minutem: 'minutem',
  minutes: 'minutes',
  minutor: 'minutor',
  minutos: 'minutos',
  minutou: 'minutou',
  miobaro: 'mióbaro',
  miocama: 'miócama',
  miocele: 'miocele',
  miocena: 'miocena',
  mioceno: 'mioceno',
  miocito: 'miócito',
  miocoma: 'miocoma',
  miodema: 'miodema',
  miodite: 'miodite',
  miodora: 'miodora',
  miofila: 'miófila',
  miofilo: 'miófilo',
  miofone: 'miofone',
  mioforo: 'mióforo',
  miogale: 'miógale',
  miogena: 'miógena',
  miogeno: 'miógeno',
  mioglia: 'mióglia',
  miogona: 'miógona',
  miogono: 'miógono',
  mioides: 'mioides',
  miolada: 'miolada',
  miolema: 'miolema',
  miolhas: 'miolhas',
  miolise: 'miólise',
  miologa: 'mióloga',
  miologo: 'miólogo',
  miolosa: 'miolosa',
  mioloso: 'mioloso',
  mioluda: 'mioluda',
  mioludo: 'mioludo',
  miombas: 'miombas',
  miombos: 'miombos',
  miomero: 'miômero',
  mionema: 'mionema',
  miongas: 'miongas',
  miongos: 'miongos',
  mionite: 'mionite',
  mionose: 'mionose',
  mioparo: 'mióparo',
  miopias: 'miopias',
  miopica: 'miópica',
  miopico: 'miópico',
  mioporo: 'mióporo',
  miosina: 'miosina',
  miosite: 'miosite',
  miosota: 'miosota',
  miosote: 'miosote',
  miosuro: 'miosuro',
  miotica: 'miótica',
  miotico: 'miótico',
  miotina: 'miotina',
  miotomo: 'miótomo',
  miotono: 'miótono',
  miqueai: 'miqueai',
  miquear: 'miquear',
  miqueei: 'miqueei',
  miqueia: 'miqueia',
  miqueie: 'miqueie',
  miqueio: 'miqueio',
  miqueou: 'miqueou',
  miquete: 'miquete',
  mirabes: 'mirabes',
  mirabil: 'mirábil',
  mirabis: 'mirábis',
  miracao: 'miração',
  miradao: 'miradão',
  miradas: 'miradas',
  mirador: 'mirador',
  mirados: 'mirados',
  mirafra: 'mirafra',
  miragem: 'miragem',
  miraias: 'miraias',
  miraita: 'miraitá',
  miramar: 'miramar',
  miramos: 'miramos',
  miranas: 'miranas',
  mirando: 'mirando',
  miranha: 'miranha',
  mirante: 'mirante',
  miraobi: 'miraobi',
  miraram: 'miraram',
  mirarao: 'mirarão',
  miraras: 'miraras',
  mirarei: 'mirarei',
  mirarem: 'mirarem',
  mirares: 'mirares',
  miraria: 'miraria',
  mirarmo: 'mirarmo',
  mirasol: 'mirasol',
  mirasse: 'mirasse',
  miraste: 'miraste',
  miratoa: 'miratoá',
  miratua: 'miratuá',
  mirauba: 'miraúba',
  miravam: 'miravam',
  miravas: 'miravas',
  miravel: 'mirável',
  mirbane: 'mirbane',
  mirceno: 'mirceno',
  mircias: 'mírcias',
  mirdita: 'mirdita',
  miremos: 'miremos',
  mirense: 'mirense',
  miriada: 'miríada',
  miriade: 'miríade',
  miriana: 'miriana',
  miriare: 'miriare',
  mirical: 'mirical',
  miricas: 'miricas',
  miricea: 'mirícea',
  miriceo: 'miríceo',
  miricis: 'miricis',
  miridea: 'mirídea',
  mirideo: 'mirídeo',
  miridio: 'mirídio',
  mirinas: 'mirinas',
  mirinea: 'mirínea',
  mirineo: 'miríneo',
  miringe: 'miringe',
  miriqui: 'miriqui',
  miritis: 'miritis',
  mirixis: 'mirixis',
  mirmece: 'mirmece',
  mirmica: 'mirmica',
  mirmila: 'mirmila',
  mirmois: 'mirmóis',
  mirmosa: 'mirmosa',
  mirofis: 'mírofis',
  mirolea: 'mirólea',
  miroleo: 'miróleo',
  mirolha: 'mirolha',
  mirolho: 'mirolho',
  mironai: 'mironai',
  mironam: 'mironam',
  mironar: 'mironar',
  mironas: 'mironas',
  mironei: 'mironei',
  mironem: 'mironem',
  mirones: 'mirones',
  mironga: 'mironga',
  mironou: 'mironou',
  miroros: 'mirorós',
  mirosma: 'mirosma',
  mirrada: 'mirrada',
  mirrado: 'mirrado',
  mirrais: 'mirrais',
  mirramo: 'mirramo',
  mirrara: 'mirrara',
  mirrava: 'mirrava',
  mirreas: 'mírreas',
  mirreis: 'mirreis',
  mirremo: 'mirremo',
  mirreos: 'mírreos',
  mirrite: 'mirrite',
  mirrois: 'mirróis',
  mirsina: 'mírsina',
  mirsine: 'mírsine',
  mirtais: 'mirtais',
  mirtale: 'mirtale',
  mirteas: 'mírteas',
  mirtedo: 'mirtedo',
  mirteis: 'mírteis',
  mirteos: 'mírteos',
  mirtilo: 'mirtilo',
  mirtois: 'mirtóis',
  mirtosa: 'mirtosa',
  mirtoso: 'mirtoso',
  miruins: 'miruins',
  mirzada: 'mirzada',
  misagas: 'misagas',
  miscada: 'miscada',
  miscado: 'miscado',
  miscais: 'miscais',
  miscamo: 'miscamo',
  miscara: 'miscará',
  miscaro: 'míscaro',
  miscava: 'miscava',
  miscela: 'miscela',
  miscofo: 'miscofo',
  miscrai: 'miscrai',
  miscram: 'miscram',
  miscrar: 'miscrar',
  miscras: 'miscras',
  miscrei: 'miscrei',
  miscrem: 'miscrem',
  miscres: 'miscres',
  miscrou: 'miscrou',
  miselia: 'misélia',
  misenas: 'misenas',
  miserai: 'miserai',
  miseram: 'miseram',
  miserar: 'miserar',
  miseras: 'míseras',
  miserei: 'miserei',
  miserem: 'miserem',
  miseres: 'miseres',
  miseria: 'miséria',
  miseros: 'míseros',
  miserou: 'miserou',
  misidea: 'misídea',
  misideo: 'misídeo',
  misoria: 'misória',
  misosas: 'misosas',
  misosos: 'misosos',
  misquei: 'misquei',
  misquem: 'misquem',
  misques: 'misques',
  misraim: 'misraim',
  missada: 'missada',
  missado: 'missado',
  missais: 'missais',
  missalo: 'missalo',
  missamo: 'missamo',
  missara: 'missará',
  missava: 'missava',
  missefo: 'missefo',
  misseis: 'mísseis',
  missemo: 'missemo',
  missiva: 'missiva',
  missivo: 'missivo',
  missoco: 'missoco',
  missoes: 'missões',
  missona: 'missona',
  missure: 'missure',
  missuri: 'missúri',
  mistace: 'místace',
  mistate: 'mistate',
  misteca: 'misteca',
  misteco: 'misteco',
  mistela: 'mistela',
  mistica: 'mística',
  mistico: 'místico',
  mistida: 'mistida',
  mistide: 'místide',
  mistito: 'mistito',
  mistoes: 'mistões',
  mistois: 'mistóis',
  mistral: 'mistral',
  mistria: 'mistria',
  mistrio: 'místrio',
  mistros: 'mistros',
  mistura: 'mistura',
  misture: 'misture',
  misturo: 'misturo',
  misulas: 'mísulas',
  misuras: 'misuras',
  mitamba: 'mitamba',
  mitelas: 'mitelas',
  mitemas: 'mitemas',
  mitenes: 'mitenes',
  mitenia: 'mitênia',
  mitetes: 'mitetes',
  mitical: 'mitical',
  miticas: 'míticas',
  miticos: 'míticos',
  mitigai: 'mitigai',
  mitigam: 'mitigam',
  mitigar: 'mitigar',
  mitigas: 'mitigas',
  mitigou: 'mitigou',
  mitigue: 'mitigue',
  mitilia: 'mitília',
  mitilos: 'mítilos',
  mitimno: 'mitimno',
  mitiris: 'mitiris',
  mitismo: 'mitismo',
  mitizai: 'mitizai',
  mitizam: 'mitizam',
  mitizar: 'mitizar',
  mitizas: 'mitizas',
  mitizei: 'mitizei',
  mitizem: 'mitizem',
  mitizes: 'mitizes',
  mitizou: 'mitizou',
  mitombo: 'mitombo',
  mitonde: 'mitonde',
  mitoses: 'mitoses',
  mitotes: 'mitotes',
  mitrada: 'mitrada',
  mitrado: 'mitrado',
  mitrais: 'mitrais',
  mitramo: 'mitramo',
  mitrara: 'mitrará',
  mitrava: 'mitrava',
  mitreis: 'mitreis',
  mitremo: 'mitremo',
  mitreta: 'mitreta',
  mitreus: 'mitreus',
  mitrida: 'mítrida',
  mitrofe: 'mítrofe',
  mitrula: 'mítrula',
  miudeai: 'miudeai',
  miudear: 'miudear',
  miudeei: 'miudeei',
  miudeia: 'miudeia',
  miudeie: 'miudeie',
  miudeio: 'miudeio',
  miudeou: 'miudeou',
  miudeza: 'miudeza',
  miudica: 'miúdica',
  miudico: 'miúdico',
  miulzao: 'miulzão',
  miuncas: 'miunças',
  miuncos: 'miunços',
  miungos: 'miungos',
  mixacio: 'mixácio',
  mixadao: 'mixadão',
  mixadas: 'mixadas',
  mixador: 'mixador',
  mixados: 'mixados',
  mixagem: 'mixagem',
  mixamos: 'mixamos',
  mixando: 'mixando',
  mixanga: 'mixanga',
  mixante: 'mixante',
  mixaram: 'mixaram',
  mixarao: 'mixarão',
  mixaras: 'mixarás',
  mixarei: 'mixarei',
  mixarem: 'mixarem',
  mixares: 'mixares',
  mixaria: 'mixaria',
  mixarmo: 'mixarmo',
  mixasse: 'mixasse',
  mixaste: 'mixaste',
  mixavam: 'mixavam',
  mixavas: 'mixavas',
  mixavel: 'mixável',
  mixeira: 'mixeira',
  mixemos: 'mixemos',
  mixetos: 'mixetos',
  mixicas: 'míxicas',
  mixicos: 'míxicos',
  mixidio: 'mixídio',
  mixilas: 'mixilas',
  mixinas: 'mixinas',
  mixinho: 'mixinho',
  mixinos: 'mixinos',
  mixiras: 'mixiras',
  mixirra: 'mixirra',
  mixitas: 'mixitas',
  mixites: 'mixites',
  mixodes: 'mixodes',
  mixoide: 'mixoide',
  mixoles: 'mixoles',
  mixolos: 'mixolos',
  mixomas: 'mixomas',
  mixonos: 'mixonos',
  mixorne: 'mixorne',
  mixteca: 'mixteca',
  mixteco: 'mixteco',
  mixtoes: 'mixtões',
  mixutos: 'mixutos',
  mizacre: 'mizacrê',
  mizando: 'mizando',
  mizcais: 'mizcais',
  mizefes: 'mizefes',
  mizeias: 'mizeias',
  mizenas: 'mizenas',
  mizimos: 'mizimos',
  mizinas: 'mizinas',
  mizines: 'mizines',
  mizitra: 'mizitra',
  mizomia: 'mizomia',
  mlxviii: 'mlxviii',
  mlxxiii: 'mlxxiii',
  mlxxvii: 'mlxxvii',
  mlxxxii: 'mlxxxii',
  mlxxxiv: 'mlxxxiv',
  mlxxxix: 'mlxxxix',
  mlxxxvi: 'mlxxxvi',
  mmlviii: 'mmlviii',
  mmlxiii: 'mmlxiii',
  mmlxvii: 'mmlxvii',
  mmlxxii: 'mmlxxii',
  mmlxxiv: 'mmlxxiv',
  mmlxxix: 'mmlxxix',
  mmlxxvi: 'mmlxxvi',
  mmlxxxi: 'mmlxxxi',
  mmlxxxv: 'mmlxxxv',
  mmxciii: 'mmxciii',
  mmxcvii: 'mmxcvii',
  mmxliii: 'mmxliii',
  mmxlvii: 'mmxlvii',
  mmxviii: 'mmxviii',
  mmxxiii: 'mmxxiii',
  mmxxvii: 'mmxxvii',
  mmxxxii: 'mmxxxii',
  mmxxxiv: 'mmxxxiv',
  mmxxxix: 'mmxxxix',
  mmxxxvi: 'mmxxxvi',
  mnemias: 'mnêmias',
  mnemica: 'mnêmica',
  mnemico: 'mnêmico',
  mnesica: 'mnésica',
  mnesico: 'mnésico',
  mniacea: 'mniácea',
  mniaceo: 'mniáceo',
  mniaros: 'mniaros',
  mnioide: 'mnioide',
  mniopse: 'mniopse',
  moabita: 'moabita',
  moagens: 'moagens',
  moaicas: 'moaicas',
  moalaca: 'moalaca',
  moalzao: 'moalzão',
  moambos: 'moambos',
  moandas: 'moandas',
  moandim: 'moandim',
  moandos: 'moandos',
  moangis: 'moangis',
  moanhas: 'moanhas',
  moansas: 'moansas',
  moarbes: 'moarbes',
  moarrao: 'moarrão',
  moarras: 'moarrãs',
  moatras: 'moatras',
  mobatos: 'mobatos',
  mobedes: 'mobedes',
  mobeles: 'mobeles',
  mobelha: 'mobelha',
  mobicas: 'mobicas',
  mobilai: 'mobilai',
  mobilam: 'mobilam',
  mobilar: 'mobilar',
  mobilas: 'mobilas',
  mobilei: 'mobilei',
  mobilem: 'mobilem',
  mobiles: 'móbiles',
  mobilha: 'mobilha',
  mobilhe: 'mobilhe',
  mobilho: 'mobilho',
  mobilia: 'mobília',
  mobilie: 'mobílie',
  mobilio: 'mobílio',
  mobilou: 'mobilou',
  mobiros: 'mobiros',
  mobolas: 'mobolas',
  mobulas: 'móbulas',
  mocacos: 'moçaços',
  mocadao: 'mocadão',
  mocadas: 'moçadas',
  mocador: 'mocador',
  mocados: 'mocados',
  mocafes: 'moçafes',
  mocafos: 'moçafos',
  mocaita: 'mocaíta',
  mocaixe: 'mocaíxe',
  mocajas: 'mocajás',
  mocalha: 'moçalha',
  mocalho: 'moçalho',
  mocamau: 'mocamau',
  mocambo: 'mocambo',
  mocames: 'moçames',
  mocamos: 'moçamos',
  mocanas: 'mocanas',
  mocanca: 'mocanca',
  mocanco: 'mocanco',
  mocando: 'moçando',
  mocanos: 'mocanos',
  mocante: 'mocante',
  mocaram: 'moçaram',
  mocarao: 'moçarão',
  mocaras: 'moçarás',
  mocarei: 'moçarei',
  mocarem: 'moçarem',
  mocares: 'moçares',
  mocaria: 'moçaria',
  mocarmo: 'mocarmo',
  mocasse: 'moçasse',
  mocasso: 'mocassó',
  mocaste: 'moçaste',
  mocavam: 'moçavam',
  mocavas: 'moçavas',
  moceada: 'moceada',
  moceado: 'moceado',
  moceais: 'moceais',
  moceara: 'moceará',
  moceava: 'moceava',
  mocedos: 'mocedos',
  moceeis: 'moceeis',
  moceiam: 'moceiam',
  moceias: 'moceias',
  moceiem: 'moceiem',
  moceies: 'moceies',
  moceira: 'moceira',
  moceiro: 'moceiro',
  mocelas: 'mocelas',
  mocemos: 'mocemos',
  mocetao: 'mocetão',
  mocetas: 'mocetas',
  mocetes: 'mocetes',
  mochaco: 'mochaco',
  mochada: 'mochada',
  mochado: 'mochado',
  mochais: 'mochais',
  mochamo: 'mochamo',
  mochara: 'mochara',
  mochaus: 'mochaus',
  mochava: 'mochava',
  mocheca: 'mocheca',
  mocheis: 'mocheis',
  mochela: 'mochela',
  mochemo: 'mochemo',
  mochena: 'mochena',
  mocheta: 'mocheta',
  mochica: 'mochica',
  mochico: 'mochico',
  mochila: 'mochila',
  mochilo: 'mochilo',
  mochoes: 'mochões',
  mochole: 'mochole',
  mocholi: 'mocholi',
  mocholo: 'mocholo',
  mochuda: 'mochuda',
  mocicas: 'mocicas',
  mocicos: 'mociços',
  mocinha: 'mocinha',
  mocinho: 'mocinho',
  mocismo: 'mocismo',
  mococos: 'mococos',
  mocoila: 'moçoila',
  mocoilo: 'moçoilo',
  mocoius: 'mocoiús',
  mocojes: 'mocojés',
  mocomas: 'mocomas',
  moconas: 'moçonas',
  moconis: 'moconis',
  mocoros: 'mocorós',
  mocosas: 'mocosas',
  mocotos: 'mocotós',
  mocozal: 'mocozal',
  mocozea: 'mocozeá',
  mocreia: 'mocreia',
  mocubas: 'mocubas',
  mocudas: 'mocudas',
  mocugio: 'mocúgio',
  mocugue: 'mocuguê',
  mocusos: 'mocusos',
  modecas: 'modecas',
  modelai: 'modelai',
  modelam: 'modelam',
  modelar: 'modelar',
  modelas: 'modelas',
  modelei: 'modelei',
  modelem: 'modelem',
  modeles: 'modeles',
  modelos: 'modelos',
  modelou: 'modelou',
  modemas: 'modemas',
  moderai: 'moderai',
  moderam: 'moderam',
  moderar: 'moderar',
  moderas: 'moderas',
  moderei: 'moderei',
  moderem: 'moderem',
  moderes: 'moderes',
  moderna: 'moderna',
  moderne: 'moderne',
  moderno: 'moderno',
  moderou: 'moderou',
  modesta: 'modesta',
  modesto: 'modesto',
  modicai: 'modicai',
  modicam: 'modicam',
  modicar: 'modicar',
  modicas: 'módicas',
  modicos: 'módicos',
  modicou: 'modicou',
  modilha: 'modilha',
  modilhe: 'modilhe',
  modilho: 'modilho',
  modinha: 'modinha',
  modinho: 'modinho',
  modiola: 'modíola',
  modiolo: 'modíolo',
  modique: 'modique',
  modismo: 'modismo',
  modista: 'modista',
  modisto: 'modisto',
  modorna: 'modorna',
  modorne: 'modorne',
  modorno: 'modorno',
  modorra: 'modorra',
  modorre: 'modorre',
  modorro: 'modorro',
  modotex: 'modotex',
  modubas: 'modubas',
  modulai: 'modulai',
  modulam: 'modulam',
  modular: 'modular',
  modulas: 'modulas',
  modulei: 'modulei',
  modulem: 'modulem',
  modules: 'modules',
  modulor: 'modulor',
  modulos: 'módulos',
  modulou: 'modulou',
  moecoes: 'moeções',
  moecona: 'moeçona',
  moedela: 'moedela',
  moedora: 'moedora',
  moedura: 'moedura',
  moeiras: 'moeiras',
  moeiros: 'moeiros',
  moelele: 'moelele',
  moendas: 'moendas',
  moengas: 'moengas',
  moengos: 'moengos',
  moentes: 'moentes',
  moenzas: 'moenzas',
  moerdes: 'moerdes',
  moereis: 'moereis',
  moeremo: 'moeremo',
  moeriam: 'moeriam',
  moerias: 'moerias',
  moermos: 'moermos',
  moesina: 'moesina',
  moessem: 'moessem',
  moesses: 'moesses',
  moestai: 'moestai',
  moestam: 'moestam',
  moestar: 'moestar',
  moestas: 'moestas',
  moestei: 'moestei',
  moestem: 'moestem',
  moestes: 'moestes',
  moestou: 'moestou',
  mofadao: 'mofadão',
  mofadas: 'mofadas',
  mofador: 'mofador',
  mofados: 'mofados',
  mofamos: 'mofamos',
  mofando: 'mofando',
  mofante: 'mofante',
  mofaram: 'mofaram',
  mofarao: 'mofarão',
  mofaras: 'mofaras',
  mofarei: 'mofarei',
  mofarem: 'mofarem',
  mofares: 'mofares',
  mofaria: 'mofaria',
  mofarmo: 'mofarmo',
  mofasse: 'mofasse',
  mofaste: 'mofaste',
  mofatra: 'mofatra',
  mofavam: 'mofavam',
  mofavas: 'mofavas',
  mofedas: 'mofedas',
  mofedos: 'mofedos',
  mofeira: 'mofeira',
  mofeiro: 'mofeiro',
  mofemos: 'mofemos',
  mofenda: 'mofenda',
  mofendo: 'mofendo',
  mofenta: 'mofenta',
  mofento: 'mofento',
  mofetas: 'mofetas',
  mofetil: 'mofetil',
  mofetis: 'mofetis',
  mofinas: 'mofinas',
  mofinez: 'mofinez',
  mofinos: 'mofinos',
  mofofos: 'mofofôs',
  mofosas: 'mofosas',
  mofosos: 'mofosos',
  mofumba: 'mofumba',
  mofumbe: 'mofumbe',
  mofumbo: 'mofumbo',
  mofumos: 'mofumos',
  mofunda: 'mofundá',
  mofunde: 'mofunde',
  mofundo: 'mofundo',
  mofungo: 'mofungo',
  moganga: 'moganga',
  mogango: 'mogango',
  mogarim: 'mogarim',
  mogaver: 'mogáver',
  mogegos: 'mogegos',
  mogenga: 'mogenga',
  mogenge: 'mogenge',
  mogengo: 'mogengo',
  mogicai: 'mogicai',
  mogicam: 'mogicam',
  mogicar: 'mogicar',
  mogicas: 'mogicas',
  mogicou: 'mogicou',
  mogique: 'mogique',
  mogonos: 'mógonos',
  mogores: 'mogores',
  mogorim: 'mogorim',
  mogoris: 'mogoris',
  mogrebi: 'mogrebi',
  moguina: 'moguina',
  moguino: 'moguino',
  mogulia: 'mogúlia',
  mohlana: 'mohlana',
  mohsita: 'mohsita',
  moiacao: 'moiação',
  moiador: 'moiador',
  moiamos: 'moíamos',
  moicana: 'moicana',
  moicano: 'moicano',
  moicoes: 'moições',
  moicona: 'moiçona',
  moidoes: 'moidões',
  moidona: 'moidona',
  moilele: 'moilele',
  moimbes: 'moimbes',
  moinada: 'moinada',
  moinado: 'moinado',
  moinais: 'moinais',
  moinamo: 'moinamo',
  moinara: 'moinará',
  moinava: 'moinava',
  moindos: 'moindos',
  moineis: 'moineis',
  moinemo: 'moinemo',
  moingis: 'moíngis',
  moinhai: 'moinhai',
  moinham: 'moinham',
  moinhar: 'moinhar',
  moinhas: 'moinhas',
  moinhei: 'moinhei',
  moinhem: 'moinhem',
  moinhes: 'moinhes',
  moinhos: 'moinhos',
  moinhou: 'moinhou',
  moinice: 'moinice',
  moipira: 'moipira',
  moipuas: 'moipuas',
  moirada: 'moirada',
  moirado: 'moirado',
  moirais: 'moirais',
  moirama: 'moirama',
  moiramo: 'moiramo',
  moirana: 'moirana',
  moirara: 'moirará',
  moirava: 'moirava',
  moireis: 'moireis',
  moireja: 'moireja',
  moireje: 'moireje',
  moirejo: 'moirejo',
  moiremo: 'moiremo',
  moirete: 'moirete',
  moiriza: 'moiriza',
  moirize: 'moirize',
  moirizo: 'moirizo',
  moiroes: 'moirões',
  moitada: 'moitada',
  moitado: 'moitado',
  moitais: 'moitais',
  moitamo: 'moitamo',
  moitara: 'moitará',
  moitava: 'moitava',
  moitedo: 'moitedo',
  moiteis: 'moiteis',
  moitemo: 'moitemo',
  moitoes: 'moitões',
  mojadao: 'mojadão',
  mojadas: 'mojadas',
  mojador: 'mojador',
  mojados: 'mojados',
  mojajas: 'mojajas',
  mojajos: 'mojajos',
  mojamos: 'mojamos',
  mojando: 'mojando',
  mojante: 'mojante',
  mojaram: 'mojaram',
  mojarao: 'mojarão',
  mojaras: 'mojaras',
  mojarei: 'mojarei',
  mojarem: 'mojarem',
  mojares: 'mojares',
  mojaria: 'mojaria',
  mojarmo: 'mojarmo',
  mojasse: 'mojasse',
  mojaste: 'mojaste',
  mojavam: 'mojavam',
  mojavas: 'mojavas',
  mojemos: 'mojemos',
  mojiana: 'mojiana',
  mojiano: 'mojiano',
  mojicai: 'mojicai',
  mojicam: 'mojicam',
  mojicar: 'mojicar',
  mojicas: 'mojicas',
  mojicou: 'mojicou',
  mojique: 'mojique',
  mojojas: 'mojojas',
  mojojos: 'mojojos',
  mojolas: 'mojolas',
  mojolos: 'mojolos',
  molacas: 'molaças',
  molacos: 'molaços',
  moladas: 'moladas',
  molados: 'moladôs',
  molagem: 'molagem',
  molamba: 'molamba',
  molambo: 'molambo',
  molamos: 'molamos',
  molanas: 'molanas',
  molanca: 'molancã',
  molango: 'molango',
  molares: 'molares',
  molassa: 'molassa',
  molasso: 'molasso',
  moldada: 'moldada',
  moldado: 'moldado',
  moldais: 'moldais',
  moldamo: 'moldamo',
  moldara: 'moldara',
  moldava: 'moldava',
  moldavo: 'moldavo',
  moldeai: 'moldeai',
  moldear: 'moldear',
  moldeei: 'moldeei',
  moldeia: 'moldeia',
  moldeie: 'moldeie',
  moldeio: 'moldeio',
  moldeis: 'moldeis',
  moldemo: 'moldemo',
  moldeou: 'moldeou',
  moldina: 'moldina',
  moldura: 'moldura',
  moldure: 'moldure',
  molduro: 'molduro',
  moleada: 'moleada',
  moleado: 'moleado',
  moleais: 'moleais',
  moleara: 'moleará',
  moleava: 'moleava',
  molecai: 'molecai',
  molecam: 'molecam',
  molecao: 'molecão',
  molecar: 'molecar',
  molecas: 'molecas',
  molecou: 'molecou',
  moledos: 'moledos',
  moledro: 'moledro',
  moleeis: 'moleeis',
  molegos: 'molegos',
  moleiam: 'moleiam',
  moleias: 'moleias',
  moleiem: 'moleiem',
  moleies: 'moleies',
  moleija: 'moleija',
  moleira: 'moleira',
  moleiro: 'moleiro',
  molejai: 'molejai',
  molejam: 'molejam',
  molejar: 'molejar',
  molejas: 'molejas',
  molejei: 'molejei',
  molejem: 'molejem',
  molejes: 'molejes',
  molejos: 'molejos',
  molejou: 'molejou',
  molembo: 'molembo',
  molenga: 'molenga',
  molengo: 'molengo',
  molense: 'molense',
  moleque: 'moleque',
  molesta: 'molesta',
  moleste: 'moleste',
  molesto: 'molesto',
  moletao: 'moletão',
  moletas: 'moletas',
  moletes: 'moletes',
  moletom: 'moletom',
  moletos: 'moletos',
  molezas: 'molezas',
  molgada: 'molgada',
  molgado: 'molgado',
  molgais: 'molgais',
  molgara: 'molgará',
  molgava: 'molgava',
  molguea: 'molgueá',
  molguei: 'molguei',
  molguem: 'molguem',
  molgues: 'molgues',
  molgula: 'mólgula',
  molhaca: 'molhaça',
  molhada: 'molhada',
  molhado: 'molhado',
  molhais: 'molhais',
  molhamo: 'molhamo',
  molhara: 'molhara',
  molhava: 'molhava',
  molheis: 'molheis',
  molhemo: 'molhemo',
  molhida: 'molhida',
  moliana: 'moliana',
  molicao: 'molição',
  molicia: 'molícia',
  molicie: 'molície',
  molicos: 'moliços',
  molidao: 'molidão',
  molidea: 'molídea',
  molideo: 'molídeo',
  molidia: 'molídia',
  molidos: 'molidos',
  molieis: 'molíeis',
  molimas: 'molimas',
  molimen: 'molímen',
  molimes: 'molimes',
  molimos: 'molimos',
  molinai: 'molinai',
  molinam: 'molinam',
  molinar: 'molinar',
  molinas: 'molinas',
  molindo: 'molindo',
  molinei: 'molinei',
  molinem: 'molinem',
  molines: 'molines',
  molinha: 'molinha',
  molinhe: 'molinhe',
  molinho: 'molinho',
  molinia: 'molínia',
  molinou: 'molinou',
  molione: 'molione',
  molipas: 'molipas',
  moliram: 'moliram',
  molirao: 'molirão',
  moliras: 'molirás',
  molirei: 'molirei',
  molirem: 'molirem',
  molires: 'molires',
  moliria: 'moliria',
  molirmo: 'molirmo',
  molisia: 'molísia',
  molisma: 'molismá',
  molisme: 'molisme',
  molismo: 'molismo',
  molisse: 'molisse',
  moliste: 'moliste',
  molitas: 'molitas',
  molites: 'molites',
  molitos: 'molitos',
  molneta: 'molneta',
  molocas: 'molocas',
  molocos: 'moloços',
  moloide: 'moloide',
  molojes: 'molojes',
  mololas: 'mololas',
  molonas: 'molonas',
  molongo: 'molongó',
  moloque: 'moloque',
  molosso: 'molosso',
  molotov: 'molotov',
  molotro: 'molotro',
  molucas: 'molucas',
  moluene: 'moluene',
  molugem: 'molugem',
  molugos: 'molugos',
  molulos: 'molulos',
  molulus: 'molulus',
  molungo: 'molungo',
  molungu: 'molungu',
  moluras: 'moluras',
  moluria: 'molúria',
  moluros: 'moluros',
  molusco: 'molusco',
  momadao: 'momadão',
  momadas: 'momadas',
  momador: 'momador',
  momados: 'momados',
  momamos: 'momamos',
  momanas: 'momanás',
  momando: 'momando',
  momante: 'momante',
  momaram: 'momaram',
  momarao: 'momarão',
  momaras: 'momarás',
  momarei: 'momarei',
  momarem: 'momarem',
  momares: 'momares',
  momaria: 'momaria',
  momarmo: 'momarmo',
  momaros: 'mômaros',
  momasse: 'momasse',
  momaste: 'momaste',
  momavam: 'momavam',
  momavas: 'momavas',
  momaxos: 'momaxós',
  mombaca: 'mombaça',
  mombina: 'mombina',
  mombius: 'mombiús',
  mombuca: 'mombuca',
  mombucu: 'mombucu',
  mombuta: 'mombuta',
  mombuto: 'mombuto',
  momeada: 'momeada',
  momeado: 'momeado',
  momeais: 'momeais',
  momeara: 'momeará',
  momeava: 'momeava',
  momeeis: 'momeeis',
  momeiam: 'momeiam',
  momeias: 'momeias',
  momeiem: 'momeiem',
  momeies: 'momeies',
  momemos: 'momemos',
  momento: 'momento',
  momesca: 'momesca',
  momesco: 'momesco',
  momices: 'momices',
  momotas: 'momotas',
  momotos: 'momotos',
  monacal: 'monacal',
  monadas: 'mônadas',
  monades: 'mônades',
  monados: 'monados',
  monailo: 'monailo',
  monamos: 'monamos',
  monanta: 'monanta',
  monanto: 'monanto',
  monapia: 'monápia',
  monarca: 'monarca',
  monarda: 'monarda',
  monares: 'monares',
  monaria: 'monaria',
  monasas: 'monasas',
  monaulo: 'monaulo',
  monaxos: 'monaxós',
  moncada: 'moncada',
  moncado: 'moncado',
  moncais: 'moncais',
  moncamo: 'moncamo',
  moncara: 'moncará',
  moncava: 'moncava',
  monchao: 'monchão',
  moncias: 'môncias',
  moncoes: 'monções',
  moncone: 'moncone',
  moncosa: 'moncosa',
  moncoso: 'moncoso',
  mondada: 'mondada',
  mondado: 'mondado',
  mondais: 'mondais',
  mondamo: 'mondamo',
  mondara: 'mondará',
  mondava: 'mondava',
  mondego: 'mondego',
  mondeis: 'mondeis',
  mondemo: 'mondemo',
  mondeus: 'mondéus',
  mondias: 'mondiás',
  mondina: 'mondina',
  mondino: 'mondino',
  mondins: 'mondins',
  monduai: 'monduaí',
  mondual: 'mondual',
  monduis: 'monduís',
  monecas: 'monecas',
  monecia: 'monecia',
  monecio: 'monécio',
  monecma: 'monecma',
  monecos: 'monecos',
  monedes: 'monedes',
  monelha: 'monelha',
  monelho: 'monelho',
  monemas: 'monemas',
  moneras: 'moneras',
  moneres: 'moneres',
  monerma: 'monerma',
  monesas: 'monesas',
  monesga: 'monesga',
  monesgo: 'monesgo',
  monesia: 'monésia',
  monesos: 'monesos',
  monetas: 'monetas',
  monetes: 'monetes',
  monfera: 'monfera',
  monfere: 'monfere',
  monferi: 'monferi',
  monfero: 'monfero',
  mongeis: 'mongeis',
  mongemo: 'mongemo',
  mongera: 'mongerá',
  mongiam: 'mongiam',
  mongias: 'mongias',
  mongida: 'mongida',
  mongido: 'mongido',
  mongoas: 'môngoas',
  mongoia: 'mongoia',
  mongoio: 'mongoió',
  mongois: 'mongóis',
  mongolo: 'mongolo',
  mongovo: 'mongovo',
  monguba: 'monguba',
  monhico: 'monhiço',
  monicao: 'monição',
  monicas: 'mônicas',
  monices: 'monices',
  monicos: 'mônicos',
  monidao: 'monidão',
  monidas: 'monidas',
  monidor: 'monidor',
  monidos: 'monidos',
  monieis: 'moníeis',
  monilas: 'monilas',
  monilha: 'monilha',
  monilhe: 'monilhe',
  monilho: 'monilho',
  monilia: 'monília',
  monilos: 'monilos',
  monimas: 'monimas',
  monimia: 'monímia',
  monimos: 'monimos',
  moninas: 'moninas',
  monindo: 'monindo',
  moniram: 'moniram',
  monirao: 'monirão',
  moniras: 'moniras',
  monirei: 'monirei',
  monirem: 'monirem',
  monires: 'monires',
  moniria: 'moniria',
  monirmo: 'monirmo',
  moniros: 'moniros',
  monismo: 'monismo',
  monisse: 'monisse',
  monista: 'monista',
  moniste: 'moniste',
  monitas: 'mônitas',
  monitor: 'monitor',
  monjais: 'monjais',
  monjamo: 'monjamo',
  monjios: 'monjios',
  monjolo: 'monjolo',
  monnina: 'monnina',
  monoamo: 'monoamo',
  monoato: 'monoato',
  monobia: 'monóbia',
  monobio: 'monóbio',
  monocas: 'monocas',
  monodia: 'monodia',
  monodie: 'monodie',
  monodio: 'monodio',
  monogas: 'monogás',
  monoica: 'monoica',
  monoico: 'monoico',
  monoide: 'monoide',
  monoila: 'monoíla',
  monoilo: 'monoílo',
  monomas: 'monomas',
  monomes: 'monomes',
  monomio: 'monômio',
  mononix: 'mônonix',
  mononte: 'mononte',
  monopes: 'monopes',
  monopis: 'monópis',
  monopsa: 'monopsa',
  monopse: 'monopse',
  monopso: 'monopso',
  monoses: 'monoses',
  monotes: 'monotes',
  monotoa: 'monótoa',
  monovia: 'monovia',
  monoxos: 'monoxós',
  monquei: 'monquei',
  monquem: 'monquem',
  monques: 'monques',
  monsior: 'monsior',
  monstra: 'monstra',
  monstro: 'monstro',
  montada: 'montada',
  montado: 'montado',
  montais: 'montais',
  montamo: 'montamo',
  montana: 'montana',
  montano: 'montano',
  montara: 'montara',
  montava: 'montava',
  monteai: 'monteai',
  montear: 'montear',
  monteco: 'monteco',
  monteei: 'monteei',
  monteia: 'monteia',
  monteie: 'monteie',
  monteio: 'monteio',
  monteis: 'monteis',
  montemo: 'montemo',
  monteou: 'monteou',
  montera: 'montera',
  montesa: 'montesa',
  monteus: 'monteus',
  montijo: 'montijo',
  montina: 'montina',
  montoes: 'montões',
  montosa: 'montosa',
  montoso: 'montoso',
  montras: 'montras',
  montujo: 'montujo',
  monturo: 'monturo',
  montuva: 'montuva',
  monuros: 'monuros',
  monvana: 'monvana',
  moogono: 'moógono',
  mopanes: 'mopanes',
  mopiecu: 'mopieçu',
  moponga: 'moponga',
  mopseia: 'mopseia',
  moquais: 'moquais',
  moquamo: 'moquamo',
  moqueai: 'moqueai',
  moquear: 'moquear',
  moqueca: 'moqueca',
  moqueco: 'moqueco',
  moqueei: 'moqueei',
  moqueia: 'moqueia',
  moqueie: 'moqueie',
  moqueio: 'moqueio',
  moqueis: 'moqueis',
  moquemo: 'moquemo',
  moquens: 'moquéns',
  moqueou: 'moqueou',
  moqueta: 'moqueta',
  moquiam: 'moquiam',
  moquias: 'moquias',
  moquico: 'moquiço',
  moquida: 'moquida',
  moquido: 'moquido',
  moquimo: 'moquimo',
  moquira: 'moquirá',
  moquite: 'moquite',
  moracea: 'morácea',
  moraceo: 'moráceo',
  moradao: 'moradão',
  moradas: 'moradas',
  moradia: 'moradia',
  moradio: 'moradio',
  morador: 'morador',
  morados: 'morados',
  moraina: 'moraina',
  moralao: 'moralão',
  moramos: 'moramos',
  moranca: 'morança',
  morando: 'morando',
  moranes: 'moranes',
  moranga: 'moranga',
  morango: 'morango',
  morante: 'morante',
  moraram: 'moraram',
  morarao: 'morarão',
  moraras: 'moraras',
  morarei: 'morarei',
  morarem: 'morarem',
  morares: 'morares',
  moraria: 'moraria',
  morarmo: 'morarmo',
  morasse: 'morasse',
  moraste: 'moraste',
  moratas: 'moratas',
  moratos: 'moratos',
  moravam: 'moravam',
  moravas: 'moravas',
  moravia: 'morávia',
  moravio: 'morávio',
  moravos: 'moravos',
  morbida: 'mórbida',
  morbido: 'mórbido',
  morbosa: 'morbosa',
  morboso: 'morboso',
  morcega: 'morcega',
  morcego: 'morcego',
  morcela: 'morcela',
  morchal: 'morchal',
  morchao: 'morchão',
  morcoes: 'morcões',
  morcona: 'morcona',
  morcuto: 'morcuto',
  mordaca: 'mordaça',
  mordace: 'mordace',
  mordaco: 'mordaço',
  mordais: 'mordais',
  mordamo: 'mordamo',
  mordeis: 'mordeis',
  mordela: 'mordela',
  mordemo: 'mordemo',
  mordera: 'mordera',
  mordexi: 'mordexi',
  mordiam: 'mordiam',
  mordias: 'mordias',
  mordica: 'mordica',
  mordico: 'mordico',
  mordida: 'mordida',
  mordido: 'mordido',
  mordixi: 'mordixi',
  mordofe: 'mordofe',
  mordoma: 'mordoma',
  mordome: 'mordome',
  mordomo: 'mordomo',
  moreeas: 'moréeas',
  moreias: 'moreias',
  moreira: 'moreira',
  morejai: 'morejai',
  morejam: 'morejam',
  morejar: 'morejar',
  morejas: 'morejas',
  morejei: 'morejei',
  morejem: 'morejem',
  morejes: 'morejes',
  morejou: 'morejou',
  morelia: 'morélia',
  moremos: 'moremos',
  morenao: 'morenão',
  morenas: 'morenas',
  morenge: 'morenge',
  morengo: 'morengo',
  morenia: 'morênia',
  morenos: 'morenos',
  morense: 'morense',
  moreoes: 'moreões',
  moreota: 'moreota',
  moreres: 'morerês',
  moretia: 'morétia',
  moretos: 'moretos',
  morexim: 'morexim',
  morfada: 'morfada',
  morfado: 'morfado',
  morfais: 'morfais',
  morfamo: 'morfamo',
  morfara: 'morfará',
  morfava: 'morfava',
  morfeia: 'morfeia',
  morfeis: 'mórfeis',
  morfema: 'morfema',
  morfemo: 'morfemo',
  morfias: 'morfias',
  morfica: 'mórfica',
  morfico: 'mórfico',
  morfila: 'morfilá',
  morfile: 'morfile',
  morfilo: 'morfilo',
  morfina: 'morfina',
  morfine: 'morfine',
  morfino: 'morfino',
  morfnos: 'morfnos',
  morfoes: 'morfões',
  morfois: 'morfóis',
  morfose: 'morfose',
  morgada: 'morgada',
  morgado: 'morgado',
  morgais: 'morgais',
  morgara: 'morgará',
  morgava: 'morgava',
  morgete: 'morgete',
  morgeto: 'morgeto',
  morgias: 'mórgias',
  morgios: 'mórgios',
  morguei: 'morguei',
  morguem: 'morguem',
  morgues: 'morgues',
  moriais: 'moriais',
  moricas: 'móricas',
  moricos: 'móricos',
  morilas: 'morilas',
  morilha: 'morilha',
  morilho: 'morilho',
  morilos: 'morilos',
  morimos: 'morimos',
  morinas: 'morinas',
  morinda: 'morinda',
  morinea: 'morínea',
  morineo: 'moríneo',
  moringa: 'moringa',
  moringo: 'moringo',
  morinos: 'mórinos',
  morique: 'morique',
  morisco: 'morisco',
  morisia: 'morísia',
  moritis: 'moritis',
  morivas: 'morivás',
  morlaca: 'morlaca',
  morlaco: 'morlaco',
  mormaco: 'mormaço',
  mormada: 'mormada',
  mormado: 'mormado',
  mormiro: 'mormiro',
  mormode: 'mormode',
  mormoes: 'mormões',
  mormons: 'mórmons',
  mormope: 'mormope',
  mormosa: 'mormosa',
  mormoso: 'mormoso',
  mornaca: 'mornaça',
  mornaco: 'mornaço',
  mornada: 'mornada',
  mornado: 'mornado',
  mornais: 'mornais',
  mornamo: 'mornamo',
  mornara: 'mornará',
  mornava: 'mornava',
  morneis: 'morneis',
  mornemo: 'mornemo',
  mornita: 'mornita',
  mornite: 'mornite',
  mornura: 'mornura',
  morobas: 'morobas',
  morocha: 'morocha',
  morocho: 'morocho',
  morodas: 'morodas',
  moroico: 'moroiço',
  moroixo: 'moroixo',
  morones: 'morones',
  moropos: 'móropos',
  morores: 'mororês',
  mororos: 'mororós',
  morosas: 'morosas',
  morosos: 'morosos',
  morotos: 'morotós',
  morouco: 'morouço',
  morouxo: 'morouxo',
  morques: 'morquês',
  morraca: 'morraça',
  morrace: 'morrace',
  morraco: 'morraco',
  morrais: 'morrais',
  morramo: 'morramo',
  morreis: 'morreis',
  morremo: 'morremo',
  morrera: 'morrera',
  morriam: 'morriam',
  morriao: 'morrião',
  morrias: 'morrias',
  morrida: 'morrida',
  morrido: 'morrido',
  morroes: 'morrões',
  morrote: 'morrote',
  morruda: 'morruda',
  morrudo: 'morrudo',
  morruol: 'morruol',
  morsais: 'morsais',
  morsega: 'morsega',
  morsego: 'morsego',
  morseta: 'morseta',
  morsolo: 'morsolo',
  mortaco: 'mortaço',
  mortada: 'mortada',
  mortais: 'mortais',
  mortens: 'mortens',
  mortica: 'mortiça',
  mortico: 'mortiço',
  mortona: 'mortona',
  morucos: 'morucos',
  morugem: 'morugem',
  morulas: 'mórulas',
  morunas: 'morunas',
  morundo: 'morundo',
  morunga: 'morunga',
  morungo: 'morungo',
  morunos: 'morunos',
  moruone: 'moruone',
  moruoni: 'moruôni',
  morures: 'morurés',
  morxama: 'morxama',
  morzela: 'morzela',
  morzelo: 'morzelo',
  mosaica: 'mosaica',
  mosaico: 'mosaico',
  mosanas: 'mosanas',
  mosanos: 'mosanos',
  moscada: 'moscada',
  moscado: 'moscado',
  moscais: 'moscais',
  moscamo: 'moscamo',
  moscara: 'moscara',
  moscata: 'moscata',
  moscava: 'moscava',
  moscoes: 'moscões',
  moscope: 'moscope',
  moscosa: 'moscosa',
  moscoso: 'moscoso',
  moselia: 'mosélia',
  mosinas: 'mosinas',
  mosinos: 'mosinos',
  mositas: 'mositas',
  mosites: 'mosites',
  mosleme: 'mosleme',
  moslemo: 'moslemo',
  moslene: 'moslene',
  moslens: 'mosléns',
  mosquam: 'mosquam',
  mosquas: 'mosquas',
  mosquea: 'mosqueá',
  mosquei: 'mosquei',
  mosquem: 'mosquem',
  mosques: 'mosques',
  mosquia: 'mosquia',
  mosquir: 'mosquir',
  mosquis: 'mosquis',
  mosquiu: 'mosquiu',
  mossada: 'mossada',
  mossado: 'mossado',
  mossais: 'mossais',
  mossamo: 'mossamo',
  mossara: 'mossará',
  mossava: 'mossava',
  mosseca: 'mossecá',
  mosseco: 'mosseco',
  mossega: 'móssega',
  mossego: 'mossego',
  mosseis: 'mosseis',
  mossemo: 'mossemo',
  mossens: 'mosséns',
  mossina: 'mossina',
  mossino: 'mossino',
  mossior: 'mossior',
  mossita: 'mossita',
  mossite: 'mossite',
  mossoro: 'mossoró',
  mossuas: 'mossuas',
  mossues: 'mossués',
  mossula: 'mossula',
  mossulo: 'mossulo',
  mostaco: 'mostaço',
  mostajo: 'mostajo',
  mosteia: 'mosteia',
  mostela: 'mostela',
  mostena: 'mostena',
  mosteno: 'mosteno',
  mostrai: 'mostrai',
  mostram: 'mostram',
  mostrar: 'mostrar',
  mostras: 'mostras',
  mostrei: 'mostrei',
  mostrem: 'mostrem',
  mostres: 'mostres',
  mostrou: 'mostrou',
  motaces: 'motacés',
  motacus: 'motacus',
  motamos: 'motamos',
  motanas: 'motanas',
  motanos: 'motanos',
  motares: 'motares',
  motavas: 'motavas',
  motazes: 'motazés',
  moteina: 'moteína',
  motejai: 'motejai',
  motejam: 'motejam',
  motejar: 'motejar',
  motejas: 'motejas',
  motejei: 'motejei',
  motejem: 'motejem',
  motejes: 'motejes',
  motejos: 'motejos',
  motejou: 'motejou',
  motelas: 'motelas',
  motenas: 'motenas',
  motenos: 'motenos',
  motense: 'motense',
  motetai: 'motetai',
  motetam: 'motetam',
  motetar: 'motetar',
  motetas: 'motetas',
  motetei: 'motetei',
  motetem: 'motetem',
  motetes: 'motetes',
  motetos: 'motetos',
  motetou: 'motetou',
  motevos: 'motevos',
  motilho: 'motilho',
  motinai: 'motinai',
  motinam: 'motinam',
  motinar: 'motinar',
  motinas: 'motinas',
  motinei: 'motinei',
  motinem: 'motinem',
  motines: 'motines',
  motinho: 'motinho',
  motinou: 'motinou',
  motiras: 'motiras',
  motivai: 'motivai',
  motivam: 'motivam',
  motivar: 'motivar',
  motivas: 'motivas',
  motivei: 'motivei',
  motivem: 'motivem',
  motives: 'motives',
  motivos: 'motivos',
  motivou: 'motivou',
  motoboi: 'motobói',
  motobol: 'motobol',
  motoboy: 'motoboy',
  motocas: 'motocas',
  motocus: 'motocus',
  motolos: 'motolos',
  motomes: 'motomes',
  motondo: 'motondo',
  motoras: 'motoras',
  motores: 'motores',
  motoria: 'motória',
  motorio: 'motório',
  motoros: 'motoros',
  motreco: 'motreco',
  motrete: 'motrete',
  motuari: 'motuari',
  motucal: 'motucal',
  motucas: 'motucas',
  motucus: 'motucus',
  motulas: 'motulas',
  motumba: 'motumbá',
  motungo: 'motungo',
  moucada: 'moucada',
  moucado: 'moucado',
  moucais: 'moucais',
  moucamo: 'moucamo',
  moucara: 'moucará',
  moucava: 'moucava',
  mouchao: 'mouchão',
  mouchar: 'mouchar',
  mouches: 'mouches',
  moufada: 'moufada',
  moufado: 'moufado',
  moufais: 'moufais',
  moufamo: 'moufamo',
  moufara: 'moufará',
  moufava: 'moufava',
  moufeis: 'moufeis',
  moufemo: 'moufemo',
  moumada: 'moumada',
  moumado: 'moumado',
  moumais: 'moumais',
  moumamo: 'moumamo',
  moumara: 'moumará',
  moumava: 'moumava',
  moumeis: 'moumeis',
  moumemo: 'moumemo',
  mounhas: 'mounhas',
  mouquei: 'mouquei',
  mouquem: 'mouquem',
  mouques: 'mouques',
  mourada: 'mourada',
  mourado: 'mourado',
  mourais: 'mourais',
  mourama: 'mourama',
  mouramo: 'mouramo',
  mourana: 'mourana',
  mourara: 'mourará',
  mourato: 'mourato',
  mourava: 'mourava',
  moureis: 'moureis',
  moureja: 'moureja',
  moureje: 'moureje',
  mourejo: 'mourejo',
  mouremo: 'mouremo',
  mourera: 'mourera',
  mourete: 'mourete',
  mourita: 'mourita',
  mouriza: 'mouriza',
  mourize: 'mourize',
  mourizo: 'mourizo',
  mouroes: 'mourões',
  mourtes: 'mourtes',
  mousses: 'mousses',
  moutais: 'moutais',
  moutedo: 'moutedo',
  moutoes: 'moutões',
  moutulo: 'moutulo',
  mouzaia: 'mouzaia',
  movamos: 'movamos',
  movedor: 'movedor',
  movemos: 'movemos',
  movenca: 'movença',
  movendo: 'movendo',
  movente: 'movente',
  moveram: 'moveram',
  moverao: 'moverão',
  moveras: 'moveras',
  moverei: 'moverei',
  moverem: 'moverem',
  moveres: 'moveres',
  moveria: 'moveria',
  movermo: 'movermo',
  movesse: 'movesse',
  moveste: 'moveste',
  movidao: 'movidão',
  movidas: 'movidas',
  movidos: 'movidos',
  movieis: 'movíeis',
  moviola: 'moviola',
  movitos: 'móvitos',
  movivel: 'movível',
  movongo: 'movongo',
  moxadao: 'moxadão',
  moxadas: 'moxadas',
  moxador: 'moxador',
  moxados: 'moxados',
  moxamai: 'moxamai',
  moxamam: 'moxamam',
  moxamar: 'moxamar',
  moxamas: 'moxamas',
  moxamei: 'moxamei',
  moxamem: 'moxamem',
  moxames: 'moxames',
  moxamos: 'moxamos',
  moxamou: 'moxamou',
  moxando: 'moxando',
  moxante: 'moxante',
  moxaram: 'moxaram',
  moxarao: 'moxarão',
  moxaras: 'moxarás',
  moxarei: 'moxarei',
  moxarem: 'moxarem',
  moxares: 'moxares',
  moxaria: 'moxaria',
  moxarmo: 'moxarmo',
  moxasse: 'moxasse',
  moxaste: 'moxaste',
  moxavam: 'moxavam',
  moxavas: 'moxavas',
  moxemos: 'moxemos',
  moxicao: 'moxicão',
  moxinga: 'moxinga',
  moxolis: 'moxolis',
  moxubia: 'moxubiá',
  mozambo: 'mozambo',
  mozenas: 'mozenas',
  mozetas: 'mozetas',
  mozinas: 'mozinas',
  mozinha: 'mozinha',
  mozinho: 'mozinho',
  muacara: 'muacara',
  muadies: 'muadiês',
  muadjir: 'muadjir',
  mualaca: 'mualaca',
  mualala: 'mualala',
  mualape: 'mualape',
  mualimo: 'mualimo',
  muambas: 'muambas',
  muambos: 'muambos',
  muamuna: 'muamuna',
  muanapo: 'muanapó',
  muances: 'muances',
  muancos: 'muancos',
  muandas: 'muandas',
  muandio: 'muandio',
  muandis: 'muandis',
  muandiu: 'muandiú',
  muandji: 'muandji',
  muandos: 'muandos',
  muangas: 'muangas',
  muanges: 'muanges',
  muangos: 'muangos',
  muangue: 'muangue',
  muangui: 'muangui',
  muanhas: 'muanhas',
  muanhis: 'muanhis',
  muanzas: 'muanzas',
  muarras: 'muarrãs',
  muatule: 'muatulé',
  muavina: 'muavina',
  mubafos: 'mubafos',
  mubambe: 'mubambe',
  mubambo: 'mubambo',
  mubanda: 'mubanda',
  mubanga: 'mubanga',
  mubango: 'mubango',
  mubanja: 'mubanja',
  mubatas: 'mubatas',
  mubebas: 'mubebas',
  mubelas: 'mubelas',
  mubicas: 'mubicas',
  mubilus: 'mubilus',
  mubires: 'mubires',
  mubonas: 'mubonas',
  mubongo: 'mubongo',
  mubonos: 'mubonos',
  mubotas: 'mubotas',
  mubulas: 'mubulas',
  mubumbo: 'mubumbo',
  mubunda: 'mubunda',
  mubungu: 'mubungu',
  mucaade: 'mucaade',
  mucacas: 'mucacas',
  mucacos: 'muçacos',
  mucages: 'mucages',
  mucaias: 'mucaiás',
  mucaino: 'mucaino',
  mucaixe: 'mucaíxe',
  mucajal: 'mucajal',
  mucajas: 'mucajás',
  mucajes: 'mucajes',
  mucalas: 'mucalas',
  mucales: 'muçales',
  mucalis: 'mucalis',
  mucalos: 'muçalos',
  mucamas: 'mucamas',
  mucamba: 'mucamba',
  mucambe: 'muçambé',
  mucambo: 'mucambo',
  mucanas: 'mucanas',
  mucanda: 'mucanda',
  mucande: 'muçande',
  mucandi: 'mucândi',
  mucando: 'mucando',
  mucanga: 'muçanga',
  mucange: 'mucange',
  mucanha: 'muçanha',
  mucanhi: 'muçanhi',
  mucania: 'mucania',
  mucanje: 'mucanje',
  mucanos: 'mucanos',
  mucapos: 'muçapos',
  mucasso: 'mucasso',
  mucatas: 'mucatas',
  mucatos: 'mucatos',
  mucauas: 'mucaúas',
  mucaxes: 'muçaxes',
  mucaxis: 'mucáxis',
  mucazos: 'mucazos',
  muceces: 'muceces',
  mucefas: 'mucefas',
  mucefos: 'mucefos',
  mucemba: 'mucemba',
  muceque: 'muceque',
  muceras: 'muceras',
  mucetes: 'mucetes',
  muchaco: 'muchaco',
  muchada: 'muchada',
  muchado: 'muchado',
  muchens: 'muchéns',
  muchete: 'muchete',
  muchica: 'muchica',
  muchico: 'muchico',
  muchoes: 'muchões',
  muchope: 'muchope',
  mucicao: 'mucicão',
  mucicas: 'mucicas',
  mucicos: 'múcicos',
  mucinas: 'mucinas',
  mucites: 'mucites',
  mucitos: 'mucitos',
  mucoali: 'mucoáli',
  mucobas: 'mucobas',
  mucobue: 'mucobue',
  mucocos: 'mucocos',
  mucoide: 'mucoide',
  mucolos: 'mucolos',
  mucomas: 'mucomas',
  mucombe: 'mucombe',
  mucombo: 'mucombo',
  muconas: 'muçonas',
  muconda: 'muconda',
  mucondo: 'muçondo',
  mucongo: 'mucongo',
  mucoral: 'mucoral',
  mucorea: 'mucórea',
  mucoreo: 'mucóreo',
  mucosas: 'mucosas',
  mucosos: 'mucosos',
  mucouco: 'mucouco',
  mucoxis: 'mucóxis',
  mucoxos: 'mucoxos',
  mucroes: 'mucrões',
  mucrons: 'múcrons',
  mucuabo: 'mucuabô',
  mucuais: 'mucuais',
  mucuaxe: 'mucuaxe',
  mucubal: 'mucubal',
  mucubas: 'mucubas',
  mucubus: 'mucubus',
  mucucos: 'mucucos',
  mucucus: 'mucucus',
  mucudas: 'mucudas',
  mucudis: 'mucudis',
  mucudos: 'mucudos',
  mucufas: 'mucufas',
  mucufos: 'mucufos',
  mucuiba: 'mucuíba',
  mucuica: 'muçuíca',
  mucuice: 'mucuíce',
  mucuins: 'mucuins',
  mucuios: 'mucuiós',
  mucuius: 'mucuiús',
  mucujas: 'mucujás',
  mucujes: 'mucujés',
  muculas: 'muculas',
  muculmi: 'muçulmi',
  muculos: 'muculos',
  mucumas: 'mucumás',
  mucumba: 'muçumba',
  mucumbi: 'mucumbi',
  mucumbu: 'mucumbu',
  mucumes: 'mucumes',
  mucunas: 'mucunas',
  mucunda: 'mucunda',
  mucunde: 'mucunde',
  mucundo: 'mucundo',
  mucunes: 'mucunes',
  mucunga: 'muçunga',
  mucungo: 'mucungo',
  mucunha: 'mucunha',
  mucunis: 'mucunis',
  mucunza: 'mucunzá',
  mucuoca: 'mucuoca',
  mucuras: 'mucuras',
  mucures: 'mucures',
  mucuris: 'mucuris',
  mucuros: 'mucuros',
  mucurro: 'mucurro',
  mucusas: 'mucusas',
  mucusos: 'mucusos',
  mucusso: 'mucusso',
  mucutas: 'mucutas',
  mucutos: 'mucutos',
  mucuves: 'mucuves',
  mudacao: 'mudação',
  mudadao: 'mudadão',
  mudadas: 'mudadas',
  mudador: 'mudador',
  mudados: 'mudados',
  mudamas: 'mudamas',
  mudamos: 'mudamos',
  mudanca: 'mudança',
  mudando: 'mudando',
  mudante: 'mudante',
  mudaram: 'mudaram',
  mudarao: 'mudarão',
  mudaras: 'mudaras',
  mudarei: 'mudarei',
  mudarem: 'mudarem',
  mudares: 'mudares',
  mudaria: 'mudaria',
  mudarmo: 'mudarmo',
  mudasse: 'mudasse',
  mudaste: 'mudaste',
  mudavam: 'mudavam',
  mudavas: 'mudavas',
  mudavel: 'mudável',
  mudejar: 'mudéjar',
  mudemos: 'mudemos',
  mudende: 'mudende',
  mudezas: 'mudezas',
  mudezes: 'mudezes',
  mudimas: 'mudimas',
  mudinha: 'mudinha',
  mudinho: 'mudinho',
  mudirie: 'mudirié',
  mudiris: 'mudiris',
  mudondo: 'mudondo',
  mudubim: 'mudubim',
  mudulos: 'mudulos',
  muedana: 'muédana',
  muedano: 'muédano',
  muedens: 'muedens',
  muemada: 'muemada',
  muemado: 'muemado',
  muemais: 'muemais',
  muemamo: 'muemamo',
  muemara: 'muemará',
  muemava: 'muemava',
  muemeis: 'muemeis',
  muememo: 'muememo',
  muenada: 'muenada',
  muenado: 'muenado',
  muenais: 'muenais',
  muenamo: 'muenamo',
  muenara: 'muenará',
  muenava: 'muenava',
  muendas: 'muendas',
  muendes: 'muendes',
  mueneis: 'mueneis',
  muenemo: 'muenemo',
  muenexi: 'muenéxi',
  muenhas: 'muenhas',
  muenhes: 'muenhês',
  muenise: 'muenise',
  muenixe: 'muenixe',
  muenque: 'muenque',
  mueslis: 'mueslis',
  muetete: 'muetete',
  muezins: 'muezins',
  mufalos: 'múfalos',
  mufanas: 'mufanas',
  mufetes: 'mufetes',
  mufiate: 'mufiate',
  muficai: 'muficai',
  muficam: 'muficam',
  muficar: 'muficar',
  muficas: 'muficas',
  muficos: 'muficos',
  muficou: 'muficou',
  mufilos: 'mufilos',
  mufinas: 'mufinas',
  mufinos: 'mufinos',
  mufique: 'mufique',
  mufitas: 'mufitas',
  mufitos: 'mufitos',
  mufixes: 'mufixés',
  mufloes: 'muflões',
  mufongo: 'mufongo',
  mufufas: 'mufufas',
  mufumas: 'mufumas',
  mufumba: 'mufumba',
  mufumbe: 'mufumbe',
  mufumbo: 'mufumbo',
  mufumos: 'mufumos',
  mufunfa: 'mufunfa',
  mugambo: 'mugambo',
  muganda: 'muganda',
  mugande: 'mugande',
  muganga: 'muganga',
  mugango: 'mugango',
  mugeira: 'mugeira',
  mugeiro: 'mugeiro',
  mugente: 'mugente',
  mugidao: 'mugidão',
  mugidas: 'mugidas',
  mugidor: 'mugidor',
  mugidos: 'mugidos',
  mugieia: 'mugieia',
  mugieis: 'mugíeis',
  mugimos: 'mugimos',
  mugindo: 'mugindo',
  muginge: 'muginge',
  muginha: 'muginha',
  mugiram: 'mugiram',
  mugirao: 'mugirão',
  mugiras: 'mugiras',
  mugirei: 'mugirei',
  mugirem: 'mugirem',
  mugires: 'mugires',
  mugiria: 'mugiria',
  mugirmo: 'mugirmo',
  mugisse: 'mugisse',
  mugiste: 'mugiste',
  mugodis: 'mugódis',
  mugondo: 'mugondo',
  mugongo: 'mugongo',
  mugonha: 'mugonha',
  mugrias: 'múgrias',
  mugunda: 'mugunda',
  mugunga: 'mugungá',
  mugunge: 'mugunge',
  mugungo: 'mugungo',
  mugunje: 'mugunje',
  mugunza: 'mugunzá',
  muiajes: 'muiajes',
  muianas: 'muianas',
  muicala: 'muicala',
  muichai: 'muichai',
  muimbas: 'muimbas',
  muimbos: 'muimbos',
  muindas: 'muindas',
  muindos: 'muindos',
  muingas: 'muingas',
  muinges: 'muinges',
  muinhas: 'muinhas',
  muinjes: 'muinjes',
  muiombo: 'muiombo',
  muirita: 'muirita',
  muirris: 'muirris',
  muitias: 'muitias',
  muiuira: 'muiuira',
  muiunas: 'muiunas',
  muiures: 'muiurés',
  mujajos: 'mujajos',
  mujamos: 'mujamos',
  mujeira: 'mujeira',
  mujeiro: 'mujeiro',
  mujenas: 'mujenas',
  mujicas: 'mujicas',
  mujicos: 'mujicos',
  mujijes: 'mujijes',
  mujimas: 'mujimas',
  mujimba: 'mujimba',
  mujimbe: 'mujimbe',
  mujimbo: 'mujimbo',
  mujinai: 'mujinai',
  mujinam: 'mujinam',
  mujinar: 'mujinar',
  mujinas: 'mujinas',
  mujinei: 'mujinei',
  mujinem: 'mujinem',
  mujines: 'mujines',
  mujinha: 'mujinha',
  mujinje: 'mujinje',
  mujinou: 'mujinou',
  mujique: 'mujique',
  mujojos: 'mujojos',
  mujolos: 'mujolos',
  mulabas: 'mulabás',
  mulabes: 'mulabes',
  muladai: 'muladai',
  muladam: 'muladam',
  muladar: 'muladar',
  muladas: 'muladas',
  muladei: 'muladei',
  muladem: 'muladem',
  mulades: 'mulades',
  muladis: 'muladis',
  muladou: 'muladou',
  mulages: 'mulages',
  mulajes: 'mulajes',
  mulalas: 'mulalas',
  mulalis: 'mulális',
  mulalos: 'mulalos',
  mulamba: 'mulamba',
  mulambe: 'mulambe',
  mulambo: 'mulambo',
  mulames: 'mulames',
  mulanas: 'mulanas',
  mulando: 'mulando',
  mulango: 'mulango',
  mulatas: 'mulatas',
  mulatos: 'mulatos',
  muldana: 'muldana',
  muldera: 'muldera',
  muleada: 'muleada',
  muleado: 'muleado',
  muleias: 'muleias',
  muleira: 'muleira',
  muleiro: 'muleiro',
  mulelas: 'mulelas',
  muleles: 'muleles',
  mulemas: 'mulemás',
  mulemba: 'mulemba',
  mulembo: 'mulembo',
  mulemos: 'mulemos',
  mulenas: 'mulenas',
  mulende: 'mulende',
  mulenga: 'mulenga',
  mulengo: 'mulengo',
  mulenos: 'mulenos',
  muleras: 'muleras',
  muleria: 'muléria',
  muletas: 'muletas',
  muletim: 'muletim',
  muliada: 'muliada',
  muliado: 'muliado',
  mulidas: 'múlidas',
  mulidea: 'mulídea',
  mulideo: 'mulídeo',
  mulinha: 'mulinha',
  mulinos: 'mulinos',
  mulitas: 'mulitas',
  mullita: 'mullita',
  muloide: 'muloide',
  mulojis: 'mulójis',
  mulolas: 'mulolas',
  mulolos: 'mulolos',
  mulombe: 'mulombe',
  mulombo: 'mulombo',
  mulonda: 'mulonda',
  mulondo: 'mulondo',
  mulonga: 'mulonga',
  mulopes: 'mulopes',
  mulosas: 'mulosas',
  mulosos: 'mulosos',
  mulsoes: 'mulsões',
  mulsona: 'mulsona',
  multada: 'multada',
  multado: 'multado',
  multais: 'multais',
  multamo: 'multamo',
  multana: 'multana',
  multani: 'multani',
  multano: 'multano',
  multara: 'multara',
  multava: 'multava',
  multeis: 'multeis',
  multemo: 'multemo',
  multipa: 'multipá',
  mulucus: 'mulucus',
  mululos: 'mululos',
  mulumba: 'mulumba',
  mulumes: 'mulumes',
  mulundo: 'mulundo',
  mulundu: 'mulundu',
  mulungo: 'mulungo',
  mulungu: 'mulungu',
  mumanga: 'mumanga',
  mumango: 'mumango',
  mumbaca: 'mumbaca',
  mumbava: 'mumbava',
  mumbavo: 'mumbavo',
  mumbebo: 'mumbebo',
  mumbica: 'mumbica',
  mumbiri: 'mumbiri',
  mumbuca: 'mumbuca',
  mumbucu: 'mumbucu',
  mumbula: 'mumbula',
  mumbulo: 'mumbulo',
  mumbura: 'mumbura',
  mumetal: 'mumetal',
  mumizai: 'mumizai',
  mumizam: 'mumizam',
  mumizar: 'mumizar',
  mumizas: 'mumizas',
  mumizei: 'mumizei',
  mumizem: 'mumizem',
  mumizes: 'mumizes',
  mumizou: 'mumizou',
  mumonas: 'mumonas',
  mumonha: 'mumonha',
  mumonos: 'mumonos',
  mumpapa: 'mumpapa',
  mumpepo: 'mumpepo',
  mumpuma: 'mumpuma',
  mumucas: 'mumucas',
  mumungu: 'mumungu',
  mumunha: 'mumunha',
  mumures: 'múmures',
  munamos: 'munamos',
  munanos: 'munanos',
  munchis: 'munchis',
  muncoto: 'muncoto',
  muncure: 'muncure',
  mundaco: 'mundacó',
  mundana: 'mundana',
  mundane: 'mundane',
  mundano: 'mundano',
  mundari: 'mundari',
  mundaus: 'mundaús',
  mundele: 'mundele',
  mundelo: 'mundelo',
  mundeus: 'mundéus',
  mundiai: 'mundiai',
  mundial: 'mundial',
  mundiam: 'mundiam',
  mundiar: 'mundiar',
  mundias: 'mundias',
  mundica: 'mundiça',
  mundice: 'mundice',
  mundiei: 'mundiei',
  mundiem: 'mundiem',
  mundies: 'mundies',
  mundios: 'múndios',
  mundiou: 'mundiou',
  mundobi: 'mundobi',
  mundoes: 'mundões',
  mundube: 'mundubé',
  mundubi: 'mundubi',
  munduca: 'munduca',
  munduri: 'munduri',
  munduru: 'munduru',
  munefes: 'munefes',
  munegas: 'munegas',
  muneira: 'muneira',
  munelho: 'munelho',
  munemas: 'munemas',
  muneres: 'muneres',
  mungaia: 'mungaia',
  mungais: 'mungais',
  mungare: 'mungare',
  mungeis: 'mungeis',
  mungemo: 'mungemo',
  mungera: 'mungera',
  mungiam: 'mungiam',
  mungias: 'mungias',
  mungida: 'mungida',
  mungido: 'mungido',
  mungira: 'mungirá',
  mungolo: 'mungolo',
  mungoma: 'mungoma',
  mungone: 'mungone',
  munguai: 'munguai',
  munguba: 'munguba',
  mungubu: 'mungubu',
  mungues: 'mungues',
  munguta: 'munguta',
  munguza: 'munguzá',
  munhafo: 'munhafo',
  munhata: 'munhata',
  munheca: 'munheca',
  munheco: 'munheco',
  munhere: 'munhere',
  munhime: 'munhime',
  munhimo: 'munhimo',
  munhoes: 'munhões',
  municao: 'munição',
  muniche: 'muniche',
  munichi: 'munichi',
  municia: 'municia',
  municie: 'municie',
  municio: 'munício',
  munidao: 'munidão',
  munidas: 'munidas',
  munidor: 'munidor',
  munidos: 'munidos',
  munieis: 'muníeis',
  munimos: 'munimos',
  munindo: 'munindo',
  muningo: 'muningo',
  muninhe: 'muninhé',
  munipiu: 'munipiú',
  muniram: 'muniram',
  munirao: 'munirão',
  muniras: 'muniras',
  munirei: 'munirei',
  munirem: 'munirem',
  munires: 'munires',
  muniria: 'muniria',
  munirmo: 'munirmo',
  munisse: 'munisse',
  muniste: 'muniste',
  munjais: 'munjais',
  munjamo: 'munjamo',
  munjojo: 'munjojo',
  munjolo: 'munjolo',
  munjovo: 'munjovo',
  munjues: 'munjues',
  munongo: 'munongo',
  munonos: 'munonos',
  munquam: 'munquam',
  munquas: 'munquas',
  munquem: 'munquém',
  munques: 'munques',
  munquia: 'munquia',
  munquir: 'munquir',
  munquis: 'munquis',
  munquiu: 'munquiu',
  muntudo: 'muntudo',
  munupiu: 'munupiú',
  munurus: 'munurus',
  muonico: 'muônico',
  muonios: 'muônios',
  muonium: 'muônium',
  mupacos: 'mupacos',
  mupanda: 'mupanda',
  mupapas: 'mupapas',
  mupecas: 'mupeças',
  mupeixe: 'mupeixe',
  mupeque: 'mupeque',
  mupeuas: 'mupéuas',
  mupexes: 'mupexes',
  mupicai: 'mupicai',
  mupicam: 'mupicam',
  mupicar: 'mupicar',
  mupicas: 'mupicas',
  mupicou: 'mupicou',
  mupinda: 'mupinda',
  mupique: 'mupique',
  mupiras: 'mupiras',
  mupondo: 'mupondo',
  muponga: 'muponga',
  mupucai: 'mupucai',
  mupucam: 'mupucam',
  mupucar: 'mupucar',
  mupucas: 'mupucas',
  mupucou: 'mupucou',
  mupumbe: 'mupumbe',
  mupunga: 'mupunga',
  mupupos: 'mupupos',
  mupuque: 'mupuque',
  muqueai: 'muqueai',
  muquear: 'muquear',
  muqueca: 'muqueca',
  muqueei: 'muqueei',
  muqueia: 'muqueia',
  muqueie: 'muqueie',
  muqueio: 'muqueio',
  muqueou: 'muqueou',
  muquero: 'muquero',
  muquete: 'muquete',
  muquias: 'múquias',
  muquice: 'muquice',
  muquico: 'muquiço',
  muquifo: 'muquifo',
  muquila: 'muquila',
  muquira: 'muquira',
  muquixe: 'muquixe',
  muquixi: 'muquíxi',
  muracas: 'muracás',
  muradal: 'muradal',
  muradao: 'muradão',
  muradas: 'muradas',
  murador: 'murador',
  murados: 'murados',
  muragem: 'muragem',
  murales: 'murales',
  muralha: 'muralha',
  muralhe: 'muralhe',
  muralho: 'muralho',
  muramos: 'muramos',
  murando: 'murando',
  muranha: 'muranha',
  muranho: 'muranho',
  murante: 'murante',
  muraram: 'muraram',
  murarao: 'murarão',
  muraras: 'muraras',
  murarei: 'murarei',
  murarem: 'murarem',
  murares: 'murares',
  muraria: 'muraria',
  murario: 'murário',
  murarmo: 'murarmo',
  murasse: 'murasse',
  muraste: 'muraste',
  muravam: 'muravam',
  muravas: 'muravas',
  murcada: 'murçada',
  murcado: 'murçado',
  murchai: 'murchai',
  murcham: 'murcham',
  murchar: 'murchar',
  murchas: 'murchas',
  murchei: 'murchei',
  murchem: 'murchem',
  murches: 'murches',
  murchez: 'murchez',
  murchos: 'murchos',
  murchou: 'murchou',
  murcuto: 'murcuto',
  mureada: 'mureada',
  mureado: 'mureado',
  mureais: 'mureais',
  mureara: 'mureará',
  mureava: 'mureava',
  mureeis: 'mureeis',
  mureiam: 'mureiam',
  mureias: 'mureias',
  mureiem: 'mureiem',
  mureies: 'mureies',
  mureina: 'mureína',
  mureira: 'mureira',
  mureita: 'mureita',
  murejao: 'murejão',
  muremos: 'muremos',
  murenas: 'murenas',
  murense: 'murense',
  mureres: 'murerés',
  murerus: 'murerus',
  muretas: 'muretas',
  muretes: 'muretes',
  muretos: 'muretos',
  murfela: 'murfela',
  muriacu: 'muriaçu',
  muriato: 'muriato',
  murices: 'múrices',
  muricis: 'muricis',
  muridas: 'múridas',
  muridea: 'murídea',
  murideo: 'murídeo',
  murinas: 'murinas',
  murindo: 'murindo',
  murinea: 'murínea',
  murineo: 'muríneo',
  murinho: 'murinho',
  murinos: 'murinos',
  muriqui: 'muriqui',
  muriris: 'muriris',
  murismo: 'murismo',
  muritim: 'muritim',
  muritis: 'muritis',
  murixis: 'murixis',
  murjina: 'murjiná',
  murjine: 'murjine',
  murjino: 'murjino',
  murjona: 'murjona',
  murmura: 'murmura',
  murmure: 'murmuré',
  murmuro: 'múrmuro',
  murnaus: 'murnaus',
  murquam: 'murquam',
  murquas: 'murquas',
  murquem: 'murquem',
  murques: 'murques',
  murquia: 'murquia',
  murquir: 'murquir',
  murquis: 'murquis',
  murquiu: 'murquiu',
  murraca: 'murraça',
  murraco: 'murraco',
  murraia: 'murraia',
  murraya: 'murraya',
  murreda: 'murreda',
  murrega: 'murrega',
  murrena: 'murrena',
  murreta: 'murreta',
  murrete: 'murrete',
  murrina: 'múrrina',
  murrino: 'múrrino',
  murroas: 'murroas',
  murroes: 'murrões',
  murrues: 'murrués',
  murruto: 'murruto',
  murtais: 'murtais',
  murtila: 'murtila',
  murtuco: 'murtuço',
  muruari: 'muruari',
  murubus: 'murubus',
  murucas: 'murucas',
  muruchi: 'muruchi',
  murucis: 'murucis',
  murucus: 'murucus',
  murugem: 'murugem',
  muruins: 'muruins',
  murujai: 'murujai',
  murujam: 'murujam',
  murujar: 'murujar',
  murujas: 'murujas',
  murujei: 'murujei',
  murujem: 'murujem',
  murujes: 'murujes',
  murujou: 'murujou',
  murumbu: 'murumbu',
  murumos: 'murumos',
  murundo: 'murundo',
  murundu: 'murundu',
  murunga: 'murunga',
  murungo: 'murungo',
  murungu: 'murungu',
  murupis: 'murupis',
  muruqui: 'muruqui',
  murures: 'mururés',
  mururua: 'mururuá',
  mururus: 'mururus',
  murutis: 'murutis',
  muruuas: 'muruuás',
  muruxis: 'muruxis',
  murzela: 'murzela',
  murzelo: 'murzelo',
  musacas: 'musaças',
  musacea: 'musácea',
  musaceo: 'musáceo',
  musales: 'musales',
  musamba: 'musamba',
  musanda: 'musanda',
  musanga: 'musanga',
  musango: 'musango',
  musanhi: 'musanhi',
  musaria: 'musaria',
  musassa: 'musassa',
  muscais: 'muscais',
  muscari: 'muscari',
  muscida: 'múscida',
  muscita: 'muscita',
  muscles: 'muscles',
  muscona: 'muscona',
  muscosa: 'muscosa',
  muscoso: 'muscoso',
  musculo: 'músculo',
  musenas: 'musenas',
  musesse: 'musesse',
  musetas: 'musetas',
  musette: 'musette',
  musgaca: 'musgaça',
  musgada: 'musgada',
  musgado: 'musgado',
  musgais: 'musgais',
  musgara: 'musgará',
  musgava: 'musgava',
  musgoes: 'musgões',
  musgosa: 'musgosa',
  musgoso: 'musgoso',
  musguei: 'musguei',
  musguem: 'musguem',
  musgues: 'musgues',
  musicai: 'musicai',
  musical: 'musical',
  musicam: 'musicam',
  musicar: 'musicar',
  musicas: 'musicas',
  musicos: 'músicos',
  musicou: 'musicou',
  musique: 'musique',
  musismo: 'musismo',
  musitas: 'musitas',
  muslema: 'muslema',
  muslemo: 'muslemo',
  muslins: 'muslins',
  musoles: 'musoles',
  musosas: 'musosas',
  musosos: 'musosos',
  musquem: 'musquem',
  musques: 'musques',
  musquia: 'músquia',
  mussaca: 'mussaca',
  mussafe: 'mussafe',
  mussala: 'mussala',
  mussale: 'mussale',
  mussalo: 'mussalo',
  mussapo: 'mussapo',
  mussefa: 'mussefa',
  mussefo: 'mussefo',
  mussina: 'mussina',
  mussino: 'mussino',
  mussita: 'mussita',
  mussite: 'mussite',
  mussito: 'mussito',
  mussius: 'mussiús',
  mussoco: 'mussoco',
  mussuas: 'mussuás',
  mussuco: 'mussuco',
  mussume: 'mussumé',
  mustafa: 'mustafá',
  mustela: 'mustela',
  mustelo: 'mustelo',
  mustone: 'mustone',
  musumba: 'musumba',
  musunos: 'musunos',
  musurga: 'musurga',
  musurgo: 'musurgo',
  mutacao: 'mutação',
  mutacas: 'mutacas',
  mutacos: 'mutacos',
  mutalas: 'mutalas',
  mutamba: 'mutamba',
  mutambo: 'mutambo',
  mutanas: 'mutanas',
  mutanca: 'mutança',
  mutanje: 'mutanje',
  mutanos: 'mutanos',
  mutanta: 'mutanta',
  mutante: 'mutante',
  mutapas: 'mutapás',
  mutases: 'mútases',
  mutavel: 'mutável',
  muteate: 'muteate',
  mutelas: 'mutelas',
  muteles: 'muteles',
  mutemba: 'mutemba',
  mutembe: 'mutembe',
  mutembo: 'mutembo',
  mutenga: 'mutenga',
  mutenge: 'mutenge',
  mutenje: 'mutenje',
  mutepas: 'mutepas',
  mutetas: 'mutetas',
  mutetes: 'mutetes',
  mutiati: 'mutiáti',
  mutibis: 'mutibis',
  muticas: 'muticas',
  muticos: 'muticos',
  mutilai: 'mutilai',
  mutilam: 'mutilam',
  mutilao: 'mutilão',
  mutilar: 'mutilar',
  mutilas: 'mutilas',
  mutilei: 'mutilei',
  mutilem: 'mutilem',
  mutiles: 'mutiles',
  mutilos: 'mútilos',
  mutilou: 'mutilou',
  mutinos: 'mutinos',
  mutirao: 'mutirão',
  mutirom: 'mutirom',
  mutirum: 'mutirum',
  mutisia: 'mutísia',
  mutismo: 'mutismo',
  mutista: 'mutista',
  mutobos: 'mutobos',
  mutobua: 'mutóbua',
  mutobue: 'mutobué',
  mutocos: 'mutocos',
  mutombo: 'mutombo',
  mutonas: 'mutonas',
  mutondo: 'mutondo',
  mutones: 'mutones',
  mutonje: 'mutonje',
  mutonto: 'mutonto',
  mutopas: 'mutopas',
  mutoves: 'mutoves',
  mutoxes: 'mutoxes',
  mutrada: 'mutrada',
  mutrado: 'mutrado',
  mutrais: 'mutrais',
  mutramo: 'mutramo',
  mutrara: 'mutrará',
  mutrava: 'mutrava',
  mutreis: 'mutreis',
  mutremo: 'mutremo',
  mutreta: 'mutreta',
  mutrete: 'mutrete',
  mutreto: 'mutreto',
  mutuada: 'mutuada',
  mutuado: 'mutuado',
  mutuais: 'mutuais',
  mutuamo: 'mutuamo',
  mutuara: 'mutuará',
  mutuava: 'mutuava',
  mutucal: 'mutucal',
  mutucao: 'mutucão',
  mutucas: 'mutucas',
  mutueis: 'mutueis',
  mutuemo: 'mutuemo',
  mutular: 'mutular',
  mutulas: 'mutulas',
  mutulos: 'mútulos',
  mutumbu: 'mutumbu',
  mutunda: 'mutunda',
  mutundo: 'mutundo',
  mutunes: 'mutunes',
  mutunga: 'mutunga',
  mutunge: 'mutunge',
  mutungo: 'mutungo',
  mutunje: 'mutunje',
  mutunte: 'mutunte',
  mutuque: 'mutuque',
  muturis: 'muturis',
  mututas: 'mututas',
  mututis: 'mututis',
  mututos: 'mututos',
  mututus: 'mututus',
  muumbes: 'muumbes',
  muungos: 'muungos',
  muvalas: 'muvalas',
  muvandi: 'muvândi',
  muvomba: 'muvomba',
  muvovos: 'muvovos',
  muvucas: 'muvucas',
  muvugas: 'muvugas',
  muvulia: 'muvúlia',
  muxaras: 'muxaras',
  muxaxas: 'muxaxas',
  muxaxos: 'muxaxos',
  muxecas: 'muxecas',
  muxenxe: 'muxenxe',
  muxibas: 'muxibas',
  muxibis: 'muxibis',
  muxicai: 'muxicai',
  muxicam: 'muxicam',
  muxicao: 'muxicão',
  muxicar: 'muxicar',
  muxicas: 'muxicas',
  muxicou: 'muxicou',
  muximai: 'muximai',
  muximam: 'muximam',
  muximar: 'muximar',
  muximas: 'muximas',
  muximba: 'muximba',
  muximei: 'muximei',
  muximem: 'muximem',
  muximes: 'muximes',
  muximou: 'muximou',
  muxinda: 'muxinda',
  muxinga: 'muxinga',
  muxinha: 'muxinha',
  muxinta: 'muxinta',
  muxique: 'muxique',
  muxirao: 'muxirão',
  muxiras: 'muxirãs',
  muxiris: 'muxiris',
  muxirom: 'muxirom',
  muxirum: 'muxirum',
  muxitos: 'muxitos',
  muxixao: 'muxixão',
  muxixes: 'muxixes',
  muxoves: 'muxoves',
  muxoxai: 'muxoxai',
  muxoxam: 'muxoxam',
  muxoxar: 'muxoxar',
  muxoxas: 'muxoxas',
  muxoxea: 'muxoxeá',
  muxoxei: 'muxoxei',
  muxoxem: 'muxoxem',
  muxoxes: 'muxoxes',
  muxoxos: 'muxoxos',
  muxoxou: 'muxoxou',
  muxuris: 'muxuris',
  muzamba: 'muzamba',
  muzambe: 'muzambê',
  muzambo: 'muzambo',
  muzanda: 'muzanda',
  muzango: 'muzango',
  muzebas: 'muzebas',
  muzemba: 'muzemba',
  muzenga: 'muzenga',
  muzenza: 'muzenza',
  muzenze: 'muzenze',
  muzimba: 'muzimba',
  muzimbo: 'muzimbo',
  muzimos: 'muzimos',
  muzinga: 'muzinga',
  muzomba: 'muzomba',
  muzombe: 'muzombe',
  muzombo: 'muzombo',
  muzozos: 'muzozos',
  muzucos: 'muzucos',
  muzumba: 'muzumba',
  muzumbi: 'muzumbi',
  muzumbo: 'muzumbo',
  muzunas: 'muzunas',
  muzundu: 'muzundu',
  muzungo: 'muzungo',
  muzungu: 'muzúngu',
  muzunos: 'muzunos',
  muzuzus: 'muzuzus',
  mxcviii: 'mxcviii',
  mxlviii: 'mxlviii',
  mxxviii: 'mxxviii',
  mxxxiii: 'mxxxiii',
  mxxxvii: 'mxxxvii',
  nababia: 'nababia',
  nababos: 'nababos',
  nabadas: 'nabadas',
  nabades: 'nabades',
  nabanga: 'nabanga',
  nabatas: 'nábatas',
  nabateu: 'nabateu',
  nabical: 'nabiçal',
  nabicas: 'nabiças',
  nabidea: 'nabídea',
  nabideo: 'nabídeo',
  nabinea: 'nabínea',
  nabineo: 'nabíneo',
  nabinha: 'nabinha',
  nablios: 'náblios',
  nabucos: 'nabucos',
  nabulos: 'nábulos',
  nacacas: 'nacacas',
  nacadar: 'nacadar',
  nacadas: 'nacadas',
  nacarai: 'nacarai',
  nacaram: 'nacaram',
  nacarar: 'nacarar',
  nacaras: 'nacaras',
  nacarei: 'nacarei',
  nacarem: 'nacarem',
  nacares: 'nácares',
  nacaria: 'nacária',
  nacarou: 'nacarou',
  nacauas: 'nacauãs',
  nacazia: 'nacázia',
  nacejas: 'nacejas',
  nacelas: 'nacelas',
  naceles: 'naceles',
  nacerda: 'nacerda',
  nacharo: 'nácharo',
  nacibos: 'nacibos',
  naclito: 'naclito',
  nacodas: 'nacodás',
  naconas: 'naçonas',
  nacreas: 'nacreás',
  nacrita: 'nacrita',
  nacrite: 'nacrite',
  nacuini: 'nacuini',
  nadabau: 'nadabau',
  nadadao: 'nadadão',
  nadadas: 'nadadas',
  nadador: 'nadador',
  nadados: 'nadados',
  nadamos: 'nadamos',
  nadando: 'nadando',
  nadante: 'nadante',
  nadapoa: 'nadapoa',
  nadaram: 'nadaram',
  nadarao: 'nadarão',
  nadaras: 'nadaras',
  nadarei: 'nadarei',
  nadarem: 'nadarem',
  nadares: 'nadares',
  nadaria: 'nadaria',
  nadarmo: 'nadarmo',
  nadasse: 'nadasse',
  nadaste: 'nadaste',
  nadavam: 'nadavam',
  nadavas: 'nadavas',
  nadegal: 'nadegal',
  nadegas: 'nádegas',
  nademos: 'nademos',
  nadense: 'nadense',
  nadicas: 'nadicas',
  nadidas: 'nadidas',
  nadimba: 'nadimba',
  nadinas: 'nadinas',
  nadinha: 'nadinha',
  nadiral: 'nadiral',
  nadivas: 'nadivas',
  nadivel: 'nadível',
  nadivos: 'nadivos',
  nadolol: 'nadolol',
  naegita: 'naegita',
  naegite: 'naegite',
  nafanas: 'nafanas',
  nafegas: 'náfegas',
  nafegos: 'náfegos',
  nafrica: 'náfrica',
  nafrico: 'náfrico',
  naftasa: 'naftasa',
  naftato: 'naftato',
  nafteno: 'nafteno',
  naftila: 'naftila',
  naftina: 'naftina',
  naftois: 'naftóis',
  naftola: 'naftola',
  nafuqua: 'nafuquá',
  nafutes: 'nafutes',
  nagadao: 'nagadão',
  nagadas: 'nagadas',
  nagador: 'nagador',
  nagados: 'nagados',
  nagaica: 'nagaica',
  nagaina: 'nagaína',
  nagalhe: 'nagalhé',
  nagalho: 'nagalho',
  nagamos: 'nagamos',
  naganas: 'naganas',
  nagando: 'nagando',
  nagante: 'nagante',
  nagaram: 'nagaram',
  nagarao: 'nagarão',
  nagaras: 'nagarás',
  nagarei: 'nagarei',
  nagarem: 'nagarem',
  nagares: 'nagares',
  nagaria: 'nagaria',
  nagasse: 'nagasse',
  nagaste: 'nagaste',
  nagavam: 'nagavam',
  nagavas: 'nagavas',
  nageras: 'nageras',
  nageres: 'nageres',
  nagibes: 'nagibes',
  naguais: 'naguais',
  nagueis: 'nagueis',
  naguins: 'naguins',
  naiadal: 'naiadal',
  naiadas: 'náiadas',
  naiadea: 'naiádea',
  naiadeo: 'naiádeo',
  naiades: 'náiades',
  naianga: 'naianga',
  naibres: 'naibres',
  naideas: 'náideas',
  naifada: 'naifada',
  naifram: 'naifram',
  naifras: 'naifras',
  naifrem: 'naifrem',
  naifres: 'naifres',
  naifria: 'naifria',
  naifrir: 'naifrir',
  naifris: 'naifris',
  naifriu: 'naifriu',
  nailons: 'náilons',
  naipada: 'naipada',
  naipado: 'naipado',
  naipais: 'naipais',
  naipamo: 'naipamo',
  naipara: 'naipará',
  naipava: 'naipava',
  naipeis: 'naipeis',
  naipemo: 'naipemo',
  naiques: 'naiques',
  naiteia: 'naiteia',
  naivaxa: 'naivaxa',
  naiveca: 'naiveca',
  najeras: 'najeras',
  najicas: 'najicas',
  najicos: 'najicos',
  najurus: 'najurus',
  nalagus: 'nalagus',
  nalasso: 'nalasso',
  nalatas: 'nalatas',
  nalgada: 'nalgada',
  nalguda: 'nalguda',
  nalgudo: 'nalgudo',
  nalguem: 'nalguém',
  nalguma: 'nalguma',
  nalguns: 'nalguns',
  namaqua: 'namáqua',
  namarra: 'namarra',
  namazes: 'namazes',
  namazis: 'namázis',
  nambete: 'nambete',
  nambiju: 'nambiju',
  nambuas: 'nambuas',
  nambula: 'nambula',
  namburi: 'namburi',
  nambuus: 'nambuus',
  nameias: 'nameias',
  namibio: 'namíbio',
  namnete: 'namnete',
  namoins: 'namoins',
  namonge: 'namonge',
  namorai: 'namorai',
  namoram: 'namoram',
  namorar: 'namorar',
  namoras: 'namoras',
  namorei: 'namorei',
  namorem: 'namorem',
  namores: 'namores',
  namoros: 'namoros',
  namorou: 'namorou',
  namoxim: 'namoxim',
  namunus: 'namunus',
  nanacis: 'nanacis',
  nanadao: 'nanadão',
  nanadas: 'nanadas',
  nanador: 'nanador',
  nanados: 'nanados',
  nanamos: 'nanamos',
  nanando: 'nanando',
  nanante: 'nanante',
  nanaram: 'nanaram',
  nanarao: 'nanarão',
  nanaras: 'nanaras',
  nanarei: 'nanarei',
  nanarem: 'nanarem',
  nanares: 'nanares',
  nanaria: 'nanaria',
  nanarmo: 'nanarmo',
  nanasse: 'nanasse',
  nanaste: 'nanaste',
  nanatos: 'nanatos',
  nanauis: 'nanauís',
  nanavam: 'nanavam',
  nanavas: 'nanavas',
  nanavis: 'nanavis',
  nandaia: 'nandaia',
  nandane: 'nandane',
  nandina: 'nandina',
  nanemos: 'nanemos',
  nangone: 'nangone',
  nangoro: 'nangoro',
  nangras: 'nangras',
  nanholo: 'nanholo',
  nanicas: 'nanicas',
  nanicos: 'nanicos',
  nanines: 'nanines',
  naninha: 'naninha',
  naninho: 'naninho',
  nanisco: 'nanisco',
  nanismo: 'nanismo',
  nanista: 'nanista',
  nanodea: 'nanódea',
  nanodeo: 'nanódeo',
  nanodes: 'nanodes',
  nanofia: 'nanófia',
  nanofio: 'nanofio',
  nanoide: 'nanoide',
  nanoled: 'nanoled',
  nanomol: 'nanomol',
  nanopos: 'nânopos',
  nanquim: 'nanquim',
  nantala: 'nantala',
  nantesa: 'nantesa',
  nantuto: 'nantuto',
  naozoes: 'nãozões',
  napacea: 'napácea',
  napaceo: 'napáceo',
  napalns: 'napalns',
  napeias: 'napeias',
  napeira: 'napeira',
  napeiro: 'napeiro',
  napelos: 'napelos',
  naperao: 'naperão',
  napevas: 'napevas',
  napista: 'napista',
  napitas: 'napitas',
  napoles: 'nápoles',
  napopes: 'napopés',
  napulas: 'napulas',
  napupas: 'napupas',
  napuras: 'napuras',
  naquela: 'naquela',
  naquele: 'naquele',
  naquibe: 'naquibe',
  naquilo: 'naquilo',
  naranio: 'narânio',
  naranol: 'naranol',
  narazes: 'narazes',
  narbona: 'narbona',
  narceja: 'narceja',
  narcina: 'narcina',
  narcisa: 'narcisa',
  narcise: 'narcise',
  narciso: 'narciso',
  narcose: 'narcose',
  nardias: 'nárdias',
  nardina: 'nárdina',
  nardino: 'nardino',
  nardoas: 'nárdoas',
  narduro: 'narduro',
  nareces: 'nareces',
  naresia: 'narésia',
  naresio: 'narésio',
  nargois: 'nargóis',
  naricas: 'naricas',
  naricia: 'narícia',
  naricio: 'narício',
  nariela: 'nariela',
  narigao: 'narigão',
  narilao: 'narilão',
  narinas: 'narinas',
  narisca: 'narisca',
  narisco: 'narisco',
  narizes: 'narizes',
  narlela: 'narlela',
  narnela: 'narnela',
  naropes: 'naropes',
  narosca: 'narosca',
  narouca: 'narouca',
  narouco: 'narouco',
  narrada: 'narrada',
  narrado: 'narrado',
  narrais: 'narrais',
  narramo: 'narramo',
  narrara: 'narrara',
  narrava: 'narrava',
  narreis: 'narreis',
  narremo: 'narremo',
  nartece: 'nartece',
  narvais: 'narvais',
  nasalai: 'nasalai',
  nasalam: 'nasalam',
  nasalar: 'nasalar',
  nasalas: 'nasalas',
  nasalei: 'nasalei',
  nasalem: 'nasalem',
  nasales: 'nasales',
  nasalis: 'nasális',
  nasalou: 'nasalou',
  nasamao: 'nasamão',
  nasamas: 'nasamãs',
  nasaras: 'nasaras',
  nasardo: 'nasardo',
  nasavia: 'nasávia',
  nascais: 'nasçais',
  nascamo: 'nasçamo',
  nasceis: 'nasceis',
  nascemo: 'nascemo',
  nascera: 'nascera',
  nasciam: 'nasciam',
  nascias: 'nascias',
  nascida: 'nascida',
  nascido: 'nascido',
  nascios: 'náscios',
  nasicas: 'násicas',
  nasicos: 'násicos',
  nasoreu: 'nasoreu',
  nasrida: 'násrida',
  nassada: 'nassada',
  nassana: 'nassaná',
  nassari: 'nassari',
  nassela: 'nassela',
  nassipa: 'nassipa',
  nassula: 'nássula',
  nastias: 'nastias',
  nastica: 'nástica',
  nastico: 'nástico',
  nastina: 'nastina',
  nastrai: 'nastrai',
  nastram: 'nastram',
  nastrar: 'nastrar',
  nastras: 'nastras',
  nastrei: 'nastrei',
  nastrem: 'nastrem',
  nastres: 'nastres',
  nastros: 'nastros',
  nastrou: 'nastrou',
  nasutos: 'nasutos',
  natabeu: 'natabeu',
  natacao: 'natação',
  natadas: 'natadas',
  natados: 'natados',
  natafes: 'natafes',
  natalis: 'natális',
  natalos: 'natalos',
  natante: 'natante',
  nataria: 'natária',
  natario: 'natário',
  natatil: 'natátil',
  nateira: 'nateira',
  nateiro: 'nateiro',
  natenta: 'natenta',
  natento: 'natento',
  naticas: 'náticas',
  natices: 'nátices',
  natineu: 'natineu',
  natinha: 'natinha',
  natioro: 'natioro',
  nativas: 'nativas',
  natives: 'natives',
  nativos: 'nativos',
  nativus: 'nativus',
  natomia: 'natomia',
  natreno: 'natreno',
  natrios: 'nátrios',
  natrita: 'natrita',
  natroes: 'natrões',
  natruns: 'natruns',
  naturai: 'naturai',
  natural: 'natural',
  naturam: 'naturam',
  naturar: 'naturar',
  naturas: 'naturas',
  naturei: 'naturei',
  naturem: 'naturem',
  natures: 'natures',
  naturou: 'naturou',
  natuvas: 'natuvas',
  nauatle: 'náuatle',
  nauclea: 'naúclea',
  naucleo: 'naúcleo',
  naucora: 'náucora',
  naucore: 'náucore',
  naucuas: 'naucuás',
  naudina: 'naudina',
  nauetes: 'nauetes',
  naufica: 'náufica',
  naufico: 'náufico',
  naumaco: 'náumaco',
  naundes: 'naundes',
  naupeta: 'náupeta',
  nauplia: 'náuplia',
  nauplio: 'náuplio',
  nauquas: 'nauquás',
  naurana: 'naurana',
  naurano: 'naurano',
  nauseai: 'nauseai',
  nausear: 'nausear',
  nauseas: 'náuseas',
  nauseei: 'nauseei',
  nauseia: 'nauseia',
  nauseie: 'nauseie',
  nauseio: 'nauseio',
  nauseou: 'nauseou',
  nautica: 'náutica',
  nautico: 'náutico',
  nautilo: 'náutilo',
  navagem: 'navagem',
  navajas: 'navajas',
  navajos: 'navajos',
  navalha: 'navalha',
  navalhe: 'navalhe',
  navalho: 'navalho',
  navarca: 'navarca',
  navarco: 'navarco',
  navarra: 'navarra',
  navarro: 'navarro',
  navegai: 'navegai',
  navegam: 'navegam',
  navegar: 'navegar',
  navegas: 'navegas',
  navegou: 'navegou',
  navegue: 'navegue',
  navelai: 'navelai',
  navelam: 'navelam',
  navelar: 'navelar',
  navelas: 'navelas',
  navelei: 'navelei',
  navelem: 'navelem',
  naveles: 'naveles',
  navelou: 'navelou',
  navetas: 'navetas',
  naxanim: 'naxanim',
  naxaras: 'náxaras',
  naxaros: 'náxaros',
  naxenim: 'naxenim',
  naxiana: 'naxiana',
  naxiano: 'naxiano',
  nazarea: 'nazárea',
  nazareo: 'nazáreo',
  nazareu: 'nazareu',
  nazeris: 'nazéris',
  nazireu: 'nazireu',
  nazismo: 'nazismo',
  nazista: 'nazista',
  nazoreu: 'nazoreu',
  ndandos: 'ndandos',
  ndarama: 'ndarama',
  ndendos: 'ndendos',
  ndimbas: 'ndimbas',
  ndombas: 'ndombas',
  ndongos: 'ndongos',
  ndumbas: 'ndumbas',
  ndzavas: 'ndzavas',
  nealoto: 'nealoto',
  neanias: 'neânias',
  neanica: 'neânica',
  neanico: 'neânico',
  neaozao: 'neãozão',
  nearcas: 'nearcas',
  nearcos: 'nearcos',
  neassos: 'neassos',
  nebafos: 'nebafos',
  nebalia: 'nebália',
  nebanga: 'nebanga',
  nebasao: 'nebasão',
  nebasos: 'nebasos',
  nebeles: 'nébeles',
  nebelia: 'nebélia',
  neblina: 'neblina',
  nebline: 'nebline',
  neblino: 'neblino',
  nebondo: 'nebondo',
  nebonzo: 'nebonzo',
  nebotos: 'nebotos',
  nebrias: 'nébrias',
  nebrida: 'nébrida',
  nebride: 'nébride',
  nebrido: 'nébrido',
  nebrina: 'nebrina',
  nebrine: 'nebrine',
  nebrino: 'nebrino',
  nebrita: 'nebrita',
  nebrite: 'nebrite',
  nebroma: 'nebroma',
  nebruna: 'nebruna',
  nebruno: 'nebruno',
  nebular: 'nebular',
  nebulas: 'nébulas',
  nebulio: 'nebúlio',
  necaras: 'nécaras',
  necator: 'necator',
  neceada: 'neceada',
  neceado: 'neceado',
  neceais: 'neceais',
  neceara: 'neceará',
  neceava: 'neceava',
  necedro: 'necedro',
  neceeis: 'neceeis',
  neceiam: 'neceiam',
  neceias: 'neceias',
  neceiem: 'neceiem',
  neceies: 'neceies',
  necisia: 'necísia',
  necocos: 'necocos',
  necodas: 'necodás',
  necoita: 'necoíta',
  necrode: 'necrode',
  necrodo: 'necrodo',
  necrosa: 'necrosa',
  necrose: 'necrose',
  necroso: 'necroso',
  nectica: 'néctica',
  nectico: 'néctico',
  nectita: 'nectita',
  nectons: 'néctons',
  nectria: 'néctria',
  necturo: 'necturo',
  necuria: 'necuriá',
  nedendo: 'nedendo',
  nedinho: 'nedinho',
  neditas: 'neditas',
  nedungo: 'nedungo',
  neegatu: 'neegatu',
  neesias: 'neésias',
  nefalia: 'nefália',
  nefalio: 'nefálio',
  nefanda: 'nefanda',
  nefando: 'nefando',
  nefanes: 'néfanes',
  nefaria: 'nefária',
  nefario: 'nefário',
  nefasta: 'nefasta',
  nefasto: 'nefasto',
  nefelio: 'nefélio',
  nefelis: 'néfelis',
  nefelos: 'nefelos',
  nefenas: 'nefenas',
  nefetas: 'nêfetas',
  nefilas: 'néfilas',
  nefitas: 'nefitas',
  nefodos: 'néfodos',
  nefopam: 'nefopam',
  nefrica: 'néfrica',
  nefrico: 'néfrico',
  nefrina: 'nefrina',
  nefrita: 'nefrita',
  nefrite: 'nefrite',
  nefrito: 'nefrito',
  nefroge: 'nefroge',
  nefroma: 'nefroma',
  nefrons: 'néfrons',
  nefrope: 'nefrope',
  nefrose: 'nefrose',
  nefteas: 'néfteas',
  neftite: 'neftite',
  negacai: 'negaçai',
  negacam: 'negaçam',
  negacao: 'negação',
  negacar: 'negaçar',
  negacas: 'negaças',
  negacea: 'negaceá',
  negacei: 'negacei',
  negacem: 'negacem',
  negaces: 'negaces',
  negacia: 'negácia',
  negacou: 'negaçou',
  negadao: 'negadão',
  negadas: 'negadas',
  negador: 'negador',
  negados: 'negados',
  negalha: 'negalha',
  negalho: 'negalho',
  negamos: 'negamos',
  negando: 'negando',
  negante: 'negante',
  negaram: 'negaram',
  negarao: 'negarão',
  negaras: 'negaras',
  negarca: 'negarça',
  negarei: 'negarei',
  negarem: 'negarem',
  negares: 'negares',
  negaria: 'negaria',
  negarmo: 'negarmo',
  negasse: 'negasse',
  negaste: 'negaste',
  negavam: 'negavam',
  negavas: 'negavas',
  negavel: 'negável',
  negilos: 'negilós',
  neglige: 'négligé',
  negocia: 'negocia',
  negocie: 'negocie',
  negocio: 'negócio',
  negocos: 'negocos',
  negombe: 'negombe',
  negraca: 'negraça',
  negraco: 'negraço',
  negrada: 'negrada',
  negrais: 'negrais',
  negreda: 'negreda',
  negreja: 'negreja',
  negreje: 'negreje',
  negrejo: 'negrejo',
  negrela: 'negrela',
  negrete: 'negrete',
  negrita: 'negrita',
  negrite: 'negrite',
  negrito: 'negrito',
  negroas: 'negroas',
  negroes: 'negrões',
  negrola: 'negrola',
  negrote: 'negrote',
  negrume: 'negrume',
  negrura: 'negrura',
  negruza: 'negruza',
  negucas: 'neguças',
  negueis: 'negueis',
  neguemo: 'neguemo',
  neguila: 'neguila',
  negumas: 'negumas',
  negumbo: 'negumbo',
  negundo: 'negundo',
  negungo: 'negungo',
  neineis: 'neineis',
  neismos: 'neísmos',
  nelavas: 'nelavãs',
  nelense: 'nelense',
  nelitas: 'nelitas',
  nelores: 'nelores',
  nelumbo: 'nelumbo',
  nemalia: 'nemália',
  nemalio: 'nemálio',
  nematos: 'nêmatos',
  nemausa: 'nemausa',
  nembrar: 'nembrar',
  nemeana: 'nemeana',
  nemeano: 'nemeano',
  nemedra: 'nêmedra',
  nemeeia: 'nemeeia',
  nemeeio: 'nemeeio',
  nemeeus: 'nemeeus',
  nemerte: 'nemerte',
  nemeses: 'nêmeses',
  nemesia: 'nemésia',
  nemesis: 'nêmesis',
  nemetas: 'nemetas',
  nemetes: 'nêmetes',
  nemiana: 'nemiana',
  nemiano: 'nemiano',
  nemobia: 'nemóbia',
  nemofis: 'nêmofis',
  nemonix: 'nêmonix',
  nemoral: 'nemoral',
  nemorea: 'nemórea',
  nemoreo: 'nemóreo',
  nemoria: 'nemória',
  nemosia: 'nemósia',
  nemples: 'nemplés',
  nemuras: 'nemuras',
  nenecai: 'nenecai',
  nenecam: 'nenecam',
  nenecar: 'nenecar',
  nenecas: 'nenecas',
  nenecou: 'nenecou',
  neneque: 'neneque',
  nengara: 'nêngara',
  nengaro: 'nêngaro',
  nengone: 'nengoné',
  nengoro: 'nengoro',
  nengras: 'nengras',
  nengros: 'nengros',
  nenhice: 'nenhice',
  nenhuma: 'nenhuma',
  nenhuns: 'nenhuns',
  nenufar: 'nenúfar',
  neoboro: 'neóboro',
  neocana: 'neocana',
  neocena: 'neocena',
  neoceno: 'neoceno',
  neocito: 'neócito',
  neocoro: 'neócoro',
  neocrex: 'néocrex',
  neodine: 'neodine',
  neodria: 'neódria',
  neoelia: 'neoélia',
  neofeto: 'neofeto',
  neofila: 'neófila',
  neofilo: 'neófilo',
  neofita: 'neófita',
  neofito: 'neófito',
  neofoba: 'neófoba',
  neofobo: 'neófobo',
  neoforo: 'neóforo',
  neofron: 'neófron',
  neogala: 'neógala',
  neogama: 'neógama',
  neogamo: 'neógamo',
  neogeia: 'neogeia',
  neogena: 'neógena',
  neogeno: 'neógeno',
  neogodo: 'neogodo',
  neogona: 'neógona',
  neogono: 'neógono',
  neolane: 'neolane',
  neolita: 'neolita',
  neolite: 'neolite',
  neolito: 'neólito',
  neologa: 'neóloga',
  neologo: 'neólogo',
  neolzao: 'neolzão',
  neonata: 'neonata',
  neonato: 'neonato',
  neonazi: 'neonazi',
  neonino: 'neonino',
  neonios: 'neônios',
  neopaga: 'neopagã',
  neorama: 'neorama',
  neorina: 'neorina',
  neotias: 'neótias',
  neotiea: 'neotíea',
  neotipo: 'neótipo',
  neotomo: 'neótomo',
  neotuno: 'neotuno',
  nepales: 'nepalês',
  nepalis: 'nepalis',
  nepanda: 'nepanda',
  nepenta: 'nepenta',
  nepente: 'nepente',
  nepepes: 'nepepes',
  nepetas: 'népetas',
  nepetea: 'nepétea',
  nepidas: 'népidas',
  nepidea: 'nepídea',
  nepideo: 'nepídeo',
  nepoide: 'nepoide',
  nepoita: 'nepoíta',
  nepotes: 'nepotes',
  nepsera: 'nepsera',
  neptuno: 'neptuno',
  nequeno: 'nequeno',
  nequice: 'nequice',
  nereias: 'nereias',
  nereida: 'nereida',
  nereide: 'nereide',
  neriina: 'neriina',
  nerinas: 'nerinas',
  nerinda: 'nerinda',
  nerinea: 'nerínea',
  neripas: 'neripas',
  neripos: 'neripos',
  neritas: 'neritas',
  nerofis: 'nérofis',
  nerolis: 'nérolis',
  neronia: 'nerônia',
  neronio: 'nerônio',
  nertera: 'nertera',
  nerusas: 'nerusas',
  nerusca: 'nerusca',
  nerusia: 'nerúsia',
  nerusio: 'nerúsio',
  nerusos: 'nerusos',
  nervada: 'nervada',
  nervado: 'nervado',
  nervais: 'nervais',
  nerveas: 'nérveas',
  nerveos: 'nérveos',
  nervias: 'nérvias',
  nervina: 'nervina',
  nervino: 'nervino',
  nervios: 'nérvios',
  nervois: 'nervóis',
  nervosa: 'nervosa',
  nervoso: 'nervoso',
  nervuda: 'nervuda',
  nervudo: 'nervudo',
  nervulo: 'nérvulo',
  nervura: 'nervura',
  nesanga: 'nesanga',
  nescias: 'néscias',
  nescios: 'néscios',
  neseias: 'neseias',
  nesgada: 'nesgada',
  nesgado: 'nesgado',
  nesgais: 'nesgais',
  nesgara: 'nesgará',
  nesgava: 'nesgava',
  nesgoes: 'nesgões',
  nesguei: 'nesguei',
  nesguem: 'nesguem',
  nesgues: 'nesgues',
  nesinga: 'nesinga',
  nesiota: 'nesiota',
  nesitas: 'nesitas',
  neslias: 'néslias',
  neslita: 'neslita',
  nesocia: 'nesócia',
  nespara: 'nêspara',
  nespera: 'nêspera',
  nespero: 'nêspero',
  nesquis: 'nésquis',
  nessora: 'nessora',
  nesteia: 'nesteia',
  nestias: 'nestias',
  nestica: 'néstica',
  nestico: 'néstico',
  nestino: 'nestino',
  nestora: 'nestora',
  netapos: 'netapos',
  netbook: 'netbook',
  netense: 'netense',
  neticas: 'néticas',
  neticos: 'néticos',
  netinha: 'netinha',
  netinho: 'netinho',
  netoide: 'netoide',
  netsuke: 'netsuke',
  netunal: 'netunal',
  netunia: 'netúnia',
  netunio: 'netúnio',
  network: 'network',
  neumada: 'neumada',
  neumado: 'neumado',
  neurada: 'nêurada',
  neurafe: 'nêurafe',
  neurais: 'neurais',
  neuraxe: 'neuraxe',
  neurias: 'nêurias',
  neurica: 'nêurica',
  neurico: 'nêurico',
  neurina: 'neurina',
  neurino: 'neurino',
  neurite: 'neurite',
  neurito: 'neurito',
  neuroma: 'neuroma',
  neuromo: 'neuromo',
  neurona: 'neurona',
  neurosa: 'neurosa',
  neurose: 'neurose',
  neuroso: 'neuroso',
  neurula: 'nêurula',
  neuston: 'nêuston',
  neustos: 'neustos',
  neutral: 'neutral',
  neutrao: 'neutrão',
  neutras: 'neutras',
  neutron: 'nêutron',
  neutros: 'neutros',
  neuvita: 'neuvita',
  nevacao: 'nevação',
  nevadao: 'nevadão',
  nevadas: 'nevadas',
  nevador: 'nevador',
  nevados: 'nevados',
  nevamos: 'nevamos',
  nevando: 'nevando',
  nevante: 'nevante',
  nevaram: 'nevaram',
  nevarao: 'nevarão',
  nevaras: 'nevaras',
  nevarei: 'nevarei',
  nevarem: 'nevarem',
  nevares: 'nevares',
  nevaria: 'nevaria',
  nevaris: 'nevaris',
  nevarmo: 'nevarmo',
  nevasca: 'nevasca',
  nevasse: 'nevasse',
  nevaste: 'nevaste',
  nevavam: 'nevavam',
  nevavas: 'nevavas',
  nevedas: 'nêvedas',
  neveira: 'neveira',
  neveiro: 'neveiro',
  nevemos: 'nevemos',
  nevense: 'nevense',
  nevisca: 'nevisca',
  nevisco: 'nevisco',
  nevoaca: 'nevoaça',
  nevoace: 'nevoace',
  nevoaco: 'nevoaço',
  nevoada: 'nevoada',
  nevoado: 'nevoado',
  nevoais: 'nevoais',
  nevoamo: 'nevoamo',
  nevoara: 'nevoará',
  nevoava: 'nevoava',
  nevoeis: 'nevoeis',
  nevoemo: 'nevoemo',
  nevosas: 'nevosas',
  nevosos: 'nevosos',
  nevrais: 'nevrais',
  nevraxe: 'nevraxe',
  nevrina: 'nevrina',
  nevrino: 'nevrino',
  nevrite: 'nevrite',
  nevroma: 'nevroma',
  nevromo: 'nevromo',
  nevrona: 'nevrona',
  nevrose: 'nevrose',
  nexibua: 'nexibua',
  nexinas: 'nexinas',
  nexuais: 'nexuais',
  nezaras: 'nezaras',
  nezinze: 'nezinze',
  ngandas: 'ngandas',
  ngangas: 'ngangas',
  ngavito: 'ngavito',
  nguembu: 'nguembu',
  nguluve: 'nguluve',
  nhacana: 'nhaçanã',
  nhacoco: 'nhacoco',
  nhafete: 'nháfete',
  nhambis: 'nhambis',
  nhambui: 'nhambuí',
  nhambus: 'nhambus',
  nhambuu: 'nhambuu',
  nhampas: 'nhampãs',
  nhamuis: 'nhamuís',
  nhandia: 'nhandiá',
  nhandis: 'nhandis',
  nhandus: 'nhandus',
  nhaneca: 'nhaneca',
  nhangos: 'nhangos',
  nhangue: 'nhangue',
  nhanhas: 'nhanhás',
  nhanica: 'nhanica',
  nhanida: 'nhanida',
  nhanido: 'nhanido',
  nhapins: 'nhapins',
  nhaques: 'nhaques',
  nhembas: 'nhembas',
  nhenhas: 'nhenhas',
  nhocana: 'nhocana',
  nhonhas: 'nhonhas',
  nhonhos: 'nhonhos',
  nhoques: 'nhoques',
  nhuacus: 'nhuaçus',
  nhumbos: 'nhumbos',
  nhumues: 'nhumues',
  nhundes: 'nhundes',
  nhundis: 'nhundis',
  nhundus: 'nhundus',
  nhungue: 'nhungue',
  nhurras: 'nhurras',
  nhurria: 'nhurria',
  nhurrio: 'nhurrio',
  nhurros: 'nhurros',
  niabele: 'niabele',
  niacina: 'niacina',
  nialzao: 'nialzão',
  nianeca: 'nianeca',
  nianjas: 'nianjas',
  niarana: 'niarana',
  nibalas: 'nibalas',
  niboras: 'niboras',
  nicaces: 'niçaces',
  nicadao: 'nicadão',
  nicadas: 'nicadas',
  nicador: 'nicador',
  nicados: 'nicados',
  nicamos: 'nicamos',
  nicanco: 'nicanço',
  nicando: 'nicando',
  nicanes: 'nicanés',
  nicante: 'nicante',
  nicaram: 'nicaram',
  nicarao: 'nicarão',
  nicaras: 'nicarás',
  nicarei: 'nicarei',
  nicarem: 'nicarem',
  nicares: 'nicares',
  nicaria: 'nicaria',
  nicarmo: 'nicarmo',
  nicasse: 'nicasse',
  nicaste: 'nicaste',
  nicates: 'nicates',
  nicavam: 'nicavam',
  nicavas: 'nicavas',
  nicazia: 'nicázia',
  nicenas: 'nicenas',
  nicenos: 'nicenos',
  nicense: 'nicense',
  nicesas: 'nicesas',
  niceses: 'niceses',
  nichada: 'nichada',
  nichado: 'nichado',
  nichais: 'nichais',
  nichamo: 'nichamo',
  nichara: 'nichará',
  nichava: 'nichava',
  nicheis: 'nicheis',
  nichemo: 'nichemo',
  nicobio: 'nicóbio',
  nicolau: 'nicolau',
  nicomol: 'nicomol',
  nicotol: 'nicotol',
  nicrada: 'nicrada',
  nicrado: 'nicrado',
  nicromo: 'nicromo',
  nictada: 'nictada',
  nictado: 'nictado',
  nictago: 'níctago',
  nictais: 'nictais',
  nictala: 'níctala',
  nictale: 'níctale',
  nictalo: 'níctalo',
  nictamo: 'nictamo',
  nictara: 'nictará',
  nictava: 'nictava',
  nicteis: 'nicteis',
  nictemo: 'nictemo',
  nictera: 'nictera',
  nicuris: 'nicuris',
  nidacao: 'nidação',
  nidadao: 'nidadão',
  nidadas: 'nidadas',
  nidador: 'nidador',
  nidados: 'nidados',
  nidamos: 'nidamos',
  nidando: 'nidando',
  nidante: 'nidante',
  nidaram: 'nidaram',
  nidarao: 'nidarão',
  nidaras: 'nidarás',
  nidarei: 'nidarei',
  nidarem: 'nidarem',
  nidares: 'nidares',
  nidaria: 'nidaria',
  nidarmo: 'nidarmo',
  nidasse: 'nidasse',
  nidaste: 'nidaste',
  nidavam: 'nidavam',
  nidavas: 'nidavas',
  nidemas: 'nidemas',
  nidemos: 'nidemos',
  nidoras: 'nidoras',
  nidores: 'nidores',
  nieiras: 'nieiras',
  nieiros: 'nieiros',
  nielins: 'nielins',
  nifargo: 'nifargo',
  nifelas: 'nifelas',
  nifonas: 'nifonas',
  nigalho: 'nigalho',
  nigalio: 'nigálio',
  nigelai: 'nigelai',
  nigelam: 'nigelam',
  nigelar: 'nigelar',
  nigelas: 'nigelas',
  nigelei: 'nigelei',
  nigelem: 'nigelem',
  nigeles: 'nigeles',
  nigelou: 'nigelou',
  niglaro: 'níglaro',
  nigrans: 'nigrans',
  nigreta: 'nigreta',
  nigrete: 'nigrete',
  nigrica: 'nígrica',
  nigrico: 'nígrico',
  nigrina: 'nigrina',
  nigrita: 'nigrita',
  nigrite: 'nigrite',
  niiamas: 'niiamas',
  nilense: 'nilense',
  nilgais: 'nilgais',
  niliaca: 'nilíaca',
  niliaco: 'nilíaco',
  niliada: 'niliada',
  niliado: 'niliado',
  niliais: 'niliais',
  niliamo: 'niliamo',
  niliara: 'niliará',
  niliava: 'niliava',
  nilicas: 'nílicas',
  nilicos: 'nílicos',
  nilieis: 'nilieis',
  niliemo: 'niliemo',
  nilotas: 'nilotas',
  nimbada: 'nimbada',
  nimbado: 'nimbado',
  nimbais: 'nimbais',
  nimbamo: 'nimbamo',
  nimbara: 'nimbara',
  nimbava: 'nimbava',
  nimbeis: 'nimbeis',
  nimbemo: 'nimbemo',
  nimbios: 'nímbios',
  nimbois: 'nimboís',
  nimbosa: 'nimbosa',
  nimboso: 'nimboso',
  nimbuia: 'nimbuia',
  nimitas: 'nimitas',
  ninadao: 'ninadão',
  ninadas: 'ninadas',
  ninador: 'ninador',
  ninados: 'ninados',
  ninaina: 'ninainá',
  ninaine: 'ninaine',
  ninaino: 'ninaino',
  ninamos: 'ninamos',
  ninando: 'ninando',
  ninante: 'ninante',
  ninaram: 'ninaram',
  ninarao: 'ninarão',
  ninaras: 'ninaras',
  ninarei: 'ninarei',
  ninarem: 'ninarem',
  ninares: 'ninares',
  ninaria: 'ninaria',
  ninarmo: 'ninarmo',
  ninasse: 'ninasse',
  ninaste: 'ninaste',
  ninavam: 'ninavam',
  ninavas: 'ninavas',
  ninemos: 'ninemos',
  ninfais: 'ninfais',
  ninfala: 'ninfala',
  ninfale: 'ninfale',
  ninfeia: 'ninfeia',
  ninfeta: 'ninfeta',
  ninfete: 'ninfete',
  ninfeus: 'ninfeus',
  ninfica: 'nínfica',
  ninfico: 'nínfico',
  ninfios: 'ninfios',
  ninfite: 'ninfite',
  ninfose: 'ninfose',
  ninfula: 'nínfula',
  ningres: 'ningres',
  ninguem: 'ninguém',
  ninhada: 'ninhada',
  ninhado: 'ninhado',
  ninhais: 'ninhais',
  ninhamo: 'ninhamo',
  ninhara: 'ninhara',
  ninhava: 'ninhava',
  ninhego: 'ninhego',
  ninheis: 'ninheis',
  ninhemo: 'ninhemo',
  niniada: 'niníada',
  ninocha: 'ninocha',
  ninquim: 'ninquim',
  ninsias: 'nínsias',
  niobato: 'niobato',
  niobica: 'nióbica',
  niobico: 'nióbico',
  niobida: 'nióbida',
  niobios: 'nióbios',
  niobita: 'niobita',
  niobite: 'niobite',
  nipacea: 'nipácea',
  nipaceo: 'nipáceo',
  niparas: 'níparas',
  niparos: 'níparos',
  nipeira: 'nipeira',
  nipenes: 'nipenes',
  nipevas: 'nipevas',
  niqueis: 'niqueis',
  niquela: 'niquela',
  niquele: 'niquele',
  niquelo: 'niquelo',
  niquemo: 'niquemo',
  niquice: 'niquice',
  niquila: 'niquilá',
  niquile: 'niquile',
  niquilo: 'niquilo',
  niquins: 'niquins',
  niquira: 'niquira',
  niquito: 'niquito',
  nirocas: 'nirocas',
  nirvana: 'nirvana',
  nirvane: 'nirvane',
  nirvano: 'nirvano',
  nisaeto: 'nisáeto',
  nisbita: 'nisbita',
  niscaro: 'níscaro',
  niscato: 'niscato',
  niscoes: 'niscões',
  niscona: 'niscona',
  niscros: 'niscros',
  niseias: 'niseias',
  nisense: 'nisense',
  nisinas: 'nisinas',
  nisitas: 'nisitas',
  nisorra: 'nisorra',
  nisorro: 'nisorro',
  nispero: 'níspero',
  nisseis: 'nisseis',
  nisueta: 'nisueta',
  nitelas: 'nitelas',
  nitelia: 'nitélia',
  nitente: 'nitente',
  nitesca: 'nitesça',
  nitesce: 'nitescê',
  nitesci: 'nitesci',
  nitesco: 'nitesço',
  nitidas: 'nítidas',
  nitidez: 'nitidez',
  nitidos: 'nítidos',
  nitinga: 'nitinga',
  nitinol: 'nitinol',
  nitonio: 'nitônio',
  nitonte: 'nitonte',
  nitrada: 'nitrada',
  nitrado: 'nitrado',
  nitrais: 'nitrais',
  nitramo: 'nitramo',
  nitrara: 'nitrará',
  nitrata: 'nitratá',
  nitrate: 'nitrate',
  nitrato: 'nitrato',
  nitrava: 'nitrava',
  nitreis: 'nitreis',
  nitremo: 'nitremo',
  nitreta: 'nitreta',
  nitrete: 'nitrete',
  nitreto: 'nitreto',
  nitriam: 'nitriam',
  nitrias: 'nitrias',
  nitrica: 'nítrica',
  nitrico: 'nítrico',
  nitrida: 'nitrida',
  nitrido: 'nitrido',
  nitrila: 'nitrila',
  nitrilo: 'nitrilo',
  nitrimo: 'nitrimo',
  nitrina: 'nitrina',
  nitrira: 'nitrira',
  nitrito: 'nitrito',
  nitrois: 'nitróis',
  nitrona: 'nitrona',
  nitrons: 'nítrons',
  nitrosa: 'nitrosa',
  nitrose: 'nitrose',
  nitroso: 'nitroso',
  nitrura: 'nitrura',
  nitrure: 'nitrure',
  nitruro: 'nitruro',
  niuambe: 'niuambê',
  niumbos: 'niumbós',
  niungue: 'niúngue',
  nivacao: 'nivação',
  nivator: 'nivator',
  niveais: 'niveais',
  niveina: 'niveína',
  nivelai: 'nivelai',
  nivelam: 'nivelam',
  nivelar: 'nivelar',
  nivelas: 'nivelas',
  nivelei: 'nivelei',
  nivelem: 'nivelem',
  niveles: 'niveles',
  nivelou: 'nivelou',
  nivenia: 'nivênia',
  niveola: 'nivéola',
  nivosas: 'nivosas',
  nivosos: 'nivosos',
  nizaros: 'nízaros',
  nizense: 'nizense',
  nizeras: 'nízeras',
  njangos: 'njangos',
  nobelio: 'nobélio',
  nobreak: 'nobreak',
  nobreca: 'nobreça',
  nobrece: 'nobrece',
  nobreci: 'nobreci',
  nobreco: 'nobreço',
  nobreza: 'nobreza',
  nobunda: 'nobunda',
  nocadao: 'nocadão',
  nocadas: 'nocadas',
  nocador: 'nocador',
  nocados: 'nocados',
  nocamos: 'nocamos',
  nocando: 'nocando',
  nocante: 'nocante',
  nocaram: 'nocaram',
  nocarao: 'nocarão',
  nocaras: 'nocarás',
  nocarei: 'nocarei',
  nocarem: 'nocarem',
  nocares: 'nocares',
  nocaria: 'nocaria',
  nocarmo: 'nocarmo',
  nocasse: 'nocasse',
  nocaste: 'nocaste',
  nocaute: 'nocaute',
  nocavam: 'nocavam',
  nocavas: 'nocavas',
  nocebos: 'nocebos',
  nocente: 'nocente',
  nocilho: 'nocilho',
  nocivas: 'nocivas',
  nocivos: 'nocivos',
  noconas: 'noçonas',
  noctuas: 'nóctuas',
  noctula: 'nóctula',
  nocuini: 'nocuini',
  nodacao: 'nodação',
  nodadao: 'nodadão',
  nodadas: 'nodadas',
  nodador: 'nodador',
  nodados: 'nodados',
  nodamos: 'nodamos',
  nodando: 'nodando',
  nodante: 'nodante',
  nodaram: 'nodaram',
  nodarao: 'nodarão',
  nodaras: 'nodarás',
  nodarei: 'nodarei',
  nodarem: 'nodarem',
  nodares: 'nodares',
  nodaria: 'nodaria',
  nodarmo: 'nodarmo',
  nodasse: 'nodasse',
  nodaste: 'nodaste',
  nodavam: 'nodavam',
  nodavas: 'nodavas',
  nodemos: 'nodemos',
  nodical: 'nodical',
  nodicas: 'nódicas',
  nodicos: 'nódicos',
  nodoada: 'nodoada',
  nodoado: 'nodoado',
  nodoais: 'nodoais',
  nodoamo: 'nodoamo',
  nodoara: 'nodoara',
  nodoava: 'nodoava',
  nodoeis: 'nodoeis',
  nodoemo: 'nodoemo',
  nodoide: 'nodoide',
  nodosas: 'nodosas',
  nodosos: 'nodosos',
  nodular: 'nodular',
  nodulas: 'nodulás',
  nodulos: 'nódulos',
  nodulou: 'nodulou',
  noelzao: 'noelzão',
  noemias: 'noêmias',
  noemica: 'noêmica',
  noemico: 'noêmico',
  noergia: 'noergia',
  noetana: 'noetana',
  noetano: 'noetano',
  noetica: 'noética',
  noetico: 'noético',
  nogadas: 'nogadas',
  nogados: 'nogados',
  nogaica: 'nogaica',
  nogaico: 'nogaico',
  noguito: 'noguito',
  noiante: 'noiante',
  noitada: 'noitada',
  noiteca: 'noiteça',
  noitece: 'noitece',
  noiteci: 'noiteci',
  noiteco: 'noiteço',
  noitibo: 'noitibó',
  noitoes: 'noitões',
  noitois: 'noitóis',
  noivada: 'noivada',
  noivado: 'noivado',
  noivais: 'noivais',
  noivamo: 'noivamo',
  noivara: 'noivara',
  noivava: 'noivava',
  noiveis: 'noiveis',
  noivemo: 'noivemo',
  nojadao: 'nojadão',
  nojadas: 'nojadas',
  nojador: 'nojador',
  nojados: 'nojados',
  nojamos: 'nojamos',
  nojando: 'nojando',
  nojante: 'nojante',
  nojaram: 'nojaram',
  nojarao: 'nojarão',
  nojaras: 'nojaras',
  nojarei: 'nojarei',
  nojarem: 'nojarem',
  nojares: 'nojares',
  nojaria: 'nojaria',
  nojarmo: 'nojarmo',
  nojasse: 'nojasse',
  nojaste: 'nojaste',
  nojavam: 'nojavam',
  nojavas: 'nojavas',
  nojeira: 'nojeira',
  nojemos: 'nojemos',
  nojenta: 'nojenta',
  nojento: 'nojento',
  nojices: 'nojices',
  nojosas: 'nojosas',
  nojosos: 'nojosos',
  nolanas: 'nolanas',
  nolanea: 'nolânea',
  nolanos: 'nolanos',
  nolelas: 'nolelas',
  nolense: 'nolense',
  nolente: 'nolente',
  noletia: 'nolétia',
  nolicao: 'nolição',
  nolidea: 'nolídea',
  nolideo: 'nolídeo',
  nolinas: 'nolinas',
  nolinea: 'nolínea',
  nolinio: 'nolínio',
  nolitas: 'nolitas',
  nomadas: 'nômadas',
  nomades: 'nômades',
  nomante: 'nomante',
  nomarca: 'nomarca',
  nomasas: 'nomasas',
  nomeada: 'nomeada',
  nomeado: 'nomeado',
  nomeais: 'nomeais',
  nomeamo: 'nomeamo',
  nomeara: 'nomeara',
  nomeava: 'nomeava',
  nomeeis: 'nomeeis',
  nomeemo: 'nomeemo',
  nomeiam: 'nomeiam',
  nomeias: 'nomeias',
  nomeiem: 'nomeiem',
  nomeies: 'nomeies',
  nomenos: 'nômenos',
  nomicas: 'nômicas',
  nomicos: 'nômicos',
  nominai: 'nominai',
  nominal: 'nominal',
  nominam: 'nominam',
  nominar: 'nominar',
  nominas: 'nominas',
  nominei: 'nominei',
  nominem: 'nominem',
  nomines: 'nomines',
  nominho: 'nominho',
  nominou: 'nominou',
  nomisma: 'nomisma',
  nomonix: 'nômonix',
  nomoxim: 'nomoxim',
  nonadas: 'nonadas',
  nonanal: 'nonanal',
  nonanas: 'nonanas',
  nonanol: 'nonanol',
  nonanos: 'nonanos',
  nonaria: 'nonária',
  nonario: 'nonário',
  nonatas: 'nonatas',
  nonatos: 'nonatos',
  nonavos: 'nonavos',
  nonenas: 'nonenas',
  nonense: 'nonense',
  nonetos: 'nonetos',
  nonilio: 'nonílio',
  noninas: 'noninas',
  noninha: 'noninha',
  nonodos: 'nonodos',
  nonoica: 'nonoica',
  nonoico: 'nonoico',
  nonoses: 'nonoses',
  nonulas: 'nonulas',
  nonuplo: 'nônuplo',
  nonusse: 'nonusse',
  noogena: 'noógena',
  noogeno: 'noógeno',
  noologa: 'noóloga',
  noologo: 'noólogo',
  nopalea: 'nopálea',
  nopaleo: 'nopáleo',
  noqueis: 'noqueis',
  noquemo: 'noquemo',
  noqueta: 'noqueta',
  noqueto: 'noqueto',
  norbana: 'norbana',
  norbano: 'norbano',
  nordica: 'nórdica',
  nordico: 'nórdico',
  nordita: 'nordita',
  norense: 'norense',
  norepol: 'norepol',
  noriana: 'noriana',
  noriano: 'noriano',
  noricas: 'nóricas',
  noricia: 'norícia',
  noricio: 'norício',
  noricos: 'nóricos',
  norinas: 'norinas',
  norinha: 'norinha',
  noritas: 'noritas',
  norites: 'norites',
  noritos: 'noritos',
  normada: 'normada',
  normado: 'normado',
  normais: 'normais',
  normana: 'normana',
  normano: 'normano',
  noropes: 'nóropes',
  norrena: 'norrena',
  norreno: 'norreno',
  nortada: 'nortada',
  norteai: 'norteai',
  nortear: 'nortear',
  norteei: 'norteei',
  norteia: 'norteia',
  norteie: 'norteie',
  norteio: 'norteio',
  norteou: 'norteou',
  nortias: 'nortias',
  nortoes: 'nortões',
  noruego: 'noruego',
  nosaria: 'nosaria',
  noscado: 'noscado',
  noseana: 'noseana',
  noseita: 'noseíta',
  noselha: 'noselha',
  nosemas: 'nosemas',
  nosemia: 'nosemia',
  nositas: 'nositas',
  nosites: 'nosites',
  nosodio: 'nosódio',
  nostras: 'nostras',
  notacao: 'notação',
  notadao: 'notadão',
  notadas: 'notadas',
  notador: 'notador',
  notados: 'notados',
  notafos: 'nótafos',
  notamia: 'notâmia',
  notamos: 'notamos',
  notando: 'notando',
  notante: 'notante',
  notaram: 'notaram',
  notarao: 'notarão',
  notaras: 'notaras',
  notarco: 'notarco',
  notarei: 'notarei',
  notarem: 'notarem',
  notares: 'notares',
  notaria: 'notaria',
  notario: 'notário',
  notarmo: 'notarmo',
  notasse: 'notasse',
  notaste: 'notaste',
  notavam: 'notavam',
  notavas: 'notavas',
  notavel: 'notável',
  noteira: 'noteira',
  noteiro: 'noteiro',
  notelea: 'notélea',
  notemos: 'notemos',
  noteras: 'nóteras',
  noteros: 'nóteros',
  noticia: 'noticia',
  noticie: 'noticie',
  noticio: 'noticio',
  notilia: 'notília',
  notinha: 'notinha',
  notiode: 'notiode',
  notista: 'notista',
  notitas: 'notitas',
  notites: 'notites',
  notolca: 'notolca',
  notomia: 'notomia',
  notonis: 'notônis',
  notoria: 'notória',
  notorio: 'notório',
  notoxos: 'notoxos',
  notozos: 'notozos',
  notulas: 'nótulas',
  noturas: 'noturas',
  noturna: 'noturna',
  noturno: 'noturno',
  noturos: 'noturos',
  noumeno: 'noúmeno',
  noutada: 'noutada',
  nouteca: 'nouteça',
  noutece: 'noutece',
  nouteci: 'nouteci',
  nouteco: 'nouteço',
  noutibo: 'noutibó',
  noutoes: 'noutões',
  noutras: 'noutras',
  noutrem: 'noutrem',
  noutros: 'noutros',
  novacao: 'novação',
  novacap: 'novacap',
  novadao: 'novadão',
  novadas: 'novadas',
  novadia: 'novadia',
  novadio: 'novadio',
  novador: 'novador',
  novados: 'novados',
  novamos: 'novamos',
  novando: 'novando',
  novante: 'novante',
  novaram: 'novaram',
  novarao: 'novarão',
  novaras: 'novaras',
  novarei: 'novarei',
  novarem: 'novarem',
  novares: 'novares',
  novaria: 'novaria',
  novarmo: 'novarmo',
  novasse: 'novasse',
  novaste: 'novaste',
  novatas: 'novatas',
  novatos: 'novatos',
  novavam: 'novavam',
  novavas: 'novavas',
  noveada: 'noveada',
  noveado: 'noveado',
  novedia: 'novedia',
  novedio: 'novedio',
  novelai: 'novelai',
  novelam: 'novelam',
  novelao: 'novelão',
  novelar: 'novelar',
  novelas: 'novelas',
  novelei: 'novelei',
  novelem: 'novelem',
  noveles: 'noveles',
  novelos: 'novelos',
  novelou: 'novelou',
  novemos: 'novemos',
  novenal: 'novenal',
  novenas: 'novenas',
  novenca: 'novença',
  novenio: 'novênio',
  novenos: 'novenos',
  novense: 'novense',
  noventa: 'noventa',
  noviais: 'noviais',
  novicas: 'noviças',
  novices: 'novices',
  novicia: 'novicia',
  novicie: 'novicie',
  novicio: 'novicio',
  novicos: 'noviços',
  novidao: 'novidão',
  novidea: 'novídea',
  novideo: 'novídeo',
  novilha: 'novilha',
  novilho: 'novilho',
  novinha: 'novinha',
  novinho: 'novinho',
  novisco: 'novisco',
  novista: 'novista',
  novitas: 'novitas',
  noxiura: 'noxiúra',
  nozadas: 'nozadas',
  nozados: 'nozados',
  nozaris: 'nozaris',
  nozedos: 'nozedos',
  nozeira: 'nozeira',
  nozelha: 'nozelha',
  nozilha: 'nozilha',
  nozinho: 'nozinho',
  nuancai: 'nuançai',
  nuancam: 'nuançam',
  nuancar: 'nuançar',
  nuancas: 'nuanças',
  nuancei: 'nuancei',
  nuancem: 'nuancem',
  nuances: 'nuances',
  nuancou: 'nuançou',
  nubeias: 'nubeias',
  nubelai: 'nubelai',
  nubelam: 'nubelam',
  nubelar: 'nubelar',
  nubelas: 'nubelas',
  nubelei: 'nubelei',
  nubelem: 'nubelem',
  nubeles: 'nubeles',
  nubelou: 'nubelou',
  nubente: 'nubente',
  nubiana: 'nubiana',
  nubiano: 'nubiano',
  nubilar: 'nubilar',
  nubilos: 'núbilos',
  nublada: 'nublada',
  nublado: 'nublado',
  nublais: 'nublais',
  nublamo: 'nublamo',
  nublara: 'nublara',
  nublava: 'nublava',
  nubleis: 'nubleis',
  nublemo: 'nublemo',
  nublina: 'nublina',
  nublosa: 'nublosa',
  nubloso: 'nubloso',
  nubrina: 'nubrina',
  nucegas: 'núcegas',
  nucegos: 'núcegos',
  nucelar: 'nucelar',
  nucelas: 'nucelas',
  nucelos: 'nucelos',
  nucibes: 'nucibes',
  nucinas: 'nucinas',
  nucleal: 'nucleal',
  nucleao: 'nucleão',
  nuclear: 'nuclear',
  nucleon: 'núcleon',
  nucleos: 'núcleos',
  nucodes: 'nucodes',
  nuconas: 'nuçonas',
  nucular: 'nucular',
  nuculas: 'núculas',
  nudacao: 'nudação',
  nudaria: 'nudária',
  nudezas: 'nudezas',
  nudezes: 'nudezes',
  nudinha: 'nudinha',
  nudinho: 'nudinho',
  nudismo: 'nudismo',
  nudista: 'nudista',
  nuelzao: 'nuelzão',
  nuevita: 'nuevita',
  nufarea: 'nufárea',
  nufares: 'núfares',
  nugacao: 'nugação',
  nugaria: 'nugaria',
  nuidade: 'nuidade',
  nuinhas: 'nuinhas',
  nuinhos: 'nuinhos',
  nuitoes: 'nuitões',
  nuitone: 'nuitone',
  nularia: 'nulária',
  nulario: 'nulário',
  numaria: 'numária',
  numario: 'numário',
  numbela: 'numbela',
  numeita: 'numeíta',
  numeite: 'numeíte',
  numenal: 'numenal',
  numenio: 'numênio',
  numenos: 'númenos',
  numerai: 'numerai',
  numeral: 'numeral',
  numeram: 'numeram',
  numerar: 'numerar',
  numeras: 'numeras',
  numerei: 'numerei',
  numerem: 'numerem',
  numeres: 'numeres',
  numeria: 'numéria',
  numeros: 'números',
  numerou: 'numerou',
  numidas: 'númidas',
  numidia: 'numídia',
  numisma: 'numisma',
  numular: 'numular',
  nunacao: 'nunação',
  nunciai: 'nunciai',
  nunciam: 'nunciam',
  nunciar: 'nunciar',
  nuncias: 'núncias',
  nunciei: 'nunciei',
  nunciem: 'nunciem',
  nuncies: 'nuncies',
  nuncios: 'núncios',
  nunciou: 'nunciou',
  nundina: 'núndina',
  nunismo: 'nunismo',
  nunista: 'nunista',
  nunumas: 'nunumas',
  nupciai: 'nupciai',
  nupcial: 'nupcial',
  nupciam: 'nupciam',
  nupciar: 'nupciar',
  nupcias: 'núpcias',
  nupciei: 'nupciei',
  nupciem: 'nupciem',
  nupcies: 'nupcies',
  nupciou: 'nupciou',
  nupebas: 'nupebas',
  nuperas: 'núperas',
  nuperos: 'núperos',
  nuqueai: 'nuqueai',
  nuquear: 'nuquear',
  nuqueei: 'nuqueei',
  nuqueia: 'nuqueia',
  nuqueie: 'nuqueie',
  nuqueio: 'nuqueio',
  nuqueou: 'nuqueou',
  nuquini: 'nuquini',
  nurague: 'nurague',
  nursery: 'nursery',
  nursina: 'nursina',
  nursino: 'nursino',
  nurumas: 'nurumas',
  nuruzes: 'nuruzes',
  nutacao: 'nutação',
  nutadao: 'nutadão',
  nutadas: 'nutadas',
  nutador: 'nutador',
  nutados: 'nutados',
  nutalia: 'nutália',
  nutamos: 'nutamos',
  nutando: 'nutando',
  nutante: 'nutante',
  nutaram: 'nutaram',
  nutarao: 'nutarão',
  nutaras: 'nutaras',
  nutarei: 'nutarei',
  nutarem: 'nutarem',
  nutares: 'nutares',
  nutaria: 'nutaria',
  nutarmo: 'nutarmo',
  nutasse: 'nutasse',
  nutaste: 'nutaste',
  nutavam: 'nutavam',
  nutavas: 'nutavas',
  nutemos: 'nutemos',
  nutrais: 'nutrais',
  nutramo: 'nutramo',
  nutremo: 'nutremo',
  nutriam: 'nutriam',
  nutrias: 'nutrias',
  nutrice: 'nutrice',
  nutrida: 'nutrida',
  nutrido: 'nutrido',
  nutrimo: 'nutrimo',
  nutrira: 'nutrira',
  nuveada: 'nuveada',
  nuveado: 'nuveado',
  nuveais: 'nuveais',
  nuveara: 'nuveará',
  nuveava: 'nuveava',
  nuveeis: 'nuveeis',
  nuveiam: 'nuveiam',
  nuveias: 'nuveias',
  nuveiem: 'nuveiem',
  nuveies: 'nuveies',
  nuviosa: 'nuviosa',
  nuvioso: 'nuvioso',
  nuvrada: 'nuvrada',
  nuvrado: 'nuvrado',
  nynorsk: 'nynorsk',
  oacacus: 'oaçacus',
  oacajus: 'oacajus',
  oacauas: 'oacauãs',
  oaianas: 'oaianas',
  oajerus: 'oajerus',
  oajurus: 'oajurus',
  oanacus: 'oanaçus',
  oanandi: 'oanandi',
  oanassu: 'oanassu',
  oanucus: 'oanuçus',
  oapinas: 'oapinas',
  oariana: 'oariana',
  oaricas: 'oáricas',
  oaricos: 'oáricos',
  oaristo: 'oaristo',
  oariula: 'oariúla',
  oasiana: 'oasiana',
  oasiano: 'oasiano',
  oasicas: 'oásicas',
  oasicos: 'oásicos',
  oasinos: 'oasinos',
  oasitas: 'oasitas',
  obaluae: 'obaluaê',
  obambas: 'obambas',
  obanije: 'obanijé',
  obarana: 'obarana',
  obcecai: 'obcecai',
  obcecam: 'obcecam',
  obcecar: 'obcecar',
  obcecas: 'obcecas',
  obcecou: 'obcecou',
  obceque: 'obceque',
  obducao: 'obdução',
  obducto: 'obducto',
  obdurai: 'obdurai',
  obduram: 'obduram',
  obdurar: 'obdurar',
  obduras: 'obduras',
  obdurei: 'obdurei',
  obdurem: 'obdurem',
  obdures: 'obdures',
  obdurou: 'obdurou',
  obecias: 'obécias',
  obedeca: 'obedeça',
  obedece: 'obedece',
  obedeci: 'obedeci',
  obedeco: 'obedeço',
  obelias: 'obélias',
  obelion: 'obélion',
  obelios: 'obélios',
  oberada: 'oberada',
  oberado: 'oberado',
  oberais: 'oberais',
  oberamo: 'oberamo',
  oberara: 'oberará',
  oberava: 'oberava',
  obereas: 'obéreas',
  obereis: 'obereis',
  oberemo: 'oberemo',
  obfirma: 'obfirma',
  obfirme: 'obfirme',
  obfirmo: 'obfirmo',
  obiones: 'obiones',
  obipons: 'obipons',
  obirins: 'obirins',
  obisios: 'obísios',
  objecao: 'objeção',
  objecta: 'objecta',
  objecte: 'objecte',
  objecto: 'objecto',
  objeito: 'objeito',
  objetai: 'objetai',
  objetal: 'objetal',
  objetam: 'objetam',
  objetar: 'objetar',
  objetas: 'objetas',
  objetei: 'objetei',
  objetem: 'objetem',
  objetes: 'objetes',
  objetor: 'objetor',
  objetos: 'objetos',
  objetou: 'objetou',
  objurga: 'objurga',
  objurgo: 'objurgo',
  oblacao: 'oblação',
  obladas: 'obladas',
  oblatai: 'oblatai',
  oblatam: 'oblatam',
  oblatar: 'oblatar',
  oblatas: 'oblatas',
  oblatei: 'oblatei',
  oblatem: 'oblatem',
  oblates: 'oblates',
  oblatou: 'oblatou',
  obligar: 'obligar',
  obliqua: 'oblíqua',
  oblique: 'oblique',
  obliquo: 'oblíquo',
  oblivio: 'oblívio',
  oblonga: 'oblonga',
  oblongo: 'oblongo',
  obnoxio: 'obnóxio',
  oboazes: 'oboazes',
  oboista: 'oboísta',
  obolida: 'obólida',
  obongas: 'obongas',
  obongos: 'obongos',
  oborita: 'oborita',
  obovada: 'obovada',
  obovado: 'obovado',
  obovais: 'obovais',
  oboveas: 'obóveas',
  oboveos: 'obóveos',
  obracao: 'obração',
  obracom: 'obraçom',
  obradai: 'obradai',
  obradam: 'obradam',
  obradao: 'obradão',
  obradar: 'obradar',
  obradas: 'obradas',
  obradei: 'obradei',
  obradem: 'obradem',
  obrades: 'obrades',
  obrador: 'obrador',
  obrados: 'obrados',
  obradou: 'obradou',
  obragem: 'obragem',
  obrages: 'obrages',
  obramos: 'obramos',
  obrando: 'obrando',
  obrante: 'obrante',
  obraram: 'obraram',
  obrarao: 'obrarão',
  obraras: 'obrarás',
  obrarei: 'obrarei',
  obrarem: 'obrarem',
  obrares: 'obrares',
  obraria: 'obraria',
  obrarmo: 'obrarmo',
  obrasse: 'obrasse',
  obraste: 'obraste',
  obravam: 'obravam',
  obravas: 'obravas',
  obravel: 'obrável',
  obregao: 'obregão',
  obreias: 'obreias',
  obreira: 'obreira',
  obreiro: 'obreiro',
  obrejai: 'obrejai',
  obrejam: 'obrejam',
  obrejar: 'obrejar',
  obrejas: 'obrejas',
  obrejei: 'obrejei',
  obrejem: 'obrejem',
  obrejes: 'obrejes',
  obrejou: 'obrejou',
  obremos: 'obremos',
  obrigai: 'obrigai',
  obrigam: 'obrigam',
  obrigar: 'obrigar',
  obrigas: 'obrigas',
  obrigou: 'obrigou',
  obrigue: 'obrigue',
  obrimas: 'obrimas',
  obrinha: 'obrinha',
  obrista: 'obrista',
  obscena: 'obscena',
  obsceno: 'obsceno',
  obscura: 'obscura',
  obscuro: 'obscuro',
  obsecra: 'obsecra',
  obsecre: 'obsecre',
  obsecro: 'obsecro',
  obsedai: 'obsedai',
  obsedam: 'obsedam',
  obsedar: 'obsedar',
  obsedas: 'obsedas',
  obsedei: 'obsedei',
  obsedem: 'obsedem',
  obsedes: 'obsedes',
  obsedia: 'obsedia',
  obsedie: 'obsedie',
  obsedio: 'obsedio',
  obsedou: 'obsedou',
  observa: 'observa',
  observe: 'observe',
  observo: 'observo',
  obsessa: 'obsessa',
  obsesso: 'obsesso',
  obsidia: 'obsidia',
  obsidie: 'obsidie',
  obsidio: 'obsidio',
  obstada: 'obstada',
  obstado: 'obstado',
  obstais: 'obstais',
  obstamo: 'obstamo',
  obstara: 'obstara',
  obstava: 'obstava',
  obsteis: 'obsteis',
  obstemo: 'obstemo',
  obstina: 'obstina',
  obstine: 'obstine',
  obstino: 'obstino',
  obstipa: 'obstipa',
  obstipe: 'obstipe',
  obstipo: 'obstipo',
  obstrua: 'obstrua',
  obstrue: 'obstrue',
  obstrui: 'obstrui',
  obstruo: 'obstruo',
  obtecto: 'obtecto',
  obtedor: 'obtedor',
  obtemos: 'obtemos',
  obtende: 'obtende',
  obtendo: 'obtendo',
  obtenha: 'obtenha',
  obtenho: 'obtenho',
  obterao: 'obterão',
  obteras: 'obterás',
  obterei: 'obterei',
  obterem: 'obterem',
  obteres: 'obteres',
  obteria: 'obteria',
  obtesta: 'obtesta',
  obteste: 'obteste',
  obtesto: 'obtesto',
  obtidao: 'obtidão',
  obtidas: 'obtidas',
  obtidos: 'obtidos',
  obtinha: 'obtinha',
  obtiver: 'obtiver',
  obtunda: 'obtunda',
  obtunde: 'obtunde',
  obtundi: 'obtundi',
  obtundo: 'obtundo',
  obturai: 'obturai',
  obturam: 'obturam',
  obturar: 'obturar',
  obturas: 'obturas',
  obturei: 'obturei',
  obturem: 'obturem',
  obtures: 'obtures',
  obturou: 'obturou',
  obtusao: 'obtusão',
  obtusas: 'obtusas',
  obtusos: 'obtusos',
  obumbra: 'obumbra',
  obumbre: 'obumbre',
  obumbro: 'obumbro',
  obusite: 'obusite',
  obvenha: 'obvenha',
  obvenho: 'obvenho',
  obversa: 'obversa',
  obverso: 'obverso',
  obviada: 'obviada',
  obviado: 'obviado',
  obviais: 'obviais',
  obviamo: 'obviamo',
  obviara: 'obviara',
  obviava: 'obviava',
  obvidao: 'obvidão',
  obvidor: 'obvidor',
  obvieis: 'obvieis',
  obviemo: 'obviemo',
  obviera: 'obviera',
  obvimos: 'obvimos',
  obvinde: 'obvinde',
  obvindo: 'obvindo',
  obvinha: 'obvinha',
  obvirao: 'obvirão',
  obviras: 'obvirás',
  obvirei: 'obvirei',
  obvirem: 'obvirem',
  obvires: 'obvires',
  obviria: 'obviria',
  ocadoes: 'ocadões',
  ocadona: 'ocadona',
  ocadora: 'ocadora',
  ocaleia: 'ocaleia',
  ocalias: 'ocálias',
  ocalios: 'ocálios',
  ocandas: 'ocandas',
  ocanhim: 'oçânhim',
  ocanhos: 'ocanhos',
  ocantes: 'ocantes',
  ocapias: 'ocápias',
  ocardes: 'ocardes',
  ocareis: 'ocáreis',
  ocaremo: 'ocaremo',
  ocariam: 'ocariam',
  ocarias: 'ocarias',
  ocarina: 'ocarina',
  ocarmos: 'ocarmos',
  ocarucu: 'ocaruçu',
  ocasiao: 'ocasião',
  ocassem: 'ocassem',
  ocasses: 'ocasses',
  ocastes: 'ocastes',
  ocaveis: 'ocáveis',
  occidio: 'occídio',
  occidua: 'occídua',
  occiduo: 'occíduo',
  occiput: 'occiput',
  occisao: 'occisão',
  oceanea: 'oceânea',
  oceaneo: 'oceâneo',
  oceanos: 'oceanos',
  ocelada: 'ocelada',
  ocelado: 'ocelado',
  oceleas: 'océleas',
  oceleos: 'océleos',
  ocelote: 'ocelote',
  ociales: 'ocíales',
  ocialos: 'ocíalos',
  ocidios: 'ocídios',
  ociduas: 'ocíduas',
  ociduos: 'ocíduos',
  ocifabe: 'ocífabe',
  ocimeas: 'ocímeas',
  ocimeno: 'ocimeno',
  ocinhes: 'ocinhés',
  ociosas: 'ociosas',
  ociosos: 'ociosos',
  ocipoda: 'ocípoda',
  ocipode: 'ocípode',
  ociroes: 'ocíroes',
  ocisiva: 'ocisiva',
  ocisivo: 'ocisivo',
  ocisoes: 'ocisões',
  ocisona: 'ocisona',
  ocitana: 'ocitana',
  ocitano: 'ocitano',
  ocitoes: 'ocítoes',
  ocladio: 'ocládio',
  ocleses: 'ocleses',
  ocluais: 'ocluais',
  ocluiam: 'ocluíam',
  ocluias: 'ocluías',
  ocluida: 'ocluída',
  ocluido: 'ocluído',
  ocluira: 'ocluirá',
  oclusal: 'oclusal',
  oclusao: 'oclusão',
  oclusas: 'oclusas',
  oclusor: 'oclusor',
  oclusos: 'oclusos',
  ocnacea: 'ocnácea',
  ocnaceo: 'ocnáceo',
  ocneria: 'ocnéria',
  ocoembo: 'ocoembo',
  ocofita: 'ocófita',
  oconite: 'oconite',
  ocorram: 'ocorram',
  ocorras: 'ocorras',
  ocorrei: 'ocorrei',
  ocorrem: 'ocorrem',
  ocorrer: 'ocorrer',
  ocorres: 'ocorres',
  ocorreu: 'ocorreu',
  ocorria: 'ocorria',
  ocoteas: 'ocóteas',
  ocoteia: 'ocoteia',
  ocotona: 'ocotona',
  ocozoal: 'ocozoal',
  ocozois: 'ocozóis',
  ocracea: 'ocrácea',
  ocraceo: 'ocráceo',
  ocralea: 'ocrálea',
  ocrames: 'ocrames',
  ocranas: 'ocranas',
  ocranos: 'ocranos',
  ocranta: 'ocranta',
  ocranto: 'ocranto',
  ocrases: 'ocrases',
  ocreada: 'ocreada',
  ocreado: 'ocreado',
  ocreata: 'ocreata',
  ocreato: 'ocreato',
  ocreina: 'ocreína',
  ocreosa: 'ocreosa',
  ocreoso: 'ocreoso',
  ocriase: 'ocríase',
  ocroita: 'ocroíta',
  ocroite: 'ocroíte',
  ocroito: 'ocroíto',
  ocromas: 'ocromas',
  ocromia: 'ocrômia',
  ocroses: 'ocroses',
  ocrosia: 'ocrosia',
  ocrosis: 'ocrósis',
  octanai: 'octanai',
  octanal: 'octanal',
  octanam: 'octanam',
  octanar: 'octanar',
  octanas: 'octanas',
  octanei: 'octanei',
  octanem: 'octanem',
  octanes: 'octanes',
  octanio: 'octânio',
  octanos: 'octanos',
  octanou: 'octanou',
  octante: 'octante',
  octavia: 'octávia',
  octebio: 'octébio',
  octecas: 'octecas',
  octenos: 'octenos',
  octeras: 'octeras',
  octeros: 'octeros',
  octetos: 'octetos',
  octiase: 'octíase',
  octidis: 'octidis',
  octilas: 'octilas',
  octilio: 'octílio',
  octilos: 'octilos',
  octinos: 'octinos',
  octodio: 'octódio',
  octodos: 'octodos',
  octoica: 'octoica',
  octoico: 'octoico',
  octonas: 'octonas',
  octonos: 'octonos',
  octopos: 'octopos',
  octorum: 'octorum',
  octupla: 'óctupla',
  octuplo: 'óctuplo',
  octusse: 'octusse',
  ocujare: 'ocujarê',
  oculada: 'oculada',
  oculado: 'oculado',
  oculeos: 'ocúleos',
  oculina: 'oculina',
  oculosa: 'oculosa',
  oculoso: 'oculoso',
  ocultai: 'ocultai',
  ocultam: 'ocultam',
  ocultar: 'ocultar',
  ocultas: 'ocultas',
  ocultei: 'ocultei',
  ocultem: 'ocultem',
  ocultes: 'ocultes',
  ocultos: 'ocultos',
  ocultou: 'ocultou',
  ocumbas: 'ocumbas',
  ocupada: 'ocupada',
  ocupado: 'ocupado',
  ocupais: 'ocupais',
  ocupamo: 'ocupamo',
  ocupara: 'ocupara',
  ocupava: 'ocupava',
  ocupeis: 'ocupeis',
  ocupemo: 'ocupemo',
  ocursai: 'ocursai',
  ocursam: 'ocursam',
  ocursar: 'ocursar',
  ocursas: 'ocursas',
  ocursei: 'ocursei',
  ocursem: 'ocursem',
  ocurses: 'ocurses',
  ocursou: 'ocursou',
  ocutava: 'ocutava',
  ocuubas: 'ocuubas',
  odalite: 'odalite',
  odatria: 'odátria',
  oddites: 'oddites',
  odenita: 'odenita',
  odezias: 'odézias',
  odiadao: 'odiadão',
  odiadas: 'odiadas',
  odiador: 'odiador',
  odiados: 'odiados',
  odiamos: 'odiamos',
  odiando: 'odiando',
  odiante: 'odiante',
  odiaram: 'odiaram',
  odiarao: 'odiarão',
  odiaras: 'odiaras',
  odiarei: 'odiarei',
  odiarem: 'odiarem',
  odiares: 'odiares',
  odiaria: 'odiaria',
  odiarmo: 'odiarmo',
  odiasse: 'odiasse',
  odiaste: 'odiaste',
  odiavam: 'odiavam',
  odiavas: 'odiavas',
  odiavel: 'odiável',
  odiemos: 'odiemos',
  odienta: 'odienta',
  odiento: 'odiento',
  odinero: 'odinero',
  odinias: 'odínias',
  odinica: 'odínica',
  odinico: 'odínico',
  odiosas: 'odiosas',
  odiosos: 'odiosos',
  odistas: 'odistas',
  odjadas: 'odjadas',
  odobeno: 'odobeno',
  odonata: 'odonata',
  odonato: 'odonato',
  odoncia: 'odôncia',
  odonima: 'odônima',
  odonimo: 'odônimo',
  odontia: 'odôntia',
  odontio: 'odôntio',
  odonton: 'odônton',
  odontos: 'odontos',
  odorada: 'odorada',
  odorado: 'odorado',
  odorais: 'odorais',
  odoramo: 'odoramo',
  odorara: 'odorará',
  odorata: 'odorata',
  odorato: 'odorato',
  odorava: 'odorava',
  odoreis: 'odoreis',
  odoremo: 'odoremo',
  odorica: 'odórica',
  odorico: 'odorico',
  odorina: 'odorina',
  odoriza: 'odoriza',
  odorize: 'odorize',
  odorizo: 'odorizo',
  odorosa: 'odorosa',
  odoroso: 'odoroso',
  odorzao: 'odorzão',
  odradas: 'odradas',
  odraria: 'odraria',
  odreira: 'odreira',
  odreiro: 'odreiro',
  odrisia: 'odrísia',
  odrisio: 'odrísio',
  odulcos: 'odulços',
  oecanto: 'oecanto',
  oecetis: 'oecétis',
  oedalos: 'oédalos',
  oeduema: 'oeduema',
  oeirana: 'oeirana',
  oersted: 'oersted',
  oestino: 'oestino',
  ofarita: 'ofarita',
  ofegada: 'ofegada',
  ofegado: 'ofegado',
  ofegais: 'ofegais',
  ofegara: 'ofegará',
  ofegava: 'ofegava',
  ofegosa: 'ofegosa',
  ofegoso: 'ofegoso',
  ofeguei: 'ofeguei',
  ofeguem: 'ofeguem',
  ofegues: 'ofegues',
  ofelias: 'ofélias',
  ofelima: 'ofélima',
  ofelimo: 'ofélimo',
  ofendam: 'ofendam',
  ofendas: 'ofendas',
  ofendei: 'ofendei',
  ofendem: 'ofendem',
  ofender: 'ofender',
  ofendes: 'ofendes',
  ofendeu: 'ofendeu',
  ofendia: 'ofendia',
  ofensao: 'ofensão',
  ofensas: 'ofensas',
  ofensor: 'ofensor',
  ofensos: 'ofensos',
  ofereca: 'ofereça',
  oferece: 'oferece',
  ofereci: 'ofereci',
  ofereco: 'ofereço',
  ofertai: 'ofertai',
  ofertam: 'ofertam',
  ofertar: 'ofertar',
  ofertas: 'ofertas',
  ofertei: 'ofertei',
  ofertem: 'ofertem',
  ofertes: 'ofertes',
  ofertou: 'ofertou',
  offline: 'offline',
  offside: 'offside',
  ofiases: 'ofíases',
  oficiai: 'oficiai',
  oficial: 'oficial',
  oficiam: 'oficiam',
  oficiar: 'oficiar',
  oficias: 'oficias',
  oficiei: 'oficiei',
  oficiem: 'oficiem',
  oficies: 'oficies',
  oficina: 'oficina',
  oficios: 'ofícios',
  oficiou: 'oficiou',
  ofictis: 'ofíctis',
  ofidica: 'ofídica',
  ofidico: 'ofídico',
  ofidida: 'ofídida',
  ofidios: 'ofídios',
  ofierno: 'ofierno',
  ofigles: 'ofigles',
  ofiodes: 'ofiodes',
  ofiodon: 'ofíodon',
  ofioelo: 'ofioelo',
  ofioide: 'ofioide',
  ofiones: 'ofiones',
  ofionix: 'ofíonix',
  ofiopes: 'ofiopes',
  ofiopos: 'ofíopos',
  ofirias: 'ofírias',
  ofirina: 'ofirina',
  ofirino: 'ofirino',
  ofirios: 'ofírios',
  ofismas: 'ofismas',
  ofisuro: 'ofisuro',
  ofitica: 'ofítica',
  ofitico: 'ofítico',
  ofitina: 'ofitina',
  ofitosa: 'ofitosa',
  ofitoso: 'ofitoso',
  ofiucos: 'ofiúcos',
  ofiuras: 'ofiúras',
  ofiuros: 'ofiúros',
  ofiusas: 'ofiúsas',
  ofiussa: 'ofiússa',
  ofomimo: 'ofomimo',
  ofonios: 'ofônios',
  ofrecao: 'ofreção',
  ofrecom: 'ofreçom',
  ofrenda: 'ofrenda',
  ofridea: 'ofrídea',
  ofrideo: 'ofrídeo',
  ofridio: 'ofrídio',
  ofriope: 'ofriope',
  ofrisia: 'ofrísia',
  ofrites: 'ofrites',
  ofsetes: 'ofsetes',
  oftalmo: 'oftalmo',
  ofuscai: 'ofuscai',
  ofuscam: 'ofuscam',
  ofuscar: 'ofuscar',
  ofuscas: 'ofuscas',
  ofuscou: 'ofuscou',
  ofusque: 'ofusque',
  ogadoes: 'ogadões',
  ogadona: 'ogadona',
  ogadora: 'ogadora',
  ogambas: 'ogambás',
  ogamica: 'ogâmica',
  ogamico: 'ogâmico',
  ogangos: 'ogangos',
  oganica: 'ogânica',
  oganico: 'ogânico',
  ogantes: 'ogantes',
  ogaozao: 'ogãozão',
  ogardes: 'ogardes',
  ogareis: 'ogareis',
  ogariam: 'ogariam',
  ogarias: 'ogarias',
  ogarmos: 'ogarmos',
  ogassem: 'ogassem',
  ogasses: 'ogasses',
  ogastes: 'ogastes',
  ogaveis: 'ogáveis',
  ogcodes: 'ogcodes',
  ogdoada: 'ogdóada',
  ogdoade: 'ogdóade',
  ogervao: 'ogervão',
  ogieras: 'ogieras',
  ogigias: 'ogígias',
  ogigica: 'ogígica',
  ogigico: 'ogígico',
  ogigios: 'ogígios',
  ogivada: 'ogivada',
  ogivado: 'ogivado',
  ogivais: 'ogivais',
  ogivamo: 'ogivamo',
  ogivara: 'ogivará',
  ogivava: 'ogivava',
  ogiveis: 'ogiveis',
  ogivemo: 'ogivemo',
  ogiveta: 'ogiveta',
  oglifas: 'oglifas',
  ogueira: 'ogueira',
  ogueiro: 'ogueiro',
  oguemos: 'oguemos',
  oguiris: 'oguiris',
  ogunhes: 'ogunhês',
  ogunies: 'oguniês',
  ohmicas: 'ôhmicas',
  ohmicos: 'ôhmicos',
  oiampim: 'oiampim',
  oiarana: 'oiaraná',
  oidemia: 'oidemia',
  oidiais: 'oidiais',
  oiedeia: 'oiedeia',
  oigadao: 'oigadão',
  oigadas: 'oigadas',
  oigador: 'oigador',
  oigados: 'oigados',
  oigales: 'oigalês',
  oigamos: 'oigamos',
  oigando: 'oigando',
  oigante: 'oigante',
  oigaram: 'oigaram',
  oigarao: 'oigarão',
  oigaras: 'oigarás',
  oigarei: 'oigarei',
  oigarem: 'oigarem',
  oigares: 'oigares',
  oigaria: 'oigaria',
  oigasse: 'oigasse',
  oigaste: 'oigaste',
  oigates: 'oigatés',
  oigavam: 'oigavam',
  oigavas: 'oigavas',
  oigueis: 'oigueis',
  oilites: 'oilites',
  oinolea: 'oinólea',
  oinoleo: 'oinóleo',
  oiradao: 'oiradão',
  oiradas: 'oiradas',
  oirador: 'oirador',
  oirados: 'oirados',
  oiramas: 'oiramas',
  oiramos: 'oiramos',
  oiranas: 'oiranas',
  oirando: 'oirando',
  oirante: 'oirante',
  oiraram: 'oiraram',
  oirarao: 'oirarão',
  oiraras: 'oirarás',
  oirarei: 'oirarei',
  oirarem: 'oirarem',
  oirares: 'oirares',
  oiraria: 'oiraria',
  oirarmo: 'oirarmo',
  oirasse: 'oirasse',
  oiraste: 'oiraste',
  oirates: 'oirates',
  oiravam: 'oiravam',
  oiravas: 'oiravas',
  oiregas: 'oiregas',
  oirejai: 'oirejai',
  oirejam: 'oirejam',
  oirejar: 'oirejar',
  oirejas: 'oirejas',
  oirejei: 'oirejei',
  oirejem: 'oirejem',
  oirejes: 'oirejes',
  oirejou: 'oirejou',
  oiremos: 'oiremos',
  oiricai: 'oiriçai',
  oiricam: 'oiriçam',
  oiricar: 'oiriçar',
  oiricas: 'oiriças',
  oiricei: 'oiricei',
  oiricem: 'oiricem',
  oirices: 'oirices',
  oiricou: 'oiriçou',
  oirincu: 'oirincu',
  oiripel: 'oiripel',
  oiropel: 'oiropel',
  oirotes: 'oirotes',
  oirudas: 'oirudas',
  oirudos: 'oirudos',
  oitante: 'oitante',
  oitavai: 'oitavai',
  oitavam: 'oitavam',
  oitavao: 'oitavão',
  oitavar: 'oitavar',
  oitavas: 'oitavas',
  oitavei: 'oitavei',
  oitavem: 'oitavem',
  oitaves: 'oitaves',
  oitavos: 'oitavos',
  oitavou: 'oitavou',
  oitchis: 'oitchis',
  oitedas: 'oitedas',
  oiteira: 'oiteira',
  oiteiro: 'oiteiro',
  oitenta: 'oitenta',
  oitibas: 'oitibás',
  oitibos: 'oitibós',
  oitivas: 'oitivas',
  oitonas: 'oitonas',
  oitubro: 'oitubro',
  ojambas: 'ojambas',
  ojambis: 'ojambis',
  ojeriza: 'ojeriza',
  ojerize: 'ojerize',
  ojerizo: 'ojerizo',
  ojungue: 'ojungue',
  olaceas: 'oláceas',
  olaceos: 'oláceos',
  olaeira: 'olaeira',
  olafita: 'olafita',
  olaflur: 'olaflur',
  olamina: 'olamina',
  olampis: 'olampis',
  olandim: 'olandim',
  olandis: 'olandis',
  olangas: 'olangás',
  olanica: 'olânica',
  olanico: 'olânico',
  olanina: 'olanina',
  olarias: 'olarias',
  olarila: 'olarila',
  olbiana: 'olbiana',
  olbiano: 'olbiano',
  oleacao: 'oleação',
  oleacea: 'oleácea',
  oleaceo: 'oleáceo',
  oleadai: 'oleadai',
  oleadam: 'oleadam',
  oleadao: 'oleadão',
  oleadar: 'oleadar',
  oleadas: 'oleadas',
  oleadei: 'oleadei',
  oleadem: 'oleadem',
  oleades: 'oleades',
  oleador: 'oleador',
  oleados: 'oleados',
  oleadou: 'oleadou',
  oleales: 'oleales',
  oleamos: 'oleamos',
  oleando: 'oleando',
  oleante: 'oleante',
  olearam: 'olearam',
  olearao: 'olearão',
  olearas: 'olearás',
  olearei: 'olearei',
  olearem: 'olearem',
  oleares: 'oleares',
  olearia: 'olearia',
  oleases: 'oléases',
  oleasse: 'oleasse',
  oleaste: 'oleaste',
  oleatos: 'oleatos',
  oleavam: 'oleavam',
  oleavas: 'oleavas',
  oleemos: 'oleemos',
  oleense: 'oleense',
  oleenta: 'oleenta',
  oleento: 'oleento',
  olefina: 'olefina',
  oleicas: 'oleicas',
  oleicos: 'oleicos',
  oleidea: 'oleídea',
  oleideo: 'oleídeo',
  oleidos: 'oleídos',
  oleilas: 'oleilas',
  oleilos: 'oleílos',
  oleinas: 'oleínas',
  oleinea: 'oleínea',
  oleineo: 'oleíneo',
  oleiras: 'oleiras',
  oleiros: 'oleiros',
  olembro: 'olembro',
  olencia: 'olência',
  olentes: 'olentes',
  oleomel: 'oleomel',
  oleonas: 'oleonas',
  oleosas: 'oleosas',
  oleosos: 'oleosos',
  olerosa: 'olerosa',
  oleroso: 'oleroso',
  oleulas: 'oleulas',
  olfacao: 'olfação',
  olfacta: 'olfactá',
  olfacte: 'olfacte',
  olfatai: 'olfatai',
  olfatam: 'olfatam',
  olfatar: 'olfatar',
  olfatas: 'olfatas',
  olfatea: 'olfateá',
  olfatei: 'olfatei',
  olfatem: 'olfatem',
  olfates: 'olfates',
  olfatos: 'olfatos',
  olfatou: 'olfatou',
  olhadao: 'olhadão',
  olhadas: 'olhadas',
  olhador: 'olhador',
  olhados: 'olhados',
  olhalva: 'olhalva',
  olhalvo: 'olhalvo',
  olhamos: 'olhamos',
  olhando: 'olhando',
  olhante: 'olhante',
  olhapim: 'olhapim',
  olharam: 'olharam',
  olharao: 'olharão',
  olharas: 'olharas',
  olharei: 'olharei',
  olharem: 'olharem',
  olhares: 'olhares',
  olharia: 'olharia',
  olharmo: 'olharmo',
  olhasse: 'olhasse',
  olhaste: 'olhaste',
  olhavam: 'olhavam',
  olhavas: 'olhavas',
  olhavel: 'olhável',
  olheira: 'olheira',
  olheiro: 'olheiro',
  olhemos: 'olhemos',
  olhenta: 'olhenta',
  olhento: 'olhento',
  olhetes: 'olhetes',
  olhicas: 'olhicas',
  olhicos: 'olhicos',
  olhinha: 'olhinha',
  olhinho: 'olhinho',
  olhudas: 'olhudas',
  olhudos: 'olhudos',
  oliaris: 'oliaris',
  oliaros: 'olíaros',
  olibana: 'olíbana',
  olibano: 'olíbano',
  olibros: 'olibros',
  oligias: 'olígias',
  oligoro: 'oligoro',
  oligota: 'olígota',
  olimone: 'olimone',
  olimpio: 'olímpio',
  olimpos: 'olimpos',
  olincia: 'olíncia',
  olincio: 'olíncio',
  olindes: 'olindês',
  olindia: 'olíndia',
  olineas: 'olíneas',
  olinica: 'olínica',
  olinico: 'olínico',
  olintia: 'olíntia',
  olintio: 'olíntio',
  olintos: 'olintos',
  olipios: 'olípios',
  olireas: 'olíreas',
  olireos: 'olíreos',
  olirias: 'olírias',
  olisbea: 'olísbea',
  olivada: 'olivada',
  olivado: 'olivado',
  olivais: 'olivais',
  olivamo: 'olivamo',
  olivara: 'olivara',
  olivari: 'olivari',
  olivava: 'olivava',
  oliveas: 'olíveas',
  olivedo: 'olivedo',
  oliveis: 'oliveis',
  olivela: 'olivelá',
  olivele: 'olivele',
  olivelo: 'olivelo',
  olivemo: 'olivemo',
  oliveos: 'olíveos',
  oliveta: 'oliveta',
  olivida: 'olívida',
  olivila: 'olivila',
  olivina: 'olivina',
  olivita: 'olivita',
  olivite: 'olivite',
  olmecas: 'olmecas',
  olmedal: 'olmedal',
  olmedia: 'olmédia',
  olmedos: 'olmedos',
  olmeira: 'olmeira',
  olmeiro: 'olmeiro',
  olococo: 'olococo',
  olofros: 'olofros',
  olongos: 'olongos',
  oloniti: 'oloniti',
  olorada: 'olorada',
  olorado: 'olorado',
  oloriza: 'oloriza',
  olorize: 'olorize',
  olorizo: 'olorizo',
  olorosa: 'olorosa',
  oloroso: 'oloroso',
  olostra: 'olostra',
  olotelo: 'olótelo',
  olpidio: 'olpídio',
  olubaje: 'olubajé',
  olulana: 'olulana',
  olulano: 'olulano',
  olulomo: 'olulomo',
  olumues: 'olumués',
  olupale: 'olupalé',
  olvidai: 'olvidai',
  olvidam: 'olvidam',
  olvidar: 'olvidar',
  olvidas: 'olvidas',
  olvidei: 'olvidei',
  olvidem: 'olvidem',
  olvides: 'olvides',
  olvidos: 'olvidos',
  olvidou: 'olvidou',
  omacios: 'omácios',
  omacola: 'omacola',
  omadios: 'omádios',
  omafias: 'omafias',
  omagras: 'omagras',
  omaguas: 'omáguas',
  omaleis: 'omáleis',
  omalgia: 'omalgia',
  omalias: 'omálias',
  omaliea: 'omalíea',
  omalios: 'omálios',
  omaliso: 'omaliso',
  omandas: 'omandas',
  omanita: 'omanita',
  omantos: 'omantos',
  omarins: 'omarins',
  omaseas: 'omáseas',
  omaseos: 'omáseos',
  omasoes: 'omasões',
  omasona: 'omasona',
  omatela: 'omatela',
  omatios: 'omátios',
  omatuas: 'omátuas',
  ombacas: 'ombacas',
  ombambe: 'ombambe',
  ombanda: 'ombanda',
  ombanja: 'ombanja',
  ombiaxo: 'ombiaxo',
  ombrada: 'ombrada',
  ombreai: 'ombreai',
  ombrear: 'ombrear',
  ombreei: 'ombreei',
  ombreia: 'ombreia',
  ombreie: 'ombreie',
  ombreio: 'ombreio',
  ombreou: 'ombreou',
  ombrino: 'ombrino',
  ombruda: 'ombruda',
  ombrudo: 'ombrudo',
  ombulos: 'ombulos',
  omeleta: 'omeleta',
  omelete: 'omelete',
  omenies: 'omeniés',
  omental: 'omental',
  omentos: 'omentos',
  omertis: 'omertis',
  omiadas: 'omíadas',
  omicron: 'ômicron',
  omicros: 'omicros',
  ominada: 'ominada',
  ominado: 'ominado',
  ominais: 'ominais',
  ominamo: 'ominamo',
  ominara: 'ominará',
  ominava: 'ominava',
  omineis: 'omineis',
  ominemo: 'ominemo',
  ominosa: 'ominosa',
  ominoso: 'ominoso',
  omissao: 'omissão',
  omissas: 'omissas',
  omissor: 'omissor',
  omissos: 'omissos',
  omitais: 'omitais',
  omitamo: 'omitamo',
  omitemo: 'omitemo',
  omitiam: 'omitiam',
  omitias: 'omitias',
  omitida: 'omitida',
  omitido: 'omitido',
  omitilo: 'omitilo',
  omitimo: 'omitimo',
  omitira: 'omitira',
  omiziam: 'omiziám',
  omnibus: 'ómnibus',
  omocera: 'omócera',
  omofaga: 'omófaga',
  omofago: 'omófago',
  omoflos: 'omoflos',
  omoforo: 'omóforo',
  omofros: 'omofros',
  omoidea: 'omoidea',
  omoideo: 'omoideo',
  omoides: 'omoides',
  omolabo: 'omólabo',
  omoloco: 'omolocô',
  omolucu: 'omolucu',
  omorgas: 'omorgas',
  omosita: 'omosita',
  ompatas: 'ompatas',
  ompeque: 'ompeque',
  omuande: 'omuande',
  omuenhe: 'omuenhe',
  omufito: 'omufito',
  omulucu: 'omulucu',
  omulzao: 'omulzão',
  omumbeu: 'omumbeu',
  omumbos: 'omumbos',
  omuxate: 'omuxate',
  onagata: 'onagata',
  onagras: 'onagras',
  onagrea: 'onágrea',
  onagreo: 'onágreo',
  onagres: 'onagres',
  onagros: 'onagros',
  onaniza: 'onanizá',
  onanize: 'onanize',
  onanizo: 'onanizo',
  onastro: 'onastro',
  oncaeia: 'oncaeia',
  onceias: 'onceias',
  onceira: 'onceira',
  onceiro: 'onceiro',
  oncense: 'oncense',
  onciais: 'onciais',
  oncidia: 'oncídia',
  oncidio: 'oncídio',
  oncimas: 'oncimas',
  oncinha: 'oncinha',
  oncinio: 'oncínio',
  oncobas: 'oncobas',
  oncobea: 'oncóbea',
  oncobeo: 'oncóbeo',
  oncodes: 'oncodes',
  onconas: 'onçonas',
  oncoses: 'oncoses',
  ondacas: 'ondacas',
  ondadao: 'ondadão',
  ondadas: 'ondadas',
  ondador: 'ondador',
  ondados: 'ondados',
  ondagem: 'ondagem',
  ondaios: 'ondaios',
  ondalas: 'ondalas',
  ondamos: 'ondamos',
  ondando: 'ondando',
  ondante: 'ondante',
  ondaram: 'ondaram',
  ondarao: 'ondarão',
  ondaras: 'ondarás',
  ondarei: 'ondarei',
  ondarem: 'ondarem',
  ondares: 'ondares',
  ondaria: 'ondaria',
  ondarmo: 'ondarmo',
  ondasse: 'ondasse',
  ondaste: 'ondaste',
  ondatra: 'ondatra',
  ondavam: 'ondavam',
  ondavas: 'ondavas',
  ondeada: 'ondeada',
  ondeado: 'ondeado',
  ondeais: 'ondeais',
  ondeamo: 'ondeamo',
  ondeara: 'ondeara',
  ondeava: 'ondeava',
  ondeeis: 'ondeeis',
  ondeemo: 'ondeemo',
  ondeiam: 'ondeiam',
  ondeias: 'ondeias',
  ondeiem: 'ondeiem',
  ondeies: 'ondeies',
  ondeira: 'ondeira',
  ondeiro: 'ondeiro',
  ondejai: 'ondejai',
  ondejam: 'ondejam',
  ondejar: 'ondejar',
  ondejas: 'ondejas',
  ondejei: 'ondejei',
  ondejem: 'ondejem',
  ondejes: 'ondejes',
  ondejou: 'ondejou',
  ondeles: 'ondeles',
  ondemos: 'ondemos',
  ondimba: 'ondimba',
  ondinas: 'ondinas',
  ondingo: 'ondingo',
  ondinha: 'ondinha',
  ondjois: 'ondjóis',
  ondoada: 'ondoada',
  ondoado: 'ondoado',
  ondoais: 'ondoais',
  ondoamo: 'ondoamo',
  ondoara: 'ondoará',
  ondoava: 'ondoava',
  ondocai: 'ondocaí',
  ondoeis: 'ondoeis',
  ondoemo: 'ondoemo',
  ondudas: 'ondudas',
  ondulai: 'ondulai',
  ondulam: 'ondulam',
  ondular: 'ondular',
  ondulas: 'ondulas',
  ondulei: 'ondulei',
  ondulem: 'ondulem',
  ondules: 'ondules',
  ondulou: 'ondulou',
  onegita: 'onegita',
  onegite: 'onegite',
  oneidas: 'oneídas',
  onemano: 'onêmano',
  onenses: 'onenses',
  onerada: 'onerada',
  onerado: 'onerado',
  onerais: 'onerais',
  oneramo: 'oneramo',
  onerara: 'onerara',
  onerava: 'onerava',
  onereis: 'onereis',
  oneremo: 'oneremo',
  onerosa: 'onerosa',
  oneroso: 'oneroso',
  onesina: 'onesina',
  onesita: 'onesita',
  onesite: 'onesite',
  onesito: 'onesito',
  onfacio: 'onfácio',
  onfalea: 'onfálea',
  onfalia: 'onfália',
  onfalos: 'onfalos',
  ongamba: 'ongamba',
  onganda: 'onganda',
  ongebos: 'ongebos',
  onglete: 'onglete',
  ongolos: 'ongolos',
  ongombe: 'ongombe',
  ongombo: 'ongombo',
  ongonga: 'ongonga',
  ongonhe: 'ongonhe',
  ongotis: 'ongotis',
  onguali: 'onguáli',
  onguari: 'onguari',
  ongueve: 'ongueve',
  ongulos: 'ongulos',
  onhames: 'onhames',
  onhenga: 'onhenga',
  onhense: 'onhense',
  onhimas: 'onhimas',
  onhocas: 'onhocas',
  onhuios: 'onhuios',
  onhunga: 'onhunga',
  onialai: 'onialai',
  onialia: 'onialia',
  oniamas: 'oniamas',
  onichas: 'onichas',
  onicoma: 'onicoma',
  onicora: 'onicora',
  onicose: 'onicose',
  onifera: 'onífera',
  onifero: 'onífero',
  onigena: 'onígena',
  onigeno: 'onígeno',
  onimoda: 'onímoda',
  onimodo: 'onímodo',
  onineas: 'oníneas',
  onineos: 'oníneos',
  oninles: 'oninlés',
  oniosas: 'oniosas',
  oniosos: 'oniosos',
  onipara: 'onípara',
  oniparo: 'oníparo',
  oniquia: 'oniquia',
  oniquio: 'oníquio',
  onirica: 'onírica',
  onirico: 'onírico',
  oniscia: 'oníscia',
  oniscio: 'oníscio',
  oniscos: 'oniscos',
  onitona: 'onítona',
  onitono: 'onítono',
  onivaga: 'onívaga',
  onivago: 'onívago',
  onivoma: 'onívoma',
  onivomo: 'onívomo',
  onivora: 'onívora',
  onivoro: 'onívoro',
  onjamba: 'onjamba',
  onjelia: 'onjélia',
  onjimbi: 'onjimbi',
  onlines: 'onlines',
  onoceto: 'onoceto',
  onoclea: 'onóclea',
  onocola: 'onocola',
  onocolo: 'onocolo',
  ononide: 'onônide',
  ononina: 'ononina',
  onosmas: 'onosmas',
  onotera: 'onotera',
  onquela: 'onquela',
  ontaria: 'ontária',
  ontario: 'ontário',
  onteros: 'onteros',
  onticas: 'ônticas',
  onticos: 'ônticos',
  ontivas: 'ontivas',
  ontivos: 'ontivos',
  onundos: 'onundos',
  onunhas: 'onunhas',
  onussis: 'onussis',
  onustas: 'onustas',
  onustos: 'onustos',
  onutuva: 'onutuva',
  onzenai: 'onzenai',
  onzenam: 'onzenam',
  onzenar: 'onzenar',
  onzenas: 'onzenas',
  onzenea: 'onzeneá',
  onzenei: 'onzenei',
  onzenem: 'onzenem',
  onzenes: 'onzenes',
  onzenou: 'onzenou',
  ooangio: 'ooângio',
  oociano: 'oocíano',
  oociese: 'oociese',
  oociste: 'oociste',
  oocisto: 'oocisto',
  oocitos: 'oócitos',
  oocormo: 'oocormo',
  oocroto: 'oocroto',
  oodeopo: 'oodéopo',
  oofagas: 'oófagas',
  oofagia: 'oofagia',
  oofagos: 'oófagos',
  oofitas: 'oófitas',
  oofitos: 'oófitos',
  ooforos: 'oóforos',
  oogamas: 'oógamas',
  oogamia: 'oogamia',
  oogamos: 'oógamos',
  oogemas: 'oogemas',
  oogenia: 'oogenia',
  oogonia: 'oogonia',
  oogonio: 'oogônio',
  oograma: 'oograma',
  ooleina: 'ooleína',
  oolemas: 'oolemas',
  oolises: 'oólises',
  oolitos: 'oólitos',
  oologia: 'oologia',
  oomante: 'oomante',
  oometra: 'oometra',
  oomorfo: 'oomorfo',
  oopelta: 'oopelta',
  ooptero: 'oóptero',
  oosfera: 'oosfera',
  oospora: 'oóspora',
  oosporo: 'oósporo',
  oossomo: 'oossomo',
  ootecas: 'ootecas',
  ootides: 'oótides',
  ootidio: 'ootídio',
  ootipia: 'ootipia',
  ootipos: 'oótipos',
  ootocas: 'oótocas',
  ootocos: 'oótocos',
  ootomas: 'oótomas',
  ootomia: 'ootomia',
  oozoide: 'oozoide',
  opacada: 'opacada',
  opacado: 'opacado',
  opacais: 'opacais',
  opacala: 'opacala',
  opacamo: 'opacamo',
  opacara: 'opacara',
  opacava: 'opacava',
  opacita: 'opacita',
  opacite: 'opacite',
  opadaca: 'opadaça',
  opadaco: 'opadaço',
  opadoes: 'opadões',
  opadona: 'opadona',
  opadora: 'opadora',
  opalgia: 'opalgia',
  opalias: 'opálias',
  opalica: 'opálica',
  opalico: 'opálico',
  opalina: 'opalina',
  opaline: 'opaline',
  opalino: 'opalino',
  opalita: 'opalita',
  opaliza: 'opaliza',
  opalize: 'opalize',
  opalizo: 'opalizo',
  opalzao: 'opalzão',
  opanije: 'opanijé',
  opanois: 'opanóis',
  opanqui: 'opanqui',
  opantes: 'opantes',
  opaquei: 'opaquei',
  opaquem: 'opaquem',
  opaques: 'opaques',
  opardes: 'opardes',
  opareis: 'opáreis',
  oparemo: 'oparemo',
  opariam: 'opariam',
  oparias: 'oparias',
  oparmos: 'oparmos',
  opassem: 'opassem',
  opasses: 'opasses',
  opastes: 'opastes',
  opatica: 'opática',
  opatros: 'opatros',
  opaveis: 'opáveis',
  opconas: 'opçonas',
  opelifa: 'opelifá',
  operada: 'operada',
  operado: 'operado',
  operais: 'operais',
  operamo: 'operamo',
  operara: 'operara',
  operava: 'operava',
  opereis: 'opereis',
  operemo: 'operemo',
  opereta: 'opereta',
  operosa: 'operosa',
  operoso: 'operoso',
  opertos: 'opertos',
  opiacea: 'opiácea',
  opiaceo: 'opiáceo',
  opiadao: 'opiadão',
  opiadas: 'opiadas',
  opiador: 'opiador',
  opiados: 'opiados',
  opiamos: 'opiamos',
  opiando: 'opiando',
  opiante: 'opiante',
  opiaram: 'opiaram',
  opiarao: 'opiarão',
  opiaras: 'opiarás',
  opiarei: 'opiarei',
  opiarem: 'opiarem',
  opiares: 'opiares',
  opiaria: 'opiaria',
  opiarmo: 'opiarmo',
  opiasse: 'opiasse',
  opiaste: 'opiaste',
  opiatos: 'opiatos',
  opiavam: 'opiavam',
  opiavas: 'opiavas',
  opidana: 'opidana',
  opidano: 'opidano',
  opiemos: 'opiemos',
  opifera: 'opífera',
  opifero: 'opífero',
  opifice: 'opífice',
  opilada: 'opilada',
  opilado: 'opilado',
  opilais: 'opilais',
  opilamo: 'opilamo',
  opilara: 'opilará',
  opilava: 'opilava',
  opileis: 'opileis',
  opilemo: 'opilemo',
  opiliao: 'opilião',
  opilias: 'opílias',
  opimias: 'opímias',
  opimios: 'opímios',
  opinada: 'opinada',
  opinado: 'opinado',
  opinais: 'opinais',
  opinaje: 'opinajé',
  opinamo: 'opinamo',
  opinara: 'opinara',
  opinava: 'opinava',
  opineis: 'opineis',
  opinemo: 'opinemo',
  opiniao: 'opinião',
  opinosa: 'opinosa',
  opinoso: 'opinoso',
  opioide: 'opioide',
  opipara: 'opípara',
  opiparo: 'opíparo',
  opistio: 'opístio',
  opistos: 'opistos',
  opitimo: 'opitimo',
  opizias: 'opízias',
  oplaria: 'oplária',
  oplario: 'oplário',
  oplomos: 'oplomos',
  opodima: 'opódima',
  opodimo: 'opódimo',
  opoente: 'opoente',
  opofila: 'opófila',
  opofilo: 'opófilo',
  opogona: 'opógona',
  opoleas: 'opóleas',
  opoleos: 'opóleos',
  opolica: 'opólica',
  opolico: 'opólico',
  opolito: 'opólito',
  opolzao: 'opolzão',
  opomiza: 'opomiza',
  opomumo: 'opomumo',
  oponais: 'oponais',
  opondes: 'opondes',
  oponham: 'oponham',
  oponhas: 'oponhas',
  opordes: 'opordes',
  oporeis: 'oporeis',
  oporemo: 'oporemo',
  oporiam: 'oporiam',
  oporias: 'oporias',
  oporica: 'opórica',
  oporina: 'opórina',
  oporino: 'opórino',
  opormos: 'opormos',
  oposina: 'oposina',
  oposita: 'opósita',
  oposito: 'opósito',
  opossum: 'opossum',
  opostas: 'opostas',
  opostos: 'opostos',
  opressa: 'opressa',
  opresso: 'opresso',
  oprimam: 'oprimam',
  oprimas: 'oprimas',
  oprimem: 'oprimem',
  oprimes: 'oprimes',
  oprimia: 'oprimia',
  oprimir: 'oprimir',
  oprimis: 'oprimis',
  oprimiu: 'oprimiu',
  oprobio: 'opróbio',
  opsilia: 'opsília',
  opsinas: 'opsinas',
  optacao: 'optação',
  optadao: 'optadão',
  optadas: 'optadas',
  optador: 'optador',
  optados: 'optados',
  optamos: 'optamos',
  optando: 'optando',
  optante: 'optante',
  optaram: 'optaram',
  optarao: 'optarão',
  optaras: 'optaras',
  optarei: 'optarei',
  optarem: 'optarem',
  optares: 'optares',
  optaria: 'optaria',
  optarmo: 'optarmo',
  optasse: 'optasse',
  optaste: 'optaste',
  optavam: 'optavam',
  optavas: 'optavas',
  optemos: 'optemos',
  opticas: 'ópticas',
  opticos: 'ópticos',
  opugnai: 'opugnai',
  opugnam: 'opugnam',
  opugnar: 'opugnar',
  opugnas: 'opugnas',
  opugnei: 'opugnei',
  opugnem: 'opugnem',
  opugnes: 'opugnes',
  opugnou: 'opugnou',
  opuluis: 'opuluís',
  opuncia: 'opúncia',
  opunham: 'opunham',
  opunhas: 'opunhas',
  opusera: 'opusera',
  oquedes: 'oquedês',
  oqueira: 'oqueira',
  oquemos: 'oquemos',
  oquenia: 'oquênia',
  oquetio: 'oquétio',
  oquinas: 'oquinas',
  orabins: 'orabins',
  orabuta: 'orabutã',
  oracoes: 'orações',
  oracona: 'oraçona',
  oracula: 'oracula',
  oracule: 'oracule',
  oraculo: 'oráculo',
  oradoes: 'oradões',
  oradona: 'oradona',
  oradora: 'oradora',
  oragono: 'orágono',
  oraliza: 'oraliza',
  oralize: 'oralize',
  oralizo: 'oralizo',
  oralzao: 'oralzão',
  oranesa: 'oranesa',
  orangos: 'orangos',
  oranita: 'oranita',
  orantes: 'orantes',
  orardes: 'orardes',
  orareis: 'orareis',
  oraremo: 'oraremo',
  orariam: 'orariam',
  orarias: 'orarias',
  orarios: 'orários',
  orarmos: 'orarmos',
  orassem: 'orassem',
  orasses: 'orasses',
  orastes: 'orastes',
  oratela: 'oratela',
  oratelo: 'oratelo',
  oraveis: 'oráveis',
  orbilha: 'orbilha',
  orbilia: 'orbília',
  orbinia: 'orbínia',
  orbitai: 'orbitai',
  orbital: 'orbital',
  orbitam: 'orbitam',
  orbitar: 'orbitar',
  orbitas: 'órbitas',
  orbitei: 'orbitei',
  orbitem: 'orbitem',
  orbites: 'orbites',
  orbitou: 'orbitou',
  orcadao: 'orçadão',
  orcadas: 'orçadas',
  orcador: 'orçador',
  orcados: 'orçados',
  orcamos: 'orçamos',
  orcando: 'orçando',
  orcante: 'orçante',
  orcaram: 'orçaram',
  orcarao: 'orçarão',
  orcaras: 'orçaras',
  orcarei: 'orçarei',
  orcarem: 'orçarem',
  orcares: 'orçares',
  orcaria: 'orçaria',
  orcarmo: 'orçarmo',
  orcasse: 'orçasse',
  orcaste: 'orçaste',
  orcavam: 'orçavam',
  orcavas: 'orçavas',
  orcavel: 'orçável',
  orcazes: 'orçazes',
  orceina: 'orceína',
  orcelas: 'orcelas',
  orcemos: 'orcemos',
  orchata: 'orchata',
  orciana: 'orciana',
  orciano: 'orciano',
  orcinas: 'orcinas',
  orcinol: 'orcinol',
  orcinos: 'orcinos',
  ordalia: 'ordália',
  ordalio: 'ordálio',
  ordeira: 'ordeira',
  ordeiro: 'ordeiro',
  ordenai: 'ordenai',
  ordenam: 'ordenam',
  ordenar: 'ordenar',
  ordenas: 'ordenas',
  ordenei: 'ordenei',
  ordenem: 'ordenem',
  ordenes: 'ordenes',
  ordenha: 'ordenha',
  ordenhe: 'ordenhe',
  ordenho: 'ordenho',
  ordenou: 'ordenou',
  ordeolo: 'ordéolo',
  ordinal: 'ordinal',
  ordinha: 'ordinha',
  ordinhe: 'ordinhe',
  ordinho: 'ordinho',
  ordumes: 'ordumes',
  oreadao: 'oreadão',
  oreadas: 'oréadas',
  oreades: 'oréades',
  oreador: 'oreador',
  oreados: 'oreados',
  oreamno: 'oreamno',
  oreamos: 'oreamos',
  oreando: 'oreando',
  oreante: 'oreante',
  orearam: 'orearam',
  orearao: 'orearão',
  orearas: 'orearás',
  orearei: 'orearei',
  orearem: 'orearem',
  oreares: 'oreares',
  orearia: 'orearia',
  oreasse: 'oreasse',
  oreaste: 'oreaste',
  oreavam: 'oreavam',
  oreavas: 'oreavas',
  orebita: 'orebita',
  orectas: 'orectas',
  oreemos: 'oreemos',
  oregano: 'orégano',
  oregoes: 'orégões',
  oreinos: 'oreinos',
  orelana: 'orelana',
  orelhai: 'orelhai',
  orelham: 'orelham',
  orelhao: 'orelhão',
  orelhar: 'orelhar',
  orelhas: 'orelhas',
  orelhei: 'orelhei',
  orelhem: 'orelhem',
  orelhes: 'orelhes',
  orelhou: 'orelhou',
  orelias: 'orélias',
  orelina: 'orelina',
  orendas: 'orendas',
  orenoco: 'orenoco',
  oreobio: 'oreóbio',
  oreoica: 'oreoica',
  orescio: 'oréscio',
  oressas: 'oressas',
  orestia: 'oréstia',
  oretana: 'oretana',
  oretano: 'oretano',
  orexias: 'orexias',
  orexina: 'orexina',
  orfaica: 'orfaica',
  orfaico: 'orfaico',
  orfanai: 'orfanai',
  orfanam: 'orfanam',
  orfanar: 'orfanar',
  orfanas: 'orfanas',
  orfanei: 'orfanei',
  orfanem: 'orfanem',
  orfanes: 'orfanes',
  orfania: 'orfânia',
  orfanou: 'orfanou',
  orfasio: 'orfásio',
  orfeica: 'orfeica',
  orfeico: 'orfeico',
  orfeoes: 'orfeões',
  orfeons: 'orfeons',
  orficas: 'órficas',
  orficos: 'órficos',
  orfismo: 'orfismo',
  orfista: 'orfista',
  orfneia: 'orfneia',
  orfonas: 'órfonas',
  orgadas: 'orgadas',
  organdi: 'organdi',
  organon: 'órganon',
  organza: 'organza',
  orgasmo: 'orgasmo',
  orgerio: 'orgério',
  orgevao: 'orgevão',
  orgiaca: 'orgíaca',
  orgiaco: 'orgíaco',
  orgiada: 'orgiada',
  orgiado: 'orgiado',
  orgiais: 'orgiais',
  orgiamo: 'orgiamo',
  orgiara: 'orgiará',
  orgiava: 'orgiava',
  orgieis: 'orgieis',
  orgiemo: 'orgiemo',
  orgiias: 'orgíias',
  orgivao: 'orgivão',
  orgonas: 'orgonas',
  orgonio: 'orgônio',
  orgulha: 'orgulha',
  orgulhe: 'orgulhe',
  orgulho: 'orgulho',
  oribata: 'oríbata',
  oricias: 'orícias',
  oricina: 'oricina',
  oricino: 'oricino',
  oricios: 'orícios',
  oricter: 'oricter',
  orictes: 'orictes',
  orictos: 'orictos',
  oricuri: 'oricuri',
  orienta: 'orienta',
  oriente: 'oriente',
  oriento: 'oriento',
  origami: 'origami',
  origamo: 'orígamo',
  origana: 'orígana',
  origano: 'orígano',
  origens: 'origens',
  origias: 'orígias',
  origina: 'origina',
  origine: 'origine',
  origino: 'origino',
  origmas: 'origmas',
  origone: 'origone',
  oriidea: 'oriídea',
  oriideo: 'oriídeo',
  orijone: 'orijone',
  orilhas: 'orilhas',
  orincos: 'orincos',
  orinque: 'orinque',
  oriolia: 'oriólia',
  oriolos: 'oríolos',
  oriquis: 'oriquis',
  orismos: 'orismos',
  orisois: 'orisóis',
  orissos: 'orissos',
  oritana: 'oritana',
  oritano: 'oritano',
  oritias: 'orítias',
  oritina: 'oritina',
  oriunda: 'oriunda',
  oriundo: 'oriundo',
  orixala: 'orixalá',
  orizeas: 'orízeas',
  orizeos: 'orízeos',
  orizita: 'orizita',
  orizite: 'orizite',
  orjavao: 'orjavão',
  orladao: 'orladão',
  orladas: 'orladas',
  orlador: 'orlador',
  orlados: 'orlados',
  orlaias: 'orlaias',
  orlamos: 'orlamos',
  orlando: 'orlando',
  orlante: 'orlante',
  orlaram: 'orlaram',
  orlarao: 'orlarão',
  orlaras: 'orlaras',
  orlarei: 'orlarei',
  orlarem: 'orlarem',
  orlares: 'orlares',
  orlaria: 'orlaria',
  orlarmo: 'orlarmo',
  orlasse: 'orlasse',
  orlaste: 'orlaste',
  orlavam: 'orlavam',
  orlavas: 'orlavas',
  orlemos: 'orlemos',
  orletas: 'orletas',
  orlinha: 'orlinha',
  orlitas: 'orlitas',
  ormasde: 'ormasde',
  ormiros: 'ormiros',
  ormosia: 'ormósia',
  ormulus: 'ormulus',
  ornadao: 'ornadão',
  ornadas: 'ornadas',
  ornador: 'ornador',
  ornados: 'ornados',
  ornamos: 'ornamos',
  ornando: 'ornando',
  ornante: 'ornante',
  ornaram: 'ornaram',
  ornarao: 'ornarão',
  ornaras: 'ornaras',
  ornarei: 'ornarei',
  ornarem: 'ornarem',
  ornares: 'ornares',
  ornaria: 'ornaria',
  ornarmo: 'ornarmo',
  ornasse: 'ornasse',
  ornaste: 'ornaste',
  ornatos: 'ornatos',
  ornavam: 'ornavam',
  ornavas: 'ornavas',
  orneada: 'orneada',
  orneado: 'orneado',
  orneais: 'orneais',
  orneara: 'orneará',
  orneava: 'orneava',
  orneeis: 'orneeis',
  orneiam: 'orneiam',
  orneias: 'orneias',
  orneiem: 'orneiem',
  orneies: 'orneies',
  orneira: 'orneira',
  orneiro: 'orneiro',
  ornejai: 'ornejai',
  ornejam: 'ornejam',
  ornejar: 'ornejar',
  ornejas: 'ornejas',
  ornejei: 'ornejei',
  ornejem: 'ornejem',
  ornejes: 'ornejes',
  ornejou: 'ornejou',
  ornemos: 'ornemos',
  orneode: 'orneode',
  ornitos: 'ornitos',
  oroanal: 'oroanal',
  orobias: 'oróbias',
  orobios: 'oróbios',
  orobita: 'orobita',
  orobite: 'orobite',
  orobito: 'orobito',
  orobodo: 'orobodó',
  orodalo: 'orodalo',
  orodino: 'oródino',
  oroense: 'oroense',
  orofeia: 'orofeia',
  orofila: 'orófila',
  orofilo: 'orófilo',
  orofita: 'orófita',
  orofito: 'orófito',
  orofoba: 'orófoba',
  orofobo: 'orófobo',
  orogena: 'orógena',
  orogeno: 'orógeno',
  oroides: 'oroides',
  oroipos: 'oroípos',
  oromila: 'oromilá',
  oroncio: 'orôncio',
  oroneta: 'oroneta',
  orongas: 'orongas',
  oronima: 'orônima',
  oronimo: 'orônimo',
  oronjas: 'oronjas',
  oroodes: 'oroodes',
  ororama: 'ororama',
  ororoba: 'ororoba',
  orotato: 'orotato',
  orotica: 'orótica',
  orotico: 'orótico',
  orovais: 'orovais',
  oroxilo: 'oróxilo',
  orquena: 'orquena',
  orqueno: 'orqueno',
  orquide: 'órquide',
  orquido: 'orquido',
  orquita: 'orquita',
  orquite: 'orquite',
  orreira: 'orreira',
  orreiro: 'orreiro',
  orretas: 'orretas',
  orsamos: 'orsamos',
  orseias: 'orseias',
  orselas: 'orselas',
  orsetas: 'orsetas',
  orsilos: 'orsilos',
  orsinia: 'orsínia',
  ortegia: 'ortégia',
  orteias: 'orteias',
  ortelia: 'ortélia',
  orteses: 'órteses',
  ortetro: 'ortetro',
  ortezia: 'ortézia',
  orticon: 'orticon',
  ortigai: 'ortigai',
  ortigam: 'ortigam',
  ortigao: 'ortigão',
  ortigar: 'ortigar',
  ortigas: 'ortigas',
  ortigou: 'ortigou',
  ortigue: 'ortigue',
  ortitas: 'ortitas',
  ortites: 'ortites',
  ortivas: 'ortivas',
  ortivos: 'ortivos',
  ortoide: 'ortoide',
  ortopes: 'ortopes',
  ortosas: 'ortosas',
  ortoses: 'ortoses',
  ortosia: 'ortósia',
  ortosio: 'ortósio',
  orucuri: 'orucuri',
  oruncos: 'oruncós',
  orungas: 'orungas',
  orungos: 'orungos',
  orungus: 'orungus',
  orvaeza: 'orvaeza',
  orvalha: 'orvalha',
  orvalhe: 'orvalhe',
  orvalho: 'orvalho',
  orvetos: 'orvetos',
  orvieto: 'orvieto',
  orzagas: 'orzagas',
  orzunas: 'orzunas',
  osassas: 'osassas',
  osazona: 'osazona',
  oscanio: 'oscânio',
  oscares: 'óscares',
  oscense: 'oscense',
  oscilai: 'oscilai',
  oscilam: 'oscilam',
  oscilar: 'oscilar',
  oscilas: 'oscilas',
  oscilei: 'oscilei',
  oscilem: 'oscilem',
  osciles: 'osciles',
  oscilou: 'oscilou',
  oscitai: 'oscitai',
  oscitam: 'oscitam',
  oscitar: 'oscitar',
  oscitas: 'oscitas',
  oscitei: 'oscitei',
  oscitem: 'oscitem',
  oscites: 'oscites',
  oscitou: 'oscitou',
  osculai: 'osculai',
  osculam: 'osculam',
  oscular: 'oscular',
  osculas: 'osculas',
  osculei: 'osculei',
  osculem: 'osculem',
  oscules: 'oscules',
  osculos: 'ósculos',
  osculou: 'osculou',
  osecrar: 'osecrar',
  oserias: 'osérias',
  osfiias: 'osfíias',
  osfiite: 'osfiite',
  osfites: 'osfites',
  osfrese: 'osfrese',
  osidase: 'osidase',
  osidios: 'osídios',
  osiride: 'osíride',
  osismia: 'osísmia',
  osismio: 'osísmio',
  osmandi: 'osmandi',
  osmanil: 'osmanil',
  osmanis: 'osmanis',
  osmanli: 'osmanli',
  osmanto: 'osmanto',
  osmatos: 'osmatos',
  osmeros: 'osmeros',
  osmiato: 'osmiato',
  osmicas: 'ósmicas',
  osmicos: 'ósmicos',
  osmieto: 'osmieto',
  osminas: 'osminas',
  osmiosa: 'osmiosa',
  osmioso: 'osmioso',
  osmitas: 'osmitas',
  osmolar: 'osmolar',
  osmonda: 'osmonda',
  osmoses: 'osmoses',
  osmunda: 'osmunda',
  osqueal: 'osqueal',
  osqueol: 'osqueol',
  osquite: 'osquite',
  ossadas: 'ossadas',
  ossamas: 'ossamas',
  ossames: 'óssames',
  ossanas: 'ossanas',
  ossanha: 'ossanha',
  ossaria: 'ossaria',
  ossario: 'ossário',
  osseana: 'osseana',
  osseano: 'osseano',
  osseias: 'osseias',
  osseina: 'osseína',
  ossenas: 'ossenas',
  ossenos: 'ossenos',
  ossetas: 'ossetas',
  ossetos: 'ossetos',
  ossicos: 'ossicos',
  ossidez: 'ossidez',
  ossimas: 'ossimas',
  ossinho: 'ossinho',
  ossobos: 'ossobós',
  ossonhe: 'ossonhe',
  ossosas: 'ossosas',
  ossosos: 'ossosos',
  ossudas: 'ossudas',
  ossudos: 'ossudos',
  ossuosa: 'ossuosa',
  ossuoso: 'ossuoso',
  ostagas: 'ostagas',
  ostagra: 'ostagra',
  ostante: 'ostante',
  ostaxas: 'ostaxas',
  osteide: 'osteíde',
  osteido: 'osteído',
  osteina: 'osteína',
  osteite: 'osteíte',
  osteito: 'osteíto',
  ostemia: 'ostemia',
  ostende: 'ostende',
  ostenta: 'ostenta',
  ostente: 'ostente',
  ostento: 'ostento',
  osteoma: 'osteoma',
  osteons: 'osteons',
  osteose: 'osteose',
  ostexia: 'ostexia',
  ostiaca: 'ostiaca',
  ostiaco: 'ostiaco',
  ostiais: 'ostiais',
  ostinho: 'ostinho',
  ostiolo: 'ostíolo',
  ostites: 'ostites',
  ostomia: 'ostomia',
  ostraco: 'óstraco',
  ostrana: 'ostrana',
  ostrano: 'ostrano',
  ostreal: 'ostreal',
  ostreia: 'ostreia',
  ostrina: 'ostrina',
  ostrino: 'ostrino',
  otaiana: 'otaiana',
  otaiano: 'otaiano',
  otalgia: 'otalgia',
  otantos: 'otantos',
  otaozao: 'otãozão',
  otarias: 'otárias',
  otarios: 'otários',
  otavias: 'otávias',
  otavita: 'otavita',
  oteatea: 'oteátea',
  otelias: 'otélias',
  oteliea: 'otelíea',
  oteniza: 'otenizá',
  otenize: 'otenize',
  otenizo: 'otenizo',
  otesina: 'otesina',
  otesino: 'otesino',
  otiatro: 'otiatro',
  otideas: 'otídeas',
  otidias: 'otídias',
  otidida: 'otídida',
  otidios: 'otídios',
  otilofo: 'otílofo',
  otimate: 'otimate',
  otimiza: 'otimiza',
  otimize: 'otimize',
  otimizo: 'otimizo',
  otinida: 'otínida',
  otitica: 'otítica',
  otitico: 'otítico',
  otitida: 'otítida',
  otobite: 'otobite',
  otociao: 'otocião',
  otocino: 'otócino',
  otocion: 'otócion',
  otofane: 'otófane',
  otofone: 'otofone',
  otofono: 'otofono',
  otoforo: 'otóforo',
  otogipo: 'otogipo',
  otolito: 'otólito',
  otologa: 'otóloga',
  otologo: 'otólogo',
  otomaca: 'otomaca',
  otomaco: 'otomaco',
  otomana: 'otomana',
  otomano: 'otomano',
  otombas: 'otombas',
  otomice: 'otômice',
  otomios: 'otômios',
  otomita: 'otomita',
  otomoes: 'otomões',
  otonias: 'otônias',
  otoques: 'otoques',
  otoquis: 'otóquis',
  ottelia: 'ottélia',
  otuitui: 'otuituí',
  otulose: 'otulose',
  otuques: 'otuqués',
  ouabaio: 'ouabaio',
  oucamos: 'ouçamos',
  oucande: 'ouçandé',
  ouconas: 'ouçonas',
  ougadao: 'ougadão',
  ougadas: 'ougadas',
  ougador: 'ougador',
  ougados: 'ougados',
  ougamos: 'ougamos',
  ougando: 'ougando',
  ougante: 'ougante',
  ougaram: 'ougaram',
  ougarao: 'ougarão',
  ougaras: 'ougarás',
  ougarei: 'ougarei',
  ougarem: 'ougarem',
  ougares: 'ougares',
  ougaria: 'ougaria',
  ougasse: 'ougasse',
  ougaste: 'ougaste',
  ougavam: 'ougavam',
  ougavas: 'ougavas',
  ougueis: 'ougueis',
  ouquias: 'ouquias',
  ouradao: 'ouradão',
  ouradas: 'ouradas',
  ourador: 'ourador',
  ourados: 'ourados',
  ouramas: 'ouramas',
  ouramos: 'ouramos',
  ouranas: 'ouranas',
  ourando: 'ourando',
  ourante: 'ourante',
  ouraram: 'ouraram',
  ourarao: 'ourarão',
  ouraras: 'ourarás',
  ourarei: 'ourarei',
  ourarem: 'ourarem',
  ourares: 'ourares',
  ouraria: 'ouraria',
  ourarmo: 'ourarmo',
  ourasse: 'ourasse',
  ouraste: 'ouraste',
  ouratea: 'ourátea',
  ourates: 'ourates',
  ouravam: 'ouravam',
  ouravas: 'ouravas',
  ourebia: 'ourebia',
  ourecas: 'oureças',
  ouregao: 'ourégão',
  ouregas: 'ouregas',
  ouregos: 'ouregos',
  ourejai: 'ourejai',
  ourejam: 'ourejam',
  ourejar: 'ourejar',
  ourejas: 'ourejas',
  ourejei: 'ourejei',
  ourejem: 'ourejem',
  ourejes: 'ourejes',
  ourejou: 'ourejou',
  ourelai: 'ourelai',
  ourelam: 'ourelam',
  ourelar: 'ourelar',
  ourelas: 'ourelas',
  ourelei: 'ourelei',
  ourelem: 'ourelem',
  oureles: 'oureles',
  ourelou: 'ourelou',
  ouremos: 'ouremos',
  ourense: 'ourense',
  ouresca: 'ouresça',
  ouresce: 'ouresce',
  ouresci: 'ouresci',
  ouresco: 'ouresço',
  ouricai: 'ouriçai',
  ouricam: 'ouriçam',
  ouricar: 'ouriçar',
  ouricas: 'ouriças',
  ouricei: 'ouricei',
  ouricem: 'ouricem',
  ourices: 'ourices',
  ouricos: 'ouriços',
  ouricou: 'ouriçou',
  ourinai: 'ourinai',
  ourinam: 'ourinam',
  ourinar: 'ourinar',
  ourinas: 'ourinas',
  ourincu: 'ourincu',
  ourinei: 'ourinei',
  ourinem: 'ourinem',
  ourines: 'ourines',
  ourinho: 'ourinho',
  ourinol: 'ourinol',
  ourinou: 'ourinou',
  ouripel: 'ouripel',
  ourique: 'ourique',
  ourives: 'ourives',
  ourolos: 'ourolos',
  ouropel: 'ouropel',
  ourreta: 'ourreta',
  ourudas: 'ourudas',
  ourudos: 'ourudos',
  ousadao: 'ousadão',
  ousadas: 'ousadas',
  ousadia: 'ousadia',
  ousador: 'ousador',
  ousados: 'ousados',
  ousamos: 'ousamos',
  ousanca: 'ousança',
  ousando: 'ousando',
  ousante: 'ousante',
  ousaram: 'ousaram',
  ousarao: 'ousarão',
  ousaras: 'ousaras',
  ousarei: 'ousarei',
  ousarem: 'ousarem',
  ousares: 'ousares',
  ousaria: 'ousaria',
  ousarmo: 'ousarmo',
  ousasse: 'ousasse',
  ousaste: 'ousaste',
  ousavam: 'ousavam',
  ousavas: 'ousavas',
  ousavel: 'ousável',
  ousemos: 'ousemos',
  ousiada: 'ousiada',
  ousiado: 'ousiado',
  ousiais: 'ousiais',
  ousiamo: 'ousiamo',
  ousiara: 'ousiara',
  ousiava: 'ousiava',
  ousieis: 'ousieis',
  ousiemo: 'ousiemo',
  outadao: 'outadão',
  outadas: 'outadas',
  outador: 'outador',
  outados: 'outados',
  outamos: 'outamos',
  outando: 'outando',
  outante: 'outante',
  outaram: 'outaram',
  outarao: 'outarão',
  outaras: 'outaras',
  outarei: 'outarei',
  outarem: 'outarem',
  outares: 'outares',
  outaria: 'outaria',
  outarmo: 'outarmo',
  outasse: 'outasse',
  outaste: 'outaste',
  outavai: 'outavai',
  outavam: 'outavam',
  outavar: 'outavar',
  outavas: 'outavas',
  outavei: 'outavei',
  outavem: 'outavem',
  outaves: 'outaves',
  outavou: 'outavou',
  outdoor: 'outdoor',
  outeira: 'outeira',
  outeiro: 'outeiro',
  outemos: 'outemos',
  outivas: 'outivas',
  outonai: 'outonai',
  outonal: 'outonal',
  outonam: 'outonam',
  outonar: 'outonar',
  outonas: 'outonas',
  outonea: 'outoneá',
  outonei: 'outonei',
  outonem: 'outonem',
  outones: 'outones',
  outonos: 'outonos',
  outonou: 'outonou',
  outorga: 'outorga',
  outorgo: 'outorgo',
  outrega: 'outrega',
  outrora: 'outrora',
  outubro: 'outubro',
  ouvenca: 'ouvença',
  ouvente: 'ouvente',
  ouvidao: 'ouvidão',
  ouvidas: 'ouvidas',
  ouvidor: 'ouvidor',
  ouvidos: 'ouvidos',
  ouvieis: 'ouvíeis',
  ouviela: 'ouviela',
  ouvimos: 'ouvimos',
  ouvindo: 'ouvindo',
  ouvinte: 'ouvinte',
  ouviram: 'ouviram',
  ouvirao: 'ouvirão',
  ouviras: 'ouviras',
  ouvirei: 'ouvirei',
  ouvirem: 'ouvirem',
  ouvires: 'ouvires',
  ouviria: 'ouviria',
  ouvirmo: 'ouvirmo',
  ouvisse: 'ouvisse',
  ouviste: 'ouviste',
  ouvisto: 'ouvisto',
  ouvivel: 'ouvível',
  ovacoes: 'ovações',
  ovacona: 'ovaçona',
  ovadela: 'ovadela',
  ovadoes: 'ovadões',
  ovadona: 'ovadona',
  ovadora: 'ovadora',
  ovalada: 'ovalada',
  ovalado: 'ovalado',
  ovalais: 'ovalais',
  ovalamo: 'ovalamo',
  ovalara: 'ovalará',
  ovalava: 'ovalava',
  ovaleis: 'ovaleis',
  ovalemo: 'ovalemo',
  ovaliza: 'ovaliza',
  ovalize: 'ovalize',
  ovalizo: 'ovalizo',
  ovalura: 'ovalura',
  ovalzao: 'ovalzão',
  ovambas: 'ovambas',
  ovambos: 'ovambos',
  ovampas: 'ovampas',
  ovampos: 'ovampos',
  ovantes: 'ovantes',
  ovardes: 'ovardes',
  ovareis: 'ováreis',
  ovaremo: 'ovaremo',
  ovariam: 'ovariam',
  ovarias: 'ovarias',
  ovarica: 'ovárica',
  ovarico: 'ovárico',
  ovarina: 'ovarina',
  ovarino: 'ovarino',
  ovarios: 'ovários',
  ovarite: 'ovarite',
  ovarmos: 'ovarmos',
  ovassem: 'ovassem',
  ovasses: 'ovasses',
  ovastes: 'ovastes',
  ovaveis: 'ováveis',
  ovecate: 'ovecate',
  oveiras: 'oveiras',
  oveiros: 'oveiros',
  ovelhas: 'ovelhas',
  ovelhum: 'ovelhum',
  ovencal: 'ovençal',
  ovencas: 'ovenças',
  ovenite: 'ovenite',
  overita: 'overita',
  oviadas: 'oviadas',
  oviados: 'oviados',
  oviaria: 'oviária',
  oviario: 'oviário',
  ovicela: 'ovicela',
  oviculo: 'ovículo',
  ovideas: 'ovídeas',
  ovideos: 'ovídeos',
  oviduto: 'oviduto',
  ovielas: 'ovielas',
  ovifera: 'ovífera',
  ovifero: 'ovífero',
  ovigena: 'ovígena',
  ovigeno: 'ovígeno',
  ovigera: 'ovígera',
  ovigero: 'ovígero',
  ovilulo: 'ovilulo',
  ovilzao: 'ovilzão',
  ovinhas: 'ovinhas',
  ovinhos: 'ovinhos',
  ovipara: 'ovípara',
  oviparo: 'ovíparo',
  ovisaco: 'ovisaco',
  ovismos: 'ovismos',
  ovistas: 'ovistas',
  ovivora: 'ovívora',
  ovivoro: 'ovívoro',
  ovocito: 'ovócito',
  ovofaga: 'ovófaga',
  ovofago: 'ovófago',
  ovogais: 'ovogais',
  ovoidal: 'ovoidal',
  ovoides: 'ovoides',
  ovomero: 'ovômero',
  ovonita: 'ovonita',
  ovonito: 'ovonito',
  ovotide: 'ovótide',
  ovulada: 'ovulada',
  ovulado: 'ovulado',
  ovulais: 'ovulais',
  ovulamo: 'ovulamo',
  ovulara: 'ovulará',
  ovulava: 'ovulava',
  ovuleis: 'ovuleis',
  ovulemo: 'ovulemo',
  oxacido: 'oxácido',
  oxaguia: 'oxaguiã',
  oxalato: 'oxalato',
  oxalica: 'oxálica',
  oxalico: 'oxálico',
  oxalida: 'oxálida',
  oxalide: 'oxálide',
  oxalila: 'oxalila',
  oxalilo: 'oxalilo',
  oxalita: 'oxalita',
  oxalmas: 'oxalmas',
  oxalmes: 'oxalmes',
  oxalose: 'oxalose',
  oxalufa: 'oxalufã',
  oxamato: 'oxamato',
  oxambim: 'oxambim',
  oxamica: 'oxâmica',
  oxamico: 'oxâmico',
  oxamida: 'oxamida',
  oxamido: 'oxamido',
  oxamina: 'oxamina',
  oxamita: 'oxamita',
  oxamite: 'oxamite',
  oxanilo: 'oxanilo',
  oxantel: 'oxantel',
  oxarita: 'oxarita',
  oxatres: 'oxatres',
  oxazina: 'oxazina',
  oxazois: 'oxazóis',
  oxazole: 'oxazole',
  oxedudu: 'oxedudu',
  oxentes: 'oxentes',
  oxeolea: 'oxeólea',
  oxeoleo: 'oxeóleo',
  oxiafia: 'oxiafia',
  oxianto: 'oxianto',
  oxibafo: 'oxíbafo',
  oxibase: 'oxíbase',
  oxibelo: 'oxibelo',
  oxibias: 'oxíbias',
  oxicana: 'oxicana',
  oxicano: 'oxicano',
  oxicara: 'oxícara',
  oxicera: 'oxícera',
  oxicoco: 'oxicoco',
  oxidada: 'oxidada',
  oxidado: 'oxidado',
  oxidais: 'oxidais',
  oxidamo: 'oxidamo',
  oxidara: 'oxidara',
  oxidase: 'oxidase',
  oxidava: 'oxidava',
  oxideis: 'oxideis',
  oxidemo: 'oxidemo',
  oxidina: 'oxidina',
  oxidios: 'oxídios',
  oxidora: 'oxidora',
  oxidula: 'oxidulá',
  oxidulo: 'oxídulo',
  oxiecos: 'oxíecos',
  oxifilo: 'oxifilo',
  oxigala: 'oxígala',
  oxigaro: 'oxígaro',
  oxigena: 'oxigena',
  oxigene: 'oxigene',
  oxigeno: 'oxigeno',
  oxigiro: 'oxigiro',
  oxigona: 'oxígona',
  oxigono: 'oxígono',
  oxilios: 'oxílios',
  oxilite: 'oxilite',
  oxilito: 'oxílito',
  oxilzao: 'oxilzão',
  oximaco: 'oxímaco',
  oximeis: 'oximéis',
  oximero: 'oxímero',
  oximoro: 'oximoro',
  oxindol: 'oxindol',
  oxinoes: 'oxínoes',
  oxinoto: 'oxinoto',
  oxiones: 'oxíones',
  oxiopes: 'oxiopes',
  oxiopia: 'oxiopia',
  oxipilo: 'oxípilo',
  oxipoda: 'oxípoda',
  oxiporo: 'oxíporo',
  oxirias: 'oxirias',
  oxirris: 'oxírris',
  oxissal: 'oxissal',
  oxissol: 'oxissol',
  oxiteca: 'oxiteca',
  oxitelo: 'oxítelo',
  oxitona: 'oxítona',
  oxitono: 'oxítono',
  oxiuras: 'oxiuras',
  oxiures: 'oxiúres',
  oxiuros: 'oxiúros',
  oxoleas: 'oxóleas',
  oxoleos: 'oxóleos',
  oxonato: 'oxonato',
  oxonica: 'oxônica',
  oxonico: 'oxônico',
  oxonios: 'oxônios',
  oxozone: 'oxozone',
  oxumare: 'oxumarê',
  ozagrel: 'ozagrel',
  ozagres: 'ozagres',
  ozeadao: 'ozeadão',
  ozeadas: 'ozeadas',
  ozeador: 'ozeador',
  ozeados: 'ozeados',
  ozeamos: 'ozeamos',
  ozeando: 'ozeando',
  ozeante: 'ozeante',
  ozearam: 'ozearam',
  ozearao: 'ozearão',
  ozearas: 'ozearas',
  ozearei: 'ozearei',
  ozearem: 'ozearem',
  ozeares: 'ozeares',
  ozearia: 'ozearia',
  ozeasse: 'ozeasse',
  ozeaste: 'ozeaste',
  ozeavam: 'ozeavam',
  ozeavas: 'ozeavas',
  ozeemos: 'ozeemos',
  ozenica: 'ozênica',
  ozenico: 'ozênico',
  ozenosa: 'ozenosa',
  ozenoso: 'ozenoso',
  ozineas: 'ozíneas',
  ozineos: 'ozíneos',
  oznitas: 'oznitas',
  ozobias: 'ozóbias',
  ozodera: 'ozódera',
  ozolemo: 'ozolemo',
  ozomena: 'ozômena',
  ozonada: 'ozonada',
  ozonado: 'ozonado',
  ozonais: 'ozonais',
  ozonamo: 'ozonamo',
  ozonara: 'ozonará',
  ozonava: 'ozonava',
  ozoneis: 'ozoneis',
  ozonemo: 'ozonemo',
  ozonide: 'ozônide',
  ozonido: 'ozônido',
  ozonios: 'ozônios',
  ozoniza: 'ozoniza',
  ozonize: 'ozonize',
  ozonizo: 'ozonizo',
  ozorias: 'ozórias',
  ozoroas: 'ozóroas',
  ozueias: 'ozueias',
  paangas: 'paangas',
  pabolas: 'pabolas',
  pabstia: 'pábstia',
  pabulai: 'pabulai',
  pabulam: 'pabulam',
  pabulao: 'pabulão',
  pabular: 'pabular',
  pabulas: 'pabulas',
  pabulei: 'pabulei',
  pabulem: 'pabulem',
  pabules: 'pabules',
  pabulos: 'pábulos',
  pabulou: 'pabulou',
  pacacao: 'pacação',
  pacacas: 'pacaças',
  pacacos: 'pacaços',
  pacagua: 'paçaguá',
  pacahas: 'pacahás',
  pacaias: 'pacaiás',
  pacaido: 'pacaído',
  pacaios: 'pacaios',
  pacajas: 'pacajás',
  pacalas: 'pacalas',
  pacalho: 'pacalho',
  pacamao: 'pacamão',
  pacamas: 'pacamãs',
  pacapia: 'pacapiá',
  pacapio: 'pacapio',
  pacaras: 'pacarás',
  pacares: 'pacarés',
  pacaria: 'pacaria',
  pacarim: 'pacarim',
  pacasos: 'pacasos',
  pacatas: 'pacatas',
  pacatez: 'pacatez',
  pacatos: 'pacatos',
  pacatus: 'pacatus',
  paceira: 'paceira',
  paceiro: 'paceiro',
  pacejai: 'pacejai',
  pacejam: 'pacejam',
  pacejar: 'pacejar',
  pacejas: 'pacejas',
  pacejei: 'pacejei',
  pacejem: 'pacejem',
  pacejes: 'pacejes',
  pacejou: 'pacejou',
  pacenho: 'pacenho',
  pacense: 'pacense',
  pachaco: 'pachaco',
  pacheis: 'pachéis',
  pachola: 'pachola',
  pachole: 'pachole',
  pacholo: 'pacholo',
  pachoro: 'pachorô',
  pachtos: 'pachtos',
  pachuco: 'pachuco',
  pacites: 'pacites',
  pacmina: 'pacmina',
  pacnoda: 'pacnoda',
  pacobal: 'pacobal',
  pacobas: 'pacobas',
  pacocao: 'paçocão',
  pacocas: 'paçocas',
  pacoles: 'pacolés',
  paconas: 'paçonas',
  pacotao: 'pacotão',
  pacotes: 'pacotes',
  pacoval: 'pacoval',
  pacovao: 'pacovão',
  pacovas: 'pacovas',
  pacovia: 'pacóvia',
  pacovio: 'pacóvio',
  pactada: 'pactada',
  pactado: 'pactado',
  pactais: 'pactais',
  pactamo: 'pactamo',
  pactara: 'pactara',
  pactava: 'pactava',
  pacteai: 'pacteai',
  pactear: 'pactear',
  pacteei: 'pacteei',
  pacteia: 'pacteia',
  pacteie: 'pacteie',
  pacteio: 'pacteio',
  pacteis: 'pacteis',
  pactemo: 'pactemo',
  pacteou: 'pacteou',
  pactolo: 'pactolo',
  pactuai: 'pactuai',
  pactual: 'pactual',
  pactuam: 'pactuam',
  pactuar: 'pactuar',
  pactuas: 'pactuas',
  pactuei: 'pactuei',
  pactuem: 'pactuem',
  pactues: 'pactues',
  pactuou: 'pactuou',
  pacuara: 'pacuará',
  pacubos: 'pacubos',
  pacucus: 'pacuçus',
  pacuera: 'pacuera',
  pacueza: 'pacueza',
  paculas: 'paculas',
  pacumas: 'pacumãs',
  pacunas: 'pacunas',
  pacuris: 'pacuris',
  pacutui: 'pacutuí',
  padagoz: 'padagoz',
  padanes: 'padanes',
  padaria: 'padaria',
  paddock: 'paddock',
  padeada: 'padeada',
  padeado: 'padeado',
  padecam: 'padeçam',
  padecas: 'padeças',
  padecei: 'padecei',
  padecem: 'padecem',
  padecer: 'padecer',
  padeces: 'padeces',
  padeceu: 'padeceu',
  padecia: 'padecia',
  padeias: 'padeias',
  padeira: 'padeira',
  padeiro: 'padeiro',
  padejai: 'padejai',
  padejam: 'padejam',
  padejar: 'padejar',
  padejas: 'padejas',
  padejei: 'padejei',
  padejem: 'padejem',
  padejes: 'padejes',
  padejou: 'padejou',
  padelas: 'padelas',
  padelos: 'padelos',
  padidas: 'padidas',
  padinas: 'padinas',
  padinha: 'padinha',
  padiola: 'padiola',
  padixas: 'padixás',
  padixos: 'padixos',
  padmini: 'padmini',
  padoque: 'padoque',
  padrada: 'padrada',
  padrado: 'padrado',
  padrais: 'padrais',
  padramo: 'padramo',
  padrara: 'padrará',
  padrava: 'padrava',
  padreai: 'padreai',
  padrear: 'padrear',
  padreca: 'padreca',
  padreco: 'padreco',
  padreei: 'padreei',
  padreia: 'padreia',
  padreie: 'padreie',
  padreio: 'padreio',
  padreis: 'padreis',
  padremo: 'padremo',
  padreou: 'padreou',
  padrice: 'padrice',
  padroes: 'padrões',
  padroom: 'padroom',
  paduana: 'paduana',
  paduano: 'paduano',
  paducai: 'paducai',
  paducam: 'paducam',
  paducar: 'paducar',
  paducas: 'paducas',
  paducou: 'paducou',
  padumar: 'padumar',
  paduque: 'paduque',
  paedero: 'paedero',
  paelhas: 'paelhas',
  paelias: 'paélias',
  paellas: 'paellas',
  pafinia: 'pafínia',
  pafiosa: 'pafiosa',
  pafioso: 'pafioso',
  pagadao: 'pagadão',
  pagadas: 'pagadas',
  pagador: 'pagador',
  pagados: 'pagados',
  pagagas: 'pagagas',
  pagaiai: 'pagaiai',
  pagaiam: 'pagaiam',
  pagaiar: 'pagaiar',
  pagaias: 'pagaias',
  pagaiei: 'pagaiei',
  pagaiem: 'pagaiem',
  pagaies: 'pagaies',
  pagaiou: 'pagaiou',
  pagalas: 'pagalas',
  pagamea: 'pagâmea',
  pagamos: 'pagamos',
  paganal: 'paganal',
  pagando: 'pagando',
  paganel: 'paganel',
  pagangu: 'pagangu',
  pagante: 'pagante',
  pagaram: 'pagaram',
  pagarao: 'pagarão',
  pagaras: 'pagaras',
  pagarca: 'pagarca',
  pagarei: 'pagarei',
  pagarem: 'pagarem',
  pagares: 'pagares',
  pagaria: 'pagaria',
  pagarmo: 'pagarmo',
  pagasas: 'págasas',
  pagasse: 'pagasse',
  pagaste: 'pagaste',
  pagavam: 'pagavam',
  pagavas: 'pagavas',
  pagavel: 'pagável',
  pageant: 'pageant',
  pageias: 'pageias',
  pagelas: 'pagelas',
  pagelos: 'pagelos',
  pagicas: 'pagiças',
  pagicos: 'pagiços',
  paginai: 'paginai',
  paginam: 'paginam',
  paginar: 'paginar',
  paginas: 'páginas',
  paginei: 'paginei',
  paginem: 'paginem',
  pagines: 'pagines',
  paginou: 'paginou',
  pagitos: 'pagitos',
  pagodea: 'pagodeá',
  pagodes: 'pagodes',
  pagodos: 'pagodôs',
  pagotem: 'pagotém',
  pagueis: 'pagueis',
  paguemo: 'paguemo',
  pagumas: 'pagumas',
  paguros: 'paguros',
  paiabas: 'paiabas',
  paiacus: 'paiacus',
  paiagua: 'paiaguá',
  paiaias: 'paiaiás',
  paianas: 'paianas',
  paiauru: 'paiauru',
  paicoje: 'paicojé',
  paideia: 'paidéia',
  paidias: 'paídias',
  paienas: 'paienas',
  pailoes: 'pailões',
  pailona: 'pailona',
  paincas: 'painças',
  paincos: 'painços',
  paineis: 'painéis',
  painhos: 'painhos',
  painita: 'painita',
  paiorra: 'paiorra',
  paipuna: 'paipuna',
  pairada: 'pairada',
  pairado: 'pairado',
  pairais: 'pairais',
  pairamo: 'pairamo',
  pairara: 'pairara',
  pairari: 'pairari',
  pairava: 'pairava',
  paireis: 'paireis',
  pairemo: 'pairemo',
  paisana: 'paisana',
  paisano: 'paisano',
  paitina: 'paitina',
  paiuras: 'paiurás',
  paivada: 'paivada',
  paivado: 'paivado',
  paivais: 'paivais',
  paivamo: 'paivamo',
  paivara: 'paivará',
  paivava: 'paivava',
  paiveia: 'paiveia',
  paiveis: 'paiveis',
  paivemo: 'paivemo',
  paivoto: 'paivoto',
  paixoes: 'paixões',
  paixois: 'paixóis',
  paixona: 'paixona',
  paizoes: 'paizões',
  pajades: 'pajades',
  pajanas: 'pajanas',
  pajauri: 'pajauri',
  pajeada: 'pajeada',
  pajeado: 'pajeado',
  pajeais: 'pajeais',
  pajeamo: 'pajeamo',
  pajeara: 'pajeara',
  pajeava: 'pajeava',
  pajeeis: 'pajeeis',
  pajeemo: 'pajeemo',
  pajeiam: 'pajeiam',
  pajeias: 'pajeias',
  pajeiem: 'pajeiem',
  pajeies: 'pajeies',
  pajioes: 'pajiões',
  pajolas: 'pajolas',
  pajonal: 'pajonal',
  pajuari: 'pajuari',
  pajuras: 'pajurás',
  pajurus: 'pajurus',
  palabra: 'palabra',
  palabre: 'palabre',
  palaces: 'palaces',
  palacha: 'palacha',
  palacio: 'palácio',
  paladar: 'paladar',
  paladas: 'paladas',
  palades: 'pálades',
  paladim: 'paladim',
  paladio: 'paládio',
  palados: 'palados',
  palaies: 'palaiês',
  palaios: 'palaios',
  palaita: 'palaíta',
  palamas: 'palamas',
  palamos: 'pálamos',
  palanca: 'palanca',
  palanco: 'palanco',
  palanga: 'palanga',
  palapas: 'palapas',
  palaque: 'palaque',
  palares: 'palares',
  palaria: 'palária',
  palasia: 'palásia',
  palasse: 'palasse',
  palatal: 'palatal',
  palatos: 'palatos',
  palavao: 'palavão',
  palavas: 'palavas',
  palaves: 'palaves',
  palavis: 'pálavis',
  palavra: 'palavra',
  palavre: 'palavre',
  palavro: 'palavro',
  paldrao: 'paldrão',
  paleada: 'paleada',
  paleado: 'paleado',
  paleais: 'paleais',
  paleara: 'paleara',
  paleava: 'paleava',
  palecas: 'palecas',
  palecos: 'palecos',
  paleeis: 'paleeis',
  palefes: 'palefes',
  palegas: 'palegas',
  paleiam: 'paleiam',
  paleias: 'paleias',
  paleica: 'paleica',
  paleico: 'paleico',
  paleiem: 'paleiem',
  paleies: 'paleies',
  paleira: 'paleira',
  paleiro: 'paleiro',
  palejai: 'palejai',
  palejam: 'palejam',
  palejar: 'palejar',
  palejas: 'palejas',
  palejei: 'palejei',
  palejem: 'palejem',
  palejes: 'palejes',
  palejou: 'palejou',
  palemon: 'palêmon',
  palenes: 'palenes',
  paleneu: 'paleneu',
  palense: 'palense',
  palente: 'palente',
  paleola: 'paléola',
  palerma: 'palerma',
  palerme: 'palerme',
  palermo: 'palermo',
  palesta: 'palesta',
  paletai: 'paletai',
  paletam: 'paletam',
  paletar: 'paletar',
  paletas: 'paletas',
  paletea: 'paleteá',
  paletei: 'paletei',
  paletem: 'paletem',
  paletes: 'paletes',
  paletos: 'paletós',
  paletou: 'paletou',
  palhaca: 'palhaça',
  palhaco: 'palhaço',
  palhada: 'palhada',
  palhado: 'palhado',
  palhais: 'palhais',
  palhamo: 'palhamo',
  palhana: 'palhana',
  palhara: 'palhará',
  palhava: 'palhava',
  palheis: 'palheis',
  palhemo: 'palhemo',
  palheta: 'palheta',
  palhete: 'palhete',
  palheto: 'palheto',
  palhica: 'palhiça',
  palhice: 'palhice',
  palhico: 'palhiço',
  palhins: 'palhins',
  palhito: 'palhito',
  palhoca: 'palhoça',
  palhoes: 'palhões',
  palhona: 'palhona',
  palhosa: 'palhosa',
  palhoso: 'palhoso',
  palhota: 'palhota',
  palhote: 'palhote',
  palhuco: 'palhuço',
  paliada: 'paliada',
  paliado: 'paliado',
  paliais: 'paliais',
  paliamo: 'paliamo',
  paliara: 'paliará',
  paliata: 'paliata',
  paliava: 'paliava',
  palibes: 'palibes',
  palicas: 'pálicas',
  palicos: 'pálicos',
  palicur: 'palicur',
  palidal: 'palidal',
  palidas: 'pálidas',
  palidez: 'palidez',
  palidos: 'pálidos',
  palieis: 'palieis',
  paliemo: 'paliemo',
  palilho: 'palilho',
  palilia: 'palília',
  palinha: 'palinha',
  paliolo: 'palíolo',
  palioto: 'palioto',
  palista: 'palista',
  palitai: 'palitai',
  palitam: 'palitam',
  palitar: 'palitar',
  palitas: 'palitas',
  palitei: 'palitei',
  palitem: 'palitem',
  palites: 'palites',
  palitos: 'palitos',
  palitou: 'palitou',
  paliuro: 'paliúro',
  palizas: 'palizas',
  palmada: 'palmada',
  palmado: 'palmado',
  palmais: 'palmais',
  palmale: 'palmale',
  palmamo: 'palmamo',
  palmara: 'palmara',
  palmato: 'palmato',
  palmava: 'palmava',
  palmeai: 'palmeai',
  palmear: 'palmear',
  palmeei: 'palmeei',
  palmeia: 'palmeia',
  palmeie: 'palmeie',
  palmeio: 'palmeio',
  palmeis: 'palmeis',
  palmeja: 'palmejá',
  palmeje: 'palmeje',
  palmejo: 'palmejo',
  palmela: 'palmela',
  palmemo: 'palmemo',
  palmeou: 'palmeou',
  palmeta: 'palmeta',
  palmeto: 'palmeto',
  palmica: 'palmica',
  palmico: 'palmico',
  palmier: 'palmier',
  palmina: 'palmina',
  palmira: 'palmira',
  palmito: 'palmito',
  palmuma: 'palmumá',
  palmura: 'palmura',
  palodes: 'palodes',
  paloias: 'paloias',
  paloios: 'paloios',
  palolos: 'palolos',
  palomai: 'palomai',
  palomam: 'palomam',
  palomar: 'palomar',
  palomas: 'palomas',
  palomba: 'palomba',
  palombe: 'palombe',
  palombo: 'palombo',
  palomei: 'palomei',
  palomem: 'palomem',
  palomes: 'palomes',
  palomou: 'palomou',
  palonca: 'palonça',
  palonco: 'palonço',
  palonga: 'palonga',
  palorai: 'palorai',
  paloram: 'paloram',
  palorar: 'palorar',
  paloras: 'paloras',
  palorei: 'palorei',
  palorem: 'palorem',
  palores: 'palores',
  palorou: 'palorou',
  palotes: 'palotes',
  palpada: 'palpada',
  palpado: 'palpado',
  palpais: 'palpais',
  palpamo: 'palpamo',
  palpara: 'palpará',
  palpava: 'palpava',
  palpeis: 'palpeis',
  palpemo: 'palpemo',
  palpita: 'palpita',
  palpite: 'palpite',
  palpito: 'palpito',
  palpulo: 'pálpulo',
  palpuma: 'palpumá',
  palrada: 'palrada',
  palrado: 'palrado',
  palrais: 'palrais',
  palramo: 'palramo',
  palrara: 'palrará',
  palrava: 'palrava',
  palreai: 'palreai',
  palrear: 'palrear',
  palreas: 'pálreas',
  palreei: 'palreei',
  palreia: 'palreia',
  palreie: 'palreie',
  palreio: 'palreio',
  palreis: 'palreis',
  palremo: 'palremo',
  palreou: 'palreou',
  palrice: 'palrice',
  palroes: 'palrões',
  paltita: 'paltita',
  paludal: 'paludal',
  paludas: 'paludas',
  paludes: 'paludes',
  paludos: 'paludos',
  palumos: 'palumos',
  palurda: 'palurda',
  palurdo: 'palurdo',
  palutas: 'palutas',
  pamanas: 'pamanás',
  pamaris: 'pamaris',
  pambala: 'pambala',
  pamboro: 'pamboro',
  pamelas: 'pâmelas',
  pamoato: 'pamoato',
  pamonas: 'pamonãs',
  pamonha: 'pamonha',
  pampalo: 'pâmpalo',
  pampana: 'pâmpana',
  pampano: 'pâmpano',
  pampilo: 'pampilo',
  pampino: 'pampino',
  pamplos: 'pamplos',
  pampuas: 'pampuãs',
  pamuari: 'pamuari',
  panabas: 'panabas',
  panacai: 'panaçai',
  panacam: 'panaçam',
  panacar: 'panaçar',
  panacas: 'panacas',
  panacei: 'panacei',
  panacem: 'panacém',
  panaces: 'panaces',
  panacha: 'panacha',
  panache: 'panachê',
  panacos: 'panaços',
  panacou: 'panaçou',
  panacum: 'panacum',
  panacus: 'panacus',
  panadao: 'panadão',
  panadas: 'panadas',
  panador: 'panador',
  panados: 'panados',
  panageu: 'panageu',
  panamas: 'panamás',
  panamos: 'panamos',
  panando: 'panando',
  panante: 'panante',
  panaque: 'panaque',
  panaram: 'panaram',
  panarao: 'panarão',
  panaras: 'panarás',
  panarei: 'panarei',
  panarem: 'panarem',
  panares: 'panares',
  panaria: 'panaria',
  panario: 'panário',
  panaris: 'panaris',
  panariz: 'panariz',
  panarmo: 'panarmo',
  panarra: 'panarra',
  panarto: 'panarto',
  panasao: 'panasão',
  panasca: 'panasca',
  panasco: 'panasco',
  panasio: 'panásio',
  panasos: 'panasos',
  panasse: 'panasse',
  panaste: 'panaste',
  panatis: 'panatis',
  panavam: 'panavam',
  panavas: 'panavas',
  panazio: 'panázio',
  panazos: 'panazos',
  pancada: 'pancada',
  pancado: 'pancado',
  pancaia: 'pancaia',
  pancaio: 'pancaio',
  pancaru: 'pancaru',
  panchao: 'panchão',
  pancoes: 'pancões',
  pancuda: 'pançuda',
  pancudo: 'pançudo',
  pandada: 'pandada',
  pandado: 'pandado',
  pandais: 'pandais',
  pandale: 'pandale',
  pandalo: 'pandalo',
  pandamo: 'pandamo',
  pandana: 'pandana',
  pandano: 'pandano',
  pandara: 'pandara',
  pandaro: 'pandaro',
  pandava: 'pandava',
  pandeai: 'pandeai',
  pandear: 'pandear',
  pandeco: 'pandeco',
  pandeei: 'pandeei',
  pandega: 'pândega',
  pandego: 'pândego',
  pandeia: 'pandeia',
  pandeie: 'pandeie',
  pandeio: 'pandeio',
  pandeis: 'pandeis',
  pandelo: 'pandeló',
  pandemo: 'pandemo',
  pandeou: 'pandeou',
  pandeta: 'pandeta',
  pandias: 'pândias',
  pandino: 'pândino',
  pandios: 'pândios',
  pandita: 'pândita',
  pandito: 'pândito',
  pandora: 'pandora',
  pandoro: 'pandoro',
  pandura: 'pandura',
  panduro: 'panduro',
  panedro: 'panedro',
  paneira: 'paneira',
  paneiro: 'paneiro',
  panejai: 'panejai',
  panejam: 'panejam',
  panejao: 'panejão',
  panejar: 'panejar',
  panejas: 'panejas',
  panejei: 'panejei',
  panejem: 'panejem',
  panejes: 'panejes',
  panejou: 'panejou',
  panelao: 'panelão',
  panelas: 'panelas',
  panelos: 'panelos',
  panemas: 'panemas',
  panemos: 'panemos',
  panenta: 'panenta',
  panento: 'panento',
  panenua: 'panenuá',
  panenva: 'panenva',
  paneolo: 'panéolo',
  panetas: 'panetas',
  panetes: 'panetes',
  panfaga: 'pânfaga',
  panfago: 'pânfago',
  panfila: 'pânfila',
  panfilo: 'pânfilo',
  panfoba: 'pânfoba',
  panfobo: 'pânfobo',
  pangada: 'pangada',
  pangado: 'pangado',
  pangaia: 'pangaia',
  pangaie: 'pangaie',
  pangaio: 'pangaio',
  pangais: 'pangais',
  pangala: 'pangala',
  pangara: 'pangará',
  pangare: 'pangaré',
  pangaus: 'pangaus',
  pangava: 'pangava',
  pangeia: 'pangeia',
  pangene: 'pangene',
  pangeno: 'pângeno',
  pangeus: 'pangeus',
  pangiea: 'pangíea',
  pangios: 'pângios',
  pangire: 'pangire',
  pangola: 'pangola',
  panguei: 'panguei',
  panguem: 'panguem',
  pangues: 'pangues',
  panhame: 'panhame',
  panhoes: 'panhões',
  panhota: 'panhota',
  panhote: 'panhote',
  paniame: 'paniame',
  panical: 'panical',
  panicas: 'pânicas',
  panicea: 'panícea',
  paniceo: 'paníceo',
  panicio: 'panício',
  panicos: 'panicos',
  panicum: 'panicum',
  paniega: 'paniega',
  paniego: 'paniego',
  panigau: 'panigau',
  panilas: 'panilas',
  paninha: 'paninha',
  paninho: 'paninho',
  paninos: 'paninos',
  panisco: 'panisco',
  panjabi: 'panjabi',
  panocas: 'panocas',
  panocha: 'panocha',
  panoias: 'panoias',
  panoilo: 'panoilo',
  panonia: 'panônia',
  panonio: 'panônio',
  panopse: 'panopse',
  panores: 'panorés',
  panorpa: 'panorpa',
  panotas: 'panotas',
  panotos: 'panotos',
  panoura: 'panoura',
  panquea: 'panqueá',
  panques: 'panques',
  panqueu: 'panqueu',
  panriai: 'panriai',
  panriam: 'panriam',
  panriar: 'panriar',
  panrias: 'panrias',
  panriei: 'panriei',
  panriem: 'panriem',
  panries: 'panries',
  panriou: 'panriou',
  pantaca: 'pantaça',
  pantais: 'pantais',
  pantala: 'pântala',
  pantana: 'pantana',
  pantano: 'pântano',
  pantara: 'pântara',
  panteai: 'panteai',
  panteao: 'panteão',
  pantear: 'pantear',
  panteas: 'pânteas',
  panteei: 'panteei',
  panteia: 'panteia',
  panteie: 'panteie',
  panteio: 'panteio',
  panteon: 'panteon',
  panteos: 'pânteos',
  panteou: 'panteou',
  pantera: 'pantera',
  panteus: 'panteus',
  pantins: 'pantins',
  pantoea: 'pantoea',
  pantone: 'pantone',
  pantufa: 'pantufa',
  pantufo: 'pantufo',
  pantume: 'pantume',
  pantuna: 'pantuna',
  pantuns: 'pantuns',
  panurea: 'panúrea',
  panurga: 'panurga',
  panurgo: 'panurgo',
  panzuai: 'panzuai',
  panzuam: 'panzuam',
  panzuar: 'panzuar',
  panzuas: 'panzuas',
  panzuei: 'panzuei',
  panzuem: 'panzuem',
  panzues: 'panzues',
  panzuou: 'panzuou',
  papadao: 'papadão',
  papadas: 'papadas',
  papador: 'papador',
  papados: 'papados',
  papagas: 'papagas',
  papagos: 'papagos',
  papaias: 'papaias',
  papaina: 'papaína',
  papaira: 'papaíra',
  papalao: 'papalão',
  papalva: 'papalva',
  papalvo: 'papalvo',
  papamos: 'papamos',
  papanas: 'papanás',
  papanca: 'papança',
  papando: 'papando',
  papangu: 'papangu',
  papante: 'papante',
  paparam: 'paparam',
  paparao: 'paparão',
  paparas: 'paparas',
  paparaz: 'paparaz',
  paparei: 'paparei',
  paparem: 'paparem',
  papares: 'papares',
  paparia: 'paparia',
  paparim: 'paparim',
  paparis: 'paparis',
  paparmo: 'paparmo',
  papasse: 'papasse',
  papaste: 'papaste',
  papatas: 'papatas',
  papataz: 'papataz',
  papavam: 'papavam',
  papavas: 'papavas',
  papavel: 'papável',
  papaver: 'papáver',
  papazes: 'papazes',
  papeada: 'papeada',
  papeado: 'papeado',
  papeais: 'papeais',
  papeara: 'papeará',
  papeata: 'papeata',
  papeava: 'papeava',
  papeeis: 'papeeis',
  papeiam: 'papeiam',
  papeias: 'papeias',
  papeiem: 'papeiem',
  papeies: 'papeies',
  papeira: 'papeira',
  papeiro: 'papeiro',
  papejai: 'papejai',
  papejam: 'papejam',
  papejar: 'papejar',
  papejas: 'papejas',
  papejei: 'papejei',
  papejem: 'papejem',
  papejes: 'papejes',
  papejou: 'papejou',
  papelao: 'papelão',
  papeles: 'papeles',
  papemos: 'papemos',
  papiada: 'papiada',
  papiado: 'papiado',
  papiais: 'papiais',
  papiamo: 'papiamo',
  papiara: 'papiará',
  papiava: 'papiava',
  papieis: 'papieis',
  papiemo: 'papiemo',
  papilar: 'papilar',
  papilas: 'papilas',
  papilha: 'papilha',
  papilho: 'papilho',
  papilio: 'papílio',
  papilos: 'papilos',
  papinha: 'papinha',
  papinho: 'papinho',
  papirea: 'papírea',
  papireo: 'papíreo',
  papiris: 'papiris',
  papiros: 'papiros',
  papisas: 'papisas',
  papismo: 'papismo',
  papista: 'papista',
  papitas: 'papitas',
  papocai: 'papocai',
  papocam: 'papocam',
  papocar: 'papocar',
  papocas: 'papocas',
  papocou: 'papocou',
  papoias: 'papoias',
  papoico: 'papoiço',
  papoila: 'papoila',
  papoile: 'papoile',
  papoilo: 'papoilo',
  papolas: 'papolas',
  papoque: 'papoque',
  paposas: 'paposas',
  paposos: 'paposos',
  papotes: 'papotes',
  papouca: 'papoucá',
  papouco: 'papouço',
  papoula: 'papoula',
  papoule: 'papoule',
  papoulo: 'papoulo',
  paprica: 'páprica',
  papuano: 'papuano',
  papucos: 'papucos',
  papudas: 'papudas',
  papudos: 'papudos',
  papuira: 'papuíra',
  papujai: 'papujai',
  papujam: 'papujam',
  papujar: 'papujar',
  papujas: 'papujas',
  papujei: 'papujei',
  papujem: 'papujem',
  papujes: 'papujes',
  papujou: 'papujou',
  papular: 'papular',
  papulas: 'pápulas',
  papulos: 'pápulos',
  papuses: 'papuses',
  papusso: 'papusso',
  papuzes: 'papuzes',
  paquara: 'paquara',
  paquelo: 'paqueló',
  paquera: 'paquera',
  paquere: 'paquere',
  paquero: 'paquero',
  paqueta: 'paqueta',
  paquete: 'paquete',
  paquife: 'paquife',
  paquima: 'páquima',
  paquina: 'paquina',
  paquino: 'paquino',
  paquito: 'paquito',
  parabao: 'parabão',
  parabem: 'parabém',
  parabia: 'parábia',
  parabus: 'parabus',
  paracai: 'paracaí',
  paracao: 'paração',
  paracme: 'paracme',
  paracua: 'paracuã',
  paradao: 'paradão',
  paradas: 'paradas',
  paradea: 'paradeá',
  parador: 'parador',
  parados: 'parados',
  parafia: 'parafia',
  paragao: 'paragão',
  paragem: 'paragem',
  paragos: 'páragos',
  paragua: 'paraguá',
  paraiba: 'paraíba',
  paraiso: 'paraíso',
  parajas: 'parajás',
  parajus: 'parajus',
  paraleu: 'paraleu',
  paralia: 'parália',
  paralio: 'parálio',
  paralos: 'páralos',
  paralta: 'paralta',
  paramos: 'paramos',
  paranai: 'paranaí',
  paranas: 'paranás',
  paranca: 'parança',
  parando: 'parando',
  paranga: 'paranga',
  parango: 'parango',
  paranho: 'paranho',
  paranji: 'paranji',
  paranos: 'paranos',
  parante: 'parante',
  paranto: 'paranto',
  parapas: 'parapas',
  parapra: 'paraprá',
  paraque: 'paraquê',
  pararam: 'pararam',
  pararao: 'pararão',
  pararas: 'pararas',
  pararei: 'pararei',
  pararem: 'pararem',
  parares: 'parares',
  pararge: 'pararge',
  pararia: 'pararia',
  pararis: 'pararis',
  pararmo: 'pararmo',
  pararus: 'pararus',
  parasca: 'parasca',
  parasol: 'parasol',
  parasse: 'parasse',
  paraste: 'paraste',
  paratis: 'paratis',
  paratoe: 'paratoe',
  parauai: 'parauaí',
  parauas: 'parauás',
  paravai: 'paravaí',
  paravam: 'paravam',
  paravar: 'paravar',
  paravas: 'paravas',
  paravel: 'parável',
  paraver: 'paraver',
  paravoa: 'parávoa',
  parbica: 'parbiça',
  parboes: 'parbões',
  parceai: 'parceai',
  parcear: 'parcear',
  parceei: 'parceei',
  parceia: 'parceia',
  parceie: 'parceie',
  parceio: 'parceio',
  parceis: 'parcéis',
  parcela: 'parcela',
  parcele: 'parcele',
  parcelo: 'parcelo',
  parceou: 'parceou',
  parchas: 'parchas',
  parchea: 'parcheá',
  parches: 'parches',
  parchos: 'parchos',
  parcial: 'parcial',
  pardaca: 'pardaça',
  pardaco: 'pardaço',
  pardada: 'pardada',
  pardado: 'pardado',
  pardais: 'pardais',
  pardala: 'pardala',
  pardale: 'pardale',
  pardalo: 'pardalo',
  pardamo: 'pardamo',
  pardara: 'pardará',
  pardaus: 'pardaus',
  pardava: 'pardava',
  pardeca: 'pardeça',
  pardece: 'pardece',
  pardeci: 'pardeci',
  pardeco: 'pardeço',
  pardeis: 'pardeis',
  pardeja: 'pardeja',
  pardeje: 'pardeje',
  pardejo: 'pardejo',
  pardela: 'pardela',
  pardelo: 'pardelo',
  pardemo: 'pardemo',
  pardesa: 'pardesa',
  pardeus: 'pardeus',
  pardexo: 'pardexo',
  pardoca: 'pardoca',
  pardosa: 'pardosa',
  pardoso: 'pardoso',
  pareada: 'pareada',
  pareado: 'pareado',
  pareais: 'pareais',
  pareamo: 'pareamo',
  pareara: 'pareara',
  pareava: 'pareava',
  parecam: 'pareçam',
  parecas: 'pareças',
  parecei: 'parecei',
  parecem: 'parecem',
  parecer: 'parecer',
  pareces: 'pareces',
  pareceu: 'pareceu',
  parecia: 'parecia',
  parecio: 'parécio',
  parecis: 'parecis',
  paredal: 'paredal',
  paredao: 'paredão',
  paredes: 'paredes',
  paredra: 'paredra',
  paredro: 'paredro',
  pareeis: 'pareeis',
  pareemo: 'pareemo',
  paregle: 'paregle',
  paregum: 'paregum',
  pareiam: 'pareiam',
  pareias: 'pareias',
  pareiem: 'pareiem',
  pareies: 'pareies',
  pareios: 'pareios',
  parejai: 'parejai',
  parejam: 'parejam',
  parejar: 'parejar',
  parejas: 'parejas',
  parejei: 'parejei',
  parejem: 'parejem',
  parejes: 'parejes',
  parejou: 'parejou',
  parelha: 'parelha',
  parelhe: 'parelhe',
  parelho: 'parelho',
  parelio: 'parélio',
  paremia: 'paremia',
  paremos: 'paremos',
  parenta: 'parenta',
  parente: 'parente',
  parento: 'parento',
  pareres: 'pareres',
  parergo: 'parergo',
  pareses: 'páreses',
  paresia: 'paresia',
  paresie: 'paresie',
  paresio: 'paresio',
  pargana: 'parganá',
  pargata: 'pargata',
  pariana: 'pariana',
  pariano: 'pariano',
  pariara: 'pariara',
  pariata: 'pariatã',
  pariato: 'pariato',
  paricao: 'parição',
  paricas: 'paricás',
  paricis: 'paricis',
  paridao: 'paridão',
  paridea: 'parídea',
  parideo: 'parídeo',
  paridor: 'paridor',
  paridos: 'paridos',
  parieis: 'paríeis',
  parifas: 'párifas',
  parimas: 'parimás',
  parimos: 'parimos',
  parinas: 'parinas',
  parindo: 'parindo',
  parinho: 'parinho',
  pariqui: 'pariqui',
  pariram: 'pariram',
  parirao: 'parirão',
  pariras: 'pariras',
  parirei: 'parirei',
  parirem: 'parirem',
  parires: 'parires',
  pariria: 'pariria',
  pariris: 'pariris',
  parirmo: 'parirmo',
  parirus: 'parirus',
  parisia: 'parísia',
  parisio: 'parísio',
  parisis: 'parísis',
  parisse: 'parisse',
  pariste: 'pariste',
  paristo: 'paristo',
  paritas: 'paritás',
  parking: 'parking',
  parlada: 'parlada',
  parlado: 'parlado',
  parlais: 'parlais',
  parlamo: 'parlamo',
  parlara: 'parlará',
  parlava: 'parlava',
  parleis: 'parleis',
  parlemo: 'parlemo',
  parmana: 'parmana',
  parmano: 'parmano',
  parmena: 'parmena',
  parmula: 'pármula',
  parnaso: 'parnaso',
  parnaus: 'parnaus',
  parnoes: 'parnões',
  parnope: 'parnope',
  paroara: 'paroara',
  parocos: 'párocos',
  parodia: 'paródia',
  parodie: 'parodie',
  parodio: 'parodio',
  parodon: 'paródon',
  parodos: 'párodos',
  paroica: 'paroica',
  paroico: 'paroico',
  parolai: 'parolai',
  parolam: 'parolam',
  parolar: 'parolar',
  parolas: 'parolas',
  parolea: 'paroleá',
  parolei: 'parolei',
  parolem: 'parolem',
  paroles: 'paroles',
  parolim: 'parolim',
  parolis: 'parolis',
  parolos: 'parolos',
  parolou: 'parolou',
  paropia: 'paropia',
  paropio: 'parópio',
  paropse: 'paropse',
  paroreu: 'paroreu',
  parotia: 'parótia',
  parpada: 'párpada',
  parpado: 'párpado',
  parpejo: 'parpejo',
  parquea: 'parqueá',
  parques: 'parques',
  parrada: 'parrada',
  parrado: 'parrado',
  parrafa: 'parrafa',
  parrafe: 'parrafe',
  parrafo: 'párrafo',
  parrais: 'parrais',
  parramo: 'parramo',
  parrana: 'parrana',
  parrano: 'parrano',
  parrara: 'parrará',
  parrava: 'parrava',
  parreca: 'parreca',
  parreco: 'parreco',
  parreis: 'parreis',
  parrelo: 'parrelo',
  parremo: 'parremo',
  parrias: 'párrias',
  parrica: 'parrica',
  parrico: 'parrico',
  parrida: 'párrida',
  parroco: 'párroco',
  parrolo: 'parrolo',
  parrona: 'parroná',
  parrone: 'parrone',
  parrono: 'parrono',
  parrosa: 'parrosa',
  parruas: 'parruás',
  parruda: 'parruda',
  parrudo: 'parrudo',
  parruma: 'parruma',
  parsana: 'parsana',
  parsano: 'parsano',
  parseas: 'párseas',
  parseos: 'párseos',
  parsina: 'parsina',
  partais: 'partais',
  partamo: 'partamo',
  parteja: 'parteja',
  parteje: 'parteje',
  partejo: 'partejo',
  partemo: 'partemo',
  parteno: 'parteno',
  partiam: 'partiam',
  partias: 'partias',
  partica: 'pártica',
  partico: 'pártico',
  partida: 'partida',
  partido: 'partido',
  partija: 'partija',
  partimo: 'partimo',
  partina: 'partina',
  partino: 'partino',
  partira: 'partira',
  partita: 'partita',
  partuno: 'partuno',
  partuns: 'partuns',
  partura: 'partura',
  parture: 'parture',
  parturi: 'parturi',
  parturo: 'parturo',
  partusa: 'partusa',
  partuso: 'partuso',
  partzia: 'partzia',
  paruara: 'paruara',
  parucas: 'parucas',
  parucos: 'parucos',
  parulas: 'párulas',
  parulia: 'parúlia',
  parurus: 'parurus',
  parusia: 'parusia',
  parvaja: 'parvajã',
  parvena: 'parvena',
  parvenu: 'parvenu',
  parvice: 'parvice',
  parvoas: 'párvoas',
  parvoes: 'parvões',
  parvuas: 'párvuas',
  parvula: 'párvula',
  parvulo: 'párvulo',
  parvuos: 'párvuos',
  pascada: 'pascada',
  pascais: 'pascais',
  pascamo: 'pasçamo',
  pascaro: 'pascaró',
  pasceis: 'pasceis',
  pascemo: 'pascemo',
  pascera: 'pascerá',
  pasciam: 'pasciam',
  pascias: 'pascias',
  pascida: 'pascida',
  pascido: 'pascido',
  pascigo: 'pascigo',
  pascoai: 'pascoai',
  pascoal: 'pascoal',
  pascoam: 'pascoam',
  pascoar: 'pascoar',
  pascoas: 'páscoas',
  pascoei: 'pascoei',
  pascoel: 'pascoel',
  pascoem: 'pascoem',
  pascoes: 'pascoes',
  pascoou: 'pascoou',
  pasiras: 'pasiras',
  pasires: 'pasires',
  pasitea: 'pasítea',
  pasmada: 'pasmada',
  pasmado: 'pasmado',
  pasmais: 'pasmais',
  pasmamo: 'pasmamo',
  pasmara: 'pasmara',
  pasmava: 'pasmava',
  pasmeis: 'pasmeis',
  pasmemo: 'pasmemo',
  pasmoes: 'pasmões',
  pasmosa: 'pasmosa',
  pasmoso: 'pasmoso',
  paspalo: 'páspalo',
  pasquim: 'pasquim',
  passacu: 'passacu',
  passada: 'passada',
  passado: 'passado',
  passais: 'passais',
  passaja: 'passaja',
  passaje: 'passaje',
  passajo: 'passajo',
  passala: 'passala',
  passalo: 'pássalo',
  passamo: 'passamo',
  passara: 'passará',
  passare: 'passaré',
  passaro: 'pássaro',
  passava: 'passava',
  passeai: 'passeai',
  passear: 'passear',
  passeei: 'passeei',
  passeia: 'passeia',
  passeie: 'passeie',
  passeio: 'passeio',
  passeis: 'passeis',
  passeja: 'passejá',
  passeje: 'passeje',
  passejo: 'passejo',
  passemo: 'passemo',
  passeou: 'passeou',
  passete: 'passete',
  passiva: 'passiva',
  passive: 'passive',
  passivo: 'passivo',
  passoca: 'passoca',
  passola: 'passola',
  passopa: 'passopa',
  passota: 'passota',
  pastada: 'pastada',
  pastado: 'pastado',
  pastais: 'pastais',
  pastamo: 'pastamo',
  pastara: 'pastara',
  pastava: 'pastava',
  pasteis: 'pastéis',
  pasteja: 'pasteja',
  pasteje: 'pasteje',
  pastejo: 'pastejo',
  pastemo: 'pastemo',
  pastios: 'pastios',
  pastora: 'pastora',
  pastore: 'pastore',
  pastoro: 'pastoro',
  pastosa: 'pastosa',
  pastoso: 'pastoso',
  pastura: 'pastura',
  patacao: 'patacão',
  patacas: 'patacas',
  patacho: 'patacho',
  patacoa: 'patacoa',
  patacos: 'patacos',
  patadas: 'patadas',
  patados: 'patados',
  patagao: 'patagão',
  patagas: 'patagãs',
  patagio: 'patágio',
  pataias: 'pataias',
  pataica: 'pataica',
  pataico: 'pataico',
  patalas: 'patalas',
  patalea: 'pataleá',
  patalia: 'patália',
  patalio: 'patálio',
  patalou: 'patalou',
  patamal: 'patamal',
  patamar: 'patamar',
  patamas: 'patamas',
  patamaz: 'patamaz',
  patamos: 'patamos',
  patanas: 'patanas',
  patanau: 'patanau',
  patanes: 'patanes',
  patanha: 'patanha',
  patanhe: 'patanhe',
  patanho: 'patanho',
  patareu: 'pataréu',
  patarra: 'patarra',
  patatas: 'patatás',
  patauas: 'patauás',
  patavar: 'patavar',
  pataxos: 'pataxós',
  patazes: 'patazes',
  pateada: 'pateada',
  pateado: 'pateado',
  pateais: 'pateais',
  pateara: 'pateará',
  pateava: 'pateava',
  patecal: 'patecal',
  patecas: 'patecas',
  patecos: 'patecos',
  pateeis: 'pateeis',
  patefaz: 'patefaz',
  patefez: 'patefez',
  patefiz: 'patefiz',
  pategai: 'pategai',
  pategam: 'pategam',
  pategar: 'pategar',
  pategas: 'pategas',
  pategos: 'pategos',
  pategou: 'pategou',
  pategue: 'pategue',
  pateiam: 'pateiam',
  pateias: 'pateias',
  pateiem: 'pateiem',
  pateies: 'pateies',
  pateira: 'pateira',
  pateiro: 'pateiro',
  patejai: 'patejai',
  patejam: 'patejam',
  patejar: 'patejar',
  patejas: 'patejas',
  patejei: 'patejei',
  patejem: 'patejem',
  patejes: 'patejes',
  patejou: 'patejou',
  patelar: 'patelar',
  patelas: 'patelas',
  patelea: 'patélea',
  patelha: 'patelha',
  patelos: 'patelos',
  patemar: 'patemar',
  patemas: 'patemas',
  patenas: 'pátenas',
  patenca: 'patença',
  patense: 'patense',
  patenta: 'patenta',
  patente: 'patente',
  patento: 'patento',
  pateras: 'páteras',
  pateres: 'pateres',
  paterna: 'paterna',
  paterno: 'paterno',
  patesca: 'patesca',
  patesco: 'patesco',
  patetai: 'patetai',
  patetam: 'patetam',
  patetar: 'patetar',
  patetas: 'patetas',
  patetea: 'pateteá',
  patetei: 'patetei',
  patetem: 'patetem',
  patetes: 'patetes',
  patetou: 'patetou',
  patiada: 'patiada',
  patiado: 'patiado',
  patiais: 'patiais',
  patiamo: 'patiamo',
  patiara: 'patiará',
  patiava: 'patiava',
  paticas: 'páticas',
  paticos: 'páticos',
  patieis: 'patieis',
  patiemo: 'patiemo',
  patifao: 'patifão',
  patifas: 'patifas',
  patifes: 'patifes',
  patigua: 'patiguá',
  patilau: 'patilau',
  patilha: 'patilha',
  patimas: 'patimas',
  patinai: 'patinai',
  patinam: 'patinam',
  patinar: 'patinar',
  patinas: 'patinas',
  patinei: 'patinei',
  patinem: 'patinem',
  patines: 'patines',
  patinga: 'patinga',
  patinha: 'patinha',
  patinhe: 'patinhe',
  patinho: 'patinho',
  patinou: 'patinou',
  patioba: 'patioba',
  patiras: 'patiras',
  patiria: 'patíria',
  patismo: 'patismo',
  pativel: 'patível',
  patocho: 'patocho',
  patoila: 'patoila',
  patoile: 'patoile',
  patoilo: 'patoilo',
  patolai: 'patolai',
  patolam: 'patolam',
  patolar: 'patolar',
  patolas: 'patolas',
  patolei: 'patolei',
  patolem: 'patolem',
  patoles: 'patoles',
  patolou: 'patolou',
  patonha: 'patonha',
  patoral: 'patoral',
  patoras: 'patorás',
  patores: 'patorés',
  patorra: 'patorra',
  patotas: 'patotas',
  patrata: 'patrata',
  patrato: 'patrato',
  patrial: 'patrial',
  patrias: 'pátrias',
  patrida: 'patridã',
  patrios: 'pátrios',
  patriza: 'patriza',
  patrize: 'patrize',
  patrizo: 'patrizo',
  patroai: 'patroai',
  patroam: 'patroam',
  patroar: 'patroar',
  patroas: 'patroas',
  patrobo: 'pátrobo',
  patroei: 'patroei',
  patroem: 'patroem',
  patroes: 'patrões',
  patrois: 'patróis',
  patrola: 'patrola',
  patrole: 'patrole',
  patrolo: 'patrolo',
  patrona: 'patrona',
  patrono: 'patrono',
  patroou: 'patroou',
  patruca: 'patruça',
  patucas: 'patucas',
  patudas: 'patudas',
  patudos: 'patudos',
  patugua: 'patuguá',
  patulas: 'pátulas',
  patulea: 'patuleá',
  patulos: 'pátulos',
  patunos: 'patunos',
  patural: 'patural',
  patures: 'paturés',
  paturis: 'paturis',
  patusca: 'patusca',
  patusco: 'patusco',
  pauates: 'pauatês',
  pauiana: 'pauiana',
  paulada: 'paulada',
  paulado: 'paulado',
  paulais: 'paulais',
  paulama: 'paulama',
  paulamo: 'paulamo',
  paulara: 'paulara',
  paulava: 'paulava',
  pauleis: 'pauleis',
  paulemo: 'paulemo',
  paulias: 'páulias',
  paulica: 'paúlica',
  paulico: 'paúlico',
  paulina: 'paulina',
  paulino: 'paulino',
  paulita: 'paulita',
  paulite: 'paulite',
  paulito: 'paulito',
  paulona: 'paulona',
  paulzao: 'paulzão',
  paumari: 'paumari',
  paurope: 'paurope',
  pauropo: 'páuropo',
  paururo: 'paururo',
  pausada: 'pausada',
  pausado: 'pausado',
  pausais: 'pausais',
  pausamo: 'pausamo',
  pausara: 'pausara',
  pausava: 'pausava',
  pauseas: 'páuseas',
  pauseis: 'pauseis',
  pausemo: 'pausemo',
  pausias: 'páusias',
  paussos: 'paussos',
  pautada: 'pautada',
  pautado: 'pautado',
  pautais: 'pautais',
  pautamo: 'pautamo',
  pautara: 'pautara',
  pautava: 'pautava',
  pauteai: 'pauteai',
  pautear: 'pautear',
  pauteei: 'pauteei',
  pauteia: 'pauteia',
  pauteie: 'pauteie',
  pauteio: 'pauteio',
  pauteis: 'pauteis',
  pautemo: 'pautemo',
  pauteou: 'pauteou',
  pauzama: 'pauzama',
  pauzito: 'pauzito',
  pavames: 'pavames',
  pavanas: 'pavanas',
  pavanos: 'pavanos',
  paveias: 'paveias',
  pavejai: 'pavejai',
  pavejam: 'pavejam',
  pavejar: 'pavejar',
  pavejas: 'pavejas',
  pavejei: 'pavejei',
  pavejem: 'pavejem',
  pavejes: 'pavejes',
  pavejou: 'pavejou',
  pavenas: 'pavenas',
  pavesai: 'pavesai',
  pavesam: 'pavesam',
  pavesar: 'pavesar',
  pavesas: 'pavesas',
  pavesei: 'pavesei',
  pavesem: 'pavesem',
  paveses: 'paveses',
  pavesou: 'pavesou',
  pavetas: 'pavetas',
  pavidas: 'pávidas',
  pavidez: 'pavidez',
  pavidos: 'pávidos',
  pavioes: 'paviões',
  paviola: 'paviola',
  pavonas: 'pavonas',
  pavonea: 'pavoneá',
  pavonia: 'pavônia',
  pavores: 'pavores',
  pavulas: 'pávulas',
  pavulos: 'pávulos',
  pavunas: 'pavunas',
  pavunva: 'pavunva',
  paxaril: 'paxaril',
  paxaris: 'paxaris',
  paxaxos: 'paxaxos',
  paxicas: 'paxicás',
  paxilar: 'paxilar',
  paxilas: 'paxilas',
  paxilos: 'paxilos',
  paxitas: 'paxitas',
  paxiuba: 'paxiúba',
  paxoros: 'paxorôs',
  pazadas: 'pazadas',
  pazeada: 'pazeada',
  pazeado: 'pazeado',
  pazeais: 'pazeais',
  pazeara: 'pazeará',
  pazeava: 'pazeava',
  pazeeis: 'pazeeis',
  pazeiam: 'pazeiam',
  pazeias: 'pazeias',
  pazeiem: 'pazeiem',
  pazeies: 'pazeies',
  pazenda: 'pazenda',
  pazense: 'pazense',
  pazigua: 'paziguá',
  pazigue: 'pazigue',
  paziguo: 'pazíguo',
  pazinha: 'pazinha',
  pazonas: 'pazonas',
  peacoes: 'peações',
  peacona: 'peaçona',
  peadoes: 'peadões',
  peadona: 'peadona',
  peadora: 'peadora',
  peagens: 'peagens',
  pealada: 'pealada',
  pealado: 'pealado',
  pealais: 'pealais',
  pealamo: 'pealamo',
  pealara: 'pealara',
  pealava: 'pealava',
  pealeis: 'pealeis',
  pealemo: 'pealemo',
  pealhas: 'pealhas',
  pealhos: 'pealhos',
  pealita: 'pealita',
  pealite: 'pealite',
  pealzao: 'pealzão',
  peancas: 'peanças',
  peanhas: 'peanhas',
  peanhos: 'peanhos',
  peantes: 'peantes',
  peaozao: 'peãozão',
  pearcas: 'pearças',
  peardes: 'peardes',
  peareis: 'peáreis',
  peariam: 'peariam',
  pearias: 'pearias',
  pearmos: 'pearmos',
  peascas: 'peascas',
  peassem: 'peassem',
  peasses: 'peasses',
  peastes: 'peastes',
  peaveis: 'peáveis',
  pebadas: 'pebadas',
  pebados: 'pebados',
  pebense: 'pebense',
  pebolim: 'pebolim',
  pebrina: 'pebrina',
  pecadao: 'pecadão',
  pecadas: 'pecadas',
  pecador: 'pecador',
  pecados: 'pecados',
  pecamos: 'peçamos',
  pecando: 'pecando',
  pecante: 'pecante',
  pecaram: 'pecaram',
  pecarao: 'pecarão',
  pecaras: 'pecaras',
  pecarei: 'pecarei',
  pecarem: 'pecarem',
  pecares: 'pecares',
  pecaria: 'pecaria',
  pecaris: 'pecaris',
  pecarmo: 'pecarmo',
  pecasse: 'pecasse',
  pecaste: 'pecaste',
  pecavam: 'pecavam',
  pecavas: 'pecavas',
  pecavel: 'pecável',
  pecelho: 'pecelho',
  pecenha: 'pecenha',
  pecenho: 'pecenho',
  pecetas: 'pecetas',
  pechada: 'pechada',
  pechado: 'pechado',
  pechais: 'pechais',
  pechamo: 'pechamo',
  pechara: 'pechara',
  pechava: 'pechava',
  pecheis: 'pecheis',
  pechemo: 'pechemo',
  pechens: 'pechéns',
  pechosa: 'pechosa',
  pechoso: 'pechoso',
  peciles: 'péciles',
  pecilia: 'pecília',
  pecilos: 'pécilos',
  pecinha: 'pecinha',
  peciolo: 'pecíolo',
  pecitas: 'pecitas',
  pecites: 'pecites',
  pecoapa: 'pecoapá',
  peconha: 'peçonha',
  pecoras: 'pécoras',
  pecorea: 'pecoreá',
  pecotes: 'peçotes',
  pectase: 'péctase',
  pectato: 'pectato',
  pectica: 'péctica',
  pectico: 'péctico',
  pectina: 'pectina',
  pectose: 'pectose',
  pecuapa: 'pecuapá',
  peculio: 'pecúlio',
  pecunia: 'pecúnia',
  pedacao: 'pedação',
  pedacos: 'pedaços',
  pedadas: 'pedadas',
  pedados: 'pedados',
  pedagio: 'pedágio',
  pedalai: 'pedalai',
  pedalam: 'pedalam',
  pedalar: 'pedalar',
  pedalas: 'pedalas',
  pedalei: 'pedalei',
  pedalem: 'pedalem',
  pedales: 'pedales',
  pedalia: 'pedália',
  pedalio: 'pedálio',
  pedalou: 'pedalou',
  pedalva: 'pedalva',
  pedalvo: 'pedalvo',
  pedanas: 'pedanas',
  pedanea: 'pedânea',
  pedaneo: 'pedâneo',
  pedanos: 'pedanos',
  pedante: 'pedante',
  pedaria: 'pedaria',
  pedario: 'pedário',
  pedatas: 'pedatas',
  pedatos: 'pedatos',
  pedauca: 'pedauca',
  pedente: 'pedente',
  pederia: 'pedéria',
  pederos: 'péderos',
  pedeses: 'pedeses',
  pedesia: 'pedesia',
  pedetes: 'pedetes',
  pediaca: 'pedíaca',
  pediaco: 'pedíaco',
  pediais: 'pediais',
  pedicai: 'pedicai',
  pedicam: 'pedicam',
  pedicao: 'pedição',
  pedicar: 'pedicar',
  pedicas: 'pedicas',
  pedicel: 'pedicel',
  pedicia: 'pedícia',
  pedicou: 'pedicou',
  pedidas: 'pedidas',
  pedidor: 'pedidor',
  pedidos: 'pedidos',
  pedieas: 'pedíeas',
  pedieis: 'pedíeis',
  pedimos: 'pedimos',
  pedindo: 'pedindo',
  pedinga: 'pedingã',
  pedinha: 'pedinha',
  pedinhe: 'pedinhe',
  pedinho: 'pedinho',
  pedinos: 'pedinos',
  pedinta: 'pedinta',
  pedinte: 'pedinte',
  pedinto: 'pedinto',
  pedioes: 'pediões',
  pedions: 'pédions',
  pediosa: 'pediosa',
  pedioso: 'pedioso',
  pedipes: 'pédipes',
  pedique: 'pedique',
  pediram: 'pediram',
  pedirao: 'pedirão',
  pediras: 'pediras',
  pedirei: 'pedirei',
  pedirem: 'pedirem',
  pedires: 'pedires',
  pediria: 'pediria',
  pedirmo: 'pedirmo',
  pedisse: 'pedisse',
  pediste: 'pediste',
  pedites: 'pédites',
  pedival: 'pedival',
  pedocal: 'pedocal',
  pedoiro: 'pedoiro',
  pedonal: 'pedonal',
  pedouro: 'pedouro',
  pedraca: 'pedraça',
  pedraco: 'pedraço',
  pedrada: 'pedrada',
  pedrado: 'pedrado',
  pedrais: 'pedrais',
  pedramo: 'pedramo',
  pedrara: 'pedrara',
  pedrava: 'pedrava',
  pedreis: 'pedreis',
  pedremo: 'pedremo',
  pedresa: 'pedresa',
  pedreta: 'pedreta',
  pedroes: 'pedrões',
  pedrosa: 'pedrosa',
  pedroso: 'pedroso',
  peeiras: 'peeiras',
  peeiros: 'peeiros',
  peeling: 'peeling',
  pefilos: 'péfilos',
  pegacao: 'pegação',
  pegacha: 'pegacha',
  pegacho: 'pegacho',
  pegadao: 'pegadão',
  pegadas: 'pegadas',
  pegadio: 'pegadio',
  pegador: 'pegador',
  pegados: 'pegados',
  pegamos: 'pegamos',
  peganas: 'péganas',
  pegando: 'pegando',
  peganha: 'peganha',
  peganhe: 'peganhe',
  peganho: 'peganho',
  peganos: 'péganos',
  peganta: 'peganta',
  pegante: 'pegante',
  pegaram: 'pegaram',
  pegarao: 'pegarão',
  pegaras: 'pegaras',
  pegarei: 'pegarei',
  pegarem: 'pegarem',
  pegares: 'pegares',
  pegaria: 'pegaria',
  pegarmo: 'pegarmo',
  pegasea: 'pegásea',
  pegaseo: 'pegáseo',
  pegaseu: 'pegaseu',
  pegasia: 'pegásia',
  pegasos: 'pégasos',
  pegasse: 'pegasse',
  pegaste: 'pegaste',
  pegatas: 'pegatas',
  pegavam: 'pegavam',
  pegavas: 'pegavas',
  pegmina: 'pegmina',
  pegnias: 'pégnias',
  pegomia: 'pegômia',
  peguaba: 'peguaba',
  peguana: 'peguana',
  peguano: 'peguano',
  pegudas: 'pegudas',
  pegudos: 'pegudos',
  pegueis: 'pegueis',
  peguemo: 'peguemo',
  peguial: 'peguial',
  peguias: 'péguias',
  peguira: 'peguira',
  pegulho: 'pegulho',
  pegungo: 'pegungo',
  pegunha: 'pegunha',
  pegunho: 'pegunho',
  pegunta: 'pegunta',
  pegunte: 'pegunte',
  pegunto: 'pegunto',
  pegural: 'pegural',
  peidada: 'peidada',
  peidado: 'peidado',
  peidais: 'peidais',
  peidamo: 'peidamo',
  peidara: 'peidara',
  peidava: 'peidava',
  peideis: 'peideis',
  peidemo: 'peidemo',
  peidoca: 'peidoca',
  peidoes: 'peidões',
  peinaco: 'peinaço',
  peinada: 'peinada',
  peinado: 'peinado',
  peinais: 'peinais',
  peinamo: 'peinamo',
  peinara: 'peinara',
  peinava: 'peinava',
  peindes: 'peindes',
  peineis: 'peineis',
  peinemo: 'peinemo',
  peiotes: 'peiotes',
  peiotle: 'peiotle',
  peiouga: 'peiouga',
  peiraus: 'peiraus',
  peiroes: 'peirões',
  peitaca: 'peitaca',
  peitaco: 'peitaço',
  peitada: 'peitada',
  peitado: 'peitado',
  peitais: 'peitais',
  peitama: 'peitama',
  peitamo: 'peitamo',
  peitara: 'peitara',
  peitava: 'peitava',
  peiteis: 'peiteis',
  peitemo: 'peitemo',
  peitica: 'peitica',
  peitoes: 'peitões',
  peituda: 'peituda',
  peitudo: 'peitudo',
  peituga: 'peituga',
  peiudas: 'peiudas',
  peiudos: 'peiudos',
  peixada: 'peixada',
  peixado: 'peixado',
  peixais: 'peixais',
  peixamo: 'peixamo',
  peixaos: 'peixãos',
  peixara: 'peixara',
  peixava: 'peixava',
  peixeis: 'peixeis',
  peixemo: 'peixemo',
  peixoes: 'peixões',
  peixois: 'peixóis',
  peixota: 'peixota',
  peixote: 'peixote',
  peixoto: 'peixoto',
  pejadao: 'pejadão',
  pejadas: 'pejadas',
  pejador: 'pejador',
  pejados: 'pejados',
  pejamos: 'pejamos',
  pejando: 'pejando',
  pejante: 'pejante',
  pejaram: 'pejaram',
  pejarao: 'pejarão',
  pejaras: 'pejarás',
  pejarei: 'pejarei',
  pejarem: 'pejarem',
  pejares: 'pejares',
  pejaria: 'pejaria',
  pejarmo: 'pejarmo',
  pejasse: 'pejasse',
  pejaste: 'pejaste',
  pejavam: 'pejavam',
  pejavas: 'pejavas',
  pejeira: 'pejeira',
  pejeiro: 'pejeiro',
  pejemos: 'pejemos',
  pejinho: 'pejinho',
  pejorai: 'pejorai',
  pejoram: 'pejoram',
  pejorar: 'pejorar',
  pejoras: 'pejoras',
  pejorei: 'pejorei',
  pejorem: 'pejorem',
  pejores: 'pejores',
  pejorou: 'pejorou',
  pejosas: 'pejosas',
  pejosos: 'pejosos',
  pelacho: 'pelacho',
  pelacil: 'pelacil',
  pelacir: 'pelacir',
  pelacis: 'pelacis',
  peladao: 'peladão',
  peladas: 'peladas',
  pelador: 'pelador',
  pelados: 'pelados',
  pelagao: 'pelagão',
  pelagas: 'pelagãs',
  pelagem: 'pelagem',
  pelagia: 'pelágia',
  pelagio: 'pelágio',
  pelagos: 'pélagos',
  pelagra: 'pelagra',
  pelames: 'pelames',
  pelamis: 'pélamis',
  pelamos: 'pelamos',
  pelanca: 'pelanca',
  pelanco: 'pelanco',
  pelando: 'pelando',
  pelanga: 'pelanga',
  pelante: 'pelante',
  pelaram: 'pelaram',
  pelarao: 'pelarão',
  pelaras: 'pelaras',
  pelarei: 'pelarei',
  pelarem: 'pelarem',
  pelares: 'pelares',
  pelaria: 'pelaria',
  pelarmo: 'pelarmo',
  pelasga: 'pelasga',
  pelasgo: 'pelasgo',
  pelasse: 'pelasse',
  pelaste: 'pelaste',
  pelavam: 'pelavam',
  pelavas: 'pelavas',
  peleada: 'peleada',
  peleado: 'peleado',
  peleais: 'peleais',
  peleana: 'peleana',
  peleano: 'peleano',
  peleara: 'peleará',
  peleava: 'peleava',
  pelecas: 'pelecas',
  pelecha: 'pelecha',
  peleche: 'peleche',
  pelecho: 'pelecho',
  pelecio: 'pelécio',
  pelecos: 'pelecos',
  pelecra: 'pelecra',
  peleeis: 'peleeis',
  pelegas: 'pelegas',
  pelegos: 'pelegos',
  peleiam: 'peleiam',
  peleias: 'peleias',
  peleiem: 'peleiem',
  peleies: 'peleies',
  peleira: 'peleira',
  peleiro: 'peleiro',
  pelejai: 'pelejai',
  pelejam: 'pelejam',
  pelejar: 'pelejar',
  pelejas: 'pelejas',
  pelejei: 'pelejei',
  pelejem: 'pelejem',
  pelejes: 'pelejes',
  pelejou: 'pelejou',
  peleles: 'peleles',
  pelemia: 'pelemia',
  pelemos: 'pelemos',
  pelenes: 'pelenes',
  peleneu: 'peleneu',
  pelense: 'pelense',
  peleoas: 'peleoas',
  peleona: 'peleona',
  peleria: 'peleria',
  peletes: 'peletes',
  pelevis: 'pelevis',
  pelexia: 'peléxia',
  pelicao: 'pelicão',
  pelicas: 'pelicas',
  pelices: 'pelicés',
  pelicha: 'pelicha',
  peliche: 'peliche',
  pelicho: 'pelicho',
  pelicos: 'pelicos',
  pelidai: 'pelidai',
  pelidam: 'pelidam',
  pelidar: 'pelidar',
  pelidas: 'pelidas',
  pelidei: 'pelidei',
  pelidem: 'pelidem',
  pelides: 'pelides',
  pelidna: 'pelidna',
  pelidou: 'pelidou',
  peligna: 'peligna',
  peligno: 'peligno',
  pelinha: 'pelinha',
  pelinho: 'pelinho',
  pelioma: 'pelioma',
  peliose: 'peliose',
  pelitos: 'pelitos',
  pelitre: 'pelitre',
  pelocos: 'pelocos',
  peloira: 'peloira',
  peloiro: 'peloiro',
  pelomes: 'pelomes',
  pelonas: 'pelonas',
  pelonia: 'pelônia',
  pelopes: 'pélopes',
  pelopeu: 'pelopeu',
  pelopio: 'pelópio',
  pelores: 'pelores',
  peloria: 'peloria',
  pelosas: 'pelosas',
  pelosos: 'pelosos',
  pelotao: 'pelotão',
  pelotar: 'pelotar',
  pelotea: 'peloteá',
  pelotes: 'pelotes',
  pelotro: 'pelotro',
  peloura: 'peloura',
  pelouro: 'pelouro',
  pelouse: 'pelouse',
  peltada: 'peltada',
  peltado: 'peltado',
  peltata: 'peltata',
  peltato: 'peltato',
  peltela: 'peltela',
  peltena: 'peltena',
  pelteno: 'pelteno',
  peltope: 'peltope',
  peltres: 'peltres',
  peluche: 'peluche',
  pelucho: 'pelucho',
  pelucia: 'pelúcia',
  peludas: 'peludas',
  peludea: 'peludeá',
  peludos: 'peludos',
  pelugem: 'pelugem',
  pelumba: 'pelumba',
  pelvica: 'pélvica',
  pelvico: 'pélvico',
  pelvina: 'pelvina',
  pelvino: 'pelvino',
  pemanas: 'pemanas',
  pemanos: 'pemanos',
  pembeji: 'pembéji',
  pembera: 'pemberá',
  pembere: 'pembere',
  pembero: 'pembero',
  pembrai: 'pembrai',
  pembram: 'pembram',
  pembrar: 'pembrar',
  pembras: 'pembras',
  pembrei: 'pembrei',
  pembrem: 'pembrem',
  pembres: 'pembres',
  pembrou: 'pembrou',
  pemenos: 'pêmenos',
  pempade: 'pêmpade',
  penacao: 'penação',
  penacea: 'penácea',
  penaceo: 'penáceo',
  penacha: 'penachá',
  penache: 'penache',
  penacho: 'penacho',
  penadao: 'penadão',
  penadas: 'penadas',
  penador: 'penador',
  penados: 'penados',
  penaias: 'penaias',
  penalti: 'pênalti',
  penalty: 'penalty',
  penamar: 'penamar',
  penambi: 'penambi',
  penamos: 'penamos',
  penando: 'penando',
  penante: 'penante',
  penaram: 'penaram',
  penarao: 'penarão',
  penaras: 'penaras',
  penarei: 'penarei',
  penarem: 'penarem',
  penares: 'penares',
  penaria: 'penaria',
  penariz: 'penariz',
  penarmo: 'penarmo',
  penasco: 'penasco',
  penasse: 'penasse',
  penaste: 'penaste',
  penates: 'penates',
  penavam: 'penavam',
  penavas: 'penavas',
  penavel: 'penável',
  pencada: 'pencada',
  pencene: 'pencenê',
  penchas: 'penchas',
  pencine: 'pencinê',
  pencoes: 'pencões',
  pencuda: 'pencuda',
  pencudo: 'pencudo',
  pendais: 'pendais',
  pendamo: 'pendamo',
  pendant: 'pendant',
  pendeis: 'pendeis',
  pendemo: 'pendemo',
  pendera: 'pendera',
  pendiam: 'pendiam',
  pendias: 'pendias',
  pendida: 'pendida',
  pendido: 'pendido',
  pendoai: 'pendoai',
  pendoam: 'pendoam',
  pendoar: 'pendoar',
  pendoas: 'pendoas',
  pendoei: 'pendoei',
  pendoem: 'pendoem',
  pendoes: 'pendões',
  pendola: 'pêndola',
  pendoou: 'pendoou',
  pendora: 'pendora',
  pendore: 'pendore',
  pendoro: 'pendoro',
  pendres: 'pendres',
  pendros: 'pendros',
  pendula: 'pêndula',
  pendule: 'pendule',
  pendulo: 'pêndulo',
  pendura: 'pendura',
  pendure: 'pendure',
  penduro: 'penduro',
  peneana: 'peneana',
  peneano: 'peneano',
  penedal: 'penedal',
  penedas: 'penedas',
  penedia: 'penedia',
  penedio: 'penedio',
  penedos: 'penedos',
  penegos: 'penegos',
  peneias: 'peneias',
  peneira: 'peneira',
  peneire: 'peneire',
  peneiro: 'peneiro',
  penejai: 'penejai',
  penejam: 'penejam',
  penejar: 'penejar',
  penejas: 'penejas',
  penejei: 'penejei',
  penejem: 'penejem',
  penejes: 'penejes',
  penejou: 'penejou',
  penelas: 'penelas',
  penemos: 'penemos',
  penense: 'penense',
  penerue: 'peneruê',
  penetas: 'penetas',
  penetra: 'penetra',
  penetre: 'penetre',
  penetro: 'penetro',
  penfigo: 'pênfigo',
  penguim: 'penguim',
  penhada: 'penhada',
  penhado: 'penhado',
  penhoar: 'penhoar',
  penhora: 'penhora',
  penhore: 'penhore',
  penhoro: 'penhoro',
  peniana: 'peniana',
  peniano: 'peniano',
  penicai: 'penicai',
  penicam: 'penicam',
  penicar: 'penicar',
  penicas: 'penicas',
  penicos: 'penicos',
  penicou: 'penicou',
  penilho: 'penilho',
  peninas: 'peninas',
  peninha: 'peninha',
  penique: 'penique',
  penisca: 'penisca',
  penisco: 'penisco',
  penivel: 'penível',
  penopos: 'pênopos',
  penosas: 'penosas',
  penosos: 'penosos',
  pensada: 'pensada',
  pensado: 'pensado',
  pensais: 'pensais',
  pensamo: 'pensamo',
  pensara: 'pensara',
  pensava: 'pensava',
  penseis: 'penseis',
  pensemo: 'pensemo',
  pensile: 'pênsile',
  pensoes: 'pensões',
  pensoso: 'pensoso',
  pentano: 'pentano',
  penteai: 'penteai',
  pentear: 'pentear',
  penteei: 'penteei',
  penteia: 'penteia',
  penteie: 'penteie',
  penteio: 'penteio',
  penteou: 'penteou',
  pentese: 'pêntese',
  pentila: 'pentila',
  pentodo: 'pêntodo',
  pentoro: 'pentoro',
  pentose: 'pentose',
  penudas: 'penudas',
  penudos: 'penudos',
  penugem: 'penugem',
  penujai: 'penujai',
  penujam: 'penujam',
  penujar: 'penujar',
  penujas: 'penujas',
  penujei: 'penujei',
  penujem: 'penujem',
  penujes: 'penujes',
  penujou: 'penujou',
  penulas: 'pénulas',
  penulos: 'pénulos',
  penuria: 'penúria',
  peoadas: 'peoadas',
  peoagem: 'peoagem',
  peonada: 'peonada',
  peonias: 'peônias',
  peonina: 'peonina',
  peonois: 'peonóis',
  peopaia: 'peopaia',
  pepasmo: 'pepasmo',
  pepecas: 'pepecas',
  pepeles: 'pepelés',
  pepeuas: 'pepeúas',
  pepevas: 'pepevas',
  pepicas: 'pepiças',
  pepinai: 'pepinai',
  pepinal: 'pepinal',
  pepinam: 'pepinam',
  pepinao: 'pepinão',
  pepinar: 'pepinar',
  pepinas: 'pepinas',
  pepinei: 'pepinei',
  pepinem: 'pepinem',
  pepines: 'pepines',
  pepinos: 'pepinos',
  pepinou: 'pepinou',
  pepione: 'pepione',
  pepiras: 'pepiras',
  pepitas: 'pepitas',
  peplida: 'péplida',
  pepluns: 'pepluns',
  pepoaca: 'pepoaça',
  pepoaza: 'pepoaza',
  pepolim: 'pepolim',
  peponio: 'pepônio',
  pepsias: 'pepsias',
  pepsica: 'pépsica',
  pepsico: 'pépsico',
  pepsina: 'pepsina',
  peptase: 'peptase',
  peptica: 'péptica',
  peptico: 'péptico',
  peptide: 'péptide',
  peptido: 'péptido',
  peptina: 'peptina',
  peptiza: 'peptiza',
  peptize: 'peptize',
  peptizo: 'peptizo',
  peptona: 'peptona',
  pepuaca: 'pepuaça',
  pepuira: 'pepuíra',
  pepulim: 'pepulim',
  pepuxis: 'pepuxis',
  pequapa: 'pequapá',
  pequeai: 'pequeai',
  pequear: 'pequear',
  pequeei: 'pequeei',
  pequeia: 'pequeia',
  pequeie: 'pequeie',
  pequeio: 'pequeio',
  pequeis: 'pequeis',
  pequemo: 'pequemo',
  pequena: 'pequena',
  pequeno: 'pequeno',
  pequeou: 'pequeou',
  pequias: 'pequiás',
  pequice: 'pequice',
  pequins: 'pequins',
  pequira: 'pequira',
  pequisa: 'pequisa',
  pequito: 'pequito',
  peradas: 'peradas',
  perados: 'perados',
  peragra: 'peragrá',
  peragre: 'peragre',
  peragro: 'peragro',
  peralta: 'peralta',
  peralte: 'peralte',
  peralto: 'peralto',
  peramas: 'peramas',
  peramis: 'pêramis',
  perante: 'perante',
  perauta: 'perauta',
  peravas: 'peravas',
  perbuna: 'perbuna',
  percada: 'percada',
  percado: 'percado',
  percais: 'percais',
  percale: 'percale',
  percamo: 'percamo',
  percara: 'percará',
  percava: 'percava',
  perceba: 'perceba',
  percebe: 'percebe',
  percebi: 'percebi',
  percebo: 'percebo',
  perceve: 'perceve',
  perchai: 'perchai',
  percham: 'percham',
  perchar: 'perchar',
  perchas: 'perchas',
  perchei: 'perchei',
  perchem: 'perchem',
  perches: 'perches',
  perchou: 'perchou',
  percida: 'pércida',
  percode: 'percode',
  percola: 'percola',
  percole: 'percole',
  percolo: 'percolo',
  percuda: 'percuda',
  percude: 'percude',
  percudi: 'percudi',
  percudo: 'percudo',
  percuta: 'percuta',
  percute: 'percute',
  percuti: 'percuti',
  percuto: 'percuto',
  perdeis: 'perdeis',
  perdemo: 'perdemo',
  perdera: 'perderá',
  perdiam: 'perdiam',
  perdias: 'perdias',
  perdida: 'perdida',
  perdido: 'perdido',
  perdiga: 'perdiga',
  perdigo: 'perdigo',
  perdita: 'perdita',
  perdoai: 'perdoai',
  perdoam: 'perdoam',
  perdoar: 'perdoar',
  perdoas: 'perdoas',
  perdoei: 'perdoei',
  perdoem: 'perdoem',
  perdoes: 'perdoes',
  perdoou: 'perdoou',
  perdudo: 'perdudo',
  perdura: 'perdura',
  perdure: 'perdure',
  perduro: 'perduro',
  perebas: 'perebas',
  perecam: 'pereçam',
  perecas: 'pereças',
  perecei: 'perecei',
  perecem: 'perecem',
  perecer: 'perecer',
  pereces: 'pereces',
  pereceu: 'pereceu',
  perecia: 'perecia',
  pereion: 'pereion',
  pereira: 'pereira',
  pereiro: 'pereiro',
  perelio: 'perélio',
  peremas: 'peremas',
  perenal: 'perenal',
  perenes: 'perenes',
  perenga: 'perenga',
  pereons: 'peréons',
  pereque: 'perequê',
  pereres: 'pererés',
  perevas: 'perevas',
  perexis: 'perexis',
  perezia: 'perézia',
  perfaca: 'perfaça',
  perfaco: 'perfaço',
  perfara: 'perfará',
  perfaze: 'perfaze',
  perfiai: 'perfiai',
  perfiam: 'perfiam',
  perfiar: 'perfiar',
  perfias: 'perfias',
  perfida: 'pérfida',
  perfido: 'pérfido',
  perfiei: 'perfiei',
  perfiem: 'perfiem',
  perfies: 'perfies',
  perfila: 'perfila',
  perfile: 'perfile',
  perfilo: 'perfilo',
  perfiou: 'perfiou',
  perfixo: 'perfixo',
  perfuma: 'perfuma',
  perfume: 'perfume',
  perfumo: 'perfumo',
  perfura: 'perfura',
  perfure: 'perfure',
  perfuro: 'perfuro',
  perfusa: 'perfusa',
  perfuso: 'perfuso',
  pergeia: 'pergeia',
  pergeus: 'pergeus',
  pergola: 'pérgola',
  pergula: 'pérgula',
  periaca: 'periaca',
  periata: 'periatã',
  periati: 'periati',
  periato: 'periato',
  pericas: 'pericas',
  periche: 'periche',
  pericia: 'perícia',
  pericie: 'pericie',
  pericio: 'pericio',
  pericom: 'pericom',
  pericos: 'pericos',
  peridas: 'peridás',
  peridea: 'perídea',
  peridio: 'perídio',
  perieco: 'perieco',
  perifos: 'périfos',
  perigai: 'perigai',
  perigam: 'perigam',
  perigar: 'perigar',
  perigas: 'perigas',
  perigeu: 'perigeu',
  perigos: 'perigos',
  perigou: 'perigou',
  perigue: 'perigue',
  perilas: 'perilas',
  perilha: 'perilha',
  perilla: 'perilla',
  perilos: 'perilos',
  perimam: 'perimam',
  perimao: 'perimão',
  perimas: 'perimas',
  perimem: 'perimem',
  perimes: 'perimes',
  perimia: 'perimia',
  perimir: 'perimir',
  perimis: 'perimis',
  perimiu: 'perimiu',
  perinas: 'perinas',
  perinde: 'perinde',
  perinea: 'perínea',
  perineo: 'períneo',
  perineu: 'perineu',
  perinha: 'perinha',
  perinho: 'perinho',
  periodo: 'período',
  periplo: 'périplo',
  perisca: 'perisca',
  peritai: 'peritai',
  peritam: 'peritam',
  peritar: 'peritar',
  peritas: 'peritas',
  peritei: 'peritei',
  peritem: 'peritem',
  perites: 'perites',
  peritos: 'peritos',
  peritou: 'peritou',
  perizeu: 'perizeu',
  perjura: 'perjura',
  perjure: 'perjure',
  perjuro: 'perjuro',
  perlada: 'perlada',
  perlado: 'perlado',
  perlais: 'perlais',
  perlamo: 'perlamo',
  perlara: 'perlara',
  perlava: 'perlava',
  perlave: 'perlave',
  perlavo: 'perlavo',
  perleis: 'perleis',
  perleja: 'perlejá',
  perleje: 'perleje',
  perlejo: 'perlejo',
  perlemo: 'perlemo',
  perlica: 'perlica',
  perlico: 'perlico',
  perlita: 'perlita',
  perlite: 'perlite',
  perlito: 'perlito',
  perlons: 'perlons',
  perluis: 'perluís',
  perluiz: 'perluiz',
  perluxo: 'perluxo',
  perluza: 'perluza',
  perluze: 'perluze',
  perluzi: 'perluzi',
  perluzo: 'perluzo',
  permeai: 'permeai',
  permear: 'permear',
  permeei: 'permeei',
  permeia: 'permeia',
  permeie: 'permeie',
  permeio: 'permeio',
  permeou: 'permeou',
  permica: 'pérmica',
  permico: 'pérmico',
  permita: 'permita',
  permite: 'permite',
  permiti: 'permiti',
  permito: 'permito',
  permuda: 'permuda',
  permude: 'permude',
  permudo: 'permudo',
  permuta: 'permuta',
  permute: 'permute',
  permuto: 'permuto',
  pernaca: 'pernaça',
  pernaco: 'pernaço',
  pernada: 'pernada',
  pername: 'pername',
  perneai: 'perneai',
  pernear: 'pernear',
  perneas: 'pérneas',
  perneei: 'perneei',
  perneia: 'perneia',
  perneie: 'perneie',
  perneio: 'perneio',
  perneja: 'perneja',
  perneje: 'perneje',
  pernejo: 'pernejo',
  perneou: 'perneou',
  perneta: 'perneta',
  pernins: 'pernins',
  pernoca: 'pernoca',
  pernoes: 'pernões',
  pernona: 'pernona',
  pernuca: 'pernuca',
  pernuda: 'pernuda',
  pernudo: 'pernudo',
  perobal: 'perobal',
  perobas: 'perobas',
  perobea: 'perobeá',
  perogis: 'perogis',
  peroide: 'peroide',
  perolai: 'perolai',
  perolam: 'perolam',
  perolar: 'perolar',
  perolas: 'pérolas',
  perolei: 'perolei',
  perolem: 'perolem',
  peroles: 'peroles',
  perolou: 'perolou',
  peroneo: 'peróneo',
  peroneu: 'peroneu',
  peronia: 'perónia',
  peronio: 'perônio',
  perorai: 'perorai',
  peroral: 'peroral',
  peroram: 'peroram',
  perorar: 'perorar',
  peroras: 'peroras',
  perorei: 'perorei',
  perorem: 'perorem',
  perores: 'perores',
  perorou: 'perorou',
  perorsa: 'perorsa',
  perorso: 'perorso',
  perosas: 'perosas',
  peroses: 'peroses',
  perotas: 'perotas',
  perotes: 'perotes',
  perotis: 'perótis',
  perovas: 'perovas',
  perozil: 'perozil',
  perozis: 'perozis',
  perpaus: 'perpaus',
  perpoem: 'perpoém',
  perquei: 'perquei',
  perquem: 'perquem',
  perques: 'perques',
  perrada: 'perrada',
  perreai: 'perreai',
  perrear: 'perrear',
  perreba: 'perreba',
  perrebo: 'perrebo',
  perreei: 'perreei',
  perreia: 'perreia',
  perreie: 'perreie',
  perreio: 'perreio',
  perreou: 'perreou',
  perrica: 'perrica',
  perrice: 'perrice',
  perrico: 'perrico',
  perruca: 'perruca',
  perruma: 'perruma',
  perruna: 'perruna',
  perruns: 'perruns',
  persais: 'persais',
  perseas: 'perseas',
  persego: 'pêrsego',
  perseia: 'perseia',
  perseos: 'pérseos',
  persias: 'pérsias',
  persica: 'pérsica',
  persico: 'pérsico',
  persiga: 'persiga',
  persigo: 'persigo',
  persina: 'persina',
  persios: 'pérsios',
  persona: 'persona',
  pertica: 'pértica',
  pertiga: 'pértiga',
  pertigo: 'pértigo',
  pertina: 'pertina',
  pertine: 'pertine',
  pertita: 'pertita',
  pertite: 'pertite',
  pertual: 'pertual',
  pertugo: 'pértugo',
  pertusa: 'pertusa',
  pertuso: 'pertuso',
  peruada: 'peruada',
  peruado: 'peruado',
  peruais: 'peruais',
  peruamo: 'peruamo',
  peruana: 'peruana',
  peruano: 'peruano',
  peruara: 'peruará',
  peruava: 'peruava',
  perucas: 'perucas',
  perudas: 'perudas',
  perueis: 'perueis',
  peruemo: 'peruemo',
  perulas: 'pérulas',
  perunca: 'perunca',
  pervada: 'pervada',
  pervade: 'pervade',
  pervadi: 'pervadi',
  pervado: 'pervado',
  pervaga: 'pervaga',
  pervage: 'pervage',
  pervago: 'pervago',
  pervais: 'pervais',
  perveis: 'perveis',
  pervemo: 'pervemo',
  pervera: 'pervera',
  perviam: 'perviam',
  pervias: 'pérvias',
  pervida: 'pervida',
  pervido: 'pervido',
  pervios: 'pérvios',
  perxina: 'perxina',
  pesadao: 'pesadão',
  pesadas: 'pesadas',
  pesadez: 'pesadez',
  pesador: 'pesador',
  pesados: 'pesados',
  pesagem: 'pesagem',
  pesames: 'pêsames',
  pesamos: 'pesamos',
  pesando: 'pesando',
  pesante: 'pesante',
  pesaram: 'pesaram',
  pesarao: 'pesarão',
  pesaras: 'pesaras',
  pesarei: 'pesarei',
  pesarem: 'pesarem',
  pesares: 'pesares',
  pesaria: 'pesaria',
  pesarmo: 'pesarmo',
  pesasse: 'pesasse',
  pesaste: 'pesaste',
  pesavam: 'pesavam',
  pesavas: 'pesavas',
  pesavel: 'pesável',
  pescada: 'pescada',
  pescado: 'pescado',
  pescais: 'pescais',
  pescamo: 'pescamo',
  pescara: 'pescara',
  pescava: 'pescava',
  pescoco: 'pescoço',
  pescota: 'pescota',
  pesebre: 'pesebre',
  pesemos: 'pesemos',
  pesetas: 'pesetas',
  pesgada: 'pesgada',
  pesgado: 'pesgado',
  pesgais: 'pesgais',
  pesgara: 'pesgará',
  pesgava: 'pesgava',
  pesguei: 'pesguei',
  pesguem: 'pesguem',
  pesgues: 'pesgues',
  pesicas: 'pésicas',
  pesicos: 'pésicos',
  pesinho: 'pesinho',
  pesonas: 'pesonas',
  pespega: 'pespega',
  pespego: 'pespego',
  pespita: 'pespita',
  pesquei: 'pesquei',
  pesquem: 'pesquem',
  pesques: 'pesques',
  pessach: 'pessach',
  pessega: 'pêssega',
  pessego: 'pêssego',
  pessima: 'péssima',
  pessimo: 'péssimo',
  pessoal: 'pessoal',
  pessoas: 'pessoas',
  pestana: 'pestana',
  pestano: 'pestano',
  pesteai: 'pesteai',
  pestear: 'pestear',
  pesteei: 'pesteei',
  pesteia: 'pesteia',
  pesteie: 'pesteie',
  pesteio: 'pesteio',
  pesteou: 'pesteou',
  pestilo: 'pestilo',
  pestiva: 'pestiva',
  pestivo: 'pestivo',
  pestosa: 'pestosa',
  pestoso: 'pestoso',
  pesumes: 'pesumes',
  pesunho: 'pesunho',
  pesuras: 'pésuras',
  pesures: 'pésures',
  pesuros: 'pésuros',
  petacno: 'petacno',
  petadao: 'petadão',
  petadas: 'petadas',
  petador: 'petador',
  petados: 'petados',
  petalas: 'pétalas',
  petalio: 'petálio',
  petamos: 'petamos',
  petando: 'petando',
  petante: 'petante',
  petaram: 'petaram',
  petarao: 'petarão',
  petaras: 'petarás',
  petarda: 'petarda',
  petarde: 'petarde',
  petardo: 'petardo',
  petarei: 'petarei',
  petarem: 'petarem',
  petares: 'petares',
  petaria: 'petaria',
  petarmo: 'petarmo',
  petasse: 'petasse',
  petaste: 'petaste',
  petauro: 'petauro',
  petavam: 'petavam',
  petavas: 'petavas',
  peteada: 'peteada',
  peteado: 'peteado',
  peteais: 'peteais',
  peteara: 'peteará',
  peteava: 'peteava',
  petecai: 'petecai',
  petecam: 'petecam',
  petecar: 'petecar',
  petecas: 'petecas',
  petecou: 'petecou',
  peteeis: 'peteeis',
  petefes: 'petefes',
  petegai: 'petegai',
  petegam: 'petegam',
  petegar: 'petegar',
  petegas: 'petegas',
  petegou: 'petegou',
  petegue: 'petegue',
  peteiam: 'peteiam',
  peteias: 'peteias',
  peteiem: 'peteiem',
  peteies: 'peteies',
  peteira: 'peteira',
  peteiro: 'peteiro',
  petemas: 'petemas',
  petemos: 'petemos',
  petenia: 'petênia',
  peteque: 'peteque',
  peticao: 'petição',
  peticar: 'peticar',
  peticas: 'petiças',
  peticia: 'petícia',
  peticos: 'petiços',
  petigma: 'petigma',
  petilha: 'petilhá',
  petilhe: 'petilhe',
  petilho: 'petilho',
  petimas: 'petimas',
  petinga: 'petinga',
  petinha: 'petinha',
  petinho: 'petinho',
  petipes: 'petipés',
  petique: 'petique',
  petisca: 'petisca',
  petisco: 'petisco',
  petismo: 'petismo',
  petista: 'petista',
  petitai: 'petitai',
  petitam: 'petitam',
  petitar: 'petitar',
  petitas: 'petitas',
  petitei: 'petitei',
  petitem: 'petitem',
  petites: 'petites',
  petitor: 'petitor',
  petitou: 'petitou',
  petizas: 'petizas',
  petizes: 'petizes',
  petorra: 'petorra',
  petrais: 'petrais',
  petreas: 'pétreas',
  petreca: 'petreca',
  petreco: 'petreco',
  petreia: 'petreia',
  petreis: 'petréis',
  petreos: 'pétreos',
  petreus: 'petreus',
  petrina: 'petrina',
  petrino: 'petrino',
  petrins: 'petrins',
  petrite: 'petrite',
  petrosa: 'petrosa',
  petrose: 'petrose',
  petroso: 'petroso',
  petumbo: 'petumbo',
  petumes: 'petumes',
  petunce: 'petuncé',
  petunga: 'petunga',
  petunia: 'petúnia',
  petunse: 'petunse',
  peucena: 'peucena',
  peuceno: 'peuceno',
  peucina: 'peucina',
  peucino: 'peucino',
  peugada: 'peugada',
  peulbes: 'peúlbes',
  peulzao: 'peulzão',
  peuvais: 'peuvais',
  pevidas: 'pevidas',
  pevides: 'pevides',
  pevinha: 'pevinha',
  pevonas: 'pevonas',
  pexelim: 'pexelim',
  pexetis: 'pexetis',
  pexicas: 'péxicas',
  pexorim: 'pexorim',
  pexotai: 'pexotai',
  pexotam: 'pexotam',
  pexotar: 'pexotar',
  pexotas: 'pexotas',
  pexotea: 'pexoteá',
  pexotei: 'pexotei',
  pexotem: 'pexotem',
  pexotes: 'pexotes',
  pexotou: 'pexotou',
  pezadas: 'pezadas',
  pezanha: 'pezanha',
  pezanho: 'pezanho',
  pezenha: 'pezenha',
  pezenho: 'pezenho',
  pezinho: 'pezinho',
  pezizas: 'pezizas',
  pezobio: 'pezóbio',
  pezorro: 'pezorro',
  pezudas: 'pezudas',
  pezudos: 'pezudos',
  pezunha: 'pezunhá',
  pezunhe: 'pezunhe',
  pezunho: 'pezunho',
  pfucada: 'pfucada',
  pfucado: 'pfucado',
  pfucais: 'pfucais',
  pfucamo: 'pfucamo',
  pfucara: 'pfucará',
  pfucava: 'pfucava',
  pfuquei: 'pfuquei',
  pfuquem: 'pfuquem',
  pfuques: 'pfuques',
  piabada: 'piabada',
  piabado: 'piabado',
  piabais: 'piabais',
  piabamo: 'piabamo',
  piabara: 'piabará',
  piabava: 'piabava',
  piabeis: 'piabeis',
  piabemo: 'piabemo',
  piabonu: 'piabonu',
  piabuca: 'piabuca',
  piabuco: 'piabuco',
  piabucu: 'piabuçu',
  piacaba: 'piaçaba',
  piacava: 'piaçava',
  piachai: 'piachai',
  piacham: 'piacham',
  piachar: 'piachar',
  piachas: 'piachas',
  piachei: 'piachei',
  piachem: 'piachem',
  piaches: 'piaches',
  piachos: 'piachos',
  piachou: 'piachou',
  piacoca: 'piaçoca',
  piacoes: 'piações',
  piacona: 'piaçona',
  piaculo: 'piáculo',
  piadade: 'piadade',
  piadela: 'piadela',
  piadita: 'piadita',
  piadoes: 'piadões',
  piadona: 'piadona',
  piadora: 'piadora',
  piadoso: 'piadoso',
  piafada: 'piafada',
  piafado: 'piafado',
  piafais: 'piafais',
  piafamo: 'piafamo',
  piafara: 'piafará',
  piafava: 'piafava',
  piafeis: 'piafeis',
  piafemo: 'piafemo',
  piaiuva: 'piaiuva',
  pialada: 'pialada',
  pialado: 'pialado',
  pialais: 'pialais',
  pialamo: 'pialamo',
  pialara: 'pialará',
  pialava: 'pialava',
  pialeis: 'pialeis',
  pialemo: 'pialemo',
  pialhai: 'pialhai',
  pialham: 'pialham',
  pialhar: 'pialhar',
  pialhas: 'pialhas',
  pialhei: 'pialhei',
  pialhem: 'pialhem',
  pialhes: 'pialhes',
  pialhou: 'pialhou',
  pialzao: 'pialzão',
  piambas: 'piambas',
  piambre: 'piambre',
  piancai: 'piançai',
  piancam: 'piançam',
  piancar: 'piançar',
  piancas: 'pianças',
  piancei: 'piancei',
  piancem: 'piancem',
  piances: 'piances',
  piancou: 'piançou',
  pianice: 'pianice',
  pianino: 'pianino',
  pianiza: 'pianizá',
  pianize: 'pianize',
  pianizo: 'pianizo',
  pianola: 'pianola',
  pianole: 'pianole',
  pianolo: 'pianolo',
  pianoma: 'pianoma',
  piantes: 'piantes',
  piaozao: 'piãozão',
  piapara: 'piapara',
  piapias: 'piápias',
  piarcas: 'piarças',
  piardas: 'piardas',
  piardes: 'piardes',
  piareis: 'piareis',
  piaremo: 'piaremo',
  piariam: 'piariam',
  piarias: 'piarias',
  piarmos: 'piarmos',
  piaroas: 'piaroas',
  piartro: 'piartro',
  piascas: 'piascas',
  piascos: 'piascos',
  piassem: 'piassem',
  piasses: 'piasses',
  piastas: 'piastas',
  piastes: 'piastes',
  piastra: 'piastra',
  piauacu: 'piauaçu',
  piaucus: 'piauçus',
  piaveis: 'piáveis',
  piavuna: 'piavuna',
  piazada: 'piazada',
  piazita: 'piazita',
  piazote: 'piazote',
  pibrete: 'pibrete',
  picacas: 'picaças',
  picacea: 'picácea',
  picaceo: 'picáceo',
  picacho: 'picacho',
  picacos: 'picaços',
  picacus: 'picaçus',
  picadao: 'picadão',
  picadas: 'picadas',
  picador: 'picador',
  picados: 'picados',
  picagem: 'picagem',
  picains: 'picains',
  picalva: 'picalva',
  picalvo: 'picalvo',
  picamar: 'picamar',
  picamos: 'picamos',
  picanas: 'picanas',
  picanca: 'picanca',
  picanco: 'picanço',
  picando: 'picando',
  picanea: 'picaneá',
  picanha: 'picanha',
  picante: 'picante',
  picapes: 'picapes',
  picaram: 'picaram',
  picarao: 'picarão',
  picaras: 'picaras',
  picarca: 'picarça',
  picarco: 'picarço',
  picarda: 'picarda',
  picardo: 'picardo',
  picarei: 'picarei',
  picarem: 'picarem',
  picares: 'picares',
  picaria: 'picaria',
  picarmo: 'picarmo',
  picaros: 'pícaros',
  picarra: 'piçarra',
  picarre: 'piçarre',
  picarro: 'piçarro',
  picasse: 'picasse',
  picaste: 'picaste',
  picauro: 'picaúro',
  picavam: 'picavam',
  picavas: 'picavas',
  picaxos: 'picaxos',
  piccolo: 'piccolo',
  piceina: 'piceína',
  picenas: 'picenas',
  picenos: 'picenos',
  picente: 'picente',
  piceois: 'piceóis',
  pichada: 'pichada',
  pichado: 'pichado',
  pichaim: 'pichaim',
  pichais: 'pichais',
  pichamo: 'pichamo',
  pichara: 'pichara',
  pichava: 'pichava',
  picheis: 'picheis',
  pichela: 'pichela',
  pichelo: 'pichelo',
  pichemo: 'pichemo',
  pichias: 'pichias',
  pichica: 'pichica',
  pichico: 'pichico',
  pichobo: 'pichobo',
  pichoes: 'pichões',
  pichori: 'pichori',
  pichosa: 'pichosa',
  pichoso: 'pichoso',
  picicas: 'picicas',
  picidas: 'pícidas',
  picidea: 'picídea',
  picideo: 'picídeo',
  picinea: 'picínea',
  picitas: 'picitas',
  picites: 'picites',
  picnica: 'pícnica',
  picnico: 'pícnico',
  picnios: 'pícnios',
  picnita: 'picnita',
  picnite: 'picnite',
  picnito: 'picnito',
  picnode: 'picnode',
  picnose: 'picnose',
  picobia: 'picóbia',
  picoide: 'picoide',
  picolas: 'picolas',
  picoles: 'picolés',
  picomol: 'picomol',
  piconol: 'piconol',
  picosas: 'picosas',
  picosos: 'picosos',
  picotai: 'picotai',
  picotam: 'picotam',
  picotar: 'picotar',
  picotas: 'picotas',
  picotei: 'picotei',
  picotem: 'picotem',
  picotes: 'picotes',
  picotou: 'picotou',
  picrato: 'picrato',
  picreas: 'pícreas',
  picrela: 'picrela',
  picrena: 'picrena',
  picreos: 'pícreos',
  picrias: 'pícrias',
  picrica: 'pícrica',
  picrico: 'pícrico',
  picrilo: 'picrilo',
  picrina: 'picrina',
  picrita: 'picrita',
  picrite: 'picrite',
  picrito: 'picrito',
  picrois: 'picróis',
  pictava: 'pictava',
  pictavo: 'pictavo',
  pictica: 'píctica',
  pictico: 'píctico',
  pictita: 'pictita',
  pictite: 'pictite',
  pictone: 'píctone',
  picuaba: 'picuaba',
  picuada: 'picuada',
  picuado: 'picuado',
  picuais: 'picuais',
  picuamo: 'picuamo',
  picuara: 'picuará',
  picuava: 'picuava',
  picudas: 'picudas',
  picudos: 'picudos',
  picueis: 'picueis',
  picuemo: 'picuemo',
  picueta: 'picueta',
  picuins: 'picuins',
  picuira: 'picuíra',
  piculas: 'piculas',
  picumas: 'picumãs',
  picumno: 'picumno',
  pidesca: 'pidesca',
  pidesco: 'pidesco',
  pidneia: 'pidneia',
  pidneus: 'pidneus',
  pidonas: 'pidonas',
  pidonha: 'pidonha',
  pidonho: 'pidonho',
  piedade: 'piedade',
  piedora: 'piedora',
  piedosa: 'piedosa',
  piedoso: 'piedoso',
  piedras: 'piedras',
  pieiras: 'pieiras',
  pieiros: 'pieiros',
  pielica: 'piélica',
  pielico: 'piélico',
  pielite: 'pielite',
  piemese: 'piêmese',
  piemias: 'piemias',
  piemica: 'piêmica',
  piemico: 'piêmico',
  pienses: 'pienses',
  pierdes: 'pierdes',
  piereis: 'piêreis',
  pierela: 'pierela',
  pieremo: 'pieremo',
  pieriam: 'pieriam',
  pierias: 'piérias',
  pierica: 'piérica',
  pierico: 'piérico',
  pieride: 'piéride',
  pierios: 'piérios',
  piermos: 'piermos',
  pierros: 'pierrôs',
  piesmas: 'piesmas',
  piessem: 'piessem',
  piesses: 'piesses',
  piestes: 'piestes',
  pifadao: 'pifadão',
  pifadas: 'pifadas',
  pifador: 'pifador',
  pifados: 'pifados',
  pifamos: 'pifamos',
  pifanas: 'pífanas',
  pifando: 'pifando',
  pifanos: 'pífanos',
  pifante: 'pifante',
  pifaram: 'pifaram',
  pifarao: 'pifarão',
  pifaras: 'pifaras',
  pifarei: 'pifarei',
  pifarem: 'pifarem',
  pifares: 'pifares',
  pifaria: 'pifaria',
  pifarmo: 'pifarmo',
  pifaros: 'pífaros',
  pifasse: 'pifasse',
  pifaste: 'pifaste',
  pifavam: 'pifavam',
  pifavas: 'pifavas',
  pifemos: 'pifemos',
  pigacas: 'pigaças',
  pigadao: 'pigadão',
  pigadas: 'pigadas',
  pigador: 'pigador',
  pigados: 'pigados',
  pigamos: 'pigamos',
  pigando: 'pigando',
  pigante: 'pigante',
  pigaram: 'pigaram',
  pigarao: 'pigarão',
  pigaras: 'pigarás',
  pigarca: 'pigarça',
  pigarco: 'pigarço',
  pigarei: 'pigarei',
  pigarem: 'pigarem',
  pigares: 'pigares',
  pigarga: 'pigarga',
  pigargo: 'pigargo',
  pigaria: 'pigaria',
  pigarra: 'pigarra',
  pigarre: 'pigarre',
  pigarro: 'pigarro',
  pigasse: 'pigasse',
  pigaste: 'pigaste',
  pigavam: 'pigavam',
  pigavas: 'pigavas',
  pigeras: 'pigeras',
  pigidio: 'pigídio',
  pigmeia: 'pigmeia',
  pigmena: 'pigmena',
  pigmeus: 'pigmeus',
  pigopos: 'pígopos',
  pigueis: 'pigueis',
  piiamos: 'piíamos',
  piidoes: 'piidões',
  piidona: 'piidona',
  piistas: 'piistas',
  pijamas: 'pijamas',
  pijames: 'pijames',
  pijeira: 'pijeira',
  pijeiro: 'pijeiro',
  pijucas: 'pijucas',
  pilacao: 'pilação',
  pilacea: 'pilácea',
  pilaceo: 'piláceo',
  pilacre: 'pilacre',
  piladao: 'piladão',
  piladas: 'piladas',
  piladia: 'piládia',
  pilador: 'pilador',
  pilados: 'pilados',
  pilafes: 'pilafes',
  pilaira: 'pilaira',
  pilamos: 'pilamos',
  pilanas: 'pilanas',
  pilando: 'pilando',
  pilanga: 'pilanga',
  pilanos: 'pilanos',
  pilante: 'pilante',
  pilaram: 'pilaram',
  pilarao: 'pilarão',
  pilaras: 'pilaras',
  pilarei: 'pilarei',
  pilarem: 'pilarem',
  pilares: 'pilares',
  pilaria: 'pilaria',
  pilarmo: 'pilarmo',
  pilarte: 'pilarte',
  pilasse: 'pilasse',
  pilaste: 'pilaste',
  pilatas: 'pilatas',
  pilatos: 'pilatos',
  pilavam: 'pilavam',
  pilavas: 'pilavas',
  pilchai: 'pilchai',
  pilcham: 'pilcham',
  pilchar: 'pilchar',
  pilchas: 'pilchas',
  pilchei: 'pilchei',
  pilchem: 'pilchem',
  pilches: 'pilches',
  pilchou: 'pilchou',
  pilcias: 'pílcias',
  pildada: 'pildada',
  pildado: 'pildado',
  pildais: 'pildais',
  pildamo: 'pildamo',
  pildara: 'pildará',
  pildava: 'pildava',
  pildeis: 'pildeis',
  pildemo: 'pildemo',
  pildrai: 'pildrai',
  pildram: 'pildram',
  pildrar: 'pildrar',
  pildras: 'pildras',
  pildrei: 'pildrei',
  pildrem: 'pildrem',
  pildres: 'pildres',
  pildrou: 'pildrou',
  pileada: 'pileada',
  pileado: 'pileado',
  pileato: 'pileato',
  pilecas: 'pilecas',
  pilecos: 'pilecos',
  pilecra: 'pilecra',
  pileela: 'pileela',
  pilegra: 'pilegra',
  pilemos: 'pilemos',
  pilense: 'pilense',
  pilenta: 'pilenta',
  pilento: 'pilento',
  pileolo: 'piléolo',
  pileque: 'pileque',
  pileras: 'pileras',
  pilesia: 'pilésia',
  piletas: 'piletas',
  pilhada: 'pilhada',
  pilhado: 'pilhado',
  pilhais: 'pilhais',
  pilhamo: 'pilhamo',
  pilhara: 'pilhara',
  pilhava: 'pilhava',
  pilheis: 'pilheis',
  pilhemo: 'pilhemo',
  pilheta: 'pilheta',
  pilhona: 'pilhona',
  pilhote: 'pilhote',
  pilidio: 'pilídio',
  pilinas: 'pilinas',
  pilinha: 'pilinha',
  pilitas: 'pilitas',
  pilites: 'pilites',
  piloada: 'piloada',
  pilocas: 'pilocas',
  piloias: 'piloias',
  piloira: 'piloira',
  piloiro: 'piloiro',
  pilomas: 'pilomas',
  pilonas: 'pilonas',
  pilones: 'pilones',
  pilonge: 'pilonge',
  pilongo: 'pilongo',
  pilonos: 'pilonos',
  pilorda: 'pilorda',
  piloria: 'piloria',
  piloros: 'piloros',
  pilosas: 'pilosas',
  piloses: 'piloses',
  pilosos: 'pilosos',
  pilotai: 'pilotai',
  pilotam: 'pilotam',
  pilotar: 'pilotar',
  pilotas: 'pilotas',
  pilotea: 'piloteá',
  pilotei: 'pilotei',
  pilotem: 'pilotem',
  pilotes: 'pilotes',
  pilotis: 'pilotis',
  pilotos: 'pilotos',
  pilotou: 'pilotou',
  piloura: 'piloura',
  pilouro: 'pilouro',
  pilreta: 'pilreta',
  pilrete: 'pilrete',
  pilrito: 'pilrito',
  pilroes: 'pilrões',
  pilulai: 'pilulai',
  pilulam: 'pilulam',
  pilular: 'pilular',
  pilulas: 'pílulas',
  pilulei: 'pilulei',
  pilulem: 'pilulem',
  pilules: 'pilules',
  pilulou: 'pilulou',
  pilumna: 'pilumna',
  pilumno: 'pilumno',
  pilungo: 'pilungo',
  pimbada: 'pimbada',
  pimbado: 'pimbado',
  pimbais: 'pimbais',
  pimbamo: 'pimbamo',
  pimbara: 'pimbará',
  pimbava: 'pimbava',
  pimbeis: 'pimbeis',
  pimbemo: 'pimbemo',
  pimelia: 'pimélia',
  pimenta: 'pimenta',
  pimento: 'pimento',
  pimpada: 'pimpada',
  pimpado: 'pimpado',
  pimpaia: 'pimpaiã',
  pimpais: 'pimpais',
  pimpamo: 'pimpamo',
  pimpara: 'pimpara',
  pimpava: 'pimpava',
  pimpeis: 'pimpeis',
  pimpemo: 'pimpemo',
  pimpins: 'pimpins',
  pimplai: 'pimplai',
  pimplam: 'pimplam',
  pimplar: 'pimplar',
  pimplas: 'pimplas',
  pimplea: 'pímplea',
  pimplei: 'pimplei',
  pimplem: 'pimplem',
  pimpleo: 'pímpleo',
  pimples: 'pimples',
  pimpleu: 'pimpleu',
  pimplou: 'pimplou',
  pimpoes: 'pimpões',
  pimpois: 'pimpóis',
  pimpolo: 'pimpolo',
  pimpona: 'pimpona',
  pimpone: 'pimpone',
  pimpono: 'pimpono',
  pinacas: 'pinaças',
  pinacea: 'pinácea',
  pinaceo: 'pináceo',
  pinacia: 'pinácia',
  pinacol: 'pinacol',
  pinadao: 'pinadão',
  pinadas: 'pinadas',
  pinador: 'pinador',
  pinados: 'pinados',
  pinagem: 'pinagem',
  pinalha: 'pinalha',
  pinalho: 'pinalho',
  pinamas: 'pinamas',
  pinamos: 'pinamos',
  pinanas: 'pinanas',
  pinando: 'pinando',
  pinanos: 'pinanos',
  pinante: 'pinante',
  pinaram: 'pinaram',
  pinarao: 'pinarão',
  pinaras: 'pinaras',
  pinarei: 'pinarei',
  pinarem: 'pinarem',
  pinares: 'pinares',
  pinaria: 'pinaria',
  pinarmo: 'pinarmo',
  pinasco: 'pinasco',
  pinasio: 'pinásio',
  pinasse: 'pinasse',
  pinaste: 'pinaste',
  pinauma: 'pinaúma',
  pinavam: 'pinavam',
  pinavas: 'pinavas',
  pinazes: 'pinazes',
  pinazio: 'pinázio',
  pincada: 'pinçada',
  pincado: 'pinçado',
  pincais: 'pinçais',
  pincamo: 'pinçamo',
  pincara: 'pinçará',
  pincaro: 'píncaro',
  pincava: 'pinçava',
  pinceis: 'pincéis',
  pincela: 'pincela',
  pincele: 'pincele',
  pincelo: 'pincelo',
  pincemo: 'pincemo',
  pincene: 'pincenê',
  pinceta: 'pinceta',
  pinchai: 'pinchai',
  pincham: 'pincham',
  pinchao: 'pinchão',
  pinchar: 'pinchar',
  pinchas: 'pinchas',
  pinchei: 'pinchei',
  pinchem: 'pinchem',
  pinches: 'pinches',
  pinchos: 'pinchos',
  pinchou: 'pinchou',
  pincoes: 'pinções',
  pincona: 'pinçona',
  pincote: 'pinçote',
  pincres: 'pincres',
  pindari: 'pindari',
  pindela: 'pindela',
  pindica: 'píndica',
  pindico: 'píndico',
  pindoba: 'pindoba',
  pindoca: 'pindoca',
  pindoco: 'pindoco',
  pindova: 'pindova',
  pindrai: 'pindrai',
  pindram: 'pindram',
  pindrar: 'pindrar',
  pindras: 'pindras',
  pindrei: 'pindrei',
  pindrem: 'pindrem',
  pindres: 'pindres',
  pindrou: 'pindrou',
  pineais: 'pineais',
  pineira: 'pineira',
  pinelai: 'pinelai',
  pinelam: 'pinelam',
  pinelar: 'pinelar',
  pinelas: 'pinelas',
  pinelea: 'pinélea',
  pinelei: 'pinelei',
  pinelem: 'pinelem',
  pineles: 'pineles',
  pinelia: 'pinélia',
  pinelou: 'pinelou',
  pinemos: 'pinemos',
  pinenos: 'pinenos',
  pinense: 'pinense',
  pineria: 'pinéria',
  pingaca: 'pingaça',
  pingaco: 'pingaço',
  pingada: 'pingada',
  pingado: 'pingado',
  pingais: 'pingais',
  pingamo: 'pingamo',
  pingara: 'pingara',
  pingato: 'pingato',
  pingava: 'pingava',
  pingoes: 'pingões',
  pingola: 'pingola',
  pingole: 'pingole',
  pingolo: 'pingolo',
  pingona: 'pingona',
  pingosa: 'pingosa',
  pingoso: 'pingoso',
  pingota: 'pingota',
  pinguca: 'pinguça',
  pinguco: 'pinguço',
  pinguei: 'pinguei',
  pinguel: 'pinguel',
  pinguem: 'pinguem',
  pingues: 'pingues',
  pinguim: 'pinguim',
  pinhata: 'pinhata',
  pinheim: 'pinheim',
  pinheis: 'pinhéis',
  pinhens: 'pinhéns',
  pinhoao: 'pinhoão',
  pinhoas: 'pinhoãs',
  pinhoca: 'pinhoca',
  pinhoes: 'pinhões',
  pinhola: 'pinhola',
  pinhole: 'pinhole',
  pinhota: 'pinhota',
  pinhuns: 'pinhuns',
  pinicai: 'pinicai',
  pinicam: 'pinicam',
  pinicao: 'pinicão',
  pinicar: 'pinicar',
  pinicas: 'pinicas',
  pinicos: 'pínicos',
  pinicou: 'pinicou',
  pinilho: 'pinilho',
  pinimas: 'pinimas',
  pinimba: 'pinimba',
  pinimbe: 'pinimbe',
  pinimbo: 'pinimbo',
  pininas: 'pininas',
  pinique: 'pinique',
  pinitas: 'pinitas',
  pinites: 'pinites',
  pinitol: 'pinitol',
  pinocai: 'pinocai',
  pinocam: 'pinocam',
  pinocar: 'pinocar',
  pinocas: 'pinocas',
  pinocos: 'pinocos',
  pinocou: 'pinocou',
  pinoias: 'pinoias',
  pinoios: 'pinoios',
  pinoita: 'pinoíta',
  pinoque: 'pinoque',
  pinotai: 'pinotai',
  pinotam: 'pinotam',
  pinotar: 'pinotar',
  pinotas: 'pinotas',
  pinotea: 'pinoteá',
  pinotei: 'pinotei',
  pinotem: 'pinotem',
  pinotes: 'pinotes',
  pinotou: 'pinotou',
  pinques: 'pinques',
  pintada: 'pintada',
  pintado: 'pintado',
  pintais: 'pintais',
  pintala: 'pintala',
  pintale: 'pintale',
  pintalo: 'pintalo',
  pintamo: 'pintamo',
  pintane: 'pintane',
  pintara: 'pintara',
  pintava: 'pintava',
  pintega: 'píntega',
  pinteis: 'pinteis',
  pintemo: 'pintemo',
  pinteus: 'pintéus',
  pintoes: 'pintões',
  pintora: 'pintora',
  pintosa: 'pintosa',
  pintoso: 'pintoso',
  pintura: 'pintura',
  pinulas: 'pínulas',
  pinulos: 'pínulos',
  pinusco: 'pinusco',
  piocaba: 'piocaba',
  piocada: 'piocada',
  piocele: 'piocele',
  piochos: 'piochos',
  piocito: 'piócito',
  pioemia: 'pioemia',
  piofila: 'piófila',
  piogena: 'piógena',
  piogeno: 'piógeno',
  pioides: 'pioides',
  piojota: 'piojota',
  piolhai: 'piolhai',
  piolham: 'piolham',
  piolhar: 'piolhar',
  piolhas: 'piolhas',
  piolhei: 'piolhei',
  piolhem: 'piolhem',
  piolhes: 'piolhes',
  piolhos: 'piolhos',
  piolhou: 'piolhou',
  piolins: 'piolins',
  pioneas: 'piôneas',
  piongos: 'piongos',
  pionico: 'piônico',
  pionios: 'piônios',
  pionita: 'pionita',
  pionona: 'pionona',
  pionoto: 'pionoto',
  piopios: 'piopios',
  piorada: 'piorada',
  piorado: 'piorado',
  piorais: 'piorais',
  pioramo: 'pioramo',
  piorana: 'piorana',
  piorano: 'piorano',
  piorara: 'piorara',
  piorava: 'piorava',
  pioreis: 'pioreis',
  pioremo: 'pioremo',
  piorias: 'piorias',
  piorios: 'piorios',
  piornal: 'piornal',
  piornas: 'piornas',
  piornes: 'piornes',
  piornos: 'piornos',
  piorras: 'piorras',
  piorros: 'piorros',
  piorzao: 'piorzão',
  piosica: 'piósica',
  piosico: 'piósico',
  piotina: 'piotina',
  piovesa: 'piovesa',
  pipadao: 'pipadão',
  pipadas: 'pipadas',
  pipador: 'pipador',
  pipados: 'pipados',
  pipamos: 'pipamos',
  pipando: 'pipando',
  pipante: 'pipante',
  piparam: 'piparam',
  piparao: 'piparão',
  piparas: 'piparás',
  piparei: 'piparei',
  piparem: 'piparem',
  pipares: 'pipares',
  piparia: 'piparia',
  piparmo: 'piparmo',
  pipasse: 'pipasse',
  pipaste: 'pipaste',
  pipavam: 'pipavam',
  pipavas: 'pipavas',
  pipedes: 'pípedes',
  pipeiro: 'pipeiro',
  pipeles: 'pipelês',
  pipemos: 'pipemos',
  pipense: 'pipense',
  piperal: 'piperal',
  piperis: 'piperis',
  pipetai: 'pipetai',
  pipetam: 'pipetam',
  pipetar: 'pipetar',
  pipetas: 'pipetas',
  pipetei: 'pipetei',
  pipetem: 'pipetem',
  pipetes: 'pipetes',
  pipetou: 'pipetou',
  pipiada: 'pipiada',
  pipiado: 'pipiado',
  pipiais: 'pipiais',
  pipiamo: 'pipiamo',
  pipiara: 'pipiará',
  pipiava: 'pipiava',
  pipidas: 'pípidas',
  pipidea: 'pipídea',
  pipideo: 'pipídeo',
  pipieis: 'pipieis',
  pipiemo: 'pipiemo',
  pipilai: 'pipilai',
  pipilam: 'pipilam',
  pipilar: 'pipilar',
  pipilas: 'pipilas',
  pipilei: 'pipilei',
  pipilem: 'pipilem',
  pipiles: 'pipiles',
  pipilou: 'pipilou',
  pipilro: 'pipilro',
  pipinai: 'pipinai',
  pipinam: 'pipinam',
  pipinar: 'pipinar',
  pipinas: 'pipinas',
  pipinei: 'pipinei',
  pipinem: 'pipinem',
  pipines: 'pipines',
  pipinou: 'pipinou',
  pipioes: 'pipiões',
  pipipas: 'pipipãs',
  pipiral: 'pipiral',
  pipiras: 'pipiras',
  pipiris: 'pipiris',
  pipitai: 'pipitai',
  pipitam: 'pipitam',
  pipitar: 'pipitar',
  pipitas: 'pipitas',
  pipitei: 'pipitei',
  pipitem: 'pipitem',
  pipites: 'pipites',
  pipitou: 'pipitou',
  pipocai: 'pipocai',
  pipocam: 'pipocam',
  pipocar: 'pipocar',
  pipocas: 'pipocas',
  pipocou: 'pipocou',
  pipoque: 'pipoque',
  pipotes: 'pipotes',
  piprida: 'píprida',
  pipturo: 'pipturo',
  pipucos: 'pipucos',
  pipuira: 'pipuíra',
  piqueai: 'piqueai',
  piquear: 'piquear',
  piqueei: 'piqueei',
  piqueia: 'piqueia',
  piqueie: 'piqueie',
  piqueio: 'piqueio',
  piqueis: 'piqueis',
  piquemo: 'piquemo',
  piqueou: 'piqueou',
  piqueta: 'piqueta',
  piquete: 'piquete',
  piqueto: 'piqueto',
  piquias: 'piquiás',
  piquira: 'piquira',
  piraaca: 'piraaca',
  pirabas: 'pirabas',
  piracaa: 'piracaá',
  piracao: 'piração',
  piracas: 'piracas',
  piracem: 'piracém',
  piracui: 'piracuí',
  piradao: 'piradão',
  piradas: 'piradas',
  pirador: 'pirador',
  pirados: 'pirados',
  piraens: 'piraéns',
  piragua: 'pirágua',
  piraias: 'piraias',
  piraiba: 'piraíba',
  piraius: 'piraiús',
  pirajas: 'pirajás',
  pirajem: 'pirajém',
  pirajia: 'pirajiá',
  pirajis: 'pirajis',
  pirajus: 'pirajus',
  pirales: 'pirales',
  piralis: 'pirális',
  piramba: 'piramba',
  pirambe: 'pirambé',
  pirambo: 'pirambo',
  pirambu: 'pirambu',
  piramia: 'pirâmia',
  piramos: 'piramos',
  piranas: 'piranas',
  pirando: 'pirando',
  piranga: 'piranga',
  pirange: 'pirange',
  pirango: 'pirango',
  piranha: 'piranha',
  piranos: 'piranos',
  piranta: 'piranta',
  pirante: 'pirante',
  piraoas: 'piraoás',
  piraoba: 'piraoba',
  pirapia: 'pirapiá',
  piraque: 'piraquê',
  piraqui: 'piraqui',
  piraram: 'piraram',
  pirarao: 'pirarão',
  piraras: 'piraras',
  pirarei: 'pirarei',
  pirarem: 'pirarem',
  pirares: 'pirares',
  piraria: 'piraria',
  pirarmo: 'pirarmo',
  pirasse: 'pirasse',
  piraste: 'piraste',
  piratai: 'piratai',
  piratam: 'piratam',
  piratar: 'piratar',
  piratas: 'piratas',
  piratea: 'pirateá',
  piratei: 'piratei',
  piratem: 'piratem',
  pirates: 'pirates',
  piratis: 'piratis',
  piratou: 'piratou',
  pirauas: 'pirauás',
  pirauna: 'piraúna',
  pirauru: 'pirauru',
  pirauxi: 'pirauxi',
  piravam: 'piravam',
  piravas: 'piravas',
  pirazol: 'pirazol',
  pirchas: 'pirchas',
  pirecos: 'pirecos',
  pireias: 'pireias',
  pirelia: 'pirélia',
  piremas: 'piremas',
  piremos: 'piremos',
  pirenas: 'pirenas',
  pirenes: 'pirenes',
  pireneu: 'pireneu',
  pirenga: 'pirenga',
  pirengo: 'pirengo',
  pirenio: 'pirênio',
  pirenol: 'pirenol',
  pirenos: 'pirenos',
  pirense: 'pirense',
  pirenta: 'pirenta',
  pirento: 'pirento',
  pireras: 'pireras',
  piretos: 'píretos',
  piretro: 'píretro',
  pirexia: 'pirexia',
  pirezas: 'pirezas',
  pirgoma: 'pirgoma',
  pirgula: 'pirgula',
  piricao: 'piricão',
  piricas: 'píricas',
  piriche: 'piriche',
  piricos: 'píricos',
  pirigua: 'piriguá',
  pirijau: 'pirijau',
  pirilau: 'pirilau',
  pirilio: 'pirílio',
  pirimas: 'pirimas',
  pirinas: 'pirinas',
  pirinha: 'pirinha',
  piriris: 'piriris',
  pirisca: 'pirisca',
  pirismo: 'pirismo',
  pirista: 'pirista',
  piritas: 'piritas',
  pirites: 'pirites',
  piritos: 'piritos',
  pirixis: 'pirixis',
  pirizal: 'pirizal',
  pirizes: 'pirizes',
  pirlito: 'pirlito',
  piroaba: 'piroaba',
  pirobos: 'pirobos',
  pirocai: 'pirocai',
  pirocam: 'pirocam',
  pirocar: 'pirocar',
  pirocas: 'pirocas',
  pirocou: 'pirocou',
  pirogas: 'pirogas',
  piroide: 'piroide',
  pirolas: 'pirolas',
  piroles: 'piroles',
  piroliz: 'piroliz',
  pirolos: 'pírolos',
  pironas: 'pironas',
  pironga: 'pironga',
  pironio: 'pirônio',
  piropos: 'piropos',
  piroque: 'piroque',
  pirosal: 'pirosal',
  pirosas: 'pirosas',
  pirosca: 'pirosca',
  piroses: 'piroses',
  pirosos: 'pirosos',
  pirotas: 'pirotas',
  pirotes: 'pirotes',
  pirraca: 'pirraça',
  pirrace: 'pirrace',
  pirraco: 'pirraço',
  pirreia: 'pirreia',
  pirrica: 'pírrica',
  pirrico: 'pírrico',
  pirrida: 'pírrida',
  pirrila: 'pirrila',
  pirrita: 'pirrita',
  pirrite: 'pirrite',
  pirrois: 'pirróis',
  pirrola: 'pirrola',
  pirrona: 'pirrona',
  pirrula: 'pírrula',
  pirrura: 'pirrura',
  pirtiga: 'pírtiga',
  pirtigo: 'pírtigo',
  piruera: 'piruera',
  pirueta: 'pirueta',
  piruete: 'piruete',
  pirueto: 'pirueto',
  pirulas: 'pírulas',
  pirulos: 'pirulos',
  pirunga: 'pirunga',
  pirusta: 'pirusta',
  pirusto: 'pirusto',
  pisadao: 'pisadão',
  pisadas: 'pisadas',
  pisador: 'pisador',
  pisados: 'pisados',
  pisalia: 'pisália',
  pisamos: 'pisamos',
  pisanas: 'pisanas',
  pisando: 'pisando',
  pisania: 'pisânia',
  pisanos: 'pisanos',
  pisante: 'pisante',
  pisaram: 'pisaram',
  pisarao: 'pisarão',
  pisaras: 'pisaras',
  pisarei: 'pisarei',
  pisarem: 'pisarem',
  pisares: 'pisares',
  pisaria: 'pisaria',
  pisarmo: 'pisarmo',
  pisasse: 'pisasse',
  pisaste: 'pisaste',
  pisaura: 'pisaura',
  pisauro: 'pisauro',
  pisavam: 'pisavam',
  pisavas: 'pisavas',
  piscada: 'piscada',
  piscado: 'piscado',
  piscais: 'piscais',
  piscamo: 'piscamo',
  piscara: 'piscara',
  piscato: 'piscato',
  piscava: 'piscava',
  pisceas: 'písceas',
  pisceos: 'písceos',
  piscina: 'piscina',
  piscola: 'píscola',
  piscosa: 'piscosa',
  piscoso: 'piscoso',
  piseias: 'piseias',
  pisemos: 'pisemos',
  pisgada: 'pisgada',
  pisgado: 'pisgado',
  pisgais: 'pisgais',
  pisgara: 'pisgara',
  pisgava: 'pisgava',
  pisguei: 'pisguei',
  pisguem: 'pisguem',
  pisgues: 'pisgues',
  pisidas: 'pisidas',
  pisidio: 'pisídio',
  pisitar: 'pisitar',
  pisoada: 'pisoada',
  pisoado: 'pisoado',
  pisoais: 'pisoais',
  pisoamo: 'pisoamo',
  pisoara: 'pisoará',
  pisoava: 'pisoava',
  pisoeis: 'pisoeis',
  pisoemo: 'pisoemo',
  pisoide: 'pisoide',
  pisonia: 'pisônia',
  pisorga: 'pisorga',
  pisotea: 'pisoteá',
  pispeto: 'pispeto',
  pisquei: 'pisquei',
  pisquem: 'pisquem',
  pisques: 'pisques',
  pisseta: 'pisseta',
  pissica: 'píssica',
  pissico: 'píssico',
  pissita: 'pissita',
  pissite: 'pissite',
  pissito: 'pissito',
  pissoda: 'pissoda',
  pissode: 'pissode',
  pissota: 'pissota',
  pistada: 'pistada',
  pistado: 'pistado',
  pistais: 'pistais',
  pistamo: 'pistamo',
  pistara: 'pistara',
  pistava: 'pistava',
  pisteis: 'pisteis',
  pistemo: 'pistemo',
  pistias: 'pístias',
  pistilo: 'pistilo',
  pistoes: 'pistões',
  pistola: 'pistola',
  pistole: 'pistolé',
  pistolo: 'pistolo',
  pistons: 'pistons',
  pistora: 'pistora',
  pistori: 'pistori',
  pisueta: 'pisueta',
  pitacal: 'pitacal',
  pitacas: 'pitacas',
  pitacio: 'pitácio',
  pitacos: 'pitacos',
  pitadao: 'pitadão',
  pitadas: 'pitadas',
  pitadea: 'pitadeá',
  pitador: 'pitador',
  pitados: 'pitados',
  pitafes: 'pitafes',
  pitafos: 'pitafos',
  pitagos: 'pítagos',
  pitaica: 'pitaica',
  pitaleu: 'pitaléu',
  pitamos: 'pitamos',
  pitanas: 'pítanas',
  pitanca: 'pitança',
  pitando: 'pitando',
  pitaneu: 'pitaneu',
  pitanga: 'pitanga',
  pitango: 'pitango',
  pitanos: 'pítanos',
  pitante: 'pitante',
  pitaram: 'pitaram',
  pitarao: 'pitarão',
  pitaras: 'pitaras',
  pitarei: 'pitarei',
  pitarem: 'pitarem',
  pitares: 'pitares',
  pitaria: 'pitaria',
  pitarmo: 'pitarmo',
  pitasse: 'pitasse',
  pitaste: 'pitaste',
  pitauas: 'pitauás',
  pitaula: 'pitaula',
  pitaulo: 'pitaulo',
  pitavam: 'pitavam',
  pitavas: 'pitavas',
  pitavia: 'pitávia',
  pitecia: 'pitécia',
  pitecio: 'pitécio',
  pitecos: 'pitecos',
  piteira: 'piteira',
  piteire: 'piteire',
  piteiro: 'piteiro',
  pitemos: 'pitemos',
  pitiada: 'pitíada',
  pitiade: 'pitíade',
  piticas: 'píticas',
  piticos: 'píticos',
  pitidea: 'pitídea',
  pitideo: 'pitídeo',
  pitigar: 'pitigar',
  pitigas: 'pítigas',
  pitigos: 'pítigos',
  pitilos: 'pítilos',
  pitimas: 'pitimas',
  pitinga: 'pitinga',
  pitinha: 'pitinha',
  pitinho: 'pitinho',
  pitinta: 'pitinta',
  pitipes: 'pitipés',
  pitiuns: 'pitiuns',
  pitocas: 'pitocas',
  pitocha: 'pitocha',
  pitocos: 'pitocos',
  pitomas: 'pitomas',
  pitomba: 'pitomba',
  pitombo: 'pitombo',
  pitoras: 'pitoras',
  pitorra: 'pitorra',
  pitosas: 'pitosas',
  pitosca: 'pitosca',
  pitosco: 'pitosco',
  pitosga: 'pitosga',
  pitosgo: 'pitosgo',
  pitosos: 'pitosos',
  pitotes: 'pitotes',
  pitribi: 'pitribi',
  pituacu: 'pituaçu',
  pitubas: 'pitubas',
  pituins: 'pituins',
  pituita: 'pituíta',
  pitulas: 'pitulas',
  pitumba: 'pitumba',
  pituras: 'pituras',
  pituris: 'pituris',
  piucada: 'piucada',
  piucado: 'piucado',
  piucais: 'piucais',
  piucamo: 'piucamo',
  piucara: 'piucará',
  piucava: 'piucava',
  piuense: 'piuense',
  piulcos: 'piulcos',
  piuquei: 'piuquei',
  piuquem: 'piuquém',
  piuques: 'piuques',
  piurias: 'piúrias',
  piurica: 'piúrica',
  piurico: 'piúrico',
  piuvais: 'piuvais',
  pivelos: 'pivelos',
  pivetes: 'pivetes',
  pivocas: 'pivocas',
  pivotai: 'pivotai',
  pivotam: 'pivotam',
  pivotar: 'pivotar',
  pivotas: 'pivotas',
  pivotei: 'pivotei',
  pivotem: 'pivotem',
  pivotes: 'pivotes',
  pivotou: 'pivotou',
  pixains: 'pixains',
  pixarro: 'pixarro',
  pixeada: 'pixeada',
  pixeado: 'pixeado',
  pixeais: 'pixeais',
  pixeara: 'pixeara',
  pixeava: 'pixeava',
  pixeeis: 'pixeeis',
  pixeiam: 'pixeiam',
  pixeias: 'pixeias',
  pixeiem: 'pixeiem',
  pixeies: 'pixeies',
  pixerai: 'pixerai',
  pixeram: 'pixeram',
  pixerar: 'pixerar',
  pixeras: 'pixeras',
  pixerei: 'pixerei',
  pixerem: 'pixerem',
  pixeres: 'pixeres',
  pixerou: 'pixerou',
  pixidea: 'pixídea',
  pixideo: 'pixídeo',
  pixides: 'píxides',
  pixidio: 'pixídio',
  pixinas: 'pixinas',
  pixinea: 'pixínea',
  pixineo: 'pixíneo',
  pixinia: 'pixínia',
  pixorca: 'pixorca',
  pixorco: 'pixorco',
  pixores: 'pixorés',
  pixorga: 'pixorga',
  pixorgo: 'pixorgo',
  pixotes: 'pixotes',
  pixoxos: 'pixoxós',
  pixuira: 'pixuíra',
  pixunas: 'pixunas',
  pixunde: 'pixundé',
  pixundu: 'pixundu',
  pixunxu: 'pixunxu',
  pixuria: 'pixúria',
  pixurim: 'pixurim',
  pixuris: 'pixuris',
  pixurum: 'pixurum',
  pizaria: 'pizaria',
  pizorga: 'pizorga',
  placada: 'placada',
  placado: 'placado',
  placard: 'placard',
  placebo: 'placebo',
  placida: 'plácida',
  placide: 'placide',
  placido: 'plácido',
  placita: 'placita',
  placite: 'placite',
  placito: 'plácito',
  placopo: 'plácopo',
  placuna: 'placuna',
  placusa: 'placusa',
  plafond: 'plafond',
  plafons: 'plafons',
  plagais: 'plagais',
  plagiai: 'plagiai',
  plagiam: 'plagiam',
  plagiar: 'plagiar',
  plagias: 'plagias',
  plagico: 'plágico',
  plagiei: 'plagiei',
  plagiem: 'plagiem',
  plagies: 'plagies',
  plagios: 'plágios',
  plagiou: 'plagiou',
  plagoes: 'plagões',
  plainai: 'plainai',
  plainam: 'plainam',
  plainar: 'plainar',
  plainas: 'plainas',
  plainei: 'plainei',
  plainem: 'plainem',
  plaines: 'plaines',
  plainos: 'plainos',
  plainou: 'plainou',
  planada: 'planada',
  planado: 'planado',
  planais: 'planais',
  planamo: 'planamo',
  planara: 'planara',
  planava: 'planava',
  plancha: 'plancha',
  planche: 'planche',
  plancho: 'plancho',
  plancto: 'plancto',
  planeai: 'planeai',
  planear: 'planear',
  planeei: 'planeei',
  planeia: 'planeia',
  planeie: 'planeie',
  planeio: 'planeio',
  planeis: 'planeis',
  planeja: 'planeja',
  planeje: 'planeje',
  planejo: 'planejo',
  planemo: 'planemo',
  planeou: 'planeou',
  planeta: 'planeta',
  planete: 'planete',
  planeza: 'planeza',
  plangei: 'plangei',
  plangem: 'plangem',
  planger: 'planger',
  planges: 'planges',
  plangeu: 'plangeu',
  plangia: 'plangia',
  plangor: 'plangor',
  planham: 'planham',
  planhas: 'planhas',
  planhem: 'planhem',
  planhes: 'planhes',
  planhia: 'planhia',
  planhir: 'planhir',
  planhis: 'planhis',
  planhiu: 'planhiu',
  planice: 'planice',
  planico: 'planiço',
  planiza: 'planiza',
  planize: 'planize',
  planizo: 'planizo',
  planjam: 'planjam',
  planjas: 'planjas',
  plantai: 'plantai',
  plantal: 'plantal',
  plantam: 'plantam',
  plantao: 'plantão',
  plantar: 'plantar',
  plantas: 'plantas',
  plantea: 'planteá',
  plantei: 'plantei',
  plantel: 'plantel',
  plantem: 'plantem',
  plantes: 'plantes',
  plantio: 'plantio',
  plantou: 'plantou',
  planula: 'plânula',
  planura: 'planura',
  planzem: 'planzém',
  plaquea: 'plaqueá',
  plaques: 'plaquês',
  plasias: 'plasias',
  plasmai: 'plasmai',
  plasmam: 'plasmam',
  plasmao: 'plasmão',
  plasmar: 'plasmar',
  plasmas: 'plasmas',
  plasmei: 'plasmei',
  plasmem: 'plasmem',
  plasmes: 'plasmes',
  plasmou: 'plasmou',
  plastas: 'plastas',
  plastia: 'plastia',
  plastos: 'plastos',
  platace: 'plátace',
  platais: 'platais',
  platana: 'plátana',
  platano: 'plátano',
  plateau: 'plateau',
  plateia: 'plateia',
  platido: 'platido',
  platina: 'platina',
  platine: 'platine',
  platino: 'platino',
  platipo: 'plátipo',
  platite: 'plátite',
  platuro: 'platuro',
  plaudam: 'plaudam',
  plaudas: 'plaudas',
  plaudem: 'plaudem',
  plaudes: 'plaudes',
  plaudia: 'plaudia',
  plaudir: 'plaudir',
  plaudis: 'plaudis',
  plaudiu: 'plaudiu',
  playboy: 'playboy',
  plazias: 'plázias',
  plearia: 'pleária',
  plebana: 'plebana',
  plebano: 'plebano',
  plebeia: 'plebeia',
  plebeus: 'plebeus',
  plebiza: 'plebizá',
  plebize: 'plebize',
  plebizo: 'plebizo',
  plecoto: 'plecoto',
  plectos: 'plectos',
  plectro: 'plectro',
  plegias: 'plegias',
  pleiada: 'plêiada',
  pleiade: 'plêiade',
  pleinea: 'pleínea',
  pleineo: 'pleíneo',
  pleiona: 'plêiona',
  pleione: 'plêione',
  pleitai: 'pleitai',
  pleitam: 'pleitam',
  pleitar: 'pleitar',
  pleitas: 'pleitas',
  pleitea: 'pleiteá',
  pleitei: 'pleitei',
  pleitem: 'pleitem',
  pleites: 'pleites',
  pleitos: 'pleitos',
  pleitou: 'pleitou',
  plengas: 'plengas',
  plengos: 'plengos',
  plengue: 'plengue',
  plenita: 'plenita',
  plenite: 'plenite',
  plenito: 'plenito',
  pleonio: 'pleônio',
  plerema: 'plerema',
  plereta: 'plereta',
  pleroma: 'pleroma',
  plerose: 'plerose',
  plesias: 'plésias',
  pletora: 'pletora',
  pletore: 'pletore',
  pletoro: 'pletoro',
  pletros: 'pletros',
  pleural: 'pleural',
  pleuras: 'pleuras',
  pleuris: 'pleuris',
  pleuros: 'pleuros',
  pleusto: 'pleusto',
  plevias: 'plévias',
  plexias: 'plexias',
  plicada: 'plicada',
  plicado: 'plicado',
  plicais: 'plicais',
  plicamo: 'plicamo',
  plicara: 'plicara',
  plicava: 'plicava',
  plicoma: 'plicoma',
  plidada: 'plidada',
  plidado: 'plidado',
  plidais: 'plidais',
  plidamo: 'plidamo',
  plidara: 'plidará',
  plidava: 'plidava',
  plideis: 'plideis',
  plidemo: 'plidemo',
  plinias: 'plínias',
  plinite: 'plinite',
  plintos: 'plintos',
  plioipo: 'plioípo',
  pliquei: 'pliquei',
  pliquem: 'pliquem',
  pliques: 'pliques',
  plissai: 'plissai',
  plissam: 'plissam',
  plissar: 'plissar',
  plissas: 'plissas',
  plissei: 'plissei',
  plissem: 'plissem',
  plisses: 'plisses',
  plissou: 'plissou',
  plocama: 'plócama',
  ploceas: 'plóceas',
  ploceos: 'plóceos',
  plocias: 'plócias',
  plodias: 'plódias',
  ploeira: 'ploeira',
  ploeiro: 'ploeiro',
  ploidia: 'ploidia',
  ploimas: 'ploimas',
  ploimos: 'ploimos',
  ploiras: 'ploiras',
  plorins: 'plorins',
  plosiva: 'plosiva',
  plosivo: 'plosivo',
  plosoes: 'plosões',
  plosona: 'plosona',
  plotada: 'plotada',
  plotado: 'plotado',
  plotais: 'plotais',
  plotamo: 'plotamo',
  plotara: 'plotara',
  plotava: 'plotava',
  ploteis: 'ploteis',
  plotemo: 'plotemo',
  plotosa: 'plotosa',
  plotoso: 'plotoso',
  plotter: 'plotter',
  plotzia: 'plótzia',
  plugada: 'plugada',
  plugado: 'plugado',
  plugais: 'plugais',
  plugamo: 'plugamo',
  plugara: 'plugara',
  plugava: 'plugava',
  pluguei: 'pluguei',
  pluguem: 'pluguem',
  plugues: 'plugues',
  plumaco: 'plumaço',
  plumada: 'plumada',
  plumado: 'plumado',
  plumais: 'plumais',
  plumamo: 'plumamo',
  plumara: 'plumará',
  plumava: 'plumava',
  plumbea: 'plúmbea',
  plumbeo: 'plúmbeo',
  plumeas: 'plúmeas',
  plumeis: 'plumeis',
  plumeja: 'plumeja',
  plumeje: 'plumeje',
  plumejo: 'plumejo',
  plumemo: 'plumemo',
  plumeos: 'plúmeos',
  plumoes: 'plumões',
  plumosa: 'plumosa',
  plumoso: 'plumoso',
  plumula: 'plúmula',
  plurais: 'plurais',
  plurima: 'plúrima',
  plurimo: 'plúrimo',
  pluteas: 'plúteas',
  plutela: 'plutela',
  plutelo: 'plutelo',
  pluteos: 'plúteos',
  plutoes: 'plutões',
  pluvial: 'pluvial',
  pluvias: 'plúvias',
  pluvios: 'plúvios',
  pneumas: 'pneumas',
  pneumon: 'pneumon',
  pnoirmo: 'pnoirmo',
  poaceas: 'poáceas',
  poaceos: 'poáceos',
  poaense: 'poaense',
  poalhas: 'poalhas',
  poalhos: 'poalhos',
  pobilas: 'pobilas',
  pobreta: 'pobreta',
  pobrete: 'pobrete',
  pobreza: 'pobreza',
  poburas: 'poburas',
  pocacus: 'pocaçus',
  pocadao: 'pocadão',
  pocadas: 'pocadas',
  pocador: 'pocador',
  pocados: 'pocados',
  pocamos: 'pocamos',
  pocanco: 'poçanco',
  pocando: 'pocando',
  pocanga: 'pocanga',
  pocante: 'pocante',
  pocaram: 'pocaram',
  pocarao: 'pocarão',
  pocaras: 'pocarás',
  pocarei: 'pocarei',
  pocarem: 'pocarem',
  pocares: 'pocares',
  pocaria: 'pocaria',
  pocarmo: 'pocarmo',
  pocasse: 'pocasse',
  pocaste: 'pocaste',
  pocauna: 'poçaúna',
  pocavam: 'pocavam',
  pocavas: 'pocavas',
  pocecas: 'pocecas',
  pocecos: 'pocecos',
  poceira: 'poceira',
  poceiro: 'poceiro',
  pocemas: 'pocemas',
  pocense: 'pocense',
  pochade: 'pochade',
  pochete: 'pochete',
  pocilga: 'pocilga',
  pocilgo: 'pocilgo',
  pocinha: 'pocinha',
  pocinho: 'pocinho',
  pococas: 'poçocas',
  pocomao: 'pocomão',
  pocucas: 'poçucas',
  podabro: 'podabro',
  podadao: 'podadão',
  podadas: 'podadas',
  podador: 'podador',
  podados: 'podados',
  podagra: 'podagra',
  podagro: 'podagro',
  podalia: 'podália',
  podamos: 'podamos',
  podando: 'podando',
  podange: 'podange',
  podante: 'podante',
  podaram: 'podaram',
  podarao: 'podarão',
  podaras: 'podaras',
  podarei: 'podarei',
  podarem: 'podarem',
  podares: 'podares',
  podargo: 'podargo',
  podaria: 'podária',
  podarmo: 'podarmo',
  podasse: 'podasse',
  podaste: 'podaste',
  podavam: 'podavam',
  podavas: 'podavas',
  podaxos: 'podaxos',
  podecio: 'podécio',
  podedor: 'podedor',
  podeira: 'podeira',
  podemos: 'podemos',
  podendo: 'podendo',
  podenga: 'podenga',
  podengo: 'podengo',
  poderao: 'poderão',
  poderas: 'poderás',
  poderei: 'poderei',
  poderem: 'poderem',
  poderes: 'poderes',
  poderia: 'poderia',
  poderio: 'poderio',
  podical: 'podical',
  podicas: 'podicas',
  podices: 'pódices',
  podidao: 'podidão',
  podidas: 'podidas',
  podieis: 'podíeis',
  podilho: 'podilho',
  poditos: 'poditos',
  podoces: 'podoces',
  podogos: 'podogós',
  podoide: 'podoide',
  podoios: 'podoios',
  podoiro: 'podoiro',
  podones: 'podones',
  podonta: 'podonta',
  podopes: 'pódopes',
  podopse: 'podopse',
  podormo: 'podormo',
  podouro: 'podouro',
  podoxia: 'podóxia',
  podraga: 'podraga',
  podreca: 'podreça',
  podrece: 'podrece',
  podreci: 'podreci',
  podreco: 'podreço',
  podrees: 'podrees',
  podreza: 'podreza',
  podrice: 'podrice',
  podrida: 'podrida',
  podrido: 'podrido',
  podroes: 'podrões',
  podrona: 'podrona',
  podruns: 'podruns',
  podrura: 'podrura',
  poduras: 'poduras',
  poduros: 'poduros',
  poecilo: 'poecilo',
  poedora: 'poedora',
  poefaga: 'poéfaga',
  poefago: 'poéfago',
  poeirai: 'poeirai',
  poeiram: 'poeiram',
  poeirao: 'poeirão',
  poeirar: 'poeirar',
  poeiras: 'poeiras',
  poeirei: 'poeirei',
  poeirem: 'poeirem',
  poeires: 'poeires',
  poeirou: 'poeirou',
  poemaco: 'poemaço',
  poemeto: 'poemeto',
  poentai: 'poentai',
  poentam: 'poentam',
  poentar: 'poentar',
  poentas: 'poentas',
  poentei: 'poentei',
  poentem: 'poentem',
  poentes: 'poentes',
  poentos: 'poentos',
  poentou: 'poentou',
  poerdes: 'poerdes',
  poereis: 'poereis',
  poeremo: 'poeremo',
  poeriam: 'poeriam',
  poerias: 'poerias',
  poermos: 'poermos',
  poescos: 'poescos',
  poesias: 'poesias',
  poessem: 'poessem',
  poesses: 'poesses',
  poestes: 'poestes',
  poetaco: 'poetaço',
  poetada: 'poetada',
  poetado: 'poetado',
  poetais: 'poetais',
  poetamo: 'poetamo',
  poetara: 'poetara',
  poetava: 'poetava',
  poeteis: 'poeteis',
  poetemo: 'poetemo',
  poetica: 'poética',
  poetico: 'poético',
  poetisa: 'poetisa',
  poetiza: 'poetiza',
  poetize: 'poetize',
  poetizo: 'poetizo',
  pogonia: 'pogônia',
  pogonio: 'pogônio',
  pogonos: 'pogonos',
  pogoros: 'pogoros',
  pogrons: 'pogrons',
  poiadao: 'poiadão',
  poiadas: 'poiadas',
  poiador: 'poiador',
  poiados: 'poiados',
  poiages: 'poiages',
  poiamos: 'poiamos',
  poianas: 'poianas',
  poiando: 'poiando',
  poiante: 'poiante',
  poiaram: 'poiaram',
  poiarao: 'poiarão',
  poiaras: 'poiarás',
  poiarei: 'poiarei',
  poiarem: 'poiarem',
  poiares: 'poiares',
  poiaria: 'poiaria',
  poiasse: 'poiasse',
  poiaste: 'poiaste',
  poiavam: 'poiavam',
  poiavas: 'poiavas',
  poidoes: 'poidões',
  poidona: 'poidona',
  poiemos: 'poiemos',
  poieses: 'poieses',
  poiloes: 'poilões',
  poilosa: 'poilosa',
  poiloso: 'poiloso',
  pointer: 'pointer',
  pointes: 'pointes',
  poisada: 'poisada',
  poisado: 'poisado',
  poisais: 'poisais',
  poisamo: 'poisamo',
  poisara: 'poisara',
  poisava: 'poisava',
  poiseis: 'poiseis',
  poisemo: 'poisemo',
  poisias: 'poisias',
  poisios: 'poisios',
  poisois: 'poisóis',
  poitada: 'poitada',
  poitado: 'poitado',
  poitais: 'poitais',
  poitamo: 'poitamo',
  poitara: 'poitara',
  poitava: 'poitava',
  poiteis: 'poiteis',
  poitemo: 'poitemo',
  poitoes: 'poitões',
  pojadao: 'pojadão',
  pojadas: 'pojadas',
  pojador: 'pojador',
  pojados: 'pojados',
  pojamos: 'pojamos',
  pojando: 'pojando',
  pojante: 'pojante',
  pojaram: 'pojaram',
  pojarao: 'pojarão',
  pojaras: 'pojarás',
  pojarei: 'pojarei',
  pojarem: 'pojarem',
  pojares: 'pojares',
  pojaria: 'pojaria',
  pojarmo: 'pojarmo',
  pojasse: 'pojasse',
  pojaste: 'pojaste',
  pojauru: 'pojauru',
  pojavam: 'pojavam',
  pojavas: 'pojavas',
  pojeias: 'pojeias',
  pojeira: 'pojeira',
  pojemos: 'pojemos',
  pojinho: 'pojinho',
  pojinos: 'pojinos',
  pojixas: 'pojixás',
  pojojis: 'pojojis',
  polabia: 'polábia',
  polabio: 'polábio',
  polacas: 'polacas',
  polacha: 'polacha',
  polacis: 'polacis',
  polacos: 'polacos',
  polacra: 'polacra',
  polador: 'polador',
  polaina: 'polaina',
  polaino: 'polaino',
  polarao: 'polarão',
  polarda: 'polarda',
  polares: 'polares',
  polaron: 'polaron',
  polcada: 'polcada',
  polcado: 'polcado',
  polcais: 'polcais',
  polcamo: 'polcamo',
  polcara: 'polcará',
  polcava: 'polcava',
  polders: 'polders',
  poldina: 'poldina',
  poldras: 'poldras',
  poldril: 'poldril',
  poldris: 'poldris',
  poldros: 'poldros',
  poleada: 'poleada',
  poleado: 'poleado',
  poleais: 'poleais',
  poleame: 'poleame',
  poleara: 'poleará',
  poleava: 'poleava',
  poleeis: 'poleeis',
  polegai: 'polegai',
  polegam: 'polegam',
  polegar: 'polegar',
  polegas: 'polegas',
  polegou: 'polegou',
  polegue: 'polegue',
  poleiam: 'poleiam',
  poleias: 'poleias',
  poleiem: 'poleiem',
  poleies: 'poleies',
  poleira: 'poleira',
  poleiro: 'poleiro',
  polelas: 'polelas',
  polemia: 'polêmia',
  polenda: 'polenda',
  polenia: 'polênia',
  polense: 'polense',
  polenta: 'polenta',
  poliada: 'políada',
  poliade: 'políade',
  poliana: 'poliana',
  poliano: 'poliano',
  poliase: 'poliase',
  polibas: 'polibãs',
  polibia: 'políbia',
  polibio: 'políbio',
  policao: 'polição',
  policas: 'pólicas',
  polices: 'pólices',
  policia: 'polícia',
  policie: 'policie',
  policio: 'policio',
  policos: 'pólicos',
  policro: 'policro',
  polidao: 'polidão',
  polidas: 'polidas',
  polidez: 'polidez',
  polidor: 'polidor',
  polidos: 'polidos',
  polieas: 'políeas',
  polieca: 'polieca',
  polieis: 'políeis',
  polieno: 'polieno',
  polieto: 'polieto',
  polifie: 'polifie',
  polilha: 'polilha',
  polimos: 'polimos',
  polinar: 'polinar',
  polinas: 'polinas',
  polindo: 'polindo',
  polinea: 'polínea',
  polineo: 'políneo',
  polinia: 'polinia',
  polinio: 'polínio',
  polinoe: 'polínoe',
  poliodo: 'políodo',
  poliois: 'polióis',
  poliope: 'poliope',
  poliose: 'poliose',
  polipos: 'pólipos',
  poliram: 'poliram',
  polirao: 'polirão',
  poliras: 'poliras',
  polirei: 'polirei',
  polirem: 'polirem',
  polires: 'polires',
  poliria: 'poliria',
  polirmo: 'polirmo',
  polisse: 'polisse',
  polista: 'polista',
  poliste: 'poliste',
  polisto: 'polisto',
  politea: 'polítea',
  politeo: 'políteo',
  politia: 'politia',
  politmo: 'politmo',
  polmaco: 'polmaço',
  polmeai: 'polmeai',
  polmear: 'polmear',
  polmeei: 'polmeei',
  polmeia: 'polmeia',
  polmeie: 'polmeie',
  polmeio: 'polmeio',
  polmeou: 'polmeou',
  polmoes: 'polmões',
  polodia: 'polodia',
  poloide: 'poloide',
  polonas: 'polonas',
  polones: 'polonês',
  polonga: 'polonga',
  polonio: 'polônio',
  polonos: 'polonos',
  polpada: 'polpada',
  polpado: 'polpado',
  polpais: 'polpais',
  polpamo: 'polpamo',
  polpara: 'polpará',
  polpava: 'polpava',
  polpeis: 'polpeis',
  polpemo: 'polpemo',
  polpoes: 'polpões',
  polposa: 'polposa',
  polposo: 'polposo',
  polpuda: 'polpuda',
  polpudo: 'polpudo',
  polquei: 'polquei',
  polquem: 'polquem',
  polques: 'polques',
  poltrao: 'poltrão',
  poltrea: 'poltreá',
  poluais: 'poluais',
  polucao: 'polução',
  poluiam: 'poluíam',
  poluias: 'poluías',
  poluida: 'poluída',
  poluido: 'poluído',
  poluira: 'poluirá',
  polutas: 'polutas',
  polutos: 'polutos',
  polvora: 'pólvora',
  pomacea: 'pomácea',
  pomaceo: 'pomáceo',
  pomadas: 'pomadas',
  pomadea: 'pomadeá',
  pomagem: 'pomagem',
  pomarea: 'pomárea',
  pomares: 'pomares',
  pomaria: 'pomária',
  pomaris: 'pomaris',
  pombais: 'pombais',
  pombeai: 'pombeai',
  pombear: 'pombear',
  pombeei: 'pombeei',
  pombeia: 'pombeia',
  pombeie: 'pombeie',
  pombeio: 'pombeio',
  pombela: 'pombela',
  pombeou: 'pombeou',
  pomboca: 'pomboca',
  pomeada: 'pomeada',
  pomeado: 'pomeado',
  pomeais: 'pomeais',
  pomeara: 'pomeará',
  pomeava: 'pomeava',
  pomecia: 'pomécia',
  pomecio: 'pomécio',
  pomeeis: 'pomeeis',
  pomeiam: 'pomeiam',
  pomeias: 'pomeias',
  pomeiem: 'pomeiem',
  pomeies: 'pomeies',
  pomeira: 'pomeira',
  pomeiro: 'pomeiro',
  pomejai: 'pomejai',
  pomejam: 'pomejam',
  pomejar: 'pomejar',
  pomejas: 'pomejas',
  pomejei: 'pomejei',
  pomejem: 'pomejem',
  pomejes: 'pomejes',
  pomejou: 'pomejou',
  pomelos: 'pomelos',
  pomerio: 'pomério',
  pomicea: 'pomícea',
  pomiceo: 'pomíceo',
  pomices: 'pômices',
  pomitas: 'pomitas',
  pomitos: 'pomitos',
  pomizai: 'pomizai',
  pomizam: 'pomizam',
  pomizar: 'pomizar',
  pomizas: 'pomizas',
  pomizei: 'pomizei',
  pomizem: 'pomizem',
  pomizes: 'pomizes',
  pomizou: 'pomizou',
  pomonas: 'pomonas',
  pomonga: 'pomonga',
  pomosas: 'pomosas',
  pomosos: 'pomosos',
  pomoxis: 'pomóxis',
  pompeai: 'pompeai',
  pompear: 'pompear',
  pompeei: 'pompeei',
  pompeia: 'pompeia',
  pompeie: 'pompeie',
  pompeio: 'pompeio',
  pompeou: 'pompeou',
  pompilo: 'pômpilo',
  pompoar: 'pompoar',
  pompons: 'pompons',
  pomposa: 'pomposa',
  pomposo: 'pomposo',
  pomulos: 'pômulos',
  ponacas: 'ponacas',
  poncada: 'ponçada',
  poncado: 'ponçado',
  poncais: 'ponçais',
  poncamo: 'ponçamo',
  poncara: 'ponçará',
  poncava: 'ponçava',
  ponceis: 'ponceis',
  poncemo: 'poncemo',
  ponchas: 'ponchas',
  ponches: 'ponches',
  ponchos: 'ponchos',
  ponciro: 'pônciro',
  ponciva: 'ponciva',
  poncivo: 'poncivo',
  pondera: 'pondera',
  pondere: 'pondere',
  pondero: 'pondero',
  pondoro: 'pondoro',
  pondras: 'pondras',
  ponente: 'ponente',
  poneras: 'poneras',
  pongada: 'pongada',
  pongado: 'pongado',
  pongais: 'pongais',
  pongara: 'pongará',
  pongava: 'pongava',
  pongida: 'pôngida',
  ponguei: 'ponguei',
  ponguem: 'ponguem',
  pongues: 'pongues',
  ponhada: 'ponhada',
  ponhado: 'ponhado',
  ponhais: 'ponhais',
  ponhamo: 'ponhamo',
  ponhara: 'ponhará',
  ponhava: 'ponhava',
  ponheis: 'ponheis',
  ponhema: 'ponhema',
  ponhemo: 'ponhemo',
  ponilha: 'ponilha',
  ponoses: 'ponoses',
  pontaco: 'pontaço',
  pontada: 'pontada',
  pontado: 'pontado',
  pontais: 'pontais',
  pontamo: 'pontamo',
  pontano: 'pontano',
  pontape: 'pontapé',
  pontara: 'pontara',
  pontari: 'pontari',
  pontava: 'pontava',
  ponteai: 'ponteai',
  pontear: 'pontear',
  ponteco: 'ponteco',
  ponteei: 'ponteei',
  ponteia: 'ponteia',
  ponteie: 'ponteie',
  ponteio: 'ponteio',
  ponteis: 'ponteis',
  pontela: 'pontela',
  pontemo: 'pontemo',
  ponteou: 'ponteou',
  pontica: 'pôntica',
  pontico: 'pôntico',
  pontigo: 'pontigo',
  pontina: 'pontina',
  pontino: 'pontino',
  pontoai: 'pontoai',
  pontoam: 'pontoam',
  pontoar: 'pontoar',
  pontoas: 'pontoas',
  pontoei: 'pontoei',
  pontoem: 'pontoem',
  pontoes: 'pontões',
  pontoou: 'pontoou',
  pontosa: 'pontosa',
  pontoso: 'pontoso',
  pontuai: 'pontuai',
  pontual: 'pontual',
  pontuam: 'pontuam',
  pontuar: 'pontuar',
  pontuas: 'pontuas',
  pontuda: 'pontuda',
  pontudo: 'pontudo',
  pontuei: 'pontuei',
  pontuem: 'pontuem',
  pontues: 'pontues',
  pontuou: 'pontuou',
  poodles: 'poodles',
  popanas: 'popanas',
  popanca: 'popança',
  popeira: 'popeira',
  popeiro: 'popeiro',
  popelas: 'popelas',
  poperis: 'poperis',
  popiada: 'popiada',
  popiado: 'popiado',
  popiais: 'popiais',
  popiamo: 'popiamo',
  popiara: 'popiará',
  popiava: 'popiava',
  popieis: 'popieis',
  popiemo: 'popiemo',
  popinas: 'popinas',
  popinha: 'popinha',
  popismo: 'popismo',
  popista: 'popista',
  poplite: 'póplite',
  popocai: 'popocai',
  popocam: 'popocam',
  popocar: 'popocar',
  popocas: 'popocas',
  popocou: 'popocou',
  popoque: 'popoque',
  popovia: 'popóvia',
  poppela: 'poppela',
  popucas: 'popucas',
  populai: 'populai',
  populam: 'populam',
  popular: 'popular',
  populas: 'populas',
  populea: 'populeã',
  populei: 'populei',
  populem: 'populem',
  populeo: 'popúleo',
  popules: 'popules',
  populos: 'pópulos',
  populou: 'populou',
  poqueca: 'poqueca',
  poqueis: 'poqueis',
  poquemo: 'poquemo',
  poracas: 'poracás',
  poracea: 'porácea',
  poraceo: 'poráceo',
  poraces: 'poracés',
  poranas: 'poranas',
  poranea: 'porânea',
  poranga: 'poranga',
  porania: 'porânia',
  poraque: 'poraquê',
  porcaco: 'porcaço',
  porcada: 'porcada',
  porcias: 'pórcias',
  porcina: 'porcina',
  porcino: 'porcino',
  porcoca: 'porcoça',
  porcoes: 'porções',
  porcona: 'porcona',
  porcuns: 'porcuns',
  porejai: 'porejai',
  porejam: 'porejam',
  porejar: 'porejar',
  porejas: 'porejas',
  porejei: 'porejei',
  porejem: 'porejem',
  porejes: 'porejes',
  porejou: 'porejou',
  porelas: 'porelas',
  poremos: 'poremos',
  porende: 'porende',
  porfiai: 'porfiai',
  porfiam: 'porfiam',
  porfiar: 'porfiar',
  porfias: 'porfias',
  porfido: 'pórfido',
  porfiei: 'porfiei',
  porfiem: 'porfiem',
  porfies: 'porfies',
  porfina: 'porfina',
  porfiou: 'porfiou',
  porfira: 'pórfira',
  porfiro: 'pórfiro',
  porieis: 'poríeis',
  porinas: 'porinas',
  porions: 'pórions',
  porisma: 'porisma',
  porites: 'porites',
  poritos: 'poritos',
  porneia: 'porneia',
  porneus: 'porneus',
  porocos: 'porocos',
  porodia: 'porodia',
  porodos: 'porodós',
  poromas: 'poromas',
  poromia: 'porômia',
  porongo: 'porongo',
  poronia: 'porônia',
  pororom: 'pororom',
  pororos: 'pororós',
  porosas: 'porosas',
  poroses: 'poroses',
  porosos: 'porosos',
  porotas: 'porotas',
  porpaus: 'porpaus',
  porpita: 'porpita',
  porques: 'porquês',
  porrada: 'porrada',
  porrado: 'porrado',
  porrais: 'porrais',
  porrege: 'porrege',
  porregi: 'porregi',
  porreja: 'porreja',
  porrejo: 'porrejo',
  porreta: 'porreta',
  porrete: 'porrete',
  porreto: 'porreto',
  porrica: 'pórrica',
  porrico: 'pórrico',
  porrigo: 'porrigo',
  porrina: 'porrina',
  porrios: 'pórrios',
  porroes: 'porrões',
  porroma: 'porroma',
  porruda: 'porruda',
  porrudo: 'porrudo',
  porruns: 'porruns',
  portada: 'portada',
  portado: 'portado',
  portais: 'portais',
  portaja: 'portajá',
  portaje: 'portaje',
  portajo: 'portajo',
  portalo: 'portaló',
  portamo: 'portamo',
  portara: 'portara',
  portava: 'portava',
  porteai: 'porteai',
  portear: 'portear',
  porteas: 'pórteas',
  porteei: 'porteei',
  porteia: 'porteia',
  porteie: 'porteie',
  porteio: 'porteio',
  porteis: 'porteis',
  portela: 'portela',
  portelo: 'portelo',
  portemo: 'portemo',
  porteou: 'porteou',
  porteta: 'porteta',
  portica: 'pórtica',
  portico: 'pórtico',
  portita: 'portita',
  portoes: 'portões',
  portora: 'portora',
  portuga: 'portuga',
  porucas: 'porucas',
  porunga: 'porunga',
  porutis: 'porutis',
  porzana: 'porzana',
  porzita: 'porzita',
  posadao: 'posadão',
  posadas: 'posadas',
  posador: 'posador',
  posados: 'posados',
  posamos: 'posamos',
  posando: 'posando',
  posanga: 'posanga',
  posante: 'posante',
  posaram: 'posaram',
  posarao: 'posarão',
  posaras: 'posaras',
  posarei: 'posarei',
  posarem: 'posarem',
  posares: 'posares',
  posaria: 'posaria',
  posarmo: 'posarmo',
  posasse: 'posasse',
  posaste: 'posaste',
  posavam: 'posavam',
  posavas: 'posavas',
  posdata: 'posdata',
  posemos: 'posemos',
  posicao: 'posição',
  posinga: 'posinga',
  positao: 'positão',
  positon: 'pósiton',
  pospelo: 'pospelo',
  pospoem: 'pospõem',
  pospoes: 'pospões',
  pospomo: 'pospomo',
  pospora: 'posporá',
  possada: 'possada',
  possado: 'possado',
  possais: 'possais',
  possamo: 'possamo',
  possara: 'possará',
  possava: 'possava',
  posseai: 'posseai',
  possear: 'possear',
  posseei: 'posseei',
  posseia: 'posseia',
  posseie: 'posseie',
  posseio: 'posseio',
  posseis: 'posseis',
  possemo: 'possemo',
  posseou: 'posseou',
  possoca: 'possoca',
  possuam: 'possuam',
  possuas: 'possuas',
  possuca: 'possuca',
  possuem: 'possuem',
  possues: 'possues',
  possuia: 'possuía',
  possuir: 'possuir',
  possuis: 'possuis',
  possuiu: 'possuiu',
  postada: 'postada',
  postado: 'postado',
  postais: 'postais',
  postamo: 'postamo',
  postara: 'postara',
  postava: 'postava',
  posteai: 'posteai',
  postear: 'postear',
  posteei: 'posteei',
  postega: 'postega',
  postego: 'postego',
  posteia: 'posteia',
  posteie: 'posteie',
  posteio: 'posteio',
  posteis: 'posteis',
  posteja: 'posteja',
  posteje: 'posteje',
  postejo: 'postejo',
  postema: 'postema',
  posteme: 'posteme',
  postemo: 'postemo',
  posteou: 'posteou',
  postera: 'póstera',
  postero: 'póstero',
  postica: 'postiça',
  postice: 'postice',
  postico: 'postiço',
  postigo: 'postigo',
  postila: 'postila',
  postile: 'postile',
  postilo: 'postilo',
  postite: 'postite',
  postres: 'postres',
  postula: 'postula',
  postule: 'postule',
  postulo: 'postulo',
  postuma: 'póstuma',
  postumo: 'póstumo',
  postura: 'postura',
  posture: 'posture',
  posturo: 'posturo',
  posuais: 'posuais',
  posudas: 'posudas',
  posudos: 'posudos',
  potabas: 'potabas',
  potagem: 'potagem',
  potages: 'potages',
  potalia: 'potália',
  potamea: 'potâmea',
  potameo: 'potâmeo',
  potamis: 'pótamis',
  potanca: 'potança',
  potassa: 'potassa',
  potavas: 'potavas',
  potavel: 'potável',
  poteias: 'poteias',
  potente: 'potente',
  poterio: 'potério',
  poteris: 'poteris',
  poterna: 'poterna',
  potiais: 'potiais',
  potiale: 'potiale',
  potiche: 'potiche',
  poticis: 'poticis',
  potieas: 'potíeas',
  potinho: 'potinho',
  potiqui: 'potiqui',
  potirao: 'potirão',
  potiris: 'potiris',
  potirom: 'potirom',
  potiuna: 'potiúna',
  potobos: 'potobós',
  potocai: 'potocai',
  potocam: 'potocam',
  potocar: 'potocar',
  potocas: 'potocas',
  potocou: 'potocou',
  potoeas: 'potóeas',
  potonas: 'potonas',
  potoque: 'potoque',
  potosia: 'potósia',
  potosis: 'potosis',
  pototes: 'pototes',
  potrada: 'potrada',
  potreai: 'potreai',
  potrear: 'potrear',
  potreas: 'pótreas',
  potreco: 'potreco',
  potreei: 'potreei',
  potreia: 'potreia',
  potreie: 'potreie',
  potreio: 'potreio',
  potreou: 'potreou',
  potrosa: 'potrosa',
  potroso: 'potroso',
  potruco: 'potruco',
  potruda: 'potruda',
  potrudo: 'potrudo',
  pottial: 'pottial',
  pottias: 'póttias',
  poturus: 'poturus',
  poucoes: 'poucões',
  poucona: 'poucona',
  poupada: 'poupada',
  poupado: 'poupado',
  poupais: 'poupais',
  poupamo: 'poupamo',
  poupara: 'poupara',
  poupava: 'poupava',
  poupeis: 'poupeis',
  poupemo: 'poupemo',
  poupoes: 'poupões',
  poupona: 'poupona',
  poupuda: 'poupuda',
  poupudo: 'poupudo',
  pousada: 'pousada',
  pousado: 'pousado',
  pousais: 'pousais',
  pousamo: 'pousamo',
  pousara: 'pousara',
  pousava: 'pousava',
  pouseis: 'pouseis',
  pousemo: 'pousemo',
  pousias: 'pousias',
  pousios: 'pousios',
  poutada: 'poutada',
  poutado: 'poutado',
  poutais: 'poutais',
  poutamo: 'poutamo',
  poutara: 'poutará',
  poutava: 'poutava',
  pouteis: 'pouteis',
  poutemo: 'poutemo',
  poutoes: 'poutões',
  povareu: 'povaréu',
  poveira: 'poveira',
  poveiro: 'poveiro',
  povense: 'povense',
  povileu: 'poviléu',
  povinha: 'povinha',
  povinho: 'povinho',
  povoada: 'povoada',
  povoado: 'povoado',
  povoais: 'povoais',
  povoamo: 'povoamo',
  povoara: 'povoara',
  povoava: 'povoava',
  povoeis: 'povoeis',
  povoemo: 'povoemo',
  povoleu: 'povoléu',
  povoreu: 'povoréu',
  povotos: 'povotos',
  poxetis: 'poxetis',
  poxoxos: 'poxoxôs',
  pozeira: 'pozeira',
  pozinho: 'pozinho',
  pracari: 'pracari',
  pracata: 'pracata',
  pracate: 'pracate',
  pracato: 'pracato',
  pracaxi: 'pracaxi',
  praceai: 'praceai',
  pracear: 'pracear',
  pracebo: 'pracebo',
  praceei: 'praceei',
  praceia: 'praceia',
  praceie: 'praceie',
  praceio: 'praceio',
  praceja: 'praceja',
  praceje: 'praceje',
  pracejo: 'pracejo',
  praceou: 'praceou',
  praceta: 'praceta',
  pracias: 'prácias',
  pradeja: 'pradeja',
  pradeje: 'pradeje',
  pradejo: 'pradejo',
  pradosa: 'pradosa',
  pradoso: 'pradoso',
  pragada: 'pragada',
  pragado: 'pragado',
  pragais: 'pragais',
  pragana: 'pragana',
  pragara: 'pragará',
  pragata: 'pragata',
  pragava: 'pragava',
  pragoes: 'pragões',
  praguas: 'praguás',
  praguei: 'praguei',
  praguem: 'praguem',
  pragues: 'pragues',
  praiada: 'praiada',
  praiana: 'praiana',
  praiano: 'praiano',
  prainas: 'prainas',
  prainha: 'prainha',
  prainos: 'prainos',
  pralina: 'pralina',
  praline: 'praliné',
  pramana: 'pramana',
  pranale: 'prânale',
  prancha: 'prancha',
  pranche: 'pranche',
  prancho: 'prancho',
  prandio: 'prândio',
  praneza: 'praneza',
  prangas: 'prangas',
  prangos: 'prangos',
  praniza: 'praniza',
  prantai: 'prantai',
  prantam: 'prantam',
  prantar: 'prantar',
  prantas: 'prantas',
  prantea: 'pranteá',
  prantei: 'prantei',
  prantem: 'prantem',
  prantes: 'prantes',
  prantos: 'prantos',
  prantou: 'prantou',
  praquia: 'praquia',
  praquio: 'praquio',
  prasias: 'prásias',
  prasina: 'prásina',
  prasino: 'prásino',
  prasios: 'prásios',
  prasmai: 'prasmai',
  prasmam: 'prasmam',
  prasmar: 'prasmar',
  prasmas: 'prasmas',
  prasmei: 'prasmei',
  prasmem: 'prasmem',
  prasmes: 'prasmes',
  prasmou: 'prasmou',
  pratada: 'pratada',
  prateai: 'prateai',
  pratear: 'pratear',
  prateei: 'prateei',
  prateia: 'prateia',
  prateie: 'prateie',
  prateio: 'prateio',
  prateis: 'pratéis',
  prateja: 'pratejá',
  prateje: 'prateje',
  pratejo: 'pratejo',
  prateou: 'prateou',
  pratias: 'prátias',
  pratibu: 'pratibu',
  pratica: 'prática',
  pratico: 'prático',
  pratita: 'pratita',
  pratoes: 'pratões',
  praxada: 'praxada',
  praxado: 'praxado',
  praxais: 'praxais',
  praxamo: 'praxamo',
  praxara: 'praxará',
  praxava: 'praxava',
  praxeis: 'praxeis',
  praxemo: 'praxemo',
  praxias: 'praxias',
  praxica: 'práxica',
  praxico: 'práxico',
  prazada: 'prazada',
  prazado: 'prazado',
  prazera: 'prazerá',
  praziam: 'praziam',
  prazida: 'prazida',
  prazido: 'prazido',
  preacaa: 'preacaá',
  preacao: 'preação',
  preacas: 'preacas',
  preadao: 'preadão',
  preadas: 'preadas',
  preador: 'preador',
  preados: 'preados',
  preamai: 'preamai',
  preamam: 'preamam',
  preamar: 'preamar',
  preamas: 'preamas',
  preamei: 'preamei',
  preamem: 'preamem',
  preames: 'preames',
  preamos: 'preamos',
  preamou: 'preamou',
  preando: 'preando',
  preante: 'preante',
  prearam: 'prearam',
  prearao: 'prearão',
  prearas: 'prearás',
  prearei: 'prearei',
  prearem: 'prearem',
  preares: 'preares',
  prearia: 'prearia',
  preasse: 'preasse',
  preaste: 'preaste',
  preavam: 'preavam',
  preavas: 'preavas',
  precata: 'precata',
  precate: 'precate',
  precato: 'precato',
  precava: 'precava',
  precave: 'precavê',
  precavi: 'precavi',
  precavo: 'precavo',
  preceda: 'preceda',
  precede: 'precede',
  precedi: 'precedi',
  precedo: 'precedo',
  prechas: 'prechas',
  precias: 'précias',
  precios: 'précios',
  precisa: 'precisa',
  precise: 'precise',
  preciso: 'preciso',
  precita: 'precita',
  precito: 'precito',
  preclua: 'preclua',
  preclui: 'preclui',
  precluo: 'precluo',
  precoce: 'precoce',
  predada: 'predada',
  predado: 'predado',
  predais: 'predais',
  predara: 'predará',
  predata: 'predata',
  predate: 'predate',
  predato: 'predato',
  predava: 'predava',
  predeem: 'predeem',
  predeis: 'predeis',
  predela: 'predela',
  predera: 'predera',
  predial: 'predial',
  predica: 'prédica',
  predico: 'predico',
  prediga: 'prediga',
  predigo: 'predigo',
  predios: 'prédios',
  predira: 'predirá',
  predita: 'predita',
  predite: 'predite',
  predito: 'predito',
  predize: 'predize',
  preemos: 'preemos',
  prefaca: 'prefaça',
  prefaco: 'prefaço',
  prefara: 'prefará',
  prefaze: 'prefaze',
  prefere: 'prefere',
  preferi: 'preferi',
  prefica: 'préfica',
  prefina: 'prefina',
  prefine: 'prefine',
  prefini: 'prefini',
  prefino: 'prefino',
  prefira: 'prefira',
  prefiro: 'prefiro',
  prefixa: 'prefixa',
  prefixe: 'prefixe',
  prefixo: 'prefixo',
  pregada: 'pregada',
  pregado: 'pregado',
  pregais: 'pregais',
  pregamo: 'pregamo',
  pregana: 'pregana',
  pregara: 'pregara',
  pregava: 'pregava',
  pregnai: 'pregnai',
  pregnam: 'pregnam',
  pregnar: 'pregnar',
  pregnas: 'pregnas',
  pregnei: 'pregnei',
  pregnem: 'pregnem',
  pregnes: 'pregnes',
  pregnou: 'pregnou',
  pregoai: 'pregoai',
  pregoam: 'pregoam',
  pregoar: 'pregoar',
  pregoas: 'pregoas',
  pregoei: 'pregoei',
  pregoem: 'pregoem',
  pregoes: 'pregões',
  pregoou: 'pregoou',
  preguas: 'préguas',
  preguea: 'pregueá',
  preguei: 'preguei',
  preguem: 'preguem',
  pregues: 'pregues',
  preitai: 'preitai',
  preitam: 'preitam',
  preitar: 'preitar',
  preitas: 'preitas',
  preitea: 'preiteá',
  preitei: 'preitei',
  preitem: 'preitem',
  preites: 'preitês',
  preitos: 'preitos',
  preitou: 'preitou',
  prelada: 'prelada',
  prelado: 'prelado',
  preleva: 'preleva',
  preleve: 'preleve',
  prelevo: 'prelevo',
  preliai: 'preliai',
  preliam: 'preliam',
  preliar: 'preliar',
  prelias: 'prelias',
  preliba: 'preliba',
  prelibe: 'prelibe',
  prelibo: 'prelibo',
  preliei: 'preliei',
  preliem: 'preliem',
  prelies: 'prelies',
  prelios: 'prélios',
  preliou: 'preliou',
  preluza: 'preluza',
  preluze: 'preluze',
  preluzi: 'preluzi',
  preluzo: 'preluzo',
  premada: 'premada',
  premado: 'premado',
  premais: 'premais',
  premamo: 'premamo',
  premara: 'premará',
  premava: 'premava',
  premeio: 'premeio',
  premeis: 'premeis',
  prememo: 'prememo',
  premera: 'premera',
  premiai: 'premiai',
  premiam: 'premiam',
  premiar: 'premiar',
  premias: 'premias',
  premida: 'premida',
  premido: 'premido',
  premiei: 'premiei',
  premiem: 'premiem',
  premier: 'premier',
  premies: 'premies',
  premimo: 'premimo',
  premios: 'prêmios',
  premiou: 'premiou',
  premira: 'premira',
  premuda: 'premuda',
  premude: 'premude',
  premudo: 'premudo',
  premuna: 'premuna',
  premune: 'premune',
  premuni: 'premuni',
  premuno: 'premuno',
  premura: 'premura',
  prenato: 'prenato',
  prendai: 'prendai',
  prendam: 'prendam',
  prendar: 'prendar',
  prendas: 'prendas',
  prendei: 'prendei',
  prendem: 'prendem',
  prender: 'prender',
  prendes: 'prendes',
  prendeu: 'prendeu',
  prendia: 'prendia',
  prendou: 'prendou',
  prenhai: 'prenhai',
  prenham: 'prenham',
  prenhar: 'prenhar',
  prenhas: 'prenhas',
  prenhei: 'prenhei',
  prenhem: 'prenhem',
  prenhes: 'prenhes',
  prenhez: 'prenhez',
  prenhou: 'prenhou',
  prenica: 'prênica',
  prenico: 'prênico',
  prenita: 'prenita',
  prenome: 'prenome',
  prenota: 'prenota',
  prenote: 'prenote',
  prenoto: 'prenoto',
  prensai: 'prensai',
  prensam: 'prensam',
  prensar: 'prensar',
  prensas: 'prensas',
  prensei: 'prensei',
  prensem: 'prensem',
  prenses: 'prenses',
  prensou: 'prensou',
  prepara: 'prepara',
  prepare: 'prepare',
  preparo: 'preparo',
  prepaus: 'prepaus',
  prepoem: 'prepoém',
  prepoes: 'prepões',
  prepomo: 'prepomo',
  prepona: 'prepona',
  prepora: 'preporá',
  prepupa: 'prepupa',
  prepusa: 'prepusa',
  presada: 'presada',
  presado: 'presado',
  presaga: 'presaga',
  presago: 'presago',
  presais: 'presais',
  presamo: 'presamo',
  presara: 'presará',
  presava: 'presava',
  presbis: 'presbis',
  preseis: 'preseis',
  preselo: 'preselo',
  presemo: 'presemo',
  presepe: 'presepe',
  preseta: 'preseta',
  presida: 'presida',
  preside: 'preside',
  presidi: 'presidi',
  presido: 'presido',
  presiga: 'presiga',
  presigo: 'presigo',
  preslia: 'préslia',
  pressao: 'pressão',
  pressas: 'pressas',
  pressor: 'pressor',
  pressos: 'pressos',
  prestai: 'prestai',
  prestal: 'prestal',
  prestam: 'prestam',
  prestar: 'prestar',
  prestas: 'prestas',
  prestei: 'prestei',
  prestem: 'prestem',
  prestes: 'prestes',
  prestou: 'prestou',
  presuma: 'presuma',
  presume: 'presume',
  presumi: 'presumi',
  presumo: 'presumo',
  presura: 'presura',
  pretana: 'pretana',
  pretano: 'pretano',
  preteai: 'preteai',
  pretear: 'pretear',
  preteei: 'preteei',
  preteia: 'preteia',
  preteie: 'preteie',
  preteio: 'preteio',
  preteja: 'preteja',
  preteje: 'preteje',
  pretejo: 'pretejo',
  preteou: 'preteou',
  pretere: 'pretere',
  preteri: 'preteri',
  pretiga: 'prétiga',
  pretira: 'pretira',
  pretiro: 'pretiro',
  pretoes: 'pretões',
  pretona: 'pretona',
  pretora: 'pretora',
  pretume: 'pretume',
  pretura: 'pretura',
  prevale: 'prevale',
  prevali: 'prevali',
  prevede: 'prevede',
  preveem: 'preveem',
  preveis: 'preveis',
  preveja: 'preveja',
  prevejo: 'prevejo',
  prevemo: 'prevemo',
  preveni: 'preveni',
  prevera: 'preverá',
  previam: 'previam',
  previas: 'previas',
  previda: 'previda',
  prevido: 'prevido',
  previna: 'previna',
  previne: 'previne',
  previno: 'previno',
  previos: 'prévios',
  previra: 'previra',
  previsa: 'previsa',
  previso: 'previso',
  previva: 'previva',
  previve: 'previve',
  previvi: 'previvi',
  previvo: 'previvo',
  prexeca: 'prexeca',
  prezada: 'prezada',
  prezado: 'prezado',
  prezais: 'prezais',
  prezamo: 'prezamo',
  prezara: 'prezara',
  prezava: 'prezava',
  prezeas: 'prézeas',
  prezeis: 'prezeis',
  prezemo: 'prezemo',
  priacas: 'priacas',
  priamos: 'príamos',
  priangu: 'priangu',
  prianta: 'prianta',
  priapeu: 'priapeu',
  priapos: 'priapos',
  prijuis: 'prijuís',
  primada: 'primada',
  primado: 'primado',
  primais: 'primais',
  primamo: 'primamo',
  primara: 'primara',
  primata: 'primata',
  primate: 'primate',
  primava: 'primava',
  primeis: 'primeis',
  primemo: 'primemo',
  primeva: 'primeva',
  primevo: 'primevo',
  primina: 'primina',
  primnoa: 'prímnoa',
  primora: 'primora',
  primore: 'primore',
  primoro: 'primoro',
  primula: 'prímula',
  princes: 'princês',
  prinias: 'prínias',
  priobio: 'prióbio',
  priones: 'príones',
  prionia: 'priônia',
  prionio: 'priônio',
  prionos: 'príonos',
  priorai: 'priorai',
  prioral: 'prioral',
  prioram: 'prioram',
  priorar: 'priorar',
  prioras: 'prioras',
  priorei: 'priorei',
  priorem: 'priorem',
  priores: 'priores',
  prioris: 'prioris',
  priorou: 'priorou',
  prioste: 'prioste',
  priscai: 'priscai',
  priscam: 'priscam',
  priscar: 'priscar',
  priscas: 'priscas',
  priscos: 'priscos',
  priscou: 'priscou',
  prismai: 'prismai',
  prismam: 'prismam',
  prismar: 'prismar',
  prismas: 'prismas',
  prismea: 'prísmea',
  prismei: 'prismei',
  prismem: 'prismem',
  prismeo: 'prísmeo',
  prismes: 'prismes',
  prismou: 'prismou',
  prisoes: 'prisões',
  prisona: 'prisona',
  prisopo: 'prísopo',
  prisque: 'prisque',
  pristas: 'pristas',
  pristes: 'pristes',
  pristis: 'prístis',
  pristos: 'pristos',
  pritane: 'prítane',
  pritego: 'prítego',
  pritica: 'prítica',
  pritico: 'prítico',
  pritiga: 'prítiga',
  pritive: 'prítive',
  privada: 'privada',
  privado: 'privado',
  privais: 'privais',
  privamo: 'privamo',
  privara: 'privara',
  privava: 'privava',
  priveis: 'priveis',
  privemo: 'privemo',
  prixita: 'prixita',
  proadao: 'proadão',
  proadas: 'proadas',
  proador: 'proador',
  proados: 'proados',
  proales: 'proales',
  proamos: 'proamos',
  proando: 'proando',
  proante: 'proante',
  proaram: 'proaram',
  proarao: 'proarão',
  proaras: 'proarás',
  proarei: 'proarei',
  proarem: 'proarem',
  proares: 'proares',
  proaria: 'proaria',
  proarmo: 'proarmo',
  proasse: 'proasse',
  proaste: 'proaste',
  proavam: 'proavam',
  proavas: 'proavas',
  proavia: 'proávia',
  proavos: 'próavos',
  procace: 'procace',
  procais: 'próçais',
  procamo: 'próçamo',
  proceda: 'proceda',
  procede: 'procede',
  procedi: 'procedi',
  procedo: 'procedo',
  proceis: 'próceis',
  procela: 'procela',
  procelo: 'procelo',
  procemo: 'prócemo',
  procera: 'procera',
  procere: 'prócere',
  procero: 'prócero',
  prociam: 'próciam',
  procias: 'prócias',
  procida: 'prócida',
  procido: 'prócido',
  procies: 'prócies',
  procion: 'prócion',
  procios: 'prócios',
  proclea: 'próclea',
  procnia: 'prócnia',
  procolo: 'procolo',
  procoto: 'procotó',
  procria: 'procria',
  procrie: 'procrie',
  procrio: 'procrio',
  procris: 'prócris',
  proctal: 'proctal',
  proctos: 'proctos',
  procura: 'procura',
  procure: 'procure',
  procuro: 'procuro',
  procuta: 'procuta',
  procuto: 'procuto',
  prodajo: 'prodajo',
  prodero: 'prodero',
  prodica: 'pródica',
  prodico: 'pródico',
  prodiga: 'pródiga',
  prodigo: 'pródigo',
  prodita: 'pródita',
  prodito: 'pródito',
  produto: 'produto',
  produza: 'produza',
  produze: 'produze',
  produzi: 'produzi',
  produzo: 'produzo',
  proedro: 'proedro',
  proeira: 'proeira',
  proeiro: 'proeiro',
  proejai: 'proejai',
  proejam: 'proejam',
  proejar: 'proejar',
  proejas: 'proejas',
  proejei: 'proejei',
  proejem: 'proejem',
  proejes: 'proejes',
  proejou: 'proejou',
  proemia: 'proemia',
  proemie: 'proemie',
  proemio: 'proêmio',
  proemos: 'proemos',
  proezas: 'proezas',
  profaca: 'profaça',
  proface: 'proface',
  profaco: 'profaço',
  profaga: 'prófaga',
  profago: 'prófago',
  profana: 'profana',
  profane: 'profane',
  profano: 'profano',
  profase: 'prófase',
  profere: 'profere',
  proferi: 'proferi',
  profeta: 'profeta',
  profete: 'profete',
  profeto: 'profeto',
  profila: 'profila',
  profile: 'profile',
  profilo: 'profilo',
  profira: 'profira',
  profiro: 'profiro',
  profise: 'prófise',
  profuga: 'prófuga',
  profugo: 'prófugo',
  profusa: 'profusa',
  profuso: 'profuso',
  prognes: 'prognes',
  progona: 'prógona',
  progono: 'prógono',
  proibam: 'proíbam',
  proibas: 'proíbas',
  proibem: 'proíbem',
  proibes: 'proíbes',
  proibia: 'proibia',
  proibir: 'proibir',
  proibis: 'proibis',
  proibiu: 'proibiu',
  proizes: 'proizes',
  projeta: 'projeta',
  projete: 'projete',
  projeto: 'projeto',
  prolana: 'prolana',
  prolano: 'prolano',
  prolans: 'prolans',
  prolata: 'prolata',
  prolate: 'prolate',
  prolato: 'prolato',
  prolema: 'prolema',
  proleta: 'proleta',
  prolica: 'prólica',
  prolico: 'prólico',
  prolina: 'prolina',
  prolixa: 'prolixa',
  prolixo: 'prolixo',
  prologa: 'prologa',
  prologo: 'prólogo',
  proluxo: 'proluxo',
  promana: 'promana',
  promane: 'promane',
  promano: 'promano',
  promero: 'prómero',
  prometa: 'prometa',
  promete: 'promete',
  prometi: 'prometi',
  prometo: 'prometo',
  promova: 'promova',
  promove: 'promove',
  promovi: 'promovi',
  promovo: 'promovo',
  pronada: 'pronada',
  pronaos: 'pronaos',
  pronaus: 'pronaus',
  pronoes: 'prônoes',
  pronome: 'pronome',
  pronoto: 'pronoto',
  prontas: 'prontas',
  prontos: 'prontos',
  pronuba: 'pronuba',
  pronubo: 'prônubo',
  propaga: 'propaga',
  propago: 'propago',
  propala: 'propala',
  propale: 'propale',
  propalo: 'propalo',
  propana: 'propana',
  propano: 'propano',
  propaus: 'propaus',
  propele: 'propele',
  propeli: 'propeli',
  propeno: 'propeno',
  propias: 'propiás',
  propila: 'propila',
  propilo: 'propilo',
  propina: 'propina',
  propine: 'propine',
  propino: 'propino',
  propoem: 'propõem',
  propoer: 'propoer',
  propoes: 'propões',
  propole: 'própole',
  propomo: 'propomo',
  propora: 'proporá',
  proporo: 'próporo',
  propoxi: 'propóxi',
  propria: 'própria',
  proprio: 'próprio',
  prorana: 'prorana',
  prorano: 'prorano',
  prorops: 'prórops',
  prosada: 'prosada',
  prosado: 'prosado',
  prosais: 'prosais',
  prosamo: 'prosamo',
  prosara: 'prosará',
  prosava: 'prosava',
  proseai: 'proseai',
  prosear: 'prosear',
  proseei: 'proseei',
  proseia: 'proseia',
  proseie: 'proseie',
  proseio: 'proseio',
  proseis: 'proseis',
  prosemo: 'prosemo',
  prosena: 'prosena',
  proseou: 'proseou',
  prosmas: 'prosmas',
  prosope: 'prosope',
  prostio: 'próstio',
  prostra: 'prostra',
  prostre: 'prostre',
  prostro: 'prostro',
  protalo: 'prótalo',
  protana: 'protana',
  protaro: 'protaro',
  protase: 'prótase',
  proteal: 'proteal',
  proteas: 'próteas',
  proteea: 'protéea',
  protege: 'protege',
  protegi: 'protegi',
  proteia: 'proteia',
  proteja: 'proteja',
  protejo: 'protejo',
  protela: 'protela',
  protele: 'protele',
  protelo: 'protelo',
  proteos: 'próteos',
  protese: 'prótese',
  proteus: 'proteus',
  protica: 'prótica',
  protico: 'prótico',
  protide: 'prótide',
  protido: 'prótido',
  protilo: 'prótilo',
  protima: 'protima',
  protino: 'protino',
  protios: 'prótios',
  protiro: 'prótiro',
  protoca: 'protoca',
  protoes: 'protões',
  protoma: 'prótoma',
  protomo: 'protomo',
  protona: 'protona',
  protone: 'protone',
  protono: 'protono',
  protons: 'prótons',
  protrai: 'protrai',
  protula: 'protula',
  protura: 'protura',
  proturo: 'proturo',
  protusa: 'protusa',
  protuso: 'protuso',
  prouvem: 'prouvem',
  prouver: 'prouver',
  prouves: 'prouvês',
  prouvia: 'prouvia',
  prouvir: 'prouvir',
  prouviu: 'prouviu',
  provada: 'provada',
  provado: 'provado',
  provais: 'provais',
  provamo: 'provamo',
  provara: 'provara',
  provava: 'provava',
  provede: 'provede',
  proveem: 'proveem',
  proveio: 'proveio',
  proveis: 'proveis',
  proveja: 'proveja',
  provejo: 'provejo',
  provemo: 'provemo',
  provens: 'provéns',
  provera: 'provera',
  proveta: 'proveta',
  provete: 'provete',
  proviam: 'proviam',
  provias: 'provias',
  provica: 'provica',
  provida: 'provida',
  provido: 'provido',
  provier: 'provier',
  provimo: 'provimo',
  provira: 'provirá',
  provoca: 'provoca',
  provoco: 'provoco',
  provoes: 'provões',
  provona: 'provona',
  proxema: 'proxema',
  proxeno: 'próxeno',
  proxima: 'próxima',
  proximo: 'próximo',
  prozada: 'prozada',
  prozado: 'prozado',
  prozais: 'prozais',
  prozamo: 'prozamo',
  prozara: 'prozara',
  prozava: 'prozava',
  prozeis: 'prozeis',
  prozemo: 'prozemo',
  pruamos: 'pruamos',
  pruidao: 'pruidão',
  pruidas: 'pruídas',
  pruidor: 'pruidor',
  pruidos: 'pruídos',
  pruieis: 'pruíeis',
  pruimos: 'pruímos',
  pruinas: 'pruínas',
  pruindo: 'pruindo',
  pruiram: 'pruíram',
  pruirao: 'pruirão',
  pruiras: 'pruíras',
  pruirei: 'pruirei',
  pruirem: 'pruírem',
  pruires: 'pruíres',
  pruiria: 'pruiria',
  pruisse: 'pruísse',
  pruiste: 'pruíste',
  pruivel: 'pruível',
  prumada: 'prumada',
  prumado: 'prumado',
  prumais: 'prumais',
  prumamo: 'prumamo',
  prumara: 'prumará',
  prumava: 'prumava',
  prumeis: 'prumeis',
  prumela: 'prumela',
  prumemo: 'prumemo',
  prumoes: 'prumões',
  prunela: 'prunela',
  prunina: 'prunina',
  prunulo: 'prúnulo',
  prurais: 'prurais',
  pruramo: 'pruramo',
  pruriam: 'pruriam',
  prurias: 'prurias',
  prurida: 'prurida',
  prurido: 'prurido',
  prurigo: 'prurigo',
  prurimo: 'prurimo',
  prurira: 'prurirá',
  prurito: 'prurito',
  prussas: 'prussas',
  prussos: 'prussos',
  psalide: 'psálide',
  psameco: 'psameco',
  psamita: 'psamita',
  psamite: 'psamite',
  psamito: 'psamito',
  psamoma: 'psamoma',
  psatira: 'psátira',
  pschent: 'pschent',
  psecade: 'psécade',
  psefita: 'psefita',
  psefite: 'psefite',
  psefito: 'psefito',
  psefoto: 'psefoto',
  psefuro: 'psefuro',
  pselafo: 'psélafo',
  pselios: 'psélios',
  psesias: 'psésias',
  psesios: 'psésios',
  pseudas: 'pseudas',
  pseudis: 'psêudis',
  pseudos: 'pseudos',
  psiadas: 'psiadas',
  psiadia: 'psiádia',
  psiados: 'psiados',
  psiches: 'psichés',
  psicode: 'psicode',
  psicose: 'psicose',
  psidios: 'psídios',
  psilago: 'psilago',
  psileta: 'psileta',
  psilida: 'psílida',
  psilios: 'psílios',
  psilito: 'psilito',
  psilope: 'psilope',
  psilose: 'psilose',
  psilota: 'psílota',
  psiloto: 'psíloto',
  psilura: 'psilura',
  psiluro: 'psiluro',
  psiques: 'psiques',
  psitaca: 'psítaca',
  psitaco: 'psítaco',
  psitias: 'psítias',
  psitino: 'psitino',
  psitios: 'psítios',
  psitira: 'psítira',
  psitiro: 'psítiro',
  psocido: 'psócido',
  psodimo: 'psódimo',
  psofias: 'psófias',
  psofida: 'psófida',
  psoites: 'psoítes',
  psorica: 'psórica',
  psorico: 'psórico',
  psorosa: 'psorosa',
  pteleas: 'ptéleas',
  pteleia: 'pteleia',
  ptenios: 'ptênios',
  ptenopo: 'ptênopo',
  pterela: 'pterela',
  pterial: 'pterial',
  pterica: 'ptérica',
  pterico: 'ptérico',
  pteride: 'ptéride',
  pterila: 'ptérila',
  pterilo: 'ptérilo',
  pterina: 'pterina',
  pterion: 'ptérion',
  pterios: 'ptérios',
  pterois: 'ptérois',
  pteroma: 'pteroma',
  pteropa: 'ptéropa',
  pteropo: 'ptéropo',
  pterula: 'ptérula',
  ptialos: 'ptialos',
  ptilino: 'ptilino',
  ptilose: 'ptilose',
  ptotico: 'ptótico',
  puadoes: 'puadões',
  puadona: 'puadona',
  puadora: 'puadora',
  puantes: 'puantes',
  puardes: 'puardes',
  puareis: 'puáreis',
  puaremo: 'puaremo',
  puariam: 'puariam',
  puarias: 'puarias',
  puarmos: 'puarmos',
  puassem: 'puassem',
  puasses: 'puasses',
  puastes: 'puastes',
  puaveis: 'puáveis',
  pubadao: 'pubadão',
  pubadas: 'pubadas',
  pubador: 'pubador',
  pubados: 'pubados',
  pubamos: 'pubamos',
  pubando: 'pubando',
  pubante: 'pubante',
  pubaram: 'pubaram',
  pubarao: 'pubarão',
  pubaras: 'pubarás',
  pubarca: 'pubarca',
  pubarei: 'pubarei',
  pubarem: 'pubarem',
  pubares: 'pubares',
  pubaria: 'pubaria',
  pubarmo: 'pubarmo',
  pubasse: 'pubasse',
  pubaste: 'pubaste',
  pubavam: 'pubavam',
  pubavas: 'pubavas',
  pubeira: 'pubeira',
  pubeiro: 'pubeiro',
  pubemos: 'pubemos',
  pubente: 'pubente',
  puberal: 'puberal',
  puberes: 'púberes',
  pubesca: 'pubesça',
  pubesce: 'pubesce',
  pubesci: 'pubesci',
  pubesco: 'pubesço',
  pubiana: 'pubiana',
  pubiano: 'pubiano',
  pubicas: 'púbicas',
  pubicos: 'púbicos',
  publica: 'pública',
  publico: 'público',
  pubrico: 'púbrico',
  pucacus: 'pucaçus',
  pucagua: 'puçaguá',
  pucanga: 'puçanga',
  pucaras: 'púcaras',
  pucarim: 'pucarim',
  pucaros: 'púcaros',
  puceira: 'puceira',
  puceiro: 'puceiro',
  pucelas: 'pucelas',
  puceros: 'púceros',
  puchara: 'púchara',
  pucharo: 'púcharo',
  puchero: 'puchero',
  puchons: 'puchons',
  pucinas: 'pucinas',
  pucinia: 'pucínia',
  pucinos: 'pucinos',
  pucucas: 'puçucas',
  pucumas: 'pucumãs',
  pucuris: 'pucuris',
  pudamos: 'pudamos',
  pudedor: 'pudedor',
  pudemos: 'pudemos',
  pudenda: 'pudenda',
  pudendo: 'pudendo',
  pudente: 'pudente',
  puderam: 'puderam',
  puderao: 'puderão',
  puderas: 'puderas',
  puderei: 'puderei',
  puderem: 'puderem',
  puderes: 'puderes',
  puderia: 'puderia',
  pudermo: 'pudermo',
  pudesse: 'pudesse',
  pudeste: 'pudeste',
  pudevem: 'pudevém',
  pudiana: 'pudiana',
  pudiano: 'pudiano',
  pudicas: 'pudicas',
  pudicia: 'pudícia',
  pudicos: 'pudicos',
  pudidao: 'pudidão',
  pudidas: 'pudidas',
  pudidos: 'pudidos',
  pudieis: 'pudíeis',
  pudivao: 'pudivão',
  pudlada: 'pudlada',
  pudlado: 'pudlado',
  pudlais: 'pudlais',
  pudlamo: 'pudlamo',
  pudlara: 'pudlará',
  pudlava: 'pudlava',
  pudleis: 'pudleis',
  pudlemo: 'pudlemo',
  pudoras: 'pudoras',
  pudores: 'pudores',
  pudvens: 'pudvéns',
  puelche: 'puelche',
  puelias: 'puélias',
  puerice: 'puerice',
  puerulo: 'puérulo',
  puetana: 'puetana',
  pufinos: 'pufinos',
  pufismo: 'pufismo',
  pufista: 'pufista',
  pugecia: 'pugécia',
  pugilai: 'pugilai',
  pugilam: 'pugilam',
  pugilar: 'pugilar',
  pugilas: 'pugilas',
  pugilei: 'pugilei',
  pugilem: 'pugilem',
  pugiles: 'pugiles',
  pugilos: 'pugilos',
  pugilou: 'pugilou',
  pugnace: 'pugnace',
  pugnada: 'pugnada',
  pugnado: 'pugnado',
  pugnais: 'pugnais',
  pugnamo: 'pugnamo',
  pugnara: 'pugnará',
  pugnava: 'pugnava',
  pugneis: 'pugneis',
  pugnemo: 'pugnemo',
  puiamos: 'puíamos',
  puidoes: 'puidões',
  puidona: 'puidona',
  puidora: 'puidora',
  puinave: 'puinave',
  puirdes: 'puirdes',
  puireis: 'puireis',
  puiriam: 'puiriam',
  puirias: 'puirias',
  puirmos: 'puirmos',
  puissem: 'puíssem',
  puisses: 'puísses',
  puistes: 'puístes',
  puitada: 'puitada',
  puitado: 'puitado',
  puitais: 'puitais',
  puitamo: 'puitamo',
  puitara: 'puitará',
  puitava: 'puitava',
  puiteis: 'puiteis',
  puitemo: 'puitemo',
  puitena: 'puitena',
  pujacas: 'pujacás',
  pujadao: 'pujadão',
  pujadas: 'pujadas',
  pujador: 'pujador',
  pujados: 'pujados',
  pujamos: 'pujamos',
  pujanca: 'pujança',
  pujando: 'pujando',
  pujante: 'pujante',
  pujaram: 'pujaram',
  pujarao: 'pujarão',
  pujaras: 'pujarás',
  pujarei: 'pujarei',
  pujarem: 'pujarem',
  pujares: 'pujares',
  pujaria: 'pujaria',
  pujarmo: 'pujarmo',
  pujasse: 'pujasse',
  pujaste: 'pujaste',
  pujavam: 'pujavam',
  pujavas: 'pujavas',
  pujemos: 'pujemos',
  pujixas: 'pujixás',
  pulacao: 'pulação',
  puladao: 'puladão',
  puladas: 'puladas',
  pulador: 'pulador',
  pulados: 'pulados',
  pulamos: 'pulamos',
  pulando: 'pulando',
  pulante: 'pulante',
  pularam: 'pularam',
  pularao: 'pularão',
  pularas: 'pularas',
  pularei: 'pularei',
  pularem: 'pularem',
  pulares: 'pulares',
  pularia: 'pularia',
  pulario: 'pulário',
  pularmo: 'pularmo',
  pulasse: 'pulasse',
  pulaste: 'pulaste',
  pulavam: 'pulavam',
  pulavas: 'pulavas',
  pulcras: 'pulcras',
  pulcros: 'pulcros',
  puleada: 'puleada',
  puleado: 'puleado',
  puleais: 'puleais',
  puleara: 'puleara',
  puleato: 'puleato',
  puleava: 'puleava',
  puleeis: 'puleeis',
  pulegol: 'pulegol',
  puleiam: 'puleiam',
  puleias: 'puleias',
  puleiem: 'puleiem',
  puleies: 'puleies',
  pulemos: 'pulemos',
  pulenia: 'pulênia',
  pulgoes: 'pulgões',
  pulgosa: 'pulgosa',
  pulgoso: 'pulgoso',
  pulhada: 'pulhada',
  pulhado: 'pulhado',
  pulhais: 'pulhais',
  pulhamo: 'pulhamo',
  pulhara: 'pulhará',
  pulhava: 'pulhava',
  pulheis: 'pulheis',
  pulhemo: 'pulhemo',
  pulhice: 'pulhice',
  pulhote: 'pulhote',
  puliato: 'puliato',
  pulicai: 'pulicai',
  pulicam: 'pulicam',
  pulicar: 'pulicar',
  pulicas: 'pulicas',
  pulices: 'púlices',
  pulicou: 'pulicou',
  pulidor: 'pulidor',
  pulinha: 'pulinha',
  pulinhe: 'pulinhe',
  pulinho: 'pulinho',
  pulique: 'pulique',
  pulizes: 'pulizes',
  pulmoes: 'pulmões',
  pulonas: 'pulonas',
  pulover: 'pulôver',
  pulpite: 'pulpite',
  pulpito: 'púlpito',
  pulposa: 'pulposa',
  pulposo: 'pulposo',
  pulques: 'pulques',
  pulsada: 'pulsada',
  pulsado: 'pulsado',
  pulsais: 'pulsais',
  pulsamo: 'pulsamo',
  pulsara: 'pulsara',
  pulsava: 'pulsava',
  pulseai: 'pulseai',
  pulsear: 'pulsear',
  pulseei: 'pulseei',
  pulseia: 'pulseia',
  pulseie: 'pulseie',
  pulseio: 'pulseio',
  pulseis: 'pulseis',
  pulsemo: 'pulsemo',
  pulseou: 'pulseou',
  pulsoes: 'pulsões',
  pulsona: 'pulsona',
  pulsota: 'pulsota',
  pultada: 'pultada',
  pultado: 'pultado',
  pultais: 'pultais',
  pultamo: 'pultamo',
  pultara: 'pultará',
  pultava: 'pultava',
  pulteis: 'pulteis',
  pultemo: 'pultemo',
  puluaga: 'puluaga',
  pululai: 'pululai',
  pululam: 'pululam',
  pulular: 'pulular',
  pululas: 'pululas',
  pululei: 'pululei',
  pululem: 'pululem',
  pulules: 'pulules',
  pululou: 'pululou',
  pulunga: 'pulunga',
  pulvato: 'pulvato',
  pulvego: 'púlvego',
  pulvica: 'púlvica',
  pulvico: 'púlvico',
  pulvina: 'pulvina',
  pulvine: 'pulvine',
  pulvino: 'pulvino',
  pulzoes: 'pulzões',
  pumacaa: 'pumacaá',
  pumaris: 'pumaris',
  pumices: 'púmices',
  pumpros: 'pumpros',
  pumumos: 'pumumos',
  punacas: 'punacas',
  punamos: 'punamos',
  punares: 'punarés',
  punarus: 'punarus',
  puncada: 'punçada',
  puncado: 'punçado',
  puncais: 'punçais',
  puncamo: 'punçamo',
  puncara: 'punçará',
  puncava: 'punçava',
  punceis: 'punceis',
  punceja: 'puncejá',
  punceje: 'punceje',
  puncejo: 'puncejo',
  puncemo: 'puncemo',
  punceta: 'punceta',
  puncoai: 'punçoai',
  puncoam: 'punçoam',
  puncoar: 'punçoar',
  puncoas: 'punçoas',
  puncoei: 'punçoei',
  puncoem: 'punçoem',
  puncoes: 'punções',
  puncona: 'punçona',
  puncoou: 'punçoou',
  punctas: 'punctas',
  punense: 'punense',
  pungiam: 'pungiam',
  pungias: 'pungias',
  pungida: 'pungida',
  pungido: 'pungido',
  pungira: 'pungira',
  punguea: 'pungueá',
  pungues: 'pungues',
  punhada: 'punhada',
  punhado: 'punhado',
  punhais: 'punhais',
  punheis: 'púnheis',
  punheta: 'punheta',
  punhete: 'punhete',
  punhoes: 'punhões',
  punicao: 'punição',
  punicas: 'púnicas',
  punicea: 'punícea',
  puniceo: 'puníceo',
  punicos: 'púnicos',
  punidao: 'punidão',
  punidas: 'punidas',
  punidor: 'punidor',
  punidos: 'punidos',
  punieis: 'puníeis',
  punilha: 'punilha',
  punimos: 'punimos',
  punindo: 'punindo',
  puniram: 'puniram',
  punirao: 'punirão',
  puniras: 'puniras',
  punirei: 'punirei',
  punirem: 'punirem',
  punires: 'punires',
  puniria: 'puniria',
  punirmo: 'punirmo',
  punisse: 'punisse',
  puniste: 'puniste',
  punivel: 'punível',
  punjabi: 'punjabi',
  punjais: 'punjais',
  puntada: 'puntada',
  puntado: 'puntado',
  puntais: 'puntais',
  puntamo: 'puntamo',
  puntara: 'puntará',
  puntava: 'puntava',
  punteis: 'punteis',
  puntemo: 'puntemo',
  puntual: 'puntual',
  puntura: 'puntura',
  pupacao: 'pupação',
  pupadao: 'pupadão',
  pupadas: 'pupadas',
  pupador: 'pupador',
  pupados: 'pupados',
  pupalia: 'pupália',
  pupamos: 'pupamos',
  pupando: 'pupando',
  pupante: 'pupante',
  puparam: 'puparam',
  puparao: 'puparão',
  puparas: 'puparás',
  puparei: 'puparei',
  puparem: 'puparem',
  pupares: 'pupares',
  puparia: 'puparia',
  pupario: 'pupário',
  puparmo: 'puparmo',
  pupasse: 'pupasse',
  pupaste: 'pupaste',
  pupavam: 'pupavam',
  pupavas: 'pupavas',
  pupavel: 'pupável',
  pupemos: 'pupemos',
  pupidea: 'pupídea',
  pupideo: 'pupídeo',
  pupilai: 'pupilai',
  pupilam: 'pupilam',
  pupilar: 'pupilar',
  pupilas: 'pupilas',
  pupilei: 'pupilei',
  pupilem: 'pupilem',
  pupiles: 'pupiles',
  pupilos: 'pupilos',
  pupilou: 'pupilou',
  pupinas: 'pupinas',
  pupunha: 'pupunha',
  puracas: 'púracas',
  puraces: 'puracés',
  puragua: 'purágua',
  puranas: 'puranas',
  puranga: 'puranga',
  puraque: 'puraquê',
  puravas: 'puravás',
  pureias: 'pureias',
  purezas: 'purezas',
  purgada: 'purgada',
  purgado: 'purgado',
  purgais: 'purgais',
  purgamo: 'purgamo',
  purgara: 'purgara',
  purgava: 'purgava',
  purguei: 'purguei',
  purguem: 'purguem',
  purgues: 'purgues',
  puricas: 'púricas',
  puricos: 'púricos',
  purinas: 'purinas',
  purinha: 'purinha',
  purismo: 'purismo',
  purista: 'purista',
  puronas: 'puronas',
  purpura: 'púrpura',
  purpure: 'purpure',
  purpuro: 'púrpuro',
  purrias: 'púrrias',
  purrios: 'púrrios',
  purrona: 'purrona',
  purucas: 'purucas',
  purumas: 'purumas',
  purunga: 'purunga',
  purungo: 'purungo',
  pururus: 'pururus',
  pusemos: 'pusemos',
  puseram: 'puseram',
  puseras: 'puseras',
  puserem: 'puserem',
  puseres: 'puseres',
  pusesse: 'pusesse',
  puseste: 'puseste',
  pustula: 'pústula',
  putadas: 'putadas',
  putamen: 'putâmen',
  putanga: 'putanga',
  putaria: 'putaria',
  putauas: 'putauás',
  puteada: 'puteada',
  puteado: 'puteado',
  puteais: 'puteais',
  puteara: 'puteará',
  puteava: 'puteava',
  putedos: 'putedos',
  puteeis: 'puteeis',
  putegas: 'pútegas',
  puteiam: 'puteiam',
  puteias: 'puteias',
  puteiem: 'puteiem',
  puteies: 'puteies',
  puteira: 'puteira',
  puteiro: 'puteiro',
  puticis: 'puticis',
  putilas: 'putilas',
  putirao: 'putirão',
  putirom: 'putirom',
  putirum: 'putirum',
  putoria: 'putória',
  putorio: 'putório',
  putreia: 'putreia',
  putriao: 'putrião',
  putrida: 'pútrida',
  putrido: 'pútrido',
  putucas: 'putucas',
  puvitas: 'puvitas',
  puxacao: 'puxação',
  puxacar: 'puxacar',
  puxadao: 'puxadão',
  puxadas: 'puxadas',
  puxador: 'puxador',
  puxados: 'puxados',
  puxamos: 'puxamos',
  puxanca: 'puxança',
  puxanco: 'puxanço',
  puxando: 'puxando',
  puxante: 'puxante',
  puxaram: 'puxaram',
  puxarao: 'puxarão',
  puxaras: 'puxaras',
  puxarei: 'puxarei',
  puxarem: 'puxarem',
  puxares: 'puxares',
  puxaria: 'puxaria',
  puxarmo: 'puxarmo',
  puxasse: 'puxasse',
  puxaste: 'puxaste',
  puxavam: 'puxavam',
  puxavao: 'puxavão',
  puxavas: 'puxavas',
  puxeira: 'puxeira',
  puxemos: 'puxemos',
  puxetas: 'puxetas',
  puxiana: 'puxiana',
  puxirao: 'puxirão',
  puxiris: 'puxiris',
  puxirum: 'puxirum',
  puxurim: 'puxurim',
  puxuris: 'puxuris',
  puzzles: 'puzzles',
  quacres: 'quacres',
  quadrai: 'quadrai',
  quadram: 'quadram',
  quadrao: 'quadrão',
  quadrar: 'quadrar',
  quadras: 'quadras',
  quadrei: 'quadrei',
  quadrem: 'quadrem',
  quadres: 'quadres',
  quadril: 'quadril',
  quadris: 'quadris',
  quadros: 'quadros',
  quadrou: 'quadrou',
  quadrum: 'quadrum',
  quaiapa: 'quaiapá',
  quaigue: 'quaigue',
  quairas: 'quairas',
  qualeas: 'quáleas',
  qualira: 'qualira',
  quamato: 'quamato',
  quandro: 'quandro',
  quantas: 'quantas',
  quantes: 'quantés',
  quanteu: 'quanteu',
  quantia: 'quantia',
  quantil: 'quantil',
  quantis: 'quantis',
  quantos: 'quantos',
  quantum: 'quantum',
  quanzas: 'quanzas',
  quapoia: 'quapoia',
  quaquer: 'quáquer',
  quaraci: 'quaraci',
  quarada: 'quarada',
  quarado: 'quarado',
  quarais: 'quarais',
  quaramo: 'quaramo',
  quarara: 'quarará',
  quarava: 'quarava',
  quarcos: 'quarços',
  quareis: 'quareis',
  quaremo: 'quaremo',
  quariba: 'quariba',
  quartai: 'quartai',
  quartal: 'quartal',
  quartam: 'quartam',
  quartao: 'quartão',
  quartar: 'quartar',
  quartas: 'quartas',
  quartau: 'quartau',
  quartea: 'quarteá',
  quartei: 'quartei',
  quartel: 'quartel',
  quartem: 'quartem',
  quartes: 'quartes',
  quartil: 'quartil',
  quartis: 'quartis',
  quartos: 'quartos',
  quartou: 'quartou',
  quartzo: 'quartzo',
  quaruba: 'quaruba',
  quaruna: 'quaruná',
  quassai: 'quassai',
  quassam: 'quassam',
  quassar: 'quassar',
  quassas: 'quassas',
  quassei: 'quassei',
  quassem: 'quassem',
  quasses: 'quasses',
  quassia: 'quássia',
  quassou: 'quassou',
  quatete: 'quatetê',
  quatibo: 'quatibo',
  quatrim: 'quatrim',
  quatros: 'quatros',
  quebrai: 'quebrai',
  quebram: 'quebram',
  quebrar: 'quebrar',
  quebras: 'quebras',
  quebrei: 'quebrei',
  quebrem: 'quebrem',
  quebres: 'quebres',
  quebrou: 'quebrou',
  quebucu: 'quebucu',
  quebulo: 'québulo',
  queches: 'queches',
  quechua: 'quéchua',
  quedaco: 'quedaço',
  quedada: 'quedada',
  quedado: 'quedado',
  quedais: 'quedais',
  quedamo: 'quedamo',
  quedana: 'quedana',
  quedano: 'quedano',
  quedara: 'quedara',
  quedava: 'quedava',
  quedeis: 'quedeis',
  quedemo: 'quedemo',
  quedios: 'quédios',
  quediva: 'quediva',
  quedive: 'quedive',
  quedoes: 'quedões',
  quedona: 'quedona',
  quegada: 'quegada',
  quegani: 'quegani',
  queguem: 'queguém',
  queijai: 'queijai',
  queijam: 'queijam',
  queijar: 'queijar',
  queijas: 'queijas',
  queijei: 'queijei',
  queijem: 'queijem',
  queijes: 'queijes',
  queijos: 'queijos',
  queijou: 'queijou',
  queilio: 'quêilio',
  queilos: 'queilos',
  queimai: 'queimai',
  queimam: 'queimam',
  queimao: 'queimão',
  queimar: 'queimar',
  queimas: 'queimas',
  queimei: 'queimei',
  queimem: 'queimem',
  queimes: 'queimes',
  queimor: 'queimor',
  queimou: 'queimou',
  queinai: 'queinai',
  queinam: 'queinam',
  queinar: 'queinar',
  queinas: 'queinas',
  queinei: 'queinei',
  queinem: 'queinem',
  queines: 'queines',
  queinou: 'queinou',
  queiram: 'queiram',
  queiras: 'queiras',
  queires: 'queires',
  queiros: 'queirós',
  queismo: 'queismo',
  queixai: 'queixai',
  queixal: 'queixal',
  queixam: 'queixam',
  queixar: 'queixar',
  queixas: 'queixas',
  queixei: 'queixei',
  queixem: 'queixem',
  queixes: 'queixes',
  queixos: 'queixos',
  queixou: 'queixou',
  quejeme: 'quejeme',
  quelada: 'quelada',
  quelado: 'quelado',
  quelais: 'quelais',
  quelamo: 'quelamo',
  quelara: 'quelara',
  quelato: 'quelato',
  quelava: 'quelava',
  queleas: 'quéleas',
  queleis: 'queleis',
  quelele: 'quelelê',
  quelemo: 'quelemo',
  quelens: 'queléns',
  queleto: 'queleto',
  quelhas: 'quelhas',
  quelhos: 'quelhos',
  quelias: 'quélias',
  quelida: 'quélida',
  quelina: 'quelina',
  quelins: 'quelins',
  quelmao: 'quelmão',
  quelmas: 'quelmas',
  quelmes: 'quelmes',
  quelmos: 'quelmos',
  quelona: 'quelona',
  quelone: 'quelone',
  quelono: 'quelono',
  quelote: 'quelote',
  queluro: 'queluro',
  queluza: 'queluza',
  quembes: 'quembes',
  quemeia: 'quemeia',
  quemose: 'quemose',
  quenali: 'quenali',
  quencia: 'quência',
  quendas: 'quendas',
  quendes: 'quendes',
  quendos: 'quendôs',
  queneus: 'queneus',
  quengas: 'quengas',
  quengos: 'quengos',
  quenhai: 'quenhai',
  quenham: 'quenham',
  quenhar: 'quenhar',
  quenhas: 'quenhas',
  quenhei: 'quenhei',
  quenhem: 'quenhem',
  quenhes: 'quenhes',
  quenhou: 'quenhou',
  quenica: 'quênica',
  quenice: 'quênice',
  quenico: 'quênico',
  quenita: 'quenita',
  quenopo: 'quênopo',
  quentai: 'quentai',
  quentam: 'quentam',
  quentao: 'quentão',
  quentar: 'quentar',
  quentas: 'quentas',
  quentei: 'quentei',
  quentem: 'quentem',
  quentes: 'quentes',
  quentia: 'quêntia',
  quentou: 'quentou',
  queomas: 'queomas',
  queomos: 'queomós',
  quepler: 'quépler',
  queques: 'queques',
  querabo: 'querabo',
  querato: 'querato',
  quercos: 'quercos',
  quereca: 'quereca',
  quereis: 'quereis',
  querela: 'querela',
  querele: 'querele',
  querelo: 'querelo',
  queremo: 'queremo',
  querena: 'querena',
  querene: 'querene',
  quereno: 'quereno',
  querera: 'quererá',
  querere: 'quererê',
  queriam: 'queriam',
  querias: 'querias',
  querida: 'querida',
  querido: 'querido',
  querila: 'querila',
  querima: 'querima',
  querion: 'quérion',
  querios: 'quérios',
  querite: 'querite',
  quermes: 'quermes',
  querona: 'querona',
  queropo: 'quéropo',
  querops: 'quérops',
  querria: 'quérria',
  querube: 'quérube',
  queruda: 'queruda',
  querudo: 'querudo',
  querula: 'quérula',
  querulo: 'quérulo',
  quesada: 'quesada',
  quesais: 'quesais',
  quesias: 'quésias',
  quesito: 'quesito',
  quesris: 'quesris',
  questao: 'questão',
  questas: 'questas',
  questor: 'questor',
  quetcal: 'quetçal',
  quetche: 'quetche',
  quetelo: 'quetelo',
  quetida: 'quétida',
  quetote: 'quetote',
  quetris: 'quetris',
  quetuas: 'quetuas',
  quetulo: 'quetulo',
  quetura: 'quetura',
  queturo: 'queturo',
  quetzal: 'quetzal',
  queuper: 'quêuper',
  queveis: 'quevéis',
  quezias: 'quezias',
  quezila: 'quezila',
  quezile: 'quezile',
  quezilo: 'quezilo',
  quiabos: 'quiabos',
  quiacas: 'quiaças',
  quialas: 'quialas',
  quiamas: 'quiamas',
  quiambi: 'quiâmbi',
  quiambo: 'quiambo',
  quianda: 'quianda',
  quianja: 'quianja',
  quiaras: 'quiaras',
  quiaris: 'quiaris',
  quiasma: 'quiasma',
  quiasmo: 'quiasmo',
  quiassa: 'quiassa',
  quibaba: 'quibabá',
  quibaca: 'quibaca',
  quibaco: 'quibaco',
  quibala: 'quibala',
  quibana: 'quibana',
  quibano: 'quibano',
  quibeba: 'quibeba',
  quibebe: 'quibebe',
  quibeto: 'quibeto',
  quibire: 'quibire',
  quiboas: 'quiboas',
  quiboca: 'quiboça',
  quibori: 'quibori',
  quibosa: 'quibosa',
  quibuas: 'quíbuas',
  quibuca: 'quibuca',
  quibuco: 'quibuco',
  quibuto: 'quibuto',
  quibuzo: 'quibuzo',
  quicaba: 'quiçaba',
  quicabo: 'quicabo',
  quicaca: 'quiçaça',
  quicada: 'quicada',
  quicado: 'quicado',
  quicafu: 'quiçafu',
  quicais: 'quiçais',
  quicama: 'quiçamã',
  quicamo: 'quicamo',
  quicapo: 'quiçapo',
  quicara: 'quicará',
  quicaus: 'quiçaus',
  quicava: 'quiçava',
  quiceca: 'quiceca',
  quiceco: 'quiceco',
  quicemo: 'quicemo',
  quiches: 'quichés',
  quichua: 'quíchua',
  quicios: 'quícios',
  quicoas: 'quicoas',
  quicola: 'quícola',
  quicole: 'quicole',
  quicuas: 'quicuas',
  quicuca: 'quicuca',
  quicuia: 'quicuia',
  quicuio: 'quicuio',
  quicuta: 'quicuta',
  quidale: 'quidalê',
  quidans: 'quidans',
  quiebro: 'quiebro',
  quieira: 'quieira',
  quielas: 'quielas',
  quienol: 'quienol',
  quienza: 'quienza',
  quienze: 'quienze',
  quietai: 'quietai',
  quietam: 'quietam',
  quietar: 'quietar',
  quietas: 'quietas',
  quietei: 'quietei',
  quietem: 'quietem',
  quietes: 'quietes',
  quietez: 'quietez',
  quietos: 'quietos',
  quietou: 'quietou',
  quifaca: 'quifaça',
  quifoci: 'quifoci',
  quifuca: 'quifuça',
  quifuco: 'quifuco',
  quifuge: 'quifuge',
  quifuje: 'quifuje',
  quifula: 'quifula',
  quifuxo: 'quifuxo',
  quigalo: 'quigalo',
  quiinas: 'quiinas',
  quiismo: 'quiismo',
  quiista: 'quiista',
  quiitas: 'quiitas',
  quijara: 'quijara',
  quijila: 'quijila',
  quijuba: 'quijuba',
  quilaia: 'quilaia',
  quilaja: 'quilaja',
  quilapi: 'quilápi',
  quilata: 'quilata',
  quilate: 'quilate',
  quilato: 'quilato',
  quileba: 'quileba',
  quilele: 'quilelê',
  quilema: 'quilema',
  quileto: 'quileto',
  quilhai: 'quilhai',
  quilham: 'quilham',
  quilhar: 'quilhar',
  quilhas: 'quilhas',
  quilhau: 'quilhau',
  quilhei: 'quilhei',
  quilhem: 'quilhem',
  quilhes: 'quilhes',
  quilhou: 'quilhou',
  quilina: 'quilina',
  quilino: 'quilino',
  quilins: 'quilins',
  quilios: 'quílios',
  quilite: 'quilite',
  quilmes: 'quilmes',
  quiloas: 'quíloas',
  quilohm: 'quilohm',
  quiloio: 'quiloiô',
  quilolo: 'quilolo',
  quilosa: 'quilosa',
  quilose: 'quilose',
  quiloso: 'quiloso',
  quilota: 'quilota',
  quilote: 'quilote',
  quiloto: 'quiloto',
  quilulo: 'quilulo',
  quilzao: 'quilzão',
  quimama: 'quimama',
  quimana: 'quimana',
  quimano: 'quimano',
  quimase: 'quimase',
  quimaus: 'quimaus',
  quimbai: 'quimbai',
  quimbam: 'quimbam',
  quimbar: 'quimbar',
  quimbas: 'quimbas',
  quimbei: 'quimbei',
  quimbem: 'quimbem',
  quimbes: 'quimbes',
  quimboa: 'quimboa',
  quimbos: 'quimbos',
  quimbou: 'quimbou',
  quimera: 'quimera',
  quimias: 'quimias',
  quimica: 'química',
  quimico: 'químico',
  quimoes: 'quimões',
  quimone: 'quimone',
  quimono: 'quimono',
  quimosa: 'quimosa',
  quimose: 'quimose',
  quimoso: 'quimoso',
  quinada: 'quinada',
  quinado: 'quinado',
  quinais: 'quinais',
  quinama: 'quinama',
  quinamo: 'quinamo',
  quinana: 'quinana',
  quinano: 'quinano',
  quinara: 'quinara',
  quinase: 'quínase',
  quinato: 'quinato',
  quinaus: 'quinaus',
  quinava: 'quinava',
  quincas: 'quincas',
  quincha: 'quincha',
  quinche: 'quinche',
  quincho: 'quincho',
  quindao: 'quindão',
  quindas: 'quindas',
  quindim: 'quindim',
  quinecu: 'quinecu',
  quineis: 'quineis',
  quinemo: 'quinemo',
  quineno: 'quineno',
  quineto: 'quineto',
  quingai: 'quingai',
  quingam: 'quingam',
  quingar: 'quingar',
  quingas: 'quingas',
  quingou: 'quingou',
  quingue: 'quingue',
  quinhao: 'quinhão',
  quinhoa: 'quinhoa',
  quinhoe: 'quinhoe',
  quinhoo: 'quinhoo',
  quinica: 'quinica',
  quinico: 'quínico',
  quinina: 'quinina',
  quinino: 'quinino',
  quinins: 'quinins',
  quinios: 'quínios',
  quinite: 'quinite',
  quinoas: 'quinoas',
  quinois: 'quinóis',
  quinola: 'quinola',
  quinona: 'quinona',
  quinoto: 'quinoto',
  quinque: 'quinqué',
  quintai: 'quintai',
  quintal: 'quintal',
  quintam: 'quintam',
  quintao: 'quintão',
  quintar: 'quintar',
  quintas: 'quintas',
  quintei: 'quintei',
  quintem: 'quintem',
  quintes: 'quintes',
  quintil: 'quintil',
  quintis: 'quintis',
  quintos: 'quintos',
  quintou: 'quintou',
  quinzes: 'quinzes',
  quinzol: 'quinzol',
  quinzos: 'quinzos',
  quiocos: 'quiocos',
  quioios: 'quioios',
  quionis: 'quíonis',
  quiosco: 'quiosco',
  quiotas: 'quiotas',
  quiovas: 'quiovas',
  quiozas: 'quiozas',
  quipais: 'quípais',
  quipata: 'quipata',
  quipede: 'quipede',
  quipeis: 'quípeis',
  quipele: 'quipele',
  quipemo: 'quípemo',
  quipera: 'quíperá',
  quipiam: 'quípiam',
  quipias: 'quípias',
  quipica: 'quipica',
  quipico: 'quipico',
  quipida: 'quípida',
  quipido: 'quípido',
  quipius: 'quipiús',
  quipoto: 'quipoto',
  quiquei: 'quiquei',
  quiquem: 'quiquem',
  quiques: 'quiques',
  quiquia: 'quiquia',
  quiquis: 'quiquis',
  quirabi: 'quirabi',
  quirais: 'quirais',
  quirana: 'quirana',
  quirari: 'quirari',
  quirate: 'quirate',
  quirato: 'quirato',
  quirche: 'quirche',
  quirdis: 'quirdis',
  quirela: 'quirela',
  quirera: 'quirera',
  quirial: 'quirial',
  quiriba: 'quiriba',
  quirica: 'quirica',
  quirida: 'quirida',
  quiries: 'quíries',
  quirina: 'quirina',
  quirins: 'quirins',
  quiriri: 'quiriri',
  quiriru: 'quiriru',
  quirita: 'quirita',
  quirite: 'quirite',
  quiroga: 'quiroga',
  quiruas: 'quiruás',
  quirule: 'quirule',
  quisafu: 'quisafu',
  quiscos: 'quiscos',
  quiseco: 'quiseco',
  quisera: 'quisera',
  quisole: 'quisole',
  quispas: 'quispás',
  quissau: 'quissau',
  quistos: 'quistos',
  quitaba: 'quitaba',
  quitabo: 'quitabo',
  quitaca: 'quitaca',
  quitada: 'quitada',
  quitado: 'quitado',
  quitais: 'quitais',
  quitamo: 'quitamo',
  quitara: 'quitara',
  quitare: 'quitare',
  quitata: 'quitata',
  quitava: 'quitava',
  quitece: 'quitece',
  quiteis: 'quiteis',
  quitemo: 'quitemo',
  quiteta: 'quiteta',
  quiteus: 'quiteus',
  quiteve: 'quiteve',
  quitias: 'quitias',
  quitina: 'quitina',
  quitira: 'quitira',
  quitiva: 'quitiva',
  quitoco: 'quitoco',
  quitoes: 'quitões',
  quitoli: 'quitôli',
  quitona: 'quitona',
  quitons: 'quítons',
  quitool: 'quitool',
  quitoto: 'quitoto',
  quitues: 'quitués',
  quituio: 'quituio',
  quituis: 'quituis',
  quitura: 'quitura',
  quitute: 'quitute',
  quituxe: 'quituxe',
  quituza: 'quituza',
  quiumba: 'quiumba',
  quiumbe: 'quiumbe',
  quivuvi: 'quivúvi',
  quixaba: 'quixaba',
  quixada: 'quixadá',
  quixias: 'quíxias',
  quixiba: 'quixiba',
  quixipa: 'quixipá',
  quixobo: 'quixobo',
  quixona: 'quixona',
  quixote: 'quixote',
  quizaca: 'quizaca',
  quizeco: 'quizeco',
  quizila: 'quizila',
  quizile: 'quizile',
  quizilo: 'quizilo',
  quizoco: 'quizoco',
  quocolo: 'quócolo',
  quoculo: 'quóculo',
  quodore: 'quodore',
  quogelo: 'quogelo',
  quoruns: 'quóruns',
  quotada: 'quotada',
  quotado: 'quotado',
  quotais: 'quotais',
  quotamo: 'quotamo',
  quotara: 'quotará',
  quotava: 'quotava',
  quoteis: 'quoteis',
  quotemo: 'quotemo',
  quotiza: 'quotiza',
  quotize: 'quotize',
  quotizo: 'quotizo',
  rababes: 'rababes',
  rabacal: 'rabaçal',
  rabacao: 'rabação',
  rabacas: 'rabaças',
  rabacos: 'rabaços',
  rabacue: 'rabacué',
  rabadao: 'rabadão',
  rabadas: 'rabadas',
  rabados: 'rabados',
  rabagem: 'rabagem',
  rabalde: 'rabalde',
  rabalha: 'rabalha',
  rabalva: 'rabalva',
  rabalvo: 'rabalvo',
  rabanai: 'rabanai',
  rabanal: 'rabanal',
  rabanam: 'rabanam',
  rabanar: 'rabanar',
  rabanas: 'rabanas',
  rabanca: 'rabanca',
  rabanea: 'rabaneá',
  rabanei: 'rabanei',
  rabanem: 'rabanem',
  rabanes: 'rabanes',
  rabanho: 'rabanho',
  rabanos: 'rabanos',
  rabanou: 'rabanou',
  rabazes: 'rabazes',
  rabdias: 'rábdias',
  rabdita: 'rabdita',
  rabdite: 'rabdite',
  rabdito: 'rabdito',
  rabdoma: 'rabdoma',
  rabdota: 'rabdota',
  rabeada: 'rabeada',
  rabeado: 'rabeado',
  rabeais: 'rabeais',
  rabeara: 'rabeará',
  rabeava: 'rabeava',
  rabecao: 'rabecão',
  rabecas: 'rabecas',
  rabecos: 'rabecos',
  rabeeis: 'rabeeis',
  rabeiam: 'rabeiam',
  rabeias: 'rabeias',
  rabeiem: 'rabeiem',
  rabeies: 'rabeies',
  rabeira: 'rabeira',
  rabeire: 'rabeire',
  rabeiro: 'rabeiro',
  rabejai: 'rabejai',
  rabejam: 'rabejam',
  rabejar: 'rabejar',
  rabejas: 'rabejas',
  rabejei: 'rabejei',
  rabejem: 'rabejem',
  rabejes: 'rabejes',
  rabejou: 'rabejou',
  rabelas: 'rabelas',
  rabelho: 'rabelho',
  rabelos: 'rabelos',
  rabendo: 'rabendo',
  rabetas: 'rabetas',
  rabetes: 'rabetes',
  rabetos: 'rabetos',
  rabiada: 'rabiada',
  rabiado: 'rabiado',
  rabiais: 'rabiais',
  rabiamo: 'rabiamo',
  rabiara: 'rabiara',
  rabiava: 'rabiava',
  rabicao: 'rabicão',
  rabicas: 'rabiças',
  rabicha: 'rabicha',
  rabicho: 'rabicho',
  rabicos: 'rabicós',
  rabidai: 'rabidai',
  rabidam: 'rabidam',
  rabidar: 'rabidar',
  rabidas: 'rábidas',
  rabidei: 'rabidei',
  rabidem: 'rabidem',
  rabides: 'rabides',
  rabidez: 'rabidez',
  rabidos: 'rábidos',
  rabidou: 'rabidou',
  rabieis: 'rabieis',
  rabiemo: 'rabiemo',
  rabigas: 'rabigas',
  rabigos: 'rabigos',
  rabilas: 'rabilas',
  rabilha: 'rabilha',
  rabinho: 'rabinho',
  rabinos: 'rabinos',
  rabiola: 'rabiola',
  rabiolo: 'rabiolo',
  rabiosa: 'rabiosa',
  rabiose: 'rabiose',
  rabioso: 'rabioso',
  rabiote: 'rabiote',
  rabisca: 'rabisca',
  rabisco: 'rabisco',
  rabitas: 'rabitas',
  rabitos: 'rabitos',
  rabolas: 'rabolas',
  rabolos: 'rabolos',
  rabonai: 'rabonai',
  rabonam: 'rabonam',
  rabonar: 'rabonar',
  rabonas: 'rabonas',
  rabonei: 'rabonei',
  rabonem: 'rabonem',
  rabones: 'rabones',
  rabonou: 'rabonou',
  rabosas: 'rabosas',
  rabosos: 'rabosos',
  rabotai: 'rabotai',
  rabotam: 'rabotam',
  rabotar: 'rabotar',
  rabotas: 'rabotas',
  rabotei: 'rabotei',
  rabotem: 'rabotem',
  rabotes: 'rabotes',
  rabotou: 'rabotou',
  rabucha: 'rabucha',
  rabucho: 'rabucho',
  rabucos: 'rabucos',
  rabudas: 'rabudas',
  rabudos: 'rabudos',
  rabugem: 'rabugem',
  rabuges: 'rabuges',
  rabujai: 'rabujai',
  rabujam: 'rabujam',
  rabujao: 'rabujão',
  rabujar: 'rabujar',
  rabujas: 'rabujas',
  rabujei: 'rabujei',
  rabujem: 'rabujem',
  rabujes: 'rabujes',
  rabujou: 'rabujou',
  rabulai: 'rabulai',
  rabulam: 'rabulam',
  rabulao: 'rabulão',
  rabular: 'rabular',
  rabulas: 'rábulas',
  rabulea: 'rabuleá',
  rabulei: 'rabulei',
  rabulem: 'rabulem',
  rabules: 'rabules',
  rabulou: 'rabulou',
  rabunai: 'rabunai',
  rabunam: 'rabunam',
  rabunar: 'rabunar',
  rabunas: 'rabunas',
  rabunea: 'rabuneá',
  rabunei: 'rabunei',
  rabunem: 'rabunem',
  rabunes: 'rabunes',
  rabunha: 'rabunha',
  rabunhe: 'rabunhe',
  rabunho: 'rabunho',
  rabunou: 'rabunou',
  rabusca: 'rabusca',
  rabusco: 'rabusco',
  racadas: 'raçadas',
  racador: 'raçador',
  racados: 'raçados',
  racamas: 'racamas',
  racaute: 'racaúte',
  raceada: 'raceada',
  raceado: 'raceado',
  raceais: 'raceais',
  raceara: 'raceará',
  raceava: 'raceava',
  raceeis: 'raceeis',
  raceiam: 'raceiam',
  raceias: 'raceias',
  raceiem: 'raceiem',
  raceies: 'raceies',
  raceira: 'raceira',
  raceiro: 'raceiro',
  racemos: 'racemos',
  rachada: 'rachada',
  rachado: 'rachado',
  rachais: 'rachais',
  rachamo: 'rachamo',
  rachara: 'rachara',
  rachava: 'rachava',
  racheai: 'racheai',
  rachear: 'rachear',
  rachedo: 'rachedo',
  racheei: 'racheei',
  racheia: 'racheia',
  racheie: 'racheie',
  racheio: 'racheio',
  racheis: 'racheis',
  rachemo: 'rachemo',
  racheou: 'racheou',
  racheta: 'racheta',
  rachoes: 'rachões',
  raciais: 'raciais',
  racicas: 'rácicas',
  racicos: 'rácicos',
  racimos: 'racimos',
  racinai: 'racinai',
  racinam: 'racinam',
  racinar: 'racinar',
  racinas: 'racinas',
  racinei: 'racinei',
  racinem: 'racinem',
  racines: 'racines',
  racinha: 'racinha',
  racinou: 'racinou',
  raciona: 'raciona',
  racione: 'racione',
  raciono: 'raciono',
  racismo: 'racismo',
  racista: 'racista',
  racoada: 'raçoada',
  racoado: 'raçoado',
  racoais: 'raçoais',
  racoamo: 'raçoamo',
  racoara: 'raçoará',
  racoava: 'raçoava',
  racodio: 'racódio',
  racoeis: 'raçoeis',
  racoemo: 'raçoemo',
  raconas: 'raçonas',
  raconta: 'raconta',
  raconte: 'raconte',
  raconto: 'raconto',
  racoses: 'racoses',
  racudas: 'raçudas',
  racudos: 'raçudos',
  radameu: 'radameu',
  radares: 'radares',
  radiada: 'radiada',
  radiado: 'radiado',
  radiais: 'radiais',
  radiamo: 'radiamo',
  radiano: 'radiano',
  radiara: 'radiara',
  radiava: 'radiava',
  radicai: 'radicai',
  radical: 'radical',
  radicam: 'radicam',
  radicar: 'radicar',
  radicas: 'radicas',
  radicia: 'radicia',
  radicie: 'radicie',
  radicio: 'radicio',
  radicos: 'rádicos',
  radicou: 'radicou',
  radieis: 'radieis',
  radiemo: 'radiemo',
  radiola: 'radiola',
  radiolo: 'radíolo',
  radiosa: 'radiosa',
  radioso: 'radioso',
  radique: 'radique',
  radiuns: 'rádiuns',
  radobai: 'radobai',
  radobam: 'radobam',
  radobar: 'radobar',
  radobas: 'radobas',
  radobei: 'radobei',
  radobem: 'radobem',
  radobes: 'radobes',
  radobou: 'radobou',
  radolho: 'radolho',
  radomos: 'radomos',
  radonio: 'radônio',
  radotes: 'radotes',
  radubai: 'radubai',
  radubam: 'radubam',
  radubar: 'radubar',
  radubas: 'radubas',
  radubei: 'radubei',
  radubem: 'radubem',
  radubes: 'radubes',
  radubou: 'radubou',
  radulas: 'rádulas',
  rafadao: 'rafadão',
  rafadas: 'rafadas',
  rafador: 'rafador',
  rafados: 'rafados',
  rafalos: 'rafalos',
  rafamos: 'rafamos',
  rafanas: 'ráfanas',
  rafando: 'rafando',
  rafanea: 'rafânea',
  rafaneo: 'rafâneo',
  rafania: 'rafania',
  rafanos: 'ráfanos',
  rafante: 'rafante',
  rafaram: 'rafaram',
  rafarao: 'rafarão',
  rafaras: 'rafaras',
  rafarei: 'rafarei',
  rafarem: 'rafarem',
  rafares: 'rafares',
  rafaria: 'rafaria',
  rafarmo: 'rafarmo',
  rafasse: 'rafasse',
  rafaste: 'rafaste',
  rafavam: 'rafavam',
  rafavas: 'rafavas',
  rafeira: 'rafeira',
  rafeiro: 'rafeiro',
  rafemos: 'rafemos',
  raffada: 'raffada',
  raffado: 'raffado',
  raffais: 'raffais',
  raffamo: 'raffamo',
  raffara: 'raffará',
  raffava: 'raffava',
  raffeis: 'raffeis',
  raffemo: 'raffemo',
  raffine: 'raffiné',
  raffles: 'raffles',
  rafiada: 'rafiada',
  rafiado: 'rafiado',
  rafiais: 'rafiais',
  rafiamo: 'rafiamo',
  rafiara: 'rafiara',
  rafiava: 'rafiava',
  rafidas: 'ráfidas',
  rafidea: 'rafídea',
  rafideo: 'rafídeo',
  rafides: 'ráfides',
  rafidia: 'rafídia',
  rafieis: 'rafieis',
  rafiemo: 'rafiemo',
  rafinai: 'rafinai',
  rafinam: 'rafinam',
  rafinar: 'rafinar',
  rafinas: 'rafinas',
  rafinaz: 'rafinaz',
  rafinei: 'rafinei',
  rafinem: 'rafinem',
  rafines: 'rafines',
  rafinou: 'rafinou',
  rafiros: 'rafiros',
  rafitas: 'rafitas',
  rafites: 'rafites',
  rafnias: 'ráfnias',
  rafting: 'rafting',
  ragadas: 'ragadas',
  ragades: 'rágades',
  ragadia: 'ragádia',
  ragadio: 'ragádio',
  rageira: 'rageira',
  ragioes: 'ragiões',
  ragitas: 'ragitas',
  ragites: 'ragites',
  ragodia: 'ragódia',
  ragoide: 'ragoide',
  ragtime: 'ragtime',
  raguebi: 'râguebi',
  ragueda: 'ragueda',
  raguita: 'raguita',
  raguras: 'raguras',
  raguseu: 'raguseu',
  raiacao: 'raiação',
  raiadao: 'raiadão',
  raiadas: 'raiadas',
  raiador: 'raiador',
  raiados: 'raiados',
  raiamos: 'raiamos',
  raianas: 'raianas',
  raiando: 'raiando',
  raianos: 'raianos',
  raiante: 'raiante',
  raiaram: 'raiaram',
  raiarao: 'raiarão',
  raiaras: 'raiaras',
  raiarei: 'raiarei',
  raiarem: 'raiarem',
  raiares: 'raiares',
  raiaria: 'raiaria',
  raiarmo: 'raiarmo',
  raiasse: 'raiasse',
  raiaste: 'raiaste',
  raiavam: 'raiavam',
  raiavas: 'raiavas',
  raieira: 'raieira',
  raieiro: 'raieiro',
  raiemos: 'raiemos',
  raigoes: 'raigões',
  raigota: 'raigota',
  raigoto: 'raigoto',
  raigras: 'raigrás',
  raiidas: 'raíidas',
  raiidea: 'raiídea',
  raiideo: 'raiídeo',
  raiinho: 'raiinho',
  railhes: 'railhes',
  railuli: 'railuli',
  railway: 'railway',
  raineta: 'raineta',
  rainhas: 'rainhas',
  rainhos: 'rainhos',
  raiolas: 'raiolas',
  raiosas: 'raiosas',
  raiosos: 'raiosos',
  raiputa: 'raiputa',
  raiputo: 'raiputo',
  raitana: 'raitana',
  raiunas: 'raiunas',
  raivaco: 'raivaço',
  raivada: 'raivada',
  raivado: 'raivado',
  raivais: 'raivais',
  raivamo: 'raivamo',
  raivara: 'raivará',
  raivava: 'raivava',
  raiveca: 'raiveça',
  raivece: 'raivece',
  raiveci: 'raiveci',
  raiveco: 'raiveço',
  raiveis: 'raiveis',
  raiveja: 'raiveja',
  raiveje: 'raiveje',
  raivejo: 'raivejo',
  raivemo: 'raivemo',
  raivosa: 'raivosa',
  raivoso: 'raivoso',
  raivuda: 'raivuda',
  raivudo: 'raivudo',
  raizada: 'raizada',
  raizado: 'raizado',
  raizama: 'raizama',
  raizame: 'raizame',
  raizedo: 'raizedo',
  raizepo: 'raizepo',
  rajabes: 'rajabes',
  rajadao: 'rajadão',
  rajadas: 'rajadas',
  rajador: 'rajador',
  rajados: 'rajados',
  rajamos: 'rajamos',
  rajando: 'rajando',
  rajania: 'rajânia',
  rajante: 'rajante',
  rajaram: 'rajaram',
  rajarao: 'rajarão',
  rajaras: 'rajaras',
  rajarei: 'rajarei',
  rajarem: 'rajarem',
  rajares: 'rajares',
  rajaria: 'rajaria',
  rajarmo: 'rajarmo',
  rajasse: 'rajasse',
  rajaste: 'rajaste',
  rajavam: 'rajavam',
  rajavas: 'rajavas',
  rajeira: 'rajeira',
  rajemos: 'rajemos',
  rajidas: 'rájidas',
  rajidea: 'rajídea',
  rajideo: 'rajídeo',
  rajidos: 'rajidos',
  ralacao: 'ralação',
  raladao: 'raladão',
  raladas: 'raladas',
  ralador: 'ralador',
  ralados: 'ralados',
  ralamos: 'ralamos',
  ralando: 'ralando',
  ralante: 'ralante',
  ralaram: 'ralaram',
  ralarao: 'ralarão',
  ralaras: 'ralaras',
  ralarei: 'ralarei',
  ralarem: 'ralarem',
  ralares: 'ralares',
  ralaria: 'ralaria',
  ralarmo: 'ralarmo',
  ralassa: 'ralassa',
  ralasse: 'ralasse',
  ralasso: 'ralasso',
  ralaste: 'ralaste',
  ralavam: 'ralavam',
  ralavas: 'ralavas',
  ralavel: 'ralável',
  raleada: 'raleada',
  raleado: 'raleado',
  raleais: 'raleais',
  raleara: 'raleara',
  raleava: 'raleava',
  raleeis: 'raleeis',
  raleiam: 'raleiam',
  raleias: 'raleias',
  raleiem: 'raleiem',
  raleies: 'raleies',
  raleira: 'raleira',
  raleiro: 'raleiro',
  ralemos: 'ralemos',
  ralenta: 'ralenta',
  ralente: 'ralente',
  ralenti: 'ralenti',
  ralento: 'ralento',
  raletes: 'raletes',
  ralezas: 'ralezas',
  ralfsia: 'rálfsia',
  ralhada: 'ralhada',
  ralhado: 'ralhado',
  ralhais: 'ralhais',
  ralhamo: 'ralhamo',
  ralhara: 'ralhara',
  ralhava: 'ralhava',
  ralheis: 'ralheis',
  ralhemo: 'ralhemo',
  ralheta: 'ralheta',
  ralhete: 'ralhete',
  ralhoes: 'ralhões',
  ralhona: 'ralhona',
  ralicas: 'ralicas',
  ralices: 'ralices',
  ralidas: 'rálidas',
  ralidea: 'ralídea',
  ralideo: 'ralídeo',
  ralinas: 'ralinas',
  ralinea: 'ralínea',
  ralineo: 'ralíneo',
  ralinho: 'ralinho',
  ralutas: 'ralutas',
  ralutos: 'ralutos',
  ramadao: 'ramadão',
  ramadas: 'ramadas',
  ramador: 'ramador',
  ramados: 'ramados',
  ramagem: 'ramagem',
  ramalda: 'ramalda',
  ramalde: 'ramalde',
  ramalha: 'ramalha',
  ramalhe: 'ramalhe',
  ramalho: 'ramalho',
  ramamos: 'ramamos',
  ramando: 'ramando',
  ramanga: 'ramanga',
  ramango: 'ramango',
  ramante: 'ramante',
  ramaram: 'ramaram',
  ramarao: 'ramarão',
  ramaras: 'ramarás',
  ramarei: 'ramarei',
  ramarem: 'ramarem',
  ramares: 'ramares',
  ramaria: 'ramaria',
  ramarmo: 'ramarmo',
  ramasse: 'ramasse',
  ramaste: 'ramaste',
  ramavam: 'ramavam',
  ramavas: 'ramavas',
  ramazas: 'ramazãs',
  rambana: 'rambana',
  ramblas: 'ramblas',
  rambles: 'rambles',
  ramboia: 'ramboia',
  rambola: 'râmbola',
  rambota: 'rambotã',
  rambuta: 'rambutã',
  rameada: 'rameada',
  rameado: 'rameado',
  rameais: 'rameais',
  rameara: 'rameará',
  rameava: 'rameava',
  rameeis: 'rameeis',
  rameiam: 'rameiam',
  rameias: 'rameias',
  rameiem: 'rameiem',
  rameies: 'rameies',
  rameira: 'rameira',
  rameiro: 'rameiro',
  ramelai: 'ramelai',
  ramelam: 'ramelam',
  ramelar: 'ramelar',
  ramelas: 'ramelas',
  ramelei: 'ramelei',
  ramelem: 'ramelem',
  rameles: 'rameles',
  ramelou: 'ramelou',
  ramemos: 'ramemos',
  ramense: 'ramense',
  ramento: 'ramento',
  rametas: 'rametas',
  ramilho: 'ramilho',
  raminha: 'raminha',
  raminho: 'raminho',
  ramisas: 'ramisas',
  ramisco: 'ramisco',
  ramisia: 'ramísia',
  ramismo: 'ramismo',
  ramisos: 'ramisos',
  ramista: 'ramista',
  ramizal: 'ramizal',
  ramnais: 'ramnais',
  ramnale: 'ramnale',
  ramneas: 'râmneas',
  ramneos: 'râmneos',
  ramnina: 'ramnina',
  ramnite: 'ramnite',
  ramnose: 'ramnose',
  ramocai: 'ramocai',
  ramocam: 'ramocam',
  ramocar: 'ramocar',
  ramocas: 'ramocas',
  ramocou: 'ramocou',
  ramolas: 'râmolas',
  ramonas: 'ramonas',
  ramonia: 'ramônia',
  ramoque: 'ramoque',
  ramosas: 'ramosas',
  ramosos: 'ramosos',
  rampada: 'rampada',
  rampado: 'rampado',
  rampana: 'rampana',
  rampane: 'rampane',
  rampano: 'rampano',
  rampeai: 'rampeai',
  rampear: 'rampear',
  rampeei: 'rampeei',
  rampeia: 'rampeia',
  rampeie: 'rampeie',
  rampeio: 'rampeio',
  rampeou: 'rampeou',
  rampona: 'rampona',
  ramudas: 'ramudas',
  ramudos: 'ramudos',
  ramular: 'ramular',
  ramulas: 'râmulas',
  ramulos: 'râmulos',
  ranales: 'ranales',
  ranaria: 'ranária',
  ranario: 'ranário',
  ranatra: 'ranatra',
  rancada: 'rançada',
  rancado: 'rançado',
  rancais: 'rançais',
  rancamo: 'rançamo',
  rancara: 'rançará',
  rancava: 'rançava',
  ranceai: 'ranceai',
  rancear: 'rancear',
  ranceei: 'ranceei',
  ranceia: 'ranceia',
  ranceie: 'ranceie',
  ranceio: 'ranceio',
  ranceis: 'ranceis',
  rancemo: 'rancemo',
  ranceou: 'ranceou',
  ranchao: 'ranchão',
  ranchas: 'ranchas',
  ranchel: 'ranchel',
  ranchos: 'ranchos',
  rancias: 'râncias',
  rancida: 'râncida',
  rancido: 'râncido',
  rancios: 'râncios',
  rancoes: 'ranções',
  rancona: 'rançona',
  rancora: 'rancora',
  rancosa: 'rançosa',
  rancoso: 'rançoso',
  rancura: 'rancura',
  rancure: 'rancure',
  rancuro: 'rancuro',
  randevu: 'randevu',
  randias: 'rândias',
  randita: 'randita',
  randite: 'randite',
  ranelas: 'ranelas',
  ranfada: 'ranfada',
  ranfado: 'ranfado',
  ranfais: 'ranfais',
  ranfamo: 'ranfamo',
  ranfara: 'ranfará',
  ranfava: 'ranfava',
  ranfeis: 'ranfeis',
  ranfemo: 'ranfemo',
  ranfias: 'rânfias',
  rangada: 'rangada',
  rangado: 'rangado',
  rangais: 'rangais',
  rangara: 'rangará',
  rangava: 'rangava',
  rangeis: 'rangeis',
  rangemo: 'rangemo',
  rangera: 'rangera',
  rangiam: 'rangiam',
  rangias: 'rangias',
  rangida: 'rangida',
  rangido: 'rangido',
  rangira: 'rangirá',
  ranguei: 'ranguei',
  ranguem: 'ranguem',
  rangues: 'rangues',
  ranhada: 'ranhada',
  ranhado: 'ranhado',
  ranhais: 'ranhais',
  ranhamo: 'ranhamo',
  ranhara: 'ranhará',
  ranhava: 'ranhava',
  ranheis: 'ranheis',
  ranhemo: 'ranhemo',
  ranheta: 'ranheta',
  ranhoca: 'ranhoca',
  ranhoes: 'ranhões',
  ranhola: 'ranhola',
  ranhosa: 'ranhosa',
  ranhoso: 'ranhoso',
  ranhura: 'ranhura',
  ranhure: 'ranhure',
  ranhuro: 'ranhuro',
  ranhuza: 'ranhuzá',
  ranhuze: 'ranhuze',
  ranhuzo: 'ranhuzo',
  ranidas: 'rânidas',
  ranidea: 'ranídea',
  ranideo: 'ranídeo',
  ranilha: 'ranilha',
  ranilhe: 'ranilhe',
  ranilho: 'ranilho',
  ranilia: 'ranília',
  raninas: 'raninas',
  raninea: 'ranínea',
  ranineo: 'raníneo',
  raninos: 'raninos',
  ranismo: 'ranismo',
  ranista: 'ranista',
  ranjais: 'ranjais',
  ranjamo: 'ranjamo',
  ranking: 'ranking',
  ranoide: 'ranoide',
  ranquea: 'ranqueá',
  ranques: 'ranques',
  ranulas: 'rânulas',
  ranzais: 'ranzais',
  rapacao: 'rapação',
  rapacea: 'rapácea',
  rapaceo: 'rapáceo',
  rapaces: 'rapaces',
  rapadao: 'rapadão',
  rapadas: 'rapadas',
  rapador: 'rapador',
  rapados: 'rapados',
  rapagao: 'rapagão',
  rapagem: 'rapagem',
  rapagoa: 'rapagoa',
  rapamos: 'rapamos',
  rapanas: 'rapanas',
  rapanca: 'rapança',
  rapando: 'rapando',
  rapanea: 'rapânea',
  rapanos: 'rapanos',
  rapante: 'rapante',
  rapanui: 'rapanui',
  rapapes: 'rapapés',
  raparam: 'raparam',
  raparao: 'raparão',
  raparas: 'raparas',
  raparei: 'raparei',
  raparem: 'raparem',
  rapares: 'rapares',
  raparia: 'raparia',
  raparmo: 'raparmo',
  rapasse: 'rapasse',
  rapaste: 'rapaste',
  rapatea: 'rapátea',
  rapavam: 'rapavam',
  rapavas: 'rapavas',
  rapazao: 'rapazão',
  rapazas: 'rapazas',
  rapazes: 'rapazes',
  rapazia: 'rapazia',
  rapazio: 'rapazio',
  rapeira: 'rapeira',
  rapelai: 'rapelai',
  rapelam: 'rapelam',
  rapelar: 'rapelar',
  rapelas: 'rapelas',
  rapelei: 'rapelei',
  rapelem: 'rapelem',
  rapeles: 'rapeles',
  rapelha: 'rapelha',
  rapelho: 'rapelho',
  rapelou: 'rapelou',
  rapemos: 'rapemos',
  rapiaca: 'rapiaça',
  rapiada: 'rapiada',
  rapiado: 'rapiado',
  rapiais: 'rapiais',
  rapiamo: 'rapiamo',
  rapiara: 'rapiara',
  rapiava: 'rapiava',
  rapicel: 'rapicel',
  rapidas: 'rápidas',
  rapidez: 'rapidez',
  rapidos: 'rápidos',
  rapieis: 'rapieis',
  rapiemo: 'rapiemo',
  rapigai: 'rapigai',
  rapigam: 'rapigam',
  rapigar: 'rapigar',
  rapigas: 'rapigas',
  rapigos: 'rapigos',
  rapigou: 'rapigou',
  rapigue: 'rapigue',
  rapilha: 'rapilha',
  rapilhe: 'rapilhe',
  rapilho: 'rapilho',
  rapinai: 'rapinai',
  rapinam: 'rapinam',
  rapinar: 'rapinar',
  rapinas: 'rapinas',
  rapinei: 'rapinei',
  rapinem: 'rapinem',
  rapines: 'rapines',
  rapinha: 'rapinha',
  rapinhe: 'rapinhe',
  rapinho: 'rapinho',
  rapinou: 'rapinou',
  rapioca: 'rapioca',
  rapolas: 'rapolas',
  raponco: 'raponço',
  raponso: 'raponso',
  raporte: 'raporte',
  raposai: 'raposai',
  raposam: 'raposam',
  raposao: 'raposão',
  raposar: 'raposar',
  raposas: 'raposas',
  raposea: 'raposeá',
  raposei: 'raposei',
  raposem: 'raposem',
  raposes: 'raposes',
  raposia: 'raposia',
  raposim: 'raposim',
  raposio: 'raposio',
  raposos: 'raposos',
  raposou: 'raposou',
  rappeis: 'rappeis',
  rappias: 'ráppias',
  rapsoda: 'rapsoda',
  rapsode: 'rapsode',
  rapsodo: 'rapsodo',
  raptada: 'raptada',
  raptado: 'raptado',
  raptais: 'raptais',
  raptamo: 'raptamo',
  raptara: 'raptara',
  raptava: 'raptava',
  rapteis: 'rapteis',
  raptemo: 'raptemo',
  raptora: 'raptora',
  rapumes: 'rapumes',
  raqueis: 'raquéis',
  raqueta: 'raqueta',
  raquete: 'raquete',
  raquial: 'raquial',
  raquios: 'ráquios',
  raquite: 'raquite',
  raquius: 'raquius',
  raradao: 'raradão',
  raradas: 'raradas',
  rarador: 'rarador',
  rarados: 'rarados',
  raramos: 'raramos',
  rarando: 'rarando',
  rarante: 'rarante',
  rararam: 'rararam',
  rararao: 'rararão',
  rararas: 'rararás',
  rararei: 'rararei',
  rararem: 'rararem',
  rarares: 'rarares',
  rararia: 'rararia',
  rararmo: 'rararmo',
  rarasse: 'rarasse',
  raraste: 'raraste',
  raravam: 'raravam',
  raravas: 'raravas',
  rareada: 'rareada',
  rareado: 'rareado',
  rareais: 'rareais',
  rareamo: 'rareamo',
  rareara: 'rareara',
  rareava: 'rareava',
  rareeis: 'rareeis',
  rareemo: 'rareemo',
  rarefaz: 'rarefaz',
  rarefez: 'rarefez',
  rarefiz: 'rarefiz',
  rareiam: 'rareiam',
  rareias: 'rareias',
  rareiem: 'rareiem',
  rareies: 'rareies',
  rareira: 'rareira',
  rarejai: 'rarejai',
  rarejam: 'rarejam',
  rarejar: 'rarejar',
  rarejas: 'rarejas',
  rarejei: 'rarejei',
  rarejem: 'rarejem',
  rarejes: 'rarejes',
  rarejou: 'rarejou',
  raremos: 'raremos',
  raresca: 'raresça',
  raresce: 'raresce',
  raresci: 'raresci',
  raresco: 'raresço',
  rarezas: 'rarezas',
  rasadao: 'rasadão',
  rasadas: 'rasadas',
  rasador: 'rasador',
  rasados: 'rasados',
  rasamos: 'rasamos',
  rasando: 'rasando',
  rasante: 'rasante',
  rasaram: 'rasaram',
  rasarao: 'rasarão',
  rasaras: 'rasarás',
  rasarei: 'rasarei',
  rasarem: 'rasarem',
  rasares: 'rasares',
  rasaria: 'rasaria',
  rasarmo: 'rasarmo',
  rasasse: 'rasasse',
  rasaste: 'rasaste',
  rasavam: 'rasavam',
  rasavas: 'rasavas',
  rasbora: 'rasbora',
  rasbuto: 'rasbuto',
  rascada: 'rascada',
  rascado: 'rascado',
  rascais: 'rascais',
  rascamo: 'rascamo',
  rascara: 'rascara',
  rascava: 'rascava',
  rascoas: 'rascoas',
  rascoes: 'rascões',
  rascois: 'rascóis',
  rascole: 'rascole',
  rascote: 'rascote',
  raseira: 'raseira',
  raseiro: 'raseiro',
  rasemos: 'rasemos',
  rasense: 'rasense',
  rasgada: 'rasgada',
  rasgado: 'rasgado',
  rasgais: 'rasgais',
  rasgamo: 'rasgamo',
  rasgara: 'rasgara',
  rasgava: 'rasgava',
  rasgoes: 'rasgões',
  rasguei: 'rasguei',
  rasguem: 'rasguem',
  rasgues: 'rasgues',
  rasinas: 'rasinas',
  rasinho: 'rasinho',
  rasmono: 'rasmono',
  rasoila: 'rasoila',
  rasoilo: 'rasoilo',
  rasoira: 'rasoira',
  rasoire: 'rasoire',
  rasoiro: 'rasoiro',
  rasolho: 'rasolho',
  rasoura: 'rasoura',
  rasoure: 'rasoure',
  rasouro: 'rasouro',
  raspada: 'raspada',
  raspado: 'raspado',
  raspais: 'raspais',
  raspamo: 'raspamo',
  raspara: 'raspara',
  raspava: 'raspava',
  raspeis: 'raspeis',
  raspemo: 'raspemo',
  raspita: 'raspita',
  raspite: 'raspite',
  raspoes: 'raspões',
  rasquea: 'rasqueá',
  rasquei: 'rasquei',
  rasquem: 'rasquem',
  rasques: 'rasques',
  rasteai: 'rasteai',
  rastear: 'rastear',
  rasteei: 'rasteei',
  rasteia: 'rasteia',
  rasteie: 'rasteie',
  rasteio: 'rasteio',
  rasteja: 'rasteja',
  rasteje: 'rasteje',
  rastejo: 'rastejo',
  rastela: 'rastela',
  rastele: 'rastele',
  rastelo: 'rastelo',
  rasteou: 'rasteou',
  rastica: 'rastiça',
  rastico: 'rastiço',
  rastoes: 'rastões',
  rastral: 'rastral',
  rastras: 'rastras',
  rastrea: 'rastreá',
  rastros: 'rastros',
  rasulha: 'rasulha',
  rasulho: 'rasulho',
  rasurai: 'rasurai',
  rasuram: 'rasuram',
  rasurar: 'rasurar',
  rasuras: 'rasuras',
  rasurei: 'rasurei',
  rasurem: 'rasurem',
  rasures: 'rasures',
  rasurou: 'rasurou',
  ratadao: 'ratadão',
  ratadas: 'ratadas',
  ratador: 'ratador',
  ratados: 'ratados',
  ratafia: 'ratafia',
  ratagem: 'ratagem',
  ratalia: 'ratalia',
  ratamos: 'ratamos',
  ratando: 'ratando',
  ratanha: 'ratanha',
  ratanhi: 'ratanhi',
  ratania: 'ratânia',
  ratante: 'ratante',
  ratapla: 'rataplã',
  rataram: 'rataram',
  ratarao: 'ratarão',
  rataras: 'ratarás',
  ratarei: 'ratarei',
  ratarem: 'ratarem',
  ratares: 'ratares',
  rataria: 'rataria',
  ratarmo: 'ratarmo',
  ratasse: 'ratasse',
  rataste: 'rataste',
  ratatau: 'ratatau',
  ratavam: 'ratavam',
  ratavas: 'ratavas',
  rateada: 'rateada',
  rateado: 'rateado',
  rateais: 'rateais',
  rateamo: 'rateamo',
  rateara: 'rateara',
  rateava: 'rateava',
  rateeis: 'rateeis',
  rateemo: 'rateemo',
  rateiam: 'rateiam',
  rateias: 'rateias',
  rateiem: 'rateiem',
  rateies: 'rateies',
  rateios: 'rateios',
  rateira: 'rateira',
  rateiro: 'rateiro',
  ratemos: 'ratemos',
  ratense: 'ratense',
  rathita: 'rathita',
  ratices: 'ratices',
  raticum: 'raticum',
  ratimbo: 'ratimbó',
  ratinai: 'ratinai',
  ratinam: 'ratinam',
  ratinar: 'ratinar',
  ratinas: 'ratinas',
  ratinei: 'ratinei',
  ratinem: 'ratinem',
  ratines: 'ratines',
  ratinha: 'ratinha',
  ratinhe: 'ratinhe',
  ratinho: 'ratinho',
  ratinos: 'ratinos',
  ratinou: 'ratinou',
  ratitas: 'ratitas',
  ratites: 'ratites',
  ratoice: 'ratoíce',
  ratonai: 'ratonai',
  ratonam: 'ratonam',
  ratonar: 'ratonar',
  ratonas: 'ratonas',
  ratonea: 'ratoneá',
  ratonei: 'ratonei',
  ratonem: 'ratonem',
  ratones: 'ratones',
  ratonou: 'ratonou',
  ratuina: 'ratuína',
  ratuino: 'ratuíno',
  ratulos: 'rátulos',
  raudais: 'raudais',
  raudiva: 'raudiva',
  raudoes: 'raudões',
  rauitas: 'rauitas',
  rauites: 'rauites',
  raumite: 'raumite',
  rauraca: 'rauraca',
  rauraco: 'rauraco',
  raurica: 'raurica',
  raurico: 'raurico',
  rausada: 'rausada',
  rausado: 'rausado',
  rausais: 'rausais',
  rausamo: 'rausamo',
  rausara: 'rausará',
  rausava: 'rausava',
  rauseis: 'rauseis',
  rausemo: 'rausemo',
  rauvita: 'rauvita',
  ravanas: 'ravanas',
  ravasca: 'ravasca',
  ravasco: 'ravasco',
  ravenha: 'ravenhá',
  ravenhe: 'ravenhe',
  ravenho: 'ravenho',
  ravenia: 'ravênia',
  ravessa: 'ravessa',
  ravetas: 'ravetas',
  ravinai: 'ravinai',
  ravinam: 'ravinam',
  ravinar: 'ravinar',
  ravinas: 'ravinas',
  ravinei: 'ravinei',
  ravinem: 'ravinem',
  ravines: 'ravines',
  ravinha: 'ravinha',
  ravinhe: 'ravinhe',
  ravinho: 'ravinho',
  ravinou: 'ravinou',
  raviois: 'ravióis',
  ravioli: 'ravióli',
  raxetas: 'raxetas',
  raziada: 'raziada',
  raziado: 'raziado',
  raziais: 'raziais',
  raziamo: 'raziamo',
  raziara: 'raziara',
  raziava: 'raziava',
  razieis: 'razieis',
  raziemo: 'raziemo',
  razimos: 'razimos',
  razinha: 'rãzinha',
  razoada: 'razoada',
  razoado: 'razoado',
  razoais: 'razoais',
  razoamo: 'razoamo',
  razoara: 'razoará',
  razoava: 'razoava',
  razoeis: 'razoeis',
  razoemo: 'razoemo',
  razonai: 'razonai',
  razonam: 'razonam',
  razonar: 'razonar',
  razonas: 'razonas',
  razonei: 'razonei',
  razonem: 'razonem',
  razones: 'razones',
  razonou: 'razonou',
  reabita: 'reabita',
  reabite: 'reabite',
  reabito: 'reabito',
  reabram: 'reabram',
  reabras: 'reabras',
  reabrem: 'reabrem',
  reabres: 'reabres',
  reabria: 'reabria',
  reabrir: 'reabrir',
  reabris: 'reabris',
  reabriu: 'reabriu',
  reacesa: 'reacesa',
  reaceso: 'reaceso',
  reachai: 'reachai',
  reacham: 'reacham',
  reachar: 'reachar',
  reachas: 'reachas',
  reachei: 'reachei',
  reachem: 'reachem',
  reaches: 'reaches',
  reachou: 'reachou',
  reacoes: 'reações',
  reacona: 'reaçona',
  reacusa: 'reacusa',
  reacuse: 'reacuse',
  reacuso: 'reacuso',
  readais: 'readais',
  readale: 'readale',
  readina: 'readina',
  readota: 'readota',
  readote: 'readote',
  readoto: 'readoto',
  reafeta: 'reafetá',
  reafete: 'reafete',
  reafeto: 'reafeto',
  reafixa: 'reafixa',
  reafixe: 'reafixe',
  reafixo: 'reafixo',
  reagiam: 'reagiam',
  reagias: 'reagias',
  reagida: 'reagida',
  reagido: 'reagido',
  reagimo: 'reagimo',
  reagina: 'reagina',
  reagira: 'reagira',
  reajais: 'reajais',
  reajamo: 'reajamo',
  realcai: 'realçai',
  realcam: 'realçam',
  realcar: 'realçar',
  realcas: 'realças',
  realcei: 'realcei',
  realcem: 'realcem',
  realces: 'realces',
  realcou: 'realçou',
  realeja: 'realeja',
  realeje: 'realeje',
  realejo: 'realejo',
  realeta: 'realeta',
  realete: 'realete',
  realeto: 'realeto',
  realeza: 'realeza',
  realgar: 'realgar',
  realito: 'realito',
  realiza: 'realiza',
  realize: 'realize',
  realizo: 'realizo',
  realoca: 'realoca',
  realoco: 'realoco',
  realoja: 'realoja',
  realoje: 'realoje',
  realojo: 'realojo',
  realuga: 'realuga',
  realugo: 'realugo',
  realzao: 'realzão',
  reamada: 'reamada',
  reamado: 'reamado',
  reamais: 'reamais',
  reamamo: 'reamamo',
  reamara: 'reamará',
  reamava: 'reamava',
  reameis: 'reameis',
  reamemo: 'reamemo',
  reandai: 'reandai',
  reandam: 'reandam',
  reandar: 'reandar',
  reandas: 'reandas',
  reandei: 'reandei',
  reandem: 'reandem',
  reandes: 'reandes',
  reandou: 'reandou',
  reanexa: 'reanexá',
  reanexe: 'reanexe',
  reanexo: 'reanexo',
  reanhas: 'reanhas',
  reanima: 'reanima',
  reanime: 'reanime',
  reanimo: 'reanimo',
  reanita: 'reanita',
  reanite: 'reanite',
  reanoda: 'reanodá',
  reanode: 'reanode',
  reanodo: 'reanodo',
  rearmai: 'rearmai',
  rearmam: 'rearmam',
  rearmar: 'rearmar',
  rearmas: 'rearmas',
  rearmei: 'rearmei',
  rearmem: 'rearmem',
  rearmes: 'rearmes',
  rearmou: 'rearmou',
  reatada: 'reatada',
  reatado: 'reatado',
  reatais: 'reatais',
  reatamo: 'reatamo',
  reatara: 'reatara',
  reatava: 'reatava',
  reateai: 'reateai',
  reatear: 'reatear',
  reateei: 'reateei',
  reateia: 'reateia',
  reateie: 'reateie',
  reateio: 'reateio',
  reateis: 'reateis',
  reatemo: 'reatemo',
  reateou: 'reateou',
  reatica: 'reatiça',
  reatice: 'reatice',
  reatico: 'reatiço',
  reatina: 'reatina',
  reatino: 'reatino',
  reativa: 'reativa',
  reative: 'reative',
  reativo: 'reativo',
  reatora: 'reatora',
  reautua: 'reautuá',
  reautue: 'reautue',
  reautuo: 'reautuo',
  reaveio: 'reaveio',
  reaveis: 'reaveis',
  reavemo: 'reavemo',
  reavens: 'reavéns',
  reavera: 'reaverá',
  reaviai: 'reaviai',
  reaviam: 'reaviam',
  reaviar: 'reaviar',
  reavias: 'reavias',
  reavida: 'reavida',
  reavido: 'reavido',
  reaviei: 'reaviei',
  reaviem: 'reaviem',
  reavier: 'reavier',
  reavies: 'reavies',
  reaviou: 'reaviou',
  reavira: 'reavirá',
  reavisa: 'reavisa',
  reavise: 'reavise',
  reaviso: 'reaviso',
  reaviva: 'reaviva',
  reavive: 'reavive',
  reavivo: 'reavivo',
  rebabes: 'rebabes',
  rebacas: 'rebaçãs',
  rebadao: 'rebadão',
  rebadas: 'rebadas',
  rebador: 'rebador',
  rebados: 'rebados',
  rebaixa: 'rebaixa',
  rebaixe: 'rebaixe',
  rebaixo: 'rebaixo',
  rebalde: 'rebalde',
  rebalsa: 'rebalsa',
  rebalse: 'rebalse',
  rebalso: 'rebalso',
  rebalva: 'rebalva',
  rebamos: 'rebamos',
  rebanai: 'rebanai',
  rebanam: 'rebanam',
  rebanar: 'rebanar',
  rebanas: 'rebanas',
  rebando: 'rebando',
  rebanea: 'rebaneá',
  rebanei: 'rebanei',
  rebanem: 'rebanem',
  rebanes: 'rebanes',
  rebanha: 'rebanha',
  rebanhe: 'rebanhe',
  rebanho: 'rebanho',
  rebanou: 'rebanou',
  rebaram: 'rebaram',
  rebarao: 'rebarão',
  rebaras: 'rebaras',
  rebarba: 'rebarba',
  rebarbe: 'rebarbe',
  rebarbo: 'rebarbo',
  rebarei: 'rebarei',
  rebarem: 'rebarem',
  rebares: 'rebares',
  rebaria: 'rebaria',
  rebarmo: 'rebarmo',
  rebasse: 'rebasse',
  rebaste: 'rebaste',
  rebatai: 'rebatai',
  rebatam: 'rebatam',
  rebatar: 'rebatar',
  rebatas: 'rebatas',
  rebatei: 'rebatei',
  rebatem: 'rebatem',
  rebater: 'rebater',
  rebates: 'rebates',
  rebateu: 'rebateu',
  rebatia: 'rebatia',
  rebatou: 'rebatou',
  rebavam: 'rebavam',
  rebavas: 'rebavas',
  rebebam: 'rebebam',
  rebebas: 'rebebas',
  rebebei: 'rebebei',
  rebebem: 'rebebem',
  rebeber: 'rebeber',
  rebebes: 'rebebes',
  rebebeu: 'rebebeu',
  rebebia: 'rebebia',
  rebecao: 'rebecão',
  rebecas: 'rebecas',
  rebeija: 'rebeija',
  rebeije: 'rebeije',
  rebeijo: 'rebeijo',
  rebelai: 'rebelai',
  rebelam: 'rebelam',
  rebelao: 'rebelão',
  rebelar: 'rebelar',
  rebelas: 'rebelas',
  rebelde: 'rebelde',
  rebelei: 'rebelei',
  rebelem: 'rebelem',
  rebeles: 'rebeles',
  rebelim: 'rebelim',
  rebelou: 'rebelou',
  rebemos: 'rebemos',
  rebenta: 'rebenta',
  rebente: 'rebente',
  rebento: 'rebento',
  rebenza: 'rebenza',
  rebenze: 'rebenze',
  rebenzi: 'rebenzi',
  rebenzo: 'rebenzo',
  rebeque: 'rebeque',
  reberra: 'reberrá',
  reberre: 'reberre',
  reberro: 'reberro',
  reberta: 'reberta',
  rebibiu: 'rebibiu',
  rebicai: 'rebicai',
  rebicam: 'rebicam',
  rebicar: 'rebicar',
  rebicas: 'rebicas',
  rebicou: 'rebicou',
  rebimba: 'rebimba',
  rebimbe: 'rebimbe',
  rebimbo: 'rebimbo',
  rebique: 'rebique',
  rebisca: 'rebiscá',
  rebisco: 'rebisco',
  rebitai: 'rebitai',
  rebitam: 'rebitam',
  rebitar: 'rebitar',
  rebitas: 'rebitas',
  rebitei: 'rebitei',
  rebitem: 'rebitem',
  rebites: 'rebites',
  rebitou: 'rebitou',
  reboada: 'reboada',
  reboado: 'reboado',
  reboais: 'reboais',
  reboamo: 'reboamo',
  reboara: 'reboara',
  reboava: 'reboava',
  rebocai: 'rebocai',
  rebocam: 'rebocam',
  rebocar: 'rebocar',
  rebocas: 'rebocas',
  rebocos: 'rebocos',
  rebocou: 'rebocou',
  reboeis: 'reboeis',
  reboemo: 'reboemo',
  rebojai: 'rebojai',
  rebojam: 'rebojam',
  rebojar: 'rebojar',
  rebojas: 'rebojas',
  rebojei: 'rebojei',
  rebojem: 'rebojem',
  rebojes: 'rebojes',
  rebojos: 'rebojos',
  rebojou: 'rebojou',
  rebolai: 'rebolai',
  rebolal: 'rebolal',
  rebolam: 'rebolam',
  rebolao: 'rebolão',
  rebolar: 'rebolar',
  rebolas: 'rebolas',
  rebolca: 'rebolca',
  rebolco: 'rebolco',
  rebolea: 'reboleá',
  rebolei: 'rebolei',
  rebolem: 'rebolem',
  reboles: 'reboles',
  rebolia: 'rebolia',
  rebolir: 'rebolir',
  rebolis: 'rebolis',
  reboliu: 'reboliu',
  rebolos: 'rebolos',
  rebolou: 'rebolou',
  rebomba: 'rebomba',
  rebombe: 'rebombe',
  rebombo: 'rebombo',
  reboque: 'reboque',
  reborai: 'reborai',
  reboram: 'reboram',
  reborar: 'reborar',
  reboras: 'reboras',
  reborda: 'reborda',
  reborde: 'reborde',
  rebordo: 'rebordo',
  reborei: 'reborei',
  reborem: 'reborem',
  rebores: 'rebores',
  reborou: 'reborou',
  reboses: 'reboses',
  rebotai: 'rebotai',
  rebotam: 'rebotam',
  rebotar: 'rebotar',
  rebotas: 'rebotas',
  rebotei: 'rebotei',
  rebotem: 'rebotem',
  rebotes: 'rebotes',
  rebotou: 'rebotou',
  rebouca: 'rebouça',
  rebouce: 'rebouce',
  rebouco: 'rebouço',
  rebraca: 'rebraça',
  rebraco: 'rebraço',
  rebrada: 'rebradá',
  rebrade: 'rebrade',
  rebrado: 'rebrado',
  rebrama: 'rebrama',
  rebrame: 'rebrame',
  rebrami: 'rebrami',
  rebramo: 'rebramo',
  rebrias: 'rebrias',
  rebrota: 'rebrota',
  rebrote: 'rebrote',
  rebroto: 'rebroto',
  rebruna: 'rebruna',
  rebrune: 'rebrune',
  rebruni: 'rebruni',
  rebruno: 'rebruno',
  rebucai: 'rebuçai',
  rebucam: 'rebuçam',
  rebucar: 'rebuçar',
  rebucas: 'rebuças',
  rebucei: 'rebucei',
  rebucem: 'rebucem',
  rebuces: 'rebuces',
  rebucos: 'rebuços',
  rebucou: 'rebuçou',
  rebufai: 'rebufai',
  rebufam: 'rebufam',
  rebufar: 'rebufar',
  rebufas: 'rebufas',
  rebufei: 'rebufei',
  rebufem: 'rebufem',
  rebufes: 'rebufes',
  rebufos: 'rebufos',
  rebufou: 'rebufou',
  rebulam: 'rebulam',
  rebulas: 'rebulas',
  rebulha: 'rebulhá',
  rebulhe: 'rebulhe',
  rebulho: 'rebulho',
  rebulia: 'rebulia',
  rebulir: 'rebulir',
  rebulis: 'rebulis',
  rebuliu: 'rebuliu',
  rebumba: 'rebumba',
  rebumbe: 'rebumbe',
  rebumbo: 'rebumbo',
  rebusca: 'rebusca',
  rebusco: 'rebusco',
  rebusna: 'rebusna',
  rebusne: 'rebusne',
  rebusno: 'rebusno',
  rebutes: 'rebutes',
  recabdo: 'recábdo',
  recabem: 'recabém',
  recacau: 'recacau',
  recacha: 'recacha',
  recache: 'recache',
  recacho: 'recacho',
  recadai: 'recadai',
  recadam: 'recadam',
  recadao: 'recadão',
  recadar: 'recadar',
  recadas: 'recadas',
  recadea: 'recadeá',
  recadei: 'recadei',
  recadem: 'recadém',
  recades: 'recades',
  recador: 'recador',
  recados: 'recados',
  recadou: 'recadou',
  recaiam: 'recaiam',
  recaias: 'recaias',
  recaida: 'recaída',
  recaido: 'recaído',
  recaimo: 'recaímo',
  recaira: 'recairá',
  recalai: 'recalai',
  recalam: 'recalam',
  recalar: 'recalar',
  recalas: 'recalas',
  recalca: 'recalca',
  recalco: 'recalco',
  recalei: 'recalei',
  recalem: 'recalem',
  recales: 'recales',
  recalha: 'recalha',
  recalma: 'recalma',
  recalmo: 'recalmo',
  recalou: 'recalou',
  recamai: 'recamai',
  recamam: 'recamam',
  recamar: 'recamar',
  recamas: 'recamas',
  recambo: 'recambó',
  recamei: 'recamei',
  recamem: 'recamem',
  recames: 'recames',
  recamos: 'recamos',
  recamou: 'recamou',
  recando: 'recando',
  recanta: 'recanta',
  recante: 'recante',
  recanto: 'recanto',
  recapai: 'recapai',
  recapam: 'recapam',
  recapar: 'recapar',
  recapas: 'recapas',
  recapea: 'recapeá',
  recapei: 'recapei',
  recapem: 'recapem',
  recapes: 'recapes',
  recapou: 'recapou',
  recapta: 'recapta',
  recapte: 'recapte',
  recapto: 'recapto',
  recaram: 'recaram',
  recarao: 'recarão',
  recaras: 'recaras',
  recarda: 'recarda',
  recarde: 'recarde',
  recardo: 'recardo',
  recarei: 'recarei',
  recarem: 'recarem',
  recares: 'recares',
  recarga: 'recarga',
  recargo: 'recargo',
  recaria: 'recaria',
  recarmo: 'recarmo',
  recasai: 'recasai',
  recasam: 'recasam',
  recasar: 'recasar',
  recasas: 'recasas',
  recasei: 'recasei',
  recasem: 'recasem',
  recases: 'recases',
  recasou: 'recasou',
  recasse: 'recasse',
  recaste: 'recaste',
  recatai: 'recatai',
  recatam: 'recatam',
  recatar: 'recatar',
  recatas: 'recatas',
  recatei: 'recatei',
  recatem: 'recatem',
  recates: 'recates',
  recatos: 'recatos',
  recatou: 'recatou',
  recauda: 'recauda',
  recaudo: 'recaudo',
  recavai: 'recavai',
  recavam: 'recavam',
  recavar: 'recavar',
  recavas: 'recavas',
  recavei: 'recavei',
  recavem: 'recavém',
  recaves: 'recaves',
  recavou: 'recavou',
  receada: 'receada',
  receado: 'receado',
  receais: 'receais',
  receamo: 'receamo',
  receara: 'receara',
  receava: 'receava',
  recebam: 'recebam',
  recebas: 'recebas',
  recebei: 'recebei',
  recebem: 'recebem',
  receber: 'receber',
  recebes: 'recebes',
  recebeu: 'recebeu',
  recebia: 'recebia',
  rececao: 'receção',
  recedam: 'recedam',
  recedas: 'recedas',
  recedei: 'recedei',
  recedem: 'recedem',
  receder: 'receder',
  recedes: 'recedes',
  recedeu: 'recedeu',
  recedia: 'recedia',
  receeis: 'receeis',
  receemo: 'receemo',
  receiam: 'receiam',
  receias: 'receias',
  receiem: 'receiem',
  receies: 'receies',
  receios: 'receios',
  receita: 'receita',
  receite: 'receite',
  receito: 'receito',
  recenai: 'recenai',
  recenam: 'recenam',
  recenar: 'recenar',
  recenas: 'recenas',
  recenda: 'recenda',
  recende: 'recende',
  recendi: 'recendi',
  recendo: 'recendo',
  recenei: 'recenei',
  recenem: 'recenem',
  recenes: 'recenes',
  recenou: 'recenou',
  recente: 'recente',
  receosa: 'receosa',
  receoso: 'receoso',
  recepta: 'recepta',
  recepte: 'recepte',
  recepto: 'recepto',
  recerra: 'recerrá',
  recerre: 'recerre',
  recerro: 'recerro',
  recerta: 'recerta',
  recerto: 'recerto',
  recesso: 'recesso',
  recetar: 'recetar',
  recetor: 'recetor',
  rechaca: 'rechaça',
  rechace: 'rechace',
  rechaco: 'rechaço',
  rechana: 'rechana',
  rechano: 'rechano',
  rechaos: 'rechãos',
  rechapa: 'rechapá',
  rechape: 'rechape',
  rechapo: 'rechapo',
  rechaud: 'réchaud',
  recheai: 'recheai',
  rechear: 'rechear',
  recheca: 'recheca',
  recheco: 'recheco',
  recheei: 'recheei',
  rechega: 'rechega',
  rechego: 'rechego',
  recheia: 'recheia',
  recheie: 'recheie',
  recheio: 'recheio',
  recheou: 'recheou',
  rechiai: 'rechiai',
  rechiam: 'rechiam',
  rechiar: 'rechiar',
  rechias: 'rechias',
  rechiei: 'rechiei',
  rechiem: 'rechiem',
  rechies: 'rechies',
  rechina: 'rechina',
  rechine: 'rechine',
  rechino: 'rechino',
  rechiou: 'rechiou',
  rechupa: 'rechupa',
  rechupe: 'rechupe',
  rechupo: 'rechupo',
  reciana: 'reciana',
  reciano: 'reciano',
  recibai: 'recibai',
  recibam: 'recibam',
  recibar: 'recibar',
  recibas: 'recibas',
  recibei: 'recibei',
  recibem: 'recibem',
  recibes: 'recibes',
  recibos: 'recibos',
  recibou: 'recibou',
  recicla: 'recicla',
  recicle: 'recicle',
  reciclo: 'reciclo',
  recifal: 'recifal',
  recifes: 'recifes',
  recinge: 'recinge',
  recingi: 'recingi',
  recinja: 'recinja',
  recinjo: 'recinjo',
  recinta: 'recinta',
  recinte: 'recinte',
  recinto: 'recinto',
  reciolo: 'recíolo',
  recipes: 'récipes',
  recitai: 'recitai',
  recital: 'recital',
  recitam: 'recitam',
  recitar: 'recitar',
  recitas: 'recitas',
  recitei: 'recitei',
  recitem: 'recitem',
  recites: 'recites',
  recitou: 'recitou',
  reclama: 'reclama',
  reclame: 'reclame',
  reclamo: 'reclamo',
  reclina: 'reclina',
  recline: 'recline',
  reclino: 'reclino',
  recluam: 'recluam',
  recluas: 'recluas',
  recluem: 'recluem',
  recluia: 'recluía',
  recluir: 'recluir',
  recluis: 'recluis',
  recluiu: 'recluiu',
  reclusa: 'reclusa',
  recluse: 'recluse',
  recluso: 'recluso',
  recluta: 'recluta',
  recobra: 'recobra',
  recobre: 'recobre',
  recobri: 'recobri',
  recobro: 'recobro',
  recocai: 'recocai',
  recocam: 'recocam',
  recocar: 'recocar',
  recocas: 'recocas',
  recocou: 'recocou',
  recocta: 'recocta',
  recocto: 'recocto',
  recodem: 'recodem',
  recodes: 'recodes',
  recoice: 'recoice',
  recoita: 'recoita',
  recoite: 'recoite',
  recoito: 'recoito',
  recolai: 'recolai',
  recolam: 'recolam',
  recolar: 'recolar',
  recolas: 'recolas',
  recolei: 'recolei',
  recolem: 'recolem',
  recoles: 'recoles',
  recolha: 'recolha',
  recolhe: 'recolhe',
  recolhi: 'recolhi',
  recolho: 'recolho',
  recolos: 'recolos',
  recolou: 'recolou',
  recolta: 'recolta',
  recolte: 'recolte',
  recolto: 'recolto',
  recomam: 'recomam',
  recomas: 'recomas',
  recomei: 'recomei',
  recomem: 'recomem',
  recomer: 'recomer',
  recomes: 'recomes',
  recomeu: 'recomeu',
  recomia: 'recomia',
  recompo: 'recompô',
  reconta: 'reconta',
  reconte: 'reconte',
  reconto: 'reconto',
  recopia: 'recópia',
  recopie: 'recopie',
  recopio: 'recopio',
  recoque: 'recoque',
  recorca: 'recorça',
  recorce: 'recorce',
  recorco: 'recorço',
  recorda: 'recorda',
  recorde: 'recorde',
  recordo: 'recordo',
  recoroa: 'recoroa',
  recoroe: 'recoroe',
  recoroo: 'recoroo',
  recorra: 'recorra',
  recorre: 'recorre',
  recorri: 'recorri',
  recorro: 'recorro',
  recorta: 'recorta',
  recorte: 'recorte',
  recorto: 'recorto',
  recosam: 'recosam',
  recosas: 'recosas',
  recosei: 'recosei',
  recosem: 'recosem',
  recoser: 'recoser',
  recoses: 'recoses',
  recoseu: 'recoseu',
  recosia: 'recosia',
  recosta: 'recosta',
  recoste: 'recoste',
  recosto: 'recosto',
  recouce: 'recouce',
  recouta: 'recouta',
  recoute: 'recoute',
  recouto: 'recouto',
  recovai: 'recovai',
  recovam: 'recovam',
  recovar: 'recovar',
  recovas: 'recovas',
  recovei: 'recovei',
  recovem: 'recovem',
  recoves: 'recoves',
  recovou: 'recovou',
  recozam: 'recozam',
  recozas: 'recozas',
  recozei: 'recozei',
  recozem: 'recozem',
  recozer: 'recozer',
  recozes: 'recozes',
  recozeu: 'recozeu',
  recozia: 'recozia',
  recramo: 'recramo',
  recrava: 'recrava',
  recrave: 'recrave',
  recravo: 'recravo',
  recreai: 'recreai',
  recrear: 'recrear',
  recreei: 'recreei',
  recreia: 'recreia',
  recreie: 'recreie',
  recreio: 'recreio',
  recreou: 'recreou',
  recriai: 'recriai',
  recriam: 'recriam',
  recriar: 'recriar',
  recrias: 'recrias',
  recriei: 'recriei',
  recriem: 'recriem',
  recries: 'recries',
  recriou: 'recriou',
  recruta: 'recruta',
  recrute: 'recrute',
  recruto: 'recruto',
  recruza: 'recruza',
  recruze: 'recruze',
  recruzo: 'recruzo',
  rectite: 'rectite',
  rectriz: 'rectriz',
  recuada: 'recuada',
  recuado: 'recuado',
  recuais: 'recuais',
  recuamo: 'recuamo',
  recuara: 'recuara',
  recuava: 'recuava',
  recubra: 'recubra',
  recubro: 'recubro',
  recucas: 'recucas',
  recucos: 'recucos',
  recudai: 'recudai',
  recudam: 'recudam',
  recudar: 'recudar',
  recudas: 'recudas',
  recudei: 'recudei',
  recudem: 'recudem',
  recudes: 'recudes',
  recudia: 'recudia',
  recudir: 'recudir',
  recudis: 'recudis',
  recudiu: 'recudiu',
  recudou: 'recudou',
  recueis: 'recueis',
  recuemo: 'recuemo',
  recuida: 'recuida',
  recuide: 'recuide',
  recuido: 'recuido',
  recuita: 'recuitá',
  recuite: 'recuite',
  recuito: 'recuito',
  reculao: 'reculão',
  reculas: 'réculas',
  recumar: 'reçumar',
  recumba: 'recumba',
  recumbe: 'recumbe',
  recumbi: 'recumbi',
  recumbo: 'recumbo',
  recunha: 'recunha',
  recunhe: 'recunhe',
  recunho: 'recunho',
  recuoes: 'recuões',
  recurai: 'recurai',
  recuram: 'recuram',
  recurar: 'recurar',
  recuras: 'recuras',
  recurei: 'recurei',
  recurem: 'recurem',
  recures: 'recures',
  recurou: 'recurou',
  recursa: 'recursa',
  recurse: 'recurse',
  recurso: 'recurso',
  recurva: 'recurva',
  recurve: 'recurve',
  recurvo: 'recurvo',
  recusai: 'recusai',
  recusam: 'recusam',
  recusar: 'recusar',
  recusas: 'recusas',
  recusei: 'recusei',
  recusem: 'recusem',
  recuses: 'recuses',
  recusou: 'recusou',
  redabes: 'redabes',
  redacao: 'redação',
  redadao: 'redadão',
  redadas: 'redadas',
  redador: 'redador',
  redados: 'redados',
  redamai: 'redamai',
  redamam: 'redamam',
  redamar: 'redamar',
  redamas: 'redamas',
  redamei: 'redamei',
  redamem: 'redamem',
  redames: 'redames',
  redamos: 'redamos',
  redamou: 'redamou',
  redando: 'redando',
  redanho: 'redanho',
  redante: 'redante',
  redarao: 'redarão',
  redaras: 'redarás',
  redarei: 'redarei',
  redarem: 'redarem',
  redares: 'redares',
  redaria: 'redaria',
  redario: 'redário',
  redator: 'redator',
  redavam: 'redavam',
  redavas: 'redavas',
  redeais: 'redeais',
  redeira: 'redeira',
  redeiro: 'redeiro',
  redemos: 'redemos',
  redende: 'redendê',
  redenga: 'redenga',
  redengo: 'redengo',
  redenho: 'redenho',
  redenta: 'redenta',
  redente: 'redente',
  redento: 'redento',
  rederam: 'rederam',
  rederas: 'rederas',
  rederem: 'rederem',
  rederes: 'rederes',
  redesca: 'redesça',
  redesce: 'redesce',
  redesci: 'redesci',
  redesco: 'redesço',
  redesse: 'redesse',
  redeste: 'redeste',
  redibam: 'redibam',
  redibas: 'redibas',
  redibem: 'redibem',
  redibes: 'redibes',
  redibia: 'redibia',
  redibir: 'redibir',
  redibis: 'redibis',
  redibiu: 'redibiu',
  redicao: 'redição',
  redigam: 'redigam',
  redigas: 'redigas',
  redigem: 'redigem',
  rediges: 'rediges',
  redigia: 'redigia',
  redigir: 'redigir',
  redigis: 'redigis',
  redigiu: 'redigiu',
  redijam: 'redijam',
  redijas: 'redijas',
  redimam: 'redimam',
  redimas: 'redimas',
  redimem: 'redimem',
  redimes: 'redimes',
  redimia: 'redimia',
  redimir: 'redimir',
  redimis: 'redimis',
  redimiu: 'redimiu',
  redinha: 'redinha',
  redirao: 'redirão',
  rediras: 'redirás',
  redirei: 'redirei',
  rediria: 'rediria',
  redispo: 'redispô',
  redisse: 'redisse',
  reditas: 'reditas',
  reditos: 'réditos',
  redizei: 'redizei',
  redizem: 'redizem',
  redizer: 'redizer',
  redizes: 'redizes',
  redizia: 'redizia',
  redobai: 'redobai',
  redobam: 'redobam',
  redobar: 'redobar',
  redobas: 'redobas',
  redobei: 'redobei',
  redobem: 'redobem',
  redobes: 'redobes',
  redobou: 'redobou',
  redobra: 'redobra',
  redobre: 'redobre',
  redobro: 'redobro',
  redoica: 'redoiça',
  redoice: 'redoice',
  redoico: 'redoiço',
  redoira: 'redoira',
  redoire: 'redoire',
  redoiro: 'redoiro',
  redolha: 'redolha',
  redolho: 'redolho',
  redomao: 'redomão',
  redomas: 'redomas',
  redonas: 'redonas',
  redonda: 'redonda',
  redondo: 'redondo',
  redones: 'rédones',
  redonos: 'rédonos',
  redopia: 'redopia',
  redopie: 'redopie',
  redopio: 'redopio',
  redoras: 'redoras',
  redores: 'redores',
  redoria: 'redoria',
  redorta: 'redorta',
  redouca: 'redouça',
  redouce: 'redouce',
  redouco: 'redouço',
  redoura: 'redoura',
  redoure: 'redoure',
  redouro: 'redouro',
  redovas: 'redovas',
  redrada: 'redrada',
  redrado: 'redrado',
  redrais: 'redrais',
  redramo: 'redramo',
  redrara: 'redrara',
  redrava: 'redrava',
  redreis: 'redreis',
  redremo: 'redremo',
  redubla: 'redubla',
  reduble: 'reduble',
  redublo: 'redublo',
  reducao: 'redução',
  redunda: 'redunda',
  redunde: 'redunde',
  redundo: 'redundo',
  reduras: 'reduras',
  redutor: 'redutor',
  redutos: 'redutos',
  reduvia: 'redúvia',
  reduvio: 'redúvio',
  reduzam: 'reduzam',
  reduzas: 'reduzas',
  reduzem: 'reduzem',
  reduzes: 'reduzes',
  reduzia: 'reduzia',
  reduzir: 'reduzir',
  reduzis: 'reduzis',
  reduziu: 'reduziu',
  reecoai: 'reecoai',
  reecoam: 'reecoam',
  reecoar: 'reecoar',
  reecoas: 'reecoas',
  reecoei: 'reecoei',
  reecoem: 'reecoem',
  reecoes: 'reecoes',
  reecoou: 'reecoou',
  reedias: 'reédias',
  reedita: 'reedita',
  reedite: 'reedite',
  reedito: 'reedito',
  reeduca: 'reeduca',
  reeduco: 'reeduco',
  reelege: 'reelege',
  reelegi: 'reelegi',
  reeleja: 'reeleja',
  reelejo: 'reelejo',
  reemita: 'reemita',
  reemite: 'reemite',
  reemiti: 'reemiti',
  reemito: 'reemito',
  reencha: 'reencha',
  reenche: 'reenche',
  reenchi: 'reenchi',
  reencho: 'reencho',
  reenfia: 'reenfiá',
  reenfie: 'reenfie',
  reenfio: 'reenfio',
  reenlea: 'reenleá',
  reentra: 'reentra',
  reentre: 'reentre',
  reentro: 'reentro',
  reenvia: 'reenvia',
  reenvie: 'reenvie',
  reenvio: 'reenvio',
  reereta: 'reereta',
  reereto: 'reereto',
  reergam: 'reergam',
  reergas: 'reergas',
  reergue: 'reergue',
  reergui: 'reergui',
  reerige: 'reerige',
  reerigi: 'reerigi',
  reerija: 'reerija',
  reerijo: 'reerijo',
  reexame: 'reexame',
  reexiba: 'reexiba',
  reexibe: 'reexibe',
  reexibi: 'reexibi',
  reexibo: 'reexibo',
  reexita: 'reexitá',
  reexite: 'reexite',
  reexito: 'reexito',
  reexpia: 'reexpiá',
  reexpie: 'reexpie',
  reexpio: 'reexpio',
  reexpoe: 'reexpõe',
  reexpor: 'reexpor',
  reexpos: 'reexpôs',
  reexpus: 'reexpus',
  refacam: 'refaçam',
  refacao: 'refação',
  refacas: 'refaças',
  refains: 'refains',
  refalai: 'refalai',
  refalam: 'refalam',
  refalar: 'refalar',
  refalas: 'refalas',
  refalei: 'refalei',
  refalem: 'refalem',
  refales: 'refales',
  refalou: 'refalou',
  refalsa: 'refalsá',
  refalse: 'refalse',
  refalso: 'refalso',
  refarao: 'refarão',
  refaras: 'refarás',
  refarei: 'refarei',
  refaria: 'refaria',
  refarta: 'refartá',
  refarte: 'refarte',
  refarto: 'refarto',
  refazei: 'refazei',
  refazem: 'refazem',
  refazer: 'refazer',
  refazes: 'refazes',
  refazia: 'refazia',
  refecam: 'refeçam',
  refecas: 'refeças',
  refecei: 'refecei',
  refecem: 'refecem',
  refecer: 'refecer',
  refeces: 'refeces',
  refeceu: 'refeceu',
  refecha: 'refechá',
  refeche: 'refeche',
  refecho: 'refecho',
  refecia: 'refecia',
  refegai: 'refegai',
  refegam: 'refegam',
  refegao: 'refegão',
  refegar: 'refegar',
  refegas: 'refegas',
  refegou: 'refegou',
  refegue: 'refegue',
  refeita: 'refeita',
  refeito: 'refeito',
  refenas: 'refenas',
  refenda: 'refenda',
  refende: 'refende',
  refendi: 'refendi',
  refendo: 'refendo',
  refenta: 'refentá',
  refente: 'refente',
  refento: 'refento',
  referee: 'referee',
  referem: 'referem',
  referes: 'referes',
  referia: 'referia',
  referir: 'referir',
  referis: 'referis',
  referiu: 'referiu',
  referra: 'referrá',
  referre: 'referre',
  referro: 'referro',
  referta: 'referta',
  referte: 'referte',
  referto: 'referto',
  referva: 'referva',
  referve: 'referve',
  refervi: 'refervi',
  refervo: 'refervo',
  refesto: 'refesto',
  refezai: 'refezai',
  refezam: 'refezam',
  refezar: 'refezar',
  refezas: 'refezas',
  refezei: 'refezei',
  refezem: 'refezem',
  refezes: 'refezes',
  refezou: 'refezou',
  refiada: 'refiada',
  refiado: 'refiado',
  refiais: 'refiais',
  refiamo: 'refiamo',
  refiara: 'refiará',
  refiava: 'refiava',
  refieis: 'refieis',
  refiemo: 'refiemo',
  refilai: 'refilai',
  refilam: 'refilam',
  refilao: 'refilão',
  refilar: 'refilar',
  refilas: 'refilas',
  refilei: 'refilei',
  refilem: 'refilem',
  refiles: 'refiles',
  refilha: 'refilha',
  refilhe: 'refilhe',
  refilho: 'refilho',
  refilia: 'refilia',
  refilie: 'refilie',
  refilio: 'refilio',
  refilma: 'refilma',
  refilme: 'refilme',
  refilmo: 'refilmo',
  refilou: 'refilou',
  refinai: 'refinai',
  refinam: 'refinam',
  refinar: 'refinar',
  refinas: 'refinas',
  refinca: 'refinca',
  refinco: 'refinco',
  refinei: 'refinei',
  refinem: 'refinem',
  refines: 'refines',
  refinou: 'refinou',
  refinta: 'refinta',
  refinte: 'refinte',
  refinto: 'refinto',
  refioes: 'refiões',
  refiram: 'refiram',
  refiras: 'refiras',
  refirma: 'refirma',
  refirme: 'refirme',
  refirmo: 'refirmo',
  refitai: 'refitai',
  refitam: 'refitam',
  refitar: 'refitar',
  refitas: 'refitas',
  refitei: 'refitei',
  refitem: 'refitem',
  refites: 'refites',
  refitou: 'refitou',
  refizer: 'refizer',
  reflada: 'reflada',
  reflado: 'reflado',
  reflais: 'reflais',
  reflamo: 'reflamo',
  reflara: 'reflará',
  reflava: 'reflava',
  refleis: 'refleis',
  reflemo: 'reflemo',
  reflete: 'reflete',
  refleti: 'refleti',
  reflexa: 'reflexa',
  reflexo: 'reflexo',
  reflita: 'reflita',
  reflito: 'reflito',
  reflora: 'reflora',
  reflore: 'reflore',
  reflori: 'reflori',
  refloro: 'refloro',
  refluam: 'refluam',
  refluas: 'refluas',
  refluem: 'refluem',
  refluia: 'refluía',
  refluir: 'refluir',
  refluis: 'refluis',
  refluiu: 'refluiu',
  refluos: 'réfluos',
  refluxa: 'refluxa',
  refluxe: 'refluxe',
  refluxo: 'refluxo',
  refocai: 'refocai',
  refocam: 'refocam',
  refocar: 'refocar',
  refocas: 'refocas',
  refocou: 'refocou',
  refogai: 'refogai',
  refogam: 'refogam',
  refogar: 'refogar',
  refogas: 'refogas',
  refogem: 'refogem',
  refoges: 'refoges',
  refogou: 'refogou',
  refogue: 'refogue',
  refojos: 'refojos',
  refolga: 'refolga',
  refolgo: 'refolgo',
  refolha: 'refolha',
  refolhe: 'refolhe',
  refolho: 'refolho',
  refoque: 'refoque',
  reforca: 'reforça',
  reforce: 'reforce',
  reforco: 'reforço',
  reforja: 'reforja',
  reforje: 'reforje',
  reforjo: 'reforjo',
  reforma: 'reforma',
  reforme: 'reforme',
  reformo: 'reformo',
  refrata: 'refrata',
  refrate: 'refrate',
  refrato: 'refrato',
  refreai: 'refreai',
  refrear: 'refrear',
  refreei: 'refreei',
  refrega: 'refrega',
  refrego: 'refrego',
  refreia: 'refreia',
  refreie: 'refreie',
  refreio: 'refreio',
  refrens: 'refréns',
  refreou: 'refreou',
  refreta: 'refreta',
  refrete: 'refrete',
  refreto: 'refreto',
  refrica: 'refricá',
  refrico: 'refrico',
  refrige: 'refrige',
  refrigi: 'refrigi',
  refrija: 'refrija',
  refrijo: 'refrijo',
  refrisa: 'refrisa',
  refrise: 'refrise',
  refriso: 'refriso',
  refrita: 'refrita',
  refrite: 'refrite',
  refrito: 'refrito',
  refroes: 'refrões',
  refugai: 'refugai',
  refugam: 'refugam',
  refugar: 'refugar',
  refugas: 'refugas',
  refugia: 'refugia',
  refugie: 'refugie',
  refugio: 'refúgio',
  refugir: 'refugir',
  refugis: 'refugis',
  refugiu: 'refugiu',
  refugos: 'refugos',
  refugou: 'refugou',
  refugue: 'refugue',
  refujam: 'refujam',
  refujas: 'refujas',
  refulge: 'refulge',
  refulgi: 'refulgi',
  refulja: 'refulja',
  refuljo: 'refuljo',
  refumai: 'refumai',
  refumam: 'refumam',
  refumar: 'refumar',
  refumas: 'refumas',
  refumei: 'refumei',
  refumem: 'refumem',
  refumes: 'refumes',
  refumou: 'refumou',
  refunda: 'refunda',
  refunde: 'refunde',
  refundi: 'refundi',
  refundo: 'refundo',
  refunfa: 'refunfá',
  refunfe: 'refunfe',
  refunfo: 'refunfo',
  refunga: 'refungá',
  refungo: 'refungo',
  refunha: 'refunhá',
  refunhe: 'refunhe',
  refunho: 'refunho',
  refusai: 'refusai',
  refusam: 'refusam',
  refusao: 'refusão',
  refusar: 'refusar',
  refusas: 'refusas',
  refusei: 'refusei',
  refusem: 'refusem',
  refuses: 'refuses',
  refusou: 'refusou',
  refusta: 'refustá',
  refuste: 'refuste',
  refusto: 'refusto',
  refutai: 'refutai',
  refutam: 'refutam',
  refutar: 'refutar',
  refutas: 'refutas',
  refutei: 'refutei',
  refutem: 'refutem',
  refutes: 'refutes',
  refutou: 'refutou',
  regacai: 'regaçai',
  regacam: 'regaçam',
  regacar: 'regaçar',
  regacas: 'regaças',
  regacei: 'regacei',
  regacem: 'regacem',
  regaces: 'regaces',
  regacos: 'regaços',
  regacou: 'regaçou',
  regadao: 'regadão',
  regadas: 'regadas',
  regadia: 'regadia',
  regadio: 'regadio',
  regador: 'regador',
  regados: 'regados',
  regagem: 'regagem',
  regalai: 'regalai',
  regalam: 'regalam',
  regalao: 'regalão',
  regalar: 'regalar',
  regalas: 'regalas',
  regalei: 'regalei',
  regalem: 'regalem',
  regales: 'regales',
  regalez: 'regalez',
  regalia: 'regalia',
  regaliz: 'regaliz',
  regalos: 'regalos',
  regalou: 'regalou',
  regamos: 'regamos',
  reganas: 'reganas',
  regando: 'regando',
  reganha: 'reganha',
  reganhe: 'reganhe',
  reganho: 'reganho',
  reganos: 'reganos',
  regante: 'regante',
  regaram: 'regaram',
  regarao: 'regarão',
  regaras: 'regaras',
  regardo: 'regardo',
  regarei: 'regarei',
  regarem: 'regarem',
  regares: 'regares',
  regaria: 'regaria',
  regarmo: 'regarmo',
  regasea: 'regaseá',
  regasse: 'regasse',
  regasta: 'regasta',
  regaste: 'regaste',
  regasto: 'regasto',
  regatai: 'regatai',
  regatam: 'regatam',
  regatao: 'regatão',
  regatar: 'regatar',
  regatas: 'regatas',
  regatea: 'regateá',
  regatei: 'regatei',
  regatem: 'regatem',
  regates: 'regates',
  regatia: 'regatia',
  regatoa: 'regatoa',
  regatos: 'regatos',
  regatou: 'regatou',
  regavam: 'regavam',
  regavas: 'regavas',
  regedor: 'regedor',
  regeira: 'regeira',
  regelai: 'regelai',
  regelam: 'regelam',
  regelar: 'regelar',
  regelas: 'regelas',
  regelei: 'regelei',
  regelem: 'regelem',
  regeles: 'regeles',
  regelou: 'regelou',
  regemos: 'regemos',
  regendo: 'regendo',
  regense: 'regense',
  regenta: 'regenta',
  regente: 'regente',
  regento: 'regento',
  regerai: 'regerai',
  regeram: 'regeram',
  regerao: 'regerão',
  regerar: 'regerar',
  regeras: 'regeras',
  regerei: 'regerei',
  regerem: 'regerem',
  regeres: 'regeres',
  regeria: 'regeria',
  regermo: 'regermo',
  regerou: 'regerou',
  regesse: 'regesse',
  regesta: 'regesta',
  regeste: 'regeste',
  regesto: 'regesto',
  reggaes: 'reggaes',
  regiana: 'regiana',
  regiano: 'regiano',
  regiate: 'regiate',
  regibos: 'regibós',
  regidao: 'regidão',
  regidas: 'regidas',
  regidos: 'regidos',
  regieis: 'regíeis',
  regilas: 'regilas',
  regimen: 'regímen',
  regimes: 'regimes',
  reginai: 'reginai',
  reginam: 'reginam',
  reginar: 'reginar',
  reginas: 'reginas',
  reginea: 'regínea',
  reginei: 'reginei',
  reginem: 'reginem',
  regineo: 'regíneo',
  regines: 'regines',
  reginos: 'reginos',
  reginou: 'reginou',
  regioes: 'regiões',
  regirai: 'regirai',
  regiram: 'regiram',
  regirar: 'regirar',
  regiras: 'regiras',
  regirei: 'regirei',
  regirem: 'regirem',
  regires: 'regires',
  regirou: 'regirou',
  regista: 'regista',
  registe: 'registe',
  registo: 'registo',
  regoada: 'regoada',
  regoado: 'regoado',
  regoais: 'regoais',
  regoamo: 'regoamo',
  regoara: 'regoara',
  regoava: 'regoava',
  regoeis: 'regoeis',
  regoemo: 'regoemo',
  regolas: 'regolas',
  regolfa: 'regolfa',
  regolfe: 'regolfe',
  regolfo: 'regolfo',
  regoliz: 'regoliz',
  regomai: 'regomai',
  regomam: 'regomam',
  regomar: 'regomar',
  regomas: 'regomas',
  regomei: 'regomei',
  regomem: 'regomem',
  regomes: 'regomes',
  regomou: 'regomou',
  regotas: 'regotas',
  regouga: 'regouga',
  regougo: 'regougo',
  regrada: 'regrada',
  regrado: 'regrado',
  regrais: 'regrais',
  regramo: 'regramo',
  regraos: 'regrãos',
  regrara: 'regrara',
  regrava: 'regrava',
  regrave: 'regrave',
  regravo: 'regravo',
  regraxa: 'regraxa',
  regraxe: 'regraxe',
  regraxo: 'regraxo',
  regreda: 'regreda',
  regrede: 'regrede',
  regredi: 'regredi',
  regredo: 'regredo',
  regreis: 'regreis',
  regremo: 'regremo',
  regreta: 'regreta',
  regrida: 'regrida',
  regride: 'regride',
  regrido: 'regrido',
  reguada: 'reguada',
  regucar: 'reguçar',
  regueis: 'regueis',
  reguemo: 'reguemo',
  reguico: 'reguiço',
  reguila: 'reguila',
  regulai: 'regulai',
  regulam: 'regulam',
  regular: 'regular',
  regulas: 'regulas',
  regulei: 'regulei',
  regulem: 'regulem',
  regules: 'regules',
  regulon: 'régulon',
  regulos: 'régulos',
  regulou: 'regulou',
  reicida: 'reicida',
  reicuas: 'reicuas',
  reideas: 'reídeas',
  reideos: 'reídeos',
  reifica: 'reifica',
  reifico: 'reifico',
  reigada: 'reigada',
  reigota: 'reigota',
  reigras: 'reigrás',
  reimoes: 'reimões',
  reimosa: 'reimosa',
  reimoso: 'reimoso',
  reimpoe: 'reimpõe',
  reimpor: 'reimpor',
  reimpos: 'reimpôs',
  reimpus: 'reimpus',
  reinaco: 'reinaço',
  reinada: 'reinada',
  reinado: 'reinado',
  reinais: 'reinais',
  reinamo: 'reinamo',
  reinara: 'reinara',
  reinata: 'reinata',
  reinava: 'reinava',
  reineis: 'reineis',
  reinemo: 'reinemo',
  reineta: 'reineta',
  reinita: 'reinita',
  reinite: 'reinite',
  reinois: 'reinóis',
  reinola: 'reinola',
  reinuma: 'reinumá',
  reinume: 'reinume',
  reinumo: 'reinumo',
  reisada: 'reisada',
  reisado: 'reisado',
  reismos: 'reísmos',
  reistas: 'reístas',
  reitera: 'reitera',
  reitere: 'reitere',
  reitero: 'reitero',
  reitora: 'reitora',
  reitres: 'reitres',
  reiunai: 'reiunai',
  reiunam: 'reiunam',
  reiunar: 'reiunar',
  reiunas: 'reiunas',
  reiunei: 'reiunei',
  reiunem: 'reiunem',
  reiunes: 'reiunes',
  reiunos: 'reiunos',
  reiunou: 'reiunou',
  reixada: 'reixada',
  reixado: 'reixado',
  reixais: 'reixais',
  reixamo: 'reixamo',
  reixara: 'reixará',
  reixava: 'reixava',
  reixeis: 'reixeis',
  reixelo: 'reixelo',
  reixemo: 'reixemo',
  reizete: 'reizete',
  rejamos: 'rejamos',
  rejatea: 'rejateá',
  rejecta: 'rejecta',
  rejecto: 'rejecto',
  rejeita: 'rejeita',
  rejeite: 'rejeite',
  rejeito: 'rejeito',
  rejetos: 'rejetos',
  rejunca: 'rejuncá',
  rejunco: 'rejunco',
  rejunta: 'rejunta',
  rejunte: 'rejunte',
  rejunto: 'rejunto',
  rejurai: 'rejurai',
  rejuram: 'rejuram',
  rejurar: 'rejurar',
  rejuras: 'rejuras',
  rejurei: 'rejurei',
  rejurem: 'rejurem',
  rejures: 'rejures',
  rejurou: 'rejurou',
  relacao: 'relação',
  relacra: 'relacra',
  relacre: 'relacre',
  relacro: 'relacro',
  reladao: 'reladão',
  reladas: 'reladas',
  relador: 'relador',
  relados: 'relados',
  relamba: 'relamba',
  relambe: 'relambe',
  relambi: 'relambi',
  relambo: 'relambo',
  relamos: 'relamos',
  relampa: 'relampa',
  relampe: 'relampe',
  relampo: 'relampo',
  relanca: 'relança',
  relance: 'relance',
  relanco: 'relanço',
  relando: 'relando',
  relapsa: 'relapsa',
  relapso: 'relapso',
  relaram: 'relaram',
  relarao: 'relarão',
  relaras: 'relaras',
  relarei: 'relarei',
  relarem: 'relarem',
  relares: 'relares',
  relarga: 'relarga',
  relargo: 'relargo',
  relaria: 'relaria',
  relarmo: 'relarmo',
  relassa: 'relassa',
  relasse: 'relasse',
  relasso: 'relasso',
  relaste: 'relaste',
  relatai: 'relatai',
  relatam: 'relatam',
  relatar: 'relatar',
  relatas: 'relatas',
  relatei: 'relatei',
  relatem: 'relatem',
  relates: 'relates',
  relator: 'relator',
  relatos: 'relatos',
  relatou: 'relatou',
  relavai: 'relavai',
  relavam: 'relavam',
  relavar: 'relavar',
  relavas: 'relavas',
  relavei: 'relavei',
  relavem: 'relavem',
  relaves: 'relaves',
  relavou: 'relavou',
  relaxai: 'relaxai',
  relaxam: 'relaxam',
  relaxar: 'relaxar',
  relaxas: 'relaxas',
  relaxei: 'relaxei',
  relaxem: 'relaxem',
  relaxes: 'relaxes',
  relaxou: 'relaxou',
  release: 'release',
  reledes: 'reledes',
  reledor: 'reledor',
  relegai: 'relegai',
  relegam: 'relegam',
  relegar: 'relegar',
  relegas: 'relegas',
  relegou: 'relegou',
  relegue: 'relegue',
  releiam: 'releiam',
  releias: 'releias',
  releira: 'releira',
  releiro: 'releiro',
  releixa: 'releixa',
  releixe: 'releixe',
  releixo: 'releixo',
  relejos: 'relejos',
  relemos: 'relemos',
  relendo: 'relendo',
  relenga: 'relenga',
  relengo: 'relengo',
  relenta: 'relenta',
  relente: 'relente',
  relento: 'relento',
  relepai: 'relepai',
  relepam: 'relepam',
  relepar: 'relepar',
  relepas: 'relepas',
  relepei: 'relepei',
  relepem: 'relepem',
  relepes: 'relepes',
  relepou: 'relepou',
  releram: 'releram',
  relerao: 'relerão',
  releras: 'releras',
  relerei: 'relerei',
  relerem: 'relerem',
  releres: 'releres',
  releria: 'releria',
  relermo: 'relermo',
  relesas: 'relesas',
  relesse: 'relesse',
  releste: 'releste',
  relevai: 'relevai',
  relevam: 'relevam',
  relevar: 'relevar',
  relevas: 'relevas',
  relevei: 'relevei',
  relevem: 'relevem',
  releves: 'releves',
  relevos: 'relevos',
  relevou: 'relevou',
  relexos: 'relexos',
  relhaco: 'relhaço',
  relhada: 'relhada',
  relhado: 'relhado',
  relhais: 'relhais',
  relhamo: 'relhamo',
  relhara: 'relhará',
  relhava: 'relhava',
  relheis: 'relheis',
  relhemo: 'relhemo',
  relhota: 'relhota',
  relhote: 'relhote',
  relices: 'relices',
  relicta: 'relicta',
  relicto: 'relicto',
  relidao: 'relidão',
  relidas: 'relidas',
  relidos: 'relidos',
  relieis: 'relíeis',
  religai: 'religai',
  religam: 'religam',
  religar: 'religar',
  religas: 'religas',
  religou: 'religou',
  religue: 'religue',
  relimai: 'relimai',
  relimam: 'relimam',
  relimar: 'relimar',
  relimas: 'relimas',
  relimei: 'relimei',
  relimem: 'relimem',
  relimes: 'relimes',
  relimou: 'relimou',
  relinga: 'relinga',
  relingo: 'relingo',
  reliqua: 'relíqua',
  reliquo: 'réliquo',
  relocai: 'relocai',
  relocam: 'relocam',
  relocar: 'relocar',
  relocas: 'relocas',
  relocou: 'relocou',
  relogio: 'relógio',
  relojai: 'relojai',
  relojam: 'relojam',
  relojao: 'relojão',
  relojar: 'relojar',
  relojas: 'relojas',
  relojei: 'relojei',
  relojem: 'relojem',
  relojes: 'relojes',
  relojos: 'relojos',
  relojou: 'relojou',
  reloque: 'reloque',
  relotea: 'reloteá',
  relouca: 'relouca',
  relouco: 'relouco',
  relumai: 'relumai',
  relumam: 'relumam',
  relumar: 'relumar',
  relumas: 'relumas',
  relumea: 'relumeá',
  relumei: 'relumei',
  relumem: 'relumem',
  relumes: 'relumes',
  relumou: 'relumou',
  relutai: 'relutai',
  relutam: 'relutam',
  relutar: 'relutar',
  relutas: 'relutas',
  relutei: 'relutei',
  relutem: 'relutem',
  relutes: 'relutes',
  relutou: 'relutou',
  reluzam: 'reluzam',
  reluzas: 'reluzas',
  reluzem: 'reluzem',
  reluzes: 'reluzes',
  reluzia: 'reluzia',
  reluzir: 'reluzir',
  reluzis: 'reluzis',
  reluziu: 'reluziu',
  relvada: 'relvada',
  relvado: 'relvado',
  relvais: 'relvais',
  relvamo: 'relvamo',
  relvara: 'relvará',
  relvava: 'relvava',
  relvedo: 'relvedo',
  relveis: 'relveis',
  relveja: 'relveja',
  relveje: 'relveje',
  relvejo: 'relvejo',
  relvemo: 'relvemo',
  relvoes: 'relvões',
  relvosa: 'relvosa',
  relvoso: 'relvoso',
  remadao: 'remadão',
  remadas: 'remadas',
  remador: 'remador',
  remados: 'remados',
  remalha: 'remalha',
  remalhe: 'remalhe',
  remalho: 'remalho',
  remamos: 'remamos',
  remanda: 'remanda',
  remande: 'remande',
  remando: 'remando',
  remanga: 'remanga',
  remango: 'remango',
  remanir: 'remanir',
  remansa: 'remansa',
  remanse: 'remanse',
  remanso: 'remanso',
  remante: 'remante',
  remapea: 'remapeá',
  remaram: 'remaram',
  remarao: 'remarão',
  remaras: 'remaras',
  remarca: 'remarca',
  remarco: 'remarco',
  remarei: 'remarei',
  remarem: 'remarem',
  remares: 'remares',
  remaria: 'remaria',
  remarmo: 'remarmo',
  remasca: 'remasca',
  remasco: 'remasco',
  remasse: 'remasse',
  remaste: 'remaste',
  rematai: 'rematai',
  rematam: 'rematam',
  rematar: 'rematar',
  rematas: 'rematas',
  rematei: 'rematei',
  rematem: 'rematem',
  remates: 'remates',
  rematou: 'rematou',
  remavam: 'remavam',
  remavas: 'remavas',
  remeada: 'remeada',
  remeado: 'remeado',
  remeais: 'remeais',
  remeara: 'remeará',
  remeava: 'remeava',
  remecam: 'remeçam',
  remecao: 'remeção',
  remecas: 'remeças',
  remedai: 'remedai',
  remedam: 'remedam',
  remedar: 'remedar',
  remedas: 'remedas',
  remedei: 'remedei',
  remedem: 'remedem',
  remedes: 'remedes',
  remedia: 'remedia',
  remedio: 'remédio',
  remedir: 'remedir',
  remedis: 'remedis',
  remediu: 'remediu',
  remedou: 'remedou',
  remeeis: 'remeeis',
  remeiam: 'remeiam',
  remeias: 'remeias',
  remeiem: 'remeiem',
  remeies: 'remeies',
  remeira: 'remeira',
  remeiro: 'remeiro',
  remelai: 'remelai',
  remelam: 'remelam',
  remelao: 'remelão',
  remelar: 'remelar',
  remelas: 'remelas',
  remelei: 'remelei',
  remelem: 'remelem',
  remeles: 'remeles',
  remelga: 'remelga',
  remelgo: 'remelgo',
  remelou: 'remelou',
  rememos: 'rememos',
  remenda: 'remenda',
  remende: 'remende',
  remendo: 'remendo',
  remenea: 'remeneá',
  remense: 'remense',
  rementa: 'rementa',
  remente: 'remente',
  rementi: 'rementi',
  remento: 'remento',
  remessa: 'remessa',
  remesse: 'remesse',
  remesso: 'remesso',
  remetam: 'remetam',
  remetas: 'remetas',
  remetei: 'remetei',
  remetem: 'remetem',
  remeter: 'remeter',
  remetes: 'remetes',
  remeteu: 'remeteu',
  remetia: 'remetia',
  remexam: 'remexam',
  remexas: 'remexas',
  remexei: 'remexei',
  remexem: 'remexem',
  remexer: 'remexer',
  remexes: 'remexes',
  remexeu: 'remexeu',
  remexia: 'remexia',
  remicao: 'remição',
  remidao: 'remidão',
  remidas: 'remidas',
  remidor: 'remidor',
  remidos: 'remidos',
  remieis: 'remíeis',
  remiges: 'rêmiges',
  remigia: 'remígia',
  remigie: 'remigie',
  remigio: 'remígio',
  remigra: 'remigra',
  remigre: 'remigre',
  remigro: 'remigro',
  remimai: 'remimai',
  remimam: 'remimam',
  remimar: 'remimar',
  remimas: 'remimas',
  remimei: 'remimei',
  remimem: 'remimem',
  remimes: 'remimes',
  remimos: 'remimos',
  remimou: 'remimou',
  reminai: 'reminai',
  reminam: 'reminam',
  reminar: 'reminar',
  reminas: 'reminas',
  remindo: 'remindo',
  reminei: 'reminei',
  reminem: 'reminem',
  remines: 'remines',
  reminha: 'reminha',
  reminou: 'reminou',
  reminta: 'reminta',
  reminto: 'reminto',
  remirai: 'remirai',
  remiram: 'remiram',
  remirao: 'remirão',
  remirar: 'remirar',
  remiras: 'remiras',
  remirea: 'remírea',
  remirei: 'remirei',
  remirem: 'remirem',
  remires: 'remires',
  remiria: 'remiria',
  remirmo: 'remirmo',
  remirou: 'remirou',
  remisga: 'remisga',
  remissa: 'remissa',
  remisse: 'remisse',
  remisso: 'remisso',
  remista: 'remista',
  remiste: 'remiste',
  remitam: 'remitam',
  remitas: 'remitas',
  remitem: 'remitem',
  remites: 'remites',
  remitia: 'remitia',
  remitir: 'remitir',
  remitis: 'remitis',
  remitiu: 'remitiu',
  remivel: 'remível',
  remixai: 'remixai',
  remixam: 'remixam',
  remixar: 'remixar',
  remixas: 'remixas',
  remixei: 'remixei',
  remixem: 'remixem',
  remixes: 'remixes',
  remixou: 'remixou',
  remoais: 'remoais',
  remoamo: 'remoamo',
  remocai: 'remoçai',
  remocam: 'remoçam',
  remocao: 'remoção',
  remocar: 'remoçar',
  remocas: 'remoças',
  remocei: 'remocei',
  remocem: 'remocem',
  remoces: 'remoces',
  remocou: 'remoçou',
  remoeis: 'remoeis',
  remoela: 'remoela',
  remoele: 'remoele',
  remoelo: 'remoelo',
  remoemo: 'remoemo',
  remoera: 'remoera',
  remoiam: 'remoíam',
  remoias: 'remoías',
  remoida: 'remoída',
  remoido: 'remoído',
  remolar: 'remolar',
  remolca: 'remolcá',
  remolco: 'remolco',
  remolga: 'remolgá',
  remolgo: 'remolgo',
  remolha: 'remolha',
  remolhe: 'remolhe',
  remolho: 'remolho',
  remonda: 'remonda',
  remonde: 'remonde',
  remondo: 'remondo',
  remonta: 'remonta',
  remonte: 'remonte',
  remonto: 'remonto',
  remoque: 'remoque',
  remorai: 'remorai',
  remoram: 'remoram',
  remorar: 'remorar',
  remoras: 'rêmoras',
  remorca: 'remorcá',
  remorco: 'remorco',
  remorda: 'remorda',
  remorde: 'remorde',
  remordi: 'remordi',
  remordo: 'remordo',
  remorei: 'remorei',
  remorem: 'remorem',
  remores: 'remores',
  remorou: 'remorou',
  remorra: 'remorra',
  remorre: 'remorre',
  remorri: 'remorri',
  remorro: 'remorro',
  remorsa: 'remorsa',
  remorso: 'remorso',
  remorta: 'remorta',
  remorto: 'remorto',
  remosta: 'remosta',
  remoste: 'remoste',
  remosto: 'remosto',
  remotas: 'remotas',
  remotos: 'remotos',
  remouca: 'remoucá',
  remouco: 'remouco',
  removam: 'removam',
  removas: 'removas',
  removei: 'removei',
  removem: 'removem',
  remover: 'remover',
  removes: 'removes',
  removeu: 'removeu',
  removia: 'removia',
  remudai: 'remudai',
  remudam: 'remudam',
  remudar: 'remudar',
  remudas: 'remudas',
  remudei: 'remudei',
  remudem: 'remudem',
  remudes: 'remudes',
  remudou: 'remudou',
  remugai: 'remugai',
  remugam: 'remugam',
  remugar: 'remugar',
  remugas: 'remugas',
  remugem: 'remugem',
  remuges: 'remuges',
  remugia: 'remugia',
  remugir: 'remugir',
  remugis: 'remugis',
  remugiu: 'remugiu',
  remugou: 'remugou',
  remugue: 'remugue',
  remuito: 'remuito',
  remujam: 'remujam',
  remujas: 'remujas',
  remunca: 'remunca',
  remunco: 'remunco',
  remusga: 'remusga',
  remusgo: 'remusgo',
  renacas: 'renacas',
  renacos: 'renacos',
  renanas: 'renanas',
  renanos: 'renanos',
  renasca: 'renasça',
  renasce: 'renasce',
  renasci: 'renasci',
  renasco: 'renasço',
  renatas: 'renatas',
  renatos: 'renatos',
  rendada: 'rendada',
  rendado: 'rendado',
  rendais: 'rendais',
  rendamo: 'rendamo',
  rendara: 'rendará',
  rendava: 'rendava',
  rendeis: 'rendeis',
  rendemo: 'rendemo',
  rendera: 'rendera',
  rendiam: 'rendiam',
  rendias: 'rendias',
  rendida: 'rendida',
  rendido: 'rendido',
  rendoes: 'rendões',
  rendora: 'rendora',
  rendosa: 'rendosa',
  rendoso: 'rendoso',
  renegai: 'renegai',
  renegam: 'renegam',
  renegar: 'renegar',
  renegas: 'renegas',
  renegou: 'renegou',
  renegue: 'renegue',
  renetai: 'renetai',
  renetam: 'renetam',
  renetar: 'renetar',
  renetas: 'renetas',
  renetei: 'renetei',
  renetem: 'renetem',
  renetes: 'renetes',
  renetou: 'renetou',
  rengaco: 'rengaço',
  renglao: 'renglão',
  rengras: 'rengras',
  renguea: 'rengueá',
  renguem: 'renguém',
  rengues: 'rengues',
  renhais: 'renhais',
  renhamo: 'renhamo',
  renhane: 'renhane',
  renhiam: 'renhiam',
  renhias: 'renhias',
  renhida: 'renhida',
  renhido: 'renhido',
  renhimo: 'renhimo',
  renhira: 'renhirá',
  renhoes: 'renhões',
  renicas: 'rênicas',
  renicos: 'rênicos',
  reniera: 'reniera',
  renifla: 'renifla',
  renifle: 'renifle',
  reniflo: 'reniflo',
  renilas: 'renilas',
  reninas: 'reninas',
  reninha: 'reninha',
  renitam: 'renitam',
  renitas: 'renitas',
  renitem: 'renitem',
  renites: 'renites',
  renitia: 'renitia',
  renitir: 'renitir',
  renitis: 'renitis',
  renitiu: 'renitiu',
  renodes: 'renodes',
  renomea: 'renomeá',
  renomes: 'renomes',
  renovai: 'renovai',
  renovam: 'renovam',
  renovar: 'renovar',
  renovas: 'renovas',
  renovei: 'renovei',
  renovem: 'renovem',
  renoves: 'renoves',
  renovos: 'renovos',
  renovou: 'renovou',
  renquea: 'rênquea',
  renques: 'renques',
  renrens: 'renréns',
  rentada: 'rentada',
  rentado: 'rentado',
  rentais: 'rentais',
  rentamo: 'rentamo',
  rentara: 'rentará',
  rentava: 'rentava',
  renteai: 'renteai',
  rentear: 'rentear',
  renteei: 'renteei',
  renteia: 'renteia',
  renteie: 'renteie',
  renteio: 'renteio',
  renteis: 'renteis',
  rentemo: 'rentemo',
  renteou: 'renteou',
  rentesa: 'rentesa',
  rentoes: 'rentões',
  rentona: 'rentona',
  rentree: 'rentrée',
  rentura: 'rentura',
  renuais: 'renuais',
  renuiam: 'renuíam',
  renuias: 'renuías',
  renuida: 'renuída',
  renuido: 'renuído',
  renuira: 'renuirá',
  renutra: 'renutra',
  renutre: 'renutre',
  renutri: 'renutri',
  renutro: 'renutro',
  reobase: 'reóbase',
  reobias: 'reóbias',
  reobios: 'reóbios',
  reobtem: 'reobtém',
  reobter: 'reobter',
  reocupa: 'reocupa',
  reocupe: 'reocupe',
  reocupo: 'reocupo',
  reofila: 'reófila',
  reofilo: 'reófilo',
  reofito: 'reófito',
  reofoba: 'reófoba',
  reofobo: 'reófobo',
  reoforo: 'reóforo',
  reolisa: 'reolisá',
  reolise: 'reolise',
  reoliso: 'reoliso',
  reonoma: 'reônoma',
  reonomo: 'reônomo',
  reopcao: 'reopção',
  reopera: 'reoperá',
  reopere: 'reopere',
  reopero: 'reopero',
  reopina: 'reopiná',
  reopine: 'reopine',
  reopino: 'reopino',
  reotomo: 'reótomo',
  reoucam: 'reouçam',
  reoucas: 'reouças',
  reouvam: 'reouvam',
  reouvas: 'reouvas',
  reouvei: 'reouvei',
  reouvem: 'reouvem',
  reouver: 'reouver',
  reouves: 'reouves',
  reouveu: 'reouveu',
  reouvia: 'reouvia',
  reouvir: 'reouvir',
  reouvis: 'reouvis',
  reouviu: 'reouviu',
  reoxida: 'reoxida',
  reoxide: 'reoxide',
  reoxido: 'reoxido',
  repagai: 'repagai',
  repagam: 'repagam',
  repagar: 'repagar',
  repagas: 'repagas',
  repagou: 'repagou',
  repague: 'repague',
  repaira: 'repairá',
  repaire: 'repaire',
  repairo: 'repairo',
  repanda: 'repanda',
  repando: 'repando',
  repanha: 'repanha',
  repanhe: 'repanhe',
  repanho: 'repanho',
  reparai: 'reparai',
  reparam: 'reparam',
  reparar: 'reparar',
  reparas: 'reparas',
  reparei: 'reparei',
  reparem: 'reparem',
  repares: 'repares',
  reparos: 'reparos',
  reparou: 'reparou',
  reparta: 'reparta',
  reparte: 'reparte',
  reparti: 'reparti',
  reparto: 'reparto',
  repasca: 'repasça',
  repasce: 'repasce',
  repasci: 'repasci',
  repasco: 'repasço',
  repassa: 'repassa',
  repasse: 'repasse',
  repasso: 'repasso',
  repasta: 'repasta',
  repaste: 'repaste',
  repasto: 'repasto',
  repatea: 'repateá',
  repecai: 'repeçai',
  repecam: 'repeçam',
  repecar: 'repeçar',
  repecas: 'repeças',
  repecei: 'repecei',
  repecem: 'repecem',
  repeces: 'repeces',
  repecha: 'repecha',
  repeche: 'repeche',
  repecho: 'repecho',
  repecou: 'repeçou',
  repedai: 'repedai',
  repedam: 'repedam',
  repedar: 'repedar',
  repedas: 'repedas',
  repedei: 'repedei',
  repedem: 'repedem',
  repedes: 'repedes',
  repedia: 'repedia',
  repedir: 'repedir',
  repedis: 'repedis',
  repediu: 'repediu',
  repedou: 'repedou',
  repegai: 'repegai',
  repegam: 'repegam',
  repegar: 'repegar',
  repegas: 'repegas',
  repegou: 'repegou',
  repegue: 'repegue',
  repeita: 'repeita',
  repeite: 'repeite',
  repeito: 'repeito',
  repelai: 'repelai',
  repelam: 'repelam',
  repelao: 'repelão',
  repelar: 'repelar',
  repelas: 'repelas',
  repelei: 'repelei',
  repelem: 'repelem',
  repeles: 'repeles',
  repelia: 'repelia',
  repelir: 'repelir',
  repelis: 'repelis',
  repeliu: 'repeliu',
  repelou: 'repelou',
  repensa: 'repensa',
  repense: 'repense',
  repenso: 'repenso',
  repente: 'repente',
  reperca: 'reperca',
  reperco: 'reperco',
  reperde: 'reperdê',
  reperdi: 'reperdi',
  reperes: 'reperes',
  repesai: 'repesai',
  repesam: 'repesam',
  repesar: 'repesar',
  repesas: 'repesas',
  repesca: 'repesca',
  repesco: 'repesco',
  repesei: 'repesei',
  repesem: 'repesem',
  repeses: 'repeses',
  repesos: 'repesos',
  repesou: 'repesou',
  repetea: 'repeteá',
  repetem: 'repetem',
  repetes: 'repetes',
  repetia: 'repetia',
  repetir: 'repetir',
  repetis: 'repetis',
  repetiu: 'repetiu',
  repicai: 'repicai',
  repicam: 'repicam',
  repicar: 'repicar',
  repicas: 'repicas',
  repicou: 'repicou',
  repilam: 'repilam',
  repilas: 'repilas',
  repilga: 'repilgá',
  repilgo: 'repilgo',
  repimpa: 'repimpa',
  repimpe: 'repimpe',
  repimpo: 'repimpo',
  repinal: 'repinal',
  repinta: 'repinta',
  repinte: 'repinte',
  repinto: 'repinto',
  repique: 'repique',
  repisai: 'repisai',
  repisam: 'repisam',
  repisar: 'repisar',
  repisas: 'repisas',
  repisca: 'repisca',
  repisco: 'repisco',
  repisei: 'repisei',
  repisem: 'repisem',
  repises: 'repises',
  repisou: 'repisou',
  repitam: 'repitam',
  repitas: 'repitas',
  replena: 'replena',
  replene: 'replene',
  repleno: 'repleno',
  repleta: 'repleta',
  replete: 'replete',
  repleto: 'repleto',
  replica: 'réplica',
  replico: 'replico',
  repodar: 'repodar',
  repoisa: 'repoisa',
  repoise: 'repoise',
  repoiso: 'repoiso',
  repolem: 'repolem',
  repoles: 'repoles',
  repolga: 'repolga',
  repolgo: 'repolgo',
  repolha: 'repolha',
  repolhe: 'repolhe',
  repolho: 'repolho',
  repolia: 'repolia',
  repolir: 'repolir',
  repolis: 'repolis',
  repoliu: 'repoliu',
  repomos: 'repomos',
  reponde: 'reponde',
  repondo: 'repondo',
  reponha: 'reponha',
  reponho: 'reponho',
  reponta: 'reponta',
  reponte: 'reponte',
  reponto: 'reponto',
  reporao: 'reporão',
  reporas: 'reporás',
  reporei: 'reporei',
  reporem: 'reporem',
  repores: 'repores',
  reporia: 'reporia',
  repormo: 'repormo',
  reporta: 'reporta',
  reporte: 'reporte',
  reporto: 'reporto',
  reposta: 'reposta',
  reposte: 'reposte',
  reposto: 'reposto',
  repousa: 'repousa',
  repouse: 'repouse',
  repouso: 'repouso',
  repovoa: 'repovoa',
  repovoe: 'repovoe',
  repovoo: 'repovoo',
  reprega: 'reprega',
  reprego: 'reprego',
  represa: 'represa',
  represe: 'represe',
  represo: 'represo',
  reprima: 'reprima',
  reprime: 'reprime',
  reprimi: 'reprimi',
  reprimo: 'reprimo',
  reprisa: 'reprisa',
  reprise: 'reprise',
  repriso: 'repriso',
  reproba: 'réproba',
  reprobo: 'réprobo',
  repropo: 'repropô',
  reprova: 'reprova',
  reprove: 'reprove',
  reprovi: 'reprovi',
  reprovo: 'reprovo',
  repruam: 'repruam',
  repruas: 'repruas',
  repruem: 'repruem',
  repruia: 'repruía',
  repruir: 'repruir',
  repruis: 'repruis',
  repruiu: 'repruiu',
  reprura: 'reprura',
  reprure: 'reprure',
  repruri: 'repruri',
  repruro: 'repruro',
  reptada: 'reptada',
  reptado: 'reptado',
  reptais: 'reptais',
  reptamo: 'reptamo',
  reptara: 'reptará',
  reptava: 'reptava',
  repteis: 'répteis',
  reptemo: 'reptemo',
  repudia: 'repudia',
  repudie: 'repudie',
  repudio: 'repúdio',
  repudir: 'repudir',
  repugna: 'repugna',
  repugne: 'repugne',
  repugno: 'repugno',
  repulai: 'repulai',
  repulam: 'repulam',
  repular: 'repular',
  repulas: 'repulas',
  repulei: 'repulei',
  repulem: 'repulem',
  repules: 'repules',
  repulou: 'repulou',
  repulsa: 'repulsa',
  repulse: 'repulse',
  repulso: 'repulso',
  repunge: 'repunge',
  repungi: 'repungi',
  repunha: 'repunha',
  repunja: 'repunja',
  repunjo: 'repunjo',
  repurga: 'repurga',
  repurgo: 'repurgo',
  repuser: 'repuser',
  reputai: 'reputai',
  reputam: 'reputam',
  reputar: 'reputar',
  reputas: 'reputas',
  reputei: 'reputei',
  reputem: 'reputem',
  reputes: 'reputes',
  reputou: 'reputou',
  repuxai: 'repuxai',
  repuxam: 'repuxam',
  repuxao: 'repuxão',
  repuxar: 'repuxar',
  repuxas: 'repuxas',
  repuxei: 'repuxei',
  repuxem: 'repuxem',
  repuxes: 'repuxes',
  repuxos: 'repuxos',
  repuxou: 'repuxou',
  requeis: 'requeis',
  requemo: 'requemo',
  requera: 'requerá',
  requere: 'requere',
  requeri: 'requeri',
  requero: 'requero',
  requete: 'requeté',
  requias: 'réquias',
  requiem: 'réquiem',
  requies: 'réquies',
  requife: 'requife',
  requins: 'requins',
  requira: 'requira',
  require: 'require',
  requiri: 'requiri',
  requiro: 'requiro',
  requixo: 'requixó',
  reriete: 'rerietê',
  resaibo: 'resaibo',
  resalte: 'resalte',
  resalto: 'resalto',
  resalva: 'resalva',
  resaque: 'resaque',
  resbuna: 'resbuna',
  resbune: 'resbune',
  resbuno: 'resbuno',
  resbuta: 'resbuta',
  resbuto: 'resbuto',
  rescuma: 'rescumá',
  rescume: 'rescume',
  rescumo: 'rescumo',
  resedal: 'resedal',
  resedas: 'resedás',
  reselia: 'resélia',
  resenas: 'resenas',
  resenha: 'resenha',
  resenhe: 'resenhe',
  resenho: 'resenho',
  resenos: 'resenos',
  reserva: 'reserva',
  reserve: 'reserve',
  reservo: 'reservo',
  resesso: 'resesso',
  resfria: 'resfria',
  resfrie: 'resfrie',
  resfrio: 'resfrio',
  resgada: 'resgada',
  resgado: 'resgado',
  resgais: 'resgais',
  resgara: 'resgará',
  resgata: 'resgata',
  resgate: 'resgate',
  resgato: 'resgato',
  resgava: 'resgava',
  resguei: 'resguei',
  resguem: 'resguem',
  resgues: 'resgues',
  residam: 'residam',
  residas: 'residas',
  residem: 'residem',
  resides: 'resides',
  residia: 'residia',
  residio: 'resídio',
  residir: 'residir',
  residis: 'residis',
  residiu: 'residiu',
  residuo: 'resíduo',
  resigna: 'resigna',
  resigne: 'resigne',
  resigno: 'resigno',
  resilam: 'resilam',
  resilas: 'resilas',
  resilem: 'resilem',
  resiles: 'resiles',
  resilia: 'resilia',
  resilio: 'resílio',
  resilir: 'resilir',
  resilis: 'resilis',
  resiliu: 'resiliu',
  resinai: 'resinai',
  resinam: 'resinam',
  resinar: 'resinar',
  resinas: 'resinas',
  resinei: 'resinei',
  resinem: 'resinem',
  resines: 'resines',
  resinga: 'resinga',
  resinia: 'resínia',
  resinio: 'resínio',
  resinol: 'resinol',
  resinou: 'resinou',
  resista: 'resista',
  resiste: 'resiste',
  resisti: 'resisti',
  resisto: 'resisto',
  resitas: 'resitas',
  resites: 'resites',
  resmada: 'resmada',
  resmado: 'resmado',
  resmais: 'resmais',
  resmamo: 'resmamo',
  resmara: 'resmara',
  resmava: 'resmava',
  resmeis: 'resmeis',
  resmemo: 'resmemo',
  resmoam: 'resmoam',
  resmoas: 'resmoas',
  resmoei: 'resmoei',
  resmoem: 'resmoem',
  resmoer: 'resmoer',
  resmoes: 'resmoes',
  resmoeu: 'resmoeu',
  resmoia: 'resmoia',
  resmono: 'resmono',
  resmuda: 'resmuda',
  resmuna: 'resmuná',
  resmune: 'resmune',
  resmuno: 'resmuno',
  resolto: 'resolto',
  resolva: 'resolva',
  resolve: 'resolve',
  resolvi: 'resolvi',
  resolvo: 'resolvo',
  resonar: 'resonar',
  respice: 'réspice',
  respiga: 'respiga',
  respigo: 'respigo',
  respira: 'respira',
  respire: 'respire',
  respiro: 'respiro',
  resquia: 'resquiá',
  resquie: 'resquie',
  resquio: 'resquio',
  ressabe: 'ressabe',
  ressaca: 'ressaca',
  ressaco: 'ressaco',
  ressada: 'ressada',
  ressaem: 'ressaem',
  ressaga: 'ressaga',
  ressaia: 'ressaia',
  ressaio: 'ressaio',
  ressair: 'ressair',
  ressais: 'ressais',
  ressaiu: 'ressaiu',
  resseca: 'resseca',
  resseco: 'resseco',
  ressega: 'ressega',
  ressego: 'ressego',
  ressela: 'ressela',
  ressele: 'ressele',
  resselo: 'resselo',
  resseso: 'resseso',
  ressica: 'ressica',
  ressico: 'ressico',
  ressiga: 'ressiga',
  ressios: 'ressios',
  ressoai: 'ressoai',
  ressoam: 'ressoam',
  ressoar: 'ressoar',
  ressoas: 'ressoas',
  ressobe: 'ressobe',
  ressoca: 'ressoca',
  ressoei: 'ressoei',
  ressoem: 'ressoem',
  ressoes: 'ressoes',
  ressois: 'ressóis',
  ressome: 'ressome',
  ressona: 'ressona',
  ressone: 'ressone',
  ressono: 'ressono',
  ressoos: 'ressoos',
  ressoou: 'ressoou',
  ressuai: 'ressuai',
  ressuam: 'ressuam',
  ressuar: 'ressuar',
  ressuas: 'ressuas',
  ressuba: 'ressuba',
  ressubi: 'ressubi',
  ressubo: 'ressubo',
  ressuda: 'ressuda',
  ressude: 'ressude',
  ressudo: 'ressudo',
  ressuei: 'ressuei',
  ressuem: 'ressuem',
  ressues: 'ressues',
  ressuma: 'ressuma',
  ressume: 'ressume',
  ressumi: 'ressumi',
  ressumo: 'ressumo',
  ressuou: 'ressuou',
  restada: 'restada',
  restado: 'restado',
  restais: 'restais',
  restamo: 'restamo',
  restara: 'restara',
  restava: 'restava',
  resteis: 'resteis',
  restela: 'restela',
  restele: 'restele',
  restelo: 'restelo',
  restemo: 'restemo',
  resteva: 'resteva',
  restias: 'réstias',
  restiga: 'réstiga',
  restila: 'restila',
  restile: 'restile',
  restilo: 'restilo',
  restios: 'réstios',
  restira: 'restira',
  restire: 'restire',
  restiro: 'restiro',
  restita: 'restita',
  restite: 'restite',
  restito: 'restito',
  restiva: 'restiva',
  restive: 'restive',
  restivo: 'restivo',
  restuca: 'restuca',
  restuco: 'restuco',
  resudar: 'resudar',
  resulho: 'resulho',
  resulta: 'resulta',
  resulte: 'resulte',
  resulto: 'resulto',
  resumam: 'resumam',
  resumao: 'resumão',
  resumar: 'resumar',
  resumas: 'resumas',
  resumem: 'resumem',
  resumes: 'resumes',
  resumia: 'resumia',
  resumir: 'resumir',
  resumis: 'resumis',
  resumiu: 'resumiu',
  resumos: 'resumos',
  resuras: 'resuras',
  resvala: 'resvala',
  resvale: 'resvale',
  resvalo: 'resvalo',
  retacai: 'retacai',
  retacam: 'retacam',
  retacar: 'retacar',
  retacas: 'retacas',
  retacos: 'retacos',
  retacou: 'retacou',
  retadao: 'retadão',
  retadas: 'retadas',
  retador: 'retador',
  retados: 'retados',
  retalha: 'retalha',
  retalhe: 'retalhe',
  retalho: 'retalho',
  retalia: 'retalia',
  retalie: 'retalie',
  retalio: 'retalio',
  retamas: 'retamas',
  retamea: 'retâmea',
  retames: 'retames',
  retamos: 'retamos',
  retando: 'retando',
  retanha: 'retanha',
  retante: 'retante',
  retanto: 'retanto',
  retaque: 'retaque',
  retaram: 'retaram',
  retarao: 'retarão',
  retaras: 'retarás',
  retarda: 'retarda',
  retarde: 'retarde',
  retardo: 'retardo',
  retarei: 'retarei',
  retarem: 'retarem',
  retares: 'retares',
  retaria: 'retaria',
  retarmo: 'retarmo',
  retasse: 'retasse',
  retaste: 'retaste',
  retavam: 'retavam',
  retavas: 'retavas',
  retavos: 'retavos',
  reteada: 'reteada',
  reteado: 'reteado',
  reteais: 'reteais',
  reteara: 'reteará',
  reteava: 'reteava',
  retedor: 'retedor',
  reteeis: 'reteeis',
  reteiam: 'reteiam',
  reteias: 'reteias',
  reteiem: 'reteiem',
  reteies: 'reteies',
  reteima: 'reteima',
  reteime: 'reteime',
  reteimo: 'reteimo',
  retelha: 'retelha',
  retelhe: 'retelhe',
  retelho: 'retelho',
  retemos: 'retemos',
  retempo: 'retempo',
  retenam: 'retenam',
  retenas: 'retenas',
  retende: 'retende',
  retendo: 'retendo',
  retenem: 'retenem',
  retenes: 'retenes',
  retenha: 'retenha',
  retenho: 'retenho',
  retenia: 'retenia',
  retenir: 'retenir',
  retenis: 'retenis',
  reteniu: 'reteniu',
  retenos: 'retenos',
  retenta: 'retenta',
  retente: 'retente',
  retento: 'retento',
  reterao: 'reterão',
  reteras: 'reterás',
  reterei: 'reterei',
  reterem: 'reterem',
  reteres: 'reteres',
  reteria: 'reteria',
  retesai: 'retesai',
  retesam: 'retesam',
  retesar: 'retesar',
  retesas: 'retesas',
  retesei: 'retesei',
  retesem: 'retesem',
  reteses: 'reteses',
  retesia: 'retesia',
  retesie: 'retesie',
  retesio: 'retesio',
  retesou: 'retesou',
  retesta: 'retesta',
  reteste: 'reteste',
  retesto: 'retesto',
  reteudo: 'reteúdo',
  retiana: 'retiana',
  retiano: 'retiano',
  reticas: 'réticas',
  reticos: 'réticos',
  retidao: 'retidão',
  retidas: 'retidas',
  retidos: 'retidos',
  retinal: 'retinal',
  retinam: 'retinam',
  retinas: 'retinas',
  retinem: 'retinem',
  retines: 'retines',
  retinge: 'retinge',
  retingi: 'retingi',
  retinha: 'retinha',
  retinia: 'retinia',
  retinim: 'retinim',
  retinir: 'retinir',
  retinis: 'retinis',
  retiniu: 'retiniu',
  retinja: 'retinja',
  retinjo: 'retinjo',
  retinol: 'retinol',
  retinta: 'retinta',
  retinto: 'retinto',
  retiolo: 'retíolo',
  retirai: 'retirai',
  retiram: 'retiram',
  retirar: 'retirar',
  retiras: 'retiras',
  retirei: 'retirei',
  retirem: 'retirem',
  retires: 'retires',
  retiros: 'retiros',
  retirou: 'retirou',
  retisna: 'retisná',
  retisne: 'retisne',
  retisno: 'retisno',
  retites: 'retites',
  retiver: 'retiver',
  retobar: 'retobar',
  retocai: 'retocai',
  retocam: 'retocam',
  retocar: 'retocar',
  retocas: 'retocas',
  retocou: 'retocou',
  retoica: 'retoiça',
  retoice: 'retoice',
  retoico: 'retoiço',
  retomai: 'retomai',
  retomam: 'retomam',
  retomar: 'retomar',
  retomas: 'retomas',
  retombo: 'retombo',
  retomei: 'retomei',
  retomem: 'retomem',
  retomes: 'retomes',
  retomou: 'retomou',
  retonha: 'retonhá',
  retonhe: 'retonhe',
  retonho: 'retonho',
  retoque: 'retoque',
  retoras: 'retoras',
  retorca: 'retorça',
  retorce: 'retorce',
  retorci: 'retorci',
  retorco: 'retorço',
  retores: 'retores',
  retoria: 'retoria',
  retorna: 'retorna',
  retorne: 'retorne',
  retorno: 'retorno',
  retorsa: 'retorsa',
  retorso: 'retorso',
  retorta: 'retorta',
  retorto: 'retorto',
  retosai: 'retosai',
  retosam: 'retosam',
  retosar: 'retosar',
  retosas: 'retosas',
  retosei: 'retosei',
  retosem: 'retosem',
  retoses: 'retoses',
  retosou: 'retosou',
  retosta: 'retosta',
  retoste: 'retoste',
  retosto: 'retosto',
  retouca: 'retouça',
  retouce: 'retouce',
  retouco: 'retouço',
  retovai: 'retovai',
  retovam: 'retovam',
  retovar: 'retovar',
  retovas: 'retovas',
  retovei: 'retovei',
  retovem: 'retovem',
  retoves: 'retoves',
  retovou: 'retovou',
  retraca: 'retraça',
  retrace: 'retrace',
  retraco: 'retraço',
  retraem: 'retraem',
  retraga: 'retraga',
  retrago: 'retrago',
  retraia: 'retraia',
  retraio: 'retraio',
  retrair: 'retrair',
  retrais: 'retrais',
  retraiu: 'retraiu',
  retrama: 'retrama',
  retrame: 'retrame',
  retramo: 'retramo',
  retrara: 'retrará',
  retrasa: 'retrasa',
  retrase: 'retrase',
  retraso: 'retraso',
  retrata: 'retrata',
  retrate: 'retrate',
  retrato: 'retrato',
  retrava: 'retrava',
  retrave: 'retrave',
  retravo: 'retravo',
  retraze: 'retraze',
  retrema: 'retrema',
  retreme: 'retreme',
  retremi: 'retremi',
  retremo: 'retremo',
  retreta: 'retreta',
  retrete: 'retrete',
  retrina: 'retrina',
  retrine: 'retrine',
  retrino: 'retrino',
  retroai: 'retroai',
  retroam: 'retroam',
  retroar: 'retroar',
  retroas: 'retroas',
  retroei: 'retroei',
  retroem: 'retroem',
  retroes: 'retroes',
  retroou: 'retroou',
  retruca: 'retruca',
  retruco: 'retruco',
  retrusa: 'retrusa',
  retruso: 'retruso',
  retubai: 'retubai',
  retubam: 'retubam',
  retubar: 'retubar',
  retubas: 'retubas',
  retubei: 'retubei',
  retubem: 'retubem',
  retubes: 'retubes',
  retubou: 'retubou',
  retuita: 'retuíta',
  retuite: 'retuíte',
  retuito: 'retuíto',
  retumba: 'retumba',
  retumbe: 'retumbe',
  retumbo: 'retumbo',
  retunda: 'retunda',
  retunde: 'retunde',
  retundi: 'retundi',
  retundo: 'retundo',
  returca: 'returca',
  returco: 'returco',
  returna: 'returna',
  returno: 'returno',
  retusas: 'retusas',
  retusos: 'retusos',
  retzias: 'rétzias',
  reumato: 'reumato',
  reumosa: 'reumosa',
  reumoso: 'reumoso',
  reunais: 'reunais',
  reunamo: 'reunamo',
  reuniam: 'reuniam',
  reuniao: 'reunião',
  reunias: 'reunias',
  reunida: 'reunida',
  reunido: 'reunido',
  reunimo: 'reunimo',
  reunira: 'reunira',
  reusada: 'reusada',
  reusado: 'reusado',
  reusais: 'reusais',
  reusamo: 'reusamo',
  reusara: 'reusará',
  reusava: 'reusava',
  reuseis: 'reuseis',
  reusemo: 'reusemo',
  reussia: 'reússia',
  revedes: 'revedes',
  revedor: 'revedor',
  revejai: 'revejai',
  revejam: 'revejam',
  revejas: 'revejas',
  revelai: 'revelai',
  revelam: 'revelam',
  revelao: 'revelão',
  revelar: 'revelar',
  revelas: 'revelas',
  revelei: 'revelei',
  revelem: 'revelem',
  reveler: 'reveler',
  reveles: 'reveles',
  revelha: 'revelha',
  revelho: 'revelho',
  revelia: 'revelia',
  revelim: 'revelim',
  revelir: 'revelir',
  revelis: 'revelis',
  reveliu: 'reveliu',
  revelou: 'revelou',
  revemos: 'revemos',
  revenam: 'revenam',
  revenas: 'revenas',
  revenda: 'revenda',
  revende: 'revende',
  revendi: 'revendi',
  revendo: 'revendo',
  revenem: 'revenem',
  revenes: 'revenes',
  revenha: 'revenha',
  revenho: 'revenho',
  revenia: 'revenia',
  revenir: 'revenir',
  revenis: 'revenis',
  reveniu: 'reveniu',
  reverao: 'reverão',
  reveras: 'reverás',
  reverei: 'reverei',
  reverem: 'reverem',
  reveres: 'reveres',
  reverga: 'revergá',
  revergo: 'revergo',
  reveria: 'reveria',
  reversa: 'reversa',
  reverse: 'reverse',
  reverso: 'reverso',
  reverta: 'reverta',
  reverte: 'reverte',
  reverti: 'reverti',
  reverto: 'reverto',
  revesai: 'revesai',
  revesam: 'revesam',
  revesar: 'revesar',
  revesas: 'revesas',
  revesei: 'revesei',
  revesem: 'revesem',
  reveses: 'reveses',
  revesio: 'revesio',
  revesou: 'revesou',
  revessa: 'revessa',
  revesse: 'revesse',
  revesso: 'revesso',
  reveste: 'reveste',
  revesti: 'revesti',
  revezai: 'revezai',
  revezam: 'revezam',
  revezar: 'revezar',
  revezas: 'revezas',
  revezei: 'revezei',
  revezem: 'revezem',
  revezes: 'revezes',
  revezou: 'revezou',
  revibra: 'revibra',
  revibre: 'revibre',
  revibro: 'revibro',
  revicai: 'reviçai',
  revicam: 'reviçam',
  revicar: 'reviçar',
  revicas: 'reviças',
  revicei: 'revicei',
  revicem: 'revicem',
  revices: 'revices',
  revicou: 'reviçou',
  revidai: 'revidai',
  revidam: 'revidam',
  revidar: 'revidar',
  revidas: 'revidas',
  revidei: 'revidei',
  revidem: 'revidem',
  revides: 'revides',
  revidor: 'revidor',
  revidou: 'revidou',
  revieis: 'revíeis',
  reviera: 'reviera',
  revigor: 'revigor',
  revilam: 'revilam',
  revilas: 'revilas',
  revimos: 'revimos',
  revinas: 'revinas',
  revinda: 'revinda',
  revinde: 'revinde',
  revindo: 'revindo',
  revinga: 'revinga',
  revingo: 'revingo',
  revinha: 'revinha',
  revinos: 'revinos',
  revirai: 'revirai',
  reviram: 'reviram',
  revirao: 'revirão',
  revirar: 'revirar',
  reviras: 'reviras',
  revirei: 'revirei',
  revirem: 'revirem',
  revires: 'revires',
  reviria: 'reviria',
  revirmo: 'revirmo',
  revirou: 'revirou',
  revisai: 'revisai',
  revisam: 'revisam',
  revisao: 'revisão',
  revisar: 'revisar',
  revisas: 'revisas',
  revisca: 'revisca',
  revisco: 'revisco',
  revisei: 'revisei',
  revisem: 'revisem',
  revises: 'revises',
  revisor: 'revisor',
  revisou: 'revisou',
  revisse: 'revisse',
  revista: 'revista',
  reviste: 'reviste',
  revisto: 'revisto',
  revival: 'revival',
  revivam: 'revivam',
  revivas: 'revivas',
  revivei: 'revivei',
  revivem: 'revivem',
  reviver: 'reviver',
  revives: 'revives',
  reviveu: 'reviveu',
  revivia: 'revivia',
  revoada: 'revoada',
  revoado: 'revoado',
  revoais: 'revoais',
  revoamo: 'revoamo',
  revoara: 'revoará',
  revoava: 'revoava',
  revocai: 'revocai',
  revocam: 'revocam',
  revocar: 'revocar',
  revocas: 'revocas',
  revocou: 'revocou',
  revoeis: 'revoeis',
  revoeja: 'revoeja',
  revoeje: 'revoeje',
  revoejo: 'revoejo',
  revoemo: 'revoemo',
  revogai: 'revogai',
  revogam: 'revogam',
  revogar: 'revogar',
  revogas: 'revogas',
  revogou: 'revogou',
  revogue: 'revogue',
  revolca: 'revolca',
  revolco: 'revolco',
  revolta: 'revolta',
  revolte: 'revolte',
  revolto: 'revolto',
  revolva: 'revolva',
  revolve: 'revolve',
  revolvi: 'revolvi',
  revolvo: 'revolvo',
  revoque: 'revoque',
  revorai: 'revorai',
  revoram: 'revoram',
  revorar: 'revorar',
  revoras: 'révoras',
  revorei: 'revorei',
  revorem: 'revorem',
  revores: 'revores',
  revorou: 'revorou',
  revosso: 'revosso',
  revovel: 'revóvel',
  revulsa: 'revulsa',
  revulse: 'revulse',
  revulso: 'revulso',
  rexelos: 'rexelos',
  rexerta: 'rexerta',
  rexismo: 'rexismo',
  rexista: 'rexista',
  rexoxos: 'rexoxós',
  rezadao: 'rezadão',
  rezadas: 'rezadas',
  rezador: 'rezador',
  rezados: 'rezados',
  rezamos: 'rezamos',
  rezando: 'rezando',
  rezante: 'rezante',
  rezaram: 'rezaram',
  rezarao: 'rezarão',
  rezaras: 'rezaras',
  rezarei: 'rezarei',
  rezarem: 'rezarem',
  rezares: 'rezares',
  rezaria: 'rezaria',
  rezarmo: 'rezarmo',
  rezasse: 'rezasse',
  rezaste: 'rezaste',
  rezavam: 'rezavam',
  rezavas: 'rezavas',
  rezemos: 'rezemos',
  rezenta: 'rezenta',
  rezente: 'rezente',
  rezento: 'rezento',
  rezinas: 'rezinas',
  rezinga: 'rezinga',
  rezingo: 'rezingo',
  rezinha: 'rezinha',
  rezipai: 'rezipai',
  rezipam: 'rezipam',
  rezipar: 'rezipar',
  rezipas: 'rezipas',
  rezipei: 'rezipei',
  rezipem: 'rezipem',
  rezipes: 'rezipes',
  rezipou: 'rezipou',
  rezonas: 'rezonas',
  rezonea: 'rezoneá',
  rezuela: 'rezuela',
  rezulho: 'rezulho',
  rezumba: 'rezumba',
  rezumbe: 'rezumbe',
  rezumbi: 'rezumbi',
  rezumbo: 'rezumbo',
  riachao: 'riachão',
  riachos: 'riachos',
  riacias: 'riácias',
  riadara: 'riadara',
  rialeza: 'rialeza',
  rialzao: 'rialzão',
  riambas: 'riambas',
  riazeno: 'riazeno',
  ribacao: 'ribação',
  ribacas: 'ribaçãs',
  ribadao: 'ribadão',
  ribadas: 'ribadas',
  ribador: 'ribador',
  ribados: 'ribados',
  ribalda: 'ribalda',
  ribalde: 'ribalde',
  ribaldo: 'ribaldo',
  ribalta: 'ribalta',
  ribamar: 'ribamar',
  ribamos: 'ribamos',
  ribanas: 'ribanas',
  ribanca: 'ribança',
  ribanco: 'ribanço',
  ribando: 'ribando',
  ribante: 'ribante',
  ribaram: 'ribaram',
  ribarao: 'ribarão',
  ribaras: 'ribaras',
  ribarei: 'ribarei',
  ribarem: 'ribarem',
  ribares: 'ribares',
  ribaria: 'ribaria',
  ribarmo: 'ribarmo',
  ribasse: 'ribasse',
  ribaste: 'ribaste',
  ribavam: 'ribavam',
  ribavas: 'ribavas',
  ribeira: 'ribeira',
  ribeire: 'ribeire',
  ribeiro: 'ribeiro',
  ribemos: 'ribemos',
  ribense: 'ribense',
  ribesia: 'ribésia',
  ribetas: 'ribetas',
  ribetes: 'ribetes',
  ribitol: 'ribitol',
  ribomba: 'ribomba',
  ribombe: 'ribombe',
  ribombo: 'ribombo',
  riboses: 'riboses',
  ricacas: 'ricaças',
  ricacos: 'ricaços',
  ricadao: 'riçadão',
  ricadas: 'riçadas',
  ricador: 'riçador',
  ricados: 'riçados',
  ricalha: 'ricalhã',
  ricamos: 'riçamos',
  ricando: 'riçando',
  ricanha: 'ricanha',
  ricanho: 'ricanho',
  ricante: 'riçante',
  ricaram: 'riçaram',
  ricarao: 'riçarão',
  ricaras: 'riçarás',
  ricardo: 'ricardo',
  ricarei: 'riçarei',
  ricarem: 'riçarem',
  ricares: 'riçares',
  ricaria: 'riçaria',
  ricarmo: 'riçarmo',
  ricasse: 'riçasse',
  ricaste: 'riçaste',
  ricavam: 'riçavam',
  ricavas: 'riçavas',
  riceira: 'riceira',
  riceiro: 'riceiro',
  ricemos: 'ricemos',
  ricerca: 'ricerca',
  ricerco: 'ricerco',
  riciela: 'riciela',
  ricinas: 'ricinas',
  ricinea: 'ricínea',
  ricineo: 'ricíneo',
  ricinio: 'ricínio',
  ricinos: 'rícinos',
  ricoica: 'ricoiça',
  ricoico: 'ricoiço',
  ricolas: 'ricolas',
  ricongo: 'ricongo',
  ricosso: 'ricosso',
  ricotas: 'ricotas',
  ricotia: 'ricótia',
  ricouca: 'ricouça',
  ricouco: 'ricouço',
  ricquia: 'rícquia',
  ricungo: 'ricungo',
  rideira: 'rideira',
  rideiro: 'rideiro',
  ridelas: 'ridelas',
  ridente: 'ridente',
  ridicai: 'ridicai',
  ridicam: 'ridicam',
  ridicar: 'ridicar',
  ridicas: 'ridicas',
  ridicos: 'rídicos',
  ridicou: 'ridicou',
  ridinha: 'ridinha',
  ridinho: 'ridinho',
  ridique: 'ridique',
  riditas: 'riditas',
  ridonas: 'ridonas',
  ridoras: 'ridoras',
  ridores: 'ridores',
  rieiras: 'rieiras',
  rieiros: 'rieiros',
  rienses: 'rienses',
  rifadao: 'rifadão',
  rifadas: 'rifadas',
  rifador: 'rifador',
  rifados: 'rifados',
  rifamos: 'rifamos',
  rifanas: 'rifanas',
  rifando: 'rifando',
  rifanos: 'rifanos',
  rifante: 'rifante',
  rifaram: 'rifaram',
  rifarao: 'rifarão',
  rifaras: 'rifaras',
  rifarei: 'rifarei',
  rifarem: 'rifarem',
  rifares: 'rifares',
  rifaria: 'rifaria',
  rifarmo: 'rifarmo',
  rifasse: 'rifasse',
  rifaste: 'rifaste',
  rifavam: 'rifavam',
  rifavas: 'rifavas',
  rifavel: 'rifável',
  rifeias: 'rifeias',
  rifemos: 'rifemos',
  rifenha: 'rifenha',
  rifenho: 'rifenho',
  rifetes: 'rifetes',
  rififis: 'rififis',
  riflada: 'riflada',
  riflado: 'riflado',
  riflais: 'riflais',
  riflamo: 'riflamo',
  riflara: 'riflara',
  riflava: 'riflava',
  rifleis: 'rifleis',
  riflemo: 'riflemo',
  rigacos: 'rigaços',
  rigadao: 'rigadão',
  rigadas: 'rigadas',
  rigador: 'rigador',
  rigados: 'rigados',
  rigamos: 'rigamos',
  rigando: 'rigando',
  rigante: 'rigante',
  rigaram: 'rigaram',
  rigarao: 'rigarão',
  rigaras: 'rigarás',
  rigarei: 'rigarei',
  rigarem: 'rigarem',
  rigares: 'rigares',
  rigaria: 'rigaria',
  rigasse: 'rigasse',
  rigaste: 'rigaste',
  rigavam: 'rigavam',
  rigavas: 'rigavas',
  rigeira: 'rigeira',
  rigente: 'rigente',
  rigidas: 'rígidas',
  rigidez: 'rigidez',
  rigidos: 'rígidos',
  riginia: 'rigínia',
  rigodao: 'rigodão',
  rigodio: 'rigódio',
  rigolas: 'rigolas',
  rigoliz: 'rigoliz',
  rigolos: 'rigolôs',
  rigores: 'rigores',
  rigueis: 'rigueis',
  rigveda: 'rigveda',
  rijadao: 'rijadão',
  rijadas: 'rijadas',
  rijador: 'rijador',
  rijados: 'rijados',
  rijamos: 'rijamos',
  rijando: 'rijando',
  rijante: 'rijante',
  rijaram: 'rijaram',
  rijarao: 'rijarão',
  rijaras: 'rijarás',
  rijarei: 'rijarei',
  rijarem: 'rijarem',
  rijares: 'rijares',
  rijaria: 'rijaria',
  rijarmo: 'rijarmo',
  rijasse: 'rijasse',
  rijaste: 'rijaste',
  rijavam: 'rijavam',
  rijavas: 'rijavas',
  rijeira: 'rijeira',
  rijemos: 'rijemos',
  rijezas: 'rijezas',
  rijinho: 'rijinho',
  rijoada: 'rijoada',
  rijosas: 'rijosas',
  rijosos: 'rijosos',
  rijumes: 'rijumes',
  riladao: 'riladão',
  riladas: 'riladas',
  rilador: 'rilador',
  rilados: 'rilados',
  rilamos: 'rilamos',
  rilando: 'rilando',
  rilante: 'rilante',
  rilaram: 'rilaram',
  rilarao: 'rilarão',
  rilaras: 'rilarás',
  rilarei: 'rilarei',
  rilarem: 'rilarem',
  rilares: 'rilares',
  rilaria: 'rilaria',
  rilarmo: 'rilarmo',
  rilasse: 'rilasse',
  rilaste: 'rilaste',
  rilavam: 'rilavam',
  rilavas: 'rilavas',
  rilemos: 'rilemos',
  rilhada: 'rilhada',
  rilhado: 'rilhado',
  rilhais: 'rilhais',
  rilhamo: 'rilhamo',
  rilhara: 'rilhara',
  rilhava: 'rilhava',
  rilheis: 'rilheis',
  rilhemo: 'rilhemo',
  rilhosa: 'rilhosa',
  rilhoso: 'rilhoso',
  rilhoto: 'rilhoto',
  riluzol: 'riluzol',
  rilzoes: 'rilzões',
  rimacao: 'rimação',
  rimadao: 'rimadão',
  rimadas: 'rimadas',
  rimador: 'rimador',
  rimados: 'rimados',
  rimalho: 'rimalho',
  rimamos: 'rimamos',
  rimance: 'rimance',
  rimanco: 'rimanço',
  rimando: 'rimando',
  rimante: 'rimante',
  rimaram: 'rimaram',
  rimarao: 'rimarão',
  rimaras: 'rimaras',
  rimarei: 'rimarei',
  rimarem: 'rimarem',
  rimares: 'rimares',
  rimaria: 'rimaria',
  rimario: 'rimário',
  rimarmo: 'rimarmo',
  rimasse: 'rimasse',
  rimaste: 'rimaste',
  rimavam: 'rimavam',
  rimavas: 'rimavas',
  rimavel: 'rimável',
  rimemos: 'rimemos',
  rimento: 'rimento',
  rimicas: 'rímicas',
  rimicos: 'rímicos',
  rimista: 'rimista',
  rimosas: 'rimosas',
  rimosia: 'rimósia',
  rimosos: 'rimosos',
  rimulas: 'rímulas',
  rinanto: 'rinanto',
  rinaria: 'rinária',
  rinario: 'rinário',
  rinasto: 'rinasto',
  rinauas: 'rinauas',
  rincada: 'rinçada',
  rincado: 'rinçado',
  rincais: 'rinçais',
  rincamo: 'rinçamo',
  rincara: 'rinçará',
  rincava: 'rinçava',
  rinceis: 'rinceis',
  rincemo: 'rincemo',
  rinchai: 'rinchai',
  rincham: 'rincham',
  rinchao: 'rinchão',
  rinchar: 'rinchar',
  rinchas: 'rinchas',
  rinchei: 'rinchei',
  rinchem: 'rinchem',
  rinches: 'rinches',
  rinchos: 'rinchos',
  rinchou: 'rinchou',
  rincodo: 'rincodo',
  rincoes: 'rincões',
  rincolo: 'ríncolo',
  rincona: 'rincona',
  rincone: 'rincone',
  rincono: 'rincono',
  rincope: 'rincope',
  rincops: 'rincóps',
  rincota: 'rincota',
  rincote: 'rincote',
  rincoto: 'rincoto',
  rinemio: 'rinêmio',
  rinemis: 'rínemis',
  rinfada: 'rinfada',
  rinfado: 'rinfado',
  rinfais: 'rinfais',
  rinfamo: 'rinfamo',
  rinfara: 'rinfará',
  rinfava: 'rinfava',
  rinfeis: 'rinfeis',
  rinfemo: 'rinfemo',
  rinfoes: 'rinfões',
  ringeis: 'ringeis',
  ringemo: 'ringemo',
  ringera: 'ringerá',
  ringiam: 'ringiam',
  ringias: 'ringias',
  ringida: 'ringida',
  ringido: 'ringido',
  ringira: 'ringirá',
  ringues: 'ringues',
  rinhada: 'rinhada',
  rinhado: 'rinhado',
  rinhais: 'rinhais',
  rinhamo: 'rinhamo',
  rinhara: 'rinhará',
  rinhava: 'rinhava',
  rinheis: 'rinheis',
  rinhemo: 'rinhemo',
  rinhoes: 'rinhões',
  riniais: 'riniais',
  riniale: 'riniale',
  rinidea: 'rinídea',
  rinideo: 'rinídeo',
  rinions: 'rínions',
  rinismo: 'rinismo',
  rinista: 'rinista',
  rinites: 'rinites',
  rinjais: 'rinjais',
  rinjamo: 'rinjamo',
  rinobio: 'rinóbio',
  rinodon: 'rínodon',
  rinofes: 'rínofes',
  rinofis: 'rínofis',
  rinonco: 'rinonco',
  rinopes: 'rinopes',
  rinorta: 'rinorta',
  rinoses: 'rinoses',
  rinques: 'rinques',
  rinusas: 'rinusas',
  riodina: 'riodina',
  riojana: 'riojana',
  riojano: 'riojano',
  riolita: 'riolita',
  riolite: 'riolite',
  riolito: 'riolito',
  rionita: 'rionita',
  rioscas: 'rioscas',
  riostes: 'riostes',
  ripadao: 'ripadão',
  ripadas: 'ripadas',
  ripador: 'ripador',
  ripados: 'ripados',
  ripagem: 'ripagem',
  ripamos: 'ripamos',
  ripanca: 'ripança',
  ripance: 'ripance',
  ripanco: 'ripanço',
  ripando: 'ripando',
  ripante: 'ripante',
  riparam: 'riparam',
  riparao: 'riparão',
  riparas: 'riparás',
  riparei: 'riparei',
  riparem: 'riparem',
  ripares: 'ripares',
  riparia: 'ripária',
  ripario: 'ripário',
  riparmo: 'riparmo',
  ripasse: 'ripasse',
  ripaste: 'ripaste',
  ripavam: 'ripavam',
  ripavas: 'ripavas',
  ripeias: 'ripeias',
  ripeira: 'ripeira',
  ripeiro: 'ripeiro',
  ripemos: 'ripemos',
  ripetas: 'ripetas',
  ripiada: 'ripiada',
  ripiado: 'ripiado',
  ripides: 'rípides',
  ripidia: 'ripídia',
  ripidio: 'ripídio',
  ripieno: 'ripieno',
  ripinas: 'ripinas',
  ripinha: 'ripinha',
  ripiste: 'ripiste',
  ripobio: 'ripóbio',
  ripolem: 'ripolém',
  riponas: 'riponas',
  riponga: 'riponga',
  riposta: 'riposta',
  riposte: 'riposte',
  riposto: 'riposto',
  ripsale: 'rípsale',
  riptica: 'ríptica',
  riptico: 'ríptico',
  riquesu: 'riquesu',
  riquexo: 'riquexó',
  riqueza: 'riqueza',
  riquias: 'ríquias',
  riquixa: 'riquixá',
  riramos: 'ríramos',
  riremos: 'riremos',
  ririeis: 'riríeis',
  risadas: 'risadas',
  risanza: 'risanza',
  riscada: 'riscada',
  riscado: 'riscado',
  riscais: 'riscais',
  riscamo: 'riscamo',
  riscara: 'riscara',
  riscava: 'riscava',
  riscoes: 'riscões',
  riscosa: 'riscosa',
  riscoso: 'riscoso',
  riscote: 'riscote',
  risdale: 'risdale',
  riseita: 'riseíta',
  riseite: 'riseíte',
  risinha: 'risinha',
  risinho: 'risinho',
  risivel: 'risível',
  risodos: 'risodos',
  risonas: 'risonas',
  risonha: 'risonha',
  risonho: 'risonho',
  risopes: 'risopes',
  risoria: 'risória',
  risorio: 'risório',
  risotas: 'risotas',
  risotes: 'risotes',
  risotos: 'risotos',
  risotto: 'risotto',
  rispida: 'ríspida',
  rispido: 'ríspido',
  risquei: 'risquei',
  risquem: 'risquem',
  risques: 'risques',
  risseis: 'rísseis',
  rissemo: 'rissemo',
  rissoia: 'rissoia',
  rissois: 'rissóis',
  rissole: 'rissole',
  ristela: 'ristela',
  ristilo: 'ristilo',
  ristina: 'ristina',
  ritacne: 'ritacne',
  ritebas: 'ritebas',
  riteira: 'riteira',
  ritende: 'ritende',
  ritense: 'ritense',
  ritidas: 'rítidas',
  ritidio: 'ritídio',
  ritidos: 'ritidos',
  ritinas: 'ritinas',
  ritinha: 'ritinha',
  ritinho: 'ritinho',
  ritiodo: 'ritiodo',
  ritioes: 'ritiões',
  ritismo: 'ritismo',
  ritista: 'ritista',
  ritmada: 'ritmada',
  ritmado: 'ritmado',
  ritmais: 'ritmais',
  ritmamo: 'ritmamo',
  ritmara: 'ritmará',
  ritmava: 'ritmava',
  ritmeis: 'ritmeis',
  ritmemo: 'ritmemo',
  ritmica: 'rítmica',
  ritmico: 'rítmico',
  ritmito: 'ritmito',
  ritmiza: 'ritmiza',
  ritmize: 'ritmize',
  ritmizo: 'ritmizo',
  ritoaia: 'ritoaia',
  ritombe: 'ritombe',
  ritonde: 'ritonde',
  rituais: 'rituais',
  ritucos: 'ritucos',
  ritumba: 'ritumba',
  ritutis: 'ritutis',
  rivaita: 'rivaíta',
  rivanol: 'rivanol',
  rivelia: 'rivélia',
  rivense: 'rivense',
  rivinas: 'rivinas',
  rivinea: 'rivínea',
  rivinia: 'rivínia',
  rivulas: 'rívulas',
  rivulos: 'rívulos',
  rixadao: 'rixadão',
  rixadas: 'rixadas',
  rixador: 'rixador',
  rixados: 'rixados',
  rixamos: 'rixamos',
  rixando: 'rixando',
  rixante: 'rixante',
  rixaram: 'rixaram',
  rixarao: 'rixarão',
  rixaras: 'rixarás',
  rixarei: 'rixarei',
  rixarem: 'rixarem',
  rixares: 'rixares',
  rixaria: 'rixaria',
  rixarmo: 'rixarmo',
  rixasse: 'rixasse',
  rixaste: 'rixaste',
  rixavam: 'rixavam',
  rixavas: 'rixavas',
  rixemos: 'rixemos',
  rixenta: 'rixenta',
  rixento: 'rixento',
  rixosas: 'rixosas',
  rixosos: 'rixosos',
  rizadao: 'rizadão',
  rizadas: 'rizadas',
  rizador: 'rizador',
  rizados: 'rizados',
  rizagra: 'rizagra',
  rizamos: 'rizamos',
  rizando: 'rizando',
  rizanta: 'rizanta',
  rizante: 'rizante',
  rizanto: 'rizanto',
  rizaram: 'rizaram',
  rizarao: 'rizarão',
  rizaras: 'rizarás',
  rizarei: 'rizarei',
  rizarem: 'rizarem',
  rizares: 'rizares',
  rizaria: 'rizaria',
  rizarmo: 'rizarmo',
  rizasse: 'rizasse',
  rizaste: 'rizaste',
  rizavam: 'rizavam',
  rizavas: 'rizavas',
  rizecos: 'rizecos',
  rizemos: 'rizemos',
  rizidio: 'rizídio',
  rizinas: 'rizinas',
  rizinos: 'rizinos',
  rizobia: 'rizóbia',
  rizobio: 'rizóbio',
  rizodos: 'rizodos',
  rizoeco: 'rizoeco',
  rizoide: 'rizoide',
  rizomas: 'rizomas',
  rizomio: 'rizômio',
  rizomis: 'rizomis',
  rizopos: 'rizopos',
  rizotas: 'rizotas',
  rizotos: 'rizotos',
  rizulas: 'rízulas',
  roadies: 'roadies',
  roalzao: 'roalzão',
  roanias: 'roânias',
  robafos: 'robafos',
  robalao: 'robalão',
  robalos: 'robalos',
  robazes: 'robazes',
  robbias: 'róbbias',
  robecha: 'robechá',
  robeche: 'robeche',
  robecho: 'robecho',
  roberta: 'roberta',
  robinas: 'robinas',
  robinia: 'robínia',
  robledo: 'robledo',
  robooes: 'roboões',
  roborai: 'roborai',
  roboram: 'roboram',
  roborar: 'roborar',
  roboras: 'roboras',
  roborea: 'robórea',
  roborei: 'roborei',
  roborem: 'roborem',
  roboreo: 'robóreo',
  robores: 'robores',
  roborou: 'roborou',
  robusta: 'robusta',
  robusto: 'robusto',
  rocadao: 'roçadão',
  rocadas: 'roçadas',
  rocador: 'roçador',
  rocados: 'roçados',
  rocagai: 'roçagai',
  rocagam: 'roçagam',
  rocagar: 'roçagar',
  rocagas: 'roçagas',
  rocagem: 'roçagem',
  rocagou: 'roçagou',
  rocague: 'roçague',
  rocalha: 'rocalha',
  rocalhe: 'rocalhe',
  rocalho: 'rocalho',
  rocamos: 'roçamos',
  rocanas: 'roçanas',
  rocando: 'roçando',
  rocanso: 'rocanso',
  rocante: 'roçante',
  rocaram: 'roçaram',
  rocarao: 'roçarão',
  rocaras: 'roçarás',
  rocarei: 'roçarei',
  rocarem: 'roçarem',
  rocares: 'roçares',
  rocaria: 'roçaria',
  rocarmo: 'roçarmo',
  rocasse: 'roçasse',
  rocaste: 'roçaste',
  rocavam: 'roçavam',
  rocavas: 'roçavas',
  rocazes: 'rocazes',
  rocedao: 'rocedão',
  rocegai: 'rocegai',
  rocegam: 'rocegam',
  rocegar: 'rocegar',
  rocegas: 'rocegas',
  rocegou: 'rocegou',
  rocegue: 'rocegue',
  roceira: 'roceira',
  roceiro: 'roceiro',
  rocelas: 'rocelas',
  rocemos: 'rocemos',
  rocenas: 'rocenas',
  rocheas: 'rócheas',
  rochedo: 'rochedo',
  rocheos: 'rócheos',
  rochete: 'rochete',
  rochina: 'rochina',
  rochona: 'rochona',
  rochosa: 'rochosa',
  rochoso: 'rochoso',
  rociada: 'rociada',
  rociado: 'rociado',
  rociais: 'rociais',
  rociamo: 'rociamo',
  rociara: 'rociara',
  rociava: 'rociava',
  rocieis: 'rocieis',
  rociemo: 'rociemo',
  rocinai: 'rocinai',
  rocinal: 'rocinal',
  rocinam: 'rocinam',
  rocinar: 'rocinar',
  rocinas: 'rocinas',
  rocinei: 'rocinei',
  rocinem: 'rocinem',
  rocines: 'rocines',
  rocinha: 'rocinha',
  rocinou: 'rocinou',
  rociosa: 'rociosa',
  rocioso: 'rocioso',
  roclada: 'roclada',
  roclado: 'roclado',
  roclais: 'roclais',
  roclamo: 'roclamo',
  roclara: 'roclará',
  roclava: 'roclava',
  rocleis: 'rocleis',
  roclemo: 'roclemo',
  rocloro: 'rocloró',
  rococos: 'rococós',
  rodacea: 'rodácea',
  rodaceo: 'rodáceo',
  rodadao: 'rodadão',
  rodadas: 'rodadas',
  rodador: 'rodador',
  rodados: 'rodados',
  rodagem: 'rodagem',
  rodagio: 'rodágio',
  rodalho: 'rodalho',
  rodalia: 'rodália',
  rodamos: 'rodamos',
  rodando: 'rodando',
  rodanta: 'rodanta',
  rodante: 'rodante',
  rodapes: 'rodapés',
  rodaque: 'rodaque',
  rodaram: 'rodaram',
  rodarao: 'rodarão',
  rodaras: 'rodaras',
  rodarei: 'rodarei',
  rodarem: 'rodarem',
  rodares: 'rodares',
  rodaria: 'rodaria',
  rodarmo: 'rodarmo',
  rodasse: 'rodasse',
  rodaste: 'rodaste',
  rodatos: 'rodatos',
  rodavam: 'rodavam',
  rodavas: 'rodavas',
  rodavel: 'rodável',
  rodeada: 'rodeada',
  rodeado: 'rodeado',
  rodeais: 'rodeais',
  rodeamo: 'rodeamo',
  rodeara: 'rodeara',
  rodeava: 'rodeava',
  rodeeis: 'rodeeis',
  rodeemo: 'rodeemo',
  rodeiam: 'rodeiam',
  rodeias: 'rodeias',
  rodeiem: 'rodeiem',
  rodeies: 'rodeies',
  rodeios: 'rodeios',
  rodeira: 'rodeira',
  rodeiro: 'rodeiro',
  rodeite: 'rodeite',
  rodejai: 'rodejai',
  rodejam: 'rodejam',
  rodejar: 'rodejar',
  rodejas: 'rodejas',
  rodejei: 'rodejei',
  rodejem: 'rodejem',
  rodejes: 'rodejes',
  rodejou: 'rodejou',
  rodelas: 'rodelas',
  rodelha: 'rodelha',
  rodelos: 'rodelos',
  rodemos: 'rodemos',
  rodense: 'rodense',
  rodeois: 'rodeóis',
  rodeose: 'rodeose',
  rodetas: 'rodetas',
  rodetes: 'rodetes',
  rodiaca: 'rodíaca',
  rodiaco: 'rodíaco',
  rodicas: 'ródicas',
  rodicio: 'rodício',
  rodicos: 'ródicos',
  rodilha: 'rodilha',
  rodilhe: 'rodilhe',
  rodilho: 'rodilho',
  rodinal: 'rodinal',
  rodines: 'rodines',
  rodinha: 'rodinha',
  rodinol: 'rodinol',
  rodiola: 'rodíola',
  rodiolo: 'rodíolo',
  rodiota: 'rodiota',
  rodista: 'rodista',
  roditas: 'roditas',
  rodites: 'rodites',
  roditos: 'roditos',
  rodizia: 'rodízia',
  rodizie: 'rodizie',
  rodizio: 'rodízio',
  rodnios: 'ródnios',
  rodoica: 'rodoiça',
  rodoide: 'rodoide',
  rodoiro: 'rodoiro',
  rodolho: 'rodolho',
  rodolia: 'rodólia',
  rodomao: 'rodomão',
  rodomel: 'rodomel',
  rodonea: 'rodônea',
  rodoneo: 'rodôneo',
  rodopeu: 'rodopeu',
  rodopia: 'rodopia',
  rodopie: 'rodopie',
  rodopio: 'rodopio',
  rodopis: 'rodópis',
  rodoras: 'rodoras',
  rodouca: 'rodouça',
  rodovia: 'rodovia',
  rodriga: 'rodriga',
  rodrigo: 'rodrigo',
  roduras: 'roduras',
  roedora: 'roedora',
  roedura: 'roedura',
  roelzao: 'roelzão',
  roenita: 'roenita',
  roerdes: 'roerdes',
  roereis: 'roereis',
  roeremo: 'roeremo',
  roeriam: 'roeriam',
  roerias: 'roerias',
  roermos: 'roermos',
  roessem: 'roessem',
  roesses: 'roesses',
  roestes: 'roestes',
  rofegos: 'rofegos',
  rogacao: 'rogação',
  rogadas: 'rogadas',
  rogador: 'rogador',
  rogados: 'rogados',
  rogamos: 'rogamos',
  rogando: 'rogando',
  rogante: 'rogante',
  rogaram: 'rogaram',
  rogarao: 'rogarão',
  rogaras: 'rogaras',
  rogarei: 'rogarei',
  rogarem: 'rogarem',
  rogares: 'rogares',
  rogaria: 'rogaria',
  rogarmo: 'rogarmo',
  rogasse: 'rogasse',
  rogaste: 'rogaste',
  rogavam: 'rogavam',
  rogavas: 'rogavas',
  rogavel: 'rogável',
  rogeira: 'rogeira',
  rogeria: 'rogéria',
  rogiera: 'rogiera',
  rogueis: 'rogueis',
  roguemo: 'roguemo',
  rohaita: 'rohaíta',
  rohania: 'rohânia',
  roiamos: 'roíamos',
  roidoes: 'roidões',
  roidona: 'roidona',
  roidsia: 'roidsia',
  roienas: 'roienas',
  rojadao: 'rojadão',
  rojadas: 'rojadas',
  rojador: 'rojador',
  rojados: 'rojados',
  rojamos: 'rojamos',
  rojando: 'rojando',
  rojante: 'rojante',
  rojaram: 'rojaram',
  rojarao: 'rojarão',
  rojaras: 'rojarás',
  rojarei: 'rojarei',
  rojarem: 'rojarem',
  rojares: 'rojares',
  rojaria: 'rojaria',
  rojarmo: 'rojarmo',
  rojasia: 'rojásia',
  rojasse: 'rojasse',
  rojaste: 'rojaste',
  rojavam: 'rojavam',
  rojavas: 'rojavas',
  rojemos: 'rojemos',
  rojoada: 'rojoada',
  rojonea: 'rojoneá',
  rolacas: 'rolaças',
  roladao: 'roladão',
  roladas: 'roladas',
  rolador: 'rolador',
  rolados: 'rolados',
  rolagem: 'rolagem',
  rolamos: 'rolamos',
  rolanca: 'rolança',
  rolando: 'rolando',
  rolante: 'rolante',
  rolaram: 'rolaram',
  rolarao: 'rolarão',
  rolaras: 'rolaras',
  rolarei: 'rolarei',
  rolarem: 'rolarem',
  rolares: 'rolares',
  rolaria: 'rolaria',
  rolarmo: 'rolarmo',
  rolasse: 'rolasse',
  rolaste: 'rolaste',
  rolavam: 'rolavam',
  rolavas: 'rolavas',
  rolazes: 'rolazes',
  roldada: 'roldada',
  roldado: 'roldado',
  roldais: 'roldais',
  roldamo: 'roldamo',
  roldana: 'roldana',
  roldara: 'roldará',
  roldava: 'roldava',
  roldeai: 'roldeai',
  roldear: 'roldear',
  roldeei: 'roldeei',
  roldeia: 'roldeia',
  roldeie: 'roldeie',
  roldeio: 'roldeio',
  roldeis: 'roldeis',
  roldemo: 'roldemo',
  roldeou: 'roldeou',
  roldoes: 'roldões',
  roleira: 'roleira',
  roleiro: 'roleiro',
  rolemos: 'rolemos',
  roletai: 'roletai',
  roletam: 'roletam',
  roletar: 'roletar',
  roletas: 'roletas',
  roletea: 'roleteá',
  roletei: 'roletei',
  roletem: 'roletem',
  roletes: 'roletes',
  roletou: 'roletou',
  rolhada: 'rolhada',
  rolhado: 'rolhado',
  rolhais: 'rolhais',
  rolhamo: 'rolhamo',
  rolhara: 'rolhará',
  rolhava: 'rolhava',
  rolheis: 'rolheis',
  rolhemo: 'rolhemo',
  rolheta: 'rolheta',
  rolhete: 'rolhete',
  rolhoes: 'rolhões',
  rolicai: 'roliçai',
  rolicam: 'roliçam',
  rolicar: 'roliçar',
  rolicas: 'roliças',
  rolicei: 'rolicei',
  rolicem: 'rolicem',
  rolices: 'rolices',
  rolicos: 'roliços',
  rolicou: 'roliçou',
  rolimas: 'rolimãs',
  rolinha: 'rolinha',
  rolinho: 'rolinho',
  rolinia: 'rolínia',
  rolista: 'rolista',
  rolotes: 'rolotês',
  romadao: 'romadão',
  romadas: 'romadas',
  romador: 'romador',
  romados: 'romados',
  romagem: 'romagem',
  romaica: 'romaica',
  romaico: 'romaico',
  romalho: 'romalho',
  romamos: 'romamos',
  romanal: 'romanal',
  romanas: 'romanas',
  romanca: 'romança',
  romance: 'romance',
  romanco: 'romanço',
  romanda: 'romanda',
  romando: 'romando',
  romanea: 'romaneá',
  romanho: 'romanho',
  romania: 'românia',
  romanim: 'romanim',
  romanis: 'romanis',
  romanos: 'romanos',
  romante: 'romante',
  romanza: 'romanza',
  romaram: 'romaram',
  romarao: 'romarão',
  romaras: 'romaras',
  romarei: 'romarei',
  romarem: 'romarem',
  romares: 'romares',
  romaria: 'romaria',
  romaris: 'romaris',
  romarmo: 'romarmo',
  romasse: 'romasse',
  romaste: 'romaste',
  romavam: 'romavam',
  romavas: 'romavas',
  rombada: 'rombada',
  rombado: 'rombado',
  rombica: 'rômbica',
  rombico: 'rômbico',
  rombuda: 'rombuda',
  rombudo: 'rombudo',
  romecas: 'romecas',
  romecos: 'romecos',
  romeica: 'romeica',
  romeico: 'romeico',
  romeina: 'romeína',
  romeira: 'romeira',
  romeiro: 'romeiro',
  romeita: 'romeíta',
  romeite: 'romeíte',
  romeito: 'romeíto',
  romelas: 'romelas',
  romelia: 'romélia',
  romelio: 'romélio',
  romemos: 'romemos',
  romenas: 'romenas',
  romenho: 'romenho',
  romenos: 'romenos',
  romense: 'romense',
  rometal: 'rometal',
  romites: 'romites',
  romneia: 'romneia',
  romonia: 'romonia',
  rompais: 'rompais',
  rompamo: 'rompamo',
  rompeis: 'rompeis',
  rompemo: 'rompemo',
  rompera: 'rompera',
  rompiam: 'rompiam',
  rompias: 'rompias',
  rompida: 'rompida',
  rompido: 'rompido',
  rompoes: 'rompões',
  romular: 'romular',
  romulea: 'romúlea',
  ronabea: 'ronábea',
  roncada: 'roncada',
  roncado: 'roncado',
  roncais: 'roncais',
  roncamo: 'roncamo',
  roncara: 'roncara',
  roncava: 'roncava',
  ronceai: 'ronceai',
  roncear: 'roncear',
  ronceei: 'ronceei',
  ronceia: 'ronceia',
  ronceie: 'ronceie',
  ronceio: 'ronceio',
  ronceou: 'ronceou',
  ronchai: 'ronchai',
  roncham: 'roncham',
  ronchar: 'ronchar',
  ronchas: 'ronchas',
  ronchei: 'ronchei',
  ronchem: 'ronchem',
  ronches: 'ronches',
  ronchou: 'ronchou',
  roncice: 'roncice',
  roncoes: 'roncões',
  rondada: 'rondada',
  rondado: 'rondado',
  rondais: 'rondais',
  rondamo: 'rondamo',
  rondara: 'rondara',
  rondava: 'rondava',
  rondeai: 'rondeai',
  rondear: 'rondear',
  rondeei: 'rondeei',
  rondeia: 'rondeia',
  rondeie: 'rondeie',
  rondeio: 'rondeio',
  rondeis: 'rondeis',
  rondeme: 'rondeme',
  rondemo: 'rondemo',
  rondeou: 'rondeou',
  rondoes: 'rondões',
  rondola: 'rondola',
  rondons: 'rondons',
  ronflai: 'ronflai',
  ronflam: 'ronflam',
  ronflar: 'ronflar',
  ronflas: 'ronflas',
  ronflei: 'ronflei',
  ronflem: 'ronflem',
  ronfles: 'ronfles',
  ronflou: 'ronflou',
  ronhice: 'ronhice',
  ronhosa: 'ronhosa',
  ronhoso: 'ronhoso',
  ronhura: 'ronhura',
  ronquea: 'ronqueá',
  ronquei: 'ronquei',
  ronquem: 'ronquém',
  ronques: 'ronques',
  ronrona: 'ronrona',
  ronrone: 'ronrone',
  ronrono: 'ronrono',
  ronrons: 'ronrons',
  ropalas: 'rópalas',
  ropalio: 'ropálio',
  ropalos: 'rópalos',
  roptria: 'róptria',
  roqueas: 'roqueás',
  roqueia: 'roqueia',
  roqueis: 'roqueis',
  roquelo: 'roqueló',
  roquemo: 'roquemo',
  roqueta: 'roqueta',
  roquete: 'roquete',
  roradao: 'roradão',
  roradas: 'roradas',
  rorador: 'rorador',
  rorados: 'rorados',
  roramos: 'roramos',
  rorando: 'rorando',
  rorante: 'rorante',
  roraram: 'roraram',
  rorarao: 'rorarão',
  roraras: 'rorarás',
  rorarei: 'rorarei',
  rorarem: 'rorarem',
  rorares: 'rorares',
  roraria: 'roraria',
  rorario: 'rorário',
  rorarmo: 'rorarmo',
  rorasse: 'rorasse',
  roraste: 'roraste',
  roravam: 'roravam',
  roravas: 'roravas',
  rorejai: 'rorejai',
  rorejam: 'rorejam',
  rorejar: 'rorejar',
  rorejas: 'rorejas',
  rorejei: 'rorejei',
  rorejem: 'rorejem',
  rorejes: 'rorejes',
  rorejou: 'rorejou',
  rorelas: 'rorelas',
  roremos: 'roremos',
  roricas: 'róricas',
  roricos: 'róricos',
  roridas: 'róridas',
  roridos: 'róridos',
  roripas: 'roripas',
  rorqual: 'rorqual',
  rosacas: 'rosaças',
  rosacea: 'rosácea',
  rosaceo: 'rosáceo',
  rosadao: 'rosadão',
  rosadas: 'rosadas',
  rosador: 'rosador',
  rosados: 'rosados',
  rosairo: 'rosairo',
  rosales: 'rosales',
  rosalia: 'rosália',
  rosalva: 'rosalva',
  rosalvo: 'rosalvo',
  rosamos: 'rosamos',
  rosando: 'rosando',
  rosante: 'rosante',
  rosaram: 'rosaram',
  rosarao: 'rosarão',
  rosaras: 'rosarás',
  rosarei: 'rosarei',
  rosarem: 'rosarem',
  rosares: 'rosares',
  rosaria: 'rosaria',
  rosario: 'rosário',
  rosarmo: 'rosarmo',
  rosasse: 'rosasse',
  rosaste: 'rosaste',
  rosatos: 'rosatos',
  rosavam: 'rosavam',
  rosavas: 'rosavas',
  rosbife: 'rosbife',
  roscada: 'roscada',
  roscado: 'roscado',
  roscais: 'roscais',
  roscamo: 'roscamo',
  roscara: 'roscara',
  roscava: 'roscava',
  rosceas: 'rósceas',
  roscida: 'róscida',
  roscido: 'róscido',
  roscofe: 'roscofe',
  roseada: 'roseada',
  roseado: 'roseado',
  roseais: 'roseais',
  roseara: 'roseara',
  roseava: 'roseava',
  roseeis: 'roseeis',
  roseiam: 'roseiam',
  roseias: 'roseias',
  roseiem: 'roseiem',
  roseies: 'roseies',
  roseina: 'roseína',
  roseira: 'roseira',
  roseiro: 'roseiro',
  roselas: 'roselas',
  roselha: 'roselha',
  roselia: 'rosélia',
  rosemas: 'rosemas',
  rosemos: 'rosemos',
  rosenia: 'rosênia',
  rosense: 'rosense',
  rosente: 'rosente',
  roseola: 'roséola',
  rosetai: 'rosetai',
  rosetam: 'rosetam',
  rosetao: 'rosetão',
  rosetar: 'rosetar',
  rosetas: 'rosetas',
  rosetea: 'roseteá',
  rosetei: 'rosetei',
  rosetem: 'rosetem',
  rosetes: 'rosetes',
  rosetou: 'rosetou',
  rosiana: 'rosiana',
  rosiano: 'rosiano',
  rosicre: 'rosicré',
  rosilha: 'rosilha',
  rosilho: 'rosilho',
  rosinas: 'rosinas',
  rosinea: 'rosínea',
  rosinha: 'rosinha',
  rosinol: 'rosinol',
  rosismo: 'rosismo',
  rosista: 'rosista',
  rositas: 'rositas',
  rosites: 'rosites',
  rositos: 'rositos',
  rosmana: 'rosmana',
  rosmano: 'rosmano',
  rosmaro: 'rosmaro',
  rosmeai: 'rosmeai',
  rosmear: 'rosmear',
  rosmeei: 'rosmeei',
  rosmeia: 'rosmeia',
  rosmeie: 'rosmeie',
  rosmeio: 'rosmeio',
  rosmeou: 'rosmeou',
  rosnada: 'rosnada',
  rosnado: 'rosnado',
  rosnais: 'rosnais',
  rosnamo: 'rosnamo',
  rosnara: 'rosnara',
  rosnava: 'rosnava',
  rosneis: 'rosneis',
  rosnemo: 'rosnemo',
  rosnido: 'rosnido',
  rosnora: 'rosnorá',
  rosnore: 'rosnore',
  rosnoro: 'rosnoro',
  rosolio: 'rosólio',
  rosquea: 'rosqueá',
  rosquei: 'rosquei',
  rosquem: 'rosquem',
  rosques: 'rosques',
  rossela: 'rossela',
  rossias: 'róssias',
  rossios: 'rossios',
  rossita: 'rossita',
  rostais: 'rostais',
  rostamo: 'rostamo',
  rosteai: 'rosteai',
  rostear: 'rostear',
  rosteei: 'rosteei',
  rosteia: 'rosteia',
  rosteie: 'rosteie',
  rosteio: 'rosteio',
  rostelo: 'rostelo',
  rosteou: 'rosteou',
  rostiam: 'rostiam',
  rostias: 'rostias',
  rostida: 'rostida',
  rostido: 'rostido',
  rostimo: 'rostimo',
  rostira: 'rostirá',
  rostoes: 'rostões',
  rostral: 'rostral',
  rostros: 'rostros',
  rostrum: 'rostrum',
  rosulho: 'rosulho',
  rosuras: 'rosuras',
  rotacao: 'rotação',
  rotacea: 'rotácea',
  rotaceo: 'rotáceo',
  rotadao: 'rotadão',
  rotadas: 'rotadas',
  rotador: 'rotador',
  rotados: 'rotados',
  rotalas: 'rotalas',
  rotalia: 'rotália',
  rotamos: 'rotamos',
  rotando: 'rotando',
  rotante: 'rotante',
  rotaram: 'rotaram',
  rotarao: 'rotarão',
  rotaras: 'rotarás',
  rotarei: 'rotarei',
  rotarem: 'rotarem',
  rotares: 'rotares',
  rotaria: 'rotaria',
  rotario: 'rotário',
  rotarmo: 'rotarmo',
  rotasse: 'rotasse',
  rotaste: 'rotaste',
  rotavam: 'rotavam',
  rotavas: 'rotavas',
  roteada: 'roteada',
  roteado: 'roteado',
  roteais: 'roteais',
  roteamo: 'roteamo',
  roteara: 'roteara',
  roteava: 'roteava',
  roteeis: 'roteeis',
  roteemo: 'roteemo',
  roteiam: 'roteiam',
  roteias: 'roteias',
  roteiem: 'roteiem',
  roteies: 'roteies',
  roteira: 'roteira',
  roteiro: 'roteiro',
  rotejai: 'rotejai',
  rotejam: 'rotejam',
  rotejar: 'rotejar',
  rotejas: 'rotejas',
  rotejei: 'rotejei',
  rotejem: 'rotejem',
  rotejes: 'rotejes',
  rotejou: 'rotejou',
  rotelas: 'rotelas',
  rotemos: 'rotemos',
  rotfone: 'rotfone',
  rotinas: 'rotinas',
  rotinho: 'rotinho',
  rotlera: 'rotlera',
  rotoina: 'rotoína',
  rotolos: 'rótolos',
  rotores: 'rotores',
  rotoria: 'rotoria',
  rotulai: 'rotulai',
  rotulam: 'rotulam',
  rotular: 'rotular',
  rotulas: 'rotulas',
  rotulei: 'rotulei',
  rotulem: 'rotulem',
  rotules: 'rotules',
  rotulos: 'rótulos',
  rotulou: 'rotulou',
  rotunda: 'rotunda',
  rotundo: 'rotundo',
  roturas: 'roturas',
  roubada: 'roubada',
  roubado: 'roubado',
  roubais: 'roubais',
  roubamo: 'roubamo',
  roubara: 'roubara',
  roubava: 'roubava',
  roubeis: 'roubeis',
  roubemo: 'roubemo',
  roucada: 'rouçada',
  roucado: 'rouçado',
  roucais: 'rouçais',
  roucamo: 'rouçamo',
  roucara: 'rouçará',
  roucava: 'rouçava',
  rouceis: 'rouceis',
  roucemo: 'roucemo',
  roucoes: 'rouções',
  roucura: 'roucura',
  roupada: 'roupada',
  roupado: 'roupado',
  roupais: 'roupais',
  roupala: 'roupala',
  roupamo: 'roupamo',
  roupara: 'roupara',
  roupava: 'roupava',
  roupeis: 'roupeis',
  roupemo: 'roupemo',
  roupeta: 'roupeta',
  roupida: 'roupida',
  roupido: 'roupido',
  roupoes: 'roupões',
  roupuda: 'roupuda',
  roupudo: 'roupudo',
  roureas: 'rôureas',
  roussai: 'roussai',
  roussam: 'roussam',
  roussar: 'roussar',
  roussas: 'roussas',
  roussei: 'roussei',
  roussem: 'roussem',
  rousses: 'rousses',
  roussos: 'roussos',
  roussou: 'roussou',
  rouvais: 'rouvais',
  rovotes: 'rovotes',
  roxeada: 'roxeada',
  roxeado: 'roxeado',
  roxeais: 'roxeais',
  roxeara: 'roxeará',
  roxeava: 'roxeava',
  roxecre: 'roxecré',
  roxeeis: 'roxeeis',
  roxeiam: 'roxeiam',
  roxeias: 'roxeias',
  roxeiem: 'roxeiem',
  roxeies: 'roxeies',
  roxense: 'roxense',
  roxetas: 'roxetas',
  roxetes: 'roxetes',
  roxicre: 'roxicré',
  roxidao: 'roxidão',
  roxinha: 'roxinha',
  roxinho: 'roxinho',
  roxinol: 'roxinol',
  roxuras: 'roxuras',
  royalty: 'royalty',
  rozeimo: 'rozeimo',
  ruadoes: 'ruadões',
  ruadona: 'ruadona',
  ruadora: 'ruadora',
  rualzao: 'rualzão',
  ruamons: 'ruamons',
  ruandas: 'ruandas',
  ruandes: 'ruandês',
  ruantes: 'ruantes',
  ruaozao: 'ruãozão',
  ruardes: 'ruardes',
  ruareis: 'ruáreis',
  ruaremo: 'ruaremo',
  ruariam: 'ruariam',
  ruarias: 'ruarias',
  ruarmos: 'ruarmos',
  ruassem: 'ruassem',
  ruasses: 'ruasses',
  ruastes: 'ruastes',
  ruaveis: 'ruáveis',
  ruazona: 'ruãzona',
  rubacao: 'rubacão',
  rubafos: 'rubafos',
  rubagos: 'rubagos',
  rubalas: 'rubalas',
  rubanas: 'rubanas',
  rubanos: 'rubanos',
  rubasse: 'rubasse',
  rubejai: 'rubejai',
  rubejam: 'rubejam',
  rubejar: 'rubejar',
  rubejas: 'rubejas',
  rubejei: 'rubejei',
  rubejem: 'rubejem',
  rubejes: 'rubejes',
  rubejou: 'rubejou',
  rubelos: 'rubelos',
  rubenos: 'rubenos',
  rubente: 'rubente',
  rubeola: 'rubéola',
  rubesca: 'rubesça',
  rubesce: 'rubesce',
  rubesci: 'rubesci',
  rubesco: 'rubesço',
  rubetas: 'rubetas',
  rubiais: 'rubiais',
  rubiale: 'rubiale',
  rubicao: 'rubicão',
  rubicas: 'rubicãs',
  rubidas: 'rúbidas',
  rubidez: 'rubidez',
  rubidio: 'rubídio',
  rubidos: 'rúbidos',
  rubieva: 'rubieva',
  rubigal: 'rubigal',
  rubigem: 'rubigem',
  rubinas: 'rubinas',
  rubinia: 'rubínia',
  rubioes: 'rubiões',
  rubisca: 'rubisca',
  rubisco: 'rubisco',
  rubixas: 'rubixás',
  rubores: 'rubores',
  rubreno: 'rubreno',
  rubrica: 'rubrica',
  rubrico: 'rubrico',
  rubrita: 'rubrita',
  rubrite: 'rubrite',
  rubriza: 'rubriza',
  rubrize: 'rubrize',
  rubrizo: 'rubrizo',
  rucadao: 'ruçadão',
  rucadas: 'ruçadas',
  rucador: 'ruçador',
  rucados: 'ruçados',
  rucamos: 'ruçamos',
  rucando: 'ruçando',
  rucante: 'ruçante',
  rucaram: 'ruçaram',
  rucarao: 'ruçarão',
  rucaras: 'ruçarás',
  rucarei: 'ruçarei',
  rucarem: 'ruçarem',
  rucares: 'ruçares',
  rucaria: 'ruçaria',
  rucarmo: 'ruçarmo',
  rucasse: 'ruçasse',
  rucaste: 'ruçaste',
  rucavam: 'ruçavam',
  rucavas: 'ruçavas',
  rucegas: 'rucegas',
  rucemos: 'rucemos',
  rucetes: 'rucetes',
  rucilha: 'rucilha',
  rucilho: 'rucilho',
  rucolas: 'rúcolas',
  ruculas: 'rúculas',
  rucumbo: 'rucumbo',
  rucungo: 'rucungo',
  rucuris: 'rucuris',
  rudacea: 'rudácea',
  rudaceo: 'rudáceo',
  ruderal: 'ruderal',
  rudezas: 'rudezas',
  rudezes: 'rudezes',
  rudgeas: 'rúdgeas',
  rudinha: 'rudinha',
  rudinho: 'rudinho',
  rudista: 'rudista',
  ruditas: 'ruditas',
  rudites: 'rudites',
  ruditos: 'ruditos',
  rudraxa: 'rudraxa',
  rueiras: 'rueiras',
  rueiros: 'rueiros',
  ruelias: 'ruélias',
  rueliea: 'ruelíea',
  ruellia: 'ruéllia',
  ruelona: 'ruelona',
  rufadao: 'rufadão',
  rufadas: 'rufadas',
  rufador: 'rufador',
  rufados: 'rufados',
  rufamos: 'rufamos',
  rufando: 'rufando',
  rufante: 'rufante',
  rufaram: 'rufaram',
  rufarao: 'rufarão',
  rufaras: 'rufaras',
  rufarei: 'rufarei',
  rufarem: 'rufarem',
  rufares: 'rufares',
  rufaria: 'rufaria',
  rufarmo: 'rufarmo',
  rufasse: 'rufasse',
  rufaste: 'rufaste',
  rufavam: 'rufavam',
  rufavas: 'rufavas',
  rufemos: 'rufemos',
  rufiada: 'rufiada',
  rufiado: 'rufiado',
  rufiais: 'rufiais',
  rufiamo: 'rufiamo',
  rufiara: 'rufiara',
  rufiava: 'rufiava',
  rufieis: 'rufieis',
  rufiemo: 'rufiemo',
  rufinas: 'rufinas',
  rufioes: 'rufiões',
  rufiona: 'rufiona',
  rufista: 'rufista',
  ruflada: 'ruflada',
  ruflado: 'ruflado',
  ruflais: 'ruflais',
  ruflamo: 'ruflamo',
  ruflara: 'ruflará',
  ruflava: 'ruflava',
  rufleis: 'rufleis',
  ruflemo: 'ruflemo',
  rugadao: 'rugadão',
  rugadas: 'rugadas',
  rugador: 'rugador',
  rugados: 'rugados',
  rugamos: 'rugamos',
  rugando: 'rugando',
  rugante: 'rugante',
  rugaram: 'rugaram',
  rugarao: 'rugarão',
  rugaras: 'rugaras',
  rugarei: 'rugarei',
  rugarem: 'rugarem',
  rugares: 'rugares',
  rugaria: 'rugaria',
  rugarmo: 'rugarmo',
  rugasse: 'rugasse',
  rugaste: 'rugaste',
  rugavam: 'rugavam',
  rugavas: 'rugavas',
  rugedor: 'rugedor',
  rugente: 'rugente',
  rugibos: 'rugibós',
  rugidao: 'rugidão',
  rugidas: 'rugidas',
  rugidor: 'rugidor',
  rugidos: 'rugidos',
  rugieis: 'rugíeis',
  rugimos: 'rugimos',
  ruginai: 'ruginai',
  ruginam: 'ruginam',
  ruginar: 'ruginar',
  ruginas: 'ruginas',
  rugindo: 'rugindo',
  ruginei: 'ruginei',
  ruginem: 'ruginem',
  rugines: 'rugines',
  ruginou: 'ruginou',
  rugiram: 'rugiram',
  rugirao: 'rugirão',
  rugiras: 'rugiras',
  rugirei: 'rugirei',
  rugirem: 'rugirem',
  rugires: 'rugires',
  rugiria: 'rugiria',
  rugirmo: 'rugirmo',
  rugisse: 'rugisse',
  rugiste: 'rugiste',
  rugitai: 'rugitai',
  rugitam: 'rugitam',
  rugitar: 'rugitar',
  rugitas: 'rugitas',
  rugitei: 'rugitei',
  rugitem: 'rugitem',
  rugites: 'rugites',
  rugitou: 'rugitou',
  rugosas: 'rugosas',
  rugosos: 'rugosos',
  rugueis: 'rugueis',
  ruguemo: 'ruguemo',
  rugusca: 'rugusca',
  rugusco: 'rugusco',
  ruiamos: 'ruíamos',
  ruianas: 'ruianas',
  ruianos: 'ruianos',
  ruidada: 'ruidada',
  ruidado: 'ruidado',
  ruidais: 'ruidais',
  ruidamo: 'ruidamo',
  ruidara: 'ruidará',
  ruidava: 'ruidava',
  ruideis: 'ruideis',
  ruidemo: 'ruidemo',
  ruidoes: 'ruidões',
  ruidona: 'ruidona',
  ruidora: 'ruidora',
  ruidosa: 'ruidosa',
  ruidoso: 'ruidoso',
  ruinada: 'ruinada',
  ruinado: 'ruinado',
  ruinhas: 'ruinhas',
  ruinosa: 'ruinosa',
  ruinoso: 'ruinoso',
  ruinzao: 'ruinzão',
  ruirdes: 'ruirdes',
  ruireis: 'ruireis',
  ruiremo: 'ruiremo',
  ruiriam: 'ruiriam',
  ruirias: 'ruirias',
  ruirmos: 'ruirmos',
  ruissem: 'ruíssem',
  ruisses: 'ruísses',
  ruistes: 'ruístes',
  ruivaca: 'ruivaca',
  ruivaco: 'ruivaco',
  ruivais: 'ruivais',
  ruivana: 'ruivana',
  ruivano: 'ruivano',
  ruivica: 'rúivica',
  ruivico: 'rúivico',
  ruivoes: 'ruivões',
  ruivona: 'ruivona',
  ruivote: 'ruivote',
  ruizita: 'ruizita',
  rujamos: 'rujamos',
  ruladao: 'ruladão',
  ruladas: 'ruladas',
  rulador: 'rulador',
  rulados: 'rulados',
  rulamos: 'rulamos',
  rulando: 'rulando',
  rulante: 'rulante',
  rularam: 'rularam',
  rularao: 'rularão',
  rularas: 'rularás',
  rularei: 'rularei',
  rularem: 'rularem',
  rulares: 'rulares',
  rularia: 'rularia',
  rularmo: 'rularmo',
  rulasse: 'rulasse',
  rulaste: 'rulaste',
  rulavam: 'rulavam',
  rulavas: 'rulavas',
  rulemos: 'rulemos',
  rulengo: 'rulengo',
  rulhada: 'rulhada',
  rulhado: 'rulhado',
  rulhais: 'rulhais',
  rulhamo: 'rulhamo',
  rulhara: 'rulhará',
  rulhava: 'rulhava',
  rulheis: 'rulheis',
  rulhemo: 'rulhemo',
  rulotes: 'rulotes',
  rumacao: 'rumação',
  rumadao: 'rumadão',
  rumadas: 'rumadas',
  rumador: 'rumador',
  rumados: 'rumados',
  rumamos: 'rumamos',
  rumando: 'rumando',
  rumante: 'rumante',
  rumaram: 'rumaram',
  rumarao: 'rumarão',
  rumaras: 'rumaras',
  rumarei: 'rumarei',
  rumarem: 'rumarem',
  rumares: 'rumares',
  rumaria: 'rumaria',
  rumarmo: 'rumarmo',
  rumasse: 'rumasse',
  rumaste: 'rumaste',
  rumavam: 'rumavam',
  rumavas: 'rumavas',
  rumbada: 'rumbada',
  rumbado: 'rumbado',
  rumbais: 'rumbais',
  rumbamo: 'rumbamo',
  rumbara: 'rumbara',
  rumbava: 'rumbava',
  rumbeai: 'rumbeai',
  rumbear: 'rumbear',
  rumbeei: 'rumbeei',
  rumbeia: 'rumbeia',
  rumbeie: 'rumbeie',
  rumbeio: 'rumbeio',
  rumbeis: 'rumbeis',
  rumbemo: 'rumbemo',
  rumbeou: 'rumbeou',
  rumbias: 'rúmbias',
  rumbodo: 'rumbodo',
  rumemos: 'rumemos',
  rumeras: 'rumeras',
  rumeros: 'rumeros',
  rumiada: 'rumiada',
  rumiado: 'rumiado',
  rumiais: 'rumiais',
  rumiamo: 'rumiamo',
  rumiara: 'rumiará',
  rumiava: 'rumiava',
  rumicea: 'rumícea',
  rumieis: 'rumieis',
  rumiemo: 'rumiemo',
  ruminai: 'ruminai',
  ruminal: 'ruminal',
  ruminam: 'ruminam',
  ruminar: 'ruminar',
  ruminas: 'ruminas',
  ruminei: 'ruminei',
  ruminem: 'ruminem',
  rumines: 'rumines',
  ruminho: 'ruminho',
  ruminos: 'rúminos',
  ruminou: 'ruminou',
  rumorai: 'rumorai',
  rumoram: 'rumoram',
  rumorar: 'rumorar',
  rumoras: 'rumoras',
  rumorei: 'rumorei',
  rumorem: 'rumorem',
  rumores: 'rumores',
  rumorio: 'rumorio',
  rumorou: 'rumorou',
  rumpris: 'rumpris',
  runcina: 'runcina',
  rundemo: 'rundemo',
  runfada: 'runfada',
  runfado: 'runfado',
  runfais: 'runfais',
  runfamo: 'runfamo',
  runfara: 'runfará',
  runfava: 'runfava',
  runfeis: 'runfeis',
  runfemo: 'runfemo',
  runfoes: 'runfões',
  runhada: 'runhada',
  runhado: 'runhado',
  runhais: 'runhais',
  runhamo: 'runhamo',
  runhara: 'runhará',
  runhava: 'runhava',
  runheis: 'runheis',
  runhemo: 'runhemo',
  runibia: 'runíbia',
  runicas: 'rúnicas',
  runicos: 'rúnicos',
  runimol: 'runimol',
  runjebe: 'runjebe',
  runjefe: 'runjefe',
  runruns: 'runruns',
  ruolzes: 'ruolzes',
  rupadao: 'rupadão',
  rupadas: 'rupadas',
  rupador: 'rupador',
  rupados: 'rupados',
  rupalia: 'rupália',
  rupamos: 'rupamos',
  rupando: 'rupando',
  rupante: 'rupante',
  ruparam: 'ruparam',
  ruparao: 'ruparão',
  ruparas: 'ruparas',
  ruparei: 'ruparei',
  ruparem: 'ruparem',
  rupares: 'rupares',
  ruparia: 'ruparia',
  ruparmo: 'ruparmo',
  rupasse: 'rupasse',
  rupaste: 'rupaste',
  rupavam: 'rupavam',
  rupavas: 'rupavas',
  rupelas: 'rupelas',
  rupelia: 'rupélia',
  rupemos: 'rupemos',
  rupiada: 'rupiada',
  rupinia: 'rupínia',
  rupipas: 'rupipas',
  ruppias: 'rúppias',
  rupteis: 'rúpteis',
  ruptora: 'ruptora',
  ruptura: 'ruptura',
  ruquibo: 'ruquibo',
  rurbana: 'rurbana',
  rurbano: 'rurbano',
  ruridas: 'rúridas',
  ruselia: 'rusélia',
  rusgada: 'rusgada',
  rusgado: 'rusgado',
  rusgais: 'rusgais',
  rusgara: 'rusgara',
  rusgata: 'rusgata',
  rusgava: 'rusgava',
  rusguei: 'rusguei',
  rusguem: 'rusguem',
  rusgues: 'rusgues',
  rusinas: 'rusinas',
  ruspone: 'ruspone',
  russada: 'russada',
  russado: 'russado',
  russais: 'russais',
  russamo: 'russamo',
  russana: 'russana',
  russano: 'russano',
  russara: 'russará',
  russava: 'russava',
  russeis: 'russeis',
  russemo: 'russemo',
  russios: 'rússios',
  russiza: 'russizá',
  russize: 'russize',
  russizo: 'russizo',
  russula: 'rússula',
  rustais: 'rustais',
  rustamo: 'rustamo',
  rustiam: 'rustiam',
  rustias: 'rustias',
  rustica: 'rústica',
  rustico: 'rústico',
  rustida: 'rustida',
  rustido: 'rustido',
  rustilo: 'rustilo',
  rustimo: 'rustimo',
  rustira: 'rustirá',
  rutacea: 'rutácea',
  rutaceo: 'rutáceo',
  rutadao: 'rutadão',
  rutadas: 'rutadas',
  rutador: 'rutador',
  rutados: 'rutados',
  rutales: 'rutales',
  rutamos: 'rutamos',
  rutando: 'rutando',
  rutante: 'rutante',
  rutaram: 'rutaram',
  rutarao: 'rutarão',
  rutaras: 'rutarás',
  rutarea: 'rutárea',
  rutarei: 'rutarei',
  rutarem: 'rutarem',
  rutareo: 'rutáreo',
  rutares: 'rutares',
  rutaria: 'rutaria',
  rutarmo: 'rutarmo',
  rutasse: 'rutasse',
  rutaste: 'rutaste',
  rutavam: 'rutavam',
  rutavas: 'rutavas',
  rutelas: 'rutelas',
  rutemos: 'rutemos',
  rutenas: 'rutenas',
  rutenio: 'rutênio',
  rutenos: 'rutenos',
  ruticas: 'rúticas',
  ruticos: 'rúticos',
  rutidea: 'rutídea',
  rutilai: 'rutilai',
  rutilam: 'rutilam',
  rutilar: 'rutilar',
  rutilas: 'rutilas',
  rutilei: 'rutilei',
  rutilem: 'rutilem',
  rutiles: 'rutiles',
  rutilia: 'rutília',
  rutilio: 'rutílio',
  rutilos: 'rútilos',
  rutilou: 'rutilou',
  rutinas: 'rutinas',
  rutonal: 'rutonal',
  rutubas: 'rutubas',
  rutulas: 'rútulas',
  rutulos: 'rútulos',
  ruturas: 'ruturas',
  ruvetos: 'ruvetos',
  ruvinha: 'ruvinha',
  ruxaxas: 'ruxaxás',
  ruxoxos: 'ruxoxós',
  ruzagas: 'ruzagás',
  règuate: 'règuatê',
  röntgen: 'röntgen',
  saamona: 'saamona',
  saaraui: 'saaraui',
  saarica: 'saárica',
  saarico: 'saárico',
  sabacia: 'sabácia',
  sabacon: 'sabácon',
  sabacus: 'sabacus',
  sabadea: 'sabadeá',
  sabadim: 'sabadim',
  sabados: 'sábados',
  sabaios: 'sabaios',
  sabaita: 'sabaíta',
  sabajos: 'sabajos',
  sabalea: 'sabálea',
  sabanas: 'sábanas',
  sabanga: 'sabanga',
  sabanos: 'sábanos',
  sabaque: 'sabaque',
  sabasto: 'sabasto',
  sabatia: 'sabátia',
  sabavel: 'sabável',
  sabazia: 'sabázia',
  sabeana: 'sabeana',
  sabeano: 'sabeano',
  sabedor: 'sabedor',
  sabeias: 'sabeias',
  sabeita: 'sabeíta',
  sabelas: 'sabelas',
  sabelha: 'sabelha',
  sabelio: 'sabélio',
  sabelos: 'sabelos',
  sabemos: 'sabemos',
  sabenas: 'sábenas',
  sabenca: 'sabença',
  sabendo: 'sabendo',
  sabente: 'sabente',
  saberao: 'saberão',
  saberas: 'saberás',
  saberei: 'saberei',
  saberem: 'saberem',
  saberes: 'saberes',
  saberia: 'saberia',
  sabetos: 'sabetos',
  sabiabe: 'sabiabe',
  sabiaci: 'sabiaci',
  sabicea: 'sabícea',
  sabicha: 'sabichã',
  sabiche: 'sabiche',
  sabicho: 'sabicho',
  sabicus: 'sabicus',
  sabidao: 'sabidão',
  sabidas: 'sabidas',
  sabidos: 'sabidos',
  sabieis: 'sabíeis',
  sabieno: 'sabieno',
  sabinas: 'sabinas',
  sabinea: 'sabínea',
  sabinia: 'sabínia',
  sabinol: 'sabinol',
  sabinos: 'sabinos',
  sabires: 'sabires',
  sabismo: 'sabismo',
  sabitus: 'sabitus',
  sabiuna: 'sabiúna',
  sabivel: 'sabível',
  sabogas: 'sabogas',
  saboias: 'saboias',
  sabongo: 'sabongo',
  saboras: 'saborás',
  saborea: 'saboreá',
  sabores: 'sabores',
  saborra: 'saborra',
  sabotai: 'sabotai',
  sabotam: 'sabotam',
  sabotar: 'sabotar',
  sabotas: 'sabotas',
  sabotei: 'sabotei',
  sabotem: 'sabotem',
  sabotes: 'sabotes',
  sabotou: 'sabotou',
  sabraca: 'sabraca',
  sabraco: 'sabraço',
  sabrada: 'sabrada',
  sabreca: 'sabreca',
  sabreco: 'sabreco',
  sabroso: 'sabroso',
  sabucai: 'sabucai',
  sabucam: 'sabucam',
  sabucar: 'sabucar',
  sabucas: 'sabucas',
  sabucou: 'sabucou',
  sabucus: 'sabucus',
  sabugai: 'sabugai',
  sabugal: 'sabugal',
  sabugam: 'sabugam',
  sabugar: 'sabugar',
  sabugas: 'sabugas',
  sabugos: 'sabugos',
  sabugou: 'sabugou',
  sabugue: 'sabugue',
  sabuias: 'sabuiás',
  sabujai: 'sabujai',
  sabujam: 'sabujam',
  sabujar: 'sabujar',
  sabujas: 'sabujas',
  sabujei: 'sabujei',
  sabujem: 'sabujem',
  sabujes: 'sabujes',
  sabujos: 'sabujos',
  sabujou: 'sabujou',
  sabulai: 'sabulai',
  sabulam: 'sabulam',
  sabular: 'sabular',
  sabulas: 'sabulas',
  sabulei: 'sabulei',
  sabulem: 'sabulem',
  sabules: 'sabules',
  sabulou: 'sabulou',
  sabuque: 'sabuque',
  saburas: 'saburás',
  saburra: 'saburra',
  saburre: 'saburre',
  saburro: 'saburro',
  saburus: 'saburus',
  sacacao: 'sacação',
  sacacas: 'sacacas',
  sacadao: 'sacadão',
  sacadas: 'sacadas',
  sacador: 'sacador',
  sacados: 'sacados',
  sacaias: 'sacaias',
  sacaios: 'sacaios',
  sacaita: 'sacaíta',
  sacalao: 'sacalão',
  sacalho: 'sacalho',
  sacambu: 'sacambu',
  sacamos: 'sacamos',
  sacanas: 'sacanas',
  sacando: 'sacando',
  sacanea: 'sacaneá',
  sacanga: 'sacanga',
  sacante: 'sacante',
  sacaram: 'sacaram',
  sacarao: 'sacarão',
  sacaras: 'sacaras',
  sacarei: 'sacarei',
  sacarem: 'sacarem',
  sacares: 'sacares',
  sacaria: 'sacaria',
  sacario: 'sacário',
  sacarmo: 'sacarmo',
  sacarol: 'sacarol',
  sacaros: 'sácaros',
  sacasse: 'sacasse',
  sacaste: 'sacaste',
  sacatea: 'sacateá',
  sacavam: 'sacavam',
  sacavas: 'sacavas',
  sacelos: 'sacelos',
  sacenas: 'sacenas',
  sachada: 'sachada',
  sachado: 'sachado',
  sachais: 'sachais',
  sachamo: 'sachamo',
  sachara: 'sachará',
  sachava: 'sachava',
  sacheis: 'sacheis',
  sachemo: 'sachemo',
  sachens: 'sachéns',
  sachoes: 'sachões',
  sachola: 'sachola',
  sachole: 'sachole',
  sacholo: 'sacholo',
  saciada: 'saciada',
  saciado: 'saciado',
  saciais: 'saciais',
  saciamo: 'saciamo',
  saciara: 'saciara',
  saciava: 'saciava',
  sacieis: 'sacieis',
  saciemo: 'saciemo',
  sacocas: 'sacocas',
  sacodem: 'sacodem',
  sacodes: 'sacodes',
  sacodio: 'sacódio',
  sacolai: 'sacolai',
  sacolam: 'sacolam',
  sacolao: 'sacolão',
  sacolar: 'sacolar',
  sacolas: 'sacolas',
  sacolei: 'sacolei',
  sacolem: 'sacolem',
  sacoles: 'sacoles',
  sacolou: 'sacolou',
  sacomao: 'sacomão',
  sacotea: 'sacoteá',
  sacotos: 'sacotos',
  sacovas: 'sacovás',
  sacrais: 'sacrais',
  sacrana: 'sacrana',
  sacrano: 'sacrano',
  sacudam: 'sacudam',
  sacudas: 'sacudas',
  sacudem: 'sacudem',
  sacudes: 'sacudes',
  sacudia: 'sacudia',
  sacudir: 'sacudir',
  sacudis: 'sacudis',
  sacudiu: 'sacudiu',
  sacudus: 'sacudus',
  sacuias: 'sacuias',
  saculai: 'saculai',
  saculam: 'saculam',
  sacular: 'sacular',
  saculas: 'saculas',
  saculei: 'saculei',
  saculem: 'saculem',
  sacules: 'sacules',
  saculos: 'sáculos',
  saculou: 'saculou',
  sacures: 'sacurês',
  sacureu: 'sacureu',
  sadacas: 'sadacas',
  sadense: 'sadense',
  sadicas: 'sádicas',
  sadicos: 'sádicos',
  sadidas: 'sádidas',
  sadinas: 'sadinas',
  sadinos: 'sadinos',
  sadismo: 'sadismo',
  sadista: 'sadista',
  saduceu: 'saduceu',
  saelina: 'saelina',
  saelino: 'saelino',
  saelzao: 'saelzão',
  saenses: 'saenses',
  safadao: 'safadão',
  safadas: 'safadas',
  safador: 'safador',
  safados: 'safados',
  safamos: 'safamos',
  safanai: 'safanai',
  safanam: 'safanam',
  safanao: 'safanão',
  safanar: 'safanar',
  safanas: 'safanas',
  safando: 'safando',
  safanei: 'safanei',
  safanem: 'safanem',
  safanes: 'safanes',
  safanos: 'safanos',
  safanou: 'safanou',
  safante: 'safante',
  safaram: 'safaram',
  safarao: 'safarão',
  safaras: 'safaras',
  safarei: 'safarei',
  safarem: 'safarem',
  safares: 'safares',
  safaria: 'safaria',
  safaris: 'safáris',
  safarmo: 'safarmo',
  safaros: 'sáfaros',
  safasse: 'safasse',
  safaste: 'safaste',
  safatas: 'safatas',
  safavam: 'safavam',
  safavas: 'safavas',
  safemos: 'safemos',
  safenas: 'safenas',
  safenos: 'safenos',
  saficas: 'sáficas',
  saficos: 'sáficos',
  safiras: 'safiras',
  safiros: 'safiros',
  safismo: 'safismo',
  safista: 'safista',
  saforil: 'saforil',
  saforis: 'saforis',
  safreja: 'safreja',
  safreje: 'safreje',
  safrejo: 'safrejo',
  safreno: 'safreno',
  safroes: 'safrões',
  safrois: 'safróis',
  safutas: 'safutas',
  sagacia: 'sagácia',
  saganha: 'saganha',
  saganho: 'saganho',
  sagazes: 'sagazes',
  sageira: 'sageira',
  sagenas: 'ságenas',
  sageria: 'sageria',
  sagezas: 'sagezas',
  sagezes: 'sagezes',
  saginai: 'saginai',
  saginam: 'saginam',
  saginar: 'saginar',
  saginas: 'saginas',
  saginei: 'saginei',
  saginem: 'saginem',
  sagines: 'sagines',
  saginou: 'saginou',
  sagitai: 'sagitai',
  sagital: 'sagital',
  sagitam: 'sagitam',
  sagitar: 'sagitar',
  sagitas: 'sagitas',
  sagitei: 'sagitei',
  sagitem: 'sagitem',
  sagites: 'sagites',
  sagitou: 'sagitou',
  sagocia: 'sagócia',
  sagonas: 'sagonas',
  sagonha: 'sagonha',
  sagonho: 'sagonho',
  sagorra: 'sagorrá',
  sagorre: 'sagorre',
  sagorro: 'sagorro',
  sagotai: 'sagotai',
  sagotam: 'sagotam',
  sagotar: 'sagotar',
  sagotas: 'sagotas',
  sagotei: 'sagotei',
  sagotem: 'sagotem',
  sagotes: 'sagotes',
  sagotou: 'sagotou',
  sagrada: 'sagrada',
  sagrado: 'sagrado',
  sagrais: 'sagrais',
  sagramo: 'sagramo',
  sagrara: 'sagrara',
  sagrava: 'sagrava',
  sagreas: 'ságreas',
  sagreis: 'sagreis',
  sagremo: 'sagremo',
  sagrina: 'sagrina',
  saguais: 'saguais',
  saguari: 'saguari',
  saguaro: 'saguaro',
  saguaru: 'saguaru',
  saguate: 'saguate',
  sagucho: 'sagucho',
  sagueza: 'sagueza',
  saguini: 'saguini',
  saguins: 'saguins',
  saguira: 'saguira',
  saguiru: 'saguiru',
  sagulos: 'ságulos',
  saguoes: 'saguões',
  saiadas: 'saiadas',
  saiamos: 'saiamos',
  saianas: 'saianas',
  saianos: 'saianos',
  saibais: 'saibais',
  saibamo: 'saibamo',
  saibrai: 'saibrai',
  saibram: 'saibram',
  saibrao: 'saibrão',
  saibrar: 'saibrar',
  saibras: 'saibras',
  saibrei: 'saibrei',
  saibrem: 'saibrem',
  saibres: 'saibres',
  saibrou: 'saibrou',
  saidela: 'saidela',
  saidica: 'saídica',
  saidico: 'saídico',
  saidoes: 'saidões',
  saidona: 'saidona',
  saidora: 'saidora',
  saieira: 'saieira',
  saiense: 'saiense',
  saieres: 'saierês',
  saietas: 'saietas',
  saiinha: 'saiinha',
  saiinho: 'saiinho',
  sailzao: 'sailzão',
  saimeis: 'saimeis',
  saimiri: 'saimiri',
  sainete: 'sainete',
  sainhas: 'sainhas',
  sainhos: 'sainhos',
  sainita: 'sainita',
  saintes: 'saintes',
  saioada: 'saioada',
  saioado: 'saioado',
  saiolas: 'saiolas',
  saionia: 'saionia',
  saioria: 'saioria',
  saiotas: 'saiotas',
  saiotes: 'saiotes',
  saiotos: 'saiotos',
  sairacu: 'sairaçu',
  sairara: 'sairara',
  sairdes: 'sairdes',
  saireis: 'saireis',
  sairemo: 'sairemo',
  sairiam: 'sairiam',
  sairias: 'sairias',
  sairmos: 'sairmos',
  sairucu: 'sairuçu',
  saisina: 'saisina',
  saissem: 'saíssem',
  saisses: 'saísses',
  saistes: 'saístes',
  saitaia: 'saitaia',
  saitaua: 'saitauá',
  saitica: 'saítica',
  saitico: 'saítico',
  sajaria: 'sajaria',
  sajenas: 'sájenas',
  sajenes: 'sajenes',
  sajense: 'sajense',
  sajicas: 'sajicas',
  salacao: 'salação',
  salacia: 'salácia',
  saladas: 'saladas',
  salafra: 'salafra',
  salafre: 'salafré',
  salagem: 'salagem',
  salagre: 'salagre',
  salague: 'salagué',
  salaios: 'salaios',
  salales: 'salalés',
  salaleu: 'salaléu',
  salamar: 'salamar',
  salamas: 'salamas',
  salamba: 'salamba',
  salamea: 'salameá',
  salames: 'salames',
  salamim: 'salamim',
  salamos: 'salamos',
  salanca: 'salanca',
  salango: 'salango',
  salaria: 'salaria',
  salarie: 'salarie',
  salario: 'salário',
  salassa: 'salassa',
  salasso: 'salasso',
  salaxes: 'salaxes',
  salazes: 'salazes',
  saldada: 'saldada',
  saldado: 'saldado',
  saldais: 'saldais',
  saldamo: 'saldamo',
  saldara: 'saldara',
  saldava: 'saldava',
  saldeis: 'saldeis',
  saldemo: 'saldemo',
  saldoes: 'saldões',
  saldula: 'sáldula',
  saleira: 'saleira',
  saleiro: 'saleiro',
  salemas: 'salemas',
  salenas: 'salenas',
  salenda: 'salenda',
  salenia: 'salênia',
  salenos: 'salenos',
  salense: 'salense',
  salepos: 'salepos',
  salerne: 'salerne',
  saleros: 'saleros',
  salesia: 'salésia',
  salesio: 'salésio',
  saletas: 'saletas',
  salfeno: 'salfeno',
  salgada: 'salgada',
  salgado: 'salgado',
  salgais: 'salgais',
  salgamo: 'salgamo',
  salgara: 'salgara',
  salgava: 'salgava',
  salguei: 'salguei',
  salguem: 'salguem',
  salgues: 'salgues',
  salhada: 'salhada',
  salhado: 'salhado',
  salhais: 'salhais',
  salhamo: 'salhamo',
  salhara: 'salhará',
  salhava: 'salhava',
  salheis: 'salheis',
  salhemo: 'salhemo',
  salical: 'salical',
  salicas: 'sálicas',
  salices: 'sálices',
  salicos: 'sálicos',
  saligas: 'saligas',
  salinai: 'salinai',
  salinam: 'salinam',
  salinar: 'salinar',
  salinas: 'salinas',
  salinei: 'salinei',
  salinem: 'salinem',
  salines: 'salines',
  salinha: 'salinha',
  salinos: 'salinos',
  salinou: 'salinou',
  salitas: 'salitas',
  salites: 'salites',
  salitra: 'salitra',
  salitre: 'salitre',
  salitro: 'salitro',
  salivai: 'salivai',
  salival: 'salival',
  salivam: 'salivam',
  salivar: 'salivar',
  salivas: 'salivas',
  salivei: 'salivei',
  salivem: 'salivem',
  salives: 'salives',
  salivou: 'salivou',
  salmaca: 'salmaça',
  salmaco: 'salmaço',
  salmana: 'salmana',
  salmano: 'salmano',
  salmeai: 'salmeai',
  salmear: 'salmear',
  salmeei: 'salmeei',
  salmeia: 'salmeia',
  salmeie: 'salmeie',
  salmeio: 'salmeio',
  salmeja: 'salmejá',
  salmeje: 'salmeje',
  salmejo: 'salmejo',
  salmeou: 'salmeou',
  salmica: 'sálmica',
  salmico: 'sálmico',
  salmina: 'salmina',
  salmino: 'salmino',
  salmita: 'salmita',
  salmite: 'salmite',
  salmiza: 'salmizá',
  salmize: 'salmize',
  salmizo: 'salmizo',
  salmoes: 'salmões',
  salobra: 'salobra',
  salobre: 'salobre',
  salobro: 'salobro',
  saloias: 'saloias',
  saloios: 'saloios',
  salpias: 'sálpias',
  salpica: 'salpica',
  salpico: 'salpico',
  salpida: 'sálpida',
  salpido: 'sálpido',
  salpina: 'salpina',
  salpino: 'salpino',
  salpins: 'salpins',
  salpios: 'sálpios',
  salsada: 'salsada',
  salsado: 'salsado',
  salsais: 'salsais',
  salsamo: 'salsamo',
  salsara: 'salsará',
  salsava: 'salsava',
  salseas: 'sálseas',
  salsedo: 'salsedo',
  salseis: 'salseis',
  salsemo: 'salsemo',
  salseos: 'sálseos',
  salsifi: 'salsifi',
  salsoes: 'salsões',
  salsola: 'salsola',
  salsona: 'salsona',
  saltada: 'saltada',
  saltado: 'saltado',
  saltais: 'saltais',
  saltamo: 'saltamo',
  saltara: 'saltara',
  saltava: 'saltava',
  salteai: 'salteai',
  saltear: 'saltear',
  salteei: 'salteei',
  salteia: 'salteia',
  salteie: 'salteie',
  salteio: 'salteio',
  salteis: 'salteis',
  saltemo: 'saltemo',
  salteou: 'salteou',
  saltica: 'saltica',
  saltico: 'saltico',
  saltita: 'saltita',
  saltite: 'saltite',
  saltito: 'saltito',
  saltoes: 'saltões',
  saltria: 'sáltria',
  salubas: 'salubás',
  salubre: 'salubre',
  salucai: 'saluçai',
  salucam: 'saluçam',
  salucar: 'saluçar',
  salucas: 'saluças',
  salucei: 'salucei',
  salucem: 'salucem',
  saluces: 'saluces',
  salucou: 'saluçou',
  saludai: 'saludai',
  saludam: 'saludam',
  saludar: 'saludar',
  saludas: 'saludas',
  saludei: 'saludei',
  saludem: 'saludem',
  saludes: 'saludes',
  saludor: 'saludor',
  saludou: 'saludou',
  salugas: 'salugas',
  salutar: 'salutar',
  salvada: 'salvada',
  salvado: 'salvado',
  salvage: 'salvage',
  salvais: 'salvais',
  salvamo: 'salvamo',
  salvara: 'salvara',
  salvava: 'salvava',
  salveis: 'salveis',
  salvemo: 'salvemo',
  salveno: 'salveno',
  salveta: 'salveta',
  salvete: 'salvete',
  salvias: 'sálvias',
  salviea: 'salvíea',
  salvina: 'salvina',
  salviol: 'salviol',
  salzoes: 'salzões',
  samadis: 'samádis',
  samagos: 'sâmagos',
  samanco: 'samanco',
  samanea: 'samânea',
  samanga: 'samanga',
  samango: 'samango',
  samaqui: 'samaqui',
  samaras: 'sâmaras',
  samarau: 'samarau',
  samario: 'samário',
  samaris: 'sâmaris',
  samarra: 'samarra',
  samarro: 'samarro',
  samatra: 'samatra',
  samauma: 'samaúma',
  samauva: 'samaúva',
  sambada: 'sambada',
  sambado: 'sambado',
  sambais: 'sambais',
  sambamo: 'sambamo',
  sambara: 'sambara',
  sambare: 'sambaré',
  sambava: 'sambava',
  sambeai: 'sambeai',
  sambear: 'sambear',
  sambeei: 'sambeei',
  sambeia: 'sambeia',
  sambeie: 'sambeie',
  sambeio: 'sambeio',
  sambeis: 'sambeis',
  sambemo: 'sambemo',
  sambeou: 'sambeou',
  sambeta: 'sambeta',
  sambica: 'sambica',
  sambico: 'sambico',
  samblai: 'samblai',
  samblam: 'samblam',
  samblar: 'samblar',
  samblas: 'samblas',
  samblei: 'samblei',
  samblem: 'samblem',
  sambles: 'sambles',
  samblou: 'samblou',
  samboca: 'samboca',
  samboco: 'samboco',
  samboes: 'sambões',
  sambojo: 'sambojô',
  sambora: 'samborá',
  sambrai: 'sambrai',
  sambram: 'sambram',
  sambrar: 'sambrar',
  sambras: 'sambras',
  sambrei: 'sambrei',
  sambrem: 'sambrem',
  sambres: 'sambres',
  sambrou: 'sambrou',
  sambuca: 'sambuca',
  sambuco: 'sambuco',
  sambuda: 'sambuda',
  sambudo: 'sambudo',
  sambuio: 'sambuio',
  sambula: 'sâmbula',
  sambura: 'samburá',
  samburo: 'samburo',
  sameias: 'sameias',
  sameira: 'sameira',
  samelos: 'samelos',
  samente: 'sãmente',
  sameode: 'sameode',
  samiana: 'samiana',
  samiano: 'samiano',
  samicas: 'samicas',
  samidea: 'samídea',
  samideo: 'samídeo',
  samieis: 'samiéis',
  saminea: 'samínea',
  samineo: 'samíneo',
  saminos: 'saminos',
  samites: 'samites',
  samitos: 'samitos',
  samnios: 'sâmnios',
  samnita: 'samnita',
  samnite: 'samnite',
  samoana: 'samoana',
  samoano: 'samoano',
  samobia: 'samóbia',
  samocos: 'samocos',
  samoita: 'samoíta',
  samolea: 'samólea',
  samoleo: 'samóleo',
  samolos: 'sâmolos',
  samonga: 'samonga',
  samorim: 'samorim',
  samoris: 'samoris',
  samouco: 'samouco',
  samovar: 'samovar',
  sampada: 'sampada',
  sampado: 'sampado',
  sampais: 'sampais',
  sampamo: 'sampamo',
  sampana: 'sampana',
  sampara: 'sampará',
  sampava: 'sampava',
  sampeis: 'sampeis',
  sampemo: 'sampemo',
  samples: 'samples',
  samsara: 'samsara',
  samurai: 'samurai',
  sanacao: 'sanação',
  sanadao: 'sanadão',
  sanadas: 'sanadas',
  sanador: 'sanador',
  sanados: 'sanados',
  sanamos: 'sanamos',
  sanando: 'sanando',
  sanandu: 'sanandu',
  sanante: 'sanante',
  sanaram: 'sanaram',
  sanarao: 'sanarão',
  sanaras: 'sanaras',
  sanarei: 'sanarei',
  sanarem: 'sanarem',
  sanares: 'sanares',
  sanaria: 'sanaria',
  sanarmo: 'sanarmo',
  sanasse: 'sanasse',
  sanaste: 'sanaste',
  sanates: 'sanates',
  sanavam: 'sanavam',
  sanavas: 'sanavas',
  sanavel: 'sanável',
  sancada: 'sancada',
  sancado: 'sancado',
  sanceno: 'sanceno',
  sanchas: 'sanchas',
  sanchim: 'sanchim',
  sanchos: 'sanchos',
  sanchus: 'sanchus',
  sancoes: 'sanções',
  sancona: 'sançona',
  sanctus: 'sanctus',
  sanculo: 'sanculo',
  sandaba: 'sandaba',
  sandada: 'sandada',
  sandado: 'sandado',
  sandais: 'sandais',
  sandala: 'sandala',
  sandalo: 'sândalo',
  sandamo: 'sandamo',
  sandana: 'sandaná',
  sandara: 'sandará',
  sandava: 'sandava',
  sandave: 'sandave',
  sandeis: 'sandeis',
  sandeja: 'sandeja',
  sandeje: 'sandeje',
  sandejo: 'sandejo',
  sandemo: 'sandemo',
  sandeus: 'sandeus',
  sandias: 'sandias',
  sandice: 'sandice',
  sandins: 'sandins',
  sandios: 'sandios',
  sandiva: 'sandiva',
  sandres: 'sandres',
  saneada: 'saneada',
  saneado: 'saneado',
  saneais: 'saneais',
  saneamo: 'saneamo',
  saneara: 'saneara',
  saneava: 'saneava',
  saneeis: 'saneeis',
  saneemo: 'saneemo',
  sanefai: 'sanefai',
  sanefam: 'sanefam',
  sanefar: 'sanefar',
  sanefas: 'sanefas',
  sanefei: 'sanefei',
  sanefem: 'sanefem',
  sanefes: 'sanefes',
  sanefou: 'sanefou',
  saneiam: 'saneiam',
  saneias: 'saneias',
  saneiem: 'saneiem',
  saneies: 'saneies',
  sanemos: 'sanemos',
  sanense: 'sanense',
  sanfeno: 'sanfeno',
  sanfona: 'sanfona',
  sanfone: 'sanfone',
  sanfono: 'sanfono',
  sangada: 'sangada',
  sangado: 'sangado',
  sangage: 'sangage',
  sangesa: 'sangesa',
  sangoes: 'sangões',
  sangoma: 'sangoma',
  sangrai: 'sangrai',
  sangram: 'sangram',
  sangrar: 'sangrar',
  sangras: 'sangras',
  sangrei: 'sangrei',
  sangrem: 'sangrem',
  sangres: 'sangres',
  sangria: 'sangria',
  sangrou: 'sangrou',
  sangues: 'sangues',
  sanguim: 'sanguim',
  sanguis: 'sanguis',
  sanhaco: 'sanhaço',
  sanhacu: 'sanhaçu',
  sanharo: 'sanharó',
  sanhosa: 'sanhosa',
  sanhoso: 'sanhoso',
  sanhuda: 'sanhuda',
  sanhudo: 'sanhudo',
  sanibas: 'sanibás',
  sanicai: 'sanicai',
  sanicam: 'sanicam',
  sanicar: 'sanicar',
  sanicas: 'sanicas',
  sanicou: 'sanicou',
  saninho: 'saninho',
  sanioje: 'sanioje',
  saniosa: 'saniosa',
  sanioso: 'sanioso',
  sanique: 'sanique',
  sanisca: 'sanisca',
  sanitas: 'sanitas',
  sanjaco: 'sanjaco',
  sanjada: 'sanjada',
  sanjado: 'sanjado',
  sanjais: 'sanjais',
  sanjamo: 'sanjamo',
  sanjara: 'sanjara',
  sanjava: 'sanjava',
  sanjeis: 'sanjeis',
  sanjemo: 'sanjemo',
  sanjica: 'sanjica',
  sanjoao: 'sanjoão',
  sanocas: 'sanocas',
  sanocos: 'sanocos',
  sanonas: 'sanonas',
  sanquia: 'sânquia',
  sansama: 'sansama',
  sanseis: 'sanseis',
  sansoes: 'sansões',
  santais: 'santais',
  santale: 'santale',
  santali: 'santali',
  santalo: 'sântalo',
  santana: 'santana',
  santelo: 'santelo',
  santeno: 'santeno',
  santias: 'sântias',
  santica: 'santica',
  santico: 'santico',
  santiga: 'santiga',
  santigo: 'santigo',
  santita: 'santita',
  santoes: 'santões',
  santois: 'santois',
  santola: 'santola',
  santome: 'santomé',
  santona: 'santona',
  santone: 'sântone',
  santono: 'sântono',
  santons: 'santons',
  santora: 'santora',
  santoro: 'santoro',
  sanvori: 'sanvori',
  sanzala: 'sanzala',
  sapadao: 'sapadão',
  sapadas: 'sapadas',
  sapador: 'sapador',
  sapados: 'sapados',
  sapajos: 'sapajos',
  sapajus: 'sapajus',
  sapamos: 'sapamos',
  sapando: 'sapando',
  sapante: 'sapante',
  saparam: 'saparam',
  saparao: 'saparão',
  saparas: 'saparás',
  saparei: 'saparei',
  saparem: 'saparem',
  sapares: 'sapares',
  saparia: 'saparia',
  sapario: 'sapário',
  saparmo: 'saparmo',
  sapasse: 'sapasse',
  sapaste: 'sapaste',
  sapatai: 'sapatai',
  sapatam: 'sapatam',
  sapatao: 'sapatão',
  sapatar: 'sapatar',
  sapatas: 'sapatas',
  sapatea: 'sapateá',
  sapatei: 'sapatei',
  sapatem: 'sapatem',
  sapates: 'sapates',
  sapatos: 'sapatos',
  sapatou: 'sapatou',
  sapavam: 'sapavam',
  sapavas: 'sapavas',
  sapeada: 'sapeada',
  sapeado: 'sapeado',
  sapeais: 'sapeais',
  sapeara: 'sapeará',
  sapeava: 'sapeava',
  sapecai: 'sapecai',
  sapecam: 'sapecam',
  sapecar: 'sapecar',
  sapecas: 'sapecas',
  sapecou: 'sapecou',
  sapeeis: 'sapeeis',
  sapeiam: 'sapeiam',
  sapeias: 'sapeias',
  sapeiem: 'sapeiem',
  sapeies: 'sapeies',
  sapeira: 'sapeira',
  sapeiro: 'sapeiro',
  sapejai: 'sapejai',
  sapejam: 'sapejam',
  sapejar: 'sapejar',
  sapejas: 'sapejas',
  sapejei: 'sapejei',
  sapejem: 'sapejem',
  sapejes: 'sapejes',
  sapejou: 'sapejou',
  sapelos: 'sapelos',
  sapemos: 'sapemos',
  sapenos: 'sapenos',
  sapense: 'sapense',
  sapeque: 'sapeque',
  saperda: 'saperda',
  saperes: 'saperês',
  sapezal: 'sapezal',
  sapicas: 'sapicas',
  sapicos: 'sapicos',
  sapicua: 'sapicuá',
  sapidas: 'sápidas',
  sapidez: 'sapidez',
  sapidos: 'sápidos',
  sapiens: 'sapiens',
  sapindo: 'sapindo',
  sapinho: 'sapinho',
  sapiqua: 'sapiquá',
  sapirao: 'sapirão',
  sapocus: 'sapocus',
  sapoila: 'sapoila',
  sapoile: 'sapoile',
  sapoilo: 'sapoilo',
  sapoleu: 'sapoléu',
  sapolga: 'sapolga',
  sapolio: 'sapólio',
  saponas: 'saponãs',
  sapopes: 'sapopés',
  sapores: 'saporés',
  saporia: 'saporiá',
  saporie: 'saporie',
  saporio: 'saporio',
  sapotas: 'sapotas',
  sapotea: 'sapótea',
  sapoteo: 'sapóteo',
  sapotes: 'sapotes',
  sapotis: 'sapotis',
  sapoula: 'sapoula',
  sapoule: 'sapoule',
  sapoulo: 'sapoulo',
  saprema: 'saprema',
  sapreme: 'sapreme',
  sapremo: 'sapremo',
  saprina: 'saprina',
  saprino: 'saprino',
  sapucai: 'sapucaí',
  sapuche: 'sapuche',
  sapudas: 'sapudas',
  sapudos: 'sapudos',
  sapuias: 'sapuias',
  sapuleu: 'sapuleu',
  sapupos: 'sapupos',
  saputas: 'saputás',
  saputis: 'saputis',
  sapuvao: 'sapuvão',
  sapuvas: 'sapuvas',
  saqueai: 'saqueai',
  saquear: 'saquear',
  saqueei: 'saqueei',
  saqueia: 'saqueia',
  saqueie: 'saqueie',
  saqueio: 'saqueio',
  saqueis: 'saqueis',
  saquela: 'saquela',
  saquele: 'saquele',
  saquelo: 'saquelo',
  saquemo: 'saquemo',
  saqueou: 'saqueou',
  saquete: 'saquete',
  saqueto: 'saqueto',
  saquins: 'saquins',
  saquito: 'saquito',
  saracas: 'saraças',
  saracos: 'saraços',
  saracua: 'saracuá',
  saradao: 'saradão',
  saradas: 'saradas',
  sarador: 'sarador',
  sarados: 'sarados',
  saragos: 'saragos',
  saragui: 'saragui',
  saraiba: 'saraíba',
  saraius: 'saraiús',
  saraiva: 'saraiva',
  saraive: 'saraive',
  saraivo: 'saraivo',
  saralho: 'saralho',
  saramba: 'saramba',
  sarambe: 'sarambé',
  sarambu: 'sarambu',
  sarames: 'sarames',
  saramos: 'saramos',
  sarampo: 'sarampo',
  saranco: 'saranco',
  saranda: 'saranda',
  sarandi: 'sarandi',
  sarando: 'sarando',
  saranga: 'saranga',
  sarango: 'sarango',
  saranha: 'saranha',
  sarante: 'sarante',
  sarapas: 'sarapas',
  sarapos: 'sarapós',
  sarapua: 'sarapuá',
  saraqua: 'saraquá',
  sararam: 'sararam',
  sararao: 'sararão',
  sararas: 'sararas',
  sararau: 'sararau',
  sararei: 'sararei',
  sararem: 'sararem',
  sarares: 'sarares',
  sararia: 'sararia',
  sararmo: 'sararmo',
  sarasse: 'sarasse',
  saraste: 'saraste',
  saraues: 'sarauês',
  saravam: 'saravam',
  saravas: 'saravas',
  sarcais: 'sarçais',
  sarcina: 'sárcina',
  sarcita: 'sarcita',
  sarcite: 'sarcite',
  sarcito: 'sarcito',
  sarcode: 'sarcode',
  sarcoma: 'sarcoma',
  sarcosa: 'sarcosa',
  sarcose: 'sarcose',
  sarcoso: 'sarcoso',
  sardada: 'sardada',
  sardado: 'sardado',
  sardais: 'sardais',
  sardamo: 'sardamo',
  sardana: 'sardana',
  sardara: 'sardará',
  sardava: 'sardava',
  sardeis: 'sardeis',
  sardemo: 'sardemo',
  sardias: 'sárdias',
  sardios: 'sárdios',
  sardoes: 'sardões',
  sardosa: 'sardosa',
  sardoso: 'sardoso',
  saremos: 'saremos',
  sarense: 'sarense',
  saresma: 'saresma',
  sargaca: 'sargaça',
  sargaco: 'sargaço',
  sargeta: 'sargeta',
  sargola: 'sargola',
  sarguca: 'sarguça',
  sariacu: 'sariaçu',
  sariana: 'sariana',
  sariano: 'sariano',
  saribas: 'saribas',
  saricas: 'sáricas',
  saricos: 'sáricos',
  sariema: 'sariema',
  sarigas: 'sarigas',
  sarigue: 'sariguê',
  sarilha: 'sarilha',
  sarilhe: 'sarilhe',
  sarilho: 'sarilho',
  sarissa: 'sarissa',
  saritas: 'saritãs',
  sarjada: 'sarjada',
  sarjado: 'sarjado',
  sarjais: 'sarjais',
  sarjamo: 'sarjamo',
  sarjara: 'sarjara',
  sarjava: 'sarjava',
  sarjeis: 'sarjeis',
  sarjemo: 'sarjemo',
  sarjeta: 'sarjeta',
  sarjoes: 'sarjões',
  sarjota: 'sarjota',
  sarmata: 'sármata',
  sarmoes: 'sarmões',
  sarnada: 'sarnada',
  sarnado: 'sarnado',
  sarnais: 'sarnais',
  sarnamo: 'sarnamo',
  sarnara: 'sarnará',
  sarnava: 'sarnava',
  sarneis: 'sarneis',
  sarnemo: 'sarnemo',
  sarneta: 'sarneta',
  sarneto: 'sarneto',
  sarnius: 'sarnius',
  sarnoes: 'sarnões',
  sarnosa: 'sarnosa',
  sarnoso: 'sarnoso',
  sarobas: 'sarobas',
  saroido: 'saroido',
  sarotes: 'sarotes',
  sarotos: 'sarotos',
  sarovas: 'sarovas',
  sarpada: 'sarpada',
  sarpado: 'sarpado',
  sarpais: 'sarpais',
  sarpamo: 'sarpamo',
  sarpara: 'sarpara',
  sarpava: 'sarpava',
  sarpeis: 'sarpeis',
  sarpemo: 'sarpemo',
  sarraco: 'sarraco',
  sarrada: 'sarrada',
  sarrado: 'sarrado',
  sarrafa: 'sarrafa',
  sarrafe: 'sarrafe',
  sarrafo: 'sarrafo',
  sarraia: 'sarraia',
  sarrais: 'sarrais',
  sarramo: 'sarramo',
  sarrara: 'sarrará',
  sarraus: 'sarraus',
  sarrava: 'sarrava',
  sarreis: 'sarreis',
  sarremo: 'sarremo',
  sarreta: 'sarreta',
  sarrica: 'sarrica',
  sarrico: 'sarrico',
  sarrido: 'sarrido',
  sarrifo: 'sarrifo',
  sarroes: 'sarrões',
  sarrona: 'sarrona',
  sarrons: 'sarrons',
  sarrosa: 'sarrosa',
  sarroso: 'sarroso',
  sarruda: 'sarruda',
  sarrudo: 'sarrudo',
  sarrufo: 'sarrufo',
  sarsara: 'sarsará',
  sarsina: 'sarsina',
  sartada: 'sartada',
  sartado: 'sartado',
  sartais: 'sartais',
  sartamo: 'sartamo',
  sartara: 'sartará',
  sartava: 'sartava',
  sarteis: 'sarteis',
  sartemo: 'sartemo',
  saruada: 'saruada',
  saruado: 'saruado',
  sarugas: 'sarugas',
  sarumas: 'sarumas',
  sashimi: 'sashimi',
  sassabi: 'sassabi',
  sassada: 'sassada',
  sassado: 'sassado',
  sassais: 'sassais',
  sassamo: 'sassamo',
  sassara: 'sassara',
  sassava: 'sassava',
  sasseia: 'sasseia',
  sasseis: 'sasseis',
  sassemo: 'sassemo',
  sasseus: 'sasseus',
  sastras: 'sastras',
  sastres: 'sastres',
  sasuras: 'sasuras',
  sasuros: 'sasuros',
  satadia: 'satádia',
  satanas: 'satanás',
  satania: 'satania',
  satanie: 'satanie',
  satanio: 'satanio',
  satarca: 'satarca',
  sataria: 'satária',
  satense: 'satense',
  satilha: 'satilha',
  satinho: 'satinho',
  satirao: 'satirão',
  satiras: 'sátiras',
  satirio: 'satírio',
  satiros: 'sátiros',
  satisda: 'satisda',
  satisde: 'satisde',
  satisdo: 'satisdo',
  satisfa: 'satisfa',
  sativas: 'sativas',
  sativos: 'sativos',
  satoris: 'satoris',
  satrapa: 'sátrapa',
  satrape: 'sátrape',
  saturai: 'saturai',
  saturam: 'saturam',
  saturar: 'saturar',
  saturas: 'saturas',
  saturei: 'saturei',
  saturem: 'saturem',
  satures: 'satures',
  saturna: 'saturna',
  saturno: 'saturno',
  saturou: 'saturou',
  sauacus: 'sauaçus',
  saubais: 'saubais',
  saudada: 'saudada',
  saudade: 'saudade',
  saudado: 'saudado',
  saudais: 'saudais',
  saudamo: 'saudamo',
  saudara: 'saudara',
  saudava: 'saudava',
  saudeem: 'saudeem',
  saudeis: 'saudeis',
  saudemo: 'saudemo',
  saudera: 'saudera',
  saudita: 'saudita',
  saudosa: 'saudosa',
  saudoso: 'saudoso',
  saugate: 'saugate',
  saunita: 'saunita',
  saurida: 'sáurida',
  saurido: 'sáurido',
  saurina: 'saurina',
  saurino: 'saurino',
  saurins: 'saurins',
  saurion: 'saúrion',
  saurios: 'sáurios',
  saurita: 'saurita',
  saurite: 'saurite',
  sauropo: 'sáuropo',
  saururo: 'saururo',
  sautora: 'sautora',
  sauvais: 'sauvais',
  savacus: 'savacus',
  savalus: 'savalus',
  savanas: 'savanas',
  savarim: 'savarim',
  savasto: 'savasto',
  savatas: 'savatas',
  savates: 'savates',
  saveira: 'saveira',
  saveiro: 'saveiro',
  savelha: 'savelha',
  savetes: 'savetes',
  savicao: 'savicão',
  savicas: 'savicas',
  savites: 'savites',
  savitos: 'savitos',
  savitus: 'savitus',
  savogas: 'savogas',
  savoras: 'savoras',
  saxatil: 'saxátil',
  saxinas: 'saxinas',
  saxones: 'sáxones',
  saxonia: 'saxônia',
  saxonio: 'saxônio',
  saxorne: 'saxorne',
  saxosas: 'saxosas',
  saxosos: 'saxosos',
  sazeira: 'sazeira',
  sazeiro: 'sazeiro',
  sazoada: 'sazoada',
  sazoado: 'sazoado',
  sazoais: 'sazoais',
  sazoamo: 'sazoamo',
  sazoara: 'sazoará',
  sazoava: 'sazoava',
  sazoeis: 'sazoeis',
  sazoemo: 'sazoemo',
  sazonai: 'sazonai',
  sazonal: 'sazonal',
  sazonam: 'sazonam',
  sazonar: 'sazonar',
  sazonas: 'sazonas',
  sazonei: 'sazonei',
  sazonem: 'sazonem',
  sazones: 'sazones',
  sazonou: 'sazonou',
  scabiei: 'scabiei',
  scanear: 'scanear',
  scanner: 'scanner',
  scheois: 'scheois',
  scherzo: 'scherzo',
  scholar: 'scholar',
  scleros: 'scleros',
  scooter: 'scooter',
  scopias: 'scopias',
  scraper: 'scraper',
  scripta: 'scripta',
  searedo: 'searedo',
  searoes: 'searões',
  sebacas: 'sebaças',
  sebacea: 'sebácea',
  sebaceo: 'sebáceo',
  sebadas: 'sebadas',
  sebados: 'sêbados',
  sebaste: 'sebaste',
  sebasto: 'sebasto',
  sebatos: 'sebatos',
  sebeira: 'sebeira',
  sebeiro: 'sebeiro',
  sebenta: 'sebenta',
  sebento: 'sebento',
  sebesta: 'sebesta',
  sebinas: 'sebinas',
  sebinho: 'sebinho',
  sebinos: 'sebinos',
  sebismo: 'sebismo',
  sebista: 'sebista',
  sebites: 'sebites',
  sebitos: 'sebitos',
  sebiubu: 'sebiubu',
  seboria: 'sebória',
  seborio: 'sebório',
  sebosas: 'sebosas',
  sebosos: 'sebosos',
  sebraju: 'sebraju',
  sebruna: 'sebruna',
  sebruno: 'sebruno',
  secacao: 'secação',
  secadal: 'secadal',
  secadao: 'secadão',
  secadas: 'secadas',
  secadio: 'secadio',
  secador: 'secador',
  secados: 'secados',
  secagem: 'secagem',
  secamos: 'secamos',
  secanas: 'secanas',
  secanca: 'secança',
  secando: 'secando',
  secanos: 'secanos',
  secante: 'secante',
  secaram: 'secaram',
  secarao: 'secarão',
  secaras: 'secaras',
  secarei: 'secarei',
  secarem: 'secarem',
  secares: 'secares',
  secaria: 'secaria',
  secarmo: 'secarmo',
  secarra: 'secarrã',
  secasse: 'secasse',
  secaste: 'secaste',
  secasto: 'secasto',
  secavam: 'secavam',
  secavas: 'secavas',
  secavel: 'secável',
  seccoes: 'secções',
  seccona: 'secçona',
  secesso: 'secesso',
  seciada: 'seciada',
  seciado: 'seciado',
  seciais: 'seciais',
  seciamo: 'seciamo',
  seciara: 'seciará',
  seciava: 'seciava',
  secieis: 'secieis',
  seciemo: 'seciemo',
  seciona: 'seciona',
  secione: 'secione',
  seciono: 'seciono',
  seclusa: 'seclusa',
  secluso: 'secluso',
  seconas: 'seçonas',
  secreta: 'secreta',
  secrete: 'secrete',
  secreto: 'secreto',
  secteis: 'sécteis',
  sectora: 'sectora',
  sectura: 'sectura',
  secular: 'secular',
  seculos: 'séculos',
  secunda: 'secunda',
  secunde: 'secunde',
  secundo: 'secundo',
  securas: 'securas',
  secures: 'secures',
  secusse: 'secusse',
  secutor: 'secutor',
  sedacao: 'sedação',
  sedacos: 'sedaços',
  sedadao: 'sedadão',
  sedadas: 'sedadas',
  sedador: 'sedador',
  sedados: 'sedados',
  sedagem: 'sedagem',
  sedalha: 'sedalha',
  sedamos: 'sedamos',
  sedando: 'sedando',
  sedante: 'sedante',
  sedaram: 'sedaram',
  sedarao: 'sedarão',
  sedaras: 'sedarás',
  sedarei: 'sedarei',
  sedarem: 'sedarem',
  sedares: 'sedares',
  sedaria: 'sedaria',
  sedarmo: 'sedarmo',
  sedasse: 'sedasse',
  sedaste: 'sedaste',
  sedavam: 'sedavam',
  sedavas: 'sedavas',
  sedeada: 'sedeada',
  sedeado: 'sedeado',
  sedeais: 'sedeais',
  sedeara: 'sedeará',
  sedeava: 'sedeava',
  sedecas: 'sedecas',
  sedeeis: 'sedeeis',
  sedeiam: 'sedeiam',
  sedeias: 'sedeias',
  sedeiem: 'sedeiem',
  sedeies: 'sedeies',
  sedeira: 'sedeira',
  sedeiro: 'sedeiro',
  sedelas: 'sedelas',
  sedemos: 'sedemos',
  sedendo: 'sedendo',
  sedenha: 'sedenha',
  sedenho: 'sedenho',
  sedense: 'sedense',
  sedenta: 'sedenta',
  sedente: 'sedente',
  sedento: 'sedento',
  sedeuda: 'sedeúda',
  sedeudo: 'sedeúdo',
  sediada: 'sediada',
  sediado: 'sediado',
  sediais: 'sediais',
  sediamo: 'sediamo',
  sediara: 'sediara',
  sediava: 'sediava',
  sedicao: 'sedição',
  sedieis: 'sedieis',
  sediela: 'sediela',
  sediemo: 'sediemo',
  sedinha: 'sedinha',
  sedonho: 'sedonho',
  sedoras: 'sedoras',
  sedores: 'sedores',
  sedosas: 'sedosas',
  sedosos: 'sedosos',
  seducao: 'sedução',
  sedulas: 'sédulas',
  sedulos: 'sédulos',
  sedunas: 'sedunas',
  sedunos: 'sedunos',
  sedutor: 'sedutor',
  seduzam: 'seduzam',
  seduzas: 'seduzas',
  seduzem: 'seduzem',
  seduzes: 'seduzes',
  seduzia: 'seduzia',
  seduzir: 'seduzir',
  seduzis: 'seduzis',
  seduziu: 'seduziu',
  sefardi: 'sefardi',
  sefelas: 'sefelas',
  sefelos: 'sefelos',
  sefelpa: 'sefelpa',
  seferia: 'seféria',
  sefinas: 'sefinas',
  seflosa: 'seflosa',
  segadao: 'segadão',
  segadas: 'segadas',
  segador: 'segador',
  segados: 'segados',
  segamos: 'segamos',
  segando: 'segando',
  segante: 'segante',
  segaram: 'segaram',
  segarao: 'segarão',
  segaras: 'segarás',
  segarei: 'segarei',
  segarem: 'segarem',
  segares: 'segares',
  segaria: 'segaria',
  segasse: 'segasse',
  segaste: 'segaste',
  segavam: 'segavam',
  segavas: 'segavas',
  segecia: 'segécia',
  segeira: 'segeira',
  segeiro: 'segeiro',
  segetal: 'segetal',
  segovia: 'segóvia',
  segreda: 'segreda',
  segrede: 'segrede',
  segredo: 'segredo',
  segrega: 'segrega',
  segrego: 'segrego',
  segreis: 'segréis',
  seguais: 'seguais',
  segueis: 'segueis',
  seguiam: 'seguiam',
  seguias: 'seguias',
  seguida: 'seguida',
  seguido: 'seguido',
  seguimo: 'seguimo',
  seguira: 'seguirá',
  segunda: 'segunda',
  segunde: 'segunde',
  segundo: 'segundo',
  segurai: 'segurai',
  seguram: 'seguram',
  segurar: 'segurar',
  seguras: 'seguras',
  segurei: 'segurei',
  segurem: 'segurem',
  segures: 'segures',
  seguros: 'seguros',
  segurou: 'segurou',
  seiadas: 'seiadas',
  seiboes: 'seibões',
  seicais: 'seiçais',
  seiches: 'seiches',
  seidico: 'seidiço',
  seifias: 'seifias',
  seiinho: 'seiinho',
  seimiri: 'seimiri',
  seirita: 'seirita',
  seiroes: 'seirões',
  seisavo: 'seisavo',
  seitada: 'seitada',
  seitado: 'seitado',
  seitais: 'seitais',
  seitamo: 'seitamo',
  seitara: 'seitará',
  seitava: 'seitava',
  seiteis: 'seiteis',
  seitemo: 'seitemo',
  seitosa: 'seitosa',
  seitoso: 'seitoso',
  seiudas: 'seiudas',
  seiudos: 'seiudos',
  seivada: 'seivada',
  seivado: 'seivado',
  seivais: 'seivais',
  seivamo: 'seivamo',
  seivara: 'seivara',
  seivava: 'seivava',
  seiveis: 'seiveis',
  seivemo: 'seivemo',
  seivosa: 'seivosa',
  seivoso: 'seivoso',
  seixada: 'seixada',
  seixais: 'seixais',
  seixosa: 'seixosa',
  seixoso: 'seixoso',
  sejagao: 'sejagão',
  sejamos: 'sejamos',
  sejanas: 'séjanas',
  sejaria: 'sejaria',
  selaceo: 'seláceo',
  selacia: 'selácia',
  selacio: 'selácio',
  seladao: 'seladão',
  seladas: 'seladas',
  selador: 'selador',
  selados: 'selados',
  selagao: 'selagão',
  selagem: 'selagem',
  selaita: 'selaíta',
  selaite: 'selaíte',
  selaito: 'selaíto',
  selamia: 'selâmia',
  selamim: 'selamim',
  selamos: 'selamos',
  selando: 'selando',
  selante: 'selante',
  selaram: 'selaram',
  selarao: 'selarão',
  selaras: 'selaras',
  selarei: 'selarei',
  selarem: 'selarem',
  selares: 'selares',
  selaria: 'selaria',
  selario: 'selário',
  selarmo: 'selarmo',
  selasia: 'selásia',
  selasse: 'selasse',
  selaste: 'selaste',
  selavam: 'selavam',
  selavas: 'selavas',
  selbite: 'selbite',
  selecao: 'seleção',
  selecta: 'selecta',
  selecto: 'selecto',
  seleira: 'seleira',
  seleiro: 'seleiro',
  selemos: 'selemos',
  selenes: 'selenes',
  selenia: 'selênia',
  selenio: 'selênio',
  selenis: 'selênis',
  seletai: 'seletai',
  seletam: 'seletam',
  seletar: 'seletar',
  seletas: 'seletas',
  seletei: 'seletei',
  seletem: 'seletem',
  seletes: 'seletes',
  seletor: 'seletor',
  seletos: 'seletos',
  seletou: 'seletou',
  selfica: 'sélfica',
  selfico: 'sélfico',
  selgica: 'sélgica',
  selgico: 'sélgico',
  seliera: 'seliera',
  selinas: 'selinas',
  selinea: 'selínea',
  selineo: 'selíneo',
  selinho: 'selinho',
  selinos: 'selinos',
  selisca: 'selisca',
  selisia: 'selísia',
  selista: 'selista',
  selites: 'selites',
  selotes: 'selotes',
  selvela: 'selvela',
  selvosa: 'selvosa',
  selvoso: 'selvoso',
  semanal: 'semanal',
  semanas: 'semanas',
  semasia: 'semásia',
  sembada: 'sembada',
  sembado: 'sembado',
  sembais: 'sembais',
  sembamo: 'sembamo',
  sembara: 'sembará',
  sembase: 'sembase',
  sembava: 'sembava',
  sembeis: 'sembeis',
  sembela: 'sembela',
  sembemo: 'sembemo',
  sembrai: 'sembrai',
  sembram: 'sembram',
  sembrar: 'sembrar',
  sembras: 'sembras',
  sembrei: 'sembrei',
  sembrem: 'sembrem',
  sembres: 'sembres',
  sembrou: 'sembrou',
  semeada: 'semeada',
  semeado: 'semeado',
  semeais: 'semeais',
  semeamo: 'semeamo',
  semeara: 'semeara',
  semeava: 'semeava',
  semeeis: 'semeeis',
  semeemo: 'semeemo',
  semeiam: 'semeiam',
  semeias: 'semeias',
  semeiem: 'semeiem',
  semeies: 'semeies',
  semelha: 'semelha',
  semelhe: 'semelhe',
  semelho: 'semelho',
  sememas: 'sememas',
  sementa: 'sementa',
  semente: 'semente',
  semento: 'semento',
  semiaca: 'semiaça',
  semiaco: 'semiaço',
  semiana: 'semianã',
  semicas: 'sêmicas',
  semicos: 'sêmicos',
  semilha: 'semilha',
  semiluz: 'semiluz',
  semimis: 'semimis',
  seminal: 'seminal',
  seminos: 'seminos',
  seminua: 'seminua',
  seminus: 'seminus',
  semioca: 'semioca',
  semioco: 'semioco',
  semiosa: 'semiosa',
  semiose: 'semiose',
  semioto: 'semioto',
  semisse: 'semisse',
  semitas: 'semitas',
  semitoa: 'semitoá',
  semitoe: 'semitoe',
  semitom: 'semitom',
  semitoo: 'semitoo',
  semivoa: 'semivoa',
  semivoe: 'semivoe',
  semivoo: 'semivoo',
  semnone: 'sêmnone',
  semolas: 'sêmolas',
  semones: 'semones',
  semotas: 'semotas',
  semotos: 'semotos',
  senabos: 'senabós',
  senador: 'senador',
  senados: 'senados',
  senagra: 'senagra',
  senaita: 'senaíta',
  senaite: 'senaíte',
  senaito: 'senaíto',
  senaria: 'senária',
  senario: 'senário',
  senatas: 'senatas',
  sencelo: 'sencelo',
  senceno: 'senceno',
  sendais: 'sendais',
  senecas: 'sênecas',
  senecio: 'senécio',
  senecta: 'senecta',
  senecto: 'senecto',
  senegas: 'sênegas',
  senembi: 'senembi',
  senembu: 'senembu',
  senense: 'senense',
  senevol: 'senevol',
  sengada: 'sengada',
  sengado: 'sengado',
  sengais: 'sengais',
  sengara: 'sengara',
  sengava: 'sengava',
  senguei: 'senguei',
  senguem: 'senguem',
  sengues: 'sengues',
  senhora: 'senhora',
  senicas: 'sênicas',
  seninha: 'seninha',
  senisga: 'senisga',
  senoide: 'senoide',
  senoite: 'senoite',
  senones: 'sênones',
  senonio: 'senônio',
  senouro: 'senouro',
  senoute: 'senoute',
  senrada: 'senrada',
  sensata: 'sensata',
  sensato: 'sensato',
  senseis: 'sênseis',
  sensila: 'sensila',
  sensiva: 'sensiva',
  sensivo: 'sensivo',
  sensora: 'sensora',
  sensual: 'sensual',
  sentada: 'sentada',
  sentado: 'sentado',
  sentais: 'sentais',
  sentamo: 'sentamo',
  sentara: 'sentara',
  sentava: 'sentava',
  senteis: 'senteis',
  sentemo: 'sentemo',
  sentiam: 'sentiam',
  sentias: 'sentias',
  sentida: 'sentida',
  sentido: 'sentido',
  sentimo: 'sentimo',
  sentina: 'sentina',
  sentira: 'sentirá',
  senzala: 'senzala',
  sepalar: 'sepalar',
  sepalas: 'sépalas',
  sepalos: 'sépalos',
  separai: 'separai',
  separam: 'separam',
  separar: 'separar',
  separas: 'separas',
  separei: 'separei',
  separem: 'separem',
  separes: 'separes',
  separou: 'separou',
  sepeese: 'sepeese',
  sepegar: 'sepegar',
  sepense: 'sepense',
  seperus: 'seperus',
  sepidio: 'sepídio',
  sepiela: 'sepiela',
  sepiola: 'sepíola',
  sepions: 'sépions',
  sepitas: 'sepitas',
  sepites: 'sepites',
  seposas: 'seposas',
  seposos: 'seposos',
  sepsias: 'sepsias',
  sepside: 'sépside',
  sepsina: 'sepsina',
  septada: 'septada',
  septado: 'septado',
  septais: 'septais',
  septamo: 'septamo',
  septara: 'septará',
  septava: 'septava',
  septeis: 'sépteis',
  septemo: 'septemo',
  septena: 'septena',
  septeno: 'septeno',
  septeto: 'septeto',
  septica: 'séptica',
  septico: 'séptico',
  septosa: 'septosa',
  septoso: 'septoso',
  septula: 'séptula',
  septulo: 'séptulo',
  septunx: 'séptunx',
  septuor: 'séptuor',
  sepulta: 'sepulta',
  sepulte: 'sepulte',
  sepulto: 'sepulto',
  sequace: 'sequace',
  sequana: 'séquana',
  sequano: 'séquano',
  sequeis: 'sequeis',
  sequela: 'sequela',
  sequele: 'sequele',
  sequelo: 'sequelo',
  sequemo: 'sequemo',
  sequiai: 'sequiai',
  sequiam: 'sequiam',
  sequiar: 'sequiar',
  sequias: 'sequias',
  sequiei: 'sequiei',
  sequiem: 'sequiem',
  sequies: 'sequies',
  sequilo: 'sequilo',
  sequins: 'sequins',
  sequios: 'séquios',
  sequiou: 'sequiou',
  sequito: 'séquito',
  sequoia: 'sequoia',
  serabia: 'serábia',
  seracas: 'seracas',
  seracos: 'seracos',
  serafim: 'serafim',
  seraiva: 'seraiva',
  serampo: 'serampo',
  seranda: 'seranda',
  serande: 'serande',
  serando: 'serando',
  seranza: 'seranza',
  seranze: 'seranze',
  seranzo: 'seranzo',
  serapeu: 'serapeu',
  serapia: 'serápia',
  serbias: 'sérbias',
  serbios: 'sérbios',
  serbros: 'serbros',
  sercial: 'sercial',
  serdoes: 'serdões',
  sereias: 'sereias',
  sereiba: 'sereíba',
  seremos: 'seremos',
  serenai: 'serenai',
  serenam: 'serenam',
  serenar: 'serenar',
  serenas: 'serenas',
  serenei: 'serenei',
  serenem: 'serenem',
  serenes: 'serenes',
  serenga: 'serenga',
  serenim: 'serenim',
  serenos: 'serenos',
  serenou: 'serenou',
  sereras: 'sereras',
  sereres: 'sereres',
  sereros: 'sereros',
  seresma: 'seresma',
  seresta: 'seresta',
  sergeta: 'sergeta',
  sergias: 'sérgias',
  serguia: 'serguia',
  seriada: 'seriada',
  seriado: 'seriado',
  seriais: 'seriais',
  seriama: 'seriama',
  seriamo: 'seriamo',
  seriara: 'seriara',
  seriava: 'seriava',
  seribas: 'seribas',
  sericas: 'séricas',
  sericea: 'serícea',
  sericeo: 'seríceo',
  sericos: 'séricos',
  seridea: 'serídea',
  serides: 'sérides',
  seridia: 'serídia',
  seridos: 'seridós',
  serieis: 'serieis',
  seriema: 'seriema',
  seriemo: 'seriemo',
  serifas: 'serifas',
  serifio: 'serífio',
  serinas: 'serinas',
  seringa: 'seringa',
  seringo: 'seringo',
  serinos: 'serinos',
  serioba: 'serioba',
  seriola: 'seríola',
  seriosa: 'seriosa',
  serioso: 'serioso',
  serissa: 'serissa',
  serisse: 'serisse',
  seriuva: 'seriúva',
  sermoai: 'sermoai',
  sermoam: 'sermoam',
  sermoar: 'sermoar',
  sermoas: 'sermoas',
  sermoei: 'sermoei',
  sermoem: 'sermoem',
  sermoes: 'sermões',
  sermona: 'sermona',
  sermone: 'sermone',
  sermono: 'sermono',
  sermoou: 'sermoou',
  sernada: 'sernada',
  sernais: 'sernais',
  seroada: 'seroada',
  seroado: 'seroado',
  seroais: 'seroais',
  seroamo: 'seroamo',
  seroara: 'seroará',
  seroava: 'seroava',
  serodia: 'serôdia',
  serodio: 'serôdio',
  seroeis: 'seroeis',
  seroemo: 'seroemo',
  serolas: 'sérolas',
  serolis: 'sérolis',
  seromas: 'seromas',
  serosas: 'serosas',
  serosos: 'serosos',
  serpeai: 'serpeai',
  serpear: 'serpear',
  serpeei: 'serpeei',
  serpeia: 'serpeia',
  serpeie: 'serpeie',
  serpeio: 'serpeio',
  serpeja: 'serpeja',
  serpeje: 'serpeje',
  serpejo: 'serpejo',
  serpeou: 'serpeou',
  serpete: 'serpete',
  serpoes: 'serpões',
  serpois: 'serpóis',
  serpula: 'sérpula',
  serrada: 'serrada',
  serrado: 'serrado',
  serrafo: 'serrafo',
  serrais: 'serrais',
  serramo: 'serramo',
  serrana: 'serrana',
  serrano: 'serrano',
  serrara: 'serrara',
  serrava: 'serrava',
  serreai: 'serreai',
  serreal: 'serreal',
  serrear: 'serrear',
  serreas: 'sérreas',
  serredo: 'serredo',
  serreei: 'serreei',
  serreia: 'serreia',
  serreie: 'serreie',
  serreio: 'serreio',
  serreis: 'serreis',
  serremo: 'serremo',
  serreos: 'sérreos',
  serreou: 'serreou',
  serreta: 'serreta',
  serrete: 'serrete',
  serrial: 'serrial',
  serrina: 'serrina',
  serrine: 'serrine',
  serrino: 'serrino',
  serrins: 'serrins',
  serroes: 'serrões',
  serrota: 'serrota',
  serrote: 'serrote',
  serroto: 'serroto',
  sertela: 'sertela',
  sertens: 'serténs',
  sertoes: 'sertões',
  sertula: 'sértula',
  sertulo: 'sértulo',
  sertuns: 'sertuns',
  seruaia: 'seruaia',
  serudas: 'serudas',
  servais: 'servais',
  servans: 'servans',
  serviam: 'serviam',
  servias: 'servias',
  service: 'service',
  servico: 'serviço',
  servida: 'servida',
  servido: 'servido',
  servimo: 'servimo',
  servios: 'sérvios',
  servira: 'servirá',
  servita: 'servita',
  serzeta: 'serzeta',
  serzete: 'serzete',
  serziam: 'serziam',
  serzias: 'serzias',
  serzido: 'serzido',
  serzimo: 'serzimo',
  serzino: 'serzino',
  serzira: 'serzira',
  sesamea: 'sesâmea',
  sesameo: 'sesâmeo',
  sesamia: 'sesâmia',
  sesamol: 'sesamol',
  sesamos: 'sésamos',
  sesarna: 'sesarna',
  sesegas: 'sésegas',
  seseles: 'séseles',
  seselio: 'sesélio',
  seselis: 'séselis',
  sesicas: 'sésicas',
  sesigas: 'sésigas',
  sesmada: 'sesmada',
  sesmado: 'sesmado',
  sesmais: 'sesmais',
  sesmamo: 'sesmamo',
  sesmara: 'sesmará',
  sesmava: 'sesmava',
  sesmeis: 'sesmeis',
  sesmemo: 'sesmemo',
  sesotho: 'sesotho',
  sessada: 'sessada',
  sessado: 'sessado',
  sessais: 'sessais',
  sessamo: 'sessamo',
  sessara: 'sessará',
  sessava: 'sessava',
  sessega: 'séssega',
  sessego: 'sêssego',
  sesseis: 'sésseis',
  sessemo: 'sessemo',
  sessene: 'sessene',
  sessoes: 'sessões',
  sessona: 'sessona',
  sessoto: 'sessoto',
  sesteai: 'sesteai',
  sestear: 'sestear',
  sesteei: 'sesteei',
  sesteia: 'sesteia',
  sesteie: 'sesteie',
  sesteio: 'sesteio',
  sesteou: 'sesteou',
  sestrai: 'sestrai',
  sestram: 'sestram',
  sestrar: 'sestrar',
  sestras: 'sestras',
  sestrei: 'sestrei',
  sestrem: 'sestrem',
  sestres: 'sestres',
  sestrou: 'sestrou',
  sesudas: 'sesudas',
  sesudos: 'sesudos',
  sesuvio: 'sesúvio',
  setacea: 'setácea',
  setaceo: 'setáceo',
  setadao: 'setadão',
  setadas: 'setadas',
  setador: 'setador',
  setados: 'setados',
  setamos: 'setamos',
  setando: 'setando',
  setante: 'setante',
  setaram: 'setaram',
  setarao: 'setarão',
  setaras: 'setarás',
  setarda: 'setarda',
  setarei: 'setarei',
  setarem: 'setarem',
  setares: 'setares',
  setaria: 'setária',
  setarmo: 'setarmo',
  setasse: 'setasse',
  setaste: 'setaste',
  setavam: 'setavam',
  setavas: 'setavas',
  seteada: 'seteada',
  seteado: 'seteado',
  seteais: 'seteais',
  seteara: 'seteará',
  seteava: 'seteava',
  seteeis: 'seteeis',
  seteiam: 'seteiam',
  seteias: 'seteias',
  seteiem: 'seteiem',
  seteies: 'seteies',
  seteira: 'seteira',
  seteire: 'seteire',
  seteiro: 'seteiro',
  setelia: 'setélia',
  setemes: 'setemês',
  setemos: 'setemos',
  setenal: 'setenal',
  setenas: 'setenas',
  setenio: 'setênio',
  setenos: 'setenos',
  setenta: 'setenta',
  setfari: 'setfari',
  setiais: 'setiais',
  setiana: 'setiana',
  setiano: 'setiano',
  setilha: 'setilha',
  setimas: 'sétimas',
  setimos: 'sétimos',
  setinai: 'setinai',
  setinam: 'setinam',
  setinar: 'setinar',
  setinas: 'setinas',
  setinei: 'setinei',
  setinem: 'setinem',
  setines: 'setines',
  setinha: 'setinha',
  setinos: 'setinos',
  setinou: 'setinou',
  setoira: 'setoira',
  setores: 'setores',
  setosas: 'setosas',
  setosos: 'setosos',
  setoura: 'setoura',
  setulas: 'sétulas',
  setupla: 'sétupla',
  setuplo: 'sétuplo',
  seurbos: 'seurbos',
  seutera: 'seutera',
  sevadao: 'sevadão',
  sevadas: 'sevadas',
  sevador: 'sevador',
  sevados: 'sevados',
  sevamos: 'sevamos',
  sevando: 'sevando',
  sevante: 'sevante',
  sevaram: 'sevaram',
  sevarao: 'sevarão',
  sevaras: 'sevaras',
  sevarei: 'sevarei',
  sevarem: 'sevarem',
  sevares: 'sevares',
  sevaria: 'sevaria',
  sevarmo: 'sevarmo',
  sevasse: 'sevasse',
  sevaste: 'sevaste',
  sevasto: 'sevasto',
  sevavam: 'sevavam',
  sevavas: 'sevavas',
  seveira: 'seveira',
  sevemos: 'sevemos',
  severas: 'severas',
  severos: 'severos',
  sevicia: 'sevícia',
  sevicie: 'sevicie',
  sevicio: 'sevicio',
  seviral: 'seviral',
  seviros: 'séviros',
  sexadao: 'sexadão',
  sexadas: 'sexadas',
  sexador: 'sexador',
  sexados: 'sexados',
  sexagem: 'sexagem',
  sexamos: 'sexamos',
  sexando: 'sexando',
  sexante: 'sexante',
  sexaram: 'sexaram',
  sexarao: 'sexarão',
  sexaras: 'sexarás',
  sexarei: 'sexarei',
  sexarem: 'sexarem',
  sexares: 'sexares',
  sexaria: 'sexaria',
  sexarmo: 'sexarmo',
  sexasse: 'sexasse',
  sexaste: 'sexaste',
  sexatas: 'sexatas',
  sexatro: 'sexatro',
  sexavam: 'sexavam',
  sexavas: 'sexavas',
  sexavos: 'sexavôs',
  sexemos: 'sexemos',
  sexenal: 'sexenal',
  sexenio: 'sexênio',
  sexismo: 'sexismo',
  sexista: 'sexista',
  sextava: 'sextava',
  sextave: 'sextave',
  sextavo: 'sextavo',
  sexteto: 'sexteto',
  sextina: 'sextina',
  sextulo: 'sêxtulo',
  sextuor: 'sêxtuor',
  sexuada: 'sexuada',
  sexuado: 'sexuado',
  sexuais: 'sexuais',
  sexuale: 'sexuale',
  sexuamo: 'sexuamo',
  sexuana: 'sexuana',
  sexuara: 'sexuará',
  sexuava: 'sexuava',
  sexueis: 'sexueis',
  sexuemo: 'sexuemo',
  sexviro: 'séxviro',
  sezenas: 'sezenas',
  sezenos: 'sezenos',
  sezoada: 'sezoada',
  sezoado: 'sezoado',
  sezoais: 'sezoais',
  sezoamo: 'sezoamo',
  sezoara: 'sezoara',
  sezoava: 'sezoava',
  sezoeis: 'sezoeis',
  sezoemo: 'sezoemo',
  sezonal: 'sezonal',
  sezutos: 'sezutos',
  sfogato: 'sfogato',
  shampoo: 'shampoo',
  sharing: 'sharing',
  shiatsu: 'shiatsu',
  shigela: 'shigela',
  shuntar: 'shuntar',
  siadoes: 'siadões',
  siadona: 'siadona',
  siadora: 'siadora',
  siagros: 'síagros',
  sialias: 'siálias',
  sialica: 'siálica',
  sialico: 'siálico',
  sialida: 'siálida',
  sialite: 'sialite',
  sialzao: 'sialzão',
  siamesa: 'siamesa',
  siamise: 'siamise',
  siampao: 'siampão',
  siantes: 'siantes',
  siaozao: 'siãozão',
  siardes: 'siardes',
  siareis: 'siareis',
  siaremo: 'siaremo',
  siariam: 'siariam',
  siarias: 'siarias',
  siarmos: 'siarmos',
  siassem: 'siassem',
  siasses: 'siasses',
  siastes: 'siastes',
  siaveis: 'siáveis',
  sibalas: 'sibalas',
  sibanas: 'sibanas',
  sibaras: 'síbaras',
  sibares: 'sibares',
  sibaris: 'síbaris',
  siberia: 'sibéria',
  sibilai: 'sibilai',
  sibilam: 'sibilam',
  sibilar: 'sibilar',
  sibilas: 'sibilas',
  sibildo: 'sibildo',
  sibilei: 'sibilei',
  sibilem: 'sibilem',
  sibiles: 'sibiles',
  sibilos: 'sibilos',
  sibilou: 'sibilou',
  sibilus: 'sibilus',
  sibinas: 'síbinas',
  sibines: 'síbines',
  sibiras: 'sibiras',
  sibitai: 'sibitai',
  sibitam: 'sibitam',
  sibitar: 'sibitar',
  sibitas: 'sibitas',
  sibitei: 'sibitei',
  sibitem: 'sibitem',
  sibites: 'sibites',
  sibitou: 'sibitou',
  sibivas: 'sibivas',
  sibivos: 'sibivos',
  sibling: 'sibling',
  sibongo: 'sibongo',
  sicanas: 'sicanas',
  sicanos: 'sicanos',
  sicario: 'sicário',
  sicates: 'sicates',
  sicense: 'sicense',
  siceras: 'síceras',
  sicidio: 'sicídio',
  sicilia: 'sicília',
  sicilio: 'sicílio',
  sicinas: 'sicinas',
  sicinio: 'sicínio',
  sicinis: 'sicínis',
  sicinos: 'sicinos',
  sicitas: 'sicitas',
  sicites: 'sicites',
  sicoias: 'sicoias',
  sicomas: 'sicomas',
  sicones: 'sícones',
  siconio: 'sicônio',
  siconos: 'síconos',
  sicorda: 'sicorda',
  sicoses: 'sicoses',
  sicrana: 'sicrana',
  sicrano: 'sicrano',
  sicuira: 'siçuíra',
  sicular: 'sicular',
  siculas: 'sículas',
  siculos: 'sículos',
  sicuris: 'sicuris',
  sidagas: 'sidagãs',
  sidamos: 'sidamos',
  sidecar: 'sidecar',
  sidemia: 'sidêmia',
  sidense: 'sidense',
  siderai: 'siderai',
  sideral: 'sideral',
  sideram: 'sideram',
  siderar: 'siderar',
  sideras: 'sideras',
  siderea: 'sidérea',
  siderei: 'siderei',
  siderem: 'siderem',
  sidereo: 'sidéreo',
  sideres: 'sideres',
  siderou: 'siderou',
  sidetas: 'sidetas',
  sididea: 'sidídea',
  sidideo: 'sidídeo',
  sidinas: 'sidinas',
  sidinea: 'sidínea',
  sidineo: 'sidíneo',
  sidinha: 'sidinha',
  sidinho: 'sidinho',
  sidoiro: 'sidoiro',
  sidonal: 'sidonal',
  sidonas: 'sidonas',
  sidonia: 'sidônia',
  sidonio: 'sidônio',
  sidouro: 'sidouro',
  siemens: 'siemens',
  sienesa: 'sienesa',
  sienita: 'sienita',
  sienite: 'sienite',
  sienito: 'sienito',
  sievert: 'sievert',
  sifiles: 'sífiles',
  sifilis: 'sífilis',
  siflada: 'siflada',
  siflado: 'siflado',
  siflais: 'siflais',
  siflamo: 'siflamo',
  siflara: 'siflará',
  siflava: 'siflava',
  sifleis: 'sifleis',
  siflemo: 'siflemo',
  sifmita: 'sifmita',
  sifneas: 'sífneas',
  sifneos: 'sífneos',
  sifnias: 'sífnias',
  sifnios: 'sífnios',
  sifoide: 'sifoide',
  sifonai: 'sifonai',
  sifonal: 'sifonal',
  sifonam: 'sifonam',
  sifonar: 'sifonar',
  sifonas: 'sifonas',
  sifonei: 'sifonei',
  sifonem: 'sifonem',
  sifones: 'sifones',
  sifonia: 'sifônia',
  sifonou: 'sifonou',
  sifulas: 'sífulas',
  sigamos: 'sigamos',
  sigilai: 'sigilai',
  sigilam: 'sigilam',
  sigilar: 'sigilar',
  sigilas: 'sigilas',
  sigilei: 'sigilei',
  sigilem: 'sigilem',
  sigiles: 'sigiles',
  sigilos: 'sigilos',
  sigilou: 'sigilou',
  siglada: 'siglada',
  siglado: 'siglado',
  siglais: 'siglais',
  siglamo: 'siglamo',
  siglara: 'siglara',
  siglava: 'siglava',
  sigleis: 'sigleis',
  siglema: 'siglema',
  siglemo: 'siglemo',
  sigliza: 'sigliza',
  siglize: 'siglize',
  siglizo: 'siglizo',
  sigmodo: 'sígmodo',
  signada: 'signada',
  signado: 'signado',
  signais: 'signais',
  signamo: 'signamo',
  signara: 'signará',
  signava: 'signava',
  signeis: 'signeis',
  signemo: 'signemo',
  signica: 'sígnica',
  signico: 'sígnico',
  signina: 'signina',
  signino: 'signino',
  siguais: 'siguais',
  silabai: 'silabai',
  silabam: 'silabam',
  silabar: 'silabar',
  silabas: 'sílabas',
  silabei: 'silabei',
  silabem: 'silabem',
  silabes: 'silabes',
  silabos: 'sílabos',
  silabou: 'silabou',
  silagem: 'silagem',
  silanas: 'silanas',
  silanol: 'silanol',
  silanos: 'silanos',
  silenas: 'silenas',
  silenea: 'silênea',
  sileneo: 'silêneo',
  silenes: 'silenes',
  silenia: 'silênia',
  silenos: 'silenos',
  silense: 'silense',
  silente: 'silente',
  silepse: 'silepse',
  silepta: 'silepta',
  silerca: 'silerca',
  sileres: 'síleres',
  sileria: 'siléria',
  silesis: 'silésis',
  silfida: 'sílfida',
  silfide: 'sílfide',
  silfion: 'sílfion',
  silfios: 'sílfios',
  silhais: 'silhais',
  silhoes: 'silhões',
  siliaca: 'silíaca',
  siliaco: 'silíaco',
  silibos: 'sílibos',
  silicas: 'sílicas',
  silicea: 'silícea',
  siliceo: 'silíceo',
  silices: 'sílices',
  silicio: 'silício',
  silicos: 'sílicos',
  silidea: 'silídea',
  silideo: 'silídeo',
  siligem: 'siligem',
  siligos: 'siligos',
  sililos: 'sililos',
  silimba: 'silimba',
  silinas: 'silinas',
  silinga: 'silinga',
  silingo: 'silingo',
  siliqua: 'síliqua',
  silogeu: 'silogeu',
  siltito: 'siltito',
  siltosa: 'siltosa',
  siltoso: 'siltoso',
  silundo: 'silundo',
  siluras: 'siluras',
  silures: 'silures',
  siluros: 'siluros',
  silvada: 'silvada',
  silvado: 'silvado',
  silvais: 'silvais',
  silvamo: 'silvamo',
  silvana: 'silvana',
  silvano: 'silvano',
  silvara: 'silvara',
  silvato: 'silvato',
  silvava: 'silvava',
  silvedo: 'silvedo',
  silveis: 'silveis',
  silvemo: 'silvemo',
  silvias: 'sílvias',
  silvica: 'sílvica',
  silvico: 'sílvico',
  silvida: 'sílvida',
  silvina: 'silvina',
  silvino: 'silvino',
  silvita: 'silvita',
  silvite: 'silvite',
  silvoes: 'silvões',
  silvosa: 'silvosa',
  silvoso: 'silvoso',
  simabas: 'simabas',
  simarra: 'simarra',
  simbama: 'símbama',
  simbaoe: 'simbaoé',
  simbase: 'símbase',
  simbola: 'simbola',
  simbole: 'simbole',
  simbolo: 'símbolo',
  simelas: 'símelas',
  simelia: 'simelia',
  simelio: 'simélio',
  simelos: 'símelos',
  simento: 'simento',
  simetes: 'simetes',
  simiana: 'simiana',
  simiano: 'simiano',
  simicas: 'símicas',
  simicos: 'símicos',
  simiida: 'simíida',
  similar: 'similar',
  similes: 'símiles',
  similia: 'similia',
  simiras: 'simiras',
  simiris: 'simiris',
  simocas: 'simocas',
  simocos: 'simocos',
  simonia: 'simonia',
  simonte: 'simonte',
  simoria: 'simória',
  simotes: 'simotes',
  simpexe: 'simpexe',
  simplao: 'simplão',
  simples: 'simples',
  simplex: 'simplex',
  simplez: 'simplez',
  simpode: 'símpode',
  simpodo: 'símpodo',
  simpulo: 'símpulo',
  simulai: 'simulai',
  simulam: 'simulam',
  simular: 'simular',
  simulas: 'simulas',
  simulei: 'simulei',
  simulem: 'simulem',
  simules: 'simules',
  simulia: 'simúlia',
  simulio: 'simúlio',
  simulos: 'símulos',
  simulou: 'simulou',
  simulta: 'simulta',
  sinabas: 'sinabas',
  sinabos: 'sinabos',
  sinadao: 'sinadão',
  sinadas: 'sinadas',
  sinador: 'sinador',
  sinados: 'sinados',
  sinafes: 'sínafes',
  sinafia: 'sinafia',
  sinagro: 'sinagro',
  sinaita: 'sinaíta',
  sinaite: 'sinaíte',
  sinaito: 'sinaíto',
  sinalai: 'sinalai',
  sinalam: 'sinalam',
  sinalar: 'sinalar',
  sinalas: 'sinalas',
  sinalei: 'sinalei',
  sinalem: 'sinalem',
  sinales: 'sinales',
  sinalou: 'sinalou',
  sinamos: 'sinamos',
  sinando: 'sinando',
  sinanta: 'sinanta',
  sinante: 'sinante',
  sinapea: 'sinápea',
  sinapes: 'sinapes',
  sinapis: 'sinápis',
  sinapse: 'sinapse',
  sinapta: 'sinapta',
  sinapto: 'sinapto',
  sinaram: 'sinaram',
  sinarao: 'sinarão',
  sinaras: 'sinarás',
  sinarca: 'sinarca',
  sinarei: 'sinarei',
  sinarem: 'sinarem',
  sinares: 'sinares',
  sinaria: 'sinaria',
  sinarmo: 'sinarmo',
  sinasse: 'sinasse',
  sinaste: 'sinaste',
  sinavam: 'sinavam',
  sinavas: 'sinavas',
  sinaxes: 'sinaxes',
  sincela: 'sincela',
  sincele: 'sincele',
  sincelo: 'sincelo',
  sinceno: 'sinceno',
  sincera: 'sincera',
  sincero: 'sincero',
  sincito: 'síncito',
  sincopa: 'síncopa',
  sincope: 'síncope',
  sincopo: 'sincopo',
  sindese: 'síndese',
  sindeto: 'síndeto',
  sindiba: 'sindiba',
  sindica: 'síndica',
  sindico: 'síndico',
  sindone: 'síndone',
  sindora: 'sindora',
  sinecas: 'sinecas',
  sinecia: 'sinecia',
  sinecos: 'sinecos',
  sinedra: 'sinedra',
  sinedro: 'sinedro',
  sineira: 'sineira',
  sineiro: 'sineiro',
  sinelas: 'sinelas',
  sinemas: 'sinemas',
  sinemio: 'sinêmio',
  sinemos: 'sinemos',
  sinergo: 'sinergo',
  sinervo: 'sinervo',
  sineses: 'síneses',
  sinetai: 'sinetai',
  sinetam: 'sinetam',
  sinetar: 'sinetar',
  sinetas: 'sinetas',
  sinetei: 'sinetei',
  sinetem: 'sinetem',
  sinetes: 'sinetes',
  sinetia: 'sinetia',
  sinetou: 'sinetou',
  sineura: 'sineura',
  sinfana: 'sínfana',
  sinfano: 'sínfano',
  sinfase: 'sínfase',
  sinfila: 'sínfila',
  sinfilo: 'sínfilo',
  sinfise: 'sínfise',
  sinfita: 'sínfita',
  sinfito: 'sínfito',
  sinfodo: 'sínfodo',
  singama: 'síngama',
  singamo: 'síngamo',
  singela: 'singela',
  singelo: 'singelo',
  singila: 'síngila',
  singilo: 'síngilo',
  singles: 'singles',
  singona: 'síngona',
  singono: 'síngono',
  singrai: 'singrai',
  singram: 'singram',
  singrar: 'singrar',
  singras: 'singras',
  singrei: 'singrei',
  singrem: 'singrem',
  singres: 'singres',
  singrou: 'singrou',
  singulo: 'síngulo',
  sinhama: 'sinhama',
  sinhara: 'sinhara',
  siniana: 'siniana',
  siniano: 'siniano',
  sinicas: 'sínicas',
  sinicos: 'sínicos',
  sinieta: 'sinieta',
  sinimbu: 'sinimbu',
  sininho: 'sininho',
  sinizai: 'sinizai',
  sinizam: 'sinizam',
  sinizar: 'sinizar',
  sinizas: 'sinizas',
  sinizei: 'sinizei',
  sinizem: 'sinizem',
  sinizes: 'sinizes',
  sinizou: 'sinizou',
  sinoble: 'sinoble',
  sinocas: 'sínocas',
  sinocos: 'sínocos',
  sinodal: 'sinodal',
  sinodas: 'sínodas',
  sinodos: 'sínodos',
  sinofre: 'sínofre',
  sinofro: 'sínofro',
  sinoica: 'sinoica',
  sinoico: 'sinoico',
  sinoita: 'sinoíta',
  sinopea: 'sinópea',
  sinopeo: 'sinópeo',
  sinopla: 'sinopla',
  sinople: 'sinople',
  sinopse: 'sinopse',
  sinotas: 'sinotas',
  sinotia: 'sinotia',
  sinotos: 'sinotos',
  sinovia: 'sinóvia',
  sinriza: 'sinriza',
  sinrizo: 'sinrizo',
  sinsara: 'sinsará',
  sinsins: 'sinsins',
  sintais: 'sintais',
  sintamo: 'sintamo',
  sintans: 'sintans',
  sintase: 'sintase',
  sintaxe: 'sintaxe',
  sinteca: 'sinteca',
  sinteco: 'sinteco',
  sintema: 'sintema',
  sintese: 'síntese',
  sinteta: 'sínteta',
  sintipo: 'síntipo',
  sintoma: 'sintoma',
  sintomo: 'síntomo',
  sintona: 'síntona',
  sintono: 'síntono',
  sintrao: 'sintrão',
  sintras: 'sintrãs',
  sintros: 'sintros',
  sinuada: 'sinuada',
  sinuado: 'sinuado',
  sinuais: 'sinuais',
  sinuamo: 'sinuamo',
  sinuara: 'sinuará',
  sinuava: 'sinuava',
  sinucai: 'sinucai',
  sinucam: 'sinucam',
  sinucar: 'sinucar',
  sinucas: 'sinucas',
  sinucos: 'sinucos',
  sinucou: 'sinucou',
  sinueis: 'sinueis',
  sinuelo: 'sinuelo',
  sinuemo: 'sinuemo',
  sinumbu: 'sinumbu',
  sinuosa: 'sinuosa',
  sinuose: 'sinuose',
  sinuoso: 'sinuoso',
  sinuque: 'sinuque',
  sinuras: 'sinuras',
  sinusal: 'sinusal',
  sinusia: 'sinúsia',
  sinzais: 'sinzais',
  siobona: 'siobona',
  siochos: 'siochos',
  sionita: 'sionita',
  sipaios: 'sipaios',
  sipalia: 'sipália',
  sipanea: 'sipânea',
  siparia: 'sipária',
  sipario: 'sipário',
  sipatao: 'sipatão',
  sipauba: 'sipaúba',
  sipeira: 'sipeira',
  sipibas: 'sipibas',
  sipibos: 'sipibos',
  sipilho: 'sipilho',
  sipiris: 'sipiris',
  sipouba: 'sipoúba',
  siracas: 'síracas',
  siracos: 'siracos',
  siracus: 'siracus',
  sirages: 'sirages',
  siralis: 'siralis',
  sirbato: 'sirbato',
  sirbota: 'sirbota',
  sirdeno: 'sirdeno',
  sirecas: 'sirecas',
  sirembo: 'sirembo',
  sirenai: 'sirenai',
  sirenam: 'sirenam',
  sirenar: 'sirenar',
  sirenas: 'sirenas',
  sirenei: 'sirenei',
  sirenem: 'sirenem',
  sirenes: 'sirenes',
  sirenia: 'sirênia',
  sirenio: 'sirênio',
  sirenou: 'sirenou',
  sirfida: 'sírfida',
  sirgada: 'sirgada',
  sirgado: 'sirgado',
  sirgais: 'sirgais',
  sirgara: 'sirgará',
  sirgava: 'sirgava',
  sirguei: 'sirguei',
  sirguem: 'sirguem',
  sirgues: 'sirgues',
  siriaca: 'siríaca',
  siriaco: 'siríaco',
  siriacu: 'siriaçu',
  siriano: 'siriano',
  siriase: 'siríase',
  siribas: 'siribás',
  siricas: 'siricas',
  siricha: 'siricha',
  siricto: 'siricto',
  siriena: 'siriena',
  sirieno: 'sirieno',
  sirigmo: 'sirigmo',
  sirinas: 'sirinas',
  siringa: 'siringa',
  siringe: 'siringe',
  sirinos: 'sirinos',
  sirioba: 'sirioba',
  siripua: 'siripuã',
  siriria: 'siriria',
  siriris: 'siriris',
  siritas: 'siritas',
  siritos: 'siritos',
  siriuba: 'siriúba',
  siriuva: 'siriúva',
  sirixas: 'sirixas',
  sirnios: 'sírnios',
  sirocos: 'sirocos',
  sirotes: 'sirotes',
  sirriza: 'sirriza',
  sirrizo: 'sirrizo',
  sirruns: 'sirruns',
  sirsaca: 'sirsaca',
  sirtica: 'sírtica',
  sirtico: 'sírtico',
  sirvais: 'sirvais',
  sirvamo: 'sirvamo',
  sirzais: 'sirzais',
  sirzamo: 'sirzamo',
  sirzino: 'sirzino',
  sisadao: 'sisadão',
  sisadas: 'sisadas',
  sisador: 'sisador',
  sisados: 'sisados',
  sisamos: 'sisamos',
  sisando: 'sisando',
  sisante: 'sisante',
  sisaram: 'sisaram',
  sisarao: 'sisarão',
  sisaras: 'sisarás',
  sisarei: 'sisarei',
  sisarem: 'sisarem',
  sisares: 'sisares',
  sisaria: 'sisaria',
  sisarmo: 'sisarmo',
  sisarra: 'sisarra',
  sisasse: 'sisasse',
  sisaste: 'sisaste',
  sisavam: 'sisavam',
  sisavas: 'sisavas',
  siseira: 'siseira',
  siseiro: 'siseiro',
  sisemos: 'sisemos',
  sisgola: 'sisgola',
  sisifia: 'sisífia',
  sisifio: 'sisífio',
  sisifos: 'sísifos',
  sismada: 'sismada',
  sismado: 'sismado',
  sismais: 'sismais',
  sismamo: 'sismamo',
  sismara: 'sismara',
  sismava: 'sismava',
  sismeis: 'sismeis',
  sismemo: 'sismemo',
  sismica: 'sísmica',
  sismico: 'sísmico',
  sisonas: 'sisonas',
  sisoria: 'sisória',
  sisorio: 'sisório',
  sispono: 'síspono',
  sissoma: 'sissoma',
  sissomo: 'sissomo',
  sistase: 'sístase',
  sistate: 'sístate',
  sisteco: 'sisteco',
  sistema: 'sistema',
  sisteme: 'sisteme',
  sistemo: 'sistemo',
  sistena: 'sístena',
  sistila: 'sistila',
  sistilo: 'sistilo',
  sistina: 'sistina',
  sistino: 'sistino',
  sistola: 'sistola',
  sistole: 'sístole',
  sistolo: 'sistolo',
  sistros: 'sistros',
  sisudas: 'sisudas',
  sisudez: 'sisudez',
  sisudos: 'sisudos',
  sisuras: 'sisuras',
  sitadao: 'sitadão',
  sitadas: 'sitadas',
  sitador: 'sitador',
  sitados: 'sitados',
  sitamos: 'sitamos',
  sitanas: 'sitanas',
  sitando: 'sitando',
  sitante: 'sitante',
  sitaram: 'sitaram',
  sitarao: 'sitarão',
  sitaras: 'sitaras',
  sitarca: 'sitarca',
  sitarei: 'sitarei',
  sitarem: 'sitarem',
  sitares: 'sitares',
  sitaria: 'sitária',
  sitario: 'sitário',
  sitaris: 'sítaris',
  sitarmo: 'sitarmo',
  sitasse: 'sitasse',
  sitaste: 'sitaste',
  sitavam: 'sitavam',
  sitavas: 'sitavas',
  sitelas: 'sitelas',
  sitemos: 'sitemos',
  sitiada: 'sitiada',
  sitiado: 'sitiado',
  sitiais: 'sitiais',
  sitiamo: 'sitiamo',
  sitiana: 'sitiana',
  sitiano: 'sitiano',
  sitiara: 'sitiara',
  sitiava: 'sitiava',
  siticas: 'síticas',
  siticos: 'síticos',
  sitidas: 'sítidas',
  sitidea: 'sitídea',
  sitideo: 'sitídeo',
  sitieis: 'sitieis',
  sitiemo: 'sitiemo',
  sitioca: 'sitioca',
  sititas: 'sititas',
  sitites: 'sitites',
  sititos: 'sititos',
  sitonas: 'sitonas',
  sitones: 'sítones',
  sitonia: 'sitônia',
  sitonio: 'sitônio',
  situada: 'situada',
  situado: 'situado',
  situais: 'situais',
  situamo: 'situamo',
  situara: 'situara',
  situava: 'situava',
  situeis: 'situeis',
  situemo: 'situemo',
  situlas: 'sítulas',
  sivales: 'sivales',
  sivanas: 'sivanas',
  sivanes: 'sivanes',
  sivanis: 'sivanis',
  sizigia: 'sizígia',
  sizigio: 'sizígio',
  skating: 'skating',
  slalons: 'slalons',
  slavina: 'slavina',
  sloanea: 'sloânea',
  slogane: 'slôgane',
  slogans: 'slogans',
  smogues: 'smogues',
  smoking: 'smoking',
  snifada: 'snifada',
  snifado: 'snifado',
  snifais: 'snifais',
  snifamo: 'snifamo',
  snifara: 'snifará',
  snifava: 'snifava',
  snifeis: 'snifeis',
  snifemo: 'snifemo',
  snooker: 'snooker',
  snorkel: 'snorkel',
  soabram: 'soabram',
  soabras: 'soabras',
  soabrem: 'soabrem',
  soabres: 'soabres',
  soabria: 'soabria',
  soabrir: 'soabrir',
  soabris: 'soabris',
  soabriu: 'soabriu',
  soadoes: 'soadões',
  soadona: 'soadona',
  soadora: 'soadora',
  soagens: 'soagens',
  soalhai: 'soalhai',
  soalhal: 'soalhal',
  soalham: 'soalham',
  soalhar: 'soalhar',
  soalhas: 'soalhas',
  soalhei: 'soalhei',
  soalhem: 'soalhem',
  soalhes: 'soalhes',
  soalhos: 'soalhos',
  soalhou: 'soalhou',
  soantes: 'soantes',
  soaozao: 'soãozão',
  soardes: 'soardes',
  soareis: 'soareis',
  soaremo: 'soaremo',
  soariam: 'soariam',
  soarias: 'soarias',
  soarmos: 'soarmos',
  soassai: 'soassai',
  soassam: 'soassam',
  soassar: 'soassar',
  soassas: 'soassas',
  soassei: 'soassei',
  soassem: 'soassem',
  soasses: 'soasses',
  soassou: 'soassou',
  soastes: 'soastes',
  soaveis: 'soáveis',
  sobadas: 'sobadas',
  sobados: 'sobados',
  sobalca: 'sobalça',
  sobalce: 'sobalce',
  sobalco: 'sobalço',
  sobarba: 'sobarba',
  sobaura: 'sobaúra',
  sobcapa: 'sobcapa',
  sobeira: 'sobeira',
  sobejai: 'sobejai',
  sobejam: 'sobejam',
  sobejar: 'sobejar',
  sobejas: 'sobejas',
  sobejei: 'sobejei',
  sobejem: 'sobejem',
  sobejes: 'sobejes',
  sobejos: 'sobejos',
  sobejou: 'sobejou',
  sobelho: 'sobelho',
  soberba: 'soberba',
  soberbe: 'soberbe',
  soberbo: 'soberbo',
  soberta: 'soberta',
  sobesca: 'sobesca',
  sobessa: 'sobessa',
  sobesta: 'sobestá',
  sobeste: 'sobeste',
  sobesto: 'sobesto',
  sobetas: 'sobetas',
  sobiada: 'sobiada',
  sobiado: 'sobiado',
  sobiais: 'sobiais',
  sobiamo: 'sobiamo',
  sobiara: 'sobiará',
  sobiava: 'sobiava',
  sobieis: 'sobieis',
  sobiemo: 'sobiemo',
  sobiote: 'sobiote',
  sobnega: 'sobnega',
  sobnego: 'sobnego',
  soboada: 'soboadã',
  soboaga: 'soboagã',
  soboles: 'sóboles',
  soborda: 'soborda',
  soboros: 'soborós',
  sobpear: 'sobpear',
  sobpoem: 'sobpõem',
  sobpoes: 'sobpões',
  sobpora: 'sobporá',
  sobraca: 'sobraça',
  sobrace: 'sobrace',
  sobraco: 'sobraço',
  sobrada: 'sobrada',
  sobrade: 'sobrade',
  sobrado: 'sobrado',
  sobrais: 'sobrais',
  sobraji: 'sobraji',
  sobraju: 'sobraju',
  sobramo: 'sobramo',
  sobrara: 'sobrara',
  sobrasa: 'sobrasa',
  sobrase: 'sobrase',
  sobraso: 'sobraso',
  sobrava: 'sobrava',
  sobrecu: 'sobrecu',
  sobrego: 'sobrego',
  sobreir: 'sobreir',
  sobreis: 'sobreis',
  sobremo: 'sobremo',
  sobrepe: 'sobrepé',
  sobrepo: 'sobrepô',
  sobresi: 'sobresi',
  sobrias: 'sóbrias',
  sobrigo: 'sobrigo',
  sobrios: 'sóbrios',
  sobsaia: 'sobsaia',
  socacao: 'socação',
  socadao: 'socadão',
  socadas: 'socadas',
  socador: 'socador',
  socados: 'socados',
  socairo: 'socairo',
  socaite: 'soçaite',
  socalca: 'socalca',
  socalco: 'socalco',
  socamos: 'socamos',
  socando: 'socando',
  socanta: 'socantã',
  socante: 'socante',
  socapas: 'socapas',
  socaram: 'socaram',
  socarao: 'socarão',
  socaras: 'socaras',
  socarei: 'socarei',
  socarem: 'socarem',
  socares: 'socares',
  socaria: 'socaria',
  socarmo: 'socarmo',
  socarra: 'socarrã',
  socasse: 'socasse',
  socaste: 'socaste',
  socatas: 'socatas',
  socates: 'socates',
  socavai: 'socavai',
  socavam: 'socavam',
  socavao: 'socavão',
  socavar: 'socavar',
  socavas: 'socavas',
  socavei: 'socavei',
  socavem: 'socavem',
  socaves: 'socaves',
  socavou: 'socavou',
  sochear: 'sochear',
  socheio: 'sócheio',
  sochiai: 'sochiai',
  sochiam: 'sochiam',
  sochiar: 'sochiar',
  sochias: 'sochias',
  sochiei: 'sochiei',
  sochiem: 'sochiem',
  sochies: 'sochies',
  sochiou: 'sochiou',
  sochoes: 'sochões',
  sociais: 'sociais',
  soclope: 'soclopé',
  socobra: 'soçobra',
  socobre: 'soçobre',
  socobro: 'soçobro',
  sococai: 'soçocai',
  sococam: 'soçocam',
  sococar: 'soçocar',
  sococas: 'soçocas',
  sococos: 'sococós',
  sococou: 'soçocou',
  socolor: 'socolor',
  socopes: 'socopés',
  socopos: 'socopos',
  socoque: 'soçoque',
  socorna: 'socorna',
  socorne: 'socorne',
  socorno: 'socorno',
  socoros: 'socorós',
  socorra: 'socorra',
  socorre: 'socorre',
  socorri: 'socorri',
  socorro: 'socorro',
  socotos: 'socotos',
  socotri: 'socotri',
  socovao: 'socovão',
  socovas: 'socovás',
  socumes: 'socumes',
  sodadao: 'sodadão',
  sodadas: 'sodadas',
  sodador: 'sodador',
  sodados: 'sodados',
  sodagar: 'sodagar',
  sodaita: 'sodaíta',
  sodaite: 'sodaíte',
  sodaito: 'sodaíto',
  sodamos: 'sodamos',
  sodando: 'sodando',
  sodante: 'sodante',
  sodaram: 'sodaram',
  sodarao: 'sodarão',
  sodaras: 'sodarás',
  sodarei: 'sodarei',
  sodarem: 'sodarem',
  sodares: 'sodares',
  sodaria: 'sodaria',
  sodarmo: 'sodarmo',
  sodasse: 'sodasse',
  sodaste: 'sodaste',
  sodatol: 'sodatol',
  sodavam: 'sodavam',
  sodavas: 'sodavas',
  soddita: 'soddita',
  sodemos: 'sodemos',
  sodicas: 'sodicas',
  sodicos: 'sódicos',
  soditas: 'soditas',
  sodites: 'sodites',
  sodomia: 'sodomia',
  soedade: 'soedade',
  soedora: 'soedora',
  soeiras: 'soeiras',
  soengas: 'soengas',
  soerdes: 'soerdes',
  soereis: 'soereis',
  soergam: 'soergam',
  soergas: 'soergas',
  soergue: 'soergue',
  soergui: 'soergui',
  soeriam: 'soeriam',
  soerias: 'soerias',
  soermos: 'soermos',
  soessem: 'soessem',
  soesses: 'soesses',
  soestes: 'soestes',
  soestra: 'soestra',
  soestro: 'soestro',
  soezice: 'soezice',
  sofenas: 'sofenas',
  sofenha: 'sofenha',
  sofenho: 'sofenho',
  sofenos: 'sofenos',
  soferim: 'soferim',
  sofisma: 'sofisma',
  sofisme: 'sofisme',
  sofismo: 'sofismo',
  sofista: 'sofista',
  sofitos: 'sofitos',
  sofolie: 'sofolié',
  soforai: 'soforai',
  soforam: 'soforam',
  soforar: 'soforar',
  soforas: 'sóforas',
  soforea: 'sofórea',
  soforei: 'soforei',
  soforem: 'soforem',
  soforeo: 'sofóreo',
  sofores: 'sofores',
  soforim: 'soforim',
  soforou: 'soforou',
  sofrais: 'sofrais',
  soframo: 'soframo',
  sofreai: 'sofreai',
  sofrear: 'sofrear',
  sofreei: 'sofreei',
  sofrega: 'sôfrega',
  sofrego: 'sôfrego',
  sofreia: 'sofreia',
  sofreie: 'sofreie',
  sofreio: 'sofreio',
  sofreis: 'sofreis',
  sofremo: 'sofremo',
  sofrena: 'sofrena',
  sofrene: 'sofrene',
  sofreno: 'sofreno',
  sofreou: 'sofreou',
  sofrera: 'sofrera',
  sofriam: 'sofriam',
  sofrias: 'sofrias',
  sofrida: 'sofrida',
  sofrido: 'sofrido',
  softbol: 'softbol',
  sogacos: 'sogaços',
  sogadao: 'sogadão',
  sogadas: 'sogadas',
  sogador: 'sogador',
  sogados: 'sogados',
  sogamos: 'sogamos',
  sogando: 'sogando',
  sogante: 'sogante',
  sogaram: 'sogaram',
  sogarao: 'sogarão',
  sogaras: 'sogaras',
  sogarei: 'sogarei',
  sogarem: 'sogarem',
  sogares: 'sogares',
  sogaria: 'sogaria',
  sogasse: 'sogasse',
  sogaste: 'sogaste',
  sogatas: 'sogatas',
  sogavam: 'sogavam',
  sogavas: 'sogavas',
  sograda: 'sograda',
  sogrado: 'sogrado',
  sograis: 'sograis',
  sogramo: 'sogramo',
  sograra: 'sogrará',
  sograva: 'sograva',
  sogreis: 'sogreis',
  sogremo: 'sogremo',
  sogueis: 'sogueis',
  soiamos: 'soíamos',
  soidade: 'soidade',
  soidoes: 'soidões',
  soidona: 'soidona',
  soidosa: 'soidosa',
  soidoso: 'soidoso',
  soieira: 'soieira',
  soieria: 'soiéria',
  soionas: 'soionas',
  soiosas: 'soiosas',
  soiotas: 'soiotas',
  soiotes: 'soiotes',
  soirees: 'soirées',
  sojigai: 'sojigai',
  sojigam: 'sojigam',
  sojigar: 'sojigar',
  sojigas: 'sojigas',
  sojigou: 'sojigou',
  sojigue: 'sojigue',
  sojoada: 'sojoada',
  sojorna: 'sojorna',
  sojorne: 'sojorne',
  sojorno: 'sojorno',
  sojugai: 'sojugai',
  sojugam: 'sojugam',
  sojugar: 'sojugar',
  sojugas: 'sojugas',
  sojugou: 'sojugou',
  sojugue: 'sojugue',
  solacai: 'solaçai',
  solacam: 'solaçam',
  solacao: 'solação',
  solacar: 'solaçar',
  solacas: 'solaças',
  solacei: 'solacei',
  solacem: 'solacem',
  solaces: 'solaces',
  solacou: 'solaçou',
  soladao: 'soladão',
  soladas: 'soladas',
  solador: 'solador',
  solados: 'solados',
  solagem: 'solagem',
  solamas: 'solamas',
  solamos: 'solamos',
  solanas: 'solanas',
  solanca: 'solanca',
  solanco: 'solanco',
  solando: 'solando',
  solanea: 'solânea',
  solaneo: 'solâneo',
  solanos: 'solanos',
  solante: 'solante',
  solapai: 'solapai',
  solapam: 'solapam',
  solapao: 'solapão',
  solapar: 'solapar',
  solapas: 'solapas',
  solapei: 'solapei',
  solapem: 'solapem',
  solapes: 'solapes',
  solapou: 'solapou',
  solaram: 'solaram',
  solarao: 'solarão',
  solaras: 'solaras',
  solarei: 'solarei',
  solarem: 'solarem',
  solares: 'solares',
  solaria: 'solaria',
  solario: 'solário',
  solarmo: 'solarmo',
  solasse: 'solasse',
  solaste: 'solaste',
  solavam: 'solavam',
  solavas: 'solavas',
  solazai: 'solazai',
  solazam: 'solazam',
  solazar: 'solazar',
  solazas: 'solazas',
  solazei: 'solazei',
  solazem: 'solazem',
  solazes: 'solazes',
  solazou: 'solazou',
  solcris: 'solcris',
  soldada: 'soldada',
  soldado: 'soldado',
  soldais: 'soldais',
  soldamo: 'soldamo',
  soldara: 'soldara',
  soldava: 'soldava',
  soldeis: 'soldeis',
  soldemo: 'soldemo',
  soldoes: 'soldões',
  soldras: 'soldras',
  soldura: 'soldura',
  soleada: 'soleada',
  soleado: 'soleado',
  soleais: 'soleais',
  soleara: 'soleara',
  soleava: 'soleava',
  solecai: 'solecai',
  solecam: 'solecam',
  solecar: 'solecar',
  solecas: 'solecas',
  solecou: 'solecou',
  soleeis: 'soleeis',
  soleiam: 'soleiam',
  soleias: 'soleias',
  soleiem: 'soleiem',
  soleies: 'soleies',
  soleina: 'soleína',
  soleira: 'soleira',
  soleiro: 'soleiro',
  solemia: 'solêmia',
  solemos: 'solemos',
  solenes: 'solenes',
  solenio: 'solênio',
  solense: 'solense',
  soleque: 'soleque',
  solerte: 'solerte',
  soletas: 'soletas',
  soletra: 'soletra',
  soletre: 'soletre',
  soletro: 'soletro',
  solevai: 'solevai',
  solevam: 'solevam',
  solevar: 'solevar',
  solevas: 'solevas',
  solevei: 'solevei',
  solevem: 'solevem',
  soleves: 'soleves',
  solevou: 'solevou',
  solfada: 'solfada',
  solfado: 'solfado',
  solfais: 'solfais',
  solfamo: 'solfamo',
  solfara: 'solfará',
  solfava: 'solfava',
  solfeis: 'solfeis',
  solfeja: 'solfeja',
  solfeje: 'solfeje',
  solfejo: 'solfejo',
  solfemo: 'solfemo',
  solhada: 'solhada',
  solhado: 'solhado',
  solhais: 'solhais',
  solhamo: 'solhamo',
  solhara: 'solhará',
  solhava: 'solhava',
  solheis: 'solheis',
  solhemo: 'solhemo',
  solicas: 'solicas',
  solidai: 'solidai',
  solidam: 'solidam',
  solidao: 'solidão',
  solidar: 'solidar',
  solidas: 'sólidas',
  solidei: 'solidei',
  solidem: 'solidem',
  solides: 'solides',
  solideu: 'solidéu',
  solidez: 'solidez',
  solidos: 'sólidos',
  solidou: 'solidou',
  solimao: 'solimão',
  solimas: 'sólimas',
  solimos: 'sólimos',
  solinas: 'solinas',
  solinha: 'solinha',
  solinhe: 'solinhe',
  solinho: 'solinho',
  soliota: 'soliota',
  solipas: 'solipas',
  solipes: 'solipés',
  solipso: 'solipso',
  solista: 'solista',
  solitas: 'sólitas',
  soliton: 'sóliton',
  solitos: 'sólitos',
  solmiza: 'solmizá',
  solmize: 'solmize',
  solmizo: 'solmizo',
  solobro: 'solobro',
  solovox: 'solovox',
  soltada: 'soltada',
  soltado: 'soltado',
  soltais: 'soltais',
  soltamo: 'soltamo',
  soltara: 'soltara',
  soltava: 'soltava',
  solteis: 'solteis',
  soltemo: 'soltemo',
  soltura: 'soltura',
  solucai: 'soluçai',
  solucam: 'soluçam',
  solucao: 'solução',
  solucar: 'soluçar',
  solucas: 'soluças',
  solucei: 'solucei',
  solucem: 'solucem',
  soluces: 'soluces',
  solucos: 'soluços',
  solucou: 'soluçou',
  solutas: 'solutas',
  solutol: 'solutol',
  solutos: 'solutos',
  soluvel: 'solúvel',
  solvais: 'solvais',
  solveio: 'solveio',
  solveis: 'solveis',
  solvemo: 'solvemo',
  solvens: 'solvéns',
  solveol: 'solveol',
  solvera: 'solverá',
  solviam: 'solviam',
  solvias: 'solvias',
  solvida: 'solvida',
  solvido: 'solvido',
  solvier: 'solvier',
  solvira: 'solvirá',
  solzoes: 'solzões',
  somacao: 'somação',
  somadao: 'somadão',
  somadas: 'somadas',
  somador: 'somador',
  somados: 'somados',
  somalis: 'somalis',
  somamos: 'somamos',
  somando: 'somando',
  somante: 'somante',
  somaram: 'somaram',
  somarao: 'somarão',
  somaras: 'somaras',
  somarei: 'somarei',
  somarem: 'somarem',
  somares: 'somares',
  somaria: 'somaria',
  somarmo: 'somarmo',
  somasco: 'somasco',
  somasse: 'somasse',
  somaste: 'somaste',
  somatos: 'somatos',
  somavam: 'somavam',
  somavas: 'somavas',
  sombrai: 'sombrai',
  sombral: 'sombral',
  sombram: 'sombram',
  sombrar: 'sombrar',
  sombras: 'sombras',
  sombrea: 'sombreá',
  sombrei: 'sombrei',
  sombrem: 'sombrem',
  sombres: 'sombres',
  sombria: 'sombria',
  sombrio: 'sombrio',
  sombrou: 'sombrou',
  someira: 'someira',
  someiro: 'someiro',
  somemos: 'somemos',
  somenos: 'somenos',
  somente: 'somente',
  sometam: 'sometam',
  sometas: 'sometas',
  sometei: 'sometei',
  sometem: 'sometem',
  someter: 'someter',
  sometes: 'sometes',
  someteu: 'someteu',
  sometia: 'sometia',
  somitos: 'somitos',
  sommier: 'sommier',
  somnata: 'somnata',
  somneca: 'somneca',
  somnial: 'somnial',
  somonte: 'somonte',
  sompada: 'sompada',
  sompado: 'sompado',
  sompais: 'sompais',
  sompamo: 'sompamo',
  sompara: 'sompara',
  sompava: 'sompava',
  sompeis: 'sompeis',
  sompemo: 'sompemo',
  sonadao: 'sonadão',
  sonadas: 'sonadas',
  sonador: 'sonador',
  sonados: 'sonados',
  sonajas: 'sonajas',
  sonalha: 'sonalha',
  sonamos: 'sonamos',
  sonando: 'sonando',
  sonante: 'sonante',
  sonaram: 'sonaram',
  sonarao: 'sonarão',
  sonaras: 'sonaras',
  sonarei: 'sonarei',
  sonarem: 'sonarem',
  sonares: 'sonares',
  sonaria: 'sonaria',
  sonarmo: 'sonarmo',
  sonasse: 'sonasse',
  sonaste: 'sonaste',
  sonatas: 'sonatas',
  sonavam: 'sonavam',
  sonavas: 'sonavas',
  sonaves: 'sonaves',
  sondada: 'sondada',
  sondado: 'sondado',
  sondaia: 'sondaia',
  sondais: 'sondais',
  sondamo: 'sondamo',
  sondara: 'sondara',
  sondava: 'sondava',
  sondeis: 'sondeis',
  sondemo: 'sondemo',
  sonecas: 'sonecas',
  sonegai: 'sonegai',
  sonegam: 'sonegam',
  sonegar: 'sonegar',
  sonegas: 'sonegas',
  sonegou: 'sonegou',
  sonegue: 'sonegue',
  soneira: 'soneira',
  sonemos: 'sonemos',
  sonense: 'sonense',
  sonento: 'sonento',
  sonetai: 'sonetai',
  sonetam: 'sonetam',
  sonetar: 'sonetar',
  sonetas: 'sonetas',
  sonetea: 'soneteá',
  sonetei: 'sonetei',
  sonetem: 'sonetem',
  sonetes: 'sonetes',
  sonetos: 'sonetos',
  sonetou: 'sonetou',
  songues: 'songues',
  sonhada: 'sonhada',
  sonhado: 'sonhado',
  sonhais: 'sonhais',
  sonhamo: 'sonhamo',
  sonhara: 'sonhara',
  sonhava: 'sonhava',
  sonheis: 'sonheis',
  sonhemo: 'sonhemo',
  sonhosa: 'sonhosa',
  sonhoso: 'sonhoso',
  sonicas: 'sônicas',
  sonicos: 'sônicos',
  sonidas: 'sonidas',
  sonidos: 'sonidos',
  soninho: 'soninho',
  soninia: 'sonínia',
  sonismo: 'sonismo',
  sonista: 'sonista',
  sonivia: 'sonívia',
  sonivio: 'sonívio',
  sonneis: 'sonneis',
  sonoite: 'sonoite',
  sonoras: 'sonoras',
  sonoros: 'sonoros',
  sonoute: 'sonoute',
  sonquia: 'sônquia',
  sonsice: 'sonsice',
  sonsoes: 'sonsões',
  sonurno: 'sonurno',
  sopadas: 'sopadas',
  sopaina: 'sopaina',
  sopanga: 'sopanga',
  sopapai: 'sopapai',
  sopapam: 'sopapam',
  sopapar: 'sopapar',
  sopapas: 'sopapas',
  sopapea: 'sopapeá',
  sopapei: 'sopapei',
  sopapem: 'sopapem',
  sopapes: 'sopapes',
  sopapos: 'sopapos',
  sopapou: 'sopapou',
  sopeada: 'sopeada',
  sopeado: 'sopeado',
  sopeais: 'sopeais',
  sopeara: 'sopeara',
  sopeava: 'sopeava',
  sopeeis: 'sopeeis',
  sopegai: 'sopegai',
  sopegam: 'sopegam',
  sopegar: 'sopegar',
  sopegas: 'sopegas',
  sopegou: 'sopegou',
  sopegue: 'sopegue',
  sopeiam: 'sopeiam',
  sopeias: 'sopeias',
  sopeiem: 'sopeiem',
  sopeies: 'sopeies',
  sopeira: 'sopeira',
  sopeiro: 'sopeiro',
  sopelao: 'sopelão',
  sopendo: 'sopendo',
  sopense: 'sopense',
  sopesai: 'sopesai',
  sopesam: 'sopesam',
  sopesar: 'sopesar',
  sopesas: 'sopesas',
  sopesei: 'sopesei',
  sopesem: 'sopesem',
  sopeses: 'sopeses',
  sopesou: 'sopesou',
  sopetea: 'sopeteá',
  sopiada: 'sopiada',
  sopiado: 'sopiado',
  sopiais: 'sopiais',
  sopiamo: 'sopiamo',
  sopiara: 'sopiará',
  sopiava: 'sopiava',
  sopieis: 'sopieis',
  sopiemo: 'sopiemo',
  sopilao: 'sopilão',
  sopilho: 'sopilho',
  sopinha: 'sopinha',
  sopista: 'sopista',
  sopitai: 'sopitai',
  sopitam: 'sopitam',
  sopitar: 'sopitar',
  sopitas: 'sopitas',
  sopitei: 'sopitei',
  sopitem: 'sopitem',
  sopites: 'sopites',
  sopitou: 'sopitou',
  soponta: 'soponta',
  soponte: 'soponte',
  soponto: 'soponto',
  soporal: 'soporal',
  sopores: 'sopores',
  soprada: 'soprada',
  soprado: 'soprado',
  soprais: 'soprais',
  sopramo: 'sopramo',
  soprano: 'soprano',
  soprara: 'soprara',
  soprava: 'soprava',
  sopreis: 'sopreis',
  sopremo: 'sopremo',
  sopresa: 'sopresa',
  soprese: 'soprese',
  sopreso: 'sopreso',
  soprete: 'soprete',
  sopreza: 'sopreza',
  sopreze: 'sopreze',
  soprezo: 'soprezo',
  soprior: 'soprior',
  soproes: 'soprões',
  soprosa: 'soprosa',
  soproso: 'soproso',
  sopubia: 'sopúbia',
  sopuxai: 'sopuxai',
  sopuxam: 'sopuxam',
  sopuxar: 'sopuxar',
  sopuxas: 'sopuxas',
  sopuxei: 'sopuxei',
  sopuxem: 'sopuxem',
  sopuxes: 'sopuxes',
  sopuxou: 'sopuxou',
  soquais: 'soquais',
  soquamo: 'soquamo',
  soqueai: 'soqueai',
  soquear: 'soquear',
  soqueei: 'soqueei',
  soqueia: 'soqueia',
  soqueie: 'soqueie',
  soqueio: 'soqueio',
  soqueis: 'soqueis',
  soquemo: 'soquemo',
  soqueou: 'soqueou',
  soquete: 'soquete',
  soquiam: 'soquiam',
  soquias: 'soquias',
  soquida: 'soquida',
  soquido: 'soquido',
  soquimo: 'soquimo',
  soquira: 'soquira',
  sorabia: 'sorábia',
  sorabio: 'sorábio',
  soracos: 'sóracos',
  soradao: 'soradão',
  soradas: 'soradas',
  sorador: 'sorador',
  sorados: 'sorados',
  sorames: 'sorames',
  soramos: 'soramos',
  soranas: 'soranas',
  sorando: 'sorando',
  soranos: 'soranos',
  sorante: 'sorante',
  soraram: 'soraram',
  sorarao: 'sorarão',
  soraras: 'soraras',
  sorarei: 'sorarei',
  sorarem: 'sorarem',
  sorares: 'sorares',
  soraria: 'soraria',
  sorarmo: 'sorarmo',
  sorasse: 'sorasse',
  soraste: 'soraste',
  soravam: 'soravam',
  soravas: 'soravas',
  sorbato: 'sorbato',
  sorbias: 'sórbias',
  sorbica: 'sorbicá',
  sorbico: 'sórbico',
  sorbina: 'sorbina',
  sorbios: 'sórbios',
  sorbita: 'sorbita',
  sorbite: 'sorbite',
  sorbois: 'sorbois',
  sorbose: 'sorbose',
  sorcoes: 'sorções',
  sorcona: 'sorçona',
  sordida: 'sórdida',
  sordido: 'sórdido',
  sordoes: 'sordões',
  soreada: 'soreada',
  soreado: 'soreado',
  soreais: 'soreais',
  soreara: 'soreará',
  soreava: 'soreava',
  soredia: 'sorédia',
  soredio: 'sorédio',
  soreeis: 'soreeis',
  soreiam: 'soreiam',
  soreias: 'soreias',
  soreiem: 'soreiem',
  soreies: 'soreies',
  sorelos: 'sorelos',
  soremas: 'soremas',
  soremos: 'soremos',
  soretes: 'soretes',
  sorfete: 'sorfete',
  soriana: 'soriana',
  soriano: 'soriano',
  sorices: 'sórices',
  soridio: 'sorídio',
  soriela: 'soriela',
  sorimao: 'sorimão',
  sorimas: 'sorimãs',
  soritas: 'soritas',
  sorites: 'sorites',
  sornada: 'sornada',
  sornado: 'sornado',
  sornais: 'sornais',
  sornamo: 'sornamo',
  sornara: 'sornará',
  sornava: 'sornava',
  sorneai: 'sorneai',
  sornear: 'sornear',
  sorneei: 'sorneei',
  sorneia: 'sorneia',
  sorneie: 'sorneie',
  sorneio: 'sorneio',
  sorneis: 'sorneis',
  sornemo: 'sornemo',
  sorneou: 'sorneou',
  sorniam: 'sorniam',
  sornias: 'sornias',
  sornice: 'sornice',
  sornida: 'sornida',
  sornido: 'sornido',
  sornimo: 'sornimo',
  sornira: 'sornirá',
  sorocas: 'sorocas',
  sorocea: 'sorócea',
  sorocos: 'sorocós',
  sorodia: 'soródia',
  sorodio: 'soródio',
  soronda: 'soronda',
  soronga: 'soronga',
  sorongo: 'sorongo',
  soronia: 'sorônia',
  soropas: 'soropas',
  sororal: 'sororal',
  sorores: 'sorores',
  sororia: 'sorória',
  sororio: 'sorório',
  sororos: 'sororós',
  sorosas: 'sorosas',
  soroses: 'soroses',
  sorosio: 'sorósio',
  sorosos: 'sorosos',
  sorraba: 'sorraba',
  sorrabe: 'sorrabe',
  sorrabo: 'sorrabo',
  sorraia: 'sorraia',
  sorrate: 'sorrate',
  sorrena: 'sorrena',
  sorrene: 'sorrene',
  sorreno: 'sorreno',
  sorriam: 'sorriam',
  sorrias: 'sorrias',
  sorrida: 'sorrida',
  sorride: 'sorride',
  sorrido: 'sorrido',
  sorriem: 'sorriem',
  sorrimo: 'sorrimo',
  sorrira: 'sorrira',
  sorriso: 'sorriso',
  sorroda: 'sorroda',
  sortais: 'sortais',
  sortamo: 'sortamo',
  sorteai: 'sorteai',
  sortear: 'sortear',
  sorteei: 'sorteei',
  sorteia: 'sorteia',
  sorteie: 'sorteie',
  sorteio: 'sorteio',
  sortela: 'sortela',
  sorteou: 'sorteou',
  sortiam: 'sortiam',
  sortias: 'sortias',
  sortida: 'sortida',
  sortido: 'sortido',
  sortimo: 'sortimo',
  sortira: 'sortira',
  sortuda: 'sortuda',
  sortudo: 'sortudo',
  sorumas: 'sorumas',
  sorvada: 'sorvada',
  sorvado: 'sorvado',
  sorvais: 'sorvais',
  sorvamo: 'sorvamo',
  sorvara: 'sorvará',
  sorvava: 'sorvava',
  sorveis: 'sorveis',
  sorvemo: 'sorvemo',
  sorvera: 'sorvera',
  sorvete: 'sorvete',
  sorviam: 'sorviam',
  sorvias: 'sorvias',
  sorvida: 'sorvida',
  sorvido: 'sorvido',
  sosanas: 'sosanas',
  sosanes: 'sosanes',
  sosanos: 'sosanos',
  sosiana: 'sosiana',
  sosiano: 'sosiano',
  sosibes: 'sósibes',
  soslaia: 'soslaia',
  soslaie: 'soslaie',
  soslaio: 'soslaio',
  sospita: 'sóspita',
  sospite: 'sospite',
  sospito: 'sospito',
  sossega: 'sossega',
  sossego: 'sossego',
  sostras: 'sostras',
  sostros: 'sostros',
  sotaans: 'sotaans',
  sotadao: 'sotadão',
  sotadas: 'sotadas',
  sotadea: 'sotádea',
  sotadeo: 'sotádeo',
  sotadeu: 'sotadeu',
  sotador: 'sotador',
  sotados: 'sotados',
  sotaina: 'sotaina',
  sotalas: 'sotalas',
  sotalha: 'sotalhá',
  sotalhe: 'sotalhe',
  sotalho: 'sotalho',
  sotalia: 'sotália',
  sotalol: 'sotalol',
  sotamos: 'sotamos',
  sotanas: 'sotanas',
  sotando: 'sotando',
  sotante: 'sotante',
  sotaque: 'sotaque',
  sotaram: 'sotaram',
  sotarao: 'sotarão',
  sotaras: 'sotarás',
  sotarei: 'sotarei',
  sotarem: 'sotarem',
  sotares: 'sotares',
  sotaria: 'sotaria',
  sotarmo: 'sotarmo',
  sotasse: 'sotasse',
  sotaste: 'sotaste',
  sotavam: 'sotavam',
  sotavas: 'sotavas',
  soteias: 'soteias',
  soteira: 'soteira',
  soteiro: 'soteiro',
  sotemos: 'sotemos',
  soteria: 'sotéria',
  soterim: 'soterim',
  soterra: 'soterra',
  soterre: 'soterre',
  soterro: 'soterro',
  sotiaca: 'sotíaca',
  sotiaco: 'sotíaco',
  sotiate: 'sotiate',
  sotnias: 'sótnias',
  sotobas: 'sotobas',
  sotopoe: 'sotopõe',
  sotopor: 'sotopor',
  sotopos: 'sotopôs',
  sotopus: 'sotopus',
  sotreta: 'sotreta',
  sotroco: 'sotroço',
  soturna: 'soturna',
  soturne: 'soturne',
  soturno: 'soturno',
  soubera: 'soubera',
  souffle: 'soufflé',
  soupica: 'soupicá',
  soupico: 'soupico',
  sousana: 'sousana',
  sousoes: 'sousões',
  sousona: 'sousona',
  soutada: 'soutada',
  soutado: 'soutado',
  soutais: 'soutais',
  soutamo: 'soutamo',
  soutara: 'soutará',
  soutava: 'soutava',
  souteis: 'souteis',
  soutemo: 'soutemo',
  soutien: 'soutien',
  sovacao: 'sovação',
  sovacos: 'sovacos',
  sovadao: 'sovadão',
  sovadas: 'sovadas',
  sovador: 'sovador',
  sovados: 'sovados',
  sovamos: 'sovamos',
  sovando: 'sovando',
  sovante: 'sovante',
  sovaram: 'sovaram',
  sovarao: 'sovarão',
  sovaras: 'sovaras',
  sovarei: 'sovarei',
  sovarem: 'sovarem',
  sovares: 'sovares',
  sovaria: 'sovaria',
  sovarmo: 'sovarmo',
  sovasse: 'sovasse',
  sovaste: 'sovaste',
  sovavam: 'sovavam',
  sovavas: 'sovavas',
  soveios: 'soveios',
  soveira: 'soveira',
  soveiro: 'soveiro',
  sovelai: 'sovelai',
  sovelam: 'sovelam',
  sovelao: 'sovelão',
  sovelar: 'sovelar',
  sovelas: 'sovelas',
  sovelei: 'sovelei',
  sovelem: 'sovelem',
  soveles: 'soveles',
  sovelou: 'sovelou',
  sovemos: 'sovemos',
  soveral: 'soveral',
  soveros: 'sôveros',
  soverta: 'soverta',
  soverte: 'soverte',
  soverti: 'soverti',
  soverto: 'soverto',
  sovessa: 'sovessa',
  sovetas: 'sovetas',
  sovetos: 'sovetos',
  soviete: 'soviete',
  sovinai: 'sovinai',
  sovinam: 'sovinam',
  sovinar: 'sovinar',
  sovinas: 'sovinas',
  sovinei: 'sovinei',
  sovinem: 'sovinem',
  sovines: 'sovines',
  sovinha: 'sovinha',
  sovinou: 'sovinou',
  sozinha: 'sozinha',
  sozinho: 'sozinho',
  sparing: 'sparing',
  speaker: 'speaker',
  sponsor: 'sponsor',
  sprinta: 'sprinta',
  sprinte: 'sprinte',
  sprinto: 'sprinto',
  stacato: 'stacato',
  stalses: 'stalses',
  standes: 'standes',
  starter: 'starter',
  stasita: 'stasita',
  statohm: 'statohm',
  stereas: 'stereas',
  stereos: 'stereos',
  stevens: 'stevens',
  steward: 'steward',
  stigmas: 'stigmas',
  stiques: 'stiques',
  stomias: 'stomias',
  stormia: 'stórmia',
  stretto: 'stretto',
  stricto: 'stricto',
  strings: 'strings',
  strudel: 'strudel',
  strumas: 'strumas',
  sturmia: 'stúrmia',
  stuzita: 'stuzita',
  suabias: 'suábias',
  suabios: 'suábios',
  suabita: 'suabita',
  suabite: 'suabite',
  suacuas: 'suaçuás',
  suacucu: 'suaçuçu',
  suadais: 'suadais',
  suadamo: 'suadamo',
  suadela: 'suadela',
  suadiam: 'suadiam',
  suadias: 'suadias',
  suadida: 'suadida',
  suadido: 'suadido',
  suadimo: 'suadimo',
  suadira: 'suadira',
  suadoes: 'suadões',
  suadona: 'suadona',
  suadora: 'suadora',
  suailis: 'suaílis',
  suainas: 'suainas',
  suangue: 'suangue',
  suanita: 'suanita',
  suantes: 'suantes',
  suaozao: 'suãozão',
  suardao: 'suardão',
  suardas: 'suardas',
  suardes: 'suardes',
  suareis: 'suareis',
  suaremo: 'suaremo',
  suariam: 'suariam',
  suarias: 'suarias',
  suarios: 'suários',
  suarmos: 'suarmos',
  suarnas: 'suarnas',
  suarnos: 'suarnos',
  suasana: 'suasana',
  suasano: 'suasano',
  suasiva: 'suasiva',
  suasivo: 'suasivo',
  suasoes: 'suasões',
  suasona: 'suasona',
  suasora: 'suasora',
  suassem: 'suassem',
  suasses: 'suasses',
  suastes: 'suastes',
  suaveis: 'suáveis',
  suaviza: 'suaviza',
  suavize: 'suavize',
  suavizo: 'suavizo',
  suazona: 'suãzona',
  subabia: 'subabia',
  subabos: 'subabos',
  subalar: 'subalar',
  subamos: 'subamos',
  subanal: 'subanal',
  subanel: 'subanel',
  subaras: 'subarás',
  subarea: 'subárea',
  subarte: 'subarte',
  subasta: 'subasta',
  subaste: 'subaste',
  subasto: 'subasto',
  subaves: 'subaves',
  subcapa: 'subcapa',
  subchao: 'subchão',
  subcopa: 'subcopa',
  subcopo: 'subcopo',
  subcoro: 'subcoro',
  subcujo: 'subcujo',
  subcume: 'subcume',
  subdeao: 'subdeão',
  subdeas: 'subdeãs',
  subdial: 'subdial',
  subdita: 'súbdita',
  subdito: 'súbdito',
  subedar: 'subedar',
  subedra: 'subedra',
  subedro: 'subedro',
  subeios: 'subeios',
  subenta: 'subenta',
  subente: 'subente',
  suberal: 'suberal',
  suberol: 'suberol',
  subexpo: 'subexpô',
  subface: 'subface',
  subfila: 'subfila',
  subfilo: 'subfilo',
  subfixa: 'subfixa',
  subguia: 'subguia',
  subidao: 'subidão',
  subidas: 'subidas',
  subidor: 'subidor',
  subidos: 'subidos',
  subieis: 'subíeis',
  subimos: 'subimos',
  subindo: 'subindo',
  subinte: 'subinte',
  subiote: 'subiote',
  subiram: 'subiram',
  subirao: 'subirão',
  subiras: 'subiras',
  subirei: 'subirei',
  subirem: 'subirem',
  subires: 'subires',
  subiria: 'subiria',
  subirmo: 'subirmo',
  subisse: 'subisse',
  subiste: 'subiste',
  subitas: 'súbitas',
  subitem: 'subitem',
  subitos: 'súbitos',
  subjaza: 'subjaza',
  subjaze: 'subjaze',
  subjazi: 'subjazi',
  subjazo: 'subjazo',
  subjuga: 'subjuga',
  subjugo: 'subjugo',
  subleva: 'subleva',
  subleve: 'subleve',
  sublevo: 'sublevo',
  sublide: 'sublide',
  subliga: 'subliga',
  sublima: 'sublima',
  sublime: 'sublime',
  sublimo: 'sublimo',
  subloca: 'subloca',
  subloco: 'subloco',
  sublote: 'sublote',
  submata: 'submata',
  submenu: 'submenu',
  submesa: 'submesa',
  submeta: 'submeta',
  submete: 'submete',
  submeti: 'submeti',
  submeto: 'submeto',
  submuro: 'submuro',
  subnega: 'subnegá',
  subnego: 'subnego',
  subnota: 'subnota',
  subnula: 'subnula',
  subnulo: 'subnulo',
  suborna: 'suborna',
  suborne: 'suborne',
  suborno: 'suborno',
  subpica: 'subpica',
  subpico: 'subpico',
  subpoem: 'subpõem',
  subpoes: 'subpões',
  subpolo: 'subpolo',
  subpora: 'subporá',
  subpuja: 'subpuja',
  subpuje: 'subpuje',
  subpujo: 'subpujo',
  subragi: 'subragi',
  subraji: 'subraji',
  subrede: 'subrede',
  subsede: 'subsede',
  subsiga: 'subsiga',
  subsigo: 'subsigo',
  subsino: 'subsino',
  subsola: 'subsola',
  subsole: 'subsole',
  subsolo: 'subsolo',
  subsome: 'subsome',
  subsuma: 'subsuma',
  subsume: 'subsume',
  subsumi: 'subsumi',
  subsumo: 'subsumo',
  subtela: 'subtela',
  subtema: 'subtema',
  subteto: 'subteto',
  subtipo: 'subtipo',
  subtons: 'subtons',
  subtrai: 'subtrai',
  subulao: 'subulão',
  subulas: 'súbulas',
  subulos: 'súbulos',
  suburgo: 'suburgo',
  suburra: 'suburra',
  subvale: 'subvale',
  subzero: 'subzero',
  subzona: 'subzona',
  sucadao: 'sucadão',
  sucadas: 'sucadas',
  sucador: 'sucador',
  sucados: 'sucados',
  sucamos: 'sucamos',
  sucando: 'sucando',
  sucanga: 'sucanga',
  sucante: 'sucante',
  sucapes: 'sucapés',
  sucaram: 'sucaram',
  sucarao: 'sucarão',
  sucaras: 'sucarás',
  sucarei: 'sucarei',
  sucarem: 'sucarem',
  sucares: 'sucares',
  sucaria: 'sucaria',
  sucarmo: 'sucarmo',
  sucaros: 'sucaros',
  sucasse: 'sucasse',
  sucaste: 'sucaste',
  sucatai: 'sucatai',
  sucatam: 'sucatam',
  sucatar: 'sucatar',
  sucatas: 'sucatas',
  sucatea: 'sucateá',
  sucatei: 'sucatei',
  sucatem: 'sucatem',
  sucates: 'sucates',
  sucatou: 'sucatou',
  sucavam: 'sucavam',
  sucavas: 'sucavas',
  succino: 'súccino',
  succoes: 'sucções',
  succona: 'sucçona',
  sucedam: 'sucedam',
  sucedas: 'sucedas',
  sucedei: 'sucedei',
  sucedem: 'sucedem',
  suceder: 'suceder',
  sucedes: 'sucedes',
  sucedeu: 'sucedeu',
  sucedia: 'sucedia',
  sucesso: 'sucesso',
  suciada: 'suciada',
  suciado: 'suciado',
  suciais: 'suciais',
  suciamo: 'suciamo',
  suciara: 'suciará',
  suciata: 'suciata',
  suciava: 'suciava',
  sucieis: 'sucieis',
  suciemo: 'suciemo',
  sucinea: 'sucínea',
  sucineo: 'sucíneo',
  sucinos: 'súcinos',
  sucinta: 'sucinta',
  sucinto: 'sucinto',
  sucisas: 'sucisas',
  sucosas: 'sucosas',
  sucosos: 'sucosos',
  sucovia: 'sucóvia',
  sucrase: 'sucrase',
  sucrato: 'sucrato',
  sucrois: 'sucróis',
  sucrosa: 'sucrosa',
  sucrose: 'sucrose',
  sucuabo: 'sucuabo',
  sucuaia: 'suçuaia',
  sucuama: 'sucuama',
  sucubas: 'súcubas',
  sucubos: 'súcubos',
  sucuiba: 'sucuíba',
  sucumba: 'sucumba',
  sucumbe: 'sucumbe',
  sucumbi: 'sucumbi',
  sucumbo: 'sucumbo',
  sucupis: 'sucupis',
  sucuris: 'sucuris',
  sucuriu: 'sucuriú',
  sucurus: 'sucurus',
  sucuuba: 'sucuuba',
  sucuuva: 'sucuuva',
  sudacao: 'sudação',
  sudadao: 'sudadão',
  sudadas: 'sudadas',
  sudador: 'sudador',
  sudados: 'sudados',
  sudairo: 'sudairo',
  sudamos: 'sudamos',
  sudando: 'sudando',
  sudanes: 'sudanês',
  sudante: 'sudante',
  sudaram: 'sudaram',
  sudarao: 'sudarão',
  sudaras: 'sudarás',
  sudarei: 'sudarei',
  sudarem: 'sudarem',
  sudares: 'sudares',
  sudaria: 'sudaria',
  sudario: 'sudário',
  sudarmo: 'sudarmo',
  sudasse: 'sudasse',
  sudaste: 'sudaste',
  sudavam: 'sudavam',
  sudavas: 'sudavas',
  sudavel: 'sudável',
  sudeira: 'sudeira',
  sudeiro: 'sudeiro',
  sudemos: 'sudemos',
  sudeste: 'sudeste',
  sudetas: 'sudetas',
  sudetos: 'sudetos',
  sudista: 'sudista',
  suditas: 'súditas',
  suditos: 'súditos',
  sudoita: 'sudoíta',
  sudoral: 'sudoral',
  sudotox: 'sudotox',
  suecias: 'suécias',
  suecios: 'suécios',
  suedine: 'suedine',
  sueiras: 'sueiras',
  suessao: 'suessão',
  suessas: 'suessãs',
  suessia: 'suessiã',
  suestai: 'suestai',
  suestam: 'suestam',
  suestar: 'suestar',
  suestas: 'suestas',
  suestei: 'suestei',
  suestem: 'suestem',
  suestes: 'suestes',
  suestou: 'suestou',
  suetras: 'suetras',
  suetros: 'suetros',
  sufaras: 'sufarás',
  sufeita: 'sufeita',
  sufeito: 'sufeito',
  sufetas: 'sufetas',
  sufetes: 'sufetes',
  sufidea: 'sufídea',
  sufideo: 'sufídeo',
  sufismo: 'sufismo',
  sufista: 'sufista',
  sufitas: 'sufitas',
  sufixai: 'sufixai',
  sufixal: 'sufixal',
  sufixam: 'sufixam',
  sufixar: 'sufixar',
  sufixas: 'sufixas',
  sufixei: 'sufixei',
  sufixem: 'sufixem',
  sufixes: 'sufixes',
  sufixos: 'sufixos',
  sufixou: 'sufixou',
  suflada: 'suflada',
  suflado: 'suflado',
  suflais: 'suflais',
  suflamo: 'suflamo',
  suflara: 'suflará',
  suflava: 'suflava',
  sufleis: 'sufleis',
  suflemo: 'suflemo',
  sufocai: 'sufocai',
  sufocam: 'sufocam',
  sufocar: 'sufocar',
  sufocas: 'sufocas',
  sufocos: 'sufocos',
  sufocou: 'sufocou',
  sufolie: 'sufolié',
  sufoque: 'sufoque',
  sufraga: 'sufraga',
  sufrago: 'sufrago',
  sufufes: 'sufufes',
  sufusao: 'sufusão',
  sugacao: 'sugação',
  sugadao: 'sugadão',
  sugadas: 'sugadas',
  sugador: 'sugador',
  sugados: 'sugados',
  sugamos: 'sugamos',
  sugando: 'sugando',
  sugante: 'sugante',
  sugaram: 'sugaram',
  sugarao: 'sugarão',
  sugaras: 'sugaras',
  sugarda: 'sugarda',
  sugarei: 'sugarei',
  sugarem: 'sugarem',
  sugares: 'sugares',
  sugaria: 'sugaria',
  sugarmo: 'sugarmo',
  sugasse: 'sugasse',
  sugaste: 'sugaste',
  sugavam: 'sugavam',
  sugavas: 'sugavas',
  sugerem: 'sugerem',
  sugeres: 'sugeres',
  sugeria: 'sugeria',
  sugerir: 'sugerir',
  sugeris: 'sugeris',
  sugeriu: 'sugeriu',
  sugesta: 'sugesta',
  sugesto: 'sugesto',
  sugilai: 'sugilai',
  sugilam: 'sugilam',
  sugilar: 'sugilar',
  sugilas: 'sugilas',
  sugilei: 'sugilei',
  sugilem: 'sugilem',
  sugiles: 'sugiles',
  sugilou: 'sugilou',
  sugiram: 'sugiram',
  sugiras: 'sugiras',
  sugueis: 'sugueis',
  suguemo: 'suguemo',
  suguiru: 'suguiru',
  suicero: 'suícero',
  suicida: 'suicida',
  suicide: 'suicide',
  suicido: 'suicido',
  suideas: 'suídeas',
  suideos: 'suídeos',
  suilate: 'suilate',
  suilzao: 'suilzão',
  suinara: 'suinara',
  suindas: 'suindás',
  suingar: 'suingar',
  suingue: 'suingue',
  suiones: 'suiones',
  suionia: 'suiônia',
  suionio: 'suiônio',
  suiriri: 'suiriri',
  suiruas: 'suiruás',
  suizaro: 'suízaro',
  sujadao: 'sujadão',
  sujadas: 'sujadas',
  sujador: 'sujador',
  sujados: 'sujados',
  sujamos: 'sujamos',
  sujando: 'sujando',
  sujante: 'sujante',
  sujaram: 'sujaram',
  sujarao: 'sujarão',
  sujaras: 'sujaras',
  sujarei: 'sujarei',
  sujarem: 'sujarem',
  sujares: 'sujares',
  sujaria: 'sujaria',
  sujarmo: 'sujarmo',
  sujasse: 'sujasse',
  sujaste: 'sujaste',
  sujavam: 'sujavam',
  sujavas: 'sujavas',
  sujavel: 'sujável',
  sujeira: 'sujeira',
  sujeita: 'sujeita',
  sujeite: 'sujeite',
  sujeito: 'sujeito',
  sujelas: 'sujelas',
  sujemos: 'sujemos',
  sujicai: 'sujicai',
  sujicam: 'sujicam',
  sujicar: 'sujicar',
  sujicas: 'sujicas',
  sujices: 'sujicés',
  sujicou: 'sujicou',
  sujigai: 'sujigai',
  sujigam: 'sujigam',
  sujigar: 'sujigar',
  sujigas: 'sujigas',
  sujigou: 'sujigou',
  sujigue: 'sujigue',
  sujinho: 'sujinho',
  sujique: 'sujique',
  sujonas: 'sujonas',
  sujugai: 'sujugai',
  sujugam: 'sujugam',
  sujugar: 'sujugar',
  sujugas: 'sujugas',
  sujugou: 'sujugou',
  sujugue: 'sujugue',
  suladao: 'suladão',
  suladas: 'suladas',
  sulador: 'sulador',
  sulados: 'sulados',
  sulamba: 'sulamba',
  sulamos: 'sulamos',
  sulanas: 'sulanas',
  sulanca: 'sulanca',
  sulanco: 'sulanco',
  sulando: 'sulando',
  sulanos: 'sulanos',
  sulante: 'sulante',
  sulaque: 'sulaque',
  sularam: 'sularam',
  sularao: 'sularão',
  sularas: 'sularas',
  sularei: 'sularei',
  sularem: 'sularem',
  sulares: 'sulares',
  sularia: 'sularia',
  sularmo: 'sularmo',
  sulasse: 'sulasse',
  sulaste: 'sulaste',
  sulavam: 'sulavam',
  sulavas: 'sulavas',
  sulcada: 'sulcada',
  sulcado: 'sulcado',
  sulcais: 'sulcais',
  sulcamo: 'sulcamo',
  sulcara: 'sulcara',
  sulcava: 'sulcava',
  suleira: 'suleira',
  suleiro: 'suleiro',
  sulemos: 'sulemos',
  sulense: 'sulense',
  sulenta: 'sulenta',
  sulento: 'sulento',
  suleste: 'suleste',
  sulfata: 'sulfata',
  sulfate: 'sulfate',
  sulfato: 'sulfato',
  sulfeta: 'sulfeta',
  sulfete: 'sulfete',
  sulfeto: 'sulfeto',
  sulfido: 'sulfido',
  sulfina: 'sulfina',
  sulfino: 'sulfino',
  sulfita: 'sulfitá',
  sulfite: 'sulfite',
  sulfito: 'sulfito',
  sulfona: 'sulfona',
  sulfone: 'sulfone',
  sulfono: 'sulfono',
  sulfura: 'sulfura',
  sulfure: 'sulfure',
  sulfuro: 'sulfuro',
  sulidas: 'súlidas',
  sulidea: 'sulídea',
  sulideo: 'sulídeo',
  sulimao: 'sulimão',
  sulinas: 'sulinas',
  sulinea: 'sulínea',
  sulinos: 'sulinos',
  suliota: 'suliota',
  sulipas: 'sulipas',
  sulismo: 'sulismo',
  sulista: 'sulista',
  sulquei: 'sulquei',
  sulquem: 'sulquem',
  sulques: 'sulques',
  sultana: 'sultana',
  sultani: 'sultani',
  sultoes: 'sultões',
  sumacas: 'sumacas',
  sumagra: 'sumagra',
  sumagre: 'sumagre',
  sumagro: 'sumagro',
  sumalar: 'sumalar',
  sumales: 'sumalés',
  sumamos: 'sumamos',
  sumanal: 'sumanal',
  sumanta: 'sumanta',
  sumaque: 'sumaque',
  sumares: 'sumarés',
  sumaria: 'sumária',
  sumarie: 'sumarie',
  sumario: 'sumário',
  sumatra: 'sumatra',
  sumauma: 'sumaúma',
  sumbaia: 'sumbaia',
  sumbare: 'sumbaré',
  sumbrai: 'sumbrai',
  sumbram: 'sumbram',
  sumbrar: 'sumbrar',
  sumbras: 'sumbras',
  sumbrei: 'sumbrei',
  sumbrem: 'sumbrem',
  sumbres: 'sumbres',
  sumbrou: 'sumbrou',
  sumbuis: 'sumbuis',
  sumelga: 'sumelga',
  sumeria: 'suméria',
  sumerio: 'sumério',
  sumersa: 'sumersa',
  sumerso: 'sumerso',
  sumicao: 'sumição',
  sumicha: 'sumicha',
  sumicho: 'sumicho',
  sumicos: 'sumiços',
  sumidao: 'sumidão',
  sumidas: 'sumidas',
  sumidor: 'sumidor',
  sumidos: 'sumidos',
  sumieis: 'sumíeis',
  sumigas: 'sumigas',
  sumimos: 'sumimos',
  sumindo: 'sumindo',
  sumiram: 'sumiram',
  sumirao: 'sumirão',
  sumiras: 'sumiras',
  sumirei: 'sumirei',
  sumirem: 'sumirem',
  sumires: 'sumires',
  sumiria: 'sumiria',
  sumirmo: 'sumirmo',
  sumisse: 'sumisse',
  sumista: 'sumista',
  sumiste: 'sumiste',
  sumosas: 'sumosas',
  sumosos: 'sumosos',
  sumpcao: 'sumpção',
  sumptas: 'sumptas',
  sumptos: 'sumptos',
  sumulai: 'sumulai',
  sumulam: 'sumulam',
  sumular: 'sumular',
  sumulas: 'sumulas',
  sumulei: 'sumulei',
  sumulem: 'sumulem',
  sumules: 'sumules',
  sumulou: 'sumulou',
  suncoes: 'sunções',
  suncona: 'sunçona',
  sundide: 'sundidé',
  sungada: 'sungada',
  sungado: 'sungado',
  sungais: 'sungais',
  sungara: 'sungara',
  sungava: 'sungava',
  sunguei: 'sunguei',
  sunguem: 'sunguem',
  sungues: 'sungues',
  sunhaca: 'súnhaca',
  sunhacu: 'sunhaçu',
  sunisga: 'sunisga',
  sunisgo: 'sunisgo',
  sunismo: 'sunismo',
  sunista: 'sunista',
  sunitas: 'sunitas',
  sunucas: 'sunucas',
  sunucos: 'sunucos',
  sununga: 'sununga',
  supenas: 'supenas',
  supenos: 'supenos',
  superai: 'superai',
  superam: 'superam',
  superar: 'superar',
  superas: 'superas',
  superei: 'superei',
  superem: 'superem',
  superes: 'superes',
  supereu: 'supereu',
  superfa: 'superfã',
  superna: 'superna',
  superno: 'superno',
  superos: 'súperos',
  superou: 'superou',
  superpo: 'superpô',
  supetao: 'supetão',
  supetas: 'súpetas',
  supetos: 'súpetos',
  supimpa: 'supimpa',
  supinar: 'supinar',
  supinas: 'supinas',
  supinos: 'supinos',
  supitas: 'súpitas',
  supitos: 'súpitos',
  suplica: 'súplica',
  suplice: 'súplice',
  suplico: 'suplico',
  supomos: 'supomos',
  suponde: 'suponde',
  supondo: 'supondo',
  suponha: 'suponha',
  suponho: 'suponho',
  suponta: 'supontá',
  suponte: 'suponte',
  suponto: 'suponto',
  suporao: 'suporão',
  suporas: 'suporás',
  suporei: 'suporei',
  suporem: 'suporem',
  supores: 'supores',
  suporia: 'suporia',
  supormo: 'supormo',
  suporta: 'suporta',
  suporte: 'suporte',
  suporto: 'suporto',
  suposta: 'suposta',
  suposto: 'suposto',
  suprais: 'suprais',
  supramo: 'supramo',
  suprema: 'suprema',
  supremo: 'supremo',
  supriam: 'supriam',
  suprias: 'suprias',
  suprida: 'suprida',
  suprido: 'suprido',
  suprima: 'suprima',
  suprime: 'suprime',
  suprimi: 'suprimi',
  suprimo: 'suprimo',
  suprira: 'suprirá',
  supunha: 'supunha',
  supurai: 'supurai',
  supuram: 'supuram',
  supurar: 'supurar',
  supuras: 'supuras',
  supurei: 'supurei',
  supurem: 'supurem',
  supures: 'supures',
  supurou: 'supurou',
  supuser: 'supuser',
  suputai: 'suputai',
  suputam: 'suputam',
  suputar: 'suputar',
  suputas: 'suputas',
  suputei: 'suputei',
  suputem: 'suputem',
  suputes: 'suputes',
  suputou: 'suputou',
  suquais: 'suquais',
  suquamo: 'suquamo',
  suqueis: 'suqueis',
  suquemo: 'suquemo',
  suquiam: 'suquiam',
  suquias: 'suquias',
  suquida: 'suquida',
  suquido: 'suquido',
  suquimo: 'suquimo',
  suquira: 'suquirá',
  suranas: 'suranas',
  suranes: 'suranes',
  suranga: 'suranga',
  suratas: 'suratas',
  surbiao: 'surbião',
  surcada: 'surcada',
  surcado: 'surcado',
  surcais: 'surcais',
  surcamo: 'surcamo',
  surcara: 'surcara',
  surcava: 'surcava',
  surculo: 'súrculo',
  surdais: 'surdais',
  surdamo: 'surdamo',
  surdeai: 'surdeai',
  surdear: 'surdear',
  surdeei: 'surdeei',
  surdeia: 'surdeia',
  surdeie: 'surdeie',
  surdeio: 'surdeio',
  surdeou: 'surdeou',
  surdeza: 'surdeza',
  surdiam: 'surdiam',
  surdias: 'surdias',
  surdida: 'surdida',
  surdido: 'surdido',
  surdimo: 'surdimo',
  surdina: 'surdina',
  surdine: 'surdine',
  surdino: 'surdino',
  surdira: 'surdira',
  surfada: 'surfada',
  surfado: 'surfado',
  surfais: 'surfais',
  surfamo: 'surfamo',
  surfara: 'surfará',
  surfava: 'surfava',
  surfeis: 'surfeis',
  surfemo: 'surfemo',
  surgiam: 'surgiam',
  surgiao: 'surgião',
  surgias: 'surgias',
  surgida: 'surgida',
  surgido: 'surgido',
  surgimo: 'surgimo',
  surgira: 'surgira',
  suriana: 'suriana',
  suriano: 'suriano',
  suribis: 'suribis',
  surilha: 'surilha',
  surilho: 'surilho',
  suritas: 'suritas',
  surjais: 'surjais',
  surjamo: 'surjamo',
  surnias: 'súrnias',
  suropos: 'suropos',
  surotes: 'surotes',
  surquei: 'surquei',
  surquem: 'surquem',
  surques: 'surques',
  surrada: 'surrada',
  surrado: 'surrado',
  surrais: 'surrais',
  surramo: 'surramo',
  surrapa: 'surrapa',
  surrara: 'surrara',
  surrate: 'surrate',
  surrava: 'surrava',
  surreal: 'surreal',
  surreis: 'surreis',
  surremo: 'surremo',
  surriai: 'surriai',
  surriam: 'surriam',
  surriar: 'surriar',
  surrias: 'surrias',
  surriba: 'surriba',
  surribe: 'surribe',
  surribo: 'surribo',
  surriei: 'surriei',
  surriem: 'surriem',
  surries: 'surries',
  surriou: 'surriou',
  surroes: 'surrões',
  surtada: 'surtada',
  surtado: 'surtado',
  surtais: 'surtais',
  surtamo: 'surtamo',
  surtara: 'surtará',
  surtava: 'surtava',
  surteis: 'surteis',
  surtemo: 'surtemo',
  surtiam: 'surtiam',
  surtias: 'surtias',
  surtida: 'surtida',
  surtido: 'surtido',
  surtimo: 'surtimo',
  surtira: 'surtira',
  surtuns: 'surtuns',
  suruana: 'suruaná',
  surubas: 'surubas',
  surubim: 'surubim',
  surubis: 'surubis',
  surucai: 'surucai',
  surucam: 'surucam',
  surucar: 'surucar',
  surucas: 'surucas',
  surucou: 'surucou',
  surucua: 'surucuá',
  surucui: 'surucuí',
  suruias: 'suruiás',
  suruiba: 'suruíba',
  surujes: 'surujés',
  surumas: 'surumas',
  surumbi: 'surumbi',
  surunga: 'surungá',
  surungo: 'surungo',
  suruqua: 'suruquá',
  suruque: 'suruque',
  sururus: 'sururus',
  susanas: 'susanas',
  susanos: 'susanos',
  suscita: 'suscita',
  suscite: 'suscite',
  suscito: 'suscito',
  susfito: 'susfito',
  susiana: 'susiana',
  susiano: 'susiano',
  susinas: 'susinas',
  susinos: 'susinos',
  suspeso: 'suspeso',
  suspira: 'suspira',
  suspire: 'suspire',
  suspiro: 'suspiro',
  susquam: 'susquam',
  susquas: 'susquas',
  susquem: 'susquem',
  susques: 'susques',
  susquia: 'susquia',
  susquir: 'susquir',
  susquis: 'susquis',
  susquiu: 'susquiu',
  sustada: 'sustada',
  sustado: 'sustado',
  sustais: 'sustais',
  sustamo: 'sustamo',
  sustara: 'sustara',
  sustava: 'sustava',
  susteis: 'susteis',
  sustemo: 'sustemo',
  sustens: 'susténs',
  sustera: 'susterá',
  susteve: 'susteve',
  sustiam: 'sustiam',
  sustias: 'sustias',
  sustida: 'sustida',
  sustido: 'sustido',
  sustive: 'sustive',
  sustosa: 'sustosa',
  sustoso: 'sustoso',
  susunus: 'susunus',
  sutache: 'sutache',
  sutadao: 'sutadão',
  sutadas: 'sutadas',
  sutador: 'sutador',
  sutados: 'sutados',
  sutagem: 'sutagem',
  sutamos: 'sutamos',
  sutanas: 'sutanas',
  sutando: 'sutando',
  sutante: 'sutante',
  sutaram: 'sutaram',
  sutarao: 'sutarão',
  sutaras: 'sutarás',
  sutarei: 'sutarei',
  sutarem: 'sutarem',
  sutares: 'sutares',
  sutaria: 'sutaria',
  sutarmo: 'sutarmo',
  sutasse: 'sutasse',
  sutaste: 'sutaste',
  sutates: 'sutates',
  sutavam: 'sutavam',
  sutavas: 'sutavas',
  sutemos: 'sutemos',
  suteras: 'suteras',
  suteria: 'sutéria',
  sutinga: 'sutinga',
  sutoras: 'sutoras',
  sutoria: 'sutória',
  sutrias: 'sútrias',
  sutrina: 'sutrina',
  sutrino: 'sutrino',
  sutrios: 'sútrios',
  sutroes: 'sutrões',
  suturai: 'suturai',
  sutural: 'sutural',
  suturam: 'suturam',
  suturar: 'suturar',
  suturas: 'suturas',
  suturei: 'suturei',
  suturem: 'suturem',
  sutures: 'sutures',
  suturou: 'suturou',
  suumbas: 'suumbas',
  suvenir: 'suvenir',
  suvinos: 'suvinos',
  suxadao: 'suxadão',
  suxadas: 'suxadas',
  suxador: 'suxador',
  suxados: 'suxados',
  suxamos: 'suxamos',
  suxando: 'suxando',
  suxante: 'suxante',
  suxaram: 'suxaram',
  suxarao: 'suxarão',
  suxaras: 'suxarás',
  suxarei: 'suxarei',
  suxarem: 'suxarem',
  suxares: 'suxares',
  suxaria: 'suxaria',
  suxarmo: 'suxarmo',
  suxasse: 'suxasse',
  suxaste: 'suxaste',
  suxavam: 'suxavam',
  suxavas: 'suxavas',
  suxemos: 'suxemos',
  suzunus: 'suzunus',
  svabita: 'svabita',
  swahili: 'swahili',
  taanhes: 'taanhés',
  tabacal: 'tabacal',
  tabacao: 'tabacão',
  tabacas: 'tabacas',
  tabacos: 'tabacos',
  tabacum: 'tabacum',
  tabagia: 'tabagia',
  tabagos: 'tabagos',
  tabaibo: 'tabaibo',
  tabalas: 'tabalas',
  tabalos: 'tabalos',
  tabanas: 'tabanas',
  tabanca: 'tabanca',
  tabanga: 'tabanga',
  tabanos: 'tabanos',
  tabapua: 'tabapuã',
  tabaque: 'tabaque',
  tabardo: 'tabardo',
  tabareu: 'tabaréu',
  tabaroa: 'tabaroa',
  tabarro: 'tabarro',
  tabarze: 'tabarzé',
  tabasco: 'tabasco',
  tabaxir: 'tabaxir',
  tabazes: 'tabazes',
  tabecas: 'tabecas',
  tabedae: 'tabedáe',
  tabedai: 'tabedai',
  tabefes: 'tabefes',
  tabeira: 'tabeira',
  tabelai: 'tabelai',
  tabelam: 'tabelam',
  tabelao: 'tabelão',
  tabelar: 'tabelar',
  tabelas: 'tabelas',
  tabelei: 'tabelei',
  tabelem: 'tabelem',
  tabeles: 'tabeles',
  tabelia: 'tabeliã',
  tabelie: 'tabelie',
  tabelio: 'tabelio',
  tabelou: 'tabelou',
  taberna: 'taberna',
  tabicai: 'tabicai',
  tabicam: 'tabicam',
  tabicao: 'tabicão',
  tabicar: 'tabicar',
  tabicas: 'tabicas',
  tabicou: 'tabicou',
  tabidas: 'tábidas',
  tabidez: 'tabidez',
  tabidos: 'tábidos',
  tabilha: 'tabilha',
  tabinos: 'tabinos',
  tabique: 'tabique',
  tabiras: 'tabiras',
  tabizai: 'tabizai',
  tabizam: 'tabizam',
  tabizar: 'tabizar',
  tabizas: 'tabizas',
  tabizei: 'tabizei',
  tabizem: 'tabizem',
  tabizes: 'tabizes',
  tabizou: 'tabizou',
  tablada: 'tablada',
  tablado: 'tablado',
  tableau: 'tableau',
  tableta: 'tableta',
  tablete: 'tablete',
  tablier: 'tablier',
  tablina: 'tablina',
  tablino: 'tablino',
  tablita: 'tablita',
  tabocal: 'tabocal',
  tabocas: 'tabocas',
  tabolim: 'tabolim',
  tabores: 'tabores',
  taborro: 'taborro',
  tabuada: 'tabuada',
  tabuado: 'tabuado',
  tabuais: 'tabuais',
  tabuame: 'tabuame',
  tabucai: 'tabucai',
  tabucam: 'tabucam',
  tabucar: 'tabucar',
  tabucas: 'tabucas',
  tabucou: 'tabucou',
  tabuiza: 'tabuíza',
  tabuize: 'tabuíze',
  tabuizo: 'tabuízo',
  tabujos: 'tabujos',
  tabulai: 'tabulai',
  tabulam: 'tabulam',
  tabulao: 'tabulão',
  tabular: 'tabular',
  tabulas: 'tabulas',
  tabulei: 'tabulei',
  tabulem: 'tabulem',
  tabules: 'tabules',
  tabulou: 'tabulou',
  tabulus: 'tabulus',
  tabuoes: 'tabuões',
  tabuona: 'tabuona',
  tabuque: 'tabuque',
  taburna: 'taburná',
  taburne: 'taburne',
  taburno: 'taburno',
  tacacas: 'tacacas',
  tacacea: 'tacácea',
  tacaceo: 'tacáceo',
  tacadao: 'tacadão',
  tacadas: 'tacadas',
  tacador: 'tacador',
  tacados: 'tacados',
  tacamos: 'tacamos',
  tacanas: 'tacanas',
  tacando: 'tacando',
  tacanha: 'tacanha',
  tacanho: 'tacanho',
  tacante: 'tacante',
  tacapes: 'tacapes',
  tacaram: 'tacaram',
  tacarao: 'tacarão',
  tacaras: 'tacaras',
  tacarei: 'tacarei',
  tacarem: 'tacarem',
  tacares: 'tacares',
  tacaria: 'tacaria',
  tacarmo: 'tacarmo',
  tacaros: 'tácaros',
  tacasse: 'tacasse',
  tacaste: 'tacaste',
  tacauas: 'tacauás',
  tacavam: 'tacavam',
  tacavas: 'tacavas',
  taceira: 'taceira',
  taceiro: 'taceiro',
  tacelos: 'tacelos',
  tachada: 'tachada',
  tachado: 'tachado',
  tachais: 'tachais',
  tachamo: 'tachamo',
  tachara: 'tachara',
  tachava: 'tachava',
  tacheai: 'tacheai',
  tachear: 'tachear',
  tacheei: 'tacheei',
  tacheia: 'tacheia',
  tacheie: 'tacheie',
  tacheio: 'tacheio',
  tacheis: 'tacheis',
  tachemo: 'tachemo',
  tacheou: 'tacheou',
  tachins: 'tachins',
  tachira: 'tachira',
  tachoes: 'tachões',
  tachola: 'tachola',
  tachona: 'tachona',
  tachone: 'tachone',
  tachono: 'tachono',
  tachote: 'tachote',
  taciais: 'taciaís',
  taciana: 'taciana',
  taciano: 'taciano',
  tacibas: 'tacibas',
  tacinga: 'tacinga',
  tacinha: 'tacinha',
  tacitas: 'tácitas',
  tacitos: 'tácitos',
  tacocua: 'tacócua',
  tacoide: 'tacoide',
  tacoila: 'tacoila',
  taconas: 'taconas',
  tacosao: 'tacosão',
  tacoses: 'tacoses',
  tacrina: 'tacrina',
  tacteai: 'tacteai',
  tactear: 'tactear',
  tacteei: 'tacteei',
  tacteia: 'tacteia',
  tacteie: 'tacteie',
  tacteio: 'tacteio',
  tacteis: 'tácteis',
  tactema: 'tactema',
  tacteou: 'tacteou',
  tactica: 'táctica',
  tactico: 'táctico',
  tactura: 'tactura',
  tacuara: 'tacuara',
  tacuare: 'tacuaré',
  tacuari: 'tacuari',
  tacuela: 'tacuela',
  tacuira: 'taçuíra',
  tacuite: 'tacuité',
  tacujas: 'tacujás',
  taculas: 'taculas',
  tacunas: 'tacunás',
  tacuris: 'tacuris',
  tacurua: 'tacurua',
  tacurus: 'tacurus',
  tadegas: 'tádegas',
  tadeias: 'tadeias',
  tadiate: 'tadiate',
  tadinha: 'tadinha',
  tadinho: 'tadinho',
  taditas: 'taditas',
  tadorna: 'tadorna',
  tadorno: 'tadorno',
  taelzao: 'taelzão',
  taenias: 'taenias',
  tafarel: 'tafarel',
  tafeiro: 'tafeiro',
  tafetas: 'tafetás',
  tafiana: 'tafiana',
  tafiano: 'tafiano',
  tafifes: 'tafifes',
  tafiles: 'tafiles',
  tafires: 'tafires',
  tafonas: 'tafonas',
  tafones: 'tafonés',
  tafrias: 'tafrias',
  tafrina: 'tafrina',
  tafulai: 'tafulai',
  tafulam: 'tafulam',
  tafulao: 'tafulão',
  tafular: 'tafular',
  tafulas: 'tafulas',
  tafulei: 'tafulei',
  tafulem: 'tafulem',
  tafules: 'tafules',
  tafulha: 'tafulha',
  tafulhe: 'tafulhe',
  tafulho: 'tafulho',
  tafulou: 'tafulou',
  tagalar: 'tagalar',
  tagalas: 'tagalas',
  tagalho: 'tagalho',
  tagalos: 'tagalos',
  taganas: 'taganas',
  taganos: 'taganos',
  taganta: 'taganta',
  tagante: 'tagante',
  taganto: 'taganto',
  tagaris: 'tagaris',
  tagarra: 'tagarra',
  tagates: 'tagatés',
  tagazes: 'tagazes',
  tagenia: 'tagênia',
  tagetes: 'tagetes',
  tagetos: 'tagetos',
  tagicas: 'tágicas',
  tagicos: 'tágicos',
  tagidea: 'tagídea',
  tagideo: 'tagídeo',
  tagides: 'tágides',
  tagmema: 'tagmema',
  tagonas: 'tagonas',
  tagoras: 'tágoras',
  tagoros: 'tágoros',
  taguara: 'taguara',
  taguari: 'taguari',
  taguaus: 'taguaús',
  tagueda: 'tágueda',
  taiacus: 'taiaçus',
  taiamar: 'taiamar',
  taiauva: 'taiaúva',
  taieira: 'taieira',
  taietes: 'taietês',
  taimada: 'taimada',
  taimado: 'taimado',
  taimbes: 'taimbés',
  tainada: 'tainada',
  tainado: 'tainado',
  tainais: 'tainais',
  tainamo: 'tainamo',
  tainara: 'tainara',
  tainava: 'tainava',
  taineis: 'taineis',
  tainemo: 'tainemo',
  tainhas: 'tainhas',
  taiobal: 'taiobal',
  taiobas: 'taiobas',
  taiocas: 'taiocas',
  taioval: 'taioval',
  taiovas: 'taiovas',
  taipada: 'taipada',
  taipado: 'taipado',
  taipais: 'taipais',
  taipamo: 'taipamo',
  taipara: 'taipará',
  taipava: 'taipava',
  taipeis: 'taipeis',
  taipemo: 'taipemo',
  taipoca: 'taipoca',
  taipoes: 'taipões',
  taipuru: 'taipuru',
  tairoca: 'tairoca',
  tairoco: 'tairoco',
  tairona: 'tairona',
  taiseno: 'taiseno',
  taititu: 'taititu',
  taiuias: 'taiuiás',
  taiuras: 'taiuras',
  taiutas: 'taiutás',
  taiuvas: 'taiuvas',
  tajacus: 'tajaçus',
  tajasno: 'tajasno',
  tajibas: 'tajibás',
  tajipas: 'tajipás',
  tajique: 'tajique',
  tajubas: 'tajubas',
  tajujas: 'tajujás',
  tajupar: 'tajupar',
  tajupas: 'tajupás',
  tajuras: 'tajurás',
  tajuvas: 'tajuvas',
  talacos: 'talaços',
  taladao: 'taladão',
  taladas: 'taladas',
  talador: 'talador',
  talados: 'talados',
  talagui: 'talagui',
  talamos: 'talamos',
  talando: 'talando',
  talante: 'talante',
  talapao: 'talapão',
  talapoe: 'talapõe',
  talaram: 'talaram',
  talarao: 'talarão',
  talaras: 'talarás',
  talarei: 'talarei',
  talarem: 'talarem',
  talares: 'talares',
  talaria: 'talaria',
  talarmo: 'talarmo',
  talassa: 'talassa',
  talasse: 'talasse',
  talasso: 'talasso',
  talaste: 'talaste',
  talauma: 'talauma',
  talavam: 'talavam',
  talavas: 'talavas',
  talavez: 'talavez',
  talcica: 'tálcica',
  talcico: 'tálcico',
  talcita: 'talcita',
  talcite: 'talcite',
  talcito: 'talcito',
  talcosa: 'talcosa',
  talcose: 'talcose',
  talcoso: 'talcoso',
  talefes: 'talefes',
  taleiga: 'taleiga',
  taleigo: 'taleigo',
  taleira: 'taleira',
  taleiro: 'taleiro',
  talemos: 'talemos',
  talento: 'talento',
  talerai: 'talerai',
  taleram: 'taleram',
  talerar: 'talerar',
  taleras: 'taleras',
  talerei: 'talerei',
  talerem: 'talerem',
  taleres: 'taleres',
  talerou: 'talerou',
  taletes: 'taletes',
  taletos: 'taletos',
  talevas: 'talevas',
  talhada: 'talhada',
  talhado: 'talhado',
  talhais: 'talhais',
  talhame: 'talhame',
  talhamo: 'talhamo',
  talhara: 'talhara',
  talhava: 'talhava',
  talheis: 'talheis',
  talhemo: 'talhemo',
  talhoes: 'talhões',
  talhona: 'talhona',
  taliate: 'taliate',
  taliato: 'taliato',
  talibas: 'talibãs',
  talibes: 'talibés',
  talicao: 'talicão',
  talicas: 'tálicas',
  talicos: 'tálicos',
  talides: 'talides',
  taligas: 'taligas',
  talimai: 'talimai',
  talimam: 'talimam',
  talimar: 'talimar',
  talimas: 'talimas',
  talimei: 'talimei',
  talimem: 'talimem',
  talimes: 'talimes',
  talimou: 'talimou',
  talinas: 'talinas',
  talinga: 'talinga',
  talingo: 'talingo',
  talinha: 'talinha',
  talinho: 'talinho',
  talinos: 'talinos',
  talioes: 'taliões',
  taliona: 'taliona',
  talione: 'talione',
  taliono: 'taliono',
  taliosa: 'taliosa',
  talioso: 'talioso',
  talipes: 'talipes',
  talipos: 'talipos',
  talique: 'talique',
  talisca: 'talisca',
  talisia: 'talísia',
  talisma: 'talismã',
  talitre: 'talitre',
  talitro: 'tálitro',
  talmuda: 'talmuda',
  talmude: 'talmude',
  talocas: 'talocas',
  talocha: 'talocha',
  talocos: 'talocos',
  talodis: 'talodis',
  taloide: 'taloide',
  talonai: 'talonai',
  talonam: 'talonam',
  talonar: 'talonar',
  talonas: 'talonas',
  talondo: 'talondo',
  talonea: 'taloneá',
  talonei: 'talonei',
  talonem: 'talonem',
  talones: 'talones',
  talonou: 'talonou',
  taloras: 'taloras',
  taloros: 'taloros',
  talosas: 'talosas',
  taloses: 'taloses',
  talosos: 'talosos',
  talpose: 'talpose',
  talucas: 'talucas',
  talucta: 'talucta',
  taludai: 'taludai',
  taludam: 'taludam',
  taludao: 'taludão',
  taludar: 'taludar',
  taludas: 'taludas',
  taludei: 'taludei',
  taludem: 'taludem',
  taludes: 'taludes',
  taludou: 'taludou',
  talzoes: 'talzões',
  tamajua: 'tamajuá',
  tamales: 'tamales',
  tamanca: 'tamanca',
  tamanco: 'tamanco',
  tamanha: 'tamanha',
  tamanho: 'tamanho',
  tamaral: 'tamaral',
  tamaras: 'tâmaras',
  tamares: 'tamarês',
  tamarga: 'tamarga',
  tamarim: 'tamarim',
  tamaris: 'tamaris',
  tamarix: 'tamárix',
  tamariz: 'tamariz',
  tamarma: 'tamarma',
  tamarus: 'tamarus',
  tamatas: 'tamatás',
  tamatia: 'tamatiá',
  tamatis: 'tamatis',
  tamatra: 'tamatra',
  tambaca: 'tambaca',
  tambaco: 'tambaco',
  tambaru: 'tambaru',
  tambero: 'tambero',
  tambeta: 'tambetá',
  tambica: 'tambica',
  tambico: 'tâmbico',
  tambicu: 'tambicu',
  tambios: 'tambiós',
  tambius: 'tambiús',
  tambona: 'tambona',
  tambono: 'tambono',
  tambori: 'tambori',
  tambuca: 'tambuca',
  tambuis: 'tambuis',
  tambula: 'tambula',
  tambura: 'tambura',
  tamburi: 'tamburi',
  tamburu: 'tamburu',
  tameira: 'tameira',
  tamiago: 'tamiago',
  tamiana: 'tamiana',
  tamiano: 'tamiano',
  tamicas: 'tamiças',
  taminas: 'taminas',
  tamisai: 'tamisai',
  tamisam: 'tamisam',
  tamisar: 'tamisar',
  tamisas: 'tamisas',
  tamisei: 'tamisei',
  tamisem: 'tamisem',
  tamises: 'tamises',
  tamisou: 'tamisou',
  tamnios: 'tâmnios',
  tamoata: 'tamoatá',
  tamoela: 'tamoela',
  tamoios: 'tamoios',
  tamonea: 'tamônea',
  tamooes: 'tamoões',
  tampada: 'tampada',
  tampado: 'tampado',
  tampais: 'tampais',
  tampamo: 'tampamo',
  tampara: 'tampara',
  tampava: 'tampava',
  tampeis: 'tampeis',
  tampemo: 'tampemo',
  tampoes: 'tampões',
  tampona: 'tampona',
  tampone: 'tampone',
  tampono: 'tampono',
  tamposa: 'tamposa',
  tamquam: 'tamquam',
  tamuana: 'tamuana',
  tamuata: 'tamuatá',
  tamucus: 'tamucus',
  tamugao: 'tamugão',
  tamujes: 'tamujes',
  tamujos: 'tamujos',
  tamungo: 'tamungo',
  tamuria: 'tamuria',
  tamusca: 'tamuscá',
  tamusco: 'tamusco',
  tamuzes: 'tamuzes',
  tanacol: 'tanacol',
  tanadao: 'tanadão',
  tanadar: 'tanadar',
  tanadas: 'tanadas',
  tanador: 'tanador',
  tanados: 'tanados',
  tanagem: 'tanagem',
  tanagra: 'tânagra',
  tanaide: 'tanaide',
  tanaita: 'tanaíta',
  tanalas: 'tanalas',
  tanamos: 'tanamos',
  tananas: 'tananás',
  tanando: 'tanando',
  tanante: 'tanante',
  tanaram: 'tanaram',
  tanarao: 'tanarão',
  tanaras: 'tanarás',
  tanarei: 'tanarei',
  tanarem: 'tanarem',
  tanares: 'tanares',
  tanaria: 'tanaria',
  tanarmo: 'tanarmo',
  tanases: 'tânases',
  tanasia: 'tanásia',
  tanasse: 'tanasse',
  tanaste: 'tanaste',
  tanatas: 'tanatãs',
  tanatau: 'tanatau',
  tanatos: 'tânatos',
  tanauba: 'tanaúba',
  tanavam: 'tanavam',
  tanavas: 'tanavas',
  tanavel: 'tanável',
  tanazai: 'tanazai',
  tanazam: 'tanazam',
  tanazar: 'tanazar',
  tanazas: 'tanazas',
  tanazei: 'tanazei',
  tanazem: 'tanazem',
  tanazes: 'tanazes',
  tanazou: 'tanazou',
  tancada: 'tancada',
  tancado: 'tancado',
  tancais: 'tancais',
  tancama: 'tancama',
  tancame: 'tancame',
  tancamo: 'tancamo',
  tancara: 'tancara',
  tancava: 'tancava',
  tanchai: 'tanchai',
  tancham: 'tancham',
  tanchao: 'tanchão',
  tanchar: 'tanchar',
  tanchas: 'tanchas',
  tanchei: 'tanchei',
  tanchem: 'tanchem',
  tanches: 'tanches',
  tanchim: 'tanchim',
  tanchou: 'tanchou',
  tandeis: 'tandéis',
  tandens: 'tandens',
  tanduju: 'tanduju',
  tanecio: 'tanécio',
  tanecos: 'tanecos',
  tanemos: 'tanemos',
  tangada: 'tangada',
  tangado: 'tangado',
  tangais: 'tangais',
  tangala: 'tangala',
  tangale: 'tangale',
  tangana: 'tangana',
  tangano: 'tângano',
  tangara: 'tangará',
  tangava: 'tangava',
  tangeis: 'tangeis',
  tangelo: 'tangelo',
  tangemo: 'tangemo',
  tangera: 'tangera',
  tangere: 'tângere',
  tangiam: 'tangiam',
  tangias: 'tangias',
  tangida: 'tangida',
  tangido: 'tangido',
  tangoes: 'tangões',
  tangram: 'tangram',
  tanguas: 'tanguás',
  tanguea: 'tangueá',
  tanguei: 'tanguei',
  tanguem: 'tanguem',
  tangues: 'tangues',
  tanguim: 'tanguim',
  tanguis: 'tanguis',
  tanguta: 'tanguta',
  tanguto: 'tanguto',
  tanhacu: 'tanhaçu',
  tanhada: 'tanhada',
  tanicas: 'taniças',
  tanicos: 'tânicos',
  taninai: 'taninai',
  taninam: 'taninam',
  taninar: 'taninar',
  taninas: 'taninas',
  taninei: 'taninei',
  taninem: 'taninem',
  tanines: 'tanines',
  taninos: 'taninos',
  taninou: 'taninou',
  tanjais: 'tanjais',
  tanjamo: 'tanjamo',
  tanjara: 'tânjara',
  tanjaro: 'tânjaro',
  tanjoes: 'tanjões',
  tanjudo: 'tanjudo',
  tanjugo: 'tanjugo',
  tanoada: 'tanoada',
  tanoado: 'tanoado',
  tanoais: 'tanoais',
  tanoamo: 'tanoamo',
  tanoara: 'tanoara',
  tanoava: 'tanoava',
  tanocas: 'tanocas',
  tanoeis: 'tanoeis',
  tanoemo: 'tanoemo',
  tanoide: 'tanoide',
  tanonas: 'tanonas',
  tanosal: 'tanosal',
  tanquei: 'tanquei',
  tanquem: 'tanquem',
  tanques: 'tanques',
  tanquia: 'tanquia',
  tantalo: 'tântalo',
  tanteai: 'tanteai',
  tantear: 'tantear',
  tanteei: 'tanteei',
  tanteia: 'tanteia',
  tanteie: 'tanteie',
  tanteio: 'tanteio',
  tanteou: 'tanteou',
  tantita: 'tantita',
  tantito: 'tantito',
  tantras: 'tantras',
  tanuana: 'tanuana',
  tanuine: 'tanuíne',
  taoismo: 'taoísmo',
  taoista: 'taoista',
  taosita: 'taosita',
  tapacao: 'tapação',
  tapacum: 'tapacum',
  tapadao: 'tapadão',
  tapadas: 'tapadas',
  tapador: 'tapador',
  tapados: 'tapados',
  tapagem: 'tapagem',
  tapajos: 'tapajós',
  tapamos: 'tapamos',
  tapando: 'tapando',
  tapante: 'tapante',
  tapaqua: 'tapaquá',
  taparam: 'taparam',
  taparao: 'taparão',
  taparas: 'taparas',
  taparei: 'taparei',
  taparem: 'taparem',
  tapares: 'tapares',
  taparia: 'taparia',
  taparis: 'taparis',
  taparmo: 'taparmo',
  tapasse: 'tapasse',
  tapaste: 'tapaste',
  tapavam: 'tapavam',
  tapavas: 'tapavas',
  tapavel: 'tapável',
  tapeada: 'tapeada',
  tapeado: 'tapeado',
  tapeais: 'tapeais',
  tapeamo: 'tapeamo',
  tapeara: 'tapeara',
  tapeava: 'tapeava',
  tapecai: 'tapeçai',
  tapecam: 'tapeçam',
  tapecar: 'tapeçar',
  tapecas: 'tapeças',
  tapecei: 'tapecei',
  tapecem: 'tapecem',
  tapeces: 'tapeces',
  tapecou: 'tapeçou',
  tapeeis: 'tapeeis',
  tapeemo: 'tapeemo',
  tapeiam: 'tapeiam',
  tapeias: 'tapeias',
  tapeiem: 'tapeiem',
  tapeies: 'tapeies',
  tapeina: 'tapeina',
  tapeira: 'tapeira',
  tapeixa: 'tapeixa',
  tapemas: 'tapemas',
  tapemos: 'tapemos',
  tapenas: 'tapenas',
  tapense: 'tapense',
  taperao: 'taperão',
  taperas: 'taperas',
  taperus: 'taperus',
  tapetai: 'tapetai',
  tapetam: 'tapetam',
  tapetao: 'tapetão',
  tapetar: 'tapetar',
  tapetas: 'tapetas',
  tapetea: 'tapeteá',
  tapetei: 'tapetei',
  tapetem: 'tapetem',
  tapetes: 'tapetes',
  tapetou: 'tapetou',
  tapiaca: 'tapiaca',
  tapiacu: 'tapiaçu',
  tapiais: 'tapiaís',
  tapiara: 'tapiara',
  tapicai: 'tapiçai',
  tapicam: 'tapiçam',
  tapicar: 'tapiçar',
  tapicas: 'tapiças',
  tapicei: 'tapicei',
  tapicem: 'tapicem',
  tapices: 'tapices',
  tapichi: 'tapichi',
  tapicou: 'tapiçou',
  tapicua: 'tapiçuá',
  tapigos: 'tapigos',
  tapiira: 'tapiíra',
  tapinas: 'tapinas',
  tapinha: 'tapinha',
  tapioca: 'tapioca',
  tapiras: 'tapiras',
  tapires: 'tapires',
  tapiria: 'tapíria',
  tapiris: 'tapiris',
  tapiros: 'tápiros',
  tapirus: 'tapirus',
  tapisas: 'tapisas',
  tapitim: 'tapitim',
  tapitis: 'tapitis',
  tapiuas: 'tapiuás',
  tapiuba: 'tapiúba',
  tapixis: 'tapixis',
  tapixos: 'tapixós',
  tapizai: 'tapizai',
  tapizam: 'tapizam',
  tapizar: 'tapizar',
  tapizas: 'tapizas',
  tapizei: 'tapizei',
  tapizem: 'tapizem',
  tapizes: 'tapizes',
  tapizou: 'tapizou',
  taponas: 'taponas',
  taporas: 'taporas',
  taporos: 'taporos',
  tapotim: 'tapotim',
  tapsias: 'tápsias',
  taptico: 'táptico',
  tapuias: 'tapuias',
  tapuios: 'tapuios',
  tapuisa: 'tapuísa',
  tapujis: 'tapujis',
  tapulha: 'tapulha',
  tapulhe: 'tapulhe',
  tapulho: 'tapulho',
  tapumes: 'tapumes',
  tapuoca: 'tapuoca',
  tapurus: 'tapurus',
  taputem: 'taputém',
  taquara: 'taquara',
  taquare: 'taquaré',
  taquari: 'taquari',
  taqueai: 'taqueai',
  taquear: 'taquear',
  taqueas: 'táqueas',
  taqueei: 'taqueei',
  taqueia: 'taqueia',
  taqueie: 'taqueie',
  taqueio: 'taqueio',
  taqueis: 'taqueis',
  taquemo: 'taquemo',
  taqueou: 'taqueou',
  taquera: 'taquera',
  taquica: 'táquica',
  taquico: 'táquico',
  taquide: 'táquide',
  taquina: 'táquina',
  taquino: 'táquino',
  taquion: 'táquion',
  taquipo: 'táquipo',
  taquira: 'taquirá',
  taquiri: 'taquiri',
  tarabes: 'tarabés',
  tarabos: 'tarabos',
  taracao: 'taração',
  taracua: 'taracuá',
  taradao: 'taradão',
  taradas: 'taradas',
  tarador: 'tarador',
  tarados: 'tarados',
  tarafes: 'tarafes',
  taraira: 'taraíra',
  taralha: 'taralha',
  taralhe: 'taralhe',
  taralho: 'taralho',
  taramas: 'taramás',
  taramos: 'taramos',
  tarando: 'tarando',
  taranta: 'taranta',
  tarante: 'tarante',
  tarapes: 'tarapés',
  taraqua: 'taraquá',
  taraque: 'táraque',
  tararam: 'tararam',
  tararao: 'tararão',
  tararas: 'tararas',
  tararei: 'tararei',
  tararem: 'tararem',
  tarares: 'tarares',
  tararia: 'tarária',
  tararmo: 'tararmo',
  tarasca: 'tarasca',
  tarasco: 'tarasco',
  tarasse: 'tarasse',
  taraste: 'taraste',
  taratas: 'taratas',
  taravam: 'taravam',
  taravas: 'taravas',
  taraxes: 'taraxes',
  taraxia: 'taraxia',
  tarbela: 'tarbela',
  tarbelo: 'tarbelo',
  tarbesa: 'tarbesa',
  tarbush: 'tarbush',
  tarcena: 'tarcena',
  tardada: 'tardada',
  tardado: 'tardado',
  tardais: 'tardais',
  tardamo: 'tardamo',
  tardara: 'tardara',
  tardava: 'tardava',
  tardeco: 'tardeco',
  tardega: 'tardega',
  tardego: 'tardego',
  tardeis: 'tardeis',
  tardemo: 'tardemo',
  tardeza: 'tardeza',
  tardiao: 'tardião',
  tardias: 'tardias',
  tardina: 'tardina',
  tardios: 'tardios',
  tardoes: 'tardões',
  tardona: 'tardona',
  tardora: 'tardora',
  tareada: 'tareada',
  tareado: 'tareado',
  tareais: 'tareais',
  tareara: 'tareará',
  tareava: 'tareava',
  tarecai: 'tarecaí',
  tarecas: 'tarecas',
  tarecos: 'tarecos',
  taredos: 'taredos',
  tareeis: 'tareeis',
  tarefai: 'tarefai',
  tarefam: 'tarefam',
  tarefar: 'tarefar',
  tarefas: 'tarefas',
  tarefei: 'tarefei',
  tarefem: 'tarefem',
  tarefes: 'tarefes',
  tarefou: 'tarefou',
  taregas: 'taregas',
  tareiam: 'tareiam',
  tareias: 'tareias',
  tareiem: 'tareiem',
  tareies: 'tareies',
  tareira: 'tareira',
  tarelai: 'tarelai',
  tarelam: 'tarelam',
  tarelar: 'tarelar',
  tarelas: 'tarelas',
  tarelei: 'tarelei',
  tarelem: 'tarelem',
  tareles: 'tareles',
  tarelho: 'tarelho',
  tarelou: 'tarelou',
  taremos: 'taremos',
  tarenas: 'tarenas',
  tareres: 'tarerés',
  tarfios: 'tárfios',
  targana: 'targana',
  targras: 'targras',
  targuns: 'targuns',
  tariana: 'tariana',
  tarifai: 'tarifai',
  tarifam: 'tarifam',
  tarifar: 'tarifar',
  tarifas: 'tarifas',
  tarifei: 'tarifei',
  tarifem: 'tarifem',
  tarifes: 'tarifes',
  tarifou: 'tarifou',
  tarigue: 'tárigue',
  tarimas: 'tarimas',
  tarimba: 'tarimba',
  tarimbe: 'tarimbe',
  tarimbo: 'tarimbo',
  tarinha: 'tarinha',
  tarioba: 'tarioba',
  tariota: 'tariota',
  tariras: 'tariras',
  tariris: 'tariris',
  tarisas: 'tarisas',
  tarjada: 'tarjada',
  tarjado: 'tarjado',
  tarjais: 'tarjais',
  tarjamo: 'tarjamo',
  tarjara: 'tarjará',
  tarjava: 'tarjava',
  tarjeis: 'tarjeis',
  tarjemo: 'tarjemo',
  tarjeta: 'tarjeta',
  tarjoes: 'tarjões',
  tarmica: 'tármica',
  tarmico: 'tármico',
  tarocai: 'tarocai',
  tarocam: 'tarocam',
  tarocar: 'tarocar',
  tarocas: 'tarocas',
  tarocos: 'tarocos',
  tarocou: 'tarocou',
  tarolai: 'tarolai',
  tarolam: 'tarolam',
  tarolar: 'tarolar',
  tarolas: 'tarolas',
  tarolei: 'tarolei',
  tarolem: 'tarolem',
  taroles: 'taroles',
  tarolos: 'tarolos',
  tarolou: 'tarolou',
  taronja: 'taronja',
  taronos: 'taronos',
  taropes: 'taropés',
  taroque: 'taroque',
  tarotes: 'tarotes',
  tarouca: 'tarouca',
  tarouco: 'tarouco',
  tarpoes: 'tarpões',
  tarraca: 'tarraca',
  tarraco: 'tarraço',
  tarrada: 'tarrada',
  tarrafa: 'tarrafa',
  tarrafe: 'tarrafe',
  tarrafo: 'tarrafo',
  tarraga: 'tárraga',
  tarraxa: 'tarraxa',
  tarraxe: 'tarraxe',
  tarraxo: 'tarraxo',
  tarreai: 'tarreai',
  tarrear: 'tarrear',
  tarreca: 'tarreca',
  tarreco: 'tarreco',
  tarreei: 'tarreei',
  tarreia: 'tarreia',
  tarreie: 'tarreie',
  tarreio: 'tarreio',
  tarrelo: 'tarrelo',
  tarreou: 'tarreou',
  tarreta: 'tarreta',
  tarreto: 'tarreto',
  tarreus: 'tarréus',
  tarroes: 'tarrões',
  tarrote: 'tarrote',
  tarruca: 'tarruca',
  tarruta: 'tarrutá',
  tarrute: 'tarrute',
  tarruto: 'tarruto',
  tarsais: 'tarsais',
  tarseas: 'társeas',
  tarseos: 'társeos',
  tarsica: 'társica',
  tarsico: 'társico',
  tarsios: 'társios',
  tarsite: 'tarsite',
  tartada: 'tartada',
  tartago: 'tartago',
  tartana: 'tartana',
  tartara: 'tártara',
  tartaro: 'tártaro',
  tartega: 'tártega',
  tartego: 'tártego',
  tartufo: 'tartufo',
  tarubas: 'tarubás',
  tarucas: 'tarucas',
  tarucos: 'tarucos',
  tarugai: 'tarugai',
  tarugam: 'tarugam',
  tarugar: 'tarugar',
  tarugas: 'tarugas',
  tarugos: 'tarugos',
  tarugou: 'tarugou',
  tarugue: 'tarugue',
  tarumai: 'tarumaí',
  tarumas: 'tarumás',
  tarvias: 'tarvias',
  tascada: 'tascada',
  tascado: 'tascado',
  tascais: 'tascais',
  tascamo: 'tascamo',
  tascara: 'tascará',
  tascava: 'tascava',
  tascina: 'tascina',
  tascoai: 'tascoai',
  tascoam: 'tascoam',
  tascoar: 'tascoar',
  tascoas: 'tascoas',
  tascoei: 'tascoei',
  tascoem: 'tascoem',
  tascoes: 'tascoes',
  tascoou: 'tascoou',
  tasgada: 'tasgada',
  tasgado: 'tasgado',
  tasgais: 'tasgais',
  tasgara: 'tasgará',
  tasgava: 'tasgava',
  tasgios: 'tásgios',
  tasguei: 'tasguei',
  tasguem: 'tasguem',
  tasgues: 'tasgues',
  tasiana: 'tasiana',
  tasiano: 'tasiano',
  tasnona: 'tasnona',
  tasquei: 'tasquei',
  tasquem: 'tasquem',
  tasques: 'tasques',
  tasselo: 'tasselo',
  tasteai: 'tasteai',
  tastear: 'tastear',
  tasteei: 'tasteei',
  tasteia: 'tasteia',
  tasteie: 'tasteie',
  tasteio: 'tasteio',
  tasteou: 'tasteou',
  tatabus: 'tatabus',
  tatacaa: 'tatacaá',
  tataiba: 'tataíba',
  tataira: 'tataíra',
  tatalai: 'tatalai',
  tatalam: 'tatalam',
  tatalar: 'tatalar',
  tatalas: 'tatalas',
  tatalei: 'tatalei',
  tatalem: 'tatalem',
  tatales: 'tatales',
  tatalou: 'tatalou',
  tatamba: 'tatamba',
  tatames: 'tatames',
  tatamis: 'tatâmis',
  tatanes: 'tatanés',
  tataras: 'tátaras',
  tatarea: 'tatareá',
  tatares: 'tatarés',
  tatarez: 'tatarez',
  tataria: 'tataria',
  tataros: 'tátaros',
  tatauba: 'tataúba',
  tateada: 'tateada',
  tateado: 'tateado',
  tateais: 'tateais',
  tateamo: 'tateamo',
  tateara: 'tateara',
  tateava: 'tateava',
  tateeis: 'tateeis',
  tateemo: 'tateemo',
  tateiam: 'tateiam',
  tateias: 'tateias',
  tateiem: 'tateiem',
  tateies: 'tateies',
  tateras: 'tateras',
  tatetos: 'tatetos',
  taticas: 'táticas',
  taticos: 'táticos',
  tatinho: 'tatinho',
  tatismo: 'tatismo',
  tatogas: 'tatogas',
  tatuaba: 'tatuaba',
  tatuacu: 'tatuaçu',
  tatuada: 'tatuada',
  tatuado: 'tatuado',
  tatuais: 'tatuais',
  tatuamo: 'tatuamo',
  tatuara: 'tatuara',
  tatuava: 'tatuava',
  tatucaa: 'tatucaá',
  tatucos: 'tatuços',
  tatueis: 'tatueis',
  tatuemo: 'tatuemo',
  tatuete: 'tatuetê',
  tatuias: 'tatuiás',
  tatuira: 'tatuíra',
  taturas: 'taturas',
  taturus: 'taturus',
  tatuzao: 'tatuzão',
  tauacus: 'tauaçus',
  tauanas: 'tauanãs',
  tauaras: 'tauaras',
  tauaris: 'tauaris',
  tauatos: 'tauatós',
  taubate: 'taubaté',
  tauguis: 'tauguis',
  tauiris: 'tauiris',
  tauismo: 'tauismo',
  tauista: 'tauista',
  tauites: 'tauités',
  tauncho: 'tauncho',
  tauocas: 'tauocas',
  taureas: 'táureas',
  taureos: 'táureos',
  taurica: 'táurica',
  taurico: 'táurico',
  taurida: 'táurida',
  taurilo: 'taurilo',
  taurina: 'taurina',
  taurino: 'taurino',
  taurins: 'taurins',
  taurita: 'taurita',
  taurite: 'taurite',
  taurito: 'taurito',
  tautaus: 'tautaus',
  tauteai: 'tauteai',
  tautear: 'tautear',
  tauteei: 'tauteei',
  tauteia: 'tauteia',
  tauteie: 'tauteie',
  tauteio: 'tauteio',
  tauteou: 'tauteou',
  tauxiai: 'tauxiai',
  tauxiam: 'tauxiam',
  tauxiar: 'tauxiar',
  tauxias: 'tauxias',
  tauxiei: 'tauxiei',
  tauxiem: 'tauxiem',
  tauxies: 'tauxies',
  tauxiou: 'tauxiou',
  tavalas: 'tavalas',
  tavanes: 'tavanês',
  tavaras: 'tavaras',
  tavares: 'tavares',
  tavedas: 'távedas',
  tavegas: 'távegas',
  tavenas: 'tavenas',
  tavenos: 'tavenos',
  taverna: 'taverna',
  tavilas: 'tavilas',
  tavitas: 'tavitas',
  tavites: 'tavites',
  tavocas: 'tavocas',
  tavolas: 'távolas',
  taxacao: 'taxação',
  taxacea: 'taxácea',
  taxaceo: 'taxáceo',
  taxadao: 'taxadão',
  taxadas: 'taxadas',
  taxador: 'taxador',
  taxados: 'taxados',
  taxales: 'taxales',
  taxamos: 'taxamos',
  taxando: 'taxando',
  taxante: 'taxante',
  taxaram: 'taxaram',
  taxarao: 'taxarão',
  taxaras: 'taxaras',
  taxarei: 'taxarei',
  taxarem: 'taxarem',
  taxares: 'taxares',
  taxaria: 'taxaria',
  taxarmo: 'taxarmo',
  taxasse: 'taxasse',
  taxaste: 'taxaste',
  taxavam: 'taxavam',
  taxavas: 'taxavas',
  taxavel: 'taxável',
  taxemas: 'taxemas',
  taxemos: 'taxemos',
  taxiada: 'taxiada',
  taxiado: 'taxiado',
  taxiais: 'taxiais',
  taxiamo: 'taxiamo',
  taxiara: 'taxiará',
  taxiava: 'taxiava',
  taxicas: 'táxicas',
  taxicos: 'táxicos',
  taxieis: 'taxieis',
  taxiemo: 'taxiemo',
  taxilas: 'táxilas',
  taxinas: 'taxinas',
  taxinea: 'taxínea',
  taxineo: 'taxíneo',
  taxinha: 'taxinha',
  taxipas: 'taxipás',
  taxiras: 'taxiras',
  taxista: 'taxista',
  taxitas: 'taxitas',
  taxites: 'taxites',
  taxizal: 'taxizal',
  taxodio: 'taxódio',
  taxupas: 'taxupás',
  taxuris: 'taxuris',
  tazolol: 'tazolol',
  tcarina: 'tçarina',
  tchecas: 'tchecas',
  tchecos: 'tchecos',
  tcheque: 'tcheque',
  teaceas: 'teáceas',
  teaceos: 'teáceos',
  teagens: 'teagens',
  teagoga: 'teagoga',
  teagogo: 'teagogo',
  tealias: 'teálias',
  tealita: 'tealita',
  tealite: 'tealite',
  teamina: 'teamina',
  teatina: 'teatina',
  teatine: 'teatine',
  teatino: 'teatino',
  teatral: 'teatral',
  teatrao: 'teatrão',
  teatros: 'teatros',
  tebaica: 'tebaica',
  tebaico: 'tebaico',
  tebaida: 'tebaida',
  tebaina: 'tebaína',
  tebanas: 'tebanas',
  tebanos: 'tebanos',
  tebedai: 'tebedaí',
  tebeias: 'tebeias',
  tebeles: 'tebeles',
  tebenas: 'tebenas',
  tebense: 'tebense',
  tebexes: 'tebexês',
  tebuane: 'tebuane',
  tecadao: 'tecadão',
  tecadas: 'tecadas',
  tecador: 'tecador',
  tecados: 'tecados',
  tecalia: 'tecália',
  tecamos: 'teçamos',
  tecando: 'tecando',
  tecante: 'tecante',
  tecaram: 'tecaram',
  tecarao: 'tecarão',
  tecaras: 'tecarás',
  tecarei: 'tecarei',
  tecarem: 'tecarem',
  tecares: 'tecares',
  tecaria: 'tecaria',
  tecarmo: 'tecarmo',
  tecasse: 'tecasse',
  tecaste: 'tecaste',
  tecavam: 'tecavam',
  tecavas: 'tecavas',
  tecebas: 'tecebás',
  tecedor: 'tecedor',
  tecelao: 'tecelão',
  tecelas: 'tecelãs',
  teceloa: 'teceloa',
  tecemos: 'tecemos',
  tecendo: 'tecendo',
  teceram: 'teceram',
  tecerao: 'tecerão',
  teceras: 'teceras',
  tecerei: 'tecerei',
  tecerem: 'tecerem',
  teceres: 'teceres',
  teceria: 'teceria',
  tecermo: 'tecermo',
  tecesse: 'tecesse',
  teceste: 'teceste',
  technas: 'technas',
  technos: 'technos',
  tecidao: 'tecidão',
  tecidas: 'tecidas',
  tecidea: 'tecídea',
  tecidos: 'tecidos',
  tecieis: 'tecíeis',
  tecites: 'tecites',
  tecivel: 'tecível',
  teclada: 'teclada',
  teclado: 'teclado',
  teclais: 'teclais',
  teclamo: 'teclamo',
  teclara: 'teclara',
  teclava: 'teclava',
  tecleai: 'tecleai',
  teclear: 'teclear',
  tecleas: 'técleas',
  tecleei: 'tecleei',
  tecleia: 'tecleia',
  tecleie: 'tecleie',
  tecleio: 'tecleio',
  tecleis: 'tecleis',
  teclemo: 'teclemo',
  tecleou: 'tecleou',
  tecnica: 'técnica',
  tecnico: 'técnico',
  tecnita: 'tecnita',
  tecoita: 'tecoíta',
  tecoles: 'tecoles',
  tecomas: 'tecomas',
  tecomea: 'tecômea',
  tectito: 'tectito',
  tectona: 'tectona',
  tectriz: 'tectriz',
  tecuena: 'tecuena',
  tecueno: 'tecueno',
  tecumas: 'tecumas',
  tecunas: 'tecunas',
  tedania: 'tedânia',
  tedesco: 'tedesco',
  tediosa: 'tediosa',
  tedioso: 'tedioso',
  tedoras: 'tedoras',
  tedores: 'tedores',
  teedias: 'teédias',
  teedora: 'teedora',
  tefilim: 'tefilim',
  tefloes: 'teflões',
  teflons: 'teflons',
  tefrina: 'tefrina',
  tefrita: 'tefrita',
  tefrite: 'tefrite',
  tefrito: 'tefrito',
  tefrose: 'tefrose',
  tegafur: 'tegafur',
  tegeata: 'tegeata',
  tegeeus: 'tegeeus',
  tegeria: 'tegéria',
  tegmais: 'tegmais',
  tegmens: 'tégmens',
  tegmina: 'tégmina',
  tegular: 'tegular',
  tegulas: 'tégulas',
  tegumen: 'tégumen',
  tegurio: 'tegúrio',
  teigula: 'têigula',
  teiideo: 'teiídeo',
  teimada: 'teimada',
  teimado: 'teimado',
  teimais: 'teimais',
  teimamo: 'teimamo',
  teimara: 'teimara',
  teimava: 'teimava',
  teimeis: 'teimeis',
  teimemo: 'teimemo',
  teimice: 'teimice',
  teimosa: 'teimosa',
  teimoso: 'teimoso',
  teineas: 'teíneas',
  teipoca: 'teipoca',
  teiroga: 'teiroga',
  teismos: 'teísmos',
  teistas: 'teístas',
  teiteis: 'teiteis',
  teixugo: 'teixugo',
  tejanas: 'tejanas',
  tejanos: 'tejanos',
  tejaroz: 'tejaroz',
  tejidea: 'tejídea',
  tejideo: 'tejídeo',
  tejoila: 'tejoila',
  tejoula: 'tejoula',
  tejuacu: 'tejuaçu',
  tejubus: 'tejubus',
  tejucos: 'tejucos',
  tejuiba: 'tejuíba',
  tejupar: 'tejupar',
  tejupim: 'tejupim',
  tejutiu: 'tejutiú',
  teladao: 'teladão',
  teladas: 'teladas',
  telador: 'telador',
  telados: 'telados',
  telamao: 'telamão',
  telamon: 'télamon',
  telamos: 'telamos',
  telando: 'telando',
  telante: 'telante',
  telaram: 'telaram',
  telarao: 'telarão',
  telaras: 'telarás',
  telarca: 'telarca',
  telarei: 'telarei',
  telarem: 'telarem',
  telares: 'telares',
  telaria: 'telária',
  telarmo: 'telarmo',
  telaros: 'telaros',
  telasse: 'telasse',
  telaste: 'telaste',
  telavam: 'telavam',
  telavas: 'telavas',
  telebes: 'telebês',
  teleboa: 'teléboa',
  teleboo: 'teléboo',
  telecal: 'telecal',
  telefax: 'telefax',
  telefio: 'teléfio',
  telegas: 'telegas',
  telemos: 'telemos',
  telepar: 'telepar',
  telepos: 'télepos',
  teleses: 'teleses',
  telesia: 'telésia',
  telesto: 'telesto',
  televam: 'televam',
  televas: 'televas',
  televei: 'televei',
  televem: 'televem',
  telever: 'telever',
  televes: 'televes',
  televeu: 'televeu',
  televia: 'televia',
  televim: 'televim',
  televir: 'televir',
  telexai: 'telexai',
  telexam: 'telexam',
  telexar: 'telexar',
  telexas: 'telexas',
  telexei: 'telexei',
  telexem: 'telexem',
  telexes: 'telexes',
  telexou: 'telexou',
  telhada: 'telhada',
  telhado: 'telhado',
  telhais: 'telhais',
  telhamo: 'telhamo',
  telhara: 'telhara',
  telhava: 'telhava',
  telheis: 'telheis',
  telhemo: 'telhemo',
  telhice: 'telhice',
  telhoes: 'telhões',
  telhuda: 'telhuda',
  telhudo: 'telhudo',
  teliana: 'teliana',
  teliano: 'teliano',
  telidio: 'telídio',
  telilha: 'telilha',
  telinas: 'telinas',
  telinga: 'telinga',
  telinha: 'telinha',
  telinio: 'telínio',
  telinta: 'telinta',
  telinte: 'telinte',
  telinto: 'telinto',
  telista: 'telista',
  telites: 'telites',
  telizes: 'telizes',
  telonas: 'telonas',
  telonio: 'telônio',
  telopes: 'telopes',
  telopse: 'telopse',
  telsons: 'télsons',
  telugos: 'telugos',
  telurio: 'telúrio',
  temamos: 'temamos',
  temario: 'temário',
  tembeta: 'tembetá',
  temblai: 'temblai',
  temblam: 'temblam',
  temblar: 'temblar',
  temblas: 'temblas',
  temblei: 'temblei',
  temblem: 'temblem',
  tembles: 'tembles',
  temblou: 'temblou',
  temedor: 'temedor',
  temefos: 'temefós',
  temembo: 'temembó',
  temembu: 'temembu',
  tememos: 'tememos',
  temendo: 'temendo',
  temenis: 'têmenis',
  temenos: 'têmenos',
  temente: 'temente',
  temeram: 'temeram',
  temerao: 'temerão',
  temeras: 'temeras',
  temerei: 'temerei',
  temerem: 'temerem',
  temeres: 'temeres',
  temeria: 'temeria',
  temermo: 'temermo',
  temeros: 'temeros',
  temerso: 'temersó',
  temesse: 'temesse',
  temeste: 'temeste',
  temetos: 'temetos',
  temidao: 'temidão',
  temidas: 'temidas',
  temidos: 'temidos',
  temieis: 'temíeis',
  temisto: 'temisto',
  temivel: 'temível',
  temnita: 'temnita',
  temodox: 'temodox',
  temonai: 'temonai',
  temonam: 'temonam',
  temonar: 'temonar',
  temonas: 'temonas',
  temonei: 'temonei',
  temonem: 'temonem',
  temones: 'temones',
  temonou: 'temonou',
  temores: 'temores',
  tempera: 'tempera',
  tempere: 'tempere',
  tempero: 'tempero',
  templai: 'templai',
  templam: 'templam',
  templar: 'templar',
  templas: 'templas',
  templei: 'templei',
  templem: 'templem',
  temples: 'temples',
  templos: 'templos',
  templou: 'templou',
  tempoes: 'tempões',
  tempora: 'têmpora',
  tempore: 'tempore',
  temporo: 'têmporo',
  temposa: 'temposa',
  tempura: 'tempura',
  temudas: 'temudas',
  temudos: 'temudos',
  tenador: 'tenador',
  tenalha: 'tenalha',
  tenalhe: 'tenalhe',
  tenalho: 'tenalho',
  tenalim: 'tenalim',
  tenares: 'tenares',
  tenaria: 'tenaria',
  tenazai: 'tenazai',
  tenazam: 'tenazam',
  tenazar: 'tenazar',
  tenazas: 'tenazas',
  tenazei: 'tenazei',
  tenazem: 'tenazem',
  tenazes: 'tenazes',
  tenazou: 'tenazou',
  tencoai: 'tençoai',
  tencoam: 'tençoam',
  tencoar: 'tençoar',
  tencoas: 'tençoas',
  tencoei: 'tençoei',
  tencoem: 'tençoem',
  tencoes: 'tenções',
  tencona: 'tençona',
  tencoou: 'tençoou',
  tendada: 'tendada',
  tendado: 'tendado',
  tendais: 'tendais',
  tendamo: 'tendamo',
  tendeis: 'tendeis',
  tendemo: 'tendemo',
  tendepa: 'tendepá',
  tendera: 'tendera',
  tendiam: 'tendiam',
  tendias: 'tendias',
  tendida: 'tendida',
  tendido: 'tendido',
  tendoes: 'tendões',
  tendola: 'tendola',
  tendona: 'tendona',
  tenebra: 'tênebra',
  tenedia: 'tenédia',
  tenedio: 'tenédio',
  tenenem: 'teneném',
  tenente: 'tenente',
  tenesmo: 'tenesmo',
  tengues: 'tengues',
  tenhais: 'tenhais',
  tenhamo: 'tenhamo',
  tenhosa: 'tenhosa',
  teniada: 'teniada',
  teniado: 'teniado',
  teniase: 'teníase',
  teniata: 'teniata',
  tenidio: 'tenídio',
  tenilha: 'tenilha',
  teniola: 'teníola',
  teniope: 'teniope',
  teniota: 'teniota',
  tenioto: 'tenioto',
  tenista: 'tenista',
  tenitas: 'tenitas',
  tenitos: 'tenitos',
  teniura: 'teniúra',
  tenores: 'tenores',
  tenoria: 'tenória',
  tenorio: 'tenório',
  tenosal: 'tenosal',
  tenrada: 'tenrada',
  tenrura: 'tenrura',
  tenseis: 'tênseis',
  tensiva: 'tensiva',
  tensivo: 'tensivo',
  tensoes: 'tensões',
  tensona: 'tensona',
  tensora: 'tensora',
  tentada: 'tentada',
  tentado: 'tentado',
  tentais: 'tentais',
  tentame: 'tentame',
  tentamo: 'tentamo',
  tentana: 'tentana',
  tentara: 'tentara',
  tentava: 'tentava',
  tenteai: 'tenteai',
  tenteal: 'tenteal',
  tentear: 'tentear',
  tenteei: 'tenteei',
  tenteia: 'tenteia',
  tenteie: 'tenteie',
  tenteio: 'tenteio',
  tenteis: 'tenteis',
  tentemo: 'tentemo',
  tentena: 'tentena',
  tentene: 'tentene',
  tenteno: 'tenteno',
  tentens: 'tenténs',
  tenteou: 'tenteou',
  tentere: 'tenterê',
  tentigo: 'tentigo',
  teocais: 'teocais',
  teocali: 'teocáli',
  teocina: 'teocina',
  teofaga: 'teófaga',
  teofago: 'teófago',
  teofoba: 'teófoba',
  teofobo: 'teófobo',
  teofora: 'teófora',
  teoforo: 'teóforo',
  teolina: 'teolina',
  teologa: 'teóloga',
  teologo: 'teólogo',
  teomana: 'teômana',
  teomano: 'teômano',
  teonela: 'teonela',
  teonima: 'teônima',
  teonimo: 'teônimo',
  teonina: 'teonina',
  teonino: 'teonino',
  teonoes: 'teônoes',
  teopeia: 'teopeia',
  teopsia: 'teopsia',
  teorbas: 'teorbas',
  teorema: 'teorema',
  teorias: 'teorias',
  teorica: 'teórica',
  teorico: 'teórico',
  teorida: 'teórida',
  teoriza: 'teoriza',
  teorize: 'teorize',
  teorizo: 'teorizo',
  teosofo: 'teósofo',
  teoteia: 'teoteia',
  tepalas: 'tépalas',
  tepente: 'tepente',
  tepidas: 'tépidas',
  tepidez: 'tepidez',
  tepidos: 'tépidos',
  tepores: 'tepores',
  tequeis: 'tequeis',
  tequemo: 'tequemo',
  tequeta: 'tequetá',
  tequete: 'tequete',
  tequeto: 'tequeto',
  tequila: 'tequila',
  terabit: 'terabit',
  terafim: 'terafim',
  teraira: 'teraíra',
  terapia: 'terapia',
  terarca: 'terarca',
  teratia: 'teratia',
  teratos: 'teratos',
  terbica: 'térbica',
  terbico: 'térbico',
  terbina: 'terbina',
  terbios: 'térbios',
  tercada: 'terçada',
  tercado: 'terçado',
  tercais: 'terçais',
  tercamo: 'terçamo',
  tercara: 'terçará',
  tercava: 'terçava',
  terceis: 'terceis',
  tercemo: 'tercemo',
  tercena: 'tercena',
  terceta: 'terceta',
  tercete: 'tercete',
  terceto: 'terceto',
  terciai: 'terciai',
  terciam: 'terciam',
  terciar: 'terciar',
  tercias: 'tércias',
  terciei: 'terciei',
  terciem: 'terciem',
  tercies: 'tercies',
  tercina: 'tercina',
  tercios: 'tércios',
  terciou: 'terciou',
  tercoes: 'terções',
  tercogo: 'terçogo',
  tercois: 'terçóis',
  terebra: 'térebra',
  terebre: 'terebre',
  terebro: 'terebro',
  terecai: 'terecaí',
  teredao: 'teredão',
  teredem: 'teredem',
  teredos: 'teredos',
  tereias: 'tereias',
  teremim: 'teremim',
  teremos: 'teremos',
  terenas: 'terenas',
  terenos: 'terenos',
  tereres: 'tererês',
  teresas: 'teresas',
  teresos: 'teresos',
  tergais: 'tergais',
  tergite: 'tergite',
  tergito: 'tergito',
  teriaca: 'teríaca',
  teriaco: 'teríaco',
  teriaga: 'teriaga',
  tericia: 'terícia',
  teridio: 'terídio',
  terieis: 'teríeis',
  terineu: 'terineu',
  terites: 'terités',
  terjura: 'terjurá',
  terjure: 'terjure',
  terjuro: 'terjuro',
  terlada: 'terlada',
  terlade: 'terlade',
  terlado: 'terlado',
  terluca: 'terluca',
  termais: 'termais',
  termiao: 'termião',
  termias: 'termias',
  termica: 'térmica',
  termico: 'térmico',
  termina: 'termina',
  termine: 'termine',
  termino: 'término',
  termion: 'termíon',
  termita: 'térmita',
  termite: 'termite',
  termodo: 'térmodo',
  termose: 'termose',
  ternada: 'ternada',
  ternado: 'ternado',
  ternais: 'ternais',
  ternamo: 'ternamo',
  ternara: 'ternara',
  ternate: 'ternate',
  ternava: 'ternava',
  terneis: 'terneis',
  ternemo: 'ternemo',
  terneza: 'terneza',
  ternura: 'ternura',
  teroide: 'teroide',
  terpena: 'terpena',
  terpeno: 'terpeno',
  terpina: 'terpina',
  terpola: 'terpola',
  terraco: 'terraço',
  terrada: 'terrada',
  terrado: 'terrado',
  terrais: 'terrais',
  terramo: 'terramo',
  terrara: 'terrara',
  terrava: 'terrava',
  terreai: 'terreai',
  terreal: 'terreal',
  terrear: 'terrear',
  terreas: 'térreas',
  terreei: 'terreei',
  terreia: 'terreia',
  terreie: 'terreie',
  terreio: 'terreio',
  terreis: 'terreis',
  terreja: 'terrejá',
  terreje: 'terreje',
  terrejo: 'terrejo',
  terremo: 'terremo',
  terrena: 'terrena',
  terreno: 'terreno',
  terreos: 'térreos',
  terreou: 'terreou',
  terreus: 'terréus',
  terrica: 'terriça',
  terrico: 'terriço',
  terrier: 'terrier',
  terries: 'terriês',
  terrina: 'terrina',
  terroes: 'terrões',
  terrois: 'terrois',
  terrola: 'terrola',
  terrora: 'terrora',
  terrore: 'terrore',
  terroro: 'terroro',
  terrosa: 'terrosa',
  terroso: 'terroso',
  tersina: 'tersina',
  tersois: 'tersóis',
  tersura: 'tersura',
  tertius: 'tertius',
  teruela: 'teruela',
  teruelo: 'teruelo',
  tesadao: 'tesadão',
  tesadas: 'tesadas',
  tesador: 'tesador',
  tesados: 'tesados',
  tesamos: 'tesamos',
  tesando: 'tesando',
  tesante: 'tesante',
  tesaram: 'tesaram',
  tesarao: 'tesarão',
  tesaras: 'tesarás',
  tesarei: 'tesarei',
  tesarem: 'tesarem',
  tesares: 'tesares',
  tesaria: 'tesaria',
  tesarmo: 'tesarmo',
  tesasse: 'tesasse',
  tesaste: 'tesaste',
  tesauro: 'tesauro',
  tesavam: 'tesavam',
  tesavas: 'tesavas',
  tesbita: 'tesbita',
  tescoes: 'tescões',
  teseias: 'teseias',
  tesemos: 'tesemos',
  tesicam: 'tesicam',
  tesidao: 'tesidão',
  tesidas: 'tesidas',
  tesieas: 'tesíeas',
  tesinho: 'tesinho',
  teslaia: 'teslaia',
  teslaio: 'teslaio',
  teslina: 'teslina',
  teslino: 'teslino',
  tesmina: 'tesmina',
  tesmine: 'tesmine',
  tesmino: 'tesmino',
  tesoira: 'tesoira',
  tesoire: 'tesoire',
  tesoiro: 'tesoiro',
  tesonas: 'tesonas',
  tesoura: 'tesoura',
  tesoure: 'tesoure',
  tesouro: 'tesouro',
  tespias: 'téspias',
  tespieu: 'tespieu',
  tespios: 'téspios',
  tessala: 'téssala',
  tessalo: 'téssalo',
  tessela: 'tessela',
  tessele: 'tessele',
  tesselo: 'tesselo',
  tessera: 'téssera',
  testaca: 'testaça',
  testada: 'testada',
  testado: 'testado',
  testais: 'testais',
  testamo: 'testamo',
  testara: 'testara',
  testava: 'testava',
  testeai: 'testeai',
  testear: 'testear',
  testeei: 'testeei',
  testeia: 'testeia',
  testeie: 'testeie',
  testeio: 'testeio',
  testeis: 'testeis',
  testemo: 'testemo',
  testeou: 'testeou',
  testica: 'testicá',
  testico: 'testico',
  testigo: 'testigo',
  testuda: 'testuda',
  testude: 'testude',
  testudo: 'testudo',
  testuga: 'testugá',
  testugo: 'testugo',
  testula: 'téstula',
  tesudas: 'tesudas',
  tesudos: 'tesudos',
  tesuras: 'tesuras',
  tetadao: 'tetadão',
  tetadas: 'tetadas',
  tetador: 'tetador',
  tetados: 'tetados',
  tetamos: 'tetamos',
  tetando: 'tetando',
  tetania: 'tetania',
  tetanis: 'tetanis',
  tetanos: 'tétanos',
  tetante: 'tetante',
  tetaram: 'tetaram',
  tetarao: 'tetarão',
  tetaras: 'tetarás',
  tetarei: 'tetarei',
  tetarem: 'tetarem',
  tetares: 'tetares',
  tetaria: 'tetaria',
  tetarmo: 'tetarmo',
  tetasse: 'tetasse',
  tetaste: 'tetaste',
  tetavam: 'tetavam',
  tetavas: 'tetavas',
  teteias: 'teteias',
  teteira: 'teteira',
  tetemos: 'tetemos',
  tetense: 'tetense',
  tetetes: 'tetetés',
  teticas: 'téticas',
  teticos: 'téticos',
  tetidea: 'tetídea',
  tetideo: 'tetídeo',
  tetigia: 'tetígia',
  tetilas: 'tetilas',
  tetilha: 'tetilha',
  tetinas: 'tetinas',
  tetrada: 'tétrada',
  tetrade: 'tétrade',
  tetrado: 'tétrado',
  tetraos: 'tétraos',
  tetravo: 'tetravó',
  tetrica: 'tétrica',
  tetrico: 'tétrico',
  tetrilo: 'tetrilo',
  tetrite: 'tetrite',
  tetrodo: 'tétrodo',
  tetrois: 'tetróis',
  tetrope: 'tetrope',
  tetrose: 'tetrose',
  tetudas: 'tetudas',
  tetudos: 'tetudos',
  teucras: 'teucras',
  teucrio: 'têucrio',
  teucros: 'teucros',
  teurgia: 'teurgia',
  teurgos: 'teurgos',
  teutoes: 'teutões',
  teutona: 'teutona',
  teutone: 'têutone',
  tevecia: 'tevécia',
  tevente: 'tevente',
  tevetia: 'tevétia',
  texanas: 'texanas',
  texanos: 'texanos',
  texiana: 'texiana',
  texiano: 'texiano',
  texteis: 'têxteis',
  textora: 'textora',
  textrix: 'téxtrix',
  textual: 'textual',
  textura: 'textura',
  texture: 'texture',
  texturo: 'texturo',
  texugos: 'texugos',
  tezimes: 'tezimes',
  tezinho: 'tezinho',
  thinner: 'thinner',
  thulita: 'thulita',
  thulite: 'thulite',
  thurnia: 'thúrnia',
  tiacuba: 'tiaçuba',
  tialina: 'tialina',
  tialzao: 'tialzão',
  tiambos: 'tiambos',
  tiamida: 'tiamida',
  tiamina: 'tiamina',
  tianeia: 'tianeia',
  tianeus: 'tianeus',
  tianhas: 'tianhas',
  tiarela: 'tiarela',
  tiarena: 'tiarena',
  tiareno: 'tiareno',
  tiarina: 'tiarina',
  tiarode: 'tiarode',
  tiasote: 'tiasote',
  tiatida: 'tiatida',
  tiatira: 'tiatira',
  tiazida: 'tiazida',
  tiazina: 'tiazina',
  tiazois: 'tiazois',
  tiazona: 'tiazona',
  tibacas: 'tibacas',
  tibadao: 'tibadão',
  tibadas: 'tibadas',
  tibador: 'tibador',
  tibados: 'tibados',
  tibamos: 'tibamos',
  tibanco: 'tibanco',
  tibando: 'tibando',
  tibante: 'tibante',
  tibaram: 'tibaram',
  tibarao: 'tibarão',
  tibaras: 'tibaras',
  tibarei: 'tibarei',
  tibarem: 'tibarem',
  tibares: 'tibares',
  tibaria: 'tibaria',
  tibarmo: 'tibarmo',
  tibasse: 'tibasse',
  tibaste: 'tibaste',
  tibavam: 'tibavam',
  tibavas: 'tibavas',
  tibelos: 'tibelos',
  tibemos: 'tibemos',
  tiberia: 'tibéria',
  tibetes: 'tibetes',
  tibiais: 'tibiais',
  tibieza: 'tibieza',
  tibirai: 'tibirai',
  tibiram: 'tibiram',
  tibirar: 'tibirar',
  tibiras: 'tibiras',
  tibirei: 'tibirei',
  tibirem: 'tibirem',
  tibires: 'tibires',
  tibiros: 'tibiros',
  tibirou: 'tibirou',
  tiborna: 'tiborna',
  tibraca: 'tibraca',
  tibrico: 'tíbrico',
  tibrina: 'tibrina',
  tibrino: 'tibrino',
  tibunas: 'tibunas',
  tibunga: 'tibunga',
  tibungo: 'tibungo',
  tiburio: 'tibúrio',
  tiburna: 'tiburna',
  tiburno: 'tiburno',
  tiburos: 'tiburos',
  tiburte: 'tiburte',
  ticacas: 'ticacas',
  ticadao: 'ticadão',
  ticadas: 'ticadas',
  ticador: 'ticador',
  ticados: 'ticados',
  ticalho: 'tiçalho',
  ticamos: 'ticamos',
  ticando: 'ticando',
  ticante: 'ticante',
  ticaram: 'ticaram',
  ticarao: 'ticarão',
  ticaras: 'ticaras',
  ticarei: 'ticarei',
  ticarem: 'ticarem',
  ticares: 'ticares',
  ticaria: 'ticaria',
  ticarmo: 'ticarmo',
  ticasse: 'ticasse',
  ticaste: 'ticaste',
  ticavam: 'ticavam',
  ticavas: 'ticavas',
  tichita: 'tichita',
  ticoada: 'tiçoada',
  ticolao: 'ticolão',
  ticonha: 'ticonha',
  ticonos: 'ticonos',
  ticopas: 'ticopás',
  ticorea: 'ticórea',
  ticoros: 'ticorós',
  ticosas: 'ticosas',
  ticosos: 'ticosos',
  ticuara: 'ticuara',
  ticucas: 'ticucas',
  ticumbi: 'ticumbi',
  ticunas: 'ticunas',
  ticupas: 'ticupás',
  ticupis: 'ticupis',
  ticuras: 'ticuras',
  tidinha: 'tidinha',
  tidinho: 'tidinho',
  tidonas: 'tidonas',
  tidoras: 'tidoras',
  tidores: 'tidores',
  tielita: 'tielita',
  tiemias: 'tiemias',
  tiemica: 'tiêmica',
  tiemico: 'tiêmico',
  tienzus: 'tienzus',
  tiestea: 'tiéstea',
  tiesteo: 'tiésteo',
  tietada: 'tietada',
  tietado: 'tietado',
  tietais: 'tietais',
  tietamo: 'tietamo',
  tietara: 'tietará',
  tietava: 'tietava',
  tieteis: 'tieteis',
  tietemo: 'tietemo',
  tifacea: 'tifácea',
  tifaceo: 'tifáceo',
  tifales: 'tifales',
  tifeias: 'tifeias',
  tifemia: 'tifemia',
  tifenta: 'tifenta',
  tifento: 'tifento',
  tifeola: 'tiféola',
  tificas: 'tíficas',
  tificos: 'tíficos',
  tifidea: 'tifídea',
  tifideo: 'tifídeo',
  tifismo: 'tifismo',
  tifista: 'tifista',
  tiflina: 'tiflina',
  tiflite: 'tiflite',
  tiflono: 'tiflono',
  tiflope: 'tiflope',
  tiflops: 'tíflops',
  tiflose: 'tiflose',
  tifobia: 'tifóbia',
  tifoias: 'tifoias',
  tifoide: 'tifoide',
  tifosas: 'tifosas',
  tifoses: 'tifoses',
  tifosos: 'tifosos',
  tifulas: 'tífulas',
  tigarro: 'tigarro',
  tigelao: 'tigelão',
  tigelas: 'tigelas',
  tigelos: 'tigelos',
  tigenol: 'tigenol',
  tiglato: 'tiglato',
  tiglica: 'tíglica',
  tiglico: 'tíglico',
  tiglina: 'tiglina',
  tiglios: 'tíglios',
  tigloes: 'tiglões',
  tigrada: 'tigrada',
  tigrado: 'tigrado',
  tigreao: 'tigreão',
  tigresa: 'tigresa',
  tigrida: 'tígrida',
  tigride: 'tígride',
  tigrido: 'tígrido',
  tigrina: 'tigrina',
  tigrino: 'tigrino',
  tigroes: 'tigrões',
  tiguara: 'tiguara',
  tiguera: 'tiguera',
  tigulia: 'tigúlia',
  tigulio: 'tigúlio',
  tiguras: 'tiguras',
  tiiadas: 'tiíadas',
  tiieras: 'tiieras',
  tijelas: 'tijelas',
  tijibus: 'tijibus',
  tijocos: 'tijocos',
  tijolai: 'tijolai',
  tijolam: 'tijolam',
  tijolao: 'tijolão',
  tijolar: 'tijolar',
  tijolas: 'tijolas',
  tijolei: 'tijolei',
  tijolem: 'tijolem',
  tijoles: 'tijoles',
  tijolos: 'tijolos',
  tijolou: 'tijolou',
  tijuacu: 'tijuaçu',
  tijucal: 'tijucal',
  tijucas: 'tijucas',
  tijucos: 'tijucos',
  tijupar: 'tijupar',
  tijupas: 'tijupás',
  tilacio: 'tilácio',
  tiladao: 'tiladão',
  tiladas: 'tiladas',
  tilador: 'tilador',
  tilados: 'tilados',
  tilaido: 'tilaído',
  tilaita: 'tilaíta',
  tilaite: 'tilaíte',
  tilamos: 'tilamos',
  tilanda: 'tilanda',
  tilando: 'tilando',
  tilante: 'tilante',
  tilanto: 'tilanto',
  tilapia: 'tilápia',
  tilaram: 'tilaram',
  tilarao: 'tilarão',
  tilaras: 'tilaras',
  tilarei: 'tilarei',
  tilarem: 'tilarem',
  tilares: 'tilares',
  tilaria: 'tilaria',
  tilarmo: 'tilarmo',
  tilasse: 'tilasse',
  tilaste: 'tilaste',
  tilavam: 'tilavam',
  tilavas: 'tilavas',
  tilburi: 'tílburi',
  tildada: 'tildada',
  tildado: 'tildado',
  tildais: 'tildais',
  tildamo: 'tildamo',
  tildara: 'tildará',
  tildava: 'tildava',
  tildeis: 'tildeis',
  tildemo: 'tildemo',
  tilecia: 'tilécia',
  tileias: 'tileias',
  tilemos: 'tilemos',
  tilenco: 'tilenco',
  tileria: 'tiléria',
  tiletia: 'tilétia',
  tilhada: 'tilhada',
  tilhado: 'tilhado',
  tilibix: 'tilíbix',
  tilieas: 'tilíeas',
  tilinas: 'tilinas',
  tilinea: 'tilínea',
  tilineo: 'tilíneo',
  tilinta: 'tilinta',
  tilinte: 'tilinte',
  tilinto: 'tilinto',
  tilitos: 'tilitos',
  tilodes: 'tilodes',
  tilodos: 'tilodos',
  tilomas: 'tilomas',
  tilomio: 'tilômio',
  tilomis: 'tílomis',
  tilosas: 'tilosas',
  tiloses: 'tiloses',
  tilosos: 'tilosos',
  timacas: 'tímacas',
  timacos: 'timaços',
  timalho: 'timalho',
  timalia: 'timália',
  timalos: 'timalos',
  timaneu: 'timaneu',
  timarca: 'timarca',
  timares: 'timares',
  timaris: 'timáris',
  timbaba: 'timbaba',
  timbais: 'timbais',
  timbale: 'timbale',
  timbatu: 'timbatu',
  timbeba: 'timbeba',
  timbila: 'timbila',
  timbira: 'timbira',
  timbiro: 'timbiro',
  timbore: 'timboré',
  timbrai: 'timbrai',
  timbram: 'timbram',
  timbrar: 'timbrar',
  timbras: 'timbras',
  timbrea: 'tímbrea',
  timbrei: 'timbrei',
  timbrem: 'timbrem',
  timbreo: 'tímbreo',
  timbres: 'timbres',
  timbris: 'timbris',
  timbrou: 'timbrou',
  timbuai: 'timbuai',
  timbuam: 'timbuam',
  timbuar: 'timbuar',
  timbuas: 'timbuas',
  timbucu: 'timbucu',
  timbuei: 'timbuei',
  timbuem: 'timbuem',
  timbues: 'timbues',
  timbuia: 'timbuia',
  timbuis: 'timbuís',
  timbuou: 'timbuou',
  timbure: 'timburé',
  timburi: 'timburi',
  timbuva: 'timbuva',
  timelas: 'tímelas',
  timeles: 'tímeles',
  timeleu: 'timeleu',
  timelia: 'timélia',
  timenas: 'timenas',
  timenes: 'timenés',
  timenio: 'timênio',
  timenos: 'timenos',
  timento: 'timento',
  timiama: 'timiama',
  timicas: 'tímicas',
  timicos: 'tímicos',
  timicui: 'timicuí',
  timicus: 'timicus',
  timidas: 'tímidas',
  timidez: 'timidez',
  timidos: 'tímidos',
  timilos: 'timilos',
  timinas: 'timinas',
  timiose: 'timiose',
  timites: 'timites',
  timnita: 'timnita',
  timolol: 'timolol',
  timonai: 'timonai',
  timonam: 'timonam',
  timonar: 'timonar',
  timonas: 'timonas',
  timonea: 'timoneá',
  timonei: 'timonei',
  timonem: 'timonem',
  timones: 'timones',
  timonou: 'timonou',
  timpano: 'tímpano',
  tinacao: 'tinação',
  tinadas: 'tinadas',
  tinalha: 'tinalha',
  tinamos: 'tinamos',
  tinamus: 'tinamus',
  tinaria: 'tinaria',
  tincais: 'tincais',
  tincoas: 'tincoãs',
  tincoes: 'tinções',
  tindalo: 'tíndalo',
  tindins: 'tindins',
  tindola: 'tindola',
  tinebra: 'tinebra',
  tinebre: 'tinebre',
  tinefes: 'tinefes',
  tineias: 'tineias',
  tineira: 'tineira',
  tinelos: 'tinelos',
  tinemba: 'tinemba',
  tinente: 'tinente',
  tineola: 'tinéola',
  tinetas: 'tinetas',
  tinfeia: 'tinfeia',
  tinfeus: 'tinfeus',
  tingacu: 'tingaçu',
  tingada: 'tingada',
  tingado: 'tingado',
  tingais: 'tingais',
  tingara: 'tingará',
  tingava: 'tingava',
  tingiam: 'tingiam',
  tingias: 'tingias',
  tingida: 'tingida',
  tingido: 'tingido',
  tingimo: 'tingimo',
  tingira: 'tingira',
  tingote: 'tingote',
  tinguas: 'tinguás',
  tinguei: 'tinguei',
  tinguem: 'tinguem',
  tingues: 'tingues',
  tinguis: 'tinguis',
  tingura: 'tingura',
  tinheis: 'tínheis',
  tinhoes: 'tinhões',
  tinhosa: 'tinhosa',
  tinhoso: 'tinhoso',
  tinhuma: 'tinhuma',
  tiniaca: 'tiníaca',
  tiniaco: 'tiníaco',
  tinidao: 'tinidão',
  tinidas: 'tinidas',
  tinidea: 'tinídea',
  tinideo: 'tinídeo',
  tinidor: 'tinidor',
  tinidos: 'tinidos',
  tinieis: 'tiníeis',
  tinilho: 'tinilho',
  tinimos: 'tinimos',
  tinindo: 'tinindo',
  tininte: 'tininte',
  tiniram: 'tiniram',
  tinirao: 'tinirão',
  tiniras: 'tiniras',
  tinirei: 'tinirei',
  tinirem: 'tinirem',
  tinires: 'tinires',
  tiniria: 'tiniria',
  tinirmo: 'tinirmo',
  tinisse: 'tinisse',
  tiniste: 'tiniste',
  tinitas: 'tinitas',
  tinitos: 'tinitos',
  tinjais: 'tinjais',
  tinjamo: 'tinjamo',
  tinjema: 'tinjema',
  tinoada: 'tinoada',
  tinocas: 'tinocas',
  tinodes: 'tinodes',
  tinoria: 'tinória',
  tinorio: 'tinório',
  tinotes: 'tinotes',
  tinotos: 'tinotos',
  tinquis: 'tinquis',
  tintada: 'tintada',
  tintado: 'tintado',
  tintais: 'tintais',
  tintamo: 'tintamo',
  tintara: 'tintará',
  tintava: 'tintava',
  tinteis: 'tinteis',
  tintemo: 'tintemo',
  tintila: 'tintila',
  tintile: 'tintile',
  tintilo: 'tintilo',
  tintina: 'tintina',
  tintine: 'tintine',
  tintini: 'tintini',
  tintino: 'tintino',
  tintins: 'tintins',
  tintlai: 'tintlai',
  tintlam: 'tintlam',
  tintlar: 'tintlar',
  tintlas: 'tintlas',
  tintlei: 'tintlei',
  tintlem: 'tintlem',
  tintles: 'tintles',
  tintlou: 'tintlou',
  tintora: 'tintora',
  tintura: 'tintura',
  tioanil: 'tioanil',
  tioanis: 'tioanis',
  tiocois: 'tiocóis',
  tiocole: 'tiocole',
  tioeter: 'tioéter',
  tiofana: 'tiofana',
  tiofano: 'tiofano',
  tiofeno: 'tiofeno',
  tiofila: 'tiófila',
  tiofilo: 'tiófilo',
  tiogena: 'tiógena',
  tiogeno: 'tiógeno',
  tioicas: 'tioicas',
  tioicos: 'tioicos',
  tiolase: 'tiólase',
  tiolato: 'tiolato',
  tiolica: 'tiólica',
  tiolico: 'tiólico',
  tiolise: 'tiólise',
  tiolzao: 'tiolzão',
  tiombes: 'tiombes',
  tionato: 'tionato',
  tionica: 'tiônica',
  tionico: 'tiônico',
  tionida: 'tionida',
  tionido: 'tionido',
  tionila: 'tionila',
  tionilo: 'tionilo',
  tionina: 'tionina',
  tionois: 'tionóis',
  tiorbas: 'tiorbas',
  tiorega: 'tiorega',
  tiorgas: 'tiorgas',
  tiossal: 'tiossal',
  tiotepa: 'tiotepa',
  tipagem: 'tipagem',
  tipanea: 'tipânea',
  tipaneo: 'tipâneo',
  tipicai: 'tipicai',
  tipicam: 'tipicam',
  tipicar: 'tipicar',
  tipicas: 'típicas',
  tipicos: 'típicos',
  tipicou: 'tipicou',
  tipinha: 'tipinha',
  tipinho: 'tipinho',
  tipique: 'tipique',
  tipiris: 'tipiris',
  tipisca: 'tipisca',
  tipismo: 'tipismo',
  tipista: 'tipista',
  tipitis: 'tipitis',
  tipixas: 'tipixas',
  tipizai: 'tipizai',
  tipizam: 'tipizam',
  tipizar: 'tipizar',
  tipizas: 'tipizas',
  tipizei: 'tipizei',
  tipizem: 'tipizem',
  tipizes: 'tipizes',
  tipizou: 'tipizou',
  tipoias: 'tipoias',
  tiponas: 'tiponas',
  tiporia: 'tipória',
  tiporio: 'tipório',
  tipotes: 'tipotes',
  tiptons: 'típtons',
  tipuana: 'tipuana',
  tipucas: 'tipucas',
  tipuius: 'tipuiús',
  tipulas: 'típulas',
  tiquara: 'tiquara',
  tiqueai: 'tiqueai',
  tiquear: 'tiquear',
  tiqueei: 'tiqueei',
  tiqueia: 'tiqueia',
  tiqueie: 'tiqueie',
  tiqueio: 'tiqueio',
  tiqueis: 'tiqueis',
  tiquemo: 'tiquemo',
  tiqueou: 'tiqueou',
  tiquete: 'tíquete',
  tiquios: 'tíquios',
  tiquira: 'tiquira',
  tiracao: 'tiração',
  tiracos: 'tiraços',
  tiradao: 'tiradão',
  tiradas: 'tiradas',
  tirador: 'tirador',
  tirados: 'tirados',
  tiragem: 'tiragem',
  tiramos: 'tiramos',
  tiranas: 'tiranas',
  tirando: 'tirando',
  tirania: 'tirania',
  tiranos: 'tiranos',
  tirante: 'tirante',
  tirapes: 'tirapés',
  tiraram: 'tiraram',
  tirarao: 'tirarão',
  tiraras: 'tiraras',
  tirarei: 'tirarei',
  tirarem: 'tirarem',
  tirares: 'tirares',
  tiraria: 'tiraria',
  tirarmo: 'tirarmo',
  tirasse: 'tirasse',
  tiraste: 'tiraste',
  tiravam: 'tiravam',
  tiravas: 'tiravas',
  tirazes: 'tirazes',
  tirazio: 'tirázio',
  tircana: 'tircana',
  tirefao: 'tirefão',
  tireial: 'tireial',
  tireias: 'tireias',
  tiremos: 'tiremos',
  tirense: 'tirense',
  tiresia: 'tirésia',
  tiretes: 'tiretes',
  tiriama: 'tiriamã',
  tiriase: 'tiríase',
  tiribai: 'tiribaí',
  tiribas: 'tiribas',
  tiricas: 'tiriças',
  tiricia: 'tirícia',
  tiridia: 'tirídia',
  tiridio: 'tirídio',
  tirinas: 'tirinas',
  tirinha: 'tirinha',
  tirinho: 'tirinho',
  tiriras: 'tiriras',
  tiriris: 'tiriris',
  tiritai: 'tiritai',
  tiritam: 'tiritam',
  tiritar: 'tiritar',
  tiritas: 'tiritas',
  tiritei: 'tiritei',
  tiritem: 'tiritem',
  tirites: 'tirites',
  tiritir: 'tiritir',
  tiritou: 'tiritou',
  tiriuma: 'tiriúma',
  tirivas: 'tirivas',
  tiroial: 'tiroial',
  tiroide: 'tiroide',
  tiroles: 'tirolês',
  tiromas: 'tiromas',
  tironea: 'tironeá',
  tirotea: 'tiroteá',
  tirrena: 'tirrena',
  tirreno: 'tirreno',
  tirsito: 'tirsito',
  tirsosa: 'tirsosa',
  tirsoso: 'tirsoso',
  tisanas: 'tisanas',
  tisania: 'tisânia',
  tisanos: 'tísanos',
  tisbita: 'tisbita',
  tiscias: 'tíscias',
  tiscios: 'tíscios',
  tishrei: 'tishrei',
  tisicai: 'tisicai',
  tisicam: 'tisicam',
  tisicar: 'tisicar',
  tisicas: 'tísicas',
  tisicos: 'tísicos',
  tisicou: 'tisicou',
  tisique: 'tisique',
  tisitas: 'tisitas',
  tisites: 'tisites',
  tisitos: 'tisitos',
  tismias: 'tísmias',
  tismiea: 'tismíea',
  tisnada: 'tisnada',
  tisnado: 'tisnado',
  tisnais: 'tisnais',
  tisnamo: 'tisnamo',
  tisnara: 'tisnara',
  tisnava: 'tisnava',
  tisneis: 'tisneis',
  tisnemo: 'tisnemo',
  tissues: 'tissues',
  tistias: 'tistias',
  tisuria: 'tisúria',
  titanas: 'titanas',
  titania: 'titânia',
  titanio: 'titânio',
  titanos: 'titanos',
  titanox: 'titânox',
  titaras: 'titaras',
  titelas: 'titelas',
  titerea: 'titereá',
  titeres: 'títeres',
  titeris: 'titeris',
  titicai: 'titicai',
  titicam: 'titicam',
  titicar: 'titicar',
  titicas: 'titicas',
  titicou: 'titicou',
  titicua: 'titicuã',
  titilai: 'titilai',
  titilam: 'titilam',
  titilar: 'titilar',
  titilas: 'titilas',
  titilei: 'titilei',
  titilem: 'titilem',
  titiles: 'titiles',
  titilou: 'titilou',
  titinas: 'titinas',
  titinga: 'titinga',
  titique: 'titique',
  titiras: 'titiras',
  titismo: 'titismo',
  titista: 'titista',
  tititis: 'tititis',
  titonia: 'titônia',
  titonio: 'titônio',
  titubai: 'titubai',
  titubam: 'titubam',
  titubar: 'titubar',
  titubas: 'titubas',
  titubea: 'titubeá',
  titubei: 'titubei',
  titubem: 'titubem',
  titubes: 'titubes',
  titubou: 'titubou',
  titulai: 'titulai',
  titulam: 'titulam',
  titular: 'titular',
  titulas: 'titulas',
  titulei: 'titulei',
  titulem: 'titulem',
  titules: 'titules',
  titulos: 'títulos',
  titulou: 'titulou',
  tituria: 'tituria',
  tiufada: 'tiufada',
  tiufado: 'tiufado',
  tiureto: 'tiureto',
  tivemos: 'tivemos',
  tiveram: 'tiveram',
  tiveras: 'tiveras',
  tiverem: 'tiverem',
  tiveres: 'tiveres',
  tivermo: 'tivermo',
  tivesse: 'tivesse',
  tiveste: 'tiveste',
  tixadil: 'tixadil',
  tixadis: 'tixadis',
  tixanox: 'tixanox',
  tizitas: 'tizitas',
  tlanoma: 'tlanoma',
  tlaspea: 'tláspea',
  tlaspes: 'tlaspes',
  tlaspio: 'tláspio',
  tlintai: 'tlintai',
  tlintam: 'tlintam',
  tlintar: 'tlintar',
  tlintas: 'tlintas',
  tlintei: 'tlintei',
  tlintem: 'tlintem',
  tlintes: 'tlintes',
  tlintou: 'tlintou',
  tlipses: 'tlipses',
  tlipsia: 'tlipsia',
  tluquis: 'tluquis',
  tmolias: 'tmólias',
  tmolios: 'tmólios',
  tmolita: 'tmolita',
  toadoes: 'toadões',
  toadona: 'toadona',
  toadora: 'toadora',
  toalete: 'toalete',
  toalhao: 'toalhão',
  toalhas: 'toalhas',
  toancia: 'toância',
  toantes: 'toantes',
  toardas: 'toardas',
  toardes: 'toardes',
  toareis: 'toáreis',
  toaremo: 'toaremo',
  toariam: 'toariam',
  toarias: 'toarias',
  toarmos: 'toarmos',
  toassem: 'toassem',
  toasses: 'toasses',
  toastes: 'toastes',
  toaveis: 'toáveis',
  tobacas: 'tobacas',
  tobeira: 'tobeira',
  tobeiro: 'tobeiro',
  tobiana: 'tobiana',
  tobiano: 'tobiano',
  tobiras: 'tobiras',
  tobogao: 'tobogão',
  tobogas: 'tobogãs',
  tobotes: 'tobotes',
  tocadao: 'tocadão',
  tocadas: 'tocadas',
  tocador: 'tocador',
  tocados: 'tocados',
  tocagem: 'tocagem',
  tocaiai: 'tocaiai',
  tocaiam: 'tocaiam',
  tocaiao: 'tocaião',
  tocaiar: 'tocaiar',
  tocaias: 'tocaias',
  tocaiei: 'tocaiei',
  tocaiem: 'tocaiem',
  tocaies: 'tocaies',
  tocaiou: 'tocaiou',
  tocajes: 'tocajés',
  tocamas: 'tocamas',
  tocamos: 'tocamos',
  tocando: 'tocando',
  tocanos: 'tocanos',
  tocante: 'tocante',
  tocanti: 'tocanti',
  tocaram: 'tocaram',
  tocarao: 'tocarão',
  tocaras: 'tocaras',
  tocarei: 'tocarei',
  tocarem: 'tocarem',
  tocares: 'tocares',
  tocaria: 'tocaria',
  tocario: 'tocário',
  tocaris: 'tocaris',
  tocarmo: 'tocarmo',
  tocaros: 'tócaros',
  tocasse: 'tocasse',
  tocaste: 'tocaste',
  tocatas: 'tocatas',
  tocavam: 'tocavam',
  tocavas: 'tocavas',
  tocavel: 'tocável',
  toceada: 'toceada',
  toceado: 'toceado',
  toceais: 'toceais',
  toceara: 'toceará',
  toceava: 'toceava',
  toceeis: 'toceeis',
  toceiam: 'toceiam',
  toceias: 'toceias',
  toceiem: 'toceiem',
  toceies: 'toceies',
  tococas: 'tococas',
  tocoios: 'tocoiós',
  todalia: 'todália',
  todaroa: 'todároa',
  todavia: 'todavia',
  toddita: 'toddita',
  todeira: 'todeira',
  todeiro: 'todeiro',
  todidea: 'todídea',
  todideo: 'todídeo',
  todinea: 'todínea',
  todinha: 'todinha',
  todinho: 'todinho',
  todolos: 'todolos',
  toeiras: 'toeiras',
  toeiros: 'toeiros',
  toenita: 'toenita',
  toenite: 'toenite',
  toesada: 'toesada',
  toesado: 'toesado',
  toesais: 'toesais',
  toesamo: 'toesamo',
  toesara: 'toesará',
  toesava: 'toesava',
  toeseis: 'toeseis',
  toesemo: 'toesemo',
  tofacea: 'tofácea',
  tofaceo: 'tofáceo',
  tofacio: 'tofácio',
  tofanas: 'tofanas',
  tofeira: 'tofeira',
  togadao: 'togadão',
  togadas: 'togadas',
  togador: 'togador',
  togados: 'togados',
  togamos: 'togamos',
  togando: 'togando',
  togante: 'togante',
  togaram: 'togaram',
  togarao: 'togarão',
  togaras: 'togaras',
  togarei: 'togarei',
  togarem: 'togarem',
  togares: 'togares',
  togaria: 'togaria',
  togasse: 'togasse',
  togaste: 'togaste',
  togatas: 'togatas',
  togatos: 'togatos',
  togavam: 'togavam',
  togavas: 'togavas',
  togoles: 'togolês',
  togueis: 'togueis',
  toguesa: 'toguesa',
  toicada: 'toiçada',
  toicado: 'toiçado',
  toicais: 'toiçais',
  toicamo: 'toiçamo',
  toicara: 'toiçará',
  toicava: 'toiçava',
  toiceis: 'toiceis',
  toicemo: 'toicemo',
  toilete: 'toilete',
  toinhas: 'toinhas',
  toirada: 'toirada',
  toirado: 'toirado',
  toirais: 'toirais',
  toiramo: 'toiramo',
  toirara: 'toirará',
  toirava: 'toirava',
  toireai: 'toireai',
  toirear: 'toirear',
  toireei: 'toireei',
  toireia: 'toireia',
  toireie: 'toireie',
  toireio: 'toireio',
  toireis: 'toireis',
  toireja: 'toirejá',
  toireje: 'toireje',
  toirejo: 'toirejo',
  toiremo: 'toiremo',
  toireou: 'toireou',
  toirigo: 'toirigo',
  toiroes: 'toirões',
  toiruna: 'toiruna',
  toiruno: 'toiruno',
  toiteai: 'toiteai',
  toitear: 'toitear',
  toiteei: 'toiteei',
  toiteia: 'toiteia',
  toiteie: 'toiteie',
  toiteio: 'toiteio',
  toiteou: 'toiteou',
  toitico: 'toitiço',
  tojeira: 'tojeira',
  tojeiro: 'tojeiro',
  tojosas: 'tojosas',
  tojosos: 'tojosos',
  tokamak: 'tokamak',
  tolacas: 'tolaças',
  tolacos: 'tolaços',
  tolamos: 'tolamos',
  tolanas: 'tolanas',
  tolanga: 'tolanga',
  tolanos: 'tolanos',
  tolares: 'tolares',
  tolaria: 'tolaria',
  tolazes: 'tolazes',
  toldada: 'toldada',
  toldado: 'toldado',
  toldais: 'toldais',
  toldamo: 'toldamo',
  toldana: 'toldana',
  toldara: 'toldara',
  toldava: 'toldava',
  toldeia: 'toldeia',
  toldeis: 'toldeis',
  toldemo: 'toldemo',
  toledor: 'toledor',
  toledos: 'toledos',
  toleima: 'toleima',
  toleira: 'toleira',
  toleiro: 'toleiro',
  tolejai: 'tolejai',
  tolejam: 'tolejam',
  tolejar: 'tolejar',
  tolejas: 'tolejas',
  tolejei: 'tolejei',
  tolejem: 'tolejem',
  tolejes: 'tolejes',
  tolejou: 'tolejou',
  tolemos: 'tolemos',
  tolendo: 'tolendo',
  tolenos: 'tolenos',
  tolerai: 'tolerai',
  toleram: 'toleram',
  tolerao: 'tolerão',
  tolerar: 'tolerar',
  toleras: 'toleras',
  tolerei: 'tolerei',
  tolerem: 'tolerem',
  toleres: 'toleres',
  toleria: 'toleria',
  tolermo: 'tolermo',
  tolerou: 'tolerou',
  tolesse: 'tolesse',
  toleste: 'toleste',
  toletas: 'toletas',
  toletes: 'toletes',
  tolhais: 'tolhais',
  tolhamo: 'tolhamo',
  tolheis: 'tolheis',
  tolhemo: 'tolhemo',
  tolhera: 'tolhera',
  tolhiam: 'tolhiam',
  tolhias: 'tolhias',
  tolhica: 'tolhiça',
  tolhico: 'tolhiço',
  tolhida: 'tolhida',
  tolhido: 'tolhido',
  tolices: 'tolices',
  tolidao: 'tolidão',
  tolidas: 'tolidas',
  tolidos: 'tolidos',
  tolieis: 'tolíeis',
  tolilas: 'tolilas',
  tolilio: 'tolílio',
  tolilos: 'tolilos',
  tolinai: 'tolinai',
  tolinam: 'tolinam',
  tolinar: 'tolinar',
  tolinas: 'tolinas',
  tolinei: 'tolinei',
  tolinem: 'tolinem',
  tolines: 'tolines',
  tolinha: 'tolinha',
  tolinho: 'tolinho',
  tolinou: 'tolinou',
  tolipes: 'tólipes',
  tolismo: 'tolismo',
  tolista: 'tolista',
  tolitas: 'tolitas',
  tolites: 'tolites',
  tolonas: 'tolonas',
  tolonio: 'tolônio',
  tolpide: 'tólpide',
  tolteca: 'tolteca',
  toluato: 'toluato',
  tolucia: 'tolúcia',
  toluena: 'toluena',
  tolueno: 'tolueno',
  toluica: 'toluica',
  toluico: 'toluico',
  toluila: 'toluíla',
  toluilo: 'toluílo',
  toluina: 'toluína',
  toluino: 'toluíno',
  toluiza: 'toluizá',
  toluize: 'toluíze',
  toluizo: 'toluízo',
  toluois: 'toluóis',
  tomabeu: 'tomabeu',
  tomacao: 'tomação',
  tomadao: 'tomadão',
  tomadas: 'tomadas',
  tomadia: 'tomadia',
  tomador: 'tomador',
  tomados: 'tomados',
  tomaina: 'tomaína',
  tomaita: 'tomaíta',
  tomaite: 'tomaíte',
  tomaito: 'tomaíto',
  tomamos: 'tomamos',
  tomando: 'tomando',
  tomante: 'tomante',
  tomaram: 'tomaram',
  tomarao: 'tomarão',
  tomaras: 'tomaras',
  tomarei: 'tomarei',
  tomarem: 'tomarem',
  tomares: 'tomares',
  tomaria: 'tomaria',
  tomarmo: 'tomarmo',
  tomarto: 'tomarto',
  tomasse: 'tomasse',
  tomaste: 'tomaste',
  tomatal: 'tomatal',
  tomatas: 'tomatas',
  tomates: 'tomates',
  tomavam: 'tomavam',
  tomavas: 'tomavas',
  tombada: 'tombada',
  tombado: 'tombado',
  tombais: 'tombais',
  tombamo: 'tombamo',
  tombara: 'tombara',
  tombava: 'tombava',
  tombeai: 'tombeai',
  tombear: 'tombear',
  tombeei: 'tombeei',
  tombeia: 'tombeia',
  tombeie: 'tombeie',
  tombeio: 'tombeio',
  tombeis: 'tombeis',
  tombemo: 'tombemo',
  tombeou: 'tombeou',
  tomboes: 'tombões',
  tombola: 'tômbola',
  tombole: 'tombole',
  tombolo: 'tômbolo',
  tomboro: 'tômboro',
  tomelos: 'tomelos',
  tomemos: 'tomemos',
  tomense: 'tomense',
  tomento: 'tomento',
  tomicas: 'tômicas',
  tomicos: 'tômicos',
  tomilho: 'tomilho',
  tomismo: 'tomismo',
  tomisos: 'tomisos',
  tomista: 'tomista',
  tomitas: 'tomitas',
  tomoata: 'tomoatá',
  tomomio: 'tomômio',
  tomomis: 'tômomis',
  tomoros: 'tômoros',
  tonadao: 'tonadão',
  tonadas: 'tonadas',
  tonador: 'tonador',
  tonados: 'tonados',
  tonamos: 'tonamos',
  tonando: 'tonando',
  tonante: 'tonante',
  tonaram: 'tonaram',
  tonarao: 'tonarão',
  tonaras: 'tonarás',
  tonarei: 'tonarei',
  tonarem: 'tonarem',
  tonares: 'tonares',
  tonaria: 'tonaria',
  tonario: 'tonário',
  tonarmo: 'tonarmo',
  tonasse: 'tonasse',
  tonaste: 'tonaste',
  tonavam: 'tonavam',
  tonavas: 'tonavas',
  toncais: 'toncaís',
  toncica: 'tôncica',
  toncico: 'tôncico',
  toneira: 'toneira',
  toneiro: 'toneiro',
  tonemas: 'tonemas',
  tonemos: 'tonemos',
  toneres: 'tôneres',
  tonesia: 'tonésia',
  tongana: 'tongana',
  tongano: 'tongano',
  tonicas: 'tônicas',
  tonicos: 'tônicos',
  tonilho: 'tonilho',
  toninas: 'toninas',
  toninha: 'toninha',
  toninho: 'toninho',
  toninia: 'tonínia',
  tonismo: 'tonismo',
  tonista: 'tonista',
  tonites: 'tonites',
  tonizai: 'tonizai',
  tonizam: 'tonizam',
  tonizar: 'tonizar',
  tonizas: 'tonizas',
  tonizei: 'tonizei',
  tonizem: 'tonizem',
  tonizes: 'tonizes',
  tonizou: 'tonizou',
  tonoses: 'tonoses',
  tonquim: 'tonquim',
  tonsada: 'tonsada',
  tonsado: 'tonsado',
  tonsais: 'tonsais',
  tonsamo: 'tonsamo',
  tonsara: 'tonsará',
  tonsava: 'tonsava',
  tonseis: 'tonseis',
  tonsemo: 'tonsemo',
  tonsila: 'tonsila',
  tonsura: 'tonsura',
  tonsure: 'tonsure',
  tonsuro: 'tonsuro',
  tontada: 'tontada',
  tonteai: 'tonteai',
  tontear: 'tontear',
  tonteei: 'tonteei',
  tonteia: 'tonteia',
  tonteie: 'tonteie',
  tonteio: 'tonteio',
  tonteja: 'tontejá',
  tonteje: 'tonteje',
  tontejo: 'tontejo',
  tonteou: 'tonteou',
  tontice: 'tontice',
  tontina: 'tontina',
  tontino: 'tontino',
  tontoes: 'tontões',
  tontona: 'tontona',
  tontura: 'tontura',
  topadao: 'topadão',
  topadas: 'topadas',
  topador: 'topador',
  topados: 'topados',
  topamos: 'topamos',
  topando: 'topando',
  topante: 'topante',
  toparam: 'toparam',
  toparao: 'toparão',
  toparas: 'toparas',
  toparca: 'toparca',
  toparei: 'toparei',
  toparem: 'toparem',
  topares: 'topares',
  toparia: 'toparia',
  toparmo: 'toparmo',
  toparos: 'toparós',
  topasse: 'topasse',
  topaste: 'topaste',
  topavam: 'topavam',
  topavas: 'topavas',
  topazas: 'topazas',
  topazes: 'topazes',
  topazio: 'topázio',
  topeada: 'topeada',
  topeado: 'topeado',
  topeais: 'topeais',
  topeara: 'topeará',
  topeava: 'topeava',
  topeeis: 'topeeis',
  topeiam: 'topeiam',
  topeias: 'topeias',
  topeiem: 'topeiem',
  topeies: 'topeies',
  topejai: 'topejai',
  topejam: 'topejam',
  topejar: 'topejar',
  topejas: 'topejas',
  topejei: 'topejei',
  topejem: 'topejem',
  topejes: 'topejes',
  topejou: 'topejou',
  topemos: 'topemos',
  topense: 'topense',
  topetai: 'topetai',
  topetam: 'topetam',
  topetar: 'topetar',
  topetas: 'topetas',
  topetei: 'topetei',
  topetem: 'topetem',
  topetes: 'topetes',
  topetou: 'topetou',
  topiada: 'topiada',
  topiado: 'topiado',
  topiais: 'topiais',
  topiamo: 'topiamo',
  topiara: 'topiara',
  topiava: 'topiava',
  topical: 'topical',
  topicas: 'tópicas',
  topicos: 'tópicos',
  topieis: 'topieis',
  topiemo: 'topiemo',
  topinas: 'topinas',
  topinha: 'topinha',
  topinho: 'topinho',
  topinos: 'topinos',
  topista: 'topista',
  topitas: 'topitás',
  topless: 'topless',
  topobea: 'topóbea',
  topspin: 'topspin',
  toqueda: 'toquedá',
  toqueis: 'toqueis',
  toquelo: 'toquelô',
  toquemo: 'toquemo',
  toquila: 'toquila',
  toraces: 'toraces',
  toracos: 'tóracos',
  toradao: 'toradão',
  toradas: 'toradas',
  torador: 'torador',
  torados: 'torados',
  toragem: 'toragem',
  toralho: 'toralho',
  toramos: 'toramos',
  toranas: 'toranas',
  torando: 'torando',
  torania: 'torânia',
  toranja: 'toranja',
  torante: 'torante',
  toraram: 'toraram',
  torarao: 'torarão',
  toraras: 'torarás',
  torarei: 'torarei',
  torarem: 'torarem',
  torares: 'torares',
  toraria: 'toraria',
  torarmo: 'torarmo',
  torasse: 'torasse',
  toraste: 'toraste',
  toravam: 'toravam',
  toravas: 'toravas',
  torcada: 'torçada',
  torcado: 'torçado',
  torcais: 'torçais',
  torcamo: 'torçamo',
  torceai: 'torceai',
  torcear: 'torcear',
  torceei: 'torceei',
  torcega: 'torcegá',
  torcego: 'torcego',
  torceia: 'torceia',
  torceie: 'torceie',
  torceio: 'torceio',
  torceis: 'torceis',
  torcemo: 'torcemo',
  torceou: 'torceou',
  torcera: 'torcera',
  torciam: 'torciam',
  torcias: 'torcias',
  torcida: 'torcida',
  torcido: 'torcido',
  torcoes: 'torções',
  torcois: 'torçóis',
  torcona: 'torçona',
  torcula: 'torcula',
  torcule: 'torcule',
  torculo: 'tórculo',
  tordeia: 'tordeia',
  tordeio: 'tordeio',
  tordela: 'tordela',
  tordiao: 'tordião',
  torecta: 'torecta',
  toremos: 'toremos',
  torenas: 'torenas',
  torenga: 'torenga',
  torengo: 'torengo',
  torenia: 'torênia',
  toretas: 'tóretas',
  toretes: 'toretes',
  toreuma: 'toreuma',
  toreuta: 'toreuta',
  torgais: 'torgais',
  torgoes: 'torgões',
  torgote: 'torgote',
  toribio: 'toríbio',
  toricas: 'tóricas',
  toricos: 'tóricos',
  toricto: 'toricto',
  torilos: 'torilos',
  torinas: 'torinas',
  torinha: 'torinha',
  torinho: 'torinho',
  torinio: 'torínio',
  torinos: 'tórinos',
  torique: 'torique',
  toritas: 'toritas',
  torites: 'torites',
  toritos: 'toritos',
  tormina: 'tórmina',
  tornada: 'tornada',
  tornado: 'tornado',
  tornais: 'tornais',
  tornamo: 'tornamo',
  tornara: 'tornará',
  tornate: 'tornate',
  tornava: 'tornava',
  torneai: 'torneai',
  tornear: 'tornear',
  torneei: 'torneei',
  torneia: 'torneia',
  torneie: 'torneie',
  torneio: 'torneio',
  torneis: 'torneis',
  torneja: 'torneja',
  torneje: 'torneje',
  tornejo: 'tornejo',
  tornemo: 'tornemo',
  torneou: 'torneou',
  tornesa: 'tornesa',
  tornete: 'tornete',
  tornina: 'tornina',
  tornino: 'tornino',
  tornois: 'tornois',
  torofos: 'torofos',
  toroide: 'toroide',
  toronai: 'toronai',
  toronam: 'toronam',
  toronar: 'toronar',
  toronas: 'toronas',
  toronei: 'toronei',
  toronem: 'toronem',
  torones: 'torones',
  toroneu: 'toroneu',
  toronio: 'torônio',
  toronja: 'toronja',
  toronou: 'toronou',
  toropas: 'tóropas',
  tororos: 'tororós',
  torosas: 'torosas',
  torosos: 'torosos',
  torpeca: 'torpeça',
  torpece: 'torpece',
  torpeci: 'torpeci',
  torpeco: 'torpeço',
  torpeda: 'torpeda',
  torpede: 'torpede',
  torpedo: 'torpedo',
  torpeza: 'torpeza',
  torpida: 'tórpida',
  torpido: 'tórpido',
  torquaz: 'torquaz',
  torques: 'torquês',
  torquez: 'torquez',
  torrada: 'torrada',
  torrado: 'torrado',
  torrais: 'torrais',
  torramo: 'torramo',
  torrara: 'torrara',
  torrava: 'torrava',
  torreai: 'torreai',
  torreao: 'torreão',
  torrear: 'torrear',
  torreei: 'torreei',
  torreia: 'torreia',
  torreie: 'torreie',
  torreio: 'torreio',
  torreis: 'torreis',
  torreja: 'torreja',
  torreje: 'torreje',
  torrejo: 'torrejo',
  torrela: 'torrela',
  torremo: 'torremo',
  torreou: 'torreou',
  torresa: 'torresã',
  torreta: 'torreta',
  torrica: 'torrica',
  torrico: 'torrico',
  torrida: 'tórrida',
  torrido: 'tórrido',
  torrija: 'torrija',
  torroas: 'torroas',
  torroes: 'torrões',
  torrone: 'torrone',
  torrosa: 'torrosa',
  torroso: 'torroso',
  torsais: 'torsais',
  tortela: 'tortela',
  tortoes: 'tortões',
  tortora: 'tortora',
  tortosa: 'tortosa',
  tortrix: 'tórtrix',
  tortual: 'tortual',
  tortuda: 'tortuda',
  tortudo: 'tortudo',
  tortula: 'tórtula',
  tortuna: 'tortuna',
  tortuno: 'tortuno',
  tortura: 'tortura',
  torture: 'torture',
  torturo: 'torturo',
  torulas: 'tórulas',
  torulea: 'torúlea',
  torulos: 'tórulos',
  torumas: 'torumãs',
  torvada: 'torvada',
  torvado: 'torvado',
  torvais: 'torvais',
  torvamo: 'torvamo',
  torvara: 'torvara',
  torvate: 'torvate',
  torvava: 'torvava',
  torveis: 'torveis',
  torvemo: 'torvemo',
  tosadao: 'tosadão',
  tosadas: 'tosadas',
  tosador: 'tosador',
  tosados: 'tosados',
  tosalis: 'tósalis',
  tosamos: 'tosamos',
  tosando: 'tosando',
  tosante: 'tosante',
  tosaram: 'tosaram',
  tosarao: 'tosarão',
  tosaras: 'tosaras',
  tosarei: 'tosarei',
  tosarem: 'tosarem',
  tosares: 'tosares',
  tosaria: 'tosaria',
  tosarmo: 'tosarmo',
  tosasse: 'tosasse',
  tosaste: 'tosaste',
  tosavam: 'tosavam',
  tosavas: 'tosavas',
  toscada: 'toscada',
  toscado: 'toscado',
  toscais: 'toscais',
  toscamo: 'toscamo',
  toscana: 'toscana',
  toscano: 'toscano',
  toscara: 'toscara',
  toscava: 'toscava',
  toselas: 'toselas',
  tosemos: 'tosemos',
  tosilas: 'tosilas',
  tosquei: 'tosquei',
  tosquem: 'tosquem',
  tosques: 'tosques',
  tosquia: 'tosquia',
  tosquie: 'tosquie',
  tosquio: 'tosquio',
  tossega: 'tossegá',
  tossego: 'tossego',
  tossiam: 'tossiam',
  tossias: 'tossias',
  tossica: 'tossica',
  tossico: 'tóssico',
  tossida: 'tossida',
  tossido: 'tossido',
  tossiga: 'tossiga',
  tossigo: 'tossigo',
  tossila: 'tossila',
  tossimo: 'tossimo',
  tossira: 'tossira',
  tostada: 'tostada',
  tostado: 'tostado',
  tostais: 'tostais',
  tostamo: 'tostamo',
  tostara: 'tostara',
  tostava: 'tostava',
  tostega: 'tostegá',
  tostego: 'tostego',
  tosteis: 'tosteis',
  tostemo: 'tostemo',
  tostoes: 'tostões',
  tosulur: 'tosulur',
  totalux: 'totalux',
  totanas: 'totanas',
  totanga: 'totanga',
  totanos: 'totanos',
  totemes: 'tótemes',
  totoras: 'totoras',
  totumas: 'totumas',
  totumos: 'totumos',
  touaite: 'touaíte',
  toucada: 'toucada',
  toucado: 'toucado',
  toucais: 'toucais',
  toucamo: 'touçamo',
  toucara: 'toucara',
  toucava: 'toucava',
  touceis: 'touceis',
  toucemo: 'toucemo',
  toufoes: 'toufões',
  tougues: 'tougues',
  touquei: 'touquei',
  touquem: 'touquem',
  touques: 'touques',
  tourada: 'tourada',
  tourado: 'tourado',
  tourais: 'tourais',
  touramo: 'touramo',
  tourara: 'tourará',
  tourava: 'tourava',
  toureai: 'toureai',
  tourear: 'tourear',
  toureei: 'toureei',
  tourega: 'tourega',
  toureia: 'toureia',
  toureie: 'toureie',
  toureio: 'toureio',
  toureis: 'toureis',
  toureja: 'toureja',
  toureje: 'toureje',
  tourejo: 'tourejo',
  touremo: 'touremo',
  toureou: 'toureou',
  touriga: 'touriga',
  tourigo: 'tourigo',
  tournee: 'tournée',
  touroes: 'tourões',
  touruna: 'touruna',
  touruno: 'touruno',
  touteai: 'touteai',
  toutear: 'toutear',
  touteei: 'touteei',
  touteia: 'touteia',
  touteie: 'touteie',
  touteio: 'touteio',
  toutelo: 'toutelo',
  touteou: 'touteou',
  toutico: 'toutiço',
  tovacas: 'tovacas',
  tovaria: 'továria',
  toviada: 'toviada',
  toviado: 'toviado',
  toxemia: 'toxemia',
  toxicai: 'toxicai',
  toxicam: 'toxicam',
  toxicar: 'toxicar',
  toxicas: 'tóxicas',
  toxicos: 'tóxicos',
  toxicou: 'toxicou',
  toxidez: 'toxidez',
  toxinas: 'toxinas',
  toxique: 'toxique',
  toxogum: 'toxógum',
  toxoide: 'toxoide',
  toxonas: 'toxonas',
  toxotas: 'tóxotas',
  toxotes: 'tóxotes',
  toxotis: 'tóxotis',
  toxotos: 'tóxotos',
  toxuria: 'toxúria',
  trabais: 'trabais',
  trabeas: 'trábeas',
  trabela: 'trabela',
  trabola: 'trabola',
  trabota: 'trabota',
  trabuca: 'trabuca',
  trabuco: 'trabuco',
  trabuis: 'trabuis',
  trabula: 'trabula',
  trabule: 'trabule',
  trabulo: 'trabulo',
  tracada: 'traçada',
  tracado: 'traçado',
  tracais: 'traçais',
  tracaja: 'tracajá',
  tracamo: 'traçamo',
  tracana: 'traçana',
  tracara: 'traçara',
  tracava: 'traçava',
  traceis: 'traceis',
  traceja: 'traceja',
  traceje: 'traceje',
  tracejo: 'tracejo',
  tracemo: 'tracemo',
  tracias: 'trácias',
  tracica: 'trácica',
  tracico: 'trácico',
  tracios: 'trácios',
  tracoes: 'trações',
  tracoma: 'tracoma',
  tracona: 'traçona',
  tracuas: 'tracuás',
  tracuro: 'tracuro',
  tradada: 'tradada',
  tradado: 'tradado',
  tradais: 'tradais',
  tradamo: 'tradamo',
  tradara: 'tradará',
  tradava: 'tradava',
  tradeai: 'tradeai',
  tradear: 'tradear',
  tradeei: 'tradeei',
  tradeia: 'tradeia',
  tradeie: 'tradeie',
  tradeio: 'tradeio',
  tradeis: 'tradeis',
  tradela: 'tradela',
  trademo: 'trademo',
  tradeou: 'tradeou',
  tradita: 'trádita',
  tradito: 'trádito',
  traduza: 'traduza',
  traduze: 'traduze',
  traduzi: 'traduzi',
  traduzo: 'traduzo',
  trafega: 'trafega',
  trafego: 'tráfego',
  trafica: 'trafica',
  trafico: 'tráfico',
  tragada: 'tragada',
  tragado: 'tragado',
  tragais: 'tragais',
  tragamo: 'tragamo',
  tragana: 'tragana',
  tragano: 'tragano',
  tragara: 'tragara',
  tragava: 'tragava',
  tragias: 'trágias',
  tragica: 'trágica',
  tragico: 'trágico',
  tragina: 'tragina',
  tragine: 'tragine',
  tragino: 'tragino',
  tragios: 'trágios',
  tragopa: 'trágopa',
  traguas: 'traguás',
  traguea: 'tragueá',
  traguei: 'traguei',
  traguem: 'traguem',
  traguer: 'traguer',
  tragues: 'tragues',
  tragula: 'trágula',
  tragulo: 'trágulo',
  traiais: 'traiais',
  traiamo: 'traiamo',
  traicao: 'traição',
  traidao: 'traidão',
  traidas: 'traídas',
  traidor: 'traidor',
  traidos: 'traídos',
  traieis: 'traíeis',
  trailer: 'trailer',
  traimos: 'traímos',
  trainai: 'trainai',
  trainam: 'trainam',
  trainar: 'trainar',
  trainas: 'trainas',
  traindo: 'traindo',
  trainei: 'trainei',
  trainel: 'trainel',
  trainem: 'trainem',
  traines: 'traines',
  trainou: 'trainou',
  trairam: 'traíram',
  trairao: 'trairão',
  trairas: 'traíras',
  trairei: 'trairei',
  trairem: 'traírem',
  traires: 'traíres',
  trairia: 'trairia',
  trairmo: 'trairmo',
  traisse: 'traísse',
  traiste: 'traíste',
  traites: 'traites',
  traiucu: 'traiuçu',
  trajada: 'trajada',
  trajado: 'trajado',
  trajais: 'trajais',
  trajamo: 'trajamo',
  trajara: 'trajara',
  trajava: 'trajava',
  trajeis: 'trajeis',
  trajemo: 'trajemo',
  trajeto: 'trajeto',
  tralada: 'traladá',
  tralade: 'tralade',
  tralado: 'tralado',
  tralala: 'tralalá',
  tralhai: 'tralhai',
  tralham: 'tralham',
  tralhao: 'tralhão',
  tralhar: 'tralhar',
  tralhas: 'tralhas',
  tralhei: 'tralhei',
  tralhem: 'tralhem',
  tralhes: 'tralhes',
  tralhou: 'tralhou',
  tramada: 'tramada',
  tramado: 'tramado',
  tramaga: 'tramaga',
  tramais: 'tramais',
  tramamo: 'tramamo',
  tramara: 'tramara',
  tramava: 'tramava',
  trameis: 'trameis',
  tramela: 'tramela',
  tramele: 'tramele',
  tramelo: 'tramelo',
  tramemo: 'tramemo',
  trameta: 'trameta',
  tramete: 'tramete',
  trameti: 'trameti',
  trameto: 'trameto',
  tramita: 'tramita',
  tramite: 'trâmite',
  tramito: 'tramito',
  tramoco: 'tramoço',
  tramoia: 'tramóia',
  tramosa: 'tramosa',
  tramoso: 'tramoso',
  trampao: 'trampão',
  trampar: 'trampar',
  trampas: 'trampas',
  trampea: 'trampeá',
  trampos: 'trampos',
  tramuei: 'trâmuei',
  tramway: 'tramway',
  tranada: 'tranada',
  tranado: 'tranado',
  tranais: 'tranais',
  tranamo: 'tranamo',
  tranara: 'tranará',
  tranava: 'tranava',
  trancai: 'trancai',
  trancam: 'trancam',
  trancao: 'trancão',
  trancar: 'trancar',
  trancas: 'tranças',
  trancei: 'trancei',
  trancem: 'trancem',
  trances: 'trances',
  trancha: 'tranchã',
  tranche: 'tranche',
  trancho: 'trancho',
  trancos: 'trancos',
  trancou: 'trancou',
  traneis: 'traneis',
  tranemo: 'tranemo',
  trangla: 'trangla',
  trangos: 'trangos',
  tranita: 'tranita',
  tranoca: 'tranoca',
  tranoco: 'tranoco',
  tranque: 'tranque',
  transai: 'transai',
  transam: 'transam',
  transar: 'transar',
  transas: 'transas',
  transei: 'transei',
  transem: 'transem',
  transes: 'transes',
  transia: 'transia',
  transir: 'transir',
  transis: 'transis',
  transiu: 'transiu',
  transou: 'transou',
  transpo: 'transpô',
  tranvia: 'tranvia',
  trapaca: 'trapaça',
  trapace: 'trapace',
  trapaco: 'trapaço',
  trapada: 'trapada',
  trapala: 'trápala',
  trapeai: 'trapeai',
  trapear: 'trapear',
  trapeei: 'trapeei',
  trapeia: 'trapeia',
  trapeie: 'trapeie',
  trapeio: 'trapeio',
  trapeis: 'trapéis',
  trapeja: 'trapeja',
  trapeje: 'trapeje',
  trapejo: 'trapejo',
  trapeou: 'trapeou',
  trapias: 'trapiás',
  trapios: 'trapios',
  trapita: 'trapita',
  trapite: 'trapite',
  trapola: 'trápola',
  trapuca: 'trapuca',
  traquas: 'traquás',
  traquea: 'tráquea',
  traques: 'traques',
  traquis: 'tráquis',
  trareis: 'trareis',
  traremo: 'traremo',
  trariam: 'trariam',
  trarias: 'trarias',
  trasdos: 'trasdós',
  trasgas: 'trasgas',
  trasgos: 'trasgos',
  traslar: 'traslar',
  trasluz: 'trasluz',
  traspes: 'traspés',
  traspor: 'traspor',
  trassai: 'trassai',
  trassam: 'trassam',
  trassar: 'trassar',
  trassas: 'trassas',
  trassei: 'trassei',
  trassem: 'trassem',
  trasses: 'trasses',
  trassou: 'trassou',
  trastea: 'trasteá',
  trastes: 'trastes',
  trastos: 'trastos',
  trasvia: 'trasvia',
  trasvie: 'trasvie',
  trasvio: 'trasvio',
  tratada: 'tratada',
  tratado: 'tratado',
  tratais: 'tratais',
  tratamo: 'tratamo',
  tratara: 'tratara',
  tratava: 'tratava',
  trateai: 'trateai',
  tratear: 'tratear',
  trateei: 'trateei',
  trateia: 'trateia',
  trateie: 'trateie',
  trateio: 'trateio',
  trateis: 'trateis',
  tratemo: 'tratemo',
  trateou: 'trateou',
  trativo: 'trativo',
  tratora: 'tratora',
  tratore: 'tratore',
  tratoro: 'tratoro',
  tratriz: 'tratriz',
  trauira: 'trauira',
  traumas: 'traumas',
  trausas: 'trausas',
  trausos: 'trausos',
  trautai: 'trautai',
  trautam: 'trautam',
  trautar: 'trautar',
  trautas: 'trautas',
  trautea: 'trauteá',
  trautei: 'trautei',
  trautem: 'trautem',
  trautes: 'trautes',
  trautou: 'trautou',
  travada: 'travada',
  travado: 'travado',
  travais: 'travais',
  travamo: 'travamo',
  travara: 'travara',
  travava: 'travava',
  traveis: 'traveis',
  traveja: 'traveja',
  traveje: 'traveje',
  travejo: 'travejo',
  travela: 'travela',
  travemo: 'travemo',
  travesa: 'travesa',
  travese: 'travese',
  traveso: 'traveso',
  traveta: 'traveta',
  travial: 'travial',
  travias: 'travias',
  travoes: 'travões',
  travosa: 'travosa',
  travoso: 'travoso',
  travota: 'travota',
  trazeis: 'trazeis',
  trazemo: 'trazemo',
  traziam: 'traziam',
  trazias: 'trazias',
  trazida: 'trazida',
  trazido: 'trazido',
  trazios: 'trázios',
  trealas: 'trealas',
  trebana: 'trebana',
  trebano: 'trebano',
  trebios: 'trébios',
  treboai: 'treboai',
  treboam: 'treboam',
  treboar: 'treboar',
  treboas: 'treboas',
  trebocu: 'treboçu',
  treboei: 'treboei',
  treboem: 'treboem',
  treboes: 'treboes',
  trebois: 'trebois',
  trebola: 'trebola',
  treboou: 'treboou',
  trebulo: 'trebulo',
  trechos: 'trechos',
  trecois: 'treçóis',
  trecula: 'trécula',
  tredice: 'tredice',
  trefega: 'trêfega',
  trefego: 'trêfego',
  trefila: 'trefila',
  trefile: 'trefile',
  trefilo: 'trefilo',
  trefina: 'trefina',
  trefine: 'trefine',
  trefino: 'trefino',
  treguas: 'tréguas',
  treicao: 'treição',
  treicia: 'treícia',
  treicio: 'treício',
  treinai: 'treinai',
  treinam: 'treinam',
  treinar: 'treinar',
  treinas: 'treinas',
  treinei: 'treinei',
  treinem: 'treinem',
  treines: 'treines',
  treinos: 'treinos',
  treinou: 'treinou',
  treitai: 'treitai',
  treitam: 'treitam',
  treitar: 'treitar',
  treitas: 'treitas',
  treitei: 'treitei',
  treitem: 'treitem',
  treites: 'treites',
  treitos: 'treitos',
  treitou: 'treitou',
  trejuga: 'trejugá',
  trejugo: 'trejugo',
  trejura: 'trejura',
  trejure: 'trejure',
  trejuro: 'trejuro',
  trelada: 'trelada',
  trelade: 'trelade',
  trelado: 'trelado',
  trelais: 'trelais',
  treleai: 'treleai',
  trelear: 'trelear',
  treleei: 'treleei',
  treleia: 'treleia',
  treleie: 'treleie',
  treleio: 'treleio',
  treleis: 'treleis',
  trelemo: 'trelemo',
  treleou: 'treleou',
  trelera: 'trelerá',
  trelhos: 'trelhos',
  treliam: 'treliam',
  trelias: 'trelias',
  trelica: 'treliça',
  trelice: 'trelice',
  trelico: 'treliço',
  trelida: 'trelida',
  trelido: 'trelido',
  trelosa: 'trelosa',
  treloso: 'treloso',
  tremada: 'tremada',
  tremado: 'tremado',
  tremais: 'tremais',
  tremamo: 'tremamo',
  tremara: 'tremará',
  tremate: 'tremate',
  tremava: 'tremava',
  tremeis: 'tremeis',
  tremela: 'tremela',
  tremele: 'tremele',
  tremelo: 'tremelo',
  trememo: 'trememo',
  tremens: 'tremens',
  tremera: 'tremera',
  tremesa: 'tremesa',
  tremeta: 'tremeta',
  tremete: 'tremete',
  tremeti: 'tremeti',
  tremeto: 'tremeto',
  tremiam: 'tremiam',
  tremias: 'tremias',
  tremida: 'tremida',
  tremido: 'tremido',
  tremito: 'trêmito',
  tremoca: 'tremoça',
  tremoce: 'tremoce',
  tremoco: 'tremoço',
  tremoes: 'tremões',
  tremoia: 'tremoia',
  tremolo: 'tremolo',
  trempem: 'trempem',
  trempes: 'trempes',
  tremuda: 'tremudá',
  tremude: 'tremude',
  tremudo: 'tremudo',
  tremula: 'tremula',
  tremule: 'tremule',
  tremulo: 'trêmulo',
  tremura: 'tremura',
  trenada: 'trenada',
  trenado: 'trenado',
  trenais: 'trenais',
  trenamo: 'trenamo',
  trenara: 'trenará',
  trenava: 'trenava',
  trencas: 'trenças',
  treneis: 'treneis',
  trenemo: 'trenemo',
  trenete: 'trenete',
  trengos: 'trengos',
  trenlas: 'trenlas',
  trenode: 'trenode',
  treoses: 'treoses',
  trepada: 'trepada',
  trepado: 'trepado',
  trepais: 'trepais',
  trepamo: 'trepamo',
  trepana: 'trepana',
  trepane: 'trepane',
  trepano: 'trépano',
  trepara: 'trepara',
  trepava: 'trepava',
  trepeco: 'trepeço',
  trepeis: 'trepeis',
  trepemo: 'trepemo',
  trepica: 'trepica',
  trepico: 'trepico',
  trepida: 'trepida',
  trepide: 'trepide',
  trepido: 'trépido',
  treplos: 'treplos',
  trepola: 'trepola',
  trerons: 'trérons',
  tresavo: 'tresavô',
  tresfia: 'tresfiá',
  tresfie: 'tresfie',
  tresfio: 'tresfio',
  tresias: 'tresias',
  tresler: 'tresler',
  tresles: 'treslês',
  tresleu: 'tresleu',
  treslia: 'treslia',
  trespor: 'trespor',
  tresses: 'tresses',
  tressis: 'tréssis',
  tressua: 'tressua',
  tressue: 'tressue',
  tressuo: 'tressuo',
  tresvam: 'tresvam',
  tresvas: 'tresvas',
  tresvei: 'tresvei',
  tresvem: 'tresvem',
  tresver: 'tresver',
  tresves: 'tresves',
  tresveu: 'tresveu',
  tresvia: 'tresvia',
  tresvie: 'tresvie',
  tresvio: 'tresvio',
  treteai: 'treteai',
  tretear: 'tretear',
  treteei: 'treteei',
  treteia: 'treteia',
  treteie: 'treteie',
  treteio: 'treteio',
  treteou: 'treteou',
  trevada: 'trevada',
  trevado: 'trevado',
  trevais: 'trevais',
  trevelo: 'trevelô',
  trevera: 'trévera',
  trevero: 'trévero',
  trevias: 'trévias',
  trevina: 'trevina',
  trevira: 'trévira',
  treviro: 'tréviro',
  trevite: 'trevite',
  trevoas: 'trévoas',
  trevosa: 'trevosa',
  trevoso: 'trevoso',
  trezena: 'trezena',
  trezeno: 'trezeno',
  triacas: 'triacas',
  triacis: 'tríacis',
  triadao: 'triadão',
  triadas: 'triadas',
  triades: 'tríades',
  triador: 'triador',
  triados: 'triados',
  triagas: 'triagas',
  triagem: 'triagem',
  triales: 'triales',
  trialto: 'trialto',
  triamos: 'triamos',
  trianas: 'trianas',
  triando: 'triando',
  trianea: 'triânea',
  trianos: 'trianos',
  trianta: 'trianta',
  triante: 'triante',
  trianto: 'trianto',
  triaram: 'triaram',
  triarao: 'triarão',
  triaras: 'triaras',
  triarca: 'triarca',
  triarco: 'triarco',
  triarei: 'triarei',
  triarem: 'triarem',
  triares: 'triares',
  triaria: 'triaria',
  triario: 'triário',
  triarmo: 'triarmo',
  triasia: 'triásia',
  triasio: 'triásio',
  triaspe: 'triaspe',
  triasse: 'triasse',
  triaste: 'triaste',
  triatlo: 'triatlo',
  triavam: 'triavam',
  triavas: 'triavas',
  triazol: 'triazol',
  tribada: 'tríbada',
  tribade: 'tríbade',
  tribado: 'tríbado',
  tribais: 'tribais',
  tribala: 'tribala',
  tribalo: 'tribalo',
  tribase: 'tribase',
  tribica: 'tribica',
  tribico: 'tribico',
  triboca: 'triboca',
  triboco: 'triboco',
  tribofa: 'tribofa',
  tribofe: 'tribofe',
  tribofo: 'tribofo',
  tribucu: 'tribuçu',
  tribufe: 'tribufe',
  tribufu: 'tribufu',
  tribuir: 'tribuir',
  tribuis: 'tribuis',
  tribula: 'tribula',
  tribule: 'tribule',
  tribulo: 'tríbulo',
  tribuna: 'tribuna',
  tribuno: 'tribuno',
  tributa: 'tributa',
  tribute: 'tribute',
  tributo: 'tributo',
  tricama: 'tricama',
  tricana: 'tricana',
  triceps: 'tríceps',
  tricera: 'trícera',
  tricero: 'trícero',
  tricias: 'trícias',
  tricios: 'trícios',
  tricles: 'tricles',
  tricoca: 'tricoca',
  tricoco: 'tricoco',
  tricola: 'tricola',
  tricolo: 'tricolo',
  tricoma: 'tricoma',
  tricomo: 'tricomo',
  tricone: 'tricone',
  tricopo: 'trícopo',
  tricosa: 'tricosa',
  tricose: 'tricose',
  tricoso: 'tricoso',
  tricota: 'tricota',
  tricote: 'tricote',
  tricoto: 'tricoto',
  tricuro: 'tricuro',
  tridias: 'tridias',
  tridimo: 'trídimo',
  triduos: 'tríduos',
  triecas: 'triecas',
  triecia: 'triecia',
  triecos: 'triecos',
  triedro: 'triedro',
  trielco: 'trielco',
  triemos: 'triemos',
  trienal: 'trienal',
  trienio: 'triênio',
  triense: 'triense',
  triente: 'triente',
  trieras: 'trieras',
  trieres: 'trieres',
  trietil: 'trietil',
  trietis: 'trietis',
  trifaca: 'trifaca',
  triface: 'triface',
  trifana: 'trifana',
  trifano: 'trífano',
  trifena: 'trifena',
  trifera: 'trífera',
  trifero: 'trífero',
  trifeta: 'trifeta',
  trifida: 'trífida',
  trifido: 'trífido',
  trifila: 'trifila',
  trifilo: 'trifilo',
  trifora: 'trífora',
  triforo: 'tríforo',
  trifosa: 'trifosa',
  trifusa: 'trifusa',
  trigada: 'trigada',
  trigado: 'trigado',
  trigaes: 'trigães',
  trigais: 'trigais',
  trigama: 'trígama',
  trigamo: 'trígamo',
  trigara: 'trigará',
  trigava: 'trigava',
  trigeas: 'trígeas',
  trigeos: 'trígeos',
  trigina: 'trígina',
  trigino: 'trígino',
  triglas: 'triglas',
  triglos: 'triglos',
  trigode: 'trigode',
  trigona: 'trigona',
  trigone: 'trígone',
  trigono: 'trígono',
  trigons: 'trígons',
  trigosa: 'trigosa',
  trigoso: 'trigoso',
  triguei: 'triguei',
  triguem: 'triguem',
  trigues: 'trigues',
  trilabo: 'trílabo',
  trilada: 'trilada',
  trilado: 'trilado',
  trilais: 'trilais',
  trilamo: 'trilamo',
  trilara: 'trilara',
  trilava: 'trilava',
  trileis: 'trileis',
  trilema: 'trilema',
  trilemo: 'trilemo',
  trilhai: 'trilhai',
  trilham: 'trilham',
  trilhao: 'trilhão',
  trilhar: 'trilhar',
  trilhas: 'trilhas',
  trilhei: 'trilhei',
  trilhem: 'trilhem',
  trilhes: 'trilhes',
  trilhos: 'trilhos',
  trilhou: 'trilhou',
  triliao: 'trilião',
  trilice: 'trilice',
  trilina: 'trilina',
  trilios: 'trílios',
  trilisa: 'trilisa',
  trilita: 'trilita',
  trilite: 'trilite',
  trilito: 'trílito',
  trilobo: 'trílobo',
  trilofo: 'trílofo',
  trilogo: 'trílogo',
  trilupa: 'trilupa',
  trimara: 'trimarã',
  trimera: 'trímera',
  trimere: 'trímere',
  trimero: 'trímero',
  trimeza: 'trimeza',
  trimios: 'trímios',
  trimoda: 'trímoda',
  trimodo: 'trímodo',
  trinace: 'trínace',
  trinada: 'trinada',
  trinado: 'trinado',
  trinais: 'trinais',
  trinamo: 'trinamo',
  trinara: 'trinara',
  trinava: 'trinava',
  trincai: 'trincai',
  trincal: 'trincal',
  trincam: 'trincam',
  trincar: 'trincar',
  trincas: 'trincas',
  trincha: 'trincha',
  trinche: 'trinche',
  trincho: 'trincho',
  trincia: 'tríncia',
  trincos: 'trincos',
  trincou: 'trincou',
  trineis: 'trineis',
  trinema: 'trinema',
  trinemo: 'trinemo',
  trineta: 'trineta',
  trineto: 'trineto',
  trinfai: 'trinfai',
  trinfam: 'trinfam',
  trinfar: 'trinfar',
  trinfas: 'trinfas',
  trinfei: 'trinfei',
  trinfem: 'trinfem',
  trinfes: 'trinfes',
  trinfou: 'trinfou',
  tringas: 'tringas',
  triniam: 'triniam',
  trinias: 'trinias',
  trinida: 'trinida',
  trinido: 'trinido',
  trinimo: 'trinimo',
  trinios: 'trínios',
  trinira: 'trinirá',
  trinode: 'trinode',
  trinoto: 'trinoto',
  trinque: 'trinque',
  trintai: 'trintai',
  trintam: 'trintam',
  trintao: 'trintão',
  trintar: 'trintar',
  trintas: 'trintas',
  trintei: 'trintei',
  trintem: 'trintem',
  trintes: 'trintes',
  trintou: 'trintou',
  trinula: 'trinulá',
  trinule: 'trinule',
  trinulo: 'trínulo',
  triodia: 'triódia',
  triodio: 'triódio',
  triodon: 'tríodon',
  triodos: 'triodos',
  trioica: 'trioica',
  trioico: 'trioico',
  trioles: 'triolés',
  trional: 'trional',
  trionix: 'tríonix',
  triopas: 'tríopas',
  triopes: 'triopes',
  trioses: 'trioses',
  triosga: 'triosga',
  tripada: 'tripada',
  tripado: 'tripado',
  tripais: 'tripais',
  tripamo: 'tripamo',
  tripara: 'trípara',
  triparo: 'tríparo',
  tripava: 'tripava',
  tripeca: 'tripeça',
  tripeco: 'tripeço',
  tripeis: 'tripeis',
  tripemo: 'tripemo',
  tripeta: 'tripeta',
  tripila: 'trípila',
  tripilo: 'trípilo',
  triplas: 'triplas',
  triplax: 'triplax',
  triples: 'triples',
  triplex: 'triplex',
  triplos: 'triplos',
  tripoda: 'trípoda',
  tripode: 'trípode',
  tripodo: 'trípodo',
  tripole: 'trípole',
  tripoli: 'trípoli',
  tripolo: 'trípolo',
  tripses: 'tripses',
  tripsia: 'tripsia',
  tripsis: 'tripsis',
  tripton: 'trípton',
  tripula: 'tripula',
  tripule: 'tripule',
  tripulo: 'tripulo',
  triques: 'triques',
  triquia: 'tríquia',
  triquio: 'tríquio',
  triquis: 'tríquis',
  trireme: 'trireme',
  trisada: 'trisada',
  trisado: 'trisado',
  trisais: 'trisais',
  trisamo: 'trisamo',
  trisara: 'trisara',
  trisava: 'trisava',
  trisavo: 'trisavó',
  triscai: 'triscai',
  triscam: 'triscam',
  triscar: 'triscar',
  triscas: 'triscas',
  triscou: 'triscou',
  triseis: 'triseis',
  trisemo: 'trisemo',
  triseto: 'triseto',
  trismos: 'trismos',
  trisnas: 'trisnas',
  trisque: 'trisque',
  trissai: 'trissai',
  trissal: 'trissal',
  trissam: 'trissam',
  trissar: 'trissar',
  trissas: 'trissas',
  trissei: 'trissei',
  trissem: 'trissem',
  trisses: 'trisses',
  trissou: 'trissou',
  tristao: 'tristão',
  tristas: 'tristas',
  tristes: 'tristes',
  tristia: 'trístia',
  tristor: 'tristor',
  tritada: 'tritada',
  tritado: 'tritado',
  tritais: 'tritais',
  tritamo: 'tritamo',
  tritara: 'tritará',
  tritava: 'tritava',
  triteis: 'triteis',
  tritemo: 'tritemo',
  triteta: 'triteta',
  tritiao: 'tritião',
  tritias: 'trítias',
  tritica: 'trítica',
  tritico: 'trítico',
  tritilo: 'tritilo',
  tritina: 'tritina',
  tritine: 'tritine',
  tritino: 'tritino',
  tritios: 'trítios',
  tritoes: 'tritões',
  tritois: 'tritois',
  tritoma: 'trítoma',
  tritono: 'trítono',
  tritons: 'tritons',
  tritura: 'tritura',
  triture: 'triture',
  trituro: 'trituro',
  triunfa: 'triunfa',
  triunfe: 'triunfe',
  triunfo: 'triunfo',
  triuras: 'triúras',
  triuris: 'triúris',
  triusas: 'triúsas',
  triusos: 'triúsos',
  trivato: 'trivato',
  trivela: 'trivela',
  trivial: 'trivial',
  trivias: 'trívias',
  trivios: 'trívios',
  trivoli: 'trivoli',
  trizias: 'trízias',
  troadao: 'troadão',
  troadas: 'troadas',
  troador: 'troador',
  troados: 'troados',
  troamos: 'troamos',
  troando: 'troando',
  troante: 'troante',
  troaram: 'troaram',
  troarao: 'troarão',
  troaras: 'troarás',
  troarei: 'troarei',
  troarem: 'troarem',
  troares: 'troares',
  troaria: 'troaria',
  troarmo: 'troarmo',
  troasse: 'troasse',
  troaste: 'troaste',
  troavam: 'troavam',
  troavas: 'troavas',
  trocada: 'trocada',
  trocado: 'trocado',
  trocais: 'trocais',
  trocamo: 'trocamo',
  trocana: 'trocana',
  trocano: 'trocano',
  trocara: 'trocara',
  trocate: 'trocate',
  trocava: 'trocava',
  troceis: 'troceis',
  trocemo: 'trocemo',
  trochai: 'trochai',
  trocham: 'trocham',
  trochar: 'trochar',
  trochas: 'trochas',
  trochei: 'trochei',
  trochem: 'trochem',
  troches: 'troches',
  trochou: 'trochou',
  troclea: 'tróclea',
  trocmas: 'trocmas',
  trocmos: 'trocmos',
  trocopo: 'trócopo',
  troctes: 'troctes',
  troctos: 'troctos',
  troemos: 'troemos',
  trofeus: 'troféus',
  trofias: 'trofias',
  trofica: 'trófica',
  trofico: 'trófico',
  trofide: 'trófide',
  trofons: 'trófons',
  trogias: 'trogias',
  trogina: 'trogina',
  trogino: 'trogino',
  trogios: 'trógios',
  trogmas: 'trogmas',
  trogmos: 'trogmos',
  trogono: 'trógono',
  trogons: 'trógons',
  trogulo: 'trógulo',
  troiada: 'troiada',
  troiado: 'troiado',
  troiais: 'troiais',
  troiana: 'troiana',
  troiano: 'troiano',
  troiara: 'troiará',
  troiava: 'troiava',
  troicas: 'troicas',
  troicos: 'troicos',
  troieis: 'troieis',
  troiles: 'troiles',
  troilos: 'troilos',
  troioes: 'troiões',
  troiras: 'troiras',
  troixas: 'troixas',
  troixel: 'troixel',
  troixos: 'troixos',
  trolada: 'trolada',
  trolado: 'trolado',
  trolaro: 'trolaró',
  troleis: 'tróleis',
  trolhas: 'trolhas',
  trolhos: 'trolhos',
  trolins: 'trolins',
  trolios: 'trólios',
  trololo: 'trololó',
  trolzao: 'trolzão',
  trombai: 'trombai',
  trombam: 'trombam',
  trombao: 'trombão',
  trombar: 'trombar',
  trombas: 'trombas',
  trombei: 'trombei',
  trombem: 'trombem',
  trombes: 'trombes',
  trombil: 'trombil',
  trombis: 'trombis',
  trombos: 'trombos',
  trombou: 'trombou',
  tromila: 'tromila',
  trompai: 'trompai',
  trompam: 'trompam',
  trompao: 'trompão',
  trompar: 'trompar',
  trompas: 'trompas',
  trompea: 'trompeá',
  trompei: 'trompei',
  trompem: 'trompem',
  trompes: 'trompes',
  trompim: 'trompim',
  trompou: 'trompou',
  tronada: 'tronada',
  tronado: 'tronado',
  tronais: 'tronais',
  tronamo: 'tronamo',
  tronara: 'tronará',
  tronava: 'tronava',
  troncai: 'troncai',
  troncal: 'troncal',
  troncam: 'troncam',
  troncao: 'troncão',
  troncar: 'troncar',
  troncas: 'troncas',
  troncha: 'troncha',
  tronche: 'tronche',
  troncho: 'troncho',
  troncos: 'troncos',
  troncou: 'troncou',
  trondao: 'trondão',
  trondas: 'trondas',
  troneai: 'troneai',
  tronear: 'tronear',
  troneei: 'troneei',
  troneia: 'troneia',
  troneie: 'troneie',
  troneio: 'troneio',
  troneis: 'troneis',
  troneja: 'troneja',
  troneje: 'troneje',
  tronejo: 'tronejo',
  tronemo: 'tronemo',
  troneou: 'troneou',
  troneto: 'troneto',
  trongas: 'trongas',
  tronios: 'trônios',
  tronque: 'tronque',
  tropada: 'tropada',
  tropado: 'tropado',
  tropais: 'tropais',
  tropamo: 'tropamo',
  tropana: 'tropana',
  tropano: 'tropano',
  tropara: 'tropara',
  tropato: 'tropato',
  tropava: 'tropava',
  tropeai: 'tropeai',
  tropear: 'tropear',
  tropeca: 'tropeça',
  tropece: 'tropece',
  tropeco: 'tropeço',
  tropeei: 'tropeei',
  tropega: 'trôpega',
  tropego: 'trôpego',
  tropeia: 'tropeia',
  tropeie: 'tropeie',
  tropeio: 'tropeio',
  tropeis: 'tropéis',
  tropeja: 'tropejá',
  tropeje: 'tropeje',
  tropejo: 'tropejo',
  tropela: 'tropelã',
  tropemo: 'tropemo',
  tropeno: 'tropeno',
  tropeou: 'tropeou',
  tropias: 'tropias',
  tropica: 'tropica',
  tropico: 'trópico',
  tropido: 'tropido',
  tropina: 'tropina',
  tropita: 'tropita',
  tropoes: 'tropões',
  tropona: 'tropona',
  troquei: 'troquei',
  troquel: 'troquel',
  troquem: 'troquem',
  troques: 'troques',
  troqueu: 'troqueu',
  troquio: 'tróquio',
  trospio: 'tróspio',
  trotada: 'trotada',
  trotado: 'trotado',
  trotais: 'trotais',
  trotamo: 'trotamo',
  trotara: 'trotara',
  trotava: 'trotava',
  troteai: 'troteai',
  trotear: 'trotear',
  troteei: 'troteei',
  troteia: 'troteia',
  troteie: 'troteie',
  troteio: 'troteio',
  troteis: 'troteis',
  troteja: 'trotejá',
  troteje: 'troteje',
  trotejo: 'trotejo',
  trotemo: 'trotemo',
  troteou: 'troteou',
  trotina: 'trotina',
  trotine: 'trotine',
  trotino: 'trotino',
  trotoes: 'trotões',
  trotona: 'trotona',
  trotski: 'trotski',
  troucai: 'trouçai',
  troucam: 'trouçam',
  troucar: 'trouçar',
  troucas: 'trouças',
  troucei: 'troucei',
  troucem: 'troucem',
  trouces: 'trouces',
  troucou: 'trouçou',
  trousse: 'trousse',
  trouxas: 'trouxas',
  trouxel: 'trouxel',
  trouxer: 'trouxer',
  trouxos: 'trouxos',
  trovada: 'trovada',
  trovado: 'trovado',
  trovais: 'trovais',
  trovamo: 'trovamo',
  trovara: 'trovará',
  trovava: 'trovava',
  troveis: 'troveis',
  troveja: 'troveja',
  troveje: 'troveje',
  trovejo: 'trovejo',
  trovemo: 'trovemo',
  trovoai: 'trovoai',
  trovoam: 'trovoam',
  trovoar: 'trovoar',
  trovoas: 'trovoas',
  trovoei: 'trovoei',
  trovoem: 'trovoem',
  trovoes: 'trovões',
  trovoou: 'trovoou',
  trovosa: 'trovosa',
  trovoso: 'trovoso',
  truacas: 'truacas',
  truanaz: 'truanaz',
  truanea: 'truaneá',
  truania: 'truania',
  truaria: 'truaria',
  trubufu: 'trubufu',
  trucada: 'trucada',
  trucado: 'trucado',
  trucais: 'trucais',
  trucamo: 'trucamo',
  trucara: 'trucará',
  trucava: 'trucava',
  truchas: 'truchas',
  trucida: 'trucida',
  trucide: 'trucide',
  trucido: 'trucido',
  trucila: 'trucila',
  trucile: 'trucile',
  trucilo: 'trucilo',
  trucuas: 'trucuás',
  trufada: 'trufada',
  trufado: 'trufado',
  trufais: 'trufais',
  trufamo: 'trufamo',
  trufara: 'trufará',
  trufava: 'trufava',
  trufeis: 'trufeis',
  trufemo: 'trufemo',
  truismo: 'truísmo',
  truista: 'truísta',
  truitas: 'truitas',
  trumais: 'trumaís',
  truncai: 'truncai',
  truncam: 'truncam',
  truncar: 'truncar',
  truncas: 'truncas',
  truncha: 'truncha',
  truncou: 'truncou',
  trunfai: 'trunfai',
  trunfam: 'trunfam',
  trunfar: 'trunfar',
  trunfas: 'trunfas',
  trunfei: 'trunfei',
  trunfem: 'trunfem',
  trunfes: 'trunfes',
  trunfos: 'trunfos',
  trunfou: 'trunfou',
  trunque: 'trunque',
  trupada: 'trupada',
  trupado: 'trupado',
  trupais: 'trupais',
  trupamo: 'trupamo',
  trupara: 'trupará',
  trupava: 'trupava',
  trupeis: 'trupeis',
  trupemo: 'trupemo',
  trupial: 'trupial',
  trupiam: 'trupiam',
  trupias: 'trupias',
  trupida: 'trupida',
  trupido: 'trupido',
  trupira: 'trupirá',
  trupita: 'trupitá',
  trupite: 'trupite',
  trupito: 'trupito',
  truquei: 'truquei',
  truquem: 'truquem',
  truques: 'truques',
  trussas: 'trussas',
  trustea: 'trusteá',
  trustes: 'trustes',
  trutina: 'trutina',
  trutine: 'trutine',
  trutino: 'trutino',
  truxalo: 'trúxalo',
  truxona: 'truxona',
  tsarina: 'tsarina',
  tsongas: 'tsongas',
  tsunami: 'tsunami',
  tuaiucu: 'tuaiuçu',
  tuamina: 'tuamina',
  tuapoca: 'tuapoca',
  tuatara: 'tuatara',
  tuatuas: 'tuatuas',
  tuaucus: 'tuauçus',
  tubabos: 'tubabos',
  tubacao: 'tubação',
  tubacas: 'tubacas',
  tubacea: 'tubácea',
  tubaceo: 'tubáceo',
  tubadao: 'tubadão',
  tubadas: 'tubadas',
  tubador: 'tubador',
  tubados: 'tubados',
  tubagem: 'tubagem',
  tubaina: 'tubaína',
  tubamos: 'tubamos',
  tubanas: 'tubanas',
  tubanca: 'tubança',
  tubando: 'tubando',
  tubanha: 'tubanha',
  tubante: 'tubante',
  tubaram: 'tubaram',
  tubarao: 'tubarão',
  tubaras: 'tubaras',
  tubarei: 'tubarei',
  tubarem: 'tubarem',
  tubares: 'tubares',
  tubaria: 'tubária',
  tubario: 'tubário',
  tubarmo: 'tubarmo',
  tubaros: 'túbaros',
  tubasse: 'tubasse',
  tubaste: 'tubaste',
  tubavam: 'tubavam',
  tubavas: 'tubavas',
  tubeira: 'tubeira',
  tubemos: 'tubemos',
  tuberal: 'tuberal',
  tuberas: 'túberas',
  tuberea: 'tubérea',
  tuberia: 'tubéria',
  tuberos: 'túberos',
  tuberta: 'tuberta',
  tubetes: 'tubetes',
  tubiana: 'tubiana',
  tubiano: 'tubiano',
  tubibas: 'tubibas',
  tubifex: 'túbifex',
  tubilho: 'tubilho',
  tubinar: 'tubinar',
  tubinha: 'tubinha',
  tubinho: 'tubinho',
  tubique: 'tubiqué',
  tubista: 'tubista',
  tubulao: 'tubulão',
  tubular: 'tubular',
  tubulos: 'túbulos',
  tubunas: 'tubunas',
  tucaias: 'tucaiás',
  tucaira: 'tucaíra',
  tucajes: 'tucajés',
  tucanai: 'tucanai',
  tucanam: 'tucanam',
  tucanar: 'tucanar',
  tucanas: 'tucanas',
  tucanei: 'tucanei',
  tucanem: 'tucanem',
  tucanes: 'tucanes',
  tucanis: 'tucanis',
  tucanos: 'tucanos',
  tucanou: 'tucanou',
  tucanui: 'tucanuí',
  tucaris: 'tucaris',
  tuchada: 'tuchada',
  tuchado: 'tuchado',
  tucuari: 'tucuari',
  tucujas: 'tucujás',
  tucujus: 'tucujus',
  tucumai: 'tucumãí',
  tucumas: 'tucumãs',
  tucunai: 'tucunaí',
  tucunas: 'tucunas',
  tucupas: 'tucupás',
  tucupim: 'tucupim',
  tucupis: 'tucupis',
  tucurao: 'tucurão',
  tucuras: 'tucuras',
  tucuris: 'tucuris',
  tucurus: 'tucurus',
  tucuxis: 'tucuxis',
  tudacas: 'tudacas',
  tudense: 'tudense',
  tuderte: 'tuderte',
  tudesca: 'tudesca',
  tudesco: 'tudesco',
  tudicla: 'tudicla',
  tudinha: 'tudinha',
  tudinho: 'tudinho',
  tudista: 'tudista',
  tuenita: 'tuenita',
  tuenite: 'tuenite',
  tuenito: 'tuenito',
  tuesita: 'tuesita',
  tuesite: 'tuesite',
  tufacia: 'tufácia',
  tufacio: 'tufácio',
  tufadao: 'tufadão',
  tufadas: 'tufadas',
  tufador: 'tufador',
  tufados: 'tufados',
  tufaita: 'tufaíta',
  tufamos: 'tufamos',
  tufando: 'tufando',
  tufante: 'tufante',
  tufaram: 'tufaram',
  tufarao: 'tufarão',
  tufaras: 'tufarás',
  tufarei: 'tufarei',
  tufarem: 'tufarem',
  tufares: 'tufares',
  tufaria: 'tufaria',
  tufarmo: 'tufarmo',
  tufasse: 'tufasse',
  tufaste: 'tufaste',
  tufavam: 'tufavam',
  tufavas: 'tufavas',
  tufavel: 'tufável',
  tufemos: 'tufemos',
  tufnois: 'tufnois',
  tufosas: 'tufosas',
  tufosos: 'tufosos',
  tugidao: 'tugidão',
  tugidas: 'tugidas',
  tugidor: 'tugidor',
  tugidos: 'tugidos',
  tugieis: 'tugíeis',
  tugimos: 'tugimos',
  tugindo: 'tugindo',
  tugiram: 'tugiram',
  tugirao: 'tugirão',
  tugiras: 'tugirás',
  tugirei: 'tugirei',
  tugirem: 'tugirem',
  tugires: 'tugires',
  tugiria: 'tugiria',
  tugisse: 'tugisse',
  tugiste: 'tugiste',
  tugonia: 'tugônia',
  tugurio: 'tugúrio',
  tuiaica: 'tuiaíca',
  tuicoes: 'tuições',
  tuicona: 'tuiçona',
  tuidara: 'tuidara',
  tuietes: 'tuietês',
  tuijuba: 'tuijuba',
  tuijuva: 'tuijuva',
  tuindas: 'tuindás',
  tuinins: 'tuinins',
  tuionas: 'tuionas',
  tuipara: 'tuipara',
  tuiroca: 'tuiroca',
  tuitada: 'tuitada',
  tuitado: 'tuitado',
  tuitais: 'tuitais',
  tuitara: 'tuitará',
  tuitava: 'tuitava',
  tuiteis: 'tuiteis',
  tuitiri: 'tuitiri',
  tuitiva: 'tuitiva',
  tuitivo: 'tuitivo',
  tuiucas: 'tuiucas',
  tuiuius: 'tuiuiús',
  tuiutis: 'tuiutis',
  tuiuvas: 'tuiuvas',
  tujamos: 'tujamos',
  tujanas: 'tujanas',
  tujanos: 'tujanos',
  tujenos: 'tujenos',
  tujonas: 'tujonas',
  tujubas: 'tujubas',
  tujucal: 'tujucal',
  tujucas: 'tujucas',
  tujucos: 'tujucos',
  tujujus: 'tujujus',
  tujupar: 'tujupar',
  tujupis: 'tujupis',
  tujuvas: 'tujuvas',
  tuliana: 'tuliana',
  tuliano: 'tuliano',
  tulinga: 'tulinga',
  tulingo: 'tulingo',
  tulipao: 'tulipão',
  tulipas: 'tulipas',
  tulipea: 'tulípea',
  tulitas: 'tulitas',
  tulites: 'tulites',
  tulones: 'tulonês',
  tulpais: 'tulpais',
  tulupas: 'tulupas',
  tuluvas: 'túluvas',
  tumbada: 'tumbada',
  tumbado: 'tumbado',
  tumbaga: 'tumbaga',
  tumbais: 'tumbais',
  tumbamo: 'tumbamo',
  tumbara: 'tumbará',
  tumbava: 'tumbava',
  tumbeis: 'tumbeis',
  tumbemo: 'tumbemo',
  tumbice: 'tumbice',
  tumbira: 'tumbira',
  tumecam: 'tumeçam',
  tumecas: 'tumeças',
  tumecei: 'tumecei',
  tumecem: 'tumecem',
  tumecer: 'tumecer',
  tumeces: 'tumeces',
  tumeceu: 'tumeceu',
  tumecia: 'tumecia',
  tumefaz: 'tumefaz',
  tumefez: 'tumefez',
  tumefiz: 'tumefiz',
  tumenol: 'tumenol',
  tumente: 'tumente',
  tumesca: 'tumesça',
  tumesce: 'tumesce',
  tumesci: 'tumesci',
  tumesco: 'tumesço',
  tumidas: 'túmidas',
  tumidez: 'tumidez',
  tumidos: 'túmidos',
  tumitas: 'tumitas',
  tumoral: 'tumoral',
  tumores: 'tumores',
  tumulai: 'tumulai',
  tumulam: 'tumulam',
  tumular: 'tumular',
  tumulas: 'tumulas',
  tumulei: 'tumulei',
  tumulem: 'tumulem',
  tumules: 'tumules',
  tumulos: 'túmulos',
  tumulou: 'tumulou',
  tumulto: 'tumulto',
  tumuras: 'tumurás',
  tunadao: 'tunadão',
  tunadas: 'tunadas',
  tunador: 'tunador',
  tunados: 'tunados',
  tunagem: 'tunagem',
  tunamos: 'tunamos',
  tunando: 'tunando',
  tunante: 'tunante',
  tunaram: 'tunaram',
  tunarao: 'tunarão',
  tunaras: 'tunarás',
  tunarei: 'tunarei',
  tunarem: 'tunarem',
  tunares: 'tunares',
  tunaria: 'tunária',
  tunarmo: 'tunarmo',
  tunasse: 'tunasse',
  tunaste: 'tunaste',
  tunavam: 'tunavam',
  tunavas: 'tunavas',
  tuncuns: 'tuncuns',
  tundada: 'tundada',
  tundado: 'tundado',
  tundais: 'tundais',
  tundamo: 'tundamo',
  tundara: 'tundara',
  tundava: 'tundava',
  tundeis: 'tundeis',
  tundemo: 'tundemo',
  tundras: 'tundras',
  tunemos: 'tunemos',
  tunense: 'tunense',
  tunesco: 'tunesco',
  tungada: 'tungada',
  tungado: 'tungado',
  tungais: 'tungais',
  tungara: 'tungara',
  tungava: 'tungava',
  tungoes: 'tungões',
  tungose: 'tungose',
  tungras: 'tungras',
  tungros: 'tungros',
  tunguea: 'tungueá',
  tunguei: 'tunguei',
  tunguem: 'tunguem',
  tungues: 'tungues',
  tungula: 'tungula',
  tunguse: 'tunguse',
  tunicas: 'túnicas',
  tunidea: 'tunídea',
  tunideo: 'tunídeo',
  tunisil: 'tunisil',
  tunisis: 'tunisis',
  tupaias: 'tupaias',
  tupaipi: 'tupaipi',
  tupamos: 'tupamos',
  tuparis: 'tuparis',
  tupeias: 'tupeias',
  tupenea: 'tupeneá',
  tupetis: 'tupetis',
  tupiana: 'tupiana',
  tupiano: 'tupiano',
  tupicas: 'túpicas',
  tupicos: 'túpicos',
  tupidas: 'tupidas',
  tupidor: 'tupidor',
  tupidos: 'tupidos',
  tupieis: 'tupíeis',
  tupigua: 'tupiguá',
  tupimos: 'tupimos',
  tupinae: 'tupinaê',
  tupinai: 'tupinaí',
  tupinas: 'tupinás',
  tupindo: 'tupindo',
  tupiram: 'tupiram',
  tupirao: 'tupirão',
  tupiras: 'tupirás',
  tupirei: 'tupirei',
  tupirem: 'tupirem',
  tupires: 'tupires',
  tupiria: 'tupiria',
  tupisse: 'tupisse',
  tupiste: 'tupiste',
  tupixas: 'tupixás',
  tuponia: 'tupônia',
  tuputas: 'tuputás',
  tuquira: 'tuquira',
  turacos: 'turacos',
  turadao: 'turadão',
  turadas: 'turadas',
  turador: 'turador',
  turados: 'turados',
  turaias: 'turaias',
  turamos: 'turamos',
  turanda: 'turanda',
  turando: 'turando',
  turania: 'turânia',
  turanio: 'turânio',
  turante: 'turante',
  turaram: 'turaram',
  turarao: 'turarão',
  turaras: 'turarás',
  turarei: 'turarei',
  turarem: 'turarem',
  turares: 'turares',
  turaria: 'turária',
  turario: 'turário',
  turaris: 'turaris',
  turarmo: 'turarmo',
  turasse: 'turasse',
  turaste: 'turaste',
  turavam: 'turavam',
  turavas: 'turavas',
  turbada: 'turbada',
  turbado: 'turbado',
  turbais: 'turbais',
  turbamo: 'turbamo',
  turbara: 'turbara',
  turbava: 'turbava',
  turbeis: 'turbeis',
  turbela: 'turbela',
  turbemo: 'turbemo',
  turbida: 'túrbida',
  turbido: 'túrbido',
  turbina: 'turbina',
  turbine: 'turbine',
  turbino: 'turbino',
  turbita: 'turbita',
  turbite: 'turbite',
  turbito: 'turbito',
  turboes: 'turbões',
  turbosa: 'turbosa',
  turboso: 'turboso',
  turcada: 'turcada',
  turcica: 'túrcica',
  turcico: 'túrcico',
  turciza: 'turcizá',
  turcize: 'turcize',
  turcizo: 'turcizo',
  turcois: 'turcois',
  turdino: 'turdino',
  turdula: 'túrdula',
  turdulo: 'túrdulo',
  turebas: 'turebas',
  turejos: 'turejos',
  turemos: 'turemos',
  turfita: 'turfita',
  turfite: 'turfite',
  turfois: 'turfóis',
  turfosa: 'turfosa',
  turfoso: 'turfoso',
  turgiam: 'turgiam',
  turgias: 'turgias',
  turgica: 'túrgica',
  turgico: 'túrgico',
  turgida: 'túrgida',
  turgido: 'túrgido',
  turgira: 'turgirá',
  turgita: 'turgita',
  turgite: 'turgite',
  turgito: 'turgito',
  turiate: 'turiate',
  turicas: 'turicas',
  turicos: 'túricos',
  turimua: 'turimua',
  turinas: 'turinas',
  turines: 'turinês',
  turinga: 'turinga',
  turingo: 'turingo',
  turinos: 'turinos',
  turioes: 'turiões',
  turiris: 'turiris',
  turismo: 'turismo',
  turista: 'turista',
  turiuas: 'turiúas',
  turiuva: 'turiúva',
  turjais: 'turjais',
  turmada: 'turmada',
  turmado: 'turmado',
  turmais: 'turmais',
  turmamo: 'turmamo',
  turmara: 'turmará',
  turmava: 'turmava',
  turmeis: 'turmeis',
  turmemo: 'turmemo',
  turmena: 'turmena',
  turmeno: 'turmeno',
  turnedo: 'turnedô',
  turnepo: 'túrnepo',
  turnera: 'turnera',
  turnias: 'túrnias',
  turnice: 'túrnice',
  turonas: 'túronas',
  turones: 'túrones',
  turonia: 'turônia',
  turonio: 'turônio',
  turonos: 'túronos',
  turpesa: 'turpesa',
  turpida: 'túrpida',
  turpido: 'túrpido',
  turques: 'turquês',
  turquim: 'turquim',
  turquis: 'turquis',
  turrada: 'turrada',
  turrado: 'turrado',
  turrais: 'turrais',
  turramo: 'turramo',
  turrara: 'turrará',
  turrava: 'turrava',
  turreas: 'túrreas',
  turreia: 'turreia',
  turreis: 'turreis',
  turremo: 'turremo',
  turrias: 'túrrias',
  turrila: 'turrila',
  turrios: 'túrrios',
  turrite: 'turrite',
  turroes: 'turrões',
  turrona: 'turrona',
  turubas: 'turubas',
  turubis: 'turubis',
  turucue: 'turucué',
  turucus: 'turuçus',
  turueis: 'turueís',
  turulia: 'turúlia',
  turumas: 'turumãs',
  turunas: 'turunas',
  turundu: 'turundu',
  tururie: 'tururié',
  tururim: 'tururim',
  tururis: 'tururis',
  tururus: 'tururus',
  turusas: 'turusás',
  turvada: 'turvada',
  turvado: 'turvado',
  turvais: 'turvais',
  turvamo: 'turvamo',
  turvara: 'turvara',
  turvava: 'turvava',
  turveis: 'turveis',
  turveja: 'turveja',
  turveje: 'turveje',
  turvejo: 'turvejo',
  turvemo: 'turvemo',
  tuscana: 'tuscana',
  tuscano: 'tuscano',
  tusebio: 'tusébio',
  tusques: 'tusques',
  tussais: 'tussais',
  tussamo: 'tussamo',
  tussois: 'tussois',
  tustios: 'tústios',
  tutadao: 'tutadão',
  tutadas: 'tutadas',
  tutador: 'tutador',
  tutados: 'tutados',
  tutamos: 'tutamos',
  tutando: 'tutando',
  tutania: 'tutânia',
  tutanos: 'tutanos',
  tutante: 'tutante',
  tutaram: 'tutaram',
  tutarao: 'tutarão',
  tutaras: 'tutaras',
  tutarei: 'tutarei',
  tutarem: 'tutarem',
  tutares: 'tutares',
  tutaria: 'tutaria',
  tutarmo: 'tutarmo',
  tutasse: 'tutasse',
  tutaste: 'tutaste',
  tutavam: 'tutavam',
  tutavas: 'tutavas',
  tuteada: 'tuteada',
  tuteado: 'tuteado',
  tuteais: 'tuteais',
  tuteara: 'tuteará',
  tuteava: 'tuteava',
  tuteeis: 'tuteeis',
  tuteiam: 'tuteiam',
  tuteias: 'tuteias',
  tuteiem: 'tuteiem',
  tuteies: 'tuteies',
  tutejai: 'tutejai',
  tutejam: 'tutejam',
  tutejar: 'tutejar',
  tutejas: 'tutejas',
  tutejei: 'tutejei',
  tutejem: 'tutejem',
  tutejes: 'tutejes',
  tutejou: 'tutejou',
  tutelai: 'tutelai',
  tutelam: 'tutelam',
  tutelar: 'tutelar',
  tutelas: 'tutelas',
  tutelei: 'tutelei',
  tutelem: 'tutelem',
  tuteles: 'tuteles',
  tutelou: 'tutelou',
  tutemos: 'tutemos',
  tutiada: 'tutiada',
  tutiado: 'tutiado',
  tutiais: 'tutiais',
  tutiamo: 'tutiamo',
  tutiara: 'tutiara',
  tutiava: 'tutiava',
  tutieis: 'tutieis',
  tutiemo: 'tutiemo',
  tutinas: 'tutinas',
  tutinha: 'tutinha',
  tutinos: 'tutinos',
  tutorai: 'tutorai',
  tutoram: 'tutoram',
  tutorar: 'tutorar',
  tutoras: 'tutoras',
  tutorea: 'tutoreá',
  tutorei: 'tutorei',
  tutorem: 'tutorem',
  tutores: 'tutores',
  tutoria: 'tutoria',
  tutorio: 'tutório',
  tutorou: 'tutorou',
  tutucai: 'tutucai',
  tutucam: 'tutucam',
  tutucar: 'tutucar',
  tutucas: 'tutucas',
  tutucou: 'tutucou',
  tutulos: 'tútulos',
  tutuque: 'tutuque',
  tutuvea: 'tutuveá',
  tutuvia: 'tutuviá',
  tutuvie: 'tutuvie',
  tutuvio: 'tutuvio',
  tutuzao: 'tutuzão',
  tuvales: 'tuvalês',
  tuvinos: 'tuvinos',
  tuviras: 'tuviras',
  tuvucue: 'tuvucué',
  tuvunas: 'tuvunas',
  tuxauas: 'tuxauas',
  tuxavas: 'tuxavas',
  tuxinau: 'tuxinau',
  tuzinas: 'tuzinas',
  tweeter: 'tweeter',
  tzarina: 'tzarina',
  tzigana: 'tzigana',
  tzigano: 'tzigano',
  tàpisso: 'tàpisso',
  uaabita: 'uaabita',
  uabaias: 'uabaías',
  uabaina: 'uabaína',
  uabaios: 'uabaios',
  uacacus: 'uaçaçus',
  uacamba: 'uacamba',
  uacanga: 'uacanga',
  uacapus: 'uacapus',
  uacaras: 'uacarás',
  uacarau: 'uacarau',
  uacarua: 'uacaruá',
  uacauas: 'uacauãs',
  uacimas: 'uacimas',
  uaconas: 'uaconás',
  uacuara: 'uacuará',
  uacumas: 'uacumãs',
  uacumos: 'uacumos',
  uacurau: 'uacurau',
  uadadas: 'uadadás',
  uadicas: 'uádicas',
  uadicos: 'uádicos',
  uaeiras: 'uaeiras',
  uaganda: 'uaganda',
  uagenia: 'uagenia',
  uagogos: 'uagogos',
  uaianas: 'uaianas',
  uaiandi: 'uaiandi',
  uaiapes: 'uaiapés',
  uaibama: 'uaibama',
  uaicana: 'uaícana',
  uaicima: 'uaicima',
  uaicues: 'uaicués',
  uaieira: 'uaieira',
  uaieues: 'uaieués',
  uaimani: 'uaimani',
  uaimiri: 'uaimiri',
  uainuma: 'uainumá',
  uaioros: 'uaiorós',
  uairari: 'uairari',
  uaitaca: 'uaitacá',
  uaiumas: 'uaiumás',
  uaiupes: 'uaiupés',
  uaiurus: 'uaiurus',
  uaixima: 'uaixima',
  uajaras: 'uajarás',
  uajerus: 'uajerus',
  uajurus: 'uajurus',
  ualabas: 'ualabás',
  ualalas: 'ualalas',
  ualongo: 'ualongo',
  uamamis: 'uamamis',
  uambiza: 'uambiza',
  uambuti: 'uambuti',
  uamirim: 'uamirim',
  uanambe: 'uanambé',
  uananas: 'uananas',
  uananau: 'uananau',
  uananim: 'uananim',
  uanapos: 'uanapos',
  uanauau: 'uanauau',
  uanderu: 'uanderu',
  uanhais: 'uanhais',
  uanicas: 'uanicas',
  uanioro: 'uanioro',
  uanjila: 'uanjila',
  uantuia: 'uantuia',
  uapacas: 'uapacas',
  uapucas: 'uapuçás',
  uapuins: 'uapuins',
  uaracus: 'uaracus',
  uaraicu: 'uaraicu',
  uaranas: 'uaranás',
  uaranga: 'uarangá',
  uarapas: 'uarapás',
  uararas: 'uararas',
  uarerui: 'uarerui',
  uariuas: 'uariuás',
  uarixis: 'uarixis',
  uaruara: 'uaruará',
  uarubes: 'uarubés',
  uarumas: 'uarumãs',
  uaruura: 'uaruurá',
  uasenas: 'uasenas',
  uatapis: 'uatapis',
  uatapus: 'uatapus',
  uatitas: 'uatitas',
  uatites: 'uatites',
  uatitos: 'uatitos',
  uatotos: 'uatotós',
  uaturas: 'uaturás',
  uatusis: 'uatúsis',
  uatutsi: 'uatútsi',
  uauacus: 'uauaçus',
  uauiras: 'uauiras',
  uauirus: 'uauirus',
  uavaona: 'uavaona',
  uaxupes: 'uaxupés',
  ubaacus: 'ubaaçus',
  ubacaba: 'ubacaba',
  ubacaia: 'ubacaia',
  ubaense: 'ubaense',
  ubaetes: 'ubaetés',
  ubaibas: 'ubaibás',
  ubainas: 'ubaínas',
  ubandgi: 'ubândgi',
  ubangos: 'ubangos',
  ubapeba: 'ubapeba',
  ubaraba: 'ubaraba',
  ubarana: 'ubarana',
  ubatins: 'ubatins',
  ubatoes: 'ubatões',
  ubegues: 'ubeguês',
  uberada: 'uberada',
  uberado: 'uberado',
  uberais: 'uberais',
  uberamo: 'uberamo',
  uberana: 'uberana',
  uberara: 'uberara',
  uberava: 'uberava',
  ubereis: 'ubereis',
  uberemo: 'uberemo',
  uberina: 'uberina',
  uberino: 'uberino',
  uberosa: 'uberosa',
  uberoso: 'uberoso',
  ubiacus: 'ubiaçus',
  ubiculo: 'ubículo',
  ubijara: 'ubijara',
  ubiquai: 'ubiquai',
  ubiquam: 'ubíquam',
  ubiquar: 'ubiquar',
  ubiquas: 'ubíquas',
  ubiquei: 'ubiquei',
  ubiquem: 'ubíquem',
  ubiques: 'ubíques',
  ubiquos: 'ubíquos',
  ubiquou: 'ubiquou',
  ubirana: 'ubirana',
  ubirata: 'ubiratã',
  ubiscas: 'ubiscas',
  ubiscos: 'ubiscos',
  ubixuma: 'ubixuma',
  ubochea: 'ubochea',
  ubojara: 'ubojara',
  uboquea: 'ubóquea',
  ubucaba: 'ubucaba',
  ubucual: 'ubuçual',
  ubuesca: 'ubuesca',
  ubuesco: 'ubuesco',
  uburana: 'uburana',
  ucacias: 'ucaciás',
  ucaiali: 'ucaiali',
  ucamari: 'ucamari',
  ucanhas: 'ucanhas',
  ucasses: 'ucasses',
  ucharia: 'ucharia',
  uchivas: 'uchivas',
  ucraina: 'ucraína',
  ucraino: 'ucraíno',
  ucranio: 'ucrânio',
  ucriana: 'ucriana',
  ucronia: 'ucronia',
  ucuissi: 'ucuíssi',
  ucumbas: 'ucumbas',
  ucuquis: 'ucuquis',
  ucussos: 'ucussos',
  ucuubas: 'ucuubas',
  udamina: 'udamina',
  udecras: 'udecrás',
  udgatri: 'udgatri',
  udiiana: 'udiiana',
  udmurte: 'udmurte',
  udonela: 'udonela',
  udoteas: 'udóteas',
  udungas: 'udungas',
  ueranas: 'ueranas',
  uerimas: 'uerimás',
  uetacas: 'uetacás',
  ufanada: 'ufanada',
  ufanado: 'ufanado',
  ufanais: 'ufanais',
  ufanamo: 'ufanamo',
  ufanara: 'ufanara',
  ufanava: 'ufanava',
  ufaneai: 'ufaneai',
  ufanear: 'ufanear',
  ufaneei: 'ufaneei',
  ufaneia: 'ufaneia',
  ufaneie: 'ufaneie',
  ufaneio: 'ufaneio',
  ufaneis: 'ufaneis',
  ufanemo: 'ufanemo',
  ufaneou: 'ufaneou',
  ufanias: 'ufanias',
  ufanosa: 'ufanosa',
  ufanoso: 'ufanoso',
  ufarsim: 'ufarsim',
  ufologa: 'ufóloga',
  ufologo: 'ufólogo',
  ufomana: 'ufômana',
  ufomano: 'ufômano',
  ugadoes: 'ugadões',
  ugadona: 'ugadona',
  ugadora: 'ugadora',
  ugalhai: 'ugalhai',
  ugalham: 'ugalham',
  ugalhar: 'ugalhar',
  ugalhas: 'ugalhas',
  ugalhei: 'ugalhei',
  ugalhem: 'ugalhem',
  ugalhes: 'ugalhes',
  ugalhos: 'ugalhos',
  ugalhou: 'ugalhou',
  ugandes: 'ugandês',
  ugantes: 'ugantes',
  ugardes: 'ugardes',
  ugareis: 'ugareis',
  ugariam: 'ugariam',
  ugarias: 'ugarias',
  ugarmos: 'ugarmos',
  ugassem: 'ugassem',
  ugasses: 'ugasses',
  ugastes: 'ugastes',
  ugaveis: 'ugáveis',
  ugerbao: 'ugerbão',
  ugerbos: 'ugerbos',
  ugernas: 'ugernas',
  ugernos: 'ugernos',
  ugolina: 'ugolina',
  ugolino: 'ugolino',
  ugriana: 'ugriana',
  ugriano: 'ugriano',
  ugricas: 'úgricas',
  ugricos: 'úgricos',
  uguemos: 'uguemos',
  uiabuis: 'uiabuís',
  uiacima: 'uiacima',
  uiaeira: 'uiaeira',
  uianari: 'uianari',
  uianuri: 'uianuri',
  uicungo: 'uicungo',
  uigitas: 'uigitas',
  uigures: 'uigures',
  uintato: 'uintato',
  uiofobo: 'uiófobo',
  uiracus: 'uiraçus',
  uiranas: 'uiranas',
  uiraris: 'uiraris',
  uirauna: 'uiraúna',
  uiraxue: 'uiraxué',
  uirinas: 'uirinas',
  uiriris: 'uiriris',
  uiscada: 'uiscada',
  uisques: 'uísques',
  uistiti: 'uistiti',
  uitotas: 'uitotas',
  uitotos: 'uitotos',
  uivacao: 'uivação',
  uivadao: 'uivadão',
  uivadas: 'uivadas',
  uivador: 'uivador',
  uivados: 'uivados',
  uivamos: 'uivamos',
  uivando: 'uivando',
  uivante: 'uivante',
  uivaram: 'uivaram',
  uivarao: 'uivarão',
  uivaras: 'uivaras',
  uivarei: 'uivarei',
  uivarem: 'uivarem',
  uivares: 'uivares',
  uivaria: 'uivaria',
  uivarmo: 'uivarmo',
  uivasse: 'uivasse',
  uivaste: 'uivaste',
  uivavam: 'uivavam',
  uivavas: 'uivavas',
  uivemos: 'uivemos',
  uivinho: 'uivinho',
  ulactis: 'uláctis',
  ulalgia: 'ulalgia',
  ulangia: 'ulângia',
  ulcerai: 'ulcerai',
  ulceram: 'ulceram',
  ulcerar: 'ulcerar',
  ulceras: 'úlceras',
  ulcerei: 'ulcerei',
  ulcerem: 'ulcerem',
  ulceres: 'ulceres',
  ulcerou: 'ulcerou',
  ulcoide: 'ulcoide',
  uleanto: 'uleanto',
  ulearos: 'ulearos',
  uleiota: 'uleiota',
  ulembas: 'ulembas',
  uleotas: 'uleotas',
  ulesina: 'ulesina',
  uletica: 'ulética',
  uletico: 'ulético',
  ulexina: 'ulexina',
  ulexita: 'ulexita',
  ulexite: 'ulexite',
  ulexito: 'ulexito',
  ulidias: 'ulídias',
  uliense: 'uliense',
  ulisseu: 'ulisseu',
  ulmacea: 'ulmácea',
  ulmaceo: 'ulmáceo',
  ulmaria: 'ulmária',
  ulmaris: 'ulmáris',
  ulmatos: 'ulmatos',
  ulmeira: 'ulmeira',
  ulmeiro: 'ulmeiro',
  ulmicas: 'úlmicas',
  ulmicos: 'úlmicos',
  ulminas: 'ulminas',
  ulnares: 'ulnares',
  ulnaria: 'ulnária',
  ulnario: 'ulnário',
  uloboro: 'ulóboro',
  ulocace: 'ulocace',
  ulocola: 'ulocola',
  uloncia: 'uloncia',
  uloncos: 'uloncos',
  ulosomo: 'ulosomo',
  ultimai: 'ultimai',
  ultimam: 'ultimam',
  ultimar: 'ultimar',
  ultimas: 'últimas',
  ultimei: 'ultimei',
  ultimem: 'ultimem',
  ultimes: 'ultimes',
  ultimos: 'últimos',
  ultimou: 'ultimou',
  ultoras: 'ultoras',
  ultores: 'ultores',
  ultoria: 'ultória',
  ultorio: 'ultório',
  ultraja: 'ultraja',
  ultraje: 'ultraje',
  ultrajo: 'ultrajo',
  ultrama: 'ultrama',
  ultrame: 'ultrame',
  ultramo: 'ultramo',
  ultrice: 'ultrice',
  ululada: 'ululada',
  ululado: 'ululado',
  ululais: 'ululais',
  ululamo: 'ululamo',
  ululara: 'ululara',
  ululato: 'ululato',
  ululava: 'ululava',
  ululeis: 'ululeis',
  ululemo: 'ululemo',
  ulvacea: 'ulvácea',
  ulvaceo: 'ulváceo',
  ulvinas: 'ulvinas',
  ulvitas: 'ulvitas',
  umacoes: 'umações',
  umacona: 'umaçona',
  umaozao: 'umãozão',
  umazona: 'umãzona',
  umbaiba: 'umbaíba',
  umbalas: 'umbalas',
  umbamba: 'umbamba',
  umbanda: 'umbanda',
  umbares: 'umbarés',
  umbarus: 'umbarus',
  umbauas: 'umbauas',
  umbauba: 'umbaúba',
  umbelal: 'umbelal',
  umbelas: 'umbelas',
  umbelol: 'umbelol',
  umbigos: 'umbigos',
  umbitza: 'umbitzã',
  umblina: 'umblina',
  umbolas: 'umbolas',
  umbonal: 'umbonal',
  umbonia: 'umbônia',
  umbonio: 'umbônio',
  umbrais: 'umbrais',
  umbrela: 'umbrela',
  umbreta: 'umbreta',
  umbrias: 'umbrias',
  umbrica: 'úmbrica',
  umbrico: 'úmbrico',
  umbrina: 'umbrina',
  umbrino: 'umbrino',
  umbrios: 'úmbrios',
  umbrita: 'umbrita',
  umbroes: 'umbrões',
  umbrosa: 'umbrosa',
  umbroso: 'umbroso',
  umbuias: 'umbuias',
  umbulas: 'umbulas',
  umbunda: 'umbunda',
  umbundo: 'umbundo',
  umbuzal: 'umbuzal',
  umectai: 'umectai',
  umectam: 'umectam',
  umectar: 'umectar',
  umectas: 'umectas',
  umectei: 'umectei',
  umectem: 'umectem',
  umectes: 'umectes',
  umectou: 'umectou',
  umedeca: 'umedeça',
  umedece: 'umedece',
  umedeci: 'umedeci',
  umedeco: 'umedeço',
  umerais: 'umerais',
  umidade: 'umidade',
  umotina: 'umotina',
  umpadas: 'umpadas',
  umpanzo: 'umpanzo',
  umpeque: 'umpeque',
  umulucu: 'umulucu',
  umutina: 'umutina',
  unacopo: 'unacopo',
  unadoes: 'unadões',
  unadona: 'unadona',
  unadora: 'unadora',
  unanima: 'unanima',
  unanime: 'unânime',
  unanimo: 'unanimo',
  unantes: 'unantes',
  unardes: 'unardes',
  unareis: 'unáreis',
  unaremo: 'unaremo',
  unariam: 'unariam',
  unarias: 'unárias',
  unarios: 'unários',
  unarmos: 'unarmos',
  unaspis: 'unáspis',
  unassem: 'unassem',
  unasses: 'unasses',
  unastes: 'unastes',
  unaveis: 'unáveis',
  uncaria: 'uncária',
  unciais: 'unciais',
  uncinea: 'uncínea',
  uncineo: 'uncíneo',
  uncinia: 'uncínia',
  unciola: 'uncíola',
  uncitos: 'uncitos',
  uncoche: 'uncoche',
  unconas: 'unçonas',
  undacao: 'undação',
  undadao: 'undadão',
  undadas: 'undadas',
  undador: 'undador',
  undados: 'undados',
  undalos: 'undalos',
  undamos: 'undamos',
  undando: 'undando',
  undante: 'undante',
  undaram: 'undaram',
  undarao: 'undarão',
  undaras: 'undarás',
  undarei: 'undarei',
  undarem: 'undarem',
  undares: 'undares',
  undaria: 'undaria',
  undarmo: 'undarmo',
  undasse: 'undasse',
  undaste: 'undaste',
  undavam: 'undavam',
  undavas: 'undavas',
  undeira: 'undeira',
  undembe: 'undembe',
  undemos: 'undemos',
  undinas: 'undinas',
  undosas: 'undosas',
  undosos: 'undosos',
  undulai: 'undulai',
  undulam: 'undulam',
  undular: 'undular',
  undulas: 'undulas',
  undulei: 'undulei',
  undulem: 'undulem',
  undules: 'undules',
  undulou: 'undulou',
  unelcos: 'unelcos',
  unenses: 'unenses',
  unescos: 'unescos',
  ungaita: 'ungaíta',
  ungaito: 'ungaíto',
  ungajas: 'ungajas',
  ungajos: 'ungajos',
  ungalia: 'ungália',
  unganjo: 'unganjo',
  ungidao: 'ungidão',
  ungidas: 'ungidas',
  ungidor: 'ungidor',
  ungidos: 'ungidos',
  ungieis: 'ungíeis',
  ungimos: 'ungimos',
  ungindo: 'ungindo',
  ungiram: 'ungiram',
  ungirao: 'ungirão',
  ungiras: 'ungiras',
  ungirei: 'ungirei',
  ungirem: 'ungirem',
  ungires: 'ungires',
  ungiria: 'ungiria',
  ungiris: 'ungiris',
  ungirmo: 'ungirmo',
  ungisse: 'ungisse',
  ungiste: 'ungiste',
  ungongo: 'ungongo',
  ungotes: 'ungotes',
  unguais: 'unguais',
  unguari: 'unguari',
  ungueal: 'ungueal',
  unguebe: 'unguebe',
  ungular: 'ungular',
  ungulas: 'úngulas',
  unhacas: 'unhacas',
  unhacos: 'unhaços',
  unhadao: 'unhadão',
  unhadas: 'unhadas',
  unhador: 'unhador',
  unhados: 'unhados',
  unhames: 'unhames',
  unhamos: 'unhamos',
  unhando: 'unhando',
  unhante: 'unhante',
  unharam: 'unharam',
  unharao: 'unharão',
  unharas: 'unharás',
  unharei: 'unharei',
  unharem: 'unharem',
  unhares: 'unhares',
  unharia: 'unharia',
  unharmo: 'unharmo',
  unhasse: 'unhasse',
  unhaste: 'unhaste',
  unhatas: 'unhatas',
  unhavam: 'unhavam',
  unhavas: 'unhavas',
  unheira: 'unheira',
  unheiro: 'unheiro',
  unhemos: 'unhemos',
  unhetas: 'unhetas',
  unhinha: 'unhinha',
  unhonas: 'unhonas',
  unhosas: 'unhosas',
  unhosos: 'unhosos',
  unhudas: 'unhudas',
  unhudos: 'unhudos',
  unhulas: 'únhulas',
  uniamos: 'uníamos',
  uniatas: 'uniatas',
  uniceps: 'úniceps',
  unicola: 'unicola',
  unicolo: 'unicolo',
  unidade: 'unidade',
  unidoes: 'unidões',
  unidona: 'unidona',
  unidora: 'unidora',
  uniduto: 'uniduto',
  uniente: 'uniente',
  unifica: 'unifica',
  unifico: 'unifico',
  unifora: 'unífora',
  uniforo: 'uníforo',
  unigama: 'unígama',
  unigamo: 'unígamo',
  unigena: 'unígena',
  unigene: 'unígene',
  unigeno: 'unígeno',
  unijuga: 'uníjuga',
  unijugo: 'uníjugo',
  uniloca: 'uníloca',
  uniloco: 'uníloco',
  unimama: 'unimama',
  unimamo: 'unímamo',
  unimana: 'unímana',
  unimano: 'unímano',
  unimodo: 'unímodo',
  uniolas: 'uníolas',
  unioras: 'unioras',
  unipara: 'unípara',
  uniparo: 'uníparo',
  unipede: 'unípede',
  unipene: 'unipene',
  unirdes: 'unirdes',
  unireis: 'unireis',
  unireme: 'unireme',
  uniremo: 'uniremo',
  uniriam: 'uniriam',
  unirias: 'unirias',
  unirmos: 'unirmos',
  unissem: 'unissem',
  unisses: 'unisses',
  unissex: 'unissex',
  unistes: 'unistes',
  uniteco: 'uniteco',
  unitiva: 'unitiva',
  unitivo: 'unitivo',
  unitiza: 'unitiza',
  unitize: 'unitize',
  unitizo: 'unitizo',
  unitona: 'unítona',
  unitono: 'unítono',
  univeis: 'uníveis',
  univoca: 'unívoca',
  univoco: 'unívoco',
  unjamos: 'unjamos',
  unjiris: 'unjiris',
  unocula: 'unócula',
  unoculo: 'unóculo',
  untacao: 'untação',
  untadao: 'untadão',
  untadas: 'untadas',
  untador: 'untador',
  untados: 'untados',
  untamos: 'untamos',
  untando: 'untando',
  untanha: 'untanha',
  untante: 'untante',
  untaram: 'untaram',
  untarao: 'untarão',
  untaras: 'untaras',
  untarei: 'untarei',
  untarem: 'untarem',
  untares: 'untares',
  untaria: 'untaria',
  untarmo: 'untarmo',
  untasse: 'untasse',
  untaste: 'untaste',
  untavam: 'untavam',
  untavas: 'untavas',
  untavel: 'untável',
  unteira: 'unteira',
  unteiro: 'unteiro',
  untemos: 'untemos',
  untitai: 'untitai',
  untitam: 'untitam',
  untitar: 'untitar',
  untitas: 'untitas',
  untitei: 'untitei',
  untitem: 'untitem',
  untites: 'untites',
  untitou: 'untitou',
  untoras: 'untoras',
  untores: 'untores',
  untoria: 'untória',
  untorio: 'untório',
  untuens: 'untuéns',
  untuosa: 'untuosa',
  untuoso: 'untuoso',
  unturas: 'unturas',
  unumbio: 'unúmbio',
  uolofes: 'uolofes',
  uolongo: 'uolongo',
  uoracus: 'uoraçus',
  upadoes: 'upadões',
  upadona: 'upadona',
  upadora: 'upadora',
  upandas: 'upandas',
  upantes: 'upantes',
  upardes: 'upardes',
  upareis: 'upáreis',
  uparemo: 'uparemo',
  upariam: 'upariam',
  uparias: 'uparias',
  uparmos: 'uparmos',
  upassem: 'upassem',
  upasses: 'upasses',
  upastes: 'upastes',
  upaveis: 'upáveis',
  upeneus: 'upeneus',
  upgrade: 'upgrade',
  upiubas: 'upiúbas',
  uplotes: 'uplotes',
  upsilao: 'upsilão',
  upsilon: 'upsilon',
  uptioto: 'uptioto',
  upupida: 'upúpida',
  uquetes: 'uquetés',
  uracacu: 'uracaçu',
  uracais: 'uracais',
  uracicu: 'uracicu',
  uracila: 'uracila',
  uracilo: 'uracilo',
  uracupa: 'uracupa',
  uragoga: 'uragoga',
  uragogo: 'uragogo',
  uralias: 'urálias',
  uralica: 'urálica',
  uralico: 'urálico',
  uralina: 'uralina',
  uralios: 'urálios',
  uralita: 'uralita',
  uralite: 'uralite',
  uralito: 'uralito',
  uraliza: 'uralizá',
  uralize: 'uralize',
  uralizo: 'uralizo',
  uralzao: 'uralzão',
  uramaca: 'uramaçá',
  uramila: 'uramila',
  uramilo: 'uramilo',
  uranada: 'uranada',
  uranado: 'uranado',
  uranais: 'uranais',
  uranamo: 'uranamo',
  uranara: 'uranará',
  uranato: 'uranato',
  uranava: 'uranava',
  uraneis: 'uraneis',
  uranemo: 'uranemo',
  uranias: 'urânias',
  uranica: 'urânica',
  uranico: 'urânico',
  uranila: 'uranila',
  uranilo: 'uranilo',
  uranina: 'uranina',
  uranios: 'urânios',
  uranita: 'uranita',
  uranite: 'uranite',
  uranito: 'uranito',
  uraniza: 'uraniza',
  uranize: 'uranize',
  uranizo: 'uranizo',
  uranosa: 'uranosa',
  uranoso: 'uranoso',
  urapara: 'urapará',
  urapeba: 'urapeba',
  urapuca: 'urapuca',
  urapuru: 'urapuru',
  urarema: 'urarema',
  urarias: 'urárias',
  uraricu: 'uraricu',
  urarina: 'urarina',
  urbanas: 'urbanas',
  urbanos: 'urbanos',
  urbeque: 'urbeque',
  urceola: 'urcéola',
  urceolo: 'urcéolo',
  urchila: 'urchila',
  urdamos: 'urdamos',
  urdicao: 'urdição',
  urdidao: 'urdidão',
  urdidas: 'urdidas',
  urdidor: 'urdidor',
  urdidos: 'urdidos',
  urdieis: 'urdíeis',
  urdimos: 'urdimos',
  urdindo: 'urdindo',
  urdiram: 'urdiram',
  urdirao: 'urdirão',
  urdiras: 'urdiras',
  urdirei: 'urdirei',
  urdirem: 'urdirem',
  urdires: 'urdires',
  urdiria: 'urdiria',
  urdirmo: 'urdirmo',
  urdisse: 'urdisse',
  urdiste: 'urdiste',
  urdumes: 'urdumes',
  uredepa: 'uredepa',
  ureicas: 'ureicas',
  ureicos: 'ureicos',
  uremias: 'uremias',
  uremica: 'urêmica',
  uremico: 'urêmico',
  urencia: 'urência',
  urentes: 'urentes',
  uretana: 'uretana',
  uretano: 'uretano',
  uretero: 'uretero',
  uretica: 'urética',
  uretico: 'urético',
  uretral: 'uretral',
  uretras: 'uretras',
  urgebao: 'urgebão',
  urgente: 'urgente',
  urgevao: 'urgevão',
  urgicas: 'úrgicas',
  urgicos: 'úrgicos',
  urgidao: 'urgidão',
  urgidas: 'urgidas',
  urgidor: 'urgidor',
  urgidos: 'urgidos',
  urgieis: 'urgíeis',
  urgimos: 'urgimos',
  urgindo: 'urgindo',
  urgiram: 'urgiram',
  urgirao: 'urgirão',
  urgiras: 'urgiras',
  urgirei: 'urgirei',
  urgirem: 'urgirem',
  urgires: 'urgires',
  urgiria: 'urgiria',
  urgirmo: 'urgirmo',
  urgisse: 'urgisse',
  urgiste: 'urgiste',
  uriates: 'uriates',
  uribaco: 'uribaco',
  uricana: 'uricana',
  urichoa: 'urichoa',
  uricuri: 'uricuri',
  uridina: 'uridina',
  urinada: 'urinada',
  urinado: 'urinado',
  urinais: 'urinais',
  urinamo: 'urinamo',
  urinara: 'urinara',
  urinava: 'urinava',
  urineis: 'urineis',
  urinemo: 'urinemo',
  urinois: 'urinóis',
  urinosa: 'urinosa',
  urinoso: 'urinoso',
  uritutu: 'uritutu',
  urjamos: 'urjamos',
  urnaria: 'urnária',
  urnario: 'urnário',
  urningo: 'urningo',
  urnulas: 'úrnulas',
  urocele: 'urocele',
  urocloa: 'urócloa',
  urocopo: 'urócopo',
  urodela: 'urodela',
  urodelo: 'urodelo',
  urodeos: 'uródeos',
  urofilo: 'urofilo',
  urofora: 'urófora',
  urolito: 'urólito',
  urologa: 'uróloga',
  urologo: 'urólogo',
  uromelo: 'urômelo',
  uromero: 'urômero',
  uronico: 'urônico',
  uropigo: 'uropigo',
  uropode: 'urópode',
  urracas: 'urracas',
  urradao: 'urradão',
  urradas: 'urradas',
  urrador: 'urrador',
  urrados: 'urrados',
  urramos: 'urramos',
  urrando: 'urrando',
  urrante: 'urrante',
  urraram: 'urraram',
  urrarao: 'urrarão',
  urraras: 'urraras',
  urrarei: 'urrarei',
  urrarem: 'urrarem',
  urrares: 'urrares',
  urraria: 'urraria',
  urrarmo: 'urrarmo',
  urrasse: 'urrasse',
  urraste: 'urraste',
  urravam: 'urravam',
  urravas: 'urravas',
  urreiro: 'urreiro',
  urremos: 'urremos',
  urrinho: 'urrinho',
  ursadas: 'ursadas',
  ursideo: 'ursídeo',
  ursinas: 'ursinas',
  ursinha: 'ursinha',
  ursinho: 'ursinho',
  ursinos: 'ursinos',
  urticai: 'urticai',
  urticam: 'urticam',
  urticar: 'urticar',
  urticas: 'urticas',
  urticou: 'urticou',
  urtigai: 'urtigai',
  urtigal: 'urtigal',
  urtigam: 'urtigam',
  urtigao: 'urtigão',
  urtigar: 'urtigar',
  urtigas: 'urtigas',
  urtigou: 'urtigou',
  urtigue: 'urtigue',
  urtique: 'urtique',
  urubuza: 'urubuza',
  urubuze: 'urubuze',
  urubuzo: 'urubuzo',
  urucaca: 'urucaca',
  urucari: 'urucari',
  urucatu: 'urucatu',
  urucuri: 'urucuri',
  uruense: 'uruense',
  urupema: 'urupema',
  urupuca: 'urupuca',
  urutago: 'urutago',
  urutaui: 'urutauí',
  urutaus: 'urutaus',
  urvilea: 'urvílea',
  urzedos: 'urzedos',
  urzeira: 'urzeira',
  urzeiro: 'urzeiro',
  urzelas: 'urzelas',
  usadica: 'usadiça',
  usadico: 'usadiço',
  usadoes: 'usadões',
  usadona: 'usadona',
  usadora: 'usadora',
  usagens: 'usagens',
  usagres: 'usagres',
  usambau: 'usambau',
  usambio: 'usâmbio',
  usancas: 'usanças',
  usantes: 'usantes',
  usardes: 'usardes',
  usareis: 'usareis',
  usaremo: 'usaremo',
  usariam: 'usariam',
  usarias: 'usarias',
  usarmos: 'usarmos',
  usassem: 'usassem',
  usasses: 'usasses',
  usastes: 'usastes',
  usaveis: 'usáveis',
  usbeque: 'usbeque',
  uscense: 'uscense',
  uscufes: 'uscufes',
  useiras: 'useiras',
  useiros: 'useiros',
  usinada: 'usinada',
  usinado: 'usinado',
  usinais: 'usinais',
  usinamo: 'usinamo',
  usinara: 'usinara',
  usinava: 'usinava',
  usineis: 'usineis',
  usinemo: 'usinemo',
  usipete: 'usípete',
  usipias: 'usípias',
  usipios: 'usípios',
  usitada: 'usitada',
  usitado: 'usitado',
  usitais: 'usitais',
  usitamo: 'usitamo',
  usitara: 'usitará',
  usitava: 'usitava',
  usiteis: 'usiteis',
  usitemo: 'usitemo',
  usmadao: 'usmadão',
  usmadas: 'usmadas',
  usmador: 'usmador',
  usmados: 'usmados',
  usmamos: 'usmamos',
  usmando: 'usmando',
  usmante: 'usmante',
  usmaram: 'usmaram',
  usmarao: 'usmarão',
  usmaras: 'usmarás',
  usmarei: 'usmarei',
  usmarem: 'usmarem',
  usmares: 'usmares',
  usmaria: 'usmaria',
  usmarmo: 'usmarmo',
  usmasse: 'usmasse',
  usmaste: 'usmaste',
  usmavam: 'usmavam',
  usmavas: 'usmavas',
  usmeira: 'usmeira',
  usmeiro: 'usmeiro',
  usmemos: 'usmemos',
  usnatos: 'usnatos',
  usneais: 'usneais',
  usneina: 'usneína',
  usneois: 'usneois',
  usnicas: 'úsnicas',
  usnicos: 'úsnicos',
  usninas: 'usninas',
  usovita: 'usovita',
  uspense: 'uspense',
  ussilas: 'ussilas',
  ustamos: 'ustamos',
  usteria: 'ustéria',
  ustidas: 'ustidas',
  ustidos: 'ustidos',
  ustieis: 'ustíeis',
  ustimos: 'ustimos',
  ustinas: 'ustinas',
  ustindo: 'ustindo',
  ustiram: 'ustiram',
  ustirao: 'ustirão',
  ustiras: 'ustirás',
  ustirei: 'ustirei',
  ustirem: 'ustirem',
  ustires: 'ustires',
  ustiria: 'ustiria',
  ustirmo: 'ustirmo',
  ustisse: 'ustisse',
  ustiste: 'ustiste',
  ustivel: 'ustível',
  ustoria: 'ustória',
  ustorio: 'ustório',
  ustrina: 'ustrina',
  ustulai: 'ustulai',
  ustulam: 'ustulam',
  ustular: 'ustular',
  ustulas: 'ustulas',
  ustulei: 'ustulei',
  ustulem: 'ustulem',
  ustules: 'ustules',
  ustulou: 'ustulou',
  usuanas: 'usuanas',
  usuanos: 'usuanos',
  usuaria: 'usuária',
  usuario: 'usuário',
  usucapa: 'usucapa',
  usucape: 'usucape',
  usucapi: 'usucapi',
  usucapo: 'usucapo',
  usufrua: 'usufrua',
  usufrui: 'usufrui',
  usufruo: 'usufruo',
  usurada: 'usurada',
  usurado: 'usurado',
  usurais: 'usurais',
  usuramo: 'usuramo',
  usurara: 'usurará',
  usurava: 'usurava',
  usureis: 'usureis',
  usuremo: 'usuremo',
  usurpai: 'usurpai',
  usurpam: 'usurpam',
  usurpar: 'usurpar',
  usurpas: 'usurpas',
  usurpei: 'usurpei',
  usurpem: 'usurpem',
  usurpes: 'usurpes',
  usurpou: 'usurpou',
  utadoes: 'utadões',
  utadona: 'utadona',
  utadora: 'utadora',
  utahita: 'utahita',
  utahite: 'utahite',
  utaitas: 'utaítas',
  utaites: 'utaítes',
  utantes: 'utantes',
  utardes: 'utardes',
  utareis: 'utáreis',
  utaremo: 'utaremo',
  utariam: 'utariam',
  utarias: 'utarias',
  utarmos: 'utarmos',
  utassem: 'utassem',
  utasses: 'utasses',
  utastes: 'utastes',
  utaveis: 'utáveis',
  utensil: 'utensil',
  utensis: 'utensis',
  utentes: 'utentes',
  uterina: 'uterina',
  uterino: 'uterino',
  uterite: 'uterite',
  utiliza: 'utiliza',
  utilize: 'utilize',
  utilizo: 'utilizo',
  utleria: 'utléria',
  utopias: 'utopias',
  utopica: 'utópica',
  utopico: 'utópico',
  utopiza: 'utopizá',
  utopize: 'utopize',
  utopizo: 'utopizo',
  utpalas: 'utpalas',
  utraria: 'utrária',
  utrario: 'utrário',
  utuabas: 'utuabas',
  utuambe: 'utuambé',
  utuauba: 'utuaúba',
  uucango: 'uuçango',
  uutenis: 'uutenis',
  uvaaias: 'uvaaias',
  uvaeira: 'uvaeira',
  uvaense: 'uvaense',
  uvalhas: 'uvalhas',
  uvalzao: 'uvalzão',
  uvanita: 'uvanita',
  uvarana: 'uvarana',
  uvarias: 'uvárias',
  uvariea: 'uvaríea',
  uvarios: 'uvários',
  uveiras: 'uveiras',
  uveiros: 'uveiros',
  uveitas: 'uveítas',
  uveites: 'uveítes',
  uvespim: 'uvespim',
  uvifera: 'uvífera',
  uvifero: 'uvífero',
  uvilhas: 'uvilhas',
  uvinhas: 'uvinhas',
  uvitato: 'uvitato',
  uvitica: 'uvítica',
  uvitico: 'uvítico',
  uvulite: 'uvulite',
  uxianas: 'uxianas',
  uxianos: 'uxianos',
  uxicrim: 'uxicrim',
  uximono: 'uximono',
  uxipucu: 'uxipuçu',
  uxirana: 'uxirana',
  uxorias: 'uxórias',
  uxorica: 'uxórica',
  uxorico: 'uxórico',
  uxorios: 'uxórios',
  uzambas: 'uzambas',
  uzambau: 'uzambau',
  uzarina: 'uzarina',
  uzbeque: 'uzbeque',
  uzelias: 'uzélias',
  uzifuro: 'uzífuro',
  vaabita: 'vaabita',
  vaalita: 'vaalita',
  vaalite: 'vaalite',
  vaambos: 'vaambos',
  vacacao: 'vacação',
  vacadao: 'vacadão',
  vacadas: 'vacadas',
  vacador: 'vacador',
  vacados: 'vacados',
  vacagem: 'vacagem',
  vacamos: 'vacamos',
  vacanas: 'vacanas',
  vacando: 'vacando',
  vacanos: 'vacanos',
  vacante: 'vacante',
  vacarai: 'vacaraí',
  vacaram: 'vacaram',
  vacarao: 'vacarão',
  vacaras: 'vacarás',
  vacarei: 'vacarei',
  vacarem: 'vacarem',
  vacares: 'vacares',
  vacaria: 'vacaria',
  vacaril: 'vacaril',
  vacaris: 'vacaris',
  vacarmo: 'vacarmo',
  vacasse: 'vacasse',
  vacaste: 'vacaste',
  vacatio: 'vacatio',
  vacavam: 'vacavam',
  vacavas: 'vacavas',
  vaceana: 'vaceana',
  vaceano: 'vaceano',
  vaceias: 'vaceias',
  vacense: 'vacense',
  vacibas: 'vacibás',
  vacilai: 'vacilai',
  vacilam: 'vacilam',
  vacilao: 'vacilão',
  vacilar: 'vacilar',
  vacilas: 'vacilas',
  vacilei: 'vacilei',
  vacilem: 'vacilem',
  vaciles: 'vaciles',
  vacilos: 'vacilos',
  vacilou: 'vacilou',
  vacinai: 'vacinai',
  vacinal: 'vacinal',
  vacinam: 'vacinam',
  vacinar: 'vacinar',
  vacinas: 'vacinas',
  vacinei: 'vacinei',
  vacinem: 'vacinem',
  vacines: 'vacines',
  vacinha: 'vacinha',
  vacinia: 'vacínia',
  vacinio: 'vacínio',
  vacinou: 'vacinou',
  vacisco: 'vacisco',
  vaconas: 'vaconas',
  vacunal: 'vacunal',
  vacunas: 'vacunas',
  vacuolo: 'vacúolo',
  vacuoma: 'vacuoma',
  vadeada: 'vadeada',
  vadeado: 'vadeado',
  vadeais: 'vadeais',
  vadeamo: 'vadeamo',
  vadeara: 'vadeara',
  vadeava: 'vadeava',
  vadeeis: 'vadeeis',
  vadeemo: 'vadeemo',
  vadeiam: 'vadeiam',
  vadeias: 'vadeias',
  vadeiem: 'vadeiem',
  vadeies: 'vadeies',
  vadense: 'vadense',
  vadeosa: 'vadeosa',
  vadeoso: 'vadeoso',
  vadiada: 'vadiada',
  vadiado: 'vadiado',
  vadiais: 'vadiais',
  vadiamo: 'vadiamo',
  vadiara: 'vadiara',
  vadiava: 'vadiava',
  vadieis: 'vadieis',
  vadiemo: 'vadiemo',
  vadiice: 'vadiice',
  vadiote: 'vadiote',
  vadosas: 'vadosas',
  vadosos: 'vadosos',
  vadroil: 'vadroil',
  vadrois: 'vadrois',
  vaesita: 'vaesita',
  vaesite: 'vaesite',
  vagacao: 'vagação',
  vagadao: 'vagadão',
  vagadas: 'vagadas',
  vagador: 'vagador',
  vagados: 'vágados',
  vagagem: 'vagagem',
  vagamao: 'vagamão',
  vagamen: 'vagâmen',
  vagamos: 'vagamos',
  vaganao: 'vaganão',
  vaganas: 'vaganãs',
  vaganau: 'vaganau',
  vagando: 'vagando',
  vaganea: 'vaganeá',
  vagante: 'vagante',
  vaganti: 'vaganti',
  vagaram: 'vagaram',
  vagarao: 'vagarão',
  vagaras: 'vagaras',
  vagarei: 'vagarei',
  vagarem: 'vagarem',
  vagares: 'vagares',
  vagaria: 'vagaria',
  vagarmo: 'vagarmo',
  vagasse: 'vagasse',
  vagaste: 'vagaste',
  vagavam: 'vagavam',
  vagavas: 'vagavas',
  vageada: 'vageada',
  vageado: 'vageado',
  vageais: 'vageais',
  vageara: 'vageará',
  vageava: 'vageava',
  vageeis: 'vageeis',
  vageiam: 'vageiam',
  vageias: 'vageias',
  vageiem: 'vageiem',
  vageies: 'vageies',
  vageira: 'vageira',
  vageiro: 'vageiro',
  vagense: 'vagense',
  vagidao: 'vagidão',
  vagidas: 'vagidas',
  vagidor: 'vagidor',
  vagidos: 'vagidos',
  vagieis: 'vagíeis',
  vagiena: 'vagiena',
  vagieno: 'vagieno',
  vagimos: 'vagimos',
  vaginal: 'vaginal',
  vaginas: 'vaginas',
  vagindo: 'vagindo',
  vagiram: 'vagiram',
  vagirao: 'vagirão',
  vagiras: 'vagiras',
  vagirei: 'vagirei',
  vagirem: 'vagirem',
  vagires: 'vagires',
  vagiria: 'vagiria',
  vagirmo: 'vagirmo',
  vagisse: 'vagisse',
  vagiste: 'vagiste',
  vagitas: 'vagitas',
  vagites: 'vagites',
  vagitos: 'vagitos',
  vagonas: 'vagonas',
  vagruco: 'vagruço',
  vagueai: 'vagueai',
  vaguear: 'vaguear',
  vaguedo: 'vaguedo',
  vagueei: 'vagueei',
  vagueia: 'vagueia',
  vagueie: 'vagueie',
  vagueio: 'vagueio',
  vagueis: 'vagueis',
  vagueja: 'vagueja',
  vagueje: 'vagueje',
  vaguejo: 'vaguejo',
  vaguemo: 'vaguemo',
  vagueou: 'vagueou',
  vagueza: 'vagueza',
  vagulas: 'vágulas',
  vagulos: 'vágulos',
  vaiadao: 'vaiadão',
  vaiadas: 'vaiadas',
  vaiador: 'vaiador',
  vaiados: 'vaiados',
  vaiamos: 'vaiamos',
  vaiando: 'vaiando',
  vaiante: 'vaiante',
  vaiaram: 'vaiaram',
  vaiarao: 'vaiarão',
  vaiaras: 'vaiaras',
  vaiarei: 'vaiarei',
  vaiarem: 'vaiarem',
  vaiares: 'vaiares',
  vaiaria: 'vaiaria',
  vaiarmo: 'vaiarmo',
  vaiasse: 'vaiasse',
  vaiaste: 'vaiaste',
  vaiavam: 'vaiavam',
  vaiavas: 'vaiavas',
  vaicias: 'vaicias',
  vaidade: 'vaidade',
  vaidosa: 'vaidosa',
  vaidoso: 'vaidoso',
  vaiemos: 'vaiemos',
  vaimiri: 'vaimiri',
  vaioros: 'vaiorós',
  vairoes: 'vairões',
  vairona: 'vairona',
  vaivais: 'vaivais',
  vaivens: 'vaivéns',
  vaivoda: 'vaivoda',
  vaixias: 'vaixias',
  vajamos: 'vajamos',
  vajanas: 'vajanas',
  vajroli: 'vajroli',
  valaate: 'valaate',
  valabas: 'valabás',
  valabua: 'valabuá',
  valacas: 'valacas',
  valacos: 'valacos',
  valadao: 'valadão',
  valadas: 'valadas',
  valadia: 'valadia',
  valadio: 'valadio',
  valador: 'valador',
  valados: 'valados',
  valagem: 'valagem',
  valaita: 'valaíta',
  valaite: 'valaíte',
  valamen: 'valâmen',
  valamos: 'valamos',
  valando: 'valando',
  valante: 'valante',
  valapie: 'valapié',
  valaram: 'valaram',
  valarao: 'valarão',
  valaras: 'valarás',
  valarei: 'valarei',
  valarem: 'valarem',
  valares: 'valares',
  valaria: 'valaria',
  valarmo: 'valarmo',
  valasse: 'valasse',
  valaste: 'valaste',
  valavam: 'valavam',
  valavas: 'valavas',
  valedia: 'valedia',
  valedio: 'valedio',
  valedor: 'valedor',
  valegos: 'valegos',
  valeias: 'valeias',
  valeira: 'valeira',
  valeiro: 'valeiro',
  valeita: 'valeíta',
  valeite: 'valeíte',
  valejos: 'valejos',
  valemos: 'valemos',
  valenca: 'valença',
  valendo: 'valendo',
  valense: 'valense',
  valente: 'valente',
  valeral: 'valeral',
  valeram: 'valeram',
  valerao: 'valerão',
  valeras: 'valeras',
  valerei: 'valerei',
  valerem: 'valerem',
  valeres: 'valeres',
  valeria: 'valéria',
  valeril: 'valeril',
  valeris: 'valeris',
  valermo: 'valermo',
  valerol: 'valerol',
  valesia: 'valésia',
  valesse: 'valesse',
  valeste: 'valeste',
  valetas: 'valetas',
  valetes: 'valetes',
  valgios: 'válgios',
  valhais: 'valhais',
  valhala: 'valhala',
  valhamo: 'valhamo',
  valiada: 'valiada',
  valiado: 'valiado',
  valiais: 'valiais',
  valiamo: 'valiamo',
  valiara: 'valiara',
  valiato: 'valiato',
  valiava: 'valiava',
  validai: 'validai',
  validam: 'validam',
  validao: 'validão',
  validar: 'validar',
  validas: 'válidas',
  validei: 'validei',
  validem: 'validem',
  valides: 'valides',
  validez: 'validez',
  validia: 'validia',
  validio: 'validio',
  validol: 'validol',
  validos: 'válidos',
  validou: 'validou',
  valieis: 'valíeis',
  valiemo: 'valiemo',
  valilos: 'valilos',
  valimba: 'valimba',
  valinas: 'valinas',
  valinca: 'valinca',
  valinho: 'valinho',
  valiosa: 'valiosa',
  valioso: 'valioso',
  valisas: 'valisas',
  valises: 'valises',
  valisia: 'valísia',
  valocas: 'valocas',
  valoide: 'valoide',
  valonas: 'valonas',
  valonia: 'valônia',
  valonio: 'valônio',
  valorai: 'valorai',
  valoram: 'valoram',
  valorar: 'valorar',
  valoras: 'valoras',
  valorei: 'valorei',
  valorem: 'valorem',
  valores: 'valores',
  valorou: 'valorou',
  valosas: 'valosas',
  valosos: 'valosos',
  valotas: 'valotas',
  valsada: 'valsada',
  valsado: 'valsado',
  valsais: 'valsais',
  valsamo: 'valsamo',
  valsara: 'valsara',
  valsava: 'valsava',
  valseai: 'valseai',
  valsear: 'valsear',
  valseas: 'válseas',
  valseei: 'valseei',
  valseia: 'valseia',
  valseie: 'valseie',
  valseio: 'valseio',
  valseis: 'valseis',
  valseja: 'valseja',
  valseje: 'valseje',
  valsejo: 'valsejo',
  valsemo: 'valsemo',
  valseos: 'válseos',
  valseou: 'valseou',
  valsois: 'valsois',
  valuius: 'valuiús',
  valumas: 'valumas',
  valuras: 'valuras',
  valvada: 'valvada',
  valvado: 'valvado',
  valvata: 'valvata',
  valvula: 'válvula',
  valzoes: 'valzões',
  vamacur: 'vamacur',
  vamente: 'vãmente',
  vamiris: 'vamiris',
  vamonez: 'vamonez',
  vampira: 'vampira',
  vampire: 'vampire',
  vampiro: 'vampiro',
  vanacus: 'vanaçus',
  vanadio: 'vanádio',
  vanadis: 'vanádis',
  vanadol: 'vanadol',
  vancali: 'vançali',
  vancoes: 'vancões',
  vandada: 'vandada',
  vandado: 'vandado',
  vandais: 'vandais',
  vandala: 'vândala',
  vandalo: 'vândalo',
  vandamo: 'vandamo',
  vandara: 'vandara',
  vandaus: 'vandaus',
  vandava: 'vandava',
  vandeas: 'vândeas',
  vandeis: 'vandeis',
  vandemo: 'vandemo',
  vandeos: 'vândeos',
  vanecam: 'vaneçam',
  vanecas: 'vaneças',
  vanecei: 'vanecei',
  vanecem: 'vanecem',
  vanecer: 'vanecer',
  vaneces: 'vaneces',
  vaneceu: 'vaneceu',
  vanecia: 'vanecia',
  vanelos: 'vanelos',
  vanense: 'vanense',
  vanerao: 'vanerão',
  vanesca: 'vanesça',
  vanesce: 'vanescê',
  vanesci: 'vanesci',
  vanesco: 'vanesço',
  vanesia: 'vanésia',
  vanessa: 'vanessa',
  vangana: 'vangana',
  vangiao: 'vangião',
  vangias: 'vangiãs',
  vanhais: 'vanhais',
  vanhame: 'vanhame',
  vanicas: 'vânicas',
  vanicos: 'vânicos',
  vanilal: 'vanilal',
  vanilas: 'vanilas',
  vantaja: 'vantaja',
  vantaje: 'vantaje',
  vantajo: 'vantajo',
  vanzeai: 'vanzeai',
  vanzear: 'vanzear',
  vanzeei: 'vanzeei',
  vanzeia: 'vanzeia',
  vanzeie: 'vanzeie',
  vanzeio: 'vanzeio',
  vanzeou: 'vanzeou',
  vanzima: 'vanzima',
  vanzoes: 'vanzões',
  vanzura: 'vanzura',
  vaosoes: 'vãosões',
  vapidas: 'vápidas',
  vapidos: 'vápidos',
  vaporai: 'vaporai',
  vaporam: 'vaporam',
  vaporar: 'vaporar',
  vaporas: 'vaporas',
  vaporei: 'vaporei',
  vaporem: 'vaporem',
  vapores: 'vapores',
  vaporou: 'vaporou',
  vapuacu: 'vapuaçu',
  vapulai: 'vapulai',
  vapulam: 'vapulam',
  vapular: 'vapular',
  vapulas: 'vapulas',
  vapulei: 'vapulei',
  vapulem: 'vapulem',
  vapules: 'vapules',
  vapulou: 'vapulou',
  vaqueai: 'vaqueai',
  vaquear: 'vaquear',
  vaqueei: 'vaqueei',
  vaqueia: 'vaqueia',
  vaqueie: 'vaqueie',
  vaqueio: 'vaqueio',
  vaqueis: 'vaqueis',
  vaqueja: 'vaqueja',
  vaqueje: 'vaqueje',
  vaquejo: 'vaquejo',
  vaquemo: 'vaquemo',
  vaqueou: 'vaqueou',
  vaqueta: 'vaqueta',
  vaquias: 'vaquias',
  vaquice: 'vaquice',
  vaquito: 'vaquito',
  varacao: 'varação',
  varacus: 'varacus',
  varadao: 'varadão',
  varadas: 'varadas',
  varador: 'varador',
  varados: 'varados',
  varagos: 'varagos',
  varalis: 'varalis',
  varames: 'varames',
  varamos: 'varamos',
  varanas: 'varanas',
  varanda: 'varanda',
  varando: 'varando',
  varanga: 'varanga',
  varango: 'varango',
  varanos: 'varanos',
  varante: 'varante',
  varapau: 'varapau',
  varapos: 'varapós',
  vararam: 'vararam',
  vararao: 'vararão',
  vararas: 'vararas',
  vararei: 'vararei',
  vararem: 'vararem',
  varares: 'varares',
  vararia: 'vararia',
  vararmo: 'vararmo',
  varasca: 'varasca',
  varasse: 'varasse',
  varaste: 'varaste',
  varavam: 'varavam',
  varavas: 'varavas',
  varavel: 'varável',
  varbara: 'varbara',
  varbaro: 'varbaro',
  vardeas: 'várdeas',
  vardeia: 'vardeia',
  vardeus: 'vardeus',
  vardias: 'vardiás',
  vardula: 'várdula',
  vardulo: 'várdulo',
  vareada: 'vareada',
  vareado: 'vareado',
  vareais: 'vareais',
  vareara: 'vareará',
  vareava: 'vareava',
  varedos: 'varedos',
  vareeis: 'vareeis',
  varegas: 'varegas',
  varegos: 'varegos',
  vareiam: 'vareiam',
  vareias: 'vareias',
  vareiem: 'vareiem',
  vareies: 'vareies',
  vareios: 'vareios',
  vareira: 'vareira',
  vareiro: 'vareiro',
  varejai: 'varejai',
  varejam: 'varejam',
  varejao: 'varejão',
  varejar: 'varejar',
  varejas: 'varejas',
  varejei: 'varejei',
  varejem: 'varejem',
  varejes: 'varejes',
  varejos: 'varejos',
  varejou: 'varejou',
  varelas: 'varelas',
  varelos: 'varelos',
  varemos: 'varemos',
  varenea: 'varênea',
  vareque: 'vareque',
  varetas: 'varetas',
  varezas: 'varezas',
  vargeas: 'várgeas',
  vargedo: 'vargedo',
  vargens: 'vargens',
  vargeta: 'vargeta',
  vargins: 'vargins',
  variada: 'variada',
  variado: 'variado',
  variais: 'variais',
  variamo: 'variamo',
  variara: 'variara',
  variava: 'variava',
  varicao: 'varição',
  varicas: 'variças',
  varicha: 'varicha',
  varicos: 'variços',
  variega: 'variega',
  variego: 'variego',
  varieis: 'varieis',
  variemo: 'variemo',
  varilha: 'varilha',
  varinas: 'varinas',
  varinel: 'varinel',
  varinha: 'varinha',
  varinos: 'varinos',
  variola: 'varíola',
  variolo: 'varíolo',
  variosa: 'variosa',
  varioso: 'varioso',
  varismo: 'varismo',
  varista: 'varista',
  varizes: 'varizes',
  varjoes: 'varjões',
  varjota: 'varjota',
  varlete: 'varlete',
  varloas: 'varloas',
  varlopa: 'varlopa',
  varolas: 'varolas',
  varonia: 'varonia',
  varonil: 'varonil',
  varonis: 'varonis',
  varotes: 'varotes',
  varrais: 'varrais',
  varramo: 'varramo',
  varreis: 'varreis',
  varremo: 'varremo',
  varrera: 'varrera',
  varriam: 'varriam',
  varrias: 'varrias',
  varrida: 'varrida',
  varrido: 'varrido',
  varroes: 'varrões',
  varuana: 'varuana',
  varuano: 'varuano',
  varudas: 'varudas',
  varudos: 'varudos',
  varunca: 'varunca',
  varvara: 'varvara',
  varvaro: 'varvaro',
  varvica: 'várvica',
  varvico: 'várvico',
  varvito: 'varvito',
  varzeas: 'várzeas',
  varzedo: 'varzedo',
  varzeos: 'várzeos',
  varzina: 'varzina',
  varzino: 'varzino',
  varzins: 'varzins',
  vasareu: 'vasaréu',
  vasaria: 'vasária',
  vasario: 'vasário',
  vasatas: 'vasatas',
  vasates: 'vasates',
  vascada: 'vascada',
  vascado: 'vascado',
  vascais: 'vascais',
  vascamo: 'vascamo',
  vascara: 'vascará',
  vascava: 'vascava',
  vascoes: 'vascões',
  vascone: 'váscone',
  vascosa: 'vascosa',
  vascoso: 'vascoso',
  vaseira: 'vaseira',
  vaseiro: 'vaseiro',
  vaselia: 'vasélia',
  vasenta: 'vasenta',
  vasento: 'vasento',
  vasilha: 'vasilha',
  vasilho: 'vasilho',
  vasinha: 'vasinha',
  vasinho: 'vasinho',
  vasitas: 'vasitas',
  vasites: 'vasites',
  vasiuda: 'vasiúda',
  vasiudo: 'vasiúdo',
  vasonas: 'vasonas',
  vasosas: 'vasosas',
  vasosos: 'vasosos',
  vasquea: 'vasqueá',
  vasquei: 'vasquei',
  vasquem: 'vasquem',
  vasquim: 'vasquim',
  vasroli: 'vasroli',
  vassala: 'vassala',
  vassale: 'vassale',
  vassalo: 'vassalo',
  vasseas: 'vásseas',
  vasseia: 'vasseia',
  vasseos: 'vásseos',
  vasseus: 'vasseus',
  vastada: 'vastada',
  vastado: 'vastado',
  vastago: 'vástago',
  vastais: 'vastais',
  vastamo: 'vastamo',
  vastara: 'vastará',
  vastava: 'vastava',
  vasteis: 'vasteis',
  vastemo: 'vastemo',
  vasteza: 'vasteza',
  vatapas: 'vatapás',
  vatapis: 'vatapis',
  vatapus: 'vatapus',
  vateria: 'vatéria',
  vaticas: 'váticas',
  vaticos: 'váticos',
  vatinea: 'vatínea',
  vatineo: 'vatíneo',
  vatinga: 'vatinga',
  vatinha: 'vatinha',
  vatinho: 'vatinho',
  vatismo: 'vatismo',
  vatuate: 'vatuate',
  vauense: 'vauense',
  vauxita: 'vauxita',
  vauxite: 'vauxite',
  vavavas: 'vavavás',
  vavucai: 'vavucai',
  vavucam: 'vavucam',
  vavucar: 'vavucar',
  vavucas: 'vavucas',
  vavucos: 'vavucos',
  vavucou: 'vavucou',
  vavuque: 'vavuque',
  vaxelos: 'vaxelos',
  vazadao: 'vazadão',
  vazadas: 'vazadas',
  vazador: 'vazador',
  vazados: 'vazados',
  vazamos: 'vazamos',
  vazando: 'vazando',
  vazante: 'vazante',
  vazaram: 'vazaram',
  vazarao: 'vazarão',
  vazaras: 'vazaras',
  vazarei: 'vazarei',
  vazarem: 'vazarem',
  vazares: 'vazares',
  vazaria: 'vazaria',
  vazarmo: 'vazarmo',
  vazasse: 'vazasse',
  vazaste: 'vazaste',
  vazavam: 'vazavam',
  vazavas: 'vazavas',
  vazeias: 'vazeias',
  vazeira: 'vazeirã',
  vazeiro: 'vazeiro',
  vazemos: 'vazemos',
  vaziada: 'vaziada',
  vaziado: 'vaziado',
  vaziais: 'vaziais',
  vaziamo: 'vaziamo',
  vaziara: 'vaziará',
  vaziava: 'vaziava',
  vazieis: 'vazieis',
  vaziemo: 'vaziemo',
  vazimba: 'vazimba',
  vaziuda: 'vaziúda',
  vaziudo: 'vaziúdo',
  veacoes: 'veações',
  veacona: 'veaçona',
  veadora: 'veadora',
  veamina: 'veamina',
  veamino: 'veamino',
  vearias: 'vearias',
  vecejai: 'vecejai',
  vecejam: 'vecejam',
  vecejar: 'vecejar',
  vecejas: 'vecejas',
  vecejei: 'vecejei',
  vecejem: 'vecejem',
  vecejes: 'vecejes',
  vecejos: 'vecejos',
  vecejou: 'vecejou',
  vectoes: 'vectões',
  vectora: 'vectora',
  vectriz: 'vectriz',
  vedacao: 'vedação',
  vedadao: 'vedadão',
  vedadas: 'vedadas',
  vedador: 'vedador',
  vedados: 'vedados',
  vedaica: 'vedaica',
  vedaico: 'vedaico',
  vedalha: 'vedalha',
  vedalia: 'vedália',
  vedamos: 'vedamos',
  vedando: 'vedando',
  vedanga: 'vedanga',
  vedanta: 'vedanta',
  vedante: 'vedante',
  vedaram: 'vedaram',
  vedarao: 'vedarão',
  vedaras: 'vedaras',
  vedarei: 'vedarei',
  vedarem: 'vedarem',
  vedares: 'vedares',
  vedaria: 'vedaria',
  vedarmo: 'vedarmo',
  vedasse: 'vedasse',
  vedaste: 'vedaste',
  vedavam: 'vedavam',
  vedavas: 'vedavas',
  vedavel: 'vedável',
  vedemos: 'vedemos',
  vedetas: 'vedetas',
  vedetes: 'vedetes',
  vedicas: 'védicas',
  vedicos: 'védicos',
  vedismo: 'vedismo',
  vedista: 'vedista',
  vedoias: 'vedoias',
  vedoide: 'vedoide',
  vedonho: 'vedonho',
  vedoras: 'vedoras',
  vedores: 'vedores',
  vedoria: 'vedoria',
  veeiras: 'veeiras',
  veeiros: 'veeiros',
  veemica: 'veêmica',
  veemico: 'veêmico',
  vegadas: 'vegadas',
  vegados: 'vegados',
  vegetai: 'vegetai',
  vegetal: 'vegetal',
  vegetam: 'vegetam',
  vegetar: 'vegetar',
  vegetas: 'vegetas',
  vegetei: 'vegetei',
  vegetem: 'vegetem',
  vegetes: 'vegetes',
  vegetos: 'végetos',
  vegetou: 'vegetou',
  veiadao: 'veiadão',
  veiadas: 'veiadas',
  veiador: 'veiador',
  veiados: 'veiados',
  veiamos: 'veiamos',
  veiando: 'veiando',
  veiante: 'veiante',
  veiaram: 'veiaram',
  veiarao: 'veiarão',
  veiaras: 'veiarás',
  veiarei: 'veiarei',
  veiarem: 'veiarem',
  veiares: 'veiares',
  veiaria: 'veiaria',
  veiarmo: 'veiarmo',
  veiasse: 'veiasse',
  veiaste: 'veiaste',
  veiavam: 'veiavam',
  veiavas: 'veiavas',
  veicula: 'veicula',
  veicule: 'veicule',
  veiculo: 'veículo',
  veieira: 'veieira',
  veiemos: 'veiemos',
  veiente: 'veiente',
  veigais: 'veigais',
  veirada: 'veirada',
  veirado: 'veirado',
  veiudas: 'veiudas',
  veiudos: 'veiudos',
  vejamos: 'vejamos',
  vejeira: 'vejeira',
  vejente: 'vejente',
  vejetes: 'vejetes',
  vejovis: 'vejóvis',
  velacao: 'velação',
  velacho: 'velacho',
  veladao: 'veladão',
  veladas: 'veladas',
  velador: 'velador',
  velados: 'velados',
  velamen: 'velâmen',
  velames: 'velames',
  velamos: 'velamos',
  velanca: 'velança',
  velando: 'velando',
  velanis: 'velanis',
  velante: 'velante',
  velaram: 'velaram',
  velarao: 'velarão',
  velaras: 'velaras',
  velarei: 'velarei',
  velarem: 'velarem',
  velares: 'velares',
  velaria: 'velaria',
  velario: 'velário',
  velarmo: 'velarmo',
  velasse: 'velasse',
  velaste: 'velaste',
  velauna: 'velauna',
  velauno: 'velauno',
  velavam: 'velavam',
  velavas: 'velavas',
  velavos: 'velavos',
  velcros: 'velcros',
  veleada: 'veleada',
  veleado: 'veleado',
  veleais: 'veleais',
  veleara: 'veleará',
  veleava: 'veleava',
  veleeis: 'veleeis',
  veleiam: 'veleiam',
  veleias: 'veleias',
  veleiem: 'veleiem',
  veleies: 'veleies',
  veleira: 'veleira',
  veleiro: 'veleiro',
  velejai: 'velejai',
  velejam: 'velejam',
  velejar: 'velejar',
  velejas: 'velejas',
  velejei: 'velejei',
  velejem: 'velejem',
  velejes: 'velejes',
  velejou: 'velejou',
  velelas: 'velelas',
  velemos: 'velemos',
  velenho: 'velenho',
  velense: 'velense',
  veletas: 'veletas',
  veletes: 'veletes',
  veletos: 'veletos',
  velezia: 'velézia',
  velhaca: 'velhaca',
  velhaco: 'velhaco',
  velhada: 'velhada',
  velhice: 'velhice',
  velhoes: 'velhões',
  velhona: 'velhona',
  velhori: 'velhori',
  velhota: 'velhota',
  velhote: 'velhote',
  velhoto: 'velhoto',
  velicai: 'velicai',
  velicam: 'velicam',
  velicar: 'velicar',
  velicas: 'velicas',
  velicou: 'velicou',
  velidas: 'velidas',
  velidea: 'velídea',
  velideo: 'velídeo',
  velidos: 'velidos',
  veliger: 'véliger',
  veliida: 'velíida',
  velilho: 'velilho',
  velinas: 'velinas',
  velinha: 'velinha',
  velinos: 'velinos',
  velique: 'velique',
  velisca: 'velisca',
  velites: 'vélites',
  veloces: 'veloces',
  veloria: 'velória',
  velorio: 'velório',
  velosas: 'velosas',
  velosia: 'velósia',
  velosos: 'velosos',
  velozes: 'velozes',
  velozia: 'velózia',
  velucia: 'velúcia',
  veludai: 'veludai',
  veludam: 'veludam',
  veludar: 'veludar',
  veludas: 'veludas',
  veludei: 'veludei',
  veludem: 'veludem',
  veludes: 'veludes',
  veludos: 'veludos',
  veludou: 'veludou',
  velutai: 'velutai',
  velutam: 'velutam',
  velutar: 'velutar',
  velutas: 'velutas',
  velutei: 'velutei',
  velutem: 'velutem',
  velutes: 'velutes',
  velutou: 'velutou',
  venablo: 'venablo',
  venacao: 'venação',
  venadas: 'venadas',
  venador: 'venador',
  venados: 'venados',
  venamas: 'venamas',
  venamos: 'venamos',
  venaria: 'venária',
  venario: 'venário',
  vencais: 'vençais',
  vencamo: 'vençamo',
  venceis: 'venceis',
  vencemo: 'vencemo',
  vencera: 'vencera',
  venciam: 'venciam',
  vencias: 'vencias',
  vencida: 'vencida',
  vencido: 'vencido',
  vendada: 'vendada',
  vendado: 'vendado',
  vendais: 'vendais',
  vendamo: 'vendamo',
  vendara: 'vendara',
  vendava: 'vendava',
  vendeis: 'vendeis',
  vendemo: 'vendemo',
  vendera: 'vendera',
  vendeta: 'vendeta',
  vendiam: 'vendiam',
  vendias: 'vendias',
  vendica: 'vêndica',
  vendico: 'vêndico',
  vendida: 'vendida',
  vendido: 'vendido',
  vendola: 'vendola',
  vendudo: 'vendudo',
  venedas: 'vênedas',
  venedos: 'vênedos',
  venelas: 'venelas',
  venelos: 'venelos',
  venenai: 'venenai',
  venenam: 'venenam',
  venenar: 'venenar',
  venenas: 'venenas',
  venenei: 'venenei',
  venenem: 'venenem',
  venenes: 'venenes',
  venenos: 'venenos',
  venenou: 'venenou',
  venense: 'venense',
  venerai: 'venerai',
  veneral: 'veneral',
  veneram: 'veneram',
  venerar: 'venerar',
  veneras: 'veneras',
  venerea: 'venérea',
  venerei: 'venerei',
  venerem: 'venerem',
  venereo: 'venéreo',
  veneres: 'veneres',
  veneros: 'véneros',
  venerou: 'venerou',
  venetas: 'venetas',
  venetos: 'vênetos',
  venhais: 'venhais',
  venhamo: 'venhamo',
  veniaga: 'veniaga',
  veniago: 'veniago',
  veniais: 'veniais',
  venidas: 'venidas',
  venidio: 'venídio',
  venosas: 'venosas',
  venosos: 'venosos',
  venoste: 'venoste',
  ventada: 'ventada',
  ventado: 'ventado',
  ventage: 'ventage',
  ventais: 'ventais',
  ventamo: 'ventamo',
  ventana: 'ventana',
  ventara: 'ventara',
  ventava: 'ventava',
  venteis: 'venteis',
  ventemo: 'ventemo',
  ventena: 'ventena',
  ventila: 'ventila',
  ventile: 'ventile',
  ventilo: 'ventilo',
  ventora: 'ventora',
  ventosa: 'ventosa',
  ventoso: 'ventoso',
  ventral: 'ventral',
  ventres: 'ventres',
  ventril: 'ventril',
  ventulo: 'vêntulo',
  ventura: 'ventura',
  venture: 'venture',
  venturo: 'venturo',
  ventusa: 'ventusa',
  venulas: 'vênulas',
  venusio: 'venúsio',
  venusta: 'venusta',
  venusto: 'venusto',
  veragra: 'veragra',
  veragro: 'veragro',
  veranal: 'veranal',
  veranea: 'veraneá',
  verania: 'verânia',
  veranil: 'veranil',
  veranis: 'veranis',
  veraria: 'verária',
  veratro: 'veratro',
  verazes: 'verazes',
  verbais: 'verbais',
  verbeja: 'verbejá',
  verbeje: 'verbeje',
  verbejo: 'verbejo',
  verbena: 'verbena',
  verbera: 'verbera',
  verbere: 'verbere',
  verbero: 'verbero',
  verbeta: 'verbeta',
  verbete: 'verbete',
  verbeto: 'verbeto',
  verbosa: 'verbosa',
  verboso: 'verboso',
  vercuda: 'verçuda',
  vercudo: 'verçudo',
  verdade: 'verdade',
  verdais: 'verdais',
  verdeai: 'verdeai',
  verdeal: 'verdeal',
  verdear: 'verdear',
  verdeca: 'verdeça',
  verdece: 'verdece',
  verdeci: 'verdeci',
  verdeco: 'verdeço',
  verdeei: 'verdeei',
  verdega: 'verdegá',
  verdego: 'verdego',
  verdeia: 'verdeia',
  verdeie: 'verdeie',
  verdeio: 'verdeio',
  verdeja: 'verdeja',
  verdeje: 'verdeje',
  verdejo: 'verdejo',
  verdema: 'verdemã',
  verdeou: 'verdeou',
  verdete: 'verdete',
  verdial: 'verdial',
  verdica: 'vérdica',
  verdico: 'vérdico',
  verdino: 'verdino',
  verdito: 'verdito',
  verdoes: 'verdões',
  verdora: 'verdora',
  verdosa: 'verdosa',
  verdoso: 'verdoso',
  verdote: 'verdote',
  verdugo: 'verdugo',
  verdume: 'verdume',
  verduns: 'verduns',
  verdura: 'verdura',
  vereada: 'vereada',
  vereado: 'vereado',
  vereais: 'vereais',
  vereara: 'vereara',
  vereava: 'vereava',
  veredas: 'veredas',
  vereeis: 'vereeis',
  vereiam: 'vereiam',
  vereias: 'vereias',
  vereiem: 'vereiem',
  vereies: 'vereies',
  veremos: 'veremos',
  verenas: 'verenas',
  verenda: 'verenda',
  verendo: 'verendo',
  verenos: 'verenos',
  verense: 'verense',
  vereque: 'vereque',
  vergada: 'vergada',
  vergado: 'vergado',
  vergais: 'vergais',
  vergame: 'vergame',
  vergara: 'vergara',
  vergava: 'vergava',
  vergeis: 'vergéis',
  vergoes: 'vergões',
  verguei: 'verguei',
  verguem: 'verguem',
  vergues: 'vergues',
  verguia: 'verguia',
  verguio: 'verguio',
  verguna: 'verguna',
  verguno: 'verguno',
  verieis: 'veríeis',
  verilha: 'verilha',
  verilos: 'verilos',
  verinas: 'verinas',
  verismo: 'verismo',
  verista: 'verista',
  veritos: 'veritos',
  verlete: 'verlete',
  verlite: 'verlite',
  verlito: 'verlito',
  vermeil: 'vermeil',
  vermens: 'vermens',
  vermeto: 'vermeto',
  vermica: 'vérmica',
  vermico: 'vérmico',
  vermina: 'vermina',
  vermine: 'vermine',
  vermino: 'vermino',
  vermita: 'vermitá',
  vermite: 'vermite',
  vermito: 'vermito',
  vermute: 'vermute',
  vermuto: 'vermuto',
  vernada: 'vernada',
  vernado: 'vernado',
  vernais: 'vernais',
  vernamo: 'vernamo',
  vernara: 'vernará',
  vernava: 'vernava',
  verneis: 'verneis',
  vernemo: 'vernemo',
  vernias: 'vérnias',
  vernier: 'vernier',
  vernina: 'vernina',
  vernons: 'vernons',
  veronal: 'veronal',
  verones: 'veronês',
  verrina: 'verrina',
  verrine: 'verrine',
  verrino: 'verrino',
  verruca: 'verruca',
  verruga: 'verruga',
  verrugo: 'verrugo',
  verruma: 'verruma',
  verrume: 'verrume',
  verrumo: 'verrumo',
  versada: 'versada',
  versado: 'versado',
  versais: 'versais',
  versamo: 'versamo',
  versara: 'versara',
  versava: 'versava',
  verseis: 'verseis',
  verseja: 'verseja',
  verseje: 'verseje',
  versejo: 'versejo',
  versemo: 'versemo',
  verseto: 'verseto',
  versiva: 'versiva',
  versivo: 'versivo',
  versoes: 'versões',
  versona: 'versona',
  versora: 'versora',
  verstas: 'verstas',
  verstes: 'verstes',
  versuda: 'versuda',
  versudo: 'versudo',
  versura: 'versura',
  versuta: 'versuta',
  versuto: 'versuto',
  vertais: 'vertais',
  vertamo: 'vertamo',
  verteas: 'verteás',
  verteis: 'verteis',
  vertemo: 'vertemo',
  vertera: 'vertera',
  vertiam: 'vertiam',
  vertias: 'vertias',
  vertice: 'vértice',
  vertida: 'vertida',
  vertido: 'vertido',
  verunas: 'verunas',
  verunos: 'verunos',
  verutos: 'verutos',
  vesanas: 'vesanas',
  vesania: 'vesânia',
  vesanos: 'vesanos',
  vesbina: 'vesbina',
  vescias: 'véscias',
  vescina: 'vescina',
  vescino: 'vescino',
  veseris: 'véseris',
  vesguea: 'vesgueá',
  vesicai: 'vesicai',
  vesical: 'vesical',
  vesicam: 'vesicam',
  vesicar: 'vesicar',
  vesicas: 'vesicas',
  vesicou: 'vesicou',
  vesique: 'vesique',
  vespera: 'véspera',
  vespero: 'véspero',
  vespica: 'vespiça',
  vespida: 'véspida',
  vespoes: 'vespões',
  vespula: 'véspula',
  vessada: 'vessada',
  vessado: 'vessado',
  vessais: 'vessais',
  vessamo: 'vessamo',
  vessara: 'vessará',
  vessava: 'vessava',
  vesseis: 'vesseis',
  vessemo: 'vessemo',
  vestada: 'vestada',
  vestado: 'vestado',
  vestais: 'vestais',
  vestamo: 'vestamo',
  vestara: 'vestará',
  vestava: 'vestava',
  vesteis: 'vesteis',
  vestemo: 'vestemo',
  vestiam: 'vestiam',
  vestias: 'vestias',
  vestida: 'vestida',
  vestido: 'vestido',
  vestigo: 'vestigo',
  vestimo: 'vestimo',
  vestina: 'vestina',
  vestino: 'vestino',
  vestira: 'vestira',
  vesugos: 'vesugos',
  vesuvio: 'vesúvio',
  vetacao: 'vetação',
  vetadao: 'vetadão',
  vetadas: 'vetadas',
  vetador: 'vetador',
  vetados: 'vetados',
  vetamos: 'vetamos',
  vetando: 'vetando',
  vetante: 'vetante',
  vetaram: 'vetaram',
  vetarao: 'vetarão',
  vetaras: 'vetaras',
  vetarei: 'vetarei',
  vetarem: 'vetarem',
  vetares: 'vetares',
  vetaria: 'vetaria',
  vetarmo: 'vetarmo',
  vetasse: 'vetasse',
  vetaste: 'vetaste',
  vetavam: 'vetavam',
  vetavas: 'vetavas',
  vetavel: 'vetável',
  vetemos: 'vetemos',
  vetilas: 'vetilas',
  vetinho: 'vetinho',
  vetiver: 'vetiver',
  vetores: 'vetores',
  veturio: 'vetúrio',
  vetusta: 'vetusta',
  vetusto: 'vetusto',
  vevuias: 'vevuias',
  vexacao: 'vexação',
  vexadao: 'vexadão',
  vexadas: 'vexadas',
  vexador: 'vexador',
  vexados: 'vexados',
  vexames: 'vexames',
  vexamos: 'vexamos',
  vexando: 'vexando',
  vexante: 'vexante',
  vexaram: 'vexaram',
  vexarao: 'vexarão',
  vexaras: 'vexaras',
  vexarei: 'vexarei',
  vexarem: 'vexarem',
  vexares: 'vexares',
  vexaria: 'vexaria',
  vexarmo: 'vexarmo',
  vexasse: 'vexasse',
  vexaste: 'vexaste',
  vexavam: 'vexavam',
  vexavas: 'vexavas',
  vexavel: 'vexável',
  vexemos: 'vexemos',
  vexilar: 'vexilar',
  vexilos: 'vexilos',
  vezadao: 'vezadão',
  vezadas: 'vezadas',
  vezador: 'vezador',
  vezados: 'vezados',
  vezamos: 'vezamos',
  vezando: 'vezando',
  vezante: 'vezante',
  vezaram: 'vezaram',
  vezarao: 'vezarão',
  vezaras: 'vezarás',
  vezarei: 'vezarei',
  vezarem: 'vezarem',
  vezares: 'vezares',
  vezaria: 'vezaria',
  vezarmo: 'vezarmo',
  vezasse: 'vezasse',
  vezaste: 'vezaste',
  vezavam: 'vezavam',
  vezavas: 'vezavas',
  vezeira: 'vezeira',
  vezeiro: 'vezeiro',
  vezemos: 'vezemos',
  vezeras: 'vézeras',
  vezinha: 'vezinha',
  vezinho: 'vezinho',
  viacoes: 'viações',
  viacona: 'viaçona',
  viadora: 'viadora',
  viaduto: 'viaduto',
  viageai: 'viageai',
  viagear: 'viagear',
  viageei: 'viageei',
  viageia: 'viageia',
  viageie: 'viageie',
  viageio: 'viageio',
  viagens: 'viagens',
  viageou: 'viageou',
  viagras: 'viagras',
  viajada: 'viajada',
  viajado: 'viajado',
  viajais: 'viajais',
  viajamo: 'viajamo',
  viajara: 'viajara',
  viajata: 'viajata',
  viajava: 'viajava',
  viajeis: 'viajeis',
  viajemo: 'viajemo',
  viajoes: 'viajões',
  vialzao: 'vialzão',
  viandai: 'viandai',
  viandam: 'viandam',
  viandar: 'viandar',
  viandas: 'viandas',
  viandei: 'viandei',
  viandem: 'viandem',
  viandes: 'viandes',
  viandou: 'viandou',
  vianesa: 'vianesa',
  vianona: 'vianona',
  viantes: 'viantes',
  viarias: 'viárias',
  viarios: 'viários',
  viascas: 'viascas',
  viascos: 'viascos',
  viatica: 'viática',
  viatico: 'viático',
  viatura: 'viatura',
  viaveis: 'viáveis',
  vibelas: 'vibelas',
  vibelos: 'vibelos',
  viberas: 'viberas',
  viberos: 'viberos',
  vibices: 'víbices',
  vibidea: 'vibídea',
  vibilia: 'vibília',
  viborao: 'viborão',
  viboras: 'víboras',
  vibordo: 'vibordo',
  vibrada: 'vibrada',
  vibrado: 'vibrado',
  vibrais: 'vibrais',
  vibramo: 'vibramo',
  vibrara: 'vibrara',
  vibrato: 'vibrato',
  vibrava: 'vibrava',
  vibreis: 'vibreis',
  vibremo: 'vibremo',
  vibriao: 'vibrião',
  vibrios: 'víbrios',
  viburno: 'viburno',
  vibutes: 'vibutes',
  vicacia: 'vicácia',
  vicadao: 'viçadão',
  vicadas: 'viçadas',
  vicador: 'viçador',
  vicados: 'viçados',
  vicaloi: 'vicalói',
  vicamos: 'viçamos',
  vicando: 'viçando',
  vicante: 'viçante',
  vicaram: 'viçaram',
  vicarao: 'viçarão',
  vicaras: 'viçarás',
  vicarei: 'viçarei',
  vicarem: 'viçarem',
  vicares: 'viçares',
  vicaria: 'vicaria',
  vicario: 'vicário',
  vicarmo: 'viçarmo',
  vicasse: 'viçasse',
  vicaste: 'viçaste',
  vicavam: 'viçavam',
  vicavas: 'viçavas',
  vicejai: 'vicejai',
  vicejam: 'vicejam',
  vicejar: 'vicejar',
  vicejas: 'vicejas',
  vicejei: 'vicejei',
  vicejem: 'vicejem',
  vicejes: 'vicejes',
  vicejos: 'vicejos',
  vicejou: 'vicejou',
  vicemos: 'vicemos',
  vicenal: 'vicenal',
  vicenio: 'vicênio',
  vicente: 'vicente',
  viciada: 'viciada',
  viciado: 'viciado',
  viciais: 'viciais',
  viciamo: 'viciamo',
  viciara: 'viciara',
  viciava: 'viciava',
  vicieis: 'vicieis',
  viciemo: 'viciemo',
  vicinal: 'vicinal',
  vicinas: 'vicinas',
  viciosa: 'viciosa',
  vicioso: 'vicioso',
  vicoita: 'vicoíta',
  vicoite: 'vicoíte',
  vicosas: 'viçosas',
  vicosos: 'viçosos',
  victron: 'victron',
  vicuiba: 'vicuíba',
  vicunha: 'vicunha',
  vidacas: 'vidaças',
  vidacos: 'vidaços',
  vidadao: 'vidadão',
  vidadas: 'vidadas',
  vidador: 'vidador',
  vidados: 'vidados',
  vidagos: 'vidagos',
  vidalas: 'vidalas',
  vidamas: 'vidamas',
  vidamia: 'vidamia',
  vidamos: 'vidamos',
  vidanas: 'vidanas',
  vidando: 'vidando',
  vidania: 'vidania',
  vidante: 'vidante',
  vidaram: 'vidaram',
  vidarao: 'vidarão',
  vidaras: 'vidarás',
  vidarei: 'vidarei',
  vidarem: 'vidarem',
  vidares: 'vidares',
  vidaria: 'vidaria',
  vidarmo: 'vidarmo',
  vidasse: 'vidasse',
  vidaste: 'vidaste',
  vidavam: 'vidavam',
  vidavas: 'vidavas',
  videada: 'videada',
  videado: 'videado',
  videais: 'videais',
  videara: 'videará',
  videava: 'videava',
  videeis: 'videeis',
  videiam: 'videiam',
  videias: 'videias',
  videiem: 'videiem',
  videies: 'videies',
  videira: 'videira',
  videiro: 'videiro',
  videmos: 'videmos',
  vidente: 'vidente',
  vidiana: 'vidiana',
  vidiano: 'vidiano',
  vidicon: 'vidicon',
  vidinha: 'vidinha',
  vidocas: 'vidocas',
  vidoiro: 'vidoiro',
  vidonha: 'vidonha',
  vidonho: 'vidonho',
  vidoras: 'vidoras',
  vidores: 'vidores',
  vidouro: 'vidouro',
  vidraca: 'vidraça',
  vidraco: 'vidraço',
  vidrada: 'vidrada',
  vidrado: 'vidrado',
  vidrais: 'vidrais',
  vidramo: 'vidramo',
  vidrara: 'vidrara',
  vidrava: 'vidrava',
  vidreis: 'vidreis',
  vidremo: 'vidremo',
  vidrina: 'vidrina',
  vidrino: 'vidrino',
  vidrios: 'vidrios',
  vidroes: 'vidrões',
  vidrosa: 'vidrosa',
  vidroso: 'vidroso',
  viduais: 'viduais',
  vieiras: 'vieiras',
  vieiros: 'vieiros',
  vienesa: 'vienesa',
  vierdes: 'vierdes',
  viereis: 'viéreis',
  vierina: 'vierina',
  viermos: 'viermos',
  viessem: 'viessem',
  viesses: 'viesses',
  viestes: 'viestes',
  vigadao: 'vigadão',
  vigadas: 'vigadas',
  vigador: 'vigador',
  vigados: 'vigados',
  vigairo: 'vigairo',
  vigamos: 'vigamos',
  vigando: 'vigando',
  vigante: 'vigante',
  vigaram: 'vigaram',
  vigarao: 'vigarão',
  vigaras: 'vigarás',
  vigarei: 'vigarei',
  vigarem: 'vigarem',
  vigares: 'vigares',
  vigaria: 'vigária',
  vigario: 'vigário',
  vigasse: 'vigasse',
  vigaste: 'vigaste',
  vigavam: 'vigavam',
  vigavas: 'vigavas',
  vigedor: 'vigedor',
  vigemos: 'vigemos',
  vigendo: 'vigendo',
  vigenel: 'vigenel',
  vigente: 'vigente',
  vigeram: 'vigeram',
  vigerao: 'vigerão',
  vigeras: 'vigeras',
  vigerei: 'vigerei',
  vigerem: 'vigerem',
  vigeres: 'vigeres',
  vigeria: 'vigeria',
  vigermo: 'vigermo',
  vigesse: 'vigesse',
  vigeste: 'vigeste',
  vigiada: 'vigiada',
  vigiado: 'vigiado',
  vigiais: 'vigiais',
  vigiamo: 'vigiamo',
  vigiara: 'vigiara',
  vigiava: 'vigiava',
  vigidao: 'vigidão',
  vigidas: 'vigidas',
  vigidos: 'vigidos',
  vigieis: 'vigieis',
  vigiemo: 'vigiemo',
  vigilai: 'vigilai',
  vigilam: 'vigilam',
  vigilar: 'vigilar',
  vigilas: 'vigilas',
  vigilei: 'vigilei',
  vigilem: 'vigilem',
  vigiles: 'vigiles',
  vigilia: 'vigília',
  vigilos: 'vígilos',
  vigilou: 'vigilou',
  vignita: 'vignita',
  vignite: 'vignite',
  vignito: 'vignito',
  vigonho: 'vigonho',
  vigorai: 'vigorai',
  vigoram: 'vigoram',
  vigorar: 'vigorar',
  vigoras: 'vigoras',
  vigorei: 'vigorei',
  vigorem: 'vigorem',
  vigores: 'vigores',
  vigorou: 'vigorou',
  vigotas: 'vigotas',
  vigotes: 'vigotes',
  vigueis: 'vigueis',
  viguesa: 'viguesa',
  vijamos: 'vijamos',
  vikings: 'vikings',
  vilagem: 'vilagem',
  vilanaz: 'vilanaz',
  vilania: 'vilania',
  vilares: 'vilares',
  vildade: 'vildade',
  vilecos: 'vilecos',
  vilelas: 'vilelas',
  vilesca: 'vilesça',
  vilesce: 'vilesce',
  vilesci: 'vilesci',
  vilesco: 'vilesço',
  viletas: 'viletas',
  vilezas: 'vilezas',
  viliada: 'viliada',
  viliado: 'viliado',
  viliais: 'viliais',
  viliamo: 'viliamo',
  viliara: 'viliará',
  viliava: 'viliava',
  vilicai: 'vilicai',
  vilicam: 'vilicam',
  vilicar: 'vilicar',
  vilicas: 'vilicas',
  vilicos: 'vilicos',
  vilicou: 'vilicou',
  vilieis: 'vilieis',
  viliemo: 'viliemo',
  vilinha: 'vilinha',
  vilique: 'vilique',
  vilnita: 'vilnita',
  vilnito: 'vilnito',
  vilocas: 'vilocas',
  vilomas: 'vilomas',
  viloria: 'vilória',
  vilorio: 'vilório',
  vilosas: 'vilosas',
  vilosos: 'vilosos',
  vilotas: 'vilotas',
  viltosa: 'viltosa',
  viltoso: 'viltoso',
  viluita: 'viluíta',
  viluite: 'viluíte',
  vilulas: 'vílulas',
  vimadao: 'vimadão',
  vimadas: 'vimadas',
  vimador: 'vimador',
  vimados: 'vimados',
  vimamos: 'vimamos',
  vimanas: 'vimanas',
  vimando: 'vimando',
  vimante: 'vimante',
  vimaram: 'vimaram',
  vimarao: 'vimarão',
  vimaras: 'vimarás',
  vimarei: 'vimarei',
  vimarem: 'vimarem',
  vimares: 'vimares',
  vimaria: 'vimaria',
  vimarmo: 'vimarmo',
  vimasse: 'vimasse',
  vimaste: 'vimaste',
  vimavam: 'vimavam',
  vimavas: 'vimavas',
  vimeira: 'vimeira',
  vimeiro: 'vimeiro',
  vimemos: 'vimemos',
  vimiais: 'vimiais',
  viminas: 'viminas',
  viminea: 'vimínea',
  vimineo: 'vimíneo',
  viminol: 'viminol',
  vimosas: 'vimosas',
  vimosos: 'vimosos',
  vinacea: 'vinácea',
  vinaceo: 'vináceo',
  vinagem: 'vinagem',
  vinagos: 'vinagos',
  vinagra: 'vinagra',
  vinagre: 'vinagre',
  vinagro: 'vinagro',
  vinaias: 'vinaias',
  vinaria: 'vinária',
  vinario: 'vinário',
  vinatos: 'vinatos',
  vincada: 'vincada',
  vincado: 'vincado',
  vincais: 'vincais',
  vincamo: 'vincamo',
  vincara: 'vincara',
  vincava: 'vincava',
  vincios: 'víncios',
  vincula: 'vincula',
  vincule: 'vincule',
  vinculo: 'vínculo',
  vindica: 'vindicá',
  vindice: 'víndice',
  vindico: 'vindiço',
  vindima: 'vindima',
  vindime: 'vindime',
  vindimo: 'vindimo',
  vindita: 'vindita',
  vingada: 'vingada',
  vingado: 'vingado',
  vingais: 'vingais',
  vingamo: 'vingamo',
  vingara: 'vingara',
  vingava: 'vingava',
  vinguei: 'vinguei',
  vinguem: 'vinguem',
  vingues: 'vingues',
  vinhaca: 'vinhaça',
  vinhaco: 'vinhaço',
  vinhada: 'vinhada',
  vinhado: 'vinhado',
  vinhaga: 'vinhaga',
  vinhago: 'vinhago',
  vinhais: 'vinhais',
  vinhame: 'vinhame',
  vinhamo: 'vinhamo',
  vinhara: 'vinhara',
  vinhato: 'vinhato',
  vinhatu: 'vinhatu',
  vinhava: 'vinhava',
  vinheca: 'vinheca',
  vinhedo: 'vinhedo',
  vinheis: 'vínheis',
  vinhemo: 'vinhemo',
  vinheta: 'vinheta',
  vinhete: 'vinhete',
  vinhoca: 'vinhoca',
  vinhoco: 'vinhoco',
  vinhogo: 'vinhogo',
  vinhosa: 'vinhosa',
  vinhoso: 'vinhoso',
  vinhote: 'vinhote',
  vinhoto: 'vinhoto',
  vinhuca: 'vinhuça',
  viniaga: 'viniaga',
  viniais: 'viniais',
  vinicas: 'vínicas',
  vinicos: 'vínicos',
  vinilas: 'vinilas',
  vinilos: 'vinilos',
  vinions: 'vinions',
  vinosas: 'vinosas',
  vinosos: 'vinosos',
  vinquei: 'vinquei',
  vinquem: 'vinquem',
  vinques: 'vinques',
  vintage: 'vintage',
  vintavo: 'vintavo',
  vintena: 'vintena',
  vinteno: 'vinteno',
  vintens: 'vinténs',
  vintoes: 'vintões',
  vintona: 'vintona',
  viocuro: 'viocuro',
  violada: 'violada',
  violado: 'violado',
  violais: 'violais',
  violale: 'violale',
  violamo: 'violamo',
  violana: 'violana',
  violara: 'violara',
  violato: 'violato',
  violava: 'violava',
  violeas: 'vióleas',
  violebo: 'violebo',
  violeis: 'violeis',
  violemo: 'violemo',
  violeos: 'vióleos',
  violeta: 'violeta',
  violete: 'violete',
  violica: 'viólica',
  violico: 'viólico',
  violina: 'violina',
  violine: 'violine',
  violino: 'violino',
  violita: 'violita',
  violles: 'violles',
  violoes: 'violões',
  viomais: 'viomais',
  viperas: 'víperas',
  viperea: 'vipérea',
  vipereo: 'vipéreo',
  viperos: 'víperos',
  vipomas: 'vipomas',
  viquita: 'viquita',
  viracao: 'viração',
  viradao: 'viradão',
  viradas: 'viradas',
  virador: 'virador',
  virados: 'virados',
  viragem: 'viragem',
  viragos: 'viragos',
  virales: 'virales',
  viramas: 'viramas',
  viramos: 'viramos',
  virando: 'virando',
  virante: 'virante',
  viraram: 'viraram',
  virarao: 'virarão',
  viraras: 'viraras',
  virarei: 'virarei',
  virarem: 'virarem',
  virares: 'virares',
  viraria: 'viraria',
  virarmo: 'virarmo',
  virarus: 'virarus',
  virasse: 'virasse',
  viraste: 'viraste',
  viratao: 'viratão',
  viravam: 'viravam',
  viravas: 'viravas',
  viravos: 'viravós',
  virbias: 'vírbias',
  virelai: 'virelai',
  viremia: 'viremia',
  viremos: 'viremos',
  virente: 'virente',
  virgada: 'virgada',
  virgado: 'virgado',
  virgens: 'virgens',
  virgina: 'virgina',
  virgosa: 'virgosa',
  virgoso: 'virgoso',
  virgula: 'vírgula',
  virgule: 'virgule',
  virgulo: 'virgulo',
  viriais: 'viriais',
  viriato: 'viriato',
  viricas: 'víricas',
  viricos: 'víricos',
  virides: 'vírides',
  viridez: 'viridez',
  viridul: 'viridul',
  virieis: 'viríeis',
  virilha: 'virilha',
  virions: 'virions',
  viroide: 'viroide',
  virolas: 'virolas',
  virosas: 'virosas',
  virosca: 'virosca',
  viroses: 'viroses',
  virosos: 'virosos',
  virotao: 'virotão',
  virotes: 'virotes',
  virtais: 'virtais',
  virtiga: 'virtiga',
  virtigo: 'virtigo',
  virtuai: 'virtuai',
  virtual: 'virtual',
  virtuam: 'virtuam',
  virtuar: 'virtuar',
  virtuas: 'virtuas',
  virtude: 'virtude',
  virtuei: 'virtuei',
  virtuem: 'virtuem',
  virtues: 'virtues',
  virtuou: 'virtuou',
  virucus: 'viruçus',
  virutas: 'virutas',
  visacea: 'visácea',
  visaceo: 'visáceo',
  visadao: 'visadão',
  visadas: 'visadas',
  visador: 'visador',
  visados: 'visados',
  visagem: 'visagem',
  visagia: 'viságia',
  visagra: 'visagra',
  visaias: 'visaias',
  visaios: 'visaios',
  visamos: 'visamos',
  visando: 'visando',
  visante: 'visante',
  visaram: 'visaram',
  visarao: 'visarão',
  visaras: 'visaras',
  visarei: 'visarei',
  visarem: 'visarem',
  visares: 'visares',
  visarga: 'visarga',
  visaria: 'visaria',
  visarma: 'visarma',
  visarmo: 'visarmo',
  visasse: 'visasse',
  visaste: 'visaste',
  visavam: 'visavam',
  visavas: 'visavas',
  viscada: 'viscada',
  viscado: 'viscado',
  viscais: 'viscais',
  viscamo: 'viscamo',
  viscara: 'viscara',
  viscava: 'viscava',
  visceas: 'vísceas',
  visceno: 'visceno',
  visceos: 'vísceos',
  viscera: 'víscera',
  viscida: 'víscida',
  viscido: 'víscido',
  viscina: 'viscina',
  viscosa: 'viscosa',
  viscose: 'viscose',
  viscoso: 'viscoso',
  viseana: 'viseana',
  viseano: 'viseano',
  viseira: 'viseira',
  viseita: 'viseíta',
  visemos: 'visemos',
  visgada: 'visgada',
  visgado: 'visgado',
  visgais: 'visgais',
  visgara: 'visgará',
  visgava: 'visgava',
  visgosa: 'visgosa',
  visgoso: 'visgoso',
  visgoto: 'visgoto',
  visguei: 'visguei',
  visguem: 'visguem',
  visgues: 'visgues',
  visibil: 'visíbil',
  visibis: 'visíbis',
  visiona: 'visiona',
  visione: 'visione',
  visiono: 'visiono',
  visitai: 'visitai',
  visitam: 'visitam',
  visitar: 'visitar',
  visitas: 'visitas',
  visitei: 'visitei',
  visitem: 'visitem',
  visites: 'visites',
  visitou: 'visitou',
  visivas: 'visivas',
  visivel: 'visível',
  visivos: 'visivos',
  vismeas: 'vísmeas',
  vismeos: 'vísmeos',
  vismias: 'vísmias',
  visneas: 'vísneas',
  visonas: 'visonas',
  visonha: 'visonha',
  visoras: 'visoras',
  visores: 'visores',
  visoria: 'visória',
  visorio: 'visório',
  vispada: 'vispada',
  vispado: 'vispado',
  vispais: 'vispais',
  vispamo: 'vispamo',
  vispara: 'vispará',
  vispava: 'vispava',
  vispeis: 'vispeis',
  vispemo: 'vispemo',
  vispera: 'vispera',
  vispere: 'víspere',
  vispero: 'vispero',
  vispora: 'víspora',
  vispore: 'vispore',
  visporo: 'visporo',
  visquei: 'visquei',
  visquem: 'visquem',
  visques: 'visques',
  visseis: 'vísseis',
  vissias: 'vissiás',
  vistaco: 'vistaço',
  vistada: 'vistada',
  vistado: 'vistado',
  vistais: 'vistais',
  vistamo: 'vistamo',
  vistano: 'vistano',
  vistara: 'vistara',
  vistava: 'vistava',
  visteis: 'visteis',
  vistemo: 'vistemo',
  vistoes: 'vistões',
  vistora: 'vistora',
  vistosa: 'vistosa',
  vistoso: 'vistoso',
  visuais: 'visuais',
  vitacea: 'vitácea',
  vitaceo: 'vitáceo',
  vitalio: 'vitálio',
  vitanda: 'vitanda',
  vitando: 'vitando',
  vitaria: 'vitária',
  vitavel: 'vitável',
  vitelao: 'vitelão',
  vitelas: 'vitelas',
  viteles: 'víteles',
  vitelos: 'vitelos',
  vitiana: 'vitiana',
  vitiano: 'vitiano',
  vitibas: 'vitibás',
  viticea: 'vitícea',
  vitices: 'vítices',
  vitimai: 'vitimai',
  vitimam: 'vitimam',
  vitimar: 'vitimar',
  vitimas: 'vítimas',
  vitimei: 'vitimei',
  vitimem: 'vitimem',
  vitimes: 'vitimes',
  vitimou: 'vitimou',
  vitinas: 'vitinas',
  vitinga: 'vitinga',
  vitinha: 'vitinha',
  vitinho: 'vitinho',
  vitirie: 'vitiriê',
  vitoras: 'vítoras',
  vitores: 'vítores',
  vitoria: 'vitória',
  vitorie: 'vitorie',
  vitorio: 'vitório',
  vitrais: 'vitrais',
  vitreas: 'vítreas',
  vitreos: 'vítreos',
  vitrias: 'vítrias',
  vitrice: 'vítrice',
  vitrina: 'vitrina',
  vitrine: 'vitrine',
  vitrita: 'vitrita',
  vitrito: 'vitrito',
  vitrola: 'vitrola',
  vitrosa: 'vitrosa',
  vitroso: 'vitroso',
  vitular: 'vitular',
  vitulos: 'vítulos',
  viuvada: 'viuvada',
  viuvado: 'viuvado',
  viuvais: 'viuvais',
  viuvamo: 'viuvamo',
  viuvara: 'viuvara',
  viuvava: 'viuvava',
  viuveis: 'viuveis',
  viuvemo: 'viuvemo',
  viuveza: 'viuveza',
  viuvice: 'viuvice',
  vivacas: 'vivaças',
  vivaces: 'vivaces',
  vivacos: 'vivaços',
  vivadao: 'vivadão',
  vivadas: 'vivadas',
  vivador: 'vivador',
  vivados: 'vivados',
  vivalha: 'vivalhá',
  vivalhe: 'vivalhe',
  vivalho: 'vivalho',
  vivalma: 'vivalma',
  vivamos: 'vivamos',
  vivando: 'vivando',
  vivante: 'vivante',
  vivaram: 'vivaram',
  vivarao: 'vivarão',
  vivaras: 'vivarás',
  vivarei: 'vivarei',
  vivarem: 'vivarem',
  vivares: 'vivares',
  vivaria: 'vivaria',
  vivario: 'vivário',
  vivarmo: 'vivarmo',
  vivasse: 'vivasse',
  vivaste: 'vivaste',
  vivavam: 'vivavam',
  vivavas: 'vivavas',
  vivazes: 'vivazes',
  vivedor: 'vivedor',
  viveiro: 'viveiro',
  vivemos: 'vivemos',
  vivenca: 'vivença',
  vivenda: 'vivenda',
  vivendi: 'vivendi',
  vivendo: 'vivendo',
  viventa: 'viventa',
  vivente: 'vivente',
  vivento: 'vivento',
  viveram: 'viveram',
  viverao: 'viverão',
  viveras: 'viveras',
  viverei: 'viverei',
  viverem: 'viverem',
  viveres: 'víveres',
  viveria: 'viveria',
  vivermo: 'vivermo',
  viverra: 'viverra',
  vivesse: 'vivesse',
  viveste: 'viveste',
  vivezas: 'vivezas',
  viviana: 'viviana',
  viviano: 'viviano',
  vividao: 'vividão',
  vividas: 'vividas',
  vividez: 'vividez',
  vividos: 'vividos',
  vivieis: 'vivíeis',
  vivinha: 'vivinha',
  vivinho: 'vivinho',
  vivisca: 'vivisca',
  vivisco: 'vivisco',
  vivivel: 'vivível',
  vivoria: 'vivória',
  vivorio: 'vivório',
  vivulas: 'vívulas',
  vivungo: 'vivungo',
  vixudas: 'vixudas',
  vizeira: 'vizeira',
  vizeiro: 'vizeiro',
  vizinal: 'vizinal',
  vizinha: 'vizinha',
  vizinhe: 'vizinhe',
  vizinho: 'vizinho',
  vizires: 'vizires',
  vldlzao: 'vldlzão',
  voadoes: 'voadões',
  voadona: 'voadona',
  voadora: 'voadora',
  voadura: 'voadura',
  voaduru: 'voaduru',
  voagens: 'voagens',
  voantes: 'voantes',
  voardes: 'voardes',
  voareis: 'voareis',
  voaremo: 'voaremo',
  voariam: 'voariam',
  voarias: 'voarias',
  voarmos: 'voarmos',
  voassem: 'voassem',
  voasses: 'voasses',
  voastes: 'voastes',
  voaveis: 'voáveis',
  vobisco: 'vobisco',
  voborde: 'voborde',
  vocabro: 'vocabro',
  vocacao: 'vocação',
  vocacas: 'vocacas',
  vocates: 'vocates',
  voceros: 'voceros',
  vocoide: 'vocoide',
  vodunce: 'vodunce',
  vodunos: 'vodunôs',
  vodunsi: 'vodúnsi',
  voeiras: 'voeiras',
  voejada: 'voejada',
  voejado: 'voejado',
  voejais: 'voejais',
  voejamo: 'voejamo',
  voejara: 'voejara',
  voejava: 'voejava',
  voejeis: 'voejeis',
  voejemo: 'voejemo',
  vogadas: 'vogadas',
  vogador: 'vogador',
  vogados: 'vogados',
  vogamos: 'vogamos',
  vogando: 'vogando',
  vogante: 'vogante',
  vogaram: 'vogaram',
  vogarao: 'vogarão',
  vogaras: 'vogarás',
  vogarei: 'vogarei',
  vogarem: 'vogarem',
  vogares: 'vogares',
  vogaria: 'vogaria',
  vogasse: 'vogasse',
  vogaste: 'vogaste',
  vogavam: 'vogavam',
  vogavas: 'vogavas',
  vogelia: 'vogélia',
  voglias: 'vóglias',
  voglita: 'voglita',
  voglite: 'voglite',
  voglito: 'voglito',
  vogtita: 'vogtita',
  vogueis: 'vogueis',
  voicias: 'voícias',
  voirias: 'vôirias',
  voivoda: 'voivoda',
  voivode: 'voivode',
  volacio: 'volácio',
  volanas: 'volanas',
  volanos: 'volanos',
  volanta: 'volanta',
  volante: 'volante',
  volapes: 'volapés',
  volares: 'volares',
  volatas: 'volatas',
  volatea: 'volateá',
  volatil: 'volátil',
  volatim: 'volatim',
  volcada: 'volcada',
  volcado: 'volcado',
  volcais: 'volcais',
  volcamo: 'volcamo',
  volcana: 'volcana',
  volcara: 'volcará',
  volcava: 'volcava',
  voleios: 'voleios',
  volemia: 'volemia',
  volfrao: 'volfrão',
  volfros: 'volfros',
  volibol: 'volibol',
  volicao: 'volição',
  volitai: 'volitai',
  volitam: 'volitam',
  volitar: 'volitar',
  volitas: 'volitas',
  volitei: 'volitei',
  volitem: 'volitem',
  volites: 'volites',
  volitos: 'vólitos',
  volitou: 'volitou',
  volivel: 'volível',
  volofos: 'volofos',
  volovas: 'volovãs',
  volpino: 'volpino',
  volquei: 'volquei',
  volquem: 'volquem',
  volques: 'volques',
  volscas: 'volscas',
  volscos: 'volscos',
  voltada: 'voltada',
  voltado: 'voltado',
  voltais: 'voltais',
  voltamo: 'voltamo',
  voltara: 'voltará',
  voltava: 'voltava',
  volteai: 'volteai',
  voltear: 'voltear',
  volteei: 'volteei',
  volteia: 'volteia',
  volteie: 'volteie',
  volteio: 'volteio',
  volteis: 'volteis',
  volteja: 'volteja',
  volteje: 'volteje',
  voltejo: 'voltejo',
  voltemo: 'voltemo',
  volteou: 'volteou',
  voltija: 'voltijá',
  voltije: 'voltije',
  voltijo: 'voltijo',
  voltios: 'vóltios',
  voltiza: 'voltizá',
  voltize: 'voltize',
  voltizo: 'voltizo',
  voltzia: 'vóltzia',
  volubil: 'volúbil',
  volubis: 'volúbis',
  volucre: 'volucre',
  volumai: 'volumai',
  volumam: 'volumam',
  volumao: 'volumão',
  volumar: 'volumar',
  volumas: 'volumas',
  volumei: 'volumei',
  volumem: 'volumem',
  volumes: 'volumes',
  volumou: 'volumou',
  volupia: 'volúpia',
  volusia: 'volúsia',
  volutai: 'volutai',
  volutam: 'volutam',
  volutar: 'volutar',
  volutas: 'volutas',
  volutea: 'voluteá',
  volutei: 'volutei',
  volutem: 'volutem',
  volutes: 'volutes',
  volutos: 'volutos',
  volutou: 'volutou',
  volutua: 'volutuá',
  volutue: 'volutue',
  volutuo: 'volutuo',
  voluvel: 'volúvel',
  volvada: 'volvada',
  volvado: 'volvado',
  volvais: 'volvais',
  volvamo: 'volvamo',
  volveis: 'volveis',
  volvele: 'volvele',
  volvemo: 'volvemo',
  volvera: 'volvera',
  volviam: 'volviam',
  volvias: 'volvias',
  volvida: 'volvida',
  volvido: 'volvido',
  volvoce: 'vólvoce',
  volvula: 'vólvula',
  volvulo: 'vólvulo',
  vombate: 'vombate',
  vomente: 'vomente',
  vomicao: 'vomição',
  vomicas: 'vômicas',
  vomicos: 'vômicos',
  vomitai: 'vomitai',
  vomitam: 'vomitam',
  vomitar: 'vomitar',
  vomitas: 'vomitas',
  vomitei: 'vomitei',
  vomitem: 'vomitem',
  vomites: 'vomites',
  vomitos: 'vômitos',
  vomitou: 'vomitou',
  vondeia: 'vondeia',
  vongole: 'vôngole',
  vontade: 'vontade',
  vonthur: 'vonthur',
  voorara: 'voorara',
  vopisca: 'vopisca',
  vopisco: 'vopisco',
  voraces: 'voraces',
  vorador: 'vorador',
  voragem: 'voragem',
  vorazes: 'vorazes',
  vormias: 'vórmias',
  vormios: 'vórmios',
  vorozol: 'vorozol',
  vortais: 'vortais',
  vortice: 'vórtice',
  voseada: 'voseada',
  voseado: 'voseado',
  voseais: 'voseais',
  voseara: 'voseara',
  voseava: 'voseava',
  voseeis: 'voseeis',
  voseiam: 'voseiam',
  voseias: 'voseias',
  voseiem: 'voseiem',
  voseies: 'voseies',
  vosmece: 'vosmecê',
  vosmice: 'vosmicê',
  vossias: 'vóssias',
  votacao: 'votação',
  votadao: 'votadão',
  votadas: 'votadas',
  votador: 'votador',
  votados: 'votados',
  votamos: 'votamos',
  votando: 'votando',
  votante: 'votante',
  votaram: 'votaram',
  votarao: 'votarão',
  votaras: 'votaras',
  votarei: 'votarei',
  votarem: 'votarem',
  votares: 'votares',
  votaria: 'votaria',
  votarmo: 'votarmo',
  votasse: 'votasse',
  votaste: 'votaste',
  votavam: 'votavam',
  votavas: 'votavas',
  votavel: 'votável',
  votemos: 'votemos',
  votiaco: 'votiáco',
  votiaga: 'votiaga',
  votiago: 'votiago',
  votinho: 'votinho',
  votismo: 'votismo',
  votista: 'votista',
  votivas: 'votivas',
  votivos: 'votivos',
  votonas: 'votonas',
  voturao: 'voturão',
  voturas: 'voturãs',
  voturos: 'voturos',
  voucher: 'voucher',
  vovente: 'vovente',
  vovozao: 'vovozão',
  voyeuse: 'voyeuse',
  vozaria: 'vozaria',
  vozario: 'vozario',
  vozeada: 'vozeada',
  vozeado: 'vozeado',
  vozeais: 'vozeais',
  vozeara: 'vozeará',
  vozeava: 'vozeava',
  vozeeis: 'vozeeis',
  vozeiam: 'vozeiam',
  vozeias: 'vozeias',
  vozeiem: 'vozeiem',
  vozeies: 'vozeies',
  vozeira: 'vozeira',
  vozeire: 'vozeire',
  vozeiro: 'vozeiro',
  vozeria: 'vozeria',
  vozerio: 'vozerio',
  vozidos: 'vozidos',
  vozinha: 'vozinha',
  vriesia: 'vriésia',
  vuarame: 'vuarame',
  vulcana: 'vulcana',
  vulcoes: 'vulcões',
  vulgada: 'vulgada',
  vulgado: 'vulgado',
  vulgata: 'vulgata',
  vulinas: 'vulinas',
  vulnera: 'vulnera',
  vulnere: 'vulnere',
  vulnero: 'vulnero',
  vulpato: 'vulpato',
  vulpias: 'vúlpias',
  vulpica: 'vúlpica',
  vulpico: 'vúlpico',
  vulpina: 'vulpina',
  vulpino: 'vulpino',
  vulscas: 'vulscas',
  vulscos: 'vulscos',
  vulsela: 'vulsela',
  vultada: 'vultada',
  vultado: 'vultado',
  vultais: 'vultais',
  vultamo: 'vultamo',
  vultara: 'vultará',
  vultava: 'vultava',
  vulteis: 'vulteis',
  vultemo: 'vultemo',
  vultosa: 'vultosa',
  vultoso: 'vultoso',
  vulvite: 'vulvite',
  vumbada: 'vumbada',
  vunadao: 'vunadão',
  vunadas: 'vunadas',
  vunador: 'vunador',
  vunados: 'vunados',
  vunamos: 'vunamos',
  vunando: 'vunando',
  vunante: 'vunante',
  vunaram: 'vunaram',
  vunarao: 'vunarão',
  vunaras: 'vunarás',
  vunarei: 'vunarei',
  vunarem: 'vunarem',
  vunares: 'vunares',
  vunaria: 'vunaria',
  vunarmo: 'vunarmo',
  vunasse: 'vunasse',
  vunaste: 'vunaste',
  vunavam: 'vunavam',
  vunavas: 'vunavas',
  vundens: 'vundéns',
  vunemos: 'vunemos',
  vungada: 'vungada',
  vungado: 'vungado',
  vungais: 'vungais',
  vungara: 'vungará',
  vungava: 'vungava',
  vunguei: 'vunguei',
  vunguem: 'vunguem',
  vungues: 'vungues',
  vunjice: 'vunjice',
  vunvuns: 'vunvuns',
  vunzada: 'vunzada',
  vunzado: 'vunzado',
  vunzais: 'vunzais',
  vunzamo: 'vunzamo',
  vunzara: 'vunzará',
  vunzava: 'vunzava',
  vunzeis: 'vunzeis',
  vunzemo: 'vunzemo',
  vurapia: 'vurapiá',
  vurbana: 'vurbana',
  vurinas: 'vurinas',
  vurmada: 'vurmada',
  vurmado: 'vurmado',
  vurmais: 'vurmais',
  vurmamo: 'vurmamo',
  vurmara: 'vurmará',
  vurmava: 'vurmava',
  vurmeis: 'vurmeis',
  vurmemo: 'vurmemo',
  vurmosa: 'vurmosa',
  vurmoso: 'vurmoso',
  vuvucai: 'vuvucai',
  vuvucam: 'vuvucam',
  vuvucar: 'vuvucar',
  vuvucas: 'vuvucas',
  vuvucou: 'vuvucou',
  vuvuque: 'vuvuque',
  walkman: 'walkman',
  warrant: 'warrant',
  wattada: 'wattada',
  wattado: 'wattado',
  webmail: 'webmail',
  website: 'website',
  weekend: 'weekend',
  western: 'western',
  whigtia: 'whígtia',
  wysiwyg: 'wysiwyg',
  xabebas: 'xabebas',
  xabegas: 'xábegas',
  xabepas: 'xabepas',
  xabocos: 'xabocos',
  xaboque: 'xaboque',
  xabouca: 'xaboucá',
  xabouco: 'xabouco',
  xabrega: 'xabrega',
  xacaras: 'xácaras',
  xacatai: 'xacatai',
  xacatam: 'xacatam',
  xacatar: 'xacatar',
  xacatas: 'xacatas',
  xacatei: 'xacatei',
  xacatem: 'xacatem',
  xacates: 'xacates',
  xacatou: 'xacatou',
  xacocos: 'xacocos',
  xacoina: 'xacoina',
  xacomas: 'xácomas',
  xacoteu: 'xacotéu',
  xacotos: 'xacotos',
  xadoras: 'xadoras',
  xadores: 'xadores',
  xadreza: 'xadreza',
  xadreze: 'xadreze',
  xadrezo: 'xadrezo',
  xafetao: 'xafetão',
  xagaras: 'xágaras',
  xaguoes: 'xaguões',
  xaimeis: 'xaiméis',
  xaimoes: 'xaimões',
  xainxas: 'xainxás',
  xaireis: 'xairéis',
  xalavar: 'xalavar',
  xalocos: 'xalocos',
  xamanes: 'xamanes',
  xamatas: 'xamatas',
  xamates: 'xamates',
  xambare: 'xambaré',
  xambioa: 'xambioá',
  xambiva: 'xambivá',
  xambuje: 'xambuje',
  xampana: 'xampanã',
  xanduca: 'xanduca',
  xangaia: 'xangaia',
  xantada: 'xantada',
  xantado: 'xantado',
  xantais: 'xantais',
  xantamo: 'xantamo',
  xantana: 'xantana',
  xantara: 'xantará',
  xantato: 'xantato',
  xantava: 'xantava',
  xanteis: 'xanteis',
  xantemo: 'xantemo',
  xantena: 'xantena',
  xantens: 'xantens',
  xantias: 'xântias',
  xantica: 'xântica',
  xantico: 'xântico',
  xantina: 'xantina',
  xantino: 'xantino',
  xantiol: 'xantiol',
  xantios: 'xântios',
  xantipa: 'xantipa',
  xantita: 'xantita',
  xantoma: 'xantoma',
  xantona: 'xantona',
  xantopo: 'xântopo',
  xantosa: 'xantosa',
  xantose: 'xantose',
  xantoso: 'xantoso',
  xanxoes: 'xanxões',
  xapanas: 'xapanãs',
  xaperus: 'xaperus',
  xaponas: 'xaponãs',
  xaputas: 'xaputas',
  xaqueai: 'xaqueai',
  xaquear: 'xaquear',
  xaqueca: 'xaqueca',
  xaqueei: 'xaqueei',
  xaqueia: 'xaqueia',
  xaqueie: 'xaqueie',
  xaqueio: 'xaqueio',
  xaquema: 'xáquema',
  xaqueou: 'xaqueou',
  xaqueta: 'xaquetá',
  xaquete: 'xaquete',
  xaqueto: 'xaqueto',
  xaquima: 'xáquima',
  xaradas: 'xaradas',
  xarafim: 'xarafim',
  xarafos: 'xarafos',
  xarapas: 'xarapas',
  xarapim: 'xarapim',
  xaraque: 'xaraque',
  xardosa: 'xardosa',
  xardoso: 'xardoso',
  xaretas: 'xaretas',
  xarifes: 'xarifes',
  xarocos: 'xarocos',
  xaropai: 'xaropai',
  xaropam: 'xaropam',
  xaropar: 'xaropar',
  xaropas: 'xaropas',
  xaropea: 'xaropeá',
  xaropei: 'xaropei',
  xaropem: 'xaropem',
  xaropes: 'xaropes',
  xaropou: 'xaropou',
  xarroco: 'xarroco',
  xarruas: 'xarruas',
  xatrias: 'xátrias',
  xauiana: 'xauianá',
  xavante: 'xavante',
  xavasca: 'xavasca',
  xavecai: 'xavecai',
  xavecam: 'xavecam',
  xavecar: 'xavecar',
  xavecas: 'xavecas',
  xavecos: 'xavecos',
  xavecou: 'xavecou',
  xavegas: 'xávegas',
  xaveque: 'xaveque',
  xaxados: 'xaxados',
  xaxaras: 'xaxarás',
  xaxatai: 'xaxatai',
  xaxatam: 'xaxatam',
  xaxatar: 'xaxatar',
  xaxatas: 'xaxatas',
  xaxatei: 'xaxatei',
  xaxatem: 'xaxatem',
  xaxates: 'xaxates',
  xaxatou: 'xaxatou',
  xecadas: 'xecadas',
  xecados: 'xecados',
  xeleleu: 'xeleléu',
  xelitas: 'xelitas',
  xenadao: 'xenadão',
  xenadas: 'xenadas',
  xenador: 'xenador',
  xenados: 'xenados',
  xenagia: 'xenagia',
  xenamos: 'xenamos',
  xenando: 'xenando',
  xenante: 'xenante',
  xenaram: 'xenaram',
  xenarao: 'xenarão',
  xenaras: 'xenarás',
  xenarei: 'xenarei',
  xenarem: 'xenarem',
  xenares: 'xenares',
  xenaria: 'xenaria',
  xenarmo: 'xenarmo',
  xenasse: 'xenasse',
  xenaste: 'xenaste',
  xenatos: 'xenatos',
  xenavam: 'xenavam',
  xenavas: 'xenavas',
  xenemos: 'xenemos',
  xenicas: 'xénicas',
  xenicos: 'xénicos',
  xenismo: 'xenismo',
  xenonio: 'xenônio',
  xepeiro: 'xepeiro',
  xequeai: 'xequeai',
  xequear: 'xequear',
  xequeei: 'xequeei',
  xequeia: 'xequeia',
  xequeie: 'xequeie',
  xequeio: 'xequeio',
  xequeou: 'xequeou',
  xerafim: 'xerafim',
  xerasia: 'xerasia',
  xerbros: 'xerbros',
  xerecas: 'xerecas',
  xerente: 'xerente',
  xererem: 'xererém',
  xereres: 'xererés',
  xeretai: 'xeretai',
  xeretam: 'xeretam',
  xeretar: 'xeretar',
  xeretas: 'xeretas',
  xeretea: 'xereteá',
  xeretei: 'xeretei',
  xeretem: 'xeretem',
  xeretes: 'xeretes',
  xeretou: 'xeretou',
  xerezes: 'xerezes',
  xergoes: 'xergões',
  xerifes: 'xerifes',
  xerocai: 'xerocai',
  xerocam: 'xerocam',
  xerocar: 'xerocar',
  xerocas: 'xerocas',
  xerocou: 'xerocou',
  xerogel: 'xerogel',
  xeromas: 'xeromas',
  xeroque: 'xeroque',
  xeroses: 'xeroses',
  xeroxai: 'xeroxai',
  xeroxam: 'xeroxam',
  xeroxar: 'xeroxar',
  xeroxas: 'xeroxas',
  xeroxei: 'xeroxei',
  xeroxem: 'xeroxem',
  xeroxes: 'xeroxes',
  xeroxou: 'xeroxou',
  xerumes: 'xerumes',
  xetetes: 'xetetês',
  xetrada: 'xetrada',
  xetrado: 'xetrado',
  xetrais: 'xetrais',
  xetramo: 'xetramo',
  xetrara: 'xetrará',
  xetrava: 'xetrava',
  xetreis: 'xetreis',
  xetremo: 'xetremo',
  xexeira: 'xexeira',
  xexeiro: 'xexeiro',
  xiamens: 'xiamens',
  xibalos: 'xibalos',
  xibaras: 'xibarás',
  xibaros: 'xibaros',
  xibatas: 'xibatãs',
  xibebes: 'xibebes',
  xibimba: 'xibimba',
  xibongo: 'xibongo',
  xibungo: 'xibungo',
  xicacas: 'xicacas',
  xicaras: 'xícaras',
  xifideo: 'xifídeo',
  xifoide: 'xifoide',
  xigogos: 'xigogos',
  xigonos: 'xigonos',
  xigubos: 'xigubos',
  xigugos: 'xigugos',
  xiismos: 'xiismos',
  xiistas: 'xiistas',
  xiladas: 'xiladas',
  xilados: 'xilados',
  xilemas: 'xilemas',
  xilenas: 'xilenas',
  xilenio: 'xilênio',
  xilenol: 'xilenol',
  xilenos: 'xilenos',
  xilicas: 'xílicas',
  xilicos: 'xílicos',
  xilitol: 'xilitol',
  xilobam: 'xilobam',
  xilodia: 'xilódia',
  xilodio: 'xilódio',
  xiloide: 'xiloide',
  xilomas: 'xilomas',
  xilopia: 'xilópia',
  xiloses: 'xiloses',
  xilreus: 'xilreus',
  xiluvas: 'xiluvas',
  ximanas: 'ximanas',
  ximango: 'ximango',
  ximbelo: 'ximbelo',
  ximbeva: 'ximbeva',
  ximbica: 'ximbica',
  ximbico: 'ximbico',
  ximbira: 'ximbira',
  ximbore: 'ximboré',
  ximbras: 'ximbras',
  ximbuas: 'ximbuás',
  ximbure: 'ximburé',
  ximburu: 'ximburu',
  ximbute: 'ximbute',
  ximocos: 'ximocos',
  xinabre: 'xinabre',
  xinadao: 'xinadão',
  xinadas: 'xinadas',
  xinador: 'xinador',
  xinados: 'xinados',
  xinamos: 'xinamos',
  xinando: 'xinando',
  xinanes: 'xinanes',
  xinante: 'xinante',
  xinapre: 'xinapre',
  xinaram: 'xinaram',
  xinarao: 'xinarão',
  xinaras: 'xinaras',
  xinarei: 'xinarei',
  xinarem: 'xinarem',
  xinares: 'xinares',
  xinaria: 'xinaria',
  xinarmo: 'xinarmo',
  xinasse: 'xinasse',
  xinaste: 'xinaste',
  xinavam: 'xinavam',
  xinavas: 'xinavas',
  xinemos: 'xinemos',
  xingada: 'xingada',
  xingado: 'xingado',
  xingais: 'xingais',
  xingamo: 'xingamo',
  xingara: 'xingara',
  xingava: 'xingava',
  xinguei: 'xinguei',
  xinguem: 'xinguem',
  xingues: 'xingues',
  xingufo: 'xingufo',
  xinxins: 'xinxins',
  xipaias: 'xipaias',
  xipalas: 'xipalas',
  xipante: 'xipante',
  xipefos: 'xipefos',
  xipenes: 'xipenes',
  xipocas: 'xipocas',
  xipocos: 'xipocos',
  xipocue: 'xipócue',
  xiranha: 'xiranha',
  xiriana: 'xiriana',
  xiricaa: 'xiricaá',
  xistica: 'xística',
  xistico: 'xístico',
  xistosa: 'xistosa',
  xistoso: 'xistoso',
  xistros: 'xistros',
  xitacas: 'xitacas',
  xitalas: 'xitalas',
  xitique: 'xitique',
  xitolos: 'xitolos',
  xitombe: 'xitombe',
  xivaita: 'xivaíta',
  xivitos: 'xivitos',
  xixicas: 'xixicas',
  xixixis: 'xixixis',
  xixuoes: 'xixuões',
  xizinho: 'xizinho',
  xocadao: 'xocadão',
  xocadas: 'xocadas',
  xocador: 'xocador',
  xocados: 'xocados',
  xocamos: 'xocamos',
  xocando: 'xocando',
  xocante: 'xocante',
  xocaram: 'xocaram',
  xocarao: 'xocarão',
  xocaras: 'xocarás',
  xocarei: 'xocarei',
  xocarem: 'xocarem',
  xocares: 'xocares',
  xocaria: 'xocaria',
  xocarmo: 'xocarmo',
  xocasse: 'xocasse',
  xocaste: 'xocaste',
  xocavam: 'xocavam',
  xocavas: 'xocavas',
  xonadao: 'xonadão',
  xonadas: 'xonadas',
  xonador: 'xonador',
  xonados: 'xonados',
  xonamos: 'xonamos',
  xonando: 'xonando',
  xonante: 'xonante',
  xonaram: 'xonaram',
  xonarao: 'xonarão',
  xonaras: 'xonarás',
  xonarei: 'xonarei',
  xonarem: 'xonarem',
  xonares: 'xonares',
  xonaria: 'xonaria',
  xonarmo: 'xonarmo',
  xonasse: 'xonasse',
  xonaste: 'xonaste',
  xonavam: 'xonavam',
  xonavas: 'xonavas',
  xonemos: 'xonemos',
  xopotos: 'xopotós',
  xoqueis: 'xoqueis',
  xoquemo: 'xoquemo',
  xorocas: 'xorocas',
  xotadao: 'xotadão',
  xotadas: 'xotadas',
  xotador: 'xotador',
  xotados: 'xotados',
  xotamos: 'xotamos',
  xotando: 'xotando',
  xotante: 'xotante',
  xotaram: 'xotaram',
  xotarao: 'xotarão',
  xotaras: 'xotarás',
  xotarei: 'xotarei',
  xotarem: 'xotarem',
  xotares: 'xotares',
  xotaria: 'xotaria',
  xotarmo: 'xotarmo',
  xotasse: 'xotasse',
  xotaste: 'xotaste',
  xotavam: 'xotavam',
  xotavas: 'xotavas',
  xotemos: 'xotemos',
  xoxombo: 'xoxombo',
  xoxotas: 'xoxotas',
  xramana: 'xrâmana',
  xramane: 'xrâmane',
  xucrice: 'xucrice',
  xucurus: 'xucurus',
  xudairo: 'xudairo',
  xumetos: 'xumetós',
  xurdais: 'xurdais',
  xurdamo: 'xurdamo',
  xurdiam: 'xurdiam',
  xurdias: 'xurdias',
  xurdida: 'xurdida',
  xurdido: 'xurdido',
  xurdimo: 'xurdimo',
  xurdira: 'xurdira',
  xxxviii: 'xxxviii',
  yeshiva: 'yeshiva',
  yorkino: 'yorkino',
  yuppies: 'yuppies',
  zaadona: 'zaadona',
  zabales: 'zabalés',
  zabeles: 'zabelês',
  zabelos: 'zabelos',
  zaberma: 'zaberma',
  zabiana: 'zabiana',
  zabiano: 'zabiano',
  zabucai: 'zabucaí',
  zabumba: 'zabumba',
  zabumbe: 'zabumbe',
  zabumbo: 'zabumbo',
  zaburro: 'zaburro',
  zacinta: 'zacinta',
  zacinto: 'zacinto',
  zaconio: 'zacônio',
  zacoros: 'zácoros',
  zadruga: 'zadruga',
  zafiras: 'zafiras',
  zagaiai: 'zagaiai',
  zagaiam: 'zagaiam',
  zagaiar: 'zagaiar',
  zagaias: 'zagaias',
  zagaiei: 'zagaiei',
  zagaiem: 'zagaiem',
  zagaies: 'zagaies',
  zagaiou: 'zagaiou',
  zagalas: 'zagalas',
  zagalos: 'zagalos',
  zagatai: 'zagatai',
  zagates: 'zagatés',
  zagonal: 'zagonal',
  zagorro: 'zagorro',
  zagroes: 'zagrões',
  zagucho: 'zagucho',
  zaidita: 'zaidita',
  zaimbos: 'zâimbos',
  zainfes: 'zainfes',
  zaipana: 'zaipana',
  zairida: 'zaírida',
  zairita: 'zairita',
  zairota: 'zairota',
  zalacas: 'zalacas',
  zalumai: 'zalumai',
  zalumam: 'zalumam',
  zalumar: 'zalumar',
  zalumas: 'zalumas',
  zalumei: 'zalumei',
  zalumem: 'zalumem',
  zalumes: 'zalumes',
  zalumou: 'zalumou',
  zalzoes: 'zalzões',
  zamaque: 'zamaque',
  zambaio: 'zambaio',
  zambana: 'zambana',
  zambele: 'zambelê',
  zambelo: 'zambelô',
  zambeta: 'zambeta',
  zambeto: 'zambeto',
  zambias: 'zâmbias',
  zambios: 'zâmbios',
  zamboas: 'zamboas',
  zamboto: 'zamboto',
  zambras: 'zambras',
  zambros: 'zambros',
  zambuco: 'zambuco',
  zambuja: 'zambuja',
  zambujo: 'zambujo',
  zambula: 'zambulá',
  zamenas: 'zamenas',
  zamenis: 'zâmenis',
  zamense: 'zamense',
  zamicro: 'zamicro',
  zamorim: 'zamorim',
  zampada: 'zampada',
  zampado: 'zampado',
  zampais: 'zampais',
  zampamo: 'zampamo',
  zampara: 'zampara',
  zampava: 'zampava',
  zampeis: 'zampeis',
  zampemo: 'zampemo',
  zamucos: 'zamucos',
  zamumos: 'zamumos',
  zanagas: 'zanagas',
  zanagos: 'zanagos',
  zanagra: 'zanagra',
  zanatas: 'zanatas',
  zancleu: 'zancleu',
  zanclos: 'zanclos',
  zangada: 'zangada',
  zangado: 'zangado',
  zangais: 'zangais',
  zangamo: 'zangamo',
  zangana: 'zângana',
  zangano: 'zângano',
  zangara: 'zangara',
  zangava: 'zangava',
  zangoes: 'zangões',
  zanguei: 'zanguei',
  zanguem: 'zanguem',
  zangues: 'zangues',
  zangula: 'zangulá',
  zangule: 'zangule',
  zangulo: 'zangulo',
  zanizos: 'zanizos',
  zanoias: 'zanoias',
  zanoios: 'zanoios',
  zanolha: 'zanolha',
  zanolho: 'zanolho',
  zanquim: 'zanquim',
  zantios: 'zântios',
  zanzada: 'zanzada',
  zanzado: 'zanzado',
  zanzais: 'zanzais',
  zanzamo: 'zanzamo',
  zanzara: 'zanzara',
  zanzare: 'zânzare',
  zanzava: 'zanzava',
  zanzeis: 'zanzeis',
  zanzemo: 'zanzemo',
  zanzino: 'zanzino',
  zapeada: 'zapeada',
  zapeado: 'zapeado',
  zapeais: 'zapeais',
  zapeara: 'zapeara',
  zapeava: 'zapeava',
  zapeeis: 'zapeeis',
  zapeiam: 'zapeiam',
  zapeias: 'zapeias',
  zapeiem: 'zapeiem',
  zapeies: 'zapeies',
  zapetes: 'zápetes',
  zapping: 'zapping',
  zapupes: 'zapupes',
  zaralha: 'zaralha',
  zaralho: 'zaralho',
  zaranza: 'zaranza',
  zaranze: 'zaranze',
  zaranzo: 'zaranzo',
  zarasca: 'zarasca',
  zarelha: 'zarelha',
  zarelhe: 'zarelhe',
  zarelho: 'zarelho',
  zarolha: 'zarolha',
  zarolho: 'zarolho',
  zarpada: 'zarpada',
  zarpado: 'zarpado',
  zarpais: 'zarpais',
  zarpamo: 'zarpamo',
  zarpara: 'zarpara',
  zarpava: 'zarpava',
  zarpeis: 'zarpeis',
  zarpemo: 'zarpemo',
  zarucas: 'zarucas',
  zarucos: 'zarucos',
  zavadao: 'zavadão',
  zavadas: 'zavadas',
  zavador: 'zavador',
  zavados: 'zavados',
  zavamos: 'zavamos',
  zavando: 'zavando',
  zavante: 'zavante',
  zavaram: 'zavaram',
  zavarao: 'zavarão',
  zavaras: 'zavarás',
  zavarei: 'zavarei',
  zavarem: 'zavarem',
  zavares: 'zavares',
  zavaria: 'zavaria',
  zavarmo: 'zavarmo',
  zavasse: 'zavasse',
  zavaste: 'zavaste',
  zavavam: 'zavavam',
  zavavas: 'zavavas',
  zavemos: 'zavemos',
  zazinta: 'zazinta',
  zebrada: 'zebrada',
  zebrado: 'zebrado',
  zebrais: 'zebrais',
  zebramo: 'zebramo',
  zebrara: 'zebrará',
  zebrava: 'zebrava',
  zebreis: 'zebreis',
  zebremo: 'zebremo',
  zebrina: 'zebrina',
  zebrino: 'zebrino',
  zebrulo: 'zébrulo',
  zebruna: 'zebruna',
  zebruno: 'zebruno',
  zebruns: 'zebruns',
  zebrura: 'zebrura',
  zebuina: 'zebuína',
  zebuino: 'zebuíno',
  zecoras: 'zécoras',
  zefires: 'zefires',
  zefiros: 'zéfiros',
  zeimoes: 'zeimões',
  zeismos: 'zeísmos',
  zelacao: 'zelação',
  zeladao: 'zeladão',
  zeladas: 'zeladas',
  zelador: 'zelador',
  zelados: 'zelados',
  zelamos: 'zelamos',
  zelando: 'zelando',
  zelante: 'zelante',
  zelaram: 'zelaram',
  zelarao: 'zelarão',
  zelaras: 'zelaras',
  zelarei: 'zelarei',
  zelarem: 'zelarem',
  zelares: 'zelares',
  zelaria: 'zelaria',
  zelarmo: 'zelarmo',
  zelasse: 'zelasse',
  zelaste: 'zelaste',
  zelavam: 'zelavam',
  zelavas: 'zelavas',
  zelemos: 'zelemos',
  zelinho: 'zelinho',
  zelosas: 'zelosas',
  zelosia: 'zelosia',
  zelosos: 'zelosos',
  zelotes: 'zelotes',
  zemareu: 'zemareu',
  zembras: 'zembras',
  zembros: 'zembros',
  zenamos: 'zenamos',
  zenepro: 'zenepro',
  zeneria: 'zenéria',
  zenetas: 'zenetas',
  zenidao: 'zenidão',
  zenidas: 'zenidas',
  zenidor: 'zenidor',
  zenidos: 'zenidos',
  zenieis: 'zeníeis',
  zenimos: 'zenimos',
  zenindo: 'zenindo',
  zeniram: 'zeniram',
  zenirao: 'zenirão',
  zeniras: 'zenirás',
  zenirei: 'zenirei',
  zenirem: 'zenirem',
  zenires: 'zenires',
  zeniria: 'zeniria',
  zenirmo: 'zenirmo',
  zenisse: 'zenisse',
  zeniste: 'zeniste',
  zenital: 'zenital',
  zenobia: 'zenóbia',
  zenzens: 'zenzéns',
  zeofaga: 'zeófaga',
  zeofago: 'zeófago',
  zeolita: 'zeólita',
  zeolite: 'zeolite',
  zeolito: 'zeólito',
  zepelim: 'zepelim',
  zeplins: 'zeplins',
  zequeis: 'zequeis',
  zeradao: 'zeradão',
  zeradas: 'zeradas',
  zerador: 'zerador',
  zerados: 'zerados',
  zeramos: 'zeramos',
  zerando: 'zerando',
  zeranol: 'zeranol',
  zerante: 'zerante',
  zeraram: 'zeraram',
  zerarao: 'zerarão',
  zeraras: 'zeraras',
  zerarei: 'zerarei',
  zerarem: 'zerarem',
  zerares: 'zerares',
  zeraria: 'zeraria',
  zerarmo: 'zerarmo',
  zerasse: 'zerasse',
  zeraste: 'zeraste',
  zeravam: 'zeravam',
  zeravas: 'zeravas',
  zerbada: 'zerbada',
  zeremos: 'zeremos',
  zerinho: 'zerinho',
  zerumba: 'zerumba',
  zeugita: 'zeugita',
  zeugmas: 'zeugmas',
  ziadoes: 'ziadões',
  ziadona: 'ziadona',
  ziadora: 'ziadora',
  ziantes: 'ziantes',
  ziardes: 'ziardes',
  ziareis: 'ziareis',
  ziaremo: 'ziaremo',
  ziariam: 'ziariam',
  ziarias: 'ziarias',
  ziarmos: 'ziarmos',
  ziassem: 'ziassem',
  ziasses: 'ziasses',
  ziastes: 'ziastes',
  ziaveis: 'ziáveis',
  zibetas: 'zibetas',
  zichada: 'zichada',
  zichado: 'zichado',
  zichais: 'zichais',
  zichamo: 'zichamo',
  zichara: 'zichara',
  zichava: 'zichava',
  zicheis: 'zicheis',
  zichemo: 'zichemo',
  zifideo: 'zifídeo',
  zignias: 'zígnias',
  zigomas: 'zigomas',
  zigoses: 'zigoses',
  zigotos: 'zigotos',
  zilhoes: 'zilhões',
  zimases: 'zímases',
  zimbrai: 'zimbrai',
  zimbral: 'zimbral',
  zimbram: 'zimbram',
  zimbrao: 'zimbrão',
  zimbrar: 'zimbrar',
  zimbras: 'zimbras',
  zimbrei: 'zimbrei',
  zimbrem: 'zimbrem',
  zimbres: 'zimbres',
  zimbros: 'zimbros',
  zimbrou: 'zimbrou',
  zimeose: 'zimeose',
  zimicas: 'zímicas',
  zimicos: 'zímicos',
  zimoses: 'zimoses',
  zinabre: 'zinabre',
  zinamos: 'zinamos',
  zincada: 'zincada',
  zincado: 'zincado',
  zincais: 'zincais',
  zincamo: 'zincamo',
  zincara: 'zincará',
  zincato: 'zincato',
  zincava: 'zincava',
  zincica: 'zíncica',
  zincico: 'zíncico',
  zincita: 'zincita',
  zincite: 'zincite',
  zineira: 'zineira',
  zingada: 'zingada',
  zingado: 'zingado',
  zingais: 'zingais',
  zingara: 'zingara',
  zingaro: 'zíngaro',
  zingava: 'zingava',
  zingrai: 'zingrai',
  zingram: 'zingram',
  zingrar: 'zingrar',
  zingras: 'zingras',
  zingrei: 'zingrei',
  zingrem: 'zingrem',
  zingres: 'zingres',
  zingrou: 'zingrou',
  zinguei: 'zinguei',
  zinguem: 'zinguem',
  zingues: 'zingues',
  zinidao: 'zinidão',
  zinidas: 'zinidas',
  zinidor: 'zinidor',
  zinidos: 'zinidos',
  zinieis: 'ziníeis',
  zinimos: 'zinimos',
  zinindo: 'zinindo',
  ziniram: 'ziniram',
  zinirao: 'zinirão',
  ziniras: 'ziniras',
  zinirei: 'zinirei',
  zinirem: 'zinirem',
  zinires: 'zinires',
  ziniria: 'ziniria',
  zinirmo: 'zinirmo',
  zinisse: 'zinisse',
  ziniste: 'ziniste',
  zinquei: 'zinquei',
  zinquem: 'zinquem',
  zinques: 'zinques',
  zipadao: 'zipadão',
  zipadas: 'zipadas',
  zipador: 'zipador',
  zipados: 'zipados',
  zipamos: 'zipamos',
  zipando: 'zipando',
  zipante: 'zipante',
  ziparam: 'ziparam',
  ziparao: 'ziparão',
  ziparas: 'ziparás',
  ziparei: 'ziparei',
  ziparem: 'ziparem',
  zipares: 'zipares',
  ziparia: 'ziparia',
  ziparmo: 'ziparmo',
  zipasse: 'zipasse',
  zipaste: 'zipaste',
  zipavam: 'zipavam',
  zipavas: 'zipavas',
  zipemos: 'zipemos',
  ziperes: 'zíperes',
  zirbais: 'zirbais',
  zircoes: 'zircões',
  zirrada: 'zirrada',
  zirrado: 'zirrado',
  zirrais: 'zirrais',
  zirramo: 'zirramo',
  zirrara: 'zirrará',
  zirrava: 'zirrava',
  zirreis: 'zirreis',
  zirremo: 'zirremo',
  zizania: 'zizânia',
  zizanie: 'zizanie',
  zizanio: 'zizanio',
  ziziada: 'ziziada',
  ziziado: 'ziziado',
  ziziais: 'ziziais',
  ziziamo: 'ziziamo',
  ziziara: 'ziziará',
  ziziava: 'ziziava',
  zizieis: 'zizieis',
  ziziemo: 'ziziemo',
  zizifos: 'zízifos',
  zoacoes: 'zoações',
  zoacona: 'zoaçona',
  zoadoes: 'zoadões',
  zoadona: 'zoadona',
  zoadora: 'zoadora',
  zoantes: 'zoantes',
  zoardes: 'zoardes',
  zoareis: 'zoáreis',
  zoaremo: 'zoaremo',
  zoariam: 'zoariam',
  zoarias: 'zoarias',
  zoarios: 'zoários',
  zoarmos: 'zoarmos',
  zoassem: 'zoassem',
  zoasses: 'zoasses',
  zoastes: 'zoastes',
  zoaveis: 'zoáveis',
  zodiaca: 'zodíaca',
  zodiaco: 'zodíaco',
  zoecias: 'zoécias',
  zoecios: 'zoécios',
  zoeiras: 'zoeiras',
  zoetica: 'zoética',
  zogadas: 'zogadas',
  zoiatra: 'zoiatra',
  zoiatro: 'zoiatro',
  zoisita: 'zoisita',
  zoisite: 'zoisite',
  zoismos: 'zoísmos',
  zolaica: 'zolaica',
  zolaico: 'zolaico',
  zolesca: 'zolesca',
  zolesco: 'zolesco',
  zolismo: 'zolismo',
  zolista: 'zolista',
  zombada: 'zombada',
  zombado: 'zombado',
  zombais: 'zombais',
  zombamo: 'zombamo',
  zombara: 'zombara',
  zombava: 'zombava',
  zombeis: 'zombeis',
  zombemo: 'zombemo',
  zombies: 'zombies',
  zonadao: 'zonadão',
  zonadas: 'zonadas',
  zonador: 'zonador',
  zonados: 'zonados',
  zonagem: 'zonagem',
  zonamos: 'zonamos',
  zonando: 'zonando',
  zonante: 'zonante',
  zonaram: 'zonaram',
  zonarao: 'zonarão',
  zonaras: 'zonaras',
  zonarei: 'zonarei',
  zonarem: 'zonarem',
  zonares: 'zonares',
  zonaria: 'zonária',
  zonarmo: 'zonarmo',
  zonasse: 'zonasse',
  zonaste: 'zonaste',
  zonavam: 'zonavam',
  zonavas: 'zonavas',
  zonchai: 'zonchai',
  zoncham: 'zoncham',
  zonchar: 'zonchar',
  zonchas: 'zonchas',
  zonchei: 'zonchei',
  zonchem: 'zonchem',
  zonches: 'zonches',
  zonchos: 'zonchos',
  zonchou: 'zonchou',
  zondoca: 'zondoca',
  zoneada: 'zoneada',
  zoneado: 'zoneado',
  zoneais: 'zoneais',
  zoneara: 'zoneará',
  zoneava: 'zoneava',
  zoneeis: 'zoneeis',
  zoneiam: 'zoneiam',
  zoneias: 'zoneias',
  zoneiem: 'zoneiem',
  zoneies: 'zoneies',
  zoneiro: 'zoneiro',
  zonemos: 'zonemos',
  zongola: 'zongolá',
  zongole: 'zongole',
  zongolo: 'zongolo',
  zonzada: 'zonzada',
  zonzado: 'zonzado',
  zonzais: 'zonzais',
  zonzamo: 'zonzamo',
  zonzara: 'zonzará',
  zonzava: 'zonzava',
  zonzeai: 'zonzeai',
  zonzear: 'zonzear',
  zonzeei: 'zonzeei',
  zonzeia: 'zonzeia',
  zonzeie: 'zonzeie',
  zonzeio: 'zonzeio',
  zonzeis: 'zonzeis',
  zonzemo: 'zonzemo',
  zonzeou: 'zonzeou',
  zonzona: 'zonzoná',
  zonzone: 'zonzone',
  zonzono: 'zonzono',
  zonzons: 'zonzons',
  zoobias: 'zoobias',
  zoobios: 'zoóbios',
  zoocoro: 'zoócoro',
  zoofaga: 'zoófaga',
  zoofago: 'zoófago',
  zoofila: 'zoófila',
  zoofilo: 'zoófilo',
  zoofita: 'zoófita',
  zoofito: 'zoófito',
  zoofoba: 'zoófoba',
  zoofobo: 'zoófobo',
  zooforo: 'zoóforo',
  zoogama: 'zoógama',
  zoogamo: 'zoógamo',
  zoogeno: 'zoógeno',
  zoogona: 'zoógona',
  zoogono: 'zoógono',
  zooides: 'zooides',
  zoolico: 'zoólico',
  zoolite: 'zoólite',
  zoolito: 'zoólito',
  zoologa: 'zoóloga',
  zoologo: 'zoólogo',
  zoomano: 'zoômano',
  zoonato: 'zoonato',
  zoonimo: 'zoônimo',
  zoonito: 'zoonito',
  zoonose: 'zoonose',
  zoontes: 'zoontes',
  zoopsia: 'zoopsia',
  zooteca: 'zooteca',
  zootico: 'zoótico',
  zopeira: 'zopeira',
  zopeiro: 'zopeiro',
  zopissa: 'zopissa',
  zoratas: 'zoratas',
  zorates: 'zorates',
  zoratos: 'zoratos',
  zorilha: 'zorilha',
  zoritas: 'zoritas',
  zorlito: 'zorlito',
  zornada: 'zornada',
  zornado: 'zornado',
  zornais: 'zornais',
  zornamo: 'zornamo',
  zornaos: 'zornãos',
  zornara: 'zornará',
  zornava: 'zornava',
  zorneis: 'zorneis',
  zornemo: 'zornemo',
  zorongo: 'zorongo',
  zorraga: 'zorraga',
  zorrago: 'zorrago',
  zorrais: 'zorrais',
  zorraos: 'zorrãos',
  zortada: 'zortada',
  zortado: 'zortado',
  zortais: 'zortais',
  zortamo: 'zortamo',
  zortara: 'zortará',
  zortava: 'zortava',
  zorteis: 'zorteis',
  zortemo: 'zortemo',
  zorzais: 'zorzais',
  zostera: 'zostera',
  zotecas: 'zotecas',
  zotismo: 'zotismo',
  zuaques: 'zuaques',
  zuartes: 'zuartes',
  zucadao: 'zucadão',
  zucadas: 'zucadas',
  zucador: 'zucador',
  zucados: 'zucados',
  zucamos: 'zucamos',
  zucando: 'zucando',
  zucante: 'zucante',
  zucaram: 'zucaram',
  zucarao: 'zucarão',
  zucaras: 'zucarás',
  zucarei: 'zucarei',
  zucarem: 'zucarem',
  zucares: 'zucares',
  zucaria: 'zucaria',
  zucarmo: 'zucarmo',
  zucasse: 'zucasse',
  zucaste: 'zucaste',
  zucavam: 'zucavam',
  zucavas: 'zucavas',
  zuelada: 'zuelada',
  zuelado: 'zuelado',
  zuelais: 'zuelais',
  zuelamo: 'zuelamo',
  zuelara: 'zuelará',
  zuelava: 'zuelava',
  zueleis: 'zueleis',
  zuelemo: 'zuelemo',
  zuiamos: 'zuíamos',
  zuidoes: 'zuidões',
  zuidona: 'zuidona',
  zuidora: 'zuidora',
  zuirdes: 'zuirdes',
  zuireis: 'zuireis',
  zuiriam: 'zuiriam',
  zuirias: 'zuirias',
  zuirmos: 'zuirmos',
  zuissem: 'zuíssem',
  zuisses: 'zuísses',
  zuistes: 'zuístes',
  zuladao: 'zuladão',
  zuladas: 'zuladas',
  zulador: 'zulador',
  zulados: 'zulados',
  zulamos: 'zulamos',
  zulando: 'zulando',
  zulante: 'zulante',
  zularam: 'zularam',
  zularao: 'zularão',
  zularas: 'zularás',
  zularei: 'zularei',
  zularem: 'zularem',
  zulares: 'zulares',
  zularia: 'zularia',
  zularmo: 'zularmo',
  zulasse: 'zulasse',
  zulaste: 'zulaste',
  zulavam: 'zulavam',
  zulavas: 'zulavas',
  zulemos: 'zulemos',
  zumbada: 'zumbada',
  zumbado: 'zumbado',
  zumbaia: 'zumbaia',
  zumbaie: 'zumbaie',
  zumbaio: 'zumbaio',
  zumbais: 'zumbais',
  zumbamo: 'zumbamo',
  zumbara: 'zumbará',
  zumbava: 'zumbava',
  zumbeis: 'zumbeis',
  zumbemo: 'zumbemo',
  zumbiam: 'zumbiam',
  zumbias: 'zumbias',
  zumbida: 'zumbida',
  zumbido: 'zumbido',
  zumbimo: 'zumbimo',
  zumbira: 'zumbira',
  zumbrai: 'zumbrai',
  zumbram: 'zumbram',
  zumbrar: 'zumbrar',
  zumbras: 'zumbras',
  zumbrei: 'zumbrei',
  zumbrem: 'zumbrem',
  zumbres: 'zumbres',
  zumbria: 'zumbria',
  zumbrir: 'zumbrir',
  zumbris: 'zumbris',
  zumbriu: 'zumbriu',
  zumbrou: 'zumbrou',
  zumzuns: 'zumzuns',
  zunadao: 'zunadão',
  zunadas: 'zunadas',
  zunador: 'zunador',
  zunados: 'zunados',
  zunamos: 'zunamos',
  zunando: 'zunando',
  zunante: 'zunante',
  zunaram: 'zunaram',
  zunarao: 'zunarão',
  zunaras: 'zunarás',
  zunarei: 'zunarei',
  zunarem: 'zunarem',
  zunares: 'zunares',
  zunaria: 'zunaria',
  zunarmo: 'zunarmo',
  zunasse: 'zunasse',
  zunaste: 'zunaste',
  zunavam: 'zunavam',
  zunavas: 'zunavas',
  zunemos: 'zunemos',
  zungada: 'zungada',
  zungado: 'zungado',
  zungais: 'zungais',
  zungara: 'zungará',
  zungato: 'zungato',
  zungava: 'zungava',
  zunguei: 'zunguei',
  zunguem: 'zunguem',
  zungues: 'zungues',
  zuniada: 'zuniada',
  zunidao: 'zunidão',
  zunidas: 'zunidas',
  zunidor: 'zunidor',
  zunidos: 'zunidos',
  zunieis: 'zuníeis',
  zunimos: 'zunimos',
  zunindo: 'zunindo',
  zuninga: 'zuninga',
  zuniram: 'zuniram',
  zunirao: 'zunirão',
  zuniras: 'zuniras',
  zunirei: 'zunirei',
  zunirem: 'zunirem',
  zunires: 'zunires',
  zuniria: 'zuniria',
  zunirmo: 'zunirmo',
  zunisse: 'zunisse',
  zuniste: 'zuniste',
  zunzuna: 'zunzuna',
  zunzune: 'zunzune',
  zunzuni: 'zunzuni',
  zunzuno: 'zunzuno',
  zunzuns: 'zunzuns',
  zupadao: 'zupadão',
  zupadas: 'zupadas',
  zupador: 'zupador',
  zupados: 'zupados',
  zupamos: 'zupamos',
  zupando: 'zupando',
  zupante: 'zupante',
  zuparam: 'zuparam',
  zuparao: 'zuparão',
  zuparas: 'zuparás',
  zuparei: 'zuparei',
  zuparem: 'zuparem',
  zupares: 'zupares',
  zuparia: 'zuparia',
  zuparmo: 'zuparmo',
  zupasse: 'zupasse',
  zupaste: 'zupaste',
  zupavam: 'zupavam',
  zupavas: 'zupavas',
  zupemos: 'zupemos',
  zuqueis: 'zuqueis',
  zuquemo: 'zuquemo',
  zuquete: 'zúquete',
  zuraque: 'zuraque',
  zurbada: 'zurbada',
  zuretas: 'zuretas',
  zurrada: 'zurrada',
  zurrado: 'zurrado',
  zurrais: 'zurrais',
  zurramo: 'zurramo',
  zurrapa: 'zurrapa',
  zurrara: 'zurrara',
  zurrava: 'zurrava',
  zurreis: 'zurreis',
  zurremo: 'zurremo',
  zurupai: 'zurupai',
  zurupam: 'zurupam',
  zurupar: 'zurupar',
  zurupas: 'zurupas',
  zurupei: 'zurupei',
  zurupem: 'zurupem',
  zurupes: 'zurupes',
  zurupou: 'zurupou',
  zurvada: 'zurvada',
  zurzais: 'zurzais',
  zurzamo: 'zurzamo',
  zurziam: 'zurziam',
  zurzias: 'zurzias',
  zurzida: 'zurzida',
  zurzido: 'zurzido',
  zurzimo: 'zurzimo',
  zurzira: 'zurzira',
}
