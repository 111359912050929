export const WORDS = [
  'palavra',
  'pessoas',
  'produto',
  'janeiro',
  'durante',
  'empresa',
  'sistema',
  'algumas',
  'através',
  'direito',
  'projeto',
  'momento',
  'público',
  'governo',
  'família',
  'verdade',
  'segundo',
  'exemplo',
  'federal',
  'relação',
  'segunda',
  'outubro',
  'precisa',
  'grandes',
  'mercado',
  'somente',
  'serviço',
  'fazendo',
  'simples',
  'preciso',
  'estamos',
  'milhões',
  'período',
  'próximo',
  'coração',
  'próprio',
  'pessoal',
  'passado',
  'estados',
  'justiça',
  'sucesso',
  'comprar',
  'sociais',
  'minutos',
  'palavra',
  'pública',
  'maneira',
  'sentido',
  'própria',
  'atenção',
  'ninguém',
  'maioria',
  'futebol',
  'poderia',
  'questão',
  'vontade',
  'caminho',
  'podemos',
  'energia',
  'certeza',
  'polícia',
  'contato',
  'prática',
  'assunto',
  'imagens',
  'análise',
  'entrega',
  'receber',
  'tamanho',
  'existem',
  'difícil',
  'mundial',
  'aqueles',
  'animais',
  'quartos',
  'artigos',
  'entanto',
  'colocar',
  'oficial',
  'eventos',
  'criança',
  'valores',
  'escolha',
  'pequeno',
  'maiores',
  'técnico',
  'entrada',
  'cultura',
  'decisão',
  'vitória',
  'estudos',
  'próxima',
  'opinião',
  'técnica',
  'nenhuma',
  'começou',
  'emprego',
  'criação',
  'postado',
  'permite',
  'oferece',
  'leitura',
  'unidade',
  'estavam',
  'últimos',
  'membros',
  'pequena',
  'usuário',
  'aumento',
  'arquivo',
  'américa',
  'domingo',
  'posição',
  'solução',
  'começar',
  'natural',
  'cidades',
  'matéria',
  'cozinha',
  'capital',
  'suporte',
  'musicas',
  'mostrar',
  'escolas',
  'chamado',
  'máquina',
  'procura',
  'cliente',
  'deveria',
  'direção',
  'efeitos',
  'alegria',
  'escrito',
  'falando',
  'ofertas',
  'agência',
  'modelos',
  'humanos',
  'vendido',
  'mudança',
  'revista',
  'consigo',
  'partido',
  'lugares',
  'páginas',
  'seguida',
  'estiver',
  'memória',
  'dúvidas',
  'celular',
  'crédito',
  'negócio',
  'avenida',
  'receita',
  'popular',
  'turismo',
  'espécie',
  'consumo',
  'piscina',
  'jogador',
  'diretor',
  'geração',
  'partida',
  'semanas',
  'chamada',
  'pressão',
  'estação',
  'esporte',
  'afirmou',
  'cuidado',
  'recebeu',
  'medidas',
  'fizeram',
  'dizendo',
  'lembrar',
  'funções',
  'conhece',
  'noticia',
  'formato',
  'esperar',
  'planeta',
  'recurso',
  'aparece',
  'viagens',
  'ricardo',
  'estrada',
  'reunião',
  'escrita',
  'inicial',
  'vestido',
  'madeira',
  'central',
  'destino',
  'tivesse',
  'pereira',
  'seleção',
  'escolar',
  'virtual',
  'editora',
  'poderão',
  'veículo',
  'digital',
  'anuncie',
  'redução',
  'fazenda',
  'carinho',
  'horário',
  'limpeza',
  'meninas',
  'direita',
  'possuem',
  'tópicos',
  'adultos',
  'médicos',
  'artista',
  'crítica',
  'atuação',
  'autores',
  'doenças',
  'objetos',
  'inteiro',
  'estrela',
  'fatores',
  'tiveram',
  'reserva',
  'explica',
  'ciência',
  'lançado',
  'supremo',
  'visitas',
  'anúncio',
  'importa',
  'bateria',
  'comando',
  'números',
  'cenário',
  'compras',
  'atender',
  'estreia',
  'bilhões',
  'cabelos',
  'caráter',
  'devemos',
  'ficaram',
  'antigos',
  'amizade',
  'salário',
  'aluguel',
  'motivos',
  'regiões',
  'enviado',
  'visitar',
  'esforço',
  'terreno',
  'desafio',
  'parecer',
  'sorriso',
  'atitude',
  'militar',
  'recente',
  'aquelas',
  'estudar',
  'coleção',
  'francês',
  'limites',
  'fábrica',
  'retorno',
  'desenho',
  'daquele',
  'querida',
  'galeria',
  'ligação',
  'chegada',
  'espaços',
  'ribeiro',
  'estádio',
  'aspecto',
  'levando',
  'reforma',
  'naquele',
  'ocorreu',
  'plantas',
  'ficando',
  'agentes',
  'martins',
  'podendo',
  'passada',
  'sabemos',
  'musical',
  'títulos',
  'versões',
  'domínio',
  'conosco',
  'combate',
  'pintura',
  'menores',
  'últimas',
  'imóveis',
  'sujeito',
  'passeio',
  'mistura',
  'orgulho',
  'coragem',
  'aluguer',
  'depende',
  'guardar',
  'métodos',
  'jornada',
  'batalha',
  'realiza',
  'acórdão',
  'sozinho',
  'almeida',
  'abraços',
  'avaliar',
  'parecia',
  'corrida',
  'duração',
  'estágio',
  'mostrou',
  'tivemos',
  'garante',
  'reduzir',
  'células',
  'colegas',
  'crescer',
  'vítimas',
  'utiliza',
  'baseado',
  'deverão',
  'padrões',
  'aceitar',
  'jornais',
  'ocasião',
  'acessar',
  'privada',
  'pedidos',
  'alterar',
  'perante',
  'inverno',
  'química',
  'amarelo',
  'atletas',
  'atingir',
  'sorteio',
  'equipes',
  'demanda',
  'líderes',
  'campeão',
  'eleição',
  'perdido',
  'licença',
  'saudade',
  'fórmula',
  'incluem',
  'aplicar',
  'aumenta',
  'olhando',
  'cumprir',
  'definir',
  'garagem',
  'felizes',
  'tarefas',
  'interna',
  'convite',
  'existir',
  'entende',
  'espelho',
  'gráfico',
  'daquela',
  'naquela',
  'comecei',
  'europeu',
  'coloque',
  'capazes',
  'estadia',
  'líquido',
  'enviada',
  'interno',
  'poderes',
  'cérebro',
  'sabendo',
  'paraíba',
  'estaria',
  'abertas',
  'moderno',
  'desejar',
  'decidiu',
  'dólares',
  'pudesse',
  'ocorrer',
  'termina',
  'teclado',
  'correta',
  'augusto',
  'excesso',
  'inteira',
  'escreve',
  'iniciar',
  'farinha',
  'parecem',
  'conheço',
  'comprei',
  'teremos',
  'lateral',
  'laranja',
  'fechado',
  'ficamos',
  'divisão',
  'décadas',
  'procure',
  'cidadão',
  'disputa',
  'desejos',
  'gerente',
  'árvores',
  'benfica',
  'privado',
  'diálogo',
  'estúdio',
  'mostram',
  'fazemos',
  'externa',
  'torcida',
  'romance',
  'formado',
  'conexão',
  'paredes',
  'cantora',
  'ensinar',
  'moderna',
  'térmica',
  'cálculo',
  'auxílio',
  'famosos',
  'envolve',
  'assumir',
  'senhora',
  'segredo',
  'setores',
  'visando',
  'gordura',
  'colocou',
  'oficina',
  'estarão',
  'revisão',
  'estejam',
  'resolvi',
  'andrade',
  'antigas',
  'bebidas',
  'alcance',
  'começam',
  'marcado',
  'cadeira',
  'avaliou',
  'cerveja',
  'brincar',
  'palácio',
  'sozinha',
  'detalhe',
  'integra',
  'ataques',
  'repente',
  'talento',
  'destaca',
  'correto',
  'tomando',
  'externo',
  'perfume',
  'símbolo',
  'baseada',
  'general',
  'aliança',
  'decidir',
  'emoções',
  'esquema',
  'postura',
  'fizemos',
  'centros',
  'delicia',
  'afirmar',
  'prestar',
  'roteiro',
  'votação',
  'tratado',
  'meninos',
  'largura',
  'balanço',
  'autoria',
  'mandato',
  'paraíso',
  'imposto',
  'criando',
  'classes',
  'algodão',
  'entendo',
  'básicos',
  'gostava',
  'séculos',
  'formada',
  'tecidos',
  'percebe',
  'torneio',
  'pimenta',
  'pedindo',
  'vivendo',
  'vivemos',
  'aprendi',
  'prático',
  'estoque',
  'edições',
  'canções',
  'acessos',
  'riqueza',
  'físicas',
  'elevado',
  'promove',
  'titular',
  'chances',
  'retirar',
  'jogando',
  'socorro',
  'figuras',
  'filtrar',
  'promete',
  'clínica',
  'bairros',
  'cheguei',
  'defende',
  'emissão',
  'barriga',
  'querido',
  'prefiro',
  'aprenda',
  'cartões',
  'ampliar',
  'básicas',
  'gigante',
  'percebi',
  'estando',
  'contudo',
  'derrota',
  'esqueça',
  'conduta',
  'quantos',
  'máscara',
  'mentira',
  'pobreza',
  'virtude',
  'baratos',
  'relógio',
  'esqueci',
  'iniciou',
  'encanto',
  'melhora',
  'línguas',
  'marcada',
  'pacotes',
  'loucura',
  'assista',
  'confira',
  'fiscais',
  'pousada',
  'regular',
  'ligados',
  'adianta',
  'recebem',
  'redação',
  'litoral',
  'terapia',
  'dezenas',
  'tarifas',
  'incluir',
  'impedir',
  'oriente',
  'atração',
  'diárias',
  'resenha',
  'atacado',
  'varanda',
  'hábitos',
  'tráfico',
  'solidão',
  'indicar',
  'esmalte',
  'ouvindo',
  'possuir',
  'debaixo',
  'senador',
  'nascido',
  'utilize',
  'exceção',
  'pescoço',
  'barulho',
  'acusado',
  'revelou',
  'prêmios',
  'implica',
  'estilos',
  'costuma',
  'janelas',
  'brancos',
  'feriado',
  'inferno',
  'quantas',
  'anônimo',
  'físicos',
  'amostra',
  'abertos',
  'quadros',
  'sapatos',
  'ouvidos',
  'superar',
  'crítico',
  'tirando',
  'humanas',
  'intuito',
  'marinho',
  'extrema',
  'recorde',
  'todavia',
  'dirigir',
  'correio',
  'lavagem',
  'grávida',
  'informa',
  'tráfego',
  'parcial',
  'garrafa',
  'criados',
  'anuncia',
  'acordar',
  'marinha',
  'círculo',
  'intensa',
  'goleiro',
  'preparo',
  'estarei',
  'conheci',
  'gráfica',
  'carioca',
  'elevada',
  'passava',
  'tabelas',
  'sudeste',
  'intenso',
  'pedaços',
  'locação',
  'repetir',
  'cresceu',
  'adiante',
  'câmeras',
  'absurdo',
  'parcela',
  'colégio',
  'contigo',
  'entendi',
  'prepara',
  'seguido',
  'resolve',
  'término',
  'teorias',
  'exercer',
  'excluir',
  'ensaios',
  'remédio',
  'dividir',
  'assinar',
  'armário',
  'alagoas',
  'sessões',
  'comprou',
  'lançada',
  'normais',
  'boletim',
  'daquilo',
  'fundada',
  'escrevi',
  'levaram',
  'sombras',
  'registo',
  'camadas',
  'futuros',
  'jardins',
  'efetuar',
  'observa',
  'adesivo',
  'amarela',
  'costume',
  'ocorrem',
  'tesouro',
  'quebrar',
  'legenda',
  'tomadas',
  'higiene',
  'situado',
  'deixado',
  'visível',
  'efetivo',
  'andando',
  'voltado',
  'extremo',
  'holanda',
  'devendo',
  'consiga',
  'índices',
  'arrumar',
  'criador',
  'obtidos',
  'músicos',
  'escreva',
  'achando',
  'terraço',
  'pensava',
  'similar',
  'procuro',
  'perdida',
  'dívidas',
  'conheça',
  'deserto',
  'aprende',
  'síntese',
  'aguardo',
  'urgente',
  'códigos',
  'embaixo',
  'debates',
  'entenda',
  'comenta',
  'curioso',
  'galinha',
  'falamos',
  'fechada',
  'motores',
  'assumiu',
  'existia',
  'carbono',
  'estarem',
  'revelar',
  'lembrou',
  'crônica',
  'seguros',
  'julgado',
  'vizinho',
  'atingiu',
  'resulta',
  'nuclear',
  'bancada',
  'manteve',
  'provoca',
  'cabeças',
  'divulga',
  'quentes',
  'abençoe',
  'parques',
  'assisti',
  'prestes',
  'milagre',
  'abrindo',
  'profeta',
  'famosas',
  'fixação',
  'esquina',
  'escrevo',
  'relatos',
  'efetiva',
  'tavares',
  'comédia',
  'filtros',
  'defeito',
  'havendo',
  'visuais',
  'imagina',
  'protege',
  'desfile',
  'cotação',
  'estável',
  'escolhe',
  'guerras',
  'treinar',
  'ligadas',
  'pilotos',
  'volante',
  'prontos',
  'balança',
  'lâmpada',
  'decreto',
  'criadas',
  'avançar',
  'trechos',
  'aliados',
  'umidade',
  'idiomas',
  'cintura',
  'batendo',
  'resgate',
  'afastar',
  'escapar',
  'separar',
  'levanta',
  'citados',
  'estampa',
  'gerando',
  'gravado',
  'cinemas',
  'remover',
  'herança',
  'prédios',
  'castelo',
  'escutar',
  'avanços',
  'agradar',
  'soldado',
  'reações',
  'vínculo',
  'exibido',
  'merecem',
  'capitão',
  'lembrei',
  'ginásio',
  'assalto',
  'futuras',
  'ameaças',
  'orações',
  'margens',
  'juvenil',
  'crenças',
  'textura',
  'legumes',
  'prefere',
  'suporta',
  'enormes',
  'confiar',
  'elogios',
  'compõem',
  'convida',
  'esquece',
  'reforço',
  'voltada',
  'lacrado',
  'origens',
  'sobrado',
  'combina',
  'chegado',
  'quintal',
  'queriam',
  'invasão',
  'nervoso',
  'confere',
  'gramado',
  'exposto',
  'relator',
  'amadora',
  'admitir',
  'humilde',
  'encarar',
  'vegetal',
  'adaptar',
  'indicam',
  'atuando',
  'reflete',
  'haveria',
  'mudando',
  'olhares',
  'dedicar',
  'desejam',
  'docente',
  'urbanos',
  'sorvete',
  'plateia',
  'curiosa',
  'retrato',
  'frontal',
  'diploma',
  'caseiro',
  'radical',
  'sentado',
  'revolta',
  'acordos',
  'observe',
  'miséria',
  'pagando',
  'faleceu',
  'relaxar',
  'treinos',
  'cavalos',
  'ocupado',
  'permita',
  'passear',
  'diminui',
  'animado',
  'afirmam',
  'gostado',
  'passará',
  'cansado',
  'reflexo',
  'piedade',
  'semente',
  'colunas',
  'sumário',
  'ensinou',
  'acabado',
  'trilhas',
  'eleitos',
  'esconde',
  'equipas',
  'manchas',
  'remoção',
  'bagagem',
  'aquário',
  'dourado',
  'editado',
  'segurar',
  'acertar',
  'adeptos',
  'inserir',
  'situada',
  'barreto',
  'lareira',
  'servido',
  'clínico',
  'mantido',
  'mandado',
  'bilhete',
  'fazerem',
  'tornado',
  'lembram',
  'sagrado',
  'clareza',
  'alemães',
  'volumes',
  'plantão',
  'caderno',
  'repouso',
  'decorar',
  'pareceu',
  'gerador',
  'fortuna',
  'missões',
  'rapidez',
  'empenho',
  'feridas',
  'teórico',
  'bondade',
  'aumente',
  'apontam',
  'conclui',
  'escolhi',
  'moradia',
  'escadas',
  'esperam',
  'batatas',
  'fizesse',
  'animada',
  'analisa',
  'causado',
  'mínimos',
  'punição',
  'percebo',
  'pássaro',
  'mestres',
  'referir',
  'carrega',
  'mensais',
  'prepare',
  'devagar',
  'painéis',
  'cansaço',
  'mentais',
  'novelas',
  'apontar',
  'encerra',
  'brindes',
  'monstro',
  'andares',
  'ficarem',
  'aceitou',
  'pensado',
  'aprovou',
  'nomeado',
  'válvula',
  'rosário',
  'veremos',
  'império',
  'precoce',
  'colocam',
  'isolado',
  'ajustes',
  'suposto',
  'vigente',
  'liminar',
  'vocação',
  'deveres',
  'costura',
  'passivo',
  'impulso',
  'feridos',
  'tiverem',
  'coberta',
  'discute',
  'filhote',
  'navegar',
  'isenção',
  'estante',
  'sábados',
  'pesados',
  'revelam',
  'aplique',
  'horária',
  'acharam',
  'sabores',
  'estreou',
  'chácara',
  'rápidas',
  'medalha',
  'retorna',
  'prender',
  'quantia',
  'ajudado',
  'rodovia',
  'levados',
  'toalhas',
  'apostar',
  'informe',
  'doações',
  'estende',
  'enganar',
  'petição',
  'médicas',
  'monitor',
  'declara',
  'demorar',
  'tamanha',
  'causada',
  'vejamos',
  'acordei',
  'truques',
  'árbitro',
  'obtenha',
  'extrato',
  'baratas',
  'ausente',
  'pedimos',
  'infeliz',
  'ficarão',
  'contará',
  'poética',
  'aviação',
  'tratava',
  'semanal',
  'notável',
  'módulos',
  'medição',
  'esboços',
  'abordar',
  'tratada',
  'bênçãos',
  'obtidas',
  'fachada',
  'sincero',
  'puderam',
  'rápidos',
  'cimento',
  'cultivo',
  'diários',
  'bonecos',
  'urbanas',
  'achamos',
  'sofrido',
  'joelhos',
  'manuais',
  'coberto',
  'sugerir',
  'trajeto',
  'buracos',
  'abraçar',
  'secreto',
  'alcança',
  'atentos',
  'fundado',
  'estátua',
  'repleto',
  'estudou',
  'apelido',
  'criaram',
  'citação',
  'queimar',
  'cansada',
  'mineral',
  'cometer',
  'demorou',
  'liberar',
  'compara',
  'possuía',
  'falaram',
  'líquida',
  'assento',
  'perícia',
  'globais',
  'tristes',
  'faltava',
  'asfalto',
  'deixava',
  'falante',
  'poesias',
  'triunfo',
  'ansiosa',
  'telhado',
  'lutando',
  'mantida',
  'bordado',
  'perigos',
  'cristal',
  'teórica',
  'apostas',
  'suposta',
  'aceitam',
  'valente',
  'mudaram',
  'bolinha',
  'paradas',
  'aguarde',
  'montado',
  'abrange',
  'prótese',
  'insetos',
  'calçada',
  'chamava',
  'suprema',
  'captura',
  'digamos',
  'pérolas',
  'esferas',
  'prometo',
  'padaria',
  'tributo',
  'tenente',
  'véspera',
  'apetite',
  'biquíni',
  'vencido',
  'sólidos',
  'imagino',
  'perdoar',
  'aprovar',
  'deixará',
  'ignorar',
  'omissão',
  'armação',
  'evoluir',
  'plantio',
  'chegará',
  'gatinha',
  'caminha',
  'paixões',
  'difusão',
  'diretos',
  'despesa',
  'palhaço',
  'colombo',
  'console',
  'explore',
  'recheio',
  'objecto',
  'outrora',
  'reforça',
  'tapetes',
  'patamar',
  'assiste',
  'exibida',
  'renovar',
  'apontou',
  'digitar',
  'inédito',
  'secador',
  'debater',
  'recados',
  'camisas',
  'assinou',
  'jaqueta',
  'chegava',
  'reserve',
  'rotação',
  'entorno',
  'mexendo',
  'químico',
  'destina',
  'cândido',
  'jogadas',
  'agendar',
  'confuso',
  'tribuna',
  'equação',
  'auxilia',
  'arranjo',
  'injeção',
  'queiram',
  'baixada',
  'seremos',
  'aguarda',
  'ajustar',
  'mochila',
  'secreta',
  'ovelhas',
  'aliviar',
  'bandeja',
  'armazém',
  'culpado',
  'ladrões',
  'reclama',
  'piratas',
  'brincos',
  'contava',
  'colônia',
  'indicou',
  'equador',
  'tentava',
  'maldade',
  'agrária',
  'naquilo',
  'prontas',
  'limitar',
  'subindo',
  'honesto',
  'pilares',
  'insiste',
  'lamento',
  'turista',
  'dublado',
  'gerados',
  'ocultas',
  'acertou',
  'teatral',
  'plantar',
  'ternura',
  'virando',
  'existiu',
  'quartas',
  'forçado',
  'faremos',
  'escuros',
  'solares',
  'potente',
  'esteira',
  'ademais',
  'orienta',
  'brilhar',
  'lençóis',
  'advento',
  'vaidade',
  'exagero',
  'exigido',
  'furacão',
  'voltará',
  'refúgio',
  'tubarão',
  'roubado',
  'sentada',
  'visitou',
  'morador',
  'materno',
  'parente',
  'retomar',
  'amoroso',
  'reparar',
  'cobrado',
  'ruptura',
  'celebra',
  'apanhar',
  'guardas',
  'romanos',
  'porções',
  'sujeita',
  'poderem',
  'imagine',
  'costumo',
  'namorar',
  'noturno',
  'tentado',
  'editais',
  'vinagre',
  'cenoura',
  'colorir',
  'decorre',
  'bagunça',
  'enfoque',
  'audição',
  'ouvimos',
  'apertar',
  'sincera',
  'povoado',
  'metendo',
  'fabiana',
  'contida',
  'melodia',
  'extensa',
  'crescem',
  'alturas',
  'elimina',
  'noturna',
  'executa',
  'valendo',
  'entrará',
  'quebrou',
  'seriado',
  'típicas',
  'comidas',
  'isolada',
  'acúmulo',
  'pediram',
  'rolando',
  'saberes',
  'galáxia',
  'quesito',
  'armados',
  'mármore',
  'trilhos',
  'emendas',
  'músculo',
  'almoçar',
  'dirigiu',
  'xícaras',
  'jogaram',
  'arsenal',
  'postada',
  'cortado',
  'ocupada',
  'natação',
  'térmico',
  'sujeira',
  'aldeias',
  'vitrine',
  'vendida',
  'planeja',
  'ficasse',
  'orelhas',
  'alertas',
  'inédita',
  'tomaram',
  'consome',
  'ventura',
  'iogurte',
  'devedor',
  'apoiado',
  'atendeu',
  'formais',
  'granito',
  'repleta',
  'ordenar',
  'emitido',
  'lanches',
  'constar',
  'ousadia',
  'casados',
  'aquecer',
  'escuras',
  'investe',
  'enquete',
  'ambição',
  'acomoda',
  'estória',
  'péssima',
  'dourada',
  'fraldas',
  'proteja',
  'egoísmo',
  'ansioso',
  'manobra',
  'tensões',
  'retrata',
  'extrair',
  'definiu',
  'papelão',
  'ficarei',
  'sejamos',
  'oitenta',
  'pintado',
  'devidos',
  'espanto',
  'desviar',
  'núcleos',
  'passiva',
  'sanções',
  'calçado',
  'nublado',
  'válidos',
  'camarão',
  'devoção',
]
